import styled from "styled-components";

export const BrandWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  font-size: 20px;
  margin: 0px;
  font-weight: 400;
  height: 40px;
  .brandName {
    margin-top: -40px;
    height: 40px;
  }
  .brandLogo{

  }
`;
