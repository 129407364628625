import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  TableSelection,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-bootstrap4";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import saveAs from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import config from "../../config";
import { Radio, Modal, Tabs } from "antd";
import { GetAPIRequest } from "../../services";
import DatePicker from "../../libs/multiselect-dropdown";
import DatePanel from "../../libs/multiselect-dropdown/plugins/date_panel/date_panel";
import {
  CllReportingTableWrapper,
  FilterTabWrapper,
  TableWrapper,
} from "./CllReportingTable.styles";
import SiteMap from "../SiteMap/SiteMap";
import moment from "moment";
import { getDateIntervalDuration } from "@amcharts/amcharts5/.internal/core/util/Time";
const { TabPane } = Tabs;
const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "DataGrid.xlsx"
    );
  });
};

const AlignCenterFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
    }}
  >
    {value}
  </span>
);
const CenterTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignCenterFormatter} {...props} />
);
const AlignLeftFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    }}
  >
    {value}
  </span>
);
const LeftTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignLeftFormatter} {...props} />
);
const buildQueryString = (url, take, skip, currentDate = "2020-04-01") =>
  `${url}?dt=${currentDate}&limit=${take}&offset=${skip}`;

const CllReportingTable = (props) => {
  var url = "";
  var tabname = "";
  if (props.dataType == "hcp") {
    url = `${config.api.reportinghcp}`;
    tabname = "Physician Details";
  } else if (props.dataType == "top_parent") {
    url = `${config.api.reportingTopaccounts}`;
    tabname = "Account Name";
  } else if (props.dataType == "parent") {
    url = `${config.api.reportingAccounts}`;
    tabname = "Account Name";
  }
  const tableref = useRef(null);

  const [columns, setColumns] = useState([]);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date("2021", "10", "01"));
  const [columnsExt, setColumnsExt] = useState([]);
  const [rows, setRows] = useState([]);
  const [centerColumns, setCenterColumns] = useState([]);
  const [unmetneedsCat, setUnmetneedsCat] = useState([]);
  const [unmetneedFilterIdx, setUnmetneedFilterIdx] = useState(0);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [subtabs, setSubTabs] = useState([]);
  const [currentSubTabs, setCurrentSubTabs] = useState([]);
  const [selectedSubTab, setSelectedSubTab] = useState("");
  const [selectedMainTab, setSelectedMainTab] = useState("");
  const [mainTabIdx, setMainTabIdx] = useState(0);
  const [subTabIdx, setSubTabIdx] = useState(0);
  const [defaultHiddenColumnNames, setdefaultHiddenColumnNames] = useState([
    "Prescriber Id",
    "NPI",
  ]);
  const [unmentNeedsKeys, setUnmentNeedsKeys] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selPhy, setSelPhy] = useState("");
  const [leftColumns, setLeftColumns] = useState(["Physician Details"]);
  var _offset = 1;
  const getData = (_date, isDateUpdated) => {
    if(isDateUpdated){
      setRows([]);
    }
    var _dte = _date ? _date : currentDate;
    var query = buildQueryString(url, limit, _offset, _dte);
    var _dt =  moment(`01 ${_dte}`, "DD MMMM YYYY").format("YYYY-MM-DD");
    if(_dt == "invalid Date"){
      query = buildQueryString(url, limit, _offset);
    } else {
      query = buildQueryString(url, limit, _offset, _dt);
    }
 
    GetAPIRequest({
      url: query,
    })
      .then((res) => {
        var maintabs = [];
        var secondarytabs = {};
        var subtabs = [];
        var physicians = [];
        var tableDataArr = [];
        var _tblcolumns = [];
        var _tblcolumnsExt = [];
        var columnns = [
          {
            title: "Physician Details",
            key: "hcp_name",
          },
          {
            title: "Account Name",
            key: "top_parent",
          },
          {
            title: "Physician NPI",
            key: "npi",
          },
        ];
        secondarytabs["HCP Information"] = {
          columns: ["Physician Details", "Account Name", "Physician NPI"],
          subtabs: [],
        };
        if (props.dataType == "top_parent") {
          columnns = [
            {
              title: "Account Name",
              key: "top_parent",
            },
          ];
          secondarytabs["HCP Information"] = {
            columns: ["Account Name"],
            subtabs: [],
          };
        } else if (props.dataType == "parent") {
          columnns = [
            {
              title: "Account Name",
              key: "parent_name",
            },
          ];
          secondarytabs["HCP Information"] = {
            columns: ["Account Name"],
            subtabs: [],
          };
        }

        res.data.map((d4, i) => {
          var phyData = { ...d4 };
          /*phyData["npi"] = d4["npi"];
          phyData["hcp_name"] = d4["hcp_name"];
          phyData["top_parent"] = d4["top_parent"];
          phyData["parent_name"] = d4["parent_name"];
          phyData["funcl_terr_nm"] = d4["funcl_terr_nm"];
          phyData["state"] = d4["state"];
          phyData["city"] = d4["city"];
          phyData["zipcode"] = d4["zipcode"];
          phyData["fips"] = d4["fips"];
          phyData["prescriberid"] = d4["prescriberid"];
          phyData["parent_az_id"] = d4["parent_az_id"];
          phyData["top_parent_az_id"] = d4["top_parent_az_id"];*/
          d4.data.map((d) => {
            if (d.data && d.data.length > 0) {
              if (d.data.data && d.data.data.length > 0) {
                d.data.map((d2) => {
                  d2.data.map((d1, idx) => {
                    //parseData(d1, d2, secondarytabs, columnns, category, subcategory, phyData);
                    var title = d2.title.split(" / ");
                    if (title[0] == "Patients Count") {
                    } else {
                      var tabtitle = "";
                      if (title.length > 1) {
                        tabtitle = title[1];
                      } else {
                        tabtitle = title[0];
                      }
                      if (i == 0) {
                        if (title.length > 1) {
                          if (secondarytabs[title[0]]) {
                            if (secondarytabs[title[0]].columns) {
                            } else {
                              secondarytabs[title[0]].columns = [];
                              secondarytabs[title[0]].subtabs = {};
                            }
                          } else {
                            secondarytabs[title[0]] = {};
                            secondarytabs[title[0]].columns = [];
                            secondarytabs[title[0]].subtabs = {};
                          }
                          if (secondarytabs[title[0]].subtabs[title[1]]) {
                            secondarytabs[title[0]].subtabs[title[1]].push(
                              d1.name
                            );
                          } else {
                            secondarytabs[title[0]].subtabs[title[1]] = [];
                            secondarytabs[title[0]].subtabs[title[1]].push(
                              d1.name
                            );
                          }
                        } else {
                          if (secondarytabs[title[0]]) {
                            if (secondarytabs[title[0]].columns) {
                            } else {
                              secondarytabs[title[0]].columns = [];
                              secondarytabs[title[0]].subtabs = {};
                            }
                          } else {
                            secondarytabs[title[0]] = {};
                            secondarytabs[title[0]].columns = [];
                            secondarytabs[title[0]].subtabs = {};
                          }
                          secondarytabs[title[0]].columns.push(d1.name);
                        }
                        columnns.push({
                          title: d1.name,
                          key: d1.name,
                        });
                      }
                      phyData[d1.name] = d1.value;
                    }
                  });
                });
              } else {
                d.data.map((d1, idx) => {
                  var title = d.title.split(" / ");
                  if (title[0] == "Patients Count") {
                  } else {
                    var tabtitle = "";
                    if (title.length > 1) {
                      tabtitle = title[1];
                    } else {
                      tabtitle = title[0];
                    }
                    if (i == 0) {
                      if (title.length > 1) {
                        if (secondarytabs[title[0]]) {
                          if (secondarytabs[title[0]].columns) {
                          } else {
                            secondarytabs[title[0]].columns = [];
                            secondarytabs[title[0]].subtabs = {};
                          }
                        } else {
                          secondarytabs[title[0]] = {};
                          secondarytabs[title[0]].columns = [];
                          secondarytabs[title[0]].subtabs = {};
                        }
                        if (secondarytabs[title[0]].subtabs[title[1]]) {
                          secondarytabs[title[0]].subtabs[title[1]].push(
                            d1.name
                          );
                        } else {
                          secondarytabs[title[0]].subtabs[title[1]] = [];
                          secondarytabs[title[0]].subtabs[title[1]].push(
                            d1.name
                          );
                        }
                      } else {
                        if (secondarytabs[title[0]]) {
                          if (secondarytabs[title[0]].columns) {
                          } else {
                            secondarytabs[title[0]].columns = [];
                            secondarytabs[title[0]].subtabs = {};
                          }
                        } else {
                          secondarytabs[title[0]] = {};
                          secondarytabs[title[0]].columns = [];
                          secondarytabs[title[0]].subtabs = {};
                        }
                        secondarytabs[title[0]].columns.push(d1.name);
                      }
                      columnns.push({
                        title: d1.name,
                        key: d1.name,
                      });
                    }
                    phyData[d1.name] = d1.value;
                  }
                });
              }
            }
          });
          physicians.push(phyData);
        });
        physicians.forEach((phy) => {
          var tableDataObj = {};
          columnns.map((c) => {
            if (props.dataType == "hcp") {
              tableDataObj["Physician Details"] = phy["hcp_name"];
              tableDataObj["Account Name"] = phy["top_parent"];
              tableDataObj["Physician NPI"] = phy["npi"];
            } else if (props.dataType == "top_parent") {
              tableDataObj["Account Name"] = phy["top_parent"];
            } else if (props.dataType == "parent") {
              tableDataObj["Account Name"] = phy["parent_name"];
            }

            tableDataObj[c.title] = phy[c.key];
          });
          tableDataArr.push(tableDataObj);
          
          rows.push(tableDataObj);
        });

        columnns.map((c) => {
          if (c.title == "Physician Details" || c.title == "Account Name") {
            _tblcolumns.push({
              name: c.title,
              title: c.title,
              getCellValue: (row) => {
                return <div>{row[c.title]}</div>;
              },
            });
            _tblcolumnsExt.push({
              columnName: c.title,
              align: "left",
              width: 200,
              backgroundColor: "#000000",
            });
          } else {
            _tblcolumns.push({
              name: c.title,
              title: c.title,
              getCellValue: (row) => row[c.title],
            });
            _tblcolumnsExt.push({
              columnName: c.title,
              align: "center",
            });
          }
        });
        setCenterColumns(columns);
        setColumns(_tblcolumns);
        setColumnsExt(_tblcolumnsExt);
        setTabs(secondarytabs);
        setRows(rows);

        //var _rows = state.rows.concat(tableDataArr);
        var hiddenColumns = [];
        setHiddenColumnNames(hiddenColumns);
        console.log("tableDataArr-hidden-columns", tableDataArr);
        console.log("hidden-columns", hiddenColumns);
        console.log("physicians", physicians);
        console.log("physicians-secondarytabs", secondarytabs);
        console.log("physicians-columns", columnns);
        console.log("physicians-_tblcolumns", _tblcolumns);
        console.log("physicians-rows", columns);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        _offset = _offset + 1;
        getData();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    _offset = 1;
    getData();

    /*function handleScroll(){
      if (tableref.scrollTop + tableref.clientHeight >=tableref.scrollHeight){
        _offset = _offset + 1;
        getData();
      }
    }
    var tableref = document.getElementsByClassName("dx-g-bs4-table")[0];
    setTimeout(()=>{
      tableref.addEventListener("scroll", handleScroll);
    }, 5000);
    return () => {
      tableref.removeEventListener("scroll", handleScroll)
    };*/
  }, []);
  const getHiddenColumnsFilteringExtensions = (hiddenColumnNames) =>
    hiddenColumnNames.map((columnName) => ({
      columnName,
      predicate: () => false,
    }));
  const [filteringColumnExtensions, setFilteringColumnExtensions] = useState(
    getHiddenColumnsFilteringExtensions(defaultHiddenColumnNames)
  );

  const onHiddenColumnNamesChange = (hiddenColumnNames) =>
    setFilteringColumnExtensions(
      getHiddenColumnsFilteringExtensions(hiddenColumnNames)
    );
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onUnmetNeedChange = (e) => {
    setUnmetneedFilterIdx(e.target.value);
    var hiddenColumns = [];
    unmetneedsCat.map((n, idx) => {
      if (idx != e.target.value) {
        var k = unmentNeedsKeys[n];
        hiddenColumns.push(...k);
      }
    });
    console.log("hidden-columns-1", hiddenColumns);
    setHiddenColumnNames(hiddenColumns);
  };
  const onMainTabChange = (e) => {
    setMainTabIdx(e.target.value);
    setSubTabs([]);
    if (tabs[Object.keys(tabs)[e.target.value]]) {
      var maintab = tabs[Object.keys(tabs)[e.target.value]];
      var subtabs = maintab.subtabs;
      if (subtabs && Object.keys(subtabs).length > 0) {
        console.log(tabs[Object.keys(tabs)[e.target.value]]);
        var subtabscolumns = subtabs[Object.keys(subtabs)[0]];
        var _columns = subtabscolumns;
        var _cols = [];
        if (_columns) {
          columns.map((_c) => {
            if (_columns.indexOf(_c.title) < 0) {
              if (_c.title != tabname) {
                _cols.push(_c.title);
              }
            }
          });
        }

        setHiddenColumnNames(_cols);
        setSubTabs(subtabs);
      } else {
        var _columns = maintab.columns;
        var _cols = [];
        columns.map((_c) => {
          if (_columns.indexOf(_c.title) < 0) {
            if (_c.title != tabname) {
              _cols.push(_c.title);
            }
          }
        });
        setHiddenColumnNames(_cols);
      }
    }
  };
  const onSubTabChange = (e) => {
    var subtabscolumns = subtabs[Object.keys(subtabs)[e.target.value]];
    setSubTabIdx(e.target.value);
    console.log(subtabscolumns);
    var _columns = subtabscolumns;
    var _cols = [];
    if (_columns) {
      columns.map((_c) => {
        if (_columns.indexOf(_c.title) < 0) {
          if (_c.title != tabname) {
            _cols.push(_c.title);
          }
        }
      });
    }

    setHiddenColumnNames(_cols);
  };
  const handleDateChange = (op) => {
    setCurrentDate(op);
    getData(op, true);
  };
  return (
    <CllReportingTableWrapper>
      <SiteMap />
      <FilterTabWrapper>
        <div className="maintabContainer">
          <Radio.Group
            buttonStyle="solid"
            value={mainTabIdx}
            onChange={onMainTabChange}
          >
            {Object.keys(tabs).map((val, idx) => (
              <Radio.Button value={idx}>{val}</Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <div className="subtabContainer">
          <Radio.Group
            buttonStyle="solid"
            value={subTabIdx}
            onChange={onSubTabChange}
          >
            {Object.keys(subtabs).map((val, idx) => (
              <Radio.Button value={idx}>{val}</Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </FilterTabWrapper>

      <TableWrapper
        id="cll-reporting-table"
        ref={tableref}
        style={{ width: window.innerWidth - 20 }}
      >
        <div className="datepicker-container">
          <span style={{ paddingRight: 10, marginLeft: 10 }}>
            Select Month{" "}
          </span>{" "}
          <DatePicker
            format="MMMM YYYY"
            value={currentDate}
            onChange={handleDateChange}
            onlyMonthPicker
            sort
            plugins={[<DatePanel />]}
            style={{ height: 35 }}
          />
        </div>
        <Grid rows={rows} columns={columns}>
          <DragDropProvider />
          <SortingState />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={(val) => {
              setSelection(val);
              if (val.length > 0) {
                console.log("setSelPhy", rows[val[0]]);
                setSelPhy(rows[val[0]]);
              }
            }}
          />
          <SearchState defaultValue="" />
          <CenterTypeProvider for={centerColumns} />
          <LeftTypeProvider for={["Physician Details", "Account Name"]} />
          <Table columnExtensions={columnsExt} />
          <TableColumnReordering defaultOrder={columns} />
          <TableHeaderRow showSortingControls />
          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={false}
          />
          <Toolbar />
          <TableColumnVisibility
            messages={{ noColumns: "Loading..." }}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            hiddenColumnNames={hiddenColumnNames}
          />
          <SearchPanel />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
          <TableFixedColumns leftColumns={leftColumns} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </TableWrapper>
    </CllReportingTableWrapper>
  );
};

CllReportingTable.propTypes = {
  // bla: PropTypes.string,
};

CllReportingTable.defaultProps = {
  // bla: 'test',
};

export default CllReportingTable;
