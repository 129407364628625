import React from "react";
import PropTypes from "prop-types";
import { PhysicianTrendingWrapper } from "./PhysicianTrending.styles";
import TrendingTable from "../../../components/TrendingTable";
import config from "../../../config";

const PhysicianTrending = (props) => {
  return (
    <PhysicianTrendingWrapper>
      <TrendingTable url={`${config.api.reportinghcp}`} />
    </PhysicianTrendingWrapper>
  );
};

PhysicianTrending.propTypes = {
  // bla: PropTypes.string,
};

PhysicianTrending.defaultProps = {
  // bla: 'test',
};

export default PhysicianTrending;
