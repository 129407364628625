import React from 'react';
import PropTypes from 'prop-types';
import { ChildAccountReportingWrapper } from './ChildAccountReporting.styles';
import CllReportingTable from '../../../components/CllReportingTable';

const ChildAccountReporting = (props) => (
  <ChildAccountReportingWrapper>
    <CllReportingTable dataType="parent"/>
  </ChildAccountReportingWrapper>
);

ChildAccountReporting.propTypes = {
  // bla: PropTypes.string,
};

ChildAccountReporting.defaultProps = {
  // bla: 'test',
};

export default ChildAccountReporting;
