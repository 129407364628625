import React from 'react';
import 'antd/dist/antd.css';
import './table.css'
import NavContent from "../HomeView/NavContent";
import { Table } from 'antd';


function Patients1LTable () {
    const columns = [
        {
            title: "Account Name",
          dataIndex: 'name',
        },
        {
            title: "Total Patients",
          dataIndex: 'chinese',
          sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
          },
        },
        {
            title: "Wait and Watch",
          dataIndex: 'math',
          sorter: {
            compare: (a, b) => a.math - b.math,
            multiple: 2,
          },
        },
        {
            title: "  Treated Patients",
          dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },

        {
            title: "  Treated Patients with Acalabrutinib 1L",
          dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },
        { title: "  Treated Patients with Acalabrutinib 1L Q4-2021",
        dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },
        
        {
            title: "  Trend",
            dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },

        {
            title: "  TRX",
            dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },



        
        {
          
      title: "  NPS",
            dataIndex: 'english',
          sorter: {
            compare: (a, b) => a.english - b.english,
            multiple: 1,
          },
          
        },
        
      ];
      const data = [
        {
          key: '1',
          name: 'Mayo Clinic',
          chinese: 98,
          math: 60,
          english: 70,
        },
        {
          key: '2',
          name: 'Texas Ontology ',
          chinese: 98,
          math: 66,
          english: 89,
        },
        {
          key: '3',
          name: 'University of Texas',
          chinese: 98,
          math: 90,
          english: 70,
        },
        {
          key: '4',
          name: 'HCA Health Inc.',
          chinese: 88,
          math: 99,
          english: 89,
        },
      ];
      
      const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
      };

      
  return (
    <div>
        
    <NavContent/>
    
<Table columns={columns} dataSource={data} onChange={onChange} />;
</div>
  )
}


export default Patients1LTable;