import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  .ant-avatar-lg {
      
  }
  .userInfoIcon {
    background-color: #7D1651;
    .anticon {
      vertical-align: middle;
    }
  }
`;
export const User = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 20px;
`;

export const UserInfoName = styled.span`
  color: #000000;
  text-align: right;
  font-size: 14px;
`;

export const UserInfoDesignation = styled.span`
  color: #000000;
  font-size: 12px;
`;
