import { Divider, Image } from "antd";
import React from "react";
import AZLOGO from "../../assets/img/azbrain_logo_1.png";
import { BrandWrapper } from "./Brand.styles";

const Brand = () => (
  <BrandWrapper>
    <Image className="brandLogo" preview={false} width={120} src={AZLOGO} />
  </BrandWrapper>
);

export default Brand;
