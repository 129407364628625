import React, { useEffect } from "react";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import SunBurstChart from "../../../components/SunburstChart";
import config from "../../../config";
import { HealthCareDisparityInfoWrapper } from "./HealthCareDisparityInfo.styles";
import SiteMap from "../../../components/SiteMap/SiteMap";
import PhysicianList from "../../../components/PhysicianList/PhysicianList";

const HealthCareDisparityInfo = (props) => {
  const navigate = useNavigate();
  let { id, type } = useParams();
  var url = "";
  if (type == "county") {
    url = `${config.api.getDisparityCountyInfo}?fip=${id}`;
  } else {
    url = `${config.api.getDisparityHdInfo}?cluster=${id}`;
  }
  var _hdInfoinstance;
  const options = {
    onLoad: function () {
      console.log("[onLoad]");
      setTimeout(() => {
        _hdInfoinstance
          .open({
            id: "1",
            title: "HealthCareDisparityInfo",
            closable: false,
            panelComponent: (porps) => {
              return (
                <SunBurstChart
                  url={url}
                  selectedId={id}
                  dataType="hd"
                  showYears={false}
                  onInfoUpdate={() => {}}
                  onResult={(root) => {
                    /*physicianList.fetchPhysiciansListNew(
                    categoryId,
                    "accounts",
                    root.id,
                    accountType
                  );*/
                  }}
                  onSelect={(d) => {}}
                  primaryTabInstance={_hdInfoinstance}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });
        _hdInfoinstance
          .open({
            id: "2",
            title: "Physicians",
            closable: false,
            panelComponent: (porps) => {
              return (
                <PhysicianList
                  url={`${config.api.getPhysicians}?fipId=${id}`}
                  dataType="hcp"
                  onInfoUpdate={() => {}}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });

        // switch to tab
        _hdInfoinstance.select("1").then(() => {
          console.log("tab is selected");
        });
      }, 1000);
    },
    onInit: function () {
      console.log("[onInit]");
    },
  };

  const [
    HealthCareDisparityInfoTabList,
    HealthCareDisparityInfoPanelList,
    onHealthCareDisparityInfoReady,
  ] = useDynTabs(options);

  onHealthCareDisparityInfoReady((instance) => {
    _hdInfoinstance = instance;
  });

  useEffect(() => {
    //alert("!");
  }, []);

  return (
    <HealthCareDisparityInfoWrapper>
      <SiteMap />
      <div className="content">
        <HealthCareDisparityInfoTabList></HealthCareDisparityInfoTabList>
        <HealthCareDisparityInfoPanelList></HealthCareDisparityInfoPanelList>
      </div>
    </HealthCareDisparityInfoWrapper>
  );
};

export default HealthCareDisparityInfo;
