import styled from "styled-components";

export const AZBrainChartWrapper = styled.div`
  width: 100%;
  margin: 10px;
  background: white;
  border-radius: 7px;
  .content {
    padding: 10px;
  }
`;
