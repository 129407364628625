import React from "react";
import PropTypes from "prop-types";
import { SankeyWrapper } from "./Sankey.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as d3 from "d3";

const Sankey = (props) => {
  const { segtabid } = props;
  const _data = props.data;
  const loadData = () => {
    if (_data && _data.length > 0) {
      let margin = { top: 20, right: 300, bottom: 150, left: 50 },
        width = window.innerWidth - margin.left - margin.right,
        height = "600px";

      d3.select(`#nav-${segtabid}`)
        .style("width", width + margin.left + margin.right)
        .style("height", height);
      var data = _data;

      var nodesObj = {};
      var nodesInfoObj = {};
      var linksInfoObj = {};
      var linksArr = [];
      var nodesArr = [];
      var placeholderId = 300;
      data.nodeInfo.forEach((node, idx) => {
        nodesInfoObj[node.id] = node.patients;
      });
      data.linkInfo.forEach((link, idx) => {
        linksInfoObj[link.id] = link.patients;
      });

      data.nodes.forEach((node, idx) => {
        nodesObj[node.id] = node.name;
        var patients = nodesInfoObj[node.id];
        var total = 0;
        console.log(patients);
        if (patients) {
          total = patients.length;
        }
        node.total = total;
        var sourcePatients = 0;
        var targetPatients = 0;
        var isHavingTarget = false;
        data.links.forEach((link, idx) => {
          if (link.source == node.id) {
            sourcePatients += link.value;
            isHavingTarget = true;
          }
          if (link.target == node.id) {
            targetPatients += link.value;
          }
        });
        if (sourcePatients < total && targetPatients < total) {
          placeholderId = placeholderId + 1;
          data.links.push({
            source: node.id,
            target: placeholderId,
            value:
              targetPatients > sourcePatients
                ? total - targetPatients
                : total - sourcePatients,
            id: data.links.length + 1,
          });
          node.nodeSettings = {
            visible: true
          };
          node.labelSettings = { forceHidden: false };
          nodesArr.push(node);
          var nextNode = {
            id: placeholderId,
            name: "placeholder",
            description: "placeholder",
            labelSettings: { forceHidden: true },
            nodeSettings: {
              visible: false
            },
          };
          data.nodes.push(nextNode);
          nodesArr.push(nextNode);
        } else {
          node.labelSettings = { forceHidden: false };
          node.nodeSettings = {
            visible: true
          };
          nodesArr.push(node);
        }
      });

      data.links.forEach((link, idx) => {
        var linkObj = {
          id: link.id,
          from: nodesObj[link.source],
          to: nodesObj[link.target],
          source: link.source,
          target: link.target,
          value: link.value,
          fromNodePatients: nodesInfoObj[link.source],
          toNodePatients: nodesInfoObj[link.target],
          linkPatients: linksInfoObj[link.id],
        };
        if (link.target >= 300) {
          linkObj.linkSettings = {
            tooltipText: "",
            forceHidden: true,
            fill: am5.color(0x3e3e3e),
            fillOpacity: 0,
            fillStyle: "solid",
          };
        } else {
          linkObj.linkSettings = {
            tooltipText: "[bold]{from} -> {to}[/]\nPatients: [bold]{value}[/]",
            fill: am5.color(0x3e3e3e),
            fillOpacity: 0.1,
            forceHidden: false,
            fillStyle: "solid",
          };
        }

        linksArr.push(linkObj);
      });
      console.log("linksArr", linksArr);
      var root = am5.Root.new(`nav-${segtabid}`);

      root.setThemes([am5themes_Animated.new(root)]);

      var series = root.container.children.push(
        am5flow.Sankey.new(root, {
          sourceIdField: "source",
          targetIdField: "target",
          valueField: "value",
          idField: "id",
          paddingRight: 200,
          linkTension: 0,
          fillOpacity: 0.05,
          nodePadding: 20,
          nodeAlign: "center",
        })
      );
      series.nodes.rectangles.template.events.on("click", function (ev) {
        console.log("Clicked on a column", ev.target.dataItem.dataContext);
      });

      series.links.template.events.on("click", function (ev) {
        console.log("Clicked on a column", ev.target.dataItem.dataContext);
      });

      // highlight all links with the same id beginning
      series.links.template.events.on("pointerover", function (event) {
        var dataItem = event.target.dataItem;
        var id = dataItem.get("id");
        am5.array.each(series.dataItems, function (dataItem) {
          if (dataItem.get("id") == id) {
            dataItem.get("link").hover();
          }
        });
      });

      series.links.template.events.on("pointerout", function (event) {
        am5.array.each(series.dataItems, function (dataItem) {
          dataItem.get("link").unhover();
        });
      });

      series.nodes.rectangles.template.set("templateField", "nodeSettings");
      series.nodes.labels.template.set("templateField", "labelSettings");
      series.nodes.labels.template.setAll({
        fontSize: 16,
        maxWidth: 150,
        wrap: true,
        text: "[bold]{name}[/] ({total})",
      });
      series.links.template.set("templateField", "linkSettings");
      series.nodes.rectangles.template.setAll({
        tooltipText: "[bold]{name}[/]\nPatients: {total}",
      });

      series.nodes.setAll({
        idField: "id",
        nameField: "name",
        fillField: "fill",
      });

      series.nodes.data.setAll(nodesArr);
      series.data.setAll(linksArr);

      // Make stuff animate on load
      series.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <SankeyWrapper id={`nav-${segtabid}`}></SankeyWrapper>;
};

Sankey.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

Sankey.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default Sankey;
