import React from "react";
import PropTypes from "prop-types";
import { SiteMapWrapper } from "./SiteMap.styles";
import { Alert, Breadcrumb } from "antd";
import { HashRouter, Link, Route, Routes, useLocation } from "react-router-dom";

const SiteMap = (props) => {
  const breadcrumbNameMap = {
    "/": "Home",
    "/insights": "Insights",
    "/reporting": "Reporting",
    "/trending": "Trending",
    "/trending/home": "Trending - Home",
    "/trending/accounts": "Accounts",
    "/insights/home": "Home",
    "/insights/accounts": "Accounts",
    "/insights/accounts-info/:accountId": "Accounts",
    "/insights/accounts-info/:accountId/:type": "Accounts",
    "/insights/geo-distribution": "Geo Distribution",
    "/insights/geo-distribution-info": "Geo Distribution",
    "/insights/sales-regions": "Sales Regions",
    "/insights/healthcare-disparity": "Healthcare Disparity",
    "/reporting/hcp": "Hcp Reports",
    "/reporting/parent-accounts": "Parent Accounts Reports",
    "/reporting/child-accounts": "Child Accounts Reports",
  };
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    if (index < pathSnippets.length - 1) {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      if (
        pathSnippets.indexOf("accounts-info") > -1 ||
        pathSnippets.indexOf("geo-distribution-info") > -1 ||
        pathSnippets.indexOf("hd-info") > -1
      ) {
        if (index < 1) {
          return (
            <Breadcrumb.Item key={url}>
              <Link to={url}>{pathSnippets[index]}</Link>
            </Breadcrumb.Item>
          );
        }
        if (index == 1) {
          if (pathSnippets.indexOf("accounts-info") > -1) {
            return (
              <Breadcrumb.Item key={"/insights/accounts"}>
                <Link to={"/insights/accounts"}>Accounts</Link>
              </Breadcrumb.Item>
            );
          } else if (pathSnippets.indexOf("geo-distribution-info") > -1) {
            return (
              <Breadcrumb.Item key={"/insights/geo-distribution"}>
                <Link to={"/insights/geo-distribution"}>Geo Distribution</Link>
              </Breadcrumb.Item>
            );
          } else if (pathSnippets.indexOf("hd-info") > -1) {
            return (
              <Breadcrumb.Item key={"/insights/healthcare-disparity"}>
                <Link to={"/insights/healthcare-disparity"}>
                  Healthcare Disparity
                </Link>
              </Breadcrumb.Item>
            );
          }
        }
      } else if (pathSnippets.indexOf("reporting") > -1) {
        if (index < 0) {
          return (
            <Breadcrumb.Item key={url}>
              <Link to={url}>{pathSnippets[index]}</Link>
            </Breadcrumb.Item>
          );
        }
        if (index == 0) {
          
        }
        
      } else {
        return (
          <Breadcrumb.Item key={url}>
            <Link to={url}>{pathSnippets[index]}</Link>
          </Breadcrumb.Item>
        );
      }
    }
    if (index == pathSnippets.length - 1) {
      if (pathSnippets.indexOf("hcp") > -1) {
        return (
          <Breadcrumb.Item key={"/reporting/hcp"}>
            <Link to={"/reporting/hcp"}>Reporting - HCPs</Link>
          </Breadcrumb.Item>
        );
      } else if (pathSnippets.indexOf("parent-accounts") > -1) {
        return (
          <Breadcrumb.Item key={"/reporting/parent-accounts"}>
            <Link to={"/reporting/parent-accounts"}>Reporting - Parent Accounts </Link>
          </Breadcrumb.Item>
        );
      } else if (pathSnippets.indexOf("child-accounts") > -1) {
        return (
          <Breadcrumb.Item key={"/reporting/child-accounts"}>
            <Link to={"/reporting/child-accounts"}>Reporting - Child Accounts </Link>
          </Breadcrumb.Item>
        );
      } else {
        return (
          <Breadcrumb.Item key={location}>
            <Link to={location}>{pathSnippets[pathSnippets.length - 1].replaceAll("%20", " ")}</Link>
          </Breadcrumb.Item>
        );
      }
      
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <SiteMapWrapper>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </SiteMapWrapper>
  );
};

SiteMap.propTypes = {
  // bla: PropTypes.string,
};

SiteMap.defaultProps = {
  // bla: 'test',
};

export default SiteMap;
