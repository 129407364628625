import * as d3 from "d3";
//import words from "./words";
import d3Cloud from "d3-cloud";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import "d3-transition";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import { GetAPIRequest } from "../../services";
import { WordCloudWrapper } from "./WordCloud.styles";
import { Alert, Spin, Switch } from "antd";
import Select from "react-select";

const WordCloudView = (props) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState(null);
  const toggle = (checked) => {
    setLoading(checked);
  };
  const { url, containerId, onClick } = props;
  const [words, setWords] = useState([]);
  const willMount = useRef(true);
  const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

  var cloudWindowHeight, cloudWindowWidth;
  useEffect(() => {
    if (willMount.current) {
      willMount.current = false;

      GetAPIRequest({
        url: url,
      })
        .then((res) => {
          console.log(res);
          loadWords(res);
        })
        .catch(() => {});
    } else {
      console.log("Repeated load");
    }
  }, []);
  function loadDimensions() {
    cloudWindowWidth = window.innerWidth - 40;
    cloudWindowHeight = window.innerHeight + 100;
  }
  const loadWords = (data) => {
    var _dataa = [];
    var _accounts = [];
    data.map((d) => {
      _dataa.push({
        text: d.name,
        size: d.count,
      });
      _accounts.push({
        value: d.name,
        label: `${d.name} - (${d.count})`,
        size: d.count,
      });
    });
    setWords(_dataa);
    setAccounts(_accounts);
    loadDimensions();
    var height = cloudWindowHeight;
    var svg = d3
      .select(`#${containerId}`)
      .append("svg")
      .attr("viewBox", `0 0 ${cloudWindowWidth} ${cloudWindowHeight}`)
      .style("width", cloudWindowWidth)
      .style("height", cloudWindowHeight);

    var sizeScale = scaleLinear()
      .domain([
        0,
        d3.max(_dataa, function (d) {
          return d.size;
        }),
      ])
      .range([5, 65]);
    const draw = (words) => {
      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + cloudWindowWidth / 2 + "," + height / 2 + ")"
        ) // "translate(" + margin.left + "," + margin.top + ")"
        .selectAll("text")
        .data(words)
        .enter()
        .append("text")
        .attr("class", "wordMouseOver")
        .style("font-size", function (d) {
          return d.size + "px";
        })
        .style("font-family", "HelveticaNeue-Medium")
        .style("fill", (d) => {
          var wordIndex = words.findIndex((w) => w.text == d.text);
          if (wordIndex > 4) return "#888888";
          else {
            return "#830051";
          }
        })
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
        })
        .on("click", (event, d) => {
          onClick(d.text);
        })
        .on("dblclick", (d) => {})
        .text(function (d) {
          return d.text;
        });
    };

    d3Cloud()
      .size([cloudWindowWidth - 20, height]) // (width, height)
      .words(_dataa)
      .rotate(function () {
        return ~~(Math.random() * 2) * 90;
      })
      .padding(1)
      .font("HelveticaNeue-Medium")
      .fontSize(function (d) {
        return sizeScale(d.size);
      })
      .on("end", (output) => {
        draw(output);
        setLoading(false);
      })
      .start();
  };
  const Option = (props) => {
    const {
      children,
      className,
      cx,
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
    } = props;
    return (
      <div
        ref={innerRef}
        css={getStyles("option", props)}
        className={cx(
          {
            option: true,
            "option--is-disabled": isDisabled,
            "option--is-focused": isFocused,
            "option--is-selected": isSelected,
          },
          className
        )}
        style={{ padding: "5px 5px", fontSize: 12 }}
        {...innerProps}
      >
        {children}
      </div>
    );
  };
  return (
    <WordCloudWrapper>
      <Spin spinning={loading}>
        <div className="searchContainer">
          <Select
            isSearchable={true}
            isMulti={false}
            isClearable={true}
            styles={{
              menu: (provided, state) => ({
                ...provided,
                width: state.selectProps.width,
                borderBottom: "1px dotted pink",
                color: state.selectProps.menuColor,
                padding: 20,
                margin: "10px 10px",
                width: "100%",
              }),
              option: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                color: state.isSelected ? "red" : "blue",
                padding: 20,
              }),
            }}
            placeholder="Search accounts"
            noOptionsMessage="No accounts found"
            value={selectedAccounts}
            onChange={(d) => {
              setSelectedAccounts(d.value);
              console.log(d.value);
              onClick(d.value);
            }}
            className="selContainer"
            components={{ Option }}
            options={accounts}
          />
        </div>
        <div
          style={{ width: window.innerWidth - 40, margin: "10px 0px 60px" }}
          id={containerId}
        ></div>
      </Spin>
    </WordCloudWrapper>
  );
};

WordCloudView.propTypes = {
  url: PropTypes.string,
  containerId: PropTypes.string,
  onClick: PropTypes.func,
};

WordCloudView.defaultProps = {
  url: "",
  containerId: "",
  onClick: () => {},
};

export default WordCloudView;
