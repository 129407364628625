import styled from "styled-components";

export const SecondaryTabWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 7px;
  margin-top: 20px;
  font-family: "HelveticaNeueMedium";
  .content {
    .rc-dyn-tabs-tablist {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border: 0px solid #ffffff;
      position: sticky;
      top: 0px;
      left: 0px;
      right: 0px;
      padding: 10px 0px;
      .rc-dyn-tabs-tab {
        background-color: #f1f1f1;
        border: 1px solid #cccccc;
        color: #666666;
        background: #ffffff;
        z-index: 11;
        
      }
      .rc-dyn-tabs-panel{
        width: 100%;
      }
      .rc-dyn-tabs-title {
        min-height: 35px;
        padding: 0px 20px;
        font-size: 15px;
      }
      .rc-dyn-tabs-selected{
        .rc-dyn-tabs-title {
            color: #000000;
            background-color: #f1f1f1;
            border: 1px solid #eeeeee !important;
            opacity: 1;
          }
      }
      
    }
  }
`;
