import React from "react";
import PropTypes from "prop-types";
import { StackedClusteredBarWrapper } from "./StackedClusteredBar.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const StackedClusteredBar = (props) => {
  const { data, segtabid } = props;
  let root = null;
  const loadData = () => {
    if (data && data.length > 0) {
      if (root) {
        root.dispose();
      }
      root = am5.Root.new(`nav-${segtabid}`);
      var chartdata = [];
      if (data) {
        data.map((_data1, i) => {
          if (_data1.data) {
            _data1.data.map((_data2) => {
              if (_data2.values) {
                _data2.values.map((_data3) => {
                  var isAvailable = false;
                  data.map((d) => {
                    if (d.brand === `${_data2.name} - ${_data3.name}`) {
                      if (d[`${_data1.name}`]) {
                      } else {
                        d[`${_data1.name}`] = _data3.count;
                      }
                      isAvailable = true;
                    }
                  });
                  if (!isAvailable) {
                    var dataObj = {};
                    dataObj.brand = `${_data2.name} - ${_data3.name}`;
                    if (dataObj[`${_data1.name}`]) {
                    } else {
                      dataObj[`${_data1.name}`] = _data3.count;
                    }
                    chartdata.push(dataObj);
                  }
                });
              }
            });
          }
        });
      }
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 30,
          marginBottom: 15,
        })
      );

      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "brand",
          renderer: am5xy.AxisRendererY.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      yAxis.data.setAll(chartdata);

      var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          extraMax: 0.3,
          strictMinMax: true,
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      function makeSeries(name, fieldName, stacked) {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: fieldName,
            categoryYField: "brand",
          })
        );

        series.columns.template.setAll({
          tooltipText: "{name} : {valueX}",
          height: am5.percent(100),
          fillOpacity: 1.0,
        });
        series.data.setAll(chartdata);

        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              centerY: am5.p50,
              text: "{valueX}",
              populateText: true,
              fontSize: 12,
            }),
          });
        });
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              centerX: am5.p100,
              centerY: am5.p50,
              text: "{name}",
              fill: am5.color(0xffffff),
              populateText: true,
              fontSize: 10,
            }),
          });
        });

        legend.data.push(series);
      }
      var prevItem = "";
      for (const [key, value] of Object.entries(data[0])) {
        if (key !== "brand") {
          var currItem = key.split(" - ")[0];
          if (currItem !== prevItem) {
            prevItem = currItem;
            makeSeries(key, key, false);
          } else {
            makeSeries(key, key, false);
          }
        }
      }

      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );

      chart.set(
        "scrollbarY",
        am5.Scrollbar.new(root, {
          orientation: "vertical",
        })
      );

      chart.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <StackedClusteredBarWrapper
      id={`nav-${segtabid}`}
    ></StackedClusteredBarWrapper>
  );
};

StackedClusteredBar.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

StackedClusteredBar.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default StackedClusteredBar;
