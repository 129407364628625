import styled from "styled-components";

export const AccountsInfoWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 7px;
  flex-direction:column;
  .searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 10px 30px;
  }
  .selContainer{
      width: 100%;

  }
  .content {
    width: 100%;
    .rc-dyn-tabs-tablist {
        padding-top: 10px;
        position: sticky;
        top: 0px;
        left: 0px;
        background: #ffffff;
        border-color: #eeeeee;
    }
    #svgroot {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .wheel-title {
      width: 100%;
      margin: 30px 0px;
      padding: 10px;
      text-align: left;
      font-family: "HelveticaNeue-Medium" !important;
      font-size: 20px !important;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
    }
    .wheel-comment {
      text-align: center;
      font-family: "HelveticaNeue" !important;
      font-size: 14px !important;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 0px 0px 30px;
      width: 100%;
    }
  }
`;
