export default {
  name: "NSCLC",
  size: 153589,
  children: [
    {
      name: "STAGE 4",
      size: 60583,
      id: "nsclc:G:3:WHEEL:I:INCtumor:NSCLC:STAGE 4:STAGE 4:2020",
      children: [
        {
          name: "Other Biomarker Test",
          size: 4775,
          key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
          nuuid: "NSCLC,STAGE 4,Other Biomarker Test",
          children: [
            {
              name: "OTHERS_THERAPY",
              size: 2090,
              comment: "OTHERS_THERAPY",
              children: [
                {
                  name: "RADIATION",
                  size: 1025,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:RADIATION",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 210,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 59,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 14,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,SURGERY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 113,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 52,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY,ALECENSA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TAGRISSO,CRIZOTINIB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,OPDIVO,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "IMFINZI",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,IMFINZI,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 24,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,SURGERY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 7,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,ERBITUX",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ALECENSA,BRIGATINIB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,GILOTRIF",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OTHERS",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OTHERS,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "VIZIMPRO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:VIZIMPRO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,OTHERS,VIZIMPRO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TECENTRIQ",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,LORLATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BRIGATINIB",
                      children: [
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,BRIGATINIB,LORLATINIB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CAPMATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,DABRAFENIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,RETEVMO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,LUMAKRAS",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,CYRAMZA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TARCEVA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TARCEVA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,TARCEVA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:YERVOY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,YERVOY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,RADIATION,YERVOY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "RADIATION",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 738,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 69,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 93,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 13,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "ERBITUX",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:ERBITUX:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,ERBITUX",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO",
                              children: [
                                {
                                  name: "SURGERY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,GILOTRIF",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 50,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 18,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 21,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 7,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "TECENTRIQ+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TEMOZOLOMIDE",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,TEMOZOLOMIDE",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO,GILOTRIF",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 30,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "RADIATION",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,ERBITUX",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "IMFINZI",
                      size: 73,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 16,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "OTHERS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:OTHERS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,OTHERS",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                                  children: [
                                    {
                                      name: "YERVOY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:YERVOY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO,YERVOY",
                                      children: [],
                                      comment:
                                        "All Biomarker tested patients expect EGFR",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                      children: [
                                        {
                                          name: "Wait & Watch",
                                          size: 1,
                                          key:
                                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                          id:
                                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                          nuuid:
                                            "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                          children: [],
                                          comment:
                                            "Wait and Watch on patients after finishing a therapy",
                                        },
                                      ],
                                      comment:
                                        "All Biomarker tested patients expect EGFR",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OTHERS",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ALECENSA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OTHERS",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,ALECENSA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BAVENCIO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BAVENCIO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,CRIZOTINIB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CHEMOTHERAPY,GILOTRIF+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "CHEMOTHERAPY",
                },
                {
                  name: "SURGERY",
                  size: 181,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:SURGERY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 7,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 26,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 12,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 20,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 7,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,RADIATION,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,TAGRISSO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OPDIVO",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OPDIVO,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OPDIVO,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,SURGERY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,ALECENSA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OTHERS",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB,RADIATION",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,DABRAFENIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GAVRETO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,SURGERY,GAVRETO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "SURGERY",
                },
                {
                  name: "OTHERS",
                  size: 133,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:OTHERS",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 26,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 5,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,KEYTRUDA,TECENTRIQ",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB",
                              children: [
                                {
                                  name: "KEYTRUDA",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:KEYTRUDA:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB,KEYTRUDA",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,RADIATION,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,TAGRISSO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,OPDIVO,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,OPDIVO,TECENTRIQ",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,SURGERY",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,SURGERY,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,ALECENSA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,ERBITUX",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,TECENTRIQ",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,OTHERS,DABRAFENIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "OTHERS",
                },
                {
                  name: "CRT",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CRT",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CRT",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CRT,KEYTRUDA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CRT,SURGERY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CRT,SURGERY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "CRT",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 3,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "CYRAMZA+CHEMOTHERAPY",
                },
                {
                  name: "CYRAMZA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CYRAMZA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,CYRAMZA",
                  children: [],
                  comment: "CYRAMZA",
                },
                {
                  name: "TEMOZOLOMIDE",
                  size: 1,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TEMOZOLOMIDE",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TEMOZOLOMIDE:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY,TEMOZOLOMIDE",
                  children: [],
                  comment: "TEMOZOLOMIDE",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,OTHERS_THERAPY",
            },
            {
              name: "IO",
              size: 1635,
              comment: "IO",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 961,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 105,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 20,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO,LUMAKRAS",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,LUMAKRAS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 16,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 22,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,ERLOTINIB_HCL",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "IMFINZI",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 70,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "CAPMATINIB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:CAPMATINIB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB,CAPMATINIB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERBITUX",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,LORLATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RETEVMO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GAVRETO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,GAVRETO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "KEYTRUDA",
                  size: 432,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:KEYTRUDA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 30,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 55,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,RADIATION,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,OTHERS",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION",
                              children: [
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 12,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,BRIGATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,DABRAFENIB",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,DABRAFENIB,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,KEYTRUDA,LUMAKRAS",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 172,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:OPDIVO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,RADIATION,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,RADIATION,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,TAGRISSO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,SURGERY,RADIATION",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "OPDIVO",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 34,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "TECENTRIQ+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 15,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:OPDIVO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TECENTRIQ",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,TECENTRIQ,RADIATION",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "TECENTRIQ",
                },
                {
                  name: "YERVOY",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:YERVOY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:YERVOY:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,YERVOY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,YERVOY,KEYTRUDA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,YERVOY,OPDIVO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "YERVOY",
                },
                {
                  name: "IMFINZI",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:IMFINZI",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "IMFINZI",
                },
                {
                  name: "BAVENCIO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:BAVENCIO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:BAVENCIO:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO,BAVENCIO",
                  children: [],
                  comment: "BAVENCIO",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 1,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:IMFINZI+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,IO,IMFINZI+CHEMOTHERAPY",
                  children: [],
                  comment: "IMFINZI+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,IO",
            },
            {
              name: "TKI",
              size: 446,
              comment: "TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 201,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TAGRISSO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "GILOTRIF",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,ERLOTINIB_HCL",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,KEYTRUDA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,OPDIVO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,SURGERY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,BEVACIZUMAB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 13,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,GILOTRIF",
                      children: [
                        {
                          name: "IRESSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:IRESSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,GILOTRIF,IRESSA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,ERLOTINIB_HCL",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "IRESSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IRESSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:IRESSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,IRESSA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "VIZIMPRO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:VIZIMPRO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:VIZIMPRO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO,VIZIMPRO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "TAGRISSO",
                },
                {
                  name: "OTHER_TKI",
                  size: 238,
                  comment: "OTHER_TKI",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 125,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:BEVACIZUMAB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "TAGRISSO",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 11,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "RADIATION",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "IMFINZI",
                              size: 6,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "IMFINZI+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 2,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                                      children: [],
                                      comment:
                                        "All Biomarker tested patients expect EGFR",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 14,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "CYRAMZA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CYRAMZA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CYRAMZA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 8,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO",
                          children: [
                            {
                              name: "TAGRISSO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "IMFINZI",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "BEVACIZUMAB",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 89,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "GILOTRIF",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:GILOTRIF:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION,GILOTRIF",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB",
                              children: [
                                {
                                  name: "LORLATINIB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:LORLATINIB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB,LORLATINIB",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RETEVMO",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "ERBITUX",
                      size: 10,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:ERBITUX",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,KEYTRUDA,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,RADIATION,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,RADIATION,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All Biomarker tested patients expect EGFR",
                                },
                              ],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "ERBITUX",
                    },
                    {
                      name: "GILOTRIF",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:GILOTRIF",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,GILOTRIF",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,GILOTRIF,KEYTRUDA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "TAGRISSO",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,GILOTRIF,TAGRISSO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,GILOTRIF,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ALECENSA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,GILOTRIF,ALECENSA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "GILOTRIF",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:ERLOTINIB_HCL",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL",
                      children: [],
                      comment: "ERLOTINIB_HCL",
                    },
                    {
                      name: "TARCEVA",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TARCEVA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,TARCEVA",
                      children: [],
                      comment: "TARCEVA",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO,KEYTRUDA",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "ERBITUX+CHEMOTHERAPY",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,TKI,OTHER_TKI",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 7,
                  key:
                    "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,TAGRISSO+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,TKI",
            },
            {
              name: "ALK",
              size: 57,
              comment: "ALK",
              children: [
                {
                  name: "ALECENSA",
                  size: 53,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:ALECENSA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA",
                  children: [
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,ALECENSA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,TAGRISSO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "SURGERY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,ALECENSA,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,BEVACIZUMAB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LORLATINIB",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,ALECENSA,LORLATINIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB,SURGERY",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB,CRIZOTINIB",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All Biomarker tested patients expect EGFR",
                            },
                          ],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                        {
                          name: "ALECENSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,LORLATINIB,ALECENSA",
                          children: [],
                          comment: "All Biomarker tested patients expect EGFR",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,BRIGATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,CRIZOTINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "ALECENSA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:ALECENSA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ALK,ALECENSA,ALECENSA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "ALECENSA",
                },
                {
                  name: "BRIGATINIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:BRIGATINIB",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:BRIGATINIB:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ALK,BRIGATINIB",
                  children: [],
                  comment: "BRIGATINIB",
                },
                {
                  name: "LORLATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:LORLATINIB",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:LORLATINIB:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ALK,LORLATINIB",
                  children: [],
                  comment: "LORLATINIB",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ALK",
            },
            {
              name: "BRAF",
              size: 18,
              comment: "BRAF",
              children: [
                {
                  name: "DABRAFENIB",
                  size: 18,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:DABRAFENIB",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,BRAF,DABRAFENIB",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,BRAF,DABRAFENIB,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,BRAF,DABRAFENIB,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,DABRAFENIB,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,BRAF,DABRAFENIB,GILOTRIF",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "VEMURAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:VEMURAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:VEMURAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,BRAF,DABRAFENIB,VEMURAFENIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "DABRAFENIB",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,BRAF",
            },
            {
              name: "ROS1",
              size: 9,
              comment: "ROS1",
              children: [
                {
                  name: "CRIZOTINIB",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CRIZOTINIB",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,KEYTRUDA",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CRIZOTINIB,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,OPDIVO",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "LORLATINIB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,LORLATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,ROS1,CRIZOTINIB,CAPMATINIB",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "CRIZOTINIB",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,ROS1",
            },
            {
              name: "RET",
              size: 7,
              comment: "RET",
              children: [
                {
                  name: "RETEVMO",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:RETEVMO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:RETEVMO:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,RET,RETEVMO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RETEVMO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,RET,RETEVMO,CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "RETEVMO",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,RET",
            },
            {
              name: "MET",
              size: 5,
              comment: "MET",
              children: [
                {
                  name: "CAPMATINIB",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:CAPMATINIB",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,MET,CAPMATINIB",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,MET,CAPMATINIB,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,Other Biomarker Test,MET,CAPMATINIB,CHEMOTHERAPY",
                      children: [],
                      comment: "All Biomarker tested patients expect EGFR",
                    },
                  ],
                  comment: "CAPMATINIB",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,MET",
            },
            {
              name: "KRAS",
              size: 3,
              comment: "KRAS",
              children: [
                {
                  name: "LUMAKRAS",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:LUMAKRAS",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 4,Other Biomarker Test,KRAS,LUMAKRAS",
                  children: [],
                  comment: "LUMAKRAS",
                },
              ],
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,KRAS",
            },
            {
              name: "UNTREATED",
              size: 505,
              key: "INCtumor:NSCLC:STAGE 4:Other Biomarker Test:UNTREATED",
              id: "nsclc:G:5:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
              nuuid: "NSCLC,STAGE 4,Other Biomarker Test,UNTREATED",
              children: [],
              comment: "UNTREATED PATIENTS",
            },
          ],
          comment: "All Biomarker tested patients expect EGFR",
        },
        {
          name: "PD-L1 TEST",
          size: 11129,
          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
          nuuid: "NSCLC,STAGE 4,PD-L1 TEST",
          children: [
            {
              name: "OTHERS_THERAPY",
              size: 5664,
              comment: "OTHERS_THERAPY",
              children: [
                {
                  name: "RADIATION",
                  size: 2876,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 198,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 184,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 65,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,SURGERY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 278,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 56,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,OPDIVO,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 95,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 62,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,SURGERY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "IMFINZI",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,IMFINZI,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 21,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 44,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY,ALECENSA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,CRIZOTINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 16,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,ERBITUX",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OTHERS",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OTHERS,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "VIZIMPRO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:VIZIMPRO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OTHERS,VIZIMPRO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,DABRAFENIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,GILOTRIF",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ALECENSA",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ALECENSA,BRIGATINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERLOTINIB_HCL",
                      children: [
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERLOTINIB_HCL,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CAPMATINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BAVENCIO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BAVENCIO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,BAVENCIO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,CRIZOTINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GAVRETO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,RADIATION,GAVRETO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "RADIATION",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1883,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 240,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 13,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "ERBITUX",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:ERBITUX:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,ERBITUX",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO",
                              children: [
                                {
                                  name: "SURGERY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 124,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 241,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 124,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 21,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 7,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "TECENTRIQ+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TEMOZOLOMIDE",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,TEMOZOLOMIDE",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 60,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "RADIATION",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,ERBITUX",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 18,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "IMFINZI",
                      size: 128,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 30,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 48,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "OTHERS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:OTHERS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,OTHERS",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                                  children: [
                                    {
                                      name: "YERVOY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:YERVOY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO,YERVOY",
                                      children: [],
                                      comment:
                                        "All IHC tested patients except EGFR and IHC",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 11,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 27,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                      children: [
                                        {
                                          name: "Wait & Watch",
                                          size: 1,
                                          key:
                                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                          id:
                                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                          nuuid:
                                            "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                          children: [],
                                          comment:
                                            "Wait and Watch on patients after finishing a therapy",
                                        },
                                      ],
                                      comment:
                                        "All IHC tested patients except EGFR and IHC",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 20,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OTHERS",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 16,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,DABRAFENIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BAVENCIO",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BAVENCIO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:YERVOY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:YERVOY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,YERVOY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TEMOZOLOMIDE",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TEMOZOLOMIDE",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,TEMOZOLOMIDE",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CRT",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRT",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,CRT",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "VITRAKVI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:VITRAKVI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:VITRAKVI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CHEMOTHERAPY,VITRAKVI",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "CHEMOTHERAPY",
                },
                {
                  name: "SURGERY",
                  size: 492,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 20,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 35,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 18,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 43,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 20,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 16,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OPDIVO",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OPDIVO,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OPDIVO,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,IMFINZI",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,TAGRISSO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,SURGERY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OTHERS",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB,RADIATION",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,ERBITUX",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,ALECENSA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,CRIZOTINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RETEVMO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,RETEVMO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:YERVOY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:YERVOY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,SURGERY,YERVOY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "SURGERY",
                },
                {
                  name: "OTHERS",
                  size: 390,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 21,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,TECENTRIQ",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 66,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 14,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 5,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 32,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 30,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 16,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB",
                              children: [
                                {
                                  name: "KEYTRUDA",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:KEYTRUDA:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB,KEYTRUDA",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,RADIATION,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,OPDIVO,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,OPDIVO,TECENTRIQ",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,TAGRISSO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,DABRAFENIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,GILOTRIF",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,ALECENSA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,OTHERS,ERBITUX+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "OTHERS",
                },
                {
                  name: "CRT",
                  size: 20,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRT",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CRT",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CRT,KEYTRUDA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CRT,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 11,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CRT,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CRT,SURGERY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "CRT",
                },
                {
                  name: "CYRAMZA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CYRAMZA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CYRAMZA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CYRAMZA,CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "CYRAMZA",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "CYRAMZA+CHEMOTHERAPY",
                },
                {
                  name: "TEMOZOLOMIDE",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TEMOZOLOMIDE",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TEMOZOLOMIDE:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,TEMOZOLOMIDE",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TEMOZOLOMIDE:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY,TEMOZOLOMIDE,BEVACIZUMAB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "TEMOZOLOMIDE",
                },
              ],
              nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHERS_THERAPY",
            },
            {
              name: "IO",
              size: 2916,
              comment: "IO",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1623,
                  key:
                    "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 123,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 12,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO,LUMAKRAS",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,LUMAKRAS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 34,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 12,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 76,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ALECENSA",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "CAPMATINIB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:CAPMATINIB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB,CAPMATINIB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:LUMAKRAS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RETEVMO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "KEYTRUDA",
                  size: 821,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 65,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 17,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 29,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 19,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 7,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,OTHERS",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION",
                              children: [
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,SURGERY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OTHERS",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:ERBITUX:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,ERBITUX",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,DABRAFENIB",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,DABRAFENIB,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CAPMATINIB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CAPMATINIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CRIZOTINIB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CRIZOTINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CAPMATINIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,KEYTRUDA,CAPMATINIB+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 292,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,RADIATION,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,DABRAFENIB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,DABRAFENIB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,DABRAFENIB,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,DABRAFENIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,OPDIVO,DABRAFENIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:YERVOY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:YERVOY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO,YERVOY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "OPDIVO",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 76,
                  key:
                    "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,TECENTRIQ+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OTHERS",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ+CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "TECENTRIQ+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ",
                  size: 49,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ,RADIATION",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:LUMAKRAS:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,TECENTRIQ,LUMAKRAS",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "TECENTRIQ",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 31,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,OPDIVO+CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "YERVOY",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:YERVOY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:YERVOY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,YERVOY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,YERVOY,KEYTRUDA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,YERVOY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "OPDIVO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,YERVOY,OPDIVO",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,YERVOY,TECENTRIQ",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "YERVOY",
                },
                {
                  name: "IMFINZI",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,IMFINZI,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "IMFINZI",
                },
                {
                  name: "BAVENCIO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BAVENCIO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:BAVENCIO:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,BAVENCIO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:BAVENCIO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,BAVENCIO,KEYTRUDA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:BAVENCIO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,BAVENCIO,RADIATION",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "BAVENCIO",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,IO,IMFINZI+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "IMFINZI+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,PD-L1 TEST,IO",
            },
            {
              name: "TKI",
              size: 901,
              comment: "TKI",
              children: [
                {
                  name: "OTHER_TKI",
                  size: 562,
                  comment: "OTHER_TKI",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 347,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 29,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 2,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                                      children: [],
                                      comment:
                                        "All IHC tested patients except EGFR and IHC",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 32,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 9,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "RADIATION",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "IMFINZI",
                              size: 6,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "IMFINZI+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 23,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "TAGRISSO",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 45,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 32,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "CYRAMZA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CYRAMZA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CYRAMZA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 5,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO",
                          children: [
                            {
                              name: "TAGRISSO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,DABRAFENIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB,CAPMATINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "BEVACIZUMAB",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 131,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 8,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "GILOTRIF",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:GILOTRIF:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION,GILOTRIF",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB",
                              children: [
                                {
                                  name: "LORLATINIB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:LORLATINIB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB,LORLATINIB",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 9,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 7,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "IMFINZI+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IMFINZI+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "ERBITUX",
                      size: 43,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 10,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,KEYTRUDA,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,RADIATION,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,RADIATION,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 5,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "ERBITUX",
                    },
                    {
                      name: "GILOTRIF",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,GILOTRIF",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,GILOTRIF,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,GILOTRIF,TAGRISSO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,GILOTRIF,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "GILOTRIF",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 15,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO,KEYTRUDA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,ERBITUX+CHEMOTHERAPY,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "ERBITUX+CHEMOTHERAPY",
                    },
                    {
                      name: "IRESSA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:IRESSA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:IRESSA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,IRESSA",
                      children: [
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IRESSA:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,IRESSA,TAGRISSO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "IRESSA",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,ERLOTINIB_HCL",
                      children: [],
                      comment: "ERLOTINIB_HCL",
                    },
                    {
                      name: "TARCEVA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TARCEVA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:TARCEVA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,TARCEVA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,TARCEVA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,TARCEVA,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:TARCEVA:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,TARCEVA,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "TARCEVA",
                    },
                    {
                      name: "PORTRAZZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:PORTRAZZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:PORTRAZZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,PORTRAZZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:PORTRAZZA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,PORTRAZZA+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "PORTRAZZA+CHEMOTHERAPY",
                    },
                    {
                      name: "VIZIMPRO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:VIZIMPRO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VIZIMPRO:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI,VIZIMPRO",
                      children: [],
                      comment: "VIZIMPRO",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,OTHER_TKI",
                },
                {
                  name: "TAGRISSO",
                  size: 334,
                  key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,KEYTRUDA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "GILOTRIF",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CHEMOTHERAPY,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "SURGERY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "GILOTRIF",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,GILOTRIF",
                      children: [
                        {
                          name: "IRESSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:IRESSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,GILOTRIF,IRESSA",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,ERLOTINIB_HCL",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "TARCEVA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TARCEVA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TARCEVA:2020",
                      nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO,TARCEVA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "TAGRISSO",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 5,
                  key:
                    "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TAGRISSO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO+CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,TKI,TAGRISSO+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All IHC tested patients except EGFR and IHC",
                    },
                  ],
                  comment: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,PD-L1 TEST,TKI",
            },
            {
              name: "OTHER_TARGETED_THERAPY",
              size: 144,
              comment: "OTHER_TARGETED_THERAPY",
              children: [
                {
                  name: "ALK",
                  size: 76,
                  comment: "ALK",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 71,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,CRIZOTINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,RETEVMO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TEMOZOLOMIDE",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TEMOZOLOMIDE",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:TEMOZOLOMIDE:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,TEMOZOLOMIDE",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "ALECENSA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:ALECENSA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,ALECENSA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,BRIGATINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ALECENSA,LORLATINIB,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,SURGERY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All IHC tested patients except EGFR and IHC",
                                },
                              ],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,ALECENSA",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "ALECENSA",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:BRIGATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,BRIGATINIB",
                      children: [],
                      comment: "BRIGATINIB",
                    },
                    {
                      name: "ALECENSA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ALECENSA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA+CHEMOTHERAPY,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "ALECENSA+CHEMOTHERAPY",
                    },
                    {
                      name: "ZYKADIA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:ZYKADIA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ZYKADIA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK,ZYKADIA",
                      children: [],
                      comment: "ZYKADIA",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ALK",
                },
                {
                  name: "BRAF",
                  size: 28,
                  comment: "BRAF",
                  children: [
                    {
                      name: "DABRAFENIB",
                      size: 27,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:DABRAFENIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All IHC tested patients except EGFR and IHC",
                            },
                          ],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "VEMURAFENIB",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:VEMURAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:VEMURAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,VEMURAFENIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "DABRAFENIB",
                    },
                    {
                      name: "VEMURAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:VEMURAFENIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VEMURAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF,VEMURAFENIB",
                      children: [],
                      comment: "VEMURAFENIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,BRAF",
                },
                {
                  name: "MET",
                  size: 15,
                  comment: "MET",
                  children: [
                    {
                      name: "CAPMATINIB",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,RADIATION",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "CAPMATINIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,MET",
                },
                {
                  name: "ROS1",
                  size: 15,
                  comment: "ROS1",
                  children: [
                    {
                      name: "CRIZOTINIB",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CRIZOTINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,OPDIVO",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,CAPMATINIB",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "CRIZOTINIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,ROS1",
                },
                {
                  name: "RET",
                  size: 7,
                  comment: "RET",
                  children: [
                    {
                      name: "RETEVMO",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:RETEVMO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,RET,RETEVMO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RETEVMO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,RET,RETEVMO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All IHC tested patients except EGFR and IHC",
                        },
                      ],
                      comment: "RETEVMO",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,RET",
                },
                {
                  name: "KRAS",
                  size: 2,
                  comment: "KRAS",
                  children: [
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:LUMAKRAS",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,KRAS,LUMAKRAS",
                      children: [],
                      comment: "LUMAKRAS",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,KRAS",
                },
                {
                  name: "NTRK",
                  size: 1,
                  comment: "NTRK",
                  children: [
                    {
                      name: "VITRAKVI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:VITRAKVI",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VITRAKVI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,NTRK,VITRAKVI",
                      children: [],
                      comment: "VITRAKVI",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY,NTRK",
                },
              ],
              nuuid: "NSCLC,STAGE 4,PD-L1 TEST,OTHER_TARGETED_THERAPY",
            },
            {
              name: "UNTREATED",
              size: 1504,
              key: "INCtumor:NSCLC:STAGE 4:PD-L1 TEST:UNTREATED",
              id: "nsclc:G:5:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
              nuuid: "NSCLC,STAGE 4,PD-L1 TEST,UNTREATED",
              children: [],
              comment: "UNTREATED PATIENTS",
            },
          ],
          comment: "All IHC tested patients except EGFR and IHC",
        },
        {
          name: "EGFR TEST",
          size: 5285,
          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
          nuuid: "NSCLC,STAGE 4,EGFR TEST",
          children: [
            {
              name: "IO",
              size: 1909,
              comment: "IO",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1212,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 105,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 20,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO,LUMAKRAS",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,LUMAKRAS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 16,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 22,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,ERLOTINIB_HCL",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 70,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "CAPMATINIB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:CAPMATINIB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB,CAPMATINIB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,LORLATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RETEVMO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GAVRETO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,GAVRETO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "KEYTRUDA",
                  size: 513,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 30,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 55,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,RADIATION,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION",
                              children: [
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION,OTHERS",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 12,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BRIGATINIB:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,BRIGATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,DABRAFENIB",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,DABRAFENIB,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:LUMAKRAS:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,KEYTRUDA,LUMAKRAS",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 109,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,RADIATION,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,RADIATION,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,TAGRISSO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,SURGERY,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "OPDIVO",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 41,
                  key:
                    "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "TECENTRIQ+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 17,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,OPDIVO+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "IMFINZI",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,IMFINZI",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,IMFINZI,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,IO,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,IO,IMFINZI,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "IMFINZI",
                },
                {
                  name: "TECENTRIQ",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,TECENTRIQ,RADIATION",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "TECENTRIQ",
                },
                {
                  name: "YERVOY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:YERVOY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:YERVOY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,YERVOY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,YERVOY,KEYTRUDA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,IO,YERVOY,OPDIVO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "YERVOY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,EGFR TEST,IO",
            },
            {
              name: "OTHERS_THERAPY",
              size: 2192,
              comment: "OTHERS_THERAPY",
              children: [
                {
                  name: "RADIATION",
                  size: 1152,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 210,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 59,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 14,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OPDIVO,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,ERBITUX,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CHEMOTHERAPY,SURGERY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 113,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,KEYTRUDA,CAPMATINIB,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 52,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY,ALECENSA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TAGRISSO,CRIZOTINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,OPDIVO,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,IMFINZI,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 24,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,SURGERY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX,CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 7,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BEVACIZUMAB,ERBITUX",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ALECENSA,BRIGATINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ERBITUX,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,GILOTRIF",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OTHERS",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OTHERS,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "VIZIMPRO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:VIZIMPRO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,OTHERS,VIZIMPRO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TECENTRIQ",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,LORLATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BRIGATINIB",
                      children: [
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,BRIGATINIB,LORLATINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CAPMATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CRIZOTINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,DABRAFENIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,RETEVMO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,LUMAKRAS",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,CYRAMZA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TARCEVA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TARCEVA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,TARCEVA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:YERVOY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,YERVOY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,RADIATION,YERVOY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "RADIATION",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 706,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 69,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 93,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 13,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "ERBITUX",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:ERBITUX:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,ERBITUX",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO",
                              children: [
                                {
                                  name: "SURGERY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,KEYTRUDA,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 50,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 18,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 21,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 7,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "TECENTRIQ+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TEMOZOLOMIDE",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,TEMOZOLOMIDE",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RADIATION,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,TAGRISSO,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 30,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "RADIATION",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO,ERBITUX",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 73,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 16,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,IMFINZI,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,SURGERY,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "OTHERS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:OTHERS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,OTHERS",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                                  children: [
                                    {
                                      name: "YERVOY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:YERVOY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO,YERVOY",
                                      children: [],
                                      comment: "All EGFR tested patients",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                      children: [
                                        {
                                          name: "Wait & Watch",
                                          size: 1,
                                          key:
                                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                          id:
                                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                          nuuid:
                                            "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                          children: [],
                                          comment:
                                            "Wait and Watch on patients after finishing a therapy",
                                        },
                                      ],
                                      comment: "All EGFR tested patients",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OTHERS",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ALECENSA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OTHERS",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,OTHERS,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,TECENTRIQ",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,RETEVMO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,ALECENSA+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BAVENCIO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BAVENCIO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,BAVENCIO,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,CRIZOTINIB+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GILOTRIF+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CHEMOTHERAPY,GILOTRIF+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "CHEMOTHERAPY",
                },
                {
                  name: "SURGERY",
                  size: 188,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 7,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 26,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 12,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 20,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 7,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,RADIATION,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,TAGRISSO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OPDIVO",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OPDIVO,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OPDIVO,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,SURGERY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,ALECENSA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OTHERS",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OTHERS,RADIATION,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,OTHERS,BEVACIZUMAB,RADIATION",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,DABRAFENIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GAVRETO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,SURGERY,GAVRETO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "SURGERY",
                },
                {
                  name: "OTHERS",
                  size: 138,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 26,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 5,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,OTHERS,CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,OTHERS,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,KEYTRUDA,TECENTRIQ",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OTHERS,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB",
                              children: [
                                {
                                  name: "KEYTRUDA",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:BEVACIZUMAB:KEYTRUDA:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,OPDIVO,BEVACIZUMAB,KEYTRUDA",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,RADIATION,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,TAGRISSO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,OPDIVO,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:OPDIVO:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,OPDIVO,TECENTRIQ",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,SURGERY",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,SURGERY,RADIATION",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,BEVACIZUMAB,OTHERS,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,ALECENSA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,ERBITUX",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,TECENTRIQ",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,CRIZOTINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,OTHERS,DABRAFENIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "OTHERS",
                },
                {
                  name: "CRT",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRT",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CRT",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CRT,KEYTRUDA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CRT,SURGERY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CRT,SURGERY,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "CRT",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CYRAMZA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "CYRAMZA+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHERS_THERAPY",
            },
            {
              name: "TKI",
              size: 583,
              comment: "TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 356,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "GILOTRIF",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CHEMOTHERAPY,ERLOTINIB_HCL",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,KEYTRUDA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "RADIATION",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,OPDIVO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,SURGERY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,BEVACIZUMAB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 13,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "GILOTRIF",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,GILOTRIF",
                      children: [
                        {
                          name: "IRESSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:IRESSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,GILOTRIF,IRESSA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CRIZOTINIB:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,CRIZOTINIB",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,ERLOTINIB_HCL",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "IRESSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IRESSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:IRESSA:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,IRESSA",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "VIZIMPRO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:VIZIMPRO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:VIZIMPRO:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO,VIZIMPRO",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "TAGRISSO",
                },
                {
                  name: "OTHER_TKI",
                  size: 219,
                  comment: "OTHER_TKI",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 114,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "TAGRISSO",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,BEVACIZUMAB",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 11,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "RADIATION",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,OTHERS",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "IMFINZI",
                              size: 6,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "IMFINZI+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 13,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 2,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                                      children: [],
                                      comment: "All EGFR tested patients",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 14,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,OPDIVO",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "CYRAMZA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CYRAMZA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CYRAMZA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 8,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO",
                          children: [
                            {
                              name: "TAGRISSO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "BEVACIZUMAB",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 66,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "GILOTRIF",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:GILOTRIF:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION,GILOTRIF",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB",
                              children: [
                                {
                                  name: "LORLATINIB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:LORLATINIB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB,LORLATINIB",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RETEVMO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "ERBITUX",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,KEYTRUDA,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,KEYTRUDA,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,RADIATION,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,RADIATION,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "ERBITUX",
                    },
                    {
                      name: "GILOTRIF",
                      size: 14,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,GILOTRIF",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,GILOTRIF,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,GILOTRIF,TAGRISSO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,GILOTRIF,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ALECENSA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,GILOTRIF,ALECENSA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "GILOTRIF",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO,KEYTRUDA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "ERBITUX+CHEMOTHERAPY",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ERLOTINIB_HCL",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI,ERLOTINIB_HCL",
                      children: [],
                      comment: "ERLOTINIB_HCL",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,OTHER_TKI",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All EGFR tested patients",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,TKI,TAGRISSO+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,TAGRISSO+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All EGFR tested patients",
                    },
                  ],
                  comment: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,EGFR TEST,TKI",
            },
            {
              name: "OTHER_TARGETED_THERAPY",
              size: 145,
              comment: "OTHER_TARGETED_THERAPY",
              children: [
                {
                  name: "ALK",
                  size: 83,
                  comment: "ALK",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 80,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA",
                      children: [
                        {
                          name: "RADIATION",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ALECENSA,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,TAGRISSO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "SURGERY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ALECENSA,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,BEVACIZUMAB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "LORLATINIB",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ALECENSA,LORLATINIB,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,SURGERY",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment: "All EGFR tested patients",
                                },
                              ],
                              comment: "All EGFR tested patients",
                            },
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,ALECENSA",
                              children: [],
                              comment: "All EGFR tested patients",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BRIGATINIB",
                          size: 6,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,BRIGATINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,CRIZOTINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "ALECENSA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:ALECENSA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:ALECENSA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,ALECENSA,ALECENSA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "ALECENSA",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:BRIGATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK,BRIGATINIB",
                      children: [],
                      comment: "BRIGATINIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ALK",
                },
                {
                  name: "ROS1",
                  size: 21,
                  comment: "ROS1",
                  children: [
                    {
                      name: "CRIZOTINIB",
                      size: 21,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CRIZOTINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,KEYTRUDA",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,CRIZOTINIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,OPDIVO",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "LORLATINIB",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,LORLATINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,CAPMATINIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "CRIZOTINIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,ROS1",
                },
                {
                  name: "BRAF",
                  size: 19,
                  comment: "BRAF",
                  children: [
                    {
                      name: "DABRAFENIB",
                      size: 18,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:EGFR TEST:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,DABRAFENIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,GILOTRIF",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "VEMURAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:VEMURAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:VEMURAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,VEMURAFENIB",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "DABRAFENIB",
                    },
                    {
                      name: "DABRAFENIB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:EGFR TEST:DABRAFENIB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB+CHEMOTHERAPY",
                      children: [],
                      comment: "DABRAFENIB+CHEMOTHERAPY",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,BRAF",
                },
                {
                  name: "MET",
                  size: 13,
                  comment: "MET",
                  children: [
                    {
                      name: "CAPMATINIB",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CAPMATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:EGFR TEST:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "CAPMATINIB",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,MET",
                },
                {
                  name: "RET",
                  size: 8,
                  comment: "RET",
                  children: [
                    {
                      name: "RETEVMO",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:RETEVMO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,RET,RETEVMO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RETEVMO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,RET,RETEVMO,CHEMOTHERAPY",
                          children: [],
                          comment: "All EGFR tested patients",
                        },
                      ],
                      comment: "RETEVMO",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,RET",
                },
                {
                  name: "NTRK",
                  size: 1,
                  comment: "NTRK",
                  children: [
                    {
                      name: "VITRAKVI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:VITRAKVI",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VITRAKVI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,NTRK,VITRAKVI",
                      children: [],
                      comment: "VITRAKVI",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY,NTRK",
                },
              ],
              nuuid: "NSCLC,STAGE 4,EGFR TEST,OTHER_TARGETED_THERAPY",
            },
            {
              name: "UNTREATED",
              size: 456,
              key: "INCtumor:NSCLC:STAGE 4:EGFR TEST:UNTREATED",
              id: "nsclc:G:5:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
              nuuid: "NSCLC,STAGE 4,EGFR TEST,UNTREATED",
              children: [],
              comment: "UNTREATED PATIENTS",
            },
          ],
          comment: "All EGFR tested patients",
        },
        {
          name: "No Biomarker Test",
          size: 39399,
          key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
          nuuid: "NSCLC,STAGE 4,No Biomarker Test",
          children: [
            {
              name: "No Biomarker Test",
              size: 8740,
              comment: "No Biomarker Test",
              children: [
                {
                  name: "RADIATION",
                  size: 5368,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 203,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 92,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,OPDIVO,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:ERBITUX:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,ERBITUX,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CHEMOTHERAPY,SURGERY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 162,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,CAPMATINIB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CAPMATINIB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA,CAPMATINIB,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 228,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RETEVMO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RETEVMO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:RETEVMO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,KEYTRUDA+CHEMOTHERAPY,RETEVMO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 68,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:OPDIVO:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,OPDIVO,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,IMFINZI,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 94,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 77,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,RADIATION,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,CHEMOTHERAPY,SURGERY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,BEVACIZUMAB",
                          children: [
                            {
                              name: "ERBITUX",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:ERBITUX:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,BEVACIZUMAB,ERBITUX,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 42,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,RADIATION,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB,ERBITUX",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 45,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY,ALECENSA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO,CRIZOTINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 17,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OTHERS",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OTHERS,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "VIZIMPRO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:VIZIMPRO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OTHERS,VIZIMPRO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 17,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 16,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,RADIATION,TECENTRIQ+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERBITUX,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ALECENSA,BRIGATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,GILOTRIF",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERLOTINIB_HCL",
                      children: [
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,ERLOTINIB_HCL,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,DABRAFENIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BAVENCIO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BAVENCIO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BAVENCIO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,CRIZOTINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,LUMAKRAS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BRIGATINIB",
                      children: [
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,BRIGATINIB,LORLATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TEMOZOLOMIDE",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TEMOZOLOMIDE:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TEMOZOLOMIDE",
                      children: [
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:TEMOZOLOMIDE:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,TEMOZOLOMIDE,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "YERVOY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,YERVOY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:YERVOY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,RADIATION,YERVOY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "RADIATION",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2513,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 231,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 162,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 21,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 7,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "TECENTRIQ+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TEMOZOLOMIDE",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ$CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,TECENTRIQ+CHEMOTHERAPY,TEMOZOLOMIDE",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,RADIATION,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 149,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 13,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "ERBITUX",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:ERBITUX:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,ERBITUX",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,ERBITUX",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:ERBITUX:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,ERBITUX,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,OTHERS",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO",
                              children: [
                                {
                                  name: "SURGERY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OTHERS:OPDIVO:SURGERY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA,OTHERS,OPDIVO,SURGERY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 105,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 64,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "RADIATION",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,LUMAKRAS",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO,ERBITUX",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI",
                      size: 148,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 52,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,SURGERY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 45,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                                      children: [
                                        {
                                          name: "Wait & Watch",
                                          size: 1,
                                          key:
                                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                          id:
                                            "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                                          nuuid:
                                            "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                                          children: [],
                                          comment:
                                            "Wait and Watch on patients after finishing a therapy",
                                        },
                                      ],
                                      comment:
                                        "All patients without Biomarker or IHC tests",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:ERBITUX:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB,ERBITUX,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TAGRISSO,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 45,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "OTHERS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:OTHERS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,OTHERS",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                                  children: [
                                    {
                                      name: "YERVOY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:YERVOY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO,YERVOY",
                                      children: [],
                                      comment:
                                        "All patients without Biomarker or IHC tests",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CYRAMZA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OTHERS",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OTHERS,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OTHERS,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TECENTRIQ",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 11,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OTHERS",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ALECENSA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BAVENCIO",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BAVENCIO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BAVENCIO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,BAVENCIO,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TARCEVA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TARCEVA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TARCEVA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TARCEVA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TARCEVA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CHEMOTHERAPY:TARCEVA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,CHEMOTHERAPY,TARCEVA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TEMOZOLOMIDE",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TEMOZOLOMIDE:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CHEMOTHERAPY,TEMOZOLOMIDE",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "CHEMOTHERAPY",
                },
                {
                  name: "SURGERY",
                  size: 807,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 24,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 13,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 45,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 23,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,RADIATION,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 14,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OPDIVO",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OPDIVO,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OPDIVO:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OPDIVO,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,IMFINZI",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 19,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,SURGERY,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,TAGRISSO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OTHERS",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OTHERS,RADIATION",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OTHERS,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OTHERS,BEVACIZUMAB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:OTHERS:BEVACIZUMAB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,OTHERS,BEVACIZUMAB,RADIATION",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,ERBITUX",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,SURGERY,CAPMATINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "SURGERY",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 27,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CYRAMZA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "CYRAMZA+CHEMOTHERAPY",
                },
                {
                  name: "CRT",
                  size: 17,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRT",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CRT",
                  children: [
                    {
                      name: "SURGERY",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CRT,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id: "nsclc:G:6:WHEEL:I:SEERtumor:CRT:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,CRT,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CRT,SURGERY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BAVENCIO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BAVENCIO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CRT,BAVENCIO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "CRT",
                },
                {
                  name: "CYRAMZA",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:CYRAMZA:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CYRAMZA",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:CYRAMZA:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,CYRAMZA,TAGRISSO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "CYRAMZA",
                },
                {
                  name: "TEMOZOLOMIDE",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TEMOZOLOMIDE",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TEMOZOLOMIDE:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test,TEMOZOLOMIDE",
                  children: [],
                  comment: "TEMOZOLOMIDE",
                },
              ],
              nuuid: "NSCLC,STAGE 4,No Biomarker Test,No Biomarker Test",
            },
            {
              name: "IO",
              size: 4717,
              comment: "IO",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 2302,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 154,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "LUMAKRAS",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:LUMAKRAS:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO,LUMAKRAS",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "IMFINZI",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,LUMAKRAS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 23,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 32,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 14,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 104,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 26,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 19,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA",
                      children: [
                        {
                          name: "BRIGATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:BRIGATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,BRIGATINIB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "CAPMATINIB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ALECENSA:LORLATINIB:CAPMATINIB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ALECENSA,LORLATINIB,CAPMATINIB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,ERLOTINIB_HCL",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 5,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "PORTRAZZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:PORTRAZZA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:PORTRAZZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA+CHEMOTHERAPY,PORTRAZZA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "KEYTRUDA",
                  size: 1415,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 94,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 21,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,RADIATION",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,ERBITUX",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 18,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 31,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 19,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,KEYTRUDA,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION",
                              children: [
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:DABRAFENIB:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO,DABRAFENIB,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,KEYTRUDA,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,SURGERY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,TECENTRIQ",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,ALECENSA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,GILOTRIF",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CAPMATINIB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CAPMATINIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,DABRAFENIB",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,DABRAFENIB,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,LUMAKRAS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,KEYTRUDA,CYRAMZA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 571,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 15,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,OPDIVO,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 20,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 12,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,RADIATION,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,RADIATION,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,KEYTRUDA,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,SURGERY",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:SURGERY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,SURGERY,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,BEVACIZUMAB,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,OTHERS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,DABRAFENIB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,DABRAFENIB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,DABRAFENIB,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,DABRAFENIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO:DABRAFENIB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,OPDIVO,DABRAFENIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "VEMURAFENIB",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VEMURAFENIB",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:VEMURAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,VEMURAFENIB",
                      children: [
                        {
                          name: "YERVOY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:VEMURAFENIB:YERVOY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO,VEMURAFENIB,YERVOY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 178,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TECENTRIQ,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TECENTRIQ,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,KEYTRUDA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,OPDIVO",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:OPDIVO:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,OPDIVO,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ:OPDIVO:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,TECENTRIQ,OPDIVO,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,SURGERY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,ERLOTINIB_HCL",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:CYRAMZA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ,CYRAMZA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "TECENTRIQ",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 117,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,TECENTRIQ+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,TECENTRIQ",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,ALECENSA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IMFINZI+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,TECENTRIQ+CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "TECENTRIQ+CHEMOTHERAPY",
                },
                {
                  name: "IMFINZI",
                  size: 71,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,RADIATION",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,RADIATION,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI:RADIATION:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,IMFINZI,RADIATION,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,KEYTRUDA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "IMFINZI",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 47,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 4,OPDIVO+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,OPDIVO,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,OPDIVO+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,OPDIVO+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "YERVOY",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:YERVOY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:YERVOY:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,YERVOY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:YERVOY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,YERVOY,OPDIVO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "YERVOY",
                },
                {
                  name: "BAVENCIO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BAVENCIO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:BAVENCIO:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO,BAVENCIO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:BAVENCIO:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,BAVENCIO,KEYTRUDA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "BAVENCIO",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 4,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,IO,IMFINZI+CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "IMFINZI+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,No Biomarker Test,IO",
            },
            {
              name: "TKI",
              size: 2976,
              comment: "TKI",
              children: [
                {
                  name: "OTHER_TKI",
                  size: 2279,
                  comment: "OTHER_TKI",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1900,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 43,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 15,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "RADIATION",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                                {
                                  name: "OTHERS",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,OTHERS",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "IMFINZI",
                              size: 6,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "TECENTRIQ",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,TECENTRIQ",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "IMFINZI+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 72,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 54,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "OPDIVO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:OPDIVO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,OPDIVO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "CYRAMZA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CYRAMZA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,RADIATION,CYRAMZA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 26,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 2,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                                  children: [
                                    {
                                      name: "CHEMOTHERAPY",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                      id:
                                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                                      children: [],
                                      comment:
                                        "All patients without Biomarker or IHC tests",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 24,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "TAGRISSO",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                              children: [
                                {
                                  name: "BEVACIZUMAB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:BEVACIZUMAB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY,TAGRISSO,BEVACIZUMAB",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OPDIVO:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OPDIVO,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "IMFINZI",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:IMFINZI:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 5,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,BEVACIZUMAB,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 11,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO",
                          children: [
                            {
                              name: "TAGRISSO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TAGRISSO:TAGRISSO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TAGRISSO,TAGRISSO+CHEMOTHERAPY,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [
                            {
                              name: "IMFINZI",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IMFINZI",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 15,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "BEVACIZUMAB",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,TECENTRIQ+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ALECENSA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ALECENSA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,GILOTRIF",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CAPMATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,DABRAFENIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,CRIZOTINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,LUMAKRAS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "VEMURAFENIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VEMURAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:VEMURAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB,VEMURAFENIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "BEVACIZUMAB",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 184,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION",
                              children: [
                                {
                                  name: "GILOTRIF",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:RADIATION:GILOTRIF:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,RADIATION,GILOTRIF",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB",
                              children: [
                                {
                                  name: "LORLATINIB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:CRIZOTINIB:LORLATINIB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,CRIZOTINIB,LORLATINIB",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 8,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 5,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "OPDIVO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA,ERBITUX+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 10,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                                  children: [
                                    {
                                      name: "Wait & Watch",
                                      size: 1,
                                      key:
                                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                      id:
                                        "nsclc:G:6:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                                      nuuid:
                                        "NSCLC,STAGE 4,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                                      children: [],
                                      comment:
                                        "Wait and Watch on patients after finishing a therapy",
                                    },
                                  ],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TECENTRIQ+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                          children: [
                            {
                              name: "CYRAMZA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:ERBITUX:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,ERBITUX,KEYTRUDA+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,BEVACIZUMAB+CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "ERBITUX",
                      size: 76,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 4,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,CHEMOTHERAPY,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ERBITUX,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,RADIATION,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:RADIATION:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,RADIATION,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 9,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ERBITUX,KEYTRUDA,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "TECENTRIQ",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ",
                              children: [
                                {
                                  name: "CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,KEYTRUDA+CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO",
                          children: [
                            {
                              name: "ERBITUX+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "CYRAMZA+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OPDIVO:ERBITUX$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,OPDIVO,ERBITUX+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,SURGERY",
                          children: [
                            {
                              name: "BEVACIZUMAB+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
                              children: [
                                {
                                  name: "RADIATION",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX,OTHERS",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "ERBITUX",
                    },
                    {
                      name: "GILOTRIF",
                      size: 61,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,GILOTRIF",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,GILOTRIF,RADIATION",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,GILOTRIF,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 7,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,GILOTRIF,TAGRISSO",
                          children: [
                            {
                              name: "KEYTRUDA+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,GILOTRIF,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "GILOTRIF",
                    },
                    {
                      name: "ERBITUX+CHEMOTHERAPY",
                      size: 21,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "OPDIVO+CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO+CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,RADIATION,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:OPDIVO:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,OPDIVO,KEYTRUDA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 4,ERBITUX+CHEMOTHERAPY,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,ERBITUX",
                          children: [
                            {
                              name: "BEVACIZUMAB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:ERBITUX:BEVACIZUMAB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERBITUX+CHEMOTHERAPY,ERBITUX,BEVACIZUMAB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "ERBITUX+CHEMOTHERAPY",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 14,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL",
                      children: [
                        {
                          name: "TAGRISSO",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERLOTINIB_HCL:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL,TAGRISSO",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERLOTINIB_HCL:TAGRISSO:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL,TAGRISSO,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "IRESSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ERLOTINIB_HCL:IRESSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL,IRESSA",
                          children: [
                            {
                              name: "ERLOTINIB_HCL",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ERLOTINIB_HCL:IRESSA:ERLOTINIB_HCL:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL,IRESSA,ERLOTINIB_HCL",
                              children: [
                                {
                                  name: "TAGRISSO",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ERLOTINIB_HCL:IRESSA:ERLOTINIB_HCL:TAGRISSO:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL,IRESSA,ERLOTINIB_HCL,TAGRISSO",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "ERLOTINIB_HCL",
                    },
                    {
                      name: "TARCEVA",
                      size: 14,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TARCEVA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA",
                      children: [
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,BEVACIZUMAB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 6,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,TAGRISSO",
                          children: [
                            {
                              name: "DABRAFENIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:DABRAFENIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,TAGRISSO,DABRAFENIB",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "VIZIMPRO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:VIZIMPRO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,TAGRISSO,VIZIMPRO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OTHERS",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OTHERS",
                          id: "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,OTHERS",
                          children: [
                            {
                              name: "TAGRISSO",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:OTHERS:TAGRISSO:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,TARCEVA,OTHERS,TAGRISSO",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "TARCEVA",
                    },
                    {
                      name: "IRESSA",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:IRESSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,IRESSA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IRESSA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,IRESSA,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:IRESSA:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,IRESSA,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "IRESSA",
                    },
                    {
                      name: "ERLOTINIB_HCL+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL+CHEMOTHERAPY",
                      id:
                        "nsclc:G:6:WHEEL:I:SEERtumor:ERLOTINIB_HCL$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,ERLOTINIB_HCL+CHEMOTHERAPY",
                      children: [],
                      comment: "ERLOTINIB_HCL+CHEMOTHERAPY",
                    },
                    {
                      name: "VIZIMPRO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VIZIMPRO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI,VIZIMPRO",
                      children: [],
                      comment: "VIZIMPRO",
                    },
                  ],
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,TKI,OTHER_TKI",
                },
                {
                  name: "TAGRISSO",
                  size: 682,
                  key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 11,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [
                            {
                              name: "GILOTRIF",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:GILOTRIF:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,GILOTRIF",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ERLOTINIB_HCL",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,ERLOTINIB_HCL",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CHEMOTHERAPY,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "RADIATION",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                          id:
                            "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 4,TAGRISSO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 10,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,OPDIVO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,BEVACIZUMAB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 3,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERBITUX",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,ERBITUX",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERBITUX:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,ERBITUX,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,ALECENSA",
                      children: [
                        {
                          name: "TAGRISSO",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ALECENSA:TAGRISSO:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,ALECENSA,TAGRISSO",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,GILOTRIF",
                      children: [
                        {
                          name: "IRESSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:IRESSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,GILOTRIF,IRESSA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 9,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,ERLOTINIB_HCL",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,CAPMATINIB",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TAGRISSO+CHEMOTHERAPY",
                      size: 4,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TAGRISSO$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,TAGRISSO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "IRESSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:IRESSA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:IRESSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,IRESSA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TARCEVA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TARCEVA",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:TARCEVA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,TARCEVA",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "VIZIMPRO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VIZIMPRO",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:VIZIMPRO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO,VIZIMPRO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "TAGRISSO",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 15,
                  key:
                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TAGRISSO+CHEMOTHERAPY",
                  id: "nsclc:G:6:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:OPDIVO",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GILOTRIF",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO$CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,TKI,TAGRISSO+CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "All patients without Biomarker or IHC tests",
                    },
                  ],
                  comment: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              nuuid: "NSCLC,STAGE 4,No Biomarker Test,TKI",
            },
            {
              name: "OTHER_TARGETED_THERAPY",
              size: 589,
              comment: "OTHER_TARGETED_THERAPY",
              children: [
                {
                  name: "ALK",
                  size: 308,
                  comment: "ALK",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 277,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA",
                      children: [
                        {
                          name: "RADIATION",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 3,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ALECENSA,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 2,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:SURGERY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,ALECENSA,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LORLATINIB",
                          size: 32,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,ALECENSA,LORLATINIB,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "SURGERY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:SURGERY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,SURGERY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "CRIZOTINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB",
                              children: [
                                {
                                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BEVACIZUMAB+CHEMOTHERAPY",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CRIZOTINIB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,CRIZOTINIB,BEVACIZUMAB+CHEMOTHERAPY",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                            {
                              name: "ALECENSA",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:ALECENSA:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB,ALECENSA",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "BRIGATINIB",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:BRIGATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,BRIGATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LORLATINIB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ALECENSA,LORLATINIB+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "ALECENSA",
                    },
                    {
                      name: "LORLATINIB",
                      size: 12,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,LORLATINIB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:LORLATINIB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,LORLATINIB,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "LORLATINIB",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 10,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,BRIGATINIB",
                      children: [],
                      comment: "BRIGATINIB",
                    },
                    {
                      name: "ZYKADIA",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ZYKADIA",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:ZYKADIA:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ZYKADIA",
                      children: [
                        {
                          name: "ALECENSA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ZYKADIA:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ZYKADIA,ALECENSA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:ZYKADIA:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK,ZYKADIA,CRIZOTINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "ZYKADIA",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ALK",
                },
                {
                  name: "ROS1",
                  size: 121,
                  comment: "ROS1",
                  children: [
                    {
                      name: "CRIZOTINIB",
                      size: 121,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CRIZOTINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "SURGERY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:SURGERY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,SURGERY",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:SURGERY:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,SURGERY,CHEMOTHERAPY",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:CRIZOTINIB:SURGERY:CHEMOTHERAPY:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,CRIZOTINIB,SURGERY,CHEMOTHERAPY,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "LORLATINIB",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,LORLATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ALECENSA",
                          size: 4,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ALECENSA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:ALECENSA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,ALECENSA",
                          children: [
                            {
                              name: "BRIGATINIB",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:BRIGATINIB",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:ALECENSA:BRIGATINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,ALECENSA,BRIGATINIB",
                              children: [
                                {
                                  name: "LORLATINIB",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LORLATINIB",
                                  id:
                                    "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:ALECENSA:BRIGATINIB:LORLATINIB:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,ALECENSA,BRIGATINIB,LORLATINIB",
                                  children: [],
                                  comment:
                                    "All patients without Biomarker or IHC tests",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "CAPMATINIB",
                          size: 5,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,CAPMATINIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "ZYKADIA",
                          size: 3,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:ZYKADIA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRIZOTINIB:ZYKADIA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1,CRIZOTINIB,ZYKADIA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "CRIZOTINIB",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,ROS1",
                },
                {
                  name: "BRAF",
                  size: 89,
                  comment: "BRAF",
                  children: [
                    {
                      name: "DABRAFENIB",
                      size: 82,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:DABRAFENIB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,DABRAFENIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,KEYTRUDA,CHEMOTHERAPY",
                              children: [],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "VEMURAFENIB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VEMURAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:DABRAFENIB:VEMURAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,DABRAFENIB,VEMURAFENIB",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "DABRAFENIB",
                    },
                    {
                      name: "VEMURAFENIB",
                      size: 7,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VEMURAFENIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VEMURAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,VEMURAFENIB",
                      children: [
                        {
                          name: "DABRAFENIB",
                          size: 2,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:DABRAFENIB",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:VEMURAFENIB:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,VEMURAFENIB,DABRAFENIB",
                          children: [
                            {
                              name: "RADIATION",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:VEMURAFENIB:DABRAFENIB:RADIATION:2020",
                              nuuid:
                                "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF,VEMURAFENIB,DABRAFENIB,RADIATION",
                              children: [
                                {
                                  name: "Wait & Watch",
                                  size: 1,
                                  key:
                                    "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                                  id:
                                    "nsclc:G:6:WHEEL:I:SEERtumor:VEMURAFENIB:DABRAFENIB:RADIATION:WW:2020",
                                  nuuid:
                                    "NSCLC,STAGE 4,VEMURAFENIB,DABRAFENIB,RADIATION,WW",
                                  children: [],
                                  comment:
                                    "Wait and Watch on patients after finishing a therapy",
                                },
                              ],
                              comment:
                                "All patients without Biomarker or IHC tests",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "VEMURAFENIB",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,BRAF",
                },
                {
                  name: "MET",
                  size: 32,
                  comment: "MET",
                  children: [
                    {
                      name: "CAPMATINIB",
                      size: 32,
                      key:
                        "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CAPMATINIB",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,MET,CAPMATINIB",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:CHEMOTHERAPY",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:CHEMOTHERAPY:WW:2020",
                              nuuid: "NSCLC,STAGE 4,CAPMATINIB,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RADIATION",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key:
                                "INCtumor:NSCLC:STAGE 4:No Biomarker Test:Wait & Watch",
                              id:
                                "nsclc:G:6:WHEEL:I:SEERtumor:CAPMATINIB:RADIATION:WW:2020",
                              nuuid: "NSCLC,STAGE 4,CAPMATINIB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 4:No Biomarker Test:KEYTRUDA",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CAPMATINIB:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,MET,CAPMATINIB,KEYTRUDA",
                          children: [],
                          comment:
                            "All patients without Biomarker or IHC tests",
                        },
                      ],
                      comment: "CAPMATINIB",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,MET",
                },
                {
                  name: "KRAS",
                  size: 18,
                  comment: "KRAS",
                  children: [
                    {
                      name: "LUMAKRAS",
                      size: 18,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:LUMAKRAS",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,KRAS,LUMAKRAS",
                      children: [],
                      comment: "LUMAKRAS",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,KRAS",
                },
                {
                  name: "RET",
                  size: 17,
                  comment: "RET",
                  children: [
                    {
                      name: "RETEVMO",
                      size: 12,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:RETEVMO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,RET,RETEVMO",
                      children: [],
                      comment: "RETEVMO",
                    },
                    {
                      name: "GAVRETO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:GAVRETO",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,RET,GAVRETO",
                      children: [],
                      comment: "GAVRETO",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,RET",
                },
                {
                  name: "NTRK",
                  size: 4,
                  comment: "NTRK",
                  children: [
                    {
                      name: "VITRAKVI",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:VITRAKVI",
                      id: "nsclc:G:6:WHEEL:I:SEERtumor:VITRAKVI:2020",
                      nuuid:
                        "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,NTRK,VITRAKVI",
                      children: [],
                      comment: "VITRAKVI",
                    },
                  ],
                  nuuid:
                    "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY,NTRK",
                },
              ],
              nuuid: "NSCLC,STAGE 4,No Biomarker Test,OTHER_TARGETED_THERAPY",
            },
            {
              name: "UNTREATED",
              size: 22377,
              key: "INCtumor:NSCLC:STAGE 4:No Biomarker Test:UNTREATED",
              id: "nsclc:G:5:WHEEL:I:SEERtumor:NSCLC:STAGE4:2020",
              nuuid: "NSCLC,STAGE 4,No Biomarker Test,UNTREATED",
              children: [],
              comment: "UNTREATED PATIENTS",
            },
          ],
          comment: "All patients without Biomarker or IHC tests",
        },
      ],
      nuuid: "NSCLC,STAGE 4",
    },
    {
      name: "STAGE 1",
      size: 50155,
      id: "nsclc:G:3:WHEEL:I:INCtumor:NSCLC:STAGE 1:STAGE 1:2020",
      children: [
        {
          name: "SURGERY",
          size: 12240,
          key: "INCtumor:NSCLC:STAGE 1:SURGERY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
          nuuid: "NSCLC,STAGE 1,SURGERY",
          children: [
            {
              name: "Wait & Watch",
              size: 10089,
              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::SURGERY:WW:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "RADIATION",
              size: 399,
              key: "INCtumor:NSCLC:STAGE 1:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 258,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OTHER IO",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "GAVRETO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:GAVRETO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:GAVRETO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,CHEMOTHERAPY,GAVRETO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,TARGETED_THERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,SURGERY,RADIATION,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,RADIATION,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,RADIATION,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 189,
              key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 90,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 27,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,IMFINZI,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,RADIATION",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 62,
              key: "INCtumor:NSCLC:STAGE 1:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 61,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TKI,TAGRISSO",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,TAGRISSO,BEVACIZUMAB",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:BEVACIZUMAB:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,SURGERY,TAGRISSO,BEVACIZUMAB,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TKI,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERLOTINIB_HCL",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 76,
              key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 55,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "BEVACIZUMAB+CHEMOTHERAPY",
                          size: 1,
                          key:
                            "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:OPDIVO:BEVACIZUMAB$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,SURGERY,KEYTRUDA,OPDIVO,BEVACIZUMAB+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 16,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 58,
              key: "INCtumor:NSCLC:STAGE 1:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 53,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OPDIVO$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,SURGERY,OPDIVO+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,SURGERY,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 49,
              key: "INCtumor:NSCLC:STAGE 1:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,OTHERS",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 39,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 22,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "ALECENSA",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 1:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "GILOTRIF",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,GILOTRIF",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:GILOTRIF:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 1,SURGERY,GILOTRIF,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "DABRAFENIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,DABRAFENIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
                {
                  name: "CAPMATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CAPMATINIB",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "GAVRETO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:GAVRETO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:GAVRETO:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,GAVRETO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GAVRETO",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 12,
              key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 1,SURGERY,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "BEVACIZUMAB+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,SURGERY,BEVACIZUMAB+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IMFINZI",
          size: 122,
          key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
          nuuid: "NSCLC,STAGE 1,IMFINZI",
          children: [
            {
              name: "Wait & Watch",
              size: 43,
              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::IMFINZI:WW:2020",
              nuuid: "NSCLC,STAGE 1,IMFINZI,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "CHEMOTHERAPY",
              size: 8,
              key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IMFINZI,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,IMFINZI,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,IMFINZI,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 5,
              key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 1,IMFINZI,OTHER IO",
              children: [
                {
                  name: "OPDIVO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,IMFINZI,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "KEYTRUDA",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,IMFINZI,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,IMFINZI,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CYRAMZA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IMFINZI,CYRAMZA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
              children: [
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,IMFINZI,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHER IO",
          size: 320,
          key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERIO:2020",
          nuuid: "NSCLC,STAGE 1,OTHER IO",
          children: [
            {
              name: "KEYTRUDA",
              size: 231,
              key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
              nuuid: "NSCLC,STAGE 1,OTHER IO,KEYTRUDA",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA,CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,KEYTRUDA,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA,CYRAMZA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,KEYTRUDA,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHER IO",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHERS",
                  children: [
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:OTHERS:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHERS,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:OTHERS:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 1,KEYTRUDA,OTHERS,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "OPDIVO",
              size: 64,
              key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:2020",
              nuuid: "NSCLC,STAGE 1,OTHER IO,OPDIVO",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ERBITUX",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO,ERBITUX",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TECENTRIQ",
              size: 24,
              key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:2020",
              nuuid: "NSCLC,STAGE 1,OTHER IO,TECENTRIQ",
              children: [
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,TECENTRIQ,RADIATION",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "YERVOY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:YERVOY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:YERVOY:2020",
              nuuid: "NSCLC,STAGE 1,OTHER IO,YERVOY",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:YERVOY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,YERVOY,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IO+CHEMOTHERAPY",
          size: 156,
          key: "INCtumor:NSCLC:STAGE 1:IO+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IO$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 1,IO+CHEMOTHERAPY",
          children: [
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 142,
              key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,RADIATION,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,KEYTRUDA+CHEMOTHERAPY,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TECENTRIQ+CHEMOTHERAPY",
              size: 10,
              key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
              children: [
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,TECENTRIQ+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,TECENTRIQ+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,TECENTRIQ+CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 1,TECENTRIQ+CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,TECENTRIQ+CHEMOTHERAPY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "OPDIVO+CHEMOTHERAPY",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 1:OPDIVO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,OPDIVO+CHEMOTHERAPY,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TARGETED THERAPY+CHEMOTHERAPY",
          size: 21,
          key: "INCtumor:NSCLC:STAGE 1:TARGETED THERAPY+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TARGETEDTHERAPY$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 1,TARGETED THERAPY+CHEMOTHERAPY",
          children: [
            {
              name: "BEVACIZUMAB+CHEMOTHERAPY",
              size: 13,
              key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 1,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
              children: [
                {
                  name: "OTHER IO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,BEVACIZUMAB+CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,BEVACIZUMAB+CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,BEVACIZUMAB+CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 1,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CYRAMZA+CHEMOTHERAPY",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 1,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ERBITUX+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:ERBITUX+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 1,TARGETED THERAPY+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
              children: [
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,ERBITUX+CHEMOTHERAPY,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,ERBITUX+CHEMOTHERAPY,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VITRAKVI+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:VITRAKVI+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VITRAKVI$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 1,TARGETED THERAPY+CHEMOTHERAPY,VITRAKVI+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CHEMOTHERAPY",
          size: 1153,
          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY",
          children: [
            {
              name: "Wait & Watch",
              size: 624,
              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CHEMOTHERAPY:WW:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "RADIATION",
              size: 113,
              key: "INCtumor:NSCLC:STAGE 1:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 89,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,SURGERY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,RADIATION,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 97,
              key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 26,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OPDIVO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED THERAPY+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED THERAPY+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,TARGETED THERAPY+CHEMOTHERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI,RADIATION",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 34,
              key: "INCtumor:NSCLC:STAGE 1:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 27,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,SURGERY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,SURGERY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,SURGERY,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 44,
              key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 33,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,CHEMOTHERAPY,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,TECENTRIQ,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
                {
                  name: "YERVOY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:YERVOY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:YERVOY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHER IO,YERVOY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:YERVOY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,YERVOY,KEYTRUDA",
                      children: [
                        {
                          name: "SURGERY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:YERVOY:KEYTRUDA:SURGERY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,CHEMOTHERAPY,YERVOY,KEYTRUDA,SURGERY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:YERVOY:KEYTRUDA:SURGERY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 1,CHEMOTHERAPY,YERVOY,KEYTRUDA,SURGERY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "YERVOY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 19,
              key: "INCtumor:NSCLC:STAGE 1:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 9,
              key: "INCtumor:NSCLC:STAGE 1:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TKI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 5,
              key: "INCtumor:NSCLC:STAGE 1:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHERS",
              children: [
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHERS,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,OTHERS,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED THERAPY+CHEMOTHERAPY",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED THERAPY+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY",
              children: [
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI+CHEMOTHERAPY",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 1:IMFINZI+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB:2020",
                  nuuid:
                    "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "CYRAMZA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED_THERAPY,CYRAMZA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA",
                },
                {
                  name: "ERBITUX",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 1,CHEMOTHERAPY,TARGETED_THERAPY,ERBITUX",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERBITUX",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CRT",
          size: 17,
          key: "INCtumor:NSCLC:STAGE 1:CRT",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
          nuuid: "NSCLC,STAGE 1,CRT",
          children: [
            {
              name: "Wait & Watch",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CRT:WW:2020",
              nuuid: "NSCLC,STAGE 1,CRT,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "SURGERY",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 1:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,CRT,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,CRT,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,CRT,SURGERY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,CRT,SURGERY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "RADIATION",
          size: 4849,
          key: "INCtumor:NSCLC:STAGE 1:RADIATION",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
          nuuid: "NSCLC,STAGE 1,RADIATION",
          children: [
            {
              name: "Wait & Watch",
              size: 3547,
              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::RADIATION:WW:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "SURGERY",
              size: 263,
              key: "INCtumor:NSCLC:STAGE 1:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 213,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,TARGETED_THERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,SURGERY,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:DABRAFENIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,SURGERY,KEYTRUDA,DABRAFENIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,SURGERY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,SURGERY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 67,
              key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 33,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "BAVENCIO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BAVENCIO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:BAVENCIO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,OTHER IO,BAVENCIO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BAVENCIO",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,CHEMOTHERAPY,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 68,
              key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 47,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "TECENTRIQ",
                  size: 11,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
                {
                  name: "OPDIVO",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,OPDIVO,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 42,
              key: "INCtumor:NSCLC:STAGE 1:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 38,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "SURGERY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:SURGERY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,SURGERY,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:SURGERY:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,SURGERY,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY",
                      children: [
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY,CRIZOTINIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "CRIZOTINIB",
                        },
                        {
                          name: "ERBITUX",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:ERBITUX",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY,ERBITUX",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "ERBITUX",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 1:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,OTHERS",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,OTHERS,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,OTHERS,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,OTHERS,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OTHERS:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,RADIATION,OTHERS,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 13,
              key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,IMFINZI",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,IMFINZI,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 13,
              key: "INCtumor:NSCLC:STAGE 1:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TKI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TKI,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERLOTINIB_HCL",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 22,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "GILOTRIF",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "DABRAFENIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,DABRAFENIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
                {
                  name: "CAPMATINIB",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CAPMATINIB",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,CRIZOTINIB",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,CRIZOTINIB,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 1,RADIATION,CRIZOTINIB,KEYTRUDA+CHEMOTHERAPY,CRIZOTINIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED THERAPY+CHEMOTHERAPY",
              size: 5,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED THERAPY+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,RADIATION,TARGETED THERAPY+CHEMOTHERAPY",
              children: [
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,RADIATION,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                },
                {
                  name: "ERBITUX+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:ERBITUX+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,TARGETED THERAPY+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERBITUX+CHEMOTHERAPY",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,RADIATION,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TKI",
          size: 759,
          key: "INCtumor:NSCLC:STAGE 1:TKI",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TKI:2020",
          nuuid: "NSCLC,STAGE 1,TKI",
          children: [
            {
              name: "TAGRISSO",
              size: 721,
              key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 1,TKI,TAGRISSO",
              children: [
                {
                  name: "ERLOTINIB_HCL",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,ERLOTINIB_HCL",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,ERLOTINIB_HCL,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,TARGETED_THERAPY",
                  children: [
                    {
                      name: "GILOTRIF",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,TARGETED_THERAPY,GILOTRIF",
                      children: [
                        {
                          name: "TAGRISSO+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:TAGRISSO+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:TAGRISSO$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,TAGRISSO,GILOTRIF,TAGRISSO+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "GILOTRIF",
                    },
                    {
                      name: "IRESSA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 1:IRESSA",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:IRESSA:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,TARGETED_THERAPY,IRESSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "IRESSA",
                    },
                    {
                      name: "VEMURAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:VEMURAFENIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:VEMURAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,TAGRISSO,TARGETED_THERAPY,VEMURAFENIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "VEMURAFENIB",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,TAGRISSO,CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,IMFINZI+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,TAGRISSO,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ERLOTINIB_HCL",
              size: 27,
              key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
              nuuid: "NSCLC,STAGE 1,TKI,ERLOTINIB_HCL",
              children: [
                {
                  name: "TAGRISSO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERLOTINIB_HCL:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,ERLOTINIB_HCL,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERLOTINIB_HCL:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 1,ERLOTINIB_HCL,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TARCEVA",
              size: 10,
              key: "INCtumor:NSCLC:STAGE 1:TARCEVA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TARCEVA:2020",
              nuuid: "NSCLC,STAGE 1,TKI,TARCEVA",
              children: [
                {
                  name: "TAGRISSO",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,TARCEVA,TAGRISSO",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 1,TARCEVA,TAGRISSO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 1,TARCEVA,TAGRISSO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VIZIMPRO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:VIZIMPRO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VIZIMPRO:2020",
              nuuid: "NSCLC,STAGE 1,TKI,VIZIMPRO",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TARGETED_THERAPY",
          size: 369,
          key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TARGETED_THERAPY:2020",
          nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY",
          children: [
            {
              name: "BEVACIZUMAB",
              size: 147,
              key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,BEVACIZUMAB",
              children: [
                {
                  name: "RADIATION",
                  size: 29,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 23,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,RADIATION,IMFINZI",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 1,BEVACIZUMAB,RADIATION,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 1,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 1,BEVACIZUMAB,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "GILOTRIF",
              size: 51,
              key: "INCtumor:NSCLC:STAGE 1:GILOTRIF",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,GILOTRIF",
              children: [
                {
                  name: "TAGRISSO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,GILOTRIF,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,GILOTRIF,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ALECENSA",
              size: 38,
              key: "INCtumor:NSCLC:STAGE 1:ALECENSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,ALECENSA",
              children: [
                {
                  name: "TARGETED_THERAPY",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 1,ALECENSA,TARGETED_THERAPY",
                  children: [
                    {
                      name: "LORLATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:LORLATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,ALECENSA,TARGETED_THERAPY,LORLATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LORLATINIB",
                    },
                    {
                      name: "ZYKADIA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 1:ZYKADIA",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:ZYKADIA:2020",
                      nuuid: "NSCLC,STAGE 1,ALECENSA,TARGETED_THERAPY,ZYKADIA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ZYKADIA",
                    },
                    {
                      name: "BRIGATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BRIGATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:BRIGATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,ALECENSA,TARGETED_THERAPY,BRIGATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BRIGATINIB",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,ALECENSA,TARGETED_THERAPY,CRIZOTINIB",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:CRIZOTINIB:KEYTRUDA:2020",
                          nuuid: "NSCLC,STAGE 1,ALECENSA,CRIZOTINIB,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CRIZOTINIB",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CAPMATINIB",
              size: 30,
              key: "INCtumor:NSCLC:STAGE 1:CAPMATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,CAPMATINIB",
              children: [
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 1,CAPMATINIB,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,CAPMATINIB,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CRIZOTINIB",
              size: 18,
              key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,CRIZOTINIB",
              children: [
                {
                  name: "TARGETED_THERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 1,CRIZOTINIB,TARGETED_THERAPY",
                  children: [
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CRIZOTINIB,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:LORLATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,CRIZOTINIB,TARGETED_THERAPY,LORLATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LORLATINIB",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "DABRAFENIB",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 1:DABRAFENIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,DABRAFENIB",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,DABRAFENIB,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "LUMAKRAS",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:LUMAKRAS:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,LUMAKRAS",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ERBITUX",
              size: 14,
              key: "INCtumor:NSCLC:STAGE 1:ERBITUX",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,ERBITUX",
              children: [
                {
                  name: "RADIATION",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,ERBITUX,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,ERBITUX,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 1,ERBITUX,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,ERBITUX,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,ERBITUX,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "RETEVMO",
              size: 14,
              key: "INCtumor:NSCLC:STAGE 1:RETEVMO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,RETEVMO",
              children: [
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,RETEVMO,RADIATION",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "IRESSA",
              size: 8,
              key: "INCtumor:NSCLC:STAGE 1:IRESSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,IRESSA",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "LORLATINIB",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 1:LORLATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:LORLATINIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,LORLATINIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VEMURAFENIB",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 1:VEMURAFENIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VEMURAFENIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,VEMURAFENIB",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:VEMURAFENIB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,VEMURAFENIB,CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:VEMURAFENIB:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 1,VEMURAFENIB,CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "VEMURAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 1:VEMURAFENIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:VEMURAFENIB:CHEMOTHERAPY:OPDIVO:VEMURAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 1,VEMURAFENIB,CHEMOTHERAPY,OPDIVO,VEMURAFENIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "BRIGATINIB",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 1:BRIGATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BRIGATINIB:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,BRIGATINIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VITRAKVI",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 1:VITRAKVI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VITRAKVI:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,VITRAKVI",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CYRAMZA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:CYRAMZA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,CYRAMZA",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "GAVRETO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:GAVRETO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:GAVRETO:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,GAVRETO",
              children: [
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GAVRETO:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 1,GAVRETO,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ZYKADIA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:ZYKADIA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ZYKADIA:2020",
              nuuid: "NSCLC,STAGE 1,TARGETED_THERAPY,ZYKADIA",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHERS",
          size: 1656,
          key: "INCtumor:NSCLC:STAGE 1:OTHERS",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
          nuuid: "NSCLC,STAGE 1,OTHERS",
          children: [
            {
              name: "SURGERY",
              size: 142,
              key: "INCtumor:NSCLC:STAGE 1:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 115,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 1:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 1,OTHERS,SURGERY,RADIATION,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERLOTINIB_HCL",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,SURGERY,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 67,
              key: "INCtumor:NSCLC:STAGE 1:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 47,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "ERBITUX+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERBITUX+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:ERBITUX$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,RADIATION,ERBITUX+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 36,
              key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OTHERS",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,OTHERS",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,OTHERS,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 1,OTHERS,CHEMOTHERAPY,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 11,
              key: "INCtumor:NSCLC:STAGE 1:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TKI,TAGRISSO",
                  children: [
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 1,OTHERS,TAGRISSO,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:TAGRISSO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TAGRISSO$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 1,OTHERS,TAGRISSO+CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 11,
              key: "INCtumor:NSCLC:STAGE 1:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,OTHER IO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 9,
              key: "INCtumor:NSCLC:STAGE 1:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 1:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "LUMAKRAS",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 1:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
                {
                  name: "ALECENSA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "ERBITUX",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,ERBITUX",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:ERBITUX:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 1,OTHERS,ERBITUX,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERBITUX",
                },
                {
                  name: "RETEVMO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:RETEVMO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RETEVMO:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,TARGETED_THERAPY,RETEVMO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "RETEVMO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CRT",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 1:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 1,OTHERS,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,IMFINZI",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 1:KEYTRUDA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 1,OTHERS,KEYTRUDA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "UNTREATED",
          size: 25741,
          key: "INCtumor:NSCLC:STAGE 1:UNTREATED",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE1:2020",
          nuuid: "NSCLC,STAGE 1,UNTREATED",
          children: [],
          comment:
            " Patients who didn't receive a treatment within NSCLC Stage3 patients",
        },
      ],
      nuuid: "NSCLC,STAGE 1",
    },
    {
      name: "STAGE 3",
      size: 31027,
      id: "nsclc:G:3:WHEEL:I:INCtumor:NSCLC:STAGE 3:STAGE 3:2020",
      children: [
        {
          name: "SURGERY",
          size: 3188,
          key: "INCtumor:NSCLC:STAGE 3:SURGERY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
          nuuid: "NSCLC,STAGE 3,SURGERY",
          children: [
            {
              name: "Wait & Watch",
              size: 1061,
              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::SURGERY:WW:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "CRT",
              size: 1324,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 705,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 180,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 66,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:IMFINZI:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,IMFINZI,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 90,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 27,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,TARGETED_THERAPY",
                      children: [
                        {
                          name: "CRIZOTINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:CRIZOTINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,TARGETED_THERAPY,CRIZOTINIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "CRIZOTINIB",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CHEMOTHERAPY:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,CHEMOTHERAPY,TARGETED_THERAPY,LUMAKRAS",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "LUMAKRAS",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 51,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 24,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,RADIATION,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,RADIATION,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,RADIATION,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:RADIATION:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,RADIATION,OTHER IO,TECENTRIQ",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "TECENTRIQ",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 51,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 33,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,KEYTRUDA,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CRT:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 3,SURGERY,CRT,KEYTRUDA,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 14,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:OPDIVO:KEYTRUDA:2020",
                          nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OPDIVO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 17,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IO+CHEMOTHERAPY",
                  size: 13,
                  key: "INCtumor:NSCLC:STAGE 3:IO+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,IO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 11,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CRT,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA+CHEMOTHERAPY",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO+CHEMOTHERAPY",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,ALECENSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ALECENSA",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,CRIZOTINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CRIZOTINIB",
                    },
                    {
                      name: "LORLATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED_THERAPY,LORLATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LORLATINIB",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED THERAPY+CHEMOTHERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED THERAPY+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:2020",
                  nuuid:
                    "NSCLC,STAGE 3,SURGERY,CRT,TARGETED THERAPY+CHEMOTHERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CRT:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CRT,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 484,
              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 353,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 25,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OTHER IO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,RADIATION,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,RADIATION,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,RADIATION,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 14,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,IMFINZI,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 11,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 11,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,CRT",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,CRT,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,TARGETED_THERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:BEVACIZUMAB:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,BEVACIZUMAB,IMFINZI",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,CHEMOTHERAPY,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 59,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 41,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,RADIATION,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,RADIATION,CRT",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,RADIATION,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 44,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 36,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CRT",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,KEYTRUDA,CRT",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,KEYTRUDA,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TAGRISSO",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,TAGRISSO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TAGRISSO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 3:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,OTHERS",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OTHERS:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,OTHERS,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 5,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "ALECENSA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "BRIGATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BRIGATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:BRIGATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY,BRIGATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BRIGATINIB",
                },
                {
                  name: "DABRAFENIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY,DABRAFENIB",
                  children: [
                    {
                      name: "CRT",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,SURGERY,DABRAFENIB,CRT",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:CRT:WW:2020",
                          nuuid: "NSCLC,STAGE 3,SURGERY,DABRAFENIB,CRT,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 3,SURGERY,TARGETED_THERAPY,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IMFINZI",
          size: 282,
          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
          nuuid: "NSCLC,STAGE 3,IMFINZI",
          children: [
            {
              name: "Wait & Watch",
              size: 145,
              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::IMFINZI:WW:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "CHEMOTHERAPY",
              size: 23,
              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CRT",
              size: 12,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CRT:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,CRT,CHEMOTHERAPY",
                  children: [
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CRT:CHEMOTHERAPY:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,IMFINZI,CRT,CHEMOTHERAPY,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CRT:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,CRT,RADIATION",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CRT:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,IMFINZI,CRT,RADIATION,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:IMFINZI:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,RADIATION,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ALECENSA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:RADIATION:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,RADIATION,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "ALECENSA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 5,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,OTHER IO",
              children: [
                {
                  name: "OPDIVO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,IMFINZI,OPDIVO,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,IMFINZI,OTHER IO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,SURGERY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TAGRISSO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 3,IMFINZI,TAGRISSO",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHER IO",
          size: 830,
          key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERIO:2020",
          nuuid: "NSCLC,STAGE 3,OTHER IO",
          children: [
            {
              name: "KEYTRUDA",
              size: 620,
              key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
              nuuid: "NSCLC,STAGE 3,OTHER IO,KEYTRUDA",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 59,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OPDIVO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY",
                      children: [
                        {
                          name: "ERBITUX",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:2020",
                          nuuid:
                            "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY,ERBITUX",
                          children: [
                            {
                              name: "OTHERS",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:ERBITUX:OTHERS:2020",
                              nuuid:
                                "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,ERBITUX,OTHERS",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "ERBITUX",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY,DABRAFENIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "DABRAFENIB",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 26,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,KEYTRUDA,CRT,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRT:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,CRT,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 21,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,KEYTRUDA,RADIATION,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:RADIATION:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,RADIATION,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,OTHER IO",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid: "NSCLC,STAGE 3,KEYTRUDA,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                    {
                      name: "YERVOY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:YERVOY",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:YERVOY:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,OTHER IO,YERVOY",
                      children: [
                        {
                          name: "CRT",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CRT",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:YERVOY:CRT:2020",
                          nuuid: "NSCLC,STAGE 3,KEYTRUDA,YERVOY,CRT",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:YERVOY:CRT:OPDIVO:2020",
                              nuuid: "NSCLC,STAGE 3,KEYTRUDA,YERVOY,CRT,OPDIVO",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "YERVOY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,TARGETED_THERAPY",
                  children: [
                    {
                      name: "DABRAFENIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,KEYTRUDA,TARGETED_THERAPY,DABRAFENIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "DABRAFENIB",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,KEYTRUDA,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,KEYTRUDA,TARGETED_THERAPY,CRIZOTINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CRIZOTINIB",
                    },
                    {
                      name: "CYRAMZA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CYRAMZA:2020",
                      nuuid: "NSCLC,STAGE 3,KEYTRUDA,TARGETED_THERAPY,CYRAMZA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,KEYTRUDA,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "OPDIVO",
              size: 138,
              key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:2020",
              nuuid: "NSCLC,STAGE 3,OTHER IO,OPDIVO",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OPDIVO,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,OPDIVO,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ERBITUX",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 3,OPDIVO,ERBITUX",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,OPDIVO,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,OTHER IO,TECENTRIQ",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:TECENTRIQ:RADIATION:2020",
                          nuuid: "NSCLC,STAGE 3,OPDIVO,TECENTRIQ,RADIATION",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,OPDIVO,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OPDIVO,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TECENTRIQ",
              size: 71,
              key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:2020",
              nuuid: "NSCLC,STAGE 3,OTHER IO,TECENTRIQ",
              children: [
                {
                  name: "RADIATION",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,TECENTRIQ,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,TECENTRIQ,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,TECENTRIQ,RADIATION,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "BAVENCIO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:BAVENCIO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BAVENCIO:2020",
              nuuid: "NSCLC,STAGE 3,OTHER IO,BAVENCIO",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OPDIVO+CHEMOTHERAPY",
          size: 1,
          key: "INCtumor:NSCLC:STAGE 3:OPDIVO+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 3,OPDIVO+CHEMOTHERAPY",
          children: [],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "BEVACIZUMAB+CHEMOTHERAPY",
          size: 7,
          key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 3,BEVACIZUMAB+CHEMOTHERAPY",
          children: [
            {
              name: "CRT",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CRT:2020",
              nuuid: "NSCLC,STAGE 3,BEVACIZUMAB+CHEMOTHERAPY,CRT",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,BEVACIZUMAB+CHEMOTHERAPY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB+CHEMOTHERAPY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CHEMOTHERAPY",
          size: 3222,
          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY",
          children: [
            {
              name: "Wait & Watch",
              size: 1173,
              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CHEMOTHERAPY:WW:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "IMFINZI",
              size: 419,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 151,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OTHER IO",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,OTHER IO",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TARGETED_THERAPY",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TARGETED_THERAPY,ALECENSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ALECENSA",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TARGETED_THERAPY,RETEVMO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "RETEVMO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,IMFINZI,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 359,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 191,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OTHER IO",
                  size: 23,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:IMFINZI:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,IMFINZI,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,TARGETED_THERAPY",
                  children: [
                    {
                      name: "LORLATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:LORLATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,TARGETED_THERAPY,LORLATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LORLATINIB",
                    },
                    {
                      name: "TEMOZOLOMIDE",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TEMOZOLOMIDE",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TEMOZOLOMIDE:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,TARGETED_THERAPY,TEMOZOLOMIDE",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TEMOZOLOMIDE",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,RADIATION,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 185,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 116,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "RADIATION",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,TARGETED_THERAPY",
                      children: [
                        {
                          name: "CYRAMZA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CYRAMZA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:CYRAMZA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,TARGETED_THERAPY,CYRAMZA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "CYRAMZA",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,TARGETED_THERAPY,GILOTRIF",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "GILOTRIF",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,KEYTRUDA,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 57,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:ERBITUX:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OPDIVO,ERBITUX",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 11,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TECENTRIQ,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TECENTRIQ,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,TECENTRIQ,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
                {
                  name: "BAVENCIO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BAVENCIO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BAVENCIO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHER IO,BAVENCIO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BAVENCIO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 67,
              key: "INCtumor:NSCLC:STAGE 3:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 42,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,RADIATION,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:SURGERY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,SURGERY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CRT",
              size: 47,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 14,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,CHEMOTHERAPY",
                  children: [
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:SURGERY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,SURGERY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CAPMATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRT:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,CRT,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TAGRISSO",
              size: 44,
              key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TAGRISSO",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 27,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,BEVACIZUMAB,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CHEMOTHERAPY,BEVACIZUMAB,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "GILOTRIF",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,GILOTRIF",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,GILOTRIF,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "LUMAKRAS",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
                {
                  name: "ALECENSA",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "CRIZOTINIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CRIZOTINIB:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "CYRAMZA",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CYRAMZA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,CYRAMZA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA",
                },
                {
                  name: "ERBITUX",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,ERBITUX",
                  children: [
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:ERBITUX:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,ERBITUX,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERBITUX",
                },
                {
                  name: "GAVRETO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GAVRETO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:GAVRETO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,GAVRETO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GAVRETO",
                },
                {
                  name: "RETEVMO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RETEVMO:2020",
                  nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,TARGETED_THERAPY,RETEVMO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "RETEVMO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 3:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,OTHERS",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "BEVACIZUMAB+CHEMOTHERAPY",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CRT",
          size: 10460,
          key: "INCtumor:NSCLC:STAGE 3:CRT",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
          nuuid: "NSCLC,STAGE 3,CRT",
          children: [
            {
              name: "Wait & Watch",
              size: 3627,
              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CRT:WW:2020",
              nuuid: "NSCLC,STAGE 3,CRT,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "IMFINZI",
              size: 2668,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,CRT,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1024,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 41,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:IMFINZI:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:CHEMOTHERAPY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,CHEMOTHERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 29,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 19,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:KEYTRUDA:OPDIVO:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,IMFINZI,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,RADIATION",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,RADIATION,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:RADIATION:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,RADIATION,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:IMFINZI:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY",
                  children: [
                    {
                      name: "LUMAKRAS",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                    {
                      name: "ALECENSA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,ALECENSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ALECENSA",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,DABRAFENIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "DABRAFENIB",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,GILOTRIF",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "GILOTRIF",
                    },
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:RETEVMO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,TARGETED_THERAPY,RETEVMO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "RETEVMO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI,IMFINZI+CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:IMFINZI:IMFINZI$CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,IMFINZI,IMFINZI+CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 678,
              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 168,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 47,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 15,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:IMFINZI:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,IMFINZI,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 37,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 21,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:KEYTRUDA:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,KEYTRUDA,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:KEYTRUDA:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,KEYTRUDA,RADIATION",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 21,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:RADIATION:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,RADIATION,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED THERAPY+CHEMOTHERAPY",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED THERAPY+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,GILOTRIF",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA+CHEMOTHERAPY",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:SURGERY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:SURGERY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TKI",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TKI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TKI",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TKI,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TAGRISSO",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:ERLOTINIB_HCL:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,TKI,ERLOTINIB_HCL",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ERLOTINIB_HCL",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CHEMOTHERAPY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,CHEMOTHERAPY,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 366,
              key: "INCtumor:NSCLC:STAGE 3:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,CRT,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 164,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 62,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 13,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,OTHER IO",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 57,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 36,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:IMFINZI:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 4,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:RADIATION:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,RADIATION,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,SURGERY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IO+CHEMOTHERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:IO+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA+CHEMOTHERAPY",
                    },
                    {
                      name: "OPDIVO+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:OPDIVO$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED THERAPY+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED THERAPY+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,SURGERY,TARGETED THERAPY+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA+CHEMOTHERAPY",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,SURGERY,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI+CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:SURGERY:IMFINZI$CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,IMFINZI+CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,SURGERY,TARGETED_THERAPY",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,TARGETED_THERAPY,ALECENSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ALECENSA",
                    },
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:SURGERY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,SURGERY,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 504,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CRT,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 292,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "TARGETED_THERAPY",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY",
                          children: [
                            {
                              name: "CAPMATINIB",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:CHEMOTHERAPY:CAPMATINIB:2020",
                              nuuid:
                                "NSCLC,STAGE 3,CRT,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY,CAPMATINIB",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                              parentId: "CAPMATINIB",
                            },
                            {
                              name: "GILOTRIF",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:CHEMOTHERAPY:GILOTRIF:2020",
                              nuuid:
                                "NSCLC,STAGE 3,CRT,KEYTRUDA,CHEMOTHERAPY,TARGETED_THERAPY,GILOTRIF",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                              parentId: "GILOTRIF",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,TARGETED_THERAPY",
                      children: [
                        {
                          name: "LUMAKRAS",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,TARGETED_THERAPY,LUMAKRAS",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "LUMAKRAS",
                        },
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,TARGETED_THERAPY,DABRAFENIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "DABRAFENIB",
                        },
                        {
                          name: "GILOTRIF",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:GILOTRIF:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,TARGETED_THERAPY,GILOTRIF",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "GILOTRIF",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,OTHER IO",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:OPDIVO:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,OTHER IO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "OPDIVO",
                        },
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA,OTHER IO,TECENTRIQ",
                          children: [
                            {
                              name: "CHEMOTHERAPY",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:TECENTRIQ:CHEMOTHERAPY:2020",
                              nuuid:
                                "NSCLC,STAGE 3,CRT,KEYTRUDA,TECENTRIQ,CHEMOTHERAPY",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "TECENTRIQ",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,KEYTRUDA,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 164,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 10,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:OPDIVO:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:OPDIVO:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHER IO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,OTHER IO",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:KEYTRUDA:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,OTHER IO,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "KEYTRUDA",
                        },
                        {
                          name: "YERVOY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:YERVOY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:YERVOY:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,OTHER IO,YERVOY",
                          children: [
                            {
                              name: "OPDIVO",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:YERVOY:OPDIVO:2020",
                              nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,YERVOY,OPDIVO",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "YERVOY",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OPDIVO,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 48,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHER IO,TECENTRIQ",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,TECENTRIQ,CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,TECENTRIQ,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,TECENTRIQ,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 270,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,CRT,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 110,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:CRT:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 23,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,RADIATION,CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,RADIATION,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,RADIATION,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,RADIATION,OPDIVO,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:CRT:RADIATION:OPDIVO:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 3,CRT,RADIATION,OPDIVO,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,SURGERY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,RADIATION,SURGERY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RADIATION:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,RADIATION,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 107,
              key: "INCtumor:NSCLC:STAGE 3:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CRT,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 97,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,CRIZOTINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OPDIVO+CHEMOTHERAPY,OPDIVO",
                      children: [
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OPDIVO$CHEMOTHERAPY:OPDIVO:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,OPDIVO+CHEMOTHERAPY,OPDIVO,TECENTRIQ",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TECENTRIQ$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,TECENTRIQ+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 44,
              key: "INCtumor:NSCLC:STAGE 3:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CRT,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 41,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TKI,TAGRISSO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TAGRISSO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,TAGRISSO,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "ERLOTINIB_HCL",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:ERLOTINIB_HCL",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TAGRISSO:ERLOTINIB_HCL:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,TAGRISSO,ERLOTINIB_HCL",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TAGRISSO:ERLOTINIB_HCL:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,TAGRISSO,ERLOTINIB_HCL,RADIATION",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ERLOTINIB_HCL",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TKI,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERLOTINIB_HCL",
                },
                {
                  name: "TAGRISSO+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:TAGRISSO$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TKI,TAGRISSO+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO+CHEMOTHERAPY",
                },
                {
                  name: "VIZIMPRO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:VIZIMPRO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:VIZIMPRO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TKI,VIZIMPRO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "VIZIMPRO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 35,
              key: "INCtumor:NSCLC:STAGE 3:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,CRT,OTHERS",
              children: [
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHERS,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CRT:OTHERS:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OTHERS,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHERS,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,OTHERS,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OTHERS,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:OTHERS:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,OTHERS,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED THERAPY+CHEMOTHERAPY",
              size: 48,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED THERAPY+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CRT,TARGETED THERAPY+CHEMOTHERAPY",
              children: [
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 24,
                  key: "INCtumor:NSCLC:STAGE 3:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CYRAMZA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CYRAMZA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CYRAMZA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,CYRAMZA+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CYRAMZA$CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,CYRAMZA+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA+CHEMOTHERAPY",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 23,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB$CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,BEVACIZUMAB+CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,BEVACIZUMAB+CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                },
                {
                  name: "GILOTRIF+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GILOTRIF$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 3,CRT,TARGETED THERAPY+CHEMOTHERAPY,GILOTRIF+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GILOTRIF$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,CRT,GILOTRIF+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GILOTRIF$CHEMOTHERAPY:CHEMOTHERAPY:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,GILOTRIF+CHEMOTHERAPY,CHEMOTHERAPY,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI+CHEMOTHERAPY",
              size: 22,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,CRT,IMFINZI+CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CRT:IMFINZI$CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI+CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OPDIVO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CRT:IMFINZI$CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,IMFINZI+CHEMOTHERAPY,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 34,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
              nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY",
              children: [
                {
                  name: "GILOTRIF",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,GILOTRIF",
                  children: [
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GILOTRIF:ALECENSA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,GILOTRIF,ALECENSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "BEVACIZUMAB",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,BEVACIZUMAB,KEYTRUDA",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB:KEYTRUDA:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,BEVACIZUMAB,KEYTRUDA,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,BEVACIZUMAB,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:CRT:BEVACIZUMAB:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 3,CRT,BEVACIZUMAB,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "CAPMATINIB",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CAPMATINIB",
                },
                {
                  name: "ALECENSA",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "DABRAFENIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,DABRAFENIB",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRT:DABRAFENIB:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,CRT,DABRAFENIB,CHEMOTHERAPY",
                      children: [
                        {
                          name: "DABRAFENIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRT:DABRAFENIB:CHEMOTHERAPY:DABRAFENIB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRT,DABRAFENIB,CHEMOTHERAPY,DABRAFENIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
                {
                  name: "LUMAKRAS",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
                {
                  name: "RETEVMO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:RETEVMO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,RETEVMO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "RETEVMO",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "CYRAMZA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CYRAMZA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:CYRAMZA:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,CYRAMZA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA",
                },
                {
                  name: "ERBITUX",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:ERBITUX:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,ERBITUX",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERBITUX",
                },
                {
                  name: "GAVRETO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GAVRETO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:GAVRETO:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,GAVRETO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GAVRETO",
                },
                {
                  name: "LORLATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:LORLATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRT,TARGETED_THERAPY,LORLATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LORLATINIB",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "RADIATION",
          size: 1296,
          key: "INCtumor:NSCLC:STAGE 3:RADIATION",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
          nuuid: "NSCLC,STAGE 3,RADIATION",
          children: [
            {
              name: "Wait & Watch",
              size: 738,
              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::RADIATION:WW:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "CHEMOTHERAPY",
              size: 132,
              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 45,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,CRT",
                  children: [
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:CRT:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,CRT,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:CRT:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,CRT,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CHEMOTHERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 75,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 55,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,KEYTRUDA,OPDIVO",
                      children: [
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:OPDIVO:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 3,RADIATION,KEYTRUDA,OPDIVO,TECENTRIQ",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 14,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,OPDIVO,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 40,
              key: "INCtumor:NSCLC:STAGE 3:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 19,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 3,RADIATION,SURGERY,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CRT",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CHEMOTHERAPY:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,CHEMOTHERAPY,CRT",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,SURGERY,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CRT",
              size: 22,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRT:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 13,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CRT,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CRT,SURGERY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRT:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,CRT,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 13,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 8,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,IMFINZI,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,IMFINZI,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 3:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,OTHERS",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHERS,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHERS,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,OTHERS,SURGERY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 7,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,TARGETED_THERAPY",
              children: [
                {
                  name: "CAPMATINIB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,TARGETED_THERAPY,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CAPMATINIB",
                },
                {
                  name: "GILOTRIF",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,TARGETED_THERAPY,GILOTRIF",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,RADIATION,GILOTRIF,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "BEVACIZUMAB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "DABRAFENIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 3,RADIATION,TARGETED_THERAPY,DABRAFENIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TAGRISSO",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,TAGRISSO",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "BEVACIZUMAB+CHEMOTHERAPY",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,RADIATION,BEVACIZUMAB+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TKI",
          size: 565,
          key: "INCtumor:NSCLC:STAGE 3:TKI",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TKI:2020",
          nuuid: "NSCLC,STAGE 3,TKI",
          children: [
            {
              name: "TAGRISSO",
              size: 538,
              key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 3,TKI,TAGRISSO",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 14,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,TARGETED_THERAPY",
                  children: [
                    {
                      name: "GILOTRIF",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
                      nuuid: "NSCLC,STAGE 3,TAGRISSO,TARGETED_THERAPY,GILOTRIF",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "GILOTRIF",
                    },
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,TAGRISSO,TARGETED_THERAPY,CRIZOTINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CRIZOTINIB",
                    },
                    {
                      name: "IRESSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IRESSA",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:IRESSA:2020",
                      nuuid: "NSCLC,STAGE 3,TAGRISSO,TARGETED_THERAPY,IRESSA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "IRESSA",
                    },
                    {
                      name: "TEMOZOLOMIDE",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TEMOZOLOMIDE",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:TEMOZOLOMIDE:2020",
                      nuuid:
                        "NSCLC,STAGE 3,TAGRISSO,TARGETED_THERAPY,TEMOZOLOMIDE",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TEMOZOLOMIDE",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:ERLOTINIB_HCL",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,ERLOTINIB_HCL",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,TAGRISSO,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,TAGRISSO,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:CRT:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,TAGRISSO,CRT,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:TAGRISSO:CRT:CHEMOTHERAPY:WW:2020",
                          nuuid: "NSCLC,STAGE 3,TAGRISSO,CRT,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,TAGRISSO,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ERLOTINIB_HCL",
              size: 18,
              key: "INCtumor:NSCLC:STAGE 3:ERLOTINIB_HCL",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERLOTINIB_HCL:2020",
              nuuid: "NSCLC,STAGE 3,TKI,ERLOTINIB_HCL",
              children: [
                {
                  name: "TAGRISSO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERLOTINIB_HCL:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,ERLOTINIB_HCL,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TARCEVA",
              size: 9,
              key: "INCtumor:NSCLC:STAGE 3:TARCEVA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TARCEVA:2020",
              nuuid: "NSCLC,STAGE 3,TKI,TARCEVA",
              children: [
                {
                  name: "TAGRISSO",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:TARCEVA:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,TARCEVA,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TARGETED_THERAPY",
          size: 283,
          key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TARGETED_THERAPY:2020",
          nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY",
          children: [
            {
              name: "BEVACIZUMAB",
              size: 105,
              key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,BEVACIZUMAB",
              children: [
                {
                  name: "CRT",
                  size: 64,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 23,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 18,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 5,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:IMFINZI:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,CRT,IMFINZI,BEVACIZUMAB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,CHEMOTHERAPY",
                      children: [
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,CRT,CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:RADIATION:WW:2020",
                          nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:RADIATION:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,CRT,RADIATION,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 3,BEVACIZUMAB,CRT,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CRT:SURGERY:WW:2020",
                          nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CRT,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION,CHEMOTHERAPY",
                      children: [
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION,CHEMOTHERAPY,IMFINZI",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,RADIATION,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CHEMOTHERAPY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CHEMOTHERAPY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,CHEMOTHERAPY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY",
                  children: [
                    {
                      name: "CRT",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,CRT",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:CRT:WW:2020",
                          nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,CRT,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:CRT:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,CRT,CHEMOTHERAPY",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:CRT:CHEMOTHERAPY:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,CRT,CHEMOTHERAPY,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:CRT:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,CRT,IMFINZI",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,BEVACIZUMAB,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,BEVACIZUMAB,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "GILOTRIF",
              size: 60,
              key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,GILOTRIF",
              children: [
                {
                  name: "TAGRISSO",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,GILOTRIF,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,GILOTRIF,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,GILOTRIF,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,GILOTRIF,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,GILOTRIF,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IRESSA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:IRESSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:IRESSA:2020",
                  nuuid: "NSCLC,STAGE 3,GILOTRIF,IRESSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ALECENSA",
              size: 32,
              key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,ALECENSA",
              children: [
                {
                  name: "CRT",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,ALECENSA,CRT",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:CRT:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,ALECENSA,CRT,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "LORLATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,ALECENSA,LORLATINIB",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,ALECENSA,LORLATINIB,CHEMOTHERAPY",
                      children: [
                        {
                          name: "KEYTRUDA",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:LORLATINIB:CHEMOTHERAPY:KEYTRUDA:2020",
                          nuuid:
                            "NSCLC,STAGE 3,ALECENSA,LORLATINIB,CHEMOTHERAPY,KEYTRUDA",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "RETEVMO",
              size: 18,
              key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,RETEVMO",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,RETEVMO,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "GAVRETO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GAVRETO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:GAVRETO:2020",
                  nuuid: "NSCLC,STAGE 3,RETEVMO,GAVRETO",
                  children: [
                    {
                      name: "RETEVMO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RETEVMO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:GAVRETO:RETEVMO:2020",
                      nuuid: "NSCLC,STAGE 3,RETEVMO,GAVRETO,RETEVMO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CAPMATINIB",
              size: 17,
              key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,CAPMATINIB",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,CAPMATINIB,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CAPMATINIB,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,CAPMATINIB,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "LUMAKRAS",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 3:LUMAKRAS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:LUMAKRAS:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,LUMAKRAS",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CRIZOTINIB",
              size: 11,
              key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,CRIZOTINIB",
              children: [
                {
                  name: "CAPMATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRIZOTINIB,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 3,CRIZOTINIB,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,CRIZOTINIB,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:TECENTRIQ:2020",
                      nuuid: "NSCLC,STAGE 3,CRIZOTINIB,OTHER IO,TECENTRIQ",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CRIZOTINIB:TECENTRIQ:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 3,CRIZOTINIB,TECENTRIQ,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "DABRAFENIB",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,DABRAFENIB",
              children: [
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 3,DABRAFENIB,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,DABRAFENIB,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:DABRAFENIB:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,DABRAFENIB,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "IRESSA",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 3:IRESSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,IRESSA",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,IRESSA,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,IRESSA,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,IRESSA,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "BRIGATINIB",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 3:BRIGATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BRIGATINIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,BRIGATINIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ERBITUX",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,ERBITUX",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,ERBITUX,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRT",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,ERBITUX,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id: "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,ERBITUX,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VEMURAFENIB",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 3:VEMURAFENIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VEMURAFENIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,VEMURAFENIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "LORLATINIB",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:LORLATINIB:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,LORLATINIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "VITRAKVI",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 3:VITRAKVI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:VITRAKVI:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,VITRAKVI",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CYRAMZA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 3:CYRAMZA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA:2020",
              nuuid: "NSCLC,STAGE 3,TARGETED_THERAPY,CYRAMZA",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHERS",
          size: 711,
          key: "INCtumor:NSCLC:STAGE 3:OTHERS",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
          nuuid: "NSCLC,STAGE 3,OTHERS",
          children: [
            {
              name: "CRT",
              size: 194,
              key: "INCtumor:NSCLC:STAGE 3:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,CRT",
              children: [
                {
                  name: "Wait & Watch",
                  size: 69,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:WW:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 33,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 9,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OTHERS",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:IMFINZI:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,IMFINZI,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:IMFINZI:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,IMFINZI,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 13,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,CHEMOTHERAPY,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:CHEMOTHERAPY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,CHEMOTHERAPY,RADIATION",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 11,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 7,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,OTHERS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,SURGERY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,SURGERY,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,SURGERY,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 3,OTHERS,CRT,SURGERY,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CRT,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CRT:SURGERY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,OTHERS,CRT,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CRT,TARGETED_THERAPY",
                  children: [
                    {
                      name: "CAPMATINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CAPMATINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:CAPMATINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CRT,TARGETED_THERAPY,CAPMATINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CAPMATINIB",
                    },
                    {
                      name: "DABRAFENIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:DABRAFENIB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CRT,TARGETED_THERAPY,DABRAFENIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "DABRAFENIB",
                    },
                    {
                      name: "ERBITUX",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ERBITUX",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:ERBITUX:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CRT,TARGETED_THERAPY,ERBITUX",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ERBITUX",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 71,
              key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 23,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 10,
                  key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "OTHERS",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:OTHERS:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,RADIATION,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:RADIATION:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,RADIATION,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,IMFINZI,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,IMFINZI,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHERS",
                  children: [
                    {
                      name: "SURGERY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:SURGERY:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHERS,SURGERY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:OTHERS:SURGERY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,OTHERS,SURGERY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,TARGETED_THERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "ALECENSA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:ALECENSA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:ALECENSA:2020",
                      nuuid:
                        "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,TARGETED_THERAPY,ALECENSA",
                      children: [
                        {
                          name: "LORLATINIB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:LORLATINIB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:ALECENSA:LORLATINIB:2020",
                          nuuid:
                            "NSCLC,STAGE 3,OTHERS,CHEMOTHERAPY,ALECENSA,LORLATINIB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "ALECENSA",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 28,
              key: "INCtumor:NSCLC:STAGE 3:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CRT",
                  size: 15,
                  key: "INCtumor:NSCLC:STAGE 3:CRT",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,IMFINZI",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:IMFINZI:WW:2020",
                          nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,IMFINZI,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CRT:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CRT,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TECENTRIQ",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,SURGERY,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 22,
              key: "INCtumor:NSCLC:STAGE 3:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 13,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "DABRAFENIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:DABRAFENIB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,DABRAFENIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:SURGERY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,SURGERY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:SURGERY:WW:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,RADIATION,SURGERY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 16,
              key: "INCtumor:NSCLC:STAGE 3:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 12,
                  key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CRT",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,KEYTRUDA,CRT",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,KEYTRUDA,OTHERS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,KEYTRUDA,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 3:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:OPDIVO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,OPDIVO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 3:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 3:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,TKI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "TARCEVA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:TARCEVA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:TARCEVA:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,TKI,TARCEVA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TARCEVA",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 3:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 3:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [
                    {
                      name: "CRT",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 3:CRT",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:CRT:2020",
                      nuuid: "NSCLC,STAGE 3,OTHERS,BEVACIZUMAB,CRT",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 3,
                          key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:CRT:WW:2020",
                          nuuid: "NSCLC,STAGE 3,OTHERS,BEVACIZUMAB,CRT,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "CRIZOTINIB",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 3:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,TARGETED_THERAPY,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 3:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 3,OTHERS,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 3:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 3,OTHERS,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "UNTREATED",
          size: 9515,
          key: "INCtumor:NSCLC:STAGE 3:UNTREATED",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE3:2020",
          nuuid: "NSCLC,STAGE 3,UNTREATED",
          children: [],
          comment:
            " Patients who didn't receive a treatment within NSCLC Stage3 patients",
        },
      ],
      nuuid: "NSCLC,STAGE 3",
    },
    {
      name: "STAGE 2",
      size: 11131,
      id: "nsclc:G:3:WHEEL:I:INCtumor:NSCLC:STAGE 2:STAGE 2:2020",
      children: [
        {
          name: "SURGERY",
          size: 388,
          key: "INCtumor:NSCLC:STAGE 2:SURGERY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
          nuuid: "NSCLC,STAGE 2,SURGERY",
          children: [
            {
              name: "Wait & Watch",
              size: 88,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::SURGERY:WW:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "RADIATION",
              size: 222,
              key: "INCtumor:NSCLC:STAGE 2:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 171,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:RADIATION:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,RADIATION,OTHER IO,KEYTRUDA",
                      children: [
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,SURGERY,RADIATION,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,RADIATION,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IO+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:IO+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,IO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,RADIATION,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA+CHEMOTHERAPY",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,RADIATION,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:RADIATION:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,RADIATION,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 15,
              key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:IMFINZI:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,IMFINZI,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:SURGERY:CHEMOTHERAPY:KEYTRUDA:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,SURGERY,CHEMOTHERAPY,KEYTRUDA,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,KEYTRUDA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,IMFINZI",
              children: [
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:IMFINZI:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,IMFINZI,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 2,SURGERY,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,SURGERY,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "DABRAFENIB",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:DABRAFENIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:DABRAFENIB:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,DABRAFENIB",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TAGRISSO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:SURGERY:TAGRISSO:2020",
              nuuid: "NSCLC,STAGE 2,SURGERY,TAGRISSO",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IMFINZI",
          size: 18,
          key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:2020",
          nuuid: "NSCLC,STAGE 2,IMFINZI",
          children: [
            {
              name: "Wait & Watch",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::IMFINZI:WW:2020",
              nuuid: "NSCLC,STAGE 2,IMFINZI,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,IMFINZI,CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "KEYTRUDA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:2020",
              nuuid: "NSCLC,STAGE 2,IMFINZI,KEYTRUDA",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI:KEYTRUDA:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,IMFINZI,KEYTRUDA,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHER IO",
          size: 58,
          key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERIO:2020",
          nuuid: "NSCLC,STAGE 2,OTHER IO",
          children: [
            {
              name: "KEYTRUDA",
              size: 47,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:2020",
              nuuid: "NSCLC,STAGE 2,OTHER IO,KEYTRUDA",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "OPDIVO",
              size: 9,
              key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:2020",
              nuuid: "NSCLC,STAGE 2,OTHER IO,OPDIVO",
              children: [
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,OPDIVO,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OPDIVO:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 2,OPDIVO,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,OPDIVO,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TECENTRIQ",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ:2020",
              nuuid: "NSCLC,STAGE 2,OTHER IO,TECENTRIQ",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IO+CHEMOTHERAPY",
          size: 52,
          key: "INCtumor:NSCLC:STAGE 2:IO+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IO$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 2,IO+CHEMOTHERAPY",
          children: [
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 45,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED THERAPY+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:TARGETED THERAPY+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:BEVACIZUMAB$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                    },
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CYRAMZA+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY",
                  children: [
                    {
                      name: "CRIZOTINIB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CRIZOTINIB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:CRIZOTINIB:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY,CRIZOTINIB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "CRIZOTINIB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:KEYTRUDA$CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,KEYTRUDA+CHEMOTHERAPY,RADIATION",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "TECENTRIQ+CHEMOTHERAPY",
              size: 6,
              key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TECENTRIQ$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "OPDIVO+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:OPDIVO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OPDIVO$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,OPDIVO+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TARGETED THERAPY+CHEMOTHERAPY",
          size: 4,
          key: "INCtumor:NSCLC:STAGE 2:TARGETED THERAPY+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TARGETEDTHERAPY$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 2,TARGETED THERAPY+CHEMOTHERAPY",
          children: [
            {
              name: "ERBITUX+CHEMOTHERAPY",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:ERBITUX+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 2,TARGETED THERAPY+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
              children: [
                {
                  name: "RADIATION",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,ERBITUX+CHEMOTHERAPY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ERBITUX$CHEMOTHERAPY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 2,ERBITUX+CHEMOTHERAPY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "BEVACIZUMAB+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 2,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,BEVACIZUMAB+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CYRAMZA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CYRAMZA$CHEMOTHERAPY:2020",
              nuuid:
                "NSCLC,STAGE 2,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "IMFINZI+CHEMOTHERAPY",
          size: 2,
          key: "INCtumor:NSCLC:STAGE 2:IMFINZI+CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:IMFINZI$CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 2,IMFINZI+CHEMOTHERAPY",
          children: [
            {
              name: "Wait & Watch",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::IMFINZI$CHEMOTHERAPY:WW:2020",
              nuuid: "NSCLC,STAGE 2,IMFINZI+CHEMOTHERAPY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CHEMOTHERAPY",
          size: 225,
          key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
          nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY",
          children: [
            {
              name: "Wait & Watch",
              size: 64,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CHEMOTHERAPY:WW:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "IMFINZI",
              size: 47,
              key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 9,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "GILOTRIF",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:CHEMOTHERAPY:GILOTRIF:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,CHEMOTHERAPY,GILOTRIF",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "RADIATION",
              size: 41,
              key: "INCtumor:NSCLC:STAGE 2:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 30,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:CYRAMZA$CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,CYRAMZA+CHEMOTHERAPY,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TECENTRIQ",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:RADIATION:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,RADIATION,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 13,
              key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "IMFINZI",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:IMFINZI:2020",
                          nuuid:
                            "NSCLC,STAGE 2,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY,IMFINZI",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                  children: [
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,CHEMOTHERAPY,TECENTRIQ,CYRAMZA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "TECENTRIQ",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ:CYRAMZA$CHEMOTHERAPY:TECENTRIQ:2020",
                          nuuid:
                            "NSCLC,STAGE 2,CHEMOTHERAPY,TECENTRIQ,CYRAMZA+CHEMOTHERAPY,TECENTRIQ",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 2:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,CHEMOTHERAPY,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,CHEMOTHERAPY,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:OTHERS:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,OTHERS",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CYRAMZA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "GILOTRIF",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,GILOTRIF",
              children: [
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:GILOTRIF:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,GILOTRIF,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IMFINZI+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:IMFINZI+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:CHEMOTHERAPY:IMFINZI$CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,CHEMOTHERAPY,IMFINZI+CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "CRT",
          size: 4,
          key: "INCtumor:NSCLC:STAGE 2:CRT",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:CRT:2020",
          nuuid: "NSCLC,STAGE 2,CRT",
          children: [
            {
              name: "Wait & Watch",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::CRT:WW:2020",
              nuuid: "NSCLC,STAGE 2,CRT,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "RADIATION",
          size: 7881,
          key: "INCtumor:NSCLC:STAGE 2:RADIATION",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
          nuuid: "NSCLC,STAGE 2,RADIATION",
          children: [
            {
              name: "Wait & Watch",
              size: 6255,
              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
              id: "nsclc:G:5:WHEEL:I:SEERtumor::RADIATION:WW:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,WW",
              children: [],
              comment: "Wait and Watch on patients after finishing a therapy",
            },
            {
              name: "IMFINZI",
              size: 115,
              key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,IMFINZI",
              children: [
                {
                  name: "Wait & Watch",
                  size: 51,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:IMFINZI:WW:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,IMFINZI,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,IMFINZI,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,IMFINZI,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:KEYTRUDA$CHEMOTHERAPY:TAGRISSO:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,IMFINZI,KEYTRUDA+CHEMOTHERAPY,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,IMFINZI,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:IMFINZI:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,IMFINZI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 145,
              key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 107,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,OTHER IO,KEYTRUDA",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,KEYTRUDA,CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED_THERAPY",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
                      id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,KEYTRUDA,TARGETED_THERAPY",
                      children: [
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA,TARGETED_THERAPY,BEVACIZUMAB",
                          children: [
                            {
                              name: "KEYTRUDA",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                              id:
                                "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:BEVACIZUMAB:KEYTRUDA:2020",
                              nuuid:
                                "NSCLC,STAGE 2,RADIATION,KEYTRUDA,BEVACIZUMAB,KEYTRUDA",
                              children: [],
                              comment: "Treatment for NSCLC Stage3 patients",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "BEVACIZUMAB",
                        },
                        {
                          name: "LUMAKRAS",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA:LUMAKRAS:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA,TARGETED_THERAPY,LUMAKRAS",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "LUMAKRAS",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 35,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,OTHER IO,OPDIVO",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,OPDIVO,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:OPDIVO:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,OPDIVO,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,OPDIVO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
                {
                  name: "TECENTRIQ",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,OTHER IO,TECENTRIQ",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "IO+CHEMOTHERAPY",
              size: 102,
              key: "INCtumor:NSCLC:STAGE 2:IO+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,IO+CHEMOTHERAPY",
              children: [
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 97,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 6,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                        {
                          name: "KEYTRUDA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "TARGETED THERAPY+CHEMOTHERAPY",
                      size: 2,
                      key:
                        "INCtumor:NSCLC:STAGE 2:TARGETED THERAPY+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "CYRAMZA+CHEMOTHERAPY",
                        },
                        {
                          name: "ERBITUX+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:ERBITUX+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:KEYTRUDA$CHEMOTHERAPY:ERBITUX$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,KEYTRUDA+CHEMOTHERAPY,TARGETED THERAPY+CHEMOTHERAPY,ERBITUX+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                          parentId: "ERBITUX+CHEMOTHERAPY",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA+CHEMOTHERAPY",
                },
                {
                  name: "OPDIVO+CHEMOTHERAPY",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,IO+CHEMOTHERAPY,OPDIVO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CYRAMZA+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OPDIVO$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,OPDIVO+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO+CHEMOTHERAPY",
                },
                {
                  name: "TECENTRIQ+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TECENTRIQ$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TECENTRIQ+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 93,
              key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 33,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id:
                    "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "OTHER IO",
                  size: 14,
                  key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,OTHER IO",
                  children: [
                    {
                      name: "OPDIVO",
                      size: 8,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,OTHER IO,OPDIVO",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:OPDIVO:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,OPDIVO,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "TECENTRIQ",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,OTHER IO,TECENTRIQ",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 7,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 3,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IO+CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 2:IO+CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,IO+CHEMOTHERAPY",
                  children: [
                    {
                      name: "KEYTRUDA+CHEMOTHERAPY",
                      size: 4,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,IO+CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                      children: [
                        {
                          name: "CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                        {
                          name: "CYRAMZA+CHEMOTHERAPY",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CYRAMZA$CHEMOTHERAPY:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA+CHEMOTHERAPY",
                    },
                    {
                      name: "TECENTRIQ+CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TECENTRIQ+CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:TECENTRIQ$CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,IO+CHEMOTHERAPY,TECENTRIQ+CHEMOTHERAPY",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "TECENTRIQ+CHEMOTHERAPY",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CRIZOTINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CRIZOTINIB",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CHEMOTHERAPY:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,CHEMOTHERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 30,
              key: "INCtumor:NSCLC:STAGE 2:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 22,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:SURGERY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,SURGERY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 46,
              key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 23,
                  key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "ALECENSA",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 2:ALECENSA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ALECENSA:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,ALECENSA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ALECENSA",
                },
                {
                  name: "CAPMATINIB",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 2:CAPMATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CAPMATINIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,CAPMATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CAPMATINIB",
                },
                {
                  name: "CRIZOTINIB",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:CRIZOTINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CRIZOTINIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,CRIZOTINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CRIZOTINIB",
                },
                {
                  name: "DABRAFENIB",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:DABRAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,DABRAFENIB",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:CHEMOTHERAPY:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,DABRAFENIB,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:RADIATION:DABRAFENIB:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,DABRAFENIB,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "DABRAFENIB",
                },
                {
                  name: "LUMAKRAS",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
                {
                  name: "RETEVMO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:RETEVMO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:RETEVMO:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,RETEVMO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "RETEVMO",
                },
                {
                  name: "BRIGATINIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:BRIGATINIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BRIGATINIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,BRIGATINIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BRIGATINIB",
                },
                {
                  name: "GILOTRIF",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:GILOTRIF:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,GILOTRIF",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "GILOTRIF",
                },
                {
                  name: "VEMURAFENIB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:VEMURAFENIB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:VEMURAFENIB:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED_THERAPY,VEMURAFENIB",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "VEMURAFENIB",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TKI",
              size: 18,
              key: "INCtumor:NSCLC:STAGE 2:TKI",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,TKI",
              children: [
                {
                  name: "TAGRISSO",
                  size: 17,
                  key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TKI,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "TAGRISSO",
                },
                {
                  name: "ERLOTINIB_HCL",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:ERLOTINIB_HCL",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:2020",
                  nuuid: "NSCLC,STAGE 2,RADIATION,TKI,ERLOTINIB_HCL",
                  children: [
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 2,RADIATION,ERLOTINIB_HCL,TAGRISSO",
                      children: [
                        {
                          name: "OPDIVO",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:ERLOTINIB_HCL:TAGRISSO:OPDIVO:2020",
                          nuuid:
                            "NSCLC,STAGE 2,RADIATION,ERLOTINIB_HCL,TAGRISSO,OPDIVO",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "ERLOTINIB_HCL",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHERS",
              size: 14,
              key: "INCtumor:NSCLC:STAGE 2:OTHERS",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:OTHERS:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,OTHERS",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED THERAPY+CHEMOTHERAPY",
              size: 3,
              key: "INCtumor:NSCLC:STAGE 2:TARGETED THERAPY+CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,RADIATION,TARGETED THERAPY+CHEMOTHERAPY",
              children: [
                {
                  name: "CYRAMZA+CHEMOTHERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:CYRAMZA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:CYRAMZA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,TARGETED THERAPY+CHEMOTHERAPY,CYRAMZA+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "CYRAMZA+CHEMOTHERAPY",
                },
                {
                  name: "BEVACIZUMAB+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:RADIATION:BEVACIZUMAB$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,RADIATION,TARGETED THERAPY+CHEMOTHERAPY,BEVACIZUMAB+CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB+CHEMOTHERAPY",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TAGRISSO",
          size: 20,
          key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:2020",
          nuuid: "NSCLC,STAGE 2,TAGRISSO",
          children: [
            {
              name: "GILOTRIF",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:TAGRISSO:GILOTRIF:2020",
              nuuid: "NSCLC,STAGE 2,TAGRISSO,GILOTRIF",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "TARGETED_THERAPY",
          size: 138,
          key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:TARGETED_THERAPY:2020",
          nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY",
          children: [
            {
              name: "BEVACIZUMAB",
              size: 132,
              key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,BEVACIZUMAB",
              children: [
                {
                  name: "RADIATION",
                  size: 83,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 57,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "KEYTRUDA",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,RADIATION,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:RADIATION:IMFINZI:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,RADIATION,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,KEYTRUDA",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:KEYTRUDA:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,KEYTRUDA,BEVACIZUMAB",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "SURGERY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:SURGERY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,SURGERY",
                  children: [
                    {
                      name: "RADIATION",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:RADIATION:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,SURGERY,RADIATION",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 2,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:SURGERY:RADIATION:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,BEVACIZUMAB,SURGERY,RADIATION,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,CHEMOTHERAPY",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHERS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:OTHERS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:2020",
                  nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,OTHERS",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                      nuuid: "NSCLC,STAGE 2,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                      children: [
                        {
                          name: "RADIATION",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:RADIATION:2020",
                          nuuid:
                            "NSCLC,STAGE 2,BEVACIZUMAB,OTHERS,BEVACIZUMAB,RADIATION",
                          children: [
                            {
                              name: "Wait & Watch",
                              size: 1,
                              key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                              id:
                                "nsclc:G:5:WHEEL:I:SEERtumor:BEVACIZUMAB:OTHERS:BEVACIZUMAB:RADIATION:WW:2020",
                              nuuid:
                                "NSCLC,STAGE 2,BEVACIZUMAB,OTHERS,BEVACIZUMAB,RADIATION,WW",
                              children: [],
                              comment:
                                "Wait and Watch on patients after finishing a therapy",
                            },
                          ],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "GILOTRIF",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:GILOTRIF",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,GILOTRIF",
              children: [
                {
                  name: "RADIATION",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,GILOTRIF,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:GILOTRIF:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 2,GILOTRIF,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TAGRISSO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:GILOTRIF:TAGRISSO:2020",
                  nuuid: "NSCLC,STAGE 2,GILOTRIF,TAGRISSO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "ALECENSA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:ALECENSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,ALECENSA",
              children: [
                {
                  name: "CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:ALECENSA:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,ALECENSA,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:ALECENSA:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 2,ALECENSA,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "CAPMATINIB",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:CAPMATINIB",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:CAPMATINIB:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,CAPMATINIB",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "IRESSA",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:IRESSA",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:IRESSA:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,IRESSA",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
            {
              name: "RETEVMO",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:RETEVMO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:RETEVMO:2020",
              nuuid: "NSCLC,STAGE 2,TARGETED_THERAPY,RETEVMO",
              children: [],
              comment: "Treatment for NSCLC Stage1 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "OTHERS",
          size: 312,
          key: "INCtumor:NSCLC:STAGE 2:OTHERS",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
          nuuid: "NSCLC,STAGE 2,OTHERS",
          children: [
            {
              name: "RADIATION",
              size: 157,
              key: "INCtumor:NSCLC:STAGE 2:RADIATION",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION",
              children: [
                {
                  name: "Wait & Watch",
                  size: 122,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:WW:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "CHEMOTHERAPY",
                  size: 5,
                  key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,CHEMOTHERAPY",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 2,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:WW:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,CHEMOTHERAPY,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "IMFINZI",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:CHEMOTHERAPY:IMFINZI:2020",
                      nuuid:
                        "NSCLC,STAGE 2,OTHERS,RADIATION,CHEMOTHERAPY,IMFINZI",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 4,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:KEYTRUDA$CHEMOTHERAPY:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 2,OTHERS,RADIATION,KEYTRUDA+CHEMOTHERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "TARGETED_THERAPY",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,TARGETED_THERAPY",
                  children: [
                    {
                      name: "BEVACIZUMAB",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:BEVACIZUMAB:2020",
                      nuuid:
                        "NSCLC,STAGE 2,OTHERS,RADIATION,TARGETED_THERAPY,BEVACIZUMAB",
                      children: [
                        {
                          name: "OTHERS",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:OTHERS",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:BEVACIZUMAB:OTHERS:2020",
                          nuuid:
                            "NSCLC,STAGE 2,OTHERS,RADIATION,BEVACIZUMAB,OTHERS",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "BEVACIZUMAB",
                    },
                    {
                      name: "LUMAKRAS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:LUMAKRAS:2020",
                      nuuid:
                        "NSCLC,STAGE 2,OTHERS,RADIATION,TARGETED_THERAPY,LUMAKRAS",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "LUMAKRAS",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,IMFINZI",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:RADIATION:IMFINZI:WW:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,IMFINZI,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "OTHER IO",
                  size: 2,
                  key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,OTHER IO",
                  children: [
                    {
                      name: "KEYTRUDA",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:KEYTRUDA:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,OTHER IO,KEYTRUDA",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "KEYTRUDA",
                    },
                    {
                      name: "OPDIVO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:RADIATION:OPDIVO:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,RADIATION,OTHER IO,OPDIVO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                      parentId: "OPDIVO",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "SURGERY",
              size: 11,
              key: "INCtumor:NSCLC:STAGE 2:SURGERY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,SURGERY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,SURGERY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "RADIATION",
                  size: 6,
                  key: "INCtumor:NSCLC:STAGE 2:RADIATION",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,SURGERY,RADIATION",
                  children: [
                    {
                      name: "Wait & Watch",
                      size: 5,
                      key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                      id:
                        "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:WW:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,SURGERY,RADIATION,WW",
                      children: [],
                      comment:
                        "Wait and Watch on patients after finishing a therapy",
                    },
                    {
                      name: "TAGRISSO",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:TAGRISSO",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:SURGERY:RADIATION:TAGRISSO:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,SURGERY,RADIATION,TAGRISSO",
                      children: [],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CHEMOTHERAPY",
              size: 8,
              key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY",
              children: [
                {
                  name: "Wait & Watch",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                  id: "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:WW:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY,WW",
                  children: [],
                  comment:
                    "Wait and Watch on patients after finishing a therapy",
                },
                {
                  name: "IMFINZI",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:IMFINZI",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:IMFINZI:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY,IMFINZI",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
                {
                  name: "KEYTRUDA+CHEMOTHERAPY",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
                  id:
                    "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:2020",
                  nuuid:
                    "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY",
                  children: [
                    {
                      name: "CHEMOTHERAPY",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:CHEMOTHERAPY",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:2020",
                      nuuid:
                        "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY",
                      children: [
                        {
                          name: "Wait & Watch",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:Wait & Watch",
                          id:
                            "nsclc:G:5:WHEEL:I:SEERtumor:OTHERS:CHEMOTHERAPY:KEYTRUDA$CHEMOTHERAPY:CHEMOTHERAPY:WW:2020",
                          nuuid:
                            "NSCLC,STAGE 2,OTHERS,CHEMOTHERAPY,KEYTRUDA+CHEMOTHERAPY,CHEMOTHERAPY,WW",
                          children: [],
                          comment:
                            "Wait and Watch on patients after finishing a therapy",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "OTHER IO",
              size: 4,
              key: "INCtumor:NSCLC:STAGE 2:OTHER IO",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,OTHER IO",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 3,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,OTHER IO,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "KEYTRUDA",
                },
                {
                  name: "OPDIVO",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:OPDIVO",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:OPDIVO:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,OTHER IO,OPDIVO",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "OPDIVO",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "TARGETED_THERAPY",
              size: 2,
              key: "INCtumor:NSCLC:STAGE 2:TARGETED_THERAPY",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,TARGETED_THERAPY",
              children: [
                {
                  name: "BEVACIZUMAB",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,TARGETED_THERAPY,BEVACIZUMAB",
                  children: [
                    {
                      name: "OTHERS",
                      size: 1,
                      key: "INCtumor:NSCLC:STAGE 2:OTHERS",
                      id:
                        "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:2020",
                      nuuid: "NSCLC,STAGE 2,OTHERS,BEVACIZUMAB,OTHERS",
                      children: [
                        {
                          name: "BEVACIZUMAB",
                          size: 1,
                          key: "INCtumor:NSCLC:STAGE 2:BEVACIZUMAB",
                          id:
                            "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:BEVACIZUMAB:OTHERS:BEVACIZUMAB:2020",
                          nuuid:
                            "NSCLC,STAGE 2,OTHERS,BEVACIZUMAB,OTHERS,BEVACIZUMAB",
                          children: [],
                          comment: "Treatment for NSCLC Stage3 patients",
                        },
                      ],
                      comment: "Treatment for NSCLC Stage3 patients",
                    },
                  ],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "BEVACIZUMAB",
                },
                {
                  name: "LUMAKRAS",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:LUMAKRAS",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:LUMAKRAS:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,TARGETED_THERAPY,LUMAKRAS",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                  parentId: "LUMAKRAS",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "CRT",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:CRT",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,CRT",
              children: [
                {
                  name: "KEYTRUDA",
                  size: 1,
                  key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA",
                  id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:CRT:KEYTRUDA:2020",
                  nuuid: "NSCLC,STAGE 2,OTHERS,CRT,KEYTRUDA",
                  children: [],
                  comment: "Treatment for NSCLC Stage3 patients",
                },
              ],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "ERLOTINIB_HCL",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:ERLOTINIB_HCL",
              id: "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:ERLOTINIB_HCL:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,ERLOTINIB_HCL",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
            {
              name: "KEYTRUDA+CHEMOTHERAPY",
              size: 1,
              key: "INCtumor:NSCLC:STAGE 2:KEYTRUDA+CHEMOTHERAPY",
              id:
                "nsclc:G:4:WHEEL:I:SEERtumor:OTHERS:KEYTRUDA$CHEMOTHERAPY:2020",
              nuuid: "NSCLC,STAGE 2,OTHERS,KEYTRUDA+CHEMOTHERAPY",
              children: [],
              comment: "Treatment for NSCLC Stage3 patients",
            },
          ],
          comment: "Treatment for NSCLC Stage1 patients",
        },
        {
          name: "UNTREATED",
          size: 41,
          key: "INCtumor:NSCLC:STAGE 2:UNTREATED",
          id: "nsclc:G:4:WHEEL:I:SEERtumor:NSCLC:STAGE2:2020",
          nuuid: "NSCLC,STAGE 2,UNTREATED",
          children: [],
          comment:
            " Patients who didn't receive a treatment within NSCLC Stage3 patients",
        },
      ],
      nuuid: "NSCLC,STAGE 2",
    },
    {
      name: "Non Identified",
      size: 444,
      id: "nsclc:G:3:WHEEL:I:INCtumor:NSCLC:Non Identified:Non Identified:2020",
      children: [],
      nuuid: "NSCLC,Non Identified",
    },
  ],
  comment: "®AstraZeneca - Tumor Identification Model, NSCLC/SCLC",
  id: "nsclc:G:2:WHEEL:I:INCtumor:NSCLC:2020",
  title: "NSCLC Incidence for the year 2020",
  nuuid: "NSCLC",
};
