import React from "react";
import PropTypes from "prop-types";
import { ClusteredBarWrapper } from "./ClusteredBar.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as d3 from "d3";

const ClusteredBar = (props) => {
  const { data, segtabid } = props;
  let root = null;
  const loadData = () => {
    if (data && data.length > 0) {
      var margin = { top: 20, right: 20, bottom: 20, left: 20 },
        width = window.innerWidth - margin.left - margin.right,
        height = 600 - margin.top - margin.bottom;
      d3.select(`#nav-${segtabid}`)
        .style("width", width + "px")
        .style("height", height +"px");
      var _chartdata = [];
      var keys = [];
      data.map((_data2) => {
        var dataObj = {};
        dataObj["brand"] = _data2.name;
        _data2.values.map((_d) => {
          dataObj[`${_d.name}`] = _d.count;
        });
        _chartdata.push(dataObj);
      });
      console.log("chartdata", _chartdata);
      if (root) {
        root.dispose();
      }
      root = am5.Root.new(`nav-${segtabid}`);
      //let root = am5.Root.new("chartdiv");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 20
        })
      );

      let chartdata = [
        {
          year: "2021",
          europe: 2.5,
          namerica: 2.5,
          asia: 2.1,
          lamerica: 1,
          meast: 0.8,
          africa: 0.4,
        },
        {
          year: "2022",
          europe: 2.6,
          namerica: 2.7,
          asia: 2.2,
          lamerica: 0.5,
          meast: 0.4,
          africa: 0.3,
        },
        {
          year: "2023",
          europe: 2.8,
          namerica: 2.9,
          asia: 2.4,
          lamerica: 0.3,
          meast: 0.9,
          africa: 0.5,
        },
      ];

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "brand",
          renderer: am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xAxis.data.setAll(_chartdata);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraMax: 0.1,
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name, fieldName) {
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: "brand",
          })
        );

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}:{valueY}",
          width: am5.percent(90),
          tooltipY: 0,
        });

        series.data.setAll(_chartdata);

        

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1.0,
            
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              dy: -10,
              fill: root.interfaceColors.get("text"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });
        legend.data.push(series);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
      }
      for (const [key, value] of Object.entries(_chartdata[0])) {
        if (key !== "brand") {
            makeSeries(key, key, false);
        }
      }
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <ClusteredBarWrapper id={`nav-${segtabid}`}></ClusteredBarWrapper>;
};

ClusteredBar.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

ClusteredBar.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default ClusteredBar;
