import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { VennDiagramWrapper, VDChart } from "./VennDiagram.styles";
import * as am5 from "@amcharts/amcharts5";
import * as am5venn from "@amcharts/amcharts5/venn";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import data from "./data";

const VennDiagram = (props) => {
  //const { data, segtabid, isPercentage } = props;
  let root = null;

  const loadData = () => {
    root.setThemes([am5themes_Animated.new(root)]);

    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.verticalLayout,
      })
    );

    // Create venn series
    let chart = container.children.push(
      am5venn.Venn.new(root, {
        categoryField: "name",
        valueField: "value",
        intersectionsField: "sets",
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
      })
    );

    // Set tooltip content
    chart.slices.template.setAll({
      tooltipText: "{category}",
      strokeOpacity: 0,
      templateField: "sliceSettings",
    });

    chart.labels.template.setAll({
      textAlign: "center",
      templateField: "labelSettings",
    });

    // Set up hover appearance
    chart.hoverGraphics.setAll({
      strokeDasharray: [3, 3],
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    // Set data
    chart.data.setAll(data);
  };

  useEffect(() => {
    if (root) {
      root.dispose();
    }
    root = am5.Root.new("vdChart");
    loadData();
    return () => {};
  }, []);

  return (
    <VennDiagramWrapper>
      <VDChart id="vdChart"></VDChart>
    </VennDiagramWrapper>
  );
};

VennDiagram.propTypes = {
  // bla: PropTypes.string,
};

VennDiagram.defaultProps = {
  // bla: 'test',
};

export default VennDiagram;
