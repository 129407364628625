import React from "react";
import SiteMap from "../../../components/SiteMap/SiteMap";
import SunBurstChart from "../../../components/SunburstChart";
import config from "../../../config";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { InsightsHomeWrapper, InsightsHomeWrapperContent } from "./InsightsHome.styles";

const InsightsHome = (props) => {
  var _instance;
  const options = {
    onLoad: function () {
      console.log("[onLoad]");
      setTimeout(() => {
        _instance
          .open({
            id: "1",
            title: "Home",
            closable: false,
            panelComponent: (porps) => {
              return (
                <SunBurstChart
                  url={`${config.api.globalWheelSummary}?year=2020`}
                  dataType="global"
                  showYears={true}
                  onInfoUpdate={() => {}}
                  onResult={(d) => {}}
                  onSelect={(d) => {}}
                  primaryTabInstance={_instance}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });

        // switch to tab
        _instance.select("1").then(() => {
          console.log("tab is selected");
        });
      }, 1000);
    },
    onInit: function () {
      console.log("[onInit]");
    },
  };

  const [TabList, PanelList, ready] = useDynTabs(options);

  ready((instance) => {
    _instance = instance;
  });
  return (
    <InsightsHomeWrapper>
      {/* <Row>
        <Col xs={24}>
          <CardLayout>
            <MultiYearPicker />
            <PatientPopulationChart />
          </CardLayout>
        </Col>
        <Col xs={24}>
          <CardLayout>
            <SankeyPlot />
          </CardLayout>
        </Col>
        <Col xs={24}>
        
        </Col>
      </Row>*/}
      <SiteMap />
      <InsightsHomeWrapperContent>
        <TabList></TabList>
        <PanelList></PanelList>
      </InsightsHomeWrapperContent>
    </InsightsHomeWrapper>
  );
};

InsightsHome.propTypes = {
  // bla: PropTypes.string,
};

InsightsHome.defaultProps = {
  // bla: 'test',
};

export default InsightsHome;
