import styled from "styled-components";

export const USBubbleMapWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 12px;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  .hcpMapBackBtn {
    background-color: #000000dd;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    max-width: 100px;
    align-self: flex-end;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 5px;
    z-index: 100000;
    color: #ffffff;
  }
`;
export const USBubbleMapContainer = styled.div`
  flex: 1;
  width: 500px;
  height: 500px;
  margin: 0px 10px;
`;
