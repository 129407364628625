import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SalesRegionWrapper } from "./SalesRegion.styles";
import SalesRegionMap from "../../../components/SalesRegionMap";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import SiteMap from "../../../components/SiteMap/SiteMap";
import PhysicianList from "../../../components/PhysicianList/PhysicianList";

const SalesRegion = (props) => {
  const navigate = useNavigate();
  var _salesRegionInstance;
  const options = {
    tabs: [
      {
        id: "1",
        title: "Home",
        closable: false,
        panelComponent: (porps) => {
          return (
            <SalesRegionMap
              url={`${config.api.getSalesRegion}?locationid=USA&brandName=Calquence`}
              containerId="salesregion"
              onClick={(d) => {
                //navigate(`${config.api.getPhysicians}?territoryId=${d}`);
                _salesRegionInstance
                  .open({
                    id: "2",
                    title: "Physicians",
                    closable: false,
                    panelComponent: (porps) => {
                      return (
                        <PhysicianList
                          url={`${config.api.getPhysicians}?territoryId=${d}`}
                          dataType="sales-regions"
                          onInfoUpdate={() => {}}
                        />
                      );
                    },
                  })
                  .then(() => {
                    console.log("tab is open");
                  });
                  _salesRegionInstance.select("2").then(() => {
                  console.log("tab is selected");
                });
              }}
            />
          );
        },
      },
    ],
    selectedTabID: "1",
    onLoad: function () {
      console.log("[onLoad]");
    },
  };
  const [Tablist, Panellist, ready] = useDynTabs(options);
  ready((instance) => {
    _salesRegionInstance = instance;
  });

  useEffect(() => {
  }, []);
  return (
    <SalesRegionWrapper>
      <SiteMap />
      <div className="content">
        <Tablist></Tablist>
        <Panellist></Panellist>
      </div>
    </SalesRegionWrapper>
  );
};

SalesRegion.propTypes = {
  // bla: PropTypes.string,
};

SalesRegion.defaultProps = {
  // bla: 'test',
};

export default SalesRegion;
