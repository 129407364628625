
import {
  DataTypeProvider, IntegratedSorting,
  SearchState, SelectionState, SortingState
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility, TableFixedColumns, TableHeaderRow, TableSelection, Toolbar
} from "@devexpress/dx-react-grid-bootstrap4";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { Tabs } from "antd";
import saveAs from "file-saver";
import React, { useState, useCallback, useEffect, useRef,  } from "react";
import { GetAPIRequest } from "../../services";
import { TrendingTableWrapper, TableWrapper } from "./TrendingTable.styles";
const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "DataGrid.xlsx"
    );
  });
};

const AlignCenterFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
    }}
  >
    {value}
  </span>
);
const CenterTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignCenterFormatter} {...props} />
);
const AlignLeftFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    }}
  >
    {value}
  </span>
);
const LeftTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignLeftFormatter} {...props} />
);

const TrendingTable = (props) => {
  //const url = `${config.api.reportinghcp}`;
  const [columns, setColumns] = useState([]);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(1);
  const [currentDate, setCurrentDate] = useState(new Date("2021", "10", "01"));
  const [columnsExt, setColumnsExt] = useState([]);
  const [rows, setRows] = useState([]);
  const [centerColumns, setCenterColumns] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [defaultHiddenColumnNames, setdefaultHiddenColumnNames] = useState([
    "Prescriber Id",
    "NPI",
  ]);
  const [selection, setSelection] = useState([]);
  const [selPhy, setSelPhy] = useState("");
  const [leftColumns, setLeftColumns] = useState(["Physician Details"]);
  const getData = (_limit = 50, _offset = 10, date = "2020-04-01") => {
    GetAPIRequest({
      url: `${props.url}?dt=${date}&limit=${_limit}&offset=${_offset}`,
    })
      .then((res) => {
        var physicians = [];
        var tableDataArr = [];
        var _tblcolumns = [];
        var _tblcolumnsExt = [];
        var columnns = [];

        res.data.map((d4, i) => {
          var phyData = { ...d4 };
          d4.data.map((d) => {
            if (d.data && d.data.length > 0) {
              if (d.data.data && d.data.data.length > 0) {
                d.data.map((d2) => {
                  d2.data.map((d1, idx) => {
                    if (i == 0) {
                      columnns.push({
                        title: d1.name,
                        key: d1.name,
                      });
                    }
                    phyData[d1.name] = d1.value;
                  });
                });
              } else {
                d.data.map((d1, idx) => {
                  if (i == 0) {
                    columnns.push({
                      title: d1.name,
                      key: d1.name,
                    });
                  }
                  phyData[d1.name] = d1.value;
                });
              }
            }
          });
          physicians.push(phyData);
        });
        physicians.forEach((phy) => {
          var tableDataObj = {};
          columnns.map((c) => {
            if (props.dataType == "hcp") {
              tableDataObj["Physician Details"] = phy["hcp_name"];
              tableDataObj["Account Name"] = phy["top_parent"];
              tableDataObj["Physician NPI"] = phy["npi"];
            } else if (props.dataType == "top_parent") {
              tableDataObj["Account Name"] = phy["top_parent"];
            } else if (props.dataType == "parent") {
              tableDataObj["Account Name"] = phy["parent_name"];
            }

            tableDataObj[c.title] = phy[c.key];
          });
          tableDataArr.push(tableDataObj);
        });

        columnns.map((c) => {
          if (c.title == "Physician Details" || c.title == "Account Name") {
            _tblcolumns.push({
              name: c.title,
              title: c.title,
              getCellValue: (row) => {
                return <div>{row[c.title]}</div>;
              },
            });
            _tblcolumnsExt.push({
              columnName: c.title,
              align: "left",
              width: 200,
              backgroundColor: "#000000",
            });
          } else {
            _tblcolumns.push({
              name: c.title,
              title: c.title,
              getCellValue: (row) => row[c.title],
            });
            _tblcolumnsExt.push({
              columnName: c.title,
              align: "center",
            });
          }
        });
        setCenterColumns(columns);
        setColumns(_tblcolumns);
        setColumnsExt(_tblcolumnsExt);
        setRows(tableDataArr);
        var hiddenColumns = [];
        setHiddenColumnNames(hiddenColumns);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const getHiddenColumnsFilteringExtensions = (hiddenColumnNames) =>
    hiddenColumnNames.map((columnName) => ({
      columnName,
      predicate: () => false,
    }));
  const [filteringColumnExtensions, setFilteringColumnExtensions] = useState(
    getHiddenColumnsFilteringExtensions(defaultHiddenColumnNames)
  );

  const onHiddenColumnNamesChange = (hiddenColumnNames) =>
    setFilteringColumnExtensions(
      getHiddenColumnsFilteringExtensions(hiddenColumnNames)
    );
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  return (
    <TrendingTableWrapper>
     <TableWrapper
        className="trending-reporting-table"
        style={{ width: window.innerWidth - 20 }}
      >
      <Grid rows={rows} columns={columns}>
        <DragDropProvider />
        <SortingState />
        <IntegratedSorting />
        <SelectionState
          selection={selection}
          onSelectionChange={(val) => {
            setSelection(val);
            if (val.length > 0) {
              console.log("setSelPhy", rows[val[0]]);
              setSelPhy(rows[val[0]]);
            }
          }}
        />
        <SearchState defaultValue="" />
        <CenterTypeProvider for={centerColumns} />
        <LeftTypeProvider for={["Physician Details", "Account Name"]} />
        <Table columnExtensions={columnsExt} />
        <TableColumnReordering defaultOrder={columns} />
        <TableHeaderRow showSortingControls />
        <TableSelection
          selectByRowClick
          highlightRow
          showSelectionColumn={false}
        />
        <Toolbar />
        <TableColumnVisibility
          messages={{ noColumns: "Loading..." }}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
        />
        <SearchPanel />
        <ColumnChooser />
        <ExportPanel startExport={startExport} />
        <TableFixedColumns leftColumns={leftColumns} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      </TableWrapper>
    </TrendingTableWrapper>
  );
};

TrendingTable.propTypes = {
  // bla: PropTypes.string,
};

TrendingTable.defaultProps = {
  // bla: 'test',
};

export default TrendingTable;
