

import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { NavDropdown, Navbar, Nav, Form,Container,Row, Col, Card, 
    ListGroup,ListGroupItem, Badge
    } from "react-bootstrap";
    import DonutGraph2 from './DonutGraph2'
 
import "./styles.css";


export default function DonutsMain() {


    const generateData = (value, length = 5) =>
    d3.range(length).map((item, index) => ({
      date: index,
      value: value === null || value === undefined ? Math.random() * 100 : value
    }));

  const [data, setData] = useState(generateData(0));
  const changeData = () => {
    setData(generateData());
  };

  useEffect(
    () => {
      setData(generateData());
    },
    [!data]
  );


  return (
    <div >

    
    
   
      <div style={{backgroundColor:"transparent"}}>

<DonutGraph2
          data={data}
          width={200}
          height={200}
          innerRadius={60}
          outerRadius={100}
        />
</div>
    </div>
  );
}

