export default [
  {
    fips: "17031",
    count: 4744,
    state: "IL",
    type: "County",
    locationid: "17031",
    county: "Cook",
  },
  {
    fips: "06037",
    count: 4176,
    state: "CA",
    type: "County",
    locationid: "06037",
    county: "Los Angeles",
  },
  {
    fips: "36061",
    count: 3765,
    state: "NY",
    type: "County",
    locationid: "36061",
    county: "New York",
  },
  {
    fips: "04013",
    count: 2785,
    state: "AZ",
    type: "County",
    locationid: "04013",
    county: "Maricopa",
  },
  {
    fips: "48201",
    count: 3074,
    state: "TX",
    type: "County",
    locationid: "48201",
    county: "Harris",
  },
  {
    fips: "39035",
    count: 2784,
    state: "OH",
    type: "County",
    locationid: "39035",
    county: "Cuyahoga",
  },
  {
    fips: "42101",
    count: 2612,
    state: "PA",
    type: "County",
    locationid: "42101",
    county: "Philadelphia",
  },
  {
    fips: "12057",
    count: 2322,
    state: "FL",
    type: "County",
    locationid: "12057",
    county: "Hillsborough",
  },
  {
    fips: "48113",
    count: 2111,
    state: "TX",
    type: "County",
    locationid: "48113",
    county: "Dallas",
  },
  {
    fips: "25025",
    count: 2358,
    state: "MA",
    type: "County",
    locationid: "25025",
    county: "Suffolk",
  },
  {
    fips: "42003",
    count: 2346,
    state: "PA",
    type: "County",
    locationid: "42003",
    county: "Allegheny",
  },
  {
    fips: "12086",
    count: 1998,
    state: "FL",
    type: "County",
    locationid: "12086",
    county: "Miami-dade",
  },
  {
    fips: "26125",
    count: 2012,
    state: "MI",
    type: "County",
    locationid: "26125",
    county: "Oakland",
  },
  {
    fips: "26163",
    count: 2048,
    state: "MI",
    type: "County",
    locationid: "26163",
    county: "Wayne",
  },
  {
    fips: "18097",
    count: 2046,
    state: "IN",
    type: "County",
    locationid: "18097",
    county: "Marion",
  },
  {
    fips: "39049",
    count: 1759,
    state: "OH",
    type: "County",
    locationid: "39049",
    county: "Franklin",
  },
  {
    fips: "47037",
    count: 1617,
    state: "TN",
    type: "County",
    locationid: "47037",
    county: "Davidson",
  },
  {
    fips: "12031",
    count: 1520,
    state: "FL",
    type: "County",
    locationid: "12031",
    county: "Duval",
  },
  {
    fips: "27053",
    count: 1515,
    state: "MN",
    type: "County",
    locationid: "27053",
    county: "Hennepin",
  },
  {
    fips: "36059",
    count: 1537,
    state: "NY",
    type: "County",
    locationid: "36059",
    county: "Nassau",
  },
  {
    fips: "24510",
    count: 1592,
    state: "MD",
    type: "County",
    locationid: "24510",
    county: "Baltimore City",
  },
  {
    fips: "53033",
    count: 1519,
    state: "WA",
    type: "County",
    locationid: "53033",
    county: "King",
  },
  {
    fips: "39061",
    count: 1516,
    state: "OH",
    type: "County",
    locationid: "39061",
    county: "Hamilton",
  },
  {
    fips: "12011",
    count: 1510,
    state: "FL",
    type: "County",
    locationid: "12011",
    county: "Broward",
  },
  {
    fips: "21111",
    count: 1351,
    state: "KY",
    type: "County",
    locationid: "21111",
    county: "Jefferson",
  },
  {
    fips: "06059",
    count: 1399,
    state: "CA",
    type: "County",
    locationid: "06059",
    county: "Orange",
  },
  {
    fips: "12095",
    count: 1438,
    state: "FL",
    type: "County",
    locationid: "12095",
    county: "Orange",
  },
  {
    fips: "32003",
    count: 1265,
    state: "NV",
    type: "County",
    locationid: "32003",
    county: "Clark",
  },
  {
    fips: "26161",
    count: 1339,
    state: "MI",
    type: "County",
    locationid: "26161",
    county: "Washtenaw",
  },
  {
    fips: "36047",
    count: 1392,
    state: "NY",
    type: "County",
    locationid: "36047",
    county: "Kings",
  },
  {
    fips: "01073",
    count: 1333,
    state: "AL",
    type: "County",
    locationid: "01073",
    county: "Jefferson",
  },
  {
    fips: "48439",
    count: 1224,
    state: "TX",
    type: "County",
    locationid: "48439",
    county: "Tarrant",
  },
  {
    fips: "12099",
    count: 1297,
    state: "FL",
    type: "County",
    locationid: "12099",
    county: "Palm Beach",
  },
  {
    fips: "06073",
    count: 1277,
    state: "CA",
    type: "County",
    locationid: "06073",
    county: "San Diego",
  },
  {
    fips: "13121",
    count: 1326,
    state: "GA",
    type: "County",
    locationid: "13121",
    county: "Fulton",
  },
  {
    fips: "25017",
    count: 1263,
    state: "MA",
    type: "County",
    locationid: "25017",
    county: "Middlesex",
  },
  {
    fips: "45019",
    count: 1156,
    state: "SC",
    type: "County",
    locationid: "45019",
    county: "Charleston",
  },
  {
    fips: "27109",
    count: 1142,
    state: "MN",
    type: "County",
    locationid: "27109",
    county: "Olmsted",
  },
  {
    fips: "13089",
    count: 1222,
    state: "GA",
    type: "County",
    locationid: "13089",
    county: "Dekalb",
  },
  {
    fips: "26081",
    count: 1087,
    state: "MI",
    type: "County",
    locationid: "26081",
    county: "Kent",
  },
  {
    fips: "47157",
    count: 1065,
    state: "TN",
    type: "County",
    locationid: "47157",
    county: "Shelby",
  },
  {
    fips: "48029",
    count: 1134,
    state: "TX",
    type: "County",
    locationid: "48029",
    county: "Bexar",
  },
  {
    fips: "47093",
    count: 1020,
    state: "TN",
    type: "County",
    locationid: "47093",
    county: "Knox",
  },
  {
    fips: "21067",
    count: 1006,
    state: "KY",
    type: "County",
    locationid: "21067",
    county: "Fayette",
  },
  {
    fips: "05119",
    count: 943,
    state: "AR",
    type: "County",
    locationid: "05119",
    county: "Pulaski",
  },
  {
    fips: "55079",
    count: 1072,
    state: "WI",
    type: "County",
    locationid: "55079",
    county: "Milwaukee",
  },
  {
    fips: "39113",
    count: 915,
    state: "OH",
    type: "County",
    locationid: "39113",
    county: "Montgomery",
  },
  {
    fips: "29189",
    count: 999,
    state: "MO",
    type: "County",
    locationid: "29189",
    county: "Saint Louis",
  },
  {
    fips: "09009",
    count: 1036,
    state: "CT",
    type: "County",
    locationid: "09009",
    county: "New Haven",
  },
  {
    fips: "09003",
    count: 950,
    state: "CT",
    type: "County",
    locationid: "09003",
    county: "Hartford",
  },
  {
    fips: "36103",
    count: 903,
    state: "NY",
    type: "County",
    locationid: "36103",
    county: "Suffolk",
  },
  {
    fips: "40109",
    count: 955,
    state: "OK",
    type: "County",
    locationid: "40109",
    county: "Oklahoma",
  },
  {
    fips: "29510",
    count: 976,
    state: "MO",
    type: "County",
    locationid: "29510",
    county: "Saint Louis City",
  },
  {
    fips: "04019",
    count: 864,
    state: "AZ",
    type: "County",
    locationid: "04019",
    county: "Pima",
  },
  {
    fips: "34023",
    count: 954,
    state: "NJ",
    type: "County",
    locationid: "34023",
    county: "Middlesex",
  },
  {
    fips: "36029",
    count: 915,
    state: "NY",
    type: "County",
    locationid: "36029",
    county: "Erie",
  },
  {
    fips: "40143",
    count: 847,
    state: "OK",
    type: "County",
    locationid: "40143",
    county: "Tulsa",
  },
  {
    fips: "51710",
    count: 808,
    state: "VA",
    type: "County",
    locationid: "51710",
    county: "Norfolk City",
  },
  {
    fips: "12103",
    count: 854,
    state: "FL",
    type: "County",
    locationid: "12103",
    county: "Pinellas",
  },
  {
    fips: "06071",
    count: 868,
    state: "CA",
    type: "County",
    locationid: "06071",
    county: "San Bernardino",
  },
  {
    fips: "36081",
    count: 848,
    state: "NY",
    type: "County",
    locationid: "36081",
    county: "Queens",
  },
  {
    fips: "39153",
    count: 823,
    state: "OH",
    type: "County",
    locationid: "39153",
    county: "Summit",
  },
  {
    fips: "42091",
    count: 855,
    state: "PA",
    type: "County",
    locationid: "42091",
    county: "Montgomery",
  },
  {
    fips: "34003",
    count: 786,
    state: "NJ",
    type: "County",
    locationid: "34003",
    county: "Bergen",
  },
  {
    fips: "41051",
    count: 836,
    state: "OR",
    type: "County",
    locationid: "41051",
    county: "Multnomah",
  },
  {
    fips: "36001",
    count: 749,
    state: "NY",
    type: "County",
    locationid: "36001",
    county: "Albany",
  },
  {
    fips: "15003",
    count: 811,
    state: "HI",
    type: "County",
    locationid: "15003",
    county: "Honolulu",
  },
  {
    fips: "29095",
    count: 811,
    state: "MO",
    type: "County",
    locationid: "29095",
    county: "Jackson",
  },
  {
    fips: "36119",
    count: 796,
    state: "NY",
    type: "County",
    locationid: "36119",
    county: "Westchester",
  },
  {
    fips: "24005",
    count: 803,
    state: "MD",
    type: "County",
    locationid: "24005",
    county: "Baltimore",
  },
  {
    fips: "24031",
    count: 788,
    state: "MD",
    type: "County",
    locationid: "24031",
    county: "Montgomery",
  },
  {
    fips: "44007",
    count: 825,
    state: "RI",
    type: "County",
    locationid: "44007",
    county: "Providence",
  },
  {
    fips: "06075",
    count: 790,
    state: "CA",
    type: "County",
    locationid: "06075",
    county: "San Francisco",
  },
  {
    fips: "06085",
    count: 793,
    state: "CA",
    type: "County",
    locationid: "06085",
    county: "Santa Clara",
  },
  {
    fips: "06067",
    count: 766,
    state: "CA",
    type: "County",
    locationid: "06067",
    county: "Sacramento",
  },
  {
    fips: "37119",
    count: 780,
    state: "NC",
    type: "County",
    locationid: "37119",
    county: "Mecklenburg",
  },
  {
    fips: "12009",
    count: 635,
    state: "FL",
    type: "County",
    locationid: "12009",
    county: "Brevard",
  },
  {
    fips: "23005",
    count: 676,
    state: "ME",
    type: "County",
    locationid: "23005",
    county: "Cumberland",
  },
  {
    fips: "26099",
    count: 729,
    state: "MI",
    type: "County",
    locationid: "26099",
    county: "Macomb",
  },
  {
    fips: "22033",
    count: 717,
    state: "LA",
    type: "County",
    locationid: "22033",
    county: "East Baton Rouge",
  },
  {
    fips: "17043",
    count: 743,
    state: "IL",
    type: "County",
    locationid: "17043",
    county: "Dupage",
  },
  {
    fips: "51059",
    count: 692,
    state: "VA",
    type: "County",
    locationid: "51059",
    county: "Fairfax",
  },
  {
    fips: "31055",
    count: 695,
    state: "NE",
    type: "County",
    locationid: "31055",
    county: "Douglas",
  },
  {
    fips: "06065",
    count: 682,
    state: "CA",
    type: "County",
    locationid: "06065",
    county: "Riverside",
  },
  {
    fips: "12001",
    count: 702,
    state: "FL",
    type: "County",
    locationid: "12001",
    county: "Alachua",
  },
  {
    fips: "19153",
    count: 619,
    state: "IA",
    type: "County",
    locationid: "19153",
    county: "Polk",
  },
  {
    fips: "34025",
    count: 675,
    state: "NJ",
    type: "County",
    locationid: "34025",
    county: "Monmouth",
  },
  {
    fips: "36067",
    count: 682,
    state: "NY",
    type: "County",
    locationid: "36067",
    county: "Onondaga",
  },
  {
    fips: "08031",
    count: 694,
    state: "CO",
    type: "County",
    locationid: "08031",
    county: "Denver",
  },
  {
    fips: "53053",
    count: 634,
    state: "WA",
    type: "County",
    locationid: "53053",
    county: "Pierce",
  },
  {
    fips: "18003",
    count: 599,
    state: "IN",
    type: "County",
    locationid: "18003",
    county: "Allen",
  },
  {
    fips: "47065",
    count: 569,
    state: "TN",
    type: "County",
    locationid: "47065",
    county: "Hamilton",
  },
  {
    fips: "55025",
    count: 698,
    state: "WI",
    type: "County",
    locationid: "55025",
    county: "Dane",
  },
  {
    fips: "12071",
    count: 648,
    state: "FL",
    type: "County",
    locationid: "12071",
    county: "Lee",
  },
  {
    fips: "42077",
    count: 670,
    state: "PA",
    type: "County",
    locationid: "42077",
    county: "Lehigh",
  },
  {
    fips: "42095",
    count: 666,
    state: "PA",
    type: "County",
    locationid: "42095",
    county: "Northampton",
  },
  {
    fips: "42045",
    count: 647,
    state: "PA",
    type: "County",
    locationid: "42045",
    county: "Delaware",
  },
  {
    fips: "34013",
    count: 644,
    state: "NJ",
    type: "County",
    locationid: "34013",
    county: "Essex",
  },
  {
    fips: "28049",
    count: 611,
    state: "MS",
    type: "County",
    locationid: "28049",
    county: "Hinds",
  },
  {
    fips: "37067",
    count: 659,
    state: "NC",
    type: "County",
    locationid: "37067",
    county: "Forsyth",
  },
  {
    fips: "53063",
    count: 584,
    state: "WA",
    type: "County",
    locationid: "53063",
    county: "Spokane",
  },
  {
    fips: "39095",
    count: 575,
    state: "OH",
    type: "County",
    locationid: "39095",
    county: "Lucas",
  },
  {
    fips: "27123",
    count: 600,
    state: "MN",
    type: "County",
    locationid: "27123",
    county: "Ramsey",
  },
  {
    fips: "36005",
    count: 635,
    state: "NY",
    type: "County",
    locationid: "36005",
    county: "Bronx",
  },
  {
    fips: "01097",
    count: 559,
    state: "AL",
    type: "County",
    locationid: "01097",
    county: "Mobile",
  },
  {
    fips: "36055",
    count: 615,
    state: "NY",
    type: "County",
    locationid: "36055",
    county: "Monroe",
  },
  {
    fips: "13051",
    count: 611,
    state: "GA",
    type: "County",
    locationid: "13051",
    county: "Chatham",
  },
  {
    fips: "01089",
    count: 503,
    state: "AL",
    type: "County",
    locationid: "01089",
    county: "Madison",
  },
  {
    fips: "35001",
    count: 589,
    state: "NM",
    type: "County",
    locationid: "35001",
    county: "Bernalillo",
  },
  {
    fips: "25027",
    count: 612,
    state: "MA",
    type: "County",
    locationid: "25027",
    county: "Worcester",
  },
  {
    fips: "34007",
    count: 609,
    state: "NJ",
    type: "County",
    locationid: "34007",
    county: "Camden",
  },
  {
    fips: "25013",
    count: 587,
    state: "MA",
    type: "County",
    locationid: "25013",
    county: "Hampden",
  },
  {
    fips: "12101",
    count: 580,
    state: "FL",
    type: "County",
    locationid: "12101",
    county: "Pasco",
  },
  {
    fips: "22051",
    count: 581,
    state: "LA",
    type: "County",
    locationid: "22051",
    county: "Jefferson",
  },
  {
    fips: "17143",
    count: 516,
    state: "IL",
    type: "County",
    locationid: "17143",
    county: "Peoria",
  },
  {
    fips: "48453",
    count: 552,
    state: "TX",
    type: "County",
    locationid: "48453",
    county: "Travis",
  },
  {
    fips: "17167",
    count: 547,
    state: "IL",
    type: "County",
    locationid: "17167",
    county: "Sangamon",
  },
  {
    fips: "48085",
    count: 526,
    state: "TX",
    type: "County",
    locationid: "48085",
    county: "Collin",
  },
  {
    fips: "10003",
    count: 495,
    state: "DE",
    type: "County",
    locationid: "10003",
    county: "New Castle",
  },
  {
    fips: "06013",
    count: 535,
    state: "CA",
    type: "County",
    locationid: "06013",
    county: "Contra Costa",
  },
  {
    fips: "20173",
    count: 537,
    state: "KS",
    type: "County",
    locationid: "20173",
    county: "Sedgwick",
  },
  {
    fips: "09001",
    count: 560,
    state: "CT",
    type: "County",
    locationid: "09001",
    county: "Fairfield",
  },
  {
    fips: "26049",
    count: 515,
    state: "MI",
    type: "County",
    locationid: "26049",
    county: "Genesee",
  },
  {
    fips: "22017",
    count: 532,
    state: "LA",
    type: "County",
    locationid: "22017",
    county: "Caddo",
  },
  {
    fips: "17097",
    count: 538,
    state: "IL",
    type: "County",
    locationid: "17097",
    county: "Lake",
  },
  {
    fips: "51760",
    count: 553,
    state: "VA",
    type: "County",
    locationid: "51760",
    county: "Richmond City",
  },
  {
    fips: "37021",
    count: 498,
    state: "NC",
    type: "County",
    locationid: "37021",
    county: "Buncombe",
  },
  {
    fips: "26077",
    count: 485,
    state: "MI",
    type: "County",
    locationid: "26077",
    county: "Kalamazoo",
  },
  {
    fips: "12115",
    count: 508,
    state: "FL",
    type: "County",
    locationid: "12115",
    county: "Sarasota",
  },
  {
    fips: "34029",
    count: 491,
    state: "NJ",
    type: "County",
    locationid: "34029",
    county: "Ocean",
  },
  {
    fips: "25021",
    count: 541,
    state: "MA",
    type: "County",
    locationid: "25021",
    county: "Norfolk",
  },
  {
    fips: "49035",
    count: 518,
    state: "UT",
    type: "County",
    locationid: "49035",
    county: "Salt Lake",
  },
  {
    fips: "37183",
    count: 531,
    state: "NC",
    type: "County",
    locationid: "37183",
    county: "Wake",
  },
  {
    fips: "21117",
    count: 495,
    state: "KY",
    type: "County",
    locationid: "21117",
    county: "Kenton",
  },
  {
    fips: "06001",
    count: 505,
    state: "CA",
    type: "County",
    locationid: "06001",
    county: "Alameda",
  },
  {
    fips: "37063",
    count: 521,
    state: "NC",
    type: "County",
    locationid: "37063",
    county: "Durham",
  },
  {
    fips: "12127",
    count: 474,
    state: "FL",
    type: "County",
    locationid: "12127",
    county: "Volusia",
  },
  {
    fips: "13245",
    count: 457,
    state: "GA",
    type: "County",
    locationid: "13245",
    county: "Richmond",
  },
  {
    fips: "24003",
    count: 450,
    state: "MD",
    type: "County",
    locationid: "24003",
    county: "Anne Arundel",
  },
  {
    fips: "08005",
    count: 493,
    state: "CO",
    type: "County",
    locationid: "08005",
    county: "Arapahoe",
  },
  {
    fips: "29019",
    count: 460,
    state: "MO",
    type: "County",
    locationid: "29019",
    county: "Boone",
  },
  {
    fips: "12105",
    count: 492,
    state: "FL",
    type: "County",
    locationid: "12105",
    county: "Polk",
  },
  {
    fips: "12069",
    count: 484,
    state: "FL",
    type: "County",
    locationid: "12069",
    county: "Lake",
  },
  {
    fips: "08001",
    count: 458,
    state: "CO",
    type: "County",
    locationid: "08001",
    county: "Adams",
  },
  {
    fips: "45079",
    count: 424,
    state: "SC",
    type: "County",
    locationid: "45079",
    county: "Richland",
  },
  {
    fips: "12033",
    count: 444,
    state: "FL",
    type: "County",
    locationid: "12033",
    county: "Escambia",
  },
  {
    fips: "46099",
    count: 451,
    state: "SD",
    type: "County",
    locationid: "46099",
    county: "Minnehaha",
  },
  {
    fips: "22055",
    count: 455,
    state: "LA",
    type: "County",
    locationid: "22055",
    county: "Lafayette",
  },
  {
    fips: "34027",
    count: 467,
    state: "NJ",
    type: "County",
    locationid: "34027",
    county: "Morris",
  },
  {
    fips: "48423",
    count: 427,
    state: "TX",
    type: "County",
    locationid: "48423",
    county: "Smith",
  },
  {
    fips: "26065",
    count: 435,
    state: "MI",
    type: "County",
    locationid: "26065",
    county: "Ingham",
  },
  {
    fips: "16001",
    count: 424,
    state: "ID",
    type: "County",
    locationid: "16001",
    county: "Ada",
  },
  {
    fips: "51087",
    count: 436,
    state: "VA",
    type: "County",
    locationid: "51087",
    county: "Henrico",
  },
  {
    fips: "45045",
    count: 474,
    state: "SC",
    type: "County",
    locationid: "45045",
    county: "Greenville",
  },
  {
    fips: "06019",
    count: 400,
    state: "CA",
    type: "County",
    locationid: "06019",
    county: "Fresno",
  },
  {
    fips: "18089",
    count: 421,
    state: "IN",
    type: "County",
    locationid: "18089",
    county: "Lake",
  },
  {
    fips: "39151",
    count: 425,
    state: "OH",
    type: "County",
    locationid: "39151",
    county: "Stark",
  },
  {
    fips: "37129",
    count: 444,
    state: "NC",
    type: "County",
    locationid: "37129",
    county: "New Hanover",
  },
  {
    fips: "42017",
    count: 419,
    state: "PA",
    type: "County",
    locationid: "42017",
    county: "Bucks",
  },
  {
    fips: "19103",
    count: 432,
    state: "IA",
    type: "County",
    locationid: "19103",
    county: "Johnson",
  },
  {
    fips: "20091",
    count: 436,
    state: "KS",
    type: "County",
    locationid: "20091",
    county: "Johnson",
  },
  {
    fips: "55009",
    count: 402,
    state: "WI",
    type: "County",
    locationid: "55009",
    county: "Brown",
  },
  {
    fips: "39099",
    count: 365,
    state: "OH",
    type: "County",
    locationid: "39099",
    county: "Mahoning",
  },
  {
    fips: "12083",
    count: 407,
    state: "FL",
    type: "County",
    locationid: "12083",
    county: "Marion",
  },
  {
    fips: "22071",
    count: 414,
    state: "LA",
    type: "County",
    locationid: "22071",
    county: "Orleans",
  },
  {
    fips: "42043",
    count: 413,
    state: "PA",
    type: "County",
    locationid: "42043",
    county: "Dauphin",
  },
  {
    fips: "51770",
    count: 358,
    state: "VA",
    type: "County",
    locationid: "51770",
    county: "Roanoke City",
  },
  {
    fips: "25009",
    count: 408,
    state: "MA",
    type: "County",
    locationid: "25009",
    county: "Essex",
  },
  {
    fips: "34005",
    count: 408,
    state: "NJ",
    type: "County",
    locationid: "34005",
    county: "Burlington",
  },
  {
    fips: "41067",
    count: 414,
    state: "OR",
    type: "County",
    locationid: "41067",
    county: "Washington",
  },
  {
    fips: "23019",
    count: 334,
    state: "ME",
    type: "County",
    locationid: "23019",
    county: "Penobscot",
  },
  {
    fips: "48339",
    count: 379,
    state: "TX",
    type: "County",
    locationid: "48339",
    county: "Montgomery",
  },
  {
    fips: "48141",
    count: 369,
    state: "TX",
    type: "County",
    locationid: "48141",
    county: "El Paso",
  },
  {
    fips: "26145",
    count: 385,
    state: "MI",
    type: "County",
    locationid: "26145",
    county: "Saginaw",
  },
  {
    fips: "13059",
    count: 324,
    state: "GA",
    type: "County",
    locationid: "13059",
    county: "Clarke",
  },
  {
    fips: "34035",
    count: 369,
    state: "NJ",
    type: "County",
    locationid: "34035",
    county: "Somerset",
  },
  {
    fips: "55063",
    count: 383,
    state: "WI",
    type: "County",
    locationid: "55063",
    county: "La Crosse",
  },
  {
    fips: "48491",
    count: 346,
    state: "TX",
    type: "County",
    locationid: "48491",
    county: "Williamson",
  },
  {
    fips: "51041",
    count: 339,
    state: "VA",
    type: "County",
    locationid: "51041",
    county: "Chesterfield",
  },
  {
    fips: "25005",
    count: 372,
    state: "MA",
    type: "County",
    locationid: "25005",
    county: "Bristol",
  },
  {
    fips: "20209",
    count: 384,
    state: "KS",
    type: "County",
    locationid: "20209",
    county: "Wyandotte",
  },
  {
    fips: "36085",
    count: 364,
    state: "NY",
    type: "County",
    locationid: "36085",
    county: "Richmond",
  },
  {
    fips: "24033",
    count: 352,
    state: "MD",
    type: "County",
    locationid: "24033",
    county: "Prince Georges",
  },
  {
    fips: "18035",
    count: 331,
    state: "IN",
    type: "County",
    locationid: "18035",
    county: "Delaware",
  },
  {
    fips: "42029",
    count: 367,
    state: "PA",
    type: "County",
    locationid: "42029",
    county: "Chester",
  },
  {
    fips: "13067",
    count: 357,
    state: "GA",
    type: "County",
    locationid: "13067",
    county: "Cobb",
  },
  {
    fips: "21145",
    count: 319,
    state: "KY",
    type: "County",
    locationid: "21145",
    county: "Mccracken",
  },
  {
    fips: "48157",
    count: 343,
    state: "TX",
    type: "County",
    locationid: "48157",
    county: "Fort Bend",
  },
  {
    fips: "47113",
    count: 336,
    state: "TN",
    type: "County",
    locationid: "47113",
    county: "Madison",
  },
  {
    fips: "48167",
    count: 349,
    state: "TX",
    type: "County",
    locationid: "48167",
    county: "Galveston",
  },
  {
    fips: "06111",
    count: 340,
    state: "CA",
    type: "County",
    locationid: "06111",
    county: "Ventura",
  },
  {
    fips: "18057",
    count: 351,
    state: "IN",
    type: "County",
    locationid: "18057",
    county: "Hamilton",
  },
  {
    fips: "41039",
    count: 291,
    state: "OR",
    type: "County",
    locationid: "41039",
    county: "Lane",
  },
  {
    fips: "12073",
    count: 320,
    state: "FL",
    type: "County",
    locationid: "12073",
    county: "Leon",
  },
  {
    fips: "01101",
    count: 353,
    state: "AL",
    type: "County",
    locationid: "01101",
    county: "Montgomery",
  },
  {
    fips: "54039",
    count: 337,
    state: "WV",
    type: "County",
    locationid: "54039",
    county: "Kanawha",
  },
  {
    fips: "47187",
    count: 330,
    state: "TN",
    type: "County",
    locationid: "47187",
    county: "Williamson",
  },
  {
    fips: "37135",
    count: 346,
    state: "NC",
    type: "County",
    locationid: "37135",
    county: "Orange",
  },
  {
    fips: "42069",
    count: 311,
    state: "PA",
    type: "County",
    locationid: "42069",
    county: "Lackawanna",
  },
  {
    fips: "47163",
    count: 340,
    state: "TN",
    type: "County",
    locationid: "47163",
    county: "Sullivan",
  },
  {
    fips: "47179",
    count: 342,
    state: "TN",
    type: "County",
    locationid: "47179",
    county: "Washington",
  },
  {
    fips: "45051",
    count: 313,
    state: "SC",
    type: "County",
    locationid: "45051",
    county: "Horry",
  },
  {
    fips: "18043",
    count: 289,
    state: "IN",
    type: "County",
    locationid: "18043",
    county: "Floyd",
  },
  {
    fips: "13021",
    count: 289,
    state: "GA",
    type: "County",
    locationid: "13021",
    county: "Bibb",
  },
  {
    fips: "18141",
    count: 321,
    state: "IN",
    type: "County",
    locationid: "18141",
    county: "St Joseph",
  },
  {
    fips: "27003",
    count: 275,
    state: "MN",
    type: "County",
    locationid: "27003",
    county: "Anoka",
  },
  {
    fips: "02020",
    count: 299,
    state: "AK",
    type: "County",
    locationid: "02020",
    county: "Anchorage",
  },
  {
    fips: "12019",
    count: 303,
    state: "FL",
    type: "County",
    locationid: "12019",
    county: "Clay",
  },
  {
    fips: "47149",
    count: 272,
    state: "TN",
    type: "County",
    locationid: "47149",
    county: "Rutherford",
  },
  {
    fips: "42071",
    count: 325,
    state: "PA",
    type: "County",
    locationid: "42071",
    county: "Lancaster",
  },
  {
    fips: "06099",
    count: 319,
    state: "CA",
    type: "County",
    locationid: "06099",
    county: "Stanislaus",
  },
  {
    fips: "34039",
    count: 304,
    state: "NJ",
    type: "County",
    locationid: "34039",
    county: "Union",
  },
  {
    fips: "05031",
    count: 296,
    state: "AR",
    type: "County",
    locationid: "05031",
    county: "Craighead",
  },
  {
    fips: "41029",
    count: 281,
    state: "OR",
    type: "County",
    locationid: "41029",
    county: "Jackson",
  },
  {
    fips: "42133",
    count: 280,
    state: "PA",
    type: "County",
    locationid: "42133",
    county: "York",
  },
  {
    fips: "32031",
    count: 292,
    state: "NV",
    type: "County",
    locationid: "32031",
    county: "Washoe",
  },
  {
    fips: "45083",
    count: 290,
    state: "SC",
    type: "County",
    locationid: "45083",
    county: "Spartanburg",
  },
  {
    fips: "48027",
    count: 314,
    state: "TX",
    type: "County",
    locationid: "48027",
    county: "Bell",
  },
  {
    fips: "39165",
    count: 294,
    state: "OH",
    type: "County",
    locationid: "39165",
    county: "Warren",
  },
  {
    fips: "42011",
    count: 267,
    state: "PA",
    type: "County",
    locationid: "42011",
    county: "Berks",
  },
  {
    fips: "29077",
    count: 271,
    state: "MO",
    type: "County",
    locationid: "29077",
    county: "Greene",
  },
  {
    fips: "47141",
    count: 265,
    state: "TN",
    type: "County",
    locationid: "47141",
    county: "Putnam",
  },
  {
    fips: "36071",
    count: 285,
    state: "NY",
    type: "County",
    locationid: "36071",
    county: "Orange",
  },
  {
    fips: "53067",
    count: 287,
    state: "WA",
    type: "County",
    locationid: "53067",
    county: "Thurston",
  },
  {
    fips: "31109",
    count: 242,
    state: "NE",
    type: "County",
    locationid: "31109",
    county: "Lancaster",
  },
  {
    fips: "39017",
    count: 273,
    state: "OH",
    type: "County",
    locationid: "39017",
    county: "Butler",
  },
  {
    fips: "12015",
    count: 275,
    state: "FL",
    type: "County",
    locationid: "12015",
    county: "Charlotte",
  },
  {
    fips: "18157",
    count: 257,
    state: "IN",
    type: "County",
    locationid: "18157",
    county: "Tippecanoe",
  },
  {
    fips: "12053",
    count: 254,
    state: "FL",
    type: "County",
    locationid: "12053",
    county: "Hernando",
  },
  {
    fips: "37147",
    count: 292,
    state: "NC",
    type: "County",
    locationid: "37147",
    county: "Pitt",
  },
  {
    fips: "51540",
    count: 298,
    state: "VA",
    type: "County",
    locationid: "51540",
    county: "Charlottesville City",
  },
  {
    fips: "08041",
    count: 277,
    state: "CO",
    type: "County",
    locationid: "08041",
    county: "El Paso",
  },
  {
    fips: "54011",
    count: 282,
    state: "WV",
    type: "County",
    locationid: "54011",
    county: "Cabell",
  },
  {
    fips: "12117",
    count: 292,
    state: "FL",
    type: "County",
    locationid: "12117",
    county: "Seminole",
  },
  {
    fips: "44003",
    count: 296,
    state: "RI",
    type: "County",
    locationid: "44003",
    county: "Kent",
  },
  {
    fips: "28035",
    count: 270,
    state: "MS",
    type: "County",
    locationid: "28035",
    county: "Forrest",
  },
  {
    fips: "37051",
    count: 285,
    state: "NC",
    type: "County",
    locationid: "37051",
    county: "Cumberland",
  },
  {
    fips: "48121",
    count: 235,
    state: "TX",
    type: "County",
    locationid: "48121",
    county: "Denton",
  },
  {
    fips: "23011",
    count: 281,
    state: "ME",
    type: "County",
    locationid: "23011",
    county: "Kennebec",
  },
  {
    fips: "42049",
    count: 282,
    state: "PA",
    type: "County",
    locationid: "42049",
    county: "Erie",
  },
  {
    fips: "12111",
    count: 254,
    state: "FL",
    type: "County",
    locationid: "12111",
    county: "Saint Lucie",
  },
  {
    fips: "13135",
    count: 269,
    state: "GA",
    type: "County",
    locationid: "13135",
    county: "Gwinnett",
  },
  {
    fips: "53073",
    count: 243,
    state: "WA",
    type: "County",
    locationid: "53073",
    county: "Whatcom",
  },
  {
    fips: "13139",
    count: 261,
    state: "GA",
    type: "County",
    locationid: "13139",
    county: "Hall",
  },
  {
    fips: "37081",
    count: 271,
    state: "NC",
    type: "County",
    locationid: "37081",
    county: "Guilford",
  },
  {
    fips: "21019",
    count: 254,
    state: "KY",
    type: "County",
    locationid: "21019",
    county: "Boyd",
  },
  {
    fips: "22103",
    count: 252,
    state: "LA",
    type: "County",
    locationid: "22103",
    county: "Saint Tammany",
  },
  {
    fips: "42079",
    count: 253,
    state: "PA",
    type: "County",
    locationid: "42079",
    county: "Luzerne",
  },
  {
    fips: "10005",
    count: 262,
    state: "DE",
    type: "County",
    locationid: "10005",
    county: "Sussex",
  },
  {
    fips: "17197",
    count: 261,
    state: "IL",
    type: "County",
    locationid: "17197",
    county: "Will",
  },
  {
    fips: "36091",
    count: 235,
    state: "NY",
    type: "County",
    locationid: "36091",
    county: "Saratoga",
  },
  {
    fips: "19113",
    count: 255,
    state: "IA",
    type: "County",
    locationid: "19113",
    county: "Linn",
  },
  {
    fips: "42129",
    count: 265,
    state: "PA",
    type: "County",
    locationid: "42129",
    county: "Westmoreland",
  },
  {
    fips: "48303",
    count: 232,
    state: "TX",
    type: "County",
    locationid: "48303",
    county: "Lubbock",
  },
  {
    fips: "50007",
    count: 250,
    state: "VT",
    type: "County",
    locationid: "50007",
    county: "Chittenden",
  },
  {
    fips: "08013",
    count: 250,
    state: "CO",
    type: "County",
    locationid: "08013",
    county: "Boulder",
  },
  {
    fips: "12085",
    count: 235,
    state: "FL",
    type: "County",
    locationid: "12085",
    county: "Martin",
  },
  {
    fips: "16055",
    count: 242,
    state: "ID",
    type: "County",
    locationid: "16055",
    county: "Kootenai",
  },
  {
    fips: "48181",
    count: 230,
    state: "TX",
    type: "County",
    locationid: "48181",
    county: "Grayson",
  },
  {
    fips: "53061",
    count: 256,
    state: "WA",
    type: "County",
    locationid: "53061",
    county: "Snohomish",
  },
  {
    fips: "08059",
    count: 244,
    state: "CO",
    type: "County",
    locationid: "08059",
    county: "Jefferson",
  },
  {
    fips: "18063",
    count: 242,
    state: "IN",
    type: "County",
    locationid: "18063",
    county: "Hendricks",
  },
  {
    fips: "36027",
    count: 241,
    state: "NY",
    type: "County",
    locationid: "36027",
    county: "Dutchess",
  },
  {
    fips: "13115",
    count: 212,
    state: "GA",
    type: "County",
    locationid: "13115",
    county: "Floyd",
  },
  {
    fips: "12081",
    count: 249,
    state: "FL",
    type: "County",
    locationid: "12081",
    county: "Manatee",
  },
  {
    fips: "13215",
    count: 224,
    state: "GA",
    type: "County",
    locationid: "13215",
    county: "Muscogee",
  },
  {
    fips: "13077",
    count: 231,
    state: "GA",
    type: "County",
    locationid: "13077",
    county: "Coweta",
  },
  {
    fips: "20177",
    count: 252,
    state: "KS",
    type: "County",
    locationid: "20177",
    county: "Shawnee",
  },
  {
    fips: "26121",
    count: 245,
    state: "MI",
    type: "County",
    locationid: "26121",
    county: "Muskegon",
  },
  {
    fips: "05143",
    count: 225,
    state: "AR",
    type: "County",
    locationid: "05143",
    county: "Washington",
  },
  {
    fips: "33011",
    count: 245,
    state: "NH",
    type: "County",
    locationid: "33011",
    county: "Hillsborough",
  },
  {
    fips: "33017",
    count: 247,
    state: "NH",
    type: "County",
    locationid: "33017",
    county: "Strafford",
  },
  {
    fips: "37035",
    count: 225,
    state: "NC",
    type: "County",
    locationid: "37035",
    county: "Catawba",
  },
  {
    fips: "33013",
    count: 227,
    state: "NH",
    type: "County",
    locationid: "33013",
    county: "Merrimack",
  },
  {
    fips: "48245",
    count: 241,
    state: "TX",
    type: "County",
    locationid: "48245",
    county: "Jefferson",
  },
  {
    fips: "04025",
    count: 220,
    state: "AZ",
    type: "County",
    locationid: "04025",
    county: "Yavapai",
  },
  {
    fips: "48183",
    count: 211,
    state: "TX",
    type: "County",
    locationid: "48183",
    county: "Gregg",
  },
  {
    fips: "12061",
    count: 205,
    state: "FL",
    type: "County",
    locationid: "12061",
    county: "Indian River",
  },
  {
    fips: "18173",
    count: 236,
    state: "IN",
    type: "County",
    locationid: "18173",
    county: "Warrick",
  },
  {
    fips: "12021",
    count: 230,
    state: "FL",
    type: "County",
    locationid: "12021",
    county: "Collier",
  },
  {
    fips: "13063",
    count: 235,
    state: "GA",
    type: "County",
    locationid: "13063",
    county: "Clayton",
  },
  {
    fips: "33009",
    count: 234,
    state: "NH",
    type: "County",
    locationid: "33009",
    county: "Grafton",
  },
  {
    fips: "39057",
    count: 224,
    state: "OH",
    type: "County",
    locationid: "39057",
    county: "Greene",
  },
  {
    fips: "48355",
    count: 213,
    state: "TX",
    type: "County",
    locationid: "48355",
    county: "Nueces",
  },
  {
    fips: "34021",
    count: 238,
    state: "NJ",
    type: "County",
    locationid: "34021",
    county: "Mercer",
  },
  {
    fips: "51840",
    count: 234,
    state: "VA",
    type: "County",
    locationid: "51840",
    county: "Winchester City",
  },
  {
    fips: "18163",
    count: 238,
    state: "IN",
    type: "County",
    locationid: "18163",
    county: "Vanderburgh",
  },
  {
    fips: "18039",
    count: 217,
    state: "IN",
    type: "County",
    locationid: "18039",
    county: "Elkhart",
  },
  {
    fips: "19163",
    count: 223,
    state: "IA",
    type: "County",
    locationid: "19163",
    county: "Scott",
  },
  {
    fips: "22019",
    count: 196,
    state: "LA",
    type: "County",
    locationid: "22019",
    county: "Calcasieu",
  },
  {
    fips: "51085",
    count: 216,
    state: "VA",
    type: "County",
    locationid: "51085",
    county: "Hanover",
  },
  {
    fips: "53005",
    count: 218,
    state: "WA",
    type: "County",
    locationid: "53005",
    county: "Benton",
  },
  {
    fips: "53011",
    count: 204,
    state: "WA",
    type: "County",
    locationid: "53011",
    county: "Clark",
  },
  {
    fips: "39093",
    count: 200,
    state: "OH",
    type: "County",
    locationid: "39093",
    county: "Lorain",
  },
  {
    fips: "55087",
    count: 181,
    state: "WI",
    type: "County",
    locationid: "55087",
    county: "Outagamie",
  },
  {
    fips: "21227",
    count: 204,
    state: "KY",
    type: "County",
    locationid: "21227",
    county: "Warren",
  },
  {
    fips: "39003",
    count: 217,
    state: "OH",
    type: "County",
    locationid: "39003",
    county: "Allen",
  },
  {
    fips: "01003",
    count: 203,
    state: "AL",
    type: "County",
    locationid: "01003",
    county: "Baldwin",
  },
  {
    fips: "28081",
    count: 228,
    state: "MS",
    type: "County",
    locationid: "28081",
    county: "Lee",
  },
  {
    fips: "37125",
    count: 222,
    state: "NC",
    type: "County",
    locationid: "37125",
    county: "Moore",
  },
  {
    fips: "48215",
    count: 210,
    state: "TX",
    type: "County",
    locationid: "48215",
    county: "Hidalgo",
  },
  {
    fips: "01069",
    count: 210,
    state: "AL",
    type: "County",
    locationid: "01069",
    county: "Houston",
  },
  {
    fips: "18105",
    count: 208,
    state: "IN",
    type: "County",
    locationid: "18105",
    county: "Monroe",
  },
  {
    fips: "41047",
    count: 196,
    state: "OR",
    type: "County",
    locationid: "41047",
    county: "Marion",
  },
  {
    fips: "17161",
    count: 183,
    state: "IL",
    type: "County",
    locationid: "17161",
    county: "Rock Island",
  },
  {
    fips: "36077",
    count: 190,
    state: "NY",
    type: "County",
    locationid: "36077",
    county: "Otsego",
  },
  {
    fips: "45013",
    count: 220,
    state: "SC",
    type: "County",
    locationid: "45013",
    county: "Beaufort",
  },
  {
    fips: "29047",
    count: 218,
    state: "MO",
    type: "County",
    locationid: "29047",
    county: "Clay",
  },
  {
    fips: "04015",
    count: 197,
    state: "AZ",
    type: "County",
    locationid: "04015",
    county: "Mohave",
  },
  {
    fips: "06029",
    count: 202,
    state: "CA",
    type: "County",
    locationid: "06029",
    county: "Kern",
  },
  {
    fips: "26055",
    count: 204,
    state: "MI",
    type: "County",
    locationid: "26055",
    county: "Grand Traverse",
  },
  {
    fips: "06097",
    count: 190,
    state: "CA",
    type: "County",
    locationid: "06097",
    county: "Sonoma",
  },
  {
    fips: "01015",
    count: 184,
    state: "AL",
    type: "County",
    locationid: "01015",
    county: "Calhoun",
  },
  {
    fips: "21093",
    count: 176,
    state: "KY",
    type: "County",
    locationid: "21093",
    county: "Hardin",
  },
  {
    fips: "12005",
    count: 187,
    state: "FL",
    type: "County",
    locationid: "12005",
    county: "Bay",
  },
  {
    fips: "42125",
    count: 208,
    state: "PA",
    type: "County",
    locationid: "42125",
    county: "Washington",
  },
  {
    fips: "54061",
    count: 200,
    state: "WV",
    type: "County",
    locationid: "54061",
    county: "Monongalia",
  },
  {
    fips: "17089",
    count: 202,
    state: "IL",
    type: "County",
    locationid: "17089",
    county: "Kane",
  },
  {
    fips: "34031",
    count: 202,
    state: "NJ",
    type: "County",
    locationid: "34031",
    county: "Passaic",
  },
  {
    fips: "36087",
    count: 190,
    state: "NY",
    type: "County",
    locationid: "36087",
    county: "Rockland",
  },
  {
    fips: "48375",
    count: 188,
    state: "TX",
    type: "County",
    locationid: "48375",
    county: "Potter",
  },
  {
    fips: "17119",
    count: 204,
    state: "IL",
    type: "County",
    locationid: "17119",
    county: "Madison",
  },
  {
    fips: "22073",
    count: 198,
    state: "LA",
    type: "County",
    locationid: "22073",
    county: "Ouachita",
  },
  {
    fips: "55089",
    count: 202,
    state: "WI",
    type: "County",
    locationid: "55089",
    county: "Ozaukee",
  },
  {
    fips: "37071",
    count: 200,
    state: "NC",
    type: "County",
    locationid: "37071",
    county: "Gaston",
  },
  {
    fips: "24045",
    count: 201,
    state: "MD",
    type: "County",
    locationid: "24045",
    county: "Wicomico",
  },
  {
    fips: "33015",
    count: 198,
    state: "NH",
    type: "County",
    locationid: "33015",
    county: "Rockingham",
  },
  {
    fips: "37025",
    count: 201,
    state: "NC",
    type: "County",
    locationid: "37025",
    county: "Cabarrus",
  },
  {
    fips: "06081",
    count: 199,
    state: "CA",
    type: "County",
    locationid: "06081",
    county: "San Mateo",
  },
  {
    fips: "25023",
    count: 192,
    state: "MA",
    type: "County",
    locationid: "25023",
    county: "Plymouth",
  },
  {
    fips: "40027",
    count: 194,
    state: "OK",
    type: "County",
    locationid: "40027",
    county: "Cleveland",
  },
  {
    fips: "53035",
    count: 187,
    state: "WA",
    type: "County",
    locationid: "53035",
    county: "Kitsap",
  },
  {
    fips: "39139",
    count: 181,
    state: "OH",
    type: "County",
    locationid: "39139",
    county: "Richland",
  },
  {
    fips: "06077",
    count: 173,
    state: "CA",
    type: "County",
    locationid: "06077",
    county: "San Joaquin",
  },
  {
    fips: "51153",
    count: 173,
    state: "VA",
    type: "County",
    locationid: "51153",
    county: "Prince William",
  },
  {
    fips: "08035",
    count: 178,
    state: "CO",
    type: "County",
    locationid: "08035",
    county: "Douglas",
  },
  {
    fips: "42041",
    count: 190,
    state: "PA",
    type: "County",
    locationid: "42041",
    county: "Cumberland",
  },
  {
    fips: "51700",
    count: 184,
    state: "VA",
    type: "County",
    locationid: "51700",
    county: "Newport News City",
  },
  {
    fips: "54069",
    count: 190,
    state: "WV",
    type: "County",
    locationid: "54069",
    county: "Ohio",
  },
  {
    fips: "34017",
    count: 178,
    state: "NJ",
    type: "County",
    locationid: "34017",
    county: "Hudson",
  },
  {
    fips: "06061",
    count: 182,
    state: "CA",
    type: "County",
    locationid: "06061",
    county: "Placer",
  },
  {
    fips: "36007",
    count: 182,
    state: "NY",
    type: "County",
    locationid: "36007",
    county: "Broome",
  },
  {
    fips: "17113",
    count: 161,
    state: "IL",
    type: "County",
    locationid: "17113",
    county: "Mclean",
  },
  {
    fips: "21195",
    count: 151,
    state: "KY",
    type: "County",
    locationid: "21195",
    county: "Pike",
  },
  {
    fips: "17163",
    count: 172,
    state: "IL",
    type: "County",
    locationid: "17163",
    county: "Saint Clair",
  },
  {
    fips: "51121",
    count: 170,
    state: "VA",
    type: "County",
    locationid: "51121",
    county: "Montgomery",
  },
  {
    fips: "48441",
    count: 157,
    state: "TX",
    type: "County",
    locationid: "48441",
    county: "Taylor",
  },
  {
    fips: "17201",
    count: 179,
    state: "IL",
    type: "County",
    locationid: "17201",
    county: "Winnebago",
  },
  {
    fips: "26075",
    count: 170,
    state: "MI",
    type: "County",
    locationid: "26075",
    county: "Jackson",
  },
  {
    fips: "51013",
    count: 172,
    state: "VA",
    type: "County",
    locationid: "51013",
    county: "Arlington",
  },
  {
    fips: "29183",
    count: 181,
    state: "MO",
    type: "County",
    locationid: "29183",
    county: "Saint Charles",
  },
  {
    fips: "42007",
    count: 181,
    state: "PA",
    type: "County",
    locationid: "42007",
    county: "Beaver",
  },
  {
    fips: "55133",
    count: 180,
    state: "WI",
    type: "County",
    locationid: "55133",
    county: "Waukesha",
  },
  {
    fips: "34011",
    count: 141,
    state: "NJ",
    type: "County",
    locationid: "34011",
    county: "Cumberland",
  },
  {
    fips: "45041",
    count: 176,
    state: "SC",
    type: "County",
    locationid: "45041",
    county: "Florence",
  },
  {
    fips: "24021",
    count: 157,
    state: "MD",
    type: "County",
    locationid: "24021",
    county: "Frederick",
  },
  {
    fips: "39043",
    count: 137,
    state: "OH",
    type: "County",
    locationid: "39043",
    county: "Erie",
  },
  {
    fips: "42021",
    count: 156,
    state: "PA",
    type: "County",
    locationid: "42021",
    county: "Cambria",
  },
  {
    fips: "48309",
    count: 154,
    state: "TX",
    type: "County",
    locationid: "48309",
    county: "Mclennan",
  },
  {
    fips: "51810",
    count: 165,
    state: "VA",
    type: "County",
    locationid: "51810",
    county: "Virginia Beach City",
  },
  {
    fips: "22079",
    count: 162,
    state: "LA",
    type: "County",
    locationid: "22079",
    county: "Rapides",
  },
  {
    fips: "39037",
    count: 176,
    state: "OH",
    type: "County",
    locationid: "39037",
    county: "Darke",
  },
  {
    fips: "06083",
    count: 162,
    state: "CA",
    type: "County",
    locationid: "06083",
    county: "Santa Barbara",
  },
  {
    fips: "24027",
    count: 153,
    state: "MD",
    type: "County",
    locationid: "24027",
    county: "Howard",
  },
  {
    fips: "12017",
    count: 164,
    state: "FL",
    type: "County",
    locationid: "12017",
    county: "Citrus",
  },
  {
    fips: "36113",
    count: 169,
    state: "NY",
    type: "County",
    locationid: "36113",
    county: "Warren",
  },
  {
    fips: "39025",
    count: 143,
    state: "OH",
    type: "County",
    locationid: "39025",
    county: "Clermont",
  },
  {
    fips: "39167",
    count: 138,
    state: "OH",
    type: "County",
    locationid: "39167",
    county: "Washington",
  },
  {
    fips: "51650",
    count: 146,
    state: "VA",
    type: "County",
    locationid: "51650",
    county: "Hampton City",
  },
  {
    fips: "55139",
    count: 154,
    state: "WI",
    type: "County",
    locationid: "55139",
    county: "Winnebago",
  },
  {
    fips: "18127",
    count: 166,
    state: "IN",
    type: "County",
    locationid: "18127",
    county: "Porter",
  },
  {
    fips: "19193",
    count: 133,
    state: "IA",
    type: "County",
    locationid: "19193",
    county: "Woodbury",
  },
  {
    fips: "13095",
    count: 150,
    state: "GA",
    type: "County",
    locationid: "13095",
    county: "Dougherty",
  },
  {
    fips: "55035",
    count: 137,
    state: "WI",
    type: "County",
    locationid: "55035",
    county: "Eau Claire",
  },
  {
    fips: "09011",
    count: 149,
    state: "CT",
    type: "County",
    locationid: "09011",
    county: "New London",
  },
  {
    fips: "17019",
    count: 148,
    state: "IL",
    type: "County",
    locationid: "17019",
    county: "Champaign",
  },
  {
    fips: "13047",
    count: 145,
    state: "GA",
    type: "County",
    locationid: "13047",
    county: "Catoosa",
  },
  {
    fips: "34001",
    count: 155,
    state: "NJ",
    type: "County",
    locationid: "34001",
    county: "Atlantic",
  },
  {
    fips: "18095",
    count: 158,
    state: "IN",
    type: "County",
    locationid: "18095",
    county: "Madison",
  },
  {
    fips: "41017",
    count: 139,
    state: "OR",
    type: "County",
    locationid: "41017",
    county: "Deschutes",
  },
  {
    fips: "51800",
    count: 137,
    state: "VA",
    type: "County",
    locationid: "51800",
    county: "Suffolk City",
  },
  {
    fips: "05131",
    count: 152,
    state: "AR",
    type: "County",
    locationid: "05131",
    county: "Sebastian",
  },
  {
    fips: "12109",
    count: 128,
    state: "FL",
    type: "County",
    locationid: "12109",
    county: "Saint Johns",
  },
  {
    fips: "19169",
    count: 124,
    state: "IA",
    type: "County",
    locationid: "19169",
    county: "Story",
  },
  {
    fips: "01125",
    count: 147,
    state: "AL",
    type: "County",
    locationid: "01125",
    county: "Tuscaloosa",
  },
  {
    fips: "28047",
    count: 155,
    state: "MS",
    type: "County",
    locationid: "28047",
    county: "Harrison",
  },
  {
    fips: "51003",
    count: 155,
    state: "VA",
    type: "County",
    locationid: "51003",
    county: "Albemarle",
  },
  {
    fips: "06041",
    count: 152,
    state: "CA",
    type: "County",
    locationid: "06041",
    county: "Marin",
  },
  {
    fips: "29161",
    count: 156,
    state: "MO",
    type: "County",
    locationid: "29161",
    county: "Phelps",
  },
  {
    fips: "30013",
    count: 128,
    state: "MT",
    type: "County",
    locationid: "30013",
    county: "Cascade",
  },
  {
    fips: "18019",
    count: 141,
    state: "IN",
    type: "County",
    locationid: "18019",
    county: "Clark",
  },
  {
    fips: "28059",
    count: 135,
    state: "MS",
    type: "County",
    locationid: "28059",
    county: "Jackson",
  },
  {
    fips: "46103",
    count: 142,
    state: "SD",
    type: "County",
    locationid: "46103",
    county: "Pennington",
  },
  {
    fips: "13275",
    count: 129,
    state: "GA",
    type: "County",
    locationid: "13275",
    county: "Thomas",
  },
  {
    fips: "48037",
    count: 149,
    state: "TX",
    type: "County",
    locationid: "48037",
    county: "Bowie",
  },
  {
    fips: "48277",
    count: 134,
    state: "TX",
    type: "County",
    locationid: "48277",
    county: "Lamar",
  },
  {
    fips: "24043",
    count: 147,
    state: "MD",
    type: "County",
    locationid: "24043",
    county: "Washington",
  },
  {
    fips: "39155",
    count: 150,
    state: "OH",
    type: "County",
    locationid: "39155",
    county: "Trumbull",
  },
  {
    fips: "26047",
    count: 143,
    state: "MI",
    type: "County",
    locationid: "26047",
    county: "Emmet",
  },
  {
    fips: "29097",
    count: 143,
    state: "MO",
    type: "County",
    locationid: "29097",
    county: "Jasper",
  },
  {
    fips: "37049",
    count: 141,
    state: "NC",
    type: "County",
    locationid: "37049",
    county: "Craven",
  },
  {
    fips: "55073",
    count: 126,
    state: "WI",
    type: "County",
    locationid: "55073",
    county: "Marathon",
  },
  {
    fips: "53077",
    count: 130,
    state: "WA",
    type: "County",
    locationid: "53077",
    county: "Yakima",
  },
  {
    fips: "13151",
    count: 136,
    state: "GA",
    type: "County",
    locationid: "13151",
    county: "Henry",
  },
  {
    fips: "47119",
    count: 148,
    state: "TN",
    type: "County",
    locationid: "47119",
    county: "Maury",
  },
  {
    fips: "47165",
    count: 126,
    state: "TN",
    type: "County",
    locationid: "47165",
    county: "Sumner",
  },
  {
    fips: "08101",
    count: 132,
    state: "CO",
    type: "County",
    locationid: "08101",
    county: "Pueblo",
  },
  {
    fips: "13113",
    count: 130,
    state: "GA",
    type: "County",
    locationid: "13113",
    county: "Fayette",
  },
  {
    fips: "17099",
    count: 126,
    state: "IL",
    type: "County",
    locationid: "17099",
    county: "La Salle",
  },
  {
    fips: "06095",
    count: 137,
    state: "CA",
    type: "County",
    locationid: "06095",
    county: "Solano",
  },
  {
    fips: "08069",
    count: 145,
    state: "CO",
    type: "County",
    locationid: "08069",
    county: "Larimer",
  },
  {
    fips: "38035",
    count: 120,
    state: "ND",
    type: "County",
    locationid: "38035",
    county: "Grand Forks",
  },
  {
    fips: "13117",
    count: 141,
    state: "GA",
    type: "County",
    locationid: "13117",
    county: "Forsyth",
  },
  {
    fips: "21193",
    count: 131,
    state: "KY",
    type: "County",
    locationid: "21193",
    county: "Perry",
  },
  {
    fips: "26021",
    count: 136,
    state: "MI",
    type: "County",
    locationid: "26021",
    county: "Berrien",
  },
  {
    fips: "28033",
    count: 145,
    state: "MS",
    type: "County",
    locationid: "28033",
    county: "Desoto",
  },
  {
    fips: "36019",
    count: 121,
    state: "NY",
    type: "County",
    locationid: "36019",
    county: "Clinton",
  },
  {
    fips: "38017",
    count: 129,
    state: "ND",
    type: "County",
    locationid: "38017",
    county: "Cass",
  },
  {
    fips: "51680",
    count: 144,
    state: "VA",
    type: "County",
    locationid: "51680",
    county: "Lynchburg City",
  },
  {
    fips: "04027",
    count: 137,
    state: "AZ",
    type: "County",
    locationid: "04027",
    county: "Yuma",
  },
  {
    fips: "29051",
    count: 131,
    state: "MO",
    type: "County",
    locationid: "29051",
    county: "Cole",
  },
  {
    fips: "48041",
    count: 123,
    state: "TX",
    type: "County",
    locationid: "48041",
    county: "Brazos",
  },
  {
    fips: "51550",
    count: 134,
    state: "VA",
    type: "County",
    locationid: "51550",
    county: "Chesapeake City",
  },
  {
    fips: "39085",
    count: 122,
    state: "OH",
    type: "County",
    locationid: "39085",
    county: "Lake",
  },
  {
    fips: "42093",
    count: 135,
    state: "PA",
    type: "County",
    locationid: "42093",
    county: "Montour",
  },
  {
    fips: "48485",
    count: 122,
    state: "TX",
    type: "County",
    locationid: "48485",
    county: "Wichita",
  },
  {
    fips: "01103",
    count: 116,
    state: "AL",
    type: "County",
    locationid: "01103",
    county: "Morgan",
  },
  {
    fips: "05051",
    count: 119,
    state: "AR",
    type: "County",
    locationid: "05051",
    county: "Garland",
  },
  {
    fips: "08077",
    count: 124,
    state: "CO",
    type: "County",
    locationid: "08077",
    county: "Mesa",
  },
  {
    fips: "12091",
    count: 119,
    state: "FL",
    type: "County",
    locationid: "12091",
    county: "Okaloosa",
  },
  {
    fips: "51775",
    count: 126,
    state: "VA",
    type: "County",
    locationid: "51775",
    county: "Salem",
  },
  {
    fips: "23031",
    count: 119,
    state: "ME",
    type: "County",
    locationid: "23031",
    county: "York",
  },
  {
    fips: "24015",
    count: 132,
    state: "MD",
    type: "County",
    locationid: "24015",
    county: "Cecil",
  },
  {
    fips: "26093",
    count: 129,
    state: "MI",
    type: "County",
    locationid: "26093",
    county: "Livingston",
  },
  {
    fips: "06107",
    count: 113,
    state: "CA",
    type: "County",
    locationid: "06107",
    county: "Tulare",
  },
  {
    fips: "42015",
    count: 123,
    state: "PA",
    type: "County",
    locationid: "42015",
    county: "Bradford",
  },
  {
    fips: "45003",
    count: 106,
    state: "SC",
    type: "County",
    locationid: "45003",
    county: "Aiken",
  },
  {
    fips: "18005",
    count: 127,
    state: "IN",
    type: "County",
    locationid: "18005",
    county: "Bartholomew",
  },
  {
    fips: "18167",
    count: 128,
    state: "IN",
    type: "County",
    locationid: "18167",
    county: "Vigo",
  },
  {
    fips: "26017",
    count: 122,
    state: "MI",
    type: "County",
    locationid: "26017",
    county: "Bay",
  },
  {
    fips: "06007",
    count: 127,
    state: "CA",
    type: "County",
    locationid: "06007",
    county: "Butte",
  },
  {
    fips: "06089",
    count: 128,
    state: "CA",
    type: "County",
    locationid: "06089",
    county: "Shasta",
  },
  {
    fips: "39023",
    count: 122,
    state: "OH",
    type: "County",
    locationid: "39023",
    county: "Clark",
  },
  {
    fips: "42019",
    count: 132,
    state: "PA",
    type: "County",
    locationid: "42019",
    county: "Butler",
  },
  {
    fips: "45091",
    count: 130,
    state: "SC",
    type: "County",
    locationid: "45091",
    county: "York",
  },
  {
    fips: "23001",
    count: 122,
    state: "ME",
    type: "County",
    locationid: "23001",
    county: "Androscoggin",
  },
  {
    fips: "42055",
    count: 114,
    state: "PA",
    type: "County",
    locationid: "42055",
    county: "Franklin",
  },
  {
    fips: "47009",
    count: 109,
    state: "TN",
    type: "County",
    locationid: "47009",
    county: "Blount",
  },
  {
    fips: "53057",
    count: 130,
    state: "WA",
    type: "County",
    locationid: "53057",
    county: "Skagit",
  },
  {
    fips: "13127",
    count: 119,
    state: "GA",
    type: "County",
    locationid: "13127",
    county: "Glynn",
  },
  {
    fips: "36057",
    count: 114,
    state: "NY",
    type: "County",
    locationid: "36057",
    county: "Montgomery",
  },
  {
    fips: "05007",
    count: 117,
    state: "AR",
    type: "County",
    locationid: "05007",
    county: "Benton",
  },
  {
    fips: "12097",
    count: 121,
    state: "FL",
    type: "County",
    locationid: "12097",
    county: "Osceola",
  },
  {
    fips: "45007",
    count: 105,
    state: "SC",
    type: "County",
    locationid: "45007",
    county: "Anderson",
  },
  {
    fips: "26111",
    count: 119,
    state: "MI",
    type: "County",
    locationid: "26111",
    county: "Midland",
  },
  {
    fips: "29031",
    count: 126,
    state: "MO",
    type: "County",
    locationid: "29031",
    county: "Cape Girardeau",
  },
  {
    fips: "29127",
    count: 125,
    state: "MO",
    type: "County",
    locationid: "29127",
    county: "Marion",
  },
  {
    fips: "45063",
    count: 119,
    state: "SC",
    type: "County",
    locationid: "45063",
    county: "Lexington",
  },
  {
    fips: "29021",
    count: 123,
    state: "MO",
    type: "County",
    locationid: "29021",
    county: "Buchanan",
  },
  {
    fips: "47125",
    count: 108,
    state: "TN",
    type: "County",
    locationid: "47125",
    county: "Montgomery",
  },
  {
    fips: "51177",
    count: 101,
    state: "VA",
    type: "County",
    locationid: "51177",
    county: "Spotsylvania",
  },
  {
    fips: "27139",
    count: 122,
    state: "MN",
    type: "County",
    locationid: "27139",
    county: "Scott",
  },
  {
    fips: "41003",
    count: 108,
    state: "OR",
    type: "County",
    locationid: "41003",
    county: "Benton",
  },
  {
    fips: "18091",
    count: 114,
    state: "IN",
    type: "County",
    locationid: "18091",
    county: "La Porte",
  },
  {
    fips: "36065",
    count: 123,
    state: "NY",
    type: "County",
    locationid: "36065",
    county: "Oneida",
  },
  {
    fips: "39169",
    count: 97,
    state: "OH",
    type: "County",
    locationid: "39169",
    county: "Wayne",
  },
  {
    fips: "01055",
    count: 102,
    state: "AL",
    type: "County",
    locationid: "01055",
    county: "Etowah",
  },
  {
    fips: "34009",
    count: 112,
    state: "NJ",
    type: "County",
    locationid: "34009",
    county: "Cape May",
  },
  {
    fips: "51107",
    count: 112,
    state: "VA",
    type: "County",
    locationid: "51107",
    county: "Loudoun",
  },
  {
    fips: "47011",
    count: 106,
    state: "TN",
    type: "County",
    locationid: "47011",
    county: "Bradley",
  },
  {
    fips: "55039",
    count: 117,
    state: "WI",
    type: "County",
    locationid: "55039",
    county: "Fond Du Lac",
  },
  {
    fips: "22109",
    count: 113,
    state: "LA",
    type: "County",
    locationid: "22109",
    county: "Terrebonne",
  },
  {
    fips: "24041",
    count: 89,
    state: "MD",
    type: "County",
    locationid: "24041",
    county: "Talbot",
  },
  {
    fips: "36089",
    count: 105,
    state: "NY",
    type: "County",
    locationid: "36089",
    county: "Saint Lawrence",
  },
  {
    fips: "04021",
    count: 111,
    state: "AZ",
    type: "County",
    locationid: "04021",
    county: "Pinal",
  },
  {
    fips: "27137",
    count: 112,
    state: "MN",
    type: "County",
    locationid: "27137",
    county: "Saint Louis",
  },
  {
    fips: "42013",
    count: 112,
    state: "PA",
    type: "County",
    locationid: "42013",
    county: "Blair",
  },
  {
    fips: "30111",
    count: 105,
    state: "MT",
    type: "County",
    locationid: "30111",
    county: "Yellowstone",
  },
  {
    fips: "39109",
    count: 101,
    state: "OH",
    type: "County",
    locationid: "39109",
    county: "Miami",
  },
  {
    fips: "09007",
    count: 100,
    state: "CT",
    type: "County",
    locationid: "09007",
    county: "Middlesex",
  },
  {
    fips: "26139",
    count: 104,
    state: "MI",
    type: "County",
    locationid: "26139",
    county: "Ottawa",
  },
  {
    fips: "06053",
    count: 97,
    state: "CA",
    type: "County",
    locationid: "06053",
    county: "Monterey",
  },
  {
    fips: "25003",
    count: 104,
    state: "MA",
    type: "County",
    locationid: "25003",
    county: "Berkshire",
  },
  {
    fips: "37097",
    count: 110,
    state: "NC",
    type: "County",
    locationid: "37097",
    county: "Iredell",
  },
  {
    fips: "13255",
    count: 100,
    state: "GA",
    type: "County",
    locationid: "13255",
    county: "Spalding",
  },
  {
    fips: "39045",
    count: 109,
    state: "OH",
    type: "County",
    locationid: "39045",
    county: "Fairfield",
  },
  {
    fips: "42075",
    count: 105,
    state: "PA",
    type: "County",
    locationid: "42075",
    county: "Lebanon",
  },
  {
    fips: "05045",
    count: 98,
    state: "AR",
    type: "County",
    locationid: "05045",
    county: "Faulkner",
  },
  {
    fips: "25001",
    count: 100,
    state: "MA",
    type: "County",
    locationid: "25001",
    county: "Barnstable",
  },
  {
    fips: "48209",
    count: 105,
    state: "TX",
    type: "County",
    locationid: "48209",
    county: "Hays",
  },
  {
    fips: "21107",
    count: 98,
    state: "KY",
    type: "County",
    locationid: "21107",
    county: "Hopkins",
  },
  {
    fips: "26147",
    count: 97,
    state: "MI",
    type: "County",
    locationid: "26147",
    county: "Saint Clair",
  },
  {
    fips: "42051",
    count: 109,
    state: "PA",
    type: "County",
    locationid: "42051",
    county: "Fayette",
  },
  {
    fips: "06079",
    count: 103,
    state: "CA",
    type: "County",
    locationid: "06079",
    county: "San Luis Obispo",
  },
  {
    fips: "08123",
    count: 104,
    state: "CO",
    type: "County",
    locationid: "08123",
    county: "Weld",
  },
  {
    fips: "19013",
    count: 97,
    state: "IA",
    type: "County",
    locationid: "19013",
    county: "Black Hawk",
  },
  {
    fips: "34015",
    count: 99,
    state: "NJ",
    type: "County",
    locationid: "34015",
    county: "Gloucester",
  },
  {
    fips: "36063",
    count: 109,
    state: "NY",
    type: "County",
    locationid: "36063",
    county: "Niagara",
  },
  {
    fips: "54081",
    count: 108,
    state: "WV",
    type: "County",
    locationid: "54081",
    county: "Raleigh",
  },
  {
    fips: "26103",
    count: 105,
    state: "MI",
    type: "County",
    locationid: "26103",
    county: "Marquette",
  },
  {
    fips: "01117",
    count: 89,
    state: "AL",
    type: "County",
    locationid: "01117",
    county: "Shelby",
  },
  {
    fips: "18029",
    count: 106,
    state: "IN",
    type: "County",
    locationid: "18029",
    county: "Dearborn",
  },
  {
    fips: "24025",
    count: 101,
    state: "MD",
    type: "County",
    locationid: "24025",
    county: "Harford",
  },
  {
    fips: "39063",
    count: 83,
    state: "OH",
    type: "County",
    locationid: "39063",
    county: "Hancock",
  },
  {
    fips: "44009",
    count: 102,
    state: "RI",
    type: "County",
    locationid: "44009",
    county: "Washington",
  },
  {
    fips: "45085",
    count: 82,
    state: "SC",
    type: "County",
    locationid: "45085",
    county: "Sumter",
  },
  {
    fips: "54107",
    count: 99,
    state: "WV",
    type: "County",
    locationid: "54107",
    county: "Wood",
  },
  {
    fips: "36083",
    count: 96,
    state: "NY",
    type: "County",
    locationid: "36083",
    county: "Rensselaer",
  },
  {
    fips: "42085",
    count: 101,
    state: "PA",
    type: "County",
    locationid: "42085",
    county: "Mercer",
  },
  {
    fips: "24001",
    count: 91,
    state: "MD",
    type: "County",
    locationid: "24001",
    county: "Allegany",
  },
  {
    fips: "48061",
    count: 90,
    state: "TX",
    type: "County",
    locationid: "48061",
    county: "Cameron",
  },
  {
    fips: "48251",
    count: 100,
    state: "TX",
    type: "County",
    locationid: "48251",
    county: "Johnson",
  },
  {
    fips: "36093",
    count: 100,
    state: "NY",
    type: "County",
    locationid: "36093",
    county: "Schenectady",
  },
  {
    fips: "40031",
    count: 101,
    state: "OK",
    type: "County",
    locationid: "40031",
    county: "Comanche",
  },
  {
    fips: "13045",
    count: 98,
    state: "GA",
    type: "County",
    locationid: "13045",
    county: "Carroll",
  },
  {
    fips: "42027",
    count: 92,
    state: "PA",
    type: "County",
    locationid: "42027",
    county: "Centre",
  },
  {
    fips: "48135",
    count: 94,
    state: "TX",
    type: "County",
    locationid: "48135",
    county: "Ector",
  },
  {
    fips: "39103",
    count: 96,
    state: "OH",
    type: "County",
    locationid: "39103",
    county: "Medina",
  },
  {
    fips: "55101",
    count: 100,
    state: "WI",
    type: "County",
    locationid: "55101",
    county: "Racine",
  },
  {
    fips: "13299",
    count: 98,
    state: "GA",
    type: "County",
    locationid: "13299",
    county: "Ware",
  },
  {
    fips: "17111",
    count: 97,
    state: "IL",
    type: "County",
    locationid: "17111",
    county: "Mchenry",
  },
  {
    fips: "17115",
    count: 92,
    state: "IL",
    type: "County",
    locationid: "17115",
    county: "Macon",
  },
  {
    fips: "35013",
    count: 95,
    state: "NM",
    type: "County",
    locationid: "35013",
    county: "Dona Ana",
  },
  {
    fips: "36111",
    count: 97,
    state: "NY",
    type: "County",
    locationid: "36111",
    county: "Ulster",
  },
  {
    fips: "55109",
    count: 99,
    state: "WI",
    type: "County",
    locationid: "55109",
    county: "Saint Croix",
  },
  {
    fips: "09005",
    count: 94,
    state: "CT",
    type: "County",
    locationid: "09005",
    county: "Litchfield",
  },
  {
    fips: "13015",
    count: 86,
    state: "GA",
    type: "County",
    locationid: "13015",
    county: "Bartow",
  },
  {
    fips: "22057",
    count: 94,
    state: "LA",
    type: "County",
    locationid: "22057",
    county: "Lafourche",
  },
  {
    fips: "37127",
    count: 98,
    state: "NC",
    type: "County",
    locationid: "37127",
    county: "Nash",
  },
  {
    fips: "38015",
    count: 86,
    state: "ND",
    type: "County",
    locationid: "38015",
    county: "Burleigh",
  },
  {
    fips: "38101",
    count: 94,
    state: "ND",
    type: "County",
    locationid: "38101",
    county: "Ward",
  },
  {
    fips: "39119",
    count: 98,
    state: "OH",
    type: "County",
    locationid: "39119",
    county: "Muskingum",
  },
  {
    fips: "48039",
    count: 97,
    state: "TX",
    type: "County",
    locationid: "48039",
    county: "Brazoria",
  },
  {
    fips: "51069",
    count: 99,
    state: "VA",
    type: "County",
    locationid: "51069",
    county: "Frederick",
  },
  {
    fips: "54033",
    count: 95,
    state: "WV",
    type: "County",
    locationid: "54033",
    county: "Harrison",
  },
  {
    fips: "04003",
    count: 93,
    state: "AZ",
    type: "County",
    locationid: "04003",
    county: "Cochise",
  },
  {
    fips: "17091",
    count: 97,
    state: "IL",
    type: "County",
    locationid: "17091",
    county: "Kankakee",
  },
  {
    fips: "39089",
    count: 92,
    state: "OH",
    type: "County",
    locationid: "39089",
    county: "Licking",
  },
  {
    fips: "21235",
    count: 95,
    state: "KY",
    type: "County",
    locationid: "21235",
    county: "Whitley",
  },
  {
    fips: "37155",
    count: 95,
    state: "NC",
    type: "County",
    locationid: "37155",
    county: "Robeson",
  },
  {
    fips: "37191",
    count: 90,
    state: "NC",
    type: "County",
    locationid: "37191",
    county: "Wayne",
  },
  {
    fips: "42039",
    count: 90,
    state: "PA",
    type: "County",
    locationid: "42039",
    county: "Crawford",
  },
  {
    fips: "01077",
    count: 87,
    state: "AL",
    type: "County",
    locationid: "01077",
    county: "Lauderdale",
  },
  {
    fips: "06023",
    count: 82,
    state: "CA",
    type: "County",
    locationid: "06023",
    county: "Humboldt",
  },
  {
    fips: "13057",
    count: 87,
    state: "GA",
    type: "County",
    locationid: "13057",
    county: "Cherokee",
  },
  {
    fips: "42033",
    count: 77,
    state: "PA",
    type: "County",
    locationid: "42033",
    county: "Clearfield",
  },
  {
    fips: "51630",
    count: 94,
    state: "VA",
    type: "County",
    locationid: "51630",
    county: "Fredericksburg City",
  },
  {
    fips: "16005",
    count: 92,
    state: "ID",
    type: "County",
    locationid: "16005",
    county: "Bannock",
  },
  {
    fips: "19155",
    count: 87,
    state: "IA",
    type: "County",
    locationid: "19155",
    county: "Pottawattamie",
  },
  {
    fips: "21199",
    count: 88,
    state: "KY",
    type: "County",
    locationid: "21199",
    county: "Pulaski",
  },
  {
    fips: "26025",
    count: 91,
    state: "MI",
    type: "County",
    locationid: "26025",
    county: "Calhoun",
  },
  {
    fips: "47001",
    count: 93,
    state: "TN",
    type: "County",
    locationid: "47001",
    county: "Anderson",
  },
  {
    fips: "47059",
    count: 76,
    state: "TN",
    type: "County",
    locationid: "47059",
    county: "Greene",
  },
  {
    fips: "25015",
    count: 92,
    state: "MA",
    type: "County",
    locationid: "25015",
    county: "Hampshire",
  },
  {
    fips: "28075",
    count: 91,
    state: "MS",
    type: "County",
    locationid: "28075",
    county: "Lauderdale",
  },
  {
    fips: "39041",
    count: 87,
    state: "OH",
    type: "County",
    locationid: "39041",
    county: "Delaware",
  },
  {
    fips: "39101",
    count: 88,
    state: "OH",
    type: "County",
    locationid: "39101",
    county: "Marion",
  },
  {
    fips: "05115",
    count: 77,
    state: "AR",
    type: "County",
    locationid: "05115",
    county: "Pope",
  },
  {
    fips: "24013",
    count: 86,
    state: "MD",
    type: "County",
    locationid: "24013",
    county: "Carroll",
  },
  {
    fips: "24047",
    count: 83,
    state: "MD",
    type: "County",
    locationid: "24047",
    county: "Worcester",
  },
  {
    fips: "28151",
    count: 89,
    state: "MS",
    type: "County",
    locationid: "28151",
    county: "Washington",
  },
  {
    fips: "29167",
    count: 77,
    state: "MO",
    type: "County",
    locationid: "29167",
    county: "Polk",
  },
  {
    fips: "41005",
    count: 92,
    state: "OR",
    type: "County",
    locationid: "41005",
    county: "Clackamas",
  },
  {
    fips: "48005",
    count: 83,
    state: "TX",
    type: "County",
    locationid: "48005",
    county: "Angelina",
  },
  {
    fips: "12119",
    count: 87,
    state: "FL",
    type: "County",
    locationid: "12119",
    county: "Sumter",
  },
  {
    fips: "18067",
    count: 91,
    state: "IN",
    type: "County",
    locationid: "18067",
    county: "Howard",
  },
  {
    fips: "27013",
    count: 71,
    state: "MN",
    type: "County",
    locationid: "27013",
    county: "Blue Earth",
  },
  {
    fips: "29071",
    count: 83,
    state: "MO",
    type: "County",
    locationid: "29071",
    county: "Franklin",
  },
  {
    fips: "34037",
    count: 73,
    state: "NJ",
    type: "County",
    locationid: "34037",
    county: "Sussex",
  },
  {
    fips: "49049",
    count: 83,
    state: "UT",
    type: "County",
    locationid: "49049",
    county: "Utah",
  },
  {
    fips: "51740",
    count: 89,
    state: "VA",
    type: "County",
    locationid: "51740",
    county: "Portsmouth City",
  },
  {
    fips: "36069",
    count: 90,
    state: "NY",
    type: "County",
    locationid: "36069",
    county: "Ontario",
  },
  {
    fips: "55141",
    count: 86,
    state: "WI",
    type: "County",
    locationid: "55141",
    county: "Wood",
  },
  {
    fips: "10001",
    count: 86,
    state: "DE",
    type: "County",
    locationid: "10001",
    county: "Kent",
  },
  {
    fips: "19033",
    count: 85,
    state: "IA",
    type: "County",
    locationid: "19033",
    county: "Cerro Gordo",
  },
  {
    fips: "36109",
    count: 88,
    state: "NY",
    type: "County",
    locationid: "36109",
    county: "Tompkins",
  },
  {
    fips: "56025",
    count: 73,
    state: "WY",
    type: "County",
    locationid: "56025",
    county: "Natrona",
  },
  {
    fips: "36045",
    count: 80,
    state: "NY",
    type: "County",
    locationid: "36045",
    county: "Jefferson",
  },
  {
    fips: "48367",
    count: 79,
    state: "TX",
    type: "County",
    locationid: "48367",
    county: "Parker",
  },
  {
    fips: "27037",
    count: 74,
    state: "MN",
    type: "County",
    locationid: "27037",
    county: "Dakota",
  },
  {
    fips: "28121",
    count: 85,
    state: "MS",
    type: "County",
    locationid: "28121",
    county: "Rankin",
  },
  {
    fips: "32510",
    count: 74,
    state: "NV",
    type: "County",
    locationid: "32510",
    county: "Carson City",
  },
  {
    fips: "41019",
    count: 83,
    state: "OR",
    type: "County",
    locationid: "41019",
    county: "Douglas",
  },
  {
    fips: "42081",
    count: 78,
    state: "PA",
    type: "County",
    locationid: "42081",
    county: "Lycoming",
  },
  {
    fips: "48099",
    count: 86,
    state: "TX",
    type: "County",
    locationid: "48099",
    county: "Coryell",
  },
  {
    fips: "09013",
    count: 85,
    state: "CT",
    type: "County",
    locationid: "09013",
    county: "Tolland",
  },
  {
    fips: "37133",
    count: 79,
    state: "NC",
    type: "County",
    locationid: "37133",
    county: "Onslow",
  },
  {
    fips: "37001",
    count: 82,
    state: "NC",
    type: "County",
    locationid: "37001",
    county: "Alamance",
  },
  {
    fips: "39145",
    count: 77,
    state: "OH",
    type: "County",
    locationid: "39145",
    county: "Scioto",
  },
  {
    fips: "51155",
    count: 82,
    state: "VA",
    type: "County",
    locationid: "51155",
    county: "Pulaski",
  },
  {
    fips: "01127",
    count: 72,
    state: "AL",
    type: "County",
    locationid: "01127",
    county: "Walker",
  },
  {
    fips: "05145",
    count: 83,
    state: "AR",
    type: "County",
    locationid: "05145",
    county: "White",
  },
  {
    fips: "37171",
    count: 82,
    state: "NC",
    type: "County",
    locationid: "37171",
    county: "Surry",
  },
  {
    fips: "26007",
    count: 76,
    state: "MI",
    type: "County",
    locationid: "26007",
    county: "Alpena",
  },
  {
    fips: "47189",
    count: 64,
    state: "TN",
    type: "County",
    locationid: "47189",
    county: "Wilson",
  },
  {
    fips: "51660",
    count: 77,
    state: "VA",
    type: "County",
    locationid: "51660",
    county: "Harrisonburg City",
  },
  {
    fips: "12055",
    count: 74,
    state: "FL",
    type: "County",
    locationid: "12055",
    county: "Highlands",
  },
  {
    fips: "17001",
    count: 77,
    state: "IL",
    type: "County",
    locationid: "17001",
    county: "Adams",
  },
  {
    fips: "21037",
    count: 81,
    state: "KY",
    type: "County",
    locationid: "21037",
    county: "Campbell",
  },
  {
    fips: "39171",
    count: 80,
    state: "OH",
    type: "County",
    locationid: "39171",
    county: "Williams",
  },
  {
    fips: "49053",
    count: 72,
    state: "UT",
    type: "County",
    locationid: "49053",
    county: "Washington",
  },
  {
    fips: "15001",
    count: 80,
    state: "HI",
    type: "County",
    locationid: "15001",
    county: "Hawaii",
  },
  {
    fips: "39053",
    count: 65,
    state: "OH",
    type: "County",
    locationid: "39053",
    county: "Gallia",
  },
  {
    fips: "39081",
    count: 73,
    state: "OH",
    type: "County",
    locationid: "39081",
    county: "Jefferson",
  },
  {
    fips: "36013",
    count: 71,
    state: "NY",
    type: "County",
    locationid: "36013",
    county: "Chautauqua",
  },
  {
    fips: "48091",
    count: 71,
    state: "TX",
    type: "County",
    locationid: "48091",
    county: "Comal",
  },
  {
    fips: "48469",
    count: 76,
    state: "TX",
    type: "County",
    locationid: "48469",
    county: "Victoria",
  },
  {
    fips: "51590",
    count: 78,
    state: "VA",
    type: "County",
    locationid: "51590",
    county: "Danville City",
  },
  {
    fips: "51730",
    count: 72,
    state: "VA",
    type: "County",
    locationid: "51730",
    county: "Petersburg City",
  },
  {
    fips: "12023",
    count: 62,
    state: "FL",
    type: "County",
    locationid: "12023",
    county: "Columbia",
  },
  {
    fips: "12035",
    count: 74,
    state: "FL",
    type: "County",
    locationid: "12035",
    county: "Flagler",
  },
  {
    fips: "37089",
    count: 77,
    state: "NC",
    type: "County",
    locationid: "37089",
    county: "Henderson",
  },
  {
    fips: "37139",
    count: 78,
    state: "NC",
    type: "County",
    locationid: "37139",
    county: "Pasquotank",
  },
  {
    fips: "56021",
    count: 62,
    state: "WY",
    type: "County",
    locationid: "56021",
    county: "Laramie",
  },
  {
    fips: "01031",
    count: 76,
    state: "AL",
    type: "County",
    locationid: "01031",
    county: "Coffee",
  },
  {
    fips: "16019",
    count: 76,
    state: "ID",
    type: "County",
    locationid: "16019",
    county: "Bonneville",
  },
  {
    fips: "27145",
    count: 77,
    state: "MN",
    type: "County",
    locationid: "27145",
    county: "Stearns",
  },
  {
    fips: "37101",
    count: 77,
    state: "NC",
    type: "County",
    locationid: "37101",
    county: "Johnston",
  },
  {
    fips: "51095",
    count: 67,
    state: "VA",
    type: "County",
    locationid: "51095",
    county: "James City",
  },
  {
    fips: "06055",
    count: 66,
    state: "CA",
    type: "County",
    locationid: "06055",
    county: "Napa",
  },
  {
    fips: "17081",
    count: 76,
    state: "IL",
    type: "County",
    locationid: "17081",
    county: "Jefferson",
  },
  {
    fips: "27019",
    count: 66,
    state: "MN",
    type: "County",
    locationid: "27019",
    county: "Carver",
  },
  {
    fips: "54055",
    count: 74,
    state: "WV",
    type: "County",
    locationid: "54055",
    county: "Mercer",
  },
  {
    fips: "16027",
    count: 67,
    state: "ID",
    type: "County",
    locationid: "16027",
    county: "Canyon",
  },
  {
    fips: "18081",
    count: 75,
    state: "IN",
    type: "County",
    locationid: "18081",
    county: "Johnson",
  },
  {
    fips: "23003",
    count: 60,
    state: "ME",
    type: "County",
    locationid: "23003",
    county: "Aroostook",
  },
  {
    fips: "35045",
    count: 64,
    state: "NM",
    type: "County",
    locationid: "35045",
    county: "San Juan",
  },
  {
    fips: "37031",
    count: 68,
    state: "NC",
    type: "County",
    locationid: "37031",
    county: "Carteret",
  },
  {
    fips: "15009",
    count: 74,
    state: "HI",
    type: "County",
    locationid: "15009",
    county: "Maui",
  },
  {
    fips: "35015",
    count: 74,
    state: "NM",
    type: "County",
    locationid: "35015",
    county: "Eddy",
  },
  {
    fips: "37195",
    count: 63,
    state: "NC",
    type: "County",
    locationid: "37195",
    county: "Wilson",
  },
  {
    fips: "27163",
    count: 69,
    state: "MN",
    type: "County",
    locationid: "27163",
    county: "Washington",
  },
  {
    fips: "48231",
    count: 70,
    state: "TX",
    type: "County",
    locationid: "48231",
    county: "Hunt",
  },
  {
    fips: "49057",
    count: 66,
    state: "UT",
    type: "County",
    locationid: "49057",
    county: "Weber",
  },
  {
    fips: "13247",
    count: 68,
    state: "GA",
    type: "County",
    locationid: "13247",
    county: "Rockdale",
  },
  {
    fips: "35049",
    count: 70,
    state: "NM",
    type: "County",
    locationid: "35049",
    county: "Santa Fe",
  },
  {
    fips: "39141",
    count: 68,
    state: "OH",
    type: "County",
    locationid: "39141",
    county: "Ross",
  },
  {
    fips: "05069",
    count: 64,
    state: "AR",
    type: "County",
    locationid: "05069",
    county: "Jefferson",
  },
  {
    fips: "13153",
    count: 69,
    state: "GA",
    type: "County",
    locationid: "13153",
    county: "Houston",
  },
  {
    fips: "18059",
    count: 66,
    state: "IN",
    type: "County",
    locationid: "18059",
    county: "Hancock",
  },
  {
    fips: "18083",
    count: 67,
    state: "IN",
    type: "County",
    locationid: "18083",
    county: "Knox",
  },
  {
    fips: "53015",
    count: 63,
    state: "WA",
    type: "County",
    locationid: "53015",
    county: "Cowlitz",
  },
  {
    fips: "18037",
    count: 57,
    state: "IN",
    type: "County",
    locationid: "18037",
    county: "Dubois",
  },
  {
    fips: "21125",
    count: 69,
    state: "KY",
    type: "County",
    locationid: "21125",
    county: "Laurel",
  },
  {
    fips: "28071",
    count: 70,
    state: "MS",
    type: "County",
    locationid: "28071",
    county: "Lafayette",
  },
  {
    fips: "28089",
    count: 70,
    state: "MS",
    type: "County",
    locationid: "28089",
    county: "Madison",
  },
  {
    fips: "31079",
    count: 64,
    state: "NE",
    type: "County",
    locationid: "31079",
    county: "Hall",
  },
  {
    fips: "31111",
    count: 63,
    state: "NE",
    type: "County",
    locationid: "31111",
    county: "Lincoln",
  },
  {
    fips: "34019",
    count: 58,
    state: "NJ",
    type: "County",
    locationid: "34019",
    county: "Hunterdon",
  },
  {
    fips: "40119",
    count: 64,
    state: "OK",
    type: "County",
    locationid: "40119",
    county: "Payne",
  },
  {
    fips: "42089",
    count: 63,
    state: "PA",
    type: "County",
    locationid: "42089",
    county: "Monroe",
  },
  {
    fips: "45075",
    count: 66,
    state: "SC",
    type: "County",
    locationid: "45075",
    county: "Orangeburg",
  },
  {
    fips: "53007",
    count: 65,
    state: "WA",
    type: "County",
    locationid: "53007",
    county: "Chelan",
  },
  {
    fips: "21059",
    count: 67,
    state: "KY",
    type: "County",
    locationid: "21059",
    county: "Daviess",
  },
  {
    fips: "28113",
    count: 52,
    state: "MS",
    type: "County",
    locationid: "28113",
    county: "Pike",
  },
  {
    fips: "51600",
    count: 69,
    state: "VA",
    type: "County",
    locationid: "51600",
    county: "Fairfax City",
  },
  {
    fips: "16083",
    count: 62,
    state: "ID",
    type: "County",
    locationid: "16083",
    county: "Twin Falls",
  },
  {
    fips: "21161",
    count: 67,
    state: "KY",
    type: "County",
    locationid: "21161",
    county: "Mason",
  },
  {
    fips: "48451",
    count: 64,
    state: "TX",
    type: "County",
    locationid: "48451",
    county: "Tom Green",
  },
  {
    fips: "06047",
    count: 61,
    state: "CA",
    type: "County",
    locationid: "06047",
    county: "Merced",
  },
  {
    fips: "13009",
    count: 61,
    state: "GA",
    type: "County",
    locationid: "13009",
    county: "Baldwin",
  },
  {
    fips: "21205",
    count: 59,
    state: "KY",
    type: "County",
    locationid: "21205",
    county: "Rowan",
  },
  {
    fips: "26115",
    count: 62,
    state: "MI",
    type: "County",
    locationid: "26115",
    county: "Monroe",
  },
  {
    fips: "28003",
    count: 60,
    state: "MS",
    type: "County",
    locationid: "28003",
    county: "Alcorn",
  },
  {
    fips: "45047",
    count: 65,
    state: "SC",
    type: "County",
    locationid: "45047",
    county: "Greenwood",
  },
  {
    fips: "01001",
    count: 66,
    state: "AL",
    type: "County",
    locationid: "01001",
    county: "Autauga",
  },
  {
    fips: "06087",
    count: 62,
    state: "CA",
    type: "County",
    locationid: "06087",
    county: "Santa Cruz",
  },
  {
    fips: "17199",
    count: 62,
    state: "IL",
    type: "County",
    locationid: "17199",
    county: "Williamson",
  },
  {
    fips: "27171",
    count: 56,
    state: "MN",
    type: "County",
    locationid: "27171",
    county: "Wright",
  },
  {
    fips: "34041",
    count: 65,
    state: "NJ",
    type: "County",
    locationid: "34041",
    county: "Warren",
  },
  {
    fips: "48329",
    count: 52,
    state: "TX",
    type: "County",
    locationid: "48329",
    county: "Midland",
  },
  {
    fips: "37091",
    count: 65,
    state: "NC",
    type: "County",
    locationid: "37091",
    county: "Hertford",
  },
  {
    fips: "08067",
    count: 63,
    state: "CO",
    type: "County",
    locationid: "08067",
    county: "La Plata",
  },
  {
    fips: "18103",
    count: 57,
    state: "IN",
    type: "County",
    locationid: "18103",
    county: "Miami",
  },
  {
    fips: "20161",
    count: 62,
    state: "KS",
    type: "County",
    locationid: "20161",
    county: "Riley",
  },
  {
    fips: "24017",
    count: 63,
    state: "MD",
    type: "County",
    locationid: "24017",
    county: "Charles",
  },
  {
    fips: "29165",
    count: 64,
    state: "MO",
    type: "County",
    locationid: "29165",
    county: "Platte",
  },
  {
    fips: "30029",
    count: 60,
    state: "MT",
    type: "County",
    locationid: "30029",
    county: "Flathead",
  },
  {
    fips: "31019",
    count: 55,
    state: "NE",
    type: "County",
    locationid: "31019",
    county: "Buffalo",
  },
  {
    fips: "41033",
    count: 64,
    state: "OR",
    type: "County",
    locationid: "41033",
    county: "Josephine",
  },
  {
    fips: "47089",
    count: 57,
    state: "TN",
    type: "County",
    locationid: "47089",
    county: "Jefferson",
  },
  {
    fips: "09015",
    count: 58,
    state: "CT",
    type: "County",
    locationid: "09015",
    county: "Windham",
  },
  {
    fips: "31119",
    count: 50,
    state: "NE",
    type: "County",
    locationid: "31119",
    county: "Madison",
  },
  {
    fips: "35035",
    count: 63,
    state: "NM",
    type: "County",
    locationid: "35035",
    county: "Otero",
  },
  {
    fips: "36101",
    count: 53,
    state: "NY",
    type: "County",
    locationid: "36101",
    county: "Steuben",
  },
  {
    fips: "47003",
    count: 52,
    state: "TN",
    type: "County",
    locationid: "47003",
    county: "Bedford",
  },
  {
    fips: "21047",
    count: 60,
    state: "KY",
    type: "County",
    locationid: "21047",
    county: "Christian",
  },
  {
    fips: "42005",
    count: 62,
    state: "PA",
    type: "County",
    locationid: "42005",
    county: "Armstrong",
  },
  {
    fips: "42107",
    count: 60,
    state: "PA",
    type: "County",
    locationid: "42107",
    county: "Schuylkill",
  },
  {
    fips: "48397",
    count: 61,
    state: "TX",
    type: "County",
    locationid: "48397",
    county: "Rockwall",
  },
  {
    fips: "26061",
    count: 60,
    state: "MI",
    type: "County",
    locationid: "26061",
    county: "Houghton",
  },
  {
    fips: "39055",
    count: 54,
    state: "OH",
    type: "County",
    locationid: "39055",
    county: "Geauga",
  },
  {
    fips: "41011",
    count: 54,
    state: "OR",
    type: "County",
    locationid: "41011",
    county: "Coos",
  },
  {
    fips: "41035",
    count: 55,
    state: "OR",
    type: "County",
    locationid: "41035",
    county: "Klamath",
  },
  {
    fips: "51179",
    count: 49,
    state: "VA",
    type: "County",
    locationid: "51179",
    county: "Stafford",
  },
  {
    fips: "01033",
    count: 54,
    state: "AL",
    type: "County",
    locationid: "01033",
    county: "Colbert",
  },
  {
    fips: "13291",
    count: 57,
    state: "GA",
    type: "County",
    locationid: "13291",
    county: "Union",
  },
  {
    fips: "26073",
    count: 56,
    state: "MI",
    type: "County",
    locationid: "26073",
    county: "Isabella",
  },
  {
    fips: "42073",
    count: 58,
    state: "PA",
    type: "County",
    locationid: "42073",
    county: "Lawrence",
  },
  {
    fips: "13097",
    count: 56,
    state: "GA",
    type: "County",
    locationid: "13097",
    county: "Douglas",
  },
  {
    fips: "13313",
    count: 53,
    state: "GA",
    type: "County",
    locationid: "13313",
    county: "Whitfield",
  },
  {
    fips: "17029",
    count: 58,
    state: "IL",
    type: "County",
    locationid: "17029",
    county: "Coles",
  },
  {
    fips: "36075",
    count: 59,
    state: "NY",
    type: "County",
    locationid: "36075",
    county: "Oswego",
  },
  {
    fips: "39173",
    count: 58,
    state: "OH",
    type: "County",
    locationid: "39173",
    county: "Wood",
  },
  {
    fips: "47043",
    count: 52,
    state: "TN",
    type: "County",
    locationid: "47043",
    county: "Dickson",
  },
  {
    fips: "05009",
    count: 58,
    state: "AR",
    type: "County",
    locationid: "05009",
    county: "Boone",
  },
  {
    fips: "13223",
    count: 55,
    state: "GA",
    type: "County",
    locationid: "13223",
    county: "Paulding",
  },
  {
    fips: "18053",
    count: 45,
    state: "IN",
    type: "County",
    locationid: "18053",
    county: "Grant",
  },
  {
    fips: "23017",
    count: 51,
    state: "ME",
    type: "County",
    locationid: "23017",
    county: "Oxford",
  },
  {
    fips: "37179",
    count: 58,
    state: "NC",
    type: "County",
    locationid: "37179",
    county: "Union",
  },
  {
    fips: "48213",
    count: 50,
    state: "TX",
    type: "County",
    locationid: "48213",
    county: "Henderson",
  },
  {
    fips: "01123",
    count: 50,
    state: "AL",
    type: "County",
    locationid: "01123",
    county: "Tallapoosa",
  },
  {
    fips: "13277",
    count: 57,
    state: "GA",
    type: "County",
    locationid: "13277",
    county: "Tift",
  },
  {
    fips: "36009",
    count: 55,
    state: "NY",
    type: "County",
    locationid: "36009",
    county: "Cattaraugus",
  },
  {
    fips: "37159",
    count: 56,
    state: "NC",
    type: "County",
    locationid: "37159",
    county: "Rowan",
  },
  {
    fips: "46083",
    count: 53,
    state: "SD",
    type: "County",
    locationid: "46083",
    county: "Lincoln",
  },
  {
    fips: "51047",
    count: 40,
    state: "VA",
    type: "County",
    locationid: "51047",
    county: "Culpeper",
  },
  {
    fips: "19061",
    count: 54,
    state: "IA",
    type: "County",
    locationid: "19061",
    county: "Dubuque",
  },
  {
    fips: "22105",
    count: 56,
    state: "LA",
    type: "County",
    locationid: "22105",
    county: "Tangipahoa",
  },
  {
    fips: "26133",
    count: 56,
    state: "MI",
    type: "County",
    locationid: "26133",
    county: "Osceola",
  },
  {
    fips: "40019",
    count: 55,
    state: "OK",
    type: "County",
    locationid: "40019",
    county: "Carter",
  },
  {
    fips: "47099",
    count: 49,
    state: "TN",
    type: "County",
    locationid: "47099",
    county: "Lawrence",
  },
  {
    fips: "48265",
    count: 52,
    state: "TX",
    type: "County",
    locationid: "48265",
    county: "Kerr",
  },
  {
    fips: "51015",
    count: 55,
    state: "VA",
    type: "County",
    locationid: "51015",
    county: "Augusta",
  },
  {
    fips: "55105",
    count: 55,
    state: "WI",
    type: "County",
    locationid: "55105",
    county: "Rock",
  },
  {
    fips: "05063",
    count: 55,
    state: "AR",
    type: "County",
    locationid: "05063",
    county: "Independence",
  },
  {
    fips: "12113",
    count: 55,
    state: "FL",
    type: "County",
    locationid: "12113",
    county: "Santa Rosa",
  },
  {
    fips: "26087",
    count: 53,
    state: "MI",
    type: "County",
    locationid: "26087",
    county: "Lapeer",
  },
  {
    fips: "29099",
    count: 51,
    state: "MO",
    type: "County",
    locationid: "29099",
    county: "Jefferson",
  },
  {
    fips: "36015",
    count: 55,
    state: "NY",
    type: "County",
    locationid: "36015",
    county: "Chemung",
  },
  {
    fips: "37023",
    count: 55,
    state: "NC",
    type: "County",
    locationid: "37023",
    county: "Burke",
  },
  {
    fips: "41071",
    count: 47,
    state: "OR",
    type: "County",
    locationid: "41071",
    county: "Yamhill",
  },
  {
    fips: "48497",
    count: 55,
    state: "TX",
    type: "County",
    locationid: "48497",
    county: "Wise",
  },
  {
    fips: "54083",
    count: 50,
    state: "WV",
    type: "County",
    locationid: "54083",
    county: "Randolph",
  },
  {
    fips: "55059",
    count: 55,
    state: "WI",
    type: "County",
    locationid: "55059",
    county: "Kenosha",
  },
  {
    fips: "12131",
    count: 50,
    state: "FL",
    type: "County",
    locationid: "12131",
    county: "Walton",
  },
  {
    fips: "24037",
    count: 54,
    state: "MD",
    type: "County",
    locationid: "24037",
    county: "Saint Marys",
  },
  {
    fips: "39157",
    count: 54,
    state: "OH",
    type: "County",
    locationid: "39157",
    county: "Tuscarawas",
  },
  {
    fips: "13185",
    count: 52,
    state: "GA",
    type: "County",
    locationid: "13185",
    county: "Lowndes",
  },
  {
    fips: "20057",
    count: 49,
    state: "KS",
    type: "County",
    locationid: "20057",
    county: "Ford",
  },
  {
    fips: "42111",
    count: 53,
    state: "PA",
    type: "County",
    locationid: "42111",
    county: "Somerset",
  },
  {
    fips: "17183",
    count: 52,
    state: "IL",
    type: "County",
    locationid: "17183",
    county: "Vermilion",
  },
  {
    fips: "21021",
    count: 52,
    state: "KY",
    type: "County",
    locationid: "21021",
    county: "Boyle",
  },
  {
    fips: "24009",
    count: 52,
    state: "MD",
    type: "County",
    locationid: "24009",
    county: "Calvert",
  },
  {
    fips: "26117",
    count: 49,
    state: "MI",
    type: "County",
    locationid: "26117",
    county: "Montcalm",
  },
  {
    fips: "30063",
    count: 49,
    state: "MT",
    type: "County",
    locationid: "30063",
    county: "Missoula",
  },
  {
    fips: "39007",
    count: 35,
    state: "OH",
    type: "County",
    locationid: "39007",
    county: "Ashtabula",
  },
  {
    fips: "48471",
    count: 48,
    state: "TX",
    type: "County",
    locationid: "48471",
    county: "Walker",
  },
  {
    fips: "49011",
    count: 51,
    state: "UT",
    type: "County",
    locationid: "49011",
    county: "Davis",
  },
  {
    fips: "51510",
    count: 45,
    state: "VA",
    type: "County",
    locationid: "51510",
    county: "Alexandria City",
  },
  {
    fips: "13285",
    count: 38,
    state: "GA",
    type: "County",
    locationid: "13285",
    county: "Troup",
  },
  {
    fips: "28149",
    count: 50,
    state: "MS",
    type: "County",
    locationid: "28149",
    county: "Warren",
  },
  {
    fips: "31157",
    count: 46,
    state: "NE",
    type: "County",
    locationid: "31157",
    county: "Scotts Bluff",
  },
  {
    fips: "36033",
    count: 47,
    state: "NY",
    type: "County",
    locationid: "36033",
    county: "Franklin",
  },
  {
    fips: "45071",
    count: 51,
    state: "SC",
    type: "County",
    locationid: "45071",
    county: "Newberry",
  },
  {
    fips: "51720",
    count: 51,
    state: "VA",
    type: "County",
    locationid: "51720",
    county: "Norton City",
  },
  {
    fips: "13111",
    count: 45,
    state: "GA",
    type: "County",
    locationid: "13111",
    county: "Fannin",
  },
  {
    fips: "19027",
    count: 49,
    state: "IA",
    type: "County",
    locationid: "19027",
    county: "Carroll",
  },
  {
    fips: "37057",
    count: 50,
    state: "NC",
    type: "County",
    locationid: "37057",
    county: "Davidson",
  },
  {
    fips: "37087",
    count: 49,
    state: "NC",
    type: "County",
    locationid: "37087",
    county: "Haywood",
  },
  {
    fips: "39029",
    count: 49,
    state: "OH",
    type: "County",
    locationid: "39029",
    county: "Columbiana",
  },
  {
    fips: "48001",
    count: 45,
    state: "TX",
    type: "County",
    locationid: "48001",
    county: "Anderson",
  },
  {
    fips: "50003",
    count: 49,
    state: "VT",
    type: "County",
    locationid: "50003",
    county: "Bennington",
  },
  {
    fips: "54045",
    count: 46,
    state: "WV",
    type: "County",
    locationid: "54045",
    county: "Logan",
  },
  {
    fips: "18177",
    count: 49,
    state: "IN",
    type: "County",
    locationid: "18177",
    county: "Wayne",
  },
  {
    fips: "27105",
    count: 48,
    state: "MN",
    type: "County",
    locationid: "27105",
    county: "Nobles",
  },
  {
    fips: "48347",
    count: 46,
    state: "TX",
    type: "County",
    locationid: "48347",
    county: "Nacogdoches",
  },
  {
    fips: "51185",
    count: 46,
    state: "VA",
    type: "County",
    locationid: "51185",
    county: "Tazewell",
  },
  {
    fips: "55085",
    count: 36,
    state: "WI",
    type: "County",
    locationid: "55085",
    county: "Oneida",
  },
  {
    fips: "13073",
    count: 48,
    state: "GA",
    type: "County",
    locationid: "13073",
    county: "Columbia",
  },
  {
    fips: "18109",
    count: 43,
    state: "IN",
    type: "County",
    locationid: "18109",
    county: "Morgan",
  },
  {
    fips: "20079",
    count: 43,
    state: "KS",
    type: "County",
    locationid: "20079",
    county: "Harvey",
  },
  {
    fips: "26057",
    count: 44,
    state: "MI",
    type: "County",
    locationid: "26057",
    county: "Gratiot",
  },
  {
    fips: "28087",
    count: 47,
    state: "MS",
    type: "County",
    locationid: "28087",
    county: "Lowndes",
  },
  {
    fips: "30049",
    count: 43,
    state: "MT",
    type: "County",
    locationid: "30049",
    county: "Lewis And Clark",
  },
  {
    fips: "33005",
    count: 48,
    state: "NH",
    type: "County",
    locationid: "33005",
    county: "Cheshire",
  },
  {
    fips: "36079",
    count: 43,
    state: "NY",
    type: "County",
    locationid: "36079",
    county: "Putnam",
  },
  {
    fips: "37157",
    count: 48,
    state: "NC",
    type: "County",
    locationid: "37157",
    county: "Rockingham",
  },
  {
    fips: "50005",
    count: 45,
    state: "VT",
    type: "County",
    locationid: "50005",
    county: "Caledonia",
  },
  {
    fips: "01039",
    count: 44,
    state: "AL",
    type: "County",
    locationid: "01039",
    county: "Covington",
  },
  {
    fips: "12107",
    count: 44,
    state: "FL",
    type: "County",
    locationid: "12107",
    county: "Putnam",
  },
  {
    fips: "29029",
    count: 43,
    state: "MO",
    type: "County",
    locationid: "29029",
    county: "Camden",
  },
  {
    fips: "39013",
    count: 47,
    state: "OH",
    type: "County",
    locationid: "39013",
    county: "Belmont",
  },
  {
    fips: "40101",
    count: 43,
    state: "OK",
    type: "County",
    locationid: "40101",
    county: "Muskogee",
  },
  {
    fips: "13175",
    count: 41,
    state: "GA",
    type: "County",
    locationid: "13175",
    county: "Laurens",
  },
  {
    fips: "31153",
    count: 40,
    state: "NE",
    type: "County",
    locationid: "31153",
    county: "Sarpy",
  },
  {
    fips: "37045",
    count: 46,
    state: "NC",
    type: "County",
    locationid: "37045",
    county: "Cleveland",
  },
  {
    fips: "42001",
    count: 41,
    state: "PA",
    type: "County",
    locationid: "42001",
    county: "Adams",
  },
  {
    fips: "46013",
    count: 41,
    state: "SD",
    type: "County",
    locationid: "46013",
    county: "Brown",
  },
  {
    fips: "55117",
    count: 44,
    state: "WI",
    type: "County",
    locationid: "55117",
    county: "Sheboygan",
  },
  {
    fips: "01081",
    count: 44,
    state: "AL",
    type: "County",
    locationid: "01081",
    county: "Lee",
  },
  {
    fips: "26005",
    count: 45,
    state: "MI",
    type: "County",
    locationid: "26005",
    county: "Allegan",
  },
  {
    fips: "27041",
    count: 45,
    state: "MN",
    type: "County",
    locationid: "27041",
    county: "Douglas",
  },
  {
    fips: "35043",
    count: 43,
    state: "NM",
    type: "County",
    locationid: "35043",
    county: "Sandoval",
  },
  {
    fips: "39009",
    count: 45,
    state: "OH",
    type: "County",
    locationid: "39009",
    county: "Athens",
  },
  {
    fips: "40123",
    count: 43,
    state: "OK",
    type: "County",
    locationid: "40123",
    county: "Pontotoc",
  },
  {
    fips: "55131",
    count: 45,
    state: "WI",
    type: "County",
    locationid: "55131",
    county: "Washington",
  },
  {
    fips: "01043",
    count: 41,
    state: "AL",
    type: "County",
    locationid: "01043",
    county: "Cullman",
  },
  {
    fips: "01095",
    count: 44,
    state: "AL",
    type: "County",
    locationid: "01095",
    county: "Marshall",
  },
  {
    fips: "17037",
    count: 44,
    state: "IL",
    type: "County",
    locationid: "17037",
    county: "Dekalb",
  },
  {
    fips: "20155",
    count: 40,
    state: "KS",
    type: "County",
    locationid: "20155",
    county: "Reno",
  },
  {
    fips: "21073",
    count: 36,
    state: "KY",
    type: "County",
    locationid: "21073",
    county: "Franklin",
  },
  {
    fips: "22097",
    count: 44,
    state: "LA",
    type: "County",
    locationid: "22097",
    county: "Saint Landry",
  },
  {
    fips: "23023",
    count: 37,
    state: "ME",
    type: "County",
    locationid: "23023",
    county: "Sagadahoc",
  },
  {
    fips: "16069",
    count: 42,
    state: "ID",
    type: "County",
    locationid: "16069",
    county: "Nez Perce",
  },
  {
    fips: "18085",
    count: 42,
    state: "IN",
    type: "County",
    locationid: "18085",
    county: "Kosciusko",
  },
  {
    fips: "18093",
    count: 39,
    state: "IN",
    type: "County",
    locationid: "18093",
    county: "Lawrence",
  },
  {
    fips: "20045",
    count: 43,
    state: "KS",
    type: "County",
    locationid: "20045",
    county: "Douglas",
  },
  {
    fips: "29023",
    count: 43,
    state: "MO",
    type: "County",
    locationid: "29023",
    county: "Butler",
  },
  {
    fips: "04005",
    count: 39,
    state: "AZ",
    type: "County",
    locationid: "04005",
    county: "Coconino",
  },
  {
    fips: "17049",
    count: 38,
    state: "IL",
    type: "County",
    locationid: "17049",
    county: "Effingham",
  },
  {
    fips: "35005",
    count: 40,
    state: "NM",
    type: "County",
    locationid: "35005",
    county: "Chaves",
  },
  {
    fips: "36021",
    count: 35,
    state: "NY",
    type: "County",
    locationid: "36021",
    county: "Columbia",
  },
  {
    fips: "54049",
    count: 42,
    state: "WV",
    type: "County",
    locationid: "54049",
    county: "Marion",
  },
  {
    fips: "12087",
    count: 35,
    state: "FL",
    type: "County",
    locationid: "12087",
    county: "Monroe",
  },
  {
    fips: "13227",
    count: 38,
    state: "GA",
    type: "County",
    locationid: "13227",
    county: "Pickens",
  },
  {
    fips: "22087",
    count: 41,
    state: "LA",
    type: "County",
    locationid: "22087",
    county: "Saint Bernard",
  },
  {
    fips: "26155",
    count: 33,
    state: "MI",
    type: "County",
    locationid: "26155",
    county: "Shiawassee",
  },
  {
    fips: "29213",
    count: 40,
    state: "MO",
    type: "County",
    locationid: "29213",
    county: "Taney",
  },
  {
    fips: "36053",
    count: 40,
    state: "NY",
    type: "County",
    locationid: "36053",
    county: "Madison",
  },
  {
    fips: "39133",
    count: 38,
    state: "OH",
    type: "County",
    locationid: "39133",
    county: "Portage",
  },
  {
    fips: "45057",
    count: 41,
    state: "SC",
    type: "County",
    locationid: "45057",
    county: "Lancaster",
  },
  {
    fips: "48221",
    count: 37,
    state: "TX",
    type: "County",
    locationid: "48221",
    county: "Hood",
  },
  {
    fips: "54003",
    count: 41,
    state: "WV",
    type: "County",
    locationid: "54003",
    county: "Berkeley",
  },
  {
    fips: "13133",
    count: 40,
    state: "GA",
    type: "County",
    locationid: "13133",
    county: "Greene",
  },
  {
    fips: "22015",
    count: 40,
    state: "LA",
    type: "County",
    locationid: "22015",
    county: "Bossier",
  },
  {
    fips: "26137",
    count: 40,
    state: "MI",
    type: "County",
    locationid: "26137",
    county: "Otsego",
  },
  {
    fips: "39077",
    count: 30,
    state: "OH",
    type: "County",
    locationid: "39077",
    county: "Huron",
  },
  {
    fips: "42127",
    count: 35,
    state: "PA",
    type: "County",
    locationid: "42127",
    county: "Wayne",
  },
  {
    fips: "44005",
    count: 40,
    state: "RI",
    type: "County",
    locationid: "44005",
    county: "Newport",
  },
  {
    fips: "47031",
    count: 38,
    state: "TN",
    type: "County",
    locationid: "47031",
    county: "Coffee",
  },
  {
    fips: "08045",
    count: 39,
    state: "CO",
    type: "County",
    locationid: "08045",
    county: "Garfield",
  },
  {
    fips: "21029",
    count: 38,
    state: "KY",
    type: "County",
    locationid: "21029",
    county: "Bullitt",
  },
  {
    fips: "21035",
    count: 35,
    state: "KY",
    type: "County",
    locationid: "21035",
    county: "Calloway",
  },
  {
    fips: "31053",
    count: 33,
    state: "NE",
    type: "County",
    locationid: "31053",
    county: "Dodge",
  },
  {
    fips: "37019",
    count: 38,
    state: "NC",
    type: "County",
    locationid: "37019",
    county: "Brunswick",
  },
  {
    fips: "41043",
    count: 33,
    state: "OR",
    type: "County",
    locationid: "41043",
    county: "Linn",
  },
  {
    fips: "51191",
    count: 37,
    state: "VA",
    type: "County",
    locationid: "51191",
    county: "Washington",
  },
  {
    fips: "20009",
    count: 31,
    state: "KS",
    type: "County",
    locationid: "20009",
    county: "Barton",
  },
  {
    fips: "23013",
    count: 34,
    state: "ME",
    type: "County",
    locationid: "23013",
    county: "Knox",
  },
  {
    fips: "37161",
    count: 37,
    state: "NC",
    type: "County",
    locationid: "37161",
    county: "Rutherford",
  },
  {
    fips: "39083",
    count: 32,
    state: "OH",
    type: "County",
    locationid: "39083",
    county: "Knox",
  },
  {
    fips: "45043",
    count: 36,
    state: "SC",
    type: "County",
    locationid: "45043",
    county: "Georgetown",
  },
  {
    fips: "47035",
    count: 35,
    state: "TN",
    type: "County",
    locationid: "47035",
    county: "Cumberland",
  },
  {
    fips: "50021",
    count: 37,
    state: "VT",
    type: "County",
    locationid: "50021",
    county: "Rutland",
  },
  {
    fips: "05005",
    count: 36,
    state: "AR",
    type: "County",
    locationid: "05005",
    county: "Baxter",
  },
  {
    fips: "06115",
    count: 31,
    state: "CA",
    type: "County",
    locationid: "06115",
    county: "Yuba",
  },
  {
    fips: "22045",
    count: 35,
    state: "LA",
    type: "County",
    locationid: "22045",
    county: "Iberia",
  },
  {
    fips: "37151",
    count: 36,
    state: "NC",
    type: "County",
    locationid: "37151",
    county: "Randolph",
  },
  {
    fips: "40047",
    count: 36,
    state: "OK",
    type: "County",
    locationid: "40047",
    county: "Garfield",
  },
  {
    fips: "48139",
    count: 35,
    state: "TX",
    type: "County",
    locationid: "48139",
    county: "Ellis",
  },
  {
    fips: "06057",
    count: 35,
    state: "CA",
    type: "County",
    locationid: "06057",
    county: "Nevada",
  },
  {
    fips: "18051",
    count: 35,
    state: "IN",
    type: "County",
    locationid: "18051",
    county: "Gibson",
  },
  {
    fips: "19041",
    count: 33,
    state: "IA",
    type: "County",
    locationid: "19041",
    county: "Clay",
  },
  {
    fips: "29187",
    count: 35,
    state: "MO",
    type: "County",
    locationid: "29187",
    county: "Saint Francois",
  },
  {
    fips: "40137",
    count: 35,
    state: "OK",
    type: "County",
    locationid: "40137",
    county: "Stephens",
  },
  {
    fips: "40147",
    count: 35,
    state: "OK",
    type: "County",
    locationid: "40147",
    county: "Washington",
  },
  {
    fips: "41059",
    count: 35,
    state: "OR",
    type: "County",
    locationid: "41059",
    county: "Umatilla",
  },
  {
    fips: "45073",
    count: 33,
    state: "SC",
    type: "County",
    locationid: "45073",
    county: "Oconee",
  },
  {
    fips: "51161",
    count: 35,
    state: "VA",
    type: "County",
    locationid: "51161",
    county: "Roanoke",
  },
  {
    fips: "54059",
    count: 35,
    state: "WV",
    type: "County",
    locationid: "54059",
    county: "Mingo",
  },
  {
    fips: "02122",
    count: 33,
    state: "AK",
    type: "County",
    locationid: "02122",
    county: "Kenai Peninsula",
  },
  {
    fips: "06025",
    count: 32,
    state: "CA",
    type: "County",
    locationid: "06025",
    county: "Imperial",
  },
  {
    fips: "17179",
    count: 34,
    state: "IL",
    type: "County",
    locationid: "17179",
    county: "Tazewell",
  },
  {
    fips: "27007",
    count: 31,
    state: "MN",
    type: "County",
    locationid: "27007",
    county: "Beltrami",
  },
  {
    fips: "28027",
    count: 29,
    state: "MS",
    type: "County",
    locationid: "28027",
    county: "Coahoma",
  },
  {
    fips: "28067",
    count: 29,
    state: "MS",
    type: "County",
    locationid: "28067",
    county: "Jones",
  },
  {
    fips: "28083",
    count: 30,
    state: "MS",
    type: "County",
    locationid: "28083",
    county: "Leflore",
  },
  {
    fips: "42063",
    count: 33,
    state: "PA",
    type: "County",
    locationid: "42063",
    county: "Indiana",
  },
  {
    fips: "42119",
    count: 34,
    state: "PA",
    type: "County",
    locationid: "42119",
    county: "Union",
  },
  {
    fips: "46135",
    count: 32,
    state: "SD",
    type: "County",
    locationid: "46135",
    county: "Yankton",
  },
  {
    fips: "12093",
    count: 30,
    state: "FL",
    type: "County",
    locationid: "12093",
    county: "Okeechobee",
  },
  {
    fips: "21151",
    count: 33,
    state: "KY",
    type: "County",
    locationid: "21151",
    county: "Madison",
  },
  {
    fips: "47079",
    count: 33,
    state: "TN",
    type: "County",
    locationid: "47079",
    county: "Henry",
  },
  {
    fips: "48449",
    count: 30,
    state: "TX",
    type: "County",
    locationid: "48449",
    county: "Titus",
  },
  {
    fips: "48479",
    count: 29,
    state: "TX",
    type: "County",
    locationid: "48479",
    county: "Webb",
  },
  {
    fips: "53009",
    count: 32,
    state: "WA",
    type: "County",
    locationid: "53009",
    county: "Clallam",
  },
  {
    fips: "13085",
    count: 32,
    state: "GA",
    type: "County",
    locationid: "13085",
    county: "Dawson",
  },
  {
    fips: "17095",
    count: 31,
    state: "IL",
    type: "County",
    locationid: "17095",
    county: "Knox",
  },
  {
    fips: "19187",
    count: 25,
    state: "IA",
    type: "County",
    locationid: "19187",
    county: "Webster",
  },
  {
    fips: "23027",
    count: 30,
    state: "ME",
    type: "County",
    locationid: "23027",
    county: "Waldo",
  },
  {
    fips: "26031",
    count: 32,
    state: "MI",
    type: "County",
    locationid: "26031",
    county: "Cheboygan",
  },
  {
    fips: "26043",
    count: 30,
    state: "MI",
    type: "County",
    locationid: "26043",
    county: "Dickinson",
  },
  {
    fips: "26105",
    count: 28,
    state: "MI",
    type: "County",
    locationid: "26105",
    county: "Mason",
  },
  {
    fips: "42103",
    count: 32,
    state: "PA",
    type: "County",
    locationid: "42103",
    county: "Pike",
  },
  {
    fips: "48049",
    count: 29,
    state: "TX",
    type: "County",
    locationid: "48049",
    county: "Brown",
  },
  {
    fips: "48171",
    count: 30,
    state: "TX",
    type: "County",
    locationid: "48171",
    county: "Gillespie",
  },
  {
    fips: "54051",
    count: 32,
    state: "WV",
    type: "County",
    locationid: "54051",
    county: "Marshall",
  },
  {
    fips: "18071",
    count: 31,
    state: "IN",
    type: "County",
    locationid: "18071",
    county: "Jackson",
  },
  {
    fips: "21005",
    count: 31,
    state: "KY",
    type: "County",
    locationid: "21005",
    county: "Anderson",
  },
  {
    fips: "22061",
    count: 29,
    state: "LA",
    type: "County",
    locationid: "22061",
    county: "Lincoln",
  },
  {
    fips: "27047",
    count: 27,
    state: "MN",
    type: "County",
    locationid: "27047",
    county: "Freeborn",
  },
  {
    fips: "36117",
    count: 31,
    state: "NY",
    type: "County",
    locationid: "36117",
    county: "Wayne",
  },
  {
    fips: "42121",
    count: 31,
    state: "PA",
    type: "County",
    locationid: "42121",
    county: "Venango",
  },
  {
    fips: "47155",
    count: 30,
    state: "TN",
    type: "County",
    locationid: "47155",
    county: "Sevier",
  },
  {
    fips: "06045",
    count: 30,
    state: "CA",
    type: "County",
    locationid: "06045",
    county: "Mendocino",
  },
  {
    fips: "08014",
    count: 30,
    state: "CO",
    type: "County",
    locationid: "08014",
    county: "Broomfield",
  },
  {
    fips: "18145",
    count: 29,
    state: "IN",
    type: "County",
    locationid: "18145",
    county: "Shelby",
  },
  {
    fips: "26045",
    count: 30,
    state: "MI",
    type: "County",
    locationid: "26045",
    county: "Eaton",
  },
  {
    fips: "42097",
    count: 30,
    state: "PA",
    type: "County",
    locationid: "42097",
    county: "Northumberland",
  },
  {
    fips: "47133",
    count: 30,
    state: "TN",
    type: "County",
    locationid: "47133",
    county: "Overton",
  },
  {
    fips: "54095",
    count: 30,
    state: "WV",
    type: "County",
    locationid: "54095",
    county: "Tyler",
  },
  {
    fips: "55111",
    count: 30,
    state: "WI",
    type: "County",
    locationid: "55111",
    county: "Sauk",
  },
  {
    fips: "13013",
    count: 29,
    state: "GA",
    type: "County",
    locationid: "13013",
    county: "Barrow",
  },
  {
    fips: "13031",
    count: 27,
    state: "GA",
    type: "County",
    locationid: "13031",
    county: "Bulloch",
  },
  {
    fips: "13069",
    count: 27,
    state: "GA",
    type: "County",
    locationid: "13069",
    county: "Coffee",
  },
  {
    fips: "20015",
    count: 29,
    state: "KS",
    type: "County",
    locationid: "20015",
    county: "Butler",
  },
  {
    fips: "21127",
    count: 29,
    state: "KY",
    type: "County",
    locationid: "21127",
    county: "Lawrence",
  },
  {
    fips: "26033",
    count: 29,
    state: "MI",
    type: "County",
    locationid: "26033",
    county: "Chippewa",
  },
  {
    fips: "27035",
    count: 28,
    state: "MN",
    type: "County",
    locationid: "27035",
    county: "Crow Wing",
  },
  {
    fips: "27131",
    count: 27,
    state: "MN",
    type: "County",
    locationid: "27131",
    county: "Rice",
  },
  {
    fips: "31001",
    count: 26,
    state: "NE",
    type: "County",
    locationid: "31001",
    county: "Adams",
  },
  {
    fips: "36023",
    count: 29,
    state: "NY",
    type: "County",
    locationid: "36023",
    county: "Cortland",
  },
  {
    fips: "37013",
    count: 29,
    state: "NC",
    type: "County",
    locationid: "37013",
    county: "Beaufort",
  },
  {
    fips: "41041",
    count: 29,
    state: "OR",
    type: "County",
    locationid: "41041",
    county: "Lincoln",
  },
  {
    fips: "55071",
    count: 27,
    state: "WI",
    type: "County",
    locationid: "55071",
    county: "Manitowoc",
  },
  {
    fips: "08029",
    count: 22,
    state: "CO",
    type: "County",
    locationid: "08029",
    county: "Delta",
  },
  {
    fips: "12063",
    count: 27,
    state: "FL",
    type: "County",
    locationid: "12063",
    county: "Jackson",
  },
  {
    fips: "17055",
    count: 27,
    state: "IL",
    type: "County",
    locationid: "17055",
    county: "Franklin",
  },
  {
    fips: "20051",
    count: 27,
    state: "KS",
    type: "County",
    locationid: "20051",
    county: "Ellis",
  },
  {
    fips: "20175",
    count: 24,
    state: "KS",
    type: "County",
    locationid: "20175",
    county: "Seward",
  },
  {
    fips: "27025",
    count: 26,
    state: "MN",
    type: "County",
    locationid: "27025",
    county: "Chisago",
  },
  {
    fips: "39059",
    count: 25,
    state: "OH",
    type: "County",
    locationid: "39059",
    county: "Guernsey",
  },
  {
    fips: "40017",
    count: 28,
    state: "OK",
    type: "County",
    locationid: "40017",
    county: "Canadian",
  },
  {
    fips: "45077",
    count: 28,
    state: "SC",
    type: "County",
    locationid: "45077",
    county: "Pickens",
  },
  {
    fips: "06113",
    count: 26,
    state: "CA",
    type: "County",
    locationid: "06113",
    county: "Yolo",
  },
  {
    fips: "13217",
    count: 27,
    state: "GA",
    type: "County",
    locationid: "13217",
    county: "Newton",
  },
  {
    fips: "13219",
    count: 27,
    state: "GA",
    type: "County",
    locationid: "13219",
    county: "Oconee",
  },
  {
    fips: "19045",
    count: 26,
    state: "IA",
    type: "County",
    locationid: "19045",
    county: "Clinton",
  },
  {
    fips: "19179",
    count: 27,
    state: "IA",
    type: "County",
    locationid: "19179",
    county: "Wapello",
  },
  {
    fips: "20055",
    count: 26,
    state: "KS",
    type: "County",
    locationid: "20055",
    county: "Finney",
  },
  {
    fips: "21009",
    count: 26,
    state: "KY",
    type: "County",
    locationid: "21009",
    county: "Barren",
  },
  {
    fips: "42123",
    count: 23,
    state: "PA",
    type: "County",
    locationid: "42123",
    county: "Warren",
  },
  {
    fips: "51109",
    count: 27,
    state: "VA",
    type: "County",
    locationid: "51109",
    county: "Louisa",
  },
  {
    fips: "55135",
    count: 23,
    state: "WI",
    type: "County",
    locationid: "55135",
    county: "Waupaca",
  },
  {
    fips: "56013",
    count: 23,
    state: "WY",
    type: "County",
    locationid: "56013",
    county: "Fremont",
  },
  {
    fips: "17077",
    count: 26,
    state: "IL",
    type: "County",
    locationid: "17077",
    county: "Jackson",
  },
  {
    fips: "20103",
    count: 26,
    state: "KS",
    type: "County",
    locationid: "20103",
    county: "Leavenworth",
  },
  {
    fips: "21133",
    count: 26,
    state: "KY",
    type: "County",
    locationid: "21133",
    county: "Letcher",
  },
  {
    fips: "21231",
    count: 26,
    state: "KY",
    type: "County",
    locationid: "21231",
    county: "Wayne",
  },
  {
    fips: "22005",
    count: 26,
    state: "LA",
    type: "County",
    locationid: "22005",
    county: "Ascension",
  },
  {
    fips: "23025",
    count: 26,
    state: "ME",
    type: "County",
    locationid: "23025",
    county: "Somerset",
  },
  {
    fips: "29091",
    count: 26,
    state: "MO",
    type: "County",
    locationid: "29091",
    county: "Howell",
  },
  {
    fips: "36011",
    count: 26,
    state: "NY",
    type: "County",
    locationid: "36011",
    county: "Cayuga",
  },
  {
    fips: "37109",
    count: 26,
    state: "NC",
    type: "County",
    locationid: "37109",
    county: "Lincoln",
  },
  {
    fips: "40021",
    count: 25,
    state: "OK",
    type: "County",
    locationid: "40021",
    county: "Cherokee",
  },
  {
    fips: "40121",
    count: 25,
    state: "OK",
    type: "County",
    locationid: "40121",
    county: "Pittsburg",
  },
  {
    fips: "41007",
    count: 25,
    state: "OR",
    type: "County",
    locationid: "41007",
    county: "Clatsop",
  },
  {
    fips: "46029",
    count: 25,
    state: "SD",
    type: "County",
    locationid: "46029",
    county: "Codington",
  },
  {
    fips: "47105",
    count: 23,
    state: "TN",
    type: "County",
    locationid: "47105",
    county: "Loudon",
  },
  {
    fips: "50023",
    count: 26,
    state: "VT",
    type: "County",
    locationid: "50023",
    county: "Washington",
  },
  {
    fips: "01083",
    count: 25,
    state: "AL",
    type: "County",
    locationid: "01083",
    county: "Limestone",
  },
  {
    fips: "20169",
    count: 25,
    state: "KS",
    type: "County",
    locationid: "20169",
    county: "Saline",
  },
  {
    fips: "26107",
    count: 23,
    state: "MI",
    type: "County",
    locationid: "26107",
    county: "Mecosta",
  },
  {
    fips: "27091",
    count: 24,
    state: "MN",
    type: "County",
    locationid: "27091",
    county: "Martin",
  },
  {
    fips: "37099",
    count: 25,
    state: "NC",
    type: "County",
    locationid: "37099",
    county: "Jackson",
  },
  {
    fips: "37163",
    count: 25,
    state: "NC",
    type: "County",
    locationid: "37163",
    county: "Sampson",
  },
  {
    fips: "42109",
    count: 25,
    state: "PA",
    type: "County",
    locationid: "42109",
    county: "Snyder",
  },
  {
    fips: "50027",
    count: 23,
    state: "VT",
    type: "County",
    locationid: "50027",
    county: "Windsor",
  },
  {
    fips: "51083",
    count: 25,
    state: "VA",
    type: "County",
    locationid: "51083",
    county: "Halifax",
  },
  {
    fips: "51690",
    count: 25,
    state: "VA",
    type: "County",
    locationid: "51690",
    county: "Martinsville City",
  },
  {
    fips: "53071",
    count: 25,
    state: "WA",
    type: "County",
    locationid: "53071",
    county: "Walla Walla",
  },
  {
    fips: "56005",
    count: 24,
    state: "WY",
    type: "County",
    locationid: "56005",
    county: "Campbell",
  },
  {
    fips: "06017",
    count: 23,
    state: "CA",
    type: "County",
    locationid: "06017",
    county: "El Dorado",
  },
  {
    fips: "15007",
    count: 24,
    state: "HI",
    type: "County",
    locationid: "15007",
    county: "Kauai",
  },
  {
    fips: "17063",
    count: 20,
    state: "IL",
    type: "County",
    locationid: "17063",
    county: "Grundy",
  },
  {
    fips: "20109",
    count: 18,
    state: "KS",
    type: "County",
    locationid: "20109",
    county: "Logan",
  },
  {
    fips: "21015",
    count: 24,
    state: "KY",
    type: "County",
    locationid: "21015",
    county: "Boone",
  },
  {
    fips: "22001",
    count: 23,
    state: "LA",
    type: "County",
    locationid: "22001",
    county: "Acadia",
  },
  {
    fips: "29181",
    count: 24,
    state: "MO",
    type: "County",
    locationid: "29181",
    county: "Ripley",
  },
  {
    fips: "37107",
    count: 24,
    state: "NC",
    type: "County",
    locationid: "37107",
    county: "Lenoir",
  },
  {
    fips: "37181",
    count: 23,
    state: "NC",
    type: "County",
    locationid: "37181",
    county: "Vance",
  },
  {
    fips: "39027",
    count: 24,
    state: "OH",
    type: "County",
    locationid: "39027",
    county: "Clinton",
  },
  {
    fips: "48021",
    count: 24,
    state: "TX",
    type: "County",
    locationid: "48021",
    county: "Bastrop",
  },
  {
    fips: "54025",
    count: 24,
    state: "WV",
    type: "County",
    locationid: "54025",
    county: "Greenbrier",
  },
  {
    fips: "06109",
    count: 23,
    state: "CA",
    type: "County",
    locationid: "06109",
    county: "Tuolumne",
  },
  {
    fips: "12089",
    count: 23,
    state: "FL",
    type: "County",
    locationid: "12089",
    county: "Nassau",
  },
  {
    fips: "21071",
    count: 22,
    state: "KY",
    type: "County",
    locationid: "21071",
    county: "Floyd",
  },
  {
    fips: "33003",
    count: 21,
    state: "NH",
    type: "County",
    locationid: "33003",
    county: "Carroll",
  },
  {
    fips: "37165",
    count: 23,
    state: "NC",
    type: "County",
    locationid: "37165",
    county: "Scotland",
  },
  {
    fips: "37193",
    count: 23,
    state: "NC",
    type: "County",
    locationid: "37193",
    county: "Wilkes",
  },
  {
    fips: "39159",
    count: 21,
    state: "OH",
    type: "County",
    locationid: "39159",
    county: "Union",
  },
  {
    fips: "42037",
    count: 23,
    state: "PA",
    type: "County",
    locationid: "42037",
    county: "Columbia",
  },
  {
    fips: "45021",
    count: 23,
    state: "SC",
    type: "County",
    locationid: "45021",
    county: "Cherokee",
  },
  {
    fips: "47063",
    count: 23,
    state: "TN",
    type: "County",
    locationid: "47063",
    county: "Hamblen",
  },
  {
    fips: "51199",
    count: 23,
    state: "VA",
    type: "County",
    locationid: "51199",
    county: "York",
  },
  {
    fips: "55005",
    count: 23,
    state: "WI",
    type: "County",
    locationid: "55005",
    county: "Barron",
  },
  {
    fips: "55097",
    count: 21,
    state: "WI",
    type: "County",
    locationid: "55097",
    county: "Portage",
  },
  {
    fips: "13235",
    count: 22,
    state: "GA",
    type: "County",
    locationid: "13235",
    county: "Pulaski",
  },
  {
    fips: "17157",
    count: 22,
    state: "IL",
    type: "County",
    locationid: "17157",
    county: "Randolph",
  },
  {
    fips: "18011",
    count: 22,
    state: "IN",
    type: "County",
    locationid: "18011",
    county: "Boone",
  },
  {
    fips: "21203",
    count: 21,
    state: "KY",
    type: "County",
    locationid: "21203",
    county: "Rockcastle",
  },
  {
    fips: "26097",
    count: 16,
    state: "MI",
    type: "County",
    locationid: "26097",
    county: "Mackinac",
  },
  {
    fips: "26101",
    count: 22,
    state: "MI",
    type: "County",
    locationid: "26101",
    county: "Manistee",
  },
  {
    fips: "36051",
    count: 21,
    state: "NY",
    type: "County",
    locationid: "36051",
    county: "Livingston",
  },
  {
    fips: "37113",
    count: 22,
    state: "NC",
    type: "County",
    locationid: "37113",
    county: "Macon",
  },
  {
    fips: "40065",
    count: 21,
    state: "OK",
    type: "County",
    locationid: "40065",
    county: "Jackson",
  },
  {
    fips: "51167",
    count: 22,
    state: "VA",
    type: "County",
    locationid: "51167",
    county: "Russell",
  },
  {
    fips: "51683",
    count: 22,
    state: "VA",
    type: "County",
    locationid: "51683",
    county: "Manassas City",
  },
  {
    fips: "55075",
    count: 19,
    state: "WI",
    type: "County",
    locationid: "55075",
    county: "Marinette",
  },
  {
    fips: "06101",
    count: 21,
    state: "CA",
    type: "County",
    locationid: "06101",
    county: "Sutter",
  },
  {
    fips: "19057",
    count: 18,
    state: "IA",
    type: "County",
    locationid: "19057",
    county: "Des Moines",
  },
  {
    fips: "21179",
    count: 19,
    state: "KY",
    type: "County",
    locationid: "21179",
    county: "Nelson",
  },
  {
    fips: "37083",
    count: 21,
    state: "NC",
    type: "County",
    locationid: "37083",
    county: "Halifax",
  },
  {
    fips: "39015",
    count: 21,
    state: "OH",
    type: "County",
    locationid: "39015",
    county: "Brown",
  },
  {
    fips: "39071",
    count: 20,
    state: "OH",
    type: "County",
    locationid: "39071",
    county: "Highland",
  },
  {
    fips: "39129",
    count: 20,
    state: "OH",
    type: "County",
    locationid: "39129",
    county: "Pickaway",
  },
  {
    fips: "39149",
    count: 21,
    state: "OH",
    type: "County",
    locationid: "39149",
    county: "Shelby",
  },
  {
    fips: "40125",
    count: 21,
    state: "OK",
    type: "County",
    locationid: "40125",
    county: "Pottawatomie",
  },
  {
    fips: "47019",
    count: 21,
    state: "TN",
    type: "County",
    locationid: "47019",
    county: "Carter",
  },
  {
    fips: "51073",
    count: 21,
    state: "VA",
    type: "County",
    locationid: "51073",
    county: "Gloucester",
  },
  {
    fips: "54029",
    count: 21,
    state: "WV",
    type: "County",
    locationid: "54029",
    county: "Hancock",
  },
  {
    fips: "55027",
    count: 21,
    state: "WI",
    type: "County",
    locationid: "55027",
    county: "Dodge",
  },
  {
    fips: "56029",
    count: 21,
    state: "WY",
    type: "County",
    locationid: "56029",
    county: "Park",
  },
  {
    fips: "56033",
    count: 20,
    state: "WY",
    type: "County",
    locationid: "56033",
    county: "Sheridan",
  },
  {
    fips: "01115",
    count: 20,
    state: "AL",
    type: "County",
    locationid: "01115",
    county: "Saint Clair",
  },
  {
    fips: "21095",
    count: 17,
    state: "KY",
    type: "County",
    locationid: "21095",
    county: "Harlan",
  },
  {
    fips: "22117",
    count: 20,
    state: "LA",
    type: "County",
    locationid: "22117",
    county: "Washington",
  },
  {
    fips: "26023",
    count: 20,
    state: "MI",
    type: "County",
    locationid: "26023",
    county: "Branch",
  },
  {
    fips: "36037",
    count: 18,
    state: "NY",
    type: "County",
    locationid: "36037",
    county: "Genesee",
  },
  {
    fips: "36105",
    count: 20,
    state: "NY",
    type: "County",
    locationid: "36105",
    county: "Sullivan",
  },
  {
    fips: "37027",
    count: 20,
    state: "NC",
    type: "County",
    locationid: "37027",
    county: "Caldwell",
  },
  {
    fips: "37189",
    count: 20,
    state: "NC",
    type: "County",
    locationid: "37189",
    county: "Watauga",
  },
  {
    fips: "47107",
    count: 20,
    state: "TN",
    type: "County",
    locationid: "47107",
    county: "Mcminn",
  },
  {
    fips: "48381",
    count: 20,
    state: "TX",
    type: "County",
    locationid: "48381",
    county: "Randall",
  },
  {
    fips: "50011",
    count: 20,
    state: "VT",
    type: "County",
    locationid: "50011",
    county: "Franklin",
  },
  {
    fips: "54019",
    count: 20,
    state: "WV",
    type: "County",
    locationid: "54019",
    county: "Fayette",
  },
  {
    fips: "55119",
    count: 20,
    state: "WI",
    type: "County",
    locationid: "55119",
    county: "Taylor",
  },
  {
    fips: "06039",
    count: 18,
    state: "CA",
    type: "County",
    locationid: "06039",
    county: "Madera",
  },
  {
    fips: "08085",
    count: 17,
    state: "CO",
    type: "County",
    locationid: "08085",
    county: "Montrose",
  },
  {
    fips: "13065",
    count: 19,
    state: "GA",
    type: "County",
    locationid: "13065",
    county: "Clinch",
  },
  {
    fips: "20037",
    count: 19,
    state: "KS",
    type: "County",
    locationid: "20037",
    county: "Crawford",
  },
  {
    fips: "21217",
    count: 17,
    state: "KY",
    type: "County",
    locationid: "21217",
    county: "Taylor",
  },
  {
    fips: "22115",
    count: 17,
    state: "LA",
    type: "County",
    locationid: "22115",
    county: "Vernon",
  },
  {
    fips: "25011",
    count: 19,
    state: "MA",
    type: "County",
    locationid: "25011",
    county: "Franklin",
  },
  {
    fips: "37105",
    count: 18,
    state: "NC",
    type: "County",
    locationid: "37105",
    county: "Lee",
  },
  {
    fips: "37167",
    count: 19,
    state: "NC",
    type: "County",
    locationid: "37167",
    county: "Stanly",
  },
  {
    fips: "39001",
    count: 19,
    state: "OH",
    type: "County",
    locationid: "39001",
    county: "Adams",
  },
  {
    fips: "39047",
    count: 19,
    state: "OH",
    type: "County",
    locationid: "39047",
    county: "Fayette",
  },
  {
    fips: "39079",
    count: 19,
    state: "OH",
    type: "County",
    locationid: "39079",
    county: "Jackson",
  },
  {
    fips: "45031",
    count: 19,
    state: "SC",
    type: "County",
    locationid: "45031",
    county: "Darlington",
  },
  {
    fips: "47073",
    count: 19,
    state: "TN",
    type: "County",
    locationid: "47073",
    county: "Hawkins",
  },
  {
    fips: "48395",
    count: 19,
    state: "TX",
    type: "County",
    locationid: "48395",
    county: "Robertson",
  },
  {
    fips: "53021",
    count: 19,
    state: "WA",
    type: "County",
    locationid: "53021",
    county: "Franklin",
  },
  {
    fips: "04017",
    count: 17,
    state: "AZ",
    type: "County",
    locationid: "04017",
    county: "Navajo",
  },
  {
    fips: "13257",
    count: 18,
    state: "GA",
    type: "County",
    locationid: "13257",
    county: "Stephens",
  },
  {
    fips: "18169",
    count: 16,
    state: "IN",
    type: "County",
    locationid: "18169",
    county: "Wabash",
  },
  {
    fips: "18181",
    count: 18,
    state: "IN",
    type: "County",
    locationid: "18181",
    county: "White",
  },
  {
    fips: "21185",
    count: 18,
    state: "KY",
    type: "County",
    locationid: "21185",
    county: "Oldham",
  },
  {
    fips: "22121",
    count: 18,
    state: "LA",
    type: "County",
    locationid: "22121",
    county: "West Baton Rouge",
  },
  {
    fips: "26041",
    count: 18,
    state: "MI",
    type: "County",
    locationid: "26041",
    county: "Delta",
  },
  {
    fips: "26059",
    count: 18,
    state: "MI",
    type: "County",
    locationid: "26059",
    county: "Hillsdale",
  },
  {
    fips: "26123",
    count: 18,
    state: "MI",
    type: "County",
    locationid: "26123",
    county: "Newaygo",
  },
  {
    fips: "26129",
    count: 18,
    state: "MI",
    type: "County",
    locationid: "26129",
    county: "Ogemaw",
  },
  {
    fips: "27015",
    count: 15,
    state: "MN",
    type: "County",
    locationid: "27015",
    county: "Brown",
  },
  {
    fips: "27067",
    count: 17,
    state: "MN",
    type: "County",
    locationid: "27067",
    county: "Kandiyohi",
  },
  {
    fips: "36003",
    count: 18,
    state: "NY",
    type: "County",
    locationid: "36003",
    county: "Allegany",
  },
  {
    fips: "37037",
    count: 18,
    state: "NC",
    type: "County",
    locationid: "37037",
    county: "Chatham",
  },
  {
    fips: "40135",
    count: 18,
    state: "OK",
    type: "County",
    locationid: "40135",
    county: "Sequoyah",
  },
  {
    fips: "41065",
    count: 17,
    state: "OR",
    type: "County",
    locationid: "41065",
    county: "Wasco",
  },
  {
    fips: "47147",
    count: 18,
    state: "TN",
    type: "County",
    locationid: "47147",
    county: "Robertson",
  },
  {
    fips: "18099",
    count: 17,
    state: "IN",
    type: "County",
    locationid: "18099",
    county: "Marshall",
  },
  {
    fips: "18101",
    count: 17,
    state: "IN",
    type: "County",
    locationid: "18101",
    county: "Martin",
  },
  {
    fips: "21013",
    count: 17,
    state: "KY",
    type: "County",
    locationid: "21013",
    county: "Bell",
  },
  {
    fips: "22039",
    count: 17,
    state: "LA",
    type: "County",
    locationid: "22039",
    county: "Evangeline",
  },
  {
    fips: "28105",
    count: 17,
    state: "MS",
    type: "County",
    locationid: "28105",
    county: "Oktibbeha",
  },
  {
    fips: "30031",
    count: 17,
    state: "MT",
    type: "County",
    locationid: "30031",
    county: "Gallatin",
  },
  {
    fips: "37047",
    count: 17,
    state: "NC",
    type: "County",
    locationid: "37047",
    county: "Columbus",
  },
  {
    fips: "37085",
    count: 17,
    state: "NC",
    type: "County",
    locationid: "37085",
    county: "Harnett",
  },
  {
    fips: "42009",
    count: 17,
    state: "PA",
    type: "County",
    locationid: "42009",
    county: "Bedford",
  },
  {
    fips: "42083",
    count: 17,
    state: "PA",
    type: "County",
    locationid: "42083",
    county: "Mckean",
  },
  {
    fips: "45015",
    count: 17,
    state: "SC",
    type: "County",
    locationid: "45015",
    county: "Berkeley",
  },
  {
    fips: "53041",
    count: 17,
    state: "WA",
    type: "County",
    locationid: "53041",
    county: "Lewis",
  },
  {
    fips: "55093",
    count: 14,
    state: "WI",
    type: "County",
    locationid: "55093",
    county: "Pierce",
  },
  {
    fips: "01121",
    count: 16,
    state: "AL",
    type: "County",
    locationid: "01121",
    county: "Talladega",
  },
  {
    fips: "04007",
    count: 16,
    state: "AZ",
    type: "County",
    locationid: "04007",
    county: "Gila",
  },
  {
    fips: "17011",
    count: 16,
    state: "IL",
    type: "County",
    locationid: "17011",
    county: "Bureau",
  },
  {
    fips: "18033",
    count: 16,
    state: "IN",
    type: "County",
    locationid: "18033",
    county: "De Kalb",
  },
  {
    fips: "22063",
    count: 16,
    state: "LA",
    type: "County",
    locationid: "22063",
    county: "Livingston",
  },
  {
    fips: "22089",
    count: 16,
    state: "LA",
    type: "County",
    locationid: "22089",
    county: "Saint Charles",
  },
  {
    fips: "26149",
    count: 16,
    state: "MI",
    type: "County",
    locationid: "26149",
    county: "Saint Joseph",
  },
  {
    fips: "27061",
    count: 16,
    state: "MN",
    type: "County",
    locationid: "27061",
    county: "Itasca",
  },
  {
    fips: "29083",
    count: 16,
    state: "MO",
    type: "County",
    locationid: "29083",
    county: "Henry",
  },
  {
    fips: "41015",
    count: 16,
    state: "OR",
    type: "County",
    locationid: "41015",
    county: "Curry",
  },
  {
    fips: "45055",
    count: 16,
    state: "SC",
    type: "County",
    locationid: "45055",
    county: "Kershaw",
  },
  {
    fips: "47045",
    count: 16,
    state: "TN",
    type: "County",
    locationid: "47045",
    county: "Dyer",
  },
  {
    fips: "48291",
    count: 16,
    state: "TX",
    type: "County",
    locationid: "48291",
    county: "Liberty",
  },
  {
    fips: "51019",
    count: 16,
    state: "VA",
    type: "County",
    locationid: "51019",
    county: "Bedford",
  },
  {
    fips: "53027",
    count: 16,
    state: "WA",
    type: "County",
    locationid: "53027",
    county: "Grays Harbor",
  },
  {
    fips: "54079",
    count: 16,
    state: "WV",
    type: "County",
    locationid: "54079",
    county: "Putnam",
  },
  {
    fips: "55127",
    count: 16,
    state: "WI",
    type: "County",
    locationid: "55127",
    county: "Walworth",
  },
  {
    fips: "06033",
    count: 15,
    state: "CA",
    type: "County",
    locationid: "06033",
    county: "Lake",
  },
  {
    fips: "08037",
    count: 15,
    state: "CO",
    type: "County",
    locationid: "08037",
    county: "Eagle",
  },
  {
    fips: "13029",
    count: 15,
    state: "GA",
    type: "County",
    locationid: "13029",
    county: "Bryan",
  },
  {
    fips: "13293",
    count: 15,
    state: "GA",
    type: "County",
    locationid: "13293",
    county: "Upson",
  },
  {
    fips: "17051",
    count: 15,
    state: "IL",
    type: "County",
    locationid: "17051",
    county: "Fayette",
  },
  {
    fips: "17195",
    count: 15,
    state: "IL",
    type: "County",
    locationid: "17195",
    county: "Whiteside",
  },
  {
    fips: "18139",
    count: 15,
    state: "IN",
    type: "County",
    locationid: "18139",
    county: "Rush",
  },
  {
    fips: "19049",
    count: 15,
    state: "IA",
    type: "County",
    locationid: "19049",
    county: "Dallas",
  },
  {
    fips: "19111",
    count: 15,
    state: "IA",
    type: "County",
    locationid: "19111",
    county: "Lee",
  },
  {
    fips: "19137",
    count: 12,
    state: "IA",
    type: "County",
    locationid: "19137",
    county: "Montgomery",
  },
  {
    fips: "21163",
    count: 15,
    state: "KY",
    type: "County",
    locationid: "21163",
    county: "Meade",
  },
  {
    fips: "21209",
    count: 15,
    state: "KY",
    type: "County",
    locationid: "21209",
    county: "Scott",
  },
  {
    fips: "26091",
    count: 15,
    state: "MI",
    type: "County",
    locationid: "26091",
    county: "Lenawee",
  },
  {
    fips: "27147",
    count: 15,
    state: "MN",
    type: "County",
    locationid: "27147",
    county: "Steele",
  },
  {
    fips: "33001",
    count: 15,
    state: "NH",
    type: "County",
    locationid: "33001",
    county: "Belknap",
  },
  {
    fips: "33007",
    count: 15,
    state: "NH",
    type: "County",
    locationid: "33007",
    county: "Coos",
  },
  {
    fips: "36073",
    count: 15,
    state: "NY",
    type: "County",
    locationid: "36073",
    county: "Orleans",
  },
  {
    fips: "37059",
    count: 15,
    state: "NC",
    type: "County",
    locationid: "37059",
    county: "Davie",
  },
  {
    fips: "37145",
    count: 15,
    state: "NC",
    type: "County",
    locationid: "37145",
    county: "Person",
  },
  {
    fips: "40051",
    count: 15,
    state: "OK",
    type: "County",
    locationid: "40051",
    county: "Grady",
  },
  {
    fips: "40131",
    count: 15,
    state: "OK",
    type: "County",
    locationid: "40131",
    county: "Rogers",
  },
  {
    fips: "48053",
    count: 15,
    state: "TX",
    type: "County",
    locationid: "48053",
    county: "Burnet",
  },
  {
    fips: "51061",
    count: 15,
    state: "VA",
    type: "County",
    locationid: "51061",
    county: "Fauquier",
  },
  {
    fips: "51570",
    count: 14,
    state: "VA",
    type: "County",
    locationid: "51570",
    county: "Colonial Heights City",
  },
  {
    fips: "51610",
    count: 15,
    state: "VA",
    type: "County",
    locationid: "51610",
    county: "Falls Church City",
  },
  {
    fips: "53031",
    count: 15,
    state: "WA",
    type: "County",
    locationid: "53031",
    county: "Jefferson",
  },
  {
    fips: "53049",
    count: 15,
    state: "WA",
    type: "County",
    locationid: "53049",
    county: "Pacific",
  },
  {
    fips: "53059",
    count: 15,
    state: "WA",
    type: "County",
    locationid: "53059",
    county: "Skamania",
  },
  {
    fips: "13179",
    count: 14,
    state: "GA",
    type: "County",
    locationid: "13179",
    county: "Liberty",
  },
  {
    fips: "16075",
    count: 13,
    state: "ID",
    type: "County",
    locationid: "16075",
    county: "Payette",
  },
  {
    fips: "18049",
    count: 14,
    state: "IN",
    type: "County",
    locationid: "18049",
    county: "Fulton",
  },
  {
    fips: "19145",
    count: 14,
    state: "IA",
    type: "County",
    locationid: "19145",
    county: "Page",
  },
  {
    fips: "21211",
    count: 14,
    state: "KY",
    type: "County",
    locationid: "21211",
    county: "Shelby",
  },
  {
    fips: "22093",
    count: 14,
    state: "LA",
    type: "County",
    locationid: "22093",
    county: "Saint James",
  },
  {
    fips: "26029",
    count: 14,
    state: "MI",
    type: "County",
    locationid: "26029",
    county: "Charlevoix",
  },
  {
    fips: "26067",
    count: 12,
    state: "MI",
    type: "County",
    locationid: "26067",
    county: "Ionia",
  },
  {
    fips: "27049",
    count: 14,
    state: "MN",
    type: "County",
    locationid: "27049",
    county: "Goodhue",
  },
  {
    fips: "27083",
    count: 14,
    state: "MN",
    type: "County",
    locationid: "27083",
    county: "Lyon",
  },
  {
    fips: "27161",
    count: 14,
    state: "MN",
    type: "County",
    locationid: "27161",
    county: "Waseca",
  },
  {
    fips: "28001",
    count: 14,
    state: "MS",
    type: "County",
    locationid: "28001",
    county: "Adams",
  },
  {
    fips: "28145",
    count: 14,
    state: "MS",
    type: "County",
    locationid: "28145",
    county: "Union",
  },
  {
    fips: "29037",
    count: 14,
    state: "MO",
    type: "County",
    locationid: "29037",
    county: "Cass",
  },
  {
    fips: "37111",
    count: 14,
    state: "NC",
    type: "County",
    locationid: "37111",
    county: "Mcdowell",
  },
  {
    fips: "39147",
    count: 14,
    state: "OH",
    type: "County",
    locationid: "39147",
    county: "Seneca",
  },
  {
    fips: "42105",
    count: 12,
    state: "PA",
    type: "County",
    locationid: "42105",
    county: "Potter",
  },
  {
    fips: "46035",
    count: 13,
    state: "SD",
    type: "County",
    locationid: "46035",
    county: "Davison",
  },
  {
    fips: "47145",
    count: 14,
    state: "TN",
    type: "County",
    locationid: "47145",
    county: "Roane",
  },
  {
    fips: "48187",
    count: 14,
    state: "TX",
    type: "County",
    locationid: "48187",
    county: "Guadalupe",
  },
  {
    fips: "48257",
    count: 14,
    state: "TX",
    type: "County",
    locationid: "48257",
    county: "Kaufman",
  },
  {
    fips: "48493",
    count: 14,
    state: "TX",
    type: "County",
    locationid: "48493",
    county: "Wilson",
  },
  {
    fips: "51035",
    count: 14,
    state: "VA",
    type: "County",
    locationid: "51035",
    county: "Carroll",
  },
  {
    fips: "51670",
    count: 14,
    state: "VA",
    type: "County",
    locationid: "51670",
    county: "Hopewell City",
  },
  {
    fips: "55095",
    count: 14,
    state: "WI",
    type: "County",
    locationid: "55095",
    county: "Polk",
  },
  {
    fips: "55115",
    count: 14,
    state: "WI",
    type: "County",
    locationid: "55115",
    county: "Shawano",
  },
  {
    fips: "05027",
    count: 13,
    state: "AR",
    type: "County",
    locationid: "05027",
    county: "Columbia",
  },
  {
    fips: "12133",
    count: 13,
    state: "FL",
    type: "County",
    locationid: "12133",
    county: "Washington",
  },
  {
    fips: "13081",
    count: 12,
    state: "GA",
    type: "County",
    locationid: "13081",
    county: "Crisp",
  },
  {
    fips: "13103",
    count: 13,
    state: "GA",
    type: "County",
    locationid: "13103",
    county: "Effingham",
  },
  {
    fips: "13129",
    count: 12,
    state: "GA",
    type: "County",
    locationid: "13129",
    county: "Gordon",
  },
  {
    fips: "17121",
    count: 13,
    state: "IL",
    type: "County",
    locationid: "17121",
    county: "Marion",
  },
  {
    fips: "17165",
    count: 13,
    state: "IL",
    type: "County",
    locationid: "17165",
    county: "Saline",
  },
  {
    fips: "18065",
    count: 13,
    state: "IN",
    type: "County",
    locationid: "18065",
    county: "Henry",
  },
  {
    fips: "21213",
    count: 13,
    state: "KY",
    type: "County",
    locationid: "21213",
    county: "Simpson",
  },
  {
    fips: "22069",
    count: 13,
    state: "LA",
    type: "County",
    locationid: "22069",
    county: "Natchitoches",
  },
  {
    fips: "26015",
    count: 12,
    state: "MI",
    type: "County",
    locationid: "26015",
    county: "Barry",
  },
  {
    fips: "26069",
    count: 13,
    state: "MI",
    type: "County",
    locationid: "26069",
    county: "Iosco",
  },
  {
    fips: "28045",
    count: 13,
    state: "MS",
    type: "County",
    locationid: "28045",
    county: "Hancock",
  },
  {
    fips: "31137",
    count: 11,
    state: "NE",
    type: "County",
    locationid: "31137",
    county: "Phelps",
  },
  {
    fips: "32023",
    count: 13,
    state: "NV",
    type: "County",
    locationid: "32023",
    county: "Nye",
  },
  {
    fips: "36035",
    count: 13,
    state: "NY",
    type: "County",
    locationid: "36035",
    county: "Fulton",
  },
  {
    fips: "39005",
    count: 13,
    state: "OH",
    type: "County",
    locationid: "39005",
    county: "Ashland",
  },
  {
    fips: "39097",
    count: 10,
    state: "OH",
    type: "County",
    locationid: "39097",
    county: "Madison",
  },
  {
    fips: "39161",
    count: 13,
    state: "OH",
    type: "County",
    locationid: "39161",
    county: "Van Wert",
  },
  {
    fips: "40071",
    count: 12,
    state: "OK",
    type: "County",
    locationid: "40071",
    county: "Kay",
  },
  {
    fips: "42031",
    count: 13,
    state: "PA",
    type: "County",
    locationid: "42031",
    county: "Clarion",
  },
  {
    fips: "42117",
    count: 12,
    state: "PA",
    type: "County",
    locationid: "42117",
    county: "Tioga",
  },
  {
    fips: "45059",
    count: 13,
    state: "SC",
    type: "County",
    locationid: "45059",
    county: "Laurens",
  },
  {
    fips: "47025",
    count: 12,
    state: "TN",
    type: "County",
    locationid: "47025",
    county: "Claiborne",
  },
  {
    fips: "47053",
    count: 13,
    state: "TN",
    type: "County",
    locationid: "47053",
    county: "Gibson",
  },
  {
    fips: "47071",
    count: 13,
    state: "TN",
    type: "County",
    locationid: "47071",
    county: "Hardin",
  },
  {
    fips: "51117",
    count: 13,
    state: "VA",
    type: "County",
    locationid: "51117",
    county: "Mecklenburg",
  },
  {
    fips: "55003",
    count: 13,
    state: "WI",
    type: "County",
    locationid: "55003",
    county: "Ashland",
  },
  {
    fips: "55129",
    count: 13,
    state: "WI",
    type: "County",
    locationid: "55129",
    county: "Washburn",
  },
  {
    fips: "01047",
    count: 12,
    state: "AL",
    type: "County",
    locationid: "01047",
    county: "Dallas",
  },
  {
    fips: "05083",
    count: 12,
    state: "AR",
    type: "County",
    locationid: "05083",
    county: "Logan",
  },
  {
    fips: "05139",
    count: 12,
    state: "AR",
    type: "County",
    locationid: "05139",
    county: "Union",
  },
  {
    fips: "06031",
    count: 12,
    state: "CA",
    type: "County",
    locationid: "06031",
    county: "Kings",
  },
  {
    fips: "08003",
    count: 11,
    state: "CO",
    type: "County",
    locationid: "08003",
    county: "Alamosa",
  },
  {
    fips: "13071",
    count: 12,
    state: "GA",
    type: "County",
    locationid: "13071",
    county: "Colquitt",
  },
  {
    fips: "13279",
    count: 12,
    state: "GA",
    type: "County",
    locationid: "13279",
    county: "Toombs",
  },
  {
    fips: "16017",
    count: 12,
    state: "ID",
    type: "County",
    locationid: "16017",
    county: "Bonner",
  },
  {
    fips: "18023",
    count: 11,
    state: "IN",
    type: "County",
    locationid: "18023",
    county: "Clinton",
  },
  {
    fips: "18143",
    count: 12,
    state: "IN",
    type: "County",
    locationid: "18143",
    county: "Scott",
  },
  {
    fips: "19127",
    count: 12,
    state: "IA",
    type: "County",
    locationid: "19127",
    county: "Marshall",
  },
  {
    fips: "20035",
    count: 12,
    state: "KS",
    type: "County",
    locationid: "20035",
    county: "Cowley",
  },
  {
    fips: "21173",
    count: 12,
    state: "KY",
    type: "County",
    locationid: "21173",
    county: "Montgomery",
  },
  {
    fips: "24023",
    count: 12,
    state: "MD",
    type: "County",
    locationid: "24023",
    county: "Garrett",
  },
  {
    fips: "26039",
    count: 12,
    state: "MI",
    type: "County",
    locationid: "26039",
    county: "Crawford",
  },
  {
    fips: "26159",
    count: 12,
    state: "MI",
    type: "County",
    locationid: "26159",
    county: "Van Buren",
  },
  {
    fips: "26165",
    count: 12,
    state: "MI",
    type: "County",
    locationid: "26165",
    county: "Wexford",
  },
  {
    fips: "27005",
    count: 10,
    state: "MN",
    type: "County",
    locationid: "27005",
    county: "Becker",
  },
  {
    fips: "27111",
    count: 12,
    state: "MN",
    type: "County",
    locationid: "27111",
    county: "Otter Tail",
  },
  {
    fips: "28011",
    count: 12,
    state: "MS",
    type: "County",
    locationid: "28011",
    county: "Bolivar",
  },
  {
    fips: "29207",
    count: 12,
    state: "MO",
    type: "County",
    locationid: "29207",
    county: "Stoddard",
  },
  {
    fips: "30093",
    count: 11,
    state: "MT",
    type: "County",
    locationid: "30093",
    county: "Silver Bow",
  },
  {
    fips: "37005",
    count: 12,
    state: "NC",
    type: "County",
    locationid: "37005",
    county: "Alleghany",
  },
  {
    fips: "37077",
    count: 12,
    state: "NC",
    type: "County",
    locationid: "37077",
    county: "Granville",
  },
  {
    fips: "39107",
    count: 12,
    state: "OH",
    type: "County",
    locationid: "39107",
    county: "Mercer",
  },
  {
    fips: "39175",
    count: 12,
    state: "OH",
    type: "County",
    locationid: "39175",
    county: "Wyandot",
  },
  {
    fips: "40079",
    count: 12,
    state: "OK",
    type: "County",
    locationid: "40079",
    county: "Le Flore",
  },
  {
    fips: "44001",
    count: 12,
    state: "RI",
    type: "County",
    locationid: "44001",
    county: "Bristol",
  },
  {
    fips: "45035",
    count: 12,
    state: "SC",
    type: "County",
    locationid: "45035",
    county: "Dorchester",
  },
  {
    fips: "45049",
    count: 12,
    state: "SC",
    type: "County",
    locationid: "45049",
    county: "Hampton",
  },
  {
    fips: "46065",
    count: 10,
    state: "SD",
    type: "County",
    locationid: "46065",
    county: "Hughes",
  },
  {
    fips: "47167",
    count: 12,
    state: "TN",
    type: "County",
    locationid: "47167",
    county: "Tipton",
  },
  {
    fips: "47183",
    count: 12,
    state: "TN",
    type: "County",
    locationid: "47183",
    county: "Weakley",
  },
  {
    fips: "48007",
    count: 12,
    state: "TX",
    type: "County",
    locationid: "48007",
    county: "Aransas",
  },
  {
    fips: "48217",
    count: 12,
    state: "TX",
    type: "County",
    locationid: "48217",
    county: "Hill",
  },
  {
    fips: "48349",
    count: 12,
    state: "TX",
    type: "County",
    locationid: "48349",
    county: "Navarro",
  },
  {
    fips: "48361",
    count: 12,
    state: "TX",
    type: "County",
    locationid: "48361",
    county: "Orange",
  },
  {
    fips: "49005",
    count: 12,
    state: "UT",
    type: "County",
    locationid: "49005",
    county: "Cache",
  },
  {
    fips: "51169",
    count: 12,
    state: "VA",
    type: "County",
    locationid: "51169",
    county: "Scott",
  },
  {
    fips: "51187",
    count: 12,
    state: "VA",
    type: "County",
    locationid: "51187",
    county: "Warren",
  },
  {
    fips: "54099",
    count: 12,
    state: "WV",
    type: "County",
    locationid: "54099",
    county: "Wayne",
  },
  {
    fips: "56037",
    count: 12,
    state: "WY",
    type: "County",
    locationid: "56037",
    county: "Sweetwater",
  },
  {
    fips: "05035",
    count: 11,
    state: "AR",
    type: "County",
    locationid: "05035",
    county: "Crittenden",
  },
  {
    fips: "05055",
    count: 11,
    state: "AR",
    type: "County",
    locationid: "05055",
    county: "Greene",
  },
  {
    fips: "06103",
    count: 11,
    state: "CA",
    type: "County",
    locationid: "06103",
    county: "Tehama",
  },
  {
    fips: "13297",
    count: 11,
    state: "GA",
    type: "County",
    locationid: "13297",
    county: "Walton",
  },
  {
    fips: "16057",
    count: 10,
    state: "ID",
    type: "County",
    locationid: "16057",
    county: "Latah",
  },
  {
    fips: "17181",
    count: 11,
    state: "IL",
    type: "County",
    locationid: "17181",
    county: "Union",
  },
  {
    fips: "21039",
    count: 11,
    state: "KY",
    type: "County",
    locationid: "21039",
    county: "Carlisle",
  },
  {
    fips: "21083",
    count: 11,
    state: "KY",
    type: "County",
    locationid: "21083",
    county: "Graves",
  },
  {
    fips: "21097",
    count: 11,
    state: "KY",
    type: "County",
    locationid: "21097",
    county: "Harrison",
  },
  {
    fips: "21155",
    count: 11,
    state: "KY",
    type: "County",
    locationid: "21155",
    county: "Marion",
  },
  {
    fips: "22113",
    count: 11,
    state: "LA",
    type: "County",
    locationid: "22113",
    county: "Vermilion",
  },
  {
    fips: "23015",
    count: 11,
    state: "ME",
    type: "County",
    locationid: "23015",
    county: "Lincoln",
  },
  {
    fips: "23029",
    count: 11,
    state: "ME",
    type: "County",
    locationid: "23029",
    county: "Washington",
  },
  {
    fips: "26019",
    count: 11,
    state: "MI",
    type: "County",
    locationid: "26019",
    county: "Benzie",
  },
  {
    fips: "26063",
    count: 11,
    state: "MI",
    type: "County",
    locationid: "26063",
    county: "Huron",
  },
  {
    fips: "35017",
    count: 11,
    state: "NM",
    type: "County",
    locationid: "35017",
    county: "Grant",
  },
  {
    fips: "37033",
    count: 11,
    state: "NC",
    type: "County",
    locationid: "37033",
    county: "Caswell",
  },
  {
    fips: "37141",
    count: 11,
    state: "NC",
    type: "County",
    locationid: "37141",
    county: "Pender",
  },
  {
    fips: "37197",
    count: 11,
    state: "NC",
    type: "County",
    locationid: "37197",
    county: "Yadkin",
  },
  {
    fips: "38089",
    count: 11,
    state: "ND",
    type: "County",
    locationid: "38089",
    county: "Stark",
  },
  {
    fips: "39033",
    count: 11,
    state: "OH",
    type: "County",
    locationid: "39033",
    county: "Crawford",
  },
  {
    fips: "39039",
    count: 11,
    state: "OH",
    type: "County",
    locationid: "39039",
    county: "Defiance",
  },
  {
    fips: "39143",
    count: 11,
    state: "OH",
    type: "County",
    locationid: "39143",
    county: "Sandusky",
  },
  {
    fips: "42035",
    count: 11,
    state: "PA",
    type: "County",
    locationid: "42035",
    county: "Clinton",
  },
  {
    fips: "42087",
    count: 11,
    state: "PA",
    type: "County",
    locationid: "42087",
    county: "Mifflin",
  },
  {
    fips: "42099",
    count: 11,
    state: "PA",
    type: "County",
    locationid: "42099",
    county: "Perry",
  },
  {
    fips: "48203",
    count: 11,
    state: "TX",
    type: "County",
    locationid: "48203",
    county: "Harrison",
  },
  {
    fips: "48249",
    count: 11,
    state: "TX",
    type: "County",
    locationid: "48249",
    county: "Jim Wells",
  },
  {
    fips: "48463",
    count: 11,
    state: "TX",
    type: "County",
    locationid: "48463",
    county: "Uvalde",
  },
  {
    fips: "51103",
    count: 11,
    state: "VA",
    type: "County",
    locationid: "51103",
    county: "Lancaster",
  },
  {
    fips: "51141",
    count: 11,
    state: "VA",
    type: "County",
    locationid: "51141",
    county: "Patrick",
  },
  {
    fips: "51149",
    count: 11,
    state: "VA",
    type: "County",
    locationid: "51149",
    county: "Prince George",
  },
  {
    fips: "51520",
    count: 11,
    state: "VA",
    type: "County",
    locationid: "51520",
    county: "Bristol",
  },
  {
    fips: "53029",
    count: 11,
    state: "WA",
    type: "County",
    locationid: "53029",
    county: "Island",
  },
  {
    fips: "54037",
    count: 11,
    state: "WV",
    type: "County",
    locationid: "54037",
    county: "Jefferson",
  },
  {
    fips: "54053",
    count: 11,
    state: "WV",
    type: "County",
    locationid: "54053",
    county: "Mason",
  },
  {
    fips: "55021",
    count: 11,
    state: "WI",
    type: "County",
    locationid: "55021",
    county: "Columbia",
  },
  {
    fips: "01049",
    count: 10,
    state: "AL",
    type: "County",
    locationid: "01049",
    county: "De Kalb",
  },
  {
    fips: "01051",
    count: 10,
    state: "AL",
    type: "County",
    locationid: "01051",
    county: "Elmore",
  },
  {
    fips: "01071",
    count: 10,
    state: "AL",
    type: "County",
    locationid: "01071",
    county: "Jackson",
  },
  {
    fips: "12045",
    count: 10,
    state: "FL",
    type: "County",
    locationid: "12045",
    county: "Gulf",
  },
  {
    fips: "13137",
    count: 10,
    state: "GA",
    type: "County",
    locationid: "13137",
    county: "Habersham",
  },
  {
    fips: "13157",
    count: 10,
    state: "GA",
    type: "County",
    locationid: "13157",
    county: "Jackson",
  },
  {
    fips: "16031",
    count: 10,
    state: "ID",
    type: "County",
    locationid: "16031",
    county: "Cassia",
  },
  {
    fips: "17127",
    count: 10,
    state: "IL",
    type: "County",
    locationid: "17127",
    county: "Massac",
  },
  {
    fips: "17177",
    count: 9,
    state: "IL",
    type: "County",
    locationid: "17177",
    county: "Stephenson",
  },
  {
    fips: "17185",
    count: 10,
    state: "IL",
    type: "County",
    locationid: "17185",
    county: "Wabash",
  },
  {
    fips: "17203",
    count: 10,
    state: "IL",
    type: "County",
    locationid: "17203",
    county: "Woodford",
  },
  {
    fips: "18031",
    count: 10,
    state: "IN",
    type: "County",
    locationid: "18031",
    county: "Decatur",
  },
  {
    fips: "18061",
    count: 10,
    state: "IN",
    type: "County",
    locationid: "18061",
    county: "Harrison",
  },
  {
    fips: "18113",
    count: 10,
    state: "IN",
    type: "County",
    locationid: "18113",
    county: "Noble",
  },
  {
    fips: "19191",
    count: 10,
    state: "IA",
    type: "County",
    locationid: "19191",
    county: "Winneshiek",
  },
  {
    fips: "21101",
    count: 9,
    state: "KY",
    type: "County",
    locationid: "21101",
    county: "Henderson",
  },
  {
    fips: "21143",
    count: 10,
    state: "KY",
    type: "County",
    locationid: "21143",
    county: "Lyon",
  },
  {
    fips: "26013",
    count: 10,
    state: "MI",
    type: "County",
    locationid: "26013",
    county: "Baraga",
  },
  {
    fips: "27103",
    count: 10,
    state: "MN",
    type: "County",
    locationid: "27103",
    county: "Nicollet",
  },
  {
    fips: "28043",
    count: 10,
    state: "MS",
    type: "County",
    locationid: "28043",
    county: "Grenada",
  },
  {
    fips: "28163",
    count: 10,
    state: "MS",
    type: "County",
    locationid: "28163",
    county: "Yazoo",
  },
  {
    fips: "29159",
    count: 10,
    state: "MO",
    type: "County",
    locationid: "29159",
    county: "Pettis",
  },
  {
    fips: "34033",
    count: 10,
    state: "NJ",
    type: "County",
    locationid: "34033",
    county: "Salem",
  },
  {
    fips: "35029",
    count: 10,
    state: "NM",
    type: "County",
    locationid: "35029",
    county: "Luna",
  },
  {
    fips: "36031",
    count: 10,
    state: "NY",
    type: "County",
    locationid: "36031",
    county: "Essex",
  },
  {
    fips: "36121",
    count: 10,
    state: "NY",
    type: "County",
    locationid: "36121",
    county: "Wyoming",
  },
  {
    fips: "37009",
    count: 10,
    state: "NC",
    type: "County",
    locationid: "37009",
    county: "Ashe",
  },
  {
    fips: "37055",
    count: 10,
    state: "NC",
    type: "County",
    locationid: "37055",
    county: "Dare",
  },
  {
    fips: "37153",
    count: 10,
    state: "NC",
    type: "County",
    locationid: "37153",
    county: "Richmond",
  },
  {
    fips: "39031",
    count: 10,
    state: "OH",
    type: "County",
    locationid: "39031",
    county: "Coshocton",
  },
  {
    fips: "39051",
    count: 10,
    state: "OH",
    type: "County",
    locationid: "39051",
    county: "Fulton",
  },
  {
    fips: "45009",
    count: 10,
    state: "SC",
    type: "County",
    locationid: "45009",
    county: "Bamberg",
  },
  {
    fips: "45023",
    count: 10,
    state: "SC",
    type: "County",
    locationid: "45023",
    county: "Chester",
  },
  {
    fips: "45025",
    count: 10,
    state: "SC",
    type: "County",
    locationid: "45025",
    county: "Chesterfield",
  },
  {
    fips: "47017",
    count: 10,
    state: "TN",
    type: "County",
    locationid: "47017",
    county: "Carroll",
  },
  {
    fips: "47123",
    count: 10,
    state: "TN",
    type: "County",
    locationid: "47123",
    county: "Monroe",
  },
  {
    fips: "47131",
    count: 10,
    state: "TN",
    type: "County",
    locationid: "47131",
    county: "Obion",
  },
  {
    fips: "48259",
    count: 10,
    state: "TX",
    type: "County",
    locationid: "48259",
    county: "Kendall",
  },
  {
    fips: "48373",
    count: 10,
    state: "TX",
    type: "County",
    locationid: "48373",
    county: "Polk",
  },
  {
    fips: "50015",
    count: 10,
    state: "VT",
    type: "County",
    locationid: "50015",
    county: "Lamoille",
  },
  {
    fips: "51195",
    count: 10,
    state: "VA",
    type: "County",
    locationid: "51195",
    county: "Wise",
  },
  {
    fips: "53003",
    count: 10,
    state: "WA",
    type: "County",
    locationid: "53003",
    county: "Asotin",
  },
  {
    fips: "53039",
    count: 10,
    state: "WA",
    type: "County",
    locationid: "53039",
    county: "Klickitat",
  },
  {
    fips: "55043",
    count: 10,
    state: "WI",
    type: "County",
    locationid: "55043",
    county: "Grant",
  },
  {
    fips: "55047",
    count: 9,
    state: "WI",
    type: "County",
    locationid: "55047",
    county: "Green Lake",
  },
  {
    fips: "55055",
    count: 10,
    state: "WI",
    type: "County",
    locationid: "55055",
    county: "Jefferson",
  },
  {
    fips: "01017",
    count: 9,
    state: "AL",
    type: "County",
    locationid: "01017",
    county: "Chambers",
  },
  {
    fips: "05003",
    count: 7,
    state: "AR",
    type: "County",
    locationid: "05003",
    county: "Ashley",
  },
  {
    fips: "05091",
    count: 9,
    state: "AR",
    type: "County",
    locationid: "05091",
    county: "Miller",
  },
  {
    fips: "05093",
    count: 9,
    state: "AR",
    type: "County",
    locationid: "05093",
    county: "Mississippi",
  },
  {
    fips: "08075",
    count: 9,
    state: "CO",
    type: "County",
    locationid: "08075",
    county: "Logan",
  },
  {
    fips: "16065",
    count: 8,
    state: "ID",
    type: "County",
    locationid: "16065",
    county: "Madison",
  },
  {
    fips: "17075",
    count: 9,
    state: "IL",
    type: "County",
    locationid: "17075",
    county: "Iroquois",
  },
  {
    fips: "18075",
    count: 9,
    state: "IN",
    type: "County",
    locationid: "18075",
    county: "Jay",
  },
  {
    fips: "18133",
    count: 9,
    state: "IN",
    type: "County",
    locationid: "18133",
    county: "Putnam",
  },
  {
    fips: "18137",
    count: 8,
    state: "IN",
    type: "County",
    locationid: "18137",
    county: "Ripley",
  },
  {
    fips: "19087",
    count: 9,
    state: "IA",
    type: "County",
    locationid: "19087",
    county: "Henry",
  },
  {
    fips: "22119",
    count: 9,
    state: "LA",
    type: "County",
    locationid: "22119",
    county: "Webster",
  },
  {
    fips: "23007",
    count: 9,
    state: "ME",
    type: "County",
    locationid: "23007",
    county: "Franklin",
  },
  {
    fips: "26035",
    count: 9,
    state: "MI",
    type: "County",
    locationid: "26035",
    county: "Clare",
  },
  {
    fips: "26157",
    count: 8,
    state: "MI",
    type: "County",
    locationid: "26157",
    county: "Tuscola",
  },
  {
    fips: "27059",
    count: 8,
    state: "MN",
    type: "County",
    locationid: "27059",
    county: "Isanti",
  },
  {
    fips: "27095",
    count: 8,
    state: "MN",
    type: "County",
    locationid: "27095",
    county: "Mille Lacs",
  },
  {
    fips: "27099",
    count: 9,
    state: "MN",
    type: "County",
    locationid: "27099",
    county: "Mower",
  },
  {
    fips: "28085",
    count: 9,
    state: "MS",
    type: "County",
    locationid: "28085",
    county: "Lincoln",
  },
  {
    fips: "29107",
    count: 9,
    state: "MO",
    type: "County",
    locationid: "29107",
    county: "Lafayette",
  },
  {
    fips: "32005",
    count: 9,
    state: "NV",
    type: "County",
    locationid: "32005",
    county: "Douglas",
  },
  {
    fips: "33019",
    count: 9,
    state: "NH",
    type: "County",
    locationid: "33019",
    county: "Sullivan",
  },
  {
    fips: "36043",
    count: 9,
    state: "NY",
    type: "County",
    locationid: "36043",
    county: "Herkimer",
  },
  {
    fips: "39131",
    count: 9,
    state: "OH",
    type: "County",
    locationid: "39131",
    county: "Pike",
  },
  {
    fips: "39135",
    count: 9,
    state: "OH",
    type: "County",
    locationid: "39135",
    county: "Preble",
  },
  {
    fips: "40013",
    count: 9,
    state: "OK",
    type: "County",
    locationid: "40013",
    county: "Bryan",
  },
  {
    fips: "40041",
    count: 9,
    state: "OK",
    type: "County",
    locationid: "40041",
    county: "Delaware",
  },
  {
    fips: "40087",
    count: 9,
    state: "OK",
    type: "County",
    locationid: "40087",
    county: "Mcclain",
  },
  {
    fips: "40139",
    count: 9,
    state: "OK",
    type: "County",
    locationid: "40139",
    county: "Texas",
  },
  {
    fips: "42025",
    count: 9,
    state: "PA",
    type: "County",
    locationid: "42025",
    county: "Carbon",
  },
  {
    fips: "42061",
    count: 9,
    state: "PA",
    type: "County",
    locationid: "42061",
    county: "Huntingdon",
  },
  {
    fips: "46093",
    count: 9,
    state: "SD",
    type: "County",
    locationid: "46093",
    county: "Meade",
  },
  {
    fips: "47013",
    count: 9,
    state: "TN",
    type: "County",
    locationid: "47013",
    county: "Campbell",
  },
  {
    fips: "47055",
    count: 9,
    state: "TN",
    type: "County",
    locationid: "47055",
    county: "Giles",
  },
  {
    fips: "48067",
    count: 9,
    state: "TX",
    type: "County",
    locationid: "48067",
    county: "Cass",
  },
  {
    fips: "48223",
    count: 9,
    state: "TX",
    type: "County",
    locationid: "48223",
    county: "Hopkins",
  },
  {
    fips: "48409",
    count: 9,
    state: "TX",
    type: "County",
    locationid: "48409",
    county: "San Patricio",
  },
  {
    fips: "50025",
    count: 9,
    state: "VT",
    type: "County",
    locationid: "50025",
    county: "Windham",
  },
  {
    fips: "51137",
    count: 9,
    state: "VA",
    type: "County",
    locationid: "51137",
    county: "Orange",
  },
  {
    fips: "51143",
    count: 9,
    state: "VA",
    type: "County",
    locationid: "51143",
    county: "Pittsylvania",
  },
  {
    fips: "51790",
    count: 9,
    state: "VA",
    type: "County",
    locationid: "51790",
    county: "Staunton City",
  },
  {
    fips: "55061",
    count: 8,
    state: "WI",
    type: "County",
    locationid: "55061",
    county: "Kewaunee",
  },
  {
    fips: "56001",
    count: 9,
    state: "WY",
    type: "County",
    locationid: "56001",
    county: "Albany",
  },
  {
    fips: "01053",
    count: 8,
    state: "AL",
    type: "County",
    locationid: "01053",
    county: "Escambia",
  },
  {
    fips: "01113",
    count: 8,
    state: "AL",
    type: "County",
    locationid: "01113",
    county: "Russell",
  },
  {
    fips: "05133",
    count: 8,
    state: "AR",
    type: "County",
    locationid: "05133",
    county: "Sevier",
  },
  {
    fips: "05135",
    count: 8,
    state: "AR",
    type: "County",
    locationid: "05135",
    county: "Sharp",
  },
  {
    fips: "13119",
    count: 8,
    state: "GA",
    type: "County",
    locationid: "13119",
    county: "Franklin",
  },
  {
    fips: "13261",
    count: 7,
    state: "GA",
    type: "County",
    locationid: "13261",
    county: "Sumter",
  },
  {
    fips: "17039",
    count: 8,
    state: "IL",
    type: "County",
    locationid: "17039",
    county: "Dewitt",
  },
  {
    fips: "17093",
    count: 8,
    state: "IL",
    type: "County",
    locationid: "17093",
    county: "Kendall",
  },
  {
    fips: "17103",
    count: 8,
    state: "IL",
    type: "County",
    locationid: "17103",
    county: "Lee",
  },
  {
    fips: "17117",
    count: 8,
    state: "IL",
    type: "County",
    locationid: "17117",
    county: "Macoupin",
  },
  {
    fips: "18087",
    count: 8,
    state: "IN",
    type: "County",
    locationid: "18087",
    county: "Lagrange",
  },
  {
    fips: "18153",
    count: 8,
    state: "IN",
    type: "County",
    locationid: "18153",
    county: "Sullivan",
  },
  {
    fips: "19099",
    count: 8,
    state: "IA",
    type: "County",
    locationid: "19099",
    county: "Jasper",
  },
  {
    fips: "20125",
    count: 8,
    state: "KS",
    type: "County",
    locationid: "20125",
    county: "Montgomery",
  },
  {
    fips: "21121",
    count: 8,
    state: "KY",
    type: "County",
    locationid: "21121",
    county: "Knox",
  },
  {
    fips: "21177",
    count: 8,
    state: "KY",
    type: "County",
    locationid: "21177",
    county: "Muhlenberg",
  },
  {
    fips: "22101",
    count: 8,
    state: "LA",
    type: "County",
    locationid: "22101",
    county: "Saint Mary",
  },
  {
    fips: "24019",
    count: 8,
    state: "MD",
    type: "County",
    locationid: "24019",
    county: "Dorchester",
  },
  {
    fips: "27057",
    count: 7,
    state: "MN",
    type: "County",
    locationid: "27057",
    county: "Hubbard",
  },
  {
    fips: "28093",
    count: 8,
    state: "MS",
    type: "County",
    locationid: "28093",
    county: "Marshall",
  },
  {
    fips: "29001",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29001",
    county: "Adair",
  },
  {
    fips: "29015",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29015",
    county: "Benton",
  },
  {
    fips: "29049",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29049",
    county: "Clinton",
  },
  {
    fips: "29069",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29069",
    county: "Dunklin",
  },
  {
    fips: "29109",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29109",
    county: "Lawrence",
  },
  {
    fips: "29169",
    count: 8,
    state: "MO",
    type: "County",
    locationid: "29169",
    county: "Pulaski",
  },
  {
    fips: "31047",
    count: 8,
    state: "NE",
    type: "County",
    locationid: "31047",
    county: "Dawson",
  },
  {
    fips: "35025",
    count: 8,
    state: "NM",
    type: "County",
    locationid: "35025",
    county: "Lea",
  },
  {
    fips: "36017",
    count: 8,
    state: "NY",
    type: "County",
    locationid: "36017",
    county: "Chenango",
  },
  {
    fips: "36025",
    count: 8,
    state: "NY",
    type: "County",
    locationid: "36025",
    county: "Delaware",
  },
  {
    fips: "36095",
    count: 8,
    state: "NY",
    type: "County",
    locationid: "36095",
    county: "Schoharie",
  },
  {
    fips: "37069",
    count: 8,
    state: "NC",
    type: "County",
    locationid: "37069",
    county: "Franklin",
  },
  {
    fips: "37103",
    count: 8,
    state: "NC",
    type: "County",
    locationid: "37103",
    county: "Jones",
  },
  {
    fips: "38071",
    count: 7,
    state: "ND",
    type: "County",
    locationid: "38071",
    county: "Ramsey",
  },
  {
    fips: "39069",
    count: 8,
    state: "OH",
    type: "County",
    locationid: "39069",
    county: "Henry",
  },
  {
    fips: "39127",
    count: 8,
    state: "OH",
    type: "County",
    locationid: "39127",
    county: "Perry",
  },
  {
    fips: "39137",
    count: 8,
    state: "OH",
    type: "County",
    locationid: "39137",
    county: "Putnam",
  },
  {
    fips: "40037",
    count: 8,
    state: "OK",
    type: "County",
    locationid: "40037",
    county: "Creek",
  },
  {
    fips: "40097",
    count: 8,
    state: "OK",
    type: "County",
    locationid: "40097",
    county: "Mayes",
  },
  {
    fips: "40111",
    count: 8,
    state: "OK",
    type: "County",
    locationid: "40111",
    county: "Okmulgee",
  },
  {
    fips: "40133",
    count: 8,
    state: "OK",
    type: "County",
    locationid: "40133",
    county: "Seminole",
  },
  {
    fips: "42047",
    count: 8,
    state: "PA",
    type: "County",
    locationid: "42047",
    county: "Elk",
  },
  {
    fips: "42059",
    count: 8,
    state: "PA",
    type: "County",
    locationid: "42059",
    county: "Greene",
  },
  {
    fips: "42065",
    count: 8,
    state: "PA",
    type: "County",
    locationid: "42065",
    county: "Jefferson",
  },
  {
    fips: "46127",
    count: 7,
    state: "SD",
    type: "County",
    locationid: "46127",
    county: "Union",
  },
  {
    fips: "47021",
    count: 8,
    state: "TN",
    type: "County",
    locationid: "47021",
    county: "Cheatham",
  },
  {
    fips: "48025",
    count: 8,
    state: "TX",
    type: "County",
    locationid: "48025",
    county: "Bee",
  },
  {
    fips: "48073",
    count: 8,
    state: "TX",
    type: "County",
    locationid: "48073",
    county: "Cherokee",
  },
  {
    fips: "51001",
    count: 8,
    state: "VA",
    type: "County",
    locationid: "51001",
    county: "Accomack",
  },
  {
    fips: "51105",
    count: 8,
    state: "VA",
    type: "County",
    locationid: "51105",
    county: "Lee",
  },
  {
    fips: "53047",
    count: 8,
    state: "WA",
    type: "County",
    locationid: "53047",
    county: "Okanogan",
  },
  {
    fips: "54001",
    count: 8,
    state: "WV",
    type: "County",
    locationid: "54001",
    county: "Barbour",
  },
  {
    fips: "54067",
    count: 8,
    state: "WV",
    type: "County",
    locationid: "54067",
    county: "Nicholas",
  },
  {
    fips: "55029",
    count: 8,
    state: "WI",
    type: "County",
    locationid: "55029",
    county: "Door",
  },
  {
    fips: "01045",
    count: 7,
    state: "AL",
    type: "County",
    locationid: "01045",
    county: "Dale",
  },
  {
    fips: "01057",
    count: 7,
    state: "AL",
    type: "County",
    locationid: "01057",
    county: "Fayette",
  },
  {
    fips: "01059",
    count: 7,
    state: "AL",
    type: "County",
    locationid: "01059",
    county: "Franklin",
  },
  {
    fips: "01099",
    count: 7,
    state: "AL",
    type: "County",
    locationid: "01099",
    county: "Monroe",
  },
  {
    fips: "01109",
    count: 7,
    state: "AL",
    type: "County",
    locationid: "01109",
    county: "Pike",
  },
  {
    fips: "05137",
    count: 7,
    state: "AR",
    type: "County",
    locationid: "05137",
    county: "Stone",
  },
  {
    fips: "06015",
    count: 7,
    state: "CA",
    type: "County",
    locationid: "06015",
    county: "Del Norte",
  },
  {
    fips: "06063",
    count: 7,
    state: "CA",
    type: "County",
    locationid: "06063",
    county: "Plumas",
  },
  {
    fips: "13107",
    count: 7,
    state: "GA",
    type: "County",
    locationid: "13107",
    county: "Emanuel",
  },
  {
    fips: "13229",
    count: 7,
    state: "GA",
    type: "County",
    locationid: "13229",
    county: "Pierce",
  },
  {
    fips: "13233",
    count: 7,
    state: "GA",
    type: "County",
    locationid: "13233",
    county: "Polk",
  },
  {
    fips: "13305",
    count: 7,
    state: "GA",
    type: "County",
    locationid: "13305",
    county: "Wayne",
  },
  {
    fips: "17109",
    count: 7,
    state: "IL",
    type: "County",
    locationid: "17109",
    county: "Mcdonough",
  },
  {
    fips: "18069",
    count: 7,
    state: "IN",
    type: "County",
    locationid: "18069",
    county: "Huntington",
  },
  {
    fips: "18077",
    count: 7,
    state: "IN",
    type: "County",
    locationid: "18077",
    county: "Jefferson",
  },
  {
    fips: "18159",
    count: 7,
    state: "IN",
    type: "County",
    locationid: "18159",
    county: "Tipton",
  },
  {
    fips: "18179",
    count: 7,
    state: "IN",
    type: "County",
    locationid: "18179",
    county: "Wells",
  },
  {
    fips: "19017",
    count: 7,
    state: "IA",
    type: "County",
    locationid: "19017",
    county: "Bremer",
  },
  {
    fips: "19029",
    count: 7,
    state: "IA",
    type: "County",
    locationid: "19029",
    county: "Cass",
  },
  {
    fips: "19059",
    count: 7,
    state: "IA",
    type: "County",
    locationid: "19059",
    county: "Dickinson",
  },
  {
    fips: "20031",
    count: 7,
    state: "KS",
    type: "County",
    locationid: "20031",
    county: "Coffey",
  },
  {
    fips: "20059",
    count: 7,
    state: "KS",
    type: "County",
    locationid: "20059",
    county: "Franklin",
  },
  {
    fips: "20087",
    count: 7,
    state: "KS",
    type: "County",
    locationid: "20087",
    county: "Jefferson",
  },
  {
    fips: "20113",
    count: 7,
    state: "KS",
    type: "County",
    locationid: "20113",
    county: "Mcpherson",
  },
  {
    fips: "21025",
    count: 7,
    state: "KY",
    type: "County",
    locationid: "21025",
    county: "Breathitt",
  },
  {
    fips: "21089",
    count: 7,
    state: "KY",
    type: "County",
    locationid: "21089",
    county: "Greenup",
  },
  {
    fips: "21183",
    count: 7,
    state: "KY",
    type: "County",
    locationid: "21183",
    county: "Ohio",
  },
  {
    fips: "22003",
    count: 7,
    state: "LA",
    type: "County",
    locationid: "22003",
    county: "Allen",
  },
  {
    fips: "22029",
    count: 7,
    state: "LA",
    type: "County",
    locationid: "22029",
    county: "Concordia",
  },
  {
    fips: "23009",
    count: 7,
    state: "ME",
    type: "County",
    locationid: "23009",
    county: "Hancock",
  },
  {
    fips: "26011",
    count: 7,
    state: "MI",
    type: "County",
    locationid: "26011",
    county: "Arenac",
  },
  {
    fips: "26037",
    count: 7,
    state: "MI",
    type: "County",
    locationid: "26037",
    county: "Clinton",
  },
  {
    fips: "27113",
    count: 7,
    state: "MN",
    type: "County",
    locationid: "27113",
    county: "Pennington",
  },
  {
    fips: "28007",
    count: 7,
    state: "MS",
    type: "County",
    locationid: "28007",
    county: "Attala",
  },
  {
    fips: "28051",
    count: 7,
    state: "MS",
    type: "County",
    locationid: "28051",
    county: "Holmes",
  },
  {
    fips: "28099",
    count: 7,
    state: "MS",
    type: "County",
    locationid: "28099",
    county: "Neshoba",
  },
  {
    fips: "28107",
    count: 7,
    state: "MS",
    type: "County",
    locationid: "28107",
    county: "Panola",
  },
  {
    fips: "29199",
    count: 7,
    state: "MO",
    type: "County",
    locationid: "29199",
    county: "Scotland",
  },
  {
    fips: "29201",
    count: 7,
    state: "MO",
    type: "County",
    locationid: "29201",
    county: "Scott",
  },
  {
    fips: "29215",
    count: 7,
    state: "MO",
    type: "County",
    locationid: "29215",
    county: "Texas",
  },
  {
    fips: "30041",
    count: 7,
    state: "MT",
    type: "County",
    locationid: "30041",
    county: "Hill",
  },
  {
    fips: "35006",
    count: 7,
    state: "NM",
    type: "County",
    locationid: "35006",
    county: "Cibola",
  },
  {
    fips: "35009",
    count: 7,
    state: "NM",
    type: "County",
    locationid: "35009",
    county: "Curry",
  },
  {
    fips: "36049",
    count: 7,
    state: "NY",
    type: "County",
    locationid: "36049",
    county: "Lewis",
  },
  {
    fips: "37169",
    count: 7,
    state: "NC",
    type: "County",
    locationid: "37169",
    county: "Stokes",
  },
  {
    fips: "39073",
    count: 7,
    state: "OH",
    type: "County",
    locationid: "39073",
    county: "Hocking",
  },
  {
    fips: "39087",
    count: 7,
    state: "OH",
    type: "County",
    locationid: "39087",
    county: "Lawrence",
  },
  {
    fips: "40001",
    count: 7,
    state: "OK",
    type: "County",
    locationid: "40001",
    county: "Adair",
  },
  {
    fips: "40015",
    count: 7,
    state: "OK",
    type: "County",
    locationid: "40015",
    county: "Caddo",
  },
  {
    fips: "40035",
    count: 7,
    state: "OK",
    type: "County",
    locationid: "40035",
    county: "Craig",
  },
  {
    fips: "40049",
    count: 7,
    state: "OK",
    type: "County",
    locationid: "40049",
    county: "Garvin",
  },
  {
    fips: "40145",
    count: 7,
    state: "OK",
    type: "County",
    locationid: "40145",
    county: "Wagoner",
  },
  {
    fips: "45029",
    count: 7,
    state: "SC",
    type: "County",
    locationid: "45029",
    county: "Colleton",
  },
  {
    fips: "47103",
    count: 7,
    state: "TN",
    type: "County",
    locationid: "47103",
    county: "Lincoln",
  },
  {
    fips: "47109",
    count: 7,
    state: "TN",
    type: "County",
    locationid: "47109",
    county: "Mcnairy",
  },
  {
    fips: "48199",
    count: 7,
    state: "TX",
    type: "County",
    locationid: "48199",
    county: "Hardin",
  },
  {
    fips: "48293",
    count: 7,
    state: "TX",
    type: "County",
    locationid: "48293",
    county: "Limestone",
  },
  {
    fips: "48325",
    count: 7,
    state: "TX",
    type: "County",
    locationid: "48325",
    county: "Medina",
  },
  {
    fips: "48465",
    count: 7,
    state: "TX",
    type: "County",
    locationid: "48465",
    county: "Val Verde",
  },
  {
    fips: "50019",
    count: 7,
    state: "VT",
    type: "County",
    locationid: "50019",
    county: "Orleans",
  },
  {
    fips: "51005",
    count: 7,
    state: "VA",
    type: "County",
    locationid: "51005",
    county: "Alleghany",
  },
  {
    fips: "54035",
    count: 7,
    state: "WV",
    type: "County",
    locationid: "54035",
    county: "Jackson",
  },
  {
    fips: "54077",
    count: 7,
    state: "WV",
    type: "County",
    locationid: "54077",
    county: "Preston",
  },
  {
    fips: "54097",
    count: 7,
    state: "WV",
    type: "County",
    locationid: "54097",
    county: "Upshur",
  },
  {
    fips: "55137",
    count: 5,
    state: "WI",
    type: "County",
    locationid: "55137",
    county: "Waushara",
  },
  {
    fips: "01021",
    count: 6,
    state: "AL",
    type: "County",
    locationid: "01021",
    county: "Chilton",
  },
  {
    fips: "01041",
    count: 6,
    state: "AL",
    type: "County",
    locationid: "01041",
    county: "Crenshaw",
  },
  {
    fips: "05103",
    count: 6,
    state: "AR",
    type: "County",
    locationid: "05103",
    county: "Ouachita",
  },
  {
    fips: "05125",
    count: 6,
    state: "AR",
    type: "County",
    locationid: "05125",
    county: "Saline",
  },
  {
    fips: "08107",
    count: 6,
    state: "CO",
    type: "County",
    locationid: "08107",
    county: "Routt",
  },
  {
    fips: "12121",
    count: 6,
    state: "FL",
    type: "County",
    locationid: "12121",
    county: "Suwannee",
  },
  {
    fips: "13001",
    count: 6,
    state: "GA",
    type: "County",
    locationid: "13001",
    county: "Appling",
  },
  {
    fips: "13043",
    count: 6,
    state: "GA",
    type: "County",
    locationid: "13043",
    county: "Candler",
  },
  {
    fips: "13143",
    count: 6,
    state: "GA",
    type: "County",
    locationid: "13143",
    county: "Haralson",
  },
  {
    fips: "13295",
    count: 6,
    state: "GA",
    type: "County",
    locationid: "13295",
    county: "Walker",
  },
  {
    fips: "13303",
    count: 6,
    state: "GA",
    type: "County",
    locationid: "13303",
    county: "Washington",
  },
  {
    fips: "17141",
    count: 6,
    state: "IL",
    type: "County",
    locationid: "17141",
    county: "Ogle",
  },
  {
    fips: "17191",
    count: 6,
    state: "IL",
    type: "County",
    locationid: "17191",
    county: "Wayne",
  },
  {
    fips: "18009",
    count: 6,
    state: "IN",
    type: "County",
    locationid: "18009",
    county: "Blackford",
  },
  {
    fips: "18107",
    count: 6,
    state: "IN",
    type: "County",
    locationid: "18107",
    county: "Montgomery",
  },
  {
    fips: "18149",
    count: 6,
    state: "IN",
    type: "County",
    locationid: "18149",
    county: "Starke",
  },
  {
    fips: "19065",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19065",
    county: "Fayette",
  },
  {
    fips: "19079",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19079",
    county: "Hamilton",
  },
  {
    fips: "19101",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19101",
    county: "Jefferson",
  },
  {
    fips: "19167",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19167",
    county: "Sioux",
  },
  {
    fips: "19181",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19181",
    county: "Warren",
  },
  {
    fips: "19183",
    count: 6,
    state: "IA",
    type: "County",
    locationid: "19183",
    county: "Washington",
  },
  {
    fips: "20061",
    count: 6,
    state: "KS",
    type: "County",
    locationid: "20061",
    county: "Geary",
  },
  {
    fips: "20085",
    count: 6,
    state: "KS",
    type: "County",
    locationid: "20085",
    county: "Jackson",
  },
  {
    fips: "21049",
    count: 6,
    state: "KY",
    type: "County",
    locationid: "21049",
    county: "Clark",
  },
  {
    fips: "21069",
    count: 6,
    state: "KY",
    type: "County",
    locationid: "21069",
    county: "Fleming",
  },
  {
    fips: "21157",
    count: 6,
    state: "KY",
    type: "County",
    locationid: "21157",
    county: "Marshall",
  },
  {
    fips: "21239",
    count: 6,
    state: "KY",
    type: "County",
    locationid: "21239",
    county: "Woodford",
  },
  {
    fips: "22053",
    count: 6,
    state: "LA",
    type: "County",
    locationid: "22053",
    county: "Jefferson Davis",
  },
  {
    fips: "22059",
    count: 6,
    state: "LA",
    type: "County",
    locationid: "22059",
    county: "La Salle",
  },
  {
    fips: "22125",
    count: 6,
    state: "LA",
    type: "County",
    locationid: "22125",
    county: "West Feliciana",
  },
  {
    fips: "24011",
    count: 6,
    state: "MD",
    type: "County",
    locationid: "24011",
    county: "Caroline",
  },
  {
    fips: "26153",
    count: 6,
    state: "MI",
    type: "County",
    locationid: "26153",
    county: "Schoolcraft",
  },
  {
    fips: "27085",
    count: 6,
    state: "MN",
    type: "County",
    locationid: "27085",
    county: "Mcleod",
  },
  {
    fips: "27117",
    count: 6,
    state: "MN",
    type: "County",
    locationid: "27117",
    county: "Pipestone",
  },
  {
    fips: "27141",
    count: 6,
    state: "MN",
    type: "County",
    locationid: "27141",
    county: "Sherburne",
  },
  {
    fips: "27157",
    count: 6,
    state: "MN",
    type: "County",
    locationid: "27157",
    county: "Wabasha",
  },
  {
    fips: "28029",
    count: 6,
    state: "MS",
    type: "County",
    locationid: "28029",
    county: "Copiah",
  },
  {
    fips: "28109",
    count: 6,
    state: "MS",
    type: "County",
    locationid: "28109",
    county: "Pearl River",
  },
  {
    fips: "28137",
    count: 6,
    state: "MS",
    type: "County",
    locationid: "28137",
    county: "Tate",
  },
  {
    fips: "29007",
    count: 6,
    state: "MO",
    type: "County",
    locationid: "29007",
    county: "Audrain",
  },
  {
    fips: "29027",
    count: 6,
    state: "MO",
    type: "County",
    locationid: "29027",
    county: "Callaway",
  },
  {
    fips: "29105",
    count: 6,
    state: "MO",
    type: "County",
    locationid: "29105",
    county: "Laclede",
  },
  {
    fips: "29145",
    count: 6,
    state: "MO",
    type: "County",
    locationid: "29145",
    county: "Newton",
  },
  {
    fips: "30053",
    count: 6,
    state: "MT",
    type: "County",
    locationid: "30053",
    county: "Lincoln",
  },
  {
    fips: "30081",
    count: 6,
    state: "MT",
    type: "County",
    locationid: "30081",
    county: "Ravalli",
  },
  {
    fips: "32007",
    count: 6,
    state: "NV",
    type: "County",
    locationid: "32007",
    county: "Elko",
  },
  {
    fips: "35028",
    count: 6,
    state: "NM",
    type: "County",
    locationid: "35028",
    county: "Los Alamos",
  },
  {
    fips: "36107",
    count: 6,
    state: "NY",
    type: "County",
    locationid: "36107",
    county: "Tioga",
  },
  {
    fips: "37149",
    count: 6,
    state: "NC",
    type: "County",
    locationid: "37149",
    county: "Polk",
  },
  {
    fips: "37175",
    count: 6,
    state: "NC",
    type: "County",
    locationid: "37175",
    county: "Transylvania",
  },
  {
    fips: "39123",
    count: 6,
    state: "OH",
    type: "County",
    locationid: "39123",
    county: "Ottawa",
  },
  {
    fips: "40089",
    count: 6,
    state: "OK",
    type: "County",
    locationid: "40089",
    county: "Mccurtain",
  },
  {
    fips: "41027",
    count: 6,
    state: "OR",
    type: "County",
    locationid: "41027",
    county: "Hood River",
  },
  {
    fips: "42115",
    count: 6,
    state: "PA",
    type: "County",
    locationid: "42115",
    county: "Susquehanna",
  },
  {
    fips: "42131",
    count: 6,
    state: "PA",
    type: "County",
    locationid: "42131",
    county: "Wyoming",
  },
  {
    fips: "45027",
    count: 6,
    state: "SC",
    type: "County",
    locationid: "45027",
    county: "Clarendon",
  },
  {
    fips: "48015",
    count: 6,
    state: "TX",
    type: "County",
    locationid: "48015",
    county: "Austin",
  },
  {
    fips: "48133",
    count: 6,
    state: "TX",
    type: "County",
    locationid: "48133",
    county: "Eastland",
  },
  {
    fips: "48143",
    count: 6,
    state: "TX",
    type: "County",
    locationid: "48143",
    county: "Erath",
  },
  {
    fips: "48337",
    count: 6,
    state: "TX",
    type: "County",
    locationid: "48337",
    county: "Montague",
  },
  {
    fips: "51023",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51023",
    county: "Botetourt",
  },
  {
    fips: "51027",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51027",
    county: "Buchanan",
  },
  {
    fips: "51077",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51077",
    county: "Grayson",
  },
  {
    fips: "51081",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51081",
    county: "Greensville",
  },
  {
    fips: "51173",
    count: 5,
    state: "VA",
    type: "County",
    locationid: "51173",
    county: "Smyth",
  },
  {
    fips: "51620",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51620",
    county: "Franklin City",
  },
  {
    fips: "51640",
    count: 6,
    state: "VA",
    type: "County",
    locationid: "51640",
    county: "Galax City",
  },
  {
    fips: "53051",
    count: 6,
    state: "WA",
    type: "County",
    locationid: "53051",
    county: "Pend Oreille",
  },
  {
    fips: "54089",
    count: 6,
    state: "WV",
    type: "County",
    locationid: "54089",
    county: "Summers",
  },
  {
    fips: "55031",
    count: 6,
    state: "WI",
    type: "County",
    locationid: "55031",
    county: "Douglas",
  },
  {
    fips: "55057",
    count: 6,
    state: "WI",
    type: "County",
    locationid: "55057",
    county: "Juneau",
  },
  {
    fips: "55125",
    count: 6,
    state: "WI",
    type: "County",
    locationid: "55125",
    county: "Vilas",
  },
  {
    fips: "01093",
    count: 5,
    state: "AL",
    type: "County",
    locationid: "01093",
    county: "Marion",
  },
  {
    fips: "05023",
    count: 5,
    state: "AR",
    type: "County",
    locationid: "05023",
    county: "Cleburne",
  },
  {
    fips: "05029",
    count: 5,
    state: "AR",
    type: "County",
    locationid: "05029",
    county: "Conway",
  },
  {
    fips: "05123",
    count: 5,
    state: "AR",
    type: "County",
    locationid: "05123",
    county: "Saint Francis",
  },
  {
    fips: "06027",
    count: 5,
    state: "CA",
    type: "County",
    locationid: "06027",
    county: "Inyo",
  },
  {
    fips: "06049",
    count: 5,
    state: "CA",
    type: "County",
    locationid: "06049",
    county: "Modoc",
  },
  {
    fips: "06093",
    count: 5,
    state: "CA",
    type: "County",
    locationid: "06093",
    county: "Siskiyou",
  },
  {
    fips: "08043",
    count: 5,
    state: "CO",
    type: "County",
    locationid: "08043",
    county: "Fremont",
  },
  {
    fips: "08087",
    count: 5,
    state: "CO",
    type: "County",
    locationid: "08087",
    county: "Morgan",
  },
  {
    fips: "08097",
    count: 5,
    state: "CO",
    type: "County",
    locationid: "08097",
    county: "Pitkin",
  },
  {
    fips: "12027",
    count: 5,
    state: "FL",
    type: "County",
    locationid: "12027",
    county: "De Soto",
  },
  {
    fips: "13161",
    count: 5,
    state: "GA",
    type: "County",
    locationid: "13161",
    county: "Jeff Davis",
  },
  {
    fips: "13207",
    count: 5,
    state: "GA",
    type: "County",
    locationid: "13207",
    county: "Monroe",
  },
  {
    fips: "13281",
    count: 5,
    state: "GA",
    type: "County",
    locationid: "13281",
    county: "Towns",
  },
  {
    fips: "16011",
    count: 5,
    state: "ID",
    type: "County",
    locationid: "16011",
    county: "Bingham",
  },
  {
    fips: "17009",
    count: 5,
    state: "IL",
    type: "County",
    locationid: "17009",
    county: "Brown",
  },
  {
    fips: "17023",
    count: 5,
    state: "IL",
    type: "County",
    locationid: "17023",
    county: "Clark",
  },
  {
    fips: "17057",
    count: 5,
    state: "IL",
    type: "County",
    locationid: "17057",
    county: "Fulton",
  },
  {
    fips: "17135",
    count: 5,
    state: "IL",
    type: "County",
    locationid: "17135",
    county: "Montgomery",
  },
  {
    fips: "17137",
    count: 5,
    state: "IL",
    type: "County",
    locationid: "17137",
    county: "Morgan",
  },
  {
    fips: "18017",
    count: 5,
    state: "IN",
    type: "County",
    locationid: "18017",
    county: "Cass",
  },
  {
    fips: "18027",
    count: 5,
    state: "IN",
    type: "County",
    locationid: "18027",
    county: "Daviess",
  },
  {
    fips: "18079",
    count: 5,
    state: "IN",
    type: "County",
    locationid: "18079",
    county: "Jennings",
  },
  {
    fips: "18121",
    count: 5,
    state: "IN",
    type: "County",
    locationid: "18121",
    county: "Parke",
  },
  {
    fips: "19015",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19015",
    county: "Boone",
  },
  {
    fips: "19019",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19019",
    county: "Buchanan",
  },
  {
    fips: "19039",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19039",
    county: "Clarke",
  },
  {
    fips: "19055",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19055",
    county: "Delaware",
  },
  {
    fips: "19139",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19139",
    county: "Muscatine",
  },
  {
    fips: "19157",
    count: 5,
    state: "IA",
    type: "County",
    locationid: "19157",
    county: "Poweshiek",
  },
  {
    fips: "20053",
    count: 5,
    state: "KS",
    type: "County",
    locationid: "20053",
    county: "Ellsworth",
  },
  {
    fips: "20111",
    count: 5,
    state: "KS",
    type: "County",
    locationid: "20111",
    county: "Lyon",
  },
  {
    fips: "20181",
    count: 4,
    state: "KS",
    type: "County",
    locationid: "20181",
    county: "Sherman",
  },
  {
    fips: "21045",
    count: 5,
    state: "KY",
    type: "County",
    locationid: "21045",
    county: "Casey",
  },
  {
    fips: "21051",
    count: 5,
    state: "KY",
    type: "County",
    locationid: "21051",
    county: "Clay",
  },
  {
    fips: "21065",
    count: 5,
    state: "KY",
    type: "County",
    locationid: "21065",
    county: "Estill",
  },
  {
    fips: "21081",
    count: 5,
    state: "KY",
    type: "County",
    locationid: "21081",
    county: "Grant",
  },
  {
    fips: "21167",
    count: 5,
    state: "KY",
    type: "County",
    locationid: "21167",
    county: "Mercer",
  },
  {
    fips: "22009",
    count: 5,
    state: "LA",
    type: "County",
    locationid: "22009",
    county: "Avoyelles",
  },
  {
    fips: "22075",
    count: 5,
    state: "LA",
    type: "County",
    locationid: "22075",
    county: "Plaquemines",
  },
  {
    fips: "26027",
    count: 5,
    state: "MI",
    type: "County",
    locationid: "26027",
    county: "Cass",
  },
  {
    fips: "26053",
    count: 5,
    state: "MI",
    type: "County",
    locationid: "26053",
    county: "Gogebic",
  },
  {
    fips: "26071",
    count: 5,
    state: "MI",
    type: "County",
    locationid: "26071",
    county: "Iron",
  },
  {
    fips: "26089",
    count: 5,
    state: "MI",
    type: "County",
    locationid: "26089",
    county: "Leelanau",
  },
  {
    fips: "26143",
    count: 5,
    state: "MI",
    type: "County",
    locationid: "26143",
    county: "Roscommon",
  },
  {
    fips: "27017",
    count: 5,
    state: "MN",
    type: "County",
    locationid: "27017",
    county: "Carlton",
  },
  {
    fips: "27159",
    count: 5,
    state: "MN",
    type: "County",
    locationid: "27159",
    county: "Wadena",
  },
  {
    fips: "27165",
    count: 5,
    state: "MN",
    type: "County",
    locationid: "27165",
    county: "Watonwan",
  },
  {
    fips: "28057",
    count: 5,
    state: "MS",
    type: "County",
    locationid: "28057",
    county: "Itawamba",
  },
  {
    fips: "28077",
    count: 5,
    state: "MS",
    type: "County",
    locationid: "28077",
    county: "Lawrence",
  },
  {
    fips: "28135",
    count: 5,
    state: "MS",
    type: "County",
    locationid: "28135",
    county: "Tallahatchie",
  },
  {
    fips: "29043",
    count: 4,
    state: "MO",
    type: "County",
    locationid: "29043",
    county: "Christian",
  },
  {
    fips: "29123",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29123",
    county: "Madison",
  },
  {
    fips: "29135",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29135",
    county: "Moniteau",
  },
  {
    fips: "29155",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29155",
    county: "Pemiscot",
  },
  {
    fips: "29163",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29163",
    county: "Pike",
  },
  {
    fips: "29217",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29217",
    county: "Vernon",
  },
  {
    fips: "29225",
    count: 5,
    state: "MO",
    type: "County",
    locationid: "29225",
    county: "Webster",
  },
  {
    fips: "30047",
    count: 5,
    state: "MT",
    type: "County",
    locationid: "30047",
    county: "Lake",
  },
  {
    fips: "30067",
    count: 5,
    state: "MT",
    type: "County",
    locationid: "30067",
    county: "Park",
  },
  {
    fips: "31013",
    count: 5,
    state: "NE",
    type: "County",
    locationid: "31013",
    county: "Box Butte",
  },
  {
    fips: "31095",
    count: 5,
    state: "NE",
    type: "County",
    locationid: "31095",
    county: "Jefferson",
  },
  {
    fips: "31141",
    count: 5,
    state: "NE",
    type: "County",
    locationid: "31141",
    county: "Platte",
  },
  {
    fips: "31177",
    count: 5,
    state: "NE",
    type: "County",
    locationid: "31177",
    county: "Washington",
  },
  {
    fips: "35051",
    count: 5,
    state: "NM",
    type: "County",
    locationid: "35051",
    county: "Sierra",
  },
  {
    fips: "36123",
    count: 5,
    state: "NY",
    type: "County",
    locationid: "36123",
    county: "Yates",
  },
  {
    fips: "37061",
    count: 5,
    state: "NC",
    type: "County",
    locationid: "37061",
    county: "Duplin",
  },
  {
    fips: "37065",
    count: 5,
    state: "NC",
    type: "County",
    locationid: "37065",
    county: "Edgecombe",
  },
  {
    fips: "38093",
    count: 5,
    state: "ND",
    type: "County",
    locationid: "38093",
    county: "Stutsman",
  },
  {
    fips: "38105",
    count: 5,
    state: "ND",
    type: "County",
    locationid: "38105",
    county: "Williams",
  },
  {
    fips: "39117",
    count: 5,
    state: "OH",
    type: "County",
    locationid: "39117",
    county: "Morrow",
  },
  {
    fips: "40005",
    count: 5,
    state: "OK",
    type: "County",
    locationid: "40005",
    county: "Atoka",
  },
  {
    fips: "40009",
    count: 5,
    state: "OK",
    type: "County",
    locationid: "40009",
    county: "Beckham",
  },
  {
    fips: "40115",
    count: 5,
    state: "OK",
    type: "County",
    locationid: "40115",
    county: "Ottawa",
  },
  {
    fips: "40141",
    count: 5,
    state: "OK",
    type: "County",
    locationid: "40141",
    county: "Tillman",
  },
  {
    fips: "40153",
    count: 5,
    state: "OK",
    type: "County",
    locationid: "40153",
    county: "Woodward",
  },
  {
    fips: "41013",
    count: 5,
    state: "OR",
    type: "County",
    locationid: "41013",
    county: "Crook",
  },
  {
    fips: "41057",
    count: 5,
    state: "OR",
    type: "County",
    locationid: "41057",
    county: "Tillamook",
  },
  {
    fips: "45039",
    count: 5,
    state: "SC",
    type: "County",
    locationid: "45039",
    county: "Fairfield",
  },
  {
    fips: "45053",
    count: 5,
    state: "SC",
    type: "County",
    locationid: "45053",
    county: "Jasper",
  },
  {
    fips: "47029",
    count: 5,
    state: "TN",
    type: "County",
    locationid: "47029",
    county: "Cocke",
  },
  {
    fips: "47051",
    count: 5,
    state: "TN",
    type: "County",
    locationid: "47051",
    county: "Franklin",
  },
  {
    fips: "47151",
    count: 5,
    state: "TN",
    type: "County",
    locationid: "47151",
    county: "Scott",
  },
  {
    fips: "47185",
    count: 5,
    state: "TN",
    type: "County",
    locationid: "47185",
    county: "White",
  },
  {
    fips: "48019",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48019",
    county: "Bandera",
  },
  {
    fips: "48241",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48241",
    county: "Jasper",
  },
  {
    fips: "48285",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48285",
    county: "Lavaca",
  },
  {
    fips: "48321",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48321",
    county: "Matagorda",
  },
  {
    fips: "48323",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48323",
    county: "Maverick",
  },
  {
    fips: "48401",
    count: 5,
    state: "TX",
    type: "County",
    locationid: "48401",
    county: "Rusk",
  },
  {
    fips: "49021",
    count: 5,
    state: "UT",
    type: "County",
    locationid: "49021",
    county: "Iron",
  },
  {
    fips: "49043",
    count: 5,
    state: "UT",
    type: "County",
    locationid: "49043",
    county: "Summit",
  },
  {
    fips: "50017",
    count: 5,
    state: "VT",
    type: "County",
    locationid: "50017",
    county: "Orange",
  },
  {
    fips: "51079",
    count: 5,
    state: "VA",
    type: "County",
    locationid: "51079",
    county: "Greene",
  },
  {
    fips: "51131",
    count: 5,
    state: "VA",
    type: "County",
    locationid: "51131",
    county: "Northampton",
  },
  {
    fips: "51171",
    count: 5,
    state: "VA",
    type: "County",
    locationid: "51171",
    county: "Shenandoah",
  },
  {
    fips: "53025",
    count: 5,
    state: "WA",
    type: "County",
    locationid: "53025",
    county: "Grant",
  },
  {
    fips: "53075",
    count: 5,
    state: "WA",
    type: "County",
    locationid: "53075",
    county: "Whitman",
  },
  {
    fips: "54087",
    count: 5,
    state: "WV",
    type: "County",
    locationid: "54087",
    county: "Roane",
  },
  {
    fips: "54103",
    count: 5,
    state: "WV",
    type: "County",
    locationid: "54103",
    county: "Wetzel",
  },
  {
    fips: "54109",
    count: 5,
    state: "WV",
    type: "County",
    locationid: "54109",
    county: "Wyoming",
  },
  {
    fips: "55053",
    count: 5,
    state: "WI",
    type: "County",
    locationid: "55053",
    county: "Jackson",
  },
  {
    fips: "55069",
    count: 5,
    state: "WI",
    type: "County",
    locationid: "55069",
    county: "Lincoln",
  },
  {
    fips: "55081",
    count: 5,
    state: "WI",
    type: "County",
    locationid: "55081",
    county: "Monroe",
  },
  {
    fips: "01005",
    count: 4,
    state: "AL",
    type: "County",
    locationid: "01005",
    county: "Barbour",
  },
  {
    fips: "01025",
    count: 4,
    state: "AL",
    type: "County",
    locationid: "01025",
    county: "Clarke",
  },
  {
    fips: "01091",
    count: 4,
    state: "AL",
    type: "County",
    locationid: "01091",
    county: "Marengo",
  },
  {
    fips: "01133",
    count: 4,
    state: "AL",
    type: "County",
    locationid: "01133",
    county: "Winston",
  },
  {
    fips: "05011",
    count: 4,
    state: "AR",
    type: "County",
    locationid: "05011",
    county: "Bradley",
  },
  {
    fips: "05043",
    count: 4,
    state: "AR",
    type: "County",
    locationid: "05043",
    county: "Drew",
  },
  {
    fips: "05057",
    count: 4,
    state: "AR",
    type: "County",
    locationid: "05057",
    county: "Hempstead",
  },
  {
    fips: "05067",
    count: 4,
    state: "AR",
    type: "County",
    locationid: "05067",
    county: "Jackson",
  },
  {
    fips: "05113",
    count: 4,
    state: "AR",
    type: "County",
    locationid: "05113",
    county: "Polk",
  },
  {
    fips: "06021",
    count: 4,
    state: "CA",
    type: "County",
    locationid: "06021",
    county: "Glenn",
  },
  {
    fips: "08015",
    count: 4,
    state: "CO",
    type: "County",
    locationid: "08015",
    county: "Chaffee",
  },
  {
    fips: "08071",
    count: 4,
    state: "CO",
    type: "County",
    locationid: "08071",
    county: "Las Animas",
  },
  {
    fips: "08117",
    count: 4,
    state: "CO",
    type: "County",
    locationid: "08117",
    county: "Summit",
  },
  {
    fips: "08119",
    count: 4,
    state: "CO",
    type: "County",
    locationid: "08119",
    county: "Teller",
  },
  {
    fips: "12041",
    count: 4,
    state: "FL",
    type: "County",
    locationid: "12041",
    county: "Gilchrist",
  },
  {
    fips: "12051",
    count: 4,
    state: "FL",
    type: "County",
    locationid: "12051",
    county: "Hendry",
  },
  {
    fips: "13005",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13005",
    county: "Bacon",
  },
  {
    fips: "13087",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13087",
    county: "Decatur",
  },
  {
    fips: "13169",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13169",
    county: "Jones",
  },
  {
    fips: "13177",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13177",
    county: "Lee",
  },
  {
    fips: "13187",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13187",
    county: "Lumpkin",
  },
  {
    fips: "13205",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13205",
    county: "Mitchell",
  },
  {
    fips: "13253",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13253",
    county: "Seminole",
  },
  {
    fips: "13321",
    count: 4,
    state: "GA",
    type: "County",
    locationid: "13321",
    county: "Worth",
  },
  {
    fips: "16013",
    count: 4,
    state: "ID",
    type: "County",
    locationid: "16013",
    county: "Blaine",
  },
  {
    fips: "16085",
    count: 4,
    state: "ID",
    type: "County",
    locationid: "16085",
    county: "Valley",
  },
  {
    fips: "17021",
    count: 4,
    state: "IL",
    type: "County",
    locationid: "17021",
    county: "Christian",
  },
  {
    fips: "17027",
    count: 4,
    state: "IL",
    type: "County",
    locationid: "17027",
    county: "Clinton",
  },
  {
    fips: "17045",
    count: 4,
    state: "IL",
    type: "County",
    locationid: "17045",
    county: "Edgar",
  },
  {
    fips: "17125",
    count: 4,
    state: "IL",
    type: "County",
    locationid: "17125",
    county: "Mason",
  },
  {
    fips: "18001",
    count: 4,
    state: "IN",
    type: "County",
    locationid: "18001",
    county: "Adams",
  },
  {
    fips: "18013",
    count: 4,
    state: "IN",
    type: "County",
    locationid: "18013",
    county: "Brown",
  },
  {
    fips: "18073",
    count: 4,
    state: "IN",
    type: "County",
    locationid: "18073",
    county: "Jasper",
  },
  {
    fips: "18147",
    count: 4,
    state: "IN",
    type: "County",
    locationid: "18147",
    county: "Spencer",
  },
  {
    fips: "18175",
    count: 4,
    state: "IN",
    type: "County",
    locationid: "18175",
    county: "Washington",
  },
  {
    fips: "19031",
    count: 4,
    state: "IA",
    type: "County",
    locationid: "19031",
    county: "Cedar",
  },
  {
    fips: "19159",
    count: 4,
    state: "IA",
    type: "County",
    locationid: "19159",
    county: "Ringgold",
  },
  {
    fips: "20021",
    count: 4,
    state: "KS",
    type: "County",
    locationid: "20021",
    county: "Cherokee",
  },
  {
    fips: "20133",
    count: 4,
    state: "KS",
    type: "County",
    locationid: "20133",
    county: "Neosho",
  },
  {
    fips: "20157",
    count: 4,
    state: "KS",
    type: "County",
    locationid: "20157",
    county: "Republic",
  },
  {
    fips: "21041",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21041",
    county: "Carroll",
  },
  {
    fips: "21087",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21087",
    county: "Green",
  },
  {
    fips: "21103",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21103",
    county: "Henry",
  },
  {
    fips: "21139",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21139",
    county: "Livingston",
  },
  {
    fips: "21141",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21141",
    county: "Logan",
  },
  {
    fips: "21159",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21159",
    county: "Martin",
  },
  {
    fips: "21171",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21171",
    county: "Monroe",
  },
  {
    fips: "21181",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21181",
    county: "Nicholas",
  },
  {
    fips: "21207",
    count: 4,
    state: "KY",
    type: "County",
    locationid: "21207",
    county: "Russell",
  },
  {
    fips: "22011",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22011",
    county: "Beauregard",
  },
  {
    fips: "22031",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22031",
    county: "De Soto",
  },
  {
    fips: "22047",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22047",
    county: "Iberville",
  },
  {
    fips: "22083",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22083",
    county: "Richland",
  },
  {
    fips: "22099",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22099",
    county: "Saint Martin",
  },
  {
    fips: "22111",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22111",
    county: "Union",
  },
  {
    fips: "22123",
    count: 4,
    state: "LA",
    type: "County",
    locationid: "22123",
    county: "West Carroll",
  },
  {
    fips: "23021",
    count: 4,
    state: "ME",
    type: "County",
    locationid: "23021",
    county: "Piscataquis",
  },
  {
    fips: "26109",
    count: 4,
    state: "MI",
    type: "County",
    locationid: "26109",
    county: "Menominee",
  },
  {
    fips: "26131",
    count: 4,
    state: "MI",
    type: "County",
    locationid: "26131",
    county: "Ontonagon",
  },
  {
    fips: "27079",
    count: 4,
    state: "MN",
    type: "County",
    locationid: "27079",
    county: "Le Sueur",
  },
  {
    fips: "27097",
    count: 4,
    state: "MN",
    type: "County",
    locationid: "27097",
    county: "Morrison",
  },
  {
    fips: "28019",
    count: 4,
    state: "MS",
    type: "County",
    locationid: "28019",
    county: "Choctaw",
  },
  {
    fips: "28123",
    count: 4,
    state: "MS",
    type: "County",
    locationid: "28123",
    county: "Scott",
  },
  {
    fips: "28133",
    count: 4,
    state: "MS",
    type: "County",
    locationid: "28133",
    county: "Sunflower",
  },
  {
    fips: "28157",
    count: 4,
    state: "MS",
    type: "County",
    locationid: "28157",
    county: "Wilkinson",
  },
  {
    fips: "28159",
    count: 4,
    state: "MS",
    type: "County",
    locationid: "28159",
    county: "Winston",
  },
  {
    fips: "29065",
    count: 4,
    state: "MO",
    type: "County",
    locationid: "29065",
    county: "Dent",
  },
  {
    fips: "29079",
    count: 4,
    state: "MO",
    type: "County",
    locationid: "29079",
    county: "Grundy",
  },
  {
    fips: "30023",
    count: 4,
    state: "MT",
    type: "County",
    locationid: "30023",
    county: "Deer Lodge",
  },
  {
    fips: "31175",
    count: 4,
    state: "NE",
    type: "County",
    locationid: "31175",
    county: "Valley",
  },
  {
    fips: "31185",
    count: 4,
    state: "NE",
    type: "County",
    locationid: "31185",
    county: "York",
  },
  {
    fips: "35019",
    count: 4,
    state: "NM",
    type: "County",
    locationid: "35019",
    county: "Guadalupe",
  },
  {
    fips: "35047",
    count: 4,
    state: "NM",
    type: "County",
    locationid: "35047",
    county: "San Miguel",
  },
  {
    fips: "35055",
    count: 4,
    state: "NM",
    type: "County",
    locationid: "35055",
    county: "Taos",
  },
  {
    fips: "35061",
    count: 4,
    state: "NM",
    type: "County",
    locationid: "35061",
    county: "Valencia",
  },
  {
    fips: "36115",
    count: 4,
    state: "NY",
    type: "County",
    locationid: "36115",
    county: "Washington",
  },
  {
    fips: "37039",
    count: 4,
    state: "NC",
    type: "County",
    locationid: "37039",
    county: "Cherokee",
  },
  {
    fips: "37079",
    count: 4,
    state: "NC",
    type: "County",
    locationid: "37079",
    county: "Greene",
  },
  {
    fips: "37121",
    count: 4,
    state: "NC",
    type: "County",
    locationid: "37121",
    county: "Mitchell",
  },
  {
    fips: "37173",
    count: 4,
    state: "NC",
    type: "County",
    locationid: "37173",
    county: "Swain",
  },
  {
    fips: "37199",
    count: 4,
    state: "NC",
    type: "County",
    locationid: "37199",
    county: "Yancey",
  },
  {
    fips: "38003",
    count: 4,
    state: "ND",
    type: "County",
    locationid: "38003",
    county: "Barnes",
  },
  {
    fips: "38059",
    count: 4,
    state: "ND",
    type: "County",
    locationid: "38059",
    county: "Morton",
  },
  {
    fips: "39075",
    count: 4,
    state: "OH",
    type: "County",
    locationid: "39075",
    county: "Holmes",
  },
  {
    fips: "39115",
    count: 4,
    state: "OH",
    type: "County",
    locationid: "39115",
    county: "Morgan",
  },
  {
    fips: "40069",
    count: 4,
    state: "OK",
    type: "County",
    locationid: "40069",
    county: "Johnston",
  },
  {
    fips: "40083",
    count: 4,
    state: "OK",
    type: "County",
    locationid: "40083",
    county: "Logan",
  },
  {
    fips: "40099",
    count: 4,
    state: "OK",
    type: "County",
    locationid: "40099",
    county: "Murray",
  },
  {
    fips: "41045",
    count: 4,
    state: "OR",
    type: "County",
    locationid: "41045",
    county: "Malheur",
  },
  {
    fips: "41061",
    count: 4,
    state: "OR",
    type: "County",
    locationid: "41061",
    county: "Union",
  },
  {
    fips: "45033",
    count: 4,
    state: "SC",
    type: "County",
    locationid: "45033",
    county: "Dillon",
  },
  {
    fips: "45067",
    count: 4,
    state: "SC",
    type: "County",
    locationid: "45067",
    county: "Marion",
  },
  {
    fips: "45069",
    count: 4,
    state: "SC",
    type: "County",
    locationid: "45069",
    county: "Marlboro",
  },
  {
    fips: "45087",
    count: 4,
    state: "SC",
    type: "County",
    locationid: "45087",
    county: "Union",
  },
  {
    fips: "46005",
    count: 4,
    state: "SD",
    type: "County",
    locationid: "46005",
    county: "Beadle",
  },
  {
    fips: "47069",
    count: 4,
    state: "TN",
    type: "County",
    locationid: "47069",
    county: "Hardeman",
  },
  {
    fips: "47091",
    count: 4,
    state: "TN",
    type: "County",
    locationid: "47091",
    county: "Johnson",
  },
  {
    fips: "47097",
    count: 4,
    state: "TN",
    type: "County",
    locationid: "47097",
    county: "Lauderdale",
  },
  {
    fips: "47181",
    count: 4,
    state: "TN",
    type: "County",
    locationid: "47181",
    county: "Wayne",
  },
  {
    fips: "48013",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48013",
    county: "Atascosa",
  },
  {
    fips: "48055",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48055",
    county: "Caldwell",
  },
  {
    fips: "48145",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48145",
    county: "Falls",
  },
  {
    fips: "48149",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48149",
    county: "Fayette",
  },
  {
    fips: "48225",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48225",
    county: "Houston",
  },
  {
    fips: "48237",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48237",
    county: "Jack",
  },
  {
    fips: "48459",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48459",
    county: "Upshur",
  },
  {
    fips: "48481",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48481",
    county: "Wharton",
  },
  {
    fips: "48499",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48499",
    county: "Wood",
  },
  {
    fips: "48503",
    count: 4,
    state: "TX",
    type: "County",
    locationid: "48503",
    county: "Young",
  },
  {
    fips: "49045",
    count: 4,
    state: "UT",
    type: "County",
    locationid: "49045",
    county: "Tooele",
  },
  {
    fips: "51051",
    count: 4,
    state: "VA",
    type: "County",
    locationid: "51051",
    county: "Dickenson",
  },
  {
    fips: "51165",
    count: 4,
    state: "VA",
    type: "County",
    locationid: "51165",
    county: "Rockingham",
  },
  {
    fips: "51678",
    count: 4,
    state: "VA",
    type: "County",
    locationid: "51678",
    county: "Lexington City",
  },
  {
    fips: "53045",
    count: 4,
    state: "WA",
    type: "County",
    locationid: "53045",
    county: "Mason",
  },
  {
    fips: "53065",
    count: 4,
    state: "WA",
    type: "County",
    locationid: "53065",
    county: "Stevens",
  },
  {
    fips: "54005",
    count: 4,
    state: "WV",
    type: "County",
    locationid: "54005",
    county: "Boone",
  },
  {
    fips: "54009",
    count: 4,
    state: "WV",
    type: "County",
    locationid: "54009",
    county: "Brooke",
  },
  {
    fips: "55017",
    count: 4,
    state: "WI",
    type: "County",
    locationid: "55017",
    county: "Chippewa",
  },
  {
    fips: "55023",
    count: 4,
    state: "WI",
    type: "County",
    locationid: "55023",
    county: "Crawford",
  },
  {
    fips: "55067",
    count: 4,
    state: "WI",
    type: "County",
    locationid: "55067",
    county: "Langlade",
  },
  {
    fips: "55123",
    count: 4,
    state: "WI",
    type: "County",
    locationid: "55123",
    county: "Vernon",
  },
  {
    fips: "56041",
    count: 4,
    state: "WY",
    type: "County",
    locationid: "56041",
    county: "Uinta",
  },
  {
    fips: "01035",
    count: 3,
    state: "AL",
    type: "County",
    locationid: "01035",
    county: "Conecuh",
  },
  {
    fips: "01079",
    count: 3,
    state: "AL",
    type: "County",
    locationid: "01079",
    county: "Lawrence",
  },
  {
    fips: "01087",
    count: 3,
    state: "AL",
    type: "County",
    locationid: "01087",
    county: "Macon",
  },
  {
    fips: "01107",
    count: 3,
    state: "AL",
    type: "County",
    locationid: "01107",
    county: "Pickens",
  },
  {
    fips: "05017",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05017",
    county: "Chicot",
  },
  {
    fips: "05039",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05039",
    county: "Dallas",
  },
  {
    fips: "05049",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05049",
    county: "Fulton",
  },
  {
    fips: "05073",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05073",
    county: "Lafayette",
  },
  {
    fips: "05075",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05075",
    county: "Lawrence",
  },
  {
    fips: "05099",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05099",
    county: "Nevada",
  },
  {
    fips: "05121",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05121",
    county: "Randolph",
  },
  {
    fips: "05147",
    count: 3,
    state: "AR",
    type: "County",
    locationid: "05147",
    county: "Woodruff",
  },
  {
    fips: "06005",
    count: 3,
    state: "CA",
    type: "County",
    locationid: "06005",
    county: "Amador",
  },
  {
    fips: "06009",
    count: 3,
    state: "CA",
    type: "County",
    locationid: "06009",
    county: "Calaveras",
  },
  {
    fips: "06043",
    count: 3,
    state: "CA",
    type: "County",
    locationid: "06043",
    county: "Mariposa",
  },
  {
    fips: "06069",
    count: 3,
    state: "CA",
    type: "County",
    locationid: "06069",
    county: "San Benito",
  },
  {
    fips: "08083",
    count: 3,
    state: "CO",
    type: "County",
    locationid: "08083",
    county: "Montezuma",
  },
  {
    fips: "12007",
    count: 3,
    state: "FL",
    type: "County",
    locationid: "12007",
    county: "Bradford",
  },
  {
    fips: "12029",
    count: 3,
    state: "FL",
    type: "County",
    locationid: "12029",
    county: "Dixie",
  },
  {
    fips: "12049",
    count: 3,
    state: "FL",
    type: "County",
    locationid: "12049",
    county: "Hardee",
  },
  {
    fips: "12075",
    count: 3,
    state: "FL",
    type: "County",
    locationid: "12075",
    county: "Levy",
  },
  {
    fips: "13019",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13019",
    county: "Berrien",
  },
  {
    fips: "13027",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13027",
    county: "Brooks",
  },
  {
    fips: "13039",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13039",
    county: "Camden",
  },
  {
    fips: "13091",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13091",
    county: "Dodge",
  },
  {
    fips: "13099",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13099",
    county: "Early",
  },
  {
    fips: "13109",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13109",
    county: "Evans",
  },
  {
    fips: "13131",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13131",
    county: "Grady",
  },
  {
    fips: "13159",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13159",
    county: "Jasper",
  },
  {
    fips: "13201",
    count: 3,
    state: "GA",
    type: "County",
    locationid: "13201",
    county: "Miller",
  },
  {
    fips: "16045",
    count: 3,
    state: "ID",
    type: "County",
    locationid: "16045",
    county: "Gem",
  },
  {
    fips: "16051",
    count: 3,
    state: "ID",
    type: "County",
    locationid: "16051",
    county: "Jefferson",
  },
  {
    fips: "16067",
    count: 3,
    state: "ID",
    type: "County",
    locationid: "16067",
    county: "Minidoka",
  },
  {
    fips: "16079",
    count: 3,
    state: "ID",
    type: "County",
    locationid: "16079",
    county: "Shoshone",
  },
  {
    fips: "17015",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17015",
    county: "Carroll",
  },
  {
    fips: "17025",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17025",
    county: "Clay",
  },
  {
    fips: "17073",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17073",
    county: "Henry",
  },
  {
    fips: "17085",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17085",
    county: "Jo Daviess",
  },
  {
    fips: "17101",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17101",
    county: "Lawrence",
  },
  {
    fips: "17129",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17129",
    county: "Menard",
  },
  {
    fips: "17147",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17147",
    county: "Piatt",
  },
  {
    fips: "17149",
    count: 3,
    state: "IL",
    type: "County",
    locationid: "17149",
    county: "Pike",
  },
  {
    fips: "18007",
    count: 3,
    state: "IN",
    type: "County",
    locationid: "18007",
    county: "Benton",
  },
  {
    fips: "18117",
    count: 3,
    state: "IN",
    type: "County",
    locationid: "18117",
    county: "Orange",
  },
  {
    fips: "19007",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19007",
    county: "Appanoose",
  },
  {
    fips: "19023",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19023",
    county: "Butler",
  },
  {
    fips: "19047",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19047",
    county: "Crawford",
  },
  {
    fips: "19097",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19097",
    county: "Jackson",
  },
  {
    fips: "19105",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19105",
    county: "Jones",
  },
  {
    fips: "19125",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19125",
    county: "Marion",
  },
  {
    fips: "19133",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19133",
    county: "Monona",
  },
  {
    fips: "19141",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19141",
    county: "Obrien",
  },
  {
    fips: "19151",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19151",
    county: "Pocahontas",
  },
  {
    fips: "19175",
    count: 3,
    state: "IA",
    type: "County",
    locationid: "19175",
    county: "Union",
  },
  {
    fips: "20005",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20005",
    county: "Atchison",
  },
  {
    fips: "20011",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20011",
    county: "Bourbon",
  },
  {
    fips: "20027",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20027",
    county: "Clay",
  },
  {
    fips: "20033",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20033",
    county: "Comanche",
  },
  {
    fips: "20099",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20099",
    county: "Labette",
  },
  {
    fips: "20107",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20107",
    county: "Linn",
  },
  {
    fips: "20139",
    count: 3,
    state: "KS",
    type: "County",
    locationid: "20139",
    county: "Osage",
  },
  {
    fips: "21001",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21001",
    county: "Adair",
  },
  {
    fips: "21055",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21055",
    county: "Crittenden",
  },
  {
    fips: "21079",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21079",
    county: "Garrard",
  },
  {
    fips: "21085",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21085",
    county: "Grayson",
  },
  {
    fips: "21099",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21099",
    county: "Hart",
  },
  {
    fips: "21113",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21113",
    county: "Jessamine",
  },
  {
    fips: "21197",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21197",
    county: "Powell",
  },
  {
    fips: "21219",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21219",
    county: "Todd",
  },
  {
    fips: "21221",
    count: 3,
    state: "KY",
    type: "County",
    locationid: "21221",
    county: "Trigg",
  },
  {
    fips: "22027",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22027",
    county: "Claiborne",
  },
  {
    fips: "22037",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22037",
    county: "East Feliciana",
  },
  {
    fips: "22041",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22041",
    county: "Franklin",
  },
  {
    fips: "22077",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22077",
    county: "Pointe Coupee",
  },
  {
    fips: "22081",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22081",
    county: "Red River",
  },
  {
    fips: "22085",
    count: 3,
    state: "LA",
    type: "County",
    locationid: "22085",
    county: "Sabine",
  },
  {
    fips: "24029",
    count: 3,
    state: "MD",
    type: "County",
    locationid: "24029",
    county: "Kent",
  },
  {
    fips: "24035",
    count: 3,
    state: "MD",
    type: "County",
    locationid: "24035",
    county: "Queen Annes",
  },
  {
    fips: "25019",
    count: 3,
    state: "MA",
    type: "County",
    locationid: "25019",
    county: "Nantucket",
  },
  {
    fips: "26079",
    count: 3,
    state: "MI",
    type: "County",
    locationid: "26079",
    county: "Kalkaska",
  },
  {
    fips: "26085",
    count: 3,
    state: "MI",
    type: "County",
    locationid: "26085",
    county: "Lake",
  },
  {
    fips: "26095",
    count: 3,
    state: "MI",
    type: "County",
    locationid: "26095",
    county: "Luce",
  },
  {
    fips: "26113",
    count: 3,
    state: "MI",
    type: "County",
    locationid: "26113",
    county: "Missaukee",
  },
  {
    fips: "27021",
    count: 3,
    state: "MN",
    type: "County",
    locationid: "27021",
    county: "Cass",
  },
  {
    fips: "27027",
    count: 3,
    state: "MN",
    type: "County",
    locationid: "27027",
    county: "Clay",
  },
  {
    fips: "27093",
    count: 3,
    state: "MN",
    type: "County",
    locationid: "27093",
    county: "Meeker",
  },
  {
    fips: "27149",
    count: 3,
    state: "MN",
    type: "County",
    locationid: "27149",
    county: "Stevens",
  },
  {
    fips: "28017",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28017",
    county: "Chickasaw",
  },
  {
    fips: "28031",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28031",
    county: "Covington",
  },
  {
    fips: "28039",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28039",
    county: "George",
  },
  {
    fips: "28095",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28095",
    county: "Monroe",
  },
  {
    fips: "28101",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28101",
    county: "Newton",
  },
  {
    fips: "28127",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28127",
    county: "Simpson",
  },
  {
    fips: "28153",
    count: 3,
    state: "MS",
    type: "County",
    locationid: "28153",
    county: "Wayne",
  },
  {
    fips: "29009",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29009",
    county: "Barry",
  },
  {
    fips: "29025",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29025",
    county: "Caldwell",
  },
  {
    fips: "29101",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29101",
    county: "Johnson",
  },
  {
    fips: "29113",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29113",
    county: "Lincoln",
  },
  {
    fips: "29121",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29121",
    county: "Macon",
  },
  {
    fips: "29157",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29157",
    county: "Perry",
  },
  {
    fips: "29177",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29177",
    county: "Ray",
  },
  {
    fips: "29186",
    count: 3,
    state: "MO",
    type: "County",
    locationid: "29186",
    county: "Sainte Genevieve",
  },
  {
    fips: "30035",
    count: 3,
    state: "MT",
    type: "County",
    locationid: "30035",
    county: "Glacier",
  },
  {
    fips: "30083",
    count: 3,
    state: "MT",
    type: "County",
    locationid: "30083",
    county: "Richland",
  },
  {
    fips: "31089",
    count: 3,
    state: "NE",
    type: "County",
    locationid: "31089",
    county: "Holt",
  },
  {
    fips: "31143",
    count: 3,
    state: "NE",
    type: "County",
    locationid: "31143",
    county: "Polk",
  },
  {
    fips: "32019",
    count: 3,
    state: "NV",
    type: "County",
    locationid: "32019",
    county: "Lyon",
  },
  {
    fips: "35027",
    count: 3,
    state: "NM",
    type: "County",
    locationid: "35027",
    county: "Lincoln",
  },
  {
    fips: "35031",
    count: 3,
    state: "NM",
    type: "County",
    locationid: "35031",
    county: "Mckinley",
  },
  {
    fips: "35039",
    count: 3,
    state: "NM",
    type: "County",
    locationid: "35039",
    county: "Rio Arriba",
  },
  {
    fips: "36099",
    count: 3,
    state: "NY",
    type: "County",
    locationid: "36099",
    county: "Seneca",
  },
  {
    fips: "37015",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37015",
    county: "Bertie",
  },
  {
    fips: "37017",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37017",
    county: "Bladen",
  },
  {
    fips: "37041",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37041",
    county: "Chowan",
  },
  {
    fips: "37053",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37053",
    county: "Currituck",
  },
  {
    fips: "37123",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37123",
    county: "Montgomery",
  },
  {
    fips: "37131",
    count: 3,
    state: "NC",
    type: "County",
    locationid: "37131",
    county: "Northampton",
  },
  {
    fips: "38023",
    count: 3,
    state: "ND",
    type: "County",
    locationid: "38023",
    county: "Divide",
  },
  {
    fips: "38057",
    count: 3,
    state: "ND",
    type: "County",
    locationid: "38057",
    county: "Mercer",
  },
  {
    fips: "38077",
    count: 3,
    state: "ND",
    type: "County",
    locationid: "38077",
    county: "Richland",
  },
  {
    fips: "39011",
    count: 3,
    state: "OH",
    type: "County",
    locationid: "39011",
    county: "Auglaize",
  },
  {
    fips: "39021",
    count: 3,
    state: "OH",
    type: "County",
    locationid: "39021",
    county: "Champaign",
  },
  {
    fips: "39065",
    count: 3,
    state: "OH",
    type: "County",
    locationid: "39065",
    county: "Hardin",
  },
  {
    fips: "39067",
    count: 3,
    state: "OH",
    type: "County",
    locationid: "39067",
    county: "Harrison",
  },
  {
    fips: "39121",
    count: 3,
    state: "OH",
    type: "County",
    locationid: "39121",
    county: "Noble",
  },
  {
    fips: "40039",
    count: 3,
    state: "OK",
    type: "County",
    locationid: "40039",
    county: "Custer",
  },
  {
    fips: "40077",
    count: 3,
    state: "OK",
    type: "County",
    locationid: "40077",
    county: "Latimer",
  },
  {
    fips: "40085",
    count: 3,
    state: "OK",
    type: "County",
    locationid: "40085",
    county: "Love",
  },
  {
    fips: "41009",
    count: 3,
    state: "OR",
    type: "County",
    locationid: "41009",
    county: "Columbia",
  },
  {
    fips: "41053",
    count: 3,
    state: "OR",
    type: "County",
    locationid: "41053",
    county: "Polk",
  },
  {
    fips: "46023",
    count: 3,
    state: "SD",
    type: "County",
    locationid: "46023",
    county: "Charles Mix",
  },
  {
    fips: "46037",
    count: 3,
    state: "SD",
    type: "County",
    locationid: "46037",
    county: "Day",
  },
  {
    fips: "47033",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47033",
    county: "Crockett",
  },
  {
    fips: "47041",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47041",
    county: "Dekalb",
  },
  {
    fips: "47049",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47049",
    county: "Fentress",
  },
  {
    fips: "47075",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47075",
    county: "Haywood",
  },
  {
    fips: "47087",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47087",
    county: "Jackson",
  },
  {
    fips: "47111",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47111",
    county: "Macon",
  },
  {
    fips: "47115",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47115",
    county: "Marion",
  },
  {
    fips: "47129",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47129",
    county: "Morgan",
  },
  {
    fips: "47143",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47143",
    county: "Rhea",
  },
  {
    fips: "47171",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47171",
    county: "Unicoi",
  },
  {
    fips: "47173",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47173",
    county: "Union",
  },
  {
    fips: "47177",
    count: 3,
    state: "TN",
    type: "County",
    locationid: "47177",
    county: "Warren",
  },
  {
    fips: "48147",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48147",
    county: "Fannin",
  },
  {
    fips: "48185",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48185",
    county: "Grimes",
  },
  {
    fips: "48189",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48189",
    county: "Hale",
  },
  {
    fips: "48193",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48193",
    county: "Hamilton",
  },
  {
    fips: "48219",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48219",
    county: "Hockley",
  },
  {
    fips: "48227",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48227",
    county: "Howard",
  },
  {
    fips: "48341",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48341",
    county: "Moore",
  },
  {
    fips: "48353",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48353",
    county: "Nolan",
  },
  {
    fips: "48455",
    count: 3,
    state: "TX",
    type: "County",
    locationid: "48455",
    county: "Trinity",
  },
  {
    fips: "49013",
    count: 3,
    state: "UT",
    type: "County",
    locationid: "49013",
    county: "Duchesne",
  },
  {
    fips: "51009",
    count: 3,
    state: "VA",
    type: "County",
    locationid: "51009",
    county: "Amherst",
  },
  {
    fips: "51057",
    count: 3,
    state: "VA",
    type: "County",
    locationid: "51057",
    county: "Essex",
  },
  {
    fips: "51157",
    count: 3,
    state: "VA",
    type: "County",
    locationid: "51157",
    county: "Rappahannock",
  },
  {
    fips: "51820",
    count: 3,
    state: "VA",
    type: "County",
    locationid: "51820",
    county: "Waynesboro City",
  },
  {
    fips: "53037",
    count: 3,
    state: "WA",
    type: "County",
    locationid: "53037",
    county: "Kittitas",
  },
  {
    fips: "55015",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55015",
    county: "Calumet",
  },
  {
    fips: "55033",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55033",
    county: "Dunn",
  },
  {
    fips: "55045",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55045",
    county: "Green",
  },
  {
    fips: "55049",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55049",
    county: "Iowa",
  },
  {
    fips: "55051",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55051",
    county: "Iron",
  },
  {
    fips: "55078",
    count: 2,
    state: "WI",
    type: "County",
    locationid: "55078",
    county: "Menominee",
  },
  {
    fips: "55113",
    count: 3,
    state: "WI",
    type: "County",
    locationid: "55113",
    county: "Sawyer",
  },
  {
    fips: "56039",
    count: 3,
    state: "WY",
    type: "County",
    locationid: "56039",
    county: "Teton",
  },
  {
    fips: "01011",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01011",
    county: "Bullock",
  },
  {
    fips: "01019",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01019",
    county: "Cherokee",
  },
  {
    fips: "01023",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01023",
    county: "Choctaw",
  },
  {
    fips: "01027",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01027",
    county: "Clay",
  },
  {
    fips: "01061",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01061",
    county: "Geneva",
  },
  {
    fips: "01063",
    count: 2,
    state: "AL",
    type: "County",
    locationid: "01063",
    county: "Greene",
  },
  {
    fips: "05001",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05001",
    county: "Arkansas",
  },
  {
    fips: "05015",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05015",
    county: "Carroll",
  },
  {
    fips: "05019",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05019",
    county: "Clark",
  },
  {
    fips: "05037",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05037",
    county: "Cross",
  },
  {
    fips: "05047",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05047",
    county: "Franklin",
  },
  {
    fips: "05065",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05065",
    county: "Izard",
  },
  {
    fips: "05071",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05071",
    county: "Johnson",
  },
  {
    fips: "05079",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05079",
    county: "Lincoln",
  },
  {
    fips: "05081",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05081",
    county: "Little River",
  },
  {
    fips: "05089",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05089",
    county: "Marion",
  },
  {
    fips: "05107",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05107",
    county: "Phillips",
  },
  {
    fips: "05111",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05111",
    county: "Poinsett",
  },
  {
    fips: "05127",
    count: 2,
    state: "AR",
    type: "County",
    locationid: "05127",
    county: "Scott",
  },
  {
    fips: "06035",
    count: 2,
    state: "CA",
    type: "County",
    locationid: "06035",
    county: "Lassen",
  },
  {
    fips: "08007",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08007",
    county: "Archuleta",
  },
  {
    fips: "08051",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08051",
    county: "Gunnison",
  },
  {
    fips: "08063",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08063",
    county: "Kit Carson",
  },
  {
    fips: "08091",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08091",
    county: "Ouray",
  },
  {
    fips: "08095",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08095",
    county: "Phillips",
  },
  {
    fips: "08103",
    count: 2,
    state: "CO",
    type: "County",
    locationid: "08103",
    county: "Rio Blanco",
  },
  {
    fips: "12013",
    count: 2,
    state: "FL",
    type: "County",
    locationid: "12013",
    county: "Calhoun",
  },
  {
    fips: "12039",
    count: 2,
    state: "FL",
    type: "County",
    locationid: "12039",
    county: "Gadsden",
  },
  {
    fips: "13055",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13055",
    county: "Chattooga",
  },
  {
    fips: "13075",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13075",
    county: "Cook",
  },
  {
    fips: "13123",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13123",
    county: "Gilmer",
  },
  {
    fips: "13155",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13155",
    county: "Irwin",
  },
  {
    fips: "13163",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13163",
    county: "Jefferson",
  },
  {
    fips: "13167",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13167",
    county: "Johnson",
  },
  {
    fips: "13171",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13171",
    county: "Lamar",
  },
  {
    fips: "13189",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13189",
    county: "Mcduffie",
  },
  {
    fips: "13199",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13199",
    county: "Meriwether",
  },
  {
    fips: "13213",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13213",
    county: "Murray",
  },
  {
    fips: "13241",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13241",
    county: "Rabun",
  },
  {
    fips: "13251",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13251",
    county: "Screven",
  },
  {
    fips: "13311",
    count: 2,
    state: "GA",
    type: "County",
    locationid: "13311",
    county: "White",
  },
  {
    fips: "16021",
    count: 2,
    state: "ID",
    type: "County",
    locationid: "16021",
    county: "Boundary",
  },
  {
    fips: "16043",
    count: 2,
    state: "ID",
    type: "County",
    locationid: "16043",
    county: "Fremont",
  },
  {
    fips: "16049",
    count: 2,
    state: "ID",
    type: "County",
    locationid: "16049",
    county: "Idaho",
  },
  {
    fips: "16053",
    count: 2,
    state: "ID",
    type: "County",
    locationid: "16053",
    county: "Jerome",
  },
  {
    fips: "16087",
    count: 2,
    state: "ID",
    type: "County",
    locationid: "16087",
    county: "Washington",
  },
  {
    fips: "17035",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17035",
    county: "Cumberland",
  },
  {
    fips: "17053",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17053",
    county: "Ford",
  },
  {
    fips: "17067",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17067",
    county: "Hancock",
  },
  {
    fips: "17083",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17083",
    county: "Jersey",
  },
  {
    fips: "17123",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17123",
    county: "Marshall",
  },
  {
    fips: "17133",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17133",
    county: "Monroe",
  },
  {
    fips: "17145",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17145",
    county: "Perry",
  },
  {
    fips: "17159",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17159",
    county: "Richland",
  },
  {
    fips: "17169",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17169",
    county: "Schuyler",
  },
  {
    fips: "17173",
    count: 2,
    state: "IL",
    type: "County",
    locationid: "17173",
    county: "Shelby",
  },
  {
    fips: "18015",
    count: 2,
    state: "IN",
    type: "County",
    locationid: "18015",
    county: "Carroll",
  },
  {
    fips: "18021",
    count: 2,
    state: "IN",
    type: "County",
    locationid: "18021",
    county: "Clay",
  },
  {
    fips: "18131",
    count: 2,
    state: "IN",
    type: "County",
    locationid: "18131",
    county: "Pulaski",
  },
  {
    fips: "18135",
    count: 2,
    state: "IN",
    type: "County",
    locationid: "18135",
    county: "Randolph",
  },
  {
    fips: "18165",
    count: 2,
    state: "IN",
    type: "County",
    locationid: "18165",
    county: "Vermillion",
  },
  {
    fips: "19051",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19051",
    county: "Davis",
  },
  {
    fips: "19063",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19063",
    county: "Emmet",
  },
  {
    fips: "19067",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19067",
    county: "Floyd",
  },
  {
    fips: "19083",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19083",
    county: "Hardin",
  },
  {
    fips: "19089",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19089",
    county: "Howard",
  },
  {
    fips: "19107",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19107",
    county: "Keokuk",
  },
  {
    fips: "19119",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19119",
    county: "Lyon",
  },
  {
    fips: "19123",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19123",
    county: "Mahaska",
  },
  {
    fips: "19149",
    count: 2,
    state: "IA",
    type: "County",
    locationid: "19149",
    county: "Plymouth",
  },
  {
    fips: "20001",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20001",
    county: "Allen",
  },
  {
    fips: "20013",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20013",
    county: "Brown",
  },
  {
    fips: "20041",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20041",
    county: "Dickinson",
  },
  {
    fips: "20121",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20121",
    county: "Miami",
  },
  {
    fips: "20123",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20123",
    county: "Mitchell",
  },
  {
    fips: "20127",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20127",
    county: "Morris",
  },
  {
    fips: "20137",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20137",
    county: "Norton",
  },
  {
    fips: "20191",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20191",
    county: "Sumner",
  },
  {
    fips: "20193",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20193",
    county: "Thomas",
  },
  {
    fips: "20205",
    count: 2,
    state: "KS",
    type: "County",
    locationid: "20205",
    county: "Wilson",
  },
  {
    fips: "21011",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21011",
    county: "Bath",
  },
  {
    fips: "21017",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21017",
    county: "Bourbon",
  },
  {
    fips: "21033",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21033",
    county: "Caldwell",
  },
  {
    fips: "21043",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21043",
    county: "Carter",
  },
  {
    fips: "21053",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21053",
    county: "Clinton",
  },
  {
    fips: "21057",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21057",
    county: "Cumberland",
  },
  {
    fips: "21063",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21063",
    county: "Elliott",
  },
  {
    fips: "21115",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21115",
    county: "Johnson",
  },
  {
    fips: "21215",
    count: 2,
    state: "KY",
    type: "County",
    locationid: "21215",
    county: "Spencer",
  },
  {
    fips: "22035",
    count: 2,
    state: "LA",
    type: "County",
    locationid: "22035",
    county: "East Carroll",
  },
  {
    fips: "22067",
    count: 2,
    state: "LA",
    type: "County",
    locationid: "22067",
    county: "Morehouse",
  },
  {
    fips: "25007",
    count: 2,
    state: "MA",
    type: "County",
    locationid: "25007",
    county: "Dukes",
  },
  {
    fips: "26001",
    count: 2,
    state: "MI",
    type: "County",
    locationid: "26001",
    county: "Alcona",
  },
  {
    fips: "26003",
    count: 2,
    state: "MI",
    type: "County",
    locationid: "26003",
    county: "Alger",
  },
  {
    fips: "27001",
    count: 2,
    state: "MN",
    type: "County",
    locationid: "27001",
    county: "Aitkin",
  },
  {
    fips: "27119",
    count: 2,
    state: "MN",
    type: "County",
    locationid: "27119",
    county: "Polk",
  },
  {
    fips: "28013",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28013",
    county: "Calhoun",
  },
  {
    fips: "28079",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28079",
    county: "Leake",
  },
  {
    fips: "28091",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28091",
    county: "Marion",
  },
  {
    fips: "28103",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28103",
    county: "Noxubee",
  },
  {
    fips: "28115",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28115",
    county: "Pontotoc",
  },
  {
    fips: "28117",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28117",
    county: "Prentiss",
  },
  {
    fips: "28141",
    count: 2,
    state: "MS",
    type: "County",
    locationid: "28141",
    county: "Tishomingo",
  },
  {
    fips: "29011",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29011",
    county: "Barton",
  },
  {
    fips: "29013",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29013",
    county: "Bates",
  },
  {
    fips: "29039",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29039",
    county: "Cedar",
  },
  {
    fips: "29053",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29053",
    county: "Cooper",
  },
  {
    fips: "29081",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29081",
    county: "Harrison",
  },
  {
    fips: "29111",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29111",
    county: "Lewis",
  },
  {
    fips: "29133",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29133",
    county: "Mississippi",
  },
  {
    fips: "29147",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29147",
    county: "Nodaway",
  },
  {
    fips: "29209",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29209",
    county: "Stone",
  },
  {
    fips: "29221",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29221",
    county: "Washington",
  },
  {
    fips: "29229",
    count: 2,
    state: "MO",
    type: "County",
    locationid: "29229",
    county: "Wright",
  },
  {
    fips: "31041",
    count: 2,
    state: "NE",
    type: "County",
    locationid: "31041",
    county: "Custer",
  },
  {
    fips: "31043",
    count: 2,
    state: "NE",
    type: "County",
    locationid: "31043",
    county: "Dakota",
  },
  {
    fips: "31107",
    count: 2,
    state: "NE",
    type: "County",
    locationid: "31107",
    county: "Knox",
  },
  {
    fips: "31147",
    count: 2,
    state: "NE",
    type: "County",
    locationid: "31147",
    county: "Richardson",
  },
  {
    fips: "31173",
    count: 2,
    state: "NE",
    type: "County",
    locationid: "31173",
    county: "Thurston",
  },
  {
    fips: "35041",
    count: 2,
    state: "NM",
    type: "County",
    locationid: "35041",
    county: "Roosevelt",
  },
  {
    fips: "35053",
    count: 2,
    state: "NM",
    type: "County",
    locationid: "35053",
    county: "Socorro",
  },
  {
    fips: "36039",
    count: 2,
    state: "NY",
    type: "County",
    locationid: "36039",
    county: "Greene",
  },
  {
    fips: "37093",
    count: 2,
    state: "NC",
    type: "County",
    locationid: "37093",
    county: "Hoke",
  },
  {
    fips: "37115",
    count: 2,
    state: "NC",
    type: "County",
    locationid: "37115",
    county: "Madison",
  },
  {
    fips: "37117",
    count: 2,
    state: "NC",
    type: "County",
    locationid: "37117",
    county: "Martin",
  },
  {
    fips: "37187",
    count: 2,
    state: "NC",
    type: "County",
    locationid: "37187",
    county: "Washington",
  },
  {
    fips: "38029",
    count: 2,
    state: "ND",
    type: "County",
    locationid: "38029",
    county: "Emmons",
  },
  {
    fips: "38037",
    count: 2,
    state: "ND",
    type: "County",
    locationid: "38037",
    county: "Grant",
  },
  {
    fips: "38053",
    count: 2,
    state: "ND",
    type: "County",
    locationid: "38053",
    county: "Mckenzie",
  },
  {
    fips: "38055",
    count: 2,
    state: "ND",
    type: "County",
    locationid: "38055",
    county: "Mclean",
  },
  {
    fips: "39091",
    count: 2,
    state: "OH",
    type: "County",
    locationid: "39091",
    county: "Logan",
  },
  {
    fips: "40023",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40023",
    county: "Choctaw",
  },
  {
    fips: "40043",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40043",
    county: "Dewey",
  },
  {
    fips: "40057",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40057",
    county: "Harmon",
  },
  {
    fips: "40061",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40061",
    county: "Haskell",
  },
  {
    fips: "40067",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40067",
    county: "Jefferson",
  },
  {
    fips: "40075",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40075",
    county: "Kiowa",
  },
  {
    fips: "40091",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40091",
    county: "Mcintosh",
  },
  {
    fips: "40107",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40107",
    county: "Okfuskee",
  },
  {
    fips: "40117",
    count: 2,
    state: "OK",
    type: "County",
    locationid: "40117",
    county: "Pawnee",
  },
  {
    fips: "41001",
    count: 2,
    state: "OR",
    type: "County",
    locationid: "41001",
    county: "Baker",
  },
  {
    fips: "41023",
    count: 2,
    state: "OR",
    type: "County",
    locationid: "41023",
    county: "Grant",
  },
  {
    fips: "41031",
    count: 2,
    state: "OR",
    type: "County",
    locationid: "41031",
    county: "Jefferson",
  },
  {
    fips: "42057",
    count: 2,
    state: "PA",
    type: "County",
    locationid: "42057",
    county: "Fulton",
  },
  {
    fips: "45001",
    count: 2,
    state: "SC",
    type: "County",
    locationid: "45001",
    county: "Abbeville",
  },
  {
    fips: "45037",
    count: 2,
    state: "SC",
    type: "County",
    locationid: "45037",
    county: "Edgefield",
  },
  {
    fips: "45061",
    count: 2,
    state: "SC",
    type: "County",
    locationid: "45061",
    county: "Lee",
  },
  {
    fips: "45089",
    count: 2,
    state: "SC",
    type: "County",
    locationid: "45089",
    county: "Williamsburg",
  },
  {
    fips: "46015",
    count: 2,
    state: "SD",
    type: "County",
    locationid: "46015",
    county: "Brule",
  },
  {
    fips: "46047",
    count: 2,
    state: "SD",
    type: "County",
    locationid: "46047",
    county: "Fall River",
  },
  {
    fips: "46067",
    count: 2,
    state: "SD",
    type: "County",
    locationid: "46067",
    county: "Hutchinson",
  },
  {
    fips: "46109",
    count: 2,
    state: "SD",
    type: "County",
    locationid: "46109",
    county: "Roberts",
  },
  {
    fips: "47077",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47077",
    county: "Henderson",
  },
  {
    fips: "47081",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47081",
    county: "Hickman",
  },
  {
    fips: "47085",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47085",
    county: "Humphreys",
  },
  {
    fips: "47117",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47117",
    county: "Marshall",
  },
  {
    fips: "47137",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47137",
    county: "Pickett",
  },
  {
    fips: "47161",
    count: 2,
    state: "TN",
    type: "County",
    locationid: "47161",
    county: "Stewart",
  },
  {
    fips: "48063",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48063",
    county: "Camp",
  },
  {
    fips: "48071",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48071",
    county: "Chambers",
  },
  {
    fips: "48089",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48089",
    county: "Colorado",
  },
  {
    fips: "48123",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48123",
    county: "De Witt",
  },
  {
    fips: "48127",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48127",
    county: "Dimmit",
  },
  {
    fips: "48153",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48153",
    county: "Floyd",
  },
  {
    fips: "48161",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48161",
    county: "Freestone",
  },
  {
    fips: "48179",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48179",
    county: "Gray",
  },
  {
    fips: "48255",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48255",
    county: "Karnes",
  },
  {
    fips: "48281",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48281",
    county: "Lampasas",
  },
  {
    fips: "48299",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48299",
    county: "Llano",
  },
  {
    fips: "48365",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48365",
    county: "Panola",
  },
  {
    fips: "48371",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48371",
    county: "Pecos",
  },
  {
    fips: "48403",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48403",
    county: "Sabine",
  },
  {
    fips: "48407",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48407",
    county: "San Jacinto",
  },
  {
    fips: "48411",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48411",
    county: "San Saba",
  },
  {
    fips: "48425",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48425",
    county: "Somervell",
  },
  {
    fips: "48467",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48467",
    county: "Van Zandt",
  },
  {
    fips: "48501",
    count: 2,
    state: "TX",
    type: "County",
    locationid: "48501",
    county: "Yoakum",
  },
  {
    fips: "49003",
    count: 2,
    state: "UT",
    type: "County",
    locationid: "49003",
    county: "Box Elder",
  },
  {
    fips: "49007",
    count: 2,
    state: "UT",
    type: "County",
    locationid: "49007",
    county: "Carbon",
  },
  {
    fips: "49023",
    count: 2,
    state: "UT",
    type: "County",
    locationid: "49023",
    county: "Juab",
  },
  {
    fips: "49025",
    count: 2,
    state: "UT",
    type: "County",
    locationid: "49025",
    county: "Kane",
  },
  {
    fips: "50001",
    count: 2,
    state: "VT",
    type: "County",
    locationid: "50001",
    county: "Addison",
  },
  {
    fips: "51031",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51031",
    county: "Campbell",
  },
  {
    fips: "51113",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51113",
    county: "Madison",
  },
  {
    fips: "51135",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51135",
    county: "Nottoway",
  },
  {
    fips: "51139",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51139",
    county: "Page",
  },
  {
    fips: "51147",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51147",
    county: "Prince Edward",
  },
  {
    fips: "51175",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51175",
    county: "Southampton",
  },
  {
    fips: "51750",
    count: 2,
    state: "VA",
    type: "County",
    locationid: "51750",
    county: "Radford",
  },
  {
    fips: "53055",
    count: 2,
    state: "WA",
    type: "County",
    locationid: "53055",
    county: "San Juan",
  },
  {
    fips: "54007",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54007",
    county: "Braxton",
  },
  {
    fips: "54023",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54023",
    county: "Grant",
  },
  {
    fips: "54063",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54063",
    county: "Monroe",
  },
  {
    fips: "54065",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54065",
    county: "Morgan",
  },
  {
    fips: "54071",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54071",
    county: "Pendleton",
  },
  {
    fips: "54073",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54073",
    county: "Pleasants",
  },
  {
    fips: "54105",
    count: 2,
    state: "WV",
    type: "County",
    locationid: "54105",
    county: "Wirt",
  },
  {
    fips: "55007",
    count: 2,
    state: "WI",
    type: "County",
    locationid: "55007",
    county: "Bayfield",
  },
  {
    fips: "55019",
    count: 2,
    state: "WI",
    type: "County",
    locationid: "55019",
    county: "Clark",
  },
  {
    fips: "55065",
    count: 2,
    state: "WI",
    type: "County",
    locationid: "55065",
    county: "Lafayette",
  },
  {
    fips: "56023",
    count: 2,
    state: "WY",
    type: "County",
    locationid: "56023",
    county: "Lincoln",
  },
  {
    fips: "56031",
    count: 2,
    state: "WY",
    type: "County",
    locationid: "56031",
    county: "Platte",
  },
  {
    fips: "01009",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01009",
    county: "Blount",
  },
  {
    fips: "01013",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01013",
    county: "Butler",
  },
  {
    fips: "01029",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01029",
    county: "Cleburne",
  },
  {
    fips: "01037",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01037",
    county: "Coosa",
  },
  {
    fips: "01065",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01065",
    county: "Hale",
  },
  {
    fips: "01067",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01067",
    county: "Henry",
  },
  {
    fips: "01085",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01085",
    county: "Lowndes",
  },
  {
    fips: "01111",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01111",
    county: "Randolph",
  },
  {
    fips: "01131",
    count: 1,
    state: "AL",
    type: "County",
    locationid: "01131",
    county: "Wilcox",
  },
  {
    fips: "02050",
    count: 1,
    state: "AK",
    type: "County",
    locationid: "02050",
    county: "Bethel",
  },
  {
    fips: "02150",
    count: 1,
    state: "AK",
    type: "County",
    locationid: "02150",
    county: "Kodiak Island",
  },
  {
    fips: "04001",
    count: 1,
    state: "AZ",
    type: "County",
    locationid: "04001",
    county: "Apache",
  },
  {
    fips: "04009",
    count: 1,
    state: "AZ",
    type: "County",
    locationid: "04009",
    county: "Graham",
  },
  {
    fips: "04012",
    count: 1,
    state: "AZ",
    type: "County",
    locationid: "04012",
    county: "La Paz",
  },
  {
    fips: "04023",
    count: 1,
    state: "AZ",
    type: "County",
    locationid: "04023",
    county: "Santa Cruz",
  },
  {
    fips: "05021",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05021",
    county: "Clay",
  },
  {
    fips: "05033",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05033",
    county: "Crawford",
  },
  {
    fips: "05059",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05059",
    county: "Hot Spring",
  },
  {
    fips: "05061",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05061",
    county: "Howard",
  },
  {
    fips: "05097",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05097",
    county: "Montgomery",
  },
  {
    fips: "05109",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05109",
    county: "Pike",
  },
  {
    fips: "05149",
    count: 1,
    state: "AR",
    type: "County",
    locationid: "05149",
    county: "Yell",
  },
  {
    fips: "06051",
    count: 1,
    state: "CA",
    type: "County",
    locationid: "06051",
    county: "Mono",
  },
  {
    fips: "06105",
    count: 1,
    state: "CA",
    type: "County",
    locationid: "06105",
    county: "Trinity",
  },
  {
    fips: "08021",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08021",
    county: "Conejos",
  },
  {
    fips: "08039",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08039",
    county: "Elbert",
  },
  {
    fips: "08055",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08055",
    county: "Huerfano",
  },
  {
    fips: "08081",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08081",
    county: "Moffat",
  },
  {
    fips: "08089",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08089",
    county: "Otero",
  },
  {
    fips: "08099",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08099",
    county: "Prowers",
  },
  {
    fips: "08105",
    count: 1,
    state: "CO",
    type: "County",
    locationid: "08105",
    county: "Rio Grande",
  },
  {
    fips: "12047",
    count: 1,
    state: "FL",
    type: "County",
    locationid: "12047",
    county: "Hamilton",
  },
  {
    fips: "12059",
    count: 1,
    state: "FL",
    type: "County",
    locationid: "12059",
    county: "Holmes",
  },
  {
    fips: "12129",
    count: 1,
    state: "FL",
    type: "County",
    locationid: "12129",
    county: "Wakulla",
  },
  {
    fips: "13007",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13007",
    county: "Baker",
  },
  {
    fips: "13017",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13017",
    county: "Ben Hill",
  },
  {
    fips: "13023",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13023",
    county: "Bleckley",
  },
  {
    fips: "13049",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13049",
    county: "Charlton",
  },
  {
    fips: "13105",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13105",
    county: "Elbert",
  },
  {
    fips: "13145",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13145",
    county: "Harris",
  },
  {
    fips: "13147",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13147",
    county: "Hart",
  },
  {
    fips: "13173",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13173",
    county: "Lanier",
  },
  {
    fips: "13193",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13193",
    county: "Macon",
  },
  {
    fips: "13197",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13197",
    county: "Marion",
  },
  {
    fips: "13211",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13211",
    county: "Morgan",
  },
  {
    fips: "13237",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13237",
    county: "Putnam",
  },
  {
    fips: "13267",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13267",
    county: "Tattnall",
  },
  {
    fips: "13271",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13271",
    county: "Telfair",
  },
  {
    fips: "13301",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13301",
    county: "Warren",
  },
  {
    fips: "13317",
    count: 1,
    state: "GA",
    type: "County",
    locationid: "13317",
    county: "Wilkes",
  },
  {
    fips: "16007",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16007",
    county: "Bear Lake",
  },
  {
    fips: "16009",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16009",
    county: "Benewah",
  },
  {
    fips: "16035",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16035",
    county: "Clearwater",
  },
  {
    fips: "16037",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16037",
    county: "Custer",
  },
  {
    fips: "16039",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16039",
    county: "Elmore",
  },
  {
    fips: "16047",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16047",
    county: "Gooding",
  },
  {
    fips: "16059",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16059",
    county: "Lemhi",
  },
  {
    fips: "16073",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16073",
    county: "Owyhee",
  },
  {
    fips: "16077",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16077",
    county: "Power",
  },
  {
    fips: "16081",
    count: 1,
    state: "ID",
    type: "County",
    locationid: "16081",
    county: "Teton",
  },
  {
    fips: "17003",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17003",
    county: "Alexander",
  },
  {
    fips: "17007",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17007",
    county: "Boone",
  },
  {
    fips: "17017",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17017",
    county: "Cass",
  },
  {
    fips: "17033",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17033",
    county: "Crawford",
  },
  {
    fips: "17041",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17041",
    county: "Douglas",
  },
  {
    fips: "17079",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17079",
    county: "Jasper",
  },
  {
    fips: "17131",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17131",
    county: "Mercer",
  },
  {
    fips: "17155",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17155",
    county: "Putnam",
  },
  {
    fips: "17193",
    count: 1,
    state: "IL",
    type: "County",
    locationid: "17193",
    county: "White",
  },
  {
    fips: "18025",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18025",
    county: "Crawford",
  },
  {
    fips: "18041",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18041",
    county: "Fayette",
  },
  {
    fips: "18045",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18045",
    county: "Fountain",
  },
  {
    fips: "18047",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18047",
    county: "Franklin",
  },
  {
    fips: "18055",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18055",
    county: "Greene",
  },
  {
    fips: "18123",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18123",
    county: "Perry",
  },
  {
    fips: "18151",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18151",
    county: "Steuben",
  },
  {
    fips: "18183",
    count: 1,
    state: "IN",
    type: "County",
    locationid: "18183",
    county: "Whitley",
  },
  {
    fips: "19005",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19005",
    county: "Allamakee",
  },
  {
    fips: "19009",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19009",
    county: "Audubon",
  },
  {
    fips: "19011",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19011",
    county: "Benton",
  },
  {
    fips: "19035",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19035",
    county: "Cherokee",
  },
  {
    fips: "19069",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19069",
    county: "Franklin",
  },
  {
    fips: "19071",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19071",
    county: "Fremont",
  },
  {
    fips: "19075",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19075",
    county: "Grundy",
  },
  {
    fips: "19077",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19077",
    county: "Guthrie",
  },
  {
    fips: "19085",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19085",
    county: "Harrison",
  },
  {
    fips: "19095",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19095",
    county: "Iowa",
  },
  {
    fips: "19115",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19115",
    county: "Louisa",
  },
  {
    fips: "19117",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19117",
    county: "Lucas",
  },
  {
    fips: "19121",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19121",
    county: "Madison",
  },
  {
    fips: "19129",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19129",
    county: "Mills",
  },
  {
    fips: "19135",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19135",
    county: "Monroe",
  },
  {
    fips: "19143",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19143",
    county: "Osceola",
  },
  {
    fips: "19165",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19165",
    county: "Shelby",
  },
  {
    fips: "19185",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19185",
    county: "Wayne",
  },
  {
    fips: "19189",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19189",
    county: "Winnebago",
  },
  {
    fips: "19197",
    count: 1,
    state: "IA",
    type: "County",
    locationid: "19197",
    county: "Wright",
  },
  {
    fips: "20047",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20047",
    county: "Edwards",
  },
  {
    fips: "20049",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20049",
    county: "Elk",
  },
  {
    fips: "20063",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20063",
    county: "Gove",
  },
  {
    fips: "20081",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20081",
    county: "Haskell",
  },
  {
    fips: "20083",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20083",
    county: "Hodgeman",
  },
  {
    fips: "20093",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20093",
    county: "Kearny",
  },
  {
    fips: "20095",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20095",
    county: "Kingman",
  },
  {
    fips: "20105",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20105",
    county: "Lincoln",
  },
  {
    fips: "20115",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20115",
    county: "Marion",
  },
  {
    fips: "20131",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20131",
    county: "Nemaha",
  },
  {
    fips: "20149",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20149",
    county: "Pottawatomie",
  },
  {
    fips: "20151",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20151",
    county: "Pratt",
  },
  {
    fips: "20165",
    count: 1,
    state: "KS",
    type: "County",
    locationid: "20165",
    county: "Rush",
  },
  {
    fips: "21003",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21003",
    county: "Allen",
  },
  {
    fips: "21023",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21023",
    county: "Bracken",
  },
  {
    fips: "21031",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21031",
    county: "Butler",
  },
  {
    fips: "21109",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21109",
    county: "Jackson",
  },
  {
    fips: "21119",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21119",
    county: "Knott",
  },
  {
    fips: "21131",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21131",
    county: "Leslie",
  },
  {
    fips: "21135",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21135",
    county: "Lewis",
  },
  {
    fips: "21147",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21147",
    county: "Mccreary",
  },
  {
    fips: "21169",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21169",
    county: "Metcalfe",
  },
  {
    fips: "21175",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21175",
    county: "Morgan",
  },
  {
    fips: "21187",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21187",
    county: "Owen",
  },
  {
    fips: "21191",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21191",
    county: "Pendleton",
  },
  {
    fips: "21225",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21225",
    county: "Union",
  },
  {
    fips: "21233",
    count: 1,
    state: "KY",
    type: "County",
    locationid: "21233",
    county: "Webster",
  },
  {
    fips: "22013",
    count: 1,
    state: "LA",
    type: "County",
    locationid: "22013",
    county: "Bienville",
  },
  {
    fips: "22021",
    count: 1,
    state: "LA",
    type: "County",
    locationid: "22021",
    county: "Caldwell",
  },
  {
    fips: "22049",
    count: 1,
    state: "LA",
    type: "County",
    locationid: "22049",
    county: "Jackson",
  },
  {
    fips: "22065",
    count: 1,
    state: "LA",
    type: "County",
    locationid: "22065",
    county: "Madison",
  },
  {
    fips: "22095",
    count: 1,
    state: "LA",
    type: "County",
    locationid: "22095",
    county: "St John The Baptist",
  },
  {
    fips: "26151",
    count: 1,
    state: "MI",
    type: "County",
    locationid: "26151",
    county: "Sanilac",
  },
  {
    fips: "27023",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27023",
    county: "Chippewa",
  },
  {
    fips: "27029",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27029",
    county: "Clearwater",
  },
  {
    fips: "27031",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27031",
    county: "Cook",
  },
  {
    fips: "27039",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27039",
    county: "Dodge",
  },
  {
    fips: "27043",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27043",
    county: "Faribault",
  },
  {
    fips: "27051",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27051",
    county: "Grant",
  },
  {
    fips: "27065",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27065",
    county: "Kanabec",
  },
  {
    fips: "27071",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27071",
    county: "Koochiching",
  },
  {
    fips: "27115",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27115",
    county: "Pine",
  },
  {
    fips: "27121",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27121",
    county: "Pope",
  },
  {
    fips: "27127",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27127",
    county: "Redwood",
  },
  {
    fips: "27129",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27129",
    county: "Renville",
  },
  {
    fips: "27135",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27135",
    county: "Roseau",
  },
  {
    fips: "27153",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27153",
    county: "Todd",
  },
  {
    fips: "27169",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27169",
    county: "Winona",
  },
  {
    fips: "27173",
    count: 1,
    state: "MN",
    type: "County",
    locationid: "27173",
    county: "Yellow Medicine",
  },
  {
    fips: "28021",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28021",
    county: "Claiborne",
  },
  {
    fips: "28023",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28023",
    county: "Clarke",
  },
  {
    fips: "28063",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28063",
    county: "Jefferson",
  },
  {
    fips: "28069",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28069",
    county: "Kemper",
  },
  {
    fips: "28097",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28097",
    county: "Montgomery",
  },
  {
    fips: "28111",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28111",
    county: "Perry",
  },
  {
    fips: "28125",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28125",
    county: "Sharkey",
  },
  {
    fips: "28139",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28139",
    county: "Tippah",
  },
  {
    fips: "28147",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28147",
    county: "Walthall",
  },
  {
    fips: "28155",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28155",
    county: "Webster",
  },
  {
    fips: "28161",
    count: 1,
    state: "MS",
    type: "County",
    locationid: "28161",
    county: "Yalobusha",
  },
  {
    fips: "29033",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29033",
    county: "Carroll",
  },
  {
    fips: "29035",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29035",
    county: "Carter",
  },
  {
    fips: "29059",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29059",
    county: "Dallas",
  },
  {
    fips: "29061",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29061",
    county: "Daviess",
  },
  {
    fips: "29073",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29073",
    county: "Gasconade",
  },
  {
    fips: "29117",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29117",
    county: "Livingston",
  },
  {
    fips: "29125",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29125",
    county: "Maries",
  },
  {
    fips: "29137",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29137",
    county: "Monroe",
  },
  {
    fips: "29139",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29139",
    county: "Montgomery",
  },
  {
    fips: "29141",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29141",
    county: "Morgan",
  },
  {
    fips: "29149",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29149",
    county: "Oregon",
  },
  {
    fips: "29175",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29175",
    county: "Randolph",
  },
  {
    fips: "29185",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29185",
    county: "Saint Clair",
  },
  {
    fips: "29195",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29195",
    county: "Saline",
  },
  {
    fips: "29211",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29211",
    county: "Sullivan",
  },
  {
    fips: "29219",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29219",
    county: "Warren",
  },
  {
    fips: "29223",
    count: 1,
    state: "MO",
    type: "County",
    locationid: "29223",
    county: "Wayne",
  },
  {
    fips: "30001",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30001",
    county: "Beaverhead",
  },
  {
    fips: "30005",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30005",
    county: "Blaine",
  },
  {
    fips: "30017",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30017",
    county: "Custer",
  },
  {
    fips: "30043",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30043",
    county: "Jefferson",
  },
  {
    fips: "30061",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30061",
    county: "Mineral",
  },
  {
    fips: "30073",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30073",
    county: "Pondera",
  },
  {
    fips: "30077",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30077",
    county: "Powell",
  },
  {
    fips: "30089",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30089",
    county: "Sanders",
  },
  {
    fips: "30095",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30095",
    county: "Stillwater",
  },
  {
    fips: "30099",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30099",
    county: "Teton",
  },
  {
    fips: "30105",
    count: 1,
    state: "MT",
    type: "County",
    locationid: "30105",
    county: "Valley",
  },
  {
    fips: "31021",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31021",
    county: "Burt",
  },
  {
    fips: "31023",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31023",
    county: "Butler",
  },
  {
    fips: "31031",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31031",
    county: "Cherry",
  },
  {
    fips: "31035",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31035",
    county: "Clay",
  },
  {
    fips: "31045",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31045",
    county: "Dawes",
  },
  {
    fips: "31065",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31065",
    county: "Furnas",
  },
  {
    fips: "31067",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31067",
    county: "Gage",
  },
  {
    fips: "31069",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31069",
    county: "Garden",
  },
  {
    fips: "31093",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31093",
    county: "Howard",
  },
  {
    fips: "31105",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31105",
    county: "Kimball",
  },
  {
    fips: "31123",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31123",
    county: "Morrill",
  },
  {
    fips: "31129",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31129",
    county: "Nuckolls",
  },
  {
    fips: "31131",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31131",
    county: "Otoe",
  },
  {
    fips: "31145",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31145",
    county: "Red Willow",
  },
  {
    fips: "31151",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31151",
    county: "Saline",
  },
  {
    fips: "31159",
    count: 1,
    state: "NE",
    type: "County",
    locationid: "31159",
    county: "Seward",
  },
  {
    fips: "32001",
    count: 1,
    state: "NV",
    type: "County",
    locationid: "32001",
    county: "Churchill",
  },
  {
    fips: "32033",
    count: 1,
    state: "NV",
    type: "County",
    locationid: "32033",
    county: "White Pine",
  },
  {
    fips: "35007",
    count: 1,
    state: "NM",
    type: "County",
    locationid: "35007",
    county: "Colfax",
  },
  {
    fips: "35023",
    count: 1,
    state: "NM",
    type: "County",
    locationid: "35023",
    county: "Hidalgo",
  },
  {
    fips: "36097",
    count: 1,
    state: "NY",
    type: "County",
    locationid: "36097",
    county: "Schuyler",
  },
  {
    fips: "37003",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37003",
    county: "Alexander",
  },
  {
    fips: "37007",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37007",
    county: "Anson",
  },
  {
    fips: "37011",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37011",
    county: "Avery",
  },
  {
    fips: "37075",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37075",
    county: "Graham",
  },
  {
    fips: "37137",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37137",
    county: "Pamlico",
  },
  {
    fips: "37143",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37143",
    county: "Perquimans",
  },
  {
    fips: "37185",
    count: 1,
    state: "NC",
    type: "County",
    locationid: "37185",
    county: "Warren",
  },
  {
    fips: "38001",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38001",
    county: "Adams",
  },
  {
    fips: "38005",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38005",
    county: "Benson",
  },
  {
    fips: "38021",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38021",
    county: "Dickey",
  },
  {
    fips: "38027",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38027",
    county: "Eddy",
  },
  {
    fips: "38031",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38031",
    county: "Foster",
  },
  {
    fips: "38049",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38049",
    county: "Mchenry",
  },
  {
    fips: "38095",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38095",
    county: "Towner",
  },
  {
    fips: "38099",
    count: 1,
    state: "ND",
    type: "County",
    locationid: "38099",
    county: "Walsh",
  },
  {
    fips: "39019",
    count: 1,
    state: "OH",
    type: "County",
    locationid: "39019",
    county: "Carroll",
  },
  {
    fips: "39105",
    count: 1,
    state: "OH",
    type: "County",
    locationid: "39105",
    county: "Meigs",
  },
  {
    fips: "39125",
    count: 1,
    state: "OH",
    type: "County",
    locationid: "39125",
    county: "Paulding",
  },
  {
    fips: "39163",
    count: 1,
    state: "OH",
    type: "County",
    locationid: "39163",
    county: "Vinton",
  },
  {
    fips: "40003",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40003",
    county: "Alfalfa",
  },
  {
    fips: "40029",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40029",
    county: "Coal",
  },
  {
    fips: "40059",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40059",
    county: "Harper",
  },
  {
    fips: "40063",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40063",
    county: "Hughes",
  },
  {
    fips: "40073",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40073",
    county: "Kingfisher",
  },
  {
    fips: "40093",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40093",
    county: "Major",
  },
  {
    fips: "40095",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40095",
    county: "Marshall",
  },
  {
    fips: "40103",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40103",
    county: "Noble",
  },
  {
    fips: "40105",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40105",
    county: "Nowata",
  },
  {
    fips: "40127",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40127",
    county: "Pushmataha",
  },
  {
    fips: "40149",
    count: 1,
    state: "OK",
    type: "County",
    locationid: "40149",
    county: "Washita",
  },
  {
    fips: "41025",
    count: 1,
    state: "OR",
    type: "County",
    locationid: "41025",
    county: "Harney",
  },
  {
    fips: "41049",
    count: 1,
    state: "OR",
    type: "County",
    locationid: "41049",
    county: "Morrow",
  },
  {
    fips: "42053",
    count: 1,
    state: "PA",
    type: "County",
    locationid: "42053",
    county: "Forest",
  },
  {
    fips: "42067",
    count: 1,
    state: "PA",
    type: "County",
    locationid: "42067",
    county: "Juniata",
  },
  {
    fips: "45005",
    count: 1,
    state: "SC",
    type: "County",
    locationid: "45005",
    county: "Allendale",
  },
  {
    fips: "45065",
    count: 1,
    state: "SC",
    type: "County",
    locationid: "45065",
    county: "Mccormick",
  },
  {
    fips: "46003",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46003",
    county: "Aurora",
  },
  {
    fips: "46009",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46009",
    county: "Bon Homme",
  },
  {
    fips: "46033",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46033",
    county: "Custer",
  },
  {
    fips: "46051",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46051",
    county: "Grant",
  },
  {
    fips: "46053",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46053",
    county: "Gregory",
  },
  {
    fips: "46059",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46059",
    county: "Hand",
  },
  {
    fips: "46061",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46061",
    county: "Hanson",
  },
  {
    fips: "46073",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46073",
    county: "Jerauld",
  },
  {
    fips: "46077",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46077",
    county: "Kingsbury",
  },
  {
    fips: "46081",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46081",
    county: "Lawrence",
  },
  {
    fips: "46085",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46085",
    county: "Lyman",
  },
  {
    fips: "46091",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46091",
    county: "Marshall",
  },
  {
    fips: "46107",
    count: 1,
    state: "SD",
    type: "County",
    locationid: "46107",
    county: "Potter",
  },
  {
    fips: "47005",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47005",
    county: "Benton",
  },
  {
    fips: "47023",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47023",
    county: "Chester",
  },
  {
    fips: "47027",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47027",
    county: "Clay",
  },
  {
    fips: "47057",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47057",
    county: "Grainger",
  },
  {
    fips: "47067",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47067",
    county: "Hancock",
  },
  {
    fips: "47095",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47095",
    county: "Lake",
  },
  {
    fips: "47101",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47101",
    county: "Lewis",
  },
  {
    fips: "47139",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47139",
    county: "Polk",
  },
  {
    fips: "47153",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47153",
    county: "Sequatchie",
  },
  {
    fips: "47159",
    count: 1,
    state: "TN",
    type: "County",
    locationid: "47159",
    county: "Smith",
  },
  {
    fips: "48023",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48023",
    county: "Baylor",
  },
  {
    fips: "48051",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48051",
    county: "Burleson",
  },
  {
    fips: "48057",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48057",
    county: "Calhoun",
  },
  {
    fips: "48077",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48077",
    county: "Clay",
  },
  {
    fips: "48109",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48109",
    county: "Culberson",
  },
  {
    fips: "48115",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48115",
    county: "Dawson",
  },
  {
    fips: "48151",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48151",
    county: "Fisher",
  },
  {
    fips: "48163",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48163",
    county: "Frio",
  },
  {
    fips: "48175",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48175",
    county: "Goliad",
  },
  {
    fips: "48177",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48177",
    county: "Gonzales",
  },
  {
    fips: "48211",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48211",
    county: "Hemphill",
  },
  {
    fips: "48271",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48271",
    county: "Kinney",
  },
  {
    fips: "48273",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48273",
    county: "Kleberg",
  },
  {
    fips: "48305",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48305",
    county: "Lynn",
  },
  {
    fips: "48307",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48307",
    county: "Mcculloch",
  },
  {
    fips: "48357",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48357",
    county: "Ochiltree",
  },
  {
    fips: "48363",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48363",
    county: "Palo Pinto",
  },
  {
    fips: "48383",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48383",
    county: "Reagan",
  },
  {
    fips: "48387",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48387",
    county: "Red River",
  },
  {
    fips: "48389",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48389",
    county: "Reeves",
  },
  {
    fips: "48405",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48405",
    county: "San Augustine",
  },
  {
    fips: "48415",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48415",
    county: "Scurry",
  },
  {
    fips: "48445",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48445",
    county: "Terry",
  },
  {
    fips: "48457",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48457",
    county: "Tyler",
  },
  {
    fips: "48473",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48473",
    county: "Waller",
  },
  {
    fips: "48505",
    count: 1,
    state: "TX",
    type: "County",
    locationid: "48505",
    county: "Zapata",
  },
  {
    fips: "49037",
    count: 1,
    state: "UT",
    type: "County",
    locationid: "49037",
    county: "San Juan",
  },
  {
    fips: "49039",
    count: 1,
    state: "UT",
    type: "County",
    locationid: "49039",
    county: "Sanpete",
  },
  {
    fips: "50009",
    count: 1,
    state: "VT",
    type: "County",
    locationid: "50009",
    county: "Essex",
  },
  {
    fips: "51011",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51011",
    county: "Appomattox",
  },
  {
    fips: "51017",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51017",
    county: "Bath",
  },
  {
    fips: "51021",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51021",
    county: "Bland",
  },
  {
    fips: "51025",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51025",
    county: "Brunswick",
  },
  {
    fips: "51029",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51029",
    county: "Buckingham",
  },
  {
    fips: "51037",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51037",
    county: "Charlotte",
  },
  {
    fips: "51043",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51043",
    county: "Clarke",
  },
  {
    fips: "51053",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51053",
    county: "Dinwiddie",
  },
  {
    fips: "51071",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51071",
    county: "Giles",
  },
  {
    fips: "51093",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51093",
    county: "Isle Of Wight",
  },
  {
    fips: "51097",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51097",
    county: "King And Queen",
  },
  {
    fips: "51101",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51101",
    county: "King William",
  },
  {
    fips: "51111",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51111",
    county: "Lunenburg",
  },
  {
    fips: "51127",
    count: 1,
    state: "VA",
    type: "County",
    locationid: "51127",
    county: "New Kent",
  },
  {
    fips: "53001",
    count: 1,
    state: "WA",
    type: "County",
    locationid: "53001",
    county: "Adams",
  },
  {
    fips: "53013",
    count: 1,
    state: "WA",
    type: "County",
    locationid: "53013",
    county: "Columbia",
  },
  {
    fips: "53043",
    count: 1,
    state: "WA",
    type: "County",
    locationid: "53043",
    county: "Lincoln",
  },
  {
    fips: "54013",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54013",
    county: "Calhoun",
  },
  {
    fips: "54015",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54015",
    county: "Clay",
  },
  {
    fips: "54017",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54017",
    county: "Doddridge",
  },
  {
    fips: "54027",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54027",
    county: "Hampshire",
  },
  {
    fips: "54031",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54031",
    county: "Hardy",
  },
  {
    fips: "54041",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54041",
    county: "Lewis",
  },
  {
    fips: "54043",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54043",
    county: "Lincoln",
  },
  {
    fips: "54047",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54047",
    county: "Mcdowell",
  },
  {
    fips: "54057",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54057",
    county: "Mineral",
  },
  {
    fips: "54091",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54091",
    county: "Taylor",
  },
  {
    fips: "54093",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54093",
    county: "Tucker",
  },
  {
    fips: "54101",
    count: 1,
    state: "WV",
    type: "County",
    locationid: "54101",
    county: "Webster",
  },
  {
    fips: "55011",
    count: 1,
    state: "WI",
    type: "County",
    locationid: "55011",
    county: "Buffalo",
  },
  {
    fips: "55013",
    count: 1,
    state: "WI",
    type: "County",
    locationid: "55013",
    county: "Burnett",
  },
  {
    fips: "55083",
    count: 1,
    state: "WI",
    type: "County",
    locationid: "55083",
    county: "Oconto",
  },
  {
    fips: "55099",
    count: 1,
    state: "WI",
    type: "County",
    locationid: "55099",
    county: "Price",
  },
  {
    fips: "55103",
    count: 1,
    state: "WI",
    type: "County",
    locationid: "55103",
    county: "Richland",
  },
  {
    fips: "56007",
    count: 1,
    state: "WY",
    type: "County",
    locationid: "56007",
    county: "Carbon",
  },
  {
    fips: "56011",
    count: 1,
    state: "WY",
    type: "County",
    locationid: "56011",
    county: "Crook",
  },
  {
    fips: "56017",
    count: 1,
    state: "WY",
    type: "County",
    locationid: "56017",
    county: "Hot Springs",
  },
  {
    fips: "56043",
    count: 1,
    state: "WY",
    type: "County",
    locationid: "56043",
    county: "Washakie",
  },
];
