
import React, { useState } from "react";
import { DatePicker, Image, Space } from "antd"
import calendarImg from "../../../assets/img/calendar.png";
import moment from 'moment';


const CustomDatePicker = (props) => {

    const dateFormat = 'YYYY-MM-DD';
    const [fromDate, setFromDate] = useState(moment(new Date("2021-07-01")).format(dateFormat));
    const [toDate, setToDate] = useState(moment(new Date("2021-12-01")).format(dateFormat));

    const updateFromDate = (dateevent) => {

        props.getFromDate(moment(dateevent._d).format("YYYY-MM-DD"))
    }

    const updatetoDate = (dateevent) => {
        props.getToDate(moment(dateevent._d).format("YYYY-MM-DD"))
    }

    return (
        <div className="" style={{ textAlign: "left" }}>
          <div className="date-ctn-div"> 
           <p className="title">Start Date</p>
            <div className="calendar-picker">
                <Space>   <DatePicker format="MMM, YYYY" picker="month" defaultValue={moment(fromDate, dateFormat)} onChange={(e) => updateFromDate(e)}
                    suffixIcon={<Image className="calendar-img" preview={false} width={25} src={calendarImg} />}  ></DatePicker>
                       </Space>            </div></div>
                       <div  className="date-ctn-div">
                <p className="title">End Date</p>
            <div className="calendar-picker">
                <Space>    <DatePicker format="MMM, YYYY" picker="month" defaultValue={moment(toDate, dateFormat)} onChange={(e) =>
                        updatetoDate(e)}
                        suffixIcon={<Image className="calendar-img" preview={false} width={25} src={calendarImg} />} ></DatePicker>
                </Space>            </div></div>
        </div>

    )
}

export default CustomDatePicker;