import React from "react";
import { FooterWrapper } from "./Footer.styles";
import { Footer } from "antd/lib/layout/layout";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined} from '@ant-design/icons';

const AZFooter = () => (
  <FooterWrapper>
    <Footer className="footer">
      <span>© 2021 AstraZeneca. All Rights Reserved. </span>
    </Footer>
  </FooterWrapper>
);

export default AZFooter;
