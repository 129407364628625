import React from 'react';
import PropTypes from 'prop-types';
import { DashboardWrapper } from './Dashboard.styles';

const Dashboard = (props) => (
  <DashboardWrapper>
    Test content
  </DashboardWrapper>
);

Dashboard.propTypes = {
  // bla: PropTypes.string,
};

Dashboard.defaultProps = {
  // bla: 'test',
};

export default Dashboard;
