import jwtDecode from "jwt-decode";
import CONFIG from "../config";

export function checkAuth() {
  let c_url = new URL(window.location.href);
  let searchParams = new URLSearchParams(c_url.search);
  let token = getCookie("access-token");

  console.log('access_token:', searchParams.get("access_token"));
  console.log('code:', searchParams.get("code"));

  if (!token) {
    if (searchParams.get("code") || searchParams.get("access_token")) {
      var access_token = searchParams.get("access_token"); /// jwt token";
      var decoded = jwtDecode(access_token);
      console.log("decoded", decoded);

      if (decoded.exp) {
        var exp = decoded.exp;
        var username = decoded.first_name + " " + decoded.last_name;

        setCookie("userName", username, exp);
        setCookie("firstName", decoded.first_name, exp);
        setCookie("lastName", decoded.last_name, exp);
        setCookie("sub", decoded.id, exp);
        setCookie("jobTitle", decoded.designation, exp);
        setCookie("email", decoded.email, exp);
        setCookie("access-token", searchParams.get("access_token"), exp);
        setCookie("refresh-token", searchParams.get("refresh_token"), exp);
        setTimeout(() => {
          const _url = window.location.href.split("?")[0];
          window.location.href = _url;
        }, 1000);
      }
    } else {
      redirectToPing();
    }
  }
}

export function isAuthenticated() {
  let c_url = new URL(window.location.href);
  let searchParams = new URLSearchParams(c_url.search);
  let token = getCookie("access-token");

  if (token === null || token === "") {
    return !!(searchParams.get("code") || searchParams.get("access_token"));
  } else {
    return true;
  }
}

export async function getRefreshToken() {
  var isRefreshToken = false;
  let refreshToken = getCookie("refresh-token");

  try {
    var response = await fetch(
      "/identity/ping/refreshtoken?refreshToken=" +
      refreshToken +
      "&grantType=refresh_token"
    ).then(res => res.json());
    isRefreshToken = true;
    var decoded = jwtDecode(response.access_token);
    if (decoded.exp) {
      setCookie("access-token", response.access_token, decoded.exp);
      setCookie("refresh-token", response.refresh_token, decoded.exp);
    }
  } catch(error) {
    console.error("Error", error);
  }

  return isRefreshToken;
}
export function redirectToPing() {
  let client_uri = "";
  if (
    localStorage.getItem("isPrevState") != "" &&
    localStorage.getItem("isPrevState") != undefined
  ) {
    var CURRENTURL = localStorage.getItem("CURRENTURL");
    var CURRENTURLHOST = localStorage.getItem("CURRENTURLHOST");
    var CURRENTURLPATHNAME = localStorage.getItem("CURRENTURLPATHNAME");
    client_uri = CURRENTURL + CURRENTURLHOST + CURRENTURLPATHNAME;
  } else client_uri = window.location.protocol + "//" + window.location.host;
  let base64Str = window.btoa(client_uri);

  let uri =
    CONFIG.api.auth + "?client_uri=" + base64Str;
  console.log(`uri: ${uri}`);
  window.location.href = uri;

}

export function setCookie(cname, cvalue, exdays) {
  if (cname === "refresh-token") {
    var d = new Date();
    var minutes = 90;

    d.setTime(d.getTime() + minutes * 60 * 1000);

    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    const d = new Date(exdays * 1000);
    let expires = "expires=" + d.toUTCString();

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function checkCookie() {
  let user = getCookie("username");

  if (!user) {
    user = prompt("Please enter your name:", "");

    if (user) {
      setCookie("username", user, 365);
    }
  }
}
