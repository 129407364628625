import styled from 'styled-components';


export const SankeyPlotWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 12px;
  padding: 50px 30px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: 16px;
`;
export const SankeyPlotContainer = styled.div`
  flex: 1;
  margin: 0px 10px;
`;
