import styled from "styled-components";

export const DrillDownPieWrapper = styled.div`
  width: 100vw;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  canvas {
    position: relative;
  }
`;
