export default [
  {
    fip: "25013",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 587,
  },
  {
    fip: "25027",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 561,
  },
  {
    fip: "25017",
    territory: "Boston MA 1",
    territoryId: "20086293",
    district: "New England",
    region: "East - IO",
    count: 174,
  },
  {
    fip: "25009",
    territory: "Boston MA 1",
    territoryId: "20086293",
    district: "New England",
    region: "East - IO",
    count: 408,
  },
  {
    fip: "25025",
    territory: "Boston MA 1",
    territoryId: "20086293",
    district: "New England",
    region: "East - IO",
    count: 44,
  },
  {
    fip: "25023",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 117,
  },
  {
    fip: "25007",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "25001",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 100,
  },
  {
    fip: "25005",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 372,
  },
  {
    fip: "33015",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 198,
  },
  {
    fip: "33011",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 245,
  },
  {
    fip: "33013",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 227,
  },
  {
    fip: "33003",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23031",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23005",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 676,
  },
  {
    fip: "23017",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23001",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23029",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23019",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 334,
  },
  {
    fip: "23013",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23015",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23009",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23011",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 54,
  },
  {
    fip: "23025",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50027",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50025",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50011",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50007",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 250,
  },
  {
    fip: "50001",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50023",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50005",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "09003",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 950,
  },
  {
    fip: "09015",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "09011",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 149,
  },
  {
    fip: "36103",
    territory: "Suffolk NY 1",
    territoryId: "20086285",
    district: "New York NY",
    region: "East - IO",
    count: 130,
  },
  {
    fip: "09009",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 1036,
  },
  {
    fip: "09007",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 100,
  },
  {
    fip: "09005",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 94,
  },
  {
    fip: "09001",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 560,
  },
  {
    fip: "34013",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 113,
  },
  {
    fip: "34035",
    territory: "Princeton NJ 1",
    territoryId: "20086332",
    district: "New York NY",
    region: "East - IO",
    count: 369,
  },
  {
    fip: "34039",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 301,
  },
  {
    fip: "34017",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 178,
  },
  {
    fip: "34037",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 73,
  },
  {
    fip: "34031",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 202,
  },
  {
    fip: "34025",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 168,
  },
  {
    fip: "34027",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 459,
  },
  {
    fip: "34005",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 408,
  },
  {
    fip: "34029",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 491,
  },
  {
    fip: "34007",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 609,
  },
  {
    fip: "34001",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 155,
  },
  {
    fip: "34011",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 141,
  },
  {
    fip: "34033",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "34021",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 179,
  },
  {
    fip: "34023",
    territory: "Princeton NJ 1",
    territoryId: "20086332",
    district: "New York NY",
    region: "East - IO",
    count: 54,
  },
  {
    fip: "36061",
    territory: "Lower Manhattan NY 1",
    territoryId: "20086255",
    district: "New York NY",
    region: "East - IO",
    count: 217,
  },
  {
    fip: "36085",
    territory: "Princeton NJ 1",
    territoryId: "20086332",
    district: "New York NY",
    region: "East - IO",
    count: 364,
  },
  {
    fip: "36005",
    territory: "New York NY 1",
    territoryId: "20086311",
    district: "New York NY",
    region: "East - IO",
    count: 106,
  },
  {
    fip: "36079",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36119",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 796,
  },
  {
    fip: "36071",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 285,
  },
  {
    fip: "36087",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 190,
  },
  {
    fip: "36047",
    territory: "Lower Manhattan NY 1",
    territoryId: "20086255",
    district: "New York NY",
    region: "East - IO",
    count: 86,
  },
  {
    fip: "36081",
    territory: "Nassau NY 1",
    territoryId: "20086300",
    district: "New York NY",
    region: "East - IO",
    count: 848,
  },
  {
    fip: "36059",
    territory: "Suffolk NY 1",
    territoryId: "20086285",
    district: "New York NY",
    region: "East - IO",
    count: 118,
  },
  {
    fip: "36091",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 235,
  },
  {
    fip: "36093",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36001",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 749,
  },
  {
    fip: "36039",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36021",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36095",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36111",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 97,
  },
  {
    fip: "36027",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 241,
  },
  {
    fip: "36105",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36031",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36109",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36067",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 682,
  },
  {
    fip: "36065",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 123,
  },
  {
    fip: "36089",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 105,
  },
  {
    fip: "36045",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36025",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36007",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 182,
  },
  {
    fip: "36023",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36107",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36077",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 190,
  },
  {
    fip: "36009",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 55,
  },
  {
    fip: "36063",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 109,
  },
  {
    fip: "36029",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 915,
  },
  {
    fip: "36037",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36069",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 90,
  },
  {
    fip: "36051",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36099",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36123",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36117",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36055",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 615,
  },
  {
    fip: "36003",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36097",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36101",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42007",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 181,
  },
  {
    fip: "42003",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 27,
  },
  {
    fip: "42125",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 208,
  },
  {
    fip: "42059",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42051",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 109,
  },
  {
    fip: "42111",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42129",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 265,
  },
  {
    fip: "42063",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42005",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42021",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 151,
  },
  {
    fip: "42073",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42085",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 101,
  },
  {
    fip: "42123",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42121",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42053",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42031",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42049",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 282,
  },
  {
    fip: "42061",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42105",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42083",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42033",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 71,
  },
  {
    fip: "42027",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 90,
  },
  {
    fip: "42117",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42041",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 190,
  },
  {
    fip: "42043",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 411,
  },
  {
    fip: "42055",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 114,
  },
  {
    fip: "42001",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42133",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 280,
  },
  {
    fip: "42071",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 325,
  },
  {
    fip: "42035",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42081",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42109",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42107",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42095",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 666,
  },
  {
    fip: "42025",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42103",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42089",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42127",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42115",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42069",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 311,
  },
  {
    fip: "42131",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42079",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 253,
  },
  {
    fip: "42015",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 123,
  },
  {
    fip: "42017",
    territory: "Philadelphia PA 1",
    territoryId: "20086299",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 419,
  },
  {
    fip: "42091",
    territory: "Philadelphia PA 1",
    territoryId: "20086299",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 66,
  },
  {
    fip: "42045",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 107,
  },
  {
    fip: "42101",
    territory: "Philadelphia PA 1",
    territoryId: "20086299",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 42,
  },
  {
    fip: "00000",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42011",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 264,
  },
  {
    fip: "10003",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 495,
  },
  {
    fip: "10005",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 262,
  },
  {
    fip: "11001",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 169,
  },
  {
    fip: "51059",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 683,
  },
  {
    fip: "51153",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 173,
  },
  {
    fip: "51107",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 112,
  },
  {
    fip: "51013",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 172,
  },
  {
    fip: "24037",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24033",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 352,
  },
  {
    fip: "24009",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24031",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 788,
  },
  {
    fip: "24003",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 279,
  },
  {
    fip: "24013",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24005",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 91,
  },
  {
    fip: "24510",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 121,
  },
  {
    fip: "24001",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 91,
  },
  {
    fip: "24011",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24027",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 144,
  },
  {
    fip: "24043",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 147,
  },
  {
    fip: "24047",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24045",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 201,
  },
  {
    fip: "24039",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24015",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 131,
  },
  {
    fip: "51033",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51103",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51171",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51047",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51003",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 155,
  },
  {
    fip: "51109",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51087",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 436,
  },
  {
    fip: "51119",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51075",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51041",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 339,
  },
  {
    fip: "51036",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51760",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 553,
  },
  {
    fip: "51131",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51093",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51199",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51175",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51147",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51037",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51155",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51690",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51019",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51067",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51169",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51191",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51035",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51790",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51015",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51031",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51185",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51051",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54055",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54047",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54025",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54079",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54019",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54005",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54035",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54065",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54037",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54043",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54045",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54011",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 282,
  },
  {
    fip: "54109",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54081",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54089",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54107",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54097",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54083",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54033",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54017",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54103",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54061",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 200,
  },
  {
    fip: "54077",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54021",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54067",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37067",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 64,
  },
  {
    fip: "37169",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37081",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 211,
  },
  {
    fip: "37057",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37033",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37183",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 529,
  },
  {
    fip: "37145",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37041",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37071",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 200,
  },
  {
    fip: "37179",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37159",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37007",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37119",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 33,
  },
  {
    fip: "37155",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 95,
  },
  {
    fip: "37051",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 285,
  },
  {
    fip: "37129",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 444,
  },
  {
    fip: "37141",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37031",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37133",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37137",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37011",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37021",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 498,
  },
  {
    fip: "37089",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 77,
  },
  {
    fip: "45075",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45009",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45027",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45063",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 119,
  },
  {
    fip: "45079",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 424,
  },
  {
    fip: "45059",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45019",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 79,
  },
  {
    fip: "45043",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45035",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45029",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45069",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45041",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 172,
  },
  {
    fip: "45067",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45077",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45091",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 129,
  },
  {
    fip: "45003",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 106,
  },
  {
    fip: "45013",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 220,
  },
  {
    fip: "25015",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "25003",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "25021",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 198,
  },
  {
    fip: "44009",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "44007",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 825,
  },
  {
    fip: "33009",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 91,
  },
  {
    fip: "33005",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "33019",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "33017",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 247,
  },
  {
    fip: "23003",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23027",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50017",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50003",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50021",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50019",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "09013",
    territory: "Bridgeport CT 1",
    territoryId: "20086271",
    district: "New England",
    region: "East - IO",
    count: 85,
  },
  {
    fip: "34003",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 86,
  },
  {
    fip: "34041",
    territory: "Morristown NJ 1",
    territoryId: "20086257",
    district: "New York NY",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "34015",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 99,
  },
  {
    fip: "34009",
    territory: "Toms River NJ 1",
    territoryId: "20086290",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 112,
  },
  {
    fip: "34019",
    territory: "Princeton NJ 1",
    territoryId: "20086332",
    district: "New York NY",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36083",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 96,
  },
  {
    fip: "36019",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 121,
  },
  {
    fip: "36017",
    territory: "White Plains NY 1",
    territoryId: "20086275",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36013",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 71,
  },
  {
    fip: "36121",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36015",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42065",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42047",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42019",
    territory: "Pittsburgh PA 1",
    territoryId: "20086277",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 132,
  },
  {
    fip: "42013",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 112,
  },
  {
    fip: "42087",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42099",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42075",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 105,
  },
  {
    fip: "42067",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42097",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42029",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 365,
  },
  {
    fip: "10001",
    territory: "West Chester PA 1",
    territoryId: "20086267",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51061",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24029",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24021",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 157,
  },
  {
    fip: "51510",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51179",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51099",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51137",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51165",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51097",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51830",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51001",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51710",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 808,
  },
  {
    fip: "51650",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 146,
  },
  {
    fip: "51730",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51117",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51770",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 358,
  },
  {
    fip: "51143",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51750",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51091",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54075",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54015",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54087",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54039",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 337,
  },
  {
    fip: "54003",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54053",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54099",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54059",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54029",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54051",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54069",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 190,
  },
  {
    fip: "54093",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54027",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54023",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37077",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37101",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37063",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 521,
  },
  {
    fip: "37127",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 98,
  },
  {
    fip: "37015",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37053",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37045",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37017",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37163",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37049",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 141,
  },
  {
    fip: "37103",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37079",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37009",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37097",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 68,
  },
  {
    fip: "37193",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37175",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37173",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37111",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37099",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37039",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45055",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45085",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 82,
  },
  {
    fip: "45021",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45083",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 288,
  },
  {
    fip: "45015",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45089",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45033",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45007",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 105,
  },
  {
    fip: "45045",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 81,
  },
  {
    fip: "45053",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13135",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 269,
  },
  {
    fip: "13089",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 159,
  },
  {
    fip: "13045",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13149",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13231",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13063",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 235,
  },
  {
    fip: "13199",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13121",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 257,
  },
  {
    fip: "13267",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13251",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13031",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13257",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13059",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 95,
  },
  {
    fip: "13297",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13055",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13295",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13245",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 107,
  },
  {
    fip: "13269",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13009",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13303",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13103",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13051",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 142,
  },
  {
    fip: "13161",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13027",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13185",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13173",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13321",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "45049",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13247",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13067",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 357,
  },
  {
    fip: "13015",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13227",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13077",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 231,
  },
  {
    fip: "13165",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13209",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13137",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13133",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13313",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13301",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13023",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13159",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13021",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 289,
  },
  {
    fip: "13025",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13275",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 129,
  },
  {
    fip: "13065",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13263",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13259",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13215",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 224,
  },
  {
    fip: "12019",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 69,
  },
  {
    fip: "12035",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12073",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 320,
  },
  {
    fip: "12037",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12039",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12005",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 187,
  },
  {
    fip: "12063",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12091",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 119,
  },
  {
    fip: "12001",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 702,
  },
  {
    fip: "12069",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 468,
  },
  {
    fip: "12009",
    territory: "West Palm Beach FL 1",
    territoryId: "20086317",
    district: "Southeast",
    region: "West - IO",
    count: 635,
  },
  {
    fip: "12087",
    territory: "Miami FL 1",
    territoryId: "20086334",
    district: "Southeast",
    region: "West - IO",
    count: 35,
  },
  {
    fip: "12086",
    territory: "Miami FL 1",
    territoryId: "20086334",
    district: "Southeast",
    region: "West - IO",
    count: 307,
  },
  {
    fip: "12011",
    territory: "Miami FL 1",
    territoryId: "20086334",
    district: "Southeast",
    region: "West - IO",
    count: 1504,
  },
  {
    fip: "12057",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 54,
  },
  {
    fip: "12101",
    territory: "Tampa FL 1",
    territoryId: "20086279",
    district: "Southeast",
    region: "West - IO",
    count: 580,
  },
  {
    fip: "12105",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 487,
  },
  {
    fip: "12015",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 275,
  },
  {
    fip: "12071",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 648,
  },
  {
    fip: "12021",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 230,
  },
  {
    fip: "12081",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 249,
  },
  {
    fip: "12017",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 164,
  },
  {
    fip: "12083",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 407,
  },
  {
    fip: "12053",
    territory: "Tampa FL 1",
    territoryId: "20086279",
    district: "Southeast",
    region: "West - IO",
    count: 227,
  },
  {
    fip: "12103",
    territory: "Tampa FL 1",
    territoryId: "20086279",
    district: "Southeast",
    region: "West - IO",
    count: 140,
  },
  {
    fip: "01115",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01073",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 147,
  },
  {
    fip: "01121",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01043",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01063",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01125",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 147,
  },
  {
    fip: "01075",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01057",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01127",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01059",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01077",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01083",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01089",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 503,
  },
  {
    fip: "01055",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 102,
  },
  {
    fip: "01095",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01049",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01041",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01001",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01011",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01015",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 184,
  },
  {
    fip: "01061",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01099",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01003",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 203,
  },
  {
    fip: "01129",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01097",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 559,
  },
  {
    fip: "01131",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01091",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01047",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01065",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01023",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47165",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 126,
  },
  {
    fip: "47085",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47081",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47003",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47011",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47061",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47121",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47177",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47065",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 569,
  },
  {
    fip: "47059",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 76,
  },
  {
    fip: "47163",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 340,
  },
  {
    fip: "47091",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47063",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47093",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 1020,
  },
  {
    fip: "47157",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 110,
  },
  {
    fip: "47131",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47053",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47033",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47159",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28139",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28027",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28119",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28145",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28117",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28115",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28095",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28121",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28079",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28089",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28049",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 611,
  },
  {
    fip: "28163",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28023",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28041",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28131",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28077",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28087",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28097",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28105",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13037",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13273",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21027",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21093",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 176,
  },
  {
    fip: "21111",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 1351,
  },
  {
    fip: "21167",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21011",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21165",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21239",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21067",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 1006,
  },
  {
    fip: "21095",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21121",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21013",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21161",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21037",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21019",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 254,
  },
  {
    fip: "21089",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21127",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21133",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21195",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 151,
  },
  {
    fip: "21193",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 131,
  },
  {
    fip: "21119",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21007",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21003",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21047",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21031",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21149",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21001",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39089",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39049",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 61,
  },
  {
    fip: "39083",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39045",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 109,
  },
  {
    fip: "39175",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39117",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39173",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39069",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39095",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 575,
  },
  {
    fip: "39059",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39031",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39119",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 98,
  },
  {
    fip: "39067",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39007",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39093",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 200,
  },
  {
    fip: "39055",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39085",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 122,
  },
  {
    fip: "39035",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 72,
  },
  {
    fip: "39153",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 62,
  },
  {
    fip: "39103",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39099",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 365,
  },
  {
    fip: "39155",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 150,
  },
  {
    fip: "39151",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 425,
  },
  {
    fip: "39147",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39139",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 181,
  },
  {
    fip: "39017",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 273,
  },
  {
    fip: "39061",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 34,
  },
  {
    fip: "39165",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 294,
  },
  {
    fip: "39071",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39015",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39109",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 101,
  },
  {
    fip: "39037",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 176,
  },
  {
    fip: "39023",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 122,
  },
  {
    fip: "39113",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 101,
  },
  {
    fip: "39145",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39009",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39167",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 137,
  },
  {
    fip: "39065",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18063",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 242,
  },
  {
    fip: "18097",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 70,
  },
  {
    fip: "18149",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18089",
    territory: "Chicago S IL 1",
    territoryId: "20086289",
    district: "Chicago IL",
    region: "East - IO",
    count: 419,
  },
  {
    fip: "18141",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 320,
  },
  {
    fip: "18033",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18183",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18001",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18087",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18017",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18029",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18019",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 136,
  },
  {
    fip: "18143",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18071",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18031",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18005",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 127,
  },
  {
    fip: "18145",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18035",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 331,
  },
  {
    fip: "18075",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18065",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18055",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18123",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18147",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18173",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 236,
  },
  {
    fip: "18163",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 238,
  },
  {
    fip: "18167",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18021",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18157",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 257,
  },
  {
    fip: "18111",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26099",
    territory: "Detroit N MI 1",
    territoryId: "20086256",
    district: "Chicago IL",
    region: "East - IO",
    count: 729,
  },
  {
    fip: "26115",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26163",
    territory: "Detroit N MI 1",
    territoryId: "20086256",
    district: "Chicago IL",
    region: "East - IO",
    count: 87,
  },
  {
    fip: "26125",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 33,
  },
  {
    fip: "26157",
    territory: "Detroit N MI 1",
    territoryId: "20086256",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26049",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 515,
  },
  {
    fip: "26087",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26069",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26001",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26025",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26159",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26023",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26021",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 136,
  },
  {
    fip: "26059",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26075",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 170,
  },
  {
    fip: "26123",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26117",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26105",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26005",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26121",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 245,
  },
  {
    fip: "26081",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 45,
  },
  {
    fip: "26133",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26055",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 204,
  },
  {
    fip: "26007",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26109",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26043",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26071",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26083",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26053",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26131",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19069",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19015",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19157",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19083",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19099",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19049",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19153",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 619,
  },
  {
    fip: "19081",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19063",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19147",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19187",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19023",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19065",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19149",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19035",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19193",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 133,
  },
  {
    fip: "19119",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19041",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19143",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19155",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 87,
  },
  {
    fip: "19029",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19055",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19043",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19095",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19105",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19113",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 255,
  },
  {
    fip: "19107",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19135",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19179",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19115",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19111",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19139",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55117",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55127",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55079",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 316,
  },
  {
    fip: "55101",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 100,
  },
  {
    fip: "55043",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55078",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55083",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55009",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 402,
  },
  {
    fip: "55029",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55073",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 126,
  },
  {
    fip: "55115",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55003",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55125",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55011",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55107",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55031",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55007",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55129",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55139",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 154,
  },
  {
    fip: "55077",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55137",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27115",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27037",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27123",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 58,
  },
  {
    fip: "27143",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27171",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27053",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 1089,
  },
  {
    fip: "27137",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 112,
  },
  {
    fip: "27001",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27013",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27043",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27033",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27101",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27133",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27051",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27083",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27145",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 77,
  },
  {
    fip: "27159",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27035",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27005",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27135",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27089",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46099",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 451,
  },
  {
    fip: "46127",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46011",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46025",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46029",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46043",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46005",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46015",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46115",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46013",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46045",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46053",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46095",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46121",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46093",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46031",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38017",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 129,
  },
  {
    fip: "38077",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38067",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38035",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 120,
  },
  {
    fip: "38103",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38079",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38027",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38043",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38039",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38093",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38003",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38015",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 86,
  },
  {
    fip: "38059",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38041",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38049",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38105",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30087",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30003",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30059",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30065",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30085",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30105",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30101",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30027",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30015",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30013",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 128,
  },
  {
    fip: "30071",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30049",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30057",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30001",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30093",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30061",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30081",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17111",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 97,
  },
  {
    fip: "17097",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 217,
  },
  {
    fip: "17037",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 44,
  },
  {
    fip: "17043",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 727,
  },
  {
    fip: "17031",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 28,
  },
  {
    fip: "17197",
    territory: "Chicago S IL 1",
    territoryId: "20086289",
    district: "Chicago IL",
    region: "East - IO",
    count: 81,
  },
  {
    fip: "17089",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 202,
  },
  {
    fip: "17053",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17091",
    territory: "Chicago S IL 1",
    territoryId: "20086289",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17075",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17195",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17141",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17201",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 179,
  },
  {
    fip: "17161",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 183,
  },
  {
    fip: "17131",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17099",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 124,
  },
  {
    fip: "17103",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17175",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17095",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17109",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17187",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17123",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17143",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 516,
  },
  {
    fip: "17113",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 161,
  },
  {
    fip: "17179",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17019",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 148,
  },
  {
    fip: "17183",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17139",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17041",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17061",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17117",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17163",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 172,
  },
  {
    fip: "17145",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17005",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17067",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17149",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17049",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17021",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17137",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17169",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17129",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17171",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17167",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 91,
  },
  {
    fip: "17055",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17191",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17121",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17077",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17087",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17069",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17153",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29189",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 999,
  },
  {
    fip: "29510",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 976,
  },
  {
    fip: "29111",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29173",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29205",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29186",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29201",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29031",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 126,
  },
  {
    fip: "29155",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29143",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29023",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29223",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29107",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29095",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 255,
  },
  {
    fip: "29047",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 63,
  },
  {
    fip: "29227",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29147",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29063",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29005",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29115",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29129",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29025",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29171",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29083",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29097",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 143,
  },
  {
    fip: "29019",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 460,
  },
  {
    fip: "29141",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29151",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29131",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29051",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 131,
  },
  {
    fip: "29041",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29027",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29159",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29169",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29065",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29109",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29229",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29167",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 77,
  },
  {
    fip: "29153",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29077",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 271,
  },
  {
    fip: "29091",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20091",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 436,
  },
  {
    fip: "20121",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20003",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20087",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20149",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20085",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20139",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20117",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20205",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20011",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20017",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20127",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20191",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20007",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20151",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20173",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 537,
  },
  {
    fip: "20125",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20049",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20019",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20169",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20041",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20147",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20195",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20153",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20057",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 49,
  },
  {
    fip: "20083",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20129",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31153",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31177",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31055",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 695,
  },
  {
    fip: "31053",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31151",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31059",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31141",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31023",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31107",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31003",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31019",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 55,
  },
  {
    fip: "31099",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31181",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31145",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31123",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31101",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31047",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22051",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 86,
  },
  {
    fip: "22071",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 60,
  },
  {
    fip: "22109",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 113,
  },
  {
    fip: "22057",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 94,
  },
  {
    fip: "22007",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22103",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 48,
  },
  {
    fip: "22063",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22105",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22099",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22101",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22039",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22115",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22033",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 717,
  },
  {
    fip: "22017",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 532,
  },
  {
    fip: "22097",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22079",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 162,
  },
  {
    fip: "22043",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22003",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05043",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05003",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05027",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05039",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05139",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05059",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05097",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05045",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 98,
  },
  {
    fip: "05117",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05119",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 943,
  },
  {
    fip: "05107",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05021",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05075",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05049",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05063",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05065",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05015",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05101",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05141",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05005",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05083",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05115",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 77,
  },
  {
    fip: "05127",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05131",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 152,
  },
  {
    fip: "40051",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40109",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 955,
  },
  {
    fip: "40027",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 194,
  },
  {
    fip: "40049",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40031",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 101,
  },
  {
    fip: "40057",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40141",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40043",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40149",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40009",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40047",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40003",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40053",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40007",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40025",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40143",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 847,
  },
  {
    fip: "40105",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40119",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 64,
  },
  {
    fip: "40091",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40145",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40127",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40013",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40089",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40133",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40125",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40081",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40135",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40079",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48121",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 235,
  },
  {
    fip: "48113",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 77,
  },
  {
    fip: "48397",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48085",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 148,
  },
  {
    fip: "48147",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48231",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 70,
  },
  {
    fip: "48181",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 230,
  },
  {
    fip: "48401",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48423",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 427,
  },
  {
    fip: "48213",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48313",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48001",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48439",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 1224,
  },
  {
    fip: "48425",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48363",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48497",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48097",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48221",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48133",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48027",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 314,
  },
  {
    fip: "48083",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48435",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48201",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 1661,
  },
  {
    fip: "48039",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 97,
  },
  {
    fip: "48199",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48167",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 349,
  },
  {
    fip: "48051",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48041",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 123,
  },
  {
    fip: "48469",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 76,
  },
  {
    fip: "48013",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48025",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48187",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48029",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 90,
  },
  {
    fip: "48247",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48355",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 213,
  },
  {
    fip: "48215",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 210,
  },
  {
    fip: "48053",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48209",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 105,
  },
  {
    fip: "48055",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48453",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 552,
  },
  {
    fip: "48149",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48295",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48233",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48179",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48375",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 188,
  },
  {
    fip: "48381",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48125",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48345",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48219",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48279",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48253",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48227",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48371",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48135",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 94,
  },
  {
    fip: "48389",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48141",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 369,
  },
  {
    fip: "08005",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 493,
  },
  {
    fip: "08059",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 244,
  },
  {
    fip: "08035",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 178,
  },
  {
    fip: "08031",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 694,
  },
  {
    fip: "08093",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08107",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08049",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08069",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 145,
  },
  {
    fip: "08001",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 458,
  },
  {
    fip: "08123",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08087",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08041",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 277,
  },
  {
    fip: "08039",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08101",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 132,
  },
  {
    fip: "08071",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08055",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08023",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08037",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08077",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 124,
  },
  {
    fip: "56001",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56031",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56007",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56003",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56041",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56023",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16077",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16007",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16041",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16031",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16013",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16053",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16019",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 76,
  },
  {
    fip: "16057",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16049",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16061",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16045",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16001",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 424,
  },
  {
    fip: "16003",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16039",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16079",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16055",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 242,
  },
  {
    fip: "16017",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49035",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 518,
  },
  {
    fip: "49009",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49049",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 83,
  },
  {
    fip: "49043",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49005",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49015",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49041",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49021",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49025",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04013",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 46,
  },
  {
    fip: "04021",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 111,
  },
  {
    fip: "04007",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04019",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 46,
  },
  {
    fip: "04017",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04005",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04025",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 220,
  },
  {
    fip: "04015",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 197,
  },
  {
    fip: "04001",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35006",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35045",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 64,
  },
  {
    fip: "35043",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35001",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 589,
  },
  {
    fip: "35031",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35055",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35047",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35033",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35053",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35051",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35013",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 95,
  },
  {
    fip: "35041",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35009",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35025",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35027",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35035",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35037",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32003",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 1265,
  },
  {
    fip: "32015",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32013",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32031",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 292,
  },
  {
    fip: "32007",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06037",
    territory: "Los Angeles NE CA 1",
    territoryId: "20086252",
    district: "Southwest",
    region: "West - IO",
    count: 89,
  },
  {
    fip: "06073",
    territory: "San Diego CA 1",
    territoryId: "20086272",
    district: "Southwest",
    region: "West - IO",
    count: 1271,
  },
  {
    fip: "06065",
    territory: "South Orange County CA 1",
    territoryId: "20086253",
    district: "Southwest",
    region: "West - IO",
    count: 60,
  },
  {
    fip: "06071",
    territory: "Los Angeles NE CA 1",
    territoryId: "20086252",
    district: "Southwest",
    region: "West - IO",
    count: 60,
  },
  {
    fip: "06027",
    territory: "Los Angeles NE CA 1",
    territoryId: "20086252",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06059",
    territory: "South Orange County CA 1",
    territoryId: "20086253",
    district: "Southwest",
    region: "West - IO",
    count: 28,
  },
  {
    fip: "06083",
    territory: "Los Angeles NW CA 1",
    territoryId: "20086265",
    district: "Southwest",
    region: "West - IO",
    count: 161,
  },
  {
    fip: "06107",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 113,
  },
  {
    fip: "06029",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 200,
  },
  {
    fip: "06079",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 103,
  },
  {
    fip: "06039",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06047",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 61,
  },
  {
    fip: "06019",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 400,
  },
  {
    fip: "06053",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 94,
  },
  {
    fip: "06075",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 790,
  },
  {
    fip: "06067",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 35,
  },
  {
    fip: "06013",
    territory: "San Jose CA 1",
    territoryId: "20086268",
    district: "Northwest",
    region: "West - IO",
    count: 165,
  },
  {
    fip: "06001",
    territory: "San Jose CA 1",
    territoryId: "20086268",
    district: "Northwest",
    region: "West - IO",
    count: 272,
  },
  {
    fip: "06041",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 152,
  },
  {
    fip: "06097",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 190,
  },
  {
    fip: "06085",
    territory: "San Jose CA 1",
    territoryId: "20086268",
    district: "Northwest",
    region: "West - IO",
    count: 80,
  },
  {
    fip: "06077",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 173,
  },
  {
    fip: "06009",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06109",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06099",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 319,
  },
  {
    fip: "06023",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 82,
  },
  {
    fip: "06113",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06095",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 111,
  },
  {
    fip: "06061",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 182,
  },
  {
    fip: "06007",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 127,
  },
  {
    fip: "06057",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06089",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 128,
  },
  {
    fip: "06105",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06003",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06063",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06093",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "15007",
    territory: "Honolulu HI 1",
    territoryId: "20086321",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "15003",
    territory: "Honolulu HI 1",
    territoryId: "20086321",
    district: "Southwest",
    region: "West - IO",
    count: 95,
  },
  {
    fip: "41057",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41067",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 414,
  },
  {
    fip: "41051",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 836,
  },
  {
    fip: "41047",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 196,
  },
  {
    fip: "41039",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 291,
  },
  {
    fip: "41019",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41011",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41033",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41035",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41037",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41031",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41001",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53033",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 331,
  },
  {
    fip: "53073",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 243,
  },
  {
    fip: "53061",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 216,
  },
  {
    fip: "53057",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 130,
  },
  {
    fip: "53035",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 187,
  },
  {
    fip: "53031",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53053",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 48,
  },
  {
    fip: "53067",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 89,
  },
  {
    fip: "53045",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53049",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53041",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53011",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 204,
  },
  {
    fip: "53039",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53025",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53047",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53077",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 130,
  },
  {
    fip: "53063",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 584,
  },
  {
    fip: "53019",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53021",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53071",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02020",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 299,
  },
  {
    fip: "02050",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02164",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02060",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02122",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02090",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02290",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02068",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02185",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12121",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12003",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12031",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 1520,
  },
  {
    fip: "12123",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12133",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12113",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12127",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 474,
  },
  {
    fip: "12095",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 42,
  },
  {
    fip: "12061",
    territory: "West Palm Beach FL 1",
    territoryId: "20086317",
    district: "Southeast",
    region: "West - IO",
    count: 205,
  },
  {
    fip: "12055",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 74,
  },
  {
    fip: "12051",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12115",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 508,
  },
  {
    fip: "12097",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 120,
  },
  {
    fip: "12111",
    territory: "West Palm Beach FL 1",
    territoryId: "20086317",
    district: "Southeast",
    region: "West - IO",
    count: 254,
  },
  {
    fip: "12085",
    territory: "West Palm Beach FL 1",
    territoryId: "20086317",
    district: "Southeast",
    region: "West - IO",
    count: 235,
  },
  {
    fip: "01117",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 89,
  },
  {
    fip: "01107",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01079",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01123",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01013",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01085",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01101",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 353,
  },
  {
    fip: "01027",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01069",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 210,
  },
  {
    fip: "01039",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01017",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47187",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 192,
  },
  {
    fip: "47149",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 105,
  },
  {
    fip: "47041",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47037",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 1439,
  },
  {
    fip: "47139",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47031",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47051",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47143",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47171",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47019",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47001",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47155",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47047",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47079",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47017",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47055",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47133",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47185",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47087",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28081",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 228,
  },
  {
    fip: "28043",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28161",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28007",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28127",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28159",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28035",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 270,
  },
  {
    fip: "28073",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28047",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 155,
  },
  {
    fip: "28045",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28103",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28019",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21179",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21103",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21229",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21163",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21235",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21125",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21051",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21117",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 495,
  },
  {
    fip: "21015",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21159",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21237",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21189",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21153",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21071",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21139",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21169",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21009",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21221",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21141",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21227",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 204,
  },
  {
    fip: "21059",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21033",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21225",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21199",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21085",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39159",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39143",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39123",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39039",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39115",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39127",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39081",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39013",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39133",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39077",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39025",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 143,
  },
  {
    fip: "39057",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 224,
  },
  {
    fip: "39135",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39149",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39141",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39003",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 217,
  },
  {
    fip: "39107",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18095",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 158,
  },
  {
    fip: "18023",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18057",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 351,
  },
  {
    fip: "18059",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18091",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18085",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18039",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 217,
  },
  {
    fip: "18113",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18003",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 599,
  },
  {
    fip: "18103",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18169",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18067",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18047",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18061",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18105",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 208,
  },
  {
    fip: "18101",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18153",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18165",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18007",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18171",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26147",
    territory: "Detroit N MI 1",
    territoryId: "20086256",
    district: "Chicago IL",
    region: "East - IO",
    count: 97,
  },
  {
    fip: "26161",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 133,
  },
  {
    fip: "26151",
    territory: "Detroit N MI 1",
    territoryId: "20086256",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26145",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 100,
  },
  {
    fip: "26011",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26063",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26057",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26067",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26045",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26037",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26155",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26065",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 435,
  },
  {
    fip: "26027",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26107",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26139",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26127",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26009",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26101",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26165",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26079",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26033",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26097",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26137",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26031",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26041",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26103",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 105,
  },
  {
    fip: "26003",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19169",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 124,
  },
  {
    fip: "19009",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19117",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19185",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19125",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19067",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19091",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19075",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19171",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19175",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19003",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19141",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19059",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19047",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19073",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19071",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19191",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19087",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19163",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 223,
  },
  {
    fip: "55015",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55027",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55059",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55105",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55049",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55065",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55025",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 698,
  },
  {
    fip: "55111",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55093",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55087",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 181,
  },
  {
    fip: "55061",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55141",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 86,
  },
  {
    fip: "55067",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55119",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55019",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55051",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55123",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55053",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55113",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27049",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27163",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27065",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27025",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27017",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27045",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27169",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27109",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 120,
  },
  {
    fip: "27063",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27155",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27023",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27127",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27057",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27021",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27111",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27107",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27007",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27061",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27029",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46027",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46079",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46073",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46021",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46041",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46081",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46103",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 142,
  },
  {
    fip: "38063",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38065",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38037",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38085",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38057",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38083",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30009",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30111",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 105,
  },
  {
    fip: "30067",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30091",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30073",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30051",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30063",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30029",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 60,
  },
  {
    fip: "17177",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17085",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17071",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17057",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "25011",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "44003",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 296,
  },
  {
    fip: "33001",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23021",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50013",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36035",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36033",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36011",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36049",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36075",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36073",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42039",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 90,
  },
  {
    fip: "42009",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42077",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 68,
  },
  {
    fip: "24017",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24025",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 101,
  },
  {
    fip: "24041",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 89,
  },
  {
    fip: "51177",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 101,
  },
  {
    fip: "51187",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51113",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51007",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51073",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51085",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 216,
  },
  {
    fip: "51550",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51810",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 165,
  },
  {
    fip: "51081",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51025",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51071",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51520",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51105",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51173",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51023",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51083",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51029",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51009",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54085",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54101",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54041",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37197",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37001",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 82,
  },
  {
    fip: "37151",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37105",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37135",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 346,
  },
  {
    fip: "37191",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37083",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37117",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37167",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37161",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37085",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37061",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37125",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 220,
  },
  {
    fip: "37165",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37023",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37087",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37199",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45031",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45005",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13217",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13143",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13139",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 261,
  },
  {
    fip: "13211",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13195",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13147",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13033",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13079",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13093",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13271",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13191",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "25019",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23007",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50015",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36113",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 169,
  },
  {
    fip: "36115",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36043",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24023",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51610",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51133",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51043",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51069",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51139",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51065",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51115",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51053",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51620",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51181",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51135",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51161",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51580",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54063",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54071",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54031",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37171",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 82,
  },
  {
    fip: "37181",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37131",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37109",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37047",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37019",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37027",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45081",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45051",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 313,
  },
  {
    fip: "45073",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13057",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13115",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 212,
  },
  {
    fip: "13113",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 130,
  },
  {
    fip: "13107",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13157",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13083",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13189",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13073",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13207",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13193",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13167",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13305",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13049",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13075",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12109",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 128,
  },
  {
    fip: "12107",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12119",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12129",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12131",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12033",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 444,
  },
  {
    fip: "12075",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12099",
    territory: "Miami FL 1",
    territoryId: "20086334",
    district: "Southeast",
    region: "West - IO",
    count: 657,
  },
  {
    fip: "13127",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13071",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13145",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12077",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12065",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12029",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12117",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 292,
  },
  {
    fip: "12043",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01037",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01119",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01133",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01103",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 116,
  },
  {
    fip: "01045",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47015",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47161",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47189",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47115",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47107",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47073",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47129",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47009",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 109,
  },
  {
    fip: "47069",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47183",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47039",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47071",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47141",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 265,
  },
  {
    fip: "47035",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28033",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 133,
  },
  {
    fip: "28011",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28151",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28133",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28017",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28051",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28123",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28029",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28075",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28061",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28153",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28065",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28059",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 135,
  },
  {
    fip: "13131",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13099",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21215",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21173",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21209",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21151",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21021",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21073",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21069",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21081",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21135",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21129",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21083",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21035",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21177",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21107",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 98,
  },
  {
    fip: "21087",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39021",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39073",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39101",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39171",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39029",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39157",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39033",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39005",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39137",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39011",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18133",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18081",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18139",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18073",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18099",
    territory: "Chicago S IL 1",
    territoryId: "20086289",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18049",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18053",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18115",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18175",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18093",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18027",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18121",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18107",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26093",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26129",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26077",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 79,
  },
  {
    fip: "26091",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26061",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19181",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19001",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19121",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19079",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19077",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19131",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19033",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19197",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19151",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19021",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19133",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19161",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19061",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19011",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19097",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19103",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 432,
  },
  {
    fip: "55089",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 202,
  },
  {
    fip: "55071",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55017",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55033",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55013",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27003",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 275,
  },
  {
    fip: "27129",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27019",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27099",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27165",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27015",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27173",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27151",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27009",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27097",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27027",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27077",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27069",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27119",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46109",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46009",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46017",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46107",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46119",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46085",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46123",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46047",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38097",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38091",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38071",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38045",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38053",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30033",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30021",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30075",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30043",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30031",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30047",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30053",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17073",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17011",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17155",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "23023",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "50009",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36041",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "36057",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 114,
  },
  {
    fip: "36053",
    territory: "Albany NY 1",
    territoryId: "20086264",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51683",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24019",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51193",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51157",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51540",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 298,
  },
  {
    fip: "51125",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51101",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51740",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51183",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51121",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 170,
  },
  {
    fip: "51141",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51195",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51590",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 78,
  },
  {
    fip: "51027",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54095",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37157",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 48,
  },
  {
    fip: "37013",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37095",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37107",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45087",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45025",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13013",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13035",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13111",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13317",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13219",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "42023",
    territory: "Buffalo NY 1",
    territoryId: "20086325",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42113",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "24035",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51660",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51127",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51011",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51680",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 144,
  },
  {
    fip: "54007",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54057",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37037",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37091",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37093",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37035",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 222,
  },
  {
    fip: "45001",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45047",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45023",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45065",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13097",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13255",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13293",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13163",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13291",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13011",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01071",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01025",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01081",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47147",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47103",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47179",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 342,
  },
  {
    fip: "47029",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47151",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47109",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47077",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47099",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28071",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28055",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28015",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28083",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28021",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28101",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28069",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28067",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28063",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28157",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28155",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21211",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21029",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21109",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21131",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21023",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21043",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21115",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21183",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21233",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39041",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39129",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39047",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39051",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39111",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39121",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39169",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39075",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39027",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39079",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39087",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39053",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39125",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18011",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18159",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18109",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18127",
    territory: "Chicago S IL 1",
    territoryId: "20086289",
    district: "Chicago IL",
    region: "East - IO",
    count: 166,
  },
  {
    fip: "18069",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18155",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18137",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18079",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18177",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18083",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18051",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18045",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26051",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26143",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19195",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19189",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19013",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 97,
  },
  {
    fip: "19037",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19025",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19145",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19005",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19019",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19007",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19031",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19045",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55055",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55133",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 156,
  },
  {
    fip: "55103",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55023",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55047",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55021",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55057",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55109",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 99,
  },
  {
    fip: "55085",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55081",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55135",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27139",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 75,
  },
  {
    fip: "27131",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27075",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27047",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27105",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27149",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27067",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27073",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27113",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46135",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46057",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46051",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46061",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46067",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46113",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38099",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38095",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38019",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38009",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38029",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38051",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38013",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30095",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30103",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30045",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30107",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17015",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17105",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17203",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17039",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17029",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17119",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 75,
  },
  {
    fip: "17173",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17185",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17189",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17025",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17199",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17151",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29199",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29197",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29187",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29093",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29207",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29021",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 123,
  },
  {
    fip: "29081",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29075",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29117",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "44005",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42037",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51057",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51145",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51700",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 184,
  },
  {
    fip: "51111",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51089",
    territory: "Durham NC 1",
    territoryId: "20086258",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51017",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54009",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54049",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37055",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37073",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37025",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 201,
  },
  {
    fip: "37153",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37003",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37113",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45061",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45017",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45057",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45037",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13285",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17133",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17157",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17101",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17159",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17065",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17081",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17059",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17003",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29099",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 51,
  },
  {
    fip: "29071",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 83,
  },
  {
    fip: "29139",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29007",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29221",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29157",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29069",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29165",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29101",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29003",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29217",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29013",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29053",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29195",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29215",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29209",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29043",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29085",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29213",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20005",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20045",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20177",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 252,
  },
  {
    fip: "20037",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20111",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20077",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20015",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20035",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20115",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20155",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20047",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20183",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20187",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31025",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "03105",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31159",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31169",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31147",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31109",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 242,
  },
  {
    fip: "31011",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31179",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31001",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31083",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31035",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22093",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22075",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22055",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 455,
  },
  {
    fip: "22053",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22023",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22047",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22005",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22013",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22069",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22073",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 198,
  },
  {
    fip: "22111",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22059",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22009",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22127",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05057",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05125",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05067",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05137",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05095",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05147",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05105",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05029",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05093",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05123",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05055",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05121",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05135",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05009",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05143",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 225,
  },
  {
    fip: "40075",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40017",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40099",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40095",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40055",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40067",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40153",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40147",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40113",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40117",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40035",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40115",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40101",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 43,
  },
  {
    fip: "40121",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40071",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40123",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 43,
  },
  {
    fip: "48257",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48119",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48183",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 211,
  },
  {
    fip: "48455",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48419",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48457",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48485",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 122,
  },
  {
    fip: "48217",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48293",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48309",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 154,
  },
  {
    fip: "48319",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48333",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48307",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48291",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48321",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48245",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 241,
  },
  {
    fip: "48289",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48057",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48123",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48325",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48091",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 71,
  },
  {
    fip: "48493",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48047",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48061",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 90,
  },
  {
    fip: "48491",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 346,
  },
  {
    fip: "48385",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48287",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48129",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48045",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48303",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 232,
  },
  {
    fip: "48433",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48329",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48495",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08019",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08013",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 250,
  },
  {
    fip: "08095",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08115",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08073",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08063",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08089",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08021",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08043",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08051",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08083",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08029",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08097",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56013",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56025",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 73,
  },
  {
    fip: "56033",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56037",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56039",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16047",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16067",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16025",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16035",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16009",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49047",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49001",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04009",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35059",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32017",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32033",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32027",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32021",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32029",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32510",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 74,
  },
  {
    fip: "06111",
    territory: "Los Angeles NW CA 1",
    territoryId: "20086265",
    district: "Southwest",
    region: "West - IO",
    count: 340,
  },
  {
    fip: "06081",
    territory: "San Jose CA 1",
    territoryId: "20086268",
    district: "Northwest",
    region: "West - IO",
    count: 148,
  },
  {
    fip: "06033",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06045",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06017",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06101",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06005",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06021",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06011",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06103",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06049",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06091",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "15001",
    territory: "Honolulu HI 1",
    territoryId: "20086321",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "15009",
    territory: "Honolulu HI 1",
    territoryId: "20086321",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41065",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41055",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41071",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41043",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41041",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41017",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 139,
  },
  {
    fip: "41061",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53027",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53075",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53043",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53003",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02070",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02261",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02016",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02170",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02240",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "33007",
    territory: "Concord NH 1",
    territoryId: "20086288",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42119",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51159",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51079",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51800",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 137,
  },
  {
    fip: "51077",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54105",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54091",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37147",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 292,
  },
  {
    fip: "37143",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37005",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37115",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37121",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45071",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13309",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13119",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13213",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17147",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17027",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17035",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17115",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17017",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17107",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17125",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17193",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17127",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29113",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29183",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 181,
  },
  {
    fip: "29121",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29017",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29035",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29011",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29135",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29073",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29029",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29137",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29175",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29161",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 156,
  },
  {
    fip: "29067",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29009",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29149",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20043",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20103",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20061",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20131",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20207",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20021",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20073",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20201",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20157",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20097",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20033",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20159",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20145",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20113",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20135",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20185",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20141",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20051",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20199",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20025",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20175",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31067",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31131",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31089",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31051",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31079",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 64,
  },
  {
    fip: "31041",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31031",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31075",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31157",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31045",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22089",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22091",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22045",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22019",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 196,
  },
  {
    fip: "22015",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22049",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22065",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22025",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22107",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05051",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 119,
  },
  {
    fip: "05053",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05145",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05031",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 296,
  },
  {
    fip: "05007",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 117,
  },
  {
    fip: "05033",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48139",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48387",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48063",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48159",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48365",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48203",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48467",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48161",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48005",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 83,
  },
  {
    fip: "48351",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48241",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48367",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 79,
  },
  {
    fip: "48251",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 100,
  },
  {
    fip: "48077",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48487",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48143",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48399",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48451",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 64,
  },
  {
    fip: "48339",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 379,
  },
  {
    fip: "48373",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48407",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48089",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48071",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48477",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48239",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48175",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48479",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48409",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48299",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48323",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48019",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48357",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48065",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48191",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48169",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48305",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48475",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08061",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08067",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08007",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08113",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08103",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56015",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56029",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16005",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 92,
  },
  {
    fip: "16065",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16085",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16027",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 67,
  },
  {
    fip: "49011",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 51,
  },
  {
    fip: "49051",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49007",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49027",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49017",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04011",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35039",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35007",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35015",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 74,
  },
  {
    fip: "32009",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32019",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06025",
    territory: "San Diego CA 1",
    territoryId: "20086272",
    district: "Southwest",
    region: "West - IO",
    count: 32,
  },
  {
    fip: "06031",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06043",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41005",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 92,
  },
  {
    fip: "41027",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41029",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 281,
  },
  {
    fip: "41013",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41059",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41045",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53029",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53069",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53015",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53007",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 65,
  },
  {
    fip: "53037",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53065",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53051",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53005",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 218,
  },
  {
    fip: "02188",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02232",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02201",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53009",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02180",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02110",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02280",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29061",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20209",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 384,
  },
  {
    fip: "20001",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20031",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20089",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20099",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20053",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20167",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20163",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20063",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31021",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31097",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31095",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31185",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31119",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31015",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31093",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31135",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31113",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31007",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22113",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22077",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22081",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22027",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22119",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22041",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05011",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05013",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05091",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05085",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05023",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05035",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05037",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05087",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05149",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40137",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40097",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40029",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40103",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48277",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 134,
  },
  {
    fip: "48037",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 148,
  },
  {
    fip: "48067",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48093",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48099",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 66,
  },
  {
    fip: "48331",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48049",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48471",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48157",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 48,
  },
  {
    fip: "48481",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48395",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48163",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48007",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48391",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48127",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48443",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48197",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48087",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48269",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48445",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48107",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48275",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48263",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48441",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 157,
  },
  {
    fip: "08125",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08121",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08025",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08009",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08003",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08015",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08085",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56009",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16063",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16043",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16081",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16075",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16021",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49003",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49057",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 66,
  },
  {
    fip: "04012",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "04003",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 93,
  },
  {
    fip: "04023",
    territory: "Phoenix AZ 1",
    territoryId: "20086260",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35049",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 70,
  },
  {
    fip: "35061",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35017",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35011",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35019",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06055",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 66,
  },
  {
    fip: "06087",
    territory: "San Jose CA 1",
    territoryId: "20086268",
    district: "Northwest",
    region: "West - IO",
    count: 62,
  },
  {
    fip: "41003",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 108,
  },
  {
    fip: "41015",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41023",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13319",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13289",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13183",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13029",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13001",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13003",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13249",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12047",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12013",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01007",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01093",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01005",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01087",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01067",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47043",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47013",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47167",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47045",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47005",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47181",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28057",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28099",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13253",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21065",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21187",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21025",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21175",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21147",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39097",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39091",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39019",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39001",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39131",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39063",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 83,
  },
  {
    fip: "18025",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18135",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18013",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18125",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18181",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26111",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26073",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26149",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26015",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26019",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26047",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 143,
  },
  {
    fip: "26039",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26153",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26095",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26013",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19109",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19137",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19165",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19129",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19089",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55131",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55095",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55063",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 129,
  },
  {
    fip: "27141",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27085",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27031",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27039",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27055",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27161",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27011",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27041",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27167",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27071",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46003",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46049",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38005",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38055",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30037",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30083",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30041",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13181",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13299",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13261",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12023",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47025",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47145",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47089",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47075",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47097",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47023",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28093",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28013",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28085",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28005",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21185",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21155",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21017",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21079",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21075",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21091",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21101",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21057",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39105",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39161",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18161",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18043",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 289,
  },
  {
    fip: "18077",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18037",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26035",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19053",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19027",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19183",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19177",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55037",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55075",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55097",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55121",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27147",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27079",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27103",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27081",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46083",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46065",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46033",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38081",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38073",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38033",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38025",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38061",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30019",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30011",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30017",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30007",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30077",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17045",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17013",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17001",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17051",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13091",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13225",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13141",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13095",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 150,
  },
  {
    fip: "13019",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13205",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12089",
    territory: "Orlando FL 1",
    territoryId: "20086278",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12045",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "33101",
    territory: "Miami FL 1",
    territoryId: "20086334",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01009",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01051",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01113",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47021",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47105",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47173",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47113",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 336,
  },
  {
    fip: "47101",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47049",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28137",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28107",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28009",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28129",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28111",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28109",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28037",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13087",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13201",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21181",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21197",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21205",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21005",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21105",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21171",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21061",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39163",
    territory: "Columbus OH 1",
    territoryId: "20086287",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19159",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19167",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55045",
    territory: "Milwaukee S WI 1",
    territoryId: "20086273",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27059",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27157",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27153",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27125",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46101",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46039",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46129",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46075",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13187",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13105",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13129",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13153",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13315",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13179",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13069",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13307",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12067",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12049",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01021",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01033",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01111",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01053",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47135",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47123",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47153",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47119",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 93,
  },
  {
    fip: "28125",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28141",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28135",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28091",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28031",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28113",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13243",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21137",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21077",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21063",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21217",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18151",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26135",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26085",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19127",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19101",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19057",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55039",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 117,
  },
  {
    fip: "55099",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55035",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 137,
  },
  {
    fip: "55005",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27117",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27087",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46125",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17165",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29103",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29123",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29133",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29177",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29049",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29037",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29119",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29089",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29015",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29055",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20161",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 62,
  },
  {
    fip: "20133",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20029",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20165",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20137",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20065",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20171",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31155",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31127",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31133",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31143",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31103",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31175",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31081",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31065",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31137",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31017",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31105",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22001",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22121",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22083",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22085",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05041",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05079",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05113",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05069",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05001",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05089",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05047",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40015",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40083",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40069",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40065",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40129",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40151",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40011",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40139",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40111",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40077",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40023",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40063",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48349",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48193",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48503",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48255",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48205",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48437",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48155",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48153",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48353",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48335",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48173",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48043",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48229",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08117",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08065",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08045",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56021",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 62,
  },
  {
    fip: "16011",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16037",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16069",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16015",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49045",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49019",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49039",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49031",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49053",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 72,
  },
  {
    fip: "35057",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35029",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32023",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06069",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53001",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02013",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02270",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "42057",
    territory: "Harrisburg PA 1",
    territoryId: "20086297",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "42093",
    territory: "Scranton PA 1",
    territoryId: "20086320",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 135,
  },
  {
    fip: "54013",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54001",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37149",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "45039",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13233",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13109",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13221",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30089",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17063",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17093",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17135",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17033",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29087",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29145",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29125",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29225",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20079",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 43,
  },
  {
    fip: "20123",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20055",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31173",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31037",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31009",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31063",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31161",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22087",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22117",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22067",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05099",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05061",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05077",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05111",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05129",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05071",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40093",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40037",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40041",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40021",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48449",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48225",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48073",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48145",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48411",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48431",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48473",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48361",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48505",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48489",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48271",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48151",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48317",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08075",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08119",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08053",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08091",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56027",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56045",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16051",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49013",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49055",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35021",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35003",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35023",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32005",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06051",
    territory: "Fresno CA 1",
    territoryId: "20086312",
    district: "Southwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06015",
    territory: "San Francisco CA 1",
    territoryId: "20086316",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41053",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41063",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53017",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02220",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02130",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "44001",
    territory: "Providence MA 1",
    territoryId: "20086248",
    district: "New England",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51021",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51640",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37185",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37065",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37195",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37187",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37189",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46091",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46007",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46071",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46063",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38031",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38087",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38101",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 94,
  },
  {
    fip: "38075",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30035",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29163",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29001",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29211",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20059",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20095",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20179",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20039",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20069",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31121",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31061",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31029",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31111",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 63,
  },
  {
    fip: "31117",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22125",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22037",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05019",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05133",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05073",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40087",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40131",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40107",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48499",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48223",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48337",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48009",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48237",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48035",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48285",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48265",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 52,
  },
  {
    fip: "48131",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48427",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48177",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48031",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48021",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48137",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48195",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48069",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48501",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48017",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48165",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48415",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08057",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08105",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08109",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08027",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56011",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56019",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56035",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16059",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49033",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38047",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38023",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30099",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17047",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29219",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29179",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29105",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29059",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20027",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20101",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20119",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31027",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31139",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31163",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31073",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31057",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31165",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22061",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22123",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05109",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40085",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40005",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48459",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48059",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48267",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48105",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48081",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48185",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48311",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48259",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48249",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48465",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48507",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48117",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48189",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48461",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08047",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08081",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16071",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16083",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 62,
  },
  {
    fip: "04027",
    territory: "San Diego CA 1",
    territoryId: "20086272",
    district: "Southwest",
    region: "West - IO",
    count: 137,
  },
  {
    fip: "32001",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06115",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41025",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51063",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51197",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37123",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37069",
    territory: "Greenville NC 1",
    territoryId: "20086323",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37029",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13117",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 141,
  },
  {
    fip: "13281",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13047",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 144,
  },
  {
    fip: "13175",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13039",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13277",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13155",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12041",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35028",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "35005",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41007",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53059",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02150",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13229",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01019",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01035",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47027",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28143",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28053",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28149",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21203",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21055",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21213",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21219",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21099",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18015",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18131",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26017",
    territory: "Ann Arbor MI 1",
    territoryId: "20086302",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19017",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19173",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19085",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19051",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55091",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27091",
    territory: "Minneapolis South MN 1",
    territoryId: "20086329",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27093",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27095",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46037",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46023",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46105",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38007",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38001",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38089",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13279",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13043",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13241",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13265",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13053",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01105",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47125",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 108,
  },
  {
    fip: "47083",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47057",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28003",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21145",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 319,
  },
  {
    fip: "21039",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21045",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18129",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18119",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26089",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26029",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19039",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "19093",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55001",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "55069",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46097",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46087",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46055",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17007",
    territory: "Chicago W IL 1",
    territoryId: "20086270",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17009",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17023",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "17079",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29127",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 125,
  },
  {
    fip: "29045",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29181",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29079",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29033",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20107",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20197",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20013",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20105",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20023",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20193",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31183",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31125",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31085",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31033",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31013",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22095",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22011",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40073",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40061",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48343",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48417",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48283",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48297",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48273",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48463",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48015",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48341",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48011",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48115",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08017",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08099",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56043",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49037",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12027",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01109",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47117",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47127",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28025",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21097",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21123",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "39043",
    territory: "Cleveland OH 1",
    territoryId: "20086308",
    district: "Washington DC",
    region: "East - IO",
    count: 137,
  },
  {
    fip: "18179",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "19123",
    territory: "Des Moines IA 1",
    territoryId: "20086303",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38011",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30069",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30023",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17083",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "29039",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41009",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41021",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30005",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29185",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29203",
    territory: "Missouri East MO 1",
    territoryId: "20086261",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20009",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20189",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22031",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40039",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40059",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40001",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48347",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48207",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48111",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48421",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48377",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16087",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51630",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51840",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 234,
  },
  {
    fip: "51163",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37139",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13151",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 136,
  },
  {
    fip: "51005",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "31129",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22035",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22029",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48171",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48243",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08014",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08033",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08111",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16073",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "32011",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "06035",
    territory: "Sacramento CA 1",
    territoryId: "20086296",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "45011",
    territory: "Columbia SC 1",
    territoryId: "20086294",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13125",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13177",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01029",
    territory: "Birmingham AL 1",
    territoryId: "20086306",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28001",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13061",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13239",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21191",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21157",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21053",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18041",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "41049",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53055",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12059",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21113",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26113",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46111",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46059",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46019",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38069",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "38021",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "27121",
    territory: "Minneapolis North MN 1",
    territoryId: "20086263",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "46137",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30097",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "29057",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20093",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20203",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31049",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31069",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05103",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05081",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40045",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48281",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48327",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48095",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48369",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48301",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51600",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51735",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51149",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51045",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51530",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37059",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13123",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12125",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53013",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20143",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20109",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31115",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05017",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "40019",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 55,
  },
  {
    fip: "48429",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48235",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56005",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16029",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49023",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51685",
    territory: "Washington DC 1",
    territoryId: "20086295",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51820",
    territory: "Bethesda MD 1",
    territoryId: "20086327",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51167",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51678",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "54073",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37177",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "08011",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "08079",
    territory: "Denver CO 1",
    territoryId: "20086328",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "53023",
    territory: "Spokane WA 1",
    territoryId: "20086310",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13287",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12007",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "01031",
    territory: "Pensacola FL 1",
    territoryId: "20086274",
    district: "Central",
    region: "West - IO",
    count: 76,
  },
  {
    fip: "47169",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21223",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "26141",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46089",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51670",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "51720",
    territory: "Winston Salem NC 1",
    territoryId: "20086304",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37075",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "12093",
    territory: "West Palm Beach FL 1",
    territoryId: "20086317",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21207",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "48315",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48405",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48483",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21143",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46077",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30079",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30109",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13223",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13237",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13005",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47111",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "47095",
    territory: "Nashville W TN 1",
    territoryId: "20086247",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21231",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "30055",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30039",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "17181",
    territory: "Springfield IL 1",
    territoryId: "20086291",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "40033",
    territory: "Oklahoma City OK 1",
    territoryId: "20086330",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48413",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48101",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48075",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31043",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31039",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31071",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31077",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "22021",
    territory: "Baton Rouge LA 1",
    territoryId: "20086324",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "05025",
    territory: "Little Rock AR 1",
    territoryId: "20086333",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48447",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48261",
    territory: "Houston S TX 1",
    territoryId: "20086307",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48359",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48109",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51095",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "37043",
    territory: "Charlotte NC 1",
    territoryId: "20086305",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13197",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47137",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "28039",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "18117",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "55041",
    territory: "Milwaukee N WI 1",
    territoryId: "20086309",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12507",
    territory: "Ft. Myers FL 1",
    territoryId: "20086331",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47067",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13007",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "26119",
    territory: "Grand Rapids MI 1",
    territoryId: "20086314",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46069",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "30025",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13171",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13235",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "12079",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20181",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20081",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31171",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48379",
    territory: "Dallas TX 1",
    territoryId: "20086283",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48079",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16023",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "16033",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02282",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "41069",
    territory: "Portland OR 1",
    territoryId: "20086281",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47007",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21049",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46117",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48403",
    territory: "Houston N TX 1",
    territoryId: "20086322",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48211",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48003",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "56017",
    territory: "Billings MT 1",
    territoryId: "20086259",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31087",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51049",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21201",
    territory: "Cincinnati OH 1",
    territoryId: "20086276",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "21041",
    territory: "Louisville KY 1",
    territoryId: "20086246",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "20075",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48023",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48393",
    territory: "Albuquerque NM 1",
    territoryId: "20086315",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48103",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51775",
    territory: "Roanoke VA 1",
    territoryId: "20086292",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13085",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13311",
    territory: "Atlanta GA 1",
    territoryId: "20086249",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13081",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13169",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20071",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "20067",
    territory: "Kansas City KS 1",
    territoryId: "20086318",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31149",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31005",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48033",
    territory: "Fort Worth TX 1",
    territoryId: "20086326",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "51570",
    territory: "Richmond VA 1",
    territoryId: "20086254",
    district: "Washington DC",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "31167",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "72643",
    territory: "Missouri W MO 1",
    territoryId: "20086262",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "21260",
    territory: "Baltimore MD 1",
    territoryId: "20086286",
    district: "Philadelphia PA",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "18009",
    territory: "Indianapolis IN 1",
    territoryId: "20086250",
    district: "Chicago IL",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "46035",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "31091",
    territory: "Omaha NE 1",
    territoryId: "20086269",
    district: "Great Plains",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "49029",
    territory: "Salt Lake City UT 1",
    territoryId: "20086298",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "02100",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    district: "Northwest",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "47175",
    territory: "Nashville E TN 1",
    territoryId: "20086313",
    district: "Nashville TN",
    region: "East - IO",
    count: 0,
  },
  {
    fip: "13283",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13101",
    territory: "Gainesville FL 1",
    territoryId: "20086245",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "13017",
    territory: "Macon GA 1",
    territoryId: "20086280",
    district: "Southeast",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "28147",
    territory: "New Orleans LA 1",
    territoryId: "20086251",
    district: "Central",
    region: "West - IO",
    count: 0,
  },
  {
    fip: "48383",
    territory: "San Antonio TX 1",
    territoryId: "20086282",
    district: "South Central",
    region: "West - IO",
    count: 0,
  },
];
