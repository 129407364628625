import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PhyRaceBarChartWrapper } from "./PhyRaceBarChart.styles";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { GetAPIRequest } from "../../services";
import config from "../../config";
const PhyRaceBarChart = (props) => {
  const {selPhy, type} = props;
  let root = null;
  useEffect(() => {
    getData();
    if (root) {
      root.dispose();
    }
    root = am5.Root.new("physicianJourneyChartContent");
    
  }, []);
  const getData = () => {
    GetAPIRequest({
      url: `${config.api.physicianJourney}?id=${selPhy["Prescriber Id"]}&racechart=false&type=${type}`
    })
      .then((response) => {
        loadData(response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const loadData = (data) => {
    var idx = -1;
    var isplaying = true;
    var stepDuration = 2000;
    var _d = data;
    var treData = [];
    var actData = [];
    var treatments = [];
    var activities = [];
    var yearArr = [];

    _d.forEach((d, idx) => {
      for (const [key, value] of Object.entries(d.treatments)) {
        if (treatments.indexOf(key) < 0) {
          treatments.push(key);
        }
      }
      yearArr.push(d.title);
      for (const [key, value] of Object.entries(d.activities)) {
        if (activities.indexOf(key) < 0) {
          activities.push(key);
        }
      }
    });
    var allData = {};
    _d.forEach((d, idx) => {
      var tobj = {};
      var tobj1 = {
        year: d.title,
      };
      var tr = d.treatments;
      treatments.forEach((t, idx) => {
        if (tr[t]) {
          tobj[t] = tr[t];
          tobj1[t] = tr[t];
        } else {
          tobj[t] = 0;
          tobj1[t] = 0;
        }
      });
      allData[d.title] = tobj;
      treData.push(tobj1);
    });
    activities.forEach((t, idx) => {
      var aobj = {};
      _d.forEach((d, idx) => {
        var act = d.activities;
        if (act[t]) {
          aobj[t] = act[t];
        } else {
          aobj[t] = 0;
        }
      });
    });
    var allarr = Object.keys(allData);
    console.log("allarr", allarr);
    root.numberFormatter.setAll({
      numberFormat: "#a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    root.setThemes([am5themes_Animated.new(root)]);

    var chart2 = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
      })
    );

    chart2.zoomOutButton.set("forceHidden", true);

    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 20,
      inversed: true,
    });

    yRenderer.grid.template.set("visible", false);

    var yAxis = chart2.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "network",
        renderer: yRenderer,
      })
    );

    var xAxis = chart2.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        strictMinMax: true,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    xAxis.set("interpolationDuration", stepDuration / 10);

    xAxis.set("interpolationEasing", am5.ease.linear);

    var series = chart2.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        categoryYField: "network",
      })
    );

    series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });

    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart2.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart2.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 1,
        sprite: am5.Label.new(root, {
          text: "{valueXWorking.formatNumber('#.# a')}",
          fill: root.interfaceColors.get("alternativeText"),
          centerX: am5.p100,
          centerY: am5.p50,
          populateText: true,
        }),
      });
    });

    var label = chart2.plotContainer.children.push(
      am5.Label.new(root, {
        text: "",
        fontSize: "4em",
        opacity: 0.2,
        x: am5.p100,
        y: am5.p100,
        centerY: am5.p100,
        centerX: am5.p100,
        dx: -80,
      })
    );

    var playLabel = am5.Label.new(root, {
      text: "Pause",
      fontSize: 15,
      fontWeight: "600",
      paddingTop: 2,
      paddingRight: 4,
      paddingBottom: 2,
      paddingLeft: 4,
    });

    var playButton = chart2.plotContainer.children.push(
      am5.Button.new(root, {
        x: am5.p100,
        y: am5.p100,
        dy: -20,
        dx: -10,
        centerY: am5.p100,
        centerX: am5.p100,
        label: playLabel,
      })
    );

    playButton.get("background").setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBR: 10,
      cornerRadiusBL: 10,
      fill: am5.color(0x5458d3),
      fillOpacity: 0.8,
    });

    playButton.events.on("click", function (event) {
      if (isplaying == false) {
        playLabel.set("text", "Stop");
        play();
      } else {
        playLabel.set("text", "Start");
        stop();
      }
    });

    playButton
      .get("background")
      .states.create("hover", {})
      .setAll({
        fill: am5.color(0x5458d3),
        fillOpacity: 0.6,
      });

    playButton
      .get("background")
      .states.create("down", {})
      .setAll({
        fill: am5.color(0x5458d3),
        fillOpacity: 0.4,
      });

    function getSeriesItem(category) {
      for (var i = 0; i < series.dataItems.length; i++) {
        var dataItem = series.dataItems[i];
        if (dataItem.get("categoryY") == category) {
          return dataItem;
        }
      }
    }

    function generateActivityData(activity) {
      console.log("actData", activity);
      var data = [];
      for (var i = 0; i < idx; ++i) {
        console.log("actData", _d[i]);
        data.push({
          year: _d[i].title,
          value: _d[i].activities[activity] ? _d[i].activities[activity] : 0,
        });
      }
      console.log("actData", data);
      return data;
    }

    var xAxis2 = chart2.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis2.data.setAll(treData);

    var yAxis2 = chart2.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );
    var chartSeries = [];

    for (var i = 0; i < activities.length; i++) {
      var series3 = chart2.series.push(
        am5xy.LineSeries.new(root, {
          name: activities[i],
          xAxis: xAxis2,
          yAxis: yAxis2,
          valueYField: "value",
          categoryXField: "year",
          legendValueText: "{valueY}",
        })
      );
      var data = generateActivityData(activities[i]);
      series3.data.setAll(data);
      series3.strokes.template.setAll({
        strokeWidth: 2,
        templateField: "strokeSettings",
      });
      series3.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          sprite: am5.Circle.new(root, {
            radius: 5,
            stroke: series3.get("stroke"),
            strokeWidth: 2,
            fill: am5.color(0xffffff),
          }),
        });
      });
      series3.appear();
      chartSeries.push(series3);
    }

    var interval = setInterval(function () {
      nextYear();
    }, stepDuration);

    function sortCategoryAxis() {
      // sort by value
      series.dataItems.sort(function (x, y) {
        return y.get("valueX") - x.get("valueX"); // descending
        //return x.get("valueX") - y.get("valueX"); // ascending
      });

      // go through each axis item
      am5.array.each(yAxis.dataItems, function (dataItem) {
        // get corresponding series item
        var seriesDataItem = getSeriesItem(dataItem.get("category"));

        if (seriesDataItem) {
          // get index of series data item
          var index = series.dataItems.indexOf(seriesDataItem);
          // calculate delta position
          var deltaPosition =
            (index - dataItem.get("index", 0)) / series.dataItems.length;
          // set index to be the same as series data item index
          if (dataItem.get("index") != index) {
            dataItem.set("index", index);
            // set deltaPosition instanlty
            dataItem.set("deltaPosition", -deltaPosition);
            // animate delta position to 0
            dataItem.animate({
              key: "deltaPosition",
              to: 0,
              duration: stepDuration / 2,
              easing: am5.ease.out(am5.ease.cubic),
            });
          }
        }
      });
      // sort axis items by index.
      // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
      yAxis.dataItems.sort(function (x, y) {
        return x.get("index") - y.get("index");
      });
    }

    var sortInterval = setInterval(function () {
      sortCategoryAxis();
    }, 100);

    function setInitialData() {
      idx++;
      label.set("text", allarr[idx].toString());
      var d = allData[allarr[idx].toString()];

      for (var n in d) {
        series.data.push({ network: n, value: d[n] });
        yAxis.data.push({ network: n });
      }
    }

    function updateData() {
      var itemsWithNonZero = 0;
      if (allData[allarr[idx].toString()]) {
        label.set("text", allarr[idx].toString());
        am5.array.each(series.dataItems, function (dataItem) {
          var category = dataItem.get("categoryY");
          var value = allData[allarr[idx].toString()][category];

          if (value > 0) {
            itemsWithNonZero++;
          }

          dataItem.animate({
            key: "valueX",
            to: value,
            duration: stepDuration,
            easing: am5.ease.linear,
          });
          dataItem.animate({
            key: "valueXWorking",
            to: value,
            duration: stepDuration,
            easing: am5.ease.linear,
          });
        });

        yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
      }
    }
    function reloadData() {
      chartSeries = [];
      for (var i = 0; i < activities.length; i++) {
        var series3 = chart2.series.push(
          am5xy.LineSeries.new(root, {
            name: activities[i],
            xAxis: xAxis2,
            yAxis: yAxis2,
            valueYField: "value",
            categoryXField: "year",
            legendValueText: "{valueY}",
          })
        );
        var data = generateActivityData(activities[i]);
        series3.data.setAll(data);
        series3.strokes.template.setAll({
          strokeWidth: 5,
          templateField: "strokeSettings",
        });
        series3.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            sprite: am5.Circle.new(root, {
              radius: 5,
              stroke: series3.get("stroke"),
              strokeWidth: 2,
              fill: am5.color(0xffffff),
            }),
          });
        });
        series3.appear();
        chartSeries.push(series3);
      }
    }
    function play() {
      if (idx >= allarr.length) {
        idx = -1;
        chartSeries.forEach((_series, k) => {
          _series.dispose();
          reloadData();
        });
      }
      isplaying = true;
      playLabel.set("text", "Pause");
      interval = setInterval(function () {
        nextYear();
      }, stepDuration);
      sortInterval = setInterval(function () {
        sortCategoryAxis();
      }, 100);
      nextYear();
    }

    function nextYear() {
      idx++;
      if (idx >= allarr.length) {
        clearInterval(interval);
        clearInterval(sortInterval);
        isplaying = false;
        playLabel.set("text", "Start");
      } else {
        updateData();
      }
      var _idx = idx - 1;
      chartSeries.forEach((_series, k) => {
        var activity = activities[k].toString();
        console.log("activity", activity);
        if (_d[_idx]) {
          _series.data.push({
            year: _d[_idx].title,
            value: _d[_idx].activities[activity]
              ? _d[_idx].activities[activity]
              : 0,
          });
        }
      });
    }

    function stop() {
      if (interval) {
        clearInterval(interval);
        clearInterval(sortInterval);
      }
      playLabel.set("text", "Start");
      isplaying = false;
    }

    setInitialData();

    series.appear(1000);

    var cursor = chart2.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    chart2.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    chart2.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
      })
    );

    var legend2 = chart2.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        paddingLeft: 20,
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend2.itemContainers.template.set("width", am5.p100);
    legend2.valueLabels.template.setAll({
      width: am5.p100,
      paddingLeft: 20,
      textAlign: "right",
    });

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend2.data.setAll(chart2.series.values);

    chart2.appear(1000, 100);
  };
  return (
    <PhyRaceBarChartWrapper>
      <div className="phychart" id="physicianJourneyChartContent"></div>
    </PhyRaceBarChartWrapper>
  );
};

PhyRaceBarChart.propTypes = {
  // bla: PropTypes.string,
};

PhyRaceBarChart.defaultProps = {
  // bla: 'test',
};

export default PhyRaceBarChart;
