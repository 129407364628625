import React from "react";
import PropTypes from "prop-types";
import { AccountTrendingInfoWrapper } from './AccountTrendingInfo.styles';

const AccountTrendingInfo = (props) => {
  return <AccountTrendingInfoWrapper>Test content</AccountTrendingInfoWrapper>;
};

AccountTrendingInfo.propTypes = {
  // bla: PropTypes.string,
};

AccountTrendingInfo.defaultProps = {
  // bla: 'test',
};

export default AccountTrendingInfo;
