import React, { useEffect } from "react";
import useDynTabs from "../../libs/react-dyn-tabs";
import "../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { GetAPIRequest } from "../../services/api";
import AZBrainChart from "../AZBrainChart";
import { SecondaryTabWrapper } from "./SecondaryTab.styles";
import config from "../../config";

const SecondaryTab = (props) => {
  var _secondaryTabInstance;
  const secondaryOptions = {
    onLoad: function () {
      console.log("[onLoad]");
      loadTabs();
    },
  };
  const [SecondaryTabList, SecondaryPanelList, onSecondaryTabReady] =
    useDynTabs(secondaryOptions);

  onSecondaryTabReady((instance) => {
    _secondaryTabInstance = instance;
  });

  const addTab = function (i) {
    _secondaryTabInstance
      .open({
        id: i,
        closable: false,
        title: "Tab " + i,
        panelComponent: (porps) => {
          return <p> panel {i} </p>;
        },
      })
      .then(() => {
        console.log("tab is open");
      });

    // switch to tab
    _secondaryTabInstance.select(i).then(() => {
      console.log("tab is selected");
    });
  };

  const loadTabs = () => {
    const { urls, ...restProps } = props;
    if (urls && Array.isArray(urls) && urls.length > 0) {
      urls.map((url, idx) => {
        GetAPIRequest({
          url: config.api.baseURL + url,
        })
          .then((result) => {
            if (result && result.template) {
              var segtabid = "tab" + "-" + result.title.replace(/\s+/g, "-");
              let title = result.title;
              let template = result.template;
              let data = result.data;
              let isPercentage = result.isPercentage;
              _secondaryTabInstance
                .open({
                  id: segtabid,
                  closable: false,
                  title: title,
                  panelComponent: (porps) => {
                    return (
                      <AZBrainChart
                        segtabid={segtabid}
                        title={title}
                        type={template}
                        data={data}
                        isPercentage={isPercentage}
                      />
                    );
                  },
                })
                .then(() => {
                  console.log("tab is open");
                });
              if (idx === 0) {
                _secondaryTabInstance.select(segtabid).then(() => {
                  console.log("tab is selected");
                });
              }
            }
          })
          .catch(() => {});
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <SecondaryTabWrapper>
      <div className="content">
        <SecondaryTabList></SecondaryTabList>
        <SecondaryPanelList></SecondaryPanelList>
      </div>
    </SecondaryTabWrapper>
  );
};

export default SecondaryTab;
