import styled from "styled-components";
export const SiteCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
  .chartContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    overflow-x: scroll;
  }
  .commentContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 14px;
    color: #7e7e7e;
  }
`;
export const LegendContainerWrapper = styled.div`
  flex: 1;
  margin: 0px 10px 0px 80px;
`;
