import React from "react";
import PropTypes from "prop-types";
import { HorizontalBarWrapper } from "./HorizontalBar.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as d3 from "d3";

const HorizontalBar = (props) => {
  const { data, segtabid, isPercentage } = props;
  var root = null;
  const loadData = () => {
    if (data && data.length > 0) {
      var margin = { top: 50, right: 100, bottom: 100, left: 380 },
        width = window.innerWidth - margin.left - margin.right,
        height = data.length * 40 + 200 - margin.top - margin.bottom;

      // append the svg object to the div called 'my_dataviz'
      d3.select(`#nav-${segtabid}`)
        .style("width", width)
        .style("height", height + "px");
      if (root) {
        root.dispose();
      }
      root = am5.Root.new(`nav-${segtabid}`);
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
        })
      );

      chart.zoomOutButton.set("forceHidden", true);

      var yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 30,
      });
      yRenderer.labels.template.setAll({
        fontSize: "14px",
      });

      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "name",
          renderer: yRenderer,
          tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
        })
      );

      var xRenderer = am5xy.AxisRendererX.new(root, {});

      var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          extraMax: 0.1,
          renderer: xRenderer,
        })
      );
      xRenderer.labels.template.setAll({
        fontSize: "12px",
      });

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "count",
          categoryYField: "name",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "left",
            labelText: isPercentage ? "[#ffffff]{valueX}%" : "{valueX}[/]",
          }),
        })
      );
      var columnTemplate = series.columns.template;

      columnTemplate.setAll({
        draggable: true,
        cursorOverStyle: "pointer",
        tooltipText: "Drag to rearrange",
        cornerRadiusBR: 5,
        cornerRadiusTR: 5,
      });

      columnTemplate.events.on("dragstop", () => {
        sortCategoryAxis();
      });

      // Make each column to be of a different color
      columnTemplate.adapters.add("fill", function (fill, target) {
        return am5.color(0x547fd4);
        //return chart.get("colors").getIndex(series.columns.indexOf(target));
        //return "#547DCF";
      });

      columnTemplate.adapters.add("stroke", function (stroke, target) {
        return am5.color(0x547fd4);
        //return chart.get("colors").getIndex(series.columns.indexOf(target));
      });

      yAxis.data.setAll(data);
      series.data.setAll(data);
      sortCategoryAxis();
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: isPercentage ? "{valueX}%" : "{valueX}",
            populateText: true,
            fontSize: "14px",
          }),
        });
      });

      // Get series item by category
      function getSeriesItem(category) {
        for (var i = 0; i < series.dataItems.length; i++) {
          var dataItem = series.dataItems[i];
          if (dataItem.get("categoryY") == category) {
            return dataItem;
          }
        }
      }

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
          xAxis: xAxis,
          yAxis: yAxis,
        })
      );
      columnTemplate.events.on("dragstop", () => {
        sortCategoryYAxis();
      });

      // Axis sorting
      function sortCategoryAxis() {
        // Sort by value
        series.dataItems.sort(function (x, y) {
          return x.get("valueX") - y.get("valueX"); // descending
          //return y.get("valueY") - x.get("valueX"); // ascending
        });

        // Go through each axis item
        am5.array.each(yAxis.dataItems, function (dataItem) {
          // get corresponding series item
          var seriesDataItem = getSeriesItem(dataItem.get("category"));

          if (seriesDataItem) {
            // get index of series data item
            var index = series.dataItems.indexOf(seriesDataItem);
            // calculate delta position
            var deltaPosition =
              (index - dataItem.get("index", 0)) / series.dataItems.length;
            // set index to be the same as series data item index
            dataItem.set("index", index);
            // set deltaPosition instanlty
            dataItem.set("deltaPosition", -deltaPosition);
            // animate delta position to 0
            dataItem.animate({
              key: "deltaPosition",
              to: 0,
              duration: 1000,
              easing: am5.ease.out(am5.ease.cubic),
            });
          }
        });

        // Sort axis items by index.
        // This changes the order instantly, but as deltaPosition is set,
        // they keep in the same places and then animate to true positions.
        yAxis.dataItems.sort(function (x, y) {
          return x.get("index") - y.get("index");
        });
      }

      function sortCategoryYAxis() {
        // Sort by value
        series.dataItems.sort(function (x, y) {
          return y.get("graphics").y() - x.get("graphics").y();
        });

        var easing = am5.ease.out(am5.ease.cubic);

        // Go through each axis item
        am5.array.each(yAxis.dataItems, function (dataItem) {
          // get corresponding series item
          var seriesDataItem = getSeriesItem(dataItem.get("category"));

          if (seriesDataItem) {
            // get index of series data item
            var index = series.dataItems.indexOf(seriesDataItem);

            var column = seriesDataItem.get("graphics");

            // position after sorting
            var fy =
              yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) -
              column.height() / 2;

            // set index to be the same as series data item index
            if (index != dataItem.get("index")) {
              dataItem.set("index", index);

              // current position
              var x = column.x();
              var y = column.y();

              column.set("dy", -(fy - y));
              column.set("dx", x);

              column.animate({
                key: "dy",
                to: 0,
                duration: 600,
                easing: easing,
              });
              column.animate({
                key: "dx",
                to: 0,
                duration: 600,
                easing: easing,
              });
            } else {
              column.animate({
                key: "y",
                to: fy,
                duration: 600,
                easing: easing,
              });
              column.animate({
                key: "x",
                to: 0,
                duration: 600,
                easing: easing,
              });
            }
          }
        });

        yAxis.dataItems.sort(function (x, y) {
          return x.get("index") - y.get("index");
        });
      }

      series.appear(1000);
      chart.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <HorizontalBarWrapper id={`nav-${segtabid}`}></HorizontalBarWrapper>;
};

HorizontalBar.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

HorizontalBar.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default HorizontalBar;
