import React, { useEffect, useState } from "react";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import WordCloud from "../../../components/WordCloud";
import { AccountsWrapper } from "./Accounts.styles";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import SiteMap from "../../../components/SiteMap/SiteMap";

const Accounts = (props) => {
  
  const navigate = useNavigate();
  var _accountsInstance;
  const options = {
    tabs: [
      {
        id: "1",
        title: "Parent",
        closable: false,
        panelComponent: (porps) => {
          return (
            <WordCloud
              url={config.api.accountsParent}
              containerId="parent"
              onClick={(d) => {
                navigate(`/insights/accounts-info/parent/${d.toUpperCase()}`);
              }}
            />
          );
        },
      },
      {
        id: "2",
        title: "Top Parent",
        lazy: true,
        closable: false,
        panelComponent: (porps) => {
          return (
            <WordCloud
              url={config.api.accountsTopParent}
              containerId="top_parent"
              onClick={(d) => {
                navigate(
                  `/insights/accounts-info/top_parent/${d.toUpperCase()}`
                );
              }}
            />
          );
        },
      },
    ],
    selectedTabID: "1",
    onLoad: function () {
      console.log("[onLoad]");
    },
  };
  const [Tablist, Panellist, ready] = useDynTabs(options);
  ready((instance) => {
    _accountsInstance = instance;
  });

  useEffect(() => {
    //alert("!");
  }, []);
  return (
    <AccountsWrapper>
      <SiteMap />
      <div className="content">
        <Tablist></Tablist>
        <Panellist></Panellist>
      </div>
    </AccountsWrapper>
  );
};

Accounts.propTypes = {
  // bla: PropTypes.string,
};

Accounts.defaultProps = {
  // bla: 'test',
};

export default Accounts;
