import { Col, DatePicker, Row, Slider, Space, Image } from "antd";
import React, { useState, useEffect } from "react";
import MultiLineChart from "../../../components/MultiLineChart/MultiLineChart";
import PhysicianList from "../../../components/PhysicianList/PhysicianList";
import { CardLayout, TrendingHomeWrapper } from "./TrendingHome.styles";
import Select from "react-select";
import SiteMap from "../../../components/SiteMap/SiteMap";
import { GetAPIRequest } from '../../../services/api/index'
import config from "../../../config";
import calendarImg from "../../../assets/img/calendar.png";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import CustomDatePicker from '../CustomDatePicker';


const { RangePicker } = DatePicker;

const Option = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;
  return (
    <div
      ref={innerRef}
      css={getStyles("option", props)}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className
      )}
      style={{ padding: "5px 5px", fontSize: 14, textAlign: "left" }}
      {...innerProps}
    >
      {children}
    </div>
  );
};

const TrendingHome = (props) => {
  const [filters, setFilters] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const dateFormat = 'YYYY-MM-DD';
  const [fromDate, setFromDate] = useState(moment(new Date("2021-07-01")).format(dateFormat));
  const [toDate, setToDate] = useState(moment(new Date("2021-12-01")).format(dateFormat));
  const navigate = useNavigate()

  useEffect(() => {
    getDrugs();
    getFilters();

  }, []);
  const onClick = (type, value) => {

  };

  useEffect(() => {
    getChart();

  }, [selectedDrug, selectedFilter, fromDate, toDate])

  const [data, setData] = useState("");
  const [nationalAvg, setNationalAvg] = useState([]);

  const getChart = () => {
    var filterValue = selectedFilter.value;
    var drugItem = "";
    if ((filterValue != "" && filterValue != undefined) &&
      (fromDate != "" && fromDate != undefined) &&
      (toDate != "" && toDate != undefined) &&
      selectedDrug != "") {

      if (selectedDrug.length > 0)
        selectedDrug.map((item, index) => {
          drugItem += item.value + ","
        });
      else
        drugItem = selectedDrug.value;

      var url = `${config.api.treatedPatients}`;
      url += "/type/" + filterValue;
      url += "/fromDate/" + fromDate;
      url += "/toDate/" + toDate;
      url += `?drugs=${drugItem}`;
      // &profile=${profile}&dataSource=${dataSource}`;


      GetAPIRequest({
        url: url
      }).then((response) => {

        if (response) {
          setData(response);
          //setNationalAvg(response.data[0].data)
        }
        else {
          console.log("no response from druglist api")
        }

      });
    }
  }

  useEffect(() => {
    if (selectedFilter != undefined && selectedFilter != "")
      getNationalAverage()
  }, [selectedFilter])

  const [isPercentage, setIsPercentage] = useState(false);

  const getNationalAverage = () => {
    var filterValue = selectedFilter.value;
    var url = `${config.api.nationalAverage}`;
    url += "/type/" + filterValue;
    GetAPIRequest({
      url: url
    }).then((response) => {

      if (response) {
        setIsPercentage(response.isPercentage)
        setNationalAvg(response.data)
      }
      else {
        console.log("no response from druglist api")
      }

    });
  }

  const getSelectedDate = (dateevent) => {
    let fromDate = moment(dateevent[0]._d).format("YYYY-MM-DD");
    let toDate = moment(dateevent[1]._d).format("YYYY-MM-DD");;
    setFromDate(fromDate);
    setToDate(toDate);
  }

  const getDrugs = () => {
    console.log("url", url);

    var profile = "CLL";
    var dataSource = "I";
    var url = `${config.api.drugs
      }?profile=${profile}&dataSource=${dataSource}`;
    GetAPIRequest({
      url: url
    }).then((response) => {
      var drugsList = []
      if (response) {
        response.map((item, index) => {
          var obj = {
            label: item.text,
            value: item.value
          }
          drugsList.push(obj);
        })
        setDrugs(drugsList);
        setSelectedDrug(drugsList[0])
      }
      else {
        console.log("no response from druglist api")
      }

    });
  }


  const getFilters = () => {
    var profile = "CLL";
    var dataSource = "I";
    var url = `${config.api.filters
      }?profile=${profile}&dataSource=${dataSource}`;

    GetAPIRequest({
      url: url
    }).then((response) => {

      var filtersList = []
      if (response) {
        response.map((item, index) => {
          var obj = {
            label: item.text,
            value: item.value
          }
          filtersList.push(obj);
        })
        setSelectedFilter(filtersList[0])
        setFilters(filtersList);
      }
      else {
        console.log("no response from filter api")
      }
    });


  }

  const gotoTableView = (name) => {
    navigate("/trending/" + name + "/" + "accounts")
  }

  const getColorByType = (name) => {
    name = name.toUpperCase();

    switch (name) {
      case "CALQUENCE":
        return "#1F77B4";
      case "VENETOCLAX":
        return "#FF7F27"
      case "CIT":
        return "#2CA02C"
      case "CD20":
        return "#D62728"
      case "IBRUTINIB":
        return "#9467BD"
      // case "Venetoclax":
      //   return "#9467BD"
      // case "Venetoclax":
      //   return "#8C564B"
      // case "Venetoclax":
      //   return "#E377C3"

    }
  }

  const getFromDate = (fromDate) => {
    setFromDate(fromDate);

  }
  const getToDate = (toDate) => {
    setToDate(toDate);
  }


  return (
    <TrendingHomeWrapper>
      <SiteMap />
      <div className="main-ctn trending-home">
        <Row justify="end">

          <div className="filterContainer">
            <Col xs={12}>
              <CustomDatePicker getFromDate={getFromDate} getToDate={getToDate}></CustomDatePicker>
            </Col>
            <Col xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="rightFilterContainer">
                <div className="rightFilter">
                  <span className="title">View</span>
                  <Select
                    isSearchable={true}
                    isMulti={true}
                    isClearable={true}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        width: state.selectProps.width,
                        borderBottom: "1px dotted pink",
                        color: state.selectProps.menuColor,
                        padding: 20,
                        margin: "10px 10px",
                        width: "200px",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        borderBottom: "1px dotted pink",
                        color: state.isSelected ? "red" : "blue",
                        padding: 20,
                      }),
                    }}
                    placeholder="Search Drug"
                    noOptionsMessage="No drug found"
                    value={selectedDrug}
                    onChange={(d) => {
                      setSelectedDrug(d);
                      console.log(d);
                      onClick("drug", d);
                    }}
                    className="selContainer"
                    components={{ Option }}
                    options={drugs}
                  />
                </div>
                <div className="rightFilter" style={{ marginRight: "0px" }}>
                  <span className="title">Filter By</span>
                  <Select
                    placeholder="Select Filter"
                    noOptionsMessage="No filter found"
                    value={selectedFilter}
                    onChange={(d) => {
                      setSelectedFilter(d);
                      console.log(d);
                      onClick("filter", d);
                    }}
                    className="selContainer"
                    components={{ Option }}
                    options={filters}
                  />
                </div>
              </div>
            </Col>
          </div>

        </Row>
        {/* <Row>
          <Col xs={24}>
            <CardLayout
              style={{ padding: 30, paddingRight: 80, paddingLeft: 80 }}
            >
              <Slider marks={marks} step={null} defaultValue={40} />
            </CardLayout>
          </Col>
        </Row>*/}
        <Row className="home-chart ">
          <Col xs={19} className="home-chart-ctn">
            <h6>{selectedFilter.label}  <span>- {localStorage.getItem("SSN_USER_PROFILE")} Treated Patients</span></h6>
            <CardLayout>
              {data != "" && <MultiLineChart data={data} />}
            </CardLayout>
          </Col>
          <Col xs={5}>
            <div className="average-details">
              <div style={{
                fontSize: "15px",
                fontWeight: "bold",

              }}>NATIONAL AVERAGE</div>
              <table className="datarange-chart-dtl">
                {

                  nationalAvg.length > 0 && nationalAvg.map((item, index) => {
                    return (
                      <tr>
                        <td><div style={{
                          height: "20px",
                          width: "20px",
                          backgroundColor: getColorByType(item.name)
                        }}></div></td>
                        <td><a style={{
                          textDecoration: "underline"
                        }} onClick={() => gotoTableView(item.name)}>  {item.name}</a>
                        </td>  <td> {item.value} {isPercentage ? "%" : ""}
                        </td></tr>
                    )

                  })
                }
              </table>
            </div>
          </Col>
          {/*<Col xs={24}>
          
          <PhysicianList />
          </Col>
          <Col xs={24}>
            <CardLayout>
              <WordCloud />
            </CardLayout>
          </Col>
          <Col xs={24}>
            <CardLayout>
              <Grid rows={rows} columns={columns}>
                <DragDropProvider />
                <SortingState
                  defaultSorting={[
                    { columnName: "ProductCategoryName", direction: "asc" },
                  ]}
                />
                <IntegratedSorting />
                <SearchState defaultValue="" />
                <IntegratedFiltering
                  columnExtensions={filteringColumnExtensions}
                />
                <VirtualTableState
                  loading={loading}
                  totalRowCount={totalCount}
                  pageSize={VIRTUAL_PAGE_SIZE}
                  skip={skip}
                  getRows={getRemoteRows}
                />
                <CurrencyTypeProvider for={currencyColumns} />
                <CenterTypeProvider for={centerColumns} />
                <VirtualTable columnExtensions={tableColumnExtensions} />
                <TableColumnReordering
                  defaultOrder={["Id", "ProductCategoryName", "ProductName","StoreName", "SalesAmount"]}
                />

                <TableHeaderRow showSortingControls />

                <Toolbar />
                <SearchPanel />
                <TableColumnVisibility
                  defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <ColumnChooser />
                <ExportPanel startExport={startExport} />
              </Grid>
              <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
              />
            </CardLayout>
          </Col>
          <Col xs={24} md={12}>
            <CardLayout>
              <VennDiagram />
            </CardLayout>
          </Col>
          <Col xs={24} md={12}>
            <CardLayout>
              <DonutChart />
            </CardLayout>
          </Col>
          <Col xs={24}>
            <CardLayout>
              <StackedBarChart />
            </CardLayout>
          </Col>
          <Col xs={24}>
            <CardLayout>
              <MultiLineBarChart />
            </CardLayout>
          </Col>
          */}
        </Row>
      </div>
    </TrendingHomeWrapper>
  );
};

export default TrendingHome;
