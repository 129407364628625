import React, { useEffect, useState, } from "react";
import { GetAPIRequest } from "../../services";
import "antd/dist/antd.css";
import './table.css'
import config from "../../config";
import {
    NavDropdown, Navbar, Nav, Form, Container, Row, Col, Card, Image,
    ListGroup, ListGroupItem, Badge
} from "react-bootstrap";
import ar from "../../assets/img/ar.jpg";
import azbrain from "../../assets/img/azbrain.png";
import DateRange from "../../components/DateRange";
import selectdropdown from "../../assets/img/selectdrop.svg";
import calendarImg from "../../assets/img/calendar.png";
import polygonDown from "../../assets/img/polygon-down.png";
import polygonUp from "../../assets/img/polygon-up.png";
import rectangle from "../../assets/img/rectangle.png";
import moment from "moment";
import { Table, DatePicker, Input, Select, Checkbox, Popover, Button, Icon } from "antd";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import CustomDatePicker from "../../containers/Trending/CustomDatePicker";

const { Option } = Select;
const { RangePicker } = DatePicker;

const TableView = (props) => {

    const [headers, setHeaders] = useState([]);
    const [headerFilter, setHeadersFilter] = useState([]);
    const [data, setData] = useState([]);
    const [topLowAccounts, setTopLowAccounts] = useState([]);
    const [topAccountNumbers, setTopAccountNumbers] = useState([])
    const [druglistData, setDruglistData] = useState([])
    const [searchableColumns, setSearchableColumns] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [defaultRows, setDefaultRows] = useState(50);
    const [offset, setOffSet] = useState(1);
    const [limit, setLimit] = useState(50);
    const dateFormat = 'YYYY-MM-DD';
    const [fromDate, setFromDate] = useState(moment(new Date("2021-07-01")).format(dateFormat));
    const [toDate, setToDate] = useState(moment(new Date("2021-12-01")).format(dateFormat));
    const navigate = useNavigate();
    let { drug, type } = useParams();

    const handleChange = (value) => {

        setSelectedFilter(selectedFilter)
        console.log(`selected ${value}`);
    };

    useEffect(() => {

        if (props.type.toLowerCase() == "physicians") {
            if (selectedFilter != undefined && selectedFilter != "")
                getPhysiciansTableData();
        }
        else {
            if (selectedFilter != undefined && selectedFilter != "")
                getTrendingTableData();
        }
    }, [selectedFilter, fromDate, toDate, defaultRows, limit, orderBy]);



    useEffect(() => {
        getPageSizeList();
        getTopLowAccountList();
    }, [])

    const getPageSizeList = () => {
        var url = `${config.api.pagesizelist}/type/${props.type}/pagedropdownlist`;
        GetAPIRequest({
            url: url
        }).then((response) => {
            if (response) {
                setTopAccountNumbers(response)
            }
        });
    }

    const getTopLowAccountList = () => {

        var url = "";
        if (props.type.toLowerCase() == "physicians")
            url = `${config.api.physicianstoplowaccountlist}/${props.type}/filterdropdownlist`;
        else
            url = `${config.api.toplowaccountlist}/${props.drug}/toplowaccountlist`;

        GetAPIRequest({
            url: url
        }).then((response) => {

            if (response) {

                setOrderBy(response[0].order)
                setSelectedFilter(response[0].value)
                setTopLowAccounts(response)
            }

        });
    }


    const getPhysiciansTableData = () => {
        var url = `${config.api.trendingPhysicians}`;
        url += "/type/" + selectedFilter;
        url += "/fromDate/" + fromDate;
        url += "/toDate/" + toDate;
        url += "/accountId/" + props.accountId
        url += `?offset=${offset}&limit=${limit}&order=${orderBy}`;
        GetAPIRequest({
            url: url
        }).then((response) => {

            console.log("data", data);

            if (response) {
                console.log(response.headers, "header res");
                let columnArr = createHeaders(response.headers)

                setSearchableColumns(response.searchableColumns)
                console.log("columnArr", columnArr);
                setHeaders(columnArr);
                setHeadersFilter(columnArr);
                setData(response.data);


                console.log(response.data, "table res");
            }

        });
    }


    const getTrendingTableData = () => {
        var url = `${config.api.trendingPatients}`;
        url += "/type/" + selectedFilter;
        url += "/fromDate/" + fromDate;
        url += "/toDate/" + toDate;
        url += `?offset=${offset}&limit=${limit}&order=${orderBy}`;
        GetAPIRequest({
            url: url
        }).then((response) => {

            console.log("data", data);

            if (response) {
                console.log(response.headers, "header res");
                let columnArr = createHeaders(response.headers)

                setSearchableColumns(response.searchableColumns)
                console.log("columnArr", columnArr);
                setHeaders(columnArr);
                setHeadersFilter(columnArr);
                setData(response.data);
                console.log(response.data, "table res");
            }

        });
    }

    function createHeaders(headers) {


        // title:    (
        //     <div
        //     dangerouslySetInnerHTML={{__html: headerText}}
        //   /> ),
        let columnArr = [];
        headers.map((item, index) => {

            let columnOnj = {};
            if (item.isVisible) {
                if (item.isPercentage) {
                    columnOnj = {
                        title: item.displayValue == undefined ? item.title : item.displayValue,
                        dataIndex: item.key,
                        key: item.key,
                        isVisible: item.isVisible,
                        sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
                            : (a, b) => {
                                return a[item.key].slice(0, -1) - b[item.key].slice(0, -1)
                            }) : false,
                        render: (_, subitem) => {

                            // if (item.isPercentage) {
                            if (item.isAppend) {
                                let appendArr = subitem[item.key];

                                return (
                                    <>
                                        {appendArr.map((item, index) => {
                                            if (index == 0)
                                                return <>
                                                    {item.isTrend ? <Image src={rectangle} width="25"></Image> : ""}   <span style={{
                                                        margin: "2px"
                                                    }}> {item}% </span>
                                                </>
                                            else
                                                return <>
                                                    {item.isTrend ? <Image src={rectangle} width="25"></Image> : ""}
                                                    <span><b> ({item})</b></span>
                                                </>
                                        })}
                                    </>
                                )

                            }
                            else {
                                let columnValue = parseFloat(subitem[item.key]);
                                if (columnValue == 0) {

                                    return <>
                                        {item.isTrend ? <Image src={rectangle} width="25"></Image> : ""}   <span> {columnValue}% </span>
                                    </>
                                }
                                else if (columnValue > 0) {

                                    return <>
                                        {item.isTrend ? <Image src={polygonUp} width="25"></Image> : ""}  <span>   {columnValue}% </span>
                                    </>
                                }
                                else if (columnValue < 0) {

                                    return <>
                                        {item.isTrend ? <Image src={polygonDown} width="25"></Image> : ""}  <span>   {columnValue}% </span>
                                    </>
                                }
                            }
                            // }
                            // else
                            //     return <>
                            //         {subitem[item.key]}
                            //     </>
                        }

                    }
                }
                else {
                    columnOnj = {
                        title: item.displayValue == undefined ? item.title : item.displayValue,
                        dataIndex: item.key,
                        key: item.key,
                        isVisible: item.isVisible,
                        sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
                            : (a, b) => {
                                return a[item.key] - b[item.key]
                            }) : false,
                        render: (_, subitem) => {

                            if (item.isPercentage) {
                                let columnValue = parseInt(subitem[item.key]);
                                if (columnValue == 0)
                                    return <>
                                        {item.isTrend ? <Image src={rectangle} width="25"></Image> : ""}    {columnValue}%
                                    </>
                                else if (columnValue > 0)
                                    return <>
                                        {item.isTrend ? <Image src={polygonUp} width="25"></Image> : ""}  {columnValue}%
                                    </>
                                else if (columnValue < 0)
                                    return <>
                                        {item.isTrend ? <Image src={polygonDown} width="25"></Image> : ""}   {columnValue}%
                                    </>
                            }
                            else
                                return <>
                                    {subitem[item.key]}
                                </>
                        }
                    }
                }
                columnArr.push(columnOnj);
            }


        })

        return columnArr;
    }

    const getSelectedDate = (dateevent) => {
        let fromDate = moment(dateevent[0]._d).format("YYYY-MM-DD");
        let toDate = moment(dateevent[1]._d).format("YYYY-MM-DD");;
        setFromDate(fromDate);
        setToDate(toDate);
    }

    const getFromDate = (fromDate) => {
        setFromDate(fromDate);

    }
    const getToDate = (toDate) => {
        setToDate(toDate);
    }



    const onChange = (selection) => {

        var headersFilterBySelection = headerFilter.filter(x => x.isVisible = true);
        selection.map((item, index) => {

            let headersFilter = headersFilterBySelection.filter(x => x.key == item);
            if (headersFilter.length > 0) {

                let index = headersFilterBySelection.indexOf(headersFilter[0]);
                headersFilterBySelection[index].isVisible = false;
            }

        })
        let columnArr = createHeaders(headersFilterBySelection);
        setHeaders(columnArr);
    }

    const [filterTableData, setFilterTableData] = useState([]);

    const [isKeywords, setKeywords] = useState(false);

    const showKeywords = () => {
        setKeywords(!isKeywords)
    }

    useEffect(() => {
        if (offset > 1)
            loadMore();
    }, [offset,])        //limit

    const loadMore = () => {
        var url = `${config.api.trendingPatients}`;
        url += "/type/" + selectedFilter;
        url += "/fromDate/" + fromDate;
        url += "/toDate/" + toDate;
        url += `?offset=${offset}&limit=${limit}&order=${orderBy}`;
        GetAPIRequest({
            url: url
        }).then((response) => {
            console.log("data", data);

            if (response) {
                console.log(response.headers, "header res");
                let columnArr = createHeaders(response.headers)

                console.log("columnArr", columnArr);
                setHeaders(columnArr);
                setHeadersFilter(columnArr);
                let newData = response.data;
                setData([...data, ...newData]);
                console.log(data, "table res");
            }

        });
    }

    const searchByValue = (value) => {
        let filterData = []
        if (searchableColumns.length > 0 && value != "") {

            searchableColumns.map((item, index) => {
                filterData = data.filter((column) => {
                    return (
                        column[item].toLowerCase()
                            .includes(value.toLowerCase())
                    )
                });
            });
            setFilterTableData(filterData);
        }
        else {
            setFilterTableData([]);
            setData(data);
        }
    }

    const navigateByType = (row) => {
        if (props.type.toLowerCase() != "physicians") {
            localStorage.setItem("ACCOUNTNAME", row.account_name)
            navigate("/trending/distributions/physicians/" + row.account_id);
        }
    }


    return (
        <Container fluid>
            <div className="main-ctn s">

                <div>
                    <div class="flex-container flex-ctn" >
                        <Row>
                            <Col md={2} lg={3}>
                                {/* <div>
                                <p>Enter Date Range</p>
                                <div className="calendar-picker">  <RangePicker
                                    defaultValue={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
                                    onChange={(d) => getSelectedDate(d)} allowClear={false} picker="month"
                                    suffixIcon={<Image className="calendar-img" preview={false} width={25} src={calendarImg} />} /></div>
                                <div className="calendar-ctn">    </div>

                            </div> */}
                                <CustomDatePicker getFromDate={getFromDate} getToDate={getToDate}></CustomDatePicker>
                            </Col>
                            <Col md={5} lg={4}>
                                <div className="fliter-ctn">
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <Form.Label column>
                                                Filter By :
                                            </Form.Label>

                                            <Form.Select aria-label="Default select example"
                                                onChange={(e) => {

                                                    setOrderBy(e.target.value)
                                                }} value={orderBy} >

                                                {
                                                    topLowAccounts.map((item, index) => {
                                                        return (<option value={item.order} key={item.order}>{item.text
                                                        }</option>)
                                                    })
                                                }
                                            </Form.Select>

                                            <Button className="drop-btn" onClick={() => showKeywords()}>
                                                <Image className="drop-img" preview={false} width={120} src={selectdropdown} />
                                            </Button>

                                            <div className="checkbox-dropdown">
                                                <Container fluid>
                                                    {
                                                        isKeywords && <Checkbox.Group onChange={onChange}>
                                                            <Row>

                                                                {headerFilter.map((group, i) => {

                                                                    return (
                                                                        <Col lg={6} align="left" key={"checkbox-group"}
                                                                        >

                                                                            <Checkbox
                                                                                key={group.key}
                                                                                value={group.key}
                                                                            >
                                                                                {group.title}
                                                                            </Checkbox>
                                                                        </Col>
                                                                    );

                                                                })}

                                                            </Row>
                                                        </Checkbox.Group>
                                                    }
                                                </Container>

                                            </div>
                                        </Form.Group>
                                    </Form>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="showtop">

                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <Form.Label  >
                                                Show Top
                                            </Form.Label>

                                            <Form.Select aria-label="Default select example" onChange={
                                                (e) => {
                                                    setLimit(parseInt(e.target.value))
                                                    //   getTopRowsByCount(e.target.value)
                                                }
                                            } value={limit}>
                                                {
                                                    topAccountNumbers.map((item, index) => {
                                                        return (<option value={item.value}>{item.text
                                                        }</option>)
                                                    })
                                                }
                                            </Form.Select>

                                        </Form.Group>
                                    </Form>
                                </div></Col>


                            <Col lg={2}>
                                <div className="serch-ctn" >
                                    <Input placeholder="Search.." onKeyUp={(e) => searchByValue(e.target.value)} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div >

                <div  >
                    <Row justify="start">
                        <Col span={24} className="table-title">
                            {props.drug == undefined ? localStorage.getItem("ACCOUNTNAME") : props.drug}
                        </Col>
                    </Row>
                </div>

                <div className="filter-table">

                    <Row>
                        <Col>
                            {filterTableData.length > 0 ? < Table
                                className="ant-table-thead"
                                columns={headers}
                                pagination={false}
                                dataSource={filterTableData}
                                bordered
                                onRow={(row) =>
                                ({
                                    onClick: (e) => {
                                        navigateByType(row)
                                    }
                                })
                                }
                            /> : data.length > 0 && < Table
                                className="ant-table-thead"
                                columns={headers}
                                // rowKey={(record) => record.login.uuid}
                                dataSource={data}
                                pagination={false}
                                bordered
                                onRow={(row) =>
                                ({
                                    onClick: (e) => {
                                        navigateByType(row)
                                        // alert("row click" + row.account_id)
                                    }
                                })
                                }
                            />
                            }
                            {/* <button onClick={() => {
                            setLimit(limit);
                            setOffSet(offset + 1);
                            loadMore();
                        }} style={{
                            backgroundColor: "#8e1457",
                            color: "#fff",
                            margin: " 10px 10px",
                            width: "120px",
                            height: "40px"
                        }}>Load more...</button> */}
                        </Col>
                    </Row></div>
                <br />

            </div>
        </Container>

    );
};
export default TableView;
