import React from "react";
import PropTypes from "prop-types";
import { AccountTrendingWrapper } from "./AccountTrending.styles";
import TrendingTable from "../../../components/TrendingTable";
import TableView from "../../../components/TableView";
import config from "../../../config";
import SiteMap from "../../../components/SiteMap/SiteMap";
import { useHistory, useNavigate, useParams } from "react-router-dom";
const AccountTrending = (props) => {
 
  let { drug, type } = useParams();
  return (
    <AccountTrendingWrapper>
      <SiteMap />
      {/* <TrendingTable url={`${config.api.reportinghcp}`} /> */}
      <TableView drug={drug} type={type}></TableView>
    </AccountTrendingWrapper>
  );
};

AccountTrending.propTypes = {
  // bla: PropTypes.string,
};

AccountTrending.defaultProps = {
  // bla: 'test',
};

export default AccountTrending;
