import React, { useEffect, useState, } from "react";
import { GetAPIRequest } from "../../services";
import "antd/dist/antd.css";
import '../TableView/table.css'
import config from "../../config";
import {
  NavDropdown, Navbar, Nav, Form, Container, Row, Col, Card, Image,
  ListGroup, ListGroupItem, Badge
} from "react-bootstrap";
import ar from "../../assets/img/ar.jpg";
import azbrain from "../../assets/img/azbrain.png";
import DateRange from "../../components/DateRange";
import selectdropdown from "../../assets/img/selectdrop.svg";
import calendarImg from "../../assets/img/calendar.png";

import moment from "moment";
import { Table, DatePicker, Input, Select, Checkbox, Popover, Button, Icon } from "antd";
import { useHistory, useNavigate, useParams } from "react-router-dom";


const { Option } = Select;
const { RangePicker } = DatePicker;



function NavContent(props) {

  const [headers, setHeaders] = useState([]);
  const [headerFilter, setHeadersFilter] = useState([]);
  const [data, setData] = useState([]);
  const [topLowAccounts, setTopLowAccounts] = useState([]);
  const [topAccountNumbers, setTopAccountNumbers] = useState([])
  const [druglistData, setDruglistData] = useState([])
  const [searchableColumns, setSearchableColumns] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [defaultRows, setDefaultRows] = useState(50);
  const [offset, setOffSet] = useState(1);
  const [limit, setLimit] = useState(50);
  const dateFormat = 'YYYY-MM-DD';
  const [fromDate, setFromDate] = useState(moment(new Date("2021-07-01")).format(dateFormat));
  const [toDate, setToDate] = useState(moment(new Date("2021-12-01")).format(dateFormat));
  const navigate = useNavigate();
  let { drug } = useParams();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  //api integrations

  useEffect(() => {

  }, []);

  const [isKeywords, setKeywords] = useState(false);

  const showKeywords = () => {
    setKeywords(!isKeywords)
  }


  const getSelectedDate = (dateevent) => {
    let fromDate = moment(dateevent[0]._d).format("YYYY-MM-DD");
    let toDate = moment(dateevent[1]._d).format("YYYY-MM-DD");;
    setFromDate(fromDate);
    setToDate(toDate);
  }

  function createHeaders(headers) {

    let columnArr = [];
    headers.map((item, index) => {

      let columnOnj = {};
      if (item.isVisible) {
        if (item.isPercentage) {
          columnOnj = {
            title: item.displayValue == undefined ? item.title : item.displayValue,
            dataIndex: item.key,
            key: item.key,
            isVisible: item.isVisible,
            sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
              : (a, b) => {
                return a[item.key].slice(0, -1) - b[item.key].slice(0, -1)
              }) : false,

          }
        }
        else {
          columnOnj = {
            title: item.displayValue == undefined ? item.title : item.displayValue,
            dataIndex: item.key,
            key: item.key,
            isVisible: item.isVisible,
            sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
              : (a, b) => {
                return a[item.key] - b[item.key]
              }) : false,

          }
        }
        columnArr.push(columnOnj);
      }


    })

    return columnArr;
  }

  const onChange = (selection) => {

    var headersFilterBySelection = headerFilter.filter(x => x.isVisible = true);
    selection.map((item, index) => {

      let headersFilter = headersFilterBySelection.filter(x => x.key == item);
      if (headersFilter.length > 0) {

        let index = headersFilterBySelection.indexOf(headersFilter[0]);
        headersFilterBySelection[index].isVisible = false;
      }

    })
    let columnArr = createHeaders(headersFilterBySelection);
    setHeaders(columnArr);
  }

  const [filterTableData, setFilterTableData] = useState([]);
  const searchByValue = (value) => {
    let filterData = []
    if (searchableColumns.length > 0 && value != "") {

      searchableColumns.map((item, index) => {
        filterData = data.filter((column) => {
          return (
            column[item].toLowerCase()
              .includes(value.toLowerCase())
          )
        });
      });
      setFilterTableData(filterData);
    }
    else {
      setFilterTableData([]);
      setData(data);
    }
  }

  return (
    <div>
      <div class="flex-container flex-ctn" >
        <Row>
          <Col md={2} lg={3}>  <div>
            <p>Enter Date Range</p>
            <div className="calendar-picker">  <RangePicker
              defaultValue={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
              onChange={(d) => getSelectedDate(d)} allowClear={false}
              suffixIcon={<Image className="calendar-img" preview={false} width={25} src={calendarImg} />} /></div>
            <div className="calendar-ctn">    </div>

          </div>
          </Col>
          <Col md={5} lg={4}>
            <div className="fliter-ctn">
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column>
                    Filter By :
                  </Form.Label>

                  <Form.Select aria-label="Default select example"
                    onChange={(e) => {
                      setOrderBy(e.target.value)
                    }} value={orderBy} >

                    {
                      topLowAccounts.map((item, index) => {

                        return (<option value={item.order} key={item.order}>{item.text
                        }</option>)
                      })
                    }
                  </Form.Select>





                  <Button className="drop-btn" onClick={() => showKeywords()}>
                    <Image className="drop-img" preview={false} width={120} src={selectdropdown} />
                  </Button>

                  <div className="checkbox-dropdown">
                    <Container fluid>
                      {
                        isKeywords && <Checkbox.Group onChange={onChange}>
                          <Row>

                            {headerFilter.map((group, i) => {

                              return (
                                <Col lg={6} align="left" key={"checkbox-group"}
                                >

                                  <Checkbox
                                    key={group.key}
                                    value={group.key}
                                  >
                                    {group.title}
                                  </Checkbox>
                                </Col>
                              );

                            })}

                          </Row>
                        </Checkbox.Group>
                      }
                    </Container>

                  </div>
                </Form.Group>
              </Form>

            </div>
          </Col>
          <Col lg={3}>
            <div className="showtop">

              <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label  >
                    Show Top
                  </Form.Label>

                  <Form.Select aria-label="Default select example" onChange={
                    (e) => {
                      setLimit(parseInt(e.target.value))

                    }
                  } value={limit}>
                    {
                      topAccountNumbers.map((item, index) => {
                        return (<option value={item.value}>{item.text
                        }</option>)
                      })
                    }
                  </Form.Select>

                </Form.Group>
              </Form>
            </div></Col>


          <Col lg={2}>
            <div className="serch-ctn" >
              <Input placeholder="Search.." onKeyUp={(e) => searchByValue(e.target.value)} />
            </div>
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default NavContent;