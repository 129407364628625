import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PhyBarChartWrapper } from "./PhyBarChart.styles";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { GetAPIRequest } from "../../services";
import config from "../../config";

const PhyBarChart = (props) => {
  const {selPhy, type} = props;
  var physicianJourneyName = "";
  var physicianJourneyId = "";
  var lineInterval = "";
  var activities = [];
  var lineData = [];
  var resData = [];
  var trendingData = [];
  var currentIndex = 0;
  var _accountType = "";
  let root = null;
  useEffect(() => {
    //
    setTimeout(()=>{
      if (root) {
        root.dispose();
      }
      root = am5.Root.new("physicianJourneyChartContent1");
      getData();
    }, 1000)
    
  }, []);
  const getData = () => {
    GetAPIRequest({
      url: `${config.api.physicianJourney}?id=${selPhy["Prescriber Id"]}&racechart=false&type=${type}`,
    })
      .then((response) => {
        loadData(response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const loadData = (data) => {
    resData = data;
    var treData = [];
    var actData = [];
    var treatments = [];
    var activities = [];
    var defaultTreatments = [
      "CRT",
      "SURGERY",
      "RADIATION",
      "CHEMOTHERAPY",
      "IMFINIZI",
      "IMFINZI+CHEMOTHERAPY",
      "KEYTRUDA",
      "KEYTRUDA+CHEMOTHERAPY",
      "TAGRISSO",
      "TAGRISSO+CHEMO",
    ];

    var profile = localStorage.getItem("SSN_USER_PROFILE");

    if (profile == "CLL") {
      defaultTreatments = [
        "CRT",
        "SURGERY",
        "RADIATION",
        "CHEMOTHERAPY",
        "IMFINIZI",
        "IMFINZI+CHEMOTHERAPY",
        "KEYTRUDA",
        "KEYTRUDA+CHEMOTHERAPY",
        "TAGRISSO",
        "TAGRISSO+CHEMO",
        "ACALABRUTINIB",
        "IBRUTINIB",
        "ZANUBRUTINIB",
        "LENALIDOMIDE",
      ];
    } else if (profile == "NSCLC") {
      defaultTreatments = [
        "CRT",
        "SURGERY",
        "RADIATION",
        "CHEMOTHERAPY",
        "IMFINIZI",
        "IMFINZI+CHEMOTHERAPY",
        "KEYTRUDA",
        "KEYTRUDA+CHEMOTHERAPY",
        "TAGRISSO",
        "TAGRISSO+CHEMO",
      ];
    }
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    data.forEach((d, idx) => {
      for (const [key, value] of Object.entries(d.treatments)) {
        if (treatments.indexOf(key) < 0) {
          treatments.push(key);
        }
      }
      for (const [key, value] of Object.entries(d.activities)) {
        if (activities.indexOf(key) < 0) {
          activities.push(key);
        }
      }
    });

    treatments.sort((a, b) => a.localeCompare(b));
    activities.sort((a, b) => a.localeCompare(b));

    data.forEach((d, idx) => {
      var tobj = {
        year: d.title,
      };
      var tr = d.treatments;
      treatments.forEach((t, idx) => {
        if (tr[t]) {
          tobj[t] = tr[t];
        } else {
          tobj[t] = 0;
        }
      });
      treData.push(tobj);
    });

    activities.forEach((t, idx) => {
      var aobj = {};
      data.forEach((d, idx) => {
        var act = d.activities;
        if (act[t]) {
          aobj[t] = act[t];
        } else {
          aobj[t] = 0;
        }
      });
    });

    console.log("treData", treData);
    console.log("actData", actData);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(treData);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    function makeSeries(name, fieldName) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          valueYShow: "valueYTotalPercent",
          categoryXField: "year",
          fillOpacity: 0.9,
        })
      );

      series.columns.template.setAll({
        tooltipText:
          "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
        tooltipY: am5.percent(10),
      });
      series.data.setAll(treData);
      if (defaultTreatments.indexOf(name) > -1) {
        series.columns.template.setAll({
          fillOpacity: 0.9,
        });
        series.appear();
      } else {
        series.columns.template.setAll({
          fillOpacity: 0.3,
        });
        series.hide();
      }

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      //legend.data.push(series);
    }

    for (const [key, value] of Object.entries(treData[0])) {
      if (key !== "year") {
        makeSeries(key, key);
      }
    }

    function generateActivityData(activity) {
      console.log("actData", activity);
      var _data = [];
      for (var i = 0; i < data.length; ++i) {
        console.log("actData", data[i]);
        _data.push({
          year: data[i].title,
          value: data[i].activities[activity]
            ? data[i].activities[activity]
            : 0,
        });
      }
      console.log("actData", _data);
      return _data;
    }

    var yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    for (var i = 0; i < activities.length; i++) {
      var series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: activities[i],
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: "value",
          categoryXField: "year",
          legendValueText: "{valueY}",
        })
      );
      var _data = generateActivityData(activities[i]);
      series3.data.setAll(_data);
      series3.strokes.template.setAll({
        strokeWidth: 2,
        templateField: "strokeSettings",
      });
      series3.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          sprite: am5.Circle.new(root, {
            radius: 5,
            stroke: series3.get("stroke"),
            strokeWidth: 2,
            fill: am5.color(0xffffff),
          }),
        });
      });

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series3.appear();
    }

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    cursor.lineY.set("visible", false);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    chart.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
      })
    );

    var legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        paddingLeft: 15,
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.itemContainers.template.set("width", am5.p100);

    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
  };

  return (
    <PhyBarChartWrapper>
      <div className="phychart" id="physicianJourneyChartContent1"></div>
    </PhyBarChartWrapper>
  );
};

PhyBarChart.propTypes = {
  // bla: PropTypes.string,
};

PhyBarChart.defaultProps = {
  // bla: 'test',
};

export default PhyBarChart;
