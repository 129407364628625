import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 110px);
`;
