import styled from 'styled-components';

export const GeoDistributionWrapper = styled.div`
 display: flex;
 width: 100%;
 flex-direction:column;
 .content {
    flex: 1;
 }
`;
