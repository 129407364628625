import styled from "styled-components";

export const SalesRegionMapWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  padding: 10px 10px;
  justify-content: flex-end;
  margin-bottom: 16px;
  .topSelection {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
    .selectbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 0px 10px;
        white-space: nowrap;
      }
      .sellistbox {
        max-height: 180px;
        overflow: auto;
        margin: 0px 0px 40px;
      }
      button {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #f1f1f1;
        color: #3e3e3e;
        padding: 5px 10px;
        border: 0px solid #f1f1f1;
      }
    }
  }
`;
export const SalesRegionMapContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
`;
