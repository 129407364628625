import React, { useEffect, useState } from "react";
import { GetAPIRequest } from "../../services";
import "antd/dist/antd.css";
import { Table } from "antd";
import './table.css'
import NavContent from "../HomeView/NavContent";

  
function TableC() {

const [filterData, setFilterData]= useState([]);

  useEffect(() => {
    GetAPIRequest({
      url: `https://api.dev.azbrain.astrazeneca.com/patient/api/v3/treatments/filter/type/patient_treated_in_1l/startDate/2021-07-01/endDate/2021-12-01?dataSource=I&profile=CLL`,
    }).then((response) => {
   if(response){
        setFilterData(
          response.data.map(index =>({
  Accountname: index.account_name,
  TotalPatients:index.total_patients,
  WaitandWatch:index.wait_and_watch,
  TreatedPatients:index.treated_patients,
  Acalabrutinib1l: index.acalabrutinib_1l,
  Cd2011:index.cd20_1l,
  Cit1l:index.cit_1l,
  Ibrutinib1l:index.ibrutinib_1l,

  Venetoclax1l:index.venetoclax_1l


    }))


        )
        console.log(response.data, "table res");
  }
     else{
       console("there is no respone");
     }

    });
  }, []);



  const columns = [


    {
      title: "Account Name",
      dataIndex: "Accountname",
      sorter: true,
      width:"30%"
    
    },
    {
      title: "Total Patients",
      dataIndex: "TotalPatients",
      sorter: true,
   
    },
 
    {
      title: "Wait and Watch",
      dataIndex: "WaitandWatch",
      sorter: true,
    
    },
    {
      title: "  Treated Patients",
      dataIndex: "TreatedPatients",
      sorter: true,
    
    },
   
    {
      title: "  Treated Patients with Acalabrutinib 1L",
      dataIndex: "Acalabrutinib1l",
      sorter: true,
    
    },
 
    {
      title: "  Treated Patients with Acalabrutinib 1L Q4-2021",
      dataIndex: "Ibrutinib1l",
      sorter: true,
    
    },
    {
      title: "  Trend",
      dataIndex: "Cd2011",
      sorter: true,
    
    },
    {
      title: "  TRX",
      dataIndex: "Cit1l",
      sorter: true,
    
    },
    {
      title: "  NPS",
      dataIndex: "Venetoclax1l",
      sorter: true,
    
    },
 
   
  ];
  
  



  return (
<div>
    
    <NavContent/>

    <Table
  className="ant-table-thead"
      columns={columns}
      // rowKey={(record) => record.login.uuid}
      dataSource={filterData}
 
    />

    <br/>

    </div>

  );
};
export default TableC;
