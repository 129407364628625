import styled from "styled-components";

export const SunburstChartWrapper = styled.div`
  width: 100%;
  background: "white";
  padding: "10px";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-segmented-item {
    min-width: 100px;
    padding: 5px;
  }
  .btnCompare {
    background-color: #908 !important;
    height: 35px;
    margin-left: 10px;
    border-radius: 5px;
    width: 150px;
  }
  .svgSunburstChart {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .topSelection {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: flex-end;
    padding: 10px 10px 20px;
    .searchContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 10px 30px;
    }
    .selContainer {
      width: 100%;
    }
    .selectbox {
      min-width: 400px;
      max-width: 400px;
      margin: 0px;
      display: flex;
      align-self: flex-end;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 0px 10px;
        white-space: nowrap;
      }
      .sellistbox {
        max-height: 180px;
        overflow: auto;
        margin: 0px 0px 40px;
      }
      button {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #f1f1f1;
        color: #3e3e3e;
        padding: 5px 10px;
        border: 0px solid #f1f1f1;
      }
    }
  }
`;

export const SunburstChartTitle = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 0px;
  text-align: left;
  font-family: "HelveticaNeue-Medium" !important;
  font-size: 20px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 10px 0px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
`;

export const SunburstChartComment = styled.div`
  text-align: center;
  font-family: "HelveticaNeue" !important;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0px 0px 30px;
  width: 100%;
`;

export const ComparisonViewWrapper = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  overfow: hidden;
  flex-direction: column;
  .comparisonDivContainer {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    width: 100%;
    flex-direction: row;
    overfow: hidden;
  overflow-x: scroll;
  }
`;
export const ComparisonDiv = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  box-shadow: 0px 2px 2px 2px #00000012;
  border-radius: 5px;
  border: 1px solid #00000012;
  margin: 10px 5px 80px;
  min-height: 500px;
  padding: 10px 10px;
  flex-direction: column;
  width: 700px;
`;
