import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TrendingChartWrapper } from "./TrendingChart.styles";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { GetAPIRequest } from "../../services";
import config from "../../config";

const TrendingChart = (props) => {
  const { selPhy } = props;
  let root = null;
  useEffect(() => {
    if (root) {
      root.dispose();
    }
    root = am5.Root.new("physicianJourneyTrendingChartContent");
    getData();
  }, []);
  const getData = () => {
    GetAPIRequest({
      url: `${config.api.physicianTrending}/${selPhy["Prescriber Id"]}`,
    })
      .then((response) => {
        loadData(response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const loadData = (data) => {
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        maxTooltipDistance: 0,
        pinchZoomX: true,
      })
    );

    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var value = 100;

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    for (var i = 0; i < data.length; i++) {
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: data[i].name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          legendValueText: "{valueY}",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        })
      );

      data[i].data.map((d, idx) => {
        let date = new Date(d.date);
        date.setHours(0, 0, 0, 0);
        d.date = date.getTime();
      });
      series.data.setAll(data[i].data);
      series.appear();
    }

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    chart.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
      })
    );

    var legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 300,
        paddingLeft: 15,
        height: am5.percent(100),
      })
    );

    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on("pointerover", function (e) {
      var itemContainer = e.target;

      // As series list is data of a legend, dataContext is series
      var series = itemContainer.dataItem.dataContext;

      chart.series.each(function (chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          });
        }
      });
    });

    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on("pointerout", function (e) {
      var itemContainer = e.target;
      var series = itemContainer.dataItem.dataContext;

      chart.series.each(function (chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: chartSeries.get("fill"),
        });
      });
    });

    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
  };
  return (
    <TrendingChartWrapper>
      <div className="phychart" id="physicianJourneyTrendingChartContent"></div>
    </TrendingChartWrapper>
  );
};

TrendingChart.propTypes = {
  // bla: PropTypes.string,
};

TrendingChart.defaultProps = {
  // bla: 'test',
};

export default TrendingChart;
