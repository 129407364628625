export default [
    {
      year: "2021",
      europe: 2.5,
      namerica: 2.5,
      asia: 2.1,
      lamerica: 1,
      meast: 0.8,
      africa: 0.4,
    },
    {
      year: "2022",
      europe: 2.6,
      namerica: 2.7,
      asia: 2.2,
      lamerica: 0.5,
      meast: 0.4,
      africa: 0.3,
    },
    {
      year: "2023",
      europe: 2.8,
      namerica: 2.9,
      asia: 2.4,
      lamerica: 0.3,
      meast: 0.9,
      africa: 0.5,
    },
  ]