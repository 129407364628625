import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SankeyPlotWrapper, SankeyPlotContainer } from "./SankeyPlot.styles";
import data from "./SankeyPlotData";
import * as d3 from "d3";
import d3sankey from "./d3sankey";

const SankeyPlot = (props) => {
  useEffect(() => {
    loadData({
      data: data,
      containerId: "sankey-wheel-chart",
      nodeColor: {
        NSCLC: "rgb(95, 86, 201)",
        "STAGE 4": "rgb(74, 113, 221)",
        "STAGE 1": "#EFEFD0",
        "STAGE 3": "#00937a",
        "STAGE 2": "#933700",
        "Non Identified": "#c83131",
        "Other Biomarker Test": "rgb(51, 145, 225)",
        "PD-L1 TEST": "#06D6A0",
        "EGFR TEST": "#FFD500",
        "No Biomarker Test": "#00509D",
        SURGERY: "#e4419d",
        IMFINZI: "#6e40aa",
        "OTHER IO": "#F7C59F",
        "IO+CHEMOTHERAPY": "#0B5502",
        CHEMOTHERAPY: "rgb(59, 242, 119)",
        RADIATION: "rgb(25, 206, 186)",
        TKI: "#4A2D27",
        TARGETED_THERAPY: "#FFB7C3",
        OTHERS: "#963db3",
        UNTREATED: "#EF476F",
        CRT: "#103D5D",
      },
    });
    return () => {};
  }, []);

  const loadData = (config) => {
    var path;
    var data = [];
    var selectedNodes = [];
    var currentHeight = 1;
    var linkId = "";
    var nodeColor = config.nodeColor;
    var margin = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    };
    var width = 1400 - margin.left - margin.right;
    var height = 600 - margin.top - margin.bottom;
    d3
    .select(`#${config.containerId}`).html("");
    console.log("root--1", config.containerId);
    var svg = d3
      .select(`#${config.containerId}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var link = svg.append("g");
    var nodes = svg.append("g");
    data = config.data;

    function updateLinks(d) {
      currentHeight = d.depth + 1;
      selectedNodes[d.depth] = d.nodeId;
      updateData(data, d.depth, d.nodeId);
    }

    function updateData(data, datadepth, nodeId) {
      var resObj = {};
      var nodesnames = [];
      var links = [];
      var nodes = [];
      parseWheelData(data);
      resObj.nodes = [];
      resObj.nodes = nodesnames;
      resObj.links = [];
      resObj.links = links;
      function parseWheelData(root) {
        console.log("parseWheelData---", root);
        var depth = 0;
        var mRoot = {};
        if (root.children) {
          mRoot.name = root.name;
          if (nodes.indexOf(root.name) < 0) {
            nodes.push(root.name);
            nodesnames.push({
              name: root.name,
              nodeId: root.name,
              depth: depth,
              value: root.size,
              id: root.id,
              nuuid: root.nuuid,
              percent: 100,
            });
          }
          selectedNodes[0] = root.name;
          mRoot.id = root.id;
          mRoot.node_size = root.size;
          mRoot.children = [];
          mRoot = parseWheelChildNode(
            root.children,
            mRoot,
            root,
            depth,
            mRoot.name
          );
        }
        return mRoot;
      }
      function parseWheelChildNode(root, mRoot, rootParent, depth, parent) {
        var tNode = mRoot;
        var dSize = 0;
        var currdepth = depth + 1;

        root.map((data, ridx) => {
          if (currdepth == 1) {
            linkId = data.name;
          }
          var d = {};
          d.name = data.name;
          var name = parent + "|" + data.name;
          var rootname = parent;
          var parents = parent + "|" + data.name;
          var sz = data.size;
          var val = ((data.size / rootParent.size) * 100).toFixed(2);
          var _vval = ((data.size / rootParent.size) * 100).toFixed(2);
          if (currdepth <= datadepth) {
            val = 100;
          }
          var isNodeValid = false;
          if (nodes.indexOf(name) < 0) {
            if (nodeId) {
              if (currdepth < datadepth) {
                if (name == selectedNodes[currdepth]) {
                  isNodeValid = true;
                }
              } else if (currdepth == datadepth) {
                if (name == nodeId) {
                  isNodeValid = true;
                }
              } else if (currdepth == datadepth + 1) {
                if (rootname == nodeId) {
                  isNodeValid = true;
                }
              }
            } else {
              isNodeValid = true;
            }
            if (isNodeValid) {
              nodes.push(name);
              nodesnames.push({
                name: parents,
                nodeId: parents,
                depth: currdepth,
                id: data.id,
                nuuid: data.nuuid,
                value: sz,
                percent: _vval,
              });
            }
          }
          let filter = links.filter(
            (d) =>
              (d.target == rootname) & (d.source == name) ||
              (d.source == rootname) & (d.target == name)
          );
          var isLinkValid = false;
          if (filter.length <= 0) {
            if (name !== rootname) {
              if (nodeId) {
                if (currdepth < datadepth) {
                  //console.log("rootname--links", `${currdepth}/${datadepth}/${nodeId}/${name}`);
                  if (
                    selectedNodes.indexOf(rootname) > -1 &&
                    selectedNodes.indexOf(name) > -1
                  ) {
                    isLinkValid = true;
                  }
                } else if (currdepth == datadepth) {
                  //console.log("rootname--links", `${currdepth}/${datadepth}/${nodeId}/${name}`);
                  if (
                    name == nodeId &&
                    selectedNodes.indexOf(rootname) > -1 &&
                    selectedNodes.indexOf(name) > -1
                  ) {
                    isLinkValid = true;
                  }
                } else if (currdepth == datadepth + 1) {
                  //console.log("rootname--links", `${currdepth}/${datadepth+1}/${nodeId}/${name}`);
                  if (rootname == nodeId) {
                    isLinkValid = true;
                  }
                }
              } else {
                isLinkValid = true;
              }
              if (isLinkValid) {
                links.push({
                  source: parent,
                  target: parents,
                  value: sz,
                  percent: _vval,
                  id: parents,
                  targetDepth: currdepth,
                  sourceDepth: depth,
                });
              }
            }
          }
          d.id = data.id;
          d.children = [];
          var rootNode = {};

          if (data.children && data.children.length > 0) {
            d.node_size = data.size;
            if (currdepth <= datadepth) {
              rootNode = parseWheelChildNode(
                data.children,
                d,
                data,
                currdepth,
                parents
              );
            }
          } else {
            d.size = data.size;
            rootNode = d;
          }
          dSize += data.size;

          tNode.children.push(rootNode);
          if (ridx == root.length - 1) {
            var _size = 1;
            if (dSize < rootParent.size) {
              _size = rootParent.size - dSize;
            }
          }
        });
        return tNode;
      }
      console.log("nodes--", nodes);
      console.log("links--", links);
      console.log("resObj--", resObj);
      draw(resObj);
    }

    function draw(graph) {
      nodes.selectAll(".node").remove();
      link.selectAll(".link").remove();

      width = (currentHeight + 1) * 250;
      height = graph.nodes.length * 20;
      if (height < 700) {
        height = 700;
      }

      d3.select(`#${config.containerId} svg`)
        .style("width", width + 450)
        .style("height", height + 50);

      var sankey = d3sankey()
        .nodeWidth(17)
        .nodePadding(27)
        .align("left")
        .size([width, height]);

      path = sankey.link();

      sankey.nodes(graph.nodes).links(graph.links).layout(64);

      sankey.relayout();

      // add in the links
      link
        .selectAll(".link")
        .data(graph.links)
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("opacity", 1.0)
        .attr("d", path)
        .style("fill", "none")
        .style("stroke", function (d) {
          return "#dddddd";
        })
        .style("stroke-opacity", "0.4")
        .on("mouseover", function (event, d) {
          d3.select(this).style("stroke-opacity", "0.7");
        })
        .on("mouseout", function (event, d) {
          d3.select(this).style("stroke-opacity", "0.4");
        })
        .style("stroke-width", function (d) {
          return Math.max(5, d.dy);
        });

      // add in the nodes
      var node = nodes
        .selectAll(".node")
        .data(graph.nodes)
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("opacity", 1.0)
        .attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        });

      // add the rectangles for the nodes
      node
        .append("rect")
        .attr("height", function (d) {
          if (d.dy < 10) {
            return 5;
          }
          return d.dy;
        })
        .attr("width", sankey.nodeWidth())
        .style("fill", function (d) {
          var name = d.name.split("|");
          name = name[name.length - 1];
          return nodeColor[name];
        })
        .style("fill-opacity", ".9")
        .style("shape-rendering", "crispEdges")
        .style("stroke", function (d) {
          return d3.rgb(d.color).darker(2);
        })
        .on("click", (event, d) => {
          if (config.onSelectSegment) {
            config.onSelectSegment({
              data: d,
            });
            console.log("d---", d);
          }
          updateLinks(d);
        })
        .append("title")
        .text(function (d) {
          var name = d.name.split("|");
          name = name[name.length - 1];
          return name + " - \n(" + d.value + ") - (" + d.percent + "%)";
        });

      node
        .append("foreignObject")
        .attr("x", 6 + sankey.nodeWidth())
        .attr("y", function (d) {
          return d.dy / 2 - 5;
        })
        .attr("width", 300)
        .attr("height", function (d) {
          return 20;
        })
        .append("xhtml:div")
        .style("color", "#000")
        .style("text-align", "left")
        .style("padding", "0px")
        .style("font-size", `12px`)
        .style("overflow-y", "auto")
        .html(function (d) {
          var name = d.name.split("|");
          name = name[name.length - 1];
          return name + " - (" + d.value + ") - (<b>" + d.percent + "%</b>)";
        });
      node.transition().duration(750);
    }

    updateData(data, 0);
  };
  return (
    <SankeyPlotWrapper>
      <SankeyPlotContainer id="sankey-wheel-chart"></SankeyPlotContainer>
    </SankeyPlotWrapper>
  );
};

SankeyPlot.propTypes = {
  // bla: PropTypes.string,
};

SankeyPlot.defaultProps = {
  // bla: 'test',
};

export default SankeyPlot;
