import TableView from "../../../components/TableView";
import PhysicianInfo from "../PhysicianInfo/PhysicianInfo";

import SiteMap from "../../../components/SiteMap/SiteMap";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import VennDiagram from "../../../components/VennDiagram/VennDiagram";
import MultiLineBarChart from "../../../components/MultiLineBarChart"
import StackedBarChart from "../../../components/StackedBarChart/StackedBarChart";

import { DatePicker,  Slider, Space, Image ,colors,Select, Tabs, Input} from "antd";
import './Slider.styles.css'
import 'antd/dist/antd.css';


import { Container, Row, Col } from "react-bootstrap";


const { TabPane } = Tabs;


const style = {
    background: '#0092ff',
  
  };

  
const marks = {
    0: '>30 Days',
    20: '3 Months',
    40: '6 Months',
    60: '1 Year',
    80:'2 Years',
  
    100:  {
    //   style: {
    //     color: 'black',
        
    //   },
      label: <strong>All Time</strong>,
    },
  };

const Distributions = (props) => {

    const navigate = useNavigate();
    const { type, accountId } = useParams();



    
    useEffect(() => { }, []);

    return (
        <>


            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <SiteMap />
                        <Tabs className="tab-view" centered defaultActiveKey="0" type="card" >
                            <TabPane className="accountdetails-view" tab="Account Details" key="accountdetails">
                                <Row jusitfy="start" ><Col align="left"> <div className="form-label-ctn">
                                    <label>Filter by</label>
                                    <Select defaultValue="TRx">
                                        <option value="TRx">TRx</option>
                                    </Select>
                                </div>
                                    <div className="form-label-ctn">
                                        <label>Pick a Drug</label>
                                        <Select defaultValue="Calquence">
                                            <option value="Calquence">Calquence</option>
                                        </Select>
                                    </div>
                                </Col><Col align="right">  <div className="form-label-ctn last-form "> <div className="serch-ctn" >
                                    <Input placeholder="Search Accounts.." />
                                </div></div></Col></Row>

                                <Row className="account-info-ctn">
                                    <Col align="left" lg={8}><h2><span>Mayo Clinic - </span>200 1st Street Southwest, Rochester, Minnesota, US</h2></Col>
                                    <Col align="right" lg={4} > <div className="form-label-ctn value-ctn">
                                        <label>Total Physicians:</label> <label className="value-label">267</label></div> <div className="last-form value-ctn form-label-ctn">
                                            <label>Patient Volume:</label> <label className="value-label">6017</label></div></Col>
                                </Row>
                                <Row>
                                    <Col lg={8}>
                                        <div className="chart-format">
                                            <MultiLineBarChart></MultiLineBarChart>
                                        </div>
                                        <br/>
                                        <br/>
      <Slider marks={marks} step={10} style={{marginLeft:"2rem"}} />
                                    </Col>
                                    <br/>

                                    <Col lg={4}>
                                        <div className="chart-format">
                                            <VennDiagram></VennDiagram>
                                        </div>
                                    </Col>
                                </Row>
                             
                                <Row className="">
                                    <Col>
                                        <div className="chart-format mt-10">
                                            <StackedBarChart></StackedBarChart>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="HCP Information" key="HCPinfo" className="">
                                <TableView type={type} accountId={accountId}></TableView>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Distributions;