//import { config } from "process";
import React, { useEffect } from "react";
import { AZBrainChartWrapper } from "./AZBrainChart.styles";
import {
  Line,
  Donut,
  Bar,
  Pie,
  StackedClusteredBar,
  HorizontalBar,
  Sankey,
  RaceBar,
  DrillDownPie,
  ClusteredBar
} from "./Charts";

const AZBrainChart = (props) => {
  const ChartView = () => {
    const { type } = props;
    if (type) {
      var templateType = type.toLowerCase();
      switch (templateType) {
        case "grouppiechart":
          return <Donut {...props} />;
        case "piechart":
          return <Pie {...props} />;
        case "hbarchart":
          return <HorizontalBar {...props} />;
        case "vbarchart":
          return <Bar {...props} />;
        case "barchart":
          return <Bar {...props} />;
        case "linechart":
          return <Line {...props} />;
        case "tabular":
          return <div></div>;
        case "multilinechart":
          return <div></div>;
        case "multicolumnchart":
          return <ClusteredBar {...props} />;
        case "racebarchart":
          return <RaceBar {...props} />;
        case "stackedbarchart":
          return <div></div>;
        case "clusteredbarchart":
          return <StackedClusteredBar {...props} />;
        case "clusteredpiechart":
          return <DrillDownPie {...props} />;
        case "stackedclusteredbarchart":
          return <StackedClusteredBar {...props} />;
        case "sankey":
          return <Sankey {...props} />;
        default:
          return <div></div>;
      }
    }
  };
  useEffect(() => {}, []);
  return (
    <AZBrainChartWrapper>
      <div className="content">
        <ChartView />
      </div>
    </AZBrainChartWrapper>
  );
};
export default AZBrainChart;
