import * as d3 from "d3";
import * as venn from "venn.js";
import React from "react";


var sets = [
  {
    size: 6,
    sets: ["Negative", "Calequence"]
   
  },
  {
    size: 0,
    sets: ["Positive", "Negative"]
  },
  {
    size: 1,
    sets: ["Negative", "Calequence"]
  },

  {
    size: 1,
    sets: ["Positive", "Calequence"]
  },
  {
    size: 2,
    sets: ["Positive", "Calequence"]
  },
  {
    size: 3,
    sets: ["Positive", "Negative"]
  },
  {
    size: 7,
    sets: ["Calequence", "Negative"]
  },
  {
    size: 11,
    sets: ["Positive"]
  },
  {
    size: 14,
    sets: ["Negative"]
  },
  {
    size: 10,
    sets: ["Calequence"]
  }

];
//global variable
var datesPresent = [];

//component
class VennChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartView = React.createRef();
  }
  chart = venn.VennDiagram().width(400).height(450);

  componentDidMount() {
    let div = d3.select(this.chartView);
    div.datum(sets).call(this.chart);

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "venntooltip")
      .style("display", "none");

    //interaction to venn diagram
    //mouse events
    div
      .selectAll("g")
      .on("mouseover", function (d, i) {
        //this.setState({ datesPresent: i.dates })
        datesPresent = i.dates;
        //detect all the areas in the venn diagram
        venn.sortAreas(div, i);
        d3.select(this).attr("opacity", 0.5);
        tooltip
          .transition()
          .duration(400)
          .style("opacity", 1)
          .style("display", "inline-block")
          .text(`${i.size} days of ${i.sets}`);
      })
      .on("mouseout", function (d, i) {
        d3.select(this).attr("opacity", 1);
        tooltip.text(``);
      });
  }

  render() {
    return (
      <div className="venn-div">
      
        <p style={{fontWeight:"bold"}}>Mayo Clinic - Calequence TRx</p>
        <div className="" ref={(el) => (this.chartView = el)}></div>
      </div>
    );
  }
}

export default VennChart;
