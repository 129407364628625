import { useEffect, useState } from "react";
import {
  LegendContainerWrapper,
  SiteCardWrapper,
} from "./ComparisonChart.styles";
import config from "../../config";
import { GetAPIRequest } from "../../services";
import { scaleOrdinal } from "d3-scale";
import { interpolateRainbow } from "d3-scale-chromatic";
import * as d3 from "d3";

window.d3 = d3;

const getPercent = (d) => {
  var percent = 0;
  if (d.data.size) {
    if (d.parent && d.parent.data && d.parent.data.size) {
      percent = d.data.size / d.parent.data.size;
    }
    if (d.parent && d.parent.data && d.parent.data.node_size) {
      percent = d.data.size / d.parent.data.node_size;
    }
    percent = percent * 100;
  }
  if (d.data.node_size) {
    if (d.parent && d.parent.data && d.parent.data.size) {
      percent = d.data.node_size / d.parent.data.size;
    }
    if (d.parent && d.parent.data && d.parent.data.node_size) {
      percent = d.data.node_size / d.parent.data.node_size;
    }
    percent = percent * 100;
  }
  return percent;
};
const GlobalLegend = (props) => {
  console.log("GlobalLegend", GlobalLegend);
  var {
    title,
    count,
    parentCount,
    percent,
    nodeColors,
    dataType = "wheel",
  } = props;
  const onLegendClick = function (e, type) {};
  const onLegendhover = function (e, type) {};
  const onLegendMouseOut = function (e, type) {};

  useEffect(() => {
    console.log("GlobalLegend", GlobalLegend);
  }, []);
  let background = {
    borderLeft: `10px solid ${nodeColors[title]}`,
  };
  return (
    <div
      className="sunburst-legend-title"
      onMouseOver={onLegendhover}
      onMouseOut={onLegendMouseOut}
      data-type={dataType}
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title}</span>
        <span className="sunburst-legend-total-label1">
          <span className="sunburst-legend-total-count">{count}</span>
          <span className="sunburst-legend-total-percent">({percent}%)</span>
        </span>
      </span>
    </div>
  );
};
const ParentLegend = (props) => {
  var {
    title,
    count,
    parentCount,
    percent,
    nodeColors,
    dataType = "wheel",
  } = props;
  const onLegendClick = function (e, type) {};
  const onLegendhover = function (e, type) {};
  const onLegendMouseOut = function (e, type) {
    //removefocus();
  };
  useEffect(() => {}, []);
  let background = {
    borderLeft: `10px solid ${nodeColors[title]}`,
  };
  return (
    <div
      className="sunburst-legend-title"
      onMouseOver={onLegendhover}
      onMouseOut={onLegendMouseOut}
      data-type={dataType}
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title}</span>
        {parentCount > 1 ? (
          <span className="sunburst-legend-total">
            <span className="sunburst-legend-total-label1">
              <span className="sunburst-legend-total-count">{count}</span>
              <span className="sunburst-legend-total-percent">
                ({percent}%)
              </span>
            </span>
          </span>
        ) : (
          <span className="sunburst-legend-total-label1">
            <span className="sunburst-legend-total-count">{count}</span>
            <span className="sunburst-legend-total-percent">({percent}%)</span>
          </span>
        )}
      </span>
    </div>
  );
};
const ChildLegend = (props) => {
  var {
    title,
    count,
    parentCount,
    percent,
    nodeColors,
    totalPercent,
    dataType = "wheel",
  } = props;
  const onLegendClick = function (e, type) {};
  const onLegendhover = function (e, type) {};
  const onLegendMouseOut = function (e, type) {
    //removefocus();
  };
  useEffect(() => {}, []);
  let background = {
    borderLeft: `10px solid ${nodeColors[title]}`,
  };
  let progressBar = {
    width: `${percent}%`,
    backgroundColor: `${nodeColors[title]}`,
  };
  return (
    <div
      className="sunburst-legend-list"
      onMouseOver={onLegendhover}
      onMouseOut={onLegendMouseOut}
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title} </span>
        <span className="sunburst-legend-total">
          <span className="sunburst-legend-total-percent">{percent}%</span>
          <div className="wheel-progress">
            <div style={progressBar} className="wheel-progress-bar"></div>
          </div>
          <span className="sunburst-legend-total-label1">
            <span className="sunburst-legend-total-count">{count}</span>
            <span className="sunburst-legend-total-percent">
              ({totalPercent}%)
            </span>
          </span>
        </span>
      </span>
    </div>
  );
};
const WheelLegend = (props) => {
  const [parentArr, setParentArr] = useState([]);
  const [childArray, setChildArray] = useState([]);
  const [parentPercent, setParentPercent] = useState("");
  const [parentTotal, setParentTotal] = useState("");
  const [parentTitle, setParentTitle] = useState("");

  const findChildren = (node) => {
    var childArray = [];
    if (node.children) {
      node.children.forEach((item, idx) => {
        if (
          !item.data.name.includes("placeholder") &&
          !item.data.name.includes("Undiagnosed")
        ) {
          childArray.push(item);
        }
      });
    }
    return childArray;
  };
  var { d, dataType = "wheel", nodeColors } = props;
  useEffect(() => {
    if (d.data) {
      console.log("wheel1", d);
      d = d.data;

      if (d) {
        let _parentArr = d.ancestors().reverse();
        _parentArr.splice(_parentArr.length - 1);
        setParentArr(_parentArr);
        console.log("WheelLegend", _parentArr);
        let s1 = d.data.size || d.data.node_size;
        let ppercent = 100;
        if (_parentArr.length > 0) {
          let p11 = (s1 / _parentArr[0].data.node_size) * 100;
          if (p11 < 1.0) {
            ppercent = parseFloat(p11).toFixed(2);
          } else {
            ppercent = Math.ceil(p11);
          }
          if (ppercent > 100) {
            ppercent = 100;
          }
        }
        setParentTitle(d.data.name);
        setParentPercent(ppercent);
        setParentTotal(s1);

        let childArray = findChildren(d);
        console.log("parentArr", _parentArr);
        childArray.sort((a, b) => a.data.name.localeCompare(b.data.name));
        console.log("childArray", childArray);
        setChildArray(childArray);
      } else {
      }
    }
  }, [d]);
  return (
    <>
      {parentArr.map((_item, idx) => {
        let item = _item.data;

        let ps1 = item.size || item.node_size;
        let pspercent = 100;
        if (parentArr.length > 0) {
          let p1 = (ps1 / parentArr[0].data.node_size) * 100;
          if (p1 < 1.0) {
            pspercent = parseFloat(p1).toFixed(2);
          } else {
            pspercent = Math.ceil(p1);
          }
          if (pspercent > 100) {
            pspercent = 100;
          }
        }
        console.log("WheelLegend-1", pspercent);
        return (
          <GlobalLegend
            key={idx}
            title={item.name}
            parentCount={parentArr.length}
            percent={pspercent}
            count={ps1}
            nodeColors={nodeColors}
          />
        );
      })}
      {
        <ParentLegend
          title={parentTitle}
          parentCount={parentArr.length}
          percent={parentPercent}
          count={parentTotal}
          nodeColors={nodeColors}
        />
      }
      {childArray.map((_item, idx) => {
        let item = _item.data;
        let s = item.size || item.node_size;
        let percent = (s / d.data.data.node_size) * 100;
        console.log("percent-1", d.data.data.node_size);
        if (percent < 1.0) {
          percent = parseFloat(percent).toFixed(2);
        } else {
          percent = Math.ceil(percent);
        }

        if (parentArr.length > 0) {
          let p12 = (s / parentArr[0].data.node_size) * 100;
          if (p12 < 1.0) {
            percent = parseFloat(p12).toFixed(2);
          } else {
            percent = Math.ceil(p12);
          }
          console.log("percent", parentArr[0].data.node_size);
        }
        console.log("percent", s);
        //console.log("percent-1", percent);
        var pcpercent = getPercent(_item);
        if (pcpercent < 1.0) {
          pcpercent = parseFloat(pcpercent).toFixed(2);
        } else {
          pcpercent = Math.ceil(pcpercent);
        }
        if (pcpercent > 100) {
          pcpercent = 100;
        }
        return (
          <ChildLegend
            key={idx}
            title={item.name}
            parentCount={parentArr.length}
            percent={pcpercent}
            totalPercent={percent}
            count={s}
            nodeColors={nodeColors}
          />
        );
      })}
    </>
  );
};

const ComparisonChart = ({ id, type, onChange, dataType, onLoaded, chartId }) => {
  const [wheelData, setWheelData] = useState([]);
  const [nodeColors, setNodeColors] = useState({});
  const [comment, setComment] = useState("");
  const [showWheel, setShowWheel] = useState(false);
  var _nodeColors = {};
  var wheelChartRoot;
  useEffect(() => {
    loadWheelInfo();
    return () => {
      console.log("wheelChartRoot dispose");
      wheelChartRoot?.dispose();
    };
  }, []);

  const loadWheelInfo = () => {
    const myChart = window.Sunburst();
    var colorArr = d3.quantize(interpolateRainbow, 20);
    console.log("interpolateRainbow", colorArr);
    colorArr.pop();
    var ccolrs = [
      "#6e40aa",
      "#963db3",
      "#bf3caf",
      "#e4419d",
      "#fe4b83",
      "#ff5e63",
      "#ff7847",
      "#fb9633",
      "#e2b72f",
      "#c6d63c",
      "#1F77B4",
      "#2CA02C",
      "#D62728",
      "#ff9da7",
      "#9467BD",
      "#8C564B",
      "#E377C3",
      "#7F7F7F",
      "#17BECF",
      "#57A9E2",
      "#5FD35F",
      "#E77C7C",
      "#BC8B81",
      "#F4CCE8",
      "#E2E362",
      "#103D5D",
      "#A74E15",
      "#165016",
      "#801718",
      "#4A2D27",
      "#CA2A99",
      "#4C4C4C",
      "#666716",
      "#0D6A73",
      "#FCB441",
      "#005CDB",
      "#056492",
      "#219DA2",
      "#75d3eb",
      "#EF476F",
      "#06D6A0",
      "#118AB2",
      "#F79256",
      "#FBD1A2",
      "#7DCFB6",
      "#FDC500",
      "#FFD500",
      "#00509D",
      "#00296B",
      "#D0016F",
      "#FF6B35",
      "#EFEFD0",
      "#F7C59F",
      "#FFB7C3",
      "#0B5502",
      "#36699d",
      "#f18500",
      "#00937a",
      "#933700",
      "#c83131",
      "#8200a8",
      "#224e2a",
      "#1b9e77",
      "#d95f02",
      "#7570b3",
      "#e7298a",
      "#66a61e",
      "#e6ab02",
      "#a6761d",
      "#4e79a7",
      "#e15759",
      "#76b7b2",
      "#af7aa1",
      "#9c755f",
      "#bab0ab",
      "#a6cee3",
      "#b2df8a",
      "#fdbf6f",
      "#cab2d6",
      "#2897bf",
      "#081d58",
      "#037877",
      "#014636",
      "#440154",
      "#482475",
      "#414487",
      "#355f8d",
      "#2a788e",
      "#21918c",
      "#22a884",
      "#44bf70",
      "#bddf26",
    ];

    colorArr = ccolrs.reverse().concat(colorArr).reverse();
    const wheelcolor1 = scaleOrdinal(colorArr);

    const getColorsCodes1 = (title) => {
      return wheelcolor1(title);
    };

    function parseWheelData(siteNetworkRoot) {
      var mRoot = {};
      if (siteNetworkRoot.children) {
        mRoot.name = siteNetworkRoot.name;
        mRoot.id = siteNetworkRoot.id;
        if (siteNetworkRoot.comment) {
          mRoot.comment = siteNetworkRoot.comment;
        } else {
          mRoot.comment = "";
        }
        mRoot.node_size = siteNetworkRoot.size;
        mRoot.nuuid = siteNetworkRoot.nuuid;
        mRoot.children = [];
        mRoot.color = getColorsCodes1(
          siteNetworkRoot.name + "-" + siteNetworkRoot.nuuid
        );
        mRoot = parseWheelChildNode(
          siteNetworkRoot.children,
          mRoot,
          siteNetworkRoot
        );
      }
      return mRoot;
    }

    function parseWheelChildNode(siteNetworkRoot, mRoot, rootParent) {
      var tNode = mRoot;
      var dSize = 0;
      siteNetworkRoot.map((data, ridx) => {
        var d = {};
        d.name = data.name;
        d.id = data.id;
        if (data.comment) {
          d.comment = data.comment;
        } else {
          d.comment = "";
        }
        d.nuuid = data.nuuid;
        d.color = getColorsCodes1(data.name + "-" + data.nuuid);
        d.children = [];
        var rootNode = {};
        if (data.children && data.children.length > 0) {
          d.node_size = data.size;
          rootNode = parseWheelChildNode(data.children, d, data);
        } else {
          d.size = data.size;
          rootNode = d;
        }
        dSize += data.size;

        tNode.children.push(rootNode);
        if (ridx == siteNetworkRoot.length - 1) {
          var _size = 1;
          if (dSize < rootParent.size) {
            _size = rootParent.size - dSize;
          }
          tNode.children.push({
            id: "placeholder",
            name: "placeholder",
            size: _size,
          });
        }
      });
      return tNode;
    }
    /* var url = CONSTANTS.api.getWheelData;
    url += `?type=parent`;
    url += `&id=MAYO CLINIC`;
    url += `&year=2021,2020`;*/
    console.log("url", url);
    var url = `${
      config.api.accountsWheelSummary
    }?id=${id.toUpperCase()}&type=${type}`;
    GetAPIRequest({
      url: url,
    })
      .then((res) => {
        if (res && res.id) {
          setShowWheel(true);
          console.log("res", res);
          loadWheelData(res);
        } else {
          setShowWheel(false);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setShowWheel(false);
      });
    //}

    //Load Wheel Data
    function loadWheelData(siteNetworkRoot) {
      var node = document.getElementById(`${chartId}-container`);
      while (node.hasChildNodes()) {
        node.removeChild(node.firstChild);
      }
      let mRoot = parseWheelData(siteNetworkRoot);
      var resObj = {};
      var nodesnames = [];
      var links = [];
      var nodes = [];
      var nodesList = [];
      var nodeColor = {};
      var linkId = "";
      var k = parseWheelData1(siteNetworkRoot);
      resObj.nodes = [];
      resObj.nodes = nodesnames;
      resObj.links = [];
      resObj.links = links;

      nodesList.forEach((nl, i) => {
        nodeColor[nl] = colorArr[i];
      });

      function parseWheelData1(siteNetworkRoot) {
        var depth = 0;
        var mRoot = {};
        if (siteNetworkRoot.children) {
          mRoot.name = siteNetworkRoot.name;
          nodesList.push(siteNetworkRoot.name);
          if (nodes.indexOf(siteNetworkRoot.name) < 0) {
            nodes.push(siteNetworkRoot.name + "-" + depth);
            nodesnames.push({
              name: siteNetworkRoot.name + "-" + depth,
              nodeId: siteNetworkRoot.name + "-" + depth,
              depth: depth,
            });
          }
          mRoot.id = siteNetworkRoot.id;
          mRoot.node_size = siteNetworkRoot.size;
          mRoot.children = [];
          mRoot = parseWheelChildNode1(
            siteNetworkRoot.children,
            mRoot,
            siteNetworkRoot,
            depth
          );
        }

        return mRoot;
      }

      function parseWheelChildNode1(siteNetworkRoot, mRoot, rootParent, depth) {
        var tNode = mRoot;
        var dSize = 0;
        var currdepth = depth + 1;

        siteNetworkRoot.map((data, ridx) => {
          if (currdepth == 1) {
            linkId = data.name;
          }
          var d = {};
          d.name = data.name;
          var name = data.name + "-" + currdepth;
          var rootname = rootParent.name + "-" + depth;
          if (nodesList.indexOf(data.name) < 0) {
            nodesList.push(data.name);
          }
          if (nodes.indexOf(name) < 0) {
            nodes.push(name);
            nodesnames.push({
              name: name,
              nodeId: name,
              depth: currdepth,
            });
          }
          let filter = links.filter(
            (d) =>
              (d.target == rootname) & (d.source == name) ||
              (d.source == rootname) & (d.target == name)
          );
          if (filter.length <= 0) {
            if (name !== rootname) {
              links.push({
                source: rootname,
                target: name,
                value: data.size,
                id: linkId,
                targetDepth: currdepth,
                sourceDepth: depth,
              });
            }
          }

          d.id = data.id;
          d.children = [];
          var rootNode = {};

          if (data.children && data.children.length > 0) {
            d.node_size = data.size;
            rootNode = parseWheelChildNode1(data.children, d, data, currdepth);
          } else {
            d.size = data.size;
            rootNode = d;
          }
          dSize += data.size;

          tNode.children.push(rootNode);
          if (ridx == siteNetworkRoot.length - 1) {
            var _size = 1;
            if (dSize < rootParent.size) {
              _size = rootParent.size - dSize;
            }
          }
        });
        return tNode;
      }

      const partition = (data) => {
        const siteNetworkRoot = d3
          .hierarchy(data)
          .sum((d) => d.size)
          .sort((a, b) => b.size - a.size);
        return d3.partition().size([2 * Math.PI, siteNetworkRoot.height])(
          siteNetworkRoot
        );
      };

      const proot = partition(mRoot);
      proot.each((d) => (d.current = d));
      if (mRoot && mRoot.comment) {
        setComment(mRoot.comment);
      } else {
        setComment("");
      }
      let _root = d3.hierarchy(mRoot);
      _root.sum((d) => d.size);

      myChart
        .chartId(chartId)
        .data(mRoot)
        .size("size")
        .width(600)
        .height(600)
        .label("name")
        .size("size")
        .centerRadius(0.05)
        .minSliceAngle(0.1)
        .maxLevels(3)
        .sort((a, b) => b.size - a.size) // sort descending by size
        .color((d) => {
          if (
            d.name.includes("placeholder") ||
            d.name.includes("Undiagnosed")
          ) {
            return "#ffffff";
          } else {
            var nc = "";
            if (_nodeColors) {
              if (_nodeColors[d.name]) {
                nc = _nodeColors[d.name];
              } else {
                nc = nodeColor[d.name];
                _nodeColors[d.name] = nc;
              }
            } else {
              nc = nodeColor[d.name];
              _nodeColors = {};
              _nodeColors[d.name] = nc;
            }
            return nc;
          }
        })
        .tooltipContent((d, node) => `Size: <i>${d.size || d.node_size}</i>`)
        .radiusScaleExponent(1.4)(document.getElementById(`${chartId}-container`));

      var clicked = (d) => {
        console.log("clicked", d);
        if (d) {
          focusOn(d);
          myChart.focusOnNode(d.data);
          onChange(d.data.id);
          var k = partition(proot).descendants();
          var d1 = k.filter((_item, indx) => {
            var item = _item.data;

            if (item && item.data && item.data.nuuid) {
              return item.data.nuuid === d.data.nuuid;
            }
          });
          if (d1.length > 0) {
            setTimeout(() => {
              updateSunburstLegend({
                d: d1[0],
              });
            }, 1000);
          }
        }
      };
      myChart.onClick(clicked);

      var addfocus = (d) => {
        console.log(d);
        var sequenceArray = d.ancestors().map((d) => d.data.nuuid);
        var sequenceChildArray = [];
        if (d.parent) {
          sequenceArray = d.ancestors().map((d) => d.data.nuuid);
        }
        if (d.children) {
          sequenceChildArray = d.children.map((d) => d.data.nuuid);
        }

        d3.select(`.sunburst-viz svg`).selectAll("path").style("opacity", 0.1);

        d3.select(`.sunburst-viz svg`)
          .selectAll("path")
          .filter(function (node) {
            return (
              sequenceArray.indexOf(node.data.nuuid) > -1 ||
              sequenceChildArray.indexOf(node.data.nuuid) > -1 ||
              node.data.nuuid == d.data.nuuid
            );
          })
          .style("opacity", 1);
      };

      var removefocus = (d) => {
        d3.select(`.sunburst-viz svg`).selectAll("path").style("opacity", 1);
      };

      var updateCenter = (_p, _root) => {
        var p = {};
        if (_p && _p.data) {
          p = _p.data;
        } else {
          p = _p;
        }
        myChart.focusOnNode(p.data);
      };

      var focusOn = (d = { x0: 0, x1: 1, y0: 0, y1: 1 }) => {
        if (d) {
        }
        if (d && d.data && d.data.comment) {
          setComment(d.data.comment);
        } else {
          setComment("");
        }
      };

      function updateSunburstLegend(config) {
        console.log("wheel", config);
        setWheelData(config.d);
      }
      setNodeColors(_nodeColors);
      onChange(mRoot.id);
      onLoaded(siteNetworkRoot);
      updateSunburstLegend({
        d: partition(proot).descendants()[0],
      });
    }
  };

  return (
    <SiteCardWrapper>
      <div className="chartContainer">
        <div id={`${chartId}-container`}></div>
      </div>
      <LegendContainerWrapper id="legendContainer">
        <WheelLegend d={wheelData} nodeColors={nodeColors} />
      </LegendContainerWrapper>
      <div className="commentContainer">{comment}</div>
    </SiteCardWrapper>
  );
};

export default ComparisonChart;
