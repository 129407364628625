import styled from "styled-components";

export const WordCloudWrapper = styled.div`
  display: flex;
  min-height: 300px;
  .searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 10px 30px;
  }
  .selContainer{
      width: 100%;

  }
`;
