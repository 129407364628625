import { Filter } from "@amcharts/amcharts4/core";
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  TableSelection,
} from "@devexpress/dx-react-grid-bootstrap4";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import saveAs from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import config from "../../config";
import { Radio, Modal, Tabs } from "antd";
import { GetAPIRequest } from "../../services";
import {
  PhysicianListWrapper,
  FilterTabWrapper,
  TableWrapper,
} from "./PhysicianList.styles";
import PhyBarChart from "../PhyBarChart/PhyBarChart";
import PhyRaceBarChart from "../PhyRaceBarChart/PhyRaceBarChart";
import TrendingChart from "../TrendingChart";
const {TabPane} = Tabs;
const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "DataGrid.xlsx"
    );
  });
};

const AlignCenterFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
    }}
  >
    {value}
  </span>
);
const CenterTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignCenterFormatter} {...props} />
);
const AlignLeftFormatter = ({ value }) => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    }}
  >
    {value}
  </span>
);
const LeftTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AlignLeftFormatter} {...props} />
);

const PhysicianList = (props) => {
  const [columns, setColumns] = useState([]);
  const [columnsExt, setColumnsExt] = useState([]);
  const [rows, setRows] = useState([]);
  const [centerColumns, setCenterColumns] = useState([]);
  const [unmetneedsCat, setUnmetneedsCat] = useState([]);
  const [unmetneedFilterIdx, setUnmetneedFilterIdx] = useState(0);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [defaultHiddenColumnNames, setdefaultHiddenColumnNames] = useState(["Prescriber Id", "NPI"]);
  const [unmentNeedsKeys, setUnmentNeedsKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selection, setSelection] = useState([]);
  const [selPhy, setSelPhy] = useState("");
  /* useEffect(() => {
    if(selection != null){
      //setVisible(true);
    }
    
  }, [selection]);*/
  const getData = () => {
    GetAPIRequest({
      url: props.url,
    })
      .then((physicians) => {
        var unmetneedsCatArray = [];
        var unmetneedsTitleArray = [];
        var unmetneedsKeyArray = [];
        var unmetneedsKeysObj = {};
        var tableDataArr = [];
        physicians.forEach((phy) => {
          var tableDataObj = {};

          tableDataObj["Name"] = phy.fullName.toUpperCase();
          tableDataObj["Count"] = phy.count;
          /*tableDataObj["City"] = phy.city;
        tableDataObj["State"] = phy.state;
        tableDataObj["Street"] = phy.street;
        tableDataObj["Phone"] = phy.phone;
        tableDataObj["ZipCode"] = phy.zipcode;*/
          //tableDataObj["Full Name"] = phy.fullName;
          tableDataObj["Prescriber Id"] = phy.prescriberId;
          tableDataObj["NPI"] = phy.npi;
          //tableDataObj["Specialties"] = phy.specialties && Array.isArray(phy.specialties) && phy.specialties.join(", ");
          if (phy.unmetneeds) {
            var unmetneeds = phy.unmetneeds;
            unmetneeds.forEach((unmetneed) => {
              var _category = unmetneed.category;
              if (unmetneedsCatArray.indexOf(_category) <= -1) {
                unmetneedsCatArray.push(_category);
              }
              if (unmetneedsTitleArray.indexOf(unmetneed.title) <= -1) {
                unmetneedsTitleArray.push(unmetneed.title);
              }
              var keys = Object.keys(unmetneed);
              tableDataObj[unmetneed.title.trim()] = unmetneed[keys[0]];
              if (unmetneedsKeyArray.indexOf(unmetneed.title) <= -1) {
                unmetneedsKeyArray.push(unmetneed.title);
              }
              var keys1 = Object.keys(unmetneedsKeysObj);
              if (keys1.length > 0 && keys1.indexOf(_category) > -1) {
                if (
                  unmetneedsKeysObj[_category].indexOf(unmetneed.title) <= -1
                ) {
                  unmetneedsKeysObj[_category].push(unmetneed.title);
                }
              } else {
                unmetneedsKeysObj[_category] = [unmetneed.title];
              }
            });
            tableDataArr.push(tableDataObj);
          }
        });
        var tblcolumns = ["Name", "Count", ...unmetneedsTitleArray];
        console.log("unmetneedsCatArray", unmetneedsCatArray);
        console.log("unmetneedsTitleArray", unmetneedsTitleArray);
        console.log("unmetneedsKeyArray", unmetneedsKeyArray);
        console.log("unmetneedsKeysObj", unmetneedsKeysObj);
        console.log("unmetneedsKeysObj", unmetneedsKeysObj);
        console.log("tableDataArr", tableDataArr);
        console.log("columns", columns);
        setUnmetneedsCat(unmetneedsCatArray);

        var _tblcolumns = [];
        var _tblcolumnsExt = [];
        tblcolumns.map((c) => {
          if (c == "Name") {
            _tblcolumns.push({
              name: c,
              title: c,
              getCellValue: (row) => {
                if (row.specialties && Array.isArray(row.specialties)) {
                  var sp = row.specialties.join(", ");
                  sp = "<br />Specialities: " + sp;
                }
                return (
                  <div>
                    {row[c]}
                    {sp}
                  </div>
                );
              },
            });
            _tblcolumnsExt.push({
              columnName: c,
              align: "left",
              width: 200,
              backgroundColor: "#000000",
            });
          } else {
            _tblcolumns.push({
              name: c,
              title: c,
              getCellValue: (row) => row[c],
            });
            _tblcolumnsExt.push({
              columnName: c,
              align: "center",
            });
          }
        });
        setCenterColumns(columns);
        setColumns(_tblcolumns);
        setColumnsExt(_tblcolumnsExt);
        setUnmentNeedsKeys(unmetneedsKeysObj);
        setRows(tableDataArr);
        var hiddenColumns = [];
        unmetneedsCatArray.map((n, idx) => {
          if (idx != 0) {
            var k = unmetneedsKeysObj[n];
            hiddenColumns.push(...k);
          }
        });
        setHiddenColumnNames(hiddenColumns);
        console.log("hidden-columns", hiddenColumns);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  /*const [defaultHiddenColumnNames] = useState([]);
  const getHiddenColumnsFilteringExtensions = (hiddenColumnNames) =>
    hiddenColumnNames.map((columnName) => ({
      columnName,
      predicate: () => false,
    }));
  const [filteringColumnExtensions, setFilteringColumnExtensions] = useState(
    getHiddenColumnsFilteringExtensions(defaultHiddenColumnNames)
  );

  const onHiddenColumnNamesChange = (hiddenColumnNames) =>
    setFilteringColumnExtensions(
      getHiddenColumnsFilteringExtensions(hiddenColumnNames)
    );*/
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onUnmetNeedChange = (e) => {
    setUnmetneedFilterIdx(e.target.value);
    var hiddenColumns = [];
    unmetneedsCat.map((n, idx) => {
      if (idx != e.target.value) {
        var k = unmentNeedsKeys[n];
        hiddenColumns.push(...k);
      }
    });
    console.log("hidden-columns-1", hiddenColumns);
    setHiddenColumnNames(hiddenColumns);
  };
  return (
    <PhysicianListWrapper>
      <FilterTabWrapper>
        <Radio.Group
          buttonStyle="solid"
          value={unmetneedFilterIdx}
          onChange={onUnmetNeedChange}
        >
          {unmetneedsCat.map((val, idx) => (
            <Radio.Button key={idx} value={idx}>{val}</Radio.Button>
          ))}
        </Radio.Group>
      </FilterTabWrapper>
      <TableWrapper id="physician-table">
        <Grid rows={rows} columns={columns}>
          <DragDropProvider />
          <SortingState />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={(val) => {
              setSelection(val);
              if (val.length > 0) {
                setVisible(true);
                console.log("setSelPhy", rows[val[0]]);
                setSelPhy(rows[val[0]]);                
              }
            }}
          />
          <SearchState defaultValue="" />
          <CenterTypeProvider for={centerColumns} />
          <LeftTypeProvider for={["Name"]} />
          <Table columnExtensions={columnsExt} />
          <TableColumnReordering defaultOrder={columns} />
          <TableHeaderRow showSortingControls />
          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={false}
          />
          <TableColumnVisibility messages={{ noColumns: "Loading..." }} defaultHiddenColumnNames={defaultHiddenColumnNames} hiddenColumnNames={hiddenColumnNames} />
          {/*<Toolbar />
        <SearchPanel />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <ColumnChooser />
        <ExportPanel startExport={startExport} />
        */}
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </TableWrapper>
      <Modal
        className="phyJourneyTab"
        title={selPhy && selPhy["Name"]}
        centered
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setSelection([]);
        }}
        width={window.innerWidth}
        height={window.innerHeight}
        footer={null}
      >
        <div
          style={{
            flex: 1,
            width: window.innerWidth,
            height: window.innerHeight-20,
            overflow: 'hidden',
            overflowY: 'scroll'
          }}
        >
          <Tabs  defaultActiveKey="1">
            <TabPane tab="Bar Chart" key="1">
              <PhyBarChart selPhy={selPhy}/>
            </TabPane>
            <TabPane tab="Race Bar Chart" key="2">
              <PhyRaceBarChart selPhy={selPhy} />
            </TabPane>
            <TabPane tab="Trending" key="3">
              <TrendingChart selPhy={selPhy}/>
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </PhysicianListWrapper>
  );
};

PhysicianList.propTypes = {
  // bla: PropTypes.string,
};

PhysicianList.defaultProps = {
  // bla: 'test',
};

export default PhysicianList;
