import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils/cookie-helper";
import { getUserName } from "../../utils/utils";
import {
  User,
  UserInfoDesignation,
  UserInfoName,
  UserInfoWrapper,
} from "./UserInfo.styles";

const UserInfo = (props) => {
  const [username, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const { showName = false, ...restprops } = props;
  useEffect(() => {
    const user = getCookie("userName");
    const jobTitle = getCookie("jobTitle");
    if (user) {
      setUserName(user);
      setDesignation(jobTitle);
    }
  }, []);
  return (
    <UserInfoWrapper>
      {showName && (
        <User>
          <UserInfoName>{username}</UserInfoName>
          <UserInfoDesignation>{designation}</UserInfoDesignation>
        </User>
      )}

      <Avatar className="userInfoIcon" size="large">
        {getUserName()}
      </Avatar>
    </UserInfoWrapper>
  );
};

export default UserInfo;
