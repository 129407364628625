import styled from "styled-components";

export const TrendingTableWrapper = styled.div`
  .phyJourneyTab {
    .ant-tabs-tab-active {
      border-bottom: 3px solid #000000 !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000000;
    }
    .ant-tabs-tab-btn {
      color: #666666;
    }
  }
  .trending-reporting-table {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
`;
export const FilterTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 20px 20px;
  .maintabContainer{
      margin: 20px;
  }
  .subtabContainer{
      margin: 10px;
  }
`;
export const TableWrapper = styled.div`
  margin: 10px 10px;
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  .datepicker-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    margin-bottom: -45px;
  }
  .rmdp-container {
    z-index: 10000 !important;
  }
`;
