import * as am5 from "@amcharts/amcharts5";
export default [
  { name: "Milk", value: 10, sliceSettings: { fill: am5.color(0xcecbc6) } },
  {
    name: "Milk foam",
    value: 10,
    sliceSettings: { fill: am5.color(0xd1d0ce) },
  },
  {
    name: "Espresso",
    value: 20,
    sliceSettings: { fill: am5.color(0x441702) },
    labelSettings: { fill: am5.color(0xffffff) },
  },
  { name: "Water", value: 8, sliceSettings: { fill: am5.color(0xbbcdd7) } },
  {
    name: "",
    value: 2,
    sets: ["Milk", "Milk foam"],
    sliceSettings: { fill: am5.color(0xd2d2d2), tooltipText: "" },
  },
  {
    name: "Flat white",
    value: 4,
    sets: ["Milk", "Espresso"],
    sliceSettings: { fill: am5.color(0xa18b80) },
  },
  {
    name: "Macchiato",
    value: 4,
    sets: ["Milk foam", "Espresso"],
    sliceSettings: { fill: am5.color(0xaba09c) },
  },
  {
    name: "Cappucino\nLatte",
    value: 2,
    sets: ["Milk", "Milk foam", "Espresso"],
    sliceSettings: { fill: am5.color(0xd1d0ce) },
  },
  {
    name: "Americano",
    value: 4,
    sets: ["Water", "Espresso"],
    sliceSettings: { fill: am5.color(0xa2a19f) },
  },
];
