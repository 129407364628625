import React from "react";
import PropTypes from "prop-types";
import { RaceBarWrapper } from "./RaceBar.styles";
import { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as d3 from "d3";

const RaceBar = (props) => {
  const { data, segtabid } = props;
  const loadData = () => {
    if (data) {
      // set the dimensions and margins of the graph
      var margin = { top: 20, right: 50, bottom: 30, left: 100 },
        width = window.innerWidth - 300 - margin.left - margin.right,
        height = 700 - margin.top - margin.bottom;
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end
      d3
        .select(`#nav-${segtabid}`)
        .append("div")
        .attr("id", `nav-${segtabid}-race-chart`)
        .style("width", width + margin.left + margin.right)
        .style("height", "600px");

      var chart = am4core.create(
        `nav-${segtabid}-race-chart`,
        am4charts.XYChart
      );
      chart.padding(40, 40, 40, 40);

      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ];

      var label = chart.plotContainer.createChild(am4core.Label);
      label.x = am4core.percent(97);
      label.y = am4core.percent(95);
      label.horizontalCenter = "right";
      label.verticalCenter = "middle";
      label.dx = -15;
      label.fontSize = 50;

      var playButton = chart.plotContainer.createChild(am4core.PlayButton);
      playButton.x = am4core.percent(97);
      playButton.y = am4core.percent(95);
      playButton.dy = -2;
      playButton.verticalCenter = "middle";
      playButton.events.on("toggled", function (event) {
        if (event.target.isActive) {
          let keys = Object.keys(allData);
          if (year >= keys.length - 1) {
            year = 0;
          }
          play();
        } else {
          stop();
        }
      });

      var stepDuration = 500;

      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "network";
      categoryAxis.renderer.minGridDistance = 1;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.grid.template.disabled = true;

      var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.rangeChangeEasing = am4core.ease.linear;
      valueAxis.rangeChangeDuration = stepDuration;
      valueAxis.extraMax = 0.1;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "network";
      series.dataFields.valueX = "MAU";
      series.tooltipText = "{valueX.value}";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusBottomRight = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;
      series.interpolationDuration = stepDuration;
      series.interpolationEasing = am4core.ease.linear;

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.horizontalCenter = "right";
      labelBullet.label.text =
        "{values.valueX.workingValue.formatNumber('#.0as')}";
      labelBullet.label.textAlign = "end";
      labelBullet.label.dx = -10;

      chart.zoomOutButton.disabled = true;

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      series.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      var year = 0;
      label.text = `2016 First Quarter`;

      var interval;

      function play() {
        interval = setInterval(function () {
          nextYear();
        }, stepDuration);
        nextYear();
      }

      function stop() {
        if (interval) {
          clearInterval(interval);
        }
      }

      // const quarters = ['First Quarter', 'Second Quarter', 'Third Quarter', 'Fourth Quarter'];
      function nextYear() {
        year++;
        let keys = Object.keys(allData);
        if (year >= keys.length - 1) {
          stop();
          playButton.isActive = false;
          //year = 0;
        }
        var newData = allData[keys[year]];
        var itemsWithNonZero = 0;
        for (var i = 0; i < chart.data.length; i++) {
          chart.data[i].MAU = newData[i].MAU;
          if (chart.data[i].MAU > 0) {
            itemsWithNonZero++;
          }
        }

        if (year == 0) {
          series.interpolationDuration = stepDuration / 4;
          valueAxis.rangeChangeDuration = stepDuration / 4;
        } else {
          series.interpolationDuration = stepDuration;
          valueAxis.rangeChangeDuration = stepDuration;
        }

        chart.invalidateRawData();
        label.text = keys[year];

        categoryAxis.zoom({
          start: 0,
          end: itemsWithNonZero / categoryAxis.dataItems.length,
        });
      }

      categoryAxis.sortBySeries = series;

      var allData = data;

      let keys = Object.keys(allData);
      chart.data = JSON.parse(JSON.stringify(allData[keys[year]]));
      categoryAxis.zoom({ start: 0, end: 1 / chart.data.length });

      series.events.on("inited", function () {
        setTimeout(function () {
          playButton.isActive = true; // this starts interval
        }, 2000);
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <RaceBarWrapper id={`nav-${segtabid}`}></RaceBarWrapper>;
};

RaceBar.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

RaceBar.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default RaceBar;
