import styled from "styled-components";

export const TrendingHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  .filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .leftFilterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 20px;
    .title {
        margin-right: 10px;
        font-weight: 600;
        color: #000000;
        font-size: 14px;
    }
  }
  .rightFilterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px;
  }
  .rightFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 10px;
    .title {
        margin-right: 10px;
        font-weight: 600;
        color: #000000;
        font-size: 14px;
    }
  }
  .trending-home .filterContainer{
    width:100%;
    .title {text-align:left; display:block;}
    .calendar-picker{ text-align:left}
  }
  .datarange-chart-dtl {width:80%;
  margin:20px auto ; margin-top:30px !important}
  .datarange-chart-dtl td:first-child{ text-align:left}
  .trending-home {width:100%; padding:0px 10px; margin:20px 0px;}
  .trending-home  .selContainer {min-width:200px; text-align:left}
  .home-chart {margin-top:40px}
  .trending-home  .rightFilterContainer {margin-right:0px; margin-right: 0px;
    margin-top: 0px;
    padding-top: 25px;
    margin-bottom: 0px;}
  .average-details table td:nth-child(2) a {text-align:left; cursor:pointer; color:#2680EB}
  .average-details table td:nth-child(2) {text-align:left; padding-left:10px }
`;
export const CardLayout = styled.div`
  box-shadow: 3px 5px 5px 5px #00000011;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -10px;
  }
`;
