export default [
  {
    date: "2012-01-01",
    distance: 227,
    townName: "New York",
    townSize: 12,
    latitude: 40.71,
    duration: 408,
  },
  {
    date: "2012-01-02",
    distance: 371,
    townName: "Washington",
    townSize: 7,
    latitude: 38.89,
    duration: 482,
  },
  {
    date: "2012-01-03",
    distance: 433,
    townName: "Wilmington",
    townSize: 3,
    latitude: 34.22,
    duration: 562,
  },
  {
    date: "2012-01-04",
    distance: 345,
    townName: "Jacksonville",
    townSize: 3.5,
    latitude: 30.35,
    duration: 379,
  },
  {
    date: "2012-01-05",
    distance: 480,
    townName: "Miami",
    townSize: 5,
    latitude: 25.83,
    duration: 501,
  },
  {
    date: "2012-01-06",
    distance: 386,
    townName: "Tallahassee",
    townSize: 3.5,
    latitude: 30.46,
    duration: 443,
  },
  {
    date: "2012-01-07",
    distance: 348,
    townName: "New Orleans",
    townSize: 5,
    latitude: 29.94,
    duration: 405,
  },
  {
    date: "2012-01-08",
    distance: 238,
    townName: "Houston",
    townSize: 8,
    latitude: 29.76,
    duration: 309,
  },
  {
    date: "2012-01-09",
    distance: 218,
    townName: "Dalas",
    townSize: 8,
    latitude: 32.8,
    duration: 287,
  },
  {
    date: "2012-01-10",
    distance: 349,
    townName: "Oklahoma City",
    townSize: 5,
    latitude: 35.49,
    duration: 485,
  },
  {
    date: "2012-01-11",
    distance: 603,
    townName: "Kansas City",
    townSize: 5,
    latitude: 39.1,
    duration: 890,
  },
  {
    date: "2012-01-12",
    distance: 534,
    townName: "Denver",
    townSize: 9,
    latitude: 39.74,
    duration: 810,
  },
  {
    date: "2012-01-13",
    townName: "Salt Lake City",
    townSize: 6,
    distance: 425,
    duration: 670,
    latitude: 40.75,
    dashLength: 8,
    alpha: 0.4,
  },
  {
    date: "2012-01-14",
    latitude: 36.1,
    duration: 470,
    townName: "Las Vegas",
  },
  {
    date: "2012-01-15",
  },
  {
    date: "2012-01-16",
  },
  {
    date: "2012-01-17",
  },
];
