import React from 'react';
import PropTypes from 'prop-types';
import { ParentAccountReportingWrapper } from './ParentAccountReporting.styles';
import CllReportingTable from '../../../components/CllReportingTable';

const ParentAccountReporting = (props) => (
  <ParentAccountReportingWrapper>
    <CllReportingTable dataType="top_parent"/>
  </ParentAccountReportingWrapper>
);

ParentAccountReporting.propTypes = {
  // bla: PropTypes.string,
};

ParentAccountReporting.defaultProps = {
  // bla: 'test',
};

export default ParentAccountReporting;
