import React from "react";
import PropTypes from "prop-types";
import { DonutWrapper } from "./Donut.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as d3 from "d3";
const Donut = (props) => {
  const { data, segtabid } = props;
  var root = null;
  const loadData = () => {
    if (data && data.length > 0) {
      var totalCount = 0;
      data.forEach(function (d) {
        totalCount += d.count;
      });

      var pieData = [];
      data.forEach(function (d) {
        var percent = ((d.count / totalCount) * 100).toFixed(2);
        pieData.push({
          name: d.name,
          count: d.count,
          percent: percent < 2 ? 2 : percent,
        });
      });
      var margin = { top: 20, right: 300, bottom: 150, left: 50 },
      width = window.innerWidth - 300 - margin.left - margin.right,
      height = 400;
      // Themes end
      d3.select(`#nav-${segtabid}`)
        .style("width", width + margin.left + margin.right)
        .style("height", data.length > 10 ? "650px" : "500px");

        if (root) {
          root.dispose();
        }
      root = am5.Root.new(`nav-${segtabid}`);

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
        })
      );

      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          alignLabels: true,
          calculateAggregates: true,
          valueField: "count",
          categoryField: "name",
          legendLabelText:
            "{category} ([bold]{valuePercentTotal.formatNumber('0.00')}%[/])",
          legendValueText: "",
          innerRadius: 100,
        })
      );

      series.slices.template.setAll({
        strokeWidth: 3,
        stroke: am5.color(0xffffff),
      });

      series.labelsContainer.set("paddingTop", 30);

      series.slices.template.adapters.add("radius", function (radius, target) {
        var dataItem = target.dataItem;
        var high = series.getPrivate("valueHigh");

        if (dataItem) {
          var value = target.dataItem.get("valueWorking", 0);
          //return (radius * value) / high;
        }
        return radius;
      });
      series.labels.template.setAll({
        fontSize: 14,
        fill: am5.color(0x000000),
        text: "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
      });

      series.data.setAll(pieData);

      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 15,
          marginBottom: 15,
        })
      );

      legend.data.setAll(series.dataItems);

      series.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <DonutWrapper id={`nav-${segtabid}`}></DonutWrapper>;
};

Donut.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

Donut.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default Donut;
