import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  HealthCareDisparityMapWrapper,
  HealthCareDisparityMapContainer,
  HDStatsViewWrapper,
} from "./HealthCareDisparityMap.styles";
import * as topojson from "topojson-client";
import { isUndefined } from "../../utils/utils";
import { Sticky, StickyContainer } from "react-sticky";
import hcdData from "./hcdData";
import counties from "./counties";
import stateInfo from "./stateInfo";
import * as d3 from "d3";
import config from "../../config";
import { nest } from "d3-collection";
import { geoPath, geoAlbersUsa } from "d3-geo";
import { scaleThreshold, scaleLinear } from "d3-scale";
import { interpolateLab } from "d3-interpolate";
import { GetAPIRequest } from "../../services";
import { Segmented, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;
const HealthCareDisparityMap = (props) => {
  const [countyInfoByState, setCountyInfoByState] = useState(null);
  const [hdData, setHdData] = useState([]);
  const [countyObj, setCountyObj] = useState({});
  const [hdObj, setHdObj] = useState({});
  const [currentClusterLevel, setCurrentClusterLevel] = useState("");
  const [currentFip, setCurrentFip] = useState("");
  const [disparityCountyInfo, setDisparityCountyInfo] = useState([]);
  const [statsTitle, setStatsTitle] = useState("");
  const [tabActiveKey, setTabActiveKey] = useState("County Info");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const getData = () => {
    GetAPIRequest({
      url: `${config.api.getHealthCareDisparity}`,
    })
      .then((response) => {
        setHdData(response);
        loadData(counties, response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDisparityCountyInfo = (state) => {
    GetAPIRequest({
      url: `${config.api.getDisparityCountyInfo}?state=${state}`,
    })
      .then((result) => {
        setDisparityCountyInfo(result);
        console.log("countyPromise", result);
        var countyInfoObj = {};
        var _countyInfoByState = countyInfoByState;
        if (_countyInfoByState == null) {
          countyInfoObj[state] = result;
          _countyInfoByState = countyInfoObj;
        } else {
          countyInfoObj = _countyInfoByState;
          if (countyInfoObj[state]) {
          } else {
            countyInfoObj[state] = result;
            _countyInfoByState = countyInfoObj;
          }
        }
        console.log("countyInfoByState", _countyInfoByState);
        setCountyInfoByState(_countyInfoByState);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getColorByClusterlevel = (clusterLevel) => {
    switch (clusterLevel) {
      case -1:
        return "#EAE8EA";
      case 0:
        return "#7492B6";
      case 1:
        return "#F8BD80";
      case 2:
        return "#9ED38F";
      case 3:
        return "#ECCE7A";
      case 4:
        return "#82B6B3";
      case 5:
        return "#F1918F";
      default:
        return "#EAE8EA";
    }
  };

  const loadData = (us, _resdata) => {
    var data = _resdata.data;
    var hd = _resdata.hd;

    d3.select("#disparitiesSvg").html("");
    d3.select("disparity-map-tooltip").remove();
    var color_domain = [-1, 0, 1, 2, 3, 4, 5];
    var colorScale = scaleLinear()
      .range(["#D4EEFF", "#0099FF"])
      .interpolate(interpolateLab);

    var pairRateWithId = {};
    var pairNameWithId = {};
    var clusterArray = [];

    var margin = {
      top: 20,
      bottom: 150,
      left: 10,
      right: 10,
    };
    var width =
      parseInt(d3.select("#disparitiesSvg").style("width")) -
      margin.left -
      margin.right;
    var mapRatio = 0.5;
    var height = width * mapRatio;
    var active = d3.select(null);
    data.forEach(function (d) {
      pairRateWithId[d.locationid] = +d.clusterlabel;
      if (clusterArray.indexOf(d.clusterlabel) == -1) {
        clusterArray.push(d.clusterlabel);
      }
      pairNameWithId[d.locationid] = d.county;
    });
    var svg = d3
      .select("#disparitiesSvg")
      .append("svg")
      .attr("id", "disparitiesMap")
      .attr("class", "center-container")
      .attr("height", height + margin.top + margin.bottom)
      .attr("width", width + margin.left + margin.right);

    svg
      .append("rect")
      .attr("class", "background center-container")
      .style("fill", "#FFFFFF")
      .attr("height", height + margin.top + margin.bottom)
      .attr("width", width + margin.left + margin.right)
      .on("click", clicked);
    var projection = geoAlbersUsa()
      .translate([width / 2, height / 2])
      .scale(width);
    var path = geoPath().projection(projection);
    var g = svg
      .append("g")
      .attr("class", "center-container center-items us-state")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    colorScale.domain(color_domain);
    g.append("g")
      .attr("id", "counties")
      .selectAll("path")
      .data(topojson.feature(us, us.objects.counties).features)
      .enter()
      .append("path")
      .attr("d", path)
      .style("fill", function (d) {
        let fipStr = d.id.toString();
        if (fipStr.length === 4) {
          fipStr = `0${fipStr}`;
        }
        let clusterLevel = pairRateWithId[fipStr];
        return getColorByClusterlevel(clusterLevel);
      })
      .on("mouseover", (event, d) => {
        console.log(d);
        let fipStr = d.id.toString();
        if (fipStr.length === 4) {
          fipStr = `0${fipStr}`;
        }
        let fipObj = data.filter((x) => x.locationid == fipStr)[0];
        if (fipObj) {
          d3.select("#disparity-map-tooltip")
            .style("display", "flex")
            .html(
              "<div><span style='margin-left: 10px;color:black;'>" +
                fipObj.county +
                ", " +
                fipObj.state +
                "</span> </div>"
            )
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 28 + "px");
        }
      })
      .on("mouseout", (event, d) => {
        d3.select("#disparity-map-tooltip").style("display", "none");
      })
      .on("click", function (event, d) {
        let fipStr = d.id.toString();
        if (fipStr.length === 4) {
          fipStr = `0${fipStr}`;
        }
        // console.log(`fip id: ${d.id}, ${fipStr}`);
        setCurrentFip(fipStr);
        let fipObj = data.filter((x) => x.locationid == fipStr)[0];
        setStatsTitle(`${fipObj.county},${fipObj.state}`);
        let clusterLevel = pairRateWithId[fipStr];
        setCurrentClusterLevel(clusterLevel);
        console.log("countyInfoByState", countyInfoByState);
        var countyInfoObj = countyInfoByState;
        console.log("countyInfoByState", countyInfoByState);
        if (countyInfoObj) {
          var countyObjHtml = "";
          var countyState = countyInfoObj[fipObj.state];
          console.log("countyInfoByState", countyState);
          if (countyState) {
            console.log("countyState", countyState);
            let countyObj = countyState.filter(
              (x) => x.County == fipObj.county
            )[0];
            setCountyObj(countyObj);
            console.log("countyInfoByState- countyObj", countyObj);
            console.log("countyObj", fipStr);
            console.log("countyObj", fipObj.county);
          }
        }
        let hdObj = hd.filter((x) => x.Cluster == clusterLevel)[0];
        console.log(hdObj);
        setHdObj(hdObj);
        console.log("countyInfoByState- hdObj", hdObj);
      })
      .on("dblclick", reset);

    g.append("g")
      .attr("id", "states")
      .selectAll("path")
      .data(topojson.feature(us, us.objects.states).features)
      .enter()
      .append("path")
      .attr("d", path)
      .on("mouseover", function (event, d) {
        let fipStr = d.id.toString();
        if (fipStr.length === 4) {
          fipStr = `0${fipStr}`;
        }
        let fipObj = stateInfo.filter((x) => x.id == fipStr)[0];
        if (fipObj) {
          d3.select("#disparity-map-tooltip")
            .style("display", "flex")
            .html(
              "<strong style='color: black'>State:</strong><span style='margin-left: 10px;color:black;'>" +
                fipObj.name +
                "</span> <br/>"
            )
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 28 + "px");
        }
      })
      .on("mouseout", (event, d) => {
        d3.select("#disparity-map-tooltip").style("display", "none");
      })
      .on("click", clicked);

    g.append("path")
      .datum(
        topojson.mesh(us, us.objects.states, function (a, b) {
          return a !== b;
        })
      )
      .attr("id", "state-borders")
      .attr("d", path);
    d3.select("#disparitiesMap")
      .append("text")
      .attr("x", 750)
      .attr("id", "disparitiesMapBackBtn")
      .attr("y", 40)
      .attr("dy", ".15em")
      .text((d, i) => {
        return "x";
      })
      .style("text-anchor", "start")
      .style("font-size", 24)
      .style("display", "none")
      .attr("class", "disparitiesMapBackBtn")
      .on("click", (d) => {
        reset();
      });
    const legendData = clusterArray.sort(function (a, b) {
      return a - b;
    });
    const legend = d3
      .select("#disparitiesMap")
      .append("g")
      .attr(
        "transform",
        "translate(" + (margin.left + margin.right + 60) + ",450)"
      )
      .selectAll("g")
      .data(legendData)
      .enter()
      .append("g");

    legend
      .append("rect")
      .attr("fill", (d, i) => getColorByClusterlevel(d))
      .attr("height", 15)
      .attr("width", 15);

    legend
      .append("text")
      .attr("x", 18)
      .attr("y", 10)
      .attr("dy", ".15em")
      .text((d, i) => {
        if (d == -1) {
          return `No Data`;
        }
        let hdObj = hd.filter((x) => x.Cluster == d)[0];
        return `${hdObj["ClusterName"]}`;
      })
      .style("text-anchor", "start")
      .style("font-size", 12);

    legend.attr("transform", function (d, i) {
      if (i < 2) {
        return "translate(" + i * 300 + ", 0)";
      } else if (i < 4) {
        return "translate(" + (i - 2) * 300 + ", 30)";
      } else if (i < 6) {
        return "translate(" + (i - 4) * 300 + ", 60)";
      } else if (i < 8) {
        return "translate(" + (i - 6) * 300 + ", 90)";
      }
    });

    function clicked(event, d) {
      if (d3.select(".background").node() === this) return reset();
      d3.select("#disparitiesMapBackBtn").style("display", "block");
      if (active.node() === this) return reset();
      //d3.selectAll("#state").classed("active", false);
      d3.select("#states").style("opacity", 0.9);

      d3.select("#disparity-stats-view").style("background", "#ffffff");
      active.classed("active", false);
      active = d3.select(this).classed("active", true);
      let fipStr = d.id.toString();
      let fipObj = stateInfo.filter((x) => x.id == fipStr)[0];

      if (fipObj) {
        console.log("fipObj.state", fipObj.code);
        getDisparityCountyInfo(fipObj.code);
      }
      var bounds = path.bounds(d),
        dx = bounds[1][0] - bounds[0][0],
        dy = bounds[1][1] - bounds[0][1],
        x = (bounds[0][0] + bounds[1][0]) / 2,
        y = (bounds[0][1] + bounds[1][1]) / 2,
        scale = 0.9 / Math.max(dx / width, dy / height),
        translate = [width / 2 - scale * x, height / 2 - scale * y];

      g.transition()
        .duration(750)
        .style("stroke-width", 1.5 / scale + "px")
        .attr("transform", "translate(" + translate + ")scale(" + scale + ")");
    }
    function reset() {
      active.classed("active", false);
      active = d3.select(null);
      d3.select("#states").style("opacity", 0);
      d3.select("#disparitiesMapBackBtn").style("display", "none");
      setCurrentClusterLevel("");
      setCurrentFip("");
      g.transition()
        .delay(100)
        .duration(750)
        .style("stroke-width", "1.5px")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    }
    d3.select("disparity-map-tooltip").remove();
    var tooltip = d3
      .select("body")
      .append("div")
      .attr("id", "disparity-map-tooltip")
      .style("opacity", 1);
  };

  const ClusterTabContent = () => (
    <div className="disparity-stats-view-tbl-ctn scroll-vsbl">
      <table className="disparity-stats-view-tbl">
        <tbody>
          {Object.keys(hdObj).map((key) => (
            <tr>
              <td style={{ textAlign: "left" }}>
                <b>{key}</b>
              </td>
              <td style={{ textAlign: "right" }}>{hdObj[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  const CountyTabContent = () => (
    <div class="disparity-stats-view-tbl-ctn scroll-vsbl">
      <table class="disparity-stats-view-tbl">
        <tbody>
          {Object.keys(countyObj).map((key) => (
            <tr>
              <td style={{ textAlign: "left" }}>
                <b>{key}</b>
              </td>
              <td style={{ textAlign: "right" }}>{countyObj[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  const showMoreInfo = (treatmentType) => {
    if (treatmentType == "cluster") {
      if (currentClusterLevel !== null && currentClusterLevel !== "") {
        navigate(`/insights/hd-info/cluster/${currentClusterLevel}`);
        setCurrentClusterLevel("");
        setCurrentFip("");
      }
    } else {
      if (currentFip !== null && currentFip != "") {
        navigate(`/insights/hd-info/county/${currentFip}`);
        setCurrentFip("");
        setCurrentClusterLevel("");
      }
    }
  };
  const onChange = (key) => {
    console.log(key);
    setTabActiveKey(key);
  };

  return (
    <HealthCareDisparityMapWrapper>
      <HealthCareDisparityMapContainer id="disparitiesSvg"></HealthCareDisparityMapContainer>
      {(currentFip !== "" || currentClusterLevel !== "") && (
        <HDStatsViewWrapper>
          {currentFip == "" && currentClusterLevel == "" ? (
            <div className="disparity-stats-view-instruction">
              Select County/Cluster in the map to see the details
            </div>
          ) : (
            <>
              <div className="disparity-stats-view-title">{statsTitle}</div>
              <div className="disparity-stats-view-tbl-ctn">
                <Segmented
                  block
                  options={["County Info", "Cluster Info"]}
                  onChange={onChange}
                />
                {tabActiveKey == "County Info" && <CountyTabContent />}
                {tabActiveKey == "Cluster Info" && <ClusterTabContent />}
              </div>
              <div id="btn-show-more">
                <div
                  className="btn-show-more btn-fmt"
                  onClick={() => {
                    showMoreInfo("cluster");
                  }}
                >
                  Cluster Treatment Info
                </div>
                <div
                  className="btn-show-more btn-fmt"
                  onClick={() => {
                    showMoreInfo("county");
                  }}
                >
                  County Treatment Info
                </div>
              </div>
            </>
          )}
        </HDStatsViewWrapper>
      )}
    </HealthCareDisparityMapWrapper>
  );
};

HealthCareDisparityMap.propTypes = {
  // bla: PropTypes.string,
};

HealthCareDisparityMap.defaultProps = {
  // bla: 'test',
};

export default HealthCareDisparityMap;
