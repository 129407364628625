import styled from "styled-components";

export const FooterWrapper = styled.div`
  .footer {
    z-index:9999;
    padding: 10px 10px;
    font-size: .625rem;
    background: #2E547A;
    color: #ffffff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.3;
    letter-spacing: .8px;
    position: fixed;
    bottom:0;
    left: 0;
    right: 0;
    .brandLogo {
      margin-top: 0px;
      height: 20px;
      width: auto;
    }
    .footer-links {
        a {
        color: #ffffff;
        padding: 8px;
        text-decoration: none;
        font-size: 12px;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
