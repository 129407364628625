import axios from "axios";
import constants from "../../config";
import {
  getCookie,
  setCookie,
  redirectToPing,
} from "../../utils/cookie-helper";
import jwtDecode from "jwt-decode";
export const client = axios.create({
  withCredentials: true,
});
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
// Add a request interceptor
client.interceptors.request.use(
  function (config) {
    config.headers.common["Content-Type"] = "application/json";
    const access_token = getCookie("access-token");
    if (access_token && access_token !== "") {
      config.headers.common["authorization"] = "Bearer " + access_token;
    }
    var userprofile = localStorage.getItem("SSN_USER_PROFILE");
    var dataSource = localStorage.getItem("SSN_USER_DATASOURCE");
    var frmDt = localStorage.getItem("SSN_FROMDT");
    var toDt = localStorage.getItem("SSN_TODT");

    if (userprofile && userprofile !== "") {
      config.params = {
        profile: userprofile,
        dataSource: dataSource,
        startDate: frmDt,
        endDate: toDt,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (res) => res,
  (error) => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;
    if (status === 401 && !originalRequest._retry) {
      console.log("status", status);
    }
    // status might be undefined
    if (!status && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const refreshToken = getCookie("refresh-token");
      if (refreshToken) {
        return new Promise(function (resolve, reject) {
          axios
            .get(
              constants.api.loginUrl +
              "/identity/ping/refreshtoken?refreshToken=" +
              refreshToken +
              "&grantType=refresh_token"
            )
            .then(({ data }) => {
              var decoded = jwtDecode(data.access_token);
              if (decoded.exp) {
                var exp = decoded.exp;
                var username = decoded.first_name + " " + decoded.last_name;
                setCookie("jobTitle", decoded.designation, exp);
                setCookie("userName", username, exp);
                setCookie("firstName", decoded.first_name, exp);
                setCookie("lastName", decoded.last_name, exp);
                setCookie("sub", decoded.id, exp);
                setCookie("email", decoded.email, exp);
                setCookie("access-token", data.access_token, exp);
                setCookie("refresh-token", data.refresh_token, exp);
              }
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.access_token;
              originalRequest.headers["Authorization"] =
                "Bearer " + data.access_token;
              processQueue(null, data.access_token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      } else {
        redirectToPing();
      }
    }
    return Promise.reject(error);
  }
);

/*// Add a response interceptor
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);*/

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    console.log("Request Successful!", response);
    console.log(response.data);
    console.log(response.status);
    console.log(response.statusText);
    console.log(response.headers);
    console.log(response.config);
    return response.data;
  };

  const handleStatusCode = (statuscode) => {
    switch (statuscode) {
      case 200:
        console.log("ajax.statusCode: 200");
        break;
      case 404:
        console.log("ajax.statusCode: 404");
        break;
      case 500:
        console.log("ajax.statusCode: 500");
        break;
      case 403:
        console.log("ajax.statusCode: 403");
        localStorage.setItem("isPrevState", "TRUE");
        redirectToPing();
        break;
      default:
        break;
    }
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
      handleStatusCode(error.response.status);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export const GetAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: url,
    withCredentials: false,
    method: "GET",
    ...otherOptions,
  });
};
export const PostAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "POST",
    withCredentials: false,
    body: JSON.stringify(options.data),
    ...otherOptions,
  });
};
export const PutAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "PUT",
    withCredentials: false,
    ...otherOptions,
  });
};
export const DeleteAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "DELETE",
    withCredentials: false,
    ...otherOptions,
  });
};
export const MultipleGetRequest = (options) => {
  if (options && options.URL1 && options.URL2) {
    axios
      .all([axios.get(options.URL1), axios.get(options.URL2)])
      .then((responseArr) => {
        console.log("Request Successful!", responseArr);
        return responseArr;
      })
      .catch((error) => {
        console.error("Request Failed:", error.config);
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Data:", error.response.data);
          console.error("Headers:", error.response.headers);
        } else {
          console.error("Error Message:", error.message);
        }
        return Promise.reject(error.response || error.message);
      });
  }
};

export default request;
