import React from "react";
import PropTypes from "prop-types";
import { PhysicianInfoWrapper } from "./PhysicianInfo.styles";

const PhysicianInfo = (props) => {
  return <PhysicianInfoWrapper>Test content</PhysicianInfoWrapper>;
};

PhysicianInfo.propTypes = {
  // bla: PropTypes.string,
};

PhysicianInfo.defaultProps = {
  // bla: 'test',
};

export default PhysicianInfo;
