import styled from "styled-components";

export const MultiYearPickerWrapper = styled.div`
  
  .rmdp-input {
    height: 30px !important;
    width:300px;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #962163dd !important;
  }
  .rmdp-panel-body li {
    background-color: #962163dd !important;
  }
  .rmdp-day.rmdp-today span {
    background-color: #96216366 !important;
  }
  .b-deselect {
    color: #666666;
    width: 16px !important;
    height: 16px !important;
    border-radius: 8px !important;
    position: absolute;
    left: 70px !important;
    top: 50% !important;
    padding: 0px !important;
  }
`;
