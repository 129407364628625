import styled from "styled-components";

export const HealthCareDisparityMapWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 12px;
  padding: 50px 30px;
  justify-content: center;
  margin-bottom: 16px;
  .hcpMapBackBtn {
    background-color: #000000dd;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    max-width: 100px;
    align-self: flex-end;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 5px;
    z-index: 100000;
    color: #ffffff;
  }
`;
export const HealthCareDisparityMapContainer = styled.div`
  min-width: 550px;
  width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
`;

export const HDStatsViewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-tabs-nav {
    margin: 0 0 0px 0;
  }
  .disparity-stats-view-title {
    margin: 0px 10px;
    margin-bottom: 20px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 1.75rem;
  }
  .disparity-stats-view-instruction {
    margin: 0px 10px;
    margin-bottom: 20px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #999999;
    font-size: 16px;
  }
  .ant-tabs-nav-wrap {
    display: inline-block !important;
  }
  .ant-tabs-nav-list {
    position: fixed !important;
    top: 180px;
    right: 20px;
    z-index: 100000000;
  }
  .ant-segmented-item-label {
    font-size: 14px;
    line-height: 40px;
  }
  .ant-segmented-group {
    height: 40px;
  }
  .ant-tabs-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
    text-shadow: 0 0 0.25px currentcolor;
  }
  .ant-tabs-tab-active {
    background-color: #908 !important;
    color: #ffffff !important;
  }
  #btn-show-more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: row;
    padding: 0px 20px;
    margin-top: 0px;
    flex: 1;
    width: 100%;
    height: 50px;
  }
  .btn-fmt {
    border-radius: 0px;
    width: 49%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8a0d51;
    color: #fff;
    font-size: 15px;
    font-family: "HelveticaNeue-medium";
    border: 0px;
  }
  .disparity-stats-view-tbl-ctn {
    /* height: 400px; */
    overflow-y: scroll;
    max-height: 450px;
    min-height: 450px !important;
    width: 100%;
    max-width: 420px;
    box-shadow: 2px 0px 2px 0px #00000022;
    border: 1px solid #eeeeee;
  }
  .disparity-stats-view-tbl {
    margin: 0px;
    margin-top: 15px;
    padding-bottom: 0px;
  }
  .disparity-stats-view-tbl td {
    color: #777777;
    font-family: "HelveticaNeue-Light";
    padding: 5px 10px;
    font-size: 14px;
    width: 50%;
  }
`;
