import React, { useEffect } from "react";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import SunBurstChart from "../../../components/SunburstChart";
import config from "../../../config";
import { GeoDistributionInfoWrapper } from "./GeoDistributionInfo.styles";
import SiteMap from "../../../components/SiteMap/SiteMap";
import PhysicianList from "../../../components/PhysicianList/PhysicianList";

const GeoDistributionInfo = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  var _geoDistributionInfoinstance;
  const options = {
    onLoad: function () {
      console.log("[onLoad]");
      setTimeout(() => {
        _geoDistributionInfoinstance
          .open({
            id: "1",
            title: "GeoDistributionInfo",
            closable: false,
            panelComponent: (porps) => {
              return (
                <SunBurstChart
                  url={`${config.api.hcpWheelSummary}?fip=${id}`}
                  selectedId={id}
                  dataType="hcp"
                  showYears={false}
                  onInfoUpdate={() => {}}
                  onResult={(root) => {
                    /*physicianList.fetchPhysiciansListNew(
                    categoryId,
                    "accounts",
                    root.id,
                    accountType
                  );*/
                  }}
                  onSelect={(d) => {}}
                  primaryTabInstance={_geoDistributionInfoinstance}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });
        _geoDistributionInfoinstance
          .open({
            id: "2",
            title: "Physicians",
            closable: false,
            panelComponent: (porps) => {
              return (
                <PhysicianList
                  url={`${config.api.getPhysicians}?fipId=${id}`}
                  dataType="hcp"
                  onInfoUpdate={() => {}}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });

        // switch to tab
        _geoDistributionInfoinstance.select("1").then(() => {
          console.log("tab is selected");
        });
      }, 1000);
    },
    onInit: function () {
      console.log("[onInit]");
    },
  };

  const [
    GeoDistributionInfoTabList,
    GeoDistributionInfoPanelList,
    onGeoDistributionInfoReady,
  ] = useDynTabs(options);

  onGeoDistributionInfoReady((instance) => {
    _geoDistributionInfoinstance = instance;
  });

  useEffect(() => {
    //alert("!");
  }, []);

  return (
    <GeoDistributionInfoWrapper>
      <SiteMap />
      <div className="content">
        <GeoDistributionInfoTabList></GeoDistributionInfoTabList>
        <GeoDistributionInfoPanelList></GeoDistributionInfoPanelList>
      </div>
    </GeoDistributionInfoWrapper>
  );
};

export default GeoDistributionInfo;
