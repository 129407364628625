export default {
  type: "Topology",
  objects: {
    counties: {
      type: "GeometryCollection",
      geometries: [
        { type: "Polygon", id: 53073, arcs: [[0, 1, 2, 3]] },
        { type: "Polygon", id: 30105, arcs: [[4, 5, 6, 7, 8, 9]] },
        {
          type: "Polygon",
          id: 30029,
          arcs: [[10, 11, 12, 13, 14, 15, 16, 17, 18, 19]],
        },
        { type: "Polygon", id: 16021, arcs: [[20, 21, 22, 23]] },
        { type: "Polygon", id: 30071, arcs: [[-9, 24, 25, 26, 27, 28, 29]] },
        { type: "Polygon", id: 38079, arcs: [[30, 31, 32, 33]] },
        { type: "Polygon", id: 30053, arcs: [[-19, 34, 35, -21, 36]] },
        { type: "Polygon", id: 38009, arcs: [[-32, 37, 38, 39, 40]] },
        { type: "Polygon", id: 30035, arcs: [[41, 42, -11, 43]] },
        { type: "Polygon", id: 30041, arcs: [[44, 45, 46, 47]] },
        { type: "Polygon", id: 30005, arcs: [[-29, -28, 48, 49, -48, 50]] },
        { type: "Polygon", id: 30019, arcs: [[51, 52, -5, 53]] },
        { type: "Polygon", id: 38067, arcs: [[54, 55, 56, 57]] },
        { type: "Polygon", id: 27069, arcs: [[58, 59, -55, 60]] },
        { type: "Polygon", id: 38095, arcs: [[61, 62, 63, -34, 64, 65]] },
        { type: "Polygon", id: 38019, arcs: [[-57, 66, 67, -66, 68]] },
        {
          type: "Polygon",
          id: 53047,
          arcs: [[69, 70, 71, 72, 73, 74, -1, 75]],
        },
        { type: "Polygon", id: 53065, arcs: [[76, 77, 78, 79, 80]] },
        { type: "Polygon", id: 53051, arcs: [[-23, 81, 82, -77, 83]] },
        { type: "Polygon", id: 53019, arcs: [[-80, 84, -70, 85]] },
        { type: "Polygon", id: 30051, arcs: [[86, 87, 88, -46, 89]] },
        { type: "Polygon", id: 38023, arcs: [[90, 91, 92, 93]] },
        { type: "Polygon", id: 38013, arcs: [[94, 95, 96, 97, -91, 98]] },
        { type: "Polygon", id: 30101, arcs: [[99, -88, 100, -42]] },
        { type: "Polygon", id: 38075, arcs: [[101, 102, -95, 103, -40]] },
        { type: "Polygon", id: 27135, arcs: [[104, 105, -59, 106, 107]] },
        { type: "Polygon", id: 30091, arcs: [[-93, 108, 109, -52, 110]] },
        {
          type: "Polygon",
          id: 16017,
          arcs: [[-36, 111, 112, 113, 114, -82, -22]],
        },
        { type: "Polygon", id: 38101, arcs: [[-103, 115, 116, 117, -96]] },
        { type: "MultiPolygon", id: 53055, arcs: [[[118]], [[119]], [[120]]] },
        { type: "Polygon", id: 27071, arcs: [[121, 122, 123, 124, 125]] },
        { type: "Polygon", id: 53057, arcs: [[126, -2, -75, 127, 128, 129]] },
        { type: "Polygon", id: 38105, arcs: [[-98, 130, 131, 132, -109, -92]] },
        {
          type: "Polygon",
          id: 38049,
          arcs: [[133, 134, 135, -116, -102, -39]],
        },
        {
          type: "Polygon",
          id: 27137,
          arcs: [
            [
              136,
              137,
              138,
              139,
              140,
              141,
              142,
              143,
              144,
              145,
              146,
              147,
              148,
              149,
              150,
              -122,
              151,
            ],
          ],
        },
        { type: "Polygon", id: 30085, arcs: [[-110, -133, 152, 153, -6, -53]] },
        { type: "Polygon", id: 53007, arcs: [[-74, 154, 155, 156, 157, -128]] },
        {
          type: "Polygon",
          id: 38061,
          arcs: [[158, 159, 160, -131, -97, -118]],
        },
        {
          type: "Polygon",
          id: 27089,
          arcs: [[161, 162, 163, 164, 165, -60, -106]],
        },
        {
          type: "Polygon",
          id: 38069,
          arcs: [[-64, 166, 167, 168, -134, -38, -31]],
        },
        { type: "Polygon", id: 38071, arcs: [[169, 170, 171, -62, -68]] },
        {
          type: "Polygon",
          id: 38099,
          arcs: [[-56, -166, 172, 173, -170, -67]],
        },
        {
          type: "Polygon",
          id: 27007,
          arcs: [[-124, 174, 175, 176, 177, 178, -162, -105, 179]],
        },
        { type: "Polygon", id: 30073, arcs: [[-101, -87, 180, 181, -12, -43]] },
        { type: "MultiPolygon", id: 53029, arcs: [[[182, 183]], [[184]]] },
        { type: "Polygon", id: 53009, arcs: [[185, 186, 187]] },
        {
          type: "Polygon",
          id: 38005,
          arcs: [[-63, -172, 188, 189, 190, -167]],
        },
        {
          type: "Polygon",
          id: 30015,
          arcs: [[-50, 191, 192, 193, 194, -181, -90, -45]],
        },
        {
          type: "Polygon",
          id: 53061,
          arcs: [[-158, 195, 196, -183, 197, -129]],
        },
        {
          type: "Polygon",
          id: 30089,
          arcs: [[-18, 198, 199, 200, 201, -112, -35]],
        },
        { type: "Polygon", id: 27075, arcs: [[202, 203, -137, 204]] },
        {
          type: "Polygon",
          id: 38063,
          arcs: [[205, 206, 207, 208, -189, -171, -174]],
        },
        {
          type: "Polygon",
          id: 38035,
          arcs: [[-165, 209, 210, 211, -206, -173]],
        },
        {
          type: "Polygon",
          id: 27119,
          arcs: [[212, 213, 214, 215, 216, 217, 218, -210, -164]],
        },
        {
          type: "Polygon",
          id: 27113,
          arcs: [[-179, 219, -215, 220, -213, -163]],
        },
        { type: "Polygon", id: 30083, arcs: [[221, 222, 223, 224, -153]] },
        { type: "Polygon", id: 53017, arcs: [[225, 226, -155, -73]] },
        {
          type: "Polygon",
          id: 38053,
          arcs: [[-161, 227, 228, 229, 230, -222, -132]],
        },
        { type: "Polygon", id: 53031, arcs: [[231, 232, 233, -186, 234]] },
        { type: "Polygon", id: 30099, arcs: [[-195, 235, 236, -13, -182]] },
        { type: "Polygon", id: 30055, arcs: [[-225, 237, 238, 239, -7, -154]] },
        {
          type: "Polygon",
          id: 16079,
          arcs: [[-202, 240, 241, 242, 243, 244, -113]],
        },
        { type: "Polygon", id: 30047, arcs: [[245, -199, -17]] },
        {
          type: "Polygon",
          id: 53063,
          arcs: [[-83, -115, 246, 247, 248, 249, -78]],
        },
        {
          type: "Polygon",
          id: 27029,
          arcs: [[250, 251, 252, -216, -220, -178]],
        },
        { type: "Polygon", id: 16055, arcs: [[-245, 253, -247, -114]] },
        {
          type: "Polygon",
          id: 30033,
          arcs: [[-240, 254, 255, 256, 257, -25, -8]],
        },
        { type: "Polygon", id: 27125, arcs: [[-214, -221]] },
        {
          type: "Polygon",
          id: 53025,
          arcs: [[-72, 258, 259, 260, 261, 262, 263, -226]],
        },
        {
          type: "Polygon",
          id: 53043,
          arcs: [[-85, -79, -250, 264, 265, -259, -71]],
        },
        {
          type: "Polygon",
          id: 30049,
          arcs: [[266, 267, 268, 269, 270, -14, -237]],
        },
        {
          type: "MultiPolygon",
          id: 53035,
          arcs: [[[271]], [[272, 273, 274, 275, 276]]],
        },
        { type: "Polygon", id: 27061, arcs: [[-151, 277, 278, -175, -123]] },
        {
          type: "Polygon",
          id: 38055,
          arcs: [[279, 280, 281, 282, 283, -159, -117, -136]],
        },
        { type: "Polygon", id: 38027, arcs: [[-209, 284, 285, 286, -190]] },
        {
          type: "Polygon",
          id: 38103,
          arcs: [[-191, -287, 287, 288, 289, 290, -168]],
        },
        {
          type: "Polygon",
          id: 38083,
          arcs: [[-169, -291, 291, 292, -280, -135]],
        },
        {
          type: "Polygon",
          id: 38025,
          arcs: [[-284, 293, 294, 295, -228, -160]],
        },
        {
          type: "Polygon",
          id: 30027,
          arcs: [[-27, 296, 297, 298, 299, 300, -192, -49]],
        },
        { type: "Polygon", id: 30021, arcs: [[-224, 301, 302, -238]] },
        {
          type: "MultiPolygon",
          id: 53033,
          arcs: [[[303]], [[-157, 304, 305, 306, 307, -196]]],
        },
        { type: "Polygon", id: 30013, arcs: [[308, 309, -267, -236, -194]] },
        {
          type: "Polygon",
          id: 38091,
          arcs: [[-212, 310, 311, 312, 313, -207]],
        },
        {
          type: "Polygon",
          id: 38039,
          arcs: [[-314, 314, 315, 316, -285, -208]],
        },
        { type: "Polygon", id: 38097, arcs: [[317, 318, -311, -211, -219]] },
        {
          type: "MultiPolygon",
          id: 53045,
          arcs: [[[319, -276, 320, 321, 322, 323, -232]]],
        },
        {
          type: "Polygon",
          id: 30063,
          arcs: [[-16, 324, 325, 326, 327, 328, 329, -200, -246]],
        },
        {
          type: "Polygon",
          id: 30077,
          arcs: [[-271, 330, 331, 332, -325, -15]],
        },
        { type: "Polygon", id: 30069, arcs: [[-258, 333, 334, -297, -26]] },
        { type: "Polygon", id: 53037, arcs: [[-227, -264, 335, -305, -156]] },
        { type: "Polygon", id: 38031, arcs: [[-317, 336, -288, -286]] },
        { type: "Polygon", id: 38057, arcs: [[337, 338, 339, -294, -283]] },
        {
          type: "Polygon",
          id: 53027,
          arcs: [[-324, 340, 341, 342, 343, -233]],
        },
        { type: "Polygon", id: 27087, arcs: [[344, 345, -217, -253]] },
        {
          type: "Polygon",
          id: 27107,
          arcs: [[-218, -346, 346, 347, 348, -318]],
        },
        { type: "Polygon", id: 30061, arcs: [[-330, 349, -241, -201]] },
        {
          type: "Polygon",
          id: 27021,
          arcs: [[350, 351, 352, 353, 354, 355, -176, -279]],
        },
        { type: "Polygon", id: 23003, arcs: [[356, 357, 358, 359, 360]] },
        { type: "Polygon", id: 30045, arcs: [[-301, 361, 362, -309, -193]] },
        { type: "Polygon", id: 16009, arcs: [[-244, 363, 364, -248, -254]] },
        { type: "Polygon", id: 27057, arcs: [[-356, 365, 366, -251, -177]] },
        {
          type: "MultiPolygon",
          id: 53053,
          arcs: [
            [[-307, -306, 367, 368, 369, 370]],
            [[-273, 371]],
            [[-321, -275, 372]],
          ],
        },
        {
          type: "Polygon",
          id: 30109,
          arcs: [[-231, 373, 374, 375, -302, -223]],
        },
        { type: "Polygon", id: 38007, arcs: [[-296, 376, 377, 378, -229]] },
        { type: "Polygon", id: 38033, arcs: [[-379, 379, 380, -374, -230]] },
        {
          type: "Polygon",
          id: 38043,
          arcs: [[381, 382, 383, 384, -292, -290]],
        },
        {
          type: "Polygon",
          id: 38093,
          arcs: [[-316, 385, 386, 387, -382, -289, -337]],
        },
        {
          type: "Polygon",
          id: 38015,
          arcs: [[-385, 388, 389, 390, -281, -293]],
        },
        { type: "Polygon", id: 38065, arcs: [[-391, 391, -338, -282]] },
        { type: "Polygon", id: 53001, arcs: [[392, 393, -260, -266]] },
        {
          type: "Polygon",
          id: 53075,
          arcs: [[-249, -365, 394, 395, 396, 397, 398, 399, -393, -265]],
        },
        {
          type: "Polygon",
          id: 38003,
          arcs: [[-313, 400, 401, 402, -386, -315]],
        },
        {
          type: "Polygon",
          id: 38017,
          arcs: [[-319, -349, 403, 404, 405, -401, -312]],
        },
        { type: "Polygon", id: 53067, arcs: [[-370, 406, -341, -323, 407]] },
        {
          type: "Polygon",
          id: 30079,
          arcs: [[-376, 408, 409, -255, -239, -303]],
        },
        {
          type: "Polygon",
          id: 27005,
          arcs: [[-367, 410, 411, 412, -347, -345, -252]],
        },
        {
          type: "Polygon",
          id: 27027,
          arcs: [[-413, 413, 414, 415, -404, -348]],
        },
        { type: "Polygon", id: 16057, arcs: [[-243, 416, 417, -395, -364]] },
        {
          type: "Polygon",
          id: 53077,
          arcs: [[-263, 418, 419, 420, 421, -368, -336]],
        },
        {
          type: "Polygon",
          id: 30059,
          arcs: [[-363, 422, 423, 424, 425, 426, -268, -310]],
        },
        {
          type: "Polygon",
          id: 27001,
          arcs: [[-150, 427, 428, 429, 430, 431, -351, -278]],
        },
        { type: "Polygon", id: 26131, arcs: [[432, 433, 434, 435]] },
        {
          type: "Polygon",
          id: 38089,
          arcs: [[-340, 436, 437, 438, 439, -377, -295]],
        },
        {
          type: "Polygon",
          id: 38059,
          arcs: [[-390, 440, 441, 442, -437, -339, -392]],
        },
        { type: "Polygon", id: 26013, arcs: [[443, 444, 445, 446]] },
        {
          type: "Polygon",
          id: 16035,
          arcs: [[-350, -329, 447, 448, 449, -417, -242]],
        },
        {
          type: "Polygon",
          id: 30017,
          arcs: [[450, 451, 452, 453, -256, -410]],
        },
        {
          type: "Polygon",
          id: 30087,
          arcs: [[-454, 454, 455, 456, 457, 458, -334, -257]],
        },
        { type: "Polygon", id: 30039, arcs: [[459, 460, -326, -333]] },
        { type: "Polygon", id: 27159, arcs: [[-355, 461, 462, -411, -366]] },
        { type: "Polygon", id: 27035, arcs: [[-432, 463, 464, -352]] },
        { type: "Polygon", id: 53049, arcs: [[465, 466, 467, -343]] },
        {
          type: "Polygon",
          id: 53041,
          arcs: [[-407, -369, -422, 468, 469, 470, -466, -342]],
        },
        { type: "Polygon", id: 30007, arcs: [[-427, 471, 472, -269]] },
        { type: "Polygon", id: 27017, arcs: [[473, 474, -428, -149]] },
        {
          type: "Polygon",
          id: 26053,
          arcs: [[475, 476, 477, 478, 479, 480, 481, 482, 483, 484, -434]],
        },
        { type: "Polygon", id: 30065, arcs: [[-459, 485, 486, -298, -335]] },
        { type: "Polygon", id: 26095, arcs: [[487, 488, 489, 490, 491]] },
        {
          type: "Polygon",
          id: 30037,
          arcs: [[-487, 492, 493, 494, 495, -299]],
        },
        { type: "Polygon", id: 30107, arcs: [[-300, -496, 496, -423, -362]] },
        {
          type: "Polygon",
          id: 53021,
          arcs: [[497, 498, 499, -261, -394, -400]],
        },
        {
          type: "Polygon",
          id: 53005,
          arcs: [[500, 501, 502, 503, -419, -262, -500]],
        },
        {
          type: "Polygon",
          id: 27111,
          arcs: [[-463, 504, 505, 506, 507, -414, -412]],
        },
        { type: "Polygon", id: 38037, arcs: [[508, 509, 510, -438, -443]] },
        { type: "Polygon", id: 53023, arcs: [[511, 512, 513, -398]] },
        {
          type: "Polygon",
          id: 30025,
          arcs: [[-381, 514, 515, 516, 517, -451, -409, -375]],
        },
        {
          type: "Polygon",
          id: 16049,
          arcs: [[518, 519, 520, 521, 522, 523, 524, -448, -328]],
        },
        {
          type: "Polygon",
          id: 30081,
          arcs: [[-461, 525, 526, 527, -519, -327]],
        },
        {
          type: "Polygon",
          id: 38029,
          arcs: [[-384, 528, 529, 530, 531, -441, -389]],
        },
        { type: "Polygon", id: 38047, arcs: [[-388, 532, 533, -529, -383]] },
        {
          type: "Polygon",
          id: 16069,
          arcs: [[-450, 534, -524, 535, 536, -396, -418]],
        },
        {
          type: "Polygon",
          id: 38087,
          arcs: [[537, 538, 539, -515, -380, -378, -440]],
        },
        {
          type: "Polygon",
          id: 38045,
          arcs: [[540, 541, 542, -533, -387, -403]],
        },
        { type: "Polygon", id: 38041, arcs: [[-511, 543, -538, -439]] },
        { type: "Polygon", id: 27167, arcs: [[-508, 544, 545, 546, -415]] },
        {
          type: "Polygon",
          id: 38073,
          arcs: [[-406, 547, 548, 549, -541, -402]],
        },
        {
          type: "Polygon",
          id: 38077,
          arcs: [[-547, 550, 551, 552, -548, -405, -416]],
        },
        {
          type: "Polygon",
          id: 53013,
          arcs: [[553, 554, 555, -498, -399, -514]],
        },
        { type: "Polygon", id: 53071, arcs: [[-556, 556, -501, -499]] },
        {
          type: "Polygon",
          id: 55051,
          arcs: [[557, -484, -483, -482, -481, -480, -479, 558, 559, 560, 561]],
        },
        {
          type: "Polygon",
          id: 23025,
          arcs: [[562, 563, 564, 565, 566, 567, -360]],
        },
        { type: "Polygon", id: 23021, arcs: [[568, -563, -359]] },
        {
          type: "Polygon",
          id: 30043,
          arcs: [[-473, 569, 570, 571, 572, -331, -270]],
        },
        { type: "Polygon", id: 26153, arcs: [[-490, 573, 574, 575, 576]] },
        {
          type: "Polygon",
          id: 30111,
          arcs: [[577, 578, 579, 580, -493, -486, -458]],
        },
        { type: "Polygon", id: 30103, arcs: [[581, -578, -457]] },
        { type: "Polygon", id: 16061, arcs: [[-449, -525, -535]] },
        { type: "Polygon", id: 53003, arcs: [[-537, 582, -512, -397]] },
        { type: "Polygon", id: 38085, arcs: [[-532, 583, 584, -509, -442]] },
        {
          type: "Polygon",
          id: 26071,
          arcs: [[-445, 585, 586, 587, 588, 589, 590, -476, -433, 591]],
        },
        {
          type: "Polygon",
          id: 27115,
          arcs: [[592, 593, 594, 595, 596, -429, -475]],
        },
        {
          type: "Polygon",
          id: 23019,
          arcs: [[597, 598, 599, 600, -564, -569, -358]],
        },
        {
          type: "Polygon",
          id: 53059,
          arcs: [[-421, 601, 602, 603, 604, 605, -469]],
        },
        {
          type: "Polygon",
          id: 53015,
          arcs: [[-606, 606, 607, 608, 609, -470]],
        },
        { type: "Polygon", id: 53069, arcs: [[-471, -610, 610, -467]] },
        {
          type: "Polygon",
          id: 27153,
          arcs: [[-354, 611, 612, 613, -505, -462]],
        },
        {
          type: "Polygon",
          id: 27097,
          arcs: [[-465, 614, 615, 616, -612, -353]],
        },
        {
          type: "Polygon",
          id: 55125,
          arcs: [[617, 618, -559, -478, -477, -591, 619]],
        },
        { type: "Polygon", id: 41007, arcs: [[620, 621, 622]] },
        {
          type: "Polygon",
          id: 38001,
          arcs: [[-510, -585, 623, 624, 625, 626, -539, -544]],
        },
        { type: "Polygon", id: 38081, arcs: [[-553, 627, 628, 629, -549]] },
        {
          type: "Polygon",
          id: 38051,
          arcs: [[-543, 630, 631, 632, -530, -534]],
        },
        {
          type: "Polygon",
          id: 38021,
          arcs: [[-550, -630, 633, 634, -631, -542]],
        },
        { type: "Polygon", id: 38011, arcs: [[-627, 635, -516, -540]] },
        {
          type: "Polygon",
          id: 30023,
          arcs: [[-573, 636, 637, -526, -460, -332]],
        },
        { type: "Polygon", id: 26043, arcs: [[638, 639, 640, -587, 641]] },
        {
          type: "Polygon",
          id: 27095,
          arcs: [[642, 643, 644, 645, -615, -464, -431]],
        },
        { type: "Polygon", id: 30097, arcs: [[-495, 646, 647, -424, -497]] },
        {
          type: "Polygon",
          id: 30031,
          arcs: [[-426, 648, 649, 650, 651, 652, -570, -472]],
        },
        {
          type: "Polygon",
          id: 30067,
          arcs: [[-648, 653, 654, 655, -649, -425]],
        },
        { type: "Polygon", id: 30093, arcs: [[656, 657, -637, -572]] },
        {
          type: "Polygon",
          id: 41009,
          arcs: [[658, 659, 660, -621, 661, -608]],
        },
        { type: "Polygon", id: 27065, arcs: [[-597, 662, -643, -430]] },
        {
          type: "Polygon",
          id: 55013,
          arcs: [[663, 664, 665, 666, -595, -594, 667]],
        },
        { type: "Polygon", id: 55113, arcs: [[668, 669, 670, 671, 672]] },
        { type: "Polygon", id: 55129, arcs: [[-672, 673, -664, 674]] },
        {
          type: "Polygon",
          id: 30011,
          arcs: [[675, 676, 677, 678, -452, -518]],
        },
        { type: "Polygon", id: 30095, arcs: [[-581, 679, -654, -647, -494]] },
        { type: "Polygon", id: 27051, arcs: [[-507, 680, 681, 682, -545]] },
        { type: "Polygon", id: 27041, arcs: [[-614, 683, 684, -681, -506]] },
        {
          type: "Polygon",
          id: 55041,
          arcs: [[685, 686, 687, 688, 689, -620, -590]],
        },
        { type: "Polygon", id: 53011, arcs: [[-605, 690, -659, -607]] },
        {
          type: "Polygon",
          id: 53039,
          arcs: [[-504, 691, 692, 693, 694, 695, -602, -420]],
        },
        {
          type: "Polygon",
          id: 30003,
          arcs: [[-456, 696, 697, 698, 699, -579, -582]],
        },
        {
          type: "Polygon",
          id: 27155,
          arcs: [[-683, 700, 701, 702, -551, -546]],
        },
        { type: "Polygon", id: 55037, arcs: [[-588, -641, 703, -686, -589]] },
        {
          type: "Polygon",
          id: 41059,
          arcs: [[-555, 704, 705, 706, 707, -502, -557]],
        },
        {
          type: "Polygon",
          id: 41063,
          arcs: [[-513, -583, -536, -523, 708, 709, 710, -705, -554]],
        },
        { type: "Polygon", id: 26109, arcs: [[711, 712, 713, 714, -639, 715]] },
        {
          type: "Polygon",
          id: 55099,
          arcs: [[-619, 716, 717, 718, 719, -670, 720, -560]],
        },
        { type: "Polygon", id: 46105, arcs: [[721, 722, 723, 724, 725, -625]] },
        {
          type: "Polygon",
          id: 46031,
          arcs: [[726, 727, 728, 729, -722, -624, -584]],
        },
        {
          type: "Polygon",
          id: 46063,
          arcs: [[-626, -726, 730, -676, -517, -636]],
        },
        { type: "Polygon", id: 46021, arcs: [[-633, 731, 732, -727, -531]] },
        {
          type: "Polygon",
          id: 30001,
          arcs: [[-658, 733, 734, 735, 736, -527, -638]],
        },
        {
          type: "Polygon",
          id: 46089,
          arcs: [[-635, 737, 738, 739, -732, -632]],
        },
        {
          type: "Polygon",
          id: 46013,
          arcs: [[-629, 740, 741, 742, 743, 744, -738, -634]],
        },
        {
          type: "Polygon",
          id: 46109,
          arcs: [[-703, 745, 746, 747, 748, -552]],
        },
        { type: "Polygon", id: 46091, arcs: [[-749, 749, -741, -628]] },
        {
          type: "Polygon",
          id: 41049,
          arcs: [[-708, 750, 751, 752, -692, -503]],
        },
        { type: "Polygon", id: 55085, arcs: [[-690, 753, 754, -717, -618]] },
        { type: "Polygon", id: 41061, arcs: [[755, 756, -706, -711]] },
        { type: "Polygon", id: 30057, arcs: [[-653, 757, -734, -657, -571]] },
        { type: "Polygon", id: 27009, arcs: [[-646, 758, 759, -616]] },
        { type: "Polygon", id: 41021, arcs: [[760, 761, 762, -693, -753]] },
        {
          type: "Polygon",
          id: 30075,
          arcs: [[-679, 763, 764, 765, -697, -455, -453]],
        },
        { type: "Polygon", id: 26031, arcs: [[766, 767, 768, 769, 770, 771]] },
        { type: "Polygon", id: 41057, arcs: [[772, 773, 774, 775, 776, -622]] },
        { type: "Polygon", id: 41067, arcs: [[777, 778, 779, -773, -661]] },
        {
          type: "Polygon",
          id: 27145,
          arcs: [[-760, 780, 781, 782, 783, 784, -684, -613, -617]],
        },
        { type: "Polygon", id: 27149, arcs: [[785, 786, 787, -701, -682]] },
        { type: "Polygon", id: 27121, arcs: [[-785, 788, 789, -786, -685]] },
        { type: "Polygon", id: 41055, arcs: [[790, -694, -763]] },
        { type: "Polygon", id: 27059, arcs: [[791, 792, 793, -644, -663]] },
        {
          type: "Polygon",
          id: 27025,
          arcs: [[-667, 794, 795, 796, -792, -596]],
        },
        { type: "Polygon", id: 55095, arcs: [[797, 798, 799, -795, -666]] },
        {
          type: "Polygon",
          id: 41051,
          arcs: [[-691, -604, 800, 801, -778, -660]],
        },
        { type: "Polygon", id: 41027, arcs: [[802, 803, -801, -603, -696]] },
        {
          type: "Polygon",
          id: 41065,
          arcs: [[-791, -762, 804, 805, 806, 807, -803, -695]],
        },
        {
          type: "Polygon",
          id: 16059,
          arcs: [[-737, 808, 809, 810, 811, -520, -528]],
        },
        { type: "Polygon", id: 23029, arcs: [[812, -598, -357, 813]] },
        { type: "Polygon", id: 23007, arcs: [[814, 815, 816, 817, -567]] },
        { type: "Polygon", id: 26141, arcs: [[818, 819, -767, 820]] },
        {
          type: "Polygon",
          id: 55005,
          arcs: [[-674, 821, 822, 823, -798, -665]],
        },
        { type: "Polygon", id: 55107, arcs: [[-720, 824, 825, -822, -671]] },
        {
          type: "Polygon",
          id: 30009,
          arcs: [[-700, 826, 827, -655, -680, -580]],
        },
        {
          type: "Polygon",
          id: 46129,
          arcs: [[-740, 828, 829, 830, -728, -733]],
        },
        { type: "Polygon", id: 46045, arcs: [[-745, 831, 832, -829, -739]] },
        {
          type: "Polygon",
          id: 46037,
          arcs: [[-750, -748, 833, 834, 835, 836, -742]],
        },
        {
          type: "Polygon",
          id: 27011,
          arcs: [[-702, -788, 837, 838, 839, 840, -746]],
        },
        {
          type: "Polygon",
          id: 27141,
          arcs: [[-645, -794, 841, 842, 843, -781, -759]],
        },
        { type: "Polygon", id: 55069, arcs: [[844, 845, 846, -718, -755]] },
        {
          type: "Polygon",
          id: 46041,
          arcs: [[-831, 847, 848, 849, 850, -729]],
        },
        { type: "Polygon", id: 46137, arcs: [[-851, 851, 852, -723, -730]] },
        {
          type: "Polygon",
          id: 55067,
          arcs: [[-689, 853, 854, 855, 856, -845, -754]],
        },
        {
          type: "Polygon",
          id: 41005,
          arcs: [[-804, -808, 857, 858, -779, -802]],
        },
        {
          type: "Polygon",
          id: 41071,
          arcs: [[-859, 859, 860, 861, -774, -780]],
        },
        {
          type: "Polygon",
          id: 27171,
          arcs: [[-844, 862, 863, 864, 865, -782]],
        },
        {
          type: "Polygon",
          id: 27003,
          arcs: [[-797, 866, 867, 868, -842, -793]],
        },
        {
          type: "Polygon",
          id: 27067,
          arcs: [[869, 870, 871, 872, -789, -784]],
        },
        {
          type: "Polygon",
          id: 27151,
          arcs: [[-873, 873, 874, -838, -787, -790]],
        },
        {
          type: "Polygon",
          id: 55119,
          arcs: [[-847, 875, 876, 877, -825, -719]],
        },
        {
          type: "Polygon",
          id: 55083,
          arcs: [[878, 879, 880, 881, 882, 883, 884, -854, -688]],
        },
        {
          type: "Polygon",
          id: 23017,
          arcs: [[-817, 885, 886, 887, 888, -889, 889, 890, 891]],
        },
        {
          type: "Polygon",
          id: 46051,
          arcs: [[-841, 892, 893, 894, -834, -747]],
        },
        { type: "Polygon", id: 27093, arcs: [[-866, 895, 896, -870, -783]] },
        { type: "Polygon", id: 33007, arcs: [[897, 898, 899, 900, -891]] },
        {
          type: "Polygon",
          id: 27163,
          arcs: [[-800, 901, 902, 903, 904, -867, -796]],
        },
        {
          type: "Polygon",
          id: 55017,
          arcs: [[-878, 905, 906, 907, -823, -826]],
        },
        {
          type: "Polygon",
          id: 41047,
          arcs: [[-860, -858, -807, 908, 909, 910, -861]],
        },
        {
          type: "Polygon",
          id: 16003,
          arcs: [[911, 912, 913, 914, -709, -522]],
        },
        {
          type: "Polygon",
          id: 27073,
          arcs: [[-839, -875, 915, 916, 917, -893, -840]],
        },
        {
          type: "MultiPolygon",
          id: 23009,
          arcs: [[[918]], [[919]], [[920, -599, -813]]],
        },
        {
          type: "Polygon",
          id: 46107,
          arcs: [[-833, 921, 922, 923, -848, -830]],
        },
        {
          type: "Polygon",
          id: 46049,
          arcs: [[-744, 924, 925, 926, -922, -832]],
        },
        {
          type: "Polygon",
          id: 27053,
          arcs: [[-843, -869, 927, 928, 929, 930, -863]],
        },
        {
          type: "Polygon",
          id: 46115,
          arcs: [[-837, 931, 932, 933, -925, -743]],
        },
        {
          type: "Polygon",
          id: 16085,
          arcs: [[-812, 934, 935, 936, -912, -521]],
        },
        {
          type: "Polygon",
          id: 46019,
          arcs: [[-725, 937, 938, 939, -677, -731]],
        },
        { type: "Polygon", id: 55109, arcs: [[940, 941, -902, -799]] },
        {
          type: "Polygon",
          id: 55033,
          arcs: [[-908, 942, 943, 944, -941, -824]],
        },
        { type: "Polygon", id: 26009, arcs: [[945, 946, 947, 948, 949]] },
        { type: "Polygon", id: 26137, arcs: [[950, 951, -946, 952, -769]] },
        { type: "Polygon", id: 26119, arcs: [[953, 954, -951, -768, -820]] },
        {
          type: "Polygon",
          id: 46025,
          arcs: [[955, 956, 957, 958, -932, -836]],
        },
        { type: "Polygon", id: 46029, arcs: [[-895, 959, 960, -956, -835]] },
        { type: "Polygon", id: 27023, arcs: [[-872, 961, 962, -916, -874]] },
        { type: "Polygon", id: 27123, arcs: [[-905, 963, -928, -868]] },
        {
          type: "Polygon",
          id: 55073,
          arcs: [[-846, -857, 964, 965, 966, 967, -876]],
        },
        { type: "Polygon", id: 55078, arcs: [[-885, 968, -855]] },
        {
          type: "Polygon",
          id: 41001,
          arcs: [[-915, 969, 970, 971, -756, -710]],
        },
        {
          type: "Polygon",
          id: 41053,
          arcs: [[-911, 972, 973, 974, -775, -862]],
        },
        {
          type: "Polygon",
          id: 41069,
          arcs: [[-752, 975, 976, 977, -805, -761]],
        },
        { type: "Polygon", id: 41041, arcs: [[-975, 978, 979, 980, -776]] },
        {
          type: "Polygon",
          id: 46093,
          arcs: [[-853, 981, 982, 983, -938, -724]],
        },
        {
          type: "Polygon",
          id: 55019,
          arcs: [[-968, 984, 985, 986, -906, -877]],
        },
        {
          type: "Polygon",
          id: 55115,
          arcs: [[-969, -884, 987, 988, 989, -965, -856]],
        },
        { type: "Polygon", id: 50011, arcs: [[990, 991, 992, 993, 994]] },
        { type: "Polygon", id: 50009, arcs: [[995, 996, 997, 998, -900]] },
        { type: "Polygon", id: 50013, arcs: [[999, 1000, 1001, -994]] },
        { type: "Polygon", id: 36019, arcs: [[1002, 1003, 1004, 1005, -1001]] },
        { type: "Polygon", id: 50019, arcs: [[-998, 1006, 1007, -991, 1008]] },
        {
          type: "Polygon",
          id: 56029,
          arcs: [[1009, 1010, 1011, 1012, 1013, -650, -656, -828]],
        },
        {
          type: "Polygon",
          id: 36089,
          arcs: [[1014, 1015, 1016, 1017, 1018, 1019]],
        },
        {
          type: "Polygon",
          id: 56003,
          arcs: [[1020, 1021, 1022, -1010, -827, -699]],
        },
        {
          type: "Polygon",
          id: 56005,
          arcs: [[1023, 1024, 1025, 1026, 1027, -765]],
        },
        {
          type: "Polygon",
          id: 56033,
          arcs: [[-766, -1028, 1028, -1021, -698]],
        },
        {
          type: "Polygon",
          id: 36033,
          arcs: [[-1005, 1029, 1030, -1015, 1031]],
        },
        {
          type: "Polygon",
          id: 56011,
          arcs: [[-678, -940, 1032, 1033, -1024, -764]],
        },
        {
          type: "Polygon",
          id: 41023,
          arcs: [[-757, -972, 1034, 1035, 1036, -976, -751, -707]],
        },
        { type: "Polygon", id: 27085, arcs: [[-865, 1037, 1038, 1039, -896]] },
        { type: "Polygon", id: 27019, arcs: [[-931, 1040, 1041, -1038, -864]] },
        {
          type: "Polygon",
          id: 46039,
          arcs: [[-918, 1042, 1043, 1044, 1045, -960, -894]],
        },
        {
          type: "Polygon",
          id: 27173,
          arcs: [[1046, 1047, 1048, 1049, -1043, -917, -963]],
        },
        {
          type: "Polygon",
          id: 27037,
          arcs: [[-904, 1050, 1051, 1052, 1053, -929, -964]],
        },
        { type: "Polygon", id: 46119, arcs: [[1054, 1055, 1056, -849, -924]] },
        {
          type: "Polygon",
          id: 46069,
          arcs: [[-927, 1057, 1058, 1059, 1060, -1055, -923]],
        },
        {
          type: "Polygon",
          id: 46059,
          arcs: [[-934, 1061, 1062, 1063, -1058, -926]],
        },
        {
          type: "Polygon",
          id: 27129,
          arcs: [[-897, -1040, 1064, 1065, 1066, 1067, -1047, -962, -871]],
        },
        {
          type: "Polygon",
          id: 16037,
          arcs: [[1068, 1069, 1070, 1071, 1072, -935, -811]],
        },
        {
          type: "Polygon",
          id: 55093,
          arcs: [[-945, 1073, 1074, -1051, -903, -942]],
        },
        { type: "Polygon", id: 26001, arcs: [[1075, 1076, 1077, 1078]] },
        { type: "Polygon", id: 26079, arcs: [[1079, 1080, 1081, -947]] },
        { type: "Polygon", id: 26039, arcs: [[1082, 1083, -1080, -952]] },
        {
          type: "Polygon",
          id: 55035,
          arcs: [[-987, 1084, 1085, 1086, 1087, -943, -907]],
        },
        { type: "Polygon", id: 26135, arcs: [[-1078, 1088, -1083, -955]] },
        { type: "Polygon", id: 16087, arcs: [[1089, 1090, 1091, -970, -914]] },
        {
          type: "Polygon",
          id: 41031,
          arcs: [[-978, 1092, 1093, 1094, -909, -806]],
        },
        {
          type: "Polygon",
          id: 27139,
          arcs: [[-1054, 1095, 1096, 1097, -1041, -930]],
        },
        { type: "Polygon", id: 46057, arcs: [[-1046, 1098, 1099, -957, -961]] },
        { type: "Polygon", id: 50015, arcs: [[1100, 1101, 1102, -992, -1008]] },
        {
          type: "Polygon",
          id: 41043,
          arcs: [[-1095, 1103, 1104, 1105, -973, -910]],
        },
        {
          type: "Polygon",
          id: 46117,
          arcs: [[-1057, 1106, 1107, 1108, 1109, -850]],
        },
        { type: "Polygon", id: 26019, arcs: [[1110, 1111, 1112, 1113]] },
        {
          type: "Polygon",
          id: 50005,
          arcs: [[1114, 1115, 1116, -1101, -1007, -997]],
        },
        {
          type: "MultiPolygon",
          id: 23027,
          arcs: [[[-601, 1117, 1118, 1119, 1120, -565]]],
        },
        {
          type: "Polygon",
          id: 16043,
          arcs: [[1121, 1122, 1123, 1124, 1125, -735, -758, -652]],
        },
        {
          type: "Polygon",
          id: 46055,
          arcs: [[1126, 1127, 1128, -982, -852, -1110]],
        },
        {
          type: "Polygon",
          id: 50007,
          arcs: [[-1103, 1129, 1130, 1131, -1003, -1000, -993]],
        },
        { type: "Polygon", id: 41003, arcs: [[-1106, 1132, -979, -974]] },
        {
          type: "Polygon",
          id: 23011,
          arcs: [[1133, 1134, 1135, 1136, -815, -566, -1121]],
        },
        {
          type: "Polygon",
          id: 27143,
          arcs: [[-1042, -1098, 1137, 1138, -1065, -1039]],
        },
        {
          type: "Polygon",
          id: 27049,
          arcs: [[1139, 1140, 1141, 1142, 1143, -1052, -1075]],
        },
        {
          type: "Polygon",
          id: 27127,
          arcs: [[1144, 1145, 1146, 1147, -1048, -1068]],
        },
        { type: "Polygon", id: 55097, arcs: [[1148, 1149, 1150, 1151, -966]] },
        {
          type: "Polygon",
          id: 55141,
          arcs: [[-1152, 1152, 1153, 1154, -985, -967]],
        },
        {
          type: "Polygon",
          id: 55009,
          arcs: [[-883, 1155, 1156, 1157, 1158, 1159, -988]],
        },
        {
          type: "Polygon",
          id: 55091,
          arcs: [[-1088, 1160, 1161, -1140, -1074, -944]],
        },
        { type: "Polygon", id: 55135, arcs: [[1162, 1163, 1164, -1149, -990]] },
        { type: "Polygon", id: 55061, arcs: [[1165, 1166, -1157, 1167, 1168]] },
        {
          type: "Polygon",
          id: 56039,
          arcs: [[1169, 1170, 1171, 1172, 1173, -1122, -651, -1014]],
        },
        {
          type: "Polygon",
          id: 46005,
          arcs: [[-959, 1174, 1175, 1176, -1062, -933]],
        },
        {
          type: "Polygon",
          id: 27081,
          arcs: [[1177, 1178, 1179, -1044, -1050]],
        },
        {
          type: "Polygon",
          id: 27083,
          arcs: [[-1148, 1180, 1181, -1178, -1049]],
        },
        { type: "Polygon", id: 46081, arcs: [[-984, 1182, 1183, -1033, -939]] },
        {
          type: "Polygon",
          id: 55011,
          arcs: [[-1087, 1184, 1185, 1186, -1161]],
        },
        {
          type: "Polygon",
          id: 55121,
          arcs: [[1187, 1188, 1189, 1190, -1185, -1086]],
        },
        {
          type: "Polygon",
          id: 55053,
          arcs: [[-986, -1155, 1191, 1192, 1193, -1188, -1085]],
        },
        {
          type: "Polygon",
          id: 55087,
          arcs: [[-989, -1160, 1194, 1195, -1163]],
        },
        { type: "Polygon", id: 16033, arcs: [[-1126, 1196, 1197, -809, -736]] },
        {
          type: "Polygon",
          id: 56019,
          arcs: [[-1027, 1198, 1199, 1200, -1022, -1029]],
        },
        {
          type: "Polygon",
          id: 41013,
          arcs: [[-977, -1037, 1201, 1202, -1093]],
        },
        { type: "Polygon", id: 46065, arcs: [[-1061, 1203, -1107, -1056]] },
        {
          type: "Polygon",
          id: 36031,
          arcs: [[1204, 1205, 1206, 1207, -1030, -1004, -1132]],
        },
        {
          type: "Polygon",
          id: 27079,
          arcs: [[1208, 1209, 1210, 1211, -1138, -1097]],
        },
        {
          type: "Polygon",
          id: 27131,
          arcs: [[-1053, -1144, 1212, 1213, 1214, -1209, -1096]],
        },
        {
          type: "Polygon",
          id: 46077,
          arcs: [[-1100, 1215, 1216, 1217, 1218, -1175, -958]],
        },
        {
          type: "Polygon",
          id: 46011,
          arcs: [[-1180, 1219, 1220, 1221, -1216, -1099, -1045]],
        },
        { type: "Polygon", id: 26101, arcs: [[1222, 1223, 1224, 1225, -1112]] },
        { type: "Polygon", id: 26165, arcs: [[1226, 1227, 1228, -1223, 1229]] },
        { type: "Polygon", id: 26143, arcs: [[1230, 1231, 1232, 1233, -1084]] },
        {
          type: "Polygon",
          id: 26113,
          arcs: [[-1234, 1234, 1235, -1227, -1081]],
        },
        { type: "Polygon", id: 26069, arcs: [[1236, 1237, 1238, -1077]] },
        {
          type: "Polygon",
          id: 26129,
          arcs: [[-1239, 1239, 1240, -1231, -1089]],
        },
        {
          type: "Polygon",
          id: 16045,
          arcs: [[-937, 1241, 1242, 1243, 1244, -1090, -913]],
        },
        {
          type: "Polygon",
          id: 46103,
          arcs: [[-1129, 1245, 1246, 1247, 1248, -1183, -983]],
        },
        {
          type: "Polygon",
          id: 50023,
          arcs: [[1249, 1250, -1130, -1102, -1117]],
        },
        {
          type: "Polygon",
          id: 27015,
          arcs: [[1251, 1252, 1253, 1254, -1145, -1067]],
        },
        { type: "Polygon", id: 23001, arcs: [[1255, 1256, -886, -816, -1137]] },
        {
          type: "Polygon",
          id: 27103,
          arcs: [[1257, -1252, -1066, -1139, -1212]],
        },
        {
          type: "Polygon",
          id: 27157,
          arcs: [[-1162, -1187, 1258, 1259, -1141]],
        },
        {
          type: "Polygon",
          id: 41045,
          arcs: [[-1092, 1260, 1261, 1262, 1263, 1264, -1035, -971]],
        },
        {
          type: "Polygon",
          id: 33009,
          arcs: [[1265, 1266, 1267, 1268, 1269, 1270, -1115, -996, -899]],
        },
        {
          type: "Polygon",
          id: 41017,
          arcs: [[-1203, 1271, 1272, 1273, 1274, -1104, -1094]],
        },
        {
          type: "Polygon",
          id: 36045,
          arcs: [[1275, 1276, -1019, 1277, 1278, 1279, 1280, 1281]],
        },
        { type: "MultiPolygon", id: 23013, arcs: [[[1282, 1283, -1119]]] },
        {
          type: "Polygon",
          id: 16015,
          arcs: [[-1073, 1284, 1285, -1242, -936]],
        },
        {
          type: "Polygon",
          id: 23015,
          arcs: [[-1120, -1284, 1286, 1287, 1288, -1134]],
        },
        {
          type: "Polygon",
          id: 55071,
          arcs: [[-1167, 1289, 1290, 1291, -1158]],
        },
        {
          type: "Polygon",
          id: 50001,
          arcs: [[-1251, 1292, 1293, 1294, 1295, -1205, -1131]],
        },
        {
          type: "Polygon",
          id: 41039,
          arcs: [[-1275, 1296, 1297, 1298, -980, -1133, -1105]],
        },
        {
          type: "Polygon",
          id: 33003,
          arcs: [[-890, 888, 1299, 1300, 1301, -1266, -898]],
        },
        {
          type: "Polygon",
          id: 27013,
          arcs: [[-1211, 1302, 1303, 1304, 1305, -1253, -1258]],
        },
        {
          type: "Polygon",
          id: 55057,
          arcs: [[1306, 1307, 1308, 1309, -1192, -1154]],
        },
        {
          type: "Polygon",
          id: 55001,
          arcs: [[-1151, 1310, 1311, 1312, -1307, -1153]],
        },
        {
          type: "Polygon",
          id: 55137,
          arcs: [[-1165, 1313, 1314, 1315, -1311, -1150]],
        },
        {
          type: "Polygon",
          id: 55139,
          arcs: [[1316, 1317, 1318, -1314, -1164, -1196]],
        },
        {
          type: "Polygon",
          id: 55015,
          arcs: [[-1159, -1292, 1319, 1320, -1317, -1195]],
        },
        {
          type: "Polygon",
          id: 16023,
          arcs: [[-1198, 1321, 1322, 1323, -1070, -1069, -810]],
        },
        {
          type: "Polygon",
          id: 50017,
          arcs: [[-1271, 1324, -1293, -1250, -1116]],
        },
        {
          type: "Polygon",
          id: 36049,
          arcs: [[1325, 1326, -1278, -1018, 1327]],
        },
        {
          type: "Polygon",
          id: 46085,
          arcs: [[1328, 1329, 1330, 1331, 1332, -1108, -1204, -1060, 1333]],
        },
        {
          type: "Polygon",
          id: 27117,
          arcs: [[-1182, 1334, 1335, 1336, -1220, -1179]],
        },
        {
          type: "Polygon",
          id: 27101,
          arcs: [[-1147, 1337, 1338, 1339, -1335, -1181]],
        },
        {
          type: "Polygon",
          id: 46073,
          arcs: [[-1177, 1340, 1341, 1342, 1343, -1063]],
        },
        { type: "Polygon", id: 27147, arcs: [[1344, 1345, 1346, 1347, -1214]] },
        {
          type: "Polygon",
          id: 27039,
          arcs: [[1348, 1349, -1345, -1213, -1143]],
        },
        { type: "Polygon", id: 46101, arcs: [[-1337, 1350, 1351, -1221]] },
        {
          type: "Polygon",
          id: 27161,
          arcs: [[-1348, 1352, 1353, -1303, -1210, -1215]],
        },
        {
          type: "Polygon",
          id: 46017,
          arcs: [[-1344, 1354, -1334, -1059, -1064]],
        },
        {
          type: "Polygon",
          id: 46111,
          arcs: [[-1219, 1355, 1356, 1357, 1358, -1341, -1176]],
        },
        {
          type: "Polygon",
          id: 27109,
          arcs: [[-1260, 1359, 1360, 1361, -1349, -1142]],
        },
        {
          type: "Polygon",
          id: 27033,
          arcs: [[-1255, 1362, 1363, 1364, -1338, -1146]],
        },
        {
          type: "Polygon",
          id: 46079,
          arcs: [[-1222, -1352, 1365, 1366, 1367, -1217]],
        },
        {
          type: "Polygon",
          id: 46097,
          arcs: [[-1368, 1368, 1369, -1356, -1218]],
        },
        {
          type: "Polygon",
          id: 27169,
          arcs: [[-1186, -1191, 1370, 1371, 1372, -1360, -1259]],
        },
        {
          type: "Polygon",
          id: 56045,
          arcs: [[-1184, -1249, 1373, 1374, 1375, -1025, -1034]],
        },
        { type: "Polygon", id: 26105, arcs: [[1376, 1377, 1378, -1225]] },
        {
          type: "Polygon",
          id: 23005,
          arcs: [[-1257, 1379, 1380, 1381, 1382, 1383, -887]],
        },
        {
          type: "Polygon",
          id: 46075,
          arcs: [[-1333, 1384, 1385, -1386, 1386, -1127, -1109]],
        },
        {
          type: "Polygon",
          id: 56043,
          arcs: [[-1201, 1387, 1388, 1389, -1011, -1023]],
        },
        {
          type: "MultiPolygon",
          id: 23023,
          arcs: [
            [[-1382, 1390]],
            [[-1288, 1391]],
            [[1392, -1380, -1256, -1136]],
          ],
        },
        {
          type: "Polygon",
          id: 26085,
          arcs: [[-1224, -1229, 1393, 1394, -1377]],
        },
        {
          type: "Polygon",
          id: 26133,
          arcs: [[-1236, 1395, 1396, -1394, -1228]],
        },
        {
          type: "Polygon",
          id: 26035,
          arcs: [[-1233, 1397, 1398, -1396, -1235]],
        },
        {
          type: "Polygon",
          id: 26051,
          arcs: [[1399, 1400, 1401, -1398, -1232, -1241]],
        },
        { type: "Polygon", id: 55081, arcs: [[-1310, 1402, 1403, -1193]] },
        { type: "Polygon", id: 16075, arcs: [[-1245, 1404, -1261, -1091]] },
        {
          type: "Polygon",
          id: 36041,
          arcs: [[1405, 1406, 1407, 1408, -1016, -1031, -1208]],
        },
        {
          type: "Polygon",
          id: 27165,
          arcs: [[-1306, 1409, 1410, -1363, -1254]],
        },
        {
          type: "Polygon",
          id: 16039,
          arcs: [[-1072, 1411, 1412, 1413, 1414, 1415, 1416, -1285]],
        },
        {
          type: "Polygon",
          id: 36043,
          arcs: [[-1409, 1417, 1418, 1419, 1420, -1328, -1017]],
        },
        {
          type: "Polygon",
          id: 55063,
          arcs: [[-1194, -1404, 1421, 1422, -1371, -1190, -1189]],
        },
        { type: "Polygon", id: 56017, arcs: [[-1390, 1423, -1012]] },
        {
          type: "Polygon",
          id: 16051,
          arcs: [[-1125, 1424, 1425, 1426, -1322, -1197]],
        },
        {
          type: "Polygon",
          id: 41025,
          arcs: [[-1265, 1427, 1428, 1429, -1272, -1202, -1036]],
        },
        {
          type: "Polygon",
          id: 56013,
          arcs: [[-1424, -1389, 1430, 1431, 1432, 1433, -1170, -1013]],
        },
        {
          type: "Polygon",
          id: 26017,
          arcs: [[1434, 1435, 1436, 1437, -1401, 1438]],
        },
        {
          type: "Polygon",
          id: 46071,
          arcs: [[-1387, 1385, 1439, 1440, 1441, -1246, -1128]],
        },
        {
          type: "Polygon",
          id: 16013,
          arcs: [[-1324, 1442, 1443, 1444, 1445, 1446, 1447, -1412, -1071]],
        },
        { type: "Polygon", id: 16081, arcs: [[-1174, 1448, 1449, -1123]] },
        {
          type: "Polygon",
          id: 55047,
          arcs: [[-1319, 1450, 1451, 1452, 1453, -1315]],
        },
        { type: "Polygon", id: 55077, arcs: [[-1454, 1454, -1312, -1316]] },
        {
          type: "Polygon",
          id: 50027,
          arcs: [[-1270, 1455, 1456, 1457, 1458, -1294, -1325]],
        },
        {
          type: "Polygon",
          id: 41019,
          arcs: [[1459, 1460, 1461, 1462, 1463, 1464, -1298]],
        },
        {
          type: "Polygon",
          id: 55039,
          arcs: [[1465, 1466, 1467, -1451, -1318, -1321]],
        },
        {
          type: "Polygon",
          id: 46003,
          arcs: [[-1359, 1468, 1469, 1470, 1471, -1342]],
        },
        {
          type: "Polygon",
          id: 46015,
          arcs: [[-1472, 1472, -1329, -1355, -1343]],
        },
        { type: "Polygon", id: 16065, arcs: [[-1450, 1473, -1425, -1124]] },
        {
          type: "Polygon",
          id: 55117,
          arcs: [[1474, 1475, 1476, -1466, -1320, -1291]],
        },
        {
          type: "Polygon",
          id: 16027,
          arcs: [[-1244, 1477, 1478, -1262, -1405]],
        },
        {
          type: "Polygon",
          id: 46095,
          arcs: [[-1332, 1479, 1480, -1440, -1386, -1385]],
        },
        { type: "Polygon", id: 16025, arcs: [[1481, 1482, -1413, -1448]] },
        { type: "Polygon", id: 50021, arcs: [[1483, 1484, -1295, -1459]] },
        {
          type: "Polygon",
          id: 46033,
          arcs: [[1485, 1486, 1487, -1374, -1248]],
        },
        {
          type: "Polygon",
          id: 27133,
          arcs: [[-1340, 1488, 1489, 1490, -1336]],
        },
        {
          type: "Polygon",
          id: 27105,
          arcs: [[-1365, 1491, 1492, 1493, -1489, -1339]],
        },
        {
          type: "Polygon",
          id: 27047,
          arcs: [[1494, 1495, 1496, 1497, -1353, -1347]],
        },
        {
          type: "Polygon",
          id: 27099,
          arcs: [[-1362, 1498, 1499, 1500, 1501, -1495, -1346, -1350]],
        },
        {
          type: "Polygon",
          id: 27055,
          arcs: [[-1423, 1502, 1503, 1504, 1505, -1372]],
        },
        {
          type: "Polygon",
          id: 46035,
          arcs: [[1506, 1507, 1508, -1469, -1358]],
        },
        {
          type: "Polygon",
          id: 46061,
          arcs: [[-1370, 1509, 1510, -1507, -1357]],
        },
        {
          type: "Polygon",
          id: 27045,
          arcs: [[-1373, -1506, 1511, 1512, -1499, -1361]],
        },
        {
          type: "Polygon",
          id: 27063,
          arcs: [[-1411, 1513, 1514, 1515, 1516, -1492, -1364]],
        },
        {
          type: "Polygon",
          id: 27043,
          arcs: [[-1354, -1498, 1517, 1518, 1519, -1304]],
        },
        {
          type: "Polygon",
          id: 46099,
          arcs: [[1520, 1521, 1522, 1523, -1366, -1351, -1491]],
        },
        {
          type: "Polygon",
          id: 27091,
          arcs: [[-1305, -1520, 1524, 1525, -1514, -1410]],
        },
        {
          type: "Polygon",
          id: 46087,
          arcs: [[-1367, -1524, 1526, 1527, -1510, -1369]],
        },
        {
          type: "Polygon",
          id: 26111,
          arcs: [[-1438, 1528, 1529, 1530, -1402]],
        },
        { type: "Polygon", id: 26127, arcs: [[1531, 1532, 1533, -1378]] },
        {
          type: "Polygon",
          id: 26073,
          arcs: [[-1531, 1534, 1535, 1536, -1399]],
        },
        {
          type: "Polygon",
          id: 23031,
          arcs: [[1537, 1538, -1300, -889, -888, -1384]],
        },
        { type: "Polygon", id: 26107, arcs: [[-1537, 1539, 1540, -1397]] },
        {
          type: "Polygon",
          id: 26123,
          arcs: [[-1541, 1541, 1542, 1543, -1532, -1395]],
        },
        {
          type: "Polygon",
          id: 36115,
          arcs: [[-1485, 1544, 1545, 1546, 1547, -1206, -1296]],
        },
        {
          type: "Polygon",
          id: 16001,
          arcs: [[-1286, -1417, 1548, -1478, -1243]],
        },
        { type: "Polygon", id: 36113, arcs: [[1549, -1406, -1207, -1548]] },
        {
          type: "Polygon",
          id: 46123,
          arcs: [[1550, 1551, 1552, -1480, -1331]],
        },
        { type: "Polygon", id: 33001, arcs: [[1553, 1554, -1267, -1302]] },
        {
          type: "Polygon",
          id: 26157,
          arcs: [[1555, 1556, 1557, 1558, 1559, -1436, 1560, 1561]],
        },
        {
          type: "Polygon",
          id: 55123,
          arcs: [[1562, 1563, 1564, 1565, -1503, -1422, -1403, -1309]],
        },
        {
          type: "Polygon",
          id: 36075,
          arcs: [[-1327, 1566, 1567, 1568, 1569, 1570, -1279]],
        },
        {
          type: "Polygon",
          id: 46113,
          arcs: [[1571, 1572, 1573, 1574, -1486, -1247, -1442]],
        },
        { type: "Polygon", id: 26151, arcs: [[1575, 1576, -1557, 1577, 1578]] },
        {
          type: "Polygon",
          id: 16073,
          arcs: [[-1549, -1416, 1579, 1580, 1581, -1263, -1479]],
        },
        {
          type: "Polygon",
          id: 55021,
          arcs: [[1582, 1583, 1584, -1313, -1455, -1453]],
        },
        {
          type: "Polygon",
          id: 55111,
          arcs: [[-1585, 1585, 1586, 1587, -1563, -1308]],
        },
        {
          type: "Polygon",
          id: 55027,
          arcs: [[1588, 1589, 1590, 1591, -1583, -1452, -1468]],
        },
        {
          type: "Polygon",
          id: 16019,
          arcs: [[-1474, -1449, -1173, 1592, 1593, 1594, -1426]],
        },
        {
          type: "Polygon",
          id: 16011,
          arcs: [[1595, 1596, 1597, -1443, -1323, -1427, -1595]],
        },
        {
          type: "Polygon",
          id: 41035,
          arcs: [[1598, 1599, 1600, 1601, -1460, -1297, -1274]],
        },
        {
          type: "Polygon",
          id: 41037,
          arcs: [[1602, 1603, -1599, -1273, -1430]],
        },
        {
          type: "Polygon",
          id: 36065,
          arcs: [[1604, 1605, -1567, -1326, -1421]],
        },
        {
          type: "Polygon",
          id: 33013,
          arcs: [[-1555, 1606, 1607, 1608, 1609, -1268]],
        },
        { type: "Polygon", id: 41011, arcs: [[1610, 1611, -1464]] },
        {
          type: "Polygon",
          id: 33019,
          arcs: [[-1610, 1612, 1613, 1614, -1456, -1269]],
        },
        {
          type: "Polygon",
          id: 33017,
          arcs: [[-1539, 1615, 1616, -1607, -1554, -1301]],
        },
        {
          type: "Polygon",
          id: 26145,
          arcs: [[-1560, 1617, 1618, 1619, -1529, -1437]],
        },
        {
          type: "Polygon",
          id: 55103,
          arcs: [[1620, 1621, 1622, -1564, -1588]],
        },
        { type: "Polygon", id: 55089, arcs: [[1623, 1624, 1625, -1476]] },
        {
          type: "Polygon",
          id: 55131,
          arcs: [[-1477, -1626, 1626, -1589, -1467]],
        },
        {
          type: "Polygon",
          id: 19189,
          arcs: [[1627, 1628, 1629, -1518, -1497]],
        },
        {
          type: "Polygon",
          id: 19109,
          arcs: [[1630, 1631, 1632, -1525, -1519, -1630, 1633]],
        },
        { type: "Polygon", id: 19059, arcs: [[1634, 1635, -1516, 1636]] },
        {
          type: "Polygon",
          id: 19063,
          arcs: [[1637, -1637, -1515, -1526, -1633]],
        },
        {
          type: "Polygon",
          id: 19195,
          arcs: [[1638, -1628, -1496, -1502, 1639]],
        },
        {
          type: "Polygon",
          id: 19143,
          arcs: [[1640, 1641, -1493, -1517, -1636]],
        },
        {
          type: "Polygon",
          id: 56027,
          arcs: [[-1488, 1642, 1643, 1644, 1645, 1646, -1375]],
        },
        {
          type: "Polygon",
          id: 19131,
          arcs: [[1647, 1648, -1640, -1501, 1649]],
        },
        {
          type: "Polygon",
          id: 19119,
          arcs: [[1650, 1651, -1521, -1490, -1494, -1642]],
        },
        {
          type: "Polygon",
          id: 19089,
          arcs: [[1652, 1653, -1650, -1500, -1513]],
        },
        {
          type: "Polygon",
          id: 19005,
          arcs: [[-1566, 1654, 1655, 1656, -1504]],
        },
        {
          type: "Polygon",
          id: 19191,
          arcs: [[1657, 1658, -1653, -1512, -1505, -1657]],
        },
        {
          type: "Polygon",
          id: 46083,
          arcs: [[-1652, 1659, 1660, 1661, 1662, -1522]],
        },
        {
          type: "Polygon",
          id: 56009,
          arcs: [[-1647, 1663, 1664, 1665, 1666, -1199, -1026, -1376]],
        },
        {
          type: "Polygon",
          id: 46023,
          arcs: [[-1471, 1667, 1668, 1669, 1670, 1671, 1672, -1473]],
        },
        {
          type: "Polygon",
          id: 46125,
          arcs: [[-1523, -1663, 1673, 1674, 1675, -1527]],
        },
        {
          type: "Polygon",
          id: 46067,
          arcs: [[-1528, -1676, 1676, 1677, -1669, 1678, -1508, -1511]],
        },
        {
          type: "Polygon",
          id: 56025,
          arcs: [[-1200, -1667, 1679, -1431, -1388]],
        },
        { type: "Polygon", id: 46043, arcs: [[-1509, -1679, -1668, -1470]] },
        {
          type: "Polygon",
          id: 46053,
          arcs: [[-1673, 1680, 1681, -1551, -1330]],
        },
        {
          type: "Polygon",
          id: 46047,
          arcs: [[-1575, 1682, 1683, -1643, -1487]],
        },
        {
          type: "Polygon",
          id: 26121,
          arcs: [[-1544, 1684, 1685, 1686, -1533]],
        },
        {
          type: "Polygon",
          id: 26117,
          arcs: [[-1536, 1687, 1688, 1689, -1542, -1540]],
        },
        { type: "Polygon", id: 56035, arcs: [[1690, 1691, -1171, -1434]] },
        {
          type: "Polygon",
          id: 26057,
          arcs: [[-1530, -1620, 1692, 1693, -1688, -1535]],
        },
        {
          type: "Polygon",
          id: 55023,
          arcs: [[-1623, 1694, 1695, -1655, -1565]],
        },
        {
          type: "Polygon",
          id: 36011,
          arcs: [[1696, 1697, 1698, 1699, 1700, 1701, -1570]],
        },
        {
          type: "Polygon",
          id: 36091,
          arcs: [[-1547, 1702, 1703, 1704, 1705, 1706, -1407, -1550]],
        },
        { type: "Polygon", id: 46007, arcs: [[1707, 1708, -1572, -1441]] },
        { type: "Polygon", id: 46121, arcs: [[-1553, 1709, -1708, -1481]] },
        { type: "Polygon", id: 36073, arcs: [[1710, 1711, 1712, 1713]] },
        { type: "Polygon", id: 36063, arcs: [[-1713, 1714, 1715, 1716]] },
        {
          type: "Polygon",
          id: 36055,
          arcs: [[1717, 1718, 1719, 1720, -1711, 1721]],
        },
        {
          type: "Polygon",
          id: 36117,
          arcs: [[1722, 1723, -1718, 1724, -1701]],
        },
        {
          type: "Polygon",
          id: 26087,
          arcs: [[1725, 1726, 1727, 1728, -1558, -1577]],
        },
        {
          type: "Polygon",
          id: 56023,
          arcs: [[-1692, 1729, 1730, 1731, 1732, 1733, -1593, -1172]],
        },
        {
          type: "Polygon",
          id: 50003,
          arcs: [[-1458, 1734, 1735, 1736, 1737, -1545, -1484]],
        },
        {
          type: "Polygon",
          id: 55025,
          arcs: [[-1592, 1738, 1739, 1740, 1741, -1586, -1584]],
        },
        {
          type: "Polygon",
          id: 26081,
          arcs: [[1742, 1743, 1744, 1745, -1685, -1543, -1690]],
        },
        { type: "Polygon", id: 36035, arcs: [[-1707, 1746, -1418, -1408]] },
        {
          type: "Polygon",
          id: 33015,
          arcs: [[1747, 1748, 1749, -1608, -1617]],
        },
        { type: "Polygon", id: 36067, arcs: [[1750, 1751, -1697, -1569]] },
        {
          type: "Polygon",
          id: 50025,
          arcs: [[-1615, 1752, 1753, -1735, -1457]],
        },
        {
          type: "Polygon",
          id: 19167,
          arcs: [[1754, 1755, 1756, -1660, -1651]],
        },
        { type: "Polygon", id: 19141, arcs: [[1757, 1758, -1755, -1641]] },
        {
          type: "Polygon",
          id: 19033,
          arcs: [[-1649, 1759, 1760, 1761, -1639]],
        },
        { type: "Polygon", id: 19081, arcs: [[-1762, 1762, -1634, -1629]] },
        { type: "Polygon", id: 19147, arcs: [[-1632, 1763, 1764, -1638]] },
        { type: "Polygon", id: 19041, arcs: [[-1765, 1765, -1758, -1635]] },
        {
          type: "Polygon",
          id: 26049,
          arcs: [[-1729, 1766, 1767, 1768, -1618, -1559]],
        },
        {
          type: "Polygon",
          id: 19037,
          arcs: [[-1659, 1769, 1770, 1771, -1654]],
        },
        { type: "Polygon", id: 19067, arcs: [[-1772, 1772, -1760, -1648]] },
        {
          type: "Polygon",
          id: 55049,
          arcs: [[-1587, -1742, 1773, 1774, 1775, -1621]],
        },
        {
          type: "Polygon",
          id: 55043,
          arcs: [[-1776, 1776, 1777, 1778, 1779, -1695, -1622]],
        },
        {
          type: "Polygon",
          id: 33011,
          arcs: [[-1750, 1780, 1781, 1782, 1783, -1613, -1609]],
        },
        { type: "Polygon", id: 26139, arcs: [[-1746, 1784, 1785, -1686]] },
        {
          type: "Polygon",
          id: 16063,
          arcs: [[1786, 1787, 1788, -1482, -1447]],
        },
        {
          type: "Polygon",
          id: 16047,
          arcs: [[-1789, 1789, 1790, 1791, -1414, -1483]],
        },
        {
          type: "Polygon",
          id: 55055,
          arcs: [[1792, 1793, 1794, -1739, -1591]],
        },
        { type: "Polygon", id: 16067, arcs: [[1795, 1796, -1787, -1446]] },
        {
          type: "Polygon",
          id: 55133,
          arcs: [[-1627, 1797, 1798, 1799, -1793, -1590]],
        },
        { type: "Polygon", id: 55079, arcs: [[1800, 1801, -1798, -1625]] },
        {
          type: "Polygon",
          id: 36053,
          arcs: [[-1606, 1802, 1803, 1804, -1751, -1568]],
        },
        {
          type: "Polygon",
          id: 33005,
          arcs: [[-1784, 1805, 1806, -1753, -1614]],
        },
        {
          type: "Polygon",
          id: 46135,
          arcs: [[1807, 1808, 1809, 1810, -1677, -1675]],
        },
        { type: "Polygon", id: 46009, arcs: [[-1811, 1811, -1670, -1678]] },
        {
          type: "Polygon",
          id: 26155,
          arcs: [[-1769, 1812, 1813, 1814, -1693, -1619]],
        },
        {
          type: "Polygon",
          id: 36037,
          arcs: [[1815, 1816, 1817, -1715, -1712, -1721]],
        },
        {
          type: "Polygon",
          id: 26067,
          arcs: [[1818, 1819, 1820, -1743, -1689]],
        },
        {
          type: "Polygon",
          id: 26037,
          arcs: [[-1815, 1821, 1822, -1819, -1694]],
        },
        {
          type: "Polygon",
          id: 16077,
          arcs: [[-1598, 1823, 1824, 1825, -1444]],
        },
        {
          type: "MultiPolygon",
          id: 36029,
          arcs: [[[1826]], [[-1818, 1827, 1828, 1829, 1830, 1831, -1716]]],
        },
        {
          type: "Polygon",
          id: 46127,
          arcs: [[-1757, 1832, 1833, 1834, 1835, 1836, -1661]],
        },
        {
          type: "Polygon",
          id: 46027,
          arcs: [[-1837, 1837, 1838, -1808, -1674, -1662]],
        },
        {
          type: "Polygon",
          id: 19065,
          arcs: [[1839, 1840, 1841, -1770, -1658]],
        },
        {
          type: "Polygon",
          id: 19043,
          arcs: [[-1696, -1780, 1842, 1843, -1840, -1656]],
        },
        {
          type: "Polygon",
          id: 36057,
          arcs: [[-1706, 1844, 1845, 1846, -1419, -1747]],
        },
        {
          type: "Polygon",
          id: 36069,
          arcs: [[1847, 1848, 1849, 1850, -1719, -1724]],
        },
        {
          type: "Polygon",
          id: 16005,
          arcs: [[1851, 1852, 1853, -1824, -1597]],
        },
        {
          type: "Polygon",
          id: 16029,
          arcs: [[-1594, -1734, 1854, 1855, -1852, -1596]],
        },
        {
          type: "Polygon",
          id: 36099,
          arcs: [[1856, 1857, 1858, -1848, -1723, -1700]],
        },
        {
          type: "Polygon",
          id: 31165,
          arcs: [[1859, 1860, 1861, -1644, -1684, 1862]],
        },
        {
          type: "Polygon",
          id: 31161,
          arcs: [[1863, 1864, 1865, 1866, 1867, -1573, 1868]],
        },
        {
          type: "Polygon",
          id: 31045,
          arcs: [[1869, -1863, -1683, -1574, -1868]],
        },
        {
          type: "Polygon",
          id: 31015,
          arcs: [[1870, 1871, 1872, -1681, -1672]],
        },
        {
          type: "Polygon",
          id: 31103,
          arcs: [[-1873, 1873, 1874, 1875, -1552, -1682]],
        },
        {
          type: "Polygon",
          id: 31031,
          arcs: [[-1710, -1876, 1876, 1877, 1878, 1879, 1880, -1869, -1709]],
        },
        { type: "Polygon", id: 41029, arcs: [[-1602, 1881, 1882, -1461]] },
        {
          type: "Polygon",
          id: 36051,
          arcs: [[-1851, 1883, 1884, 1885, -1816, -1720]],
        },
        {
          type: "Polygon",
          id: 36083,
          arcs: [[-1738, 1886, 1887, 1888, -1703, -1546]],
        },
        { type: "Polygon", id: 36093, arcs: [[1889, 1890, -1845, -1705]] },
        {
          type: "Polygon",
          id: 41015,
          arcs: [[-1463, 1891, 1892, 1893, -1611]],
        },
        {
          type: "Polygon",
          id: 16083,
          arcs: [[-1791, 1894, 1895, 1896, -1580, -1415, -1792]],
        },
        { type: "Polygon", id: 19149, arcs: [[1897, 1898, -1833, -1756]] },
        { type: "Polygon", id: 19021, arcs: [[1899, 1900, 1901, -1766]] },
        {
          type: "Polygon",
          id: 19035,
          arcs: [[-1902, 1902, 1903, -1898, -1759]],
        },
        {
          type: "Polygon",
          id: 19151,
          arcs: [[1904, 1905, 1906, -1900, -1764]],
        },
        { type: "Polygon", id: 19197, arcs: [[1907, 1908, 1909, 1910, -1763]] },
        { type: "Polygon", id: 19091, arcs: [[-1911, 1911, -1905, -1631]] },
        { type: "Polygon", id: 19069, arcs: [[1912, 1913, -1908, -1761]] },
        {
          type: "Polygon",
          id: 19023,
          arcs: [[1914, 1915, 1916, -1913, -1773]],
        },
        { type: "Polygon", id: 19017, arcs: [[-1842, 1917, -1915, -1771]] },
        {
          type: "Polygon",
          id: 36077,
          arcs: [[-1847, 1918, 1919, 1920, -1803, -1605, -1420]],
        },
        {
          type: "Polygon",
          id: 31089,
          arcs: [[1921, 1922, 1923, 1924, 1925, 1926, -1872]],
        },
        { type: "Polygon", id: 26099, arcs: [[1927, 1928, 1929, -1727, 1930]] },
        {
          type: "Polygon",
          id: 26125,
          arcs: [[1931, 1932, 1933, -1767, -1728, -1930]],
        },
        {
          type: "Polygon",
          id: 25009,
          arcs: [[1934, 1935, 1936, -1781, -1749]],
        },
        {
          type: "Polygon",
          id: 31107,
          arcs: [[-1812, -1810, 1937, 1938, 1939, -1922, -1871, -1671]],
        },
        {
          type: "Polygon",
          id: 31027,
          arcs: [[-1839, 1940, 1941, 1942, -1938, -1809]],
        },
        {
          type: "Polygon",
          id: 36121,
          arcs: [[-1886, 1943, 1944, -1828, -1817]],
        },
        {
          type: "Polygon",
          id: 55045,
          arcs: [[1945, 1946, 1947, 1948, -1774, -1741]],
        },
        {
          type: "Polygon",
          id: 16053,
          arcs: [[-1797, 1949, -1895, -1790, -1788]],
        },
        {
          type: "Polygon",
          id: 55105,
          arcs: [[-1795, 1950, 1951, 1952, -1946, -1740]],
        },
        {
          type: "Polygon",
          id: 31017,
          arcs: [[1953, 1954, 1955, -1877, -1875]],
        },
        {
          type: "Polygon",
          id: 55127,
          arcs: [[-1800, 1956, 1957, 1958, 1959, -1951, -1794]],
        },
        {
          type: "Polygon",
          id: 55101,
          arcs: [[1960, 1961, -1957, -1799, -1802]],
        },
        {
          type: "Polygon",
          id: 36095,
          arcs: [[-1891, 1962, 1963, 1964, -1919, -1846]],
        },
        {
          type: "Polygon",
          id: 36001,
          arcs: [[-1889, 1965, -1963, -1890, -1704]],
        },
        {
          type: "Polygon",
          id: 55065,
          arcs: [[-1949, 1966, 1967, -1777, -1775]],
        },
        { type: "Polygon", id: 31149, arcs: [[1968, -1954, -1874, -1927]] },
        {
          type: "Polygon",
          id: 36023,
          arcs: [[1969, 1970, -1698, -1752, -1805, 1971, 1972]],
        },
        {
          type: "Polygon",
          id: 41033,
          arcs: [[-1462, -1883, 1973, 1974, -1892]],
        },
        {
          type: "Polygon",
          id: 26093,
          arcs: [[-1934, 1975, 1976, 1977, -1813, -1768]],
        },
        {
          type: "Polygon",
          id: 26065,
          arcs: [[-1978, 1978, 1979, -1822, -1814]],
        },
        {
          type: "Polygon",
          id: 26045,
          arcs: [[-1823, -1980, 1980, 1981, 1982, -1820]],
        },
        {
          type: "Polygon",
          id: 26015,
          arcs: [[-1983, 1983, 1984, 1985, -1744, -1821]],
        },
        {
          type: "Polygon",
          id: 26005,
          arcs: [[-1745, -1986, 1986, 1987, 1988, -1785]],
        },
        { type: "Polygon", id: 36123, arcs: [[1989, 1990, -1849, -1859]] },
        {
          type: "Polygon",
          id: 31051,
          arcs: [[-1836, 1991, 1992, 1993, -1941, -1838]],
        },
        {
          type: "Polygon",
          id: 25003,
          arcs: [[1994, 1995, 1996, 1997, 1998, 1999, -1887, -1737]],
        },
        {
          type: "Polygon",
          id: 36017,
          arcs: [[-1921, 2000, 2001, 2002, -1972, -1804]],
        },
        {
          type: "Polygon",
          id: 25011,
          arcs: [[2003, -1995, -1736, -1754, -1807, 2004]],
        },
        {
          type: "Polygon",
          id: 25017,
          arcs: [
            [
              2005,
              2006,
              2007,
              2008,
              2009,
              2010,
              2011,
              2012,
              2013,
              -1782,
              -1937,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 25027,
          arcs: [
            [-1783, -2014, 2014, 2015, 2016, 2017, 2018, 2019, -2005, -1806],
          ],
        },
        {
          type: "Polygon",
          id: 16031,
          arcs: [[-1826, 2020, 2021, 2022, -1896, -1950, -1796, -1445]],
        },
        {
          type: "Polygon",
          id: 19061,
          arcs: [[2023, 2024, 2025, 2026, -1843, -1779]],
        },
        {
          type: "Polygon",
          id: 55059,
          arcs: [[2027, 2028, 2029, -1958, -1962]],
        },
        {
          type: "Polygon",
          id: 19055,
          arcs: [[-2027, 2030, 2031, 2032, -1844]],
        },
        {
          type: "Polygon",
          id: 19187,
          arcs: [[-1910, 2033, 2034, 2035, 2036, -1906, -1912]],
        },
        {
          type: "Polygon",
          id: 19019,
          arcs: [[-2033, 2037, 2038, 2039, -1841]],
        },
        {
          type: "Polygon",
          id: 19013,
          arcs: [[-2040, 2040, 2041, 2042, -1916, -1918]],
        },
        {
          type: "Polygon",
          id: 36109,
          arcs: [[-1971, 2043, 2044, 2045, -1857, -1699]],
        },
        {
          type: "Polygon",
          id: 56015,
          arcs: [[-1862, 2046, 2047, 2048, 2049, -1645]],
        },
        {
          type: "Polygon",
          id: 56031,
          arcs: [[-2050, 2050, 2051, -1664, -1646]],
        },
        { type: "Polygon", id: 16007, arcs: [[-1733, 2052, 2053, -1855]] },
        {
          type: "Polygon",
          id: 36101,
          arcs: [[-1850, -1991, 2054, 2055, 2056, 2057, 2058, -1884]],
        },
        { type: "Polygon", id: 36013, arcs: [[2059, 2060, 2061, 2062, -1831]] },
        {
          type: "Polygon",
          id: 19193,
          arcs: [[-1904, 2063, 2064, 2065, 2066, -1834, -1899]],
        },
        { type: "Polygon", id: 19161, arcs: [[2067, 2068, 2069, 2070, -1901]] },
        { type: "Polygon", id: 19093, arcs: [[-2071, 2071, -2064, -1903]] },
        {
          type: "Polygon",
          id: 19025,
          arcs: [[-2037, 2072, 2073, -2068, -1907]],
        },
        {
          type: "Polygon",
          id: 19079,
          arcs: [[2074, 2075, 2076, -2034, -1909]],
        },
        {
          type: "Polygon",
          id: 19083,
          arcs: [[-1914, 2077, 2078, 2079, -2075]],
        },
        {
          type: "Polygon",
          id: 19075,
          arcs: [[-2043, 2080, 2081, -2078, -1917]],
        },
        { type: "Polygon", id: 25015, arcs: [[-2020, 2082, -1996, -2004]] },
        {
          type: "Polygon",
          id: 36097,
          arcs: [[-2046, 2083, -2055, -1990, -1858]],
        },
        {
          type: "Polygon",
          id: 36009,
          arcs: [[-1829, -1945, 2084, 2085, 2086, -2060, -1830]],
        },
        { type: "Polygon", id: 31043, arcs: [[-1835, -2067, 2087, -1992]] },
        {
          type: "Polygon",
          id: 36003,
          arcs: [[-1885, -2059, 2088, 2089, -2085, -1944]],
        },
        {
          type: "Polygon",
          id: 36025,
          arcs: [[-1965, 2090, 2091, 2092, 2093, 2094, -2001, -1920]],
        },
        {
          type: "Polygon",
          id: 17085,
          arcs: [[-1968, 2095, 2096, 2097, -2024, -1778]],
        },
        {
          type: "Polygon",
          id: 36021,
          arcs: [[2098, 2099, 2100, -1888, -2000]],
        },
        {
          type: "Polygon",
          id: 17177,
          arcs: [[2101, -2096, -1967, -1948, 2102, 2103]],
        },
        {
          type: "Polygon",
          id: 17201,
          arcs: [[2104, 2105, -2103, -1947, -1953]],
        },
        {
          type: "Polygon",
          id: 16071,
          arcs: [[-1854, 2106, 2107, 2108, -2021, -1825]],
        },
        {
          type: "Polygon",
          id: 17111,
          arcs: [[2109, 2110, 2111, 2112, 2113, -1959, -2030]],
        },
        {
          type: "Polygon",
          id: 17007,
          arcs: [[-1960, -2114, 2114, -2105, -1952]],
        },
        { type: "Polygon", id: 17097, arcs: [[2115, -2110, -2029, 2116]] },
        {
          type: "Polygon",
          id: 36039,
          arcs: [[2117, -2091, -1964, -1966, -2101]],
        },
        {
          type: "MultiPolygon",
          id: 25025,
          arcs: [[[2118, 2119, -2012, 2120, -2010]]],
        },
        {
          type: "Polygon",
          id: 31139,
          arcs: [[2121, 2122, 2123, -1939, -1943]],
        },
        {
          type: "Polygon",
          id: 31013,
          arcs: [[2124, 2125, -1860, -1870, -1867]],
        },
        {
          type: "Polygon",
          id: 31003,
          arcs: [[-2124, 2126, 2127, 2128, -1923, -1940]],
        },
        {
          type: "Polygon",
          id: 26161,
          arcs: [[2129, 2130, 2131, -1976, -1933, 2132]],
        },
        {
          type: "Polygon",
          id: 56007,
          arcs: [[-1666, 2133, 2134, 2135, 2136, 2137, -1432, -1680]],
        },
        {
          type: "Polygon",
          id: 56001,
          arcs: [[-2052, 2138, 2139, 2140, -2134, -1665]],
        },
        {
          type: "Polygon",
          id: 16041,
          arcs: [[-2054, 2141, -2107, -1853, -1856]],
        },
        {
          type: "Polygon",
          id: 26075,
          arcs: [[-1977, -2132, 2142, 2143, 2144, -1981, -1979]],
        },
        {
          type: "Polygon",
          id: 26025,
          arcs: [[-2145, 2145, 2146, 2147, -1984, -1982]],
        },
        { type: "Polygon", id: 26159, arcs: [[2148, 2149, 2150, 2151, -1988]] },
        {
          type: "Polygon",
          id: 26077,
          arcs: [[-2148, 2152, -2149, -1987, -1985]],
        },
        {
          type: "Polygon",
          id: 36007,
          arcs: [[-2002, -2095, 2153, 2154, 2155, -1973, -2003]],
        },
        {
          type: "Polygon",
          id: 36107,
          arcs: [[-2156, 2156, 2157, 2158, -2044, -1970]],
        },
        {
          type: "Polygon",
          id: 19097,
          arcs: [[-2098, 2159, 2160, 2161, -2025]],
        },
        {
          type: "Polygon",
          id: 31179,
          arcs: [[-1994, 2162, 2163, 2164, -2122, -1942]],
        },
        {
          type: "Polygon",
          id: 25021,
          arcs: [[-2120, 2165, 2166, 2167, 2168, -2015, -2013]],
        },
        {
          type: "Polygon",
          id: 25013,
          arcs: [[-2019, 2169, 2170, 2171, -1997, -2083]],
        },
        {
          type: "Polygon",
          id: 25023,
          arcs: [[2172, 2173, 2174, 2175, 2176, -2167, 2177]],
        },
        {
          type: "Polygon",
          id: 19011,
          arcs: [[2178, 2179, 2180, -2041, -2039]],
        },
        {
          type: "Polygon",
          id: 19113,
          arcs: [[-2032, 2181, 2182, 2183, -2179, -2038]],
        },
        {
          type: "Polygon",
          id: 19171,
          arcs: [[-2181, 2184, 2185, -2081, -2042]],
        },
        {
          type: "Polygon",
          id: 19105,
          arcs: [[-2026, -2162, 2186, 2187, -2182, -2031]],
        },
        {
          type: "Polygon",
          id: 36015,
          arcs: [[-2045, -2159, 2188, 2189, -2056, -2084]],
        },
        {
          type: "Polygon",
          id: 31173,
          arcs: [[-2066, 2190, 2191, 2192, -2163, -1993, -2088]],
        },
        {
          type: "Polygon",
          id: 56037,
          arcs: [[-1433, -2138, 2193, 2194, 2195, 2196, -1730, -1691]],
        },
        { type: "Polygon", id: 42049, arcs: [[2197, 2198, 2199, 2200, -2062]] },
        { type: "Polygon", id: 26021, arcs: [[2201, 2202, 2203, 2204, -2151]] },
        {
          type: "Polygon",
          id: 19133,
          arcs: [[2205, 2206, 2207, -2191, -2065]],
        },
        {
          type: "Polygon",
          id: 19127,
          arcs: [[-2186, 2208, 2209, -2079, -2082]],
        },
        {
          type: "Polygon",
          id: 19027,
          arcs: [[-2074, 2210, 2211, 2212, 2213, -2069]],
        },
        {
          type: "Polygon",
          id: 19047,
          arcs: [[-2072, -2070, -2214, 2214, 2215, -2206]],
        },
        {
          type: "Polygon",
          id: 19015,
          arcs: [[-2077, 2216, 2217, 2218, 2219, -2035]],
        },
        {
          type: "Polygon",
          id: 19073,
          arcs: [[-2220, 2220, 2221, -2211, -2073, -2036]],
        },
        {
          type: "Polygon",
          id: 19169,
          arcs: [[-2080, -2210, 2222, 2223, -2217, -2076]],
        },
        {
          type: "Polygon",
          id: 17141,
          arcs: [[2224, 2225, 2226, 2227, -2104, -2106]],
        },
        {
          type: "Polygon",
          id: 17015,
          arcs: [[-2228, 2228, 2229, -2160, -2097, -2102]],
        },
        {
          type: "Polygon",
          id: 36111,
          arcs: [[-2100, 2230, 2231, 2232, -2092, -2118]],
        },
        {
          type: "Polygon",
          id: 17031,
          arcs: [[-2116, 2233, 2234, 2235, 2236, 2237, 2238, -2111]],
        },
        {
          type: "Polygon",
          id: 17037,
          arcs: [[2239, 2240, 2241, 2242, -2225, -2115, -2113]],
        },
        {
          type: "Polygon",
          id: 17089,
          arcs: [[-2239, 2243, 2244, -2240, -2112]],
        },
        {
          type: "Polygon",
          id: 31075,
          arcs: [[2245, 2246, 2247, -1864, -1881]],
        },
        {
          type: "Polygon",
          id: 25005,
          arcs: [[2248, 2249, 2250, 2251, 2252, -2168, -2177]],
        },
        {
          type: "Polygon",
          id: 31091,
          arcs: [[2253, 2254, 2255, -2246, -1880]],
        },
        {
          type: "Polygon",
          id: 31039,
          arcs: [[-2193, 2256, 2257, 2258, 2259, -2164]],
        },
        {
          type: "Polygon",
          id: 31119,
          arcs: [[2260, 2261, 2262, -2127, -2123]],
        },
        {
          type: "Polygon",
          id: 31167,
          arcs: [[-2260, 2263, 2264, -2261, -2165]],
        },
        {
          type: "Polygon",
          id: 31171,
          arcs: [[2265, 2266, 2267, -2254, -1879]],
        },
        {
          type: "Polygon",
          id: 31183,
          arcs: [[2268, 2269, 2270, 2271, -1924, -2129]],
        },
        {
          type: "Polygon",
          id: 31009,
          arcs: [[-1878, -1956, 2272, 2273, 2274, -2266]],
        },
        {
          type: "Polygon",
          id: 31115,
          arcs: [[2275, 2276, -2273, -1955, -1969, -1926]],
        },
        {
          type: "Polygon",
          id: 31071,
          arcs: [[-2272, 2277, 2278, -2276, -1925]],
        },
        {
          type: "Polygon",
          id: 26091,
          arcs: [[2279, 2280, 2281, 2282, -2143, -2131]],
        },
        {
          type: "Polygon",
          id: 36027,
          arcs: [[-1999, 2283, 2284, 2285, 2286, -2231, -2099]],
        },
        { type: "Polygon", id: 25001, arcs: [[-2175, 2287]] },
        { type: "Polygon", id: 26023, arcs: [[2288, 2289, 2290, 2291, -2147]] },
        {
          type: "Polygon",
          id: 26059,
          arcs: [[-2283, 2292, 2293, 2294, -2289, -2146, -2144]],
        },
        {
          type: "Polygon",
          id: 26149,
          arcs: [[-2292, 2295, 2296, 2297, -2153]],
        },
        {
          type: "Polygon",
          id: 26027,
          arcs: [[-2298, 2298, 2299, -2202, -2150]],
        },
        {
          type: "Polygon",
          id: 9005,
          arcs: [[-2172, 2300, 2301, 2302, -2284, -1998]],
        },
        {
          type: "Polygon",
          id: 31021,
          arcs: [[2303, 2304, 2305, -2257, -2192, -2208]],
        },
        {
          type: "Polygon",
          id: 9003,
          arcs: [[2306, 2307, 2308, 2309, -2301, -2171]],
        },
        {
          type: "Polygon",
          id: 9013,
          arcs: [[-2018, 2310, 2311, -2307, -2170]],
        },
        {
          type: "Polygon",
          id: 19045,
          arcs: [[-2230, 2312, 2313, 2314, 2315, 2316, -2187, -2161]],
        },
        { type: "Polygon", id: 9015, arcs: [[2317, 2318, 2319, -2311, -2017]] },
        {
          type: "Polygon",
          id: 44007,
          arcs: [[-2253, 2320, 2321, 2322, -2318, -2016, -2169]],
        },
        {
          type: "Polygon",
          id: 36105,
          arcs: [[2323, 2324, 2325, -2093, -2233]],
        },
        {
          type: "Polygon",
          id: 6093,
          arcs: [[-1601, 2326, 2327, 2328, 2329, 2330, -1974, -1882]],
        },
        {
          type: "Polygon",
          id: 31069,
          arcs: [[-2248, 2331, 2332, 2333, 2334, 2335, -1865]],
        },
        {
          type: "Polygon",
          id: 31123,
          arcs: [[-2336, 2336, 2337, 2338, -2125, -1866]],
        },
        {
          type: "Polygon",
          id: 49005,
          arcs: [[2339, 2340, 2341, -2108, -2142]],
        },
        {
          type: "Polygon",
          id: 31157,
          arcs: [[-2339, 2342, -2047, -1861, -2126]],
        },
        {
          type: "Polygon",
          id: 49033,
          arcs: [[-1732, 2343, 2344, 2345, 2346, -2340, -2053]],
        },
        {
          type: "Polygon",
          id: 42015,
          arcs: [[-2189, -2158, 2347, 2348, 2349, 2350, 2351]],
        },
        {
          type: "Polygon",
          id: 42117,
          arcs: [[-2190, -2352, 2352, 2353, -2057]],
        },
        {
          type: "Polygon",
          id: 49003,
          arcs: [[2354, 2355, 2356, -2022, -2109, -2342]],
        },
        {
          type: "Polygon",
          id: 32013,
          arcs: [[2357, 2358, 2359, -1428, -1264, -1582, 2360]],
        },
        {
          type: "Polygon",
          id: 32007,
          arcs: [[-2023, -2357, 2361, 2362, 2363, 2364, -2361, -1581, -1897]],
        },
        {
          type: "Polygon",
          id: 42083,
          arcs: [[-2090, 2365, 2366, 2367, 2368, -2086]],
        },
        {
          type: "Polygon",
          id: 42105,
          arcs: [[-2058, -2354, 2369, 2370, 2371, -2366, -2089]],
        },
        {
          type: "Polygon",
          id: 6015,
          arcs: [[-2331, 2372, 2373, -1893, -1975]],
        },
        {
          type: "Polygon",
          id: 42127,
          arcs: [[-2094, -2326, 2374, 2375, 2376, 2377, -2154]],
        },
        {
          type: "Polygon",
          id: 42115,
          arcs: [[2378, 2379, -2348, -2157, -2155, -2378]],
        },
        {
          type: "Polygon",
          id: 42123,
          arcs: [[-2369, 2380, 2381, 2382, -2198, -2061, -2087]],
        },
        {
          type: "Polygon",
          id: 6049,
          arcs: [[-1604, 2383, 2384, 2385, -2327, -1600]],
        },
        {
          type: "Polygon",
          id: 32031,
          arcs: [
            [
              2386,
              2387,
              2388,
              2389,
              2390,
              2391,
              2392,
              2393,
              2394,
              -2384,
              -1603,
              -1429,
              -2360,
            ],
          ],
        },
        { type: "Polygon", id: 17043, arcs: [[2395, 2396, -2244, -2238]] },
        {
          type: "Polygon",
          id: 39007,
          arcs: [[2397, 2398, 2399, 2400, 2401, -2200]],
        },
        {
          type: "Polygon",
          id: 19031,
          arcs: [[-2188, -2317, 2402, 2403, 2404, -2183]],
        },
        {
          type: "Polygon",
          id: 17195,
          arcs: [[-2227, 2405, 2406, 2407, 2408, -2313, -2229]],
        },
        {
          type: "Polygon",
          id: 31011,
          arcs: [[-2263, 2409, 2410, 2411, -2269, -2128]],
        },
        {
          type: "Polygon",
          id: 17103,
          arcs: [[-2243, 2412, 2413, -2406, -2226]],
        },
        {
          type: "Polygon",
          id: 19085,
          arcs: [[-2216, 2414, 2415, 2416, -2304, -2207]],
        },
        { type: "Polygon", id: 19095, arcs: [[2417, 2418, 2419, 2420, -2180]] },
        {
          type: "Polygon",
          id: 19049,
          arcs: [[2421, 2422, 2423, -2221, -2219]],
        },
        {
          type: "Polygon",
          id: 19165,
          arcs: [[2424, 2425, 2426, -2415, -2215]],
        },
        { type: "Polygon", id: 19009, arcs: [[2427, 2428, -2425, -2213]] },
        {
          type: "Polygon",
          id: 19157,
          arcs: [[-2421, 2429, 2430, 2431, -2185]],
        },
        {
          type: "Polygon",
          id: 19153,
          arcs: [[-2224, 2432, 2433, 2434, -2422, -2218]],
        },
        {
          type: "Polygon",
          id: 19099,
          arcs: [[-2209, -2432, 2435, 2436, -2433, -2223]],
        },
        {
          type: "Polygon",
          id: 19077,
          arcs: [[-2424, 2437, -2428, -2212, -2222]],
        },
        {
          type: "Polygon",
          id: 19103,
          arcs: [[-2405, 2438, 2439, 2440, -2418, -2184]],
        },
        { type: "Polygon", id: 39085, arcs: [[2441, 2442, 2443, -2401]] },
        {
          type: "Polygon",
          id: 42039,
          arcs: [[-2383, 2444, 2445, 2446, -2398, -2199]],
        },
        {
          type: "Polygon",
          id: 17161,
          arcs: [[2447, 2448, 2449, 2450, 2451, -2314, -2409]],
        },
        { type: "Polygon", id: 44001, arcs: [[2452, -2321, -2252]] },
        {
          type: "Polygon",
          id: 19163,
          arcs: [[-2452, 2453, -2403, -2316, -2315]],
        },
        {
          type: "Polygon",
          id: 44003,
          arcs: [[2454, 2455, 2456, -2319, -2323]],
        },
        {
          type: "Polygon",
          id: 18039,
          arcs: [[2457, 2458, 2459, 2460, 2461, -2299, -2297]],
        },
        {
          type: "Polygon",
          id: 18141,
          arcs: [[2462, 2463, 2464, -2203, -2300, -2462]],
        },
        {
          type: "Polygon",
          id: 18091,
          arcs: [[2465, 2466, 2467, -2204, -2465]],
        },
        {
          type: "Polygon",
          id: 18151,
          arcs: [[2468, 2469, -2290, -2295, 2470]],
        },
        {
          type: "Polygon",
          id: 18087,
          arcs: [[2471, -2458, -2296, -2291, -2470]],
        },
        {
          type: "Polygon",
          id: 31037,
          arcs: [[-2259, 2472, 2473, 2474, -2264]],
        },
        {
          type: "Polygon",
          id: 31141,
          arcs: [[-2475, 2475, 2476, 2477, 2478, -2410, -2262, -2265]],
        },
        {
          type: "Polygon",
          id: 31053,
          arcs: [[-2306, 2479, 2480, 2481, 2482, -2473, -2258]],
        },
        {
          type: "Polygon",
          id: 31117,
          arcs: [[-2268, 2483, 2484, 2485, 2486, -2255]],
        },
        {
          type: "Polygon",
          id: 31005,
          arcs: [[-2487, 2487, -2332, -2247, -2256]],
        },
        {
          type: "Polygon",
          id: 31077,
          arcs: [[2488, 2489, 2490, 2491, -2270, -2412]],
        },
        {
          type: "Polygon",
          id: 31041,
          arcs: [[-2279, 2492, 2493, 2494, 2495, 2496, 2497, -2274, -2277]],
        },
        {
          type: "Polygon",
          id: 31113,
          arcs: [[-2275, -2498, 2498, -2484, -2267]],
        },
        {
          type: "Polygon",
          id: 31175,
          arcs: [[-2271, -2492, 2499, -2493, -2278]],
        },
        {
          type: "Polygon",
          id: 39095,
          arcs: [
            [2500, 2501, 2502, 2503, -2281, 2504, 2505, 2506, 2507, 2508, 2509],
          ],
        },
        {
          type: "Polygon",
          id: 17197,
          arcs: [[-2237, 2510, 2511, 2512, 2513, -2396]],
        },
        {
          type: "Polygon",
          id: 39123,
          arcs: [[2514, 2515, 2516, 2517, 2518, -2501]],
        },
        {
          type: "Polygon",
          id: 17093,
          arcs: [[-2514, 2519, 2520, -2241, -2245, -2397]],
        },
        {
          type: "Polygon",
          id: 39051,
          arcs: [[2521, 2522, -2293, -2282, -2504]],
        },
        {
          type: "Polygon",
          id: 39055,
          arcs: [[2523, 2524, 2525, -2442, -2400]],
        },
        {
          type: "Polygon",
          id: 9011,
          arcs: [[-2457, 2526, 2527, 2528, -2308, -2312, -2320]],
        },
        {
          type: "Polygon",
          id: 18089,
          arcs: [[2529, 2530, 2531, 2532, -2511, -2236, 2533]],
        },
        { type: "Polygon", id: 18127, arcs: [[2534, -2530, 2535, -2467]] },
        {
          type: "Polygon",
          id: 39171,
          arcs: [[2536, 2537, 2538, -2471, -2294, -2523]],
        },
        {
          type: "Polygon",
          id: 31007,
          arcs: [[-2338, 2539, 2540, 2541, -2048, -2343]],
        },
        {
          type: "Polygon",
          id: 31177,
          arcs: [[-2417, 2542, 2543, -2480, -2305]],
        },
        { type: "MultiPolygon", id: 44005, arcs: [[[2544]], [[2545, -2250]]] },
        {
          type: "Polygon",
          id: 9001,
          arcs: [[2546, 2547, 2548, 2549, -2285, -2303]],
        },
        {
          type: "Polygon",
          id: 56021,
          arcs: [[-2049, -2542, 2550, 2551, 2552, -2139, -2051]],
        },
        { type: "Polygon", id: 44009, arcs: [[-2527, -2456, 2553]] },
        {
          type: "Polygon",
          id: 42131,
          arcs: [[-2380, 2554, 2555, 2556, -2349]],
        },
        { type: "Polygon", id: 9007, arcs: [[-2529, 2557, 2558, -2309]] },
        {
          type: "Polygon",
          id: 9009,
          arcs: [[-2559, 2559, 2560, 2561, -2547, -2302, -2310]],
        },
        {
          type: "Polygon",
          id: 42069,
          arcs: [[-2377, 2562, 2563, -2555, -2379]],
        },
        {
          type: "Polygon",
          id: 36071,
          arcs: [[-2287, 2564, 2565, 2566, 2567, 2568, -2324, -2232]],
        },
        {
          type: "Polygon",
          id: 17099,
          arcs: [[-2521, 2569, 2570, 2571, 2572, 2573, 2574, -2413, -2242]],
        },
        {
          type: "Polygon",
          id: 39035,
          arcs: [[-2526, 2575, 2576, 2577, 2578, 2579, -2443]],
        },
        { type: "Polygon", id: 42047, arcs: [[2580, 2581, 2582, 2583, -2368]] },
        {
          type: "Polygon",
          id: 42053,
          arcs: [[-2584, 2584, 2585, 2586, -2381]],
        },
        {
          type: "Polygon",
          id: 42121,
          arcs: [[2587, 2588, 2589, -2445, -2382, -2587]],
        },
        {
          type: "Polygon",
          id: 39043,
          arcs: [[2590, 2591, 2592, 2593, 2594, 2595]],
        },
        {
          type: "Polygon",
          id: 39173,
          arcs: [[-2519, 2596, 2597, 2598, 2599, -2502]],
        },
        {
          type: "Polygon",
          id: 42023,
          arcs: [[-2372, 2600, 2601, -2581, -2367]],
        },
        {
          type: "Polygon",
          id: 42103,
          arcs: [[-2569, 2602, 2603, -2375, -2325]],
        },
        {
          type: "Polygon",
          id: 19139,
          arcs: [[-2404, -2454, -2451, 2604, -2439]],
        },
        {
          type: "Polygon",
          id: 42081,
          arcs: [[2605, 2606, 2607, 2608, 2609, 2610, -2370, -2353, -2351]],
        },
        {
          type: "Polygon",
          id: 42113,
          arcs: [[-2606, -2350, -2557, 2611, 2612]],
        },
        {
          type: "Polygon",
          id: 17011,
          arcs: [[-2575, 2613, 2614, 2615, 2616, -2407, -2414]],
        },
        {
          type: "Polygon",
          id: 17073,
          arcs: [[-2617, 2617, 2618, 2619, -2448, -2408]],
        },
        { type: "Polygon", id: 56041, arcs: [[-2197, 2620, -2344, -1731]] },
        {
          type: "Polygon",
          id: 18033,
          arcs: [[2621, 2622, 2623, -2469, -2539]],
        },
        {
          type: "Polygon",
          id: 18113,
          arcs: [[-2624, 2624, 2625, 2626, -2459, -2472]],
        },
        {
          type: "Polygon",
          id: 31125,
          arcs: [[2627, 2628, -2489, -2411, -2479]],
        },
        {
          type: "Polygon",
          id: 36079,
          arcs: [[2629, 2630, -2565, -2286, -2550, 2631]],
        },
        { type: "Polygon", id: 25007, arcs: [[2632]] },
        {
          type: "Polygon",
          id: 39093,
          arcs: [[-2579, 2633, 2634, 2635, -2591, 2636]],
        },
        {
          type: "Polygon",
          id: 19183,
          arcs: [[2637, 2638, 2639, 2640, -2419, -2441]],
        },
        { type: "Polygon", id: 19181, arcs: [[2641, 2642, 2643, 2644, -2435]] },
        {
          type: "Polygon",
          id: 19107,
          arcs: [[-2641, 2645, 2646, 2647, -2430, -2420]],
        },
        {
          type: "Polygon",
          id: 19121,
          arcs: [[2648, 2649, 2650, -2423, -2645]],
        },
        {
          type: "Polygon",
          id: 19123,
          arcs: [[-2648, 2651, 2652, 2653, -2436, -2431]],
        },
        {
          type: "Polygon",
          id: 19125,
          arcs: [[-2654, 2654, 2655, -2642, -2434, -2437]],
        },
        {
          type: "Polygon",
          id: 19155,
          arcs: [[-2427, 2656, 2657, 2658, 2659, 2660, -2543, -2416]],
        },
        {
          type: "Polygon",
          id: 19029,
          arcs: [[-2429, 2661, 2662, 2663, -2657, -2426]],
        },
        {
          type: "Polygon",
          id: 19001,
          arcs: [[-2438, -2651, 2664, 2665, -2662]],
        },
        {
          type: "Polygon",
          id: 39155,
          arcs: [[-2447, 2666, 2667, 2668, -2524, -2399]],
        },
        {
          type: "Polygon",
          id: 39143,
          arcs: [[2669, -2593, 2670, 2671, -2597, -2518]],
        },
        {
          type: "Polygon",
          id: 42085,
          arcs: [[-2590, 2672, 2673, 2674, -2667, -2446]],
        },
        {
          type: "Polygon",
          id: 39069,
          arcs: [[-2503, -2600, 2675, 2676, -2537, -2522]],
        },
        {
          type: "Polygon",
          id: 18099,
          arcs: [[-2461, 2677, 2678, 2679, -2463]],
        },
        {
          type: "Polygon",
          id: 42035,
          arcs: [[-2611, 2680, 2681, 2682, -2601, -2371]],
        },
        { type: "Polygon", id: 6023, arcs: [[-2330, 2683, 2684, 2685, -2373]] },
        {
          type: "Polygon",
          id: 17063,
          arcs: [[2686, 2687, -2570, -2520, -2513]],
        },
        {
          type: "Polygon",
          id: 31023,
          arcs: [[-2483, 2688, 2689, 2690, 2691, -2476, -2474]],
        },
        {
          type: "Polygon",
          id: 31155,
          arcs: [[2692, 2693, 2694, 2695, -2689, -2482]],
        },
        {
          type: "Polygon",
          id: 18085,
          arcs: [[-2627, 2696, 2697, 2698, -2678, -2460]],
        },
        {
          type: "Polygon",
          id: 31033,
          arcs: [[-2335, 2699, 2700, 2701, 2702, -2540, -2337]],
        },
        {
          type: "Polygon",
          id: 42031,
          arcs: [[2703, 2704, 2705, -2588, -2586]],
        },
        {
          type: "Polygon",
          id: 49057,
          arcs: [[-2341, -2347, 2706, 2707, -2355]],
        },
        {
          type: "Polygon",
          id: 18149,
          arcs: [[-2680, 2708, 2709, -2466, -2464]],
        },
        {
          type: "Polygon",
          id: 42079,
          arcs: [[2710, 2711, 2712, 2713, -2612, -2556, -2564]],
        },
        {
          type: "Polygon",
          id: 39039,
          arcs: [[2714, 2715, 2716, -2622, -2538, -2677]],
        },
        {
          type: "Polygon",
          id: 19115,
          arcs: [[-2605, -2450, 2717, 2718, 2719, -2638, -2440]],
        },
        {
          type: "Polygon",
          id: 31101,
          arcs: [[2720, 2721, 2722, -2333, -2488, -2486]],
        },
        {
          type: "Polygon",
          id: 31111,
          arcs: [[-2499, -2497, 2723, 2724, 2725, 2726, -2721, -2485]],
        },
        { type: "Polygon", id: 25019, arcs: [[2727]] },
        {
          type: "Polygon",
          id: 31143,
          arcs: [[2728, 2729, 2730, -2477, -2692]],
        },
        {
          type: "Polygon",
          id: 31105,
          arcs: [[-2703, 2731, 2732, -2551, -2541]],
        },
        {
          type: "Polygon",
          id: 31121,
          arcs: [[-2731, 2733, 2734, 2735, -2628, -2478]],
        },
        {
          type: "Polygon",
          id: 31093,
          arcs: [[-2629, -2736, 2736, 2737, 2738, -2490]],
        },
        {
          type: "Polygon",
          id: 31163,
          arcs: [[-2491, -2739, 2739, -2494, -2500]],
        },
        {
          type: "Polygon",
          id: 31055,
          arcs: [[-2544, -2661, 2740, -2693, -2481]],
        },
        {
          type: "Polygon",
          id: 49029,
          arcs: [[2741, 2742, 2743, -2707, -2346]],
        },
        {
          type: "Polygon",
          id: 42065,
          arcs: [[2744, 2745, 2746, -2704, -2585, -2583]],
        },
        { type: "Polygon", id: 6105, arcs: [[2747, 2748, 2749, -2684, -2329]] },
        {
          type: "Polygon",
          id: 36119,
          arcs: [[2750, 2751, -2632, -2549, 2752]],
        },
        {
          type: "Polygon",
          id: 34037,
          arcs: [[2753, 2754, 2755, 2756, -2603, -2568]],
        },
        { type: "Polygon", id: 39153, arcs: [[2757, 2758, 2759, 2760, -2577]] },
        {
          type: "Polygon",
          id: 39133,
          arcs: [[-2669, 2761, 2762, -2758, -2576, -2525]],
        },
        {
          type: "Polygon",
          id: 17131,
          arcs: [[-2620, 2763, 2764, 2765, 2766, -2718, -2449]],
        },
        { type: "Polygon", id: 17155, arcs: [[-2574, 2767, -2614]] },
        {
          type: "Polygon",
          id: 36087,
          arcs: [[2768, 2769, -2566, -2631, 2770]],
        },
        {
          type: "Polygon",
          id: 42037,
          arcs: [[2771, 2772, 2773, -2607, -2613, -2714]],
        },
        {
          type: "Polygon",
          id: 17091,
          arcs: [[-2533, 2774, 2775, 2776, 2777, -2687, -2512]],
        },
        {
          type: "Polygon",
          id: 18183,
          arcs: [[2778, 2779, 2780, -2697, -2626]],
        },
        { type: "MultiPolygon", id: 36103, arcs: [[[2781, 2782]]] },
        {
          type: "Polygon",
          id: 39077,
          arcs: [[-2636, 2783, 2784, 2785, 2786, -2671, -2592]],
        },
        {
          type: "Polygon",
          id: 18073,
          arcs: [[-2710, 2787, 2788, 2789, 2790, -2531, -2535]],
        },
        {
          type: "Polygon",
          id: 39103,
          arcs: [[-2761, 2791, 2792, -2634, -2578]],
        },
        {
          type: "Polygon",
          id: 18003,
          arcs: [[2793, 2794, 2795, 2796, 2797, -2779, -2625, -2623, -2717]],
        },
        {
          type: "Polygon",
          id: 39147,
          arcs: [[-2787, 2798, 2799, 2800, -2598, -2672]],
        },
        {
          type: "Polygon",
          id: 42033,
          arcs: [[2801, 2802, 2803, 2804, -2745, -2582, -2602, -2683]],
        },
        {
          type: "Polygon",
          id: 49043,
          arcs: [[-2621, -2196, 2805, 2806, 2807, 2808, -2742, -2345]],
        },
        {
          type: "Polygon",
          id: 42027,
          arcs: [[2809, 2810, 2811, 2812, -2802, -2682]],
        },
        {
          type: "Polygon",
          id: 42089,
          arcs: [[-2757, 2813, 2814, 2815, -2711, -2563, -2376, -2604]],
        },
        { type: "Polygon", id: 39125, arcs: [[2816, 2817, -2794, -2716]] },
        {
          type: "Polygon",
          id: 17175,
          arcs: [[-2616, 2818, 2819, 2820, -2618]],
        },
        {
          type: "Polygon",
          id: 31049,
          arcs: [[-2723, 2821, 2822, -2700, -2334]],
        },
        {
          type: "Polygon",
          id: 18111,
          arcs: [[2823, 2824, -2775, -2532, -2791]],
        },
        {
          type: "Polygon",
          id: 34031,
          arcs: [[2825, 2826, -2754, -2567, -2770, 2827]],
        },
        {
          type: "Polygon",
          id: 31153,
          arcs: [[-2660, 2828, 2829, -2694, -2741]],
        },
        { type: "Polygon", id: 6035, arcs: [[-2395, 2830, 2831, 2832, -2385]] },
        {
          type: "Polygon",
          id: 6089,
          arcs: [[-2386, -2833, 2833, 2834, -2748, -2328]],
        },
        {
          type: "Polygon",
          id: 42097,
          arcs: [[2835, -2773, 2836, 2837, 2838, 2839, 2840, -2609]],
        },
        {
          type: "Polygon",
          id: 18049,
          arcs: [[2841, 2842, 2843, 2844, -2679, -2699]],
        },
        {
          type: "Polygon",
          id: 31081,
          arcs: [[2845, 2846, 2847, 2848, -2734, -2730]],
        },
        {
          type: "Polygon",
          id: 18131,
          arcs: [[-2845, 2849, 2850, -2788, -2709]],
        },
        { type: "Polygon", id: 42093, arcs: [[-2774, -2836, -2608]] },
        {
          type: "Polygon",
          id: 42019,
          arcs: [[-2706, 2851, 2852, 2853, 2854, -2673, -2589]],
        },
        {
          type: "Polygon",
          id: 42005,
          arcs: [[-2747, 2855, 2856, 2857, -2852, -2705]],
        },
        {
          type: "Polygon",
          id: 39063,
          arcs: [[-2801, 2858, 2859, 2860, 2861, -2599]],
        },
        {
          type: "Polygon",
          id: 39137,
          arcs: [[-2862, 2862, 2863, -2817, -2715, -2676]],
        },
        {
          type: "Polygon",
          id: 19101,
          arcs: [[-2640, 2864, 2865, 2866, -2646]],
        },
        {
          type: "Polygon",
          id: 19087,
          arcs: [[-2720, 2867, 2868, 2869, -2865, -2639]],
        },
        {
          type: "Polygon",
          id: 19179,
          arcs: [[-2867, 2870, 2871, -2652, -2647]],
        },
        {
          type: "Polygon",
          id: 19039,
          arcs: [[2872, 2873, 2874, -2649, -2644]],
        },
        {
          type: "Polygon",
          id: 19117,
          arcs: [[-2656, 2875, 2876, -2873, -2643]],
        },
        {
          type: "Polygon",
          id: 19135,
          arcs: [[-2872, 2877, -2876, -2655, -2653]],
        },
        {
          type: "Polygon",
          id: 19129,
          arcs: [[2878, 2879, 2880, -2829, -2659]],
        },
        {
          type: "Polygon",
          id: 19137,
          arcs: [[-2664, 2881, 2882, -2879, -2658]],
        },
        {
          type: "Polygon",
          id: 19003,
          arcs: [[-2666, 2883, 2884, -2882, -2663]],
        },
        {
          type: "Polygon",
          id: 19175,
          arcs: [[-2650, -2875, 2885, -2884, -2665]],
        },
        { type: "Polygon", id: 49011, arcs: [[-2744, 2886, 2887, -2708]] },
        {
          type: "Polygon",
          id: 17095,
          arcs: [[-2821, 2888, 2889, 2890, -2764, -2619]],
        },
        {
          type: "Polygon",
          id: 42119,
          arcs: [[2891, 2892, -2810, -2681, -2610, -2841]],
        },
        {
          type: "Polygon",
          id: 17123,
          arcs: [[-2768, -2573, 2893, 2894, -2819, -2615]],
        },
        {
          type: "Polygon",
          id: 34003,
          arcs: [[2895, 2896, -2828, -2769, 2897]],
        },
        {
          type: "Polygon",
          id: 39099,
          arcs: [[-2675, 2898, 2899, 2900, -2762, -2668]],
        },
        {
          type: "Polygon",
          id: 42025,
          arcs: [[-2816, 2901, 2902, 2903, -2712]],
        },
        {
          type: "Polygon",
          id: 42073,
          arcs: [[-2855, 2904, 2905, -2899, -2674]],
        },
        {
          type: "Polygon",
          id: 17105,
          arcs: [[-2778, 2906, 2907, 2908, -2571, -2688]],
        },
        {
          type: "Polygon",
          id: 34041,
          arcs: [[2909, 2910, 2911, 2912, -2814, -2756]],
        },
        {
          type: "Polygon",
          id: 34027,
          arcs: [[2913, 2914, 2915, 2916, -2910, -2755, -2827]],
        },
        {
          type: "Polygon",
          id: 49045,
          arcs: [[2917, 2918, 2919, 2920, -2362, -2356, -2888]],
        },
        {
          type: "Polygon",
          id: 19057,
          arcs: [[-2767, 2921, 2922, 2923, -2868, -2719]],
        },
        {
          type: "Polygon",
          id: 17071,
          arcs: [[2924, 2925, 2926, 2927, -2922, -2766]],
        },
        {
          type: "Polygon",
          id: 17187,
          arcs: [[-2891, 2928, 2929, -2925, -2765]],
        },
        {
          type: "Polygon",
          id: 39005,
          arcs: [[-2793, 2930, 2931, 2932, 2933, -2784, -2635]],
        },
        {
          type: "Polygon",
          id: 31025,
          arcs: [[-2881, 2934, 2935, 2936, -2695, -2830]],
        },
        {
          type: "Polygon",
          id: 31185,
          arcs: [[2937, 2938, 2939, -2846, -2729]],
        },
        {
          type: "Polygon",
          id: 31079,
          arcs: [[-2735, -2849, 2940, 2941, -2737]],
        },
        {
          type: "Polygon",
          id: 31047,
          arcs: [[2942, 2943, 2944, 2945, -2724, -2496]],
        },
        {
          type: "Polygon",
          id: 31019,
          arcs: [[-2738, -2942, 2946, 2947, 2948, -2943, -2495, -2740]],
        },
        { type: "Polygon", id: 31159, arcs: [[2949, 2950, -2938, -2691]] },
        {
          type: "Polygon",
          id: 31109,
          arcs: [[-2937, 2951, 2952, 2953, -2950, -2690, -2696]],
        },
        {
          type: "Polygon",
          id: 18169,
          arcs: [[-2781, 2954, 2955, 2956, -2842, -2698]],
        },
        {
          type: "Polygon",
          id: 17075,
          arcs: [[-2825, 2957, 2958, 2959, -2776]],
        },
        {
          type: "Polygon",
          id: 18069,
          arcs: [[-2798, 2960, 2961, -2955, -2780]],
        },
        {
          type: "Polygon",
          id: 8123,
          arcs: [[2962, 2963, 2964, 2965, 2966, -2552, -2733, 2967]],
        },
        {
          type: "Polygon",
          id: 31135,
          arcs: [[-2727, 2968, 2969, 2970, 2971, -2822, -2722]],
        },
        {
          type: "Polygon",
          id: 8107,
          arcs: [[2972, 2973, 2974, 2975, 2976, -2136, 2977]],
        },
        {
          type: "Polygon",
          id: 8057,
          arcs: [[2978, -2978, -2135, -2141, 2979]],
        },
        {
          type: "Polygon",
          id: 8081,
          arcs: [[-2977, 2980, 2981, 2982, -2194, -2137]],
        },
        {
          type: "Polygon",
          id: 8075,
          arcs: [[2983, 2984, 2985, -2968, -2732, -2702, 2986, 2987]],
        },
        {
          type: "Polygon",
          id: 8115,
          arcs: [[2988, -2987, -2701, -2823, -2972]],
        },
        {
          type: "Polygon",
          id: 49009,
          arcs: [[2989, 2990, -2806, -2195, -2983]],
        },
        { type: "Polygon", id: 32011, arcs: [[2991, 2992, 2993, -2364]] },
        {
          type: "Polygon",
          id: 32015,
          arcs: [[-2994, 2994, 2995, 2996, -2358, -2365]],
        },
        {
          type: "Polygon",
          id: 8069,
          arcs: [[2997, 2998, -2980, -2140, -2553, -2967]],
        },
        {
          type: "Polygon",
          id: 18103,
          arcs: [[-2957, 2999, 3000, 3001, -2843]],
        },
        {
          type: "Polygon",
          id: 39033,
          arcs: [[3002, 3003, 3004, 3005, -2799, -2786]],
        },
        {
          type: "Polygon",
          id: 17053,
          arcs: [[3006, 3007, 3008, -2907, -2777, -2960]],
        },
        {
          type: "Polygon",
          id: 39139,
          arcs: [[-2934, 3009, 3010, -3003, -2785]],
        },
        {
          type: "Polygon",
          id: 39175,
          arcs: [[-3006, 3011, 3012, -2859, -2800]],
        },
        {
          type: "Polygon",
          id: 39169,
          arcs: [[-2760, 3013, 3014, -2931, -2792]],
        },
        {
          type: "Polygon",
          id: 39161,
          arcs: [[-2864, 3015, 3016, 3017, 3018, -2795, -2818]],
        },
        {
          type: "Polygon",
          id: 39151,
          arcs: [[-2901, 3019, 3020, 3021, 3022, -3014, -2759, -2763]],
        },
        {
          type: "Polygon",
          id: 17143,
          arcs: [[-2895, 3023, 3024, 3025, -2889, -2820]],
        },
        {
          type: "Polygon",
          id: 42095,
          arcs: [[3026, 3027, -2902, -2815, -2913]],
        },
        { type: "Polygon", id: 32027, arcs: [[-2997, 3028, -2387, -2359]] },
        {
          type: "Polygon",
          id: 42107,
          arcs: [[-2904, 3029, 3030, 3031, 3032, -2837, -2772, -2713]],
        },
        {
          type: "Polygon",
          id: 39029,
          arcs: [[-2906, 3033, 3034, 3035, 3036, -3020, -2900]],
        },
        {
          type: "Polygon",
          id: 17203,
          arcs: [[3037, 3038, -3024, -2894, -2572, -2909]],
        },
        {
          type: "Polygon",
          id: 18001,
          arcs: [[3039, 3040, 3041, -2796, -3019]],
        },
        {
          type: "Polygon",
          id: 39003,
          arcs: [[-2861, 3042, 3043, -3016, -2863]],
        },
        {
          type: "Polygon",
          id: 49035,
          arcs: [[-2743, -2809, 3044, 3045, -2918, -2887]],
        },
        { type: "Polygon", id: 36005, arcs: [[3046, 3047, 3048, -2751]] },
        {
          type: "Polygon",
          id: 18179,
          arcs: [[-3042, 3049, 3050, 3051, -2961, -2797]],
        },
        {
          type: "Polygon",
          id: 36059,
          arcs: [[-2782, 3052, 3053, 3054, 3055, 3056]],
        },
        {
          type: "Polygon",
          id: 18181,
          arcs: [[3057, 3058, 3059, 3060, -2789, -2851]],
        },
        {
          type: "Polygon",
          id: 18017,
          arcs: [[-2844, -3002, 3061, 3062, -3058, -2850]],
        },
        {
          type: "Polygon",
          id: 42063,
          arcs: [[-2805, 3063, 3064, -2856, -2746]],
        },
        {
          type: "Polygon",
          id: 34013,
          arcs: [[-2826, -2897, 3065, 3066, 3067, -2914]],
        },
        {
          type: "Polygon",
          id: 19071,
          arcs: [[3068, 3069, 3070, -2935, -2880]],
        },
        {
          type: "Polygon",
          id: 19145,
          arcs: [[3071, 3072, 3073, -3069, -2883]],
        },
        {
          type: "Polygon",
          id: 19173,
          arcs: [[3074, 3075, 3076, -3072, -2885]],
        },
        {
          type: "Polygon",
          id: 19177,
          arcs: [[-2870, 3077, 3078, 3079, 3080, -2866]],
        },
        {
          type: "Polygon",
          id: 19051,
          arcs: [[-3081, 3081, 3082, 3083, -2871]],
        },
        {
          type: "Polygon",
          id: 19159,
          arcs: [[3084, 3085, 3086, -3075, -2886]],
        },
        {
          type: "Polygon",
          id: 19053,
          arcs: [[3087, 3088, 3089, -3085, -2874]],
        },
        {
          type: "Polygon",
          id: 19185,
          arcs: [[3090, 3091, 3092, -3088, -2877]],
        },
        {
          type: "Polygon",
          id: 19007,
          arcs: [[-3084, 3093, 3094, -3091, -2878]],
        },
        { type: "Polygon", id: 42109, arcs: [[-2840, 3095, 3096, -2892]] },
        { type: "Polygon", id: 36061, arcs: [[3097, -3048]] },
        {
          type: "Polygon",
          id: 49047,
          arcs: [[-2982, 3098, 3099, 3100, 3101, 3102, 3103, -2990]],
        },
        {
          type: "Polygon",
          id: 42007,
          arcs: [[-2854, 3104, 3105, 3106, -3034, -2905]],
        },
        {
          type: "Polygon",
          id: 42087,
          arcs: [[3107, -2811, -2893, -3097, 3108]],
        },
        {
          type: "Polygon",
          id: 49013,
          arcs: [[-2991, -3104, 3109, 3110, 3111, -2807]],
        },
        {
          type: "Polygon",
          id: 39065,
          arcs: [[-3013, 3112, 3113, 3114, 3115, -3043, -2860]],
        },
        { type: "Polygon", id: 34017, arcs: [[3116, -3066, -2896]] },
        {
          type: "Polygon",
          id: 19111,
          arcs: [[-2924, -2923, -2928, 3117, 3118, -3078, -2869]],
        },
        { type: "Polygon", id: 36081, arcs: [[-3056, 3119, 3120, 3121]] },
        {
          type: "Polygon",
          id: 34019,
          arcs: [[3122, 3123, 3124, -2911, -2917]],
        },
        {
          type: "Polygon",
          id: 42077,
          arcs: [[3125, 3126, 3127, -3030, -2903, -3028]],
        },
        {
          type: "Polygon",
          id: 31131,
          arcs: [[-3071, 3128, 3129, 3130, -2952, -2936]],
        },
        {
          type: "Polygon",
          id: 34035,
          arcs: [[3131, 3132, 3133, -3123, -2916]],
        },
        {
          type: "Polygon",
          id: 17113,
          arcs: [[-3009, 3134, 3135, 3136, 3137, 3138, -3038, -2908]],
        },
        {
          type: "Polygon",
          id: 8095,
          arcs: [[-2971, 3139, 3140, -2988, -2989]],
        },
        {
          type: "Polygon",
          id: 17179,
          arcs: [[-3139, 3141, 3142, 3143, -3025, -3039]],
        },
        {
          type: "Polygon",
          id: 42061,
          arcs: [[-3108, 3144, 3145, 3146, 3147, 3148, 3149, -2812]],
        },
        {
          type: "Polygon",
          id: 42013,
          arcs: [[-3149, 3150, 3151, -2803, -2813, -3150]],
        },
        { type: "Polygon", id: 36047, arcs: [[3152, -3121]] },
        {
          type: "Polygon",
          id: 18007,
          arcs: [[-2790, -3061, 3153, 3154, 3155, -2958, -2824]],
        },
        {
          type: "Polygon",
          id: 34039,
          arcs: [[3156, 3157, -3132, -2915, -3068]],
        },
        {
          type: "Polygon",
          id: 18015,
          arcs: [[-3063, 3158, 3159, 3160, -3059]],
        },
        {
          type: "Polygon",
          id: 39019,
          arcs: [[-3037, 3161, 3162, 3163, -3021]],
        },
        {
          type: "Polygon",
          id: 39107,
          arcs: [[3164, 3165, 3166, 3167, -3040, -3018]],
        },
        {
          type: "Polygon",
          id: 42021,
          arcs: [[-3152, 3168, 3169, 3170, -3064, -2804]],
        },
        {
          type: "Polygon",
          id: 17057,
          arcs: [[-3026, -3144, 3171, 3172, 3173, -2929, -2890]],
        },
        {
          type: "Polygon",
          id: 39117,
          arcs: [[-3011, 3174, 3175, 3176, -3004]],
        },
        {
          type: "Polygon",
          id: 39101,
          arcs: [[-3177, 3177, 3178, -3113, -3012, -3005]],
        },
        {
          type: "Polygon",
          id: 31063,
          arcs: [[-2946, 3179, 3180, 3181, 3182, 3183, -2725]],
        },
        {
          type: "Polygon",
          id: 31001,
          arcs: [[-2848, 3184, 3185, 3186, -2947, -2941]],
        },
        { type: "Polygon", id: 31073, arcs: [[3187, 3188, -3180, -2945]] },
        {
          type: "Polygon",
          id: 31085,
          arcs: [[-3184, 3189, 3190, 3191, -2969, -2726]],
        },
        {
          type: "Polygon",
          id: 31029,
          arcs: [[-3192, 3192, 3193, -3140, -2970]],
        },
        { type: "Polygon", id: 31059, arcs: [[3194, 3195, 3196, 3197, -2939]] },
        {
          type: "Polygon",
          id: 31151,
          arcs: [[-2951, -2954, 3198, 3199, -3195]],
        },
        {
          type: "Polygon",
          id: 31035,
          arcs: [[-3198, 3200, 3201, -3185, -2847, -2940]],
        },
        {
          type: "Polygon",
          id: 42067,
          arcs: [[-2839, 3202, 3203, -3145, -3109, -3096]],
        },
        { type: "Polygon", id: 49051, arcs: [[-3112, 3204, -3045, -2808]] },
        {
          type: "Polygon",
          id: 31099,
          arcs: [[3205, 3206, 3207, -2948, -3187]],
        },
        {
          type: "Polygon",
          id: 39011,
          arcs: [[-3116, 3208, 3209, -3165, -3017, -3044]],
        },
        {
          type: "Polygon",
          id: 31137,
          arcs: [[-2949, -3208, 3210, 3211, -3188, -2944]],
        },
        {
          type: "Polygon",
          id: 42129,
          arcs: [[-3065, -3171, 3212, 3213, 3214, 3215, -2857]],
        },
        {
          type: "Polygon",
          id: 42011,
          arcs: [[3216, 3217, 3218, 3219, -3031, -3128]],
        },
        {
          type: "Polygon",
          id: 42003,
          arcs: [[-2858, -3216, 3220, -3105, -2853]],
        },
        {
          type: "Polygon",
          id: 39075,
          arcs: [[-3023, 3221, 3222, 3223, -2932, -3015]],
        },
        {
          type: "Polygon",
          id: 39157,
          arcs: [[-3164, 3224, 3225, 3226, -3222, -3022]],
        },
        {
          type: "Polygon",
          id: 42043,
          arcs: [[-3033, 3227, 3228, 3229, 3230, 3231, -2838]],
        },
        {
          type: "Polygon",
          id: 18053,
          arcs: [[-3052, 3232, 3233, 3234, 3235, 3236, -3000, -2956, -2962]],
        },
        { type: "Polygon", id: 36085, arcs: [[3237]] },
        {
          type: "Polygon",
          id: 17067,
          arcs: [[3238, 3239, 3240, 3241, 3242, -3118, -2927]],
        },
        {
          type: "Polygon",
          id: 54029,
          arcs: [[3243, 3244, 3245, -3035, -3107]],
        },
        {
          type: "Polygon",
          id: 17109,
          arcs: [[-3174, 3246, -3239, -2926, -2930]],
        },
        { type: "Polygon", id: 42099, arcs: [[3247, 3248, -3203, -3232]] },
        {
          type: "Polygon",
          id: 29045,
          arcs: [[-3243, 3249, 3250, 3251, -3079, -3119]],
        },
        {
          type: "Polygon",
          id: 42017,
          arcs: [[3252, 3253, 3254, 3255, -3126, -3027, -2912, -3125]],
        },
        {
          type: "Polygon",
          id: 34023,
          arcs: [[3256, 3257, 3258, -3133, -3158]],
        },
        {
          type: "Polygon",
          id: 29199,
          arcs: [[-3252, 3259, 3260, 3261, -3082, -3080]],
        },
        {
          type: "Polygon",
          id: 39081,
          arcs: [[-3246, 3262, 3263, 3264, 3265, -3162, -3036]],
        },
        {
          type: "Polygon",
          id: 29197,
          arcs: [[3266, 3267, -3094, -3083, -3262]],
        },
        {
          type: "Polygon",
          id: 29171,
          arcs: [[-3268, 3268, 3269, 3270, -3092, -3095]],
        },
        {
          type: "Polygon",
          id: 29005,
          arcs: [[-3074, 3271, 3272, 3273, -3129, -3070]],
        },
        {
          type: "Polygon",
          id: 29129,
          arcs: [[-3271, 3274, 3275, 3276, -3089, -3093]],
        },
        {
          type: "Polygon",
          id: 29147,
          arcs: [[-3077, 3277, 3278, 3279, 3280, -3272, -3073]],
        },
        {
          type: "Polygon",
          id: 49049,
          arcs: [[-3111, 3281, 3282, 3283, -2919, -3046, -3205]],
        },
        {
          type: "Polygon",
          id: 29081,
          arcs: [[-3277, 3284, 3285, 3286, 3287, -3086, -3090]],
        },
        {
          type: "Polygon",
          id: 39083,
          arcs: [[3288, 3289, 3290, -3175, -3010, -2933, -3224]],
        },
        {
          type: "Polygon",
          id: 18075,
          arcs: [[-3168, 3291, 3292, 3293, 3294, -3050, -3041]],
        },
        {
          type: "Polygon",
          id: 29227,
          arcs: [[-3288, 3295, -3278, -3076, -3087]],
        },
        { type: "Polygon", id: 18009, arcs: [[-3295, 3296, -3233, -3051]] },
        {
          type: "Polygon",
          id: 18067,
          arcs: [[3297, 3298, -3159, -3062, -3001, -3237]],
        },
        {
          type: "Polygon",
          id: 18157,
          arcs: [[-3161, 3299, 3300, 3301, 3302, -3154, -3060]],
        },
        {
          type: "Polygon",
          id: 31127,
          arcs: [[3303, 3304, 3305, 3306, -3130, -3274]],
        },
        { type: "Polygon", id: 42075, arcs: [[-3228, -3032, -3220, 3307]] },
        {
          type: "Polygon",
          id: 39091,
          arcs: [[-3115, 3308, 3309, 3310, -3209]],
        },
        { type: "Polygon", id: 8087, arcs: [[-2986, 3311, 3312, -2963]] },
        {
          type: "Polygon",
          id: 31067,
          arcs: [[3313, 3314, 3315, 3316, 3317, -3199, -2953]],
        },
        { type: "Polygon", id: 31097, arcs: [[-3307, 3318, -3314, -3131]] },
        {
          type: "Polygon",
          id: 39159,
          arcs: [[3319, 3320, 3321, -3309, -3114, -3179, 3322]],
        },
        {
          type: "Polygon",
          id: 17183,
          arcs: [[-3156, 3323, 3324, 3325, 3326, -3007, -2959]],
        },
        {
          type: "Polygon",
          id: 8049,
          arcs: [[3327, 3328, 3329, 3330, 3331, -2973, -2979, -2999]],
        },
        {
          type: "Polygon",
          id: 39149,
          arcs: [[-3311, 3332, 3333, 3334, -3166, -3210]],
        },
        {
          type: "Polygon",
          id: 42125,
          arcs: [[-3221, -3215, 3335, 3336, 3337, 3338, 3339, -3244, -3106]],
        },
        {
          type: "Polygon",
          id: 18171,
          arcs: [[-3303, 3340, 3341, -3324, -3155]],
        },
        {
          type: "Polygon",
          id: 34025,
          arcs: [[3342, 3343, 3344, 3345, -3258, 3346]],
        },
        {
          type: "Polygon",
          id: 39031,
          arcs: [[-3227, 3347, 3348, 3349, -3289, -3223]],
        },
        {
          type: "Polygon",
          id: 42091,
          arcs: [[3350, 3351, 3352, 3353, -3217, -3127, -3256]],
        },
        {
          type: "Polygon",
          id: 6103,
          arcs: [[3354, 3355, 3356, 3357, -2749, -2835]],
        },
        {
          type: "Polygon",
          id: 6063,
          arcs: [[-2832, 3358, 3359, 3360, -3355, -2834]],
        },
        {
          type: "Polygon",
          id: 39041,
          arcs: [[-3291, 3361, 3362, -3323, -3178, -3176]],
        },
        {
          type: "Polygon",
          id: 8125,
          arcs: [[-3194, 3363, 3364, 3365, 3366, -2984, -3141]],
        },
        {
          type: "Polygon",
          id: 8121,
          arcs: [[-3367, 3367, 3368, 3369, 3370, -3312, -2985]],
        },
        {
          type: "Polygon",
          id: 17125,
          arcs: [[3371, 3372, 3373, 3374, -3172, -3143]],
        },
        {
          type: "Polygon",
          id: 39067,
          arcs: [[-3266, 3375, 3376, -3225, -3163]],
        },
        {
          type: "Polygon",
          id: 18023,
          arcs: [[-3299, 3377, 3378, 3379, 3380, -3300, -3160]],
        },
        {
          type: "Polygon",
          id: 34021,
          arcs: [[-3259, -3346, 3381, -3253, -3124, -3134]],
        },
        {
          type: "Polygon",
          id: 18159,
          arcs: [[-3236, 3382, 3383, -3378, -3298]],
        },
        { type: "Polygon", id: 54009, arcs: [[-3340, 3384, -3263, -3245]] },
        {
          type: "Polygon",
          id: 17019,
          arcs: [[-3327, 3385, 3386, -3135, -3008]],
        },
        {
          type: "Polygon",
          id: 29211,
          arcs: [[3387, 3388, 3389, -3275, -3270]],
        },
        {
          type: "Polygon",
          id: 29075,
          arcs: [[-3287, 3390, 3391, 3392, -3279, -3296]],
        },
        {
          type: "Polygon",
          id: 18095,
          arcs: [[3393, 3394, 3395, 3396, -3383, -3235]],
        },
        {
          type: "Polygon",
          id: 18035,
          arcs: [[-3297, -3294, 3397, 3398, -3394, -3234]],
        },
        {
          type: "Polygon",
          id: 18045,
          arcs: [[-3302, 3399, 3400, 3401, -3341]],
        },
        {
          type: "Polygon",
          id: 39037,
          arcs: [[-3335, 3402, 3403, 3404, 3405, 3406, -3292, -3167]],
        },
        {
          type: "Polygon",
          id: 31181,
          arcs: [[-3202, 3407, 3408, 3409, 3410, -3206, -3186]],
        },
        {
          type: "Polygon",
          id: 31061,
          arcs: [[-3411, 3411, 3412, 3413, -3207]],
        },
        {
          type: "Polygon",
          id: 31129,
          arcs: [[-3197, 3414, 3415, 3416, -3408, -3201]],
        },
        {
          type: "Polygon",
          id: 31057,
          arcs: [[3417, 3418, 3419, -3364, -3193, -3191]],
        },
        {
          type: "Polygon",
          id: 31065,
          arcs: [[-3212, 3420, 3421, 3422, 3423, -3181, -3189]],
        },
        { type: "Polygon", id: 31169, arcs: [[3424, 3425, -3415, -3196]] },
        { type: "Polygon", id: 31095, arcs: [[-3200, -3318, 3426, -3425]] },
        {
          type: "Polygon",
          id: 31145,
          arcs: [[-3424, 3427, 3428, 3429, -3182]],
        },
        {
          type: "Polygon",
          id: 31087,
          arcs: [[-3183, -3430, 3430, -3418, -3190]],
        },
        {
          type: "Polygon",
          id: 31083,
          arcs: [[-3414, 3431, 3432, -3421, -3211]],
        },
        {
          type: "Polygon",
          id: 29001,
          arcs: [[-3261, 3433, 3434, 3435, -3388, -3269, -3267]],
        },
        {
          type: "Polygon",
          id: 42041,
          arcs: [[3436, 3437, 3438, -3248, -3231]],
        },
        {
          type: "Polygon",
          id: 17107,
          arcs: [[3439, 3440, 3441, 3442, -3372, -3142, -3138]],
        },
        {
          type: "Polygon",
          id: 42009,
          arcs: [[-3148, 3443, 3444, 3445, -3169, -3151]],
        },
        {
          type: "Polygon",
          id: 42071,
          arcs: [[3446, 3447, 3448, 3449, -3229, -3308, -3219]],
        },
        {
          type: "Polygon",
          id: 18135,
          arcs: [[-3407, 3450, 3451, -3398, -3293]],
        },
        {
          type: "Polygon",
          id: 29103,
          arcs: [[-3251, 3452, 3453, 3454, -3434, -3260]],
        },
        {
          type: "Polygon",
          id: 42055,
          arcs: [[-3439, 3455, 3456, 3457, 3458, -3146, -3204, -3249]],
        },
        {
          type: "Polygon",
          id: 42111,
          arcs: [[-3446, 3459, 3460, 3461, -3213, -3170]],
        },
        { type: "Polygon", id: 17039, arcs: [[3462, 3463, -3440, -3137]] },
        {
          type: "Polygon",
          id: 17169,
          arcs: [[-3173, -3375, 3464, 3465, 3466, -3240, -3247]],
        },
        {
          type: "Polygon",
          id: 17147,
          arcs: [[-3387, 3467, 3468, 3469, -3463, -3136]],
        },
        {
          type: "Polygon",
          id: 39089,
          arcs: [[-3350, 3470, 3471, 3472, 3473, -3362, -3290]],
        },
        {
          type: "Polygon",
          id: 39021,
          arcs: [[-3322, 3474, 3475, 3476, -3333, -3310]],
        },
        {
          type: "Polygon",
          id: 29079,
          arcs: [[-3390, 3477, 3478, 3479, -3285, -3276]],
        },
        {
          type: "Polygon",
          id: 8013,
          arcs: [[-2966, 3480, 3481, 3482, -3328, -2998]],
        },
        {
          type: "Polygon",
          id: 31133,
          arcs: [[-3306, 3483, 3484, 3485, -3315, -3319]],
        },
        {
          type: "Polygon",
          id: 31147,
          arcs: [[3486, 3487, 3488, 3489, -3484, -3305]],
        },
        {
          type: "Polygon",
          id: 29087,
          arcs: [[-3281, 3490, 3491, -3487, -3304, -3273]],
        },
        {
          type: "Polygon",
          id: 29111,
          arcs: [[-3242, 3492, 3493, 3494, -3453, -3250]],
        },
        {
          type: "Polygon",
          id: 42029,
          arcs: [[3495, 3496, 3497, -3447, -3218, -3354]],
        },
        {
          type: "Polygon",
          id: 42133,
          arcs: [[-3450, 3498, 3499, 3500, 3501, -3437, -3230]],
        },
        { type: "Polygon", id: 8103, arcs: [[3502, -3099, -2981, -2976]] },
        {
          type: "Polygon",
          id: 39059,
          arcs: [[3503, 3504, -3348, -3226, -3377, 3505]],
        },
        {
          type: "Polygon",
          id: 18057,
          arcs: [[3506, 3507, 3508, -3379, -3384, -3397]],
        },
        {
          type: "Polygon",
          id: 18107,
          arcs: [[-3381, 3509, 3510, 3511, 3512, -3400, -3301]],
        },
        {
          type: "Polygon",
          id: 17001,
          arcs: [[-3467, 3513, 3514, 3515, -3493, -3241]],
        },
        {
          type: "Polygon",
          id: 39109,
          arcs: [[-3477, 3516, 3517, -3403, -3334]],
        },
        {
          type: "Polygon",
          id: 54069,
          arcs: [[-3339, 3518, 3519, -3264, -3385]],
        },
        {
          type: "Polygon",
          id: 34005,
          arcs: [[-3345, 3520, 3521, 3522, 3523, 3524, 3525, -3254, -3382]],
        },
        {
          type: "Polygon",
          id: 18011,
          arcs: [[-3509, 3526, 3527, -3510, -3380]],
        },
        {
          type: "Polygon",
          id: 39013,
          arcs: [[-3265, -3520, 3528, 3529, 3530, -3506, -3376]],
        },
        { type: "Polygon", id: 34029, arcs: [[-3343, 3531, -3521, -3344]] },
        {
          type: "Polygon",
          id: 39119,
          arcs: [[-3505, 3532, 3533, 3534, -3471, -3349]],
        },
        {
          type: "Polygon",
          id: 42057,
          arcs: [[-3459, 3535, 3536, -3444, -3147]],
        },
        { type: "Polygon", id: 17129, arcs: [[-3443, 3537, 3538, -3373]] },
        {
          type: "Polygon",
          id: 6007,
          arcs: [[3539, 3540, 3541, 3542, -3356, -3361]],
        },
        {
          type: "Polygon",
          id: 18165,
          arcs: [[-3402, 3543, 3544, 3545, -3325, -3342]],
        },
        {
          type: "Polygon",
          id: 42051,
          arcs: [[-3462, 3546, 3547, 3548, 3549, -3336, -3214]],
        },
        {
          type: "Polygon",
          id: 39049,
          arcs: [[-3474, 3550, 3551, 3552, -3320, -3363]],
        },
        {
          type: "Polygon",
          id: 42101,
          arcs: [[-3526, 3553, 3554, -3352, -3351, -3255]],
        },
        {
          type: "Polygon",
          id: 29061,
          arcs: [[-3286, -3480, 3555, 3556, 3557, -3391]],
        },
        {
          type: "Polygon",
          id: 29003,
          arcs: [[-3393, 3558, 3559, 3560, -3491, -3280]],
        },
        {
          type: "Polygon",
          id: 32033,
          arcs: [[-2921, 3561, 3562, 3563, 3564, -2992, -2363]],
        },
        {
          type: "Polygon",
          id: 17017,
          arcs: [[-3374, -3539, 3565, 3566, 3567, -3465]],
        },
        {
          type: "Polygon",
          id: 39097,
          arcs: [[3568, 3569, 3570, 3571, -3475, -3321, -3553]],
        },
        {
          type: "Polygon",
          id: 17009,
          arcs: [[-3568, 3572, 3573, -3514, -3466]],
        },
        {
          type: "Polygon",
          id: 8045,
          arcs: [[-2975, 3574, 3575, 3576, 3577, -3100, -3503]],
        },
        {
          type: "Polygon",
          id: 18065,
          arcs: [[-3452, 3578, 3579, 3580, 3581, -3395, -3399]],
        },
        {
          type: "Polygon",
          id: 42001,
          arcs: [[3582, 3583, -3456, -3438, -3502]],
        },
        {
          type: "Polygon",
          id: 42045,
          arcs: [[-3555, 3584, 3585, 3586, 3587, -3496, -3353]],
        },
        {
          type: "Polygon",
          id: 17115,
          arcs: [[-3470, 3588, 3589, 3590, 3591, -3441, -3464]],
        },
        { type: "Polygon", id: 8014, arcs: [[3592, 3593, 3594, -3481, -2965]] },
        {
          type: "Polygon",
          id: 29121,
          arcs: [[-3455, 3595, 3596, 3597, 3598, 3599, -3435]],
        },
        {
          type: "Polygon",
          id: 39023,
          arcs: [[-3572, 3600, 3601, -3517, -3476]],
        },
        {
          type: "Polygon",
          id: 29115,
          arcs: [[-3600, 3602, 3603, -3478, -3389, -3436]],
        },
        {
          type: "Polygon",
          id: 29063,
          arcs: [[-3558, 3604, 3605, 3606, -3559, -3392]],
        },
        {
          type: "Polygon",
          id: 54051,
          arcs: [[3607, 3608, -3529, -3519, -3338, 3609]],
        },
        {
          type: "Polygon",
          id: 42059,
          arcs: [[-3550, 3610, 3611, -3610, -3337]],
        },
        {
          type: "Polygon",
          id: 49023,
          arcs: [[-3284, 3612, 3613, -3562, -2920]],
        },
        {
          type: "Polygon",
          id: 18177,
          arcs: [[-3406, 3614, 3615, 3616, -3579, -3451]],
        },
        {
          type: "Polygon",
          id: 20023,
          arcs: [[3617, 3618, 3619, -3365, -3420]],
        },
        {
          type: "Polygon",
          id: 20153,
          arcs: [[-3429, 3620, 3621, 3622, -3618, -3419, -3431]],
        },
        {
          type: "Polygon",
          id: 6045,
          arcs: [[-2750, -3358, 3623, 3624, 3625, 3626, -2685]],
        },
        {
          type: "Polygon",
          id: 20089,
          arcs: [[3627, 3628, 3629, 3630, 3631, -3409, -3417]],
        },
        {
          type: "Polygon",
          id: 20183,
          arcs: [[-3632, 3632, 3633, 3634, -3412, -3410]],
        },
        {
          type: "Polygon",
          id: 20157,
          arcs: [[3635, 3636, -3628, -3416, -3426]],
        },
        {
          type: "Polygon",
          id: 20201,
          arcs: [[-3317, 3637, 3638, 3639, 3640, -3636, -3427]],
        },
        {
          type: "Polygon",
          id: 20039,
          arcs: [[-3423, 3641, 3642, 3643, -3621, -3428]],
        },
        {
          type: "Polygon",
          id: 32001,
          arcs: [[-2996, 3644, 3645, 3646, -2388, -3029]],
        },
        {
          type: "Polygon",
          id: 20137,
          arcs: [[3647, 3648, 3649, -3642, -3422, -3433]],
        },
        {
          type: "Polygon",
          id: 20147,
          arcs: [[-3635, 3650, 3651, -3648, -3432, -3413]],
        },
        {
          type: "Polygon",
          id: 20117,
          arcs: [[-3486, 3652, 3653, 3654, -3638, -3316]],
        },
        {
          type: "Polygon",
          id: 8001,
          arcs: [[-3371, 3655, 3656, 3657, -3594, -3593, -2964, -3313]],
        },
        { type: "Polygon", id: 20013, arcs: [[3658, 3659, 3660, 3661, -3489]] },
        {
          type: "Polygon",
          id: 20131,
          arcs: [[-3490, -3662, 3662, 3663, -3653, -3485]],
        },
        {
          type: "Polygon",
          id: 20043,
          arcs: [[-3492, -3561, 3664, 3665, -3659, -3488]],
        },
        { type: "Polygon", id: 34007, arcs: [[3666, 3667, 3668, -3524]] },
        {
          type: "Polygon",
          id: 17167,
          arcs: [[-3592, 3669, 3670, 3671, 3672, -3566, -3538, -3442]],
        },
        {
          type: "Polygon",
          id: 29117,
          arcs: [[3673, 3674, 3675, -3556, -3479, -3604]],
        },
        {
          type: "Polygon",
          id: 29205,
          arcs: [[-3495, 3676, 3677, -3596, -3454]],
        },
        {
          type: "Polygon",
          id: 18121,
          arcs: [[-3513, 3678, 3679, 3680, -3544, -3401]],
        },
        {
          type: "Polygon",
          id: 39121,
          arcs: [[-3531, 3681, 3682, 3683, -3533, -3504]],
        },
        {
          type: "Polygon",
          id: 29127,
          arcs: [[-3516, 3684, 3685, 3686, -3677, -3494]],
        },
        {
          type: "Polygon",
          id: 18059,
          arcs: [[-3582, 3687, 3688, 3689, -3507, -3396]],
        },
        {
          type: "Polygon",
          id: 39045,
          arcs: [[-3473, 3690, 3691, 3692, -3551]],
        },
        { type: "Polygon", id: 8047, arcs: [[3693, 3694, -3329, -3483]] },
        {
          type: "Polygon",
          id: 39127,
          arcs: [[3695, 3696, 3697, -3691, -3472, -3535]],
        },
        {
          type: "Polygon",
          id: 18097,
          arcs: [[-3690, 3698, 3699, 3700, 3701, -3527, -3508]],
        },
        {
          type: "Polygon",
          id: 8037,
          arcs: [[3702, 3703, 3704, -3575, -2974, -3332]],
        },
        { type: "Polygon", id: 8117, arcs: [[3705, 3706, 3707, -3703, -3331]] },
        {
          type: "Polygon",
          id: 18063,
          arcs: [[-3702, 3708, 3709, -3511, -3528]],
        },
        {
          type: "Polygon",
          id: 39113,
          arcs: [[-3602, 3710, 3711, 3712, 3713, -3404, -3518]],
        },
        {
          type: "Polygon",
          id: 39135,
          arcs: [[-3714, 3714, 3715, -3615, -3405]],
        },
        {
          type: "Polygon",
          id: 8059,
          arcs: [
            [-3658, 3716, 3717, 3718, 3719, 3720, 3721, -3694, -3482, -3595],
          ],
        },
        { type: "Polygon", id: 8031, arcs: [[3722, -3717, -3657]] },
        { type: "Polygon", id: 34015, arcs: [[3723, 3724, 3725, 3726, -3668]] },
        {
          type: "Polygon",
          id: 17045,
          arcs: [[3727, 3728, 3729, 3730, -3326, -3546]],
        },
        {
          type: "Polygon",
          id: 17041,
          arcs: [[-3731, 3731, 3732, -3468, -3386]],
        },
        {
          type: "Polygon",
          id: 17137,
          arcs: [[-3673, 3733, 3734, 3735, 3736, -3573, -3567]],
        },
        {
          type: "Polygon",
          id: 39111,
          arcs: [[-3609, 3737, 3738, 3739, -3682, -3530]],
        },
        {
          type: "Polygon",
          id: 18133,
          arcs: [[-3710, 3740, 3741, 3742, -3679, -3512]],
        },
        {
          type: "Polygon",
          id: 8019,
          arcs: [[-3722, 3743, -3706, -3330, -3695]],
        },
        {
          type: "Polygon",
          id: 39057,
          arcs: [[-3571, 3744, 3745, 3746, -3711, -3601]],
        },
        {
          type: "Polygon",
          id: 17149,
          arcs: [[-3737, 3747, 3748, 3749, 3750, 3751, -3685, -3515, -3574]],
        },
        {
          type: "Polygon",
          id: 10003,
          arcs: [[3752, 3753, 3754, 3755, -3497, -3588, 3756, -3586]],
        },
        { type: "Polygon", id: 17021, arcs: [[-3591, 3757, 3758, -3670]] },
        {
          type: "Polygon",
          id: 29021,
          arcs: [[-3607, 3759, 3760, 3761, -3665, -3560]],
        },
        {
          type: "Polygon",
          id: 49007,
          arcs: [[-3110, -3103, 3762, 3763, -3282]],
        },
        {
          type: "Polygon",
          id: 49039,
          arcs: [[-3764, 3764, 3765, 3766, -3613, -3283]],
        },
        {
          type: "Polygon",
          id: 39129,
          arcs: [[-3693, 3767, 3768, 3769, -3569, -3552]],
        },
        {
          type: "Polygon",
          id: 6021,
          arcs: [[-3543, 3770, 3771, -3624, -3357]],
        },
        {
          type: "Polygon",
          id: 17139,
          arcs: [[-3733, 3772, 3773, -3589, -3469]],
        },
        {
          type: "Polygon",
          id: 18041,
          arcs: [[3774, 3775, 3776, -3580, -3617]],
        },
        {
          type: "Polygon",
          id: 18139,
          arcs: [[-3777, 3777, 3778, 3779, -3688, -3581]],
        },
        { type: "Polygon", id: 17171, arcs: [[3780, -3748, -3736]] },
        {
          type: "Polygon",
          id: 29025,
          arcs: [[-3676, 3781, 3782, 3783, -3605, -3557]],
        },
        {
          type: "Polygon",
          id: 34033,
          arcs: [[3784, 3785, 3786, 3787, 3788, 3789, -3726]],
        },
        {
          type: "Polygon",
          id: 6091,
          arcs: [[-2831, -2394, 3790, 3791, -3359]],
        },
        {
          type: "Polygon",
          id: 39115,
          arcs: [[-3684, 3792, 3793, -3696, -3534]],
        },
        {
          type: "Polygon",
          id: 29049,
          arcs: [[-3784, 3794, 3795, 3796, -3760, -3606]],
        },
        {
          type: "Polygon",
          id: 8005,
          arcs: [[-3370, 3797, 3798, 3799, -3718, -3723, -3656]],
        },
        {
          type: "Polygon",
          id: 32019,
          arcs: [[3800, 3801, 3802, 3803, 3804, -2389, -3647]],
        },
        {
          type: "Polygon",
          id: 34001,
          arcs: [[3805, 3806, 3807, -3724, -3667, -3523]],
        },
        {
          type: "Polygon",
          id: 18161,
          arcs: [[-3716, 3808, 3809, -3775, -3616]],
        },
        {
          type: "Polygon",
          id: 24043,
          arcs: [[-3458, 3810, 3811, 3812, 3813, 3814, 3815, -3536]],
        },
        {
          type: "Polygon",
          id: 24001,
          arcs: [[-3537, -3816, 3816, 3817, 3818, 3819, -3460, -3445]],
        },
        {
          type: "Polygon",
          id: 24023,
          arcs: [[-3820, 3820, 3821, 3822, -3547, -3461]],
        },
        {
          type: "Polygon",
          id: 24015,
          arcs: [[-3756, 3823, 3824, 3825, -3448, -3498]],
        },
        {
          type: "Polygon",
          id: 54061,
          arcs: [[-3549, 3826, 3827, 3828, 3829, -3611]],
        },
        {
          type: "Polygon",
          id: 54077,
          arcs: [[-3823, 3830, 3831, 3832, 3833, -3827, -3548]],
        },
        {
          type: "Polygon",
          id: 24025,
          arcs: [[-3449, -3826, 3834, 3835, -3499]],
        },
        {
          type: "Polygon",
          id: 54103,
          arcs: [[-3612, -3830, 3836, 3837, 3838, 3839, -3738, -3608]],
        },
        {
          type: "Polygon",
          id: 24005,
          arcs: [[-3836, 3840, 3841, 3842, 3843, 3844, -3500]],
        },
        {
          type: "Polygon",
          id: 24013,
          arcs: [[-3845, 3845, 3846, -3583, -3501]],
        },
        {
          type: "Polygon",
          id: 24021,
          arcs: [[-3847, 3847, 3848, 3849, -3811, -3457, -3584]],
        },
        {
          type: "Polygon",
          id: 39047,
          arcs: [[-3770, 3850, 3851, 3852, -3745, -3570]],
        },
        {
          type: "Polygon",
          id: 49015,
          arcs: [[-3102, 3853, 3854, 3855, -3765, -3763]],
        },
        {
          type: "Polygon",
          id: 29041,
          arcs: [[-3599, 3856, 3857, 3858, 3859, -3674, -3603]],
        },
        {
          type: "Polygon",
          id: 18145,
          arcs: [[-3780, 3860, 3861, 3862, -3699, -3689]],
        },
        {
          type: "Polygon",
          id: 54065,
          arcs: [[3863, 3864, 3865, -3817, -3815]],
        },
        {
          type: "Polygon",
          id: 29173,
          arcs: [[-3752, 3866, 3867, 3868, -3686]],
        },
        {
          type: "Polygon",
          id: 17029,
          arcs: [[-3730, 3869, 3870, 3871, -3773, -3732]],
        },
        {
          type: "Polygon",
          id: 29137,
          arcs: [[-3687, -3869, 3872, 3873, -3597, -3678]],
        },
        {
          type: "Polygon",
          id: 39073,
          arcs: [[3874, 3875, 3876, -3768, -3692, -3698]],
        },
        {
          type: "Polygon",
          id: 20029,
          arcs: [[-3641, 3877, 3878, 3879, -3629, -3637]],
        },
        {
          type: "Polygon",
          id: 20085,
          arcs: [[3880, 3881, 3882, 3883, -3663, -3661]],
        },
        {
          type: "Polygon",
          id: 20005,
          arcs: [[-3762, 3884, 3885, 3886, -3881, -3660, -3666]],
        },
        {
          type: "Polygon",
          id: 17173,
          arcs: [[-3774, -3872, 3887, 3888, 3889, 3890, -3758, -3590]],
        },
        { type: "Polygon", id: 54057, arcs: [[3891, 3892, -3821, -3819]] },
        {
          type: "Polygon",
          id: 39167,
          arcs: [[-3740, 3893, 3894, 3895, 3896, -3793, -3683]],
        },
        { type: "Polygon", id: 54049, arcs: [[-3829, 3897, 3898, -3837]] },
        {
          type: "Polygon",
          id: 18081,
          arcs: [[-3863, 3899, 3900, 3901, -3700]],
        },
        {
          type: "Polygon",
          id: 6115,
          arcs: [[-3792, 3902, 3903, 3904, -3540, -3360]],
        },
        {
          type: "Polygon",
          id: 18109,
          arcs: [[-3902, 3905, 3906, 3907, -3741, -3709, -3701]],
        },
        { type: "Polygon", id: 32029, arcs: [[-2390, -3805]] },
        { type: "Polygon", id: 54003, arcs: [[-3814, 3908, 3909, -3864]] },
        {
          type: "Polygon",
          id: 29033,
          arcs: [[-3860, 3910, 3911, 3912, -3782, -3675]],
        },
        {
          type: "Polygon",
          id: 29175,
          arcs: [[-3874, 3913, 3914, 3915, -3857, -3598]],
        },
        {
          type: "Polygon",
          id: 18167,
          arcs: [[3916, 3917, 3918, -3728, -3545, -3681]],
        },
        {
          type: "Polygon",
          id: 18021,
          arcs: [[-3743, 3919, 3920, 3921, -3917, -3680]],
        },
        {
          type: "Polygon",
          id: 54095,
          arcs: [[3922, 3923, 3924, -3894, -3739, -3840]],
        },
        {
          type: "Polygon",
          id: 29163,
          arcs: [[3925, 3926, 3927, -3867, -3751, 3928]],
        },
        {
          type: "Polygon",
          id: 39017,
          arcs: [[3929, 3930, 3931, -3809, -3715, -3713]],
        },
        {
          type: "Polygon",
          id: 39165,
          arcs: [[-3747, 3932, 3933, 3934, -3930, -3712]],
        },
        {
          type: "Polygon",
          id: 6033,
          arcs: [[3935, 3936, 3937, 3938, -3625, -3772]],
        },
        {
          type: "Polygon",
          id: 8063,
          arcs: [[-3620, 3939, 3940, 3941, 3942, -3368, -3366]],
        },
        {
          type: "Polygon",
          id: 34011,
          arcs: [[3943, 3944, -3785, -3725, -3808, 3945, 3946]],
        },
        {
          type: "Polygon",
          id: 20163,
          arcs: [[3947, 3948, 3949, 3950, -3651, -3634]],
        },
        {
          type: "Polygon",
          id: 39027,
          arcs: [[3951, 3952, -3933, -3746, -3853, 3953]],
        },
        {
          type: "Polygon",
          id: 20181,
          arcs: [[-3623, 3954, 3955, 3956, -3940, -3619]],
        },
        {
          type: "Polygon",
          id: 20193,
          arcs: [[-3644, 3957, 3958, 3959, -3955, -3622]],
        },
        {
          type: "Polygon",
          id: 20141,
          arcs: [[-3631, 3960, 3961, 3962, 3963, -3948, -3633]],
        },
        {
          type: "Polygon",
          id: 20179,
          arcs: [[-3650, 3964, 3965, -3958, -3643]],
        },
        {
          type: "Polygon",
          id: 20027,
          arcs: [[3966, 3967, 3968, 3969, -3878, -3640]],
        },
        {
          type: "Polygon",
          id: 20123,
          arcs: [[-3880, 3970, 3971, -3961, -3630]],
        },
        {
          type: "Polygon",
          id: 20065,
          arcs: [[-3951, 3972, 3973, -3965, -3649, -3652]],
        },
        {
          type: "Polygon",
          id: 20161,
          arcs: [[3974, 3975, 3976, -3967, -3639, -3655]],
        },
        { type: "Polygon", id: 8035, arcs: [[3977, 3978, 3979, -3719, -3800]] },
        { type: "Polygon", id: 8039, arcs: [[3980, 3981, -3978, -3799]] },
        {
          type: "Polygon",
          id: 20149,
          arcs: [[-3664, -3884, 3982, 3983, -3975, -3654]],
        },
        {
          type: "Polygon",
          id: 8073,
          arcs: [[-3943, 3984, 3985, 3986, 3987, -3981, -3798, -3369]],
        },
        {
          type: "Polygon",
          id: 8093,
          arcs: [[-3721, 3988, 3989, 3990, 3991, -3707, -3744]],
        },
        {
          type: "Polygon",
          id: 39009,
          arcs: [[-3897, 3992, 3993, 3994, -3875, -3697, -3794]],
        },
        {
          type: "Polygon",
          id: 49027,
          arcs: [[-3767, 3995, 3996, 3997, -3563, -3614]],
        },
        {
          type: "Polygon",
          id: 54027,
          arcs: [[-3866, 3998, 3999, -3892, -3818]],
        },
        {
          type: "Polygon",
          id: 29165,
          arcs: [[-3761, -3797, 4000, 4001, 4002, -3885]],
        },
        {
          type: "Polygon",
          id: 18047,
          arcs: [[-3810, -3932, 4003, 4004, 4005, -3778, -3776]],
        },
        {
          type: "Polygon",
          id: 29177,
          arcs: [[-3913, 4006, 4007, 4008, -3795, -3783]],
        },
        {
          type: "Polygon",
          id: 17135,
          arcs: [[-3759, -3891, 4009, 4010, 4011, 4012, -3671]],
        },
        {
          type: "Polygon",
          id: 17117,
          arcs: [[-4013, 4013, 4014, 4015, -3734, -3672]],
        },
        { type: "Polygon", id: 6057, arcs: [[-2393, 4016, -3903, -3791]] },
        {
          type: "Polygon",
          id: 17061,
          arcs: [[-3735, -4016, 4017, 4018, -3749, -3781]],
        },
        {
          type: "Polygon",
          id: 39141,
          arcs: [[-3877, 4019, 4020, 4021, 4022, -3851, -3769]],
        },
        { type: "Polygon", id: 54037, arcs: [[4023, 4024, -3909, -3813]] },
        {
          type: "Polygon",
          id: 49019,
          arcs: [[-3578, 4025, 4026, -3854, -3101]],
        },
        {
          type: "Polygon",
          id: 17023,
          arcs: [[-3919, 4027, 4028, 4029, 4030, -3870, -3729]],
        },
        { type: "Polygon", id: 54073, arcs: [[4031, 4032, -3895, -3925]] },
        {
          type: "Polygon",
          id: 18119,
          arcs: [[-3908, 4033, 4034, -3920, -3742]],
        },
        {
          type: "Polygon",
          id: 54033,
          arcs: [[4035, 4036, 4037, 4038, 4039, -3838, -3899]],
        },
        {
          type: "Polygon",
          id: 51069,
          arcs: [[4040, 4041, 4042, 4043, -3999, -3865, -3910]],
        },
        {
          type: "Polygon",
          id: 29047,
          arcs: [[-3796, -4009, 4044, 4045, -4001]],
        },
        {
          type: "Polygon",
          id: 18031,
          arcs: [[-4006, 4046, 4047, 4048, -3861, -3779]],
        },
        {
          type: "Polygon",
          id: 54091,
          arcs: [[4049, -4036, -3898, -3828, -3834]],
        },
        {
          type: "Polygon",
          id: 54017,
          arcs: [[-4040, 4050, 4051, 4052, -3923, -3839]],
        },
        {
          type: "Polygon",
          id: 20087,
          arcs: [[4053, 4054, 4055, -3882, -3887]],
        },
        {
          type: "Polygon",
          id: 20103,
          arcs: [[-4003, 4056, 4057, 4058, -4054, -3886]],
        },
        {
          type: "Polygon",
          id: 6011,
          arcs: [[-3542, 4059, 4060, -3936, -3771]],
        },
        {
          type: "Polygon",
          id: 29195,
          arcs: [[-3859, 4061, 4062, 4063, 4064, -3911]],
        },
        {
          type: "Polygon",
          id: 54107,
          arcs: [[-4033, 4065, 4066, 4067, 4068, -3993, -3896]],
        },
        {
          type: "Polygon",
          id: 17013,
          arcs: [[-4019, 4069, 4070, 4071, -3929, -3750]],
        },
        {
          type: "Polygon",
          id: 39163,
          arcs: [[-3995, 4072, 4073, 4074, -4020, -3876]],
        },
        {
          type: "Polygon",
          id: 54085,
          arcs: [[-4053, 4075, 4076, 4077, -4066, -4032, -3924]],
        },
        {
          type: "Polygon",
          id: 24029,
          arcs: [[-3755, 4078, 4079, 4080, -3824]],
        },
        {
          type: "Polygon",
          id: 8065,
          arcs: [[-3708, -3992, 4081, 4082, -3704]],
        },
        {
          type: "Polygon",
          id: 17035,
          arcs: [[-4031, 4083, 4084, -3888, -3871]],
        },
        {
          type: "Polygon",
          id: 39071,
          arcs: [[-4023, 4085, 4086, 4087, -3954, -3852]],
        },
        { type: "Polygon", id: 24510, arcs: [[4088, 4089, -3842]] },
        {
          type: "Polygon",
          id: 24027,
          arcs: [[-3844, 4090, 4091, 4092, -3848, -3846]],
        },
        {
          type: "Polygon",
          id: 8077,
          arcs: [[4093, 4094, 4095, 4096, -4026, -3577]],
        },
        {
          type: "Polygon",
          id: 8097,
          arcs: [[-4083, 4097, 4098, -4094, -3576, -3705]],
        },
        {
          type: "Polygon",
          id: 10001,
          arcs: [[4099, 4100, 4101, -4079, -3754, 4102]],
        },
        {
          type: "Polygon",
          id: 18105,
          arcs: [[4103, 4104, 4105, 4106, -4034, -3907]],
        },
        {
          type: "Polygon",
          id: 18005,
          arcs: [[-4049, 4107, 4108, 4109, -3900, -3862]],
        },
        {
          type: "Polygon",
          id: 29007,
          arcs: [[-3868, -3928, 4110, 4111, 4112, -3914, -3873]],
        },
        {
          type: "Polygon",
          id: 24031,
          arcs: [[4113, 4114, 4115, 4116, -3849, -4093]],
        },
        {
          type: "Polygon",
          id: 18013,
          arcs: [[-4110, 4117, -4104, -3906, -3901]],
        },
        {
          type: "Polygon",
          id: 29089,
          arcs: [[4118, -4062, -3858, -3916, 4119]],
        },
        {
          type: "Polygon",
          id: 54023,
          arcs: [[4120, 4121, 4122, 4123, -3831, -3822, -3893]],
        },
        { type: "Polygon", id: 34009, arcs: [[4124, -3946, -3807]] },
        {
          type: "Polygon",
          id: 51107,
          arcs: [[-4117, 4125, 4126, 4127, 4128, -4024, -3812, -3850]],
        },
        {
          type: "Polygon",
          id: 6061,
          arcs: [[-2392, 4129, 4130, 4131, 4132, 4133, -3904, -4017]],
        },
        {
          type: "Polygon",
          id: 39061,
          arcs: [[-3935, 4134, 4135, 4136, 4137, 4138, -3931]],
        },
        {
          type: "Polygon",
          id: 18137,
          arcs: [[4139, 4140, 4141, 4142, 4143, -4047, -4005]],
        },
        {
          type: "Polygon",
          id: 18029,
          arcs: [[-4004, -4139, 4144, 4145, -4140]],
        },
        {
          type: "Polygon",
          id: 20143,
          arcs: [[-3970, 4146, 4147, 4148, -3971, -3879]],
        },
        {
          type: "Polygon",
          id: 6101,
          arcs: [[-3905, -4134, 4149, 4150, -4060, -3541]],
        },
        {
          type: "Polygon",
          id: 54001,
          arcs: [[4151, 4152, 4153, -4037, -4050, -3833]],
        },
        {
          type: "Polygon",
          id: 29107,
          arcs: [[4154, 4155, 4156, -4007, -3912, -4065]],
        },
        { type: "Polygon", id: 54093, arcs: [[4157, -4152, -3832, -4124]] },
        {
          type: "Polygon",
          id: 39025,
          arcs: [[4158, 4159, 4160, -4135, -3934, -3953, 4161]],
        },
        {
          type: "Polygon",
          id: 51043,
          arcs: [[-4129, 4162, 4163, -4041, -4025]],
        },
        {
          type: "Polygon",
          id: 17083,
          arcs: [[-4015, 4164, 4165, -4070, -4018]],
        },
        {
          type: "MultiPolygon",
          id: 24035,
          arcs: [[[4166]], [[-4102, 4167, 4168, 4169, -4080]]],
        },
        {
          type: "Polygon",
          id: 18153,
          arcs: [[-3922, 4170, 4171, 4172, -4028, -3918]],
        },
        {
          type: "Polygon",
          id: 8051,
          arcs: [[-4099, 4173, 4174, 4175, 4176, 4177, 4178, -4095]],
        },
        {
          type: "Polygon",
          id: 39015,
          arcs: [[4179, 4180, 4181, -4162, -3952, -4088]],
        },
        {
          type: "Polygon",
          id: 29019,
          arcs: [[-4113, 4182, 4183, 4184, 4185, -4120, -3915]],
        },
        { type: "Polygon", id: 32510, arcs: [[4186, -4130, -2391, -3804]] },
        {
          type: "Polygon",
          id: 54031,
          arcs: [[-4044, 4187, 4188, 4189, -4121, -4000]],
        },
        {
          type: "Polygon",
          id: 24003,
          arcs: [[4190, 4191, -4091, -3843, -4090, 4192, 4193, 4194]],
        },
        {
          type: "Polygon",
          id: 29095,
          arcs: [[-4008, -4157, 4195, 4196, 4197, 4198, -4045]],
        },
        {
          type: "Polygon",
          id: 29113,
          arcs: [[-4072, 4199, 4200, 4201, -3926]],
        },
        {
          type: "Polygon",
          id: 20061,
          arcs: [[4202, 4203, 4204, -3968, -3977]],
        },
        { type: "Polygon", id: 8029, arcs: [[-4179, 4205, -4096]] },
        {
          type: "Polygon",
          id: 20105,
          arcs: [[-4149, 4206, 4207, 4208, -3962, -3972]],
        },
        {
          type: "Polygon",
          id: 20177,
          arcs: [[-4056, 4209, 4210, 4211, -3983, -3883]],
        },
        {
          type: "Polygon",
          id: 17051,
          arcs: [[-3890, 4212, 4213, 4214, 4215, 4216, -4010]],
        },
        {
          type: "Polygon",
          id: 17049,
          arcs: [[-4085, 4217, 4218, -4213, -3889]],
        },
        {
          type: "Polygon",
          id: 20197,
          arcs: [[-4212, 4219, 4220, 4221, -4203, -3976, -3984]],
        },
        {
          type: "Polygon",
          id: 39079,
          arcs: [[4222, 4223, 4224, 4225, -4021, -4075]],
        },
        {
          type: "Polygon",
          id: 39105,
          arcs: [[4226, 4227, -4073, -3994, -4069, 4228]],
        },
        {
          type: "Polygon",
          id: 20209,
          arcs: [[-4002, -4046, -4199, 4229, -4057]],
        },
        {
          type: "Polygon",
          id: 39131,
          arcs: [[4230, 4231, -4086, -4022, -4226]],
        },
        {
          type: "Polygon",
          id: 18079,
          arcs: [[4232, 4233, 4234, -4108, -4048, -4144]],
        },
        {
          type: "Polygon",
          id: 54105,
          arcs: [[4235, 4236, 4237, -4067, -4078]],
        },
        {
          type: "Polygon",
          id: 17033,
          arcs: [[-4173, 4238, 4239, 4240, 4241, -4029]],
        },
        {
          type: "Polygon",
          id: 17079,
          arcs: [[-4242, 4242, 4243, -4218, -4084, -4030]],
        },
        {
          type: "Polygon",
          id: 18055,
          arcs: [[-4035, -4107, 4244, 4245, 4246, 4247, -4171, -3921]],
        },
        {
          type: "Polygon",
          id: 54041,
          arcs: [[4248, 4249, 4250, 4251, -4051, -4039]],
        },
        {
          type: "Polygon",
          id: 32023,
          arcs: [[-2993, -3565, 4252, 4253, 4254, 4255, 4256, -3645, -2995]],
        },
        {
          type: "Polygon",
          id: 29139,
          arcs: [[-3927, -4202, 4257, 4258, 4259, 4260, -4111]],
        },
        {
          type: "Polygon",
          id: 21015,
          arcs: [[4261, 4262, 4263, 4264, 4265, -4145, -4138]],
        },
        {
          type: "Polygon",
          id: 24011,
          arcs: [[4266, 4267, 4268, 4269, -4168, -4101]],
        },
        {
          type: "Polygon",
          id: 20109,
          arcs: [[4270, 4271, 4272, 4273, -3956, -3960]],
        },
        {
          type: "Polygon",
          id: 20199,
          arcs: [[-4274, 4274, 4275, 4276, -3941, -3957]],
        },
        {
          type: "Polygon",
          id: 20051,
          arcs: [[-3964, 4277, 4278, 4279, 4280, -3949]],
        },
        {
          type: "Polygon",
          id: 20063,
          arcs: [[-3966, -3974, 4281, 4282, 4283, 4284, -4271, -3959]],
        },
        {
          type: "Polygon",
          id: 20041,
          arcs: [[-4205, 4285, 4286, 4287, -4147, -3969]],
        },
        {
          type: "Polygon",
          id: 20167,
          arcs: [[-4209, 4288, 4289, 4290, -4278, -3963]],
        },
        {
          type: "Polygon",
          id: 20195,
          arcs: [[-3950, -4281, 4291, -4282, -3973]],
        },
        {
          type: "Polygon",
          id: 24033,
          arcs: [[-4192, 4292, 4293, 4294, 4295, 4296, -4114, -4092]],
        },
        {
          type: "Polygon",
          id: 8119,
          arcs: [[4297, 4298, -3989, -3720, -3980]],
        },
        {
          type: "Polygon",
          id: 8041,
          arcs: [[-3982, -3988, 4299, 4300, -4298, -3979]],
        },
        { type: "Polygon", id: 21037, arcs: [[-4161, 4301, 4302, -4136]] },
        {
          type: "Polygon",
          id: 54083,
          arcs: [[-4123, 4303, 4304, 4305, 4306, -4153, -4158]],
        },
        {
          type: "Polygon",
          id: 32005,
          arcs: [[-3803, 4307, 4308, 4309, -4131, -4187]],
        },
        {
          type: "Polygon",
          id: 54097,
          arcs: [[-4154, -4307, 4310, -4249, -4038]],
        },
        {
          type: "Polygon",
          id: 54021,
          arcs: [[-4252, 4311, 4312, -4076, -4052]],
        },
        {
          type: "Polygon",
          id: 51171,
          arcs: [[4313, 4314, 4315, -4188, -4043]],
        },
        {
          type: "Polygon",
          id: 21117,
          arcs: [[-4303, 4316, 4317, -4262, -4137]],
        },
        {
          type: "Polygon",
          id: 54035,
          arcs: [[-4238, 4318, 4319, 4320, 4321, -4229, -4068]],
        },
        {
          type: "Polygon",
          id: 32021,
          arcs: [[-4257, 4322, 4323, -3801, -3646]],
        },
        {
          type: "Polygon",
          id: 18071,
          arcs: [[-4235, 4324, 4325, 4326, -4105, -4118, -4109]],
        },
        {
          type: "Polygon",
          id: 20045,
          arcs: [[-4059, 4327, 4328, 4329, -4210, -4055]],
        },
        { type: "Polygon", id: 6017, arcs: [[-4310, 4330, 4331, 4332, -4132]] },
        {
          type: "Polygon",
          id: 29027,
          arcs: [[-4112, -4261, 4333, 4334, -4183]],
        },
        {
          type: "Polygon",
          id: 29053,
          arcs: [[-4186, 4335, 4336, 4337, -4063, -4119]],
        },
        {
          type: "Polygon",
          id: 51059,
          arcs: [[4338, 4339, 4340, 4341, 4342, 4343, -4126, -4116]],
        },
        {
          type: "Polygon",
          id: 8015,
          arcs: [[-3991, 4344, 4345, -4174, -4098, -4082]],
        },
        {
          type: "Polygon",
          id: 20091,
          arcs: [[-4198, 4346, 4347, -4328, -4058, -4230]],
        },
        {
          type: "Polygon",
          id: 39001,
          arcs: [[4348, 4349, 4350, -4180, -4087, -4232]],
        },
        {
          type: "Polygon",
          id: 49041,
          arcs: [[-3856, 4351, 4352, 4353, -3996, -3766]],
        },
        {
          type: "Polygon",
          id: 8017,
          arcs: [[-4277, 4354, 4355, -3985, -3942]],
        },
        {
          type: "Polygon",
          id: 54013,
          arcs: [[-4313, 4356, 4357, 4358, -4236, -4077]],
        },
        {
          type: "Polygon",
          id: 51187,
          arcs: [[4359, 4360, 4361, -4314, -4042, -4164]],
        },
        {
          type: "Polygon",
          id: 39053,
          arcs: [[4362, 4363, 4364, -4223, -4074, -4228]],
        },
        { type: "Polygon", id: 18115, arcs: [[4365, -4141, -4146, -4266]] },
        {
          type: "Polygon",
          id: 54053,
          arcs: [[-4322, 4366, 4367, -4363, -4227]],
        },
        { type: "Polygon", id: 17005, arcs: [[-4217, 4368, 4369, -4011]] },
        {
          type: "Polygon",
          id: 39145,
          arcs: [[-4225, 4370, 4371, 4372, -4349, -4231]],
        },
        {
          type: "Polygon",
          id: 51061,
          arcs: [[4373, 4374, 4375, 4376, -4360, -4163, -4128]],
        },
        {
          type: "Polygon",
          id: 17119,
          arcs: [[-4014, -4012, -4370, 4377, 4378, 4379, 4380, 4381, -4165]],
        },
        {
          type: "Polygon",
          id: 29219,
          arcs: [[4382, 4383, 4384, -4258, -4201]],
        },
        { type: "Polygon", id: 11001, arcs: [[4385, 4386, -4115, -4297]] },
        {
          type: "Polygon",
          id: 18093,
          arcs: [[-4327, 4387, 4388, 4389, -4245, -4106]],
        },
        {
          type: "Polygon",
          id: 29183,
          arcs: [[-4382, 4390, 4391, -4383, -4200, -4071, -4166]],
        },
        {
          type: "Polygon",
          id: 10005,
          arcs: [[4392, 4393, 4394, 4395, 4396, 4397, -4267, -4100]],
        },
        {
          type: "Polygon",
          id: 20169,
          arcs: [[-4288, 4398, 4399, -4207, -4148]],
        },
        {
          type: "Polygon",
          id: 54071,
          arcs: [[-4190, 4400, 4401, 4402, 4403, -4304, -4122]],
        },
        {
          type: "Polygon",
          id: 29159,
          arcs: [[-4338, 4404, 4405, 4406, 4407, -4155, -4064]],
        },
        { type: "Polygon", id: 24041, arcs: [[-4270, 4408, -4169]] },
        {
          type: "Polygon",
          id: 51153,
          arcs: [[-4344, 4409, 4410, -4374, -4127]],
        },
        {
          type: "Polygon",
          id: 54087,
          arcs: [[-4359, 4411, 4412, -4319, -4237]],
        },
        {
          type: "Polygon",
          id: 29101,
          arcs: [[-4408, 4413, 4414, -4196, -4156]],
        },
        {
          type: "Polygon",
          id: 51013,
          arcs: [[4415, -4341, 4416, -4339, -4387, 4417]],
        },
        {
          type: "Polygon",
          id: 6003,
          arcs: [[4418, 4419, 4420, 4421, -4331, -4309]],
        },
        {
          type: "Polygon",
          id: 18155,
          arcs: [[-4265, 4422, 4423, 4424, -4142, -4366]],
        },
        {
          type: "Polygon",
          id: 29135,
          arcs: [[4425, 4426, 4427, -4336, -4185]],
        },
        {
          type: "Polygon",
          id: 6113,
          arcs: [[-4151, 4428, 4429, 4430, -4431, 4431, -3937, -4061]],
        },
        {
          type: "Polygon",
          id: 18077,
          arcs: [[-4425, 4432, 4433, 4434, 4435, -4233, -4143]],
        },
        {
          type: "Polygon",
          id: 17025,
          arcs: [[-4244, 4436, 4437, 4438, -4214, -4219]],
        },
        {
          type: "Polygon",
          id: 18083,
          arcs: [[4439, 4440, 4441, 4442, 4443, 4444, -4239, -4172, -4248]],
        },
        {
          type: "Polygon",
          id: 18101,
          arcs: [[-4390, 4445, 4446, 4447, -4246]],
        },
        {
          type: "Polygon",
          id: 18027,
          arcs: [[4448, 4449, -4440, -4247, -4448]],
        },
        {
          type: "Polygon",
          id: 54007,
          arcs: [[-4251, 4450, 4451, 4452, -4357, -4312]],
        },
        {
          type: "Polygon",
          id: 29189,
          arcs: [[-4381, 4453, 4454, 4455, 4456, 4457, -4391]],
        },
        {
          type: "Polygon",
          id: 21191,
          arcs: [[4458, 4459, 4460, -4317, -4302, -4160, 4461]],
        },
        {
          type: "Polygon",
          id: 20127,
          arcs: [[-4222, 4462, 4463, 4464, -4286, -4204]],
        },
        {
          type: "Polygon",
          id: 20053,
          arcs: [[-4400, 4465, 4466, 4467, -4289, -4208]],
        },
        {
          type: "Polygon",
          id: 20139,
          arcs: [[-4330, 4468, 4469, 4470, -4220, -4211]],
        },
        {
          type: "Polygon",
          id: 6055,
          arcs: [[4430, 4471, 4472, 4473, 4474, 4475, -3938, -4432]],
        },
        {
          type: "Polygon",
          id: 51157,
          arcs: [[4476, 4477, 4478, -4361, -4377]],
        },
        {
          type: "Polygon",
          id: 21077,
          arcs: [[4479, 4480, 4481, -4423, -4264]],
        },
        {
          type: "Polygon",
          id: 6097,
          arcs: [[-3939, -4476, 4482, 4483, 4484, -3626]],
        },
        { type: "Polygon", id: 17101, arcs: [[4485, 4486, -4240, -4445]] },
        {
          type: "Polygon",
          id: 51165,
          arcs: [[-4316, 4487, 4488, 4489, 4490, -4401, -4189]],
        },
        {
          type: "Polygon",
          id: 17159,
          arcs: [[-4241, -4487, 4491, 4492, 4493, -4437, -4243]],
        },
        {
          type: "Polygon",
          id: 39087,
          arcs: [[4494, 4495, 4496, 4497, -4371, -4224, -4365]],
        },
        {
          type: "Polygon",
          id: 29037,
          arcs: [[-4415, 4498, 4499, 4500, -4347, -4197]],
        },
        {
          type: "Polygon",
          id: 51139,
          arcs: [[-4479, 4501, 4502, -4488, -4315, -4362]],
        },
        {
          type: "Polygon",
          id: 18143,
          arcs: [[-4436, 4503, 4504, -4325, -4234]],
        },
        {
          type: "Polygon",
          id: 21023,
          arcs: [[-4182, 4505, 4506, 4507, -4462, -4159]],
        },
        {
          type: "Polygon",
          id: 17121,
          arcs: [[-4439, 4508, 4509, 4510, 4511, -4215]],
        },
        {
          type: "Polygon",
          id: 21081,
          arcs: [[-4318, -4461, 4512, 4513, 4514, -4480, -4263]],
        },
        {
          type: "Polygon",
          id: 18175,
          arcs: [[-4505, 4515, 4516, 4517, 4518, 4519, -4388, -4326]],
        },
        { type: "Polygon", id: 29510, arcs: [[-4454, -4380, 4520]] },
        { type: "Polygon", id: 24009, arcs: [[4521, -4293, -4191]] },
        {
          type: "Polygon",
          id: 21161,
          arcs: [[-4351, 4522, 4523, 4524, -4506, -4181]],
        },
        {
          type: "Polygon",
          id: 21041,
          arcs: [[4525, 4526, 4527, -4433, -4424, -4482]],
        },
        {
          type: "Polygon",
          id: 21089,
          arcs: [[-4498, 4528, 4529, 4530, -4372]],
        },
        {
          type: "Polygon",
          id: 17027,
          arcs: [[-4216, -4512, 4531, 4532, 4533, -4378, -4369]],
        },
        {
          type: "Polygon",
          id: 54075,
          arcs: [[-4404, 4534, 4535, 4536, 4537, -4305]],
        },
        {
          type: "Polygon",
          id: 29051,
          arcs: [[-4335, 4538, 4539, -4426, -4184]],
        },
        {
          type: "Polygon",
          id: 20111,
          arcs: [[-4471, 4540, 4541, 4542, -4463, -4221]],
        },
        {
          type: "Polygon",
          id: 20059,
          arcs: [[4543, 4544, 4545, -4469, -4329]],
        },
        {
          type: "Polygon",
          id: 20121,
          arcs: [[-4501, 4546, 4547, -4544, -4348]],
        },
        {
          type: "Polygon",
          id: 54101,
          arcs: [[-4311, -4306, -4538, 4548, 4549, -4451, -4250]],
        },
        {
          type: "Polygon",
          id: 6067,
          arcs: [[4550, 4551, 4552, 4553, -4429, -4150, -4133, -4333]],
        },
        {
          type: "Polygon",
          id: 21223,
          arcs: [[-4528, 4554, 4555, 4556, -4434]],
        },
        {
          type: "Polygon",
          id: 21187,
          arcs: [[-4515, 4557, 4558, 4559, -4526, -4481]],
        },
        {
          type: "Polygon",
          id: 21135,
          arcs: [[4560, 4561, 4562, -4523, -4350, -4373, -4531]],
        },
        {
          type: "Polygon",
          id: 29073,
          arcs: [[-4385, 4563, 4564, 4565, 4566, 4567, -4259]],
        },
        {
          type: "Polygon",
          id: 6051,
          arcs: [[-3802, -4324, 4568, 4569, 4570, 4571, 4572, -4419, -4308]],
        },
        {
          type: "Polygon",
          id: 29071,
          arcs: [[-4392, -4458, 4573, 4574, 4575, -4564, -4384]],
        },
        {
          type: "Polygon",
          id: 29151,
          arcs: [[-4260, -4568, 4576, 4577, -4539, -4334]],
        },
        {
          type: "Polygon",
          id: 6005,
          arcs: [[-4422, 4578, 4579, -4551, -4332]],
        },
        { type: "Polygon", id: 24019, arcs: [[-4268, -4398, 4580, 4581]] },
        {
          type: "Polygon",
          id: 20171,
          arcs: [[-4285, 4582, 4583, 4584, 4585, -4272]],
        },
        { type: "Polygon", id: 20101, arcs: [[4586, 4587, -4583, -4284]] },
        {
          type: "Polygon",
          id: 20071,
          arcs: [[4588, 4589, 4590, 4591, -4355, -4276]],
        },
        {
          type: "Polygon",
          id: 20203,
          arcs: [[-4586, 4592, 4593, -4589, -4275, -4273]],
        },
        {
          type: "Polygon",
          id: 51047,
          arcs: [[4594, 4595, 4596, 4597, -4477, -4376]],
        },
        {
          type: "Polygon",
          id: 20135,
          arcs: [[-4292, -4280, 4598, 4599, 4600, 4601, -4587, -4283]],
        },
        {
          type: "Polygon",
          id: 29141,
          arcs: [[-4428, 4602, 4603, 4604, -4405, -4337]],
        },
        {
          type: "Polygon",
          id: 20009,
          arcs: [[-4468, 4605, 4606, 4607, 4608, -4290]],
        },
        {
          type: "Polygon",
          id: 8043,
          arcs: [[-4301, 4609, 4610, 4611, -4345, -3990, -4299]],
        },
        {
          type: "Polygon",
          id: 20165,
          arcs: [[-4291, -4609, 4612, -4599, -4279]],
        },
        { type: "Polygon", id: 24017, arcs: [[-4295, 4613, 4614, 4615]] },
        {
          type: "Polygon",
          id: 18117,
          arcs: [[-4520, 4616, 4617, -4446, -4389]],
        },
        {
          type: "Polygon",
          id: 54079,
          arcs: [[4618, 4619, -4367, -4321, 4620]],
        },
        {
          type: "Polygon",
          id: 32017,
          arcs: [[-3998, 4621, 4622, 4623, 4624, 4625, -4253, -3564]],
        },
        {
          type: "Polygon",
          id: 8085,
          arcs: [[-4178, 4626, 4627, 4628, -4097, -4206]],
        },
        {
          type: "Polygon",
          id: 54015,
          arcs: [[4629, 4630, -4412, -4358, -4453]],
        },
        {
          type: "Polygon",
          id: 17163,
          arcs: [[-4534, 4631, 4632, 4633, -4455, -4521, -4379]],
        },
        {
          type: "Polygon",
          id: 51113,
          arcs: [[-4598, 4634, 4635, -4502, -4478]],
        },
        {
          type: "Polygon",
          id: 54039,
          arcs: [[-4413, -4631, 4636, 4637, 4638, 4639, 4640, -4621, -4320]],
        },
        {
          type: "Polygon",
          id: 8061,
          arcs: [[-4592, 4641, 4642, 4643, 4644, -3986, -4356]],
        },
        {
          type: "Polygon",
          id: 20113,
          arcs: [[4645, 4646, 4647, 4648, -4466, -4399]],
        },
        {
          type: "Polygon",
          id: 20115,
          arcs: [[-4465, 4649, 4650, 4651, -4646, -4287]],
        },
        {
          type: "Polygon",
          id: 21201,
          arcs: [[-4525, 4652, 4653, 4654, -4507]],
        },
        {
          type: "Polygon",
          id: 17191,
          arcs: [[-4494, 4655, 4656, 4657, 4658, -4509, -4438]],
        },
        {
          type: "Polygon",
          id: 18019,
          arcs: [[-4557, 4659, 4660, 4661, -4516, -4504, -4435]],
        },
        {
          type: "Polygon",
          id: 54011,
          arcs: [[-4368, -4620, 4662, 4663, -4495, -4364]],
        },
        {
          type: "Polygon",
          id: 21103,
          arcs: [[4664, 4665, 4666, -4555, -4527, -4560]],
        },
        { type: "Polygon", id: 51091, arcs: [[4667, 4668, -4535, -4403]] },
        {
          type: "Polygon",
          id: 51179,
          arcs: [[4669, 4670, 4671, 4672, 4673, 4674, -4595, -4375, -4411]],
        },
        {
          type: "Polygon",
          id: 21097,
          arcs: [[-4655, 4675, 4676, 4677, -4513, -4460, -4459, -4508]],
        },
        {
          type: "Polygon",
          id: 49001,
          arcs: [[-4354, 4678, 4679, 4680, -4622, -3997]],
        },
        { type: "Polygon", id: 17047, arcs: [[4681, 4682, -4656, -4493]] },
        {
          type: "Polygon",
          id: 17185,
          arcs: [[-4492, -4486, -4444, 4683, 4684, -4682]],
        },
        {
          type: "Polygon",
          id: 29083,
          arcs: [[-4407, 4685, 4686, 4687, -4499, -4414]],
        },
        {
          type: "Polygon",
          id: 24045,
          arcs: [[4688, 4689, 4690, -4581, -4397]],
        },
        {
          type: "Polygon",
          id: 54067,
          arcs: [[-4550, 4691, 4692, -4637, -4630, -4452]],
        },
        {
          type: "Polygon",
          id: 18125,
          arcs: [[4693, 4694, 4695, -4441, -4450]],
        },
        {
          type: "MultiPolygon",
          id: 6095,
          arcs: [[[4696, -4474]], [[-4554, 4697, -4472, -4431, -4430]]],
        },
        {
          type: "Polygon",
          id: 29015,
          arcs: [[-4605, 4698, 4699, 4700, -4686, -4406]],
        },
        {
          type: "Polygon",
          id: 18051,
          arcs: [[4701, 4702, 4703, 4704, -4684, -4443, -4442, -4696]],
        },
        {
          type: "Polygon",
          id: 18037,
          arcs: [[-4618, 4705, 4706, 4707, 4708, -4694, -4449, -4447]],
        },
        {
          type: "Polygon",
          id: 21069,
          arcs: [[4709, 4710, 4711, -4653, -4524, -4563]],
        },
        {
          type: "Polygon",
          id: 21185,
          arcs: [[-4667, 4712, 4713, -4660, -4556]],
        },
        {
          type: "Polygon",
          id: 20017,
          arcs: [[-4543, 4714, 4715, -4650, -4464]],
        },
        {
          type: "Polygon",
          id: 8101,
          arcs: [[4716, 4717, 4718, 4719, 4720, -4610, -4300]],
        },
        {
          type: "Polygon",
          id: 8025,
          arcs: [[-4645, 4721, 4722, -4717, -3987]],
        },
        {
          type: "Polygon",
          id: 20159,
          arcs: [[-4649, 4723, 4724, -4606, -4467]],
        },
        {
          type: "Polygon",
          id: 17133,
          arcs: [[4725, 4726, 4727, -4456, -4634]],
        },
        {
          type: "Polygon",
          id: 17189,
          arcs: [[-4532, -4511, 4728, 4729, 4730, -4632, -4533]],
        },
        { type: "Polygon", id: 24037, arcs: [[-4615, 4731]] },
        { type: "Polygon", id: 6009, arcs: [[-4421, 4732, 4733, 4734, -4579]] },
        { type: "Polygon", id: 49031, arcs: [[4735, 4736, -4679, -4353]] },
        {
          type: "Polygon",
          id: 49055,
          arcs: [[-3855, 4737, 4738, -4736, -4352]],
        },
        {
          type: "Polygon",
          id: 29099,
          arcs: [[-4728, 4739, 4740, 4741, -4574, -4457]],
        },
        {
          type: "Polygon",
          id: 21019,
          arcs: [[4742, 4743, -4529, -4497, 4744]],
        },
        {
          type: "Polygon",
          id: 21043,
          arcs: [[-4744, 4745, 4746, 4747, -4561, -4530]],
        },
        {
          type: "Polygon",
          id: 49037,
          arcs: [
            [
              -4629,
              4748,
              4749,
              4750,
              4751,
              4752,
              4753,
              4754,
              4755,
              -4738,
              -4027,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 21209,
          arcs: [[4756, 4757, 4758, 4759, -4558, -4514, -4678]],
        },
        {
          type: "Polygon",
          id: 51079,
          arcs: [[4760, 4761, -4489, -4503, -4636]],
        },
        {
          type: "Polygon",
          id: 51015,
          arcs: [[4762, 4763, 4764, 4765, -4668, -4402, -4491]],
        },
        {
          type: "Polygon",
          id: 17081,
          arcs: [[-4659, 4766, 4767, 4768, -4729, -4510]],
        },
        {
          type: "Polygon",
          id: 29013,
          arcs: [[-4688, 4769, 4770, 4771, -4547, -4500]],
        },
        { type: "Polygon", id: 32009, arcs: [[4772, -4569, -4323, -4256]] },
        {
          type: "Polygon",
          id: 21181,
          arcs: [[-4712, 4773, 4774, -4676, -4654]],
        },
        {
          type: "Polygon",
          id: 8109,
          arcs: [[-4612, 4775, 4776, 4777, 4778, 4779, 4780, -4175, -4346]],
        },
        {
          type: "MultiPolygon",
          id: 24047,
          arcs: [[[4781, 4782, 4783, -4689, -4396, 4784]]],
        },
        {
          type: "Polygon",
          id: 6109,
          arcs: [[-4573, 4785, 4786, 4787, -4733, -4420]],
        },
        {
          type: "Polygon",
          id: 20031,
          arcs: [[-4546, 4788, 4789, 4790, -4541, -4470]],
        },
        {
          type: "Polygon",
          id: 29131,
          arcs: [[-4540, -4578, 4791, 4792, 4793, -4603, -4427]],
        },
        {
          type: "Polygon",
          id: 18025,
          arcs: [[4794, 4795, 4796, -4706, -4617, -4519]],
        },
        {
          type: "Polygon",
          id: 18061,
          arcs: [[4797, 4798, 4799, 4800, -4795, -4518]],
        },
        {
          type: "Polygon",
          id: 54099,
          arcs: [[-4664, 4801, 4802, 4803, 4804, -4745, -4496]],
        },
        { type: "Polygon", id: 18043, arcs: [[-4662, 4805, -4798, -4517]] },
        {
          type: "Polygon",
          id: 51099,
          arcs: [[4806, 4807, 4808, 4809, -4671, 4810]],
        },
        {
          type: "Polygon",
          id: 21205,
          arcs: [[-4748, 4811, 4812, 4813, 4814, -4710, -4562]],
        },
        {
          type: "Polygon",
          id: 51137,
          arcs: [[4815, 4816, 4817, -4761, -4635, -4597]],
        },
        { type: "Polygon", id: 20003, arcs: [[4818, 4819, -4789, -4545]] },
        {
          type: "Polygon",
          id: 20107,
          arcs: [[-4772, 4820, 4821, -4819, -4548]],
        },
        {
          type: "Polygon",
          id: 21111,
          arcs: [[4822, 4823, 4824, 4825, -4799, -4806, -4661, -4714]],
        },
        {
          type: "Polygon",
          id: 51177,
          arcs: [[-4675, 4826, -4673, 4827, 4828, 4829, -4816, -4596]],
        },
        {
          type: "Polygon",
          id: 54043,
          arcs: [[-4619, -4641, 4830, 4831, 4832, -4802, -4663]],
        },
        {
          type: "Polygon",
          id: 21073,
          arcs: [[-4559, -4760, 4833, 4834, 4835, -4665]],
        },
        {
          type: "Polygon",
          id: 21017,
          arcs: [[4836, 4837, 4838, -4757, -4677, -4775]],
        },
        {
          type: "Polygon",
          id: 21211,
          arcs: [[4839, 4840, -4823, -4713, -4666, -4836]],
        },
        {
          type: "Polygon",
          id: 20145,
          arcs: [[-4608, 4841, 4842, 4843, -4600, -4613]],
        },
        { type: "Polygon", id: 8091, arcs: [[-4177, 4844, 4845, 4846, -4627]] },
        { type: "Polygon", id: 6041, arcs: [[4847, -4484]] },
        {
          type: "Polygon",
          id: 21011,
          arcs: [[-4815, 4848, 4849, -4774, -4711]],
        },
        {
          type: "Polygon",
          id: 6077,
          arcs: [[-4580, -4735, 4850, 4851, 4852, 4853, -4552]],
        },
        {
          type: "Polygon",
          id: 29125,
          arcs: [[-4567, 4854, 4855, -4792, -4577]],
        },
        {
          type: "Polygon",
          id: 24039,
          arcs: [[-4690, -4784, 4856, 4857, 4858]],
        },
        {
          type: "Polygon",
          id: 51003,
          arcs: [[-4818, 4859, 4860, 4861, 4862, -4763, -4490, -4762]],
        },
        { type: "Polygon", id: 51193, arcs: [[4863, 4864, 4865, -4807, 4866]] },
        {
          type: "Polygon",
          id: 29029,
          arcs: [[-4794, 4867, 4868, 4869, 4870, -4699, -4604]],
        },
        {
          type: "Polygon",
          id: 21127,
          arcs: [[-4805, 4871, 4872, 4873, 4874, -4746, -4743]],
        },
        {
          type: "Polygon",
          id: 51017,
          arcs: [[-4669, -4766, 4875, 4876, 4877, -4536]],
        },
        {
          type: "Polygon",
          id: 8099,
          arcs: [[-4591, 4878, 4879, 4880, 4881, -4642]],
        },
        {
          type: "Polygon",
          id: 54025,
          arcs: [[-4878, 4882, 4883, 4884, 4885, -4692, -4549, -4537]],
        },
        { type: "Polygon", id: 21063, arcs: [[-4875, 4886, -4812, -4747]] },
        { type: "Polygon", id: 8027, arcs: [[-4721, 4887, -4776, -4611]] },
        { type: "Polygon", id: 8011, arcs: [[-4882, 4888, 4889, 4890, -4643]] },
        {
          type: "Polygon",
          id: 8089,
          arcs: [[-4891, 4891, -4718, -4723, -4722, -4644]],
        },
        {
          type: "Polygon",
          id: 18123,
          arcs: [[4892, 4893, 4894, 4895, -4707, -4797]],
        },
        {
          type: "Polygon",
          id: 54019,
          arcs: [[-4693, -4886, 4896, 4897, -4638]],
        },
        {
          type: "Polygon",
          id: 20093,
          arcs: [[4898, 4899, 4900, -4593, -4585]],
        },
        {
          type: "Polygon",
          id: 20055,
          arcs: [[-4588, -4602, 4901, 4902, 4903, -4899, -4584]],
        },
        {
          type: "Polygon",
          id: 20075,
          arcs: [[-4901, 4904, -4879, -4590, -4594]],
        },
        {
          type: "Polygon",
          id: 17193,
          arcs: [[-4685, -4705, 4905, 4906, 4907, -4657, -4683]],
        },
        {
          type: "Polygon",
          id: 20185,
          arcs: [[-4725, 4908, 4909, 4910, -4842, -4607]],
        },
        {
          type: "Polygon",
          id: 20083,
          arcs: [[-4844, 4911, 4912, 4913, -4902, -4601]],
        },
        {
          type: "Polygon",
          id: 17065,
          arcs: [[-4908, 4914, 4915, 4916, -4767, -4658]],
        },
        {
          type: "Polygon",
          id: 51033,
          arcs: [[-4810, 4917, 4918, 4919, 4920, -4828, -4672]],
        },
        {
          type: "Polygon",
          id: 18173,
          arcs: [[-4709, 4921, 4922, 4923, 4924, 4925, -4702, -4695]],
        },
        {
          type: "Polygon",
          id: 18129,
          arcs: [[4926, 4927, 4928, 4929, -4906, -4704]],
        },
        {
          type: "Polygon",
          id: 54005,
          arcs: [[4930, 4931, 4932, -4831, -4640]],
        },
        {
          type: "Polygon",
          id: 17157,
          arcs: [[-4633, -4731, 4933, 4934, 4935, 4936, -4726]],
        },
        {
          type: "Polygon",
          id: 29185,
          arcs: [[-4701, 4937, 4938, 4939, 4940, -4770, -4687]],
        },
        {
          type: "Polygon",
          id: 17145,
          arcs: [[-4730, -4769, 4941, 4942, -4934]],
        },
        {
          type: "Polygon",
          id: 29055,
          arcs: [[-4576, 4943, 4944, 4945, 4946, -4565]],
        },
        {
          type: "Polygon",
          id: 21067,
          arcs: [[4947, 4948, 4949, 4950, -4758, -4839]],
        },
        {
          type: "Polygon",
          id: 29221,
          arcs: [[-4742, 4951, 4952, -4944, -4575]],
        },
        {
          type: "Polygon",
          id: 18147,
          arcs: [[-4896, 4953, 4954, -4923, -4922, -4708]],
        },
        {
          type: "Polygon",
          id: 21163,
          arcs: [[4955, 4956, -4893, -4796, -4801]],
        },
        {
          type: "Polygon",
          id: 21239,
          arcs: [[-4951, 4957, 4958, 4959, -4834, -4759]],
        },
        {
          type: "Polygon",
          id: 21173,
          arcs: [[4960, 4961, 4962, -4837, -4850]],
        },
        {
          type: "Polygon",
          id: 20079,
          arcs: [[4963, 4964, 4965, -4647, -4652]],
        },
        {
          type: "Polygon",
          id: 20073,
          arcs: [[-4542, -4791, 4966, 4967, 4968, 4969, -4715]],
        },
        {
          type: "Polygon",
          id: 20155,
          arcs: [[-4648, -4966, 4970, 4971, 4972, -4909, -4724]],
        },
        {
          type: "Polygon",
          id: 18163,
          arcs: [[-4926, 4973, 4974, -4927, -4703]],
        },
        {
          type: "Polygon",
          id: 29161,
          arcs: [[-4947, 4975, 4976, 4977, -4855, -4566]],
        },
        {
          type: "Polygon",
          id: 49017,
          arcs: [[-4756, 4978, 4979, -4680, -4737, -4739]],
        },
        {
          type: "Polygon",
          id: 51057,
          arcs: [[4980, 4981, -4918, -4809, 4982]],
        },
        {
          type: "Polygon",
          id: 8113,
          arcs: [[-4847, 4983, 4984, -4749, -4628]],
        },
        {
          type: "Polygon",
          id: 51109,
          arcs: [[-4830, 4985, 4986, 4987, -4860, -4817]],
        },
        {
          type: "Polygon",
          id: 49021,
          arcs: [[-4980, 4988, 4989, -4623, -4681]],
        },
        {
          type: "Polygon",
          id: 21215,
          arcs: [[4990, 4991, 4992, -4824, -4841]],
        },
        {
          type: "Polygon",
          id: 8053,
          arcs: [[-4781, 4993, 4994, 4995, 4996, -4845, -4176]],
        },
        {
          type: "Polygon",
          id: 21005,
          arcs: [[-4960, 4997, 4998, 4999, -4991, -4840, -4835]],
        },
        {
          type: "Polygon",
          id: 17055,
          arcs: [[-4917, 5000, 5001, 5002, -4942, -4768]],
        },
        { type: "Polygon", id: 51159, arcs: [[5003, 5004, 5005, -4865]] },
        {
          type: "Polygon",
          id: 29186,
          arcs: [[-4937, 5006, 5007, -4740, -4727]],
        },
        { type: "Polygon", id: 21029, arcs: [[5008, 5009, -4825, -4993]] },
        {
          type: "Polygon",
          id: 21175,
          arcs: [[-4874, 5010, 5011, 5012, 5013, -4813, -4887]],
        },
        {
          type: "Polygon",
          id: 21049,
          arcs: [[5014, 5015, 5016, -4948, -4838, -4963]],
        },
        { type: "Polygon", id: 6013, arcs: [[-4853, 5017, 5018]] },
        {
          type: "Polygon",
          id: 20015,
          arcs: [[-4716, -4970, 5019, 5020, 5021, -4964, -4651]],
        },
        {
          type: "Polygon",
          id: 20047,
          arcs: [[-4911, 5022, 5023, 5024, -4912, -4843]],
        },
        {
          type: "Polygon",
          id: 51163,
          arcs: [[5025, 5026, 5027, 5028, -4876, -4765, 5029]],
        },
        {
          type: "Polygon",
          id: 29187,
          arcs: [[-5008, 5030, 5031, 5032, -4952, -4741]],
        },
        {
          type: "Polygon",
          id: 6099,
          arcs: [[5033, 5034, 5035, -4851, -4734, -4788]],
        },
        {
          type: "Polygon",
          id: 29085,
          arcs: [[-4871, 5036, 5037, -4938, -4700]],
        },
        {
          type: "Polygon",
          id: 29217,
          arcs: [[-4941, 5038, 5039, 5040, 5041, -4821, -4771]],
        },
        {
          type: "Polygon",
          id: 21165,
          arcs: [[-4814, -5014, 5042, 5043, -4961, -4849]],
        },
        {
          type: "Polygon",
          id: 51125,
          arcs: [[5044, 5045, 5046, -5030, -4764, -4863]],
        },
        {
          type: "Polygon",
          id: 21027,
          arcs: [[5047, 5048, 5049, 5050, -4894, -4957]],
        },
        { type: "Polygon", id: 20207, arcs: [[5051, 5052, -4967, -4790]] },
        { type: "Polygon", id: 20001, arcs: [[5053, 5054, -5052, -4820]] },
        {
          type: "Polygon",
          id: 20011,
          arcs: [[-5042, 5055, 5056, -5054, -4822]],
        },
        {
          type: "MultiPolygon",
          id: 51001,
          arcs: [[[5057, 5058, -4782, 5059]]],
        },
        { type: "Polygon", id: 54045, arcs: [[-4933, 5060, 5061, -4832]] },
        { type: "Polygon", id: 51133, arcs: [[5062, -5004, -4864, 5063]] },
        {
          type: "Polygon",
          id: 29169,
          arcs: [[-4856, -4978, 5064, 5065, -4868, -4793]],
        },
        {
          type: "Polygon",
          id: 8055,
          arcs: [[-4720, 5066, 5067, 5068, -4777, -4888]],
        },
        {
          type: "Polygon",
          id: 51085,
          arcs: [[-4921, 5069, 5070, 5071, 5072, -4986, -4829]],
        },
        {
          type: "Polygon",
          id: 51065,
          arcs: [[5073, 5074, -4861, -4988, 5075]],
        },
        {
          type: "Polygon",
          id: 21093,
          arcs: [
            [5076, 5077, 5078, 5079, 5080, -5048, -4956, -4800, -4826, -5010],
          ],
        },
        {
          type: "Polygon",
          id: 21113,
          arcs: [[5081, 5082, 5083, -4958, -4950]],
        },
        {
          type: "Polygon",
          id: 21115,
          arcs: [[5084, 5085, 5086, -5011, -4873]],
        },
        {
          type: "Polygon",
          id: 20069,
          arcs: [[-4914, 5087, 5088, 5089, -4903]],
        },
        {
          type: "Polygon",
          id: 21091,
          arcs: [[-5051, 5090, 5091, -4954, -4895]],
        },
        {
          type: "Polygon",
          id: 54081,
          arcs: [[-4639, -4898, 5092, 5093, 5094, -4931]],
        },
        {
          type: "Polygon",
          id: 21179,
          arcs: [[-5000, 5095, 5096, 5097, -5077, -5009, -4992]],
        },
        {
          type: "Polygon",
          id: 54059,
          arcs: [[5098, 5099, 5100, 5101, 5102, -4803, -4833, -5062]],
        },
        {
          type: "Polygon",
          id: 21101,
          arcs: [[-4974, -4925, 5103, 5104, 5105, 5106, -4928, -4975]],
        },
        {
          type: "Polygon",
          id: 51097,
          arcs: [[-4982, 5107, 5108, 5109, 5110, -4919]],
        },
        {
          type: "Polygon",
          id: 8111,
          arcs: [[-4997, 5111, 5112, -4984, -4846]],
        },
        {
          type: "Polygon",
          id: 21167,
          arcs: [[-5084, 5113, 5114, 5115, -4998, -4959]],
        },
        { type: "Polygon", id: 8079, arcs: [[5116, 5117, -4994, -4780]] },
        {
          type: "Polygon",
          id: 21159,
          arcs: [[-4804, -5103, 5118, 5119, -5085, -4872]],
        },
        {
          type: "Polygon",
          id: 51005,
          arcs: [[-5029, 5120, 5121, 5122, -4883, -4877]],
        },
        {
          type: "Polygon",
          id: 17077,
          arcs: [[-5003, 5123, 5124, 5125, -4935, -4943]],
        },
        {
          type: "Polygon",
          id: 21197,
          arcs: [[-5044, 5126, 5127, 5128, -5015, -4962]],
        },
        {
          type: "Polygon",
          id: 21059,
          arcs: [[5129, 5130, -5104, -4924, -4955, -5092]],
        },
        {
          type: "Polygon",
          id: 21151,
          arcs: [[5131, 5132, 5133, 5134, -5082, -4949, -5017]],
        },
        {
          type: "Polygon",
          id: 17059,
          arcs: [[-4930, 5135, 5136, 5137, -4915, -4907]],
        },
        {
          type: "Polygon",
          id: 21229,
          arcs: [[-5116, 5138, 5139, -5096, -4999]],
        },
        {
          type: "Polygon",
          id: 20057,
          arcs: [[-5025, 5140, 5141, 5142, -5088, -4913]],
        },
        {
          type: "Polygon",
          id: 20173,
          arcs: [[-5022, 5143, 5144, -4971, -4965]],
        },
        {
          type: "Polygon",
          id: 17165,
          arcs: [[-5138, 5145, 5146, 5147, -5001, -4916]],
        },
        {
          type: "Polygon",
          id: 51101,
          arcs: [[-5111, 5148, 5149, -5070, -4920]],
        },
        {
          type: "Polygon",
          id: 29059,
          arcs: [[-4870, 5150, 5151, 5152, 5153, -5037]],
        },
        {
          type: "Polygon",
          id: 6001,
          arcs: [[-4852, -5036, 5154, 5155, -5018]],
        },
        {
          type: "Polygon",
          id: 51075,
          arcs: [[5156, 5157, 5158, -5076, -4987, -5073]],
        },
        {
          type: "Polygon",
          id: 29157,
          arcs: [[-5126, 5159, 5160, 5161, 5162, -5031, -5007, -4936]],
        },
        {
          type: "Polygon",
          id: 29039,
          arcs: [[5163, 5164, 5165, -5039, -4940]],
        },
        { type: "Polygon", id: 6043, arcs: [[5166, 5167, -4787]] },
        {
          type: "Polygon",
          id: 21225,
          arcs: [[5168, 5169, 5170, -5136, -4929, -5107]],
        },
        {
          type: "Polygon",
          id: 29105,
          arcs: [[-5066, 5171, 5172, 5173, -5151, -4869]],
        },
        { type: "Polygon", id: 8033, arcs: [[-5113, 5174, -4750, -4985]] },
        {
          type: "Polygon",
          id: 21153,
          arcs: [[-5087, 5175, 5176, 5177, 5178, -5012]],
        },
        {
          type: "Polygon",
          id: 54089,
          arcs: [[5179, 5180, -5093, -4897, -4885, 5181]],
        },
        {
          type: "Polygon",
          id: 17199,
          arcs: [[-5148, 5182, 5183, -5124, -5002]],
        },
        {
          type: "Polygon",
          id: 21237,
          arcs: [[-5179, 5184, 5185, -5127, -5043, -5013]],
        },
        {
          type: "Polygon",
          id: 21065,
          arcs: [[-5129, 5186, 5187, -5132, -5016]],
        },
        { type: "Polygon", id: 51103, arcs: [[-5005, -5063, 5188]] },
        { type: "Polygon", id: 8105, arcs: [[-4779, 5189, 5190, 5191, -5117]] },
        { type: "Polygon", id: 6075, arcs: [[5192, 5193]] },
        {
          type: "Polygon",
          id: 29167,
          arcs: [[-5038, -5154, 5194, 5195, -5164, -4939]],
        },
        {
          type: "Polygon",
          id: 21079,
          arcs: [[-5135, 5196, 5197, 5198, -5114, -5083]],
        },
        {
          type: "Polygon",
          id: 20151,
          arcs: [[-4973, 5199, 5200, 5201, -5023, -4910]],
        },
        {
          type: "Polygon",
          id: 51009,
          arcs: [[5202, 5203, 5204, 5205, -5026, -5047]],
        },
        {
          type: "Polygon",
          id: 8071,
          arcs: [[-4890, 5206, 5207, 5208, 5209, -5067, -4719, -4892]],
        },
        {
          type: "Polygon",
          id: 51023,
          arcs: [[5210, 5211, 5212, -5121, -5028]],
        },
        {
          type: "Polygon",
          id: 29065,
          arcs: [[-4946, 5213, 5214, 5215, 5216, -4976]],
        },
        {
          type: "Polygon",
          id: 54109,
          arcs: [[5217, 5218, -5099, -5061, -4932, -5095]],
        },
        {
          type: "Polygon",
          id: 51029,
          arcs: [[5219, 5220, 5221, -5045, -4862, -5075]],
        },
        {
          type: "Polygon",
          id: 6039,
          arcs: [[-4572, 5222, 5223, -5167, -4786]],
        },
        { type: "Polygon", id: 51119, arcs: [[5224, -5108, -4981, 5225]] },
        {
          type: "Polygon",
          id: 21071,
          arcs: [[-5120, 5226, 5227, -5176, -5086]],
        },
        {
          type: "Polygon",
          id: 8003,
          arcs: [[-5069, 5228, 5229, -5190, -4778]],
        },
        {
          type: "Polygon",
          id: 51049,
          arcs: [[-5159, 5230, 5231, 5232, -5220, -5074]],
        },
        {
          type: "Polygon",
          id: 21195,
          arcs: [[5233, 5234, 5235, 5236, 5237, -5227, -5119, -5102]],
        },
        {
          type: "Polygon",
          id: 29093,
          arcs: [[-5033, 5238, 5239, 5240, -5214, -4945, -4953]],
        },
        {
          type: "Polygon",
          id: 20187,
          arcs: [[5241, 5242, 5243, 5244, -4880, -4905]],
        },
        {
          type: "Polygon",
          id: 21183,
          arcs: [[-5050, 5245, 5246, 5247, 5248, -5130, -5091]],
        },
        { type: "Polygon", id: 20067, arcs: [[5249, 5250, -5242, -4900]] },
        {
          type: "Polygon",
          id: 20081,
          arcs: [[-5090, 5251, 5252, 5253, -5250, -4904]],
        },
        {
          type: "Polygon",
          id: 20097,
          arcs: [[-5202, 5254, 5255, 5256, -5141, -5024]],
        },
        {
          type: "Polygon",
          id: 20095,
          arcs: [[-5145, 5257, 5258, 5259, -5200, -4972]],
        },
        {
          type: "Polygon",
          id: 20205,
          arcs: [[5260, 5261, 5262, -4968, -5053]],
        },
        {
          type: "Polygon",
          id: 20133,
          arcs: [[-5057, 5263, 5264, 5265, -5261, -5055]],
        },
        {
          type: "Polygon",
          id: 21123,
          arcs: [[5266, 5267, 5268, 5269, -5078, -5098]],
        },
        {
          type: "Polygon",
          id: 21155,
          arcs: [[-5140, 5270, 5271, 5272, -5267, -5097]],
        },
        {
          type: "Polygon",
          id: 54063,
          arcs: [[-5123, 5273, 5274, -5182, -4884]],
        },
        {
          type: "Polygon",
          id: 21129,
          arcs: [[-5186, 5275, 5276, 5277, -5187, -5128]],
        },
        {
          type: "Polygon",
          id: 21021,
          arcs: [[-5199, 5278, 5279, -5271, -5139, -5115]],
        },
        {
          type: "Polygon",
          id: 51087,
          arcs: [
            [5280, 5281, 5282, 5283, 5284, 5285, 5286, 5287, -5157, -5072],
          ],
        },
        { type: "Polygon", id: 6081, arcs: [[5288, 5289, 5290, 5291, -5193]] },
        {
          type: "Polygon",
          id: 21025,
          arcs: [[-5178, 5292, 5293, 5294, -5276, -5185]],
        },
        { type: "Polygon", id: 51145, arcs: [[5295, 5296, -5231, -5158]] },
        {
          type: "Polygon",
          id: 21149,
          arcs: [[-5249, 5297, 5298, 5299, -5105, -5131]],
        },
        {
          type: "Polygon",
          id: 20037,
          arcs: [[-5041, 5300, 5301, 5302, 5303, -5264, -5056]],
        },
        {
          type: "Polygon",
          id: 51045,
          arcs: [[5304, 5305, 5306, -5274, -5122, -5213]],
        },
        {
          type: "Polygon",
          id: 8023,
          arcs: [[-5210, 5307, 5308, 5309, -5229, -5068]],
        },
        {
          type: "Polygon",
          id: 29011,
          arcs: [[-5166, 5310, 5311, -5301, -5040]],
        },
        {
          type: "Polygon",
          id: 21233,
          arcs: [[5312, 5313, 5314, -5169, -5106, -5300]],
        },
        {
          type: "Polygon",
          id: 29123,
          arcs: [[-5163, 5315, 5316, -5239, -5032]],
        },
        {
          type: "Polygon",
          id: 8009,
          arcs: [[-5245, 5317, 5318, 5319, -5207, -4889, -4881]],
        },
        { type: "Polygon", id: 8083, arcs: [[5320, 5321, -4751, -5175]] },
        {
          type: "Polygon",
          id: 8067,
          arcs: [[-4996, 5322, 5323, -5321, -5112]],
        },
        {
          type: "Polygon",
          id: 6047,
          arcs: [[-5224, 5324, 5325, 5326, -5034, -5168]],
        },
        {
          type: "Polygon",
          id: 21137,
          arcs: [[5327, 5328, 5329, -5279, -5198]],
        },
        {
          type: "Polygon",
          id: 51127,
          arcs: [[5330, 5331, 5332, -5281, -5071, -5150]],
        },
        {
          type: "Polygon",
          id: 21085,
          arcs: [[-5081, -5080, 5333, 5334, 5335, -5246, -5049]],
        },
        {
          type: "Polygon",
          id: 51019,
          arcs: [[5336, 5337, 5338, 5339, 5340, -5211, -5027, -5206]],
        },
        { type: "Polygon", id: 49053, arcs: [[5341, 5342, -4624, -4990]] },
        {
          type: "Polygon",
          id: 20049,
          arcs: [[-5263, 5343, 5344, 5345, -5020, -4969]],
        },
        {
          type: "Polygon",
          id: 29179,
          arcs: [[5346, 5347, 5348, -5215, -5241]],
        },
        {
          type: "Polygon",
          id: 29031,
          arcs: [[5349, 5350, 5351, 5352, 5353, -5161]],
        },
        {
          type: "Polygon",
          id: 29215,
          arcs: [[-4977, -5217, 5354, 5355, 5356, 5357, -5172, -5065]],
        },
        {
          type: "Polygon",
          id: 17069,
          arcs: [[-5137, -5171, 5358, 5359, 5360, -5146]],
        },
        {
          type: "Polygon",
          id: 17151,
          arcs: [[-5361, 5361, 5362, 5363, -5147]],
        },
        { type: "Polygon", id: 51760, arcs: [[-5285, 5364, -5287, -5286]] },
        {
          type: "Polygon",
          id: 17087,
          arcs: [[-5364, 5365, 5366, 5367, -5183]],
        },
        {
          type: "Polygon",
          id: 17181,
          arcs: [[-5184, -5368, 5368, 5369, -5350, -5160, -5125]],
        },
        {
          type: "Polygon",
          id: 51073,
          arcs: [[5370, 5371, 5372, -5109, -5225]],
        },
        {
          type: "Polygon",
          id: 29017,
          arcs: [[-5354, 5373, 5374, -5316, -5162]],
        },
        {
          type: "Polygon",
          id: 54055,
          arcs: [[-5181, 5375, 5376, 5377, 5378, -5218, -5094]],
        },
        {
          type: "Polygon",
          id: 6019,
          arcs: [[5379, 5380, 5381, 5382, 5383, -5325, -5223, -4571]],
        },
        {
          type: "Polygon",
          id: 29057,
          arcs: [[-5196, 5384, 5385, 5386, -5311, -5165]],
        },
        {
          type: "Polygon",
          id: 21109,
          arcs: [[-5278, 5387, 5388, 5389, 5390, -5133, -5188]],
        },
        {
          type: "Polygon",
          id: 21107,
          arcs: [[5391, 5392, 5393, -5313, -5299]],
        },
        {
          type: "Polygon",
          id: 51041,
          arcs: [
            [
              -5365,
              -5284,
              5394,
              5395,
              5396,
              5397,
              5398,
              5399,
              5400,
              5401,
              -5296,
              -5288,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 51011,
          arcs: [[5402, 5403, 5404, -5203, -5046, -5222]],
        },
        { type: "MultiPolygon", id: 51131, arcs: [[[5405, -5058]]] },
        {
          type: "Polygon",
          id: 21045,
          arcs: [[-5330, 5406, 5407, 5408, 5409, -5272, -5280]],
        },
        {
          type: "Polygon",
          id: 54047,
          arcs: [[-5379, 5410, 5411, -5100, -5219]],
        },
        {
          type: "Polygon",
          id: 21055,
          arcs: [[-5315, 5412, 5413, 5414, -5359, -5170]],
        },
        {
          type: "Polygon",
          id: 49025,
          arcs: [[-4979, -4755, 5415, 5416, -5342, -4989]],
        },
        {
          type: "Polygon",
          id: 21189,
          arcs: [[-5295, 5417, 5418, -5388, -5277]],
        },
        {
          type: "Polygon",
          id: 21203,
          arcs: [[-5391, 5419, 5420, -5328, -5197, -5134]],
        },
        {
          type: "Polygon",
          id: 51027,
          arcs: [[-5412, 5421, 5422, 5423, -5234, -5101]],
        },
        { type: "Polygon", id: 51115, arcs: [[-5372, 5424]] },
        {
          type: "Polygon",
          id: 21119,
          arcs: [[-5177, -5228, -5238, 5425, 5426, -5293]],
        },
        {
          type: "Polygon",
          id: 51007,
          arcs: [[-5402, 5427, 5428, 5429, -5232, -5297]],
        },
        { type: "Polygon", id: 51036, arcs: [[5430, 5431, -5282, -5333]] },
        {
          type: "Polygon",
          id: 29225,
          arcs: [[-5174, 5432, 5433, 5434, 5435, -5152]],
        },
        {
          type: "Polygon",
          id: 6085,
          arcs: [[-5035, -5327, 5436, 5437, -5290, 5438, -5155]],
        },
        {
          type: "Polygon",
          id: 21217,
          arcs: [[-5273, -5410, 5439, 5440, -5268]],
        },
        { type: "Polygon", id: 29229, arcs: [[-5358, 5441, -5433, -5173]] },
        {
          type: "Polygon",
          id: 51071,
          arcs: [[-5307, 5442, 5443, 5444, -5376, -5180, -5275]],
        },
        {
          type: "Polygon",
          id: 20035,
          arcs: [[-5346, 5445, 5446, 5447, 5448, -5021]],
        },
        {
          type: "Polygon",
          id: 20191,
          arcs: [[-5449, 5449, 5450, 5451, -5258, -5144]],
        },
        {
          type: "Polygon",
          id: 20119,
          arcs: [[-5143, 5452, 5453, 5454, -5252, -5089]],
        },
        {
          type: "Polygon",
          id: 20025,
          arcs: [[-5257, 5455, 5456, 5457, -5453, -5142]],
        },
        {
          type: "Polygon",
          id: 20007,
          arcs: [[-5260, 5458, 5459, 5460, 5461, -5255, -5201]],
        },
        {
          type: "Polygon",
          id: 21087,
          arcs: [[-5441, 5462, 5463, 5464, -5269]],
        },
        { type: "Polygon", id: 51680, arcs: [[-5205, 5465, -5337]] },
        {
          type: "Polygon",
          id: 6027,
          arcs: [[-4773, -4255, 5466, 5467, 5468, 5469, -5380, -4570]],
        },
        {
          type: "Polygon",
          id: 51095,
          arcs: [[5470, 5471, 5472, -5431, -5332, 5473, 5474, 5475]],
        },
        {
          type: "Polygon",
          id: 21099,
          arcs: [[-5270, -5465, 5476, 5477, 5478, -5334, -5079]],
        },
        {
          type: "Polygon",
          id: 21193,
          arcs: [[-5427, 5479, 5480, 5481, 5482, -5418, -5294]],
        },
        {
          type: "Polygon",
          id: 51031,
          arcs: [[-5405, 5483, 5484, 5485, -5338, -5466, -5204]],
        },
        {
          type: "Polygon",
          id: 29077,
          arcs: [[-5153, -5436, 5486, 5487, -5385, -5195]],
        },
        {
          type: "Polygon",
          id: 21139,
          arcs: [[-5415, 5488, 5489, 5490, 5491, -5362, -5360]],
        },
        {
          type: "Polygon",
          id: 29203,
          arcs: [[-5349, 5492, 5493, 5494, -5355, -5216]],
        },
        {
          type: "Polygon",
          id: 8007,
          arcs: [[-5118, -5192, 5495, 5496, 5497, -5323, -4995]],
        },
        {
          type: "Polygon",
          id: 51161,
          arcs: [
            [-5341, 5498, 5499, 5500, -5305, -5212],
            [5501, 5502],
          ],
        },
        {
          type: "Polygon",
          id: 21177,
          arcs: [[5503, 5504, 5505, 5506, -5392, -5298, -5248]],
        },
        {
          type: "Polygon",
          id: 51147,
          arcs: [[-5233, -5430, 5507, 5508, 5509, -5403, -5221]],
        },
        {
          type: "Polygon",
          id: 8021,
          arcs: [[-5191, -5230, -5310, 5510, 5511, -5496]],
        },
        {
          type: "Polygon",
          id: 21031,
          arcs: [[5512, 5513, -5504, -5247, -5336, 5514]],
        },
        {
          type: "Polygon",
          id: 20129,
          arcs: [[5515, 5516, 5517, -5318, -5244]],
        },
        {
          type: "Polygon",
          id: 20189,
          arcs: [[-5254, 5518, 5519, -5516, -5243, -5251]],
        },
        {
          type: "Polygon",
          id: 20175,
          arcs: [[-5455, 5520, 5521, -5519, -5253]],
        },
        {
          type: "Polygon",
          id: 20125,
          arcs: [[-5262, -5266, 5522, 5523, 5524, 5525, -5344]],
        },
        {
          type: "Polygon",
          id: 20077,
          arcs: [[5526, 5527, -5459, -5259, -5452]],
        },
        {
          type: "Polygon",
          id: 20033,
          arcs: [[5528, 5529, -5456, -5256, -5462]],
        },
        {
          type: "Polygon",
          id: 20099,
          arcs: [[-5304, 5530, 5531, 5532, -5523, -5265]],
        },
        {
          type: "Polygon",
          id: 21033,
          arcs: [[-5394, 5533, 5534, 5535, -5413, -5314]],
        },
        {
          type: "Polygon",
          id: 51199,
          arcs: [[5536, 5537, 5538, 5539, -5471, 5540, -5475]],
        },
        {
          type: "Polygon",
          id: 29097,
          arcs: [[-5387, 5541, 5542, 5543, -5302, -5312]],
        },
        {
          type: "Polygon",
          id: 51121,
          arcs: [[5544, 5545, 5546, 5547, -5443, -5306, -5501]],
        },
        {
          type: "Polygon",
          id: 21051,
          arcs: [[-5483, 5548, 5549, 5550, 5551, -5389, -5419]],
        },
        {
          type: "Polygon",
          id: 21199,
          arcs: [[5552, 5553, 5554, -5407, -5329, -5421, 5555]],
        },
        {
          type: "Polygon",
          id: 20021,
          arcs: [[-5544, 5556, 5557, 5558, -5531, -5303]],
        },
        {
          type: "Polygon",
          id: 21061,
          arcs: [[-5479, 5559, 5560, -5515, -5335]],
        },
        { type: "Polygon", id: 51770, arcs: [[5561, -5503]] },
        {
          type: "Polygon",
          id: 17127,
          arcs: [[-5492, 5562, 5563, -5366, -5363]],
        },
        {
          type: "Polygon",
          id: 51185,
          arcs: [[-5378, 5564, 5565, 5566, -5422, -5411]],
        },
        {
          type: "Polygon",
          id: 17003,
          arcs: [[5567, 5568, 5569, 5570, -5351, -5370]],
        },
        {
          type: "Polygon",
          id: 21125,
          arcs: [[-5552, 5571, 5572, 5573, -5556, -5420, -5390]],
        },
        {
          type: "Polygon",
          id: 17153,
          arcs: [[-5367, -5564, 5574, 5575, -5568, -5369]],
        },
        { type: "Polygon", id: 21131, arcs: [[5576, 5577, -5549, -5482]] },
        {
          type: "Polygon",
          id: 29223,
          arcs: [[-5375, 5578, 5579, 5580, -5347, -5240, -5317]],
        },
        {
          type: "Polygon",
          id: 51149,
          arcs: [[5581, 5582, 5583, 5584, 5585, -5397, 5586]],
        },
        {
          type: "Polygon",
          id: 21001,
          arcs: [[-5409, 5587, 5588, 5589, -5463, -5440]],
        },
        { type: "Polygon", id: 51051, arcs: [[5590, 5591, -5235, -5424]] },
        {
          type: "Polygon",
          id: 20019,
          arcs: [[-5526, 5592, 5593, -5446, -5345]],
        },
        {
          type: "Polygon",
          id: 51021,
          arcs: [[5594, 5595, 5596, -5565, -5377, -5445]],
        },
        {
          type: "Polygon",
          id: 51135,
          arcs: [[5597, 5598, -5508, -5429, 5599]],
        },
        {
          type: "Polygon",
          id: 29109,
          arcs: [[-5488, 5600, 5601, 5602, 5603, -5542, -5386]],
        },
        { type: "Polygon", id: 6087, arcs: [[5604, 5605, 5606, -5291, -5438]] },
        {
          type: "Polygon",
          id: 51053,
          arcs: [[5607, -5585, 5608, 5609, 5610, -5600, -5428, -5401]],
        },
        {
          type: "Polygon",
          id: 21133,
          arcs: [[-5237, 5611, 5612, -5480, -5426]],
        },
        {
          type: "Polygon",
          id: 29201,
          arcs: [[5613, 5614, 5615, -5352, -5571]],
        },
        {
          type: "Polygon",
          id: 51037,
          arcs: [[5616, 5617, 5618, -5484, -5404, -5510]],
        },
        {
          type: "Polygon",
          id: 51155,
          arcs: [[5619, -5546, 5620, 5621, 5622, -5595, -5444, -5548]],
        },
        { type: "Polygon", id: 51181, arcs: [[5623, 5624, 5625, -5583, 5626]] },
        {
          type: "Polygon",
          id: 51067,
          arcs: [[5627, 5628, 5629, 5630, -5499, -5340]],
        },
        {
          type: "Polygon",
          id: 21007,
          arcs: [[5631, 5632, -5569, -5576, 5633]],
        },
        {
          type: "Polygon",
          id: 21145,
          arcs: [[-5491, 5634, 5635, 5636, -5634, -5575, -5563]],
        },
        { type: "Polygon", id: 51700, arcs: [[5637, 5638, -5472, -5540]] },
        {
          type: "Polygon",
          id: 51195,
          arcs: [[5639, 5640, 5641, 5642, -5612, -5236, -5592]],
        },
        {
          type: "Polygon",
          id: 21227,
          arcs: [[-5561, 5643, 5644, 5645, 5646, -5513]],
        },
        {
          type: "Polygon",
          id: 21207,
          arcs: [[-5555, 5647, 5648, 5649, -5588, -5408]],
        },
        {
          type: "Polygon",
          id: 21169,
          arcs: [[-5590, 5650, 5651, 5652, -5477, -5464]],
        },
        {
          type: "Polygon",
          id: 21143,
          arcs: [[-5536, 5653, 5654, -5489, -5414]],
        },
        {
          type: "Polygon",
          id: 21009,
          arcs: [[5655, 5656, -5644, -5560, -5478, -5653]],
        },
        {
          type: "Polygon",
          id: 21047,
          arcs: [[-5507, 5657, 5658, 5659, 5660, -5534, -5393]],
        },
        {
          type: "Polygon",
          id: 51093,
          arcs: [[5661, 5662, 5663, 5664, -5624, 5665]],
        },
        {
          type: "Polygon",
          id: 51167,
          arcs: [[5666, 5667, 5668, -5640, -5591, -5423, -5567]],
        },
        {
          type: "Polygon",
          id: 51143,
          arcs: [[5669, 5670, 5671, 5672, 5673, 5674, -5628, -5339, -5486]],
        },
        {
          type: "Polygon",
          id: 51063,
          arcs: [[-5631, 5675, 5676, -5621, -5545, -5500]],
        },
        {
          type: "Polygon",
          id: 29207,
          arcs: [[-5353, -5616, 5677, 5678, 5679, -5579, -5374]],
        },
        {
          type: "Polygon",
          id: 51111,
          arcs: [[-5599, 5680, 5681, -5617, -5509]],
        },
        { type: "Polygon", id: 51650, arcs: [[-5638, -5539, -5683, 5683]] },
        {
          type: "Polygon",
          id: 51183,
          arcs: [[5684, 5685, -5609, -5584, -5626]],
        },
        {
          type: "Polygon",
          id: 29035,
          arcs: [[-5581, 5686, 5687, 5688, -5493, -5348]],
        },
        {
          type: "Polygon",
          id: 29043,
          arcs: [[-5435, 5689, 5690, 5691, -5601, -5487]],
        },
        {
          type: "Polygon",
          id: 51197,
          arcs: [[5692, 5693, 5694, -5596, -5623]],
        },
        {
          type: "Polygon",
          id: 21141,
          arcs: [[5695, 5696, 5697, -5505, -5514, -5647]],
        },
        {
          type: "Polygon",
          id: 21219,
          arcs: [[-5698, 5698, 5699, -5658, -5506]],
        },
        {
          type: "Polygon",
          id: 29067,
          arcs: [[-5442, -5357, 5700, 5701, 5702, -5690, -5434]],
        },
        {
          type: "Polygon",
          id: 21157,
          arcs: [[-5655, 5703, 5704, 5705, -5635, -5490]],
        },
        {
          type: "Polygon",
          id: 29133,
          arcs: [[-5633, 5706, 5707, 5708, 5709, -5614, -5570]],
        },
        {
          type: "Polygon",
          id: 51083,
          arcs: [[-5619, 5710, 5711, 5712, 5713, -5670, -5485]],
        },
        {
          type: "Polygon",
          id: 29091,
          arcs: [[-5495, 5714, 5715, 5716, -5701, -5356]],
        },
        {
          type: "Polygon",
          id: 29145,
          arcs: [[-5604, 5717, 5718, 5719, -5557, -5543]],
        },
        { type: "Polygon", id: 21121, arcs: [[5720, 5721, -5572, -5551]] },
        {
          type: "Polygon",
          id: 51025,
          arcs: [[-5611, 5722, 5723, 5724, 5725, -5681, -5598]],
        },
        {
          type: "Polygon",
          id: 21095,
          arcs: [[-5613, -5643, 5726, 5727, -5577, -5481]],
        },
        {
          type: "Polygon",
          id: 51173,
          arcs: [[-5597, -5695, 5728, 5729, -5667, -5566]],
        },
        { type: "Polygon", id: 4017, arcs: [[5730, 5731, 5732, 5733, -4753]] },
        {
          type: "Polygon",
          id: 4005,
          arcs: [[-5734, 5734, 5735, 5736, -5416, -4754]],
        },
        {
          type: "Polygon",
          id: 21221,
          arcs: [[5737, 5738, -5704, -5654, -5535, -5661]],
        },
        {
          type: "Polygon",
          id: 40105,
          arcs: [[-5533, 5739, 5740, 5741, -5524]],
        },
        {
          type: "Polygon",
          id: 40113,
          arcs: [[5742, 5743, 5744, 5745, 5746, -5447, -5594]],
        },
        {
          type: "Polygon",
          id: 40151,
          arcs: [[-5461, 5747, 5748, 5749, 5750, 5751, -5529]],
        },
        {
          type: "Polygon",
          id: 40035,
          arcs: [[-5559, 5752, 5753, 5754, 5755, -5740, -5532]],
        },
        {
          type: "Polygon",
          id: 40147,
          arcs: [[-5742, 5756, 5757, -5743, -5593, -5525]],
        },
        {
          type: "Polygon",
          id: 40053,
          arcs: [[5758, 5759, 5760, -5527, -5451]],
        },
        {
          type: "Polygon",
          id: 40003,
          arcs: [[-5528, -5761, 5761, 5762, -5748, -5460]],
        },
        {
          type: "Polygon",
          id: 40071,
          arcs: [[-5747, 5763, 5764, -5759, -5450, -5448]],
        },
        {
          type: "Polygon",
          id: 40115,
          arcs: [[-5720, 5765, 5766, -5753, -5558]],
        },
        {
          type: "Polygon",
          id: 40059,
          arcs: [[-5530, -5752, -5751, 5767, 5768, 5769, -5457]],
        },
        {
          type: "Polygon",
          id: 35039,
          arcs: [[-5512, 5770, 5771, 5772, 5773, 5774, 5775, -5497]],
        },
        {
          type: "Polygon",
          id: 35045,
          arcs: [[-5776, 5776, 5777, 5778, -5322, -5324, -5498]],
        },
        {
          type: "Polygon",
          id: 4001,
          arcs: [[-5779, 5779, 5780, 5781, 5782, 5783, -5731, -4752]],
        },
        {
          type: "Polygon",
          id: 35059,
          arcs: [[5784, 5785, 5786, 5787, 5788, 5789, -5208, -5320]],
        },
        {
          type: "Polygon",
          id: 40025,
          arcs: [[-5518, 5790, 5791, 5792, -5785, -5319]],
        },
        {
          type: "Polygon",
          id: 40139,
          arcs: [[5793, 5794, 5795, 5796, -5791, -5517, -5520, -5522]],
        },
        {
          type: "Polygon",
          id: 40007,
          arcs: [[-5458, -5770, 5797, 5798, 5799, -5794, -5521, -5454]],
        },
        {
          type: "Polygon",
          id: 51175,
          arcs: [
            [5800, -5663, 5801, 5802, 5803, 5804, 5805, -5685, -5625, -5665],
          ],
        },
        {
          type: "Polygon",
          id: 21231,
          arcs: [[5806, 5807, 5808, 5809, -5648, -5554]],
        },
        {
          type: "Polygon",
          id: 35055,
          arcs: [[5810, 5811, -5771, -5511, -5309]],
        },
        {
          type: "Polygon",
          id: 35007,
          arcs: [[-5790, 5812, 5813, -5811, -5308, -5209]],
        },
        {
          type: "Polygon",
          id: 29209,
          arcs: [[5814, 5815, 5816, -5602, -5692]],
        },
        {
          type: "Polygon",
          id: 6069,
          arcs: [[-5326, -5384, 5817, -5605, -5437]],
        },
        {
          type: "Polygon",
          id: 21235,
          arcs: [[-5722, 5818, 5819, 5820, 5821, -5573]],
        },
        { type: "Polygon", id: 51710, arcs: [[5822, 5823]] },
        {
          type: "Polygon",
          id: 21147,
          arcs: [[-5822, 5824, 5825, -5807, -5553, -5574]],
        },
        {
          type: "Polygon",
          id: 21013,
          arcs: [[-5578, -5728, 5826, 5827, -5819, -5721, -5550]],
        },
        {
          type: "Polygon",
          id: 21039,
          arcs: [[-5637, 5828, 5829, -5707, -5632]],
        },
        {
          type: "Polygon",
          id: 21083,
          arcs: [[-5706, 5830, 5831, 5832, 5833, -5829, -5636]],
        },
        {
          type: "Polygon",
          id: 21057,
          arcs: [[5834, 5835, 5836, -5651, -5589, -5650]],
        },
        {
          type: "Polygon",
          id: 21003,
          arcs: [[-5657, 5837, 5838, 5839, 5840, -5645]],
        },
        {
          type: "Polygon",
          id: 29009,
          arcs: [[-5817, 5841, 5842, 5843, -5718, -5603]],
        },
        {
          type: "Polygon",
          id: 51810,
          arcs: [[5844, 5845, 5846, 5847, 5848, 5849, 5850, -5823, 5851]],
        },
        {
          type: "Polygon",
          id: 51035,
          arcs: [[5852, 5853, 5854, 5855, 5856, -5693, -5622, -5677]],
        },
        {
          type: "Polygon",
          id: 51191,
          arcs: [[5857, 5858, 5859, -5668, -5730, 5860, 5861, 5862, 5863]],
        },
        {
          type: "Polygon",
          id: 29023,
          arcs: [[-5680, 5864, 5865, 5866, -5687, -5580]],
        },
        {
          type: "Polygon",
          id: 51800,
          arcs: [[-5868, 5868, 5869, 5870, -5802, -5662, 5871]],
        },
        {
          type: "Polygon",
          id: 6053,
          arcs: [[-5818, -5383, 5872, 5873, 5874, -5606]],
        },
        {
          type: "Polygon",
          id: 51081,
          arcs: [[-5686, -5806, 5875, -5723, -5610]],
        },
        {
          type: "Polygon",
          id: 51105,
          arcs: [[5876, 5877, 5878, -5827, -5727, -5642]],
        },
        {
          type: "Polygon",
          id: 51117,
          arcs: [[-5726, 5879, 5880, 5881, -5711, -5618, -5682]],
        },
        {
          type: "Polygon",
          id: 21053,
          arcs: [[-5810, 5882, 5883, -5835, -5649]],
        },
        {
          type: "Polygon",
          id: 29149,
          arcs: [[-5689, 5884, 5885, 5886, 5887, -5715, -5494]],
        },
        {
          type: "Polygon",
          id: 51169,
          arcs: [[-5669, -5860, 5888, 5889, 5890, -5877, -5641]],
        },
        {
          type: "Polygon",
          id: 21213,
          arcs: [[5891, 5892, -5696, -5646, -5841]],
        },
        {
          type: "Polygon",
          id: 51141,
          arcs: [[5893, 5894, 5895, -5853, -5676, -5630]],
        },
        {
          type: "Polygon",
          id: 29143,
          arcs: [[-5710, 5896, 5897, 5898, 5899, 5900, 5901, -5678, -5615]],
        },
        {
          type: "Polygon",
          id: 51550,
          arcs: [[5902, -5904, 5904, 5905, -5850, 5906, 5907, -5869, -5909]],
        },
        {
          type: "Polygon",
          id: 51089,
          arcs: [[5909, 5910, -5894, -5629, -5675]],
        },
        {
          type: "Polygon",
          id: 32003,
          arcs: [[5911, 5912, -5467, -4254, -4626]],
        },
        {
          type: "Polygon",
          id: 21171,
          arcs: [[-5837, 5913, 5914, -5838, -5656, -5652]],
        },
        {
          type: "Polygon",
          id: 29181,
          arcs: [[-5867, 5915, 5916, -5885, -5688]],
        },
        {
          type: "Polygon",
          id: 29213,
          arcs: [[-5703, 5917, 5918, 5919, 5920, -5815, -5691]],
        },
        {
          type: "Polygon",
          id: 40153,
          arcs: [[5921, 5922, 5923, -5768, 5750, -5751, -5750]],
        },
        {
          type: "Polygon",
          id: 51077,
          arcs: [
            [-5857, 5924, -5855, 5925, 5926, 5927, 5928, -5861, -5729, -5694],
          ],
        },
        {
          type: "Polygon",
          id: 29153,
          arcs: [[-5717, 5929, 5930, 5931, -5918, -5702]],
        },
        {
          type: "Polygon",
          id: 21105,
          arcs: [[-5834, 5932, 5933, 5934, -5708, -5830]],
        },
        {
          type: "Polygon",
          id: 29119,
          arcs: [[-5844, 5935, 5936, -5766, -5719]],
        },
        {
          type: "Polygon",
          id: 21035,
          arcs: [[-5739, 5937, 5938, -5831, -5705]],
        },
        { type: "Polygon", id: 6107, arcs: [[5939, 5940, -5381, -5470]] },
        {
          type: "Polygon",
          id: 47161,
          arcs: [[-5660, 5941, 5942, 5943, 5944, -5938, -5738]],
        },
        {
          type: "Polygon",
          id: 40041,
          arcs: [[-5937, 5945, 5946, 5947, 5948, -5754, -5767]],
        },
        {
          type: "Polygon",
          id: 47147,
          arcs: [[5949, 5950, 5951, -5699, -5697, -5893, 5952]],
        },
        {
          type: "Polygon",
          id: 47165,
          arcs: [[5953, 5954, 5955, 5956, -5953, -5892, -5840]],
        },
        {
          type: "MultiPolygon",
          id: 21075,
          arcs: [[[5957, 5958, -5897, -5709, -5935]]],
        },
        {
          type: "Polygon",
          id: 47125,
          arcs: [[-5952, 5959, 5960, 5961, -5942, -5659, -5700]],
        },
        { type: "Polygon", id: 51590, arcs: [[5962, -5672]] },
        {
          type: "Polygon",
          id: 47111,
          arcs: [[5963, 5964, 5965, -5954, -5839, -5915, 5966]],
        },
        {
          type: "Polygon",
          id: 29069,
          arcs: [[-5902, 5967, 5968, 5969, 5970, 5971, 5972, -5865, -5679]],
        },
        {
          type: "Polygon",
          id: 47137,
          arcs: [[5973, 5974, 5975, 5976, -5883, -5809]],
        },
        {
          type: "Polygon",
          id: 47027,
          arcs: [[5977, 5978, -5967, -5914, -5836, -5884, -5977]],
        },
        {
          type: "Polygon",
          id: 47163,
          arcs: [[5979, 5980, 5981, -5889, -5859, -5983, -5863, 5983]],
        },
        {
          type: "Polygon",
          id: 47091,
          arcs: [[-5929, 5984, 5985, 5986, 5987, -5984, -5862]],
        },
        {
          type: "Polygon",
          id: 47151,
          arcs: [[5988, 5989, 5990, 5991, -5974, -5808, -5826]],
        },
        {
          type: "Polygon",
          id: 47025,
          arcs: [[5992, 5993, 5994, 5995, -5820, -5828, -5879]],
        },
        {
          type: "Polygon",
          id: 40103,
          arcs: [[5996, 5997, 5998, 5999, -5764, -5746]],
        },
        {
          type: "Polygon",
          id: 47067,
          arcs: [[-5891, 6000, 6001, -5993, -5878]],
        },
        {
          type: "Polygon",
          id: 40131,
          arcs: [[6002, 6003, 6004, -5757, -5741, -5756]],
        },
        {
          type: "Polygon",
          id: 47013,
          arcs: [[6005, 6006, -5989, -5825, -5821, -5996]],
        },
        {
          type: "Polygon",
          id: 47073,
          arcs: [[-5982, 6007, 6008, 6009, 6010, -6001, -5890]],
        },
        {
          type: "Polygon",
          id: 40047,
          arcs: [[-5765, -6000, 6011, 6012, 6013, -5762, -5760]],
        },
        {
          type: "Polygon",
          id: 40045,
          arcs: [[-5924, 6014, 6015, 6016, 6017, -5798, -5769]],
        },
        {
          type: "Polygon",
          id: 37009,
          arcs: [[6018, 6019, 6020, -5985, -5928]],
        },
        {
          type: "Polygon",
          id: 47049,
          arcs: [[-5992, 6021, 6022, 6023, 6024, -5975]],
        },
        { type: "Polygon", id: 37005, arcs: [[6025, 6026, -6019, -5927]] },
        {
          type: "Polygon",
          id: 40117,
          arcs: [[6027, 6028, 6029, -5997, -5745]],
        },
        {
          type: "Polygon",
          id: 37171,
          arcs: [[-5896, 6030, 6031, 6032, 6033, -6026, -5926, -5854]],
        },
        {
          type: "Polygon",
          id: 37073,
          arcs: [[6034, 6035, 6036, 6037, 6038, 6039, -5803, -5871]],
        },
        {
          type: "MultiPolygon",
          id: 37053,
          arcs: [[[6040, -5907, -5849, 6041]], [[-5845, 6042, 6043, 6044]]],
        },
        {
          type: "Polygon",
          id: 37169,
          arcs: [[-5911, 6045, 6046, -6031, -5895]],
        },
        {
          type: "Polygon",
          id: 37029,
          arcs: [[-5870, -5908, -6041, 6047, 6048, -6035]],
        },
        {
          type: "Polygon",
          id: 37185,
          arcs: [[6049, 6050, 6051, 6052, -5880, -5725]],
        },
        {
          type: "Polygon",
          id: 37131,
          arcs: [[6053, 6054, -6050, -5724, -5876, -5805, 6055]],
        },
        {
          type: "Polygon",
          id: 37091,
          arcs: [[-6040, 6056, 6057, -6056, -5804]],
        },
        { type: "Polygon", id: 37145, arcs: [[6058, 6059, 6060, 6061, -5713]] },
        { type: "Polygon", id: 37181, arcs: [[-6053, 6062, 6063, -5881]] },
        {
          type: "Polygon",
          id: 37077,
          arcs: [[6064, 6065, 6066, -6059, -5712, -5882, -6064]],
        },
        {
          type: "Polygon",
          id: 37157,
          arcs: [[-5674, 6067, 6068, 6069, -6046, -5910]],
        },
        {
          type: "Polygon",
          id: 37033,
          arcs: [[-6062, 6070, 6071, -6068, -5673, -5963, -5671, -5714]],
        },
        {
          type: "Polygon",
          id: 47133,
          arcs: [[-6025, 6072, 6073, -5978, -5976]],
        },
        {
          type: "Polygon",
          id: 47087,
          arcs: [[-6074, 6074, 6075, -5964, -5979]],
        },
        {
          type: "Polygon",
          id: 37083,
          arcs: [[6076, 6077, 6078, 6079, -6051, -6055]],
        },
        {
          type: "Polygon",
          id: 40097,
          arcs: [[-5949, 6080, 6081, -6003, -5755]],
        },
        {
          type: "Polygon",
          id: 47019,
          arcs: [[6082, 6083, 6084, 6085, -5980, -5988]],
        },
        { type: "Polygon", id: 37139, arcs: [[6086, 6087, -6036, -6049]] },
        {
          type: "Polygon",
          id: 47131,
          arcs: [[-5934, 6088, 6089, 6090, 6091, -5958]],
        },
        {
          type: "Polygon",
          id: 40093,
          arcs: [[-5763, -6014, 6092, 6093, 6094, -5922, -5749]],
        },
        {
          type: "Polygon",
          id: 47183,
          arcs: [[6095, 6096, 6097, -6089, -5933, -5833]],
        },
        {
          type: "Polygon",
          id: 47095,
          arcs: [[6098, 6099, -5900, -6101, -5898, -5959, -6092]],
        },
        {
          type: "Polygon",
          id: 48421,
          arcs: [[6101, 6102, -5792, -5797, 6103]],
        },
        {
          type: "Polygon",
          id: 47079,
          arcs: [[-5945, 6104, 6105, -6096, -5832, -5939]],
        },
        {
          type: "Polygon",
          id: 48195,
          arcs: [[6106, 6107, -6104, -5796, 6108]],
        },
        { type: "Polygon", id: 48111, arcs: [[-5793, -6103, 6109, -5786]] },
        {
          type: "Polygon",
          id: 48357,
          arcs: [[6110, 6111, -6109, -5795, -5800]],
        },
        {
          type: "Polygon",
          id: 48295,
          arcs: [[6112, 6113, -6111, -5799, -6018]],
        },
        {
          type: "Polygon",
          id: 5007,
          arcs: [[-5843, 6114, 6115, 6116, 6117, -5946, -5936]],
        },
        {
          type: "Polygon",
          id: 5049,
          arcs: [[6118, 6119, 6120, -5930, -5716, -5888]],
        },
        {
          type: "Polygon",
          id: 5015,
          arcs: [[-5816, -5921, 6121, 6122, 6123, -6115, -5842]],
        },
        {
          type: "Polygon",
          id: 5135,
          arcs: [[-5887, 6124, 6125, 6126, 6127, -6119]],
        },
        {
          type: "Polygon",
          id: 5121,
          arcs: [[6128, 6129, -6125, -5886, -5917, 6130]],
        },
        { type: "Polygon", id: 5009, arcs: [[6131, 6132, 6133, -6122, -5920]] },
        {
          type: "Polygon",
          id: 5089,
          arcs: [[6134, 6135, -6132, -5919, -5932]],
        },
        {
          type: "Polygon",
          id: 5005,
          arcs: [[-6121, 6136, 6137, 6138, -6135, -5931]],
        },
        {
          type: "Polygon",
          id: 5021,
          arcs: [[-5866, -5973, 6139, -6131, -5916]],
        },
        {
          type: "Polygon",
          id: 6031,
          arcs: [[6140, 6141, -5873, -5382, -5941]],
        },
        { type: "Polygon", id: 47169, arcs: [[-5966, 6142, 6143, -5955]] },
        {
          type: "Polygon",
          id: 47021,
          arcs: [[6144, 6145, 6146, 6147, -5960, -5951]],
        },
        {
          type: "Polygon",
          id: 47179,
          arcs: [[-6086, 6148, 6149, -6008, -5981]],
        },
        {
          type: "Polygon",
          id: 37193,
          arcs: [[-6034, 6150, 6151, 6152, 6153, 6154, -6020, -6027]],
        },
        {
          type: "Polygon",
          id: 47173,
          arcs: [[6155, 6156, 6157, -6006, -5995]],
        },
        {
          type: "Polygon",
          id: 47159,
          arcs: [[-6076, 6158, 6159, 6160, -6143, -5965]],
        },
        {
          type: "Polygon",
          id: 29155,
          arcs: [[-6100, 6161, 6162, -5968, -5901]],
        },
        {
          type: "Polygon",
          id: 40143,
          arcs: [[-6005, 6163, 6164, 6165, -6028, -5744, -5758]],
        },
        {
          type: "Polygon",
          id: 47057,
          arcs: [[-6002, -6011, 6166, 6167, 6168, -6156, -5994]],
        },
        {
          type: "Polygon",
          id: 47037,
          arcs: [[-5957, 6169, 6170, 6171, -6145, -5950]],
        },
        {
          type: "Polygon",
          id: 47059,
          arcs: [[6172, 6173, 6174, 6175, 6176, -6009, -6150]],
        },
        {
          type: "Polygon",
          id: 37189,
          arcs: [[-6155, 6177, 6178, -5986, -6021]],
        },
        { type: "Polygon", id: 37143, arcs: [[6179, 6180, -6037, -6088]] },
        {
          type: "Polygon",
          id: 47129,
          arcs: [[-5991, 6181, 6182, 6183, -6022]],
        },
        {
          type: "Polygon",
          id: 47083,
          arcs: [[-5962, 6184, 6185, 6186, -5943]],
        },
        {
          type: "Polygon",
          id: 47005,
          arcs: [[-6187, 6187, 6188, 6189, 6190, -6105, -5944]],
        },
        {
          type: "Polygon",
          id: 47189,
          arcs: [[-6144, -6161, 6191, 6192, 6193, -6170, -5956]],
        },
        { type: "Polygon", id: 37041, arcs: [[6194, -6038, -6181]] },
        {
          type: "Polygon",
          id: 47063,
          arcs: [[-6177, 6195, 6196, -6167, -6010]],
        },
        {
          type: "Polygon",
          id: 47043,
          arcs: [[-6148, -6147, 6197, 6198, 6199, -6185, -5961]],
        },
        {
          type: "Polygon",
          id: 5087,
          arcs: [[6200, 6201, 6202, 6203, 6204, -6116, -6124]],
        },
        {
          type: "Polygon",
          id: 47141,
          arcs: [[-6024, 6205, 6206, 6207, -6159, -6075, -6073]],
        },
        {
          type: "Polygon",
          id: 47001,
          arcs: [[-6158, 6208, 6209, -6182, -5990, -6007]],
        },
        {
          type: "Polygon",
          id: 37197,
          arcs: [[6210, 6211, 6212, -6151, -6033]],
        },
        {
          type: "Polygon",
          id: 37011,
          arcs: [[6213, 6214, 6215, 6216, 6217, -6083, -5987, -6179]],
        },
        {
          type: "Polygon",
          id: 37069,
          arcs: [[6218, 6219, -6065, -6063, -6052]],
        },
        {
          type: "Polygon",
          id: 5055,
          arcs: [[-5972, -5971, 6220, 6221, -6129, -6140]],
        },
        {
          type: "Polygon",
          id: 35033,
          arcs: [[-5814, 6222, 6223, 6224, -5772, -5812]],
        },
        {
          type: "Polygon",
          id: 37067,
          arcs: [[6225, 6226, 6227, -6211, -6032, -6047]],
        },
        {
          type: "Polygon",
          id: 5065,
          arcs: [[-6128, 6228, 6229, -6137, -6120]],
        },
        {
          type: "Polygon",
          id: 47171,
          arcs: [[6230, 6231, 6232, -6173, -6149, -6085]],
        },
        {
          type: "Polygon",
          id: 5075,
          arcs: [[-6222, 6233, 6234, 6235, -6126, -6130]],
        },
        {
          type: "Polygon",
          id: 37081,
          arcs: [[6236, 6237, 6238, -6226, -6070]],
        },
        {
          type: "Polygon",
          id: 37001,
          arcs: [[6239, 6240, 6241, -6237, -6069, -6072]],
        },
        {
          type: "Polygon",
          id: 40119,
          arcs: [[6242, 6243, 6244, -5998, -6030]],
        },
        {
          type: "Polygon",
          id: 47085,
          arcs: [[-6200, 6245, 6246, -6188, -6186]],
        },
        {
          type: "Polygon",
          id: 37015,
          arcs: [[6247, 6248, 6249, -6250, 6250, -6077, -6054, -6058]],
        },
        {
          type: "Polygon",
          id: 37135,
          arcs: [[-6061, 6251, 6252, -6240, -6071]],
        },
        {
          type: "Polygon",
          id: 37063,
          arcs: [[-6067, 6253, 6254, -6252, -6060]],
        },
        { type: "Polygon", id: 5143, arcs: [[6255, 6256, -6117, -6205]] },
        {
          type: "MultiPolygon",
          id: 37055,
          arcs: [[[6257, 6258]], [[-6044, 6259]]],
        },
        {
          type: "Polygon",
          id: 47053,
          arcs: [[6260, 6261, 6262, 6263, -6090, -6098]],
        },
        {
          type: "Polygon",
          id: 35043,
          arcs: [[6264, 6265, 6266, 6267, 6268, -5777, -5775]],
        },
        {
          type: "Polygon",
          id: 35021,
          arcs: [[-5789, 6269, 6270, -6223, -5813]],
        },
        {
          type: "Polygon",
          id: 47045,
          arcs: [[6271, 6272, 6273, -6162, -6099, -6091, -6264]],
        },
        {
          type: "Polygon",
          id: 37127,
          arcs: [[-6080, 6274, 6275, 6276, -6219]],
        },
        {
          type: "Polygon",
          id: 47089,
          arcs: [[-6197, 6277, 6278, 6279, -6168]],
        },
        {
          type: "Polygon",
          id: 47093,
          arcs: [[-6280, 6280, 6281, 6282, 6283, -6209, -6157, -6169]],
        },
        {
          type: "Polygon",
          id: 47029,
          arcs: [[-6175, 6284, 6285, 6286, 6287, -6278, -6196, -6176]],
        },
        {
          type: "Polygon",
          id: 47035,
          arcs: [
            [-6184, 6288, 6289, 6290, 6291, 6292, 6293, 6294, -6206, -6023],
          ],
        },
        {
          type: "Polygon",
          id: 40073,
          arcs: [[6295, 6296, 6297, -6093, -6013]],
        },
        {
          type: "Polygon",
          id: 40083,
          arcs: [[-5999, -6245, 6298, 6299, -6296, -6012]],
        },
        {
          type: "Polygon",
          id: 40011,
          arcs: [[-6298, 6300, 6301, 6302, 6303, -6094]],
        },
        {
          type: "Polygon",
          id: 40043,
          arcs: [[6304, 6305, -6015, -5923, -6095, -6304]],
        },
        {
          type: "Polygon",
          id: 40037,
          arcs: [[-6166, 6306, 6307, 6308, -6243, -6029]],
        },
        {
          type: "Polygon",
          id: 40145,
          arcs: [[-6082, 6309, 6310, 6311, -6164, -6004]],
        },
        {
          type: "Polygon",
          id: 40021,
          arcs: [[-5948, 6312, 6313, 6314, -6310, -6081]],
        },
        {
          type: "Polygon",
          id: 40001,
          arcs: [[-6118, -6257, 6315, 6316, -6313, -5947]],
        },
        {
          type: "Polygon",
          id: 37121,
          arcs: [[-6218, 6317, 6318, -6231, -6084]],
        },
        {
          type: "Polygon",
          id: 37065,
          arcs: [[6319, 6320, 6321, -6275, -6079]],
        },
        {
          type: "Polygon",
          id: 47017,
          arcs: [[-6191, 6322, 6323, 6324, -6261, -6097, -6106]],
        },
        {
          type: "Polygon",
          id: 47041,
          arcs: [[6325, 6326, 6327, -6192, -6160, -6208]],
        },
        {
          type: "Polygon",
          id: 5137,
          arcs: [[6328, 6329, 6330, 6331, -6138, -6230]],
        },
        {
          type: "Polygon",
          id: 5101,
          arcs: [[-6134, 6332, 6333, 6334, -6201, -6123]],
        },
        {
          type: "Polygon",
          id: 37027,
          arcs: [[-6154, 6335, 6336, 6337, -6214, -6178]],
        },
        {
          type: "Polygon",
          id: 5129,
          arcs: [[-6139, -6332, 6338, 6339, -6333, -6133, -6136]],
        },
        {
          type: "Polygon",
          id: 47149,
          arcs: [[6340, 6341, 6342, 6343, 6344, -6171, -6194]],
        },
        {
          type: "Polygon",
          id: 37199,
          arcs: [[6345, 6346, 6347, -6232, -6319]],
        },
        {
          type: "Polygon",
          id: 47185,
          arcs: [[-6295, 6348, 6349, -6326, -6207]],
        },
        {
          type: "Polygon",
          id: 37183,
          arcs: [[-6220, 6350, 6351, 6352, -6254, -6066]],
        },
        {
          type: "Polygon",
          id: 37117,
          arcs: [[6249, 6353, 6354, 6355, -6320, -6078, -6251]],
        },
        {
          type: "Polygon",
          id: 37115,
          arcs: [[-6233, -6348, 6356, 6357, -6285, 6174, -6175, -6174]],
        },
        {
          type: "Polygon",
          id: 37059,
          arcs: [[6358, 6359, 6360, -6212, -6228]],
        },
        {
          type: "Polygon",
          id: 48393,
          arcs: [[-6114, 6361, 6362, 6363, 6364, -6107, -6112]],
        },
        {
          type: "Polygon",
          id: 48211,
          arcs: [[-6113, -6017, 6365, 6366, -6362]],
        },
        { type: "Polygon", id: 48233, arcs: [[-6108, -6365, 6367, 6368]] },
        {
          type: "Polygon",
          id: 48205,
          arcs: [[6369, 6370, 6371, -5787, -6110]],
        },
        {
          type: "Polygon",
          id: 37097,
          arcs: [[-6213, -6361, 6372, 6373, 6374, 6375, 6376, 6377, -6152]],
        },
        {
          type: "Polygon",
          id: 48341,
          arcs: [[-6369, 6378, 6379, 6380, -6370, -6102]],
        },
        {
          type: "Polygon",
          id: 47187,
          arcs: [[-6345, 6381, 6382, 6383, -6198, -6146, -6172]],
        },
        { type: "Polygon", id: 37003, arcs: [[6384, -6336, -6153, -6378]] },
        {
          type: "Polygon",
          id: 47145,
          arcs: [[6385, 6386, 6387, 6388, -6289, -6183, -6210, -6284, 6389]],
        },
        {
          type: "Polygon",
          id: 47155,
          arcs: [[-6288, -6287, 6286, 6390, 6391, 6392, -6281, -6279]],
        },
        {
          type: "Polygon",
          id: 37057,
          arcs: [[-6239, 6393, 6394, 6395, -6359, -6227]],
        },
        {
          type: "Polygon",
          id: 40129,
          arcs: [[-6306, 6396, 6397, 6398, -6366, -6016]],
        },
        { type: "Polygon", id: 35031, arcs: [[-6269, 6399, -5780, -5778]] },
        {
          type: "Polygon",
          id: 35049,
          arcs: [[-6225, 6400, 6401, 6402, -6266, 6403, 6404, 6405, -5773]],
        },
        {
          type: "Polygon",
          id: 5093,
          arcs: [
            [6406, 6407, 6408, 6409, 6410, 6411, 6412, -5969, -6163, -6274],
          ],
        },
        {
          type: "Polygon",
          id: 37023,
          arcs: [[-6338, 6413, 6414, 6415, 6416, 6417, -6216, -6215]],
        },
        {
          type: "Polygon",
          id: 47033,
          arcs: [[6418, 6419, 6420, -6272, -6263]],
        },
        {
          type: "Polygon",
          id: 5031,
          arcs: [[-6413, 6421, 6422, -6234, -6221, -5970]],
        },
        { type: "Polygon", id: 37177, arcs: [[6423, 6424, 6425]] },
        {
          type: "Polygon",
          id: 47081,
          arcs: [[-6384, 6426, 6427, 6428, -6246, -6199]],
        },
        {
          type: "Polygon",
          id: 37187,
          arcs: [[-6425, 6429, 6430, -6354, -6250, -6249, 6431]],
        },
        {
          type: "Polygon",
          id: 35028,
          arcs: [[-5774, -6406, 6432, -6404, -6265]],
        },
        {
          type: "Polygon",
          id: 47015,
          arcs: [[6433, 6434, -6341, -6193, -6328]],
        },
        {
          type: "Polygon",
          id: 37111,
          arcs: [[6435, 6436, -6346, -6318, -6217, -6418]],
        },
        {
          type: "Polygon",
          id: 47097,
          arcs: [[-6421, 6437, 6438, -6407, -6273]],
        },
        {
          type: "Polygon",
          id: 40081,
          arcs: [[-6309, 6439, 6440, 6441, -6299, -6244]],
        },
        {
          type: "Polygon",
          id: 5063,
          arcs: [[-6236, 6442, 6443, 6444, -6329, -6229, -6127]],
        },
        {
          type: "Polygon",
          id: 37151,
          arcs: [[-6242, 6445, 6446, 6447, -6394, -6238]],
        },
        {
          type: "Polygon",
          id: 47105,
          arcs: [[-6390, -6283, 6448, 6449, 6450, -6386]],
        },
        {
          type: "Polygon",
          id: 5067,
          arcs: [[-6235, -6423, 6451, 6452, 6453, 6454, -6443]],
        },
        {
          type: "Polygon",
          id: 47009,
          arcs: [[6455, 6456, 6457, -6449, -6282, -6393]],
        },
        {
          type: "Polygon",
          id: 37037,
          arcs: [[-6255, -6353, 6458, 6459, 6460, 6461, -6446, -6241, -6253]],
        },
        {
          type: "Polygon",
          id: 35047,
          arcs: [[-6271, 6462, 6463, 6464, -6401, -6224]],
        },
        {
          type: "Polygon",
          id: 37195,
          arcs: [[6465, 6466, 6467, 6468, -6276, -6322]],
        },
        {
          type: "Polygon",
          id: 37159,
          arcs: [[-6396, 6469, 6470, -6373, -6360]],
        },
        {
          type: "Polygon",
          id: 40111,
          arcs: [[-6165, -6312, 6471, 6472, 6473, -6307]],
        },
        {
          type: "Polygon",
          id: 40101,
          arcs: [[-6315, 6474, 6475, 6476, -6472, -6311]],
        },
        {
          type: "Polygon",
          id: 47177,
          arcs: [[-6350, 6477, 6478, 6479, 6480, -6434, -6327]],
        },
        {
          type: "Polygon",
          id: 47119,
          arcs: [[6481, 6482, 6483, 6484, -6427, -6383]],
        },
        {
          type: "Polygon",
          id: 47039,
          arcs: [[6485, 6486, 6487, 6488, -6323, -6190]],
        },
        {
          type: "Polygon",
          id: 47135,
          arcs: [[-6429, 6489, 6490, -6486, -6189, -6247]],
        },
        {
          type: "Polygon",
          id: 37147,
          arcs: [[-6356, 6491, 6492, 6493, 6494, 6495, 6496, -6466, -6321]],
        },
        {
          type: "Polygon",
          id: 37035,
          arcs: [[-6377, 6497, -6414, -6337, -6385]],
        },
        {
          type: "Polygon",
          id: 37021,
          arcs: [[-6437, 6498, 6499, 6500, -6357, -6347]],
        },
        {
          type: "Polygon",
          id: 47143,
          arcs: [[6501, 6502, 6503, -6290, -6389]],
        },
        {
          type: "Polygon",
          id: 47175,
          arcs: [[-6294, 6504, -6292, 6505, 6506, -6478, -6349]],
        },
        {
          type: "Polygon",
          id: 47077,
          arcs: [[-6489, 6507, 6508, 6509, -6324]],
        },
        {
          type: "Polygon",
          id: 47075,
          arcs: [[6510, 6511, 6512, 6513, -6438, -6420]],
        },
        {
          type: "Polygon",
          id: 37101,
          arcs: [[-6469, 6514, 6515, 6516, -6351, -6277]],
        },
        {
          type: "Polygon",
          id: 40039,
          arcs: [[-6303, 6517, 6518, 6519, -6397, -6305]],
        },
        {
          type: "Polygon",
          id: 6071,
          arcs: [[6520, 6521, 6522, 6523, 6524, 6525, -5468, -5913]],
        },
        { type: "Polygon", id: 6079, arcs: [[-6142, 6526, 6527, 6528, -5874]] },
        {
          type: "Polygon",
          id: 6029,
          arcs: [[-6526, 6529, 6530, 6531, -6527, -6141, -5940, -5469]],
        },
        {
          type: "Polygon",
          id: 47113,
          arcs: [[-6325, -6510, 6532, 6533, -6511, -6419, -6262]],
        },
        {
          type: "Polygon",
          id: 5141,
          arcs: [[-6331, 6534, 6535, 6536, 6537, -6339]],
        },
        {
          type: "Polygon",
          id: 37087,
          arcs: [[-6501, 6538, 6539, 6540, -6391, -6287, -6286, -6358]],
        },
        { type: "Polygon", id: 5047, arcs: [[6541, 6542, 6543, -6203, 6544]] },
        {
          type: "Polygon",
          id: 47007,
          arcs: [[-6504, 6545, 6546, -6506, -6291]],
        },
        {
          type: "Polygon",
          id: 5071,
          arcs: [[-6202, -6335, 6547, 6548, -6545]],
        },
        {
          type: "Polygon",
          id: 5033,
          arcs: [[6549, 6550, -6316, -6256, -6204, -6544]],
        },
        {
          type: "Polygon",
          id: 47121,
          arcs: [[6551, 6552, 6553, -6502, -6388]],
        },
        {
          type: "Polygon",
          id: 35037,
          arcs: [
            [-6372, 6554, 6555, 6556, 6557, 6558, 6559, -6463, -6270, -5788],
          ],
        },
        {
          type: "MultiPolygon",
          id: 37013,
          arcs: [
            [[6560, 6561, 6562, -6494]],
            [[-6431, 6563, 6564, -6492, -6355]],
          ],
        },
        {
          type: "Polygon",
          id: 5115,
          arcs: [[-6340, -6538, 6565, 6566, 6567, -6548, -6334]],
        },
        {
          type: "Polygon",
          id: 40017,
          arcs: [[6568, 6569, 6570, 6571, 6572, -6301, -6297]],
        },
        {
          type: "Polygon",
          id: 40109,
          arcs: [[-6442, 6573, 6574, -6569, -6300]],
        },
        {
          type: "Polygon",
          id: 5023,
          arcs: [[-6445, 6575, 6576, -6535, -6330]],
        },
        {
          type: "Polygon",
          id: 47117,
          arcs: [[6577, 6578, 6579, -6482, -6382, -6344]],
        },
        {
          type: "Polygon",
          id: 5111,
          arcs: [[-6412, 6580, 6581, -6452, -6422]],
        },
        {
          type: "Polygon",
          id: 37095,
          arcs: [[-6424, 6582, -6258, 6583, -6564, -6430]],
        },
        {
          type: "Polygon",
          id: 47031,
          arcs: [[-6435, -6481, 6584, 6585, 6586, 6587, -6342]],
        },
        {
          type: "Polygon",
          id: 47003,
          arcs: [[-6588, 6588, 6589, -6578, -6343]],
        },
        {
          type: "Polygon",
          id: 37173,
          arcs: [[6590, 6591, 6592, -6456, -6392, -6541]],
        },
        {
          type: "Polygon",
          id: 47123,
          arcs: [[-6458, 6593, 6594, 6595, 6596, 6597, 6598, -6450]],
        },
        {
          type: "Polygon",
          id: 47101,
          arcs: [[-6485, 6599, 6600, -6490, -6428]],
        },
        { type: "Polygon", id: 37079, arcs: [[6601, 6602, -6467, -6497]] },
        {
          type: "MultiPolygon",
          id: 47167,
          arcs: [[[6603, 6604, -6410]], [[-6514, 6605, 6606, -6408, -6439]]],
        },
        {
          type: "Polygon",
          id: 47107,
          arcs: [[-6451, -6599, 6607, -6597, 6608, 6609, -6552, -6387]],
        },
        {
          type: "Polygon",
          id: 40135,
          arcs: [[-6317, -6551, 6610, 6611, 6612, -6475, -6314]],
        },
        {
          type: "Polygon",
          id: 40107,
          arcs: [[-6474, 6613, 6614, 6615, 6616, -6440, -6308]],
        },
        { type: "Polygon", id: 37105, arcs: [[6617, 6618, -6461, -6460]] },
        {
          type: "Polygon",
          id: 48359,
          arcs: [[-6381, 6619, 6620, -6555, -6371]],
        },
        {
          type: "Polygon",
          id: 48065,
          arcs: [[-6364, 6621, 6622, 6623, -6379, -6368]],
        },
        { type: "Polygon", id: 48179, arcs: [[6624, 6625, -6622, -6363]] },
        {
          type: "Polygon",
          id: 48483,
          arcs: [[-6399, 6626, 6627, -6625, -6367]],
        },
        { type: "Polygon", id: 48375, arcs: [[-6624, 6628, -6620, -6380]] },
        {
          type: "Polygon",
          id: 37161,
          arcs: [[6629, 6630, 6631, 6632, 6633, -6499, -6436, -6417]],
        },
        {
          type: "Polygon",
          id: 37191,
          arcs: [[-6603, 6634, 6635, 6636, -6515, -6468]],
        },
        {
          type: "Polygon",
          id: 47023,
          arcs: [[6637, 6638, 6639, -6533, -6509]],
        },
        {
          type: "Polygon",
          id: 37085,
          arcs: [[-6517, 6640, 6641, 6642, -6618, -6459, -6352]],
        },
        {
          type: "Polygon",
          id: 37045,
          arcs: [[6643, 6644, 6645, 6646, -6630, -6416]],
        },
        {
          type: "Polygon",
          id: 37109,
          arcs: [[-6376, 6647, 6648, -6644, -6415, -6498]],
        },
        {
          type: "Polygon",
          id: 47153,
          arcs: [[6649, 6650, 6651, -6479, -6507, -6547]],
        },
        {
          type: "Polygon",
          id: 40091,
          arcs: [[6652, 6653, 6654, -6614, -6473, -6477]],
        },
        {
          type: "Polygon",
          id: 40015,
          arcs: [[-6573, 6655, 6656, 6657, 6658, -6518, -6302]],
        },
        {
          type: "Polygon",
          id: 5145,
          arcs: [[-6455, 6659, 6660, 6661, 6662, -6576, -6444]],
        },
        {
          type: "Polygon",
          id: 47061,
          arcs: [[6663, 6664, -6585, -6480, -6652]],
        },
        {
          type: "Polygon",
          id: 4025,
          arcs: [[6665, 6666, 6667, 6668, 6669, -5736]],
        },
        {
          type: "Polygon",
          id: 37099,
          arcs: [[-6540, 6670, 6671, 6672, 6673, -6591]],
        },
        {
          type: "Polygon",
          id: 37119,
          arcs: [[6674, 6675, 6676, 6677, 6678, -6648, -6375]],
        },
        {
          type: "Polygon",
          id: 37125,
          arcs: [[-6619, -6643, 6679, 6680, 6681, 6682, -6447, -6462]],
        },
        {
          type: "Polygon",
          id: 37123,
          arcs: [[-6683, 6683, 6684, -6395, -6448]],
        },
        {
          type: "Polygon",
          id: 37025,
          arcs: [[6685, 6686, -6675, -6374, -6471]],
        },
        {
          type: "Polygon",
          id: 40009,
          arcs: [[-6520, 6687, 6688, 6689, 6690, 6691, -6627, -6398]],
        },
        {
          type: "Polygon",
          id: 37167,
          arcs: [[-6685, 6692, 6693, -6686, -6470]],
        },
        {
          type: "Polygon",
          id: 37089,
          arcs: [[-6634, 6694, 6695, 6696, -6500]],
        },
        {
          type: "Polygon",
          id: 47181,
          arcs: [[6697, 6698, 6699, -6487, -6491, -6601]],
        },
        { type: "Polygon", id: 40133, arcs: [[6700, 6701, 6702, -6616]] },
        {
          type: "Polygon",
          id: 37075,
          arcs: [[6703, 6704, -6594, -6457, -6593]],
        },
        { type: "Polygon", id: 40149, arcs: [[-6659, 6705, -6688, -6519]] },
        {
          type: "Polygon",
          id: 40125,
          arcs: [[-6617, -6703, 6706, 6707, 6708, -6574, -6441]],
        },
        { type: "Polygon", id: 5029, arcs: [[6709, 6710, 6711, -6566, -6537]] },
        {
          type: "Polygon",
          id: 47099,
          arcs: [[-6484, 6712, 6713, -6698, -6600]],
        },
        {
          type: "Polygon",
          id: 40061,
          arcs: [[6714, 6715, 6716, -6653, -6476, -6613]],
        },
        {
          type: "Polygon",
          id: 47065,
          arcs: [[-6554, 6717, 6718, 6719, 6720, 6721, -6650, -6546, -6503]],
        },
        {
          type: "Polygon",
          id: 47055,
          arcs: [[-6580, 6722, 6723, 6724, -6713, -6483]],
        },
        {
          type: "Polygon",
          id: 5131,
          arcs: [[-6543, 6725, 6726, 6727, -6611, -6550]],
        },
        { type: "Polygon", id: 5037, arcs: [[6728, 6729, 6730, -6453, -6582]] },
        {
          type: "Polygon",
          id: 5147,
          arcs: [[-6731, 6731, 6732, 6733, -6660, -6454]],
        },
        {
          type: "Polygon",
          id: 5035,
          arcs: [[-6411, -6605, 6734, 6735, 6736, 6737, 6738, -6729, -6581]],
        },
        {
          type: "Polygon",
          id: 47069,
          arcs: [[-6534, -6640, 6739, 6740, 6741, 6742, 6743, -6512]],
        },
        {
          type: "Polygon",
          id: 37107,
          arcs: [[6744, 6745, 6746, -6635, -6602, -6496]],
        },
        {
          type: "Polygon",
          id: 5083,
          arcs: [[-6549, -6568, 6747, 6748, -6726, -6542]],
        },
        {
          type: "Polygon",
          id: 37175,
          arcs: [[6749, 6750, 6751, -6671, -6539, -6697]],
        },
        {
          type: "Polygon",
          id: 47071,
          arcs: [[-6700, 6752, 6753, 6754, 6755, -6638, -6508, -6488]],
        },
        { type: "Polygon", id: 37071, arcs: [[-6679, 6756, -6645, -6649]] },
        {
          type: "MultiPolygon",
          id: 37049,
          arcs: [
            [[6757, 6758, 6759]],
            [[-6563, 6760, 6761, 6762, -6745, -6495]],
          ],
        },
        { type: "Polygon", id: 47127, arcs: [[6763, 6764, -6589, -6587]] },
        {
          type: "Polygon",
          id: 47157,
          arcs: [[6765, 6766, 6767, -6735, -6604, -6409, -6607]],
        },
        { type: "Polygon", id: 37149, arcs: [[6768, 6769, -6695, -6633]] },
        {
          type: "Polygon",
          id: 47047,
          arcs: [[-6744, 6770, 6771, -6766, -6606, -6513]],
        },
        { type: "Polygon", id: 47109, arcs: [[-6756, 6772, -6740, -6639]] },
        {
          type: "Polygon",
          id: 40079,
          arcs: [[6773, 6774, 6775, 6776, 6777, -6715, -6612, -6728]],
        },
        {
          type: "Polygon",
          id: 40051,
          arcs: [[6778, 6779, 6780, 6781, -6656, -6572]],
        },
        { type: "Polygon", id: 40027, arcs: [[-6709, 6782, -6570, -6575]] },
        {
          type: "Polygon",
          id: 47103,
          arcs: [[-6765, 6783, 6784, 6785, -6723, -6579, -6590]],
        },
        {
          type: "Polygon",
          id: 5045,
          arcs: [[-6577, -6663, 6786, 6787, 6788, -6710, -6536]],
        },
        {
          type: "Polygon",
          id: 47051,
          arcs: [[-6665, 6789, 6790, 6791, -6784, -6764, -6586]],
        },
        {
          type: "Polygon",
          id: 47011,
          arcs: [[6792, 6793, 6794, -6718, -6553, -6610]],
        },
        {
          type: "Polygon",
          id: 35006,
          arcs: [[-6268, 6795, 6796, 6797, 6798, -5781, -6400]],
        },
        {
          type: "Polygon",
          id: 40087,
          arcs: [[-6708, 6799, 6800, -6779, -6571, -6783]],
        },
        {
          type: "Polygon",
          id: 37113,
          arcs: [[6801, 6802, 6803, -6704, -6592, -6674]],
        },
        { type: "Polygon", id: 37137, arcs: [[-6761, -6562, 6804]] },
        {
          type: "Polygon",
          id: 47115,
          arcs: [[-6722, 6805, 6806, -6790, -6664, -6651]],
        },
        {
          type: "Polygon",
          id: 37163,
          arcs: [[-6637, 6807, 6808, 6809, 6810, -6641, -6516]],
        },
        {
          type: "Polygon",
          id: 5149,
          arcs: [[-6712, 6811, 6812, 6813, 6814, -6748, -6567]],
        },
        {
          type: "Polygon",
          id: 40121,
          arcs: [[-6717, 6815, 6816, 6817, 6818, 6819, -6654]],
        },
        {
          type: "Polygon",
          id: 37039,
          arcs: [[-6804, 6820, 6821, 6822, 6823, -6595, -6705]],
        },
        {
          type: "Polygon",
          id: 40063,
          arcs: [[-6655, -6820, 6824, 6825, -6701, -6615]],
        },
        {
          type: "Polygon",
          id: 47139,
          arcs: [[-6824, 6826, 6827, -6793, -6609, -6596]],
        },
        {
          type: "Polygon",
          id: 37051,
          arcs: [[-6811, 6828, 6829, 6830, -6680, -6642]],
        },
        {
          type: "Polygon",
          id: 37103,
          arcs: [[6831, -6759, 6832, 6833, 6834, -6746, -6763]],
        },
        {
          type: "Polygon",
          id: 35001,
          arcs: [[-6403, 6835, 6836, -6796, -6267]],
        },
        {
          type: "Polygon",
          id: 35019,
          arcs: [[-6560, 6837, 6838, 6839, -6464]],
        },
        {
          type: "Polygon",
          id: 45045,
          arcs: [[-6770, 6840, 6841, 6842, 6843, 6844, -6750, -6696]],
        },
        { type: "Polygon", id: 37007, arcs: [[6845, 6846, 6847, 6848, -6693]] },
        {
          type: "Polygon",
          id: 37093,
          arcs: [[-6831, 6849, 6850, 6851, -6681]],
        },
        {
          type: "Polygon",
          id: 37179,
          arcs: [[-6849, 6852, 6853, -6676, -6687, -6694]],
        },
        {
          type: "Polygon",
          id: 45083,
          arcs: [[6854, 6855, -6841, -6769, -6632, 6856]],
        },
        {
          type: "Polygon",
          id: 37061,
          arcs: [[-6747, -6835, 6857, 6858, -6808, -6636]],
        },
        {
          type: "Polygon",
          id: 48117,
          arcs: [[6859, 6860, 6861, 6862, -6556, -6621]],
        },
        {
          type: "Polygon",
          id: 45021,
          arcs: [[6863, 6864, -6857, -6631, -6647, 6865]],
        },
        { type: "Polygon", id: 48129, arcs: [[6866, 6867, 6868, 6869, -6626]] },
        {
          type: "Polygon",
          id: 48087,
          arcs: [[-6692, 6870, 6871, 6872, -6867, -6628]],
        },
        {
          type: "Polygon",
          id: 48381,
          arcs: [[6873, 6874, 6875, -6860, -6629]],
        },
        {
          type: "Polygon",
          id: 37153,
          arcs: [[-6682, 6876, 6877, -6846, -6684]],
        },
        {
          type: "Polygon",
          id: 48011,
          arcs: [[-6870, 6878, 6879, -6874, -6623]],
        },
        {
          type: "Polygon",
          id: 45091,
          arcs: [[6880, 6881, 6882, -6866, -6646, -6757, -6678]],
        },
        {
          type: "Polygon",
          id: 37043,
          arcs: [[-6803, 6883, 6884, 6885, -6821]],
        },
        {
          type: "Polygon",
          id: 5123,
          arcs: [[-6739, 6886, 6887, -6732, -6730]],
        },
        {
          type: "Polygon",
          id: 40075,
          arcs: [[-6658, 6888, 6889, 6890, 6891, -6689, -6706]],
        },
        {
          type: "MultiPolygon",
          id: 37031,
          arcs: [[[6892, -6833, -6758, 6893]]],
        },
        { type: "Polygon", id: 40055, arcs: [[-6892, 6894, 6895, -6690]] },
        {
          type: "MultiPolygon",
          id: 6083,
          arcs: [[[6896]], [[6897]], [[-6532, 6898, 6899, -6528]]],
        },
        {
          type: "Polygon",
          id: 5105,
          arcs: [[6900, 6901, 6902, -6812, -6711, -6789]],
        },
        {
          type: "Polygon",
          id: 5127,
          arcs: [[-6815, 6903, 6904, -6774, -6727, -6749]],
        },
        { type: "Polygon", id: 5117, arcs: [[-6734, 6905, 6906, 6907, -6661]] },
        { type: "Polygon", id: 45077, arcs: [[-6845, 6908, 6909, -6751]] },
        {
          type: "Polygon",
          id: 5085,
          arcs: [[-6908, 6910, 6911, 6912, -6787, -6662]],
        },
        {
          type: "Polygon",
          id: 45057,
          arcs: [[-6854, 6913, 6914, 6915, 6916, -6881, -6677]],
        },
        { type: "Polygon", id: 40077, arcs: [[-6778, 6917, -6816, -6716]] },
        {
          type: "Polygon",
          id: 45073,
          arcs: [[6918, 6919, 6920, 6921, 6922, 6923, -6672, -6752, -6910]],
        },
        {
          type: "Polygon",
          id: 35057,
          arcs: [[-6840, 6924, 6925, 6926, -6836, -6402, -6465]],
        },
        {
          type: "Polygon",
          id: 37165,
          arcs: [[-6851, 6927, 6928, -6877, -6852]],
        },
        {
          type: "Polygon",
          id: 40057,
          arcs: [[-6896, 6929, 6930, 6931, -6871, -6691]],
        },
        {
          type: "Polygon",
          id: 5119,
          arcs: [[-6913, 6932, 6933, 6934, -6901, -6788]],
        },
        {
          type: "Polygon",
          id: 1077,
          arcs: [[6935, 6936, 6937, -6753, -6699, -6714, -6725, 6938]],
        },
        {
          type: "Polygon",
          id: 5095,
          arcs: [[-6888, 6939, 6940, 6941, -6906, -6733]],
        },
        {
          type: "Polygon",
          id: 13241,
          arcs: [[6942, 6943, -6884, -6802, -6673, -6924]],
        },
        {
          type: "Polygon",
          id: 1083,
          arcs: [[-6786, 6944, 6945, 6946, -6939, -6724]],
        },
        {
          type: "Polygon",
          id: 28003,
          arcs: [[6947, 6948, -6741, -6773, -6755, 6949]],
        },
        {
          type: "Polygon",
          id: 28141,
          arcs: [[6950, 6951, 6952, -6950, -6754, -6938, 6953]],
        },
        {
          type: "Polygon",
          id: 28139,
          arcs: [[6954, 6955, 6956, -6742, -6949]],
        },
        {
          type: "Polygon",
          id: 28033,
          arcs: [[6957, 6958, 6959, -6736, -6768, 6960]],
        },
        {
          type: "Polygon",
          id: 28009,
          arcs: [[6961, 6962, -6771, -6743, -6957]],
        },
        {
          type: "Polygon",
          id: 28093,
          arcs: [[6963, 6964, 6965, -6961, -6767, -6772, -6963]],
        },
        {
          type: "Polygon",
          id: 13281,
          arcs: [[-6944, 6966, 6967, 6968, -6885]],
        },
        {
          type: "Polygon",
          id: 1089,
          arcs: [[-6792, 6969, 6970, 6971, -6945, -6785]],
        },
        {
          type: "Polygon",
          id: 1071,
          arcs: [[6972, 6973, -6970, -6791, -6807, 6974]],
        },
        {
          type: "Polygon",
          id: 13213,
          arcs: [[6975, 6976, 6977, 6978, -6794, -6828, 6979]],
        },
        {
          type: "Polygon",
          id: 13111,
          arcs: [[-6823, 6980, 6981, 6982, 6983, -6980, -6827]],
        },
        {
          type: "Polygon",
          id: 13313,
          arcs: [[-6978, 6984, 6985, 6986, -6795, -6979]],
        },
        { type: "Polygon", id: 13047, arcs: [[6987, -6719, -6987]] },
        {
          type: "Polygon",
          id: 13291,
          arcs: [[-6886, -6969, 6988, 6989, -6981, -6822]],
        },
        {
          type: "Polygon",
          id: 37133,
          arcs: [[-6893, 6990, 6991, -6858, -6834]],
        },
        {
          type: "Polygon",
          id: 13083,
          arcs: [[-6806, -6721, 6992, 6993, -6975]],
        },
        {
          type: "Polygon",
          id: 13295,
          arcs: [[-6986, 6994, 6995, 6996, 6997, -6993, -6720, -6988]],
        },
        {
          type: "Polygon",
          id: 40123,
          arcs: [[-6702, -6826, 6998, 6999, 7000, 7001, -6800, -6707]],
        },
        { type: "Polygon", id: 35061, arcs: [[-6837, -6927, 7002, -6797]] },
        {
          type: "Polygon",
          id: 37155,
          arcs: [[7003, 7004, 7005, 7006, 7007, -6928, -6850, -6830]],
        },
        {
          type: "Polygon",
          id: 35009,
          arcs: [[-6863, 7008, 7009, 7010, -6557]],
        },
        {
          type: "Polygon",
          id: 45087,
          arcs: [[-6864, -6883, 7011, 7012, 7013, 7014, -6855, -6865]],
        },
        {
          type: "Polygon",
          id: 5077,
          arcs: [[-6738, 7015, 7016, -6940, -6887]],
        },
        { type: "Polygon", id: 1033, arcs: [[7017, 7018, -6954, -6937]] },
        {
          type: "MultiPolygon",
          id: 6111,
          arcs: [[[7019]], [[7020, -6899, -6531, 7021]]],
        },
        {
          type: "Polygon",
          id: 28143,
          arcs: [[-6737, -6960, 7022, 7023, 7024, 7025, 7026, -7016]],
        },
        {
          type: "Polygon",
          id: 1049,
          arcs: [[-6998, 7027, 7028, 7029, 7030, -6973, -6994]],
        },
        {
          type: "Polygon",
          id: 37017,
          arcs: [[7031, 7032, -7004, -6829, -6810]],
        },
        {
          type: "Polygon",
          id: 40065,
          arcs: [[7033, 7034, 7035, 7036, -6930, -6895, -6891]],
        },
        { type: "Polygon", id: 5125, arcs: [[-6935, 7037, 7038, 7039, -6902]] },
        {
          type: "Polygon",
          id: 40031,
          arcs: [[-6657, -6782, 7040, 7041, 7042, -6889]],
        },
        {
          type: "Polygon",
          id: 40049,
          arcs: [[-7002, 7043, 7044, 7045, -6780, -6801]],
        },
        {
          type: "Polygon",
          id: 13123,
          arcs: [[7046, 7047, 7048, -6976, -6984]],
        },
        {
          type: "Polygon",
          id: 13137,
          arcs: [[-6923, 7049, 7050, 7051, 7052, -6967, -6943]],
        },
        {
          type: "MultiPolygon",
          id: 6037,
          arcs: [[[7053]], [[7054]], [[-6525, 7055, 7056, -7022, -6530]]],
        },
        { type: "Polygon", id: 45023, arcs: [[-6917, 7057, -7012, -6882]] },
        {
          type: "Polygon",
          id: 45007,
          arcs: [[-6843, 7058, 7059, 7060, -6919, -6909, -6844]],
        },
        {
          type: "Polygon",
          id: 45025,
          arcs: [[7061, 7062, 7063, -6914, -6853, -6848, 7064]],
        },
        {
          type: "Polygon",
          id: 45069,
          arcs: [[-7008, 7065, 7066, 7067, -7065, -6847, -6878, -6929]],
        },
        {
          type: "Polygon",
          id: 1079,
          arcs: [[-6947, 7068, 7069, 7070, 7071, -7018, -6936]],
        },
        {
          type: "Polygon",
          id: 13311,
          arcs: [[7072, 7073, -6989, -6968, -7053]],
        },
        {
          type: "Polygon",
          id: 45059,
          arcs: [[-7015, 7074, 7075, 7076, -6842, -6856]],
        },
        {
          type: "Polygon",
          id: 35011,
          arcs: [[7077, 7078, 7079, -6838, -6559]],
        },
        {
          type: "Polygon",
          id: 28137,
          arcs: [[-6966, 7080, 7081, -7023, -6959, -6958]],
        },
        {
          type: "Polygon",
          id: 5051,
          arcs: [[-7040, 7082, 7083, -6813, -6903]],
        },
        {
          type: "Polygon",
          id: 40029,
          arcs: [[-6819, 7084, 7085, -6999, -6825]],
        },
        {
          type: "Polygon",
          id: 28117,
          arcs: [[-6953, 7086, 7087, 7088, -6955, -6948]],
        },
        {
          type: "Polygon",
          id: 48437,
          arcs: [[-6880, 7089, 7090, 7091, 7092, -6875]],
        },
        {
          type: "Polygon",
          id: 48045,
          arcs: [[-6869, 7093, 7094, 7095, -7090, -6879]],
        },
        {
          type: "Polygon",
          id: 48069,
          arcs: [[-7093, 7096, 7097, 7098, -6861, -6876]],
        },
        {
          type: "Polygon",
          id: 48191,
          arcs: [[-6873, 7099, 7100, 7101, -7094, -6868]],
        },
        {
          type: "Polygon",
          id: 48075,
          arcs: [[-6932, 7102, 7103, -7100, -6872]],
        },
        {
          type: "Polygon",
          id: 5097,
          arcs: [[-7084, 7104, 7105, 7106, 7107, -6904, -6814]],
        },
        {
          type: "Polygon",
          id: 48369,
          arcs: [[-7099, 7108, 7109, -7009, -6862]],
        },
        {
          type: "Polygon",
          id: 13187,
          arcs: [[-7074, 7110, 7111, -6982, -6990]],
        },
        {
          type: "Polygon",
          id: 37141,
          arcs: [[-6992, 7112, 7113, 7114, 7115, -7032, -6809, -6859]],
        },
        {
          type: "Polygon",
          id: 5113,
          arcs: [[7116, 7117, 7118, -6775, -6905, -7108]],
        },
        {
          type: "Polygon",
          id: 1103,
          arcs: [[-6972, 7119, 7120, -7069, -6946]],
        },
        { type: "Polygon", id: 13257, arcs: [[7121, 7122, -7050, -6922]] },
        {
          type: "Polygon",
          id: 40137,
          arcs: [[-7046, 7123, 7124, 7125, -7041, -6781]],
        },
        {
          type: "Polygon",
          id: 40005,
          arcs: [[7126, 7127, 7128, 7129, -7085, -6818]],
        },
        {
          type: "Polygon",
          id: 40127,
          arcs: [[-6777, 7130, 7131, -7127, -6817, -6918]],
        },
        {
          type: "Polygon",
          id: 5107,
          arcs: [[-7027, 7132, 7133, 7134, 7135, -6941, -7017]],
        },
        { type: "Polygon", id: 40099, arcs: [[-7001, 7136, 7137, -7044]] },
        {
          type: "Polygon",
          id: 40141,
          arcs: [[-7043, 7138, 7139, 7140, -7035, -7034, -6890]],
        },
        {
          type: "Polygon",
          id: 13129,
          arcs: [[-7049, 7141, 7142, 7143, -6995, -6985, -6977]],
        },
        {
          type: "Polygon",
          id: 45033,
          arcs: [[7144, 7145, 7146, -7066, -7007]],
        },
        {
          type: "Polygon",
          id: 13085,
          arcs: [[-7112, 7147, 7148, 7149, 7150, -7047, -6983]],
        },
        {
          type: "Polygon",
          id: 45055,
          arcs: [[-7063, 7151, 7152, 7153, 7154, 7155, -6915, -7064]],
        },
        {
          type: "Polygon",
          id: 35041,
          arcs: [[-7011, 7156, 7157, 7158, 7159, -7078, -6558]],
        },
        {
          type: "Polygon",
          id: 28145,
          arcs: [[-7089, 7160, 7161, 7162, -6964, -6962, -6956]],
        },
        {
          type: "Polygon",
          id: 1095,
          arcs: [[-7031, 7163, 7164, 7165, -7120, -6971, -6974]],
        },
        { type: "Polygon", id: 13055, arcs: [[7166, 7167, -7028, -6997]] },
        {
          type: "Polygon",
          id: 13115,
          arcs: [[-7144, 7168, 7169, 7170, -7167, -6996]],
        },
        {
          type: "Polygon",
          id: 35053,
          arcs: [[-6926, 7171, 7172, 7173, -6798, -7003]],
        },
        {
          type: "Polygon",
          id: 35003,
          arcs: [[-7174, 7174, 7175, 7176, -5782, -6799]],
        },
        {
          type: "Polygon",
          id: 1059,
          arcs: [[-7072, 7177, 7178, 7179, -6951, -7019]],
        },
        {
          type: "Polygon",
          id: 48197,
          arcs: [[-7037, 7180, 7181, 7182, -7103, -6931]],
        },
        {
          type: "Polygon",
          id: 45039,
          arcs: [[-6916, -7156, 7183, 7184, -7013, -7058]],
        },
        {
          type: "Polygon",
          id: 5001,
          arcs: [[-6942, -7136, 7185, 7186, 7187, -6911, -6907]],
        },
        { type: "Polygon", id: 13227, arcs: [[-7151, 7188, -7142, -7048]] },
        {
          type: "Polygon",
          id: 28071,
          arcs: [[-7163, 7189, 7190, 7191, 7192, -7081, -6965]],
        },
        {
          type: "Polygon",
          id: 28107,
          arcs: [[-7193, 7193, 7194, 7195, -7024, -7082]],
        },
        {
          type: "Polygon",
          id: 13119,
          arcs: [[7196, 7197, 7198, -7122, -6921]],
        },
        {
          type: "Polygon",
          id: 45031,
          arcs: [[-7068, 7199, 7200, -7152, -7062]],
        },
        {
          type: "Polygon",
          id: 45071,
          arcs: [[-7185, 7201, 7202, 7203, 7204, -7075, -7014]],
        },
        {
          type: "Polygon",
          id: 1019,
          arcs: [[-7171, 7205, 7206, 7207, 7208, -7029, -7168]],
        },
        {
          type: "Polygon",
          id: 28027,
          arcs: [[7209, 7210, 7211, -7133, -7026, 7212]],
        },
        { type: "Polygon", id: 28119, arcs: [[-7196, 7213, -7213, -7025]] },
        {
          type: "Polygon",
          id: 13139,
          arcs: [[-7052, 7214, 7215, 7216, 7217, -7148, -7111, -7073]],
        },
        {
          type: "Polygon",
          id: 28081,
          arcs: [[7218, 7219, 7220, 7221, -7161, -7088]],
        },
        {
          type: "Polygon",
          id: 40019,
          arcs: [[-7138, 7222, 7223, 7224, 7225, -7124, -7045]],
        },
        {
          type: "Polygon",
          id: 40069,
          arcs: [[-7000, -7086, -7130, 7226, 7227, -7223, -7137]],
        },
        {
          type: "Polygon",
          id: 40033,
          arcs: [[-7126, 7228, 7229, 7230, -7139, -7042]],
        },
        {
          type: "Polygon",
          id: 40089,
          arcs: [[-7119, 7231, 7232, 7233, 7234, 7235, -7131, -6776]],
        },
        {
          type: "Polygon",
          id: 5059,
          arcs: [[-7039, 7236, 7237, 7238, -7105, -7083]],
        },
        {
          type: "Polygon",
          id: 4007,
          arcs: [[-5733, 7239, 7240, 7241, -6667, -6666, -5735]],
        },
        {
          type: "Polygon",
          id: 13147,
          arcs: [[-7061, 7242, 7243, -7197, -6920]],
        },
        {
          type: "Polygon",
          id: 5053,
          arcs: [[-6934, 7244, 7245, 7246, -7237, -7038]],
        },
        {
          type: "Polygon",
          id: 5069,
          arcs: [[-6912, -7188, 7247, 7248, -7245, -6933]],
        },
        {
          type: "Polygon",
          id: 13011,
          arcs: [[7249, 7250, -7215, -7051, -7123, -7199]],
        },
        {
          type: "Polygon",
          id: 37047,
          arcs: [[-7116, 7251, 7252, -7005, -7033]],
        },
        {
          type: "Polygon",
          id: 45001,
          arcs: [[7253, 7254, 7255, -7059, -7077]],
        },
        {
          type: "Polygon",
          id: 28057,
          arcs: [[-6952, -7180, 7256, 7257, -7219, -7087]],
        },
        {
          type: "Polygon",
          id: 48487,
          arcs: [[-7141, 7258, 7259, 7260, -7181, -7036]],
        },
        {
          type: "Polygon",
          id: 13015,
          arcs: [[7261, 7262, 7263, -7169, -7143, 7264]],
        },
        {
          type: "Polygon",
          id: 13057,
          arcs: [[-7150, 7265, 7266, 7267, -7265, -7189]],
        },
        {
          type: "Polygon",
          id: 45047,
          arcs: [[-7205, 7268, 7269, 7270, -7254, -7076]],
        },
        { type: "Polygon", id: 37129, arcs: [[7271, 7272, 7273, 7274, -7114]] },
        {
          type: "Polygon",
          id: 28115,
          arcs: [[-7222, 7275, 7276, -7190, -7162]],
        },
        { type: "Polygon", id: 45061, arcs: [[7277, 7278, -7153, -7201]] },
        {
          type: "Polygon",
          id: 37019,
          arcs: [[-7275, 7279, 7280, 7281, 7282, -7252, -7115]],
        },
        { type: "Polygon", id: 5061, arcs: [[7283, 7284, 7285, 7286, -7117]] },
        { type: "Polygon", id: 5109, arcs: [[7287, 7288, 7289, -7284, -7107]] },
        {
          type: "Polygon",
          id: 35027,
          arcs: [[-7080, 7290, 7291, 7292, -7172, -6925, -6839]],
        },
        {
          type: "Polygon",
          id: 5019,
          arcs: [[-7239, 7293, 7294, 7295, -7288, -7106]],
        },
        {
          type: "Polygon",
          id: 13117,
          arcs: [[-7218, 7296, 7297, -7266, -7149]],
        },
        {
          type: "Polygon",
          id: 1093,
          arcs: [[7298, 7299, 7300, 7301, 7302, -7257, -7179]],
        },
        {
          type: "Polygon",
          id: 4012,
          arcs: [[-6669, 7303, 7304, 7305, 7306, -6522, 7307]],
        },
        {
          type: "Polygon",
          id: 48345,
          arcs: [[7308, 7309, 7310, -7095, -7102]],
        },
        {
          type: "Polygon",
          id: 48101,
          arcs: [[-7104, -7183, 7311, 7312, -7309, -7101]],
        },
        {
          type: "Polygon",
          id: 1043,
          arcs: [[-7166, 7313, 7314, 7315, -7070, -7121]],
        },
        {
          type: "Polygon",
          id: 48153,
          arcs: [[-7311, 7316, 7317, -7091, -7096]],
        },
        {
          type: "Polygon",
          id: 48189,
          arcs: [[-7097, -7092, -7318, 7318, 7319]],
        },
        {
          type: "Polygon",
          id: 48279,
          arcs: [[-7320, 7320, 7321, -7109, -7098]],
        },
        {
          type: "Polygon",
          id: 45041,
          arcs: [[7322, 7323, 7324, 7325, -7278, -7200, -7067, -7147]],
        },
        {
          type: "Polygon",
          id: 48017,
          arcs: [[-7322, 7326, -7157, -7010, -7110]],
        },
        {
          type: "Polygon",
          id: 1133,
          arcs: [[-7071, -7316, 7327, -7299, -7178]],
        },
        {
          type: "Polygon",
          id: 45051,
          arcs: [[-7253, -7283, 7328, 7329, 7330, -7145, -7006]],
        },
        {
          type: "Polygon",
          id: 45067,
          arcs: [[-7331, 7331, 7332, -7323, -7146]],
        },
        {
          type: "Polygon",
          id: 13157,
          arcs: [[7333, 7334, 7335, -7216, -7251]],
        },
        {
          type: "Polygon",
          id: 40067,
          arcs: [[-7226, 7336, 7337, 7338, -7229, -7125]],
        },
        {
          type: "Polygon",
          id: 13105,
          arcs: [[-7256, 7339, 7340, 7341, 7342, 7343, -7243, -7060]],
        },
        {
          type: "Polygon",
          id: 13195,
          arcs: [[-7344, 7344, 7345, 7346, -7334, -7250, -7198, -7244]],
        },
        {
          type: "Polygon",
          id: 45079,
          arcs: [[7347, 7348, 7349, -7202, -7184, -7155]],
        },
        {
          type: "Polygon",
          id: 1009,
          arcs: [[-7165, 7350, 7351, 7352, 7353, -7314]],
        },
        {
          type: "Polygon",
          id: 48155,
          arcs: [[-7182, -7261, 7354, 7355, 7356, -7312]],
        },
        {
          type: "Polygon",
          id: 48485,
          arcs: [[-7231, 7357, 7358, -7259, -7140]],
        },
        {
          type: "Polygon",
          id: 1055,
          arcs: [[-7209, 7359, 7360, -7351, -7164, -7030]],
        },
        {
          type: "Polygon",
          id: 45063,
          arcs: [[7361, 7362, 7363, 7364, -7203, -7350]],
        },
        {
          type: "Polygon",
          id: 28161,
          arcs: [[7365, 7366, 7367, -7194, -7192]],
        },
        { type: "Polygon", id: 5133, arcs: [[7368, -7232, -7118, -7287]] },
        {
          type: "Polygon",
          id: 45081,
          arcs: [[-7365, 7369, 7370, -7269, -7204]],
        },
        {
          type: "Polygon",
          id: 13121,
          arcs: [
            [-7298, 7371, 7372, 7373, 7374, 7375, 7376, 7377, 7378, -7267],
          ],
        },
        {
          type: "Polygon",
          id: 40095,
          arcs: [[7379, 7380, 7381, -7224, -7228]],
        },
        { type: "Polygon", id: 5079, arcs: [[-7187, 7382, 7383, 7384, -7248]] },
        {
          type: "Polygon",
          id: 45085,
          arcs: [[-7326, 7385, 7386, -7348, -7154, -7279]],
        },
        {
          type: "Polygon",
          id: 13135,
          arcs: [[7387, 7388, 7389, 7390, -7372, -7297, -7217]],
        },
        {
          type: "Polygon",
          id: 28135,
          arcs: [[-7368, 7391, 7392, 7393, -7210, -7214, -7195]],
        },
        {
          type: "Polygon",
          id: 28013,
          arcs: [[-7277, 7394, 7395, 7396, -7366, -7191]],
        },
        {
          type: "Polygon",
          id: 40013,
          arcs: [[7397, 7398, 7399, 7400, -7380, -7227, -7129]],
        },
        {
          type: "Polygon",
          id: 40023,
          arcs: [[-7236, 7401, 7402, -7398, -7128, -7132]],
        },
        {
          type: "Polygon",
          id: 5039,
          arcs: [[-7247, 7403, 7404, 7405, -7294, -7238]],
        },
        {
          type: "Polygon",
          id: 48077,
          arcs: [[-7339, 7406, 7407, 7408, -7358, -7230]],
        },
        { type: "Polygon", id: 13013, arcs: [[7409, 7410, -7388, -7336]] },
        {
          type: "Polygon",
          id: 28011,
          arcs: [[7411, 7412, 7413, 7414, -7134, -7212]],
        },
        {
          type: "Polygon",
          id: 5041,
          arcs: [[-7415, 7415, 7416, -7383, -7186, -7135]],
        },
        {
          type: "Polygon",
          id: 13233,
          arcs: [[-7264, 7417, 7418, 7419, -7206, -7170]],
        },
        {
          type: "Polygon",
          id: 28095,
          arcs: [[-7258, -7303, 7420, 7421, 7422, 7423, -7220]],
        },
        {
          type: "Polygon",
          id: 35005,
          arcs: [[-7160, 7424, 7425, 7426, -7291, -7079]],
        },
        {
          type: "Polygon",
          id: 13067,
          arcs: [[-7379, 7427, 7428, -7262, -7268]],
        },
        { type: "Polygon", id: 6065, arcs: [[7429, 7430, 7431, -6523, -7307]] },
        {
          type: "Polygon",
          id: 13223,
          arcs: [[-7429, 7432, 7433, 7434, -7418, -7263]],
        },
        {
          type: "Polygon",
          id: 45065,
          arcs: [[-7271, 7435, 7436, 7437, -7340, -7255]],
        },
        {
          type: "Polygon",
          id: 28017,
          arcs: [[-7221, -7424, 7438, 7439, -7395, -7276]],
        },
        {
          type: "Polygon",
          id: 40085,
          arcs: [[-7382, 7440, 7441, 7442, -7337, -7225]],
        },
        {
          type: "Polygon",
          id: 5025,
          arcs: [[-7385, 7443, 7444, 7445, -7404, -7246, -7249]],
        },
        { type: "Polygon", id: 1075, arcs: [[7446, 7447, 7448, -7421, -7302]] },
        {
          type: "Polygon",
          id: 13221,
          arcs: [[-7343, 7449, 7450, 7451, 7452, 7453, -7346, -7345]],
        },
        {
          type: "Polygon",
          id: 4013,
          arcs: [[-7242, 7454, 7455, 7456, -7304, -6668]],
        },
        { type: "Polygon", id: 13059, arcs: [[-7454, 7457, -7335, -7347]] },
        {
          type: "Polygon",
          id: 5057,
          arcs: [[7458, 7459, 7460, 7461, -7285, -7290]],
        },
        {
          type: "Polygon",
          id: 1127,
          arcs: [[-7315, -7354, 7462, 7463, 7464, -7300, -7328]],
        },
        {
          type: "Polygon",
          id: 13317,
          arcs: [[7465, 7466, 7467, 7468, -7450, -7342]],
        },
        {
          type: "Polygon",
          id: 48337,
          arcs: [[-7443, 7469, 7470, 7471, -7407, -7338]],
        },
        {
          type: "Polygon",
          id: 1115,
          arcs: [[7472, 7473, 7474, 7475, -7352, -7361]],
        },
        {
          type: "Polygon",
          id: 28133,
          arcs: [[-7394, 7476, 7477, 7478, -7412, -7211]],
        },
        {
          type: "Polygon",
          id: 13181,
          arcs: [[-7438, 7479, 7480, -7466, -7341]],
        },
        {
          type: "Polygon",
          id: 45037,
          arcs: [[7481, 7482, 7483, -7436, -7270, -7371]],
        },
        {
          type: "Polygon",
          id: 13089,
          arcs: [[-7391, 7484, 7485, 7486, -7373]],
        },
        {
          type: "Polygon",
          id: 1015,
          arcs: [[7487, -7473, -7360, -7208, 7488]],
        },
        {
          type: "Polygon",
          id: 13219,
          arcs: [[-7453, 7489, 7490, 7491, -7410, -7458]],
        },
        {
          type: "Polygon",
          id: 1029,
          arcs: [[-7420, 7492, 7493, 7494, 7495, 7496, -7489, -7207]],
        },
        {
          type: "Polygon",
          id: 48387,
          arcs: [[-7235, 7497, 7498, 7499, 7500, 7501, 7502, -7402]],
        },
        {
          type: "Polygon",
          id: 5099,
          arcs: [[-7296, 7503, 7504, 7505, -7459, -7289]],
        },
        {
          type: "Polygon",
          id: 48181,
          arcs: [[-7401, 7506, 7507, 7508, 7509, -7441, -7381]],
        },
        {
          type: "Polygon",
          id: 48097,
          arcs: [[-7510, 7510, 7511, -7470, -7442]],
        },
        {
          type: "Polygon",
          id: 5081,
          arcs: [[-7286, -7462, 7512, 7513, -7233, -7369]],
        },
        {
          type: "Polygon",
          id: 45027,
          arcs: [[7514, 7515, 7516, 7517, -7386, -7325]],
        },
        {
          type: "Polygon",
          id: 6059,
          arcs: [[-6524, -7432, 7518, 7519, -7056]],
        },
        {
          type: "Polygon",
          id: 48277,
          arcs: [[-7503, 7520, 7521, -7399, -7403]],
        },
        {
          type: "Polygon",
          id: 13297,
          arcs: [[-7492, 7522, 7523, 7524, -7389, -7411]],
        },
        {
          type: "Polygon",
          id: 1057,
          arcs: [[-7465, 7525, 7526, -7447, -7301]],
        },
        { type: "Polygon", id: 13143, arcs: [[7527, -7493, -7419, -7435]] },
        {
          type: "Polygon",
          id: 28043,
          arcs: [[-7397, 7528, 7529, 7530, 7531, -7392, -7367]],
        },
        {
          type: "Polygon",
          id: 45089,
          arcs: [[-7333, 7532, 7533, -7515, -7324]],
        },
        {
          type: "Polygon",
          id: 48147,
          arcs: [[-7522, 7534, 7535, 7536, -7507, -7400]],
        },
        {
          type: "Polygon",
          id: 45017,
          arcs: [[-7387, -7518, 7537, -7362, -7349]],
        },
        {
          type: "Polygon",
          id: 45003,
          arcs: [[7538, 7539, 7540, 7541, -7482, -7370, -7364]],
        },
        {
          type: "Polygon",
          id: 1073,
          arcs: [[-7476, 7542, 7543, 7544, -7463, -7353]],
        },
        {
          type: "Polygon",
          id: 48269,
          arcs: [[-7357, 7545, 7546, 7547, -7313]],
        },
        { type: "Polygon", id: 48275, arcs: [[7548, 7549, -7546, -7356]] },
        {
          type: "Polygon",
          id: 48009,
          arcs: [[-7409, 7550, 7551, 7552, -7359]],
        },
        { type: "Polygon", id: 48125, arcs: [[-7548, 7553, 7554, -7310]] },
        { type: "Polygon", id: 48107, arcs: [[-7555, 7555, 7556, -7317]] },
        {
          type: "Polygon",
          id: 48023,
          arcs: [[-7553, 7557, -7549, -7355, -7260]],
        },
        { type: "Polygon", id: 48303, arcs: [[-7557, 7558, 7559, -7319]] },
        {
          type: "Polygon",
          id: 48079,
          arcs: [[7560, 7561, 7562, -7158, -7327]],
        },
        { type: "Polygon", id: 48219, arcs: [[-7560, 7563, -7561, -7321]] },
        {
          type: "Polygon",
          id: 5103,
          arcs: [[-7406, 7564, 7565, 7566, -7504, -7295]],
        },
        {
          type: "Polygon",
          id: 13211,
          arcs: [[7567, 7568, 7569, 7570, -7523, -7491]],
        },
        {
          type: "Polygon",
          id: 13045,
          arcs: [[-7377, 7571, 7572, 7573, -7494, -7528, -7434, 7574]],
        },
        {
          type: "Polygon",
          id: 28083,
          arcs: [[-7532, 7575, 7576, 7577, -7477, -7393]],
        },
        {
          type: "Polygon",
          id: 28025,
          arcs: [[-7423, 7578, 7579, 7580, -7439]],
        },
        { type: "Polygon", id: 13097, arcs: [[-7378, -7575, -7433, -7428]] },
        {
          type: "Polygon",
          id: 5013,
          arcs: [[7581, 7582, -7565, -7405, -7446]],
        },
        {
          type: "Polygon",
          id: 5043,
          arcs: [[-7417, 7583, 7584, 7585, -7444, -7384]],
        },
        {
          type: "Polygon",
          id: 13247,
          arcs: [[7586, -7485, -7390, -7525, 7587]],
        },
        {
          type: "Polygon",
          id: 45043,
          arcs: [[-7330, 7588, 7589, 7590, -7533, -7332]],
        },
        {
          type: "Polygon",
          id: 4011,
          arcs: [[-7177, 7591, 7592, 7593, 7594, -5783]],
        },
        {
          type: "Polygon",
          id: 13133,
          arcs: [[7595, 7596, 7597, -7568, -7490, -7452]],
        },
        {
          type: "Polygon",
          id: 28087,
          arcs: [[-7449, 7598, 7599, 7600, -7579, -7422]],
        },
        {
          type: "Polygon",
          id: 13217,
          arcs: [[-7571, 7601, 7602, 7603, -7588, -7524]],
        },
        {
          type: "Polygon",
          id: 28155,
          arcs: [[-7581, 7604, 7605, 7606, -7529, -7396, -7440]],
        },
        {
          type: "Polygon",
          id: 13265,
          arcs: [[7607, 7608, 7609, -7596, -7451, -7469]],
        },
        {
          type: "Polygon",
          id: 48037,
          arcs: [[-7514, 7610, 7611, 7612, -7498, -7234]],
        },
        {
          type: "Polygon",
          id: 45075,
          arcs: [[-7517, 7613, 7614, 7615, 7616, 7617, -7539, -7363, -7538]],
        },
        {
          type: "Polygon",
          id: 5011,
          arcs: [[-7586, 7618, 7619, -7582, -7445]],
        },
        {
          type: "Polygon",
          id: 13073,
          arcs: [[-7437, -7484, 7620, 7621, -7480]],
        },
        {
          type: "Polygon",
          id: 1121,
          arcs: [[-7488, -7497, 7622, 7623, 7624, -7474]],
        },
        {
          type: "Polygon",
          id: 28097,
          arcs: [[-7607, 7625, 7626, 7627, -7530]],
        },
        {
          type: "Polygon",
          id: 28015,
          arcs: [[-7628, 7628, 7629, -7576, -7531]],
        },
        {
          type: "Polygon",
          id: 13189,
          arcs: [[-7481, -7622, 7630, 7631, 7632, 7633, -7467]],
        },
        {
          type: "Polygon",
          id: 4009,
          arcs: [[-7595, 7634, 7635, 7636, -7240, -5732, -5784]],
        },
        {
          type: "Polygon",
          id: 13063,
          arcs: [[-7487, 7637, 7638, 7639, -7374]],
        },
        {
          type: "Polygon",
          id: 13151,
          arcs: [[-7587, -7604, 7640, 7641, -7638, -7486]],
        },
        {
          type: "Polygon",
          id: 5091,
          arcs: [[7642, 7643, 7644, 7645, -7611, -7513, -7461]],
        },
        {
          type: "Polygon",
          id: 13301,
          arcs: [[7646, 7647, 7648, -7609, -7608, -7468, -7634]],
        },
        {
          type: "Polygon",
          id: 1125,
          arcs: [[-7464, -7545, 7649, 7650, 7651, 7652, -7526]],
        },
        {
          type: "Polygon",
          id: 35025,
          arcs: [[-7563, 7653, 7654, 7655, 7656, 7657, 7658, -7425, -7159]],
        },
        {
          type: "Polygon",
          id: 28105,
          arcs: [[-7601, 7659, 7660, 7661, -7605, -7580]],
        },
        {
          type: "Polygon",
          id: 5017,
          arcs: [[-7414, 7662, 7663, 7664, 7665, 7666, 7667, -7584, -7416]],
        },
        { type: "Polygon", id: 13113, arcs: [[7668, 7669, -7375, -7640]] },
        {
          type: "Polygon",
          id: 1117,
          arcs: [[-7625, 7670, 7671, 7672, -7543, -7475]],
        },
        {
          type: "Polygon",
          id: 13245,
          arcs: [[7673, 7674, 7675, -7631, -7621, -7483, -7542]],
        },
        {
          type: "Polygon",
          id: 1107,
          arcs: [[-7527, -7653, 7676, 7677, 7678, -7599, -7448]],
        },
        {
          type: "Polygon",
          id: 28019,
          arcs: [[-7662, 7679, 7680, -7626, -7606]],
        },
        {
          type: "Polygon",
          id: 28151,
          arcs: [[-7479, 7681, 7682, 7683, -7663, -7413]],
        },
        {
          type: "Polygon",
          id: 13159,
          arcs: [[7684, 7685, 7686, 7687, -7602, -7570]],
        },
        {
          type: "Polygon",
          id: 13077,
          arcs: [[-7670, 7688, 7689, 7690, 7691, -7572, -7376]],
        },
        {
          type: "Polygon",
          id: 45015,
          arcs: [[-7591, 7692, 7693, 7694, 7695, -7614, -7516, -7534]],
        },
        { type: "Polygon", id: 6073, arcs: [[7696, 7697, -7519, -7431]] },
        {
          type: "Polygon",
          id: 1111,
          arcs: [[-7574, 7698, 7699, 7700, 7701, 7702, -7495]],
        },
        {
          type: "Polygon",
          id: 1027,
          arcs: [[-7703, 7703, 7704, -7623, -7496]],
        },
        {
          type: "Polygon",
          id: 48119,
          arcs: [[-7502, 7705, 7706, 7707, -7535, -7521]],
        },
        {
          type: "Polygon",
          id: 45011,
          arcs: [[7708, 7709, 7710, -7540, -7618]],
        },
        {
          type: "Polygon",
          id: 13237,
          arcs: [[7711, 7712, 7713, -7685, -7569, -7598]],
        },
        {
          type: "Polygon",
          id: 35051,
          arcs: [[-7293, 7714, 7715, 7716, 7717, -7175, -7173]],
        },
        {
          type: "Polygon",
          id: 5073,
          arcs: [[-7506, 7718, 7719, 7720, -7643, -7460]],
        },
        {
          type: "Polygon",
          id: 13141,
          arcs: [[-7649, 7721, 7722, 7723, -7712, -7597, -7610]],
        },
        { type: "Polygon", id: 4027, arcs: [[-7457, 7724, 7725, 7726, -7305]] },
        { type: "Polygon", id: 4021, arcs: [[-7241, -7637, 7727, -7455]] },
        {
          type: "Polygon",
          id: 48237,
          arcs: [[-7472, 7728, 7729, 7730, 7731, -7551, -7408]],
        },
        {
          type: "Polygon",
          id: 5027,
          arcs: [[-7567, 7732, 7733, 7734, -7719, -7505]],
        },
        {
          type: "Polygon",
          id: 13035,
          arcs: [[-7688, 7735, 7736, 7737, -7641, -7603]],
        },
        { type: "Polygon", id: 45009, arcs: [[7738, 7739, -7709, -7617]] },
        {
          type: "Polygon",
          id: 6025,
          arcs: [[-7727, 7740, -7697, -7430, -7306]],
        },
        {
          type: "Polygon",
          id: 48497,
          arcs: [[-7512, 7741, 7742, 7743, -7729, -7471]],
        },
        {
          type: "Polygon",
          id: 48121,
          arcs: [[7744, 7745, 7746, -7742, -7511, -7509]],
        },
        { type: "Polygon", id: 13149, arcs: [[-7692, 7747, -7699, -7573]] },
        {
          type: "Polygon",
          id: 48231,
          arcs: [[7748, 7749, 7750, 7751, 7752, -7536, -7708, 7753]],
        },
        {
          type: "Polygon",
          id: 48085,
          arcs: [[-7537, -7753, 7754, 7755, -7745, -7508]],
        },
        { type: "Polygon", id: 48263, arcs: [[7756, 7757, 7758, 7759, -7554]] },
        {
          type: "Polygon",
          id: 48433,
          arcs: [[7760, 7761, 7762, -7757, -7547]],
        },
        { type: "Polygon", id: 48449, arcs: [[7763, 7764, 7765, -7500]] },
        {
          type: "Polygon",
          id: 48169,
          arcs: [[-7760, 7766, 7767, 7768, -7556]],
        },
        { type: "Polygon", id: 48447, arcs: [[7769, 7770, 7771, 7772, -7558]] },
        {
          type: "Polygon",
          id: 48503,
          arcs: [[-7732, 7773, 7774, -7770, -7552]],
        },
        {
          type: "Polygon",
          id: 48207,
          arcs: [[-7773, 7775, 7776, -7761, -7550]],
        },
        {
          type: "Polygon",
          id: 5003,
          arcs: [[-7668, 7777, 7778, -7619, -7585]],
        },
        {
          type: "Polygon",
          id: 48305,
          arcs: [[-7769, 7779, 7780, 7781, -7559]],
        },
        {
          type: "Polygon",
          id: 35035,
          arcs: [[-7427, 7782, 7783, 7784, 7785, 7786, -7715, -7292]],
        },
        { type: "Polygon", id: 48501, arcs: [[7787, 7788, -7654, -7562]] },
        {
          type: "Polygon",
          id: 48445,
          arcs: [[-7782, 7789, 7790, -7788, -7564]],
        },
        {
          type: "Polygon",
          id: 48159,
          arcs: [[-7766, 7791, 7792, 7793, -7706, -7501]],
        },
        {
          type: "Polygon",
          id: 5139,
          arcs: [[-7583, -7620, -7779, 7794, 7795, -7733, -7566]],
        },
        {
          type: "Polygon",
          id: 48223,
          arcs: [[-7794, 7796, 7797, -7754, -7707]],
        },
        {
          type: "Polygon",
          id: 48343,
          arcs: [[-7613, 7798, 7799, 7800, 7801, -7764, -7499]],
        },
        {
          type: "Polygon",
          id: 28051,
          arcs: [[-7630, 7802, 7803, 7804, -7577]],
        },
        {
          type: "Polygon",
          id: 13255,
          arcs: [[-7738, 7805, 7806, 7807, -7689, -7669, -7639, -7642]],
        },
        { type: "Polygon", id: 45035, arcs: [[-7696, 7808, 7809, -7615]] },
        {
          type: "Polygon",
          id: 28053,
          arcs: [[-7578, -7805, 7810, 7811, -7682, -7478]],
        },
        {
          type: "Polygon",
          id: 13125,
          arcs: [[7812, 7813, 7814, -7722, -7648]],
        },
        {
          type: "Polygon",
          id: 13163,
          arcs: [
            [-7675, 7815, 7816, 7817, 7818, -7813, -7647, -7633, -7632, -7676],
          ],
        },
        {
          type: "Polygon",
          id: 48067,
          arcs: [[-7646, 7819, 7820, -7799, -7612]],
        },
        {
          type: "Polygon",
          id: 13033,
          arcs: [[-7541, -7711, 7821, 7822, 7823, 7824, -7816, -7674]],
        },
        {
          type: "Polygon",
          id: 28103,
          arcs: [[-7679, 7825, 7826, 7827, -7660, -7600]],
        },
        {
          type: "Polygon",
          id: 28159,
          arcs: [[-7828, 7828, 7829, 7830, -7680, -7661]],
        },
        {
          type: "Polygon",
          id: 28007,
          arcs: [[-7831, 7831, 7832, -7803, -7629, -7627, -7681]],
        },
        {
          type: "Polygon",
          id: 1007,
          arcs: [[7833, 7834, 7835, -7650, -7544, -7673]],
        },
        {
          type: "Polygon",
          id: 13303,
          arcs: [[-7815, -7814, -7819, 7836, 7837, 7838, -7723]],
        },
        {
          type: "Polygon",
          id: 13199,
          arcs: [[-7808, 7839, 7840, 7841, 7842, 7843, -7690]],
        },
        {
          type: "Polygon",
          id: 13285,
          arcs: [[-7844, 7844, 7845, -7700, -7748, -7691]],
        },
        {
          type: "Polygon",
          id: 35017,
          arcs: [[-7718, 7846, 7847, -7592, -7176]],
        },
        { type: "Polygon", id: 13231, arcs: [[7848, 7849, -7840, -7807]] },
        {
          type: "Polygon",
          id: 13171,
          arcs: [[7850, 7851, -7849, -7806, -7737]],
        },
        {
          type: "Polygon",
          id: 13207,
          arcs: [[-7687, 7852, 7853, 7854, 7855, -7851, -7736]],
        },
        {
          type: "Polygon",
          id: 13009,
          arcs: [[-7724, -7839, 7856, 7857, -7713]],
        },
        {
          type: "Polygon",
          id: 13169,
          arcs: [[-7858, 7858, 7859, 7860, -7853, -7686, -7714]],
        },
        {
          type: "Polygon",
          id: 45029,
          arcs: [[-7810, 7861, 7862, 7863, 7864, -7739, -7616]],
        },
        {
          type: "Polygon",
          id: 45005,
          arcs: [[-7740, 7865, 7866, 7867, -7822, -7710]],
        },
        { type: "Polygon", id: 1063, arcs: [[7868, 7869, 7870, -7677, -7652]] },
        { type: "Polygon", id: 1017, arcs: [[-7846, 7871, 7872, 7873, -7701]] },
        {
          type: "Polygon",
          id: 1123,
          arcs: [[-7874, 7874, 7875, 7876, 7877, -7704, -7702]],
        },
        {
          type: "Polygon",
          id: 1037,
          arcs: [[-7878, 7878, 7879, -7671, -7624, -7705]],
        },
        { type: "Polygon", id: 28125, arcs: [[-7812, 7880, 7881, -7683]] },
        {
          type: "Polygon",
          id: 48063,
          arcs: [[-7802, 7882, 7883, -7792, -7765]],
        },
        {
          type: "Polygon",
          id: 1021,
          arcs: [[-7880, 7884, 7885, 7886, 7887, -7834, -7672]],
        },
        {
          type: "Polygon",
          id: 35013,
          arcs: [[7888, 7889, 7890, -7716, -7787]],
        },
        {
          type: "Polygon",
          id: 13251,
          arcs: [[-7867, 7891, 7892, 7893, 7894, 7895, -7823, -7868]],
        },
        {
          type: "Polygon",
          id: 45049,
          arcs: [[7896, 7897, 7898, -7892, -7866, -7865]],
        },
        {
          type: "Polygon",
          id: 28163,
          arcs: [[7899, 7900, 7901, 7902, 7903, -7881, -7811, -7804]],
        },
        {
          type: "Polygon",
          id: 22017,
          arcs: [[7904, 7905, 7906, 7907, 7908, -7820, -7645, 7909]],
        },
        {
          type: "Polygon",
          id: 22015,
          arcs: [[7910, 7911, 7912, -7910, -7644, -7721]],
        },
        {
          type: "Polygon",
          id: 22119,
          arcs: [[7913, -7911, -7720, -7735, 7914]],
        },
        {
          type: "Polygon",
          id: 22027,
          arcs: [[7915, 7916, -7915, -7734, -7796, 7917]],
        },
        {
          type: "Polygon",
          id: 22111,
          arcs: [[7918, 7919, -7918, -7795, 7920]],
        },
        {
          type: "Polygon",
          id: 48499,
          arcs: [[7921, 7922, 7923, 7924, 7925, -7797, -7793, -7884]],
        },
        {
          type: "Polygon",
          id: 28055,
          arcs: [[-7882, -7904, 7926, 7927, -7664, -7684]],
        },
        {
          type: "Polygon",
          id: 13319,
          arcs: [[-7838, 7928, 7929, 7930, -7859, -7857]],
        },
        {
          type: "Polygon",
          id: 22067,
          arcs: [[-7667, 7931, 7932, 7933, -7921, -7778]],
        },
        {
          type: "Polygon",
          id: 1065,
          arcs: [[-7836, 7934, 7935, -7869, -7651]],
        },
        { type: "Polygon", id: 22123, arcs: [[7936, 7937, -7932, -7666]] },
        {
          type: "Polygon",
          id: 48363,
          arcs: [[7938, 7939, 7940, 7941, 7942, -7774, -7731]],
        },
        {
          type: "Polygon",
          id: 22035,
          arcs: [[7943, 7944, 7945, -7937, -7665, -7928]],
        },
        {
          type: "Polygon",
          id: 48367,
          arcs: [[7946, 7947, 7948, -7939, -7730, -7744]],
        },
        {
          type: "Polygon",
          id: 13293,
          arcs: [[-7852, -7856, 7949, 7950, 7951, -7841, -7850]],
        },
        {
          type: "Polygon",
          id: 1119,
          arcs: [[7952, 7953, 7954, 7955, -7826, -7678, -7871]],
        },
        {
          type: "Polygon",
          id: 48439,
          arcs: [[-7743, -7747, 7956, 7957, 7958, -7947]],
        },
        {
          type: "Polygon",
          id: 48113,
          arcs: [[-7756, 7959, 7960, 7961, -7957, -7746]],
        },
        { type: "Polygon", id: 48397, arcs: [[-7752, 7962, -7960, -7755]] },
        { type: "Polygon", id: 48379, arcs: [[-7926, 7963, -7749, -7798]] },
        {
          type: "Polygon",
          id: 48415,
          arcs: [[7964, 7965, 7966, -7767, -7759]],
        },
        {
          type: "Polygon",
          id: 35015,
          arcs: [[-7659, 7967, 7968, 7969, -7783, -7426]],
        },
        {
          type: "Polygon",
          id: 48151,
          arcs: [[-7763, 7970, 7971, -7965, -7758]],
        },
        {
          type: "Polygon",
          id: 48033,
          arcs: [[-7967, 7972, 7973, 7974, -7780, -7768]],
        },
        {
          type: "Polygon",
          id: 48115,
          arcs: [[-7975, 7975, 7976, -7790, -7781]],
        },
        {
          type: "Polygon",
          id: 48165,
          arcs: [[-7791, -7977, 7977, 7978, -7655, -7789]],
        },
        {
          type: "Polygon",
          id: 48253,
          arcs: [[-7777, 7979, 7980, 7981, -7971, -7762]],
        },
        {
          type: "Polygon",
          id: 48417,
          arcs: [[7982, 7983, 7984, -7980, -7776, -7772]],
        },
        {
          type: "Polygon",
          id: 48429,
          arcs: [[-7943, 7985, -7983, -7771, -7775]],
        },
        { type: "Polygon", id: 13165, arcs: [[-7896, 7986, 7987, -7824]] },
        {
          type: "Polygon",
          id: 13021,
          arcs: [[7988, 7989, 7990, 7991, -7854, -7861]],
        },
        { type: "Polygon", id: 28099, arcs: [[7992, 7993, 7994, -7830]] },
        {
          type: "Polygon",
          id: 28079,
          arcs: [[-7995, 7995, 7996, 7997, -7832]],
        },
        {
          type: "Polygon",
          id: 28069,
          arcs: [[-7956, 7998, -7993, -7829, -7827]],
        },
        {
          type: "Polygon",
          id: 48459,
          arcs: [[-7801, 7999, 8000, 8001, 8002, -7922, -7883]],
        },
        {
          type: "Polygon",
          id: 13289,
          arcs: [[8003, 8004, -7990, -7989, -7860, -7931]],
        },
        {
          type: "Polygon",
          id: 13263,
          arcs: [[8005, 8006, 8007, 8008, 8009, -7842, -7952]],
        },
        {
          type: "Polygon",
          id: 48315,
          arcs: [[-7909, 8010, -8000, -7800, -7821]],
        },
        {
          type: "Polygon",
          id: 28089,
          arcs: [[-7998, 8011, 8012, 8013, -7901, -7900, -7833]],
        },
        {
          type: "Polygon",
          id: 1105,
          arcs: [[-7888, 8014, 8015, -7935, -7835]],
        },
        {
          type: "Polygon",
          id: 13145,
          arcs: [[-8010, 8016, 8017, -7872, -7845, -7843]],
        },
        {
          type: "Polygon",
          id: 13079,
          arcs: [[-7992, 8018, 8019, 8020, -7950, -7855]],
        },
        {
          type: "Polygon",
          id: 48257,
          arcs: [[8021, 8022, 8023, -7961, -7963, -7751]],
        },
        {
          type: "Polygon",
          id: 13107,
          arcs: [[-7825, -7988, 8024, 8025, 8026, 8027, 8028, 8029, -7817]],
        },
        {
          type: "Polygon",
          id: 48467,
          arcs: [[-7964, -7925, 8030, 8031, -8022, -7750]],
        },
        {
          type: "Polygon",
          id: 13167,
          arcs: [[-8030, 8032, -7929, -7837, -7818]],
        },
        {
          type: "Polygon",
          id: 48203,
          arcs: [[-7908, 8033, 8034, 8035, -8001, -8011]],
        },
        {
          type: "Polygon",
          id: 35023,
          arcs: [[8036, 8037, 8038, -7593, -7848]],
        },
        {
          type: "Polygon",
          id: 1051,
          arcs: [[8039, 8040, 8041, -7885, -7879, -7877]],
        },
        {
          type: "Polygon",
          id: 22061,
          arcs: [[8042, 8043, 8044, -7916, -7920]],
        },
        {
          type: "Polygon",
          id: 45053,
          arcs: [
            [
              8045,
              8046,
              8047,
              8048,
              8049,
              8050,
              8051,
              8052,
              8053,
              8054,
              8055,
              8056,
              -7898,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 13269,
          arcs: [[-8021, 8057, 8058, 8059, 8060, -8006, -7951]],
        },
        {
          type: "Polygon",
          id: 1081,
          arcs: [[-8018, 8061, 8062, 8063, -7875, -7873]],
        },
        {
          type: "Polygon",
          id: 1047,
          arcs: [[8064, 8065, 8066, 8067, -8015, -7887]],
        },
        {
          type: "Polygon",
          id: 22073,
          arcs: [[8068, 8069, 8070, -8043, -7919, -7934]],
        },
        {
          type: "Polygon",
          id: 13175,
          arcs: [[8071, 8072, 8073, -7930, -8033, 8074]],
        },
        {
          type: "Polygon",
          id: 1001,
          arcs: [[-8042, 8075, 8076, -8065, -7886]],
        },
        {
          type: "MultiPolygon",
          id: 45013,
          arcs: [
            [[8077]],
            [[-8053, -8052, 8078]],
            [[8079]],
            [[8080]],
            [[8081, -8046, -7897, -7864]],
          ],
        },
        { type: "Polygon", id: 13225, arcs: [[8082, -8058, -8020, 8083]] },
        {
          type: "Polygon",
          id: 13153,
          arcs: [[-8005, 8084, 8085, 8086, 8087, -8084, -8019, -7991]],
        },
        {
          type: "Polygon",
          id: 48423,
          arcs: [[-7923, -8003, 8088, 8089, 8090, 8091, -8031, -7924]],
        },
        {
          type: "Polygon",
          id: 22083,
          arcs: [[-7946, 8092, 8093, 8094, -8069, -7933, -7938]],
        },
        { type: "Polygon", id: 48183, arcs: [[-8036, 8095, -8089, -8002]] },
        {
          type: "Polygon",
          id: 13031,
          arcs: [[-7894, 8096, 8097, 8098, 8099, -8025, -7987, -7895]],
        },
        {
          type: "Polygon",
          id: 28123,
          arcs: [[-7997, 8100, 8101, 8102, -8012]],
        },
        {
          type: "Polygon",
          id: 28149,
          arcs: [[8103, 8104, 8105, 8106, 8107, 8108, -7944, -7927, -7903]],
        },
        {
          type: "Polygon",
          id: 13215,
          arcs: [[-8009, 8109, 8110, -8062, -8017]],
        },
        {
          type: "Polygon",
          id: 35029,
          arcs: [[-7891, 8111, -8037, -7847, -7717]],
        },
        {
          type: "Polygon",
          id: 13103,
          arcs: [[-8057, -8056, 8112, 8113, -8097, -7893, -7899]],
        },
        {
          type: "Polygon",
          id: 1087,
          arcs: [[8114, 8115, 8116, 8117, -8040, -7876, -8064]],
        },
        {
          type: "Polygon",
          id: 28121,
          arcs: [[-8103, 8118, 8119, 8120, -8013]],
        },
        {
          type: "Polygon",
          id: 22013,
          arcs: [[-8045, 8121, 8122, 8123, 8124, -7912, -7914, -7917]],
        },
        {
          type: "Polygon",
          id: 13023,
          arcs: [[8125, 8126, -8085, -8004, -8074]],
        },
        {
          type: "Polygon",
          id: 28075,
          arcs: [[-7955, 8127, 8128, 8129, -7999]],
        },
        {
          type: "Polygon",
          id: 28101,
          arcs: [[-8130, 8130, -8101, -7996, -7994]],
        },
        {
          type: "Polygon",
          id: 28049,
          arcs: [[-8121, 8131, 8132, -8104, -7902, -8014]],
        },
        {
          type: "Polygon",
          id: 22065,
          arcs: [[8133, 8134, -8093, -7945, -8109]],
        },
        {
          type: "Polygon",
          id: 13197,
          arcs: [[8135, 8136, 8137, 8138, 8139, -8007, -8061]],
        },
        { type: "Polygon", id: 13043, arcs: [[8140, 8141, -8026, -8100]] },
        {
          type: "Polygon",
          id: 48221,
          arcs: [[8142, 8143, 8144, -7940, -7949]],
        },
        {
          type: "Polygon",
          id: 48251,
          arcs: [[8145, 8146, 8147, 8148, -8143, -7948, -7959]],
        },
        {
          type: "Polygon",
          id: 48139,
          arcs: [[-7962, -8024, 8149, 8150, 8151, -8146, -7958]],
        },
        {
          type: "Polygon",
          id: 1091,
          arcs: [[-7936, -8016, -8068, 8152, 8153, 8154, -7953, -7870]],
        },
        {
          type: "Polygon",
          id: 13053,
          arcs: [[-8140, 8155, 8156, -8110, -8008]],
        },
        { type: "Polygon", id: 48227, arcs: [[8157, 8158, 8159, 8160, -7974]] },
        {
          type: "Polygon",
          id: 48335,
          arcs: [[8161, 8162, 8163, -8158, -7973, -7966]],
        },
        {
          type: "Polygon",
          id: 48353,
          arcs: [[8164, 8165, 8166, -8162, -7972]],
        },
        {
          type: "Polygon",
          id: 48317,
          arcs: [[-8161, 8167, 8168, 8169, -7978, -7976]],
        },
        {
          type: "Polygon",
          id: 48003,
          arcs: [[-8170, 8170, 8171, 8172, -7656, -7979]],
        },
        {
          type: "Polygon",
          id: 13193,
          arcs: [[-8083, -8088, 8173, 8174, 8175, -8059]],
        },
        {
          type: "Polygon",
          id: 48441,
          arcs: [[8176, 8177, 8178, -8165, -7982]],
        },
        {
          type: "Polygon",
          id: 48133,
          arcs: [[-7942, 8179, 8180, 8181, 8182, -7984, -7986]],
        },
        {
          type: "Polygon",
          id: 48143,
          arcs: [[-7941, -8145, 8183, 8184, 8185, 8186, -8180]],
        },
        {
          type: "Polygon",
          id: 48059,
          arcs: [[-8183, 8187, 8188, -8177, -7981, -7985]],
        },
        {
          type: "Polygon",
          id: 4019,
          arcs: [[-7636, 8189, 8190, 8191, -7725, -7456, -7728]],
        },
        { type: "Polygon", id: 13283, arcs: [[8192, 8193, -8075, -8029]] },
        {
          type: "Polygon",
          id: 1113,
          arcs: [[-8111, -8157, 8194, 8195, 8196, -8115, -8063]],
        },
        {
          type: "Polygon",
          id: 1101,
          arcs: [[-8118, -8117, 8197, 8198, 8199, 8200, -8076, -8041]],
        },
        {
          type: "Polygon",
          id: 22049,
          arcs: [[-8071, 8201, 8202, -8122, -8044]],
        },
        {
          type: "Polygon",
          id: 13091,
          arcs: [[8203, 8204, 8205, 8206, -8126, -8073]],
        },
        { type: "Polygon", id: 13249, arcs: [[-8176, 8207, -8136, -8060]] },
        {
          type: "Polygon",
          id: 4003,
          arcs: [[-7594, -8039, 8208, 8209, -8190, -7635]],
        },
        {
          type: "Polygon",
          id: 1085,
          arcs: [[-8201, 8210, 8211, 8212, -8066, -8077]],
        },
        {
          type: "Polygon",
          id: 48401,
          arcs: [[8213, 8214, 8215, 8216, -8090, -8096, -8035]],
        },
        {
          type: "Polygon",
          id: 22041,
          arcs: [[-8135, 8217, 8218, 8219, -8094]],
        },
        {
          type: "Polygon",
          id: 13235,
          arcs: [[-8127, -8207, 8220, 8221, -8086]],
        },
        {
          type: "Polygon",
          id: 48365,
          arcs: [[-7907, 8222, 8223, -8214, -8034]],
        },
        {
          type: "Polygon",
          id: 48213,
          arcs: [[-8032, -8092, 8224, 8225, 8226, 8227, -8150, -8023]],
        },
        { type: "Polygon", id: 13209, arcs: [[8228, 8229, -8193, 8230]] },
        {
          type: "Polygon",
          id: 13279,
          arcs: [[-8028, 8231, 8232, 8233, -8231]],
        },
        {
          type: "Polygon",
          id: 22031,
          arcs: [[8234, 8235, 8236, 8237, -8223, -7906]],
        },
        {
          type: "Polygon",
          id: 48349,
          arcs: [[8238, 8239, 8240, -8151, -8228]],
        },
        { type: "Polygon", id: 48425, arcs: [[-8149, 8241, -8184, -8144]] },
        {
          type: "Polygon",
          id: 13267,
          arcs: [[8242, 8243, 8244, 8245, 8246, -8232, -8027, -8142]],
        },
        {
          type: "Polygon",
          id: 1023,
          arcs: [[8247, 8248, 8249, 8250, -8128, -7954, -8155]],
        },
        {
          type: "Polygon",
          id: 13309,
          arcs: [[-8230, 8251, 8252, -8204, -8072, -8194]],
        },
        {
          type: "Polygon",
          id: 1011,
          arcs: [[-8197, 8253, 8254, -8198, -8116]],
        },
        {
          type: "Polygon",
          id: 13093,
          arcs: [[-8222, 8255, 8256, 8257, 8258, -8174, -8087]],
        },
        {
          type: "Polygon",
          id: 13109,
          arcs: [[-8099, 8259, 8260, -8243, -8141]],
        },
        {
          type: "Polygon",
          id: 22021,
          arcs: [[-8095, -8220, 8261, 8262, 8263, -8202, -8070]],
        },
        {
          type: "Polygon",
          id: 1131,
          arcs: [[-8067, -8213, 8264, 8265, 8266, -8153]],
        },
        {
          type: "Polygon",
          id: 48217,
          arcs: [[-8241, 8267, 8268, 8269, -8147, -8152]],
        },
        {
          type: "Polygon",
          id: 48093,
          arcs: [[8270, 8271, 8272, -8181, -8187]],
        },
        {
          type: "Polygon",
          id: 22107,
          arcs: [
            [-8108, 8273, -8106, 8274, 8275, 8276, 8277, 8278, -8218, -8134],
          ],
        },
        {
          type: "Polygon",
          id: 13029,
          arcs: [[8279, 8280, 8281, -8260, -8098, -8114]],
        },
        {
          type: "Polygon",
          id: 22081,
          arcs: [[-7913, -8125, 8282, -8235, -7905]],
        },
        {
          type: "MultiPolygon",
          id: 13051,
          arcs: [[[8283]], [[8284]], [[8285, -8280, -8113, -8055]]],
        },
        { type: "Polygon", id: 13307, arcs: [[8286, 8287, 8288, 8289, -8138]] },
        {
          type: "Polygon",
          id: 13259,
          arcs: [[-8290, 8290, 8291, 8292, -8195, -8156, -8139]],
        },
        {
          type: "Polygon",
          id: 13261,
          arcs: [[-8259, -8258, 8293, 8294, 8295, -8287, -8137, -8208, -8175]],
        },
        { type: "Polygon", id: 28023, arcs: [[-8251, 8296, 8297, -8129]] },
        {
          type: "Polygon",
          id: 28021,
          arcs: [[-8133, 8298, 8299, -8275, -8105]],
        },
        {
          type: "Polygon",
          id: 28061,
          arcs: [[-8298, 8300, 8301, 8302, -8131]],
        },
        {
          type: "Polygon",
          id: 28129,
          arcs: [[-8303, 8303, 8304, 8305, -8119, -8102]],
        },
        {
          type: "Polygon",
          id: 48035,
          arcs: [[-8148, -8270, 8306, 8307, 8308, -8185, -8242]],
        },
        {
          type: "Polygon",
          id: 13271,
          arcs: [[8309, 8310, 8311, 8312, -8205, -8253]],
        },
        {
          type: "Polygon",
          id: 22127,
          arcs: [[-8203, -8264, 8313, 8314, 8315, -8123]],
        },
        {
          type: "Polygon",
          id: 22069,
          arcs: [[-8316, 8316, 8317, 8318, 8319, -8236, -8283, -8124]],
        },
        {
          type: "Polygon",
          id: 1005,
          arcs: [[-8293, 8320, 8321, 8322, 8323, 8324, 8325, -8254, -8196]],
        },
        {
          type: "Polygon",
          id: 48073,
          arcs: [[-8217, 8326, 8327, 8328, 8329, -8225, -8091]],
        },
        {
          type: "Polygon",
          id: 13315,
          arcs: [[-8206, -8313, 8330, 8331, 8332, -8256, -8221]],
        },
        {
          type: "MultiPolygon",
          id: 13179,
          arcs: [[[8333]], [[8334, 8335, -8244, -8261, -8282, 8336]]],
        },
        {
          type: "Polygon",
          id: 48431,
          arcs: [[-8164, 8337, 8338, 8339, 8340, -8159]],
        },
        {
          type: "Polygon",
          id: 48173,
          arcs: [[-8341, 8341, 8342, -8168, -8160]],
        },
        {
          type: "Polygon",
          id: 48329,
          arcs: [[-8343, 8343, 8344, -8171, -8169]],
        },
        {
          type: "Polygon",
          id: 48135,
          arcs: [[-8345, 8345, 8346, 8347, 8348, -8172]],
        },
        {
          type: "Polygon",
          id: 48495,
          arcs: [[-8349, 8349, 8350, -7657, -8173]],
        },
        {
          type: "Polygon",
          id: 48081,
          arcs: [[-8167, 8351, 8352, -8338, -8163]],
        },
        {
          type: "Polygon",
          id: 48001,
          arcs: [[8353, 8354, 8355, -8226, -8330]],
        },
        {
          type: "Polygon",
          id: 48083,
          arcs: [[-8189, 8356, 8357, 8358, 8359, -8178]],
        },
        {
          type: "Polygon",
          id: 48399,
          arcs: [[-8360, 8360, 8361, -8352, -8166, -8179]],
        },
        {
          type: "Polygon",
          id: 48049,
          arcs: [[-8273, 8362, 8363, 8364, -8357, -8188, -8182]],
        },
        {
          type: "Polygon",
          id: 1109,
          arcs: [[-8326, -8325, 8365, 8366, 8367, -8199, -8255]],
        },
        {
          type: "Polygon",
          id: 1041,
          arcs: [[-8368, 8368, 8369, 8370, -8211, -8200]],
        },
        {
          type: "Polygon",
          id: 28029,
          arcs: [[8371, 8372, 8373, 8374, -8299, -8132]],
        },
        {
          type: "Polygon",
          id: 28127,
          arcs: [[-8306, 8375, 8376, 8377, -8372, -8120]],
        },
        {
          type: "Polygon",
          id: 13081,
          arcs: [[-8333, 8378, 8379, 8380, -8294, -8257]],
        },
        {
          type: "Polygon",
          id: 48193,
          arcs: [[8381, -8271, -8186, -8309, 8382, 8383]],
        },
        { type: "Polygon", id: 13183, arcs: [[8384, 8385, -8245, -8336]] },
        {
          type: "Polygon",
          id: 48161,
          arcs: [[8386, 8387, -8239, -8227, -8356]],
        },
        {
          type: "Polygon",
          id: 48109,
          arcs: [[8388, 8389, 8390, -7784, -7970]],
        },
        {
          type: "Polygon",
          id: 48229,
          arcs: [[8391, 8392, 8393, -7785, -8391]],
        },
        { type: "Polygon", id: 48141, arcs: [[8394, -7889, -7786, -8394]] },
        {
          type: "Polygon",
          id: 48301,
          arcs: [[8395, 8396, -7968, -7658, -8351]],
        },
        {
          type: "Polygon",
          id: 48389,
          arcs: [[8397, 8398, 8399, -8389, -7969, -8397]],
        },
        { type: "Polygon", id: 13239, arcs: [[8400, 8401, -8321, -8292]] },
        {
          type: "Polygon",
          id: 1025,
          arcs: [[-8267, 8402, 8403, 8404, -8248, -8154]],
        },
        {
          type: "Polygon",
          id: 48419,
          arcs: [[8405, 8406, 8407, 8408, -8215, -8224, -8238]],
        },
        {
          type: "Polygon",
          id: 22025,
          arcs: [[-8279, 8409, 8410, 8411, -8262, -8219]],
        },
        {
          type: "Polygon",
          id: 13161,
          arcs: [[8412, 8413, 8414, -8310, -8252, -8229, -8234]],
        },
        {
          type: "Polygon",
          id: 13273,
          arcs: [[8415, 8416, 8417, 8418, -8288, -8296]],
        },
        {
          type: "Polygon",
          id: 13001,
          arcs: [[-8247, 8419, 8420, 8421, -8413, -8233]],
        },
        {
          type: "Polygon",
          id: 1013,
          arcs: [[-8371, 8422, 8423, 8424, -8265, -8212]],
        },
        {
          type: "Polygon",
          id: 13243,
          arcs: [[-8289, -8419, 8425, 8426, -8401, -8291]],
        },
        {
          type: "Polygon",
          id: 22059,
          arcs: [[-8412, 8427, 8428, 8429, -8314, -8263]],
        },
        {
          type: "Polygon",
          id: 13177,
          arcs: [[-8381, 8430, 8431, -8416, -8295]],
        },
        {
          type: "Polygon",
          id: 28153,
          arcs: [[8432, 8433, 8434, -8301, -8297, -8250, 8435]],
        },
        {
          type: "Polygon",
          id: 28063,
          arcs: [[-8375, 8436, 8437, 8438, -8276, -8300]],
        },
        {
          type: "Polygon",
          id: 48309,
          arcs: [[8439, -8307, -8269, 8440, 8441, 8442]],
        },
        {
          type: "Polygon",
          id: 13287,
          arcs: [[8443, 8444, 8445, 8446, -8379, -8332]],
        },
        {
          type: "Polygon",
          id: 13017,
          arcs: [[-8312, 8447, 8448, -8444, -8331]],
        },
        {
          type: "Polygon",
          id: 13321,
          arcs: [[-8380, -8447, 8449, 8450, 8451, 8452, -8431]],
        },
        {
          type: "Polygon",
          id: 48347,
          arcs: [[-8409, 8453, 8454, -8327, -8216]],
        },
        {
          type: "Polygon",
          id: 22085,
          arcs: [[-8320, 8455, 8456, 8457, -8406, -8237]],
        },
        {
          type: "Polygon",
          id: 13305,
          arcs: [[-8246, -8386, 8458, 8459, 8460, 8461, -8420]],
        },
        {
          type: "Polygon",
          id: 1099,
          arcs: [[-8425, 8462, 8463, 8464, -8403, -8266]],
        },
        {
          type: "Polygon",
          id: 28067,
          arcs: [[8465, 8466, 8467, -8304, -8302, -8435]],
        },
        {
          type: "Polygon",
          id: 48293,
          arcs: [[8468, -8441, -8268, -8240, -8388, 8469, 8470]],
        },
        {
          type: "Polygon",
          id: 13069,
          arcs: [[8471, 8472, 8473, 8474, 8475, -8448, -8311, -8415]],
        },
        { type: "Polygon", id: 22043, arcs: [[-8430, 8476, -8317, -8315]] },
        {
          type: "Polygon",
          id: 28031,
          arcs: [[-8468, 8477, 8478, 8479, -8376, -8305]],
        },
        {
          type: "Polygon",
          id: 13061,
          arcs: [[-8427, 8480, 8481, 8482, -8322, -8402]],
        },
        { type: "Polygon", id: 1067, arcs: [[-8483, 8483, 8484, 8485, -8323]] },
        {
          type: "Polygon",
          id: 28065,
          arcs: [[8486, 8487, 8488, -8377, -8480]],
        },
        {
          type: "Polygon",
          id: 13155,
          arcs: [[-8476, 8489, 8490, -8445, -8449]],
        },
        {
          type: "Polygon",
          id: 22029,
          arcs: [[-8278, 8491, 8492, 8493, 8494, 8495, 8496, -8410]],
        },
        {
          type: "Polygon",
          id: 28077,
          arcs: [[8497, 8498, 8499, -8373, -8378, -8489]],
        },
        { type: "Polygon", id: 1035, arcs: [[8500, 8501, -8463, -8424]] },
        {
          type: "Polygon",
          id: 28001,
          arcs: [[8502, 8503, -8492, -8277, -8439]],
        },
        { type: "Polygon", id: 4023, arcs: [[-8210, 8504, -8191]] },
        {
          type: "Polygon",
          id: 48333,
          arcs: [[8505, 8506, -8363, -8272, -8382]],
        },
        {
          type: "Polygon",
          id: 28085,
          arcs: [[8507, 8508, 8509, 8510, -8437, -8374, -8500]],
        },
        {
          type: "Polygon",
          id: 13005,
          arcs: [[8511, 8512, -8472, -8414, -8422]],
        },
        {
          type: "Polygon",
          id: 48099,
          arcs: [[8513, -8383, -8308, -8440, 8514]],
        },
        {
          type: "Polygon",
          id: 48451,
          arcs: [[-8362, 8515, 8516, 8517, 8518, -8339, -8353]],
        },
        {
          type: "Polygon",
          id: 1129,
          arcs: [[-8405, 8519, 8520, 8521, -8436, -8249]],
        },
        {
          type: "MultiPolygon",
          id: 13191,
          arcs: [[[8522]], [[8523, 8524, -8459, -8385, -8335]]],
        },
        {
          type: "Polygon",
          id: 48289,
          arcs: [[8525, 8526, 8527, -8470, -8387, -8355]],
        },
        {
          type: "Polygon",
          id: 48461,
          arcs: [[8528, 8529, 8530, -8346, -8344]],
        },
        {
          type: "Polygon",
          id: 48103,
          arcs: [[-8531, 8531, 8532, 8533, -8347]],
        },
        {
          type: "Polygon",
          id: 48405,
          arcs: [[8534, 8535, 8536, -8454, -8408]],
        },
        {
          type: "Polygon",
          id: 48383,
          arcs: [[-8340, -8519, 8537, 8538, -8529, -8342]],
        },
        {
          type: "Polygon",
          id: 48475,
          arcs: [[-8396, -8350, -8348, -8534, 8539, -8398]],
        },
        {
          type: "Polygon",
          id: 13095,
          arcs: [[-8453, 8540, 8541, 8542, -8417, -8432]],
        },
        {
          type: "Polygon",
          id: 13037,
          arcs: [[-8418, -8543, 8543, 8544, -8481, -8426]],
        },
        {
          type: "Polygon",
          id: 1045,
          arcs: [[-8486, 8545, 8546, 8547, -8366, -8324]],
        },
        {
          type: "Polygon",
          id: 1031,
          arcs: [[8548, 8549, -8369, -8367, -8548]],
        },
        {
          type: "Polygon",
          id: 28037,
          arcs: [[-8511, 8550, 8551, -8503, -8438]],
        },
        {
          type: "Polygon",
          id: 48403,
          arcs: [[-8458, 8552, 8553, -8535, -8407]],
        },
        {
          type: "Polygon",
          id: 13277,
          arcs: [[-8491, 8554, 8555, 8556, -8450, -8446]],
        },
        {
          type: "Polygon",
          id: 48225,
          arcs: [[8557, 8558, 8559, 8560, -8526, -8354, -8329]],
        },
        {
          type: "Polygon",
          id: 48095,
          arcs: [[-8359, 8561, 8562, -8516, -8361]],
        },
        {
          type: "Polygon",
          id: 13229,
          arcs: [[-8462, 8563, 8564, -8512, -8421]],
        },
        {
          type: "Polygon",
          id: 1039,
          arcs: [[-8550, 8565, 8566, 8567, 8568, -8501, -8423, -8370]],
        },
        { type: "Polygon", id: 48235, arcs: [[8569, 8570, -8538, -8518]] },
        {
          type: "Polygon",
          id: 48005,
          arcs: [[-8537, 8571, 8572, 8573, 8574, -8558, -8328, -8455]],
        },
        {
          type: "Polygon",
          id: 13099,
          arcs: [[-8545, 8575, 8576, 8577, 8578, -8484, -8482]],
        },
        {
          type: "Polygon",
          id: 48145,
          arcs: [[8579, 8580, -8442, -8469, 8581]],
        },
        {
          type: "Polygon",
          id: 22079,
          arcs: [[-8429, 8582, 8583, 8584, 8585, -8318, -8477]],
        },
        {
          type: "Polygon",
          id: 48307,
          arcs: [[-8365, 8586, 8587, 8588, -8562, -8358]],
        },
        {
          type: "Polygon",
          id: 48411,
          arcs: [[-8507, 8589, 8590, 8591, 8592, -8587, -8364]],
        },
        {
          type: "Polygon",
          id: 13019,
          arcs: [[-8475, 8593, 8594, 8595, 8596, -8555, -8490]],
        },
        {
          type: "Polygon",
          id: 13299,
          arcs: [[-8565, 8597, 8598, 8599, 8600, 8601, 8602, -8473, -8513]],
        },
        {
          type: "Polygon",
          id: 48281,
          arcs: [[8603, 8604, -8590, -8506, -8384, -8514]],
        },
        {
          type: "MultiPolygon",
          id: 13127,
          arcs: [
            [[8605, 8606]],
            [[8607, 8608, 8609, 8610, 8611, -8460, -8525]],
          ],
        },
        {
          type: "Polygon",
          id: 13007,
          arcs: [[-8542, 8612, 8613, 8614, -8576, -8544]],
        },
        {
          type: "Polygon",
          id: 13205,
          arcs: [[-8452, 8615, 8616, 8617, 8618, -8613, -8541]],
        },
        {
          type: "Polygon",
          id: 28041,
          arcs: [[-8522, 8619, 8620, 8621, -8433]],
        },
        {
          type: "Polygon",
          id: 28035,
          arcs: [[8622, 8623, 8624, 8625, -8478, -8467]],
        },
        {
          type: "Polygon",
          id: 28073,
          arcs: [[-8479, -8626, 8626, 8627, -8487]],
        },
        {
          type: "Polygon",
          id: 28111,
          arcs: [[-8434, -8622, 8628, 8629, -8623, -8466]],
        },
        {
          type: "Polygon",
          id: 28091,
          arcs: [[-8628, 8630, 8631, 8632, -8498, -8488]],
        },
        {
          type: "Polygon",
          id: 13003,
          arcs: [[-8603, -8602, 8633, 8634, -8594, -8474]],
        },
        {
          type: "Polygon",
          id: 48455,
          arcs: [[8635, 8636, 8637, -8559, -8575]],
        },
        {
          type: "Polygon",
          id: 48371,
          arcs: [[-8533, 8638, 8639, 8640, 8641, -8399, -8540]],
        },
        {
          type: "Polygon",
          id: 13025,
          arcs: [[-8612, 8642, 8643, -8598, -8564, -8461]],
        },
        {
          type: "Polygon",
          id: 28157,
          arcs: [[-8552, 8644, 8645, 8646, -8493, -8504]],
        },
        {
          type: "Polygon",
          id: 22115,
          arcs: [[-8586, 8647, 8648, 8649, -8456, -8319]],
        },
        {
          type: "Polygon",
          id: 48395,
          arcs: [[8650, 8651, 8652, -8582, -8471, -8528]],
        },
        {
          type: "Polygon",
          id: 13075,
          arcs: [[8653, 8654, 8655, 8656, -8556, -8597]],
        },
        {
          type: "Polygon",
          id: 28005,
          arcs: [[-8510, 8657, 8658, 8659, 8660, -8645, -8551]],
        },
        {
          type: "Polygon",
          id: 28113,
          arcs: [[8661, 8662, 8663, -8658, -8509]],
        },
        {
          type: "Polygon",
          id: 28147,
          arcs: [[-8633, 8664, -8662, -8508, -8499]],
        },
        {
          type: "Polygon",
          id: 22009,
          arcs: [[-8411, -8497, 8665, 8666, 8667, 8668, -8583, -8428]],
        },
        {
          type: "Polygon",
          id: 13071,
          arcs: [[-8557, -8657, 8669, 8670, -8616, -8451]],
        },
        {
          type: "Polygon",
          id: 48027,
          arcs: [[-8581, 8671, 8672, 8673, -8604, -8515, -8443]],
        },
        {
          type: "Polygon",
          id: 1003,
          arcs: [[-8465, 8674, 8675, 8676, 8677, 8678, 8679, -8520, -8404]],
        },
        {
          type: "Polygon",
          id: 1069,
          arcs: [[-8579, 8680, 8681, 8682, -8546, -8485]],
        },
        {
          type: "Polygon",
          id: 1053,
          arcs: [[-8569, 8683, 8684, 8685, -8675, -8464, -8502]],
        },
        { type: "Polygon", id: 13201, arcs: [[-8615, 8686, 8687, -8577]] },
        {
          type: "Polygon",
          id: 1061,
          arcs: [[8688, 8689, 8690, -8566, -8549, -8547, -8683]],
        },
        {
          type: "Polygon",
          id: 48351,
          arcs: [[8691, 8692, 8693, 8694, -8553, -8457, -8650]],
        },
        {
          type: "Polygon",
          id: 13065,
          arcs: [[-8601, 8695, 8696, 8697, 8698, -8634]],
        },
        {
          type: "Polygon",
          id: 13173,
          arcs: [[-8699, 8699, 8700, -8595, -8635]],
        },
        {
          type: "Polygon",
          id: 1097,
          arcs: [[8701, 8702, 8703, -8620, -8521, -8680]],
        },
        {
          type: "MultiPolygon",
          id: 13039,
          arcs: [[[8704]], [[8705, 8706, 8707, -8643, -8611]]],
        },
        {
          type: "Polygon",
          id: 48241,
          arcs: [[8708, 8709, 8710, -8572, -8536, -8554, -8695]],
        },
        {
          type: "Polygon",
          id: 48373,
          arcs: [[8711, 8712, 8713, 8714, -8636, -8574]],
        },
        {
          type: "Polygon",
          id: 48331,
          arcs: [[8715, 8716, 8717, -8672, -8580, -8653]],
        },
        {
          type: "Polygon",
          id: 48243,
          arcs: [[8718, -8392, -8390, -8400, -8642, 8719]],
        },
        {
          type: "Polygon",
          id: 48313,
          arcs: [[8720, 8721, 8722, -8527, -8561]],
        },
        {
          type: "Polygon",
          id: 48327,
          arcs: [[-8589, 8723, 8724, 8725, -8563]],
        },
        {
          type: "Polygon",
          id: 48413,
          arcs: [[-8726, 8726, 8727, -8570, -8517]],
        },
        {
          type: "Polygon",
          id: 48105,
          arcs: [[-8530, -8539, -8571, -8728, 8728, 8729, 8730, -8639, -8532]],
        },
        {
          type: "Polygon",
          id: 13087,
          arcs: [[-8614, -8619, 8731, 8732, 8733, -8687]],
        },
        {
          type: "Polygon",
          id: 13131,
          arcs: [[8734, 8735, 8736, -8732, -8618]],
        },
        {
          type: "Polygon",
          id: 13027,
          arcs: [[-8655, 8737, 8738, 8739, 8740, -8670, -8656]],
        },
        {
          type: "Polygon",
          id: 13275,
          arcs: [[-8671, -8741, 8741, 8742, -8735, -8617]],
        },
        {
          type: "Polygon",
          id: 13253,
          arcs: [[-8734, 8743, 8744, -8681, -8578, -8688]],
        },
        {
          type: "Polygon",
          id: 13049,
          arcs: [[8745, 8746, -8599, -8644, -8708]],
        },
        { type: "Polygon", id: 48457, arcs: [[-8711, 8747, -8712, -8573]] },
        {
          type: "Polygon",
          id: 48471,
          arcs: [[8748, 8749, 8750, -8721, -8560, -8638]],
        },
        {
          type: "MultiPolygon",
          id: 22125,
          arcs: [[[8751, 8752, -8494, -8647, 8753]]],
        },
        {
          type: "Polygon",
          id: 48053,
          arcs: [[-8674, 8754, 8755, 8756, 8757, -8591, -8605]],
        },
        {
          type: "Polygon",
          id: 13185,
          arcs: [[-8596, -8701, 8758, 8759, 8760, 8761, -8738, -8654]],
        },
        {
          type: "Polygon",
          id: 22077,
          arcs: [[-8495, -8753, 8762, 8763, 8764, 8765, -8667, -8767]],
        },
        {
          type: "Polygon",
          id: 28109,
          arcs: [[-8625, 8767, 8768, 8769, 8770, -8631, -8627]],
        },
        {
          type: "Polygon",
          id: 22117,
          arcs: [[-8771, 8771, 8772, -8663, -8665, -8632]],
        },
        {
          type: "Polygon",
          id: 22039,
          arcs: [[-8669, 8773, 8774, 8775, 8776, -8584]],
        },
        {
          type: "Polygon",
          id: 12063,
          arcs: [[-8745, 8777, 8778, 8779, 8780, 8781, -8689, -8682]],
        },
        {
          type: "Polygon",
          id: 22105,
          arcs: [[-8773, 8782, 8783, 8784, 8785, 8786, -8659, -8664]],
        },
        { type: "Polygon", id: 12033, arcs: [[8787, -8676, -8686, 8788]] },
        { type: "Polygon", id: 12113, arcs: [[8789, 8790, -8789, -8685]] },
        {
          type: "Polygon",
          id: 28039,
          arcs: [[-8704, 8791, 8792, -8629, -8621]],
        },
        {
          type: "Polygon",
          id: 22091,
          arcs: [[-8787, 8793, 8794, 8795, -8660]],
        },
        {
          type: "MultiPolygon",
          id: 12091,
          arcs: [[[-8568, 8796, 8797, -8790, -8684]]],
        },
        {
          type: "Polygon",
          id: 22037,
          arcs: [[-8796, 8798, 8799, -8754, -8646, -8661]],
        },
        { type: "Polygon", id: 12059, arcs: [[-8782, 8800, 8801, -8690]] },
        {
          type: "Polygon",
          id: 12131,
          arcs: [[-8691, -8802, 8802, 8803, 8804, -8806, 8806, -8797, -8567]],
        },
        {
          type: "Polygon",
          id: 48041,
          arcs: [[8807, 8808, 8809, -8651, -8723]],
        },
        {
          type: "Polygon",
          id: 48319,
          arcs: [[-8593, 8810, 8811, 8812, -8724, -8588]],
        },
        {
          type: "Polygon",
          id: 48299,
          arcs: [[-8758, 8813, 8814, -8811, -8592]],
        },
        {
          type: "Polygon",
          id: 28131,
          arcs: [[-8793, 8815, 8816, -8768, -8624, -8630]],
        },
        {
          type: "Polygon",
          id: 48491,
          arcs: [[8817, 8818, 8819, -8755, -8673, -8718]],
        },
        {
          type: "Polygon",
          id: 48407,
          arcs: [[-8715, 8820, 8821, -8749, -8637]],
        },
        {
          type: "Polygon",
          id: 22003,
          arcs: [[-8777, 8822, 8823, -8648, -8585]],
        },
        {
          type: "Polygon",
          id: 22011,
          arcs: [[-8824, 8824, 8825, -8692, -8649]],
        },
        {
          type: "Polygon",
          id: 13101,
          arcs: [[8826, 8827, -8759, -8700, -8698]],
        },
        {
          type: "Polygon",
          id: 48185,
          arcs: [[-8751, 8828, 8829, 8830, -8808, -8722]],
        },
        {
          type: "Polygon",
          id: 22097,
          arcs: [[-8766, 8831, 8832, 8833, -8774, -8668]],
        },
        { type: "Polygon", id: 12133, arcs: [[8834, -8803, -8801, -8781]] },
        {
          type: "Polygon",
          id: 12089,
          arcs: [[-8707, 8835, 8836, 8837, -8746]],
        },
        {
          type: "Polygon",
          id: 28059,
          arcs: [[-8703, 8838, 8839, -8816, -8792]],
        },
        {
          type: "Polygon",
          id: 48051,
          arcs: [[-8810, 8840, 8841, -8716, -8652]],
        },
        {
          type: "Polygon",
          id: 22033,
          arcs: [[8842, 8843, 8844, 8845, -8799, -8795]],
        },
        {
          type: "Polygon",
          id: 22103,
          arcs: [[-8770, 8846, 8847, 8848, 8849, -8783, -8772]],
        },
        {
          type: "Polygon",
          id: 12039,
          arcs: [[-8737, 8850, 8851, -8778, -8744, -8733]],
        },
        {
          type: "Polygon",
          id: 48267,
          arcs: [[8852, 8853, 8854, 8855, -8725, -8813]],
        },
        {
          type: "Polygon",
          id: 48435,
          arcs: [[-8856, 8856, 8857, -8729, -8727]],
        },
        {
          type: "Polygon",
          id: 12073,
          arcs: [[-8743, 8858, 8859, 8860, -8851, -8736]],
        },
        { type: "Polygon", id: 28047, arcs: [[-8840, 8861, 8862, -8817]] },
        {
          type: "Polygon",
          id: 12065,
          arcs: [[-8740, 8863, 8864, 8865, 8866, -8859, -8742]],
        },
        {
          type: "Polygon",
          id: 48043,
          arcs: [[8867, -8720, -8641, 8868, 8869]],
        },
        {
          type: "Polygon",
          id: 22121,
          arcs: [[8870, -8763, -8752, -8800, -8846]],
        },
        {
          type: "Polygon",
          id: 48443,
          arcs: [[8871, 8872, -8869, -8640, -8731]],
        },
        {
          type: "Polygon",
          id: 22063,
          arcs: [[-8786, 8873, 8874, -8843, -8794]],
        },
        {
          type: "Polygon",
          id: 12079,
          arcs: [[-8762, 8875, 8876, 8877, 8878, 8879, -8864, -8739]],
        },
        { type: "Polygon", id: 28045, arcs: [[-8863, 8880, -8847, -8769]] },
        {
          type: "Polygon",
          id: 12047,
          arcs: [[-8828, 8881, 8882, -8877, -8760]],
        },
        { type: "Polygon", id: 48377, arcs: [[-8868, 8883, -8719]] },
        {
          type: "Polygon",
          id: 48339,
          arcs: [[-8822, 8884, 8885, 8886, -8829, -8750]],
        },
        {
          type: "Polygon",
          id: 48453,
          arcs: [[8887, 8888, 8889, 8890, -8756, -8820]],
        },
        { type: "Polygon", id: 12013, arcs: [[8891, 8892, 8893, -8779]] },
        {
          type: "Polygon",
          id: 12077,
          arcs: [[-8861, 8894, 8895, 8896, -8892, -8852]],
        },
        {
          type: "Polygon",
          id: 12023,
          arcs: [[-8697, 8897, 8898, 8899, 8900, 8901, -8882, -8827]],
        },
        {
          type: "MultiPolygon",
          id: 12031,
          arcs: [[[8902, 8903]], [[8904, 8905]], [[8906, 8907, 8908, -8837]]],
        },
        {
          type: "Polygon",
          id: 12003,
          arcs: [[-8838, -8909, 8909, 8910, 8911, -8898, -8696, -8600, -8747]],
        },
        {
          type: "MultiPolygon",
          id: 12005,
          arcs: [[[8912, 8913]], [[-8894, 8914, 8915, -8804, -8835, -8780]]],
        },
        {
          type: "Polygon",
          id: 48287,
          arcs: [[8916, 8917, 8918, -8818, -8717, -8842]],
        },
        {
          type: "Polygon",
          id: 48199,
          arcs: [[-8710, 8919, 8920, 8921, -8713, -8748]],
        },
        {
          type: "Polygon",
          id: 48171,
          arcs: [[-8815, 8922, 8923, 8924, -8853, -8812]],
        },
        {
          type: "Polygon",
          id: 48031,
          arcs: [[-8757, -8891, 8925, 8926, 8927, -8923, -8814]],
        },
        {
          type: "MultiPolygon",
          id: 22099,
          arcs: [[[8928, 8929, 8930]], [[8931, 8932, -8832, -8765, 8933]]],
        },
        {
          type: "Polygon",
          id: 22047,
          arcs: [[-8871, -8845, 8934, 8935, 8936, -8934, -8764]],
        },
        {
          type: "Polygon",
          id: 48291,
          arcs: [[-8922, 8937, 8938, 8939, -8885, -8821, -8714]],
        },
        {
          type: "Polygon",
          id: 22019,
          arcs: [[8940, 8941, 8942, -8693, -8826]],
        },
        {
          type: "Polygon",
          id: 22053,
          arcs: [[-8776, 8943, 8944, 8945, 8946, -8941, -8825, -8823]],
        },
        {
          type: "Polygon",
          id: 22001,
          arcs: [[-8834, 8947, 8948, 8949, -8945, -8944, -8775]],
        },
        {
          type: "Polygon",
          id: 12121,
          arcs: [[-8902, 8950, 8951, -8878, -8883]],
        },
        {
          type: "Polygon",
          id: 48021,
          arcs: [[8952, 8953, -8888, -8819, -8919]],
        },
        {
          type: "Polygon",
          id: 48477,
          arcs: [[-8831, 8954, 8955, 8956, -8917, -8841, -8809]],
        },
        {
          type: "Polygon",
          id: 22055,
          arcs: [[-8933, 8957, 8958, -8948, -8833]],
        },
        {
          type: "Polygon",
          id: 48209,
          arcs: [[8959, 8960, -8926, -8890, 8961]],
        },
        {
          type: "Polygon",
          id: 22005,
          arcs: [[-8875, 8962, 8963, 8964, 8965, -8935, -8844]],
        },
        {
          type: "Polygon",
          id: 12123,
          arcs: [[8966, 8967, 8968, -8865, -8880]],
        },
        {
          type: "Polygon",
          id: 12129,
          arcs: [[-8867, 8969, 8970, -8895, -8860]],
        },
        {
          type: "Polygon",
          id: 22095,
          arcs: [[8971, 8972, 8973, 8974, -8963, -8874, -8785]],
        },
        {
          type: "Polygon",
          id: 48465,
          arcs: [[-8858, 8975, 8976, 8977, -8872, -8730]],
        },
        {
          type: "Polygon",
          id: 48137,
          arcs: [[8978, 8979, 8980, 8981, -8976, -8857, -8855]],
        },
        {
          type: "Polygon",
          id: 48265,
          arcs: [[8982, 8983, 8984, -8979, -8854, -8925]],
        },
        {
          type: "Polygon",
          id: 12067,
          arcs: [[-8952, 8985, 8986, -8967, -8879]],
        },
        {
          type: "MultiPolygon",
          id: 12109,
          arcs: [
            [[8987, 8988, 8989, 8990, 8991, -8903, 8992]],
            [[-8905, 8993]],
          ],
        },
        {
          type: "Polygon",
          id: 48361,
          arcs: [[-8943, 8994, 8995, -8920, -8709, -8694]],
        },
        {
          type: "Polygon",
          id: 48473,
          arcs: [[8996, 8997, 8998, -8955, -8830, -8887]],
        },
        { type: "Polygon", id: 22089, arcs: [[8999, -8972, 9000]] },
        {
          type: "Polygon",
          id: 22051,
          arcs: [[9001, 9002, 9003, 9004, -9001, -8784, -8850]],
        },
        {
          type: "Polygon",
          id: 12045,
          arcs: [[-8897, 9005, 9006, 9007, 9008, -8913, 9009, -8915, -8893]],
        },
        {
          type: "Polygon",
          id: 22071,
          arcs: [[9010, 9011, 9012, -9002, -8849]],
        },
        {
          type: "Polygon",
          id: 12019,
          arcs: [[9013, 9014, 9015, -8910, -8908]],
        },
        {
          type: "Polygon",
          id: 48245,
          arcs: [[-8996, 9016, 9017, 9018, -8938, -8921]],
        },
        {
          type: "Polygon",
          id: 48201,
          arcs: [[-8940, 9019, 9020, 9021, 9022, 9023, -8997, -8886]],
        },
        {
          type: "MultiPolygon",
          id: 22087,
          arcs: [[[9024, 9025, 9026, -9012, 9027]], [[9028]]],
        },
        {
          type: "Polygon",
          id: 22093,
          arcs: [[9029, 9030, -8965, -8964, -8975]],
        },
        {
          type: "Polygon",
          id: 48149,
          arcs: [[9031, 9032, 9033, 9034, 9035, -8953, -8918, -8957]],
        },
        {
          type: "Polygon",
          id: 22113,
          arcs: [[-8959, 9036, 9037, 9038, -8946, -8950, -8949]],
        },
        {
          type: "Polygon",
          id: 12007,
          arcs: [[-9016, 9039, 9040, 9041, -8911]],
        },
        { type: "Polygon", id: 12125, arcs: [[-9042, 9042, -8899, -8912]] },
        {
          type: "Polygon",
          id: 48259,
          arcs: [[-8928, 9043, 9044, 9045, -8983, -8924]],
        },
        {
          type: "MultiPolygon",
          id: 22045,
          arcs: [
            [[9046]],
            [[-8937, 9047, -8930, 9048, 9049, -9037, -8958, -8932]],
          ],
        },
        {
          type: "Polygon",
          id: 48015,
          arcs: [[-8999, 9050, 9051, 9052, -9032, -8956]],
        },
        {
          type: "Polygon",
          id: 22007,
          arcs: [
            [-8965, 8964, -9031, 9053, 9054, 9055, -8931, -9048, -8936, -8966],
          ],
        },
        { type: "Polygon", id: 48385, arcs: [[-8985, 9056, 9057, -8980]] },
        {
          type: "Polygon",
          id: 48055,
          arcs: [[-8954, -9036, 9058, 9059, -8962, -8889]],
        },
        {
          type: "Polygon",
          id: 22023,
          arcs: [[-8947, -9039, 9060, -9017, -8995, -8942]],
        },
        {
          type: "Polygon",
          id: 48091,
          arcs: [[9061, 9062, -9044, -8927, -8961]],
        },
        {
          type: "MultiPolygon",
          id: 12037,
          arcs: [[[9063]], [[-9008, 9064]], [[-8971, 9065, -9006, -8896]]],
        },
        {
          type: "Polygon",
          id: 48089,
          arcs: [[9066, 9067, 9068, -9033, -9053]],
        },
        {
          type: "Polygon",
          id: 22101,
          arcs: [[-9056, 9069, 9070, -9049, -8929]],
        },
        {
          type: "Polygon",
          id: 12001,
          arcs: [[-9041, 9071, 9072, 9073, 9074, -8900, -9043]],
        },
        {
          type: "Polygon",
          id: 12041,
          arcs: [[-9075, 9075, 9076, -8986, -8951, -8901]],
        },
        {
          type: "Polygon",
          id: 22057,
          arcs: [
            [
              9077,
              9078,
              9079,
              9080,
              9081,
              9082,
              9083,
              -9054,
              -9030,
              -8974,
              -8973,
              -9000,
              -9005,
              9084,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 48019,
          arcs: [[9085, -9057, -8984, -9046, 9086, 9087]],
        },
        {
          type: "MultiPolygon",
          id: 22075,
          arcs: [[[9088]], [[-9003, -9013, -9027, 9089]]],
        },
        {
          type: "Polygon",
          id: 48071,
          arcs: [[-9019, 9090, 9091, 9092, -9020, -8939]],
        },
        {
          type: "Polygon",
          id: 48187,
          arcs: [[9093, 9094, 9095, -9062, -8960, -9060]],
        },
        {
          type: "MultiPolygon",
          id: 12107,
          arcs: [
            [[-8991, 9096, 9097, 9098]],
            [[9099, 9100, -9072, -9040, -9015]],
          ],
        },
        {
          type: "Polygon",
          id: 48157,
          arcs: [[9101, 9102, 9103, -9051, -8998, -9024]],
        },
        {
          type: "Polygon",
          id: 48177,
          arcs: [[9104, 9105, 9106, 9107, -9094, -9059, -9035]],
        },
        {
          type: "MultiPolygon",
          id: 22109,
          arcs: [[[9108]], [[-9070, -9055, -9084, 9109]]],
        },
        {
          type: "Polygon",
          id: 48029,
          arcs: [[-9096, 9110, 9111, 9112, -9087, -9045, -9063]],
        },
        {
          type: "Polygon",
          id: 48325,
          arcs: [[9113, 9114, 9115, -9088, -9113]],
        },
        { type: "Polygon", id: 12035, arcs: [[9116, 9117, -9097, -8990]] },
        {
          type: "Polygon",
          id: 48481,
          arcs: [[-9104, 9118, 9119, 9120, -9067, -9052]],
        },
        {
          type: "Polygon",
          id: 48285,
          arcs: [[9121, 9122, 9123, -9105, -9034, -9069]],
        },
        {
          type: "Polygon",
          id: 48463,
          arcs: [[-9116, 9124, 9125, -8981, -9058, -9086]],
        },
        {
          type: "Polygon",
          id: 48271,
          arcs: [[-9126, 9126, 9127, -8977, -8982]],
        },
        {
          type: "MultiPolygon",
          id: 48167,
          arcs: [[[9128]], [[9129, -9022, 9130]], [[9131, -9092]]],
        },
        {
          type: "Polygon",
          id: 48039,
          arcs: [[-9130, 9132, 9133, -9119, -9103, -9102, -9023]],
        },
        {
          type: "Polygon",
          id: 12075,
          arcs: [[-9074, 9134, 9135, 9136, 9137, 9138, -9076]],
        },
        {
          type: "Polygon",
          id: 12083,
          arcs: [[9139, 9140, 9141, 9142, -9135, -9073, -9101]],
        },
        {
          type: "Polygon",
          id: 48493,
          arcs: [[-9108, 9143, 9144, -9111, -9095]],
        },
        {
          type: "MultiPolygon",
          id: 12127,
          arcs: [
            [
              [
                9145,
                9146,
                9147,
                9148,
                9149,
                9150,
                9151,
                9152,
                -9098,
                -9118,
                9153,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 48123,
          arcs: [[9154, 9155, -9106, -9124, 9156]],
        },
        {
          type: "Polygon",
          id: 12069,
          arcs: [[-9152, -9151, 9157, 9158, 9159, 9160, -9141, 9161]],
        },
        {
          type: "Polygon",
          id: 48239,
          arcs: [
            [
              9162,
              9163,
              9164,
              9165,
              9166,
              9167,
              9168,
              9169,
              -9122,
              -9068,
              -9121,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 48013,
          arcs: [[9170, 9171, -9114, -9112, -9145, 9172, 9173]],
        },
        {
          type: "Polygon",
          id: 48321,
          arcs: [[9174, -9163, -9120, -9134, 9175]],
        },
        {
          type: "Polygon",
          id: 48255,
          arcs: [[9176, 9177, -9173, -9144, -9107, -9156, 9178]],
        },
        {
          type: "Polygon",
          id: 48469,
          arcs: [[-9170, 9179, 9180, 9181, 9182, -9157, -9123]],
        },
        {
          type: "Polygon",
          id: 48163,
          arcs: [[-9172, 9183, 9184, 9185, -9115]],
        },
        { type: "Polygon", id: 48507, arcs: [[-9186, 9186, 9187, -9125]] },
        {
          type: "Polygon",
          id: 48323,
          arcs: [[-9188, 9188, 9189, 9190, -9127]],
        },
        {
          type: "Polygon",
          id: 12017,
          arcs: [[-9136, -9143, 9191, 9192, 9193]],
        },
        {
          type: "Polygon",
          id: 12119,
          arcs: [[-9161, 9194, 9195, 9196, -9192, -9142]],
        },
        {
          type: "Polygon",
          id: 48175,
          arcs: [[-9183, 9197, 9198, -9179, -9155]],
        },
        { type: "Polygon", id: 12117, arcs: [[-9149, 9199, -9158, -9150]] },
        {
          type: "MultiPolygon",
          id: 12009,
          arcs: [
            [[9200, 9201, 9202, 9203, -9148]],
            [[9204, 9205, 9206, -9146]],
          ],
        },
        {
          type: "Polygon",
          id: 48297,
          arcs: [[9207, 9208, 9209, -9174, -9178, 9210, 9211]],
        },
        { type: "Polygon", id: 12095, arcs: [[-9200, -9204, 9212, -9159]] },
        {
          type: "MultiPolygon",
          id: 48057,
          arcs: [[[9213, 9214]], [[9215, -9181, 9216]], [[-9166, 9217]]],
        },
        {
          type: "Polygon",
          id: 48025,
          arcs: [[-9199, 9218, 9219, -9211, -9177]],
        },
        { type: "Polygon", id: 12053, arcs: [[-9197, 9220, 9221, -9193]] },
        { type: "Polygon", id: 48283, arcs: [[9222, 9223, 9224, -9184]] },
        { type: "Polygon", id: 48311, arcs: [[-9210, 9225, -9223, -9171]] },
        {
          type: "Polygon",
          id: 48127,
          arcs: [[-9185, -9225, 9226, -9189, -9187]],
        },
        {
          type: "Polygon",
          id: 48391,
          arcs: [[-9216, 9227, 9228, 9229, 9230, 9231, -9219, -9198, -9182]],
        },
        {
          type: "Polygon",
          id: 12101,
          arcs: [[-9196, 9232, 9233, 9234, 9235, -9221]],
        },
        {
          type: "Polygon",
          id: 12105,
          arcs: [[9236, 9237, 9238, 9239, -9233, -9195, -9160]],
        },
        {
          type: "Polygon",
          id: 12097,
          arcs: [[-9203, 9240, 9241, -9237, -9213]],
        },
        {
          type: "MultiPolygon",
          id: 48007,
          arcs: [
            [[9242]],
            [[9243, 9244, -9231]],
            [[9245, 9246]],
            [[-9229, 9247, 9248, 9249]],
          ],
        },
        {
          type: "Polygon",
          id: 48479,
          arcs: [[9250, 9251, 9252, 9253, -9190, -9227, -9224]],
        },
        {
          type: "Polygon",
          id: 48409,
          arcs: [
            [
              -9245,
              9254,
              -9246,
              9255,
              9256,
              9257,
              9258,
              9259,
              9260,
              -9212,
              -9220,
              -9232,
            ],
          ],
        },
        { type: "Polygon", id: 12103, arcs: [[9261, 9262, -9235]] },
        {
          type: "Polygon",
          id: 12057,
          arcs: [[-9240, 9263, 9264, -9262, -9234]],
        },
        {
          type: "Polygon",
          id: 48131,
          arcs: [[9265, 9266, 9267, -9251, -9226, -9209]],
        },
        {
          type: "Polygon",
          id: 48249,
          arcs: [[-9261, -9260, 9268, 9269, 9270, -9266, -9208]],
        },
        {
          type: "MultiPolygon",
          id: 48355,
          arcs: [[[9271]], [[9272, -9269, -9259, 9273]]],
        },
        {
          type: "MultiPolygon",
          id: 12061,
          arcs: [[[9274, 9275, -9241, -9202, 9276]]],
        },
        { type: "Polygon", id: 12055, arcs: [[9277, 9278, 9279, 9280, -9238]] },
        { type: "Polygon", id: 12049, arcs: [[-9281, 9281, 9282, -9239]] },
        {
          type: "Polygon",
          id: 12081,
          arcs: [[-9283, 9283, 9284, 9285, -9264]],
        },
        {
          type: "Polygon",
          id: 12093,
          arcs: [[-9276, 9286, 9287, 9288, -9278, -9242]],
        },
        {
          type: "MultiPolygon",
          id: 48273,
          arcs: [
            [[9289, 9290, 9291, 9292]],
            [[9293, 9294, 9295, -9270, -9273]],
          ],
        },
        {
          type: "Polygon",
          id: 12111,
          arcs: [[9296, 9297, 9298, 9299, -9287, -9275]],
        },
        {
          type: "Polygon",
          id: 12115,
          arcs: [[-9285, 9300, 9301, 9302, 9303, 9304]],
        },
        {
          type: "Polygon",
          id: 48247,
          arcs: [[9305, 9306, 9307, -9252, -9268]],
        },
        {
          type: "Polygon",
          id: 12027,
          arcs: [[-9280, 9308, -9301, -9284, -9282]],
        },
        { type: "Polygon", id: 48505, arcs: [[-9308, 9309, 9310, -9253]] },
        {
          type: "MultiPolygon",
          id: 48261,
          arcs: [
            [[9311, 9312]],
            [[9313, -9290, 9314]],
            [[9315, 9316, 9317, -9295, 9318]],
          ],
        },
        {
          type: "Polygon",
          id: 48047,
          arcs: [[-9271, -9296, -9318, 9319, 9320, -9306, -9267]],
        },
        {
          type: "MultiPolygon",
          id: 12085,
          arcs: [[[9321, 9322, 9323, 9324, 9325, -9288, -9300, 9326]]],
        },
        { type: "Polygon", id: 12043, arcs: [[9327, 9328, -9279, -9289]] },
        {
          type: "MultiPolygon",
          id: 12015,
          arcs: [[[-9304, 9329]], [[-9329, 9330, 9331, -9302, -9309]]],
        },
        { type: "Polygon", id: 12099, arcs: [[9332, 9333, 9334, -9326]] },
        {
          type: "Polygon",
          id: 12051,
          arcs: [[9335, 9336, 9337, -9328, -9335]],
        },
        {
          type: "MultiPolygon",
          id: 12071,
          arcs: [[[9338]], [[-9338, 9339, 9340, -9331]]],
        },
        {
          type: "Polygon",
          id: 48427,
          arcs: [[-9321, 9341, 9342, -9310, -9307]],
        },
        {
          type: "Polygon",
          id: 48215,
          arcs: [[-9317, 9343, 9344, 9345, -9342, -9320]],
        },
        { type: "Polygon", id: 48489, arcs: [[9346, -9344, -9316, 9347]] },
        {
          type: "MultiPolygon",
          id: 12021,
          arcs: [[[9348, 9349, 9350, 9351, -9340, -9337]]],
        },
        { type: "Polygon", id: 48061, arcs: [[-9345, -9347, 9352]] },
        {
          type: "Polygon",
          id: 12011,
          arcs: [[9353, 9354, -9349, -9336, -9334]],
        },
        {
          type: "Polygon",
          id: 12086,
          arcs: [[9355, 9356, 9357, 9358, 9359, -9350, -9355, 9360]],
        },
        {
          type: "MultiPolygon",
          id: 12087,
          arcs: [[[9361]], [[-9360, 9362, -9351]]],
        },
        {
          type: "Polygon",
          id: 4015,
          arcs: [[-5737, -6670, -7308, -6521, -5912, -4625, -5343, -5417]],
        },
        {
          type: "Polygon",
          id: 12029,
          arcs: [[-9077, -9139, 9363, -8968, -8987]],
        },
        { type: "Polygon", id: 27077, arcs: [[-125, -180, -108, 9364]] },
        { type: "Polygon", id: 27031, arcs: [[9365, -203, 9366]] },
        {
          type: "Polygon",
          id: 55031,
          arcs: [[9367, -675, -668, -593, -474, -148, 9368]],
        },
        { type: "Polygon", id: 55007, arcs: [[9369, -673, -9368, 9370]] },
        { type: "Polygon", id: 55003, arcs: [[-561, -721, -669, -9370, 9371]] },
        { type: "Polygon", id: 55003, arcs: [[9372]] },
        { type: "Polygon", id: 26083, arcs: [[9373, 9374, 9375]] },
        { type: "Polygon", id: 26083, arcs: [[9376]] },
        { type: "Polygon", id: 26061, arcs: [[9377, -446, -592, -436, 9378]] },
        { type: "Polygon", id: 26061, arcs: [[9379, -9375]] },
        {
          type: "Polygon",
          id: 26103,
          arcs: [[9380, 9381, -716, -642, -586, -444, 9382]],
        },
        { type: "Polygon", id: 26003, arcs: [[-577, 9383, -9381, 9384, -491]] },
        {
          type: "Polygon",
          id: 26041,
          arcs: [[-9384, -576, 9385, -712, -9382]],
        },
        {
          type: "Polygon",
          id: 55075,
          arcs: [[-715, 9386, -879, -687, -704, -640]],
        },
        { type: "Polygon", id: 55029, arcs: [[-1169, 9387]] },
        { type: "Polygon", id: 26033, arcs: [[9388, 9389]] },
        { type: "Polygon", id: 26033, arcs: [[9390]] },
        { type: "Polygon", id: 26033, arcs: [[9391, 9392, 9393, -488, 9394]] },
        { type: "Polygon", id: 26097, arcs: [[9395]] },
        { type: "Polygon", id: 26097, arcs: [[9396, 9397, -574, -489, -9394]] },
        { type: "Polygon", id: 26047, arcs: [[-771, 9398, 9399]] },
        { type: "Polygon", id: 26029, arcs: [[-9399, -770, -953, -950, 9400]] },
        { type: "Polygon", id: 26029, arcs: [[9401]] },
        { type: "Polygon", id: 26089, arcs: [[9402]] },
        { type: "Polygon", id: 26089, arcs: [[9403, -1114, 9404]] },
        {
          type: "Polygon",
          id: 26055,
          arcs: [[-948, -1082, -1230, -1111, -9404, 9405]],
        },
        { type: "Polygon", id: 26007, arcs: [[9406, -1079, -954, -819]] },
        {
          type: "Polygon",
          id: 26011,
          arcs: [[9407, -1439, -1400, -1240, -1238]],
        },
        { type: "Polygon", id: 26063, arcs: [[-1578, -1556, 9408]] },
        { type: "Polygon", id: 26147, arcs: [[-1931, -1726, -1576, 9409]] },
        {
          type: "Polygon",
          id: 26163,
          arcs: [[9410, 9411, -2133, -1932, -1929]],
        },
        {
          type: "Polygon",
          id: 26115,
          arcs: [[9412, -2505, -2280, -2130, -9412]],
        },
        {
          type: "MultiPolygon",
          id: 45019,
          arcs: [
            [[9413, 9414, 9415, -7862, -7809, -7695]],
            [[-7693, -7590, 9416]],
          ],
        },
        { type: "Polygon", id: 15001, arcs: [[9417]] },
        { type: "Polygon", id: 15007, arcs: [[9418]] },
        { type: "Polygon", id: 15009, arcs: [[-9420, 9420]] },
        { type: "Polygon", id: 15009, arcs: [[9421]] },
        { type: "Polygon", id: 15009, arcs: [[9422]] },
        { type: "Polygon", id: 15009, arcs: [[9423]] },
        { type: "Polygon", id: 15003, arcs: [[9424]] },
        { type: "Polygon", id: 15007, arcs: [[9425]] },
        {
          type: "MultiPolygon",
          id: 2016,
          arcs: [
            [[9426]],
            [[9427]],
            [[9428]],
            [[9429]],
            [[9430]],
            [[9431]],
            [[9432]],
            [[9433]],
            [[9434]],
            [[9435]],
            [[9436]],
            [[9437]],
            [[9438]],
            [[9439]],
            [[9440]],
            [[9441]],
            [[9442]],
            [[9443]],
            [[9444]],
            [[9445]],
            [[9446]],
            [[9447]],
            [[9448]],
            [[9449]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2013,
          arcs: [
            [[9450]],
            [[9451]],
            [[9452]],
            [[9453]],
            [[9454]],
            [[9455]],
            [[9456]],
            [[9457]],
            [[9458]],
            [[9459]],
            [[9460]],
            [[9461]],
            [[9462, 9463, 9464, 9465]],
          ],
        },
        { type: "MultiPolygon", id: 2130, arcs: [[[9466]], [[9467]]] },
        { type: "Polygon", id: 2060, arcs: [[9468, 9469]] },
        {
          type: "MultiPolygon",
          id: 2070,
          arcs: [[[9470]], [[9471, 9472]], [[9473, 9474, 9475]]],
        },
        {
          type: "MultiPolygon",
          id: 2164,
          arcs: [
            [[9476]],
            [[9477, 9478, 9479, -9465, 9480, -9470, 9481, -9475, 9482]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2150,
          arcs: [
            [[9483]],
            [[9484]],
            [[9485]],
            [[9486]],
            [[9487]],
            [[9488]],
            [[9489]],
            [[9490]],
            [[9491]],
            [[9492]],
            [[9493, -9479, 9494, 9495]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2110,
          arcs: [
            [[9496, 9497, 9498, 9499]],
            [[9500]],
            [[9501, 9502, 9503, 9504, 9505, 9506]],
            [[9507, 9508]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2280,
          arcs: [
            [[9509]],
            [[9510]],
            [[9511]],
            [[9512]],
            [[9513]],
            [[9514]],
            [[9515]],
            [[9516, 9517, 9518, 9519]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2232,
          arcs: [
            [[9520, 9521, 9522, 9523]],
            [[9524]],
            [[-9520, 9525, 9526, 9527, -9503, 9528]],
            [[-9497, 9529, 9530, 9531]],
            [[9532, 9533]],
            [[-9499, 9534]],
            [[9535, 9536, 9537, 9538]],
            [[9539, 9540, 9541, 9542]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2100,
          arcs: [
            [[-9507, 9543, -9508, 9544, -9540, 9545]],
            [[9546, -9538, 9547, -9542]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2220,
          arcs: [[[9548]], [[9549, 9550]], [[-9521, 9551, -9533, 9552]]],
        },
        {
          type: "MultiPolygon",
          id: 2270,
          arcs: [[[9553]], [[9554]], [[9555]], [[9556, 9557, 9558, 9559]]],
        },
        {
          type: "MultiPolygon",
          id: 2050,
          arcs: [
            [[9560]],
            [[9561]],
            [[9562]],
            [[9563]],
            [[-9559, 9564, 9565, 9566, -9483, -9474, 9567, -9473, 9568]],
          ],
        },
        {
          type: "Polygon",
          id: 2170,
          arcs: [[9569, 9570, 9571, 9572, -9566, 9573, 9574, 9575]],
        },
        { type: "Polygon", id: 2068, arcs: [[9576, 9577, -9575, 9578]] },
        { type: "Polygon", id: 2020, arcs: [[-9571, 9579, 9580, 9581]] },
        {
          type: "MultiPolygon",
          id: 2261,
          arcs: [
            [[9582]],
            [[9583]],
            [[9584]],
            [[9585]],
            [[9586]],
            [[9587]],
            [[9588]],
            [[9589]],
            [[9590, 9591]],
            [[9592]],
            [[9593]],
            [[9594]],
            [[9595, 9596]],
            [[9597]],
            [[9598, -9580, -9570, 9599, 9600, 9601, 9602]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 2122,
          arcs: [
            [[9603]],
            [[9604]],
            [[9605]],
            [[-9581, -9599, 9606, -9596, 9607, -9591, 9608]],
            [[-9495, -9478, -9567, -9573, 9609]],
          ],
        },
        { type: "Polygon", id: 2282, arcs: [[-9602, 9610, -9536, 9611]] },
        {
          type: "Polygon",
          id: 2290,
          arcs: [
            [9612, 9613, 9614, -9579, -9574, -9565, -9558, 9615, 9616, 9617],
          ],
        },
        { type: "Polygon", id: 2090, arcs: [[9618, -9577, -9615]] },
        {
          type: "Polygon",
          id: 2240,
          arcs: [[-9600, -9576, -9578, -9619, -9614, 9619]],
        },
        {
          type: "MultiPolygon",
          id: 2185,
          arcs: [[[9620]], [[-9618, 9621, 9622]]],
        },
        { type: "Polygon", id: 2188, arcs: [[-9617, 9623, 9624, -9622]] },
        {
          type: "MultiPolygon",
          id: 2180,
          arcs: [[[9625]], [[9626]], [[9627]], [[-9624, -9616, -9557, 9628]]],
        },
        { type: "Polygon", id: 2201, arcs: [[9629]] },
        { type: "Polygon", id: 2201, arcs: [[9630]] },
        { type: "Polygon", id: 2201, arcs: [[9631]] },
        { type: "Polygon", id: 2201, arcs: [[9632]] },
        { type: "Polygon", id: 2201, arcs: [[9633]] },
        { type: "Polygon", id: 2201, arcs: [[9634]] },
        { type: "Polygon", id: 2201, arcs: [[9635]] },
        { type: "Polygon", id: 2201, arcs: [[9636]] },
        { type: "Polygon", id: 2201, arcs: [[9637]] },
        { type: "Polygon", id: 2201, arcs: [[9638]] },
        { type: "Polygon", id: 2201, arcs: [[9639]] },
        { type: "Polygon", id: 2201, arcs: [[9640]] },
        { type: "Polygon", id: 2201, arcs: [[9641]] },
        { type: "Polygon", id: 2201, arcs: [[9642]] },
        { type: "Polygon", id: 2201, arcs: [[9643]] },
        { type: "Polygon", id: 2201, arcs: [[-9518, 9644]] },
        {
          type: "Polygon",
          id: 72125,
          arcs: [[9645, 9646, 9647, 9648, 9649, 9650]],
        },
        { type: "Polygon", id: 72003, arcs: [[9651, 9652, 9653, 9654, 9655]] },
        {
          type: "Polygon",
          id: 72097,
          arcs: [[9656, 9657, -9651, 9658, 9659, 9660, 9661]],
        },
        { type: "Polygon", id: 72065, arcs: [[9662, 9663, 9664, 9665, 9666]] },
        { type: "Polygon", id: 72055, arcs: [[9667, 9668, 9669, 9670]] },
        { type: "Polygon", id: 72083, arcs: [[9671, 9672, -9657, 9673, 9674]] },
        {
          type: "Polygon",
          id: 72025,
          arcs: [[9675, 9676, 9677, 9678, 9679, 9680, 9681]],
        },
        { type: "Polygon", id: 72045, arcs: [[9682, 9683, 9684, 9685, 9686]] },
        { type: "Polygon", id: 72133, arcs: [[9687, 9688, 9689, 9690]] },
        {
          type: "Polygon",
          id: 72121,
          arcs: [[-9671, 9691, -9647, 9692, 9693]],
        },
        { type: "Polygon", id: 72027, arcs: [[-9666, 9694, 9695, 9696, 9697]] },
        {
          type: "Polygon",
          id: 72001,
          arcs: [[9698, 9699, 9700, 9701, 9702, 9703]],
        },
        { type: "Polygon", id: 72111, arcs: [[9704, 9705, 9706, -9700]] },
        {
          type: "Polygon",
          id: 72047,
          arcs: [[9707, 9708, 9709, 9710, 9711, 9712]],
        },
        {
          type: "Polygon",
          id: 72091,
          arcs: [[9713, 9714, 9715, 9716, 9717, 9718]],
        },
        {
          type: "Polygon",
          id: 72013,
          arcs: [[9719, 9720, 9721, 9722, -9663, 9723]],
        },
        { type: "Polygon", id: 72145, arcs: [[9724, 9725, -9714, 9726]] },
        {
          type: "Polygon",
          id: 72031,
          arcs: [[9727, 9728, 9729, 9730, 9731, 9732]],
        },
        { type: "Polygon", id: 72061, arcs: [[9733, 9734, 9735, 9736, 9737]] },
        {
          type: "Polygon",
          id: 72129,
          arcs: [[9738, 9739, 9740, 9741, 9742, -9678, 9743]],
        },
        {
          type: "Polygon",
          id: 72075,
          arcs: [[9744, 9745, -9691, 9746, 9747, 9748, 9749]],
        },
        {
          type: "Polygon",
          id: 72063,
          arcs: [[-9729, 9750, -9744, -9677, 9751]],
        },
        { type: "Polygon", id: 72073, arcs: [[9752, -9749, 9753, 9754, 9755]] },
        {
          type: "Polygon",
          id: 72143,
          arcs: [[9756, 9757, -9712, 9758, -9725, 9759]],
        },
        {
          type: "Polygon",
          id: 72011,
          arcs: [[9760, -9674, -9662, 9761, 9762, -9653, 9763]],
        },
        {
          type: "Polygon",
          id: 72081,
          arcs: [[-9665, 9764, -9703, 9765, 9766, -9672, 9767, -9695]],
        },
        {
          type: "Polygon",
          id: 72079,
          arcs: [[-9692, -9670, 9768, 9769, -9648]],
        },
        { type: "Polygon", id: 72009, arcs: [[9770, 9771, 9772, 9773, 9774]] },
        {
          type: "Polygon",
          id: 72099,
          arcs: [[9775, 9776, -9764, -9652, 9777]],
        },
        {
          type: "Polygon",
          id: 72023,
          arcs: [[9778, -9649, -9770, 9779, -9660]],
        },
        {
          type: "Polygon",
          id: 72109,
          arcs: [[9780, 9781, 9782, -9784, 9784, 9785, -9742]],
        },
        {
          type: "Polygon",
          id: 72101,
          arcs: [[-9759, -9711, 9786, 9787, -9715, -9726]],
        },
        { type: "Polygon", id: 72005, arcs: [[-9778, -9656, 9788, 9789]] },
        { type: "Polygon", id: 72059, arcs: [[-9707, 9790, 9791, -9701]] },
        {
          type: "Polygon",
          id: 72021,
          arcs: [[-9736, 9792, -9683, 9793, 9794, 9795, 9796]],
        },
        {
          type: "Polygon",
          id: 72141,
          arcs: [[9797, -9755, 9798, -9704, -9765, -9664, -9723]],
        },
        {
          type: "Polygon",
          id: 72041,
          arcs: [[-9680, 9799, -9775, 9800, -9685, 9801]],
        },
        {
          type: "Polygon",
          id: 72123,
          arcs: [[9802, 9803, -9689, 9804, -9772, 9805]],
        },
        {
          type: "Polygon",
          id: 72131,
          arcs: [[9806, -9696, -9768, -9675, -9761, -9777, 9807]],
        },
        {
          type: "Polygon",
          id: 72035,
          arcs: [[-9743, -9786, 9808, -9806, -9771, -9800, -9679]],
        },
        {
          type: "Polygon",
          id: 72135,
          arcs: [[-9795, 9809, -9713, -9758, 9810, 9811]],
        },
        { type: "Polygon", id: 72115, arcs: [[-9697, -9807, 9812, 9813]] },
        { type: "Polygon", id: 72054, arcs: [[-9717, 9814, -9721, 9815]] },
        {
          type: "Polygon",
          id: 72105,
          arcs: [[-9794, -9687, 9816, -9708, -9810]],
        },
        { type: "Polygon", id: 72017, arcs: [[-9718, -9816, -9720, 9817]] },
        {
          type: "Polygon",
          id: 72127,
          arcs: [[-9731, 9818, -9682, 9819, -9734, 9820]],
        },
        { type: "Polygon", id: 72139, arcs: [[-9730, -9752, -9676, -9819]] },
        {
          type: "Polygon",
          id: 72057,
          arcs: [[-9785, -9822, 9822, -9803, -9809]],
        },
        {
          type: "Polygon",
          id: 72153,
          arcs: [[-9702, -9792, 9823, -9668, -9694, 9824, -9766]],
        },
        {
          type: "Polygon",
          id: 72043,
          arcs: [[9825, 9826, -9773, -9805, -9688, -9746, 9827]],
        },
        { type: "Polygon", id: 72149, arcs: [[-9828, -9745, 9828]] },
        {
          type: "Polygon",
          id: 72039,
          arcs: [[-9788, 9829, -9756, -9798, -9722, -9815, -9716]],
        },
        {
          type: "Polygon",
          id: 72113,
          arcs: [[-9748, 9830, -9705, -9699, -9799, -9754]],
        },
        {
          type: "Polygon",
          id: 72107,
          arcs: [[9831, -9826, -9829, -9750, -9753, -9830, -9787, -9710]],
        },
        {
          type: "Polygon",
          id: 72071,
          arcs: [[-9813, -9808, -9776, -9790, 9832]],
        },
        {
          type: "Polygon",
          id: 72007,
          arcs: [[-9681, -9802, -9684, -9793, -9735, -9820]],
        },
        {
          type: "Polygon",
          id: 72019,
          arcs: [[-9686, -9801, -9774, -9827, -9832, -9709, -9817]],
        },
        {
          type: "Polygon",
          id: 72093,
          arcs: [[-9767, -9825, -9693, -9646, -9658, -9673]],
        },
        {
          type: "Polygon",
          id: 72151,
          arcs: [[9833, 9834, 9835, -9781, -9741, 9836]],
        },
        {
          type: "Polygon",
          id: 72137,
          arcs: [[9837, -9796, -9812, 9838, 9839]],
        },
        { type: "Polygon", id: 78030, arcs: [[9840]] },
        { type: "Polygon", id: 72089, arcs: [[9841, 9842, 9843, 9844]] },
        { type: "Polygon", id: 72087, arcs: [[9845, 9846, -9733, 9847]] },
        { type: "Polygon", id: 72095, arcs: [[9848, -9782, -9836]] },
        {
          type: "Polygon",
          id: 72119,
          arcs: [[-9844, 9849, 9850, 9851, 9852, -9846, 9853]],
        },
        { type: "Polygon", id: 72103, arcs: [[9854, 9855, 9856, 9857, -9851]] },
        {
          type: "Polygon",
          id: 72085,
          arcs: [[-9858, 9858, -9837, -9740, 9859, 9860, -9852]],
        },
        {
          type: "Polygon",
          id: 72029,
          arcs: [[-9853, -9861, 9861, -9728, -9847]],
        },
        { type: "Polygon", id: 72053, arcs: [[9862, -9842, 9863]] },
        { type: "Polygon", id: 72077, arcs: [[-9860, -9739, -9751, -9862]] },
        {
          type: "Polygon",
          id: 72037,
          arcs: [[9864, -9855, -9850, -9843, -9863]],
        },
        { type: "Polygon", id: 72069, arcs: [[9865, -9834, -9859, -9857]] },
        { type: "Polygon", id: 72147, arcs: [[9866]] },
        { type: "Polygon", id: 78010, arcs: [[9867]] },
        { type: "Polygon", id: 72051, arcs: [[-9839, -9811, -9757, 9868]] },
      ],
    },
    states: {
      type: "GeometryCollection",
      geometries: [
        {
          type: "MultiPolygon",
          id: 2,
          arcs: [
            [[9426]],
            [[9427]],
            [[9428]],
            [[9429]],
            [[9430]],
            [[9431]],
            [[9432]],
            [[9433]],
            [[9434]],
            [[9435]],
            [[9436]],
            [[9437]],
            [[9438]],
            [[9439]],
            [[9440]],
            [[9441]],
            [[9442]],
            [[9443]],
            [[9444]],
            [[9445]],
            [[9446]],
            [[9447]],
            [[9450]],
            [[9451]],
            [[9452]],
            [[9453]],
            [[9629]],
            [[9454]],
            [[9630]],
            [[9455]],
            [[9456]],
            [[9631]],
            [[9457]],
            [[9632]],
            [[9633]],
            [[9458]],
            [[9634]],
            [[9459]],
            [[9460]],
            [[9466]],
            [[9635]],
            [[9461]],
            [[9636]],
            [[9637]],
            [[9638]],
            [[9639]],
            [[9483]],
            [[9640]],
            [[9509]],
            [[9467]],
            [[9641]],
            [[9642]],
            [[9510]],
            [[9643]],
            [[9511]],
            [[9512]],
            [[9484]],
            [[9476]],
            [[9485]],
            [[9486]],
            [[9448]],
            [[9513]],
            [[9514]],
            [[9515]],
            [[9487]],
            [[9449]],
            [[9548]],
            [[9869, 9550]],
            [[9488]],
            [[9489]],
            [[9524]],
            [[9490]],
            [[9552, 9521, 9870, 9523, 9551, 9533]],
            [[9500]],
            [[9499, 9529, 9871, 9531, 9497, 9534]],
            [[9491]],
            [[9492]],
            [[9470]],
            [[9603]],
            [[9604]],
            [[9582]],
            [[9583]],
            [[9584]],
            [[9585]],
            [[9560]],
            [[9586]],
            [[9587]],
            [[9561]],
            [[9588]],
            [[9605]],
            [[9589]],
            [[9562]],
            [[9592]],
            [[9593]],
            [[9594]],
            [[9563]],
            [[9597]],
            [[9553]],
            [[9554]],
            [[9555]],
            [[9625]],
            [[9626]],
            [[9627]],
            [[9620]],
            [
              [
                9611,
                9602,
                9606,
                9596,
                9607,
                9591,
                9608,
                9581,
                9571,
                9609,
                9872,
                9493,
                9479,
                9465,
                9873,
                9463,
                9480,
                9468,
                9481,
                9475,
                9567,
                9471,
                9568,
                9559,
                9628,
                9624,
                9622,
                9612,
                9619,
                9600,
                9610,
                9536,
                9547,
                9542,
                9545,
                9501,
                9528,
                9516,
                9644,
                9518,
                9525,
                9874,
                9527,
                9503,
                9875,
                9505,
                9543,
                9508,
                9544,
                9540,
                9546,
                9538,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 15,
          arcs: [
            [[9417]],
            [[9422]],
            [[9421]],
            [[9423]],
            [[9876, 9420]],
            [[9424]],
            [[9425]],
            [[9418]],
          ],
        },
        {
          type: "MultiPolygon",
          id: 72,
          arcs: [
            [[9866]],
            [
              [
                9813,
                9697,
                9666,
                9723,
                9817,
                9718,
                9726,
                9759,
                9868,
                9839,
                9877,
                9737,
                9820,
                9731,
                9847,
                9853,
                9844,
                9863,
                9864,
                9855,
                9865,
                9834,
                9848,
                9782,
                9878,
                9822,
                9803,
                9689,
                9746,
                9830,
                9705,
                9790,
                9823,
                9668,
                9768,
                9779,
                9660,
                9761,
                9879,
                9654,
                9788,
                9832,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 1,
          arcs: [
            [
              -6786,
              -6785,
              -6792,
              -6791,
              -6807,
              6974,
              -6994,
              -6998,
              7027,
              -7168,
              -7171,
              7205,
              -7420,
              7492,
              7493,
              -7574,
              7698,
              7699,
              -7846,
              7871,
              -8018,
              8061,
              -8111,
              -8157,
              8194,
              -8293,
              8320,
              8321,
              -8483,
              8483,
              -8579,
              8680,
              8681,
              8688,
              8689,
              8690,
              8566,
              8567,
              8683,
              8684,
              8685,
              8675,
              8676,
              9880,
              8678,
              8701,
              8702,
              8703,
              -8620,
              8521,
              -8436,
              8249,
              8250,
              -8128,
              7954,
              7955,
              -7826,
              7678,
              -7599,
              7448,
              -7421,
              7302,
              -7257,
              7179,
              -6951,
              -6954,
              6937,
              -6753,
              -6699,
              -6714,
              -6725,
              -6724,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 5,
          arcs: [
            [
              -5843,
              -5842,
              -5816,
              -5921,
              -5920,
              -5919,
              -5932,
              -5931,
              -5930,
              -5716,
              -5888,
              -5887,
              -5886,
              -5917,
              -5916,
              -5866,
              -5973,
              -5972,
              -5971,
              -5970,
              -5969,
              -6163,
              -6274,
              6406,
              6407,
              6408,
              6409,
              -6605,
              6734,
              6735,
              6736,
              7015,
              -7027,
              7132,
              7133,
              -7415,
              -7414,
              7662,
              7663,
              7664,
              7665,
              7666,
              7777,
              7794,
              7795,
              7733,
              7734,
              7719,
              7720,
              7643,
              7644,
              7645,
              -7611,
              7513,
              -7233,
              -7232,
              7118,
              -6775,
              -6774,
              6727,
              -6611,
              6550,
              -6316,
              6256,
              6117,
              -5946,
              -5936,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 4,
          arcs: [
            [
              -4752,
              -5779,
              5779,
              5780,
              5781,
              -7177,
              7591,
              7592,
              -8039,
              8208,
              8504,
              8191,
              7725,
              7726,
              7305,
              7306,
              -6522,
              -6521,
              -5912,
              -4625,
              -5343,
              -5417,
              -5416,
              -4754,
              -4753,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 6,
          arcs: [
            [[7053]],
            [[7019]],
            [[7054]],
            [[6896]],
            [[6897]],
            [
              [
                -1601,
                -1600,
                -1604,
                2383,
                -2395,
                -2394,
                -2393,
                -2392,
                4129,
                4130,
                -4310,
                -4309,
                -4308,
                -3802,
                -4324,
                4568,
                -4773,
                -4255,
                5466,
                -5913,
                6520,
                6521,
                -7307,
                -7306,
                -7727,
                7740,
                7697,
                7519,
                7056,
                7020,
                6899,
                6528,
                5874,
                5606,
                5291,
                5193,
                5288,
                5438,
                5155,
                5018,
                4853,
                4552,
                4697,
                4472,
                4696,
                4474,
                4482,
                4847,
                4484,
                3626,
                2685,
                2373,
                -1893,
                -1975,
                -1974,
                -1882,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 8,
          arcs: [
            [
              -2141,
              -2140,
              -2553,
              -2552,
              -2733,
              -2732,
              -2702,
              -2701,
              -2823,
              -2972,
              -2971,
              3139,
              -3194,
              3363,
              3364,
              -3620,
              3939,
              3940,
              -4277,
              4354,
              -4592,
              -4591,
              4878,
              4879,
              -5245,
              5317,
              5318,
              5319,
              5207,
              5208,
              5307,
              5308,
              5510,
              5511,
              5496,
              5497,
              5323,
              5321,
              -4751,
              -4750,
              -4749,
              4628,
              -4026,
              3577,
              -3100,
              -3099,
              2981,
              2982,
              -2194,
              -2137,
              -2136,
              -2135,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 9,
          arcs: [
            [
              -2172,
              -2171,
              -2170,
              -2018,
              -2017,
              2317,
              2318,
              -2457,
              2526,
              2527,
              2557,
              2559,
              9881,
              2561,
              2547,
              2548,
              2549,
              -2285,
              -2284,
              -1998,
            ],
          ],
        },
        { type: "Polygon", id: 11, arcs: [[4385, 4386, -4115, -4297]] },
        {
          type: "Polygon",
          id: 10,
          arcs: [
            [
              3752,
              4102,
              4392,
              4393,
              4394,
              4395,
              4396,
              4397,
              -4267,
              4100,
              4101,
              -4079,
              3754,
              3755,
              -3497,
              -3588,
              3756,
              -3586,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 12,
          arcs: [
            [[9361]],
            [[9338]],
            [[9063]],
            [[9882, 9883]],
            [[8993, 8905]],
            [
              [
                9884,
                9356,
                9885,
                9358,
                9362,
                9351,
                9340,
                9331,
                9302,
                9329,
                9304,
                9285,
                9264,
                9262,
                9235,
                9221,
                9193,
                9886,
                9137,
                9363,
                8968,
                8865,
                8969,
                9065,
                9006,
                9064,
                9008,
                8913,
                9009,
                8915,
                8804,
                9887,
                8806,
                8797,
                8790,
                8787,
                -8676,
                -8686,
                -8685,
                -8684,
                -8568,
                -8567,
                -8691,
                -8690,
                -8689,
                -8682,
                -8745,
                -8744,
                -8733,
                -8737,
                -8736,
                -8743,
                -8742,
                -8740,
                -8739,
                -8762,
                8875,
                -8760,
                -8828,
                -8827,
                -8697,
                -8696,
                -8600,
                -8747,
                -8746,
                -8707,
                8835,
                8906,
                9013,
                9099,
                9139,
                9161,
                9152,
                9098,
                8991,
                8903,
                8992,
                9888,
                8988,
                9116,
                9153,
                9204,
                9889,
                9206,
                9146,
                9200,
                9276,
                9296,
                9890,
                9298,
                9326,
                9891,
                9322,
                9892,
                9324,
                9332,
                9353,
                9360,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 13,
          arcs: [
            [[8704]],
            [[9893, 8606]],
            [[8522]],
            [[8333]],
            [[8283]],
            [[8284]],
            [
              [
                8523,
                8607,
                9894,
                8609,
                8705,
                8706,
                8745,
                8746,
                8599,
                8695,
                8696,
                8826,
                8827,
                8759,
                -8876,
                8761,
                8738,
                8739,
                8741,
                8742,
                8735,
                8736,
                8732,
                8743,
                8744,
                -8681,
                8578,
                -8484,
                8482,
                -8322,
                -8321,
                8292,
                -8195,
                8156,
                8110,
                -8062,
                8017,
                -7872,
                7845,
                -7700,
                -7699,
                7573,
                -7494,
                -7493,
                7419,
                -7206,
                7170,
                7167,
                -7028,
                6997,
                6993,
                -6975,
                -6806,
                -6721,
                -6720,
                -6719,
                -6795,
                -6794,
                -6828,
                -6827,
                -6823,
                -6822,
                -6886,
                -6885,
                -6884,
                -6802,
                -6673,
                -6924,
                -6923,
                -6922,
                -6921,
                -6920,
                -7061,
                -7060,
                -7256,
                7339,
                -7438,
                -7437,
                -7484,
                -7483,
                -7542,
                -7541,
                -7711,
                7821,
                -7868,
                -7867,
                7891,
                -7899,
                -8057,
                -8056,
                -8055,
                8285,
                8280,
                8336,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 19,
          arcs: [
            [
              -1566,
              1654,
              -1696,
              -1780,
              -1779,
              2023,
              -2098,
              2159,
              -2230,
              2312,
              2313,
              -2452,
              -2451,
              -2450,
              2717,
              -2767,
              2921,
              -2928,
              3117,
              3118,
              3078,
              3079,
              3081,
              3082,
              3093,
              3094,
              3091,
              3092,
              3088,
              3089,
              3085,
              3086,
              3075,
              3076,
              3072,
              3073,
              3069,
              3070,
              -2935,
              2880,
              -2829,
              2659,
              2660,
              -2543,
              2416,
              -2304,
              2207,
              -2191,
              2065,
              2066,
              -1834,
              -1833,
              1756,
              -1660,
              1651,
              -1521,
              -1490,
              -1494,
              -1493,
              -1517,
              -1516,
              -1515,
              -1526,
              -1525,
              -1519,
              -1518,
              -1497,
              -1496,
              -1502,
              -1501,
              -1500,
              -1513,
              -1512,
              -1505,
              -1504,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 16,
          arcs: [
            [
              20,
              -36,
              111,
              -202,
              240,
              -350,
              -329,
              -328,
              518,
              -528,
              -737,
              -736,
              -735,
              -758,
              -652,
              1121,
              -1174,
              -1173,
              1592,
              -1734,
              -1733,
              2052,
              2141,
              2107,
              2108,
              2021,
              2022,
              1896,
              1580,
              1581,
              -1263,
              -1262,
              -1261,
              1091,
              -970,
              914,
              -709,
              522,
              535,
              536,
              -396,
              -395,
              364,
              -248,
              -247,
              114,
              -82,
              22,
              23,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 17,
          arcs: [
            [
              -1968,
              -1967,
              -1948,
              -1947,
              -1953,
              -1952,
              -1960,
              -1959,
              -2030,
              -2029,
              2116,
              2233,
              2234,
              2235,
              2510,
              -2533,
              2774,
              -2825,
              2957,
              -3156,
              3323,
              3324,
              -3546,
              3727,
              -3919,
              4027,
              -4173,
              4238,
              -4445,
              -4444,
              4683,
              -4705,
              4905,
              -4930,
              5135,
              -5171,
              5358,
              5359,
              5361,
              -5492,
              5562,
              5574,
              5575,
              5568,
              5569,
              5570,
              -5351,
              -5350,
              -5160,
              5125,
              4935,
              4936,
              4726,
              4727,
              -4456,
              -4455,
              -4521,
              4379,
              4380,
              4381,
              4165,
              4070,
              4071,
              -3929,
              3750,
              3751,
              -3685,
              3515,
              -3493,
              3241,
              3242,
              -3118,
              2927,
              -2922,
              2766,
              -2718,
              2449,
              2450,
              2451,
              -2314,
              -2313,
              2229,
              -2160,
              2097,
              -2024,
              -1778,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 18,
          arcs: [
            [
              -2297,
              -2296,
              -2291,
              -2290,
              -2295,
              2470,
              -2539,
              2621,
              -2717,
              2793,
              2794,
              -3019,
              3039,
              -3168,
              3291,
              -3407,
              -3406,
              3614,
              -3716,
              3808,
              -3932,
              -4139,
              4144,
              -4266,
              -4265,
              4422,
              4423,
              4432,
              4433,
              -4557,
              4659,
              4660,
              4805,
              4798,
              4799,
              4800,
              4795,
              4892,
              4893,
              4894,
              4953,
              4954,
              4923,
              4924,
              4973,
              4974,
              4927,
              4928,
              4929,
              -4906,
              4704,
              -4684,
              4443,
              4444,
              -4239,
              4172,
              -4028,
              3918,
              -3728,
              3545,
              -3325,
              -3324,
              3155,
              -2958,
              2824,
              -2775,
              2532,
              -2511,
              -2236,
              2533,
              2535,
              2467,
              -2204,
              -2203,
              -2300,
              -2299,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 20,
          arcs: [
            [
              -3419,
              -3431,
              -3429,
              -3428,
              -3423,
              -3422,
              -3433,
              -3432,
              -3413,
              -3412,
              -3410,
              -3409,
              -3417,
              -3416,
              -3426,
              -3427,
              -3317,
              -3316,
              -3486,
              -3485,
              -3490,
              -3489,
              -3488,
              -3492,
              -3561,
              3664,
              -3762,
              3884,
              -4003,
              -4002,
              -4046,
              -4199,
              -4198,
              4346,
              -4501,
              4546,
              -4772,
              4820,
              -5042,
              -5041,
              5300,
              5301,
              -5544,
              5556,
              5557,
              5558,
              5531,
              5532,
              5523,
              5524,
              5592,
              5593,
              5446,
              5447,
              5449,
              5450,
              5526,
              5527,
              5459,
              5460,
              5528,
              5529,
              5456,
              5457,
              5453,
              5520,
              5521,
              5519,
              5516,
              5517,
              -5318,
              5244,
              -4880,
              -4879,
              4590,
              4591,
              -4355,
              4276,
              -3941,
              -3940,
              3619,
              -3365,
              -3420,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 21,
          arcs: [
            [
              [
                -4137,
                -4136,
                -4161,
                -4160,
                -4159,
                -4182,
                -4181,
                -4351,
                -4350,
                -4373,
                -4372,
                -4498,
                -4497,
                4744,
                -4805,
                -4804,
                -5103,
                -5102,
                5233,
                5234,
                5235,
                5611,
                -5643,
                5726,
                5826,
                5827,
                5819,
                5820,
                5824,
                5825,
                5807,
                5808,
                5882,
                5883,
                5835,
                5913,
                5914,
                5838,
                5839,
                5891,
                5892,
                5696,
                5698,
                5699,
                5658,
                5659,
                5737,
                5937,
                5938,
                5831,
                5832,
                5932,
                5933,
                5957,
                5958,
                -5897,
                -5709,
                -5708,
                -5707,
                5632,
                -5569,
                -5576,
                -5575,
                -5563,
                5491,
                -5362,
                -5360,
                -5359,
                5170,
                -5136,
                -4929,
                -4928,
                -4975,
                -4974,
                -4925,
                -4924,
                -4955,
                -4954,
                -4895,
                -4894,
                -4893,
                -4796,
                -4801,
                -4800,
                -4799,
                -4806,
                -4661,
                -4660,
                4556,
                -4434,
                -4433,
                -4424,
                -4423,
                4264,
                4265,
                -4145,
                -4138,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 22,
          arcs: [
            [[9108]],
            [[9088]],
            [[9046]],
            [[9028]],
            [
              [
                9899,
                9025,
                9089,
                9003,
                9084,
                9900,
                9078,
                9901,
                9080,
                9902,
                9082,
                9109,
                9070,
                9049,
                9037,
                9060,
                -9017,
                -8995,
                8942,
                -8693,
                -8692,
                8649,
                8456,
                8457,
                -8406,
                8237,
                -8223,
                7906,
                7907,
                7908,
                -7820,
                -7645,
                -7644,
                -7721,
                -7720,
                -7735,
                -7734,
                -7796,
                -7795,
                -7778,
                -7667,
                -7666,
                -7665,
                -7928,
                7943,
                -8109,
                -8108,
                -8107,
                -8106,
                8274,
                8275,
                8276,
                8491,
                8492,
                -8647,
                -8646,
                -8661,
                -8660,
                -8659,
                -8664,
                -8663,
                -8665,
                -8632,
                -8771,
                -8770,
                8846,
                8847,
                9010,
                9027,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 25,
          arcs: [
            [[2727]],
            [[2632]],
            [
              [
                2175,
                2248,
                2249,
                2250,
                2251,
                2252,
                2168,
                2015,
                2016,
                2017,
                2169,
                2170,
                2171,
                1997,
                1998,
                1999,
                -1887,
                -1737,
                -1736,
                -1754,
                -1807,
                -1806,
                -1783,
                -1782,
                -1781,
                -1749,
                1934,
                9903,
                2006,
                9904,
                2008,
                2118,
                2165,
                2177,
                9905,
                2173,
                2287,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 24,
          arcs: [
            [[4166]],
            [
              [
                4781,
                4782,
                4856,
                4857,
                4858,
                4690,
                4581,
                4268,
                4408,
                4169,
                4080,
                3824,
                3834,
                3840,
                4088,
                4192,
                9906,
                4194,
                4521,
                4293,
                4613,
                4731,
                4615,
                4295,
                4296,
                4114,
                4115,
                4116,
                3849,
                3811,
                3812,
                3813,
                3814,
                3816,
                3817,
                3818,
                3820,
                3821,
                3822,
                -3547,
                -3461,
                -3460,
                -3445,
                -3537,
                -3536,
                -3458,
                -3457,
                -3584,
                -3583,
                -3501,
                -3500,
                -3499,
                -3449,
                -3448,
                -3498,
                -3756,
                -3755,
                4078,
                -4102,
                -4101,
                4266,
                -4398,
                -4397,
                -4396,
                4784,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 23,
          arcs: [
            [[918]],
            [[919]],
            [
              [
                1288,
                1134,
                1392,
                1380,
                1390,
                1382,
                1537,
                1538,
                -1300,
                -889,
                889,
                890,
                891,
                817,
                567,
                360,
                813,
                920,
                599,
                1117,
                1282,
                1286,
                1391,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 26,
          arcs: [
            [[9402]],
            [[9401]],
            [
              [
                771,
                820,
                9406,
                1075,
                1236,
                9407,
                1434,
                1560,
                1561,
                9408,
                1578,
                9409,
                1927,
                9410,
                9412,
                -2505,
                2280,
                2281,
                2292,
                2293,
                2294,
                2289,
                2290,
                2295,
                2296,
                2298,
                2299,
                2202,
                2203,
                2204,
                2151,
                1988,
                1785,
                1686,
                1533,
                1378,
                1225,
                1112,
                9404,
                9405,
                948,
                9400,
                9399,
              ],
            ],
            [[9395]],
            [[9388, 9389]],
            [[9390]],
            [
              [
                9377,
                446,
                9382,
                9384,
                491,
                9394,
                9907,
                9392,
                9396,
                9397,
                574,
                9385,
                712,
                713,
                714,
                639,
                640,
                587,
                588,
                589,
                590,
                476,
                477,
                478,
                479,
                480,
                481,
                482,
                483,
                484,
                434,
                9378,
              ],
            ],
            [[9373, 9379, 9375]],
            [[9376]],
          ],
        },
        {
          type: "Polygon",
          id: 27,
          arcs: [
            [
              125,
              151,
              204,
              9366,
              9365,
              203,
              137,
              138,
              139,
              140,
              141,
              142,
              143,
              144,
              145,
              146,
              147,
              473,
              592,
              593,
              594,
              -667,
              794,
              -800,
              901,
              902,
              1050,
              -1075,
              1139,
              -1162,
              -1187,
              -1186,
              -1191,
              1370,
              -1423,
              1502,
              1503,
              1504,
              1511,
              1512,
              1499,
              1500,
              1501,
              1495,
              1496,
              1517,
              1518,
              1524,
              1525,
              1514,
              1515,
              1516,
              1492,
              1493,
              1489,
              1490,
              1336,
              -1220,
              1179,
              -1044,
              -1043,
              917,
              -893,
              840,
              -746,
              702,
              -551,
              546,
              415,
              -404,
              348,
              -318,
              218,
              -210,
              164,
              165,
              -55,
              60,
              106,
              9364,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 29,
          arcs: [
            [
              -3119,
              -3243,
              -3242,
              3492,
              -3516,
              3684,
              -3752,
              -3751,
              3928,
              -4072,
              -4071,
              -4166,
              -4382,
              -4381,
              -4380,
              4520,
              4454,
              4455,
              -4728,
              -4727,
              -4937,
              -4936,
              -5126,
              5159,
              5349,
              5350,
              -5571,
              -5570,
              -5633,
              5706,
              5707,
              5708,
              5896,
              5897,
              5898,
              5899,
              -6100,
              6161,
              6162,
              5968,
              5969,
              5970,
              5971,
              5972,
              5865,
              5915,
              5916,
              5885,
              5886,
              5887,
              5715,
              5929,
              5930,
              5931,
              5918,
              5919,
              5920,
              5815,
              5841,
              5842,
              5935,
              5936,
              -5766,
              5719,
              -5557,
              5543,
              -5302,
              -5301,
              5040,
              5041,
              -4821,
              4771,
              -4547,
              4500,
              -4347,
              4197,
              4198,
              4045,
              4001,
              4002,
              -3885,
              3761,
              -3665,
              3560,
              3491,
              -3487,
              -3304,
              3273,
              -3129,
              -3070,
              -3074,
              -3073,
              -3077,
              -3076,
              -3087,
              -3086,
              -3090,
              -3089,
              -3093,
              -3092,
              -3095,
              -3094,
              -3083,
              -3082,
              -3080,
              -3079,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 28,
          arcs: [
            [
              -6755,
              -6754,
              -6938,
              6953,
              6950,
              -7180,
              7256,
              -7303,
              7420,
              -7449,
              7598,
              -7679,
              7825,
              -7956,
              -7955,
              8127,
              -8251,
              -8250,
              8435,
              -8522,
              8619,
              -8704,
              -8703,
              8838,
              8861,
              8880,
              -8847,
              8769,
              8770,
              8631,
              8664,
              8662,
              8663,
              8658,
              8659,
              8660,
              8645,
              8646,
              -8493,
              -8492,
              -8277,
              -8276,
              -8275,
              8105,
              8106,
              8107,
              8108,
              -7944,
              7927,
              -7664,
              -7663,
              7413,
              7414,
              -7134,
              -7133,
              7026,
              -7016,
              -6737,
              -6736,
              -6768,
              -6767,
              -6772,
              -6771,
              -6743,
              -6742,
              -6741,
              -6773,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 30,
          arcs: [
            [
              43,
              99,
              88,
              46,
              50,
              29,
              9,
              53,
              110,
              -93,
              108,
              -133,
              221,
              -231,
              373,
              -381,
              514,
              515,
              516,
              675,
              676,
              677,
              763,
              764,
              765,
              697,
              698,
              826,
              827,
              655,
              649,
              650,
              651,
              757,
              734,
              735,
              736,
              527,
              -519,
              327,
              328,
              349,
              -241,
              201,
              -112,
              35,
              -21,
              36,
              19,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 37,
          arcs: [
            [[-5845, 6042, 6259, 6044]],
            [
              [
                6047,
                6086,
                6179,
                6194,
                6038,
                6056,
                6247,
                6431,
                6425,
                6582,
                6258,
                6583,
                6564,
                6492,
                6560,
                6804,
                6761,
                6831,
                6759,
                6893,
                6990,
                7112,
                7271,
                9920,
                7273,
                7279,
                7280,
                7281,
                7282,
                7252,
                7005,
                7006,
                7007,
                6928,
                6877,
                6846,
                6847,
                6852,
                6853,
                6676,
                6677,
                6756,
                6645,
                6646,
                6630,
                6631,
                6768,
                6769,
                6695,
                6749,
                6750,
                6751,
                6671,
                6672,
                6801,
                6883,
                6884,
                6885,
                6821,
                6822,
                6823,
                -6595,
                -6594,
                -6457,
                -6456,
                -6392,
                -6391,
                -6287,
                -6286,
                -6285,
                6174,
                -6175,
                -6174,
                -6233,
                -6232,
                -6231,
                -6084,
                -6083,
                -5987,
                -5986,
                -5985,
                -5928,
                -5927,
                -5926,
                -5854,
                -5896,
                -5895,
                -5911,
                -5910,
                -5674,
                -5673,
                -5963,
                -5671,
                -5714,
                -5713,
                -5712,
                -5882,
                -5881,
                -5880,
                -5725,
                -5724,
                -5876,
                -5805,
                -5804,
                -5803,
                -5871,
                -5870,
                -5908,
                -5907,
                -5849,
                6041,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 38,
          arcs: [
            [
              54,
              -166,
              -165,
              209,
              -219,
              317,
              -349,
              403,
              -416,
              -547,
              550,
              551,
              627,
              628,
              633,
              634,
              631,
              632,
              530,
              583,
              623,
              624,
              625,
              635,
              -516,
              -515,
              380,
              -374,
              230,
              -222,
              132,
              -109,
              92,
              93,
              98,
              103,
              40,
              32,
              64,
              68,
              57,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 31,
          arcs: [
            [
              -1710,
              -1552,
              -1682,
              -1681,
              -1672,
              -1671,
              -1812,
              -1810,
              -1809,
              -1839,
              -1838,
              -1836,
              -1835,
              -2067,
              -2066,
              2190,
              -2208,
              2303,
              -2417,
              2542,
              -2661,
              -2660,
              2828,
              -2881,
              2934,
              -3071,
              3128,
              -3274,
              3303,
              3486,
              3487,
              3488,
              3489,
              3484,
              3485,
              3315,
              3316,
              3426,
              3425,
              3415,
              3416,
              3408,
              3409,
              3411,
              3412,
              3431,
              3432,
              3421,
              3422,
              3427,
              3428,
              3430,
              3418,
              3419,
              -3364,
              3193,
              -3140,
              2970,
              2971,
              2822,
              2700,
              2701,
              2731,
              2732,
              -2551,
              2541,
              -2048,
              -2047,
              1861,
              -1644,
              -1684,
              -1683,
              -1574,
              -1573,
              -1709,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 33,
          arcs: [
            [
              -890,
              888,
              1299,
              -1539,
              1615,
              1747,
              1748,
              1780,
              1781,
              1782,
              1805,
              1806,
              -1753,
              1614,
              -1456,
              1269,
              1270,
              -1115,
              -996,
              899,
              900,
              -891,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 34,
          arcs: [
            [
              9921,
              3944,
              3785,
              3786,
              3787,
              3788,
              3789,
              3726,
              3668,
              3524,
              3525,
              -3254,
              -3253,
              3124,
              2911,
              2912,
              -2814,
              2756,
              -2603,
              -2568,
              -2567,
              -2770,
              -2769,
              2897,
              3116,
              3066,
              3156,
              3256,
              3346,
              3531,
              3521,
              3805,
              4124,
              3946,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 35,
          arcs: [
            [
              5784,
              5785,
              5786,
              -6372,
              6554,
              6555,
              -6863,
              7008,
              7009,
              7156,
              7157,
              -7563,
              7653,
              7654,
              7655,
              7656,
              7657,
              7967,
              7968,
              7969,
              7783,
              7784,
              7785,
              7888,
              7889,
              8111,
              8037,
              8038,
              -7593,
              -7592,
              7176,
              -5782,
              -5781,
              -5780,
              5778,
              -5322,
              -5324,
              -5498,
              -5497,
              -5512,
              -5511,
              -5309,
              -5308,
              -5209,
              -5208,
              -5320,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 32,
          arcs: [
            [
              -2023,
              -2357,
              2361,
              -2921,
              3561,
              3562,
              -3998,
              4621,
              4622,
              4623,
              4624,
              5911,
              5912,
              -5467,
              4254,
              4772,
              -4569,
              4323,
              3801,
              4307,
              4308,
              4309,
              -4131,
              -4130,
              2391,
              2392,
              2393,
              2394,
              -2384,
              -1603,
              -1429,
              -1428,
              -1264,
              -1582,
              -1581,
              -1897,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 36,
          arcs: [
            [[3237]],
            [[3052, 9922, 3054, 3119, 3152, 3121, 3056, 2782]],
            [[1826]],
            [
              [
                3046,
                3097,
                3048,
                2751,
                2629,
                2770,
                2768,
                2769,
                2566,
                2567,
                2568,
                2324,
                2325,
                2093,
                2153,
                2154,
                2156,
                2157,
                2188,
                2189,
                2056,
                2057,
                2088,
                2089,
                2085,
                2086,
                2060,
                2061,
                2062,
                1831,
                1716,
                1713,
                1721,
                1724,
                1701,
                1570,
                1279,
                9923,
                1281,
                1275,
                1276,
                1019,
                1031,
                1005,
                -1001,
                1002,
                -1132,
                1204,
                -1296,
                -1485,
                1544,
                -1738,
                1886,
                -2000,
                -1999,
                2283,
                2284,
                -2550,
                -2549,
                2752,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 39,
          arcs: [
            [
              2397,
              -2447,
              2666,
              -2675,
              2898,
              -2906,
              3033,
              3034,
              -3246,
              3262,
              3263,
              -3520,
              3528,
              -3609,
              3737,
              3738,
              3893,
              3894,
              3895,
              3992,
              -4069,
              4228,
              4226,
              4362,
              4363,
              4494,
              4495,
              4496,
              4497,
              4371,
              4372,
              4349,
              4350,
              4180,
              4181,
              4158,
              4159,
              4160,
              4135,
              4136,
              4137,
              4138,
              3931,
              -3809,
              3715,
              -3615,
              3405,
              3406,
              -3292,
              3167,
              -3040,
              3018,
              -2795,
              -2794,
              2716,
              -2622,
              2538,
              -2471,
              -2294,
              -2293,
              -2282,
              -2281,
              2504,
              2505,
              2506,
              2507,
              2508,
              2509,
              2514,
              9926,
              2516,
              2669,
              2593,
              2594,
              2595,
              2636,
              2579,
              2443,
              2401,
              -2200,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 40,
          arcs: [
            [
              -5458,
              -5457,
              -5530,
              -5529,
              -5461,
              -5460,
              -5528,
              -5527,
              -5451,
              -5450,
              -5448,
              -5447,
              -5594,
              -5593,
              -5525,
              -5524,
              -5533,
              -5532,
              -5559,
              -5558,
              -5720,
              5765,
              -5937,
              5945,
              -6118,
              -6257,
              6315,
              -6551,
              6610,
              -6728,
              6773,
              6774,
              -7119,
              7231,
              7232,
              7233,
              7234,
              7401,
              7402,
              7398,
              7399,
              7400,
              7380,
              7440,
              7441,
              7442,
              7337,
              7338,
              7229,
              7230,
              7139,
              7140,
              7035,
              7036,
              6930,
              6931,
              -6871,
              6691,
              -6627,
              6398,
              -6366,
              6016,
              6017,
              5798,
              5799,
              5794,
              5795,
              5796,
              5791,
              5792,
              -5785,
              -5319,
              -5518,
              -5517,
              -5520,
              -5522,
              -5521,
              -5454,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 41,
          arcs: [
            [
              661,
              -608,
              658,
              -691,
              -604,
              -603,
              -696,
              -695,
              -694,
              -693,
              -692,
              -503,
              -502,
              -557,
              -555,
              -554,
              -513,
              -583,
              -536,
              -523,
              708,
              -915,
              969,
              -1092,
              1260,
              1261,
              1262,
              1263,
              1427,
              1428,
              1602,
              1603,
              1599,
              1600,
              1881,
              1973,
              1974,
              1892,
              1893,
              1611,
              1464,
              1298,
              980,
              776,
              622,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 42,
          arcs: [
            [
              -2061,
              -2087,
              -2086,
              -2090,
              -2089,
              -2058,
              -2057,
              -2190,
              -2189,
              -2158,
              -2157,
              -2155,
              -2154,
              -2094,
              -2326,
              -2325,
              -2569,
              2602,
              -2757,
              2813,
              -2913,
              -2912,
              -3125,
              3252,
              3253,
              -3526,
              3553,
              3584,
              3585,
              -3757,
              3587,
              3496,
              3497,
              3447,
              3448,
              3498,
              3499,
              3500,
              3582,
              3583,
              3456,
              3457,
              3535,
              3536,
              3444,
              3459,
              3460,
              3546,
              3547,
              3548,
              3610,
              3611,
              -3610,
              3337,
              3338,
              3339,
              -3244,
              3106,
              -3034,
              2905,
              -2899,
              2674,
              -2667,
              2446,
              -2398,
              2199,
              2200,
              -2062,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 44,
          arcs: [
            [[2544]],
            [[2545, -2250]],
            [
              [
                -2253,
                -2252,
                2452,
                2321,
                2454,
                2553,
                -2527,
                2456,
                -2319,
                -2318,
                -2016,
                -2169,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 45,
          arcs: [
            [[8077]],
            [[8079]],
            [[8080]],
            [
              [
                7693,
                9413,
                9930,
                9415,
                7862,
                8081,
                8046,
                9931,
                8048,
                9932,
                8050,
                8078,
                8053,
                8054,
                8055,
                8056,
                7898,
                -7892,
                7866,
                7867,
                -7822,
                7710,
                7540,
                7541,
                7482,
                7483,
                7436,
                7437,
                -7340,
                7255,
                7059,
                7060,
                6919,
                6920,
                6921,
                6922,
                6923,
                -6672,
                -6752,
                -6751,
                -6750,
                -6696,
                -6770,
                -6769,
                -6632,
                -6631,
                -6647,
                -6646,
                -6757,
                -6678,
                -6677,
                -6854,
                -6853,
                -6848,
                -6847,
                -6878,
                -6929,
                -7008,
                -7007,
                -7006,
                -7253,
                -7283,
                7328,
                7588,
                9416,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 46,
          arcs: [
            [
              -624,
              -584,
              -531,
              -633,
              -632,
              -635,
              -634,
              -629,
              -628,
              -552,
              -703,
              745,
              -841,
              892,
              -918,
              1042,
              1043,
              -1180,
              1219,
              -1337,
              -1491,
              1520,
              -1652,
              1659,
              -1757,
              1832,
              1833,
              1834,
              1835,
              1837,
              1838,
              1808,
              1809,
              1811,
              1670,
              1671,
              1680,
              1681,
              1551,
              1709,
              1708,
              1572,
              1573,
              1682,
              1683,
              -1643,
              1487,
              -1374,
              1248,
              1183,
              -1033,
              939,
              -677,
              -676,
              -517,
              -636,
              -626,
              -625,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 47,
          arcs: [
            [
              -5660,
              -5659,
              -5700,
              -5699,
              -5697,
              -5893,
              -5892,
              -5840,
              -5839,
              -5915,
              -5914,
              -5836,
              -5884,
              -5883,
              -5809,
              -5808,
              -5826,
              -5825,
              -5821,
              -5820,
              -5828,
              -5879,
              -5878,
              -5891,
              -5890,
              -5889,
              -5859,
              -5983,
              -5863,
              -5862,
              -5929,
              5984,
              5985,
              5986,
              6082,
              6083,
              6230,
              6231,
              6232,
              6173,
              6174,
              -6175,
              6284,
              6285,
              6286,
              6390,
              6391,
              6455,
              6456,
              6593,
              6594,
              -6824,
              6826,
              6827,
              6793,
              6794,
              6718,
              6719,
              6720,
              6805,
              6806,
              6790,
              6791,
              6784,
              6785,
              6723,
              6724,
              6713,
              6698,
              6752,
              6753,
              6754,
              6772,
              6740,
              6741,
              6742,
              6770,
              6771,
              6766,
              6767,
              -6735,
              6604,
              -6410,
              -6409,
              -6408,
              -6407,
              6273,
              -6162,
              6099,
              -5900,
              -6101,
              -5898,
              -5959,
              -5958,
              -5934,
              -5933,
              -5833,
              -5832,
              -5939,
              -5938,
              -5738,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 48,
          arcs: [
            [[9933]],
            [[9311, 9312]],
            [[9313, 9290, 9934, 9292, 9314]],
            [[9271]],
            [[9242]],
            [[9935, 9214]],
            [[9128]],
            [
              [
                9132,
                9175,
                9936,
                9164,
                9217,
                9166,
                9937,
                9168,
                9179,
                9216,
                9227,
                9247,
                9938,
                9249,
                9229,
                9243,
                9254,
                9246,
                9255,
                9939,
                9257,
                9273,
                9293,
                9318,
                9347,
                9352,
                9345,
                9342,
                9310,
                9253,
                9190,
                9127,
                8977,
                8872,
                8869,
                8883,
                8392,
                8394,
                -7889,
                -7786,
                -7785,
                -7784,
                -7970,
                -7969,
                -7968,
                -7658,
                -7657,
                -7656,
                -7655,
                -7654,
                7562,
                -7158,
                -7157,
                -7010,
                -7009,
                6862,
                -6556,
                -6555,
                6371,
                -5787,
                -5786,
                -5793,
                -5792,
                -5797,
                -5796,
                -5795,
                -5800,
                -5799,
                -6018,
                -6017,
                6365,
                -6399,
                6626,
                -6692,
                6870,
                -6932,
                -6931,
                -7037,
                -7036,
                -7141,
                -7140,
                -7231,
                -7230,
                -7339,
                -7338,
                -7443,
                -7442,
                -7441,
                -7381,
                -7401,
                -7400,
                -7399,
                -7403,
                -7402,
                -7235,
                -7234,
                -7514,
                7610,
                -7646,
                7819,
                -7909,
                -7908,
                -7907,
                8222,
                -8238,
                8405,
                -8458,
                -8457,
                -8650,
                8691,
                8692,
                -8943,
                8994,
                9016,
                9017,
                9090,
                9131,
                9092,
                9020,
                9130,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 49,
          arcs: [
            [
              -1732,
              2343,
              -2621,
              -2196,
              -2195,
              -2983,
              -2982,
              3098,
              3099,
              -3578,
              4025,
              -4629,
              4748,
              4749,
              4750,
              4751,
              4752,
              4753,
              5415,
              5416,
              5342,
              -4624,
              -4623,
              -4622,
              3997,
              -3563,
              -3562,
              2920,
              -2362,
              2356,
              -2022,
              -2109,
              -2108,
              -2142,
              -2053,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 51,
          arcs: [
            [[5405, 5058, -4782, 5059]],
            [
              [
                5005,
                4865,
                4807,
                4982,
                5225,
                5370,
                5424,
                5372,
                5109,
                5148,
                5330,
                5473,
                5536,
                9940,
                5683,
                5638,
                5472,
                5431,
                5282,
                5394,
                9941,
                5581,
                5626,
                5665,
                5871,
                9942,
                5902,
                9943,
                9944,
                5905,
                5850,
                5823,
                5851,
                5844,
                5845,
                5846,
                5847,
                5848,
                5906,
                5907,
                5869,
                5870,
                5802,
                5803,
                5804,
                5875,
                5723,
                5724,
                5879,
                5880,
                5881,
                5711,
                5712,
                5713,
                5670,
                5962,
                5672,
                5673,
                5909,
                5910,
                5894,
                5895,
                5853,
                5925,
                5926,
                5927,
                5928,
                5861,
                5862,
                5982,
                5858,
                5888,
                5889,
                5890,
                5877,
                5878,
                -5827,
                -5727,
                5642,
                -5612,
                -5236,
                -5235,
                -5234,
                -5101,
                -5412,
                -5411,
                -5378,
                -5377,
                -5376,
                -5180,
                -5275,
                -5274,
                5122,
                -4883,
                4877,
                -4536,
                -4535,
                -4403,
                -4402,
                -4401,
                -4189,
                -4188,
                4043,
                -3999,
                -3865,
                -3910,
                -4025,
                -4024,
                -3812,
                -3850,
                -4117,
                -4116,
                -4387,
                4417,
                9945,
                4342,
                4409,
                4669,
                4810,
                4866,
                5063,
                5188,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 50,
          arcs: [
            [
              1008,
              998,
              -900,
              995,
              1114,
              -1271,
              -1270,
              1455,
              -1615,
              1752,
              1753,
              1735,
              1736,
              1737,
              -1545,
              1484,
              1295,
              -1205,
              1131,
              -1003,
              1000,
              1001,
              994,
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 53,
          arcs: [
            [[303]],
            [[271]],
            [[184]],
            [[118]],
            [[119]],
            [[120]],
            [
              [
                126,
                2,
                3,
                75,
                85,
                80,
                83,
                -23,
                81,
                -115,
                246,
                247,
                -365,
                394,
                395,
                -537,
                582,
                512,
                553,
                554,
                556,
                501,
                502,
                691,
                692,
                693,
                694,
                695,
                602,
                603,
                690,
                -659,
                607,
                608,
                610,
                467,
                343,
                233,
                186,
                187,
                234,
                319,
                276,
                371,
                273,
                372,
                321,
                407,
                370,
                307,
                196,
                183,
                197,
                129,
              ],
            ],
          ],
        },
        {
          type: "MultiPolygon",
          id: 55,
          arcs: [
            [[9372]],
            [
              [
                9371,
                561,
                557,
                -484,
                -483,
                -482,
                -481,
                -480,
                -479,
                -478,
                -477,
                -591,
                -590,
                -589,
                -588,
                -641,
                -640,
                -715,
                9386,
                879,
                9949,
                881,
                1155,
                1167,
                9387,
                1165,
                1289,
                1474,
                1623,
                1800,
                1960,
                2027,
                2028,
                2029,
                1958,
                1959,
                1951,
                1952,
                1946,
                1947,
                1966,
                1967,
                1777,
                1778,
                1779,
                1695,
                -1655,
                1565,
                -1503,
                1422,
                -1371,
                1190,
                1185,
                1186,
                1161,
                -1140,
                1074,
                -1051,
                -903,
                -902,
                799,
                -795,
                666,
                -595,
                -594,
                -593,
                -474,
                -148,
                9368,
                9370,
              ],
            ],
          ],
        },
        {
          type: "Polygon",
          id: 54,
          arcs: [
            [
              3243,
              -3340,
              -3339,
              -3338,
              3609,
              -3612,
              -3611,
              -3549,
              -3548,
              -3823,
              -3822,
              -3821,
              -3819,
              -3818,
              -3817,
              -3815,
              -3814,
              -3813,
              4023,
              4024,
              3909,
              3864,
              3998,
              -4044,
              4187,
              4188,
              4400,
              4401,
              4402,
              4534,
              4535,
              -4878,
              4882,
              -5123,
              5273,
              5274,
              5179,
              5375,
              5376,
              5377,
              5410,
              5411,
              5100,
              5101,
              5102,
              4803,
              4804,
              -4745,
              -4496,
              -4495,
              -4364,
              -4363,
              -4227,
              -4229,
              4068,
              -3993,
              -3896,
              -3895,
              -3894,
              -3739,
              -3738,
              3608,
              -3529,
              3519,
              -3264,
              -3263,
              3245,
              -3035,
              -3107,
            ],
          ],
        },
        {
          type: "Polygon",
          id: 56,
          arcs: [
            [
              -827,
              -699,
              -698,
              -766,
              -765,
              -764,
              -678,
              -940,
              1032,
              -1184,
              -1249,
              1373,
              -1488,
              1642,
              1643,
              -1862,
              2046,
              2047,
              -2542,
              2550,
              2551,
              2552,
              2139,
              2140,
              2134,
              2135,
              2136,
              2193,
              2194,
              2195,
              2620,
              -2344,
              1731,
              1732,
              1733,
              -1593,
              1172,
              1173,
              -1122,
              -651,
              -650,
              -656,
              -828,
            ],
          ],
        },
        { type: "MultiPolygon", id: 78, arcs: [[[9867]], [[9840]]] },
      ],
    },
    land: {
      type: "MultiPolygon",
      arcs: [
        [[9361]],
        [[9933]],
        [[9338]],
        [[9311, 9312]],
        [[9313, 9290, 9934, 9292, 9314]],
        [[9271]],
        [[9242]],
        [[9935, 9214]],
        [[9128]],
        [[9108]],
        [[9088]],
        [[9046]],
        [[9063]],
        [[9882, 9883]],
        [[9028]],
        [[8993, 8905]],
        [[8704]],
        [[9893, 8606]],
        [[8522]],
        [[8333]],
        [[8283]],
        [[8284]],
        [[8077]],
        [[8079]],
        [[8080]],
        [[7053]],
        [[7019]],
        [[7054]],
        [[6896]],
        [[6897]],
        [[4166]],
        [[3237]],
        [[3052, 9922, 3054, 3119, 3152, 3121, 3056, 2782]],
        [[2727]],
        [[2632]],
        [[2544]],
        [[1826]],
        [[918]],
        [[919]],
        [[9402]],
        [[9401]],
        [[9395]],
        [[9388, 9389]],
        [[9390]],
        [[9372]],
        [[9373, 9379, 9375]],
        [[-9918]],
        [
          [
            1275,
            1276,
            1019,
            1031,
            1005,
            1001,
            994,
            1008,
            998,
            900,
            891,
            817,
            567,
            360,
            813,
            920,
            599,
            1117,
            1282,
            1286,
            1391,
            1288,
            1134,
            1392,
            1380,
            1390,
            1382,
            1537,
            1615,
            1747,
            1934,
            9903,
            2006,
            9904,
            2008,
            2118,
            2165,
            2177,
            9905,
            2173,
            2287,
            2175,
            2248,
            2545,
            2250,
            2452,
            2321,
            2454,
            2553,
            2527,
            2557,
            2559,
            9881,
            2561,
            2547,
            2752,
            3046,
            3097,
            3048,
            2751,
            2629,
            2770,
            2897,
            3116,
            3066,
            3156,
            3256,
            3346,
            3531,
            3521,
            3805,
            4124,
            3946,
            9921,
            3944,
            3785,
            9954,
            3787,
            9955,
            3789,
            3726,
            3668,
            3524,
            3553,
            3584,
            3752,
            4102,
            4392,
            9956,
            4394,
            4784,
            5059,
            5405,
            5058,
            4782,
            4856,
            9957,
            4858,
            4690,
            4581,
            4268,
            4408,
            4169,
            4080,
            3824,
            3834,
            3840,
            4088,
            4192,
            9906,
            4194,
            4521,
            4293,
            4613,
            4731,
            4615,
            4295,
            4385,
            4417,
            9945,
            4342,
            4409,
            4669,
            4810,
            4866,
            5063,
            5188,
            5005,
            4865,
            4807,
            4982,
            5225,
            5370,
            5424,
            5372,
            5109,
            5148,
            5330,
            5473,
            5536,
            9940,
            5683,
            5638,
            5472,
            5431,
            5282,
            5394,
            9941,
            5581,
            5626,
            5665,
            5871,
            9942,
            5902,
            9943,
            9944,
            5905,
            5850,
            5823,
            5851,
            6042,
            6259,
            6044,
            5845,
            9958,
            9959,
            9960,
            5847,
            6041,
            6047,
            6086,
            6179,
            6194,
            6038,
            6056,
            6247,
            6431,
            6425,
            6582,
            6258,
            6583,
            6564,
            6492,
            6560,
            6804,
            6761,
            6831,
            6759,
            6893,
            6990,
            7112,
            7271,
            9920,
            7273,
            7279,
            9961,
            7281,
            7328,
            7588,
            9416,
            7693,
            9413,
            9930,
            9415,
            7862,
            8081,
            8046,
            9931,
            8048,
            9932,
            8050,
            8078,
            8053,
            8285,
            8280,
            8336,
            8523,
            8607,
            9894,
            8609,
            8705,
            8835,
            8906,
            9013,
            9099,
            9139,
            9161,
            9152,
            9098,
            8991,
            8903,
            8992,
            9888,
            8988,
            9116,
            9153,
            9204,
            9889,
            9206,
            9146,
            9200,
            9276,
            9296,
            9890,
            9298,
            9326,
            9891,
            9322,
            9892,
            9324,
            9332,
            9353,
            9360,
            9884,
            9356,
            9885,
            9358,
            9362,
            9351,
            9340,
            9331,
            9302,
            9329,
            9304,
            9285,
            9264,
            9262,
            9235,
            9221,
            9193,
            9886,
            9137,
            9363,
            8968,
            8865,
            8969,
            9065,
            9006,
            9064,
            9008,
            8913,
            9009,
            8915,
            8804,
            9887,
            8806,
            8797,
            8790,
            8787,
            8676,
            9880,
            8678,
            8701,
            8838,
            8861,
            8880,
            8847,
            9010,
            9027,
            9899,
            9025,
            9089,
            9003,
            9084,
            9900,
            9078,
            9901,
            9080,
            9902,
            9082,
            9109,
            9070,
            9049,
            9037,
            9060,
            9017,
            9090,
            9131,
            9092,
            9020,
            9130,
            9132,
            9175,
            9936,
            9164,
            9217,
            9166,
            9937,
            9168,
            9179,
            9216,
            9227,
            9247,
            9938,
            9249,
            9229,
            9243,
            9254,
            9246,
            9255,
            9939,
            9257,
            9273,
            9293,
            9318,
            9347,
            9352,
            9345,
            9342,
            9310,
            9253,
            9190,
            9127,
            8977,
            8872,
            8869,
            8883,
            8392,
            8394,
            7889,
            8111,
            8037,
            8208,
            8504,
            8191,
            7725,
            7740,
            7697,
            7519,
            7056,
            7020,
            6899,
            6528,
            5874,
            5606,
            5291,
            5193,
            5288,
            5438,
            5155,
            5018,
            4853,
            4552,
            4697,
            4472,
            4696,
            4474,
            4482,
            4847,
            4484,
            3626,
            2685,
            2373,
            1893,
            1611,
            1464,
            1298,
            980,
            776,
            622,
            661,
            608,
            610,
            467,
            343,
            233,
            186,
            9946,
            9962,
            9948,
            3,
            75,
            85,
            80,
            83,
            23,
            36,
            19,
            43,
            99,
            88,
            46,
            50,
            29,
            9,
            53,
            110,
            93,
            98,
            103,
            40,
            32,
            64,
            68,
            57,
            60,
            106,
            9364,
            125,
            151,
            204,
            9366,
            9365,
            203,
            137,
            138,
            139,
            140,
            141,
            142,
            143,
            144,
            145,
            146,
            9368,
            9370,
            9371,
            561,
            557,
            484,
            434,
            9378,
            9377,
            446,
            9382,
            9384,
            491,
            9394,
            9907,
            9392,
            9396,
            9397,
            574,
            9385,
            712,
            713,
            9386,
            879,
            9949,
            881,
            1155,
            1167,
            9387,
            1165,
            1289,
            1474,
            1623,
            1800,
            1960,
            2027,
            2116,
            2233,
            2234,
            2533,
            2535,
            2467,
            2204,
            2151,
            1988,
            1785,
            1686,
            1533,
            1378,
            1225,
            1112,
            9404,
            9405,
            948,
            9400,
            9399,
            771,
            820,
            9406,
            1075,
            1236,
            9407,
            1434,
            1560,
            1561,
            9408,
            1578,
            9409,
            1927,
            9410,
            9412,
            2505,
            -9912,
            2507,
            -9910,
            2509,
            2514,
            9926,
            2516,
            2669,
            2593,
            2594,
            2595,
            2636,
            2579,
            2443,
            2401,
            2200,
            2062,
            1831,
            1716,
            1713,
            1721,
            1724,
            1701,
            1570,
            1279,
            9923,
            1281,
          ],
        ],
        [
          [
            9612,
            9619,
            9600,
            9610,
            9536,
            9547,
            9542,
            9545,
            9501,
            9528,
            9516,
            9644,
            9518,
            9525,
            9874,
            9527,
            9503,
            9875,
            9505,
            9543,
            9508,
            9544,
            9540,
            9546,
            9538,
            9611,
            9602,
            9606,
            9596,
            9607,
            9591,
            9608,
            9581,
            9571,
            9609,
            9872,
            9493,
            9479,
            9465,
            9873,
            9463,
            9480,
            9468,
            9481,
            9475,
            9567,
            9471,
            9568,
            9559,
            9628,
            9624,
            9622,
          ],
        ],
        [[9489]],
        [[9626]],
        [[9552, 9521, 9870, 9523, 9551, 9533]],
        [[9561]],
        [[9499, 9529, 9871, 9531, 9497, 9534]],
        [[9869, 9550]],
        [[9455]],
        [[9515]],
        [[9447]],
        [[9563]],
        [[9514]],
        [[9491]],
        [[9445]],
        [[9555]],
        [[9554]],
        [[9440]],
        [[9586]],
        [[9510]],
        [[9444]],
        [[9434]],
        [[9513]],
        [[9512]],
        [[9588]],
        [[9511]],
        [[9431]],
        [[9548]],
        [[9642]],
        [[9460]],
        [[9438]],
        [[9562]],
        [[9432]],
        [[9470]],
        [[9451]],
        [[9589]],
        [[9487]],
        [[9458]],
        [[9426]],
        [[9553]],
        [[9437]],
        [[9524]],
        [[9492]],
        [[9500]],
        [[9441]],
        [[9435]],
        [[9490]],
        [[9592]],
        [[9439]],
        [[9625]],
        [[9486]],
        [[9485]],
        [[9488]],
        [[9442]],
        [[9452]],
        [[9443]],
        [[9597]],
        [[9454]],
        [[9436]],
        [[9453]],
        [[9585]],
        [[9483]],
        [[9449]],
        [[9428]],
        [[9456]],
        [[9446]],
        [[9620]],
        [[9603]],
        [[9459]],
        [[9448]],
        [[9457]],
        [[9450]],
        [[9594]],
        [[9583]],
        [[9584]],
        [[9582]],
        [[9476]],
        [[9587]],
        [[9605]],
        [[9604]],
        [[9484]],
        [[9627]],
        [[9461]],
        [[9560]],
        [[9429]],
        [[9427]],
        [[9433]],
        [[9593]],
        [[9430]],
        [[9629]],
        [[9630]],
        [[9631]],
        [[9632]],
        [[9633]],
        [[9634]],
        [[9466]],
        [[9635]],
        [[9636]],
        [[9637]],
        [[9638]],
        [[9639]],
        [[9640]],
        [[9509]],
        [[9467]],
        [[9641]],
        [[9643]],
        [[9867]],
        [[9866]],
        [[9840]],
        [
          [
            9813,
            9697,
            9666,
            9723,
            9817,
            9718,
            9726,
            9759,
            9868,
            9839,
            9877,
            9737,
            9820,
            9731,
            9847,
            9853,
            9844,
            9863,
            9864,
            9855,
            9865,
            9834,
            9848,
            9782,
            9878,
            9822,
            9803,
            9689,
            9746,
            9830,
            9705,
            9790,
            9823,
            9668,
            9768,
            9779,
            9660,
            9761,
            9879,
            9654,
            9788,
            9832,
          ],
        ],
        [[9417]],
        [[9422]],
        [[9421]],
        [[9423]],
        [[9876, 9420]],
        [[9424]],
        [[9425]],
        [[9418]],
      ],
    },
  },
  arcs: [
    [
      [162416, 583189],
      [236, -863],
      [94, -3199],
      [220, -1079],
      [-271, -1241],
    ],
    [
      [162695, 576807],
      [-443, -309],
      [-4396, 83],
    ],
    [
      [157856, 576581],
      [-6, 1800],
      [-436, 606],
      [-476, 3062],
      [167, 1173],
    ],
    [
      [157105, 583222],
      [2782, -89],
      [2529, 56],
    ],
    [
      [203483, 583173],
      [-111, -3268],
      [363, 0],
      [1, -4838],
      [604, -14],
    ],
    [
      [204340, 575053],
      [0, -6406],
      [-109, -3],
      [-1, -3886],
    ],
    [
      [204230, 564758],
      [-1074, 300],
      [-478, 661],
      [-49, -1946],
    ],
    [
      [202629, 563773],
      [-411, -2654],
      [-860, -2419],
      [-1043, -435],
      [-462, 561],
    ],
    [
      [199853, 558826],
      [29, 9804],
      [98, 1617],
      [303, -7],
      [-114, 3279],
      [302, 529],
      [-36, 7508],
      [72, 1628],
    ],
    [
      [200507, 583184],
      [2976, -11],
    ],
    [
      [181317, 583162],
      [311, -3373],
      [318, 707],
      [389, -2530],
      [-133, -1970],
      [788, -1235],
      [-38, -1636],
      [347, -662],
      [20, -2123],
    ],
    [
      [183319, 570340],
      [343, -1416],
      [-25, -1101],
      [608, -791],
    ],
    [
      [184245, 567032],
      [386, -2907],
      [-296, -416],
    ],
    [
      [184335, 563709],
      [-284, -667],
      [56, -2637],
      [-277, -1038],
      [59, -2316],
    ],
    [
      [183889, 557051],
      [-896, 68],
    ],
    [
      [182993, 557119],
      [-468, -2],
    ],
    [
      [182525, 557117],
      [101, 1562],
      [-233, 2072],
      [31, 1860],
      [-477, 1550],
      [-122, 1357],
      [-537, -515],
      [111, -1134],
      [-1263, -12],
      [41, -3203],
      [-354, -5],
    ],
    [
      [179823, 560649],
      [-1, 1601],
      [-1073, -52],
      [-59, 2655],
    ],
    [
      [178690, 564853],
      [-26, 3920],
      [480, 4],
      [-5, 6466],
      [-109, 1581],
      [659, 10],
      [112, 1730],
      [-222, 1681],
      [-105, 2947],
    ],
    [
      [179474, 583192],
      [1843, -30],
    ],
    [
      [175797, 583199],
      [0, -9287],
    ],
    [
      [175797, 573912],
      [-2052, -28],
      [-1, 6456],
      [-689, -16],
    ],
    [
      [173055, 580324],
      [0, 2844],
    ],
    [
      [173055, 583168],
      [2742, 31],
    ],
    [
      [199853, 558826],
      [-98, -1296],
      [-681, 144],
      [-597, -3335],
    ],
    [
      [198477, 554339],
      [-62, 2205],
      [-374, 544],
      [-690, -278],
    ],
    [
      [197351, 556810],
      [-1278, 1156],
      [-333, 1655],
    ],
    [
      [195740, 559621],
      [8, 3507],
      [732, -36],
      [49, 1307],
      [484, -269],
      [0, 4521],
      [87, 4167],
    ],
    [
      [197100, 572818],
      [159, -390],
      [75, 5957],
      [166, -2],
      [64, 4793],
    ],
    [
      [197564, 583176],
      [2943, 8],
    ],
    [
      [221924, 574709],
      [-1820, 8],
    ],
    [
      [220104, 574717],
      [-102, 3252],
      [0, 5201],
    ],
    [
      [220002, 583170],
      [1830, 2],
    ],
    [
      [221832, 583172],
      [3, -5232],
      [89, -3231],
    ],
    [
      [178690, 564853],
      [-400, 43],
      [-118, -1893],
      [-228, -480],
      [-676, 326],
      [-48, 1381],
      [-615, 5245],
      [-698, -1923],
      [-112, 1018],
    ],
    [
      [175795, 568570],
      [2, 5342],
    ],
    [
      [175797, 583199],
      [3677, -7],
    ],
    [
      [220104, 574717],
      [-363, -10],
    ],
    [
      [219741, 574707],
      [-362, -1],
      [-1, 1624],
      [-1819, 10],
      [0, -1619],
    ],
    [
      [217559, 574721],
      [-1091, 11],
      [0, 3239],
      [-126, 10],
      [-1, 5192],
    ],
    [
      [216341, 583173],
      [3661, -3],
    ],
    [
      [186539, 583158],
      [25, -9701],
    ],
    [
      [186564, 573457],
      [-1094, 108],
      [-9, -3230],
      [-2142, 5],
    ],
    [
      [181317, 583162],
      [5222, -4],
    ],
    [
      [193948, 567081],
      [-538, -46],
      [0, 1653],
      [-330, -43],
      [0, 1617],
      [-2172, 10],
      [0, -1617],
      [-363, 1],
    ],
    [
      [190545, 568656],
      [35, 14501],
    ],
    [
      [190580, 583157],
      [3492, 32],
    ],
    [
      [194072, 583189],
      [-44, -7981],
      [115, -2218],
      [-242, -2960],
      [47, -2949],
    ],
    [
      [195740, 559621],
      [-133, 1040],
      [-934, 55],
      [-434, -1362],
      [-302, 351],
    ],
    [
      [193937, 559705],
      [11, 7376],
    ],
    [
      [194072, 583189],
      [3492, -13],
    ],
    [
      [206421, 583169],
      [53, -6498],
      [181, -1619],
    ],
    [
      [206655, 575052],
      [-2315, 1],
    ],
    [
      [203483, 583173],
      [2938, -4],
    ],
    [
      [228232, 583191],
      [206, -4516],
      [166, -1312],
      [-187, -2686],
    ],
    [
      [228417, 574677],
      [-2130, 0],
    ],
    [
      [226287, 574677],
      [-64, 8514],
    ],
    [
      [226223, 583191],
      [2009, 0],
    ],
    [
      [230526, 583184],
      [49, -8486],
    ],
    [
      [230575, 574698],
      [-2158, -21],
    ],
    [
      [228232, 583191],
      [2294, -7],
    ],
    [
      [223380, 574698],
      [-1, -3220],
      [-639, -11],
    ],
    [
      [222740, 571467],
      [-816, 5],
    ],
    [
      [221924, 571472],
      [0, 3237],
    ],
    [
      [221832, 583172],
      [1466, 10],
    ],
    [
      [223298, 583182],
      [0, -5247],
      [82, -3237],
    ],
    [
      [226287, 574677],
      [-1090, 12],
    ],
    [
      [225197, 574689],
      [-1817, 9],
    ],
    [
      [223298, 583182],
      [2925, 9],
    ],
    [
      [168030, 583185],
      [-42, -19407],
    ],
    [
      [167988, 563778],
      [-338, -262],
    ],
    [
      [167650, 563516],
      [-25, 343],
    ],
    [
      [167625, 563859],
      [88, 1056],
      [-308, 2218],
      [-251, 148],
      [-798, -1281],
      [-586, -1450],
      [-104, 1725],
      [-570, -648],
      [55, -1799],
    ],
    [
      [165151, 563828],
      [-497, 4],
      [-263, 1945],
      [-611, 1931],
      [-82, 1381],
      [-532, 1417],
      [-331, 3957],
    ],
    [
      [162835, 574463],
      [-140, 2344],
    ],
    [
      [162416, 583189],
      [2209, -15],
      [3405, 11],
    ],
    [
      [171951, 583192],
      [-373, -3049],
      [-183, -3367],
      [361, 7],
      [-7, -9706],
      [182, -1632],
    ],
    [
      [171931, 565445],
      [-274, 12],
      [-1, -4693],
      [-436, 1680],
      [-359, -1132],
    ],
    [
      [170861, 561312],
      [-166, 418],
      [-548, -890],
      [-371, 2707],
      [-364, -947],
    ],
    [
      [169412, 562600],
      [-132, 2358],
      [405, 757],
      [-4, 1210],
      [289, 2381],
      [-187, 3288],
      [316, 4420],
      [-331, 3951],
      [45, 2219],
    ],
    [
      [169813, 583184],
      [2138, 8],
    ],
    [
      [173055, 580324],
      [-26, -14910],
    ],
    [
      [173029, 565414],
      [-1098, 31],
    ],
    [
      [171951, 583192],
      [1104, -24],
    ],
    [
      [169412, 562600],
      [-45, -983],
      [-485, 620],
      [-109, 1069],
      [-446, -598],
      [-339, 1070],
    ],
    [
      [168030, 583185],
      [1783, -1],
    ],
    [
      [188725, 567028],
      [0, 1624],
    ],
    [
      [188725, 568652],
      [374, 0],
      [11, 14480],
    ],
    [
      [189110, 583132],
      [1470, 25],
    ],
    [
      [190545, 568656],
      [-200, -1611],
      [-1620, -17],
    ],
    [
      [212324, 583170],
      [-3, -5175],
      [150, -1639],
    ],
    [
      [212471, 576356],
      [-3238, 15],
    ],
    [
      [209233, 576371],
      [-1, 6810],
    ],
    [
      [209232, 583181],
      [3092, -11],
    ],
    [
      [214880, 583162],
      [-1, -3570],
    ],
    [
      [214879, 579592],
      [-364, -1],
      [-1, -1615],
      [-226, 0],
      [0, -3239],
    ],
    [
      [214288, 574737],
      [-1816, 1],
    ],
    [
      [212472, 574738],
      [-1, 1618],
    ],
    [
      [212324, 583170],
      [2556, -8],
    ],
    [
      [186539, 583158],
      [2571, -26],
    ],
    [
      [188725, 568652],
      [-718, -1],
      [2, 2422],
      [-362, 804],
      [-543, 30],
      [-81, 1075],
      [-459, 475],
    ],
    [
      [217559, 574721],
      [0, -1621],
    ],
    [
      [217559, 573100],
      [-2179, 21],
      [-1, 4852],
      [-499, 6],
      [-1, 1613],
    ],
    [
      [214880, 583162],
      [1461, 11],
    ],
    [
      [233488, 574623],
      [-724, -26],
    ],
    [
      [232764, 574597],
      [-2189, 101],
    ],
    [
      [230526, 583184],
      [3019, -21],
    ],
    [
      [233545, 583163],
      [240, -2188],
      [402, 728],
      [5, -3862],
      [-704, -3],
      [0, -3215],
    ],
    [
      [209233, 576371],
      [4, -4555],
    ],
    [
      [209237, 571816],
      [-1620, -4],
      [1, 1622],
      [-363, 4],
      [0, 1612],
      [-600, 2],
    ],
    [
      [206421, 583169],
      [2811, 12],
    ],
    [
      [175795, 568570],
      [0, -4401],
    ],
    [
      [175795, 564169],
      [-503, 1656],
      [-278, -929],
      [18, -2373],
    ],
    [
      [175032, 562523],
      [-508, -3],
      [3, 1885],
      [-1501, -262],
    ],
    [
      [173026, 564143],
      [3, 1271],
    ],
    [
      [217559, 573100],
      [127, -1619],
      [-2, -6492],
      [124, -2],
      [-3, -3238],
    ],
    [
      [217805, 561749],
      [-2509, -20],
    ],
    [
      [215296, 561729],
      [1, 3269],
      [-141, 0],
      [0, 6485],
      [-143, 10],
      [0, 3259],
      [-725, -15],
    ],
    [
      [156776, 574866],
      [145, -2337],
      [-336, 700],
      [191, 1637],
    ],
    [
      [156018, 576188],
      [367, -1161],
      [-54, -1921],
      [-274, 763],
      [-39, 2319],
    ],
    [
      [156711, 577874],
      [437, -1003],
      [-572, -1182],
      [-174, 1060],
      [309, 1125],
    ],
    [
      [239768, 576252],
      [-26, -7172],
      [46, -6528],
    ],
    [
      [239788, 562552],
      [-1935, 133],
      [-2, -978],
      [-1789, -11],
    ],
    [
      [236062, 561696],
      [-29, 9709],
    ],
    [
      [236033, 571405],
      [-4, 6210],
    ],
    [
      [236029, 577615],
      [339, 173],
      [235, -1132],
      [1047, -347],
      [154, -2131],
      [907, 561],
      [6, 841],
      [719, 948],
      [332, -276],
    ],
    [
      [157403, 572219],
      [-142, 1501],
      [248, 575],
      [362, -1247],
      [-15, 3533],
    ],
    [
      [162835, 574463],
      [-341, 252],
      [-585, -931],
      [-106, -3299],
      [197, -417],
    ],
    [
      [162000, 570068],
      [-3852, 30],
    ],
    [
      [158148, 570098],
      [-745, 2121],
    ],
    [
      [212472, 574738],
      [0, -3235],
      [160, 6],
      [-1, -4641],
    ],
    [
      [212631, 566868],
      [-1041, 215],
      [-260, -1783],
      [-494, -878],
      [-372, 2570],
      [-383, -709],
      [-219, -2110],
      [-618, 322],
    ],
    [
      [209244, 564495],
      [-7, 7321],
    ],
    [
      [219741, 574707],
      [109, -3230],
      [1, -6504],
      [110, 0],
      [-1, -3243],
    ],
    [
      [219960, 561730],
      [-1079, -3],
    ],
    [
      [218881, 561727],
      [-1076, 22],
    ],
    [
      [243362, 568344],
      [29, -12216],
      [-17, -11280],
    ],
    [
      [243374, 544848],
      [-834, -2840],
      [212, -1505],
    ],
    [
      [242752, 540503],
      [-2, -7],
    ],
    [
      [242750, 540496],
      [-224, 823],
    ],
    [
      [242526, 541319],
      [-10, 0],
    ],
    [
      [242516, 541319],
      [-187, -592],
    ],
    [
      [242329, 540727],
      [-9, 7],
    ],
    [
      [242320, 540734],
      [-75, -212],
    ],
    [
      [242245, 540522],
      [-5, -38],
    ],
    [
      [242240, 540484],
      [-9, -872],
    ],
    [
      [242231, 539612],
      [3, 11],
    ],
    [
      [242234, 539623],
      [-248, 57],
    ],
    [
      [241986, 539680],
      [-26, 1879],
      [-2117, 44],
    ],
    [
      [239843, 541603],
      [15, 4838],
    ],
    [
      [239858, 546441],
      [-15, 12915],
      [-55, 3196],
    ],
    [
      [239768, 576252],
      [1003, -1644],
      [260, 59],
      [-216, -1483],
      [572, -280],
      [382, -4206],
      [280, 489],
      [20, 1987],
      [577, 82],
      [135, -1750],
      [581, -1162],
    ],
    [
      [209244, 564495],
      [-258, 1115],
      [-593, -179],
      [-307, 1362],
      [-1057, 19],
      [-145, 560],
      [-288, -1583],
      [-558, -1],
    ],
    [
      [206038, 565788],
      [-93, 498],
      [-752, 103],
      [-963, -1631],
    ],
    [
      [165151, 563828],
      [-359, -3363],
      [-516, -331],
      [-139, -2995],
      [-195, -1515],
      [49, -2367],
      [561, -1042],
      [-25, -1385],
    ],
    [
      [164527, 550830],
      [-822, -18],
      [-768, 1529],
      [-383, 1474],
      [-339, 202],
      [-528, 3049],
    ],
    [
      [161687, 557066],
      [129, 2030],
      [-144, 1373],
    ],
    [
      [161672, 560469],
      [135, 875],
      [-307, 1331],
      [76, 2648],
      [386, 644],
      [303, 1650],
      [-265, 2451],
    ],
    [
      [215296, 561729],
      [-1431, 3],
      [-2, -1673],
    ],
    [
      [213863, 560059],
      [-714, 1226],
    ],
    [
      [213149, 561285],
      [-15, 1450],
      [265, 1806],
      [-308, 1452],
      [-460, 875],
    ],
    [
      [232764, 574597],
      [24, -6811],
    ],
    [
      [232788, 567786],
      [-2527, 21],
    ],
    [
      [230261, 567807],
      [-1799, -6],
    ],
    [
      [228462, 567801],
      [14, 367],
    ],
    [
      [228476, 568168],
      [71, 2441],
      [-130, 4068],
    ],
    [
      [221924, 571472],
      [-986, 7],
      [-3, -6507],
      [100, -3249],
    ],
    [
      [221035, 561723],
      [-716, 0],
    ],
    [
      [220319, 561723],
      [-359, 7],
    ],
    [
      [225197, 574689],
      [73, -6488],
    ],
    [
      [225270, 568201],
      [-362, -8],
      [-2, -3234],
      [-287, 1],
      [0, -1975],
    ],
    [
      [224619, 562985],
      [-263, 25],
      [-571, 2718],
      [-293, -1216],
      [-752, 3114],
      [0, 3841],
    ],
    [
      [228476, 568168],
      [-2123, 27],
    ],
    [
      [226353, 568195],
      [-1083, 6],
    ],
    [
      [236062, 561696],
      [6, -7465],
    ],
    [
      [236068, 554231],
      [0, -615],
      [-707, -32],
    ],
    [
      [235361, 553584],
      [-1430, 50],
    ],
    [
      [233931, 553634],
      [-29, 8560],
      [-224, 1564],
      [125, 1180],
      [-986, 11],
    ],
    [
      [232817, 564949],
      [-29, 2837],
    ],
    [
      [233488, 574623],
      [363, -5],
      [3, -3239],
      [2179, 26],
    ],
    [
      [188725, 567028],
      [1, -2703],
    ],
    [
      [188726, 564325],
      [-1602, -42],
      [-179, 1901],
      [-363, 820],
      [-2337, 28],
    ],
    [
      [158090, 569229],
      [27, -389],
    ],
    [
      [158117, 568840],
      [-233, -1009],
      [277, -1641],
      [-369, 861],
      [-55, 2156],
      [353, 22],
    ],
    [
      [157568, 571941],
      [243, -1776],
      [-417, -307],
      [-219, -1059],
      [350, -339],
      [185, -3598],
      [45, 1502],
      [415, -1154],
      [-2, -2382],
      [-642, 2318],
      [-20, 2344],
      [-434, 1258],
      [298, 3296],
      [198, -103],
    ],
    [
      [156634, 565779],
      [-60, -3687],
      [-1564, -7],
      [0, 264],
      [-3066, -4],
    ],
    [
      [151944, 562345],
      [-337, 5365],
      [202, 2998],
      [-202, 971],
    ],
    [
      [151607, 571679],
      [463, -265],
      [484, -1494],
      [396, -424],
      [748, -1863],
      [763, 58],
      [868, -995],
      [776, 701],
      [529, -1618],
    ],
    [
      [224619, 562985],
      [0, -1281],
    ],
    [
      [224619, 561704],
      [-2151, 10],
    ],
    [
      [222468, 561714],
      [-1433, 9],
    ],
    [
      [193937, 559705],
      [-803, -422],
      [-51, -3212],
      [-1027, -2365],
    ],
    [
      [192056, 553706],
      [-1187, -7],
    ],
    [
      [190869, 553699],
      [-361, 552],
      [1, 1469],
      [-266, 4],
      [-517, 1364],
      [204, 1867],
      [-1205, -7],
    ],
    [
      [188725, 558948],
      [1, 5377],
    ],
    [
      [161672, 560469],
      [-3555, -37],
    ],
    [
      [158117, 560432],
      [249, 3191],
      [301, 1598],
      [-407, 1180],
      [-143, 2439],
    ],
    [
      [158090, 569229],
      [58, 869],
    ],
    [
      [179823, 560649],
      [58, -3498],
      [649, 4],
      [-91, -2233],
      [238, 251],
      [-154, -1949],
      [417, -649],
      [44, -4053],
    ],
    [
      [180984, 548522],
      [-292, -238],
      [-171, 1188],
      [-551, 1163],
      [-685, 298],
    ],
    [
      [179285, 550933],
      [-478, 836],
      [-38, 1568],
      [-837, 1677],
      [-963, -135],
    ],
    [
      [176969, 554879],
      [-354, 1336],
      [183, 799],
      [-95, 1862],
      [-283, 1075],
      [-625, 4218],
    ],
    [
      [245498, 568091],
      [24, -13480],
    ],
    [
      [245522, 554611],
      [-1265, -6316],
      [-883, -3447],
    ],
    [
      [243362, 568344],
      [242, -1593],
      [425, -163],
      [-24, -1207],
      [886, 751],
      [607, 1959],
    ],
    [
      [226353, 568195],
      [59, -3242],
      [-1, -6490],
    ],
    [
      [226411, 558463],
      [-285, 2],
    ],
    [
      [226126, 558465],
      [-1436, 0],
    ],
    [
      [224690, 558465],
      [-71, 3239],
    ],
    [
      [228462, 567801],
      [350, -5611],
      [344, -3679],
    ],
    [
      [229156, 558511],
      [-1603, -50],
    ],
    [
      [227553, 558461],
      [-1142, 2],
    ],
    [
      [230261, 567807],
      [50, -3920],
    ],
    [
      [230311, 563887],
      [2, -2161],
      [360, -5],
      [2, -1615],
      [1435, 5],
      [0, 1629],
      [356, 7],
      [-1, 1624],
    ],
    [
      [232465, 563371],
      [355, -34],
    ],
    [
      [232820, 563337],
      [77, -8073],
    ],
    [
      [232897, 555264],
      [-1430, -21],
    ],
    [
      [231467, 555243],
      [-2182, 14],
    ],
    [
      [229285, 555257],
      [-129, 3254],
    ],
    [
      [232817, 564949],
      [3, -1612],
    ],
    [
      [232465, 563371],
      [-1, 531],
      [-2153, -15],
    ],
    [
      [209244, 564495],
      [-2, -11147],
    ],
    [
      [209242, 553348],
      [-241, -806],
      [-805, 9],
    ],
    [
      [208196, 552551],
      [-259, 2],
      [1, 3249],
      [-225, -5],
      [0, 1619],
      [-1072, 7],
      [1, 1622],
      [-715, 4],
      [0, 1622],
    ],
    [
      [205927, 560671],
      [111, 1440],
      [0, 3677],
    ],
    [
      [167625, 563859],
      [-339, -5],
      [-301, -1380],
      [-1, -1619],
      [-240, -1347],
      [-120, -2159],
      [-533, -1577],
      [-2, -1607],
      [-955, -85],
      [-358, -2172],
      [-6, -1862],
    ],
    [
      [164770, 550046],
      [-243, 784],
    ],
    [
      [213149, 561285],
      [0, -2790],
      [-1274, 10],
      [2, -6436],
    ],
    [
      [211877, 552069],
      [-1581, 12],
    ],
    [
      [210296, 552081],
      [-1055, 15],
    ],
    [
      [209241, 552096],
      [1, 1252],
    ],
    [
      [156465, 557234],
      [-1439, 15],
      [0, -1663],
    ],
    [
      [155026, 555586],
      [-1512, 9],
      [-857, 298],
    ],
    [
      [152657, 555893],
      [-215, 4015],
      [-498, 2437],
    ],
    [
      [156634, 565779],
      [273, 1293],
      [567, -4576],
      [-189, -396],
      [-251, -3033],
      [-105, 2479],
      [-246, -3231],
      [-218, -1081],
    ],
    [
      [188725, 558948],
      [-718, -7],
      [0, -1612],
      [-713, 3],
      [0, -1972],
      [-347, 194],
    ],
    [
      [186947, 555554],
      [-644, -185],
      [-426, 1863],
      [-409, 506],
      [-656, -490],
      [-280, 1681],
      [6, 2406],
      [-203, 2374],
    ],
    [
      [205927, 560671],
      [-357, -7],
      [-3, -4859],
      [-120, -3],
      [2, -6473],
    ],
    [
      [205449, 549329],
      [-1188, -10],
      [0, -1636],
      [-355, -3],
      [0, 1641],
      [-354, -3],
    ],
    [
      [203552, 549318],
      [-232, 0],
      [-1, 3235],
      [-355, -6],
      [105, 3251],
      [-2, 6316],
      [-270, 1],
      [-168, 1658],
    ],
    [
      [176969, 554879],
      [-257, -1008],
      [473, -1408],
      [60, -942],
      [526, -680],
      [549, -2997],
      [263, -2458],
      [250, -686],
    ],
    [
      [178833, 544700],
      [-3820, 28],
    ],
    [
      [175013, 544728],
      [0, 1637],
    ],
    [
      [175013, 546365],
      [0, 7286],
    ],
    [
      [175013, 553651],
      [19, 8872],
    ],
    [
      [182525, 557117],
      [-825, -26],
      [-58, -2280],
      [224, -1574],
      [136, -3906],
      [-311, -808],
      [-707, -1],
    ],
    [
      [173026, 564143],
      [8, -11380],
    ],
    [
      [173034, 552763],
      [0, -1987],
    ],
    [
      [173034, 550776],
      [-2181, 20],
    ],
    [
      [170853, 550796],
      [8, 10516],
    ],
    [
      [233931, 553634],
      [40, -4845],
    ],
    [
      [233971, 548789],
      [-1065, -20],
    ],
    [
      [232906, 548769],
      [-9, 6495],
    ],
    [
      [175013, 553651],
      [-715, 16],
      [-120, -800],
      [-1144, -104],
    ],
    [
      [203552, 549318],
      [2, -5970],
    ],
    [
      [203554, 543348],
      [0, -266],
    ],
    [
      [203554, 543082],
      [-1766, -14],
      [-2, 268],
      [-3263, -152],
    ],
    [
      [198523, 543184],
      [-142, 489],
      [173, 2154],
      [-209, 2560],
      [-11, 4245],
      [143, 1707],
    ],
    [
      [167650, 563516],
      [-17, -12700],
    ],
    [
      [167633, 550816],
      [-9, -6518],
      [-1075, 22],
      [-3, -3262],
    ],
    [
      [166546, 541058],
      [-233, -1081],
    ],
    [
      [166313, 539977],
      [-187, 857],
      [-295, -1494],
      [-690, -306],
    ],
    [
      [165141, 539034],
      [-276, 2022],
    ],
    [
      [164865, 541056],
      [127, 1141],
      [-311, 5065],
      [89, 2784],
    ],
    [
      [170853, 550796],
      [-381, 1],
    ],
    [
      [170472, 550797],
      [-2839, 19],
    ],
    [
      [186947, 555554],
      [7, -6012],
      [711, -1191],
      [-2, -4005],
      [368, -11],
    ],
    [
      [188031, 544335],
      [35, -1328],
      [411, -1480],
    ],
    [
      [188477, 541527],
      [-392, -449],
      [14, -3150],
      [-430, -3],
    ],
    [
      [187669, 537925],
      [-672, 2],
      [-784, -2757],
    ],
    [
      [186213, 535170],
      [46, 3441],
      [-325, 1748],
      [-347, -22],
      [-1, 2529],
      [-723, -49],
      [-4, 6432],
      [-731, 40],
      [-1, 5728],
      [-238, 2034],
    ],
    [
      [157723, 558953],
      [152, -2103],
      [-263, 244],
      [111, 1859],
    ],
    [
      [157679, 553460],
      [-209, 2],
    ],
    [
      [157470, 553462],
      [-20, -1],
    ],
    [
      [157450, 553461],
      [-465, 10],
    ],
    [
      [156985, 553471],
      [3, 2161],
      [-621, 2],
    ],
    [
      [156367, 555634],
      [297, 1874],
      [455, 873],
      [193, 2438],
      [440, 2118],
      [103, -3144],
      [-199, 149],
      [-132, -3407],
      [313, -1094],
      [-158, -1981],
    ],
    [
      [239858, 546441],
      [-2004, 75],
    ],
    [
      [237854, 546516],
      [-6, 4597],
      [-694, 1379],
      [-255, 2407],
      [-570, -1212],
      [-261, 544],
    ],
    [
      [218881, 561727],
      [0, -3232],
      [-241, 5],
      [-5, -6449],
    ],
    [
      [218635, 552051],
      [-211, -1],
      [0, -3173],
      [-602, -11],
    ],
    [
      [217822, 548866],
      [-251, 2540],
      [-563, -513],
    ],
    [
      [217008, 550893],
      [-256, 567],
      [-256, 3108],
      [36, 1844],
      [-852, -555],
      [-222, -665],
      [-1091, 1453],
    ],
    [
      [214367, 556645],
      [-155, 784],
      [-3, 3078],
      [-346, -448],
    ],
    [
      [224690, 558465],
      [0, -1585],
    ],
    [
      [224690, 556880],
      [-2133, 9],
    ],
    [
      [222557, 556889],
      [-89, 4825],
    ],
    [
      [222557, 556889],
      [-2, -4848],
    ],
    [
      [222555, 552041],
      [-597, -4],
    ],
    [
      [221958, 552037],
      [-1539, 4],
    ],
    [
      [220419, 552041],
      [1, 6449],
      [-101, 3233],
    ],
    [
      [220419, 552041],
      [-222, 0],
    ],
    [
      [220197, 552041],
      [-1562, 10],
    ],
    [
      [214367, 556645],
      [0, -4588],
      [170, 0],
      [-1, -5915],
    ],
    [
      [214536, 546142],
      [-1061, -2],
      [0, -539],
      [-1412, -13],
    ],
    [
      [212063, 545588],
      [-1, 6481],
      [-185, 0],
    ],
    [
      [197351, 556810],
      [-6, -4998],
      [-770, -161],
      [-356, -670],
      [-49, -3204],
      [356, -6],
      [-61, -6487],
    ],
    [
      [196465, 541284],
      [-1055, 22],
    ],
    [
      [195410, 541306],
      [-1057, 8],
      [-1, -1068],
    ],
    [
      [194352, 540246],
      [-993, 3],
    ],
    [
      [193359, 540249],
      [-236, 2014],
      [209, 1754],
      [-4, 5386],
      [-1034, 1610],
      [0, 1620],
      [-238, 1073],
    ],
    [
      [208196, 552551],
      [218, -2029],
      [102, -3785],
      [-297, -2572],
      [-534, -806],
    ],
    [
      [207685, 543359],
      [-1181, 12],
      [-117, 1073],
      [-355, 1],
      [0, 1079],
      [-356, 1],
      [0, 3802],
      [-227, 2],
    ],
    [
      [157999, 553531],
      [-253, -1157],
      [151, 3089],
      [102, -1932],
    ],
    [
      [161687, 557066],
      [-282, -523],
      [-596, -2846],
      [-98, -1164],
      [460, -3838],
      [-225, -1131],
    ],
    [
      [160946, 547564],
      [-1160, 1608],
      [-293, -321],
    ],
    [
      [159493, 548851],
      [-540, 718],
      [-133, 1180],
      [-534, 2],
      [-231, 1165],
    ],
    [
      [158055, 551916],
      [258, 528],
      [-269, 4233],
      [225, 530],
      [-269, 1061],
      [117, 2164],
    ],
    [
      [190869, 553699],
      [1, -1066],
      [-358, 1],
      [-13, -4900],
      [354, -2],
      [-21, -5066],
    ],
    [
      [190832, 542666],
      [-725, 2921],
      [-441, 463],
      [-28, 1539],
      [-216, -1426],
      [-1061, -216],
      [1, -1610],
      [-331, -2],
    ],
    [
      [227553, 558461],
      [-3, -6470],
      [62, -1591],
    ],
    [
      [227612, 550400],
      [-709, 17],
    ],
    [
      [226903, 550417],
      [-711, 10],
    ],
    [
      [226192, 550427],
      [-72, 1593],
      [6, 6445],
    ],
    [
      [226192, 550427],
      [-1411, -1],
    ],
    [
      [224781, 550426],
      [-88, 1602],
    ],
    [
      [224693, 552028],
      [-3, 4852],
    ],
    [
      [229285, 555257],
      [53, -4884],
    ],
    [
      [229338, 550373],
      [-1726, 27],
    ],
    [
      [156465, 557234],
      [-98, -1600],
    ],
    [
      [156985, 553471],
      [-2, -837],
    ],
    [
      [156983, 552634],
      [-349, -1455],
      [-54, -1446],
      [-357, -1953],
    ],
    [
      [156223, 547780],
      [-354, -250],
    ],
    [
      [155869, 547530],
      [-801, -41],
      [-42, 8097],
    ],
    [
      [182993, 557119],
      [1, -7838],
      [454, 9],
      [0, -6466],
    ],
    [
      [183448, 542824],
      [-493, -548],
      [1, -1075],
      [-524, -539],
      [1, -1072],
      [-447, 49],
    ],
    [
      [181986, 539639],
      [-1407, -5],
    ],
    [
      [180579, 539634],
      [-732, -499],
    ],
    [
      [179847, 539135],
      [-222, 1916],
    ],
    [
      [179625, 541051],
      [352, 195],
      [-48, 4028],
      [320, 550],
      [-90, 1379],
      [-636, 2137],
      [-238, 1593],
    ],
    [
      [186213, 535170],
      [-690, -2887],
    ],
    [
      [185523, 532283],
      [-1333, 17],
    ],
    [
      [184190, 532300],
      [-6, 8737],
      [-214, -420],
      [-522, 2207],
    ],
    [
      [198523, 543184],
      [180, -1780],
    ],
    [
      [198703, 541404],
      [-2238, -120],
    ],
    [
      [164865, 541056],
      [-1495, 18],
      [1, 1609],
      [-349, 24],
      [-1, 1604],
      [-1091, -12],
      [-177, 1484],
      [-533, 1821],
      [-274, -40],
    ],
    [
      [224693, 552028],
      [-2138, 13],
    ],
    [
      [217008, 550893],
      [-1, -451],
      [-1411, -2],
      [0, -4839],
    ],
    [
      [215596, 545601],
      [-928, -4],
    ],
    [
      [214668, 545597],
      [-132, 545],
    ],
    [
      [155869, 547530],
      [120, -1651],
      [-5, -3776],
    ],
    [
      [155984, 542103],
      [-587, -24],
    ],
    [
      [155397, 542079],
      [-2025, 38],
    ],
    [
      [153372, 542117],
      [-110, 2053],
      [253, -202],
      [623, 1195],
      [-914, 1191],
      [-230, 4847],
      [-241, 1362],
      [-96, 3330],
    ],
    [
      [232906, 548769],
      [-1437, 4],
    ],
    [
      [231469, 548773],
      [-2, 6470],
    ],
    [
      [231469, 548773],
      [-356, -11],
    ],
    [
      [231113, 548762],
      [-1799, -4],
    ],
    [
      [229314, 548758],
      [24, 1615],
    ],
    [
      [179625, 541051],
      [-761, 2278],
      [-31, 1371],
    ],
    [
      [237854, 546516],
      [-2, -4237],
    ],
    [
      [237852, 542279],
      [-1578, 50],
      [42, -9604],
      [-40, -223],
    ],
    [
      [236276, 532502],
      [-231, 1006],
      [-307, -538],
      [-332, 854],
    ],
    [
      [235406, 533824],
      [-216, 372],
    ],
    [
      [235190, 534196],
      [-133, 486],
      [-22, 7620],
    ],
    [
      [235035, 542302],
      [353, -11],
      [-27, 11293],
    ],
    [
      [310206, 521389],
      [-674, -758],
    ],
    [
      [309532, 520631],
      [-1071, -1126],
      [-11, 14936],
      [-1066, 258],
    ],
    [
      [307384, 534699],
      [-8, 3291],
      [-2505, 26],
    ],
    [
      [304871, 538016],
      [-841, 2],
    ],
    [
      [304030, 538018],
      [72, 2263],
      [2153, 14228],
      [505, -616],
      [6, -3382],
      [393, -1252],
      [815, 1288],
      [76, 747],
      [564, 5],
      [41, 1253],
      [358, 12],
      [783, -2953],
      [455, -2410],
      [25, -20921],
      [-70, -4891],
    ],
    [
      [193359, 540249],
      [-620, 1062],
      [-850, -749],
    ],
    [
      [191889, 540562],
      [-514, -598],
      [-543, 2702],
    ],
    [
      [175013, 546365],
      [-1409, 188],
      [-569, 1764],
    ],
    [
      [173035, 548317],
      [-1, 2459],
    ],
    [
      [235035, 542302],
      [-1048, 14],
    ],
    [
      [233987, 542316],
      [-16, 6473],
    ],
    [
      [160946, 547564],
      [-215, -3053],
      [-185, -928],
      [189, -1670],
    ],
    [
      [160735, 541913],
      [-845, 11],
      [-206, -1040],
      [-1032, 654],
    ],
    [
      [158652, 541538],
      [-352, 1507],
      [-447, 420],
      [-411, 2054],
      [-166, 2243],
    ],
    [
      [157276, 547762],
      [314, 1630],
      [142, 2452],
      [323, 72],
    ],
    [
      [157679, 553460],
      [-93, -2779],
      [-243, 609],
      [127, 2172],
    ],
    [
      [157450, 553461],
      [-258, -1218],
      [-95, -3180],
      [-196, 1415],
      [82, 2156],
    ],
    [
      [209241, 552096],
      [0, -12823],
    ],
    [
      [209241, 539273],
      [-860, 0],
      [-178, 810],
      [-527, 0],
    ],
    [
      [207676, 540083],
      [9, 3276],
    ],
    [
      [212063, 545588],
      [-553, -13],
      [-2, -6518],
    ],
    [
      [211508, 539057],
      [-1052, -1],
    ],
    [
      [210456, 539056],
      [-1, 6519],
      [-160, -2],
      [1, 6508],
    ],
    [
      [210456, 539056],
      [-534, -4],
      [0, -1652],
      [-681, 3],
    ],
    [
      [209241, 537403],
      [0, 1870],
    ],
    [
      [221958, 552037],
      [-1, -6447],
      [97, 4],
      [-9, -6501],
    ],
    [
      [222045, 539093],
      [-1299, 23],
    ],
    [
      [220746, 539116],
      [-462, 8],
    ],
    [
      [220284, 539124],
      [18, 6480],
      [-110, 4],
      [5, 6433],
    ],
    [
      [224781, 550426],
      [0, -4857],
      [78, 2],
      [0, -6489],
    ],
    [
      [224859, 539082],
      [-1664, -10],
    ],
    [
      [223195, 539072],
      [-1150, 21],
    ],
    [
      [220284, 539124],
      [-1617, 18],
    ],
    [
      [218667, 539142],
      [-333, 973],
      [-96, 2185],
      [-334, 3330],
    ],
    [
      [217904, 545630],
      [153, 619],
      [-235, 2617],
    ],
    [
      [217904, 545630],
      [-2308, -29],
    ],
    [
      [170472, 550797],
      [5, -6432],
      [-150, -2317],
      [-550, -959],
    ],
    [
      [169777, 541089],
      [-3231, -31],
    ],
    [
      [173035, 548317],
      [0, -10901],
    ],
    [
      [173035, 537416],
      [-1, -2155],
    ],
    [
      [173034, 535261],
      [-435, -110],
      [-89, 788],
    ],
    [
      [172510, 535939],
      [-70, 1545],
      [-616, 2886],
      [-769, -192],
      [-280, -1214],
    ],
    [
      [170775, 538964],
      [-894, -1263],
      [-120, 595],
    ],
    [
      [169761, 538296],
      [16, 2793],
    ],
    [
      [226903, 550417],
      [68, -4868],
      [-1, -6487],
    ],
    [
      [226970, 539062],
      [-981, 13],
    ],
    [
      [225989, 539075],
      [-1130, 7],
    ],
    [
      [229314, 548758],
      [6, -2652],
      [212, -1371],
      [-110, -2548],
      [10, -3117],
    ],
    [
      [229432, 539070],
      [-1342, -24],
    ],
    [
      [228090, 539046],
      [-1120, 16],
    ],
    [
      [158652, 541538],
      [-2669, 23],
      [1, 542],
    ],
    [
      [156223, 547780],
      [371, 1366],
      [-165, -1818],
      [270, 11],
      [248, 1911],
      [329, -1488],
    ],
    [
      [207676, 540083],
      [-352, -1344],
    ],
    [
      [207324, 538739],
      [-349, -4],
      [-351, -1338],
      [-710, 7],
      [-585, 557],
      [-127, 1621],
      [-225, -2],
      [-117, 3243],
      [-599, 530],
      [-707, -5],
    ],
    [
      [233987, 542316],
      [-1, -1623],
    ],
    [
      [233986, 540693],
      [-2817, 4],
    ],
    [
      [231169, 540697],
      [-56, 8065],
    ],
    [
      [231169, 540697],
      [7, -1623],
      [-303, 3],
    ],
    [
      [230873, 539077],
      [-1399, -8],
    ],
    [
      [229474, 539069],
      [-42, 1],
    ],
    [
      [175013, 544728],
      [1, -5708],
      [-353, 21],
    ],
    [
      [174661, 539041],
      [-461, -3],
      [-274, -1604],
      [-891, -18],
    ],
    [
      [165141, 539034],
      [20, -10944],
    ],
    [
      [165161, 528090],
      [-2024, 0],
      [-2593, 63],
    ],
    [
      [160544, 528153],
      [0, 6416],
    ],
    [
      [160544, 534569],
      [359, 11],
      [-157, 2684],
      [251, 3313],
      [-262, 1336],
    ],
    [
      [191889, 540562],
      [-24, -9125],
    ],
    [
      [191865, 531437],
      [0, -675],
    ],
    [
      [191865, 530762],
      [-1399, 153],
    ],
    [
      [190466, 530915],
      [-777, 9],
    ],
    [
      [189689, 530924],
      [-178, 1515],
      [195, 2306],
      [-474, 569],
      [-329, 2182],
      [-426, 4031],
    ],
    [
      [239843, 541603],
      [18, -6465],
    ],
    [
      [239861, 535138],
      [2, -4863],
    ],
    [
      [239863, 530275],
      [-1052, -76],
    ],
    [
      [238811, 530199],
      [4, 1716],
      [-1019, -55],
    ],
    [
      [237796, 531860],
      [-43, 6341],
      [94, 109],
      [5, 3969],
    ],
    [
      [251183, 535146],
      [2, -1610],
    ],
    [
      [251185, 533536],
      [-1045, -5],
      [1, 3214],
      [-1046, -7],
      [1, 1617],
      [-346, 13],
      [-67, 3221],
    ],
    [
      [248683, 541589],
      [281, 995],
      [1035, 453],
      [493, 1189],
      [335, 1615],
      [517, 690],
    ],
    [
      [251344, 546531],
      [2, -4943],
      [190, -1609],
      [-348, 15],
      [-5, -4848],
    ],
    [
      [214668, 545597],
      [3, -4901],
    ],
    [
      [214671, 540696],
      [-1, -1616],
    ],
    [
      [214670, 539080],
      [-2316, -18],
    ],
    [
      [212354, 539062],
      [-846, -5],
    ],
    [
      [218667, 539142],
      [262, -751],
      [-62, -3093],
    ],
    [
      [218867, 535298],
      [-369, -884],
      [-548, 257],
      [-360, -2039],
    ],
    [
      [217590, 532632],
      [0, 1615],
      [-698, 1],
      [0, 4816],
      [-1166, 16],
      [-1, 1620],
      [-1054, -4],
    ],
    [
      [253820, 544308],
      [0, -2728],
      [148, -1606],
      [-346, -13],
      [-2, -4817],
    ],
    [
      [253620, 535144],
      [-1568, 10],
    ],
    [
      [252052, 535154],
      [1, 8035],
      [354, 0],
      [-1, 1615],
      [281, 8],
    ],
    [
      [252687, 544812],
      [-46, -3487],
      [634, 3347],
      [545, -364],
    ],
    [
      [179847, 539135],
      [-2885, -2989],
      [-517, -3856],
      [-988, 1959],
    ],
    [
      [175457, 534249],
      [-555, 1774],
    ],
    [
      [174902, 536023],
      [-222, 561],
      [-19, 2457],
    ],
    [
      [207324, 538739],
      [0, -2429],
      [-481, -1],
      [56, -6431],
    ],
    [
      [206899, 529878],
      [-149, -3],
      [0, -6510],
      [-119, 1],
    ],
    [
      [206631, 523366],
      [-3371, 30],
    ],
    [
      [203260, 523396],
      [2, 6487],
      [106, 4],
      [-6, 6446],
      [97, 2],
      [-1, 6474],
      [96, 273],
    ],
    [
      [203260, 523396],
      [-118, -5],
      [-3, -8127],
      [-123, -27],
      [0, -3173],
    ],
    [
      [203016, 512064],
      [-1361, -4],
      [-1, 3224],
      [108, -22],
      [0, 5983],
      [-512, 206],
      [100, 2748],
      [-171, 715],
    ],
    [
      [201179, 524914],
      [0, 4963],
      [-191, -49],
      [-58, 4866],
      [-348, 2],
      [-60, 1616],
      [-1571, -11],
    ],
    [
      [198951, 536301],
      [-119, 265],
    ],
    [
      [198832, 536566],
      [-129, 4838],
    ],
    [
      [184190, 532300],
      [-1, -814],
      [-687, -6],
      [15, -3299],
      [-666, -1967],
    ],
    [
      [182851, 526214],
      [-255, 180],
      [-220, 1599],
      [-323, 48],
      [141, 1392],
      [-197, 1673],
      [207, 1275],
      [-352, 4420],
      [134, 2838],
    ],
    [
      [235190, 534196],
      [-1183, 4],
    ],
    [
      [234007, 534200],
      [-21, 6493],
    ],
    [
      [237796, 531860],
      [-40, -1612],
    ],
    [
      [237756, 530248],
      [-1572, 8],
      [92, 2246],
    ],
    [
      [155397, 542079],
      [36, -7597],
    ],
    [
      [155433, 534482],
      [-1024, 26],
      [-6, -1764],
    ],
    [
      [154403, 532744],
      [-413, -941],
      [-401, 1402],
      [-173, -855],
      [164, 5828],
      [40, -3886],
      [154, 66],
      [163, 3197],
      [-178, 1201],
      [335, 1945],
      [-445, -237],
      [-277, 1653],
    ],
    [
      [160544, 534569],
      [-1998, -62],
    ],
    [
      [158546, 534507],
      [-2722, -5],
    ],
    [
      [155824, 534502],
      [-391, -20],
    ],
    [
      [189689, 530924],
      [-790, -90],
      [-193, -3456],
      [-681, -3145],
    ],
    [
      [188025, 524233],
      [-2, 4020],
      [-344, 10],
      [-10, 9662],
    ],
    [
      [241986, 539680],
      [-2, -4580],
    ],
    [
      [241984, 535100],
      [-2123, 38],
    ],
    [
      [251185, 533536],
      [-1, -4392],
    ],
    [
      [251184, 529144],
      [-281, 766],
      [-2333, 3002],
    ],
    [
      [248570, 532912],
      [1, -217],
    ],
    [
      [248571, 532695],
      [-1, 217],
    ],
    [
      [248570, 532912],
      [-533, 692],
      [-150, 1914],
    ],
    [
      [247887, 535518],
      [-158, 1305],
    ],
    [
      [247729, 536823],
      [-232, 120],
    ],
    [
      [247497, 536943],
      [-117, 450],
    ],
    [
      [247380, 537393],
      [-170, 465],
    ],
    [
      [247210, 537858],
      [1092, 2058],
      [381, 1673],
    ],
    [
      [198832, 536566],
      [-2, -1875],
      [-409, 1],
      [-280, -2448],
      [-812, 5],
      [-234, -521],
      [0, -1926],
      [-1045, -3],
    ],
    [
      [196050, 529799],
      [2, 2685],
      [-229, 1641],
      [-121, 3955],
      [-233, 23],
      [-59, 3203],
    ],
    [
      [261640, 541399],
      [0, -9499],
    ],
    [
      [261640, 531900],
      [-1747, -14],
    ],
    [
      [259893, 531886],
      [0, 4842],
    ],
    [
      [259893, 536728],
      [1, 3448],
    ],
    [
      [259894, 540176],
      [1066, -173],
      [680, 1396],
    ],
    [
      [196050, 529799],
      [-402, -5],
    ],
    [
      [195648, 529794],
      [-1369, 1],
      [-6, -1634],
    ],
    [
      [194273, 528161],
      [-522, 16],
      [-137, 3239],
    ],
    [
      [193614, 531416],
      [699, 2],
      [39, 8828],
    ],
    [
      [193614, 531416],
      [-1749, 21],
    ],
    [
      [169761, 538296],
      [-37, 88],
    ],
    [
      [169724, 538384],
      [-623, 62],
      [-454, -1721],
      [-56, -1735],
      [-390, -2246],
      [-741, -1821],
    ],
    [
      [167460, 530923],
      [-633, 1584],
      [-9, 4567],
      [-505, 2903],
    ],
    [
      [167460, 530923],
      [206, -1170],
      [-54, -2423],
    ],
    [
      [167612, 527330],
      [-388, -1247],
      [-851, -272],
    ],
    [
      [166373, 525811],
      [-390, 134],
      [-275, -1277],
      [-551, -390],
    ],
    [
      [165157, 524278],
      [4, 3812],
    ],
    [
      [234007, 534200],
      [29, -4880],
    ],
    [
      [234036, 529320],
      [-1739, 14],
    ],
    [
      [232297, 529334],
      [-1380, 20],
    ],
    [
      [230917, 529354],
      [-44, 9723],
    ],
    [
      [217590, 532632],
      [-397, -2552],
      [-436, -1343],
      [-920, -1191],
      [-478, 1151],
      [-416, -370],
    ],
    [
      [214943, 528327],
      [1, 2829],
    ],
    [
      [214944, 531156],
      [-135, 1457],
      [-12, 6465],
      [-127, 2],
    ],
    [
      [172510, 535939],
      [0, -949],
      [-531, -522],
      [0, -4866],
      [-168, 2],
      [-1, -2312],
    ],
    [
      [171810, 527292],
      [-342, 20],
    ],
    [
      [171468, 527312],
      [-26, 6322],
      [-175, -16],
      [-173, 2490],
      [-351, -8],
      [32, 2864],
    ],
    [
      [209241, 537403],
      [-2, -4854],
    ],
    [
      [209239, 532549],
      [2, -6235],
    ],
    [
      [209241, 526314],
      [3, -1180],
    ],
    [
      [209244, 525134],
      [-252, 2],
      [0, 1618],
      [-714, 26],
      [2, 1575],
      [-351, -6],
      [0, 1522],
      [-1030, 7],
    ],
    [
      [180579, 539634],
      [-29, -2628],
      [-262, -4539],
      [-67, -3326],
      [-131, -1223],
      [266, -995],
      [5, -2349],
      [-249, -34],
      [-187, -1420],
      [189, -1949],
      [-181, -2072],
    ],
    [
      [179933, 519099],
      [-282, -1616],
      [-344, 766],
      [-14, -1410],
      [527, -2617],
      [-250, -1838],
    ],
    [
      [179570, 512384],
      [-3571, -27],
      [-470, -1640],
    ],
    [
      [175529, 510717],
      [-390, 422],
      [-163, 2564],
      [-956, -4],
    ],
    [
      [174020, 513699],
      [362, 4439],
      [258, 2019],
      [-236, 2563],
      [-686, 1930],
    ],
    [
      [173718, 524650],
      [258, 2608],
    ],
    [
      [173976, 527258],
      [446, 46],
      [76, 2435],
      [240, 1363],
      [665, -892],
      [157, 1127],
      [335, 203],
      [-438, 2709],
    ],
    [
      [182851, 526214],
      [3, -24],
    ],
    [
      [182854, 526190],
      [-34, -1070],
      [-471, -700],
      [-670, -2752],
    ],
    [
      [181679, 521668],
      [-944, -3979],
      [-194, -400],
      [-349, 1946],
      [-259, -136],
    ],
    [
      [220746, 539116],
      [-2, -6512],
      [105, -7],
    ],
    [
      [220849, 532597],
      [-3, -6355],
    ],
    [
      [220846, 526242],
      [-1761, 43],
    ],
    [
      [219085, 526285],
      [-327, 3734],
      [198, 1955],
      [-89, 3324],
    ],
    [
      [223195, 539072],
      [-2, -6466],
    ],
    [
      [223193, 532606],
      [-2344, -9],
    ],
    [
      [174902, 536023],
      [1, -2306],
      [-958, -326],
      [31, -6133],
    ],
    [
      [173718, 524650],
      [-339, 2599],
    ],
    [
      [173379, 527249],
      [-122, 1925],
      [104, 1310],
      [-372, 3279],
      [45, 1498],
    ],
    [
      [212354, 539062],
      [9, -6489],
    ],
    [
      [212363, 532573],
      [-197, -14],
    ],
    [
      [212166, 532559],
      [-2927, -10],
    ],
    [
      [225989, 539075],
      [-2, -6478],
    ],
    [
      [225987, 532597],
      [-2698, 1],
    ],
    [
      [223289, 532598],
      [-96, 8],
    ],
    [
      [214944, 531156],
      [-1391, 13],
      [0, 1436],
      [-1190, -32],
    ],
    [
      [230917, 529354],
      [-1, -1621],
    ],
    [
      [230916, 527733],
      [-866, 4],
    ],
    [
      [230050, 527737],
      [45, 1766],
      [-114, 3993],
      [-380, 2928],
      [-127, 2645],
    ],
    [
      [228090, 539046],
      [-2, -6457],
    ],
    [
      [228088, 532589],
      [-2023, 4],
    ],
    [
      [226065, 532593],
      [-78, 4],
    ],
    [
      [230050, 527737],
      [35, -1611],
    ],
    [
      [230085, 526126],
      [-1852, -1],
    ],
    [
      [228233, 526125],
      [-87, 1154],
      [-58, 5310],
    ],
    [
      [171468, 527312],
      [-1045, 34],
    ],
    [
      [170423, 527346],
      [-53, 1],
    ],
    [
      [170370, 527347],
      [16, 3854],
      [-350, 1],
      [2, 1631],
      [-349, -8],
      [35, 5559],
    ],
    [
      [170370, 527347],
      [-2758, -17],
    ],
    [
      [247207, 537862],
      [3, -4],
    ],
    [
      [248571, 532695],
      [0, -5698],
      [-321, -1],
    ],
    [
      [248250, 526996],
      [-719, -11],
    ],
    [
      [247531, 526985],
      [-3, 3230],
      [-343, -15],
      [1, 1609],
      [-352, 7],
      [6, 6374],
    ],
    [
      [246840, 538190],
      [367, -328],
    ],
    [
      [304871, 538016],
      [-24, -11134],
      [233, -2084],
      [-240, -2015],
      [-258, -234],
      [344, -2135],
      [-221, -1593],
      [445, -9902],
      [739, 1165],
    ],
    [
      [305889, 510084],
      [248, -6545],
    ],
    [
      [306137, 503539],
      [-330, 592],
      [-241, -1132],
    ],
    [
      [305566, 502999],
      [-450, 139],
      [79, -2294],
      [-907, 625],
    ],
    [
      [304288, 501469],
      [-183, 1244],
      [-102, 3478],
      [-276, -236],
      [62, 1778],
      [-139, 3374],
      [-376, -344],
      [-347, 651],
      [-372, 9721],
    ],
    [
      [302555, 521135],
      [466, 1247],
      [-87, 1101],
      [431, 1781],
      [-152, 2424],
      [219, 2371],
      [-153, 838],
      [286, 2948],
      [372, 1242],
      [93, 2931],
    ],
    [
      [307384, 534699],
      [-16, -13241],
      [-374, -415],
      [-14, -2790],
      [297, 274],
      [227, -5338],
      [-292, -284],
      [68, -1531],
      [-1391, -1290],
    ],
    [
      [188025, 524233],
      [-403, -667],
    ],
    [
      [187622, 523566],
      [-350, 1101],
      [-490, -286],
      [-233, -1732],
    ],
    [
      [186549, 522649],
      [-588, 1549],
      [-293, 6520],
      [-198, -55],
    ],
    [
      [185470, 530663],
      [53, 1620],
    ],
    [
      [259893, 531886],
      [-1, -5144],
    ],
    [
      [259892, 526742],
      [-133, -900],
      [-413, 823],
      [-698, -828],
      [-156, -2487],
      [-258, -538],
    ],
    [
      [258234, 522812],
      [-83, 2590],
      [0, 4873],
      [-349, 11],
    ],
    [
      [257802, 530286],
      [0, 3251],
      [697, -26],
      [0, 3238],
      [1394, -21],
    ],
    [
      [198951, 536301],
      [351, -3235],
      [408, -2428],
      [-126, -2500],
    ],
    [
      [199584, 528138],
      [-454, 7],
      [0, -1079],
      [-345, 0],
      [-233, -1614],
      [-461, 6],
      [-62, -7075],
      [-689, -1094],
      [-914, 66],
    ],
    [
      [196426, 517355],
      [-551, 2736],
    ],
    [
      [195875, 520091],
      [-174, 1330],
      [10, 5159],
      [-63, 3214],
    ],
    [
      [201179, 524914],
      [-661, 1614],
      [-693, 2],
      [0, 1610],
      [-241, -2],
    ],
    [
      [173379, 527249],
      [-1569, 43],
    ],
    [
      [219085, 526285],
      [-4142, 17],
    ],
    [
      [214943, 526302],
      [0, 2025],
    ],
    [
      [253620, 535144],
      [-3, -3220],
    ],
    [
      [253617, 531924],
      [2, -6051],
    ],
    [
      [253619, 525873],
      [-776, 1095],
    ],
    [
      [252843, 526968],
      [-385, 757],
      [-418, -132],
    ],
    [
      [252040, 527593],
      [-694, 1110],
    ],
    [
      [251346, 528703],
      [-162, 441],
    ],
    [
      [251183, 535146],
      [869, 8],
    ],
    [
      [241984, 535100],
      [-2, -4840],
    ],
    [
      [241982, 530260],
      [-158, -2623],
      [-854, -1719],
    ],
    [
      [240970, 525918],
      [-513, -3612],
    ],
    [
      [240457, 522306],
      [-838, 12],
    ],
    [
      [239619, 522318],
      [-5, 4660],
      [247, 5],
      [2, 3292],
    ],
    [
      [309532, 520631],
      [304, -6938],
      [-325, -226],
    ],
    [
      [309511, 513467],
      [-690, -626],
      [93, -2259],
      [-353, -313],
      [61, -1593],
      [-355, -304],
      [157, -4050],
      [-1023, -1483],
    ],
    [
      [307401, 502839],
      [-7, -12],
    ],
    [
      [307394, 502827],
      [-61, 341],
      [-964, -1079],
      [-232, 1450],
    ],
    [
      [160544, 528153],
      [-247, -4],
      [10, -4864],
      [241, -1079],
    ],
    [
      [160548, 522206],
      [-796, -345],
      [-318, -1062],
    ],
    [
      [159434, 520799],
      [-910, -1886],
    ],
    [
      [158524, 518913],
      [9, 9424],
    ],
    [
      [158533, 528337],
      [13, 6170],
    ],
    [
      [158533, 528337],
      [-318, -1795],
      [-454, 494],
      [-602, -1308],
      [-130, -1178],
    ],
    [
      [157029, 524550],
      [-330, 4339],
      [-600, 1896],
      [-259, -235],
    ],
    [
      [155840, 530550],
      [-2, 54],
    ],
    [
      [155838, 530604],
      [-14, 3898],
    ],
    [
      [155838, 530604],
      [-183, -462],
      [-495, 2189],
      [-757, 413],
    ],
    [
      [235406, 533824],
      [28, -10701],
    ],
    [
      [235434, 523123],
      [-1381, -10],
    ],
    [
      [234053, 523113],
      [-17, 6207],
    ],
    [
      [237756, 530248],
      [0, -3231],
      [116, -9],
      [14, -2987],
    ],
    [
      [237886, 524021],
      [-1641, -2],
      [226, -872],
    ],
    [
      [236471, 523147],
      [-1037, -24],
    ],
    [
      [251026, 525384],
      [-2773, 33],
    ],
    [
      [248253, 525417],
      [-3, 1579],
    ],
    [
      [251346, 528703],
      [-2, -1704],
      [-318, 2],
      [0, -1617],
    ],
    [
      [155418, 529962],
      [8, -6731],
    ],
    [
      [155426, 523231],
      [-1689, 63],
    ],
    [
      [153737, 523294],
      [-68, 3037],
      [184, 1180],
      [-262, 4093],
      [309, -882],
      [393, 425],
      [493, -351],
      [259, 901],
      [373, -1735],
    ],
    [
      [214943, 526302],
      [-5, -2],
    ],
    [
      [214938, 526300],
      [-2623, 9],
    ],
    [
      [212315, 526309],
      [-150, 2],
    ],
    [
      [212165, 526311],
      [1, 6248],
    ],
    [
      [228233, 526125],
      [-2090, 12],
    ],
    [
      [226143, 526137],
      [-83, 1],
    ],
    [
      [226060, 526138],
      [5, 6455],
    ],
    [
      [223289, 532598],
      [-7, -6387],
    ],
    [
      [223282, 526211],
      [-1986, 21],
    ],
    [
      [221296, 526232],
      [-450, 10],
    ],
    [
      [226060, 526138],
      [-1995, 52],
    ],
    [
      [224065, 526190],
      [-783, 21],
    ],
    [
      [212165, 526311],
      [-2924, 3],
    ],
    [
      [185470, 530663],
      [-558, -802],
      [1, -1628],
      [-449, -1027],
      [40, -1093],
      [-451, -1375],
    ],
    [
      [184053, 524738],
      [-389, 163],
      [-476, -1895],
      [-334, 3184],
    ],
    [
      [255011, 527072],
      [0, -1623],
      [-222, 0],
      [-2, -3274],
      [-416, -8],
    ],
    [
      [254371, 522167],
      [-587, 1081],
    ],
    [
      [253784, 523248],
      [-213, 794],
      [48, 1831],
    ],
    [
      [253617, 531924],
      [1399, -8],
      [-5, -4844],
    ],
    [
      [238811, 530199],
      [-2, -3213],
      [-238, 7],
      [11, -4605],
    ],
    [
      [238582, 522388],
      [9, -3269],
    ],
    [
      [238591, 519119],
      [-694, 16],
    ],
    [
      [237897, 519135],
      [-11, 4886],
    ],
    [
      [194273, 528161],
      [-16, -1570],
      [-232, -20],
      [-119, -1641],
      [-35, -4874],
      [-1029, -1611],
      [-3, -3241],
      [-365, 3],
      [-1, -3287],
    ],
    [
      [192473, 511920],
      [-459, 14],
      [21, 11394],
      [-200, 2],
      [30, 7432],
    ],
    [
      [190466, 530915],
      [-30, -11211],
      [-346, -1209],
      [4, -3242],
      [-336, 0],
      [-17, -6512],
    ],
    [
      [189741, 508741],
      [-32, -6238],
    ],
    [
      [189709, 502503],
      [19, -3579],
    ],
    [
      [189728, 498924],
      [-493, 1905],
      [18, 850],
      [-441, 2415],
    ],
    [
      [188812, 504094],
      [13, 11149],
      [64, -1],
      [-3, 5424],
      [-518, 4],
      [-341, 1882],
      [-405, 1014],
    ],
    [
      [192473, 511920],
      [739, -89],
    ],
    [
      [193212, 511831],
      [-1, -3075],
    ],
    [
      [193211, 508756],
      [-2525, -184],
      [-945, 169],
    ],
    [
      [186549, 522649],
      [-756, -104],
      [-628, -2190],
    ],
    [
      [185165, 520355],
      [-202, 2288],
      [-910, 2095],
    ],
    [
      [157029, 524550],
      [63, -2277],
    ],
    [
      [157092, 522273],
      [-463, -110],
    ],
    [
      [156629, 522163],
      [-284, 1059],
      [-919, 9],
    ],
    [
      [155418, 529962],
      [422, 588],
    ],
    [
      [239619, 522318],
      [-1037, 70],
    ],
    [
      [242661, 530268],
      [51, -9640],
    ],
    [
      [242712, 520628],
      [-343, -3],
    ],
    [
      [242369, 520625],
      [1, 1598],
      [-1042, 59],
      [-2, -1612],
      [-997, 38],
    ],
    [
      [240329, 520708],
      [128, 1598],
    ],
    [
      [241982, 530260],
      [679, 8],
    ],
    [
      [245796, 530213],
      [-1, -3233],
      [691, 9],
    ],
    [
      [246486, 526989],
      [-5, -6392],
    ],
    [
      [246481, 520597],
      [-2401, -12],
    ],
    [
      [244080, 520585],
      [-30, 9671],
    ],
    [
      [244050, 530256],
      [1746, -43],
    ],
    [
      [244080, 520585],
      [-1368, 43],
    ],
    [
      [242661, 530268],
      [1389, -12],
    ],
    [
      [209244, 525134],
      [12, -12455],
    ],
    [
      [209256, 512679],
      [-50, -4012],
    ],
    [
      [209206, 508667],
      [-2732, 55],
    ],
    [
      [206474, 508722],
      [1, 6547],
      [142, -1],
      [14, 8098],
    ],
    [
      [195875, 520091],
      [-795, -463],
      [-177, -2270],
      [-459, 3],
      [-573, -1888],
      [-130, -1894],
      [-228, 1],
      [-3, -1752],
      [-298, 3],
    ],
    [
      [232297, 529334],
      [32, -6470],
    ],
    [
      [232329, 522864],
      [-1381, -3],
    ],
    [
      [230948, 522861],
      [-32, 4872],
    ],
    [
      [234053, 523113],
      [0, -269],
    ],
    [
      [234053, 522844],
      [-1724, 20],
    ],
    [
      [252040, 527593],
      [20, -5420],
      [700, -8],
    ],
    [
      [252760, 522165],
      [-8, -6432],
    ],
    [
      [252752, 515733],
      [-696, 32],
    ],
    [
      [252056, 515765],
      [-692, -1],
      [9, 1610],
      [-345, -7],
    ],
    [
      [251028, 517367],
      [-2, 8017],
    ],
    [
      [158524, 518913],
      [-1068, 1088],
      [-329, 768],
      [-35, 1504],
    ],
    [
      [165157, 524278],
      [-366, -437],
    ],
    [
      [164791, 523841],
      [-600, -1643],
      [-745, -544],
      [-478, 786],
    ],
    [
      [162968, 522440],
      [-730, -1784],
    ],
    [
      [162238, 520656],
      [-782, -457],
      [-46, 928],
      [-635, 569],
    ],
    [
      [160775, 521696],
      [-227, 510],
    ],
    [
      [203016, 512064],
      [45, -3465],
    ],
    [
      [203061, 508599],
      [-4592, 141],
    ],
    [
      [198469, 508740],
      [-941, -34],
    ],
    [
      [197528, 508706],
      [337, 4066],
      [-1591, 0],
      [48, 4006],
      [104, 577],
    ],
    [
      [230948, 522861],
      [3, -3246],
    ],
    [
      [230951, 519615],
      [-1623, 13],
    ],
    [
      [229328, 519628],
      [-7, 1157],
      [513, 1813],
      [251, 3528],
    ],
    [
      [253784, 523248],
      [-5, -1258],
      [-1019, 175],
    ],
    [
      [170423, 527346],
      [14, -2610],
    ],
    [
      [170437, 524736],
      [-401, -3204],
      [0, -4052],
      [-226, -813],
      [-642, 33],
      [1, -1372],
      [-752, -187],
      [118, -2760],
      [304, 1],
      [77, -3742],
    ],
    [
      [168916, 508640],
      [-1798, -1],
    ],
    [
      [167118, 508639],
      [49, 1621],
      [4, 8063],
      [-288, 1],
      [0, 1596],
      [-518, 16],
      [8, 5875],
    ],
    [
      [174020, 513699],
      [-270, -3512],
    ],
    [
      [173750, 510187],
      [-1345, 32],
    ],
    [
      [172405, 510219],
      [-2, 1583],
      [-584, -9],
      [-295, 3221],
      [-171, -21],
      [-111, 3312],
      [-289, 3248],
      [113, 3191],
      [-629, -8],
    ],
    [
      [255706, 527058],
      [117, -1614],
      [-5, -6481],
      [175, -7],
    ],
    [
      [255993, 518956],
      [-481, -4769],
      [-453, -2674],
      [26, -1006],
    ],
    [
      [255085, 510507],
      [-74, 93],
    ],
    [
      [255011, 510600],
      [-348, 1798],
      [263, 2651],
      [-666, 243],
      [263, 2751],
      [-117, 1110],
      [148, 2102],
      [-183, 912],
    ],
    [
      [255011, 527072],
      [695, -14],
    ],
    [
      [248253, 525417],
      [-2, -6367],
    ],
    [
      [248251, 519050],
      [4, -3225],
    ],
    [
      [248255, 515825],
      [-1774, -77],
    ],
    [
      [246481, 515748],
      [0, 4849],
    ],
    [
      [246486, 526989],
      [1045, -4],
    ],
    [
      [214938, 526300],
      [2, -8789],
    ],
    [
      [214940, 517511],
      [-1, -8089],
    ],
    [
      [214939, 509422],
      [-2667, 29],
    ],
    [
      [212272, 509451],
      [0, 3227],
    ],
    [
      [212272, 512678],
      [43, 13631],
    ],
    [
      [219085, 526285],
      [420, -1613],
      [146, -2779],
      [-339, -2104],
    ],
    [
      [219312, 519789],
      [-135, -1298],
      [382, -971],
    ],
    [
      [219559, 517520],
      [-3145, -9],
    ],
    [
      [216414, 517511],
      [-1474, 0],
    ],
    [
      [212272, 512678],
      [-3016, 1],
    ],
    [
      [221296, 526232],
      [3, -6469],
    ],
    [
      [221299, 519763],
      [-1987, 26],
    ],
    [
      [185165, 520355],
      [-3, -2744],
      [506, -650],
      [135, -1730],
      [3, -4866],
      [343, -1],
      [-3, -1637],
      [400, -13],
      [67, -3224],
      [282, -801],
      [790, -48],
      [2, -1347],
      [860, 8],
    ],
    [
      [188547, 503302],
      [-99, -3225],
      [-305, 274],
    ],
    [
      [188143, 500351],
      [-567, -772],
      [-129, 1024],
      [-637, -765],
      [-533, 835],
      [-280, -2222],
      [-238, 584],
      [-857, 90],
      [-96, -2021],
    ],
    [
      [184806, 497104],
      [-526, 1386],
      [-10, 1397],
      [-348, 4611],
      [-316, 914],
      [-262, -706],
      [-323, 1502],
      [13, 3600],
      [-287, 1010],
      [-383, 2793],
      [-233, 2701],
      [-86, 3613],
      [-272, 361],
      [-94, 1382],
    ],
    [
      [224065, 526190],
      [-1, -6467],
    ],
    [
      [224064, 519723],
      [-2739, 40],
    ],
    [
      [221325, 519763],
      [-26, 0],
    ],
    [
      [226143, 526137],
      [-2, -6467],
    ],
    [
      [226141, 519670],
      [-6, -6480],
    ],
    [
      [226135, 513190],
      [-2051, 63],
    ],
    [
      [224084, 513253],
      [-13, 1],
    ],
    [
      [224071, 513254],
      [-7, 6469],
    ],
    [
      [229328, 519628],
      [389, -3087],
      [628, -1746],
    ],
    [
      [230345, 514795],
      [-1456, 6],
      [-40, -559],
      [-610, 15],
    ],
    [
      [228239, 514257],
      [-2, 4850],
    ],
    [
      [228237, 519107],
      [-4, 7018],
    ],
    [
      [228237, 519107],
      [-408, 548],
      [-1688, 15],
    ],
    [
      [167118, 508639],
      [-1414, -28],
    ],
    [
      [165704, 508611],
      [-332, 4],
      [2, 1361],
    ],
    [
      [165374, 509976],
      [84, 1859],
      [-342, 39],
      [1, 1607],
      [-347, 28],
      [21, 10332],
    ],
    [
      [251028, 517367],
      [-1054, 48],
    ],
    [
      [249974, 517415],
      [-9, 1640],
      [-1714, -5],
    ],
    [
      [172405, 510219],
      [-826, -23],
      [-2, -1616],
      [-596, -10],
      [-301, 1240],
      [-304, -1200],
      [-378, 943],
      [-318, -1615],
    ],
    [
      [169680, 507938],
      [-764, 702],
    ],
    [
      [188812, 504094],
      [-265, -792],
    ],
    [
      [237897, 519135],
      [-1085, -11],
    ],
    [
      [236812, 519124],
      [-341, 4023],
    ],
    [
      [165374, 509976],
      [-1964, 15],
    ],
    [
      [163410, 509991],
      [-24, 269],
    ],
    [
      [163386, 510260],
      [-111, 2406],
      [145, 4765],
      [366, 767],
      [-140, 1600],
      [-678, 2642],
    ],
    [
      [206474, 508722],
      [-142, 2],
    ],
    [
      [206332, 508724],
      [-2606, -129],
    ],
    [
      [203726, 508595],
      [-665, 4],
    ],
    [
      [264518, 520373],
      [-131, -4],
      [15, -7954],
    ],
    [
      [264402, 512415],
      [-335, 1],
    ],
    [
      [264067, 512416],
      [-1024, 59],
    ],
    [
      [263043, 512475],
      [5, 1628],
    ],
    [
      [263048, 514103],
      [0, 9277],
    ],
    [
      [263048, 523380],
      [752, -2508],
      [718, -499],
    ],
    [
      [155426, 523231],
      [0, -1322],
      [-345, 8],
      [171, -1358],
      [345, -537],
      [-450, -2154],
      [-12, -1076],
    ],
    [
      [155135, 516792],
      [6, -4051],
      [-896, -4],
      [0, -2601],
      [169, -5],
    ],
    [
      [154414, 510131],
      [-4, -597],
    ],
    [
      [154410, 509534],
      [-766, 17],
    ],
    [
      [153644, 509551],
      [191, 6727],
      [-98, 7016],
    ],
    [
      [156629, 522163],
      [0, -1647],
      [515, -2132],
      [1, -1597],
    ],
    [
      [157145, 516787],
      [-1, -1893],
      [-346, -271],
    ],
    [
      [156798, 514623],
      [-744, 2159],
      [-919, 10],
    ],
    [
      [236812, 519124],
      [284, -2536],
    ],
    [
      [237096, 516588],
      [-222, -1934],
      [-374, -651],
    ],
    [
      [236500, 514003],
      [-341, 795],
      [-1058, -11],
    ],
    [
      [235101, 514787],
      [1, 1614],
      [-1027, -8],
    ],
    [
      [234075, 516393],
      [-22, 6451],
    ],
    [
      [232329, 522864],
      [30, -6471],
    ],
    [
      [232359, 516393],
      [-1030, -8],
    ],
    [
      [231329, 516385],
      [-347, 13],
      [-31, 3217],
    ],
    [
      [234075, 516393],
      [-344, 3],
    ],
    [
      [233731, 516396],
      [-1372, -3],
    ],
    [
      [163386, 510260],
      [-629, 17],
      [17, 874],
      [-376, 1203],
      [-462, 444],
      [26, 1607],
      [414, 1539],
      [112, 2268],
      [-250, 2444],
    ],
    [
      [239619, 522318],
      [0, -3212],
      [333, -17],
      [8, -2705],
    ],
    [
      [239960, 516384],
      [-1367, 53],
    ],
    [
      [238593, 516437],
      [-2, 2682],
    ],
    [
      [240329, 520708],
      [8, -1270],
      [308, -144],
      [354, -2360],
      [-276, -2707],
    ],
    [
      [240723, 514227],
      [-762, 15],
    ],
    [
      [239961, 514242],
      [-1, 2142],
    ],
    [
      [242369, 520625],
      [-6, -8009],
    ],
    [
      [242363, 512616],
      [-1675, 1],
    ],
    [
      [240688, 512617],
      [35, 1610],
    ],
    [
      [159434, 520799],
      [0, -1905],
      [284, -1586],
    ],
    [
      [159718, 517308],
      [-2288, -7],
      [-285, -514],
    ],
    [
      [160775, 521696],
      [0, -3313],
      [-116, 6],
      [3, -4865],
      [-600, -5],
    ],
    [
      [160062, 513519],
      [3, 2149],
      [-347, 1640],
    ],
    [
      [163410, 509991],
      [119, -3799],
      [225, -798],
    ],
    [
      [163754, 505394],
      [-3866, 76],
    ],
    [
      [159888, 505470],
      [69, 1165],
    ],
    [
      [159957, 506635],
      [-196, 2311],
      [405, 1008],
      [-239, 2616],
      [135, 949],
    ],
    [
      [184806, 497104],
      [3, -2717],
      [-506, 66],
    ],
    [
      [184303, 494453],
      [-906, -18],
    ],
    [
      [183397, 494435],
      [-56, 1597],
      [-348, 2544],
      [-106, -614],
      [-845, 1225],
      [-633, 3398],
      [-141, 1578],
      [-365, -189],
      [52, 2183],
      [-181, -234],
      [-402, -2430],
      [55, -905],
      [-491, -1771],
      [-716, 3192],
      [20, 1138],
    ],
    [
      [179240, 505147],
      [217, 1310],
      [9, 5102],
      [104, 825],
    ],
    [
      [309697, 499440],
      [-326, 8144],
      [343, 307],
      [-203, 5576],
    ],
    [
      [310206, 521389],
      [551, -1389],
      [510, -516],
      [-213, -1647],
      [200, -1810],
      [-163, -2048],
      [408, -2916],
      [145, 1141],
      [349, -534],
      [352, -3817],
      [161, -2744],
      [-592, -2997],
      [-880, -140],
      [-164, -1985],
      [-252, 636],
      [-149, -1354],
      [-202, 2089],
      [-181, -786],
      [-141, -3116],
      [-248, 1984],
    ],
    [
      [304288, 501469],
      [-305, -404],
      [-9, -1934],
      [-241, 39],
    ],
    [
      [303733, 499170],
      [-304, -527],
    ],
    [
      [303429, 498643],
      [-117, 2061],
      [-640, 1070],
      [-291, 3135],
      [-430, -1180],
      [-152, 5251],
      [122, 80],
      [-151, 4819],
    ],
    [
      [301770, 513879],
      [26, 2283],
      [518, -340],
      [-235, 2392],
      [476, 2921],
    ],
    [
      [266792, 512571],
      [-1372, -94],
    ],
    [
      [265420, 512477],
      [-1018, -62],
    ],
    [
      [264518, 520373],
      [261, -2225],
      [563, -388],
      [377, -1459],
      [555, -1119],
      [218, 171],
      [319, -1624],
      [-19, -1158],
    ],
    [
      [244080, 520585],
      [-2, -6434],
    ],
    [
      [244078, 514151],
      [-2, -1590],
      [-344, 26],
    ],
    [
      [243732, 512587],
      [-1369, 29],
    ],
    [
      [246481, 515748],
      [-686, 31],
      [0, -1629],
    ],
    [
      [245795, 514150],
      [-1717, 1],
    ],
    [
      [197528, 508706],
      [-1036, 15],
    ],
    [
      [196492, 508721],
      [-3281, 35],
    ],
    [
      [221325, 519763],
      [-3, -6470],
    ],
    [
      [221322, 513293],
      [-1536, 18],
    ],
    [
      [219786, 513311],
      [-158, 1348],
      [120, 1153],
      [-189, 1708],
    ],
    [
      [224071, 513254],
      [-2371, 30],
    ],
    [
      [221700, 513284],
      [-378, 9],
    ],
    [
      [228239, 514257],
      [0, -2714],
    ],
    [
      [228239, 511543],
      [-746, -4],
    ],
    [
      [227493, 511539],
      [-1359, 31],
    ],
    [
      [226134, 511570],
      [1, 1620],
    ],
    [
      [231329, 516385],
      [38, -4380],
    ],
    [
      [231367, 512005],
      [-336, 821],
    ],
    [
      [231031, 512826],
      [-637, 896],
    ],
    [
      [230394, 513722],
      [-49, 1073],
    ],
    [
      [238593, 516437],
      [-6, -3147],
    ],
    [
      [238587, 513290],
      [-26, 17],
    ],
    [
      [238561, 513307],
      [-163, 843],
      [-522, 101],
      [-780, 2337],
    ],
    [
      [249974, 517415],
      [-2, -6482],
    ],
    [
      [249972, 510933],
      [-1721, 20],
    ],
    [
      [248251, 510953],
      [4, 4872],
    ],
    [
      [219786, 513311],
      [-178, -2703],
      [123, -1550],
      [-312, 142],
      [-35, -2389],
    ],
    [
      [219384, 506811],
      [-387, -2543],
      [-370, 1156],
      [-115, -974],
    ],
    [
      [218512, 504450],
      [-583, 57],
      [-208, -834],
      [-377, 334],
    ],
    [
      [217344, 504007],
      [2, 4597],
      [-1015, -5],
      [83, 8912],
    ],
    [
      [217344, 504007],
      [-269, -1030],
      [-621, -1015],
      [-210, -1237],
      [-503, 175],
      [-264, -1035],
      [-539, -243],
    ],
    [
      [214938, 499622],
      [1, 9800],
    ],
    [
      [252056, 515765],
      [-7, -3234],
      [109, -1632],
    ],
    [
      [252158, 510899],
      [-950, 13],
      [2, -1661],
    ],
    [
      [251210, 509251],
      [-675, 9],
    ],
    [
      [250535, 509260],
      [-1, 1662],
      [-562, 11],
    ],
    [
      [159957, 506635],
      [-1846, -55],
      [-387, 968],
      [-169, 1538],
      [-534, 2059],
      [129, 2390],
      [-300, 15],
    ],
    [
      [156850, 513550],
      [-52, 1073],
    ],
    [
      [156850, 513550],
      [-43, -124],
    ],
    [
      [156807, 513426],
      [-486, -577],
      [117, -1993],
      [-201, -742],
    ],
    [
      [156237, 510114],
      [-1823, 17],
    ],
    [
      [238561, 513307],
      [-382, -977],
      [-277, -2080],
      [-23, -1945],
    ],
    [
      [237879, 508305],
      [-685, 14],
    ],
    [
      [237194, 508319],
      [-679, 14],
    ],
    [
      [236515, 508333],
      [-15, 5670],
    ],
    [
      [239961, 514242],
      [-4, -3220],
    ],
    [
      [239957, 511022],
      [-577, 12],
      [2, -1654],
    ],
    [
      [239382, 509380],
      [-216, 1965],
      [-579, 1945],
    ],
    [
      [235101, 514787],
      [15, -8081],
    ],
    [
      [235116, 506706],
      [-1367, -14],
    ],
    [
      [233749, 506692],
      [6, 4861],
    ],
    [
      [233755, 511553],
      [-24, 4843],
    ],
    [
      [233755, 511553],
      [-2201, -4],
    ],
    [
      [231554, 511549],
      [-187, 456],
    ],
    [
      [248251, 510953],
      [-430, 7],
      [0, -1618],
      [-327, 3],
    ],
    [
      [247494, 509345],
      [-1694, -51],
    ],
    [
      [245800, 509294],
      [-5, 4856],
    ],
    [
      [252752, 515733],
      [340, -44],
      [-9, -3220],
      [342, -69],
      [182, -3245],
      [999, -1069],
    ],
    [
      [254606, 508086],
      [-385, -2583],
      [-242, -2789],
    ],
    [
      [253979, 502714],
      [-8, -1],
    ],
    [
      [253971, 502713],
      [-10, -1],
    ],
    [
      [253961, 502712],
      [-693, 38],
    ],
    [
      [253268, 502750],
      [-16, 3221],
      [-670, 52],
    ],
    [
      [252582, 506023],
      [14, 4857],
      [-438, 19],
    ],
    [
      [303429, 498643],
      [-266, -4622],
      [-406, -3326],
    ],
    [
      [302757, 490695],
      [-370, 576],
      [-32, 2012],
      [-647, -2107],
      [393, -1789],
      [-189, -2754],
    ],
    [
      [301912, 486633],
      [-573, -426],
    ],
    [
      [301339, 486207],
      [0, -16],
    ],
    [
      [301339, 486207],
      [-59, 9193],
    ],
    [
      [301280, 495400],
      [-86, 9641],
      [-121, 9365],
    ],
    [
      [301073, 514406],
      [217, 752],
      [480, -1279],
    ],
    [
      [230394, 513722],
      [3, -5426],
    ],
    [
      [230397, 508296],
      [-1199, -12],
    ],
    [
      [229198, 508284],
      [-5, 3228],
      [-954, 31],
    ],
    [
      [236515, 508333],
      [-686, 5],
      [-1, -1627],
    ],
    [
      [235828, 506711],
      [-712, -5],
    ],
    [
      [301280, 495400],
      [-90, -875],
      [-585, 121],
      [-254, -2830],
      [-201, 2230],
    ],
    [
      [300150, 494046],
      [-446, 795],
      [-524, 2812],
    ],
    [
      [299180, 497653],
      [520, 1849],
      [112, 1480],
      [-264, 3054],
      [383, 3039],
      [-19, 1891],
    ],
    [
      [299912, 508966],
      [388, 4730],
      [214, 623],
      [386, -1123],
      [173, 1210],
    ],
    [
      [240688, 512617],
      [-96, -2433],
      [64, -4037],
    ],
    [
      [240656, 506147],
      [-95, -2137],
    ],
    [
      [240561, 504010],
      [-562, 444],
      [-38, 2207],
    ],
    [
      [239961, 506661],
      [97, 22],
      [-1, 4337],
      [-100, 2],
    ],
    [
      [245800, 509294],
      [3, -3236],
    ],
    [
      [245803, 506058],
      [-2030, -24],
    ],
    [
      [243773, 506034],
      [-41, 6553],
    ],
    [
      [159888, 505470],
      [-167, -669],
      [70, -1970],
    ],
    [
      [159791, 502831],
      [-1153, 206],
      [-234, 1076],
      [-631, 10],
      [-781, 720],
      [-578, -1960],
      [-382, 1172],
    ],
    [
      [156032, 504055],
      [-98, 1557],
      [388, 2208],
      [-85, 2294],
    ],
    [
      [175529, 510717],
      [154, -826],
      [-340, -5246],
      [151, -1455],
      [0, -3805],
    ],
    [
      [175494, 499385],
      [-398, -1021],
    ],
    [
      [175096, 498364],
      [-634, 868],
      [-4, 2312],
      [-267, 893],
      [0, 3275],
      [-765, 34],
    ],
    [
      [173426, 505746],
      [189, 1739],
      [-44, 1635],
      [179, 1067],
    ],
    [
      [231554, 511549],
      [662, -3749],
      [173, -272],
    ],
    [
      [232389, 507528],
      [-309, -828],
      [-3, -1608],
      [-1680, 19],
    ],
    [
      [230397, 505111],
      [0, 3185],
    ],
    [
      [307819, 495119],
      [63, -2040],
      [-255, 1149],
      [192, 891],
    ],
    [
      [308823, 498239],
      [322, -1210],
      [-422, -2791],
      [-256, 1682],
      [356, 2319],
    ],
    [
      [309697, 499440],
      [-194, -3153],
      [-121, 2654],
      [-613, 1160],
      [154, -1192],
      [-465, -1333],
      [31, 1761],
      [-402, -1706],
      [118, -3275],
      [-828, 3345],
      [180, 1911],
      [-237, 2132],
      [81, 1095],
    ],
    [
      [221700, 513284],
      [1, -6478],
    ],
    [
      [221701, 506806],
      [-279, 1],
    ],
    [
      [221422, 506807],
      [-2038, 4],
    ],
    [
      [224084, 513253],
      [3, -6454],
    ],
    [
      [224087, 506799],
      [-1659, 3],
    ],
    [
      [222428, 506802],
      [-727, 4],
    ],
    [
      [239382, 509380],
      [134, -2743],
    ],
    [
      [239516, 506637],
      [-427, -1841],
    ],
    [
      [239089, 504796],
      [-534, 227],
    ],
    [
      [238555, 505023],
      [9, 1658],
      [-687, 3],
      [2, 1621],
    ],
    [
      [226134, 511570],
      [12, -9713],
    ],
    [
      [226146, 501857],
      [-2029, 49],
    ],
    [
      [224117, 501906],
      [-30, 4893],
    ],
    [
      [179240, 505147],
      [-395, -1629],
      [-367, 938],
      [-230, -2310],
      [-308, -655],
      [142, -3509],
      [-185, -1566],
    ],
    [
      [177897, 496416],
      [-297, -1464],
      [-332, -476],
      [-1622, 25],
      [-30, -1653],
      [-277, 66],
    ],
    [
      [175339, 492914],
      [2, 3219],
      [164, 2],
      [-11, 3250],
    ],
    [
      [212272, 509451],
      [-19, -8102],
      [-1681, -4],
    ],
    [
      [210572, 501345],
      [-701, 13],
      [-661, -630],
    ],
    [
      [209210, 500728],
      [-4, 7939],
    ],
    [
      [242363, 512616],
      [57, -6548],
    ],
    [
      [242420, 506068],
      [-1764, 79],
    ],
    [
      [243773, 506034],
      [0, -3210],
    ],
    [
      [243773, 502824],
      [-1350, 15],
    ],
    [
      [242423, 502839],
      [-3, 3229],
    ],
    [
      [262700, 510887],
      [28, -4815],
    ],
    [
      [262728, 506072],
      [-1240, 27],
      [-113, -876],
    ],
    [
      [261375, 505223],
      [-305, 883],
    ],
    [
      [261070, 506106],
      [220, 4694],
      [-69, 1784],
    ],
    [
      [261221, 512584],
      [458, -42],
      [1, -1633],
      [1020, -22],
    ],
    [
      [264067, 512416],
      [-14, -6399],
    ],
    [
      [264053, 506017],
      [-1325, 55],
    ],
    [
      [262700, 510887],
      [342, -32],
      [1, 1620],
    ],
    [
      [265420, 512477],
      [-20, -6431],
    ],
    [
      [265400, 506046],
      [-1347, -29],
    ],
    [
      [227493, 511539],
      [8, -6473],
    ],
    [
      [227501, 505066],
      [-4, -4842],
    ],
    [
      [227497, 500224],
      [-1004, 10],
    ],
    [
      [226493, 500234],
      [-9, 1613],
      [-338, 10],
    ],
    [
      [229198, 508284],
      [-7, -3209],
    ],
    [
      [229191, 505075],
      [-1690, -9],
    ],
    [
      [233749, 506692],
      [-652, -11],
      [0, -2565],
    ],
    [
      [233097, 504116],
      [-708, 3412],
    ],
    [
      [239961, 506661],
      [-445, -24],
    ],
    [
      [250535, 509260],
      [0, -6476],
    ],
    [
      [250535, 502784],
      [-1731, 65],
    ],
    [
      [248804, 502849],
      [-1313, 5],
    ],
    [
      [247491, 502854],
      [3, 6491],
    ],
    [
      [252582, 506023],
      [-689, 18],
      [2, 1616],
      [-685, -6],
      [0, 1600],
    ],
    [
      [173426, 505746],
      [-385, -1718],
      [-296, -3771],
      [-219, -1145],
      [9, -3403],
    ],
    [
      [172535, 495709],
      [-741, 0],
      [0, 1607],
      [-294, 1057],
      [-1059, -21],
      [-726, -3486],
    ],
    [
      [169715, 494866],
      [-738, -18],
      [245, 2192],
      [-29, 1556],
      [314, 2473],
      [-552, 1434],
      [612, 1573],
      [-29, 2092],
      [142, 1770],
    ],
    [
      [156032, 504055],
      [-16, -548],
    ],
    [
      [156016, 503507],
      [-1264, 17],
    ],
    [
      [154752, 503524],
      [-340, 334],
      [-2, 5676],
    ],
    [
      [165704, 508611],
      [55, -3187],
      [-11, -9610],
    ],
    [
      [165748, 495814],
      [-676, -6],
      [-1, 1543],
      [-342, 912],
      [-1014, -19],
      [1, 2354],
    ],
    [
      [163716, 500598],
      [38, 4796],
    ],
    [
      [154752, 503524],
      [16, -5366],
      [-345, 3],
      [16, -1350],
      [-284, -293],
      [116, -1140],
    ],
    [
      [154271, 495378],
      [-947, -137],
    ],
    [
      [153324, 495241],
      [89, 2631],
      [44, 7616],
      [170, 1569],
      [17, 2494],
    ],
    [
      [214938, 499622],
      [-3, -12],
    ],
    [
      [214935, 499610],
      [-311, -1407],
      [-474, 240],
      [-222, -2776],
      [-71, -2959],
      [-2964, 12],
    ],
    [
      [210893, 492720],
      [-2, 2156],
      [-338, -10],
      [19, 6479],
    ],
    [
      [247491, 502854],
      [1, -4850],
    ],
    [
      [247492, 498004],
      [-1354, -31],
      [0, 1610],
      [-337, 6],
      [2, 1612],
    ],
    [
      [245803, 501201],
      [0, 4857],
    ],
    [
      [253268, 502750],
      [-7, -1765],
    ],
    [
      [253261, 500985],
      [-1005, 107],
    ],
    [
      [252256, 501092],
      [2, 1635],
      [-1723, 57],
    ],
    [
      [296979, 508865],
      [60, -3257],
      [-127, -958],
    ],
    [
      [296912, 504650],
      [-471, 86],
      [-134, -1750],
      [-359, -1102],
    ],
    [
      [295948, 501884],
      [-849, 1672],
    ],
    [
      [295099, 503556],
      [103, 5410],
    ],
    [
      [295202, 508966],
      [1777, -101],
    ],
    [
      [299180, 497653],
      [-205, -1076],
    ],
    [
      [298975, 496577],
      [-266, 1736],
      [213, 1023],
      [-144, 948],
      [183, 1193],
      [-188, 668],
      [204, 1295],
      [-286, 977],
    ],
    [
      [298691, 504417],
      [181, 2000],
      [-64, 2446],
    ],
    [
      [298808, 508863],
      [1104, 103],
    ],
    [
      [295099, 503556],
      [-145, -2596],
      [-226, -366],
    ],
    [
      [294728, 500594],
      [-75, 974],
      [149, 3512],
      [-119, 604],
      [97, 3231],
    ],
    [
      [294780, 508915],
      [422, 51],
    ],
    [
      [294728, 500594],
      [65, -315],
    ],
    [
      [294793, 500279],
      [-930, -2003],
      [-662, -178],
    ],
    [
      [293201, 498098],
      [-213, 5176],
      [-115, 5362],
    ],
    [
      [292873, 508636],
      [1907, 279],
    ],
    [
      [298691, 504417],
      [-203, -1307],
      [-284, 945],
      [-413, -3859],
      [-312, 777],
    ],
    [
      [297479, 500973],
      [-299, 925],
      [174, 1659],
      [-442, 1093],
    ],
    [
      [296979, 508865],
      [1829, -2],
    ],
    [
      [196492, 508721],
      [81, -2400],
      [-1, -6524],
      [53, -2],
      [-1, -6560],
      [65, 0],
    ],
    [
      [196689, 493235],
      [-1, -1658],
    ],
    [
      [196688, 491577],
      [-673, 4],
      [0, -1650],
      [-344, -3],
      [9, -1603],
      [-502, -5],
      [2, -1644],
      [-615, -55],
    ],
    [
      [194565, 486621],
      [-361, 2183],
      [-278, 575],
      [-278, -718],
      [-65, -1424],
      [-340, -839],
      [-198, 2909],
      [-378, -147],
      [-165, 1088],
    ],
    [
      [192502, 490248],
      [0, 2320],
      [-270, 2958],
      [-406, 2562],
      [50, 1661],
      [-272, 1204],
      [-814, -4],
      [0, 1554],
      [-1081, 0],
    ],
    [
      [290927, 508615],
      [234, -781],
      [324, -14558],
      [-26, -1329],
    ],
    [
      [291459, 491947],
      [-889, -543],
    ],
    [
      [290570, 491404],
      [-581, -367],
      [-299, 867],
    ],
    [
      [289690, 491904],
      [-769, 2247],
    ],
    [
      [288921, 494151],
      [-1154, 3457],
    ],
    [
      [287767, 497608],
      [260, 2084],
      [1343, 6200],
      [870, 2516],
      [687, 207],
    ],
    [
      [198469, 508740],
      [221, -3931],
      [453, -2060],
      [152, 402],
      [684, -2631],
    ],
    [
      [199979, 500520],
      [185, -1795],
      [287, -397],
      [154, -2198],
      [-5, -2947],
    ],
    [
      [200600, 493183],
      [-3911, 52],
    ],
    [
      [206332, 508724],
      [28, -15346],
    ],
    [
      [206360, 493378],
      [-1, -12616],
    ],
    [
      [206359, 480762],
      [-2612, -66],
    ],
    [
      [203747, 480696],
      [21, 19902],
    ],
    [
      [203768, 500598],
      [-42, 7997],
    ],
    [
      [203768, 500598],
      [-1168, -134],
      [-2621, 56],
    ],
    [
      [293201, 498098],
      [-645, -417],
      [133, -5014],
      [-525, -324],
    ],
    [
      [292164, 492343],
      [-705, -396],
    ],
    [
      [290927, 508615],
      [1946, 21],
    ],
    [
      [209210, 500728],
      [5, -7271],
    ],
    [
      [209215, 493457],
      [-2855, -79],
    ],
    [
      [169715, 494866],
      [13, -4024],
    ],
    [
      [169728, 490842],
      [-1637, 145],
      [-4, -1633],
      [-2343, -17],
    ],
    [
      [165744, 489337],
      [4, 6477],
    ],
    [
      [237194, 508319],
      [5, -4865],
    ],
    [
      [237199, 503454],
      [-679, 9],
      [-2, -1632],
      [-677, -6],
      [-1, 1627],
    ],
    [
      [235840, 503452],
      [-12, 3259],
    ],
    [
      [238555, 505023],
      [-264, -1740],
      [-417, -1264],
    ],
    [
      [237874, 502019],
      [-336, 1431],
      [-339, 4],
    ],
    [
      [230397, 505111],
      [0, -3258],
    ],
    [
      [230397, 501853],
      [0, -1635],
    ],
    [
      [230397, 500218],
      [-1208, 4],
    ],
    [
      [229189, 500222],
      [2, 4853],
    ],
    [
      [233097, 504116],
      [341, -1014],
    ],
    [
      [233438, 503102],
      [0, -2921],
      [-654, 12],
    ],
    [
      [232784, 500193],
      [-25, 1632],
      [-1362, 11],
    ],
    [
      [231397, 501836],
      [-1000, 17],
    ],
    [
      [240561, 504010],
      [200, -612],
    ],
    [
      [240761, 503398],
      [-169, -3191],
      [-689, -567],
      [0, -757],
    ],
    [
      [239903, 498883],
      [-674, 2],
      [0, 1341],
    ],
    [
      [239229, 500226],
      [-140, 4570],
    ],
    [
      [221422, 506807],
      [-9, -6505],
    ],
    [
      [221413, 500302],
      [-2368, -13],
    ],
    [
      [219045, 500289],
      [-317, 676],
      [77, 2217],
      [-293, 1268],
    ],
    [
      [222428, 506802],
      [33, -13076],
    ],
    [
      [222461, 493726],
      [-769, -45],
    ],
    [
      [221692, 493681],
      [-243, 467],
    ],
    [
      [221449, 494148],
      [-36, 6154],
    ],
    [
      [224117, 501906],
      [15, -8145],
    ],
    [
      [224132, 493761],
      [-628, -3],
    ],
    [
      [223504, 493758],
      [-1043, -32],
    ],
    [
      [235840, 503452],
      [-367, -8],
      [16, -4852],
    ],
    [
      [235489, 498592],
      [-437, 8],
    ],
    [
      [235052, 498600],
      [-236, 771],
    ],
    [
      [234816, 499371],
      [-1378, 3731],
    ],
    [
      [183397, 494435],
      [-268, 4],
      [1, -3240],
      [-116, 1],
      [10, -3446],
      [227, -982],
      [-443, -1878],
    ],
    [
      [182808, 484894],
      [-596, -1711],
      [-145, -1114],
    ],
    [
      [182067, 482069],
      [-654, 3911],
      [-113, -748],
      [-563, 1718],
      [-9, 879],
      [-657, 322],
      [-39, -921],
      [-426, 1521],
      [-370, 1],
      [1, 1214],
      [-439, -1021],
    ],
    [
      [178798, 488945],
      [-56, 216],
    ],
    [
      [178742, 489161],
      [-154, 1983],
      [62, 1848],
      [-398, 879],
      [-33, 1744],
      [-322, 801],
    ],
    [
      [242423, 502839],
      [0, -2695],
      [-505, 27],
    ],
    [
      [241918, 500171],
      [-647, 507],
      [-510, 2720],
    ],
    [
      [266980, 506080],
      [138, -2703],
      [-131, -3752],
    ],
    [
      [266987, 499625],
      [-1582, -51],
    ],
    [
      [265405, 499574],
      [-5, 6472],
    ],
    [
      [265400, 506046],
      [1580, 34],
    ],
    [
      [262728, 506072],
      [-10, -6460],
    ],
    [
      [262718, 499612],
      [-1346, 23],
    ],
    [
      [261372, 499635],
      [3, 5588],
    ],
    [
      [264053, 506017],
      [3, -6475],
    ],
    [
      [264056, 499542],
      [-1338, 70],
    ],
    [
      [245803, 501201],
      [-678, 11],
    ],
    [
      [245125, 501212],
      [-1014, -15],
    ],
    [
      [244111, 501197],
      [-336, 9],
    ],
    [
      [243775, 501206],
      [-2, 1618],
    ],
    [
      [265405, 499574],
      [-1349, -32],
    ],
    [
      [175096, 498364],
      [-132, -2275],
      [-32, -3181],
      [-260, 7],
    ],
    [
      [174672, 492915],
      [-1240, 21],
    ],
    [
      [173432, 492936],
      [-217, 1684],
      [-680, 1089],
    ],
    [
      [163716, 500598],
      [-1231, -21],
      [0, -1605],
      [-451, -3],
      [2, -1609],
      [-331, 10],
    ],
    [
      [161705, 497370],
      [-2049, 35],
    ],
    [
      [159656, 497405],
      [135, 5426],
    ],
    [
      [239229, 500226],
      [-676, -7],
    ],
    [
      [238553, 500219],
      [-1077, -9],
    ],
    [
      [237476, 500210],
      [398, 1809],
    ],
    [
      [229189, 500222],
      [-676, -12],
    ],
    [
      [228513, 500210],
      [-1016, 14],
    ],
    [
      [297479, 500973],
      [-166, -1451],
    ],
    [
      [297313, 499522],
      [-157, -1497],
      [-296, 998],
      [-160, -1510],
      [-420, 996],
    ],
    [
      [296280, 498509],
      [-67, 1872],
      [-265, 1503],
    ],
    [
      [159656, 497405],
      [121, -2501],
    ],
    [
      [159777, 494904],
      [-1469, -89],
      [-166, -673],
      [-532, 204],
      [-411, 1117],
      [-498, -541],
      [-6, -1096],
      [-722, -4],
      [-55, 1558],
    ],
    [
      [155918, 495380],
      [-123, 1127],
      [43, 2998],
      [-132, 924],
      [521, 1787],
      [-211, 1291],
    ],
    [
      [219045, 500289],
      [-133, -1711],
      [523, -288],
      [58, -1572],
      [780, -877],
      [539, -1239],
      [-128, -869],
    ],
    [
      [220684, 493733],
      [-1196, -11],
      [0, -483],
    ],
    [
      [219488, 493239],
      [-1893, -6],
    ],
    [
      [217595, 493233],
      [-334, -5],
      [34, 9715],
      [49, 1064],
    ],
    [
      [260032, 504518],
      [-8, -4869],
    ],
    [
      [260024, 499649],
      [-1160, 99],
    ],
    [
      [258864, 499748],
      [-13, 3454],
      [458, 1388],
    ],
    [
      [259309, 504590],
      [723, -72],
    ],
    [
      [298975, 496577],
      [-544, -513],
      [-20, -3063],
    ],
    [
      [298411, 493001],
      [-914, 898],
    ],
    [
      [297497, 493899],
      [402, 4106],
      [-586, 1517],
    ],
    [
      [307394, 502827],
      [-140, -1573],
      [180, -625],
      [-64, -1886],
      [-523, -588],
      [177, -1427],
      [-225, -1986],
    ],
    [
      [306799, 494742],
      [-257, 115],
      [-412, 2029],
      [-390, -679],
    ],
    [
      [305740, 496207],
      [-272, 275],
    ],
    [
      [305468, 496482],
      [309, 2126],
      [8, 3412],
      [-219, 979],
    ],
    [
      [189728, 498924],
      [5, -9133],
    ],
    [
      [189733, 489791],
      [-389, -951],
      [-593, -175],
    ],
    [
      [188751, 488665],
      [-448, -740],
      [-1162, 820],
    ],
    [
      [187141, 488745],
      [-1, 811],
      [-499, 8],
      [1, 1607],
    ],
    [
      [186642, 491171],
      [338, -6],
      [2, 1607],
      [490, 820],
      [15, 2406],
      [170, 1557],
      [484, 19],
      [2, 2777],
    ],
    [
      [217595, 493233],
      [-48, -3229],
    ],
    [
      [217547, 490004],
      [-2628, -7],
    ],
    [
      [214919, 489997],
      [16, 9613],
    ],
    [
      [296280, 498509],
      [-410, -5407],
    ],
    [
      [295870, 493102],
      [-68, 2572],
      [-938, -658],
    ],
    [
      [294864, 495016],
      [48, 3058],
      [-119, 2205],
    ],
    [
      [155918, 495380],
      [-1647, -2],
    ],
    [
      [305468, 496482],
      [-133, -1505],
      [-266, 329],
      [-18, -2929],
      [-277, 307],
    ],
    [
      [304774, 492684],
      [-7, 9],
    ],
    [
      [304767, 492693],
      [-269, 531],
      [-119, -1120],
      [-300, 314],
    ],
    [
      [304079, 492418],
      [-196, 1521],
      [-150, 5231],
    ],
    [
      [237476, 500210],
      [-51, -1566],
    ],
    [
      [237425, 498644],
      [-1936, -52],
    ],
    [
      [241918, 500171],
      [207, -1615],
    ],
    [
      [242125, 498556],
      [-525, 16],
      [0, -1617],
      [-334, 5],
      [-1, -3232],
    ],
    [
      [241265, 493728],
      [-356, 11],
    ],
    [
      [240909, 493739],
      [-1010, 22],
    ],
    [
      [239899, 493761],
      [4, 5122],
    ],
    [
      [234816, 499371],
      [-1, -4018],
      [-672, -9],
      [-5, -1614],
    ],
    [
      [234138, 493730],
      [-984, 19],
    ],
    [
      [233154, 493749],
      [-366, -7],
    ],
    [
      [232788, 493742],
      [-4, 6451],
    ],
    [
      [250535, 502784],
      [-3, -8151],
    ],
    [
      [250532, 494633],
      [-1040, 40],
    ],
    [
      [249492, 494673],
      [-353, 36],
    ],
    [
      [249139, 494709],
      [-6, 4906],
      [-327, 5],
      [-2, 3229],
    ],
    [
      [249139, 494709],
      [-495, 33],
    ],
    [
      [248644, 494742],
      [-1141, -12],
    ],
    [
      [247503, 494730],
      [-11, 3274],
    ],
    [
      [253961, 502712],
      [-152, -2104],
      [333, -532],
      [464, 2018],
    ],
    [
      [254606, 502094],
      [-10, -5904],
    ],
    [
      [254596, 496190],
      [-339, 8],
      [-1, -1624],
      [-432, 11],
    ],
    [
      [253824, 494585],
      [-416, 19],
    ],
    [
      [253408, 494604],
      [6, 6419],
      [-153, -38],
    ],
    [
      [243775, 501206],
      [-1056, -193],
      [-152, -3331],
    ],
    [
      [242567, 497682],
      [-442, 874],
    ],
    [
      [252256, 501092],
      [-365, 1],
      [-8, -6465],
    ],
    [
      [251883, 494628],
      [-410, -12],
    ],
    [
      [251473, 494616],
      [-941, 17],
    ],
    [
      [255685, 502676],
      [-302, -3069],
      [-169, -3411],
    ],
    [
      [255214, 496196],
      [-618, -6],
    ],
    [
      [254606, 502094],
      [72, 609],
    ],
    [
      [254678, 502703],
      [1007, -27],
    ],
    [
      [192502, 490248],
      [5, -10113],
    ],
    [
      [192507, 480135],
      [-16, -1620],
      [-799, 3],
      [0, -1615],
      [-653, 8],
      [-1, -1051],
    ],
    [
      [191038, 475860],
      [-655, -1],
      [0, 1504],
      [-643, -3],
    ],
    [
      [189740, 477360],
      [-2, 3452],
    ],
    [
      [189738, 480812],
      [-5, 8979],
    ],
    [
      [226493, 500234],
      [-2, -6499],
    ],
    [
      [226491, 493735],
      [-1332, 24],
    ],
    [
      [225159, 493759],
      [-1027, 2],
    ],
    [
      [231397, 501836],
      [40, -8076],
    ],
    [
      [231437, 493760],
      [-1042, 2],
    ],
    [
      [230395, 493762],
      [2, 6456],
    ],
    [
      [232788, 493742],
      [-1310, 16],
    ],
    [
      [231478, 493758],
      [-41, 2],
    ],
    [
      [210893, 492720],
      [-1678, 5],
    ],
    [
      [209215, 492725],
      [0, 732],
    ],
    [
      [244111, 501197],
      [-1, -6504],
      [-219, -857],
      [207, -1948],
      [-69, -1318],
    ],
    [
      [244029, 490570],
      [-834, 3122],
    ],
    [
      [243195, 493692],
      [-279, 3080],
      [-349, 910],
    ],
    [
      [245125, 501212],
      [38, -9630],
    ],
    [
      [245163, 491582],
      [-120, 124],
    ],
    [
      [245043, 491706],
      [-313, -400],
      [-41, -1496],
      [-287, -3],
    ],
    [
      [244402, 489807],
      [-373, 763],
    ],
    [
      [247503, 494730],
      [-1, -1740],
    ],
    [
      [247502, 492990],
      [-1654, 56],
      [-187, -1630],
    ],
    [
      [245661, 491416],
      [-498, 166],
    ],
    [
      [253408, 494604],
      [-589, 39],
    ],
    [
      [252819, 494643],
      [-936, -15],
    ],
    [
      [186642, 491171],
      [-1501, 14],
      [-1, -1610],
    ],
    [
      [185140, 489575],
      [-834, 8],
      [-3, 4870],
    ],
    [
      [203747, 480696],
      [-170, -9],
    ],
    [
      [203577, 480687],
      [-2877, -39],
      [0, 148],
    ],
    [
      [200700, 480796],
      [-49, 12388],
      [-51, -1],
    ],
    [
      [165744, 489337],
      [-329, 0],
      [-5, -4859],
      [-338, 5],
    ],
    [
      [165072, 484483],
      [-1001, 8],
      [0, 1610],
      [-336, -4],
      [-2, 1618],
      [-1027, -13],
      [-1, 1606],
      [-664, 64],
      [-2, 3219],
      [-321, 77],
      [-13, 4702],
    ],
    [
      [221449, 494148],
      [180, -1781],
      [-749, 47],
      [-196, 1319],
    ],
    [
      [294864, 495016],
      [-214, -1344],
      [-132, -2763],
      [162, -4374],
    ],
    [
      [294680, 486535],
      [-165, -92],
    ],
    [
      [294515, 486443],
      [-1724, -1101],
    ],
    [
      [292791, 485342],
      [28, 960],
      [-465, 275],
      [-343, 2130],
      [225, 833],
      [-72, 2803],
    ],
    [
      [238553, 500219],
      [-2, -6472],
    ],
    [
      [238551, 493747],
      [-677, -2],
    ],
    [
      [237874, 493745],
      [1, 807],
      [-681, -7],
    ],
    [
      [237194, 494545],
      [221, 1970],
      [10, 2129],
    ],
    [
      [239899, 493761],
      [-13, 0],
    ],
    [
      [239886, 493761],
      [-1006, -6],
    ],
    [
      [238880, 493755],
      [-329, -8],
    ],
    [
      [228513, 500210],
      [-4, -6463],
    ],
    [
      [228509, 493747],
      [-670, -20],
    ],
    [
      [227839, 493727],
      [-1336, 7],
    ],
    [
      [226503, 493734],
      [-12, 1],
    ],
    [
      [230395, 493762],
      [0, -2],
    ],
    [
      [230395, 493760],
      [-1217, -20],
    ],
    [
      [229178, 493740],
      [-669, 7],
    ],
    [
      [260024, 499649],
      [-9, -6494],
    ],
    [
      [260015, 493155],
      [-615, 55],
    ],
    [
      [259400, 493210],
      [-964, 215],
    ],
    [
      [258436, 493425],
      [364, 3555],
      [64, 2768],
    ],
    [
      [261372, 499635],
      [-2, -6463],
    ],
    [
      [261370, 493172],
      [-640, -4],
    ],
    [
      [260730, 493168],
      [-715, -13],
    ],
    [
      [260024, 499649],
      [1348, -14],
    ],
    [
      [264056, 499542],
      [8, -6454],
    ],
    [
      [264064, 493088],
      [-669, -1],
    ],
    [
      [263395, 493087],
      [-679, 16],
    ],
    [
      [262716, 493103],
      [2, 6509],
    ],
    [
      [262716, 493103],
      [-656, 52],
    ],
    [
      [262060, 493155],
      [-690, 17],
    ],
    [
      [266987, 499625],
      [-62, -3376],
      [-470, -1067],
      [-157, -2040],
    ],
    [
      [266298, 493142],
      [-886, -31],
    ],
    [
      [265412, 493111],
      [-7, 6463],
    ],
    [
      [265412, 493111],
      [-786, 0],
    ],
    [
      [264626, 493111],
      [-562, -23],
    ],
    [
      [175339, 492914],
      [-169, -3],
      [-23, -6408],
    ],
    [
      [175147, 486503],
      [-644, 5],
    ],
    [
      [174503, 486508],
      [-557, -1],
    ],
    [
      [173946, 486507],
      [0, 3241],
      [391, -17],
      [114, 1594],
      [220, -13],
      [1, 1603],
    ],
    [
      [214919, 489997],
      [-33, -5323],
      [-334, -145],
    ],
    [
      [214552, 484529],
      [-104, -245],
      [-1766, 9],
    ],
    [
      [212682, 484293],
      [343, 3115],
      [-3812, -38],
    ],
    [
      [209213, 487370],
      [2, 5355],
    ],
    [
      [297497, 493899],
      [-208, -1404],
      [-408, 589],
      [-262, -2738],
      [-163, 304],
    ],
    [
      [296456, 490650],
      [-487, 788],
      [-99, 1664],
    ],
    [
      [235052, 498600],
      [861, -2186],
      [275, -1379],
    ],
    [
      [236188, 495035],
      [12, -2914],
    ],
    [
      [236200, 492121],
      [-1368, -13],
    ],
    [
      [234832, 492108],
      [-669, 3],
      [-25, 1619],
    ],
    [
      [304079, 492418],
      [-79, -2770],
    ],
    [
      [304000, 489648],
      [-229, -1286],
      [-556, 2438],
      [-134, -953],
      [-324, 848],
    ],
    [
      [237194, 494545],
      [-162, -1485],
      [-844, 1975],
    ],
    [
      [243195, 493692],
      [-616, 1],
      [0, -1602],
    ],
    [
      [242579, 492091],
      [-668, 7],
      [-1, 1620],
      [-645, 10],
    ],
    [
      [173432, 492936],
      [-215, -1206],
      [111, -1864],
      [-135, -2004],
    ],
    [
      [173193, 487862],
      [-118, -728],
      [-1, -2989],
    ],
    [
      [173074, 484145],
      [-2, -31284],
    ],
    [
      [173072, 452861],
      [-3259, -52],
    ],
    [
      [169813, 452809],
      [-49, 5195],
      [-6, 11887],
      [-34, 1],
      [4, 20950],
    ],
    [
      [300150, 494046],
      [150, -5520],
      [-565, -417],
      [90, -2385],
    ],
    [
      [299825, 485724],
      [28, -1263],
      [-360, -306],
      [-213, -2182],
    ],
    [
      [299280, 481973],
      [-251, -349],
      [-88, 1241],
      [-239, -1545],
    ],
    [
      [298702, 481320],
      [-1098, 1335],
    ],
    [
      [297604, 482655],
      [73, 1782],
      [273, 1403],
    ],
    [
      [297950, 485840],
      [461, 7161],
    ],
    [
      [165072, 484483],
      [0, -1675],
    ],
    [
      [165072, 482808],
      [-3996, 131],
    ],
    [
      [161076, 482939],
      [-1867, -3],
    ],
    [
      [159209, 482936],
      [78, 4514],
      [296, 1024],
      [283, 3753],
      [-89, 2677],
    ],
    [
      [286590, 490299],
      [-326, 1891],
    ],
    [
      [286264, 492190],
      [242, 1614],
      [1261, 3804],
    ],
    [
      [288921, 494151],
      [-278, -2136],
      [171, -535],
      [-163, -1981],
      [-600, -1658],
      [-229, 98],
      [184, -3632],
    ],
    [
      [288006, 484307],
      [-698, 337],
      [-439, -517],
    ],
    [
      [286869, 484127],
      [-30, 11],
    ],
    [
      [286839, 484138],
      [-17, 6],
    ],
    [
      [286822, 484144],
      [-93, 2654],
      [-183, 296],
      [473, 1068],
      [-221, 1526],
      [192, 1728],
      [-400, -1117],
    ],
    [
      [306799, 494742],
      [-208, -3178],
      [83, -580],
      [-394, -2170],
      [-422, 630],
      [40, 936],
    ],
    [
      [305898, 490380],
      [198, 677],
      [-157, 2940],
      [-190, 25],
      [-9, 2185],
    ],
    [
      [178742, 489161],
      [-408, 649],
      [-97, 1865],
      [-374, -2892],
      [-523, -708],
      [-420, -3554],
      [-518, -1775],
      [-403, -257],
    ],
    [
      [175999, 482489],
      [-852, 4014],
    ],
    [
      [305898, 490380],
      [-88, 1150],
      [-333, -4456],
      [-153, 1112],
      [-294, -803],
      [27, 3034],
      [-191, -1422],
    ],
    [
      [304866, 488995],
      [63, 1229],
      [-293, 335],
    ],
    [
      [304636, 490559],
      [138, 2125],
    ],
    [
      [255214, 496196],
      [10, -3137],
      [-283, -948],
      [-252, -4020],
    ],
    [
      [254689, 488091],
      [-861, -9],
    ],
    [
      [253828, 488082],
      [-4, 6503],
    ],
    [
      [296456, 490650],
      [-138, -1260],
    ],
    [
      [296318, 489390],
      [-526, -1476],
      [60, -1043],
    ],
    [
      [295852, 486871],
      [-725, 197],
      [35, -1311],
      [-435, -257],
    ],
    [
      [294727, 485500],
      [-47, 1035],
    ],
    [
      [159209, 482936],
      [-356, -1083],
      [-4, -2172],
    ],
    [
      [158849, 479681],
      [-1695, -61],
      [-4, 1999],
      [-1023, -130],
      [-80, 4507],
      [-584, 9],
      [-506, 918],
      [-251, 1689],
      [-581, 464],
      [-272, -1480],
      [-649, -38],
    ],
    [
      [153204, 487558],
      [120, 7683],
    ],
    [
      [301339, 486191],
      [74, -4637],
    ],
    [
      [301413, 481554],
      [-561, -31],
    ],
    [
      [300852, 481523],
      [-271, 609],
      [-756, 3592],
    ],
    [
      [237874, 493745],
      [1, -6477],
    ],
    [
      [237875, 487268],
      [-1335, -3],
    ],
    [
      [236540, 487265],
      [-340, -1],
    ],
    [
      [236200, 487264],
      [0, 4857],
    ],
    [
      [248644, 494742],
      [4, -1203],
      [-356, -1775],
      [167, -1777],
      [17, -2211],
      [270, -1702],
      [223, -2658],
    ],
    [
      [248969, 483416],
      [-1467, -1],
    ],
    [
      [247502, 483415],
      [1, 1681],
    ],
    [
      [247503, 485096],
      [-1, 7894],
    ],
    [
      [249492, 494673],
      [0, -4905],
    ],
    [
      [249492, 489768],
      [-4, -6324],
    ],
    [
      [249488, 483444],
      [-519, -28],
    ],
    [
      [251473, 494616],
      [2, -4829],
    ],
    [
      [251475, 489787],
      [-785, -8],
    ],
    [
      [250690, 489779],
      [-1198, -11],
    ],
    [
      [252819, 494643],
      [-1, -5695],
    ],
    [
      [252818, 488948],
      [3, -842],
      [-1344, 42],
    ],
    [
      [251477, 488148],
      [-2, 1639],
    ],
    [
      [253828, 488082],
      [-336, -5],
    ],
    [
      [253492, 488077],
      [2, 862],
      [-676, 9],
    ],
    [
      [185140, 489575],
      [-7, -6496],
    ],
    [
      [185133, 483079],
      [-5, -1541],
      [-325, -81],
      [-2, -1617],
      [-333, 6],
      [-31, -3059],
      [-167, -6],
    ],
    [
      [184270, 476781],
      [-988, -2],
      [0, 1538],
      [-762, 6],
      [71, 2286],
      [-253, 1758],
      [-271, -298],
    ],
    [
      [297950, 485840],
      [-1609, 2937],
      [-23, 613],
    ],
    [
      [289857, 482936],
      [-1180, -3640],
      [-620, 942],
    ],
    [
      [288057, 480238],
      [-51, 4069],
    ],
    [
      [289690, 491904],
      [167, -8968],
    ],
    [
      [222306, 488875],
      [139, -1400],
      [-277, -1473],
      [-155, -2285],
      [239, -7],
      [214, -2924],
    ],
    [
      [222466, 480786],
      [-655, 1],
    ],
    [
      [221811, 480787],
      [-251, 2],
      [-1, 4520],
      [-205, 288],
      [-400, -1289],
      [-686, -69],
      [-402, 533],
    ],
    [
      [219866, 484772],
      [-299, 59],
    ],
    [
      [219567, 484831],
      [-77, 2419],
      [-2, 5989],
    ],
    [
      [221692, 493681],
      [38, -1693],
      [582, -1977],
      [-6, -1136],
    ],
    [
      [231478, 493758],
      [-2, -6467],
    ],
    [
      [231476, 487291],
      [-1083, 9],
    ],
    [
      [230393, 487300],
      [2, 6460],
    ],
    [
      [233154, 493749],
      [0, -6482],
    ],
    [
      [233154, 487267],
      [-1644, 21],
    ],
    [
      [231510, 487288],
      [-34, 3],
    ],
    [
      [225159, 493759],
      [1, -4821],
    ],
    [
      [225160, 488938],
      [-1327, -45],
    ],
    [
      [223833, 488893],
      [-333, -2],
    ],
    [
      [223500, 488891],
      [4, 4867],
    ],
    [
      [239886, 493761],
      [1, -6484],
    ],
    [
      [239887, 487277],
      [-11, 1],
    ],
    [
      [239876, 487278],
      [-996, -7],
    ],
    [
      [238880, 487271],
      [0, 6484],
    ],
    [
      [240909, 493739],
      [-31, -6461],
    ],
    [
      [240878, 487278],
      [-991, -1],
    ],
    [
      [230393, 487300],
      [-1213, -24],
    ],
    [
      [229180, 487276],
      [-2, 6464],
    ],
    [
      [238880, 487271],
      [-672, 1],
    ],
    [
      [238208, 487272],
      [-333, -4],
    ],
    [
      [223500, 488891],
      [-1194, -16],
    ],
    [
      [226503, 493734],
      [-5, -6430],
    ],
    [
      [226498, 487304],
      [-323, 15],
    ],
    [
      [226175, 487319],
      [-998, 7],
    ],
    [
      [225177, 487326],
      [-17, 1612],
    ],
    [
      [242579, 492091],
      [0, -4839],
    ],
    [
      [242579, 487252],
      [-1030, -242],
    ],
    [
      [241549, 487010],
      [-671, 268],
    ],
    [
      [234832, 492108],
      [2, -4840],
    ],
    [
      [234834, 487268],
      [-1653, 0],
    ],
    [
      [233181, 487268],
      [-27, -1],
    ],
    [
      [229180, 487276],
      [-670, -7],
    ],
    [
      [228510, 487269],
      [-670, 12],
    ],
    [
      [227840, 487281],
      [-1, 6446],
    ],
    [
      [227840, 487281],
      [-666, 8],
    ],
    [
      [227174, 487289],
      [-676, 15],
    ],
    [
      [244402, 489807],
      [392, -2555],
    ],
    [
      [244794, 487252],
      [-1243, -3],
    ],
    [
      [243551, 487249],
      [-972, 3],
    ],
    [
      [209213, 487370],
      [2, -6520],
    ],
    [
      [209215, 480850],
      [-2355, -66],
    ],
    [
      [206860, 480784],
      [-501, -22],
    ],
    [
      [259400, 493210],
      [11, -6545],
    ],
    [
      [259411, 486665],
      [-1095, 72],
    ],
    [
      [258316, 486737],
      [-227, 4105],
      [347, 2583],
    ],
    [
      [304000, 489648],
      [314, -1030],
    ],
    [
      [304314, 488618],
      [155, 528],
    ],
    [
      [304469, 489146],
      [-41, -1011],
    ],
    [
      [304428, 488135],
      [-42, -1969],
      [-144, 1438],
      [-555, -1235],
      [-307, -2321],
      [164, -2049],
      [-435, -545],
    ],
    [
      [303109, 481454],
      [-386, 1324],
      [-161, 2030],
      [-306, -178],
      [3, 1605],
      [-347, 398],
    ],
    [
      [219567, 484831],
      [-690, 962],
      [-261, -1066],
      [-279, 233],
      [-415, 1973],
      [-376, 207],
    ],
    [
      [217546, 487140],
      [0, -1],
    ],
    [
      [217546, 487140],
      [1, 2864],
    ],
    [
      [200700, 480796],
      [-1181, 20],
    ],
    [
      [199519, 480816],
      [-168, 0],
    ],
    [
      [199351, 480816],
      [0, 2700],
      [-338, -12],
      [1, 1631],
      [-671, 1],
      [0, 1587],
      [-983, 520],
      [0, 1054],
      [-332, 2],
      [5, 1652],
      [-345, 1626],
    ],
    [
      [304469, 489146],
      [96, 425],
      [146, -4224],
      [-211, -753],
      [-72, 3541],
    ],
    [
      [304866, 488995],
      [-144, -863],
      [186, -1284],
      [-136, -1342],
      [-199, 4245],
      [63, 808],
    ],
    [
      [304767, 492693],
      [-187, -2521],
      [-266, -1554],
    ],
    [
      [260730, 493168],
      [6, -6502],
    ],
    [
      [260736, 486666],
      [-1325, -1],
    ],
    [
      [262060, 493155],
      [-5, -6525],
    ],
    [
      [262055, 486630],
      [-1319, 36],
    ],
    [
      [263395, 493087],
      [5, -6429],
    ],
    [
      [263400, 486658],
      [-1345, -28],
    ],
    [
      [264626, 493111],
      [-1, -3070],
    ],
    [
      [264625, 490041],
      [-2, -3183],
    ],
    [
      [264623, 486858],
      [-1223, -200],
    ],
    [
      [247503, 485096],
      [-1667, -111],
    ],
    [
      [245836, 484985],
      [-8, 6443],
      [-167, -12],
    ],
    [
      [173946, 486507],
      [-392, -272],
      [-361, 1627],
    ],
    [
      [292791, 485342],
      [-439, -295],
      [152, -6649],
    ],
    [
      [292504, 478398],
      [55, -2188],
    ],
    [
      [292559, 476210],
      [-1099, -481],
      [-496, 1080],
    ],
    [
      [290964, 476809],
      [-432, 1000],
      [257, 2733],
      [-219, 10862],
    ],
    [
      [236200, 487264],
      [-1352, 5],
    ],
    [
      [234848, 487269],
      [-14, -1],
    ],
    [
      [178798, 488945],
      [-51, -1480],
    ],
    [
      [178747, 487465],
      [-147, -2107],
      [27, -2295],
      [-141, -364],
      [-9, -7526],
    ],
    [
      [178477, 475173],
      [-2, -5280],
      [149, -49],
    ],
    [
      [178624, 469844],
      [-11, -2674],
    ],
    [
      [178613, 467170],
      [-1161, -3],
      [35, 3027],
      [-978, 384],
      [-398, 937],
      [-84, -1083],
      [-654, 1955],
      [-183, 1188],
    ],
    [
      [175190, 473575],
      [801, 17],
      [8, 8897],
    ],
    [
      [290964, 476809],
      [48, -2087],
      [-179, -2358],
    ],
    [
      [290833, 472364],
      [-10, -3429],
    ],
    [
      [290823, 468935],
      [-322, 652],
      [-79, -1360],
      [-540, 1549],
      [-309, -528],
    ],
    [
      [289573, 469248],
      [-20, 3211],
      [418, 3255],
      [-260, 632],
      [239, 1293],
      [-93, 5297],
    ],
    [
      [245836, 484985],
      [-965, 57],
    ],
    [
      [244871, 485042],
      [-77, 2210],
    ],
    [
      [199351, 480816],
      [0, -542],
      [-2071, -233],
      [-330, 808],
      [-664, 541],
      [-332, 1359],
      [-996, 270],
      [0, 1480],
      [-393, 2122],
    ],
    [
      [187141, 488745],
      [-18, -3230],
      [155, 229],
      [839, -2595],
    ],
    [
      [188117, 483149],
      [-2489, -4],
    ],
    [
      [185628, 483145],
      [-495, -66],
    ],
    [
      [169813, 452809],
      [-3140, -59],
    ],
    [
      [166673, 452750],
      [-102, 8],
    ],
    [
      [166571, 452758],
      [-13, 14052],
      [-1606, -147],
      [6, 8084],
      [116, 22],
      [-2, 8039],
    ],
    [
      [199519, 480816],
      [49, -535],
      [44, -12867],
      [-117, 0],
      [4, -6462],
      [53, 0],
    ],
    [
      [199552, 460952],
      [1, -3214],
    ],
    [
      [199553, 457738],
      [-4238, 36],
    ],
    [
      [195315, 457774],
      [-81, 8002],
      [-208, 644],
      [-621, 4049],
      [-508, 1245],
      [-388, 4713],
      [-175, 3678],
      [-827, 30],
    ],
    [
      [265345, 488432],
      [-13, -4364],
      [594, -1608],
    ],
    [
      [265926, 482460],
      [2, -2061],
    ],
    [
      [265928, 480399],
      [-330, 807],
      [-651, 39],
      [-327, 827],
    ],
    [
      [264620, 482072],
      [3, 4786],
    ],
    [
      [264625, 490041],
      [337, 2],
      [2, -1583],
      [381, -28],
    ],
    [
      [217546, 487139],
      [-458, -846],
      [2, -7566],
    ],
    [
      [217090, 478727],
      [-2459, 14],
    ],
    [
      [214631, 478741],
      [-79, 1615],
      [0, 4173],
    ],
    [
      [184270, 476781],
      [-1, -3235],
    ],
    [
      [184269, 473546],
      [-657, 2],
      [18, -6493],
      [161, -1616],
      [-161, -935],
    ],
    [
      [183630, 464504],
      [-371, 1135],
      [-285, -316],
    ],
    [
      [182974, 465323],
      [1, 3349],
      [164, 1],
      [-2, 6527],
      [-838, 4],
    ],
    [
      [182299, 475204],
      [-1840, -8],
    ],
    [
      [180459, 475196],
      [-55, 2307],
      [-390, 208],
      [75, 2719],
      [-486, 2042],
      [-29, 3598],
      [-455, 236],
      [-372, 1159],
    ],
    [
      [189738, 480812],
      [-430, 714],
      [-126, 1528],
      [-434, 6],
    ],
    [
      [188748, 483060],
      [3, 5605],
    ],
    [
      [251477, 488148],
      [-2, -4871],
    ],
    [
      [251475, 483277],
      [-336, -10],
    ],
    [
      [251139, 483267],
      [-664, 186],
    ],
    [
      [250475, 483453],
      [215, 6326],
    ],
    [
      [250475, 483453],
      [-987, -9],
    ],
    [
      [297604, 482655],
      [-139, -531],
      [-150, -6299],
    ],
    [
      [297315, 475825],
      [-695, -198],
      [-380, 613],
    ],
    [
      [296240, 476240],
      [-135, 842],
    ],
    [
      [296105, 477082],
      [40, 1309],
      [261, -315],
      [91, 2132],
      [-178, 490],
      [251, 3497],
      [-332, 632],
      [104, 1629],
      [-255, 1003],
      [-235, -588],
    ],
    [
      [158849, 479681],
      [187, -616],
      [254, -2713],
      [-330, -3406],
      [-529, -206],
      [-3, -1355],
    ],
    [
      [158428, 471385],
      [-331, 3],
      [-437, -1062],
      [-659, -2951],
      [-995, -473],
      [-214, -851],
    ],
    [
      [155792, 466051],
      [-606, -123],
      [-747, 708],
      [-3, 821],
    ],
    [
      [154436, 467457],
      [-267, 104],
    ],
    [
      [154169, 467561],
      [-22, 3824],
      [329, 1721],
      [-5, 3184],
      [-170, -23],
      [1, 3263],
      [-310, 1557],
      [0, 1719],
      [-955, 38],
    ],
    [
      [153037, 482844],
      [167, 4714],
    ],
    [
      [253492, 488077],
      [4, -6488],
    ],
    [
      [253496, 481589],
      [-667, 11],
    ],
    [
      [252829, 481600],
      [-2, 1626],
      [-1352, 51],
    ],
    [
      [225177, 487326],
      [14, -6561],
    ],
    [
      [225191, 480765],
      [-1074, 13],
    ],
    [
      [224117, 480778],
      [-247, -3],
    ],
    [
      [223870, 480775],
      [-37, 8118],
    ],
    [
      [223870, 480775],
      [-1404, 11],
    ],
    [
      [188748, 483060],
      [-631, 89],
    ],
    [
      [254689, 488091],
      [84, -3796],
      [-251, -2704],
    ],
    [
      [254522, 481591],
      [-691, -12],
    ],
    [
      [253831, 481579],
      [-335, 10],
    ],
    [
      [174503, 486508],
      [-1, -3222],
      [107, -1],
      [3, -3245],
      [-111, -9],
      [5, -3147],
    ],
    [
      [174506, 476884],
      [-751, 3480],
      [-169, 2160],
      [-512, 1621],
    ],
    [
      [219866, 484772],
      [49, -6023],
    ],
    [
      [219915, 478749],
      [-2825, -22],
    ],
    [
      [180459, 475196],
      [-612, -25],
    ],
    [
      [179847, 475171],
      [-1370, 2],
    ],
    [
      [296105, 477082],
      [-1078, 260],
    ],
    [
      [295027, 477342],
      [17, 4427],
      [-151, 1362],
      [-345, 330],
      [179, 2039],
    ],
    [
      [212682, 484293],
      [-532, -1541],
      [1, -2393],
    ],
    [
      [212151, 480359],
      [-2937, 18],
    ],
    [
      [209214, 480377],
      [1, 473],
    ],
    [
      [231510, 487288],
      [-3, -6495],
    ],
    [
      [231507, 480793],
      [-1113, 5],
    ],
    [
      [230394, 480798],
      [-1, 6502],
    ],
    [
      [233181, 487268],
      [-6, -6467],
    ],
    [
      [233175, 480801],
      [-1132, -10],
    ],
    [
      [232043, 480791],
      [-536, 2],
    ],
    [
      [239876, 487278],
      [3, -6493],
    ],
    [
      [239879, 480785],
      [-1250, -4],
    ],
    [
      [238629, 480781],
      [-423, 3],
    ],
    [
      [238206, 480784],
      [2, 6488],
    ],
    [
      [241549, 487010],
      [0, -6211],
    ],
    [
      [241549, 480799],
      [-290, -4],
    ],
    [
      [241259, 480795],
      [-1313, -10],
    ],
    [
      [239946, 480785],
      [-67, 0],
    ],
    [
      [244871, 485042],
      [-35, -2081],
      [144, -2160],
    ],
    [
      [244980, 480801],
      [-1096, 2],
    ],
    [
      [243884, 480803],
      [-334, 1],
    ],
    [
      [243550, 480804],
      [1, 6445],
    ],
    [
      [226175, 487319],
      [6, -6546],
    ],
    [
      [226181, 480773],
      [-417, -14],
    ],
    [
      [225764, 480759],
      [-573, 6],
    ],
    [
      [227174, 487289],
      [5, -6502],
    ],
    [
      [227179, 480787],
      [-998, -14],
    ],
    [
      [243550, 480804],
      [-972, -1],
    ],
    [
      [242578, 480803],
      [-1029, -4],
    ],
    [
      [234848, 487269],
      [-4, -6462],
    ],
    [
      [234844, 480807],
      [-164, -8],
    ],
    [
      [234680, 480799],
      [-1319, -4],
    ],
    [
      [233361, 480795],
      [-186, 6],
    ],
    [
      [238206, 480784],
      [-897, -1],
    ],
    [
      [237309, 480783],
      [-772, 10],
    ],
    [
      [236537, 480793],
      [3, 6472],
    ],
    [
      [230394, 480798],
      [-407, 0],
    ],
    [
      [229987, 480798],
      [-908, -8],
    ],
    [
      [229079, 480790],
      [-570, -5],
    ],
    [
      [228509, 480785],
      [1, 6484],
    ],
    [
      [236537, 480793],
      [-544, 5],
    ],
    [
      [235993, 480798],
      [-1149, 9],
    ],
    [
      [228509, 480785],
      [-758, 1],
    ],
    [
      [227751, 480786],
      [-572, 1],
    ],
    [
      [264620, 482072],
      [-6, -1618],
      [-556, -296],
    ],
    [
      [264058, 480158],
      [-662, 0],
    ],
    [
      [263396, 480158],
      [4, 6500],
    ],
    [
      [259411, 486665],
      [-4, -6481],
    ],
    [
      [259407, 480184],
      [-1175, 91],
    ],
    [
      [258232, 480275],
      [-219, 3393],
      [303, 3069],
    ],
    [
      [263396, 480158],
      [-664, 2],
    ],
    [
      [262732, 480160],
      [-666, 1],
    ],
    [
      [262066, 480161],
      [-11, 6469],
    ],
    [
      [303109, 481454],
      [-237, -3363],
      [-309, -544],
      [-343, -4752],
      [-437, 1185],
    ],
    [
      [301783, 473980],
      [56, 1699],
      [-493, 2897],
      [67, 2978],
    ],
    [
      [262066, 480161],
      [-1330, 37],
    ],
    [
      [260736, 480198],
      [0, 6468],
    ],
    [
      [260736, 480198],
      [0, -3238],
    ],
    [
      [260736, 476960],
      [-635, -23],
    ],
    [
      [260101, 476937],
      [-693, 9],
      [-1, 3238],
    ],
    [
      [295027, 477342],
      [-54, -6909],
    ],
    [
      [294973, 470433],
      [-1008, -44],
    ],
    [
      [293965, 470389],
      [167, 2590],
      [-54, 4202],
    ],
    [
      [294078, 477181],
      [-94, 3357],
      [378, 3142],
      [153, 2763],
    ],
    [
      [175190, 473575],
      [-334, 1506],
      [-129, 1913],
      [-221, -110],
    ],
    [
      [294078, 477181],
      [-671, -975],
      [-134, 2686],
      [-769, -494],
    ],
    [
      [221811, 480787],
      [-2, -9343],
    ],
    [
      [221809, 471444],
      [-1850, 12],
    ],
    [
      [219959, 471456],
      [-44, 7293],
    ],
    [
      [300852, 481523],
      [10, -3416],
      [-211, -1325],
    ],
    [
      [300651, 476782],
      [-895, 3175],
      [-123, -380],
      [-353, 2396],
    ],
    [
      [266573, 485031],
      [2, -1116],
      [972, 143],
    ],
    [
      [267547, 484058],
      [-7, -6487],
    ],
    [
      [267540, 477571],
      [-652, -91],
      [7, -1620],
      [-304, -225],
    ],
    [
      [266591, 475635],
      [-654, -32],
    ],
    [
      [265937, 475603],
      [-9, 4796],
    ],
    [
      [265926, 482460],
      [77, 10],
    ],
    [
      [266003, 482470],
      [432, 2693],
      [138, -132],
    ],
    [
      [247502, 483415],
      [3, -1619],
    ],
    [
      [247505, 481796],
      [-1004, -19],
      [9, -2420],
    ],
    [
      [246510, 479357],
      [-1496, -1],
    ],
    [
      [245014, 479356],
      [-34, 1445],
    ],
    [
      [288057, 480238],
      [-163, 246],
      [-201, -2951],
      [7, -3154],
    ],
    [
      [287700, 474379],
      [-304, 516],
    ],
    [
      [287396, 474895],
      [-580, 1634],
      [-156, -692],
      [-618, -119],
    ],
    [
      [286042, 475718],
      [-350, 485],
      [-33, 3060],
    ],
    [
      [285659, 479263],
      [575, 1959],
      [489, 118],
      [146, 2787],
    ],
    [
      [214631, 478741],
      [5, -4840],
      [73, -2432],
    ],
    [
      [214709, 471469],
      [-1978, 12],
    ],
    [
      [212731, 471481],
      [-580, 15],
    ],
    [
      [212151, 471496],
      [0, 8863],
    ],
    [
      [269259, 474627],
      [-1374, -276],
    ],
    [
      [267885, 474351],
      [-11, 2434],
      [-334, 786],
    ],
    [
      [267547, 484058],
      [1423, 278],
    ],
    [
      [268970, 484336],
      [189, -4720],
      [100, -4989],
    ],
    [
      [178613, 467170],
      [-2, -14382],
    ],
    [
      [178611, 452788],
      [-3086, 41],
      [-2428, 19],
    ],
    [
      [173097, 452848],
      [-25, 13],
    ],
    [
      [251139, 483267],
      [-6, -6483],
    ],
    [
      [251133, 476784],
      [-1982, 151],
    ],
    [
      [249151, 476935],
      [334, 1631],
      [4, 3332],
      [-358, 174],
      [-162, 1344],
    ],
    [
      [249151, 476935],
      [-328, -1617],
    ],
    [
      [248823, 475318],
      [-991, -774],
    ],
    [
      [247832, 474544],
      [6, 7269],
      [-333, -17],
    ],
    [
      [252829, 481600],
      [-49, -6493],
    ],
    [
      [252780, 475107],
      [-328, 24],
    ],
    [
      [252452, 475131],
      [-1318, 30],
    ],
    [
      [251134, 475161],
      [-1, 1623],
    ],
    [
      [189740, 477360],
      [2, -5505],
    ],
    [
      [189742, 471855],
      [-1523, -1],
    ],
    [
      [188219, 471854],
      [6, 4870],
      [-654, 10],
      [0, 1562],
      [-614, 11],
      [-169, 1087],
      [-1161, 0],
      [1, 3751],
    ],
    [
      [188219, 471854],
      [-1319, 42],
    ],
    [
      [186900, 471896],
      [-1651, -7],
      [-264, -1313],
    ],
    [
      [184985, 470576],
      [-183, -1642],
      [-532, -4],
      [-1, 4616],
    ],
    [
      [161076, 482939],
      [-47, -16196],
      [1299, -29],
      [11, -13965],
    ],
    [
      [162339, 452749],
      [-1582, 70],
    ],
    [
      [160757, 452819],
      [-2346, 190],
    ],
    [
      [158411, 453009],
      [17, 18376],
    ],
    [
      [166571, 452758],
      [-1779, 10],
    ],
    [
      [164792, 452768],
      [-2453, -19],
    ],
    [
      [289573, 469248],
      [-100, -158],
    ],
    [
      [289473, 469090],
      [-527, -154],
      [-20, 1300],
      [-279, -24],
      [-23, 1968],
      [-519, 2370],
      [-405, -171],
    ],
    [
      [300651, 476782],
      [-32, -195],
    ],
    [
      [300619, 476587],
      [-404, -4938],
    ],
    [
      [300215, 471649],
      [-352, 1116],
      [-342, -274],
      [-74, 1832],
      [-524, -693],
      [-159, 1711],
      [-267, -361],
    ],
    [
      [298497, 474980],
      [-226, 2506],
      [101, 2208],
      [330, 1626],
    ],
    [
      [154169, 467561],
      [-316, -270],
      [-199, -1661],
      [-396, -276],
      [-61, 3940],
      [-268, 1346],
      [-619, 4],
    ],
    [
      [152310, 470644],
      [269, 5888],
      [458, 6312],
    ],
    [
      [298497, 474980],
      [-81, -1100],
    ],
    [
      [298416, 473880],
      [-391, 59],
      [61, 931],
      [-822, -385],
    ],
    [
      [297264, 474485],
      [51, 1340],
    ],
    [
      [301783, 473980],
      [-163, -962],
    ],
    [
      [301620, 473018],
      [-513, -14],
      [127, 1049],
      [-615, 2534],
    ],
    [
      [265937, 475603],
      [-658, -19],
      [7, -1630],
    ],
    [
      [265286, 473954],
      [-1222, -81],
    ],
    [
      [264064, 473873],
      [-6, 6285],
    ],
    [
      [247832, 474544],
      [-657, 679],
    ],
    [
      [247175, 475223],
      [-660, -544],
    ],
    [
      [246515, 474679],
      [-5, 4678],
    ],
    [
      [254522, 481591],
      [-329, -5330],
      [49, -1204],
    ],
    [
      [254242, 475057],
      [-476, 2],
    ],
    [
      [253766, 475059],
      [65, 6520],
    ],
    [
      [253766, 475059],
      [-986, 48],
    ],
    [
      [238629, 480781],
      [-1, -4543],
    ],
    [
      [238628, 476238],
      [-1318, -2],
    ],
    [
      [237310, 476236],
      [-1, 4547],
    ],
    [
      [237308, 469765],
      [-1315, 6],
    ],
    [
      [235993, 469771],
      [0, 6459],
    ],
    [
      [235993, 476230],
      [0, 4568],
    ],
    [
      [237310, 476236],
      [-2, -6471],
    ],
    [
      [234682, 476230],
      [-1321, 3],
    ],
    [
      [233361, 476233],
      [0, 4562],
    ],
    [
      [234680, 480799],
      [2, -4569],
    ],
    [
      [235993, 476230],
      [-1311, 0],
    ],
    [
      [239947, 476239],
      [-1319, -1],
    ],
    [
      [239946, 480785],
      [1, -4546],
    ],
    [
      [233361, 476233],
      [-1320, 43],
    ],
    [
      [232041, 476276],
      [2, 4515],
    ],
    [
      [209214, 480377],
      [5, -8812],
    ],
    [
      [209219, 471565],
      [0, -7313],
    ],
    [
      [209219, 464252],
      [-1679, -40],
    ],
    [
      [207540, 464212],
      [-659, -10],
    ],
    [
      [206881, 464202],
      [-21, 16582],
    ],
    [
      [241256, 475444],
      [-1309, 0],
    ],
    [
      [239947, 475444],
      [0, 795],
    ],
    [
      [241259, 480795],
      [-3, -5351],
    ],
    [
      [232041, 476276],
      [-1933, 42],
    ],
    [
      [230108, 476318],
      [88, 2490],
      [-209, 1990],
    ],
    [
      [242578, 480803],
      [-3, -5357],
    ],
    [
      [242575, 475446],
      [-1319, -2],
    ],
    [
      [245014, 479356],
      [4, -1361],
      [405, -1717],
      [-331, -3302],
    ],
    [
      [245092, 472976],
      [-1192, 26],
    ],
    [
      [243900, 473002],
      [-16, 7801],
    ],
    [
      [243900, 473002],
      [-1326, 21],
    ],
    [
      [242574, 473023],
      [1, 2423],
    ],
    [
      [230108, 476318],
      [224, -745],
      [59, -2539],
    ],
    [
      [230391, 473034],
      [-980, 6],
    ],
    [
      [229411, 473040],
      [-330, 1],
    ],
    [
      [229081, 473041],
      [-2, 7749],
    ],
    [
      [206881, 464202],
      [-1098, -58],
      [7, -3247],
    ],
    [
      [205790, 460897],
      [-134, -6],
      [-121, -2624],
      [-664, 265],
      [160, 2348],
      [-1441, 51],
    ],
    [
      [203590, 460931],
      [-7, -1],
    ],
    [
      [203583, 460930],
      [-6, 19757],
    ],
    [
      [224117, 480778],
      [91, -2393],
      [1571, -3239],
    ],
    [
      [225779, 475146],
      [90, -530],
    ],
    [
      [225869, 474616],
      [-47, -3529],
      [-165, -2603],
    ],
    [
      [225657, 468484],
      [-436, 813],
    ],
    [
      [225221, 469297],
      [-526, 2160],
    ],
    [
      [224695, 471457],
      [-1032, 3041],
      [-78, 1580],
      [-470, 1325],
      [-243, 2027],
      [-406, 1356],
    ],
    [
      [229081, 473041],
      [-659, -11],
    ],
    [
      [228422, 473030],
      [0, 1617],
      [-665, -11],
    ],
    [
      [227757, 474636],
      [-6, 6150],
    ],
    [
      [227757, 474636],
      [-663, -12],
    ],
    [
      [227094, 474624],
      [-1225, -8],
    ],
    [
      [225779, 475146],
      [-15, 5613],
    ],
    [
      [203583, 460930],
      [-1530, -51],
      [-2501, 73],
    ],
    [
      [224695, 471457],
      [-2109, -11],
    ],
    [
      [222586, 471446],
      [-777, -2],
    ],
    [
      [212151, 471496],
      [-1401, 0],
    ],
    [
      [210750, 471496],
      [-1531, 69],
    ],
    [
      [260101, 476937],
      [-1, -1635],
    ],
    [
      [260100, 475302],
      [-329, 14],
      [4, -1620],
      [-1016, -10],
    ],
    [
      [258759, 473686],
      [-527, 6589],
    ],
    [
      [262732, 480160],
      [25, -6465],
    ],
    [
      [262757, 473695],
      [-1325, -1],
    ],
    [
      [261432, 473694],
      [1, 3265],
      [-697, 1],
    ],
    [
      [195315, 457774],
      [-2814, 132],
    ],
    [
      [192501, 457906],
      [-1359, 125],
      [-5, 3185],
      [-131, -2],
      [32, 14646],
    ],
    [
      [264064, 473873],
      [0, -195],
    ],
    [
      [264064, 473678],
      [-1307, 17],
    ],
    [
      [246515, 474679],
      [-1111, -3345],
      [-255, -73],
    ],
    [
      [245149, 471261],
      [-57, 1715],
    ],
    [
      [286042, 475718],
      [-35, -4144],
      [115, -2963],
      [261, 67],
      [229, -1455],
    ],
    [
      [286612, 467223],
      [26, -2749],
    ],
    [
      [286638, 464474],
      [-1117, -2],
    ],
    [
      [285521, 464472],
      [-187, 1943],
      [55, 5513],
    ],
    [
      [285389, 471928],
      [-24, 5905],
    ],
    [
      [285365, 477833],
      [294, 1430],
    ],
    [
      [293965, 470389],
      [-115, -2942],
    ],
    [
      [293850, 467447],
      [-369, -82],
    ],
    [
      [293481, 467365],
      [-266, 2462],
      [-523, 832],
    ],
    [
      [292692, 470659],
      [-14, 505],
    ],
    [
      [292678, 471164],
      [-119, 5046],
    ],
    [
      [217090, 478727],
      [0, -7285],
    ],
    [
      [217090, 471442],
      [-2381, 27],
    ],
    [
      [219959, 471456],
      [-2869, -14],
    ],
    [
      [281818, 478275],
      [-5, -4317],
    ],
    [
      [281813, 473958],
      [-1305, -81],
    ],
    [
      [280508, 473877],
      [0, 4510],
    ],
    [
      [280508, 478387],
      [1310, -112],
    ],
    [
      [280508, 473877],
      [4, -838],
    ],
    [
      [280512, 473039],
      [-752, 7],
      [-414, -1125],
    ],
    [
      [279346, 471921],
      [-518, 1158],
      [12, 3307],
      [719, 1122],
      [949, 879],
    ],
    [
      [283548, 476628],
      [9, -4501],
    ],
    [
      [283557, 472127],
      [-318, -2],
      [8, -1701],
      [-272, 15],
    ],
    [
      [282975, 470439],
      [-429, 33],
      [9, 794],
      [-498, -13],
    ],
    [
      [282057, 471253],
      [10, 2716],
      [-254, -11],
    ],
    [
      [281818, 478275],
      [657, -443],
      [527, -1887],
      [546, 683],
    ],
    [
      [285389, 471928],
      [-697, -201],
    ],
    [
      [284692, 471727],
      [-474, 498],
      [-661, -98],
    ],
    [
      [283548, 476628],
      [635, 251],
      [429, -526],
      [753, 1480],
    ],
    [
      [267885, 474351],
      [36, -4847],
    ],
    [
      [267921, 469504],
      [-333, -96],
    ],
    [
      [267588, 469408],
      [-976, -152],
    ],
    [
      [266612, 469256],
      [-21, 6379],
    ],
    [
      [192501, 457906],
      [15, -12908],
    ],
    [
      [192516, 444998],
      [-2779, 45],
    ],
    [
      [189737, 445043],
      [-3, 7851],
    ],
    [
      [189734, 452894],
      [0, 9524],
    ],
    [
      [189734, 462418],
      [8, 9437],
    ],
    [
      [296240, 476240],
      [-137, -2650],
      [-357, 111],
      [-31, -3519],
      [241, -61],
      [-25, -3494],
    ],
    [
      [295931, 466627],
      [-259, 37],
    ],
    [
      [295672, 466664],
      [-674, 89],
    ],
    [
      [294998, 466753],
      [-25, 3680],
    ],
    [
      [251134, 475161],
      [-13, -6521],
    ],
    [
      [251121, 468640],
      [-991, -43],
    ],
    [
      [250130, 468597],
      [-1307, 230],
    ],
    [
      [248823, 468827],
      [0, 6491],
    ],
    [
      [261432, 473694],
      [8, -6496],
    ],
    [
      [261440, 467198],
      [-657, -32],
    ],
    [
      [260783, 467166],
      [-660, 1],
    ],
    [
      [260123, 467167],
      [-23, 8135],
    ],
    [
      [292678, 471164],
      [-1239, 47],
      [-606, 1153],
    ],
    [
      [301620, 473018],
      [189, 655],
      [301, -1389],
      [-295, -3187],
    ],
    [
      [301815, 469097],
      [-594, -238],
      [-94, -983],
      [-503, -1186],
    ],
    [
      [300624, 466690],
      [-506, 1531],
      [-67, 1971],
      [164, 1457],
    ],
    [
      [287396, 474895],
      [215, -2005],
      [56, -5300],
    ],
    [
      [287667, 467590],
      [-1055, -367],
    ],
    [
      [297264, 474485],
      [21, -2797],
      [-310, -2918],
      [270, -2372],
    ],
    [
      [297245, 466398],
      [-1314, 229],
    ],
    [
      [232041, 476276],
      [7, -6486],
    ],
    [
      [232048, 469790],
      [-1892, -6],
    ],
    [
      [230156, 469784],
      [78, 2628],
      [157, 622],
    ],
    [
      [233361, 476233],
      [0, -6428],
    ],
    [
      [233361, 469805],
      [-1313, -15],
    ],
    [
      [239947, 475444],
      [-4, -5679],
    ],
    [
      [239943, 469765],
      [-1318, 14],
    ],
    [
      [238625, 469779],
      [3, 6459],
    ],
    [
      [238625, 469779],
      [-1317, -14],
    ],
    [
      [235993, 469771],
      [-1311, 30],
    ],
    [
      [234682, 469801],
      [0, 6429],
    ],
    [
      [234682, 469801],
      [-1321, 4],
    ],
    [
      [266612, 469256],
      [-658, -171],
      [8, -1638],
    ],
    [
      [265962, 467447],
      [-657, -45],
    ],
    [
      [265305, 467402],
      [-19, 6552],
    ],
    [
      [242574, 473023],
      [-2, -3272],
    ],
    [
      [242572, 469751],
      [-1316, 2],
    ],
    [
      [241256, 469753],
      [0, 5691],
    ],
    [
      [241256, 469753],
      [-1313, 12],
    ],
    [
      [248823, 468827],
      [0, -812],
    ],
    [
      [248823, 468015],
      [-1640, -18],
    ],
    [
      [247183, 467997],
      [-8, 7226],
    ],
    [
      [247183, 467997],
      [1, -5692],
    ],
    [
      [247184, 462305],
      [-597, 23],
    ],
    [
      [246587, 462328],
      [-172, 2280],
      [-542, 826],
    ],
    [
      [245873, 465434],
      [-469, 1433],
      [-255, 4394],
    ],
    [
      [300624, 466690],
      [-29, -121],
    ],
    [
      [300595, 466569],
      [-107, -729],
      [-1684, 270],
    ],
    [
      [298804, 466110],
      [-84, 15],
    ],
    [
      [298720, 466125],
      [-215, 4313],
      [-157, 16],
      [68, 3426],
    ],
    [
      [260123, 467167],
      [-1187, -13],
    ],
    [
      [258936, 467154],
      [-50, 4104],
      [-127, 2428],
    ],
    [
      [182299, 475204],
      [2, -6522],
      [-141, -1591],
      [-469, 14],
    ],
    [
      [181691, 467105],
      [-327, 810],
      [-823, 11],
      [-55, 776],
      [-638, 0],
    ],
    [
      [179848, 468702],
      [-1, 6469],
    ],
    [
      [179848, 468702],
      [-63, -3749],
    ],
    [
      [179785, 464953],
      [-565, 398],
      [-162, 1616],
    ],
    [
      [179058, 466967],
      [-63, 1875],
      [-371, 1002],
    ],
    [
      [252452, 475131],
      [-16, -6573],
    ],
    [
      [252436, 468558],
      [-656, -5],
    ],
    [
      [251780, 468553],
      [-659, 87],
    ],
    [
      [182974, 465323],
      [-238, -239],
      [-275, -2258],
      [-767, 3],
    ],
    [
      [181694, 462829],
      [-3, 4276],
    ],
    [
      [253766, 475059],
      [-16, -6494],
    ],
    [
      [253750, 468565],
      [-660, -23],
    ],
    [
      [253090, 468542],
      [-654, 16],
    ],
    [
      [254242, 475057],
      [185, -6512],
    ],
    [
      [254427, 468545],
      [-677, 20],
    ],
    [
      [289473, 469090],
      [-133, -2371],
    ],
    [
      [289340, 466719],
      [-1655, -378],
    ],
    [
      [287685, 466341],
      [-18, 1249],
    ],
    [
      [298720, 466125],
      [-986, 173],
    ],
    [
      [297734, 466298],
      [-489, 100],
    ],
    [
      [228422, 473030],
      [0, -5278],
    ],
    [
      [228422, 467752],
      [-410, 1264],
      [-493, -321],
    ],
    [
      [227519, 468695],
      [-420, 21],
    ],
    [
      [227099, 468716],
      [-5, 5908],
    ],
    [
      [227099, 468716],
      [-647, 153],
      [-239, -1688],
      [-225, -93],
      [-331, 1396],
    ],
    [
      [265305, 467402],
      [-657, -79],
    ],
    [
      [264648, 467323],
      [-572, -16],
    ],
    [
      [264076, 467307],
      [-12, 6371],
    ],
    [
      [282057, 471253],
      [-126, -2331],
    ],
    [
      [281931, 468922],
      [-1419, 93],
    ],
    [
      [280512, 469015],
      [0, 4024],
    ],
    [
      [262757, 473695],
      [0, -6486],
    ],
    [
      [262757, 467209],
      [-661, 6],
    ],
    [
      [262096, 467215],
      [-656, -17],
    ],
    [
      [264076, 467307],
      [-666, -109],
    ],
    [
      [263410, 467198],
      [-653, 11],
    ],
    [
      [184985, 470576],
      [7, -703],
      [463, 1104],
      [183, -1172],
      [54, -3295],
      [325, -1616],
      [-111, -2670],
    ],
    [
      [185906, 462224],
      [-218, 4],
      [1, -1621],
      [-435, 0],
      [0, -1622],
      [-966, -25],
    ],
    [
      [184288, 458960],
      [-6, 4851],
      [-652, 693],
    ],
    [
      [279029, 472686],
      [285, -724],
      [-124, -1251],
      [-161, 1975],
    ],
    [
      [280512, 469015],
      [1, -6165],
    ],
    [
      [280513, 462850],
      [-1219, -1634],
    ],
    [
      [279294, 461216],
      [-444, 1662],
    ],
    [
      [278850, 462878],
      [-210, 586],
    ],
    [
      [278640, 463464],
      [245, 2248],
      [543, 1799],
      [-163, 3149],
      [81, 1261],
    ],
    [
      [230156, 469784],
      [-257, -2656],
      [18, -1098],
      [319, -1424],
      [30, -1300],
    ],
    [
      [230266, 463306],
      [144, -1322],
    ],
    [
      [230410, 461984],
      [-513, 643],
    ],
    [
      [229897, 462627],
      [-187, 2346],
      [-300, 1001],
    ],
    [
      [229410, 465974],
      [1, 7066],
    ],
    [
      [229410, 465974],
      [-586, 1070],
    ],
    [
      [228824, 467044],
      [-402, 708],
    ],
    [
      [243900, 473002],
      [-5, -8148],
    ],
    [
      [243895, 464854],
      [-1323, -36],
    ],
    [
      [242572, 464818],
      [0, 4933],
    ],
    [
      [245873, 465434],
      [-1, -548],
      [-656, 3],
    ],
    [
      [245216, 464889],
      [-1321, -35],
    ],
    [
      [292692, 470659],
      [24, -1088],
      [-500, -1877],
    ],
    [
      [292216, 467694],
      [-534, -438],
      [-538, 1053],
    ],
    [
      [291144, 468309],
      [-321, 626],
    ],
    [
      [284692, 471727],
      [-24, -4636],
    ],
    [
      [284668, 467091],
      [-949, -53],
      [-149, -3444],
    ],
    [
      [283570, 463594],
      [-344, 17],
    ],
    [
      [283226, 463611],
      [9, 1733],
      [-312, 31],
      [52, 5064],
    ],
    [
      [186900, 471896],
      [-241, -2319],
      [147, -3747],
      [355, 398],
      [146, -1874],
      [116, -3740],
    ],
    [
      [187423, 460614],
      [-60, -2973],
      [-639, 535],
    ],
    [
      [186724, 458176],
      [-180, 1226],
      [-380, -494],
      [84, 2409],
      [-342, 907],
    ],
    [
      [189734, 462418],
      [-524, -641],
      [35, 1743],
      [-1064, 341],
      [14, -3257],
    ],
    [
      [188195, 460604],
      [-772, 10],
    ],
    [
      [285521, 464472],
      [224, -1369],
      [-308, -60],
    ],
    [
      [285437, 463043],
      [-555, -97],
    ],
    [
      [284882, 462946],
      [2, 2100],
      [-216, 2045],
    ],
    [
      [210915, 461007],
      [1, -6453],
      [118, -1623],
    ],
    [
      [211034, 452931],
      [-1815, -34],
    ],
    [
      [209219, 452897],
      [0, 11355],
    ],
    [
      [210750, 471496],
      [65, -4017],
      [1, -6473],
      [99, 1],
    ],
    [
      [214922, 454659],
      [-168, -1624],
    ],
    [
      [214754, 453035],
      [-1702, -71],
    ],
    [
      [213052, 452964],
      [-57, -9],
    ],
    [
      [212995, 452955],
      [-124, 1630],
      [-20, 6469],
      [-66, 0],
    ],
    [
      [212785, 461054],
      [0, 6430],
      [-54, 3997],
    ],
    [
      [214709, 471469],
      [45, -10348],
      [76, -3],
      [-4, -6452],
      [96, -7],
    ],
    [
      [212785, 461054],
      [-1870, -47],
    ],
    [
      [225221, 469297],
      [13, -2261],
    ],
    [
      [225234, 467036],
      [-777, 345],
      [-862, 1235],
      [-166, 869],
      [-848, -1646],
    ],
    [
      [222581, 467839],
      [5, 3607],
    ],
    [
      [222581, 467839],
      [-781, -1614],
      [-391, 247],
    ],
    [
      [221409, 466472],
      [-1271, 1432],
      [-179, 713],
    ],
    [
      [219959, 468617],
      [0, 2839],
    ],
    [
      [219959, 468617],
      [86, -14154],
    ],
    [
      [220045, 454463],
      [-279, 6],
    ],
    [
      [219766, 454469],
      [-1612, 38],
    ],
    [
      [218154, 454507],
      [-1617, 75],
    ],
    [
      [216537, 454582],
      [-1615, 77],
    ],
    [
      [158411, 453009],
      [-2622, -72],
    ],
    [
      [155789, 452937],
      [3, 13114],
    ],
    [
      [283226, 463611],
      [-473, 58],
      [-176, -2031],
    ],
    [
      [282577, 461638],
      [-328, 866],
      [-551, 60],
    ],
    [
      [281698, 462564],
      [229, 2735],
      [4, 3623],
    ],
    [
      [294998, 466753],
      [-244, -4393],
    ],
    [
      [294754, 462360],
      [-1202, -852],
    ],
    [
      [293552, 461508],
      [61, 2653],
      [237, 3286],
    ],
    [
      [293481, 467365],
      [-1033, -910],
    ],
    [
      [292448, 466455],
      [-257, -293],
      [25, 1532],
    ],
    [
      [154436, 467457],
      [-322, -2815],
      [-164, -2462],
      [-328, -79],
      [-48, -2548],
      [372, -119],
      [281, -2180],
      [-183, -1761],
      [99, -2713],
    ],
    [
      [154143, 452780],
      [-1087, 57],
    ],
    [
      [153056, 452837],
      [-394, 1953],
      [-208, 4302],
      [105, 4473],
      [-71, 1599],
      [-264, 1356],
      [-114, 2029],
      [200, 2095],
    ],
    [
      [179785, 464953],
      [674, -952],
      [683, -1920],
      [360, 583],
    ],
    [
      [181502, 462664],
      [-185, -223],
      [-6, -1813],
      [-601, 5],
      [9, -7878],
    ],
    [
      [180719, 452755],
      [-2108, 33],
    ],
    [
      [232048, 469790],
      [-2, -6489],
    ],
    [
      [232046, 463301],
      [-1780, 5],
    ],
    [
      [234682, 469801],
      [-2, -6505],
    ],
    [
      [234680, 463296],
      [-1319, 27],
    ],
    [
      [233361, 463323],
      [0, 6482],
    ],
    [
      [233361, 463323],
      [-985, -8],
    ],
    [
      [232376, 463315],
      [-330, -14],
    ],
    [
      [235993, 469771],
      [0, -4895],
    ],
    [
      [235993, 464876],
      [0, -1607],
    ],
    [
      [235993, 463269],
      [-1313, 27],
    ],
    [
      [238625, 469779],
      [-3, -6531],
    ],
    [
      [238622, 463248],
      [-1315, 8],
    ],
    [
      [237307, 463256],
      [0, 1612],
    ],
    [
      [237307, 464868],
      [1, 4897],
    ],
    [
      [237307, 464868],
      [-1314, 8],
    ],
    [
      [239943, 469765],
      [-4, -6533],
    ],
    [
      [239939, 463232],
      [-1317, 16],
    ],
    [
      [241256, 469753],
      [-1, -4930],
    ],
    [
      [241255, 464823],
      [1, -1610],
    ],
    [
      [241256, 463213],
      [-1317, 19],
    ],
    [
      [242572, 464818],
      [-1317, 5],
    ],
    [
      [291144, 468309],
      [49, -3778],
      [-226, -2026],
    ],
    [
      [290967, 462505],
      [-369, -97],
      [-984, -2871],
      [-607, -823],
    ],
    [
      [289007, 458714],
      [112, 1789],
      [-67, 1881],
      [190, 1044],
      [98, 3291],
    ],
    [
      [225234, 467036],
      [12, -6037],
    ],
    [
      [225246, 460999],
      [0, -6480],
    ],
    [
      [225246, 454519],
      [-1283, -6],
    ],
    [
      [223963, 454513],
      [-1286, -11],
    ],
    [
      [222677, 454502],
      [-33, 1],
    ],
    [
      [222644, 454503],
      [-31, 12930],
      [-32, 406],
    ],
    [
      [268694, 465642],
      [-316, -1305],
      [-142, -3076],
    ],
    [
      [268236, 461261],
      [-593, -71],
    ],
    [
      [267643, 461190],
      [-55, 8218],
    ],
    [
      [267921, 469504],
      [681, 71],
      [92, -3933],
    ],
    [
      [267643, 461190],
      [-1306, -223],
    ],
    [
      [266337, 460967],
      [-314, -75],
    ],
    [
      [266023, 460892],
      [-61, 6555],
    ],
    [
      [301815, 469097],
      [129, -3011],
      [504, -1291],
      [-172, -917],
      [-656, -1009],
      [-20, -1435],
      [-247, -532],
    ],
    [
      [301353, 460902],
      [-115, 246],
    ],
    [
      [301238, 461148],
      [-90, 2974],
      [-550, 962],
      [-3, 1485],
    ],
    [
      [227519, 468695],
      [-2, -7665],
    ],
    [
      [227517, 461030],
      [-972, -16],
    ],
    [
      [226545, 461014],
      [-1299, -15],
    ],
    [
      [228824, 467044],
      [-4, -7638],
    ],
    [
      [228820, 459406],
      [-975, 10],
    ],
    [
      [227845, 459416],
      [1, 1618],
      [-329, -4],
    ],
    [
      [281698, 462564],
      [-753, 1],
    ],
    [
      [280945, 462565],
      [-432, 285],
    ],
    [
      [250130, 468597],
      [9, -6418],
    ],
    [
      [250139, 462179],
      [-99, 3],
    ],
    [
      [250040, 462182],
      [-1216, 98],
    ],
    [
      [248824, 462280],
      [-1, 5735],
    ],
    [
      [181694, 462829],
      [-192, -165],
    ],
    [
      [251780, 468553],
      [1, -6529],
    ],
    [
      [251781, 462024],
      [-457, 58],
    ],
    [
      [251324, 462082],
      [-1185, 97],
    ],
    [
      [221409, 466472],
      [43, -12006],
    ],
    [
      [221452, 454466],
      [-68, 2],
    ],
    [
      [221384, 454468],
      [-1339, -5],
    ],
    [
      [253090, 468542],
      [2, -4305],
    ],
    [
      [253092, 464237],
      [4, -2164],
    ],
    [
      [253096, 462073],
      [-1122, -19],
    ],
    [
      [251974, 462054],
      [-193, -30],
    ],
    [
      [254427, 468545],
      [185, -1078],
      [-127, -2165],
    ],
    [
      [254485, 465302],
      [-1064, 31],
      [0, -1088],
      [-329, -8],
    ],
    [
      [292448, 466455],
      [-171, -3336],
      [-36, -2654],
    ],
    [
      [292241, 460465],
      [-527, -991],
    ],
    [
      [291714, 459474],
      [-489, 1291],
      [-258, 1740],
    ],
    [
      [293552, 461508],
      [-1311, -1043],
    ],
    [
      [248824, 462280],
      [-247, 1],
    ],
    [
      [248577, 462281],
      [-1393, 24],
    ],
    [
      [222644, 454503],
      [-1192, -37],
    ],
    [
      [287015, 460504],
      [-343, -51],
    ],
    [
      [286672, 460453],
      [-34, 4021],
    ],
    [
      [287685, 466341],
      [71, -5738],
    ],
    [
      [287756, 460603],
      [-741, -99],
    ],
    [
      [155789, 452937],
      [-801, -56],
    ],
    [
      [154988, 452881],
      [-845, -101],
    ],
    [
      [266023, 460892],
      [-1299, -123],
    ],
    [
      [264724, 460769],
      [-27, 1],
    ],
    [
      [264697, 460770],
      [-49, 6553],
    ],
    [
      [264697, 460770],
      [-1281, -49],
    ],
    [
      [263416, 460721],
      [-6, 6477],
    ],
    [
      [263416, 460721],
      [-329, -8],
    ],
    [
      [263087, 460713],
      [-985, -2],
    ],
    [
      [262102, 460711],
      [-6, 6504],
    ],
    [
      [262102, 460711],
      [-632, -30],
    ],
    [
      [261470, 460681],
      [-681, 30],
    ],
    [
      [260789, 460711],
      [-6, 6455],
    ],
    [
      [260789, 460711],
      [-616, -16],
    ],
    [
      [260173, 460695],
      [-1419, -24],
    ],
    [
      [258754, 460671],
      [182, 6483],
    ],
    [
      [284882, 462946],
      [17, -1460],
      [-606, 385],
    ],
    [
      [284293, 461871],
      [-102, 1733],
      [-621, -10],
    ],
    [
      [229897, 462627],
      [-253, 6],
      [-13, -4592],
    ],
    [
      [229631, 458041],
      [-268, -255],
    ],
    [
      [229363, 457786],
      [-543, 3],
      [0, 1617],
    ],
    [
      [295672, 466664],
      [201, -1871],
      [-69, -1578],
    ],
    [
      [295804, 463215],
      [-69, -4535],
    ],
    [
      [295735, 458680],
      [-148, -5073],
    ],
    [
      [295587, 453607],
      [-1209, 182],
    ],
    [
      [294378, 453789],
      [-26, 1],
    ],
    [
      [294352, 453790],
      [-31, 692],
      [433, 7878],
    ],
    [
      [289007, 458714],
      [-9, -2219],
    ],
    [
      [288998, 456495],
      [-609, 12],
      [-4, 988],
      [-551, -86],
    ],
    [
      [287834, 457409],
      [-78, 3194],
    ],
    [
      [297645, 459260],
      [-169, 1432],
      [-916, -278],
      [9, 882],
      [-474, 583],
      [-291, 1336],
    ],
    [
      [297734, 466298],
      [148, -1093],
      [-43, -2779],
      [-194, -3166],
    ],
    [
      [301238, 461148],
      [-70, -970],
    ],
    [
      [301168, 460178],
      [-67, -41],
    ],
    [
      [301101, 460137],
      [28, -397],
    ],
    [
      [301129, 459740],
      [-2, -4],
    ],
    [
      [301127, 459736],
      [-256, -726],
    ],
    [
      [300871, 459010],
      [-22, -492],
    ],
    [
      [300849, 458518],
      [-74, -390],
    ],
    [
      [300775, 458128],
      [-385, 553],
      [-40, -2081],
      [-373, -819],
    ],
    [
      [299977, 455781],
      [-294, 713],
      [-11, 1200],
      [281, 1310],
      [-388, 368],
      [185, 1162],
      [57, 2438],
      [-390, -234],
      [39, 1513],
      [-540, 411],
      [-112, 1448],
    ],
    [
      [299977, 455781],
      [-56, -2596],
    ],
    [
      [299921, 453185],
      [-840, -171],
    ],
    [
      [299081, 453014],
      [-844, 387],
    ],
    [
      [298237, 453401],
      [-93, 27],
    ],
    [
      [298144, 453428],
      [2, 2448],
      [-359, 413],
      [119, 1141],
    ],
    [
      [297906, 457430],
      [27, 1226],
      [-288, 604],
    ],
    [
      [184288, 458960],
      [0, -6128],
    ],
    [
      [184288, 452832],
      [-2898, -86],
    ],
    [
      [181390, 452746],
      [-671, 9],
    ],
    [
      [246587, 462328],
      [-37, -556],
      [502, -1805],
    ],
    [
      [247052, 459967],
      [-532, 14],
      [-2, -1629],
      [-648, 10],
    ],
    [
      [245870, 458362],
      [-647, 9],
    ],
    [
      [245223, 458371],
      [-7, 6518],
    ],
    [
      [254485, 465302],
      [3, -3266],
    ],
    [
      [254488, 462036],
      [-1092, 47],
    ],
    [
      [253396, 462083],
      [-300, -10],
    ],
    [
      [245223, 458371],
      [-649, 13],
    ],
    [
      [244574, 458384],
      [-652, -1],
    ],
    [
      [243922, 458383],
      [-27, 6471],
    ],
    [
      [237307, 463256],
      [111, -1603],
      [1, -4876],
    ],
    [
      [237419, 456777],
      [-650, -3],
    ],
    [
      [236769, 456774],
      [-649, -15],
    ],
    [
      [236120, 456759],
      [0, 4918],
      [-127, 1592],
    ],
    [
      [243922, 458383],
      [-651, 50],
    ],
    [
      [243271, 458433],
      [-651, -34],
    ],
    [
      [242620, 458399],
      [-48, 6419],
    ],
    [
      [242620, 458399],
      [-653, 5],
    ],
    [
      [241967, 458404],
      [-654, -10],
    ],
    [
      [241313, 458394],
      [-57, 4819],
    ],
    [
      [286672, 460453],
      [9, -2065],
      [-460, 403],
      [-3, -1030],
      [-340, 349],
    ],
    [
      [285878, 458110],
      [-226, 21],
    ],
    [
      [285652, 458131],
      [-201, 28],
      [-14, 4884],
    ],
    [
      [209219, 452897],
      [0, -5656],
    ],
    [
      [209219, 447241],
      [2, -2488],
    ],
    [
      [209221, 444753],
      [-1673, 14],
      [-1, 1638],
    ],
    [
      [207547, 446405],
      [-7, 17807],
    ],
    [
      [207547, 446405],
      [-1742, 68],
    ],
    [
      [205805, 446473],
      [-15, 14424],
    ],
    [
      [189734, 452894],
      [-1286, -39],
    ],
    [
      [188448, 452855],
      [-243, 1756],
      [-87, 2141],
      [178, 1265],
      [-101, 2587],
    ],
    [
      [284293, 461871],
      [21, -3935],
      [375, 114],
    ],
    [
      [284689, 458050],
      [-2, -5187],
    ],
    [
      [284687, 452863],
      [-1795, -1],
    ],
    [
      [282892, 452862],
      [-390, -48],
    ],
    [
      [282502, 452814],
      [75, 8824],
    ],
    [
      [278850, 462878],
      [-1, -10015],
    ],
    [
      [278849, 452863],
      [-1529, -47],
    ],
    [
      [277320, 452816],
      [-423, 27],
      [0, 5034],
    ],
    [
      [276897, 457877],
      [602, 1758],
      [633, 2570],
      [508, 1259],
    ],
    [
      [232376, 463315],
      [-1, -1600],
      [202, -13],
      [-4, -4901],
    ],
    [
      [232573, 456801],
      [-1911, 67],
    ],
    [
      [230662, 456868],
      [1, 1145],
    ],
    [
      [230663, 458013],
      [-154, 1201],
      [39, 2679],
      [-138, 91],
    ],
    [
      [234680, 463296],
      [161, -1608],
      [-5, -4919],
    ],
    [
      [234836, 456769],
      [-647, 13],
    ],
    [
      [234189, 456782],
      [-648, 10],
    ],
    [
      [233541, 456792],
      [3, 4901],
      [-183, 1630],
    ],
    [
      [233541, 456792],
      [-968, 9],
    ],
    [
      [236120, 456759],
      [-644, 5],
    ],
    [
      [235476, 456764],
      [-640, 5],
    ],
    [
      [238622, 463248],
      [103, -1618],
      [-1, -4868],
    ],
    [
      [238724, 456762],
      [-656, 0],
    ],
    [
      [238068, 456762],
      [-649, 15],
    ],
    [
      [239939, 463232],
      [70, -6471],
    ],
    [
      [240009, 456761],
      [-640, -7],
    ],
    [
      [239369, 456754],
      [-645, 8],
    ],
    [
      [241313, 458394],
      [-653, -6],
      [2, -1611],
    ],
    [
      [240662, 456777],
      [-653, -16],
    ],
    [
      [297906, 457430],
      [-486, -1106],
      [-24, 856],
      [-523, -374],
      [-118, 1160],
      [-154, -1681],
      [-621, 1035],
      [76, 1737],
      [-321, -377],
    ],
    [
      [285652, 458131],
      [-963, -81],
    ],
    [
      [280945, 462565],
      [4, -9702],
    ],
    [
      [280949, 452863],
      [-1704, -50],
    ],
    [
      [279245, 452813],
      [-396, 50],
    ],
    [
      [230663, 458013],
      [-1032, 28],
    ],
    [
      [282502, 452814],
      [-1272, 49],
    ],
    [
      [281230, 452863],
      [-281, 0],
    ],
    [
      [291714, 459474],
      [-261, -2859],
      [238, -600],
    ],
    [
      [291691, 456015],
      [-916, -2845],
    ],
    [
      [290775, 453170],
      [-1003, -3057],
    ],
    [
      [289772, 450113],
      [-329, 184],
      [-290, 2566],
    ],
    [
      [289153, 452863],
      [-165, 772],
      [10, 2860],
    ],
    [
      [248577, 462281],
      [18, -5761],
    ],
    [
      [248595, 456520],
      [-1108, -48],
    ],
    [
      [247487, 456472],
      [-211, 623],
      [-224, 2872],
    ],
    [
      [294352, 453790],
      [-85, -1335],
      [-514, 519],
      [-607, 1358],
    ],
    [
      [293146, 454332],
      [53, 903],
    ],
    [
      [293199, 455235],
      [352, 2851],
      [1, 3422],
    ],
    [
      [249240, 456572],
      [-645, -52],
    ],
    [
      [250040, 462182],
      [14, -5553],
    ],
    [
      [250054, 456629],
      [-814, -57],
    ],
    [
      [251324, 462082],
      [2, -6381],
    ],
    [
      [251326, 455701],
      [-650, -38],
      [2, 1005],
      [-624, -39],
    ],
    [
      [186724, 458176],
      [47, -5357],
    ],
    [
      [186771, 452819],
      [-155, 1],
    ],
    [
      [186616, 452820],
      [-2328, 12],
    ],
    [
      [253396, 462083],
      [-8, -6346],
    ],
    [
      [253388, 455737],
      [-109, 0],
    ],
    [
      [253279, 455737],
      [-975, -13],
    ],
    [
      [252304, 455724],
      [-325, 0],
    ],
    [
      [251979, 455724],
      [-5, 6330],
    ],
    [
      [251979, 455724],
      [-653, -23],
    ],
    [
      [254614, 455702],
      [-1226, 35],
    ],
    [
      [254488, 462036],
      [-82, -3532],
      [208, -2802],
    ],
    [
      [293199, 455235],
      [-367, 801],
      [-92, -1372],
      [-647, 330],
      [-402, 1021],
    ],
    [
      [301127, 459736],
      [69, -1616],
    ],
    [
      [301196, 458120],
      [-253, -1012],
      [-168, 1020],
    ],
    [
      [300849, 458518],
      [22, 492],
    ],
    [
      [227845, 459416],
      [-2, -4858],
    ],
    [
      [227843, 454558],
      [-1298, -23],
    ],
    [
      [226545, 454535],
      [0, 6479],
    ],
    [
      [212995, 452955],
      [-1853, -35],
    ],
    [
      [211142, 452920],
      [-108, 11],
    ],
    [
      [226545, 454535],
      [3, -3229],
    ],
    [
      [226548, 451306],
      [-1288, -24],
    ],
    [
      [225260, 451282],
      [-14, 3237],
    ],
    [
      [266372, 454459],
      [-653, -60],
    ],
    [
      [265719, 454399],
      [-998, -201],
    ],
    [
      [264721, 454198],
      [3, 6571],
    ],
    [
      [266337, 460967],
      [35, -6508],
    ],
    [
      [203590, 460931],
      [13, -19328],
      [-708, 2],
      [8, -7373],
    ],
    [
      [202903, 434232],
      [-1501, 65],
    ],
    [
      [201402, 434297],
      [-1279, 5],
    ],
    [
      [200123, 434302],
      [-1673, -16],
    ],
    [
      [198450, 434286],
      [-31, 12241],
      [1170, -39],
      [16, 8015],
      [-52, 3235],
    ],
    [
      [205805, 446473],
      [4, -12257],
    ],
    [
      [205809, 434216],
      [-2545, -6],
    ],
    [
      [203264, 434210],
      [-361, 22],
    ],
    [
      [188448, 452855],
      [-1677, -36],
    ],
    [
      [264721, 454198],
      [-644, 35],
    ],
    [
      [264077, 454233],
      [-965, -59],
    ],
    [
      [263112, 454174],
      [-25, 6539],
    ],
    [
      [263112, 454174],
      [-326, 40],
    ],
    [
      [262786, 454214],
      [-1301, -17],
    ],
    [
      [261485, 454197],
      [-15, 6484],
    ],
    [
      [260173, 460695],
      [3, -6540],
    ],
    [
      [260176, 454155],
      [-1280, 41],
    ],
    [
      [258896, 454196],
      [-2, 3199],
      [-393, -4],
    ],
    [
      [258501, 457391],
      [253, 3280],
    ],
    [
      [261485, 454197],
      [-1309, -42],
    ],
    [
      [289153, 452863],
      [-334, -23],
    ],
    [
      [288819, 452840],
      [-1736, 23],
    ],
    [
      [287083, 452863],
      [-29, 3458],
      [98, 834],
      [-137, 3349],
    ],
    [
      [287083, 452863],
      [-115, 0],
    ],
    [
      [286968, 452863],
      [-1145, 0],
    ],
    [
      [285823, 452863],
      [55, 5247],
    ],
    [
      [247487, 456472],
      [432, -1434],
      [26, -1577],
    ],
    [
      [247945, 453461],
      [-2077, 28],
    ],
    [
      [245868, 453489],
      [2, 4873],
    ],
    [
      [229363, 457786],
      [-2, -3238],
    ],
    [
      [229361, 454548],
      [-545, 3],
    ],
    [
      [228816, 454551],
      [-973, 7],
    ],
    [
      [301196, 458120],
      [304, -647],
    ],
    [
      [301500, 457473],
      [18, -1677],
      [-433, -1150],
    ],
    [
      [301085, 454646],
      [-839, -2060],
    ],
    [
      [300246, 452586],
      [-325, 599],
    ],
    [
      [298144, 453428],
      [-1041, 73],
    ],
    [
      [297103, 453501],
      [-1391, 88],
    ],
    [
      [295712, 453589],
      [-125, 18],
    ],
    [
      [301793, 457791],
      [116, -445],
    ],
    [
      [301909, 457346],
      [403, -2846],
      [-196, -1501],
      [475, -1514],
      [5, -2152],
    ],
    [
      [302596, 449333],
      [-234, -1189],
    ],
    [
      [302362, 448144],
      [-280, -204],
      [-97, -1526],
      [-241, -453],
    ],
    [
      [301744, 445961],
      [-218, 3011],
      [-291, -217],
      [146, 1519],
      [-214, 1940],
      [-82, 2432],
    ],
    [
      [301500, 457473],
      [293, 318],
    ],
    [
      [243271, 458433],
      [-1, -8140],
    ],
    [
      [243270, 450293],
      [-1301, 18],
    ],
    [
      [241969, 450311],
      [-2, 8093],
    ],
    [
      [244574, 458384],
      [-7, -6498],
    ],
    [
      [244567, 451886],
      [-1, -1627],
    ],
    [
      [244566, 450259],
      [-1296, 34],
    ],
    [
      [241969, 450311],
      [-1303, -7],
    ],
    [
      [240666, 450304],
      [-4, 6473],
    ],
    [
      [245868, 453489],
      [0, -1629],
    ],
    [
      [245868, 451860],
      [-1301, 26],
    ],
    [
      [285823, 452863],
      [-1027, 0],
    ],
    [
      [284796, 452863],
      [-109, 0],
    ],
    [
      [230662, 456868],
      [249, -1941],
      [-15, -1182],
    ],
    [
      [230896, 453745],
      [-107, -597],
      [-679, 13],
    ],
    [
      [230110, 453161],
      [-2, 1379],
      [-747, 8],
    ],
    [
      [198450, 434286],
      [-3154, -25],
    ],
    [
      [195296, 434261],
      [-2647, -56],
    ],
    [
      [192649, 434205],
      [-133, 0],
    ],
    [
      [192516, 434205],
      [0, 10793],
    ],
    [
      [277320, 452816],
      [-6, -2742],
    ],
    [
      [277314, 450074],
      [-2528, -14],
    ],
    [
      [274786, 450060],
      [0, 2383],
    ],
    [
      [274786, 452443],
      [526, 1090],
      [1585, 4344],
    ],
    [
      [258896, 454196],
      [-10, -5792],
    ],
    [
      [258886, 448404],
      [-830, -14],
    ],
    [
      [258056, 448390],
      [-837, 12],
    ],
    [
      [257219, 448402],
      [579, 2539],
      [358, 3966],
      [345, 2484],
    ],
    [
      [232573, 456801],
      [-5, -6484],
    ],
    [
      [232568, 450317],
      [-1299, 59],
    ],
    [
      [231269, 450376],
      [19, 2011],
      [-392, 1358],
    ],
    [
      [240666, 450304],
      [-1299, 5],
    ],
    [
      [239367, 450309],
      [2, 6445],
    ],
    [
      [235476, 456764],
      [0, -6454],
    ],
    [
      [235476, 450310],
      [-323, -7],
    ],
    [
      [235153, 450303],
      [-970, 19],
    ],
    [
      [234183, 450322],
      [6, 6460],
    ],
    [
      [234183, 450322],
      [-1294, 1],
    ],
    [
      [232889, 450323],
      [-321, -6],
    ],
    [
      [238068, 456762],
      [0, -6440],
    ],
    [
      [238068, 450322],
      [-326, 1],
    ],
    [
      [237742, 450323],
      [-971, -4],
    ],
    [
      [236771, 450319],
      [-2, 6455],
    ],
    [
      [236771, 450319],
      [-324, -4],
    ],
    [
      [236447, 450315],
      [-971, -5],
    ],
    [
      [239367, 450309],
      [-322, 8],
    ],
    [
      [239045, 450317],
      [-977, 5],
    ],
    [
      [251326, 455701],
      [-4, -4851],
    ],
    [
      [251322, 450850],
      [-1168, -69],
      [-750, 255],
    ],
    [
      [249404, 451036],
      [-159, 538],
    ],
    [
      [249245, 451574],
      [-5, 4998],
    ],
    [
      [249245, 451574],
      [-1296, -32],
    ],
    [
      [247949, 451542],
      [-4, 1919],
    ],
    [
      [293146, 454332],
      [-66, -9100],
    ],
    [
      [293080, 445232],
      [-482, -138],
      [-384, 934],
      [-288, -777],
    ],
    [
      [291926, 445251],
      [-77, 1003],
      [-501, 1869],
      [338, 2426],
      [-911, 2621],
    ],
    [
      [254614, 455702],
      [218, -1446],
      [316, -5438],
    ],
    [
      [255148, 448818],
      [123, -1399],
    ],
    [
      [255271, 447419],
      [-6, -4485],
    ],
    [
      [255265, 442934],
      [-737, 66],
      [-9, 1648],
      [-322, -35],
      [-7, 1623],
      [-327, 774],
    ],
    [
      [253863, 447010],
      [319, 582],
      [-16, 5161],
      [-954, -144],
    ],
    [
      [253212, 452609],
      [67, 3128],
    ],
    [
      [252304, 455724],
      [-37, -8080],
    ],
    [
      [252267, 447644],
      [0, -1641],
    ],
    [
      [252267, 446003],
      [-938, -57],
    ],
    [
      [251329, 445946],
      [-7, 4904],
    ],
    [
      [253212, 452609],
      [3, -4870],
    ],
    [
      [253215, 447739],
      [-948, -95],
    ],
    [
      [216537, 454582],
      [2, -6500],
    ],
    [
      [216539, 448082],
      [-1559, -14],
    ],
    [
      [214980, 448068],
      [-230, -4],
      [4, 4971],
    ],
    [
      [301744, 445961],
      [-236, -1674],
      [-533, -780],
    ],
    [
      [300975, 443507],
      [-36, 3034],
      [-176, 277],
    ],
    [
      [300763, 446818],
      [-87, 707],
    ],
    [
      [300676, 447525],
      [-253, 1172],
    ],
    [
      [300423, 448697],
      [-177, 3889],
    ],
    [
      [218154, 454507],
      [10, -6475],
    ],
    [
      [218164, 448032],
      [-1572, 52],
    ],
    [
      [216592, 448084],
      [-53, -2],
    ],
    [
      [230110, 453161],
      [-2, -5099],
    ],
    [
      [230108, 448062],
      [-976, 13],
    ],
    [
      [229132, 448075],
      [-318, 5],
    ],
    [
      [228814, 448080],
      [2, 6471],
    ],
    [
      [227843, 454558],
      [1, -6473],
    ],
    [
      [227844, 448085],
      [-1288, -18],
    ],
    [
      [226556, 448067],
      [-8, 3239],
    ],
    [
      [228814, 448080],
      [-648, 2],
    ],
    [
      [228166, 448082],
      [-322, 3],
    ],
    [
      [219766, 454469],
      [6, -6447],
    ],
    [
      [219772, 448022],
      [-1243, -2],
    ],
    [
      [218529, 448020],
      [-365, 12],
    ],
    [
      [225260, 451282],
      [0, -3235],
    ],
    [
      [225260, 448047],
      [-1272, -15],
    ],
    [
      [223988, 448032],
      [-19, 0],
    ],
    [
      [223969, 448032],
      [-6, 6481],
    ],
    [
      [221384, 454468],
      [0, -6437],
    ],
    [
      [221384, 448031],
      [-1572, -10],
    ],
    [
      [219812, 448021],
      [-40, 1],
    ],
    [
      [222677, 454502],
      [3, -6462],
    ],
    [
      [222680, 448040],
      [-1296, -9],
    ],
    [
      [223969, 448032],
      [-1263, 4],
    ],
    [
      [222706, 448036],
      [-26, 4],
    ],
    [
      [265719, 454399],
      [30, -6681],
    ],
    [
      [265749, 447718],
      [-327, -64],
    ],
    [
      [265422, 447654],
      [-1337, -251],
    ],
    [
      [264085, 447403],
      [-8, 6830],
    ],
    [
      [294378, 453789],
      [-86, -7126],
    ],
    [
      [294292, 446663],
      [-33, -2600],
    ],
    [
      [294259, 444063],
      [-1125, -725],
      [-133, -918],
    ],
    [
      [293001, 442420],
      [79, 2812],
    ],
    [
      [302596, 449333],
      [321, -1189],
      [408, -369],
      [764, 1511],
      [-341, 4569],
      [401, -2137],
      [107, -3239],
      [-210, -1768],
      [-1085, -604],
      [-241, -1586],
      [-502, -404],
      [144, 4027],
    ],
    [
      [262786, 454214],
      [4, -5818],
    ],
    [
      [262790, 448396],
      [-1035, -6],
    ],
    [
      [261755, 448390],
      [-267, 4],
    ],
    [
      [261488, 448394],
      [-3, 5803],
    ],
    [
      [264085, 447403],
      [-109, -15],
    ],
    [
      [263976, 447388],
      [-1133, -180],
    ],
    [
      [262843, 447208],
      [-53, 1188],
    ],
    [
      [261488, 448394],
      [-1023, -15],
    ],
    [
      [260465, 448379],
      [-349, 0],
    ],
    [
      [260116, 448379],
      [69, 741],
      [-9, 5035],
    ],
    [
      [260116, 448379],
      [-774, 27],
    ],
    [
      [259342, 448406],
      [-456, -2],
    ],
    [
      [295712, 453589],
      [-58, -1345],
      [397, 121],
      [-173, -3103],
      [-189, -155],
      [94, -2951],
    ],
    [
      [295783, 446156],
      [-481, -2335],
      [-431, -845],
    ],
    [
      [294871, 442976],
      [-365, 419],
      [-214, 3268],
    ],
    [
      [231269, 450376],
      [205, -1283],
      [-160, -2129],
    ],
    [
      [231314, 446964],
      [-897, 11],
    ],
    [
      [230417, 446975],
      [1, 1083],
      [-310, 4],
    ],
    [
      [297103, 453501],
      [5, -4180],
      [88, 63],
      [182, -3932],
    ],
    [
      [297378, 445452],
      [-156, -333],
    ],
    [
      [297222, 445119],
      [-108, 1173],
      [-581, -358],
      [-107, -908],
    ],
    [
      [296426, 445026],
      [-541, -412],
      [-102, 1542],
    ],
    [
      [298237, 453401],
      [8, -1301],
      [-328, -15],
      [-2, -2289],
      [170, -1515],
      [-230, -737],
    ],
    [
      [297855, 447544],
      [-477, -2092],
    ],
    [
      [247949, 451542],
      [-252, -2717],
    ],
    [
      [247697, 448825],
      [-205, -1009],
    ],
    [
      [247492, 447816],
      [-632, 692],
    ],
    [
      [246860, 448508],
      [-476, -580],
      [-516, 680],
    ],
    [
      [245868, 448608],
      [0, 3252],
    ],
    [
      [299081, 453014],
      [27, -5277],
    ],
    [
      [299108, 447737],
      [6, -1575],
    ],
    [
      [299114, 446162],
      [-459, -104],
      [-560, 583],
      [-240, 903],
    ],
    [
      [300423, 448697],
      [-95, -429],
    ],
    [
      [300328, 448268],
      [-110, 206],
    ],
    [
      [300218, 448474],
      [-189, -592],
      [-921, -145],
    ],
    [
      [291926, 445251],
      [-301, -1608],
      [-771, -203],
      [-6, -1282],
    ],
    [
      [290848, 442158],
      [-634, 1017],
      [-245, 2280],
    ],
    [
      [289969, 445455],
      [47, 2798],
      [-244, 1860],
    ],
    [
      [160757, 452819],
      [3, -15155],
    ],
    [
      [160760, 437664],
      [-2931, -15],
    ],
    [
      [157829, 437649],
      [50, 2566],
      [-253, 887],
      [-643, -3074],
      [-236, 36],
      [-242, -1729],
      [156, -2198],
      [-334, 192],
      [-289, 1393],
      [-274, -63],
      [-470, 1939],
    ],
    [
      [155294, 437598],
      [-187, 3492],
      [-519, 272],
    ],
    [
      [154588, 441362],
      [111, 941],
      [-260, 2964],
      [146, 1892],
      [-113, 2512],
      [387, 1397],
      [129, 1813],
    ],
    [
      [214980, 448068],
      [2, -6471],
    ],
    [
      [214982, 441597],
      [-197, 8],
      [0, -3232],
    ],
    [
      [214785, 438373],
      [-1547, 1],
    ],
    [
      [213238, 438374],
      [-73, 4047],
    ],
    [
      [213165, 442421],
      [1, 5662],
      [-117, 1089],
      [3, 3792],
    ],
    [
      [213165, 442421],
      [-2040, -26],
    ],
    [
      [211125, 442395],
      [-4, 4869],
    ],
    [
      [211121, 447264],
      [21, 5656],
    ],
    [
      [188448, 452855],
      [2, -2742],
      [226, -3238],
      [-46, -2736],
      [-189, -2015],
    ],
    [
      [188441, 442124],
      [-433, 106],
      [-167, -983],
      [-443, 939],
    ],
    [
      [187398, 442186],
      [-88, 2093],
      [-182, -89],
      [-193, 3078],
      [-4, 3038],
      [-315, 2514],
    ],
    [
      [211121, 447264],
      [-1902, -23],
    ],
    [
      [189737, 445043],
      [-2, -6109],
    ],
    [
      [189735, 438934],
      [-608, -2000],
    ],
    [
      [189127, 436934],
      [120, 1271],
      [-305, 2684],
      [-249, 88],
    ],
    [
      [188693, 440977],
      [-252, 1147],
    ],
    [
      [286968, 452863],
      [88, -6473],
    ],
    [
      [287056, 446390],
      [-234, -102],
      [-57, -1948],
    ],
    [
      [286765, 444340],
      [-1655, 896],
    ],
    [
      [285110, 445236],
      [-170, 121],
    ],
    [
      [284940, 445357],
      [-144, 7506],
    ],
    [
      [284940, 445357],
      [-238, -844],
      [-1781, -169],
    ],
    [
      [282921, 444344],
      [-29, 8518],
    ],
    [
      [187398, 442186],
      [-205, 139],
      [-194, -1813],
      [-584, 5],
      [-711, -4841],
    ],
    [
      [185704, 435676],
      [-852, -1423],
      [-3463, -5],
    ],
    [
      [181389, 434248],
      [1, 18498],
    ],
    [
      [173097, 434253],
      [-1, -6651],
      [-636, -10],
      [-149, -2165],
    ],
    [
      [172311, 425427],
      [-25, 2930],
      [-933, 15],
      [0, 3230],
      [-3183, -37],
      [0, 1955],
      [-1457, -9],
    ],
    [
      [166713, 433511],
      [-57, 0],
      [17, 19239],
    ],
    [
      [173097, 452848],
      [0, -18595],
    ],
    [
      [181389, 434248],
      [-13, -16439],
    ],
    [
      [181376, 417809],
      [-3391, 104],
      [-1592, 99],
    ],
    [
      [176393, 418012],
      [-461, -9],
      [-438, 10035],
      [-1, 6209],
      [-1195, 9],
    ],
    [
      [174298, 434256],
      [-1201, -3],
    ],
    [
      [281230, 452863],
      [9, -7106],
    ],
    [
      [281239, 445757],
      [-601, -298],
    ],
    [
      [280638, 445459],
      [-179, 516],
      [-1317, -113],
    ],
    [
      [279142, 445862],
      [103, 6951],
    ],
    [
      [282921, 444344],
      [4, -1186],
    ],
    [
      [282925, 443158],
      [-1089, -71],
    ],
    [
      [281836, 443087],
      [-171, 12],
      [-426, 2658],
    ],
    [
      [154588, 441362],
      [-303, -18],
      [-4, 1572],
      [-819, -16],
    ],
    [
      [153462, 442900],
      [-267, 5082],
      [-262, 812],
      [123, 4043],
    ],
    [
      [289969, 445455],
      [-804, -6748],
    ],
    [
      [289165, 438707],
      [-411, -130],
    ],
    [
      [288754, 438577],
      [180, 478],
      [-59, 7138],
    ],
    [
      [288875, 446193],
      [-56, 6647],
    ],
    [
      [288875, 446193],
      [-721, 12],
    ],
    [
      [288154, 446205],
      [-1098, 185],
    ],
    [
      [279142, 445862],
      [-1549, 13],
    ],
    [
      [277593, 445875],
      [-281, -13],
    ],
    [
      [277312, 445862],
      [2, 4212],
    ],
    [
      [164792, 452768],
      [-2, -15095],
    ],
    [
      [164790, 437673],
      [-3709, -1],
    ],
    [
      [161081, 437672],
      [-321, -8],
    ],
    [
      [166713, 433511],
      [-9, -8230],
      [-71, -9],
      [-2, -9666],
      [318, 13],
    ],
    [
      [166949, 415619],
      [45, -3525],
      [106, -405],
      [-51, -2898],
    ],
    [
      [167049, 408791],
      [-264, -205],
    ],
    [
      [166785, 408586],
      [-505, -709],
      [-438, -1338],
      [-205, 182],
      [104, -3535],
      [-151, -1501],
    ],
    [
      [165590, 401685],
      [-469, -1590],
      [-343, 3],
    ],
    [
      [164778, 400098],
      [-9, 2800],
    ],
    [
      [164769, 402898],
      [12, 2458],
    ],
    [
      [164781, 405356],
      [6, 5108],
    ],
    [
      [164787, 410464],
      [3, 27209],
    ],
    [
      [253863, 447010],
      [-4, 809],
      [-643, -82],
    ],
    [
      [253216, 447737],
      [-1, 2],
    ],
    [
      [274786, 450060],
      [0, -6514],
    ],
    [
      [274786, 443546],
      [-1348, 42],
    ],
    [
      [273438, 443588],
      [-1, 3974],
    ],
    [
      [273437, 447562],
      [3, 2573],
    ],
    [
      [273440, 450135],
      [1346, 2308],
    ],
    [
      [245868, 448608],
      [-3, -3243],
    ],
    [
      [245865, 445365],
      [-1300, 22],
    ],
    [
      [244565, 445387],
      [1, 4872],
    ],
    [
      [249404, 451036],
      [-5, -5898],
    ],
    [
      [249399, 445138],
      [-644, -16],
    ],
    [
      [248755, 445122],
      [-900, 11],
    ],
    [
      [247855, 445133],
      [-158, 3692],
    ],
    [
      [226556, 448067],
      [2, -4023],
    ],
    [
      [226558, 444044],
      [-753, -845],
      [-533, -1],
    ],
    [
      [225272, 443198],
      [-12, 4849],
    ],
    [
      [251329, 445946],
      [-636, 7],
      [1, -814],
    ],
    [
      [250694, 445139],
      [-1295, -1],
    ],
    [
      [232889, 450323],
      [0, -4862],
      [165, -1791],
    ],
    [
      [233054, 443670],
      [-1389, 16],
    ],
    [
      [231665, 443686],
      [-256, 439],
      [-95, 2839],
    ],
    [
      [243270, 450293],
      [6, -6524],
    ],
    [
      [243276, 443769],
      [-326, -13],
    ],
    [
      [242950, 443756],
      [-979, -17],
    ],
    [
      [241971, 443739],
      [-2, 6572],
    ],
    [
      [237742, 450323],
      [68, -6545],
    ],
    [
      [237810, 443778],
      [-1255, -153],
    ],
    [
      [236555, 443625],
      [-107, 1818],
      [-1, 4872],
    ],
    [
      [234183, 450322],
      [1, -4860],
      [144, -1818],
    ],
    [
      [234328, 443644],
      [-317, 10],
    ],
    [
      [234011, 443654],
      [-957, 16],
    ],
    [
      [235153, 450303],
      [2, -4797],
      [121, -1872],
    ],
    [
      [235276, 443634],
      [-948, 10],
    ],
    [
      [241971, 443739],
      [-319, -4],
    ],
    [
      [241652, 443735],
      [-960, -14],
    ],
    [
      [240692, 443721],
      [-26, 6583],
    ],
    [
      [239045, 450317],
      [53, -6615],
    ],
    [
      [239098, 443702],
      [0, -314],
    ],
    [
      [239098, 443388],
      [-1288, 390],
    ],
    [
      [240692, 443721],
      [-320, -6],
    ],
    [
      [240372, 443715],
      [-1274, -13],
    ],
    [
      [236555, 443625],
      [-1279, 9],
    ],
    [
      [244565, 445387],
      [-5, -3261],
    ],
    [
      [244560, 442126],
      [-322, 12],
    ],
    [
      [244238, 442138],
      [-104, 1623],
      [-858, 8],
    ],
    [
      [273437, 447562],
      [-275, -15],
      [3, -1357],
      [-539, -11],
      [-3, -1320],
      [-265, -4],
    ],
    [
      [272358, 444855],
      [-272, 1146],
    ],
    [
      [272086, 446001],
      [570, 2452],
      [784, 1682],
    ],
    [
      [277312, 445862],
      [-602, -34],
      [-476, -2456],
    ],
    [
      [276234, 443372],
      [-1448, -20],
    ],
    [
      [274786, 443352],
      [0, 194],
    ],
    [
      [247855, 445133],
      [-29, -825],
      [-657, -1555],
      [-5, -2418],
    ],
    [
      [247164, 440335],
      [-1778, 120],
    ],
    [
      [245386, 440455],
      [0, 4],
    ],
    [
      [245386, 440459],
      [89, 1581],
      [707, 640],
    ],
    [
      [246182, 442680],
      [363, 171],
      [871, 2340],
      [76, 2625],
    ],
    [
      [300676, 447525],
      [-94, -1308],
      [-254, 2051],
    ],
    [
      [246182, 442680],
      [7, 2674],
      [-324, 11],
    ],
    [
      [300218, 448474],
      [-108, -2110],
    ],
    [
      [300110, 446364],
      [-154, -894],
      [-847, -110],
    ],
    [
      [299109, 445360],
      [5, 802],
    ],
    [
      [260465, 448379],
      [14, -4389],
    ],
    [
      [260479, 443990],
      [3, -1610],
    ],
    [
      [260482, 442380],
      [-1130, -18],
    ],
    [
      [259352, 442362],
      [-1, 806],
    ],
    [
      [259351, 443168],
      [-9, 5238],
    ],
    [
      [259351, 443168],
      [-1136, -50],
      [2, -810],
    ],
    [
      [258217, 442308],
      [-163, -1],
    ],
    [
      [258054, 442307],
      [104, 4029],
      [-102, 2054],
    ],
    [
      [258054, 442307],
      [-490, -569],
      [-231, -2173],
      [-408, -905],
    ],
    [
      [256925, 438660],
      [-7, 8808],
    ],
    [
      [256918, 447468],
      [301, 934],
    ],
    [
      [262847, 444118],
      [-1085, -69],
    ],
    [
      [261762, 444049],
      [-7, 4341],
    ],
    [
      [262843, 447208],
      [4, -3090],
    ],
    [
      [261762, 444049],
      [-1283, -59],
    ],
    [
      [229132, 448075],
      [0, -5329],
    ],
    [
      [229132, 442746],
      [-759, -1483],
      [-211, 138],
    ],
    [
      [228162, 441401],
      [4, 6681],
    ],
    [
      [228162, 441401],
      [-318, 227],
    ],
    [
      [227844, 441628],
      [-643, -1180],
    ],
    [
      [227201, 440448],
      [0, 1151],
      [-292, 1],
    ],
    [
      [226909, 441600],
      [0, 2456],
      [-351, -12],
    ],
    [
      [230417, 446975],
      [2, -2974],
      [320, -2434],
    ],
    [
      [230739, 441567],
      [-395, 2],
    ],
    [
      [230344, 441569],
      [-276, 747],
      [-936, 373],
    ],
    [
      [229132, 442689],
      [0, 57],
    ],
    [
      [218529, 448020],
      [-5, -6446],
    ],
    [
      [218524, 441574],
      [-1550, 27],
    ],
    [
      [216974, 441601],
      [-383, 9],
    ],
    [
      [216591, 441610],
      [1, 6474],
    ],
    [
      [216591, 441610],
      [-1609, -13],
    ],
    [
      [225272, 443198],
      [0, -1617],
    ],
    [
      [225272, 441581],
      [-1262, 6],
    ],
    [
      [224010, 441587],
      [-20, -2],
    ],
    [
      [223990, 441585],
      [-2, 6447],
    ],
    [
      [222706, 448036],
      [25, -6450],
    ],
    [
      [222731, 441586],
      [-12, -6463],
    ],
    [
      [222719, 435123],
      [-609, -9],
    ],
    [
      [222110, 435114],
      [-2224, 2],
    ],
    [
      [219886, 435116],
      [-74, 6454],
    ],
    [
      [219812, 441570],
      [0, 6451],
    ],
    [
      [219812, 441570],
      [-1288, 4],
    ],
    [
      [223990, 441585],
      [-1259, 1],
    ],
    [
      [267414, 445850],
      [-697, -80],
    ],
    [
      [266717, 445770],
      [-430, -32],
      [-394, -2264],
      [-479, -1510],
    ],
    [
      [265414, 441964],
      [1, 1360],
    ],
    [
      [265415, 443324],
      [7, 4330],
    ],
    [
      [265749, 447718],
      [781, 161],
    ],
    [
      [266530, 447879],
      [6, 1],
    ],
    [
      [266536, 447880],
      [30, 6],
    ],
    [
      [266566, 447886],
      [16, 1],
    ],
    [
      [266582, 447887],
      [26, 6],
    ],
    [
      [266608, 447893],
      [806, -2043],
    ],
    [
      [255265, 442934],
      [-2, -3134],
    ],
    [
      [255263, 439800],
      [-1357, -110],
      [6, -1614],
      [-648, -80],
    ],
    [
      [253264, 437996],
      [-23, 4868],
    ],
    [
      [253241, 442864],
      [-25, 4873],
    ],
    [
      [267414, 445850],
      [546, -1925],
      [381, 1255],
      [294, -1578],
      [-868, -836],
    ],
    [
      [267767, 442766],
      [-50, -4],
    ],
    [
      [267717, 442762],
      [-15, -1],
    ],
    [
      [267702, 442761],
      [-769, 5],
      [-213, 794],
    ],
    [
      [266720, 443560],
      [-3, 2210],
    ],
    [
      [253241, 442864],
      [-956, -107],
    ],
    [
      [252285, 442757],
      [-18, 3246],
    ],
    [
      [265415, 443324],
      [-1279, -37],
    ],
    [
      [264136, 443287],
      [-108, 529],
      [-52, 3572],
    ],
    [
      [273438, 443588],
      [0, -2864],
    ],
    [
      [273438, 440724],
      [-1081, 8],
    ],
    [
      [272357, 440732],
      [1, 4123],
    ],
    [
      [299109, 445360],
      [-23, -3357],
      [-140, -1618],
    ],
    [
      [298946, 440385],
      [-436, -421],
      [-482, 288],
      [-416, -840],
      [-291, 2723],
    ],
    [
      [297321, 442135],
      [350, 235],
      [-50, 1824],
      [-299, -181],
      [-100, 1106],
    ],
    [
      [256110, 445860],
      [11, -7106],
    ],
    [
      [256121, 438754],
      [-158, -435],
    ],
    [
      [255963, 438319],
      [-386, -1051],
      [-314, 71],
    ],
    [
      [255263, 437339],
      [0, 2461],
    ],
    [
      [255271, 447419],
      [248, -1015],
      [591, -544],
    ],
    [
      [256925, 438660],
      [-559, 918],
      [-245, -824],
    ],
    [
      [256110, 445860],
      [808, 1608],
    ],
    [
      [264136, 443287],
      [1, -1079],
    ],
    [
      [264137, 442208],
      [-1288, -29],
    ],
    [
      [262849, 442179],
      [-2, 1939],
    ],
    [
      [211125, 442395],
      [-24, -800],
    ],
    [
      [211101, 441595],
      [-1879, -26],
    ],
    [
      [209222, 441569],
      [-1, 3184],
    ],
    [
      [231665, 443686],
      [164, -2163],
    ],
    [
      [231829, 441523],
      [-1090, 44],
    ],
    [
      [300704, 446133],
      [-85, -2793],
      [-210, 505],
      [295, 2288],
    ],
    [
      [300975, 443507],
      [-208, -748],
      [-4, 4059],
    ],
    [
      [294871, 442976],
      [348, -1153],
      [336, -2034],
      [-121, -1229],
    ],
    [
      [295434, 438560],
      [-200, -1187],
      [-705, -2090],
      [-626, -1185],
    ],
    [
      [293903, 434098],
      [-195, 2025],
      [683, 2086],
      [-173, 2860],
    ],
    [
      [294218, 441069],
      [41, 2994],
    ],
    [
      [209222, 441569],
      [-4, -7295],
    ],
    [
      [209218, 434274],
      [-2479, -59],
    ],
    [
      [206739, 434215],
      [-930, 1],
    ],
    [
      [300110, 446364],
      [27, -3366],
      [-179, -1833],
      [-1042, -1242],
      [30, 462],
    ],
    [
      [288154, 446205],
      [-316, -4003],
    ],
    [
      [287838, 442202],
      [-487, -794],
      [-761, -136],
    ],
    [
      [286590, 441272],
      [175, 3068],
    ],
    [
      [297321, 442135],
      [207, -2884],
      [-531, -114],
    ],
    [
      [296997, 439137],
      [-298, 3270],
      [-256, -278],
      [-17, 2897],
    ],
    [
      [296997, 439137],
      [9, -62],
    ],
    [
      [297006, 439075],
      [21, -79],
    ],
    [
      [297027, 438996],
      [-1112, 58],
      [-473, -1600],
      [-8, 1106],
    ],
    [
      [288754, 438577],
      [-264, -1321],
    ],
    [
      [288490, 437256],
      [-133, 486],
      [-104, 2827],
      [-415, 1633],
    ],
    [
      [293001, 442420],
      [-13, -2190],
    ],
    [
      [292988, 440230],
      [-683, -3302],
    ],
    [
      [292305, 436928],
      [-373, 1054],
    ],
    [
      [291932, 437982],
      [-934, 2867],
    ],
    [
      [290998, 440849],
      [-150, 1309],
    ],
    [
      [252285, 442757],
      [26, -6500],
    ],
    [
      [252311, 436257],
      [-960, -39],
      [-1, -3314],
    ],
    [
      [251350, 432904],
      [-324, -34],
    ],
    [
      [251026, 432870],
      [0, 3329],
      [-319, -14],
    ],
    [
      [250707, 436185],
      [-5, 3838],
    ],
    [
      [250702, 440023],
      [-8, 5116],
    ],
    [
      [272357, 440732],
      [-1, 0],
    ],
    [
      [272356, 440732],
      [-569, 47],
      [73, -1359],
      [-321, -13],
    ],
    [
      [271539, 439407],
      [-538, -38],
    ],
    [
      [271001, 439369],
      [3, 1405],
      [-263, 14],
      [8, 2861],
    ],
    [
      [270749, 443649],
      [623, -332],
      [714, 2684],
    ],
    [
      [280638, 445459],
      [-3, -3672],
      [463, -4],
      [46, -3243],
    ],
    [
      [281144, 438540],
      [-1140, 433],
      [-177, -968],
    ],
    [
      [279827, 438005],
      [-70, 1137],
      [-624, 1848],
      [-379, -396],
    ],
    [
      [278754, 440594],
      [88, 2092],
      [292, -302],
      [8, 3478],
    ],
    [
      [278754, 440594],
      [-317, -166],
    ],
    [
      [278437, 440428],
      [5, 1839],
      [-538, 103],
      [-216, -927],
    ],
    [
      [277688, 441443],
      [-95, 4432],
    ],
    [
      [277688, 441443],
      [-1, -945],
      [-351, -264],
      [-253, -2768],
    ],
    [
      [277083, 437466],
      [-849, -18],
    ],
    [
      [276234, 437448],
      [0, 5924],
    ],
    [
      [269692, 442195],
      [16, -2668],
    ],
    [
      [269708, 439527],
      [-1391, 120],
    ],
    [
      [268317, 439647],
      [-18, 2605],
      [-150, 10],
    ],
    [
      [268149, 442262],
      [253, 825],
      [384, -407],
    ],
    [
      [268786, 442680],
      [-5, -153],
    ],
    [
      [268781, 442527],
      [480, -1172],
      [431, 840],
    ],
    [
      [266720, 443560],
      [-15, -4583],
    ],
    [
      [266705, 438977],
      [-3, -1624],
    ],
    [
      [266702, 437353],
      [-1282, 19],
    ],
    [
      [265420, 437372],
      [-6, 4592],
    ],
    [
      [281836, 443087],
      [1, -1967],
      [-292, -2833],
    ],
    [
      [281545, 438287],
      [-401, 253],
    ],
    [
      [290998, 440849],
      [-384, -1439],
      [-427, -3445],
    ],
    [
      [290187, 435965],
      [-461, 1079],
      [82, 1909],
      [-643, -246],
    ],
    [
      [245386, 440459],
      [-830, 46],
      [4, 1621],
    ],
    [
      [285110, 445236],
      [175, -3416],
      [441, -1905],
      [405, -536],
    ],
    [
      [286131, 439379],
      [-539, -2232],
    ],
    [
      [285592, 437147],
      [-256, 304],
    ],
    [
      [285336, 437451],
      [-455, -615],
    ],
    [
      [284881, 436836],
      [-228, -951],
      [-463, -355],
    ],
    [
      [284190, 435530],
      [-1068, 5402],
      [-197, 2226],
    ],
    [
      [286590, 441272],
      [-78, -1277],
    ],
    [
      [286512, 439995],
      [-381, -616],
    ],
    [
      [250702, 440023],
      [-480, -176],
      [-57, -1256],
      [-306, 11],
      [0, -1588],
    ],
    [
      [249859, 437014],
      [-479, 1],
    ],
    [
      [249380, 437015],
      [-1, 1587],
      [-610, 12],
    ],
    [
      [248769, 438614],
      [-14, 6508],
    ],
    [
      [248769, 438614],
      [-31, -1592],
      [-323, 7],
    ],
    [
      [248415, 437029],
      [-1262, 34],
    ],
    [
      [247153, 437063],
      [11, 3272],
    ],
    [
      [192516, 434205],
      [-2782, 5],
      [1, 4724],
    ],
    [
      [262849, 442179],
      [1, -2880],
    ],
    [
      [262850, 439299],
      [-1082, -133],
    ],
    [
      [261768, 439166],
      [-6, 4883],
    ],
    [
      [261768, 439166],
      [-323, -1],
    ],
    [
      [261445, 439165],
      [-639, 38],
      [-321, 533],
    ],
    [
      [260485, 439736],
      [-3, 2644],
    ],
    [
      [226909, 441600],
      [-6, -1597],
      [-967, -298],
      [-621, -536],
      [-31, 2413],
    ],
    [
      [225284, 441582],
      [-12, -1],
    ],
    [
      [293006, 440243],
      [-18, -18],
    ],
    [
      [292988, 440225],
      [0, 5],
    ],
    [
      [294218, 441069],
      [-1212, -826],
    ],
    [
      [302428, 442673],
      [372, -1939],
      [-773, -264],
      [113, 1840],
      [288, 363],
    ],
    [
      [271001, 439369],
      [-263, 5],
      [-6, -1403],
      [-273, -2],
      [-271, -1169],
      [-5, -1369],
    ],
    [
      [270183, 435431],
      [-459, 41],
    ],
    [
      [269724, 435472],
      [-16, 4055],
    ],
    [
      [269692, 442195],
      [936, 1653],
      [121, -199],
    ],
    [
      [244238, 442138],
      [-5, -4866],
    ],
    [
      [244233, 437272],
      [-641, 8],
    ],
    [
      [243592, 437280],
      [-640, 13],
    ],
    [
      [242952, 437293],
      [-2, 6463],
    ],
    [
      [239098, 443388],
      [2, -6149],
    ],
    [
      [239100, 437239],
      [-639, 12],
    ],
    [
      [238461, 437251],
      [-646, 14],
    ],
    [
      [237815, 437265],
      [-5, 6513],
    ],
    [
      [242952, 437293],
      [-653, -16],
    ],
    [
      [242299, 437277],
      [-642, -14],
    ],
    [
      [241657, 437263],
      [-5, 6472],
    ],
    [
      [237815, 437265],
      [-627, -98],
    ],
    [
      [237188, 437167],
      [-633, 7],
    ],
    [
      [236555, 437174],
      [0, 6451],
    ],
    [
      [241657, 437263],
      [-642, -12],
    ],
    [
      [241015, 437251],
      [-638, -8],
    ],
    [
      [240377, 437243],
      [-5, 6472],
    ],
    [
      [240377, 437243],
      [-639, -2],
    ],
    [
      [239738, 437241],
      [-638, -2],
    ],
    [
      [234011, 443654],
      [-4, -6441],
    ],
    [
      [234007, 437213],
      [-636, 14],
    ],
    [
      [233371, 437227],
      [-1382, -2],
    ],
    [
      [231989, 437225],
      [-120, 575],
    ],
    [
      [231869, 437800],
      [88, 2413],
      [-128, 1310],
    ],
    [
      [235276, 443634],
      [0, -6443],
    ],
    [
      [235276, 437191],
      [-633, 8],
    ],
    [
      [234643, 437199],
      [-636, 14],
    ],
    [
      [236555, 437174],
      [-638, 8],
    ],
    [
      [235917, 437182],
      [-641, 9],
    ],
    [
      [274786, 443352],
      [1, -6623],
    ],
    [
      [274787, 436729],
      [-1339, 18],
    ],
    [
      [273448, 436747],
      [-10, 3977],
    ],
    [
      [267702, 442761],
      [447, -499],
    ],
    [
      [268317, 439647],
      [3, -645],
    ],
    [
      [268320, 439002],
      [-1615, -25],
    ],
    [
      [276234, 437448],
      [-271, -1900],
    ],
    [
      [275963, 435548],
      [-140, 819],
      [-1037, 201],
    ],
    [
      [274786, 436568],
      [1, 161],
    ],
    [
      [265420, 437372],
      [-967, -37],
    ],
    [
      [264453, 437335],
      [-1, 4878],
      [-315, -5],
    ],
    [
      [259352, 442362],
      [14, -4890],
      [-65, -6],
    ],
    [
      [259301, 437466],
      [-1086, -26],
    ],
    [
      [258215, 437440],
      [2, 4868],
    ],
    [
      [284190, 435530],
      [0, -456],
    ],
    [
      [284190, 435074],
      [-1015, -1516],
      [-180, 1961],
      [-470, 731],
      [-132, 1393],
      [-289, -77],
      [-14, 1365],
      [-389, -1859],
    ],
    [
      [281701, 437072],
      [-156, 1215],
    ],
    [
      [155294, 437598],
      [-138, -2080],
      [141, -717],
      [-208, -2138],
      [-386, 312],
      [218, -3716],
      [-6, -13593],
    ],
    [
      [154915, 415666],
      [-1334, -12],
    ],
    [
      [153581, 415654],
      [-243, 1901],
      [-703, 2914],
      [-129, 3412],
      [387, 4770],
      [132, -280],
      [308, 6363],
      [-131, 874],
      [260, 7292],
    ],
    [
      [253264, 437996],
      [-21, -1627],
    ],
    [
      [253243, 436369],
      [-932, -112],
    ],
    [
      [229132, 442689],
      [-7, -7583],
    ],
    [
      [229125, 435106],
      [-7, 1],
    ],
    [
      [229118, 435107],
      [-1274, 15],
    ],
    [
      [227844, 435122],
      [0, 6506],
    ],
    [
      [230344, 441569],
      [400, -3784],
    ],
    [
      [230744, 437785],
      [23, -2699],
    ],
    [
      [230767, 435086],
      [-404, -540],
    ],
    [
      [230363, 434546],
      [0, 542],
      [-1238, 18],
    ],
    [
      [260485, 439736],
      [-89, -4618],
    ],
    [
      [260396, 435118],
      [-728, -71],
    ],
    [
      [259668, 435047],
      [-364, 782],
      [-3, 1637],
    ],
    [
      [213238, 438374],
      [-29, -4081],
    ],
    [
      [213209, 434293],
      [-91, -1],
    ],
    [
      [213118, 434292],
      [-2030, -1],
    ],
    [
      [211088, 434291],
      [13, 7304],
    ],
    [
      [278437, 440428],
      [-17, -5242],
    ],
    [
      [278420, 435186],
      [-185, -771],
      [-669, -629],
      [-401, 1395],
      [-70, 2246],
    ],
    [
      [277095, 437427],
      [-12, 39],
    ],
    [
      [188693, 440977],
      [-212, -2506],
      [-449, -761],
      [-413, 552],
      [-140, -1426],
    ],
    [
      [187479, 436836],
      [-928, 257],
      [-847, -1417],
    ],
    [
      [258215, 437440],
      [-1290, 17],
    ],
    [
      [256925, 437457],
      [0, 1203],
    ],
    [
      [288490, 437256],
      [-135, -732],
    ],
    [
      [288355, 436524],
      [-329, -581],
      [98, -1543],
      [-739, -1772],
    ],
    [
      [287385, 432628],
      [-586, 684],
    ],
    [
      [286799, 433312],
      [-59, 3514],
      [-251, 1238],
      [23, 1931],
    ],
    [
      [264453, 437335],
      [-316, -6],
    ],
    [
      [264137, 437329],
      [-325, 1637],
      [-962, -17],
    ],
    [
      [262850, 438949],
      [0, 350],
    ],
    [
      [245386, 440455],
      [-115, -1740],
      [344, -1568],
      [113, -1545],
    ],
    [
      [245728, 435602],
      [-1175, 26],
    ],
    [
      [244553, 435628],
      [2, 1613],
      [-322, 31],
    ],
    [
      [216974, 441601],
      [-2, -6470],
      [56, -810],
    ],
    [
      [217028, 434321],
      [-2233, -1],
    ],
    [
      [214795, 434320],
      [-10, 4053],
    ],
    [
      [219886, 435116],
      [3, -6448],
    ],
    [
      [219889, 428668],
      [-1545, -2],
    ],
    [
      [218344, 428666],
      [-1309, -44],
    ],
    [
      [217035, 428622],
      [-7, 5699],
    ],
    [
      [304069, 440802],
      [121, -1847],
      [-424, -197],
      [303, 2044],
    ],
    [
      [227844, 435122],
      [-1276, -6],
    ],
    [
      [226568, 435116],
      [-6, 2365],
    ],
    [
      [226562, 437481],
      [639, 2967],
    ],
    [
      [211088, 434291],
      [-535, -10],
    ],
    [
      [210553, 434281],
      [-1335, -7],
    ],
    [
      [226562, 437481],
      [-534, -2005],
      [-733, -3605],
    ],
    [
      [225295, 431871],
      [-11, 3241],
    ],
    [
      [225284, 435112],
      [0, 6470],
    ],
    [
      [225284, 435112],
      [-1212, 5],
    ],
    [
      [224072, 435117],
      [-74, 0],
    ],
    [
      [223998, 435117],
      [12, 6470],
    ],
    [
      [223998, 435117],
      [-1279, 6],
    ],
    [
      [231869, 437800],
      [-1125, -15],
    ],
    [
      [189127, 436934],
      [-680, -1517],
      [-235, -1307],
      [316, -2474],
      [-193, -1471],
      [-256, 343],
    ],
    [
      [188079, 430508],
      [-272, 1154],
    ],
    [
      [187807, 431662],
      [-214, 1796],
      [-114, 3378],
    ],
    [
      [279827, 438005],
      [-264, -1317],
      [-1, -4191],
    ],
    [
      [279562, 432497],
      [-1143, 101],
    ],
    [
      [278419, 432598],
      [1, 2588],
    ],
    [
      [157829, 437649],
      [146, -436],
      [-429, -4820],
      [-421, -4012],
      [145, -2152],
      [-836, -2813],
      [-185, -2444],
    ],
    [
      [156249, 420972],
      [222, -710],
      [144, -5037],
    ],
    [
      [156615, 415225],
      [-1701, -22],
      [1, 463],
    ],
    [
      [293524, 432082],
      [-325, 588],
    ],
    [
      [293199, 432670],
      [123, 2992],
      [-28, 2071],
      [-288, 2510],
    ],
    [
      [293903, 434098],
      [-379, -2016],
    ],
    [
      [291932, 437982],
      [-383, -2133],
    ],
    [
      [291549, 435849],
      [-417, -3054],
      [-322, -187],
    ],
    [
      [290810, 432608],
      [-554, 3400],
    ],
    [
      [290256, 436008],
      [-69, -43],
    ],
    [
      [272356, 440732],
      [-5, -6697],
    ],
    [
      [272351, 434035],
      [-74, -1524],
      [-634, 144],
    ],
    [
      [271643, 432655],
      [-114, 1381],
    ],
    [
      [271529, 434036],
      [10, 5371],
    ],
    [
      [273448, 436747],
      [0, -2714],
      [-243, -5],
    ],
    [
      [273205, 434028],
      [-854, 7],
    ],
    [
      [247153, 437063],
      [-6, -1623],
    ],
    [
      [247147, 435440],
      [-963, 88],
    ],
    [
      [246184, 435528],
      [-456, 35],
    ],
    [
      [245728, 435563],
      [0, 39],
    ],
    [
      [250707, 436185],
      [-548, -8],
      [-300, 837],
    ],
    [
      [293219, 434221],
      [-863, 2534],
    ],
    [
      [292356, 436755],
      [-51, 173],
    ],
    [
      [292988, 440225],
      [220, -3323],
      [11, -2681],
    ],
    [
      [286799, 433312],
      [-277, -2753],
      [-204, -490],
    ],
    [
      [286318, 430069],
      [-412, 1992],
    ],
    [
      [285906, 432061],
      [44, 1187],
      [-292, 2181],
      [-66, 1718],
    ],
    [
      [255263, 437339],
      [2, -2898],
    ],
    [
      [255265, 434441],
      [-1688, -234],
    ],
    [
      [253577, 434207],
      [-321, -56],
    ],
    [
      [253256, 434151],
      [-13, 2218],
    ],
    [
      [261445, 439165],
      [-77, -4820],
    ],
    [
      [261368, 434345],
      [-859, -54],
    ],
    [
      [260509, 434291],
      [-113, 827],
    ],
    [
      [294554, 427872],
      [-111, 3761],
    ],
    [
      [294443, 431633],
      [-93, 1192],
      [769, -305],
      [174, 1128],
      [1072, -46],
      [376, 298],
      [903, 2985],
      [-441, -2838],
      [-371, -1388],
      [292, -372],
      [379, 1926],
      [532, 898],
      [205, -1023],
      [552, 1761],
      [82, -626],
      [-1554, -3632],
      [-192, 472],
      [-405, -1606],
      [-231, 346],
      [-428, -1336],
      [-389, 99],
      [-351, -945],
      [-238, 353],
      [-532, -1102],
    ],
    [
      [269724, 435472],
      [-278, -7],
      [9, -1349],
    ],
    [
      [269455, 434116],
      [-813, 51],
    ],
    [
      [268642, 434167],
      [-292, 19],
    ],
    [
      [268350, 434186],
      [-30, 4816],
    ],
    [
      [256925, 437457],
      [-1, -4839],
    ],
    [
      [256924, 432618],
      [-156, -1387],
      [-315, -5],
      [2, -1882],
    ],
    [
      [256455, 429344],
      [-469, -1],
    ],
    [
      [255986, 429343],
      [-23, 8976],
    ],
    [
      [271529, 434036],
      [-1228, 59],
    ],
    [
      [270301, 434095],
      [-118, 1336],
    ],
    [
      [262850, 438949],
      [1, -4898],
    ],
    [
      [262851, 434051],
      [1, -1244],
    ],
    [
      [262852, 432807],
      [-755, -89],
    ],
    [
      [262097, 432718],
      [-730, -14],
    ],
    [
      [261367, 432704],
      [1, 1641],
    ],
    [
      [268350, 434186],
      [-789, -60],
    ],
    [
      [267561, 434126],
      [-857, -30],
    ],
    [
      [266704, 434096],
      [-2, 3257],
    ],
    [
      [281701, 437072],
      [-194, -834],
      [74, -2825],
      [-363, -706],
      [-462, -3035],
      [47, -403],
    ],
    [
      [280803, 429269],
      [26, -143],
    ],
    [
      [280829, 429126],
      [-1257, -6],
    ],
    [
      [279572, 429120],
      [-10, 3377],
    ],
    [
      [192649, 434205],
      [0, -3424],
    ],
    [
      [192649, 430781],
      [-728, 405],
      [-315, -879],
      [-787, -888],
      [-261, 131],
      [-420, -1293],
    ],
    [
      [190138, 428257],
      [-340, -1866],
      [-343, -529],
      [-618, 1479],
      [-69, 1143],
      [-267, -1698],
      [-177, 190],
    ],
    [
      [188324, 426976],
      [-245, 3532],
    ],
    [
      [284190, 435074],
      [-613, -3675],
    ],
    [
      [283577, 431399],
      [-884, -2182],
    ],
    [
      [282693, 429217],
      [-399, 259],
      [-333, -970],
      [-479, 890],
    ],
    [
      [281482, 429396],
      [-679, -127],
    ],
    [
      [290256, 436008],
      [-428, -2351],
    ],
    [
      [289828, 433657],
      [-466, -1940],
      [-519, -917],
    ],
    [
      [288843, 430800],
      [-280, 2542],
      [218, 671],
      [-426, 2511],
    ],
    [
      [264137, 437329],
      [-1, -3255],
      [-160, -7],
    ],
    [
      [263976, 434067],
      [-1125, -16],
    ],
    [
      [249380, 437015],
      [-1, -3256],
    ],
    [
      [249379, 433759],
      [-966, 19],
    ],
    [
      [248413, 433778],
      [2, 3251],
    ],
    [
      [214795, 434320],
      [-2, -28],
    ],
    [
      [214793, 434292],
      [-1584, 1],
    ],
    [
      [255986, 429343],
      [-721, 8],
    ],
    [
      [255265, 429351],
      [0, 5090],
    ],
    [
      [292587, 430897],
      [-395, 1442],
    ],
    [
      [292192, 432339],
      [-104, 1880],
      [-539, 1630],
    ],
    [
      [292356, 436755],
      [-165, -2166],
      [367, -971],
      [29, -2721],
    ],
    [
      [231989, 437225],
      [3, -1985],
    ],
    [
      [231992, 435240],
      [-431, 236],
      [-484, -1309],
      [-310, 919],
    ],
    [
      [164787, 410464],
      [-407, -275],
    ],
    [
      [164380, 410189],
      [102, 1081],
      [5, 3235],
      [-258, 1376],
      [-24, 1351],
      [-366, 545],
      [-472, 2488],
      [-707, 1250],
      [-457, -2310],
      [-371, 1200],
      [1, 3539],
      [-743, -22],
    ],
    [
      [161090, 423922],
      [22, 8573],
      [-31, 5177],
    ],
    [
      [161090, 423922],
      [-473, 4],
    ],
    [
      [160617, 423926],
      [-1427, -349],
      [-831, -1032],
      [-549, 336],
      [-407, -1138],
      [-271, 689],
      [-883, -1460],
    ],
    [
      [285336, 437451],
      [-167, -4196],
      [366, 397],
      [371, -1591],
    ],
    [
      [286318, 430069],
      [-895, -2188],
    ],
    [
      [285423, 427881],
      [-525, -887],
      [-165, 332],
    ],
    [
      [284733, 427326],
      [26, 188],
    ],
    [
      [284759, 427514],
      [265, 1448],
      [124, 3090],
    ],
    [
      [285148, 432052],
      [-223, 1660],
      [-44, 3124],
    ],
    [
      [259668, 435047],
      [-2, -813],
    ],
    [
      [259666, 434234],
      [-620, -58],
      [0, -1610],
    ],
    [
      [259046, 432566],
      [-835, 5],
    ],
    [
      [258211, 432571],
      [4, 4869],
    ],
    [
      [226568, 435116],
      [1, -6480],
    ],
    [
      [226569, 428636],
      [-1260, -4],
    ],
    [
      [225309, 428632],
      [-14, -1],
    ],
    [
      [225295, 428631],
      [0, 3240],
    ],
    [
      [258211, 432571],
      [-314, 19],
    ],
    [
      [257897, 432590],
      [-973, 28],
    ],
    [
      [277095, 437427],
      [-6, -9328],
    ],
    [
      [277089, 428099],
      [-1270, 90],
    ],
    [
      [275819, 428189],
      [-27, 3362],
    ],
    [
      [275792, 431551],
      [-19, 2706],
      [190, 1291],
    ],
    [
      [278419, 432598],
      [1, -2521],
      [-655, -4576],
    ],
    [
      [277765, 425501],
      [-675, 2580],
    ],
    [
      [277090, 428081],
      [-1, 18],
    ],
    [
      [266704, 434096],
      [-266, -3234],
    ],
    [
      [266438, 430862],
      [-1015, 34],
    ],
    [
      [265423, 430896],
      [-1, 1871],
    ],
    [
      [265422, 432767],
      [-2, 4605],
    ],
    [
      [265422, 432767],
      [-638, -293],
      [0, -813],
      [-644, -36],
    ],
    [
      [264140, 431625],
      [-164, 2442],
    ],
    [
      [243592, 437280],
      [-8, -4878],
    ],
    [
      [243584, 432402],
      [-1283, -20],
    ],
    [
      [242301, 432382],
      [-2, 4895],
    ],
    [
      [244553, 435628],
      [-3, -4868],
      [-104, 1],
    ],
    [
      [244446, 430761],
      [-862, 15],
    ],
    [
      [243584, 430776],
      [0, 1626],
    ],
    [
      [242301, 432382],
      [-1281, -16],
    ],
    [
      [241020, 432366],
      [-5, 4885],
    ],
    [
      [238461, 437251],
      [3, -4896],
    ],
    [
      [238464, 432355],
      [-1277, -24],
    ],
    [
      [237187, 432331],
      [1, 4836],
    ],
    [
      [239738, 437241],
      [4, -4883],
    ],
    [
      [239742, 432358],
      [-1278, -3],
    ],
    [
      [241020, 432366],
      [-1278, -8],
    ],
    [
      [233371, 437227],
      [-2, -4811],
    ],
    [
      [233369, 432416],
      [-1193, 2],
    ],
    [
      [232176, 432418],
      [-184, 2822],
    ],
    [
      [234643, 437199],
      [-2, -4800],
    ],
    [
      [234641, 432399],
      [-1272, 17],
    ],
    [
      [235917, 437182],
      [-1, -4805],
    ],
    [
      [235916, 432377],
      [-1275, 22],
    ],
    [
      [237187, 432331],
      [-1271, 46],
    ],
    [
      [187807, 431662],
      [-565, -723],
      [-182, 1854],
      [-716, -2763],
    ],
    [
      [186344, 430030],
      [-640, 5646],
    ],
    [
      [248413, 433778],
      [-2, -4886],
    ],
    [
      [248411, 428892],
      [-1277, 44],
    ],
    [
      [247134, 428936],
      [13, 6504],
    ],
    [
      [285148, 432052],
      [-398, 107],
      [-1152, -1498],
    ],
    [
      [283598, 430661],
      [-21, 738],
    ],
    [
      [251026, 432870],
      [-1183, -88],
    ],
    [
      [249843, 432782],
      [68, 968],
      [-532, 9],
    ],
    [
      [292970, 430541],
      [-431, -269],
    ],
    [
      [292539, 430272],
      [48, 625],
    ],
    [
      [293219, 434221],
      [-249, -3680],
    ],
    [
      [274786, 436568],
      [0, -4189],
    ],
    [
      [274786, 432379],
      [-1580, 38],
    ],
    [
      [273206, 432417],
      [-1, 1611],
    ],
    [
      [288843, 430800],
      [-376, -510],
    ],
    [
      [288467, 430290],
      [-415, -967],
    ],
    [
      [288052, 429323],
      [-667, 3305],
    ],
    [
      [275792, 431551],
      [-1004, -68],
    ],
    [
      [274788, 431483],
      [-2, 896],
    ],
    [
      [253256, 434151],
      [34, -7012],
      [-625, -15],
    ],
    [
      [252665, 427124],
      [-321, -16],
      [-28, 2626],
      [-961, -79],
    ],
    [
      [251355, 429655],
      [-5, 3249],
    ],
    [
      [290810, 432608],
      [-339, -2310],
    ],
    [
      [290471, 430298],
      [-220, -1439],
      [-616, -2210],
    ],
    [
      [289635, 426649],
      [-20, 311],
    ],
    [
      [289615, 426960],
      [68, 3155],
      [341, 1723],
      [-196, 1819],
    ],
    [
      [292192, 432339],
      [-162, 208],
      [-116, -3152],
    ],
    [
      [291914, 429395],
      [-248, -1212],
    ],
    [
      [291666, 428183],
      [-265, 1560],
      [-471, -723],
    ],
    [
      [290930, 429020],
      [-459, 1278],
    ],
    [
      [186344, 430030],
      [155, -1074],
      [97, -4631],
    ],
    [
      [186596, 424325],
      [-109, -154],
      [37, -2824],
      [149, -2529],
      [-95, -2971],
    ],
    [
      [186578, 415847],
      [-452, -1998],
      [-1271, -19],
      [-3480, 52],
    ],
    [
      [181375, 413882],
      [1, 3927],
    ],
    [
      [245728, 435563],
      [-37, -2695],
      [-356, -1884],
      [-63, -2389],
    ],
    [
      [245272, 428595],
      [-424, 1079],
    ],
    [
      [244848, 429674],
      [-402, 1087],
    ],
    [
      [246184, 435528],
      [-12, -8061],
    ],
    [
      [246172, 427467],
      [-319, 64],
    ],
    [
      [245853, 427531],
      [-783, -26],
    ],
    [
      [245070, 427505],
      [202, 1090],
    ],
    [
      [247134, 428936],
      [-3, -1616],
    ],
    [
      [247131, 427320],
      [-959, 147],
    ],
    [
      [270301, 434095],
      [8, -6024],
    ],
    [
      [270309, 428071],
      [-263, -11],
      [2, -1850],
    ],
    [
      [270048, 426210],
      [-326, -246],
    ],
    [
      [269722, 425964],
      [-6, 3195],
      [-215, 1865],
      [-46, 3092],
    ],
    [
      [232176, 432418],
      [-58, -2195],
    ],
    [
      [232118, 430223],
      [-1754, 3],
    ],
    [
      [230364, 430226],
      [-1, 4320],
    ],
    [
      [227844, 435122],
      [-1, -6484],
    ],
    [
      [227843, 428638],
      [-1271, -2],
    ],
    [
      [226572, 428636],
      [-3, 0],
    ],
    [
      [225295, 428631],
      [-1221, 12],
    ],
    [
      [224074, 428643],
      [-2, 6474],
    ],
    [
      [222110, 435114],
      [25, -6995],
    ],
    [
      [222135, 428119],
      [-630, 263],
    ],
    [
      [221505, 428382],
      [-943, 281],
    ],
    [
      [220562, 428663],
      [-673, 5],
    ],
    [
      [224074, 428643],
      [-9, -170],
    ],
    [
      [224065, 428473],
      [-507, -690],
      [-760, 186],
    ],
    [
      [222798, 427969],
      [-663, 150],
    ],
    [
      [229118, 435107],
      [-7, -6481],
    ],
    [
      [229111, 428626],
      [-1268, 12],
    ],
    [
      [230364, 430226],
      [0, -4858],
    ],
    [
      [230364, 425368],
      [-1251, 12],
    ],
    [
      [229113, 425380],
      [-2, 3246],
    ],
    [
      [260509, 434291],
      [14, -6500],
    ],
    [
      [260523, 427791],
      [-630, -27],
    ],
    [
      [259893, 427764],
      [-208, -13],
      [-19, 6483],
    ],
    [
      [255265, 429351],
      [-1, -4575],
    ],
    [
      [255264, 424776],
      [-1140, -98],
    ],
    [
      [254124, 424678],
      [-508, 40],
      [-39, 9489],
    ],
    [
      [261367, 432704],
      [3, -4889],
      [-318, -15],
    ],
    [
      [261052, 427800],
      [-529, -9],
    ],
    [
      [210532, 425375],
      [-1577, 18],
      [-7, -9746],
    ],
    [
      [208948, 415647],
      [-2260, -10],
    ],
    [
      [206688, 415637],
      [-254, -1],
    ],
    [
      [206434, 415636],
      [-6, 4869],
    ],
    [
      [206428, 420505],
      [-5, 1629],
      [310, -3],
      [6, 12084],
    ],
    [
      [210553, 434281],
      [-21, -8906],
    ],
    [
      [217035, 428622],
      [-272, 6],
    ],
    [
      [216763, 428628],
      [-1968, -10],
    ],
    [
      [214795, 428618],
      [0, 969],
    ],
    [
      [214795, 429587],
      [-2, 4705],
    ],
    [
      [201978, 423908],
      [55, -1918],
      [16, -7760],
    ],
    [
      [202049, 414230],
      [-1129, -108],
    ],
    [
      [200920, 414122],
      [-15, 3214],
    ],
    [
      [200905, 417336],
      [-2, 2479],
      [-1118, -26],
    ],
    [
      [199785, 419789],
      [29, 5937],
      [320, 1081],
      [-11, 7495],
    ],
    [
      [201402, 434297],
      [26, -1459],
      [436, -670],
      [159, -1784],
      [-185, -3985],
      [140, -2491],
    ],
    [
      [204199, 424684],
      [-292, -2572],
      [-164, 468],
      [-589, -929],
      [-98, 691],
      [-519, -162],
      [-107, 1114],
      [-340, -603],
      [-112, 1217],
    ],
    [
      [203264, 434210],
      [15, -1191],
      [530, -3758],
      [390, -4577],
    ],
    [
      [199785, 419789],
      [-1878, -31],
      [-2612, 18],
    ],
    [
      [195295, 419776],
      [7, 8024],
    ],
    [
      [195302, 427800],
      [-6, 6461],
    ],
    [
      [213087, 423794],
      [-321, 0],
    ],
    [
      [212766, 423794],
      [-1910, -39],
    ],
    [
      [210856, 423755],
      [-5, 1618],
      [-319, 2],
    ],
    [
      [213118, 434292],
      [7, -4708],
    ],
    [
      [213125, 429584],
      [-38, -5790],
    ],
    [
      [214795, 429587],
      [-1670, -3],
    ],
    [
      [195302, 427800],
      [-315, 562],
      [-118, 3255],
      [-531, -11],
      [-250, -2208],
      [-269, 1599],
      [-807, -1146],
      [-299, 857],
    ],
    [
      [192713, 430708],
      [-64, 73],
    ],
    [
      [176393, 418012],
      [113, -5116],
      [-101, -2197],
      [49, -2635],
      [-263, -2403],
      [2, -5633],
    ],
    [
      [176193, 400028],
      [-1933, -7],
    ],
    [
      [174260, 400021],
      [29, 16934],
      [-18, 16297],
      [27, 1004],
    ],
    [
      [174260, 400021],
      [-2037, 36],
      [-1236, -1303],
    ],
    [
      [170987, 398754],
      [153, 2224],
      [-118, 2607],
      [482, 3073],
      [266, 298],
      [86, 1838],
      [-89, 2169],
      [75, 3206],
      [-204, 1481],
    ],
    [
      [171638, 415650],
      [673, 9777],
    ],
    [
      [206428, 420505],
      [-1667, -25],
    ],
    [
      [204761, 420480],
      [-562, 4204],
    ],
    [
      [259893, 427764],
      [3, -1604],
    ],
    [
      [259896, 426160],
      [-839, -53],
    ],
    [
      [259057, 426107],
      [-11, 6459],
    ],
    [
      [268642, 434167],
      [-7, -5295],
    ],
    [
      [268635, 428872],
      [-365, -116],
    ],
    [
      [268270, 428756],
      [-705, -39],
    ],
    [
      [267565, 428717],
      [-4, 5409],
    ],
    [
      [254124, 424678],
      [8, -1608],
    ],
    [
      [254132, 423070],
      [-1470, -13],
    ],
    [
      [252662, 423057],
      [3, 4067],
    ],
    [
      [269722, 425964],
      [-798, -95],
    ],
    [
      [268924, 425869],
      [-10, 2970],
      [-279, 33],
    ],
    [
      [267565, 428717],
      [-860, -299],
    ],
    [
      [266705, 428418],
      [-211, 275],
      [-56, 2169],
    ],
    [
      [271643, 432655],
      [-7, -4586],
    ],
    [
      [271636, 428069],
      [-1327, 2],
    ],
    [
      [264140, 431625],
      [-158, -803],
      [1, -2439],
    ],
    [
      [263983, 428383],
      [-165, -1],
    ],
    [
      [263818, 428382],
      [0, 807],
      [-964, -2],
    ],
    [
      [262854, 429187],
      [-2, 3620],
    ],
    [
      [273206, 432417],
      [-2, -3235],
    ],
    [
      [273204, 429182],
      [-418, -80],
      [-224, -1340],
    ],
    [
      [272562, 427762],
      [-923, -316],
    ],
    [
      [271639, 427446],
      [-3, 623],
    ],
    [
      [249843, 432782],
      [-231, -3232],
    ],
    [
      [249612, 429550],
      [-356, -3635],
      [-528, -731],
    ],
    [
      [248728, 425184],
      [-4, 2060],
      [-320, 38],
      [7, 1610],
    ],
    [
      [289615, 426960],
      [-382, -1335],
    ],
    [
      [289233, 425625],
      [-268, 2542],
      [-150, -335],
      [-348, 2458],
    ],
    [
      [171638, 415650],
      [-2672, -44],
      [-2017, 13],
    ],
    [
      [288052, 429323],
      [-372, -1084],
    ],
    [
      [287680, 428239],
      [-781, -2648],
      [-748, -727],
    ],
    [
      [286151, 424864],
      [-265, 1103],
    ],
    [
      [285886, 425967],
      [-463, 1914],
    ],
    [
      [274788, 431483],
      [-1, -3962],
    ],
    [
      [274787, 427521],
      [-415, -1047],
    ],
    [
      [274372, 426474],
      [-540, 317],
    ],
    [
      [273832, 426791],
      [-154, 2374],
      [-474, 17],
    ],
    [
      [251355, 429655],
      [-154, -1644],
      [-415, -1270],
      [-378, -47],
    ],
    [
      [250408, 426694],
      [-162, 394],
      [-9, 2476],
      [-625, -14],
    ],
    [
      [262854, 429187],
      [-1, -2903],
    ],
    [
      [262853, 426284],
      [-742, -74],
    ],
    [
      [262111, 426210],
      [-14, 6508],
    ],
    [
      [265423, 430896],
      [0, -3263],
    ],
    [
      [265423, 427633],
      [-634, -31],
      [-321, 800],
      [-485, -19],
    ],
    [
      [188324, 426976],
      [-114, -601],
    ],
    [
      [188210, 426375],
      [-465, -891],
      [-618, -2134],
      [-142, 895],
      [-389, 80],
    ],
    [
      [293524, 432082],
      [-12, -1259],
      [-325, -351],
    ],
    [
      [293187, 430472],
      [-27, 1524],
    ],
    [
      [293160, 431996],
      [39, 674],
    ],
    [
      [262111, 426210],
      [-369, -18],
    ],
    [
      [261742, 426192],
      [-685, -6],
    ],
    [
      [261057, 426186],
      [-5, 1614],
    ],
    [
      [294554, 427872],
      [-875, -1167],
    ],
    [
      [293679, 426705],
      [-79, 470],
    ],
    [
      [293600, 427175],
      [63, 323],
    ],
    [
      [293663, 427498],
      [119, 2121],
      [-126, 535],
    ],
    [
      [293656, 430154],
      [90, 1670],
      [410, 854],
      [287, -1045],
    ],
    [
      [257897, 432590],
      [-1, -3261],
    ],
    [
      [257896, 429329],
      [-463, 33],
      [-65, -3266],
    ],
    [
      [257368, 426096],
      [-903, 15],
    ],
    [
      [256465, 426111],
      [-10, 3233],
    ],
    [
      [259057, 426107],
      [-583, -24],
    ],
    [
      [258474, 426083],
      [2, 2440],
      [-422, -1],
      [-158, 807],
    ],
    [
      [279572, 429120],
      [-482, -6118],
    ],
    [
      [279090, 423002],
      [-351, -508],
      [-158, 819],
      [-661, 807],
      [-155, 1381],
    ],
    [
      [292539, 430272],
      [83, -963],
    ],
    [
      [292622, 429309],
      [-118, -889],
    ],
    [
      [292504, 428420],
      [-590, 975],
    ],
    [
      [233369, 432416],
      [31, -5977],
    ],
    [
      [233400, 426439],
      [-1091, 87],
    ],
    [
      [232309, 426526],
      [-329, 3028],
      [138, 669],
    ],
    [
      [234641, 432399],
      [38, -6061],
    ],
    [
      [234679, 426338],
      [-801, 63],
    ],
    [
      [233878, 426401],
      [-478, 38],
    ],
    [
      [235916, 432377],
      [-1, -6119],
    ],
    [
      [235915, 426258],
      [-449, 10],
    ],
    [
      [235466, 426268],
      [-787, 70],
    ],
    [
      [243584, 430776],
      [5, -4002],
    ],
    [
      [243589, 426774],
      [-630, 136],
    ],
    [
      [242959, 426910],
      [-660, -98],
    ],
    [
      [242299, 426812],
      [2, 5570],
    ],
    [
      [242299, 426812],
      [-476, -61],
    ],
    [
      [241823, 426751],
      [-800, -120],
    ],
    [
      [241023, 426631],
      [-3, 5735],
    ],
    [
      [237187, 432331],
      [-2, -6015],
    ],
    [
      [237185, 426316],
      [-604, -38],
    ],
    [
      [236581, 426278],
      [-666, -20],
    ],
    [
      [238464, 432355],
      [-1, -5921],
    ],
    [
      [238463, 426434],
      [-606, -50],
    ],
    [
      [237857, 426384],
      [-672, -68],
    ],
    [
      [239742, 432358],
      [1, -5855],
    ],
    [
      [239743, 426503],
      [-772, -67],
    ],
    [
      [238971, 426436],
      [-508, -2],
    ],
    [
      [241023, 426631],
      [-213, -26],
    ],
    [
      [240810, 426605],
      [-1067, -102],
    ],
    [
      [284759, 427514],
      [-273, 768],
      [-697, 261],
    ],
    [
      [283789, 428543],
      [-185, 151],
      [-6, 1967],
    ],
    [
      [293187, 430472],
      [-271, -1807],
      [244, 3331],
    ],
    [
      [195295, 419776],
      [-1, -10465],
    ],
    [
      [195294, 409311],
      [0, -3031],
    ],
    [
      [195294, 406280],
      [-156, -665],
      [-2555, -5],
    ],
    [
      [192583, 405610],
      [1, 135],
    ],
    [
      [192584, 405745],
      [-39, 1805],
      [122, 2542],
      [304, 1923],
      [-230, 5],
    ],
    [
      [192741, 412020],
      [-28, 18688],
    ],
    [
      [275819, 428189],
      [-81, -1216],
      [-510, -2451],
    ],
    [
      [275228, 424522],
      [-441, -3],
    ],
    [
      [274787, 424519],
      [0, 3002],
    ],
    [
      [282497, 422685],
      [-451, 359],
      [271, 1888],
      [-71, 939],
      [431, 2360],
      [16, 986],
    ],
    [
      [283789, 428543],
      [-584, -2082],
      [-708, -3776],
    ],
    [
      [192741, 412020],
      [-2481, 120],
    ],
    [
      [190260, 412140],
      [-95, 1614],
    ],
    [
      [190165, 413754],
      [-27, 14503],
    ],
    [
      [266705, 428418],
      [4, -3381],
    ],
    [
      [266709, 425037],
      [-283, -21],
    ],
    [
      [266426, 425016],
      [-1003, 645],
    ],
    [
      [265423, 425661],
      [0, 1972],
    ],
    [
      [292970, 430541],
      [-211, -2552],
      [-210, -378],
      [73, 1698],
    ],
    [
      [245070, 427505],
      [-470, -582],
      [-146, -1016],
      [115, -2813],
      [-151, -420],
    ],
    [
      [244418, 422674],
      [-829, 4100],
    ],
    [
      [293663, 427498],
      [-315, 245],
    ],
    [
      [293348, 427743],
      [-288, 1615],
    ],
    [
      [293060, 429358],
      [139, 999],
      [457, -203],
    ],
    [
      [290930, 429020],
      [33, -2516],
      [-174, -1499],
      [76, -1477],
    ],
    [
      [290865, 423528],
      [-301, -1443],
      [-241, -92],
    ],
    [
      [290323, 421993],
      [-332, 1496],
      [-16, 2220],
      [-340, 940],
    ],
    [
      [289233, 425625],
      [-418, -2203],
    ],
    [
      [288815, 423422],
      [-127, 532],
    ],
    [
      [288688, 423954],
      [-1008, 4285],
    ],
    [
      [232309, 426526],
      [155, -1143],
    ],
    [
      [232464, 425383],
      [-995, -12],
    ],
    [
      [231469, 425371],
      [-1105, -3],
    ],
    [
      [291666, 428183],
      [-6, -1402],
    ],
    [
      [291660, 426781],
      [-177, -793],
      [187, -1217],
      [-446, -2170],
    ],
    [
      [291224, 422601],
      [-359, 927],
    ],
    [
      [252662, 423057],
      [2, -2183],
    ],
    [
      [252664, 420874],
      [-322, -1],
    ],
    [
      [252342, 420873],
      [-1598, 9],
    ],
    [
      [250744, 420882],
      [-321, 803],
    ],
    [
      [250423, 421685],
      [-15, 5009],
    ],
    [
      [214795, 428618],
      [0, -4795],
    ],
    [
      [214795, 423823],
      [-1708, -29],
    ],
    [
      [250423, 421685],
      [-945, -94],
    ],
    [
      [249478, 421591],
      [-311, -17],
      [-6, 2159],
      [-579, 14],
    ],
    [
      [248582, 423747],
      [146, 1437],
    ],
    [
      [282497, 422685],
      [136, -2142],
    ],
    [
      [282633, 420543],
      [-450, -3766],
    ],
    [
      [282183, 416777],
      [-753, 1929],
    ],
    [
      [281430, 418706],
      [-282, 1104],
      [-60, 1366],
    ],
    [
      [281088, 421176],
      [343, 3266],
      [-54, 1256],
    ],
    [
      [281377, 425698],
      [-185, 2188],
      [290, 1510],
    ],
    [
      [281088, 421176],
      [-85, -1011],
      [-408, 1094],
      [-38, -1088],
      [-480, 1549],
    ],
    [
      [280077, 421720],
      [157, 810],
      [68, 2530],
      [527, 4066],
    ],
    [
      [293348, 427743],
      [-53, -1237],
      [-451, 497],
      [216, 2355],
    ],
    [
      [256465, 426111],
      [5, -1623],
    ],
    [
      [256470, 424488],
      [-1206, 27],
    ],
    [
      [255264, 424515],
      [0, 261],
    ],
    [
      [292504, 428420],
      [-134, -1705],
    ],
    [
      [292370, 426715],
      [-710, 66],
    ],
    [
      [258474, 426083],
      [-4, -2412],
    ],
    [
      [258470, 423671],
      [-889, 5],
    ],
    [
      [257581, 423676],
      [-3, 2416],
      [-210, 4],
    ],
    [
      [273832, 426791],
      [-165, -804],
      [-57, -2449],
    ],
    [
      [273610, 423538],
      [-915, 163],
    ],
    [
      [272695, 423701],
      [15, 2450],
      [-148, 1611],
    ],
    [
      [263818, 428382],
      [2, -6071],
      [59, -79],
    ],
    [
      [263879, 422232],
      [-1, -5],
    ],
    [
      [263878, 422227],
      [-1029, -28],
    ],
    [
      [262849, 422199],
      [4, 4085],
    ],
    [
      [280077, 421720],
      [-106, -1566],
    ],
    [
      [279971, 420154],
      [-653, 120],
      [-455, 659],
    ],
    [
      [278863, 420933],
      [227, 2069],
    ],
    [
      [248582, 423747],
      [-330, -1227],
      [-73, -1405],
      [-363, -2059],
    ],
    [
      [247816, 419056],
      [-702, 92],
      [4, 1628],
    ],
    [
      [247118, 420776],
      [13, 6544],
    ],
    [
      [268924, 425869],
      [-64, -3813],
      [-274, 84],
    ],
    [
      [268586, 422140],
      [-515, 158],
      [-254, 1409],
    ],
    [
      [267817, 423707],
      [174, 1059],
      [2, 2875],
      [277, 1115],
    ],
    [
      [267817, 423707],
      [-635, 190],
    ],
    [
      [267182, 423897],
      [2, 1173],
      [-475, -33],
    ],
    [
      [220562, 428663],
      [2, -4872],
      [-317, 7],
      [0, -1627],
    ],
    [
      [220247, 422171],
      [-287, -23],
    ],
    [
      [219960, 422148],
      [-1562, -10],
    ],
    [
      [218398, 422138],
      [-52, 0],
    ],
    [
      [218346, 422138],
      [-2, 6528],
    ],
    [
      [225309, 428632],
      [0, -6478],
    ],
    [
      [225309, 422154],
      [-1242, 1],
    ],
    [
      [224067, 422155],
      [-2, 6318],
    ],
    [
      [221505, 428382],
      [3, -6212],
    ],
    [
      [221508, 422170],
      [-1261, 1],
    ],
    [
      [218346, 422138],
      [-1521, 20],
    ],
    [
      [216825, 422158],
      [-55, -2],
    ],
    [
      [216770, 422156],
      [-7, 6472],
    ],
    [
      [216770, 422156],
      [-1975, -23],
    ],
    [
      [214795, 422133],
      [0, 1690],
    ],
    [
      [227843, 428638],
      [0, -6483],
    ],
    [
      [227843, 422155],
      [-1260, 3],
    ],
    [
      [226583, 422158],
      [-9, 0],
    ],
    [
      [226574, 422158],
      [-2, 6478],
    ],
    [
      [229113, 425380],
      [-8, -3237],
    ],
    [
      [229105, 422143],
      [-1262, 12],
    ],
    [
      [226574, 422158],
      [-1253, -4],
    ],
    [
      [225321, 422154],
      [-12, 0],
    ],
    [
      [284733, 427326],
      [-651, -856],
      [-995, -3406],
      [-367, -2037],
    ],
    [
      [282720, 421027],
      [-87, -484],
    ],
    [
      [190165, 413754],
      [-530, 18],
      [-2, 802],
      [-456, 2164],
      [59, 2009],
      [-250, 2239],
      [-310, -14],
      [-357, 1412],
      [-183, 1615],
      [74, 2376],
    ],
    [
      [224067, 422155],
      [-8, 0],
    ],
    [
      [224059, 422155],
      [-1261, 5],
    ],
    [
      [222798, 422160],
      [0, 5809],
    ],
    [
      [265423, 425661],
      [-341, -1036],
    ],
    [
      [265082, 424625],
      [-938, -40],
      [1, -1903],
      [-266, -450],
    ],
    [
      [222798, 422160],
      [-1257, 8],
    ],
    [
      [221541, 422168],
      [-33, 2],
    ],
    [
      [278863, 420933],
      [-360, -3256],
      [-302, -1293],
    ],
    [
      [278201, 416384],
      [-562, 1959],
      [-401, -1171],
      [-297, 845],
      [-366, -25],
    ],
    [
      [276575, 417992],
      [19, 1314],
    ],
    [
      [276594, 419306],
      [179, 812],
      [290, 5226],
      [-200, 1084],
      [227, 1653],
    ],
    [
      [288688, 423954],
      [-466, -3820],
    ],
    [
      [288222, 420134],
      [-493, -1946],
    ],
    [
      [287729, 418188],
      [-773, 3321],
    ],
    [
      [286956, 421509],
      [-805, 3355],
    ],
    [
      [276594, 419306],
      [-123, 1029],
      [-749, 1490],
      [-494, 2697],
    ],
    [
      [271639, 427446],
      [-58, -3551],
      [-110, 16],
    ],
    [
      [271471, 423911],
      [-1324, 215],
    ],
    [
      [270147, 424126],
      [-99, 2084],
    ],
    [
      [272695, 423701],
      [-15, -2420],
      [-175, -1661],
    ],
    [
      [272505, 419620],
      [-793, 132],
    ],
    [
      [271712, 419752],
      [19, 2733],
      [-268, 49],
      [8, 1377],
    ],
    [
      [285886, 425967],
      [-397, -1498],
      [304, -5190],
    ],
    [
      [285793, 419279],
      [-424, -1382],
    ],
    [
      [285369, 417897],
      [-35, 1119],
      [-345, 857],
    ],
    [
      [284989, 419873],
      [-161, 1874],
    ],
    [
      [284828, 421747],
      [-318, 1084],
      [208, 1376],
      [-107, 1919],
      [122, 1200],
    ],
    [
      [261057, 426186],
      [8, -3496],
    ],
    [
      [261065, 422690],
      [-374, 7],
    ],
    [
      [260691, 422697],
      [-791, -21],
    ],
    [
      [259900, 422676],
      [0, 530],
    ],
    [
      [259900, 423206],
      [-4, 2954],
    ],
    [
      [292731, 427719],
      [-75, -1794],
      [-401, -1052],
      [179, 2727],
      [297, 119],
    ],
    [
      [245853, 427531],
      [-16, -6606],
    ],
    [
      [245837, 420925],
      [-6, -1698],
    ],
    [
      [245831, 419227],
      [-1653, 137],
    ],
    [
      [244178, 419364],
      [22, 898],
    ],
    [
      [244200, 420262],
      [218, 2412],
    ],
    [
      [274787, 424519],
      [3, -1461],
    ],
    [
      [274790, 423058],
      [-309, -66],
    ],
    [
      [274481, 422992],
      [90, 1247],
      [-199, 2235],
    ],
    [
      [247118, 420776],
      [-1281, 149],
    ],
    [
      [284828, 421747],
      [-989, -927],
      [-267, 651],
      [-693, -2144],
    ],
    [
      [282879, 419327],
      [-159, 1700],
    ],
    [
      [244200, 420262],
      [-1263, 158],
    ],
    [
      [242937, 420420],
      [2, 812],
    ],
    [
      [242939, 421232],
      [20, 5678],
    ],
    [
      [290323, 421993],
      [609, -3619],
    ],
    [
      [290932, 418374],
      [-696, -1839],
    ],
    [
      [290236, 416535],
      [-114, 1663],
    ],
    [
      [290122, 418198],
      [-1307, 5224],
    ],
    [
      [292370, 426715],
      [-184, -1985],
      [143, -700],
    ],
    [
      [292329, 424030],
      [-474, -3179],
      [-254, -504],
    ],
    [
      [291601, 420347],
      [-383, 1322],
      [6, 932],
    ],
    [
      [242939, 421232],
      [-1108, 36],
    ],
    [
      [241831, 421268],
      [-6, 813],
    ],
    [
      [241825, 422081],
      [-2, 4670],
    ],
    [
      [274481, 422992],
      [82, -1388],
      [-230, -2519],
    ],
    [
      [274333, 419085],
      [-58, -527],
    ],
    [
      [274275, 418558],
      [-502, 43],
    ],
    [
      [273773, 418601],
      [49, 4903],
      [-212, 34],
    ],
    [
      [241825, 422081],
      [-931, -55],
    ],
    [
      [240894, 422026],
      [21, 2915],
      [-105, 1664],
    ],
    [
      [240894, 422026],
      [-478, -14],
    ],
    [
      [240416, 422012],
      [1, 803],
      [-1426, -54],
    ],
    [
      [238991, 422761],
      [-20, 3675],
    ],
    [
      [233878, 426401],
      [60, -5898],
    ],
    [
      [233938, 420503],
      [-1042, 58],
    ],
    [
      [232896, 420561],
      [-163, 921],
      [-269, 3901],
    ],
    [
      [238991, 422761],
      [0, -2171],
    ],
    [
      [238991, 420590],
      [-1104, -44],
    ],
    [
      [237887, 420546],
      [-30, 5838],
    ],
    [
      [235466, 426268],
      [95, -3437],
    ],
    [
      [235561, 422831],
      [-21, -4851],
    ],
    [
      [235540, 417980],
      [-1218, 74],
    ],
    [
      [234322, 418054],
      [-79, 2428],
      [-305, 21],
    ],
    [
      [190260, 412140],
      [-1085, 14],
    ],
    [
      [189175, 412154],
      [-1095, -4],
    ],
    [
      [188080, 412150],
      [-519, 2499],
      [-397, -2608],
      [-336, -472],
      [101, 2059],
      [-351, 2219],
    ],
    [
      [237887, 420546],
      [-2, -2440],
    ],
    [
      [237885, 418106],
      [-1261, 68],
    ],
    [
      [236624, 418174],
      [7, 4610],
    ],
    [
      [236631, 422784],
      [-50, 3494],
    ],
    [
      [270147, 424126],
      [-32, -4044],
    ],
    [
      [270115, 420082],
      [-784, 126],
      [-762, 580],
    ],
    [
      [268569, 420788],
      [17, 1352],
    ],
    [
      [262849, 422199],
      [1, -796],
    ],
    [
      [262850, 421403],
      [-1157, -62],
    ],
    [
      [261693, 421341],
      [-3, 1350],
    ],
    [
      [261690, 422691],
      [52, 3501],
    ],
    [
      [236631, 422784],
      [-1070, 47],
    ],
    [
      [261690, 422691],
      [-625, -1],
    ],
    [
      [259900, 423206],
      [-1060, -73],
      [0, -541],
    ],
    [
      [258840, 422592],
      [-370, 1079],
    ],
    [
      [257581, 423676],
      [-3, -4054],
    ],
    [
      [257578, 419622],
      [-1105, 9],
    ],
    [
      [256473, 419631],
      [1, 2822],
    ],
    [
      [256474, 422453],
      [-4, 2035],
    ],
    [
      [232896, 420561],
      [7, -55],
    ],
    [
      [232903, 420506],
      [-1276, -7],
    ],
    [
      [231627, 420499],
      [-157, 10],
    ],
    [
      [231470, 420509],
      [-1, 4862],
    ],
    [
      [286956, 421509],
      [-435, -1129],
      [-728, -1101],
    ],
    [
      [266426, 425016],
      [-87, -5114],
    ],
    [
      [266339, 419902],
      [-1291, 820],
    ],
    [
      [265048, 420722],
      [34, 3903],
    ],
    [
      [210856, 423755],
      [-18, -8096],
      [-651, -3],
    ],
    [
      [210187, 415656],
      [-1239, -9],
    ],
    [
      [230364, 425368],
      [0, -4857],
    ],
    [
      [230364, 420511],
      [0, -4861],
    ],
    [
      [230364, 415650],
      [-953, 8],
    ],
    [
      [229411, 415658],
      [-308, 1],
    ],
    [
      [229103, 415659],
      [2, 6484],
    ],
    [
      [231470, 420509],
      [-1106, 2],
    ],
    [
      [267401, 418295],
      [-101, -658],
    ],
    [
      [267300, 417637],
      [-828, 69],
    ],
    [
      [266472, 417706],
      [25, 2123],
      [-158, 73],
    ],
    [
      [267182, 423897],
      [1, -3714],
      [212, -4],
      [6, -1884],
    ],
    [
      [255264, 424515],
      [-14, -6129],
    ],
    [
      [255250, 418386],
      [-5, -4933],
    ],
    [
      [255245, 413453],
      [-1127, -59],
    ],
    [
      [254118, 413394],
      [14, 9676],
    ],
    [
      [204761, 420480],
      [44, -3995],
      [-107, -2111],
    ],
    [
      [204698, 414374],
      [-40, -1499],
    ],
    [
      [204658, 412875],
      [-515, -982],
      [-138, -1866],
    ],
    [
      [204005, 410027],
      [-269, -196],
      [-186, 2235],
      [-440, 1922],
      [-526, 245],
    ],
    [
      [202584, 414233],
      [-535, -3],
    ],
    [
      [265048, 420722],
      [-23, -1666],
    ],
    [
      [265025, 419056],
      [-1141, 243],
    ],
    [
      [263884, 419299],
      [-6, 2928],
    ],
    [
      [276575, 417992],
      [2, -1690],
      [-338, -982],
    ],
    [
      [276239, 415320],
      [-807, 669],
      [-645, -1057],
    ],
    [
      [274787, 414932],
      [1, 1006],
    ],
    [
      [274788, 415938],
      [-1, 2667],
    ],
    [
      [274787, 418605],
      [3, 4453],
    ],
    [
      [256474, 422453],
      [-391, -1026],
      [-571, -2640],
      [86, -782],
    ],
    [
      [255598, 418005],
      [-348, 381],
    ],
    [
      [292681, 417933],
      [-2, 100],
    ],
    [
      [292679, 418033],
      [-411, -199],
      [-60, 940],
      [-392, 65],
      [-407, -1734],
    ],
    [
      [291409, 417105],
      [-97, 1099],
    ],
    [
      [291312, 418204],
      [289, 2143],
    ],
    [
      [292329, 424030],
      [617, -741],
      [71, -1974],
      [-164, -3756],
      [-172, 374],
    ],
    [
      [271712, 419752],
      [-260, -1288],
    ],
    [
      [271452, 418464],
      [-1312, 274],
    ],
    [
      [270140, 418738],
      [-25, 1344],
    ],
    [
      [290122, 418198],
      [-263, -1712],
    ],
    [
      [289859, 416486],
      [-319, 873],
      [49, -1506],
      [-196, -651],
    ],
    [
      [289393, 415202],
      [-235, 1650],
    ],
    [
      [289158, 416852],
      [-265, 413],
      [-399, 2752],
      [-272, 117],
    ],
    [
      [160617, 423926],
      [82, -1783],
      [339, -681],
      [-59, -1870],
      [-213, -1153],
    ],
    [
      [160766, 418439],
      [-393, -947],
      [-168, -2179],
      [-364, -1740],
      [-738, -94],
      [-15, -1615],
    ],
    [
      [159088, 411864],
      [-2483, 10],
    ],
    [
      [156605, 411874],
      [10, 3351],
    ],
    [
      [164380, 410189],
      [-1412, -44],
      [-74, -533],
      [-535, 1856],
      [-381, -2584],
    ],
    [
      [161978, 408884],
      [-186, -741],
    ],
    [
      [161792, 408143],
      [-928, 5114],
      [-31, 2656],
      [120, 1726],
      [-187, 800],
    ],
    [
      [268569, 420788],
      [-30, -2813],
    ],
    [
      [268539, 417975],
      [-1138, 320],
    ],
    [
      [214795, 422133],
      [0, -6444],
    ],
    [
      [214795, 415689],
      [6, -7987],
    ],
    [
      [214801, 407702],
      [-2099, -116],
    ],
    [
      [212702, 407586],
      [-2, 8093],
      [66, 8115],
    ],
    [
      [212702, 407586],
      [-979, -41],
    ],
    [
      [211723, 407545],
      [-1541, 11],
    ],
    [
      [210182, 407556],
      [3, 3232],
    ],
    [
      [210185, 410788],
      [2, 4868],
    ],
    [
      [249478, 421591],
      [4, -3680],
    ],
    [
      [249482, 417911],
      [-1008, 385],
      [-87, -647],
    ],
    [
      [248387, 417649],
      [-390, -864],
      [-421, -125],
      [-193, 1284],
    ],
    [
      [247383, 417944],
      [433, 1112],
    ],
    [
      [273773, 418601],
      [-955, 197],
    ],
    [
      [272818, 418798],
      [-313, 822],
    ],
    [
      [258840, 422592],
      [0, -2942],
    ],
    [
      [258840, 419650],
      [1, -653],
    ],
    [
      [258841, 418997],
      [-1263, -39],
    ],
    [
      [257578, 418958],
      [0, 664],
    ],
    [
      [291312, 418204],
      [-380, 170],
    ],
    [
      [259900, 422676],
      [0, -2968],
    ],
    [
      [259900, 419708],
      [-1060, -58],
    ],
    [
      [274787, 418605],
      [-454, 480],
    ],
    [
      [254118, 413394],
      [-1462, -13],
    ],
    [
      [252656, 413381],
      [8, 7493],
    ],
    [
      [240416, 422012],
      [-1, -5688],
    ],
    [
      [240415, 416324],
      [-1409, -76],
    ],
    [
      [239006, 416248],
      [-15, 4342],
    ],
    [
      [236624, 418174],
      [-6, -1893],
    ],
    [
      [236618, 416281],
      [-1077, 81],
    ],
    [
      [235541, 416362],
      [-1, 1618],
    ],
    [
      [260691, 422697],
      [7, -5629],
    ],
    [
      [260698, 417068],
      [-1, -2446],
    ],
    [
      [260697, 414622],
      [-797, -40],
    ],
    [
      [259900, 414582],
      [0, 5126],
    ],
    [
      [261693, 421341],
      [13, -4278],
    ],
    [
      [261706, 417063],
      [-1008, 5],
    ],
    [
      [256473, 419631],
      [4, -4874],
    ],
    [
      [256477, 414757],
      [-917, -11],
    ],
    [
      [255560, 414746],
      [38, 3259],
    ],
    [
      [263884, 419299],
      [17, -5164],
    ],
    [
      [263901, 414135],
      [-164, -21],
    ],
    [
      [263737, 414114],
      [-912, -29],
    ],
    [
      [262825, 414085],
      [5, 1641],
    ],
    [
      [262830, 415726],
      [20, 5677],
    ],
    [
      [225321, 422154],
      [-1, -6473],
    ],
    [
      [225320, 415681],
      [-642, -7],
    ],
    [
      [224678, 415674],
      [-618, 2],
    ],
    [
      [224060, 415676],
      [-1, 6479],
    ],
    [
      [224060, 415676],
      [-949, -1],
    ],
    [
      [223111, 415675],
      [-312, -4],
    ],
    [
      [222799, 415671],
      [-1, 6489],
    ],
    [
      [226583, 422158],
      [-2, -6488],
    ],
    [
      [226581, 415670],
      [-307, 0],
    ],
    [
      [226274, 415670],
      [-954, 11],
    ],
    [
      [216825, 422158],
      [-7, -6476],
    ],
    [
      [216818, 415682],
      [-238, -3],
    ],
    [
      [216580, 415679],
      [-1785, 10],
    ],
    [
      [221541, 422168],
      [6, -6503],
    ],
    [
      [221547, 415665],
      [-1532, -5],
    ],
    [
      [220015, 415660],
      [-43, 2],
    ],
    [
      [219972, 415662],
      [-12, 6486],
    ],
    [
      [227843, 422155],
      [-2, -6487],
    ],
    [
      [227841, 415668],
      [-1260, 2],
    ],
    [
      [229103, 415659],
      [-1262, 9],
    ],
    [
      [219972, 415662],
      [-1519, 9],
    ],
    [
      [218453, 415671],
      [-56, 3],
    ],
    [
      [218397, 415674],
      [1, 6464],
    ],
    [
      [218397, 415674],
      [-1579, 8],
    ],
    [
      [222799, 415671],
      [-1245, -6],
    ],
    [
      [221554, 415665],
      [-7, 0],
    ],
    [
      [241831, 421268],
      [7, -4945],
    ],
    [
      [241838, 416323],
      [-1399, 2],
    ],
    [
      [240439, 416325],
      [-24, -1],
    ],
    [
      [284989, 419873],
      [-135, -1158],
      [-365, -437],
      [-280, -1345],
    ],
    [
      [284209, 416933],
      [-135, -748],
      [-607, -656],
      [-188, -939],
    ],
    [
      [283279, 414590],
      [-400, 4737],
    ],
    [
      [250744, 420882],
      [11, -4342],
    ],
    [
      [250755, 416540],
      [4, -2437],
      [-209, -17],
    ],
    [
      [250550, 414086],
      [-738, 301],
      [-265, 799],
    ],
    [
      [249547, 415186],
      [-65, 2725],
    ],
    [
      [281430, 418706],
      [-459, -6339],
      [-226, -1900],
    ],
    [
      [280745, 410467],
      [-1192, 6],
    ],
    [
      [279553, 410473],
      [143, 1890],
      [-17, 4331],
      [292, 3460],
    ],
    [
      [287729, 418188],
      [-193, -522],
      [-134, -4476],
      [-402, -2750],
    ],
    [
      [287000, 410440],
      [-272, 4],
    ],
    [
      [286728, 410444],
      [-19, 0],
    ],
    [
      [286709, 410444],
      [-635, 4132],
      [-197, 2069],
      [-508, 1252],
    ],
    [
      [262830, 415726],
      [-1089, -10],
    ],
    [
      [261741, 415716],
      [-35, 1347],
    ],
    [
      [242937, 420420],
      [-5, -5731],
    ],
    [
      [242932, 414689],
      [-936, 53],
    ],
    [
      [241996, 414742],
      [-158, 1581],
    ],
    [
      [283279, 414590],
      [33, -4171],
    ],
    [
      [283312, 410419],
      [-28, 0],
    ],
    [
      [283284, 410419],
      [-1756, 39],
    ],
    [
      [281528, 410458],
      [270, 1944],
      [385, 4375],
    ],
    [
      [279553, 410473],
      [-342, -2],
    ],
    [
      [279211, 410471],
      [-1285, -23],
    ],
    [
      [277926, 410448],
      [-70, 2461],
      [345, 3475],
    ],
    [
      [252342, 420873],
      [-474, -4214],
    ],
    [
      [251868, 416659],
      [-1113, -119],
    ],
    [
      [247383, 417944],
      [-442, -2539],
    ],
    [
      [246941, 415405],
      [-262, -106],
      [-246, 2264],
      [-607, 13],
    ],
    [
      [245826, 417576],
      [5, 1651],
    ],
    [
      [252656, 413381],
      [-31, -1626],
    ],
    [
      [252625, 411755],
      [-759, 5],
    ],
    [
      [251866, 411760],
      [2, 4899],
    ],
    [
      [270140, 418738],
      [-33, -4034],
      [-98, -688],
    ],
    [
      [270009, 414016],
      [-637, 319],
    ],
    [
      [269372, 414335],
      [-891, 172],
    ],
    [
      [268481, 414507],
      [58, 3468],
    ],
    [
      [266472, 417706],
      [-35, -1885],
    ],
    [
      [266437, 415821],
      [-1449, 559],
    ],
    [
      [264988, 416380],
      [37, 2676],
    ],
    [
      [239006, 416248],
      [0, -1219],
    ],
    [
      [239006, 415029],
      [-1115, -150],
    ],
    [
      [237891, 414879],
      [-6, 3227],
    ],
    [
      [206434, 415636],
      [-263, -1609],
    ],
    [
      [206171, 414027],
      [-698, -18],
    ],
    [
      [205473, 414009],
      [-2, 412],
      [-773, -47],
    ],
    [
      [231627, 420499],
      [-1, -4856],
    ],
    [
      [231626, 415643],
      [-637, 4],
    ],
    [
      [230989, 415647],
      [-625, 3],
    ],
    [
      [232903, 420506],
      [433, -2548],
      [-67, -1276],
      [313, -1051],
    ],
    [
      [233582, 415631],
      [-87, 0],
    ],
    [
      [233495, 415631],
      [-1249, 8],
    ],
    [
      [232246, 415639],
      [-620, 4],
    ],
    [
      [234322, 418054],
      [138, -4323],
    ],
    [
      [234460, 413731],
      [-255, -671],
      [-623, 2571],
    ],
    [
      [244178, 419364],
      [30, -3037],
      [165, -1715],
    ],
    [
      [244373, 414612],
      [-1128, 56],
    ],
    [
      [243245, 414668],
      [-313, 21],
    ],
    [
      [289158, 416852],
      [-454, -2262],
      [-196, -1986],
    ],
    [
      [288508, 412604],
      [-541, -2144],
    ],
    [
      [287967, 410460],
      [-967, -20],
    ],
    [
      [286709, 410444],
      [-918, -3],
    ],
    [
      [285791, 410441],
      [-606, -7],
    ],
    [
      [285185, 410434],
      [-592, -13],
    ],
    [
      [284593, 410421],
      [124, 2546],
      [-179, 2926],
      [-329, 1040],
    ],
    [
      [200905, 417336],
      [-779, -15],
      [-5, -3273],
      [-315, 52],
      [0, -1664],
      [-1412, -62],
      [0, -2430],
      [-1717, -16],
      [1, -809],
      [-1384, 192],
    ],
    [
      [272795, 414723],
      [-423, -10],
      [-218, -1045],
      [-319, 59],
      [-5, -1092],
      [-317, 67],
    ],
    [
      [271513, 412702],
      [-96, 1662],
      [35, 4100],
    ],
    [
      [272818, 418798],
      [-23, -4075],
    ],
    [
      [259900, 414582],
      [-209, -308],
    ],
    [
      [259691, 414274],
      [-843, -20],
    ],
    [
      [258848, 414254],
      [-7, 4743],
    ],
    [
      [257578, 418958],
      [2, -4763],
    ],
    [
      [257580, 414195],
      [0, -1081],
    ],
    [
      [257580, 413114],
      [-876, 36],
    ],
    [
      [256704, 413150],
      [-229, 10],
      [2, 1597],
    ],
    [
      [245826, 417576],
      [-7, -4829],
    ],
    [
      [245819, 412747],
      [1, -1636],
      [-1253, 29],
    ],
    [
      [244567, 411140],
      [-193, 1581],
      [-1, 1891],
    ],
    [
      [264988, 416380],
      [-41, -2986],
    ],
    [
      [264947, 413394],
      [-298, 808],
      [-748, -67],
    ],
    [
      [274788, 415938],
      [-600, 318],
    ],
    [
      [274188, 416256],
      [87, 2302],
    ],
    [
      [291409, 417105],
      [453, -5673],
      [-67, -3974],
    ],
    [
      [291795, 407458],
      [-8, -68],
    ],
    [
      [291787, 407390],
      [-674, 1374],
      [-215, 1839],
    ],
    [
      [290898, 410603],
      [-464, 1137],
      [-81, 1797],
      [-341, 1921],
    ],
    [
      [290012, 415458],
      [-13, 49],
    ],
    [
      [289999, 415507],
      [237, 1028],
    ],
    [
      [258848, 414254],
      [-240, -36],
    ],
    [
      [258608, 414218],
      [-1028, -23],
    ],
    [
      [274188, 416256],
      [-53, -2245],
      [-194, -1195],
    ],
    [
      [273941, 412816],
      [-1155, 355],
    ],
    [
      [272786, 413171],
      [9, 1552],
    ],
    [
      [292681, 417933],
      [179, -418],
      [-159, -5398],
      [-46, 2228],
      [-255, -3465],
      [103, -763],
      [-505, -2576],
      [-203, -83],
    ],
    [
      [271513, 412702],
      [-9, -1621],
    ],
    [
      [271504, 411081],
      [-1056, 287],
    ],
    [
      [270448, 411368],
      [-261, 927],
      [22, 1648],
      [-200, 73],
    ],
    [
      [281528, 410458],
      [-678, 3],
    ],
    [
      [280850, 410461],
      [-105, 6],
    ],
    [
      [249547, 415186],
      [-334, -14],
      [-11, -1088],
      [-814, -276],
    ],
    [
      [248388, 413808],
      [-1, 3841],
    ],
    [
      [161792, 408143],
      [-203, -1301],
      [-432, -149],
      [-282, -3313],
      [-609, -861],
    ],
    [
      [160266, 402519],
      [-794, 177],
    ],
    [
      [159472, 402696],
      [52, 1463],
    ],
    [
      [159524, 404159],
      [106, 2863],
      [-398, -48],
      [-7, 2486],
      [153, 591],
      [-290, 1813],
    ],
    [
      [255560, 414746],
      [183, -1655],
      [-78, -4758],
    ],
    [
      [255665, 408333],
      [-417, -12],
    ],
    [
      [255248, 408321],
      [-3, 5132],
    ],
    [
      [277926, 410448],
      [-234, -9],
    ],
    [
      [277692, 410439],
      [-800, -6],
    ],
    [
      [276892, 410433],
      [-426, 0],
    ],
    [
      [276466, 410433],
      [-22, 3775],
      [-205, 1112],
    ],
    [
      [268481, 414507],
      [-116, -2692],
    ],
    [
      [268365, 411815],
      [-1169, 326],
    ],
    [
      [267196, 412141],
      [-43, 3579],
      [147, 1917],
    ],
    [
      [289999, 415507],
      [-235, -1943],
      [-206, -350],
    ],
    [
      [289558, 413214],
      [-165, 1988],
    ],
    [
      [237891, 414879],
      [8, -3256],
    ],
    [
      [237899, 411623],
      [-1244, 79],
    ],
    [
      [236655, 411702],
      [-37, 4579],
    ],
    [
      [235541, 416362],
      [8, -4089],
    ],
    [
      [235549, 412273],
      [-763, 19],
    ],
    [
      [234786, 412292],
      [-326, 1439],
    ],
    [
      [181375, 413882],
      [-2, -6764],
    ],
    [
      [181373, 407118],
      [-6, -16110],
    ],
    [
      [181367, 391008],
      [-2649, 0],
    ],
    [
      [178718, 391008],
      [-2525, 9020],
    ],
    [
      [248388, 413808],
      [0, -544],
    ],
    [
      [248388, 413264],
      [-1641, 72],
    ],
    [
      [246747, 413336],
      [194, 2069],
    ],
    [
      [267196, 412141],
      [-24, -2179],
    ],
    [
      [267172, 409962],
      [-1117, 398],
    ],
    [
      [266055, 410360],
      [17, 1046],
    ],
    [
      [266072, 411406],
      [162, -81],
      [203, 4496],
    ],
    [
      [246747, 413336],
      [32, -697],
    ],
    [
      [246779, 412639],
      [-960, 108],
    ],
    [
      [200920, 414122],
      [-228, 4],
      [1, -10296],
    ],
    [
      [200693, 403830],
      [-885, 2],
    ],
    [
      [199808, 403832],
      [-4513, 9],
    ],
    [
      [195295, 403841],
      [-1, 2439],
    ],
    [
      [261741, 415716],
      [-55, -4023],
    ],
    [
      [261686, 411693],
      [-223, -17],
    ],
    [
      [261463, 411676],
      [-823, -18],
    ],
    [
      [260640, 411658],
      [57, 2964],
    ],
    [
      [284593, 410421],
      [-604, -5],
    ],
    [
      [283989, 410416],
      [-677, 3],
    ],
    [
      [289558, 413214],
      [-576, -1173],
    ],
    [
      [288982, 412041],
      [-434, 587],
    ],
    [
      [288548, 412628],
      [-38, -23],
    ],
    [
      [288510, 412605],
      [-2, -1],
    ],
    [
      [251866, 411760],
      [-181, -2587],
    ],
    [
      [251685, 409173],
      [-599, 19],
    ],
    [
      [251086, 409192],
      [-316, 21],
      [-7, 2708],
      [-211, 269],
    ],
    [
      [250552, 412190],
      [-2, 1896],
    ],
    [
      [206688, 415637],
      [-41, -6],
    ],
    [
      [206647, 415631],
      [-213, -1605],
    ],
    [
      [206434, 414026],
      [-263, 1],
    ],
    [
      [241996, 414742],
      [-30, -6459],
    ],
    [
      [241966, 408283],
      [-4, 0],
    ],
    [
      [241962, 408283],
      [-1090, 94],
    ],
    [
      [240872, 408377],
      [8, 1624],
      [-470, 45],
    ],
    [
      [240410, 410046],
      [29, 6279],
    ],
    [
      [266072, 411406],
      [-502, 924],
      [-631, 517],
    ],
    [
      [264939, 412847],
      [8, 547],
    ],
    [
      [240410, 410046],
      [-1140, 66],
    ],
    [
      [239270, 410112],
      [-272, 4],
      [8, 4913],
    ],
    [
      [236655, 411702],
      [-1, -802],
    ],
    [
      [236654, 410900],
      [-1104, 25],
    ],
    [
      [235550, 410925],
      [-1, 1348],
    ],
    [
      [274786, 410447],
      [-874, -18],
    ],
    [
      [273912, 410429],
      [29, 2387],
    ],
    [
      [274787, 414932],
      [-1, -4485],
    ],
    [
      [276466, 410433],
      [-1406, 8],
    ],
    [
      [275060, 410441],
      [-274, 6],
    ],
    [
      [188080, 412150],
      [154, -1357],
      [-372, -847],
      [15, -4365],
      [-94, -1439],
      [-489, -27],
      [-259, -1246],
    ],
    [
      [187035, 402869],
      [-502, 281],
      [-66, 4178],
      [-5094, -210],
    ],
    [
      [262825, 414085],
      [-5, -3544],
    ],
    [
      [262820, 410541],
      [-613, -224],
    ],
    [
      [262207, 410317],
      [-419, 17],
      [-102, 1359],
    ],
    [
      [216580, 415679],
      [-8, -8083],
    ],
    [
      [216572, 407596],
      [-1771, -4],
    ],
    [
      [214801, 407592],
      [0, 110],
    ],
    [
      [218453, 415671],
      [-6, -8082],
    ],
    [
      [218447, 407589],
      [-1806, 7],
    ],
    [
      [216641, 407596],
      [-69, 0],
    ],
    [
      [156605, 411874],
      [135, -4012],
    ],
    [
      [156740, 407862],
      [1, -964],
      [-483, -512],
      [-38, -1771],
      [225, -3191],
      [-238, -1152],
      [-36, -1898],
      [296, -1405],
      [109, -1803],
      [362, -938],
    ],
    [
      [156938, 394228],
      [-728, 41],
      [-164, -808],
      [-645, -63],
      [-455, -694],
    ],
    [
      [154946, 392704],
      [-541, 2801],
      [109, 2093],
      [-386, 5926],
      [150, 3428],
      [-35, 2228],
      [-194, 3368],
      [-468, 3106],
    ],
    [
      [226274, 415670],
      [1, -6485],
    ],
    [
      [226275, 409185],
      [-1, -1617],
    ],
    [
      [226274, 407568],
      [-1548, 10],
    ],
    [
      [224726, 407578],
      [-50, 3],
    ],
    [
      [224676, 407581],
      [2, 8093],
    ],
    [
      [224676, 407581],
      [-1502, 8],
    ],
    [
      [223174, 407589],
      [-61, 1],
    ],
    [
      [223113, 407590],
      [-2, 8085],
    ],
    [
      [227841, 415668],
      [2, -6478],
    ],
    [
      [227843, 409190],
      [-1568, -5],
    ],
    [
      [229411, 415658],
      [-2, -8099],
    ],
    [
      [229409, 407559],
      [-425, -1],
    ],
    [
      [228984, 407558],
      [-1141, 12],
    ],
    [
      [227843, 407570],
      [0, 1620],
    ],
    [
      [220015, 415660],
      [-6, -8093],
    ],
    [
      [220009, 407567],
      [-1504, 22],
    ],
    [
      [218505, 407589],
      [-58, 0],
    ],
    [
      [170987, 398754],
      [-249, -368],
    ],
    [
      [170738, 398386],
      [-2476, 18],
    ],
    [
      [168262, 398404],
      [-1, 696],
      [-1014, 5147],
      [112, 6217],
      [-310, -1673],
    ],
    [
      [221554, 415665],
      [-6, -8087],
    ],
    [
      [221548, 407578],
      [-1487, -3],
    ],
    [
      [220061, 407575],
      [-52, -8],
    ],
    [
      [223113, 407590],
      [-1493, -14],
    ],
    [
      [221620, 407576],
      [-72, 2],
    ],
    [
      [230989, 415647],
      [1, -8092],
    ],
    [
      [230990, 407555],
      [-955, 2],
    ],
    [
      [230035, 407557],
      [-626, 2],
    ],
    [
      [210185, 410788],
      [-3283, 6],
    ],
    [
      [206902, 410794],
      [415, 545],
      [-67, 968],
      [390, 99],
      [54, 1354],
      [-365, -16],
      [-165, -1868],
      [-731, -135],
    ],
    [
      [206433, 411741],
      [1, 2285],
    ],
    [
      [233495, 415631],
      [0, -6461],
    ],
    [
      [233495, 409170],
      [-625, 0],
    ],
    [
      [232870, 409170],
      [-626, 1],
    ],
    [
      [232244, 409171],
      [2, 6468],
    ],
    [
      [232244, 409171],
      [-1, -1611],
      [-687, -7],
    ],
    [
      [231556, 407553],
      [-566, 2],
    ],
    [
      [234786, 412292],
      [-21, -1748],
      [-200, 350],
      [-276, -2271],
    ],
    [
      [234289, 408623],
      [-168, 539],
      [-626, 8],
    ],
    [
      [290898, 410603],
      [-392, -2264],
    ],
    [
      [290506, 408339],
      [-409, 2275],
      [-302, 2865],
    ],
    [
      [289795, 413479],
      [217, 1979],
    ],
    [
      [250552, 412190],
      [-498, -1355],
      [-387, -1885],
      [5, -2169],
    ],
    [
      [249672, 406781],
      [-468, -23],
    ],
    [
      [249204, 406758],
      [-627, -24],
    ],
    [
      [248577, 406734],
      [-163, 3649],
      [-26, 2881],
    ],
    [
      [239270, 410112],
      [-32, -1637],
    ],
    [
      [239238, 408475],
      [-1337, -78],
    ],
    [
      [237901, 408397],
      [-2, 3226],
    ],
    [
      [243245, 414668],
      [-19, -5394],
    ],
    [
      [243226, 409274],
      [-941, 82],
      [-5, -1096],
      [-314, 23],
    ],
    [
      [256704, 413150],
      [-11, -4877],
    ],
    [
      [256693, 408273],
      [-517, 45],
    ],
    [
      [256176, 408318],
      [-511, 15],
    ],
    [
      [272786, 413171],
      [-213, 31],
      [-15, -3018],
      [100, -1891],
    ],
    [
      [272658, 408293],
      [-850, -351],
    ],
    [
      [271808, 407942],
      [-304, 3139],
    ],
    [
      [244567, 411140],
      [169, -1392],
    ],
    [
      [244736, 409748],
      [-1143, 47],
      [1, -537],
    ],
    [
      [243594, 409258],
      [-368, 16],
    ],
    [
      [260640, 411658],
      [-101, -1640],
    ],
    [
      [260539, 410018],
      [-888, -25],
    ],
    [
      [259651, 409993],
      [40, 4281],
    ],
    [
      [269372, 414335],
      [-25, -1734],
      [207, -77],
      [64, -3317],
    ],
    [
      [269618, 409207],
      [-318, 124],
      [-21, -1094],
      [-338, 108],
      [-318, -1009],
    ],
    [
      [268623, 407336],
      [-310, 141],
      [52, 4338],
    ],
    [
      [205473, 414009],
      [-1, -3086],
    ],
    [
      [205472, 410923],
      [-407, 495],
      [-407, 1457],
    ],
    [
      [270448, 411368],
      [146, -862],
      [-65, -3237],
    ],
    [
      [270529, 407269],
      [-311, 107],
    ],
    [
      [270218, 407376],
      [-615, 748],
      [15, 1083],
    ],
    [
      [259651, 409993],
      [-1, -1094],
    ],
    [
      [259650, 408899],
      [-829, -88],
    ],
    [
      [258821, 408811],
      [-213, -27],
    ],
    [
      [258608, 408784],
      [0, 5434],
    ],
    [
      [202584, 414233],
      [137, -2936],
      [562, -2151],
      [-209, -2932],
      [145, -2133],
    ],
    [
      [203219, 404081],
      [-612, -329],
    ],
    [
      [202607, 403752],
      [-1914, 78],
    ],
    [
      [204005, 410027],
      [367, -1170],
      [-102, -1326],
    ],
    [
      [204270, 407531],
      [-525, -3718],
      [-328, 267],
    ],
    [
      [203417, 404080],
      [-198, 1],
    ],
    [
      [258608, 408784],
      [-389, -585],
      [-532, 8],
    ],
    [
      [257687, 408207],
      [-86, 542],
      [-21, 4365],
    ],
    [
      [264939, 412847],
      [-109, -226],
      [-59, -4847],
    ],
    [
      [264771, 407774],
      [-700, 215],
    ],
    [
      [264071, 407989],
      [-317, 28],
    ],
    [
      [263754, 408017],
      [-17, 6097],
    ],
    [
      [263754, 408017],
      [1, -413],
      [-936, -24],
    ],
    [
      [262819, 407580],
      [1, 2961],
    ],
    [
      [206433, 411741],
      [-1, -3287],
    ],
    [
      [206432, 408454],
      [13, -903],
    ],
    [
      [206445, 407551],
      [-516, -5958],
      [-264, -2164],
    ],
    [
      [205665, 399429],
      [-5, 0],
    ],
    [
      [205660, 399429],
      [-187, -2],
      [-3, 8127],
    ],
    [
      [205470, 407554],
      [2, 3369],
    ],
    [
      [206902, 410794],
      [-80, -2161],
      [-167, 812],
      [-223, -991],
    ],
    [
      [290506, 408339],
      [-301, -1740],
    ],
    [
      [290205, 406599],
      [-214, 1002],
    ],
    [
      [289991, 407601],
      [-385, 1831],
      [-352, 446],
      [-281, 1743],
    ],
    [
      [288973, 411621],
      [822, 1858],
    ],
    [
      [255248, 408321],
      [1, -2424],
    ],
    [
      [255249, 405897],
      [-1194, 77],
    ],
    [
      [254055, 405974],
      [-17, 3810],
    ],
    [
      [254038, 409784],
      [80, 3610],
    ],
    [
      [254038, 409784],
      [-270, -622],
      [-1139, -17],
    ],
    [
      [252629, 409145],
      [-4, 2610],
    ],
    [
      [248577, 406734],
      [-633, -33],
    ],
    [
      [247944, 406701],
      [-412, -2],
    ],
    [
      [247532, 406699],
      [2, 2166],
      [-201, 554],
      [5, 1620],
      [-635, 683],
    ],
    [
      [246703, 411722],
      [76, 917],
    ],
    [
      [273912, 410429],
      [-309, -2118],
    ],
    [
      [273603, 408311],
      [-264, -1244],
    ],
    [
      [273339, 407067],
      [6, 599],
      [-687, 627],
    ],
    [
      [257687, 408207],
      [-84, -2441],
    ],
    [
      [257603, 405766],
      [-705, 61],
    ],
    [
      [256898, 405827],
      [-209, 8],
      [4, 2438],
    ],
    [
      [205470, 407554],
      [-1200, -23],
    ],
    [
      [266055, 410360],
      [-47, -3101],
    ],
    [
      [266008, 407259],
      [-855, 352],
    ],
    [
      [265153, 407611],
      [-382, 163],
    ],
    [
      [246703, 411722],
      [-132, -1725],
      [183, -3269],
    ],
    [
      [246754, 406728],
      [-92, -2345],
    ],
    [
      [246662, 404383],
      [-900, 73],
    ],
    [
      [245762, 404456],
      [-253, 771],
      [-419, 2924],
    ],
    [
      [245090, 408151],
      [-354, 1597],
    ],
    [
      [288982, 412041],
      [-534, -3560],
      [147, -1015],
      [-73, -1880],
      [204, -1804],
    ],
    [
      [288726, 403782],
      [-376, -1333],
      [-304, 88],
    ],
    [
      [288046, 402537],
      [-18, 1506],
    ],
    [
      [288028, 404043],
      [-61, 6417],
    ],
    [
      [288510, 412605],
      [38, 23],
    ],
    [
      [251086, 409192],
      [2, -5734],
      [-320, 52],
    ],
    [
      [250768, 403510],
      [-1088, 3],
      [-8, 3268],
    ],
    [
      [235550, 410925],
      [3, -4037],
    ],
    [
      [235553, 406888],
      [-1398, 56],
    ],
    [
      [234155, 406944],
      [134, 1679],
    ],
    [
      [192584, 405745],
      [-2935, 12],
      [-153, 2687],
      [-321, 1686],
    ],
    [
      [189175, 410130],
      [0, 2024],
    ],
    [
      [189175, 410130],
      [1, -4419],
      [-152, 2],
      [6, -8099],
    ],
    [
      [189030, 397614],
      [-1545, 14],
      [-447, 229],
    ],
    [
      [187038, 397857],
      [-3, 5012],
    ],
    [
      [268623, 407336],
      [-26, -1603],
    ],
    [
      [268597, 405733],
      [-717, 218],
      [40, 496],
      [-788, 178],
    ],
    [
      [267132, 406625],
      [40, 3337],
    ],
    [
      [159524, 404159],
      [-693, 578],
      [2, -532],
      [-1674, -52],
    ],
    [
      [157159, 404153],
      [9, 3692],
      [-428, 17],
    ],
    [
      [252629, 409145],
      [5, -3808],
    ],
    [
      [252634, 405337],
      [-318, 9],
      [-371, 2452],
      [-255, 17],
      [-5, 1358],
    ],
    [
      [262207, 410317],
      [-5, -3506],
    ],
    [
      [262202, 406811],
      [-729, -13],
    ],
    [
      [261473, 406798],
      [-10, 4878],
    ],
    [
      [261473, 406798],
      [1, -1345],
    ],
    [
      [261474, 405453],
      [-925, -9],
    ],
    [
      [260549, 405444],
      [-10, 4574],
    ],
    [
      [247532, 406699],
      [-778, 29],
    ],
    [
      [237901, 408397],
      [-2, -1616],
    ],
    [
      [237899, 406781],
      [-1252, 49],
    ],
    [
      [236647, 406830],
      [7, 4070],
    ],
    [
      [289991, 407601],
      [-61, -2065],
      [-434, 1889],
      [-494, -3193],
    ],
    [
      [289002, 404232],
      [-350, 2027],
    ],
    [
      [288652, 406259],
      [12, 7],
    ],
    [
      [288664, 406266],
      [-51, 2043],
    ],
    [
      [288613, 408309],
      [-8, 430],
    ],
    [
      [288605, 408739],
      [368, 2882],
    ],
    [
      [164781, 405356],
      [-1401, -15],
      [-152, 1283],
      [-273, 170],
      [-234, -1182],
      [-772, -1245],
    ],
    [
      [161949, 404367],
      [29, 4517],
    ],
    [
      [271808, 407942],
      [-352, -103],
      [16, -1874],
      [-379, -569],
    ],
    [
      [271093, 405396],
      [-587, 184],
      [23, 1689],
    ],
    [
      [236647, 406830],
      [-6, -1351],
    ],
    [
      [236641, 405479],
      [-1087, 27],
    ],
    [
      [235554, 405506],
      [-1, 1382],
    ],
    [
      [210182, 407556],
      [-20, 1],
    ],
    [
      [210162, 407557],
      [-2635, -8],
    ],
    [
      [207527, 407549],
      [-1082, 2],
    ],
    [
      [168262, 398404],
      [-491, -22],
      [-237, -2422],
      [1, -1714],
      [313, 16],
      [-13, -8145],
      [-697, 1],
    ],
    [
      [167138, 386118],
      [-482, 2257],
    ],
    [
      [166656, 388375],
      [-55, 3726],
      [-157, -50],
      [-42, 4120],
      [307, 671],
      [-2, 1728],
      [-666, 42],
    ],
    [
      [166041, 398612],
      [-32, 2039],
      [-419, 1034],
    ],
    [
      [165590, 401685],
      [652, 1617],
      [543, 5284],
    ],
    [
      [291787, 407390],
      [22, -3591],
      [-686, -1401],
    ],
    [
      [291123, 402398],
      [-572, 644],
    ],
    [
      [290551, 403042],
      [14, 1877],
      [-360, 1680],
    ],
    [
      [262819, 407580],
      [-2, -848],
    ],
    [
      [262817, 406732],
      [-615, 79],
    ],
    [
      [283284, 410419],
      [-281, -1862],
      [-298, -5500],
    ],
    [
      [282705, 403057],
      [-115, -74],
    ],
    [
      [282590, 402983],
      [-298, 3217],
    ],
    [
      [282292, 406200],
      [-167, 1078],
      [132, 1025],
      [-513, 247],
    ],
    [
      [281744, 408550],
      [-440, 1436],
      [-237, -1383],
      [-192, 274],
    ],
    [
      [280875, 408877],
      [-25, 1584],
    ],
    [
      [280875, 408877],
      [-278, -307],
      [-96, -1937],
    ],
    [
      [280501, 406633],
      [-526, 345],
    ],
    [
      [279975, 406978],
      [-386, 1376],
      [-475, -3170],
      [-283, 767],
    ],
    [
      [278831, 405951],
      [380, 4520],
    ],
    [
      [278831, 405951],
      [-568, -2831],
    ],
    [
      [278263, 403120],
      [-600, -2270],
    ],
    [
      [277663, 400850],
      [29, 9589],
    ],
    [
      [288028, 404043],
      [-186, -68],
    ],
    [
      [287842, 403975],
      [-578, 293],
      [198, 3147],
      [-296, -282],
    ],
    [
      [287166, 407133],
      [-438, 3311],
    ],
    [
      [276892, 410433],
      [-365, -5307],
    ],
    [
      [276527, 405126],
      [-123, 314],
    ],
    [
      [276404, 405440],
      [-598, 2940],
      [-675, 502],
    ],
    [
      [275131, 408882],
      [-71, 1559],
    ],
    [
      [277663, 400850],
      [0, -206],
    ],
    [
      [277663, 400644],
      [-557, 1424],
      [-342, -761],
    ],
    [
      [276764, 401307],
      [-239, 1285],
    ],
    [
      [276525, 402592],
      [2, 2534],
    ],
    [
      [287166, 407133],
      [-74, -2015],
      [-419, -1267],
      [83, 1880],
      [-379, -1384],
    ],
    [
      [286377, 404347],
      [-194, 1934],
      [-292, 888],
      [-100, 3272],
    ],
    [
      [275131, 408882],
      [-285, -1378],
      [11, -1745],
    ],
    [
      [274857, 405759],
      [-140, -755],
    ],
    [
      [274717, 405004],
      [-207, 396],
    ],
    [
      [274510, 405400],
      [-283, 562],
      [-368, 2261],
      [-256, 88],
    ],
    [
      [286377, 404347],
      [69, -1031],
      [-251, -2457],
      [-293, 640],
    ],
    [
      [285902, 401499],
      [0, 2434],
      [-506, 6],
      [2, -1756],
      [256, -748],
    ],
    [
      [285654, 401435],
      [-221, -442],
    ],
    [
      [285433, 400993],
      [-513, 2542],
    ],
    [
      [284920, 403535],
      [-27, 1753],
      [292, 5146],
    ],
    [
      [284920, 403535],
      [-797, 69],
    ],
    [
      [284123, 403604],
      [171, 2561],
      [-558, 2378],
      [253, 1873],
    ],
    [
      [284123, 403604],
      [-2, -8],
    ],
    [
      [284121, 403596],
      [-805, -2420],
    ],
    [
      [283316, 401176],
      [-301, 1526],
      [-310, 355],
    ],
    [
      [267132, 406625],
      [-296, -2585],
    ],
    [
      [266836, 404040],
      [-607, 26],
    ],
    [
      [266229, 404066],
      [40, 3087],
      [-261, 106],
    ],
    [
      [192583, 405610],
      [37, -825],
      [-294, -4891],
      [-129, -5401],
      [227, -2018],
      [156, -4770],
    ],
    [
      [192580, 387705],
      [-3568, 0],
      [0, 191],
    ],
    [
      [189012, 387896],
      [18, 9718],
    ],
    [
      [240872, 408377],
      [-43, -5375],
    ],
    [
      [240829, 403002],
      [-232, 408],
      [-163, -2184],
    ],
    [
      [240434, 401226],
      [-711, 2931],
    ],
    [
      [239723, 404157],
      [-347, 980],
      [-138, 3338],
    ],
    [
      [260549, 405444],
      [-153, -1912],
    ],
    [
      [260396, 403532],
      [-746, -50],
    ],
    [
      [259650, 403482],
      [0, 5417],
    ],
    [
      [281744, 408550],
      [-325, -479],
      [-251, -3773],
    ],
    [
      [281168, 404298],
      [-330, 1391],
    ],
    [
      [280838, 405689],
      [-337, 944],
    ],
    [
      [245090, 408151],
      [-786, -2744],
      [61, -2460],
    ],
    [
      [244365, 402947],
      [-779, 381],
    ],
    [
      [243586, 403328],
      [8, 5930],
    ],
    [
      [254055, 405974],
      [-144, -1904],
    ],
    [
      [253911, 404070],
      [-1278, -83],
    ],
    [
      [252633, 403987],
      [1, 1350],
    ],
    [
      [243586, 403328],
      [-1650, 162],
    ],
    [
      [241936, 403490],
      [26, 4793],
    ],
    [
      [270218, 407376],
      [-23, -1681],
      [-315, 124],
      [-26, -1652],
    ],
    [
      [269854, 404167],
      [-626, 216],
      [-9, -491],
      [-644, -24],
    ],
    [
      [268575, 403868],
      [22, 1865],
    ],
    [
      [227843, 407570],
      [-4, -4859],
    ],
    [
      [227839, 402711],
      [-1559, 8],
    ],
    [
      [226280, 402719],
      [-6, 4849],
    ],
    [
      [232870, 409170],
      [-17, -4355],
    ],
    [
      [232853, 404815],
      [-53, -3774],
    ],
    [
      [232800, 401041],
      [-1244, 5],
    ],
    [
      [231556, 401046],
      [0, 6507],
    ],
    [
      [234155, 406944],
      [373, -2132],
    ],
    [
      [234528, 404812],
      [-590, 6],
    ],
    [
      [233938, 404818],
      [-1085, -3],
    ],
    [
      [252633, 403987],
      [0, -2970],
    ],
    [
      [252633, 401017],
      [-933, 24],
    ],
    [
      [251700, 401041],
      [-932, 31],
    ],
    [
      [250768, 401072],
      [0, 2438],
    ],
    [
      [279975, 406978],
      [-539, -3798],
      [-360, -1739],
    ],
    [
      [279076, 401441],
      [-431, 1393],
      [-382, 286],
    ],
    [
      [273339, 407067],
      [-230, -1522],
    ],
    [
      [273109, 405545],
      [-695, -2161],
    ],
    [
      [272414, 403384],
      [-195, 1249],
      [-314, -1223],
      [-55, -1445],
      [-293, 114],
      [-131, -1044],
    ],
    [
      [271426, 401035],
      [10, 997],
      [-367, 905],
      [24, 2459],
    ],
    [
      [276404, 405440],
      [-240, -950],
      [-479, -156],
    ],
    [
      [275685, 404334],
      [-828, 1425],
    ],
    [
      [259650, 403482],
      [-372, -61],
    ],
    [
      [259278, 403421],
      [-462, -45],
    ],
    [
      [258816, 403376],
      [5, 5435],
    ],
    [
      [161949, 404367],
      [-299, -280],
      [-426, -2782],
      [0, -3647],
    ],
    [
      [161224, 397658],
      [-376, -706],
    ],
    [
      [160848, 396952],
      [-363, -449],
      [-90, -981],
      [-129, 6997],
    ],
    [
      [258816, 403376],
      [-362, -36],
    ],
    [
      [258454, 403340],
      [-695, 149],
    ],
    [
      [257759, 403489],
      [-4, 2280],
      [-152, -3],
    ],
    [
      [282292, 406200],
      [-394, -2354],
      [-185, -1907],
    ],
    [
      [281713, 401939],
      [-545, 2359],
    ],
    [
      [239723, 404157],
      [-203, 523],
      [-147, -1561],
      [-300, -406],
      [-194, -1441],
      [-195, 1193],
    ],
    [
      [238684, 402465],
      [-29, -1189],
      [-453, 360],
      [-301, -768],
    ],
    [
      [237901, 400868],
      [-2, 5913],
    ],
    [
      [241936, 403490],
      [-12, -1887],
    ],
    [
      [241924, 401603],
      [-322, 44],
    ],
    [
      [241602, 401647],
      [-773, 1355],
    ],
    [
      [256176, 408318],
      [-109, -1613],
      [-6, -4868],
    ],
    [
      [256061, 401837],
      [-1014, 8],
    ],
    [
      [255047, 401845],
      [202, 1649],
      [0, 2403],
    ],
    [
      [256898, 405827],
      [-7, -2443],
      [-310, 8],
      [-2, -3248],
    ],
    [
      [256579, 400144],
      [-520, 69],
    ],
    [
      [256059, 400213],
      [2, 1624],
    ],
    [
      [274510, 405400],
      [-752, -2906],
    ],
    [
      [273758, 402494],
      [-136, 1719],
      [-196, -664],
    ],
    [
      [273426, 403549],
      [-49, 2100],
      [-268, -104],
    ],
    [
      [246361, 401187],
      [-1284, 58],
      [-7, -1638],
      [-209, 11],
    ],
    [
      [244861, 399618],
      [-414, 50],
    ],
    [
      [244447, 399668],
      [-82, 3279],
    ],
    [
      [245762, 404456],
      [468, -1904],
      [131, -1365],
    ],
    [
      [264071, 407989],
      [93, -711],
      [-59, -4822],
    ],
    [
      [264105, 402456],
      [-1299, 240],
    ],
    [
      [262806, 402696],
      [11, 4036],
    ],
    [
      [265153, 407611],
      [-83, -5848],
    ],
    [
      [265070, 401763],
      [-704, 292],
    ],
    [
      [264366, 402055],
      [-261, 401],
    ],
    [
      [157159, 404153],
      [-103, -1293],
      [316, -1811],
      [516, -796],
      [-42, -2231],
      [424, -2417],
    ],
    [
      [158270, 395605],
      [-153, -1117],
    ],
    [
      [158117, 394488],
      [-193, -2961],
      [-454, -702],
    ],
    [
      [157470, 390825],
      [-532, 3403],
    ],
    [
      [214801, 407592],
      [7, -8099],
    ],
    [
      [214808, 399493],
      [2, -1604],
    ],
    [
      [214810, 397889],
      [-3111, -174],
    ],
    [
      [211699, 397715],
      [24, 9830],
    ],
    [
      [289029, 404122],
      [-16, 45],
    ],
    [
      [289013, 404167],
      [-11, 65],
    ],
    [
      [290551, 403042],
      [-150, -2724],
    ],
    [
      [290401, 400318],
      [-533, 654],
      [-508, 1437],
      [-331, 1713],
    ],
    [
      [223174, 407589],
      [-9, -8099],
    ],
    [
      [223165, 399490],
      [-1516, -10],
    ],
    [
      [221649, 399480],
      [-38, 0],
    ],
    [
      [221611, 399480],
      [9, 8096],
    ],
    [
      [265463, 401619],
      [-346, 126],
    ],
    [
      [265117, 401745],
      [-47, 18],
    ],
    [
      [266229, 404066],
      [-541, -2162],
      [-225, -285],
    ],
    [
      [216641, 407596],
      [-7, -8066],
    ],
    [
      [216634, 399530],
      [-241, -6],
    ],
    [
      [216393, 399524],
      [-1585, -31],
    ],
    [
      [218505, 407589],
      [-3, -8092],
    ],
    [
      [218502, 399497],
      [-253, 0],
    ],
    [
      [218249, 399497],
      [-1615, 33],
    ],
    [
      [224726, 407578],
      [-8, -6473],
    ],
    [
      [224718, 401105],
      [0, -1621],
    ],
    [
      [224718, 399484],
      [-1525, 8],
    ],
    [
      [223193, 399492],
      [-28, -2],
    ],
    [
      [220061, 407575],
      [-7, -8104],
    ],
    [
      [220054, 399471],
      [-1552, 26],
    ],
    [
      [228984, 407558],
      [-8, -6447],
    ],
    [
      [228976, 401111],
      [-4, -1634],
    ],
    [
      [228972, 399477],
      [-1137, -7],
    ],
    [
      [227835, 399470],
      [4, 3241],
    ],
    [
      [226280, 402719],
      [-2, -1625],
    ],
    [
      [226278, 401094],
      [-1560, 11],
    ],
    [
      [221611, 399480],
      [-1512, -5],
    ],
    [
      [220099, 399475],
      [-45, -4],
    ],
    [
      [230035, 407557],
      [-404, -3028],
      [468, -4114],
      [473, -185],
    ],
    [
      [230572, 400230],
      [-5, -2410],
      [-307, 8],
    ],
    [
      [230260, 397828],
      [0, 542],
      [-975, 290],
      [5, 2433],
      [-314, 18],
    ],
    [
      [207527, 407549],
      [-7, -8122],
    ],
    [
      [207520, 399427],
      [-1032, 5],
    ],
    [
      [206488, 399432],
      [-823, -3],
    ],
    [
      [210162, 407557],
      [-14, -13021],
      [-936, 36],
    ],
    [
      [209212, 394572],
      [12, 4830],
      [-1704, 25],
    ],
    [
      [231556, 401046],
      [-18, -1625],
    ],
    [
      [231538, 399421],
      [-118, 1200],
      [-442, 280],
      [-406, -671],
    ],
    [
      [211699, 397715],
      [-29, -7916],
    ],
    [
      [211670, 389799],
      [2, -1622],
      [-925, -165],
    ],
    [
      [210747, 388012],
      [-1530, 111],
    ],
    [
      [209217, 388123],
      [-5, 6449],
    ],
    [
      [205660, 399429],
      [4, -8051],
    ],
    [
      [205664, 391378],
      [-1785, -68],
    ],
    [
      [203879, 391310],
      [176, 2110],
      [-338, 2531],
      [-236, -28],
      [-218, 2136],
    ],
    [
      [203263, 398059],
      [20, 4732],
      [134, 1289],
    ],
    [
      [271426, 401035],
      [-87, -662],
    ],
    [
      [271339, 400373],
      [-1426, 435],
    ],
    [
      [269913, 400808],
      [-59, 3359],
    ],
    [
      [187038, 397857],
      [-586, -3353],
      [17, -2559],
      [-254, -958],
      [-384, 92],
      [-188, -2026],
    ],
    [
      [185643, 389053],
      [-4276, 11],
    ],
    [
      [181367, 389064],
      [0, 1944],
    ],
    [
      [280838, 405689],
      [20, -2091],
      [-220, -1799],
      [43, -1667],
      [-291, -1467],
    ],
    [
      [280390, 398665],
      [-1091, 2059],
      [-223, 717],
    ],
    [
      [235554, 405506],
      [-2, -5520],
    ],
    [
      [235552, 399986],
      [-482, 732],
    ],
    [
      [235070, 400718],
      [-348, 1876],
      [57, 1415],
      [-251, 803],
    ],
    [
      [262806, 402696],
      [-686, 37],
    ],
    [
      [262120, 402733],
      [-425, 22],
      [-218, -746],
    ],
    [
      [261477, 402009],
      [-3, 3444],
    ],
    [
      [237901, 400868],
      [-183, 147],
      [-782, -1329],
    ],
    [
      [236936, 399686],
      [-300, 1183],
    ],
    [
      [236636, 400869],
      [5, 4610],
    ],
    [
      [250768, 401072],
      [-307, -8],
      [0, -3525],
    ],
    [
      [250461, 397539],
      [-935, 2],
      [-151, -542],
    ],
    [
      [249375, 396999],
      [-164, -3],
    ],
    [
      [249211, 396996],
      [-7, 9762],
    ],
    [
      [249211, 396996],
      [-1245, 20],
    ],
    [
      [247966, 397016],
      [-6, 4876],
    ],
    [
      [247960, 401892],
      [-16, 4809],
    ],
    [
      [164769, 402898],
      [-1779, -16],
      [-231, -508],
      [-692, -3277],
      [-169, -1785],
      [-674, 346],
    ],
    [
      [247960, 401892],
      [-463, -687],
      [1, -945],
      [-743, 197],
      [-64, -1253],
    ],
    [
      [246691, 399204],
      [-29, 5179],
    ],
    [
      [268575, 403868],
      [-39, -2983],
    ],
    [
      [268536, 400885],
      [-64, -729],
    ],
    [
      [268472, 400156],
      [-1582, 537],
    ],
    [
      [266890, 400693],
      [-54, 3347],
    ],
    [
      [282590, 402983],
      [-306, -3506],
    ],
    [
      [282284, 399477],
      [-571, 2462],
    ],
    [
      [195295, 403841],
      [-25, -16136],
    ],
    [
      [195270, 387705],
      [-2690, 0],
    ],
    [
      [255047, 401845],
      [-69, -1900],
    ],
    [
      [254978, 399945],
      [-895, 326],
    ],
    [
      [254083, 400271],
      [-160, -18],
    ],
    [
      [253923, 400253],
      [-12, 3817],
    ],
    [
      [273426, 403549],
      [-646, -1538],
    ],
    [
      [272780, 402011],
      [-366, 1373],
    ],
    [
      [257759, 403489],
      [-152, -219],
      [7, -3170],
    ],
    [
      [257614, 400100],
      [-1035, 44],
    ],
    [
      [275685, 404334],
      [85, -2807],
    ],
    [
      [275770, 401527],
      [-169, -2411],
    ],
    [
      [275601, 399116],
      [-203, -165],
    ],
    [
      [275398, 398951],
      [-825, 1176],
    ],
    [
      [274573, 400127],
      [195, 794],
      [-216, 1514],
      [165, 2569],
    ],
    [
      [281713, 401939],
      [-331, -4249],
    ],
    [
      [281382, 397690],
      [-453, -530],
    ],
    [
      [280929, 397160],
      [-54, 1697],
      [-336, -1326],
      [-249, 538],
    ],
    [
      [280290, 398069],
      [100, 596],
    ],
    [
      [236636, 400869],
      [-257, 453],
      [-437, -2067],
      [-407, -128],
    ],
    [
      [235535, 399127],
      [17, 859],
    ],
    [
      [261477, 402009],
      [-400, -1353],
    ],
    [
      [261077, 400656],
      [-346, -1150],
      [-342, -59],
    ],
    [
      [260389, 399447],
      [7, 4085],
    ],
    [
      [276525, 402592],
      [-354, -957],
      [-401, -108],
    ],
    [
      [274573, 400127],
      [-368, -1330],
    ],
    [
      [274205, 398797],
      [-236, 255],
    ],
    [
      [273969, 399052],
      [-281, 3046],
      [70, 396],
    ],
    [
      [233938, 404818],
      [-18, -6982],
    ],
    [
      [233920, 397836],
      [-872, 234],
    ],
    [
      [233048, 398070],
      [-272, 169],
      [24, 2802],
    ],
    [
      [235070, 400718],
      [-349, 74],
      [-25, -3937],
    ],
    [
      [234696, 396855],
      [-411, -173],
    ],
    [
      [234285, 396682],
      [-361, -327],
      [-4, 1481],
    ],
    [
      [159472, 402696],
      [-103, -2315],
      [298, -2192],
      [9, -2579],
    ],
    [
      [159676, 395610],
      [-1406, -5],
    ],
    [
      [240434, 401226],
      [-265, -1911],
      [27, -1099],
    ],
    [
      [240196, 398216],
      [-322, -2537],
    ],
    [
      [239874, 395679],
      [-1243, 272],
    ],
    [
      [238631, 395951],
      [53, 6514],
    ],
    [
      [272780, 402011],
      [-162, -1543],
    ],
    [
      [272618, 400468],
      [-357, -942],
      [-433, -2022],
    ],
    [
      [271828, 397504],
      [-465, 1315],
    ],
    [
      [271363, 398819],
      [-24, 1554],
    ],
    [
      [246691, 399204],
      [104, -2426],
      [318, -370],
    ],
    [
      [247113, 396408],
      [-268, -1767],
      [-327, 1147],
    ],
    [
      [246518, 395788],
      [-157, 5399],
    ],
    [
      [269913, 400808],
      [-106, 32],
      [-45, -3310],
    ],
    [
      [269762, 397530],
      [-314, 137],
    ],
    [
      [269448, 397667],
      [30, 1913],
      [-311, 800],
      [-631, 505],
    ],
    [
      [273969, 399052],
      [-615, -1858],
    ],
    [
      [273354, 397194],
      [-359, 392],
    ],
    [
      [272995, 397586],
      [-242, 74],
      [-135, 2808],
    ],
    [
      [288046, 402537],
      [11, -942],
    ],
    [
      [288057, 401595],
      [-639, -5],
    ],
    [
      [287418, 401590],
      [-372, -2533],
      [-163, 577],
      [-139, -1617],
      [-138, 1747],
      [461, 4160],
      [775, 51],
    ],
    [
      [203263, 398059],
      [-1079, 33],
    ],
    [
      [202184, 398092],
      [232, 2578],
      [24, 2143],
      [167, 939],
    ],
    [
      [253923, 400253],
      [-983, -53],
    ],
    [
      [252940, 400200],
      [-307, 817],
    ],
    [
      [266890, 400693],
      [-89, -2651],
    ],
    [
      [266801, 398042],
      [-801, -648],
    ],
    [
      [266000, 397394],
      [-555, 16],
      [18, 4209],
    ],
    [
      [285902, 401499],
      [-150, -622],
    ],
    [
      [285752, 400877],
      [-98, 558],
    ],
    [
      [285433, 400993],
      [-397, -2058],
    ],
    [
      [285036, 398935],
      [-134, 518],
    ],
    [
      [284902, 399453],
      [-167, -26],
      [-614, 4169],
    ],
    [
      [199808, 403832],
      [101, -2046],
    ],
    [
      [199909, 401786],
      [-296, -714],
    ],
    [
      [199613, 401072],
      [-737, -3251],
      [-270, 662],
      [-334, -354],
      [-440, -2814],
      [-665, -1480],
      [1, -2999],
    ],
    [
      [197168, 390836],
      [-2, -3131],
      [-1896, 0],
    ],
    [
      [202184, 398092],
      [-58, -1116],
    ],
    [
      [202126, 396976],
      [-415, 809],
      [-150, -1174],
      [-541, 1039],
      [-198, 1577],
      [-600, -2],
      [-313, 2561],
    ],
    [
      [289289, 396094],
      [-480, -882],
      [-190, -1251],
      [-469, -135],
    ],
    [
      [288150, 393826],
      [-70, 5854],
    ],
    [
      [288080, 399680],
      [-23, 1915],
    ],
    [
      [288726, 403782],
      [311, -1995],
      [21, -3695],
      [231, -1998],
    ],
    [
      [258454, 403340],
      [32, -5425],
      [144, 8],
    ],
    [
      [258630, 397923],
      [2, -1078],
    ],
    [
      [258632, 396845],
      [-1016, 28],
    ],
    [
      [257616, 396873],
      [-2, 3227],
    ],
    [
      [260389, 399447],
      [-315, -33],
      [3, -1128],
    ],
    [
      [260077, 398286],
      [-173, -511],
      [-611, 176],
    ],
    [
      [259293, 397951],
      [-15, 5470],
    ],
    [
      [244447, 399668],
      [-615, 99],
      [-12, -1654],
    ],
    [
      [243820, 398113],
      [-1328, 97],
    ],
    [
      [242492, 398210],
      [16, 3269],
      [-584, 124],
    ],
    [
      [284902, 399453],
      [-317, -3074],
    ],
    [
      [284585, 396379],
      [-327, -588],
    ],
    [
      [284258, 395791],
      [-575, 2284],
    ],
    [
      [283683, 398075],
      [-376, 337],
      [-185, 1327],
      [194, 1437],
    ],
    [
      [259293, 397951],
      [-663, -28],
    ],
    [
      [241246, 396473],
      [-791, 166],
      [-259, 1577],
    ],
    [
      [241602, 401647],
      [-356, -5174],
    ],
    [
      [279076, 401441],
      [-306, -3713],
      [122, -2044],
      [-246, -2142],
    ],
    [
      [278646, 393542],
      [-601, 2684],
    ],
    [
      [278045, 396226],
      [-22, 130],
    ],
    [
      [278023, 396356],
      [164, 2029],
      [-157, 242],
      [174, 1961],
      [-541, 56],
    ],
    [
      [291123, 402398],
      [69, -689],
      [-433, -4685],
      [-217, -1125],
      [-299, -2],
      [197, 2821],
      [-39, 1600],
    ],
    [
      [283683, 398075],
      [-584, -3904],
    ],
    [
      [283099, 394171],
      [-333, 1776],
    ],
    [
      [282766, 395947],
      [-855, 1322],
    ],
    [
      [281911, 397269],
      [373, 2208],
    ],
    [
      [164778, 400098],
      [2, -985],
    ],
    [
      [164780, 399113],
      [3, -842],
    ],
    [
      [164783, 398271],
      [-389, 10],
      [-272, -829],
      [-495, 64],
      [-430, -2100],
      [-547, 1772],
      [-795, -2034],
      [-254, -3502],
    ],
    [
      [161601, 391652],
      [-950, 430],
    ],
    [
      [160651, 392082],
      [48, 3551],
      [149, 1319],
    ],
    [
      [264366, 402055],
      [-167, -4639],
    ],
    [
      [264199, 397416],
      [-520, 1367],
    ],
    [
      [263679, 398783],
      [-327, -384],
    ],
    [
      [263352, 398399],
      [-336, 1361],
      [-212, -782],
    ],
    [
      [262804, 398978],
      [2, 3718],
    ],
    [
      [262120, 402733],
      [-187, -6686],
    ],
    [
      [261933, 396047],
      [-9, -343],
    ],
    [
      [261924, 395704],
      [-187, -294],
    ],
    [
      [261737, 395410],
      [-674, -15],
    ],
    [
      [261063, 395395],
      [14, 5261],
    ],
    [
      [262804, 398978],
      [-161, -1387],
    ],
    [
      [262643, 397591],
      [-710, -1544],
    ],
    [
      [227835, 399470],
      [-1, -3241],
    ],
    [
      [227834, 396229],
      [-1551, 10],
    ],
    [
      [226283, 396239],
      [-5, 4855],
    ],
    [
      [160651, 392082],
      [-328, 7],
    ],
    [
      [160323, 392089],
      [-249, 595],
      [-92, 1588],
      [-306, 1338],
    ],
    [
      [276764, 401307],
      [-43, -2135],
    ],
    [
      [276721, 399172],
      [-197, -2650],
      [-527, -489],
    ],
    [
      [275997, 396033],
      [78, 1671],
      [-474, 1412],
    ],
    [
      [238631, 395951],
      [-2, -269],
    ],
    [
      [238629, 395682],
      [-940, 177],
      [-5, -542],
      [-774, 158],
    ],
    [
      [236910, 395475],
      [26, 4211],
    ],
    [
      [278023, 396356],
      [-421, 199],
      [-771, 1158],
      [-110, 1459],
    ],
    [
      [264942, 392757],
      [-495, 1045],
    ],
    [
      [264447, 393802],
      [-4, 881],
    ],
    [
      [264443, 394683],
      [-244, 2733],
    ],
    [
      [265117, 401745],
      [-175, -8988],
    ],
    [
      [281911, 397269],
      [-117, -636],
    ],
    [
      [281794, 396633],
      [-412, 1057],
    ],
    [
      [247966, 397016],
      [-356, -13],
      [-7, -1413],
    ],
    [
      [247603, 395590],
      [-490, 818],
    ],
    [
      [286601, 396690],
      [-270, -2438],
      [180, 3428],
      [90, -990],
    ],
    [
      [288080, 399680],
      [-243, -645],
      [-318, -3541],
    ],
    [
      [287519, 395494],
      [-452, 119],
    ],
    [
      [287067, 395613],
      [-237, -813],
      [-18, 1649],
      [166, 2200],
      [440, 2941],
    ],
    [
      [256059, 400213],
      [0, -4914],
    ],
    [
      [256059, 395299],
      [-785, -99],
    ],
    [
      [255274, 395200],
      [-296, 4745],
    ],
    [
      [202126, 396976],
      [391, -1636],
      [384, -265],
      [-241, -1239],
      [-125, -2430],
      [202, -1122],
      [70, -1956],
      [300, -2059],
    ],
    [
      [203107, 386269],
      [-2100, 52],
      [-1, -5185],
    ],
    [
      [201006, 381136],
      [-1582, 2],
    ],
    [
      [199424, 381138],
      [-184, 2879],
    ],
    [
      [199240, 384017],
      [376, 1],
      [-3, 6826],
    ],
    [
      [199613, 390844],
      [0, 10228],
    ],
    [
      [266000, 397394],
      [-90, -7128],
    ],
    [
      [265910, 390266],
      [-554, 2430],
    ],
    [
      [265356, 392696],
      [-414, 61],
    ],
    [
      [242492, 398210],
      [-119, -2235],
      [32, -2529],
      [-220, -3074],
    ],
    [
      [242185, 390372],
      [-394, 741],
      [-91, 1045],
    ],
    [
      [241700, 392158],
      [-289, 3405],
    ],
    [
      [241411, 395563],
      [-165, 910],
    ],
    [
      [166041, 398612],
      [-586, 525],
      [-675, -24],
    ],
    [
      [280290, 398069],
      [-483, -2836],
      [-192, -318],
      [-231, -2313],
    ],
    [
      [279384, 392602],
      [-347, 1620],
      [-177, -1641],
    ],
    [
      [278860, 392581],
      [-214, 961],
    ],
    [
      [285904, 391677],
      [-438, 651],
    ],
    [
      [285466, 392328],
      [40, 2580],
      [-96, 1946],
      [-374, 2081],
    ],
    [
      [285752, 400877],
      [17, -34],
    ],
    [
      [285769, 400843],
      [123, 37],
    ],
    [
      [285892, 400880],
      [302, -2195],
      [-274, -289],
      [359, -1162],
      [-397, -2847],
      [22, -2710],
    ],
    [
      [236910, 395475],
      [-14, -1566],
    ],
    [
      [236896, 393909],
      [-1362, 260],
    ],
    [
      [235534, 394169],
      [1, 3667],
    ],
    [
      [235535, 397836],
      [0, 1291],
    ],
    [
      [246518, 395788],
      [-459, -1047],
      [-357, -130],
    ],
    [
      [245702, 394611],
      [-426, 36],
      [-426, 2233],
    ],
    [
      [244850, 396880],
      [11, 2738],
    ],
    [
      [230260, 397828],
      [-3, -3239],
    ],
    [
      [230257, 394589],
      [-1081, 7],
    ],
    [
      [229176, 394596],
      [-204, 1767],
      [0, 3114],
    ],
    [
      [199613, 390844],
      [-2445, -8],
    ],
    [
      [226283, 396239],
      [-1, -1625],
    ],
    [
      [226282, 394614],
      [-1549, -5],
    ],
    [
      [224733, 394609],
      [-15, 4875],
    ],
    [
      [233048, 398070],
      [-2, -3479],
    ],
    [
      [233046, 394591],
      [-1241, 3],
    ],
    [
      [231805, 394594],
      [-1, 4258],
      [-266, 569],
    ],
    [
      [251700, 401041],
      [-3, -5671],
      [315, 56],
    ],
    [
      [252012, 395426],
      [-4, -1645],
    ],
    [
      [252008, 393781],
      [-1234, -55],
      [-1, -1621],
    ],
    [
      [250773, 392105],
      [-323, 107],
    ],
    [
      [250450, 392212],
      [11, 5327],
    ],
    [
      [252940, 400200],
      [-3, -4846],
    ],
    [
      [252937, 395354],
      [-925, 72],
    ],
    [
      [231805, 394594],
      [2, -2436],
    ],
    [
      [231807, 392158],
      [-1134, -2],
    ],
    [
      [230673, 392156],
      [-105, 1611],
      [-311, 822],
    ],
    [
      [269448, 397667],
      [-55, -3546],
      [-336, -2],
    ],
    [
      [269057, 394119],
      [-207, 85],
    ],
    [
      [268850, 394204],
      [-308, 109],
      [-130, 1734],
    ],
    [
      [268412, 396047],
      [60, 4109],
    ],
    [
      [270916, 394754],
      [20, 1086],
      [-281, 1702],
      [-269, -1234],
    ],
    [
      [270386, 396308],
      [9, 758],
      [-633, 464],
    ],
    [
      [271363, 398819],
      [-98, -3218],
      [-349, -847],
    ],
    [
      [235535, 397836],
      [-738, 278],
      [-101, -1259],
    ],
    [
      [268412, 396047],
      [-1128, 225],
      [-164, 1037],
    ],
    [
      [267120, 397309],
      [-319, 733],
    ],
    [
      [261063, 395395],
      [-665, -1817],
    ],
    [
      [260398, 393578],
      [-311, -147],
    ],
    [
      [260087, 393431],
      [-10, 4855],
    ],
    [
      [272995, 397586],
      [-322, -2153],
    ],
    [
      [272673, 395433],
      [-626, 51],
    ],
    [
      [272047, 395484],
      [-219, 2020],
    ],
    [
      [255274, 395200],
      [-23, -931],
    ],
    [
      [255251, 394269],
      [-1050, -45],
    ],
    [
      [254201, 394224],
      [-106, 0],
    ],
    [
      [254095, 394224],
      [-12, 6047],
    ],
    [
      [254095, 394224],
      [-871, -49],
    ],
    [
      [253224, 394175],
      [-287, 84],
      [0, 1095],
    ],
    [
      [257616, 396873],
      [-2, -1632],
    ],
    [
      [257614, 395241],
      [-617, -10],
    ],
    [
      [256997, 395231],
      [-542, -4],
    ],
    [
      [256455, 395227],
      [-396, 72],
    ],
    [
      [275398, 398951],
      [-49, -2705],
      [-193, -1560],
      [-17, -2747],
    ],
    [
      [275139, 391939],
      [-179, 223],
    ],
    [
      [274960, 392162],
      [-177, 2249],
      [-237, 821],
    ],
    [
      [274546, 395232],
      [-309, 1940],
      [-32, 1625],
    ],
    [
      [178718, 391008],
      [0, -11659],
      [-2488, -10],
      [-9, -22498],
    ],
    [
      [176221, 356841],
      [9, -15660],
    ],
    [
      [176230, 341181],
      [-3544, 18062],
    ],
    [
      [172686, 359243],
      [1, 19187],
      [-1464, 8789],
    ],
    [
      [171223, 387219],
      [-1407, 8282],
      [-2, 1512],
      [924, 1373],
    ],
    [
      [244850, 396880],
      [-2, -2773],
      [-421, 28],
      [-7, -2523],
    ],
    [
      [244420, 391612],
      [-356, -627],
      [-262, 515],
    ],
    [
      [243802, 391500],
      [-20, -7],
    ],
    [
      [243782, 391493],
      [38, 6620],
    ],
    [
      [263352, 398399],
      [22, -5065],
    ],
    [
      [263374, 393334],
      [-124, -465],
    ],
    [
      [263250, 392869],
      [-377, 1491],
    ],
    [
      [262873, 394360],
      [-210, 810],
    ],
    [
      [262663, 395170],
      [-20, 2421],
    ],
    [
      [288150, 393826],
      [43, -3599],
    ],
    [
      [288193, 390227],
      [-151, 927],
      [-491, -152],
    ],
    [
      [287551, 391002],
      [-166, 1427],
      [180, 785],
    ],
    [
      [287565, 393214],
      [-46, 2280],
    ],
    [
      [218249, 399497],
      [-18, -8071],
    ],
    [
      [218231, 391426],
      [-863, 14],
    ],
    [
      [217368, 391440],
      [-992, -7],
    ],
    [
      [216376, 391433],
      [17, 8091],
    ],
    [
      [216376, 391433],
      [-231, -10],
    ],
    [
      [216145, 391423],
      [-1332, -39],
    ],
    [
      [214813, 391384],
      [-3, 6505],
    ],
    [
      [223193, 399492],
      [-14, -8122],
    ],
    [
      [223179, 391370],
      [-1512, -4],
    ],
    [
      [221667, 391366],
      [-36, -1],
    ],
    [
      [221631, 391365],
      [18, 8115],
    ],
    [
      [220099, 399475],
      [-16, -8095],
    ],
    [
      [220083, 391380],
      [-260, 15],
    ],
    [
      [219823, 391395],
      [-1228, 34],
    ],
    [
      [218595, 391429],
      [-364, -3],
    ],
    [
      [229176, 394596],
      [-104, -1079],
      [-8, -3775],
    ],
    [
      [229064, 389742],
      [-1230, 0],
    ],
    [
      [227834, 389742],
      [0, 6487],
    ],
    [
      [224733, 394609],
      [-4, -3237],
    ],
    [
      [224729, 391372],
      [-1523, -3],
    ],
    [
      [223206, 391369],
      [-27, 1],
    ],
    [
      [221631, 391365],
      [-1548, 15],
    ],
    [
      [285466, 392328],
      [-31, -1556],
    ],
    [
      [285435, 390772],
      [-7, -2276],
    ],
    [
      [285428, 388496],
      [-134, 1401],
      [-606, 809],
      [-230, -837],
      [-81, 1376],
    ],
    [
      [284377, 391245],
      [169, 2219],
    ],
    [
      [284546, 393464],
      [298, 1555],
      [-259, 1360],
    ],
    [
      [206488, 399432],
      [13, -4858],
      [-121, -1295],
      [371, -47],
      [-10, -2736],
    ],
    [
      [206741, 390496],
      [-830, -42],
      [-247, 924],
    ],
    [
      [209217, 388123],
      [-2473, -52],
    ],
    [
      [206744, 388071],
      [-3, 2425],
    ],
    [
      [264443, 394683],
      [-524, -1280],
    ],
    [
      [263919, 393403],
      [-110, 901],
      [24, 2588],
      [-154, 1891],
    ],
    [
      [278045, 396226],
      [-294, -515],
      [-232, -1658],
      [80, -1120],
      [-325, -2167],
    ],
    [
      [277274, 390766],
      [-416, 1407],
      [-243, -3513],
      [-463, -1711],
      [-244, 275],
      [-358, -1593],
    ],
    [
      [275550, 385631],
      [171, 2537],
      [-267, 3168],
    ],
    [
      [275454, 391336],
      [428, 949],
      [115, 3748],
    ],
    [
      [166656, 388375],
      [-699, 3239],
    ],
    [
      [165957, 391614],
      [-901, 4159],
    ],
    [
      [165056, 395773],
      [-269, 1240],
      [-4, 1258],
    ],
    [
      [275454, 391336],
      [-315, 603],
    ],
    [
      [274546, 395232],
      [-268, -471],
      [-788, -2996],
    ],
    [
      [273490, 391765],
      [-200, 1393],
      [150, 994],
      [-86, 3042],
    ],
    [
      [280929, 397160],
      [39, -1197],
      [-240, -2110],
    ],
    [
      [280728, 393853],
      [-452, -1704],
      [-259, -2493],
    ],
    [
      [280017, 389656],
      [-633, 2946],
    ],
    [
      [263919, 393403],
      [-308, -257],
    ],
    [
      [263611, 393146],
      [-237, 188],
    ],
    [
      [272047, 395484],
      [-122, -4567],
      [68, -1111],
    ],
    [
      [271993, 389806],
      [-308, -1084],
      [-178, 1340],
    ],
    [
      [271507, 390062],
      [-213, 1013],
    ],
    [
      [271294, 391075],
      [-378, 3679],
    ],
    [
      [171223, 387219],
      [-1840, -10801],
      [-214, 48],
    ],
    [
      [169169, 376466],
      [-2031, 9652],
    ],
    [
      [260087, 393431],
      [-260, -1361],
    ],
    [
      [259827, 392070],
      [-266, 627],
      [-811, -80],
    ],
    [
      [258750, 392617],
      [-16, 4231],
      [-102, -3],
    ],
    [
      [234285, 396682],
      [0, -4534],
    ],
    [
      [234285, 392148],
      [-1239, 4],
    ],
    [
      [233046, 392152],
      [0, 2439],
    ],
    [
      [165056, 395773],
      [67, -1276],
      [-536, -3016],
    ],
    [
      [164587, 391481],
      [-681, -2964],
      [-867, -752],
      [-370, 962],
      [-735, -860],
    ],
    [
      [161934, 387867],
      [-256, 3852],
      [-77, -67],
    ],
    [
      [243782, 391493],
      [-600, -672],
      [-237, -1354],
    ],
    [
      [242945, 389467],
      [-301, -676],
      [-459, 1581],
    ],
    [
      [241411, 395563],
      [-330, -4505],
      [-622, 73],
    ],
    [
      [240459, 391131],
      [-612, 170],
    ],
    [
      [239847, 391301],
      [27, 4378],
    ],
    [
      [284258, 395791],
      [-146, -762],
    ],
    [
      [284112, 395029],
      [62, -330],
    ],
    [
      [284174, 394699],
      [109, -599],
    ],
    [
      [284283, 394100],
      [166, -999],
    ],
    [
      [284449, 393101],
      [22, -1315],
      [-319, -1468],
      [-235, 541],
    ],
    [
      [283917, 390859],
      [-818, 3312],
    ],
    [
      [203879, 391310],
      [232, -1351],
      [-63, -2148],
      [-283, -1101],
    ],
    [
      [203765, 386710],
      [-658, -441],
    ],
    [
      [235534, 394169],
      [-3, -2030],
    ],
    [
      [235531, 392139],
      [-1246, 9],
    ],
    [
      [267120, 397309],
      [10, -7398],
    ],
    [
      [267130, 389911],
      [-689, 1520],
      [-363, -1183],
    ],
    [
      [266078, 390248],
      [-168, 18],
    ],
    [
      [189012, 387896],
      [-1284, -154],
    ],
    [
      [187728, 387742],
      [-2094, 148],
    ],
    [
      [185634, 387890],
      [9, 1163],
    ],
    [
      [214813, 391384],
      [1, -1534],
    ],
    [
      [214814, 389850],
      [-3144, -51],
    ],
    [
      [273490, 391765],
      [-131, -932],
    ],
    [
      [273359, 390833],
      [-145, -1041],
    ],
    [
      [273214, 389792],
      [-209, 606],
      [-29, 1581],
      [-303, 3454],
    ],
    [
      [281794, 396633],
      [-352, -2134],
    ],
    [
      [281442, 394499],
      [-181, -1569],
      [-249, -398],
    ],
    [
      [281012, 392532],
      [-284, 1321],
    ],
    [
      [270386, 396308],
      [-126, -2312],
      [-210, -922],
      [124, -3421],
      [-112, -240],
    ],
    [
      [270062, 389413],
      [-201, -166],
    ],
    [
      [269861, 389247],
      [-208, 45],
      [20, 1691],
      [-328, 121],
      [-31, 1670],
      [-276, 128],
      [19, 1217],
    ],
    [
      [262663, 395170],
      [-751, 43],
      [12, 491],
    ],
    [
      [271294, 391075],
      [-509, -438],
      [-303, -919],
      [26, -2485],
    ],
    [
      [270508, 387233],
      [-446, 2180],
    ],
    [
      [250450, 392212],
      [-956, 23],
    ],
    [
      [249494, 392235],
      [-119, 4764],
    ],
    [
      [268850, 394204],
      [-252, -4689],
      [-210, -491],
    ],
    [
      [268388, 389024],
      [-201, 3414],
      [-398, -533],
    ],
    [
      [267789, 391905],
      [-350, -1991],
      [-309, -3],
    ],
    [
      [282766, 395947],
      [-168, -2056],
      [513, -5136],
    ],
    [
      [283111, 388755],
      [-288, -2729],
    ],
    [
      [282823, 386026],
      [-280, 58],
      [-558, 5269],
    ],
    [
      [281985, 391353],
      [-543, 3146],
    ],
    [
      [249494, 392235],
      [6, -1626],
      [-311, -14],
    ],
    [
      [249189, 390595],
      [-1322, 101],
    ],
    [
      [247867, 390696],
      [42, 2085],
    ],
    [
      [247909, 392781],
      [135, 617],
    ],
    [
      [248044, 393398],
      [6, 862],
      [-447, 1330],
    ],
    [
      [245702, 394611],
      [-17, -6019],
    ],
    [
      [245685, 388592],
      [-299, 1157],
      [-435, 348],
      [-393, 1319],
    ],
    [
      [244558, 391416],
      [-138, 196],
    ],
    [
      [284546, 393464],
      [-223, 1829],
    ],
    [
      [284323, 395293],
      [-65, 498],
    ],
    [
      [258750, 392617],
      [-93, -1410],
    ],
    [
      [258657, 391207],
      [-1043, -28],
    ],
    [
      [257614, 391179],
      [0, 4062],
    ],
    [
      [248044, 393398],
      [-494, 1528],
      [-406, -1104],
      [-259, -2536],
      [-553, -983],
    ],
    [
      [246332, 390303],
      [-515, -1949],
      [-132, 238],
    ],
    [
      [289289, 396094],
      [351, -2735],
      [270, -53],
      [-16, -3322],
      [132, -3186],
    ],
    [
      [290026, 386798],
      [-57, 0],
    ],
    [
      [289969, 386798],
      [-44, 0],
    ],
    [
      [289925, 386798],
      [-712, 13],
    ],
    [
      [289213, 386811],
      [-982, 153],
      [-22, 1873],
    ],
    [
      [288209, 388837],
      [-16, 1390],
    ],
    [
      [227834, 389742],
      [-1541, 10],
    ],
    [
      [226293, 389752],
      [-11, 4862],
    ],
    [
      [278860, 392581],
      [-466, -5287],
    ],
    [
      [278394, 387294],
      [-254, -1223],
    ],
    [
      [278140, 386071],
      [-449, 839],
      [-166, 1723],
      [-310, 768],
    ],
    [
      [277215, 389401],
      [59, 1365],
    ],
    [
      [239847, 391301],
      [-20, -3034],
    ],
    [
      [239827, 388267],
      [-622, 98],
      [-4, -541],
      [-615, 114],
    ],
    [
      [238586, 387938],
      [4, 815],
    ],
    [
      [238590, 388753],
      [39, 6929],
    ],
    [
      [287565, 393214],
      [-230, -1318],
      [167, -933],
      [-195, -1781],
      [-402, 1979],
      [-480, 1174],
      [215, 1900],
      [256, -1835],
      [-68, 1744],
      [239, 1469],
    ],
    [
      [283917, 390859],
      [-162, -3064],
    ],
    [
      [283755, 387795],
      [-414, 1424],
      [-230, -464],
    ],
    [
      [273214, 389792],
      [-307, -1569],
    ],
    [
      [272907, 388223],
      [-755, 413],
      [-159, 1170],
    ],
    [
      [238590, 388753],
      [-1541, 208],
    ],
    [
      [237049, 388961],
      [-153, 25],
      [0, 4923],
    ],
    [
      [284458, 394060],
      [-175, 40],
    ],
    [
      [284174, 394699],
      [-62, 330],
    ],
    [
      [284323, 395293],
      [135, -1233],
    ],
    [
      [165957, 391614],
      [-111, -1812],
      [217, -2118],
      [-268, -3202],
    ],
    [
      [165795, 384482],
      [-318, 1675],
      [-351, -1063],
      [-392, 1451],
    ],
    [
      [164734, 386545],
      [-147, 1347],
    ],
    [
      [164587, 387892],
      [0, 3589],
    ],
    [
      [262873, 394360],
      [-58, -1367],
      [-578, -418],
    ],
    [
      [262237, 392575],
      [-496, -1308],
    ],
    [
      [261741, 391267],
      [-4, 4143],
    ],
    [
      [241700, 392158],
      [-280, -5796],
    ],
    [
      [241420, 386362],
      [-362, 56],
    ],
    [
      [241058, 386418],
      [-603, 3090],
      [4, 1623],
    ],
    [
      [160323, 392089],
      [-77, -1062],
      [333, -1452],
      [-117, -1633],
      [135, -802],
      [-46, -1999],
      [-216, -908],
    ],
    [
      [160335, 384233],
      [-267, 44],
      [0, 3908],
      [-681, 143],
      [-458, -370],
    ],
    [
      [158929, 387958],
      [0, -4],
    ],
    [
      [158929, 387958],
      [-514, 6077],
      [-298, 453],
    ],
    [
      [261741, 391267],
      [-366, 837],
    ],
    [
      [261375, 392104],
      [-331, -489],
      [66, -2303],
    ],
    [
      [261110, 389312],
      [-395, 371],
    ],
    [
      [260715, 389683],
      [-3, 1350],
      [-313, 1080],
      [-1, 1465],
    ],
    [
      [253224, 394175],
      [-108, -3960],
      [133, -658],
    ],
    [
      [253249, 389557],
      [-1250, 128],
    ],
    [
      [251999, 389685],
      [9, 4096],
    ],
    [
      [256455, 395227],
      [-410, -1812],
      [-93, -2129],
      [104, -2737],
    ],
    [
      [256056, 388549],
      [-615, -232],
    ],
    [
      [255441, 388317],
      [-297, -785],
    ],
    [
      [255144, 387532],
      [-486, -1420],
    ],
    [
      [254658, 386112],
      [-34, 905],
      [308, 954],
      [-17, 1005],
    ],
    [
      [254915, 388976],
      [435, 3214],
      [-99, 2079],
    ],
    [
      [257614, 391179],
      [2, -2983],
    ],
    [
      [257616, 388196],
      [-674, -391],
    ],
    [
      [256942, 387805],
      [55, 7426],
    ],
    [
      [256942, 387805],
      [-413, 171],
    ],
    [
      [256529, 387976],
      [-473, 573],
    ],
    [
      [274960, 392162],
      [-143, -1744],
      [-396, -2216],
    ],
    [
      [274421, 388202],
      [-376, 577],
      [-266, -940],
    ],
    [
      [273779, 387839],
      [-420, 2994],
    ],
    [
      [247909, 392781],
      [-238, -998],
      [-192, -2324],
      [175, -1160],
    ],
    [
      [247654, 388299],
      [-24, -168],
    ],
    [
      [247630, 388131],
      [-211, -2528],
    ],
    [
      [247419, 385603],
      [-176, 2124],
      [-512, 9],
      [-410, -650],
    ],
    [
      [246321, 387086],
      [11, 3217],
    ],
    [
      [264517, 389239],
      [-698, -957],
    ],
    [
      [263819, 388282],
      [-57, 221],
    ],
    [
      [263762, 388503],
      [-151, 4643],
    ],
    [
      [264447, 393802],
      [70, -4563],
    ],
    [
      [230673, 392156],
      [-3, -4046],
    ],
    [
      [230670, 388110],
      [-1298, 15],
    ],
    [
      [229372, 388125],
      [-307, -1],
      [-1, 1618],
    ],
    [
      [226293, 389752],
      [2, -1622],
    ],
    [
      [226295, 388130],
      [-1550, -17],
    ],
    [
      [224745, 388113],
      [-16, 3259],
    ],
    [
      [233046, 392152],
      [-20, -5676],
    ],
    [
      [233026, 386476],
      [-1232, 4],
    ],
    [
      [231794, 386480],
      [13, 5678],
    ],
    [
      [158929, 387954],
      [-62, -1644],
      [169, -2032],
      [-399, 3],
      [-19, -2538],
      [-216, -434],
    ],
    [
      [158402, 381309],
      [-24, 5],
    ],
    [
      [158378, 381314],
      [-291, -21],
    ],
    [
      [158087, 381293],
      [-3, 45],
    ],
    [
      [158084, 381338],
      [140, 477],
      [-107, 2272],
      [-522, 4538],
      [-125, 2200],
    ],
    [
      [281985, 391353],
      [-669, -3258],
      [-157, 217],
    ],
    [
      [281159, 388312],
      [-296, 1764],
    ],
    [
      [280863, 390076],
      [149, 2456],
    ],
    [
      [263250, 392869],
      [-350, -1063],
    ],
    [
      [262900, 391806],
      [-413, -1090],
    ],
    [
      [262487, 390716],
      [-250, 1859],
    ],
    [
      [158084, 381338],
      [-237, -853],
      [-152, 859],
    ],
    [
      [157695, 381344],
      [-539, 908],
      [-435, 1971],
      [-296, -310],
    ],
    [
      [156425, 383913],
      [-353, 2861],
      [-569, 2152],
      [-557, 3778],
    ],
    [
      [254915, 388976],
      [-726, 35],
    ],
    [
      [254189, 389011],
      [12, 5213],
    ],
    [
      [280017, 389656],
      [-138, -1763],
      [572, -1648],
    ],
    [
      [280451, 386245],
      [-493, -2674],
    ],
    [
      [279958, 383571],
      [-240, -1325],
    ],
    [
      [279718, 382246],
      [-1324, 5048],
    ],
    [
      [254189, 389011],
      [-119, 3],
    ],
    [
      [254070, 389014],
      [-537, -21],
    ],
    [
      [253533, 388993],
      [-284, 564],
    ],
    [
      [269861, 389247],
      [-95, -2476],
      [-512, -708],
    ],
    [
      [269254, 386063],
      [-253, 186],
    ],
    [
      [269001, 386249],
      [-194, 1565],
    ],
    [
      [268807, 387814],
      [-419, 1210],
    ],
    [
      [237049, 388961],
      [-4, -2241],
    ],
    [
      [237045, 386720],
      [-1525, 568],
    ],
    [
      [235520, 387288],
      [11, 4851],
    ],
    [
      [280863, 390076],
      [-319, -2827],
    ],
    [
      [280544, 387249],
      [-93, -1004],
    ],
    [
      [260715, 389683],
      [-623, -25],
      [-151, -811],
    ],
    [
      [259941, 388847],
      [-106, 262],
      [-8, 2961],
    ],
    [
      [265356, 392696],
      [-244, -3246],
    ],
    [
      [265112, 389450],
      [-475, -738],
    ],
    [
      [264637, 388712],
      [-120, 527],
    ],
    [
      [251999, 389685],
      [-2, -2447],
    ],
    [
      [251997, 387238],
      [-1241, -17],
    ],
    [
      [250756, 387221],
      [2, 543],
    ],
    [
      [250758, 387764],
      [15, 4341],
    ],
    [
      [263762, 388503],
      [-226, -927],
    ],
    [
      [263536, 387576],
      [-64, -376],
    ],
    [
      [263472, 387200],
      [-541, 2720],
      [-31, 1886],
    ],
    [
      [259941, 388847],
      [-406, -1355],
      [-4, -1306],
    ],
    [
      [259531, 386186],
      [-106, -8],
    ],
    [
      [259425, 386178],
      [-618, 89],
    ],
    [
      [258807, 386267],
      [-150, 4],
    ],
    [
      [258657, 386271],
      [0, 4936],
    ],
    [
      [247867, 390696],
      [-213, -2397],
    ],
    [
      [285904, 391677],
      [33, -3347],
      [377, -2748],
      [-110, -1238],
      [-607, 2727],
      [-162, 3701],
    ],
    [
      [266078, 390248],
      [8, -2072],
    ],
    [
      [266086, 388176],
      [-605, -1279],
      [-198, 665],
    ],
    [
      [265283, 387562],
      [-171, 1888],
    ],
    [
      [262487, 390716],
      [-392, -1207],
    ],
    [
      [262095, 389509],
      [-262, -210],
    ],
    [
      [261833, 389299],
      [-458, 2805],
    ],
    [
      [268807, 387814],
      [-424, -2456],
    ],
    [
      [268383, 385358],
      [-426, 367],
      [-546, 2055],
    ],
    [
      [267411, 387780],
      [397, 3341],
      [-19, 784],
    ],
    [
      [250758, 387764],
      [-457, 107],
    ],
    [
      [250301, 387871],
      [-488, -745],
      [-369, 60],
      [-248, -998],
    ],
    [
      [249196, 386188],
      [-7, 4407],
    ],
    [
      [277215, 389401],
      [-117, -2971],
      [-334, -2300],
      [41, -777],
    ],
    [
      [276805, 383353],
      [-350, -1565],
      [-111, -2182],
    ],
    [
      [276344, 379606],
      [-848, -338],
      [-274, 1261],
      [9, 2076],
    ],
    [
      [275231, 382605],
      [22, 2224],
      [297, 802],
    ],
    [
      [242945, 389467],
      [-266, -2385],
      [-345, 60],
      [127, -1459],
      [-203, -1038],
    ],
    [
      [242258, 384645],
      [-596, 107],
      [14, 1563],
      [-256, 47],
    ],
    [
      [231794, 386480],
      [-23, -4900],
    ],
    [
      [231771, 381580],
      [-1110, 51],
    ],
    [
      [230661, 381631],
      [9, 6479],
    ],
    [
      [234285, 392148],
      [-26, -6491],
    ],
    [
      [234259, 385657],
      [-1233, 6],
    ],
    [
      [233026, 385663],
      [0, 813],
    ],
    [
      [235520, 387288],
      [0, -1654],
    ],
    [
      [235520, 385634],
      [-1261, 23],
    ],
    [
      [275231, 382605],
      [-213, 772],
    ],
    [
      [275018, 383377],
      [-482, 1699],
      [-115, 3126],
    ],
    [
      [161934, 387867],
      [6, -3922],
    ],
    [
      [161940, 383945],
      [-1040, -1303],
      [-210, 591],
      [-282, -2812],
    ],
    [
      [160408, 380421],
      [-311, -431],
      [76, 1800],
    ],
    [
      [160173, 381790],
      [162, 2443],
    ],
    [
      [261833, 389299],
      [-405, -1730],
    ],
    [
      [261428, 387569],
      [-332, 583],
    ],
    [
      [261096, 388152],
      [14, 1160],
    ],
    [
      [263472, 387200],
      [-446, -2246],
    ],
    [
      [263026, 384954],
      [-363, 80],
    ],
    [
      [262663, 385034],
      [-258, 1506],
      [-110, 2136],
      [-200, 833],
    ],
    [
      [267411, 387780],
      [-197, -1449],
      [23, -1521],
      [-313, -464],
    ],
    [
      [266924, 384346],
      [-311, 1158],
    ],
    [
      [266613, 385504],
      [-354, 897],
      [-173, 1775],
    ],
    [
      [244558, 391416],
      [4, -9113],
    ],
    [
      [244562, 382303],
      [-467, 23],
      [2, -1088],
    ],
    [
      [244097, 381238],
      [-267, 17],
    ],
    [
      [243830, 381255],
      [-40, 2514],
    ],
    [
      [243790, 383769],
      [12, 7731],
    ],
    [
      [169169, 376466],
      [1659, -8029],
    ],
    [
      [170828, 368437],
      [-2625, -35],
    ],
    [
      [168203, 368402],
      [-689, 2284],
    ],
    [
      [167514, 370686],
      [-267, 2691],
      [-422, 166],
    ],
    [
      [166825, 373543],
      [187, 2774],
      [-290, 1077],
      [-121, 2597],
      [-312, 216],
      [-475, 1906],
      [-19, 2369],
    ],
    [
      [246321, 387086],
      [-122, -4889],
    ],
    [
      [246199, 382197],
      [-880, -1],
    ],
    [
      [245319, 382196],
      [-757, 107],
    ],
    [
      [243790, 383769],
      [-1536, 69],
    ],
    [
      [242254, 383838],
      [4, 807],
    ],
    [
      [164587, 387892],
      [-720, -834],
      [-259, 133],
      [-515, -1390],
      [-61, -1062],
      [-1009, -2141],
    ],
    [
      [162023, 382598],
      [-83, 1347],
    ],
    [
      [288209, 388837],
      [-340, -1484],
      [-14, -1069],
    ],
    [
      [287855, 386284],
      [-365, -3226],
      [-285, 1002],
      [9, -1396],
      [-759, 4562],
      [218, 1227],
      [-54, 1256],
      [301, 244],
      [335, -1096],
      [296, 2145],
    ],
    [
      [218595, 391429],
      [0, -8114],
    ],
    [
      [218595, 383315],
      [-1157, 7],
    ],
    [
      [217438, 383322],
      [-63, -1],
    ],
    [
      [217375, 383321],
      [-7, 8119],
    ],
    [
      [219823, 391395],
      [8, -8114],
    ],
    [
      [219831, 383281],
      [-1236, 34],
    ],
    [
      [216145, 391423],
      [-1, -8126],
    ],
    [
      [216144, 383297],
      [-1330, -14],
    ],
    [
      [214814, 383283],
      [1, 118],
    ],
    [
      [214815, 383401],
      [-1, 6449],
    ],
    [
      [217375, 383321],
      [-1161, -23],
    ],
    [
      [216214, 383298],
      [-70, -1],
    ],
    [
      [282823, 386026],
      [45, -780],
    ],
    [
      [282868, 385246],
      [-236, -135],
    ],
    [
      [282632, 385111],
      [-191, 603],
      [-899, -1519],
    ],
    [
      [281542, 384195],
      [7, 1473],
      [-390, 2644],
    ],
    [
      [221667, 391366],
      [1, -6464],
    ],
    [
      [221668, 384902],
      [2, -1623],
    ],
    [
      [221670, 383279],
      [-1791, 1],
    ],
    [
      [219879, 383280],
      [-48, 1],
    ],
    [
      [241058, 386418],
      [-180, -1533],
      [-17, -2354],
    ],
    [
      [240861, 382531],
      [-193, -605],
      [-544, 142],
      [-327, 1182],
    ],
    [
      [239797, 383250],
      [30, 5017],
    ],
    [
      [224745, 388113],
      [2, -4860],
    ],
    [
      [224747, 383253],
      [-1206, 6],
    ],
    [
      [223541, 383259],
      [-334, 2],
      [0, 1622],
    ],
    [
      [223207, 384883],
      [-1, 6486],
    ],
    [
      [206744, 388071],
      [3, -4865],
      [-303, -5],
    ],
    [
      [206444, 383201],
      [-2083, 130],
    ],
    [
      [204361, 383331],
      [-596, 3379],
    ],
    [
      [223207, 384883],
      [-1539, 19],
    ],
    [
      [285428, 388496],
      [2, -844],
    ],
    [
      [285430, 387652],
      [-211, 220],
      [-263, -2218],
    ],
    [
      [284956, 385654],
      [81, -2289],
      [-234, 434],
      [-329, 2869],
      [-459, -1569],
      [-187, 2281],
      [549, 3865],
    ],
    [
      [258657, 386271],
      [-1, -537],
      [-1038, 13],
    ],
    [
      [257618, 385747],
      [-2, 2449],
    ],
    [
      [270898, 384461],
      [-214, -1052],
      [-154, 1960],
    ],
    [
      [270530, 385369],
      [-22, 1864],
    ],
    [
      [271507, 390062],
      [-150, -656],
      [68, -2319],
      [-484, -1260],
      [-43, -1366],
    ],
    [
      [181367, 389064],
      [0, -7900],
    ],
    [
      [181367, 381164],
      [-7, -10123],
    ],
    [
      [181360, 371041],
      [4, -11253],
    ],
    [
      [181364, 359788],
      [2, -2927],
    ],
    [
      [181366, 356861],
      [-1984, 13],
      [0, 178],
      [-2727, -7],
      [-434, -204],
    ],
    [
      [199240, 384017],
      [-2, 565],
      [-1384, -11],
      [128, -1422],
      [415, -674],
      [-79, -1241],
    ],
    [
      [198318, 381234],
      [-2999, 12],
    ],
    [
      [195319, 381246],
      [-49, 6459],
    ],
    [
      [273779, 387839],
      [177, -533],
      [-261, -1185],
      [-892, -2812],
    ],
    [
      [272803, 383309],
      [-146, 3878],
      [250, 1036],
    ],
    [
      [249196, 386188],
      [3, -3707],
    ],
    [
      [249199, 382481],
      [-545, 27],
    ],
    [
      [248654, 382508],
      [-40, 1621],
      [-342, 19],
      [-642, 3983],
    ],
    [
      [281542, 384195],
      [-437, -1435],
      [-99, 693],
    ],
    [
      [281006, 383453],
      [-396, 1782],
      [-66, 2014],
    ],
    [
      [272803, 383309],
      [-1, -6],
    ],
    [
      [272802, 383303],
      [-272, -1508],
      [-143, -3971],
    ],
    [
      [272387, 377824],
      [-212, 340],
    ],
    [
      [272175, 378164],
      [-165, 2328],
      [-356, 2011],
      [-528, -202],
    ],
    [
      [271126, 382301],
      [46, 1976],
      [-274, 184],
    ],
    [
      [214815, 383401],
      [-1944, -34],
    ],
    [
      [212871, 383367],
      [-1832, -28],
    ],
    [
      [211039, 383339],
      [-284, -8],
    ],
    [
      [210755, 383331],
      [-8, 4681],
    ],
    [
      [227834, 389742],
      [0, -8111],
    ],
    [
      [227834, 381631],
      [-920, 3],
    ],
    [
      [226914, 381634],
      [-613, -2],
    ],
    [
      [226301, 381632],
      [-6, 6498],
    ],
    [
      [229372, 388125],
      [-59, -8133],
    ],
    [
      [229313, 379992],
      [-869, 39],
    ],
    [
      [228444, 380031],
      [-2, 1618],
      [-608, -18],
    ],
    [
      [265283, 387562],
      [-139, -989],
    ],
    [
      [265144, 386573],
      [-341, 379],
    ],
    [
      [264803, 386952],
      [-166, 1760],
    ],
    [
      [253533, 388993],
      [-9, -5827],
    ],
    [
      [253524, 383166],
      [-612, -14],
    ],
    [
      [252912, 383152],
      [-924, 24],
    ],
    [
      [251988, 383176],
      [9, 4062],
    ],
    [
      [261096, 388152],
      [-573, -2670],
    ],
    [
      [260523, 385482],
      [-285, -2089],
      [-141, 377],
    ],
    [
      [260097, 383770],
      [-46, 2157],
      [-520, 259],
    ],
    [
      [270530, 385369],
      [-363, -645],
      [-243, -2058],
    ],
    [
      [269924, 382666],
      [-670, 3397],
    ],
    [
      [262663, 385034],
      [-353, -388],
    ],
    [
      [262310, 384646],
      [-796, 417],
    ],
    [
      [261514, 385063],
      [-86, 2506],
    ],
    [
      [278140, 386071],
      [-341, -3644],
      [-207, -671],
    ],
    [
      [277592, 381756],
      [-787, 1597],
    ],
    [
      [283755, 387795],
      [-68, -3119],
    ],
    [
      [283687, 384676],
      [-6, -1714],
    ],
    [
      [283681, 382962],
      [-121, 26],
    ],
    [
      [283560, 382988],
      [-214, 707],
    ],
    [
      [283346, 383695],
      [-232, 459],
    ],
    [
      [283114, 384154],
      [-246, 1092],
    ],
    [
      [264803, 386952],
      [-255, -1635],
    ],
    [
      [264548, 385317],
      [-418, -1608],
      [-274, -40],
    ],
    [
      [263856, 383669],
      [-44, 1680],
      [-276, 2227],
    ],
    [
      [185634, 387890],
      [509, -3310],
      [-59, -2002],
      [-242, -1389],
    ],
    [
      [185842, 381189],
      [-97, -48],
    ],
    [
      [185745, 381141],
      [-4378, 23],
    ],
    [
      [254070, 389014],
      [36, -2007],
      [-137, -3775],
    ],
    [
      [253969, 383232],
      [-445, -66],
    ],
    [
      [254658, 386112],
      [-463, -2723],
      [-218, -213],
    ],
    [
      [253977, 383176],
      [-8, 56],
    ],
    [
      [238586, 387938],
      [-30, -5629],
    ],
    [
      [238556, 382309],
      [-1467, 73],
    ],
    [
      [237089, 382382],
      [-71, 8],
      [27, 4330],
    ],
    [
      [289213, 386811],
      [95, -1816],
      [-160, -1192],
      [-692, -216],
    ],
    [
      [288456, 383587],
      [-506, -230],
    ],
    [
      [287950, 383357],
      [-283, -697],
      [-32, 1164],
      [220, 2460],
    ],
    [
      [275018, 383377],
      [-768, -3505],
      [-473, 452],
    ],
    [
      [273777, 380324],
      [-279, 2237],
      [-256, -553],
      [-440, 1295],
    ],
    [
      [256529, 387976],
      [-3, -5248],
    ],
    [
      [256526, 382728],
      [-678, 251],
    ],
    [
      [255848, 382979],
      [2, 2497],
      [-255, -92],
      [-154, 2933],
    ],
    [
      [158378, 381314],
      [146, -1464],
      [-437, 1443],
    ],
    [
      [160173, 381790],
      [-408, -2224],
      [-354, -188],
      [-351, 1518],
      [-215, -1698],
      [-283, 502],
      [-160, 1609],
    ],
    [
      [239797, 383250],
      [35, -3689],
    ],
    [
      [239832, 379561],
      [-1222, 173],
    ],
    [
      [238610, 379734],
      [-54, 2575],
    ],
    [
      [255848, 382979],
      [-1, -825],
      [-418, -677],
    ],
    [
      [255429, 381477],
      [-616, 58],
    ],
    [
      [254813, 381535],
      [-309, 1118],
      [-517, 34],
    ],
    [
      [253987, 382687],
      [-10, 489],
    ],
    [
      [257618, 385747],
      [6, -2451],
    ],
    [
      [257624, 383296],
      [-312, -1080],
    ],
    [
      [257312, 382216],
      [-630, -29],
    ],
    [
      [256682, 382187],
      [-156, 541],
    ],
    [
      [266613, 385504],
      [-146, -2035],
      [-362, -1580],
    ],
    [
      [266105, 381889],
      [-246, 1825],
      [-347, 209],
    ],
    [
      [265512, 383923],
      [-368, 2650],
    ],
    [
      [261514, 385063],
      [-513, -1353],
    ],
    [
      [261001, 383710],
      [-478, 1772],
    ],
    [
      [230661, 381631],
      [-3, -1635],
      [-459, 10],
    ],
    [
      [230199, 380006],
      [-886, -14],
    ],
    [
      [209217, 388123],
      [-12, -6998],
    ],
    [
      [209205, 381125],
      [-8, -7666],
    ],
    [
      [209197, 373459],
      [-808, 1541],
    ],
    [
      [208389, 375000],
      [-823, 1546],
      [-1023, -359],
      [-102, 637],
    ],
    [
      [206441, 376824],
      [3, 6377],
    ],
    [
      [210755, 383331],
      [-21, -1721],
      [-306, -20],
      [0, -1082],
      [-596, -8],
      [-593, 529],
    ],
    [
      [209239, 381029],
      [-34, 96],
    ],
    [
      [226301, 381632],
      [-1535, -18],
    ],
    [
      [224766, 381614],
      [-19, 1639],
    ],
    [
      [248654, 382508],
      [-383, 35],
      [1, -1620],
      [-477, -883],
    ],
    [
      [247795, 380040],
      [-127, 736],
    ],
    [
      [247668, 380776],
      [-303, 1892],
      [54, 2935],
    ],
    [
      [250756, 387221],
      [-8, -4860],
    ],
    [
      [250748, 382361],
      [-1241, 119],
    ],
    [
      [249507, 382480],
      [-308, 1],
    ],
    [
      [285430, 387652],
      [553, -2587],
      [494, -4061],
      [-10, -1655],
      [-344, 2010],
      [-710, 2346],
      [-112, -1069],
      [-345, 3018],
    ],
    [
      [164734, 386545],
      [-434, -1184],
      [-950, -7856],
      [-382, -2239],
    ],
    [
      [162968, 375266],
      [-758, 4581],
    ],
    [
      [162210, 379847],
      [-187, 2751],
    ],
    [
      [187728, 387742],
      [-228, -1434],
      [16, -5094],
    ],
    [
      [187516, 381214],
      [-1674, -25],
    ],
    [
      [192580, 387705],
      [27, -2493],
      [351, -3273],
      [-106, -713],
    ],
    [
      [192852, 381226],
      [-4142, -34],
      [-1194, 22],
    ],
    [
      [247668, 380776],
      [-455, -1591],
    ],
    [
      [247213, 379185],
      [-466, -722],
      [-158, 1359],
    ],
    [
      [246589, 379822],
      [-390, 2375],
    ],
    [
      [268978, 383008],
      [-530, -70],
    ],
    [
      [268448, 382938],
      [-65, 2420],
    ],
    [
      [269001, 386249],
      [-23, -3241],
    ],
    [
      [268448, 382938],
      [-363, -1281],
    ],
    [
      [268085, 381657],
      [-378, 305],
      [-289, 1389],
      [-223, -1372],
    ],
    [
      [267195, 381979],
      [-271, 2367],
    ],
    [
      [195319, 381246],
      [2, -5061],
    ],
    [
      [195321, 376185],
      [-5, -7382],
    ],
    [
      [195316, 368803],
      [-5, -9039],
    ],
    [
      [195311, 359764],
      [-2662, -21],
    ],
    [
      [192649, 359743],
      [-2090, 97],
    ],
    [
      [190559, 359840],
      [-1844, -32],
    ],
    [
      [188715, 359808],
      [653, 1884],
      [604, 385],
      [346, 3275],
      [287, 901],
      [-33, 1956],
      [278, 1645],
    ],
    [
      [190850, 369854],
      [446, 2473],
      [227, 3835],
      [527, 220],
      [571, 4056],
      [231, 788],
    ],
    [
      [263856, 383669],
      [113, -1403],
    ],
    [
      [263969, 382266],
      [-621, -1698],
    ],
    [
      [263348, 380568],
      [-281, 1465],
    ],
    [
      [263067, 382033],
      [-41, 2921],
    ],
    [
      [281006, 383453],
      [-231, -1615],
    ],
    [
      [280775, 381838],
      [-817, 1733],
    ],
    [
      [279718, 382246],
      [-89, -2346],
      [-162, -617],
    ],
    [
      [279467, 379283],
      [-168, -1840],
      [-296, -1251],
      [-422, 177],
    ],
    [
      [278581, 376369],
      [-906, 3632],
    ],
    [
      [277675, 380001],
      [127, 1436],
      [-210, 319],
    ],
    [
      [251988, 383176],
      [-8, -2447],
    ],
    [
      [251980, 380729],
      [-1183, -9],
    ],
    [
      [250797, 380720],
      [-49, 1641],
    ],
    [
      [237089, 382382],
      [-24, -3302],
    ],
    [
      [237065, 379080],
      [-1548, 436],
    ],
    [
      [235517, 379516],
      [3, 6118],
    ],
    [
      [172686, 359243],
      [-1858, 9194],
    ],
    [
      [265512, 383923],
      [-362, -1946],
    ],
    [
      [265150, 381977],
      [-326, 1245],
      [-276, 2095],
    ],
    [
      [204361, 383331],
      [336, -2212],
      [254, -3271],
      [312, -1387],
    ],
    [
      [205263, 376461],
      [44, -2691],
    ],
    [
      [205307, 373770],
      [-1620, -57],
    ],
    [
      [203687, 373713],
      [-1526, -10],
      [-2, 1613],
      [-297, 15],
    ],
    [
      [201862, 375331],
      [0, 678],
      [-568, 1790],
      [-287, -220],
    ],
    [
      [201007, 377579],
      [-1, 3557],
    ],
    [
      [289111, 378680],
      [-677, -384],
    ],
    [
      [288434, 378296],
      [-6, 32],
    ],
    [
      [288428, 378328],
      [-108, 834],
      [337, 902],
      [-201, 3523],
    ],
    [
      [289925, 386798],
      [-186, -3801],
      [-305, -810],
      [-323, -3507],
    ],
    [
      [166825, 373543],
      [-110, 722],
    ],
    [
      [166715, 374265],
      [-627, 2326],
      [-335, -1722],
      [-420, -936],
      [-390, 89],
      [-379, 1183],
      [-853, -3610],
    ],
    [
      [163711, 371595],
      [-743, 3671],
    ],
    [
      [233026, 385663],
      [-31, -6553],
    ],
    [
      [232995, 379110],
      [-1225, 36],
    ],
    [
      [231770, 379146],
      [1, 2434],
    ],
    [
      [242254, 383838],
      [30, -5137],
    ],
    [
      [242284, 378701],
      [-616, 91],
    ],
    [
      [241668, 378792],
      [-306, 22],
      [-345, 3550],
      [-156, 167],
    ],
    [
      [258807, 386267],
      [-62, -3640],
      [-147, -849],
    ],
    [
      [258598, 381778],
      [-366, -1124],
    ],
    [
      [258232, 380654],
      [2, 1594],
      [-309, 13],
      [3, 1094],
      [-304, -59],
    ],
    [
      [259425, 386178],
      [1, -1624],
      [368, -2837],
    ],
    [
      [259794, 381717],
      [-134, -3193],
    ],
    [
      [259660, 378524],
      [-140, -131],
    ],
    [
      [259520, 378393],
      [-503, 215],
      [-244, 853],
      [-175, 2317],
    ],
    [
      [269924, 382666],
      [-59, -1571],
      [261, -1815],
      [-318, -1930],
    ],
    [
      [269808, 377350],
      [-304, -1659],
    ],
    [
      [269504, 375691],
      [-230, 1692],
    ],
    [
      [269274, 377383],
      [-144, 2308],
      [-237, 1273],
      [85, 2044],
    ],
    [
      [260097, 383770],
      [-303, -2053],
    ],
    [
      [284512, 383385],
      [-92, -1945],
    ],
    [
      [284420, 381440],
      [-8, -27],
    ],
    [
      [284412, 381413],
      [-144, -224],
    ],
    [
      [284268, 381189],
      [-437, 1843],
      [-150, -70],
    ],
    [
      [283687, 384676],
      [250, -96],
      [535, 1272],
      [40, -2467],
    ],
    [
      [267195, 381979],
      [-57, -1464],
    ],
    [
      [267138, 380515],
      [-472, -1482],
    ],
    [
      [266666, 379033],
      [-181, 318],
    ],
    [
      [266485, 379351],
      [-380, 2538],
    ],
    [
      [282632, 385111],
      [-701, -4528],
    ],
    [
      [281931, 380583],
      [-408, 650],
      [-297, -356],
    ],
    [
      [281226, 380877],
      [-451, 961],
    ],
    [
      [234259, 385657],
      [-34, -6557],
    ],
    [
      [234225, 379100],
      [-1230, 10],
    ],
    [
      [235517, 379516],
      [0, -428],
    ],
    [
      [235517, 379088],
      [-1292, 12],
    ],
    [
      [261001, 383710],
      [173, -405],
      [-53, -2162],
    ],
    [
      [261121, 381143],
      [-14, -546],
    ],
    [
      [261107, 380597],
      [-355, -820],
      [-434, 244],
      [-629, -1650],
    ],
    [
      [259689, 378371],
      [-29, 153],
    ],
    [
      [283114, 384154],
      [232, -459],
    ],
    [
      [283560, 382988],
      [-758, -4755],
    ],
    [
      [282802, 378233],
      [-126, 314],
    ],
    [
      [282676, 378547],
      [-383, 1648],
      [-362, 388],
    ],
    [
      [271126, 382301],
      [-263, -1063],
      [-13, -2367],
    ],
    [
      [270850, 378871],
      [-720, -941],
    ],
    [
      [270130, 377930],
      [-322, -580],
    ],
    [
      [263067, 382033],
      [-389, -1467],
    ],
    [
      [262678, 380566],
      [-442, 233],
    ],
    [
      [262236, 380799],
      [74, 3847],
    ],
    [
      [265150, 381977],
      [-286, -1440],
    ],
    [
      [264864, 380537],
      [-573, -892],
    ],
    [
      [264291, 379645],
      [-263, 850],
      [-59, 1771],
    ],
    [
      [262236, 380799],
      [-219, -1712],
    ],
    [
      [262017, 379087],
      [-896, 2056],
    ],
    [
      [223541, 383259],
      [-1, -4853],
      [-304, -4],
    ],
    [
      [223236, 378402],
      [-914, 9],
      [-1, 1610],
      [-610, 3],
    ],
    [
      [221711, 380024],
      [-41, 3255],
    ],
    [
      [199424, 381138],
      [158, -1618],
      [-158, -1773],
    ],
    [
      [199424, 377747],
      [-472, -1110],
    ],
    [
      [198952, 376637],
      [-30, 888],
      [-421, 1384],
      [24, 1647],
      [-207, 678],
    ],
    [
      [157695, 381344],
      [162, -972],
      [-41, -3299],
      [179, -856],
      [-251, -1183],
      [-370, 1731],
      [-114, -336],
      [-479, 2414],
      [-374, -283],
      [165, 2872],
      [317, -1457],
      [-464, 3938],
    ],
    [
      [266485, 379351],
      [-223, -717],
      [-503, -259],
    ],
    [
      [265759, 378375],
      [-341, 2566],
      [-268, 1036],
    ],
    [
      [162210, 379847],
      [2, -6325],
      [-188, 423],
      [-596, -1237],
      [-739, -3958],
    ],
    [
      [160689, 368750],
      [-239, 1114],
      [0, 5132],
    ],
    [
      [160450, 374996],
      [-71, 4167],
    ],
    [
      [160379, 379163],
      [29, 1258],
    ],
    [
      [243830, 381255],
      [-6, -1908],
      [-732, 4],
      [-351, -755],
    ],
    [
      [242741, 378596],
      [-457, 105],
    ],
    [
      [288428, 378328],
      [-760, -1368],
      [99, 2154],
      [225, 837],
    ],
    [
      [287992, 379951],
      [5, 43],
    ],
    [
      [287997, 379994],
      [-216, -302],
      [-1, 1870],
      [-264, -794],
      [434, 2589],
    ],
    [
      [281226, 380877],
      [-275, -2361],
    ],
    [
      [280951, 378516],
      [-515, -3903],
    ],
    [
      [280436, 374613],
      [-425, -1184],
    ],
    [
      [280011, 373429],
      [-544, 5854],
    ],
    [
      [285766, 378696],
      [-155, -920],
    ],
    [
      [285611, 377776],
      [-363, 657],
      [-314, 2280],
      [-163, -800],
    ],
    [
      [284771, 379913],
      [-351, 1527],
    ],
    [
      [284512, 383385],
      [528, -1942],
      [635, -277],
      [253, -1943],
      [-162, -527],
    ],
    [
      [241668, 378792],
      [-8, -3016],
    ],
    [
      [241660, 375776],
      [-363, -1063],
      [-225, 1683],
      [-655, 61],
    ],
    [
      [240417, 376457],
      [-605, 128],
    ],
    [
      [239812, 376585],
      [20, 2976],
    ],
    [
      [269274, 377383],
      [-231, 160],
      [-89, -1393],
    ],
    [
      [268954, 376150],
      [-910, 2229],
      [-138, -652],
    ],
    [
      [267906, 377727],
      [-93, 803],
    ],
    [
      [267813, 378530],
      [371, 1947],
      [-99, 1180],
    ],
    [
      [277675, 380001],
      [-469, -3994],
    ],
    [
      [277206, 376007],
      [-657, 395],
      [-135, 1144],
      [-337, -42],
    ],
    [
      [276077, 377504],
      [267, 2102],
    ],
    [
      [214814, 383283],
      [8, -9753],
    ],
    [
      [214822, 373530],
      [1, -1754],
    ],
    [
      [214823, 371776],
      [-1967, -12],
    ],
    [
      [212856, 371764],
      [15, 11603],
    ],
    [
      [276077, 377504],
      [-296, -1431],
      [-373, -3413],
    ],
    [
      [275408, 372660],
      [-430, -146],
      [-592, 890],
    ],
    [
      [274386, 373404],
      [-399, 2555],
    ],
    [
      [273987, 375959],
      [102, 2169],
      [-312, 2196],
    ],
    [
      [267813, 378530],
      [-479, 65],
      [-196, 1920],
    ],
    [
      [206441, 376824],
      [-330, 1926],
      [-322, -2223],
      [-84, 711],
      [-442, -777],
    ],
    [
      [212856, 371764],
      [-914, -4],
    ],
    [
      [211942, 371760],
      [-915, 4],
    ],
    [
      [211027, 371764],
      [12, 11575],
    ],
    [
      [211027, 371764],
      [-1822, 7],
      [-8, 1688],
    ],
    [
      [258232, 380654],
      [-83, -1403],
    ],
    [
      [258149, 379251],
      [-55, -2225],
      [-404, -1554],
    ],
    [
      [257690, 375472],
      [32, 1005],
      [-295, 274],
      [-178, 1615],
    ],
    [
      [257249, 378366],
      [114, -4],
      [-51, 3854],
    ],
    [
      [273987, 375959],
      [-386, -927],
    ],
    [
      [273601, 375032],
      [-357, 1057],
      [-619, 250],
      [-238, 1485],
    ],
    [
      [217438, 383322],
      [-3, -8107],
      [40, -1726],
    ],
    [
      [217475, 373489],
      [-1218, 0],
    ],
    [
      [216257, 373489],
      [-44, 1699],
      [1, 8110],
    ],
    [
      [219879, 383280],
      [1, -4863],
    ],
    [
      [219880, 378417],
      [-1219, 28],
      [33, -4957],
    ],
    [
      [218694, 373488],
      [-1219, 1],
    ],
    [
      [216257, 373489],
      [-1435, 41],
    ],
    [
      [253987, 382687],
      [206, -1256],
      [-505, -5063],
    ],
    [
      [253688, 376368],
      [-787, 325],
    ],
    [
      [252901, 376693],
      [11, 6459],
    ],
    [
      [224766, 381614],
      [1, -6484],
    ],
    [
      [224767, 375130],
      [-1507, 16],
    ],
    [
      [223260, 375146],
      [-24, 3256],
    ],
    [
      [221711, 380024],
      [-1, -3245],
    ],
    [
      [221710, 376779],
      [-1831, 17],
    ],
    [
      [219879, 376796],
      [1, 1621],
    ],
    [
      [252901, 376693],
      [0, -15],
    ],
    [
      [252901, 376678],
      [-925, -16],
    ],
    [
      [251976, 376662],
      [4, 4067],
    ],
    [
      [284268, 381189],
      [-150, -1311],
      [278, -2136],
    ],
    [
      [284396, 377742],
      [-309, -256],
      [-1, -1105],
    ],
    [
      [284086, 376381],
      [-456, -1904],
    ],
    [
      [283630, 374477],
      [-165, -177],
      [-222, 1929],
      [-441, 2004],
    ],
    [
      [256682, 382187],
      [-392, -3475],
    ],
    [
      [256290, 378712],
      [-307, -2568],
    ],
    [
      [255983, 376144],
      [-94, 369],
    ],
    [
      [255889, 376513],
      [-415, 787],
    ],
    [
      [255474, 377300],
      [-45, 4177],
    ],
    [
      [254813, 381535],
      [-35, -5040],
    ],
    [
      [254778, 376495],
      [-630, 72],
    ],
    [
      [254148, 376567],
      [54, -1761],
      [-336, -146],
    ],
    [
      [253866, 374660],
      [-178, 1708],
    ],
    [
      [272175, 378164],
      [-318, -1099],
      [158, -2552],
    ],
    [
      [272015, 374513],
      [-260, -47],
    ],
    [
      [271755, 374466],
      [-295, 397],
      [-169, 2098],
      [-573, 1278],
      [132, 632],
    ],
    [
      [249507, 382480],
      [-7, -4915],
    ],
    [
      [249500, 377565],
      [-220, -2832],
    ],
    [
      [249280, 374733],
      [-473, 1903],
      [-262, -565],
    ],
    [
      [248545, 376071],
      [-165, 1642],
      [-585, 2327],
    ],
    [
      [238610, 379734],
      [-22, -2902],
      [-171, -1635],
    ],
    [
      [238417, 375197],
      [-154, 26],
    ],
    [
      [238263, 375223],
      [-509, 95],
      [7, 1074],
      [-716, 163],
    ],
    [
      [237045, 376555],
      [20, 2525],
    ],
    [
      [250797, 380720],
      [-133, -3247],
    ],
    [
      [250664, 377473],
      [-1164, 92],
    ],
    [
      [245319, 382196],
      [-11, -8638],
    ],
    [
      [245308, 373558],
      [-149, -795],
    ],
    [
      [245159, 372763],
      [-438, 51],
      [4, 1622],
      [-612, 34],
    ],
    [
      [244113, 374470],
      [-16, 6768],
    ],
    [
      [264291, 379645],
      [-143, -3260],
    ],
    [
      [264148, 376385],
      [-273, -833],
    ],
    [
      [263875, 375552],
      [-122, 1730],
      [-504, 1185],
    ],
    [
      [263249, 378467],
      [99, 2101],
    ],
    [
      [246589, 379822],
      [-14, -6361],
    ],
    [
      [246575, 373461],
      [-1267, 97],
    ],
    [
      [257249, 378366],
      [-460, -1268],
    ],
    [
      [256789, 377098],
      [-280, -2533],
      [-526, 1579],
    ],
    [
      [259520, 378393],
      [-23, -3367],
      [-403, -371],
    ],
    [
      [259094, 374655],
      [-945, 4596],
    ],
    [
      [263249, 378467],
      [-138, -2638],
    ],
    [
      [263111, 375829],
      [-141, -279],
      [-100, 2291],
    ],
    [
      [262870, 377841],
      [-192, 2725],
    ],
    [
      [265759, 378375],
      [-26, -1497],
    ],
    [
      [265733, 376878],
      [-550, 230],
    ],
    [
      [265183, 377108],
      [-319, 3429],
    ],
    [
      [228444, 380031],
      [1, -3258],
    ],
    [
      [228445, 376773],
      [-1531, -4],
    ],
    [
      [226914, 376769],
      [0, 4865],
    ],
    [
      [231770, 379146],
      [-5, -5695],
    ],
    [
      [231765, 373451],
      [0, -2430],
    ],
    [
      [231765, 371021],
      [-1573, 61],
    ],
    [
      [230192, 371082],
      [7, 8924],
    ],
    [
      [226914, 376769],
      [9, -3303],
      [-304, -24],
    ],
    [
      [226619, 373442],
      [-1831, -22],
    ],
    [
      [224788, 373420],
      [-21, 1710],
    ],
    [
      [255474, 377300],
      [-169, -643],
      [-206, 1280],
    ],
    [
      [255099, 377937],
      [-83, -2666],
      [-238, 1224],
    ],
    [
      [244113, 374470],
      [-771, 42],
      [-10, -3582],
    ],
    [
      [243332, 370930],
      [-613, 69],
    ],
    [
      [242719, 370999],
      [22, 7597],
    ],
    [
      [190850, 369854],
      [-4778, -85],
      [-898, 125],
    ],
    [
      [185174, 369894],
      [-16, 4888],
      [309, -23],
      [-28, 1599],
      [335, 1],
      [-29, 4782],
    ],
    [
      [285474, 374121],
      [-189, -776],
    ],
    [
      [285285, 373345],
      [-143, 1232],
      [-392, -213],
      [-211, 1145],
      [-143, 2233],
    ],
    [
      [284412, 381413],
      [24, -1012],
      [357, -714],
      [24, -1757],
      [347, -1481],
      [310, -2328],
    ],
    [
      [198952, 376637],
      [-342, -2418],
    ],
    [
      [198610, 374219],
      [-358, 1511],
      [-614, -665],
      [-130, 1368],
      [-1561, 20],
      [-626, -268],
    ],
    [
      [282676, 378547],
      [-305, -5181],
    ],
    [
      [282371, 373366],
      [-288, 723],
      [-132, 1443],
      [-320, 1091],
    ],
    [
      [281631, 376623],
      [-680, 1893],
    ],
    [
      [185174, 369894],
      [-607, 4],
      [0, -813],
    ],
    [
      [184567, 369085],
      [-377, -469],
      [-604, 143],
      [0, 868],
      [-616, 2],
      [-1, 1663],
      [-315, -252],
      [-1294, 1],
    ],
    [
      [262017, 379087],
      [-182, -1209],
    ],
    [
      [261835, 377878],
      [-647, -598],
      [-256, 976],
    ],
    [
      [260932, 378256],
      [175, 2341],
    ],
    [
      [201007, 377579],
      [-383, -319],
      [-19, -4903],
      [45, -4703],
    ],
    [
      [200650, 367654],
      [-984, -5],
    ],
    [
      [199666, 367649],
      [-1, 4038],
    ],
    [
      [199665, 371687],
      [0, 2392],
      [-241, 3668],
    ],
    [
      [262870, 377841],
      [-381, -297],
      [-271, -1165],
    ],
    [
      [262218, 376379],
      [-341, 113],
    ],
    [
      [261877, 376492],
      [-42, 1386],
    ],
    [
      [251976, 376662],
      [-1, -808],
    ],
    [
      [251975, 375854],
      [-1238, -25],
    ],
    [
      [250737, 375829],
      [-73, 1644],
    ],
    [
      [285611, 377776],
      [352, -2379],
    ],
    [
      [285963, 375397],
      [-300, -592],
    ],
    [
      [285663, 374805],
      [-240, 293],
      [-546, 3121],
      [-106, 1694],
    ],
    [
      [248545, 376071],
      [-481, -3793],
    ],
    [
      [248064, 372278],
      [-249, -32],
      [-722, 3898],
      [380, 1913],
      [-260, 1128],
    ],
    [
      [260932, 378256],
      [-418, -2942],
      [-267, -430],
    ],
    [
      [260247, 374884],
      [-558, 3487],
    ],
    [
      [267906, 377727],
      [-44, -1950],
    ],
    [
      [267862, 375777],
      [-303, 91],
      [-437, -1812],
      [23, -1008],
    ],
    [
      [267145, 373048],
      [-650, 2775],
    ],
    [
      [266495, 375823],
      [171, 3210],
    ],
    [
      [265183, 377108],
      [-100, -1732],
    ],
    [
      [265083, 375376],
      [-218, 283],
    ],
    [
      [264865, 375659],
      [-717, 726],
    ],
    [
      [160450, 374996],
      [-1125, -1844],
      [-234, 1488],
      [-260, 115],
      [-482, 1735],
    ],
    [
      [158349, 376490],
      [-209, 1238],
      [349, 1656],
      [315, -572],
      [373, 668],
      [783, -708],
      [357, 1506],
      [62, -1115],
    ],
    [
      [230192, 371082],
      [-1, -2432],
    ],
    [
      [230191, 368650],
      [-1749, -16],
    ],
    [
      [228442, 368634],
      [3, 8139],
    ],
    [
      [223260, 375146],
      [0, -1720],
    ],
    [
      [223260, 373426],
      [-1521, 36],
    ],
    [
      [221739, 373462],
      [-29, 3317],
    ],
    [
      [278540, 374733],
      [-257, -158],
      [-226, -2494],
      [-255, -813],
    ],
    [
      [277802, 371268],
      [-172, -1567],
    ],
    [
      [277630, 369701],
      [-515, 2398],
      [26, 1888],
    ],
    [
      [277141, 373987],
      [65, 2020],
    ],
    [
      [278581, 376369],
      [-41, -1636],
    ],
    [
      [248064, 372278],
      [-101, -552],
    ],
    [
      [247963, 371726],
      [-1081, 38],
    ],
    [
      [246882, 371764],
      [-315, -16],
      [8, 1713],
    ],
    [
      [163711, 371595],
      [-1656, -4355],
      [43, -1065],
      [-727, -3846],
    ],
    [
      [161371, 362329],
      [-154, 872],
      [-328, -612],
      [-163, 2474],
      [146, 498],
      [-186, 3197],
    ],
    [
      [160686, 368758],
      [3, -8],
    ],
    [
      [239812, 376585],
      [-307, 30],
      [-14, -1889],
    ],
    [
      [239491, 374726],
      [-1074, 471],
    ],
    [
      [237045, 376555],
      [-22, -4864],
    ],
    [
      [237023, 371691],
      [-1517, 258],
    ],
    [
      [235506, 371949],
      [0, 363],
    ],
    [
      [235506, 372312],
      [11, 6776],
    ],
    [
      [266495, 375823],
      [-378, -675],
    ],
    [
      [266117, 375148],
      [-384, 1730],
    ],
    [
      [280011, 373429],
      [-196, -1763],
      [-242, 63],
      [-67, -1661],
    ],
    [
      [279506, 370068],
      [-122, -195],
    ],
    [
      [279384, 369873],
      [-552, 4224],
      [-292, 636],
    ],
    [
      [259094, 374655],
      [-346, -3823],
    ],
    [
      [258748, 370832],
      [-372, -503],
      [-265, 1038],
      [-246, -1075],
    ],
    [
      [257865, 370292],
      [-129, 1809],
    ],
    [
      [257736, 372101],
      [-46, 3371],
    ],
    [
      [232995, 379110],
      [-18, -5688],
    ],
    [
      [232977, 373422],
      [-1212, 29],
    ],
    [
      [234225, 379100],
      [-29, -5681],
    ],
    [
      [234196, 373419],
      [-1219, 3],
    ],
    [
      [235506, 372312],
      [-1310, 27],
    ],
    [
      [234196, 372339],
      [0, 1080],
    ],
    [
      [287953, 369117],
      [-126, 1004],
    ],
    [
      [287827, 370121],
      [-283, 134],
      [320, 3324],
      [379, 1762],
      [191, 2955],
    ],
    [
      [289111, 378680],
      [-116, -2440],
      [-517, -3407],
      [-55, -2436],
      [-470, -1280],
    ],
    [
      [271755, 374466],
      [-438, -677],
      [176, -948],
      [-282, -761],
    ],
    [
      [271211, 372080],
      [-347, 405],
      [-184, -776],
      [-249, 1135],
      [-83, 2839],
      [-218, 2247],
    ],
    [
      [286401, 372837],
      [-192, 2274],
      [-246, 286],
    ],
    [
      [285766, 378696],
      [289, -8],
      [647, -2450],
      [-301, -3401],
    ],
    [
      [242719, 370999],
      [-614, 37],
    ],
    [
      [242105, 371036],
      [2, 817],
      [-459, 1220],
      [12, 2703],
    ],
    [
      [208389, 375000],
      [-289, -1427],
      [-262, -3093],
      [-561, -3167],
      [-509, -171],
      [-616, -1904],
    ],
    [
      [206152, 365238],
      [46, 2010],
      [-135, 4024],
      [-310, 764],
      [-525, -1496],
    ],
    [
      [205228, 370540],
      [79, 3230],
    ],
    [
      [283630, 374477],
      [147, -1396],
      [514, -1038],
      [-44, -591],
    ],
    [
      [284247, 371452],
      [-292, -1688],
    ],
    [
      [283955, 369764],
      [-489, 1286],
      [-105, 1395],
      [-525, 503],
    ],
    [
      [282836, 372948],
      [-465, 418],
    ],
    [
      [281362, 373717],
      [-225, -1081],
    ],
    [
      [281137, 372636],
      [-621, 1277],
      [-80, 700],
    ],
    [
      [281631, 376623],
      [-269, -2906],
    ],
    [
      [260247, 374884],
      [160, -1473],
    ],
    [
      [260407, 373411],
      [-544, -3500],
      [-48, -1933],
    ],
    [
      [259815, 367978],
      [-431, 176],
    ],
    [
      [259384, 368154],
      [-184, 2177],
      [-263, 88],
    ],
    [
      [258937, 370419],
      [-189, 413],
    ],
    [
      [263875, 375552],
      [-250, -1451],
    ],
    [
      [263625, 374101],
      [-126, -753],
      [-407, 1614],
    ],
    [
      [263092, 374962],
      [19, 867],
    ],
    [
      [268954, 376150],
      [-162, -516],
      [84, -2449],
    ],
    [
      [268876, 373185],
      [-340, 667],
      [-495, -733],
    ],
    [
      [268041, 373119],
      [-179, 2658],
    ],
    [
      [219879, 376796],
      [30, -8168],
    ],
    [
      [219909, 368628],
      [-1216, -7],
    ],
    [
      [218693, 368621],
      [1, 4867],
    ],
    [
      [257736, 372101],
      [-514, 1415],
    ],
    [
      [257222, 373516],
      [-85, 1915],
      [-348, 1667],
    ],
    [
      [273601, 375032],
      [187, -2459],
      [-193, 530],
      [-410, -2364],
    ],
    [
      [273185, 370739],
      [-292, -249],
      [-64, -1205],
    ],
    [
      [272829, 369285],
      [-281, 1663],
      [-394, 3474],
      [-139, 91],
    ],
    [
      [261877, 376492],
      [-288, -708],
      [-405, -2399],
    ],
    [
      [261184, 373385],
      [-333, -3287],
    ],
    [
      [260851, 370098],
      [-286, -39],
      [142, 1604],
      [-300, 1748],
    ],
    [
      [271211, 372080],
      [-148, -2078],
    ],
    [
      [271063, 370002],
      [-199, -676],
    ],
    [
      [270864, 369326],
      [-114, 472],
    ],
    [
      [270750, 369798],
      [-437, 240],
      [-582, 3546],
    ],
    [
      [269731, 373584],
      [-227, 2107],
    ],
    [
      [255889, 376513],
      [81, -2236],
      [-378, -1771],
    ],
    [
      [255592, 372506],
      [-241, -671],
    ],
    [
      [255351, 371835],
      [-666, -172],
    ],
    [
      [254685, 371663],
      [-57, 1803],
      [-480, 3101],
    ],
    [
      [285285, 373345],
      [270, -2335],
    ],
    [
      [285555, 371010],
      [-138, -2862],
    ],
    [
      [285417, 368148],
      [-256, 2601],
    ],
    [
      [285161, 370749],
      [-819, 3092],
      [-256, 2540],
    ],
    [
      [199665, 371687],
      [-1364, 1],
    ],
    [
      [198301, 371688],
      [8, 1040],
      [301, 1491],
    ],
    [
      [263092, 374962],
      [-79, -1906],
    ],
    [
      [263013, 373056],
      [-780, -638],
    ],
    [
      [262233, 372418],
      [68, 3271],
      [-83, 690],
    ],
    [
      [201862, 375331],
      [-47, -8024],
    ],
    [
      [201815, 367307],
      [-1165, -220],
      [0, 567],
    ],
    [
      [269731, 373584],
      [-340, -1357],
      [-300, 301],
    ],
    [
      [269091, 372528],
      [-215, 657],
    ],
    [
      [277141, 373987],
      [-397, 706],
      [-568, -2859],
    ],
    [
      [276176, 371834],
      [-345, -947],
      [-213, 581],
    ],
    [
      [275618, 371468],
      [-210, 1192],
    ],
    [
      [250737, 375829],
      [-7, -4869],
    ],
    [
      [250730, 370960],
      [-827, -8],
      [-198, -520],
    ],
    [
      [249705, 370432],
      [27, 2111],
      [-434, 1360],
      [-18, 830],
    ],
    [
      [266117, 375148],
      [-215, -2029],
    ],
    [
      [265902, 373119],
      [-39, -3],
    ],
    [
      [265863, 373116],
      [-510, 788],
      [-270, 1472],
    ],
    [
      [257222, 373516],
      [17, -1185],
      [-615, -2106],
    ],
    [
      [256624, 370225],
      [-1032, 2281],
    ],
    [
      [264865, 375659],
      [-28, -5332],
    ],
    [
      [264837, 370327],
      [-303, -813],
    ],
    [
      [264534, 369514],
      [-414, 301],
    ],
    [
      [264120, 369815],
      [-495, 4286],
    ],
    [
      [253866, 374660],
      [-364, -2527],
      [71, -1661],
    ],
    [
      [253573, 370472],
      [-674, 471],
    ],
    [
      [252899, 370943],
      [2, 5735],
    ],
    [
      [262233, 372418],
      [-14, -886],
    ],
    [
      [262219, 371532],
      [-865, 379],
      [-170, 1474],
    ],
    [
      [221739, 373462],
      [9, -4974],
    ],
    [
      [221748, 368488],
      [-1535, 133],
    ],
    [
      [220213, 368621],
      [-304, 7],
    ],
    [
      [228442, 368634],
      [-1822, -25],
    ],
    [
      [226620, 368609],
      [-1, 4833],
    ],
    [
      [252899, 370943],
      [-103, 7],
    ],
    [
      [252796, 370950],
      [-827, -12],
    ],
    [
      [251969, 370938],
      [6, 4916],
    ],
    [
      [285161, 370749],
      [-468, -117],
    ],
    [
      [284693, 370632],
      [-446, 820],
    ],
    [
      [240417, 376457],
      [5, -7665],
    ],
    [
      [240422, 368792],
      [-608, 47],
      [-4, -1332],
    ],
    [
      [239810, 367507],
      [-305, 42],
    ],
    [
      [239505, 367549],
      [-14, 7177],
    ],
    [
      [160686, 368758],
      [-1094, 44],
      [-338, -404],
    ],
    [
      [159254, 368398],
      [-341, 754],
      [-149, 3106],
      [-463, 2406],
      [48, 1826],
    ],
    [
      [282836, 372948],
      [-67, -2692],
    ],
    [
      [282769, 370256],
      [-815, 2165],
      [-30, -1006],
      [-320, 603],
    ],
    [
      [281604, 372018],
      [-242, 1699],
    ],
    [
      [249705, 370432],
      [0, -110],
    ],
    [
      [249705, 370322],
      [-946, 616],
    ],
    [
      [248759, 370938],
      [-796, -36],
    ],
    [
      [247963, 370902],
      [0, 824],
    ],
    [
      [238263, 375223],
      [35, -4781],
    ],
    [
      [238298, 370442],
      [-1280, 166],
    ],
    [
      [237018, 370608],
      [5, 1083],
    ],
    [
      [166715, 374265],
      [-765, -4053],
      [-188, -2675],
      [-313, 4],
      [-790, -4320],
    ],
    [
      [164659, 363221],
      [-270, 1013],
      [-380, 3367],
      [-298, 3994],
    ],
    [
      [254685, 371663],
      [-553, -2946],
    ],
    [
      [254132, 368717],
      [-234, 1246],
      [-124, -776],
    ],
    [
      [253774, 369187],
      [-201, 1285],
    ],
    [
      [242105, 371036],
      [-9, -2451],
    ],
    [
      [242096, 368585],
      [-1210, 161],
    ],
    [
      [240886, 368746],
      [-464, 46],
    ],
    [
      [198301, 371688],
      [-2633, -143],
      [-352, -2742],
    ],
    [
      [268041, 373119],
      [135, -3677],
      [-93, -602],
    ],
    [
      [268083, 368840],
      [-64, 308],
    ],
    [
      [268019, 369148],
      [-398, 2375],
      [-439, 724],
    ],
    [
      [267182, 372247],
      [-37, 801],
    ],
    [
      [273842, 367755],
      [-4, 20],
    ],
    [
      [273838, 367775],
      [-653, 2964],
    ],
    [
      [274386, 373404],
      [-544, -5649],
    ],
    [
      [251969, 370938],
      [-926, -51],
    ],
    [
      [251043, 370887],
      [-313, 73],
    ],
    [
      [267182, 372247],
      [-389, 564],
      [-373, -1145],
    ],
    [
      [266420, 371666],
      [-518, 1453],
    ],
    [
      [265863, 373116],
      [-284, -991],
      [-227, -2237],
    ],
    [
      [265352, 369888],
      [-515, 439],
    ],
    [
      [286401, 372837],
      [-31, -1676],
      [-479, 915],
      [-228, 2729],
    ],
    [
      [203687, 373713],
      [-2, -6471],
    ],
    [
      [203685, 367242],
      [-1781, 53],
    ],
    [
      [201904, 367295],
      [-89, 12],
    ],
    [
      [158120, 372965],
      [-303, -2],
    ],
    [
      [157817, 372963],
      [-33, 1344],
      [287, 552],
      [49, -1894],
    ],
    [
      [239505, 367549],
      [-1221, 189],
    ],
    [
      [238284, 367738],
      [14, 2704],
    ],
    [
      [264120, 369815],
      [-276, -992],
    ],
    [
      [263844, 368823],
      [-589, 2769],
    ],
    [
      [263255, 371592],
      [-242, 1464],
    ],
    [
      [224788, 373420],
      [0, -4871],
    ],
    [
      [224788, 368549],
      [-1526, -10],
    ],
    [
      [223262, 368539],
      [-2, 4887],
    ],
    [
      [279384, 369873],
      [-416, -2088],
    ],
    [
      [278968, 367785],
      [-186, -655],
    ],
    [
      [278782, 367130],
      [-282, 1313],
    ],
    [
      [278500, 368443],
      [-442, 1074],
      [-256, 1751],
    ],
    [
      [211942, 371760],
      [-29, -11975],
    ],
    [
      [211913, 359785],
      [-2568, -74],
    ],
    [
      [209345, 359711],
      [-3196, -18],
    ],
    [
      [206149, 359693],
      [3, 5545],
    ],
    [
      [277630, 369701],
      [-234, -1501],
      [-213, 613],
      [-430, -1507],
      [-95, -1769],
    ],
    [
      [276658, 365537],
      [-630, 2108],
    ],
    [
      [276028, 367645],
      [291, 2275],
      [-143, 1914],
    ],
    [
      [245159, 372763],
      [-5, -2033],
    ],
    [
      [245154, 370730],
      [-438, 88],
      [-5, -1634],
      [288, -70],
      [-3, -1602],
    ],
    [
      [244996, 367512],
      [-1212, 138],
    ],
    [
      [243784, 367650],
      [-301, 26],
      [0, 3252],
      [-151, 2],
    ],
    [
      [272829, 369285],
      [-248, -1599],
    ],
    [
      [272581, 367686],
      [-815, 1322],
      [-628, 339],
      [-75, 655],
    ],
    [
      [281137, 372636],
      [-25, -1023],
      [-602, -5508],
    ],
    [
      [280510, 366105],
      [-361, 1084],
    ],
    [
      [280149, 367189],
      [-643, 2879],
    ],
    [
      [167514, 370686],
      [-806, -4323],
      [-52, -2727],
      [-160, -1067],
      [-478, -108],
      [49, -890],
      [-436, -1627],
      [-319, -2893],
      [-1552, -1282],
      [-242, 1461],
      [-239, 3370],
    ],
    [
      [163279, 360600],
      [183, 976],
      [522, 1046],
      [675, 599],
    ],
    [
      [285850, 370354],
      [-295, 656],
    ],
    [
      [285474, 374121],
      [422, -2948],
      [651, -1011],
      [-346, -835],
      [-351, 1027],
    ],
    [
      [269091, 372528],
      [-167, -3365],
      [109, -686],
      [-352, -3385],
    ],
    [
      [268681, 365092],
      [-160, 2649],
      [-438, 1099],
    ],
    [
      [205228, 370540],
      [-718, -4116],
    ],
    [
      [204510, 366424],
      [-823, 2],
      [-2, 816],
    ],
    [
      [281604, 372018],
      [-166, -3772],
    ],
    [
      [281438, 368246],
      [-287, -1612],
    ],
    [
      [281151, 366634],
      [-415, -1159],
      [-226, 630],
    ],
    [
      [270750, 369798],
      [-954, -4452],
    ],
    [
      [269796, 365346],
      [-678, -1792],
    ],
    [
      [269118, 363554],
      [-32, -122],
    ],
    [
      [269086, 363432],
      [-466, 1404],
    ],
    [
      [268620, 364836],
      [61, 256],
    ],
    [
      [246882, 371764],
      [-42, -6070],
    ],
    [
      [246840, 365694],
      [-2, -838],
      [-515, -29],
    ],
    [
      [246323, 364827],
      [-104, 1909],
      [11, 4271],
      [-1076, -277],
    ],
    [
      [216257, 373489],
      [3, -6477],
    ],
    [
      [216260, 367012],
      [-86, 2],
    ],
    [
      [216174, 367014],
      [-1351, 13],
    ],
    [
      [214823, 367027],
      [0, 4749],
    ],
    [
      [257865, 370292],
      [-200, -146],
      [146, -3006],
    ],
    [
      [257811, 367140],
      [-547, -1924],
      [-253, -1484],
    ],
    [
      [257011, 363732],
      [-559, 3794],
    ],
    [
      [256452, 367526],
      [172, 2699],
    ],
    [
      [217475, 373489],
      [0, -6490],
    ],
    [
      [217475, 366999],
      [-1215, 13],
    ],
    [
      [218693, 368621],
      [0, -1623],
    ],
    [
      [218693, 366998],
      [-1158, 2],
    ],
    [
      [217535, 367000],
      [-60, -1],
    ],
    [
      [223262, 368539],
      [4, -1605],
    ],
    [
      [223266, 366934],
      [-1483, -59],
    ],
    [
      [221783, 366875],
      [-35, 1613],
    ],
    [
      [226620, 368609],
      [1, -1637],
    ],
    [
      [226621, 366972],
      [-1512, -41],
    ],
    [
      [225109, 366931],
      [-321, 1],
      [0, 1617],
    ],
    [
      [232977, 373422],
      [0, -6492],
    ],
    [
      [232977, 366930],
      [-1214, 49],
    ],
    [
      [231763, 366979],
      [2, 4042],
    ],
    [
      [234196, 372339],
      [0, -5412],
    ],
    [
      [234196, 366927],
      [-1206, 3],
    ],
    [
      [232990, 366930],
      [-13, 0],
    ],
    [
      [260851, 370098],
      [153, -1651],
    ],
    [
      [261004, 368447],
      [-327, 81],
    ],
    [
      [260677, 368528],
      [-206, -892],
    ],
    [
      [260471, 367636],
      [-656, 342],
    ],
    [
      [262219, 371532],
      [-27, -1601],
    ],
    [
      [262192, 369931],
      [-95, -2448],
    ],
    [
      [262097, 367483],
      [-273, 950],
      [-278, -893],
      [-215, 1025],
      [-327, -118],
    ],
    [
      [275618, 371468],
      [-300, -1181],
      [80, -1044],
      [-490, -1589],
    ],
    [
      [274908, 367654],
      [-100, 1094],
      [-720, -2036],
      [-246, 1043],
    ],
    [
      [266420, 371666],
      [-159, -2460],
    ],
    [
      [266261, 369206],
      [-144, 586],
      [-553, -948],
      [-155, 568],
    ],
    [
      [265409, 369412],
      [-57, 476],
    ],
    [
      [263255, 371592],
      [-527, -1618],
    ],
    [
      [262728, 369974],
      [-536, -43],
    ],
    [
      [283955, 369764],
      [143, -836],
    ],
    [
      [284098, 368928],
      [-199, -2026],
    ],
    [
      [283899, 366902],
      [-182, 62],
    ],
    [
      [283717, 366964],
      [-298, 1139],
    ],
    [
      [283419, 368103],
      [99, 1639],
      [-146, 1185],
    ],
    [
      [283372, 370927],
      [-246, -568],
    ],
    [
      [283126, 370359],
      [-198, -214],
    ],
    [
      [282928, 370145],
      [-159, 111],
    ],
    [
      [158120, 372965],
      [96, -2153],
      [271, -374],
      [409, -1981],
    ],
    [
      [158896, 368457],
      [-209, -644],
      [106, -2702],
    ],
    [
      [158793, 365111],
      [-4, -1304],
      [-465, -535],
      [78, -1485],
    ],
    [
      [158402, 361787],
      [-314, 1660],
      [11, 3040],
      [-319, 2976],
      [37, 3500],
    ],
    [
      [268019, 369148],
      [-408, 283],
      [-84, -2105],
    ],
    [
      [267527, 367326],
      [-252, 702],
      [-463, -1950],
      [-186, 747],
      [-277, -816],
    ],
    [
      [266349, 366009],
      [-88, 3197],
    ],
    [
      [282769, 370256],
      [-560, -2687],
    ],
    [
      [282209, 367569],
      [-433, 1404],
      [-338, -727],
    ],
    [
      [256452, 367526],
      [-546, -453],
    ],
    [
      [255906, 367073],
      [-158, 651],
      [-62, 2671],
    ],
    [
      [255686, 370395],
      [-335, 1440],
    ],
    [
      [235506, 371949],
      [0, -5388],
    ],
    [
      [235506, 366561],
      [0, -480],
    ],
    [
      [235506, 366081],
      [-1273, 28],
    ],
    [
      [234233, 366109],
      [-37, 818],
    ],
    [
      [276028, 367645],
      [-525, -1521],
    ],
    [
      [275503, 366124],
      [-183, 485],
      [-294, -965],
    ],
    [
      [275026, 365644],
      [-118, 2010],
    ],
    [
      [206149, 359693],
      [-184, -2],
    ],
    [
      [205965, 359691],
      [-1385, 13],
    ],
    [
      [204580, 359704],
      [-148, 938],
      [143, 4466],
      [-65, 1316],
    ],
    [
      [237018, 370608],
      [-19, -4325],
    ],
    [
      [236999, 366283],
      [-1493, 278],
    ],
    [
      [255686, 370395],
      [-1221, -4085],
    ],
    [
      [254465, 366310],
      [33, 525],
    ],
    [
      [254498, 366835],
      [-359, 563],
      [-7, 1319],
    ],
    [
      [247963, 370902],
      [3, -5312],
      [-200, 55],
    ],
    [
      [247766, 365645],
      [-926, 49],
    ],
    [
      [214823, 367027],
      [-1, -7366],
    ],
    [
      [214822, 359661],
      [-2676, 121],
    ],
    [
      [212146, 359782],
      [-233, 3],
    ],
    [
      [198301, 371688],
      [-141, -896],
      [-30, -2362],
      [-478, -2240],
      [-241, -2267],
      [2, -1425],
      [-247, -2726],
    ],
    [
      [197166, 359772],
      [-1855, -8],
    ],
    [
      [199666, 367649],
      [1, -7868],
    ],
    [
      [199667, 359781],
      [-2501, -9],
    ],
    [
      [163279, 360600],
      [-1049, -5634],
    ],
    [
      [162230, 354966],
      [-621, 1774],
      [-205, 2320],
    ],
    [
      [161404, 359060],
      [-33, 3269],
    ],
    [
      [263844, 368823],
      [-156, -2927],
    ],
    [
      [263688, 365896],
      [-150, 509],
      [-457, -2191],
    ],
    [
      [263081, 364214],
      [-5, 2272],
      [-355, 1236],
      [7, 2252],
    ],
    [
      [284693, 370632],
      [402, -618],
      [175, -1717],
    ],
    [
      [285270, 368297],
      [-384, -511],
      [-28, -974],
    ],
    [
      [284858, 366812],
      [-760, 2116],
    ],
    [
      [259384, 368154],
      [-65, -2032],
      [-241, -92],
    ],
    [
      [259078, 366030],
      [-868, -273],
    ],
    [
      [258210, 365757],
      [-399, 1383],
    ],
    [
      [278500, 368443],
      [-229, -637],
      [32, -1405],
    ],
    [
      [278303, 366401],
      [-518, -5580],
    ],
    [
      [277785, 360821],
      [-108, -926],
      [-313, 672],
    ],
    [
      [277364, 360567],
      [-363, 2794],
      [-342, 617],
    ],
    [
      [276659, 363978],
      [-1, 1559],
    ],
    [
      [184567, 369085],
      [6, -9298],
    ],
    [
      [184573, 359787],
      [-3209, 1],
    ],
    [
      [231763, 366979],
      [-8, -1628],
    ],
    [
      [231755, 365351],
      [-1561, 66],
    ],
    [
      [230194, 365417],
      [-3, 3233],
    ],
    [
      [246323, 364827],
      [-123, -4110],
    ],
    [
      [246200, 360717],
      [-515, 62],
      [-148, 783],
    ],
    [
      [245537, 361562],
      [-47, 1372],
      [-348, 1595],
      [-44, 2975],
      [-102, 8],
    ],
    [
      [249705, 370322],
      [275, -3108],
      [-171, -1185],
    ],
    [
      [249809, 366029],
      [-13, -1567],
    ],
    [
      [249796, 364462],
      [-367, -344],
      [-402, -1991],
    ],
    [
      [249027, 362127],
      [-283, 3],
    ],
    [
      [248744, 362130],
      [15, 8808],
    ],
    [
      [243784, 367650],
      [-24, -6958],
    ],
    [
      [243760, 360692],
      [-1208, 136],
    ],
    [
      [242552, 360828],
      [-452, 64],
    ],
    [
      [242100, 360892],
      [-4, 7693],
    ],
    [
      [253774, 369187],
      [-63, -598],
      [-543, -370],
      [-221, -906],
    ],
    [
      [252947, 367313],
      [-159, 350],
    ],
    [
      [252788, 367663],
      [8, 3287],
    ],
    [
      [252788, 367663],
      [-169, -665],
      [-98, -2358],
      [240, -2056],
      [-183, -1537],
    ],
    [
      [252578, 361047],
      [0, 1701],
      [-613, 3308],
    ],
    [
      [251965, 366056],
      [4, 4882],
    ],
    [
      [283419, 368103],
      [-491, 2042],
    ],
    [
      [251965, 366056],
      [-546, -28],
      [-64, -613],
    ],
    [
      [251355, 365415],
      [-321, 507],
    ],
    [
      [251034, 365922],
      [9, 4965],
    ],
    [
      [251034, 365922],
      [-568, 97],
    ],
    [
      [250466, 366019],
      [-657, 10],
    ],
    [
      [285850, 370354],
      [308, -1052],
    ],
    [
      [286158, 369302],
      [-18, -1124],
    ],
    [
      [286140, 368178],
      [152, -3489],
      [-321, -310],
      [-554, 3769],
    ],
    [
      [248744, 362130],
      [-259, 20],
      [1, -1356],
      [-425, -246],
    ],
    [
      [248061, 360548],
      [-311, 844],
      [16, 4253],
    ],
    [
      [273838, 367775],
      [29, -1536],
      [-368, -994],
    ],
    [
      [273499, 365245],
      [-679, -1091],
    ],
    [
      [272820, 364154],
      [-382, 1913],
    ],
    [
      [272438, 366067],
      [143, 1619],
    ],
    [
      [168203, 368402],
      [-33, -2228],
      [201, -311],
      [166, -3441],
      [607, -1527],
      [212, -3239],
      [-22, -2583],
    ],
    [
      [169334, 355073],
      [-1716, -82],
      [-1, -1560],
      [-890, 26],
      [-3, -1596],
      [-460, 19],
      [-298, -1620],
    ],
    [
      [165966, 350260],
      [-246, -1296],
      [-814, -375],
      [-3, -4045],
      [-992, -5108],
    ],
    [
      [163911, 339436],
      [-928, 3750],
      [56, 1793],
      [-140, 1162],
    ],
    [
      [162899, 346141],
      [229, 1144],
      [-1, 2966],
      [-897, 4715],
    ],
    [
      [238284, 367738],
      [-14, -2707],
    ],
    [
      [238270, 365031],
      [-1188, 150],
    ],
    [
      [237082, 365181],
      [-83, 1102],
    ],
    [
      [265409, 369412],
      [279, -3141],
    ],
    [
      [265688, 366271],
      [-442, -1825],
    ],
    [
      [265246, 364446],
      [-535, 1287],
    ],
    [
      [264711, 365733],
      [-177, 3781],
    ],
    [
      [255906, 367073],
      [-251, -1812],
      [146, -2556],
    ],
    [
      [255801, 362705],
      [-529, -954],
      [-438, 809],
    ],
    [
      [254834, 362560],
      [-346, 1711],
      [-23, 2039],
    ],
    [
      [283717, 366964],
      [-7, -1436],
    ],
    [
      [283710, 365528],
      [-51, 69],
    ],
    [
      [283659, 365597],
      [-117, -1255],
    ],
    [
      [283542, 364342],
      [-75, -143],
    ],
    [
      [283467, 364199],
      [-36, -77],
    ],
    [
      [283431, 364122],
      [-87, -195],
    ],
    [
      [283344, 363927],
      [-565, 790],
    ],
    [
      [282779, 364717],
      [-265, 34],
      [-366, 1828],
      [61, 990],
    ],
    [
      [280149, 367189],
      [-243, -2778],
    ],
    [
      [279906, 364411],
      [-397, -807],
    ],
    [
      [279509, 363604],
      [-259, 695],
      [-282, 3486],
    ],
    [
      [287953, 369117],
      [-346, -3597],
      [-150, -3563],
      [-151, 2650],
      [266, 5441],
      [255, 73],
    ],
    [
      [263081, 364214],
      [-502, -2273],
    ],
    [
      [262579, 361941],
      [-398, 1304],
    ],
    [
      [262181, 363245],
      [-37, 1322],
      [-302, 999],
    ],
    [
      [261842, 365566],
      [255, 1917],
    ],
    [
      [272438, 366067],
      [-555, -2439],
      [-498, 578],
    ],
    [
      [271385, 364206],
      [-316, 930],
      [-383, 3657],
      [178, 533],
    ],
    [
      [254498, 366835],
      [-802, -2923],
      [-19, -787],
    ],
    [
      [253677, 363125],
      [-271, -607],
    ],
    [
      [253406, 362518],
      [-71, 2344],
      [-388, 2451],
    ],
    [
      [188715, 359808],
      [-3142, -15],
    ],
    [
      [185573, 359793],
      [-1000, -6],
    ],
    [
      [266349, 366009],
      [57, -1412],
    ],
    [
      [266406, 364597],
      [-349, 1839],
      [-369, -165],
    ],
    [
      [264711, 365733],
      [-224, -683],
      [-203, -2441],
    ],
    [
      [264284, 362609],
      [-596, 3287],
    ],
    [
      [271385, 364206],
      [-448, -1771],
    ],
    [
      [270937, 362435],
      [-326, -1378],
      [-364, -500],
    ],
    [
      [270247, 360557],
      [-283, 4288],
      [-168, 501],
    ],
    [
      [286158, 369302],
      [207, 215],
      [310, -1575],
      [-73, -2372],
      [-462, 2608],
    ],
    [
      [268620, 364836],
      [-243, -50],
      [-502, -1356],
    ],
    [
      [267875, 363430],
      [-354, 1381],
      [6, 2515],
    ],
    [
      [282779, 364717],
      [-405, -1350],
    ],
    [
      [282374, 363367],
      [-1213, 1929],
    ],
    [
      [281161, 365296],
      [-10, 1338],
    ],
    [
      [284858, 366812],
      [77, -164],
    ],
    [
      [284935, 366648],
      [-7, -2001],
      [-380, 948],
      [-673, 137],
      [24, 1170],
    ],
    [
      [240886, 368746],
      [3, -7717],
    ],
    [
      [240889, 361029],
      [-605, 67],
    ],
    [
      [240284, 361096],
      [-452, 335],
    ],
    [
      [239832, 361431],
      [-22, 6076],
    ],
    [
      [161404, 359060],
      [-763, 407],
      [-257, -1537],
    ],
    [
      [160384, 357930],
      [-486, 2766],
      [-658, 1780],
      [-447, 2635],
    ],
    [
      [158896, 368457],
      [358, -59],
    ],
    [
      [261842, 365566],
      [-523, -2202],
    ],
    [
      [261319, 363364],
      [-642, 5164],
    ],
    [
      [242100, 360892],
      [-1211, 137],
    ],
    [
      [275026, 365644],
      [-484, -1303],
    ],
    [
      [274542, 364341],
      [-690, -1814],
    ],
    [
      [273852, 362527],
      [-445, 2380],
      [92, 338],
    ],
    [
      [230194, 365417],
      [-2, -5660],
    ],
    [
      [230192, 359757],
      [-625, 5],
    ],
    [
      [229567, 359762],
      [-1109, 1],
    ],
    [
      [228458, 359763],
      [-16, 8871],
    ],
    [
      [228458, 359763],
      [-876, -6],
    ],
    [
      [227582, 359757],
      [-948, 0],
    ],
    [
      [226634, 359757],
      [-13, 7215],
    ],
    [
      [220213, 368621],
      [49, -8799],
    ],
    [
      [220262, 359822],
      [-1515, -40],
    ],
    [
      [218747, 359782],
      [-2, 7215],
      [-52, 1],
    ],
    [
      [221783, 366875],
      [7, -7101],
    ],
    [
      [221790, 359774],
      [-1286, 38],
    ],
    [
      [220504, 359812],
      [-242, 10],
    ],
    [
      [225109, 366931],
      [7, -7184],
    ],
    [
      [225116, 359747],
      [-550, 11],
    ],
    [
      [224566, 359758],
      [-1269, 13],
    ],
    [
      [223297, 359771],
      [-31, 7163],
    ],
    [
      [261319, 363364],
      [-484, -1545],
    ],
    [
      [260835, 361819],
      [-445, 1353],
    ],
    [
      [260390, 363172],
      [-31, 2226],
      [112, 2238],
    ],
    [
      [278782, 367130],
      [-479, -729],
    ],
    [
      [176230, 341181],
      [676, -3535],
    ],
    [
      [176906, 337646],
      [-237, -293],
      [-2131, -6],
      [-3163, 16],
    ],
    [
      [171375, 337363],
      [-1036, -168],
    ],
    [
      [170339, 337195],
      [74, 1516],
      [-404, 8051],
      [81, 566],
      [-423, 2802],
      [-72, 2164],
      [-261, 2779],
    ],
    [
      [285483, 364729],
      [247, -950],
    ],
    [
      [285730, 363779],
      [-49, -868],
    ],
    [
      [285681, 362911],
      [-94, 912],
      [-633, 479],
      [-19, 2346],
    ],
    [
      [285270, 368297],
      [233, -1659],
    ],
    [
      [285503, 366638],
      [-153, -1180],
    ],
    [
      [285350, 365458],
      [133, -729],
    ],
    [
      [260390, 363172],
      [-160, -225],
    ],
    [
      [260230, 362947],
      [-453, -403],
      [-418, 352],
    ],
    [
      [259359, 362896],
      [21, 897],
      [-302, 2237],
    ],
    [
      [267875, 363430],
      [-170, -1501],
      [19, -1797],
      [-189, -331],
    ],
    [
      [267535, 359801],
      [-167, 368],
    ],
    [
      [267368, 360169],
      [-60, 2882],
      [-338, 936],
      [-80, 1342],
      [-349, -222],
      [-82, -931],
    ],
    [
      [266459, 364176],
      [-53, 421],
    ],
    [
      [279509, 363604],
      [-224, -3408],
    ],
    [
      [279285, 360196],
      [-519, 775],
    ],
    [
      [278766, 360971],
      [-292, -175],
      [-159, 1424],
      [-530, -1399],
    ],
    [
      [239832, 361431],
      [-1514, 177],
    ],
    [
      [238318, 361608],
      [-48, 3423],
    ],
    [
      [253406, 362518],
      [-128, -3094],
    ],
    [
      [253278, 359424],
      [-344, 1547],
      [-334, -766],
    ],
    [
      [252600, 360205],
      [-233, 1071],
    ],
    [
      [252367, 361276],
      [211, -229],
    ],
    [
      [245537, 361562],
      [-556, -176],
      [-16, -3767],
    ],
    [
      [244965, 357619],
      [-1212, 97],
    ],
    [
      [243753, 357716],
      [7, 2976],
    ],
    [
      [201904, 367295],
      [0, -3259],
      [564, -4371],
    ],
    [
      [202468, 359665],
      [-2632, 116],
    ],
    [
      [199836, 359781],
      [-169, 0],
    ],
    [
      [276659, 363978],
      [-321, -1646],
      [-128, 663],
      [-334, -926],
    ],
    [
      [275876, 362069],
      [-139, -177],
    ],
    [
      [275737, 361892],
      [-37, 2241],
      [-197, 1991],
    ],
    [
      [276171, 365518],
      [-31, -845],
    ],
    [
      [276140, 364673],
      [211, -935],
      [217, 1690],
      [-397, 90],
    ],
    [
      [257011, 363732],
      [-118, -2665],
    ],
    [
      [256893, 361067],
      [-311, -151],
    ],
    [
      [256582, 360916],
      [-548, -371],
      [-28, 565],
    ],
    [
      [256006, 361110],
      [-205, 1595],
    ],
    [
      [281161, 365296],
      [-22, -3279],
    ],
    [
      [281139, 362017],
      [-569, -758],
    ],
    [
      [280570, 361259],
      [-694, 2327],
      [30, 825],
    ],
    [
      [204580, 359704],
      [-804, -10],
    ],
    [
      [203776, 359694],
      [-1308, -29],
    ],
    [
      [258405, 362945],
      [-625, 205],
      [-142, -3375],
    ],
    [
      [257638, 359775],
      [-745, 1292],
    ],
    [
      [258210, 365757],
      [195, -2812],
    ],
    [
      [216174, 367014],
      [4, -7320],
    ],
    [
      [216178, 359694],
      [-1317, -41],
    ],
    [
      [214861, 359653],
      [-39, 8],
    ],
    [
      [217535, 367000],
      [4, -7258],
    ],
    [
      [217539, 359742],
      [-1361, -48],
    ],
    [
      [218747, 359782],
      [-870, -30],
    ],
    [
      [217877, 359752],
      [-338, -10],
    ],
    [
      [232990, 366930],
      [-4, -7162],
    ],
    [
      [232986, 359768],
      [-736, 0],
    ],
    [
      [232250, 359768],
      [-495, -4],
    ],
    [
      [231755, 359764],
      [0, 5587],
    ],
    [
      [226634, 359757],
      [-863, -11],
    ],
    [
      [225771, 359746],
      [-655, 1],
    ],
    [
      [223297, 359771],
      [-1270, 0],
    ],
    [
      [222027, 359771],
      [-237, 3],
    ],
    [
      [234233, 366109],
      [3, -6336],
    ],
    [
      [234236, 359773],
      [-930, -5],
    ],
    [
      [233306, 359768],
      [-320, 0],
    ],
    [
      [254834, 362560],
      [-147, -2742],
    ],
    [
      [254687, 359818],
      [-400, -772],
    ],
    [
      [254287, 359046],
      [-461, 3988],
      [-149, 91],
    ],
    [
      [285503, 366638],
      [154, -1165],
      [635, -2093],
      [-66, -1012],
    ],
    [
      [286226, 362368],
      [53, -609],
    ],
    [
      [286279, 361759],
      [-115, -211],
    ],
    [
      [286164, 361548],
      [-434, 2231],
    ],
    [
      [285483, 364729],
      [-133, 729],
    ],
    [
      [237082, 365181],
      [-19, -4504],
    ],
    [
      [237063, 360677],
      [-1557, 162],
    ],
    [
      [235506, 360839],
      [0, 5242],
    ],
    [
      [275737, 361892],
      [-473, -1709],
      [-546, -689],
    ],
    [
      [274718, 359494],
      [-101, 1954],
    ],
    [
      [274617, 361448],
      [153, 1099],
    ],
    [
      [274770, 362547],
      [-228, 1794],
    ],
    [
      [266459, 364176],
      [-121, -2911],
      [119, -2630],
    ],
    [
      [266457, 358635],
      [-224, 322],
    ],
    [
      [266233, 358957],
      [-784, 1845],
    ],
    [
      [265449, 360802],
      [-274, 2103],
      [71, 1541],
    ],
    [
      [264091, 359038],
      [-613, -1731],
    ],
    [
      [263478, 357307],
      [-281, 2109],
      [-435, 319],
    ],
    [
      [262762, 359735],
      [-183, 2206],
    ],
    [
      [264284, 362609],
      [-193, -3571],
    ],
    [
      [235506, 360839],
      [0, -1078],
    ],
    [
      [235506, 359761],
      [-1085, 11],
    ],
    [
      [234421, 359772],
      [-185, 1],
    ],
    [
      [259359, 362896],
      [-159, -1977],
    ],
    [
      [259200, 360919],
      [-468, 373],
      [-327, 1653],
    ],
    [
      [276140, 364673],
      [31, 845],
    ],
    [
      [252367, 361276],
      [-1008, 2722],
    ],
    [
      [251359, 363998],
      [-4, 1417],
    ],
    [
      [272820, 364154],
      [-287, -1786],
      [161, -862],
      [-535, -1063],
      [70, -474],
    ],
    [
      [272229, 359969],
      [-685, -1435],
      [-271, 477],
    ],
    [
      [271273, 359011],
      [-336, 3424],
    ],
    [
      [250466, 366019],
      [-48, -4180],
      [274, -850],
    ],
    [
      [250692, 360989],
      [92, -1564],
    ],
    [
      [250784, 359425],
      [-347, 1556],
      [-155, -1005],
    ],
    [
      [250282, 359976],
      [-177, 539],
      [-309, 3947],
    ],
    [
      [265449, 360802],
      [-249, -1448],
      [-375, -410],
    ],
    [
      [264825, 358944],
      [-564, -169],
    ],
    [
      [264261, 358775],
      [-170, 263],
    ],
    [
      [251359, 363998],
      [-15, -29],
    ],
    [
      [251344, 363969],
      [-427, -1199],
      [-225, -1781],
    ],
    [
      [267368, 360169],
      [-217, -969],
      [-641, -1367],
    ],
    [
      [266510, 357833],
      [-53, 802],
    ],
    [
      [248061, 360548],
      [-409, -2218],
    ],
    [
      [247652, 358330],
      [-1170, 82],
    ],
    [
      [246482, 358412],
      [-282, 2305],
    ],
    [
      [283884, 365227],
      [441, 265],
      [239, -1371],
    ],
    [
      [284564, 364121],
      [-405, -2255],
    ],
    [
      [284159, 361866],
      [-678, -2215],
    ],
    [
      [283481, 359651],
      [-2, 3311],
    ],
    [
      [283479, 362962],
      [63, 1380],
    ],
    [
      [283659, 365597],
      [225, -370],
    ],
    [
      [262181, 363245],
      [-452, -3529],
      [-71, -1327],
    ],
    [
      [261658, 358389],
      [-614, 240],
    ],
    [
      [261044, 358629],
      [-209, 3190],
    ],
    [
      [270247, 360557],
      [-500, -1318],
    ],
    [
      [269747, 359239],
      [-387, 951],
      [-242, 3364],
    ],
    [
      [231755, 359764],
      [-101, -4],
    ],
    [
      [231654, 359760],
      [-1462, -3],
    ],
    [
      [273852, 362527],
      [-162, -1380],
    ],
    [
      [273690, 361147],
      [-561, -945],
      [-310, 462],
      [-429, -1770],
    ],
    [
      [272390, 358894],
      [-161, 1075],
    ],
    [
      [282112, 359572],
      [-317, 633],
    ],
    [
      [281795, 360205],
      [-220, -114],
      [-436, 1926],
    ],
    [
      [282374, 363367],
      [-290, -906],
      [28, -2889],
    ],
    [
      [238318, 361608],
      [-4, -1905],
    ],
    [
      [238314, 359703],
      [-2, -1358],
    ],
    [
      [238312, 358345],
      [-1259, 166],
    ],
    [
      [237053, 358511],
      [10, 2166],
    ],
    [
      [160384, 357930],
      [-174, -102],
    ],
    [
      [160210, 357828],
      [-281, 264],
      [-164, -998],
    ],
    [
      [159765, 357094],
      [-379, 2271],
      [-464, -404],
      [-520, 2826],
    ],
    [
      [283344, 363927],
      [135, -965],
    ],
    [
      [283481, 359651],
      [-614, -2145],
    ],
    [
      [282867, 357506],
      [-109, 311],
    ],
    [
      [282758, 357817],
      [-299, 1492],
      [-347, 263],
    ],
    [
      [269086, 363432],
      [-450, -1508],
      [10, -1311],
      [-407, -1312],
    ],
    [
      [268239, 359301],
      [-193, 764],
      [-584, -1127],
      [73, 863],
    ],
    [
      [250282, 359976],
      [-572, -2622],
    ],
    [
      [249710, 357354],
      [-479, -188],
    ],
    [
      [249231, 357166],
      [11, 1267],
      [-274, 2900],
      [59, 794],
    ],
    [
      [280570, 361259],
      [-138, -3504],
    ],
    [
      [280432, 357755],
      [-433, -3598],
    ],
    [
      [279999, 354157],
      [-48, 2703],
      [-666, 3336],
    ],
    [
      [274770, 362547],
      [-153, -1099],
    ],
    [
      [274718, 359494],
      [-251, -983],
    ],
    [
      [274467, 358511],
      [-297, -983],
    ],
    [
      [274170, 357528],
      [-480, 3619],
    ],
    [
      [285497, 362381],
      [-63, -1487],
      [-424, -1183],
    ],
    [
      [285010, 359711],
      [-289, -964],
    ],
    [
      [284721, 358747],
      [-34, 2055],
      [-528, 1064],
    ],
    [
      [284564, 364121],
      [581, -507],
      [352, -1233],
    ],
    [
      [277364, 360567],
      [-130, -3464],
    ],
    [
      [277234, 357103],
      [-337, -1260],
      [-663, 787],
      [-116, -677],
    ],
    [
      [276118, 355953],
      [-70, 868],
      [-471, 580],
    ],
    [
      [275577, 357401],
      [360, 2850],
      [-61, 1818],
    ],
    [
      [251671, 358926],
      [-486, -648],
      [-308, 443],
    ],
    [
      [250877, 358721],
      [-93, 704],
    ],
    [
      [251344, 363969],
      [327, -5043],
    ],
    [
      [252600, 360205],
      [-4, -1497],
    ],
    [
      [252596, 358708],
      [-916, 78],
    ],
    [
      [251680, 358786],
      [-9, 140],
    ],
    [
      [286164, 361548],
      [133, -1960],
    ],
    [
      [286297, 359588],
      [-664, 2557],
      [48, 766],
    ],
    [
      [269747, 359239],
      [82, -1209],
      [-301, -552],
    ],
    [
      [269528, 357478],
      [-635, 40],
      [-381, -1462],
    ],
    [
      [268512, 356056],
      [-298, 1723],
    ],
    [
      [268214, 357779],
      [25, 1522],
    ],
    [
      [259200, 360919],
      [-148, -2366],
    ],
    [
      [259052, 358553],
      [-666, -2939],
    ],
    [
      [258386, 355614],
      [14, 578],
      [-508, 429],
      [-79, 979],
    ],
    [
      [257813, 357600],
      [-175, 2175],
    ],
    [
      [262762, 359735],
      [-638, -2584],
    ],
    [
      [262124, 357151],
      [-421, -78],
    ],
    [
      [261703, 357073],
      [-45, 1316],
    ],
    [
      [261044, 358629],
      [-402, -2234],
    ],
    [
      [260642, 356395],
      [-399, 432],
    ],
    [
      [260243, 356827],
      [-13, 6120],
    ],
    [
      [254287, 359046],
      [-781, -1739],
    ],
    [
      [253506, 357307],
      [-228, 2117],
    ],
    [
      [260243, 356827],
      [-662, -2212],
    ],
    [
      [259581, 354615],
      [-529, 3938],
    ],
    [
      [256006, 361110],
      [-211, -8003],
    ],
    [
      [255795, 353107],
      [-851, -64],
    ],
    [
      [254944, 353043],
      [-147, -18],
    ],
    [
      [254797, 353025],
      [95, 6137],
      [-205, 656],
    ],
    [
      [285916, 358061],
      [-1039, -4907],
    ],
    [
      [284877, 353154],
      [-34, 77],
    ],
    [
      [284843, 353231],
      [-42, 1109],
    ],
    [
      [284801, 354340],
      [305, 3818],
      [-96, 1553],
    ],
    [
      [285497, 362381],
      [59, -1873],
      [467, -1557],
      [-107, -890],
    ],
    [
      [271273, 359011],
      [-157, -551],
    ],
    [
      [271116, 358460],
      [-308, -1199],
      [-364, -179],
      [-73, -1054],
      [-635, -1658],
    ],
    [
      [269736, 354370],
      [-208, 3108],
    ],
    [
      [278766, 360971],
      [-356, -9724],
    ],
    [
      [278410, 351247],
      [-345, -4],
    ],
    [
      [278065, 351243],
      [106, 760],
      [-263, 1161],
      [-285, -493],
      [86, -1438],
    ],
    [
      [277709, 351233],
      [-112, -1],
    ],
    [
      [277597, 351232],
      [-569, 21],
    ],
    [
      [277028, 351253],
      [206, 5850],
    ],
    [
      [275577, 357401],
      [-419, -1374],
      [-218, -1669],
    ],
    [
      [274940, 354358],
      [-473, 4153],
    ],
    [
      [249231, 357166],
      [-25, -4320],
      [-720, 33],
    ],
    [
      [248486, 352879],
      [-527, 25],
    ],
    [
      [247959, 352904],
      [-9, 1582],
      [-298, 3844],
    ],
    [
      [281795, 360205],
      [-66, -4560],
    ],
    [
      [281729, 355645],
      [-596, 561],
      [-701, 1549],
    ],
    [
      [286315, 361864],
      [-36, -105],
    ],
    [
      [286315, 361864],
      [307, -509],
      [-325, -1767],
    ],
    [
      [284721, 358747],
      [-1328, -4408],
    ],
    [
      [283393, 354339],
      [-167, 252],
      [88, 2639],
      [-447, 276],
    ],
    [
      [246482, 358412],
      [44, -2141],
    ],
    [
      [246526, 356271],
      [-1263, 227],
    ],
    [
      [245263, 356498],
      [-303, 38],
      [5, 1083],
    ],
    [
      [240284, 361096],
      [-17, -4867],
    ],
    [
      [240267, 356229],
      [-1101, 142],
    ],
    [
      [239166, 356371],
      [-106, 8],
      [13, 3262],
      [-759, 62],
    ],
    [
      [274170, 357528],
      [-841, -1351],
    ],
    [
      [273329, 356177],
      [-156, -696],
      [-455, -110],
    ],
    [
      [272718, 355371],
      [-328, 3523],
    ],
    [
      [257813, 357600],
      [-221, -728],
      [-203, -3628],
    ],
    [
      [257389, 353244],
      [-828, -107],
    ],
    [
      [256561, 353137],
      [21, 7779],
    ],
    [
      [256561, 353137],
      [-151, -14],
    ],
    [
      [256410, 353123],
      [-615, -16],
    ],
    [
      [242552, 360828],
      [-65, -4878],
    ],
    [
      [242487, 355950],
      [-1819, 221],
    ],
    [
      [240668, 356171],
      [-401, 58],
    ],
    [
      [253506, 357307],
      [130, -2237],
    ],
    [
      [253636, 355070],
      [-1045, 64],
    ],
    [
      [252591, 355134],
      [5, 3574],
    ],
    [
      [250877, 358721],
      [-61, -2945],
    ],
    [
      [250816, 355776],
      [-215, -1258],
      [64, -1202],
    ],
    [
      [250665, 353316],
      [-204, -1647],
      [-215, 1259],
    ],
    [
      [250246, 352928],
      [-536, 4426],
    ],
    [
      [279999, 354157],
      [-151, -412],
      [-88, -2492],
    ],
    [
      [279760, 351253],
      [-173, -5],
    ],
    [
      [279587, 351248],
      [-953, 1],
    ],
    [
      [278634, 351249],
      [-224, -2],
    ],
    [
      [243753, 357716],
      [-40, -7253],
    ],
    [
      [243713, 350463],
      [-1248, -11],
    ],
    [
      [242465, 350452],
      [22, 5498],
    ],
    [
      [237053, 358511],
      [-17, -3425],
    ],
    [
      [237036, 355086],
      [-1531, 348],
    ],
    [
      [235505, 355434],
      [1, 4327],
    ],
    [
      [266233, 358957],
      [17, -976],
      [-599, -2205],
      [-221, -1818],
    ],
    [
      [265430, 353958],
      [-521, 3091],
      [-84, 1895],
    ],
    [
      [282758, 357817],
      [6, -3369],
      [-310, -3129],
    ],
    [
      [282454, 351319],
      [-369, -16],
    ],
    [
      [282085, 351303],
      [-408, -9],
    ],
    [
      [281677, 351294],
      [52, 4351],
    ],
    [
      [268214, 357779],
      [-540, -708],
      [-177, -2081],
      [-906, -1448],
    ],
    [
      [266591, 353542],
      [-141, 2662],
      [60, 1629],
    ],
    [
      [272718, 355371],
      [-733, -1028],
      [-226, -1288],
    ],
    [
      [271759, 353055],
      [-261, 2813],
      [-382, 2592],
    ],
    [
      [192649, 359743],
      [0, -24860],
      [483, -3],
      [-46, -2691],
      [-3, -12919],
      [-57, -4902],
      [46, -1624],
      [-29, -12941],
      [-91, -9],
      [0, -3908],
    ],
    [
      [192952, 295886],
      [-303, 169],
    ],
    [
      [192649, 296055],
      [0, 7877],
      [-2090, 0],
      [0, 4900],
    ],
    [
      [190559, 308832],
      [0, 51008],
    ],
    [
      [190559, 308832],
      [-267, 69],
      [-549, 2097],
      [-586, 1352],
      [-625, -723],
      [-214, 1094],
    ],
    [
      [188318, 312721],
      [8, 6150],
      [-629, 15],
      [-1, 3313],
      [-1550, -154],
      [-3, 3263],
      [-297, 21],
      [4, 2068],
      [-380, -319],
      [-149, 1054],
      [-899, 1045],
      [-616, 2799],
      [-445, 401],
    ],
    [
      [183361, 332377],
      [1, 5120],
      [66, 1656],
      [-122, 2775],
      [363, 944],
      [70, 1118],
      [518, 1618],
      [615, 865],
      [469, 2332],
      [-89, 1927],
      [91, 1455],
      [24, 3834],
      [237, 2874],
      [-31, 898],
    ],
    [
      [254797, 353025],
      [-1049, 763],
    ],
    [
      [253748, 353788],
      [-112, 1282],
    ],
    [
      [233306, 359768],
      [-67, -1052],
      [1, -6427],
    ],
    [
      [233240, 352289],
      [-1054, -2],
    ],
    [
      [232186, 352287],
      [-1, 6412],
      [65, 1069],
    ],
    [
      [231654, 359760],
      [-1, -10708],
    ],
    [
      [231653, 349052],
      [-2, -4884],
      [-743, 12],
    ],
    [
      [230908, 344180],
      [41, 1148],
      [-278, 232],
      [-357, 1844],
      [-191, -673],
      [-230, 2345],
      [-236, 376],
      [-145, 2238],
      [-299, -1766],
      [-371, 677],
    ],
    [
      [228842, 350601],
      [273, 1701],
      [-395, -68],
    ],
    [
      [228720, 352234],
      [-26, 1706],
      [357, -36],
      [129, 1256],
      [379, 564],
      [8, 4038],
    ],
    [
      [224566, 359758],
      [31, -9970],
    ],
    [
      [224597, 349788],
      [-210, -1342],
      [-482, 1078],
      [-172, 1064],
      [-325, 12],
    ],
    [
      [223408, 350600],
      [-48, 1718],
      [-452, 3424],
      [-433, 679],
    ],
    [
      [222475, 356421],
      [0, 1],
    ],
    [
      [222475, 356422],
      [-448, 3349],
    ],
    [
      [234421, 359772],
      [22, -6122],
    ],
    [
      [234443, 353650],
      [-18, -2995],
    ],
    [
      [234425, 350655],
      [-897, 9],
    ],
    [
      [233528, 350664],
      [-1, 1625],
      [-287, 0],
    ],
    [
      [232186, 352287],
      [-8, -3237],
    ],
    [
      [232178, 349050],
      [-525, 2],
    ],
    [
      [227582, 359757],
      [-1, -7542],
    ],
    [
      [227581, 352215],
      [-1788, -1],
    ],
    [
      [225793, 352214],
      [-22, 7532],
    ],
    [
      [225793, 352214],
      [0, -2428],
    ],
    [
      [225793, 349786],
      [-1196, 2],
    ],
    [
      [228720, 352234],
      [-1136, -19],
    ],
    [
      [227584, 352215],
      [-3, 0],
    ],
    [
      [235505, 355434],
      [1, -1837],
    ],
    [
      [235506, 353597],
      [-1063, 53],
    ],
    [
      [222475, 356421],
      [0, -4177],
      [-865, -45],
    ],
    [
      [221610, 352199],
      [-1108, 8],
    ],
    [
      [220502, 352207],
      [2, 7605],
    ],
    [
      [203776, 359694],
      [98, -2074],
      [-26, -3169],
      [117, -4650],
      [-91, -2222],
      [-239, -893],
      [559, -1233],
      [337, -2424],
      [573, -1622],
    ],
    [
      [205104, 341407],
      [-115, -753],
      [-407, 115],
    ],
    [
      [204582, 340769],
      [2, 439],
      [-988, -6],
      [48, -1326],
      [-533, 2],
    ],
    [
      [203111, 339878],
      [-7, 660],
    ],
    [
      [203104, 340538],
      [1, 655],
      [-1776, -49],
      [0, 4052],
      [-2059, 59],
    ],
    [
      [199270, 345255],
      [16, 10890],
      [481, 2044],
      [69, 1592],
    ],
    [
      [199270, 345255],
      [-7, -4085],
    ],
    [
      [199263, 341170],
      [-3954, 38],
    ],
    [
      [195309, 341208],
      [2, 18556],
    ],
    [
      [195309, 341208],
      [0, -19410],
    ],
    [
      [195309, 321798],
      [-1, -7083],
    ],
    [
      [195308, 314715],
      [-3, -14913],
    ],
    [
      [195305, 299802],
      [-837, -13],
      [-15, -900],
      [-398, -1421],
    ],
    [
      [194055, 297468],
      [-881, -3135],
      [-222, 1553],
    ],
    [
      [212146, 359782],
      [0, -9302],
    ],
    [
      [212146, 350480],
      [-110, 1],
      [3, -8289],
    ],
    [
      [212039, 342192],
      [-2, -5881],
    ],
    [
      [212037, 336311],
      [-929, 13],
    ],
    [
      [211108, 336324],
      [28, 6448],
      [-1196, -12],
      [1, 1644],
      [-599, 9],
      [0, 810],
    ],
    [
      [209342, 345223],
      [3, 14488],
    ],
    [
      [214861, 359653],
      [-12, -9170],
    ],
    [
      [214849, 350483],
      [-362, -4],
    ],
    [
      [214487, 350479],
      [-2341, 1],
    ],
    [
      [217877, 359752],
      [-24, -9286],
    ],
    [
      [217853, 350466],
      [-365, -7],
    ],
    [
      [217488, 350459],
      [-1501, 5],
    ],
    [
      [215987, 350464],
      [-1138, 19],
    ],
    [
      [220502, 352207],
      [-2, -1740],
    ],
    [
      [220500, 350467],
      [-1510, -6],
    ],
    [
      [218990, 350461],
      [-1137, 5],
    ],
    [
      [284801, 354340],
      [42, -1109],
    ],
    [
      [284877, 353154],
      [-51, -1711],
    ],
    [
      [284826, 351443],
      [-1, -153],
    ],
    [
      [284825, 351290],
      [-691, 44],
    ],
    [
      [284134, 351334],
      [-370, -26],
    ],
    [
      [283764, 351308],
      [-248, 1789],
      [-323, 550],
      [200, 692],
    ],
    [
      [263478, 357307],
      [2, -789],
      [-559, -4123],
    ],
    [
      [262921, 352395],
      [-20, 2],
    ],
    [
      [262901, 352397],
      [-528, 227],
    ],
    [
      [262373, 352624],
      [-249, 4527],
    ],
    [
      [205965, 359691],
      [58, -1482],
      [-85, -3570],
      [-387, -996],
      [-22, -2199],
      [163, -1118],
      [-61, -4279],
    ],
    [
      [205631, 346047],
      [-184, -448],
      [-36, -1983],
      [-307, -2209],
    ],
    [
      [209342, 345223],
      [-1191, 14],
    ],
    [
      [208151, 345237],
      [-1196, -14],
      [2, 803],
      [-1326, 21],
    ],
    [
      [239166, 356371],
      [-30, -5933],
    ],
    [
      [239136, 350438],
      [-750, 14],
    ],
    [
      [238386, 350452],
      [29, 5979],
      [-103, 1914],
    ],
    [
      [162899, 346141],
      [-231, -1190],
      [-456, 1973],
      [-261, -928],
      [-61, 1185],
      [-572, 3411],
      [-183, -60],
      [-19, 1981],
      [-417, 1414],
      [44, 778],
      [-533, 3123],
    ],
    [
      [265430, 353958],
      [-148, -1853],
    ],
    [
      [265282, 352105],
      [-222, 46],
    ],
    [
      [265060, 352151],
      [-603, 35],
    ],
    [
      [264457, 352186],
      [-224, 1544],
      [-135, 2569],
      [163, 2476],
    ],
    [
      [286870, 358327],
      [-79, -1657],
    ],
    [
      [286791, 356670],
      [-328, 768],
      [3, 1656],
      [404, -767],
    ],
    [
      [264457, 352186],
      [-96, -2],
    ],
    [
      [264361, 352184],
      [-1440, 211],
    ],
    [
      [266591, 353542],
      [-596, -1193],
    ],
    [
      [265995, 352349],
      [-713, -244],
    ],
    [
      [251680, 358786],
      [-1, -3231],
    ],
    [
      [251679, 355555],
      [-863, 221],
    ],
    [
      [252591, 355134],
      [-9, -4639],
    ],
    [
      [252582, 350495],
      [-78, 9],
    ],
    [
      [252504, 350504],
      [-835, 20],
    ],
    [
      [251669, 350524],
      [10, 5031],
    ],
    [
      [261703, 357073],
      [-226, -4256],
    ],
    [
      [261477, 352817],
      [-391, -141],
    ],
    [
      [261086, 352676],
      [-444, 3719],
    ],
    [
      [259581, 354615],
      [12, -1749],
    ],
    [
      [259593, 352866],
      [-647, 210],
    ],
    [
      [258946, 353076],
      [-576, 206],
    ],
    [
      [258370, 353282],
      [16, 2332],
    ],
    [
      [238386, 350452],
      [-787, -3],
    ],
    [
      [237599, 350449],
      [-586, 0],
    ],
    [
      [237013, 350449],
      [23, 4637],
    ],
    [
      [287748, 351418],
      [-69, 0],
    ],
    [
      [287679, 351418],
      [-139, 3114],
      [-110, -3118],
    ],
    [
      [287430, 351414],
      [-90, 0],
    ],
    [
      [287340, 351414],
      [-61, 2],
    ],
    [
      [287279, 351416],
      [-242, -2],
    ],
    [
      [287037, 351414],
      [1, 2143],
      [150, 496],
      [-378, 1441],
      [-61, 1239],
    ],
    [
      [286749, 356733],
      [42, -63],
    ],
    [
      [286870, 358327],
      [530, -179],
      [348, -6730],
    ],
    [
      [274940, 354358],
      [-364, -882],
      [-48, -1926],
    ],
    [
      [274528, 351550],
      [-629, 24],
    ],
    [
      [273899, 351574],
      [-213, 1696],
    ],
    [
      [273686, 353270],
      [-50, 397],
    ],
    [
      [273636, 353667],
      [-307, 2510],
    ],
    [
      [270321, 353193],
      [-338, -940],
    ],
    [
      [269983, 352253],
      [-141, 1],
    ],
    [
      [269842, 352254],
      [-106, 2116],
    ],
    [
      [271759, 353055],
      [-114, -499],
    ],
    [
      [271645, 352556],
      [-501, 43],
    ],
    [
      [271144, 352599],
      [-890, -363],
    ],
    [
      [270254, 352236],
      [67, 957],
    ],
    [
      [247959, 352904],
      [-202, -2471],
    ],
    [
      [247757, 350433],
      [-990, 13],
    ],
    [
      [246767, 350446],
      [-44, 3365],
      [-197, 2460],
    ],
    [
      [286206, 357292],
      [36, 565],
    ],
    [
      [286206, 357292],
      [-103, -959],
      [-95, -4917],
    ],
    [
      [286008, 351416],
      [-140, 0],
    ],
    [
      [285868, 351416],
      [-1042, 27],
    ],
    [
      [285916, 358061],
      [-22, -1250],
      [348, 1046],
    ],
    [
      [163911, 339436],
      [276, -2210],
    ],
    [
      [164187, 337226],
      [-3149, 125],
    ],
    [
      [161038, 337351],
      [-326, 1746],
      [-77, 1735],
      [-231, 787],
      [-396, 3161],
      [-503, 2053],
      [-222, 5105],
      [442, 1739],
      [40, 3417],
    ],
    [
      [283764, 351308],
      [-1310, 11],
    ],
    [
      [268512, 356056],
      [-491, -2310],
      [-105, -1529],
    ],
    [
      [267916, 352217],
      [-1356, 67],
    ],
    [
      [266560, 352284],
      [-565, 65],
    ],
    [
      [281677, 351294],
      [-774, -7],
    ],
    [
      [280903, 351287],
      [-371, -27],
    ],
    [
      [280532, 351260],
      [-772, -7],
    ],
    [
      [262373, 352624],
      [-838, 204],
    ],
    [
      [261535, 352828],
      [-58, -11],
    ],
    [
      [245263, 356498],
      [-29, -6068],
    ],
    [
      [245234, 350430],
      [-782, -12],
    ],
    [
      [244452, 350418],
      [-119, 11],
    ],
    [
      [244333, 350429],
      [-620, 34],
    ],
    [
      [269842, 352254],
      [-878, -31],
    ],
    [
      [268964, 352223],
      [-617, -6],
    ],
    [
      [268347, 352217],
      [-431, 0],
    ],
    [
      [258370, 353282],
      [-422, -315],
    ],
    [
      [257948, 352967],
      [-559, 277],
    ],
    [
      [276118, 355953],
      [-148, -277],
      [114, -4413],
    ],
    [
      [276084, 351263],
      [-1077, 152],
    ],
    [
      [275007, 351415],
      [-479, 135],
    ],
    [
      [250246, 352928],
      [-253, -2473],
    ],
    [
      [249993, 350455],
      [-186, -21],
    ],
    [
      [249807, 350434],
      [-151, 5],
    ],
    [
      [249656, 350439],
      [-18, -3004],
    ],
    [
      [249638, 347435],
      [-129, 1228],
      [-367, 441],
      [-80, -746],
      [-582, 45],
    ],
    [
      [248480, 348403],
      [6, 4476],
    ],
    [
      [286305, 356800],
      [-51, -466],
    ],
    [
      [286570, 356468],
      [-316, -134],
    ],
    [
      [286570, 356468],
      [124, 213],
    ],
    [
      [286694, 356681],
      [55, 52],
    ],
    [
      [287037, 351414],
      [-532, -2],
    ],
    [
      [286505, 351412],
      [-497, 4],
    ],
    [
      [286305, 356800],
      [-99, 492],
    ],
    [
      [277028, 351253],
      [-871, 10],
    ],
    [
      [276157, 351263],
      [-73, 0],
    ],
    [
      [181366, 356861],
      [18, -12097],
      [-305, -3169],
      [-223, -192],
      [-381, 2417],
      [-725, -8],
      [-350, -1053],
      [144, -4362],
      [76, -6300],
      [227, -3470],
      [73, -3058],
      [-216, -1125],
      [36, -1876],
    ],
    [
      [179740, 322568],
      [-2834, 15078],
    ],
    [
      [261086, 352676],
      [-980, 65],
    ],
    [
      [260106, 352741],
      [-513, 125],
    ],
    [
      [246767, 350446],
      [-580, -1],
    ],
    [
      [246187, 350445],
      [-953, -15],
    ],
    [
      [240668, 356171],
      [-20, -5742],
    ],
    [
      [240648, 350429],
      [-227, 4],
    ],
    [
      [240421, 350433],
      [-1234, 7],
    ],
    [
      [239187, 350440],
      [-51, -2],
    ],
    [
      [223408, 350600],
      [12, -6428],
    ],
    [
      [223420, 344172],
      [-1187, 51],
    ],
    [
      [222233, 344223],
      [-596, -4],
      [-27, 7980],
    ],
    [
      [273636, 353667],
      [50, -397],
    ],
    [
      [273899, 351574],
      [-178, 50],
    ],
    [
      [273721, 351624],
      [-1258, 239],
    ],
    [
      [272463, 351863],
      [-903, 250],
    ],
    [
      [271560, 352113],
      [85, 443],
    ],
    [
      [242465, 350452],
      [-84, -5],
    ],
    [
      [242381, 350447],
      [-1055, 0],
    ],
    [
      [241326, 350447],
      [-678, -18],
    ],
    [
      [251669, 350524],
      [-28, 4],
    ],
    [
      [251641, 350528],
      [-22, -4],
    ],
    [
      [251619, 350524],
      [-191, 1382],
      [-763, 1410],
    ],
    [
      [237013, 350449],
      [-1507, 13],
    ],
    [
      [235506, 350462],
      [0, 3135],
    ],
    [
      [253748, 353788],
      [47, -3358],
    ],
    [
      [253795, 350430],
      [-1213, 65],
    ],
    [
      [170339, 337195],
      [-4266, 60],
    ],
    [
      [166073, 337255],
      [29, 8917],
      [143, -16],
      [5, 2474],
      [-284, 1630],
    ],
    [
      [254944, 353043],
      [136, -5034],
    ],
    [
      [255080, 348009],
      [-640, -725],
      [-443, 466],
    ],
    [
      [253997, 347750],
      [-26, 131],
    ],
    [
      [253971, 347881],
      [-176, 2549],
    ],
    [
      [235506, 350462],
      [154, -6283],
    ],
    [
      [235660, 344179],
      [-653, -10],
    ],
    [
      [235007, 344169],
      [-597, 8],
    ],
    [
      [234410, 344177],
      [15, 6478],
    ],
    [
      [257414, 348712],
      [-201, -1047],
      [-242, 621],
    ],
    [
      [256971, 348286],
      [-207, -250],
      [-369, 1607],
    ],
    [
      [256395, 349643],
      [15, 3480],
    ],
    [
      [257948, 352967],
      [-373, -4227],
      [-161, -28],
    ],
    [
      [258946, 353076],
      [-66, -2837],
    ],
    [
      [258880, 350239],
      [-152, -2605],
    ],
    [
      [258728, 347634],
      [-470, -221],
      [-397, -1687],
    ],
    [
      [257861, 345726],
      [-447, 2986],
    ],
    [
      [251619, 350524],
      [-1425, 9],
    ],
    [
      [250194, 350533],
      [-201, -78],
    ],
    [
      [256395, 349643],
      [-463, -2485],
    ],
    [
      [255932, 347158],
      [-632, 238],
    ],
    [
      [255300, 347396],
      [-220, 613],
    ],
    [
      [278065, 351243],
      [-356, -10],
    ],
    [
      [260035, 350463],
      [-39, -1632],
    ],
    [
      [259996, 348831],
      [-420, 265],
    ],
    [
      [259576, 349096],
      [-528, 339],
      [-168, 804],
    ],
    [
      [260106, 352741],
      [-71, -2278],
    ],
    [
      [248480, 348403],
      [6, -7256],
    ],
    [
      [248486, 341147],
      [-919, -50],
    ],
    [
      [247567, 341097],
      [-247, -13],
    ],
    [
      [247320, 341084],
      [102, 953],
    ],
    [
      [247422, 342037],
      [419, 2876],
    ],
    [
      [247841, 344913],
      [353, 1892],
      [-44, 1811],
      [-209, 1819],
      [-184, -2],
    ],
    [
      [262901, 352397],
      [149, -1465],
    ],
    [
      [263050, 350932],
      [-592, 1050],
      [-298, -536],
      [-193, -2703],
    ],
    [
      [261967, 348743],
      [-458, 2312],
    ],
    [
      [261509, 351055],
      [26, 1773],
    ],
    [
      [261509, 351055],
      [-433, -573],
      [-153, -1811],
    ],
    [
      [260923, 348671],
      [-582, 2219],
      [-306, -427],
    ],
    [
      [270706, 350628],
      [-572, -2009],
      [-308, -73],
    ],
    [
      [269826, 348546],
      [-389, 912],
      [-672, -256],
    ],
    [
      [268765, 349202],
      [199, 3021],
    ],
    [
      [270254, 352236],
      [-271, 17],
    ],
    [
      [271144, 352599],
      [-438, -1971],
    ],
    [
      [271560, 352113],
      [-142, -3665],
    ],
    [
      [271418, 348448],
      [-528, -1943],
    ],
    [
      [270890, 346505],
      [-41, -397],
    ],
    [
      [270849, 346108],
      [-351, 1893],
      [300, 1976],
      [-92, 651],
    ],
    [
      [264361, 352184],
      [5, -1438],
      [-252, -900],
      [56, -3382],
      [-120, -1264],
    ],
    [
      [264050, 345200],
      [-190, -992],
    ],
    [
      [263860, 344208],
      [-474, 1989],
      [-250, 1871],
    ],
    [
      [263136, 348068],
      [117, 454],
      [-203, 2410],
    ],
    [
      [266560, 352284],
      [206, -1169],
      [31, -2258],
    ],
    [
      [266797, 348857],
      [-174, -720],
      [-607, -562],
    ],
    [
      [266016, 347575],
      [-183, 13],
      [-202, 1691],
      [-279, -297],
    ],
    [
      [265352, 348982],
      [-292, 3169],
    ],
    [
      [228842, 350601],
      [-61, -3232],
      [298, 0],
      [0, -1617],
    ],
    [
      [229079, 345752],
      [-602, -1],
      [-1, -1624],
      [-593, -2],
    ],
    [
      [227883, 344125],
      [-297, 101],
    ],
    [
      [227586, 344226],
      [-2, 7989],
    ],
    [
      [268347, 352217],
      [-502, -559],
      [-752, -3147],
    ],
    [
      [267093, 348511],
      [-296, 346],
    ],
    [
      [233528, 350664],
      [-299, -2],
      [-14, -8097],
    ],
    [
      [233215, 342565],
      [-365, 1],
      [-131, 1627],
      [-399, 1],
    ],
    [
      [232320, 344194],
      [-150, -1],
      [8, 4857],
    ],
    [
      [265352, 348982],
      [-275, -2785],
    ],
    [
      [265077, 346197],
      [-193, -790],
      [-429, 315],
      [-38, -1252],
      [-367, 730],
    ],
    [
      [268765, 349202],
      [-61, -397],
    ],
    [
      [268704, 348805],
      [-665, -1448],
      [-393, -1682],
    ],
    [
      [267646, 345675],
      [-224, 1917],
      [-258, -1046],
    ],
    [
      [267164, 346546],
      [-71, 1965],
    ],
    [
      [227586, 344226],
      [-599, 4],
    ],
    [
      [226987, 344230],
      [-1193, 4],
    ],
    [
      [225794, 344234],
      [-1, 5552],
    ],
    [
      [222233, 344223],
      [3, -2818],
    ],
    [
      [222236, 341405],
      [-226, -158],
      [-275, -2614],
      [-162, -422],
      [-441, 841],
      [-101, 2156],
      [-521, -2268],
    ],
    [
      [220510, 338940],
      [0, 3261],
    ],
    [
      [220510, 342201],
      [-10, 8266],
    ],
    [
      [272463, 351863],
      [277, -3899],
    ],
    [
      [272740, 347964],
      [-622, -2325],
    ],
    [
      [272118, 345639],
      [-700, 2809],
    ],
    [
      [263136, 348068],
      [-494, -1684],
      [-81, -2308],
    ],
    [
      [262561, 344076],
      [-537, -332],
    ],
    [
      [262024, 343744],
      [-54, 115],
    ],
    [
      [261970, 343859],
      [103, 956],
      [-106, 3928],
    ],
    [
      [273721, 351624],
      [-182, -2986],
    ],
    [
      [273539, 348638],
      [-167, -516],
      [-189, 1242],
      [-443, -1400],
    ],
    [
      [230908, 344180],
      [-82, 5],
    ],
    [
      [230826, 344185],
      [-902, -44],
    ],
    [
      [229924, 344141],
      [-550, -15],
      [0, 1626],
      [-295, 0],
    ],
    [
      [275007, 351415],
      [-33, -5379],
    ],
    [
      [274974, 346036],
      [-1, -378],
    ],
    [
      [274973, 345658],
      [-247, 762],
      [-926, -859],
    ],
    [
      [273800, 345561],
      [12, 1685],
      [-273, 1392],
    ],
    [
      [285868, 351416],
      [140, -749],
    ],
    [
      [286008, 350667],
      [106, -2464],
    ],
    [
      [286114, 348203],
      [-296, -486],
    ],
    [
      [285818, 347717],
      [-335, -961],
    ],
    [
      [285483, 346756],
      [-266, 1180],
      [-452, 774],
    ],
    [
      [284765, 348710],
      [60, 2580],
    ],
    [
      [287331, 347551],
      [-272, 271],
      [-554, 3590],
    ],
    [
      [287279, 351416],
      [112, -2597],
      [320, -2367],
      [229, -3945],
      [-412, 3972],
      [-197, 1072],
    ],
    [
      [287748, 351418],
      [261, -5943],
    ],
    [
      [288009, 345475],
      [-7, -9],
    ],
    [
      [288002, 345466],
      [-124, 1107],
      [-199, 4845],
    ],
    [
      [276157, 351263],
      [-22, -5309],
    ],
    [
      [276135, 345954],
      [-1161, 82],
    ],
    [
      [287331, 347551],
      [294, -3319],
      [-296, 418],
      [-595, 3435],
    ],
    [
      [286734, 348085],
      [-726, 2582],
    ],
    [
      [282085, 351303],
      [5, -704],
    ],
    [
      [282090, 350599],
      [-40, -2323],
      [-263, -3337],
    ],
    [
      [281787, 344939],
      [-837, 1178],
    ],
    [
      [280950, 346117],
      [82, 750],
      [-129, 4420],
    ],
    [
      [284010, 345759],
      [-232, -1457],
    ],
    [
      [283778, 344302],
      [-264, 840],
      [-153, 2057],
      [-292, -389],
      [-26, 2140],
      [-250, 1169],
      [-703, 480],
    ],
    [
      [284134, 351334],
      [123, -1350],
      [-247, -4225],
    ],
    [
      [284765, 348710],
      [428, -835],
      [170, -2171],
    ],
    [
      [285363, 345704],
      [-1353, 55],
    ],
    [
      [279587, 351248],
      [-16, -5691],
    ],
    [
      [279571, 345557],
      [-415, 60],
    ],
    [
      [279156, 345617],
      [-565, 56],
    ],
    [
      [278591, 345673],
      [43, 5576],
    ],
    [
      [280950, 346117],
      [-322, -1897],
      [-207, 205],
    ],
    [
      [280421, 344425],
      [-40, 5196],
      [151, 1639],
    ],
    [
      [280421, 344425],
      [-138, -2849],
    ],
    [
      [280283, 341576],
      [-564, 914],
    ],
    [
      [279719, 342490],
      [-155, 442],
      [7, 2625],
    ],
    [
      [277597, 351232],
      [-60, -5440],
    ],
    [
      [277537, 345792],
      [-1, -133],
    ],
    [
      [277536, 345659],
      [-1401, 295],
    ],
    [
      [278591, 345673],
      [-288, 31],
    ],
    [
      [278303, 345704],
      [-766, 88],
    ],
    [
      [261970, 343859],
      [-836, 1715],
      [-211, 1218],
    ],
    [
      [260923, 346792],
      [0, 1879],
    ],
    [
      [260923, 346792],
      [-350, -1703],
      [-444, 506],
    ],
    [
      [260129, 345595],
      [-184, 949],
      [51, 2287],
    ],
    [
      [283778, 344302],
      [137, -1341],
      [-235, -398],
    ],
    [
      [283680, 342563],
      [-211, -1306],
    ],
    [
      [283469, 341257],
      [-343, 603],
      [-21, 992],
      [-459, 1162],
    ],
    [
      [282646, 344014],
      [-526, -168],
      [-333, 1093],
    ],
    [
      [234410, 344177],
      [-298, -3],
      [1, -1624],
      [-250, 5],
    ],
    [
      [233863, 342555],
      [-648, 10],
    ],
    [
      [270849, 346108],
      [-412, -3021],
    ],
    [
      [270437, 343087],
      [-395, 999],
    ],
    [
      [270042, 344086],
      [-95, 1419],
      [-234, 366],
    ],
    [
      [269713, 345871],
      [113, 2675],
    ],
    [
      [286734, 348085],
      [37, -1381],
      [441, -2720],
      [-295, -510],
      [-337, 1726],
    ],
    [
      [286580, 345200],
      [-247, 781],
      [-219, 2222],
    ],
    [
      [251641, 350528],
      [29, -1722],
      [-373, -22],
      [-27, -3471],
    ],
    [
      [251270, 345313],
      [-542, -338],
    ],
    [
      [250728, 344975],
      [-915, 150],
    ],
    [
      [249813, 345125],
      [341, 3659],
      [40, 1749],
    ],
    [
      [225794, 344234],
      [-297, 0],
    ],
    [
      [225497, 344234],
      [-1188, 0],
    ],
    [
      [224309, 344234],
      [-889, -62],
    ],
    [
      [252504, 350504],
      [-37, -6508],
    ],
    [
      [252467, 343996],
      [-452, -1664],
    ],
    [
      [252015, 342332],
      [-416, 1162],
      [-329, 1819],
    ],
    [
      [249813, 345125],
      [-408, -510],
    ],
    [
      [249405, 344615],
      [-185, 1255],
      [449, 35],
      [-218, 1039],
      [187, 491],
    ],
    [
      [249807, 350434],
      [-151, 5],
    ],
    [
      [215987, 342196],
      [-1502, -4],
    ],
    [
      [214485, 342192],
      [2, 8287],
    ],
    [
      [215987, 350464],
      [0, -8268],
    ],
    [
      [253971, 347881],
      [-287, -10],
      [29, -1851],
      [-361, -2614],
    ],
    [
      [253352, 343406],
      [3, 475],
      [-888, 115],
    ],
    [
      [217486, 342236],
      [0, -44],
    ],
    [
      [217486, 342192],
      [-1499, 4],
    ],
    [
      [217488, 350459],
      [-2, -8223],
    ],
    [
      [214485, 342192],
      [-2446, 0],
    ],
    [
      [218990, 350461],
      [-2, -8244],
    ],
    [
      [218988, 342217],
      [-1502, 19],
    ],
    [
      [220510, 342201],
      [-1504, 15],
    ],
    [
      [219006, 342216],
      [-18, 1],
    ],
    [
      [237599, 350449],
      [-67, -2392],
      [210, -1186],
    ],
    [
      [237742, 346871],
      [-201, -1331],
    ],
    [
      [237541, 345540],
      [-238, -554],
      [-1004, 172],
      [-161, -2143],
      [-448, 53],
    ],
    [
      [235690, 343068],
      [-30, 1111],
    ],
    [
      [244333, 350429],
      [-13, -3019],
      [-310, 36],
      [-1, -1628],
      [-349, 62],
    ],
    [
      [243660, 345880],
      [-1297, 148],
    ],
    [
      [242363, 346028],
      [18, 4419],
    ],
    [
      [239187, 350440],
      [-9, -7019],
    ],
    [
      [239178, 343421],
      [-500, 72],
    ],
    [
      [238678, 343493],
      [-199, 24],
      [-297, 3289],
      [-440, 65],
    ],
    [
      [244452, 350418],
      [273, -1429],
      [140, -3024],
    ],
    [
      [244865, 345965],
      [-253, -501],
      [-21, -6336],
    ],
    [
      [244591, 339128],
      [-295, 860],
      [-689, 92],
    ],
    [
      [243607, 340080],
      [77, 1094],
      [-24, 4706],
    ],
    [
      [246124, 346133],
      [-9, -2184],
    ],
    [
      [246115, 343949],
      [-921, 327],
      [-329, 1689],
    ],
    [
      [246187, 350445],
      [91, -4020],
      [-154, -292],
    ],
    [
      [240421, 350433],
      [-86, -130],
      [-16, -7014],
    ],
    [
      [240319, 343289],
      [-151, 19],
    ],
    [
      [240168, 343308],
      [-990, 113],
    ],
    [
      [241326, 350447],
      [-51, -4301],
      [214, -302],
      [163, -1657],
      [2, -1854],
    ],
    [
      [241654, 342333],
      [-1188, 137],
      [-147, 819],
    ],
    [
      [242363, 346028],
      [-109, -2389],
    ],
    [
      [242254, 343639],
      [-4, -1372],
      [-299, 33],
      [-7, -1620],
      [-298, 35],
    ],
    [
      [241646, 340715],
      [8, 1618],
    ],
    [
      [247841, 344913],
      [-363, -14],
      [3, 1092],
      [-1357, 142],
    ],
    [
      [166073, 337255],
      [-1825, -24],
    ],
    [
      [164248, 337231],
      [-61, -5],
    ],
    [
      [259576, 349096],
      [-441, -2425],
    ],
    [
      [259135, 346671],
      [-407, 963],
    ],
    [
      [256971, 348286],
      [-234, -4732],
      [-157, -1542],
    ],
    [
      [256580, 342012],
      [-358, 78],
    ],
    [
      [256222, 342090],
      [31, 3291],
    ],
    [
      [256253, 345381],
      [-11, 1627],
      [-310, 150],
    ],
    [
      [269713, 345871],
      [-270, -1826],
      [-440, -1091],
    ],
    [
      [269003, 342954],
      [-147, 935],
      [39, 5066],
      [-191, -150],
    ],
    [
      [273800, 345561],
      [-24, -3359],
    ],
    [
      [273776, 342202],
      [-410, -186],
    ],
    [
      [273366, 342016],
      [-834, -921],
    ],
    [
      [272532, 341095],
      [-604, 2256],
    ],
    [
      [271928, 343351],
      [190, 2288],
    ],
    [
      [266016, 347575],
      [-182, -3347],
    ],
    [
      [265834, 344228],
      [-583, 406],
    ],
    [
      [265251, 344634],
      [-174, 1563],
    ],
    [
      [260129, 345595],
      [-76, -1981],
    ],
    [
      [260053, 343614],
      [-159, -706],
      [-544, -143],
    ],
    [
      [259350, 342765],
      [-215, 3906],
    ],
    [
      [249405, 344615],
      [100, -1065],
      [-238, -811],
      [-78, -1540],
    ],
    [
      [249189, 341199],
      [-703, -52],
    ],
    [
      [232320, 344194],
      [0, -4873],
      [-163, -839],
    ],
    [
      [232157, 338482],
      [-593, 17],
    ],
    [
      [231564, 338499],
      [9, 4068],
      [-746, 8],
      [-1, 1610],
    ],
    [
      [267164, 346546],
      [-203, 141],
      [-387, -2271],
    ],
    [
      [266574, 344416],
      [-254, 92],
      [-305, -1843],
    ],
    [
      [266015, 342665],
      [-181, 1563],
    ],
    [
      [257861, 345726],
      [219, -2689],
    ],
    [
      [258080, 343037],
      [-288, -2464],
    ],
    [
      [257792, 340573],
      [-469, 1261],
      [-395, 312],
      [-275, -1179],
      [-73, 1045],
    ],
    [
      [269003, 342954],
      [-22, -1048],
    ],
    [
      [268981, 341906],
      [-95, 483],
      [-414, -1436],
      [-99, -1242],
      [-215, 430],
    ],
    [
      [268158, 340141],
      [0, 1],
    ],
    [
      [268158, 340142],
      [-746, 4390],
    ],
    [
      [267412, 344532],
      [234, 1143],
    ],
    [
      [271928, 343351],
      [-738, -110],
    ],
    [
      [271190, 343241],
      [-274, 1942],
      [-26, 1322],
    ],
    [
      [286580, 345200],
      [260, -2059],
      [-166, -58],
      [-563, 1793],
      [417, -1963],
      [-516, -159],
    ],
    [
      [286012, 342754],
      [-231, 471],
      [37, 4492],
    ],
    [
      [263860, 344208],
      [277, -2133],
    ],
    [
      [264137, 342075],
      [-291, -1173],
      [-653, -1442],
    ],
    [
      [263193, 339460],
      [-112, 1610],
      [-520, 3006],
    ],
    [
      [255300, 347396],
      [-148, -2919],
    ],
    [
      [255152, 344477],
      [-646, 1243],
      [-422, -29],
    ],
    [
      [254084, 345691],
      [-87, 2059],
    ],
    [
      [254084, 345691],
      [77, -2250],
      [-252, -3270],
      [243, -246],
      [-104, -1722],
    ],
    [
      [254048, 338203],
      [-22, -470],
    ],
    [
      [254026, 337733],
      [-578, 561],
    ],
    [
      [253448, 338294],
      [-109, 14],
      [13, 5098],
    ],
    [
      [259350, 342765],
      [125, -2327],
    ],
    [
      [259475, 340438],
      [-385, -123],
    ],
    [
      [259090, 340315],
      [-438, 450],
      [-572, 2272],
    ],
    [
      [286012, 342754],
      [225, -326],
      [-290, -1136],
      [-496, 1123],
      [-83, 1530],
      [115, 2811],
    ],
    [
      [267412, 344532],
      [-191, -1784],
    ],
    [
      [267221, 342748],
      [-165, 990],
      [-482, 678],
    ],
    [
      [256222, 342090],
      [-60, -1686],
    ],
    [
      [256162, 340404],
      [-921, 638],
    ],
    [
      [255241, 341042],
      [-89, 3435],
    ],
    [
      [238678, 343493],
      [80, -2934],
      [-203, -1599],
      [13, -2199],
    ],
    [
      [238568, 336761],
      [-493, 96],
    ],
    [
      [238075, 336857],
      [-599, -165],
    ],
    [
      [237476, 336692],
      [-145, 21],
    ],
    [
      [237331, 336713],
      [52, 6147],
      [158, 2680],
    ],
    [
      [262024, 343744],
      [-409, -705],
      [-49, -2263],
    ],
    [
      [261566, 340776],
      [-126, 977],
      [-554, 938],
      [-379, -1246],
    ],
    [
      [260507, 341445],
      [-454, 2169],
    ],
    [
      [265251, 344634],
      [-424, -2187],
      [97, -598],
      [-378, -805],
      [-214, -1553],
    ],
    [
      [264332, 339491],
      [-195, 2584],
    ],
    [
      [274973, 345658],
      [38, -1939],
      [-162, -1685],
    ],
    [
      [274849, 342034],
      [-546, 79],
    ],
    [
      [274303, 342113],
      [-527, 89],
    ],
    [
      [271190, 343241],
      [205, -849],
      [-197, -1935],
    ],
    [
      [271198, 340457],
      [-257, 707],
    ],
    [
      [270941, 341164],
      [-122, -754],
    ],
    [
      [270819, 340410],
      [-107, -893],
    ],
    [
      [270712, 339517],
      [-146, 572],
      [-129, 2998],
    ],
    [
      [281787, 344939],
      [-692, -7162],
    ],
    [
      [281095, 337777],
      [-139, 1398],
      [-673, 2401],
    ],
    [
      [247320, 341084],
      [-33, -576],
      [-1290, 60],
    ],
    [
      [245997, 340568],
      [118, 3381],
    ],
    [
      [208151, 345237],
      [293, -4778],
      [-163, -1891],
      [63, -1472],
    ],
    [
      [208344, 337096],
      [-1781, -187],
      [-951, 1848],
      [-1038, 6],
    ],
    [
      [204574, 338763],
      [8, 2006],
    ],
    [
      [276135, 345954],
      [-22, -4592],
    ],
    [
      [276113, 341362],
      [-980, -698],
    ],
    [
      [275133, 340664],
      [-284, 1370],
    ],
    [
      [243607, 340080],
      [-387, -1384],
    ],
    [
      [243220, 338696],
      [-296, 1224],
      [-438, 78],
      [155, 1793],
      [-387, 1848],
    ],
    [
      [270042, 344086],
      [-542, -1570],
    ],
    [
      [269500, 342516],
      [-247, -1768],
    ],
    [
      [269253, 340748],
      [-294, -190],
      [22, 1348],
    ],
    [
      [245997, 340568],
      [-63, -1620],
      [-438, 76],
    ],
    [
      [245496, 339024],
      [-464, 93],
    ],
    [
      [245032, 339117],
      [-441, 11],
    ],
    [
      [277536, 345659],
      [-27, -6364],
    ],
    [
      [277509, 339295],
      [-1406, 392],
    ],
    [
      [276103, 339687],
      [10, 1675],
    ],
    [
      [278303, 345704],
      [22, -6834],
    ],
    [
      [278325, 338870],
      [33, -603],
      [-850, -17],
    ],
    [
      [277508, 338250],
      [1, 1045],
    ],
    [
      [229924, 344141],
      [1, -4065],
    ],
    [
      [229925, 340076],
      [-1447, 8],
    ],
    [
      [228478, 340084],
      [-594, 800],
      [-1, 3241],
    ],
    [
      [255241, 341042],
      [-480, -1635],
      [-27, -1245],
    ],
    [
      [254734, 338162],
      [-379, -650],
      [-307, 691],
    ],
    [
      [285363, 345704],
      [-90, -1720],
      [200, -3016],
      [-54, -1230],
    ],
    [
      [285419, 339738],
      [-161, -1089],
    ],
    [
      [285258, 338649],
      [0, -1],
    ],
    [
      [285258, 338649],
      [-207, 364],
      [-191, -1126],
      [-97, 1433],
      [-186, -785],
      [-144, 1522],
      [-394, -15],
      [-22, 1228],
      [-337, 1293],
    ],
    [
      [279156, 345617],
      [-183, -6999],
    ],
    [
      [278973, 338618],
      [-648, 252],
    ],
    [
      [279719, 342490],
      [139, -1156],
      [-370, -2639],
      [-207, 11],
    ],
    [
      [279281, 338706],
      [-308, -88],
    ],
    [
      [237331, 336713],
      [-1478, -32],
    ],
    [
      [235853, 336681],
      [-163, 6387],
    ],
    [
      [287690, 334442],
      [-349, 563],
    ],
    [
      [287341, 335005],
      [74, 4092],
      [488, 660],
      [174, -762],
      [41, -4814],
      [-337, -751],
      [-91, 1012],
    ],
    [
      [288009, 345475],
      [568, -6870],
      [-469, 3339],
      [-106, 3522],
    ],
    [
      [252015, 342332],
      [-40, -5057],
    ],
    [
      [251975, 337275],
      [-583, 117],
    ],
    [
      [251392, 337392],
      [-539, 1204],
      [-221, 2559],
    ],
    [
      [250632, 341155],
      [96, 3820],
    ],
    [
      [203104, 340538],
      [-422, -118],
      [-2, -2837],
      [423, -966],
    ],
    [
      [203103, 336617],
      [9, -10066],
    ],
    [
      [203112, 326551],
      [-2650, 80],
    ],
    [
      [200462, 326631],
      [-119, 1601],
      [-198, -3],
    ],
    [
      [200145, 328229],
      [4, 12910],
      [-886, 31],
    ],
    [
      [211108, 336324],
      [-14, -6367],
      [-716, -155],
    ],
    [
      [210378, 329802],
      [-244, 606],
      [-702, 7065],
      [-124, -377],
      [-964, 0],
    ],
    [
      [250632, 341155],
      [-254, -2106],
      [-175, -101],
    ],
    [
      [250203, 338948],
      [-180, 1222],
      [-661, -786],
    ],
    [
      [249362, 339384],
      [-173, 1815],
    ],
    [
      [282646, 344014],
      [-362, -5323],
    ],
    [
      [282284, 338691],
      [-1014, -2547],
    ],
    [
      [281270, 336144],
      [-175, 1633],
    ],
    [
      [267221, 342748],
      [-212, -3523],
    ],
    [
      [267009, 339225],
      [-457, 517],
      [-553, 2139],
    ],
    [
      [265999, 341881],
      [16, 784],
    ],
    [
      [265999, 341881],
      [52, -1240],
      [-389, -1579],
    ],
    [
      [265662, 339062],
      [-165, -855],
      [-257, 652],
      [-539, -385],
      [-75, -938],
    ],
    [
      [264626, 337536],
      [-271, 1710],
    ],
    [
      [264355, 339246],
      [-23, 245],
    ],
    [
      [268158, 340141],
      [-176, -2867],
    ],
    [
      [267982, 337274],
      [-326, -27],
      [-492, -1365],
    ],
    [
      [267164, 335882],
      [-1, -24],
    ],
    [
      [267163, 335858],
      [-154, 3367],
    ],
    [
      [263193, 339460],
      [-282, -1555],
    ],
    [
      [262911, 337905],
      [-375, -1179],
    ],
    [
      [262536, 336726],
      [-941, 75],
    ],
    [
      [261595, 336801],
      [0, 25],
    ],
    [
      [261595, 336826],
      [-52, 385],
    ],
    [
      [261543, 337211],
      [10, 106],
    ],
    [
      [261553, 337317],
      [156, 2178],
      [-143, 1281],
    ],
    [
      [226987, 344230],
      [5, -8168],
    ],
    [
      [226992, 336062],
      [-1485, -16],
    ],
    [
      [225507, 336046],
      [-10, 8188],
    ],
    [
      [228478, 340084],
      [-1, -4054],
    ],
    [
      [228477, 336030],
      [-1485, 32],
    ],
    [
      [225507, 336046],
      [-296, -3],
      [0, -3229],
    ],
    [
      [225211, 332814],
      [-863, 2],
    ],
    [
      [224348, 332816],
      [-25, 4855],
    ],
    [
      [224323, 337671],
      [-14, 6563],
    ],
    [
      [224323, 337671],
      [-2072, -4],
    ],
    [
      [222251, 337667],
      [-15, 3738],
    ],
    [
      [231564, 338499],
      [-444, 3],
      [-1, -4058],
    ],
    [
      [231119, 334444],
      [-1192, -1],
    ],
    [
      [229927, 334443],
      [-2, 5633],
    ],
    [
      [233863, 342555],
      [-208, -1974],
      [145, -1820],
      [-98, -1074],
    ],
    [
      [233702, 337687],
      [-1043, -408],
      [-29, 1219],
      [-323, -7],
    ],
    [
      [232307, 338491],
      [-150, -9],
    ],
    [
      [235007, 344169],
      [-28, -9734],
    ],
    [
      [234979, 334435],
      [-892, 6],
    ],
    [
      [234087, 334441],
      [0, 3236],
      [-385, 10],
    ],
    [
      [235853, 336681],
      [58, -2247],
    ],
    [
      [235911, 334434],
      [-932, 1],
    ],
    [
      [270712, 339517],
      [-422, -1622],
    ],
    [
      [270290, 337895],
      [-183, 3520],
      [-607, 1101],
    ],
    [
      [283469, 341257],
      [168, -1847],
      [-22, -1611],
    ],
    [
      [283615, 337799],
      [-112, 244],
      [-755, -2883],
    ],
    [
      [282748, 335160],
      [-250, 2798],
      [-214, 733],
    ],
    [
      [253448, 338294],
      [-3, -541],
    ],
    [
      [253445, 337753],
      [-1089, 148],
      [-102, -658],
    ],
    [
      [252254, 337243],
      [-279, 32],
    ],
    [
      [260507, 341445],
      [21, -2479],
      [-126, -943],
    ],
    [
      [260402, 338023],
      [-565, 156],
    ],
    [
      [259837, 338179],
      [-362, 2259],
    ],
    [
      [243220, 338696],
      [34, -3025],
    ],
    [
      [243254, 335671],
      [-1124, 134],
    ],
    [
      [242130, 335805],
      [-496, 72],
      [5, 1351],
    ],
    [
      [241639, 337228],
      [7, 3487],
    ],
    [
      [240168, 343308],
      [-18, -7271],
    ],
    [
      [240150, 336037],
      [-594, 79],
    ],
    [
      [239556, 336116],
      [-989, 104],
      [1, 541],
    ],
    [
      [272532, 341095],
      [-16, -3720],
    ],
    [
      [272516, 337375],
      [-83, -532],
    ],
    [
      [272433, 336843],
      [-535, 176],
      [-700, 3438],
    ],
    [
      [241639, 337228],
      [-595, 42],
      [-2, -1349],
      [-498, 75],
    ],
    [
      [240544, 335996],
      [-394, 41],
    ],
    [
      [259090, 340315],
      [-150, -4665],
    ],
    [
      [258940, 335650],
      [-106, -1338],
    ],
    [
      [258834, 334312],
      [-300, 532],
      [-141, -775],
      [-313, 1293],
      [-345, -45],
    ],
    [
      [257735, 335317],
      [-130, 449],
    ],
    [
      [257605, 335766],
      [203, 1497],
      [-16, 3310],
    ],
    [
      [270290, 337895],
      [-396, -2233],
    ],
    [
      [269894, 335662],
      [-202, 1972],
      [-170, 143],
    ],
    [
      [269522, 337777],
      [-269, 2971],
    ],
    [
      [261553, 337317],
      [-455, 475],
      [-475, -445],
    ],
    [
      [260623, 337347],
      [-221, 676],
    ],
    [
      [281095, 337777],
      [-585, -2047],
      [-679, -3519],
    ],
    [
      [279831, 332211],
      [-571, 1186],
    ],
    [
      [279260, 333397],
      [-227, 517],
      [248, 4792],
    ],
    [
      [285258, 338648],
      [-235, -2967],
    ],
    [
      [285023, 335681],
      [-392, -875],
      [-524, 1390],
    ],
    [
      [284107, 336196],
      [-492, 1603],
    ],
    [
      [269522, 337777],
      [-293, -274],
      [-813, -2185],
      [-219, -149],
    ],
    [
      [268197, 335169],
      [-215, 2105],
    ],
    [
      [275133, 340664],
      [83, -1448],
      [-296, -1329],
      [32, -1467],
    ],
    [
      [274952, 336420],
      [-480, 1873],
      [-210, 137],
    ],
    [
      [274262, 338430],
      [41, 3683],
    ],
    [
      [219006, 342216],
      [1, -8139],
    ],
    [
      [219007, 334077],
      [-1521, -4],
    ],
    [
      [217486, 334073],
      [0, 114],
    ],
    [
      [217486, 334187],
      [0, 8005],
    ],
    [
      [220510, 338940],
      [0, -4867],
    ],
    [
      [220510, 334073],
      [-1503, 4],
    ],
    [
      [217486, 334187],
      [-1496, -22],
    ],
    [
      [215990, 334165],
      [-3, 8031],
    ],
    [
      [214485, 342192],
      [1, -7963],
    ],
    [
      [214486, 334229],
      [-2449, -95],
    ],
    [
      [212037, 334134],
      [0, 2177],
    ],
    [
      [274262, 338430],
      [-178, -3320],
      [96, -3115],
    ],
    [
      [274180, 331995],
      [-135, -24],
    ],
    [
      [274045, 331971],
      [-450, -339],
    ],
    [
      [273595, 331632],
      [-36, 1117],
    ],
    [
      [273559, 332749],
      [96, 1381],
      [-522, 2938],
    ],
    [
      [273133, 337068],
      [300, 3339],
      [-67, 1609],
    ],
    [
      [215990, 334165],
      [0, -71],
    ],
    [
      [215990, 334094],
      [-1504, -3],
    ],
    [
      [214486, 334091],
      [0, 138],
    ],
    [
      [257605, 335766],
      [-267, -59],
    ],
    [
      [257338, 335707],
      [-1207, 2676],
    ],
    [
      [256131, 338383],
      [31, 2021],
    ],
    [
      [273133, 337068],
      [-79, 883],
      [-538, -576],
    ],
    [
      [263585, 335029],
      [-124, -484],
    ],
    [
      [263461, 334545],
      [-99, 4],
    ],
    [
      [263362, 334549],
      [-9, 1249],
      [-281, 766],
    ],
    [
      [263072, 336564],
      [-161, 1341],
    ],
    [
      [264355, 339246],
      [-770, -4217],
    ],
    [
      [267163, 335858],
      [4, -355],
    ],
    [
      [267167, 335503],
      [-680, -2475],
      [-459, 116],
    ],
    [
      [266028, 333144],
      [-109, 2607],
      [-257, 3311],
    ],
    [
      [276103, 339687],
      [-56, -7726],
    ],
    [
      [276047, 331961],
      [-323, -24],
    ],
    [
      [275724, 331937],
      [-70, 1389],
      [-359, 2653],
      [-343, 441],
    ],
    [
      [222251, 337667],
      [32, -5647],
    ],
    [
      [222283, 332020],
      [-589, 1],
      [-2, -1624],
      [-1182, 13],
    ],
    [
      [220510, 330410],
      [0, 3663],
    ],
    [
      [200145, 328229],
      [-3228, 29],
      [-2, -6474],
      [-1606, 14],
    ],
    [
      [204574, 338763],
      [16, -15442],
    ],
    [
      [204590, 323321],
      [-1474, -28],
    ],
    [
      [203116, 323293],
      [-4, 3258],
    ],
    [
      [203103, 336617],
      [208, 1296],
      [-208, 257],
    ],
    [
      [203103, 338170],
      [0, 312],
    ],
    [
      [203103, 338482],
      [8, 1396],
    ],
    [
      [249362, 339384],
      [-275, 31],
      [115, -1382],
      [-335, -1404],
      [-366, -378],
      [270, -1925],
      [-281, -846],
      [135, -919],
    ],
    [
      [248625, 332561],
      [-364, 176],
      [-4, -2806],
    ],
    [
      [248257, 329931],
      [-36, -137],
    ],
    [
      [248221, 329794],
      [-36, 1335],
      [-212, -483],
    ],
    [
      [247973, 330646],
      [-400, 66],
    ],
    [
      [247573, 330712],
      [0, 4865],
    ],
    [
      [247573, 335577],
      [-6, 5520],
    ],
    [
      [272433, 336843],
      [-475, -3705],
    ],
    [
      [271958, 333138],
      [-10, -83],
    ],
    [
      [271948, 333055],
      [-430, 282],
    ],
    [
      [271518, 333337],
      [-367, -86],
    ],
    [
      [271151, 333251],
      [-126, 2692],
      [-326, 1639],
      [222, 1422],
      [-102, 1406],
    ],
    [
      [251392, 337392],
      [-291, -2345],
      [-136, 397],
    ],
    [
      [250965, 335444],
      [-553, 1154],
      [-247, 1169],
    ],
    [
      [250165, 337767],
      [38, 1181],
    ],
    [
      [247573, 335577],
      [-2090, 162],
    ],
    [
      [245483, 335739],
      [13, 3285],
    ],
    [
      [286926, 335501],
      [-145, -1821],
      [-233, 206],
      [79, 1506],
      [-351, 140],
    ],
    [
      [286276, 335532],
      [78, 4358],
    ],
    [
      [286354, 339890],
      [552, 1171],
      [303, -80],
      [50, -5636],
      [-333, 156],
    ],
    [
      [256131, 338383],
      [-53, -2345],
      [-292, -1220],
    ],
    [
      [255786, 334818],
      [-336, -820],
      [-354, 712],
      [-199, -818],
    ],
    [
      [254897, 333892],
      [-239, 1015],
      [76, 3255],
    ],
    [
      [286276, 335532],
      [-676, 145],
    ],
    [
      [285600, 335677],
      [-577, 4],
    ],
    [
      [285419, 339738],
      [847, 1102],
      [88, -950],
    ],
    [
      [203103, 338482],
      [0, -312],
    ],
    [
      [259837, 338179],
      [-278, -1956],
      [-1, -1386],
    ],
    [
      [259558, 334837],
      [-289, -248],
      [-329, 1061],
    ],
    [
      [271151, 333251],
      [-416, -936],
      [-546, 60],
    ],
    [
      [270189, 332375],
      [-338, 1184],
      [43, 2103],
    ],
    [
      [250165, 337767],
      [-126, 96],
      [-281, -4503],
    ],
    [
      [249758, 333360],
      [-309, 1168],
      [-404, 124],
      [-420, -2091],
    ],
    [
      [229927, 334443],
      [-12, -3281],
    ],
    [
      [229915, 331162],
      [-1439, 22],
    ],
    [
      [228476, 331184],
      [1, 4846],
    ],
    [
      [245032, 339117],
      [-92, -1440],
      [-340, -1929],
      [-44, -3402],
      [-591, 77],
    ],
    [
      [243965, 332423],
      [-591, 68],
    ],
    [
      [243374, 332491],
      [12, 3306],
      [-132, -126],
    ],
    [
      [277508, 338250],
      [-37, -6115],
    ],
    [
      [277471, 332135],
      [-595, -66],
    ],
    [
      [276876, 332069],
      [-829, -108],
    ],
    [
      [264626, 337536],
      [88, -2617],
      [-150, -1005],
    ],
    [
      [264564, 333914],
      [-285, 822],
      [-464, 206],
      [-191, -774],
    ],
    [
      [263624, 334168],
      [-163, 377],
    ],
    [
      [245483, 335739],
      [-6, -4945],
    ],
    [
      [245477, 330794],
      [-2, -1651],
    ],
    [
      [245475, 329143],
      [-590, 63],
      [-3, 1642],
      [-263, -127],
    ],
    [
      [244619, 330721],
      [-662, 64],
      [8, 1638],
    ],
    [
      [266028, 333144],
      [-304, -143],
      [-504, -1878],
    ],
    [
      [265220, 331123],
      [-22, 58],
    ],
    [
      [265198, 331181],
      [-124, 1694],
      [-214, -289],
      [-296, 1328],
    ],
    [
      [279260, 333397],
      [-157, -1140],
    ],
    [
      [279103, 332257],
      [-411, 1984],
      [-405, -1720],
      [-166, 127],
    ],
    [
      [278121, 332648],
      [-77, -460],
    ],
    [
      [278044, 332188],
      [-573, -53],
    ],
    [
      [210378, 329802],
      [0, -2771],
      [-614, 433],
      [-745, -2270],
    ],
    [
      [209019, 325194],
      [-2, 1368],
      [-3247, 12],
      [1, -3243],
    ],
    [
      [205771, 323331],
      [-1181, -10],
    ],
    [
      [282748, 335160],
      [-108, -471],
    ],
    [
      [282640, 334689],
      [-343, -1479],
    ],
    [
      [282297, 333210],
      [-672, 237],
    ],
    [
      [281625, 333447],
      [-169, 331],
      [-186, 2366],
    ],
    [
      [275724, 331937],
      [-313, -27],
    ],
    [
      [275411, 331910],
      [-1231, 85],
    ],
    [
      [232307, 338491],
      [0, -2433],
      [148, -23],
      [1, -3216],
    ],
    [
      [232456, 332819],
      [-305, -2],
      [-149, -3256],
      [-295, 1],
    ],
    [
      [231707, 329562],
      [-295, 1],
      [-1, 3262],
      [-292, -4],
      [0, 1623],
    ],
    [
      [234087, 334441],
      [-14, -2089],
      [230, -1261],
    ],
    [
      [234303, 331091],
      [-538, -3686],
      [-283, 598],
    ],
    [
      [233482, 328003],
      [-1, 4815],
      [-1025, 1],
    ],
    [
      [260623, 337347],
      [23, -3296],
      [103, -1581],
    ],
    [
      [260749, 332470],
      [-138, -25],
    ],
    [
      [260611, 332445],
      [-753, -140],
    ],
    [
      [259858, 332305],
      [-300, 2532],
    ],
    [
      [257338, 335707],
      [-156, -2905],
      [-342, -2492],
    ],
    [
      [256840, 330310],
      [-446, 716],
      [-239, -414],
    ],
    [
      [256155, 330612],
      [-245, 215],
    ],
    [
      [255910, 330827],
      [24, 1814],
      [-148, 2177],
    ],
    [
      [254026, 337733],
      [-164, -1607],
      [-17, -2092],
      [167, -1717],
      [-89, -1888],
    ],
    [
      [253923, 330429],
      [-48, -579],
    ],
    [
      [253875, 329850],
      [-477, -215],
      [-128, 791],
    ],
    [
      [253270, 330426],
      [-3, 1967],
      [147, 1493],
      [31, 3867],
    ],
    [
      [254897, 333892],
      [-166, -2346],
    ],
    [
      [254731, 331546],
      [-712, -436],
      [-96, -681],
    ],
    [
      [284107, 336196],
      [0, -1815],
      [235, -1426],
    ],
    [
      [284342, 332955],
      [-25, -150],
    ],
    [
      [284317, 332805],
      [-216, -594],
      [-40, -1864],
    ],
    [
      [284061, 330347],
      [-198, -1249],
      [-361, -217],
    ],
    [
      [283502, 328881],
      [-237, 1612],
    ],
    [
      [283265, 330493],
      [-76, 1699],
      [-549, 2497],
    ],
    [
      [273559, 332749],
      [-1601, 389],
    ],
    [
      [270189, 332375],
      [-269, -1121],
    ],
    [
      [269920, 331254],
      [-1335, -829],
    ],
    [
      [268585, 330425],
      [-153, 883],
      [95, 1791],
      [-330, 2070],
    ],
    [
      [263072, 336564],
      [-195, -3681],
      [-172, -21],
      [-175, -2137],
      [-273, -558],
    ],
    [
      [262257, 330167],
      [-331, 919],
    ],
    [
      [261926, 331086],
      [76, 2096],
      [534, 3544],
    ],
    [
      [261543, 337211],
      [52, -385],
    ],
    [
      [261595, 336801],
      [-473, -3690],
    ],
    [
      [261122, 333111],
      [-373, -641],
    ],
    [
      [253270, 330426],
      [-331, -79],
    ],
    [
      [252939, 330347],
      [-8, 1375],
      [-395, 358],
      [-301, 1417],
    ],
    [
      [252235, 333497],
      [19, 3746],
    ],
    [
      [250965, 335444],
      [-26, -4865],
    ],
    [
      [250939, 330579],
      [-293, -638],
    ],
    [
      [250646, 329941],
      [-810, 109],
    ],
    [
      [249836, 330050],
      [-78, 3310],
    ],
    [
      [281625, 333447],
      [-251, -3124],
      [4, -1252],
      [-421, -1196],
    ],
    [
      [280957, 327875],
      [-522, -413],
      [-138, 954],
    ],
    [
      [280297, 328416],
      [-332, 3370],
      [-134, 425],
    ],
    [
      [224348, 332816],
      [-1, -1627],
    ],
    [
      [224347, 331189],
      [-2064, 20],
    ],
    [
      [222283, 331209],
      [0, 811],
    ],
    [
      [179740, 322568],
      [3, -2452],
      [444, -2894],
      [103, -2182],
      [267, -2704],
      [564, -2760],
    ],
    [
      [181121, 309576],
      [-326, -2417],
      [-494, -1592],
    ],
    [
      [180301, 305567],
      [-2460, -124],
      [1, -805],
      [-4499, -63],
      [2, -553],
      [-829, -9],
      [-928, 528],
      [-315, -3026],
    ],
    [
      [171273, 301515],
      [-312, 1400],
    ],
    [
      [170961, 302915],
      [145, 1407],
      [238, 4958],
      [-64, 9969],
    ],
    [
      [171280, 319249],
      [98, 4],
      [-3, 18110],
    ],
    [
      [164248, 337231],
      [-3, -3233],
      [301, -23],
      [0, -1605],
      [244, -1640],
      [325, -33],
      [5, -1631],
      [192, 12],
      [4, -1614],
      [397, -45],
      [3, -1614],
      [314, 77],
      [-22, -1690],
      [246, -211],
      [0, -3299],
    ],
    [
      [166254, 320682],
      [-755, 1375],
      [-936, 2625],
      [-284, -1411],
      [-421, -724],
      [101, -1704],
      [-444, 1648],
      [-536, -409],
    ],
    [
      [162979, 322082],
      [-5, 3222],
      [-296, 222],
      [-393, 1771],
      [197, 1535],
      [-165, 1791],
      [-326, 509],
      [-446, 3198],
      [-339, 588],
      [-168, 2433],
    ],
    [
      [171280, 319249],
      [-3410, -90],
      [36, -551],
    ],
    [
      [167906, 318608],
      [-267, 446],
      [-750, 39],
      [-88, 1202],
      [-462, 376],
    ],
    [
      [166339, 320671],
      [-85, 11],
    ],
    [
      [252235, 333497],
      [-177, -1852],
      [-458, -1134],
    ],
    [
      [251600, 330511],
      [-661, 68],
    ],
    [
      [242130, 335805],
      [-34, -6486],
    ],
    [
      [242096, 329319],
      [-637, 87],
    ],
    [
      [241459, 329406],
      [3, 1623],
      [-1032, 137],
    ],
    [
      [240430, 331166],
      [104, 1602],
      [10, 3228],
    ],
    [
      [268585, 330425],
      [-242, -1949],
      [-248, -496],
    ],
    [
      [268095, 327980],
      [-738, 4146],
    ],
    [
      [267357, 332126],
      [82, 740],
      [-272, 2637],
    ],
    [
      [238036, 329533],
      [-270, 1020],
      [-9, -2177],
      [-298, 111],
      [-9, -1669],
      [-302, -290],
    ],
    [
      [237148, 326528],
      [-149, 21],
      [20, 4291],
    ],
    [
      [237019, 330840],
      [144, 2397],
      [-140, 1638],
      [249, -26],
      [204, 1843],
    ],
    [
      [238075, 336857],
      [-39, -7324],
    ],
    [
      [261926, 331086],
      [-253, -1944],
    ],
    [
      [261673, 329142],
      [-494, 2572],
      [-57, 1397],
    ],
    [
      [239556, 336116],
      [-9, -2441],
      [-149, 23],
      [-7, -2427],
      [-202, 25],
      [-8, -2654],
    ],
    [
      [239181, 328642],
      [-294, 242],
      [-94, 1576],
      [-757, -927],
    ],
    [
      [237019, 330840],
      [-164, -1085],
      [-526, -532],
      [-175, 1675],
      [-128, -957],
    ],
    [
      [236026, 329941],
      [-115, 4493],
    ],
    [
      [263362, 334549],
      [-670, -5484],
    ],
    [
      [262692, 329065],
      [-240, -1164],
    ],
    [
      [262452, 327901],
      [-195, 2266],
    ],
    [
      [212037, 334134],
      [-2, -8177],
    ],
    [
      [212035, 325957],
      [-1, -4264],
    ],
    [
      [212034, 321693],
      [-669, 4],
      [-4, -1665],
      [-293, 7],
      [-3, -1619],
      [-292, 8],
      [-1, -1623],
      [-585, 17],
      [-91, -1634],
    ],
    [
      [210096, 315188],
      [-586, 7],
    ],
    [
      [209510, 315195],
      [-502, 3],
      [-1, 3241],
    ],
    [
      [209007, 318439],
      [12, 6755],
    ],
    [
      [284317, 332805],
      [371, -2175],
      [955, -1730],
      [26, -1206],
    ],
    [
      [285669, 327694],
      [-54, -767],
      [-593, -358],
      [-140, 698],
    ],
    [
      [284882, 327267],
      [-821, 3080],
    ],
    [
      [285600, 335677],
      [126, -1604],
      [273, -883],
    ],
    [
      [285999, 333190],
      [-398, -1100],
      [175, -2297],
      [-1124, 1768],
      [-310, 1394],
    ],
    [
      [240430, 331166],
      [-12, -5402],
      [-114, -39],
    ],
    [
      [240304, 325725],
      [-550, -592],
      [-185, 1872],
      [-331, 1451],
    ],
    [
      [239238, 328456],
      [-57, 186],
    ],
    [
      [226992, 336062],
      [8, -6492],
    ],
    [
      [227000, 329570],
      [-1, -749],
    ],
    [
      [226999, 328821],
      [0, -19],
    ],
    [
      [226999, 328802],
      [-475, -44],
      [-290, 766],
      [-418, 55],
    ],
    [
      [225816, 329579],
      [-586, -9],
      [-19, 3244],
    ],
    [
      [228476, 331184],
      [-1, -1622],
    ],
    [
      [228475, 329562],
      [-1475, 8],
    ],
    [
      [243374, 332491],
      [-161, -3273],
      [-723, 82],
    ],
    [
      [242490, 329300],
      [-394, 19],
    ],
    [
      [257735, 335317],
      [-73, -4276],
      [185, -1700],
    ],
    [
      [257847, 329341],
      [-411, -2087],
      [-227, 202],
    ],
    [
      [257209, 327456],
      [-369, 2854],
    ],
    [
      [247573, 330712],
      [-600, 58],
    ],
    [
      [246973, 330770],
      [-1496, 24],
    ],
    [
      [286926, 335501],
      [415, -496],
    ],
    [
      [287690, 334442],
      [20, -1175],
      [-419, -3065],
      [-333, -1394],
      [-288, 214],
      [-380, 1512],
      [-221, -1047],
      [-325, 2540],
      [265, -143],
      [-10, 1306],
    ],
    [
      [259858, 332305],
      [-101, -4380],
    ],
    [
      [259757, 327925],
      [-680, 1377],
      [-289, -544],
    ],
    [
      [258788, 328758],
      [11, 1469],
    ],
    [
      [258799, 330227],
      [35, 4085],
    ],
    [
      [258799, 330227],
      [-519, -1708],
      [-228, 632],
    ],
    [
      [258052, 329151],
      [-205, 190],
    ],
    [
      [267357, 332126],
      [-461, -863],
      [33, -2552],
    ],
    [
      [266929, 328711],
      [-948, -960],
    ],
    [
      [265981, 327751],
      [263, 2892],
      [-1024, 480],
    ],
    [
      [265198, 331181],
      [-169, -1111],
      [-21, -2086],
    ],
    [
      [265008, 327984],
      [-272, -947],
      [-243, 467],
      [-219, -1109],
    ],
    [
      [264274, 326395],
      [-566, 1467],
    ],
    [
      [263708, 327862],
      [264, 835],
      [-23, 1267],
      [-316, 3898],
    ],
    [
      [263633, 333862],
      [1, 202],
    ],
    [
      [263634, 334064],
      [-10, 104],
    ],
    [
      [255910, 330827],
      [-429, 244],
      [-353, -1106],
    ],
    [
      [255128, 329965],
      [-397, 1581],
    ],
    [
      [283265, 330493],
      [-572, -1458],
      [-346, 398],
    ],
    [
      [282347, 329433],
      [-50, 3777],
    ],
    [
      [248221, 329794],
      [-58, -95],
    ],
    [
      [248163, 329699],
      [-190, 947],
    ],
    [
      [249836, 330050],
      [-441, -507],
    ],
    [
      [249395, 329543],
      [-1138, 388],
    ],
    [
      [263634, 334064],
      [-1, -202],
    ],
    [
      [263708, 327862],
      [-580, -828],
    ],
    [
      [263128, 327034],
      [-436, 2031],
    ],
    [
      [236026, 329941],
      [-4, -304],
    ],
    [
      [236022, 329637],
      [-175, -1477],
      [-443, -195],
      [-252, 1214],
      [-191, -612],
    ],
    [
      [234961, 328567],
      [-77, 886],
      [-581, 1638],
    ],
    [
      [231707, 329562],
      [0, -1617],
    ],
    [
      [231707, 327945],
      [-1281, 2],
    ],
    [
      [230426, 327947],
      [0, 3299],
      [-261, -1414],
      [-247, 176],
    ],
    [
      [229918, 330008],
      [-3, 1154],
    ],
    [
      [279103, 332257],
      [-595, -3991],
    ],
    [
      [278508, 328266],
      [-251, 855],
      [-213, 3067],
    ],
    [
      [214486, 334091],
      [-13, -8132],
    ],
    [
      [214473, 325959],
      [-2438, -2],
    ],
    [
      [217486, 334073],
      [-2, -8135],
    ],
    [
      [217484, 325938],
      [-1494, 19],
    ],
    [
      [215990, 325957],
      [0, 8137],
    ],
    [
      [219007, 334077],
      [3, -8124],
    ],
    [
      [219010, 325953],
      [-1526, -15],
    ],
    [
      [220510, 330410],
      [0, -4461],
    ],
    [
      [220510, 325949],
      [-1500, 4],
    ],
    [
      [215990, 325957],
      [-1517, 2],
    ],
    [
      [271518, 333337],
      [-29, -4139],
      [-182, -3291],
    ],
    [
      [271307, 325907],
      [-296, 68],
    ],
    [
      [271011, 325975],
      [-264, 59],
    ],
    [
      [270747, 326034],
      [1, 1307],
      [-511, 2796],
      [-304, -278],
    ],
    [
      [269933, 329859],
      [-13, 1395],
    ],
    [
      [282347, 329433],
      [-80, -3574],
    ],
    [
      [282267, 325859],
      [-165, -563],
      [-420, 837],
      [-332, -60],
    ],
    [
      [281350, 326073],
      [-393, 1802],
    ],
    [
      [252939, 330347],
      [-5, -701],
    ],
    [
      [252934, 329646],
      [-672, -91],
      [-323, -2399],
      [-173, 1],
    ],
    [
      [251766, 327157],
      [-166, 3354],
    ],
    [
      [280297, 328416],
      [-211, -1293],
    ],
    [
      [280086, 327123],
      [-581, 255],
      [-754, -1252],
    ],
    [
      [278751, 326126],
      [-353, 1413],
      [110, 727],
    ],
    [
      [271948, 333055],
      [227, -2695],
    ],
    [
      [272175, 330360],
      [382, -2967],
      [-24, -1802],
    ],
    [
      [272533, 325591],
      [-107, 26],
    ],
    [
      [272426, 325617],
      [-1119, 290],
    ],
    [
      [273595, 331632],
      [-24, -1620],
    ],
    [
      [273571, 330012],
      [-1396, 348],
    ],
    [
      [261673, 329142],
      [-449, -3851],
    ],
    [
      [261224, 325291],
      [-188, 2236],
      [-289, 965],
    ],
    [
      [260747, 328492],
      [203, 1622],
      [-339, 2331],
    ],
    [
      [233482, 328003],
      [-312, 226],
    ],
    [
      [233170, 328229],
      [-399, -1548],
      [-1070, -1312],
    ],
    [
      [231701, 325369],
      [6, 2576],
    ],
    [
      [225816, 329579],
      [10, -9732],
    ],
    [
      [225826, 319847],
      [-1469, 0],
    ],
    [
      [224357, 319847],
      [-5, 4513],
    ],
    [
      [224352, 324360],
      [-5, 6829],
    ],
    [
      [244619, 330721],
      [-212, -1147],
      [105, -2458],
      [-223, -2903],
    ],
    [
      [244289, 324213],
      [-330, 36],
      [-3, -1236],
      [-368, 689],
      [-238, -589],
    ],
    [
      [243350, 323113],
      [-586, 854],
      [-297, -183],
    ],
    [
      [242467, 323784],
      [23, 5516],
    ],
    [
      [260747, 328492],
      [-422, -561],
      [-84, -1375],
      [-372, 150],
    ],
    [
      [259869, 326706],
      [-112, 1219],
    ],
    [
      [188318, 312721],
      [-108, -986],
    ],
    [
      [188210, 311735],
      [-183, -611],
      [-147, -4378],
      [653, -3],
      [-47, -2816],
    ],
    [
      [188486, 303927],
      [-643, 1],
      [-1223, 898],
      [-311, -3086],
      [-1306, 2189],
      [-1641, -13],
    ],
    [
      [183362, 303916],
      [1, 5933],
    ],
    [
      [183363, 309849],
      [-2, 22528],
    ],
    [
      [268095, 327980],
      [-175, -2988],
      [-203, -1444],
      [134, -484],
    ],
    [
      [267851, 323064],
      [-277, -501],
    ],
    [
      [267574, 322563],
      [-1, -3],
    ],
    [
      [267573, 322560],
      [-644, 6151],
    ],
    [
      [274045, 331971],
      [332, -4443],
      [324, -1102],
    ],
    [
      [274701, 326426],
      [-806, -3867],
    ],
    [
      [273895, 322559],
      [-185, 1400],
    ],
    [
      [273710, 323959],
      [-377, -576],
      [22, 1922],
    ],
    [
      [273355, 325305],
      [216, 4707],
    ],
    [
      [278751, 326126],
      [-5, -338],
    ],
    [
      [278746, 325788],
      [-367, 682],
      [-294, -913],
      [-345, -2209],
    ],
    [
      [277740, 323348],
      [-312, 427],
      [-120, 1819],
    ],
    [
      [277308, 325594],
      [-233, 1635],
      [-199, 4840],
    ],
    [
      [277308, 325594],
      [-662, 369],
      [-623, -753],
    ],
    [
      [276023, 325210],
      [-58, 1792],
      [126, 2469],
      [-367, 2466],
    ],
    [
      [275411, 331910],
      [-586, -5936],
    ],
    [
      [274825, 325974],
      [-124, 452],
    ],
    [
      [222283, 331209],
      [11, -6489],
    ],
    [
      [222294, 324720],
      [-137, -41],
    ],
    [
      [222157, 324679],
      [-1041, 42],
      [0, -1614],
      [-294, 3],
    ],
    [
      [220822, 323110],
      [-312, 20],
    ],
    [
      [220510, 323130],
      [0, 2819],
    ],
    [
      [276023, 325210],
      [-562, 989],
    ],
    [
      [275461, 326199],
      [-228, -565],
      [-408, 340],
    ],
    [
      [269933, 329859],
      [-238, -2104],
      [-19, -1655],
    ],
    [
      [269676, 326100],
      [-619, -853],
    ],
    [
      [269057, 325247],
      [-122, 2958],
      [-350, 2220],
    ],
    [
      [255128, 329965],
      [-90, -7353],
    ],
    [
      [255038, 322612],
      [-1051, 45],
    ],
    [
      [253987, 322657],
      [8, 5395],
      [-120, 1798],
    ],
    [
      [230426, 327947],
      [0, -3244],
      [-137, 1],
      [-1, -3821],
    ],
    [
      [230288, 320883],
      [-667, -984],
      [-127, 786],
    ],
    [
      [229494, 320685],
      [-2, 9491],
      [426, -168],
    ],
    [
      [265981, 327751],
      [-64, -576],
    ],
    [
      [265917, 327175],
      [-396, 127],
      [-315, -711],
      [-198, 1393],
    ],
    [
      [224352, 324360],
      [-359, 353],
      [-1699, 7],
    ],
    [
      [229494, 320685],
      [-431, 1199],
    ],
    [
      [229063, 321884],
      [-238, -1062],
      [-352, 454],
    ],
    [
      [228473, 321276],
      [2, 8286],
    ],
    [
      [241459, 329406],
      [-204, -4750],
    ],
    [
      [241255, 324656],
      [-449, -779],
      [-905, 103],
    ],
    [
      [239901, 323980],
      [403, 1745],
    ],
    [
      [256155, 330612],
      [-49, -8076],
    ],
    [
      [256106, 322536],
      [-1068, 76],
    ],
    [
      [234961, 328567],
      [-3, -2253],
      [-315, -6],
      [0, -2676],
    ],
    [
      [234643, 323632],
      [-1174, 5],
    ],
    [
      [233469, 323637],
      [0, 1895],
      [-295, 3],
      [-4, 2694],
    ],
    [
      [262452, 327901],
      [-222, -2978],
      [129, -2605],
    ],
    [
      [262359, 322318],
      [-796, -48],
    ],
    [
      [261563, 322270],
      [-276, -33],
    ],
    [
      [261287, 322237],
      [-306, -7],
    ],
    [
      [260981, 322230],
      [247, 1084],
      [-4, 1977],
    ],
    [
      [257209, 327456],
      [-20, -5059],
    ],
    [
      [257189, 322397],
      [-1046, 132],
    ],
    [
      [256143, 322529],
      [-37, 7],
    ],
    [
      [237148, 326528],
      [-7, -1358],
      [-305, -767],
    ],
    [
      [236836, 324403],
      [-229, -383],
      [-21, -2059],
      [-606, -644],
    ],
    [
      [235980, 321317],
      [42, 8320],
    ],
    [
      [246973, 330770],
      [-2, -5539],
    ],
    [
      [246971, 325231],
      [-1504, 75],
    ],
    [
      [245467, 325306],
      [8, 3837],
    ],
    [
      [245467, 325306],
      [-8, -2742],
      [-290, 30],
    ],
    [
      [245169, 322594],
      [-399, -324],
      [-210, -1347],
    ],
    [
      [244560, 320923],
      [13, 2577],
      [-284, 713],
    ],
    [
      [248163, 329699],
      [-214, -2387],
      [219, -627],
      [20, -1533],
      [-293, -395],
      [-116, -1725],
      [-268, -566],
    ],
    [
      [247511, 322466],
      [186, -1296],
      [-172, -1230],
    ],
    [
      [247525, 319940],
      [-289, -503],
    ],
    [
      [247236, 319437],
      [-2, 1326],
    ],
    [
      [247234, 320763],
      [22, 4543],
      [-285, -75],
    ],
    [
      [251766, 327157],
      [-13, -4699],
    ],
    [
      [251753, 322458],
      [-101, 0],
    ],
    [
      [251652, 322458],
      [-541, -5],
    ],
    [
      [251111, 322453],
      [-505, -9],
    ],
    [
      [250606, 322444],
      [40, 7497],
    ],
    [
      [283502, 328881],
      [-231, -2074],
    ],
    [
      [283271, 326807],
      [-356, -2933],
      [-358, -1181],
    ],
    [
      [282557, 322693],
      [-108, 2562],
      [-182, 604],
    ],
    [
      [239238, 328456],
      [-4, -1941],
      [-493, 49],
      [-203, -1309],
      [-493, -195],
      [5, -2146],
    ],
    [
      [238050, 322914],
      [-1029, 136],
      [-185, 1353],
    ],
    [
      [269057, 325247],
      [-524, -1430],
    ],
    [
      [268533, 323817],
      [-371, -223],
    ],
    [
      [268162, 323594],
      [-311, -530],
    ],
    [
      [253987, 322657],
      [-600, -192],
    ],
    [
      [253387, 322465],
      [-453, -3],
    ],
    [
      [252934, 322462],
      [-51, 3],
    ],
    [
      [252883, 322465],
      [51, 7181],
    ],
    [
      [273355, 325305],
      [-822, 286],
    ],
    [
      [285469, 320638],
      [-203, -948],
      [-926, -816],
    ],
    [
      [284340, 318874],
      [-64, 5013],
    ],
    [
      [284276, 323887],
      [204, 503],
      [398, -2748],
      [591, -1004],
    ],
    [
      [284882, 327267],
      [-235, -2198],
      [118, -897],
    ],
    [
      [284765, 324172],
      [-259, 864],
      [-282, -1162],
    ],
    [
      [284224, 323874],
      [-953, 2933],
    ],
    [
      [258788, 328758],
      [-158, -3843],
    ],
    [
      [258630, 324915],
      [-206, 2015],
      [-376, 1415],
      [4, 806],
    ],
    [
      [249395, 329543],
      [-32, -7084],
    ],
    [
      [249363, 322459],
      [-223, -9],
    ],
    [
      [249140, 322450],
      [-1629, 16],
    ],
    [
      [270747, 326034],
      [-688, 163],
    ],
    [
      [270059, 326197],
      [-383, -97],
    ],
    [
      [250606, 322444],
      [-430, -12],
    ],
    [
      [250176, 322432],
      [-813, 27],
    ],
    [
      [252883, 322465],
      [-1130, -7],
    ],
    [
      [235980, 321317],
      [-18, -3815],
    ],
    [
      [235962, 317502],
      [-20, -4124],
    ],
    [
      [235942, 313378],
      [-1326, -11],
    ],
    [
      [234616, 313367],
      [-2, 3239],
      [-338, -2],
    ],
    [
      [234276, 316604],
      [0, 3244],
      [171, 1607],
      [196, 17],
      [0, 2160],
    ],
    [
      [226999, 328802],
      [9, -8951],
    ],
    [
      [227008, 319851],
      [-1, -3236],
    ],
    [
      [227007, 316615],
      [-1172, 1],
    ],
    [
      [225835, 316616],
      [-9, 3231],
    ],
    [
      [228473, 321276],
      [-555, 611],
      [-74, 2247],
      [-845, 4687],
    ],
    [
      [258630, 324915],
      [-2, -2532],
    ],
    [
      [258628, 322383],
      [-1295, 15],
    ],
    [
      [257333, 322398],
      [-144, -1],
    ],
    [
      [242467, 323784],
      [-5, -1009],
    ],
    [
      [242462, 322775],
      [-297, 41],
      [-5, -1617],
      [-876, 479],
    ],
    [
      [241284, 321678],
      [-151, 871],
      [122, 2107],
    ],
    [
      [259869, 326706],
      [27, -4377],
    ],
    [
      [259896, 322329],
      [-1243, 53],
    ],
    [
      [258653, 322382],
      [-25, 1],
    ],
    [
      [263128, 327034],
      [11, -1303],
      [-212, -3409],
    ],
    [
      [262927, 322322],
      [-101, -5],
    ],
    [
      [262826, 322317],
      [-467, 1],
    ],
    [
      [200462, 326631],
      [361, -4881],
    ],
    [
      [200823, 321750],
      [-379, 16],
      [-5, -7060],
    ],
    [
      [200439, 314706],
      [-1451, -14],
    ],
    [
      [198988, 314692],
      [-3680, 23],
    ],
    [
      [229063, 321884],
      [-7, -2046],
    ],
    [
      [229056, 319838],
      [-2048, 13],
    ],
    [
      [267573, 322560],
      [-1043, -137],
    ],
    [
      [266530, 322423],
      [-489, 3118],
      [-223, -91],
    ],
    [
      [265818, 325450],
      [99, 1725],
    ],
    [
      [285669, 327694],
      [310, 746],
      [30, -1788],
      [-452, -995],
      [295, -346],
      [-138, -1387],
      [-575, -2025],
      [-396, 1477],
      [22, 796],
    ],
    [
      [260981, 322230],
      [-364, 32],
    ],
    [
      [260617, 322262],
      [-721, 67],
    ],
    [
      [281350, 326073],
      [46, -5342],
      [-149, -3077],
      [243, -284],
    ],
    [
      [281490, 317370],
      [-394, -3132],
    ],
    [
      [281096, 314238],
      [-204, 2184],
      [-465, 3449],
    ],
    [
      [280427, 319871],
      [-419, 2517],
      [-71, 1882],
      [149, 2853],
    ],
    [
      [239901, 323980],
      [-690, -4023],
      [1, -1632],
    ],
    [
      [239212, 318325],
      [-296, -555],
    ],
    [
      [238916, 317770],
      [-881, 35],
    ],
    [
      [238035, 317805],
      [15, 5109],
    ],
    [
      [233469, 323637],
      [-441, -557],
      [-20, -6469],
    ],
    [
      [233008, 316611],
      [1, -1619],
      [-439, -6],
    ],
    [
      [232570, 314986],
      [-587, -6],
      [0, 1619],
      [-585, 2],
    ],
    [
      [231398, 316601],
      [2, 1620],
    ],
    [
      [231400, 318221],
      [7, 5253],
      [294, 1895],
    ],
    [
      [265818, 325450],
      [-331, -347],
      [-413, -2793],
    ],
    [
      [265074, 322310],
      [-349, 6],
    ],
    [
      [264725, 322316],
      [-533, 16],
    ],
    [
      [264192, 322332],
      [82, 4063],
    ],
    [
      [231400, 318221],
      [-877, 0],
    ],
    [
      [230523, 318221],
      [1, 2620],
      [-236, 42],
    ],
    [
      [264192, 322332],
      [-835, -2],
    ],
    [
      [263357, 322330],
      [-430, -8],
    ],
    [
      [280427, 319871],
      [-1132, -401],
    ],
    [
      [279295, 319470],
      [-372, 2213],
    ],
    [
      [278923, 321683],
      [-155, 1648],
      [-22, 2457],
    ],
    [
      [284224, 323874],
      [52, 13],
    ],
    [
      [284340, 318874],
      [-211, -331],
    ],
    [
      [284129, 318543],
      [-198, 2128],
      [-360, 239],
      [-380, 1072],
      [-481, 71],
    ],
    [
      [282710, 322053],
      [-153, 640],
    ],
    [
      [203116, 323293],
      [0, -1615],
      [261, 2],
      [1, -1550],
      [-729, 1],
    ],
    [
      [202649, 320131],
      [-58, 616],
      [-705, 53],
      [-101, -673],
      [-844, 4],
      [-118, 1619],
    ],
    [
      [209007, 318439],
      [-587, -7],
      [1, -1615],
      [-294, -10],
      [-3, -1617],
      [-1242, -7],
      [0, -4792],
    ],
    [
      [206882, 310391],
      [-1173, -5],
    ],
    [
      [205709, 310386],
      [1, 4846],
      [62, -6],
      [-1, 8105],
    ],
    [
      [270059, 326197],
      [-28, -6431],
      [221, -1212],
    ],
    [
      [270252, 318554],
      [-254, -3697],
      [-212, -1915],
    ],
    [
      [269786, 312942],
      [-11, 23],
    ],
    [
      [269775, 312965],
      [-379, 2612],
      [-91, 3608],
    ],
    [
      [269305, 319185],
      [-142, 3645],
      [-154, 854],
      [-476, 133],
    ],
    [
      [276023, 325210],
      [227, -1072],
      [186, 597],
      [147, -1356],
      [71, -2679],
      [-218, -1752],
    ],
    [
      [276436, 318948],
      [-6, 0],
    ],
    [
      [276430, 318948],
      [-1089, 137],
    ],
    [
      [275341, 319085],
      [120, 7114],
    ],
    [
      [278923, 321683],
      [-437, -2239],
      [-436, 98],
    ],
    [
      [278050, 319542],
      [11, 671],
    ],
    [
      [278061, 320213],
      [-25, 1291],
      [-296, 1844],
    ],
    [
      [275341, 319085],
      [-679, 65],
    ],
    [
      [274662, 319150],
      [-651, 42],
      [43, 2132],
      [-159, 1235],
    ],
    [
      [271464, 320921],
      [-199, -1405],
      [-198, -4516],
    ],
    [
      [271067, 315000],
      [-604, 2048],
      [-211, 1506],
    ],
    [
      [271011, 325975],
      [333, -4682],
      [120, -372],
    ],
    [
      [282710, 322053],
      [66, -1015],
      [-81, -3692],
    ],
    [
      [282695, 317346],
      [-1205, 24],
    ],
    [
      [214473, 325959],
      [-5, -8115],
    ],
    [
      [214468, 317844],
      [-992, -9],
    ],
    [
      [213476, 317835],
      [-1442, 8],
    ],
    [
      [212034, 317843],
      [0, 3850],
    ],
    [
      [272173, 319555],
      [-237, 565],
    ],
    [
      [271936, 320120],
      [-472, 801],
    ],
    [
      [272426, 325617],
      [-120, -2206],
      [-216, -215],
      [83, -3641],
    ],
    [
      [219010, 325953],
      [-5, -8103],
    ],
    [
      [219005, 317850],
      [-1126, 11],
    ],
    [
      [217879, 317861],
      [-406, 0],
    ],
    [
      [217473, 317861],
      [11, 8077],
    ],
    [
      [220510, 323130],
      [0, -5304],
    ],
    [
      [220510, 317826],
      [-1158, 20],
    ],
    [
      [219352, 317846],
      [-347, 4],
    ],
    [
      [215990, 325957],
      [-18, -8108],
    ],
    [
      [215972, 317849],
      [-1028, 11],
    ],
    [
      [214944, 317860],
      [-476, -16],
    ],
    [
      [277740, 323348],
      [-316, -945],
      [31, -1526],
      [-351, -1962],
    ],
    [
      [277104, 318915],
      [-668, 33],
    ],
    [
      [217473, 317861],
      [-1061, -16],
    ],
    [
      [216412, 317845],
      [-440, 4],
    ],
    [
      [273710, 323959],
      [112, -2797],
      [-91, -1953],
    ],
    [
      [273731, 319209],
      [-1617, 15],
    ],
    [
      [272114, 319224],
      [59, 331],
    ],
    [
      [266530, 322423],
      [-186, -14],
    ],
    [
      [266344, 322409],
      [-1079, -93],
    ],
    [
      [265265, 322316],
      [-191, -6],
    ],
    [
      [247234, 320763],
      [-1924, 142],
      [-2, -829],
    ],
    [
      [245308, 320076],
      [-139, 2518],
    ],
    [
      [224357, 319847],
      [-575, 10],
      [0, -4860],
    ],
    [
      [223782, 314997],
      [-488, -1],
      [-284, 811],
    ],
    [
      [223010, 315807],
      [139, 1188],
      [-267, 11],
      [14, 2696],
      [-285, -467],
    ],
    [
      [222611, 319235],
      [-167, 661],
      [-83, 3113],
      [-204, 1670],
    ],
    [
      [284158, 318179],
      [-29, 364],
    ],
    [
      [285469, 320638],
      [29, 1355],
      [695, 561],
      [-107, -1081],
      [521, 322],
      [-484, -2668],
      [-165, -1717],
      [-182, -44],
      [-131, 1684],
      [-204, -1777],
      [-551, 162],
      [-575, -954],
      [-157, 1698],
    ],
    [
      [222611, 319235],
      [-441, -168],
      [-51, -1639],
      [-679, -8],
    ],
    [
      [221440, 317420],
      [-149, 817],
      [-33, 3268],
      [-436, -2],
      [0, 1607],
    ],
    [
      [164640, 304627],
      [231, -1775],
      [-418, -877],
      [-322, 2121],
      [509, 531],
    ],
    [
      [165054, 305328],
      [976, -1440],
      [-461, -704],
      [-359, 131],
      [-156, 2013],
    ],
    [
      [166339, 320671],
      [-2, -7969],
      [-90, -1807],
    ],
    [
      [166247, 310895],
      [-250, 760],
      [-867, -159],
      [-357, 994],
      [-804, 202],
      [-490, -416],
      [-116, 1396],
      [-373, 1056],
      [124, 2395],
      [-24, 2471],
      [-171, 1150],
      [60, 1338],
    ],
    [
      [241284, 321678],
      [-584, -794],
      [39, -1065],
    ],
    [
      [240739, 319819],
      [-931, 86],
      [-3, -1605],
    ],
    [
      [239805, 318300],
      [-593, 25],
    ],
    [
      [238035, 317805],
      [-614, -1483],
    ],
    [
      [237421, 316322],
      [-244, 500],
      [-834, 91],
      [-381, 589],
    ],
    [
      [244560, 320923],
      [-20, -4593],
      [-199, 25],
      [193, -1934],
    ],
    [
      [244534, 314421],
      [-604, 81],
      [-3, -1622],
      [-230, 23],
    ],
    [
      [243697, 312903],
      [96, 2891],
      [-160, 660],
      [20, 4598],
      [-307, 36],
      [4, 2025],
    ],
    [
      [269305, 319185],
      [-840, -2741],
      [-145, -912],
    ],
    [
      [268320, 315532],
      [-168, 2206],
      [10, 5856],
    ],
    [
      [243697, 312903],
      [-75, 13],
    ],
    [
      [243622, 312916],
      [-905, 125],
    ],
    [
      [242717, 313041],
      [1, 2457],
      [-235, 29],
      [98, 2072],
      [20, 5168],
      [-139, 8],
    ],
    [
      [274662, 319150],
      [434, -3775],
    ],
    [
      [275096, 315375],
      [-397, -1019],
      [74, -878],
      [-605, -586],
      [-6, 1090],
      [-378, -1531],
    ],
    [
      [273784, 312451],
      [4, 1591],
    ],
    [
      [273788, 314042],
      [49, 2841],
      [-106, 2326],
    ],
    [
      [234276, 316604],
      [-1268, 7],
    ],
    [
      [268320, 315532],
      [-415, -2628],
    ],
    [
      [267905, 312904],
      [-174, 218],
    ],
    [
      [267731, 313122],
      [-145, 795],
    ],
    [
      [267586, 313917],
      [-653, 2806],
    ],
    [
      [266933, 316723],
      [-40, 540],
    ],
    [
      [266893, 317263],
      [305, 3008],
      [323, 1133],
      [53, 1159],
    ],
    [
      [205709, 310386],
      [-2, -1614],
      [-1700, 0],
    ],
    [
      [204007, 308772],
      [-1371, -6],
      [-3, 3352],
    ],
    [
      [202633, 312118],
      [-147, 1515],
      [16, 6496],
      [147, 2],
    ],
    [
      [278050, 319542],
      [43, -1242],
      [-360, -2633],
    ],
    [
      [277733, 315667],
      [-629, 3248],
    ],
    [
      [221440, 317420],
      [1, -4034],
      [-493, -32],
    ],
    [
      [220948, 313354],
      [-223, 1273],
      [-207, -261],
    ],
    [
      [220518, 314366],
      [-8, 3460],
    ],
    [
      [242717, 313041],
      [-496, 63],
    ],
    [
      [242221, 313104],
      [-106, 14],
    ],
    [
      [242115, 313118],
      [5, 1627],
      [-291, 31],
      [-264, 1665],
      [-303, 325],
      [6, 1345],
      [-291, 53],
      [9, 1622],
      [-247, 33],
    ],
    [
      [256004, 318053],
      [-462, 772],
    ],
    [
      [255542, 318825],
      [-243, 556],
      [-714, -1852],
      [-404, 1290],
      [-162, 1541],
      [-347, 181],
    ],
    [
      [253672, 320541],
      [-285, 1924],
    ],
    [
      [256143, 322529],
      [-11, -3407],
      [-128, -1069],
    ],
    [
      [245308, 320076],
      [-7, -2464],
      [140, -1661],
    ],
    [
      [245441, 315951],
      [-7, -3254],
      [151, -26],
      [-6, -2481],
      [-150, 6],
    ],
    [
      [245429, 310196],
      [-103, 136],
      [-579, 3569],
      [-213, 520],
    ],
    [
      [266893, 317263],
      [-555, 643],
      [-280, 1346],
    ],
    [
      [266058, 319252],
      [-12, 1037],
      [298, 2120],
    ],
    [
      [257333, 322398],
      [-17, -8213],
    ],
    [
      [257316, 314185],
      [-332, 545],
      [-546, 1972],
    ],
    [
      [256438, 316702],
      [-434, 1351],
    ],
    [
      [252925, 317998],
      [-982, 22],
    ],
    [
      [251943, 318020],
      [-96, 1897],
      [-197, -2],
      [2, 2543],
    ],
    [
      [252934, 322462],
      [-9, -4464],
    ],
    [
      [253555, 314761],
      [-47, -2207],
    ],
    [
      [253508, 312554],
      [-481, 7],
    ],
    [
      [253027, 312561],
      [-4, 5160],
      [-98, 277],
    ],
    [
      [253672, 320541],
      [-117, -5780],
    ],
    [
      [251943, 318020],
      [-47, -2986],
    ],
    [
      [251896, 315034],
      [-984, 33],
    ],
    [
      [250912, 315067],
      [1, 4042],
      [197, 807],
      [1, 2537],
    ],
    [
      [249142, 318304],
      [-519, 9],
      [-251, -975],
    ],
    [
      [248372, 317338],
      [-343, 700],
      [-215, -623],
    ],
    [
      [247814, 317415],
      [-5, 2533],
      [-284, -8],
    ],
    [
      [249140, 322450],
      [2, -4146],
    ],
    [
      [250912, 315067],
      [-438, -278],
    ],
    [
      [250474, 314789],
      [-147, -6],
      [-2, 4867],
      [-149, 0],
      [0, 2782],
    ],
    [
      [250474, 314789],
      [-2, -1631],
    ],
    [
      [250472, 313158],
      [-642, 1090],
      [-534, 3],
    ],
    [
      [249296, 314251],
      [-2, 2446],
      [-152, 1607],
    ],
    [
      [266058, 319252],
      [-82, -420],
    ],
    [
      [265976, 318832],
      [-275, -140],
    ],
    [
      [265701, 318692],
      [-106, 2241],
      [-330, 1383],
    ],
    [
      [258653, 322382],
      [-137, -6674],
      [89, -612],
    ],
    [
      [258605, 315096],
      [-29, -1679],
      [-447, -518],
      [-146, 1195],
    ],
    [
      [257983, 314094],
      [-427, 804],
      [-240, -713],
    ],
    [
      [260678, 319948],
      [-562, -4378],
      [-610, -2702],
      [-150, -76],
    ],
    [
      [259356, 312792],
      [-223, 839],
      [-30, 1451],
      [-498, 14],
    ],
    [
      [260617, 322262],
      [61, -2314],
    ],
    [
      [263365, 319856],
      [101, -595],
      [-208, -1794],
      [7, -2679],
    ],
    [
      [263265, 314788],
      [-172, 733],
      [-550, 214],
    ],
    [
      [262543, 315735],
      [233, 4869],
    ],
    [
      [262776, 320604],
      [50, 1713],
    ],
    [
      [263357, 322330],
      [8, -2474],
    ],
    [
      [264725, 322316],
      [-135, -684],
      [239, -2782],
      [-180, -2853],
    ],
    [
      [264649, 315997],
      [-86, -841],
    ],
    [
      [264563, 315156],
      [-22, 277],
    ],
    [
      [264541, 315433],
      [-329, 3527],
      [-293, 882],
      [-554, 14],
    ],
    [
      [262543, 315735],
      [-381, -217],
    ],
    [
      [262162, 315518],
      [-1, 1816],
      [-262, 888],
    ],
    [
      [261899, 318222],
      [232, 964],
      [228, 3132],
    ],
    [
      [261899, 318222],
      [-334, 1613],
      [-2, 2435],
    ],
    [
      [265701, 318692],
      [-212, -1319],
    ],
    [
      [265489, 317373],
      [-231, 351],
      [-271, -1838],
      [-338, 111],
    ],
    [
      [284158, 318179],
      [119, -1091],
      [-640, -3358],
      [-489, -1594],
    ],
    [
      [283148, 312136],
      [-159, 657],
      [-294, 4553],
    ],
    [
      [261287, 322237],
      [-239, -2821],
      [0, -1352],
      [-233, -2520],
    ],
    [
      [260815, 315544],
      [-137, 4404],
    ],
    [
      [262162, 315518],
      [-52, -656],
    ],
    [
      [262110, 314862],
      [-108, 4],
    ],
    [
      [262002, 314866],
      [-1168, 24],
    ],
    [
      [260834, 314890],
      [-19, 654],
    ],
    [
      [230523, 318221],
      [0, -1628],
      [-295, -3],
      [-4, -3251],
    ],
    [
      [230224, 313339],
      [-873, 12],
    ],
    [
      [229351, 313351],
      [0, 1622],
      [-293, 812],
    ],
    [
      [229058, 315785],
      [-2, 4053],
    ],
    [
      [202633, 312118],
      [-979, 1703],
      [-317, 896],
      [-898, -11],
    ],
    [
      [279295, 319470],
      [266, -2708],
      [-183, -3842],
    ],
    [
      [279378, 312920],
      [-224, -636],
      [-333, -2783],
    ],
    [
      [278821, 309501],
      [-4, 24],
    ],
    [
      [278817, 309525],
      [-1052, 5966],
    ],
    [
      [277765, 315491],
      [-32, 176],
    ],
    [
      [212034, 317843],
      [-3, -8091],
    ],
    [
      [212031, 309752],
      [0, -188],
    ],
    [
      [212031, 309564],
      [-1940, 9],
      [5, 5615],
    ],
    [
      [272114, 319224],
      [155, -4645],
    ],
    [
      [272269, 314579],
      [-1, -1445],
    ],
    [
      [272268, 313134],
      [-327, -920],
      [-286, 1648],
    ],
    [
      [271655, 313862],
      [-588, 1138],
    ],
    [
      [247236, 319437],
      [-213, 565],
      [88, -2172],
      [-320, -435],
      [282, -923],
      [-325, -595],
    ],
    [
      [246748, 315877],
      [-1307, 74],
    ],
    [
      [255542, 318825],
      [30, -628],
      [-318, -3711],
    ],
    [
      [255254, 314486],
      [-1699, 275],
    ],
    [
      [166086, 290611],
      [276, -965],
      [-300, -16],
      [24, 981],
    ],
    [
      [167726, 304768],
      [-743, 1873],
      [-183, 2255],
      [-553, 1999],
    ],
    [
      [167906, 318608],
      [691, -9433],
      [-104, -2110],
      [-329, 6],
      [-438, -2303],
    ],
    [
      [247814, 317415],
      [-122, -2322],
      [127, -841],
    ],
    [
      [247819, 314252],
      [0, -807],
    ],
    [
      [247819, 313445],
      [-294, -28],
      [6, -1584],
      [-272, -13],
    ],
    [
      [247259, 311820],
      [-473, 1881],
    ],
    [
      [246786, 313701],
      [-38, 2176],
    ],
    [
      [260834, 314890],
      [37, -1200],
    ],
    [
      [260871, 313690],
      [-340, -2929],
      [-579, -3107],
    ],
    [
      [259952, 307654],
      [-731, 14],
    ],
    [
      [259221, 307668],
      [-12, 4227],
      [147, 897],
    ],
    [
      [281096, 314238],
      [217, -1643],
      [-222, -1225],
    ],
    [
      [281091, 311370],
      [-528, -425],
      [-618, 1727],
      [-567, 248],
    ],
    [
      [223010, 315807],
      [-346, -3461],
    ],
    [
      [222664, 312346],
      [46, -2146],
    ],
    [
      [222710, 310200],
      [-463, 2268],
      [-59, -1528],
      [-214, 361],
    ],
    [
      [221974, 311301],
      [-292, 391],
      [-321, -718],
      [-413, 2380],
    ],
    [
      [242115, 313118],
      [-438, 43],
      [-4, -817],
      [-514, 69],
      [-222, -771],
    ],
    [
      [240937, 311642],
      [-45, 1094],
      [-302, 570],
    ],
    [
      [240590, 313306],
      [-42, 1616],
      [-478, 872],
      [30, 2513],
      [-295, -7],
    ],
    [
      [225835, 316616],
      [-146, -4],
      [-1, -3243],
    ],
    [
      [225688, 313369],
      [-284, 3],
      [-145, -1083],
      [-582, 7],
      [-438, -808],
    ],
    [
      [224239, 311488],
      [-1, 1890],
      [-456, -2],
      [0, 1621],
    ],
    [
      [229058, 315785],
      [-745, -262],
      [155, -2162],
      [-579, 4],
    ],
    [
      [227889, 313365],
      [-586, 3],
    ],
    [
      [227303, 313368],
      [-1, 3244],
      [-295, 3],
    ],
    [
      [264541, 315433],
      [-415, -1026],
    ],
    [
      [264126, 314407],
      [-860, -258],
    ],
    [
      [263266, 314149],
      [-1, 639],
    ],
    [
      [266933, 316723],
      [-339, -3833],
    ],
    [
      [266594, 312890],
      [-433, -921],
    ],
    [
      [266161, 311969],
      [-142, 1338],
    ],
    [
      [266019, 313307],
      [139, 813],
      [-135, 1990],
      [115, 1469],
      [-162, 1253],
    ],
    [
      [168718, 285870],
      [613, -3553],
      [-362, 241],
      [-251, 3312],
    ],
    [
      [168675, 294217],
      [663, -1336],
      [119, -1995],
      [-366, 418],
      [-149, 2047],
      [-267, 866],
    ],
    [
      [170961, 302915],
      [-533, 9],
      [-383, -3729],
    ],
    [
      [170045, 299195],
      [-299, 468],
      [-188, -1226],
      [-342, 667],
      [55, 1824],
      [-185, 2348],
      [-237, 1371],
      [-531, -106],
      [-203, -603],
      [-389, 830],
    ],
    [
      [273788, 314042],
      [-1519, 537],
    ],
    [
      [269786, 312942],
      [-1189, -5125],
    ],
    [
      [268597, 307817],
      [-93, 1502],
    ],
    [
      [268504, 309319],
      [-251, 3128],
      [-348, 457],
    ],
    [
      [276707, 313833],
      [-152, -754],
      [-450, 18],
      [-676, -2348],
    ],
    [
      [275429, 310749],
      [-151, 2724],
    ],
    [
      [275278, 313473],
      [-182, 1902],
    ],
    [
      [276430, 318948],
      [164, -2140],
      [207, -809],
      [-94, -2166],
    ],
    [
      [277765, 315491],
      [-510, -6013],
    ],
    [
      [277255, 309478],
      [-68, 132],
    ],
    [
      [277187, 309610],
      [-190, 3520],
      [-290, 703],
    ],
    [
      [256438, 316702],
      [-14, -6930],
    ],
    [
      [256424, 309772],
      [0, -270],
    ],
    [
      [256424, 309502],
      [-1170, 99],
    ],
    [
      [255254, 309601],
      [0, 4885],
    ],
    [
      [266019, 313307],
      [-494, 36],
    ],
    [
      [265525, 313343],
      [-95, 2291],
      [59, 1739],
    ],
    [
      [271655, 313862],
      [-387, -3128],
      [-316, -1221],
      [-138, -1800],
    ],
    [
      [270814, 307713],
      [-619, 2360],
      [-220, 1485],
    ],
    [
      [269975, 311558],
      [-189, 1384],
    ],
    [
      [209510, 315195],
      [7, -9723],
    ],
    [
      [209517, 305472],
      [-585, 10],
      [0, -1637],
      [-1755, 87],
      [0, 1633],
      [-298, 9],
    ],
    [
      [206879, 305574],
      [3, 4817],
    ],
    [
      [249296, 314251],
      [-147, -1],
    ],
    [
      [249149, 314250],
      [-1330, 2],
    ],
    [
      [240590, 313306],
      [-340, 47],
      [-91, -2176],
      [-1279, 142],
    ],
    [
      [238880, 311319],
      [36, 6451],
    ],
    [
      [231398, 316601],
      [2, -3247],
      [-148, -1629],
      [-731, -2],
    ],
    [
      [230521, 311723],
      [-297, 19],
      [0, 1597],
    ],
    [
      [253027, 312561],
      [-593, 32],
    ],
    [
      [252434, 312593],
      [1, 817],
      [-542, -5],
    ],
    [
      [251893, 313405],
      [3, 1629],
    ],
    [
      [216412, 317845],
      [0, -8101],
    ],
    [
      [216412, 309744],
      [-260, 3],
    ],
    [
      [216152, 309747],
      [-1208, 11],
    ],
    [
      [214944, 309758],
      [0, 8102],
    ],
    [
      [217879, 317861],
      [-4, -8109],
    ],
    [
      [217875, 309752],
      [-265, -6],
    ],
    [
      [217610, 309746],
      [-1198, -2],
    ],
    [
      [214944, 309758],
      [-258, 1],
    ],
    [
      [214686, 309759],
      [-1211, -2],
    ],
    [
      [213475, 309757],
      [1, 8078],
    ],
    [
      [219352, 317846],
      [-4, -8079],
    ],
    [
      [219348, 309767],
      [-278, 10],
    ],
    [
      [219070, 309777],
      [-1195, -25],
    ],
    [
      [220518, 314366],
      [0, -4631],
    ],
    [
      [220518, 309735],
      [-1170, 32],
    ],
    [
      [238880, 311319],
      [-3, -1067],
    ],
    [
      [238877, 310252],
      [-196, 24],
    ],
    [
      [238681, 310276],
      [-1273, 171],
    ],
    [
      [237408, 310447],
      [13, 5875],
    ],
    [
      [213475, 309757],
      [-250, -2],
    ],
    [
      [213225, 309755],
      [-1194, -3],
    ],
    [
      [265525, 313343],
      [-379, -1617],
    ],
    [
      [265146, 311726],
      [-589, 2243],
      [6, 1187],
    ],
    [
      [283148, 312136],
      [-617, -2746],
    ],
    [
      [282531, 309390],
      [-232, 1787],
      [-388, -117],
      [-188, -953],
    ],
    [
      [281723, 310107],
      [-370, 469],
    ],
    [
      [281353, 310576],
      [-262, 794],
    ],
    [
      [237408, 310447],
      [-890, 102],
      [31, -3035],
    ],
    [
      [236549, 307514],
      [-631, -50],
    ],
    [
      [235918, 307464],
      [24, 5914],
    ],
    [
      [257983, 314094],
      [-89, -4491],
    ],
    [
      [257894, 309603],
      [-1470, 169],
    ],
    [
      [267586, 313917],
      [-206, -1091],
      [-615, -312],
    ],
    [
      [266765, 312514],
      [-171, 376],
    ],
    [
      [227303, 313368],
      [-1, -4055],
    ],
    [
      [227302, 309313],
      [-1606, 12],
    ],
    [
      [225696, 309325],
      [-8, 4044],
    ],
    [
      [232570, 314986],
      [0, -1620],
      [-300, -11],
      [2, -6507],
    ],
    [
      [232272, 306848],
      [-593, 2],
    ],
    [
      [231679, 306850],
      [-1159, 9],
    ],
    [
      [230520, 306859],
      [1, 4864],
    ],
    [
      [234616, 313367],
      [-304, 7],
      [0, -4907],
      [-291, 4],
      [-15, -1618],
    ],
    [
      [234006, 306853],
      [-1734, -5],
    ],
    [
      [246786, 313701],
      [-19, -2054],
      [-497, -863],
      [38, -1147],
      [-293, -1724],
      [-253, 399],
      [310, -1738],
      [-364, -407],
    ],
    [
      [245708, 306167],
      [3, -24],
    ],
    [
      [245711, 306143],
      [-453, 5],
    ],
    [
      [245258, 306148],
      [92, 410],
      [79, 3638],
    ],
    [
      [229351, 313351],
      [-149, -2],
      [-1, -3219],
      [-146, -6],
    ],
    [
      [229055, 310124],
      [-301, 797],
      [-865, 13],
      [0, 2431],
    ],
    [
      [224239, 311488],
      [147, -1345],
      [-3, -3288],
    ],
    [
      [224383, 306855],
      [-432, -385],
      [-522, 1415],
    ],
    [
      [223429, 307885],
      [-656, 27],
      [-63, 2288],
    ],
    [
      [263266, 314149],
      [3, -2538],
    ],
    [
      [263269, 311611],
      [-982, -374],
    ],
    [
      [262287, 311237],
      [-274, 215],
      [97, 3410],
    ],
    [
      [278817, 309525],
      [-155, -872],
    ],
    [
      [278662, 308653],
      [-702, 872],
      [-466, -1308],
    ],
    [
      [277494, 308217],
      [-239, 1261],
    ],
    [
      [265146, 311726],
      [62, -1577],
    ],
    [
      [265208, 310149],
      [-838, 21],
    ],
    [
      [264370, 310170],
      [1, 855],
    ],
    [
      [264371, 311025],
      [-245, 3382],
    ],
    [
      [275429, 310749],
      [0, -38],
    ],
    [
      [275429, 310711],
      [-371, -1301],
      [-162, -2339],
    ],
    [
      [274896, 307071],
      [-379, -1286],
    ],
    [
      [274517, 305785],
      [-288, -570],
      [-418, 2121],
      [121, 1598],
    ],
    [
      [273932, 308934],
      [155, 2001],
      [-303, 1516],
    ],
    [
      [212031, 309564],
      [-10, -8889],
    ],
    [
      [212021, 300675],
      [-15, -4739],
    ],
    [
      [212006, 295936],
      [-1274, -7],
    ],
    [
      [210732, 295929],
      [1, 1618],
      [-582, -19],
      [5, 3039],
      [-348, -1],
      [0, 4885],
      [-291, 21],
    ],
    [
      [251893, 313405],
      [-148, -262],
      [-98, -2422],
    ],
    [
      [251647, 310721],
      [-1175, 275],
    ],
    [
      [250472, 310996],
      [0, 2162],
    ],
    [
      [259221, 307668],
      [-550, -1892],
    ],
    [
      [258671, 305776],
      [-416, 2982],
    ],
    [
      [258255, 308758],
      [-361, 845],
    ],
    [
      [262002, 314866],
      [-202, -2820],
      [-428, -1223],
      [-148, -1566],
      [-209, 4],
    ],
    [
      [261015, 309261],
      [-144, 4429],
    ],
    [
      [262287, 311237],
      [-115, -5511],
    ],
    [
      [262172, 305726],
      [-1045, -291],
    ],
    [
      [261127, 305435],
      [-112, 3826],
    ],
    [
      [204007, 308772],
      [-2, -8089],
      [-357, 6],
      [0, -3269],
      [-895, 189],
      [3, -3357],
    ],
    [
      [202756, 294252],
      [-282, -80],
      [-3451, 20],
    ],
    [
      [199023, 294192],
      [-10, 14576],
      [-25, 5924],
    ],
    [
      [199023, 294192],
      [-802, 21],
      [0, -5147],
    ],
    [
      [198221, 289066],
      [-2916, 138],
    ],
    [
      [195305, 289204],
      [0, 10598],
    ],
    [
      [255254, 309601],
      [-292, 45],
    ],
    [
      [254962, 309646],
      [-1501, 262],
    ],
    [
      [253461, 309908],
      [47, 2646],
    ],
    [
      [221974, 311301],
      [-1, -5776],
    ],
    [
      [221973, 305525],
      [-842, 203],
      [-613, 2379],
    ],
    [
      [220518, 308107],
      [0, 1628],
    ],
    [
      [273932, 308934],
      [-751, -1023],
      [-232, -720],
      [-385, 1197],
    ],
    [
      [272564, 308388],
      [-296, 4746],
    ],
    [
      [245258, 306148],
      [-220, -1244],
      [179, -1807],
      [-328, 48],
      [-478, 1054],
    ],
    [
      [244411, 304199],
      [-66, 1224],
    ],
    [
      [244345, 305423],
      [-235, 2560],
      [-507, 303],
      [19, 4630],
    ],
    [
      [264371, 311025],
      [-903, 8],
      [-199, 578],
    ],
    [
      [250472, 310996],
      [3, -4065],
    ],
    [
      [250475, 306931],
      [-737, 17],
    ],
    [
      [249738, 306948],
      [0, 538],
      [-588, 29],
    ],
    [
      [249150, 307515],
      [-1, 6735],
    ],
    [
      [249150, 307515],
      [-2, -541],
      [-585, 15],
    ],
    [
      [248563, 306989],
      [-568, -78],
    ],
    [
      [247995, 306911],
      [2, 1971],
      [-165, 659],
      [-13, 3904],
    ],
    [
      [267731, 313122],
      [-172, -4100],
    ],
    [
      [267559, 309022],
      [-165, -650],
      [-348, 481],
      [-159, -755],
    ],
    [
      [266887, 308098],
      [-122, 4416],
    ],
    [
      [277187, 309610],
      [-200, -184],
      [-957, -3894],
    ],
    [
      [276030, 305532],
      [-239, 1408],
      [128, 659],
      [-179, 3128],
      [-311, -16],
    ],
    [
      [272564, 308388],
      [-61, -777],
    ],
    [
      [272503, 307611],
      [-371, -2256],
    ],
    [
      [272132, 305355],
      [-182, 82],
      [-533, 1971],
      [-394, -959],
    ],
    [
      [271023, 306449],
      [-209, 1264],
    ],
    [
      [261127, 305435],
      [65, -2172],
    ],
    [
      [261192, 303263],
      [-366, -424],
    ],
    [
      [260826, 302839],
      [-582, 505],
    ],
    [
      [260244, 303344],
      [-292, 4310],
    ],
    [
      [247116, 305288],
      [0, -1624],
    ],
    [
      [247116, 303664],
      [-574, 36],
    ],
    [
      [246542, 303700],
      [-7, 2441],
      [-827, 26],
    ],
    [
      [247259, 311820],
      [2, -3021],
      [-145, 16],
      [0, -3527],
    ],
    [
      [247995, 306911],
      [-1, -1623],
      [-878, 0],
    ],
    [
      [266161, 311969],
      [-151, -1209],
      [137, -1333],
    ],
    [
      [266147, 309427],
      [-550, -3124],
    ],
    [
      [265597, 306303],
      [-143, -536],
      [-540, 1289],
    ],
    [
      [264914, 307056],
      [377, 2075],
      [-83, 1018],
    ],
    [
      [252434, 312593],
      [-3, -7006],
    ],
    [
      [252431, 305587],
      [-491, -269],
    ],
    [
      [251940, 305318],
      [-293, 8],
    ],
    [
      [251647, 305326],
      [0, 5395],
    ],
    [
      [229055, 310124],
      [0, -2981],
    ],
    [
      [229055, 307143],
      [-102, -1894],
    ],
    [
      [228953, 305249],
      [-1646, 5],
    ],
    [
      [227307, 305254],
      [-5, 4059],
    ],
    [
      [230520, 306859],
      [-129, -799],
      [-366, 1],
    ],
    [
      [230025, 306061],
      [-236, 1077],
      [-734, 5],
    ],
    [
      [225696, 309325],
      [4, -2728],
    ],
    [
      [225700, 306597],
      [-87, -540],
      [-545, 799],
      [-164, -1383],
    ],
    [
      [224904, 305473],
      [-175, -372],
      [-346, 1754],
    ],
    [
      [235918, 307464],
      [-20, -4634],
    ],
    [
      [235898, 302830],
      [-25, -5641],
    ],
    [
      [235873, 297189],
      [-725, 1210],
    ],
    [
      [235148, 298399],
      [-85, 1118],
      [-236, -357],
      [-302, 2195],
      [-518, 1395],
    ],
    [
      [234007, 302750],
      [-1, 4103],
    ],
    [
      [240937, 311642],
      [-27, -4926],
    ],
    [
      [240910, 306716],
      [-580, 117],
    ],
    [
      [240330, 306833],
      [-387, 76],
      [-25, 1423],
      [-898, 817],
      [-143, 1103],
    ],
    [
      [192649, 296055],
      [0, -2067],
      [-471, -22],
      [-761, -1596],
      [-17, -3435],
    ],
    [
      [191400, 288935],
      [-519, -982],
      [-411, -2924],
      [-457, 4942],
      [-260, 4020],
    ],
    [
      [189753, 293991],
      [-316, 3938],
      [-198, -1382],
      [-453, 2730],
      [43, 1284],
      [-343, 3366],
    ],
    [
      [268504, 309319],
      [-576, -1452],
      [-270, 224],
    ],
    [
      [267658, 308091],
      [-99, 931],
    ],
    [
      [242221, 313104],
      [-71, -8013],
    ],
    [
      [242150, 305091],
      [-288, -50],
    ],
    [
      [241862, 305041],
      [-455, 1583],
      [-497, 92],
    ],
    [
      [244345, 305423],
      [-785, 1742],
      [-49, -1484],
      [-582, -12],
      [0, -545],
    ],
    [
      [242929, 305124],
      [-779, -33],
    ],
    [
      [266887, 308098],
      [-133, -492],
    ],
    [
      [266754, 307606],
      [-217, 1202],
      [-390, 619],
    ],
    [
      [281353, 310576],
      [-256, -2617],
      [-334, -211],
      [-135, -1226],
      [-339, 132],
      [-50, -1711],
      [-247, -2039],
    ],
    [
      [279992, 302904],
      [-1171, 6597],
    ],
    [
      [269975, 311558],
      [-244, -1242],
      [240, -2297],
      [-54, -2071],
      [-167, -825],
    ],
    [
      [269750, 305123],
      [-567, 148],
      [-179, -1083],
    ],
    [
      [269004, 304188],
      [-407, 3629],
    ],
    [
      [253461, 309908],
      [-84, -4366],
    ],
    [
      [253377, 305542],
      [-946, 45],
    ],
    [
      [223429, 307885],
      [-1, -7045],
    ],
    [
      [223428, 300840],
      [-1456, 0],
    ],
    [
      [221972, 300840],
      [1, 4685],
    ],
    [
      [263252, 305382],
      [-219, 27],
    ],
    [
      [263033, 305409],
      [-515, 58],
    ],
    [
      [262518, 305467],
      [-346, 259],
    ],
    [
      [263269, 311611],
      [-17, -6229],
    ],
    [
      [264370, 310170],
      [-2, -2778],
    ],
    [
      [264368, 307392],
      [-267, -204],
      [-179, -1893],
    ],
    [
      [263922, 305295],
      [-670, 87],
    ],
    [
      [271023, 306449],
      [-385, -3232],
    ],
    [
      [270638, 303217],
      [-108, -158],
    ],
    [
      [270530, 303059],
      [5, 561],
      [-727, -210],
      [-58, 1713],
    ],
    [
      [282531, 309390],
      [61, 24],
    ],
    [
      [282592, 309414],
      [10, -65],
    ],
    [
      [282602, 309349],
      [-405, -2626],
      [-165, -1820],
      [-109, 2615],
    ],
    [
      [281923, 307518],
      [-200, 2589],
    ],
    [
      [251647, 305326],
      [-877, -15],
    ],
    [
      [250770, 305311],
      [-293, -4],
      [-2, 1624],
    ],
    [
      [276030, 305532],
      [200, -730],
    ],
    [
      [276230, 304802],
      [-500, -1759],
      [-394, 2383],
      [-444, 601],
      [4, 1044],
    ],
    [
      [281923, 307518],
      [8, -4063],
      [-186, -1610],
      [-387, 486],
      [-737, -285],
      [-373, -589],
    ],
    [
      [280248, 301457],
      [-33, 176],
    ],
    [
      [280215, 301633],
      [-11, 67],
    ],
    [
      [280204, 301700],
      [-212, 1204],
    ],
    [
      [237408, 310447],
      [27, -3044],
      [293, -50],
      [-7, -3257],
    ],
    [
      [237721, 304096],
      [-23, -4862],
      [-351, 60],
    ],
    [
      [237347, 299294],
      [-4, 1],
    ],
    [
      [237343, 299295],
      [-252, 2449],
      [-133, 5739],
      [-409, 31],
    ],
    [
      [238681, 310276],
      [-66, -2358],
      [143, -158],
      [46, -2922],
      [169, -1709],
    ],
    [
      [238973, 303129],
      [-231, -2],
    ],
    [
      [238742, 303127],
      [-202, -247],
      [-819, 1216],
    ],
    [
      [206879, 305574],
      [2, -12856],
      [-37, -4822],
      [-1146, -121],
    ],
    [
      [205698, 287775],
      [-6, 3219],
      [-1134, -5],
      [-3, 1594],
      [-1724, 6],
    ],
    [
      [202831, 292589],
      [-75, 1663],
    ],
    [
      [240330, 306833],
      [-20, -6441],
    ],
    [
      [240310, 300392],
      [-587, -612],
    ],
    [
      [239723, 299780],
      [-511, 1233],
      [-239, 2116],
    ],
    [
      [264914, 307056],
      [-97, -2181],
    ],
    [
      [264817, 304875],
      [-449, 1093],
      [0, 1424],
    ],
    [
      [254962, 309646],
      [-4, -5675],
    ],
    [
      [254958, 303971],
      [0, -1619],
    ],
    [
      [254958, 302352],
      [-879, 89],
    ],
    [
      [254079, 302441],
      [-98, 2459],
      [-614, 117],
    ],
    [
      [253367, 305017],
      [10, 525],
    ],
    [
      [183362, 303916],
      [0, -11575],
    ],
    [
      [183362, 292341],
      [-1740, 3],
      [0, 1620],
      [-862, -20],
      [-3, -8075],
      [-686, -61],
    ],
    [
      [180071, 285808],
      [-439, 260],
      [-94, 887],
      [82, 3389],
      [-151, 600],
      [21, 1938],
      [272, 564],
    ],
    [
      [179762, 293446],
      [283, 2148],
      [77, 2692],
      [-106, 4262],
      [274, 1899],
      [11, 1120],
    ],
    [
      [181121, 309576],
      [281, -790],
      [1201, -586],
      [253, 1502],
      [507, 147],
    ],
    [
      [219070, 309777],
      [-4, -8906],
    ],
    [
      [219066, 300871],
      [-1455, -38],
    ],
    [
      [217611, 300833],
      [-1, 8913],
    ],
    [
      [220518, 308107],
      [-138, 101],
      [-4, -7331],
    ],
    [
      [220376, 300877],
      [-1310, -6],
    ],
    [
      [258255, 308758],
      [-186, -144],
      [-224, -2438],
      [-291, -747],
      [-162, -1946],
      [-430, -1123],
      [-129, -1066],
    ],
    [
      [256833, 301294],
      [-358, 590],
      [-165, 1921],
    ],
    [
      [256310, 303805],
      [109, -16],
      [5, 5713],
    ],
    [
      [217611, 300833],
      [-1456, -60],
    ],
    [
      [216155, 300773],
      [-3, 8974],
    ],
    [
      [216155, 300773],
      [-1454, -106],
    ],
    [
      [214701, 300667],
      [-15, 9092],
    ],
    [
      [214701, 300667],
      [-1476, 8],
    ],
    [
      [213225, 300675],
      [0, 9080],
    ],
    [
      [277494, 308217],
      [-65, -786],
      [217, -4445],
      [468, -2796],
    ],
    [
      [278114, 300190],
      [-479, -436],
      [-840, 1115],
      [-215, 922],
    ],
    [
      [276580, 301791],
      [-276, 1144],
    ],
    [
      [276304, 302935],
      [225, 772],
      [-299, 1095],
    ],
    [
      [213225, 300675],
      [-1204, 0],
    ],
    [
      [256310, 303805],
      [-1352, 166],
    ],
    [
      [280204, 301700],
      [21, -471],
      [-685, -2076],
      [-529, -3188],
    ],
    [
      [279011, 295965],
      [-269, -6],
      [-253, 2493],
    ],
    [
      [278489, 298452],
      [-185, 3128],
      [-229, 1784],
      [245, 1596],
      [342, 3693],
    ],
    [
      [278489, 298452],
      [-265, 205],
      [-88, 1174],
    ],
    [
      [278136, 299831],
      [-22, 359],
    ],
    [
      [266754, 307606],
      [118, -2921],
    ],
    [
      [266872, 304685],
      [-494, -1390],
    ],
    [
      [266378, 303295],
      [-72, 1227],
      [-571, 652],
      [-138, 1129],
    ],
    [
      [227307, 305254],
      [-1, -3184],
    ],
    [
      [227306, 302070],
      [-352, 1622],
      [-507, -2568],
      [-302, 757],
    ],
    [
      [226145, 301881],
      [90, 1860],
      [-381, 232],
      [-154, 2624],
    ],
    [
      [269004, 304188],
      [84, -1081],
    ],
    [
      [269088, 303107],
      [-225, 529],
    ],
    [
      [268863, 303636],
      [-374, -245],
    ],
    [
      [268489, 303391],
      [-548, 1345],
    ],
    [
      [267941, 304736],
      [-283, 3355],
    ],
    [
      [267941, 304736],
      [-591, -490],
    ],
    [
      [267350, 304246],
      [-195, -334],
    ],
    [
      [267155, 303912],
      [-283, 773],
    ],
    [
      [274517, 305785],
      [-85, -1864],
      [134, -4155],
      [-61, -616],
    ],
    [
      [274505, 299150],
      [-581, 663],
      [-511, 1884],
    ],
    [
      [273413, 301697],
      [-135, 2327],
      [-372, 1680],
      [-227, 39],
      [-176, 1868],
    ],
    [
      [258671, 305776],
      [-63, -2961],
    ],
    [
      [258608, 302815],
      [-229, -1970],
      [-471, -601],
      [9, -685],
    ],
    [
      [257917, 299559],
      [-515, 1403],
      [-542, -470],
    ],
    [
      [256860, 300492],
      [-27, 802],
    ],
    [
      [221972, 300840],
      [4, -1864],
    ],
    [
      [221976, 298976],
      [-229, 1201],
      [-392, 509],
      [-834, 191],
    ],
    [
      [220521, 300877],
      [-145, 0],
    ],
    [
      [224904, 305473],
      [0, -4595],
    ],
    [
      [224904, 300878],
      [-1476, -38],
    ],
    [
      [260244, 303344],
      [-448, -474],
      [-461, -1878],
    ],
    [
      [259335, 300992],
      [-374, 2733],
      [-353, -910],
    ],
    [
      [273413, 301697],
      [-85, -3220],
    ],
    [
      [273328, 298477],
      [-403, -1010],
    ],
    [
      [272925, 297467],
      [-503, 1412],
      [-567, 2766],
    ],
    [
      [271855, 301645],
      [277, 3710],
    ],
    [
      [249738, 306948],
      [7, -5486],
    ],
    [
      [249745, 301462],
      [-730, -2],
      [-438, 571],
    ],
    [
      [248577, 302031],
      [-14, 4958],
    ],
    [
      [237343, 299295],
      [-645, 694],
      [-800, 2841],
    ],
    [
      [271855, 301645],
      [-224, -1169],
    ],
    [
      [271631, 300476],
      [-514, 964],
      [-162, 2031],
      [-317, -254],
    ],
    [
      [264817, 304875],
      [-499, -1732],
    ],
    [
      [264318, 303143],
      [-198, 104],
      [-7, -5871],
    ],
    [
      [264113, 297376],
      [-299, 18],
      [-3, -1823],
    ],
    [
      [263811, 295571],
      [-420, -902],
    ],
    [
      [263391, 294669],
      [-672, 168],
    ],
    [
      [262719, 294837],
      [116, 1165],
    ],
    [
      [262835, 296002],
      [415, 1551],
      [228, 1602],
    ],
    [
      [263478, 299155],
      [298, 1539],
      [245, 2560],
      [-99, 2041],
    ],
    [
      [230025, 306061],
      [101, -881],
      [-211, -1084],
      [101, -2120],
    ],
    [
      [230016, 301976],
      [-186, 350],
      [-327, -1482],
      [-269, 387],
      [-183, 1849],
    ],
    [
      [229051, 303080],
      [-98, 2169],
    ],
    [
      [244411, 304199],
      [-353, -701],
      [-25, -3600],
    ],
    [
      [244033, 299898],
      [-1166, 155],
    ],
    [
      [242867, 300053],
      [62, 5071],
    ],
    [
      [276304, 302935],
      [-783, -2654],
      [-394, -674],
      [-382, -2307],
    ],
    [
      [274745, 297300],
      [-240, 1850],
    ],
    [
      [265597, 306303],
      [-143, -2279],
      [195, -1400],
    ],
    [
      [265649, 302624],
      [-510, -2675],
    ],
    [
      [265139, 299949],
      [-116, -620],
    ],
    [
      [265023, 299329],
      [-659, 3103],
      [-46, 711],
    ],
    [
      [248577, 302031],
      [0, -1380],
      [-585, -270],
      [-2, -1632],
    ],
    [
      [247990, 298749],
      [-442, 543],
      [316, 1089],
      [-750, 38],
    ],
    [
      [247114, 300419],
      [2, 3245],
    ],
    [
      [250770, 305311],
      [4, -4878],
      [-150, -1369],
    ],
    [
      [250624, 299064],
      [-878, -312],
    ],
    [
      [249746, 298752],
      [-1, 2710],
    ],
    [
      [231679, 306850],
      [149, -590],
      [1, -3199],
      [271, -1474],
      [224, -5],
    ],
    [
      [232324, 301582],
      [-241, -630],
    ],
    [
      [232083, 300952],
      [-247, 888],
      [-429, -565],
      [-250, -1798],
      [-558, -652],
    ],
    [
      [230599, 298825],
      [-125, 949],
      [-568, 1263],
      [110, 939],
    ],
    [
      [234007, 302750],
      [-176, 481],
      [-257, -1570],
    ],
    [
      [233574, 301661],
      [-669, 201],
      [-133, 967],
      [-448, -1247],
    ],
    [
      [241862, 305041],
      [-376, -48],
      [-11, -1571],
      [394, -3307],
    ],
    [
      [241869, 300115],
      [-693, 133],
    ],
    [
      [241176, 300248],
      [-866, 144],
    ],
    [
      [226145, 301881],
      [-3, -7871],
    ],
    [
      [226142, 294010],
      [-1232, -6],
    ],
    [
      [224910, 294004],
      [-6, 6874],
    ],
    [
      [266378, 303295],
      [-305, -1110],
    ],
    [
      [266073, 302185],
      [-424, 439],
    ],
    [
      [246542, 303700],
      [-5, -6913],
      [-293, -2],
      [0, -1616],
    ],
    [
      [246244, 295169],
      [-1256, -5],
    ],
    [
      [244988, 295164],
      [-45, 601],
    ],
    [
      [244943, 295765],
      [269, 599],
      [-236, 1265],
      [282, 815],
      [-40, 1455],
      [303, -382],
      [-185, 3716],
      [560, 1252],
      [-185, 1658],
    ],
    [
      [244943, 295765],
      [-41, -823],
      [-579, 866],
    ],
    [
      [244323, 295808],
      [8, 4034],
      [-298, 56],
    ],
    [
      [262518, 305467],
      [-156, -2441],
      [-200, -874],
    ],
    [
      [262162, 302152],
      [-936, -50],
    ],
    [
      [261226, 302102],
      [-34, 1161],
    ],
    [
      [253367, 305017],
      [-117, -5834],
    ],
    [
      [253250, 299183],
      [-737, -1766],
    ],
    [
      [252513, 297417],
      [38, 554],
      [-611, 6],
      [-2, 2436],
    ],
    [
      [251938, 300413],
      [2, 4905],
    ],
    [
      [210732, 295929],
      [-587, -11],
      [-3, -3235],
      [-124, -7],
      [0, -8008],
      [-134, -3],
    ],
    [
      [209884, 284665],
      [-2861, -35],
      [-28, -8243],
    ],
    [
      [206995, 276387],
      [-1400, -32],
      [4, 8250],
      [101, 1],
      [-2, 3169],
    ],
    [
      [263478, 299155],
      [-127, 1132],
      [-280, 33],
    ],
    [
      [263071, 300320],
      [-38, 5089],
    ],
    [
      [179762, 293446],
      [-4058, -181],
    ],
    [
      [175704, 293265],
      [-2643, -5],
      [-585, 89],
      [-362, 1122],
      [-387, 245],
    ],
    [
      [171727, 294716],
      [267, 2870],
      [-339, 971],
      [-382, 2958],
    ],
    [
      [263071, 300320],
      [-494, -473],
    ],
    [
      [262577, 299847],
      [-380, 582],
    ],
    [
      [262197, 300429],
      [-35, 1723],
    ],
    [
      [270530, 303059],
      [-303, -446],
      [112, -1813],
      [-159, -1241],
      [160, -3138],
    ],
    [
      [270340, 296421],
      [-326, 1801],
    ],
    [
      [270014, 298222],
      [-319, 2646],
      [-607, 2239],
    ],
    [
      [251938, 300413],
      [-588, 24],
      [-1, -1081],
      [-287, -276],
    ],
    [
      [251062, 299080],
      [-438, -16],
    ],
    [
      [229051, 303080],
      [-27, -97],
    ],
    [
      [229024, 302983],
      [-420, -2885],
      [-33, -1359],
      [-271, 1490],
      [50, 1869],
      [-342, -239],
      [-177, -1289],
      [-249, 387],
      [-61, 1401],
    ],
    [
      [227521, 302358],
      [-215, -288],
    ],
    [
      [242867, 300053],
      [0, -1626],
    ],
    [
      [242867, 298427],
      [-989, 64],
    ],
    [
      [241878, 298491],
      [-9, 1624],
    ],
    [
      [254079, 302441],
      [14, -7371],
    ],
    [
      [254093, 295070],
      [-914, 185],
    ],
    [
      [253179, 295255],
      [71, 3928],
    ],
    [
      [268489, 303391],
      [-191, -580],
      [-387, -2963],
      [106, -884],
    ],
    [
      [268017, 298964],
      [-130, -739],
    ],
    [
      [267887, 298225],
      [-343, 99],
      [-450, 1171],
    ],
    [
      [267094, 299495],
      [12, 1605],
    ],
    [
      [267106, 301100],
      [49, 2812],
    ],
    [
      [189753, 293991],
      [-1506, -3],
      [-6, -4849],
      [-1376, -19],
      [-349, 1601],
      [-5, -14590],
    ],
    [
      [186511, 276131],
      [-3149, -35],
    ],
    [
      [183362, 276096],
      [0, 16245],
    ],
    [
      [267106, 301100],
      [-728, 2195],
    ],
    [
      [238742, 303127],
      [-74, -8950],
    ],
    [
      [238668, 294177],
      [-668, 109],
    ],
    [
      [238000, 294286],
      [-287, 2381],
    ],
    [
      [237713, 296667],
      [-388, 1098],
      [22, 1529],
    ],
    [
      [256860, 300492],
      [-290, -2960],
      [-338, -792],
      [-245, -1877],
    ],
    [
      [255987, 294863],
      [-144, 1386],
      [-293, 274],
    ],
    [
      [255550, 296523],
      [0, 1620],
      [-291, 54],
      [-9, 3268],
      [-292, 887],
    ],
    [
      [268863, 303636],
      [139, -2861],
      [323, -3555],
    ],
    [
      [269325, 297220],
      [-471, -581],
    ],
    [
      [268854, 296639],
      [-87, -160],
    ],
    [
      [268767, 296479],
      [-371, 1042],
      [-216, -421],
      [-163, 1864],
    ],
    [
      [227521, 302358],
      [-8, -8969],
    ],
    [
      [227513, 293389],
      [-1201, 1],
    ],
    [
      [226312, 293390],
      [-170, 620],
    ],
    [
      [259335, 300992],
      [56, -1316],
      [-280, -1720],
    ],
    [
      [259111, 297956],
      [-212, -1709],
      [70, -887],
      [-508, -2769],
    ],
    [
      [258461, 292591],
      [2, 2075],
      [-386, 811],
    ],
    [
      [258077, 295477],
      [-160, 4082],
    ],
    [
      [247114, 300419],
      [-4, -8971],
    ],
    [
      [247110, 291448],
      [-285, -7],
      [1, -1091],
      [-449, -9],
    ],
    [
      [246377, 290341],
      [-133, 6],
      [0, 4822],
    ],
    [
      [270014, 298222],
      [-538, -798],
    ],
    [
      [269476, 297424],
      [-151, -204],
    ],
    [
      [271631, 300476],
      [-1005, -5256],
    ],
    [
      [270626, 295220],
      [-44, 238],
    ],
    [
      [270582, 295458],
      [-242, 963],
    ],
    [
      [265023, 299329],
      [-257, -2572],
      [-190, 585],
    ],
    [
      [264576, 297342],
      [-271, 24],
    ],
    [
      [264305, 297366],
      [-192, 10],
    ],
    [
      [260085, 295668],
      [2, 547],
      [-556, 17],
      [-158, 1641],
      [-262, 83],
    ],
    [
      [260826, 302839],
      [-296, -1218],
      [-7, -4236],
      [-438, -1717],
    ],
    [
      [267094, 299495],
      [-351, -1180],
    ],
    [
      [266743, 298315],
      [-277, 2222],
    ],
    [
      [266466, 300537],
      [-393, 1648],
    ],
    [
      [261226, 302102],
      [133, -4629],
    ],
    [
      [261359, 297473],
      [96, -3170],
    ],
    [
      [261455, 294303],
      [-945, 242],
    ],
    [
      [260510, 294545],
      [-581, 52],
    ],
    [
      [259929, 294597],
      [156, 1071],
    ],
    [
      [235148, 298399],
      [-1, -6962],
    ],
    [
      [235147, 291437],
      [-172, 647],
    ],
    [
      [234975, 292084],
      [-223, 645],
      [-660, -165],
    ],
    [
      [234092, 292564],
      [-504, -212],
    ],
    [
      [233588, 292352],
      [-6, 49],
    ],
    [
      [233582, 292401],
      [-8, 9260],
    ],
    [
      [239723, 299780],
      [-33, -6035],
    ],
    [
      [239690, 293745],
      [-694, -157],
    ],
    [
      [238996, 293588],
      [-328, 589],
    ],
    [
      [230599, 298825],
      [-15, -6105],
    ],
    [
      [230584, 292720],
      [-1252, 143],
    ],
    [
      [229332, 292863],
      [-306, 203],
    ],
    [
      [229026, 293066],
      [-2, 9917],
    ],
    [
      [229026, 293066],
      [-1224, 262],
    ],
    [
      [227802, 293328],
      [-289, 61],
    ],
    [
      [237713, 296667],
      [-606, -1073],
    ],
    [
      [237107, 295594],
      [-320, 702],
      [-637, -837],
      [-277, 1730],
    ],
    [
      [276580, 301791],
      [-335, -2182],
      [7, -1181],
      [-302, -3863],
    ],
    [
      [275950, 294565],
      [-336, -991],
    ],
    [
      [275614, 293574],
      [-478, 39],
      [-285, 2097],
    ],
    [
      [274851, 295710],
      [-106, 1590],
    ],
    [
      [171727, 294716],
      [-239, -2194],
    ],
    [
      [171488, 292522],
      [-527, 2873],
      [-607, 2124],
      [-309, 1676],
    ],
    [
      [233582, 292401],
      [-516, 1393],
      [-745, 742],
      [-269, -626],
    ],
    [
      [232052, 293910],
      [31, 7042],
    ],
    [
      [266466, 300537],
      [-488, -4110],
    ],
    [
      [265978, 296427],
      [-652, 2740],
    ],
    [
      [265326, 299167],
      [-187, 782],
    ],
    [
      [255550, 296523],
      [-579, 144],
      [-98, -1641],
      [-485, 59],
    ],
    [
      [254388, 295085],
      [-295, -15],
    ],
    [
      [262197, 300429],
      [-36, -1811],
      [-802, -1145],
    ],
    [
      [249746, 298752],
      [0, -823],
    ],
    [
      [249746, 297929],
      [-780, -17],
    ],
    [
      [248966, 297912],
      [-952, 5],
    ],
    [
      [248014, 297917],
      [-24, 832],
    ],
    [
      [278136, 299831],
      [-261, -1729],
      [-65, -2062],
      [-295, -1492],
      [-382, -3555],
    ],
    [
      [277133, 290993],
      [-221, 469],
      [-599, 3177],
      [-363, -74],
    ],
    [
      [232052, 293910],
      [-3, -968],
    ],
    [
      [232049, 292942],
      [-1216, -1076],
    ],
    [
      [230833, 291866],
      [-249, 854],
    ],
    [
      [274851, 295710],
      [-156, 131],
      [-298, -1760],
      [-415, 2481],
      [-367, 85],
      [-287, 1830],
    ],
    [
      [272925, 297467],
      [-517, -3023],
    ],
    [
      [272408, 294444],
      [-1070, -5442],
    ],
    [
      [271338, 289002],
      [-265, 919],
    ],
    [
      [271073, 289921],
      [-257, 2318],
      [43, 1727],
      [-233, 1254],
    ],
    [
      [258077, 295477],
      [-439, -1478],
      [-435, -2504],
      [-548, -1594],
    ],
    [
      [256655, 289901],
      [-109, 9],
    ],
    [
      [256546, 289910],
      [-267, 1112],
      [-470, 3450],
      [178, 391],
    ],
    [
      [220521, 300877],
      [16, -8166],
    ],
    [
      [220537, 292711],
      [-1467, 10],
    ],
    [
      [219070, 292721],
      [-4, 8150],
    ],
    [
      [221976, 298976],
      [5, -6234],
    ],
    [
      [221981, 292742],
      [-1444, -31],
    ],
    [
      [224910, 294004],
      [2, -1315],
    ],
    [
      [224912, 292689],
      [-1486, 26],
    ],
    [
      [223426, 292715],
      [2, 8125],
    ],
    [
      [219070, 292721],
      [-1453, -13],
    ],
    [
      [217617, 292708],
      [-6, 8125],
    ],
    [
      [217617, 292708],
      [-1443, -45],
    ],
    [
      [216174, 292663],
      [-19, 8110],
    ],
    [
      [223426, 292715],
      [-1445, 27],
    ],
    [
      [216174, 292663],
      [-1446, -96],
    ],
    [
      [214728, 292567],
      [-27, 8100],
    ],
    [
      [213225, 300675],
      [57, -8129],
    ],
    [
      [213282, 292546],
      [-1287, -1],
    ],
    [
      [211995, 292545],
      [11, 3391],
    ],
    [
      [214728, 292567],
      [-1446, -21],
    ],
    [
      [241176, 300248],
      [-12, -1321],
      [-518, -4118],
      [192, -1475],
      [382, -1184],
    ],
    [
      [241220, 292150],
      [-1146, 188],
    ],
    [
      [240074, 292338],
      [-391, 56],
      [7, 1351],
    ],
    [
      [266743, 298315],
      [382, -3067],
      [-30, -933],
    ],
    [
      [267095, 294315],
      [-707, -913],
    ],
    [
      [266388, 293402],
      [-414, 1708],
    ],
    [
      [265974, 295110],
      [4, 1317],
    ],
    [
      [262719, 294837],
      [-459, -1602],
    ],
    [
      [262260, 293235],
      [-776, 48],
    ],
    [
      [261484, 293283],
      [-29, 1020],
    ],
    [
      [262577, 299847],
      [-12, -3859],
      [270, 14],
    ],
    [
      [248014, 297917],
      [76, -4060],
      [-205, -780],
      [1, -1558],
    ],
    [
      [247886, 291519],
      [-437, -567],
    ],
    [
      [247449, 290952],
      [-227, -1245],
      [-112, 1741],
    ],
    [
      [252513, 297417],
      [69, -2403],
      [-508, -277],
    ],
    [
      [252074, 294737],
      [0, 1098],
      [-968, -56],
    ],
    [
      [251106, 295779],
      [-44, 3301],
    ],
    [
      [241878, 298491],
      [54, -3825],
      [-183, -2389],
      [75, -1398],
    ],
    [
      [241824, 290879],
      [-230, -523],
      [-374, 1794],
    ],
    [
      [244323, 295808],
      [-11, -3253],
    ],
    [
      [244312, 292555],
      [-1484, 172],
    ],
    [
      [242828, 292727],
      [39, 5700],
    ],
    [
      [264965, 295106],
      [-389, 2236],
    ],
    [
      [265326, 299167],
      [-46, -1734],
      [-315, -2327],
    ],
    [
      [279011, 295965],
      [-403, -3616],
      [-92, -2363],
      [-262, 1132],
      [231, -2620],
      [-212, -589],
      [-375, 805],
    ],
    [
      [277898, 288714],
      [-123, 574],
    ],
    [
      [277775, 289288],
      [-642, 1705],
    ],
    [
      [195305, 289204],
      [0, -8032],
    ],
    [
      [195305, 281172],
      [-1, -6537],
    ],
    [
      [195304, 274635],
      [-186, -4],
    ],
    [
      [195118, 274631],
      [-312, 2178],
      [43, 3573],
      [-198, 582],
      [-596, 5810],
      [0, 10694],
    ],
    [
      [267887, 298225],
      [124, -661],
      [-175, -1445],
      [3, -2070],
    ],
    [
      [267839, 294049],
      [-421, -2128],
    ],
    [
      [267418, 291921],
      [-323, 2394],
    ],
    [
      [253179, 295255],
      [-83, -4574],
    ],
    [
      [253096, 290681],
      [-1016, -26],
    ],
    [
      [252080, 290655],
      [-6, 4082],
    ],
    [
      [265974, 295110],
      [21, -726],
      [-419, -926],
      [-105, -1288],
    ],
    [
      [265471, 292170],
      [-170, 1415],
    ],
    [
      [265301, 293585],
      [-336, 1521],
    ],
    [
      [251106, 295779],
      [-194, -540],
    ],
    [
      [250912, 295239],
      [-465, -210],
      [-350, -1146],
    ],
    [
      [250097, 293883],
      [-350, 16],
      [-1, 4030],
    ],
    [
      [268767, 296479],
      [-104, -668],
    ],
    [
      [268663, 295811],
      [-273, -796],
      [-103, -1445],
    ],
    [
      [268287, 293570],
      [-448, 479],
    ],
    [
      [237107, 295594],
      [1, -5232],
    ],
    [
      [237108, 290362],
      [-341, 757],
      [-196, -1322],
      [-639, 965],
      [-521, -443],
    ],
    [
      [235411, 290319],
      [-264, 1118],
    ],
    [
      [275614, 293574],
      [-88, -2688],
      [-301, -779],
    ],
    [
      [275225, 290107],
      [-353, 382],
      [-40, 1051],
      [-800, -2860],
    ],
    [
      [274032, 288680],
      [-22, -72],
    ],
    [
      [274010, 288608],
      [-398, 2352],
      [-785, 2545],
    ],
    [
      [272827, 293505],
      [-419, 939],
    ],
    [
      [242828, 292727],
      [22, -1854],
      [-368, -1418],
      [-65, -1107],
    ],
    [
      [242417, 288348],
      [-241, 1667],
      [-352, 864],
    ],
    [
      [270582, 295458],
      [-741, -3541],
    ],
    [
      [269841, 291917],
      [-395, 3623],
      [30, 1884],
    ],
    [
      [259929, 294597],
      [-147, 0],
      [-211, -3806],
      [-383, 38],
      [-157, -3571],
    ],
    [
      [259031, 287258],
      [-882, -28],
    ],
    [
      [258149, 287230],
      [-35, 1334],
      [210, 2007],
      [198, 282],
      [-61, 1738],
    ],
    [
      [250097, 293883],
      [-203, -505],
      [0, -2741],
    ],
    [
      [249894, 290637],
      [-534, -9],
    ],
    [
      [249360, 290628],
      [-4, 2180],
      [-385, 256],
      [-5, 4848],
    ],
    [
      [249360, 290628],
      [-290, -1321],
    ],
    [
      [249070, 289307],
      [-1184, 2212],
    ],
    [
      [269841, 291917],
      [-165, -789],
    ],
    [
      [269676, 291128],
      [-4, -5],
    ],
    [
      [269672, 291123],
      [-80, 1],
    ],
    [
      [269592, 291124],
      [-424, 845],
      [-314, 4670],
    ],
    [
      [195118, 274631],
      [-3725, 22],
    ],
    [
      [191393, 274653],
      [0, 1610],
    ],
    [
      [191393, 276263],
      [7, 12672],
    ],
    [
      [264305, 297366],
      [79, -1215],
      [-282, -4274],
    ],
    [
      [264102, 291877],
      [-95, 1],
    ],
    [
      [264007, 291878],
      [19, 2058],
      [-215, 1635],
    ],
    [
      [265301, 293585],
      [-113, -1287],
      [-385, -1434],
    ],
    [
      [264803, 290864],
      [-134, 698],
      [-567, 315],
    ],
    [
      [238000, 294286],
      [55, -4237],
      [-203, -2132],
      [-208, 87],
      [128, -2331],
    ],
    [
      [237772, 285673],
      [-29, 1],
    ],
    [
      [237743, 285674],
      [-635, -3],
    ],
    [
      [237108, 285671],
      [0, 4691],
    ],
    [
      [269592, 291124],
      [-134, -694],
    ],
    [
      [269458, 290430],
      [-356, 976],
      [-547, -1372],
    ],
    [
      [268555, 290034],
      [-268, 3536],
    ],
    [
      [256546, 289910],
      [-601, -2119],
      [-102, -2363],
      [-288, -51],
    ],
    [
      [255555, 285377],
      [-818, 63],
    ],
    [
      [254737, 285440],
      [-326, 195],
      [-14, 2539],
    ],
    [
      [254397, 288174],
      [-9, 6911],
    ],
    [
      [211995, 292545],
      [-22, -7993],
    ],
    [
      [211973, 284552],
      [0, -8133],
    ],
    [
      [211973, 276419],
      [1, -8101],
    ],
    [
      [211974, 268318],
      [0, -1611],
      [-731, -3],
    ],
    [
      [211243, 266704],
      [-1104, -3],
    ],
    [
      [210139, 266701],
      [0, 9724],
      [-255, -1],
      [0, 8241],
    ],
    [
      [252080, 290655],
      [-401, -9],
    ],
    [
      [251679, 290646],
      [-766, -8],
    ],
    [
      [250913, 290638],
      [-1, 4601],
    ],
    [
      [244988, 295164],
      [-59, -1673],
      [289, 734],
      [-26, -3356],
      [151, -2943],
      [-317, -598],
      [225, -984],
      [-128, -820],
    ],
    [
      [245123, 285524],
      [0, -134],
    ],
    [
      [245123, 285390],
      [-271, 18],
    ],
    [
      [244852, 285408],
      [-481, 19],
    ],
    [
      [244371, 285427],
      [-67, -3],
    ],
    [
      [244304, 285424],
      [8, 7131],
    ],
    [
      [264007, 291878],
      [-125, -1789],
      [-179, 18],
    ],
    [
      [263703, 290107],
      [-245, 1867],
      [-67, 2695],
    ],
    [
      [258149, 287230],
      [-73, -1530],
    ],
    [
      [258076, 285700],
      [-260, 915],
      [-756, 37],
      [2, -410],
    ],
    [
      [257062, 286242],
      [-403, 2158],
      [-4, 1501],
    ],
    [
      [271073, 289921],
      [-669, -319],
      [-229, 1236],
      [-260, -546],
    ],
    [
      [269915, 290292],
      [-148, 616],
    ],
    [
      [269767, 290908],
      [-91, 220],
    ],
    [
      [254397, 288174],
      [-931, -2937],
    ],
    [
      [253466, 285237],
      [-470, -87],
    ],
    [
      [252996, 285150],
      [100, 5531],
    ],
    [
      [250913, 290638],
      [-58, -1638],
      [-291, -9],
      [-3, -1640],
      [-289, -10],
    ],
    [
      [250272, 287341],
      [5, 3289],
      [-383, 7],
    ],
    [
      [246377, 290341],
      [40, -3234],
    ],
    [
      [246417, 287107],
      [-592, 20],
      [0, -1670],
    ],
    [
      [245825, 285457],
      [-702, 67],
    ],
    [
      [266388, 293402],
      [-34, -4888],
    ],
    [
      [266354, 288514],
      [-753, -747],
    ],
    [
      [265601, 287767],
      [-17, 901],
    ],
    [
      [265584, 288668],
      [-113, 3502],
    ],
    [
      [263703, 290107],
      [-14, -676],
    ],
    [
      [263689, 289431],
      [-975, 44],
      [-28, -604],
    ],
    [
      [262686, 288871],
      [-213, 626],
    ],
    [
      [262473, 289497],
      [-213, 3738],
    ],
    [
      [277775, 289288],
      [-200, -1210],
      [-345, -446],
      [-431, -3536],
    ],
    [
      [276799, 284096],
      [-357, -1958],
      [-73, 1501],
    ],
    [
      [276369, 283639],
      [-170, -185],
      [-119, 1854],
      [-263, 409],
    ],
    [
      [275817, 285717],
      [-479, 2444],
      [-113, 1946],
    ],
    [
      [175704, 293265],
      [8, -6545],
      [-64, -14],
      [-11, -8498],
    ],
    [
      [175637, 278208],
      [-2838, -1564],
      [17, 2580],
      [-320, 834],
      [-131, 1949],
      [82, 971],
      [-206, 4490],
      [-532, 4208],
      [-221, 846],
    ],
    [
      [261484, 293283],
      [160, -5557],
    ],
    [
      [261644, 287726],
      [11, -400],
    ],
    [
      [261655, 287326],
      [-1005, -14],
    ],
    [
      [260650, 287312],
      [-168, -13],
    ],
    [
      [260482, 287299],
      [28, 7246],
    ],
    [
      [260482, 287299],
      [-991, -305],
    ],
    [
      [259491, 286994],
      [-460, 264],
    ],
    [
      [233588, 292352],
      [-6, -55],
    ],
    [
      [233582, 292297],
      [-854, -556],
      [-398, -900],
      [-289, -1444],
    ],
    [
      [232041, 289397],
      [8, 3545],
    ],
    [
      [272827, 293505],
      [-15, -5200],
      [98, -784],
    ],
    [
      [272910, 287521],
      [-478, -195],
      [-495, 933],
      [-201, -1171],
    ],
    [
      [271736, 287088],
      [-363, 872],
      [-35, 1042],
    ],
    [
      [267418, 291921],
      [45, -836],
      [-297, -951],
      [-55, -1334],
    ],
    [
      [267111, 288800],
      [-431, -35],
    ],
    [
      [266680, 288765],
      [-326, -251],
    ],
    [
      [202831, 292589],
      [15, -6292],
    ],
    [
      [202846, 286297],
      [-1525, -4281],
      [1, -811],
      [-1146, 18],
      [-2, -3253],
    ],
    [
      [200174, 277970],
      [-860, -3],
    ],
    [
      [199314, 277967],
      [-358, 449],
      [43, 2766],
      [-165, 3253],
      [-161, 392],
      [-104, 4258],
      [-348, -19],
    ],
    [
      [238996, 293588],
      [-214, -3466],
      [-111, 31],
      [-24, -4496],
    ],
    [
      [238647, 285657],
      [-85, 2],
    ],
    [
      [238562, 285659],
      [-790, 14],
    ],
    [
      [268555, 290034],
      [21, -284],
    ],
    [
      [268576, 289750],
      [-391, -1856],
      [-455, -1079],
    ],
    [
      [267730, 286815],
      [-127, 1309],
      [-492, 676],
    ],
    [
      [183362, 276096],
      [1, -8676],
    ],
    [
      [183363, 267420],
      [-4125, 8475],
      [13, 2284],
      [251, 1898],
    ],
    [
      [179502, 280077],
      [536, 715],
      [179, 2807],
      [-146, 2209],
    ],
    [
      [191393, 276263],
      [-1966, -35],
      [0, -192],
      [-2916, 95],
    ],
    [
      [226312, 293390],
      [-10, -8052],
    ],
    [
      [226302, 285338],
      [-375, 38],
    ],
    [
      [225927, 285376],
      [-1032, 85],
    ],
    [
      [224895, 285461],
      [17, 7228],
    ],
    [
      [240074, 292338],
      [-28, -6700],
    ],
    [
      [240046, 285638],
      [-697, 10],
    ],
    [
      [239349, 285648],
      [-702, 9],
    ],
    [
      [265584, 288668],
      [-611, 418],
    ],
    [
      [264973, 289086],
      [-229, 4],
    ],
    [
      [264744, 289090],
      [59, 1774],
    ],
    [
      [274010, 288608],
      [-793, -2802],
    ],
    [
      [273217, 285806],
      [-307, 1715],
    ],
    [
      [179502, 280077],
      [-3865, -1869],
    ],
    [
      [227802, 293328],
      [-42, -8197],
    ],
    [
      [227760, 285131],
      [-406, 73],
    ],
    [
      [227354, 285204],
      [-1052, 134],
    ],
    [
      [229332, 292863],
      [-29, -7782],
    ],
    [
      [229303, 285081],
      [-523, -53],
    ],
    [
      [228780, 285028],
      [-1020, 103],
    ],
    [
      [262473, 289497],
      [-829, -1771],
    ],
    [
      [232039, 284934],
      [-231, 5],
      [-115, -1982],
      [146, -674],
    ],
    [
      [231839, 282283],
      [-397, 23],
    ],
    [
      [231442, 282306],
      [-615, 61],
    ],
    [
      [230827, 282367],
      [1, 2606],
    ],
    [
      [230828, 284973],
      [5, 6893],
    ],
    [
      [232041, 289397],
      [-2, -4463],
    ],
    [
      [230828, 284973],
      [-612, 11],
    ],
    [
      [230216, 284984],
      [-913, 97],
    ],
    [
      [219070, 292721],
      [-5, -8098],
    ],
    [
      [219065, 284623],
      [-381, 10],
    ],
    [
      [218684, 284633],
      [-1066, 127],
    ],
    [
      [217618, 284760],
      [-1, 7948],
    ],
    [
      [220537, 292711],
      [5, -8140],
    ],
    [
      [220542, 284571],
      [-432, -2],
    ],
    [
      [220110, 284569],
      [-1045, 54],
    ],
    [
      [234975, 292084],
      [-31, -7088],
    ],
    [
      [234944, 284996],
      [-292, 1575],
      [-561, -613],
    ],
    [
      [234091, 285958],
      [1, 6606],
    ],
    [
      [217618, 284760],
      [-376, -125],
    ],
    [
      [217242, 284635],
      [-1070, -47],
    ],
    [
      [216172, 284588],
      [2, 8075],
    ],
    [
      [223426, 292715],
      [8, -8204],
    ],
    [
      [223434, 284511],
      [-404, 2],
    ],
    [
      [223030, 284513],
      [-1045, 0],
    ],
    [
      [221985, 284513],
      [-4, 8229],
    ],
    [
      [224895, 285461],
      [-2, -1035],
      [-413, 38],
    ],
    [
      [224480, 284464],
      [-1046, 47],
    ],
    [
      [221985, 284513],
      [-393, -1],
    ],
    [
      [221592, 284512],
      [-1050, 59],
    ],
    [
      [244304, 285424],
      [-1696, 42],
    ],
    [
      [242608, 285466],
      [-191, 2882],
    ],
    [
      [216172, 284588],
      [-374, 14],
    ],
    [
      [215798, 284602],
      [-1072, -38],
    ],
    [
      [214726, 284564],
      [2, 8003],
    ],
    [
      [206995, 276387],
      [10, -9680],
    ],
    [
      [207005, 266707],
      [-197, -1],
    ],
    [
      [206808, 266706],
      [-3007, 34],
    ],
    [
      [203801, 266740],
      [-1057, -19],
    ],
    [
      [202744, 266721],
      [2, 17810],
      [100, 1766],
    ],
    [
      [213282, 292546],
      [0, -7999],
    ],
    [
      [213282, 284547],
      [-1309, 5],
    ],
    [
      [214726, 284564],
      [-368, -14],
    ],
    [
      [214358, 284550],
      [-1076, -3],
    ],
    [
      [234091, 285958],
      [-73, -393],
    ],
    [
      [234018, 285565],
      [-60, -961],
      [-377, 12],
    ],
    [
      [233581, 284616],
      [1, 7681],
    ],
    [
      [242608, 285466],
      [-1828, 115],
    ],
    [
      [240780, 285581],
      [-734, 57],
    ],
    [
      [233581, 284616],
      [-993, -39],
    ],
    [
      [232588, 284577],
      [-549, 357],
    ],
    [
      [235411, 290319],
      [-5, -7248],
    ],
    [
      [235406, 283071],
      [-145, -6],
    ],
    [
      [235261, 283065],
      [-39, 471],
    ],
    [
      [235222, 283536],
      [-278, 1460],
    ],
    [
      [249070, 289307],
      [-206, -1883],
      [-209, -3399],
      [-189, -953],
    ],
    [
      [248466, 283072],
      [-1108, 2439],
    ],
    [
      [247358, 285511],
      [-151, 1938],
      [245, 380],
      [-3, 3123],
    ],
    [
      [264744, 289090],
      [-346, -257],
    ],
    [
      [264398, 288833],
      [-567, 369],
      [-126, -463],
    ],
    [
      [263705, 288739],
      [-16, 692],
    ],
    [
      [275817, 285717],
      [197, -1750],
      [-222, -2037],
      [-677, 747],
    ],
    [
      [275115, 282677],
      [4, 3611],
      [-520, 96],
      [-567, 2296],
    ],
    [
      [247358, 285511],
      [-237, -29],
      [-3, -1592],
      [-571, -19],
    ],
    [
      [246547, 283871],
      [-8, 3240],
      [-122, -4],
    ],
    [
      [269458, 290430],
      [-641, -2764],
    ],
    [
      [268817, 287666],
      [-197, 886],
    ],
    [
      [268620, 288552],
      [-44, 1198],
    ],
    [
      [269915, 290292],
      [98, -679],
      [-163, -3128],
      [52, -2328],
      [-122, -1899],
    ],
    [
      [269780, 282258],
      [-329, -1370],
    ],
    [
      [269451, 280888],
      [-242, 1119],
    ],
    [
      [269209, 282007],
      [-81, 3665],
      [-311, 1994],
    ],
    [
      [237108, 285671],
      [0, -2571],
    ],
    [
      [237108, 283100],
      [-1702, -29],
    ],
    [
      [271736, 287088],
      [202, -924],
    ],
    [
      [271938, 286164],
      [-630, -2537],
    ],
    [
      [271308, 283627],
      [-251, 828],
      [-618, -687],
      [-182, -1942],
    ],
    [
      [270257, 281826],
      [-477, 432],
    ],
    [
      [252996, 285150],
      [-21, -1156],
    ],
    [
      [252975, 283994],
      [-1295, -59],
    ],
    [
      [251680, 283935],
      [-1, 6711],
    ],
    [
      [251680, 283935],
      [-283, 11],
    ],
    [
      [251397, 283946],
      [-1128, 96],
    ],
    [
      [250269, 284042],
      [3, 3299],
    ],
    [
      [250269, 284042],
      [-1132, -33],
      [-12, -840],
    ],
    [
      [249125, 283169],
      [-659, -97],
    ],
    [
      [257062, 286242],
      [14, -3976],
      [-399, 14],
    ],
    [
      [256677, 282280],
      [-836, -103],
      [-284, 800],
    ],
    [
      [255557, 282977],
      [-2, 2400],
    ],
    [
      [269209, 282007],
      [-689, -991],
      [-95, 755],
      [-402, -936],
    ],
    [
      [268023, 280835],
      [-353, 3484],
    ],
    [
      [267670, 284319],
      [60, 2496],
    ],
    [
      [263705, 288739],
      [-84, -3973],
    ],
    [
      [263621, 284766],
      [55, -1652],
    ],
    [
      [263676, 283114],
      [-539, -692],
    ],
    [
      [263137, 282422],
      [-449, 518],
    ],
    [
      [262688, 282940],
      [-2, 5931],
    ],
    [
      [262688, 282940],
      [-898, -38],
    ],
    [
      [261790, 282902],
      [-135, 4424],
    ],
    [
      [199314, 277967],
      [-878, -19],
      [0, -1622],
      [-852, -5],
      [-2, -8135],
      [36, -4019],
    ],
    [
      [197618, 264167],
      [-857, -7],
      [-37, 4023],
      [2, 8133],
      [-306, 3],
      [2, 1614],
      [-576, 1],
      [-5, 3232],
      [-536, 6],
    ],
    [
      [264398, 288833],
      [-62, -3686],
    ],
    [
      [264336, 285147],
      [-715, -381],
    ],
    [
      [264973, 289086],
      [-7, -4742],
      [-221, -294],
    ],
    [
      [264745, 284050],
      [2, 1070],
      [-411, 27],
    ],
    [
      [265601, 287767],
      [265, -1986],
      [29, -1346],
    ],
    [
      [265895, 284435],
      [-506, -1945],
    ],
    [
      [265389, 282490],
      [-646, 23],
    ],
    [
      [264743, 282513],
      [2, 1537],
    ],
    [
      [267670, 284319],
      [-205, 1161],
      [-587, -1542],
    ],
    [
      [266878, 283938],
      [-198, 4827],
    ],
    [
      [266878, 283938],
      [-137, -519],
    ],
    [
      [266741, 283419],
      [-297, -993],
    ],
    [
      [266444, 282426],
      [-403, 799],
      [-146, 1210],
    ],
    [
      [275115, 282677],
      [-128, -2424],
      [77, -2306],
    ],
    [
      [275064, 277947],
      [21, -1799],
      [-453, 1073],
    ],
    [
      [274632, 277221],
      [-397, 2101],
      [-304, 487],
    ],
    [
      [273931, 279809],
      [-206, 2389],
      [-508, 3608],
    ],
    [
      [273217, 285806],
      [-382, -1361],
      [-117, -2128],
      [-399, -1751],
    ],
    [
      [272319, 280566],
      [-269, 3521],
      [-24, 1416],
    ],
    [
      [272026, 285503],
      [-88, 661],
    ],
    [
      [254737, 285440],
      [-323, -2639],
      [122, -3923],
      [141, -1192],
      [-210, -1223],
    ],
    [
      [254467, 276463],
      [-114, 140],
    ],
    [
      [254353, 276603],
      [-184, 1820],
      [-374, -689],
      [-62, 3380],
      [-367, 2778],
      [100, 1345],
    ],
    [
      [261790, 282902],
      [136, -2308],
    ],
    [
      [261926, 280594],
      [-1276, -334],
    ],
    [
      [260650, 280260],
      [0, 7052],
    ],
    [
      [260650, 280260],
      [-288, -579],
      [2, -1890],
    ],
    [
      [260364, 277791],
      [-286, -278],
      [4, -1615],
      [-248, -23],
    ],
    [
      [259834, 275875],
      [-17, 4870],
      [-320, 8],
    ],
    [
      [259497, 280753],
      [-6, 6241],
    ],
    [
      [259497, 280753],
      [-1025, -27],
    ],
    [
      [258472, 280726],
      [-231, 1150],
      [-165, 3824],
    ],
    [
      [246547, 283871],
      [-292, -1615],
      [176, -342],
      [-73, -2890],
    ],
    [
      [246358, 279024],
      [-674, 12],
      [3, 3263],
      [138, 3158],
    ],
    [
      [235222, 283536],
      [-1204, -38],
    ],
    [
      [234018, 283498],
      [0, 2067],
    ],
    [
      [258472, 280726],
      [-106, -860],
    ],
    [
      [258366, 279866],
      [-839, -31],
      [-2, -819],
      [-563, 45],
    ],
    [
      [256962, 279061],
      [-281, -16],
      [0, 1226],
    ],
    [
      [256681, 280271],
      [-4, 2009],
    ],
    [
      [202744, 266721],
      [-672, -16],
      [-49, -2500],
      [301, -1531],
    ],
    [
      [202324, 262674],
      [-2142, -5],
    ],
    [
      [200182, 262669],
      [-8, 15301],
    ],
    [
      [272319, 280566],
      [44, -2784],
    ],
    [
      [272363, 277782],
      [-442, -1977],
    ],
    [
      [271921, 275805],
      [-595, 2074],
    ],
    [
      [271326, 277879],
      [-222, 903],
    ],
    [
      [271104, 278782],
      [-65, 751],
      [269, 4094],
    ],
    [
      [273931, 279809],
      [-121, -807],
    ],
    [
      [273810, 279002],
      [-90, -725],
      [-314, 2439],
      [-742, -3661],
    ],
    [
      [272664, 277055],
      [-301, 727],
    ],
    [
      [248466, 283072],
      [-231, -2682],
      [-557, -1363],
    ],
    [
      [247678, 279027],
      [-560, -1640],
    ],
    [
      [247118, 277387],
      [-289, -1241],
    ],
    [
      [246829, 276146],
      [-463, 2072],
    ],
    [
      [246366, 278218],
      [-8, 806],
    ],
    [
      [238701, 271113],
      [-400, 6],
    ],
    [
      [238301, 271119],
      [-149, 1513],
      [-322, 444],
      [-467, -2738],
      [-254, 8],
    ],
    [
      [237109, 270346],
      [-1, 3653],
    ],
    [
      [237108, 273999],
      [0, 5600],
    ],
    [
      [237108, 279599],
      [0, 3501],
    ],
    [
      [237743, 285674],
      [-73, -1364],
      [157, -1652],
      [-2, -3964],
      [475, -5512],
      [401, -2069],
    ],
    [
      [238562, 285659],
      [194, -3036],
      [29, -8285],
      [191, -5],
    ],
    [
      [238976, 274333],
      [-156, -3244],
    ],
    [
      [238820, 271089],
      [-119, 24],
    ],
    [
      [239516, 277585],
      [89, -2415],
      [-250, -839],
      [-379, 2],
    ],
    [
      [239349, 285648],
      [4, -5606],
      [141, -1],
      [22, -2456],
    ],
    [
      [240779, 280837],
      [-287, -14],
      [-146, -3236],
    ],
    [
      [240346, 277587],
      [-830, -2],
    ],
    [
      [240780, 285581],
      [-1, -4744],
    ],
    [
      [242619, 280157],
      [-575, -2639],
      [-400, 30],
    ],
    [
      [241644, 277548],
      [0, 1623],
      [-290, 937],
      [-575, 729],
    ],
    [
      [242608, 285466],
      [11, -5309],
    ],
    [
      [234018, 283498],
      [-3, -6187],
    ],
    [
      [234015, 277311],
      [-813, 788],
    ],
    [
      [233202, 278099],
      [-417, 1389],
    ],
    [
      [232785, 279488],
      [-113, 619],
    ],
    [
      [232672, 280107],
      [-84, 4470],
    ],
    [
      [246366, 278218],
      [-28, -989],
      [-363, 176],
      [-2, -2478],
      [-251, 42],
      [-36, 2444],
      [-221, 12],
    ],
    [
      [245465, 277425],
      [-297, 1279],
      [258, 1420],
      [-227, 256],
      [-7, 1864],
      [186, 934],
      [-62, 1882],
      [-224, -1603],
      [31, 1933],
    ],
    [
      [268023, 280835],
      [-29, -951],
    ],
    [
      [267994, 279884],
      [-750, -2311],
    ],
    [
      [267244, 277573],
      [-503, 5846],
    ],
    [
      [244371, 285427],
      [-163, -2146],
      [-308, -2022],
      [-90, -2138],
    ],
    [
      [243810, 279121],
      [-368, -2107],
      [-400, -899],
    ],
    [
      [243042, 276115],
      [-26, 3010],
      [-397, 1032],
    ],
    [
      [255557, 282977],
      [-143, -816],
      [-4, -3254],
      [-143, -3],
      [2, -3233],
    ],
    [
      [255269, 275671],
      [-569, -21],
      [-233, 813],
    ],
    [
      [244852, 285408],
      [-216, -2699],
      [-7, -2066],
      [-283, -3112],
    ],
    [
      [244346, 277531],
      [-420, 5],
      [-116, 1585],
    ],
    [
      [225927, 285376],
      [-30, -8275],
    ],
    [
      [225897, 277101],
      [-4, -879],
    ],
    [
      [225893, 276222],
      [-1134, 26],
    ],
    [
      [224759, 276248],
      [-281, 39],
    ],
    [
      [224478, 276287],
      [2, 8177],
    ],
    [
      [245465, 277425],
      [-76, -261],
    ],
    [
      [245389, 277164],
      [-175, -453],
      [-903, -2],
    ],
    [
      [244311, 276709],
      [35, 822],
    ],
    [
      [227354, 285204],
      [-18, -8167],
    ],
    [
      [227336, 277037],
      [-186, 1],
    ],
    [
      [227150, 277038],
      [-1253, 63],
    ],
    [
      [264743, 282513],
      [-219, -2968],
    ],
    [
      [264524, 279545],
      [-235, 1092],
    ],
    [
      [264289, 280637],
      [-264, 580],
      [-349, 1897],
    ],
    [
      [254353, 276603],
      [-498, -1834],
      [-40, -1059],
      [323, -1231],
    ],
    [
      [254138, 272479],
      [-1368, -35],
    ],
    [
      [252770, 272444],
      [91, 5015],
    ],
    [
      [252861, 277459],
      [114, 6535],
    ],
    [
      [228780, 285028],
      [-17, -8118],
    ],
    [
      [228763, 276910],
      [-135, 16],
    ],
    [
      [228628, 276926],
      [-1292, 111],
    ],
    [
      [230216, 284984],
      [-6, -3141],
    ],
    [
      [230210, 281843],
      [-31, -4995],
    ],
    [
      [230179, 276848],
      [-1416, 62],
    ],
    [
      [230827, 282367],
      [0, -513],
      [-617, -11],
    ],
    [
      [232672, 280107],
      [-600, 939],
      [-233, 1237],
    ],
    [
      [218684, 284633],
      [-13, -8156],
    ],
    [
      [218671, 276477],
      [-1432, 43],
    ],
    [
      [217239, 276520],
      [3, 8115],
    ],
    [
      [210139, 266701],
      [-717, -2],
    ],
    [
      [209422, 266699],
      [-123, -1],
    ],
    [
      [209299, 266698],
      [-2294, 9],
    ],
    [
      [220110, 284569],
      [-7, -8139],
    ],
    [
      [220103, 276430],
      [-1432, 47],
    ],
    [
      [217239, 276520],
      [0, -66],
    ],
    [
      [217239, 276454],
      [-1432, 21],
    ],
    [
      [215807, 276475],
      [-9, 8127],
    ],
    [
      [215807, 276475],
      [-1432, -37],
    ],
    [
      [214375, 276438],
      [-17, 8112],
    ],
    [
      [214375, 276438],
      [-25, -1],
    ],
    [
      [214350, 276437],
      [-2377, -18],
    ],
    [
      [221592, 284512],
      [0, -8234],
    ],
    [
      [221592, 276278],
      [-49, 0],
    ],
    [
      [221543, 276278],
      [-1440, 152],
    ],
    [
      [223030, 284513],
      [0, -8233],
    ],
    [
      [223030, 276280],
      [-51, 1],
    ],
    [
      [222979, 276281],
      [-1387, -3],
    ],
    [
      [224478, 276287],
      [-1448, -7],
    ],
    [
      [271104, 278782],
      [-447, -786],
    ],
    [
      [270657, 277996],
      [-226, 1046],
      [-174, 2784],
    ],
    [
      [266444, 282426],
      [-237, -2443],
    ],
    [
      [266207, 279983],
      [2, -920],
    ],
    [
      [266209, 279063],
      [-288, 519],
    ],
    [
      [265921, 279582],
      [-345, 931],
      [-187, 1977],
    ],
    [
      [251397, 283946],
      [0, -6508],
    ],
    [
      [251397, 277438],
      [-1123, -9],
    ],
    [
      [250274, 277429],
      [-5, 6613],
    ],
    [
      [250274, 277429],
      [-14, -1],
    ],
    [
      [250260, 277428],
      [-1136, 15],
      [0, 1061],
    ],
    [
      [249124, 278504],
      [1, 4665],
    ],
    [
      [252861, 277459],
      [-1464, -21],
    ],
    [
      [235261, 283065],
      [11, -1603],
    ],
    [
      [235272, 281462],
      [1, -2618],
    ],
    [
      [235273, 278844],
      [-795, -2143],
    ],
    [
      [234478, 276701],
      [-463, 610],
    ],
    [
      [267244, 277573],
      [-756, -2456],
    ],
    [
      [266488, 275117],
      [-124, 3123],
      [-155, 823],
    ],
    [
      [264289, 280637],
      [-142, -524],
      [-197, -2955],
      [-99, 3],
    ],
    [
      [263851, 277161],
      [-538, -506],
    ],
    [
      [263313, 276655],
      [-159, -301],
    ],
    [
      [263154, 276354],
      [0, 1215],
    ],
    [
      [263154, 277569],
      [-17, 4853],
    ],
    [
      [237108, 279599],
      [-281, 902],
      [-86, -872],
      [-299, 1007],
      [-286, -1037],
      [-341, 13],
      [-543, 1850],
    ],
    [
      [249124, 278504],
      [-151, -881],
    ],
    [
      [248973, 277623],
      [-205, -34],
      [-580, -3455],
    ],
    [
      [248188, 274134],
      [-494, 8],
      [0, 1631],
      [-576, 8],
      [0, 1606],
    ],
    [
      [256681, 280271],
      [-69, -2498],
      [-191, -1960],
      [-869, -125],
      [4, -3255],
      [-143, -9],
    ],
    [
      [255413, 272424],
      [-143, -6],
      [-1, 3253],
    ],
    [
      [263154, 277569],
      [-1076, 448],
    ],
    [
      [262078, 278017],
      [-152, 2577],
    ],
    [
      [265921, 279582],
      [1, -28],
    ],
    [
      [265922, 279554],
      [-184, 35],
      [-659, -3027],
    ],
    [
      [265079, 276562],
      [-284, 2688],
      [-271, 295],
    ],
    [
      [231442, 282306],
      [2, -8953],
    ],
    [
      [231444, 273353],
      [-1049, 21],
    ],
    [
      [230395, 273374],
      [-216, 3474],
    ],
    [
      [270657, 277996],
      [-80, -1269],
    ],
    [
      [270577, 276727],
      [-329, -341],
      [-291, -3111],
      [55, -643],
    ],
    [
      [270012, 272632],
      [0, -5],
    ],
    [
      [270012, 272627],
      [-338, -491],
      [-153, 1148],
    ],
    [
      [269521, 273284],
      [74, 1606],
      [-301, 1073],
      [-437, 275],
    ],
    [
      [268857, 276238],
      [507, 2500],
      [87, 2150],
    ],
    [
      [232785, 279488],
      [1, -3858],
      [404, -2322],
    ],
    [
      [233190, 273308],
      [-1746, 45],
    ],
    [
      [268857, 276238],
      [-59, 1856],
      [-542, 1927],
      [-262, -137],
    ],
    [
      [237108, 273999],
      [-848, -1143],
      [-402, 1183],
    ],
    [
      [235858, 274039],
      [-244, 1],
    ],
    [
      [235614, 274040],
      [-342, 532],
      [1, 4272],
    ],
    [
      [197618, 264167],
      [24, -1497],
    ],
    [
      [197642, 262670],
      [0, -8384],
      [-2346, -23],
    ],
    [
      [195296, 254263],
      [8, 20372],
    ],
    [
      [259834, 275875],
      [18, -769],
      [-399, -590],
    ],
    [
      [259453, 274516],
      [-479, 386],
      [-118, 1013],
      [-486, -1586],
    ],
    [
      [258370, 274329],
      [-4, 5537],
    ],
    [
      [241644, 277548],
      [-1, -1627],
    ],
    [
      [241643, 275921],
      [-577, 18],
      [2, -799],
      [-433, -2],
    ],
    [
      [240635, 275138],
      [-285, 12],
      [-4, 2437],
    ],
    [
      [273810, 279002],
      [98, -1654],
    ],
    [
      [273908, 277348],
      [8, -2098],
    ],
    [
      [273916, 275250],
      [-11, -827],
    ],
    [
      [273905, 274423],
      [15, -90],
    ],
    [
      [273920, 274333],
      [-20, -184],
    ],
    [
      [273900, 274149],
      [-60, -392],
    ],
    [
      [273840, 273757],
      [-438, -2518],
      [175, -1903],
    ],
    [
      [273577, 269336],
      [17, -224],
    ],
    [
      [273594, 269112],
      [-42, -1026],
      [-431, 800],
    ],
    [
      [273121, 268886],
      [-76, 2024],
    ],
    [
      [273045, 270910],
      [44, 2085],
      [-299, 3226],
    ],
    [
      [272790, 276221],
      [-126, 834],
    ],
    [
      [265079, 276562],
      [-41, -437],
    ],
    [
      [265038, 276125],
      [-259, 143],
      [-399, -2644],
    ],
    [
      [264380, 273624],
      [-384, 781],
    ],
    [
      [263996, 274405],
      [-145, 2756],
    ],
    [
      [262078, 278017],
      [222, -1817],
    ],
    [
      [262300, 276200],
      [-162, -699],
      [-765, -79],
      [10, -1076],
      [-289, -21],
    ],
    [
      [261094, 274325],
      [-155, 1623],
      [-575, 1843],
    ],
    [
      [256962, 279061],
      [29, -2256],
      [255, -3763],
    ],
    [
      [257246, 273042],
      [-260, -2156],
      [4, -3296],
    ],
    [
      [256990, 267590],
      [-755, -8],
      [-207, 1771],
      [-614, 2276],
    ],
    [
      [255414, 271629],
      [-1, 795],
    ],
    [
      [243042, 276115],
      [-252, -2320],
      [-86, -1940],
    ],
    [
      [242704, 271855],
      [-773, 7],
    ],
    [
      [241931, 271862],
      [-288, 2425],
      [0, 1634],
    ],
    [
      [268650, 272453],
      [-454, -2106],
    ],
    [
      [268196, 270347],
      [-296, -908],
      [-412, 5134],
    ],
    [
      [267488, 274573],
      [-244, 3000],
    ],
    [
      [268857, 276238],
      [-273, -3365],
      [66, -420],
    ],
    [
      [258370, 274329],
      [-238, -1220],
    ],
    [
      [258132, 273109],
      [-630, 1133],
      [-256, -1200],
    ],
    [
      [274213, 271739],
      [154, -1026],
      [-395, -1964],
      [241, 2990],
    ],
    [
      [273840, 273757],
      [261, -1594],
      [-185, -2612],
      [-322, -439],
    ],
    [
      [274414, 275981],
      [264, -153],
      [280, -1524],
      [-294, -990],
      [-167, -1798],
      [-139, 1959],
      [56, 2506],
    ],
    [
      [274160, 276707],
      [170, -315],
      [46, -4156],
      [-277, 1435],
      [-122, 1906],
      [183, 1130],
    ],
    [
      [274632, 277221],
      [33, -1171],
      [-536, 816],
      [-157, -984],
      [-64, 1466],
    ],
    [
      [265516, 275419],
      [-478, 706],
    ],
    [
      [265922, 279554],
      [-53, -2957],
      [-353, -1178],
    ],
    [
      [266488, 275117],
      [0, -942],
    ],
    [
      [266488, 274175],
      [-328, -2106],
    ],
    [
      [266160, 272069],
      [-649, 44],
    ],
    [
      [265511, 272113],
      [5, 3306],
    ],
    [
      [234478, 276701],
      [5, -3100],
    ],
    [
      [234483, 273601],
      [0, -4335],
    ],
    [
      [234483, 269266],
      [-1322, -45],
    ],
    [
      [233161, 269221],
      [-81, 1800],
      [110, 2287],
    ],
    [
      [244311, 276709],
      [-53, -2458],
    ],
    [
      [244258, 274251],
      [-441, -8],
      [-351, -1303],
      [-29, -1625],
      [-344, -1798],
    ],
    [
      [243093, 269517],
      [-234, 272],
      [-155, 2066],
    ],
    [
      [235614, 274040],
      [-175, -434],
      [-956, -5],
    ],
    [
      [271921, 275805],
      [37, -1771],
      [275, -2845],
    ],
    [
      [272233, 271189],
      [-961, -1642],
    ],
    [
      [271272, 269547],
      [-98, 1564],
      [-427, 591],
    ],
    [
      [270747, 271702],
      [140, 2717],
      [-310, 2308],
    ],
    [
      [250260, 277428],
      [-1, -6571],
    ],
    [
      [250259, 270857],
      [-1135, -31],
    ],
    [
      [249124, 270826],
      [0, 2438],
      [-151, 4359],
    ],
    [
      [246829, 276146],
      [-140, -527],
      [-67, -2600],
      [-183, 267],
      [-97, -2398],
    ],
    [
      [246342, 270888],
      [-114, -1301],
      [-411, -1386],
      [-315, 624],
    ],
    [
      [245502, 268825],
      [-3, 115],
    ],
    [
      [245499, 268940],
      [-61, 70],
    ],
    [
      [245438, 269010],
      [-315, 191],
      [118, 1426],
    ],
    [
      [245241, 270627],
      [337, 223],
      [252, 2167],
      [-256, 411],
      [68, 1606],
      [-306, 122],
      [53, 2008],
    ],
    [
      [263154, 276354],
      [-211, -1787],
      [-562, -838],
    ],
    [
      [262381, 273729],
      [-81, 2471],
    ],
    [
      [200182, 262669],
      [-2540, 1],
    ],
    [
      [273045, 270910],
      [-142, 209],
      [-547, -2637],
    ],
    [
      [272356, 268482],
      [-123, 2707],
    ],
    [
      [261094, 274325],
      [1, -3262],
    ],
    [
      [261095, 271063],
      [-1173, -46],
      [-180, 788],
    ],
    [
      [259742, 271805],
      [-224, 1640],
    ],
    [
      [259518, 273445],
      [-65, 1071],
    ],
    [
      [249124, 270826],
      [0, -3253],
    ],
    [
      [249124, 267573],
      [-1393, 50],
    ],
    [
      [247731, 267623],
      [-42, 2293],
      [499, 4218],
    ],
    [
      [240635, 275138],
      [6, -4081],
      [-110, -1625],
    ],
    [
      [240531, 269432],
      [-347, 19],
    ],
    [
      [240184, 269451],
      [-691, 9],
    ],
    [
      [239493, 269460],
      [-75, 1632],
      [-598, -3],
    ],
    [
      [267488, 274573],
      [-96, 548],
      [-482, -3351],
    ],
    [
      [266910, 271770],
      [-422, 2405],
    ],
    [
      [252770, 272444],
      [-27, -1509],
    ],
    [
      [252743, 270935],
      [-1345, -64],
    ],
    [
      [251398, 270871],
      [-1, 6567],
    ],
    [
      [251398, 270871],
      [-1139, -14],
    ],
    [
      [247731, 267623],
      [-1360, -26],
    ],
    [
      [246371, 267597],
      [-29, 3291],
    ],
    [
      [245241, 270627],
      [-1027, -144],
    ],
    [
      [244214, 270483],
      [-103, 1786],
      [147, 1982],
    ],
    [
      [263996, 274405],
      [-106, -4613],
    ],
    [
      [263890, 269792],
      [-3, -599],
    ],
    [
      [263887, 269193],
      [-267, 9],
      [-340, 1832],
    ],
    [
      [263280, 271034],
      [-27, -2],
    ],
    [
      [263253, 271032],
      [60, 5623],
    ],
    [
      [270747, 271702],
      [-157, 187],
    ],
    [
      [270590, 271889],
      [-578, 743],
    ],
    [
      [227150, 277038],
      [6, -4410],
    ],
    [
      [227156, 272628],
      [-469, -47],
      [-452, -1537],
    ],
    [
      [226235, 271044],
      [-342, 5178],
    ],
    [
      [228628, 276926],
      [1, -5287],
    ],
    [
      [228629, 271639],
      [-1086, -1713],
    ],
    [
      [227543, 269926],
      [-107, -742],
      [-280, 1303],
    ],
    [
      [227156, 270487],
      [0, 2141],
    ],
    [
      [230395, 273374],
      [194, -555],
    ],
    [
      [230589, 272819],
      [-1430, -4745],
    ],
    [
      [229159, 268074],
      [-124, -408],
      [-406, 3973],
    ],
    [
      [255414, 271629],
      [-142, -188],
      [4, -2273],
      [-284, -8],
      [4, -2361],
      [-126, -263],
    ],
    [
      [254870, 266536],
      [-1130, -23],
    ],
    [
      [253740, 266513],
      [-126, 1159],
      [208, 569],
      [68, 3761],
      [248, 477],
    ],
    [
      [263253, 271032],
      [-736, -38],
    ],
    [
      [262517, 270994],
      [98, 589],
      [-330, 1226],
      [96, 920],
    ],
    [
      [217239, 276454],
      [-26, -8133],
    ],
    [
      [217213, 268321],
      [-225, -2],
    ],
    [
      [216988, 268319],
      [-1199, 8],
    ],
    [
      [215789, 268327],
      [18, 8148],
    ],
    [
      [218671, 276477],
      [-13, -8190],
    ],
    [
      [218658, 268287],
      [-436, 22],
    ],
    [
      [218222, 268309],
      [-1009, 12],
    ],
    [
      [220103, 276430],
      [-15, -8195],
    ],
    [
      [220088, 268235],
      [-232, -5],
    ],
    [
      [219856, 268230],
      [-1198, 57],
    ],
    [
      [215789, 268327],
      [-226, -12],
    ],
    [
      [215563, 268315],
      [-1213, -2],
    ],
    [
      [214350, 268313],
      [0, 8124],
    ],
    [
      [214350, 268313],
      [-210, 4],
    ],
    [
      [214140, 268317],
      [-1427, -23],
    ],
    [
      [212713, 268294],
      [-739, 24],
    ],
    [
      [265511, 272113],
      [-316, -757],
      [-183, -1474],
    ],
    [
      [265012, 269882],
      [-430, 1091],
    ],
    [
      [264582, 270973],
      [-206, 1238],
      [4, 1413],
    ],
    [
      [221543, 276278],
      [-5, -8068],
    ],
    [
      [221538, 268210],
      [-231, 14],
    ],
    [
      [221307, 268224],
      [-1219, 11],
    ],
    [
      [224759, 276248],
      [2, -3983],
      [-213, -701],
    ],
    [
      [224548, 271564],
      [-1040, -3414],
    ],
    [
      [223508, 268150],
      [-541, 29],
    ],
    [
      [222967, 268179],
      [12, 8102],
    ],
    [
      [226235, 271044],
      [225, -2721],
    ],
    [
      [226460, 268323],
      [-392, -1293],
    ],
    [
      [226068, 267030],
      [-565, -1869],
    ],
    [
      [225503, 265161],
      [-119, 1166],
      [-213, -724],
      [-623, 5961],
    ],
    [
      [222967, 268179],
      [-215, -7],
    ],
    [
      [222752, 268172],
      [-1214, 38],
    ],
    [
      [191393, 274653],
      [-1, -12960],
    ],
    [
      [191392, 261693],
      [-1985, -97],
      [1, -3801],
      [-565, -10],
      [0, -1773],
    ],
    [
      [188843, 256012],
      [-5480, 11408],
    ],
    [
      [269521, 273284],
      [-686, -1046],
    ],
    [
      [268835, 272238],
      [-185, 215],
    ],
    [
      [262517, 270994],
      [-386, -1847],
      [16, -1276],
    ],
    [
      [262147, 267871],
      [-360, -24],
      [-203, 1604],
      [-425, -24],
    ],
    [
      [261159, 269427],
      [-64, 1636],
    ],
    [
      [259742, 271805],
      [-223, -445],
      [6, -3713],
    ],
    [
      [259525, 267647],
      [5, -1554],
      [-546, -20],
    ],
    [
      [258984, 266073],
      [-309, -26],
      [-4, 1611],
      [-286, -16],
    ],
    [
      [258385, 267642],
      [-7, 3607],
      [-246, 1860],
    ],
    [
      [241931, 271862],
      [-1, -2445],
    ],
    [
      [241930, 269417],
      [-1399, 15],
    ],
    [
      [268196, 270347],
      [-120, -1132],
    ],
    [
      [268076, 269215],
      [-774, -4371],
    ],
    [
      [267302, 264844],
      [-222, 1408],
      [-144, 2418],
    ],
    [
      [266936, 268670],
      [128, 1356],
      [-154, 1744],
    ],
    [
      [264582, 270973],
      [-5, -1325],
      [-687, 144],
    ],
    [
      [195296, 254263],
      [-3927, 9],
    ],
    [
      [191369, 254272],
      [23, 7421],
    ],
    [
      [258385, 267642],
      [-117, -1603],
    ],
    [
      [258268, 266039],
      [-1141, -46],
    ],
    [
      [257127, 265993],
      [-137, 1597],
    ],
    [
      [235858, 274039],
      [-247, -3595],
      [-56, -4246],
      [248, 15],
    ],
    [
      [235803, 266213],
      [165, -2415],
    ],
    [
      [235968, 263798],
      [-1351, 24],
    ],
    [
      [234617, 263822],
      [-133, 12],
      [-1, 5432],
    ],
    [
      [244214, 270483],
      [8, -2105],
      [-240, -3867],
    ],
    [
      [243982, 264511],
      [-60, 1623],
      [-510, -1824],
      [-304, 1856],
    ],
    [
      [243108, 266166],
      [-15, 3351],
    ],
    [
      [266936, 268670],
      [-756, 231],
    ],
    [
      [266180, 268901],
      [-20, 3168],
    ],
    [
      [237109, 270346],
      [74, -4024],
    ],
    [
      [237183, 266322],
      [-1380, -109],
    ],
    [
      [233161, 269221],
      [87, -951],
    ],
    [
      [233248, 268270],
      [-1739, -1463],
    ],
    [
      [231509, 266807],
      [-6, 123],
    ],
    [
      [231503, 266930],
      [-162, 2701],
      [-752, 3188],
    ],
    [
      [269315, 266119],
      [-168, -187],
    ],
    [
      [269147, 265932],
      [-152, 1040],
      [-160, 5266],
    ],
    [
      [269521, 273284],
      [-206, -7165],
    ],
    [
      [270012, 272627],
      [143, -2843],
      [-121, -4705],
    ],
    [
      [270034, 265079],
      [-575, 985],
    ],
    [
      [269459, 266064],
      [-144, 55],
    ],
    [
      [238301, 271119],
      [215, -818],
      [-12, -1664],
      [522, -3202],
    ],
    [
      [239026, 265435],
      [-240, -345],
      [-1, -1274],
    ],
    [
      [238785, 263816],
      [-1220, -19],
    ],
    [
      [237565, 263797],
      [-382, 2525],
    ],
    [
      [231503, 266930],
      [-1231, -4027],
    ],
    [
      [230272, 262903],
      [-620, 348],
    ],
    [
      [229652, 263251],
      [-493, 4823],
    ],
    [
      [227156, 270487],
      [-696, -2164],
    ],
    [
      [270590, 271889],
      [127, -3676],
      [608, -623],
    ],
    [
      [271325, 267590],
      [-175, -617],
    ],
    [
      [271150, 266973],
      [-403, -4199],
    ],
    [
      [270747, 262774],
      [-221, 704],
    ],
    [
      [270526, 263478],
      [-177, 1370],
      [-315, 231],
    ],
    [
      [253740, 266513],
      [-294, -3347],
      [251, -2068],
    ],
    [
      [253697, 261098],
      [-1046, -26],
    ],
    [
      [252651, 261072],
      [-26, 3649],
    ],
    [
      [252625, 264721],
      [118, 6214],
    ],
    [
      [269147, 265932],
      [-285, -745],
    ],
    [
      [268862, 265187],
      [-233, 1642],
      [-442, 1367],
      [-111, 1019],
    ],
    [
      [261159, 269427],
      [-50, -2453],
      [-440, -327],
      [-199, -2179],
    ],
    [
      [260470, 264468],
      [-372, 2],
      [4, 1617],
      [-263, 0],
      [-26, 1492],
      [-288, 68],
    ],
    [
      [266180, 268901],
      [-4, -1686],
    ],
    [
      [266176, 267215],
      [-979, 52],
    ],
    [
      [265197, 267267],
      [-144, 1878],
    ],
    [
      [265053, 269145],
      [-41, 737],
    ],
    [
      [271272, 269547],
      [173, -1188],
    ],
    [
      [271445, 268359],
      [-120, -769],
    ],
    [
      [243108, 266166],
      [-323, -822],
    ],
    [
      [242785, 265344],
      [-856, 1],
    ],
    [
      [241929, 265345],
      [1, 4072],
    ],
    [
      [257127, 265993],
      [-143, -9],
      [6, -2440],
    ],
    [
      [256990, 263544],
      [-1656, -27],
    ],
    [
      [255334, 263517],
      [-331, -38],
      [-133, 3057],
    ],
    [
      [229652, 263251],
      [-593, -1974],
    ],
    [
      [229059, 261277],
      [-293, 2871],
      [-669, -2188],
    ],
    [
      [228097, 261960],
      [2, 1607],
      [-453, 1892],
      [173, 815],
      [-272, 611],
      [-4, 3041],
    ],
    [
      [225503, 265161],
      [144, -1412],
      [-855, -2935],
    ],
    [
      [224792, 260814],
      [-82, 738],
      [-489, -431],
    ],
    [
      [224221, 261121],
      [-713, 7029],
    ],
    [
      [245438, 269010],
      [61, -70],
    ],
    [
      [245502, 268825],
      [-127, -1815],
      [-313, -1032],
      [-168, -1704],
    ],
    [
      [244894, 264274],
      [-270, -484],
      [77, -1752],
    ],
    [
      [244701, 262038],
      [-172, -287],
    ],
    [
      [244529, 261751],
      [-462, 332],
    ],
    [
      [244067, 262083],
      [-85, 2428],
    ],
    [
      [272356, 268482],
      [444, -3810],
    ],
    [
      [272800, 264672],
      [248, -708],
      [-50, -2336],
      [-442, 1524],
    ],
    [
      [272556, 263152],
      [-244, 2510],
      [-518, 506],
      [-349, 2191],
    ],
    [
      [239493, 269460],
      [179, -3272],
      [-322, 2],
      [-3, -1288],
      [-321, 533],
    ],
    [
      [273103, 263918],
      [241, -620],
      [-261, -1764],
      [-124, 1738],
      [144, 646],
    ],
    [
      [273429, 267956],
      [229, -1123],
      [-211, -968],
      [-18, 2091],
    ],
    [
      [273121, 268886],
      [209, -711],
      [80, -2196],
      [219, -966],
      [-158, -953],
      [-438, 1415],
      [-233, -803],
    ],
    [
      [263887, 269193],
      [-34, -3123],
    ],
    [
      [263853, 266070],
      [-27, -877],
      [-408, 18],
    ],
    [
      [263418, 265211],
      [-156, 2],
    ],
    [
      [263262, 265213],
      [18, 5821],
    ],
    [
      [263262, 265213],
      [-700, 78],
    ],
    [
      [262562, 265291],
      [-449, 1252],
    ],
    [
      [262113, 266543],
      [34, 1328],
    ],
    [
      [265197, 267267],
      [107, -2252],
    ],
    [
      [265304, 265015],
      [-1157, 121],
      [0, -794],
    ],
    [
      [264147, 264342],
      [-294, 1728],
    ],
    [
      [252625, 264721],
      [-414, -683],
      [-803, -568],
    ],
    [
      [251408, 263470],
      [-10, 7401],
    ],
    [
      [246371, 267597],
      [-50, -4882],
    ],
    [
      [246321, 262715],
      [-773, 1410],
      [-654, 149],
    ],
    [
      [251408, 263470],
      [-93, -39],
    ],
    [
      [251315, 263431],
      [-1039, -418],
    ],
    [
      [250276, 263013],
      [-17, 7844],
    ],
    [
      [250276, 263013],
      [-235, -98],
    ],
    [
      [250041, 262915],
      [-702, -302],
    ],
    [
      [249339, 262613],
      [-25, 4964],
      [-190, -4],
    ],
    [
      [228097, 261960],
      [-913, -2938],
    ],
    [
      [227184, 259022],
      [-236, 2295],
      [-214, -718],
    ],
    [
      [226734, 260599],
      [-666, 6431],
    ],
    [
      [268862, 265187],
      [-531, -2011],
    ],
    [
      [268331, 263176],
      [-444, -556],
    ],
    [
      [267887, 262620],
      [-507, 1245],
    ],
    [
      [267380, 263865],
      [-78, 979],
    ],
    [
      [241929, 265345],
      [-140, -2434],
    ],
    [
      [241789, 262911],
      [-714, 10],
      [-2, -1631],
      [-985, -12],
    ],
    [
      [240088, 261278],
      [64, 2739],
      [215, 1718],
      [-183, 3716],
    ],
    [
      [240088, 261278],
      [187, -1588],
      [292, -495],
      [230, -1479],
    ],
    [
      [240797, 257716],
      [-541, -2780],
      [-190, -403],
    ],
    [
      [240066, 254533],
      [-710, 332],
    ],
    [
      [239356, 254865],
      [-1, 3236],
      [-281, -4],
      [-5, 3280],
      [-287, 5],
      [3, 2434],
    ],
    [
      [262113, 266543],
      [-202, -2490],
      [-1, -1443],
    ],
    [
      [261910, 262610],
      [38, -331],
    ],
    [
      [261948, 262279],
      [-247, -1122],
      [-557, 78],
      [-1, -1623],
    ],
    [
      [261143, 259612],
      [-925, -26],
    ],
    [
      [260218, 259586],
      [89, 792],
    ],
    [
      [260307, 260378],
      [163, 4090],
    ],
    [
      [234617, 263822],
      [-104, -1184],
      [69, -2704],
      [234, -2044],
    ],
    [
      [234816, 257890],
      [-383, -1877],
    ],
    [
      [234433, 256013],
      [-270, 786],
      [-111, 1767],
      [-372, 551],
    ],
    [
      [233680, 259117],
      [-7, 1203],
      [-351, 1944],
      [-118, 1559],
      [44, 4447],
    ],
    [
      [267380, 263865],
      [-842, -11],
    ],
    [
      [266538, 263854],
      [-369, 126],
    ],
    [
      [266169, 263980],
      [7, 3235],
    ],
    [
      [273082, 261033],
      [-133, -2581],
      [-38, 2028],
      [171, 553],
    ],
    [
      [272467, 260258],
      [-390, 845],
    ],
    [
      [272077, 261103],
      [-418, 1230],
      [-343, 2022],
      [41, 1842],
      [-207, 776],
    ],
    [
      [272556, 263152],
      [364, -1898],
      [-453, -996],
    ],
    [
      [218222, 268309],
      [-10, -7269],
    ],
    [
      [218212, 261040],
      [-112, -2492],
      [-1121, -109],
    ],
    [
      [216979, 258439],
      [2, 1757],
    ],
    [
      [216981, 260196],
      [7, 8123],
    ],
    [
      [216981, 260196],
      [-1417, 10],
    ],
    [
      [215564, 260206],
      [-1, 8109],
    ],
    [
      [215564, 260206],
      [-1425, -1],
    ],
    [
      [214139, 260205],
      [1, 8112],
    ],
    [
      [214139, 260205],
      [-87, 1],
    ],
    [
      [214052, 260206],
      [-1250, 7],
    ],
    [
      [212802, 260213],
      [-89, 1],
    ],
    [
      [212713, 260214],
      [0, 8080],
    ],
    [
      [212713, 260214],
      [-1472, -6],
    ],
    [
      [211241, 260208],
      [2, 6496],
    ],
    [
      [219856, 268230],
      [-1, -7250],
    ],
    [
      [219855, 260980],
      [-1643, 60],
    ],
    [
      [233680, 259117],
      [-1054, -950],
      [-246, -706],
    ],
    [
      [232380, 257461],
      [15, 2779],
      [-147, -648],
    ],
    [
      [232248, 259592],
      [-249, 2566],
      [-298, 601],
      [38, 1653],
      [-230, 2395],
    ],
    [
      [222752, 268172],
      [-19, -11396],
    ],
    [
      [222733, 256776],
      [-311, -1069],
      [-354, 1218],
      [-198, -944],
      [-249, 1262],
    ],
    [
      [221621, 257243],
      [-334, 1575],
    ],
    [
      [221287, 258818],
      [20, 9406],
    ],
    [
      [221287, 258818],
      [-1085, 65],
    ],
    [
      [220202, 258883],
      [-345, 20],
      [-2, 2077],
    ],
    [
      [224221, 261121],
      [-256, -235],
      [-55, -1438],
      [-590, -2354],
    ],
    [
      [223320, 257094],
      [-275, -434],
    ],
    [
      [223045, 256660],
      [-312, 116],
    ],
    [
      [260218, 259586],
      [-114, -1],
    ],
    [
      [260104, 259585],
      [-994, -4],
    ],
    [
      [259110, 259581],
      [-7, 3224],
      [-142, -9],
      [23, 3277],
    ],
    [
      [259110, 259581],
      [-135, -3310],
    ],
    [
      [258975, 256271],
      [-230, 291],
      [-9, 1358],
      [-330, -1425],
      [-5, 1399],
      [-275, -34],
    ],
    [
      [258126, 257860],
      [-1, 2419],
      [142, 6],
      [1, 5754],
    ],
    [
      [247731, 267623],
      [274, -3564],
      [25, -1966],
    ],
    [
      [248030, 262093],
      [-341, -656],
    ],
    [
      [247689, 261437],
      [-106, -329],
      [-1263, -24],
    ],
    [
      [246320, 261084],
      [1, 1631],
    ],
    [
      [249339, 262613],
      [-284, -111],
    ],
    [
      [249055, 262502],
      [-612, -243],
    ],
    [
      [248443, 262259],
      [-413, -166],
    ],
    [
      [266169, 263980],
      [-1, -929],
      [-528, -12],
    ],
    [
      [265640, 263039],
      [-382, 824],
    ],
    [
      [265258, 263863],
      [46, 1152],
    ],
    [
      [225328, 255832],
      [-536, 4982],
    ],
    [
      [226734, 260599],
      [-1152, -3887],
    ],
    [
      [225582, 256712],
      [-254, -880],
    ],
    [
      [272077, 261103],
      [-207, -2290],
      [-271, -705],
    ],
    [
      [271599, 258108],
      [-852, 4666],
    ],
    [
      [232248, 259592],
      [-1251, -3816],
    ],
    [
      [230997, 255776],
      [-725, 7127],
    ],
    [
      [209299, 266698],
      [-218, -16661],
    ],
    [
      [209081, 250037],
      [-2269, -8219],
    ],
    [
      [206812, 241818],
      [27, 3208],
      [-31, 21680],
    ],
    [
      [206812, 241818],
      [-175, -639],
    ],
    [
      [206637, 241179],
      [-655, 3287],
      [-500, 980],
      [-452, 2433],
      [-137, 1784],
      [-467, 1522],
      [-266, 2270],
      [-357, 1834],
    ],
    [
      [203803, 255289],
      [-2, 11451],
    ],
    [
      [203803, 255289],
      [-618, 1714],
      [-454, 4718],
      [-407, 953],
    ],
    [
      [211241, 260208],
      [-789, 7],
    ],
    [
      [210452, 260215],
      [-226, 1786],
      [-294, 419],
      [-120, 2210],
      [-128, -359],
      [-262, 2428],
    ],
    [
      [210452, 260215],
      [261, -433],
      [176, -3774],
      [534, 31],
      [187, -1030],
      [512, -17],
    ],
    [
      [212122, 254992],
      [-1599, -11259],
    ],
    [
      [210523, 243733],
      [-1442, 6304],
    ],
    [
      [262562, 265291],
      [-9, -2748],
      [-135, 18],
    ],
    [
      [262418, 262561],
      [-508, 49],
    ],
    [
      [255334, 263517],
      [-41, -2445],
      [-142, -14],
      [4, -2674],
      [-156, -742],
      [132, -1478],
      [-365, -604],
      [-167, -1944],
    ],
    [
      [254599, 253616],
      [-506, -1944],
    ],
    [
      [254093, 251672],
      [-63, 1221],
      [229, 2446],
      [-147, -275],
      [95, 2173],
      [-484, 2051],
      [-26, 1810],
    ],
    [
      [237565, 263797],
      [234, -2648],
      [-111, -2155],
    ],
    [
      [237688, 258994],
      [-417, -314],
    ],
    [
      [237271, 258680],
      [-297, 954],
      [-857, 614],
    ],
    [
      [236117, 260248],
      [-149, 3550],
    ],
    [
      [244067, 262083],
      [-416, -314],
      [-36, -1776],
      [-276, -171],
      [-2, -3574],
      [-156, -1569],
      [81, -1641],
    ],
    [
      [243262, 253038],
      [-258, 531],
      [-178, -1296],
      [-48, 1873],
    ],
    [
      [242778, 254146],
      [7, 11198],
    ],
    [
      [269459, 266064],
      [0, -2383],
      [-247, 7],
      [-4, -2375],
    ],
    [
      [269208, 261313],
      [-295, -711],
    ],
    [
      [268913, 260602],
      [-583, -22],
      [1, 2596],
    ],
    [
      [264147, 264342],
      [212, -842],
      [-100, -3841],
    ],
    [
      [264259, 259659],
      [-425, -12],
    ],
    [
      [263834, 259647],
      [-269, 0],
    ],
    [
      [263565, 259647],
      [-156, 2805],
      [9, 2759],
    ],
    [
      [270526, 263478],
      [-236, -1000],
      [1, -5622],
    ],
    [
      [270291, 256856],
      [-262, 1105],
    ],
    [
      [270029, 257961],
      [-821, 3352],
    ],
    [
      [258126, 257860],
      [-564, -27],
    ],
    [
      [257562, 257833],
      [-384, 26],
      [-188, 2000],
      [3, 2240],
    ],
    [
      [256993, 262099],
      [-3, 1445],
    ],
    [
      [263565, 259647],
      [-754, -30],
    ],
    [
      [262811, 259617],
      [-348, -20],
      [-45, 2964],
    ],
    [
      [242778, 254146],
      [-219, 178],
    ],
    [
      [242559, 254324],
      [-305, 2653],
    ],
    [
      [242254, 256977],
      [-195, 277],
      [-241, 2436],
      [-29, 3221],
    ],
    [
      [265258, 263863],
      [-181, -811],
      [-39, -2867],
    ],
    [
      [265038, 260185],
      [-69, -496],
      [-710, -30],
    ],
    [
      [252692, 256194],
      [-1093, -41],
    ],
    [
      [251599, 256153],
      [-284, -4],
    ],
    [
      [251315, 256149],
      [0, 7282],
    ],
    [
      [252651, 261072],
      [41, -4878],
    ],
    [
      [246320, 261084],
      [-2, -1627],
    ],
    [
      [246318, 259457],
      [-1160, -19],
    ],
    [
      [245158, 259438],
      [-408, 1159],
      [-49, 1441],
    ],
    [
      [227703, 254041],
      [-519, 4981],
    ],
    [
      [229059, 261277],
      [364, -3474],
    ],
    [
      [229423, 257803],
      [-1328, -4514],
    ],
    [
      [228095, 253289],
      [-182, -662],
      [-210, 1414],
    ],
    [
      [266538, 263854],
      [73, -1664],
    ],
    [
      [266611, 262190],
      [-131, -3053],
    ],
    [
      [266480, 259137],
      [-414, -482],
    ],
    [
      [266066, 258655],
      [-411, 1022],
      [-15, 3362],
    ],
    [
      [267887, 262620],
      [-7, -2008],
    ],
    [
      [267880, 260612],
      [-912, 138],
      [0, 1415],
      [-357, 25],
    ],
    [
      [266066, 258655],
      [-13, -4419],
    ],
    [
      [266053, 254236],
      [-962, 79],
    ],
    [
      [265091, 254315],
      [4, 2025],
    ],
    [
      [265095, 256340],
      [-57, 3845],
    ],
    [
      [236117, 260248],
      [245, -1198],
      [-44, -6786],
    ],
    [
      [236318, 252264],
      [-314, 1644],
      [-175, 1815],
      [-632, 880],
      [-381, 1287],
    ],
    [
      [239356, 254865],
      [-430, -6],
      [-146, -1627],
      [-240, 7],
      [-65, -1705],
    ],
    [
      [238475, 251534],
      [-133, -174],
    ],
    [
      [238342, 251360],
      [-234, 2339],
      [-88, 3954],
      [-332, 1341],
    ],
    [
      [271599, 258108],
      [109, -1595],
    ],
    [
      [271708, 256513],
      [-299, -2289],
    ],
    [
      [271409, 254224],
      [-861, 806],
    ],
    [
      [270548, 255030],
      [-29, 1447],
      [-228, 379],
    ],
    [
      [256993, 262099],
      [-405, -645],
      [-234, -1413],
      [-90, -2314],
      [-724, -4800],
    ],
    [
      [255540, 252927],
      [-525, -296],
    ],
    [
      [255015, 252631],
      [-416, 985],
    ],
    [
      [251315, 256149],
      [-565, 11],
    ],
    [
      [250750, 256160],
      [-705, 0],
    ],
    [
      [250045, 256160],
      [-4, 6755],
    ],
    [
      [229993, 252193],
      [-570, 5610],
    ],
    [
      [230997, 255776],
      [-230, -1047],
    ],
    [
      [230767, 254729],
      [-774, -2536],
    ],
    [
      [268913, 260602],
      [84, -3783],
    ],
    [
      [268997, 256819],
      [-88, -1964],
    ],
    [
      [268909, 254855],
      [-525, 10],
      [-61, 691],
      [-839, 350],
    ],
    [
      [267484, 255906],
      [-15, 966],
    ],
    [
      [267469, 256872],
      [411, 3740],
    ],
    [
      [242254, 256977],
      [-1212, -1625],
      [-245, 2364],
    ],
    [
      [250045, 256160],
      [-145, 0],
    ],
    [
      [249900, 256160],
      [-376, -7],
    ],
    [
      [249524, 256153],
      [-46, 1959],
      [-425, 1320],
      [2, 3070],
    ],
    [
      [262811, 259617],
      [-6, -2207],
    ],
    [
      [262805, 257410],
      [-584, -281],
      [-45, 579],
    ],
    [
      [262176, 257708],
      [-228, 4571],
    ],
    [
      [262176, 257708],
      [-117, -3882],
    ],
    [
      [262059, 253826],
      [-920, 118],
    ],
    [
      [261139, 253944],
      [4, 5668],
    ],
    [
      [249524, 256153],
      [-188, 2],
    ],
    [
      [249336, 256155],
      [-474, -1],
      [2, -814],
      [-378, 9],
    ],
    [
      [248486, 255349],
      [-43, 6910],
    ],
    [
      [267469, 256872],
      [-538, 69],
    ],
    [
      [266931, 256941],
      [-451, 2196],
    ],
    [
      [244529, 261751],
      [-62, -2123],
      [-258, 460],
      [229, -1495],
      [-276, -599],
      [18, -3120],
      [-205, 750],
      [174, -2365],
      [-387, -521],
      [182, -1100],
    ],
    [
      [243944, 251638],
      [-102, -1383],
      [173, -931],
      [-202, -1256],
    ],
    [
      [243813, 248068],
      [-73, -540],
    ],
    [
      [243740, 247528],
      [12, 447],
    ],
    [
      [243752, 247975],
      [-182, 929],
    ],
    [
      [243570, 248904],
      [129, 2677],
      [-437, 1457],
    ],
    [
      [248486, 255349],
      [-225, -1013],
    ],
    [
      [248261, 254336],
      [-569, 265],
    ],
    [
      [247692, 254601],
      [-3, 6836],
    ],
    [
      [257562, 257833],
      [96, -2876],
      [-100, -437],
      [8, -2858],
    ],
    [
      [257566, 251662],
      [-179, 1283],
      [-1847, -18],
    ],
    [
      [245158, 259438],
      [-12, -4904],
    ],
    [
      [245146, 254534],
      [-217, 450],
      [-649, -954],
      [-49, -1688],
      [-287, -704],
    ],
    [
      [191369, 254272],
      [-1713, -9],
      [-813, 1749],
    ],
    [
      [225328, 255832],
      [-812, -3459],
    ],
    [
      [224516, 252373],
      [-177, 743],
      [-194, 2530],
      [-443, 141],
      [-382, 1307],
    ],
    [
      [247692, 254601],
      [-45, 0],
    ],
    [
      [247647, 254601],
      [-802, -13],
    ],
    [
      [246845, 254588],
      [-237, -7],
    ],
    [
      [246608, 254581],
      [0, 4882],
      [-290, -6],
    ],
    [
      [270029, 257961],
      [-328, -1873],
      [-203, -243],
    ],
    [
      [269498, 255845],
      [-501, 974],
    ],
    [
      [226342, 249372],
      [-760, 7340],
    ],
    [
      [227703, 254041],
      [-1361, -4669],
    ],
    [
      [220202, 258883],
      [-12, -9165],
    ],
    [
      [220190, 249718],
      [-1597, -27],
    ],
    [
      [218593, 249691],
      [-12, 8144],
      [-1602, 88],
    ],
    [
      [216979, 257923],
      [0, 516],
    ],
    [
      [254093, 251672],
      [-73, -563],
    ],
    [
      [254020, 251109],
      [-140, -343],
      [-854, -8],
      [-285, -550],
    ],
    [
      [252741, 250208],
      [-49, 5986],
    ],
    [
      [272895, 258076],
      [-221, -2947],
      [-140, 1836],
      [361, 1111],
    ],
    [
      [272467, 260258],
      [359, -497],
      [69, -955],
      [-372, -536],
      [-14, -3438],
      [142, -738],
      [-530, 300],
    ],
    [
      [272121, 254394],
      [-413, 2119],
    ],
    [
      [232380, 257461],
      [233, -3410],
      [-192, -919],
      [-109, -3298],
    ],
    [
      [232312, 249834],
      [-587, -40],
      [-741, -2203],
    ],
    [
      [230984, 247591],
      [-246, 5282],
      [29, 1856],
    ],
    [
      [215564, 260206],
      [-1, -10641],
    ],
    [
      [215563, 249565],
      [-1463, 119],
    ],
    [
      [214100, 249684],
      [-48, 10522],
    ],
    [
      [214100, 249684],
      [-246, 18],
    ],
    [
      [213854, 249702],
      [-112, 1974],
      [-687, 2489],
      [-255, -616],
    ],
    [
      [212800, 253549],
      [2, 6664],
    ],
    [
      [237271, 258680],
      [3, -1922],
      [-169, -1045],
      [14, -5133],
    ],
    [
      [237119, 250580],
      [-252, -652],
    ],
    [
      [236867, 249928],
      [-549, 2336],
    ],
    [
      [216979, 257923],
      [-20, -8366],
    ],
    [
      [216959, 249557],
      [-1396, 8],
    ],
    [
      [212800, 253549],
      [-158, -489],
      [-520, 1932],
    ],
    [
      [265095, 256340],
      [-399, -67],
    ],
    [
      [264696, 256273],
      [-805, -61],
    ],
    [
      [263891, 256212],
      [-57, 3435],
    ],
    [
      [263891, 256212],
      [-578, -54],
    ],
    [
      [263313, 256158],
      [-423, 46],
      [-85, 1206],
    ],
    [
      [261139, 253944],
      [-190, -1282],
      [-485, 462],
      [-142, -1410],
    ],
    [
      [260322, 251714],
      [-224, 22],
    ],
    [
      [260098, 251736],
      [6, 7849],
    ],
    [
      [260098, 251736],
      [-1121, -78],
    ],
    [
      [258977, 251658],
      [-2, 4613],
    ],
    [
      [246608, 254581],
      [-1058, -7],
      [-230, -519],
    ],
    [
      [245320, 254055],
      [-174, 479],
    ],
    [
      [238342, 251360],
      [-866, -337],
    ],
    [
      [237476, 251023],
      [-357, -443],
    ],
    [
      [266931, 256941],
      [-267, -2338],
    ],
    [
      [266664, 254603],
      [-217, -428],
    ],
    [
      [266447, 254175],
      [-394, 61],
    ],
    [
      [234433, 256013],
      [126, -728],
    ],
    [
      [234559, 255285],
      [-1328, -6123],
    ],
    [
      [233231, 249162],
      [-512, -2377],
    ],
    [
      [232719, 246785],
      [-160, 1025],
      [33, 1830],
      [-280, 194],
    ],
    [
      [221621, 257243],
      [-4, -7538],
    ],
    [
      [221617, 249705],
      [-1427, 13],
    ],
    [
      [270548, 255030],
      [135, -1217],
      [-559, -1966],
      [-260, 400],
    ],
    [
      [269864, 252247],
      [-282, 1360],
      [-84, 2238],
    ],
    [
      [258977, 251658],
      [18, -3690],
    ],
    [
      [258995, 247968],
      [-561, 3],
    ],
    [
      [258434, 247971],
      [-835, 7],
    ],
    [
      [257599, 247978],
      [-33, 3684],
    ],
    [
      [218593, 249691],
      [-762, -75],
    ],
    [
      [217831, 249616],
      [-872, -59],
    ],
    [
      [236867, 249928],
      [-914, -1225],
    ],
    [
      [235953, 248703],
      [-290, 477],
    ],
    [
      [235663, 249180],
      [-783, 1632],
    ],
    [
      [234880, 250812],
      [-186, 3541],
      [-135, 932],
    ],
    [
      [263313, 256158],
      [-12, -3257],
    ],
    [
      [263301, 252901],
      [-768, -60],
      [-14, -3409],
    ],
    [
      [262519, 249432],
      [-296, 57],
    ],
    [
      [262223, 249489],
      [-201, 1664],
      [37, 2673],
    ],
    [
      [229345, 250051],
      [-671, -2227],
    ],
    [
      [228674, 247824],
      [-579, 5465],
    ],
    [
      [229993, 252193],
      [-648, -2142],
    ],
    [
      [242559, 254324],
      [-409, -313],
      [69, -6634],
      [-201, 53],
    ],
    [
      [242018, 247430],
      [-298, 679],
      [-278, -1715],
      [-307, -248],
    ],
    [
      [241135, 246146],
      [-630, -114],
    ],
    [
      [240505, 246032],
      [-17, 6745],
      [-422, 1756],
    ],
    [
      [223045, 256660],
      [-5, -9678],
    ],
    [
      [223040, 246982],
      [-1093, -7],
    ],
    [
      [221947, 246975],
      [-331, 2],
      [1, 2728],
    ],
    [
      [224516, 252373],
      [110, -2638],
      [233, -1105],
    ],
    [
      [224859, 248630],
      [-17, -2012],
    ],
    [
      [224842, 246618],
      [-1446, -2],
    ],
    [
      [223396, 246616],
      [-356, 366],
    ],
    [
      [267484, 255906],
      [289, -2603],
      [-29, -1804],
    ],
    [
      [267744, 251499],
      [-329, -678],
      [-91, -2266],
    ],
    [
      [267324, 248555],
      [-270, 35],
    ],
    [
      [267054, 248590],
      [-114, 4038],
      [-276, 1975],
    ],
    [
      [269864, 252247],
      [216, -986],
      [1, -1604],
      [213, -1377],
    ],
    [
      [270294, 248280],
      [-791, 53],
      [-15, -4066],
      [756, -202],
      [-181, -4018],
    ],
    [
      [270063, 240047],
      [-569, 247],
    ],
    [
      [269494, 240294],
      [-47, 4435],
      [-166, -9],
      [13, 2675],
      [-283, 1029],
      [-221, 3072],
    ],
    [
      [268790, 251496],
      [-78, 1758],
      [187, -78],
    ],
    [
      [268899, 253176],
      [10, 1679],
    ],
    [
      [226342, 249372],
      [-12, -642],
    ],
    [
      [226330, 248730],
      [-1471, -100],
    ],
    [
      [272518, 253791],
      [20, 18],
    ],
    [
      [272538, 253809],
      [99, -1699],
      [-281, -1544],
      [-109, 1279],
      [124, 2060],
      [147, -114],
    ],
    [
      [272121, 254394],
      [4, -51],
    ],
    [
      [272125, 254343],
      [81, -132],
    ],
    [
      [272206, 254211],
      [155, -845],
      [-204, -1996],
      [71, -2600],
      [-325, 802],
    ],
    [
      [271903, 249572],
      [-591, 1666],
    ],
    [
      [271312, 251238],
      [97, 2986],
    ],
    [
      [264696, 256273],
      [-167, -1561],
      [-391, -949],
      [-69, -1201],
      [-328, -1573],
      [-68, -1447],
    ],
    [
      [263673, 249542],
      [-96, 10],
    ],
    [
      [263577, 249552],
      [15, 3293],
      [-291, 56],
    ],
    [
      [265091, 254315],
      [-12, -4796],
    ],
    [
      [265079, 249519],
      [-315, 12],
    ],
    [
      [264764, 249531],
      [-724, 18],
    ],
    [
      [264040, 249549],
      [-367, -7],
    ],
    [
      [252741, 250208],
      [18, -2159],
    ],
    [
      [252759, 248049],
      [-1139, -6],
    ],
    [
      [251620, 248043],
      [-21, 8110],
    ],
    [
      [250750, 256160],
      [24, -9758],
    ],
    [
      [250774, 246402],
      [-565, 1],
    ],
    [
      [250209, 246403],
      [-23, 1870],
    ],
    [
      [250186, 248273],
      [3, 6261],
      [-287, -6],
      [-2, 1632],
    ],
    [
      [250186, 248273],
      [-850, -146],
    ],
    [
      [249336, 248127],
      [0, 8028],
    ],
    [
      [251620, 248043],
      [-141, -1624],
    ],
    [
      [251479, 246419],
      [-705, -17],
    ],
    [
      [249336, 248127],
      [-206, -4],
    ],
    [
      [249130, 248123],
      [-300, -5],
    ],
    [
      [248830, 248118],
      [0, 1358],
      [-237, -6],
      [-309, 1882],
      [-23, 2984],
    ],
    [
      [268790, 251496],
      [-833, 9],
    ],
    [
      [267957, 251505],
      [-213, -6],
    ],
    [
      [234880, 250812],
      [-269, -1952],
      [-727, -4046],
    ],
    [
      [233884, 244814],
      [-145, 1529],
      [-209, -878],
    ],
    [
      [233530, 245465],
      [-193, -35],
      [-106, 3732],
    ],
    [
      [213854, 249702],
      [556, -1560],
      [549, -266],
      [329, -1468],
      [180, -3869],
      [116, -913],
    ],
    [
      [215584, 241626],
      [-1029, 50],
      [-1, -1090],
      [-571, 24],
      [0, -5857],
      [-623, -16],
      [0, -4293],
    ],
    [
      [213360, 230444],
      [-2433, 11417],
    ],
    [
      [210927, 241861],
      [-404, 1872],
    ],
    [
      [271312, 251238],
      [-472, -2258],
    ],
    [
      [270840, 248980],
      [-349, 503],
      [-197, -1203],
    ],
    [
      [245320, 254055],
      [101, -733],
      [-2, -5263],
    ],
    [
      [245419, 248059],
      [-325, 3],
    ],
    [
      [245094, 248062],
      [-1281, 6],
    ],
    [
      [240505, 246032],
      [-431, -220],
    ],
    [
      [240074, 245812],
      [-1234, 93],
      [-382, -268],
    ],
    [
      [238458, 245637],
      [138, 2982],
      [-121, 2915],
    ],
    [
      [230984, 247591],
      [-297, -1133],
      [-306, -3132],
      [-307, -919],
    ],
    [
      [230074, 242407],
      [-150, 660],
    ],
    [
      [229924, 243067],
      [-83, 1790],
      [-230, 1424],
      [-92, 2592],
      [-174, 1178],
    ],
    [
      [267054, 248590],
      [-504, 85],
    ],
    [
      [266550, 248675],
      [-89, 305],
    ],
    [
      [266461, 248980],
      [-188, 553],
    ],
    [
      [266273, 249533],
      [181, 2384],
      [-7, 2258],
    ],
    [
      [246845, 254588],
      [1, -6514],
    ],
    [
      [246846, 248074],
      [-55, 0],
    ],
    [
      [246791, 248074],
      [-720, -7],
    ],
    [
      [246071, 248067],
      [-652, -8],
    ],
    [
      [247647, 254601],
      [2, -6508],
    ],
    [
      [247649, 248093],
      [-244, -6],
    ],
    [
      [247405, 248087],
      [-559, -13],
    ],
    [
      [248830, 248118],
      [-1181, -25],
    ],
    [
      [243570, 248904],
      [-75, -474],
    ],
    [
      [243495, 248430],
      [-154, -909],
      [-26, -2268],
    ],
    [
      [243315, 245253],
      [-1107, 8],
    ],
    [
      [242208, 245261],
      [-190, 2169],
    ],
    [
      [266273, 249533],
      [3, -822],
      [-452, 71],
    ],
    [
      [265824, 248782],
      [-746, 72],
      [1, 665],
    ],
    [
      [228674, 247824],
      [-526, -1799],
      [-157, -2556],
    ],
    [
      [227991, 243469],
      [-863, 2199],
      [-567, 665],
    ],
    [
      [226561, 246333],
      [-231, 2397],
    ],
    [
      [255015, 252631],
      [47, -4598],
    ],
    [
      [255062, 248033],
      [-100, -2449],
      [292, -2293],
      [343, -1258],
      [-72, -3553],
    ],
    [
      [255525, 238480],
      [118, -709],
      [-472, -3244],
      [-776, -834],
      [-363, 138],
      [617, 1003],
      [-445, 2269],
      [13, 2457],
      [-99, 3216],
      [-227, 556],
    ],
    [
      [253891, 243332],
      [-3, 424],
    ],
    [
      [253888, 243756],
      [51, 358],
    ],
    [
      [253939, 244114],
      [171, 4892],
      [-90, 2103],
    ],
    [
      [262223, 249489],
      [73, -1396],
    ],
    [
      [262296, 248093],
      [-1355, -67],
    ],
    [
      [260941, 248026],
      [8, 3775],
      [-627, -87],
    ],
    [
      [257599, 247978],
      [-271, 46],
    ],
    [
      [257328, 248024],
      [-1052, 37],
    ],
    [
      [256276, 248061],
      [-1214, -28],
    ],
    [
      [263577, 249552],
      [-524, -184],
    ],
    [
      [263053, 249368],
      [-534, 64],
    ],
    [
      [260941, 248026],
      [-26, -3],
    ],
    [
      [260915, 248023],
      [-1496, -68],
    ],
    [
      [259419, 247955],
      [-424, 13],
    ],
    [
      [238458, 245637],
      [-203, -3553],
      [-301, -2557],
      [116, -1801],
      [-122, -774],
    ],
    [
      [237948, 236952],
      [-67, -1097],
      [156, -1846],
    ],
    [
      [238037, 234009],
      [-534, -28],
    ],
    [
      [237503, 233981],
      [98, 13117],
      [-125, 3925],
    ],
    [
      [269494, 240294],
      [-114, 45],
    ],
    [
      [269380, 240339],
      [-346, 137],
    ],
    [
      [269034, 240476],
      [-18, 2136],
      [-493, 492],
      [-222, 1908],
      [-346, 636],
    ],
    [
      [267955, 245648],
      [2, 5857],
    ],
    [
      [267955, 245648],
      [-134, -370],
    ],
    [
      [267821, 245278],
      [-66, 1821],
      [-380, 52],
      [-51, 1404],
    ],
    [
      [253939, 244114],
      [-283, -5327],
      [-10, -2293],
      [-805, 174],
    ],
    [
      [252841, 236668],
      [-47, 6490],
    ],
    [
      [252794, 243158],
      [-35, 4891],
    ],
    [
      [272307, 246842],
      [-203, -3711],
      [-81, 2742],
      [284, 969],
    ],
    [
      [271903, 249572],
      [306, -2478],
      [-160, -4072],
      [-292, -203],
    ],
    [
      [271757, 242819],
      [-831, 2065],
    ],
    [
      [270926, 244884],
      [0, 3895],
      [-86, 201],
    ],
    [
      [237503, 233981],
      [-603, -19],
    ],
    [
      [236900, 233962],
      [96, 1937],
      [-93, 1644],
      [125, 1714],
    ],
    [
      [237028, 239257],
      [57, 2122],
      [-200, 796],
      [-362, 6258],
      [-570, 270],
    ],
    [
      [235663, 249180],
      [-268, -877],
      [313, -9029],
    ],
    [
      [235708, 239274],
      [21, -670],
      [-542, -17],
    ],
    [
      [235187, 238587],
      [-329, 77],
    ],
    [
      [234858, 238664],
      [56, 882],
      [-384, 658],
      [-240, 1922],
      [-168, -357],
      [-46, 2247],
      [-192, 798],
    ],
    [
      [229924, 243067],
      [-953, -3232],
    ],
    [
      [228971, 239835],
      [-534, -1857],
    ],
    [
      [228437, 237978],
      [-325, 5186],
      [-121, 305],
    ],
    [
      [209922, 237143],
      [-279, -6],
      [-3006, 4042],
    ],
    [
      [210927, 241861],
      [-1005, -4718],
    ],
    [
      [232719, 246785],
      [-682, -1234],
    ],
    [
      [232037, 245551],
      [-850, -766],
    ],
    [
      [231187, 244785],
      [-203, 2806],
    ],
    [
      [221947, 246975],
      [2, -4279],
    ],
    [
      [221949, 242696],
      [-1761, -8],
    ],
    [
      [220188, 242688],
      [2, 7030],
    ],
    [
      [220188, 242688],
      [-2353, -81],
    ],
    [
      [217835, 242607],
      [-4, 7009],
    ],
    [
      [217835, 242607],
      [0, -7787],
    ],
    [
      [217835, 234820],
      [-2223, 6],
    ],
    [
      [215612, 234826],
      [279, 1284],
      [-229, 1216],
      [263, 2565],
      [-18, 1122],
      [-323, 613],
    ],
    [
      [264040, 249549],
      [-12, -7241],
    ],
    [
      [264028, 242308],
      [-1345, 403],
    ],
    [
      [262683, 242711],
      [300, 3247],
      [70, 3410],
    ],
    [
      [264764, 249531],
      [114, -3092],
      [-22, -4394],
    ],
    [
      [264856, 242045],
      [-554, 176],
    ],
    [
      [264302, 242221],
      [-274, 87],
    ],
    [
      [266550, 248675],
      [98, -2368],
      [249, -1172],
      [-274, -444],
      [-99, -1222],
      [356, -2141],
    ],
    [
      [266880, 241328],
      [-709, 260],
    ],
    [
      [266171, 241588],
      [-368, 134],
    ],
    [
      [265803, 241722],
      [21, 7060],
    ],
    [
      [265803, 241722],
      [-735, 254],
    ],
    [
      [265068, 241976],
      [-212, 69],
    ],
    [
      [262683, 242711],
      [-3, 0],
    ],
    [
      [262680, 242711],
      [-384, 5382],
    ],
    [
      [270926, 244884],
      [-366, -1369],
      [-37, -1852],
      [123, -1709],
      [-124, -3745],
    ],
    [
      [270522, 236209],
      [-335, -64],
      [-107, 1022],
      [-17, 2880],
    ],
    [
      [237028, 239257],
      [-1320, 17],
    ],
    [
      [233530, 245465],
      [-89, -6610],
    ],
    [
      [233441, 238855],
      [-666, 84],
      [-646, 2258],
    ],
    [
      [232129, 241197],
      [-92, 4354],
    ],
    [
      [244740, 241601],
      [-76, 124],
    ],
    [
      [244664, 241725],
      [-138, 1864],
      [-596, -160],
      [213, 1883],
      [-298, -206],
      [-105, 2422],
    ],
    [
      [245094, 248062],
      [-354, -6461],
    ],
    [
      [226561, 246333],
      [-375, -2252],
      [-241, -2998],
    ],
    [
      [225945, 241083],
      [-212, -3685],
    ],
    [
      [225733, 237398],
      [-624, 1108],
    ],
    [
      [225109, 238506],
      [-245, 3503],
      [173, 1353],
      [-5, 1659],
      [-190, 1597],
    ],
    [
      [267821, 245278],
      [-326, -1352],
      [-1, -2846],
    ],
    [
      [267494, 241080],
      [-481, 193],
    ],
    [
      [267013, 241273],
      [-88, 37],
    ],
    [
      [266925, 241310],
      [-45, 18],
    ],
    [
      [244664, 241725],
      [-233, -2739],
      [-196, -268],
    ],
    [
      [244235, 238718],
      [-602, 5],
    ],
    [
      [243633, 238723],
      [-154, 6],
    ],
    [
      [243479, 238729],
      [63, 3797],
      [-227, 2727],
    ],
    [
      [243752, 247975],
      [-257, 455],
    ],
    [
      [250209, 246403],
      [-2, -4881],
    ],
    [
      [250207, 241522],
      [-565, 12],
      [0, -2449],
      [-406, -1071],
    ],
    [
      [249236, 238014],
      [-349, 2359],
      [-75, 1498],
    ],
    [
      [248812, 241871],
      [318, 6252],
    ],
    [
      [248812, 241871],
      [-426, -22],
      [-726, 870],
    ],
    [
      [247660, 242719],
      [-255, 5368],
    ],
    [
      [242208, 245261],
      [112, -1501],
      [-109, -3720],
      [-662, -628],
      [-123, -1003],
    ],
    [
      [241426, 238409],
      [-387, 12],
    ],
    [
      [241039, 238421],
      [5, 125],
    ],
    [
      [241044, 238546],
      [92, 1719],
      [-1, 5881],
    ],
    [
      [262680, 242711],
      [-189, -1959],
    ],
    [
      [262491, 240752],
      [-656, 31],
      [-11, -813],
      [-591, 47],
    ],
    [
      [261233, 240017],
      [-143, 13],
    ],
    [
      [261090, 240030],
      [8, 4056],
      [-466, 837],
    ],
    [
      [260632, 244923],
      [283, 3100],
    ],
    [
      [247660, 242719],
      [34, -9078],
    ],
    [
      [247694, 233641],
      [-96, 120],
    ],
    [
      [247598, 233761],
      [-55, 1170],
      [-290, -153],
    ],
    [
      [247253, 234778],
      [-284, 1435],
      [-176, 2313],
      [-2, 3039],
    ],
    [
      [246791, 241565],
      [0, 6509],
    ],
    [
      [256232, 239600],
      [16, -2077],
      [-262, -1642],
      [-543, -650],
      [107, 1725],
      [233, 527],
      [-258, 997],
    ],
    [
      [256276, 248061],
      [-334, -1385],
      [-81, -3536],
      [371, -3540],
    ],
    [
      [257328, 248024],
      [-39, -10998],
    ],
    [
      [257289, 237026],
      [-1052, -1037],
      [665, 1910],
      [-225, 961],
      [-136, -1053],
      [-250, 2451],
      [-59, -658],
    ],
    [
      [252794, 243158],
      [-1314, 0],
    ],
    [
      [251480, 243158],
      [-1, 3261],
    ],
    [
      [246791, 241565],
      [-956, -12],
    ],
    [
      [245835, 241553],
      [167, 1301],
    ],
    [
      [246002, 242854],
      [-21, 4096],
      [90, 1117],
    ],
    [
      [258434, 247971],
      [-17, -10056],
    ],
    [
      [258417, 237915],
      [-166, 1067],
      [-410, -2043],
      [-552, 87],
    ],
    [
      [246002, 242854],
      [-1125, -260],
      [-121, -1038],
    ],
    [
      [244756, 241556],
      [-16, 45],
    ],
    [
      [260632, 244923],
      [-55, -1607],
      [-562, 869],
      [-66, -1633],
    ],
    [
      [259949, 242552],
      [-537, 37],
      [7, 5366],
    ],
    [
      [259949, 242552],
      [-196, -1239],
      [99, -2459],
      [-315, -2140],
    ],
    [
      [259537, 236714],
      [-9, -2240],
    ],
    [
      [259528, 234474],
      [-1118, 2046],
    ],
    [
      [258410, 236875],
      [0, -355],
    ],
    [
      [258410, 236875],
      [816, -133],
      [-426, 1780],
      [-383, -607],
    ],
    [
      [231187, 244785],
      [-45, -4378],
      [221, -3089],
      [-139, -1697],
    ],
    [
      [231224, 235621],
      [12, 737],
      [-414, 180],
    ],
    [
      [230822, 236538],
      [-165, 3013],
      [-358, 1438],
      [-320, 391],
      [95, 1027],
    ],
    [
      [223396, 246616],
      [1, -7873],
    ],
    [
      [223397, 238743],
      [-946, 26],
    ],
    [
      [222451, 238769],
      [-503, -4],
      [1, 3931],
    ],
    [
      [225109, 238506],
      [-674, 264],
    ],
    [
      [224435, 238770],
      [-1038, -27],
    ],
    [
      [251480, 243158],
      [0, -1087],
    ],
    [
      [251480, 242071],
      [-990, -2],
      [-283, -547],
    ],
    [
      [228437, 237978],
      [-499, -1008],
    ],
    [
      [227938, 236970],
      [-97, 304],
    ],
    [
      [227841, 237274],
      [-634, 1522],
      [-437, -1264],
      [-263, 726],
      [-303, 2900],
      [-259, -75],
    ],
    [
      [234858, 238664],
      [-878, -2778],
    ],
    [
      [233980, 235886],
      [-396, 1124],
      [-143, 1845],
    ],
    [
      [241044, 238546],
      [-396, 8],
      [-9, -943],
      [-990, -262],
    ],
    [
      [239649, 237349],
      [2, 3245],
      [421, 9],
      [2, 5209],
    ],
    [
      [239649, 237349],
      [0, -382],
    ],
    [
      [239649, 236967],
      [-709, -8],
      [2, 1629],
      [-283, 7],
      [1, -1639],
      [-712, -4],
    ],
    [
      [269034, 240476],
      [-293, 113],
    ],
    [
      [268741, 240589],
      [-1247, 491],
    ],
    [
      [232129, 241197],
      [72, -7162],
    ],
    [
      [232201, 234035],
      [-806, -384],
    ],
    [
      [231395, 233651],
      [-171, 1970],
    ],
    [
      [243479, 238729],
      [216, -1917],
      [-535, 411],
      [-328, -897],
    ],
    [
      [242832, 236326],
      [-172, 207],
      [-257, -1508],
    ],
    [
      [242403, 235025],
      [-94, 2569],
      [-194, 809],
      [-689, 6],
    ],
    [
      [261090, 240030],
      [-142, 0],
      [-5, -2437],
      [-1018, 73],
      [-388, -952],
    ],
    [
      [271757, 242819],
      [498, -308],
      [-34, -3432],
      [-155, 926],
      [-295, -107],
    ],
    [
      [271771, 239898],
      [-322, 203],
      [-925, -5548],
    ],
    [
      [270524, 234553],
      [-2, 1656],
    ],
    [
      [252841, 236668],
      [-233, -1277],
      [-178, 1270],
      [-575, -702],
      [-371, 1453],
    ],
    [
      [251484, 237412],
      [-4, 4659],
    ],
    [
      [230822, 236538],
      [-952, -1549],
    ],
    [
      [229870, 234989],
      [-278, 409],
      [-621, 4437],
    ],
    [
      [245835, 241553],
      [-210, -1460],
      [-15, -2062],
      [278, -2140],
    ],
    [
      [245888, 235891],
      [-360, -439],
    ],
    [
      [245528, 235452],
      [-338, 30],
    ],
    [
      [245190, 235482],
      [-277, 639],
      [124, 2784],
      [-183, -36],
      [-98, 2687],
    ],
    [
      [249236, 238014],
      [203, -4319],
      [261, -907],
    ],
    [
      [249700, 232788],
      [-283, -440],
    ],
    [
      [249417, 232348],
      [-471, -68],
      [-254, 869],
      [-304, -894],
      [-309, 779],
    ],
    [
      [248079, 233034],
      [-385, 607],
    ],
    [
      [264302, 242221],
      [-267, -2074],
      [-54, -1701],
      [-693, -1753],
    ],
    [
      [263288, 236693],
      [-375, 1330],
      [-2, 1097],
      [-281, 268],
      [-139, 1364],
    ],
    [
      [222451, 238769],
      [6, -3970],
    ],
    [
      [222457, 234799],
      [-1261, 76],
    ],
    [
      [221196, 234875],
      [-1009, -7],
    ],
    [
      [220187, 234868],
      [1, 7820],
    ],
    [
      [220187, 234868],
      [-1627, -38],
    ],
    [
      [218560, 234830],
      [-725, -10],
    ],
    [
      [265068, 241976],
      [85, -2796],
      [-178, -1],
      [-97, -4622],
    ],
    [
      [264878, 234557],
      [-479, 557],
      [-1297, -63],
    ],
    [
      [263102, 235051],
      [186, 1642],
    ],
    [
      [251484, 237412],
      [-1134, -2305],
      [-141, 1298],
    ],
    [
      [250209, 236405],
      [-2, 5117],
    ],
    [
      [266171, 241588],
      [21, -1291],
      [-370, -1383],
      [42, -1369],
      [-274, -2432],
    ],
    [
      [265590, 235113],
      [-266, -1104],
      [-213, -2775],
    ],
    [
      [265111, 231234],
      [-234, 66],
    ],
    [
      [264877, 231300],
      [1, 3257],
    ],
    [
      [209944, 215738],
      [-22, 21405],
    ],
    [
      [213360, 230444],
      [686, -3232],
    ],
    [
      [214046, 227212],
      [-190, -2194],
      [-356, 428],
      [-422, -724],
      [-393, -4187],
      [-190, -3082],
      [-26, -2697],
      [-288, -495],
      [-452, -3923],
      [-784, 1354],
      [-338, 2103],
      [-458, 443],
      [-205, 1500],
    ],
    [
      [245190, 235482],
      [-472, -15],
      [-483, 3251],
    ],
    [
      [215612, 234826],
      [-8, -9436],
    ],
    [
      [215604, 225390],
      [-988, 210],
      [-570, 1612],
    ],
    [
      [247253, 234778],
      [-437, -1709],
      [-207, 516],
    ],
    [
      [246609, 233585],
      [-184, -841],
      [-537, 3147],
    ],
    [
      [266925, 241310],
      [88, -37],
    ],
    [
      [267013, 241273],
      [105, -135],
      [78, -2865],
      [203, -1637],
    ],
    [
      [267399, 236636],
      [-213, -2319],
    ],
    [
      [267186, 234317],
      [-334, -5],
    ],
    [
      [266852, 234312],
      [-283, 815],
      [-979, -14],
    ],
    [
      [250209, 236405],
      [34, -1301],
      [-543, -2316],
    ],
    [
      [268741, 240589],
      [-104, -715],
      [226, -2817],
      [-101, -1193],
      [-315, -130],
    ],
    [
      [268447, 235734],
      [-586, 1764],
      [-462, -862],
    ],
    [
      [209944, 215738],
      [-499, 619],
      [-308, 933],
      [-500, 3117],
      [-209, 111],
      [-481, 2314],
      [-467, 5014],
      [-27, 4955],
      [-481, 3934],
      [-35, 2230],
      [-300, 2214],
    ],
    [
      [233980, 235886],
      [192, -3310],
    ],
    [
      [234172, 232576],
      [-466, -2513],
      [-754, 2533],
      [-380, -1253],
      [-369, -201],
    ],
    [
      [232203, 231142],
      [-2, 2893],
    ],
    [
      [227841, 237274],
      [-344, -3901],
      [-437, -2646],
    ],
    [
      [227060, 230727],
      [-169, -807],
    ],
    [
      [226891, 229920],
      [-1289, 6177],
    ],
    [
      [225602, 236097],
      [131, 1301],
    ],
    [
      [262491, 240752],
      [-511, -7567],
    ],
    [
      [261980, 233185],
      [-763, 18],
    ],
    [
      [261217, 233203],
      [16, 6814],
    ],
    [
      [263102, 235051],
      [119, -3211],
      [349, -2174],
    ],
    [
      [263570, 229666],
      [-1288, 55],
      [-50, -791],
    ],
    [
      [262232, 228930],
      [-308, 1351],
      [56, 2904],
    ],
    [
      [269380, 240339],
      [4, -8335],
    ],
    [
      [269384, 232004],
      [-309, -3002],
      [110, -640],
    ],
    [
      [269185, 228362],
      [-359, -2062],
    ],
    [
      [268826, 226300],
      [-394, 1903],
    ],
    [
      [268432, 228203],
      [15, 7531],
    ],
    [
      [272248, 234159],
      [-9, -2722],
      [-434, 368],
    ],
    [
      [271805, 231805],
      [-186, 100],
      [91, 1895],
      [-113, 885],
      [151, 2029],
      [471, -634],
      [29, -1921],
    ],
    [
      [272388, 234172],
      [-135, -12],
    ],
    [
      [272253, 234160],
      [-2, 2672],
      [137, -2660],
    ],
    [
      [271771, 239898],
      [387, -696],
      [23, -1979],
      [-488, -401],
      [1, -1430],
      [-265, -958],
      [92, -1411],
    ],
    [
      [271521, 233023],
      [-998, -79],
    ],
    [
      [270523, 232944],
      [1, 1609],
    ],
    [
      [270523, 232944],
      [0, -816],
    ],
    [
      [270523, 232128],
      [-259, 0],
    ],
    [
      [270264, 232128],
      [-880, -124],
    ],
    [
      [261213, 230026],
      [6, -1939],
    ],
    [
      [261219, 228087],
      [-831, 3621],
      [181, 87],
      [319, -1692],
      [325, -77],
    ],
    [
      [261217, 233203],
      [-4, -3055],
    ],
    [
      [261213, 230148],
      [-256, -46],
      [-113, 1656],
      [-199, -122],
      [-386, 1289],
      [-6, -1143],
      [-725, 2692],
    ],
    [
      [229870, 234989],
      [-428, -2537],
    ],
    [
      [229442, 232452],
      [-640, -2032],
    ],
    [
      [228802, 230420],
      [-160, 3872],
      [-704, 2678],
    ],
    [
      [236900, 233962],
      [5, -1513],
    ],
    [
      [236905, 232449],
      [-445, 185],
      [-184, -1246],
      [-288, 178],
    ],
    [
      [235988, 231566],
      [-422, -16],
      [-379, 7037],
    ],
    [
      [224435, 238770],
      [11, -6721],
    ],
    [
      [224446, 232049],
      [-926, -12],
    ],
    [
      [223520, 232037],
      [-1061, -73],
      [-2, 2835],
    ],
    [
      [225602, 236097],
      [-347, -5926],
    ],
    [
      [225255, 230171],
      [-325, -1870],
    ],
    [
      [224930, 228301],
      [-484, 3748],
    ],
    [
      [245308, 223867],
      [-799, 1569],
      [36, 944],
      [-274, 2264],
    ],
    [
      [244271, 228644],
      [603, 287],
    ],
    [
      [244874, 228931],
      [203, -2331],
      [251, -832],
      [-20, -1901],
    ],
    [
      [244558, 230559],
      [-645, -479],
      [-351, 1662],
      [-258, -1459],
      [-367, 543],
    ],
    [
      [242937, 230826],
      [126, 1509],
      [-231, 3991],
    ],
    [
      [243633, 238723],
      [169, -1018],
      [54, -2471],
      [406, -1498],
      [38, -2365],
      [258, -812],
    ],
    [
      [245528, 235452],
      [-238, -4825],
    ],
    [
      [245290, 230627],
      [-329, -692],
    ],
    [
      [244961, 229935],
      [-403, 624],
    ],
    [
      [235988, 231566],
      [8, -4159],
    ],
    [
      [235996, 227407],
      [-1499, -100],
    ],
    [
      [234497, 227307],
      [-325, 5269],
    ],
    [
      [239649, 236967],
      [279, -442],
      [95, -4141],
      [287, -11],
      [14, -1373],
      [-301, -819],
    ],
    [
      [240023, 230181],
      [-367, 261],
      [-1652, -20],
    ],
    [
      [238004, 230422],
      [33, 3587],
    ],
    [
      [241039, 238421],
      [19, -2543],
    ],
    [
      [241058, 235878],
      [90, -2556],
      [-90, -2140],
    ],
    [
      [241058, 231182],
      [-312, -1023],
    ],
    [
      [240746, 230159],
      [-723, 22],
    ],
    [
      [242403, 235025],
      [-396, -2817],
    ],
    [
      [242007, 232208],
      [-408, -1799],
      [-460, 906],
    ],
    [
      [241139, 231315],
      [-81, -133],
    ],
    [
      [268432, 228203],
      [-222, -845],
    ],
    [
      [268210, 227358],
      [-526, 3596],
      [-439, 645],
      [-59, 2718],
    ],
    [
      [228802, 230420],
      [-812, -4930],
    ],
    [
      [227990, 225490],
      [-930, 5237],
    ],
    [
      [231395, 233651],
      [-275, -1643],
      [131, -1238],
    ],
    [
      [231251, 230770],
      [-412, 483],
      [-916, -965],
    ],
    [
      [229923, 230288],
      [-71, 1866],
      [-410, 298],
    ],
    [
      [242937, 230826],
      [-38, -676],
    ],
    [
      [242899, 230150],
      [-457, 1473],
      [-435, 585],
    ],
    [
      [226432, 226825],
      [-346, -1970],
    ],
    [
      [226086, 224855],
      [-87, 1789],
      [-744, 3527],
    ],
    [
      [226891, 229920],
      [-513, -2622],
      [54, -473],
    ],
    [
      [246609, 233585],
      [-25, -1023],
    ],
    [
      [246584, 232562],
      [-762, -996],
      [-135, -865],
    ],
    [
      [245687, 230701],
      [0, -1],
    ],
    [
      [245687, 230700],
      [-397, -73],
    ],
    [
      [266852, 234312],
      [-10, -6935],
      [145, -1217],
    ],
    [
      [266987, 226160],
      [-200, -2815],
    ],
    [
      [266787, 223345],
      [-399, 937],
      [-283, 2980],
      [-405, 1944],
      [-589, 2028],
    ],
    [
      [264877, 231300],
      [-794, -653],
      [8, -1736],
      [-255, 56],
    ],
    [
      [263836, 228967],
      [-266, 699],
    ],
    [
      [247598, 233761],
      [-477, -3687],
      [-248, -294],
      [-10, -2337],
    ],
    [
      [246863, 227443],
      [-276, 26],
    ],
    [
      [246587, 227469],
      [-43, -63],
    ],
    [
      [246544, 227406],
      [-91, 2727],
      [131, 2429],
    ],
    [
      [218560, 234830],
      [1, -12369],
    ],
    [
      [218561, 222461],
      [3, -3801],
      [-131, -3064],
      [-135, -246],
    ],
    [
      [218298, 215350],
      [-586, 2295],
      [-155, 1738],
      [-559, 1471],
      [-109, 1938],
      [-428, 2706],
      [-230, -539],
      [-627, 431],
    ],
    [
      [221196, 234875],
      [-9, -4032],
    ],
    [
      [221187, 230843],
      [-585, 153],
      [-126, -5325],
      [-5, -3218],
    ],
    [
      [220471, 222453],
      [-272, -3],
    ],
    [
      [220199, 222450],
      [-1638, 11],
    ],
    [
      [223520, 232037],
      [7, -6643],
    ],
    [
      [223527, 225394],
      [-721, 2115],
      [-1183, 235],
    ],
    [
      [221623, 227744],
      [-251, 17],
      [4, 3079],
      [-189, 3],
    ],
    [
      [268210, 227358],
      [-114, -1168],
    ],
    [
      [268096, 226190],
      [-1109, -30],
    ],
    [
      [272837, 223331],
      [-14, 0],
    ],
    [
      [272823, 223331],
      [-64, -213],
    ],
    [
      [272759, 223118],
      [-215, -625],
      [-556, -55],
    ],
    [
      [271988, 222438],
      [-4, 2546],
    ],
    [
      [271984, 224984],
      [-177, 2427],
      [99, 1442],
      [-322, 1679],
      [221, 1273],
    ],
    [
      [272248, 234159],
      [350, -6434],
      [-19, -1080],
      [258, -3314],
    ],
    [
      [272388, 234172],
      [216, -5656],
      [-351, 5644],
    ],
    [
      [238004, 230422],
      [-370, -3472],
    ],
    [
      [237634, 226950],
      [-15, 2086],
      [-480, 949],
      [-234, 2464],
    ],
    [
      [232203, 231142],
      [-438, 1363],
      [375, -6983],
    ],
    [
      [232140, 225522],
      [-575, -1123],
    ],
    [
      [231565, 224399],
      [-196, 1520],
      [-118, 4851],
    ],
    [
      [247737, 223729],
      [-329, 25],
      [-92, 1327],
      [-453, 2362],
    ],
    [
      [247598, 233761],
      [-7, -4805],
      [112, -2059],
      [203, -841],
      [-169, -2327],
    ],
    [
      [248079, 233034],
      [-93, -5082],
      [222, 493],
      [142, -926],
    ],
    [
      [248350, 227519],
      [-169, -1243],
      [4, -1675],
      [243, -2463],
      [7, -2735],
    ],
    [
      [248435, 219403],
      [-412, 1679],
      [32, 1884],
      [-166, 601],
    ],
    [
      [247889, 223567],
      [-152, 162],
    ],
    [
      [262232, 228930],
      [-64, -3572],
    ],
    [
      [262168, 225358],
      [-1, -31],
    ],
    [
      [262167, 225327],
      [-472, -1559],
    ],
    [
      [261695, 223768],
      [-260, -97],
      [30, 2044],
      [-246, 2372],
    ],
    [
      [261213, 230026],
      [0, 122],
    ],
    [
      [249417, 232348],
      [-288, -1736],
      [-365, -1099],
    ],
    [
      [248764, 229513],
      [-363, -244],
      [-62, -812],
      [282, -1440],
    ],
    [
      [248621, 227017],
      [-271, 502],
    ],
    [
      [271521, 233023],
      [-209, -1367],
      [211, 19],
      [-61, -1459],
      [280, -1434],
      [17, -2305],
    ],
    [
      [271759, 226477],
      [-575, -58],
      [-356, -1658],
      [-304, -535],
    ],
    [
      [270524, 224226],
      [-1, 7902],
    ],
    [
      [237634, 226950],
      [-209, -1155],
      [251, -2061],
    ],
    [
      [237676, 223734],
      [-488, -232],
      [-948, -2204],
    ],
    [
      [236240, 221298],
      [-9, 6070],
      [-235, 39],
    ],
    [
      [234497, 227307],
      [188, -998],
      [-40, -2779],
    ],
    [
      [234645, 223530],
      [-249, 562],
      [-163, 2138],
      [-77, -1073],
      [335, -1692],
      [-100, -2415],
    ],
    [
      [234391, 221050],
      [-400, -946],
      [-152, 1094],
    ],
    [
      [233839, 221198],
      [-128, 697],
      [-451, -247],
    ],
    [
      [233260, 221648],
      [-1120, 3874],
    ],
    [
      [249637, 222999],
      [-31, 137],
    ],
    [
      [249606, 223136],
      [-17, -11],
    ],
    [
      [249589, 223125],
      [-854, 2530],
      [-114, 1362],
    ],
    [
      [248764, 229513],
      [178, -1314],
      [189, 484],
      [62, -1577],
      [278, -41],
      [74, 2259],
      [374, 1265],
      [70, -3363],
      [267, -194],
      [-426, -2469],
      [-490, 256],
      [297, -1820],
    ],
    [
      [250525, 230647],
      [-312, -71],
      [261, 1146],
      [51, -1075],
    ],
    [
      [246544, 227406],
      [-360, 597],
      [-279, -302],
    ],
    [
      [245905, 227701],
      [-208, 1350],
      [-10, 1649],
    ],
    [
      [229923, 230288],
      [145, -1541],
    ],
    [
      [230068, 228747],
      [-848, -6121],
    ],
    [
      [229220, 222626],
      [-749, -87],
    ],
    [
      [228471, 222539],
      [-487, 2914],
    ],
    [
      [227984, 225453],
      [6, 37],
    ],
    [
      [242899, 230150],
      [-21, -3785],
    ],
    [
      [242878, 226365],
      [-34, -727],
      [-510, -1743],
      [182, -15],
      [-22, -1563],
      [-530, -1558],
      [-885, 886],
    ],
    [
      [241079, 221645],
      [5, 8529],
      [-338, -15],
    ],
    [
      [270524, 224226],
      [-18, -8],
    ],
    [
      [270506, 224218],
      [-216, 2191],
      [-389, 132],
      [-406, 1491],
    ],
    [
      [269495, 228032],
      [311, 619],
      [458, 3477],
    ],
    [
      [269495, 228032],
      [-310, 330],
    ],
    [
      [224930, 228301],
      [-647, -3579],
    ],
    [
      [224283, 224722],
      [-370, -472],
    ],
    [
      [223913, 224250],
      [-386, 1144],
    ],
    [
      [243094, 222650],
      [507, -1497],
      [-373, -1379],
      [-504, 1635],
      [370, 1241],
    ],
    [
      [244961, 229935],
      [-87, -1004],
    ],
    [
      [244271, 228644],
      [-426, -208],
      [-645, -3854],
    ],
    [
      [243200, 224582],
      [80, 1697],
      [-402, 86],
    ],
    [
      [231565, 224399],
      [21, -2330],
      [-179, -21],
    ],
    [
      [231407, 222048],
      [-240, 599],
    ],
    [
      [231167, 222647],
      [-226, 617],
      [-208, 2752],
      [-251, 273],
      [-414, 2458],
    ],
    [
      [245905, 227701],
      [-338, -3548],
    ],
    [
      [245567, 224153],
      [-211, -1645],
    ],
    [
      [245356, 222508],
      [-48, 1359],
    ],
    [
      [221623, 227744],
      [-6, -5221],
    ],
    [
      [221617, 222523],
      [-1146, -70],
    ],
    [
      [227984, 225453],
      [-780, -2865],
      [-98, 358],
    ],
    [
      [227106, 222946],
      [-308, 892],
      [-366, 2987],
    ],
    [
      [241079, 221645],
      [-915, 2302],
      [-643, 1244],
      [-860, -33],
      [-722, -625],
      [-263, -799],
    ],
    [
      [226086, 224855],
      [-251, -1276],
      [-617, -1664],
    ],
    [
      [225218, 221915],
      [-190, 1265],
      [73, 1338],
      [-818, 204],
    ],
    [
      [261984, 223666],
      [48, -1027],
      [-349, 843],
      [301, 184],
    ],
    [
      [262167, 225327],
      [153, -1172],
      [-625, -387],
    ],
    [
      [263836, 228967],
      [267, -1312],
      [-501, 151],
      [-1029, -3276],
      [-405, 828],
    ],
    [
      [231167, 222647],
      [-368, -2222],
      [-115, -1978],
      [-812, -2987],
    ],
    [
      [229872, 215460],
      [-51, 299],
    ],
    [
      [229821, 215759],
      [273, 1332],
      [-648, 4505],
      [-226, 1030],
    ],
    [
      [245356, 222508],
      [-369, -726],
    ],
    [
      [244987, 221782],
      [-280, -1858],
      [-256, 1228],
      [-396, -628],
      [-7, 2090],
      [-206, -84],
      [-20, 2140],
      [-622, -88],
    ],
    [
      [270506, 224218],
      [-1, -4598],
    ],
    [
      [270505, 219620],
      [-384, -936],
      [-50, 1175],
      [-543, 18],
    ],
    [
      [269528, 219877],
      [-418, -93],
      [-2, 1070],
      [-275, 505],
    ],
    [
      [268833, 221359],
      [-7, 4941],
    ],
    [
      [268833, 221359],
      [-783, 495],
    ],
    [
      [268050, 221854],
      [-36, 3076],
      [82, 1260],
    ],
    [
      [248195, 214699],
      [-70, -748],
    ],
    [
      [248125, 213951],
      [-368, -1498],
      [-381, 4155],
      [-144, -1654],
    ],
    [
      [247232, 214954],
      [0, 260],
    ],
    [
      [247232, 215214],
      [0, 103],
    ],
    [
      [247232, 215317],
      [15, 318],
    ],
    [
      [247247, 215635],
      [13, 86],
    ],
    [
      [247260, 215721],
      [55, 1625],
      [-668, 4635],
      [-414, 1833],
      [-105, 1452],
      [-208, -1076],
      [-353, -37],
    ],
    [
      [247889, 223567],
      [32, -2068],
      [-180, -982],
      [125, -870],
      [386, -448],
      [38, -1287],
      [-225, -2000],
      [130, -1213],
    ],
    [
      [222150, 222529],
      [-533, -6],
    ],
    [
      [223913, 224250],
      [-73, -521],
    ],
    [
      [223840, 223729],
      [-340, -2416],
      [-157, 1139],
      [-1193, 77],
    ],
    [
      [249173, 217874],
      [111, -1305],
      [-400, 134],
      [289, 1171],
    ],
    [
      [249589, 223125],
      [-488, -958],
      [203, -1847],
      [362, -1192],
      [-111, -777],
      [530, -230],
      [375, -1687],
      [141, 831],
      [307, -3039],
      [-50, -1667],
      [-254, -1182],
      [-172, 579],
      [-447, -2429],
      [422, 3793],
      [-138, 1557],
      [-368, -603],
      [-134, 2620],
      [-304, 1182],
      [-628, 94],
      [58, 1184],
      [-307, 734],
      [-151, -685],
    ],
    [
      [236240, 221298],
      [-45, -112],
    ],
    [
      [236195, 221186],
      [-276, 35],
    ],
    [
      [235919, 221221],
      [-211, 269],
      [-540, -841],
      [124, 4279],
      [-302, 699],
      [-198, -2304],
      [-147, 207],
    ],
    [
      [227106, 222946],
      [-578, -5086],
    ],
    [
      [226528, 217860],
      [-680, 53],
      [-139, 1116],
    ],
    [
      [225709, 219029],
      [22, 814],
      [-513, 2072],
    ],
    [
      [271988, 222438],
      [9, -2348],
      [241, -1825],
    ],
    [
      [272238, 218265],
      [-390, -928],
    ],
    [
      [271848, 217337],
      [-291, 1592],
      [-5, 2834],
      [246, 456],
      [-91, 1669],
      [277, 1096],
    ],
    [
      [271759, 226477],
      [138, -1242],
      [-266, -1802],
      [-119, -4400],
      [73, -1266],
    ],
    [
      [271585, 217767],
      [-204, -13],
      [-96, 2166],
      [-186, 606],
      [-594, -906],
    ],
    [
      [233260, 221648],
      [-107, -2624],
      [-243, -16],
      [-116, -2071],
    ],
    [
      [232794, 216937],
      [-175, -671],
      [-275, 621],
      [-264, -1151],
    ],
    [
      [232080, 215736],
      [-404, 3185],
      [-269, 3127],
    ],
    [
      [228471, 222539],
      [-170, -901],
      [-100, -3633],
    ],
    [
      [228201, 218005],
      [-1040, -5116],
    ],
    [
      [227161, 212889],
      [-321, 2085],
    ],
    [
      [226840, 214974],
      [-364, 2442],
      [52, 444],
    ],
    [
      [244928, 217715],
      [211, -933],
      [-107, -1820],
      [-305, 1569],
      [201, 1184],
    ],
    [
      [247260, 215721],
      [-525, 940],
      [-7, -1505],
      [-198, -130],
      [-95, -1883],
      [-522, -151],
      [-171, 914],
      [376, 1325],
      [-332, 809],
      [-84, 1095],
      [-261, -2776],
      [-233, 825],
      [15, 1953],
      [-358, 1998],
      [122, 2647],
    ],
    [
      [225709, 219029],
      [-761, -6052],
    ],
    [
      [224948, 212977],
      [-1106, 2536],
    ],
    [
      [223842, 215513],
      [-2, 8216],
    ],
    [
      [223842, 215513],
      [-1, -2983],
    ],
    [
      [223841, 212530],
      [-1697, 16],
    ],
    [
      [222144, 212546],
      [6, 9983],
    ],
    [
      [272759, 223118],
      [386, -4323],
    ],
    [
      [273145, 218795],
      [-132, -298],
      [16, -2699],
      [-744, -89],
      [-47, 2556],
    ],
    [
      [232080, 215736],
      [-73, -613],
    ],
    [
      [232007, 215123],
      [-254, -1543],
      [-960, -3417],
    ],
    [
      [230793, 210163],
      [-921, 5297],
    ],
    [
      [229821, 215759],
      [-780, -3735],
    ],
    [
      [229041, 212024],
      [-105, 759],
    ],
    [
      [228936, 212783],
      [-735, 5222],
    ],
    [
      [222144, 212546],
      [-1943, -92],
    ],
    [
      [220201, 212454],
      [-2, 9996],
    ],
    [
      [220201, 212454],
      [-1549, -37],
    ],
    [
      [218652, 212417],
      [-301, 1679],
      [-53, 1254],
    ],
    [
      [235183, 217123],
      [-168, -988],
      [-897, -3581],
      [738, 3848],
      [327, 721],
    ],
    [
      [234284, 214573],
      [-491, 4941],
      [46, 1684],
    ],
    [
      [234391, 221050],
      [301, -995],
      [33, -3555],
      [-122, 190],
      [-319, -2117],
    ],
    [
      [236195, 221186],
      [-1085, -2978],
      [545, 2519],
      [264, 494],
    ],
    [
      [234284, 214573],
      [-259, -341],
      [-245, -3555],
      [-456, -2355],
      [-295, -718],
    ],
    [
      [233029, 207604],
      [-168, 76],
      [-313, 2527],
      [-238, 31],
      [-6, 1604],
      [-193, 734],
      [-104, 2547],
    ],
    [
      [269528, 219877],
      [10, -5017],
      [-370, -17],
      [1, -3161],
    ],
    [
      [269169, 211682],
      [-610, -875],
    ],
    [
      [268559, 210807],
      [-4, -26],
    ],
    [
      [268555, 210781],
      [11, 1180],
      [-229, 1769],
      [-631, 423],
      [-130, 2753],
    ],
    [
      [267576, 216906],
      [349, 3015],
      [125, 1933],
    ],
    [
      [271585, 217767],
      [74, -1765],
    ],
    [
      [271659, 216002],
      [-47, -5892],
      [-823, -7],
    ],
    [
      [270789, 210103],
      [-996, 7],
    ],
    [
      [269793, 210110],
      [-470, 1716],
      [-154, -144],
    ],
    [
      [226840, 214974],
      [-1288, -6318],
    ],
    [
      [225552, 208656],
      [-604, 4321],
    ],
    [
      [274038, 206958],
      [-133, 1],
    ],
    [
      [273905, 206959],
      [-39, -1],
    ],
    [
      [273866, 206958],
      [-329, -17],
      [-54, -3299],
    ],
    [
      [273483, 203642],
      [-296, 3940],
    ],
    [
      [273187, 207582],
      [-245, -773],
      [-517, 1780],
    ],
    [
      [272425, 208589],
      [33, 1990],
      [-268, 1417],
    ],
    [
      [272190, 211996],
      [-290, 2402],
    ],
    [
      [271900, 214398],
      [73, 2026],
      [-125, 913],
    ],
    [
      [273145, 218795],
      [71, -1438],
      [822, -10399],
    ],
    [
      [228017, 208315],
      [-311, 1140],
      [-437, -2085],
    ],
    [
      [227269, 207370],
      [-501, 3617],
      [393, 1902],
    ],
    [
      [228936, 212783],
      [-919, -4468],
    ],
    [
      [272425, 208589],
      [-133, -1746],
    ],
    [
      [272292, 206843],
      [-680, -113],
      [4, -8039],
    ],
    [
      [271616, 198691],
      [-837, -35],
    ],
    [
      [270779, 198656],
      [10, 11447],
    ],
    [
      [271659, 216002],
      [241, -1604],
    ],
    [
      [230793, 210163],
      [-40, -5357],
    ],
    [
      [230753, 204806],
      [-186, -16],
    ],
    [
      [230567, 204790],
      [-105, 611],
    ],
    [
      [230462, 205401],
      [-405, -37],
    ],
    [
      [230057, 205364],
      [-229, 272],
    ],
    [
      [229828, 205636],
      [-9, -8],
    ],
    [
      [229819, 205628],
      [-2, 32],
    ],
    [
      [229817, 205660],
      [-203, 3106],
      [-295, 2552],
      [-278, 706],
    ],
    [
      [225150, 203636],
      [-1, 663],
      [-1297, -17],
    ],
    [
      [223852, 204282],
      [-11, 8248],
    ],
    [
      [225552, 208656],
      [258, -1776],
    ],
    [
      [225810, 206880],
      [-660, -3244],
    ],
    [
      [230757, 204203],
      [-4, 603],
    ],
    [
      [233029, 207604],
      [-514, -1808],
      [-270, 336],
      [-409, -941],
      [-73, -1321],
      [716, 1768],
      [-1755, -5263],
      [983, 3206],
      [18, 865],
      [-525, -948],
      [-443, 705],
    ],
    [
      [226700, 204666],
      [-382, 955],
      [-249, -541],
    ],
    [
      [226069, 205080],
      [-259, 1800],
    ],
    [
      [227269, 207370],
      [-569, -2704],
    ],
    [
      [229817, 205660],
      [1, -376],
    ],
    [
      [229818, 205284],
      [-753, -2126],
      [109, -1486],
    ],
    [
      [229174, 201672],
      [-69, -398],
      [-683, 1260],
    ],
    [
      [228422, 202534],
      [5, 4144],
      [-122, 1448],
      [-288, 189],
    ],
    [
      [223852, 204282],
      [-1657, -123],
    ],
    [
      [222195, 204159],
      [-37, -4],
    ],
    [
      [222158, 204155],
      [-14, 8391],
    ],
    [
      [222158, 204155],
      [-1965, 141],
    ],
    [
      [220193, 204296],
      [8, 8158],
    ],
    [
      [220193, 204296],
      [2, -8383],
    ],
    [
      [220195, 195913],
      [-275, -20],
    ],
    [
      [219920, 195893],
      [-228, 1634],
      [-299, 5632],
      [-270, 1346],
      [-137, 3111],
      [-290, 2181],
      [-44, 2620],
    ],
    [
      [269793, 210110],
      [391, -3170],
      [-256, -2281],
    ],
    [
      [269928, 204659],
      [-433, 506],
      [-598, -8],
    ],
    [
      [268897, 205157],
      [-149, 697],
      [-6, 3295],
      [-183, 1658],
    ],
    [
      [270779, 198656],
      [-274, -620],
    ],
    [
      [270505, 198036],
      [2, 3099],
    ],
    [
      [270507, 201135],
      [2, 766],
      [-428, 978],
      [-153, 1780],
    ],
    [
      [228422, 202534],
      [-599, -3072],
    ],
    [
      [227823, 199462],
      [-496, 1215],
      [-66, 1282],
      [-333, 364],
      [-228, 2343],
    ],
    [
      [273483, 203642],
      [-949, -51],
      [-367, 555],
      [125, 2697],
    ],
    [
      [273866, 206958],
      [152, -4435],
      [384, -6703],
      [460, -6126],
      [-21, -545],
    ],
    [
      [274841, 189149],
      [-1029, -223],
    ],
    [
      [273812, 188926],
      [17, 9772],
    ],
    [
      [273829, 198698],
      [-61, 3007],
      [-285, 1937],
    ],
    [
      [274038, 206958],
      [411, -2425],
      [-276, 2427],
    ],
    [
      [274173, 206960],
      [19, 0],
    ],
    [
      [274192, 206960],
      [437, -3794],
      [144, -2446],
      [-167, -836],
      [-12, 3069],
      [-288, -3646],
      [-72, 100],
      [-11, 3804],
      [-174, 606],
      [153, 1506],
      [-297, 1636],
    ],
    [
      [226409, 193288],
      [-981, 21],
    ],
    [
      [225428, 193309],
      [-276, -3],
    ],
    [
      [225152, 193306],
      [-2, 10330],
    ],
    [
      [226069, 205080],
      [-235, -508],
      [782, -8928],
      [-25, -123],
    ],
    [
      [226591, 195521],
      [-240, -1164],
      [58, -1069],
    ],
    [
      [273829, 198698],
      [-2213, -7],
    ],
    [
      [229313, 193901],
      [29, 366],
    ],
    [
      [229342, 194267],
      [112, 1478],
      [226, 125],
      [508, 2370],
      [340, 470],
      [-1215, -4809],
    ],
    [
      [229467, 200562],
      [-293, 1110],
    ],
    [
      [229818, 205284],
      [138, -1955],
      [593, -2862],
      [-702, -2230],
      [-380, 2325],
    ],
    [
      [230462, 205401],
      [-19, -1857],
      [-353, 715],
      [-33, 1105],
    ],
    [
      [227823, 199462],
      [-461, -4167],
    ],
    [
      [227362, 195295],
      [-76, -648],
      [-695, 874],
    ],
    [
      [270507, 201135],
      [-552, 2],
      [0, -813],
      [-1166, -23],
    ],
    [
      [268789, 200301],
      [108, 4856],
    ],
    [
      [223852, 204282],
      [-7, -10983],
    ],
    [
      [223845, 193299],
      [-1631, -503],
      [-15, 3242],
    ],
    [
      [222199, 196038],
      [-4, 8121],
    ],
    [
      [225152, 193306],
      [-1307, -7],
    ],
    [
      [222199, 196038],
      [-2004, -125],
    ],
    [
      [229467, 200562],
      [-206, -608],
      [191, -1783],
    ],
    [
      [229452, 198171],
      [-926, -864],
      [295, -1302],
    ],
    [
      [228821, 196005],
      [-626, -2606],
    ],
    [
      [228195, 193399],
      [-53, 240],
    ],
    [
      [228142, 193639],
      [-158, 1151],
      [-622, 505],
    ],
    [
      [270505, 198036],
      [-139, -2611],
    ],
    [
      [270366, 195425],
      [-1519, 30],
    ],
    [
      [268847, 195455],
      [-374, -12],
    ],
    [
      [268473, 195443],
      [316, 4858],
    ],
    [
      [271616, 198691],
      [0, -1635],
      [277, 2],
      [94, -2171],
      [192, -1069],
      [303, -11],
      [-262, -959],
      [585, -3549],
      [246, -3711],
    ],
    [
      [273051, 185588],
      [-1173, 63],
    ],
    [
      [271878, 185651],
      [-1368, -4],
    ],
    [
      [270510, 185647],
      [-6, 9777],
      [-138, 1],
    ],
    [
      [273812, 188926],
      [-11, -3356],
    ],
    [
      [273801, 185570],
      [-750, 18],
    ],
    [
      [229222, 193536],
      [-326, -2723],
      [39, 1456],
      [287, 1267],
    ],
    [
      [228195, 193399],
      [229, -262],
      [-76, -1380],
    ],
    [
      [228348, 191757],
      [-206, 1882],
    ],
    [
      [228490, 190446],
      [-134, 1235],
    ],
    [
      [228356, 191681],
      [444, 2665],
      [-7, -1493],
      [-303, -2407],
    ],
    [
      [229452, 198171],
      [-21, -806],
    ],
    [
      [229431, 197365],
      [-30, -1032],
    ],
    [
      [229401, 196333],
      [-338, -1783],
      [-242, 1455],
    ],
    [
      [223845, 193299],
      [14, -13095],
    ],
    [
      [223859, 180204],
      [1, -1601],
      [-436, 24],
    ],
    [
      [223424, 178627],
      [-1056, 73],
      [-103, 854],
      [-232, -1005],
    ],
    [
      [222033, 178549],
      [-141, 1379],
      [72, 2611],
      [-322, 2960],
      [-327, 370],
      [-463, 2627],
      [-155, 3392],
      [-155, 198],
      [-278, 2896],
      [-344, 911],
    ],
    [
      [228348, 191757],
      [8, -76],
    ],
    [
      [228490, 190446],
      [-3, -28],
    ],
    [
      [228487, 190418],
      [-16, -156],
    ],
    [
      [228471, 190262],
      [-133, -1330],
      [-231, 1091],
      [-604, -774],
    ],
    [
      [227503, 189249],
      [-413, 475],
      [-457, 1288],
      [12, 1137],
    ],
    [
      [226645, 192149],
      [-45, 799],
    ],
    [
      [226600, 192948],
      [-191, 340],
    ],
    [
      [268847, 195455],
      [7, -2885],
    ],
    [
      [268854, 192570],
      [-198, -1538],
      [342, -1335],
      [-115, -2957],
      [-287, 863],
      [-300, 2065],
      [148, 2365],
      [29, 3410],
    ],
    [
      [270510, 185647],
      [-1388, -19],
    ],
    [
      [269122, 185628],
      [467, 3254],
      [-294, 1838],
      [49, -1786],
      [-175, 307],
      [19, 1799],
      [-334, 1530],
    ],
    [
      [225428, 193309],
      [7, -14809],
    ],
    [
      [225435, 178500],
      [-810, 49],
    ],
    [
      [224625, 178549],
      [3, 1455],
      [-769, 200],
    ],
    [
      [226645, 192149],
      [-378, -2056],
      [-22, -4642],
    ],
    [
      [226245, 185451],
      [-328, -1],
      [5, -6978],
    ],
    [
      [225922, 178472],
      [-487, 28],
    ],
    [
      [228402, 187096],
      [249, 1941],
      [-343, -3973],
      [94, 2032],
    ],
    [
      [227963, 184054],
      [-1438, -46],
      [-280, 1443],
    ],
    [
      [227503, 189249],
      [288, 8],
      [201, -2841],
      [170, 164],
      [-199, -2526],
    ],
    [
      [275241, 183987],
      [-902, 23],
    ],
    [
      [274339, 184010],
      [-271, 4],
      [-3, 1573],
      [-264, -17],
    ],
    [
      [274841, 189149],
      [341, -3281],
      [59, -1881],
    ],
    [
      [273051, 185588],
      [-176, -2846],
      [396, -2050],
      [334, -3158],
    ],
    [
      [273605, 177534],
      [-628, -17],
      [1, -1639],
      [-277, -12],
      [2, -1649],
      [-824, 22],
    ],
    [
      [271879, 174239],
      [-2, 5717],
    ],
    [
      [271877, 179956],
      [1, 5695],
    ],
    [
      [271877, 179956],
      [-1371, -47],
    ],
    [
      [270506, 179909],
      [4, 5738],
    ],
    [
      [270506, 179909],
      [-2, -2429],
    ],
    [
      [270504, 177480],
      [-549, 22],
      [3, 3302],
      [-874, 53],
    ],
    [
      [269084, 180857],
      [-345, 1646],
      [240, 734],
      [143, 2391],
    ],
    [
      [274339, 184010],
      [6, -6562],
    ],
    [
      [274345, 177448],
      [1, -1562],
      [-580, -3038],
    ],
    [
      [273766, 172848],
      [42, 3614],
      [-203, 1072],
    ],
    [
      [227901, 178788],
      [-37, 1],
    ],
    [
      [227864, 178789],
      [9, 1767],
      [307, 3881],
    ],
    [
      [228180, 184437],
      [70, -94],
    ],
    [
      [228250, 184343],
      [-349, -5555],
    ],
    [
      [227963, 184054],
      [-251, -4475],
      [-258, -852],
      [114, 2438],
      [-407, -1626],
      [-336, 1829],
      [200, -2389],
      [-320, -88],
    ],
    [
      [226705, 178891],
      [-582, -1386],
    ],
    [
      [226123, 177505],
      [-201, 967],
    ],
    [
      [275241, 183987],
      [343, -5478],
    ],
    [
      [275584, 178509],
      [-145, -584],
    ],
    [
      [275439, 177925],
      [-1, -410],
    ],
    [
      [275438, 177515],
      [-1093, -67],
    ],
    [
      [270504, 177480],
      [-3, -3270],
    ],
    [
      [270501, 174210],
      [-551, 17],
      [0, -506],
    ],
    [
      [269950, 173721],
      [0, -130],
    ],
    [
      [269950, 173591],
      [-1, -996],
      [-273, 12],
    ],
    [
      [269676, 172607],
      [-254, 2093],
      [-338, 6157],
    ],
    [
      [224625, 178549],
      [159, -3917],
      [135, 10],
      [-13, -5059],
    ],
    [
      [224906, 169583],
      [-1481, 40],
    ],
    [
      [223425, 169623],
      [-1, 9004],
    ],
    [
      [271879, 174239],
      [-1378, -29],
    ],
    [
      [223425, 169623],
      [-158, -2061],
      [-441, -1922],
    ],
    [
      [222826, 165640],
      [-276, 5054],
      [-494, 3303],
      [54, 2886],
      [-77, 1666],
    ],
    [
      [228015, 167092],
      [-166, 1925],
      [-8, 2670],
    ],
    [
      [227841, 171687],
      [174, -4595],
    ],
    [
      [227841, 171687],
      [-52, 110],
      [22, 6050],
      [53, 942],
    ],
    [
      [227901, 178788],
      [-82, -3294],
      [22, -3807],
    ],
    [
      [227632, 166164],
      [-1163, -35],
      [-267, 256],
    ],
    [
      [226202, 166385],
      [-78, 3148],
    ],
    [
      [226124, 169533],
      [-1, 7972],
    ],
    [
      [226705, 178891],
      [360, -137],
      [283, -871],
      [341, 625],
      [-132, -4582],
      [-184, -313],
      [-31, -3271],
      [212, -1257],
      [78, -2921],
    ],
    [
      [226124, 169533],
      [-934, 42],
    ],
    [
      [225190, 169575],
      [-284, 8],
    ],
    [
      [275994, 173067],
      [-63, 2],
    ],
    [
      [275931, 173069],
      [-9, 1],
    ],
    [
      [275922, 173070],
      [-37, 2],
    ],
    [
      [275885, 173072],
      [-22, 0],
    ],
    [
      [275863, 173072],
      [-26, -266],
      [-2071, 42],
    ],
    [
      [275438, 177515],
      [375, -1551],
      [181, -2897],
    ],
    [
      [273766, 172848],
      [-167, -3535],
      [-1729, 10],
    ],
    [
      [271870, 169323],
      [9, 4916],
    ],
    [
      [269950, 173591],
      [197, -1158],
      [100, -2595],
      [-427, 921],
      [-144, 1848],
    ],
    [
      [271870, 169323],
      [-1374, 16],
    ],
    [
      [270496, 169339],
      [-105, 2644],
      [304, 1032],
      [-368, 4],
      [-94, -803],
      [-283, 1505],
    ],
    [
      [275863, 173072],
      [173, -517],
      [58, -7156],
      [-69, -4426],
    ],
    [
      [276025, 160973],
      [-424, 252],
      [-1823, -64],
    ],
    [
      [273778, 161161],
      [-12, 11687],
    ],
    [
      [273778, 161161],
      [4, -1336],
    ],
    [
      [273782, 159825],
      [-1083, -119],
      [-9, 4916],
      [-814, -70],
    ],
    [
      [271876, 164552],
      [-6, 4771],
    ],
    [
      [270224, 168129],
      [277, -3877],
      [-101, -154],
      [-176, 4031],
    ],
    [
      [271876, 164552],
      [5, -1688],
      [-271, -31],
      [4, -1924],
      [-522, 237],
    ],
    [
      [271092, 161146],
      [-65, 2473],
      [-474, 1226],
      [-124, 2786],
      [67, 1708],
    ],
    [
      [225190, 169575],
      [-740, -9780],
    ],
    [
      [224450, 159795],
      [-249, -323],
      [-357, 2361],
      [-856, 1101],
      [54, 931],
      [-216, 1775],
    ],
    [
      [226202, 166385],
      [-130, -3036],
      [395, -282],
      [0, -1591],
    ],
    [
      [226467, 161476],
      [2, -5174],
    ],
    [
      [226469, 156302],
      [-921, -317],
      [-542, 1946],
      [-162, 1244],
      [-394, 620],
    ],
    [
      [227781, 162637],
      [-161, -1600],
      [-221, -469],
      [-932, 908],
    ],
    [
      [227632, 166164],
      [36, -3208],
      [113, -319],
    ],
    [
      [273782, 159825],
      [19, -5212],
    ],
    [
      [273801, 154613],
      [0, -3238],
    ],
    [
      [273801, 151375],
      [-1312, -39],
    ],
    [
      [272489, 151336],
      [-461, 1867],
      [-228, -193],
      [-395, 1936],
      [-191, 1815],
      [-122, 4385],
    ],
    [
      [227781, 162637],
      [266, -4006],
      [-45, -1342],
      [291, -825],
      [-120, -2091],
      [-349, -1094],
      [6, -1266],
      [-411, 861],
      [-516, 2812],
      [-434, 616],
    ],
    [
      [276025, 160973],
      [-123, -6440],
    ],
    [
      [275902, 154533],
      [-491, -343],
      [-1072, 2],
      [-538, 421],
    ],
    [
      [275036, 140768],
      [-16, 1],
    ],
    [
      [275020, 140769],
      [-149, -434],
    ],
    [
      [274871, 140335],
      [3, -76],
    ],
    [
      [274874, 140259],
      [-199, 601],
      [-388, -1467],
      [-445, 274],
    ],
    [
      [273842, 139667],
      [-42, 3489],
      [1, 8219],
    ],
    [
      [275902, 154533],
      [-196, -4031],
      [-305, -2455],
      [-117, -2922],
      [81, -1855],
      [-329, -2502],
    ],
    [
      [275491, 142866],
      [-401, -4417],
      [-362, -2015],
      [523, 3742],
      [240, 2690],
    ],
    [
      [273842, 139667],
      [-118, -686],
      [-512, -438],
      [-244, 1966],
      [102, 2254],
      [184, -1641],
      [296, -971],
      [166, 920],
      [-246, 1771],
      [-349, 146],
      [-259, 2834],
      [-188, 3420],
      [234, 599],
      [-210, 868],
      [-82, -1356],
      [-127, 1983],
    ],
    [
      [267576, 216906],
      [-181, 85],
      [-177, 1931],
      [-468, 2015],
      [37, 2408],
    ],
    [
      [233545, 583163],
      [470, -1],
      [0, 7179],
      [546, -266],
      [367, -1412],
      [396, -7638],
      [-21, -1975],
      [288, -1125],
      [438, -310],
    ],
    [
      [249572, 564554],
      [-1138, -3158],
      [-1287, -1848],
      [-816, -1938],
      [-809, -2999],
    ],
    [
      [245498, 568091],
      [407, 1054],
      [402, -2818],
      [1697, 311],
      [722, -2325],
      [296, 673],
      [550, -432],
    ],
    [
      [244050, 541402],
      [0, -11146],
    ],
    [
      [242234, 539623],
      [263, 1550],
      [296, -1143],
      [509, 150],
      [748, 1222],
    ],
    [
      [245788, 538230],
      [8, -8017],
    ],
    [
      [244050, 541402],
      [832, 1557],
      [406, 335],
      [672, 1956],
      [311, -1206],
      [-365, -2639],
      [91, -1053],
      [-209, -2122],
    ],
    [
      [245788, 538230],
      [630, 1480],
      [422, -1520],
    ],
    [
      [246668, 543604],
      [119, -491],
      [-601, -1772],
      [482, 2263],
    ],
    [
      [254361, 554779],
      [386, -1343],
      [-683, -275],
      [52, -770],
      [-806, -2719],
    ],
    [
      [253310, 549672],
      [-198, 1603],
      [-596, -3],
    ],
    [
      [252516, 551272],
      [245, 1549],
      [576, 1487],
      [1024, 471],
    ],
    [
      [252129, 567028],
      [638, 1075],
      [-743, -3451],
      [-872, -1783],
      [142, -551],
      [-582, -1037],
      [-166, 1627],
      [1583, 4120],
    ],
    [
      [252473, 548019],
      [214, -3207],
    ],
    [
      [251344, 546531],
      [128, 1308],
      [717, 2354],
      [12, -1815],
      [272, -359],
    ],
    [
      [253310, 549672],
      [-511, -4124],
      [-63, 2158],
      [-507, 612],
      [-28, 1852],
      [315, 1102],
    ],
    [
      [256406, 536544],
      [0, -6253],
    ],
    [
      [256406, 530291],
      [-348, -1],
      [-4, -3236],
      [-348, 4],
    ],
    [
      [253820, 544308],
      [634, -392],
      [509, -1456],
      [171, -1720],
      [674, -4188],
      [598, -8],
    ],
    [
      [257802, 530286],
      [-1396, 5],
    ],
    [
      [256406, 536544],
      [265, 725],
      [573, -1784],
      [182, 767],
      [315, -1250],
      [489, 2628],
      [901, 2228],
      [763, 318],
    ],
    [
      [258234, 522812],
      [-689, -2000],
      [491, 3712],
      [-698, 206],
      [-249, -2795],
      [-468, 641],
      [-361, -1504],
      [-267, -2116],
    ],
    [
      [255011, 510600],
      [-35, -2168],
      [-370, -346],
    ],
    [
      [254678, 502703],
      [346, 2967],
      [570, 1286],
      [473, 4864],
      [330, 489],
      [143, 1898],
      [235, 8],
      [-572, -6086],
      [-44, -1740],
      [-320, -1641],
      [-154, -2072],
    ],
    [
      [266291, 525881],
      [-781, 769],
    ],
    [
      [265510, 526650],
      [490, 1336],
      [-121, 1234],
      [374, -217],
      [304, -1926],
      [-266, -1196],
    ],
    [
      [264534, 537282],
      [192, -119],
      [-169, -2149],
      [-263, 1387],
      [240, 881],
    ],
    [
      [264771, 527003],
      [-281, 49],
    ],
    [
      [264490, 527052],
      [-48, -2],
    ],
    [
      [264442, 527050],
      [-19, 1612],
      [-346, 2],
      [-1, 1612],
      [-2086, 1],
      [-3, 1628],
      [-347, -5],
    ],
    [
      [261640, 541399],
      [788, 243],
      [-207, -1485],
      [-13, -3740],
      [568, -816],
      [557, 744],
      [103, -1308],
      [702, 1641],
      [226, -1325],
      [215, -3355],
      [-110, -1628],
      [279, 286],
      [140, -1587],
      [477, -2056],
      [-594, -10],
    ],
    [
      [263518, 523913],
      [579, -847],
      [-387, -679],
      [-192, 1526],
    ],
    [
      [264442, 527050],
      [-1182, 1276],
      [-230, -2015],
      [-35, -1966],
      [-708, 3125],
      [-1172, 1762],
      [-254, -167],
      [-506, -2455],
    ],
    [
      [260355, 526610],
      [-463, 132],
    ],
    [
      [263048, 514103],
      [-754, -309],
      [-259, 1732],
    ],
    [
      [262035, 515526],
      [484, 1050],
      [-316, 238],
      [-233, 2467],
      [498, 2959],
      [580, 1140],
    ],
    [
      [261221, 512584],
      [62, 1278],
      [458, 1591],
      [294, 73],
    ],
    [
      [260809, 522684],
      [123, -2641],
      [-358, -487],
      [235, 3128],
    ],
    [
      [259619, 509895],
      [-266, 697],
      [189, 946],
      [77, -1643],
    ],
    [
      [260516, 504530],
      [-484, -12],
    ],
    [
      [259309, 504590],
      [9, 2168],
      [372, 1310],
      [375, -215],
      [515, 4334],
      [143, -2358],
      [-221, -3319],
      [14, -1980],
    ],
    [
      [260516, 504530],
      [127, -152],
      [342, 4121],
      [-150, -4481],
      [235, 2088],
    ],
    [
      [266792, 512571],
      [208, -2839],
      [-312, 126],
      [-6, -2477],
      [298, -1301],
    ],
    [
      [266298, 493142],
      [-35, -2125],
      [-276, -1072],
      [-460, 40],
      [-182, -1553],
    ],
    [
      [266573, 485031],
      [572, 4601],
      [580, 621],
      [347, 1131],
      [524, -1430],
      [297, -2969],
      [77, -2649],
    ],
    [
      [269259, 474627],
      [236, -3607],
      [-157, -1606],
      [-122, -4884],
      [-353, 83],
      [-169, 1029],
    ],
    [
      [268236, 461261],
      [-80, -1234],
      [-498, -1330],
      [-248, -2669],
      [-87, -2521],
    ],
    [
      [267323, 453507],
      [-275, 1112],
      [-676, -160],
    ],
    [
      [267323, 453507],
      [-736, -4608],
      [-57, -1020],
    ],
    [
      [276369, 283639],
      [51, -2897],
      [155, -81],
      [-317, -2464],
      [-721, -1619],
    ],
    [
      [275537, 276578],
      [-263, -628],
    ],
    [
      [275274, 275950],
      [-210, 1997],
    ],
    [
      [277898, 288714],
      [355, -1067],
      [-412, -2026],
      [-248, 443],
      [-747, -4416],
      [-356, -321],
      [309, 2769],
    ],
    [
      [64909, 48285],
      [735, -2763],
      [362, -391],
      [488, -1483],
      [555, -3196],
      [-25, -2084],
      [244, 11],
      [54, -1736],
      [492, -2286],
      [-474, -3219],
      [-428, -1373],
      [-453, -186],
      [-605, -2496],
      [-405, -3858],
      [-627, 2125],
      [-103, 1501],
      [89, 4218],
      [-292, 5441],
      [-196, 1715],
      [345, 2265],
      [317, 3334],
      [-187, 1774],
      [-23, 2014],
      [137, 673],
    ],
    [
      [55028, 84760],
      [233, -813],
      [-51, -4138],
      [-317, -1724],
      [-531, 856],
      [-341, 1190],
      [-70, 1626],
      [168, 1566],
      [392, 1359],
      [517, 78],
    ],
    [
      [61944, 65038],
      [-272, 274],
    ],
    [
      [61944, 65038],
      [565, -356],
      [-456, -1900],
      [-525, 1012],
      [-688, 11],
      [160, 2281],
      [672, -774],
    ],
    [
      [61752, 60573],
      [246, -292],
      [244, -1961],
      [-82, -859],
      [-351, -533],
      [-258, 3324],
      [201, 321],
    ],
    [
      [62904, 54516],
      [45, -1246],
      [-351, -599],
      [9, 965],
      [297, 880],
    ],
    [
      [62814, 62495],
      [340, -2480],
      [439, 903],
      [263, -353],
      [320, -1916],
      [312, -600],
      [35, -1559],
      [-160, -1021],
      [-712, -1316],
      [-391, 412],
      [-54, 3221],
      [-459, 616],
      [-172, 1326],
      [59, 2293],
      [180, 474],
    ],
    [
      [58972, 75138],
      [409, -3430],
      [-19, -1219],
      [215, 21],
      [315, -3031],
      [-404, -786],
      [-271, 1419],
      [-580, -706],
      [-493, 5221],
      [435, 169],
      [393, 2342],
    ],
    [
      [53118, 80469],
      [25, -1794],
      [-233, -683],
      [-126, -1603],
      [-75, 1953],
      [409, 2127],
    ],
    [
      [996993, 632383],
      [817, -1163],
      [91, -906],
      [715, -2639],
      [-621, 1211],
      [-350, 1710],
      [-879, 1732],
      [227, 55],
    ],
    [
      [949, 635992],
      [99, -1643],
      [-281, 619],
      [182, 1024],
    ],
    [
      [7983, 636500],
      [-23, -2275],
      [-307, -73],
      [-67, 2101],
      [397, 247],
    ],
    [
      [8254, 636861],
      [430, -729],
      [-177, -971],
      [-343, 386],
      [90, 1314],
    ],
    [
      [8791, 637399],
      [79, -1229],
      [-422, 751],
      [343, 478],
    ],
    [
      [2943, 637533],
      [355, -28],
      [110, -1138],
      [762, -730],
      [-472, -573],
      [-84, -1947],
      [-423, -823],
      [-299, 1293],
      [442, 1083],
      [-738, 1716],
      [347, 1147],
    ],
    [
      [5406, 633632],
      [-183, -597],
      [-329, 1038],
      [-855, -380],
      [1115, 1264],
      [256, 737],
      [15, 1940],
      [429, -501],
      [-230, -1194],
      [22, -1773],
      [-240, -534],
    ],
    [
      [996376, 638802],
      [311, -785],
      [-243, -852],
      [-68, 1637],
    ],
    [
      [7153, 639094],
      [-123, -3160],
      [551, 52],
      [-111, -1993],
      [-622, -692],
      [-248, -1116],
      [-148, 1715],
      [-276, -2445],
      [-150, 1181],
      [346, 1636],
      [-142, 1180],
      [574, -355],
      [-294, 2578],
      [643, 1419],
    ],
    [
      [999634, 639522],
      [333, -975],
      [-327, -1865],
      [-356, 430],
      [-110, 1601],
      [460, 809],
    ],
    [
      [8394, 641129],
      [361, -843],
      [-150, -1151],
      [-356, -113],
      [145, 2107],
    ],
    [
      [993962, 641501],
      [134, -1164],
      [-301, -1591],
      [5, -1345],
      [-561, -90],
      [-112, -1517],
      [-310, 1266],
      [482, 1562],
      [297, 123],
      [366, 2756],
    ],
    [
      [15681, 641867],
      [-88, -644],
      [551, -599],
      [499, 441],
      [600, -278],
      [-1374, -850],
      [-663, 468],
      [-396, -613],
      [-512, 1117],
      [346, 752],
      [243, -725],
      [794, 931],
    ],
    [
      [18716, 646240],
      [355, -1060],
      [-312, -984],
      [-542, -452],
      [86, 1790],
      [413, 706],
    ],
    [
      [13937, 646817],
      [445, -1871],
      [-209, -1713],
      [-378, -563],
      [294, -1046],
      [-846, -839],
      [-955, -1616],
      [-414, 666],
      [-938, -680],
      [1039, 1800],
      [664, 138],
      [756, 1388],
      [293, 1606],
      [-346, 796],
      [246, 1637],
      [349, 297],
    ],
    [
      [983194, 648582],
      [-58, -2897],
      [-305, 734],
      [-723, 157],
      [686, 1802],
      [400, 204],
    ],
    [
      [23639, 652034],
      [277, -453],
      [-113, -1345],
      [-515, -1146],
      [-83, 1789],
      [434, 1155],
    ],
    [
      [26147, 655623],
      [246, -1330],
      [-168, -813],
      [-713, 1494],
      [635, 649],
    ],
    [
      [980646, 657671],
      [765, -147],
      [436, -2390],
      [463, -235],
      [-708, -1136],
      [-317, 775],
      [-432, -1614],
      [-470, 872],
      [165, 1668],
      [-516, -336],
      [77, 1140],
      [-541, -71],
      [552, 1545],
      [526, -71],
    ],
    [
      [28035, 654543],
      [906, 4624],
      [-94, 1472],
      [527, 2186],
      [747, 66],
      [-272, 830],
      [81, 1805],
      [502, 2014],
      [613, 648],
      [607, -970],
      [-156, -2372],
      [-1193, -2675],
      [-517, -3466],
      [-1751, -4162],
    ],
    [
      [36357, 673363],
      [-391, -2467],
      [-196, 1415],
      [587, 1052],
    ],
    [
      [34797, 676523],
      [213, -3286],
      [496, 2734],
      [387, -123],
      [69, -1817],
      [-313, -391],
      [-520, -2697],
      [580, 1301],
      [182, -1595],
      [-673, -869],
      [-156, -1540],
      [-280, 592],
      [46, -1877],
      [-401, 265],
      [-1841, -3579],
      [-465, -1377],
      [-652, 1241],
      [1054, 2460],
      [1094, 1206],
      [-224, 1758],
      [468, 338],
      [-151, 1493],
      [938, 464],
      [-875, 524],
      [-340, 2033],
      [352, 1704],
      [1012, 1038],
    ],
    [
      [26197, 724966],
      [756, -263],
      [-288, -1191],
      [-468, 1454],
    ],
    [
      [25148, 736553],
      [-442, -1993],
      [-390, 1138],
      [832, 855],
    ],
    [
      [39421, 678834],
      [125, -1114],
      [-550, -30],
      [-134, 746],
      [559, 398],
    ],
    [
      [36825, 680387],
      [730, -1661],
      [-578, -1781],
      [-479, 150],
      [-104, 2381],
      [431, 911],
    ],
    [
      [38083, 681762],
      [-169, -1483],
      [356, -62],
      [-384, -1861],
      [-336, 2175],
      [180, 1214],
      [353, 17],
    ],
    [
      [45571, 685391],
      [688, -1487],
      [-645, -37],
      [-43, 1524],
    ],
    [
      [46952, 694607],
      [166, -1890],
      [-229, -1028],
      [-301, 1739],
      [364, 1179],
    ],
    [
      [42853, 695877],
      [635, 14],
      [266, -1568],
      [335, -4089],
      [373, 437],
      [214, -1478],
      [-487, 52],
      [-124, 932],
      [-254, -1722],
      [-453, -779],
      [-609, 441],
      [-827, -359],
      [-641, -1585],
      [-62, -1249],
      [-802, -1375],
      [-569, 491],
      [-279, 2148],
      [72, 1348],
      [583, 1047],
      [405, 3984],
      [357, 1064],
      [527, -514],
      [982, 2495],
      [358, 265],
    ],
    [
      [48297, 698203],
      [503, -1314],
      [-281, -972],
      [-458, 2014],
      [236, 272],
    ],
    [
      [54719, 699114],
      [35, -1698],
      [-430, -1611],
      [395, 3309],
    ],
    [
      [53768, 699716],
      [-57, -3065],
      [-690, -2054],
      [16, 3270],
      [392, -758],
      [85, 2465],
      [254, 142],
    ],
    [
      [52387, 701641],
      [19, -2213],
      [-523, 1390],
      [504, 823],
    ],
    [
      [51366, 702388],
      [94, -1872],
      [270, 219],
      [343, -2313],
      [-187, -1094],
      [-921, 1412],
      [40, 2470],
      [361, 1178],
    ],
    [
      [52632, 703466],
      [224, -1137],
      [-482, 351],
      [258, 786],
    ],
    [
      [56428, 729876],
      [0, -164],
    ],
    [
      [56428, 729712],
      [1, -1271],
    ],
    [
      [56429, 728441],
      [-376, -902],
      [0, -1576],
      [-688, 44],
      [0, -1691],
      [-885, 0],
      [-1, -1609],
      [-854, -47],
      [-10, -3153],
      [262, -17],
      [11, -6313],
      [-174, -1752],
      [845, 0],
      [0, -4665],
    ],
    [
      [54559, 706760],
      [-221, 3584],
      [-465, 487],
      [-226, -1331],
      [-227, 543],
      [-128, -1791],
      [-942, -1954],
      [-501, -2558],
      [-203, 1961],
      [-156, -2246],
      [-321, -160],
      [-388, 1335],
      [-213, -1550],
      [-896, -1527],
      [-526, 87],
      [101, 2154],
      [312, 2133],
      [-635, 501],
      [-315, -1612],
      [38, -2336],
      [-533, -3433],
      [-423, 296],
      [235, -2134],
      [-319, -953],
      [-328, 1503],
      [-197, -2494],
      [-614, 575],
      [-124, 3742],
      [-387, 952],
      [-195, -964],
      [305, -1471],
      [135, -4091],
      [-322, 1731],
      [-87, -1650],
      [-581, -104],
      [-229, 2389],
      [-557, 961],
      [-44, -1927],
      [533, -1473],
      [-911, -2585],
      [204, 4150],
      [-79, 1484],
      [293, 977],
      [934, 318],
      [218, 2402],
      [379, 1261],
      [396, -106],
      [-126, 1804],
      [846, 4218],
      [1241, 3631],
      [1202, 1199],
      [764, 131],
      [636, 709],
      [-429, -1919],
      [670, -3198],
      [315, -565],
      [-272, 3531],
      [664, -282],
      [156, -1434],
      [616, -516],
      [78, 1328],
      [-802, 1925],
      [-146, 1183],
      [588, 5125],
      [1475, 4921],
      [1402, 2354],
      [1201, 3895],
    ],
    [
      [131839, 702693],
      [478, -1815],
      [-261, -3590],
      [-338, 3444],
      [121, 1961],
    ],
    [
      [133475, 712615],
      [472, -2306],
      [365, -3908],
      [-109, -3956],
      [-237, -2723],
      [-412, -1256],
      [-725, 1926],
      [513, 3110],
      [-666, -3077],
      [-842, 2748],
      [552, 2915],
      [-268, 716],
      [-19, 1677],
      [519, 1253],
      [-147, 1905],
      [1004, 976],
    ],
    [
      [60956, 762087],
      [579, 2152],
      [207, 3039],
    ],
    [
      [61742, 767278],
      [1861, 118],
      [1, -5313],
      [-2648, 4],
    ],
    [
      [51410, 765657],
      [-525, -4199],
      [-534, -542],
      [50, 2808],
      [1009, 1933],
    ],
    [
      [50361, 766039],
      [-741, -1613],
    ],
    [
      [49620, 764426],
      [-84, 1634],
      [825, -21],
    ],
    [
      [50463, 766367],
      [0, 3048],
      [447, 4],
      [153, 1512],
      [-4, 3289],
      [475, 13],
      [-1, 1618],
      [474, 68],
      [155, 1546],
      [1, 3271],
      [484, -4],
      [8, 3225],
      [637, 43],
      [12, 3203],
      [445, 89],
      [14, 3067],
      [182, 1579],
      [477, 25],
      [0, 3225],
      [493, -12],
      [187, 1563],
      [6, 3225],
      [507, 4],
      [7, 1610],
      [466, 16],
      [197, 3194],
      [2958, 4],
      [8, -1469],
      [509, -23],
      [-9, 1634],
      [498, 0],
      [0, 1661],
      [983, -7],
      [4, -1637],
      [3363, -86],
    ],
    [
      [64599, 804865],
      [6, -22904],
      [-455, -40],
      [-4, -1619],
      [-951, 22],
      [-1, -1609],
      [-472, 18],
      [-264, -3260],
      [-959, 50],
      [-1, -1622],
      [-475, 33],
      [8, -3299],
      [224, -22],
      [8, -3864],
    ],
    [
      [61263, 766749],
      [-1094, -1969],
      [-1642, -2596],
      [-488, 694],
      [-100, 1404],
      [-595, 1396],
      [200, 3649],
      [-346, -1592],
      [-442, -553],
      [-70, -2804],
      [-205, -87],
      [353, -4364],
      [-131, -1596],
      [-719, 558],
      [-940, 6038],
      [-710, 1488],
      [71, 1756],
      [-303, -417],
      [-191, -2035],
      [-311, -514],
      [-282, 1932],
      [-662, 238],
      [-84, 2398],
      [-285, 617],
      [-1091, -3021],
      [-733, -1002],
    ],
    [
      [61033, 724292],
      [684, -545],
      [-930, -219],
      [246, 764],
    ],
    [
      [71633, 804882],
      [114, -1587],
      [1, -6528],
      [-370, -12],
      [-9, -6508],
      [-350, -18],
      [4, -6265],
      [-356, 2],
      [-2, -1646],
      [-479, -11],
      [4, -3276],
      [-475, -9],
      [9, -1643],
      [-816, -49],
      [14, -3240],
      [-942, -29],
      [5, -3278],
      [149, -20],
      [-2, -6355],
      [154, -1634],
      [924, 0],
    ],
    [
      [69210, 762776],
      [7, -3471],
      [-453, -1843],
      [-736, -1056],
      [-774, -256],
      [-138, -1018],
      [-764, -799],
      [4, -5901],
      [-324, -916],
      [-689, -542],
      [-206, -2415],
      [-365, -3],
      [219, -1278],
      [-365, -813],
      [-334, 533],
      [-169, -1391],
      [-664, 236],
      [-689, -3490],
      [-377, -3219],
      [1107, -39],
    ],
    [
      [63500, 735095],
      [-534, -3229],
      [-498, 991],
      [-283, -2643],
      [-165, 1250],
      [-876, -3730],
      [-513, 1840],
      [-93, -1738],
      [-418, -1467],
      [297, -1220],
      [-476, -272],
      [-290, 1208],
      [-838, -1435],
      [-237, -1294],
      [835, 518],
      [-97, -1512],
      [-773, 886],
      [47, -1061],
      [-586, 328],
      [-1008, -4054],
      [729, 1416],
      [658, -1055],
      [-890, -5668],
      [-359, 2476],
      [4, -2388],
      [-569, 564],
      [-201, -1660],
      [-1170, -915],
      [-208, -1647],
      [-122, 1992],
      [-209, -168],
      [97, -2311],
      [-195, -2337],
    ],
    [
      [56429, 728441],
      [768, -812],
      [-200, 4018],
      [209, 1634],
      [848, 4197],
      [641, 1528],
      [416, 1983],
      [586, 1663],
      [449, -1864],
      [-119, 2914],
      [-249, -206],
      [-35, 2059],
      [292, 6723],
      [196, 1491],
      [338, 171],
      [-417, 2931],
      [211, 2814],
      [593, 2402],
    ],
    [
      [61742, 767278],
      [-123, 1251],
      [-356, -1780],
    ],
    [
      [64599, 804865],
      [4288, -12],
      [2746, 29],
    ],
    [
      [65699, 709069],
      [-504, 744],
      [538, 1338],
      [-34, -2082],
    ],
    [
      [70400, 724037],
      [-204, -1087],
      [-508, -19],
      [712, 1106],
    ],
    [
      [68718, 724702],
      [-249, -2013],
      [-521, -1694],
      [196, 2303],
      [574, 1404],
    ],
    [
      [69851, 724354],
      [-436, -1711],
      [-363, 957],
      [373, 1310],
      [426, -556],
    ],
    [
      [72263, 735888],
      [654, -276],
      [179, -1133],
      [-749, -602],
      [-369, -1913],
      [-213, 2136],
      [498, 1788],
    ],
    [
      [71587, 750055],
      [468, -1112],
      [218, -1969],
      [-712, 1537],
      [26, 1544],
    ],
    [
      [72121, 750653],
      [752, -1609],
      [143, 801],
      [402, -896],
      [-319, -1580],
      [498, -23],
      [268, 1873],
      [864, -1804],
      [-645, -1900],
      [256, -24],
      [-10, -2224],
      [878, 340],
      [-525, -3652],
      [-488, 209],
      [-628, 1356],
      [-204, -635],
      [516, -1217],
      [-216, -2411],
      [-309, -183],
      [-530, 1368],
      [-251, -544],
      [428, -942],
      [-369, -902],
      [-533, 226],
      [-638, -2935],
      [-448, 348],
      [322, -1679],
      [-993, -4198],
      [-693, -436],
      [211, 1779],
      [606, 2267],
      [-320, -122],
      [143, 1970],
      [-433, -1663],
      [-186, 436],
      [448, 2279],
      [-693, 794],
      [-670, -679],
      [120, -1420],
      [344, 1135],
      [575, 213],
      [-523, -3914],
      [-670, 1507],
      [-38, 3723],
      [-597, 1836],
      [85, 2427],
      [257, 1668],
      [757, 2434],
      [973, 102],
      [63, -1862],
      [628, -4462],
      [-147, 2063],
      [79, 2795],
      [-204, 1554],
      [706, -247],
      [-928, 1791],
      [8, 1470],
      [588, 1559],
      [419, -1165],
      [70, -2442],
      [174, 2478],
      [786, -908],
      [-91, 1984],
      [527, -1432],
      [-621, 2635],
      [26, 690],
    ],
    [
      [72294, 752633],
      [298, -243],
      [384, -2001],
      [-771, 1279],
      [-524, 168],
      [436, 1600],
      [177, -803],
    ],
    [
      [74768, 758552],
      [203, -1494],
      [403, 602],
      [-149, -1955],
      [513, 1173],
      [-64, -2164],
      [-263, -1096],
      [-683, 1715],
      [168, -2076],
      [-475, 81],
      [-333, -1022],
      [-37, 2484],
      [-168, -2623],
      [-420, -227],
      [-2, -1319],
      [-1063, 2147],
      [-114, 1830],
      [590, -260],
      [-304, 1148],
      [155, 946],
      [718, -477],
      [-72, 2281],
      [417, 1422],
      [495, -330],
      [341, -2521],
      [144, 1735],
    ],
    [
      [73815, 761335],
      [865, 1186],
      [-376, -3031],
      [-417, 471],
      [-72, 1374],
    ],
    [
      [72144, 766667],
      [-256, -165],
      [-279, -2562],
      [-649, -1819],
      [-613, -91],
      [-174, -2213],
      [-343, -43],
      [106, -2403],
      [-387, -650],
      [215, -848],
      [-602, -2221],
      [-49, -1387],
      [-356, 1779],
      [54, -1574],
      [-325, 275],
      [-479, -1769],
      [-793, 237],
      [-111, -2260],
      [-819, -2014],
      [-130, -1038],
      [-600, 882],
      [68, -2347],
      [-396, -579],
      [9, -1505],
      [-775, 384],
      [-35, -2432],
      [-520, 654],
      [-902, -2724],
      [-22, -1053],
      [573, 813],
      [-196, -1902],
      [142, -997],
    ],
    [
      [69210, 762776],
      [1368, 8],
      [-5, 1620],
      [1015, 10],
      [-7, 2250],
      [405, -14],
    ],
    [
      [71986, 766650],
      [158, 17],
    ],
    [
      [124853, 753145],
      [-549, -606],
      [-394, -1297],
      [-255, 1284],
    ],
    [
      [123655, 752526],
      [58, 1721],
    ],
    [
      [123713, 754247],
      [93, 682],
    ],
    [
      [123806, 754929],
      [27, -1188],
      [798, 113],
      [222, -709],
    ],
    [
      [124293, 757146],
      [749, -2547],
      [-1014, 763],
      [-164, 962],
      [429, 822],
    ],
    [
      [124853, 768717],
      [211, -1953],
      [1165, -2450],
      [370, -2181],
      [920, -3383],
      [-235, -797],
      [808, -4371],
    ],
    [
      [128092, 753582],
      [-945, -4191],
    ],
    [
      [127147, 749391],
      [-329, -1604],
    ],
    [
      [126818, 747787],
      [-267, -754],
    ],
    [
      [126551, 747033],
      [-348, 2791],
      [-600, 2743],
      [-36, 3375],
      [303, 711],
      [-120, 1531],
      [-569, -3645],
      [-841, 2824],
      [-590, 340],
      [-226, 2827],
      [-376, 2538],
      [-6, 5749],
    ],
    [
      [123142, 768817],
      [1711, -100],
    ],
    [
      [122507, 768865],
      [608, -35],
    ],
    [
      [123115, 768830],
      [94, -1237],
      [-284, -3190],
      [-329, 1968],
      [-89, 2494],
    ],
    [
      [125028, 711856],
      [427, 56],
      [-445, -1309],
      [18, 1253],
    ],
    [
      [130215, 719801],
      [107, -2063],
      [756, -2315],
      [-338, -626],
      [254, -2100],
      [-618, -805],
      [-298, 1242],
      [209, 718],
      [-487, 1319],
      [-280, -690],
      [-164, 3076],
      [325, 413],
      [163, 1718],
      [371, 113],
    ],
    [
      [129378, 721918],
      [249, -1134],
      [-115, -2167],
      [-590, -788],
      [-540, 1882],
      [182, 1807],
      [814, 400],
    ],
    [
      [130830, 716910],
      [-489, 1840],
      [74, 2204],
      [-143, 1693],
      [379, -778],
      [302, -2036],
      [326, 196],
      [295, -2757],
      [-436, -1557],
      [-308, 1195],
    ],
    [
      [128815, 728558],
      [1034, -4174],
      [-772, -1663],
      [-360, 265],
      [62, 2359],
      [-148, 3099],
      [184, 114],
    ],
    [
      [126153, 727422],
      [-66, 1104],
      [446, -558],
      [-459, -4949],
      [-272, -4761],
      [208, 396],
      [-146, -3602],
      [-196, 615],
      [-111, 3651],
      [-137, -5807],
      [-277, 1260],
      [-136, 3641],
      [146, 2921],
      [525, 49],
      [-191, 2101],
      [-591, 341],
      [101, 963],
      [-332, 2219],
      [-28, 2428],
      [338, -759],
      [186, 2187],
      [562, -1046],
      [430, -2394],
    ],
    [
      [126096, 733937],
      [1281, -1597],
      [902, -116],
      [343, -1458],
      [134, -4906],
      [-199, -1131],
      [-500, 1925],
      [-370, 3179],
      [207, -4437],
      [380, -383],
      [50, -1490],
      [-321, -1688],
      [-659, 1031],
      [14, -897],
      [-617, -308],
      [-202, 4527],
      [180, 2218],
      [-303, 119],
      [-132, 1682],
      [-636, 2448],
      [448, 1282],
    ],
    [
      [130329, 738612],
      [336, -2561],
      [-336, -2230],
      [901, -864],
      [-218, -3192],
      [698, -1259],
      [105, -3850],
      [709, 243],
      [1430, -3886],
    ],
    [
      [133954, 721013],
      [215, -2257],
      [-268, -1573],
      [-450, 60],
      [-523, -1243],
      [217, -2328],
      [-367, 57],
      [-534, 1418],
      [-526, -1313],
      [-53, -1739],
      [-415, -1188],
      [272, -1077],
      [-70, -1918],
      [-374, -1019],
      [-347, 1785],
    ],
    [
      [130731, 708678],
      [461, 1375],
      [54, 2694],
      [202, 40],
      [-10, 3932],
      [574, 625],
      [-464, 526],
      [-186, 2188],
      [-506, 719],
      [-71, 1280],
      [-436, 1390],
      [254, 2614],
      [-500, -62],
      [-115, 1369],
      [-779, 1724],
      [-462, 2944],
      [356, -607],
      [-84, 2467],
      [-160, -1289],
      [-1099, 1537],
      [-631, 1283],
    ],
    [
      [127129, 735427],
      [607, 1116],
      [481, -957],
      [392, 2691],
      [952, 1778],
      [768, -1443],
    ],
    [
      [120135, 747679],
      [-911, -75],
    ],
    [
      [119224, 747604],
      [-113, -9],
    ],
    [
      [119111, 747595],
      [-67, -5],
    ],
    [
      [119044, 747590],
      [175, 3136],
      [916, -3047],
    ],
    [
      [118824, 752542],
      [436, -1596],
      [-323, -3068],
      [-279, 1217],
      [166, 3447],
    ],
    [
      [127129, 735427],
      [-11, 1829],
      [1069, 643],
      [-784, 626],
      [-296, 2733],
      [133, 1559],
      [-543, 1078],
      [247, 1825],
      [1153, -2770],
      [-708, 2710],
      [-430, 722],
      [26, 2218],
    ],
    [
      [126985, 748600],
      [107, 525],
    ],
    [
      [127092, 749125],
      [55, 266],
    ],
    [
      [128092, 753582],
      [282, -2841],
      [560, -2991],
      [880, -6389],
      [515, -2749],
    ],
    [
      [124853, 753145],
      [18, 23],
    ],
    [
      [124871, 753168],
      [418, 537],
    ],
    [
      [125289, 753705],
      [-148, -676],
      [909, -5880],
      [23, -2256],
      [-213, 92],
      [-809, 7066],
      [78, -1904],
      [-258, 510],
      [590, -4994],
      [507, -2211],
      [51, -2144],
      [-521, 218],
      [645, -2298],
      [-310, -1473],
      [-533, 1666],
      [53, -3416],
      [-405, -716],
      [-196, -1446],
      [-544, -1275],
      [-190, 2104],
      [50, 2518],
      [367, 839],
      [-3, 1567],
      [-686, 5962],
      [69, 2065],
      [-286, 4181],
      [126, 722],
    ],
    [
      [120491, 750760],
      [-344, -2996],
    ],
    [
      [120147, 747764],
      [-1106, 4388],
      [166, 2241],
      [347, -765],
      [278, 1062],
      [445, -194],
      [427, 1507],
      [913, -2103],
      [-367, -2249],
      [68, -1158],
      [543, 2952],
      [316, -1048],
      [502, 33],
      [561, -2130],
      [-236, -1608],
      [-622, 1179],
      [757, -2767],
      [-774, -493],
      [-454, 1349],
      [-1420, 2800],
    ],
    [
      [123713, 754247],
      [-95, -1670],
      [-341, 1775],
      [-156, 4018],
      [685, -3441],
    ],
    [
      [114794, 765672],
      [1165, 1936],
    ],
    [
      [115959, 767608],
      [1943, 4721],
      [682, 110],
      [264, 1748],
    ],
    [
      [118848, 774187],
      [1665, -1619],
      [529, -1279],
      [326, -2198],
      [-480, -4537],
      [582, -819],
      [409, -1530],
      [231, -2153],
      [-489, -11],
    ],
    [
      [121621, 760041],
      [49, -2296],
      [-405, 961],
      [-824, -873],
      [206, 2553],
      [-684, 5535],
      [900, 1274],
      [-791, 545],
      [-394, 2927],
      [235, -3862],
      [-191, -2032],
      [-886, 1580],
      [-101, 2460],
      [-280, -1505],
      [-688, 1343],
      [-390, -1137],
      [761, -460],
      [670, -1418],
      [-346, -493],
      [774, -1580],
      [-470, -1770],
      [538, 847],
      [342, -513],
      [455, -4297],
      [-639, -1196],
      [-13, 909],
      [-621, -1129],
      [-298, 1069],
      [41, -2621],
      [-717, 2666],
      [-654, 322],
      [-1470, 4078],
      [-936, 3744],
    ],
    [
      [122912, 775799],
      [-900, -103],
    ],
    [
      [122012, 775696],
      [-90, 222],
    ],
    [
      [121922, 775918],
      [-936, 7001],
    ],
    [
      [120986, 782919],
      [676, 1289],
      [685, -1898],
      [571, -2465],
      [-202, -2534],
      [196, -1512],
    ],
    [
      [123142, 768817],
      [-27, 13],
    ],
    [
      [122507, 768865],
      [-495, 6831],
    ],
    [
      [122912, 775799],
      [198, -1219],
      [722, -593],
      [59, -1056],
      [546, -1130],
      [416, -3084],
    ],
    [
      [121922, 775918],
      [-145, -2315],
      [-358, 301],
      [513, -2663],
      [-50, -2481],
      [722, -6540],
      [-70, -1018],
      [302, -3962],
      [-98, -2100],
      [-512, -56],
      [-321, 1677],
      [-284, 3280],
    ],
    [
      [118848, 774187],
      [34, 3836],
      [479, -24],
      [183, 1767],
      [-318, 752],
      [1118, 1161],
      [642, 1240],
    ],
    [
      [120877, 738496],
      [389, -2216],
      [137, -2451],
      [-166, -1459],
      [-605, -380],
      [11, 6413],
      [234, 93],
    ],
    [
      [123928, 718235],
      [-2, -1610],
    ],
    [
      [123926, 716625],
      [-393, 1362],
      [-697, 5371],
      [278, 1647],
      [-447, -368],
      [-256, 2377],
      [77, 1437],
      [-515, 245],
      [-59, 2351],
      [446, 1679],
      [-481, 1224],
      [98, 2800],
      [-464, -184],
      [-452, 2135],
      [588, 60],
      [-326, 842],
      [146, 1989],
      [629, 565],
      [-77, -1197],
      [395, 257],
      [557, -1676],
      [490, 7],
      [-81, -2391],
      [463, -6886],
      [222, -4906],
      [-139, -7130],
    ],
    [
      [120135, 747679],
      [12, 85],
    ],
    [
      [120491, 750760],
      [1455, -3708],
      [395, -1834],
      [349, 1018],
      [504, 16],
      [258, -5147],
      [-689, -337],
      [-265, 1057],
      [-1794, 4644],
      [718, -3300],
      [33, -2583],
      [-443, -1626],
      [-365, 435],
      [-451, 2590],
      [538, -1592],
      [-959, 3723],
      [84, 790],
      [-635, 2698],
    ],
    [
      [40063, 839903],
      [770, -536],
      [-859, -2772],
      [-125, 3412],
      [214, -104],
    ],
    [
      [40964, 844347],
      [883, -2223],
      [79, -1915],
      [441, -2171],
      [-40, -1740],
      [-704, 2418],
      [-1774, 1537],
      [90, 1825],
      [445, 2301],
      [580, -32],
    ],
    [
      [42065, 848667],
      [903, -976],
      [563, -1663],
      [-706, -1821],
      [-339, -2285],
      [-564, -2],
      [-711, 2401],
      [-620, 812],
      [14, 1297],
      [651, 1761],
      [809, 476],
    ],
    [
      [46153, 848898],
      [568, 1],
      [10, -1610],
      [529, 1],
      [18, -1676],
      [524, -12],
      [-4, -1547],
      [3176, 0],
    ],
    [
      [50974, 844055],
      [-395, -2136],
      [-20, -6445],
      [-126, -25],
      [-6, -6437],
      [383, -5],
      [-10, -4748],
      [1058, -39],
    ],
    [
      [51858, 824220],
      [180, -1451],
      [604, -215],
      [-1456, -2220],
      [-980, -2588],
      [-900, -571],
      [-1213, -1602],
      [-1234, 662],
      [-494, 709],
      [-1854, -1999],
      [-659, 648],
      [-690, -2756],
      [-941, 669],
      [31, -2392],
      [-389, -2165],
      [-92, -1943],
      [-1046, -1523],
      [-746, 776],
      [-935, -1159],
    ],
    [
      [39044, 805100],
      [-172, 1112],
      [664, 830],
      [-1128, 3201],
      [-13, -2176],
      [-520, 182],
      [-234, 2567],
      [116, 849],
      [-614, 571],
      [-207, 2123],
      [418, 1187],
      [-481, 1394],
      [-546, -1187],
      [-95, 2644],
      [1073, 795],
      [-526, 599],
      [-413, 1924],
      [1380, 582],
      [-440, 3023],
      [251, 2549],
      [1109, 5289],
      [619, 2101],
      [570, -119],
      [933, 3977],
      [823, -896],
      [965, -4031],
      [-209, 4581],
      [-457, 1793],
      [12, 1197],
      [848, 640],
      [78, 1725],
      [725, 1785],
      [524, -1399],
      [778, 464],
      [610, 2670],
      [668, 1252],
    ],
    [
      [46896, 791982],
      [261, -1285],
      [-270, -665],
      [9, 1950],
    ],
    [
      [36192, 795959],
      [180, -1952],
      [581, 363],
      [562, -947],
      [-123, -4413],
      [448, -2402],
      [-1265, -1162],
      [-486, -2126],
      [-685, 2000],
      [-493, -131],
      [-1055, 2670],
      [-324, -89],
      [-644, 1570],
      [-206, 2197],
      [272, 858],
      [1286, -625],
      [56, 1283],
      [1008, 2111],
      [905, -187],
      [-17, 982],
    ],
    [
      [17304, 799216],
      [126, -1745],
      [804, -2158],
      [616, -183],
      [448, -1365],
      [-1028, 65],
      [-1000, 3083],
      [-322, 373],
      [356, 1930],
    ],
    [
      [39654, 805231],
      [136, -1106],
      [641, 589],
      [-101, -1482],
      [649, 41],
      [525, -808],
      [139, -1889],
      [-482, -1833],
      [-426, -534],
      [213, -1308],
      [-375, -571],
      [-319, -2845],
      [-422, 135],
      [-775, 2413],
      [439, 1980],
      [-612, -774],
      [-640, 997],
      [1252, 2977],
      [-199, 1359],
      [401, 589],
      [-44, 2070],
    ],
    [
      [51858, 824220],
      [3536, -32],
      [10, 1609],
      [2034, -5],
      [-4, 1617],
      [4083, 203],
      [1, -1838],
      [5893, -29],
      [3388, 4],
      [-14, 1716],
      [962, 59],
      [3, 1571],
      [903, -11],
      [192, 1616],
    ],
    [
      [72845, 830700],
      [-3, -16190],
    ],
    [
      [72842, 814510],
      [-1303, 45],
      [-14, -5422],
      [124, -21],
      [-16, -4230],
    ],
    [
      [50463, 766367],
      [-102, -328],
    ],
    [
      [49620, 764426],
      [-139, -1468],
      [-1061, -1905],
      [-97, 1293],
      [-1035, 446],
      [862, 1205],
      [617, 1650],
      [-307, 160],
      [-238, 3846],
      [703, 1138],
      [-113, 1059],
      [-716, -1213],
      [-383, 2903],
      [195, 2895],
      [687, 2210],
      [-441, 2484],
      [-273, 3082],
      [-346, 1471],
      [-416, 3726],
      [137, 1138],
      [-767, 3115],
      [393, 1259],
      [285, 3791],
      [-129, 577],
      [-402, -4189],
      [-434, -1001],
      [311, -2534],
      [-69, -3036],
      [-406, -1085],
      [-1628, -2447],
      [-1520, -851],
      [-1012, 789],
      [-258, 2086],
      [267, 429],
      [-760, 1956],
      [-694, 3087],
      [-87, 1177],
      [383, 1597],
      [-110, 1291],
      [397, 379],
      [-183, 1458],
      [364, 50],
      [277, 1573],
      [411, 342],
      [228, 1752],
      [702, -118],
      [-16, -3249],
      [425, 240],
      [607, 2498],
      [-384, 1562],
      [-1050, 843],
      [629, 147],
      [400, 1001],
      [-600, 267],
      [-481, -1345],
      [-125, 3490],
      [-458, -1834],
      [195, -1307],
      [-1242, -569],
      [-216, 1520],
      [-538, -627],
      [-174, 927],
      [-743, -457],
    ],
    [
      [91002, 847056],
      [9, -3988],
      [145, -1795],
      [8, -11469],
      [-1548, 26],
      [56, -1646],
      [46, -12769],
      [-726, 14],
      [-2, -927],
      [-3493, 32],
    ],
    [
      [85497, 814534],
      [-2026, -32],
      [-141, 995],
    ],
    [
      [83330, 815497],
      [-404, 601],
      [-735, -459],
      [-672, -2022],
      [-283, -2604],
      [-1335, 126],
      [-328, 2166],
      [-15, -1517],
      [-1058, -1636],
    ],
    [
      [78500, 810152],
      [-4, 1152],
      [-1002, 21],
      [-4, 3164],
      [-4648, 21],
    ],
    [
      [72845, 830700],
      [0, 8044],
    ],
    [
      [72845, 838744],
      [3088, -82],
      [10, 1347],
      [6637, 9993],
      [4096, -25],
      [12, 2587],
      [2946, 186],
    ],
    [
      [89634, 852750],
      [1353, 59],
      [15, -5753],
    ],
    [
      [86653, 868811],
      [757, -1570],
      [2145, 14],
    ],
    [
      [89555, 867255],
      [87, -6323],
      [-8, -8182],
    ],
    [
      [72845, 838744],
      [1580, 8236],
      [-1, 2534],
      [-536, -83],
      [-516, 926],
      [-152, 2433],
      [36, 3161],
      [1704, 93],
      [24, 3109],
      [496, -4],
      [22, 3291],
      [619, 95],
      [97, 1432],
      [588, 58],
      [151, -985],
      [625, -419],
      [1536, 6612],
      [4538, -401],
      [2997, -21],
    ],
    [
      [85497, 814534],
      [-37, -10730],
      [-760, -83],
      [1, -2090],
    ],
    [
      [84701, 801631],
      [-828, 17],
      [9, 1987],
    ],
    [
      [83882, 803635],
      [-435, 1931],
      [-460, -308],
      [-1062, 1669],
      [-902, 2471],
      [707, 3049],
      [1129, 2748],
      [471, 302],
    ],
    [
      [97244, 787641],
      [-970, -3439],
      [63, 995],
      [907, 2444],
    ],
    [
      [87222, 789210],
      [-181, -1772],
      [-422, -410],
      [603, 2182],
    ],
    [
      [86635, 788749],
      [223, 2200],
      [232, -961],
      [-455, -1239],
    ],
    [
      [86658, 791573],
      [-369, -3115],
      [-330, 1166],
      [699, 1949],
    ],
    [
      [89188, 794370],
      [360, -14],
      [-243, -1523],
      [503, 637],
      [-806, -2688],
      [-814, -3927],
      [156, -1241],
      [-672, -1392],
      [-694, -249],
      [130, 1479],
      [1249, 4306],
      [686, 2953],
      [145, 1659],
    ],
    [
      [86769, 795107],
      [-71, -1859],
      [-324, 817],
      [395, 1042],
    ],
    [
      [90857, 797012],
      [511, -366],
      [-57, -1276],
      [666, 500],
      [124, -712],
      [-950, -1371],
      [-533, -1467],
      [-218, 828],
      [596, 1566],
      [-651, -377],
      [-25, 892],
      [537, 1783],
    ],
    [
      [87021, 792777],
      [237, 3392],
      [601, -294],
      [-413, -4995],
      [-425, 1897],
    ],
    [
      [84910, 786500],
      [234, 323],
      [68, 9012],
      [-286, 660],
    ],
    [
      [84926, 796495],
      [584, 1623],
      [519, -1019],
      [523, 1881],
      [285, -1347],
      [30, -1922],
      [-996, -2869],
      [605, -1030],
      [-717, -2215],
      [-215, -2677],
      [-634, -420],
    ],
    [
      [92921, 798974],
      [-9, -545],
      [-1147, -2071],
      [-222, 1208],
      [1378, 1408],
    ],
    [
      [88339, 800844],
      [383, -576],
      [-511, -771],
      [128, 1347],
    ],
    [
      [86236, 802132],
      [283, -1843],
      [-421, 600],
      [138, 1243],
    ],
    [
      [84951, 797243],
      [-1, 3142],
    ],
    [
      [84950, 800385],
      [697, 2105],
      [-36, -2998],
      [388, 2501],
      [244, -2671],
      [-390, -1481],
      [-418, 814],
      [-484, -1412],
    ],
    [
      [86676, 805210],
      [325, -2117],
      [-624, -286],
      [299, 2403],
    ],
    [
      [84951, 801507],
      [-250, 124],
    ],
    [
      [91002, 847056],
      [976, 234],
      [63, 648],
      [2682, 18],
      [0, -1651],
      [1595, -292],
      [4037, -37],
      [350, -2273],
      [-454, -2972],
      [436, -1269],
      [-270, -2840],
      [732, -302],
      [295, 1951],
      [1174, -336],
      [-15, -1588],
      [537, -65],
      [-10, -1550],
      [466, -24],
      [-40, -6434],
      [413, -697],
      [4, -4218],
      [2303, 15],
    ],
    [
      [106276, 823374],
      [-1, -28114],
    ],
    [
      [106275, 795260],
      [-589, 822],
      [-1600, -31],
      [4, 1610],
      [-3847, -34],
      [-2014, -8871],
      [13, -855],
    ],
    [
      [98242, 787901],
      [-1021, 2713],
      [47, 775],
      [-1005, -33],
      [-276, 1650],
      [-683, 426],
      [369, 3141],
      [18, 2628],
      [-612, -1565],
      [-178, -1401],
      [-659, -2022],
      [-457, 1505],
      [-714, 1071],
      [-573, -359],
      [943, 3800],
      [-1116, -764],
      [259, 1691],
      [-925, -1558],
      [532, 2101],
      [-1009, -888],
      [-677, 62],
      [-94, 801],
      [1014, 956],
      [446, 1115],
      [-1050, -704],
      [-516, 1833],
      [317, 3213],
      [1053, 57],
      [-177, 903],
      [-744, -283],
      [-1116, -3389],
      [-359, 1426],
      [-193, -1467],
      [-586, -1116],
      [-376, 408],
      [6, 3472],
      [-402, -3497],
      [-547, -1350],
      [-586, 2075],
      [324, 2590],
      [504, 2086],
      [123, 1742],
      [-770, -3524],
      [-194, -1549],
      [-275, 1236],
      [-413, -4415],
      [-943, -1986],
    ],
    [
      [71670, 777076],
      [233, -1112],
      [-578, -408],
      [345, 1520],
    ],
    [
      [79473, 776784],
      [-279, -2074],
      [90, 2387],
      [189, -313],
    ],
    [
      [75949, 796147],
      [-337, -1236],
      [224, 2635],
      [113, -1399],
    ],
    [
      [84951, 801507],
      [-1, -1122],
    ],
    [
      [84951, 797243],
      [-25, -748],
    ],
    [
      [84910, 786500],
      [-1245, 807],
      [240, 1474],
      [-371, -167],
      [-386, -2038],
      [-206, 3702],
      [-29, -2396],
      [-481, -1666],
      [-173, -1732],
      [-183, 2620],
      [-197, -5326],
      [-848, 3357],
      [-39, -1545],
      [420, -800],
      [-371, -1104],
      [-68, -1569],
      [-635, -858],
      [208, 3841],
      [-673, -5212],
      [-363, 1475],
      [21, -2088],
      [-388, -20],
      [-756, -4011],
      [-74, 2111],
      [-347, -2231],
      [-660, 934],
      [-173, -859],
      [-799, -887],
      [-18, 1049],
      [-623, 715],
      [205, 2741],
      [546, 1203],
      [718, 23],
      [17, 1317],
      [737, 963],
      [-66, 842],
      [764, 2927],
      [-372, 25],
      [-1227, -2967],
      [-387, 246],
      [-629, 2275],
      [460, 4880],
      [786, 3381],
      [112, 2715],
      [231, 475],
      [102, 3025],
      [-410, 3227],
      [967, 1254],
      [994, 2799],
      [846, 1832],
      [515, -1977],
      [448, -352],
      [796, 1052],
      [346, -814],
      [1490, -887],
      [200, -646],
    ],
    [
      [78500, 810152],
      [-529, -2655],
      [-889, -709],
      [-891, -2927],
      [268, -2258],
      [-402, 43],
      [-550, -1341],
      [-153, -1302],
      [-582, -1608],
      [-230, -3803],
      [-474, -1479],
      [-1035, 411],
      [707, -1449],
      [308, -1771],
      [-381, -2913],
      [-287, -680],
      [-1149, -274],
      [-159, -989],
      [796, 2],
      [-164, -2224],
      [-453, -1068],
      [-562, 296],
      [227, 1314],
      [-337, 1411],
      [33, -1984],
      [-398, -2576],
      [-671, -197],
      [279, -1966],
      [-1055, -1700],
      [-61, -2764],
      [-263, -488],
      [166, -2860],
      [277, 1050],
      [1025, 35],
      [413, -1682],
      [604, -1164],
      [58, -1233],
    ],
    [
      [106275, 795260],
      [0, -1588],
      [1343, -1612],
      [173, 1644],
      [1330, -2348],
      [801, 2853],
      [1723, 322],
      [-345, -4919],
      [404, -1740],
      [964, -1648],
      [226, -2564],
      [2839, -9771],
      [300, -4810],
      [-74, -1471],
    ],
    [
      [114794, 765672],
      [-73, 1821],
      [-694, 2541],
      [-828, 1292],
      [163, 1473],
      [-568, -631],
      [-1881, 4464],
      [-679, 903],
      [-783, 1959],
      [551, 424],
      [542, 2604],
      [-421, 3603],
      [333, 1618],
      [530, -2532],
      [164, 177],
      [-480, 2635],
      [-360, 838],
      [-174, -1871],
      [-463, -2242],
      [-1472, -2407],
      [-1544, 735],
      [-1623, 2726],
      [527, 2089],
      [-371, 3153],
      [-375, -856],
      [437, -1616],
      [-624, -1272],
      [-2761, 2302],
      [-2696, -711],
      [-929, -990],
    ],
    [
      [106279, 946179],
      [-1, -49412],
    ],
    [
      [106278, 896767],
      [-929, -2457],
      [-130, -1600],
      [-1321, -3359],
      [-907, 419],
      [-783, -1693],
      [-450, 408],
      [-349, -3372],
      [-325, -1449],
      [-654, -436],
      [-1188, -2953],
      [58, -3794],
      [-686, -1988],
      [-821, 654],
    ],
    [
      [97793, 875147],
      [-205, 2151],
      [358, 3325],
      [-316, 719],
      [513, 846],
      [-159, 1080],
      [-963, -156],
      [-338, -900],
      [-1244, 1394],
      [-886, -1195],
      [-579, 221],
      [-639, -953],
      [-286, 1202],
      [374, 853],
      [-1289, 1884],
      [-166, 1202],
      [398, 1802],
      [-514, 869],
      [-625, -618],
      [-460, -1403],
      [-1061, -1321],
      [-1073, -10],
      [-601, -1159],
      [-3102, 6],
      [49, -11363],
      [273, 317],
      [853, -2473],
      [548, -2656],
    ],
    [
      [50974, 844055],
      [10, 4776],
      [492, 29],
      [-58, 4949],
      [548, 10],
      [4, 1425],
      [1103, 13],
      [-17, 1635],
      [486, 23],
      [-3, 1682],
      [645, -27],
      [-89, 4840],
      [-627, -26],
      [-61, 12986],
      [569, 33],
      [-9, 3217],
      [557, 13],
      [-4, 6309],
      [539, -18],
      [-5, 4867],
      [-575, -8],
    ],
    [
      [54479, 890783],
      [-5, 8105],
      [1752, -14],
      [2, 3093],
      [2984, 34],
      [5, 6543],
      [2343, 38],
      [1, -3290],
      [1183, 54],
      [6, 3236],
      [1194, 12],
      [-3, -1576],
      [600, 61],
      [1, -1630],
      [1168, -18],
      [135, 4836],
      [1805, -29],
      [9, 2778],
      [1841, -6],
      [167, 1643],
      [-10, 6646],
      [-437, -20],
      [-10, 1684],
      [-1234, 59],
      [4, 4809],
      [169, 1664],
      [-624, -6],
      [17, 1606],
      [-634, -27],
      [7, 1645],
      [-621, 10],
      [151, 4212],
    ],
    [
      [66445, 936935],
      [6419, -2],
      [8315, -30],
      [7299, 18],
      [3888, 6],
      [-10, 9096],
      [4663, 309],
      [4790, -300],
      [4470, 147],
    ],
    [
      [97793, 875147],
      [-1158, -1860],
      [-1437, -204],
      [-262, -1096],
      [-804, -486],
      [-476, -1291],
      [-576, 796],
      [-732, -1427],
      [-433, 43],
      [-568, -2104],
      [-1792, -263],
    ],
    [
      [106278, 896767],
      [-2, -73393],
    ],
    [
      [79659, 983425],
      [-186, -1458],
      [-395, 1373],
      [581, 85],
    ],
    [
      [66445, 936935],
      [-1, 525],
      [-2610, -23],
      [-8, 1900],
      [-2541, -186],
      [-15, 1669],
      [-3117, 28],
      [-4016, 91],
      [-18, 1253],
      [-1129, 58],
      [26, -1250],
      [-2705, 21],
      [-18, 1268],
      [-1182, 3],
      [11, -1279],
      [-1329, 46],
      [-7, 1283],
      [-2020, 165],
      [0, -1272],
      [-4957, -98],
      [15, -3782],
      [-2411, 70],
    ],
    [
      [38413, 937425],
      [-1695, 2109],
      [-331, 1545],
      [-1320, 2645],
      [530, 804],
      [403, 3101],
      [38, 5653],
      [2426, -416],
      [2905, 1304],
      [905, 1071],
      [1222, 2916],
      [1184, 4349],
      [538, 5906],
      [-329, 649],
      [1231, 3476],
      [365, 2385],
      [846, 2065],
      [658, 2577],
      [521, -1587],
      [1146, 663],
      [1622, 2412],
      [1470, 3493],
      [882, 527],
      [-302, -3710],
      [454, -2622],
      [-62, 3461],
      [380, 972],
      [-494, 2433],
      [-541, 46],
      [1260, 3273],
      [1370, 1209],
      [-523, -1007],
      [415, -852],
      [3297, 1404],
      [1421, 2118],
      [704, 1933],
      [1322, 4593],
      [409, 808],
      [627, -1392],
      [915, -472],
      [117, -1342],
      [1298, -97],
      [153, -1576],
      [-589, -1821],
      [-1263, -1269],
      [606, -390],
      [-400, -1319],
      [1303, -54],
      [456, 606],
      [-100, 1673],
      [703, 1368],
      [285, 1695],
      [248, -1305],
      [706, 928],
      [682, -1755],
      [-272, -1975],
      [1375, -2189],
      [693, 2096],
      [1211, 87],
      [987, 691],
      [970, -830],
      [279, -2575],
      [299, 2682],
      [989, -1071],
      [-561, -2519],
      [5, -1532],
      [1009, -525],
      [-1482, -681],
      [2474, 169],
      [-499, -2280],
      [1552, -378],
      [326, -1080],
      [240, 1607],
      [1280, 271],
      [-195, -2495],
      [904, 1573],
      [845, 524],
      [665, 1466],
      [1963, -425],
      [886, -1703],
      [688, 50],
      [281, -1515],
      [1081, 464],
      [877, -2131],
      [2068, -1579],
      [1439, 785],
      [1493, -1038],
      [433, 638],
      [1637, -3240],
      [1773, -387],
      [359, 912],
      [3399, 1866],
      [1396, -1344],
      [1104, -2165],
      [387, -1505],
      [1266, -1051],
      [1131, -2960],
      [450, 854],
      [588, -630],
      [-1, -21362],
    ],
    [
      [54479, 890783],
      [-578, 0],
      [-10, -1617],
      [-6227, 42],
      [-4789, -36],
      [-1, 3200],
      [-592, 6],
      [0, 3250],
      [-769, -10],
      [8, 6456],
      [-205, 26],
      [-5, 6430],
      [-226, 1957],
    ],
    [
      [41085, 910487],
      [1578, 205],
      [202, -2556],
      [-311, -1225],
      [120, -2178],
      [-263, -1028],
      [215, -1763],
      [590, -1196],
      [375, 540],
      [1138, -445],
      [965, 699],
      [321, -1177],
      [667, -159],
      [982, 797],
      [546, -2004],
      [189, 2045],
      [810, 3513],
      [796, -959],
      [609, 527],
      [-495, 1902],
      [-1494, 1020],
      [-695, -1271],
      [194, 3234],
      [-839, 3465],
      [-836, 788],
      [-422, 2410],
      [386, 1581],
      [446, 30],
      [876, -3094],
      [-154, -2498],
      [448, -1913],
      [931, -1944],
      [1114, 1832],
      [1074, -3146],
      [1511, 331],
      [131, 1770],
      [-331, 2163],
      [-932, -162],
      [-460, 1241],
      [-885, -263],
      [-253, -1860],
      [-717, -285],
      [-1099, 3517],
      [232, 3056],
      [871, 1516],
      [-974, 1717],
      [-1121, -1076],
      [-1026, -153],
      [-389, 1249],
      [-528, -564],
      [-2241, 1824],
      [-397, 5492],
      [-677, 3578],
      [-1097, 2080],
      [-2353, 5735],
    ],
    [
      [46592, 855663],
      [131, -1524],
      [-910, 378],
      [779, 1146],
    ],
    [
      [20846, 858392],
      [155, -1918],
      [1767, -2169],
      [1395, 2514],
      [527, -232],
      [506, -1478],
      [153, -2243],
      [1093, -1044],
      [302, -1308],
      [1483, -327],
      [899, -741],
      [-443, -2851],
      [-1263, 664],
      [-756, -2937],
      [125, -925],
      [-599, -398],
      [122, 1508],
      [-581, 2032],
      [-993, 769],
      [95, 1587],
      [-980, 2254],
      [-777, 1164],
      [-1179, -1499],
      [-406, -1269],
      [-853, 1089],
      [-307, 2233],
      [515, 5525],
    ],
    [
      [40155, 909679],
      [-378, -812],
      [-1698, -1707],
      [2076, 2519],
    ],
    [
      [46153, 848898],
      [640, 3384],
      [128, 1617],
      [836, -1042],
      [-394, -958],
      [1743, 358],
      [1080, 995],
      [1027, 4968],
      [-512, 5797],
      [-74, 2808],
      [-783, 2760],
      [-729, 205],
      [138, 2075],
      [1089, -245],
      [770, 2178],
      [52, 1988],
      [-320, 1965],
      [-760, 1982],
      [-574, -2906],
      [-898, 82],
      [-597, -1411],
      [-710, -399],
      [-196, -1163],
      [-840, -1617],
      [-252, -2634],
      [-446, -1194],
      [-161, 3173],
      [-951, 2836],
      [-459, -1140],
      [680, -1515],
      [9, -1792],
      [-1367, 2870],
      [-2018, -58],
      [-2019, -2251],
      [-807, 901],
      [-2508, 1784],
      [-689, 2790],
      [279, 1486],
      [-107, 1445],
      [-749, 1760],
      [-607, 2842],
      [664, -527],
      [574, 1254],
      [301, 1828],
      [876, -777],
      [601, -2903],
      [499, -323],
      [640, 1345],
      [-382, 828],
      [-828, 195],
      [-166, -711],
      [-669, 2577],
      [-1941, 1613],
      [-1534, 482],
      [-1420, 2818],
      [-465, 461],
      [786, 2442],
      [578, 22],
      [0, 1593],
      [845, 1741],
      [325, -909],
      [992, 2481],
      [418, 1888],
      [1044, 1672],
      [643, -1028],
      [1215, 99],
      [319, 783],
      [-1041, 1553],
      [504, 1846],
      [720, 1193],
      [1099, 506],
      [107, -679],
      [853, 2834],
      [831, 668],
    ],
    [
      [129335, 693546],
      [271, -1010],
      [-142, -1955],
      [-385, 2864],
      [256, 101],
    ],
    [
      [133465, 694933],
      [144, -1619],
      [-433, -1116],
      [-420, 1401],
      [709, 1334],
    ],
    [
      [129050, 698432],
      [385, -3029],
      [-187, -665],
      [-381, 868],
      [233, 1060],
      [-50, 1766],
    ],
    [
      [128271, 699419],
      [264, -2151],
      [-73, -1620],
      [899, -5179],
      [112, -1526],
      [-465, 199],
      [-651, 4105],
      [-450, 3522],
      [53, 2603],
      [311, 47],
    ],
    [
      [132791, 699517],
      [329, -1553],
      [-33, -2741],
      [-745, 304],
      [319, 2136],
      [-199, 1261],
      [329, 593],
    ],
    [
      [127590, 701351],
      [340, -1447],
      [-98, -960],
      [-490, -120],
      [14, 2204],
      [234, 323],
    ],
    [
      [126995, 702605],
      [287, -601],
      [-587, -1676],
      [300, 2277],
    ],
    [
      [127349, 703974],
      [-507, -707],
      [254, 1440],
      [253, -733],
    ],
    [
      [126549, 705223],
      [400, -333],
      [-240, -1814],
      [-293, 1014],
      [133, 1133],
    ],
    [
      [127577, 705503],
      [192, -1281],
      [-213, -946],
      [-252, 1520],
      [273, 707],
    ],
    [
      [126975, 710441],
      [673, -1209],
      [-593, 136],
      [46, -1542],
      [-470, 1638],
      [344, 977],
    ],
    [
      [127738, 711890],
      [119, -2595],
      [-286, 1329],
      [167, 1266],
    ],
    [
      [132951, 712558],
      [-517, -394],
      [513, 1288],
      [4, -894],
    ],
    [
      [127377, 716539],
      [387, -260],
      [-15, -2495],
      [-236, 369],
      [-706, -1255],
      [-240, -1211],
      [-257, 709],
      [519, 3076],
      [548, 1067],
    ],
    [
      [126878, 720140],
      [451, -579],
      [750, 57],
      [384, -2788],
      [-191, -1469],
      [383, -1822],
      [435, 266],
      [497, -1761],
      [456, -2620],
      [47, -3196],
      [267, 734],
      [169, -1960],
      [371, -1718],
      [-850, 2044],
      [-552, -1434],
      [684, 586],
      [160, -2342],
      [299, 487],
      [445, -2791],
      [-464, -922],
      [454, -352],
      [205, 1480],
      [89, -3147],
      [-431, -827],
      [396, -512],
      [101, -2502],
      [-398, -59],
      [421, -1193],
      [-183, -2479],
      [-613, 702],
      [-449, 4336],
      [-561, -35],
      [258, 2640],
      [-329, -710],
      [132, 1822],
      [-300, -502],
      [-725, 2251],
      [-725, 264],
      [28, 1947],
      [514, -21],
      [-361, 1667],
      [236, 2818],
      [-439, -873],
      [-448, 796],
      [643, 3530],
      [-238, 1458],
      [-150, 5160],
      [-814, 306],
      [-187, 1877],
      [133, 1386],
    ],
    [
      [133954, 721013],
      [796, -683],
      [442, -1861],
      [432, -443],
      [118, -1890],
      [501, -841],
      [396, 492],
      [280, -2136],
      [-1, -1873],
      [-414, -2628],
      [68, -3449],
      [429, -5569],
      [-359, -1662],
      [-236, -2416],
      [-441, -2702],
      [-867, -2699],
      [-213, 1932],
      [-286, -2060],
      [-269, 663],
      [-207, 4192],
      [490, 1686],
      [-537, -477],
      [-156, 1948],
      [582, 2169],
      [-76, 7458],
      [-887, 5088],
      [-486, -470],
      [-72, 869],
      [-950, -2430],
      [-396, -118],
      [344, -1651],
      [-545, -5283],
      [-588, 1664],
      [-115, 2845],
    ],
    [
      [312327, 9345],
      [187, -607],
    ],
    [
      [312514, 8738],
      [-15, -1699],
    ],
    [
      [312499, 7039],
      [-350, 67],
    ],
    [
      [312149, 7106],
      [17, 912],
    ],
    [
      [312166, 8018],
      [43, 812],
    ],
    [
      [312209, 8830],
      [118, 515],
    ],
    [
      [312084, 13310],
      [6, -1330],
    ],
    [
      [312090, 11980],
      [-147, -91],
    ],
    [
      [311943, 11889],
      [-157, 1158],
    ],
    [
      [311786, 13047],
      [223, 739],
    ],
    [
      [312009, 13786],
      [75, -476],
    ],
    [
      [312224, 10808],
      [178, -1249],
    ],
    [
      [312402, 9559],
      [-75, -214],
    ],
    [
      [312209, 8830],
      [-202, 83],
    ],
    [
      [312007, 8913],
      [-56, 276],
    ],
    [
      [311951, 9189],
      [-19, 1837],
    ],
    [
      [311932, 11026],
      [292, -218],
    ],
    [
      [313107, 15065],
      [-14, -2951],
    ],
    [
      [313093, 12114],
      [-156, -33],
    ],
    [
      [312937, 12081],
      [5, 365],
    ],
    [
      [312942, 12446],
      [-35, 2687],
    ],
    [
      [312907, 15133],
      [200, -68],
    ],
    [
      [312774, 6524],
      [70, -1308],
    ],
    [
      [312844, 5216],
      [-339, -42],
    ],
    [
      [312505, 5174],
      [62, 1491],
    ],
    [
      [312567, 6665],
      [207, -141],
    ],
    [
      [312709, 10759],
      [26, -1200],
    ],
    [
      [312735, 9559],
      [-333, 0],
    ],
    [
      [312224, 10808],
      [123, 645],
    ],
    [
      [312347, 11453],
      [362, -694],
    ],
    [
      [315123, 11875],
      [54, -128],
    ],
    [
      [315177, 11747],
      [78, -1803],
    ],
    [
      [315255, 9944],
      [-163, -1770],
    ],
    [
      [315092, 8174],
      [-119, 1033],
    ],
    [
      [314973, 9207],
      [-63, 762],
    ],
    [
      [314910, 9969],
      [144, 1736],
    ],
    [
      [315054, 11705],
      [69, 170],
    ],
    [
      [314670, 11208],
      [37, -330],
    ],
    [
      [314707, 10878],
      [60, -642],
    ],
    [
      [314767, 10236],
      [-199, -737],
    ],
    [
      [314568, 9499],
      [-72, 1129],
    ],
    [
      [314496, 10628],
      [174, 580],
    ],
    [
      [314052, 6833],
      [264, -476],
    ],
    [
      [314316, 6357],
      [-19, -702],
    ],
    [
      [314297, 5655],
      [-318, 75],
    ],
    [
      [313979, 5730],
      [73, 1103],
    ],
    [
      [312567, 6665],
      [-68, 374],
    ],
    [
      [312514, 8738],
      [144, 137],
    ],
    [
      [312658, 8875],
      [116, -2351],
    ],
    [
      [312942, 12446],
      [-192, 449],
    ],
    [
      [312750, 12895],
      [-16, -17],
    ],
    [
      [312734, 12878],
      [-7, 2191],
    ],
    [
      [312727, 15069],
      [180, 64],
    ],
    [
      [313370, 8907],
      [-65, -415],
    ],
    [
      [313305, 8492],
      [-209, 82],
    ],
    [
      [313096, 8574],
      [-80, -28],
    ],
    [
      [313016, 8546],
      [-82, 705],
    ],
    [
      [312934, 9251],
      [27, 1093],
    ],
    [
      [312961, 10344],
      [345, -244],
      [64, -1193],
    ],
    [
      [313305, 8492],
      [-14, -2847],
    ],
    [
      [313291, 5645],
      [-154, 398],
    ],
    [
      [313137, 6043],
      [-41, 2531],
    ],
    [
      [314464, 12204],
      [-98, -1501],
    ],
    [
      [314366, 10703],
      [-105, -151],
    ],
    [
      [314261, 10552],
      [-75, 983],
    ],
    [
      [314186, 11535],
      [35, 729],
    ],
    [
      [314221, 12264],
      [129, 637],
    ],
    [
      [314350, 12901],
      [114, -697],
    ],
    [
      [314016, 15104],
      [-14, -2136],
    ],
    [
      [314002, 12968],
      [-54, 9],
    ],
    [
      [313948, 12977],
      [-196, -375],
    ],
    [
      [313752, 12602],
      [-27, 1006],
    ],
    [
      [313725, 13608],
      [25, 1394],
    ],
    [
      [313750, 15002],
      [266, 102],
    ],
    [
      [313604, 15085],
      [-4, -1775],
    ],
    [
      [313600, 13310],
      [-8, -955],
    ],
    [
      [313592, 12355],
      [-45, -184],
    ],
    [
      [313547, 12171],
      [-454, -57],
    ],
    [
      [313107, 15065],
      [497, 20],
    ],
    [
      [314264, 15070],
      [-76, -2600],
    ],
    [
      [314188, 12470],
      [-186, 498],
    ],
    [
      [314016, 15104],
      [248, -34],
    ],
    [
      [315464, 13512],
      [1, -2420],
    ],
    [
      [315465, 11092],
      [-66, 434],
    ],
    [
      [315399, 11526],
      [-159, 1610],
    ],
    [
      [315240, 13136],
      [-103, 1307],
    ],
    [
      [315137, 14443],
      [122, 138],
    ],
    [
      [315259, 14581],
      [205, -1069],
    ],
    [
      [314938, 14053],
      [76, -2457],
    ],
    [
      [315014, 11596],
      [-176, -329],
    ],
    [
      [314838, 11267],
      [34, 2700],
    ],
    [
      [314872, 13967],
      [51, 292],
    ],
    [
      [314923, 14259],
      [15, -206],
    ],
    [
      [315375, 10330],
      [63, -1609],
    ],
    [
      [315438, 8721],
      [6, -427],
    ],
    [
      [315444, 8294],
      [-234, -827],
    ],
    [
      [315210, 7467],
      [-116, 640],
    ],
    [
      [315094, 8107],
      [-2, 67],
    ],
    [
      [315255, 9944],
      [120, 386],
    ],
    [
      [313784, 8891],
      [255, -1303],
    ],
    [
      [314039, 7588],
      [13, -755],
    ],
    [
      [313979, 5730],
      [-242, -150],
    ],
    [
      [313737, 5580],
      [-39, 3313],
    ],
    [
      [313698, 8893],
      [16, 19],
    ],
    [
      [313714, 8912],
      [70, -21],
    ],
    [
      [315465, 11092],
      [-90, -762],
    ],
    [
      [315177, 11747],
      [222, -221],
    ],
    [
      [313725, 9097],
      [-11, -185],
    ],
    [
      [313698, 8893],
      [-269, 126],
    ],
    [
      [313429, 9019],
      [231, 2602],
    ],
    [
      [313660, 11621],
      [65, -2524],
    ],
    [
      [314361, 14911],
      [31, -1699],
    ],
    [
      [314392, 13212],
      [-42, -311],
    ],
    [
      [314221, 12264],
      [-33, 206],
    ],
    [
      [314264, 15070],
      [97, -159],
    ],
    [
      [312306, 11771],
      [41, -318],
    ],
    [
      [311932, 11026],
      [-107, 569],
    ],
    [
      [311825, 11595],
      [118, 294],
    ],
    [
      [312090, 11980],
      [216, -209],
    ],
    [
      [312937, 12081],
      [24, -1737],
    ],
    [
      [312934, 9251],
      [-27, -13],
    ],
    [
      [312907, 9238],
      [-172, 321],
    ],
    [
      [312709, 10759],
      [41, 2136],
    ],
    [
      [312505, 5174],
      [-359, -115],
    ],
    [
      [312146, 5059],
      [3, 2047],
    ],
    [
      [314634, 8728],
      [-21, -893],
    ],
    [
      [314613, 7835],
      [-92, -362],
    ],
    [
      [314521, 7473],
      [-160, 1420],
    ],
    [
      [314361, 8893],
      [201, 525],
    ],
    [
      [314562, 9418],
      [72, -690],
    ],
    [
      [312295, 14639],
      [81, -1251],
    ],
    [
      [312376, 13388],
      [-70, -1617],
    ],
    [
      [312084, 13310],
      [211, 1329],
    ],
    [
      [312007, 8913],
      [159, -895],
    ],
    [
      [312146, 5059],
      [-281, 903],
      [86, 3227],
    ],
    [
      [315210, 7467],
      [60, -308],
    ],
    [
      [315270, 7159],
      [221, -1461],
    ],
    [
      [315491, 5698],
      [-308, -32],
    ],
    [
      [315009, 6674],
      [174, -1008],
    ],
    [
      [315009, 6674],
      [81, 1384],
    ],
    [
      [315090, 8058],
      [4, 49],
    ],
    [
      [314186, 11535],
      [-217, -675],
    ],
    [
      [313969, 10860],
      [-21, 2117],
    ],
    [
      [312009, 13786],
      [157, 1824],
    ],
    [
      [312166, 15610],
      [129, -971],
    ],
    [
      [313137, 6043],
      [-273, -821],
    ],
    [
      [312864, 5222],
      [152, 3324],
    ],
    [
      [314838, 11267],
      [-131, -389],
    ],
    [
      [314670, 11208],
      [-8, 753],
    ],
    [
      [314662, 11961],
      [28, 1346],
    ],
    [
      [314690, 13307],
      [76, 779],
    ],
    [
      [314766, 14086],
      [106, -119],
    ],
    [
      [313547, 12171],
      [113, -550],
    ],
    [
      [313429, 9019],
      [-59, -112],
    ],
    [
      [314973, 9207],
      [-339, -479],
    ],
    [
      [314562, 9418],
      [6, 81],
    ],
    [
      [314767, 10236],
      [143, -267],
    ],
    [
      [314776, 6994],
      [-112, -1632],
    ],
    [
      [314664, 5362],
      [-367, 293],
    ],
    [
      [314316, 6357],
      [205, 1116],
    ],
    [
      [314613, 7835],
      [163, -841],
    ],
    [
      [312679, 13385],
      [55, -507],
    ],
    [
      [312376, 13388],
      [303, -3],
    ],
    [
      [315090, 8058],
      [-314, -1064],
    ],
    [
      [314662, 11961],
      [-198, 243],
    ],
    [
      [314392, 13212],
      [143, 200],
    ],
    [
      [314535, 13412],
      [155, -105],
    ],
    [
      [312679, 13385],
      [-109, 1772],
    ],
    [
      [312570, 15157],
      [157, -88],
    ],
    [
      [313752, 12602],
      [-160, -247],
    ],
    [
      [313600, 13310],
      [125, 298],
    ],
    [
      [314496, 10628],
      [-130, 75],
    ],
    [
      [313604, 15085],
      [146, -83],
    ],
    [
      [315240, 13136],
      [-117, -1261],
    ],
    [
      [315054, 11705],
      [-40, -109],
    ],
    [
      [314938, 14053],
      [199, 390],
    ],
    [
      [315043, 5450],
      [-34, 1224],
    ],
    [
      [315043, 5450],
      [-379, -88],
    ],
    [
      [312864, 5222],
      [-20, -6],
    ],
    [
      [312658, 8875],
      [249, 363],
    ],
    [
      [314001, 9350],
      [196, -57],
    ],
    [
      [314197, 9293],
      [164, -400],
    ],
    [
      [314039, 7588],
      [-38, 1762],
    ],
    [
      [313784, 8891],
      [217, 459],
    ],
    [
      [313969, 10860],
      [-244, -1763],
    ],
    [
      [313737, 5580],
      [-446, 65],
    ],
    [
      [314261, 10552],
      [-64, -1259],
    ],
    [
      [312166, 15610],
      [404, -453],
    ],
    [
      [315579, 8262],
      [226, -901],
    ],
    [
      [315805, 7361],
      [-150, -1087],
    ],
    [
      [315655, 6274],
      [-385, 885],
    ],
    [
      [315444, 8294],
      [135, -32],
    ],
    [
      [314849, 14669],
      [-83, -583],
    ],
    [
      [314535, 13412],
      [108, 1312],
    ],
    [
      [314643, 14724],
      [206, -55],
    ],
    [
      [318309, 12804],
      [169, -865],
      [-544, 829],
      [375, 36],
    ],
    [
      [316158, 12800],
      [-233, -1215],
    ],
    [
      [315925, 11585],
      [-15, -114],
    ],
    [
      [315910, 11471],
      [23, 1757],
    ],
    [
      [315933, 13228],
      [225, -428],
    ],
    [
      [315714, 13935],
      [-106, -832],
    ],
    [
      [315608, 13103],
      [-144, 409],
    ],
    [
      [315259, 14581],
      [455, -646],
    ],
    [
      [315655, 6274],
      [-164, -576],
    ],
    [
      [315910, 11471],
      [-22, -197],
    ],
    [
      [315888, 11274],
      [-160, -129],
    ],
    [
      [315728, 11145],
      [-34, 40],
    ],
    [
      [315694, 11185],
      [-86, 1918],
    ],
    [
      [315714, 13935],
      [219, -707],
    ],
    [
      [315888, 11274],
      [293, -1348],
    ],
    [
      [316181, 9926],
      [-216, -625],
    ],
    [
      [315965, 9301],
      [-242, 491],
    ],
    [
      [315723, 9792],
      [5, 1353],
    ],
    [
      [315723, 9792],
      [-144, -1530],
    ],
    [
      [315438, 8721],
      [211, 2085],
    ],
    [
      [315649, 10806],
      [45, 379],
    ],
    [
      [315649, 10806],
      [-184, 286],
    ],
    [
      [316256, 11346],
      [-331, 239],
    ],
    [
      [316158, 12800],
      [154, 393],
      [-56, -1847],
    ],
    [
      [316256, 11346],
      [125, -1004],
      [-200, -416],
    ],
    [
      [315965, 9301],
      [-160, -1940],
    ],
    [
      [316936, 9055],
      [328, -560],
      [-757, -909],
      [-91, 685],
      [520, 784],
    ],
    [
      [318661, 1986],
      [376, -1085],
      [-740, -781],
      [24, 1181],
      [340, 685],
    ],
    [
      [314361, 14911],
      [282, -187],
    ],
    [
      [123928, 718235],
      [-2, -1610],
    ],
    [
      [119111, 747595],
      [-67, -5],
    ],
    [
      [124871, 753168],
      [418, 537],
    ],
    [
      [71986, 766650],
      [158, 17],
    ],
    [
      [56428, 729876],
      [0, -164],
    ],
    [
      [126985, 748600],
      [107, 525],
    ],
    [
      [126818, 747787],
      [-267, -754],
    ],
    [
      [61672, 65312],
      [272, -274],
    ],
    [
      [314849, 14669],
      [74, -410],
    ],
    [
      [315183, 5666],
      [-140, -216],
    ],
    [
      [311825, 11595],
      [-39, 1452],
    ],
    [
      [253891, 243332],
      [-3, 424],
    ],
    [
      [297006, 439075],
      [21, -79],
    ],
    [
      [273150, 218795],
      [-218, 2571],
      [-109, 2556],
      [341, -5125],
    ],
    [
      [273164, 218797],
      [-14, -2],
    ],
    [
      [275036, 140768],
      [-16, 1],
    ],
    [
      [274871, 140335],
      [3, -76],
    ],
    [
      [268559, 210807],
      [-4, -26],
    ],
    [
      [258410, 236520],
      [0, 355],
    ],
    [
      [272837, 223331],
      [-14, 0],
    ],
    [
      [274173, 206960],
      [19, 0],
    ],
    [
      [275584, 178509],
      [-145, -584],
    ],
    [
      [275994, 173067],
      [-63, 2],
    ],
    [
      [275922, 173070],
      [-37, 2],
    ],
    [
      [272518, 253791],
      [20, 18],
    ],
    [
      [272125, 254343],
      [81, -132],
    ],
    [
      [256680, 462075],
      [-142, -2897],
      [-384, -10769],
    ],
    [
      [256154, 448409],
      [-883, -7],
      [0, -983],
    ],
    [
      [254488, 462036],
      [2192, 39],
    ],
    [
      [256154, 448409],
      [1065, -7],
    ],
    [
      [249637, 222999],
      [-31, 137],
    ],
    [
      [248195, 214699],
      [-70, -748],
    ],
    [
      [247232, 214954],
      [0, 260],
    ],
    [
      [247232, 215317],
      [15, 318],
    ],
    [
      [301353, 460902],
      [-185, -724],
    ],
    [
      [301101, 460137],
      [28, -397],
    ],
    [
      [301793, 457791],
      [116, -445],
    ],
    [
      [285769, 400843],
      [123, 37],
    ],
    [
      [264771, 527003],
      [-281, 49],
    ],
    [
      [267568, 452013],
      [-852, -4103],
      [-108, -17],
    ],
    [
      [266608, 447893],
      [-26, -6],
    ],
    [
      [266582, 447887],
      [-16, -1],
    ],
    [
      [266566, 447886],
      [-30, -6],
    ],
    [
      [266536, 447880],
      [-6, -1],
    ],
    [
      [256680, 462075],
      [-375, 16538],
      [12, 3500],
      [187, 5880],
      [609, 12037],
      [445, 5943],
      [542, 3980],
      [721, 3116],
      [-1423, 3912],
      [-936, -37],
      [-588, -3704],
      [-267, -718],
      [-95, -2358],
      [-427, 343],
    ],
    [
      [247207, 537862],
      [1281, 13508],
    ],
    [
      [248488, 551370],
      [1322, 13439],
    ],
    [
      [249810, 564809],
      [408, -727],
      [1834, 5024],
      [865, 1148],
      [2109, -5688],
      [7666, -20686],
      [271, -4739],
      [574, -3230],
      [509, 900],
      [683, 396],
      [-47, -2079],
      [105, -3301],
      [426, -3438],
      [360, 1156],
      [708, -243],
      [385, -2004],
      [-455, -3293],
      [3005, -8985],
      [1103, -32532],
      [-926, -12370],
      [-34, -3060],
      [-156, -2886],
      [-328, -976],
      [-521, -3379],
      [-656, -1036],
      [-180, -1472],
      [-60, -3681],
      [110, -1615],
    ],
    [
      [252129, 567028],
      [-1583, -4120],
      [166, -1627],
      [582, 1037],
      [-142, 551],
      [872, 1783],
      [743, 3451],
      [-638, -1075],
    ],
    [
      [248488, 551370],
      [-1963, 212],
      [-2260, -6869],
      [-1513, -4210],
    ],
    [
      [249572, 564554],
      [238, 255],
    ],
    [
      [282592, 309414],
      [10, -65],
    ],
    [
      [289029, 404122],
      [-16, 45],
    ],
    [
      [293679, 426705],
      [-79, 470],
    ],
    [
      [286839, 484138],
      [-17, 6],
    ],
    [
      [276897, 457877],
      [-3, 4558],
    ],
    [
      [276894, 462435],
      [2305, 5850],
      [8, 2378],
      [-220, 544],
      [-164, 5160],
      [-363, 3502],
      [1422, 3362],
      [1923, 0],
      [3353, 0],
      [997, 8619],
      [109, 340],
    ],
    [
      [267767, 442766],
      [-50, -4],
    ],
    [
      [267568, 452013],
      [114, -1687],
      [1085, -3482],
      [787, 0],
      [3209, 9884],
      [2039, 2175],
    ],
    [
      [274802, 458903],
      [-16, -6460],
    ],
    [
      [274802, 458903],
      [1209, 1289],
      [883, 2243],
    ],
    [
      [275537, 276578],
      [-263, -628],
    ],
    [
      [273916, 275250],
      [-11, -827],
    ],
    [
      [273920, 274333],
      [-20, -184],
    ],
    [
      [228015, 167092],
      [147, -2633],
      [-252, 2651],
      [105, -18],
    ],
    [
      [228180, 184437],
      [70, -94],
    ],
    [
      [229313, 193901],
      [29, 366],
    ],
    [
      [230757, 204203],
      [-190, 587],
    ],
    [
      [229828, 205636],
      [-9, -8],
    ],
    [
      [229431, 197365],
      [-30, -1032],
    ],
    [
      [228487, 190418],
      [-16, -156],
    ],
    [
      [286226, 362368],
      [89, -504],
    ],
    [
      [283710, 365528],
      [174, -301],
    ],
    [
      [286242, 357857],
      [63, -1057],
    ],
    [
      [286254, 356334],
      [304, 311],
    ],
    [
      [286558, 356645],
      [136, 36],
    ],
    [
      [284458, 394060],
      [-9, -959],
    ],
    [
      [151607, 571679],
      [12, 2074],
      [1993, -3664],
      [1312, -1342],
      [815, 1107],
      [372, 2584],
      [-289, 2342],
      [-137, 2706],
      [722, 1361],
      [0, 1194],
      [-873, 3181],
      [648, -1],
    ],
    [
      [156182, 583221],
      [153, 1],
    ],
    [
      [156335, 583222],
      [770, 0],
    ],
    [
      [253979, 502714],
      [-8, -1],
    ],
    [
      [242231, 539612],
      [9, 872],
    ],
    [
      [242245, 540522],
      [75, 212],
    ],
    [
      [242329, 540727],
      [187, 592],
    ],
    [
      [242526, 541319],
      [224, -823],
    ],
    [
      [288652, 406259],
      [12, 7],
    ],
    [
      [288613, 408309],
      [-8, 430],
    ],
    [
      [290026, 386798],
      [-57, 0],
    ],
    [
      [287992, 379951],
      [5, 43],
    ],
    [
      [287430, 351414],
      [50, -2],
    ],
    [
      [287480, 351412],
      [118, 4],
    ],
    [
      [287598, 351416],
      [-258, -2],
    ],
    [
      [280248, 301457],
      [-33, 176],
    ],
    [
      [156182, 583221],
      [153, 1],
    ],
  ],
  transform: {
    scale: [0.00035892617892657177, 0.00005371486851395936],
    translate: [-179.1473400003406, 17.67439566600018],
  },
};
