import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MultiLineChartWrapper, MLChart } from "./MultiLineChart.styles";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate } from "react-router-dom";

const MultiLineChart = (props) => {

  //const { data, segtabid, isPercentage } = props;
  //let root = null;
  const navigate = useNavigate();
  const loadData = (root) => {
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true
      })
    );
    chart.get("colors").set("colors", [
      am5.color("#1F77B4"),
      am5.color("#FF7F27"),
      am5.color("#2CA02C"),
      am5.color("#D62728"),
      am5.color("#9467BD"),
      am5.color("#8C564B"),
      am5.color("#E377C3")
    ]);
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    var dataObject = props.data.data;
    let data = [];
    dataObject.map((item, index) => {
      let obj = {
        name: item.month
      }
      item.data.map((item, index) => {
        obj[item.name] = parseInt(item.value);
      })
      data.push(obj);
    })

    var xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("location", 0.5);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5
    });
    // xRenderer.grid.template.disabled = true;
    xRenderer.grid.template.setAll({
      forceHidden: true,
    });
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          //inversed: true
        })
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    // for (var i = 0; i < data.length; i++) {
    function makeSeries(name, fieldName) {
      
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "name",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]" + name + ": {valueY}"
            // labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
          })
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      // series.bullets.push(function () {
      //     return am5.Bullet.new(root, {
      //         sprite: am5.Circle.new(root, {
      //             radius: 5,
      //             fill: series.get("fill")
      //         })
      //     });
      // });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set("setStateOnChildren", true);
      series.states.create("hover", {});

      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});

      series.strokes.template.states.create("hover", {
        strokeWidth: 4
      });
      series.data.setAll(data);
      series.appear(1000);
    }

    var prevItem = "";
    var prevName = ""
    if (data.length > 0) {
      for (const [key, value] of Object.entries(data[0])) {


        var keyName;
        if (key !== "name") {
          var currItem = key.split(" - ")[0];
          if (currItem !== prevItem) {
            prevItem = currItem;
            console.log("make series", key)
            makeSeries(key, key, keyName, false);
          } else {
            makeSeries(key, key, keyName, false);
          }
        }
      }
    }
    //  createSeries("Italy", "italy");
    // createSeries("Germany", "germany");
    // createSeries("UK", "uk");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal",
    //   marginBottom: 20
    // }));

    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create("hover", {});

    legend.itemContainers.template.events.on("pointerover", function (e) {
      e.target.dataItem.dataContext.hover();
    });
    legend.itemContainers.template.events.on("pointerout", function (e) {
      e.target.dataItem.dataContext.unhover();
    });

    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
  };

  const chartdiv = useRef(null);
  var chartDiv = null;
  useEffect(() => {
    chartDiv = am5.Root.new("chartdiv");
    // chartdiv.current = chartDiv;
    // if (root) {
    //   root.dispose();
    // }
    // root = am5.Root.new("chartdiv");
    loadData(chartDiv);

    return () => {
      chartDiv.dispose();
    };
  }, [props.data]);

  return (
    <MultiLineChartWrapper>
      <MLChart id="chartdiv"></MLChart>
    </MultiLineChartWrapper>
  );
};

MultiLineChart.propTypes = {
  // bla: PropTypes.string,
};

MultiLineChart.defaultProps = {
  // bla: 'test',
};

export default MultiLineChart;
