import React, {useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import TableC from "../../containers/TableView";
import LineandBarMixChart from "../../containers/LineandBarMixChart"
import './AccoutAndHCPtTab.css'

export default function AccoutAndHCPtTab() {
    const [key, setKey] = useState('home');
  
    return (
      <Tabs 
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        
      >
        <Tab eventKey="home" title="  Account Details">
        LineandBarMixChart
{/* <LineandBarMixChart/> */}
 
        </Tab>
        <Tab eventKey="profile" title=" HCP Information">
        <TableC/>
        </Tab>
    
      </Tabs>
    );
  }
  