import styled from 'styled-components';

export const VennDiagramWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 20px 10px 20px;
`;

export const VDChart = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
`;
