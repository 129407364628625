import React from 'react';
import PropTypes from 'prop-types';
import { HcpReportingWrapper } from './HcpReporting.styles';
import CllReportingTable from '../../../components/CllReportingTable';
import config from "../../../config";

const HcpReporting = (props) => (
  <HcpReportingWrapper>
    <CllReportingTable dataType="hcp" />
  </HcpReportingWrapper>
);

HcpReporting.propTypes = {
  // bla: PropTypes.string,
};

HcpReporting.defaultProps = {
  // bla: 'test',
};

export default HcpReporting;
