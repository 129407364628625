import React from "react";
import PropTypes from "prop-types";
import { HealthcareDisparityWrapper } from "./HealthcareDisparity.styles";
import HealthCareDisparityMap from "../../../components/HealthCareDisparityMap";

const HealthcareDisparity = (props) => {
  return (
    <HealthcareDisparityWrapper>
      <HealthCareDisparityMap />
    </HealthcareDisparityWrapper>
  );
};

HealthcareDisparity.propTypes = {
  // bla: PropTypes.string,
};

HealthcareDisparity.defaultProps = {
  // bla: 'test',
};

export default HealthcareDisparity;
