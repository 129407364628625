import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  About,
  Dashboard,
  Home,
  Insights,
  Reporting,
  HomeView,
  TableC,
  BarGraph,
  Patients1LTable,
  LineandBarMixChart,
  AccoutAndHCPtTab, VennChart
} from "../containers";

import {
  HealthCareDisparityInfo,
  GeoDistributionInfo,
  AccountsInfo,
  Accounts,
  GeoDistribution,
  HealthcareDisparity,
  InsightsHome,
  SalesRegion,
} from "../containers/Insights";
import NotFound from "../containers/NotFound/NotFound";
import {
  ChildAccountReporting,
  HcpReporting,
  ParentAccountReporting,
} from "../containers/Reporting";
import AccountTrending from "../containers/Trending/AccountTrending/AccountTrending";
import AccountTrendingInfo from "../containers/Trending/AccountTrendingInfo/AccountTrendingInfo";
import Distributions from "../containers/Trending/Distributions";
import PhysicianTrending from "../containers/Trending/PhysicianTrending/PhysicianTrending";
import TrendingHome from "../containers/Trending/TrendingHome";

const AppRoutes = () => (
  <>
    <div style={{ padding: 30 }}></div>
    <Routes>
      <Route path="/" element={<TrendingHome />} />
      <Route path="/trending" element={<TrendingHome />} />
      <Route path="/trending/home" element={<TrendingHome />} />
      <Route path="/trending/distributions/:type/:accountId" element={<Distributions />} />
      <Route path="about" element={<About />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="insights" element={<InsightsHome />} />
      <Route path="insights/accounts" element={<Accounts />} />
      <Route path="HomeView" element={<HomeView />} />

     <Route path="VennChart" element={<VennChart/>} />
     
 
      <Route path="AccoutAndHCPtTab" element={<AccoutAndHCPtTab/>} />

      <Route path="Patients1LTable" element={<Patients1LTable />} />
      <Route path="TableC" element={<TableC />} />
      <Route path="BarGraph" element={<BarGraph />} />

      <Route
        path="insights/accounts-info/:type/:accountId"
        element={<AccountsInfo />}
      />
      <Route path="insights/geo-distribution" element={<GeoDistribution />} />
      <Route
        path="insights/geo-distribution-info/:id"
        element={<GeoDistributionInfo />}
      />
      <Route
        path="insights/hd-info/:type/:id"
        element={<HealthCareDisparityInfo />}
      />
      <Route path="insights/sales-regions" element={<SalesRegion />} />
      <Route
        path="insights/healthcare-disparity"
        element={<HealthcareDisparity />}
      />
      <Route path="reporting/hcp" element={<HcpReporting />} />
      <Route
        path="reporting/parent-accounts"
        element={<ParentAccountReporting />}
      />
      <Route
        path="reporting/child-accounts"
        element={<ChildAccountReporting />}
      />
      <Route path="trending" element={<TrendingHome />} />
      <Route path="trending/home" element={<TrendingHome />} />
      <Route path="trending/:drug/:type" element={<AccountTrending />} />
      <Route
        path="trending/:drug/account/:accountId"
        element={<AccountTrendingInfo />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);

export default AppRoutes;
