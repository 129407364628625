import * as am5 from "@amcharts/amcharts5";
import am5geodata_region_usa_usaCountiesLow from "@amcharts/amcharts5-geodata/region/usa/usaCountiesLow";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Spin } from "antd";
import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import hcpData from "./hcpData";
import { USBubbleMapContainer, USBubbleMapWrapper } from "./USBubbleMap.styles";

const USBubbleMap = (props) => {
  const { url, containerId, onClick } = props;
  const [loading, setLoading] = useState(true);
  let root = null;
  useEffect(() => {
    if (root) {
      root.dispose();
    }
    root = am5.Root.new("usbubbleMap");
    loadData(hcpData);

    return () => {};
  }, []);

  const loadData = (data) => {
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        wheelY: "zoom",
        minZoomLevel: 1,
        maxZoomLevel: 60,
        projection: am5map.geoAlbersUsa(),
        layout: root.horizontalLayout,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0xeeeeee),
          fillOpacity: 0.9,
        }),
      })
    );
    var zoomControl = am5map.ZoomControl.new(root, {});

    chart.set("zoomControl", zoomControl);
    var _homeButton = am5.Button.new(root, {
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      icon: am5.Graphics.new(root, {
        svgPath:
          "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8",
        fill: am5.color(0xffffff),
      }),
    });
    _homeButton.get("background").setAll({
      fill: am5.color(0x740049),
      fillOpacity: 0.7,
      hover: am5.color(0x740049),
    });
    _homeButton
      .get("background")
      .states.create("hover", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 0.4,
      });

    _homeButton
      .get("background")
      .states.create("down", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 1,
      });
    var homeButton = zoomControl.children.moveValue(_homeButton, 0);
    zoomControl.plusButton.get("background").setAll({
      fill: am5.color(0x740049),
      fillOpacity: 0.7,
      hover: am5.color(0x740049),
    });
    zoomControl.plusButton
      .get("background")
      .states.create("hover", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 0.4,
      });

    zoomControl.plusButton
      .get("background")
      .states.create("down", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 1,
      });
    zoomControl.minusButton.get("background").setAll({
      fill: am5.color(0x740049),
      fillOpacity: 0.7,
    });
    zoomControl.minusButton
      .get("background")
      .states.create("hover", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 0.4,
      });

    zoomControl.minusButton
      .get("background")
      .states.create("down", {})
      .setAll({
        fill: am5.color(0x740049),
        fillOpacity: 1,
      });

    homeButton.events.on("click", function () {
      chart.goHome();
    });

    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_region_usa_usaCountiesLow,
        valueField: "count",
        idField: "fips",
        calculateAggregates: true,
        fill: am5.color(0xdedede),
        fillOpacity: 1,
      })
    );
    polygonSeries.data.setAll(data);
    polygonSeries.show();

    var circleTemplate = am5.Template.new({
      tooltipText: "{name}, {STATE}, {CNTRY}: {value}",
    });
    var circleTemplate1 = am5.Template.new({
      tooltipText: "{name}, {STATE}, {CNTRY}: {value}",
    });

    var bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        calculateAggregates: true,
        valueField: "count",
        polygonIdField: "fips",
      })
    );
    var bubbleSeries1 = chart.series.push(
      am5map.MapPointSeries.new(root, {
        calculateAggregates: true,
        valueField: "count",
        polygonIdField: "fips",
      })
    );

    bubbleSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});

      var circle = container.children.push(
        am5.Circle.new(
          root,
          {
            radius: 10,
            fill: am5.color(0x740049),
            fillOpacity: 0.8,
            zIndex: 100,
          },
          circleTemplate
        )
      );
      //setTimeout(()=>{
      var label = container.children.push(
        am5.Label.new(root, {
          text: "{name}",
          fill: am5.color(0x740049),
          opacity: 0.3,
          fontSize: 7,
          populateText: true,
          centerX: am5.p50,
          paddingTop: 30,
          centerY: am5.p50,
          textAlign: "center",
          zIndex: 1,
        })
      );
      //}, 1)

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });

    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        min: 3,
        max: 30,
        key: "radius",
        dataField: "value",
      },
    ]);

    circleTemplate.events.on("pointerover", function (ev) {
      //alert(ev.target.dataItem.dataContext.id);
      console.log(ev.target);
    });
    circleTemplate.events.on("click", function (ev) {
      var id = ev.target.dataItem.dataContext.id;
      console.log("polygonSeries", id);
      onClick(id);
    });
    root.container.events.onAll(function (ev) {
      console.log("something happened ", ev);
    });

    bubbleSeries.data.setAll(data);
    bubbleSeries.show();
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <USBubbleMapWrapper>
        <USBubbleMapContainer id="usbubbleMap"></USBubbleMapContainer>
      </USBubbleMapWrapper>
    </Spin>
  );
};

USBubbleMap.propTypes = {
  // bla: PropTypes.string,
};

USBubbleMap.defaultProps = {
  // bla: 'test',
};

export default USBubbleMap;
