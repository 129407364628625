import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedBreastNetworkSiteCategory: 'All',
    selectedBreastSiteCategory: 'All',
    selectedBreastSiteSubCategory: 'All',
    selectedBreastTaskForceSiteCategory: 'All',
    selectedCategory: 'All',
    selectedCountry: '',
    selectedCountryCode: '',
    selectedSiteType: 'All',
    selectedIndication: 1,
    selectedTop30SiteCategory: 'All',
    selectedFilterCategory: "Site Profile",
    selectedSiteCategory: "",
    selectedSiteProfile: "",
    selectedAdminIndication: "Breast Cancer"
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        selectBreastNetworkSiteCategory: (state, action) => { 
            state.selectedBreastNetworkSiteCategory = action.payload;
        },
        selectBreastSiteCategory: (state, action) => {
            state.selectedBreastSiteCategory = action.payload;
        },
        selectBreastSiteSubCategory: (state, action) => {
            state.selectedBreastSiteSubCategory = action.payload;
        },
        selectBreastTaskForceSiteCategory: (state, action) => {
            state.selectedBreastTaskForceSiteCategory = action.payload;
        },
        selectCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        selectCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        selectCountryCode: (state, action) => {
            state.selectedCountryCode = action.payload;
        },
        selectIndication: (state, action) => {
            state.selectedIndication = action.payload;
        },
        selectSiteType: (state, action) => {
            state.selectedSiteType = action.payload;
        },
        selectTop30SiteCategory: (state, action) => {
            state.selectedTop30SiteCategory = action.payload;
        },
        selectFilterCategory: (state, action) => {
            state.selectFilterCategory = action.payload;
        },
        selectSiteCategory: (state, action) => {
            state.selectedSiteCategory = action.payload;
        },
        selectSiteProfile: (state, action) => {
            state.selectedSiteProfile = action.payload;
        },
        selectAdminIndication: (state, action) => {
            state.selectedAdminIndication = action.payload;
        },
    }
});

export const getSelectedBreastSiteCategory = (state) => state.filter.selectedBreastSiteCategory;
export const getSelectedBreastSiteSubCategory = (state) => state.filter.selectedBreastSiteSubCategory;
export const getSelectedBreastNetworkSiteCategory = (state) => state.filter.selectedBreastNetworkSiteCategory;
export const getSelectedBreastTaskForceSiteCategory = (state) => state.filter.selectedBreastTaskForceSiteCategory;
export const getSelectedCategory = (state) => state.filter.selectedCategory;
export const getSelectedCountry = (state) => state.filter.selectedCountry;
export const getSelectedCountryCode = (state) => state.filter.selectedCountryCode;
export const getSelectedIndication = (state) => state.filter.selectedIndication;
export const getSelectedSiteType = (state) => state.filter.selectedSiteType;
export const getSelectedTop30SiteCategory = (state) => state.filter.selectedTop30SiteCategory;
export const getSelectedFilterCategory = (state) => state.filter.selectFilterCategory;
export const getSelectedSiteCategory = (state) => state.filter.selectedSiteCategory;
export const getSelectedSiteProfile = (state) => state.filter.selectedSiteProfile;
export const getSelectedAdminIndication = (state) => state.filter.selectedAdminIndication;
export const {
    selectBreastNetworkSiteCategory,
    selectBreastSiteCategory,
    selectBreastSiteSubCategory,
    selectBreastTaskForceSiteCategory,
    selectCategory,
    selectCountry,
    selectCountryCode,
    selectIndication,
    selectSiteType,
    selectTop30SiteCategory,
    selectFilterCategory,
    selectSiteCategory,
    selectSiteProfile,
    selectAdminIndication
} = filterSlice.actions;

export default filterSlice.reducer;
