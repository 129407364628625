import React from "react";
import PropTypes from "prop-types";
import { NotFoundWrapper } from "./NotFound.styles";
import { Link } from "react-router-dom";
import NotFoundImage from "../../assets/img/not-found.png";
import { Image } from "antd";

const NotFound = (props) => (
  <NotFoundWrapper>
      <Image
        src={NotFoundImage}
        preview={false}
        style={{ width: "50%", maxWidth: 1000, height: "auto" }}
      />
      <Link to="/">Go Home</Link>
  </NotFoundWrapper>
);

NotFound.propTypes = {
  // bla: PropTypes.string,
};

NotFound.defaultProps = {
  // bla: 'test',
};

export default NotFound;
