import React, { useEffect, useRef, useState } from "react";
import DateObject from "react-date-object";
import config from "../../config";
import { GetAPIRequest } from "../../services";
import { MultiYearPickerWrapper } from "./MultiYearPicker.styles";
import MultiSelectPicker from "../../libs/multiselect-dropdown";
import DatePanel from "../../libs/multiselect-dropdown/plugins/date_panel/date_panel";

const MultiYearPicker = (props) => {
  const datePickerRef = useRef();
  const [years, setYears] = useState([
    new DateObject({ year: 2020, month: 1, day: 1 }),
  ]);
  const [selYears, setSelYears] = useState([2020]);
  const [yearsOpt, setYearsOpt] = useState([]);
  const [minYear, setMinYear] = useState(
    new DateObject({ year: 2012, month: 1, day: 1 })
  );
  const [maxYear, setMaxYear] = useState(new DateObject());
  useEffect(() => {
    GetAPIRequest({
      url: config.api.getYears,
    })
      .then((res) => {
        console.log("years", res);
        var opt = [];
        res.map((r, idx) => {
          opt.push({
            value: r.year,
            isDefault: r.isDefault,
          });
          console.log("years", r);
          if (r.isDefault == true) {
            setSelYears([r.year]);
            localStorage.setItem("SSNSELECTEDYEARS", r.year);
          }
        });
        var min = Math.min(...res.map((e) => e.year));
        var max = Math.max(...res.map((e) => e.year));
        setMinYear(new DateObject({ year: min, month: 1, day: 1 }));
        setMaxYear(new DateObject({ year: max, month: 1, day: 1 }));
        setYearsOpt(opt);
      })
      .catch(() => {});
  }, []);
  return (
    <MultiYearPickerWrapper>
      <MultiSelectPicker
        ref={datePickerRef}
        value={years}
        onChange={(val) => {
          setYears(val);
          var years = val.map((v) => v.year);
          console.log(years);
        }}
        onlyYearPicker
        showOtherDays={false}
        multiple
        sort
        minDate={minYear}
        maxDate={maxYear}
        plugins={[<DatePanel />]}
      >
        <button
          style={{
            border: "1px solid #962163",
            borderRadius: 5,
            background: "#ffffff",
            color: "#962163",

          }}
          onClick={() => datePickerRef.current.closeCalendar()}
        >
          Apply
        </button>
      </MultiSelectPicker>
    </MultiYearPickerWrapper>
  );
};

MultiYearPicker.propTypes = {
  // bla: PropTypes.string,
};

MultiYearPicker.defaultProps = {
  // bla: 'test',
};

export default MultiYearPicker;
