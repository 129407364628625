import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import config from "../../config";
import {
  SalesRegionMapWrapper,
  SalesRegionMapContainer,
} from "./SalesRegionMap.styles";
import * as topojson from "topojson-client";
import { isUndefined } from "../../utils/utils";
import salesRegionData from "./salesRegionData";
import counties from "./counties";
import * as d3 from "d3";
import { nest } from "d3-collection";
import { geoPath } from "d3-geo";
import { scaleSqrt } from "d3-scale";
import { GetAPIRequest } from "../../services";
import { Select, Spin } from "antd";

const { Option } = Select;
const SalesRegionMap = (props) => {
  const { url, containerId, onClick } = props;
  const [selBrand, setSelBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getBrands();
    return () => {};
  }, []);

  const getBrands = () => {
    GetAPIRequest({
      url: `${config.api.getBrandNames}?locationid=USA`,
    })
      .then((res) => {
        if (res) {
          setBrands(res);
          setSelBrand(res[0]);
          updateMap(res[0]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  function updateMap(brandName) {
    GetAPIRequest({
      url: `${config.api.getSalesRegion}?locationid=USA&brandName=${brandName}`,
    })
      .then((response) => {
        if (response) {
          loadData(counties, response);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getColorByDistrict(region) {
    switch (region) {
      case "Central":
        return "#441d5e";
      case "Chicago IL":
        return "#b97fa6";
      case "Great Plains":
        return "#7b7c7c";
      case "Nashville TN":
        return "#d7d8d8";
      case "New England":
        return "#94a7a6";
      case "New York NY":
        return "#95a7a6";
      case "Northwest":
        return "#bec8c9";
      case "Philadelphia PA":
        return "#95a7a6";
      case "South Central":
        return "#cab1db";
      case "Southeast":
        return "#8a7499";
      case "Southwest":
        return "#800f5c";
      case "Washington DC":
        return "#d3bfcc";
      case "Midwest":
        return "#57396b";
      case "Mountain West":
        return "#a36d90";
      case "Mid Atlantic":
        return "#f0d3e7";
      case "Northeast":
        return "#e7d8f2";
      case "Carolinas":
        return "#f5ebfc";
      case "Unassigned District":
        return "#d1d1d1";
      default:
        return "#d1d1d1";
    }
  }

  function LightenColor(color, percent) {
    var num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  function randomBetween(min, max) {
    if (min < 0) {
      return min + Math.random() * (Math.abs(min) + max);
    } else {
      return min + Math.random() * max;
    }
  }

  const loadData = (us, salesRegion, sleDataObj) => {
    d3.select("#salesRegionSvg").html("");
    d3.select("#tooltip").remove();
    var isDoubleClick = true;
    var colorArr = [];
    var territoryArr = [];
    var territoryArr1 = [];
    var territoryArr2 = [];
    var territoryArr3 = [];
    var innerCircleData = [];
    var innerCircleRadius = [];
    var maxRadius = 0;
    var minRadius = 0;
    var profile = localStorage.getItem("SSN_USER_PROFILE");
    var margin = {
      top: 20,
      bottom: 150,
      left: 10,
      right: 10,
    };
    var width = 960 - margin.left - margin.right;
    var mapRatio = 0.5;
    var height = width * mapRatio;
    var mapwidth = window.innerWidth > 900 ? 900 : window.innerWidth;
    var countyData = topojson.feature(us, us.objects.counties).features;
    var path = geoPath().projection(null);

    var svg = d3
      .select("#salesRegionSvg")
      .append("svg")
      .attr("class", "map-area")
      .attr("viewBox", `0 0 900 700`)
      .attr("width", mapwidth)
      .attr(`height`, mapwidth - 300);

    var tooltip = d3
      .selectAll("body")
      .append("div")
      .attr("id", "tooltip")
      .style("opacity", 0);

    const getTerritoryId = (d) => {
      var result = salesRegion.find((obj) => parseInt(obj.fip) === d.id);
      return isUndefined(result) ? null : result.territoryId;
    };

    const getTerritoryName = (d) => {
      var result = salesRegion.find((obj) => parseInt(obj.fip) === d.id);
      return isUndefined(result) ? null : result.territory;
    };

    const getDistrict = (d) => {
      var result = salesRegion
        .filter((obj) => parseInt(obj.fip) === d.id)
        .map((obj) => obj.district);
      return result.length > 0 ? result : null;
    };

    const getDataObj = (d) => {
      var territoryCount = 0;
      var districtCount = 0;
      var result = salesRegion.find((obj) => parseInt(obj.fip) === d.id);
      if (!isUndefined(result)) {
        salesRegion
          .filter((x) => x.territoryId === result.territoryId)
          .map((x) => {
            territoryCount += isUndefined(x.count) ? 0 : x.count;
          });

        salesRegion
          .filter((x) => x.district === result.district)
          .map((x) => {
            districtCount += isUndefined(x.count) ? 0 : x.count;
          });
      }
      return { result, territoryCount, districtCount };
    };

    const getStrokeColor = (d) => {
      var result = getDistrict(d);
      var color = "";
      if (result == null) {
        color = "#dddddd";
      } else if (result.indexOf("Unassigned District") > -1) {
        color = "#ffffff";
      } else {
        var territoryId = getTerritoryId(d);
        if (territoryArr1.indexOf(territoryId) > -1) {
          var idx = territoryArr1.indexOf(territoryId);
          color = colorArr[idx];
        } else {
          var _c = LightenColor(
            getColorByDistrict(result[0]),
            randomBetween(-20, -10)
          );
          territoryArr1.push(territoryId);
          colorArr.push(_c);
          color = _c;
        }
      }
      return color;
    };

    const getColor = (d) => {
      var result = getDistrict(d);
      var color = "";
      if (result == null) {
        color = "#ffffff";
      } else if (result.indexOf("Unassigned District") > -1) {
        color = "#ffffff";
      } else {
        var territoryId = getTerritoryId(d);
        if (territoryArr.indexOf(territoryId) > -1) {
          var idx = territoryArr.indexOf(territoryId);
          color = colorArr[idx];
        } else {
          var _c = LightenColor(
            getColorByDistrict(result[0]),
            randomBetween(-20, -10)
          );
          territoryArr.push(territoryId);
          colorArr.push(_c);
          color = _c;
        }
      }
      return color;
    };

    const mouseOverFn = (event, d) => {
      let mouseOverData = getDataObj(d);
      if (mouseOverData.result === null || mouseOverData.result === undefined) {
        console.log(`missing data from the api fip: ${d.id}`);
      } else {
        tooltip
          .style("opacity", 0.9)
          .attr("class", "d3-tip")
          .style("text-align", "left")
          .style("left", event.pageX + 30 + "px")
          .style("top", event.pageY - 15 + "px")
          .html(
            `
                  ${mouseOverData.result.territory}, ${mouseOverData.result.district}, ${mouseOverData.result.region}
                  <div style='margin-top: 10px;margin-bottom: 10px;'><strong style='color: black'>Territory Count: </strong> <span style='color:black; font-size: .9em;'>${mouseOverData.territoryCount}</span></div> 
                  <div style='margin-top: 10px;margin-bottom: 10px;'><strong style='color: black'>District Count:  </strong> <span style='color:black; font-size: .9em'>${mouseOverData.districtCount}</span></div> 
                `
          );
      }
    };

    const mouseOutFn = () => {
      tooltip.style("opacity", 0);
    };

    var groupedData = nest()
      .key(function (d) {
        return d.territoryId;
      })
      .rollup(function (d) {
        return d.map(function (g) {
          return parseInt(g.fip);
        });
      })
      .entries(salesRegion);

    var groupedCounties = groupedData.map((fips) => {
      return topojson.merge(
        us,
        us.objects.counties.geometries.filter(function (d) {
          return fips.value.indexOf(+d.id) > -1;
        })
      );
    });
    var countis = {
      type: "FeatureCollection",
    };
    var groupedCountiesArr = [];
    groupedCounties.forEach((cnt, idx) => {
      groupedCountiesArr.push({
        type: "Feature",
        geometry: cnt,
        properties: {},
        id: idx,
      });
    });
    countis["features"] = groupedCountiesArr;
    console.log("groupedCounties", countis);

    var g = svg
      .append("g")
      .attr("class", "center-container center-items us-state")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom);

    g.append("g")
      .selectAll("path")
      .data(countyData)
      .enter()
      .append("path")
      .attr("class", "county")
      .attr("stroke-width", "1")
      .attr("stroke", getStrokeColor)
      .attr("fill", getColor)
      .attr("d", path)
      .on("mouseover", mouseOverFn)
      .on("mouseout", mouseOutFn)
      .on("click", (event, d) => {
        setTimeout(() => {
          //if (!isDoubleClick) {
          var territoryName = getTerritoryName(d);
          let mouseOverData = getDataObj(d);

          var territoryId = getTerritoryId(d);
          onClick(territoryId);
          //salesRegionController.showPhysician(territoryName);
          //fetchPhysicians(territoryId);
          //}
        }, 1000);
      })
      .on("dblclick", (event, d) => {
        isDoubleClick = true;
        g.transition()
          .delay(100)
          .duration(750)
          .style("stroke-width", "1.5px")
          .attr(
            "transform",
            "translate(" + margin.left + "," + margin.top + ")"
          );
        var territoryId = getTerritoryId(d);
        //getUnmetneedsSummary(territoryId);
        setTimeout(() => {
          isDoubleClick = false;
        }, 3000);
      });

    g.append("g")
      .selectAll("path")
      .data(groupedCounties)
      .enter()
      .append("path")
      .attr("class", "county")
      .attr("fill", (d) => {
        return "none";
      })
      .attr("stroke-width", "1")
      .attr("opacity", "0.5")
      .attr("stroke", (d) => {
        return "#666666";
      })
      .attr("d", path);

    if (profile && profile === "SLE") {
      function rnd(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
      var radius = scaleSqrt.domain([minRadius, maxRadius]).range([0, 50]);
      var innerRadius = scaleSqrt.domain([minRadius, maxRadius]).range([0, 30]);
      function getOuterCircleRadius(d) {
        var rad = 0;
        var territoryId = getTerritoryId(d);
        let _radius = 0;
        if (
          territoryId &&
          sleDataObj[territoryId] &&
          sleDataObj[territoryId]["MS"]
        ) {
          rad = sleDataObj[territoryId]["MS"];
        }
        if (territoryArr2.indexOf(territoryId) > -1) {
          return _radius;
        } else {
          if (territoryId == "20110577") {
            if (d.id == 30011) {
              territoryArr2.push(territoryId);
              innerCircleData.push(d);
              _radius = radius(maxRadius) + 20;
            }
          } else {
            territoryArr2.push(territoryId);
            innerCircleData.push(d);
            _radius = radius(rad);
          }
          if (_radius < 0) {
            return 0;
          } else {
            return _radius;
          }
        }
      }

      function getInnerCircleRadius(d, idx) {
        var territoryId = getTerritoryId(d);
        let inner_rad = 0;
        let _radius = 0;
        if (
          territoryId &&
          sleDataObj[territoryId] &&
          sleDataObj[territoryId]["HOCS"]
        ) {
          inner_rad = sleDataObj[territoryId]["HOCS"];
        }
        if (territoryId == "20110577") {
          _radius = radius(maxRadius) - 10;
        } else {
          _radius = innerRadius(inner_rad);
        }
        if (_radius < 0) {
          return 0;
        } else {
          return _radius;
        }
      }

      svg
        .append("g")
        .attr("class", "bubble")
        .selectAll("circle")
        .data(countyData)
        .enter()
        .append("circle")
        .attr("fill", function (d) {
          var territoryId = getTerritoryId(d);
          if (territoryId && territoryId == "20110577") {
            return "#FF5722";
          }
          return "#FFC300";
        })
        .attr("fill-opacity", "0.9")
        .attr("transform", function (d) {
          return "translate(" + path.centroid(d) + ")";
        })
        .attr("r", getOuterCircleRadius)
        .on("mouseover", bubbleOnMouseOver)
        .on("mouseout", mouseOutFn)
        .on("click", bubbleOnClick)
        .on("dblclick", bubbleOnDblClick);
      svg
        .append("g")
        .attr("class", "bubble")
        .selectAll("circle")
        .data(innerCircleData)
        .enter()
        .append("circle")
        .attr("fill", "#DAF7A6")
        .attr("fill-opacity", "0.5")
        .attr("transform", function (d) {
          return "translate(" + path.centroid(d) + ")";
        })
        .attr("r", getInnerCircleRadius)
        .on("mouseover", () => {})
        .on("mouseout", () => {})
        .on("click", bubbleOnClick)
        .on("dblclick", bubbleOnDblClick);

      function bubbleOnMouseOver(event, d) {
        let mouseOverData = getDataObj(d);
        if (
          mouseOverData.result === null ||
          mouseOverData.result === undefined
        ) {
          console.log(`missing data from the api fip: ${d.id}`);
        } else {
          var territoryId = getTerritoryId(d);
          var ms_ocs = "";
          if (
            territoryId &&
            sleDataObj[territoryId] &&
            sleDataObj[territoryId]["MS"]
          ) {
            ms_ocs = sleDataObj[territoryId]["MS"];
          }
          var STgte7 = "";
          if (
            territoryId &&
            sleDataObj[territoryId] &&
            sleDataObj[territoryId]["HOCS"]
          ) {
            STgte7 = sleDataObj[territoryId]["HOCS"];
          }
          tooltip
            .style("opacity", 0.9)
            .attr("class", "d3-tip")
            .style("text-align", "left")
            .style("left", event.pageX + 30 + "px")
            .style("top", event.pageY - 15 + "px")
            .html(
              `${mouseOverData.result.territory}, ${mouseOverData.result.district}, ${mouseOverData.result.region}
                  <div style='margin-top: 10px;margin-bottom: 10px;'><strong style='color: black'>MS: </strong> <span style='color:black; font-size: .9em;'>${ms_ocs}</span></div> 
                  <div style='margin-top: 10px;margin-bottom: 10px;'><strong style='color: black'>HOCS:  </strong> <span style='color:black; font-size: .9em'>${STgte7}</span></div> 
                `
            );
        }
      }
      function bubbleOnClick(event, d) {
        setTimeout(() => {
          if (!isDoubleClick) {
            var territoryId = getTerritoryId(d);
            //fetchPhysicians(territoryId);
          }
        }, 1000);
      }
      function bubbleOnDblClick(event, d) {
        isDoubleClick = true;
        var territoryId = getTerritoryId(d);
        //getUnmetneedsSummary(territoryId);
        setTimeout(() => {
          isDoubleClick = false;
        }, 3000);
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    selBrand(e.target.value);
    updateMap(e.target.value);
  };

  return (
    <Spin spinning={loading}>
    <SalesRegionMapWrapper>
      <div className="topSelection">
        <div className="selectbox">
          <span>Select Brand</span>
          <Select
            value={selBrand}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            {brands.map((brand) => (
              <Option value={brand}>{brand}</Option>
            ))}
          </Select>
        </div>
      </div>
     
        <SalesRegionMapContainer id="salesRegionSvg"></SalesRegionMapContainer>
      
    </SalesRegionMapWrapper>
    </Spin>
  );
};

SalesRegionMap.propTypes = {
  // bla: PropTypes.string,
};

SalesRegionMap.defaultProps = {
  // bla: 'test',
};

export default SalesRegionMap;
