import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MultiLineBarChartWrapper, MLBChart } from "./MultiLineBarChart.styles";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import data from "./data";
const MultiLineBarChart = (props) => {
  //const { data, segtabid, isPercentage } = props;
  let root = null;
  const loadData = () => {
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelY: "none",
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    chart.get("colors").set("step", 2);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    var yRenderer = am5xy.AxisRendererY.new(root, { inside: true });
    yRenderer.grid.template.set("forceHidden", true);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer
      })
    );

    let distanceAxisRenderer = am5xy.AxisRendererY.new(root, {});
    distanceAxisRenderer.grid.template.set("forceHidden", true);
    let distanceAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: distanceAxisRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let latitudeAxisRenderer = am5xy.AxisRendererY.new(root, {});
    latitudeAxisRenderer.grid.template.set("forceHidden", true);
    let latitudeAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: latitudeAxisRenderer,
        forceHidden: true,
      })
    );

    let durationAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
    });
    durationAxisRenderer.grid.template.set("forceHidden", true);
    let durationAxis = chart.yAxes.push(
      am5xy.DurationAxis.new(root, {
        baseUnit: "minute",
        renderer: durationAxisRenderer,
        extraMax: 0.3,
      })
    );

    let distanceSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        //  yAxis: distanceAxis,
        yAxis: yAxis,
        valueYField: "distance",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY} miles",
        }),
      })
    );



    distanceSeries.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    let latitudeSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: latitudeAxis,
        valueYField: "latitude",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "latitude: {valueY} ({townName})",
        }),
      })
    );

    latitudeSeries.strokes.template.setAll({ strokeWidth: 2 });

    latitudeSeries.bullets.push(function () {
      let graphics = am5.Circle.new(root, {
        strokeWidth: 2,
        radius: 5,
        stroke: latitudeSeries.get("stroke"),
        fill: root.interfaceColors.get("background"),
      });

      graphics.adapters.add("radius", function (radius, target) {
        return target.dataItem.dataContext.townSize;
      });

      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    let durationSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: durationAxis,
        valueYField: "duration",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "duration: {valueY.formatDuration()}",
        }),
      })
    );

    durationSeries.strokes.template.setAll({ strokeWidth: 2 });

    durationSeries.bullets.push(function () {
      let graphics = am5.Rectangle.new(root, {
        width: 10,
        height: 10,
        centerX: am5.p50,
        centerY: am5.p50,
        fill: durationSeries.get("stroke"),
      });

      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: distanceAxis,
      })
    );



    distanceSeries.data.setAll(data);
    latitudeSeries.data.setAll(data);
    durationSeries.data.setAll(data);
    xAxis.data.setAll(data);
    // add ranges
    var goalRange = yAxis.createAxisRange(yAxis.makeDataItem({
      value: 300
    }));

    goalRange.get("grid").setAll({
      forceHidden: false,
      strokeOpacity: 1
    });

    var goalLabel = goalRange.get("label");

    goalLabel.setAll({
      centerY: am5.p100,
      centerX: am5.p100,
      //text: "Goal"
    });

    // put to other side
    goalLabel.adapters.add("x", function (x) {
      return chart.plotContainer.width();
    });

    chart.plotContainer.onPrivate("width", function () {
      goalLabel.markDirtyPosition();
    });

    distanceSeries.appear(1000);
    chart.appear(1000, 100);
  };
  useEffect(() => {
    if (root) {
      root.dispose();
    }
    root = am5.Root.new("mlbChart");
    loadData();
    return () => { };
  }, []);
  return (
    <MultiLineBarChartWrapper>
      <MLBChart id="mlbChart"></MLBChart>
    </MultiLineBarChartWrapper>
  );
};

MultiLineBarChart.propTypes = {
  // bla: PropTypes.string,
};

MultiLineBarChart.defaultProps = {
  // bla: 'test',
};

export default MultiLineBarChart;
