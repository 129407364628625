import styled from 'styled-components';

export const PhyBarChartWrapper = styled.div`
 display: flex;
 .phychart{
     width: 100%;
     height: 100vh;
     overflow: hidden;
     overflow-y: scroll;
 }
`;
