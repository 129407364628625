import React, { useState } from "react";
import { StackedBarGraph } from "./StackedBarGraph";
import { NavDropdown, Navbar, Nav, Form,Container,Row, Col, Card, 
  ListGroup,ListGroupItem, Badge
  } from "react-bootstrap";
import DonutGraph from "./DonutGraph";
import DonutsMain from "./DonutsMain";
import DonutGraph2 from "./DonutGraph2";

const data = [
  {
    name: " Ibrutinib Treated (n=220)",
    type: 1,
    PatientsIbrutinib: 10,
    PatientsCalequence: 20,
    WaitAndWatch: 30,
    ExperiencingAF:70,
    TreatedPatients:40
  },
 
 
  {
    name: "Calequence Treated (n=140)",
    type: 1,
    PatientsIbrutinib: 40,
    PatientsCalequence: 60,
    WaitAndWatch: 100,
    ExperiencingAF:30,
    TreatedPatients:60
  },

];

const allKeys = ["PatientsIbrutinib", "PatientsCalequence", "WaitAndWatch", "ExperiencingAF","TreatedPatients" ];

const colors = {
  PatientsIbrutinib: "red",
  PatientsCalequence: "green",
  WaitAndWatch: "darkgrey",
  ExperiencingAF:"#f1371d",
  TreatedPatients:"#00b2ec"

};

function BarGraph() {
  const [keys, setKeys] = useState(allKeys);

  return (

<>
<br/>
<br/>

<Container>
  <Row>
    <Col sm={6}>
    <div>
      <StackedBarGraph datasets={data} colors={colors} keys={keys} />
      <div className="fields" style={{ display: "flex" }}>
        {allKeys.map((key) => (
          <div key={key} className="field" style={{ display: "flex" }}>
            <input
              id={key}
              type="checkbox"
              checked={keys.includes(key)}
              onChange={(e) => {
                if (e.target.checked) {
                  setKeys(Array.from(new Set([...keys, key])));
                } else {
                  setKeys(keys.filter((_key) => _key !== key));
                }
              }}
            />
            <label htmlFor={key} style={{ color: colors[key] }}>
              {key}
            </label>
          </div>
        ))}
      </div>
    </div>

    </Col>
    <Col sm={3}>
      <p style={{fontWeight:"bold", color:"black"}}>Ibrutinib</p>
<div>


<DonutGraph/>
</div>

    </Col>
    <Col sm={3}>
      <p style={{fontWeight:"bold", color:"black"}}>Calequence</p>
<div>

<DonutsMain/>

</div>

    </Col>
  </Row>
  </Container>

    </>

  );
};

export default BarGraph;