import styled from "styled-components";

export const PhysicianListWrapper = styled.div`
  /* height: 400px;
  overflow: hidden;
  overflow-y: scroll;*/
  
  .phyJourneyTab {
    
    .ant-tabs-tab-active {
      border-bottom: 3px solid #000000 !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000000;
    }
    .ant-tabs-tab-btn {
      color: #666666;
    }
  }
`;
export const FilterTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
`;
export const TableWrapper = styled.div`
  margin: 10px;
`;
