import React, { useEffect, useState } from "react";
import Brand from "../Brand";
import ProfileSelectionPopup from "../ProfileSelectionPopup";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./Header.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faAngleDown,
  faFileContract,
  faChartPie,
  faBullseye,
  faFileText,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
const AZHeader = () => {
  const [isShrunk, setShrunk] = useState(false);
  var userprofile = localStorage.getItem("SSN_USER_PROFILE");
  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const NavMenu = () => (
    <div className="navbar1" style={{ flex: 1 }}>
      <a href="/">
        <FontAwesomeIcon style={{ paddingRight: 5 }} icon={faHouse} />
        Home
      </a>
      <div className="subnav1">
        <button className="subnavbtn1">
          <FontAwesomeIcon style={{ paddingRight: 5 }} icon={faBullseye} />
          <span>Insights</span>{" "}
          <FontAwesomeIcon style={{ paddingLeft: 5 }} icon={faAngleDown} />
        </button>
        <div className="subnav-content1">
          <a href="/insights">Home</a>
          <a href="/insights/accounts">Accounts</a>
          <a href="/insights/geo-distribution">Geo Distribution</a>
          <a href="/insights/sales-regions">Sales Region</a>
          {userprofile == "NSCLC" && (
            <a href="/insights/healthcare-disparity">Healthcare Disparity</a>
          )}
        </div>
      </div>
      {userprofile == "CLL" && (
        <div className="subnav1">
          <button className="subnavbtn1">
            <FontAwesomeIcon
              style={{ paddingRight: 5 }}
              icon={faSquarePollHorizontal}
            />
            <span>Reporting</span>{" "}
            <FontAwesomeIcon style={{ paddingLeft: 5 }} icon={faAngleDown} />
          </button>
          <div className="subnav-content1">
            <a href="/reporting/hcp">HCPs</a>
            <a href="/reporting/parent-accounts">Parent Accounts</a>
            <a href="/reporting/child-accounts">Child Accounts</a>
          </div>
        </div>
      )}
      {userprofile !== "CLL" && userprofile !== "NSCLC" && (
        <a href="/dashboard">
          <FontAwesomeIcon style={{ paddingRight: 5 }} icon={faChartPie} />
          Dashboard
        </a>
      )}
    </div>
  );

  return (
    <HeaderWrapper>
      <div className="header">
        <Brand />
        <NavMenu />
        <ProfileSelectionPopup />
        <UserInfo />
      </div>
    </HeaderWrapper>
  );
};

export default AZHeader;
