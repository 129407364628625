import React, { useEffect, useState } from "react";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import SunBurstChart from "../../../components/SunburstChart";
import config from "../../../config";
import { AccountsInfoWrapper } from "./AccountsInfo.styles";
import SiteMap from "../../../components/SiteMap/SiteMap";
import PhysicianList from "../../../components/PhysicianList/PhysicianList";
import { GetAPIRequest } from "../../../services";
const AccountsInfo = (props) => {
  //const {accounts} = props;
  var _accountsInfoinstance;
  const navigate = useNavigate();
  let { accountId, type } = useParams();
  const options = {
    onLoad: function () {
      console.log("[onLoad]");
      setTimeout(() => {
        _accountsInfoinstance
          .open({
            id: "1",
            title: "Home",
            closable: false,
            panelComponent: (porps) => {
              return (
                <SunBurstChart
                  url={`${
                    config.api.accountsWheelSummary
                  }?id=${accountId.toUpperCase()}&type=${type}`}
                  accountId={accountId.toUpperCase()}
                  dataType="accounts"
                  showYears={false}
                  onInfoUpdate={() => {}}
                  onResult={(d) => {}}
                  onSelect={(d) => {}}
                  type={type}
                  primaryTabInstance={_accountsInfoinstance}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });

        _accountsInfoinstance
          .open({
            id: "2",
            title: "Physicians",
            closable: false,
            panelComponent: (porps) => {
              return (
                <PhysicianList
                  url={`${
                    config.api.getPhysicians
                  }?type=parent&id=${accountId.toUpperCase()}`}
                  dataType="accounts"
                  onInfoUpdate={() => {}}
                />
              );
            },
          })
          .then(() => {
            console.log("tab is open");
          });

        // switch to tab
        _accountsInfoinstance.select("1").then(() => {
          console.log("tab is selected");
        });
      }, 1000);
    },
    onInit: function () {
      console.log("[onInit]");
    },
  };

  const [
    AccountsInfoTabList,
    AccountsInfoPanelList,
    onAccountsInfoReady,
  ] = useDynTabs(options);

  onAccountsInfoReady((instance) => {
    _accountsInfoinstance = instance;
  });

  useEffect(() => {
    //alert("!");
  }, []);

  return (
    <AccountsInfoWrapper>
      <SiteMap />
      <div className="content">
        <AccountsInfoTabList></AccountsInfoTabList>
        <AccountsInfoPanelList></AccountsInfoPanelList>
      </div>
    </AccountsInfoWrapper>
  );
};

export default AccountsInfo;
