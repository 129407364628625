import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GeoDistributionWrapper } from "./GeoDistribution.styles";
import USBubbleMap from "../../../components/USBubbleMap";
import useDynTabs from "../../../libs/react-dyn-tabs";
import "../../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import SiteMap from "../../../components/SiteMap/SiteMap";
const GeoDistribution = (props) => {
  const navigate = useNavigate();
  var _geoDistributionInstance;
  const options = {
    tabs: [
      {
        id: "1",
        title: "Home",
        closable: false,
        panelComponent: (porps) => {
          return (
            <USBubbleMap
              url={`${config.api.getGeoDistribution}`}
              containerId="geodistribution"
              onClick={(d) => {
                navigate(`/insights/geo-distribution-info/${d}`);
              }}
            />
          );
        },
      },
    ],
    selectedTabID: "1",
    onLoad: function () {
      console.log("[onLoad]");
    },
  };
  const [Tablist, Panellist, ready] = useDynTabs(options);
  ready((instance) => {
    _geoDistributionInstance = instance;
  });

  useEffect(() => {
    //alert("!");
  }, []);
  return (
    <GeoDistributionWrapper>
      <SiteMap />
      <div className="content">
        <Tablist></Tablist>
        <Panellist></Panellist>
      </div>
    </GeoDistributionWrapper>
  );
};

GeoDistribution.propTypes = {
  // bla: PropTypes.string,
};

GeoDistribution.defaultProps = {
  // bla: 'test',
};

export default GeoDistribution;
