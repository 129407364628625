import styled from "styled-components";

export const HeaderWrapper = styled.div`
  .header {
    display: flex;
    background-color: #f9f9f9; /*#2E547A;*/
    border-bottom: 1px solid #0000000a;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #000000;
    padding: 0px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;

    /* The navigation menu */
    .navbar1 {
      overflow: hidden;
      background-color: transparent;
    }

    /* Navigation links */
    .navbar1 a {
      float: left;
      font-size: 16px;
      color: #666666;
      text-align: center;
      padding: 18px 30px;
      text-decoration: none;
    }

    /* The subnavigation menu */
    .subnav1 {
      float: left;
      overflow: hidden;
    }

    /* Subnav button */
    .subnav1 .subnavbtn1 {
      font-size: 16px;
      border: none;
      outline: none;
      color: #666666;
      padding: 18px 30px;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
    }

    /* Add a red background color to navigation links on hover */
    .navbar1 a:hover,
    .subnav1:hover .subnavbtn1 {
      background-color: #962163;
      color: #ffffff;
    }

    /* Style the subnav content - positioned absolute */
    .subnav-content1 {
      display: none;
      position: absolute;
      left: 0;
      background-color: #962163;
      width: 100%;
      z-index: 100000000000;
    }

    /* Style the subnav links */
    .subnav-content1 a {
      font-size: 14px;
      float: left;
      color: #ffffff;
      text-decoration: none;
      padding: 10px 16px;
    }

    /* Add a grey background color on hover */
    .subnav-content1 a:hover {
      background-color: #962163;
      color: #f1f1f1;
      font-weight: bold;
    }

    /* When you move the mouse over the subnav container, open the subnav content */
    .subnav1:hover .subnav-content1 {
      display: block;
    }
  }
`;
