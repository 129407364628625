import React, { useEffect, useState } from "react";
import AppRoutes from "./routes/appRoutes";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { checkAuth, getCookie } from "./utils/cookie-helper";

function App() {
  const [token, setToken] = useState("");
  useEffect(() => {
    let token = getCookie("access-token");
    setToken(token);
    if (token === null || token === "") {
      checkAuth();
    }
  }, []);
  return (
    <div className="App">
      <Header />
      {token != null && token != "" && <AppRoutes />}
      <Footer />
    </div>
  );
}

export default App;
