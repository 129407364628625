import React from "react";
import PropTypes from "prop-types";
import { BarWrapper } from "./Bar.styles";
import { useEffect } from "react";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Bar = (props) => {
  const { data, segtabid, isPercentage } = props;
  const loadData = () => {
    if (data && data.length > 0) {
      var margin = { top: 50, right: 100, bottom: 100, left: 380 },
        width = window.innerWidth - margin.left - margin.right,
        height = 600 - margin.top - margin.bottom;

      // append the svg object to the div called 'my_dataviz'
      d3.select(`#nav-${segtabid}`)
        .style("width", width)
        .style("height", height + "px");
      var root = am5.Root.new(`nav-${segtabid}`);
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );
      var cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "zoomX",
        })
      );
      cursor.lineY.set("visible", true);
      chart.zoomOutButton.set("forceHidden", false);
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );

      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        minGridDistance: 30
      });
      xRenderer.labels.template.setAll({});

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: "name",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
        })
      );

      var yRenderer = am5xy.AxisRendererY.new(root, {});

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          extraMax: 0.1,
          renderer: yRenderer,
        })
      );
      yRenderer.labels.template.setAll({
        fontSize: "12px",
      });

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "count",
          categoryXField: "name",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "left",
            labelText: isPercentage ? "[#ffffff]{valueY}%" : "{valueY}[/]",
          }),
        })
      );

      xAxis.data.setAll(data);
      series.data.setAll(data);
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(root, {
            centerY: am5.p100,
            centerX: am5.p50,
            text: isPercentage ? "{valueY}%" : "{valueY}",
            populateText: true,
            fontSize: "14px",
          }),
        });
      });

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
          xAxis: xAxis,
          yAxis: yAxis,
        })
      );

      series.appear(1000);
      chart.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <BarWrapper id={`nav-${segtabid}`}></BarWrapper>;
};

Bar.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

Bar.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default Bar;
