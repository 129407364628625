import React from "react";
import PropTypes from "prop-types";
import { PieWrapper } from "./Pie.styles";
import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as d3 from "d3";

const Pie = (props) => {
  const { data, segtabid } = props;
  var root = null;
  const loadData = () => {
    if (data && data.length > 0) {
      // set the dimensions and margins of the graph
      var margin = { top: 100, right: 100, bottom: 100, left: 70 },
        width = window.innerWidth - 400 - margin.left - margin.right,
        height = 450 - margin.top - margin.bottom;
      d3.select(`#nav-${segtabid}`)
        .style("width", width)
        .style("height", "500px");
        if (root) {
          root.dispose();
        }
      root = am5.Root.new(`nav-${segtabid}`);
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
        })
      );
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: "count",
          categoryField: "name",
          legendLabelText:
            "{category}  ([bold]{valuePercentTotal.formatNumber('0.00')}%[/])",
          legendValueText: "",
          wrap: true,
          maxWidth: 200,
        })
      );
      series.data.setAll(data);
      series.labels.template.setAll({
        fontSize: 14,
        fill: am5.color(0x000000),
        maxWidth: "130px",
        wrap: true,
        text: "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
      });
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          marginTop: 15,
          marginBottom: 15,
          layout: am5.GridLayout.new(root, {
            fixedWidthGrid: true,
          }),
        })
      );
      legend.data.setAll(series.dataItems);

      series.appear(1000, 100);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return <PieWrapper id={`nav-${segtabid}`}></PieWrapper>;
};

Pie.propTypes = {
  data: PropTypes.array,
  segtabid: PropTypes.string,
};

Pie.defaultProps = {
  data: [],
  segtabid: "segtabid",
};

export default Pie;
