import { PieChartOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Button, Segmented, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import config from "../../config";
import "../../libs/react-dyn-tabs/style/react-dyn-tabs.css";
import "../../libs/react-dyn-tabs/themes/react-dyn-tabs-card.css";
import { GetAPIRequest } from "../../services/api";
import ComparisonChart from "../ComparisonChart";
import MultiYearPicker from "../MultiYearPicker";
import PatientPopulationChart from "../PatientPopulationChart";
import SankeyPlot from "../SankeyPlot";
import SecondaryTab from "../SecondaryTab";
import {
  ComparisonDiv,
  ComparisonViewWrapper,
  SunburstChartComment,
  SunburstChartTitle,
  SunburstChartWrapper,
} from "./SunburstChart.styles";

const SunBurstChart = (props) => {
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [selectedYearsOption, setSelectedYearsOption] = useState([]);
  const [selectedYears, setSelectedYears] = useState("");
  const [years, setYears] = useState([]);
  const [isSankeyVisible, setIsSankeyVisible] = useState(false);
  const [accountsData, setAccountsData] = useState([]);
  const [selAccounts, setSelAccounts] = useState(props.accountId);
  const [value, setValue] = useState("wheel");
  const [showComparison, setShowComparison] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedAccounts, setSelectedAccounts] = useState([
    {
      value: props.accountId,
      label: props.accountId,
    },
  ]);

  useEffect(() => {
    var url = `${config.api.accountsTopParent}`;
    if (props.type == "parent") {
      url = `${config.api.accountsParent}`;
    }
    GetAPIRequest({
      url: url,
    })
      .then((res) => {
        console.log("res", res);
        var _accounts = [];
        res.map((d) => {
          _accounts.push({
            value: d.name,
            label: `${d.name} - (${d.count})`,
            size: d.count,
          });
        });
        console.log("res-accounts", _accounts);
        setAccountsData(_accounts);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (props.showYears) {
      GetAPIRequest({
        url: config.api.getYears,
      })
        .then((res) => {
          console.log("years", res);
          var opt = [];
          var defaultOption = {};
          res.map((r, idx) => {
            opt.push({
              value: r.year,
              label: `${r.year}`,
              isDefault: r.isDefault,
            });
            console.log("years", r);
            if (r.isDefault == true) {
              defaultOption = {
                value: r.year,
                label: `${r.year}`,
                isDefault: r.isDefault,
              };
              setSelectedYears(r.year);
              localStorage.setItem("SSNSELECTEDYEARS", r.year);
            }
          });
          setYears(opt);
          setSelectedYearsOption([defaultOption]);
        })
        .catch(() => {});
    }
  }, []);

  const handleChange = (selectedYears) => {
    var selYear = [];
    selectedYears.map((op, idx) => {
      selYear.push(op.value);
    });
    const selYearStr = selYear.join(",");
    setSelectedYearsOption(selectedYears);
    setSelectedYears(selYearStr);
    console.log(`Option selected:`, selYearStr);
    localStorage.setItem("SSNSELECTEDYEARS", selYearStr);
  };

  const showWheelSegmentinfo = (id) => {
    const { url, dataType, primaryTabInstance, ...restProps } = props;
    var type = sessionStorage.getItem("accountType");
    let distUrl = `${config.api.globalDistribution}?id=${id}`;
    if (props.dataType == "compare-accounts") {
      distUrl = `${config.api.comparisonDistribution}?id=${id}`;
    }
    var idx = 0;
    if (dataType == "global") {
      idx = 0;
    } else if (dataType == "disparities") {
      idx = 0;
    } else if (dataType == "hcp") {
      idx = 1;
    } else if (dataType == "accounts") {
      idx = 2;
    } else if (dataType == "comparison") {
      idx = 0;
      distUrl = `${config.api.comparisonDistribution}?id=${id}&years=${selectedYears}&type=${
        type == null ? "parent" : type
      }`;
    }

    GetAPIRequest({
      url: distUrl,
    })
      .then((res) => {
        console.log(res);
        res.map((result, idx) => {
          var tabid = result.category.replace(" ", "-");
          console.log(tabid);
          var tabBarId = `nav-${dataType}-${tabid}-tab`;
          if (primaryTabInstance.isOpen(tabBarId) == true) {
            primaryTabInstance
              .close(tabBarId)
              .then(({ currentData, instance }) => {
                console.log(`${tabBarId} is closed`);
                addPrimaryTab({
                  id: tabBarId,
                  title: result.category,
                  catId: id,
                  urls: result.urls,
                });
              });
          } else {
            addPrimaryTab({
              id: tabBarId,
              title: result.category,
              catId: id,
              urls: result.urls,
            });
          }
        });

        function addPrimaryTab(config) {
          const { id, title, urls, catId } = config;
          primaryTabInstance
            .open({
              id: id,
              closable: false,
              title: title,
              panelComponent: (porps) => {
                return <SecondaryTab urls={urls} />;
              },
            })
            .then(() => {
              console.log("tab is open");
            });
        }
      })
      .catch(() => {});
      setLoading(false);
  };

  const Option = (props) => {
    const {
      children,
      className,
      cx,
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
    } = props;
    return (
      <div
        ref={innerRef}
        css={getStyles("option", props)}
        className={cx(
          {
            option: true,
            "option--is-disabled": isDisabled,
            "option--is-focused": isFocused,
            "option--is-selected": isSelected,
          },
          className
        )}
        style={{ padding: "5px 5px", fontSize: 12 }}
        {...innerProps}
      >
        {children}
      </div>
    );
  };

  const AccountComparisonView = () => {
    return (
      <ComparisonViewWrapper>
        <div className="topSelection">
          <div className="searchContainer">
            <Select
              isSearchable={true}
              isMulti={true}
              isClearable={true}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  width: state.selectProps.width,
                  borderBottom: "1px dotted pink",
                  color: state.selectProps.menuColor,
                  padding: 20,
                  margin: "10px 10px",
                  width: "100%",
                }),
                option: (provided, state) => ({
                  ...provided,
                  borderBottom: "1px dotted pink",
                  color: state.isSelected ? "red" : "blue",
                  padding: 20,
                }),
              }}
              placeholder="Search and add accounts to compare"
              value={selectedAccounts}
              onChange={(d) => {
                var _acc = [];
                d.map((_d) => {
                  _acc.push(_d.value);
                });
                setSelAccounts(_acc);
                setSelectedAccounts(d);
                console.log(d);

                if (d.length == 0) {
                  setShowComparison(false);
                  setSelAccounts(props.accountId);
                  setSelectedAccounts({
                    value: props.accountId,
                    label: props.accountId,
                  });
                } else {
                  setShowComparison(true);
                }
              }}
              className="selContainer"
              components={{ Option }}
              options={accountsData}
            />
            <Button
              type="primary"
              className="btnCompare"
              onClick={() => {
                compareAccounts();
              }}
            >
              Compare
            </Button>
          </div>
        </div>
        <div className="comparisonDivContainer">
          {selAccounts &&
            selAccounts.map((account, idx) => (
              <ComparisonDiv key={idx}>
                <div>{account}</div>
                <ComparisonChart
                  id={account}
                  chartId={`sunburstchart-${idx}`}
                  idx={idx}
                  dataType={props.dataType}
                  onLoaded={(root) => {
                    console.log("wheel", root.title);
                  }}
                  onChange={(id) => {
                    //showWheelSegmentinfo(id);
                  }}
                />
              </ComparisonDiv>
            ))}
        </div>
      </ComparisonViewWrapper>
    );
  };

  const compareAccounts = () => {
    setShowComparison(true);
  };
  return (
    <Spin spinning={loading}>
    <SunburstChartWrapper>
      {showComparison ? (
        <AccountComparisonView />
      ) : (
        <>
          {" "}
          <div className="topSelection">
            <SunburstChartTitle>{title}</SunburstChartTitle>
            {props.dataType && props.dataType == "accounts" && (
              <div className="searchContainer">
                <Select
                  isSearchable={true}
                  isMulti={true}
                  isClearable={true}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      width: state.selectProps.width,
                      borderBottom: "1px dotted pink",
                      color: state.selectProps.menuColor,
                      padding: 20,
                      margin: "10px 10px",
                      width: "100%",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      borderBottom: "1px dotted pink",
                      color: state.isSelected ? "red" : "blue",
                      padding: 20,
                    }),
                  }}
                  placeholder="Search and add accounts to compare"
                  value={selectedAccounts}
                  onChange={(d) => {
                    var _acc = [];
                    d.map((_d) => {
                      _acc.push(_d.value);
                    });
                    setSelAccounts(_acc);
                    setSelectedAccounts(d);
                    if (d.length == 0) {
                      setShowComparison(false);
                      setSelAccounts(props.accountId);
                      setSelectedAccounts({
                        value: props.accountId,
                        label: props.accountId,
                      });
                    } else {
                      setShowComparison(true);
                    }
                    console.log(d);
                  }}
                  components={{ Option }}
                  className="selContainer"
                  options={accountsData}
                />
                <Button
                  type="primary"
                  className="btnCompare"
                  onClick={() => {
                    compareAccounts();
                  }}
                >
                  Compare
                </Button>
              </div>
            )}
            {props.showYears && (
              <div className="selectbox">
                <span>Select Years</span>
                <MultiYearPicker onChange={handleChange} />
              </div>
            )}
          </div>
          <Segmented
            onChange={(val) => {
              setValue(val);
              setIsSankeyVisible(val == "sankey");
            }}
            value={value}
            options={[
              {
                value: "wheel",
                icon: <PieChartOutlined />,
              },
              {
                value: "sankey",
                icon: <ShareAltOutlined />,
              },
            ]}
          />
          {!isSankeyVisible && (
            <PatientPopulationChart
              url={props.url}
              dataType={props.dataType}
              onLoaded={(root) => {
                setTitle(root.title);
                console.log("wheel", root.title);
              }}
              onChange={(id) => {
                showWheelSegmentinfo(id);
              }}
            />
          )}
          {isSankeyVisible && (
            <SankeyPlot
              url={props.url}
              onChange={(id) => {
                showWheelSegmentinfo(id);
              }}
            />
          )}
          <SunburstChartComment>{comment}</SunburstChartComment>
        </>
      )}
    </SunburstChartWrapper>
    </Spin>
  );
};

export default SunBurstChart;
