import { Button, Col, DatePicker, Form, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GetAPIRequest } from "../../services";
import config from "../../config";
import { ProfileSelectionPopupWrapper } from "./ProfileSelectionPopup.styles";
import { isUndefined } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const ProfileSelectionPopup = (props) => {
  const navigate = useNavigate();
  const [modal1Visible, setModal1Visible] = useState(false);

  const [isProfileChanged, setIsProfileChanged] = useState(false);
  const [fromDt, setFromDt] = useState("2019-01-01");
  const [toDt, setToDt] = useState(moment().format(dateFormat));
  const [profile, setProfile] = useState("CLL");
  const [dataSource, setDataSource] = useState("I");
  const [profileVal, setProfileVal] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 16,
          },
        }
      : null;

  useEffect(() => {
    loadData(false);
  }, []);

  function loadData(showPopup) {
    GetAPIRequest({
      url: `${config.api.baseURL}/patient/api/v2/profiles`,
    })
      .then((response) => {
        console.log("response", response);
        if (response) {
          var _dataSource = [];
          var _profiles = [];
          response.map((profile, idx) => {
            var pname = profile.name;
            _profiles.push({
              name: pname,
              value: pname,
              selected: profile.default,
            });
            var userprofile = localStorage.getItem("SSN_USER_PROFILE");
            var dataSource = localStorage.getItem("SSN_USER_DATASOURCE");
            if (profile.default === true) {
              if (userprofile == "" && userprofile == null) {
                setProfile(pname);
                localStorage.setItem("SSN_USER_PROFILE", pname);
              }
              var pDataSource = profile.DataSource;
              var i = 0;
              for (var _dataSourceKey in pDataSource) {
                var selected = false;
                if (dataSource == "" && dataSource == null) {
                  selected =
                    pname && pname === "NSCLC" && i == 1
                      ? true
                      : i == 0
                      ? true
                      : false;
                } else {
                  selected =
                    dataSource == pDataSource[_dataSourceKey] ? true : false;
                }

                _dataSource.push({
                  name: _dataSourceKey,
                  value: pDataSource[_dataSourceKey],
                  selected: selected,
                });
                if (selected) {
                  setDataSource(pDataSource[_dataSourceKey]);
                  localStorage.setItem(
                    "SSN_USER_DATASOURCE",
                    pDataSource[_dataSourceKey]
                  );
                }

                i++;
              }
            }
          });
          setProfiles(_profiles);
          console.log("_profiles", _profiles);
          setDataSources(_dataSource);
          setModal1Visible(showPopup);
          updateProfileInfo();
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  const updateProfileInfo = () => {
    var userprofile = localStorage.getItem("SSN_USER_PROFILE") || "CLL";
    var dataSource = localStorage.getItem("SSN_USER_DATASOURCE") || "I";
    var frmDt = localStorage.getItem("SSN_FROMDT");
    var toDt = localStorage.getItem("SSN_TODT");
    if (frmDt === null || frmDt === "") {
      localStorage.setItem("SSN_FROMDT", "2019-01-01");
    }
    if (toDt === null || toDt === "") {
      localStorage.setItem("SSN_TODT", moment().format(dateFormat));
    }
    setProfileVal(`${userprofile} - ${dataSource} (${frmDt} - ${toDt})`);
    setProfile(userprofile);
    setFromDt(frmDt);
    setToDt(toDt);
    setDataSource(dataSource);
  };
  useEffect(() => {
    //updateProfileInfo();
    return () => {};
  }, []);

  const ProfileSelection = () => (
    <Form.Item label="Profile" required tooltip="This is a required field">
      <Select
        showSearch={false}
        style={{
          width: 200,
        }}
        onChange={(val) => {
          setProfile(val);
          setIsProfileChanged(true);
        }}
        placeholder="Search to Select"
        value={profile || "CLL"}
      >
        {profiles.map((profile, idx) => (
          <Option key={idx} value={profile.value}>
            {profile.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const DataSourceSelection = () => (
    <Form.Item label="DataSource" required tooltip="This is a required field">
      <Select
        showSearch={false}
        style={{
          width: 200,
        }}
        onChange={(val) => {
          setDataSource(val);
          setIsProfileChanged(true);
        }}
        placeholder="Select DataSource"
        value={dataSource}
      >
        {dataSources.map((datasource, idx) => (
          <Option key={idx} value={datasource.value}>
            {datasource.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const DateSelection = () => (
    <Form.Item label="Duration" required tooltip="This is a required field">
      <RangePicker
        onChange={(dates, datesStr) => {
          setFromDt(dates[0]);
          setToDt(dates[1]);
          setIsProfileChanged(true);
        }}
        defaultValue={[moment("2019-01-01", dateFormat), moment()]}
      />
    </Form.Item>
  );

  return (
    <ProfileSelectionPopupWrapper>
      <Button
        type="outline"
        onClick={() => {
          loadData(true);
        }}
      >
        {profileVal}
      </Button>
      <Modal
        title="Select Profile"
        centered
        visible={modal1Visible}
        okText="Update"
        onOk={() => {
          setModal1Visible(false);
          localStorage.setItem("SSN_FROMDT", fromDt);
          localStorage.setItem("SSN_TODT", toDt);
          localStorage.setItem("SSN_USER_DATASOURCE", dataSource);
          localStorage.setItem("SSN_USER_PROFILE", profile);
        }}
        focusTriggerAfterClose={false}
        onCancel={() => setModal1Visible(false)}
        afterClose={() => {
          updateProfileInfo();
          if (isProfileChanged) {
            window.location.href =
              window.location.protocol + "//" + window.location.host;
          }
        }}
      >
        <Row>
          <Col xs={24}>
            <Form form={form} layout={"horizontal"} {...formItemLayout}>
              <ProfileSelection />
              <DataSourceSelection />
              <DateSelection />
            </Form>
          </Col>
        </Row>
      </Modal>
    </ProfileSelectionPopupWrapper>
  );
};

ProfileSelectionPopup.propTypes = {
  // bla: PropTypes.string,
};

ProfileSelectionPopup.defaultProps = {
  // bla: 'test',
};

export default ProfileSelectionPopup;
