import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import "./DonutStyle.css";
import DonutGraph2 from "./DonutGraph2";

class DonutGraph extends Component {
  chart = null;

  componentDidMount() {
    this.renderChartUsingConfig();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  renderChartUsingConfig = () => {
    const usersData = [
      {
        userType: "Active Users",
        count: 10
      },
      {
        userType: "Disabled Users",
        count: 5
      },
      {
        userType: "Trial Users",
        count: 2
      }
    ];

    const colors = ["red", "#7DD08B", "#5ECCC6"];
    const config = {
      innerRadius: 80,
      radius: 110,
      data: usersData,
      events: {
        ready: function({ type, target: chart }) {
          chart.customLegend = document.getElementById("legend");
          const series = chart.series.getIndex(0);

          chart.dataItems.each(function(row, i) {
            const color = series.colors.getIndex(i);
            const data = row.dataContext;

            chart.customLegend.innerHTML += `
            <div>
            <div class="legend-value text-left">${data.count}</div>
              <div class="legend-item d-flex align-items-center mb-3" style="color: ${color};">
                <div class="legend-marker" style="background: ${color};"></div>
                    <div class="legend-label">${data.userType}</div>
              </div></div>`;
          });
        }
      },
      series: [
        {
          type: "PieSeries",
          dataFields: {
            value: "count",
            category: "userType"
          },
          children: [
            {
              type: "Label",
              forceCreate: true,
              text: "9%",
              horizontalCenter: "middle",
              verticalCenter: "middle",
              fontSize: 30,
              tooltipHTML: `<div class="tooltip_column" style="width: auto">Total 
                {values.value.sum}
              users </div>`
            }
          ],
          tooltip: {
            getFillFromObject: false,
            background: {
              fill: "#000",
              strokeWidth: 0,
              fillOpacity: 0.7,
              cornerRadius: 4
            },
            fontSize: "12px",
            filters: [
              {
                type: "DropShadowFilter",
                dx: 1,
                dy: 1,
                blur: 3,
                color: "#373737"
              }
            ]
          },
          slices: {
            states: {
              hover: {
                properties: {
                  scale: 1
                }
              },
              active: {
                properties: {
                  shiftRadius: 0
                }
              }
            },
            stroke: "#fff",
            strokeWidth: 1,
            tooltipHTML: `<div class="tooltip_column" style="width: auto">{count} {userType}</div>`
          },
          colors: {
            list: colors
          },
          ticks: {
            disabled: true
          },
          labels: {
            disabled: true
          }
        }
      ]
    };

    this.chart = am4core.createFromConfig(
      config,
      "demo-chart",
      am4charts.PieChart
    );
  };

  render() {
    return (
      <div className="pie_chart_container d-flex align-items-center">
        <div
          id="demo-chart"
          className="pie_chart flex-grow-1"
          style={{ height: "250px" }}
        />
        <div id="legend" className="pie_chart_legend flex-grow-1" />
      </div>
    );
  }
}

export default DonutGraph;
