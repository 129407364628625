const BASE_URL = "https://api.dev.azbrain.astrazeneca.com";//process.env.REACT_APP_BASE_URL;
const BASE_URL_WITH_PATH = BASE_URL + "/patient/api/v2";
const BASE_URL_WITH_PATH_V3 = BASE_URL + "/patient/api/v3";
const appDefaults = {
  api: {
    baseURL: BASE_URL,
    auth: `${BASE_URL}/identity/ping/login`,
    getYears: `${BASE_URL_WITH_PATH}/incidence/years`,
    getIndications: `${BASE_URL_WITH_PATH}/indication/all`,
    getWheelData: `${BASE_URL_WITH_PATH}/summary/accounts`,
    accountsParent: `${BASE_URL_WITH_PATH}/summary/insights/accounts?type=parent`,
    accountsTopParent: `${BASE_URL_WITH_PATH}/summary/insights/accounts?type=top_parent`,
    globalDistribution: `${BASE_URL_WITH_PATH}/distribution`,
    globalWheelSummary: `${BASE_URL_WITH_PATH}/summary`,
    accountsWheelSummary: `${BASE_URL_WITH_PATH}/summary/accounts`,
    hcpWheelSummary: `${BASE_URL_WITH_PATH}/summary/county`,
    comparisonDistribution: `${BASE_URL_WITH_PATH}/distribution/v2`,
    getSalesRegion: `${BASE_URL_WITH_PATH}/summary/insights/salesRegion`,
    getGeoDistribution: `${BASE_URL_WITH_PATH}/summary/insights/geo`,
    getPhysicians: `${BASE_URL_WITH_PATH}/summary/insights/unmetneeds/category/physician`,
    getBrandNames: `${BASE_URL_WITH_PATH}/summary/insights/salesRegion/brandnames`,
    getHealthCareDisparity: `${BASE_URL_WITH_PATH}/summary/insights/geo/hd`,
    getDisparityCountyInfo: `${BASE_URL_WITH_PATH}/summary/insights/geo/hd/county`,
    getDisparityHdInfo: `${BASE_URL_WITH_PATH}/summary/hd`,
    disparityWheelSummary: `${BASE_URL_WITH_PATH}/summary/insights/geo/hd`,
    physicianJourney: `${BASE_URL_WITH_PATH}/journey/physician`,
    physicianTrending: `${BASE_URL_WITH_PATH}/hcpinfo/trending/prescriberid`,
    reportingTopaccounts: `${BASE_URL_WITH_PATH}/hcpinfo/topaccounts`,
    reportingAccounts: `${BASE_URL_WITH_PATH}/hcpinfo/accounts`,
    reportinghcp: `${BASE_URL_WITH_PATH}/hcpinfo/hcp`,
    drugs: `${BASE_URL_WITH_PATH_V3}/treatments/drugslist`,
    filters: `${BASE_URL_WITH_PATH_V3}/treatments/filterlist`,
    treatedPatients: `${BASE_URL_WITH_PATH_V3}/treatments`,
    trendingPatients: `${BASE_URL_WITH_PATH_V3}/treatments/filter`,
    pagesizelist: `${BASE_URL_WITH_PATH_V3}/treatments`,
    toplowaccountlist: `${BASE_URL_WITH_PATH_V3}/treatments/drug`,
    physicianstoplowaccountlist: `${BASE_URL_WITH_PATH_V3}/treatments`,
    trendingPhysicians:  `${BASE_URL_WITH_PATH_V3}/treatments/physicians`,
    nationalAverage : `${BASE_URL_WITH_PATH_V3}/treatments/nationalavg`,
  },
};

export default appDefaults;
