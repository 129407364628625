import { configureStore } from '@reduxjs/toolkit';
import siteReducer from './siteSlice';
import keeReducer from './keeSlice';
import filterReducer from './filterSlice';

export const store = configureStore({
  reducer: {
    site: siteReducer,
    kee: keeReducer,
    filter: filterReducer
  },
});
