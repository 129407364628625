export default {
	"type": "Topology",
	"objects": {
		"counties": {
			"type": "GeometryCollection",
			"geometries": [
				{
					"type": "Polygon",
					"id": 5089,
					"arcs": [
						[
							0,
							1,
							2,
							3,
							4
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6079,
					"arcs": [
						[
							5,
							6,
							7,
							8,
							9
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17111,
					"arcs": [
						[
							10,
							11,
							12,
							13,
							14,
							15,
							16
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20131,
					"arcs": [
						[
							17,
							18,
							19,
							20,
							21,
							22
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28141,
					"arcs": [
						[
							23,
							24,
							25,
							26,
							27,
							28,
							29
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39009,
					"arcs": [
						[
							30,
							31,
							32,
							33,
							34,
							35,
							36
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48369,
					"arcs": [
						[
							37,
							38,
							39,
							40,
							41
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48211,
					"arcs": [
						[
							42,
							43,
							44,
							45,
							46
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1007,
					"arcs": [
						[
							47,
							48,
							49,
							50,
							51,
							52
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5111,
					"arcs": [
						[
							53,
							54,
							55,
							56,
							57
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19105,
					"arcs": [
						[
							58,
							59,
							60,
							61,
							62,
							63
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20111,
					"arcs": [
						[
							64,
							65,
							66,
							67,
							68,
							69
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20171,
					"arcs": [
						[
							70,
							71,
							72,
							73,
							74,
							75
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22003,
					"arcs": [
						[
							76,
							77,
							78,
							79,
							80
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27161,
					"arcs": [
						[
							81,
							82,
							83,
							84,
							85,
							86
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29203,
					"arcs": [
						[
							87,
							88,
							89,
							90,
							91,
							92
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31039,
					"arcs": [
						[
							93,
							94,
							95,
							96,
							97,
							98
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39021,
					"arcs": [
						[
							99,
							100,
							101,
							102,
							103,
							104
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39137,
					"arcs": [
						[
							105,
							106,
							107,
							108,
							109,
							110
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48113,
					"arcs": [
						[
							111,
							112,
							113,
							114,
							115,
							116
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48173,
					"arcs": [
						[
							117,
							118,
							119,
							120,
							121
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48009,
					"arcs": [
						[
							122,
							123,
							124,
							125,
							126
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29125,
					"arcs": [
						[
							127,
							128,
							129,
							130,
							131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31079,
					"arcs": [
						[
							132,
							133,
							134,
							135,
							136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31123,
					"arcs": [
						[
							137,
							138,
							139,
							140,
							141,
							142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1003,
					"arcs": [
						[
							143,
							144,
							145,
							146,
							147,
							148,
							149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4012,
					"arcs": [
						[
							150,
							151,
							152,
							153,
							154,
							155,
							156
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8063,
					"arcs": [
						[
							157,
							158,
							159,
							160,
							161,
							162,
							163
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18161,
					"arcs": [
						[
							164,
							165,
							166,
							167,
							168
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20189,
					"arcs": [
						[
							169,
							170,
							171,
							172,
							173,
							174
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26019,
					"arcs": [
						[
							175,
							176,
							177,
							178
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30001,
					"arcs": [
						[
							179,
							180,
							181,
							182,
							183,
							184,
							185
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36087,
					"arcs": [
						[
							186,
							187,
							188,
							189,
							190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41055,
					"arcs": [
						[
							191,
							192,
							193
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51700,
					"arcs": [
						[
							194,
							195,
							196,
							197
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56045,
					"arcs": [
						[
							198,
							199,
							200,
							201,
							202,
							203,
							204
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5059,
					"arcs": [
						[
							205,
							206,
							207,
							208,
							209,
							210
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18041,
					"arcs": [
						[
							211,
							212,
							-169,
							213,
							214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18013,
					"arcs": [
						[
							215,
							216,
							217,
							218,
							219
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28043,
					"arcs": [
						[
							220,
							221,
							222,
							223,
							224,
							225,
							226
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31009,
					"arcs": [
						[
							227,
							228,
							229,
							230,
							231,
							232
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37063,
					"arcs": [
						[
							233,
							234,
							235,
							236,
							237
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40021,
					"arcs": [
						[
							238,
							239,
							240,
							241,
							242,
							243
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47083,
					"arcs": [
						[
							244,
							245,
							246,
							247,
							248
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48099,
					"arcs": [
						[
							249,
							250,
							251,
							252,
							253
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48093,
					"arcs": [
						[
							254,
							255,
							256,
							257,
							258
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51685,
					"arcs": [
						[
							259,
							260
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26165,
					"arcs": [
						[
							261,
							262,
							263,
							264,
							265
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51775,
					"arcs": [
						[
							266,
							267
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55035,
					"arcs": [
						[
							268,
							269,
							270,
							271,
							272,
							273,
							274
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48233,
					"arcs": [
						[
							275,
							276,
							277,
							278
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40109,
					"arcs": [
						[
							279,
							280,
							281,
							282,
							283
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4005,
					"arcs": [
						[
							284,
							285,
							286,
							287,
							288,
							289
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2198,
					"arcs": [
						[
							[
								290,
								291
							]
						],
						[
							[
								292
							]
						],
						[
							[
								293
							]
						],
						[
							[
								294
							]
						],
						[
							[
								295,
								296
							]
						],
						[
							[
								297,
								298
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18167,
					"arcs": [
						[
							299,
							300,
							301,
							302,
							303,
							304
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26005,
					"arcs": [
						[
							305,
							306,
							307,
							308,
							309,
							310
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28023,
					"arcs": [
						[
							311,
							312,
							313,
							314
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30053,
					"arcs": [
						[
							315,
							316,
							317,
							318,
							319
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 39123,
					"arcs": [
						[
							[
								320
							]
						],
						[
							[
								321,
								322,
								323,
								324,
								325
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50019,
					"arcs": [
						[
							326,
							327,
							328,
							329,
							330
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6031,
					"arcs": [
						[
							331,
							332,
							333,
							-7,
							334
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17165,
					"arcs": [
						[
							335,
							336,
							337,
							338,
							339,
							340
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19027,
					"arcs": [
						[
							341,
							342,
							343,
							344,
							345,
							346
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20101,
					"arcs": [
						[
							347,
							348,
							349,
							-73
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26117,
					"arcs": [
						[
							350,
							351,
							352,
							353,
							354,
							355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27041,
					"arcs": [
						[
							356,
							357,
							358,
							359,
							360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30049,
					"arcs": [
						[
							361,
							362,
							363,
							364,
							365,
							366,
							367
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29059,
					"arcs": [
						[
							368,
							369,
							370,
							371,
							372,
							373
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37067,
					"arcs": [
						[
							374,
							375,
							376,
							377,
							378,
							379
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38091,
					"arcs": [
						[
							380,
							381,
							382,
							383,
							384,
							385
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40137,
					"arcs": [
						[
							386,
							387,
							388,
							389,
							390,
							391
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48171,
					"arcs": [
						[
							392,
							393,
							394,
							395,
							396,
							397
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48283,
					"arcs": [
						[
							398,
							399,
							400,
							401,
							402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51065,
					"arcs": [
						[
							403,
							404,
							405,
							406,
							407
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55135,
					"arcs": [
						[
							408,
							409,
							410,
							411,
							412
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20093,
					"arcs": [
						[
							413,
							-75,
							414,
							415,
							416
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28129,
					"arcs": [
						[
							417,
							418,
							419,
							420,
							421,
							422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18057,
					"arcs": [
						[
							423,
							424,
							425,
							426,
							427,
							428
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40003,
					"arcs": [
						[
							429,
							430,
							431,
							432,
							433,
							434
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56041,
					"arcs": [
						[
							435,
							436,
							437,
							438
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6017,
					"arcs": [
						[
							439,
							440,
							441,
							442,
							443
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13111,
					"arcs": [
						[
							444,
							445,
							446,
							447,
							448,
							449,
							450
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 23009,
					"arcs": [
						[
							[
								451
							]
						],
						[
							[
								452,
								453,
								454,
								455,
								456,
								457
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27133,
					"arcs": [
						[
							458,
							459,
							460,
							461,
							462
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31145,
					"arcs": [
						[
							463,
							464,
							465,
							466,
							467
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31083,
					"arcs": [
						[
							468,
							469,
							470,
							471,
							472
						]
					]
				},
				{
					"type": "Polygon",
					"id": 44003,
					"arcs": [
						[
							473,
							474,
							475,
							476,
							477
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54061,
					"arcs": [
						[
							478,
							479,
							480,
							481,
							482,
							483
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13165,
					"arcs": [
						[
							484,
							485,
							486,
							487
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16061,
					"arcs": [
						[
							488,
							489,
							490
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16067,
					"arcs": [
						[
							491,
							492,
							493,
							494
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18053,
					"arcs": [
						[
							495,
							496,
							497,
							498,
							499,
							500,
							501,
							502,
							503
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27151,
					"arcs": [
						[
							504,
							505,
							506,
							507,
							508,
							509
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27039,
					"arcs": [
						[
							510,
							511,
							512,
							513,
							514
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31019,
					"arcs": [
						[
							515,
							516,
							517,
							-137,
							518,
							519,
							520,
							521
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46125,
					"arcs": [
						[
							522,
							523,
							524,
							525,
							526,
							527
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48401,
					"arcs": [
						[
							528,
							529,
							530,
							531,
							532,
							533,
							534
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48375,
					"arcs": [
						[
							535,
							536,
							537,
							538
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36123,
					"arcs": [
						[
							539,
							540,
							541,
							542
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26091,
					"arcs": [
						[
							543,
							544,
							545,
							546,
							547,
							548
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27165,
					"arcs": [
						[
							549,
							550,
							551,
							552,
							553
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31151,
					"arcs": [
						[
							554,
							555,
							556,
							557,
							558
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48011,
					"arcs": [
						[
							559,
							560,
							561,
							562,
							563
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8045,
					"arcs": [
						[
							564,
							565,
							566,
							567,
							568,
							569,
							570
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17075,
					"arcs": [
						[
							571,
							572,
							573,
							574,
							575
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19109,
					"arcs": [
						[
							576,
							577,
							578,
							579,
							580,
							581,
							582
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19131,
					"arcs": [
						[
							583,
							584,
							585,
							586,
							587
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20033,
					"arcs": [
						[
							588,
							589,
							590,
							591,
							592
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22091,
					"arcs": [
						[
							593,
							594,
							595,
							596,
							597
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28075,
					"arcs": [
						[
							598,
							599,
							600,
							-312,
							601
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31181,
					"arcs": [
						[
							602,
							603,
							604,
							605,
							606,
							607,
							608
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35015,
					"arcs": [
						[
							609,
							610,
							611,
							612,
							613,
							614
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53023,
					"arcs": [
						[
							615,
							616,
							617,
							618
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5109,
					"arcs": [
						[
							619,
							620,
							621,
							622,
							623
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17039,
					"arcs": [
						[
							624,
							625,
							626,
							627
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26107,
					"arcs": [
						[
							628,
							629,
							-351,
							630
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27005,
					"arcs": [
						[
							631,
							632,
							633,
							634,
							635,
							636,
							637
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29063,
					"arcs": [
						[
							638,
							639,
							640,
							641,
							642,
							643
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30033,
					"arcs": [
						[
							644,
							645,
							646,
							647,
							648,
							649,
							650
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35011,
					"arcs": [
						[
							651,
							652,
							653,
							654,
							655
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46037,
					"arcs": [
						[
							656,
							657,
							658,
							659,
							660,
							661,
							662
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48131,
					"arcs": [
						[
							663,
							664,
							665,
							666,
							667,
							668
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48235,
					"arcs": [
						[
							669,
							670,
							671,
							672
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55099,
					"arcs": [
						[
							673,
							674,
							675,
							676,
							677,
							678,
							679,
							680
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28071,
					"arcs": [
						[
							681,
							682,
							683,
							684,
							685,
							686,
							687
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17099,
					"arcs": [
						[
							688,
							689,
							690,
							691,
							692,
							693,
							694,
							695,
							696
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6071,
					"arcs": [
						[
							697,
							698,
							699,
							700,
							-152,
							701,
							702,
							703
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20007,
					"arcs": [
						[
							704,
							705,
							706,
							-430,
							707,
							-590,
							708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29217,
					"arcs": [
						[
							709,
							710,
							711,
							712,
							713,
							714,
							715
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30089,
					"arcs": [
						[
							-319,
							716,
							717,
							718,
							719,
							720,
							721
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42105,
					"arcs": [
						[
							722,
							723,
							724,
							725,
							726,
							727,
							728
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46033,
					"arcs": [
						[
							729,
							730,
							731,
							732,
							-202
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50009,
					"arcs": [
						[
							733,
							734,
							735,
							736,
							-328
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55071,
					"arcs": [
						[
							737,
							738,
							739,
							740,
							741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12043,
					"arcs": [
						[
							742,
							743,
							744,
							745
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13011,
					"arcs": [
						[
							746,
							747,
							748,
							749,
							750,
							751
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18101,
					"arcs": [
						[
							752,
							753,
							754,
							755,
							756
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27003,
					"arcs": [
						[
							757,
							758,
							759,
							760,
							761,
							762
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29061,
					"arcs": [
						[
							763,
							764,
							765,
							766,
							-640,
							767
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40107,
					"arcs": [
						[
							768,
							769,
							770,
							771,
							772,
							773,
							774
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48023,
					"arcs": [
						[
							775,
							-127,
							776,
							777,
							778
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48269,
					"arcs": [
						[
							779,
							780,
							781,
							782,
							783
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49051,
					"arcs": [
						[
							784,
							785,
							786,
							787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20095,
					"arcs": [
						[
							788,
							789,
							790,
							791,
							792,
							-706
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31047,
					"arcs": [
						[
							793,
							-522,
							794,
							795,
							796,
							797
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27147,
					"arcs": [
						[
							798,
							-515,
							799,
							800,
							-84
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42047,
					"arcs": [
						[
							801,
							802,
							803,
							804,
							805,
							806
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38065,
					"arcs": [
						[
							807,
							808,
							809,
							810
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48151,
					"arcs": [
						[
							811,
							812,
							813,
							814,
							815
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13061,
					"arcs": [
						[
							816,
							817,
							818,
							819,
							820,
							821
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20137,
					"arcs": [
						[
							822,
							-472,
							823,
							824,
							825,
							826
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26127,
					"arcs": [
						[
							827,
							828,
							829,
							830
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28039,
					"arcs": [
						[
							831,
							832,
							833,
							834,
							835
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46039,
					"arcs": [
						[
							836,
							837,
							838,
							839,
							840,
							841,
							842
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2158,
					"arcs": [
						[
							843,
							844,
							845,
							846
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54075,
					"arcs": [
						[
							847,
							848,
							849,
							850,
							851,
							852
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56005,
					"arcs": [
						[
							853,
							854,
							-205,
							855,
							856,
							857
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5115,
					"arcs": [
						[
							858,
							859,
							860,
							861,
							862,
							863,
							864
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13077,
					"arcs": [
						[
							865,
							866,
							867,
							868,
							869,
							870,
							871
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17135,
					"arcs": [
						[
							872,
							873,
							874,
							875,
							876,
							877,
							878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19015,
					"arcs": [
						[
							879,
							880,
							881,
							882,
							883,
							884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28073,
					"arcs": [
						[
							885,
							886,
							887,
							888,
							889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29057,
					"arcs": [
						[
							890,
							891,
							892,
							893,
							894,
							895
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39159,
					"arcs": [
						[
							896,
							897,
							898,
							899,
							900,
							-102,
							901
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40011,
					"arcs": [
						[
							902,
							903,
							904,
							905,
							906,
							907
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47017,
					"arcs": [
						[
							908,
							909,
							910,
							911,
							912,
							913,
							914
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48393,
					"arcs": [
						[
							-277,
							915,
							916,
							917,
							-47,
							918,
							919
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48207,
					"arcs": [
						[
							920,
							921,
							922,
							923,
							924
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55131,
					"arcs": [
						[
							925,
							926,
							927,
							928,
							929
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21157,
					"arcs": [
						[
							930,
							931,
							932,
							933,
							934,
							935
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19117,
					"arcs": [
						[
							936,
							937,
							938,
							939,
							940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48179,
					"arcs": [
						[
							-919,
							941,
							942,
							943
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6049,
					"arcs": [
						[
							944,
							945,
							946,
							947,
							948,
							949
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13143,
					"arcs": [
						[
							950,
							951,
							952,
							953
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21029,
					"arcs": [
						[
							954,
							955,
							956,
							957,
							958
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37165,
					"arcs": [
						[
							959,
							960,
							961,
							962
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39107,
					"arcs": [
						[
							963,
							964,
							965,
							966,
							967,
							968
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47137,
					"arcs": [
						[
							969,
							970,
							971,
							972,
							973,
							974
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49043,
					"arcs": [
						[
							975,
							976,
							-439,
							977,
							978,
							979,
							-786,
							980
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51800,
					"arcs": [
						[
							981,
							982,
							983,
							984,
							985,
							986,
							987
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55101,
					"arcs": [
						[
							988,
							989,
							990,
							991,
							992
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12117,
					"arcs": [
						[
							993,
							994,
							995
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17063,
					"arcs": [
						[
							996,
							997,
							998,
							999,
							-691
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18031,
					"arcs": [
						[
							1000,
							1001,
							1002,
							1003,
							1004,
							1005
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19095,
					"arcs": [
						[
							1006,
							1007,
							1008,
							1009,
							1010
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27019,
					"arcs": [
						[
							1011,
							1012,
							1013,
							1014,
							1015
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38007,
					"arcs": [
						[
							1016,
							1017,
							1018,
							1019,
							1020
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39069,
					"arcs": [
						[
							1021,
							1022,
							1023,
							-108,
							1024,
							1025
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48281,
					"arcs": [
						[
							1026,
							1027,
							-254,
							1028,
							1029,
							1030
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48363,
					"arcs": [
						[
							1031,
							1032,
							1033,
							1034,
							1035,
							1036,
							1037
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55097,
					"arcs": [
						[
							1038,
							-413,
							1039,
							1040,
							1041
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55078,
					"arcs": [
						[
							1042,
							1043,
							1044
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13035,
					"arcs": [
						[
							1045,
							1046,
							1047,
							1048,
							1049,
							1050
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18059,
					"arcs": [
						[
							-426,
							1051,
							1052,
							1053,
							1054,
							1055
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19185,
					"arcs": [
						[
							-940,
							1056,
							1057,
							1058,
							1059
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46097,
					"arcs": [
						[
							1060,
							1061,
							1062,
							1063,
							1064
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48013,
					"arcs": [
						[
							1065,
							1066,
							1067,
							1068,
							1069,
							-400,
							1070,
							1071
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48319,
					"arcs": [
						[
							1072,
							1073,
							1074,
							-393,
							1075,
							1076
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48503,
					"arcs": [
						[
							-126,
							1077,
							-1032,
							1078,
							1079
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55133,
					"arcs": [
						[
							1080,
							-929,
							1081,
							-989,
							1082,
							1083
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41043,
					"arcs": [
						[
							1084,
							1085,
							1086,
							1087,
							1088,
							1089
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48055,
					"arcs": [
						[
							1090,
							1091,
							1092,
							1093,
							1094,
							1095
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21207,
					"arcs": [
						[
							1096,
							1097,
							1098,
							1099,
							1100,
							1101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51595,
					"arcs": [
						[
							1102
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55061,
					"arcs": [
						[
							1103,
							1104,
							1105,
							-739,
							1106
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49037,
					"arcs": [
						[
							1107,
							1108,
							1109,
							1110,
							1111,
							1112,
							1113,
							1114,
							1115,
							-286,
							1116
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55015,
					"arcs": [
						[
							1117,
							1118,
							-742,
							1119,
							1120,
							1121
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18099,
					"arcs": [
						[
							1122,
							1123,
							1124,
							1125,
							1126
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35053,
					"arcs": [
						[
							1127,
							1128,
							1129,
							1130,
							1131,
							1132
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18085,
					"arcs": [
						[
							-1124,
							1133,
							1134,
							1135,
							1136,
							1137
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18113,
					"arcs": [
						[
							1138,
							1139,
							1140,
							1141,
							-1135,
							1142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16059,
					"arcs": [
						[
							1143,
							1144,
							1145,
							-186,
							1146,
							1147,
							1148
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19141,
					"arcs": [
						[
							1149,
							1150,
							1151,
							1152
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19159,
					"arcs": [
						[
							1153,
							1154,
							1155,
							1156,
							1157
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19169,
					"arcs": [
						[
							1158,
							1159,
							1160,
							1161,
							1162,
							-882
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19175,
					"arcs": [
						[
							1163,
							1164,
							1165,
							-1154,
							1166
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19179,
					"arcs": [
						[
							1167,
							1168,
							1169,
							1170,
							1171
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47143,
					"arcs": [
						[
							1172,
							1173,
							1174,
							1175,
							1176
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55087,
					"arcs": [
						[
							1177,
							1178,
							-1118,
							1179,
							-410
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17159,
					"arcs": [
						[
							1180,
							1181,
							1182,
							1183,
							1184,
							1185,
							1186
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17169,
					"arcs": [
						[
							1187,
							1188,
							1189,
							1190,
							1191,
							1192,
							1193
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48079,
					"arcs": [
						[
							1194,
							1195,
							1196,
							1197,
							1198
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20107,
					"arcs": [
						[
							1199,
							1200,
							-710,
							1201,
							1202
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23001,
					"arcs": [
						[
							1203,
							1204,
							1205,
							1206,
							1207
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20001,
					"arcs": [
						[
							1208,
							1209,
							1210,
							1211
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29051,
					"arcs": [
						[
							1212,
							1213,
							1214,
							1215,
							1216
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29053,
					"arcs": [
						[
							1217,
							1218,
							1219,
							1220,
							1221,
							1222
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1057,
					"arcs": [
						[
							1223,
							1224,
							1225,
							1226,
							1227
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41033,
					"arcs": [
						[
							1228,
							1229,
							1230,
							1231,
							1232
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16039,
					"arcs": [
						[
							1233,
							1234,
							1235,
							1236,
							1237,
							1238,
							1239,
							1240
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47033,
					"arcs": [
						[
							1241,
							1242,
							1243,
							1244,
							1245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29071,
					"arcs": [
						[
							1246,
							1247,
							1248,
							1249,
							1250,
							1251,
							1252
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16051,
					"arcs": [
						[
							1253,
							1254,
							1255,
							1256,
							1257,
							1258
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31045,
					"arcs": [
						[
							1259,
							1260,
							1261,
							1262,
							1263
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31091,
					"arcs": [
						[
							1264,
							1265,
							1266,
							1267,
							1268
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17025,
					"arcs": [
						[
							1269,
							1270,
							1271,
							-1187,
							1272,
							1273
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29221,
					"arcs": [
						[
							-1251,
							1274,
							1275,
							1276,
							1277
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29105,
					"arcs": [
						[
							1278,
							1279,
							1280,
							1281,
							1282,
							-371
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29121,
					"arcs": [
						[
							1283,
							1284,
							1285,
							1286,
							1287,
							1288,
							1289
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26093,
					"arcs": [
						[
							1290,
							1291,
							1292,
							1293,
							1294,
							1295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42093,
					"arcs": [
						[
							1296,
							1297,
							1298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26137,
					"arcs": [
						[
							1299,
							1300,
							1301,
							1302,
							1303
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33001,
					"arcs": [
						[
							1304,
							1305,
							1306,
							1307
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31119,
					"arcs": [
						[
							1308,
							1309,
							1310,
							1311,
							1312
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38031,
					"arcs": [
						[
							1313,
							1314,
							1315,
							1316
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31135,
					"arcs": [
						[
							1317,
							1318,
							1319,
							1320,
							1321,
							1322,
							1323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29109,
					"arcs": [
						[
							1324,
							-895,
							1325,
							1326,
							1327,
							1328,
							1329
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48139,
					"arcs": [
						[
							1330,
							-116,
							1331,
							1332,
							1333,
							1334,
							1335
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39065,
					"arcs": [
						[
							1336,
							1337,
							1338,
							-897,
							1339,
							1340,
							1341
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31157,
					"arcs": [
						[
							1342,
							1343,
							-143,
							1344,
							1345
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2195,
					"arcs": [
						[
							[
								1346,
								-296
							]
						],
						[
							[
								1347,
								1348,
								1349,
								1350
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2275,
					"arcs": [
						[
							[
								-1350,
								1351,
								1352,
								1353
							]
						],
						[
							[
								1354
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48163,
					"arcs": [
						[
							1355,
							-1071,
							-399,
							1356,
							1357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38003,
					"arcs": [
						[
							1358,
							-385,
							1359,
							1360,
							1361,
							1362
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38039,
					"arcs": [
						[
							1363,
							-386,
							-1359,
							1364,
							-1315,
							1365
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42113,
					"arcs": [
						[
							1366,
							1367,
							1368,
							1369,
							1370
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39083,
					"arcs": [
						[
							1371,
							1372,
							1373,
							1374,
							1375,
							1376,
							1377
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42117,
					"arcs": [
						[
							1378,
							1379,
							1380,
							1381,
							-725
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5053,
					"arcs": [
						[
							1382,
							1383,
							1384,
							1385,
							1386,
							-208
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31069,
					"arcs": [
						[
							1387,
							1388,
							1389,
							1390,
							1391,
							1392,
							-140
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42123,
					"arcs": [
						[
							1393,
							1394,
							1395,
							1396,
							-802,
							1397,
							1398,
							1399
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51520,
					"arcs": [
						[
							1400,
							1401
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51530,
					"arcs": [
						[
							1402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29215,
					"arcs": [
						[
							-1281,
							1403,
							1404,
							1405,
							-88,
							1406,
							1407,
							1408
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37081,
					"arcs": [
						[
							-377,
							1409,
							1410,
							1411,
							1412
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51610,
					"arcs": [
						[
							1413,
							1414
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37109,
					"arcs": [
						[
							1415,
							1416,
							1417,
							1418,
							1419,
							1420
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51660,
					"arcs": [
						[
							1421
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51690,
					"arcs": [
						[
							1422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19001,
					"arcs": [
						[
							1423,
							1424,
							-1164,
							1425,
							1426
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6053,
					"arcs": [
						[
							1427,
							1428,
							1429,
							-335,
							-6,
							1430
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51790,
					"arcs": [
						[
							1431
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29219,
					"arcs": [
						[
							1432,
							1433,
							-1247,
							1434,
							1435
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19021,
					"arcs": [
						[
							1436,
							1437,
							1438,
							1439
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20073,
					"arcs": [
						[
							1440,
							-69,
							1441,
							1442,
							1443,
							1444,
							1445
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48087,
					"arcs": [
						[
							1446,
							1447,
							1448,
							1449,
							1450,
							1451
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48133,
					"arcs": [
						[
							1452,
							1453,
							-1037,
							1454,
							-255,
							1455,
							1456
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48109,
					"arcs": [
						[
							1457,
							-614,
							1458,
							1459,
							1460
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26037,
					"arcs": [
						[
							1461,
							1462,
							1463,
							1464,
							1465
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19073,
					"arcs": [
						[
							1466,
							1467,
							-885,
							1468,
							1469,
							-344
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19077,
					"arcs": [
						[
							-345,
							-1470,
							1470,
							-1424,
							1471
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26045,
					"arcs": [
						[
							1472,
							-1465,
							1473,
							1474,
							1475,
							1476
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20079,
					"arcs": [
						[
							1477,
							1478,
							1479,
							1480,
							1481
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48121,
					"arcs": [
						[
							1482,
							1483,
							1484,
							-112,
							1485,
							1486
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19195,
					"arcs": [
						[
							1487,
							1488,
							-588,
							1489,
							1490
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26067,
					"arcs": [
						[
							-354,
							-1466,
							-1473,
							1491,
							1492
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26077,
					"arcs": [
						[
							-309,
							1493,
							1494,
							1495,
							1496
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26085,
					"arcs": [
						[
							1497,
							-265,
							1498,
							1499,
							1500
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37183,
					"arcs": [
						[
							-237,
							1501,
							1502,
							1503,
							1504,
							1505
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27067,
					"arcs": [
						[
							1506,
							1507,
							1508,
							1509,
							1510,
							-507
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27159,
					"arcs": [
						[
							1511,
							1512,
							1513,
							1514,
							-636
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37093,
					"arcs": [
						[
							1515,
							1516,
							1517,
							-961
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26123,
					"arcs": [
						[
							-829,
							-1500,
							-631,
							-356,
							1518,
							1519
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21219,
					"arcs": [
						[
							1520,
							1521,
							1522,
							1523,
							1524
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17051,
					"arcs": [
						[
							-876,
							1525,
							1526,
							-1270,
							1527,
							1528,
							1529
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17053,
					"arcs": [
						[
							1530,
							1531,
							-576,
							1532,
							1533,
							1534
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27013,
					"arcs": [
						[
							1535,
							1536,
							-87,
							1537,
							1538,
							-551,
							1539
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48145,
					"arcs": [
						[
							1540,
							1541,
							1542,
							1543,
							1544
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40047,
					"arcs": [
						[
							1545,
							1546,
							1547,
							1548,
							1549,
							1550,
							-433
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46005,
					"arcs": [
						[
							1551,
							1552,
							1553,
							1554,
							1555,
							1556
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46025,
					"arcs": [
						[
							-662,
							1557,
							1558,
							1559,
							-1553,
							1560
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21035,
					"arcs": [
						[
							-935,
							1561,
							1562,
							1563,
							1564
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48015,
					"arcs": [
						[
							1565,
							1566,
							1567,
							1568,
							1569,
							1570
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2105,
					"arcs": [
						[
							[
								1571
							]
						],
						[
							[
								1572,
								1573
							]
						],
						[
							[
								1574,
								1575,
								1576,
								1577
							]
						],
						[
							[
								1578,
								1579,
								-1348,
								1580
							]
						],
						[
							[
								1581,
								1582
							]
						],
						[
							[
								1583,
								1584
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45023,
					"arcs": [
						[
							1585,
							1586,
							1587,
							1588
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46057,
					"arcs": [
						[
							1589,
							-843,
							1590,
							1591,
							-1559
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47077,
					"arcs": [
						[
							-913,
							1592,
							1593,
							1594,
							1595
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46073,
					"arcs": [
						[
							1596,
							-1556,
							1597,
							1598,
							1599,
							1600
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49021,
					"arcs": [
						[
							1601,
							1602,
							1603,
							1604,
							1605
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29085,
					"arcs": [
						[
							1606,
							1607,
							1608,
							-369,
							1609
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27081,
					"arcs": [
						[
							-841,
							1610,
							1611,
							1612,
							1613
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27091,
					"arcs": [
						[
							-552,
							-1539,
							1614,
							-578,
							1615,
							1616
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31003,
					"arcs": [
						[
							1617,
							1618,
							-1313,
							1619,
							1620,
							1621
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29007,
					"arcs": [
						[
							1622,
							1623,
							1624,
							1625,
							1626,
							1627,
							1628
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29009,
					"arcs": [
						[
							1629,
							-1329,
							1630,
							1631,
							1632,
							1633
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51049,
					"arcs": [
						[
							1634,
							-407,
							1635,
							1636,
							1637,
							1638
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55107,
					"arcs": [
						[
							1639,
							-680,
							1640,
							1641,
							1642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55033,
					"arcs": [
						[
							1643,
							1644,
							-275,
							1645,
							1646,
							1647
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56025,
					"arcs": [
						[
							1648,
							1649,
							1650,
							1651,
							1652
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56029,
					"arcs": [
						[
							1653,
							1654,
							1655,
							1656,
							1657,
							1658,
							1659,
							1660
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27099,
					"arcs": [
						[
							-800,
							-514,
							1661,
							1662,
							1663,
							-584,
							-1489,
							1664
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6069,
					"arcs": [
						[
							1665,
							1666,
							1667,
							1668,
							-1429
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19025,
					"arcs": [
						[
							1669,
							1670,
							-1467,
							-343,
							1671
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40037,
					"arcs": [
						[
							1672,
							1673,
							1674,
							-770,
							1675,
							1676
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51840,
					"arcs": [
						[
							1677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20003,
					"arcs": [
						[
							1678,
							-1203,
							-1209,
							1679
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40039,
					"arcs": [
						[
							1680,
							-907,
							1681,
							1682,
							1683,
							1684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6091,
					"arcs": [
						[
							1685,
							1686,
							1687,
							1688,
							1689
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19047,
					"arcs": [
						[
							1690,
							1691,
							-347,
							1692,
							1693,
							1694
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6061,
					"arcs": [
						[
							1695,
							1696,
							1697,
							1698,
							1699,
							-444,
							1700,
							1701
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19051,
					"arcs": [
						[
							-1171,
							1702,
							1703,
							1704,
							1705
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17105,
					"arcs": [
						[
							-1000,
							1706,
							-1531,
							1707,
							1708,
							-692
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1017,
					"arcs": [
						[
							1709,
							1710,
							1711,
							1712,
							1713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1031,
					"arcs": [
						[
							1714,
							1715,
							1716,
							1717,
							1718
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17113,
					"arcs": [
						[
							1719,
							-1708,
							-1535,
							1720,
							1721,
							-625,
							1722,
							1723
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8121,
					"arcs": [
						[
							1724,
							1725,
							1726,
							1727,
							-158,
							1728,
							1729
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19079,
					"arcs": [
						[
							1730,
							1731,
							-1159,
							-881,
							1732
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20153,
					"arcs": [
						[
							1733,
							1734,
							1735,
							-467,
							1736,
							1737,
							1738,
							1739
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39119,
					"arcs": [
						[
							1740,
							1741,
							1742,
							1743,
							1744,
							1745
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39127,
					"arcs": [
						[
							1746,
							-1745,
							1747,
							-32,
							1748,
							1749
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48367,
					"arcs": [
						[
							1750,
							1751,
							1752,
							1753,
							1754,
							-1034
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19113,
					"arcs": [
						[
							1755,
							1756,
							-64,
							1757,
							1758,
							1759
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8105,
					"arcs": [
						[
							1760,
							1761,
							1762,
							1763,
							1764
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16085,
					"arcs": [
						[
							1765,
							1766,
							-1144,
							1767,
							1768,
							1769
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17175,
					"arcs": [
						[
							1770,
							1771,
							1772,
							1773,
							1774
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19157,
					"arcs": [
						[
							1775,
							-1011,
							1776,
							1777,
							1778
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48399,
					"arcs": [
						[
							1779,
							1780,
							1781,
							1782,
							1783,
							1784
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19153,
					"arcs": [
						[
							-883,
							-1163,
							1785,
							1786,
							1787,
							1788
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48285,
					"arcs": [
						[
							1789,
							1790,
							1791,
							1792,
							1793,
							1794
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20077,
					"arcs": [
						[
							-793,
							1795,
							1796,
							-431,
							-707
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17091,
					"arcs": [
						[
							-999,
							1797,
							1798,
							1799,
							-572,
							-1532,
							-1707
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19075,
					"arcs": [
						[
							1800,
							1801,
							1802,
							1803,
							1804
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48397,
					"arcs": [
						[
							1805,
							1806,
							1807,
							-114
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48413,
					"arcs": [
						[
							-671,
							1808,
							1809,
							1810,
							1811
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27149,
					"arcs": [
						[
							1812,
							1813,
							-505,
							1814,
							1815
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27109,
					"arcs": [
						[
							-513,
							1816,
							1817,
							1818,
							1819,
							-1662
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27171,
					"arcs": [
						[
							1820,
							1821,
							1822,
							-1012,
							1823,
							1824
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48359,
					"arcs": [
						[
							1825,
							1826,
							-539,
							1827,
							1828
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27121,
					"arcs": [
						[
							-360,
							1829,
							-1507,
							-506,
							-1814
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48345,
					"arcs": [
						[
							1830,
							1831,
							1832,
							1833,
							1834
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29115,
					"arcs": [
						[
							1835,
							1836,
							1837,
							-1290,
							1838,
							1839
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13307,
					"arcs": [
						[
							1840,
							1841,
							1842,
							1843,
							1844
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13135,
					"arcs": [
						[
							1845,
							1846,
							1847,
							1848,
							1849,
							1850,
							1851
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55073,
					"arcs": [
						[
							1852,
							1853,
							1854,
							1855,
							-1039,
							1856,
							1857
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5037,
					"arcs": [
						[
							1858,
							-57,
							1859,
							1860,
							1861
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2122,
					"arcs": [
						[
							[
								1862,
								1863,
								1864
							]
						],
						[
							[
								1865,
								1866,
								1867,
								1868,
								1869
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27095,
					"arcs": [
						[
							1870,
							1871,
							1872,
							1873,
							1874,
							1875,
							1876
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39115,
					"arcs": [
						[
							-1744,
							1877,
							1878,
							-33,
							-1748
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39129,
					"arcs": [
						[
							1879,
							1880,
							1881,
							1882,
							1883,
							1884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39131,
					"arcs": [
						[
							1885,
							1886,
							1887,
							1888,
							1889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46119,
					"arcs": [
						[
							1890,
							1891,
							1892,
							1893,
							1894
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29079,
					"arcs": [
						[
							1895,
							1896,
							-1836,
							1897,
							-765,
							1898
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47081,
					"arcs": [
						[
							1899,
							1900,
							1901,
							1902,
							1903,
							1904
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48049,
					"arcs": [
						[
							1905,
							-1456,
							-259,
							1906,
							1907,
							1908,
							1909
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48293,
					"arcs": [
						[
							1910,
							1911,
							1912,
							1913,
							1914,
							-1542,
							1915
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48165,
					"arcs": [
						[
							1916,
							1917,
							1918,
							1919,
							1920,
							1921
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48021,
					"arcs": [
						[
							1922,
							1923,
							1924,
							1925,
							-1092
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55009,
					"arcs": [
						[
							1926,
							1927,
							-1107,
							-738,
							-1119,
							-1179,
							1928
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13065,
					"arcs": [
						[
							1929,
							1930,
							1931,
							1932,
							1933,
							1934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55127,
					"arcs": [
						[
							1935,
							-1083,
							-993,
							1936,
							-11,
							1937,
							1938
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51580,
					"arcs": [
						[
							1939
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32510,
					"arcs": [
						[
							1940,
							1941,
							1942,
							-1699
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19127,
					"arcs": [
						[
							1943,
							-1804,
							1944,
							1945,
							-1161
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37047,
					"arcs": [
						[
							1946,
							1947,
							1948,
							1949,
							1950
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2220,
					"arcs": [
						[
							[
								-299,
								1951
							]
						],
						[
							[
								1952
							]
						],
						[
							[
								-1573,
								1953
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13155,
					"arcs": [
						[
							1954,
							1955,
							1956,
							1957,
							1958
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38089,
					"arcs": [
						[
							1959,
							1960,
							1961,
							1962,
							1963,
							1964,
							-1019
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2188,
					"arcs": [
						[
							1965,
							1966,
							1967,
							1968
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20145,
					"arcs": [
						[
							1969,
							1970,
							1971,
							1972,
							1973,
							1974
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20185,
					"arcs": [
						[
							-1972,
							1975,
							1976,
							1977,
							1978,
							1979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41021,
					"arcs": [
						[
							1980,
							1981,
							1982,
							1983,
							-193
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19121,
					"arcs": [
						[
							1984,
							1985,
							1986,
							-1165,
							-1425
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48451,
					"arcs": [
						[
							1987,
							1988,
							-1784,
							1989,
							-1809,
							-670,
							1990
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12049,
					"arcs": [
						[
							1991,
							1992,
							1993,
							1994
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39165,
					"arcs": [
						[
							1995,
							1996,
							1997,
							1998,
							1999,
							2000
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12011,
					"arcs": [
						[
							2001,
							2002,
							2003,
							2004,
							2005
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39175,
					"arcs": [
						[
							2006,
							2007,
							2008,
							-1338,
							2009
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39059,
					"arcs": [
						[
							2010,
							2011,
							2012,
							2013,
							2014,
							-1742
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39063,
					"arcs": [
						[
							2015,
							2016,
							-2010,
							-1337,
							2017,
							-109
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39027,
					"arcs": [
						[
							-1998,
							2018,
							2019,
							2020,
							2021,
							2022
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48501,
					"arcs": [
						[
							-1199,
							2023,
							-1917,
							2024
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13117,
					"arcs": [
						[
							2025,
							2026,
							2027,
							-1847,
							2028
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48159,
					"arcs": [
						[
							2029,
							2030,
							2031,
							2032,
							2033,
							2034
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36023,
					"arcs": [
						[
							2035,
							2036,
							2037,
							2038,
							2039,
							2040,
							2041
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48343,
					"arcs": [
						[
							2042,
							2043,
							2044,
							2045,
							2046,
							2047,
							2048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31071,
					"arcs": [
						[
							2049,
							2050,
							2051,
							2052,
							2053
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40143,
					"arcs": [
						[
							2054,
							2055,
							2056,
							2057,
							2058,
							2059,
							-1674
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51187,
					"arcs": [
						[
							2060,
							2061,
							2062,
							2063,
							2064,
							2065
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40019,
					"arcs": [
						[
							-389,
							2066,
							2067,
							2068,
							2069,
							2070,
							2071
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51680,
					"arcs": [
						[
							2072,
							2073,
							2074
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40099,
					"arcs": [
						[
							2075,
							2076,
							2077,
							-2068
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40127,
					"arcs": [
						[
							2078,
							2079,
							2080,
							2081,
							2082,
							2083
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1105,
					"arcs": [
						[
							-52,
							2084,
							2085,
							2086,
							2087
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1075,
					"arcs": [
						[
							2088,
							2089,
							-1228,
							2090,
							2091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1127,
					"arcs": [
						[
							2092,
							2093,
							2094,
							2095,
							2096,
							-1225,
							2097
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28097,
					"arcs": [
						[
							-225,
							2098,
							2099,
							2100,
							2101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28119,
					"arcs": [
						[
							2102,
							2103,
							2104,
							2105
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29025,
					"arcs": [
						[
							-641,
							-767,
							2106,
							2107,
							2108,
							2109
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51750,
					"arcs": [
						[
							2110,
							2111
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54033,
					"arcs": [
						[
							2112,
							2113,
							2114,
							2115,
							2116,
							2117,
							2118
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29049,
					"arcs": [
						[
							-642,
							-2110,
							2119,
							2120,
							2121,
							2122
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54101,
					"arcs": [
						[
							2123,
							2124,
							2125,
							2126,
							-848,
							2127,
							2128
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28099,
					"arcs": [
						[
							2129,
							2130,
							2131,
							2132
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12035,
					"arcs": [
						[
							2133,
							2134,
							2135,
							2136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13007,
					"arcs": [
						[
							2137,
							2138,
							2139,
							2140,
							2141,
							2142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28013,
					"arcs": [
						[
							-686,
							2143,
							2144,
							2145,
							-223,
							2146
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28061,
					"arcs": [
						[
							2147,
							-315,
							2148,
							2149,
							-419
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46089,
					"arcs": [
						[
							2150,
							2151,
							2152,
							2153,
							2154,
							2155
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13131,
					"arcs": [
						[
							2156,
							2157,
							2158,
							2159,
							2160
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27111,
					"arcs": [
						[
							2161,
							-637,
							-1515,
							2162,
							-357,
							2163,
							2164
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1107,
					"arcs": [
						[
							2165,
							-2091,
							-1227,
							2166,
							2167,
							2168,
							2169
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19143,
					"arcs": [
						[
							2170,
							2171,
							2172,
							-1150,
							2173
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51179,
					"arcs": [
						[
							2174,
							2175,
							2176,
							2177,
							2178,
							2179,
							2180,
							2181,
							2182
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17123,
					"arcs": [
						[
							2183,
							2184,
							-694,
							2185,
							2186,
							-1773
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31063,
					"arcs": [
						[
							2187,
							-797,
							2188,
							2189,
							-464,
							2190,
							2191
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18171,
					"arcs": [
						[
							2192,
							2193,
							2194,
							2195,
							2196
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26035,
					"arcs": [
						[
							2197,
							2198,
							2199,
							2200,
							2201
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26051,
					"arcs": [
						[
							2202,
							2203,
							2204,
							2205,
							2206,
							-2200
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2185,
					"arcs": [
						[
							2207,
							-1966,
							2208
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51145,
					"arcs": [
						[
							-1637,
							2209,
							2210,
							2211
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17171,
					"arcs": [
						[
							2212,
							2213,
							2214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26119,
					"arcs": [
						[
							2215,
							2216,
							2217,
							2218,
							-1302
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55039,
					"arcs": [
						[
							2219,
							-1121,
							2220,
							-926,
							2221,
							2222
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20165,
					"arcs": [
						[
							2223,
							2224,
							2225,
							-1970,
							2226
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17089,
					"arcs": [
						[
							-15,
							2227,
							2228,
							2229,
							2230
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38019,
					"arcs": [
						[
							2231,
							2232,
							2233,
							2234,
							2235
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18069,
					"arcs": [
						[
							2236,
							2237,
							2238,
							-497,
							2239
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20053,
					"arcs": [
						[
							2240,
							2241,
							2242,
							2243,
							2244,
							2245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19087,
					"arcs": [
						[
							2246,
							2247,
							2248,
							2249,
							2250,
							2251
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19171,
					"arcs": [
						[
							2252,
							2253,
							-1776,
							-1945,
							-1803
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18011,
					"arcs": [
						[
							2254,
							-428,
							2255,
							2256,
							2257
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38047,
					"arcs": [
						[
							2258,
							2259,
							2260,
							2261,
							2262
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19173,
					"arcs": [
						[
							2263,
							-1158,
							2264,
							2265,
							2266
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6057,
					"arcs": [
						[
							-1689,
							2267,
							-1697,
							2268
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55115,
					"arcs": [
						[
							2269,
							-1044,
							2270,
							-1929,
							-1178,
							-409,
							-1856
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16049,
					"arcs": [
						[
							2271,
							-490,
							2272,
							2273,
							2274,
							-1145,
							-1767,
							2275,
							2276
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6089,
					"arcs": [
						[
							2277,
							-949,
							2278,
							2279,
							2280,
							2281
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46029,
					"arcs": [
						[
							-661,
							2282,
							-837,
							-1590,
							-1558
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55019,
					"arcs": [
						[
							2283,
							-1858,
							2284,
							2285,
							-270,
							2286
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20123,
					"arcs": [
						[
							2287,
							2288,
							2289,
							2290,
							2291
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20143,
					"arcs": [
						[
							2292,
							2293,
							2294,
							2295,
							2296,
							-2290
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27119,
					"arcs": [
						[
							2297,
							2298,
							2299,
							2300,
							2301,
							2302,
							2303,
							2304,
							2305
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20195,
					"arcs": [
						[
							2306,
							2307,
							2308,
							2309,
							2310
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5049,
					"arcs": [
						[
							2311,
							2312,
							2313,
							2314,
							2315,
							2316
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36049,
					"arcs": [
						[
							2317,
							2318,
							2319,
							2320,
							2321
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5141,
					"arcs": [
						[
							2322,
							2323,
							2324,
							2325,
							2326,
							-861
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51720,
					"arcs": [
						[
							2327
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29035,
					"arcs": [
						[
							-91,
							2328,
							2329,
							2330,
							2331,
							2332
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48267,
					"arcs": [
						[
							2333,
							-1076,
							-398,
							2334,
							2335,
							2336
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29067,
					"arcs": [
						[
							2337,
							2338,
							-1408,
							2339,
							2340,
							2341,
							2342
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48317,
					"arcs": [
						[
							-1920,
							2343,
							2344,
							-118,
							2345,
							2346
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29101,
					"arcs": [
						[
							2347,
							2348,
							2349,
							2350,
							2351
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48033,
					"arcs": [
						[
							2352,
							2353,
							2354,
							2355,
							2356,
							2357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29083,
					"arcs": [
						[
							2358,
							-2351,
							2359,
							2360,
							2361,
							2362
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40031,
					"arcs": [
						[
							2363,
							2364,
							2365,
							-392,
							2366,
							2367
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48305,
					"arcs": [
						[
							2368,
							2369,
							-2353,
							2370,
							2371
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29153,
					"arcs": [
						[
							2372,
							-2341,
							2373,
							-2312,
							2374,
							-2
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8005,
					"arcs": [
						[
							2375,
							2376,
							-1730,
							2377,
							2378,
							2379,
							2380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8101,
					"arcs": [
						[
							2381,
							2382,
							2383,
							2384,
							2385,
							2386,
							2387
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29187,
					"arcs": [
						[
							-1276,
							2388,
							2389,
							2390,
							2391,
							2392
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8061,
					"arcs": [
						[
							2393,
							2394,
							2395,
							2396,
							2397,
							2398,
							2399
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8119,
					"arcs": [
						[
							2400,
							2401,
							2402,
							2403,
							2404
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48259,
					"arcs": [
						[
							-396,
							2405,
							2406,
							2407,
							2408,
							2409
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48227,
					"arcs": [
						[
							-2357,
							2410,
							2411,
							-119,
							-2345
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29213,
					"arcs": [
						[
							2412,
							-2342,
							-2373,
							-1,
							2413,
							2414,
							2415
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20019,
					"arcs": [
						[
							2416,
							2417,
							2418,
							2419,
							2420
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1131,
					"arcs": [
						[
							2421,
							2422,
							2423,
							2424,
							2425,
							2426
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26073,
					"arcs": [
						[
							-2201,
							2427,
							2428,
							-352,
							-630
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31117,
					"arcs": [
						[
							-1267,
							2429,
							2430,
							2431,
							2432,
							2433
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48069,
					"arcs": [
						[
							2434,
							2435,
							2436,
							2437,
							2438,
							-40
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20075,
					"arcs": [
						[
							2439,
							2440,
							-417,
							2441,
							2442
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38033,
					"arcs": [
						[
							2443,
							-1021,
							2444,
							2445,
							2446
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19125,
					"arcs": [
						[
							2447,
							2448,
							2449,
							-938,
							2450,
							-1787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20081,
					"arcs": [
						[
							2451,
							2452,
							2453,
							2454,
							-172,
							2455
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26015,
					"arcs": [
						[
							2456,
							-1492,
							-1477,
							2457,
							-1494,
							-308
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48435,
					"arcs": [
						[
							-1811,
							-2337,
							2458,
							2459,
							2460
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40093,
					"arcs": [
						[
							2461,
							-434,
							-1551,
							2462,
							-903,
							2463,
							2464
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48303,
					"arcs": [
						[
							2465,
							2466,
							-2369,
							2467
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30029,
					"arcs": [
						[
							2468,
							2469,
							2470,
							2471,
							-368,
							2472,
							2473,
							2474,
							-717,
							-318
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39099,
					"arcs": [
						[
							2475,
							2476,
							2477,
							2478,
							2479,
							2480
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48205,
					"arcs": [
						[
							2481,
							2482,
							-1826,
							2483,
							2484
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17073,
					"arcs": [
						[
							2485,
							2486,
							2487,
							-1771,
							2488,
							2489
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31093,
					"arcs": [
						[
							2490,
							2491,
							2492,
							-133,
							-518,
							2493
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48143,
					"arcs": [
						[
							-1036,
							2494,
							2495,
							2496,
							2497,
							-256,
							-1455
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51620,
					"arcs": [
						[
							2498,
							2499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19191,
					"arcs": [
						[
							2500,
							2501,
							2502,
							2503,
							2504,
							2505
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46081,
					"arcs": [
						[
							2506,
							2507,
							2508,
							-200,
							2509
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2180,
					"arcs": [
						[
							[
								-1968,
								2510,
								-844,
								2511
							]
						],
						[
							[
								2512
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20193,
					"arcs": [
						[
							-1738,
							2513,
							2514,
							2515,
							2516,
							2517
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39011,
					"arcs": [
						[
							2518,
							2519,
							-1341,
							2520,
							2521,
							-966
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17117,
					"arcs": [
						[
							2522,
							2523,
							-879,
							2524,
							2525,
							2526
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27131,
					"arcs": [
						[
							2527,
							2528,
							2529,
							-511,
							-799,
							-83,
							2530
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42075,
					"arcs": [
						[
							2531,
							2532,
							2533,
							2534
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51570,
					"arcs": [
						[
							2535,
							2536
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20127,
					"arcs": [
						[
							2537,
							2538,
							-65,
							2539,
							2540,
							2541
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27085,
					"arcs": [
						[
							-1824,
							-1016,
							2542,
							2543,
							2544
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13149,
					"arcs": [
						[
							2545,
							-871,
							2546,
							2547
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28139,
					"arcs": [
						[
							2548,
							2549,
							2550,
							2551,
							2552
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33007,
					"arcs": [
						[
							2553,
							2554,
							2555,
							2556,
							-735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47125,
					"arcs": [
						[
							2557,
							-1525,
							2558,
							2559,
							2560,
							-246,
							2561
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5129,
					"arcs": [
						[
							2562,
							-4,
							2563,
							2564,
							-2323,
							-860,
							2565
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13013,
					"arcs": [
						[
							2566,
							2567,
							2568,
							-1849
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19033,
					"arcs": [
						[
							-1490,
							-587,
							2569,
							2570,
							2571
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28127,
					"arcs": [
						[
							2572,
							-422,
							2573,
							2574,
							2575,
							2576
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38103,
					"arcs": [
						[
							2577,
							2578,
							2579,
							-1317,
							2580,
							2581,
							2582
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48031,
					"arcs": [
						[
							2583,
							2584,
							2585,
							2586,
							2587,
							-2406,
							-395
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55129,
					"arcs": [
						[
							2588,
							2589,
							2590,
							2591
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13177,
					"arcs": [
						[
							2592,
							2593,
							2594,
							2595,
							2596
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19081,
					"arcs": [
						[
							2597,
							-2572,
							2598,
							-581
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56031,
					"arcs": [
						[
							2599,
							2600,
							2601,
							2602,
							2603
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19035,
					"arcs": [
						[
							-1152,
							-1440,
							2604,
							2605,
							2606
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28103,
					"arcs": [
						[
							2607,
							2608,
							-2170,
							2609,
							2610,
							2611
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31109,
					"arcs": [
						[
							2612,
							2613,
							2614,
							2615,
							2616,
							2617,
							-556
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18047,
					"arcs": [
						[
							-214,
							-168,
							2618,
							2619,
							2620,
							-1003,
							2621
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36003,
					"arcs": [
						[
							2622,
							2623,
							2624,
							-723,
							2625,
							2626
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28085,
					"arcs": [
						[
							2627,
							2628,
							2629,
							2630,
							2631,
							2632,
							2633
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23029,
					"arcs": [
						[
							2634,
							2635,
							2636,
							-454
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17027,
					"arcs": [
						[
							2637,
							2638,
							-1529,
							2639,
							2640,
							2641
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39023,
					"arcs": [
						[
							2642,
							-104,
							2643,
							2644,
							2645
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8087,
					"arcs": [
						[
							2646,
							-1726,
							2647,
							2648
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20015,
					"arcs": [
						[
							-1480,
							2649,
							2650,
							-1446,
							2651,
							2652,
							2653
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18007,
					"arcs": [
						[
							-574,
							2654,
							2655,
							2656,
							2657,
							-2193,
							2658
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17107,
					"arcs": [
						[
							2659,
							-1723,
							-628,
							2660,
							2661,
							2662,
							2663
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55085,
					"arcs": [
						[
							2664,
							2665,
							2666,
							2667,
							-677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37135,
					"arcs": [
						[
							2668,
							2669,
							-234,
							2670,
							2671
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48081,
					"arcs": [
						[
							2672,
							2673,
							-1785,
							-1989,
							2674
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40009,
					"arcs": [
						[
							2675,
							-1684,
							2676,
							2677,
							2678,
							2679,
							-1448,
							2680
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31033,
					"arcs": [
						[
							2681,
							-141,
							-1393,
							2682,
							2683,
							2684,
							2685
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19083,
					"arcs": [
						[
							2686,
							-1805,
							-1944,
							-1160,
							-1732
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27125,
					"arcs": [
						[
							2687,
							-2300
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28069,
					"arcs": [
						[
							2688,
							-2611,
							2689,
							-599,
							-2131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39163,
					"arcs": [
						[
							2690,
							-37,
							2691,
							2692,
							2693,
							2694
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1083,
					"arcs": [
						[
							2695,
							2696,
							2697,
							2698,
							2699,
							2700
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20085,
					"arcs": [
						[
							-21,
							2701,
							2702,
							2703,
							2704,
							2705
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8025,
					"arcs": [
						[
							2706,
							-2400,
							2707,
							-2384
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20175,
					"arcs": [
						[
							-2455,
							2708,
							2709,
							2710,
							-173
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48111,
					"arcs": [
						[
							2711,
							2712,
							-2482,
							2713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21109,
					"arcs": [
						[
							2714,
							2715,
							2716,
							2717,
							2718,
							2719,
							2720
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31035,
					"arcs": [
						[
							2721,
							2722,
							2723,
							2724,
							-605,
							2725
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51830,
					"arcs": [
						[
							2726,
							2727
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20067,
					"arcs": [
						[
							-416,
							-2456,
							-171,
							2728
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31111,
					"arcs": [
						[
							-2432,
							2729,
							2730,
							-798,
							-2188,
							2731,
							-1319,
							2732
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29095,
					"arcs": [
						[
							2733,
							2734,
							2735,
							2736,
							-2348,
							2737,
							2738
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16015,
					"arcs": [
						[
							-1769,
							2739,
							-1235,
							2740,
							2741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21033,
					"arcs": [
						[
							2742,
							2743,
							2744,
							2745,
							2746,
							2747
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20069,
					"arcs": [
						[
							2748,
							2749,
							2750,
							-2453,
							2751
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17101,
					"arcs": [
						[
							2752,
							2753,
							2754,
							-1183
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27137,
					"arcs": [
						[
							2755,
							2756,
							2757,
							2758,
							2759,
							2760,
							2761,
							2762
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54069,
					"arcs": [
						[
							2763,
							2764,
							2765,
							2766,
							2767
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31023,
					"arcs": [
						[
							2768,
							2769,
							2770,
							2771,
							-2614,
							2772,
							2773
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20023,
					"arcs": [
						[
							2774,
							-1740,
							2775,
							-160,
							2776
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12027,
					"arcs": [
						[
							2777,
							-1994,
							2778,
							2779,
							2780
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48107,
					"arcs": [
						[
							2781,
							2782,
							2783,
							-2467
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19067,
					"arcs": [
						[
							-586,
							2784,
							2785,
							-2570
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48123,
					"arcs": [
						[
							2786,
							-1794,
							2787,
							2788,
							2789
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55027,
					"arcs": [
						[
							2790,
							2791,
							-2222,
							-930,
							-1081,
							2792,
							2793
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27065,
					"arcs": [
						[
							2794,
							2795,
							2796,
							-1872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46061,
					"arcs": [
						[
							2797,
							-1064,
							2798,
							2799,
							2800
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51029,
					"arcs": [
						[
							2801,
							-408,
							-1635,
							2802,
							2803,
							2804
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29229,
					"arcs": [
						[
							-1282,
							-1409,
							-2339,
							2805
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18111,
					"arcs": [
						[
							2806,
							2807,
							-2655,
							-573,
							-1800
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19069,
					"arcs": [
						[
							-2571,
							2808,
							-2687,
							2809
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39135,
					"arcs": [
						[
							2810,
							2811,
							2812,
							2813,
							-166
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13273,
					"arcs": [
						[
							-1843,
							2814,
							-2596,
							2815,
							2816,
							2817
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29159,
					"arcs": [
						[
							-2350,
							2818,
							2819,
							-1223,
							2820,
							2821,
							-2360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41065,
					"arcs": [
						[
							2822,
							2823,
							-194,
							-1984,
							2824,
							2825,
							2826,
							2827
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55053,
					"arcs": [
						[
							-271,
							-2286,
							2828,
							2829,
							2830,
							2831,
							2832
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29199,
					"arcs": [
						[
							-1704,
							2833,
							2834,
							2835,
							2836,
							2837
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13069,
					"arcs": [
						[
							-1957,
							2838,
							2839,
							2840,
							2841,
							2842,
							2843,
							2844
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26069,
					"arcs": [
						[
							2845,
							2846,
							2847,
							2848
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17199,
					"arcs": [
						[
							2849,
							-341,
							2850,
							2851,
							2852
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36093,
					"arcs": [
						[
							2853,
							2854,
							2855,
							2856
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1133,
					"arcs": [
						[
							2857,
							2858,
							2859,
							-2093,
							2860
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28079,
					"arcs": [
						[
							2861,
							2862,
							-2133,
							2863,
							2864
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46049,
					"arcs": [
						[
							2865,
							2866,
							2867,
							2868,
							2869,
							2870
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20105,
					"arcs": [
						[
							-2291,
							-2297,
							2871,
							-2241,
							2872,
							2873
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18183,
					"arcs": [
						[
							-1142,
							2874,
							-2237,
							2875,
							-1136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6109,
					"arcs": [
						[
							2876,
							2877,
							2878,
							2879,
							2880,
							2881
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46085,
					"arcs": [
						[
							2882,
							2883,
							2884,
							2885,
							2886,
							2887,
							2888,
							2889,
							2890,
							2891
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 6083,
					"arcs": [
						[
							[
								2892
							]
						],
						[
							[
								2893
							]
						],
						[
							[
								2894
							]
						],
						[
							[
								-9,
								2895,
								2896,
								2897
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26095,
					"arcs": [
						[
							2898,
							2899,
							2900,
							2901,
							2902
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8011,
					"arcs": [
						[
							-2398,
							2903,
							2904,
							2905,
							2906
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29065,
					"arcs": [
						[
							2907,
							2908,
							2909,
							-89,
							-1406,
							2910
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48169,
					"arcs": [
						[
							-2784,
							2911,
							2912,
							-2354,
							-2370
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48417,
					"arcs": [
						[
							-923,
							2913,
							2914,
							-1453,
							2915,
							2916
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40051,
					"arcs": [
						[
							2917,
							2918,
							2919,
							-387,
							-2366,
							2920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29077,
					"arcs": [
						[
							2921,
							-373,
							2922,
							2923,
							-1326,
							-894
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8009,
					"arcs": [
						[
							-2905,
							2924,
							2925,
							2926,
							2927,
							2928,
							2929
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12007,
					"arcs": [
						[
							2930,
							2931,
							2932,
							2933,
							2934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19055,
					"arcs": [
						[
							2935,
							2936,
							-59,
							-1757,
							2937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48137,
					"arcs": [
						[
							-2459,
							-2336,
							2938,
							2939,
							2940,
							2941,
							2942
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26101,
					"arcs": [
						[
							-178,
							-266,
							-1498,
							2943,
							2944
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17037,
					"arcs": [
						[
							2945,
							-16,
							-2231,
							2946,
							-689,
							2947,
							2948
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39113,
					"arcs": [
						[
							2949,
							2950,
							-2646,
							2951,
							-1996,
							2952,
							-2813
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48231,
					"arcs": [
						[
							2953,
							2954,
							2955,
							2956,
							2957,
							2958,
							2959,
							-1807
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31163,
					"arcs": [
						[
							2960,
							2961,
							-2494,
							-517,
							2962
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39109,
					"arcs": [
						[
							2963,
							-105,
							-2643,
							-2951,
							2964
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46043,
					"arcs": [
						[
							2965,
							2966,
							2967,
							2968
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18065,
					"arcs": [
						[
							2969,
							2970,
							2971,
							2972,
							-212,
							2973,
							-1053
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2013,
					"arcs": [
						[
							[
								2974
							]
						],
						[
							[
								2975
							]
						],
						[
							[
								2976
							]
						],
						[
							[
								2977
							]
						],
						[
							[
								2978
							]
						],
						[
							[
								2979,
								2980
							]
						],
						[
							[
								2981
							]
						],
						[
							[
								2982
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13059,
					"arcs": [
						[
							2983,
							2984,
							2985,
							2986
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18139,
					"arcs": [
						[
							-2974,
							-215,
							-2622,
							-1002,
							2987,
							-1054
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38043,
					"arcs": [
						[
							2988,
							-2582,
							2989,
							-2259,
							2990,
							2991
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48127,
					"arcs": [
						[
							2992,
							-1357,
							-403,
							2993,
							2994
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55025,
					"arcs": [
						[
							2995,
							2996,
							-2794,
							2997,
							2998,
							2999,
							3000
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4015,
					"arcs": [
						[
							3001,
							3002,
							3003,
							-290,
							3004,
							-153,
							-701,
							3005
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12033,
					"arcs": [
						[
							3006,
							3007,
							3008,
							-149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16001,
					"arcs": [
						[
							3009,
							-2741,
							-1234,
							3010,
							3011
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38063,
					"arcs": [
						[
							3012,
							3013,
							-381,
							-1364,
							3014,
							3015,
							3016
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48445,
					"arcs": [
						[
							3017,
							-2372,
							3018,
							-1918,
							-2024
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17109,
					"arcs": [
						[
							3019,
							3020,
							3021,
							-1189,
							3022
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19181,
					"arcs": [
						[
							-1788,
							-2451,
							-937,
							3023,
							-1986
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31133,
					"arcs": [
						[
							3024,
							3025,
							3026,
							-18,
							3027,
							3028
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18075,
					"arcs": [
						[
							3029,
							3030,
							3031,
							-969,
							3032,
							3033,
							3034
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53051,
					"arcs": [
						[
							3035,
							3036,
							3037,
							3038,
							3039
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19031,
					"arcs": [
						[
							-1758,
							-63,
							3040,
							3041,
							3042,
							3043
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30107,
					"arcs": [
						[
							3044,
							3045,
							3046,
							3047,
							3048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28107,
					"arcs": [
						[
							3049,
							-688,
							3050,
							3051,
							-2104,
							3052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21191,
					"arcs": [
						[
							3053,
							3054,
							3055,
							3056,
							3057,
							3058
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48329,
					"arcs": [
						[
							3059,
							-2346,
							-122,
							3060,
							3061
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38013,
					"arcs": [
						[
							3062,
							3063,
							3064,
							3065,
							3066,
							3067
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46077,
					"arcs": [
						[
							-1560,
							-1592,
							3068,
							3069,
							-1061,
							3070,
							-1554
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5099,
					"arcs": [
						[
							3071,
							-622,
							3072,
							3073,
							3074,
							3075
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17095,
					"arcs": [
						[
							3076,
							-2489,
							-1775,
							3077,
							3078,
							3079
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40153,
					"arcs": [
						[
							3080,
							3081,
							-2465,
							3082,
							3083
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1093,
					"arcs": [
						[
							3084,
							3085,
							-2861,
							-2098,
							-1224,
							-2090,
							3086
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17007,
					"arcs": [
						[
							3087,
							-1938,
							-17,
							-2946,
							3088
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37181,
					"arcs": [
						[
							3089,
							3090,
							3091,
							3092
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 15009,
					"arcs": [
						[
							[
								3093
							]
						],
						[
							[
								3094
							]
						],
						[
							[
								3095
							]
						],
						[
							[
								3096,
								3097
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20065,
					"arcs": [
						[
							-825,
							3098,
							3099,
							-2307,
							3100,
							3101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27141,
					"arcs": [
						[
							3102,
							-1874,
							3103,
							-763,
							3104,
							-1822,
							3105
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31059,
					"arcs": [
						[
							3106,
							-559,
							3107,
							3108,
							-2724
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48047,
					"arcs": [
						[
							-667,
							3109,
							3110,
							3111,
							3112,
							3113,
							3114
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48125,
					"arcs": [
						[
							-1834,
							-784,
							3115,
							-2783
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19099,
					"arcs": [
						[
							-1162,
							-1946,
							-1779,
							3116,
							-2448,
							-1786
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19123,
					"arcs": [
						[
							-3117,
							-1778,
							3117,
							-1168,
							3118,
							-2449
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19147,
					"arcs": [
						[
							3119,
							-583,
							3120,
							3121
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37195,
					"arcs": [
						[
							3122,
							3123,
							3124,
							3125,
							3126,
							3127
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47133,
					"arcs": [
						[
							3128,
							-975,
							3129,
							3130,
							3131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27031,
					"arcs": [
						[
							3132,
							3133
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26143,
					"arcs": [
						[
							3134,
							3135,
							-2203,
							-2199,
							3136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26113,
					"arcs": [
						[
							3137,
							-3137,
							-2198,
							3138,
							-263
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38069,
					"arcs": [
						[
							3139,
							3140,
							3141,
							3142,
							-2578,
							3143,
							3144
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48191,
					"arcs": [
						[
							3145,
							-1451,
							3146,
							3147,
							-1832,
							3148
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21071,
					"arcs": [
						[
							3149,
							3150,
							3151,
							3152,
							3153
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20017,
					"arcs": [
						[
							-2540,
							-70,
							-1441,
							-2651,
							3154
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35003,
					"arcs": [
						[
							3155,
							3156,
							-1133,
							3157,
							3158,
							3159
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21171,
					"arcs": [
						[
							3160,
							3161,
							3162,
							3163,
							3164,
							3165
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48075,
					"arcs": [
						[
							-1450,
							3166,
							3167,
							3168,
							-3147
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41047,
					"arcs": [
						[
							3169,
							3170,
							-2827,
							3171,
							-1086,
							3172
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1061,
					"arcs": [
						[
							-1718,
							3173,
							3174,
							3175,
							3176,
							3177,
							3178
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19007,
					"arcs": [
						[
							3179,
							-1706,
							3180,
							3181,
							-1057
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20083,
					"arcs": [
						[
							3182,
							-1974,
							3183,
							3184,
							-2749,
							3185
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48327,
					"arcs": [
						[
							3186,
							3187,
							-1077,
							-2334,
							-1810
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31161,
					"arcs": [
						[
							3188,
							3189,
							3190,
							-1388,
							-139,
							3191,
							-1262
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51810,
					"arcs": [
						[
							3192,
							3193,
							3194,
							3195
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18117,
					"arcs": [
						[
							3196,
							3197,
							3198,
							3199,
							-755
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29161,
					"arcs": [
						[
							-130,
							3200,
							3201,
							-2911,
							-1405,
							3202
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53007,
					"arcs": [
						[
							3203,
							3204,
							3205,
							3206,
							3207,
							3208
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19003,
					"arcs": [
						[
							3209,
							-1426,
							-1167,
							-2264,
							3210
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37169,
					"arcs": [
						[
							3211,
							3212,
							3213,
							-376,
							3214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20047,
					"arcs": [
						[
							-3184,
							-1973,
							-1980,
							3215,
							3216,
							3217
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26079,
					"arcs": [
						[
							3218,
							3219,
							-3138,
							3220
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48483,
					"arcs": [
						[
							-46,
							3221,
							-2681,
							-1447,
							-942
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46003,
					"arcs": [
						[
							-1599,
							3222,
							3223,
							-2966,
							3224,
							3225
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28077,
					"arcs": [
						[
							3226,
							-2576,
							3227,
							3228,
							3229,
							-2629
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37001,
					"arcs": [
						[
							-1411,
							3230,
							3231,
							-2672,
							3232,
							3233
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48433,
					"arcs": [
						[
							-783,
							-925,
							3234,
							-813,
							3235
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48325,
					"arcs": [
						[
							3236,
							3237,
							-1072,
							-1356,
							3238
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46111,
					"arcs": [
						[
							-1555,
							-3071,
							-1065,
							-2798,
							3239,
							-3223,
							-1598
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30037,
					"arcs": [
						[
							-3047,
							3240,
							3241,
							3242,
							3243,
							3244
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19029,
					"arcs": [
						[
							3245,
							3246,
							-1427,
							-3210,
							3247,
							3248
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18141,
					"arcs": [
						[
							3249,
							3250,
							3251,
							-1127,
							3252,
							3253
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19093,
					"arcs": [
						[
							-2605,
							3254,
							-1691,
							3255
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48381,
					"arcs": [
						[
							-538,
							-564,
							3256,
							-2436,
							3257
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48421,
					"arcs": [
						[
							3258,
							3259,
							3260,
							3261,
							-2713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31001,
					"arcs": [
						[
							-519,
							-136,
							3262,
							-2726,
							-604,
							3263
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56017,
					"arcs": [
						[
							-1658,
							3264,
							3265
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36009,
					"arcs": [
						[
							3266,
							3267,
							-2627,
							3268,
							-1396,
							3269
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27057,
					"arcs": [
						[
							3270,
							3271,
							-1512,
							-635,
							3272
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27037,
					"arcs": [
						[
							3273,
							3274,
							3275,
							3276,
							3277,
							-2529,
							3278
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48447,
					"arcs": [
						[
							-777,
							-1080,
							3279,
							-2914,
							-922
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42115,
					"arcs": [
						[
							3280,
							3281,
							3282,
							3283,
							3284,
							3285
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5043,
					"arcs": [
						[
							3286,
							3287,
							3288,
							3289,
							3290,
							3291
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29171,
					"arcs": [
						[
							-1058,
							-3182,
							3292,
							3293,
							3294,
							3295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28091,
					"arcs": [
						[
							3296,
							-890,
							3297,
							3298,
							3299,
							-3229
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46045,
					"arcs": [
						[
							-2154,
							3300,
							-2866,
							3301,
							3302
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28057,
					"arcs": [
						[
							3303,
							-28,
							3304,
							-3085,
							3305,
							3306
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17079,
					"arcs": [
						[
							3307,
							3308,
							3309,
							3310,
							-1181,
							-1272
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38041,
					"arcs": [
						[
							-1964,
							3311,
							3312,
							3313
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55077,
					"arcs": [
						[
							3314,
							3315,
							3316,
							3317
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29093,
					"arcs": [
						[
							3318,
							-1277,
							-2393,
							3319,
							3320,
							3321,
							-2909
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48353,
					"arcs": [
						[
							-815,
							3322,
							-1780,
							-2674,
							3323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48415,
					"arcs": [
						[
							-2913,
							3324,
							-816,
							3325,
							-2355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48253,
					"arcs": [
						[
							-3235,
							-924,
							-2917,
							3326,
							3327,
							-814
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48335,
					"arcs": [
						[
							-2356,
							-3326,
							-3324,
							-2673,
							3328,
							-2411
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1059,
					"arcs": [
						[
							-27,
							3329,
							3330,
							-2858,
							-3086,
							-3305
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28035,
					"arcs": [
						[
							3331,
							3332,
							3333,
							3334,
							3335,
							-888
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16037,
					"arcs": [
						[
							-1149,
							3336,
							3337,
							-1236,
							-2740,
							-1768
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29023,
					"arcs": [
						[
							3338,
							3339,
							3340,
							3341,
							3342,
							-2331
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39057,
					"arcs": [
						[
							-2645,
							3343,
							3344,
							-2019,
							-1997,
							-2952
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38017,
					"arcs": [
						[
							-384,
							3345,
							3346,
							3347,
							3348,
							3349,
							-1360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37069,
					"arcs": [
						[
							-3092,
							3350,
							3351,
							-1503,
							3352
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28101,
					"arcs": [
						[
							-2864,
							-2132,
							-602,
							-2148,
							3353
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35031,
					"arcs": [
						[
							3354,
							3355,
							3356,
							3357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1069,
					"arcs": [
						[
							3358,
							3359,
							3360,
							3361,
							-3175,
							3362
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5017,
					"arcs": [
						[
							-3290,
							3363,
							3364,
							3365,
							3366,
							3367,
							3368,
							3369,
							3370
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 6111,
					"arcs": [
						[
							[
								3371
							]
						],
						[
							[
								-2897,
								3372,
								3373,
								3374
							]
						],
						[
							[
								3375
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8075,
					"arcs": [
						[
							3376,
							-2685,
							3377,
							3378,
							3379,
							-1727,
							-2647,
							3380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46015,
					"arcs": [
						[
							3381,
							-1600,
							-3226,
							3382,
							-2886
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35006,
					"arcs": [
						[
							-3357,
							3383,
							3384,
							3385,
							-1128,
							-3157,
							3386
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17155,
					"arcs": [
						[
							-695,
							-2185,
							3387
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20199,
					"arcs": [
						[
							3388,
							3389,
							3390,
							3391,
							3392,
							-162
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20057,
					"arcs": [
						[
							-3185,
							-3218,
							3393,
							3394,
							3395,
							-2750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42131,
					"arcs": [
						[
							3396,
							-3284,
							3397,
							3398,
							-1367
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38051,
					"arcs": [
						[
							-2262,
							3399,
							3400,
							-2151,
							3401,
							3402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26109,
					"arcs": [
						[
							3403,
							3404,
							3405,
							3406,
							3407
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48193,
					"arcs": [
						[
							-257,
							-2498,
							3408,
							-250,
							-1028,
							3409
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51678,
					"arcs": [
						[
							3410
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21129,
					"arcs": [
						[
							3411,
							3412,
							3413,
							3414,
							3415,
							-2717
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26121,
					"arcs": [
						[
							-830,
							-1520,
							3416,
							3417,
							3418
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42103,
					"arcs": [
						[
							3419,
							3420,
							3421,
							3422,
							3423
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46053,
					"arcs": [
						[
							-2888,
							3424,
							3425,
							3426,
							3427
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46021,
					"arcs": [
						[
							3428,
							-3402,
							-2156,
							3429,
							3430
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48071,
					"arcs": [
						[
							3431,
							3432,
							3433,
							3434,
							3435,
							3436
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48201,
					"arcs": [
						[
							3437,
							3438,
							-3437,
							3439,
							3440,
							3441,
							3442,
							3443
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5077,
					"arcs": [
						[
							3444,
							3445,
							3446,
							3447,
							3448
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50005,
					"arcs": [
						[
							-329,
							-737,
							3449,
							3450,
							3451,
							3452
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51133,
					"arcs": [
						[
							3453,
							3454,
							3455,
							3456
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53005,
					"arcs": [
						[
							3457,
							3458,
							3459,
							3460,
							3461,
							3462,
							3463
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54059,
					"arcs": [
						[
							3464,
							3465,
							3466,
							3467,
							3468,
							3469,
							3470,
							3471
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55125,
					"arcs": [
						[
							3472,
							3473,
							3474,
							3475,
							-2665,
							-676
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1041,
					"arcs": [
						[
							3476,
							3477,
							3478,
							-1715,
							3479,
							3480
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5023,
					"arcs": [
						[
							3481,
							3482,
							3483,
							3484,
							-2325
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20151,
					"arcs": [
						[
							-1979,
							3485,
							-789,
							-705,
							3486,
							-3216
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12121,
					"arcs": [
						[
							3487,
							3488,
							3489,
							3490,
							3491
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13173,
					"arcs": [
						[
							3492,
							-1935,
							3493,
							3494,
							3495
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13303,
					"arcs": [
						[
							3496,
							3497,
							3498,
							3499,
							3500,
							3501
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13305,
					"arcs": [
						[
							3502,
							3503,
							3504,
							3505,
							3506,
							3507,
							3508
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16047,
					"arcs": [
						[
							3509,
							3510,
							3511,
							3512,
							-1239
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18125,
					"arcs": [
						[
							3513,
							3514,
							3515,
							3516,
							3517
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21073,
					"arcs": [
						[
							3518,
							3519,
							3520,
							3521,
							3522,
							3523
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21143,
					"arcs": [
						[
							3524,
							-2747,
							3525,
							-933,
							3526
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21201,
					"arcs": [
						[
							3527,
							3528,
							3529,
							3530,
							3531
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12023,
					"arcs": [
						[
							-3490,
							3532,
							3533,
							-1933,
							3534,
							3535,
							3536,
							3537
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16009,
					"arcs": [
						[
							3538,
							3539,
							3540,
							3541,
							3542
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13299,
					"arcs": [
						[
							-2843,
							3543,
							3544,
							3545,
							3546,
							3547,
							-1931,
							3548
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13099,
					"arcs": [
						[
							3549,
							-820,
							3550,
							-2143,
							3551,
							3552,
							-3360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18029,
					"arcs": [
						[
							3553,
							-2620,
							3554,
							3555,
							3556
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18091,
					"arcs": [
						[
							3557,
							3558,
							-3254,
							3559,
							3560
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19053,
					"arcs": [
						[
							3561,
							-1060,
							3562,
							3563,
							-1155
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20091,
					"arcs": [
						[
							3564,
							3565,
							-2739,
							3566,
							3567,
							3568
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20037,
					"arcs": [
						[
							3569,
							-715,
							3570,
							3571,
							3572,
							3573,
							3574
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22067,
					"arcs": [
						[
							3575,
							-3370,
							3576,
							3577,
							3578,
							3579
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22027,
					"arcs": [
						[
							3580,
							3581,
							3582,
							3583,
							3584,
							3585
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 23027,
					"arcs": [
						[
							[
								3586
							]
						],
						[
							[
								3587,
								3588,
								-458,
								3589,
								3590,
								3591,
								3592
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29145,
					"arcs": [
						[
							3593,
							3594,
							-1330,
							-1630,
							3595,
							3596
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29227,
					"arcs": [
						[
							-2265,
							-1157,
							3597,
							3598,
							3599
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30101,
					"arcs": [
						[
							3600,
							3601,
							3602,
							3603
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30057,
					"arcs": [
						[
							3604,
							3605,
							3606,
							3607,
							-183
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35023,
					"arcs": [
						[
							3608,
							3609,
							3610,
							3611,
							3612
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36101,
					"arcs": [
						[
							3613,
							3614,
							-542,
							3615,
							3616,
							-1379,
							-724,
							-2625
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37039,
					"arcs": [
						[
							3617,
							3618,
							3619,
							3620,
							3621,
							-446,
							3622
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38097,
					"arcs": [
						[
							3623,
							-2305,
							3624,
							-3346,
							-383
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38029,
					"arcs": [
						[
							3625,
							-2991,
							-2263,
							-3403,
							-3429,
							3626,
							3627,
							3628
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40147,
					"arcs": [
						[
							-2419,
							3629,
							3630,
							3631,
							-2057,
							3632
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21097,
					"arcs": [
						[
							3633,
							-3058,
							3634,
							-3532,
							3635,
							3636,
							3637
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21069,
					"arcs": [
						[
							3638,
							3639,
							3640,
							3641,
							3642,
							-3530
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22069,
					"arcs": [
						[
							3643,
							3644,
							3645,
							3646,
							3647,
							3648,
							3649,
							3650
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39143,
					"arcs": [
						[
							-325,
							3651,
							3652,
							3653,
							3654
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28137,
					"arcs": [
						[
							3655,
							3656,
							-682,
							-3050,
							3657
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28049,
					"arcs": [
						[
							3658,
							3659,
							3660,
							3661,
							3662,
							3663
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31077,
					"arcs": [
						[
							3664,
							3665,
							3666,
							-2491,
							-2962,
							3667
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31121,
					"arcs": [
						[
							3668,
							3669,
							3670,
							3671,
							-134,
							-2493
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31125,
					"arcs": [
						[
							3672,
							3673,
							-3669,
							-2492,
							-3667
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34027,
					"arcs": [
						[
							3674,
							3675,
							3676,
							3677,
							3678,
							3679,
							3680
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35051,
					"arcs": [
						[
							-1132,
							3681,
							3682,
							3683,
							3684,
							3685,
							-3158
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36067,
					"arcs": [
						[
							3686,
							3687,
							-2036,
							3688
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37065,
					"arcs": [
						[
							3689,
							3690,
							3691,
							-3123,
							3692
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37105,
					"arcs": [
						[
							3693,
							3694,
							3695
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39019,
					"arcs": [
						[
							3696,
							3697,
							3698,
							3699,
							3700
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40073,
					"arcs": [
						[
							-2463,
							-1550,
							3701,
							3702,
							-904
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42067,
					"arcs": [
						[
							3703,
							3704,
							3705,
							3706,
							3707,
							3708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47085,
					"arcs": [
						[
							-248,
							3709,
							-1900,
							3710,
							3711
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47145,
					"arcs": [
						[
							3712,
							3713,
							3714,
							3715,
							3716,
							3717,
							3718,
							-1174
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27089,
					"arcs": [
						[
							3719,
							3720,
							3721,
							3722,
							-2298,
							3723,
							3724,
							3725
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41061,
					"arcs": [
						[
							3726,
							3727,
							3728,
							3729
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55109,
					"arcs": [
						[
							3730,
							-1648,
							3731,
							3732
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48323,
					"arcs": [
						[
							3733,
							3734,
							-2995,
							3735,
							3736
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39055,
					"arcs": [
						[
							3737,
							3738,
							3739,
							3740,
							3741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40015,
					"arcs": [
						[
							-1682,
							-906,
							3742,
							-2921,
							-2365,
							3743,
							3744
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4023,
					"arcs": [
						[
							3745,
							3746,
							3747
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1039,
					"arcs": [
						[
							3748,
							-3480,
							-1719,
							-3179,
							3749,
							3750,
							3751,
							3752
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6085,
					"arcs": [
						[
							3753,
							3754,
							3755,
							3756,
							-1667,
							3757,
							3758
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6093,
					"arcs": [
						[
							-1231,
							3759,
							3760,
							-950,
							-2278,
							3761,
							3762,
							3763
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8077,
					"arcs": [
						[
							-570,
							3764,
							3765,
							3766,
							3767,
							3768
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8081,
					"arcs": [
						[
							3769,
							3770,
							3771,
							3772,
							3773,
							3774
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8021,
					"arcs": [
						[
							-1763,
							3775,
							3776,
							3777,
							3778,
							3779
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9015,
					"arcs": [
						[
							3780,
							3781,
							-478,
							3782,
							3783
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12063,
					"arcs": [
						[
							-3176,
							-3362,
							3784,
							3785,
							3786,
							3787,
							3788,
							3789,
							3790
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13145,
					"arcs": [
						[
							3791,
							3792,
							3793,
							3794,
							3795,
							-1712
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16071,
					"arcs": [
						[
							3796,
							3797,
							3798,
							3799,
							3800,
							3801
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17077,
					"arcs": [
						[
							3802,
							3803,
							-2853,
							3804,
							3805,
							3806
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51019,
					"arcs": [
						[
							3807,
							3808,
							3809,
							-2075,
							3810,
							3811,
							3812,
							3813
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48429,
					"arcs": [
						[
							-3280,
							-1079,
							-1038,
							-1454,
							-2915
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48299,
					"arcs": [
						[
							3814,
							3815,
							-2584,
							-394,
							-1075
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51079,
					"arcs": [
						[
							3816,
							3817,
							3818,
							3819,
							3820
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54085,
					"arcs": [
						[
							3821,
							3822,
							3823,
							3824,
							3825,
							3826,
							3827
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13017,
					"arcs": [
						[
							3828,
							3829,
							-2839,
							-1956,
							3830
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48409,
					"arcs": [
						[
							3831,
							3832,
							3833,
							3834,
							3835,
							3836,
							3837
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48039,
					"arcs": [
						[
							3838,
							3839,
							-3442,
							3840,
							3841,
							3842,
							3843,
							3844
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48277,
					"arcs": [
						[
							3845,
							3846,
							3847,
							3848,
							3849
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54095,
					"arcs": [
						[
							3850,
							3851,
							3852,
							-3824,
							3853,
							3854
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55059,
					"arcs": [
						[
							-992,
							3855,
							3856,
							-12,
							-1937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1055,
					"arcs": [
						[
							3857,
							3858,
							3859,
							3860,
							3861,
							3862
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1121,
					"arcs": [
						[
							3863,
							3864,
							3865,
							3866,
							3867,
							3868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5105,
					"arcs": [
						[
							3869,
							3870,
							3871,
							3872,
							3873,
							3874
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6009,
					"arcs": [
						[
							3875,
							-2882,
							3876,
							3877,
							3878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21193,
					"arcs": [
						[
							3879,
							3880,
							3881,
							3882,
							3883,
							3884,
							3885
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13319,
					"arcs": [
						[
							3886,
							-3501,
							3887,
							3888,
							3889,
							3890
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13019,
					"arcs": [
						[
							3891,
							-1958,
							-2845,
							3892,
							-3496,
							3893,
							3894
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13301,
					"arcs": [
						[
							3895,
							3896,
							3897,
							3898,
							3899,
							3900
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13317,
					"arcs": [
						[
							3901,
							3902,
							3903,
							-3896,
							3904,
							3905
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13205,
					"arcs": [
						[
							3906,
							3907,
							3908,
							3909,
							-2157,
							3910,
							-2140
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20179,
					"arcs": [
						[
							3911,
							-826,
							-3102,
							3912,
							-2515
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20027,
					"arcs": [
						[
							3913,
							3914,
							3915,
							3916,
							3917,
							-2294
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20059,
					"arcs": [
						[
							3918,
							3919,
							3920,
							-1679,
							3921
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21211,
					"arcs": [
						[
							3922,
							-3524,
							3923,
							3924,
							3925,
							3926
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21177,
					"arcs": [
						[
							3927,
							3928,
							3929,
							3930,
							-1522,
							3931,
							3932
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21153,
					"arcs": [
						[
							3933,
							-3154,
							3934,
							3935,
							3936,
							3937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22089,
					"arcs": [
						[
							3938,
							3939,
							3940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28015,
					"arcs": [
						[
							-226,
							-2102,
							3941,
							3942,
							3943
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18083,
					"arcs": [
						[
							-2754,
							3944,
							3945,
							3946,
							3947,
							-3514,
							3948,
							3949
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18087,
					"arcs": [
						[
							3950,
							3951,
							3952,
							-1139,
							3953
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21225,
					"arcs": [
						[
							3954,
							3955,
							3956,
							3957,
							3958,
							3959
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22085,
					"arcs": [
						[
							3960,
							-3651,
							3961,
							3962,
							3963,
							3964
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22013,
					"arcs": [
						[
							3965,
							-3585,
							3966,
							3967,
							3968,
							-3646,
							3969,
							3970
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24031,
					"arcs": [
						[
							3971,
							3972,
							3973,
							3974,
							3975,
							3976
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 25007,
					"arcs": [
						[
							[
								3977
							]
						],
						[
							[
								3978,
								3979
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26115,
					"arcs": [
						[
							3980,
							3981,
							3982,
							3983,
							-546
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28063,
					"arcs": [
						[
							3984,
							3985,
							-2634,
							3986,
							3987,
							3988
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29047,
					"arcs": [
						[
							3989,
							-2121,
							3990,
							-2735,
							3991
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29129,
					"arcs": [
						[
							-3563,
							-1059,
							-3296,
							3992,
							-1896,
							3993
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30063,
					"arcs": [
						[
							-719,
							3994,
							-2474,
							3995,
							3996,
							3997,
							-2274,
							3998,
							3999
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31127,
					"arcs": [
						[
							4000,
							4001,
							4002,
							4003,
							-3026,
							4004
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34009,
					"arcs": [
						[
							4005,
							4006,
							4007
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36005,
					"arcs": [
						[
							4008,
							4009,
							4010,
							4011,
							4012
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37075,
					"arcs": [
						[
							4013,
							4014,
							4015,
							-3619,
							4016
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40035,
					"arcs": [
						[
							4017,
							4018,
							4019,
							4020,
							4021,
							4022,
							4023
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40033,
					"arcs": [
						[
							-2367,
							-391,
							4024,
							4025,
							4026,
							4027
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41037,
					"arcs": [
						[
							4028,
							4029,
							4030,
							-946,
							4031
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46083,
					"arcs": [
						[
							4032,
							4033,
							4034,
							4035,
							4036,
							-525
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47163,
					"arcs": [
						[
							4037,
							4038,
							-1402,
							4039,
							4040,
							4041,
							4042,
							4043
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47055,
					"arcs": [
						[
							4044,
							4045,
							4046,
							-2696,
							4047,
							4048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28135,
					"arcs": [
						[
							4049,
							-2105,
							-3052,
							4050,
							-221,
							4051,
							4052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30015,
					"arcs": [
						[
							4053,
							4054,
							4055,
							4056,
							4057,
							4058,
							4059,
							4060
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31113,
					"arcs": [
						[
							4061,
							-232,
							4062,
							-2730,
							-2431
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 35043,
					"arcs": [
						[
							[
								4063,
								4064
							]
						],
						[
							[
								4065,
								4066,
								4067,
								4068,
								-3384,
								-3356,
								4069
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37107,
					"arcs": [
						[
							4070,
							4071,
							4072,
							4073,
							4074,
							4075
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37083,
					"arcs": [
						[
							4076,
							4077,
							4078,
							4079,
							-3690,
							4080
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39101,
					"arcs": [
						[
							-2009,
							4081,
							4082,
							4083,
							-898,
							-1339
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40005,
					"arcs": [
						[
							4084,
							4085,
							-2084,
							4086,
							4087,
							4088
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40027,
					"arcs": [
						[
							-283,
							4089,
							4090,
							4091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40133,
					"arcs": [
						[
							-774,
							4092,
							4093,
							4094
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42079,
					"arcs": [
						[
							-1368,
							-3399,
							4095,
							4096,
							4097,
							4098,
							4099
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42109,
					"arcs": [
						[
							4100,
							4101,
							-3704,
							4102
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45063,
					"arcs": [
						[
							4103,
							4104,
							4105,
							4106,
							4107,
							4108
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47039,
					"arcs": [
						[
							-912,
							4109,
							4110,
							4111,
							4112,
							-1593
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48425,
					"arcs": [
						[
							4113,
							4114,
							4115,
							-2496
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48255,
					"arcs": [
						[
							4116,
							4117,
							-2790,
							4118,
							4119,
							4120,
							-1068
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48459,
					"arcs": [
						[
							4121,
							-2047,
							4122,
							4123,
							4124,
							4125,
							4126
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48297,
					"arcs": [
						[
							-1069,
							-4121,
							4127,
							-3838,
							4128,
							-665,
							4129
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53001,
					"arcs": [
						[
							4130,
							4131,
							4132,
							4133
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54041,
					"arcs": [
						[
							4134,
							-2118,
							4135,
							-2125,
							4136,
							4137
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46093,
					"arcs": [
						[
							4138,
							4139,
							4140,
							4141,
							4142,
							-2508
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47027,
					"arcs": [
						[
							-3164,
							4143,
							4144,
							-970,
							-3129,
							4145,
							4146
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40123,
					"arcs": [
						[
							4147,
							4148,
							-4094,
							4149,
							4150,
							4151,
							-2077,
							4152
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1071,
					"arcs": [
						[
							4153,
							4154,
							4155,
							4156,
							4157,
							4158
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 6075,
					"arcs": [
						[
							[
								4159,
								4160
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2110,
					"arcs": [
						[
							[
								4161,
								-1584
							]
						],
						[
							[
								4162,
								4163,
								-1579,
								4164,
								-1582,
								4165
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12129,
					"arcs": [
						[
							4166,
							4167,
							4168,
							4169,
							4170
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16055,
					"arcs": [
						[
							4171,
							4172,
							-3539,
							4173
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17161,
					"arcs": [
						[
							4174,
							4175,
							4176,
							4177,
							-2486,
							4178,
							4179
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18177,
					"arcs": [
						[
							4180,
							4181,
							-2811,
							-165,
							-213,
							-2973
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19129,
					"arcs": [
						[
							4182,
							4183,
							4184,
							4185,
							4186
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21111,
					"arcs": [
						[
							4187,
							4188,
							4189,
							-3926,
							4190,
							-955,
							4191
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22019,
					"arcs": [
						[
							4192,
							4193,
							4194,
							4195,
							4196
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23017,
					"arcs": [
						[
							4197,
							-1208,
							4198,
							4199,
							4200,
							-2555,
							4201
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26099,
					"arcs": [
						[
							4202,
							4203,
							4204,
							4205,
							4206
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26003,
					"arcs": [
						[
							4207,
							-2899,
							4208,
							4209,
							4210
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28109,
					"arcs": [
						[
							4211,
							-3298,
							-889,
							-3336,
							4212,
							4213,
							4214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28153,
					"arcs": [
						[
							-2149,
							-314,
							4215,
							4216,
							4217,
							4218,
							4219
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31055,
					"arcs": [
						[
							4220,
							4221,
							4222,
							4223,
							4224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28155,
					"arcs": [
						[
							-2146,
							4225,
							4226,
							4227,
							4228,
							-2099,
							-224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13265,
					"arcs": [
						[
							4229,
							-3905,
							-3901,
							4230,
							4231
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21083,
					"arcs": [
						[
							4232,
							4233,
							-936,
							-1565,
							4234,
							4235,
							4236
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29186,
					"arcs": [
						[
							4237,
							4238,
							4239,
							4240,
							-2390
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26017,
					"arcs": [
						[
							-2206,
							4241,
							4242,
							4243,
							4244,
							4245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42085,
					"arcs": [
						[
							4246,
							4247,
							4248,
							4249,
							-2478,
							4250
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51630,
					"arcs": [
						[
							-2180,
							4251
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20055,
					"arcs": [
						[
							-74,
							-350,
							4252,
							-3186,
							-2752,
							-2452,
							-415
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21155,
					"arcs": [
						[
							4253,
							4254,
							4255,
							4256,
							4257,
							4258
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22039,
					"arcs": [
						[
							-79,
							4259,
							4260,
							4261,
							4262,
							4263
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23021,
					"arcs": [
						[
							4264,
							4265,
							4266
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29043,
					"arcs": [
						[
							-2924,
							4267,
							-2343,
							-2413,
							4268,
							-1327
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29205,
					"arcs": [
						[
							4269,
							4270,
							4271,
							4272,
							-1286
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30007,
					"arcs": [
						[
							-365,
							4273,
							4274,
							4275
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31149,
					"arcs": [
						[
							4276,
							4277,
							4278,
							4279
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27127,
					"arcs": [
						[
							4280,
							4281,
							4282,
							4283,
							4284,
							4285
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28029,
					"arcs": [
						[
							4286,
							-3662,
							-2577,
							-3227,
							-2628,
							-3986
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8019,
					"arcs": [
						[
							4287,
							4288,
							4289,
							4290,
							4291
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36069,
					"arcs": [
						[
							4292,
							4293,
							4294,
							-543,
							-3615,
							4295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37197,
					"arcs": [
						[
							4296,
							-380,
							4297,
							4298,
							4299
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38071,
					"arcs": [
						[
							4300,
							-2235,
							4301,
							-3017,
							4302
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40029,
					"arcs": [
						[
							-4151,
							4303,
							4304,
							-4085,
							4305
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41071,
					"arcs": [
						[
							4306,
							4307,
							-3170,
							4308,
							4309
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47075,
					"arcs": [
						[
							-1245,
							4310,
							4311,
							4312,
							4313,
							4314
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48439,
					"arcs": [
						[
							4315,
							-1486,
							-117,
							-1331,
							4316,
							-1753
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48311,
					"arcs": [
						[
							-1070,
							-4130,
							-664,
							-401
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48089,
					"arcs": [
						[
							4317,
							-1569,
							4318,
							4319,
							-1791
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48217,
					"arcs": [
						[
							4320,
							-1335,
							4321,
							-1911,
							4322,
							4323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51113,
					"arcs": [
						[
							4324,
							4325,
							4326,
							-3818,
							4327
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51173,
					"arcs": [
						[
							4328,
							4329,
							4330,
							4331,
							4332,
							4333
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51600,
					"arcs": [
						[
							4334
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55139,
					"arcs": [
						[
							-411,
							-1180,
							-1122,
							-2220,
							4335,
							4336
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13267,
					"arcs": [
						[
							4337,
							4338,
							4339,
							4340,
							-3503,
							4341,
							4342
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29167,
					"arcs": [
						[
							4343,
							-1610,
							-374,
							-2922,
							-893,
							4344
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26139,
					"arcs": [
						[
							-3418,
							4345,
							-306,
							4346
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19039,
					"arcs": [
						[
							-1987,
							-3024,
							-941,
							-3562,
							-1166
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27157,
					"arcs": [
						[
							4347,
							4348,
							4349,
							-1818,
							4350
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40089,
					"arcs": [
						[
							-2082,
							4351,
							4352,
							4353,
							4354,
							4355,
							4356,
							4357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51057,
					"arcs": [
						[
							4358,
							4359,
							4360,
							4361,
							4362,
							4363,
							4364
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36053,
					"arcs": [
						[
							4365,
							4366,
							4367,
							-2037,
							-3688,
							4368
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34025,
					"arcs": [
						[
							4369,
							4370,
							4371,
							4372,
							4373
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35013,
					"arcs": [
						[
							4374,
							4375,
							4376,
							4377,
							-3684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37015,
					"arcs": [
						[
							-4079,
							4378,
							4379,
							4380,
							4381,
							4382
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37013,
					"arcs": [
						[
							4383,
							4384,
							4385,
							4386,
							4387,
							4388,
							4389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37009,
					"arcs": [
						[
							4390,
							4391,
							4392,
							4393,
							4394
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6113,
					"arcs": [
						[
							4395,
							4396,
							4397,
							4398,
							4399,
							4400
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39007,
					"arcs": [
						[
							4401,
							4402,
							4403,
							4404,
							-3739,
							4405
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42089,
					"arcs": [
						[
							-4097,
							4406,
							4407,
							-3423,
							4408,
							4409,
							4410,
							4411
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45069,
					"arcs": [
						[
							4412,
							-963,
							4413,
							4414,
							4415,
							4416,
							4417
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46121,
					"arcs": [
						[
							4418,
							4419,
							4420,
							4421
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46031,
					"arcs": [
						[
							4422,
							4423,
							-3627,
							-3431,
							4424,
							4425,
							4426,
							4427
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48097,
					"arcs": [
						[
							4428,
							4429,
							-1483,
							4430,
							4431
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49001,
					"arcs": [
						[
							4432,
							4433,
							4434,
							4435,
							-1602,
							4436
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51095,
					"arcs": [
						[
							4437,
							4438,
							4439,
							4440,
							-2728,
							4441,
							-195,
							4442,
							4443
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53013,
					"arcs": [
						[
							4444,
							4445,
							-619,
							4446,
							4447,
							4448
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55041,
					"arcs": [
						[
							4449,
							4450,
							4451,
							4452,
							4453,
							-2666,
							-3476
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56011,
					"arcs": [
						[
							4454,
							4455,
							4456,
							-2510,
							-199,
							-855
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5125,
					"arcs": [
						[
							4457,
							-1383,
							-207,
							4458,
							-3873
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8003,
					"arcs": [
						[
							-1762,
							4459,
							4460,
							4461,
							-3776
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12041,
					"arcs": [
						[
							4462,
							-3491,
							-3538,
							4463,
							4464,
							4465
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12077,
					"arcs": [
						[
							4466,
							4467,
							4468,
							-4171,
							4469,
							4470
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13283,
					"arcs": [
						[
							4471,
							4472,
							4473,
							4474
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13151,
					"arcs": [
						[
							4475,
							4476,
							4477,
							-1046,
							4478,
							4479
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16063,
					"arcs": [
						[
							4480,
							4481,
							-492,
							4482,
							-3511
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17065,
					"arcs": [
						[
							4483,
							4484,
							4485,
							4486,
							-337,
							4487
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18145,
					"arcs": [
						[
							4488,
							-1055,
							-2988,
							-1001,
							4489,
							4490
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19165,
					"arcs": [
						[
							-1693,
							4491,
							-3246,
							4492,
							4493
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20097,
					"arcs": [
						[
							-3217,
							-3487,
							-709,
							-589,
							4494,
							-3394
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54071,
					"arcs": [
						[
							4495,
							4496,
							4497,
							4498,
							4499,
							4500,
							-850
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37185,
					"arcs": [
						[
							4501,
							4502,
							4503,
							-4077,
							-3351,
							-3091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48455,
					"arcs": [
						[
							4504,
							4505,
							4506,
							4507,
							4508
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38075,
					"arcs": [
						[
							-3064,
							4509,
							4510,
							4511,
							4512
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48505,
					"arcs": [
						[
							4513,
							4514,
							4515,
							4516
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1023,
					"arcs": [
						[
							-313,
							-601,
							4517,
							4518,
							4519,
							4520,
							-4216
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1113,
					"arcs": [
						[
							4521,
							4522,
							4523,
							4524,
							4525,
							4526,
							4527
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5005,
					"arcs": [
						[
							-2375,
							-2317,
							4528,
							4529,
							-2564,
							-3
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8113,
					"arcs": [
						[
							4530,
							4531,
							4532,
							4533,
							-1112
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13087,
					"arcs": [
						[
							4534,
							4535,
							-2141,
							-3911,
							-2161,
							4536,
							-3786
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17177,
					"arcs": [
						[
							4537,
							4538,
							4539,
							4540,
							4541,
							4542
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19163,
					"arcs": [
						[
							4543,
							-4176,
							4544,
							-3042
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20021,
					"arcs": [
						[
							-3573,
							4545,
							-3594,
							4546,
							-4019,
							4547
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29189,
					"arcs": [
						[
							4548,
							4549,
							4550,
							4551,
							4552,
							4553,
							-1249
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29201,
					"arcs": [
						[
							4554,
							4555,
							4556,
							4557,
							4558
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29069,
					"arcs": [
						[
							4559,
							4560,
							-3341,
							4561,
							4562,
							4563,
							4564,
							4565
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31051,
					"arcs": [
						[
							4566,
							4567,
							4568,
							4569,
							4570,
							4571
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33019,
					"arcs": [
						[
							4572,
							4573,
							4574,
							4575,
							4576,
							4577
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33017,
					"arcs": [
						[
							-1306,
							4578,
							4579,
							4580,
							4581
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35009,
					"arcs": [
						[
							4582,
							4583,
							-38,
							4584,
							4585
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35039,
					"arcs": [
						[
							4586,
							-3779,
							4587,
							4588,
							4589,
							4590,
							-4066,
							4591
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36073,
					"arcs": [
						[
							4592,
							4593,
							4594,
							4595
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37139,
					"arcs": [
						[
							4596,
							4597,
							4598,
							4599
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38087,
					"arcs": [
						[
							-2445,
							-1020,
							-1965,
							-3314,
							4600,
							4601,
							4602
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40139,
					"arcs": [
						[
							4603,
							-174,
							-2711,
							4604,
							4605,
							4606,
							-3260,
							4607
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41049,
					"arcs": [
						[
							4608,
							-3462,
							4609,
							4610,
							4611,
							-1982
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41063,
					"arcs": [
						[
							-4447,
							-618,
							4612,
							4613,
							-2277,
							4614,
							4615,
							-3727,
							4616
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42071,
					"arcs": [
						[
							-2534,
							4617,
							4618,
							4619,
							4620,
							4621,
							4622
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47047,
					"arcs": [
						[
							4623,
							-4313,
							4624,
							4625,
							4626,
							4627
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47155,
					"arcs": [
						[
							4628,
							4629,
							4630,
							4631,
							4632,
							4633
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48357,
					"arcs": [
						[
							-4606,
							4634,
							4635,
							-917,
							4636
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48295,
					"arcs": [
						[
							4637,
							4638,
							-43,
							-918,
							-4636
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48465,
					"arcs": [
						[
							4639,
							-2460,
							-2943,
							4640,
							4641,
							4642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21039,
					"arcs": [
						[
							4643,
							4644,
							-4233,
							4645,
							4646
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21117,
					"arcs": [
						[
							4647,
							-3054,
							4648,
							4649,
							4650
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22011,
					"arcs": [
						[
							4651,
							-81,
							4652,
							-4193,
							4653
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25011,
					"arcs": [
						[
							4654,
							4655,
							4656,
							4657,
							4658,
							4659
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26047,
					"arcs": [
						[
							4660,
							4661,
							4662
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27077,
					"arcs": [
						[
							4663,
							4664,
							4665,
							4666
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51121,
					"arcs": [
						[
							4667,
							4668,
							4669,
							4670,
							4671,
							-2111,
							4672
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51161,
					"arcs": [
						[
							4673,
							4674,
							-3814,
							4675,
							4676,
							-4670
						],
						[
							4677,
							-268
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54007,
					"arcs": [
						[
							4678,
							-4137,
							-2124,
							4679,
							4680,
							4681
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56009,
					"arcs": [
						[
							4682,
							-856,
							-204,
							4683,
							-2604,
							4684,
							4685,
							-1651
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36037,
					"arcs": [
						[
							-4595,
							4686,
							4687,
							4688,
							4689,
							4690
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21045,
					"arcs": [
						[
							-4257,
							4691,
							4692,
							4693,
							-1098,
							4694,
							4695
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21115,
					"arcs": [
						[
							4696,
							4697,
							-3150,
							-3934,
							4698
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13225,
					"arcs": [
						[
							4699,
							4700,
							4701,
							4702,
							4703
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21009,
					"arcs": [
						[
							4704,
							4705,
							4706,
							4707,
							-3161,
							4708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29011,
					"arcs": [
						[
							-714,
							4709,
							-891,
							4710,
							-3571
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18137,
					"arcs": [
						[
							-1004,
							-2621,
							-3554,
							4711,
							4712,
							4713,
							4714
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18077,
					"arcs": [
						[
							-4714,
							4715,
							4716,
							4717,
							4718,
							4719,
							4720
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19101,
					"arcs": [
						[
							4721,
							4722,
							-2247,
							4723,
							-1170
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19107,
					"arcs": [
						[
							-1777,
							-1010,
							4724,
							-4722,
							-1169,
							-3118
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46099,
					"arcs": [
						[
							4725,
							4726,
							-463,
							4727,
							-4033,
							-524,
							4728
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47107,
					"arcs": [
						[
							-3718,
							4729,
							4730,
							4731,
							4732,
							4733
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56001,
					"arcs": [
						[
							-4685,
							-2603,
							4734,
							4735,
							4736,
							4737
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38011,
					"arcs": [
						[
							-4602,
							4738,
							4739,
							4740
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40105,
					"arcs": [
						[
							4741,
							4742,
							-4024,
							4743,
							-3631
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54025,
					"arcs": [
						[
							4744,
							-2128,
							-853,
							4745,
							4746,
							4747,
							4748,
							4749
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54099,
					"arcs": [
						[
							4750,
							4751,
							4752,
							4753,
							-3465,
							4754,
							4755
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55095,
					"arcs": [
						[
							4756,
							4757,
							-3731,
							4758,
							4759
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2230,
					"arcs": [
						[
							4760,
							4761
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6039,
					"arcs": [
						[
							4762,
							4763,
							-2879,
							4764,
							4765
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6043,
					"arcs": [
						[
							-4764,
							4766,
							4767,
							-2880
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8027,
					"arcs": [
						[
							4768,
							-2388,
							4769,
							4770
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17115,
					"arcs": [
						[
							-2661,
							-627,
							4771,
							4772,
							4773,
							4774,
							4775
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17019,
					"arcs": [
						[
							-1721,
							-1534,
							4776,
							4777,
							4778
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18023,
					"arcs": [
						[
							4779,
							4780,
							4781,
							4782,
							-429,
							-2255,
							4783
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18055,
					"arcs": [
						[
							4784,
							4785,
							4786,
							4787,
							-753,
							4788,
							-3947,
							4789
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18045,
					"arcs": [
						[
							-2195,
							4790,
							4791,
							4792,
							4793
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20009,
					"arcs": [
						[
							4794,
							-2245,
							4795,
							-1976,
							-1971,
							-2226
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20045,
					"arcs": [
						[
							4796,
							4797,
							4798,
							-3569,
							-3920,
							4799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21087,
					"arcs": [
						[
							4800,
							4801,
							4802,
							4803,
							4804
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21205,
					"arcs": [
						[
							-3641,
							4805,
							4806,
							4807,
							4808,
							4809,
							4810
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22079,
					"arcs": [
						[
							-3649,
							4811,
							4812,
							4813,
							-4260,
							-78,
							4814
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22043,
					"arcs": [
						[
							4815,
							4816,
							-4812,
							-3648
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27145,
					"arcs": [
						[
							-359,
							4817,
							4818,
							4819,
							-3106,
							-1821,
							4820,
							-1508,
							-1830
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29123,
					"arcs": [
						[
							-2392,
							4821,
							4822,
							4823,
							-3320
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29139,
					"arcs": [
						[
							4824,
							-1626,
							4825,
							4826,
							-1436,
							4827,
							4828
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35057,
					"arcs": [
						[
							4829,
							4830,
							4831,
							4832,
							4833,
							-1130,
							4834
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8117,
					"arcs": [
						[
							4835,
							-4292,
							4836,
							4837,
							4838
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35001,
					"arcs": [
						[
							-4069,
							4839,
							-4830,
							4840,
							-3385
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36097,
					"arcs": [
						[
							-541,
							4841,
							4842,
							4843,
							-3616
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36091,
					"arcs": [
						[
							4844,
							4845,
							4846,
							4847,
							-2855,
							4848,
							4849,
							4850
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37127,
					"arcs": [
						[
							-3352,
							-4081,
							-3693,
							-3128,
							4851
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40125,
					"arcs": [
						[
							-282,
							4852,
							-775,
							-4095,
							-4149,
							4853,
							-4090
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41023,
					"arcs": [
						[
							-4611,
							4854,
							-3729,
							4855,
							4856,
							4857,
							4858,
							4859
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42011,
					"arcs": [
						[
							4860,
							4861,
							4862,
							-4618,
							-2533,
							4863
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47117,
					"arcs": [
						[
							4864,
							4865,
							4866,
							4867,
							-4046,
							4868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47093,
					"arcs": [
						[
							4869,
							4870,
							4871,
							-4634,
							4872,
							4873,
							-3716,
							4874
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48291,
					"arcs": [
						[
							4875,
							4876,
							4877,
							4878,
							-3432,
							-3439,
							4879
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46047,
					"arcs": [
						[
							-732,
							4880,
							-1260,
							4881,
							4882
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48041,
					"arcs": [
						[
							4883,
							4884,
							4885,
							4886,
							4887
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4025,
					"arcs": [
						[
							-289,
							4888,
							4889,
							-154,
							-3005
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8065,
					"arcs": [
						[
							4890,
							-4838,
							4891,
							4892,
							4893
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12097,
					"arcs": [
						[
							4894,
							4895,
							4896,
							4897,
							4898
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13153,
					"arcs": [
						[
							-4702,
							4899,
							4900,
							4901,
							4902,
							4903,
							4904,
							4905
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17125,
					"arcs": [
						[
							4906,
							4907,
							-2664,
							4908,
							4909,
							-1191
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18143,
					"arcs": [
						[
							4910,
							4911,
							-4720,
							4912,
							4913
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20155,
					"arcs": [
						[
							4914,
							4915,
							-1482,
							4916,
							-790,
							-3486,
							-1978
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53073,
					"arcs": [
						[
							[
								4917,
								4918,
								4919
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55063,
					"arcs": [
						[
							4920,
							-2832,
							4921,
							4922,
							4923,
							4924
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1101,
					"arcs": [
						[
							4925,
							4926,
							4927,
							4928,
							-3478,
							4929,
							4930
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1047,
					"arcs": [
						[
							-2086,
							4931,
							4932,
							4933,
							-2422,
							4934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1015,
					"arcs": [
						[
							-3861,
							4935,
							4936,
							-3865,
							4937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49041,
					"arcs": [
						[
							4938,
							4939,
							4940,
							4941,
							4942,
							-4434
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51155,
					"arcs": [
						[
							4943,
							-4673,
							-2112,
							-4672,
							4944,
							4945,
							4946,
							4947
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51097,
					"arcs": [
						[
							-4364,
							4948,
							4949,
							-4439,
							4950,
							4951,
							4952
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54013,
					"arcs": [
						[
							-3827,
							4953,
							-4682,
							4954,
							4955,
							4956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12017,
					"arcs": [
						[
							4957,
							4958,
							4959,
							4960,
							4961
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2282,
					"arcs": [
						[
							4962,
							-1575,
							4963,
							4964
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5137,
					"arcs": [
						[
							-2565,
							-4530,
							4965,
							4966,
							-3482,
							-2324
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6007,
					"arcs": [
						[
							4967,
							4968,
							4969,
							4970,
							4971,
							4972
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13227,
					"arcs": [
						[
							4973,
							4974,
							4975,
							4976
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21021,
					"arcs": [
						[
							4977,
							4978,
							4979,
							4980,
							-4692,
							-4256
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4013,
					"arcs": [
						[
							-4890,
							4981,
							4982,
							4983,
							4984,
							-155
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5057,
					"arcs": [
						[
							4985,
							-623,
							-3072,
							4986,
							4987,
							4988
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5011,
					"arcs": [
						[
							4989,
							-3292,
							4990,
							4991,
							4992
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8059,
					"arcs": [
						[
							-4290,
							4993,
							4994,
							4995,
							4996,
							4997,
							-2381,
							4998,
							-2405,
							4999
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8111,
					"arcs": [
						[
							-4533,
							5000,
							5001,
							5002,
							5003
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13171,
					"arcs": [
						[
							5004,
							-1050,
							5005,
							5006,
							5007
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2020,
					"arcs": [
						[
							[
								5008,
								5009,
								-1863,
								5010
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28151,
					"arcs": [
						[
							5011,
							5012,
							5013,
							5014,
							5015,
							-3366
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28125,
					"arcs": [
						[
							-5015,
							5016,
							5017,
							5018
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29211,
					"arcs": [
						[
							-3295,
							5019,
							-1837,
							-1897,
							-3993
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29015,
					"arcs": [
						[
							-2822,
							5020,
							5021,
							-1608,
							5022,
							-2361
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18019,
					"arcs": [
						[
							5023,
							-4913,
							-4719,
							5024,
							5025,
							-4189,
							5026
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13245,
					"arcs": [
						[
							5027,
							5028,
							5029,
							5030,
							5031,
							5032
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21007,
					"arcs": [
						[
							5033,
							5034,
							-4644,
							5035,
							5036
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13093,
					"arcs": [
						[
							5037,
							-4905,
							5038,
							5039,
							5040,
							5041
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13129,
					"arcs": [
						[
							5042,
							5043,
							5044,
							5045,
							-4977,
							5046,
							5047
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21077,
					"arcs": [
						[
							5048,
							5049,
							5050,
							5051,
							5052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21043,
					"arcs": [
						[
							5053,
							5054,
							5055,
							5056,
							5057,
							-4807
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22127,
					"arcs": [
						[
							-3969,
							5058,
							5059,
							5060,
							-4816,
							-3647
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 13193,
					"arcs": [
						[
							[
								-4700,
								5061,
								5062
							]
						],
						[
							[
								5063,
								5064,
								-4703,
								-4906,
								-5038,
								5065,
								5066
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 13269,
					"arcs": [
						[
							[
								-5062,
								-4704,
								-5065
							]
						],
						[
							[
								5067,
								5068,
								5069,
								-5063,
								-5064,
								5070,
								5071
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13095,
					"arcs": [
						[
							-2816,
							-2595,
							5072,
							-3907,
							-2139,
							5073
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4001,
					"arcs": [
						[
							-1115,
							5074,
							-3358,
							-3387,
							-3156,
							5075,
							5076,
							5077
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18079,
					"arcs": [
						[
							-1005,
							-4715,
							-4721,
							-4912,
							5078,
							5079
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19009,
					"arcs": [
						[
							-346,
							-1472,
							-3247,
							-4492
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20041,
					"arcs": [
						[
							-2295,
							-3918,
							5080,
							-2542,
							5081,
							5082
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20063,
					"arcs": [
						[
							-2516,
							-3913,
							-3101,
							-2311,
							5083,
							-348,
							-72,
							5084
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21203,
					"arcs": [
						[
							5085,
							5086,
							-2721,
							5087,
							5088,
							5089
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13123,
					"arcs": [
						[
							-450,
							5090,
							-4974,
							-5046,
							5091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13023,
					"arcs": [
						[
							5092,
							5093,
							5094,
							5095,
							-4903
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13097,
					"arcs": [
						[
							5096,
							5097,
							5098,
							5099
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13211,
					"arcs": [
						[
							5100,
							5101,
							5102,
							5103,
							5104,
							5105
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17009,
					"arcs": [
						[
							-1193,
							5106,
							5107,
							5108,
							5109
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17141,
					"arcs": [
						[
							-4540,
							5110,
							-2949,
							5111,
							5112,
							5113
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19013,
					"arcs": [
						[
							5114,
							5115,
							5116,
							-2253,
							-1802,
							5117
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21113,
					"arcs": [
						[
							5118,
							5119,
							5120,
							5121,
							5122
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21061,
					"arcs": [
						[
							5123,
							5124,
							-4706,
							5125,
							5126
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21005,
					"arcs": [
						[
							-3924,
							-3523,
							5127,
							5128,
							5129,
							5130,
							5131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19017,
					"arcs": [
						[
							5132,
							5133,
							-5115,
							5134
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18119,
					"arcs": [
						[
							5135,
							5136,
							5137,
							-4786,
							5138
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31129,
					"arcs": [
						[
							-606,
							-2725,
							-3109,
							5139,
							5140,
							5141
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47101,
					"arcs": [
						[
							-1904,
							5142,
							5143,
							5144,
							5145
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31095,
					"arcs": [
						[
							5146,
							-558,
							5147,
							5148
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40119,
					"arcs": [
						[
							5149,
							5150,
							-1677,
							5151,
							5152
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1077,
					"arcs": [
						[
							5153,
							5154,
							-4048,
							-2701,
							5155,
							5156,
							-25,
							5157
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1049,
					"arcs": [
						[
							-4157,
							5158,
							5159,
							5160,
							5161,
							-3859,
							5162
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1053,
					"arcs": [
						[
							5163,
							5164,
							-3752,
							5165,
							5166,
							-3007,
							-148
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31011,
					"arcs": [
						[
							-1620,
							-1312,
							5167,
							-3673,
							-3666,
							5168
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31143,
					"arcs": [
						[
							-3671,
							5169,
							-2774,
							5170,
							5171
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18149,
					"arcs": [
						[
							-3560,
							-3253,
							-1126,
							5172,
							5173
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31089,
					"arcs": [
						[
							5174,
							5175,
							-1622,
							5176,
							-2050,
							5177,
							-4278
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19135,
					"arcs": [
						[
							-2450,
							-3119,
							-1172,
							-3180,
							-939
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51670,
					"arcs": [
						[
							5178,
							5179
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37033,
					"arcs": [
						[
							5180,
							5181,
							5182,
							5183,
							5184,
							-2669,
							-3232,
							5185
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47161,
					"arcs": [
						[
							5186,
							5187,
							-2562,
							-245,
							5188,
							5189,
							-1563
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37101,
					"arcs": [
						[
							-1504,
							-4852,
							-3127,
							5190,
							5191,
							5192
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37121,
					"arcs": [
						[
							5193,
							5194,
							5195,
							5196,
							5197
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25019,
					"arcs": [
						[
							5198
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28081,
					"arcs": [
						[
							5199,
							-3307,
							5200,
							5201,
							5202,
							5203
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30095,
					"arcs": [
						[
							-3244,
							5204,
							5205,
							5206,
							5207
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31159,
					"arcs": [
						[
							-2773,
							-2613,
							-555,
							5208
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29169,
					"arcs": [
						[
							5209,
							5210,
							-131,
							-3203,
							-1404,
							-1280
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35005,
					"arcs": [
						[
							5211,
							-655,
							5212,
							5213,
							-610,
							5214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38059,
					"arcs": [
						[
							5215,
							-810,
							5216,
							-3629,
							5217,
							5218,
							-1962
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37027,
					"arcs": [
						[
							5219,
							5220,
							5221,
							5222,
							5223,
							5224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27103,
					"arcs": [
						[
							5225,
							5226,
							5227,
							-1536,
							5228
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27097,
					"arcs": [
						[
							5229,
							5230,
							-1876,
							5231,
							-4819,
							5232
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39157,
					"arcs": [
						[
							5233,
							5234,
							-3701,
							5235,
							-2012,
							5236
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8097,
					"arcs": [
						[
							-569,
							5237,
							-4894,
							5238,
							5239,
							-3765
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37151,
					"arcs": [
						[
							-1412,
							-3234,
							5240,
							5241,
							5242,
							5243
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39139,
					"arcs": [
						[
							5244,
							5245,
							-1373,
							5246,
							5247
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38055,
					"arcs": [
						[
							5248,
							5249,
							5250,
							5251,
							-808,
							5252,
							5253,
							5254
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45059,
					"arcs": [
						[
							5255,
							5256,
							5257,
							5258,
							5259,
							5260
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46115,
					"arcs": [
						[
							5261,
							-663,
							-1561,
							-1552,
							5262,
							-2868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5027,
					"arcs": [
						[
							5263,
							-3075,
							5264,
							5265,
							-3581,
							5266
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6003,
					"arcs": [
						[
							5267,
							-441,
							5268,
							5269,
							-2877,
							-3876
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 12086,
					"arcs": [
						[
							[
								5270,
								5271
							]
						],
						[
							[
								5272,
								-2004,
								5273,
								5274,
								5275,
								5276
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13083,
					"arcs": [
						[
							5277,
							5278,
							5279,
							-5159,
							-4156
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16027,
					"arcs": [
						[
							5280,
							5281,
							-3012,
							5282,
							5283
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17001,
					"arcs": [
						[
							5284,
							-1194,
							-5110,
							5285,
							5286,
							5287
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48213,
					"arcs": [
						[
							5288,
							5289,
							5290,
							5291,
							5292,
							5293,
							5294,
							-1333
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53025,
					"arcs": [
						[
							5295,
							5296,
							5297,
							-4134,
							5298,
							-3458,
							5299,
							5300
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50021,
					"arcs": [
						[
							5301,
							5302,
							5303,
							5304
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27129,
					"arcs": [
						[
							-1510,
							5305,
							-2544,
							5306,
							-5226,
							5307,
							-4282,
							5308,
							5309
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53059,
					"arcs": [
						[
							5310,
							5311,
							5312,
							5313,
							5314,
							5315,
							5316
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42129,
					"arcs": [
						[
							5317,
							5318,
							5319,
							5320,
							5321,
							5322,
							5323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42027,
					"arcs": [
						[
							5324,
							5325,
							5326,
							5327,
							5328,
							5329
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45041,
					"arcs": [
						[
							5330,
							-4416,
							5331,
							5332,
							5333,
							5334,
							5335,
							5336
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47031,
					"arcs": [
						[
							5337,
							5338,
							5339,
							5340,
							5341,
							5342,
							5343
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47035,
					"arcs": [
						[
							5344,
							5345,
							5346,
							5347,
							-3713,
							-1173,
							5348,
							5349,
							5350,
							5351
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49057,
					"arcs": [
						[
							5352,
							5353,
							5354,
							5355,
							5356
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47127,
					"arcs": [
						[
							5357,
							-5344,
							5358,
							5359
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47007,
					"arcs": [
						[
							5360,
							-5349,
							-1177,
							5361,
							5362
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48119,
					"arcs": [
						[
							5363,
							-3849,
							5364,
							-2030,
							5365,
							-2956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47015,
					"arcs": [
						[
							5366,
							5367,
							5368,
							-5340,
							5369
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48209,
					"arcs": [
						[
							5370,
							-1096,
							5371,
							5372,
							-2587
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48407,
					"arcs": [
						[
							5373,
							-4508,
							5374,
							-4876,
							5375
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18153,
					"arcs": [
						[
							5376,
							-304,
							5377,
							-4790,
							-3946,
							5378
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19145,
					"arcs": [
						[
							5379,
							-2267,
							5380,
							5381,
							5382
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19177,
					"arcs": [
						[
							-4724,
							-2252,
							5383,
							5384,
							-2834,
							-1703
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20039,
					"arcs": [
						[
							-466,
							5385,
							-827,
							-3912,
							-2514,
							-1737
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21015,
					"arcs": [
						[
							5386,
							-4650,
							5387,
							-5049,
							5388,
							5389,
							-3556
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 44005,
					"arcs": [
						[
							[
								5390,
								5391
							]
						],
						[
							[
								5392
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41069,
					"arcs": [
						[
							-1983,
							-4612,
							-4860,
							5393,
							5394,
							-2825
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53021,
					"arcs": [
						[
							-5299,
							-4133,
							5395,
							-4445,
							5396,
							-3459
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30097,
					"arcs": [
						[
							5397,
							-3048,
							-3245,
							-5208,
							5398
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30023,
					"arcs": [
						[
							5399,
							5400,
							5401,
							5402,
							-181,
							5403
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30093,
					"arcs": [
						[
							5404,
							-3605,
							-182,
							-5403
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47041,
					"arcs": [
						[
							5405,
							5406,
							5407,
							5408,
							-5368,
							5409
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48063,
					"arcs": [
						[
							-2033,
							5410,
							-2048,
							-4122,
							5411
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48241,
					"arcs": [
						[
							5412,
							5413,
							5414,
							5415,
							5416,
							5417,
							5418
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49055,
					"arcs": [
						[
							-4942,
							5419,
							-1109,
							5420,
							5421
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51137,
					"arcs": [
						[
							-4327,
							5422,
							5423,
							5424,
							5425,
							-3819
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48001,
					"arcs": [
						[
							-5293,
							5426,
							5427,
							5428,
							5429
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50023,
					"arcs": [
						[
							5430,
							5431,
							-3452,
							5432,
							5433
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51011,
					"arcs": [
						[
							5434,
							-2804,
							5435,
							5436,
							5437,
							5438
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51063,
					"arcs": [
						[
							-4671,
							-4677,
							5439,
							5440,
							5441,
							-4945
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23025,
					"arcs": [
						[
							5442,
							5443,
							-4267,
							5444,
							-3588,
							5445,
							5446
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26089,
					"arcs": [
						[
							[
								5447
							]
						],
						[
							[
								5448,
								-176,
								5449
							]
						],
						[
							[
								5450
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4017,
					"arcs": [
						[
							-1116,
							-5078,
							5451,
							5452,
							-287
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4019,
					"arcs": [
						[
							-4984,
							5453,
							5454,
							5455,
							-3748,
							5456,
							5457
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8033,
					"arcs": [
						[
							-4534,
							-5004,
							5458,
							-1113
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9011,
					"arcs": [
						[
							5459,
							5460,
							-3783,
							-477,
							5461,
							5462,
							5463
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51163,
					"arcs": [
						[
							5464,
							5465,
							5466,
							5467,
							5468,
							-3809,
							5469
						],
						[
							-3411
						],
						[
							-1403
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53037,
					"arcs": [
						[
							-3207,
							5470,
							-5301,
							5471,
							5472
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20187,
					"arcs": [
						[
							5473,
							-2442,
							-2729,
							-170,
							5474,
							-2926
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13207,
					"arcs": [
						[
							-5006,
							-1049,
							5475,
							5476,
							5477,
							5478,
							5479
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29117,
					"arcs": [
						[
							-1898,
							-1840,
							5480,
							5481,
							-2107,
							-766
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17103,
					"arcs": [
						[
							-5112,
							-2948,
							-697,
							5482,
							5483
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26075,
					"arcs": [
						[
							-1475,
							5484,
							-1295,
							5485,
							-544,
							5486,
							5487
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21063,
					"arcs": [
						[
							-4808,
							-5058,
							5488,
							5489
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21183,
					"arcs": [
						[
							5490,
							5491,
							5492,
							5493,
							5494,
							5495,
							-3929
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22081,
					"arcs": [
						[
							5496,
							5497,
							-3970,
							-3645,
							5498
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23019,
					"arcs": [
						[
							-4266,
							5499,
							-2635,
							-453,
							-3589,
							-5445
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27083,
					"arcs": [
						[
							5500,
							-4286,
							5501,
							5502,
							-1612
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27007,
					"arcs": [
						[
							5503,
							-4666,
							5504,
							5505,
							5506,
							-3271,
							5507,
							5508,
							-3722
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28145,
					"arcs": [
						[
							5509,
							5510,
							-2552,
							5511,
							-5204,
							5512,
							-684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28163,
					"arcs": [
						[
							5513,
							5514,
							5515,
							-3659,
							5516,
							5517,
							-5018
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27049,
					"arcs": [
						[
							-3278,
							5518,
							5519,
							-4351,
							-1817,
							-512,
							-2530
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 28059,
					"arcs": [
						[
							[
								5520
							]
						],
						[
							[
								5521
							]
						],
						[
							[
								-835,
								5522,
								5523,
								5524,
								5525
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29097,
					"arcs": [
						[
							-3572,
							-4711,
							-896,
							-1325,
							-3595,
							-4546
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30067,
					"arcs": [
						[
							5526,
							5527,
							-5399,
							-5207,
							5528,
							-1654
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32007,
					"arcs": [
						[
							5529,
							5530,
							5531,
							5532,
							5533,
							5534,
							5535,
							5536,
							5537
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36071,
					"arcs": [
						[
							5538,
							5539,
							5540,
							-187,
							5541,
							5542,
							-3421,
							5543
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 12087,
					"arcs": [
						[
							[
								-5275,
								5544,
								-5272,
								5545
							]
						],
						[
							[
								5546
							]
						],
						[
							[
								5547
							]
						],
						[
							[
								5548,
								-5277,
								5549
							]
						],
						[
							[
								5550
							]
						],
						[
							[
								5551
							]
						],
						[
							[
								5552
							]
						],
						[
							[
								5553
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12091,
					"arcs": [
						[
							-5166,
							-3751,
							5554,
							5555,
							5556
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13101,
					"arcs": [
						[
							-3494,
							-1934,
							-3534,
							5557,
							5558
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13213,
					"arcs": [
						[
							5559,
							5560,
							-451,
							-5092,
							-5045,
							5561
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17201,
					"arcs": [
						[
							5562,
							5563,
							-3089,
							-5111,
							-4539
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18043,
					"arcs": [
						[
							5564,
							-5027,
							-4188,
							5565
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31073,
					"arcs": [
						[
							-796,
							5566,
							5567,
							-2189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31141,
					"arcs": [
						[
							-1311,
							5568,
							5569,
							-2769,
							-5170,
							-3670,
							-3674,
							-5168
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20129,
					"arcs": [
						[
							-5475,
							-175,
							-4604,
							5570,
							-2927
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37111,
					"arcs": [
						[
							5571,
							-5196,
							5572,
							5573,
							5574,
							5575
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48373,
					"arcs": [
						[
							-4507,
							5576,
							5577,
							5578,
							-4877,
							-5375
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1119,
					"arcs": [
						[
							-2690,
							-2610,
							-2169,
							5579,
							5580,
							-4518,
							-600
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8007,
					"arcs": [
						[
							5581,
							5582,
							-1764,
							-3780,
							-4587,
							5583,
							5584
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37061,
					"arcs": [
						[
							5585,
							-4075,
							5586,
							5587,
							5588,
							5589
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38083,
					"arcs": [
						[
							5590,
							-3144,
							-2583,
							-2989,
							5591,
							-5251
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38061,
					"arcs": [
						[
							-3066,
							5592,
							-5255,
							5593,
							5594,
							5595
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40145,
					"arcs": [
						[
							5596,
							5597,
							-244,
							5598,
							5599,
							-2059
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46117,
					"arcs": [
						[
							5600,
							-1894,
							5601,
							-2892,
							5602,
							5603
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48257,
					"arcs": [
						[
							-1808,
							-2960,
							5604,
							-5289,
							-1332,
							-115
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48249,
					"arcs": [
						[
							-4129,
							-3837,
							5605,
							5606,
							-3110,
							-666
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48223,
					"arcs": [
						[
							-5366,
							-2035,
							5607,
							5608,
							-2957
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51183,
					"arcs": [
						[
							5609,
							5610,
							5611,
							5612,
							5613
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51640,
					"arcs": [
						[
							5614,
							5615
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37019,
					"arcs": [
						[
							5616,
							5617,
							5618,
							5619,
							-1949
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37115,
					"arcs": [
						[
							5620,
							5621,
							5622,
							5623,
							5624,
							5625
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 39043,
					"arcs": [
						[
							[
								-324,
								5626,
								5627,
								5628,
								-3652
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39145,
					"arcs": [
						[
							-1888,
							5629,
							5630,
							5631,
							5632,
							5633
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18061,
					"arcs": [
						[
							5634,
							5635,
							-5566,
							-4192,
							-959,
							5636,
							5637
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18115,
					"arcs": [
						[
							-4712,
							-3557,
							-5390,
							5638
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19119,
					"arcs": [
						[
							-4728,
							-462,
							5639,
							-2174,
							5640,
							-4034
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19167,
					"arcs": [
						[
							-5641,
							-1153,
							5641,
							5642,
							-4035
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21003,
					"arcs": [
						[
							5643,
							-4709,
							-3166,
							5644,
							5645,
							5646
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9009,
					"arcs": [
						[
							5647,
							5648,
							5649,
							5650,
							5651
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12021,
					"arcs": [
						[
							5652,
							5653,
							-2005,
							-5273,
							-5549,
							5654
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13295,
					"arcs": [
						[
							-5280,
							5655,
							5656,
							5657,
							-5043,
							5658,
							5659,
							-5160
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17003,
					"arcs": [
						[
							5660,
							5661,
							-5037,
							5662,
							-4556,
							5663
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17185,
					"arcs": [
						[
							-1184,
							-2755,
							-3950,
							5664,
							5665,
							5666
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13115,
					"arcs": [
						[
							5667,
							-5659,
							-5048,
							5668,
							5669,
							5670
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54021,
					"arcs": [
						[
							-3826,
							5671,
							-4138,
							-4679,
							-4954
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54043,
					"arcs": [
						[
							5672,
							5673,
							5674,
							5675,
							5676,
							-3466,
							-4754
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13057,
					"arcs": [
						[
							-4976,
							5677,
							-2026,
							5678,
							5679,
							5680
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29103,
					"arcs": [
						[
							-2836,
							5681,
							5682,
							-4270,
							-1285,
							5683
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17137,
					"arcs": [
						[
							-5108,
							5684,
							5685,
							-2523,
							5686,
							-2213,
							5687
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18071,
					"arcs": [
						[
							-219,
							5688,
							-5079,
							-4911,
							5689,
							5690,
							5691
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19151,
					"arcs": [
						[
							-3121,
							5692,
							5693,
							-1670,
							-1438
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45011,
					"arcs": [
						[
							5694,
							5695,
							5696,
							5697,
							5698
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40065,
					"arcs": [
						[
							5699,
							5700,
							5701,
							5702,
							5703,
							5704
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42101,
					"arcs": [
						[
							5705,
							5706,
							5707,
							5708,
							5709,
							5710
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42133,
					"arcs": [
						[
							5711,
							5712,
							-4622,
							5713,
							5714,
							5715,
							5716
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21023,
					"arcs": [
						[
							5717,
							5718,
							5719,
							-3528,
							-3635,
							-3057
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22075,
					"arcs": [
						[
							[
								5720,
								5721,
								5722,
								5723
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22065,
					"arcs": [
						[
							[
								5724,
								5725
							]
						],
						[
							[
								5726,
								5727,
								5728,
								5729,
								5730
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18123,
					"arcs": [
						[
							5731,
							5732,
							5733,
							5734,
							5735,
							5736
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20005,
					"arcs": [
						[
							5737,
							5738,
							5739,
							5740,
							5741,
							5742,
							-2703
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21235,
					"arcs": [
						[
							5743,
							5744,
							5745,
							5746,
							5747,
							5748
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21089,
					"arcs": [
						[
							-5632,
							5749,
							5750,
							-5055,
							5751
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21163,
					"arcs": [
						[
							5752,
							-5638,
							5753,
							5754,
							-5735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39039,
					"arcs": [
						[
							5755,
							-1025,
							-107,
							5756,
							5757,
							5758
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39003,
					"arcs": [
						[
							-110,
							-2018,
							-1342,
							-2520,
							5759
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48347,
					"arcs": [
						[
							-533,
							5760,
							5761,
							5762,
							5763
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6001,
					"arcs": [
						[
							5764,
							5765,
							5766,
							-3755,
							5767
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6023,
					"arcs": [
						[
							5768,
							-3763,
							5769,
							5770,
							5771
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45051,
					"arcs": [
						[
							5772,
							-1950,
							-5620,
							5773,
							5774,
							5775
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45073,
					"arcs": [
						[
							5776,
							5777,
							5778,
							5779,
							5780,
							5781,
							5782,
							5783,
							5784
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48485,
					"arcs": [
						[
							5785,
							-4027,
							5786,
							-123,
							5787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26031,
					"arcs": [
						[
							-4661,
							5788,
							5789,
							-2216,
							-1301,
							5790
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28009,
					"arcs": [
						[
							-4626,
							5791,
							-2553,
							-5511,
							5792
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28095,
					"arcs": [
						[
							5793,
							-5201,
							-3306,
							-3087,
							-2089,
							5794,
							5795
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28157,
					"arcs": [
						[
							5796,
							5797,
							5798,
							5799,
							5800,
							5801
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28087,
					"arcs": [
						[
							5802,
							-5795,
							-2092,
							-2166,
							-2609,
							5803
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29143,
					"arcs": [
						[
							5804,
							-4558,
							5805,
							5806,
							5807,
							5808,
							5809,
							5810,
							-4563
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22029,
					"arcs": [
						[
							5811,
							5812,
							-5802,
							5813,
							5814,
							5815,
							5816,
							5817
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23005,
					"arcs": [
						[
							-1207,
							5818,
							5819,
							5820,
							-4199
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 24041,
					"arcs": [
						[
							[
								5821,
								5822,
								5823,
								5824
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8071,
					"arcs": [
						[
							5825,
							-2386,
							5826,
							-2906,
							-2930,
							5827,
							5828,
							5829
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9005,
					"arcs": [
						[
							5830,
							5831,
							5832,
							-5652,
							5833,
							5834
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13181,
					"arcs": [
						[
							5835,
							5836,
							5837,
							-3903,
							5838
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13119,
					"arcs": [
						[
							5839,
							-5782,
							5840,
							5841,
							-749
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17013,
					"arcs": [
						[
							5842,
							5843,
							5844,
							5845,
							5846,
							5847
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48443,
					"arcs": [
						[
							5848,
							-4643,
							5849,
							5850,
							5851
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49033,
					"arcs": [
						[
							5852,
							5853,
							5854,
							-436,
							-977,
							5855,
							-5355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51103,
					"arcs": [
						[
							-3455,
							5856,
							5857
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53033,
					"arcs": [
						[
							[
								5858,
								-3208,
								-5473,
								5859,
								5860
							]
						],
						[
							[
								5861
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29087,
					"arcs": [
						[
							5862,
							5863,
							5864,
							5865,
							5866,
							-4003
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30085,
					"arcs": [
						[
							5867,
							5868,
							5869,
							5870,
							5871,
							5872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31065,
					"arcs": [
						[
							-2190,
							-5568,
							5873,
							-473,
							-823,
							-5386,
							-465
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32005,
					"arcs": [
						[
							-1943,
							5874,
							5875,
							-5269,
							-440,
							-1700
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36055,
					"arcs": [
						[
							5876,
							5877,
							-4293,
							5878,
							-4687,
							-4594
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24021,
					"arcs": [
						[
							5879,
							5880,
							5881,
							5882,
							5883,
							-3972,
							5884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26053,
					"arcs": [
						[
							5885,
							5886,
							5887,
							-3474,
							5888
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27115,
					"arcs": [
						[
							5889,
							5890,
							5891,
							5892,
							5893,
							-2796
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17085,
					"arcs": [
						[
							5894,
							5895,
							-4542,
							5896,
							5897,
							5898
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18155,
					"arcs": [
						[
							-5639,
							-5389,
							-5053,
							5899,
							-4716,
							-4713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20119,
					"arcs": [
						[
							-2751,
							-3396,
							5900,
							5901,
							-2709,
							-2454
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21135,
					"arcs": [
						[
							-5633,
							-5752,
							-5054,
							-4806,
							-3640,
							5902,
							5903
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54065,
					"arcs": [
						[
							5904,
							5905,
							5906,
							5907,
							5908
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56023,
					"arcs": [
						[
							5909,
							5910,
							5911,
							5912,
							5913,
							-437,
							-5855,
							5914
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1125,
					"arcs": [
						[
							-1226,
							-2097,
							5915,
							-48,
							5916,
							5917,
							-2167
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5149,
					"arcs": [
						[
							5918,
							-863,
							5919,
							-3875,
							5920,
							5921,
							5922
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5071,
					"arcs": [
						[
							5923,
							5924,
							-865,
							5925,
							5926
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33005,
					"arcs": [
						[
							-4577,
							5927,
							5928,
							-4657,
							5929
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36083,
					"arcs": [
						[
							-4847,
							5930,
							5931,
							5932,
							5933,
							5934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37149,
					"arcs": [
						[
							5935,
							5936,
							5937,
							5938
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39105,
					"arcs": [
						[
							-2692,
							-36,
							5939,
							5940,
							5941,
							5942
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42057,
					"arcs": [
						[
							5943,
							5944,
							5945,
							5946,
							5947
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6095,
					"arcs": [
						[
							5948,
							-4399,
							5949,
							5950,
							5951,
							5952
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30041,
					"arcs": [
						[
							5953,
							5954,
							-4055,
							5955
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31025,
					"arcs": [
						[
							5956,
							5957,
							-4186,
							5958,
							5959,
							-2616
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32013,
					"arcs": [
						[
							5960,
							5961,
							5962,
							-5538,
							5963,
							5964,
							5965
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34007,
					"arcs": [
						[
							5966,
							5967,
							5968,
							-5709
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36081,
					"arcs": [
						[
							5969,
							-4012,
							5970,
							5971,
							5972,
							5973
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 23013,
					"arcs": [
						[
							[
								-456,
								5974
							]
						],
						[
							[
								5975
							]
						],
						[
							[
								-3591,
								5976,
								5977
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23007,
					"arcs": [
						[
							-5447,
							5978,
							-1204,
							-4198,
							5979
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 24039,
					"arcs": [
						[
							[
								5980,
								5981,
								5982,
								5983
							]
						],
						[
							[
								5984,
								5985
							]
						],
						[
							[
								5986,
								5987
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5147,
					"arcs": [
						[
							5988,
							5989,
							-1862,
							5990,
							5991,
							5992
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8037,
					"arcs": [
						[
							5993,
							5994,
							-4839,
							-4891,
							-5238,
							-568
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8015,
					"arcs": [
						[
							-5239,
							-4893,
							5995,
							5996,
							5997,
							5998
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13169,
					"arcs": [
						[
							5999,
							6000,
							6001,
							-3891,
							6002,
							6003,
							-5477
						]
					]
				},
				{
					"type": "Polygon",
					"id": 44001,
					"arcs": [
						[
							6004,
							6005,
							6006
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46009,
					"arcs": [
						[
							6007,
							6008,
							6009,
							6010
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48261,
					"arcs": [
						[
							[
								6011,
								6012,
								6013,
								6014
							]
						],
						[
							[
								6015,
								6016,
								6017,
								6018,
								-3112
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50001,
					"arcs": [
						[
							6019,
							-5434,
							6020,
							6021,
							-5302,
							6022,
							6023
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36029,
					"arcs": [
						[
							6024,
							-4690,
							6025,
							-3267,
							6026,
							6027
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37007,
					"arcs": [
						[
							6028,
							6029,
							6030,
							6031
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37131,
					"arcs": [
						[
							6032,
							6033,
							6034,
							6035,
							-4379,
							-4078,
							-4504
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37119,
					"arcs": [
						[
							6036,
							-1418,
							6037,
							6038,
							6039,
							6040,
							6041
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42125,
					"arcs": [
						[
							-2766,
							6042,
							6043,
							6044,
							6045,
							-5324,
							6046,
							6047,
							6048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27017,
					"arcs": [
						[
							-2760,
							6049,
							-5891,
							6050
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27117,
					"arcs": [
						[
							-1613,
							-5503,
							6051,
							-459,
							6052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29163,
					"arcs": [
						[
							6053,
							-5848,
							6054,
							-4826,
							-1625,
							6055
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31067,
					"arcs": [
						[
							-5148,
							-557,
							-2618,
							6056,
							-3029,
							6057,
							6058
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31015,
					"arcs": [
						[
							-3426,
							6059,
							6060,
							-5175,
							6061
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31027,
					"arcs": [
						[
							6062,
							6063,
							-4567,
							6064,
							6065,
							6066
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13121,
					"arcs": [
						[
							-5099,
							6067,
							-5679,
							-2029,
							-1846,
							6068,
							6069,
							6070,
							-866,
							6071
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13021,
					"arcs": [
						[
							-5478,
							-6004,
							6072,
							-4901,
							6073
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13001,
					"arcs": [
						[
							6074,
							-4342,
							-3509,
							6075,
							6076,
							6077
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21123,
					"arcs": [
						[
							6078,
							-4259,
							6079,
							-4801,
							6080,
							6081
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21065,
					"arcs": [
						[
							6082,
							6083,
							-3412,
							-2716,
							6084
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20029,
					"arcs": [
						[
							6085,
							6086,
							-3914,
							-2293,
							-2289,
							6087
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20109,
					"arcs": [
						[
							6088,
							-2517,
							-5085,
							-71,
							6089,
							-3390
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21081,
					"arcs": [
						[
							-5388,
							-4649,
							-3059,
							-3634,
							6090,
							6091,
							-5050
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21125,
					"arcs": [
						[
							6092,
							-5088,
							-2720,
							6093,
							6094,
							-5744,
							6095
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50027,
					"arcs": [
						[
							6096,
							6097,
							-4573,
							6098,
							6099,
							-5303,
							-6022
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53011,
					"arcs": [
						[
							6100,
							-5317,
							6101,
							6102
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53019,
					"arcs": [
						[
							6103,
							6104,
							6105,
							6106
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45057,
					"arcs": [
						[
							-6041,
							6107,
							6108,
							6109,
							6110,
							-1587,
							6111
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48203,
					"arcs": [
						[
							6112,
							6113,
							6114,
							-530,
							6115,
							-4124
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48197,
					"arcs": [
						[
							-3168,
							6116,
							-5705,
							6117,
							6118,
							6119
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48351,
					"arcs": [
						[
							6120,
							-3963,
							6121,
							-4654,
							-4197,
							6122,
							-5416
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35007,
					"arcs": [
						[
							6123,
							-5829,
							6124,
							6125,
							6126,
							6127
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38021,
					"arcs": [
						[
							-3401,
							6128,
							6129,
							6130,
							6131,
							-2152
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38001,
					"arcs": [
						[
							-4601,
							-3313,
							6132,
							6133,
							-4423,
							6134,
							6135,
							-4739
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39095,
					"arcs": [
						[
							6136,
							-547,
							-3984,
							6137,
							-322,
							6138,
							-1023
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40025,
					"arcs": [
						[
							-2928,
							-5571,
							-4608,
							-3259,
							-2712,
							6139
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41041,
					"arcs": [
						[
							6140,
							6141,
							6142,
							6143,
							6144
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21167,
					"arcs": [
						[
							-5129,
							6145,
							-5123,
							6146,
							-4979,
							6147
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18175,
					"arcs": [
						[
							-5690,
							-4914,
							-5024,
							-5565,
							-5636,
							6148,
							-3198,
							6149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21217,
					"arcs": [
						[
							-6080,
							-4258,
							-4696,
							6150,
							-4802
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27015,
					"arcs": [
						[
							-5308,
							-5229,
							-1540,
							-550,
							6151,
							-4283
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29137,
					"arcs": [
						[
							-1287,
							-4273,
							6152,
							6153,
							-1623,
							6154
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29001,
					"arcs": [
						[
							-3294,
							6155,
							-2837,
							-5684,
							-1284,
							-1838,
							-5020
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30055,
					"arcs": [
						[
							-5872,
							6156,
							6157,
							6158,
							-647,
							6159
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31081,
					"arcs": [
						[
							-3672,
							-5172,
							6160,
							-2722,
							-3263,
							-135
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31017,
					"arcs": [
						[
							6161,
							-4280,
							6162,
							-229,
							6163
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55013,
					"arcs": [
						[
							-5893,
							6164,
							-2592,
							6165,
							-4757,
							6166
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56015,
					"arcs": [
						[
							6167,
							6168,
							-1346,
							6169,
							6170,
							-2601
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1073,
					"arcs": [
						[
							-2096,
							6171,
							6172,
							6173,
							-49,
							-5916
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1043,
					"arcs": [
						[
							-2860,
							6174,
							6175,
							6176,
							6177,
							-2094
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51059,
					"arcs": [
						[
							6178,
							-3976,
							6179,
							-1415,
							6180,
							6181,
							6182,
							6183,
							6184,
							6185
						],
						[
							-4335
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51171,
					"arcs": [
						[
							6186,
							-2066,
							6187,
							6188,
							6189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1095,
					"arcs": [
						[
							6190,
							-4158,
							-5163,
							-3858,
							6191,
							-6177,
							6192
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1009,
					"arcs": [
						[
							-6192,
							-3863,
							6193,
							-6172,
							-2095,
							-6178
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48391,
					"arcs": [
						[
							6194,
							6195,
							6196,
							6197,
							6198,
							6199,
							6200,
							6201,
							-3833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48077,
					"arcs": [
						[
							-4026,
							6202,
							6203,
							6204,
							-124,
							-5787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50007,
					"arcs": [
						[
							6205,
							6206,
							6207,
							-5431,
							-6020,
							6208,
							6209
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51131,
					"arcs": [
						[
							6210,
							6211
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38057,
					"arcs": [
						[
							-5253,
							-811,
							-5216,
							-1961,
							6212
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39041,
					"arcs": [
						[
							-4084,
							6213,
							-1378,
							6214,
							6215,
							-899
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45035,
					"arcs": [
						[
							6216,
							6217,
							6218,
							6219
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46017,
					"arcs": [
						[
							6220,
							6221,
							-1601,
							-3382,
							-2885
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47087,
					"arcs": [
						[
							6222,
							-4146,
							-3132,
							6223,
							6224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47057,
					"arcs": [
						[
							6225,
							6226,
							6227,
							6228,
							6229,
							6230,
							-4871
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48091,
					"arcs": [
						[
							-2407,
							-2588,
							-5373,
							6231,
							6232
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49031,
					"arcs": [
						[
							-4943,
							-5422,
							6233,
							-4435
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8049,
					"arcs": [
						[
							6234,
							6235,
							6236,
							6237,
							-4288,
							-4836,
							-5995,
							6238
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6087,
					"arcs": [
						[
							-3758,
							-1666,
							-1428,
							6239,
							6240
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13009,
					"arcs": [
						[
							6241,
							-3502,
							-3887,
							-6002,
							6242
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13141,
					"arcs": [
						[
							6243,
							-4231,
							-3900,
							6244,
							-3497,
							-6242,
							6245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16019,
					"arcs": [
						[
							-1257,
							6246,
							6247,
							6248,
							-5911,
							6249,
							6250
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24043,
					"arcs": [
						[
							-5947,
							6251,
							-5880,
							6252,
							6253,
							6254,
							-5905,
							6255
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17197,
					"arcs": [
						[
							6256,
							6257,
							6258,
							-1798,
							-998,
							6259
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19045,
					"arcs": [
						[
							6260,
							6261,
							6262,
							-4177,
							-4544,
							-3041,
							-62
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24047,
					"arcs": [
						[
							6263,
							6264,
							6265,
							6266,
							-5982
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34011,
					"arcs": [
						[
							6267,
							6268,
							-4006,
							6269,
							6270
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34033,
					"arcs": [
						[
							6271,
							6272,
							6273,
							-6271,
							6274,
							6275
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1011,
					"arcs": [
						[
							6276,
							-4527,
							6277,
							6278,
							-4928
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36085,
					"arcs": [
						[
							6279,
							6280,
							6281,
							6282
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29209,
					"arcs": [
						[
							-1328,
							-4269,
							-2416,
							6283,
							-1631
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45005,
					"arcs": [
						[
							-5698,
							6284,
							6285,
							6286,
							6287
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47005,
					"arcs": [
						[
							6288,
							-5189,
							-249,
							-3712,
							6289,
							-4110,
							-911
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25025,
					"arcs": [
						[
							6290,
							6291,
							6292,
							6293,
							6294,
							6295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47009,
					"arcs": [
						[
							6296,
							-4873,
							-4633,
							6297,
							-4014,
							6298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8051,
					"arcs": [
						[
							6299,
							6300,
							-3766,
							-5240,
							-5999,
							6301,
							6302,
							6303
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8053,
					"arcs": [
						[
							-6303,
							6304,
							6305,
							-5582,
							6306,
							-5002,
							6307
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48273,
					"arcs": [
						[
							[
								6308,
								6309,
								-6012,
								6310
							]
						],
						[
							[
								6311,
								6312,
								-6016,
								-3111,
								-5607
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1087,
					"arcs": [
						[
							6313,
							6314,
							6315,
							-4528,
							-6277,
							-4927
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5139,
					"arcs": [
						[
							6316,
							6317,
							-4992,
							6318,
							6319,
							-3582,
							-5266
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17193,
					"arcs": [
						[
							6320,
							6321,
							-5666,
							6322,
							6323,
							6324,
							-4486
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24045,
					"arcs": [
						[
							6325,
							6326,
							-6264,
							-5981,
							6327
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41031,
					"arcs": [
						[
							-3172,
							-2826,
							-5395,
							6328,
							6329,
							-1087
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51109,
					"arcs": [
						[
							6330,
							-5425,
							6331,
							6332,
							6333,
							-405
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50011,
					"arcs": [
						[
							6334,
							-331,
							6335,
							-6207,
							6336
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 37055,
					"arcs": [
						[
							[
								6337
							]
						],
						[
							[
								6338,
								6339
							]
						],
						[
							[
								6340,
								6341
							]
						],
						[
							[
								6342,
								6343
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34035,
					"arcs": [
						[
							-3679,
							6344,
							6345,
							6346,
							6347
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34039,
					"arcs": [
						[
							-6345,
							-3678,
							6348,
							6349,
							-6280,
							6350
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36111,
					"arcs": [
						[
							6351,
							6352,
							6353,
							-5539,
							6354,
							6355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45007,
					"arcs": [
						[
							-5780,
							6356,
							6357,
							6358,
							6359,
							6360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48005,
					"arcs": [
						[
							6361,
							-5763,
							6362,
							-5413,
							6363,
							-5577,
							-4506,
							6364
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46103,
					"arcs": [
						[
							-201,
							-2509,
							-4143,
							6365,
							6366,
							6367,
							-730
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45009,
					"arcs": [
						[
							6368,
							6369,
							-6285,
							-5697
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47019,
					"arcs": [
						[
							-4042,
							6370,
							6371,
							-5194,
							6372,
							6373
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51035,
					"arcs": [
						[
							-4946,
							-5442,
							6374,
							6375,
							6376,
							-5615,
							6377,
							6378
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6115,
					"arcs": [
						[
							-4969,
							6379,
							-1690,
							-2269,
							-1696,
							6380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16031,
					"arcs": [
						[
							6381,
							-494,
							6382,
							6383,
							-3802,
							6384,
							-5532,
							6385
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37175,
					"arcs": [
						[
							6386,
							6387,
							6388,
							6389,
							-5778,
							6390
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 12071,
					"arcs": [
						[
							[
								6391,
								6392,
								-5653,
								6393
							]
						],
						[
							[
								6394
							]
						],
						[
							[
								6395,
								6396
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41017,
					"arcs": [
						[
							-1088,
							-6330,
							6397,
							6398,
							-4029,
							6399,
							6400
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53075,
					"arcs": [
						[
							-4132,
							6401,
							6402,
							-3542,
							6403,
							6404,
							6405,
							-616,
							-4446,
							-5396
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29131,
					"arcs": [
						[
							6406,
							-1217,
							6407,
							-132,
							-5211,
							6408,
							6409
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26021,
					"arcs": [
						[
							6410,
							6411,
							6412,
							-3250,
							-3559
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6011,
					"arcs": [
						[
							6413,
							-4971,
							6414,
							-4396,
							6415
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5063,
					"arcs": [
						[
							6416,
							6417,
							6418,
							6419,
							6420,
							-3483,
							-4967
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51197,
					"arcs": [
						[
							-4947,
							-6379,
							6421,
							-4331,
							6422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5065,
					"arcs": [
						[
							-2316,
							6423,
							-6417,
							-4966,
							-4529
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48007,
					"arcs": [
						[
							[
								6424,
								6425,
								6426,
								6427
							]
						],
						[
							[
								6428,
								6429,
								6430,
								-6200
							]
						],
						[
							[
								6431,
								-3834,
								-6202
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45013,
					"arcs": [
						[
							6432,
							6433,
							6434,
							6435
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46135,
					"arcs": [
						[
							6436,
							-527,
							6437,
							-6063,
							6438,
							-6009
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37087,
					"arcs": [
						[
							6439,
							-5625,
							6440,
							6441,
							-6387,
							6442,
							6443,
							-4631
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48017,
					"arcs": [
						[
							-4585,
							-42,
							6444,
							-1197,
							6445
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19061,
					"arcs": [
						[
							6446,
							6447,
							-5899,
							6448,
							-60,
							-2937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20121,
					"arcs": [
						[
							-3568,
							6449,
							6450,
							-1200,
							-3921
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6081,
					"arcs": [
						[
							-4160,
							6451,
							-3759,
							-6241,
							6452
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8069,
					"arcs": [
						[
							-4736,
							6453,
							6454,
							6455,
							-6236,
							6456
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18173,
					"arcs": [
						[
							6457,
							-3517,
							6458,
							6459,
							6460,
							6461,
							6462
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20113,
					"arcs": [
						[
							6463,
							6464,
							-1478,
							-4916,
							6465,
							-2243
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55037,
					"arcs": [
						[
							6466,
							6467,
							6468,
							-4451
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29055,
					"arcs": [
						[
							-1252,
							-1278,
							-3319,
							-2908,
							-3202,
							6469
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30075,
					"arcs": [
						[
							6470,
							6471,
							-4455,
							-854,
							6472,
							6473,
							6474
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25027,
					"arcs": [
						[
							-5929,
							6475,
							6476,
							6477,
							6478,
							-3781,
							6479,
							6480,
							6481,
							-4658
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17017,
					"arcs": [
						[
							-1192,
							-4910,
							6482,
							6483,
							-5685,
							-5107
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18021,
					"arcs": [
						[
							-303,
							6484,
							6485,
							-5139,
							-4785,
							-5378
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6021,
					"arcs": [
						[
							6486,
							-4972,
							-6414,
							6487,
							6488
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5085,
					"arcs": [
						[
							6489,
							6490,
							6491,
							6492,
							6493,
							6494
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31043,
					"arcs": [
						[
							6495,
							6496,
							6497,
							-4570
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37077,
					"arcs": [
						[
							6498,
							6499,
							6500,
							-3093,
							-3353,
							-1502,
							-236
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5075,
					"arcs": [
						[
							6501,
							6502,
							6503,
							6504,
							-6419,
							6505
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5079,
					"arcs": [
						[
							6506,
							6507,
							6508,
							-3288,
							6509
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5081,
					"arcs": [
						[
							6510,
							6511,
							-4989,
							6512,
							6513,
							-4355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47029,
					"arcs": [
						[
							6514,
							6515,
							6516,
							-5626,
							-6440,
							-4630
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42017,
					"arcs": [
						[
							6517,
							6518,
							6519,
							6520,
							6521,
							-5707,
							6522,
							6523
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45019,
					"arcs": [
						[
							-6219,
							6524,
							6525,
							6526,
							6527
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 51001,
					"arcs": [
						[
							[
								-5983,
								-6267,
								6528,
								-6211,
								6529
							]
						],
						[
							[
								6530,
								-5985
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13029,
					"arcs": [
						[
							6531,
							6532,
							6533,
							6534,
							6535,
							6536
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48289,
					"arcs": [
						[
							-1914,
							6537,
							-5429,
							6538,
							6539,
							6540
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16007,
					"arcs": [
						[
							-5915,
							-5854,
							6541,
							6542
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42055,
					"arcs": [
						[
							-5946,
							6543,
							-3707,
							6544,
							6545,
							6546,
							-5881,
							-6252
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51159,
					"arcs": [
						[
							-3456,
							-5858,
							6547,
							-4361,
							6548
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 23015,
					"arcs": [
						[
							[
								6549,
								-3592,
								-5978,
								6550,
								6551
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30099,
					"arcs": [
						[
							6552,
							-4060,
							6553,
							-362,
							-2472
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51590,
					"arcs": [
						[
							-5182,
							6554
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37097,
					"arcs": [
						[
							6555,
							6556,
							-4299,
							6557,
							6558,
							6559,
							-6038,
							-1417,
							6560
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42005,
					"arcs": [
						[
							6561,
							6562,
							6563,
							6564,
							-5319,
							6565
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34001,
					"arcs": [
						[
							-5968,
							6566,
							6567,
							6568,
							-4007,
							-6269,
							6569
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42009,
					"arcs": [
						[
							6570,
							6571,
							6572,
							-5944,
							6573,
							6574
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20013,
					"arcs": [
						[
							6575,
							6576,
							-5738,
							-2702,
							-20
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22101,
					"arcs": [
						[
							6577,
							6578,
							6579,
							6580,
							6581
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51730,
					"arcs": [
						[
							6582,
							-2536,
							6583,
							6584,
							6585
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20173,
					"arcs": [
						[
							-4917,
							-1481,
							-2654,
							6586,
							-791
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42021,
					"arcs": [
						[
							6587,
							6588,
							6589,
							-6571,
							6590,
							-5321
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46019,
					"arcs": [
						[
							6591,
							6592,
							6593,
							-4139,
							-2507,
							-4457
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46027,
					"arcs": [
						[
							-526,
							-4037,
							6594,
							-4568,
							-6064,
							-6438
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47063,
					"arcs": [
						[
							6595,
							6596,
							-6516,
							6597,
							-6230
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45025,
					"arcs": [
						[
							6598,
							-6032,
							-4418,
							6599,
							6600,
							-6109
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47067,
					"arcs": [
						[
							6601,
							6602,
							6603,
							-6228,
							6604
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47071,
					"arcs": [
						[
							6605,
							-1594,
							-4113,
							6606,
							-5158,
							-24,
							6607,
							6608
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45027,
					"arcs": [
						[
							6609,
							-5335,
							6610,
							6611,
							6612,
							6613
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1033,
					"arcs": [
						[
							-5157,
							6614,
							-3330,
							-26
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46123,
					"arcs": [
						[
							-2889,
							-3428,
							6615,
							-4420,
							6616
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46105,
					"arcs": [
						[
							6617,
							-6135,
							-4428,
							6618,
							-4140,
							-6594
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40007,
					"arcs": [
						[
							-2710,
							-5902,
							6619,
							6620,
							6621,
							-4638,
							-4635,
							-4605
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12083,
					"arcs": [
						[
							6622,
							6623,
							6624,
							6625,
							6626,
							6627,
							-4958
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51135,
					"arcs": [
						[
							6628,
							6629,
							6630,
							6631,
							6632
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50025,
					"arcs": [
						[
							-6099,
							-4578,
							-5930,
							-4656,
							6633
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 25005,
					"arcs": [
						[
							[
								6634,
								6635,
								6636,
								-5391,
								6637,
								-6005,
								6638
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18073,
					"arcs": [
						[
							6639,
							6640,
							-5174,
							6641,
							6642,
							-2656,
							-2808
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17083,
					"arcs": [
						[
							-2526,
							6643,
							6644,
							-5845,
							6645
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18129,
					"arcs": [
						[
							6646,
							6647,
							6648,
							-3955,
							6649,
							-6324
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12015,
					"arcs": [
						[
							6650,
							-2780,
							-746,
							-6392,
							6651,
							-6396,
							6652
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9003,
					"arcs": [
						[
							6653,
							6654,
							-5460,
							6655,
							-5648,
							-5833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45031,
					"arcs": [
						[
							-6600,
							-4417,
							-5331,
							6656,
							6657
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42045,
					"arcs": [
						[
							6658,
							-5711,
							6659,
							6660,
							6661
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45033,
					"arcs": [
						[
							-4415,
							6662,
							-5773,
							6663,
							-5332
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45039,
					"arcs": [
						[
							-1588,
							-6111,
							6664,
							6665,
							6666,
							6667
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47089,
					"arcs": [
						[
							-6231,
							-6598,
							-6515,
							-4629,
							-4872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16017,
					"arcs": [
						[
							-3038,
							6668,
							-320,
							-722,
							6669,
							-4172,
							6670
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37187,
					"arcs": [
						[
							-4382,
							6671,
							6672,
							6673,
							-4385,
							6674
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48195,
					"arcs": [
						[
							-4607,
							-4637,
							-916,
							-276,
							-3261
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47129,
					"arcs": [
						[
							6675,
							6676,
							-3714,
							-5348,
							6677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40059,
					"arcs": [
						[
							6678,
							-592,
							6679,
							-3081,
							6680,
							-6621
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 47185,
					"arcs": [
						[
							[
								-5351,
								6681
							]
						],
						[
							[
								-5408,
								6682,
								-5345,
								6683,
								6684
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46063,
					"arcs": [
						[
							-4740,
							-6136,
							-6618,
							-6593,
							6685,
							6686
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47169,
					"arcs": [
						[
							6687,
							6688,
							6689,
							6690
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19103,
					"arcs": [
						[
							-1759,
							-3044,
							6691,
							6692,
							6693,
							-1008
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 25021,
					"arcs": [
						[
							[
								6694,
								6695
							]
						],
						[
							[
								-6292,
								6696
							]
						],
						[
							[
								6697,
								-6296,
								6698,
								6699,
								-6635,
								6700,
								-6478
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16043,
					"arcs": [
						[
							6701,
							-184,
							-3608,
							6702,
							6703,
							6704,
							6705,
							-1255
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17145,
					"arcs": [
						[
							6706,
							6707,
							6708,
							-3803,
							6709
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13233,
					"arcs": [
						[
							-5670,
							6710,
							6711,
							-951,
							6712,
							6713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24003,
					"arcs": [
						[
							6714,
							6715,
							6716,
							6717,
							6718,
							6719
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12001,
					"arcs": [
						[
							-3537,
							6720,
							-2935,
							6721,
							-6624,
							6722,
							-4464
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8029,
					"arcs": [
						[
							-6301,
							6723,
							-3767
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1051,
					"arcs": [
						[
							6724,
							6725,
							-6314,
							-4926,
							6726,
							6727
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9007,
					"arcs": [
						[
							-5464,
							6728,
							-5649,
							-6656
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1021,
					"arcs": [
						[
							-51,
							6729,
							6730,
							-6728,
							6731,
							-4932,
							-2085
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39173,
					"arcs": [
						[
							-6139,
							-326,
							-3655,
							6732,
							-2016,
							-1024
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20011,
					"arcs": [
						[
							-1210,
							-1202,
							-716,
							-3570,
							6733
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48497,
					"arcs": [
						[
							6734,
							-4431,
							-1487,
							-4316,
							-1752,
							6735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48101,
					"arcs": [
						[
							-3148,
							-3169,
							-6120,
							6736,
							-780,
							-1833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48103,
					"arcs": [
						[
							6737,
							6738,
							6739,
							6740,
							6741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27069,
					"arcs": [
						[
							6742,
							6743,
							-3720,
							6744
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41057,
					"arcs": [
						[
							6745,
							6746,
							-4310,
							6747,
							-6141,
							6748
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42035,
					"arcs": [
						[
							6749,
							-727,
							6750,
							6751,
							-5325,
							6752
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54001,
					"arcs": [
						[
							6753,
							6754,
							6755,
							6756,
							6757,
							-2116
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31105,
					"arcs": [
						[
							6758,
							-2686,
							-3377,
							6759,
							6760
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38025,
					"arcs": [
						[
							-5594,
							-5254,
							-6213,
							-1960,
							-1018,
							6761
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47003,
					"arcs": [
						[
							6762,
							-5338,
							-5358,
							6763,
							-4867
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35027,
					"arcs": [
						[
							-4834,
							6764,
							-656,
							-5212,
							6765,
							-3682,
							-1131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47013,
					"arcs": [
						[
							6766,
							-5748,
							6767,
							6768,
							6769,
							6770
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18181,
					"arcs": [
						[
							6771,
							6772,
							6773,
							6774,
							-2657,
							-6643
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18157,
					"arcs": [
						[
							-6775,
							6775,
							-4780,
							6776,
							-4791,
							-2194,
							-2658
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21047,
					"arcs": [
						[
							6777,
							-3932,
							-1521,
							-2558,
							-5188,
							6778,
							-2745
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38085,
					"arcs": [
						[
							6779,
							-5218,
							-3628,
							-4424,
							-6134
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21151,
					"arcs": [
						[
							-5121,
							6780,
							6781,
							-6085,
							-2715,
							-5087,
							6782
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13321,
					"arcs": [
						[
							6783,
							6784,
							6785,
							6786,
							-3908,
							-5073,
							-2594
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20147,
					"arcs": [
						[
							-471,
							6787,
							6788,
							6789,
							-3099,
							-824
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42063,
					"arcs": [
						[
							-6565,
							6790,
							6791,
							-6588,
							-5320
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17093,
					"arcs": [
						[
							-2230,
							6792,
							-6260,
							-997,
							-690,
							-2947
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18089,
					"arcs": [
						[
							-6259,
							6793,
							6794,
							6795,
							-6640,
							-2807,
							-1799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17097,
					"arcs": [
						[
							-3857,
							6796,
							6797,
							-13
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17139,
					"arcs": [
						[
							6798,
							6799,
							6800,
							6801,
							-4773
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21105,
					"arcs": [
						[
							-4646,
							-4237,
							6802,
							6803,
							6804,
							6805
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20031,
					"arcs": [
						[
							-68,
							6806,
							-3922,
							-1680,
							6807,
							-1442
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17029,
					"arcs": [
						[
							6808,
							6809,
							6810,
							6811,
							6812,
							-6801
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17023,
					"arcs": [
						[
							6813,
							-305,
							-5377,
							6814,
							-3310,
							6815,
							-6811
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37017,
					"arcs": [
						[
							6816,
							6817,
							6818,
							-1947,
							6819
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20207,
					"arcs": [
						[
							-6808,
							-1212,
							6820,
							-1443
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19189,
					"arcs": [
						[
							6821,
							6822,
							-1491,
							-2598,
							-580
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17041,
					"arcs": [
						[
							6823,
							-4778,
							6824,
							-6809,
							-6800
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37163,
					"arcs": [
						[
							6825,
							-5192,
							6826,
							-5590,
							6827,
							-6818,
							6828
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26059,
					"arcs": [
						[
							6829,
							-5487,
							-549,
							6830,
							6831,
							6832,
							6833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30009,
					"arcs": [
						[
							-5206,
							6834,
							6835,
							6836,
							-1655,
							-5529
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27073,
					"arcs": [
						[
							6837,
							-509,
							6838,
							6839,
							-839,
							6840
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51087,
					"arcs": [
						[
							6841,
							6842,
							6843,
							6844,
							6845,
							6846,
							6847
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33011,
					"arcs": [
						[
							-4576,
							6848,
							6849,
							6850,
							6851,
							-6476,
							-5928
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42037,
					"arcs": [
						[
							6852,
							-1369,
							-4100,
							6853,
							6854,
							-1298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48067,
					"arcs": [
						[
							6855,
							6856,
							6857,
							6858,
							-2045
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19193,
					"arcs": [
						[
							6859,
							-2606,
							-3256,
							6860,
							6861,
							-6497,
							6862
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47061,
					"arcs": [
						[
							-5342,
							6863,
							6864,
							6865,
							6866
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21051,
					"arcs": [
						[
							-2719,
							6867,
							-3885,
							6868,
							6869,
							6870,
							-6094
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51036,
					"arcs": [
						[
							-6844,
							6871,
							-4444,
							6872,
							6873,
							6874,
							6875
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42069,
					"arcs": [
						[
							-3398,
							-3283,
							6876,
							-4407,
							-4096
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42077,
					"arcs": [
						[
							6877,
							6878,
							-6524,
							6879,
							-4861,
							6880
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29107,
					"arcs": [
						[
							6881,
							6882,
							6883,
							-2819,
							-2349,
							-2737
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42083,
					"arcs": [
						[
							-3269,
							-2626,
							-729,
							6884,
							-803,
							-1397
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29119,
					"arcs": [
						[
							-3596,
							-1634,
							6885,
							6886,
							6887
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42087,
					"arcs": [
						[
							-5327,
							6888,
							-4103,
							-3709,
							6889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29151,
					"arcs": [
						[
							-1216,
							6890,
							-4829,
							6891,
							-128,
							-6408
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38105,
					"arcs": [
						[
							6892,
							-3067,
							-5596,
							6893,
							-5870,
							6894
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47157,
					"arcs": [
						[
							6895,
							6896,
							6897,
							-4628,
							6898,
							6899,
							6900
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19183,
					"arcs": [
						[
							-1009,
							-6694,
							6901,
							-2248,
							-4723,
							-4725
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26061,
					"arcs": [
						[
							6902,
							6903,
							6904,
							6905,
							6906,
							6907
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47159,
					"arcs": [
						[
							6908,
							-6225,
							6909,
							-5406,
							6910,
							-6690
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 47167,
					"arcs": [
						[
							[
								6911,
								-4314,
								-4624,
								-6898,
								6912
							]
						],
						[
							[
								6913,
								-6896,
								6914
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56035,
					"arcs": [
						[
							6915,
							6916,
							6917,
							-5913
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48245,
					"arcs": [
						[
							6918,
							6919,
							6920,
							6921,
							-3433,
							-4879
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53039,
					"arcs": [
						[
							6922,
							-3463,
							-4609,
							-1981,
							-192,
							-2824,
							6923,
							-5314
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53077,
					"arcs": [
						[
							6924,
							6925,
							-5472,
							-5300,
							-3464,
							-6923,
							-5313
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47113,
					"arcs": [
						[
							-1244,
							6926,
							-914,
							-1596,
							6927,
							6928,
							-4311
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30003,
					"arcs": [
						[
							6929,
							6930,
							6931,
							-6474,
							6932,
							6933,
							-6836
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47049,
					"arcs": [
						[
							-974,
							6934,
							-6678,
							-5347,
							6935,
							-3130
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48073,
					"arcs": [
						[
							6936,
							-534,
							-5764,
							-6362,
							6937,
							-5427,
							-5292
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51149,
					"arcs": [
						[
							-6585,
							6938,
							-5180,
							6939,
							-6875,
							6940,
							-5611,
							6941
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29133,
					"arcs": [
						[
							-5663,
							-5036,
							-4647,
							-6806,
							6942,
							-5806,
							-4557
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22055,
					"arcs": [
						[
							6943,
							6944,
							6945,
							6946,
							6947
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51683,
					"arcs": [
						[
							-260,
							6948
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29135,
					"arcs": [
						[
							-1221,
							6949,
							-1213,
							-6407,
							6950
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42095,
					"arcs": [
						[
							6951,
							-4411,
							6952,
							-6518,
							-6879
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20125,
					"arcs": [
						[
							6953,
							6954,
							6955,
							-4742,
							-3630,
							-2418,
							6956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21131,
					"arcs": [
						[
							-3884,
							6957,
							6958,
							-6869
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21133,
					"arcs": [
						[
							-3882,
							6959,
							6960,
							6961,
							6962
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20191,
					"arcs": [
						[
							-6587,
							6963,
							6964,
							6965,
							-1796,
							-792
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21139,
					"arcs": [
						[
							6966,
							6967,
							6968,
							6969,
							-3527,
							-932,
							6970
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27023,
					"arcs": [
						[
							-508,
							-1511,
							-5310,
							6971,
							-6839
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29173,
					"arcs": [
						[
							-6154,
							6972,
							6973,
							-6056,
							-1624
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30031,
					"arcs": [
						[
							6974,
							-4275,
							6975,
							-5527,
							-1661,
							6976,
							-6703,
							-3607
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49017,
					"arcs": [
						[
							-4436,
							-6234,
							-5421,
							-1108,
							6977,
							-1603
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56003,
					"arcs": [
						[
							-6837,
							-6934,
							6978,
							6979,
							6980,
							-1656
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53053,
					"arcs": [
						[
							[
								6981,
								-5860,
								-6926,
								6982,
								6983
							]
						],
						[
							[
								6984,
								6985,
								6986
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6013,
					"arcs": [
						[
							-5951,
							6987,
							6988,
							-5765,
							6989
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16045,
					"arcs": [
						[
							6990,
							6991,
							-1770,
							-2742,
							-3010,
							-5282,
							6992
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16075,
					"arcs": [
						[
							6993,
							-6993,
							-5281,
							6994
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24033,
					"arcs": [
						[
							6995,
							6996,
							-3974,
							6997,
							-6720,
							6998,
							6999,
							-6183
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32011,
					"arcs": [
						[
							-5536,
							7000,
							7001,
							7002
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22061,
					"arcs": [
						[
							7003,
							7004,
							7005,
							-3967,
							-3584
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22111,
					"arcs": [
						[
							-6320,
							-3580,
							7006,
							-7004,
							-3583
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37199,
					"arcs": [
						[
							-5197,
							-5572,
							7007,
							-5623,
							7008
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48045,
					"arcs": [
						[
							-562,
							7009,
							-3149,
							-1831,
							7010,
							7011
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27105,
					"arcs": [
						[
							7012,
							7013,
							7014,
							-2171,
							-5640,
							-461
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21145,
					"arcs": [
						[
							7015,
							7016,
							-6971,
							-931,
							-4234,
							-4645,
							-5035
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37037,
					"arcs": [
						[
							-3233,
							-2671,
							-238,
							-1506,
							7017,
							-3694,
							7018,
							-5241
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27045,
					"arcs": [
						[
							-1820,
							7019,
							7020,
							-2502,
							7021,
							-1663
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49011,
					"arcs": [
						[
							7022,
							7023,
							7024,
							-5357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21159,
					"arcs": [
						[
							7025,
							-4755,
							-3472,
							7026,
							-3151,
							-4698
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37085,
					"arcs": [
						[
							-3695,
							-7018,
							-1505,
							-5193,
							-6826,
							7027,
							7028
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27063,
					"arcs": [
						[
							7029,
							-553,
							-1617,
							7030,
							7031,
							-2172,
							-7015
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49025,
					"arcs": [
						[
							-1604,
							-6978,
							-1117,
							-285,
							-3004,
							7032
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48251,
					"arcs": [
						[
							-1754,
							-4317,
							-1336,
							-4321,
							7033,
							-4115,
							7034
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55089,
					"arcs": [
						[
							7035,
							7036,
							7037,
							-928
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47147,
					"arcs": [
						[
							-1524,
							7038,
							7039,
							7040,
							7041,
							7042,
							-2559
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30045,
					"arcs": [
						[
							-4058,
							7043,
							-3045,
							7044,
							7045
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22031,
					"arcs": [
						[
							7046,
							-5499,
							-3644,
							-3961,
							7047,
							7048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24005,
					"arcs": [
						[
							-5715,
							7049,
							7050,
							7051,
							-6716,
							7052,
							7053
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16079,
					"arcs": [
						[
							-3540,
							-4173,
							-6670,
							-721,
							7054,
							7055,
							7056
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24015,
					"arcs": [
						[
							-4620,
							7057,
							7058,
							7059,
							7060,
							7061
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37079,
					"arcs": [
						[
							-3125,
							7062,
							-4071,
							7063
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34019,
					"arcs": [
						[
							7064,
							-3680,
							-6348,
							7065,
							-6520
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5019,
					"arcs": [
						[
							7066,
							-210,
							7067,
							7068,
							-3073,
							-621
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37005,
					"arcs": [
						[
							7069,
							7070,
							7071,
							-4392
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37171,
					"arcs": [
						[
							7072,
							-6376,
							7073,
							-3215,
							-375,
							-4297,
							7074,
							-7071
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42099,
					"arcs": [
						[
							-3706,
							7075,
							7076,
							-6545
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5021,
					"arcs": [
						[
							7077,
							-3342,
							-4561,
							7078,
							7079
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49035,
					"arcs": [
						[
							-7024,
							7080,
							-981,
							-785,
							7081,
							7082
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54089,
					"arcs": [
						[
							7083,
							-4749,
							7084,
							7085,
							7086,
							7087
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37193,
					"arcs": [
						[
							7088,
							-4393,
							-7072,
							-7075,
							-4300,
							-6557,
							7089,
							-5221
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55055,
					"arcs": [
						[
							-2793,
							-1084,
							-1936,
							7090,
							-2998
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46051,
					"arcs": [
						[
							7091,
							7092,
							-6841,
							-838,
							-2283,
							-660
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46091,
					"arcs": [
						[
							7093,
							7094,
							-658,
							7095
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27025,
					"arcs": [
						[
							-5894,
							-6167,
							-4760,
							7096,
							-759,
							7097
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30059,
					"arcs": [
						[
							7098,
							-7045,
							-3049,
							-5398,
							-5528,
							-6976,
							-4274,
							-364
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48035,
					"arcs": [
						[
							-4116,
							-7034,
							-4324,
							7099,
							-251,
							-3409,
							-2497
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5091,
					"arcs": [
						[
							-6513,
							-4988,
							7100,
							7101,
							7102,
							-6857,
							7103
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16035,
					"arcs": [
						[
							7104,
							-7056,
							7105,
							-3999,
							-2273,
							-489,
							7106
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 37095,
					"arcs": [
						[
							[
								-6340,
								7107
							]
						],
						[
							[
								7108,
								7109,
								-6343,
								7110,
								-4386,
								-6674
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47065,
					"arcs": [
						[
							7111,
							7112,
							-5362,
							-1176,
							7113,
							7114,
							7115,
							7116,
							-5656,
							-5279
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19187,
					"arcs": [
						[
							7117,
							7118,
							-1733,
							-880,
							-1468,
							-1671,
							-5694
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17131,
					"arcs": [
						[
							-4179,
							-2490,
							-3077,
							7119,
							7120,
							7121,
							7122
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17147,
					"arcs": [
						[
							-626,
							-1722,
							-4779,
							-6824,
							-6799,
							-4772
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34021,
					"arcs": [
						[
							-7066,
							-6347,
							7123,
							-4374,
							7124,
							-6521
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31139,
					"arcs": [
						[
							7125,
							-6066,
							7126,
							-1309,
							-1619
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35017,
					"arcs": [
						[
							-3159,
							-3686,
							7127,
							-3610,
							7128
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35021,
					"arcs": [
						[
							-6126,
							7129,
							7130,
							7131,
							7132
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55065,
					"arcs": [
						[
							7133,
							7134,
							-4543,
							-5896,
							7135
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36047,
					"arcs": [
						[
							7136,
							-5974,
							7137
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51169,
					"arcs": [
						[
							7138,
							7139,
							7140,
							-4038,
							7141,
							-6603,
							7142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29141,
					"arcs": [
						[
							-2821,
							-1222,
							-6951,
							-6410,
							7143,
							-5021
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5029,
					"arcs": [
						[
							-862,
							-2327,
							7144,
							-3870,
							-5920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47045,
					"arcs": [
						[
							7145,
							7146,
							7147,
							7148,
							-1242,
							7149,
							7150
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27043,
					"arcs": [
						[
							-1538,
							-86,
							7151,
							-6822,
							-579,
							-1615
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27047,
					"arcs": [
						[
							-85,
							-801,
							-1665,
							-1488,
							-6823,
							-7152
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37125,
					"arcs": [
						[
							-5242,
							-7019,
							-3696,
							-7029,
							7152,
							-1516,
							7153,
							7154
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27053,
					"arcs": [
						[
							-3105,
							-762,
							7155,
							-3274,
							7156,
							-1013,
							-1823
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21213,
					"arcs": [
						[
							7157,
							7158,
							-5647,
							7159,
							-7040
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37153,
					"arcs": [
						[
							7160,
							-7154,
							-960,
							-4413,
							-6031
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48037,
					"arcs": [
						[
							-4356,
							-6514,
							-7104,
							-6856,
							-2044,
							7161
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47181,
					"arcs": [
						[
							-4112,
							7162,
							-5145,
							7163,
							-5154,
							-6607
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47183,
					"arcs": [
						[
							-6803,
							-4236,
							7164,
							-909,
							7165,
							7166
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55105,
					"arcs": [
						[
							-2999,
							-7091,
							-1939,
							-3088,
							-5564,
							7167
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16087,
					"arcs": [
						[
							7168,
							-6991,
							-6994,
							7169,
							7170
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24023,
					"arcs": [
						[
							7171,
							7172,
							7173,
							7174,
							7175,
							7176
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54077,
					"arcs": [
						[
							7177,
							-7177,
							7178,
							7179,
							-6755,
							7180,
							-481
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46023,
					"arcs": [
						[
							-2887,
							-3383,
							-3225,
							-2969,
							7181,
							-6011,
							7182,
							-6060,
							-3425
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37021,
					"arcs": [
						[
							-5624,
							-7008,
							-5576,
							7183,
							7184,
							-6441
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42107,
					"arcs": [
						[
							-6854,
							-4099,
							7185,
							-6881,
							-4864,
							-2532,
							7186,
							7187
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5031,
					"arcs": [
						[
							-6504,
							7188,
							-4566,
							7189,
							-54,
							7190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35041,
					"arcs": [
						[
							7191,
							-4586,
							-6446,
							-1196,
							7192,
							-5213,
							-654
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31165,
					"arcs": [
						[
							-4882,
							-1264,
							7193,
							-1343,
							-6169,
							7194
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31167,
					"arcs": [
						[
							7195,
							-99,
							7196,
							-5569,
							-1310
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37023,
					"arcs": [
						[
							7197,
							-5224,
							7198,
							-1421,
							7199,
							7200,
							-5574
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36059,
					"arcs": [
						[
							7201,
							7202,
							-5972,
							7203
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31179,
					"arcs": [
						[
							-6065,
							-4572,
							7204,
							-94,
							-7196,
							-7127
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17031,
					"arcs": [
						[
							-14,
							-6798,
							7205,
							-6794,
							-6258,
							7206,
							-2228
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17043,
					"arcs": [
						[
							-7207,
							-6257,
							-6793,
							-2229
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17045,
					"arcs": [
						[
							7207,
							7208,
							-300,
							-6814,
							-6810,
							-6825
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17055,
					"arcs": [
						[
							7209,
							-4488,
							-336,
							-2850,
							-3804,
							-6709
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17059,
					"arcs": [
						[
							-4487,
							-6325,
							-6650,
							-3960,
							7210,
							-338
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17061,
					"arcs": [
						[
							7211,
							-2214,
							-5687,
							-2527,
							-6646,
							-5844
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48365,
					"arcs": [
						[
							-6115,
							7212,
							-7049,
							7213,
							-531
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27009,
					"arcs": [
						[
							-1875,
							-3103,
							-4820,
							-5232
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21237,
					"arcs": [
						[
							7214,
							7215,
							-3937,
							7216,
							-3414,
							7217
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45067,
					"arcs": [
						[
							-6664,
							-5776,
							7218,
							7219,
							-5333
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47187,
					"arcs": [
						[
							7220,
							7221,
							7222,
							7223,
							-4865,
							7224,
							-1902
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55117,
					"arcs": [
						[
							-1120,
							-741,
							7225,
							-7036,
							-927,
							-2221
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51013,
					"arcs": [
						[
							-6180,
							7226,
							7227,
							-6181,
							-1414
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56027,
					"arcs": [
						[
							-203,
							-733,
							-4883,
							-7195,
							-6168,
							-2600,
							-4684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45071,
					"arcs": [
						[
							-5259,
							7228,
							-6667,
							7229,
							-4104,
							7230,
							7231
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24025,
					"arcs": [
						[
							-5714,
							-4621,
							-7062,
							7232,
							-7050
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46041,
					"arcs": [
						[
							-4426,
							7233,
							7234,
							-1895,
							-5601,
							7235
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47097,
					"arcs": [
						[
							-7150,
							-1246,
							-4315,
							-6912,
							7236
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46055,
					"arcs": [
						[
							-4142,
							7237,
							-5604,
							7238,
							7239,
							-6366
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39087,
					"arcs": [
						[
							-5631,
							7240,
							7241,
							7242,
							-4752,
							7243,
							-5750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29181,
					"arcs": [
						[
							-2332,
							-3343,
							-7078,
							7244,
							7245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42111,
					"arcs": [
						[
							-5322,
							-6591,
							-6575,
							7246,
							-7173,
							7247
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48167,
					"arcs": [
						[
							[
								-3435,
								7248
							]
						],
						[
							[
								7249,
								-3843
							]
						],
						[
							[
								-3441,
								7250,
								-3841
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5045,
					"arcs": [
						[
							-7145,
							-2326,
							-3485,
							7251,
							-6495,
							7252,
							-3871
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31153,
					"arcs": [
						[
							-4224,
							7253,
							-4187,
							-5958,
							7254
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21011,
					"arcs": [
						[
							7255,
							-3642,
							-4811,
							7256,
							7257
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21013,
					"arcs": [
						[
							7258,
							-6870,
							-6959,
							7259,
							7260,
							7261,
							-5746
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37057,
					"arcs": [
						[
							-378,
							-1413,
							-5244,
							7262,
							7263,
							7264
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25001,
					"arcs": [
						[
							7265,
							7266,
							7267,
							-3979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45083,
					"arcs": [
						[
							-5937,
							7268,
							7269,
							7270,
							-5257,
							7271
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56033,
					"arcs": [
						[
							-6933,
							-6473,
							-858,
							7272,
							-6979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51093,
					"arcs": [
						[
							7273,
							7274,
							7275,
							-982,
							7276,
							-2499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56037,
					"arcs": [
						[
							-6918,
							7277,
							7278,
							-3771,
							7279,
							-978,
							-438,
							-5914
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53003,
					"arcs": [
						[
							-617,
							-6406,
							7280,
							-4613
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47023,
					"arcs": [
						[
							-6928,
							-1595,
							-6606,
							7281,
							7282
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47109,
					"arcs": [
						[
							-7282,
							-6609,
							7283,
							7284
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19019,
					"arcs": [
						[
							7285,
							-2938,
							-1756,
							7286,
							-5116
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22119,
					"arcs": [
						[
							7287,
							-5267,
							-3586,
							-3966,
							7288
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47111,
					"arcs": [
						[
							-5645,
							-3165,
							-4147,
							-6223,
							-6909,
							-6689,
							7289
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37129,
					"arcs": [
						[
							7290,
							7291,
							-5618
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19023,
					"arcs": [
						[
							-2786,
							-5135,
							-5118,
							-1801,
							-2809
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46071,
					"arcs": [
						[
							-6367,
							-7240,
							7292,
							7293,
							7294,
							7295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26023,
					"arcs": [
						[
							7296,
							-6834,
							7297,
							-3952,
							7298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47139,
					"arcs": [
						[
							-4732,
							7299,
							-3623,
							-445,
							-5561,
							7300
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31007,
					"arcs": [
						[
							-1345,
							-142,
							-2682,
							-6759,
							7301,
							-6170
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51181,
					"arcs": [
						[
							-6941,
							-6874,
							7302,
							-7275,
							7303,
							-5612
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5051,
					"arcs": [
						[
							-5921,
							-3874,
							-4459,
							-206,
							7304
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29195,
					"arcs": [
						[
							7305,
							7306,
							7307,
							-1218,
							-2820,
							-6884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31029,
					"arcs": [
						[
							7308,
							-1321,
							7309,
							7310,
							7311
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26131,
					"arcs": [
						[
							7312,
							-6908,
							7313,
							-5887
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37045,
					"arcs": [
						[
							-7200,
							-1420,
							7314,
							7315,
							7316,
							7317
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31057,
					"arcs": [
						[
							-7311,
							7318,
							7319,
							-1735,
							-1734,
							-2775,
							7320
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42121,
					"arcs": [
						[
							7321,
							-1399,
							7322,
							7323,
							7324,
							-4248
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21025,
					"arcs": [
						[
							-3415,
							-7217,
							-3936,
							7325,
							-3880,
							7326
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40103,
					"arcs": [
						[
							7327,
							7328,
							7329,
							-5150,
							7330,
							-1548
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21031,
					"arcs": [
						[
							-5496,
							7331,
							-5127,
							7332,
							7333,
							-3930
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46013,
					"arcs": [
						[
							-6132,
							7334,
							-7096,
							-657,
							-5262,
							-2867,
							-3301,
							-2153
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45001,
					"arcs": [
						[
							-6359,
							-5261,
							7335,
							7336,
							7337
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47131,
					"arcs": [
						[
							7338,
							-6804,
							-7167,
							7339,
							-7148,
							7340
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51043,
					"arcs": [
						[
							7341,
							7342,
							7343,
							-2062,
							7344
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12055,
					"arcs": [
						[
							7345,
							7346,
							-743,
							-2779,
							-1993
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5127,
					"arcs": [
						[
							7347,
							7348,
							-5923,
							7349,
							7350,
							7351
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1037,
					"arcs": [
						[
							7352,
							-3868,
							7353,
							7354,
							-6725,
							-6731
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12061,
					"arcs": [
						[
							7355,
							7356,
							7357,
							7358,
							-4897
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12111,
					"arcs": [
						[
							-7358,
							7359,
							7360,
							7361
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5131,
					"arcs": [
						[
							7362,
							7363,
							7364,
							7365,
							-7348,
							7366
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37161,
					"arcs": [
						[
							-7184,
							-5575,
							-7201,
							-7318,
							7367,
							-7269,
							-5936,
							7368
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32027,
					"arcs": [
						[
							-5965,
							7369,
							7370,
							7371
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50017,
					"arcs": [
						[
							-6021,
							-5433,
							-3451,
							7372,
							-6097
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51067,
					"arcs": [
						[
							-4676,
							-3813,
							7373,
							7374,
							7375,
							-5440
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55045,
					"arcs": [
						[
							-3000,
							-7168,
							-5563,
							-4538,
							-7135,
							7376
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48491,
					"arcs": [
						[
							7377,
							7378,
							7379,
							7380,
							-1924,
							7381
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32019,
					"arcs": [
						[
							7382,
							7383,
							7384,
							7385,
							7386,
							-5875,
							-1942
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13243,
					"arcs": [
						[
							7387,
							7388,
							-1844,
							-2818,
							7389,
							-818
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13159,
					"arcs": [
						[
							7390,
							-5105,
							7391,
							-6000,
							-5476,
							-1048
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19155,
					"arcs": [
						[
							7392,
							-4493,
							-3249,
							7393,
							-4183,
							-7254,
							-4223,
							7394
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48239,
					"arcs": [
						[
							-1792,
							-4320,
							7395,
							7396,
							7397,
							7398
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16073,
					"arcs": [
						[
							-5283,
							-3011,
							-1241,
							7399,
							-5530,
							-5963,
							7400
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53035,
					"arcs": [
						[
							[
								-6986,
								7401,
								7402
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12131,
					"arcs": [
						[
							-3750,
							-3178,
							7403,
							7404,
							7405,
							7406,
							-5555
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13201,
					"arcs": [
						[
							-2142,
							-4536,
							7407,
							-3552
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13235,
					"arcs": [
						[
							-4904,
							-5096,
							7408,
							7409,
							-5039
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13275,
					"arcs": [
						[
							-3910,
							7410,
							7411,
							7412,
							7413,
							-2158
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13287,
					"arcs": [
						[
							7414,
							7415,
							-3831,
							-1955,
							7416,
							-6785
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34023,
					"arcs": [
						[
							-6346,
							-6351,
							-6283,
							7417,
							-4370,
							-7124
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31049,
					"arcs": [
						[
							-1392,
							7418,
							-1324,
							7419,
							-2683
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13297,
					"arcs": [
						[
							-1850,
							-2569,
							7420,
							-5101,
							7421,
							7422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29089,
					"arcs": [
						[
							7423,
							7424,
							7425,
							-1219,
							-7308
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34013,
					"arcs": [
						[
							7426,
							7427,
							7428,
							-6349,
							-3677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13309,
					"arcs": [
						[
							7429,
							7430,
							-4474,
							7431,
							7432,
							7433
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13311,
					"arcs": [
						[
							7434,
							7435,
							7436,
							7437,
							7438
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13125,
					"arcs": [
						[
							-3899,
							7439,
							-3498,
							-6245
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13209,
					"arcs": [
						[
							7440,
							7441,
							-7432,
							-4473
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48405,
					"arcs": [
						[
							7442,
							7443,
							-5414,
							-6363,
							-5762
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20043,
					"arcs": [
						[
							7444,
							-5866,
							7445,
							7446,
							-5739,
							-6577
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13255,
					"arcs": [
						[
							7447,
							7448,
							-4479,
							-1051,
							-5005,
							7449,
							7450,
							-868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40055,
					"arcs": [
						[
							-2679,
							7451,
							-5701,
							7452
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40049,
					"arcs": [
						[
							7453,
							-4153,
							-2076,
							-2067,
							-388,
							-2920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48449,
					"arcs": [
						[
							7454,
							-2049,
							-5411,
							-2032
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40071,
					"arcs": [
						[
							-6965,
							7455,
							7456,
							-7328,
							-1547,
							7457
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41045,
					"arcs": [
						[
							7458,
							-7170,
							-6995,
							-5284,
							-7401,
							-5962,
							7459,
							-4857
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22023,
					"arcs": [
						[
							7460,
							-4195,
							7461,
							7462,
							7463,
							-6921
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40023,
					"arcs": [
						[
							-4087,
							-2083,
							-4358,
							7464,
							-3847,
							7465
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51009,
					"arcs": [
						[
							-5469,
							7466,
							-5439,
							7467,
							-2073,
							-3810
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53061,
					"arcs": [
						[
							[
								7468,
								7469,
								-3209,
								-5859,
								7470,
								7471
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28001,
					"arcs": [
						[
							7472,
							-3988,
							7473,
							-5797,
							-5813
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28005,
					"arcs": [
						[
							7474,
							-2632,
							7475,
							7476,
							-595,
							7477,
							-5799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13175,
					"arcs": [
						[
							-3889,
							7478,
							-4475,
							-7431,
							7479,
							-5094
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13167,
					"arcs": [
						[
							-3500,
							7480,
							7481,
							-7479,
							-3888
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13133,
					"arcs": [
						[
							7482,
							7483,
							-4232,
							-6244,
							7484,
							-5103
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2016,
					"arcs": [
						[
							[
								7485
							]
						],
						[
							[
								7486
							]
						],
						[
							[
								7487
							]
						],
						[
							[
								7488
							]
						],
						[
							[
								7489
							]
						],
						[
							[
								7490
							]
						],
						[
							[
								7491
							]
						],
						[
							[
								7492
							]
						],
						[
							[
								7493
							]
						],
						[
							[
								7494
							]
						],
						[
							[
								7495
							]
						],
						[
							[
								7496
							]
						],
						[
							[
								7497
							]
						],
						[
							[
								7498
							]
						],
						[
							[
								7499
							]
						],
						[
							[
								7500
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2100,
					"arcs": [
						[
							[
								-4761,
								7501,
								-4163,
								7502,
								-1577,
								7503
							],
							[
								-1572
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5095,
					"arcs": [
						[
							-5992,
							7504,
							-3449,
							7505,
							7506,
							7507
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48467,
					"arcs": [
						[
							-2959,
							7508,
							7509,
							7510,
							-5290,
							-5605
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40087,
					"arcs": [
						[
							-4091,
							-4854,
							-4148,
							-7454,
							-2919,
							7511
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36113,
					"arcs": [
						[
							7512,
							7513,
							-4845,
							7514
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36025,
					"arcs": [
						[
							7515,
							7516,
							7517,
							7518,
							-6356,
							7519,
							7520,
							7521
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22097,
					"arcs": [
						[
							7522,
							7523,
							7524,
							-6945,
							7525,
							-4262
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40113,
					"arcs": [
						[
							7526,
							-2420,
							-3633,
							-2056,
							7527,
							-7329,
							-7457
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40063,
					"arcs": [
						[
							-773,
							7528,
							7529,
							-4304,
							-4150,
							-4093
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51031,
					"arcs": [
						[
							-3811,
							-2074,
							-7468,
							-5438,
							7530,
							7531,
							7532
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40095,
					"arcs": [
						[
							-2070,
							7533,
							7534,
							7535,
							7536
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1103,
					"arcs": [
						[
							-2699,
							7537,
							-6193,
							-6176,
							7538
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48225,
					"arcs": [
						[
							-5428,
							-6938,
							-6365,
							-4505,
							7539,
							7540,
							-6539
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40121,
					"arcs": [
						[
							-7530,
							7541,
							7542,
							7543,
							-2079,
							-4086,
							-4305
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40061,
					"arcs": [
						[
							7544,
							7545,
							7546,
							7547,
							7548,
							-7543
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28011,
					"arcs": [
						[
							7549,
							7550,
							7551,
							7552,
							-5012,
							-3365
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28021,
					"arcs": [
						[
							7553,
							-3663,
							-4287,
							-3985,
							7554
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28025,
					"arcs": [
						[
							7555,
							-5796,
							-5803,
							7556,
							-4227
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5117,
					"arcs": [
						[
							7557,
							-5993,
							-7508,
							7558,
							-6491
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5107,
					"arcs": [
						[
							-7506,
							-3448,
							7559,
							7560,
							-7551,
							7561,
							7562
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2060,
					"arcs": [
						[
							7563,
							7564
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48423,
					"arcs": [
						[
							7565,
							-4126,
							7566,
							-535,
							-6937,
							-5291,
							-7511
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22107,
					"arcs": [
						[
							7567,
							-5730,
							7568,
							-5725,
							7569,
							-7555,
							-3989,
							-7473,
							-5812,
							7570
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47103,
					"arcs": [
						[
							-4868,
							-6764,
							-5360,
							7571,
							7572,
							-2697,
							-4047
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36007,
					"arcs": [
						[
							-2039,
							7573,
							-7522,
							7574,
							-3281,
							7575
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54009,
					"arcs": [
						[
							7576,
							-6043,
							-2765,
							7577
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39013,
					"arcs": [
						[
							-2014,
							7578,
							7579,
							-2768,
							7580,
							7581,
							7582
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40079,
					"arcs": [
						[
							-7548,
							7583,
							-7367,
							-7352,
							7584,
							-4352,
							-2081,
							7585
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54053,
					"arcs": [
						[
							-5942,
							7586,
							7587,
							7588,
							7589
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37099,
					"arcs": [
						[
							-6443,
							-6391,
							-5777,
							7590,
							7591
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54063,
					"arcs": [
						[
							-7085,
							-4748,
							7592,
							7593,
							7594
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54079,
					"arcs": [
						[
							7595,
							7596,
							-5674,
							7597,
							-7588
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55137,
					"arcs": [
						[
							-1040,
							-412,
							-4337,
							7598,
							-3315,
							7599
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54081,
					"arcs": [
						[
							7600,
							7601,
							-7088,
							7602,
							7603,
							7604
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54109,
					"arcs": [
						[
							7605,
							7606,
							-7604,
							7607,
							7608,
							-3468
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34037,
					"arcs": [
						[
							-3422,
							-5543,
							7609,
							-3675,
							7610,
							-4409
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2070,
					"arcs": [
						[
							[
								7611,
								7612,
								7613
							]
						],
						[
							[
								7614
							]
						],
						[
							[
								7615,
								7616
							]
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2261,
					"arcs": [
						[
							[
								7617
							]
						],
						[
							[
								7618
							]
						],
						[
							[
								7619,
								7620,
								7621,
								-4965,
								7622,
								-1864,
								-5010
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34017,
					"arcs": [
						[
							7623,
							7624,
							7625,
							-6281,
							-6350,
							-7429
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37173,
					"arcs": [
						[
							-4632,
							-6444,
							-7592,
							7626,
							-4015,
							-6298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48313,
					"arcs": [
						[
							-6540,
							-7541,
							7627,
							7628,
							-4885
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51047,
					"arcs": [
						[
							7629,
							-2182,
							7630,
							-5423,
							-4326,
							7631
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51061,
					"arcs": [
						[
							-7344,
							7632,
							7633,
							-2183,
							-7630,
							7634,
							-2063
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54011,
					"arcs": [
						[
							7635,
							-7589,
							-7598,
							-5673,
							-4753,
							-7243
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42059,
					"arcs": [
						[
							-6048,
							7636,
							-479,
							7637,
							7638
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54051,
					"arcs": [
						[
							-7581,
							-2767,
							-6049,
							-7639,
							7639,
							7640
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1065,
					"arcs": [
						[
							-5917,
							-53,
							-2088,
							7641,
							7642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28037,
					"arcs": [
						[
							-3987,
							-2633,
							-7475,
							-5798,
							-7474
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53041,
					"arcs": [
						[
							7643,
							7644,
							-6983,
							-6925,
							-5312,
							7645,
							7646,
							7647
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28041,
					"arcs": [
						[
							-4218,
							7648,
							7649,
							-833,
							7650
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 28047,
					"arcs": [
						[
							[
								7651
							]
						],
						[
							[
								7652,
								-5525,
								7653,
								7654
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54067,
					"arcs": [
						[
							7655,
							-4680,
							-2129,
							-4745,
							7656,
							7657
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18135,
					"arcs": [
						[
							-3034,
							7658,
							-4181,
							-2972,
							7659
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56013,
					"arcs": [
						[
							-1659,
							-3266,
							7660,
							-1653,
							7661,
							-7278,
							-6917,
							7662
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36061,
					"arcs": [
						[
							-7625,
							7663,
							-4013,
							-5970,
							-7137,
							7664
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27113,
					"arcs": [
						[
							-3723,
							-5509,
							7665,
							-2301,
							-2688,
							-2299
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5103,
					"arcs": [
						[
							-3074,
							-7069,
							7666,
							7667,
							-6317,
							-5265
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40013,
					"arcs": [
						[
							-7535,
							7668,
							-4088,
							-7466,
							-3846,
							7669,
							7670
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36115,
					"arcs": [
						[
							7671,
							-6023,
							-5305,
							7672,
							-5931,
							-4846,
							-7514
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48471,
					"arcs": [
						[
							-7540,
							-4509,
							-5374,
							7673,
							7674,
							-7628
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51157,
					"arcs": [
						[
							-2064,
							-7635,
							-7632,
							-4325,
							7675
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39167,
					"arcs": [
						[
							-1879,
							7676,
							7677,
							-3855,
							7678,
							7679,
							-34
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1099,
					"arcs": [
						[
							-2425,
							7680,
							7681,
							-5164,
							-147,
							7682
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51027,
					"arcs": [
						[
							-3470,
							7683,
							7684,
							7685,
							7686,
							7687
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12099,
					"arcs": [
						[
							7688,
							7689,
							-2002,
							7690
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13033,
					"arcs": [
						[
							7691,
							-5032,
							7692,
							-5699,
							-6288,
							7693,
							-488,
							7694
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48309,
					"arcs": [
						[
							-4323,
							-1916,
							-1541,
							7695,
							-252,
							-7100
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1081,
					"arcs": [
						[
							-1713,
							-3796,
							7696,
							-4522,
							-6316,
							7697
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 6041,
					"arcs": [
						[
							[
								7698,
								7699
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51053,
					"arcs": [
						[
							7700,
							7701,
							-6586,
							-6942,
							-5610,
							7702,
							7703,
							-6630
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45029,
					"arcs": [
						[
							-6370,
							7704,
							-6220,
							-6528,
							7705,
							-6434,
							7706
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12031,
					"arcs": [
						[
							7707,
							7708,
							7709,
							7710,
							7711
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39093,
					"arcs": [
						[
							7712,
							7713,
							7714,
							7715,
							7716,
							-5628
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38099,
					"arcs": [
						[
							-2234,
							7717,
							-3725,
							7718,
							-3013,
							-4302
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12067,
					"arcs": [
						[
							7719,
							-3492,
							-4463,
							7720,
							7721
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35061,
					"arcs": [
						[
							-4841,
							-4835,
							-1129,
							-3386
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17057,
					"arcs": [
						[
							-3022,
							7722,
							-3079,
							7723,
							7724,
							-4907,
							-1190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26025,
					"arcs": [
						[
							-2458,
							-1476,
							-5488,
							-6830,
							-7297,
							-1495
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2090,
					"arcs": [
						[
							7725,
							7726,
							7727
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26027,
					"arcs": [
						[
							-6413,
							7728,
							7729,
							7730,
							-3251
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51045,
					"arcs": [
						[
							7731,
							7732,
							-4674,
							-4669,
							7733,
							-7594
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54057,
					"arcs": [
						[
							7734,
							7735,
							7736,
							-7175
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51165,
					"arcs": [
						[
							7737,
							-6189,
							7738,
							-3821,
							7739,
							7740,
							-4499
						],
						[
							-1422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13037,
					"arcs": [
						[
							-819,
							-7390,
							-2817,
							-5074,
							-2138,
							-3551
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48333,
					"arcs": [
						[
							-1907,
							-258,
							-3410,
							-1027,
							7741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16041,
					"arcs": [
						[
							7742,
							7743,
							-6542,
							7744,
							-3799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6101,
					"arcs": [
						[
							-4970,
							-6381,
							-1702,
							7745,
							-4397,
							-6415
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8095,
					"arcs": [
						[
							7746,
							-1322,
							-7309,
							7747,
							-3379
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13043,
					"arcs": [
						[
							7748,
							7749,
							-4338,
							7750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19097,
					"arcs": [
						[
							-6449,
							-5898,
							7751,
							-6261,
							-61
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28007,
					"arcs": [
						[
							-3942,
							-2101,
							7752,
							7753,
							-2863,
							7754,
							7755
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28019,
					"arcs": [
						[
							-4229,
							7756,
							7757,
							-7753,
							-2100
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 44009,
					"arcs": [
						[
							[
								7758
							]
						],
						[
							[
								-5462,
								-476,
								7759
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42007,
					"arcs": [
						[
							7760,
							7761,
							7762,
							7763,
							-6045,
							7764
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46109,
					"arcs": [
						[
							7765,
							7766,
							7767,
							-7092,
							-659,
							-7095
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46102,
					"arcs": [
						[
							-6368,
							-7296,
							7768,
							7769,
							-3189,
							-1261,
							-4881,
							-731
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42039,
					"arcs": [
						[
							7770,
							-1400,
							-7322,
							-4247,
							7771,
							-4404
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13271,
					"arcs": [
						[
							7772,
							-7434,
							7773,
							-2840,
							-3830,
							7774
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12047,
					"arcs": [
						[
							7775,
							-5558,
							-3533,
							-3489,
							7776
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13185,
					"arcs": [
						[
							7777,
							-3894,
							-3495,
							-5559,
							-7776,
							7778,
							7779
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13263,
					"arcs": [
						[
							7780,
							7781,
							-5068,
							7782,
							7783,
							7784,
							-3794
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 36103,
					"arcs": [
						[
							[
								7785
							]
						],
						[
							[
								-7202,
								7786
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1129,
					"arcs": [
						[
							-4521,
							7787,
							-145,
							7788,
							-7649,
							-4217
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54083,
					"arcs": [
						[
							-6757,
							7789,
							7790,
							-4496,
							-849,
							-2127,
							7791
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5003,
					"arcs": [
						[
							-4991,
							-3291,
							-3371,
							-3576,
							-6319
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28053,
					"arcs": [
						[
							7792,
							7793,
							7794,
							-5514,
							-5017,
							-5014
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48361,
					"arcs": [
						[
							-5417,
							-6123,
							-4196,
							-7461,
							-6920,
							7795
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13045,
					"arcs": [
						[
							-953,
							7796,
							-5100,
							-6072,
							-872,
							-2546,
							7797,
							7798
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16057,
					"arcs": [
						[
							-3541,
							-7057,
							-7105,
							7799,
							-6404
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16081,
					"arcs": [
						[
							-6705,
							7800,
							-6248,
							7801
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51081,
					"arcs": [
						[
							7802,
							-7703,
							-5614,
							7803,
							-6034
						],
						[
							-1103
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48371,
					"arcs": [
						[
							7804,
							7805,
							-6741,
							7806,
							-5852,
							7807,
							7808
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29021,
					"arcs": [
						[
							-7447,
							7809,
							-643,
							-2123,
							7810,
							-5740
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29165,
					"arcs": [
						[
							-7811,
							-2122,
							-3990,
							7811,
							7812,
							-5741
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39061,
					"arcs": [
						[
							7813,
							-2000,
							7814,
							7815,
							-4651,
							-5387,
							-3555
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30021,
					"arcs": [
						[
							7816,
							7817,
							7818,
							-6158
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30109,
					"arcs": [
						[
							-7818,
							7819,
							7820,
							-2447,
							7821,
							7822
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 15007,
					"arcs": [
						[
							[
								7823
							]
						],
						[
							[
								7824
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42033,
					"arcs": [
						[
							7825,
							-805,
							7826,
							-6753,
							-5330,
							7827,
							-6589,
							-6792
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47141,
					"arcs": [
						[
							-6224,
							-3131,
							-6936,
							-5346,
							-6683,
							-5407,
							-6910
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51185,
					"arcs": [
						[
							-7685,
							7828,
							7829,
							7830,
							-4329,
							7831
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28143,
					"arcs": [
						[
							7832,
							7833,
							-3658,
							-3053,
							-2103,
							7834,
							-7560,
							-3447
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28159,
					"arcs": [
						[
							-7758,
							7835,
							-2612,
							-2689,
							-2130,
							-7754
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29045,
					"arcs": [
						[
							-2835,
							-5385,
							7836,
							7837,
							7838,
							-5682
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48377,
					"arcs": [
						[
							7839,
							7840,
							7841
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51083,
					"arcs": [
						[
							7842,
							-7532,
							7843,
							7844,
							-6500,
							7845,
							-5184
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19149,
					"arcs": [
						[
							-5642,
							-2607,
							-6860,
							7846
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48385,
					"arcs": [
						[
							7847,
							7848,
							7849,
							-2940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13073,
					"arcs": [
						[
							-5837,
							7850,
							7851,
							-5029,
							7852
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30083,
					"arcs": [
						[
							-6157,
							-5871,
							7853,
							-7820,
							-7817
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49003,
					"arcs": [
						[
							-6385,
							-3801,
							7854,
							-5353,
							7855,
							-5533
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48135,
					"arcs": [
						[
							7856,
							-3062,
							7857,
							-6738,
							7858,
							7859
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48187,
					"arcs": [
						[
							-6232,
							-5372,
							-1095,
							7860,
							7861,
							7862
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47091,
					"arcs": [
						[
							-4041,
							7863,
							7864,
							-4395,
							7865,
							7866,
							-6371
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12019,
					"arcs": [
						[
							-7711,
							7867,
							7868,
							-2933,
							7869
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12029,
					"arcs": [
						[
							7870,
							-7721,
							-4466,
							7871,
							7872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27021,
					"arcs": [
						[
							-3272,
							-5507,
							7873,
							7874,
							7875,
							-5230,
							7876,
							-1513
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39171,
					"arcs": [
						[
							-6832,
							7877,
							-1026,
							-5756,
							7878,
							7879
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29197,
					"arcs": [
						[
							-3181,
							-1705,
							-2838,
							-6156,
							-3293
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30025,
					"arcs": [
						[
							7880,
							-7822,
							-2446,
							-4603,
							-4741,
							-6687,
							7881,
							7882
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30091,
					"arcs": [
						[
							7883,
							7884,
							-6895,
							-5869,
							7885
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54017,
					"arcs": [
						[
							-3853,
							7886,
							-2119,
							-4135,
							-5672,
							-3825
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30105,
					"arcs": [
						[
							7887,
							7888,
							-5873,
							-6160,
							-646,
							7889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51015,
					"arcs": [
						[
							7890,
							-4500,
							-7741,
							7891,
							7892,
							-5467,
							7893
						],
						[
							-1432
						],
						[
							7894
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8115,
					"arcs": [
						[
							-2684,
							-7420,
							-1323,
							-7747,
							-3378
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13081,
					"arcs": [
						[
							-5041,
							7895,
							-7415,
							-6784,
							-2593,
							7896
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 12101,
					"arcs": [
						[
							[
								7897,
								7898,
								7899,
								7900,
								7901,
								7902
							]
						],
						[
							[
								7903,
								7904
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39141,
					"arcs": [
						[
							7905,
							-1884,
							7906,
							-2695,
							7907,
							-1886,
							7908
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8039,
					"arcs": [
						[
							-2379,
							7909,
							7910,
							7911
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13015,
					"arcs": [
						[
							-5669,
							-5047,
							-5681,
							7912,
							7913,
							-6711
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21227,
					"arcs": [
						[
							-5126,
							-4705,
							-5644,
							-7159,
							7914,
							-7333
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48403,
					"arcs": [
						[
							7915,
							-3964,
							-6121,
							-5415,
							-7444
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39147,
					"arcs": [
						[
							-6733,
							-3654,
							7916,
							7917,
							-2007,
							-2017
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48427,
					"arcs": [
						[
							-4516,
							7918,
							-3114,
							7919,
							7920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49013,
					"arcs": [
						[
							-980,
							7921,
							7922,
							7923,
							7924,
							-787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49015,
					"arcs": [
						[
							7925,
							7926,
							7927,
							-5420,
							-4941
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27071,
					"arcs": [
						[
							7928,
							-2763,
							7929,
							-5505,
							-4665
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30087,
					"arcs": [
						[
							-650,
							7930,
							-6475,
							-6932,
							7931,
							7932,
							7933,
							7934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28027,
					"arcs": [
						[
							-7835,
							-2106,
							-4050,
							7935,
							-7552,
							-7561
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38005,
					"arcs": [
						[
							7936,
							-4303,
							-3016,
							7937,
							-2579,
							-3143
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38027,
					"arcs": [
						[
							-7938,
							-3015,
							-1366,
							-1314,
							-2580
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54107,
					"arcs": [
						[
							-35,
							-7680,
							7938,
							-3822,
							7939,
							7940,
							-5940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54031,
					"arcs": [
						[
							7941,
							7942,
							-6190,
							-7738,
							-4498,
							7943
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54091,
					"arcs": [
						[
							7944,
							-482,
							-7181,
							-6754,
							-2115
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39051,
					"arcs": [
						[
							-6831,
							-548,
							-6137,
							-1022,
							-7878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47011,
					"arcs": [
						[
							7945,
							-4733,
							-7301,
							-5560,
							7946,
							-7115
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 8123,
					"arcs": [
						[
							[
								7947
							]
						],
						[
							[
								7948,
								-6760,
								-3381,
								-2649,
								7949,
								7950,
								7951,
								-6455
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40057,
					"arcs": [
						[
							-2680,
							-7453,
							-5700,
							-6117,
							-3167,
							-1449
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13279,
					"arcs": [
						[
							-7441,
							7952,
							-4343,
							-6075,
							7953
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40085,
					"arcs": [
						[
							-2071,
							-7537,
							7954,
							-4429,
							7955,
							7956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26151,
					"arcs": [
						[
							7957,
							7958,
							7959,
							7960,
							7961
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49019,
					"arcs": [
						[
							7962,
							7963,
							-571,
							-3769,
							-1110,
							-7928
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48057,
					"arcs": [
						[
							[
								-6430,
								7964
							]
						],
						[
							[
								-7398,
								7965,
								7966,
								-6426,
								7967,
								-6198,
								7968
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49045,
					"arcs": [
						[
							-5534,
							-7856,
							-7025,
							-7083,
							7969,
							7970,
							7971
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49047,
					"arcs": [
						[
							-7923,
							7972,
							-3775,
							7973,
							-565,
							-7964,
							7974
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47115,
					"arcs": [
						[
							-6866,
							7975,
							-7112,
							-5278,
							-4155,
							7976
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40149,
					"arcs": [
						[
							-1683,
							-3745,
							7977,
							-2677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20159,
					"arcs": [
						[
							-2244,
							-6466,
							-4915,
							-1977,
							-4796
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40131,
					"arcs": [
						[
							-3632,
							-4744,
							-4023,
							7978,
							-5597,
							-2058
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20209,
					"arcs": [
						[
							-7812,
							-3992,
							-2734,
							-3566,
							7979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22093,
					"arcs": [
						[
							7980,
							7981,
							7982,
							7983
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22109,
					"arcs": [
						[
							[
								7984
							]
						],
						[
							[
								7985
							]
						],
						[
							[
								-6581,
								7986,
								7987,
								7988
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41027,
					"arcs": [
						[
							-5315,
							-6924,
							-2823,
							7989,
							7990
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54027,
					"arcs": [
						[
							7991,
							-5908,
							7992,
							-7942,
							-7736
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47123,
					"arcs": [
						[
							7993,
							-6299,
							-4017,
							-3618,
							-7300,
							-4731
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12079,
					"arcs": [
						[
							7994,
							-7779,
							-7777,
							-3488,
							-7720,
							7995,
							7996
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48321,
					"arcs": [
						[
							-7966,
							-7397,
							7997,
							-3845,
							7998
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25015,
					"arcs": [
						[
							-4659,
							-6482,
							7999,
							8000
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17071,
					"arcs": [
						[
							8001,
							-7121,
							8002,
							-3020,
							8003,
							8004
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23011,
					"arcs": [
						[
							-5979,
							-5446,
							-3593,
							-6550,
							8005,
							-1205
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13005,
					"arcs": [
						[
							8006,
							-6077,
							8007,
							-3544,
							-2842
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51127,
					"arcs": [
						[
							8008,
							-4951,
							-4438,
							-6872,
							-6843,
							8009
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12109,
					"arcs": [
						[
							-7710,
							8010,
							-2134,
							8011,
							-7868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13003,
					"arcs": [
						[
							-2844,
							-3549,
							-1930,
							-3493,
							-3893
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53055,
					"arcs": [
						[
							[
								8012
							]
						],
						[
							[
								8013
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13103,
					"arcs": [
						[
							8014,
							8015,
							8016,
							-6533,
							8017,
							8018
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17195,
					"arcs": [
						[
							-6263,
							8019,
							-5113,
							-5484,
							8020,
							-2487,
							-4178
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39153,
					"arcs": [
						[
							8021,
							8022,
							8023,
							8024,
							8025
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13127,
					"arcs": [
						[
							-3506,
							8026,
							8027,
							8028,
							8029
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12119,
					"arcs": [
						[
							-6628,
							8030,
							8031,
							-7899,
							8032,
							-4959
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39155,
					"arcs": [
						[
							-3740,
							-4405,
							-7772,
							-4251,
							-2477,
							8033
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51005,
					"arcs": [
						[
							8034,
							-5465,
							8035,
							-7732,
							-7593,
							-4747
						],
						[
							-1940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51071,
					"arcs": [
						[
							8036,
							-7086,
							-7595,
							-7734,
							-4668,
							-4944,
							8037
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51017,
					"arcs": [
						[
							-852,
							8038,
							-7894,
							-5466,
							-8035,
							-4746
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1117,
					"arcs": [
						[
							-6174,
							8039,
							-3869,
							-7353,
							-6730,
							-50
						]
					]
				},
				{
					"type": "Polygon",
					"id": 10001,
					"arcs": [
						[
							8040,
							8041,
							8042,
							8043,
							8044,
							8045
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18163,
					"arcs": [
						[
							8046,
							-6463,
							8047,
							-6648
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39071,
					"arcs": [
						[
							-2021,
							8048,
							-7909,
							-1890,
							8049,
							8050
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27075,
					"arcs": [
						[
							8051,
							-3134,
							8052,
							-2757
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53057,
					"arcs": [
						[
							[
								8053
							]
						],
						[
							[
								-4919,
								8054,
								-3204,
								-7470,
								8055
							]
						],
						[
							[
								8056
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53065,
					"arcs": [
						[
							8057,
							-3040,
							8058,
							8059,
							-6105
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53067,
					"arcs": [
						[
							8060,
							8061,
							-6984,
							-7645,
							8062
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13137,
					"arcs": [
						[
							8063,
							-5784,
							8064,
							-747,
							8065,
							-7437,
							8066
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13187,
					"arcs": [
						[
							-448,
							8067,
							-7439,
							8068,
							8069
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17015,
					"arcs": [
						[
							-5897,
							-4541,
							-5114,
							-8020,
							-6262,
							-7752
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13147,
					"arcs": [
						[
							-5841,
							-5781,
							-6361,
							8070,
							8071
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48453,
					"arcs": [
						[
							8072,
							-7382,
							-1923,
							-1091,
							-5371,
							-2586
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28031,
					"arcs": [
						[
							-2574,
							-421,
							8073,
							-3332,
							-887,
							8074
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28045,
					"arcs": [
						[
							-4214,
							-7655,
							8075,
							8076
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28123,
					"arcs": [
						[
							-2865,
							-3354,
							-418,
							8077,
							8078
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53015,
					"arcs": [
						[
							-7646,
							-5311,
							-6101,
							8079,
							8080
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1079,
					"arcs": [
						[
							-6615,
							-5156,
							-2700,
							-7539,
							-6175,
							-2859,
							-3331
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8091,
					"arcs": [
						[
							-6304,
							-6308,
							-5001,
							-4532,
							8081
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8107,
					"arcs": [
						[
							8082,
							8083,
							-6239,
							-5994,
							-567,
							8084,
							-3773
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16023,
					"arcs": [
						[
							-1148,
							8085,
							-1259,
							8086,
							8087,
							-3337
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39005,
					"arcs": [
						[
							-7716,
							8088,
							8089,
							8090,
							-1374,
							-5246,
							8091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29147,
					"arcs": [
						[
							-5381,
							-2266,
							-3600,
							8092,
							8093,
							-5864,
							8094
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40111,
					"arcs": [
						[
							-1675,
							-2060,
							-5600,
							8095,
							8096,
							-771
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39067,
					"arcs": [
						[
							-5236,
							-3700,
							8097,
							-7579,
							-2013
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13047,
					"arcs": [
						[
							-7117,
							8098,
							-5657
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13313,
					"arcs": [
						[
							-8099,
							-7116,
							-7947,
							-5562,
							-5044,
							-5658
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53017,
					"arcs": [
						[
							8099,
							-5296,
							-5471,
							-3206
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54003,
					"arcs": [
						[
							-5906,
							-6255,
							8100,
							8101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28067,
					"arcs": [
						[
							-420,
							-2150,
							-4220,
							8102,
							-3333,
							-8074
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50015,
					"arcs": [
						[
							-330,
							-3453,
							-5432,
							-6208,
							-6336
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48457,
					"arcs": [
						[
							-6364,
							-5419,
							8103,
							-5578
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39151,
					"arcs": [
						[
							8104,
							-8024,
							8105,
							-2481,
							8106,
							-3697,
							-5235,
							8107
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13179,
					"arcs": [
						[
							8108,
							-6536,
							8109,
							8110,
							8111,
							-4340
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13183,
					"arcs": [
						[
							-8112,
							8112,
							-3504,
							-4341
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48469,
					"arcs": [
						[
							-2788,
							-1793,
							-7399,
							-7969,
							-6197,
							8113
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1115,
					"arcs": [
						[
							-3862,
							-4938,
							-3864,
							-8040,
							-6173,
							-6194
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5035,
					"arcs": [
						[
							-56,
							8114,
							-6915,
							-6901,
							8115,
							-7833,
							-3446,
							8116,
							-1860
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41007,
					"arcs": [
						[
							8117,
							8118,
							-6746,
							8119
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12059,
					"arcs": [
						[
							-3177,
							-3791,
							8120,
							-7404
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12133,
					"arcs": [
						[
							-8121,
							-3790,
							8121,
							-7405
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39081,
					"arcs": [
						[
							8122,
							8123,
							-7578,
							-2764,
							-7580,
							-8098,
							-3699
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37003,
					"arcs": [
						[
							-7090,
							-6556,
							8124,
							-5222
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37117,
					"arcs": [
						[
							-4080,
							-4383,
							-6675,
							-4384,
							8125,
							-3691
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28147,
					"arcs": [
						[
							-2630,
							-3230,
							-3300,
							8126,
							8127
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13291,
					"arcs": [
						[
							-3622,
							8128,
							8129,
							-7435,
							-8068,
							-447
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39001,
					"arcs": [
						[
							8130,
							-8050,
							-1889,
							-5634,
							-5904,
							8131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48279,
					"arcs": [
						[
							-41,
							-2439,
							8132,
							8133,
							-6445
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31155,
					"arcs": [
						[
							8134,
							-4225,
							-7255,
							-5957,
							-2615,
							-2772
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54023,
					"arcs": [
						[
							-7176,
							-7737,
							-7944,
							-4497,
							-7791,
							8135,
							-7179
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54093,
					"arcs": [
						[
							-7180,
							-8136,
							-7790,
							-6756
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51023,
					"arcs": [
						[
							-7733,
							-8036,
							-5470,
							-3808,
							-4675
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31107,
					"arcs": [
						[
							-7183,
							-6010,
							-6439,
							-6067,
							-7126,
							-1618,
							-5176,
							-6061
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50013,
					"arcs": [
						[
							8136,
							-6337,
							-6206,
							8137
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31173,
					"arcs": [
						[
							-4571,
							-6498,
							-6862,
							8138,
							8139,
							-95,
							-7205
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13191,
					"arcs": [
						[
							-8113,
							-8111,
							8140,
							-8027,
							-3505
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13195,
					"arcs": [
						[
							-5842,
							-8072,
							8141,
							8142,
							-2985,
							8143,
							-750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13199,
					"arcs": [
						[
							-869,
							-7451,
							8144,
							8145,
							-7781,
							-3793,
							8146
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48481,
					"arcs": [
						[
							-1568,
							8147,
							-3839,
							-7998,
							-7396,
							-4319
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13219,
					"arcs": [
						[
							-2568,
							-2987,
							8148,
							-7483,
							-5102,
							-7421
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13221,
					"arcs": [
						[
							-2986,
							-8143,
							8149,
							-3906,
							-4230,
							-7484,
							-8149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39017,
					"arcs": [
						[
							-167,
							-2814,
							-2953,
							-2001,
							-7814,
							-2619
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13239,
					"arcs": [
						[
							8150,
							-7388,
							-817,
							8151
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39031,
					"arcs": [
						[
							8152,
							-5237,
							-2011,
							-1741,
							8153,
							-1376
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12065,
					"arcs": [
						[
							8154,
							-7413,
							8155,
							-7997,
							8156,
							8157,
							-4168
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12075,
					"arcs": [
						[
							-7872,
							-4465,
							-6723,
							-6623,
							-4962,
							8158
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41009,
					"arcs": [
						[
							8159,
							-8080,
							-6103,
							8160,
							8161,
							-8119
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12107,
					"arcs": [
						[
							-2934,
							-7869,
							-8012,
							-2137,
							8162,
							-6625,
							-6722
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12113,
					"arcs": [
						[
							-5167,
							-5557,
							8163,
							-3008
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23023,
					"arcs": [
						[
							-8006,
							-6552,
							8164,
							-5819,
							-1206
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47151,
					"arcs": [
						[
							8165,
							8166,
							-6771,
							8167,
							-6676,
							-6935,
							-973
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21221,
					"arcs": [
						[
							-3526,
							-2746,
							-6779,
							-5187,
							-1562,
							-934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23031,
					"arcs": [
						[
							-4200,
							-5821,
							8168,
							8169,
							-4580,
							8170
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47021,
					"arcs": [
						[
							-7043,
							8171,
							-7222,
							8172,
							-2560
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18109,
					"arcs": [
						[
							8173,
							8174,
							8175,
							-216,
							8176,
							-5137,
							8177
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29019,
					"arcs": [
						[
							8178,
							-1628,
							8179,
							-1214,
							-6950,
							-1220,
							-7426
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18159,
					"arcs": [
						[
							-502,
							8180,
							-424,
							-4783,
							8181
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19057,
					"arcs": [
						[
							-2250,
							8182,
							-7122,
							-8002,
							8183
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33003,
					"arcs": [
						[
							-2556,
							-4201,
							-8171,
							-4579,
							-1305,
							8184
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19115,
					"arcs": [
						[
							-6693,
							8185,
							-4180,
							-7123,
							-8183,
							-2249,
							-6902
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6073,
					"arcs": [
						[
							8186,
							8187,
							8188,
							8189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37043,
					"arcs": [
						[
							8190,
							8191,
							8192,
							-8129,
							-3621
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5033,
					"arcs": [
						[
							8193,
							8194,
							8195,
							-7364,
							8196,
							8197
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42127,
					"arcs": [
						[
							-3282,
							-7575,
							-7521,
							8198,
							-3424,
							-4408,
							-6877
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36077,
					"arcs": [
						[
							-4367,
							8199,
							8200,
							8201,
							8202,
							-7517,
							8203
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32017,
					"arcs": [
						[
							8204,
							8205,
							-4437,
							-1606,
							8206,
							-3002,
							8207,
							8208
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20103,
					"arcs": [
						[
							-5742,
							-7813,
							-7980,
							-3565,
							-4799,
							8209
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51073,
					"arcs": [
						[
							8210,
							8211,
							8212,
							8213,
							-4440,
							-4950
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37123,
					"arcs": [
						[
							-7263,
							-5243,
							-7155,
							-7161,
							8214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5143,
					"arcs": [
						[
							8215,
							8216,
							-8194,
							8217
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47073,
					"arcs": [
						[
							-6604,
							-7142,
							-4044,
							8218,
							8219,
							-6596,
							-6229
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47179,
					"arcs": [
						[
							-4043,
							-6374,
							8220,
							8221,
							-8219
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30061,
					"arcs": [
						[
							-720,
							-4000,
							-7106,
							-7055
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51007,
					"arcs": [
						[
							-1638,
							-2212,
							8222,
							-7701,
							-6629,
							8223
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16065,
					"arcs": [
						[
							-6706,
							-7802,
							-6247,
							-1256
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8103,
					"arcs": [
						[
							-3774,
							-8085,
							-566,
							-7974
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37189,
					"arcs": [
						[
							-4394,
							-7089,
							-5220,
							8224,
							-7866
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45055,
					"arcs": [
						[
							-6110,
							-6601,
							-6658,
							8225,
							8226,
							8227,
							-6665
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41005,
					"arcs": [
						[
							8228,
							8229,
							-7990,
							-2828,
							-3171,
							-4308
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20161,
					"arcs": [
						[
							8230,
							8231,
							8232,
							8233,
							8234,
							-3916
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48419,
					"arcs": [
						[
							-7214,
							-7048,
							-3965,
							-7916,
							-7443,
							-5761,
							-532
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37049,
					"arcs": [
						[
							-4073,
							8235,
							-4389,
							8236,
							8237,
							8238,
							8239
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45043,
					"arcs": [
						[
							-7219,
							-5775,
							8240,
							-6526,
							8241,
							8242
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48487,
					"arcs": [
						[
							-6118,
							-5704,
							8243,
							-5788,
							-776,
							8244
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51069,
					"arcs": [
						[
							-7993,
							-5907,
							-8102,
							-7345,
							-2061,
							-6187,
							-7943
						],
						[
							-1678
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18081,
					"arcs": [
						[
							8245,
							-4491,
							8246,
							-217,
							-8176
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12089,
					"arcs": [
						[
							8247,
							8248,
							-7708,
							8249,
							8250
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17119,
					"arcs": [
						[
							-2525,
							-878,
							8251,
							-2638,
							8252,
							8253,
							-4550,
							8254,
							-6644
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19071,
					"arcs": [
						[
							-5959,
							-4185,
							-5383,
							8255,
							8256
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21053,
					"arcs": [
						[
							8257,
							-1101,
							8258,
							-971,
							-4145
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48219,
					"arcs": [
						[
							-8134,
							-2468,
							-3018,
							-1198
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48437,
					"arcs": [
						[
							-3257,
							-563,
							-7012,
							8259,
							8260,
							-2437
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13063,
					"arcs": [
						[
							8261,
							-4480,
							-7449,
							8262,
							-6070
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39049,
					"arcs": [
						[
							-900,
							-6216,
							8263,
							8264,
							-1881,
							8265
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48027,
					"arcs": [
						[
							-253,
							-7696,
							-1545,
							8266,
							-7379,
							8267,
							-1029
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13229,
					"arcs": [
						[
							-8008,
							-6076,
							-3508,
							8268,
							-3545
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38081,
					"arcs": [
						[
							8269,
							8270,
							-7094,
							-7335,
							-6131
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13231,
					"arcs": [
						[
							-7450,
							-5008,
							8271,
							-8145
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39029,
					"arcs": [
						[
							-2480,
							8272,
							-7761,
							8273,
							-8123,
							-3698,
							-8107
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6045,
					"arcs": [
						[
							-5771,
							8274,
							8275,
							-6489,
							8276,
							8277,
							8278
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16011,
					"arcs": [
						[
							8279,
							-8087,
							-1258,
							-6251,
							8280,
							8281,
							8282
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31021,
					"arcs": [
						[
							-8140,
							8283,
							8284,
							8285,
							8286,
							-96
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55001,
					"arcs": [
						[
							8287,
							-1041,
							-7600,
							-3318,
							8288,
							8289
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6063,
					"arcs": [
						[
							-2280,
							8290,
							-1686,
							-6380,
							-4968,
							8291
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18093,
					"arcs": [
						[
							-4788,
							8292,
							-5691,
							-6150,
							-3197,
							-754
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29039,
					"arcs": [
						[
							-713,
							8293,
							-4345,
							-892,
							-4710
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37059,
					"arcs": [
						[
							-4298,
							-379,
							-7265,
							8294,
							-6558
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40017,
					"arcs": [
						[
							-3703,
							-284,
							-4092,
							-7512,
							-2918,
							-3743,
							-905
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42041,
					"arcs": [
						[
							-7077,
							8295,
							-5712,
							8296,
							-6546
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21095,
					"arcs": [
						[
							-6958,
							-3883,
							-6963,
							8297,
							8298,
							-7260
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22113,
					"arcs": [
						[
							8299,
							-6948,
							8300,
							8301,
							-7463,
							8302
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26083,
					"arcs": [
						[
							[
								-6904,
								8303
							]
						],
						[
							[
								8304
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35029,
					"arcs": [
						[
							-3685,
							-4378,
							8305,
							-3611,
							-7128
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36105,
					"arcs": [
						[
							-7520,
							-6355,
							-5544,
							-3420,
							-8199
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48185,
					"arcs": [
						[
							-7629,
							-7675,
							8306,
							8307,
							8308,
							-4886
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30019,
					"arcs": [
						[
							8309,
							-7886,
							-5868,
							-7889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12005,
					"arcs": [
						[
							-8122,
							-3789,
							8310,
							8311,
							8312,
							-7406
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48307,
					"arcs": [
						[
							8313,
							8314,
							-1909,
							8315,
							-1073,
							-3188
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26087,
					"arcs": [
						[
							-7962,
							8316,
							-4203,
							8317,
							8318,
							8319
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20117,
					"arcs": [
						[
							-6058,
							-3028,
							-23,
							8320,
							-8232,
							8321
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38079,
					"arcs": [
						[
							8322,
							8323,
							-3141,
							8324
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30011,
					"arcs": [
						[
							8325,
							-7882,
							-6686,
							-6592,
							-4456,
							-6472
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20157,
					"arcs": [
						[
							-5141,
							8326,
							8327,
							-6086,
							8328
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 55003,
					"arcs": [
						[
							[
								8329
							]
						],
						[
							[
								8330
							]
						],
						[
							[
								8331
							]
						],
						[
							[
								8332
							]
						],
						[
							[
								8333,
								8334,
								-674,
								8335,
								8336
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20201,
					"arcs": [
						[
							-5149,
							-6059,
							-8322,
							-8231,
							-3915,
							-6087,
							-8328
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22007,
					"arcs": [
						[
							8337,
							8338,
							-7984,
							8339,
							-7987,
							-6580,
							8340,
							8341
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55031,
					"arcs": [
						[
							-6050,
							-2759,
							8342,
							8343,
							-2589,
							-6165,
							-5892
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45089,
					"arcs": [
						[
							-6611,
							-5334,
							-7220,
							-8243,
							8344
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47037,
					"arcs": [
						[
							-7042,
							8345,
							8346,
							8347,
							-7223,
							-8172
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13277,
					"arcs": [
						[
							-7417,
							-1959,
							-3892,
							8348,
							8349,
							-6786
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18095,
					"arcs": [
						[
							-8181,
							-501,
							8350,
							-2970,
							-1052,
							-425
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42051,
					"arcs": [
						[
							-6047,
							-5323,
							-7248,
							-7172,
							-7178,
							-480,
							-7637
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48337,
					"arcs": [
						[
							-6204,
							8351,
							-7956,
							-4432,
							-6735,
							8352
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36117,
					"arcs": [
						[
							8353,
							8354,
							8355,
							-4294,
							-5878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37089,
					"arcs": [
						[
							-7185,
							-7369,
							-5939,
							8356,
							-6388,
							-6442
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40151,
					"arcs": [
						[
							-591,
							-708,
							-435,
							-2462,
							-3082,
							-6680
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45053,
					"arcs": [
						[
							8357,
							-6436,
							8358,
							8359,
							-8016
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48003,
					"arcs": [
						[
							-1921,
							-2347,
							-3060,
							-7857,
							8360,
							8361
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45045,
					"arcs": [
						[
							-8357,
							-5938,
							-7272,
							-5256,
							-6358,
							8362,
							-6389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37029,
					"arcs": [
						[
							8363,
							8364,
							8365,
							-4597,
							8366,
							-986
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51710,
					"arcs": [
						[
							8367,
							-3193,
							8368,
							8369
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5007,
					"arcs": [
						[
							-6886,
							-1633,
							8370,
							8371,
							-8216,
							8372,
							8373
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22025,
					"arcs": [
						[
							8374,
							8375,
							8376,
							-7571,
							-5818,
							8377
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29037,
					"arcs": [
						[
							-3567,
							-2738,
							-2352,
							-2359,
							8378,
							-6450
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6097,
					"arcs": [
						[
							-8278,
							8379,
							8380,
							-5953,
							8381,
							-7699,
							8382
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55043,
					"arcs": [
						[
							8383,
							8384,
							8385,
							-7136,
							-5895,
							-6448,
							8386
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5093,
					"arcs": [
						[
							-4565,
							8387,
							-7151,
							-7237,
							-6913,
							-6897,
							-6914,
							-8115,
							-55,
							-7190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16033,
					"arcs": [
						[
							-1147,
							-185,
							-6702,
							-1254,
							-8086
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25013,
					"arcs": [
						[
							-8000,
							-6481,
							8388,
							-6654,
							-5832,
							8389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51051,
					"arcs": [
						[
							8390,
							-7687,
							8391,
							8392
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56007,
					"arcs": [
						[
							-7662,
							-1652,
							-4686,
							-4738,
							8393,
							-8083,
							-3772,
							-7279
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1063,
					"arcs": [
						[
							-2168,
							-5918,
							-7643,
							8394,
							-5580
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21197,
					"arcs": [
						[
							8395,
							8396,
							-7218,
							-3413,
							-6084,
							8397
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12013,
					"arcs": [
						[
							-3788,
							-4467,
							8398,
							-8311
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13107,
					"arcs": [
						[
							8399,
							-7695,
							-487,
							8400,
							-7751,
							-7953,
							-4472,
							-7482
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12085,
					"arcs": [
						[
							-7361,
							8401,
							-7689,
							8402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35049,
					"arcs": [
						[
							8403,
							-4064,
							8404,
							-4590,
							8405,
							8406,
							-4831,
							-4840,
							-4068
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29111,
					"arcs": [
						[
							-7839,
							8407,
							-5288,
							8408,
							-4271,
							-5683
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6025,
					"arcs": [
						[
							8409,
							-157,
							8410,
							8411,
							-8189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22035,
					"arcs": [
						[
							8412,
							-3368,
							8413,
							8414,
							-5728,
							8415
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22037,
					"arcs": [
						[
							-5800,
							-7478,
							-594,
							8416,
							8417,
							8418
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19005,
					"arcs": [
						[
							8419,
							8420,
							8421,
							8422,
							-2504
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19043,
					"arcs": [
						[
							-8423,
							8423,
							-8387,
							-6447,
							-2936,
							8424
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27169,
					"arcs": [
						[
							-4350,
							8425,
							8426,
							-4925,
							8427,
							-7020,
							-1819
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29005,
					"arcs": [
						[
							-8256,
							-5382,
							-8095,
							-5863,
							-4002,
							8428
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36019,
					"arcs": [
						[
							8429,
							-8138,
							-6210,
							8430,
							8431
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42015,
					"arcs": [
						[
							8432,
							-3285,
							-3397,
							-1371,
							8433,
							-1381,
							8434
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13161,
					"arcs": [
						[
							-7774,
							-7433,
							-7442,
							-7954,
							-6078,
							-8007,
							-2841
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17143,
					"arcs": [
						[
							-3078,
							-1774,
							-2187,
							8435,
							8436,
							-7724
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17167,
					"arcs": [
						[
							8437,
							-2662,
							-4776,
							8438,
							-873,
							-2524,
							-5686,
							-6484
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22021,
					"arcs": [
						[
							8439,
							8440,
							8441,
							8442,
							-8376,
							8443,
							-5060
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29223,
					"arcs": [
						[
							-3321,
							-4824,
							8444,
							8445,
							-3339,
							-2330,
							8446
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29041,
					"arcs": [
						[
							-1839,
							-1289,
							8447,
							-7424,
							-7307,
							8448,
							-5481
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29017,
					"arcs": [
						[
							-4823,
							8449,
							8450,
							8451,
							-8445
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30043,
					"arcs": [
						[
							8452,
							-366,
							-4276,
							-6975,
							-3606,
							-5405,
							-5402
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 10003,
					"arcs": [
						[
							[
								-6276,
								8453
							]
						],
						[
							[
								8454,
								-6661,
								8455,
								-6273,
								8456,
								-8041,
								8457,
								-7059
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17149,
					"arcs": [
						[
							-5286,
							-5109,
							-5688,
							-2215,
							-7212,
							-5843,
							-6054,
							-6974,
							8458
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21127,
					"arcs": [
						[
							-5489,
							-5057,
							8459,
							-4756,
							-7026,
							-4697,
							8460
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51175,
					"arcs": [
						[
							-5613,
							-7304,
							-7274,
							-2500,
							-7277,
							-988,
							8461,
							8462,
							-6035,
							-7804
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29013,
					"arcs": [
						[
							-6451,
							-8379,
							-2363,
							8463,
							-711,
							-1201
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22047,
					"arcs": [
						[
							8464,
							8465,
							8466,
							8467,
							-8338,
							8468,
							8469
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37073,
					"arcs": [
						[
							-8462,
							-987,
							-8367,
							-4600,
							8470,
							8471,
							8472
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55121,
					"arcs": [
						[
							-272,
							-2833,
							-4921,
							-8427,
							8473
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48389,
					"arcs": [
						[
							-613,
							8474,
							8475,
							-7805,
							8476,
							-1459
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5119,
					"arcs": [
						[
							-7253,
							-6494,
							8477,
							-1384,
							-4458,
							-3872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8085,
					"arcs": [
						[
							-3768,
							-6724,
							-6300,
							-8082,
							-4531,
							-1111
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13237,
					"arcs": [
						[
							-5104,
							-7485,
							-6246,
							-6243,
							-6001,
							-7392
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28131,
					"arcs": [
						[
							-3335,
							8478,
							-836,
							-5526,
							-7653,
							-4213
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30079,
					"arcs": [
						[
							-6159,
							-7819,
							-7823,
							-7881,
							8479,
							-648
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51125,
					"arcs": [
						[
							-7893,
							8480,
							-2805,
							-5435,
							-7467,
							-5468
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47121,
					"arcs": [
						[
							-1175,
							-3719,
							-4734,
							-7946,
							-7114
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40091,
					"arcs": [
						[
							-772,
							-8097,
							8481,
							-7545,
							-7542,
							-7529
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49039,
					"arcs": [
						[
							8482,
							8483,
							-7926,
							-4940,
							8484,
							8485
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51037,
					"arcs": [
						[
							-5437,
							8486,
							8487,
							8488,
							-7844,
							-7531
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56019,
					"arcs": [
						[
							-7273,
							-857,
							-4683,
							-1650,
							8489,
							-6980
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55111,
					"arcs": [
						[
							8490,
							8491,
							-2996,
							8492,
							8493,
							8494
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27001,
					"arcs": [
						[
							-7875,
							8495,
							-2761,
							-6051,
							-5890,
							-2795,
							-1871,
							8496
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25009,
					"arcs": [
						[
							-6851,
							8497,
							8498,
							-6294,
							8499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38067,
					"arcs": [
						[
							8500,
							-6745,
							-3726,
							-7718,
							-2233
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42029,
					"arcs": [
						[
							-4863,
							8501,
							-6662,
							-8455,
							-7058,
							-4619
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51195,
					"arcs": [
						[
							-6962,
							8502,
							-8393,
							8503,
							-7139,
							8504,
							-8298
						],
						[
							-2328
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36075,
					"arcs": [
						[
							8505,
							-2321,
							8506,
							-4369,
							-3687,
							8507,
							8508
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22051,
					"arcs": [
						[
							8509,
							8510,
							8511,
							-5724,
							8512,
							8513,
							-3939
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36079,
					"arcs": [
						[
							-5541,
							8514,
							8515,
							8516,
							-188
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32015,
					"arcs": [
						[
							-7370,
							-5964,
							-5537,
							-7003,
							8517,
							8518
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45015,
					"arcs": [
						[
							-6612,
							-8345,
							-8242,
							-6525,
							-6218,
							8519
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51760,
					"arcs": [
						[
							-6846,
							8520
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51101,
					"arcs": [
						[
							8521,
							-4952,
							-8009,
							8522
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42053,
					"arcs": [
						[
							-1398,
							-807,
							8523,
							8524,
							-7323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56043,
					"arcs": [
						[
							-6981,
							-8490,
							-1649,
							-7661,
							-3265,
							-1657
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8023,
					"arcs": [
						[
							-4462,
							8525,
							-5830,
							-6124,
							8526,
							-3777
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47051,
					"arcs": [
						[
							-5343,
							-6867,
							-7977,
							-4154,
							8527,
							-7572,
							-5359
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6033,
					"arcs": [
						[
							-6488,
							-6416,
							-4401,
							8528,
							-8380,
							-8277
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48215,
					"arcs": [
						[
							-3113,
							-6019,
							8529,
							8530,
							8531,
							-7920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48229,
					"arcs": [
						[
							8532,
							-1461,
							8533,
							8534,
							8535
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1013,
					"arcs": [
						[
							8536,
							-3481,
							-3749,
							8537,
							-7681,
							-2424
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5025,
					"arcs": [
						[
							-1386,
							8538,
							-6510,
							-3287,
							-4990,
							8539,
							8540
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20169,
					"arcs": [
						[
							-2296,
							-5083,
							-6464,
							-2242,
							-2872
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22121,
					"arcs": [
						[
							8541,
							-8418,
							8542,
							-8466,
							8543
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28089,
					"arcs": [
						[
							-5516,
							-7755,
							-2862,
							-8079,
							8544,
							-3660
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37143,
					"arcs": [
						[
							-8471,
							-4599,
							8545,
							8546
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51650,
					"arcs": [
						[
							8547,
							8548,
							8549,
							-197
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9001,
					"arcs": [
						[
							-8516,
							8550,
							-5834,
							-5651,
							8551,
							8552
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36089,
					"arcs": [
						[
							8553,
							8554,
							8555,
							-2318,
							8556,
							8557
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26141,
					"arcs": [
						[
							8558,
							8559,
							-2217,
							-5790
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48301,
					"arcs": [
						[
							-612,
							8560,
							8561,
							8562,
							-8475
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36011,
					"arcs": [
						[
							-8355,
							8563,
							-8508,
							-3689,
							-2042,
							8564,
							8565
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18027,
					"arcs": [
						[
							-4789,
							-757,
							8566,
							-3515,
							-3948
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37155,
					"arcs": [
						[
							-962,
							-1518,
							8567,
							-6820,
							-1951,
							-6663,
							-4414
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18147,
					"arcs": [
						[
							8568,
							-5732,
							8569,
							8570,
							-6460
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21223,
					"arcs": [
						[
							8571,
							8572,
							8573,
							-5025,
							-4718
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26029,
					"arcs": [
						[
							[
								-4662,
								-5791,
								-1300,
								8574,
								8575
							]
						],
						[
							[
								8576
							]
						],
						[
							[
								8577
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30047,
					"arcs": [
						[
							-2475,
							-3995,
							-718
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47053,
					"arcs": [
						[
							-7340,
							-7166,
							-915,
							-6927,
							-1243,
							-7149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48477,
					"arcs": [
						[
							8578,
							8579,
							-4887,
							-8309,
							8580,
							-1571,
							8581
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16003,
					"arcs": [
						[
							-4615,
							-2276,
							-1766,
							-6992,
							-7169,
							8582
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35047,
					"arcs": [
						[
							8583,
							-7132,
							8584,
							8585,
							-4832,
							-8407
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46065,
					"arcs": [
						[
							-1893,
							8586,
							-2883,
							-5602
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48051,
					"arcs": [
						[
							8587,
							8588,
							-4888,
							-8580,
							8589
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48349,
					"arcs": [
						[
							-1334,
							-5295,
							8590,
							-1912,
							-4322
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21231,
					"arcs": [
						[
							-1100,
							8591,
							8592,
							-8166,
							-972,
							-8259
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19139,
					"arcs": [
						[
							-6692,
							-3043,
							-4545,
							-4175,
							-8186
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1019,
					"arcs": [
						[
							8593,
							-5671,
							-6714,
							8594,
							-4936,
							-3860,
							-5162
						]
					]
				},
				{
					"type": "Polygon",
					"id": 9013,
					"arcs": [
						[
							-8389,
							-6480,
							-3784,
							-5461,
							-6655
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8014,
					"arcs": [
						[
							8595,
							-7951,
							8596,
							-4996
						],
						[
							8597
						],
						[
							-7948
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8031,
					"arcs": [
						[
							8598,
							-2376,
							-4998
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51735,
					"arcs": [
						[
							8599,
							-8549,
							8600
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51740,
					"arcs": [
						[
							8601,
							-8370,
							8602,
							-984
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36119,
					"arcs": [
						[
							-8517,
							-8553,
							8603,
							-4010,
							8604,
							-189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22071,
					"arcs": [
						[
							8605,
							8606,
							8607,
							-5721,
							-8512
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28033,
					"arcs": [
						[
							-6900,
							8608,
							-3656,
							-7834,
							-8116
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33015,
					"arcs": [
						[
							8609,
							-4581,
							-8170,
							8610,
							-8498,
							-6850
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 36045,
					"arcs": [
						[
							[
								-8557,
								-2322,
								-8506,
								8611
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53069,
					"arcs": [
						[
							-7647,
							-8081,
							-8160,
							-8118,
							8612,
							8613
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1045,
					"arcs": [
						[
							8614,
							8615,
							8616,
							-3363,
							-3174,
							-1717
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21199,
					"arcs": [
						[
							-4694,
							8617,
							-5089,
							-6093,
							8618,
							-8592,
							-1099
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18107,
					"arcs": [
						[
							-6777,
							-4784,
							-2258,
							8619,
							8620,
							8621,
							-4792
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20049,
					"arcs": [
						[
							-1445,
							8622,
							-6957,
							-2417,
							8623,
							-2652
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55093,
					"arcs": [
						[
							-3732,
							-1647,
							8624,
							-5519,
							-3277,
							8625
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48083,
					"arcs": [
						[
							-1782,
							8626,
							8627,
							-1910,
							-8315,
							8628
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22001,
					"arcs": [
						[
							-4263,
							-7526,
							-6944,
							-8300,
							8629
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5133,
					"arcs": [
						[
							8630,
							8631,
							-6511,
							-4354
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27055,
					"arcs": [
						[
							-8428,
							-4924,
							8632,
							-8420,
							-2503,
							-7021
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13109,
					"arcs": [
						[
							8633,
							-6537,
							-8109,
							-4339,
							-7750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6065,
					"arcs": [
						[
							-151,
							-8410,
							-8188,
							8634,
							-702
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13039,
					"arcs": [
						[
							-8029,
							8635,
							-8248,
							8636,
							8637
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21141,
					"arcs": [
						[
							-3931,
							-7334,
							-7915,
							-7158,
							-7039,
							-1523
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22087,
					"arcs": [
						[
							[
								8638
							]
						],
						[
							[
								8639
							]
						],
						[
							[
								8640,
								-5722,
								-8608
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40067,
					"arcs": [
						[
							-390,
							-2072,
							-7957,
							-8352,
							-6203,
							-4025
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22073,
					"arcs": [
						[
							-7005,
							-7007,
							-3579,
							8641,
							-8441,
							8642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22049,
					"arcs": [
						[
							-7006,
							-8643,
							-8440,
							-5059,
							-3968
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36065,
					"arcs": [
						[
							-2320,
							8643,
							-8200,
							-4366,
							-8507
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36043,
					"arcs": [
						[
							-2319,
							-8556,
							8644,
							8645,
							8646,
							-8201,
							-8644
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41003,
					"arcs": [
						[
							8647,
							-1090,
							8648,
							-6143
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47189,
					"arcs": [
						[
							8649,
							-6691,
							-6911,
							-5410,
							-5367,
							8650,
							-8347
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13249,
					"arcs": [
						[
							-5071,
							-5067,
							8651,
							8652
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18127,
					"arcs": [
						[
							-3561,
							-6641,
							-6796,
							8653
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22059,
					"arcs": [
						[
							-5061,
							-8444,
							-8375,
							8654,
							-4813,
							-4817
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47001,
					"arcs": [
						[
							-8168,
							-6770,
							8655,
							-4875,
							-3715,
							-6677
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24510,
					"arcs": [
						[
							8656,
							-6717,
							-7052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2068,
					"arcs": [
						[
							-7727,
							8657,
							8658,
							8659
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21149,
					"arcs": [
						[
							8660,
							8661,
							-5491,
							-3928,
							8662,
							8663
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49049,
					"arcs": [
						[
							-7082,
							-788,
							-7925,
							8664,
							-8483,
							8665,
							-7970
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18063,
					"arcs": [
						[
							-2257,
							8666,
							-8174,
							8667,
							-8620
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41035,
					"arcs": [
						[
							8668,
							8669,
							-6400,
							-4032,
							-945,
							-3761,
							8670
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37145,
					"arcs": [
						[
							-7846,
							-6499,
							-235,
							-2670,
							-5185
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18001,
					"arcs": [
						[
							8671,
							8672,
							-964,
							-3032,
							8673
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47173,
					"arcs": [
						[
							-6769,
							8674,
							-6226,
							-4870,
							-8656
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17183,
					"arcs": [
						[
							-1533,
							-575,
							-2659,
							-2197,
							8675,
							-7208,
							-4777
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32023,
					"arcs": [
						[
							8676,
							-8518,
							-7002,
							8677,
							-8209,
							8678,
							8679,
							8680,
							8681
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19197,
					"arcs": [
						[
							8682,
							-2599,
							-2810,
							-1731,
							-7119
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22033,
					"arcs": [
						[
							-8417,
							-598,
							8683,
							8684,
							-8467,
							-8543
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40075,
					"arcs": [
						[
							-7978,
							-3744,
							-2364,
							8685,
							-5702,
							-7452,
							-2678
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48383,
					"arcs": [
						[
							-121,
							8686,
							-1991,
							-673,
							8687,
							8688
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40141,
					"arcs": [
						[
							-8686,
							-2368,
							-4028,
							-5786,
							-8244,
							-5703
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8041,
					"arcs": [
						[
							8689,
							-7911,
							8690,
							-2383,
							8691,
							-2402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48411,
					"arcs": [
						[
							-8316,
							-1908,
							-7742,
							-1031,
							8692,
							-3815,
							-1074
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29185,
					"arcs": [
						[
							-8464,
							-2362,
							-5023,
							-1607,
							-4344,
							-8294,
							-712
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32001,
					"arcs": [
						[
							-7371,
							-8519,
							-8677,
							8693,
							-7385,
							8694
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46137,
					"arcs": [
						[
							-6619,
							-4427,
							-7236,
							-7238,
							-4141
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4011,
					"arcs": [
						[
							-5076,
							-3160,
							-7129,
							-3609,
							8695,
							8696
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12057,
					"arcs": [
						[
							-7901,
							8697,
							8698,
							8699,
							8700
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2170,
					"arcs": [
						[
							8701,
							-8659,
							8702,
							-7620,
							-5009,
							8703,
							-1867,
							8704
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31075,
					"arcs": [
						[
							-3191,
							8705,
							-1269,
							8706,
							-1389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48221,
					"arcs": [
						[
							-1755,
							-7035,
							-4114,
							-2495,
							-1035
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48053,
					"arcs": [
						[
							-1030,
							-8268,
							-7378,
							-8073,
							-2585,
							-3816,
							-8693
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22063,
					"arcs": [
						[
							-597,
							8707,
							8708,
							8709,
							-8684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1001,
					"arcs": [
						[
							-6732,
							-6727,
							-4931,
							8710,
							-4933
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1005,
					"arcs": [
						[
							-6278,
							-4526,
							8711,
							-8152,
							-822,
							8712,
							-8616,
							8713
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26125,
					"arcs": [
						[
							-8318,
							-4207,
							8714,
							8715,
							-1293,
							8716
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26001,
					"arcs": [
						[
							8717,
							8718,
							-2846,
							8719
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26111,
					"arcs": [
						[
							-2207,
							-4246,
							8720,
							8721,
							-2428
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26129,
					"arcs": [
						[
							8722,
							-2849,
							8723,
							-2204,
							-3136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26145,
					"arcs": [
						[
							-4245,
							8724,
							8725,
							8726,
							8727,
							-8721
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8043,
					"arcs": [
						[
							-5997,
							8728,
							-2403,
							-8692,
							-2382,
							-4769,
							8729
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8047,
					"arcs": [
						[
							8730,
							-4994,
							-4289,
							-6238
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48461,
					"arcs": [
						[
							-7858,
							-3061,
							-8689,
							8731,
							-6739
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8055,
					"arcs": [
						[
							8732,
							-4770,
							-2387,
							-5826,
							-8526,
							-4461
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34003,
					"arcs": [
						[
							-190,
							-8605,
							-4009,
							-7664,
							-7624,
							-7428,
							8733
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48489,
					"arcs": [
						[
							[
								-6014,
								8734,
								8735,
								8736
							]
						],
						[
							[
								-6018,
								8737,
								8738,
								-8530
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22083,
					"arcs": [
						[
							-3578,
							8739,
							-8416,
							-5727,
							8740,
							-8442,
							-8642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6051,
					"arcs": [
						[
							-5270,
							-5876,
							-7387,
							8741,
							8742,
							8743,
							8744,
							-4765,
							-2878
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 12037,
					"arcs": [
						[
							[
								8745
							]
						],
						[
							[
								-4470,
								-4170,
								8746,
								8747
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18025,
					"arcs": [
						[
							-3199,
							-6149,
							-5635,
							-5753,
							-5734,
							8748
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21161,
					"arcs": [
						[
							-5720,
							8749,
							-8132,
							-5903,
							-3639,
							-3529
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21195,
					"arcs": [
						[
							-3152,
							-7027,
							-3471,
							-7688,
							-8391,
							-8503,
							-6961,
							8750
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35025,
					"arcs": [
						[
							-5214,
							-7193,
							-1195,
							-2025,
							-1922,
							-8362,
							8751,
							-8561,
							-611
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53047,
					"arcs": [
						[
							8752,
							-6107,
							8753,
							-5297,
							-8100,
							-3205,
							-8055,
							-4918
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54087,
					"arcs": [
						[
							8754,
							-4956,
							8755,
							8756,
							8757
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26147,
					"arcs": [
						[
							-7961,
							8758,
							-4204,
							-8317
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20141,
					"arcs": [
						[
							8759,
							8760,
							-2292,
							-2874,
							8761,
							8762,
							8763
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22057,
					"arcs": [
						[
							[
								8764
							]
						],
						[
							[
								-7983,
								8765,
								-3940,
								-8514,
								8766,
								-7988,
								-8340
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38009,
					"arcs": [
						[
							8767,
							-8325,
							-3140,
							8768,
							-4511
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37091,
					"arcs": [
						[
							-8463,
							-8473,
							8769,
							8770,
							-4380,
							-6036
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26097,
					"arcs": [
						[
							[
								8771
							]
						],
						[
							[
								-2902,
								8772,
								8773,
								8774
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41011,
					"arcs": [
						[
							8775,
							8776,
							8777
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8109,
					"arcs": [
						[
							-6302,
							-5998,
							-8730,
							-4771,
							-8733,
							-4460,
							-1761,
							8778,
							-6305
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24029,
					"arcs": [
						[
							-7060,
							-8458,
							-8046,
							8779,
							8780
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32009,
					"arcs": [
						[
							-8681,
							8781,
							-8743,
							8782
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36021,
					"arcs": [
						[
							8783,
							-5934,
							8784,
							8785,
							-6353
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38023,
					"arcs": [
						[
							8786,
							-3068,
							-6893,
							-7885
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55079,
					"arcs": [
						[
							-7038,
							8787,
							-990,
							-1082
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13067,
					"arcs": [
						[
							-7913,
							-5680,
							-6068,
							-5098,
							8788
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17189,
					"arcs": [
						[
							-2641,
							8789,
							8790,
							-6707,
							8791,
							8792
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21229,
					"arcs": [
						[
							8793,
							-5130,
							-6148,
							-4978,
							-4255
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18151,
					"arcs": [
						[
							-7298,
							-6833,
							-7880,
							8794,
							-3953
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35035,
					"arcs": [
						[
							-3683,
							-6766,
							-5215,
							-615,
							-1458,
							-8533,
							8795,
							-4375
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55123,
					"arcs": [
						[
							-4923,
							8796,
							8797,
							-8495,
							8798,
							8799,
							-8421,
							-8633
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13259,
					"arcs": [
						[
							-4525,
							8800,
							8801,
							-1845,
							-7389,
							-8151,
							-8712
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13257,
					"arcs": [
						[
							-8065,
							-5783,
							-5840,
							-748
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35045,
					"arcs": [
						[
							8802,
							8803,
							-5584,
							-4592,
							-4070,
							-3355,
							-5075
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51550,
					"arcs": [
						[
							-985,
							-8603,
							-8369,
							-3196,
							8804,
							-8364
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26155,
					"arcs": [
						[
							-8727,
							8805,
							-1291,
							8806,
							-1463,
							8807
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38037,
					"arcs": [
						[
							-5219,
							-6780,
							-6133,
							-3312,
							-1963
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38053,
					"arcs": [
						[
							-7854,
							-6894,
							-5595,
							-6762,
							-1017,
							-2444,
							-7821
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41019,
					"arcs": [
						[
							8808,
							-8669,
							8809,
							-1229,
							8810,
							-8776,
							8811
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55023,
					"arcs": [
						[
							-8800,
							8812,
							-8384,
							-8424,
							-8422
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8035,
					"arcs": [
						[
							-2380,
							-7912,
							-8690,
							-2401,
							-4999
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48061,
					"arcs": [
						[
							[
								8813,
								-8736
							]
						],
						[
							[
								-8739,
								8814,
								-8531
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13217,
					"arcs": [
						[
							8815,
							-7422,
							-5106,
							-7391,
							-1047,
							-4478
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16053,
					"arcs": [
						[
							-4483,
							-495,
							-6382,
							8816,
							-3512
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19091,
					"arcs": [
						[
							-582,
							-8683,
							-7118,
							-5693
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21165,
					"arcs": [
						[
							-7257,
							-4810,
							8817,
							-7215,
							-8397,
							8818
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29183,
					"arcs": [
						[
							8819,
							-5846,
							-6645,
							-8255,
							-4549,
							-1248,
							-1434
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30111,
					"arcs": [
						[
							-3243,
							8820,
							-7933,
							8821,
							-6930,
							-6835,
							-5205
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38093,
					"arcs": [
						[
							-2581,
							-1316,
							-1365,
							-1363,
							8822,
							-2260,
							-2990
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46059,
					"arcs": [
						[
							-2869,
							-5263,
							-1557,
							-1597,
							-6222,
							8823
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53049,
					"arcs": [
						[
							8824,
							-7648,
							-8614,
							8825
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28065,
					"arcs": [
						[
							-2575,
							-8075,
							-886,
							-3297,
							-3228
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31101,
					"arcs": [
						[
							-1391,
							8826,
							-2433,
							-2733,
							-1318,
							-7419
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41013,
					"arcs": [
						[
							-6329,
							-5394,
							-4859,
							8827,
							-6398
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37031,
					"arcs": [
						[
							-8239,
							8828,
							8829,
							8830
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17151,
					"arcs": [
						[
							-340,
							8831,
							-6968,
							8832,
							8833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32031,
					"arcs": [
						[
							-2268,
							-1688,
							8834,
							-947,
							-4031,
							8835,
							-5966,
							-7372,
							-8695,
							-7384,
							8836,
							-1941,
							-1698
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18003,
					"arcs": [
						[
							-1141,
							8837,
							-5758,
							8838,
							8839,
							-8672,
							8840,
							-2238,
							-2875
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20087,
					"arcs": [
						[
							-2704,
							-5743,
							-8210,
							-4798,
							8841
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20177,
					"arcs": [
						[
							-2705,
							-8842,
							-4797,
							8842,
							8843,
							8844
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46101,
					"arcs": [
						[
							8845,
							-6053,
							-4727,
							8846
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22123,
					"arcs": [
						[
							-3369,
							-8413,
							-8740,
							-3577
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1089,
					"arcs": [
						[
							-7573,
							-8528,
							-4159,
							-6191,
							-7538,
							-2698
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18067,
					"arcs": [
						[
							8847,
							8848,
							-503,
							-8182,
							-4782,
							8849
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18103,
					"arcs": [
						[
							8850,
							8851,
							-504,
							-8849,
							8852
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40083,
					"arcs": [
						[
							-1549,
							-7331,
							-5153,
							8853,
							-280,
							-3702
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13241,
					"arcs": [
						[
							-8192,
							8854,
							-5785,
							-8064,
							8855
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37113,
					"arcs": [
						[
							-4016,
							-7627,
							-7591,
							-8855,
							-8191,
							-3620
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36031,
					"arcs": [
						[
							8856,
							-8431,
							-6209,
							-6024,
							-7672,
							-7513,
							8857
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40001,
					"arcs": [
						[
							8858,
							-8373,
							-8218,
							-8198,
							8859,
							-241
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48183,
					"arcs": [
						[
							-4125,
							-6116,
							-529,
							-7567
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47177,
					"arcs": [
						[
							-5409,
							-6685,
							8860,
							8861,
							-6864,
							-5341,
							-5369
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48177,
					"arcs": [
						[
							-7861,
							-1094,
							8862,
							-1795,
							-2787,
							-4118,
							8863
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48175,
					"arcs": [
						[
							-4119,
							-2789,
							-8114,
							-6196,
							8864
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55021,
					"arcs": [
						[
							-8289,
							-3317,
							8865,
							-2791,
							-2997,
							-8492,
							8866
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16005,
					"arcs": [
						[
							8867,
							-7743,
							-3798,
							8868,
							-8282
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17011,
					"arcs": [
						[
							-8021,
							-5483,
							-696,
							-3388,
							-2184,
							-1772,
							-2488
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30013,
					"arcs": [
						[
							-6554,
							-4059,
							-7046,
							-7099,
							-363
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41029,
					"arcs": [
						[
							-1230,
							-8810,
							-8671,
							-3760
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13247,
					"arcs": [
						[
							-1851,
							-7423,
							-8816,
							-4477,
							8869
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39045,
					"arcs": [
						[
							-8265,
							8870,
							-1750,
							8871,
							-1882
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26041,
					"arcs": [
						[
							[
								8872
							]
						],
						[
							[
								8873,
								-4210,
								8874,
								8875,
								-3406
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29149,
					"arcs": [
						[
							-92,
							-2333,
							-7246,
							8876,
							8877,
							-2314,
							8878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48199,
					"arcs": [
						[
							-5579,
							-8104,
							-5418,
							-7796,
							-6919,
							-4878
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22041,
					"arcs": [
						[
							-8741,
							-5731,
							-7568,
							-8377,
							-8443
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36027,
					"arcs": [
						[
							-6354,
							-8786,
							8879,
							-5835,
							-8551,
							-8515,
							-5540
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51199,
					"arcs": [
						[
							-8214,
							8880,
							-8601,
							-8548,
							-196,
							-4442,
							-2727,
							-4441
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28149,
					"arcs": [
						[
							-5729,
							-8415,
							8881,
							-5517,
							-3664,
							-7554,
							-7570,
							-5726,
							-7569
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31103,
					"arcs": [
						[
							-6616,
							-3427,
							-6062,
							-4277,
							-6162,
							8882
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39111,
					"arcs": [
						[
							-7582,
							-7641,
							8883,
							-3851,
							-7678,
							8884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47059,
					"arcs": [
						[
							-6597,
							-8220,
							-8222,
							8885,
							-5621,
							-6517
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53063,
					"arcs": [
						[
							-8059,
							-3039,
							-6671,
							-4174,
							-3543,
							-6403,
							8886
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8079,
					"arcs": [
						[
							-8779,
							-1765,
							-5583,
							-6306
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21215,
					"arcs": [
						[
							-4191,
							-3925,
							-5132,
							8887,
							-956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27079,
					"arcs": [
						[
							8888,
							8889,
							-2531,
							-82,
							-1537,
							-5228
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29113,
					"arcs": [
						[
							-6055,
							-5847,
							-8820,
							-1433,
							-4827
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29127,
					"arcs": [
						[
							-8409,
							-5287,
							-8459,
							-6973,
							-6153,
							-4272
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1111,
					"arcs": [
						[
							8890,
							-7798,
							-2548,
							8891,
							-1710,
							8892,
							8893
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55047,
					"arcs": [
						[
							-7599,
							-4336,
							-2223,
							-2792,
							-8866,
							-3316
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55049,
					"arcs": [
						[
							8894,
							-8493,
							-3001,
							-7377,
							-7134,
							-8386
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27167,
					"arcs": [
						[
							8895,
							-2165,
							8896,
							8897,
							8898
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42003,
					"arcs": [
						[
							8899,
							-6566,
							-5318,
							-6046,
							-7764
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29510,
					"arcs": [
						[
							-8254,
							8900,
							-4551
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51041,
					"arcs": [
						[
							-2211,
							-6847,
							-8521,
							-6845,
							-6876,
							-6940,
							-5179,
							-6939,
							-6584,
							-2537,
							-6583,
							-7702,
							-8223
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48085,
					"arcs": [
						[
							8901,
							8902,
							-2954,
							-1806,
							-113,
							-1485
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47153,
					"arcs": [
						[
							-8862,
							8903,
							-5363,
							-7113,
							-7976,
							-6865
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20115,
					"arcs": [
						[
							-5082,
							-2541,
							-3155,
							-2650,
							-1479,
							-6465
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48117,
					"arcs": [
						[
							-1828,
							-3258,
							-2435,
							-39,
							-4584,
							8904
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37051,
					"arcs": [
						[
							-7153,
							-7028,
							-6829,
							-6817,
							-8568,
							-1517
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21173,
					"arcs": [
						[
							-7258,
							-8819,
							-8396,
							8905,
							8906
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29157,
					"arcs": [
						[
							-2391,
							-4241,
							8907,
							-3806,
							8908,
							8909,
							-8450,
							-4822
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36109,
					"arcs": [
						[
							8910,
							-8565,
							-2041,
							8911,
							8912,
							-4843
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42119,
					"arcs": [
						[
							-5326,
							-6752,
							8913,
							8914,
							-4101,
							-6889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51033,
					"arcs": [
						[
							8915,
							-2178,
							8916,
							-4365,
							-4953,
							-8522,
							8917
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13223,
					"arcs": [
						[
							-7914,
							-8789,
							-5097,
							-7797,
							-952,
							-6712
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46129,
					"arcs": [
						[
							-3430,
							-2155,
							-3303,
							8918,
							-7234,
							-4425
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5113,
					"arcs": [
						[
							-7585,
							-7351,
							8919,
							8920,
							-8631,
							-4353
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17181,
					"arcs": [
						[
							-8909,
							-3805,
							-2852,
							8921,
							8922,
							-5661,
							8923
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31137,
					"arcs": [
						[
							-795,
							-521,
							8924,
							-469,
							-5874,
							-5567
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32003,
					"arcs": [
						[
							-8208,
							-3006,
							-700,
							8925,
							-8679
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37133,
					"arcs": [
						[
							8926,
							-8830,
							8927,
							8928,
							-5588
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47025,
					"arcs": [
						[
							-5747,
							-7262,
							8929,
							-6605,
							-6227,
							-8675,
							-6768
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5097,
					"arcs": [
						[
							-7350,
							-5922,
							-7305,
							-211,
							-7067,
							-620,
							-8920
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5123,
					"arcs": [
						[
							-5991,
							-1861,
							-8117,
							-3445,
							-7505
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48147,
					"arcs": [
						[
							8930,
							-7670,
							-3850,
							-5364,
							-2955,
							-8903
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37025,
					"arcs": [
						[
							-6560,
							8931,
							8932,
							8933,
							-6039
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27123,
					"arcs": [
						[
							8934,
							-3275,
							-7156,
							-761
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37071,
					"arcs": [
						[
							-1419,
							-6037,
							8935,
							-7315
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51105,
					"arcs": [
						[
							-8299,
							-8505,
							-7143,
							-6602,
							-8930,
							-7261
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5009,
					"arcs": [
						[
							-2414,
							-5,
							-2563,
							8936,
							8937
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29073,
					"arcs": [
						[
							-6892,
							-4828,
							-1435,
							-1253,
							-6470,
							-3201,
							-129
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21189,
					"arcs": [
						[
							-3416,
							-7327,
							-3886,
							-6868,
							-2718
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17047,
					"arcs": [
						[
							-1185,
							-5667,
							-6322,
							8938
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37035,
					"arcs": [
						[
							-5223,
							-8125,
							-6561,
							-1416,
							-7199
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21019,
					"arcs": [
						[
							-7244,
							-4751,
							-8460,
							-5056,
							-5751
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42013,
					"arcs": [
						[
							-7828,
							-5329,
							8939,
							-6572,
							-6590
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45003,
					"arcs": [
						[
							8940,
							8941,
							-4108,
							8942,
							-5695,
							-7693,
							-5031
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47079,
					"arcs": [
						[
							-4235,
							-1564,
							-5190,
							-6289,
							-910,
							-7165
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21093,
					"arcs": [
						[
							8943,
							-5754,
							-5637,
							-958,
							8944,
							-6082,
							8945,
							8946
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26063,
					"arcs": [
						[
							-7959,
							8947,
							8948
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30071,
					"arcs": [
						[
							8949,
							-7890,
							-645,
							8950,
							8951,
							8952
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39015,
					"arcs": [
						[
							8953,
							-2022,
							-8051,
							-8131,
							-8750,
							-5719
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46069,
					"arcs": [
						[
							8954,
							-2870,
							-8824,
							-6221,
							-2884,
							-8587,
							-1892
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51021,
					"arcs": [
						[
							8955,
							-8038,
							-4948,
							-6423,
							-4330,
							-7831
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13089,
					"arcs": [
						[
							-1852,
							-8870,
							-4476,
							-8262,
							-6069
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21121,
					"arcs": [
						[
							-6095,
							-6871,
							-7259,
							-5745
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37167,
					"arcs": [
						[
							8956,
							-8215,
							-6030,
							8957,
							-8933
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46035,
					"arcs": [
						[
							-3240,
							-2801,
							8958,
							-2967,
							-3224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48475,
					"arcs": [
						[
							-8563,
							8959,
							-7859,
							-6742,
							-7806,
							-8476
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51139,
					"arcs": [
						[
							-6188,
							-2065,
							-7676,
							-4328,
							-3817,
							-7739
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33013,
					"arcs": [
						[
							8960,
							-1307,
							-4582,
							-8610,
							-6849,
							-4575
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47043,
					"arcs": [
						[
							-247,
							-2561,
							-8173,
							-7221,
							-1901,
							-3710
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51167,
					"arcs": [
						[
							-8392,
							-7686,
							-7832,
							-4334,
							8961,
							-7140,
							-8504
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27143,
					"arcs": [
						[
							-2543,
							-1015,
							8962,
							-8889,
							-5227,
							-5307
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48271,
					"arcs": [
						[
							-4641,
							-2942,
							8963,
							-3734,
							8964
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 6037,
					"arcs": [
						[
							[
								8965
							]
						],
						[
							[
								8966
							]
						],
						[
							[
								8967,
								-704,
								8968,
								8969,
								-3374
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46127,
					"arcs": [
						[
							-4036,
							-5643,
							-7847,
							-6863,
							-6496,
							-4569,
							-6595
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45017,
					"arcs": [
						[
							8970,
							8971,
							-6614,
							8972,
							-4106
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45037,
					"arcs": [
						[
							8973,
							8974,
							-8941,
							-5030,
							-7852,
							8975
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25003,
					"arcs": [
						[
							-5933,
							8976,
							-4660,
							-8001,
							-8390,
							-5831,
							-8880,
							-8785
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5083,
					"arcs": [
						[
							-7366,
							8977,
							-5926,
							-864,
							-5919,
							-7349
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22095,
					"arcs": [
						[
							8978,
							-8709,
							8979,
							8980,
							-8510,
							-3941,
							-8766,
							-7982
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27061,
					"arcs": [
						[
							-7930,
							-2762,
							-8496,
							-7874,
							-5506
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30039,
					"arcs": [
						[
							-3997,
							8981,
							-5400,
							8982
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36121,
					"arcs": [
						[
							-4689,
							8983,
							-2623,
							-3268,
							-6026
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5041,
					"arcs": [
						[
							8984,
							-7562,
							-7550,
							-3364,
							-3289,
							-6509
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16069,
					"arcs": [
						[
							-6405,
							-7800,
							-7107,
							-491,
							-2272,
							-4614,
							-7281
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27011,
					"arcs": [
						[
							8985,
							-1815,
							-510,
							-6838,
							-7093,
							-7768
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35033,
					"arcs": [
						[
							-4589,
							8986,
							-6127,
							-7133,
							-8584,
							-8406
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13163,
					"arcs": [
						[
							-7440,
							-3898,
							8987,
							-5033,
							-7692,
							-8400,
							-7481,
							-3499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47119,
					"arcs": [
						[
							-1903,
							-7225,
							-4869,
							-4045,
							8988,
							-5143
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17133,
					"arcs": [
						[
							-4553,
							8989,
							8990,
							-4239,
							8991
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27135,
					"arcs": [
						[
							8992,
							-4667,
							-5504,
							-3721,
							-6744
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31131,
					"arcs": [
						[
							-5960,
							-8257,
							-8429,
							-4001,
							8993,
							-2617
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31177,
					"arcs": [
						[
							-8286,
							8994,
							-7395,
							-4222,
							8995
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56021,
					"arcs": [
						[
							-2602,
							-6171,
							-7302,
							-6761,
							-7949,
							-6454,
							-4735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47135,
					"arcs": [
						[
							-6290,
							-3711,
							-1905,
							-5146,
							-7163,
							-4111
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48043,
					"arcs": [
						[
							8996,
							-7808,
							-5851,
							8997,
							-7841
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37157,
					"arcs": [
						[
							8998,
							8999,
							-5186,
							-3231,
							-1410,
							-3214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51177,
					"arcs": [
						[
							-5424,
							-7631,
							-2181,
							-4252,
							-2179,
							-8916,
							9000,
							-6332
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21239,
					"arcs": [
						[
							-3522,
							9001,
							9002,
							-5119,
							-6146,
							-5128
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45087,
					"arcs": [
						[
							-7271,
							9003,
							9004,
							-1589,
							-6668,
							-7229,
							-5258
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53031,
					"arcs": [
						[
							9005,
							9006,
							9007,
							9008,
							9009
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6059,
					"arcs": [
						[
							-8969,
							-703,
							-8635,
							-8187,
							9010
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12127,
					"arcs": [
						[
							-8163,
							-2136,
							9011,
							9012,
							-995,
							9013,
							-6626
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24017,
					"arcs": [
						[
							-6184,
							-7000,
							9014,
							9015,
							9016
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29155,
					"arcs": [
						[
							-5811,
							9017,
							-7146,
							-8388,
							-4564
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35059,
					"arcs": [
						[
							-5828,
							-2929,
							-6140,
							-2714,
							-2485,
							9018,
							-7130,
							-6125
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41025,
					"arcs": [
						[
							-6399,
							-8828,
							-4858,
							-7460,
							-5961,
							-8836,
							-4030
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48141,
					"arcs": [
						[
							-8796,
							-8536,
							9019,
							-4376
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39053,
					"arcs": [
						[
							9020,
							-2693,
							-5943,
							-7590,
							-7636,
							-7242
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5067,
					"arcs": [
						[
							-6420,
							-6505,
							-7191,
							-58,
							-1859,
							-5990,
							9021
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17081,
					"arcs": [
						[
							-8791,
							9022,
							9023,
							-4484,
							-7210,
							-6708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21181,
					"arcs": [
						[
							-3636,
							-3531,
							-3643,
							-7256,
							9024
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35028,
					"arcs": [
						[
							-4591,
							-8405,
							-4065,
							-8404,
							-4067
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48161,
					"arcs": [
						[
							-8591,
							-5294,
							-5430,
							-6538,
							-1913
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45065,
					"arcs": [
						[
							-7337,
							9025,
							-8976,
							-7851,
							-5836,
							9026
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1027,
					"arcs": [
						[
							9027,
							-8894,
							9028,
							-7354,
							-3867
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1067,
					"arcs": [
						[
							-8713,
							-821,
							-3550,
							-3359,
							-8617
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40041,
					"arcs": [
						[
							-4021,
							9029,
							-6887,
							-8374,
							-8859,
							-240,
							9030
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51077,
					"arcs": [
						[
							9031,
							-4332,
							-6422,
							-6378,
							-5616,
							-6377,
							-7073,
							-7070,
							-4391,
							-7865
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12081,
					"arcs": [
						[
							-8699,
							-1995,
							-2778,
							9032,
							9033
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20139,
					"arcs": [
						[
							9034,
							-8843,
							-4800,
							-3919,
							-6807,
							-67
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17129,
					"arcs": [
						[
							-4909,
							-2663,
							-8438,
							-6483
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20089,
					"arcs": [
						[
							-607,
							-5142,
							-8329,
							-6088,
							-2288,
							-8761,
							9035
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25023,
					"arcs": [
						[
							-6700,
							9036,
							-6696,
							9037,
							-7267,
							9038,
							-6636
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29099,
					"arcs": [
						[
							-4554,
							-8992,
							-4238,
							-2389,
							-1275,
							-1250
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55051,
					"arcs": [
						[
							9039,
							-5889,
							-3473,
							-675,
							-8335
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4021,
					"arcs": [
						[
							9040,
							9041,
							-5454,
							-4983
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21175,
					"arcs": [
						[
							-4809,
							-5490,
							-8461,
							-4699,
							-3938,
							-7216,
							-8818
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24027,
					"arcs": [
						[
							-5884,
							9042,
							-7053,
							-6715,
							-6998,
							-3973
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39073,
					"arcs": [
						[
							-1883,
							-8872,
							-1749,
							-31,
							-2691,
							-7907
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16083,
					"arcs": [
						[
							-1240,
							-3513,
							-8817,
							-6386,
							-5531,
							-7400
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26055,
					"arcs": [
						[
							-5449,
							9043,
							9044,
							-3221,
							-262,
							-177
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34029,
					"arcs": [
						[
							-4372,
							9045,
							-6568,
							9046
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39035,
					"arcs": [
						[
							9047,
							9048,
							-3742,
							-8022,
							9049,
							-7714
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1109,
					"arcs": [
						[
							-4929,
							-6279,
							-8714,
							-8615,
							-1716,
							-3479
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8089,
					"arcs": [
						[
							-2708,
							-2399,
							-2907,
							-5827,
							-2385
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12125,
					"arcs": [
						[
							9050,
							-2931,
							-6721,
							-3536
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13031,
					"arcs": [
						[
							-486,
							9051,
							-8018,
							-6532,
							-8634,
							-7749,
							-8401
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39121,
					"arcs": [
						[
							-2015,
							-7583,
							-8885,
							-7677,
							-1878,
							-1743
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17157,
					"arcs": [
						[
							-8991,
							9052,
							-8792,
							-6710,
							-3807,
							-8908,
							-4240
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51085,
					"arcs": [
						[
							-9001,
							-8918,
							-8523,
							-8010,
							-6842,
							9053,
							-6333
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13091,
					"arcs": [
						[
							-5095,
							-7480,
							-7430,
							-7773,
							9054,
							-7409
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12105,
					"arcs": [
						[
							-8032,
							9055,
							-4899,
							-7346,
							-1992,
							-8698,
							-7900
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21085,
					"arcs": [
						[
							9056,
							-8947,
							9057,
							-5124,
							-7332,
							-5495
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27093,
					"arcs": [
						[
							-4821,
							-1825,
							-2545,
							-5306,
							-1509
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29179,
					"arcs": [
						[
							-3322,
							-8447,
							-2329,
							-90,
							-2910
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48431,
					"arcs": [
						[
							-120,
							-2412,
							-3329,
							-2675,
							-1988,
							-8687
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48495,
					"arcs": [
						[
							-8752,
							-8361,
							-7860,
							-8960,
							-8562
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17203,
					"arcs": [
						[
							-2186,
							-693,
							-1709,
							-1720,
							9058,
							-8436
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39097,
					"arcs": [
						[
							-2644,
							-103,
							-901,
							-8266,
							-1880,
							9059,
							-3344
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29003,
					"arcs": [
						[
							-8094,
							9060,
							-644,
							-7810,
							-7446,
							-5865
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5087,
					"arcs": [
						[
							-8372,
							9061,
							9062,
							-5924,
							9063,
							-8195,
							-8217
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37141,
					"arcs": [
						[
							-6828,
							-5589,
							-8929,
							9064,
							-7291,
							-5617,
							-1948,
							-6819
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54073,
					"arcs": [
						[
							-7679,
							-3854,
							-3823,
							-7939
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51820,
					"arcs": [
						[
							-7895
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30065,
					"arcs": [
						[
							9065,
							9066,
							-7934,
							-8821,
							-3242
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1029,
					"arcs": [
						[
							-3866,
							-4937,
							-8595,
							-6713,
							-954,
							-7799,
							-8891,
							-9028
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13049,
					"arcs": [
						[
							9067,
							-8637,
							-8251,
							9068,
							-3547
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21027,
					"arcs": [
						[
							-5736,
							-5755,
							-8944,
							-9057,
							-5494,
							9069
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22053,
					"arcs": [
						[
							-80,
							-4264,
							-8630,
							-8303,
							-7462,
							-4194,
							-4653
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29207,
					"arcs": [
						[
							-8446,
							-8452,
							9070,
							-4559,
							-5805,
							-4562,
							-3340
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35019,
					"arcs": [
						[
							-8586,
							9071,
							-652,
							-6765,
							-4833
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42097,
					"arcs": [
						[
							-4102,
							-8915,
							9072,
							-1299,
							-6855,
							-7188,
							9073,
							-3705
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47175,
					"arcs": [
						[
							-6684,
							-5352,
							-6682,
							-5350,
							-5361,
							-8904,
							-8861
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48339,
					"arcs": [
						[
							-7674,
							-5376,
							-4880,
							-3438,
							9074,
							-8307
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55057,
					"arcs": [
						[
							-2830,
							9075,
							-8290,
							-8867,
							-8491,
							-8798,
							9076
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24011,
					"arcs": [
						[
							9077,
							-8044,
							9078,
							9079,
							-5823
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17153,
					"arcs": [
						[
							-8923,
							9080,
							9081,
							-7016,
							-5034,
							-5662
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5073,
					"arcs": [
						[
							-4987,
							-3076,
							-5264,
							-7288,
							9082,
							-7101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28083,
					"arcs": [
						[
							9083,
							-4052,
							-227,
							-3944,
							9084,
							-7794
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48275,
					"arcs": [
						[
							9085,
							-778,
							-921,
							-782
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40115,
					"arcs": [
						[
							-4547,
							-3597,
							-6888,
							-9030,
							-4020
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2164,
					"arcs": [
						[
							[
								9086,
								-7564,
								9087,
								-7612,
								9088,
								-1870,
								9089,
								9090,
								-2980
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39037,
					"arcs": [
						[
							-4182,
							-7659,
							-3033,
							-968,
							9091,
							-2965,
							-2950,
							-2812
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27139,
					"arcs": [
						[
							-1014,
							-7157,
							-3279,
							-2528,
							-8890,
							-8963
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19111,
					"arcs": [
						[
							-2251,
							-8184,
							-8005,
							9092,
							-7837,
							-5384
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45049,
					"arcs": [
						[
							-6286,
							-7707,
							-6433,
							-8358,
							-8015,
							9093
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19133,
					"arcs": [
						[
							-6861,
							-1695,
							9094,
							-8284,
							-8139
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27173,
					"arcs": [
						[
							-6840,
							-6972,
							-5309,
							-4281,
							-5501,
							-1611,
							-840
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36033,
					"arcs": [
						[
							-8432,
							-8857,
							9095,
							-8554,
							9096
						]
					]
				},
				{
					"type": "Polygon",
					"id": 25017,
					"arcs": [
						[
							-6852,
							-8500,
							-6293,
							-6697,
							-6291,
							-6698,
							-6477
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30005,
					"arcs": [
						[
							9097,
							-8953,
							9098,
							-4056,
							-5955
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 53029,
					"arcs": [
						[
							[
								-7472,
								9099
							]
						],
						[
							[
								9100
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1123,
					"arcs": [
						[
							-9029,
							-8893,
							-1714,
							-7698,
							-6315,
							-6726,
							-7355
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13085,
					"arcs": [
						[
							-5091,
							-449,
							-8070,
							9101,
							-2027,
							-5678,
							-4975
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13105,
					"arcs": [
						[
							-8071,
							-6360,
							-7338,
							-9027,
							-5839,
							-3902,
							-8150,
							-8142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19085,
					"arcs": [
						[
							-9095,
							-1694,
							-4494,
							-7393,
							-8995,
							-8285
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21185,
					"arcs": [
						[
							-8574,
							9102,
							-3927,
							-4190,
							-5026
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46095,
					"arcs": [
						[
							9103,
							-2890,
							-6617,
							-4419,
							-7294
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49007,
					"arcs": [
						[
							-8665,
							-7924,
							-7975,
							-7963,
							-7927,
							-8484
						]
					]
				},
				{
					"type": "Polygon",
					"id": 15003,
					"arcs": [
						[
							9104
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47165,
					"arcs": [
						[
							-7160,
							-5646,
							-7290,
							-6688,
							-8650,
							-8346,
							-7041
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53045,
					"arcs": [
						[
							-9008,
							9105,
							-7402,
							-6985,
							9106,
							-8061,
							9107
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5001,
					"arcs": [
						[
							-6492,
							-7559,
							-7507,
							-7563,
							-8985,
							-6508,
							9108
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19011,
					"arcs": [
						[
							-5117,
							-7287,
							-1760,
							-1007,
							-2254
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29225,
					"arcs": [
						[
							-372,
							-1283,
							-2806,
							-2338,
							-4268,
							-2923
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37103,
					"arcs": [
						[
							-4074,
							-8240,
							-8831,
							-8927,
							-5587
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19037,
					"arcs": [
						[
							9109,
							-2506,
							9110,
							-5133,
							-2785
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22077,
					"arcs": [
						[
							9111,
							-5815,
							9112,
							-8544,
							-8465,
							9113,
							-7524,
							9114
						]
					]
				},
				{
					"type": "Polygon",
					"id": 10005,
					"arcs": [
						[
							-8043,
							9115,
							-6265,
							-6327,
							9116,
							-9079
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45079,
					"arcs": [
						[
							-6666,
							-8228,
							9117,
							-8971,
							-4105,
							-7230
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48331,
					"arcs": [
						[
							-8267,
							-1544,
							9118,
							-8588,
							9119,
							-7380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54019,
					"arcs": [
						[
							9120,
							-7657,
							-4750,
							-7084,
							-7602
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22015,
					"arcs": [
						[
							-7102,
							-9083,
							-7289,
							-3971,
							-5498,
							9121
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53009,
					"arcs": [
						[
							-9006,
							9122
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13027,
					"arcs": [
						[
							9123,
							9124,
							-7780,
							-7995,
							-8156,
							-7412
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 48355,
					"arcs": [
						[
							[
								-6428,
								9125,
								-6309,
								9126
							]
						],
						[
							[
								-3836,
								9127,
								-6312,
								-5606
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30073,
					"arcs": [
						[
							9128,
							-3603,
							9129,
							-4061,
							-6553,
							-2471
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32029,
					"arcs": [
						[
							-7383,
							-8837
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12115,
					"arcs": [
						[
							-9033,
							-2781,
							-6651,
							9130
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46075,
					"arcs": [
						[
							-7239,
							-5603,
							-2891,
							-9104,
							-7293
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48287,
					"arcs": [
						[
							-7381,
							-9120,
							-8590,
							-8579,
							9131,
							-1925
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29033,
					"arcs": [
						[
							-2108,
							-5482,
							-8449,
							-7306,
							-6883,
							9132
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5121,
					"arcs": [
						[
							-7245,
							-7080,
							9133,
							-6502,
							9134,
							-8877
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17033,
					"arcs": [
						[
							-6815,
							-5379,
							-3945,
							-2753,
							-1182,
							-3311
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45081,
					"arcs": [
						[
							-7231,
							-4109,
							-8942,
							-8975,
							9135
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6027,
					"arcs": [
						[
							-8744,
							-8782,
							-8680,
							-8926,
							-699,
							9136,
							9137,
							9138
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21037,
					"arcs": [
						[
							9139,
							-3055,
							-4648,
							-7816
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28093,
					"arcs": [
						[
							-6899,
							-4627,
							-5793,
							-5510,
							-683,
							-3657,
							-8609
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 26033,
					"arcs": [
						[
							[
								9140,
								-8773,
								-2901
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31147,
					"arcs": [
						[
							-3027,
							-4004,
							-5867,
							-7445,
							-6576,
							-19
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36013,
					"arcs": [
						[
							9141,
							-6027,
							-3270,
							-1395,
							9142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47099,
					"arcs": [
						[
							-5144,
							-8989,
							-4049,
							-5155,
							-7164
						]
					]
				},
				{
					"type": "Polygon",
					"id": 15005,
					"arcs": [
						[
							9143,
							-3097
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13075,
					"arcs": [
						[
							-8349,
							-3895,
							-7778,
							-9125,
							9144
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26013,
					"arcs": [
						[
							9145,
							9146,
							9147,
							-6906
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40101,
					"arcs": [
						[
							-5599,
							-243,
							9148,
							-7546,
							-8482,
							-8096
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17191,
					"arcs": [
						[
							9149,
							-1273,
							-1186,
							-8939,
							-6321,
							-4485,
							-9024
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39161,
					"arcs": [
						[
							9150,
							-111,
							-5760,
							-2519,
							-965,
							-8673,
							-8840
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39047,
					"arcs": [
						[
							-9060,
							-1885,
							-7906,
							-8049,
							-2020,
							-3345
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48493,
					"arcs": [
						[
							9151,
							-7862,
							-8864,
							-4117,
							-1067
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48243,
					"arcs": [
						[
							-1460,
							-8477,
							-7809,
							-8997,
							-7840,
							-8534
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36095,
					"arcs": [
						[
							9152,
							-2857,
							9153,
							9154,
							-7518,
							-8203
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12003,
					"arcs": [
						[
							-1932,
							-3548,
							-9069,
							-8250,
							-7712,
							-7870,
							-2932,
							-9051,
							-3535
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19059,
					"arcs": [
						[
							-7032,
							9155,
							9156,
							-2173
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34041,
					"arcs": [
						[
							-4410,
							-7611,
							-3681,
							-7065,
							-6519,
							-6953
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45021,
					"arcs": [
						[
							-7368,
							-7317,
							9157,
							-9004,
							-7270
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49023,
					"arcs": [
						[
							-7971,
							-8666,
							-8486,
							9158,
							9159
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41039,
					"arcs": [
						[
							-6144,
							-8649,
							-1089,
							-6401,
							-8670,
							-8809,
							9160
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47171,
					"arcs": [
						[
							-8886,
							-8221,
							-6373,
							-5198,
							-7009,
							-5622
						]
					]
				},
				{
					"type": "Polygon",
					"id": 50003,
					"arcs": [
						[
							-7673,
							-5304,
							-6100,
							-6634,
							-4655,
							-8977,
							-5932
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53071,
					"arcs": [
						[
							-4449,
							9161,
							-3460,
							-5397
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6055,
					"arcs": [
						[
							-8529,
							-4400,
							-5949,
							-8381
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21209,
					"arcs": [
						[
							9162,
							-6091,
							-3638,
							9163,
							9164,
							-9002,
							-3521
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26157,
					"arcs": [
						[
							9165,
							-8948,
							-7958,
							-8320,
							9166,
							-8725,
							-4244
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27029,
					"arcs": [
						[
							-5508,
							-3273,
							-634,
							9167,
							-2302,
							-7666
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48341,
					"arcs": [
						[
							-3262,
							-279,
							9168,
							-536,
							-1827,
							-2483
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48105,
					"arcs": [
						[
							-6740,
							-8732,
							-8688,
							-672,
							-1812,
							-2461,
							-4640,
							-5849,
							-7807
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48499,
					"arcs": [
						[
							-5608,
							-2034,
							-5412,
							-4127,
							-7566,
							-7510,
							9169
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40117,
					"arcs": [
						[
							-7528,
							-2055,
							-1673,
							-5151,
							-7330
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36017,
					"arcs": [
						[
							-4368,
							-8204,
							-7516,
							-7574,
							-2038
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40069,
					"arcs": [
						[
							-2078,
							-4152,
							-4306,
							-4089,
							-7669,
							-7534,
							-2069
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6067,
					"arcs": [
						[
							-4398,
							-7746,
							-1701,
							-443,
							9170,
							9171,
							-6988,
							-5950
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6029,
					"arcs": [
						[
							-334,
							9172,
							-9137,
							-698,
							-8968,
							-3373,
							-2896,
							-8
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21079,
					"arcs": [
						[
							-6147,
							-5122,
							-6783,
							-5086,
							9173,
							-4980
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30017,
					"arcs": [
						[
							-649,
							-8480,
							-7883,
							-8326,
							-6471,
							-7931
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46107,
					"arcs": [
						[
							-8919,
							-3302,
							-2871,
							-8955,
							-1891,
							-7235
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38049,
					"arcs": [
						[
							-4512,
							-8769,
							-3145,
							-5591,
							-5250,
							9174
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8083,
					"arcs": [
						[
							-5459,
							9175,
							-8803,
							-1114
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51107,
					"arcs": [
						[
							9176,
							-6253,
							-5885,
							-3977,
							-6179,
							9177,
							-7633,
							-7343
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1085,
					"arcs": [
						[
							-8711,
							-4930,
							-3477,
							-8537,
							-2423,
							-4934
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6077,
					"arcs": [
						[
							-9172,
							9178,
							-3878,
							9179,
							-5766,
							-6989
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21067,
					"arcs": [
						[
							-9165,
							9180,
							9181,
							-6781,
							-5120,
							-9003
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42081,
					"arcs": [
						[
							-1382,
							-8434,
							-1370,
							-6853,
							-1297,
							-9073,
							-8914,
							-6751,
							-726
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51003,
					"arcs": [
						[
							-7740,
							-3820,
							-5426,
							-6331,
							-404,
							-2802,
							-8481,
							-7892
						],
						[
							9182
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51075,
					"arcs": [
						[
							-6334,
							-9054,
							-6848,
							-2210,
							-1636,
							-406
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28111,
					"arcs": [
						[
							-8103,
							-4219,
							-7651,
							-832,
							-8479,
							-3334
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26049,
					"arcs": [
						[
							-9167,
							-8319,
							-8717,
							-1292,
							-8806,
							-8726
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39079,
					"arcs": [
						[
							-7908,
							-2694,
							-9021,
							-7241,
							-5630,
							-1887
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5015,
					"arcs": [
						[
							-1632,
							-6284,
							-2415,
							-8938,
							9183,
							-9062,
							-8371
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12103,
					"arcs": [
						[
							9184,
							-7902,
							-8701,
							9185,
							-7904
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 21075,
					"arcs": [
						[
							[
								-6943,
								-6805,
								-7339,
								9186,
								-5807
							]
						],
						[
							[
								9187,
								-5809
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54045,
					"arcs": [
						[
							-5677,
							9188,
							-7606,
							-3467
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51091,
					"arcs": [
						[
							-4501,
							-7891,
							-8039,
							-851
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29081,
					"arcs": [
						[
							-1156,
							-3564,
							-3994,
							-1899,
							-764,
							9189,
							-3598
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54049,
					"arcs": [
						[
							-483,
							-7945,
							-2114,
							9190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28051,
					"arcs": [
						[
							-9085,
							-3943,
							-7756,
							-5515,
							-7795
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21101,
					"arcs": [
						[
							-8048,
							-6462,
							9191,
							-8661,
							9192,
							-3956,
							-6649
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29091,
					"arcs": [
						[
							-2374,
							-2340,
							-1407,
							-93,
							-8879,
							-2313
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48019,
					"arcs": [
						[
							9193,
							-2409,
							9194,
							-3237,
							9195,
							-7849
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4027,
					"arcs": [
						[
							-8411,
							-156,
							-4985,
							-5458,
							9196
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21001,
					"arcs": [
						[
							-4803,
							-6151,
							-4695,
							-1097,
							9197,
							9198
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29175,
					"arcs": [
						[
							-1288,
							-6155,
							-1629,
							-8179,
							-7425,
							-8448
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51147,
					"arcs": [
						[
							-5436,
							-2803,
							-1639,
							-8224,
							-6633,
							9199,
							-8487
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30069,
					"arcs": [
						[
							-8951,
							-651,
							-7935,
							-9067,
							9200
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51099,
					"arcs": [
						[
							9201,
							9202,
							-4359,
							-8917,
							-2177
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21179,
					"arcs": [
						[
							-957,
							-8888,
							-5131,
							-8794,
							-4254,
							-6079,
							-8945
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37137,
					"arcs": [
						[
							-4388,
							9203,
							-8237
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16021,
					"arcs": [
						[
							9204,
							-316,
							-6669,
							-3037
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22103,
					"arcs": [
						[
							9205,
							-4215,
							-8077,
							9206,
							-8606,
							-8511,
							-8981,
							9207
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 24019,
					"arcs": [
						[
							[
								-5987,
								9208
							]
						],
						[
							[
								-5824,
								-9080,
								-9117,
								-6326,
								9209
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54039,
					"arcs": [
						[
							9210,
							-8757,
							9211,
							-7658,
							-9121,
							-7601,
							9212,
							-5675,
							-7597
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28161,
					"arcs": [
						[
							-3051,
							-687,
							-2147,
							-222,
							-4051
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12053,
					"arcs": [
						[
							-4960,
							-8033,
							-7898,
							9213
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54055,
					"arcs": [
						[
							9214,
							-7608,
							-7603,
							-7087,
							-8037,
							-8956,
							-7830
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13253,
					"arcs": [
						[
							-3553,
							-7408,
							-4535,
							-3785,
							-3361
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32021,
					"arcs": [
						[
							-7386,
							-8694,
							-8682,
							-8783,
							-8742
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13189,
					"arcs": [
						[
							-5838,
							-7853,
							-5028,
							-8988,
							-3897,
							-3904
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36057,
					"arcs": [
						[
							9215,
							-4849,
							-2854,
							-9153,
							-8202,
							-8647
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6035,
					"arcs": [
						[
							-948,
							-8835,
							-1687,
							-8291,
							-2279
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18051,
					"arcs": [
						[
							-5665,
							-3949,
							-3518,
							-6458,
							-8047,
							-6647,
							-6323
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22045,
					"arcs": [
						[
							[
								-6947,
								9216,
								-8469,
								-8342,
								9217,
								-6578,
								9218,
								-8301
							]
						],
						[
							[
								9219
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26043,
					"arcs": [
						[
							9220,
							9221,
							-3404,
							9222,
							-6468
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39025,
					"arcs": [
						[
							-1999,
							-2023,
							-8954,
							-5718,
							-3056,
							-9140,
							-7815
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34005,
					"arcs": [
						[
							-6522,
							-7125,
							-4373,
							-9047,
							-6567,
							-5967,
							-5708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21059,
					"arcs": [
						[
							-6461,
							-8571,
							9223,
							-5492,
							-8662,
							-9192
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24013,
					"arcs": [
						[
							9224,
							-5716,
							-7054,
							-9043,
							-5883
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47069,
					"arcs": [
						[
							-4312,
							-6929,
							-7283,
							-7285,
							9225,
							-2549,
							-5792,
							-4625
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6099,
					"arcs": [
						[
							-5767,
							-9180,
							-3877,
							-2881,
							-4768,
							9226,
							-3756
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17163,
					"arcs": [
						[
							-8901,
							-8253,
							-2642,
							-8793,
							-9053,
							-8990,
							-4552
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38035,
					"arcs": [
						[
							-7719,
							-3724,
							-2306,
							-3624,
							-382,
							-3014
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42073,
					"arcs": [
						[
							-4250,
							9227,
							-7762,
							-8273,
							-2479
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49053,
					"arcs": [
						[
							-1605,
							-7033,
							-3003,
							-8207
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54029,
					"arcs": [
						[
							-8274,
							-7765,
							-6044,
							-7577,
							-8124
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38015,
					"arcs": [
						[
							-5252,
							-5592,
							-2992,
							-3626,
							-5217,
							-809
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24001,
					"arcs": [
						[
							-7247,
							-6574,
							-5948,
							-6256,
							-5909,
							-7992,
							-7735,
							-7174
						]
					]
				},
				{
					"type": "Polygon",
					"id": 32033,
					"arcs": [
						[
							-5535,
							-7972,
							-9160,
							9228,
							-8205,
							-8678,
							-7001
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26011,
					"arcs": [
						[
							-8724,
							-2848,
							9229,
							-4242,
							-2205
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30035,
					"arcs": [
						[
							9230,
							-3604,
							-9129,
							-2470
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47095,
					"arcs": [
						[
							-5810,
							-9188,
							-5808,
							-9187,
							-7341,
							-7147,
							-9018
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48181,
					"arcs": [
						[
							-7955,
							-7536,
							-7671,
							-8931,
							-8902,
							-1484,
							-4430
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21091,
					"arcs": [
						[
							-8570,
							-5737,
							-9070,
							-5493,
							-9224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20149,
					"arcs": [
						[
							-8321,
							-22,
							-2706,
							-8845,
							9231,
							-8233
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51119,
					"arcs": [
						[
							-4363,
							9232,
							9233,
							-8211,
							-4949
						]
					]
				},
				{
					"type": "Polygon",
					"id": 56039,
					"arcs": [
						[
							-1660,
							-7663,
							-6916,
							-5912,
							-6249,
							-7801,
							-6704,
							-6977
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5101,
					"arcs": [
						[
							-9184,
							-8937,
							-2566,
							-859,
							-5925,
							-9063
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8093,
					"arcs": [
						[
							-4837,
							-4291,
							-5000,
							-2404,
							-8729,
							-5996,
							-4892
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29029,
					"arcs": [
						[
							-7144,
							-6409,
							-5210,
							-1279,
							-370,
							-1609,
							-5022
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31053,
					"arcs": [
						[
							-97,
							-8287,
							-8996,
							-4221,
							-8135,
							-2771,
							9234
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26163,
					"arcs": [
						[
							-8715,
							-4206,
							9235,
							-3982,
							9236
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20183,
					"arcs": [
						[
							9237,
							-608,
							-9036,
							-8760,
							9238,
							-6789
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22105,
					"arcs": [
						[
							-7477,
							9239,
							9240,
							-9208,
							-8980,
							-8708,
							-596
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51770,
					"arcs": [
						[
							-267,
							-4678
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27087,
					"arcs": [
						[
							-2303,
							-9168,
							-633,
							9241
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30103,
					"arcs": [
						[
							-7932,
							-6931,
							-8822
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 55007,
					"arcs": [
						[
							[
								9242,
								-8337,
								9243,
								-8344
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1091,
					"arcs": [
						[
							-5581,
							-8395,
							-7642,
							-2087,
							-4935,
							-2427,
							9244,
							-4519
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21099,
					"arcs": [
						[
							-8946,
							-6081,
							-4805,
							9245,
							-4707,
							-5125,
							-9058
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22009,
					"arcs": [
						[
							-4814,
							-8655,
							-8378,
							-5817,
							9246,
							-9115,
							-7523,
							-4261
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41053,
					"arcs": [
						[
							-6748,
							-4309,
							-3173,
							-1085,
							-8648,
							-6142
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47105,
					"arcs": [
						[
							-3717,
							-4874,
							-6297,
							-7994,
							-4730
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28115,
					"arcs": [
						[
							-5513,
							-5203,
							9247,
							-2144,
							-685
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31041,
					"arcs": [
						[
							-231,
							9248,
							-2053,
							9249,
							-2963,
							-516,
							-794,
							-2731,
							-4063
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20099,
					"arcs": [
						[
							9250,
							-3574,
							-4548,
							-4018,
							-4743,
							-6956
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38077,
					"arcs": [
						[
							-3349,
							9251,
							-8899,
							9252,
							-7766,
							-8271,
							9253
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48157,
					"arcs": [
						[
							-1567,
							9254,
							-3443,
							-3840,
							-8148
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31031,
					"arcs": [
						[
							-7770,
							9255,
							-4421,
							-8883,
							-6164,
							-228,
							9256,
							-1265,
							-8706,
							-3190
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51141,
					"arcs": [
						[
							-5441,
							-7376,
							9257,
							-3212,
							-7074,
							-6375
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8125,
					"arcs": [
						[
							-3380,
							-7748,
							-7312,
							-7321,
							-2777,
							-159,
							-1728
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24035,
					"arcs": [
						[
							-8780,
							-8045,
							-9078,
							-5822,
							9258
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12069,
					"arcs": [
						[
							-6627,
							-9014,
							-994,
							9259,
							-9056,
							-8031
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13053,
					"arcs": [
						[
							9260,
							-7784,
							9261,
							-8801,
							-4524
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13293,
					"arcs": [
						[
							-8272,
							-5007,
							-5480,
							9262,
							-5069,
							-7782,
							-8146
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37011,
					"arcs": [
						[
							-7867,
							-8225,
							-5225,
							-7198,
							-5573,
							-5195,
							-6372
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30077,
					"arcs": [
						[
							-2473,
							-367,
							-8453,
							-5401,
							-8982,
							-3996
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31169,
					"arcs": [
						[
							-3108,
							-5147,
							-8327,
							-5140
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42061,
					"arcs": [
						[
							-5328,
							-6890,
							-3708,
							-6544,
							-5945,
							-6573,
							-8940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51143,
					"arcs": [
						[
							9263,
							-7374,
							-3812,
							-7533,
							-7843,
							-5183,
							-6555,
							-5181,
							-9000
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22117,
					"arcs": [
						[
							9264,
							-8127,
							-3299,
							-4212,
							-9206,
							-9241
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51153,
					"arcs": [
						[
							-9178,
							-6186,
							9265,
							-2175,
							-7634
						],
						[
							-261,
							-6949
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40129,
					"arcs": [
						[
							9266,
							9267,
							-1685,
							-2676,
							-3222,
							-45
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19049,
					"arcs": [
						[
							-1469,
							-884,
							-1789,
							-1985,
							-1471
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26007,
					"arcs": [
						[
							-8560,
							9268,
							-8718,
							-2218
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18131,
					"arcs": [
						[
							-5173,
							9269,
							9270,
							-6772,
							-6642
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41059,
					"arcs": [
						[
							-3461,
							-9162,
							-4448,
							-4617,
							-3730,
							-4855,
							-4610
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55083,
					"arcs": [
						[
							-4453,
							9271,
							9272,
							-1927,
							-2271,
							-1043,
							9273
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39125,
					"arcs": [
						[
							-5757,
							-106,
							-9151,
							-8839
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39133,
					"arcs": [
						[
							-3741,
							-8034,
							-2476,
							-8106,
							-8023
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30027,
					"arcs": [
						[
							-4057,
							-9099,
							-8952,
							-9201,
							-9066,
							-3241,
							-3046,
							-7044
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48029,
					"arcs": [
						[
							-9195,
							-2408,
							-6233,
							-7863,
							-9152,
							-1066,
							-3238
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34015,
					"arcs": [
						[
							-6660,
							-5710,
							-5969,
							-6570,
							-6268,
							-6274,
							-8456
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42043,
					"arcs": [
						[
							-9074,
							-7187,
							-2535,
							-4623,
							-5713,
							-8296,
							-7076
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55067,
					"arcs": [
						[
							-2667,
							-4454,
							-9274,
							-1045,
							-2270,
							-1855,
							9274
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48155,
					"arcs": [
						[
							-6119,
							-8245,
							-779,
							-9086,
							-781,
							-6737
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26103,
					"arcs": [
						[
							-9147,
							9275,
							-4211,
							-8874,
							-3405,
							-9222,
							9276
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26153,
					"arcs": [
						[
							-2903,
							-8775,
							9277,
							-8875,
							-4209
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51193,
					"arcs": [
						[
							-9203,
							9278,
							-3457,
							-6549,
							-4360
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53027,
					"arcs": [
						[
							-9009,
							-9108,
							-8063,
							-7644,
							-8825,
							9279
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49029,
					"arcs": [
						[
							-5856,
							-976,
							-7081,
							-7023,
							-5356
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54037,
					"arcs": [
						[
							-6254,
							-9177,
							-7342,
							-8101
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5069,
					"arcs": [
						[
							-8478,
							-6493,
							-9109,
							-6507,
							-8539,
							-1385
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29177,
					"arcs": [
						[
							-2120,
							-2109,
							-9133,
							-6882,
							-2736,
							-3991
						]
					]
				},
				{
					"type": "Polygon",
					"id": 11001,
					"arcs": [
						[
							-3975,
							-6997,
							9280,
							-7227
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48095,
					"arcs": [
						[
							-1783,
							-8629,
							-8314,
							-3187,
							-1990
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19063,
					"arcs": [
						[
							-7031,
							-1616,
							-577,
							-3120,
							-9156
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21119,
					"arcs": [
						[
							-3935,
							-3153,
							-8751,
							-6960,
							-3881,
							-7326
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 55029,
					"arcs": [
						[
							[
								9281
							]
						],
						[
							[
								-1105,
								9282
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16029,
					"arcs": [
						[
							-8281,
							-6250,
							-5910,
							-6543,
							-7744,
							-8868
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41051,
					"arcs": [
						[
							-8161,
							-6102,
							-5316,
							-7991,
							-8230,
							9283
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26071,
					"arcs": [
						[
							-6907,
							-9148,
							-9277,
							-9221,
							-6467,
							-4450,
							-3475,
							-5888,
							-7314
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26081,
					"arcs": [
						[
							-1519,
							-355,
							-1493,
							-2457,
							-307,
							-4346,
							-3417
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51025,
					"arcs": [
						[
							9284,
							9285,
							-6631,
							-7704,
							-7803,
							-6033,
							-4503
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27051,
					"arcs": [
						[
							-8897,
							-2164,
							-361,
							-1813,
							9286
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17067,
					"arcs": [
						[
							-7838,
							-9093,
							-8004,
							-3023,
							-1188,
							-5285,
							-8408
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21017,
					"arcs": [
						[
							-3637,
							-9025,
							-8907,
							9287,
							-9181,
							-9164
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22017,
					"arcs": [
						[
							9288,
							-6858,
							-7103,
							-9122,
							-5497,
							-7047,
							-7213,
							-6114
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2050,
					"arcs": [
						[
							[
								-846,
								9289,
								-8705,
								-1866,
								-9089,
								-7614,
								9290,
								-7617,
								9291
							]
						],
						[
							[
								9292
							]
						],
						[
							[
								9293
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40135,
					"arcs": [
						[
							-242,
							-8860,
							-8197,
							-7363,
							-7584,
							-7547,
							-9149
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17069,
					"arcs": [
						[
							-339,
							-7211,
							-3959,
							9294,
							-6969,
							-8832
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12051,
					"arcs": [
						[
							-745,
							-7691,
							-2006,
							-5654,
							-6393
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13215,
					"arcs": [
						[
							-3795,
							-7785,
							-9261,
							-4523,
							-7697
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13251,
					"arcs": [
						[
							-7694,
							-6287,
							-9094,
							-8019,
							-9052,
							-485
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24009,
					"arcs": [
						[
							-6719,
							9295,
							9296,
							-9015,
							-6999
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27101,
					"arcs": [
						[
							-5502,
							-4285,
							9297,
							-7013,
							-460,
							-6052
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55119,
					"arcs": [
						[
							-679,
							9298,
							-1853,
							-2284,
							9299,
							-1641
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13285,
					"arcs": [
						[
							-2547,
							-870,
							-8147,
							-3792,
							-1711,
							-8892
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28117,
					"arcs": [
						[
							-2551,
							9300,
							-29,
							-3304,
							-5200,
							-5512
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4007,
					"arcs": [
						[
							-288,
							-5453,
							9301,
							-9041,
							-4982,
							-4889
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26039,
					"arcs": [
						[
							-1303,
							9302,
							-3135,
							-3220
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39091,
					"arcs": [
						[
							-2521,
							-1340,
							-902,
							-101,
							9303
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20025,
					"arcs": [
						[
							-3395,
							-4495,
							-593,
							-6679,
							-6620,
							-5901
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35037,
					"arcs": [
						[
							-8585,
							-7131,
							-9019,
							-2484,
							-1829,
							-8905,
							-4583,
							-7192,
							-653,
							-9072
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51111,
					"arcs": [
						[
							-8488,
							-9200,
							-6632,
							-9286,
							9304
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40045,
					"arcs": [
						[
							-6681,
							-3084,
							9305,
							-9267,
							-44,
							-4639,
							-6622
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18037,
					"arcs": [
						[
							-3516,
							-8567,
							-756,
							-3200,
							-8749,
							-5733,
							-8569,
							-6459
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6015,
					"arcs": [
						[
							9306,
							-1232,
							-3764,
							-5769,
							9307
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36063,
					"arcs": [
						[
							-4596,
							-4691,
							-6025,
							9308
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49027,
					"arcs": [
						[
							-9229,
							-9159,
							-8485,
							-4939,
							-4433,
							-8206
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1035,
					"arcs": [
						[
							-8538,
							-3753,
							-5165,
							-7682
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22099,
					"arcs": [
						[
							[
								-9218,
								-8341,
								-6579
							]
						],
						[
							[
								-9114,
								-8470,
								-9217,
								-6946,
								-7525
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36107,
					"arcs": [
						[
							-8912,
							-2040,
							-7576,
							-3286,
							-8433,
							9309
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48473,
					"arcs": [
						[
							-8308,
							-9075,
							-3444,
							-9255,
							-1566,
							-8581
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48395,
					"arcs": [
						[
							-1543,
							-1915,
							-6541,
							-4884,
							-8589,
							-9119
						]
					]
				},
				{
					"type": "Polygon",
					"id": 1025,
					"arcs": [
						[
							-9245,
							-2426,
							-7683,
							-146,
							-7788,
							-4520
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21147,
					"arcs": [
						[
							-8619,
							-6096,
							-5749,
							-6767,
							-8167,
							-8593
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26065,
					"arcs": [
						[
							-1464,
							-8807,
							-1296,
							-5485,
							-1474
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17187,
					"arcs": [
						[
							-7120,
							-3080,
							-7723,
							-3021,
							-8003
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37177,
					"arcs": [
						[
							9310,
							-7109,
							-6673
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17049,
					"arcs": [
						[
							9311,
							9312,
							-3308,
							-1271,
							-1527
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48129,
					"arcs": [
						[
							-943,
							-1452,
							-3146,
							-7010,
							-561
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48189,
					"arcs": [
						[
							-2438,
							-8261,
							9313,
							-2466,
							-8133
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2240,
					"arcs": [
						[
							-7726,
							9314,
							9315,
							-7621,
							-8703,
							-8658
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39033,
					"arcs": [
						[
							-7918,
							9316,
							-5248,
							9317,
							-4082,
							-2008
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20061,
					"arcs": [
						[
							-3917,
							-8235,
							9318,
							-2538,
							-5081
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48247,
					"arcs": [
						[
							9319,
							-668,
							-3115,
							-7919,
							-4515
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26161,
					"arcs": [
						[
							-1294,
							-8716,
							-9237,
							-3981,
							-545,
							-5486
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20035,
					"arcs": [
						[
							-2653,
							-8624,
							-2421,
							-7527,
							-7456,
							-6964
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5013,
					"arcs": [
						[
							9320,
							-8540,
							-4993,
							-6318,
							-7668
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40043,
					"arcs": [
						[
							-3083,
							-2464,
							-908,
							-1681,
							-9268,
							-9306
						]
					]
				},
				{
					"type": "Polygon",
					"id": 53043,
					"arcs": [
						[
							-8754,
							-6106,
							-8060,
							-8887,
							-6402,
							-4131,
							-5298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22115,
					"arcs": [
						[
							-3962,
							-3650,
							-4815,
							-77,
							-4652,
							-6122
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12123,
					"arcs": [
						[
							-7996,
							-7722,
							-7871,
							9321,
							-8157
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21057,
					"arcs": [
						[
							-9198,
							-1102,
							-8258,
							-4144,
							-3163,
							9322
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26009,
					"arcs": [
						[
							-8575,
							-1304,
							-3219,
							-9045,
							9323
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 22125,
					"arcs": [
						[
							[
								-5801,
								-8419,
								-8542,
								-9113,
								-5814
							]
						],
						[
							[
								-5816,
								-9112,
								-9247
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31099,
					"arcs": [
						[
							-520,
							-3264,
							-603,
							9324,
							-8925
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54097,
					"arcs": [
						[
							-2117,
							-6758,
							-7792,
							-2126,
							-4136
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54005,
					"arcs": [
						[
							-5676,
							-9213,
							-7605,
							-7607,
							-9189
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54035,
					"arcs": [
						[
							-5941,
							-7941,
							9325,
							-8758,
							-9211,
							-7596,
							-7587
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12045,
					"arcs": [
						[
							-8312,
							-8399,
							-4471,
							-8748,
							9326
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19065,
					"arcs": [
						[
							-9111,
							-2505,
							-8425,
							-7286,
							-5134
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17179,
					"arcs": [
						[
							-8437,
							-9059,
							-1724,
							-2660,
							-4908,
							-7725
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27059,
					"arcs": [
						[
							-2797,
							-7098,
							-758,
							-3104,
							-1873
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55113,
					"arcs": [
						[
							-9244,
							-8336,
							-681,
							-1640,
							-2590
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36001,
					"arcs": [
						[
							-2856,
							-4848,
							-5935,
							9327,
							-9154
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39149,
					"arcs": [
						[
							-2522,
							-9304,
							-100,
							-2964,
							-9092,
							-967
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6103,
					"arcs": [
						[
							-8292,
							-4973,
							-6487,
							-8276,
							9328,
							-2281
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8001,
					"arcs": [
						[
							-8597,
							-7950,
							-2648,
							-1725,
							-2377,
							-8599,
							-4997
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45077,
					"arcs": [
						[
							-6390,
							-8363,
							-6357,
							-5779
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28113,
					"arcs": [
						[
							-2631,
							-8128,
							-9265,
							-9240,
							-7476
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19161,
					"arcs": [
						[
							-1439,
							-1672,
							-342,
							-1692,
							-3255
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48065,
					"arcs": [
						[
							-9169,
							-278,
							-920,
							-944,
							-560,
							-537
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28105,
					"arcs": [
						[
							-4228,
							-7557,
							-5804,
							-2608,
							-7836,
							-7757
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41001,
					"arcs": [
						[
							-3728,
							-4616,
							-8583,
							-7171,
							-7459,
							-4856
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4009,
					"arcs": [
						[
							-5452,
							-5077,
							-8697,
							9329,
							-5455,
							-9042,
							-9302
						]
					]
				},
				{
					"type": "Polygon",
					"id": 22005,
					"arcs": [
						[
							-8685,
							-8710,
							-8979,
							-7981,
							-8339,
							-8468
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12095,
					"arcs": [
						[
							-996,
							9330,
							-4895,
							-9260
						]
					]
				},
				{
					"type": "Polygon",
					"id": 15001,
					"arcs": [
						[
							9331
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28003,
					"arcs": [
						[
							-9226,
							-7284,
							-6608,
							-30,
							-9301,
							-2550
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21169,
					"arcs": [
						[
							-9246,
							-4804,
							-9199,
							-9323,
							-3162,
							-4708
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12093,
					"arcs": [
						[
							-4898,
							-7359,
							-7362,
							-8403,
							-744,
							-7347
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55005,
					"arcs": [
						[
							-6166,
							-2591,
							-1643,
							9332,
							-1644,
							-4758
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36041,
					"arcs": [
						[
							-8555,
							-9096,
							-8858,
							-7515,
							-4851,
							9333,
							-8645
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31061,
					"arcs": [
						[
							-9325,
							-609,
							-9238,
							-6788,
							-470
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13157,
					"arcs": [
						[
							9334,
							-751,
							-8144,
							-2984,
							-2567
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30051,
					"arcs": [
						[
							-3602,
							9335,
							-5956,
							-4054,
							-9130
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20051,
					"arcs": [
						[
							9336,
							-8763,
							9337,
							-2224,
							9338,
							-2309
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48059,
					"arcs": [
						[
							-3327,
							-2916,
							-1457,
							-1906,
							-8628,
							9339
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8057,
					"arcs": [
						[
							-8394,
							-4737,
							-6457,
							-6235,
							-8084
						]
					]
				},
				{
					"type": "Polygon",
					"id": 4003,
					"arcs": [
						[
							-5456,
							-9330,
							-8696,
							-3613,
							9340,
							-3746
						]
					]
				},
				{
					"type": "Polygon",
					"id": 33009,
					"arcs": [
						[
							-7373,
							-3450,
							-736,
							-2557,
							-8185,
							-1308,
							-8961,
							-4574,
							-6098
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19137,
					"arcs": [
						[
							-7394,
							-3248,
							-3211,
							-5380,
							-4184
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36035,
					"arcs": [
						[
							-9334,
							-4850,
							-9216,
							-8646
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20135,
					"arcs": [
						[
							-5084,
							-2310,
							-9339,
							-2227,
							-1975,
							-3183,
							-4253,
							-349
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21103,
					"arcs": [
						[
							-8573,
							9341,
							9342,
							-3519,
							-3923,
							-9103
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42001,
					"arcs": [
						[
							-8297,
							-5717,
							-9225,
							-5882,
							-6547
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12009,
					"arcs": [
						[
							9343,
							-7356,
							-4896,
							-9331,
							-9013
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21041,
					"arcs": [
						[
							-5900,
							-5052,
							9344,
							-9342,
							-8572,
							-4717
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27163,
					"arcs": [
						[
							-8935,
							-760,
							-7097,
							-4759,
							-3733,
							-8626,
							-3276
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27027,
					"arcs": [
						[
							9345,
							-638,
							-2162,
							-8896,
							-9252,
							-3348
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13079,
					"arcs": [
						[
							-9263,
							-5479,
							-6074,
							-4900,
							-4701,
							-5070
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18015,
					"arcs": [
						[
							9346,
							-8850,
							-4781,
							-6776,
							-6774
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18033,
					"arcs": [
						[
							-8795,
							-7879,
							-5759,
							-8838,
							-1140
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18097,
					"arcs": [
						[
							-2256,
							-427,
							-1056,
							-4489,
							-8246,
							-8175,
							-8667
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37179,
					"arcs": [
						[
							-6040,
							-8934,
							-8958,
							-6029,
							-6599,
							-6108
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36015,
					"arcs": [
						[
							-3617,
							-4844,
							-8913,
							-9310,
							-8435,
							-1380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31183,
					"arcs": [
						[
							-5177,
							-1621,
							-5169,
							-3665,
							9347,
							-2051
						]
					]
				},
				{
					"type": "Polygon",
					"id": 23003,
					"arcs": [
						[
							-2636,
							-5500,
							-4265,
							-5444,
							9348
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18017,
					"arcs": [
						[
							-9271,
							9349,
							-8853,
							-8848,
							-9347,
							-6773
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29075,
					"arcs": [
						[
							-8093,
							-3599,
							-9190,
							-768,
							-639,
							-9061
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18105,
					"arcs": [
						[
							-8177,
							-220,
							-5692,
							-8293,
							-4787,
							-5138
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31097,
					"arcs": [
						[
							-8994,
							-4005,
							-3025,
							-6057
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6005,
					"arcs": [
						[
							-442,
							-5268,
							-3879,
							-9179,
							-9171
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31085,
					"arcs": [
						[
							-1320,
							-2732,
							-2192,
							9350,
							-7319,
							-7310
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39075,
					"arcs": [
						[
							9351,
							-8108,
							-5234,
							-8153,
							-1375,
							-8091
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5061,
					"arcs": [
						[
							-624,
							-4986,
							-6512,
							-8632,
							-8921
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27033,
					"arcs": [
						[
							-4284,
							-6152,
							-554,
							-7030,
							-7014,
							-9298
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21233,
					"arcs": [
						[
							-3957,
							-9193,
							-8664,
							9352,
							-2743,
							9353
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51540,
					"arcs": [
						[
							-9183
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48479,
					"arcs": [
						[
							-3736,
							-2994,
							-402,
							-669,
							-9320,
							-4514,
							9354
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55091,
					"arcs": [
						[
							-8625,
							-1646,
							-274,
							9355,
							-4348,
							-5520
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5145,
					"arcs": [
						[
							-3484,
							-6421,
							-9022,
							-5989,
							-7558,
							-6490,
							-7252
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45091,
					"arcs": [
						[
							-7316,
							-8936,
							-6042,
							-6112,
							-1586,
							-9005,
							-9158
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13197,
					"arcs": [
						[
							-7783,
							-5072,
							-8653,
							9356,
							-1841,
							-8802,
							-9262
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37041,
					"arcs": [
						[
							-8770,
							-8472,
							-8547,
							9357
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55011,
					"arcs": [
						[
							-273,
							-8474,
							-8426,
							-4349,
							-9356
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 8013,
					"arcs": [
						[
							[
								-8598
							]
						],
						[
							[
								-6456,
								-7952,
								-8596,
								-4995,
								-8731,
								-6237
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31037,
					"arcs": [
						[
							-7197,
							-98,
							-9235,
							-2770,
							-5570
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46007,
					"arcs": [
						[
							-7295,
							-4422,
							-9256,
							-7769
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5135,
					"arcs": [
						[
							-2315,
							-8878,
							-9135,
							-6506,
							-6418,
							-6424
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40097,
					"arcs": [
						[
							-4022,
							-9031,
							-239,
							-5598,
							-7979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55081,
					"arcs": [
						[
							-9077,
							-8797,
							-4922,
							-2831
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13071,
					"arcs": [
						[
							-6787,
							-8350,
							-9145,
							-9124,
							-7411,
							-3909
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38101,
					"arcs": [
						[
							-4513,
							-9175,
							-5249,
							-5593,
							-3065
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17035,
					"arcs": [
						[
							9358,
							-6812,
							-6816,
							-3309,
							-9313
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40077,
					"arcs": [
						[
							-7549,
							-7586,
							-2080,
							-7544
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54047,
					"arcs": [
						[
							-3469,
							-7609,
							-9215,
							-7829,
							-7684
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54015,
					"arcs": [
						[
							-8756,
							-4955,
							-4681,
							-7656,
							-9212
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5055,
					"arcs": [
						[
							-9134,
							-7079,
							-4560,
							-7189,
							-6503
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20167,
					"arcs": [
						[
							-8762,
							-2873,
							-2246,
							-4795,
							-2225,
							-9338
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55017,
					"arcs": [
						[
							-9333,
							-1642,
							-9300,
							-2287,
							-269,
							-1645
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55103,
					"arcs": [
						[
							-8494,
							-8895,
							-8385,
							-8813,
							-8799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16025,
					"arcs": [
						[
							9359,
							-4481,
							-3510,
							-1238
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39077,
					"arcs": [
						[
							-5629,
							-7717,
							-8092,
							-5245,
							-9317,
							-7917,
							-3653
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48379,
					"arcs": [
						[
							-5609,
							-9170,
							-7509,
							-2958
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12039,
					"arcs": [
						[
							-4537,
							-2160,
							9360,
							-4468,
							-3787
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19089,
					"arcs": [
						[
							-1664,
							-7022,
							-2501,
							-9110,
							-585
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48315,
					"arcs": [
						[
							-2046,
							-6859,
							-9289,
							-6113,
							-4123
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5047,
					"arcs": [
						[
							-8196,
							-9064,
							-5927,
							-8978,
							-7365
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29031,
					"arcs": [
						[
							-8910,
							-8924,
							-5664,
							-4555,
							-9071,
							-8451
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48265,
					"arcs": [
						[
							-2335,
							-397,
							-2410,
							-9194,
							-7848,
							-2939
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2130,
					"arcs": [
						[
							[
								9361
							]
						],
						[
							[
								9362
							]
						],
						[
							[
								-1353,
								9363,
								-292,
								9364
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51191,
					"arcs": [
						[
							-8962,
							-4333,
							-9032,
							-7864,
							-4040,
							-1401,
							-4039,
							-7141
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36051,
					"arcs": [
						[
							-4688,
							-5879,
							-4296,
							-3614,
							-2624,
							-8984
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42049,
					"arcs": [
						[
							9365,
							-9143,
							-1394,
							-7771,
							-4403
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48263,
					"arcs": [
						[
							-3116,
							-3236,
							-812,
							-3325,
							-2912
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13261,
					"arcs": [
						[
							-1842,
							-9357,
							-8652,
							-5066,
							-5042,
							-7897,
							-2597,
							-2815
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51510,
					"arcs": [
						[
							-7228,
							-9281,
							-6996,
							-6182
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31175,
					"arcs": [
						[
							-2052,
							-9348,
							-3668,
							-2961,
							-9250
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55075,
					"arcs": [
						[
							-6469,
							-9223,
							-3408,
							9366,
							-9272,
							-4452
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20163,
					"arcs": [
						[
							-6790,
							-9239,
							-8764,
							-9337,
							-2308,
							-3100
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20205,
					"arcs": [
						[
							-1444,
							-6821,
							9367,
							-6954,
							-8623
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13281,
					"arcs": [
						[
							-8193,
							-8856,
							-8067,
							-7436,
							-8130
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39089,
					"arcs": [
						[
							-6215,
							-1377,
							-8154,
							-1746,
							-1747,
							-8871,
							-8264
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42025,
					"arcs": [
						[
							-4098,
							-4412,
							-6952,
							-6878,
							-7186
						]
					]
				},
				{
					"type": "Polygon",
					"id": 30081,
					"arcs": [
						[
							-3998,
							-8983,
							-5404,
							-180,
							-1146,
							-2275
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39169,
					"arcs": [
						[
							9368,
							-8025,
							-8105,
							-9352,
							-8090
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54105,
					"arcs": [
						[
							-3828,
							-4957,
							-8755,
							-9326,
							-7940
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28055,
					"arcs": [
						[
							-3367,
							-5016,
							-5019,
							-5518,
							-8882,
							-8414
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39085,
					"arcs": [
						[
							9369,
							-4406,
							-3738,
							-9049
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48387,
					"arcs": [
						[
							-7465,
							-4357,
							-7162,
							-2043,
							-7455,
							-2031,
							-5365,
							-3848
						]
					]
				},
				{
					"type": "Polygon",
					"id": 54103,
					"arcs": [
						[
							-7640,
							-7638,
							-484,
							-9191,
							-2113,
							-7887,
							-3852,
							-8884
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36099,
					"arcs": [
						[
							-8356,
							-8566,
							-8911,
							-4842,
							-540,
							-4295
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13055,
					"arcs": [
						[
							-5660,
							-5668,
							-8594,
							-5161
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51089,
					"arcs": [
						[
							-7375,
							-9264,
							-8999,
							-3213,
							-9258
						],
						[
							-1423
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28121,
					"arcs": [
						[
							-8545,
							-8078,
							-423,
							-2573,
							-3661
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 1097,
					"arcs": [
						[
							[
								9370
							]
						],
						[
							[
								-7789,
								-144,
								9371,
								-5523,
								-834,
								-7650
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26149,
					"arcs": [
						[
							-1496,
							-7299,
							-3951,
							9372,
							-7730
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49009,
					"arcs": [
						[
							-7280,
							-3770,
							-7973,
							-7922,
							-979
						]
					]
				},
				{
					"type": "Polygon",
					"id": 36039,
					"arcs": [
						[
							-9155,
							-9328,
							-8784,
							-6352,
							-7519
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31171,
					"arcs": [
						[
							-9257,
							-233,
							-4062,
							-2430,
							-1266
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31115,
					"arcs": [
						[
							-6163,
							-4279,
							-5178,
							-2054,
							-9249,
							-230
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46079,
					"arcs": [
						[
							-3070,
							9373,
							-8847,
							-4726,
							9374,
							-1062
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28017,
					"arcs": [
						[
							-9248,
							-5202,
							-5794,
							-7556,
							-4226,
							-2145
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31013,
					"arcs": [
						[
							-1263,
							-3192,
							-138,
							-1344,
							-7194
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13139,
					"arcs": [
						[
							-9102,
							-8069,
							-7438,
							-8066,
							-752,
							-9335,
							-1848,
							-2028
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48237,
					"arcs": [
						[
							-125,
							-6205,
							-8353,
							-6736,
							-1751,
							-1033,
							-1078
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20133,
					"arcs": [
						[
							-1211,
							-6734,
							-3575,
							-9251,
							-6955,
							-9368
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8017,
					"arcs": [
						[
							-163,
							-3393,
							9375,
							-2395,
							9376
						]
					]
				},
				{
					"type": "Polygon",
					"id": 19041,
					"arcs": [
						[
							-9157,
							-3122,
							-1437,
							-1151
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17021,
					"arcs": [
						[
							-4775,
							9377,
							-874,
							-8439
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18035,
					"arcs": [
						[
							-500,
							9378,
							-3035,
							-7660,
							-2971,
							-8351
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38045,
					"arcs": [
						[
							-8823,
							-1362,
							9379,
							-6129,
							-3400,
							-2261
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26133,
					"arcs": [
						[
							-264,
							-3139,
							-2202,
							-629,
							-1499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 5039,
					"arcs": [
						[
							-209,
							-1387,
							-8541,
							-9321,
							-7667,
							-7068
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16013,
					"arcs": [
						[
							-3338,
							-8088,
							-8280,
							9380,
							-6383,
							-493,
							-4482,
							-9360,
							-1237
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21107,
					"arcs": [
						[
							-9353,
							-8663,
							-3933,
							-6778,
							-2744
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48463,
					"arcs": [
						[
							-2941,
							-7850,
							-9196,
							-3239,
							9381,
							-8964
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55141,
					"arcs": [
						[
							-2285,
							-1857,
							-1042,
							-8288,
							-9076,
							-2829
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31005,
					"arcs": [
						[
							-8707,
							-1268,
							-2434,
							-8827,
							-1390
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20071,
					"arcs": [
						[
							-9376,
							-3392,
							9382,
							-2440,
							9383,
							-2396
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21049,
					"arcs": [
						[
							-9288,
							-8906,
							-8398,
							-6083,
							-6782,
							-9182
						]
					]
				},
				{
					"type": "Polygon",
					"id": 47149,
					"arcs": [
						[
							-8348,
							-8651,
							-5370,
							-5339,
							-6763,
							-4866,
							-7224
						]
					]
				},
				{
					"type": "Polygon",
					"id": 24037,
					"arcs": [
						[
							-9297,
							9384,
							-9016
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37191,
					"arcs": [
						[
							-3126,
							-7064,
							-4076,
							-5586,
							-6827,
							-5191
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37053,
					"arcs": [
						[
							-8805,
							-3195,
							9385,
							-6341,
							9386,
							-8365
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8099,
					"arcs": [
						[
							-2397,
							-9384,
							-2443,
							-5474,
							-2925,
							-2904
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17005,
					"arcs": [
						[
							-877,
							-1530,
							-2639,
							-8252
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18009,
					"arcs": [
						[
							9387,
							-3030,
							-9379,
							-499
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26057,
					"arcs": [
						[
							-2429,
							-8722,
							-8728,
							-8808,
							-1462,
							-353
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31185,
					"arcs": [
						[
							-5171,
							-5209,
							-3107,
							-2723,
							-6161
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48153,
					"arcs": [
						[
							-8260,
							-7011,
							-1835,
							-2782,
							-9314
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40081,
					"arcs": [
						[
							-8854,
							-5152,
							-1676,
							-769,
							-4853,
							-281
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6019,
					"arcs": [
						[
							-4766,
							-8745,
							-9139,
							9388,
							-332,
							-1430,
							-1669,
							9389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27153,
					"arcs": [
						[
							-1514,
							-7877,
							-5233,
							-4818,
							-358,
							-2163
						]
					]
				},
				{
					"type": "Polygon",
					"id": 31087,
					"arcs": [
						[
							1734,
							-7320,
							-9351,
							-2191,
							-468,
							-1736,
							-1735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38095,
					"arcs": [
						[
							9390,
							-2236,
							-4301,
							-7937,
							-3142,
							-8324
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17087,
					"arcs": [
						[
							-8922,
							-2851,
							-8834,
							9391,
							-9081
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20203,
					"arcs": [
						[
							-3391,
							-6090,
							-76,
							-414,
							-2441,
							-9383
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27107,
					"arcs": [
						[
							-2304,
							-9242,
							-632,
							-9346,
							-3347,
							-3625
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48149,
					"arcs": [
						[
							-1926,
							-9132,
							-8582,
							-1570,
							-4318,
							-1790,
							-8863,
							-1093
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46011,
					"arcs": [
						[
							-1591,
							-842,
							-1614,
							-8846,
							-9374,
							-3069
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46067,
					"arcs": [
						[
							-8959,
							-2800,
							9392,
							-528,
							-6437,
							-6008,
							-7182,
							-2968
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48025,
					"arcs": [
						[
							-4120,
							-8865,
							-6195,
							-3832,
							-4128
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42031,
					"arcs": [
						[
							-8525,
							9393,
							-6563,
							9394,
							-7324
						]
					]
				},
				{
					"type": "Polygon",
					"id": 29027,
					"arcs": [
						[
							-1627,
							-4825,
							-6891,
							-1215,
							-8180
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21187,
					"arcs": [
						[
							-9345,
							-5051,
							-6092,
							-9163,
							-3520,
							-9343
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41067,
					"arcs": [
						[
							-8162,
							-9284,
							-8229,
							-4307,
							-6747
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26105,
					"arcs": [
						[
							-2944,
							-1501,
							-828,
							9395
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13315,
					"arcs": [
						[
							-5040,
							-7410,
							-9055,
							-7775,
							-3829,
							-7416,
							-7896
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8073,
					"arcs": [
						[
							-7910,
							-2378,
							-1729,
							-164,
							-9377,
							-2394,
							-2707,
							-8691
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51117,
					"arcs": [
						[
							-8489,
							-9305,
							-9285,
							-4502,
							-3090,
							-6501,
							-7845
						]
					]
				},
				{
					"type": "Polygon",
					"id": 8067,
					"arcs": [
						[
							-5003,
							-6307,
							-5585,
							-8804,
							-9176
						]
					]
				},
				{
					"type": "Polygon",
					"id": 46087,
					"arcs": [
						[
							-1063,
							-9375,
							-4729,
							-523,
							-9393,
							-2799
						]
					]
				},
				{
					"type": "Polygon",
					"id": 55069,
					"arcs": [
						[
							-678,
							-2668,
							-9275,
							-1854,
							-9299
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20181,
					"arcs": [
						[
							-2776,
							-1739,
							-2518,
							-6089,
							-3389,
							-161
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13289,
					"arcs": [
						[
							-6003,
							-3890,
							-5093,
							-4902,
							-6073
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42019,
					"arcs": [
						[
							-4249,
							-7325,
							-9395,
							-6562,
							-8900,
							-7763,
							-9228
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6105,
					"arcs": [
						[
							-3762,
							-2282,
							-9329,
							-8275,
							-5770
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13113,
					"arcs": [
						[
							-6071,
							-8263,
							-7448,
							-867
						]
					]
				},
				{
					"type": "Polygon",
					"id": 2290,
					"arcs": [
						[
							-2511,
							-1967,
							-2208,
							9396,
							-9315,
							-7728,
							-8660,
							-8702,
							-9290,
							-845
						]
					]
				},
				{
					"type": "Polygon",
					"id": 28133,
					"arcs": [
						[
							-7553,
							-7936,
							-4053,
							-9084,
							-7793,
							-5013
						]
					]
				},
				{
					"type": "Polygon",
					"id": 12073,
					"arcs": [
						[
							-9361,
							-2159,
							-7414,
							-8155,
							-4167,
							-4469
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26135,
					"arcs": [
						[
							-2219,
							-8720,
							-8723,
							-9303
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18133,
					"arcs": [
						[
							9397,
							-8621,
							-8668,
							-8178,
							-5136,
							-6486
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48115,
					"arcs": [
						[
							-3019,
							-2371,
							-2358,
							-2344,
							-1919
						]
					]
				},
				{
					"type": "Polygon",
					"id": 49005,
					"arcs": [
						[
							-3800,
							-7745,
							-5853,
							-5354,
							-7855
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45047,
					"arcs": [
						[
							-5260,
							-7232,
							-9136,
							-8974,
							-9026,
							-7336
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45061,
					"arcs": [
						[
							-8226,
							-6657,
							-5337,
							9398
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45075,
					"arcs": [
						[
							-4107,
							-8973,
							-6613,
							-8520,
							-6217,
							-7705,
							-6369,
							-5696,
							-8943
						]
					]
				},
				{
					"type": "Polygon",
					"id": 40053,
					"arcs": [
						[
							-1797,
							-6966,
							-7458,
							-1546,
							-432
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18165,
					"arcs": [
						[
							-2196,
							-4794,
							9399,
							-301,
							-7209,
							-8676
						]
					]
				},
				{
					"type": "Polygon",
					"id": 35055,
					"arcs": [
						[
							-3778,
							-8527,
							-6128,
							-8987,
							-4588
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21055,
					"arcs": [
						[
							-9295,
							-3958,
							-9354,
							-2748,
							-3525,
							-6970
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18179,
					"arcs": [
						[
							-2239,
							-8841,
							-8674,
							-3031,
							-9388,
							-498
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17121,
					"arcs": [
						[
							-2640,
							-1528,
							-1274,
							-9150,
							-9023,
							-8790
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48441,
					"arcs": [
						[
							-3328,
							-9340,
							-8627,
							-1781,
							-3323
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27035,
					"arcs": [
						[
							-7876,
							-8497,
							-1877,
							-5231
						]
					]
				},
				{
					"type": "Polygon",
					"id": 38073,
					"arcs": [
						[
							-1361,
							-3350,
							-9254,
							-8270,
							-6130,
							-9380
						]
					]
				},
				{
					"type": "Polygon",
					"id": 48507,
					"arcs": [
						[
							-9382,
							-1358,
							-2993,
							-3735
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18169,
					"arcs": [
						[
							-1137,
							-2876,
							-2240,
							-496,
							-8852,
							9400
						]
					]
				},
				{
					"type": "Polygon",
					"id": 41015,
					"arcs": [
						[
							-8777,
							-8811,
							-1233,
							-9307,
							9401
						]
					]
				},
				{
					"type": "Polygon",
					"id": 26159,
					"arcs": [
						[
							-310,
							-1497,
							-7729,
							-6412,
							9402
						]
					]
				},
				{
					"type": "Polygon",
					"id": 34031,
					"arcs": [
						[
							-7610,
							-5542,
							-191,
							-8734,
							-7427,
							-3676
						]
					]
				},
				{
					"type": "Polygon",
					"id": 16077,
					"arcs": [
						[
							-8283,
							-8869,
							-3797,
							-6384,
							-9381
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42065,
					"arcs": [
						[
							-9394,
							-8524,
							-806,
							-7826,
							-6791,
							-6564
						]
					]
				},
				{
					"type": "MultiPolygon",
					"id": 2150,
					"arcs": [
						[
							[
								9403
							]
						],
						[
							[
								9404
							]
						],
						[
							[
								9405
							]
						],
						[
							[
								9406
							]
						],
						[
							[
								-1869,
								9407,
								-9090
							]
						]
					]
				},
				{
					"type": "Polygon",
					"id": 45085,
					"arcs": [
						[
							-8227,
							-9399,
							-5336,
							-6610,
							-8972,
							-9118
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18121,
					"arcs": [
						[
							-4793,
							-8622,
							-9398,
							-6485,
							-302,
							-9400
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39103,
					"arcs": [
						[
							-7715,
							-9050,
							-8026,
							-9369,
							-8089
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13025,
					"arcs": [
						[
							-8269,
							-3507,
							-8030,
							-8638,
							-9068,
							-3546
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6047,
					"arcs": [
						[
							-9227,
							-4767,
							-4763,
							-9390,
							-1668,
							-3757
						]
					]
				},
				{
					"type": "Polygon",
					"id": 39117,
					"arcs": [
						[
							-4083,
							-9318,
							-5247,
							-1372,
							-6214
						]
					]
				},
				{
					"type": "Polygon",
					"id": 13051,
					"arcs": [
						[
							-8017,
							-8360,
							9408,
							-6534
						]
					]
				},
				{
					"type": "Polygon",
					"id": 6107,
					"arcs": [
						[
							-9138,
							-9173,
							-333,
							-9389
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17127,
					"arcs": [
						[
							-9392,
							-8833,
							-6967,
							-7017,
							-9082
						]
					]
				},
				{
					"type": "Polygon",
					"id": 17173,
					"arcs": [
						[
							-9378,
							-4774,
							-6802,
							-6813,
							-9359,
							-9312,
							-1526,
							-875
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37147,
					"arcs": [
						[
							-3124,
							-3692,
							-8126,
							-4390,
							-8236,
							-4072,
							-7063
						]
					]
				},
				{
					"type": "Polygon",
					"id": 51115,
					"arcs": [
						[
							-9234,
							9409,
							-8212
						]
					]
				},
				{
					"type": "Polygon",
					"id": 27155,
					"arcs": [
						[
							-9253,
							-8898,
							-9287,
							-1816,
							-8986,
							-7767
						]
					]
				},
				{
					"type": "Polygon",
					"id": 44007,
					"arcs": [
						[
							-6479,
							-6701,
							-6639,
							-6007,
							9410,
							-474,
							-3782
						]
					]
				},
				{
					"type": "Polygon",
					"id": 20197,
					"arcs": [
						[
							-9319,
							-8234,
							-9232,
							-8844,
							-9035,
							-66,
							-2539
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18039,
					"arcs": [
						[
							-7731,
							-9373,
							-3954,
							-1143,
							-1134,
							-1123,
							-3252
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18005,
					"arcs": [
						[
							-8247,
							-4490,
							-1006,
							-5080,
							-5689,
							-218
						]
					]
				},
				{
					"type": "Polygon",
					"id": 21137,
					"arcs": [
						[
							-4981,
							-9174,
							-5090,
							-8618,
							-4693
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42023,
					"arcs": [
						[
							-6885,
							-728,
							-6750,
							-7827,
							-804
						]
					]
				},
				{
					"type": "Polygon",
					"id": 18049,
					"arcs": [
						[
							-1125,
							-1138,
							-9401,
							-8851,
							-9350,
							-9270
						]
					]
				},
				{
					"type": "Polygon",
					"id": 42091,
					"arcs": [
						[
							-4862,
							-6880,
							-6523,
							-5706,
							-6659,
							-8502
						]
					]
				},
				{
					"type": "Polygon",
					"id": 37159,
					"arcs": [
						[
							-8295,
							-7264,
							-8957,
							-8932,
							-6559
						]
					]
				}
			]
		},
		"states": {
			"type": "GeometryCollection",
			"geometries": [
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								2311,
								2312,
								2313,
								-8878,
								-8877,
								-7245,
								7077,
								-3342,
								-4561,
								-4560,
								-4566,
								-4565,
								8387,
								-7151,
								-7237,
								-6913,
								-6897,
								-6914,
								-6915,
								-6901,
								8115,
								-7833,
								3446,
								7559,
								7560,
								-7551,
								-7550,
								3364,
								3365,
								3366,
								3367,
								3368,
								3369,
								-3576,
								6319,
								-3582,
								-3581,
								5266,
								-7288,
								9082,
								7101,
								7102,
								-6857,
								7103,
								6513,
								-4355,
								-4354,
								-4353,
								-7585,
								7351,
								7366,
								7362,
								8196,
								8197,
								8217,
								8372,
								8373,
								-6886,
								-1633,
								-1632,
								-6284,
								-2415,
								-2414,
								0,
								1,
								-2375
							]
						]
					],
					"id": "05"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								6239,
								6452,
								4160,
								6451,
								3753,
								5767,
								6989,
								5951,
								8381,
								7699,
								8382,
								8278,
								5771,
								9307,
								9306,
								-1232,
								-1231,
								3759,
								3760,
								944,
								945,
								946,
								-8835,
								1687,
								2267,
								1697,
								1698,
								1699,
								439,
								5268,
								-5876,
								-7387,
								8741,
								8742,
								-8782,
								-8680,
								-8926,
								699,
								700,
								-152,
								-151,
								-157,
								8410,
								8411,
								8189,
								9010,
								8969,
								3374,
								2897,
								9,
								1430
							]
						],
						[
							[
								2892
							]
						],
						[
							[
								2893
							]
						],
						[
							[
								2894
							]
						],
						[
							[
								3371
							]
						],
						[
							[
								3375
							]
						],
						[
							[
								8965
							]
						],
						[
							[
								8966
							]
						]
					],
					"id": "06"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								7205,
								-6794,
								6258,
								1798,
								1799,
								572,
								573,
								-2659,
								-2197,
								8675,
								7208,
								-300,
								-305,
								-5377,
								-5379,
								-3945,
								2753,
								-3950,
								5664,
								6322,
								6323,
								-6650,
								-3960,
								-3959,
								9294,
								-6969,
								-6968,
								-6967,
								-7017,
								-7016,
								-5034,
								-5037,
								5662,
								-4556,
								5663,
								8923,
								-8909,
								3805,
								-8908,
								-4240,
								-4239,
								8991,
								-4553,
								-4552,
								-8901,
								8253,
								-4550,
								8254,
								6644,
								5845,
								5846,
								5847,
								-6054,
								-6974,
								8458,
								5286,
								5287,
								-8408,
								-7838,
								-9093,
								8004,
								8001,
								7121,
								7122,
								4179,
								4174,
								4175,
								4176,
								-6263,
								-6262,
								-7752,
								5897,
								5898,
								5894,
								5895,
								4542,
								4537,
								5562,
								5563,
								3087,
								-1938,
								10,
								11,
								-3857,
								6796
							]
						]
					],
					"id": "17"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								7444,
								-5866,
								7445,
								7446,
								5739,
								5740,
								-7813,
								-7812,
								-3992,
								-2734,
								-2739,
								3566,
								6449,
								6450,
								1200,
								-710,
								-716,
								-715,
								3570,
								3571,
								4545,
								-3594,
								4546,
								-4019,
								-4018,
								-4743,
								-4742,
								-3630,
								2418,
								2419,
								-7527,
								-7456,
								6964,
								6965,
								1796,
								-431,
								-430,
								707,
								590,
								591,
								-6679,
								-6620,
								5901,
								2709,
								2710,
								173,
								-4604,
								5570,
								-2927,
								-2926,
								5473,
								2442,
								9383,
								-2396,
								-9376,
								3392,
								-162,
								-161,
								-160,
								2776,
								2774,
								1733,
								1734,
								1735,
								-467,
								-466,
								5385,
								822,
								-472,
								-471,
								6787,
								9237,
								-608,
								-607,
								-5142,
								-5141,
								8326,
								-5149,
								-6059,
								-6058,
								-3028,
								17,
								18,
								6575
							]
						]
					],
					"id": "20"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-3087,
								-2089,
								-2092,
								-2166,
								-2170,
								2609,
								2689,
								599,
								600,
								312,
								4215,
								4216,
								7648,
								7649,
								833,
								5522,
								5523,
								7653,
								8075,
								8076,
								4214,
								4211,
								3298,
								8126,
								-9265,
								-9240,
								7476,
								-595,
								7477,
								5799,
								5800,
								5801,
								-5813,
								7472,
								3988,
								7554,
								-7570,
								-5726,
								-7569,
								-5729,
								-8415,
								-8414,
								-3367,
								-3366,
								-3365,
								7549,
								7550,
								-7561,
								-7560,
								-3447,
								7832,
								-8116,
								-6900,
								-6899,
								-4627,
								-4626,
								5791,
								2548,
								-9226,
								-7284,
								-6608,
								23,
								24,
								25,
								26,
								3304,
								-3085
							]
						],
						[
							[
								5520
							]
						],
						[
							[
								5521
							]
						],
						[
							[
								7651
							]
						]
					],
					"id": "28"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								34,
								5939,
								5940,
								5941,
								-7590,
								-7636,
								7242,
								-4752,
								7243,
								-5750,
								5631,
								5632,
								-5904,
								8131,
								-8750,
								-5719,
								-5718,
								-3056,
								-9140,
								7815,
								-4651,
								-5387,
								-3555,
								-2619,
								-167,
								-166,
								2810,
								-4182,
								-7659,
								-3033,
								968,
								963,
								-8673,
								-8840,
								-8839,
								5757,
								5758,
								7878,
								7879,
								-6832,
								-6831,
								-548,
								-547,
								-3984,
								6137,
								322,
								5626,
								7712,
								9047,
								9369,
								4401,
								4402,
								4403,
								-7772,
								-4251,
								2477,
								2478,
								8272,
								-7761,
								8273,
								8123,
								-7578,
								-2764,
								-2768,
								7580,
								-7641,
								8883,
								-3851,
								-3855,
								7678,
								7679
							]
						],
						[
							[
								320
							]
						]
					],
					"id": "39"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								1828,
								2483,
								2484,
								2713,
								2711,
								3258,
								3259,
								-4607,
								-4606,
								4634,
								4637,
								4638,
								43,
								44,
								3221,
								-2681,
								1447,
								1448,
								3166,
								6116,
								-5705,
								-5704,
								8243,
								5785,
								-4027,
								-4026,
								6202,
								8351,
								-7956,
								4428,
								-7955,
								-7536,
								-7671,
								-7670,
								3845,
								3846,
								-7465,
								-4357,
								-4356,
								-6514,
								-7104,
								6856,
								6857,
								-9289,
								6113,
								7212,
								-7049,
								-7048,
								-3965,
								-3964,
								-3963,
								6121,
								-4654,
								-4197,
								-4196,
								-7461,
								6920,
								6921,
								3433,
								7248,
								3435,
								3439,
								7250,
								3841,
								7249,
								3843,
								7998,
								7966,
								6426,
								9125,
								6309,
								6012,
								8734,
								8813,
								8736,
								6014,
								6310,
								9126,
								6424,
								7967,
								6198,
								6428,
								7964,
								6430,
								6200,
								6431,
								3834,
								9127,
								6312,
								6016,
								8737,
								8814,
								8531,
								7920,
								4516,
								9354,
								3736,
								8964,
								4641,
								5849,
								8997,
								7841,
								8534,
								9019,
								-4376,
								-8796,
								8532,
								1457,
								-614,
								-613,
								-612,
								8560,
								-8752,
								8361,
								1921,
								2024,
								1194,
								1195,
								6445,
								-4585,
								37,
								-4584,
								8904
							]
						]
					],
					"id": "48"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								2165,
								2091,
								2088,
								3086,
								3084,
								-3305,
								-27,
								-26,
								-25,
								5157,
								5153,
								5154,
								-4048,
								2695,
								2696,
								-7573,
								-8528,
								4153,
								4154,
								4155,
								5158,
								5159,
								5160,
								8593,
								-5671,
								-6714,
								-6713,
								-954,
								-7799,
								-7798,
								-2548,
								8891,
								1710,
								1711,
								-3796,
								7696,
								4522,
								4523,
								4524,
								8711,
								-8152,
								-822,
								-821,
								-3550,
								3359,
								3360,
								3361,
								3175,
								3176,
								3177,
								3749,
								3750,
								5165,
								5166,
								-3007,
								148,
								149,
								9371,
								-5523,
								-834,
								-7650,
								-7649,
								-4217,
								-4216,
								-313,
								-601,
								-600,
								-2690,
								-2610,
								2169
							]
						],
						[
							[
								9370
							]
						]
					],
					"id": "01"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-5898,
								7751,
								6261,
								6262,
								-4177,
								-4176,
								-4175,
								-4180,
								-7123,
								-7122,
								-8002,
								-8005,
								9092,
								-7837,
								5384,
								-2834,
								1703,
								1704,
								3180,
								3181,
								1057,
								1058,
								3562,
								3563,
								1155,
								1156,
								2264,
								2265,
								5380,
								5381,
								8255,
								8256,
								-5959,
								4185,
								4186,
								-7254,
								-4223,
								7394,
								-8995,
								-8285,
								-8284,
								-8139,
								6861,
								-6497,
								6862,
								7846,
								5642,
								-4035,
								-4034,
								-4728,
								-462,
								5639,
								2170,
								2171,
								-7032,
								-7031,
								-1616,
								577,
								578,
								6821,
								6822,
								1487,
								1488,
								583,
								-1664,
								-7022,
								2501,
								2502,
								8419,
								8420,
								8421,
								8423,
								-8387,
								6447,
								-5899
							]
						]
					],
					"id": "19"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								3962,
								3963,
								3964,
								7047,
								7048,
								-7213,
								-6114,
								9288,
								-6858,
								-7103,
								-7102,
								-9083,
								7287,
								-5267,
								3580,
								3581,
								-6320,
								3575,
								-3370,
								-3369,
								-3368,
								8413,
								8414,
								5728,
								7568,
								5725,
								7569,
								-7555,
								-3989,
								-7473,
								5812,
								-5802,
								-5801,
								-5800,
								-7478,
								594,
								-7477,
								9239,
								9264,
								-8127,
								-3299,
								-4212,
								-4215,
								-8077,
								9206,
								8606,
								8640,
								5722,
								8512,
								8766,
								7988,
								6581,
								9218,
								8301,
								7463,
								-6921,
								7460,
								4195,
								4196,
								4653,
								-6122
							]
						],
						[
							[
								7984
							]
						],
						[
							[
								7985
							]
						],
						[
							[
								8638
							]
						],
						[
							[
								8639
							]
						],
						[
							[
								8764
							]
						],
						[
							[
								9219
							]
						]
					],
					"id": "22"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-6822,
								-579,
								-578,
								1615,
								7030,
								7031,
								-2172,
								-2171,
								-5640,
								461,
								462,
								6052,
								1613,
								-841,
								-840,
								-839,
								6840,
								-7093,
								-7768,
								-7767,
								-9253,
								8898,
								-9252,
								-3348,
								-3347,
								-3625,
								2304,
								2305,
								3723,
								3724,
								3725,
								6744,
								6742,
								8992,
								4663,
								7928,
								2755,
								8051,
								3132,
								8052,
								2757,
								2758,
								6049,
								5891,
								5892,
								-6167,
								-4760,
								-4759,
								-3733,
								-8626,
								3276,
								5518,
								5519,
								4347,
								4348,
								8425,
								8426,
								-4925,
								-4924,
								8632,
								-8420,
								-2503,
								-2502,
								7021,
								1663,
								-584,
								-1489,
								-1488,
								-6823
							]
						]
					],
					"id": "27"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-2313,
								-2312,
								2374,
								-2,
								-1,
								2413,
								2414,
								6283,
								1631,
								1632,
								6885,
								6886,
								6887,
								3596,
								3593,
								-4546,
								-3572,
								-3571,
								714,
								715,
								709,
								-1201,
								-6451,
								-6450,
								-3567,
								2738,
								2733,
								3991,
								7811,
								7812,
								-5741,
								-5740,
								-7447,
								-7446,
								5865,
								5866,
								-4003,
								-4002,
								8428,
								-8256,
								-5382,
								-5381,
								-2266,
								-2265,
								-1157,
								-1156,
								-3564,
								-3563,
								-1059,
								-1058,
								-3182,
								-3181,
								-1705,
								-1704,
								2833,
								-5385,
								7836,
								7837,
								8407,
								-5288,
								-5287,
								-8459,
								6973,
								6053,
								-5848,
								-5847,
								-5846,
								-6645,
								-8255,
								4549,
								-8254,
								8900,
								4551,
								4552,
								-8992,
								4238,
								4239,
								8907,
								-3806,
								8908,
								-8924,
								-5664,
								4555,
								-5663,
								-5036,
								-4647,
								-6806,
								6942,
								5806,
								5807,
								5808,
								5809,
								9017,
								-7146,
								-8388,
								4564,
								4565,
								4559,
								4560,
								3341,
								-7078,
								7244,
								8876,
								8877,
								-2314
							]
						]
					],
					"id": "29"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-1734,
								-2775,
								7320,
								7311,
								7308,
								1321,
								1322,
								7419,
								2683,
								2684,
								-3377,
								6759,
								6760,
								7301,
								-6170,
								1345,
								-6169,
								7194,
								-4882,
								1259,
								1260,
								3188,
								-7770,
								9255,
								-4421,
								-6616,
								-3427,
								-3426,
								6059,
								-7183,
								-6010,
								-6439,
								6062,
								6063,
								4567,
								4568,
								6495,
								6496,
								-6862,
								8138,
								8283,
								8284,
								8994,
								-7395,
								4222,
								7253,
								-4187,
								-4186,
								5958,
								-8257,
								-8429,
								4001,
								4002,
								-5867,
								-7445,
								-6576,
								-19,
								-18,
								3027,
								6057,
								6058,
								5148,
								-8327,
								5140,
								5141,
								606,
								607,
								-9238,
								-6788,
								470,
								471,
								-823,
								-5386,
								465,
								466,
								-1736
							]
						]
					],
					"id": "31"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								3001,
								3002,
								3003,
								284,
								285,
								-1116,
								-1115,
								5074,
								-3358,
								-3387,
								-3156,
								-3160,
								-7129,
								-3609,
								-3613,
								9340,
								3746,
								5456,
								9196,
								-8411,
								156,
								150,
								151,
								-701,
								3005
							]
						]
					],
					"id": "04"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								159,
								160,
								161,
								-3393,
								9375,
								2395,
								-9384,
								-2443,
								-5474,
								2925,
								2926,
								2927,
								2928,
								5827,
								5828,
								-6124,
								8526,
								3777,
								3778,
								-4587,
								5583,
								-8804,
								-8803,
								-1114,
								-1113,
								-1112,
								-1111,
								3768,
								570,
								564,
								-7974,
								3774,
								3769,
								3770,
								3771,
								8082,
								-8394,
								-4737,
								-4736,
								6453,
								7948,
								-6760,
								3376,
								-2685,
								-2684,
								-7420,
								-1323,
								-1322,
								-7309,
								-7312,
								-7321,
								-2777
							]
						]
					],
					"id": "08"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								165,
								166,
								2618,
								3554,
								3555,
								-5390,
								-5389,
								-5053,
								5899,
								4716,
								4717,
								5024,
								5025,
								-4189,
								-4188,
								-4192,
								-959,
								5636,
								5637,
								-5753,
								5734,
								5735,
								5736,
								8569,
								8570,
								6460,
								6461,
								8047,
								6648,
								-3955,
								6649,
								-6324,
								-6323,
								-5665,
								3949,
								-2754,
								3944,
								5378,
								5376,
								304,
								299,
								-7209,
								-8676,
								2196,
								2658,
								-574,
								-573,
								-1800,
								-1799,
								-6259,
								6793,
								6794,
								8653,
								3557,
								3558,
								3249,
								3250,
								-7731,
								-9373,
								3950,
								3951,
								-7298,
								-6833,
								-7880,
								-7879,
								-5759,
								-5758,
								8838,
								8839,
								8672,
								-964,
								-969,
								3032,
								7658,
								4181,
								-2811
							]
						]
					],
					"id": "18"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								9043,
								9323,
								8575,
								4662,
								5788,
								8558,
								9268,
								8718,
								2846,
								9229,
								4242,
								9165,
								8948,
								7959,
								8758,
								4204,
								9235,
								3982,
								3983,
								546,
								547,
								6830,
								6831,
								6832,
								7297,
								-3952,
								-3951,
								9372,
								7730,
								-3251,
								-3250,
								-3559,
								6410,
								9402,
								310,
								4346,
								3418,
								830,
								9395,
								2944,
								178,
								5449
							]
						],
						[
							[
								2899,
								9140,
								8773,
								9277,
								8875,
								3406,
								3407,
								9222,
								-6468,
								-6467,
								-4450,
								-3475,
								-3474,
								5888,
								5885,
								7312,
								6902,
								8303,
								6904,
								9145,
								9275,
								4207
							]
						],
						[
							[
								5447
							]
						],
						[
							[
								5450
							]
						],
						[
							[
								8304
							]
						],
						[
							[
								8576
							]
						],
						[
							[
								8577
							]
						],
						[
							[
								8771
							]
						],
						[
							[
								8872
							]
						]
					],
					"id": "26"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-2274,
								3998,
								-7106,
								-7055,
								720,
								721,
								319,
								315,
								316,
								2468,
								9230,
								3600,
								9335,
								5953,
								9097,
								8949,
								7887,
								8309,
								7883,
								7884,
								-6895,
								5869,
								7853,
								7820,
								-2447,
								-2446,
								-4603,
								-4741,
								-6687,
								-6686,
								-6592,
								-4456,
								-4455,
								-854,
								6472,
								6932,
								6933,
								6836,
								-1655,
								-1654,
								-1661,
								6976,
								-6703,
								3607,
								183,
								184,
								185,
								-1146,
								-2275
							]
						]
					],
					"id": "30"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-3420,
								-8199,
								7520,
								7574,
								-3281,
								-3286,
								-8433,
								-8435,
								-1380,
								-1379,
								-724,
								-723,
								2625,
								3268,
								-1396,
								-1395,
								9142,
								9141,
								6027,
								9308,
								4592,
								5876,
								8353,
								8563,
								8508,
								8611,
								8557,
								9096,
								8429,
								-8138,
								-6210,
								-6209,
								-6024,
								-6023,
								-5305,
								7672,
								5931,
								5932,
								8784,
								8879,
								-5835,
								-8551,
								8515,
								-8553,
								8603,
								4010,
								5970,
								7203,
								7786,
								7202,
								5972,
								7137,
								7664,
								-7625,
								7663,
								4008,
								8604,
								189,
								190,
								5541,
								5542,
								-3421
							]
						],
						[
							[
								6279,
								6280,
								6281,
								6282
							]
						],
						[
							[
								7785
							]
						]
					],
					"id": "36"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								4608,
								-3462,
								-3461,
								-9162,
								-4448,
								-4447,
								-618,
								4612,
								4613,
								-2277,
								4614,
								-8583,
								-7171,
								-7170,
								-6995,
								-5284,
								-7401,
								-5962,
								-5961,
								-8836,
								4030,
								-946,
								-945,
								-3761,
								-3760,
								1230,
								1231,
								-9307,
								9401,
								8777,
								8811,
								9160,
								6144,
								6748,
								8119,
								8117,
								8159,
								-8080,
								-6103,
								-6102,
								-5316,
								-5315,
								-6924,
								2823,
								191,
								1980
							]
						]
					],
					"id": "41"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								6872,
								7302,
								7275,
								982,
								8601,
								8367,
								3193,
								3194,
								8804,
								-8364,
								985,
								986,
								8461,
								8462,
								-6035,
								-6034,
								-6033,
								-4503,
								-4502,
								-3090,
								-6501,
								-6500,
								7845,
								-5184,
								-5183,
								-5182,
								-5181,
								-9000,
								-8999,
								-3213,
								-3212,
								-7074,
								6375,
								-7073,
								-7070,
								-4391,
								-7865,
								-7864,
								-4040,
								1401,
								-4039,
								-4038,
								7141,
								-6603,
								-6602,
								-8930,
								-7261,
								-8299,
								-8298,
								-6962,
								8502,
								8390,
								7687,
								-3470,
								7683,
								7828,
								7829,
								8955,
								8036,
								-7086,
								-7595,
								-7594,
								-7593,
								-4747,
								-4746,
								-852,
								-851,
								-4501,
								-4500,
								-4499,
								7737,
								6189,
								-7943,
								-7993,
								-5907,
								-8102,
								7341,
								9176,
								-6253,
								-5885,
								-3977,
								-3976,
								7226,
								-9281,
								-6996,
								6182,
								6183,
								6184,
								9265,
								2175,
								9201,
								9278,
								3453,
								5856,
								6547,
								4361,
								9232,
								9409,
								8212,
								8880,
								8599,
								8549,
								197,
								4442
							]
						],
						[
							[
								-5983,
								-6267,
								6528,
								6211,
								6529
							]
						],
						[
							[
								6530,
								-5985
							]
						]
					],
					"id": "51"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								199,
								200,
								201,
								-733,
								-4883,
								-7195,
								6168,
								-1346,
								6169,
								-7302,
								-6761,
								-7949,
								-6454,
								4735,
								4736,
								8393,
								-8083,
								-3772,
								-3771,
								7279,
								-978,
								438,
								435,
								-5855,
								5914,
								5909,
								5910,
								-6249,
								-7801,
								-6704,
								-6977,
								1660,
								1653,
								1654,
								-6837,
								-6934,
								-6933,
								-6473,
								853,
								4454,
								4455,
								4456,
								-2510
							]
						]
					],
					"id": "56"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								6499,
								6500,
								3089,
								4501,
								4502,
								6032,
								6033,
								6034,
								-8463,
								-8462,
								-987,
								-986,
								8363,
								-8805,
								-3195,
								9385,
								6341,
								9386,
								8365,
								4597,
								8545,
								9357,
								8770,
								4380,
								6671,
								9310,
								7109,
								6343,
								7110,
								4386,
								9203,
								8237,
								8828,
								8927,
								9064,
								7291,
								5618,
								5619,
								1949,
								-6663,
								-4414,
								962,
								-4413,
								6031,
								-6599,
								-6108,
								6040,
								6041,
								8935,
								7315,
								7316,
								7367,
								-7269,
								5936,
								5937,
								8356,
								6388,
								6389,
								-5778,
								-5777,
								-8855,
								8191,
								8192,
								-8129,
								3621,
								-446,
								3622,
								3617,
								4016,
								4013,
								-6298,
								-4632,
								-4631,
								6439,
								5625,
								5620,
								5621,
								7008,
								5197,
								5193,
								-6372,
								-7867,
								-7866,
								4394,
								4390,
								7069,
								7072,
								-6376,
								7073,
								3211,
								3212,
								8998,
								8999,
								5180,
								5181,
								5182,
								5183,
								-7846
							]
						],
						[
							[
								6337
							]
						],
						[
							[
								6338,
								7107
							]
						]
					],
					"id": "37"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-8373,
								-8218,
								-8198,
								-8197,
								-7363,
								-7367,
								-7352,
								7584,
								4352,
								4353,
								4354,
								4355,
								4356,
								7464,
								-3847,
								-3846,
								7669,
								7670,
								7535,
								7954,
								-4429,
								7955,
								-8352,
								-6203,
								4025,
								4026,
								-5786,
								-8244,
								5703,
								5704,
								-6117,
								-3167,
								-1449,
								-1448,
								2680,
								-3222,
								-45,
								-44,
								-4639,
								-4638,
								-4635,
								4605,
								4606,
								-3260,
								-3259,
								-2712,
								6139,
								-2928,
								-5571,
								4603,
								-174,
								-2711,
								-2710,
								-5902,
								6619,
								6678,
								-592,
								-591,
								-708,
								429,
								430,
								-1797,
								-6966,
								-6965,
								7455,
								7526,
								-2420,
								-2419,
								3629,
								4741,
								4742,
								4017,
								4018,
								-4547,
								-3597,
								-6888,
								-6887,
								-8374
							]
						]
					],
					"id": "40"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								5186,
								5187,
								2557,
								-1525,
								-1524,
								7038,
								7039,
								-7160,
								-5646,
								-5645,
								-3165,
								-3164,
								4143,
								4144,
								970,
								971,
								8165,
								8166,
								6766,
								-5748,
								-5747,
								-7262,
								8929,
								6601,
								6602,
								-7142,
								4037,
								4038,
								-1402,
								4039,
								7863,
								7864,
								-4395,
								7865,
								7866,
								6371,
								-5194,
								-5198,
								-7009,
								-5622,
								-5621,
								-5626,
								-6440,
								4630,
								4631,
								6297,
								-4014,
								-4017,
								-3618,
								-3623,
								-445,
								-5561,
								-5560,
								7946,
								7115,
								7116,
								-5656,
								-5279,
								-5278,
								-4155,
								-4154,
								8527,
								7572,
								-2697,
								-2696,
								4047,
								-5155,
								-5154,
								-5158,
								-24,
								6607,
								7283,
								9225,
								-2549,
								-5792,
								4625,
								4626,
								6898,
								6899,
								6900,
								6914,
								6913,
								6896,
								6912,
								7236,
								7150,
								7145,
								-9018,
								-5810,
								-9188,
								-5808,
								-9187,
								7338,
								-6804,
								-6803,
								-4236,
								-4235,
								-1564,
								-1563
							]
						]
					],
					"id": "47"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-8426,
								-4349,
								-4348,
								-5520,
								-5519,
								-3277,
								8625,
								3732,
								4758,
								4759,
								6166,
								-5893,
								-5892,
								-6050,
								-2759,
								8342,
								9242,
								8333,
								9039,
								-5889,
								3473,
								3474,
								4449,
								6466,
								6467,
								-9223,
								-3408,
								9366,
								9272,
								1927,
								1103,
								9282,
								1105,
								739,
								7225,
								7036,
								8787,
								990,
								3855,
								3856,
								-12,
								-11,
								1937,
								-3088,
								-5564,
								-5563,
								-4538,
								-4543,
								-5896,
								-5895,
								-6448,
								8386,
								-8424,
								-8422,
								-8421,
								-8633,
								4923,
								4924,
								-8427
							]
						],
						[
							[
								8329
							]
						],
						[
							[
								8330
							]
						],
						[
							[
								8331
							]
						],
						[
							[
								8332
							]
						],
						[
							[
								9281
							]
						]
					],
					"id": "55"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								7501,
								4163,
								1579,
								1348,
								1351,
								9363,
								290,
								9364,
								1353,
								1350,
								1580,
								4164,
								1582,
								4165,
								7502,
								1577,
								4963,
								7622,
								1864,
								5010,
								8703,
								1867,
								9407,
								9090,
								2980,
								9086,
								7564,
								9087,
								7612,
								9290,
								7615,
								9291,
								846,
								2511,
								1968,
								2208,
								9396,
								9315,
								7621,
								4962,
								1575,
								7503,
								4761
							]
						],
						[
							[
								292
							]
						],
						[
							[
								293
							]
						],
						[
							[
								294
							]
						],
						[
							[
								296,
								1346
							]
						],
						[
							[
								297,
								1951
							]
						],
						[
							[
								1354
							]
						],
						[
							[
								1573,
								1953
							]
						],
						[
							[
								1584,
								4161
							]
						],
						[
							[
								1952
							]
						],
						[
							[
								2512
							]
						],
						[
							[
								2974
							]
						],
						[
							[
								2975
							]
						],
						[
							[
								2976
							]
						],
						[
							[
								2977
							]
						],
						[
							[
								2978
							]
						],
						[
							[
								2981
							]
						],
						[
							[
								2982
							]
						],
						[
							[
								7485
							]
						],
						[
							[
								7486
							]
						],
						[
							[
								7487
							]
						],
						[
							[
								7488
							]
						],
						[
							[
								7489
							]
						],
						[
							[
								7490
							]
						],
						[
							[
								7491
							]
						],
						[
							[
								7492
							]
						],
						[
							[
								7493
							]
						],
						[
							[
								7494
							]
						],
						[
							[
								7495
							]
						],
						[
							[
								7496
							]
						],
						[
							[
								7497
							]
						],
						[
							[
								7498
							]
						],
						[
							[
								7499
							]
						],
						[
							[
								7500
							]
						],
						[
							[
								7614
							]
						],
						[
							[
								7617
							]
						],
						[
							[
								7618
							]
						],
						[
							[
								9292
							]
						],
						[
							[
								9293
							]
						],
						[
							[
								9361
							]
						],
						[
							[
								9362
							]
						],
						[
							[
								9403
							]
						],
						[
							[
								9404
							]
						],
						[
							[
								9405
							]
						],
						[
							[
								9406
							]
						]
					],
					"id": "02"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								3449,
								7372,
								6097,
								-4573,
								-4578,
								-5930,
								-4656,
								-4655,
								-8977,
								-5932,
								-7673,
								5304,
								6022,
								6023,
								6208,
								6209,
								8137,
								8136,
								6334,
								326,
								733,
								734,
								735
							]
						]
					],
					"id": "50"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-2305,
								3624,
								3346,
								3347,
								9251,
								-8899,
								9252,
								-7766,
								-7094,
								-7335,
								6131,
								-2152,
								-2151,
								3401,
								-3429,
								3626,
								-4424,
								-4423,
								6134,
								6135,
								4739,
								4740,
								4602,
								2445,
								2446,
								-7821,
								-7854,
								-5870,
								6894,
								-7885,
								8786,
								3062,
								4509,
								8767,
								8322,
								9390,
								2231,
								8500,
								-6745,
								-3726,
								-3725,
								-3724,
								-2306
							]
						]
					],
					"id": "38"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-8193,
								-8192,
								8854,
								-5785,
								-5784,
								-5783,
								-5782,
								-5781,
								-6361,
								-6360,
								-7338,
								-9027,
								5835,
								7850,
								7851,
								5029,
								5030,
								7692,
								-5699,
								-6288,
								-6287,
								-9094,
								8014,
								8015,
								-8360,
								9408,
								6534,
								8109,
								8140,
								8027,
								8635,
								-8248,
								-8251,
								9068,
								3547,
								1931,
								1932,
								-3534,
								5557,
								-7776,
								7778,
								-7995,
								-8156,
								7412,
								7413,
								2158,
								2159,
								4536,
								-3786,
								-3785,
								-3361,
								-3360,
								3549,
								820,
								821,
								8151,
								-8712,
								-4525,
								-4524,
								-4523,
								-7697,
								3795,
								-1712,
								-1711,
								-8892,
								2547,
								7797,
								7798,
								953,
								6712,
								6713,
								5670,
								-8594,
								-5161,
								-5160,
								-5159,
								-4156,
								5277,
								5278,
								5655,
								-7117,
								-7116,
								-7947,
								5559,
								5560,
								444,
								445,
								-3622,
								8128
							]
						]
					],
					"id": "13"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								451
							]
						],
						[
							[
								454,
								5974,
								456,
								3589,
								5976,
								6550,
								8164,
								5819,
								8168,
								8169,
								-4580,
								8170,
								4200,
								-2555,
								4201,
								5979,
								5442,
								9348,
								2636
							]
						],
						[
							[
								3586
							]
						],
						[
							[
								5975
							]
						]
					],
					"id": "23"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								9410,
								474,
								7759,
								-5462,
								476,
								477,
								-3782,
								-6479,
								-6701,
								-6639,
								6004,
								6005
							]
						],
						[
							[
								5390,
								5391
							]
						],
						[
							[
								5392
							]
						],
						[
							[
								7758
							]
						]
					],
					"id": "44"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-7176,
								-7175,
								7734,
								7991,
								5908,
								5904,
								-6255,
								-6254,
								-9177,
								-7342,
								8101,
								5906,
								7992,
								7942,
								-6190,
								-7738,
								4498,
								4499,
								4500,
								850,
								851,
								4745,
								4746,
								7592,
								7593,
								7594,
								7085,
								-8037,
								-8956,
								-7830,
								-7829,
								-7684,
								3469,
								3470,
								3471,
								4754,
								4755,
								4750,
								4751,
								-7243,
								7635,
								7589,
								-5942,
								-5941,
								-5940,
								-35,
								-7680,
								-7679,
								3854,
								3850,
								-8884,
								7640,
								-7581,
								2767,
								2763,
								7577,
								-8124,
								-8274,
								-7765,
								-6044,
								-6043,
								2765,
								-6049,
								-7639,
								-7638,
								478,
								479,
								7177,
								-7177
							]
						]
					],
					"id": "54"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								2273,
								2274,
								1145,
								-186,
								-185,
								-184,
								-3608,
								6702,
								6703,
								7800,
								6248,
								-5911,
								-5910,
								-5915,
								-5854,
								7744,
								3799,
								3800,
								6384,
								-5532,
								-5531,
								-5530,
								-5963,
								7400,
								5283,
								6994,
								7169,
								7170,
								8582,
								-4615,
								2276,
								-4614,
								-7281,
								-6405,
								-6404,
								3541,
								3542,
								4173,
								6670,
								-3038,
								-3037,
								9204,
								-316,
								-320,
								-722,
								-721,
								7054,
								7105,
								-3999
							]
						]
					],
					"id": "16"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								4033,
								4034,
								-5643,
								-7847,
								-6863,
								-6496,
								-4569,
								-4568,
								-6064,
								-6063,
								6438,
								6009,
								7182,
								-6060,
								3425,
								3426,
								6615,
								4420,
								-9256,
								7769,
								-3189,
								-1261,
								-1260,
								4881,
								4882,
								732,
								-202,
								-201,
								-200,
								2509,
								-4457,
								6591,
								6685,
								6686,
								-4740,
								-6136,
								-6135,
								4422,
								4423,
								-3627,
								3428,
								-3402,
								2150,
								2151,
								-6132,
								7334,
								7093,
								7765,
								7766,
								7767,
								7092,
								-6841,
								838,
								839,
								840,
								-1614,
								-6053,
								-463,
								4727
							]
						]
					],
					"id": "46"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								611,
								612,
								613,
								-1458,
								-8533,
								8795,
								4375,
								4376,
								8305,
								3611,
								3612,
								3608,
								7128,
								3159,
								3155,
								3386,
								3357,
								-5075,
								8802,
								8803,
								-5584,
								4586,
								-3779,
								-3778,
								-8527,
								6123,
								-5829,
								-5828,
								-2929,
								-6140,
								-2714,
								-2485,
								-2484,
								-1829,
								-8905,
								4583,
								-38,
								4584,
								-6446,
								-1196,
								-1195,
								-2025,
								-1922,
								-8362,
								8751,
								-8561
							]
						]
					],
					"id": "35"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								7280,
								-4613,
								617,
								4446,
								4447,
								9161,
								3460,
								3461,
								-4609,
								-1981,
								-192,
								-2824,
								6923,
								5314,
								5315,
								6101,
								6102,
								8079,
								-8160,
								-8118,
								8612,
								8825,
								9279,
								9009,
								9122,
								9006,
								9105,
								7402,
								6986,
								9106,
								8061,
								6981,
								5860,
								7470,
								9099,
								7468,
								8055,
								4919,
								8752,
								6103,
								8057,
								3035,
								3036,
								3037,
								-6671,
								-4174,
								-3543,
								-3542,
								6403,
								6404
							]
						],
						[
							[
								5861
							]
						],
						[
							[
								8012
							]
						],
						[
							[
								8013
							]
						],
						[
							[
								8053
							]
						],
						[
							[
								8056
							]
						],
						[
							[
								9100
							]
						]
					],
					"id": "53"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								8434,
								8432,
								3285,
								3280,
								-7575,
								-7521,
								8198,
								3419,
								3420,
								3421,
								4408,
								4409,
								6952,
								6518,
								6519,
								6520,
								6521,
								5707,
								5708,
								5709,
								6659,
								6660,
								-8455,
								-7058,
								4619,
								4620,
								5713,
								5714,
								5715,
								-9225,
								-5882,
								-5881,
								-6252,
								5946,
								5947,
								6573,
								7246,
								-7173,
								-7172,
								-7178,
								-480,
								-479,
								7637,
								7638,
								6048,
								-2766,
								6042,
								6043,
								7764,
								7760,
								-8273,
								-2479,
								-2478,
								4250,
								7771,
								-4404,
								-4403,
								9365,
								-9143,
								1394,
								1395,
								-3269,
								-2626,
								722,
								723,
								1378,
								1379
							]
						]
					],
					"id": "42"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								9033,
								8699,
								9185,
								7904,
								9184,
								7902,
								9213,
								4960,
								8158,
								7872,
								9321,
								8157,
								4168,
								8746,
								9326,
								8312,
								7406,
								5555,
								8163,
								3008,
								-149,
								3006,
								-5167,
								-5166,
								-3751,
								-3750,
								-3178,
								-3177,
								-3176,
								-3362,
								3784,
								3785,
								-4537,
								-2160,
								-2159,
								-7414,
								-7413,
								8155,
								7994,
								-7779,
								7775,
								-5558,
								3533,
								-1933,
								-1932,
								-3548,
								-9069,
								8250,
								8247,
								8248,
								7708,
								8010,
								2134,
								9011,
								9343,
								7356,
								7359,
								8401,
								7689,
								2002,
								5273,
								5544,
								5270,
								5545,
								5275,
								5549,
								5654,
								6393,
								6651,
								6396,
								6652,
								9130
							]
						],
						[
							[
								5546
							]
						],
						[
							[
								5547
							]
						],
						[
							[
								5550
							]
						],
						[
							[
								5551
							]
						],
						[
							[
								5552
							]
						],
						[
							[
								5553
							]
						],
						[
							[
								6394
							]
						],
						[
							[
								8745
							]
						]
					],
					"id": "12"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-7280,
								-3770,
								-3775,
								7973,
								-565,
								-571,
								-3769,
								1110,
								1111,
								1112,
								1113,
								1114,
								1115,
								-286,
								-285,
								-3004,
								-3003,
								-8207,
								1605,
								4436,
								-8206,
								-9229,
								9159,
								7971,
								-5534,
								-5533,
								-6385,
								-3801,
								-3800,
								-7745,
								5853,
								5854,
								-436,
								-439,
								977
							]
						]
					],
					"id": "49"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								6966,
								6967,
								6968,
								-9295,
								3958,
								3959,
								3954,
								-6649,
								-8048,
								-6462,
								-6461,
								-8571,
								-8570,
								-5737,
								-5736,
								-5735,
								5752,
								-5638,
								-5637,
								958,
								4191,
								4187,
								4188,
								-5026,
								-5025,
								-4718,
								-4717,
								-5900,
								5052,
								5388,
								5389,
								-3556,
								5386,
								4650,
								-7816,
								9139,
								3055,
								5717,
								5718,
								8749,
								-8132,
								5903,
								-5633,
								-5632,
								5749,
								-7244,
								-4751,
								-4756,
								-4755,
								-3472,
								-3471,
								-7688,
								-8391,
								-8503,
								6961,
								8297,
								8298,
								7260,
								7261,
								5746,
								5747,
								-6767,
								-8167,
								-8166,
								-972,
								-971,
								-4145,
								-4144,
								3163,
								3164,
								5644,
								5645,
								7159,
								-7040,
								-7039,
								1523,
								1524,
								-2558,
								-5188,
								-5187,
								1562,
								1563,
								4234,
								4235,
								6802,
								6803,
								-7339,
								9186,
								-5807,
								-6943,
								6805,
								4646,
								5035,
								5036,
								5033,
								7015,
								7016
							]
						],
						[
							[
								9187,
								-5809
							]
						]
					],
					"id": "21"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								4579,
								-8170,
								8610,
								-8498,
								6850,
								6851,
								-6476,
								5928,
								-4657,
								5929,
								4577,
								4572,
								-6098,
								-7373,
								-3450,
								-736,
								-735,
								2553,
								2554,
								-4201,
								-8171
							]
						]
					],
					"id": "33"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-6041,
								6107,
								6598,
								-6032,
								4412,
								-963,
								4413,
								6662,
								-1950,
								-5620,
								5773,
								8240,
								6526,
								7705,
								6434,
								8358,
								8359,
								-8016,
								-8015,
								9093,
								6286,
								6287,
								5698,
								-7693,
								-5031,
								-5030,
								-7852,
								-7851,
								-5836,
								9026,
								7337,
								6359,
								6360,
								5780,
								5781,
								5782,
								5783,
								5784,
								5776,
								5777,
								-6390,
								-6389,
								-8357,
								-5938,
								-5937,
								7268,
								-7368,
								-7317,
								-7316,
								-8936,
								-6042
							]
						]
					],
					"id": "45"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-2268,
								-1688,
								8834,
								-947,
								-4031,
								8835,
								5960,
								5961,
								5962,
								5529,
								5530,
								5531,
								5532,
								5533,
								-7972,
								-9160,
								9228,
								8205,
								-4437,
								-1606,
								8206,
								-3002,
								-3006,
								-700,
								8925,
								8679,
								8781,
								-8743,
								-8742,
								7386,
								5875,
								-5269,
								-440,
								-1700,
								-1699,
								-1698
							]
						]
					],
					"id": "32"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								3093
							]
						],
						[
							[
								3094
							]
						],
						[
							[
								3095
							]
						],
						[
							[
								3097,
								9143
							]
						],
						[
							[
								7823
							]
						],
						[
							[
								7824
							]
						],
						[
							[
								9104
							]
						],
						[
							[
								9331
							]
						]
					],
					"id": "15"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-3422,
								-5543,
								-5542,
								-191,
								-190,
								-8605,
								-4009,
								-7664,
								7624,
								7625,
								-6281,
								-6280,
								-6283,
								7417,
								4370,
								9045,
								6568,
								4007,
								6269,
								6274,
								6275,
								6271,
								6272,
								-8456,
								-6660,
								-5710,
								-5709,
								-5708,
								-6522,
								-6521,
								-6520,
								-6519,
								-6953,
								-4410,
								-4409
							]
						]
					],
					"id": "34"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								6728,
								5649,
								8551,
								8552,
								-8516,
								8550,
								5834,
								5830,
								5831,
								6653,
								-8389,
								-6480,
								3780,
								3781,
								-478,
								-477,
								5461,
								5462
							]
						]
					],
					"id": "09"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								7171,
								7172,
								-7247,
								-6574,
								-5948,
								-5947,
								6251,
								5880,
								5881,
								9224,
								-5716,
								-5715,
								-5714,
								-4621,
								-4620,
								7057,
								7058,
								-8458,
								-8046,
								-8045,
								-8044,
								9078,
								-9117,
								6326,
								6264,
								6265,
								6266,
								5982,
								5983,
								6327,
								9209,
								5824,
								9258,
								8780,
								7060,
								7232,
								7050,
								8656,
								6717,
								9295,
								9384,
								9016,
								-6184,
								-6183,
								6995,
								6996,
								3974,
								3975,
								3976,
								5884,
								6252,
								6253,
								6254,
								-5905,
								-5909,
								-7992,
								-7735,
								7174,
								7175,
								7176
							]
						],
						[
							[
								5984,
								5985
							]
						],
						[
							[
								5987,
								9208
							]
						]
					],
					"id": "24"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								3977
							]
						],
						[
							[
								-6851,
								8497,
								8498,
								6294,
								6698,
								9036,
								6694,
								9037,
								7267,
								3979,
								7265,
								9038,
								6636,
								-5391,
								6637,
								-6005,
								6638,
								6700,
								6478,
								-3781,
								6479,
								8388,
								-6654,
								-5832,
								-5831,
								-8880,
								-8785,
								-5933,
								8976,
								4654,
								4655,
								4656,
								-5929,
								6475,
								-6852
							]
						],
						[
							[
								5198
							]
						]
					],
					"id": "25"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								8454,
								-6661,
								8455,
								-6273,
								8456,
								8041,
								9115,
								-6265,
								-6327,
								9116,
								-9079,
								8043,
								8044,
								8045,
								8457,
								-7059
							]
						],
						[
							[
								-6276,
								8453
							]
						]
					],
					"id": "10"
				},
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								-3975,
								-6997,
								9280,
								-7227
							]
						]
					],
					"id": "11"
				}
			]
		},
		"nation": {
			"type": "GeometryCollection",
			"geometries": [
				{
					"type": "MultiPolygon",
					"arcs": [
						[
							[
								9206,
								8606,
								8640,
								5722,
								8512,
								8766,
								7988,
								6581,
								9218,
								8301,
								7463,
								6921,
								3433,
								7248,
								3435,
								3439,
								7250,
								3841,
								7249,
								3843,
								7998,
								7966,
								6426,
								9125,
								6309,
								6012,
								8734,
								8813,
								8736,
								6014,
								6310,
								9126,
								6424,
								7967,
								6198,
								6428,
								7964,
								6430,
								6200,
								6431,
								3834,
								9127,
								6312,
								6016,
								8737,
								8814,
								8531,
								7920,
								4516,
								9354,
								3736,
								8964,
								4641,
								5849,
								8997,
								7841,
								8534,
								9019,
								4376,
								8305,
								3611,
								9340,
								3746,
								5456,
								9196,
								8411,
								8189,
								9010,
								8969,
								3374,
								2897,
								9,
								1430,
								6239,
								6452,
								4160,
								6451,
								3753,
								5767,
								6989,
								5951,
								8381,
								7699,
								8382,
								8278,
								5771,
								9307,
								9401,
								8777,
								8811,
								9160,
								6144,
								6748,
								8119,
								8612,
								8825,
								9279,
								9009,
								9122,
								9006,
								9105,
								7402,
								6986,
								9106,
								8061,
								6981,
								5860,
								7470,
								9099,
								7468,
								8055,
								4919,
								8752,
								6103,
								8057,
								3035,
								9204,
								316,
								2468,
								9230,
								3600,
								9335,
								5953,
								9097,
								8949,
								7887,
								8309,
								7883,
								8786,
								3062,
								4509,
								8767,
								8322,
								9390,
								2231,
								8500,
								6742,
								8992,
								4663,
								7928,
								2755,
								8051,
								3132,
								8052,
								2757,
								8342,
								9242,
								8333,
								9039,
								5885,
								7312,
								6902,
								8303,
								6904,
								9145,
								9275,
								4207,
								2899,
								9140,
								8773,
								9277,
								8875,
								3406,
								9366,
								9272,
								1927,
								1103,
								9282,
								1105,
								739,
								7225,
								7036,
								8787,
								990,
								3855,
								6796,
								7205,
								6794,
								8653,
								3557,
								6410,
								9402,
								310,
								4346,
								3418,
								830,
								9395,
								2944,
								178,
								5449,
								9043,
								9323,
								8575,
								4662,
								5788,
								8558,
								9268,
								8718,
								2846,
								9229,
								4242,
								9165,
								8948,
								7959,
								8758,
								4204,
								9235,
								3982,
								6137,
								322,
								5626,
								7712,
								9047,
								9369,
								4401,
								9365,
								9141,
								6027,
								9308,
								4592,
								5876,
								8353,
								8563,
								8508,
								8611,
								8557,
								9096,
								8429,
								8136,
								6334,
								326,
								733,
								2553,
								4201,
								5979,
								5442,
								9348,
								2636,
								454,
								5974,
								456,
								3589,
								5976,
								6550,
								8164,
								5819,
								8168,
								8610,
								8498,
								6294,
								6698,
								9036,
								6694,
								9037,
								7267,
								3979,
								7265,
								9038,
								6636,
								5391,
								6637,
								6005,
								9410,
								474,
								7759,
								5462,
								6728,
								5649,
								8551,
								8603,
								4010,
								5970,
								7203,
								7786,
								7202,
								5972,
								7137,
								7664,
								7625,
								6281,
								7417,
								4370,
								9045,
								6568,
								4007,
								6269,
								6274,
								8453,
								6271,
								8456,
								8041,
								9115,
								6265,
								6528,
								6211,
								6529,
								5983,
								6327,
								9209,
								5824,
								9258,
								8780,
								7060,
								7232,
								7050,
								8656,
								6717,
								9295,
								9384,
								9016,
								6184,
								9265,
								2175,
								9201,
								9278,
								3453,
								5856,
								6547,
								4361,
								9232,
								9409,
								8212,
								8880,
								8599,
								8549,
								197,
								4442,
								6872,
								7302,
								7275,
								982,
								8601,
								8367,
								3193,
								9385,
								6341,
								9386,
								8365,
								4597,
								8545,
								9357,
								8770,
								4380,
								6671,
								9310,
								7109,
								6343,
								7110,
								4386,
								9203,
								8237,
								8828,
								8927,
								9064,
								7291,
								5618,
								5773,
								8240,
								6526,
								7705,
								6434,
								8358,
								9408,
								6534,
								8109,
								8140,
								8027,
								8635,
								8248,
								7708,
								8010,
								2134,
								9011,
								9343,
								7356,
								7359,
								8401,
								7689,
								2002,
								5273,
								5544,
								5270,
								5545,
								5275,
								5549,
								5654,
								6393,
								6651,
								6396,
								6652,
								9130,
								9033,
								8699,
								9185,
								7904,
								9184,
								7902,
								9213,
								4960,
								8158,
								7872,
								9321,
								8157,
								4168,
								8746,
								9326,
								8312,
								7406,
								5555,
								8163,
								3008,
								149,
								9371,
								5523,
								7653,
								8075
							],
							[
								1734
							]
						],
						[
							[
								2892
							]
						],
						[
							[
								2893
							]
						],
						[
							[
								2894
							]
						],
						[
							[
								3371
							]
						],
						[
							[
								3375
							]
						],
						[
							[
								8965
							]
						],
						[
							[
								8966
							]
						],
						[
							[
								5520
							]
						],
						[
							[
								5521
							]
						],
						[
							[
								7651
							]
						],
						[
							[
								320
							]
						],
						[
							[
								9370
							]
						],
						[
							[
								7984
							]
						],
						[
							[
								7985
							]
						],
						[
							[
								8638
							]
						],
						[
							[
								8639
							]
						],
						[
							[
								8764
							]
						],
						[
							[
								9219
							]
						],
						[
							[
								5447
							]
						],
						[
							[
								5450
							]
						],
						[
							[
								8304
							]
						],
						[
							[
								8576
							]
						],
						[
							[
								8577
							]
						],
						[
							[
								8771
							]
						],
						[
							[
								8872
							]
						],
						[
							[
								7785
							]
						],
						[
							[
								6530,
								5985
							]
						],
						[
							[
								6337
							]
						],
						[
							[
								6338,
								7107
							]
						],
						[
							[
								8329
							]
						],
						[
							[
								8330
							]
						],
						[
							[
								8331
							]
						],
						[
							[
								8332
							]
						],
						[
							[
								9281
							]
						],
						[
							[
								7501,
								4163,
								1579,
								1348,
								1351,
								9363,
								290,
								9364,
								1353,
								1350,
								1580,
								4164,
								1582,
								4165,
								7502,
								1577,
								4963,
								7622,
								1864,
								5010,
								8703,
								1867,
								9407,
								9090,
								2980,
								9086,
								7564,
								9087,
								7612,
								9290,
								7615,
								9291,
								846,
								2511,
								1968,
								2208,
								9396,
								9315,
								7621,
								4962,
								1575,
								7503,
								4761
							]
						],
						[
							[
								292
							]
						],
						[
							[
								293
							]
						],
						[
							[
								294
							]
						],
						[
							[
								296,
								1346
							]
						],
						[
							[
								297,
								1951
							]
						],
						[
							[
								1354
							]
						],
						[
							[
								1573,
								1953
							]
						],
						[
							[
								1584,
								4161
							]
						],
						[
							[
								1952
							]
						],
						[
							[
								2512
							]
						],
						[
							[
								2974
							]
						],
						[
							[
								2975
							]
						],
						[
							[
								2976
							]
						],
						[
							[
								2977
							]
						],
						[
							[
								2978
							]
						],
						[
							[
								2981
							]
						],
						[
							[
								2982
							]
						],
						[
							[
								7485
							]
						],
						[
							[
								7486
							]
						],
						[
							[
								7487
							]
						],
						[
							[
								7488
							]
						],
						[
							[
								7489
							]
						],
						[
							[
								7490
							]
						],
						[
							[
								7491
							]
						],
						[
							[
								7492
							]
						],
						[
							[
								7493
							]
						],
						[
							[
								7494
							]
						],
						[
							[
								7495
							]
						],
						[
							[
								7496
							]
						],
						[
							[
								7497
							]
						],
						[
							[
								7498
							]
						],
						[
							[
								7499
							]
						],
						[
							[
								7500
							]
						],
						[
							[
								7614
							]
						],
						[
							[
								7617
							]
						],
						[
							[
								7618
							]
						],
						[
							[
								9292
							]
						],
						[
							[
								9293
							]
						],
						[
							[
								9361
							]
						],
						[
							[
								9362
							]
						],
						[
							[
								9403
							]
						],
						[
							[
								9404
							]
						],
						[
							[
								9405
							]
						],
						[
							[
								9406
							]
						],
						[
							[
								451
							]
						],
						[
							[
								3586
							]
						],
						[
							[
								5975
							]
						],
						[
							[
								5392
							]
						],
						[
							[
								7758
							]
						],
						[
							[
								5861
							]
						],
						[
							[
								8012
							]
						],
						[
							[
								8013
							]
						],
						[
							[
								8053
							]
						],
						[
							[
								8056
							]
						],
						[
							[
								9100
							]
						],
						[
							[
								5546
							]
						],
						[
							[
								5547
							]
						],
						[
							[
								5550
							]
						],
						[
							[
								5551
							]
						],
						[
							[
								5552
							]
						],
						[
							[
								5553
							]
						],
						[
							[
								6394
							]
						],
						[
							[
								8745
							]
						],
						[
							[
								3093
							]
						],
						[
							[
								3094
							]
						],
						[
							[
								3095
							]
						],
						[
							[
								3097,
								9143
							]
						],
						[
							[
								7823
							]
						],
						[
							[
								7824
							]
						],
						[
							[
								9104
							]
						],
						[
							[
								9331
							]
						],
						[
							[
								5987,
								9208
							]
						],
						[
							[
								3977
							]
						],
						[
							[
								5198
							]
						]
					]
				}
			]
		}
	},
	"arcs": [
		[
			[
				60335,
				57541
			],
			[
				145,
				-7
			]
		],
		[
			[
				60480,
				57534
			],
			[
				432,
				-29
			]
		],
		[
			[
				60912,
				57505
			],
			[
				2,
				432
			],
			[
				-107,
				130
			],
			[
				39,
				173
			],
			[
				89,
				23
			],
			[
				-28,
				127
			],
			[
				133,
				63
			],
			[
				-16,
				200
			],
			[
				126,
				136
			],
			[
				9,
				383
			]
		],
		[
			[
				61159,
				59172
			],
			[
				-761,
				18
			],
			[
				-4,
				-169
			],
			[
				-96,
				4
			]
		],
		[
			[
				60298,
				59025
			],
			[
				-17,
				-1452
			],
			[
				54,
				-32
			]
		],
		[
			[
				9743,
				50089
			],
			[
				1031,
				492
			],
			[
				931,
				427
			]
		],
		[
			[
				11705,
				51008
			],
			[
				34,
				16
			]
		],
		[
			[
				11739,
				51024
			],
			[
				-99,
				653
			],
			[
				189,
				83
			],
			[
				-51,
				328
			],
			[
				77,
				145
			],
			[
				30,
				249
			],
			[
				204,
				91
			],
			[
				-49,
				328
			],
			[
				124,
				56
			],
			[
				-49,
				326
			],
			[
				249,
				113
			],
			[
				-49,
				328
			],
			[
				202,
				67
			],
			[
				-64,
				339
			],
			[
				149,
				108
			],
			[
				-97,
				656
			]
		],
		[
			[
				12505,
				54894
			],
			[
				-114,
				-37
			],
			[
				-191,
				-374
			],
			[
				-139,
				-74
			],
			[
				-130,
				-185
			],
			[
				-138,
				-268
			],
			[
				-89,
				-39
			],
			[
				-184,
				-297
			],
			[
				-140,
				90
			],
			[
				-109,
				166
			],
			[
				-199,
				-71
			],
			[
				9,
				330
			],
			[
				-27,
				96
			],
			[
				-198,
				-408
			],
			[
				-108,
				-66
			],
			[
				-243,
				9
			],
			[
				-27,
				-60
			]
		],
		[
			[
				10478,
				53706
			],
			[
				105,
				-461
			],
			[
				-1,
				-151
			],
			[
				-135,
				-217
			],
			[
				-49,
				50
			],
			[
				-148,
				-251
			],
			[
				-47,
				-187
			],
			[
				98,
				-272
			],
			[
				41,
				-285
			],
			[
				-41,
				-201
			],
			[
				-160,
				-120
			],
			[
				-119,
				-407
			],
			[
				-62,
				-375
			],
			[
				-183,
				-239
			],
			[
				-34,
				-501
			]
		],
		[
			[
				66666,
				33795
			],
			[
				657,
				-94
			]
		],
		[
			[
				67323,
				33701
			],
			[
				172,
				-29
			]
		],
		[
			[
				67495,
				33672
			],
			[
				63,
				1311
			]
		],
		[
			[
				67558,
				34983
			],
			[
				-64,
				9
			]
		],
		[
			[
				67494,
				34992
			],
			[
				-574,
				81
			]
		],
		[
			[
				66920,
				35073
			],
			[
				-192,
				26
			]
		],
		[
			[
				66728,
				35099
			],
			[
				-62,
				-1304
			]
		],
		[
			[
				54331,
				44181
			],
			[
				388,
				1
			]
		],
		[
			[
				54719,
				44182
			],
			[
				377,
				0
			]
		],
		[
			[
				55096,
				44182
			],
			[
				1,
				1341
			]
		],
		[
			[
				55097,
				45523
			],
			[
				0,
				336
			],
			[
				-422,
				0
			]
		],
		[
			[
				54675,
				45859
			],
			[
				-346,
				-2
			]
		],
		[
			[
				54329,
				45857
			],
			[
				2,
				-1676
			]
		],
		[
			[
				68585,
				62543
			],
			[
				295,
				-42
			]
		],
		[
			[
				68880,
				62501
			],
			[
				96,
				270
			],
			[
				109,
				102
			]
		],
		[
			[
				69085,
				62873
			],
			[
				-19,
				1203
			]
		],
		[
			[
				69066,
				64076
			],
			[
				-7,
				459
			]
		],
		[
			[
				69059,
				64535
			],
			[
				-316,
				43
			]
		],
		[
			[
				68743,
				64578
			],
			[
				-53,
				-1064
			],
			[
				-65,
				-49
			]
		],
		[
			[
				68625,
				63465
			],
			[
				-40,
				-922
			]
		],
		[
			[
				78116,
				43672
			],
			[
				-13,
				-343
			],
			[
				193,
				-22
			],
			[
				-15,
				-349
			]
		],
		[
			[
				78281,
				42958
			],
			[
				190,
				-25
			]
		],
		[
			[
				78471,
				42933
			],
			[
				16,
				350
			],
			[
				360,
				-53
			]
		],
		[
			[
				78847,
				43230
			],
			[
				29,
				507
			],
			[
				75,
				159
			],
			[
				165,
				-31
			],
			[
				12,
				207
			]
		],
		[
			[
				79128,
				44072
			],
			[
				-40,
				148
			]
		],
		[
			[
				79088,
				44220
			],
			[
				-878,
				132
			]
		],
		[
			[
				78210,
				44352
			],
			[
				-14,
				-344
			],
			[
				-66,
				8
			],
			[
				-14,
				-344
			]
		],
		[
			[
				41847,
				65309
			],
			[
				75,
				-1670
			]
		],
		[
			[
				41922,
				63639
			],
			[
				940,
				117
			]
		],
		[
			[
				42862,
				63756
			],
			[
				-68,
				1668
			]
		],
		[
			[
				42794,
				65424
			],
			[
				-163,
				-19
			]
		],
		[
			[
				42631,
				65405
			],
			[
				-784,
				-96
			]
		],
		[
			[
				46612,
				59071
			],
			[
				965,
				78
			]
		],
		[
			[
				47577,
				59149
			],
			[
				-16,
				673
			]
		],
		[
			[
				47561,
				59822
			],
			[
				-25,
				1009
			]
		],
		[
			[
				47536,
				60831
			],
			[
				-971,
				-75
			]
		],
		[
			[
				46565,
				60756
			],
			[
				47,
				-1685
			]
		],
		[
			[
				70692,
				69935
			],
			[
				191,
				-41
			],
			[
				-5,
				-338
			],
			[
				47,
				-159
			],
			[
				154,
				-15
			],
			[
				-14,
				-252
			],
			[
				80,
				-152
			],
			[
				91,
				28
			],
			[
				65,
				-108
			]
		],
		[
			[
				71301,
				68898
			],
			[
				72,
				-10
			]
		],
		[
			[
				71373,
				68888
			],
			[
				20,
				308
			],
			[
				95,
				0
			],
			[
				23,
				241
			],
			[
				73,
				121
			],
			[
				101,
				38
			]
		],
		[
			[
				71685,
				69596
			],
			[
				55,
				817
			],
			[
				-266,
				40
			]
		],
		[
			[
				71474,
				70453
			],
			[
				-556,
				110
			],
			[
				-10,
				-167
			],
			[
				-188,
				32
			]
		],
		[
			[
				70720,
				70428
			],
			[
				-28,
				-493
			]
		],
		[
			[
				63658,
				60348
			],
			[
				965,
				-87
			],
			[
				384,
				-10
			]
		],
		[
			[
				65007,
				60251
			],
			[
				35,
				1006
			]
		],
		[
			[
				65042,
				61257
			],
			[
				-389,
				27
			]
		],
		[
			[
				64653,
				61284
			],
			[
				-386,
				7
			],
			[
				-387,
				66
			],
			[
				-195,
				7
			]
		],
		[
			[
				63685,
				61364
			],
			[
				-27,
				-1016
			]
		],
		[
			[
				62348,
				35019
			],
			[
				382,
				-31
			]
		],
		[
			[
				62730,
				34988
			],
			[
				381,
				-32
			]
		],
		[
			[
				63111,
				34956
			],
			[
				31,
				1006
			]
		],
		[
			[
				63142,
				35962
			],
			[
				10,
				336
			]
		],
		[
			[
				63152,
				36298
			],
			[
				-770,
				63
			]
		],
		[
			[
				62382,
				36361
			],
			[
				-34,
				-1342
			]
		],
		[
			[
				54124,
				49887
			],
			[
				4,
				-838
			]
		],
		[
			[
				54128,
				49049
			],
			[
				702,
				1
			]
		],
		[
			[
				54830,
				49050
			],
			[
				-7,
				1177
			]
		],
		[
			[
				54823,
				50227
			],
			[
				1,
				673
			],
			[
				-16,
				343
			]
		],
		[
			[
				54808,
				51243
			],
			[
				-693,
				-9
			]
		],
		[
			[
				54115,
				51234
			],
			[
				9,
				-1347
			]
		],
		[
			[
				45882,
				48790
			],
			[
				534,
				51
			]
		],
		[
			[
				46416,
				48841
			],
			[
				226,
				18
			]
		],
		[
			[
				46642,
				48859
			],
			[
				-43,
				1681
			]
		],
		[
			[
				46599,
				50540
			],
			[
				-726,
				-65
			]
		],
		[
			[
				45873,
				50475
			],
			[
				-39,
				-4
			]
		],
		[
			[
				45834,
				50471
			],
			[
				48,
				-1681
			]
		],
		[
			[
				60515,
				79171
			],
			[
				297,
				-7
			],
			[
				-2,
				-55
			]
		],
		[
			[
				60810,
				79109
			],
			[
				432,
				-49
			]
		],
		[
			[
				61242,
				79060
			],
			[
				26,
				1205
			],
			[
				28,
				70
			],
			[
				-84,
				292
			]
		],
		[
			[
				61212,
				80627
			],
			[
				-276,
				15
			],
			[
				0,
				194
			],
			[
				-557,
				27
			],
			[
				-125,
				64
			]
		],
		[
			[
				60254,
				80927
			],
			[
				-10,
				-667
			],
			[
				290,
				-17
			],
			[
				-19,
				-1072
			]
		],
		[
			[
				58301,
				27967
			],
			[
				388,
				-16
			]
		],
		[
			[
				58689,
				27951
			],
			[
				190,
				-11
			]
		],
		[
			[
				58879,
				27940
			],
			[
				21,
				1336
			]
		],
		[
			[
				58900,
				29276
			],
			[
				-388,
				17
			]
		],
		[
			[
				58512,
				29293
			],
			[
				-192,
				9
			]
		],
		[
			[
				58320,
				29302
			],
			[
				-19,
				-1335
			]
		],
		[
			[
				62409,
				55274
			],
			[
				-23,
				-1443
			]
		],
		[
			[
				62386,
				53831
			],
			[
				764,
				-34
			]
		],
		[
			[
				63150,
				53797
			],
			[
				64,
				-3
			],
			[
				11,
				394
			],
			[
				36,
				219
			],
			[
				64,
				-4
			],
			[
				1,
				213
			],
			[
				66,
				-20
			],
			[
				35,
				153
			],
			[
				64,
				-31
			],
			[
				38,
				281
			]
		],
		[
			[
				63529,
				54999
			],
			[
				-351,
				68
			],
			[
				13,
				780
			]
		],
		[
			[
				63191,
				55847
			],
			[
				-770,
				45
			]
		],
		[
			[
				62421,
				55892
			],
			[
				-12,
				-618
			]
		],
		[
			[
				53058,
				36113
			],
			[
				322,
				6
			]
		],
		[
			[
				53380,
				36119
			],
			[
				442,
				7
			],
			[
				0,
				285
			]
		],
		[
			[
				53822,
				36411
			],
			[
				-4,
				1055
			]
		],
		[
			[
				53818,
				37466
			],
			[
				-581,
				-11
			]
		],
		[
			[
				53237,
				37455
			],
			[
				-188,
				-4
			]
		],
		[
			[
				53049,
				37451
			],
			[
				9,
				-1338
			]
		],
		[
			[
				74951,
				41294
			],
			[
				-12,
				-345
			]
		],
		[
			[
				74939,
				40949
			],
			[
				390,
				-34
			],
			[
				3,
				58
			],
			[
				397,
				-27
			]
		],
		[
			[
				75729,
				40946
			],
			[
				96,
				-7
			],
			[
				18,
				439
			]
		],
		[
			[
				75843,
				41378
			],
			[
				9,
				392
			]
		],
		[
			[
				75852,
				41770
			],
			[
				-883,
				79
			]
		],
		[
			[
				74969,
				41849
			],
			[
				-18,
				-555
			]
		],
		[
			[
				74097,
				38343
			],
			[
				95,
				-21
			],
			[
				-47,
				-669
			]
		],
		[
			[
				74145,
				37653
			],
			[
				187,
				-41
			]
		],
		[
			[
				74332,
				37612
			],
			[
				575,
				-132
			]
		],
		[
			[
				74907,
				37480
			],
			[
				72,
				946
			]
		],
		[
			[
				74979,
				38426
			],
			[
				-191,
				43
			],
			[
				4,
				57
			],
			[
				-190,
				42
			],
			[
				13,
				168
			],
			[
				-384,
				89
			]
		],
		[
			[
				74231,
				38825
			],
			[
				-13,
				-171
			],
			[
				-95,
				22
			],
			[
				-26,
				-333
			]
		],
		[
			[
				52814,
				71203
			],
			[
				351,
				13
			]
		],
		[
			[
				53165,
				71216
			],
			[
				609,
				27
			]
		],
		[
			[
				53774,
				71243
			],
			[
				-6,
				649
			]
		],
		[
			[
				53768,
				71892
			],
			[
				-11,
				1032
			],
			[
				-13,
				0
			]
		],
		[
			[
				53744,
				72924
			],
			[
				-952,
				-27
			]
		],
		[
			[
				52792,
				72897
			],
			[
				22,
				-1694
			]
		],
		[
			[
				43860,
				74124
			],
			[
				153,
				14
			]
		],
		[
			[
				44013,
				74138
			],
			[
				810,
				81
			]
		],
		[
			[
				44823,
				74219
			],
			[
				-60,
				1672
			]
		],
		[
			[
				44763,
				75891
			],
			[
				-962,
				-97
			]
		],
		[
			[
				43801,
				75794
			],
			[
				59,
				-1670
			]
		],
		[
			[
				49295,
				67826
			],
			[
				976,
				39
			]
		],
		[
			[
				50271,
				67865
			],
			[
				-17,
				1421
			]
		],
		[
			[
				50254,
				69286
			],
			[
				-3,
				272
			]
		],
		[
			[
				50251,
				69558
			],
			[
				-988,
				-53
			]
		],
		[
			[
				49263,
				69505
			],
			[
				32,
				-1679
			]
		],
		[
			[
				61343,
				50549
			],
			[
				298,
				-8
			],
			[
				659,
				-48
			]
		],
		[
			[
				62300,
				50493
			],
			[
				14,
				520
			],
			[
				25,
				-2
			]
		],
		[
			[
				62339,
				51011
			],
			[
				7,
				396
			],
			[
				-458,
				34
			],
			[
				-119,
				169
			],
			[
				-96,
				3
			]
		],
		[
			[
				61673,
				51613
			],
			[
				-287,
				-1
			]
		],
		[
			[
				61386,
				51612
			],
			[
				-6,
				-559
			],
			[
				-28,
				0
			],
			[
				-9,
				-504
			]
		],
		[
			[
				50187,
				40038
			],
			[
				727,
				34
			]
		],
		[
			[
				50914,
				40072
			],
			[
				-2,
				685
			]
		],
		[
			[
				50912,
				40757
			],
			[
				-10,
				656
			]
		],
		[
			[
				50902,
				41413
			],
			[
				-737,
				-35
			]
		],
		[
			[
				50165,
				41378
			],
			[
				22,
				-1340
			]
		],
		[
			[
				42606,
				35662
			],
			[
				1095,
				131
			]
		],
		[
			[
				43701,
				35793
			],
			[
				33,
				2
			]
		],
		[
			[
				43734,
				35795
			],
			[
				-33,
				783
			],
			[
				13,
				226
			],
			[
				47,
				6
			],
			[
				-48,
				1169
			]
		],
		[
			[
				43713,
				37979
			],
			[
				-1215,
				-148
			]
		],
		[
			[
				42498,
				37831
			],
			[
				44,
				-1005
			]
		],
		[
			[
				42542,
				36826
			],
			[
				20,
				-168
			],
			[
				44,
				-996
			]
		],
		[
			[
				70041,
				78968
			],
			[
				-47,
				-257
			],
			[
				64,
				-232
			],
			[
				78,
				-72
			],
			[
				-81,
				-218
			],
			[
				100,
				-205
			],
			[
				-84,
				-105
			],
			[
				30,
				-317
			],
			[
				-75,
				-149
			],
			[
				58,
				-230
			],
			[
				-65,
				-50
			]
		],
		[
			[
				70019,
				77133
			],
			[
				44,
				-123
			]
		],
		[
			[
				70063,
				77010
			],
			[
				127,
				-38
			],
			[
				81,
				-310
			],
			[
				116,
				-102
			]
		],
		[
			[
				70387,
				76560
			],
			[
				89,
				-35
			],
			[
				86,
				145
			],
			[
				118,
				50
			]
		],
		[
			[
				70680,
				76720
			],
			[
				50,
				944
			],
			[
				32,
				-5
			]
		],
		[
			[
				70762,
				77659
			],
			[
				24,
				123
			],
			[
				-67,
				390
			],
			[
				188,
				307
			],
			[
				31,
				130
			],
			[
				254,
				224
			],
			[
				34,
				165
			],
			[
				-79,
				476
			],
			[
				74,
				193
			],
			[
				89,
				89
			],
			[
				-101,
				97
			],
			[
				-35,
				248
			],
			[
				-81,
				121
			],
			[
				59,
				51
			],
			[
				-94,
				99
			]
		],
		[
			[
				71058,
				80372
			],
			[
				-260,
				157
			],
			[
				-344,
				138
			],
			[
				-239,
				26
			],
			[
				45,
				-129
			],
			[
				86,
				72
			],
			[
				239,
				-124
			],
			[
				16,
				-113
			],
			[
				-189,
				-303
			],
			[
				-123,
				-105
			],
			[
				-67,
				-290
			],
			[
				44,
				-199
			],
			[
				-29,
				-297
			],
			[
				-54,
				-152
			],
			[
				-142,
				-85
			]
		],
		[
			[
				20453,
				63748
			],
			[
				167,
				-329
			],
			[
				71,
				-56
			],
			[
				45,
				-484
			],
			[
				69,
				-46
			],
			[
				6,
				-591
			],
			[
				57,
				-59
			],
			[
				-30,
				-272
			],
			[
				220,
				-292
			],
			[
				24,
				-195
			]
		],
		[
			[
				21082,
				61424
			],
			[
				47,
				-94
			],
			[
				185,
				-59
			],
			[
				135,
				-93
			],
			[
				206,
				-263
			],
			[
				58,
				-158
			]
		],
		[
			[
				21713,
				60757
			],
			[
				87,
				46
			],
			[
				85,
				182
			],
			[
				322,
				140
			],
			[
				12,
				107
			],
			[
				187,
				-2
			],
			[
				52,
				135
			],
			[
				184,
				-16
			],
			[
				150,
				-254
			],
			[
				315,
				148
			],
			[
				61,
				-73
			]
		],
		[
			[
				23168,
				61170
			],
			[
				-131,
				1206
			]
		],
		[
			[
				23037,
				62376
			],
			[
				-254,
				2355
			]
		],
		[
			[
				22783,
				64731
			],
			[
				-1137,
				-366
			],
			[
				37,
				-329
			],
			[
				-564,
				-182
			],
			[
				-188,
				1637
			],
			[
				-454,
				-143
			]
		],
		[
			[
				20477,
				65348
			],
			[
				-155,
				-44
			],
			[
				-122,
				-91
			],
			[
				-42,
				-247
			],
			[
				80,
				-216
			],
			[
				53,
				-417
			],
			[
				-85,
				-155
			],
			[
				84,
				-202
			],
			[
				-29,
				-178
			],
			[
				192,
				-50
			]
		],
		[
			[
				42537,
				45075
			],
			[
				598,
				67
			]
		],
		[
			[
				43135,
				45142
			],
			[
				1284,
				124
			]
		],
		[
			[
				44419,
				45266
			],
			[
				-1,
				23
			]
		],
		[
			[
				44418,
				45289
			],
			[
				-58,
				1676
			]
		],
		[
			[
				44360,
				46965
			],
			[
				-11,
				331
			]
		],
		[
			[
				44349,
				47296
			],
			[
				-1150,
				-99
			],
			[
				-767,
				-92
			]
		],
		[
			[
				42432,
				47105
			],
			[
				29,
				-346
			],
			[
				76,
				-1684
			]
		],
		[
			[
				73373,
				43445
			],
			[
				-3,
				-56
			],
			[
				373,
				-65
			]
		],
		[
			[
				73743,
				43324
			],
			[
				40,
				610
			]
		],
		[
			[
				73783,
				43934
			],
			[
				12,
				175
			]
		],
		[
			[
				73795,
				44109
			],
			[
				-376,
				59
			]
		],
		[
			[
				73419,
				44168
			],
			[
				-46,
				-723
			]
		],
		[
			[
				44971,
				53775
			],
			[
				54,
				6
			]
		],
		[
			[
				45025,
				53781
			],
			[
				766,
				75
			]
		],
		[
			[
				45791,
				53856
			],
			[
				38,
				4
			]
		],
		[
			[
				45829,
				53860
			],
			[
				-45,
				1502
			]
		],
		[
			[
				45784,
				55362
			],
			[
				-863,
				-72
			]
		],
		[
			[
				44921,
				55290
			],
			[
				50,
				-1515
			]
		],
		[
			[
				70427,
				24404
			],
			[
				407,
				-59
			]
		],
		[
			[
				70834,
				24345
			],
			[
				59,
				997
			]
		],
		[
			[
				70893,
				25342
			],
			[
				-659,
				98
			]
		],
		[
			[
				70234,
				25440
			],
			[
				10,
				-188
			],
			[
				-72,
				-301
			],
			[
				21,
				-227
			],
			[
				218,
				-175
			],
			[
				16,
				-145
			]
		],
		[
			[
				26874,
				17822
			],
			[
				54,
				-31
			],
			[
				12,
				-180
			],
			[
				85,
				-110
			],
			[
				49,
				136
			],
			[
				241,
				-283
			],
			[
				165,
				27
			],
			[
				110,
				-85
			],
			[
				42,
				-210
			]
		],
		[
			[
				27632,
				17086
			],
			[
				31,
				20
			],
			[
				85,
				686
			],
			[
				109,
				-147
			],
			[
				95,
				11
			],
			[
				91,
				-159
			],
			[
				85,
				-47
			],
			[
				118,
				51
			],
			[
				14,
				86
			]
		],
		[
			[
				28260,
				17587
			],
			[
				128,
				242
			],
			[
				121,
				147
			],
			[
				174,
				117
			],
			[
				44,
				315
			],
			[
				51,
				52
			],
			[
				1,
				201
			]
		],
		[
			[
				28779,
				18661
			],
			[
				-63,
				337
			],
			[
				1,
				193
			],
			[
				81,
				191
			],
			[
				75,
				65
			],
			[
				115,
				-17
			],
			[
				45,
				205
			],
			[
				-106,
				1153
			],
			[
				191,
				58
			],
			[
				-34,
				331
			],
			[
				222,
				66
			],
			[
				-32,
				331
			],
			[
				37,
				12
			],
			[
				-33,
				323
			],
			[
				158,
				50
			],
			[
				-16,
				160
			],
			[
				443,
				138
			],
			[
				-27,
				272
			],
			[
				468,
				134
			]
		],
		[
			[
				30304,
				22663
			],
			[
				-2,
				167
			],
			[
				-104,
				206
			],
			[
				69,
				225
			],
			[
				-54,
				31
			],
			[
				-124,
				-124
			],
			[
				-55,
				37
			]
		],
		[
			[
				30034,
				23205
			],
			[
				-132,
				-80
			],
			[
				-203,
				140
			],
			[
				-56,
				-229
			],
			[
				-183,
				54
			],
			[
				-78,
				-62
			],
			[
				-126,
				64
			],
			[
				-116,
				-82
			],
			[
				-79,
				-159
			],
			[
				-67,
				-22
			],
			[
				-129,
				116
			],
			[
				-76,
				292
			],
			[
				-122,
				-161
			],
			[
				-49,
				51
			],
			[
				-71,
				-106
			],
			[
				-172,
				-55
			],
			[
				-84,
				-98
			],
			[
				-105,
				44
			],
			[
				-110,
				208
			],
			[
				16,
				183
			]
		],
		[
			[
				28092,
				23303
			],
			[
				-55,
				56
			],
			[
				-50,
				-162
			],
			[
				-162,
				-264
			],
			[
				-17,
				-185
			],
			[
				41,
				-100
			],
			[
				-89,
				-324
			],
			[
				55,
				-57
			],
			[
				1,
				-227
			],
			[
				-40,
				-82
			],
			[
				-22,
				-300
			],
			[
				-160,
				-245
			],
			[
				-163,
				97
			],
			[
				-8,
				-139
			],
			[
				-135,
				-221
			],
			[
				-28,
				-332
			],
			[
				84,
				-18
			],
			[
				28,
				-377
			],
			[
				-92,
				-161
			],
			[
				19,
				-80
			],
			[
				-89,
				-80
			],
			[
				-13,
				-248
			],
			[
				-106,
				-278
			],
			[
				-49,
				-294
			],
			[
				30,
				-252
			],
			[
				-51,
				-260
			],
			[
				56,
				-237
			],
			[
				-106,
				-35
			],
			[
				77,
				-292
			],
			[
				-124,
				-203
			],
			[
				-50,
				-181
			]
		],
		[
			[
				90756,
				32694
			],
			[
				318,
				-847
			]
		],
		[
			[
				91074,
				31847
			],
			[
				-1,
				12
			]
		],
		[
			[
				91073,
				31859
			],
			[
				74,
				92
			],
			[
				-16,
				177
			],
			[
				192,
				356
			],
			[
				62,
				534
			]
		],
		[
			[
				91385,
				33018
			],
			[
				-590,
				-305
			]
		],
		[
			[
				90795,
				32713
			],
			[
				-39,
				-19
			]
		],
		[
			[
				16233,
				13824
			],
			[
				109,
				-69
			],
			[
				276,
				-49
			],
			[
				66,
				-55
			]
		],
		[
			[
				16684,
				13651
			],
			[
				287,
				699
			],
			[
				-15,
				138
			],
			[
				42,
				209
			],
			[
				-221,
				58
			],
			[
				-148,
				410
			],
			[
				-75,
				349
			],
			[
				15,
				190
			],
			[
				62,
				68
			],
			[
				22,
				207
			],
			[
				-115,
				193
			]
		],
		[
			[
				16538,
				16172
			],
			[
				-335,
				-155
			],
			[
				27,
				-174
			],
			[
				-56,
				-151
			],
			[
				-166,
				-237
			],
			[
				-173,
				-105
			],
			[
				23,
				-298
			],
			[
				297,
				-262
			],
			[
				40,
				-203
			],
			[
				100,
				-235
			],
			[
				-76,
				-84
			],
			[
				14,
				-444
			]
		],
		[
			[
				88699,
				49173
			],
			[
				12,
				-192
			]
		],
		[
			[
				88711,
				48981
			],
			[
				42,
				-39
			],
			[
				240,
				388
			],
			[
				42,
				8
			]
		],
		[
			[
				89035,
				49338
			],
			[
				24,
				293
			],
			[
				107,
				75
			]
		],
		[
			[
				89166,
				49706
			],
			[
				-62,
				100
			],
			[
				-88,
				-196
			],
			[
				-196,
				-127
			],
			[
				-112,
				-126
			],
			[
				-9,
				-184
			]
		],
		[
			[
				40253,
				26930
			],
			[
				1121,
				156
			],
			[
				510,
				80
			]
		],
		[
			[
				41884,
				27166
			],
			[
				-7,
				151
			]
		],
		[
			[
				41877,
				27317
			],
			[
				-56,
				1099
			]
		],
		[
			[
				41821,
				28416
			],
			[
				-66,
				1339
			]
		],
		[
			[
				41755,
				29755
			],
			[
				-1359,
				-193
			]
		],
		[
			[
				40396,
				29562
			],
			[
				-289,
				-43
			]
		],
		[
			[
				40107,
				29519
			],
			[
				146,
				-2589
			]
		],
		[
			[
				59480,
				65692
			],
			[
				840,
				-14
			],
			[
				-4,
				-230
			],
			[
				63,
				-1
			],
			[
				-7,
				-222
			],
			[
				223,
				-3
			]
		],
		[
			[
				60595,
				65222
			],
			[
				100,
				-5
			],
			[
				1,
				114
			],
			[
				98,
				-3
			],
			[
				35,
				225
			]
		],
		[
			[
				60829,
				65553
			],
			[
				3,
				1019
			]
		],
		[
			[
				60832,
				66572
			],
			[
				-382,
				-1
			]
		],
		[
			[
				60450,
				66571
			],
			[
				-255,
				-2
			],
			[
				-22,
				-293
			],
			[
				-303,
				-30
			],
			[
				0,
				-113
			],
			[
				-291,
				3
			],
			[
				-2,
				-225
			],
			[
				-95,
				1
			]
		],
		[
			[
				59482,
				65912
			],
			[
				-2,
				-220
			]
		],
		[
			[
				72903,
				43254
			],
			[
				136,
				-30
			]
		],
		[
			[
				73039,
				43224
			],
			[
				63,
				-12
			],
			[
				16,
				280
			],
			[
				255,
				-47
			]
		],
		[
			[
				73419,
				44168
			],
			[
				-444,
				90
			]
		],
		[
			[
				72975,
				44258
			],
			[
				-72,
				-1004
			]
		],
		[
			[
				71178,
				45308
			],
			[
				220,
				-46
			]
		],
		[
			[
				71398,
				45262
			],
			[
				283,
				-60
			]
		],
		[
			[
				71681,
				45202
			],
			[
				78,
				1127
			]
		],
		[
			[
				71759,
				46329
			],
			[
				-407,
				77
			]
		],
		[
			[
				71352,
				46406
			],
			[
				-88,
				17
			],
			[
				-51,
				-776
			],
			[
				-35,
				-339
			]
		],
		[
			[
				65551,
				67832
			],
			[
				-12,
				-337
			],
			[
				291,
				-33
			],
			[
				94,
				-64
			],
			[
				-11,
				-285
			]
		],
		[
			[
				65913,
				67113
			],
			[
				289,
				-29
			],
			[
				5,
				115
			],
			[
				480,
				-56
			]
		],
		[
			[
				66687,
				67143
			],
			[
				23,
				559
			]
		],
		[
			[
				66710,
				67702
			],
			[
				8,
				170
			]
		],
		[
			[
				66718,
				67872
			],
			[
				-516,
				63
			]
		],
		[
			[
				66202,
				67935
			],
			[
				-628,
				67
			]
		],
		[
			[
				65574,
				68002
			],
			[
				-23,
				-170
			]
		],
		[
			[
				47710,
				35876
			],
			[
				164,
				13
			]
		],
		[
			[
				47874,
				35889
			],
			[
				792,
				55
			]
		],
		[
			[
				48666,
				35944
			],
			[
				-30,
				1330
			]
		],
		[
			[
				48636,
				37274
			],
			[
				-934,
				-64
			]
		],
		[
			[
				47702,
				37210
			],
			[
				-23,
				-2
			]
		],
		[
			[
				47679,
				37208
			],
			[
				31,
				-1332
			]
		],
		[
			[
				85056,
				55504
			],
			[
				-35,
				-1463
			]
		],
		[
			[
				85021,
				54041
			],
			[
				263,
				-70
			]
		],
		[
			[
				85284,
				53971
			],
			[
				52,
				537
			],
			[
				108,
				59
			]
		],
		[
			[
				85444,
				54567
			],
			[
				13,
				154
			],
			[
				101,
				42
			],
			[
				-15,
				217
			],
			[
				-55,
				174
			],
			[
				-86,
				-11
			],
			[
				-21,
				244
			],
			[
				-132,
				38
			]
		],
		[
			[
				85249,
				55425
			],
			[
				-193,
				79
			]
		],
		[
			[
				56154,
				59323
			],
			[
				161,
				-2
			],
			[
				-2,
				-336
			],
			[
				191,
				-4
			]
		],
		[
			[
				56504,
				58981
			],
			[
				383,
				-6
			]
		],
		[
			[
				56887,
				58975
			],
			[
				7,
				1005
			],
			[
				-18,
				0
			],
			[
				8,
				1012
			]
		],
		[
			[
				56884,
				60992
			],
			[
				-577,
				10
			]
		],
		[
			[
				56307,
				61002
			],
			[
				-3,
				-671
			],
			[
				-248,
				2
			]
		],
		[
			[
				56056,
				60333
			],
			[
				60,
				-147
			],
			[
				-91,
				-203
			],
			[
				5,
				-232
			],
			[
				94,
				-175
			],
			[
				30,
				-253
			]
		],
		[
			[
				69019,
				57227
			],
			[
				287,
				53
			],
			[
				181,
				-180
			],
			[
				219,
				-30
			]
		],
		[
			[
				69706,
				57070
			],
			[
				15,
				98
			],
			[
				132,
				7
			]
		],
		[
			[
				69853,
				57175
			],
			[
				-1,
				333
			],
			[
				-62,
				284
			]
		],
		[
			[
				69790,
				57792
			],
			[
				-38,
				-71
			],
			[
				-137,
				7
			],
			[
				-67,
				-73
			],
			[
				-184,
				-58
			],
			[
				-62,
				57
			],
			[
				-206,
				-11
			]
		],
		[
			[
				69096,
				57643
			],
			[
				-25,
				-264
			],
			[
				-52,
				-152
			]
		],
		[
			[
				50597,
				77001
			],
			[
				794,
				-773
			]
		],
		[
			[
				51391,
				76228
			],
			[
				147,
				-143
			],
			[
				155,
				477
			]
		],
		[
			[
				51693,
				76562
			],
			[
				345,
				1037
			]
		],
		[
			[
				52038,
				77599
			],
			[
				-940,
				934
			]
		],
		[
			[
				51098,
				78533
			],
			[
				-501,
				-1532
			]
		],
		[
			[
				49227,
				74579
			],
			[
				714,
				-670
			]
		],
		[
			[
				49941,
				73909
			],
			[
				403,
				1248
			],
			[
				146,
				-143
			],
			[
				77,
				244
			]
		],
		[
			[
				50567,
				75258
			],
			[
				94,
				294
			],
			[
				-589,
				582
			]
		],
		[
			[
				50072,
				76134
			],
			[
				-53,
				-155
			],
			[
				-334,
				73
			]
		],
		[
			[
				49685,
				76052
			],
			[
				-458,
				-1473
			]
		],
		[
			[
				86530,
				43600
			],
			[
				-32,
				-23
			]
		],
		[
			[
				86498,
				43577
			],
			[
				32,
				23
			]
		],
		[
			[
				70893,
				25342
			],
			[
				765,
				-142
			]
		],
		[
			[
				71658,
				25200
			],
			[
				14,
				333
			],
			[
				71,
				990
			]
		],
		[
			[
				71743,
				26523
			],
			[
				-364,
				71
			]
		],
		[
			[
				71379,
				26594
			],
			[
				-408,
				78
			]
		],
		[
			[
				70971,
				26672
			],
			[
				-78,
				-1330
			]
		],
		[
			[
				82733,
				50539
			],
			[
				-3,
				178
			]
		],
		[
			[
				82730,
				50717
			],
			[
				-26,
				48
			],
			[
				-134,
				-96
			],
			[
				137,
				-185
			],
			[
				26,
				55
			]
		],
		[
			[
				61614,
				25241
			],
			[
				1150,
				-103
			]
		],
		[
			[
				62764,
				25138
			],
			[
				31,
				998
			]
		],
		[
			[
				62795,
				26136
			],
			[
				-386,
				32
			]
		],
		[
			[
				62409,
				26168
			],
			[
				-576,
				51
			]
		],
		[
			[
				61833,
				26219
			],
			[
				-192,
				14
			]
		],
		[
			[
				61641,
				26233
			],
			[
				-9,
				-333
			]
		],
		[
			[
				61632,
				25900
			],
			[
				-18,
				-659
			]
		],
		[
			[
				44675,
				58898
			],
			[
				962,
				94
			]
		],
		[
			[
				45637,
				58992
			],
			[
				-53,
				1656
			]
		],
		[
			[
				45584,
				60648
			],
			[
				-965,
				-89
			]
		],
		[
			[
				44619,
				60559
			],
			[
				56,
				-1661
			]
		],
		[
			[
				51727,
				60633
			],
			[
				958,
				30
			]
		],
		[
			[
				52685,
				60663
			],
			[
				-8,
				1005
			]
		],
		[
			[
				52677,
				61668
			],
			[
				-3,
				336
			]
		],
		[
			[
				52674,
				62004
			],
			[
				-956,
				-26
			]
		],
		[
			[
				51718,
				61978
			],
			[
				9,
				-1345
			]
		],
		[
			[
				25648,
				51421
			],
			[
				2071,
				598
			]
		],
		[
			[
				27719,
				52019
			],
			[
				1047,
				278
			]
		],
		[
			[
				28766,
				52297
			],
			[
				-955,
				10439
			]
		],
		[
			[
				27811,
				62736
			],
			[
				-51,
				-192
			],
			[
				-123,
				136
			],
			[
				-77,
				-164
			],
			[
				-107,
				-76
			],
			[
				-67,
				-183
			],
			[
				-153,
				-197
			],
			[
				-99,
				56
			],
			[
				-101,
				-157
			],
			[
				-57,
				-191
			],
			[
				-145,
				79
			],
			[
				-43,
				-84
			],
			[
				-87,
				34
			],
			[
				-23,
				178
			],
			[
				-46,
				-40
			],
			[
				7,
				-233
			],
			[
				-150,
				77
			],
			[
				-67,
				-32
			],
			[
				156,
				-105
			],
			[
				-145,
				-98
			]
		],
		[
			[
				26433,
				61544
			],
			[
				126,
				-1257
			],
			[
				-405,
				-119
			],
			[
				66,
				-674
			],
			[
				-439,
				-100
			],
			[
				-560,
				-161
			],
			[
				65,
				-666
			],
			[
				-189,
				-61
			],
			[
				45,
				-421
			],
			[
				-65,
				67
			],
			[
				-185,
				-75
			],
			[
				-182,
				-322
			],
			[
				-109,
				-13
			],
			[
				-333,
				-297
			],
			[
				-26,
				-152
			],
			[
				-271,
				-420
			],
			[
				-35,
				-120
			],
			[
				-147,
				-137
			],
			[
				-128,
				-34
			]
		],
		[
			[
				23661,
				56582
			],
			[
				113,
				-1043
			],
			[
				64,
				-210
			],
			[
				-20,
				-208
			],
			[
				53,
				-200
			],
			[
				-29,
				-320
			],
			[
				81,
				-172
			],
			[
				162,
				76
			],
			[
				64,
				-208
			],
			[
				117,
				-31
			],
			[
				260,
				-201
			],
			[
				198,
				-2
			],
			[
				65,
				-88
			],
			[
				123,
				32
			],
			[
				161,
				-201
			],
			[
				69,
				44
			],
			[
				123,
				-227
			],
			[
				-22,
				-153
			],
			[
				56,
				-127
			],
			[
				-47,
				-136
			],
			[
				16,
				-152
			],
			[
				87,
				-209
			],
			[
				-8,
				-230
			],
			[
				69,
				-237
			],
			[
				-2,
				-121
			],
			[
				134,
				-444
			],
			[
				97,
				-203
			],
			[
				3,
				-190
			]
		],
		[
			[
				25334,
				93326
			],
			[
				53,
				-62
			],
			[
				87,
				232
			],
			[
				-10,
				219
			]
		],
		[
			[
				25464,
				93715
			],
			[
				-147,
				-158
			],
			[
				55,
				-116
			],
			[
				-38,
				-115
			]
		],
		[
			[
				25034,
				95023
			],
			[
				14,
				-161
			],
			[
				-46,
				-173
			],
			[
				92,
				49
			],
			[
				55,
				249
			],
			[
				-115,
				36
			]
		],
		[
			[
				24024,
				95001
			],
			[
				0,
				-142
			],
			[
				132,
				-88
			],
			[
				66,
				85
			],
			[
				-25,
				152
			],
			[
				-95,
				166
			],
			[
				-7,
				-170
			],
			[
				-71,
				-3
			]
		],
		[
			[
				23894,
				94360
			],
			[
				61,
				-318
			],
			[
				-63,
				-29
			],
			[
				7,
				-201
			],
			[
				181,
				-50
			],
			[
				182,
				317
			],
			[
				134,
				63
			],
			[
				103,
				138
			],
			[
				166,
				313
			],
			[
				-134,
				-41
			],
			[
				-16,
				137
			],
			[
				77,
				-81
			],
			[
				139,
				130
			],
			[
				20,
				194
			],
			[
				78,
				-104
			],
			[
				28,
				94
			],
			[
				-17,
				286
			],
			[
				53,
				-91
			],
			[
				72,
				293
			],
			[
				-8,
				132
			],
			[
				-225,
				18
			],
			[
				52,
				-130
			],
			[
				-141,
				-80
			],
			[
				-37,
				-261
			],
			[
				-58,
				78
			],
			[
				28,
				178
			],
			[
				-83,
				-21
			],
			[
				-48,
				-206
			],
			[
				-88,
				-9
			],
			[
				184,
				397
			],
			[
				57,
				-93
			],
			[
				90,
				178
			],
			[
				-71,
				-22
			],
			[
				68,
				159
			],
			[
				-88,
				20
			],
			[
				-187,
				-257
			],
			[
				-92,
				-329
			],
			[
				-80,
				70
			],
			[
				-35,
				-149
			],
			[
				133,
				-186
			],
			[
				-117,
				-158
			],
			[
				-235,
				-176
			],
			[
				49,
				-97
			],
			[
				-18,
				-246
			],
			[
				-64,
				209
			],
			[
				-57,
				-69
			]
		],
		[
			[
				23847,
				92914
			],
			[
				44,
				99
			],
			[
				-82,
				102
			],
			[
				126,
				477
			]
		],
		[
			[
				23935,
				93592
			],
			[
				-92,
				116
			],
			[
				-45,
				-119
			],
			[
				6,
				641
			],
			[
				-54,
				147
			],
			[
				-77,
				-120
			],
			[
				-68,
				-291
			],
			[
				-1,
				-247
			],
			[
				-56,
				-4
			],
			[
				-106,
				-356
			],
			[
				39,
				-143
			],
			[
				94,
				-32
			],
			[
				70,
				128
			],
			[
				-28,
				-204
			],
			[
				-68,
				-59
			],
			[
				50,
				-123
			],
			[
				248,
				-12
			]
		],
		[
			[
				23479,
				94128
			],
			[
				13,
				155
			]
		],
		[
			[
				23492,
				94283
			],
			[
				-13,
				-155
			]
		],
		[
			[
				69190,
				45100
			],
			[
				-27,
				-500
			]
		],
		[
			[
				69163,
				44600
			],
			[
				254,
				-42
			]
		],
		[
			[
				69417,
				44558
			],
			[
				312,
				-46
			]
		],
		[
			[
				69729,
				44512
			],
			[
				16,
				336
			],
			[
				-66,
				8
			],
			[
				52,
				1005
			]
		],
		[
			[
				69731,
				45861
			],
			[
				-621,
				95
			]
		],
		[
			[
				69110,
				45956
			],
			[
				-5,
				-207
			],
			[
				111,
				-152
			],
			[
				-26,
				-497
			]
		],
		[
			[
				70678,
				32118
			],
			[
				691,
				-127
			]
		],
		[
			[
				71369,
				31991
			],
			[
				384,
				-71
			]
		],
		[
			[
				71753,
				31920
			],
			[
				90,
				1324
			]
		],
		[
			[
				71843,
				33244
			],
			[
				-361,
				71
			]
		],
		[
			[
				71482,
				33315
			],
			[
				-830,
				155
			]
		],
		[
			[
				70652,
				33470
			],
			[
				25,
				-241
			],
			[
				15,
				-927
			],
			[
				-14,
				-184
			]
		],
		[
			[
				68053,
				73313
			],
			[
				905,
				-133
			]
		],
		[
			[
				68958,
				73180
			],
			[
				-20,
				1289
			]
		],
		[
			[
				68938,
				74469
			],
			[
				-279,
				181
			],
			[
				-257,
				113
			],
			[
				-276,
				72
			]
		],
		[
			[
				68126,
				74835
			],
			[
				-73,
				-1522
			]
		],
		[
			[
				24960,
				6235
			],
			[
				231,
				-1844
			]
		],
		[
			[
				25191,
				4391
			],
			[
				1222,
				445
			],
			[
				700,
				237
			]
		],
		[
			[
				27113,
				5073
			],
			[
				50,
				122
			],
			[
				-65,
				111
			],
			[
				-2,
				371
			],
			[
				-73,
				102
			],
			[
				84,
				66
			],
			[
				66,
				207
			],
			[
				-109,
				67
			],
			[
				-39,
				158
			],
			[
				69,
				105
			],
			[
				-365,
				-124
			],
			[
				-38,
				316
			],
			[
				57,
				19
			],
			[
				-149,
				1290
			],
			[
				-253,
				-87
			],
			[
				-78,
				779
			]
		],
		[
			[
				26268,
				8575
			],
			[
				-198,
				-70
			],
			[
				-109,
				348
			],
			[
				-147,
				56
			],
			[
				-51,
				-135
			],
			[
				-305,
				-67
			],
			[
				-33,
				-124
			],
			[
				44,
				-140
			],
			[
				-103,
				-421
			],
			[
				15,
				-214
			],
			[
				-52,
				-181
			],
			[
				46,
				-107
			],
			[
				-69,
				-229
			],
			[
				-108,
				107
			],
			[
				-219,
				76
			],
			[
				-45,
				112
			],
			[
				-74,
				-67
			],
			[
				-33,
				-226
			]
		],
		[
			[
				24827,
				7293
			],
			[
				133,
				-1058
			]
		],
		[
			[
				76474,
				35259
			],
			[
				-5,
				-240
			],
			[
				90,
				37
			],
			[
				-85,
				203
			]
		],
		[
			[
				75542,
				35585
			],
			[
				410,
				-111
			]
		],
		[
			[
				75952,
				35474
			],
			[
				172,
				69
			],
			[
				127,
				191
			],
			[
				115,
				67
			],
			[
				117,
				-160
			],
			[
				25,
				-159
			],
			[
				94,
				160
			],
			[
				111,
				-32
			],
			[
				53,
				174
			]
		],
		[
			[
				76766,
				35784
			],
			[
				-85,
				86
			],
			[
				-117,
				-2
			],
			[
				-210,
				154
			]
		],
		[
			[
				76354,
				36022
			],
			[
				-636,
				150
			],
			[
				-14,
				-166
			],
			[
				-124,
				32
			]
		],
		[
			[
				75580,
				36038
			],
			[
				-38,
				-453
			]
		],
		[
			[
				91362,
				17161
			],
			[
				376,
				-145
			],
			[
				628,
				-293
			]
		],
		[
			[
				92366,
				16723
			],
			[
				9,
				395
			],
			[
				100,
				78
			],
			[
				-102,
				400
			],
			[
				62,
				42
			]
		],
		[
			[
				92435,
				17638
			],
			[
				-72,
				310
			],
			[
				-185,
				-120
			],
			[
				76,
				227
			],
			[
				-99,
				-34
			],
			[
				-90,
				676
			],
			[
				-195,
				-80
			]
		],
		[
			[
				91870,
				18617
			],
			[
				-193,
				-113
			],
			[
				51,
				-374
			],
			[
				-279,
				-111
			]
		],
		[
			[
				91449,
				18019
			],
			[
				42,
				-223
			],
			[
				-129,
				-635
			]
		],
		[
			[
				11597,
				50490
			],
			[
				772,
				-751
			],
			[
				123,
				-820
			],
			[
				355,
				152
			],
			[
				161,
				3
			],
			[
				199,
				-192
			]
		],
		[
			[
				13207,
				48882
			],
			[
				77,
				38
			],
			[
				-48,
				322
			],
			[
				93,
				42
			],
			[
				-73,
				494
			],
			[
				-94,
				-44
			],
			[
				-85,
				488
			],
			[
				-196,
				1301
			]
		],
		[
			[
				12881,
				51523
			],
			[
				-1142,
				-499
			]
		],
		[
			[
				11705,
				51008
			],
			[
				-47,
				-243
			],
			[
				45,
				-63
			],
			[
				-39,
				-177
			],
			[
				-67,
				-35
			]
		],
		[
			[
				67468,
				51595
			],
			[
				-8,
				-168
			]
		],
		[
			[
				67460,
				51427
			],
			[
				578,
				-81
			]
		],
		[
			[
				68038,
				51346
			],
			[
				55,
				1186
			]
		],
		[
			[
				68093,
				52532
			],
			[
				-65,
				7
			]
		],
		[
			[
				68028,
				52539
			],
			[
				-518,
				72
			]
		],
		[
			[
				67510,
				52611
			],
			[
				-42,
				-1016
			]
		],
		[
			[
				56232,
				35655
			],
			[
				382,
				-4
			]
		],
		[
			[
				56614,
				35651
			],
			[
				377,
				-8
			]
		],
		[
			[
				56991,
				35643
			],
			[
				12,
				1334
			]
		],
		[
			[
				57003,
				36977
			],
			[
				-192,
				6
			]
		],
		[
			[
				56811,
				36983
			],
			[
				-575,
				8
			]
		],
		[
			[
				56236,
				36991
			],
			[
				-4,
				-1336
			]
		],
		[
			[
				46642,
				48859
			],
			[
				760,
				69
			]
		],
		[
			[
				47402,
				48928
			],
			[
				-39,
				1681
			]
		],
		[
			[
				47363,
				50609
			],
			[
				-764,
				-69
			]
		],
		[
			[
				71553,
				29250
			],
			[
				767,
				-139
			]
		],
		[
			[
				72320,
				29111
			],
			[
				383,
				-77
			]
		],
		[
			[
				72703,
				29034
			],
			[
				46,
				664
			],
			[
				17,
				-4
			],
			[
				43,
				662
			]
		],
		[
			[
				72809,
				30356
			],
			[
				-767,
				152
			]
		],
		[
			[
				72042,
				30508
			],
			[
				-44,
				-670
			],
			[
				-402,
				77
			]
		],
		[
			[
				71596,
				29915
			],
			[
				-43,
				-665
			]
		],
		[
			[
				55094,
				20725
			],
			[
				969,
				-7
			]
		],
		[
			[
				56063,
				20718
			],
			[
				16,
				1273
			]
		],
		[
			[
				56079,
				21991
			],
			[
				0,
				55
			]
		],
		[
			[
				56079,
				22046
			],
			[
				-965,
				6
			]
		],
		[
			[
				55114,
				22052
			],
			[
				-2,
				-664
			],
			[
				-17,
				0
			],
			[
				-1,
				-663
			]
		],
		[
			[
				29243,
				9796
			],
			[
				54,
				513
			],
			[
				-43,
				234
			],
			[
				-3,
				263
			],
			[
				103,
				357
			],
			[
				375,
				11
			],
			[
				201,
				171
			],
			[
				23,
				214
			],
			[
				125,
				80
			],
			[
				48,
				149
			],
			[
				346,
				64
			]
		],
		[
			[
				30472,
				11852
			],
			[
				-115,
				1212
			],
			[
				96,
				62
			],
			[
				39,
				167
			],
			[
				225,
				122
			],
			[
				-81,
				806
			],
			[
				200,
				59
			]
		],
		[
			[
				30836,
				14280
			],
			[
				67,
				100
			],
			[
				-74,
				168
			],
			[
				58,
				154
			],
			[
				150,
				110
			],
			[
				-13,
				103
			]
		],
		[
			[
				31024,
				14915
			],
			[
				-153,
				-104
			],
			[
				-70,
				135
			],
			[
				-51,
				635
			],
			[
				-237,
				-66
			]
		],
		[
			[
				30513,
				15515
			],
			[
				-367,
				-103
			],
			[
				-102,
				172
			],
			[
				-77,
				-43
			],
			[
				-115,
				52
			],
			[
				28,
				176
			],
			[
				-156,
				114
			],
			[
				-61,
				-42
			]
		],
		[
			[
				29663,
				15841
			],
			[
				48,
				-578
			],
			[
				48,
				-105
			],
			[
				-23,
				-203
			],
			[
				-136,
				-41
			],
			[
				16,
				-163
			],
			[
				-189,
				-57
			],
			[
				52,
				-505
			],
			[
				-394,
				-110
			],
			[
				135,
				-1293
			],
			[
				-381,
				-118
			],
			[
				109,
				-1169
			],
			[
				-82,
				-32
			],
			[
				-3,
				-407
			]
		],
		[
			[
				28863,
				11060
			],
			[
				50,
				-238
			],
			[
				-32,
				-236
			],
			[
				138,
				-73
			],
			[
				81,
				-498
			],
			[
				-23,
				-133
			],
			[
				166,
				-86
			]
		],
		[
			[
				59654,
				52540
			],
			[
				2,
				-392
			],
			[
				192,
				-4
			]
		],
		[
			[
				59848,
				52144
			],
			[
				380,
				6
			]
		],
		[
			[
				60228,
				52150
			],
			[
				5,
				674
			],
			[
				24,
				-1
			],
			[
				5,
				915
			]
		],
		[
			[
				60262,
				53738
			],
			[
				-383,
				8
			],
			[
				2,
				279
			]
		],
		[
			[
				59881,
				54025
			],
			[
				-192,
				2
			]
		],
		[
			[
				59689,
				54027
			],
			[
				-5,
				-1206
			],
			[
				-32,
				0
			],
			[
				2,
				-281
			]
		],
		[
			[
				82378,
				54815
			],
			[
				-7,
				-78
			]
		],
		[
			[
				82371,
				54737
			],
			[
				735,
				-193
			]
		],
		[
			[
				83106,
				54544
			],
			[
				79,
				942
			]
		],
		[
			[
				83185,
				55486
			],
			[
				-301,
				24
			],
			[
				5,
				116
			],
			[
				-192,
				55
			],
			[
				-120,
				126
			]
		],
		[
			[
				82577,
				55807
			],
			[
				-154,
				-306
			],
			[
				-51,
				77
			]
		],
		[
			[
				82372,
				55578
			],
			[
				-52,
				-165
			],
			[
				119,
				-206
			],
			[
				-61,
				-392
			]
		],
		[
			[
				51729,
				14717
			],
			[
				155,
				5
			]
		],
		[
			[
				51884,
				14722
			],
			[
				621,
				20
			]
		],
		[
			[
				52505,
				14742
			],
			[
				-15,
				1318
			],
			[
				35,
				2
			],
			[
				-3,
				325
			]
		],
		[
			[
				52522,
				16387
			],
			[
				-388,
				-15
			]
		],
		[
			[
				52134,
				16372
			],
			[
				-389,
				-14
			]
		],
		[
			[
				51745,
				16358
			],
			[
				5,
				-325
			],
			[
				-40,
				-2
			],
			[
				19,
				-1314
			]
		],
		[
			[
				50929,
				64635
			],
			[
				767,
				25
			]
		],
		[
			[
				51696,
				64660
			],
			[
				192,
				7
			],
			[
				-5,
				671
			]
		],
		[
			[
				51883,
				65338
			],
			[
				-9,
				839
			]
		],
		[
			[
				51874,
				66177
			],
			[
				-1056,
				-38
			]
		],
		[
			[
				50818,
				66139
			],
			[
				6,
				-837
			]
		],
		[
			[
				50824,
				65302
			],
			[
				9,
				-671
			],
			[
				96,
				4
			]
		],
		[
			[
				48389,
				80589
			],
			[
				652,
				42
			]
		],
		[
			[
				49041,
				80631
			],
			[
				716,
				30
			]
		],
		[
			[
				49757,
				80661
			],
			[
				-15,
				1382
			]
		],
		[
			[
				49742,
				82043
			],
			[
				-641,
				-29
			]
		],
		[
			[
				49101,
				82014
			],
			[
				-735,
				-26
			],
			[
				11,
				-583
			]
		],
		[
			[
				48377,
				81405
			],
			[
				12,
				-816
			]
		],
		[
			[
				48064,
				87681
			],
			[
				1169,
				39
			]
		],
		[
			[
				49233,
				87720
			],
			[
				0,
				1
			]
		],
		[
			[
				49233,
				87721
			],
			[
				-28,
				1696
			],
			[
				-15,
				551
			]
		],
		[
			[
				49190,
				89968
			],
			[
				-1161,
				39
			],
			[
				4,
				-664
			]
		],
		[
			[
				48033,
				89343
			],
			[
				31,
				-1662
			]
		],
		[
			[
				85184,
				47878
			],
			[
				230,
				-898
			]
		],
		[
			[
				85414,
				46980
			],
			[
				460,
				249
			]
		],
		[
			[
				85874,
				47229
			],
			[
				-99,
				648
			]
		],
		[
			[
				85775,
				47877
			],
			[
				-114,
				265
			]
		],
		[
			[
				85661,
				48142
			],
			[
				-304,
				-78
			],
			[
				-110,
				-62
			],
			[
				-63,
				-124
			]
		],
		[
			[
				65473,
				25523
			],
			[
				979,
				-113
			],
			[
				14,
				336
			]
		],
		[
			[
				66466,
				25746
			],
			[
				-207,
				27
			],
			[
				55,
				1327
			]
		],
		[
			[
				66314,
				27100
			],
			[
				-234,
				33
			]
		],
		[
			[
				66080,
				27133
			],
			[
				-539,
				64
			]
		],
		[
			[
				65541,
				27197
			],
			[
				-68,
				-1674
			]
		],
		[
			[
				45111,
				50407
			],
			[
				723,
				64
			]
		],
		[
			[
				45873,
				50475
			],
			[
				-54,
				1678
			],
			[
				24,
				2
			],
			[
				-10,
				358
			]
		],
		[
			[
				45833,
				52513
			],
			[
				-765,
				-73
			]
		],
		[
			[
				45068,
				52440
			],
			[
				12,
				-352
			],
			[
				-27,
				-3
			],
			[
				58,
				-1678
			]
		],
		[
			[
				66521,
				73506
			],
			[
				765,
				-95
			]
		],
		[
			[
				67286,
				73411
			],
			[
				59,
				1337
			],
			[
				19,
				276
			]
		],
		[
			[
				67364,
				75024
			],
			[
				-159,
				39
			]
		],
		[
			[
				67205,
				75063
			],
			[
				-472,
				118
			]
		],
		[
			[
				66733,
				75181
			],
			[
				-38,
				-893
			],
			[
				-19,
				-127
			],
			[
				-128,
				15
			]
		],
		[
			[
				66548,
				74176
			],
			[
				-27,
				-670
			]
		],
		[
			[
				71212,
				41905
			],
			[
				639,
				-127
			]
		],
		[
			[
				71851,
				41778
			],
			[
				66,
				1056
			]
		],
		[
			[
				71917,
				42834
			],
			[
				-123,
				86
			]
		],
		[
			[
				71794,
				42920
			],
			[
				-510,
				96
			]
		],
		[
			[
				71284,
				43016
			],
			[
				-64,
				-977
			]
		],
		[
			[
				71220,
				42039
			],
			[
				-8,
				-134
			]
		],
		[
			[
				50239,
				55663
			],
			[
				349,
				20
			]
		],
		[
			[
				50588,
				55683
			],
			[
				415,
				16
			]
		],
		[
			[
				51003,
				55699
			],
			[
				-6,
				1561
			]
		],
		[
			[
				50997,
				57260
			],
			[
				-7,
				504
			]
		],
		[
			[
				50990,
				57764
			],
			[
				-764,
				-33
			]
		],
		[
			[
				50226,
				57731
			],
			[
				17,
				-1848
			],
			[
				-4,
				-220
			]
		],
		[
			[
				29761,
				35971
			],
			[
				118,
				-1248
			]
		],
		[
			[
				29879,
				34723
			],
			[
				1635,
				438
			]
		],
		[
			[
				31514,
				35161
			],
			[
				-193,
				2212
			]
		],
		[
			[
				31321,
				37373
			],
			[
				-749,
				-183
			],
			[
				-902,
				-254
			],
			[
				91,
				-965
			]
		],
		[
			[
				13921,
				38921
			],
			[
				-36,
				255
			],
			[
				124,
				319
			]
		],
		[
			[
				14009,
				39495
			],
			[
				10,
				256
			],
			[
				-200,
				287
			],
			[
				-220,
				195
			]
		],
		[
			[
				13599,
				40233
			],
			[
				-91,
				0
			],
			[
				-49,
				174
			],
			[
				-136,
				-3
			],
			[
				-150,
				210
			],
			[
				-423,
				4
			],
			[
				-193,
				-57
			],
			[
				-141,
				-238
			],
			[
				-155,
				-121
			],
			[
				-130,
				81
			],
			[
				-89,
				-54
			],
			[
				-153,
				8
			]
		],
		[
			[
				11889,
				40237
			],
			[
				-29,
				-849
			],
			[
				-40,
				2
			]
		],
		[
			[
				11820,
				39390
			],
			[
				128,
				-354
			],
			[
				89,
				-86
			],
			[
				70,
				-241
			],
			[
				67,
				22
			],
			[
				127,
				-120
			],
			[
				129,
				100
			],
			[
				106,
				-143
			],
			[
				139,
				48
			],
			[
				111,
				288
			],
			[
				113,
				174
			],
			[
				184,
				-123
			],
			[
				96,
				-208
			],
			[
				175,
				102
			],
			[
				244,
				75
			],
			[
				88,
				-106
			],
			[
				235,
				103
			]
		],
		[
			[
				75345,
				61410
			],
			[
				540,
				-112
			]
		],
		[
			[
				75885,
				61298
			],
			[
				347,
				-71
			]
		],
		[
			[
				76232,
				61227
			],
			[
				-79,
				155
			],
			[
				147,
				221
			],
			[
				-60,
				178
			],
			[
				24,
				141
			],
			[
				86,
				3
			],
			[
				2,
				285
			],
			[
				-76,
				326
			]
		],
		[
			[
				76276,
				62536
			],
			[
				-43,
				186
			]
		],
		[
			[
				76233,
				62722
			],
			[
				-19,
				-55
			]
		],
		[
			[
				76214,
				62667
			],
			[
				0,
				-81
			],
			[
				-113,
				-76
			],
			[
				-15,
				-205
			],
			[
				-138,
				-317
			],
			[
				-208,
				-151
			],
			[
				-354,
				81
			]
		],
		[
			[
				75386,
				61918
			],
			[
				-41,
				-508
			]
		],
		[
			[
				98072,
				17379
			],
			[
				-8,
				-281
			],
			[
				189,
				209
			],
			[
				-58,
				185
			],
			[
				-123,
				-113
			]
		],
		[
			[
				97258,
				15698
			],
			[
				124,
				30
			],
			[
				42,
				-265
			],
			[
				78,
				32
			],
			[
				22,
				-160
			],
			[
				247,
				-214
			],
			[
				-224,
				-755
			],
			[
				178,
				-158
			],
			[
				-86,
				-299
			],
			[
				180,
				-159
			],
			[
				-128,
				-419
			],
			[
				354,
				-307
			]
		],
		[
			[
				98045,
				13024
			],
			[
				303,
				1029
			],
			[
				-175,
				158
			],
			[
				278,
				916
			],
			[
				27,
				-24
			],
			[
				89,
				292
			],
			[
				-34,
				30
			],
			[
				204,
				589
			]
		],
		[
			[
				98737,
				16014
			],
			[
				-71,
				61
			],
			[
				-14,
				323
			],
			[
				-100,
				-58
			],
			[
				-28,
				-136
			],
			[
				-110,
				98
			],
			[
				50,
				203
			],
			[
				-46,
				269
			],
			[
				74,
				74
			],
			[
				-182,
				149
			],
			[
				-145,
				-34
			],
			[
				-144,
				-295
			],
			[
				16,
				-110
			],
			[
				104,
				-144
			],
			[
				-56,
				-122
			],
			[
				-37,
				147
			],
			[
				-46,
				-141
			],
			[
				-180,
				271
			],
			[
				57,
				128
			],
			[
				-9,
				155
			],
			[
				109,
				174
			],
			[
				10,
				148
			],
			[
				-84,
				309
			],
			[
				56,
				9
			],
			[
				-44,
				155
			]
		],
		[
			[
				97917,
				17647
			],
			[
				-61,
				2
			]
		],
		[
			[
				97856,
				17649
			],
			[
				-121,
				-121
			],
			[
				-59,
				-192
			],
			[
				57,
				-203
			],
			[
				-130,
				-74
			],
			[
				-39,
				-101
			],
			[
				-89,
				115
			],
			[
				-52,
				-360
			],
			[
				34,
				-170
			],
			[
				-78,
				-272
			]
		],
		[
			[
				97379,
				16271
			],
			[
				-20,
				-153
			],
			[
				-110,
				-167
			],
			[
				9,
				-253
			]
		],
		[
			[
				54009,
				29365
			],
			[
				624,
				4
			]
		],
		[
			[
				54633,
				29369
			],
			[
				20,
				0
			]
		],
		[
			[
				54653,
				29369
			],
			[
				-2,
				1340
			]
		],
		[
			[
				54651,
				30709
			],
			[
				-646,
				-4
			]
		],
		[
			[
				54005,
				30705
			],
			[
				4,
				-1340
			]
		],
		[
			[
				46707,
				42493
			],
			[
				945,
				74
			]
		],
		[
			[
				47652,
				42567
			],
			[
				-27,
				1343
			]
		],
		[
			[
				47625,
				43910
			],
			[
				-924,
				-76
			]
		],
		[
			[
				46701,
				43834
			],
			[
				-34,
				-4
			]
		],
		[
			[
				46667,
				43830
			],
			[
				40,
				-1337
			]
		],
		[
			[
				48609,
				42630
			],
			[
				761,
				49
			]
		],
		[
			[
				49370,
				42679
			],
			[
				-26,
				1344
			]
		],
		[
			[
				49344,
				44023
			],
			[
				-756,
				-46
			]
		],
		[
			[
				48588,
				43977
			],
			[
				-5,
				0
			]
		],
		[
			[
				48583,
				43977
			],
			[
				26,
				-1347
			]
		],
		[
			[
				94389,
				28853
			],
			[
				531,
				-269
			],
			[
				12,
				-115
			],
			[
				86,
				-30
			]
		],
		[
			[
				95018,
				28439
			],
			[
				57,
				135
			],
			[
				-24,
				143
			],
			[
				-96,
				30
			],
			[
				85,
				96
			]
		],
		[
			[
				95040,
				28843
			],
			[
				-78,
				97
			],
			[
				-14,
				148
			],
			[
				-486,
				241
			]
		],
		[
			[
				94462,
				29329
			],
			[
				-22,
				-162
			]
		],
		[
			[
				94440,
				29167
			],
			[
				-51,
				-314
			]
		],
		[
			[
				81151,
				41550
			],
			[
				849,
				-243
			]
		],
		[
			[
				82000,
				41307
			],
			[
				256,
				-75
			]
		],
		[
			[
				82256,
				41232
			],
			[
				-6,
				382
			],
			[
				-132,
				594
			],
			[
				26,
				173
			]
		],
		[
			[
				82144,
				42381
			],
			[
				-80,
				-42
			]
		],
		[
			[
				82064,
				42339
			],
			[
				-53,
				-26
			],
			[
				-368,
				-470
			],
			[
				-327,
				88
			],
			[
				-91,
				-74
			]
		],
		[
			[
				81225,
				41857
			],
			[
				-74,
				-307
			]
		],
		[
			[
				81178,
				68136
			],
			[
				-109,
				916
			],
			[
				60,
				109
			]
		],
		[
			[
				81129,
				69161
			],
			[
				-282,
				236
			]
		],
		[
			[
				80847,
				69397
			],
			[
				-170,
				-176
			],
			[
				-164,
				-540
			]
		],
		[
			[
				80513,
				68681
			],
			[
				16,
				-279
			],
			[
				68,
				-146
			],
			[
				228,
				-85
			],
			[
				173,
				-160
			],
			[
				180,
				125
			]
		],
		[
			[
				23531,
				13614
			],
			[
				66,
				235
			],
			[
				169,
				62
			],
			[
				20,
				170
			]
		],
		[
			[
				23786,
				14081
			],
			[
				32,
				299
			],
			[
				137,
				333
			],
			[
				-186,
				-29
			],
			[
				-115,
				195
			],
			[
				-200,
				-190
			],
			[
				-172,
				-113
			],
			[
				-77,
				210
			],
			[
				-57,
				20
			],
			[
				-44,
				328
			],
			[
				-58,
				124
			],
			[
				-43,
				-123
			],
			[
				-102,
				-55
			],
			[
				-10,
				116
			],
			[
				-89,
				-17
			]
		],
		[
			[
				22802,
				15179
			],
			[
				116,
				-901
			],
			[
				32,
				-9
			],
			[
				-7,
				-323
			],
			[
				339,
				127
			],
			[
				43,
				-83
			],
			[
				146,
				86
			],
			[
				38,
				-274
			],
			[
				-51,
				-219
			],
			[
				73,
				31
			]
		],
		[
			[
				25661,
				28828
			],
			[
				271,
				90
			],
			[
				35,
				-322
			],
			[
				80,
				26
			],
			[
				145,
				-1323
			]
		],
		[
			[
				26192,
				27299
			],
			[
				479,
				154
			],
			[
				-142,
				1325
			],
			[
				-95,
				-30
			],
			[
				-74,
				681
			]
		],
		[
			[
				26360,
				29429
			],
			[
				-142,
				4
			],
			[
				-207,
				407
			],
			[
				-141,
				4
			],
			[
				-52,
				-151
			],
			[
				-87,
				57
			],
			[
				-92,
				-85
			],
			[
				-73,
				32
			]
		],
		[
			[
				25566,
				29697
			],
			[
				95,
				-869
			]
		],
		[
			[
				71743,
				40119
			],
			[
				377,
				-75
			]
		],
		[
			[
				72120,
				40044
			],
			[
				317,
				-62
			]
		],
		[
			[
				72437,
				39982
			],
			[
				25,
				332
			]
		],
		[
			[
				72462,
				40314
			],
			[
				51,
				719
			]
		],
		[
			[
				72513,
				41033
			],
			[
				-225,
				41
			]
		],
		[
			[
				72288,
				41074
			],
			[
				-476,
				93
			]
		],
		[
			[
				71812,
				41167
			],
			[
				-6,
				-110
			]
		],
		[
			[
				71806,
				41057
			],
			[
				-41,
				-608
			]
		],
		[
			[
				71765,
				40449
			],
			[
				-22,
				-330
			]
		],
		[
			[
				54553,
				23382
			],
			[
				580,
				-2
			]
		],
		[
			[
				55133,
				23380
			],
			[
				772,
				-8
			]
		],
		[
			[
				55905,
				23372
			],
			[
				3,
				665
			],
			[
				15,
				330
			]
		],
		[
			[
				55923,
				24367
			],
			[
				-1244,
				9
			]
		],
		[
			[
				54679,
				24376
			],
			[
				-105,
				-94
			]
		],
		[
			[
				54574,
				24282
			],
			[
				-23,
				-241
			],
			[
				2,
				-659
			]
		],
		[
			[
				59454,
				27910
			],
			[
				8,
				0
			]
		],
		[
			[
				59462,
				27910
			],
			[
				579,
				-28
			]
		],
		[
			[
				60041,
				27882
			],
			[
				27,
				1330
			],
			[
				-17,
				1
			]
		],
		[
			[
				60051,
				29213
			],
			[
				-573,
				33
			]
		],
		[
			[
				59478,
				29246
			],
			[
				-24,
				-1336
			]
		],
		[
			[
				49011,
				39973
			],
			[
				365,
				20
			]
		],
		[
			[
				49376,
				39993
			],
			[
				767,
				43
			]
		],
		[
			[
				50143,
				40036
			],
			[
				44,
				2
			]
		],
		[
			[
				50165,
				41378
			],
			[
				-5,
				35
			]
		],
		[
			[
				50160,
				41413
			],
			[
				-308,
				127
			],
			[
				-458,
				-55
			]
		],
		[
			[
				49394,
				41485
			],
			[
				-399,
				-64
			]
		],
		[
			[
				48995,
				41421
			],
			[
				-13,
				-112
			],
			[
				29,
				-1336
			]
		],
		[
			[
				52475,
				30682
			],
			[
				439,
				10
			]
		],
		[
			[
				52914,
				30692
			],
			[
				330,
				5
			]
		],
		[
			[
				53244,
				30697
			],
			[
				-8,
				1599
			]
		],
		[
			[
				53236,
				32296
			],
			[
				-383,
				-5
			]
		],
		[
			[
				52853,
				32291
			],
			[
				2,
				-334
			],
			[
				-387,
				-6
			]
		],
		[
			[
				52468,
				31951
			],
			[
				7,
				-1269
			]
		],
		[
			[
				56642,
				73582
			],
			[
				651,
				-13
			],
			[
				71,
				38
			],
			[
				39,
				-135
			]
		],
		[
			[
				57403,
				73472
			],
			[
				164,
				-1
			]
		],
		[
			[
				57567,
				73471
			],
			[
				-1,
				145
			],
			[
				-67,
				327
			],
			[
				-91,
				268
			],
			[
				-36,
				472
			],
			[
				6,
				410
			],
			[
				167,
				-7
			]
		],
		[
			[
				57545,
				75086
			],
			[
				3,
				195
			],
			[
				58,
				78
			],
			[
				55,
				222
			]
		],
		[
			[
				57661,
				75581
			],
			[
				-916,
				16
			]
		],
		[
			[
				56745,
				75597
			],
			[
				-90,
				0
			],
			[
				-8,
				-1121
			]
		],
		[
			[
				56647,
				74476
			],
			[
				-5,
				-894
			]
		],
		[
			[
				43648,
				60472
			],
			[
				971,
				102
			]
		],
		[
			[
				44619,
				60574
			],
			[
				-59,
				1683
			]
		],
		[
			[
				44560,
				62257
			],
			[
				-984,
				-105
			]
		],
		[
			[
				43576,
				62152
			],
			[
				72,
				-1680
			]
		],
		[
			[
				85566,
				28241
			],
			[
				170,
				364
			],
			[
				51,
				433
			]
		],
		[
			[
				85787,
				29038
			],
			[
				45,
				293
			],
			[
				-158,
				54
			],
			[
				-11,
				-81
			],
			[
				-189,
				69
			]
		],
		[
			[
				85474,
				29373
			],
			[
				-75,
				-38
			],
			[
				-25,
				-292
			],
			[
				-357,
				123
			]
		],
		[
			[
				85017,
				29166
			],
			[
				-41,
				-344
			],
			[
				45,
				-382
			],
			[
				545,
				-199
			]
		],
		[
			[
				73859,
				34190
			],
			[
				379,
				-73
			]
		],
		[
			[
				74238,
				34117
			],
			[
				582,
				-169
			]
		],
		[
			[
				74820,
				33948
			],
			[
				121,
				1366
			]
		],
		[
			[
				74941,
				35314
			],
			[
				-191,
				56
			]
		],
		[
			[
				74750,
				35370
			],
			[
				-785,
				221
			]
		],
		[
			[
				73965,
				35591
			],
			[
				-106,
				-1401
			]
		],
		[
			[
				56560,
				28357
			],
			[
				785,
				-22
			]
		],
		[
			[
				57345,
				28335
			],
			[
				10,
				1000
			]
		],
		[
			[
				57355,
				29335
			],
			[
				-779,
				19
			]
		],
		[
			[
				56576,
				29354
			],
			[
				-8,
				0
			]
		],
		[
			[
				56568,
				29354
			],
			[
				-8,
				-997
			]
		],
		[
			[
				52438,
				41462
			],
			[
				764,
				19
			]
		],
		[
			[
				53202,
				41481
			],
			[
				-2,
				672
			]
		],
		[
			[
				53200,
				42153
			],
			[
				-10,
				670
			]
		],
		[
			[
				53190,
				42823
			],
			[
				-764,
				-18
			]
		],
		[
			[
				52426,
				42805
			],
			[
				12,
				-1343
			]
		],
		[
			[
				44560,
				62257
			],
			[
				970,
				97
			]
		],
		[
			[
				45530,
				62354
			],
			[
				-60,
				1670
			]
		],
		[
			[
				45470,
				64024
			],
			[
				-693,
				-63
			]
		],
		[
			[
				44777,
				63961
			],
			[
				-286,
				-30
			]
		],
		[
			[
				44491,
				63931
			],
			[
				69,
				-1674
			]
		],
		[
			[
				32509,
				43508
			],
			[
				51,
				-622
			]
		],
		[
			[
				32560,
				42886
			],
			[
				262,
				62
			],
			[
				-3,
				39
			],
			[
				576,
				133
			],
			[
				13,
				-166
			],
			[
				1041,
				228
			],
			[
				37,
				-500
			],
			[
				414,
				68
			],
			[
				442,
				100
			],
			[
				25,
				-342
			],
			[
				189,
				49
			],
			[
				51,
				-672
			],
			[
				470,
				92
			]
		],
		[
			[
				36077,
				41977
			],
			[
				-39,
				662
			]
		],
		[
			[
				36038,
				42639
			],
			[
				-135,
				-27
			],
			[
				-145,
				2119
			]
		],
		[
			[
				35758,
				44731
			],
			[
				-539,
				-111
			]
		],
		[
			[
				35219,
				44620
			],
			[
				-1147,
				-244
			],
			[
				-1603,
				-367
			]
		],
		[
			[
				32469,
				44009
			],
			[
				40,
				-501
			]
		],
		[
			[
				67884,
				39406
			],
			[
				1007,
				-197
			]
		],
		[
			[
				68891,
				39209
			],
			[
				55,
				1050
			]
		],
		[
			[
				68946,
				40259
			],
			[
				49,
				944
			]
		],
		[
			[
				68995,
				41203
			],
			[
				-685,
				122
			]
		],
		[
			[
				68310,
				41325
			],
			[
				-307,
				36
			],
			[
				-119,
				-1955
			]
		],
		[
			[
				57258,
				31615
			],
			[
				-9,
				-943
			]
		],
		[
			[
				57249,
				30672
			],
			[
				315,
				-8
			]
		],
		[
			[
				57564,
				30664
			],
			[
				447,
				-13
			]
		],
		[
			[
				58011,
				30651
			],
			[
				12,
				938
			]
		],
		[
			[
				58023,
				31589
			],
			[
				16,
				1336
			]
		],
		[
			[
				58039,
				32925
			],
			[
				-768,
				23
			]
		],
		[
			[
				57271,
				32948
			],
			[
				-13,
				-1333
			]
		],
		[
			[
				59538,
				30582
			],
			[
				760,
				-46
			]
		],
		[
			[
				60298,
				30536
			],
			[
				21,
				1103
			]
		],
		[
			[
				60319,
				31639
			],
			[
				-761,
				44
			]
		],
		[
			[
				59558,
				31683
			],
			[
				-3,
				-164
			]
		],
		[
			[
				59555,
				31519
			],
			[
				-17,
				-937
			]
		],
		[
			[
				48507,
				54093
			],
			[
				934,
				43
			]
		],
		[
			[
				49441,
				54136
			],
			[
				18,
				1
			],
			[
				-25,
				1484
			]
		],
		[
			[
				49434,
				55621
			],
			[
				-806,
				-46
			]
		],
		[
			[
				48628,
				55575
			],
			[
				-150,
				-10
			]
		],
		[
			[
				48478,
				55565
			],
			[
				29,
				-1472
			]
		],
		[
			[
				64604,
				79480
			],
			[
				16,
				-362
			],
			[
				-51,
				-171
			],
			[
				21,
				-116
			],
			[
				-40,
				-201
			],
			[
				64,
				-226
			]
		],
		[
			[
				64614,
				78404
			],
			[
				493,
				-49
			]
		],
		[
			[
				65107,
				78355
			],
			[
				45,
				1338
			]
		],
		[
			[
				65152,
				79693
			],
			[
				-658,
				65
			]
		],
		[
			[
				64494,
				79758
			],
			[
				110,
				-278
			]
		],
		[
			[
				67993,
				71961
			],
			[
				981,
				-134
			]
		],
		[
			[
				68974,
				71827
			],
			[
				-12,
				1040
			]
		],
		[
			[
				68962,
				72867
			],
			[
				-4,
				313
			]
		],
		[
			[
				68053,
				73313
			],
			[
				-60,
				-1352
			]
		],
		[
			[
				50134,
				42720
			],
			[
				5,
				1
			]
		],
		[
			[
				50139,
				42721
			],
			[
				752,
				34
			]
		],
		[
			[
				50891,
				42755
			],
			[
				8,
				0
			]
		],
		[
			[
				50899,
				42755
			],
			[
				-20,
				1341
			]
		],
		[
			[
				50879,
				44096
			],
			[
				-391,
				-16
			]
		],
		[
			[
				50488,
				44080
			],
			[
				-376,
				-17
			]
		],
		[
			[
				50112,
				44063
			],
			[
				22,
				-1343
			]
		],
		[
			[
				38173,
				71704
			],
			[
				18,
				2
			],
			[
				94,
				-1695
			],
			[
				1906,
				279
			]
		],
		[
			[
				40191,
				70290
			],
			[
				-81,
				1697
			],
			[
				171,
				24
			],
			[
				-96,
				2000
			]
		],
		[
			[
				40185,
				74011
			],
			[
				-483,
				-68
			]
		],
		[
			[
				39702,
				73943
			],
			[
				-84,
				-12
			]
		],
		[
			[
				39618,
				73931
			],
			[
				-1546,
				-237
			]
		],
		[
			[
				38072,
				73694
			],
			[
				101,
				-1990
			]
		],
		[
			[
				21378,
				12166
			],
			[
				89,
				21
			],
			[
				96,
				-203
			],
			[
				102,
				-10
			],
			[
				113,
				164
			],
			[
				120,
				29
			],
			[
				97,
				-50
			],
			[
				81,
				363
			],
			[
				160,
				336
			],
			[
				-3,
				323
			]
		],
		[
			[
				22233,
				13139
			],
			[
				-25,
				189
			],
			[
				-191,
				-78
			],
			[
				-110,
				70
			],
			[
				-130,
				972
			],
			[
				-92,
				-36
			],
			[
				-62,
				461
			]
		],
		[
			[
				21623,
				14717
			],
			[
				-186,
				-77
			]
		],
		[
			[
				21437,
				14640
			],
			[
				46,
				-458
			],
			[
				112,
				-810
			],
			[
				-96,
				-34
			],
			[
				24,
				-169
			],
			[
				-56,
				-80
			],
			[
				6,
				-285
			],
			[
				-190,
				-74
			],
			[
				95,
				-564
			]
		],
		[
			[
				58516,
				65912
			],
			[
				836,
				1
			]
		],
		[
			[
				59352,
				65913
			],
			[
				3,
				337
			],
			[
				-38,
				153
			],
			[
				109,
				17
			],
			[
				-21,
				301
			],
			[
				47,
				310
			],
			[
				105,
				239
			],
			[
				12,
				112
			]
		],
		[
			[
				59569,
				67382
			],
			[
				-153,
				8
			]
		],
		[
			[
				59416,
				67390
			],
			[
				-131,
				59
			],
			[
				-165,
				-57
			],
			[
				-58,
				-87
			],
			[
				-187,
				-84
			],
			[
				-136,
				-3
			]
		],
		[
			[
				58739,
				67218
			],
			[
				-5,
				-673
			],
			[
				-192,
				-4
			],
			[
				-26,
				-629
			]
		],
		[
			[
				66304,
				42384
			],
			[
				968,
				-123
			]
		],
		[
			[
				67272,
				42261
			],
			[
				-167,
				561
			],
			[
				7,
				168
			],
			[
				-96,
				12
			],
			[
				8,
				168
			]
		],
		[
			[
				67024,
				43170
			],
			[
				-675,
				110
			]
		],
		[
			[
				66349,
				43280
			],
			[
				-45,
				-896
			]
		],
		[
			[
				71468,
				27925
			],
			[
				756,
				-138
			]
		],
		[
			[
				72224,
				27787
			],
			[
				96,
				1324
			]
		],
		[
			[
				71553,
				29250
			],
			[
				-85,
				-1325
			]
		],
		[
			[
				54439,
				16750
			],
			[
				195,
				-2
			]
		],
		[
			[
				54634,
				16748
			],
			[
				788,
				-2
			]
		],
		[
			[
				55422,
				16746
			],
			[
				584,
				-11
			]
		],
		[
			[
				56006,
				16735
			],
			[
				16,
				1324
			]
		],
		[
			[
				56022,
				18059
			],
			[
				1,
				331
			]
		],
		[
			[
				56023,
				18390
			],
			[
				-130,
				7
			],
			[
				-1425,
				2
			]
		],
		[
			[
				54468,
				18399
			],
			[
				2,
				-988
			],
			[
				-30,
				0
			],
			[
				-1,
				-661
			]
		],
		[
			[
				57101,
				44004
			],
			[
				656,
				-2
			]
		],
		[
			[
				57757,
				44002
			],
			[
				26,
				0
			],
			[
				6,
				948
			]
		],
		[
			[
				57789,
				44950
			],
			[
				2,
				166
			]
		],
		[
			[
				57791,
				45116
			],
			[
				-675,
				14
			]
		],
		[
			[
				57116,
				45130
			],
			[
				-3,
				-279
			]
		],
		[
			[
				57113,
				44851
			],
			[
				-12,
				-847
			]
		],
		[
			[
				36673,
				13674
			],
			[
				70,
				-6
			],
			[
				14,
				-218
			],
			[
				81,
				-107
			],
			[
				84,
				11
			],
			[
				123,
				-286
			],
			[
				131,
				-40
			],
			[
				252,
				136
			],
			[
				55,
				-242
			]
		],
		[
			[
				37483,
				12922
			],
			[
				194,
				83
			],
			[
				48,
				82
			],
			[
				558,
				49
			],
			[
				44,
				-218
			],
			[
				191,
				49
			],
			[
				43,
				-136
			],
			[
				167,
				-16
			],
			[
				162,
				-329
			],
			[
				-24,
				-98
			],
			[
				57,
				-198
			],
			[
				113,
				-47
			],
			[
				11,
				73
			]
		],
		[
			[
				39047,
				12216
			],
			[
				91,
				18
			],
			[
				-21,
				335
			],
			[
				145,
				27
			],
			[
				-81,
				1281
			],
			[
				-56,
				-11
			],
			[
				-42,
				660
			],
			[
				193,
				35
			],
			[
				-41,
				657
			],
			[
				127,
				22
			]
		],
		[
			[
				39362,
				15240
			],
			[
				-75,
				1213
			]
		],
		[
			[
				39287,
				16453
			],
			[
				-3,
				55
			]
		],
		[
			[
				39284,
				16508
			],
			[
				-967,
				-179
			],
			[
				4,
				-55
			],
			[
				-1140,
				-227
			],
			[
				-2,
				31
			],
			[
				-647,
				-137
			]
		],
		[
			[
				36532,
				15941
			],
			[
				-65,
				-116
			],
			[
				69,
				-335
			],
			[
				49,
				-109
			],
			[
				-49,
				-183
			],
			[
				-30,
				-319
			],
			[
				58,
				-143
			],
			[
				-35,
				-319
			],
			[
				37,
				-128
			],
			[
				-1,
				-261
			],
			[
				93,
				-182
			],
			[
				15,
				-172
			]
		],
		[
			[
				38482,
				64693
			],
			[
				54,
				-988
			],
			[
				812,
				126
			],
			[
				18,
				-333
			],
			[
				191,
				26
			],
			[
				16,
				-333
			],
			[
				382,
				56
			]
		],
		[
			[
				39955,
				63247
			],
			[
				-32,
				668
			],
			[
				327,
				48
			]
		],
		[
			[
				40250,
				63963
			],
			[
				-66,
				1325
			],
			[
				-27,
				681
			]
		],
		[
			[
				40157,
				65969
			],
			[
				-384,
				-58
			],
			[
				-18,
				338
			],
			[
				-1152,
				-195
			],
			[
				18,
				-336
			],
			[
				-196,
				-33
			]
		],
		[
			[
				38425,
				65685
			],
			[
				57,
				-992
			]
		],
		[
			[
				51621,
				23983
			],
			[
				19,
				-1331
			]
		],
		[
			[
				51640,
				22652
			],
			[
				948,
				32
			],
			[
				13,
				111
			],
			[
				215,
				7
			]
		],
		[
			[
				52816,
				22802
			],
			[
				-6,
				996
			]
		],
		[
			[
				52810,
				23798
			],
			[
				-4,
				558
			]
		],
		[
			[
				52806,
				24356
			],
			[
				-422,
				-10
			]
		],
		[
			[
				52384,
				24346
			],
			[
				-768,
				-30
			]
		],
		[
			[
				51616,
				24316
			],
			[
				5,
				-333
			]
		],
		[
			[
				49190,
				89968
			],
			[
				927,
				42
			]
		],
		[
			[
				50117,
				90010
			],
			[
				196,
				7
			]
		],
		[
			[
				50313,
				90017
			],
			[
				-36,
				3027
			]
		],
		[
			[
				50277,
				93044
			],
			[
				-580,
				-35
			]
		],
		[
			[
				49697,
				93009
			],
			[
				6,
				-297
			],
			[
				-133,
				-65
			],
			[
				-417,
				-2
			]
		],
		[
			[
				49153,
				92645
			],
			[
				37,
				-2677
			]
		],
		[
			[
				44746,
				76359
			],
			[
				1089,
				116
			],
			[
				-40,
				1677
			]
		],
		[
			[
				45795,
				78152
			],
			[
				-521,
				-30
			]
		],
		[
			[
				45274,
				78122
			],
			[
				-596,
				-43
			]
		],
		[
			[
				44678,
				78079
			],
			[
				68,
				-1720
			]
		],
		[
			[
				63011,
				20810
			],
			[
				582,
				-57
			]
		],
		[
			[
				63593,
				20753
			],
			[
				400,
				-44
			]
		],
		[
			[
				63993,
				20709
			],
			[
				14,
				323
			]
		],
		[
			[
				64007,
				21032
			],
			[
				47,
				1304
			]
		],
		[
			[
				64054,
				22336
			],
			[
				26,
				660
			]
		],
		[
			[
				64080,
				22996
			],
			[
				-997,
				117
			]
		],
		[
			[
				63083,
				23113
			],
			[
				-32,
				-994
			]
		],
		[
			[
				63051,
				22119
			],
			[
				-40,
				-1309
			]
		],
		[
			[
				66192,
				64548
			],
			[
				96,
				-11
			]
		],
		[
			[
				66288,
				64537
			],
			[
				350,
				-40
			],
			[
				100,
				124
			],
			[
				179,
				-48
			],
			[
				150,
				99
			]
		],
		[
			[
				67067,
				64672
			],
			[
				18,
				447
			]
		],
		[
			[
				67085,
				65119
			],
			[
				37,
				838
			]
		],
		[
			[
				67122,
				65957
			],
			[
				-484,
				54
			]
		],
		[
			[
				66638,
				66011
			],
			[
				-4,
				-111
			],
			[
				-388,
				39
			]
		],
		[
			[
				66246,
				65939
			],
			[
				-54,
				-1391
			]
		],
		[
			[
				66433,
				37165
			],
			[
				556,
				-84
			]
		],
		[
			[
				66989,
				37081
			],
			[
				41,
				668
			]
		],
		[
			[
				67030,
				37749
			],
			[
				77,
				1337
			]
		],
		[
			[
				67107,
				39086
			],
			[
				-573,
				84
			],
			[
				29,
				685
			]
		],
		[
			[
				66563,
				39855
			],
			[
				-194,
				32
			]
		],
		[
			[
				66369,
				39887
			],
			[
				-30,
				-688
			],
			[
				-191,
				27
			]
		],
		[
			[
				66148,
				39226
			],
			[
				-35,
				-792
			]
		],
		[
			[
				66113,
				38434
			],
			[
				-50,
				-1057
			]
		],
		[
			[
				66063,
				37377
			],
			[
				-8,
				-166
			],
			[
				378,
				-46
			]
		],
		[
			[
				15655,
				56510
			],
			[
				62,
				26
			],
			[
				494,
				-3664
			]
		],
		[
			[
				16211,
				52872
			],
			[
				1720,
				673
			],
			[
				1606,
				600
			],
			[
				8,
				-59
			],
			[
				154,
				54
			]
		],
		[
			[
				19699,
				54140
			],
			[
				1431,
				3678
			]
		],
		[
			[
				21130,
				57818
			],
			[
				-59,
				487
			],
			[
				69,
				170
			],
			[
				31,
				281
			],
			[
				121,
				252
			],
			[
				40,
				416
			],
			[
				-18,
				68
			],
			[
				61,
				264
			],
			[
				-42,
				268
			],
			[
				89,
				60
			],
			[
				160,
				384
			],
			[
				81,
				92
			],
			[
				50,
				197
			]
		],
		[
			[
				21082,
				61424
			],
			[
				-1587,
				-539
			],
			[
				-20,
				165
			],
			[
				-1834,
				-658
			],
			[
				-1066,
				-408
			],
			[
				-14,
				110
			],
			[
				-531,
				-202
			],
			[
				7,
				-54
			],
			[
				-261,
				-105
			],
			[
				0,
				-57
			],
			[
				-328,
				-127
			],
			[
				-23,
				169
			],
			[
				-101,
				26
			],
			[
				-25,
				174
			],
			[
				-80,
				-29
			],
			[
				-60,
				188
			]
		],
		[
			[
				15159,
				60077
			],
			[
				-158,
				-362
			]
		],
		[
			[
				15001,
				59715
			],
			[
				-20,
				-123
			],
			[
				90,
				-153
			],
			[
				63,
				33
			],
			[
				167,
				-502
			],
			[
				119,
				-443
			],
			[
				101,
				-1024
			],
			[
				134,
				-993
			]
		],
		[
			[
				49446,
				53804
			],
			[
				962,
				45
			]
		],
		[
			[
				50408,
				53849
			],
			[
				-6,
				335
			],
			[
				203,
				9
			]
		],
		[
			[
				50605,
				54193
			],
			[
				-17,
				1490
			]
		],
		[
			[
				50239,
				55663
			],
			[
				-805,
				-42
			]
		],
		[
			[
				49441,
				54136
			],
			[
				5,
				-332
			]
		],
		[
			[
				57153,
				51730
			],
			[
				-1,
				-89
			]
		],
		[
			[
				57152,
				51641
			],
			[
				651,
				17
			],
			[
				183,
				-34
			],
			[
				135,
				78
			]
		],
		[
			[
				58121,
				51702
			],
			[
				-6,
				524
			]
		],
		[
			[
				58115,
				52226
			],
			[
				-2,
				1009
			]
		],
		[
			[
				58113,
				53235
			],
			[
				-954,
				-25
			]
		],
		[
			[
				57159,
				53210
			],
			[
				-1,
				-75
			]
		],
		[
			[
				57158,
				53135
			],
			[
				-5,
				-1405
			]
		],
		[
			[
				26268,
				8575
			],
			[
				25,
				64
			],
			[
				-57,
				485
			],
			[
				571,
				185
			],
			[
				-37,
				319
			]
		],
		[
			[
				26770,
				9628
			],
			[
				11,
				172
			],
			[
				-61,
				539
			],
			[
				348,
				115
			],
			[
				12,
				177
			],
			[
				-57,
				44
			],
			[
				-55,
				206
			],
			[
				132,
				-6
			],
			[
				-127,
				369
			],
			[
				82,
				159
			],
			[
				128,
				43
			],
			[
				-74,
				649
			],
			[
				6,
				171
			]
		],
		[
			[
				27115,
				12266
			],
			[
				-107,
				31
			],
			[
				-138,
				-189
			],
			[
				23,
				-64
			],
			[
				-149,
				-172
			],
			[
				-144,
				-230
			],
			[
				-176,
				-136
			],
			[
				-41,
				62
			],
			[
				-126,
				-98
			]
		],
		[
			[
				26257,
				11470
			],
			[
				-55,
				-131
			],
			[
				-182,
				-120
			],
			[
				21,
				-314
			],
			[
				-414,
				-498
			],
			[
				-94,
				28
			],
			[
				-82,
				-83
			],
			[
				-71,
				49
			],
			[
				-109,
				-154
			],
			[
				-20,
				70
			],
			[
				-140,
				-66
			]
		],
		[
			[
				25111,
				10251
			],
			[
				-84,
				-53
			],
			[
				3,
				-163
			],
			[
				-60,
				-128
			],
			[
				104,
				-119
			],
			[
				-43,
				-245
			],
			[
				38,
				-149
			],
			[
				-124,
				-260
			],
			[
				-2,
				-244
			],
			[
				-69,
				-134
			],
			[
				-132,
				-553
			],
			[
				-25,
				-25
			]
		],
		[
			[
				24717,
				8178
			],
			[
				110,
				-885
			]
		],
		[
			[
				83909,
				31795
			],
			[
				740,
				-240
			]
		],
		[
			[
				84649,
				31555
			],
			[
				227,
				-78
			]
		],
		[
			[
				84876,
				31477
			],
			[
				215,
				1722
			]
		],
		[
			[
				85091,
				33199
			],
			[
				30,
				240
			]
		],
		[
			[
				85121,
				33439
			],
			[
				-637,
				226
			]
		],
		[
			[
				84484,
				33665
			],
			[
				-100,
				30
			],
			[
				-309,
				-459
			]
		],
		[
			[
				84075,
				33236
			],
			[
				-166,
				-1441
			]
		],
		[
			[
				41821,
				28416
			],
			[
				2190,
				283
			],
			[
				-22,
				218
			],
			[
				-202,
				399
			]
		],
		[
			[
				43787,
				29316
			],
			[
				-85,
				102
			],
			[
				-65,
				-70
			],
			[
				-58,
				209
			],
			[
				-112,
				36
			],
			[
				-21,
				494
			]
		],
		[
			[
				43446,
				30087
			],
			[
				-1696,
				-234
			]
		],
		[
			[
				41750,
				29853
			],
			[
				5,
				-98
			]
		],
		[
			[
				92366,
				16723
			],
			[
				602,
				-290
			]
		],
		[
			[
				92968,
				16433
			],
			[
				-39,
				133
			],
			[
				109,
				241
			],
			[
				-58,
				218
			],
			[
				-1,
				303
			],
			[
				-62,
				158
			],
			[
				187,
				395
			],
			[
				32,
				250
			],
			[
				-55,
				65
			],
			[
				60,
				185
			],
			[
				-88,
				145
			],
			[
				-60,
				277
			],
			[
				-84,
				59
			]
		],
		[
			[
				92909,
				18862
			],
			[
				-80,
				269
			]
		],
		[
			[
				92829,
				19131
			],
			[
				-200,
				-282
			],
			[
				88,
				-256
			],
			[
				-109,
				-153
			],
			[
				65,
				-283
			],
			[
				-124,
				-87
			],
			[
				73,
				-308
			],
			[
				-187,
				-124
			]
		],
		[
			[
				67422,
				26957
			],
			[
				247,
				-33
			],
			[
				-16,
				-333
			],
			[
				193,
				-27
			]
		],
		[
			[
				67846,
				26564
			],
			[
				355,
				-55
			]
		],
		[
			[
				68201,
				26509
			],
			[
				74,
				365
			],
			[
				-8,
				193
			],
			[
				-187,
				318
			],
			[
				-94,
				833
			]
		],
		[
			[
				67986,
				28218
			],
			[
				-496,
				74
			]
		],
		[
			[
				67490,
				28292
			],
			[
				-68,
				-1335
			]
		],
		[
			[
				83545,
				90253
			],
			[
				585,
				-150
			],
			[
				-32,
				-332
			],
			[
				196,
				-55
			],
			[
				-32,
				-329
			],
			[
				446,
				-125
			]
		],
		[
			[
				84708,
				89262
			],
			[
				162,
				170
			],
			[
				40,
				743
			]
		],
		[
			[
				84910,
				90175
			],
			[
				-52,
				744
			],
			[
				-1232,
				328
			]
		],
		[
			[
				83626,
				91247
			],
			[
				-81,
				-994
			]
		],
		[
			[
				77321,
				63148
			],
			[
				123,
				-261
			],
			[
				144,
				8
			]
		],
		[
			[
				77588,
				62895
			],
			[
				117,
				53
			]
		],
		[
			[
				77705,
				62948
			],
			[
				39,
				115
			],
			[
				10,
				402
			],
			[
				121,
				221
			],
			[
				-21,
				149
			]
		],
		[
			[
				77854,
				63835
			],
			[
				-78,
				120
			]
		],
		[
			[
				77776,
				63955
			],
			[
				-160,
				-214
			],
			[
				-145,
				69
			],
			[
				-119,
				-139
			]
		],
		[
			[
				77352,
				63671
			],
			[
				-111,
				-250
			],
			[
				80,
				-273
			]
		],
		[
			[
				70381,
				47132
			],
			[
				379,
				-65
			]
		],
		[
			[
				70760,
				47067
			],
			[
				48,
				838
			]
		],
		[
			[
				70808,
				47905
			],
			[
				37,
				615
			]
		],
		[
			[
				70845,
				48520
			],
			[
				-268,
				44
			],
			[
				-62,
				130
			],
			[
				-83,
				-24
			]
		],
		[
			[
				70432,
				48670
			],
			[
				-64,
				-1199
			],
			[
				32,
				-6
			],
			[
				-19,
				-333
			]
		],
		[
			[
				58642,
				23284
			],
			[
				769,
				-26
			]
		],
		[
			[
				59411,
				23258
			],
			[
				8,
				439
			]
		],
		[
			[
				59419,
				23697
			],
			[
				11,
				661
			]
		],
		[
			[
				59430,
				24358
			],
			[
				-327,
				15
			],
			[
				7,
				340
			]
		],
		[
			[
				59110,
				24713
			],
			[
				-89,
				5
			],
			[
				4,
				-260
			],
			[
				-55,
				-152
			],
			[
				-322,
				-375
			]
		],
		[
			[
				58648,
				23931
			],
			[
				-6,
				-647
			]
		],
		[
			[
				57756,
				43609
			],
			[
				767,
				-13
			]
		],
		[
			[
				58523,
				43596
			],
			[
				12,
				668
			]
		],
		[
			[
				58535,
				44264
			],
			[
				15,
				675
			]
		],
		[
			[
				58550,
				44939
			],
			[
				-761,
				11
			]
		],
		[
			[
				57757,
				44002
			],
			[
				-1,
				-393
			]
		],
		[
			[
				53609,
				61687
			],
			[
				11,
				-680
			]
		],
		[
			[
				53620,
				61007
			],
			[
				770,
				6
			]
		],
		[
			[
				54390,
				61013
			],
			[
				-1,
				336
			],
			[
				190,
				0
			],
			[
				0,
				676
			],
			[
				192,
				0
			]
		],
		[
			[
				54771,
				62025
			],
			[
				-1,
				335
			]
		],
		[
			[
				54770,
				62360
			],
			[
				-831,
				-4
			]
		],
		[
			[
				53939,
				62356
			],
			[
				2,
				-685
			],
			[
				-101,
				149
			],
			[
				-102,
				-99
			],
			[
				-27,
				62
			],
			[
				59,
				182
			],
			[
				-159,
				-39
			]
		],
		[
			[
				53611,
				61926
			],
			[
				-2,
				-239
			]
		],
		[
			[
				48333,
				67770
			],
			[
				962,
				56
			]
		],
		[
			[
				49263,
				69505
			],
			[
				-960,
				-61
			]
		],
		[
			[
				48303,
				69444
			],
			[
				24,
				-1289
			]
		],
		[
			[
				48327,
				68155
			],
			[
				6,
				-385
			]
		],
		[
			[
				46413,
				67625
			],
			[
				865,
				66
			]
		],
		[
			[
				47278,
				67691
			],
			[
				96,
				7
			]
		],
		[
			[
				47374,
				67698
			],
			[
				-31,
				1687
			]
		],
		[
			[
				47343,
				69385
			],
			[
				-975,
				-76
			]
		],
		[
			[
				46368,
				69309
			],
			[
				45,
				-1684
			]
		],
		[
			[
				28612,
				38286
			],
			[
				79,
				-104
			]
		],
		[
			[
				28691,
				38182
			],
			[
				105,
				65
			],
			[
				115,
				-299
			],
			[
				76,
				10
			],
			[
				10,
				220
			],
			[
				76,
				58
			],
			[
				127,
				211
			],
			[
				143,
				151
			],
			[
				60,
				-144
			],
			[
				148,
				95
			],
			[
				85,
				-35
			],
			[
				108,
				-315
			],
			[
				53,
				20
			]
		],
		[
			[
				29797,
				38219
			],
			[
				-89,
				940
			],
			[
				-168,
				2030
			]
		],
		[
			[
				29540,
				41189
			],
			[
				-320,
				-89
			],
			[
				15,
				-164
			],
			[
				-169,
				-453
			],
			[
				-67,
				-19
			],
			[
				69,
				-282
			],
			[
				8,
				-165
			],
			[
				-60,
				-230
			],
			[
				22,
				-74
			],
			[
				-67,
				-195
			],
			[
				-187,
				-50
			],
			[
				-62,
				-311
			],
			[
				-125,
				-37
			],
			[
				-13,
				-200
			],
			[
				-64,
				-162
			],
			[
				82,
				-103
			],
			[
				42,
				-244
			],
			[
				-32,
				-125
			]
		],
		[
			[
				50408,
				53849
			],
			[
				15,
				-1010
			]
		],
		[
			[
				50423,
				52839
			],
			[
				1150,
				40
			]
		],
		[
			[
				51573,
				52879
			],
			[
				-11,
				1002
			]
		],
		[
			[
				51562,
				53881
			],
			[
				-3,
				339
			]
		],
		[
			[
				51559,
				54220
			],
			[
				-954,
				-27
			]
		],
		[
			[
				47677,
				39881
			],
			[
				1334,
				92
			]
		],
		[
			[
				48995,
				41421
			],
			[
				-379,
				-78
			]
		],
		[
			[
				48616,
				41343
			],
			[
				-92,
				-64
			],
			[
				-474,
				-33
			]
		],
		[
			[
				48050,
				41246
			],
			[
				-406,
				-31
			]
		],
		[
			[
				47644,
				41215
			],
			[
				33,
				-1334
			]
		],
		[
			[
				58879,
				27940
			],
			[
				575,
				-30
			]
		],
		[
			[
				59478,
				29246
			],
			[
				-5,
				0
			]
		],
		[
			[
				59473,
				29246
			],
			[
				-573,
				30
			]
		],
		[
			[
				82845,
				33605
			],
			[
				0,
				-2
			]
		],
		[
			[
				82845,
				33603
			],
			[
				229,
				-66
			],
			[
				539,
				-199
			],
			[
				13,
				104
			],
			[
				104,
				-32
			]
		],
		[
			[
				83730,
				33410
			],
			[
				80,
				747
			],
			[
				271,
				-86
			],
			[
				34,
				307
			],
			[
				32,
				-10
			],
			[
				35,
				353
			]
		],
		[
			[
				84182,
				34721
			],
			[
				-681,
				127
			],
			[
				13,
				138
			],
			[
				-96,
				91
			]
		],
		[
			[
				83418,
				35077
			],
			[
				-66,
				-218
			],
			[
				-407,
				-262
			],
			[
				-26,
				-48
			],
			[
				-188,
				198
			]
		],
		[
			[
				82731,
				34747
			],
			[
				-30,
				-314
			],
			[
				37,
				-128
			],
			[
				177,
				8
			],
			[
				-70,
				-708
			]
		],
		[
			[
				46723,
				15939
			],
			[
				92,
				70
			],
			[
				21,
				-114
			],
			[
				190,
				-36
			],
			[
				121,
				175
			],
			[
				-28,
				173
			],
			[
				36,
				184
			]
		],
		[
			[
				47155,
				16391
			],
			[
				35,
				243
			],
			[
				79,
				289
			],
			[
				-89,
				132
			]
		],
		[
			[
				47180,
				17055
			],
			[
				-1267,
				-117
			]
		],
		[
			[
				45913,
				16938
			],
			[
				35,
				-987
			],
			[
				771,
				77
			],
			[
				4,
				-89
			]
		],
		[
			[
				46064,
				70957
			],
			[
				254,
				23
			]
		],
		[
			[
				46318,
				70980
			],
			[
				698,
				66
			]
		],
		[
			[
				47016,
				71046
			],
			[
				-47,
				1679
			]
		],
		[
			[
				46969,
				72725
			],
			[
				-962,
				-86
			]
		],
		[
			[
				46007,
				72639
			],
			[
				57,
				-1682
			]
		],
		[
			[
				75257,
				73858
			],
			[
				103,
				14
			],
			[
				215,
				-68
			]
		],
		[
			[
				75575,
				73804
			],
			[
				15,
				246
			],
			[
				57,
				355
			],
			[
				234,
				-51
			]
		],
		[
			[
				75881,
				74354
			],
			[
				27,
				452
			]
		],
		[
			[
				75908,
				74806
			],
			[
				-219,
				39
			],
			[
				-171,
				98
			],
			[
				-39,
				-112
			]
		],
		[
			[
				75479,
				74831
			],
			[
				-51,
				-389
			],
			[
				-146,
				-260
			],
			[
				-16,
				-259
			]
		],
		[
			[
				75266,
				73923
			],
			[
				-9,
				-65
			]
		],
		[
			[
				47651,
				43912
			],
			[
				932,
				65
			]
		],
		[
			[
				48588,
				43977
			],
			[
				-42,
				1675
			]
		],
		[
			[
				48546,
				45652
			],
			[
				-910,
				-64
			]
		],
		[
			[
				47636,
				45588
			],
			[
				-32,
				-1
			]
		],
		[
			[
				47604,
				45587
			],
			[
				47,
				-1675
			]
		],
		[
			[
				70080,
				28161
			],
			[
				629,
				-96
			]
		],
		[
			[
				70709,
				28065
			],
			[
				79,
				1330
			]
		],
		[
			[
				70788,
				29395
			],
			[
				-680,
				100
			]
		],
		[
			[
				70108,
				29495
			],
			[
				-117,
				-382
			],
			[
				-48,
				-254
			],
			[
				11,
				-130
			],
			[
				93,
				-282
			],
			[
				33,
				-286
			]
		],
		[
			[
				68328,
				78333
			],
			[
				96,
				-11
			],
			[
				-14,
				-335
			]
		],
		[
			[
				68410,
				77987
			],
			[
				778,
				-105
			]
		],
		[
			[
				69188,
				77882
			],
			[
				72,
				1001
			]
		],
		[
			[
				69260,
				78883
			],
			[
				-902,
				119
			]
		],
		[
			[
				68358,
				79002
			],
			[
				-30,
				-669
			]
		],
		[
			[
				53337,
				25696
			],
			[
				7,
				-660
			]
		],
		[
			[
				53344,
				25036
			],
			[
				679,
				5
			]
		],
		[
			[
				54023,
				25041
			],
			[
				-1,
				659
			]
		],
		[
			[
				54022,
				25700
			],
			[
				-2,
				667
			]
		],
		[
			[
				54020,
				26367
			],
			[
				-1,
				336
			]
		],
		[
			[
				54019,
				26703
			],
			[
				-689,
				-9
			]
		],
		[
			[
				53330,
				26694
			],
			[
				7,
				-998
			]
		],
		[
			[
				12197,
				86492
			],
			[
				73,
				20
			],
			[
				110,
				375
			],
			[
				401,
				77
			]
		],
		[
			[
				12781,
				86964
			],
			[
				-59,
				144
			],
			[
				-77,
				919
			],
			[
				50,
				12
			],
			[
				-23,
				340
			],
			[
				139,
				27
			]
		],
		[
			[
				12811,
				88406
			],
			[
				98,
				139
			],
			[
				-203,
				125
			],
			[
				-141,
				162
			],
			[
				-296,
				104
			],
			[
				-216,
				-149
			],
			[
				-256,
				86
			],
			[
				-67,
				-50
			],
			[
				-124,
				157
			],
			[
				-121,
				-79
			],
			[
				-11,
				171
			],
			[
				-91,
				276
			],
			[
				-150,
				71
			],
			[
				-27,
				-59
			],
			[
				-198,
				22
			]
		],
		[
			[
				11008,
				89382
			],
			[
				60,
				-108
			],
			[
				-86,
				-254
			],
			[
				-43,
				121
			],
			[
				-68,
				-32
			],
			[
				0,
				-240
			],
			[
				-86,
				-117
			],
			[
				-3,
				-116
			],
			[
				72,
				-86
			],
			[
				-50,
				-94
			],
			[
				-93,
				29
			],
			[
				21,
				-165
			],
			[
				125,
				-7
			],
			[
				-79,
				-221
			],
			[
				165,
				10
			],
			[
				-22,
				-218
			],
			[
				51,
				-173
			],
			[
				369,
				-634
			],
			[
				-1,
				-135
			],
			[
				124,
				-392
			],
			[
				88,
				-131
			],
			[
				104,
				-36
			],
			[
				136,
				97
			],
			[
				126,
				269
			],
			[
				99,
				-9
			],
			[
				180,
				-248
			]
		],
		[
			[
				81807,
				47213
			],
			[
				-31,
				-458
			],
			[
				41,
				25
			],
			[
				126,
				-242
			]
		],
		[
			[
				81943,
				46538
			],
			[
				176,
				-363
			],
			[
				183,
				-38
			],
			[
				232,
				-419
			],
			[
				46,
				-629
			],
			[
				36,
				-118
			],
			[
				182,
				238
			],
			[
				26,
				-107
			],
			[
				70,
				79
			]
		],
		[
			[
				82894,
				45181
			],
			[
				30,
				161
			],
			[
				-40,
				127
			]
		],
		[
			[
				82884,
				45469
			],
			[
				-41,
				472
			],
			[
				34,
				157
			],
			[
				-63,
				322
			],
			[
				-95,
				220
			],
			[
				39,
				139
			]
		],
		[
			[
				82758,
				46779
			],
			[
				-147,
				331
			],
			[
				-56,
				257
			],
			[
				41,
				84
			],
			[
				-41,
				182
			]
		],
		[
			[
				82555,
				47633
			],
			[
				-250,
				103
			],
			[
				-100,
				106
			],
			[
				-168,
				7
			],
			[
				-194,
				-209
			],
			[
				-36,
				-427
			]
		],
		[
			[
				38943,
				23557
			],
			[
				279,
				24
			],
			[
				1211,
				207
			]
		],
		[
			[
				40433,
				23788
			],
			[
				-46,
				816
			],
			[
				-13,
				-5
			],
			[
				-57,
				988
			],
			[
				-64,
				1343
			]
		],
		[
			[
				40107,
				29519
			],
			[
				-1506,
				-246
			]
		],
		[
			[
				38601,
				29273
			],
			[
				77,
				-1247
			],
			[
				13,
				2
			],
			[
				71,
				-1330
			],
			[
				86,
				-1338
			],
			[
				17,
				-163
			]
		],
		[
			[
				38865,
				25197
			],
			[
				62,
				-1120
			],
			[
				16,
				-520
			]
		],
		[
			[
				59834,
				60537
			],
			[
				384,
				-4
			]
		],
		[
			[
				60218,
				60533
			],
			[
				254,
				-6
			]
		],
		[
			[
				60472,
				60527
			],
			[
				7,
				669
			],
			[
				-65,
				3
			],
			[
				4,
				333
			]
		],
		[
			[
				60418,
				61532
			],
			[
				14,
				1118
			],
			[
				-74,
				13
			]
		],
		[
			[
				60358,
				62663
			],
			[
				-189,
				19
			],
			[
				-79,
				197
			],
			[
				-71,
				-31
			],
			[
				8,
				-199
			],
			[
				-71,
				29
			],
			[
				-73,
				-239
			],
			[
				-84,
				-137
			],
			[
				-79,
				-11
			],
			[
				-64,
				-157
			]
		],
		[
			[
				59656,
				62134
			],
			[
				-38,
				-37
			]
		],
		[
			[
				59618,
				62097
			],
			[
				-3,
				-550
			],
			[
				130,
				-1
			],
			[
				-4,
				-503
			],
			[
				96,
				0
			],
			[
				-3,
				-506
			]
		],
		[
			[
				75321,
				67145
			],
			[
				423,
				-83
			],
			[
				23,
				27
			]
		],
		[
			[
				75767,
				67089
			],
			[
				-15,
				246
			],
			[
				97,
				297
			],
			[
				101,
				66
			],
			[
				89,
				284
			]
		],
		[
			[
				76039,
				67982
			],
			[
				1,
				141
			]
		],
		[
			[
				76040,
				68123
			],
			[
				-646,
				122
			],
			[
				-10,
				128
			]
		],
		[
			[
				75384,
				68373
			],
			[
				-8,
				-125
			],
			[
				-142,
				25
			]
		],
		[
			[
				75234,
				68273
			],
			[
				-193,
				-739
			]
		],
		[
			[
				75041,
				67534
			],
			[
				58,
				-132
			],
			[
				51,
				57
			],
			[
				171,
				-314
			]
		],
		[
			[
				65486,
				45414
			],
			[
				286,
				-37
			]
		],
		[
			[
				65772,
				45377
			],
			[
				31,
				676
			],
			[
				668,
				-81
			]
		],
		[
			[
				66471,
				45972
			],
			[
				21,
				504
			]
		],
		[
			[
				66492,
				46476
			],
			[
				-189,
				25
			],
			[
				31,
				729
			]
		],
		[
			[
				66334,
				47230
			],
			[
				-576,
				68
			],
			[
				4,
				111
			],
			[
				-91,
				12
			]
		],
		[
			[
				65671,
				47421
			],
			[
				-102,
				12
			]
		],
		[
			[
				65569,
				47433
			],
			[
				-58,
				-1285
			],
			[
				-25,
				-734
			]
		],
		[
			[
				57754,
				35619
			],
			[
				383,
				-14
			]
		],
		[
			[
				58137,
				35605
			],
			[
				384,
				-12
			]
		],
		[
			[
				58521,
				35593
			],
			[
				19,
				1331
			]
		],
		[
			[
				58540,
				36924
			],
			[
				-194,
				8
			]
		],
		[
			[
				58346,
				36932
			],
			[
				-576,
				21
			]
		],
		[
			[
				57770,
				36953
			],
			[
				-16,
				-1334
			]
		],
		[
			[
				66783,
				76509
			],
			[
				127,
				-14
			]
		],
		[
			[
				66910,
				76495
			],
			[
				256,
				-31
			]
		],
		[
			[
				67166,
				76464
			],
			[
				15,
				335
			],
			[
				196,
				-24
			],
			[
				50,
				1287
			]
		],
		[
			[
				67427,
				78062
			],
			[
				-144,
				18
			],
			[
				3,
				106
			],
			[
				-96,
				9
			],
			[
				-54,
				-113
			],
			[
				-94,
				58
			],
			[
				-195,
				20
			]
		],
		[
			[
				66847,
				78160
			],
			[
				-64,
				-1651
			]
		],
		[
			[
				58111,
				54356
			],
			[
				1,
				-896
			]
		],
		[
			[
				58112,
				53460
			],
			[
				806,
				3
			]
		],
		[
			[
				58918,
				53463
			],
			[
				-1,
				560
			]
		],
		[
			[
				58917,
				54023
			],
			[
				0,
				562
			]
		],
		[
			[
				58917,
				54585
			],
			[
				-751,
				-2
			]
		],
		[
			[
				58166,
				54583
			],
			[
				-55,
				-1
			],
			[
				0,
				-226
			]
		],
		[
			[
				75700,
				39884
			],
			[
				169,
				-43
			]
		],
		[
			[
				75869,
				39841
			],
			[
				284,
				-72
			],
			[
				18,
				241
			]
		],
		[
			[
				76171,
				40010
			],
			[
				61,
				762
			],
			[
				127,
				-29
			],
			[
				35,
				386
			]
		],
		[
			[
				76394,
				41129
			],
			[
				-50,
				149
			]
		],
		[
			[
				76344,
				41278
			],
			[
				-501,
				100
			]
		],
		[
			[
				75729,
				40946
			],
			[
				-29,
				-1062
			]
		],
		[
			[
				50024,
				58873
			],
			[
				762,
				34
			]
		],
		[
			[
				50786,
				58907
			],
			[
				-17,
				1696
			]
		],
		[
			[
				50769,
				60603
			],
			[
				-191,
				-8
			],
			[
				-10,
				669
			]
		],
		[
			[
				50568,
				61264
			],
			[
				-558,
				-25
			]
		],
		[
			[
				50010,
				61239
			],
			[
				-16,
				-1
			],
			[
				16,
				-1005
			]
		],
		[
			[
				50010,
				60233
			],
			[
				14,
				-1360
			]
		],
		[
			[
				67804,
				58520
			],
			[
				69,
				-15
			],
			[
				-12,
				-204
			],
			[
				220,
				-22
			],
			[
				-3,
				-141
			]
		],
		[
			[
				68078,
				58138
			],
			[
				569,
				-55
			],
			[
				3,
				99
			]
		],
		[
			[
				68650,
				58182
			],
			[
				42,
				1053
			],
			[
				71,
				-7
			]
		],
		[
			[
				68763,
				59228
			],
			[
				3,
				112
			]
		],
		[
			[
				68766,
				59340
			],
			[
				-700,
				67
			],
			[
				-61,
				144
			]
		],
		[
			[
				68005,
				59551
			],
			[
				-179,
				17
			]
		],
		[
			[
				67826,
				59568
			],
			[
				-22,
				-1048
			]
		],
		[
			[
				45637,
				58992
			],
			[
				0,
				-9
			]
		],
		[
			[
				45637,
				58983
			],
			[
				963,
				87
			]
		],
		[
			[
				46600,
				59070
			],
			[
				12,
				1
			]
		],
		[
			[
				46565,
				60756
			],
			[
				-981,
				-84
			]
		],
		[
			[
				45584,
				60672
			],
			[
				0,
				-24
			]
		],
		[
			[
				47343,
				69385
			],
			[
				960,
				59
			]
		],
		[
			[
				48303,
				69444
			],
			[
				-34,
				1699
			]
		],
		[
			[
				48269,
				71143
			],
			[
				-262,
				-16
			]
		],
		[
			[
				48007,
				71127
			],
			[
				-702,
				-60
			]
		],
		[
			[
				47305,
				71067
			],
			[
				38,
				-1682
			]
		],
		[
			[
				66979,
				29705
			],
			[
				385,
				-52
			]
		],
		[
			[
				67364,
				29653
			],
			[
				194,
				-25
			]
		],
		[
			[
				67558,
				29628
			],
			[
				33,
				668
			],
			[
				-36,
				7
			],
			[
				32,
				671
			]
		],
		[
			[
				67587,
				30974
			],
			[
				-574,
				70
			]
		],
		[
			[
				67013,
				31044
			],
			[
				-32,
				-672
			],
			[
				27,
				-3
			],
			[
				-29,
				-664
			]
		],
		[
			[
				68020,
				55087
			],
			[
				-13,
				-309
			]
		],
		[
			[
				68007,
				54778
			],
			[
				200,
				-189
			],
			[
				104,
				46
			],
			[
				131,
				240
			]
		],
		[
			[
				68442,
				54875
			],
			[
				99,
				166
			],
			[
				69,
				255
			]
		],
		[
			[
				68610,
				55296
			],
			[
				104,
				450
			]
		],
		[
			[
				68714,
				55746
			],
			[
				-663,
				80
			]
		],
		[
			[
				68051,
				55826
			],
			[
				-31,
				-739
			]
		],
		[
			[
				58813,
				39618
			],
			[
				383,
				-15
			]
		],
		[
			[
				59196,
				39603
			],
			[
				381,
				-20
			]
		],
		[
			[
				59577,
				39583
			],
			[
				21,
				1010
			]
		],
		[
			[
				59598,
				40593
			],
			[
				-768,
				38
			]
		],
		[
			[
				58830,
				40631
			],
			[
				-17,
				-1013
			]
		],
		[
			[
				46565,
				60756
			],
			[
				-45,
				1680
			]
		],
		[
			[
				46520,
				62436
			],
			[
				-990,
				-82
			]
		],
		[
			[
				45530,
				62354
			],
			[
				54,
				-1682
			]
		],
		[
			[
				13268,
				26936
			],
			[
				903,
				432
			]
		],
		[
			[
				14171,
				27368
			],
			[
				945,
				431
			],
			[
				459,
				198
			]
		],
		[
			[
				15575,
				27997
			],
			[
				-457,
				3022
			]
		],
		[
			[
				15118,
				31019
			],
			[
				-810,
				-361
			],
			[
				-1338,
				-614
			]
		],
		[
			[
				12970,
				30044
			],
			[
				-185,
				-84
			]
		],
		[
			[
				12785,
				29960
			],
			[
				350,
				-2205
			],
			[
				-13,
				-6
			],
			[
				103,
				-644
			],
			[
				43,
				-169
			]
		],
		[
			[
				74239,
				65845
			],
			[
				318,
				-52
			],
			[
				296,
				-78
			]
		],
		[
			[
				74853,
				65715
			],
			[
				25,
				-4
			],
			[
				22,
				353
			]
		],
		[
			[
				74900,
				66064
			],
			[
				1,
				377
			],
			[
				-158,
				88
			],
			[
				-264,
				237
			],
			[
				-89,
				13
			]
		],
		[
			[
				74390,
				66779
			],
			[
				-151,
				-934
			]
		],
		[
			[
				72271,
				50208
			],
			[
				351,
				-299
			],
			[
				272,
				1
			],
			[
				212,
				-213
			]
		],
		[
			[
				73106,
				49697
			],
			[
				-65,
				98
			],
			[
				-61,
				342
			],
			[
				53,
				70
			]
		],
		[
			[
				73033,
				50207
			],
			[
				-72,
				112
			],
			[
				-53,
				321
			],
			[
				-164,
				304
			],
			[
				-99,
				30
			]
		],
		[
			[
				72645,
				50974
			],
			[
				-190,
				-227
			],
			[
				-45,
				-248
			],
			[
				-87,
				27
			],
			[
				-16,
				-188
			],
			[
				-66,
				-57
			]
		],
		[
			[
				72241,
				50281
			],
			[
				30,
				-73
			]
		],
		[
			[
				84268,
				59886
			],
			[
				97,
				-109
			],
			[
				4,
				-105
			],
			[
				93,
				-256
			],
			[
				-54,
				-119
			],
			[
				4,
				-190
			],
			[
				182,
				-250
			]
		],
		[
			[
				84594,
				58857
			],
			[
				226,
				313
			],
			[
				67,
				344
			],
			[
				-16,
				60
			]
		],
		[
			[
				84871,
				59574
			],
			[
				57,
				239
			],
			[
				-177,
				612
			]
		],
		[
			[
				84751,
				60425
			],
			[
				-451,
				-547
			],
			[
				-32,
				8
			]
		],
		[
			[
				73539,
				40082
			],
			[
				-41,
				-597
			]
		],
		[
			[
				73498,
				39485
			],
			[
				576,
				-120
			],
			[
				12,
				167
			]
		],
		[
			[
				74086,
				39532
			],
			[
				91,
				1248
			],
			[
				36,
				8
			]
		],
		[
			[
				74213,
				40788
			],
			[
				0,
				1
			]
		],
		[
			[
				74213,
				40789
			],
			[
				-619,
				134
			]
		],
		[
			[
				73594,
				40923
			],
			[
				-55,
				-841
			]
		],
		[
			[
				73758,
				55736
			],
			[
				20,
				-167
			],
			[
				-29,
				-201
			]
		],
		[
			[
				73749,
				55368
			],
			[
				534,
				-63
			]
		],
		[
			[
				74283,
				55305
			],
			[
				338,
				-20
			]
		],
		[
			[
				74621,
				55285
			],
			[
				116,
				282
			]
		],
		[
			[
				74737,
				55567
			],
			[
				-93,
				18
			],
			[
				-150,
				-145
			],
			[
				-146,
				-15
			],
			[
				-172,
				122
			],
			[
				-63,
				241
			],
			[
				-2,
				350
			],
			[
				-31,
				17
			]
		],
		[
			[
				74080,
				56155
			],
			[
				-136,
				-297
			],
			[
				-161,
				-162
			],
			[
				-25,
				40
			]
		],
		[
			[
				28616,
				37420
			],
			[
				46,
				-33
			],
			[
				91,
				145
			],
			[
				79,
				-84
			],
			[
				70,
				-217
			],
			[
				-55,
				-227
			],
			[
				10,
				-133
			],
			[
				-79,
				-30
			],
			[
				-14,
				-119
			],
			[
				126,
				-213
			],
			[
				153,
				-73
			],
			[
				106,
				11
			],
			[
				39,
				-148
			],
			[
				175,
				-18
			]
		],
		[
			[
				29363,
				36281
			],
			[
				52,
				20
			],
			[
				240,
				-188
			],
			[
				106,
				-142
			]
		],
		[
			[
				31321,
				37373
			],
			[
				79,
				21
			]
		],
		[
			[
				31400,
				37394
			],
			[
				-61,
				701
			]
		],
		[
			[
				31339,
				38095
			],
			[
				-226,
				-49
			],
			[
				-254,
				-151
			],
			[
				-87,
				22
			],
			[
				-60,
				114
			],
			[
				-189,
				-15
			],
			[
				-69,
				53
			],
			[
				-228,
				23
			],
			[
				-155,
				-69
			],
			[
				-101,
				110
			],
			[
				-136,
				-95
			],
			[
				-37,
				181
			]
		],
		[
			[
				28691,
				38182
			],
			[
				-64,
				-241
			],
			[
				32,
				-119
			],
			[
				-66,
				-309
			],
			[
				23,
				-93
			]
		],
		[
			[
				88439,
				51324
			],
			[
				201,
				-472
			],
			[
				310,
				-779
			],
			[
				77,
				-78
			]
		],
		[
			[
				89027,
				49995
			],
			[
				17,
				123
			],
			[
				129,
				-52
			]
		],
		[
			[
				89173,
				50066
			],
			[
				-6,
				126
			]
		],
		[
			[
				89167,
				50192
			],
			[
				-41,
				217
			],
			[
				62,
				1019
			]
		],
		[
			[
				89188,
				51428
			],
			[
				-86,
				33
			]
		],
		[
			[
				89102,
				51461
			],
			[
				-653,
				222
			]
		],
		[
			[
				88449,
				51683
			],
			[
				-10,
				-359
			]
		],
		[
			[
				67258,
				32370
			],
			[
				383,
				-59
			]
		],
		[
			[
				67641,
				32311
			],
			[
				395,
				-53
			]
		],
		[
			[
				68036,
				32258
			],
			[
				109,
				205
			],
			[
				-41,
				457
			]
		],
		[
			[
				68104,
				32920
			],
			[
				-623,
				84
			],
			[
				11,
				223
			],
			[
				-192,
				30
			]
		],
		[
			[
				67300,
				33257
			],
			[
				-42,
				-887
			]
		],
		[
			[
				83242,
				83586
			],
			[
				-21,
				-111
			],
			[
				80,
				-268
			]
		],
		[
			[
				83301,
				83207
			],
			[
				106,
				144
			],
			[
				183,
				-42
			],
			[
				109,
				167
			],
			[
				89,
				-176
			],
			[
				97,
				-19
			],
			[
				54,
				254
			],
			[
				106,
				191
			],
			[
				85,
				286
			]
		],
		[
			[
				84130,
				84012
			],
			[
				-661,
				188
			],
			[
				-12,
				-110
			],
			[
				-251,
				64
			],
			[
				-27,
				-274
			],
			[
				62,
				-110
			],
			[
				1,
				-184
			]
		],
		[
			[
				67030,
				37749
			],
			[
				568,
				-101
			]
		],
		[
			[
				67598,
				37648
			],
			[
				62,
				1002
			]
		],
		[
			[
				67660,
				38650
			],
			[
				3,
				337
			]
		],
		[
			[
				67663,
				38987
			],
			[
				-556,
				99
			]
		],
		[
			[
				72362,
				45055
			],
			[
				92,
				-18
			],
			[
				-23,
				-393
			]
		],
		[
			[
				72431,
				44644
			],
			[
				563,
				-110
			]
		],
		[
			[
				72994,
				44534
			],
			[
				49,
				709
			]
		],
		[
			[
				73043,
				45243
			],
			[
				-226,
				326
			]
		],
		[
			[
				72817,
				45569
			],
			[
				-200,
				282
			],
			[
				-205,
				46
			]
		],
		[
			[
				72412,
				45897
			],
			[
				-50,
				-842
			]
		],
		[
			[
				60852,
				36802
			],
			[
				771,
				-51
			]
		],
		[
			[
				61623,
				36751
			],
			[
				38,
				1347
			]
		],
		[
			[
				61661,
				38098
			],
			[
				-194,
				18
			]
		],
		[
			[
				61467,
				38116
			],
			[
				-583,
				44
			]
		],
		[
			[
				60884,
				38160
			],
			[
				-32,
				-1358
			]
		],
		[
			[
				57875,
				24984
			],
			[
				386,
				-12
			]
		],
		[
			[
				58261,
				24972
			],
			[
				5,
				333
			],
			[
				390,
				-19
			],
			[
				5,
				334
			]
		],
		[
			[
				58661,
				25620
			],
			[
				-124,
				89
			],
			[
				-48,
				228
			],
			[
				-165,
				304
			],
			[
				-46,
				23
			]
		],
		[
			[
				58278,
				26264
			],
			[
				-2,
				-127
			],
			[
				-191,
				8
			],
			[
				-2,
				-167
			],
			[
				-193,
				6
			]
		],
		[
			[
				57890,
				25984
			],
			[
				-15,
				-1000
			]
		],
		[
			[
				43068,
				15267
			],
			[
				862,
				118
			]
		],
		[
			[
				43930,
				15385
			],
			[
				102,
				13
			],
			[
				-58,
				1320
			]
		],
		[
			[
				43974,
				16718
			],
			[
				-303,
				-38
			],
			[
				-60,
				1330
			]
		],
		[
			[
				43611,
				18010
			],
			[
				-580,
				-77
			]
		],
		[
			[
				43031,
				17933
			],
			[
				62,
				-1329
			],
			[
				-88,
				-12
			],
			[
				63,
				-1325
			]
		],
		[
			[
				74056,
				36429
			],
			[
				756,
				-170
			]
		],
		[
			[
				74812,
				36259
			],
			[
				20,
				279
			]
		],
		[
			[
				74832,
				36538
			],
			[
				75,
				942
			]
		],
		[
			[
				74332,
				37612
			],
			[
				-73,
				-1002
			],
			[
				-187,
				41
			]
		],
		[
			[
				74072,
				36651
			],
			[
				-16,
				-222
			]
		],
		[
			[
				49857,
				77864
			],
			[
				573,
				-665
			],
			[
				-8,
				-23
			]
		],
		[
			[
				50422,
				77176
			],
			[
				175,
				-175
			]
		],
		[
			[
				51098,
				78533
			],
			[
				-11,
				131
			]
		],
		[
			[
				51087,
				78664
			],
			[
				-1008,
				-18
			]
		],
		[
			[
				50079,
				78646
			],
			[
				-83,
				-145
			],
			[
				52,
				-42
			],
			[
				-44,
				-161
			],
			[
				-81,
				113
			],
			[
				-45,
				-135
			],
			[
				74,
				-120
			],
			[
				-109,
				-169
			],
			[
				14,
				-123
			]
		],
		[
			[
				49937,
				71244
			],
			[
				277,
				21
			],
			[
				4,
				-214
			]
		],
		[
			[
				50218,
				71051
			],
			[
				689,
				45
			]
		],
		[
			[
				50907,
				71096
			],
			[
				-41,
				1708
			]
		],
		[
			[
				50866,
				72804
			],
			[
				-6,
				182
			]
		],
		[
			[
				50860,
				72986
			],
			[
				-762,
				-37
			]
		],
		[
			[
				50098,
				72949
			],
			[
				-189,
				-17
			]
		],
		[
			[
				49909,
				72932
			],
			[
				28,
				-1688
			]
		],
		[
			[
				64490,
				25624
			],
			[
				983,
				-101
			]
		],
		[
			[
				65541,
				27197
			],
			[
				-594,
				62
			]
		],
		[
			[
				64947,
				27259
			],
			[
				-202,
				16
			]
		],
		[
			[
				64745,
				27275
			],
			[
				-42,
				-1007
			],
			[
				-187,
				19
			],
			[
				-26,
				-663
			]
		],
		[
			[
				66319,
				23742
			],
			[
				247,
				-31
			],
			[
				38,
				998
			]
		],
		[
			[
				66604,
				24709
			],
			[
				-390,
				49
			],
			[
				-14,
				-332
			],
			[
				-387,
				51
			],
			[
				-14,
				-328
			]
		],
		[
			[
				65799,
				24149
			],
			[
				-16,
				-339
			],
			[
				536,
				-68
			]
		],
		[
			[
				76756,
				67673
			],
			[
				72,
				-61
			],
			[
				83,
				-245
			],
			[
				77,
				-43
			],
			[
				-25,
				-120
			],
			[
				80,
				-160
			]
		],
		[
			[
				77043,
				67044
			],
			[
				133,
				265
			]
		],
		[
			[
				77176,
				67309
			],
			[
				127,
				462
			],
			[
				3,
				239
			]
		],
		[
			[
				77306,
				68010
			],
			[
				-74,
				-62
			],
			[
				-337,
				65
			]
		],
		[
			[
				76895,
				68013
			],
			[
				-152,
				32
			]
		],
		[
			[
				76743,
				68045
			],
			[
				13,
				-372
			]
		],
		[
			[
				71917,
				42834
			],
			[
				482,
				-98
			]
		],
		[
			[
				72399,
				42736
			],
			[
				17,
				280
			],
			[
				-33,
				6
			],
			[
				21,
				331
			]
		],
		[
			[
				72404,
				43353
			],
			[
				-62,
				13
			],
			[
				22,
				336
			]
		],
		[
			[
				72364,
				43702
			],
			[
				-539,
				105
			]
		],
		[
			[
				71825,
				43807
			],
			[
				-42,
				-609
			],
			[
				11,
				-278
			]
		],
		[
			[
				59598,
				40593
			],
			[
				22,
				1212
			]
		],
		[
			[
				59620,
				41805
			],
			[
				-465,
				37
			]
		],
		[
			[
				59155,
				41842
			],
			[
				-307,
				15
			]
		],
		[
			[
				58848,
				41857
			],
			[
				-18,
				-1226
			]
		],
		[
			[
				51783,
				27992
			],
			[
				765,
				23
			]
		],
		[
			[
				52548,
				28015
			],
			[
				-10,
				1329
			]
		],
		[
			[
				52538,
				29344
			],
			[
				-383,
				-12
			]
		],
		[
			[
				52155,
				29332
			],
			[
				-390,
				-15
			]
		],
		[
			[
				51765,
				29317
			],
			[
				18,
				-1325
			]
		],
		[
			[
				49265,
				85418
			],
			[
				768,
				557
			]
		],
		[
			[
				50033,
				85975
			],
			[
				410,
				903
			]
		],
		[
			[
				50443,
				86878
			],
			[
				177,
				371
			]
		],
		[
			[
				50620,
				87249
			],
			[
				-474,
				646
			]
		],
		[
			[
				50146,
				87895
			],
			[
				2,
				-135
			],
			[
				-915,
				-39
			]
		],
		[
			[
				49233,
				87720
			],
			[
				21,
				-1690
			]
		],
		[
			[
				49254,
				86030
			],
			[
				11,
				-612
			]
		],
		[
			[
				48078,
				78880
			],
			[
				750,
				43
			]
		],
		[
			[
				48828,
				78923
			],
			[
				-2,
				73
			],
			[
				244,
				15
			]
		],
		[
			[
				49070,
				79011
			],
			[
				-29,
				1620
			]
		],
		[
			[
				48389,
				80589
			],
			[
				-347,
				-20
			],
			[
				19,
				-809
			]
		],
		[
			[
				48061,
				79760
			],
			[
				17,
				-880
			]
		],
		[
			[
				50251,
				69558
			],
			[
				-33,
				1493
			]
		],
		[
			[
				49937,
				71244
			],
			[
				-699,
				-44
			]
		],
		[
			[
				49238,
				71200
			],
			[
				25,
				-1695
			]
		],
		[
			[
				66822,
				31065
			],
			[
				191,
				-21
			]
		],
		[
			[
				67587,
				30974
			],
			[
				54,
				1337
			]
		],
		[
			[
				67258,
				32370
			],
			[
				-383,
				49
			]
		],
		[
			[
				66875,
				32419
			],
			[
				-53,
				-1354
			]
		],
		[
			[
				12292,
				15590
			],
			[
				28,
				-99
			]
		],
		[
			[
				12320,
				15491
			],
			[
				54,
				-10
			],
			[
				116,
				187
			],
			[
				-3,
				149
			],
			[
				124,
				-46
			],
			[
				253,
				-178
			],
			[
				157,
				140
			],
			[
				126,
				12
			],
			[
				184,
				219
			],
			[
				251,
				109
			],
			[
				99,
				295
			],
			[
				140,
				-49
			],
			[
				147,
				229
			],
			[
				367,
				186
			]
		],
		[
			[
				14335,
				16734
			],
			[
				-66,
				236
			],
			[
				7,
				110
			],
			[
				-63,
				262
			],
			[
				-81,
				85
			],
			[
				-47,
				348
			]
		],
		[
			[
				14085,
				17775
			],
			[
				-61,
				395
			],
			[
				47,
				132
			]
		],
		[
			[
				14071,
				18302
			],
			[
				-813,
				-372
			],
			[
				-113,
				89
			],
			[
				-287,
				-183
			],
			[
				-83,
				-195
			],
			[
				-159,
				-174
			],
			[
				-239,
				7
			],
			[
				-40,
				215
			],
			[
				-398,
				-198
			],
			[
				39,
				-94
			],
			[
				-15,
				-228
			]
		],
		[
			[
				11963,
				17169
			],
			[
				-31,
				-395
			],
			[
				99,
				-208
			],
			[
				27,
				-244
			],
			[
				-37,
				-224
			],
			[
				121,
				-46
			],
			[
				63,
				-162
			],
			[
				158,
				15
			],
			[
				-71,
				-315
			]
		],
		[
			[
				51431,
				82547
			],
			[
				119,
				-163
			]
		],
		[
			[
				51550,
				82384
			],
			[
				636,
				1094
			]
		],
		[
			[
				52186,
				83478
			],
			[
				-4,
				7
			]
		],
		[
			[
				52182,
				83485
			],
			[
				-548,
				574
			],
			[
				-68,
				-75
			]
		],
		[
			[
				51566,
				83984
			],
			[
				-171,
				-128
			],
			[
				-229,
				-456
			],
			[
				-61,
				-227
			]
		],
		[
			[
				51105,
				83173
			],
			[
				-35,
				-99
			],
			[
				199,
				-228
			],
			[
				162,
				-299
			]
		],
		[
			[
				73751,
				54208
			],
			[
				27,
				-285
			],
			[
				61,
				-153
			],
			[
				176,
				-628
			]
		],
		[
			[
				74015,
				53142
			],
			[
				177,
				290
			],
			[
				90,
				-72
			]
		],
		[
			[
				74282,
				53360
			],
			[
				10,
				266
			],
			[
				136,
				166
			]
		],
		[
			[
				74428,
				53792
			],
			[
				-113,
				225
			],
			[
				-77,
				-23
			],
			[
				-8,
				153
			],
			[
				-167,
				257
			]
		],
		[
			[
				74063,
				54404
			],
			[
				-87,
				139
			],
			[
				-221,
				-183
			],
			[
				43,
				113
			]
		],
		[
			[
				73798,
				54473
			],
			[
				-116,
				-146
			],
			[
				69,
				-119
			]
		],
		[
			[
				87261,
				51454
			],
			[
				75,
				-47
			],
			[
				-3,
				183
			],
			[
				-72,
				-136
			]
		],
		[
			[
				67791,
				25351
			],
			[
				35,
				-130
			]
		],
		[
			[
				67826,
				25221
			],
			[
				573,
				-82
			]
		],
		[
			[
				68399,
				25139
			],
			[
				-125,
				495
			],
			[
				-73,
				875
			]
		],
		[
			[
				67846,
				26564
			],
			[
				-55,
				-1213
			]
		],
		[
			[
				29135,
				50294
			],
			[
				85,
				-96
			],
			[
				90,
				-222
			],
			[
				110,
				-38
			],
			[
				53,
				-193
			],
			[
				121,
				-239
			],
			[
				-64,
				-15
			],
			[
				135,
				-290
			],
			[
				8,
				-93
			],
			[
				324,
				38
			],
			[
				38,
				-180
			],
			[
				146,
				-216
			],
			[
				99,
				39
			],
			[
				135,
				-369
			],
			[
				35,
				32
			],
			[
				133,
				-168
			]
		],
		[
			[
				30583,
				48284
			],
			[
				85,
				-118
			],
			[
				-46,
				-156
			],
			[
				-2,
				-233
			],
			[
				-70,
				-303
			],
			[
				-69,
				-125
			],
			[
				60,
				-107
			],
			[
				23,
				-243
			],
			[
				-33,
				-86
			]
		],
		[
			[
				30531,
				46913
			],
			[
				1655,
				406
			]
		],
		[
			[
				32186,
				47319
			],
			[
				-70,
				858
			],
			[
				-6,
				476
			]
		],
		[
			[
				32110,
				48653
			],
			[
				-84,
				1040
			]
		],
		[
			[
				32026,
				49693
			],
			[
				-125,
				1514
			]
		],
		[
			[
				31901,
				51207
			],
			[
				-153,
				1857
			]
		],
		[
			[
				31748,
				53064
			],
			[
				-1673,
				-406
			]
		],
		[
			[
				30075,
				52658
			],
			[
				-820,
				-212
			],
			[
				-51,
				-38
			],
			[
				-438,
				-111
			]
		],
		[
			[
				27719,
				52019
			],
			[
				128,
				-108
			],
			[
				77,
				75
			],
			[
				125,
				-261
			],
			[
				225,
				64
			],
			[
				173,
				-54
			],
			[
				114,
				-165
			],
			[
				59,
				-282
			],
			[
				99,
				-238
			],
			[
				181,
				-50
			],
			[
				1,
				-227
			],
			[
				102,
				-76
			],
			[
				-73,
				-120
			],
			[
				154,
				-131
			],
			[
				51,
				-152
			]
		],
		[
			[
				66848,
				27026
			],
			[
				336,
				-39
			]
		],
		[
			[
				67184,
				26987
			],
			[
				238,
				-30
			]
		],
		[
			[
				67490,
				28292
			],
			[
				-193,
				28
			]
		],
		[
			[
				67297,
				28320
			],
			[
				-7,
				-176
			],
			[
				-388,
				51
			]
		],
		[
			[
				66902,
				28195
			],
			[
				-54,
				-1169
			]
		],
		[
			[
				71223,
				37007
			],
			[
				10,
				166
			]
		],
		[
			[
				71233,
				37173
			],
			[
				71,
				1005
			],
			[
				-39,
				8
			]
		],
		[
			[
				71265,
				38186
			],
			[
				-647,
				119
			]
		],
		[
			[
				70618,
				38305
			],
			[
				-58,
				-1003
			]
		],
		[
			[
				70560,
				37302
			],
			[
				-11,
				-166
			],
			[
				674,
				-129
			]
		],
		[
			[
				33390,
				62849
			],
			[
				943,
				193
			]
		],
		[
			[
				34333,
				63042
			],
			[
				584,
				114
			],
			[
				195,
				225
			],
			[
				460,
				376
			],
			[
				155,
				96
			]
		],
		[
			[
				35727,
				63853
			],
			[
				-42,
				690
			],
			[
				896,
				164
			]
		],
		[
			[
				36581,
				64707
			],
			[
				-103,
				1664
			],
			[
				-235,
				-44
			],
			[
				-41,
				672
			],
			[
				-588,
				-147
			],
			[
				-42,
				690
			]
		],
		[
			[
				35572,
				67542
			],
			[
				-188,
				-18
			],
			[
				-2277,
				-461
			]
		],
		[
			[
				33107,
				67063
			],
			[
				93,
				-1278
			],
			[
				101,
				-1719
			],
			[
				89,
				-1217
			]
		],
		[
			[
				71233,
				37173
			],
			[
				671,
				-127
			]
		],
		[
			[
				71904,
				37046
			],
			[
				37,
				544
			]
		],
		[
			[
				71941,
				37590
			],
			[
				27,
				445
			],
			[
				-56,
				11
			],
			[
				36,
				504
			]
		],
		[
			[
				71948,
				38550
			],
			[
				-433,
				95
			]
		],
		[
			[
				71515,
				38645
			],
			[
				-122,
				25
			],
			[
				-10,
				-169
			],
			[
				-95,
				22
			],
			[
				-23,
				-337
			]
		],
		[
			[
				71880,
				36715
			],
			[
				760,
				-157
			]
		],
		[
			[
				72640,
				36558
			],
			[
				70,
				1003
			]
		],
		[
			[
				72710,
				37561
			],
			[
				-191,
				38
			]
		],
		[
			[
				72519,
				37599
			],
			[
				-381,
				64
			],
			[
				-6,
				-111
			],
			[
				-191,
				38
			]
		],
		[
			[
				71904,
				37046
			],
			[
				-24,
				-331
			]
		],
		[
			[
				25155,
				20669
			],
			[
				175,
				-264
			],
			[
				16,
				-311
			],
			[
				51,
				-254
			],
			[
				6,
				-205
			],
			[
				54,
				-312
			],
			[
				51,
				-51
			]
		],
		[
			[
				25508,
				19272
			],
			[
				182,
				-322
			],
			[
				-130,
				-436
			],
			[
				-100,
				-192
			],
			[
				32,
				-270
			],
			[
				182,
				208
			],
			[
				80,
				-175
			],
			[
				123,
				-125
			]
		],
		[
			[
				25877,
				17960
			],
			[
				149,
				68
			],
			[
				47,
				216
			],
			[
				105,
				232
			],
			[
				126,
				-100
			],
			[
				42,
				-181
			],
			[
				81,
				63
			],
			[
				97,
				-49
			],
			[
				134,
				-229
			],
			[
				84,
				-54
			],
			[
				17,
				-167
			],
			[
				115,
				63
			]
		],
		[
			[
				28092,
				23303
			],
			[
				-57,
				553
			],
			[
				-284,
				-101
			]
		],
		[
			[
				27751,
				23755
			],
			[
				-511,
				-155
			]
		],
		[
			[
				27240,
				23600
			],
			[
				3,
				-334
			],
			[
				-74,
				-271
			],
			[
				-38,
				-280
			],
			[
				-100,
				80
			],
			[
				-233,
				-185
			],
			[
				-229,
				-247
			],
			[
				-132,
				-327
			],
			[
				-133,
				-446
			],
			[
				-42,
				-345
			],
			[
				-90,
				71
			],
			[
				-119,
				-104
			],
			[
				76,
				-279
			],
			[
				4,
				-148
			],
			[
				-113,
				23
			],
			[
				-145,
				161
			],
			[
				-128,
				248
			],
			[
				10,
				192
			],
			[
				-86,
				49
			],
			[
				-88,
				194
			],
			[
				-57,
				-59
			],
			[
				-84,
				82
			],
			[
				-33,
				-179
			],
			[
				-86,
				-199
			],
			[
				-126,
				-8
			],
			[
				-7,
				-165
			],
			[
				-74,
				-230
			],
			[
				39,
				-225
			]
		],
		[
			[
				54960,
				31640
			],
			[
				768,
				4
			]
		],
		[
			[
				55728,
				31644
			],
			[
				5,
				1327
			]
		],
		[
			[
				55733,
				32971
			],
			[
				-767,
				8
			]
		],
		[
			[
				54966,
				32979
			],
			[
				-6,
				-1339
			]
		],
		[
			[
				57298,
				40681
			],
			[
				764,
				-14
			]
		],
		[
			[
				58062,
				40667
			],
			[
				14,
				1245
			]
		],
		[
			[
				58076,
				41912
			],
			[
				-364,
				20
			]
		],
		[
			[
				57712,
				41932
			],
			[
				-402,
				16
			]
		],
		[
			[
				57310,
				41948
			],
			[
				-12,
				-1267
			]
		],
		[
			[
				58521,
				35593
			],
			[
				386,
				-17
			]
		],
		[
			[
				58907,
				35576
			],
			[
				381,
				-16
			]
		],
		[
			[
				59288,
				35560
			],
			[
				22,
				1332
			]
		],
		[
			[
				59310,
				36892
			],
			[
				-192,
				8
			]
		],
		[
			[
				59118,
				36900
			],
			[
				-578,
				24
			]
		],
		[
			[
				57289,
				39687
			],
			[
				382,
				-10
			]
		],
		[
			[
				57671,
				39677
			],
			[
				380,
				-11
			]
		],
		[
			[
				58051,
				39666
			],
			[
				11,
				1001
			]
		],
		[
			[
				57298,
				40681
			],
			[
				-9,
				-994
			]
		],
		[
			[
				60343,
				39538
			],
			[
				384,
				-27
			]
		],
		[
			[
				60727,
				39511
			],
			[
				384,
				-28
			]
		],
		[
			[
				61111,
				39483
			],
			[
				25,
				1013
			]
		],
		[
			[
				61136,
				40496
			],
			[
				-770,
				53
			]
		],
		[
			[
				60366,
				40549
			],
			[
				-23,
				-1011
			]
		],
		[
			[
				74611,
				58555
			],
			[
				223,
				-289
			]
		],
		[
			[
				74834,
				58266
			],
			[
				123,
				255
			]
		],
		[
			[
				74957,
				58521
			],
			[
				18,
				242
			],
			[
				-80,
				-25
			],
			[
				-21,
				106
			],
			[
				9,
				470
			],
			[
				-141,
				37
			],
			[
				39,
				199
			],
			[
				-79,
				227
			],
			[
				-108,
				-72
			],
			[
				-70,
				237
			]
		],
		[
			[
				74524,
				59942
			],
			[
				-98,
				-87
			],
			[
				-61,
				44
			],
			[
				-67,
				-104
			]
		],
		[
			[
				74298,
				59795
			],
			[
				-28,
				-121
			],
			[
				74,
				-179
			],
			[
				-26,
				-141
			],
			[
				96,
				-203
			],
			[
				197,
				-596
			]
		],
		[
			[
				66466,
				25746
			],
			[
				572,
				-56
			]
		],
		[
			[
				67038,
				25690
			],
			[
				86,
				-20
			],
			[
				60,
				1317
			]
		],
		[
			[
				66848,
				27026
			],
			[
				-534,
				74
			]
		],
		[
			[
				68067,
				47703
			],
			[
				537,
				-87
			]
		],
		[
			[
				68604,
				47616
			],
			[
				65,
				-9
			]
		],
		[
			[
				68669,
				47607
			],
			[
				47,
				1078
			]
		],
		[
			[
				68716,
				48685
			],
			[
				-72,
				10
			]
		],
		[
			[
				68644,
				48695
			],
			[
				-334,
				52
			]
		],
		[
			[
				68310,
				48747
			],
			[
				-6,
				-111
			],
			[
				-183,
				20
			]
		],
		[
			[
				68121,
				48656
			],
			[
				-81,
				-123
			],
			[
				38,
				-115
			],
			[
				-32,
				-264
			],
			[
				44,
				-4
			],
			[
				-23,
				-447
			]
		],
		[
			[
				63341,
				43046
			],
			[
				-7,
				-352
			]
		],
		[
			[
				63334,
				42694
			],
			[
				777,
				-43
			]
		],
		[
			[
				64111,
				42651
			],
			[
				9,
				337
			],
			[
				426,
				-25
			]
		],
		[
			[
				64546,
				42963
			],
			[
				-23,
				132
			],
			[
				-89,
				-7
			],
			[
				-143,
				132
			]
		],
		[
			[
				64291,
				43220
			],
			[
				-116,
				259
			],
			[
				-6,
				154
			],
			[
				-128,
				139
			]
		],
		[
			[
				64041,
				43772
			],
			[
				-158,
				40
			],
			[
				6,
				-85
			],
			[
				-135,
				-178
			],
			[
				-36,
				-193
			],
			[
				-369,
				32
			]
		],
		[
			[
				63349,
				43388
			],
			[
				-8,
				-342
			]
		],
		[
			[
				41670,
				68855
			],
			[
				38,
				-698
			]
		],
		[
			[
				41708,
				68157
			],
			[
				52,
				-974
			]
		],
		[
			[
				41760,
				67183
			],
			[
				794,
				96
			]
		],
		[
			[
				42554,
				67279
			],
			[
				-31,
				1681
			]
		],
		[
			[
				42523,
				68960
			],
			[
				-853,
				-105
			]
		],
		[
			[
				56357,
				50384
			],
			[
				785,
				-12
			]
		],
		[
			[
				57142,
				50372
			],
			[
				10,
				1269
			]
		],
		[
			[
				57153,
				51730
			],
			[
				-809,
				14
			]
		],
		[
			[
				56344,
				51744
			],
			[
				-1,
				-853
			],
			[
				18,
				0
			],
			[
				-4,
				-507
			]
		],
		[
			[
				95223,
				17589
			],
			[
				151,
				-185
			]
		],
		[
			[
				95374,
				17404
			],
			[
				107,
				361
			],
			[
				-42,
				70
			],
			[
				133,
				226
			],
			[
				54,
				342
			],
			[
				141,
				34
			],
			[
				16,
				215
			]
		],
		[
			[
				95783,
				18652
			],
			[
				46,
				572
			]
		],
		[
			[
				95829,
				19224
			],
			[
				-84,
				315
			],
			[
				-149,
				-86
			],
			[
				-238,
				-252
			],
			[
				-44,
				225
			],
			[
				-207,
				-84
			]
		],
		[
			[
				95107,
				19342
			],
			[
				-25,
				-101
			],
			[
				159,
				-664
			],
			[
				-1,
				-614
			],
			[
				-60,
				-281
			],
			[
				43,
				-93
			]
		],
		[
			[
				55575,
				51752
			],
			[
				769,
				-8
			]
		],
		[
			[
				56344,
				51744
			],
			[
				2,
				841
			],
			[
				-13,
				337
			]
		],
		[
			[
				56333,
				52922
			],
			[
				-766,
				9
			]
		],
		[
			[
				55567,
				52931
			],
			[
				10,
				-338
			],
			[
				-2,
				-841
			]
		],
		[
			[
				60812,
				50060
			],
			[
				147,
				-1212
			]
		],
		[
			[
				60959,
				48848
			],
			[
				92,
				126
			],
			[
				-22,
				100
			],
			[
				240,
				124
			]
		],
		[
			[
				61269,
				49198
			],
			[
				107,
				244
			],
			[
				169,
				70
			],
			[
				96,
				-61
			]
		],
		[
			[
				61641,
				49451
			],
			[
				7,
				274
			],
			[
				-57,
				133
			],
			[
				-201,
				102
			],
			[
				83,
				156
			],
			[
				-132,
				129
			],
			[
				1,
				137
			]
		],
		[
			[
				61342,
				50382
			],
			[
				-96,
				56
			],
			[
				-61,
				-54
			],
			[
				-215,
				0
			],
			[
				1,
				-325
			],
			[
				-159,
				1
			]
		],
		[
			[
				59815,
				48185
			],
			[
				0,
				-170
			],
			[
				190,
				-366
			]
		],
		[
			[
				60005,
				47649
			],
			[
				37,
				165
			],
			[
				109,
				145
			],
			[
				106,
				33
			],
			[
				125,
				-55
			],
			[
				277,
				36
			]
		],
		[
			[
				60659,
				47973
			],
			[
				107,
				181
			]
		],
		[
			[
				60766,
				48154
			],
			[
				-186,
				890
			],
			[
				1,
				56
			],
			[
				-385,
				8
			]
		],
		[
			[
				60196,
				49108
			],
			[
				-381,
				-15
			]
		],
		[
			[
				59815,
				49093
			],
			[
				0,
				-908
			]
		],
		[
			[
				69535,
				66567
			],
			[
				580,
				-68
			]
		],
		[
			[
				70115,
				66499
			],
			[
				8,
				166
			],
			[
				193,
				-12
			],
			[
				41,
				672
			],
			[
				193,
				-18
			],
			[
				17,
				334
			]
		],
		[
			[
				70567,
				67641
			],
			[
				-384,
				29
			],
			[
				-58,
				124
			],
			[
				11,
				223
			],
			[
				-321,
				36
			]
		],
		[
			[
				69815,
				68053
			],
			[
				-195,
				31
			]
		],
		[
			[
				69620,
				68084
			],
			[
				-85,
				-1517
			]
		],
		[
			[
				10181,
				22270
			],
			[
				-27,
				165
			],
			[
				209,
				105
			],
			[
				42,
				141
			],
			[
				149,
				105
			],
			[
				141,
				-45
			],
			[
				145,
				75
			],
			[
				49,
				142
			]
		],
		[
			[
				10889,
				22958
			],
			[
				-128,
				763
			],
			[
				-317,
				1815
			]
		],
		[
			[
				10444,
				25536
			],
			[
				-456,
				-219
			]
		],
		[
			[
				9988,
				25317
			],
			[
				-483,
				-226
			]
		],
		[
			[
				9505,
				25091
			],
			[
				2,
				-220
			],
			[
				55,
				-114
			],
			[
				-19,
				-181
			],
			[
				87,
				-123
			],
			[
				73,
				-206
			],
			[
				-62,
				-493
			],
			[
				-223,
				-177
			],
			[
				69,
				-201
			],
			[
				50,
				-282
			],
			[
				187,
				80
			],
			[
				152,
				-295
			],
			[
				78,
				-222
			],
			[
				227,
				-387
			]
		],
		[
			[
				22097,
				26224
			],
			[
				457,
				164
			],
			[
				228,
				-1794
			]
		],
		[
			[
				22782,
				24594
			],
			[
				248,
				18
			],
			[
				84,
				-121
			],
			[
				139,
				-17
			],
			[
				121,
				-137
			],
			[
				23,
				-145
			],
			[
				88,
				-151
			],
			[
				68,
				29
			],
			[
				89,
				-298
			],
			[
				401,
				-107
			],
			[
				179,
				-419
			],
			[
				65,
				79
			],
			[
				-9,
				213
			],
			[
				96,
				248
			],
			[
				122,
				13
			]
		],
		[
			[
				24496,
				23799
			],
			[
				26,
				54
			]
		],
		[
			[
				24522,
				23853
			],
			[
				-51,
				75
			],
			[
				-13,
				214
			]
		],
		[
			[
				24458,
				24142
			],
			[
				-64,
				118
			],
			[
				-70,
				279
			],
			[
				31,
				199
			],
			[
				-49,
				61
			],
			[
				-25,
				222
			],
			[
				-84,
				26
			],
			[
				-188,
				1526
			]
		],
		[
			[
				24009,
				26573
			],
			[
				-127,
				1069
			],
			[
				77,
				38
			]
		],
		[
			[
				23959,
				27680
			],
			[
				-64,
				540
			]
		],
		[
			[
				23895,
				28220
			],
			[
				-666,
				-231
			],
			[
				87,
				-543
			],
			[
				-133,
				-159
			],
			[
				-406,
				-157
			],
			[
				3,
				-109
			],
			[
				-179,
				-108
			],
			[
				-26,
				-77
			],
			[
				-73,
				177
			],
			[
				-74,
				-26
			],
			[
				-48,
				-161
			],
			[
				-136,
				-158
			],
			[
				-66,
				-161
			],
			[
				18,
				-142
			],
			[
				-99,
				-141
			]
		],
		[
			[
				66672,
				59365
			],
			[
				111,
				-34
			],
			[
				122,
				-312
			],
			[
				24,
				-144
			]
		],
		[
			[
				66929,
				58875
			],
			[
				99,
				111
			],
			[
				65,
				401
			],
			[
				262,
				103
			],
			[
				95,
				103
			]
		],
		[
			[
				67450,
				59593
			],
			[
				44,
				46
			],
			[
				-212,
				463
			],
			[
				-90,
				-72
			]
		],
		[
			[
				67192,
				60030
			],
			[
				-86,
				-15
			],
			[
				-164,
				-165
			],
			[
				-121,
				-15
			],
			[
				-162,
				-223
			]
		],
		[
			[
				66659,
				59612
			],
			[
				13,
				-247
			]
		],
		[
			[
				62733,
				48870
			],
			[
				86,
				-42
			],
			[
				165,
				239
			],
			[
				4,
				82
			],
			[
				165,
				76
			],
			[
				94,
				-51
			],
			[
				202,
				221
			]
		],
		[
			[
				63449,
				49395
			],
			[
				85,
				41
			],
			[
				239,
				-253
			],
			[
				66,
				-179
			]
		],
		[
			[
				63839,
				49004
			],
			[
				15,
				666
			]
		],
		[
			[
				63854,
				49670
			],
			[
				4,
				196
			],
			[
				-48,
				823
			]
		],
		[
			[
				63810,
				50689
			],
			[
				-548,
				50
			]
		],
		[
			[
				63262,
				50739
			],
			[
				-472,
				41
			],
			[
				0,
				-22
			]
		],
		[
			[
				62790,
				50758
			],
			[
				-57,
				-1888
			]
		],
		[
			[
				28120,
				24890
			],
			[
				35,
				-327
			],
			[
				849,
				256
			]
		],
		[
			[
				29004,
				24819
			],
			[
				-34,
				327
			],
			[
				283,
				84
			],
			[
				-16,
				165
			]
		],
		[
			[
				29237,
				25395
			],
			[
				-75,
				654
			],
			[
				89,
				-27
			],
			[
				296,
				521
			],
			[
				145,
				41
			],
			[
				-11,
				108
			]
		],
		[
			[
				29681,
				26692
			],
			[
				-1418,
				-413
			]
		],
		[
			[
				28263,
				26279
			],
			[
				-283,
				-72
			]
		],
		[
			[
				27980,
				26207
			],
			[
				140,
				-1317
			]
		],
		[
			[
				42550,
				31804
			],
			[
				817,
				108
			]
		],
		[
			[
				43367,
				31912
			],
			[
				339,
				44
			]
		],
		[
			[
				43706,
				31956
			],
			[
				-36,
				824
			],
			[
				32,
				3
			],
			[
				-55,
				1325
			]
		],
		[
			[
				43647,
				34108
			],
			[
				-1098,
				-131
			]
		],
		[
			[
				42549,
				33977
			],
			[
				-58,
				-7
			],
			[
				61,
				-1333
			],
			[
				-37,
				-5
			],
			[
				35,
				-828
			]
		],
		[
			[
				45804,
				35686
			],
			[
				954,
				104
			]
		],
		[
			[
				46758,
				35790
			],
			[
				-33,
				1337
			]
		],
		[
			[
				46725,
				37127
			],
			[
				-932,
				-96
			]
		],
		[
			[
				45793,
				37031
			],
			[
				-32,
				-3
			]
		],
		[
			[
				45761,
				37028
			],
			[
				43,
				-1342
			]
		],
		[
			[
				67321,
				47886
			],
			[
				-13,
				-340
			]
		],
		[
			[
				67308,
				47546
			],
			[
				571,
				-62
			]
		],
		[
			[
				67879,
				47484
			],
			[
				10,
				226
			],
			[
				178,
				-7
			]
		],
		[
			[
				68121,
				48656
			],
			[
				-768,
				77
			]
		],
		[
			[
				67353,
				48733
			],
			[
				-32,
				-847
			]
		],
		[
			[
				63810,
				50689
			],
			[
				185,
				374
			],
			[
				75,
				94
			]
		],
		[
			[
				64070,
				51157
			],
			[
				35,
				1318
			]
		],
		[
			[
				64105,
				52475
			],
			[
				-401,
				40
			],
			[
				-395,
				13
			]
		],
		[
			[
				63309,
				52528
			],
			[
				-47,
				-1789
			]
		],
		[
			[
				60228,
				52150
			],
			[
				411,
				-12
			],
			[
				39,
				199
			],
			[
				92,
				148
			],
			[
				81,
				-110
			],
			[
				86,
				8
			],
			[
				73,
				-139
			]
		],
		[
			[
				61010,
				52244
			],
			[
				4,
				560
			],
			[
				294,
				234
			],
			[
				3,
				169
			]
		],
		[
			[
				61311,
				53207
			],
			[
				7,
				508
			]
		],
		[
			[
				61318,
				53715
			],
			[
				-763,
				16
			]
		],
		[
			[
				60555,
				53731
			],
			[
				-293,
				7
			]
		],
		[
			[
				60081,
				43889
			],
			[
				852,
				-51
			]
		],
		[
			[
				60933,
				43838
			],
			[
				8,
				323
			],
			[
				96,
				-3
			]
		],
		[
			[
				61037,
				44158
			],
			[
				11,
				1339
			]
		],
		[
			[
				61048,
				45497
			],
			[
				-2,
				0
			]
		],
		[
			[
				61046,
				45497
			],
			[
				-668,
				23
			]
		],
		[
			[
				60378,
				45520
			],
			[
				-2,
				-338
			],
			[
				-287,
				9
			]
		],
		[
			[
				60089,
				45191
			],
			[
				-8,
				-1302
			]
		],
		[
			[
				73998,
				31434
			],
			[
				380,
				-98
			]
		],
		[
			[
				74378,
				31336
			],
			[
				381,
				-94
			]
		],
		[
			[
				74759,
				31242
			],
			[
				138,
				1335
			]
		],
		[
			[
				74897,
				32577
			],
			[
				-757,
				191
			]
		],
		[
			[
				74140,
				32768
			],
			[
				-15,
				4
			]
		],
		[
			[
				74125,
				32772
			],
			[
				-127,
				-1338
			]
		],
		[
			[
				86669,
				34111
			],
			[
				158,
				9
			]
		],
		[
			[
				86827,
				34120
			],
			[
				78,
				335
			],
			[
				84,
				9
			],
			[
				33,
				201
			],
			[
				111,
				172
			],
			[
				4,
				249
			]
		],
		[
			[
				87137,
				35086
			],
			[
				-74,
				-195
			],
			[
				-184,
				-50
			],
			[
				-206,
				156
			],
			[
				29,
				-583
			],
			[
				-33,
				-303
			]
		],
		[
			[
				72252,
				22754
			],
			[
				192,
				-32
			],
			[
				-22,
				-331
			]
		],
		[
			[
				72422,
				22391
			],
			[
				575,
				-107
			]
		],
		[
			[
				72997,
				22284
			],
			[
				86,
				1307
			]
		],
		[
			[
				73083,
				23591
			],
			[
				-747,
				143
			]
		],
		[
			[
				72336,
				23734
			],
			[
				-84,
				-980
			]
		],
		[
			[
				93633,
				21082
			],
			[
				533,
				525
			],
			[
				171,
				52
			]
		],
		[
			[
				94337,
				21659
			],
			[
				-29,
				119
			],
			[
				141,
				559
			],
			[
				-78,
				318
			]
		],
		[
			[
				94371,
				22655
			],
			[
				-603,
				-405
			],
			[
				-74,
				102
			],
			[
				-71,
				-185
			],
			[
				-95,
				-49
			],
			[
				-85,
				-152
			]
		],
		[
			[
				93443,
				21966
			],
			[
				-19,
				-89
			],
			[
				106,
				-179
			],
			[
				-32,
				-241
			],
			[
				189,
				-131
			],
			[
				-54,
				-244
			]
		],
		[
			[
				51715,
				36082
			],
			[
				768,
				17
			]
		],
		[
			[
				52483,
				36099
			],
			[
				-11,
				1339
			]
		],
		[
			[
				52472,
				37438
			],
			[
				-765,
				-18
			]
		],
		[
			[
				51707,
				37420
			],
			[
				3,
				-671
			]
		],
		[
			[
				51710,
				36749
			],
			[
				5,
				-667
			]
		],
		[
			[
				49782,
				14946
			],
			[
				1161,
				62
			]
		],
		[
			[
				50943,
				15008
			],
			[
				-14,
				990
			]
		],
		[
			[
				50929,
				15998
			],
			[
				-1168,
				-63
			]
		],
		[
			[
				49761,
				15935
			],
			[
				21,
				-989
			]
		],
		[
			[
				44621,
				39763
			],
			[
				1338,
				136
			]
		],
		[
			[
				45959,
				39899
			],
			[
				-34,
				1179
			]
		],
		[
			[
				45925,
				41078
			],
			[
				-164,
				-17
			]
		],
		[
			[
				45761,
				41061
			],
			[
				-1184,
				-119
			]
		],
		[
			[
				44577,
				40942
			],
			[
				8,
				-201
			]
		],
		[
			[
				44585,
				40741
			],
			[
				35,
				-972
			]
		],
		[
			[
				44620,
				39769
			],
			[
				1,
				-6
			]
		],
		[
			[
				58165,
				55517
			],
			[
				1,
				-934
			]
		],
		[
			[
				58917,
				54585
			],
			[
				37,
				0
			],
			[
				4,
				708
			]
		],
		[
			[
				58958,
				55293
			],
			[
				3,
				395
			]
		],
		[
			[
				58961,
				55688
			],
			[
				3,
				282
			]
		],
		[
			[
				58964,
				55970
			],
			[
				-800,
				-4
			]
		],
		[
			[
				58164,
				55966
			],
			[
				1,
				-449
			]
		],
		[
			[
				52701,
				72893
			],
			[
				91,
				4
			]
		],
		[
			[
				53744,
				72924
			],
			[
				98,
				428
			],
			[
				79,
				88
			],
			[
				-33,
				202
			]
		],
		[
			[
				53888,
				73642
			],
			[
				129,
				116
			]
		],
		[
			[
				54017,
				73758
			],
			[
				-969,
				968
			]
		],
		[
			[
				53048,
				74726
			],
			[
				-84,
				83
			],
			[
				-269,
				-825
			]
		],
		[
			[
				52695,
				73984
			],
			[
				6,
				-1091
			]
		],
		[
			[
				75008,
				38810
			],
			[
				606,
				-128
			]
		],
		[
			[
				75614,
				38682
			],
			[
				33,
				-7
			],
			[
				35,
				444
			],
			[
				131,
				28
			]
		],
		[
			[
				75813,
				39147
			],
			[
				56,
				694
			]
		],
		[
			[
				75700,
				39884
			],
			[
				-1,
				-20
			],
			[
				-608,
				47
			],
			[
				-2,
				-26
			]
		],
		[
			[
				75089,
				39885
			],
			[
				-31,
				-405
			]
		],
		[
			[
				75058,
				39480
			],
			[
				-50,
				-670
			]
		],
		[
			[
				41473,
				35509
			],
			[
				1070,
				142
			]
		],
		[
			[
				42543,
				35651
			],
			[
				63,
				11
			]
		],
		[
			[
				42542,
				36826
			],
			[
				-1127,
				-152
			]
		],
		[
			[
				41415,
				36674
			],
			[
				58,
				-1165
			]
		],
		[
			[
				23847,
				92914
			],
			[
				91,
				-43
			],
			[
				130,
				212
			],
			[
				202,
				195
			],
			[
				-91,
				178
			],
			[
				-141,
				25
			],
			[
				-6,
				124
			],
			[
				-97,
				-13
			]
		],
		[
			[
				23574,
				91990
			],
			[
				13,
				-76
			],
			[
				138,
				37
			],
			[
				93,
				-58
			],
			[
				92,
				184
			],
			[
				61,
				-79
			]
		],
		[
			[
				23971,
				91998
			],
			[
				257,
				411
			],
			[
				-17,
				181
			],
			[
				140,
				-19
			],
			[
				13,
				218
			]
		],
		[
			[
				24364,
				92789
			],
			[
				-39,
				21
			],
			[
				-28,
				287
			],
			[
				-63,
				19
			]
		],
		[
			[
				24234,
				93116
			],
			[
				-120,
				-107
			],
			[
				-138,
				-197
			],
			[
				-246,
				-69
			],
			[
				-17,
				-242
			],
			[
				-74,
				-272
			],
			[
				-79,
				-23
			],
			[
				-12,
				-118
			],
			[
				219,
				58
			],
			[
				-193,
				-156
			]
		],
		[
			[
				24364,
				92789
			],
			[
				122,
				51
			],
			[
				67,
				249
			],
			[
				100,
				-76
			],
			[
				254,
				138
			]
		],
		[
			[
				24907,
				93151
			],
			[
				72,
				159
			],
			[
				-142,
				280
			],
			[
				57,
				117
			],
			[
				-143,
				-26
			],
			[
				-51,
				96
			],
			[
				-26,
				304
			],
			[
				51,
				196
			],
			[
				-77,
				120
			]
		],
		[
			[
				24648,
				94397
			],
			[
				-2,
				-273
			],
			[
				-48,
				-275
			],
			[
				15,
				-216
			],
			[
				-61,
				230
			],
			[
				44,
				113
			],
			[
				-91,
				164
			],
			[
				-86,
				-215
			],
			[
				-101,
				-7
			],
			[
				-30,
				-132
			],
			[
				44,
				-187
			],
			[
				80,
				-60
			],
			[
				-58,
				-170
			],
			[
				83,
				-8
			],
			[
				-191,
				-138
			],
			[
				-12,
				-107
			]
		],
		[
			[
				24110,
				93691
			],
			[
				33,
				-161
			],
			[
				142,
				12
			],
			[
				12,
				158
			],
			[
				-80,
				94
			],
			[
				-107,
				-103
			]
		],
		[
			[
				48065,
				85960
			],
			[
				1189,
				70
			]
		],
		[
			[
				48064,
				87681
			],
			[
				-26,
				-1
			]
		],
		[
			[
				48038,
				87680
			],
			[
				27,
				-1720
			]
		],
		[
			[
				50973,
				16327
			],
			[
				772,
				31
			]
		],
		[
			[
				52134,
				16372
			],
			[
				-10,
				995
			],
			[
				37,
				1
			],
			[
				-14,
				1327
			]
		],
		[
			[
				52147,
				18695
			],
			[
				-542,
				-21
			]
		],
		[
			[
				51605,
				18674
			],
			[
				-624,
				-27
			]
		],
		[
			[
				50981,
				18647
			],
			[
				19,
				-1327
			],
			[
				-43,
				-1
			],
			[
				16,
				-992
			]
		],
		[
			[
				50948,
				14685
			],
			[
				781,
				32
			]
		],
		[
			[
				50973,
				16327
			],
			[
				5,
				-327
			],
			[
				-49,
				-2
			]
		],
		[
			[
				50943,
				15008
			],
			[
				5,
				-323
			]
		],
		[
			[
				87335,
				32421
			],
			[
				-26,
				658
			]
		],
		[
			[
				87309,
				33079
			],
			[
				-13,
				266
			]
		],
		[
			[
				87296,
				33345
			],
			[
				-159,
				64
			],
			[
				-49,
				147
			]
		],
		[
			[
				87088,
				33556
			],
			[
				-250,
				-25
			],
			[
				-302,
				-291
			],
			[
				-188,
				-660
			]
		],
		[
			[
				86348,
				32580
			],
			[
				987,
				-159
			]
		],
		[
			[
				77042,
				40172
			],
			[
				166,
				-22
			],
			[
				-58,
				-784
			],
			[
				32,
				-7
			]
		],
		[
			[
				77182,
				39359
			],
			[
				476,
				-137
			]
		],
		[
			[
				77658,
				39222
			],
			[
				190,
				-99
			]
		],
		[
			[
				77848,
				39123
			],
			[
				67,
				-36
			],
			[
				29,
				448
			]
		],
		[
			[
				77944,
				39535
			],
			[
				52,
				834
			]
		],
		[
			[
				77996,
				40369
			],
			[
				-473,
				91
			],
			[
				-4,
				-73
			],
			[
				-464,
				65
			]
		],
		[
			[
				77055,
				40452
			],
			[
				-13,
				-280
			]
		],
		[
			[
				84876,
				31477
			],
			[
				1041,
				-362
			]
		],
		[
			[
				85917,
				31115
			],
			[
				62,
				-24
			]
		],
		[
			[
				85979,
				31091
			],
			[
				267,
				1498
			]
		],
		[
			[
				86246,
				32589
			],
			[
				-119,
				220
			],
			[
				-1036,
				390
			]
		],
		[
			[
				60829,
				65553
			],
			[
				80,
				-1
			],
			[
				61,
				-168
			],
			[
				338,
				-7
			],
			[
				-1,
				-169
			],
			[
				288,
				-10
			]
		],
		[
			[
				61595,
				65198
			],
			[
				69,
				-2
			]
		],
		[
			[
				61664,
				65196
			],
			[
				15,
				1011
			],
			[
				-37,
				1
			],
			[
				14,
				648
			]
		],
		[
			[
				61656,
				66856
			],
			[
				-190,
				23
			]
		],
		[
			[
				61466,
				66879
			],
			[
				-131,
				-206
			],
			[
				-164,
				-101
			],
			[
				-339,
				0
			]
		],
		[
			[
				43734,
				35795
			],
			[
				1006,
				101
			]
		],
		[
			[
				44740,
				35896
			],
			[
				-40,
				1026
			],
			[
				136,
				14
			]
		],
		[
			[
				44836,
				36936
			],
			[
				-48,
				1336
			]
		],
		[
			[
				44788,
				38272
			],
			[
				-117,
				-14
			],
			[
				-25,
				667
			]
		],
		[
			[
				44646,
				38925
			],
			[
				-924,
				-105
			]
		],
		[
			[
				43722,
				38820
			],
			[
				29,
				-667
			],
			[
				-38,
				-174
			]
		],
		[
			[
				81687,
				33071
			],
			[
				-56,
				-566
			]
		],
		[
			[
				81631,
				32505
			],
			[
				893,
				-271
			]
		],
		[
			[
				82524,
				32234
			],
			[
				231,
				-67
			]
		],
		[
			[
				82755,
				32167
			],
			[
				68,
				594
			],
			[
				-49,
				56
			],
			[
				71,
				786
			]
		],
		[
			[
				82845,
				33605
			],
			[
				-908,
				273
			]
		],
		[
			[
				81937,
				33878
			],
			[
				-164,
				51
			]
		],
		[
			[
				81773,
				33929
			],
			[
				-86,
				-858
			]
		],
		[
			[
				79103,
				54302
			],
			[
				101,
				-147
			],
			[
				111,
				-73
			],
			[
				-41,
				181
			]
		],
		[
			[
				79274,
				54263
			],
			[
				-171,
				39
			]
		],
		[
			[
				83687,
				48545
			],
			[
				70,
				-82
			],
			[
				-47,
				268
			],
			[
				-23,
				-186
			]
		],
		[
			[
				61311,
				53207
			],
			[
				386,
				-19
			]
		],
		[
			[
				61697,
				53188
			],
			[
				386,
				-14
			]
		],
		[
			[
				62083,
				53174
			],
			[
				96,
				-7
			],
			[
				17,
				674
			],
			[
				190,
				-10
			]
		],
		[
			[
				62409,
				55274
			],
			[
				-765,
				28
			]
		],
		[
			[
				61644,
				55302
			],
			[
				-287,
				7
			]
		],
		[
			[
				61357,
				55309
			],
			[
				-39,
				-1594
			]
		],
		[
			[
				83106,
				54544
			],
			[
				892,
				-200
			]
		],
		[
			[
				83998,
				54344
			],
			[
				115,
				1304
			]
		],
		[
			[
				84113,
				55648
			],
			[
				-900,
				182
			]
		],
		[
			[
				83213,
				55830
			],
			[
				-28,
				-344
			]
		],
		[
			[
				86962,
				42978
			],
			[
				46,
				54
			]
		],
		[
			[
				87008,
				43032
			],
			[
				-46,
				-54
			]
		],
		[
			[
				80700,
				57899
			],
			[
				1030,
				-194
			]
		],
		[
			[
				81730,
				57705
			],
			[
				43,
				220
			]
		],
		[
			[
				81773,
				57925
			],
			[
				17,
				338
			]
		],
		[
			[
				81790,
				58263
			],
			[
				-900,
				168
			]
		],
		[
			[
				80890,
				58431
			],
			[
				-195,
				-514
			]
		],
		[
			[
				80695,
				57917
			],
			[
				5,
				-18
			]
		],
		[
			[
				84196,
				45674
			],
			[
				59,
				-185
			],
			[
				99,
				202
			],
			[
				-137,
				162
			],
			[
				-21,
				-179
			]
		],
		[
			[
				83175,
				52853
			],
			[
				30,
				-116
			],
			[
				79,
				6
			],
			[
				33,
				217
			],
			[
				-142,
				-107
			]
		],
		[
			[
				56895,
				38361
			],
			[
				762,
				-19
			]
		],
		[
			[
				57657,
				38342
			],
			[
				14,
				1335
			]
		],
		[
			[
				57289,
				39687
			],
			[
				-383,
				7
			]
		],
		[
			[
				56906,
				39694
			],
			[
				-11,
				-1333
			]
		],
		[
			[
				9578,
				45787
			],
			[
				147,
				-165
			],
			[
				146,
				50
			],
			[
				17,
				89
			]
		],
		[
			[
				9888,
				45761
			],
			[
				2,
				149
			],
			[
				136,
				406
			],
			[
				63,
				60
			],
			[
				21,
				181
			],
			[
				-46,
				115
			],
			[
				179,
				241
			],
			[
				31,
				171
			],
			[
				-49,
				183
			],
			[
				38,
				141
			],
			[
				-40,
				79
			],
			[
				117,
				37
			],
			[
				64,
				302
			],
			[
				168,
				507
			],
			[
				-3,
				280
			],
			[
				91,
				-88
			],
			[
				42,
				90
			],
			[
				85,
				-102
			],
			[
				82,
				112
			],
			[
				127,
				413
			],
			[
				95,
				36
			],
			[
				-13,
				-426
			],
			[
				97,
				215
			]
		],
		[
			[
				11175,
				48863
			],
			[
				51,
				280
			],
			[
				-95,
				126
			],
			[
				3,
				211
			],
			[
				79,
				62
			],
			[
				-2,
				117
			],
			[
				161,
				319
			],
			[
				55,
				190
			],
			[
				124,
				75
			],
			[
				46,
				247
			]
		],
		[
			[
				9743,
				50089
			],
			[
				-147,
				-430
			],
			[
				-2,
				-460
			],
			[
				-102,
				-133
			],
			[
				-48,
				-394
			],
			[
				-102,
				-372
			],
			[
				-143,
				-224
			],
			[
				-88,
				-377
			],
			[
				26,
				-244
			],
			[
				-5,
				-418
			],
			[
				61,
				-269
			],
			[
				-51,
				-116
			],
			[
				111,
				-154
			],
			[
				40,
				150
			],
			[
				143,
				-153
			],
			[
				152,
				-516
			],
			[
				-10,
				-192
			]
		],
		[
			[
				83987,
				46730
			],
			[
				123,
				-81
			],
			[
				17,
				234
			],
			[
				-33,
				63
			],
			[
				-122,
				-88
			],
			[
				15,
				-128
			]
		],
		[
			[
				62881,
				47724
			],
			[
				130,
				-9
			],
			[
				6,
				232
			],
			[
				136,
				-11
			],
			[
				5,
				228
			],
			[
				263,
				-16
			]
		],
		[
			[
				63421,
				48148
			],
			[
				28,
				1247
			]
		],
		[
			[
				62733,
				48870
			],
			[
				-86,
				-33
			]
		],
		[
			[
				62647,
				48837
			],
			[
				-10,
				-523
			],
			[
				260,
				-16
			],
			[
				-16,
				-574
			]
		],
		[
			[
				55733,
				32971
			],
			[
				771,
				-11
			]
		],
		[
			[
				56504,
				32960
			],
			[
				8,
				1344
			]
		],
		[
			[
				56512,
				34304
			],
			[
				-774,
				6
			]
		],
		[
			[
				55738,
				34310
			],
			[
				-5,
				-1339
			]
		],
		[
			[
				53826,
				51565
			],
			[
				287,
				5
			],
			[
				2,
				-336
			]
		],
		[
			[
				54808,
				51243
			],
			[
				0,
				505
			]
		],
		[
			[
				54808,
				51748
			],
			[
				-3,
				1180
			]
		],
		[
			[
				54805,
				52928
			],
			[
				0,
				504
			]
		],
		[
			[
				54805,
				53432
			],
			[
				-989,
				-17
			]
		],
		[
			[
				53816,
				53415
			],
			[
				10,
				-1850
			]
		],
		[
			[
				46520,
				62436
			],
			[
				975,
				77
			]
		],
		[
			[
				47495,
				62513
			],
			[
				-15,
				586
			]
		],
		[
			[
				47480,
				63099
			],
			[
				-27,
				1094
			]
		],
		[
			[
				47453,
				64193
			],
			[
				-756,
				-62
			]
		],
		[
			[
				46697,
				64131
			],
			[
				-227,
				-19
			]
		],
		[
			[
				46470,
				64112
			],
			[
				50,
				-1676
			]
		],
		[
			[
				48901,
				72882
			],
			[
				34,
				2
			]
		],
		[
			[
				48935,
				72884
			],
			[
				974,
				48
			]
		],
		[
			[
				50098,
				72949
			],
			[
				-11,
				822
			],
			[
				-146,
				138
			]
		],
		[
			[
				49227,
				74579
			],
			[
				-366,
				-26
			]
		],
		[
			[
				48861,
				74553
			],
			[
				40,
				-1671
			]
		],
		[
			[
				37939,
				73673
			],
			[
				133,
				21
			]
		],
		[
			[
				39618,
				73931
			],
			[
				-317,
				3401
			]
		],
		[
			[
				39301,
				77332
			],
			[
				-1641,
				1447
			]
		],
		[
			[
				37660,
				78779
			],
			[
				38,
				-657
			],
			[
				17,
				3
			],
			[
				185,
				-3736
			],
			[
				39,
				-716
			]
		],
		[
			[
				72809,
				30356
			],
			[
				756,
				-152
			]
		],
		[
			[
				73565,
				30204
			],
			[
				101,
				1304
			]
		],
		[
			[
				73666,
				31508
			],
			[
				1,
				22
			],
			[
				-387,
				81
			]
		],
		[
			[
				73280,
				31611
			],
			[
				-380,
				75
			]
		],
		[
			[
				72900,
				31686
			],
			[
				-91,
				-1330
			]
		],
		[
			[
				56991,
				35643
			],
			[
				380,
				-9
			]
		],
		[
			[
				57371,
				35634
			],
			[
				383,
				-15
			]
		],
		[
			[
				57770,
				36953
			],
			[
				-192,
				8
			]
		],
		[
			[
				57578,
				36961
			],
			[
				-575,
				16
			]
		],
		[
			[
				57578,
				36961
			],
			[
				11,
				1007
			],
			[
				64,
				-1
			],
			[
				4,
				375
			]
		],
		[
			[
				56895,
				38361
			],
			[
				-3,
				-377
			],
			[
				-72,
				1
			],
			[
				-9,
				-1002
			]
		],
		[
			[
				72516,
				31762
			],
			[
				384,
				-76
			]
		],
		[
			[
				73280,
				31611
			],
			[
				98,
				1328
			]
		],
		[
			[
				73378,
				32939
			],
			[
				-193,
				41
			]
		],
		[
			[
				73185,
				32980
			],
			[
				-574,
				116
			]
		],
		[
			[
				72611,
				33096
			],
			[
				-95,
				-1334
			]
		],
		[
			[
				51776,
				51187
			],
			[
				574,
				16
			]
		],
		[
			[
				52350,
				51203
			],
			[
				381,
				5
			],
			[
				-3,
				336
			]
		],
		[
			[
				52728,
				51544
			],
			[
				-4,
				675
			]
		],
		[
			[
				52724,
				52219
			],
			[
				-959,
				-19
			]
		],
		[
			[
				51765,
				52200
			],
			[
				11,
				-1013
			]
		],
		[
			[
				52175,
				69492
			],
			[
				813,
				71
			]
		],
		[
			[
				52988,
				69563
			],
			[
				203,
				45
			]
		],
		[
			[
				53191,
				69608
			],
			[
				-26,
				1608
			]
		],
		[
			[
				52814,
				71203
			],
			[
				-682,
				-21
			]
		],
		[
			[
				52132,
				71182
			],
			[
				43,
				-1690
			]
		],
		[
			[
				58775,
				30621
			],
			[
				723,
				-36
			]
		],
		[
			[
				59498,
				30585
			],
			[
				40,
				-3
			]
		],
		[
			[
				59555,
				31519
			],
			[
				-766,
				38
			]
		],
		[
			[
				58789,
				31557
			],
			[
				-14,
				-936
			]
		],
		[
			[
				72516,
				31762
			],
			[
				-381,
				78
			]
		],
		[
			[
				72135,
				31840
			],
			[
				-93,
				-1332
			]
		],
		[
			[
				71843,
				33244
			],
			[
				398,
				-70
			]
		],
		[
			[
				72241,
				33174
			],
			[
				97,
				1330
			]
		],
		[
			[
				72338,
				34504
			],
			[
				-768,
				154
			]
		],
		[
			[
				71570,
				34658
			],
			[
				-88,
				-1343
			]
		],
		[
			[
				70620,
				26725
			],
			[
				351,
				-53
			]
		],
		[
			[
				71379,
				26594
			],
			[
				89,
				1331
			]
		],
		[
			[
				71468,
				27925
			],
			[
				-759,
				140
			]
		],
		[
			[
				70709,
				28065
			],
			[
				-89,
				-1340
			]
		],
		[
			[
				85444,
				54567
			],
			[
				114,
				-49
			],
			[
				263,
				124
			]
		],
		[
			[
				85821,
				54642
			],
			[
				230,
				111
			],
			[
				73,
				126
			],
			[
				224,
				181
			],
			[
				71,
				191
			]
		],
		[
			[
				86419,
				55251
			],
			[
				-315,
				541
			],
			[
				-364,
				848
			]
		],
		[
			[
				85740,
				56640
			],
			[
				-389,
				-130
			]
		],
		[
			[
				85351,
				56510
			],
			[
				-155,
				-58
			],
			[
				57,
				-930
			],
			[
				-4,
				-97
			]
		],
		[
			[
				55905,
				23372
			],
			[
				194,
				-2
			]
		],
		[
			[
				56099,
				23370
			],
			[
				578,
				-13
			],
			[
				2,
				332
			]
		],
		[
			[
				56679,
				23689
			],
			[
				21,
				1660
			]
		],
		[
			[
				56700,
				25349
			],
			[
				-775,
				17
			]
		],
		[
			[
				55925,
				25366
			],
			[
				-2,
				-999
			]
		],
		[
			[
				56022,
				18059
			],
			[
				578,
				-8
			]
		],
		[
			[
				56600,
				18051
			],
			[
				24,
				1561
			],
			[
				74,
				95
			]
		],
		[
			[
				56698,
				19707
			],
			[
				-656,
				12
			]
		],
		[
			[
				56042,
				19719
			],
			[
				-5,
				-995
			],
			[
				-14,
				-334
			]
		],
		[
			[
				84594,
				58857
			],
			[
				175,
				-518
			],
			[
				169,
				-242
			],
			[
				204,
				110
			],
			[
				46,
				-44
			]
		],
		[
			[
				85188,
				58163
			],
			[
				-9,
				190
			],
			[
				75,
				308
			],
			[
				134,
				305
			]
		],
		[
			[
				85388,
				58966
			],
			[
				-232,
				542
			],
			[
				-285,
				66
			]
		],
		[
			[
				71596,
				29915
			],
			[
				-366,
				73
			]
		],
		[
			[
				71230,
				29988
			],
			[
				-400,
				71
			],
			[
				-42,
				-664
			]
		],
		[
			[
				70105,
				55947
			],
			[
				46,
				-1677
			]
		],
		[
			[
				70151,
				54270
			],
			[
				23,
				120
			],
			[
				232,
				-53
			],
			[
				110,
				-81
			]
		],
		[
			[
				70516,
				54256
			],
			[
				76,
				1607
			]
		],
		[
			[
				70592,
				55863
			],
			[
				-96,
				19
			]
		],
		[
			[
				70496,
				55882
			],
			[
				-391,
				65
			]
		],
		[
			[
				66492,
				46476
			],
			[
				573,
				-66
			]
		],
		[
			[
				67065,
				46410
			],
			[
				49,
				1173
			],
			[
				194,
				-37
			]
		],
		[
			[
				67321,
				47886
			],
			[
				-761,
				105
			],
			[
				13,
				339
			]
		],
		[
			[
				66573,
				48330
			],
			[
				-200,
				2
			]
		],
		[
			[
				66373,
				48332
			],
			[
				-47,
				-989
			],
			[
				8,
				-113
			]
		],
		[
			[
				67406,
				40944
			],
			[
				376,
				-55
			],
			[
				-90,
				-1443
			]
		],
		[
			[
				67692,
				39446
			],
			[
				192,
				-40
			]
		],
		[
			[
				68310,
				41325
			],
			[
				20,
				332
			]
		],
		[
			[
				68330,
				41657
			],
			[
				-886,
				127
			]
		],
		[
			[
				67444,
				41784
			],
			[
				-38,
				-840
			]
		],
		[
			[
				57335,
				27738
			],
			[
				253,
				234
			],
			[
				161,
				72
			],
			[
				66,
				85
			],
			[
				112,
				-45
			],
			[
				-17,
				-268
			]
		],
		[
			[
				57910,
				27816
			],
			[
				389,
				-15
			],
			[
				2,
				166
			]
		],
		[
			[
				58320,
				29302
			],
			[
				-769,
				27
			]
		],
		[
			[
				57551,
				29329
			],
			[
				-196,
				6
			]
		],
		[
			[
				57345,
				28335
			],
			[
				-10,
				-597
			]
		],
		[
			[
				52304,
				77760
			],
			[
				913,
				-913
			]
		],
		[
			[
				53217,
				76847
			],
			[
				383,
				1161
			]
		],
		[
			[
				53600,
				78008
			],
			[
				-445,
				435
			]
		],
		[
			[
				53155,
				78443
			],
			[
				-1,
				-18
			],
			[
				-461,
				469
			]
		],
		[
			[
				52693,
				78894
			],
			[
				-389,
				-1134
			]
		],
		[
			[
				50997,
				57260
			],
			[
				1140,
				37
			]
		],
		[
			[
				52137,
				57297
			],
			[
				2,
				0
			]
		],
		[
			[
				52139,
				57297
			],
			[
				-13,
				1655
			]
		],
		[
			[
				52126,
				58952
			],
			[
				-384,
				-11
			]
		],
		[
			[
				51742,
				58941
			],
			[
				-766,
				-27
			]
		],
		[
			[
				50976,
				58914
			],
			[
				14,
				-1150
			]
		],
		[
			[
				50444,
				26255
			],
			[
				1154,
				58
			]
		],
		[
			[
				51598,
				26313
			],
			[
				193,
				8
			],
			[
				1,
				333
			]
		],
		[
			[
				51792,
				26654
			],
			[
				-16,
				1338
			]
		],
		[
			[
				51776,
				27992
			],
			[
				-764,
				-34
			]
		],
		[
			[
				51012,
				27958
			],
			[
				-588,
				-27
			]
		],
		[
			[
				50424,
				27931
			],
			[
				20,
				-1676
			]
		],
		[
			[
				52384,
				24346
			],
			[
				-8,
				1331
			]
		],
		[
			[
				52376,
				25677
			],
			[
				-10,
				996
			]
		],
		[
			[
				52366,
				26673
			],
			[
				-574,
				-19
			]
		],
		[
			[
				51598,
				26313
			],
			[
				18,
				-1997
			]
		],
		[
			[
				68714,
				55746
			],
			[
				85,
				255
			]
		],
		[
			[
				68799,
				56001
			],
			[
				93,
				519
			],
			[
				-29,
				173
			]
		],
		[
			[
				68863,
				56693
			],
			[
				-774,
				92
			]
		],
		[
			[
				68089,
				56785
			],
			[
				-38,
				-959
			]
		],
		[
			[
				54455,
				82422
			],
			[
				118,
				250
			],
			[
				-91,
				258
			],
			[
				69,
				233
			],
			[
				-49,
				153
			],
			[
				36,
				103
			],
			[
				103,
				25
			],
			[
				32,
				288
			]
		],
		[
			[
				54673,
				83732
			],
			[
				41,
				31
			],
			[
				-94,
				168
			],
			[
				86,
				47
			],
			[
				-18,
				232
			],
			[
				-124,
				5
			]
		],
		[
			[
				54564,
				84215
			],
			[
				-168,
				-124
			]
		],
		[
			[
				54396,
				84091
			],
			[
				-160,
				-131
			],
			[
				-23,
				-186
			],
			[
				-79,
				-149
			],
			[
				-62,
				-284
			],
			[
				-136,
				17
			],
			[
				-236,
				-317
			],
			[
				-66,
				-208
			]
		],
		[
			[
				53634,
				82833
			],
			[
				-99,
				-318
			]
		],
		[
			[
				53535,
				82515
			],
			[
				70,
				-77
			],
			[
				129,
				-1
			],
			[
				447,
				-117
			],
			[
				246,
				167
			],
			[
				28,
				-65
			]
		],
		[
			[
				22285,
				90354
			],
			[
				0,
				0
			]
		],
		[
			[
				22954,
				92313
			],
			[
				-52,
				37
			],
			[
				-314,
				-176
			],
			[
				-14,
				237
			],
			[
				-136,
				70
			]
		],
		[
			[
				22438,
				92481
			],
			[
				-101,
				-86
			],
			[
				-24,
				-198
			],
			[
				61,
				-128
			],
			[
				-39,
				-153
			],
			[
				85,
				100
			],
			[
				98,
				-88
			],
			[
				58,
				-146
			],
			[
				164,
				133
			],
			[
				210,
				16
			],
			[
				45,
				154
			],
			[
				-74,
				88
			],
			[
				86,
				54
			],
			[
				-53,
				86
			]
		],
		[
			[
				21623,
				91496
			],
			[
				144,
				-206
			]
		],
		[
			[
				21767,
				91290
			],
			[
				221,
				-447
			],
			[
				94,
				-51
			],
			[
				17,
				-142
			]
		],
		[
			[
				22099,
				90650
			],
			[
				235,
				9
			],
			[
				98,
				61
			],
			[
				117,
				234
			],
			[
				-61,
				224
			],
			[
				157,
				94
			],
			[
				63,
				133
			],
			[
				-58,
				32
			],
			[
				34,
				134
			]
		],
		[
			[
				22684,
				91571
			],
			[
				-78,
				-1
			],
			[
				-99,
				114
			],
			[
				-52,
				-307
			],
			[
				-111,
				-222
			],
			[
				-48,
				110
			],
			[
				-114,
				-66
			],
			[
				-46,
				-158
			],
			[
				-33,
				119
			],
			[
				-95,
				-53
			],
			[
				-7,
				89
			],
			[
				133,
				14
			],
			[
				132,
				153
			],
			[
				58,
				1
			],
			[
				130,
				346
			],
			[
				-120,
				172
			],
			[
				-65,
				3
			],
			[
				-42,
				154
			],
			[
				-132,
				-181
			],
			[
				-66,
				30
			],
			[
				-218,
				-176
			],
			[
				-188,
				-216
			]
		],
		[
			[
				23534,
				91889
			],
			[
				98,
				-462
			]
		],
		[
			[
				23632,
				91427
			],
			[
				75,
				170
			],
			[
				264,
				401
			]
		],
		[
			[
				23574,
				91990
			],
			[
				-40,
				-101
			]
		],
		[
			[
				23512,
				91993
			],
			[
				14,
				-69
			]
		],
		[
			[
				23526,
				91924
			],
			[
				-14,
				69
			]
		],
		[
			[
				23012,
				91658
			],
			[
				50,
				241
			],
			[
				175,
				-272
			]
		],
		[
			[
				23237,
				91627
			],
			[
				16,
				107
			],
			[
				173,
				287
			],
			[
				60,
				227
			],
			[
				-112,
				-215
			],
			[
				-88,
				-62
			],
			[
				55,
				162
			],
			[
				118,
				142
			],
			[
				86,
				314
			],
			[
				-178,
				553
			],
			[
				-43,
				18
			],
			[
				-62,
				-238
			],
			[
				37,
				-251
			],
			[
				-86,
				-125
			],
			[
				-96,
				-243
			],
			[
				-14,
				-143
			],
			[
				-169,
				-454
			],
			[
				-60,
				-244
			],
			[
				138,
				196
			]
		],
		[
			[
				81056,
				60720
			],
			[
				1043,
				-277
			]
		],
		[
			[
				82099,
				60443
			],
			[
				51,
				114
			],
			[
				62,
				343
			],
			[
				-42,
				261
			],
			[
				65,
				328
			]
		],
		[
			[
				82235,
				61489
			],
			[
				-993,
				155
			]
		],
		[
			[
				81242,
				61644
			],
			[
				-9,
				-238
			],
			[
				-83,
				-140
			],
			[
				-28,
				-327
			],
			[
				-66,
				-219
			]
		],
		[
			[
				52376,
				25677
			],
			[
				961,
				19
			]
		],
		[
			[
				53330,
				26694
			],
			[
				-386,
				-4
			]
		],
		[
			[
				52944,
				26690
			],
			[
				-578,
				-17
			]
		],
		[
			[
				68766,
				59340
			],
			[
				18,
				802
			],
			[
				-79,
				321
			],
			[
				22,
				406
			]
		],
		[
			[
				68727,
				60869
			],
			[
				-214,
				46
			]
		],
		[
			[
				68513,
				60915
			],
			[
				-19,
				-283
			],
			[
				-89,
				53
			],
			[
				-169,
				-92
			],
			[
				-121,
				-274
			],
			[
				-87,
				7
			]
		],
		[
			[
				68028,
				60326
			],
			[
				-23,
				-775
			]
		],
		[
			[
				50064,
				27913
			],
			[
				360,
				18
			]
		],
		[
			[
				51012,
				27958
			],
			[
				-13,
				993
			]
		],
		[
			[
				50999,
				28951
			],
			[
				-763,
				-26
			]
		],
		[
			[
				50236,
				28925
			],
			[
				-192,
				-10
			]
		],
		[
			[
				50044,
				28915
			],
			[
				20,
				-1002
			]
		],
		[
			[
				23509,
				46242
			],
			[
				989,
				320
			],
			[
				1701,
				530
			]
		],
		[
			[
				26199,
				47092
			],
			[
				-67,
				645
			],
			[
				-15,
				337
			],
			[
				-207,
				-62
			],
			[
				-17,
				332
			],
			[
				-190,
				-62
			],
			[
				-94,
				999
			]
		],
		[
			[
				25609,
				49281
			],
			[
				-376,
				-114
			],
			[
				-17,
				165
			]
		],
		[
			[
				25216,
				49332
			],
			[
				-235,
				-72
			],
			[
				-10,
				96
			],
			[
				-371,
				-145
			],
			[
				19,
				-178
			],
			[
				-380,
				-121
			],
			[
				36,
				-339
			],
			[
				-196,
				-62
			],
			[
				-5,
				52
			],
			[
				-801,
				-260
			]
		],
		[
			[
				23273,
				48303
			],
			[
				94,
				-775
			],
			[
				142,
				-1286
			]
		],
		[
			[
				58979,
				52474
			],
			[
				2,
				-336
			],
			[
				99,
				1
			],
			[
				5,
				-616
			]
		],
		[
			[
				59085,
				51523
			],
			[
				765,
				4
			]
		],
		[
			[
				59850,
				51527
			],
			[
				-2,
				617
			]
		],
		[
			[
				59654,
				52540
			],
			[
				-676,
				-9
			],
			[
				1,
				-57
			]
		],
		[
			[
				54020,
				26367
			],
			[
				569,
				6
			]
		],
		[
			[
				54589,
				26373
			],
			[
				20,
				335
			],
			[
				2,
				1328
			]
		],
		[
			[
				54611,
				28036
			],
			[
				-597,
				-3
			]
		],
		[
			[
				54014,
				28033
			],
			[
				5,
				-1330
			]
		],
		[
			[
				57551,
				29329
			],
			[
				13,
				1335
			]
		],
		[
			[
				57249,
				30672
			],
			[
				-664,
				16
			]
		],
		[
			[
				56585,
				30688
			],
			[
				-9,
				-1334
			]
		],
		[
			[
				50968,
				34718
			],
			[
				763,
				25
			]
		],
		[
			[
				51731,
				34743
			],
			[
				-16,
				1339
			]
		],
		[
			[
				51710,
				36749
			],
			[
				-763,
				-23
			]
		],
		[
			[
				50947,
				36726
			],
			[
				1,
				-669
			]
		],
		[
			[
				50948,
				36057
			],
			[
				20,
				-1339
			]
		],
		[
			[
				61053,
				46491
			],
			[
				759,
				-14
			],
			[
				254,
				-25
			]
		],
		[
			[
				62066,
				46452
			],
			[
				1,
				57
			],
			[
				480,
				-16
			]
		],
		[
			[
				62547,
				46493
			],
			[
				69,
				675
			]
		],
		[
			[
				62616,
				47168
			],
			[
				-379,
				10
			],
			[
				2,
				343
			]
		],
		[
			[
				62239,
				47521
			],
			[
				-819,
				41
			]
		],
		[
			[
				61420,
				47562
			],
			[
				-6,
				-678
			],
			[
				-360,
				-1
			]
		],
		[
			[
				61054,
				46883
			],
			[
				-1,
				-392
			]
		],
		[
			[
				58162,
				56676
			],
			[
				2,
				-710
			]
		],
		[
			[
				58964,
				55970
			],
			[
				2,
				394
			],
			[
				70,
				-1
			],
			[
				-1,
				1240
			]
		],
		[
			[
				59035,
				57603
			],
			[
				-502,
				21
			]
		],
		[
			[
				58533,
				57624
			],
			[
				-374,
				14
			]
		],
		[
			[
				58159,
				57638
			],
			[
				3,
				-962
			]
		],
		[
			[
				85418,
				49597
			],
			[
				250,
				-1242
			],
			[
				-7,
				-213
			]
		],
		[
			[
				85775,
				47877
			],
			[
				131,
				113
			],
			[
				-17,
				122
			],
			[
				74,
				62
			]
		],
		[
			[
				85963,
				48174
			],
			[
				-14,
				119
			],
			[
				-3,
				683
			]
		],
		[
			[
				85946,
				48976
			],
			[
				-63,
				68
			],
			[
				-79,
				318
			]
		],
		[
			[
				85804,
				49362
			],
			[
				-125,
				271
			],
			[
				-125,
				56
			],
			[
				-136,
				-92
			]
		],
		[
			[
				61706,
				22240
			],
			[
				1345,
				-121
			]
		],
		[
			[
				63083,
				23113
			],
			[
				-386,
				30
			],
			[
				10,
				334
			]
		],
		[
			[
				62707,
				23477
			],
			[
				-966,
				83
			]
		],
		[
			[
				61741,
				23560
			],
			[
				-35,
				-1320
			]
		],
		[
			[
				60782,
				23947
			],
			[
				772,
				-50
			]
		],
		[
			[
				61554,
				23897
			],
			[
				31,
				331
			],
			[
				29,
				1013
			]
		],
		[
			[
				61632,
				25900
			],
			[
				-769,
				54
			]
		],
		[
			[
				60863,
				25954
			],
			[
				-17,
				-664
			]
		],
		[
			[
				60846,
				25290
			],
			[
				-24,
				-1008
			],
			[
				-33,
				2
			],
			[
				-7,
				-337
			]
		],
		[
			[
				36169,
				28775
			],
			[
				680,
				143
			]
		],
		[
			[
				36849,
				28918
			],
			[
				-3,
				30
			],
			[
				1418,
				270
			],
			[
				239,
				39
			]
		],
		[
			[
				38503,
				29257
			],
			[
				2,
				85
			],
			[
				-85,
				1320
			],
			[
				-73,
				1249
			],
			[
				-12,
				84
			],
			[
				-80,
				1319
			]
		],
		[
			[
				38255,
				33314
			],
			[
				-1434,
				-273
			],
			[
				-922,
				-191
			]
		],
		[
			[
				35899,
				32850
			],
			[
				-31,
				-6
			],
			[
				92,
				-1326
			],
			[
				68,
				14
			],
			[
				92,
				-1323
			],
			[
				-23,
				-4
			],
			[
				93,
				-1315
			],
			[
				-21,
				-115
			]
		],
		[
			[
				31091,
				21746
			],
			[
				406,
				103
			],
			[
				121,
				74
			],
			[
				790,
				192
			],
			[
				142,
				7
			],
			[
				486,
				129
			]
		],
		[
			[
				33036,
				22251
			],
			[
				1087,
				251
			],
			[
				30,
				31
			],
			[
				723,
				165
			]
		],
		[
			[
				34876,
				22698
			],
			[
				-37,
				490
			],
			[
				44,
				10
			],
			[
				-104,
				1330
			],
			[
				30,
				8
			],
			[
				-105,
				1338
			],
			[
				37,
				9
			]
		],
		[
			[
				34741,
				25883
			],
			[
				-27,
				338
			]
		],
		[
			[
				34714,
				26221
			],
			[
				-383,
				-88
			],
			[
				-26,
				337
			],
			[
				-197,
				-46
			],
			[
				-21,
				329
			],
			[
				-286,
				-66
			],
			[
				-28,
				329
			],
			[
				-350,
				-67
			]
		],
		[
			[
				33423,
				26949
			],
			[
				-70,
				-130
			],
			[
				-9,
				-142
			],
			[
				-134,
				-280
			],
			[
				-135,
				-80
			],
			[
				-166,
				160
			],
			[
				-36,
				195
			],
			[
				-215,
				126
			],
			[
				-88,
				-100
			],
			[
				63,
				-228
			],
			[
				-55,
				-170
			],
			[
				25,
				-102
			],
			[
				-220,
				-37
			],
			[
				-73,
				-242
			]
		],
		[
			[
				32310,
				25919
			],
			[
				41,
				-473
			],
			[
				-111,
				-70
			],
			[
				41,
				-248
			],
			[
				-27,
				-307
			],
			[
				-81,
				-100
			],
			[
				-20,
				-271
			],
			[
				-86,
				-235
			],
			[
				52,
				-246
			],
			[
				-4,
				-173
			],
			[
				-135,
				-114
			],
			[
				7,
				-76
			],
			[
				-452,
				-118
			],
			[
				29,
				-316
			],
			[
				-607,
				-164
			]
		],
		[
			[
				30957,
				23008
			],
			[
				118,
				-1267
			],
			[
				16,
				5
			]
		],
		[
			[
				60051,
				29213
			],
			[
				1,
				56
			],
			[
				386,
				-24
			]
		],
		[
			[
				60438,
				29245
			],
			[
				28,
				1280
			]
		],
		[
			[
				60466,
				30525
			],
			[
				-168,
				11
			]
		],
		[
			[
				59498,
				30585
			],
			[
				-25,
				-1339
			]
		],
		[
			[
				9888,
				45761
			],
			[
				110,
				31
			]
		],
		[
			[
				9998,
				45792
			],
			[
				35,
				25
			],
			[
				173,
				-263
			],
			[
				107,
				141
			],
			[
				347,
				158
			]
		],
		[
			[
				10660,
				45853
			],
			[
				-54,
				113
			],
			[
				55,
				76
			],
			[
				51,
				334
			],
			[
				325,
				535
			]
		],
		[
			[
				11037,
				46911
			],
			[
				394,
				1186
			],
			[
				-80,
				602
			],
			[
				-114,
				-3
			],
			[
				-62,
				167
			]
		],
		[
			[
				56512,
				34304
			],
			[
				772,
				-13
			]
		],
		[
			[
				57284,
				34291
			],
			[
				3,
				329
			],
			[
				74,
				-2
			],
			[
				10,
				1016
			]
		],
		[
			[
				56614,
				35651
			],
			[
				-5,
				-1017
			],
			[
				-94,
				1
			],
			[
				-3,
				-331
			]
		],
		[
			[
				53626,
				58998
			],
			[
				578,
				-4
			]
		],
		[
			[
				54204,
				58994
			],
			[
				0,
				333
			],
			[
				480,
				3
			],
			[
				-7,
				844
			]
		],
		[
			[
				54677,
				60174
			],
			[
				-286,
				-2
			],
			[
				-1,
				841
			]
		],
		[
			[
				53620,
				61007
			],
			[
				3,
				-1166
			]
		],
		[
			[
				53623,
				59841
			],
			[
				3,
				-843
			]
		],
		[
			[
				85102,
				42471
			],
			[
				12,
				-100
			],
			[
				94,
				48
			],
			[
				-55,
				229
			],
			[
				-51,
				-177
			]
		],
		[
			[
				55590,
				50393
			],
			[
				767,
				-9
			]
		],
		[
			[
				55575,
				51752
			],
			[
				2,
				-854
			],
			[
				13,
				-505
			]
		],
		[
			[
				48675,
				60162
			],
			[
				1335,
				71
			]
		],
		[
			[
				50010,
				61239
			],
			[
				-6,
				337
			]
		],
		[
			[
				50004,
				61576
			],
			[
				-1336,
				-76
			]
		],
		[
			[
				48668,
				61500
			],
			[
				4,
				-168
			]
		],
		[
			[
				48672,
				61332
			],
			[
				-20,
				-167
			],
			[
				23,
				-1003
			]
		],
		[
			[
				12583,
				36035
			],
			[
				115,
				-74
			],
			[
				74,
				-244
			],
			[
				120,
				-85
			],
			[
				98,
				308
			],
			[
				62,
				-9
			],
			[
				94,
				208
			],
			[
				90,
				-73
			],
			[
				807,
				358
			]
		],
		[
			[
				14043,
				36424
			],
			[
				218,
				92
			],
			[
				31,
				-41
			]
		],
		[
			[
				14292,
				36475
			],
			[
				-159,
				1035
			]
		],
		[
			[
				14133,
				37510
			],
			[
				-814,
				-367
			],
			[
				-53,
				-94
			],
			[
				-35,
				-250
			],
			[
				-164,
				-65
			],
			[
				-176,
				190
			],
			[
				-281,
				0
			],
			[
				-107,
				-39
			],
			[
				-86,
				64
			]
		],
		[
			[
				12417,
				36949
			],
			[
				52,
				-467
			],
			[
				114,
				-447
			]
		],
		[
			[
				55278,
				35662
			],
			[
				571,
				-4
			]
		],
		[
			[
				55849,
				35658
			],
			[
				383,
				-3
			]
		],
		[
			[
				56236,
				36991
			],
			[
				-768,
				9
			]
		],
		[
			[
				55468,
				37000
			],
			[
				-191,
				2
			]
		],
		[
			[
				55277,
				37002
			],
			[
				1,
				-1340
			]
		],
		[
			[
				11533,
				38119
			],
			[
				214,
				-126
			],
			[
				33,
				88
			]
		],
		[
			[
				11780,
				38081
			],
			[
				146,
				148
			],
			[
				85,
				-47
			],
			[
				50,
				121
			],
			[
				99,
				39
			],
			[
				122,
				-200
			],
			[
				25,
				-124
			],
			[
				286,
				-269
			],
			[
				298,
				-215
			],
			[
				114,
				-11
			],
			[
				1052,
				466
			]
		],
		[
			[
				14057,
				37989
			],
			[
				-84,
				564
			]
		],
		[
			[
				13973,
				38553
			],
			[
				-28,
				199
			]
		],
		[
			[
				13945,
				38752
			],
			[
				-24,
				169
			]
		],
		[
			[
				11820,
				39390
			],
			[
				-559,
				-351
			]
		],
		[
			[
				11261,
				39039
			],
			[
				35,
				-53
			],
			[
				104,
				-648
			],
			[
				92,
				42
			],
			[
				41,
				-261
			]
		],
		[
			[
				61136,
				40496
			],
			[
				26,
				1152
			]
		],
		[
			[
				61162,
				41648
			],
			[
				-287,
				32
			]
		],
		[
			[
				60875,
				41680
			],
			[
				-483,
				55
			]
		],
		[
			[
				60392,
				41735
			],
			[
				-26,
				-1186
			]
		],
		[
			[
				67663,
				38987
			],
			[
				29,
				459
			]
		],
		[
			[
				67406,
				40944
			],
			[
				-193,
				29
			],
			[
				-41,
				-540
			],
			[
				-577,
				92
			]
		],
		[
			[
				66595,
				40525
			],
			[
				-32,
				-670
			]
		],
		[
			[
				74056,
				68956
			],
			[
				666,
				-130
			]
		],
		[
			[
				74722,
				68826
			],
			[
				147,
				892
			]
		],
		[
			[
				74869,
				69718
			],
			[
				67,
				93
			],
			[
				-19,
				125
			],
			[
				95,
				129
			],
			[
				-9,
				85
			]
		],
		[
			[
				75003,
				70150
			],
			[
				-286,
				82
			],
			[
				4,
				55
			],
			[
				-572,
				117
			]
		],
		[
			[
				74149,
				70404
			],
			[
				-93,
				-1448
			]
		],
		[
			[
				73330,
				75531
			],
			[
				-22,
				-343
			],
			[
				94,
				-46
			],
			[
				-22,
				-305
			]
		],
		[
			[
				73380,
				74837
			],
			[
				672,
				-123
			]
		],
		[
			[
				74052,
				74714
			],
			[
				97,
				1609
			]
		],
		[
			[
				74149,
				76323
			],
			[
				-761,
				154
			]
		],
		[
			[
				73388,
				76477
			],
			[
				-58,
				-946
			]
		],
		[
			[
				66051,
				41207
			],
			[
				227,
				-37
			],
			[
				50,
				-115
			],
			[
				95,
				-21
			],
			[
				-7,
				-141
			],
			[
				101,
				-16
			],
			[
				-18,
				-336
			],
			[
				96,
				-16
			]
		],
		[
			[
				67444,
				41784
			],
			[
				20,
				450
			]
		],
		[
			[
				67464,
				42234
			],
			[
				-192,
				27
			]
		],
		[
			[
				66304,
				42384
			],
			[
				-191,
				28
			],
			[
				-9,
				-171
			]
		],
		[
			[
				66104,
				42241
			],
			[
				-53,
				-1034
			]
		],
		[
			[
				41629,
				44279
			],
			[
				49,
				-1005
			]
		],
		[
			[
				41678,
				43274
			],
			[
				395,
				53
			],
			[
				64,
				-1338
			],
			[
				25,
				-332
			]
		],
		[
			[
				42162,
				41657
			],
			[
				1152,
				140
			]
		],
		[
			[
				43314,
				41797
			],
			[
				-37,
				332
			],
			[
				-55,
				1342
			],
			[
				-18,
				-2
			],
			[
				-69,
				1673
			]
		],
		[
			[
				42537,
				45075
			],
			[
				-941,
				-129
			]
		],
		[
			[
				41596,
				44946
			],
			[
				33,
				-667
			]
		],
		[
			[
				58055,
				34269
			],
			[
				772,
				-30
			]
		],
		[
			[
				58827,
				34239
			],
			[
				6,
				333
			],
			[
				59,
				-2
			],
			[
				15,
				1006
			]
		],
		[
			[
				58137,
				35605
			],
			[
				-13,
				-1007
			],
			[
				-65,
				2
			],
			[
				-4,
				-331
			]
		],
		[
			[
				45562,
				43729
			],
			[
				145,
				13
			]
		],
		[
			[
				45707,
				43742
			],
			[
				2,
				0
			]
		],
		[
			[
				45709,
				43742
			],
			[
				958,
				88
			]
		],
		[
			[
				46701,
				43834
			],
			[
				-53,
				1672
			]
		],
		[
			[
				46648,
				45506
			],
			[
				-1104,
				-102
			]
		],
		[
			[
				45544,
				45404
			],
			[
				-43,
				-4
			]
		],
		[
			[
				45501,
				45400
			],
			[
				61,
				-1671
			]
		],
		[
			[
				78034,
				40641
			],
			[
				793,
				-145
			]
		],
		[
			[
				78827,
				40496
			],
			[
				54,
				846
			],
			[
				63,
				-12
			],
			[
				25,
				337
			]
		],
		[
			[
				78969,
				41667
			],
			[
				24,
				334
			]
		],
		[
			[
				78993,
				42001
			],
			[
				-644,
				104
			]
		],
		[
			[
				78349,
				42105
			],
			[
				-8,
				-174
			],
			[
				-165,
				24
			],
			[
				-16,
				-342
			],
			[
				-122,
				15
			]
		],
		[
			[
				78038,
				41628
			],
			[
				47,
				-155
			],
			[
				-51,
				-832
			]
		],
		[
			[
				77648,
				41658
			],
			[
				390,
				-30
			]
		],
		[
			[
				78349,
				42105
			],
			[
				9,
				167
			],
			[
				95,
				-13
			],
			[
				32,
				673
			],
			[
				-14,
				1
			]
		],
		[
			[
				78281,
				42958
			],
			[
				-178,
				23
			],
			[
				-7,
				-109
			],
			[
				-201,
				26
			],
			[
				-10,
				-225
			]
		],
		[
			[
				77885,
				42673
			],
			[
				-14,
				-338
			],
			[
				-65,
				8
			],
			[
				-15,
				-341
			],
			[
				-129,
				16
			],
			[
				-14,
				-360
			]
		],
		[
			[
				50907,
				71096
			],
			[
				251,
				17
			]
		],
		[
			[
				51158,
				71113
			],
			[
				702,
				49
			]
		],
		[
			[
				51860,
				71162
			],
			[
				-28,
				1687
			]
		],
		[
			[
				51832,
				72849
			],
			[
				-124,
				-4
			]
		],
		[
			[
				51708,
				72845
			],
			[
				-842,
				-41
			]
		],
		[
			[
				61580,
				35069
			],
			[
				384,
				-19
			]
		],
		[
			[
				61964,
				35050
			],
			[
				384,
				-31
			]
		],
		[
			[
				62382,
				36361
			],
			[
				9,
				336
			]
		],
		[
			[
				62391,
				36697
			],
			[
				-768,
				54
			]
		],
		[
			[
				61623,
				36751
			],
			[
				-43,
				-1682
			]
		],
		[
			[
				36084,
				50742
			],
			[
				185,
				39
			],
			[
				-20,
				333
			],
			[
				953,
				175
			]
		],
		[
			[
				37202,
				51289
			],
			[
				-84,
				1334
			]
		],
		[
			[
				37118,
				52623
			],
			[
				-785,
				-140
			],
			[
				-331,
				-80
			]
		],
		[
			[
				36002,
				52403
			],
			[
				-57,
				-13
			]
		],
		[
			[
				35945,
				52390
			],
			[
				65,
				-998
			],
			[
				27,
				5
			],
			[
				47,
				-655
			]
		],
		[
			[
				22925,
				21086
			],
			[
				0,
				-342
			],
			[
				97,
				-423
			],
			[
				-47,
				-279
			],
			[
				60,
				-339
			],
			[
				102,
				-278
			],
			[
				80,
				-93
			],
			[
				-17,
				-131
			],
			[
				92,
				-185
			],
			[
				-61,
				-201
			]
		],
		[
			[
				23231,
				18815
			],
			[
				60,
				-178
			],
			[
				59,
				44
			],
			[
				195,
				-190
			],
			[
				-13,
				90
			],
			[
				539,
				193
			],
			[
				1437,
				498
			]
		],
		[
			[
				25155,
				20669
			],
			[
				-181,
				140
			],
			[
				-79,
				112
			],
			[
				-181,
				-260
			],
			[
				-121,
				129
			],
			[
				-62,
				291
			],
			[
				-60,
				70
			],
			[
				-131,
				3
			],
			[
				-53,
				107
			],
			[
				10,
				240
			],
			[
				63,
				51
			],
			[
				-21,
				311
			],
			[
				-144,
				305
			]
		],
		[
			[
				24195,
				22168
			],
			[
				-95,
				-58
			],
			[
				-1,
				124
			],
			[
				-107,
				176
			],
			[
				-197,
				25
			],
			[
				-909,
				-334
			],
			[
				-57,
				327
			],
			[
				-154,
				-71
			]
		],
		[
			[
				22675,
				22357
			],
			[
				82,
				-648
			],
			[
				93,
				34
			],
			[
				75,
				-657
			]
		],
		[
			[
				64772,
				39210
			],
			[
				193,
				-19
			],
			[
				5,
				-331
			]
		],
		[
			[
				64970,
				38860
			],
			[
				365,
				-39
			],
			[
				13,
				328
			]
		],
		[
			[
				65348,
				39149
			],
			[
				26,
				672
			]
		],
		[
			[
				65374,
				39821
			],
			[
				-579,
				62
			]
		],
		[
			[
				64795,
				39883
			],
			[
				-23,
				-673
			]
		],
		[
			[
				60080,
				36851
			],
			[
				772,
				-49
			]
		],
		[
			[
				60884,
				38160
			],
			[
				-190,
				13
			]
		],
		[
			[
				60694,
				38173
			],
			[
				-572,
				38
			]
		],
		[
			[
				60122,
				38211
			],
			[
				-24,
				-355
			],
			[
				-18,
				-1005
			]
		],
		[
			[
				46759,
				74403
			],
			[
				157,
				11
			]
		],
		[
			[
				46916,
				74414
			],
			[
				824,
				60
			]
		],
		[
			[
				47740,
				74474
			],
			[
				-59,
				1938
			]
		],
		[
			[
				47681,
				76412
			],
			[
				-737,
				-65
			]
		],
		[
			[
				46944,
				76347
			],
			[
				-232,
				-25
			],
			[
				7,
				-425
			]
		],
		[
			[
				46719,
				75897
			],
			[
				40,
				-1494
			]
		],
		[
			[
				59118,
				36900
			],
			[
				16,
				1009
			],
			[
				33,
				-3
			],
			[
				6,
				360
			]
		],
		[
			[
				59173,
				38266
			],
			[
				1,
				65
			]
		],
		[
			[
				59174,
				38331
			],
			[
				-149,
				-73
			],
			[
				-619,
				26
			]
		],
		[
			[
				58406,
				38284
			],
			[
				-5,
				-341
			],
			[
				-39,
				1
			],
			[
				-16,
				-1012
			]
		],
		[
			[
				52518,
				84091
			],
			[
				521,
				-8
			]
		],
		[
			[
				53039,
				84083
			],
			[
				79,
				215
			],
			[
				77,
				-1
			],
			[
				449,
				930
			],
			[
				-192,
				271
			]
		],
		[
			[
				53452,
				85498
			],
			[
				-551,
				759
			]
		],
		[
			[
				52901,
				86257
			],
			[
				-73,
				-157
			]
		],
		[
			[
				52828,
				86100
			],
			[
				-507,
				-1082
			]
		],
		[
			[
				52321,
				85018
			],
			[
				77,
				-469
			],
			[
				-1,
				-279
			],
			[
				121,
				-179
			]
		],
		[
			[
				51559,
				54220
			],
			[
				-8,
				1496
			]
		],
		[
			[
				51551,
				55716
			],
			[
				-548,
				-17
			]
		],
		[
			[
				67660,
				38650
			],
			[
				385,
				-70
			],
			[
				-20,
				-333
			],
			[
				807,
				-142
			]
		],
		[
			[
				68832,
				38105
			],
			[
				27,
				507
			]
		],
		[
			[
				68859,
				38612
			],
			[
				32,
				597
			]
		],
		[
			[
				59600,
				34204
			],
			[
				773,
				-41
			]
		],
		[
			[
				60373,
				34163
			],
			[
				7,
				332
			],
			[
				33,
				-2
			],
			[
				15,
				663
			]
		],
		[
			[
				60428,
				35156
			],
			[
				-385,
				25
			],
			[
				8,
				333
			]
		],
		[
			[
				60051,
				35514
			],
			[
				-385,
				25
			]
		],
		[
			[
				59666,
				35539
			],
			[
				-21,
				-1005
			],
			[
				-39,
				1
			],
			[
				-6,
				-331
			]
		],
		[
			[
				53774,
				71243
			],
			[
				409,
				6
			]
		],
		[
			[
				54183,
				71249
			],
			[
				-1,
				538
			]
		],
		[
			[
				54182,
				71787
			],
			[
				0,
				106
			],
			[
				-414,
				-1
			]
		],
		[
			[
				45795,
				78152
			],
			[
				1093,
				81
			]
		],
		[
			[
				46888,
				78233
			],
			[
				-38,
				1446
			]
		],
		[
			[
				46850,
				79679
			],
			[
				-1617,
				-116
			]
		],
		[
			[
				45233,
				79563
			],
			[
				41,
				-1441
			]
		],
		[
			[
				54340,
				22052
			],
			[
				774,
				0
			]
		],
		[
			[
				55114,
				22052
			],
			[
				1,
				666
			],
			[
				16,
				0
			],
			[
				2,
				662
			]
		],
		[
			[
				54553,
				23382
			],
			[
				-195,
				-3
			],
			[
				1,
				-661
			],
			[
				-18,
				0
			]
		],
		[
			[
				54341,
				22718
			],
			[
				-1,
				-666
			]
		],
		[
			[
				60041,
				27882
			],
			[
				204,
				-10
			]
		],
		[
			[
				60245,
				27872
			],
			[
				370,
				-21
			],
			[
				7,
				331
			],
			[
				383,
				-23
			]
		],
		[
			[
				61005,
				28159
			],
			[
				24,
				996
			]
		],
		[
			[
				61029,
				29155
			],
			[
				-592,
				35
			],
			[
				1,
				55
			]
		],
		[
			[
				57470,
				23829
			],
			[
				5,
				-86
			],
			[
				110,
				-48
			],
			[
				40,
				62
			],
			[
				123,
				-254
			],
			[
				51,
				-216
			]
		],
		[
			[
				57799,
				23287
			],
			[
				143,
				166
			],
			[
				48,
				-31
			],
			[
				95,
				174
			],
			[
				175,
				163
			],
			[
				280,
				-1
			],
			[
				93,
				170
			]
		],
		[
			[
				58633,
				23928
			],
			[
				-74,
				111
			],
			[
				-109,
				40
			],
			[
				4,
				167
			],
			[
				-71,
				62
			],
			[
				-82,
				266
			],
			[
				-36,
				2
			],
			[
				-4,
				396
			]
		],
		[
			[
				57875,
				24984
			],
			[
				-385,
				10
			]
		],
		[
			[
				57490,
				24994
			],
			[
				-20,
				-1165
			]
		],
		[
			[
				42070,
				60275
			],
			[
				1579,
				168
			]
		],
		[
			[
				43649,
				60443
			],
			[
				-1,
				29
			]
		],
		[
			[
				43576,
				62152
			],
			[
				-1581,
				-188
			]
		],
		[
			[
				41995,
				61964
			],
			[
				75,
				-1689
			]
		],
		[
			[
				56079,
				22046
			],
			[
				3,
				664
			],
			[
				14,
				0
			],
			[
				3,
				660
			]
		],
		[
			[
				45507,
				65709
			],
			[
				175,
				14
			]
		],
		[
			[
				45682,
				65723
			],
			[
				784,
				62
			]
		],
		[
			[
				46466,
				65785
			],
			[
				-53,
				1840
			]
		],
		[
			[
				46413,
				67625
			],
			[
				-962,
				-75
			]
		],
		[
			[
				45451,
				67550
			],
			[
				56,
				-1841
			]
		],
		[
			[
				59214,
				44204
			],
			[
				-4,
				-253
			]
		],
		[
			[
				59210,
				43951
			],
			[
				857,
				-61
			]
		],
		[
			[
				60067,
				43890
			],
			[
				14,
				-1
			]
		],
		[
			[
				60089,
				45191
			],
			[
				-383,
				-5
			],
			[
				-314,
				28
			]
		],
		[
			[
				59392,
				45214
			],
			[
				-167,
				7
			],
			[
				-11,
				-1017
			]
		],
		[
			[
				76034,
				71951
			],
			[
				54,
				-11
			],
			[
				49,
				152
			],
			[
				67,
				-9
			],
			[
				85,
				196
			],
			[
				179,
				-35
			]
		],
		[
			[
				76468,
				72244
			],
			[
				22,
				640
			]
		],
		[
			[
				76490,
				72884
			],
			[
				-5,
				188
			],
			[
				-274,
				53
			]
		],
		[
			[
				76211,
				73125
			],
			[
				-105,
				21
			]
		],
		[
			[
				76106,
				73146
			],
			[
				-8,
				-418
			],
			[
				-64,
				-777
			]
		],
		[
			[
				76252,
				65222
			],
			[
				79,
				-197
			],
			[
				83,
				7
			],
			[
				35,
				-142
			],
			[
				104,
				-94
			]
		],
		[
			[
				76553,
				64796
			],
			[
				-41,
				-50
			],
			[
				72,
				-411
			]
		],
		[
			[
				76584,
				64335
			],
			[
				374,
				189
			],
			[
				83,
				-132
			]
		],
		[
			[
				77041,
				64392
			],
			[
				-58,
				491
			],
			[
				149,
				256
			]
		],
		[
			[
				77132,
				65139
			],
			[
				-293,
				623
			]
		],
		[
			[
				76839,
				65762
			],
			[
				-67,
				143
			]
		],
		[
			[
				76772,
				65905
			],
			[
				-102,
				-73
			],
			[
				-124,
				-198
			],
			[
				-252,
				-271
			],
			[
				-42,
				-141
			]
		],
		[
			[
				63699,
				24370
			],
			[
				185,
				-18
			],
			[
				-12,
				-332
			],
			[
				242,
				-24
			]
		],
		[
			[
				64114,
				23996
			],
			[
				972,
				-106
			]
		],
		[
			[
				65086,
				23890
			],
			[
				317,
				-36
			],
			[
				15,
				341
			]
		],
		[
			[
				65418,
				24195
			],
			[
				55,
				1328
			]
		],
		[
			[
				64490,
				25624
			],
			[
				-746,
				79
			]
		],
		[
			[
				63744,
				25703
			],
			[
				-45,
				-1333
			]
		],
		[
			[
				63695,
				61712
			],
			[
				-10,
				-348
			]
		],
		[
			[
				64653,
				61284
			],
			[
				38,
				1145
			]
		],
		[
			[
				64691,
				62429
			],
			[
				-977,
				77
			]
		],
		[
			[
				63714,
				62506
			],
			[
				-19,
				-794
			]
		],
		[
			[
				17084,
				89966
			],
			[
				7,
				151
			],
			[
				111,
				-15
			]
		],
		[
			[
				17202,
				90102
			],
			[
				34,
				-6
			],
			[
				16,
				417
			],
			[
				40,
				-5
			],
			[
				21,
				619
			],
			[
				-39,
				31
			]
		],
		[
			[
				17274,
				91158
			],
			[
				-66,
				33
			],
			[
				-125,
				-116
			],
			[
				-32,
				206
			],
			[
				-74,
				-328
			],
			[
				-4,
				275
			],
			[
				-110,
				-27
			],
			[
				14,
				416
			],
			[
				-118,
				-191
			],
			[
				43,
				157
			],
			[
				-154,
				355
			],
			[
				-34,
				20
			],
			[
				-54,
				-246
			],
			[
				-11,
				222
			],
			[
				-55,
				6
			],
			[
				-96,
				278
			],
			[
				-121,
				28
			],
			[
				-42,
				-91
			],
			[
				-70,
				163
			],
			[
				-157,
				-115
			],
			[
				32,
				-226
			],
			[
				144,
				-94
			],
			[
				201,
				-416
			],
			[
				-51,
				12
			],
			[
				-148,
				203
			],
			[
				-148,
				-173
			],
			[
				58,
				-357
			],
			[
				110,
				-270
			],
			[
				43,
				-434
			],
			[
				-59,
				-226
			],
			[
				143,
				-114
			],
			[
				236,
				-345
			],
			[
				75,
				178
			],
			[
				75,
				43
			],
			[
				82,
				-148
			],
			[
				283,
				130
			]
		],
		[
			[
				15416,
				90000
			],
			[
				-16,
				-697
			],
			[
				174,
				0
			]
		],
		[
			[
				15574,
				89303
			],
			[
				622,
				-28
			],
			[
				6,
				228
			],
			[
				135,
				-8
			],
			[
				2,
				82
			]
		],
		[
			[
				16339,
				89577
			],
			[
				-68,
				201
			],
			[
				-117,
				56
			],
			[
				-117,
				218
			],
			[
				40,
				161
			],
			[
				-55,
				1
			],
			[
				-171,
				314
			],
			[
				31,
				150
			],
			[
				-189,
				301
			],
			[
				66,
				136
			],
			[
				-91,
				263
			],
			[
				-158,
				25
			],
			[
				83,
				47
			],
			[
				-18,
				182
			],
			[
				-278,
				203
			],
			[
				13,
				145
			],
			[
				-154,
				94
			],
			[
				-17,
				467
			],
			[
				38,
				-73
			],
			[
				125,
				26
			],
			[
				142,
				115
			],
			[
				23,
				159
			]
		],
		[
			[
				15467,
				92768
			],
			[
				-43,
				151
			],
			[
				-147,
				2
			],
			[
				0,
				117
			],
			[
				-199,
				0
			]
		],
		[
			[
				15078,
				93038
			],
			[
				-134,
				-1
			],
			[
				-22,
				-118
			],
			[
				3,
				-459
			],
			[
				-20,
				-238
			],
			[
				134,
				0
			],
			[
				-1,
				-234
			],
			[
				116,
				-2
			],
			[
				66,
				-119
			],
			[
				0,
				-237
			],
			[
				118,
				-120
			],
			[
				-2,
				-452
			],
			[
				48,
				-2
			],
			[
				0,
				-467
			],
			[
				51,
				-3
			],
			[
				-19,
				-586
			]
		],
		[
			[
				58150,
				20140
			],
			[
				566,
				-36
			],
			[
				4,
				352
			]
		],
		[
			[
				58720,
				20456
			],
			[
				9,
				659
			],
			[
				-133,
				4
			],
			[
				21,
				945
			]
		],
		[
			[
				58617,
				22064
			],
			[
				15,
				670
			]
		],
		[
			[
				58632,
				22734
			],
			[
				-389,
				13
			]
		],
		[
			[
				58243,
				22747
			],
			[
				-21,
				-1002
			]
		],
		[
			[
				58222,
				21745
			],
			[
				-16,
				-612
			],
			[
				-64,
				0
			],
			[
				-9,
				-661
			]
		],
		[
			[
				58133,
				20472
			],
			[
				17,
				-332
			]
		],
		[
			[
				78993,
				42001
			],
			[
				98,
				-17
			],
			[
				24,
				336
			],
			[
				97,
				-19
			],
			[
				22,
				295
			]
		],
		[
			[
				79234,
				42596
			],
			[
				-209,
				61
			],
			[
				42,
				397
			],
			[
				-198,
				-1
			],
			[
				-22,
				177
			]
		],
		[
			[
				76391,
				42872
			],
			[
				-21,
				-450
			]
		],
		[
			[
				76370,
				42422
			],
			[
				712,
				-100
			]
		],
		[
			[
				77082,
				42322
			],
			[
				42,
				900
			],
			[
				190,
				-18
			]
		],
		[
			[
				77314,
				43204
			],
			[
				12,
				333
			]
		],
		[
			[
				77326,
				43537
			],
			[
				-440,
				59
			],
			[
				15,
				-108
			],
			[
				-480,
				75
			]
		],
		[
			[
				76421,
				43563
			],
			[
				-30,
				-691
			]
		],
		[
			[
				76369,
				44816
			],
			[
				974,
				-116
			]
		],
		[
			[
				77343,
				44700
			],
			[
				33,
				852
			]
		],
		[
			[
				77376,
				45552
			],
			[
				-695,
				116
			],
			[
				-15,
				-120
			],
			[
				-102,
				-69
			]
		],
		[
			[
				76564,
				45479
			],
			[
				-85,
				-122
			],
			[
				-122,
				16
			]
		],
		[
			[
				76357,
				45373
			],
			[
				12,
				-557
			]
		],
		[
			[
				47777,
				25074
			],
			[
				1155,
				85
			]
		],
		[
			[
				48932,
				25159
			],
			[
				-35,
				1337
			]
		],
		[
			[
				48897,
				26496
			],
			[
				-1350,
				-97
			]
		],
		[
			[
				47547,
				26399
			],
			[
				-176,
				-154
			],
			[
				55,
				-469
			],
			[
				-23,
				-117
			],
			[
				-134,
				-141
			]
		],
		[
			[
				47269,
				25518
			],
			[
				9,
				-192
			],
			[
				62,
				-2
			],
			[
				100,
				214
			],
			[
				103,
				41
			],
			[
				206,
				-299
			],
			[
				28,
				-206
			]
		],
		[
			[
				58517,
				43091
			],
			[
				669,
				-39
			]
		],
		[
			[
				59186,
				43052
			],
			[
				24,
				899
			]
		],
		[
			[
				59214,
				44204
			],
			[
				-679,
				60
			]
		],
		[
			[
				58523,
				43596
			],
			[
				-6,
				-505
			]
		],
		[
			[
				69589,
				59136
			],
			[
				6,
				-261
			],
			[
				160,
				-195
			],
			[
				89,
				-7
			],
			[
				40,
				-179
			]
		],
		[
			[
				69884,
				58494
			],
			[
				597,
				38
			]
		],
		[
			[
				70481,
				58532
			],
			[
				3,
				420
			]
		],
		[
			[
				70484,
				58952
			],
			[
				-8,
				489
			],
			[
				-175,
				282
			]
		],
		[
			[
				70301,
				59723
			],
			[
				-207,
				202
			],
			[
				-163,
				-33
			],
			[
				-72,
				-78
			],
			[
				-120,
				187
			]
		],
		[
			[
				69739,
				60001
			],
			[
				-164,
				-186
			],
			[
				14,
				-679
			]
		],
		[
			[
				48716,
				74546
			],
			[
				145,
				7
			]
		],
		[
			[
				49685,
				76052
			],
			[
				-174,
				40
			],
			[
				-43,
				294
			],
			[
				-410,
				465
			]
		],
		[
			[
				49058,
				76851
			],
			[
				-94,
				-29
			],
			[
				-95,
				108
			]
		],
		[
			[
				48869,
				76930
			],
			[
				-213,
				-36
			]
		],
		[
			[
				48656,
				76894
			],
			[
				60,
				-2348
			]
		],
		[
			[
				52972,
				76127
			],
			[
				405,
				-401
			]
		],
		[
			[
				53377,
				75726
			],
			[
				420,
				76
			]
		],
		[
			[
				53797,
				75802
			],
			[
				490,
				1473
			]
		],
		[
			[
				54287,
				77275
			],
			[
				-164,
				150
			],
			[
				7,
				65
			]
		],
		[
			[
				54130,
				77490
			],
			[
				-530,
				518
			]
		],
		[
			[
				53217,
				76847
			],
			[
				-245,
				-720
			]
		],
		[
			[
				41583,
				70501
			],
			[
				873,
				108
			]
		],
		[
			[
				42456,
				70609
			],
			[
				719,
				83
			]
		],
		[
			[
				43175,
				70692
			],
			[
				-53,
				1673
			]
		],
		[
			[
				43122,
				72365
			],
			[
				-16,
				-2
			]
		],
		[
			[
				43106,
				72363
			],
			[
				-1596,
				-187
			]
		],
		[
			[
				41510,
				72176
			],
			[
				73,
				-1675
			]
		],
		[
			[
				51550,
				82384
			],
			[
				311,
				-543
			],
			[
				241,
				-788
			]
		],
		[
			[
				52102,
				81053
			],
			[
				67,
				65
			]
		],
		[
			[
				52169,
				81118
			],
			[
				258,
				255
			],
			[
				225,
				305
			],
			[
				105,
				798
			]
		],
		[
			[
				52757,
				82476
			],
			[
				-571,
				1002
			]
		],
		[
			[
				67025,
				25327
			],
			[
				19,
				25
			],
			[
				381,
				-74
			]
		],
		[
			[
				67425,
				25278
			],
			[
				-1,
				263
			],
			[
				-62,
				172
			],
			[
				64,
				97
			],
			[
				122,
				-6
			],
			[
				31,
				-179
			],
			[
				140,
				-232
			],
			[
				72,
				-42
			]
		],
		[
			[
				67038,
				25690
			],
			[
				-13,
				-363
			]
		],
		[
			[
				79486,
				75250
			],
			[
				565,
				-134
			]
		],
		[
			[
				80051,
				75116
			],
			[
				203,
				591
			],
			[
				153,
				177
			],
			[
				56,
				-14
			],
			[
				36,
				547
			],
			[
				113,
				-29
			],
			[
				76,
				783
			],
			[
				32,
				113
			]
		],
		[
			[
				80720,
				77284
			],
			[
				-78,
				9
			]
		],
		[
			[
				80642,
				77293
			],
			[
				-239,
				29
			]
		],
		[
			[
				80403,
				77322
			],
			[
				-27,
				-397
			],
			[
				-49,
				-56
			],
			[
				-314,
				3
			],
			[
				-137,
				-156
			],
			[
				-46,
				-197
			],
			[
				-248,
				-74
			]
		],
		[
			[
				79582,
				76445
			],
			[
				-96,
				-1195
			]
		],
		[
			[
				66493,
				32471
			],
			[
				382,
				-52
			]
		],
		[
			[
				67300,
				33257
			],
			[
				23,
				444
			]
		],
		[
			[
				66666,
				33795
			],
			[
				-112,
				20
			]
		],
		[
			[
				66554,
				33815
			],
			[
				-61,
				-1344
			]
		],
		[
			[
				82585,
				48807
			],
			[
				17,
				-209
			],
			[
				57,
				205
			],
			[
				-74,
				4
			]
		],
		[
			[
				13973,
				38553
			],
			[
				206,
				91
			],
			[
				113,
				-119
			],
			[
				97,
				13
			],
			[
				117,
				-91
			]
		],
		[
			[
				14506,
				38447
			],
			[
				75,
				254
			],
			[
				144,
				65
			],
			[
				-44,
				180
			],
			[
				2,
				235
			]
		],
		[
			[
				14683,
				39181
			],
			[
				-335,
				-142
			],
			[
				1,
				-115
			],
			[
				-404,
				-172
			]
		],
		[
			[
				59288,
				35560
			],
			[
				378,
				-21
			]
		],
		[
			[
				60051,
				35514
			],
			[
				29,
				1337
			]
		],
		[
			[
				60080,
				36851
			],
			[
				-770,
				41
			]
		],
		[
			[
				85870,
				60661
			],
			[
				80,
				54
			],
			[
				292,
				-120
			],
			[
				436,
				225
			],
			[
				200,
				-26
			],
			[
				132,
				-170
			]
		],
		[
			[
				87010,
				60624
			],
			[
				119,
				146
			],
			[
				68,
				-39
			]
		],
		[
			[
				87197,
				60731
			],
			[
				-107,
				586
			],
			[
				-211,
				112
			],
			[
				20,
				58
			],
			[
				-79,
				217
			],
			[
				-170,
				-55
			],
			[
				-55,
				103
			],
			[
				13,
				324
			],
			[
				-130,
				246
			],
			[
				10,
				253
			]
		],
		[
			[
				86488,
				62575
			],
			[
				-904,
				-1108
			]
		],
		[
			[
				85584,
				61467
			],
			[
				53,
				-70
			],
			[
				22,
				-273
			],
			[
				69,
				-77
			],
			[
				-1,
				-185
			],
			[
				143,
				-201
			]
		],
		[
			[
				23492,
				94283
			],
			[
				-73,
				-55
			],
			[
				-251,
				-492
			],
			[
				-203,
				-158
			],
			[
				60,
				-14
			],
			[
				-4,
				-233
			],
			[
				-73,
				-67
			],
			[
				18,
				-224
			],
			[
				-72,
				57
			],
			[
				-84,
				-147
			],
			[
				66,
				-306
			],
			[
				174,
				116
			],
			[
				89,
				-32
			],
			[
				39,
				206
			],
			[
				202,
				624
			],
			[
				99,
				570
			]
		],
		[
			[
				22770,
				93146
			],
			[
				15,
				-154
			],
			[
				118,
				129
			],
			[
				4,
				281
			],
			[
				-86,
				61
			],
			[
				14,
				-185
			],
			[
				-65,
				-132
			]
		],
		[
			[
				22954,
				92313
			],
			[
				70,
				-22
			],
			[
				105,
				316
			],
			[
				-103,
				94
			],
			[
				-235,
				-169
			],
			[
				43,
				242
			],
			[
				-34,
				181
			],
			[
				-81,
				-17
			],
			[
				-131,
				-193
			],
			[
				-45,
				15
			],
			[
				-105,
				-279
			]
		],
		[
			[
				78365,
				73921
			],
			[
				-11,
				-392
			],
			[
				67,
				-63
			],
			[
				-15,
				-188
			]
		],
		[
			[
				78406,
				73278
			],
			[
				241,
				-50
			],
			[
				22,
				289
			],
			[
				112,
				-23
			],
			[
				7,
				98
			],
			[
				168,
				-29
			],
			[
				-7,
				-102
			],
			[
				336,
				-59
			]
		],
		[
			[
				79285,
				73402
			],
			[
				-18,
				150
			],
			[
				-76,
				113
			],
			[
				11,
				174
			],
			[
				-70,
				355
			],
			[
				-62,
				37
			]
		],
		[
			[
				79070,
				74231
			],
			[
				-365,
				70
			]
		],
		[
			[
				78705,
				74301
			],
			[
				-73,
				-8
			],
			[
				-104,
				-252
			],
			[
				-156,
				-29
			],
			[
				-7,
				-91
			]
		],
		[
			[
				43974,
				16718
			],
			[
				775,
				92
			],
			[
				5,
				-110
			],
			[
				581,
				66
			]
		],
		[
			[
				45335,
				16766
			],
			[
				70,
				119
			]
		],
		[
			[
				45405,
				16885
			],
			[
				-37,
				1001
			]
		],
		[
			[
				45368,
				17886
			],
			[
				-13,
				329
			]
		],
		[
			[
				45355,
				18215
			],
			[
				-1278,
				-146
			]
		],
		[
			[
				44077,
				18069
			],
			[
				-466,
				-59
			]
		],
		[
			[
				11860,
				79978
			],
			[
				258,
				74
			],
			[
				21,
				-227
			],
			[
				516,
				128
			],
			[
				8,
				-114
			],
			[
				203,
				44
			],
			[
				-8,
				114
			],
			[
				135,
				28
			],
			[
				8,
				-114
			],
			[
				135,
				25
			],
			[
				-7,
				114
			],
			[
				272,
				47
			],
			[
				6,
				-115
			],
			[
				135,
				21
			],
			[
				-5,
				115
			],
			[
				742,
				84
			],
			[
				-4,
				115
			],
			[
				270,
				19
			],
			[
				-2,
				114
			],
			[
				270,
				13
			],
			[
				0,
				46
			]
		],
		[
			[
				14813,
				80509
			],
			[
				-19,
				298
			],
			[
				68,
				2
			],
			[
				65,
				232
			],
			[
				68,
				1
			],
			[
				-20,
				461
			],
			[
				135,
				1
			],
			[
				48,
				116
			],
			[
				-1,
				461
			],
			[
				-20,
				115
			],
			[
				-201,
				-2
			],
			[
				-2,
				230
			],
			[
				-203,
				-6
			],
			[
				-19,
				346
			],
			[
				-134,
				-6
			],
			[
				-63,
				-235
			],
			[
				-134,
				-8
			],
			[
				-6,
				231
			],
			[
				-134,
				-10
			],
			[
				6,
				-231
			],
			[
				-267,
				-24
			],
			[
				-16,
				462
			],
			[
				-338,
				-39
			],
			[
				-10,
				230
			],
			[
				-201,
				-27
			],
			[
				-25,
				577
			]
		],
		[
			[
				13390,
				83684
			],
			[
				-73,
				105
			],
			[
				-537,
				-91
			],
			[
				-741,
				-166
			],
			[
				20,
				-229
			],
			[
				-67,
				-17
			],
			[
				21,
				-228
			],
			[
				-90,
				-24
			],
			[
				42,
				-457
			],
			[
				-24,
				-6
			],
			[
				42,
				-457
			],
			[
				-11,
				-144
			]
		],
		[
			[
				11972,
				81970
			],
			[
				178,
				32
			],
			[
				3,
				186
			],
			[
				-78,
				336
			],
			[
				32,
				183
			],
			[
				188,
				49
			],
			[
				135,
				-11
			],
			[
				107,
				107
			],
			[
				84,
				-50
			],
			[
				90,
				95
			],
			[
				111,
				-270
			],
			[
				174,
				40
			],
			[
				-34,
				-143
			],
			[
				-119,
				-104
			],
			[
				-125,
				45
			],
			[
				27,
				-218
			],
			[
				-102,
				-299
			],
			[
				-65,
				-31
			],
			[
				-31,
				-168
			],
			[
				101,
				-102
			],
			[
				79,
				236
			],
			[
				-30,
				174
			],
			[
				135,
				305
			],
			[
				80,
				-38
			],
			[
				-150,
				-350
			],
			[
				42,
				-213
			],
			[
				90,
				-120
			],
			[
				-80,
				-103
			],
			[
				-217,
				52
			],
			[
				-362,
				-286
			],
			[
				11,
				-172
			],
			[
				-42,
				-387
			],
			[
				-344,
				-767
			]
		],
		[
			[
				48516,
				50354
			],
			[
				958,
				61
			]
		],
		[
			[
				49474,
				50415
			],
			[
				-6,
				336
			],
			[
				208,
				11
			]
		],
		[
			[
				49676,
				50762
			],
			[
				-19,
				1006
			],
			[
				-190,
				-9
			]
		],
		[
			[
				49467,
				51759
			],
			[
				-572,
				-35
			],
			[
				7,
				-335
			],
			[
				-382,
				-23
			]
		],
		[
			[
				48520,
				51366
			],
			[
				13,
				-675
			],
			[
				-23,
				-1
			]
		],
		[
			[
				48510,
				50690
			],
			[
				6,
				-336
			]
		],
		[
			[
				49676,
				50762
			],
			[
				752,
				38
			]
		],
		[
			[
				50428,
				50800
			],
			[
				7,
				340
			]
		],
		[
			[
				50435,
				51140
			],
			[
				-20,
				1344
			]
		],
		[
			[
				50415,
				52484
			],
			[
				-945,
				-50
			]
		],
		[
			[
				49470,
				52434
			],
			[
				-16,
				-1
			],
			[
				13,
				-674
			]
		],
		[
			[
				16684,
				13651
			],
			[
				100,
				9
			],
			[
				102,
				229
			],
			[
				154,
				73
			],
			[
				307,
				31
			],
			[
				81,
				-106
			],
			[
				286,
				-72
			]
		],
		[
			[
				17714,
				13815
			],
			[
				-321,
				2049
			],
			[
				189,
				89
			],
			[
				-49,
				319
			],
			[
				186,
				90
			],
			[
				-48,
				320
			],
			[
				-54,
				30
			]
		],
		[
			[
				17617,
				16712
			],
			[
				-184,
				-72
			],
			[
				-891,
				-409
			]
		],
		[
			[
				16542,
				16231
			],
			[
				-4,
				-59
			]
		],
		[
			[
				57657,
				38342
			],
			[
				573,
				-18
			],
			[
				176,
				-40
			]
		],
		[
			[
				58406,
				38284
			],
			[
				20,
				1347
			]
		],
		[
			[
				58426,
				39631
			],
			[
				-375,
				35
			]
		],
		[
			[
				44750,
				76253
			],
			[
				763,
				47
			],
			[
				25,
				-548
			],
			[
				66,
				42
			]
		],
		[
			[
				45604,
				75794
			],
			[
				1115,
				103
			]
		],
		[
			[
				46944,
				76347
			],
			[
				-56,
				1886
			]
		],
		[
			[
				44746,
				76359
			],
			[
				4,
				-106
			]
		],
		[
			[
				82371,
				88200
			],
			[
				966,
				-250
			]
		],
		[
			[
				83337,
				87950
			],
			[
				102,
				1151
			]
		],
		[
			[
				83439,
				89101
			],
			[
				-970,
				259
			]
		],
		[
			[
				82469,
				89360
			],
			[
				-98,
				-1160
			]
		],
		[
			[
				74540,
				43693
			],
			[
				428,
				-46
			]
		],
		[
			[
				74968,
				43647
			],
			[
				235,
				-17
			]
		],
		[
			[
				75203,
				43630
			],
			[
				38,
				1213
			]
		],
		[
			[
				75241,
				44843
			],
			[
				-434,
				32
			]
		],
		[
			[
				74807,
				44875
			],
			[
				-10,
				-66
			],
			[
				-155,
				18
			]
		],
		[
			[
				74642,
				44827
			],
			[
				-35,
				-990
			],
			[
				-54,
				31
			],
			[
				-13,
				-175
			]
		],
		[
			[
				85139,
				92515
			],
			[
				1163,
				-328
			],
			[
				-7,
				-79
			],
			[
				195,
				50
			],
			[
				260,
				-48
			]
		],
		[
			[
				86750,
				92110
			],
			[
				17,
				894
			],
			[
				25,
				423
			]
		],
		[
			[
				86792,
				93427
			],
			[
				-353,
				119
			],
			[
				5,
				51
			],
			[
				-770,
				217
			],
			[
				-8,
				-82
			],
			[
				-386,
				104
			]
		],
		[
			[
				85280,
				93836
			],
			[
				-112,
				-1044
			]
		],
		[
			[
				85168,
				92792
			],
			[
				-29,
				-277
			]
		],
		[
			[
				75721,
				37982
			],
			[
				510,
				-124
			]
		],
		[
			[
				76231,
				37858
			],
			[
				91,
				1109
			]
		],
		[
			[
				76322,
				38967
			],
			[
				-322,
				78
			],
			[
				5,
				57
			],
			[
				-192,
				45
			]
		],
		[
			[
				75614,
				38682
			],
			[
				-23,
				-333
			],
			[
				63,
				-14
			],
			[
				5,
				-338
			],
			[
				62,
				-15
			]
		],
		[
			[
				78827,
				40496
			],
			[
				77,
				-15
			],
			[
				-19,
				-274
			],
			[
				75,
				-15
			]
		],
		[
			[
				78960,
				40192
			],
			[
				479,
				-99
			]
		],
		[
			[
				79439,
				40093
			],
			[
				12,
				162
			],
			[
				191,
				-43
			]
		],
		[
			[
				79642,
				40212
			],
			[
				62,
				837
			]
		],
		[
			[
				79704,
				41049
			],
			[
				-254,
				70
			],
			[
				8,
				113
			],
			[
				-128,
				25
			],
			[
				8,
				111
			],
			[
				-193,
				38
			],
			[
				17,
				225
			],
			[
				-193,
				36
			]
		],
		[
			[
				74907,
				37480
			],
			[
				761,
				-166
			]
		],
		[
			[
				75668,
				37314
			],
			[
				53,
				668
			]
		],
		[
			[
				75008,
				38810
			],
			[
				-29,
				-384
			]
		],
		[
			[
				75203,
				43630
			],
			[
				525,
				-42
			]
		],
		[
			[
				75728,
				43588
			],
			[
				160,
				-14
			],
			[
				24,
				640
			]
		],
		[
			[
				75912,
				44214
			],
			[
				-295,
				517
			],
			[
				-76,
				170
			],
			[
				-57,
				-80
			]
		],
		[
			[
				75484,
				44821
			],
			[
				-219,
				21
			]
		],
		[
			[
				75265,
				44842
			],
			[
				-24,
				1
			]
		],
		[
			[
				42523,
				68960
			],
			[
				-67,
				1649
			]
		],
		[
			[
				41583,
				70501
			],
			[
				87,
				-1646
			]
		],
		[
			[
				76222,
				64342
			],
			[
				-40,
				-570
			]
		],
		[
			[
				76182,
				63772
			],
			[
				547,
				-114
			]
		],
		[
			[
				76729,
				63658
			],
			[
				71,
				174
			],
			[
				-90,
				367
			],
			[
				-126,
				136
			]
		],
		[
			[
				76553,
				64796
			],
			[
				-84,
				1
			],
			[
				-141,
				-140
			],
			[
				-85,
				-23
			],
			[
				-21,
				-292
			]
		],
		[
			[
				56018,
				69721
			],
			[
				4,
				-4
			]
		],
		[
			[
				56022,
				69717
			],
			[
				85,
				42
			],
			[
				251,
				-90
			]
		],
		[
			[
				56358,
				69669
			],
			[
				6,
				1365
			]
		],
		[
			[
				56364,
				71034
			],
			[
				-48,
				82
			]
		],
		[
			[
				56316,
				71116
			],
			[
				-38,
				199
			],
			[
				-253,
				1
			]
		],
		[
			[
				56025,
				71316
			],
			[
				-7,
				-1595
			]
		],
		[
			[
				86676,
				27821
			],
			[
				595,
				-294
			]
		],
		[
			[
				87271,
				27527
			],
			[
				41,
				249
			]
		],
		[
			[
				87312,
				27776
			],
			[
				187,
				1144
			]
		],
		[
			[
				87499,
				28920
			],
			[
				-424,
				176
			]
		],
		[
			[
				87075,
				29096
			],
			[
				-196,
				81
			]
		],
		[
			[
				86879,
				29177
			],
			[
				-120,
				-806
			]
		],
		[
			[
				86759,
				28371
			],
			[
				-83,
				-550
			]
		],
		[
			[
				56945,
				69757
			],
			[
				116,
				131
			]
		],
		[
			[
				57061,
				69888
			],
			[
				103,
				61
			],
			[
				74,
				166
			]
		],
		[
			[
				57238,
				70115
			],
			[
				10,
				1498
			]
		],
		[
			[
				57248,
				71613
			],
			[
				-97,
				3
			]
		],
		[
			[
				57151,
				71616
			],
			[
				-27,
				-96
			]
		],
		[
			[
				57124,
				71520
			],
			[
				-92,
				-80
			],
			[
				-97,
				-218
			]
		],
		[
			[
				56935,
				71222
			],
			[
				10,
				-1465
			]
		],
		[
			[
				49430,
				35985
			],
			[
				760,
				38
			]
		],
		[
			[
				50190,
				36023
			],
			[
				-20,
				1340
			]
		],
		[
			[
				50170,
				37363
			],
			[
				-749,
				-41
			]
		],
		[
			[
				49421,
				37322
			],
			[
				-16,
				-1
			]
		],
		[
			[
				49405,
				37321
			],
			[
				25,
				-1336
			]
		],
		[
			[
				54204,
				58994
			],
			[
				53,
				1
			]
		],
		[
			[
				54257,
				58995
			],
			[
				478,
				4
			],
			[
				0,
				-1012
			]
		],
		[
			[
				54735,
				57987
			],
			[
				335,
				0
			]
		],
		[
			[
				55070,
				57987
			],
			[
				-3,
				1006
			],
			[
				96,
				-1
			]
		],
		[
			[
				55163,
				58992
			],
			[
				1,
				1011
			],
			[
				-103,
				-1
			],
			[
				-1,
				175
			]
		],
		[
			[
				55060,
				60177
			],
			[
				-383,
				-3
			]
		],
		[
			[
				84983,
				43186
			],
			[
				250,
				-120
			],
			[
				12,
				-77
			]
		],
		[
			[
				85245,
				42989
			],
			[
				165,
				14
			],
			[
				107,
				118
			]
		],
		[
			[
				85517,
				43121
			],
			[
				-89,
				292
			],
			[
				30,
				77
			],
			[
				-104,
				138
			]
		],
		[
			[
				85354,
				43628
			],
			[
				-81,
				371
			],
			[
				-137,
				114
			]
		],
		[
			[
				85136,
				44113
			],
			[
				-17,
				-76
			],
			[
				-185,
				-137
			]
		],
		[
			[
				84934,
				43900
			],
			[
				60,
				-187
			],
			[
				39,
				-291
			],
			[
				-61,
				-3
			],
			[
				11,
				-233
			]
		],
		[
			[
				51883,
				65338
			],
			[
				384,
				11
			]
		],
		[
			[
				52267,
				65349
			],
			[
				-5,
				503
			],
			[
				578,
				15
			],
			[
				13,
				165
			],
			[
				175,
				3
			]
		],
		[
			[
				53028,
				66035
			],
			[
				-4,
				617
			]
		],
		[
			[
				53024,
				66652
			],
			[
				-64,
				-1
			],
			[
				-5,
				391
			]
		],
		[
			[
				52955,
				67042
			],
			[
				-1086,
				-25
			]
		],
		[
			[
				51869,
				67017
			],
			[
				5,
				-840
			]
		],
		[
			[
				84125,
				49506
			],
			[
				136,
				224
			],
			[
				62,
				-5
			]
		],
		[
			[
				84323,
				49725
			],
			[
				-200,
				303
			],
			[
				-82,
				-64
			]
		],
		[
			[
				84041,
				49964
			],
			[
				28,
				-162
			],
			[
				-78,
				-118
			],
			[
				134,
				-178
			]
		],
		[
			[
				52267,
				65349
			],
			[
				380,
				9
			],
			[
				-56,
				-345
			],
			[
				-43,
				-105
			],
			[
				105,
				-53
			],
			[
				383,
				8
			]
		],
		[
			[
				53036,
				64863
			],
			[
				-1,
				168
			],
			[
				192,
				3
			],
			[
				-2,
				336
			]
		],
		[
			[
				53225,
				65370
			],
			[
				-97,
				-1
			],
			[
				-4,
				667
			],
			[
				-96,
				-1
			]
		],
		[
			[
				55335,
				65041
			],
			[
				288,
				-3
			],
			[
				-1,
				-335
			]
		],
		[
			[
				55622,
				64703
			],
			[
				829,
				-9
			]
		],
		[
			[
				56451,
				64694
			],
			[
				222,
				-5
			],
			[
				5,
				671
			]
		],
		[
			[
				56678,
				65360
			],
			[
				-200,
				2
			],
			[
				7,
				1015
			],
			[
				-192,
				3
			],
			[
				-8,
				335
			]
		],
		[
			[
				56285,
				66715
			],
			[
				-1143,
				12
			]
		],
		[
			[
				55142,
				66727
			],
			[
				-2,
				-1348
			],
			[
				197,
				-2
			],
			[
				-2,
				-336
			]
		],
		[
			[
				71474,
				70453
			],
			[
				26,
				411
			]
		],
		[
			[
				71500,
				70864
			],
			[
				-33,
				426
			],
			[
				-46,
				330
			],
			[
				-54,
				200
			],
			[
				-572,
				108
			],
			[
				38,
				669
			],
			[
				-96,
				16
			]
		],
		[
			[
				70737,
				72613
			],
			[
				-95,
				16
			],
			[
				-36,
				-668
			]
		],
		[
			[
				70606,
				71961
			],
			[
				-36,
				-665
			],
			[
				96,
				-15
			],
			[
				-32,
				-670
			],
			[
				94,
				-16
			],
			[
				-8,
				-167
			]
		],
		[
			[
				69022,
				67316
			],
			[
				18,
				-1213
			]
		],
		[
			[
				69040,
				66103
			],
			[
				405,
				-34
			],
			[
				70,
				106
			],
			[
				20,
				392
			]
		],
		[
			[
				69620,
				68084
			],
			[
				-607,
				48
			]
		],
		[
			[
				69013,
				68132
			],
			[
				9,
				-816
			]
		],
		[
			[
				70097,
				66166
			],
			[
				251,
				-17
			],
			[
				639,
				-87
			]
		],
		[
			[
				70987,
				66062
			],
			[
				78,
				118
			],
			[
				53,
				260
			],
			[
				155,
				-20
			],
			[
				87,
				104
			]
		],
		[
			[
				71360,
				66524
			],
			[
				27,
				162
			]
		],
		[
			[
				71387,
				66686
			],
			[
				-90,
				123
			],
			[
				-51,
				234
			],
			[
				-16,
				283
			],
			[
				-59,
				120
			],
			[
				-156,
				79
			],
			[
				-39,
				142
			],
			[
				39,
				82
			],
			[
				-141,
				188
			]
		],
		[
			[
				70874,
				67937
			],
			[
				-94,
				10
			],
			[
				-16,
				-280
			],
			[
				-197,
				-26
			]
		],
		[
			[
				70115,
				66499
			],
			[
				-18,
				-333
			]
		],
		[
			[
				66718,
				67872
			],
			[
				34,
				837
			],
			[
				231,
				-30
			]
		],
		[
			[
				66983,
				68679
			],
			[
				-130,
				120
			],
			[
				23,
				566
			]
		],
		[
			[
				66876,
				69365
			],
			[
				-354,
				42
			]
		],
		[
			[
				66522,
				69407
			],
			[
				-20,
				-449
			],
			[
				-259,
				-23
			],
			[
				-41,
				-1000
			]
		],
		[
			[
				64974,
				65182
			],
			[
				178,
				-21
			],
			[
				-16,
				-326
			],
			[
				193,
				-26
			]
		],
		[
			[
				65329,
				64809
			],
			[
				36,
				806
			],
			[
				115,
				127
			],
			[
				12,
				405
			]
		],
		[
			[
				65492,
				66147
			],
			[
				12,
				335
			],
			[
				-578,
				59
			]
		],
		[
			[
				64926,
				66541
			],
			[
				-24,
				-728
			],
			[
				94,
				-7
			],
			[
				-22,
				-624
			]
		],
		[
			[
				58550,
				44939
			],
			[
				10,
				668
			]
		],
		[
			[
				58560,
				45607
			],
			[
				3,
				335
			]
		],
		[
			[
				58563,
				45942
			],
			[
				-767,
				18
			]
		],
		[
			[
				57796,
				45960
			],
			[
				-5,
				-844
			]
		],
		[
			[
				81915,
				51454
			],
			[
				-71,
				194
			]
		],
		[
			[
				81844,
				51648
			],
			[
				-37,
				-140
			],
			[
				108,
				-54
			]
		],
		[
			[
				81051,
				42720
			],
			[
				70,
				-178
			]
		],
		[
			[
				81121,
				42542
			],
			[
				351,
				39
			],
			[
				178,
				110
			]
		],
		[
			[
				81650,
				42691
			],
			[
				34,
				439
			],
			[
				75,
				120
			]
		],
		[
			[
				81759,
				43250
			],
			[
				-72,
				300
			],
			[
				17,
				222
			]
		],
		[
			[
				81704,
				43772
			],
			[
				-120,
				70
			]
		],
		[
			[
				81584,
				43842
			],
			[
				-525,
				-100
			]
		],
		[
			[
				81059,
				43742
			],
			[
				104,
				-141
			],
			[
				-45,
				-140
			],
			[
				-116,
				-168
			],
			[
				96,
				-316
			],
			[
				-47,
				-257
			]
		],
		[
			[
				57796,
				45960
			],
			[
				-1,
				279
			]
		],
		[
			[
				57795,
				46239
			],
			[
				-666,
				14
			]
		],
		[
			[
				57129,
				46253
			],
			[
				-4,
				-286
			]
		],
		[
			[
				57125,
				45967
			],
			[
				-9,
				-837
			]
		],
		[
			[
				81199,
				46208
			],
			[
				199,
				-521
			],
			[
				53,
				-381
			]
		],
		[
			[
				81451,
				45306
			],
			[
				114,
				13
			]
		],
		[
			[
				81565,
				45319
			],
			[
				67,
				-31
			],
			[
				77,
				159
			],
			[
				61,
				-58
			]
		],
		[
			[
				81770,
				45389
			],
			[
				227,
				598
			],
			[
				-54,
				551
			]
		],
		[
			[
				81807,
				47213
			],
			[
				-146,
				-121
			]
		],
		[
			[
				81661,
				47092
			],
			[
				-330,
				-264
			],
			[
				-109,
				-180
			],
			[
				31,
				-55
			],
			[
				-93,
				-284
			],
			[
				39,
				-101
			]
		],
		[
			[
				67181,
				70694
			],
			[
				561,
				-67
			],
			[
				193,
				-7
			]
		],
		[
			[
				67935,
				70620
			],
			[
				58,
				1341
			]
		],
		[
			[
				67993,
				71961
			],
			[
				-753,
				96
			]
		],
		[
			[
				67240,
				72057
			],
			[
				-59,
				-1363
			]
		],
		[
			[
				82746,
				80480
			],
			[
				382,
				-113
			],
			[
				94,
				-170
			],
			[
				104,
				-57
			]
		],
		[
			[
				83326,
				80140
			],
			[
				298,
				862
			]
		],
		[
			[
				83624,
				81002
			],
			[
				-99,
				89
			],
			[
				62,
				544
			],
			[
				-513,
				156
			],
			[
				-54,
				-521
			],
			[
				-25,
				11
			]
		],
		[
			[
				82995,
				81281
			],
			[
				-82,
				29
			],
			[
				-120,
				-370
			],
			[
				-47,
				-460
			]
		],
		[
			[
				76270,
				74993
			],
			[
				278,
				-34
			],
			[
				74,
				-120
			],
			[
				39,
				62
			]
		],
		[
			[
				76661,
				74901
			],
			[
				543,
				-129
			]
		],
		[
			[
				77204,
				74772
			],
			[
				-87,
				203
			],
			[
				-1,
				144
			],
			[
				-204,
				171
			],
			[
				-78,
				336
			],
			[
				-178,
				314
			],
			[
				-55,
				185
			],
			[
				9,
				171
			]
		],
		[
			[
				76610,
				76296
			],
			[
				-65,
				11
			]
		],
		[
			[
				76545,
				76307
			],
			[
				-38,
				-675
			],
			[
				-198,
				29
			]
		],
		[
			[
				76309,
				75661
			],
			[
				-39,
				-668
			]
		],
		[
			[
				67122,
				65957
			],
			[
				16,
				335
			],
			[
				193,
				-24
			]
		],
		[
			[
				67331,
				66268
			],
			[
				44,
				1006
			],
			[
				-95,
				13
			],
			[
				8,
				281
			]
		],
		[
			[
				67288,
				67568
			],
			[
				3,
				64
			],
			[
				-581,
				70
			]
		],
		[
			[
				66687,
				67143
			],
			[
				-49,
				-1132
			]
		],
		[
			[
				67286,
				73411
			],
			[
				767,
				-98
			]
		],
		[
			[
				68126,
				74835
			],
			[
				-61,
				16
			]
		],
		[
			[
				68065,
				74851
			],
			[
				-701,
				173
			]
		],
		[
			[
				48952,
				21168
			],
			[
				1108,
				71
			]
		],
		[
			[
				50060,
				21239
			],
			[
				438,
				27
			]
		],
		[
			[
				50498,
				21266
			],
			[
				-24,
				1327
			]
		],
		[
			[
				50474,
				22593
			],
			[
				-1536,
				-98
			]
		],
		[
			[
				48938,
				22495
			],
			[
				-15,
				-1
			]
		],
		[
			[
				48923,
				22494
			],
			[
				29,
				-1326
			]
		],
		[
			[
				76859,
				76242
			],
			[
				493,
				-101
			]
		],
		[
			[
				77352,
				76141
			],
			[
				22,
				370
			],
			[
				101,
				243
			],
			[
				52,
				903
			]
		],
		[
			[
				77527,
				77657
			],
			[
				-381,
				45
			]
		],
		[
			[
				77146,
				77702
			],
			[
				-188,
				22
			]
		],
		[
			[
				76958,
				77724
			],
			[
				-99,
				-1482
			]
		],
		[
			[
				54304,
				18730
			],
			[
				167,
				1
			],
			[
				-3,
				-332
			]
		],
		[
			[
				56042,
				19719
			],
			[
				17,
				330
			],
			[
				4,
				669
			]
		],
		[
			[
				55094,
				20725
			],
			[
				-769,
				-4
			]
		],
		[
			[
				54325,
				20721
			],
			[
				2,
				-663
			],
			[
				-24,
				0
			],
			[
				1,
				-1328
			]
		],
		[
			[
				69003,
				69082
			],
			[
				10,
				-950
			]
		],
		[
			[
				69815,
				68053
			],
			[
				77,
				1422
			]
		],
		[
			[
				69892,
				69475
			],
			[
				-194,
				204
			],
			[
				-34,
				121
			],
			[
				-210,
				192
			],
			[
				-118,
				13
			],
			[
				-34,
				164
			]
		],
		[
			[
				69302,
				70169
			],
			[
				-312,
				62
			]
		],
		[
			[
				68990,
				70231
			],
			[
				13,
				-1149
			]
		],
		[
			[
				54961,
				30709
			],
			[
				656,
				-6
			]
		],
		[
			[
				55617,
				30703
			],
			[
				108,
				0
			]
		],
		[
			[
				55725,
				30703
			],
			[
				3,
				941
			]
		],
		[
			[
				54960,
				31640
			],
			[
				1,
				-931
			]
		],
		[
			[
				86503,
				44462
			],
			[
				69,
				-167
			],
			[
				220,
				211
			],
			[
				121,
				18
			]
		],
		[
			[
				86913,
				44524
			],
			[
				-14,
				225
			],
			[
				43,
				242
			],
			[
				69,
				121
			]
		],
		[
			[
				87011,
				45112
			],
			[
				-70,
				71
			],
			[
				6,
				288
			],
			[
				40,
				50
			]
		],
		[
			[
				86987,
				45521
			],
			[
				-75,
				20
			]
		],
		[
			[
				86912,
				45541
			],
			[
				-148,
				-100
			]
		],
		[
			[
				86764,
				45441
			],
			[
				-50,
				-118
			],
			[
				-103,
				73
			]
		],
		[
			[
				86611,
				45396
			],
			[
				-176,
				-172
			]
		],
		[
			[
				86435,
				45224
			],
			[
				-45,
				-149
			]
		],
		[
			[
				86390,
				45075
			],
			[
				-23,
				-214
			],
			[
				136,
				-399
			]
		],
		[
			[
				65348,
				39149
			],
			[
				287,
				-33
			]
		],
		[
			[
				65635,
				39116
			],
			[
				228,
				-25
			],
			[
				-42,
				176
			],
			[
				327,
				-41
			]
		],
		[
			[
				66369,
				39887
			],
			[
				-709,
				104
			]
		],
		[
			[
				65660,
				39991
			],
			[
				33,
				-205
			],
			[
				-319,
				35
			]
		],
		[
			[
				46714,
				41140
			],
			[
				930,
				75
			]
		],
		[
			[
				48050,
				41246
			],
			[
				-24,
				1008
			],
			[
				-192,
				-15
			],
			[
				-8,
				337
			]
		],
		[
			[
				47826,
				42576
			],
			[
				-174,
				-9
			]
		],
		[
			[
				46707,
				42493
			],
			[
				-32,
				-3
			]
		],
		[
			[
				46675,
				42490
			],
			[
				39,
				-1350
			]
		],
		[
			[
				68998,
				41258
			],
			[
				726,
				-110
			]
		],
		[
			[
				69724,
				41148
			],
			[
				26,
				419
			]
		],
		[
			[
				69750,
				41567
			],
			[
				-61,
				110
			],
			[
				-184,
				151
			],
			[
				-169,
				408
			],
			[
				-102,
				87
			],
			[
				-26,
				107
			],
			[
				62,
				139
			]
		],
		[
			[
				69270,
				42569
			],
			[
				-141,
				22
			],
			[
				-74,
				-69
			]
		],
		[
			[
				69055,
				42522
			],
			[
				-57,
				-1264
			]
		],
		[
			[
				72137,
				26450
			],
			[
				374,
				-64
			]
		],
		[
			[
				72511,
				26386
			],
			[
				387,
				-75
			]
		],
		[
			[
				72898,
				26311
			],
			[
				96,
				1314
			]
		],
		[
			[
				72994,
				27625
			],
			[
				-770,
				162
			]
		],
		[
			[
				72224,
				27787
			],
			[
				-87,
				-1337
			]
		],
		[
			[
				72898,
				26311
			],
			[
				381,
				-79
			]
		],
		[
			[
				73279,
				26232
			],
			[
				320,
				-73
			]
		],
		[
			[
				73599,
				26159
			],
			[
				45,
				627
			]
		],
		[
			[
				73644,
				26786
			],
			[
				46,
				652
			]
		],
		[
			[
				73690,
				27438
			],
			[
				-318,
				58
			],
			[
				4,
				56
			],
			[
				-382,
				73
			]
		],
		[
			[
				18942,
				79227
			],
			[
				-385,
				135
			],
			[
				-556,
				131
			],
			[
				-487,
				135
			],
			[
				46,
				645
			],
			[
				-497,
				92
			],
			[
				-454,
				65
			],
			[
				-699,
				64
			],
			[
				-413,
				19
			],
			[
				-684,
				-4
			]
		],
		[
			[
				11860,
				79978
			],
			[
				-156,
				-190
			],
			[
				-32,
				-147
			],
			[
				-145,
				-222
			],
			[
				101,
				-35
			],
			[
				57,
				-159
			],
			[
				54,
				-421
			],
			[
				247,
				104
			],
			[
				311,
				-10
			],
			[
				225,
				-224
			],
			[
				99,
				-195
			],
			[
				58,
				-394
			],
			[
				60,
				-252
			],
			[
				230,
				-468
			],
			[
				124,
				-140
			],
			[
				155,
				74
			],
			[
				134,
				-81
			],
			[
				172,
				-212
			],
			[
				165,
				-284
			],
			[
				130,
				-88
			],
			[
				129,
				133
			],
			[
				238,
				-92
			],
			[
				95,
				-123
			],
			[
				169,
				-392
			],
			[
				73,
				-5
			],
			[
				239,
				172
			],
			[
				18,
				100
			],
			[
				-124,
				170
			],
			[
				9,
				164
			],
			[
				76,
				21
			],
			[
				36,
				-151
			],
			[
				89,
				-111
			],
			[
				39,
				-154
			],
			[
				107,
				159
			],
			[
				10,
				262
			],
			[
				103,
				77
			],
			[
				76,
				-154
			],
			[
				171,
				-50
			],
			[
				267,
				120
			],
			[
				-63,
				180
			],
			[
				15,
				104
			],
			[
				188,
				61
			],
			[
				-39,
				166
			],
			[
				196,
				58
			],
			[
				50,
				-112
			],
			[
				143,
				-13
			],
			[
				16,
				65
			],
			[
				234,
				-168
			],
			[
				186,
				129
			],
			[
				46,
				-33
			],
			[
				77,
				145
			],
			[
				36,
				-63
			],
			[
				150,
				172
			],
			[
				144,
				34
			],
			[
				71,
				-53
			],
			[
				278,
				-28
			],
			[
				134,
				149
			],
			[
				130,
				66
			],
			[
				89,
				-35
			],
			[
				179,
				-246
			],
			[
				166,
				-87
			],
			[
				551,
				444
			],
			[
				124,
				25
			],
			[
				172,
				1487
			]
		],
		[
			[
				85963,
				48174
			],
			[
				213,
				57
			],
			[
				-8,
				-202
			],
			[
				46,
				-72
			],
			[
				173,
				169
			],
			[
				42,
				95
			],
			[
				93,
				-48
			],
			[
				202,
				122
			]
		],
		[
			[
				86724,
				48295
			],
			[
				-203,
				475
			],
			[
				-6,
				130
			],
			[
				-76,
				57
			]
		],
		[
			[
				86439,
				48957
			],
			[
				-101,
				-191
			],
			[
				-205,
				2
			],
			[
				-73,
				181
			],
			[
				-114,
				27
			]
		],
		[
			[
				63922,
				44548
			],
			[
				197,
				-16
			],
			[
				4,
				138
			],
			[
				191,
				-19
			],
			[
				8,
				338
			],
			[
				127,
				100
			],
			[
				14,
				448
			]
		],
		[
			[
				64463,
				45537
			],
			[
				-476,
				42
			]
		],
		[
			[
				63987,
				45579
			],
			[
				2,
				-147
			],
			[
				-136,
				-542
			],
			[
				69,
				-342
			]
		],
		[
			[
				72997,
				22284
			],
			[
				188,
				-39
			]
		],
		[
			[
				73185,
				22245
			],
			[
				569,
				-136
			]
		],
		[
			[
				73754,
				22109
			],
			[
				51,
				650
			],
			[
				-16,
				4
			],
			[
				52,
				660
			]
		],
		[
			[
				73841,
				23423
			],
			[
				-758,
				168
			]
		],
		[
			[
				66140,
				28462
			],
			[
				772,
				-94
			],
			[
				-10,
				-173
			]
		],
		[
			[
				67297,
				28320
			],
			[
				67,
				1333
			]
		],
		[
			[
				66979,
				29705
			],
			[
				-17,
				-335
			],
			[
				-779,
				94
			]
		],
		[
			[
				66183,
				29464
			],
			[
				-43,
				-1002
			]
		],
		[
			[
				48545,
				49015
			],
			[
				937,
				54
			]
		],
		[
			[
				49482,
				49069
			],
			[
				16,
				2
			]
		],
		[
			[
				49498,
				49071
			],
			[
				-24,
				1344
			]
		],
		[
			[
				48516,
				50354
			],
			[
				29,
				-1339
			]
		],
		[
			[
				67494,
				34992
			],
			[
				17,
				335
			],
			[
				-42,
				7
			],
			[
				16,
				308
			]
		],
		[
			[
				67485,
				35642
			],
			[
				49,
				1004
			]
		],
		[
			[
				67534,
				36646
			],
			[
				-560,
				96
			]
		],
		[
			[
				66974,
				36742
			],
			[
				-61,
				-1332
			],
			[
				7,
				-337
			]
		],
		[
			[
				50284,
				9624
			],
			[
				1558,
				67
			]
		],
		[
			[
				51842,
				9691
			],
			[
				-12,
				1069
			],
			[
				33,
				1
			],
			[
				-8,
				660
			]
		],
		[
			[
				51855,
				11421
			],
			[
				-584,
				-25
			]
		],
		[
			[
				51271,
				11396
			],
			[
				-975,
				-48
			]
		],
		[
			[
				50296,
				11348
			],
			[
				12,
				-657
			],
			[
				-44,
				-3
			],
			[
				20,
				-1064
			]
		],
		[
			[
				72026,
				38708
			],
			[
				512,
				-109
			]
		],
		[
			[
				72538,
				38599
			],
			[
				21,
				338
			]
		],
		[
			[
				72559,
				38937
			],
			[
				69,
				1006
			],
			[
				-191,
				39
			]
		],
		[
			[
				72120,
				40044
			],
			[
				-94,
				-1336
			]
		],
		[
			[
				50455,
				48446
			],
			[
				958,
				37
			]
		],
		[
			[
				51413,
				48483
			],
			[
				-5,
				1008
			]
		],
		[
			[
				51408,
				49491
			],
			[
				-3,
				336
			]
		],
		[
			[
				51405,
				49827
			],
			[
				-963,
				-34
			]
		],
		[
			[
				50442,
				49793
			],
			[
				1,
				-676
			]
		],
		[
			[
				50443,
				49117
			],
			[
				12,
				-671
			]
		],
		[
			[
				61907,
				40435
			],
			[
				-23,
				-1009
			]
		],
		[
			[
				61884,
				39426
			],
			[
				384,
				-29
			]
		],
		[
			[
				62268,
				39397
			],
			[
				193,
				-11
			],
			[
				9,
				335
			]
		],
		[
			[
				62470,
				39721
			],
			[
				26,
				1007
			],
			[
				-62,
				5
			]
		],
		[
			[
				62434,
				40733
			],
			[
				-519,
				38
			]
		],
		[
			[
				61915,
				40771
			],
			[
				-8,
				-336
			]
		],
		[
			[
				60428,
				35156
			],
			[
				385,
				-26
			]
		],
		[
			[
				60813,
				35130
			],
			[
				39,
				1672
			]
		],
		[
			[
				70457,
				42179
			],
			[
				763,
				-140
			]
		],
		[
			[
				71284,
				43016
			],
			[
				-146,
				33
			]
		],
		[
			[
				71138,
				43049
			],
			[
				-623,
				112
			]
		],
		[
			[
				70515,
				43161
			],
			[
				-58,
				-982
			]
		],
		[
			[
				48709,
				18510
			],
			[
				717,
				53
			]
		],
		[
			[
				49426,
				18563
			],
			[
				635,
				41
			]
		],
		[
			[
				50061,
				18604
			],
			[
				-26,
				1323
			]
		],
		[
			[
				50035,
				19927
			],
			[
				-1301,
				-79
			]
		],
		[
			[
				48734,
				19848
			],
			[
				-59,
				-6
			],
			[
				34,
				-1332
			]
		],
		[
			[
				56532,
				40695
			],
			[
				766,
				-14
			]
		],
		[
			[
				57310,
				41948
			],
			[
				-271,
				6
			]
		],
		[
			[
				57039,
				41954
			],
			[
				-476,
				-5
			]
		],
		[
			[
				56563,
				41949
			],
			[
				-1,
				-587
			],
			[
				-26,
				0
			],
			[
				-4,
				-667
			]
		],
		[
			[
				14133,
				37510
			],
			[
				-76,
				479
			]
		],
		[
			[
				11780,
				38081
			],
			[
				116,
				-729
			],
			[
				48,
				-147
			],
			[
				80,
				-2
			],
			[
				44,
				-115
			],
			[
				168,
				-177
			],
			[
				181,
				38
			]
		],
		[
			[
				65418,
				24195
			],
			[
				381,
				-46
			]
		],
		[
			[
				66604,
				24709
			],
			[
				380,
				-42
			],
			[
				41,
				660
			]
		],
		[
			[
				22594,
				15648
			],
			[
				116,
				-104
			],
			[
				-17,
				-198
			],
			[
				109,
				-167
			]
		],
		[
			[
				23786,
				14081
			],
			[
				170,
				125
			],
			[
				59,
				213
			],
			[
				315,
				267
			],
			[
				60,
				-95
			],
			[
				71,
				-272
			],
			[
				133,
				-136
			],
			[
				55,
				-185
			],
			[
				1635,
				-53
			]
		],
		[
			[
				26284,
				13945
			],
			[
				78,
				-17
			],
			[
				116,
				89
			],
			[
				76,
				-87
			],
			[
				139,
				47
			]
		],
		[
			[
				26693,
				13977
			],
			[
				12,
				57
			],
			[
				-106,
				504
			],
			[
				-82,
				9
			],
			[
				16,
				222
			],
			[
				-93,
				185
			],
			[
				-9,
				196
			],
			[
				-58,
				211
			],
			[
				-58,
				17
			],
			[
				-3,
				362
			],
			[
				-107,
				-12
			],
			[
				-28,
				156
			],
			[
				81,
				136
			],
			[
				-71,
				171
			],
			[
				11,
				203
			],
			[
				84,
				184
			],
			[
				-50,
				62
			],
			[
				43,
				222
			],
			[
				-46,
				104
			],
			[
				-137,
				-43
			],
			[
				-136,
				253
			],
			[
				72,
				226
			],
			[
				-15,
				202
			],
			[
				-107,
				77
			],
			[
				21,
				136
			],
			[
				-50,
				143
			]
		],
		[
			[
				23231,
				18815
			],
			[
				-246,
				-96
			],
			[
				47,
				-84
			],
			[
				-30,
				-533
			],
			[
				-528,
				-197
			]
		],
		[
			[
				22474,
				17905
			],
			[
				46,
				-193
			],
			[
				189,
				-404
			],
			[
				61,
				-55
			],
			[
				32,
				-174
			],
			[
				181,
				-345
			],
			[
				-68,
				-282
			],
			[
				6,
				-270
			],
			[
				-58,
				-129
			],
			[
				-108,
				-55
			],
			[
				-30,
				-160
			],
			[
				-128,
				-69
			],
			[
				-3,
				-121
			]
		],
		[
			[
				11095,
				29152
			],
			[
				1690,
				808
			]
		],
		[
			[
				12970,
				30044
			],
			[
				-166,
				1035
			],
			[
				20,
				8
			],
			[
				-286,
				1707
			]
		],
		[
			[
				12538,
				32794
			],
			[
				-276,
				-130
			]
		],
		[
			[
				12262,
				32664
			],
			[
				-248,
				-75
			],
			[
				-52,
				-97
			],
			[
				-92,
				26
			],
			[
				-184,
				-116
			],
			[
				-164,
				30
			],
			[
				-104,
				-93
			],
			[
				-222,
				8
			],
			[
				-357,
				-66
			],
			[
				-185,
				-108
			],
			[
				-92,
				-113
			],
			[
				-250,
				133
			],
			[
				-128,
				-211
			],
			[
				-214,
				-38
			],
			[
				-101,
				118
			],
			[
				-88,
				-77
			],
			[
				-162,
				31
			]
		],
		[
			[
				9619,
				32016
			],
			[
				44,
				-219
			],
			[
				84,
				-57
			],
			[
				72,
				-151
			],
			[
				167,
				-29
			],
			[
				122,
				-169
			],
			[
				94,
				3
			],
			[
				41,
				-104
			],
			[
				146,
				-25
			],
			[
				17,
				-247
			],
			[
				-23,
				-244
			],
			[
				176,
				-237
			],
			[
				48,
				-203
			],
			[
				123,
				-165
			],
			[
				74,
				-323
			],
			[
				129,
				-80
			],
			[
				44,
				-245
			],
			[
				135,
				-32
			],
			[
				45,
				-225
			],
			[
				-62,
				-112
			]
		],
		[
			[
				52806,
				24356
			],
			[
				539,
				16
			],
			[
				-1,
				664
			]
		],
		[
			[
				62742,
				24473
			],
			[
				771,
				-73
			],
			[
				186,
				-30
			]
		],
		[
			[
				63744,
				25703
			],
			[
				35,
				997
			]
		],
		[
			[
				63779,
				26700
			],
			[
				-772,
				83
			],
			[
				-10,
				-333
			],
			[
				-192,
				17
			],
			[
				-10,
				-331
			]
		],
		[
			[
				62764,
				25138
			],
			[
				-22,
				-665
			]
		],
		[
			[
				50492,
				45758
			],
			[
				948,
				40
			]
		],
		[
			[
				51440,
				45798
			],
			[
				-8,
				1005
			]
		],
		[
			[
				51432,
				46803
			],
			[
				-5,
				337
			]
		],
		[
			[
				51427,
				47140
			],
			[
				-961,
				-41
			]
		],
		[
			[
				50466,
				47099
			],
			[
				26,
				-1341
			]
		],
		[
			[
				51432,
				46803
			],
			[
				958,
				30
			]
		],
		[
			[
				52390,
				46833
			],
			[
				-8,
				672
			]
		],
		[
			[
				52382,
				47505
			],
			[
				-6,
				672
			]
		],
		[
			[
				52376,
				48177
			],
			[
				-959,
				-31
			]
		],
		[
			[
				51417,
				48146
			],
			[
				10,
				-1006
			]
		],
		[
			[
				53014,
				12853
			],
			[
				970,
				11
			]
		],
		[
			[
				53984,
				12864
			],
			[
				-2,
				578
			],
			[
				27,
				220
			]
		],
		[
			[
				54009,
				13662
			],
			[
				-1,
				441
			],
			[
				196,
				1
			],
			[
				0,
				331
			],
			[
				779,
				-1
			],
			[
				0,
				-332
			],
			[
				193,
				-2
			],
			[
				-1,
				-331
			]
		],
		[
			[
				55175,
				13769
			],
			[
				192,
				6
			]
		],
		[
			[
				55367,
				13775
			],
			[
				8,
				988
			],
			[
				39,
				0
			],
			[
				0,
				659
			]
		],
		[
			[
				55414,
				15422
			],
			[
				-780,
				6
			]
		],
		[
			[
				54634,
				15428
			],
			[
				-1192,
				-9
			]
		],
		[
			[
				53442,
				15419
			],
			[
				3,
				-343
			],
			[
				-59,
				-316
			]
		],
		[
			[
				53386,
				14760
			],
			[
				-71,
				-366
			],
			[
				-61,
				-143
			],
			[
				-54,
				-434
			],
			[
				-65,
				-167
			],
			[
				-16,
				-333
			],
			[
				-44,
				-78
			],
			[
				-61,
				-386
			]
		],
		[
			[
				47616,
				47267
			],
			[
				932,
				64
			]
		],
		[
			[
				48548,
				47331
			],
			[
				23,
				2
			]
		],
		[
			[
				48571,
				47333
			],
			[
				-49,
				1680
			]
		],
		[
			[
				48522,
				49013
			],
			[
				-959,
				-70
			]
		],
		[
			[
				47563,
				48943
			],
			[
				53,
				-1676
			]
		],
		[
			[
				61586,
				57460
			],
			[
				53,
				-4
			]
		],
		[
			[
				61639,
				57456
			],
			[
				797,
				-62
			]
		],
		[
			[
				62436,
				57394
			],
			[
				395,
				-24
			]
		],
		[
			[
				62831,
				57370
			],
			[
				10,
				626
			],
			[
				-199,
				8
			],
			[
				9,
				337
			],
			[
				-224,
				5
			]
		],
		[
			[
				62427,
				58346
			],
			[
				-831,
				30
			]
		],
		[
			[
				61596,
				58376
			],
			[
				-10,
				-916
			]
		],
		[
			[
				87300,
				21910
			],
			[
				489,
				289
			]
		],
		[
			[
				87789,
				22199
			],
			[
				330,
				1768
			]
		],
		[
			[
				88119,
				23967
			],
			[
				-573,
				985
			],
			[
				-375,
				-59
			]
		],
		[
			[
				87171,
				24893
			],
			[
				-133,
				-809
			]
		],
		[
			[
				87038,
				24084
			],
			[
				-66,
				-366
			],
			[
				-102,
				23
			],
			[
				-28,
				-351
			],
			[
				132,
				-28
			],
			[
				198,
				-437
			],
			[
				97,
				-22
			],
			[
				-31,
				-147
			],
			[
				75,
				-34
			],
			[
				-4,
				-252
			],
			[
				-110,
				-72
			],
			[
				101,
				-488
			]
		],
		[
			[
				60472,
				60527
			],
			[
				321,
				-3
			],
			[
				-3,
				-279
			],
			[
				383,
				-16
			]
		],
		[
			[
				61173,
				60229
			],
			[
				3,
				280
			],
			[
				320,
				-6
			]
		],
		[
			[
				61496,
				60503
			],
			[
				9,
				1344
			]
		],
		[
			[
				61505,
				61847
			],
			[
				-413,
				9
			]
		],
		[
			[
				61092,
				61856
			],
			[
				-4,
				-335
			],
			[
				-670,
				11
			]
		],
		[
			[
				78261,
				53239
			],
			[
				111,
				-162
			],
			[
				-22,
				203
			],
			[
				-89,
				-41
			]
		],
		[
			[
				63529,
				54999
			],
			[
				95,
				-15
			],
			[
				4,
				169
			],
			[
				326,
				-18
			]
		],
		[
			[
				63954,
				55135
			],
			[
				88,
				-5
			],
			[
				4,
				190
			],
			[
				102,
				276
			]
		],
		[
			[
				64148,
				55596
			],
			[
				36,
				165
			],
			[
				7,
				275
			]
		],
		[
			[
				64191,
				56036
			],
			[
				-413,
				34
			],
			[
				-391,
				-7
			]
		],
		[
			[
				63387,
				56063
			],
			[
				-193,
				9
			],
			[
				-3,
				-225
			]
		],
		[
			[
				46850,
				79679
			],
			[
				1211,
				81
			]
		],
		[
			[
				48377,
				81405
			],
			[
				-871,
				-71
			]
		],
		[
			[
				47506,
				81334
			],
			[
				-698,
				-47
			]
		],
		[
			[
				46808,
				81287
			],
			[
				42,
				-1608
			]
		],
		[
			[
				60205,
				55338
			],
			[
				384,
				-9
			]
		],
		[
			[
				60589,
				55329
			],
			[
				768,
				-20
			]
		],
		[
			[
				61644,
				55302
			],
			[
				11,
				1013
			],
			[
				-29,
				1
			]
		],
		[
			[
				61626,
				56316
			],
			[
				-1156,
				28
			]
		],
		[
			[
				60470,
				56344
			],
			[
				-257,
				3
			]
		],
		[
			[
				60213,
				56347
			],
			[
				-8,
				-1009
			]
		],
		[
			[
				43122,
				72365
			],
			[
				961,
				95
			]
		],
		[
			[
				44083,
				72460
			],
			[
				-70,
				1678
			]
		],
		[
			[
				43860,
				74124
			],
			[
				-818,
				-88
			]
		],
		[
			[
				43042,
				74036
			],
			[
				64,
				-1673
			]
		],
		[
			[
				57980,
				48632
			],
			[
				4,
				-325
			]
		],
		[
			[
				57984,
				48307
			],
			[
				479,
				16
			],
			[
				2,
				-113
			],
			[
				581,
				12
			]
		],
		[
			[
				59046,
				48222
			],
			[
				9,
				114
			],
			[
				-11,
				1324
			]
		],
		[
			[
				59044,
				49660
			],
			[
				-958,
				-5
			]
		],
		[
			[
				58086,
				49655
			],
			[
				-95,
				-2
			],
			[
				-21,
				-336
			],
			[
				10,
				-685
			]
		],
		[
			[
				44137,
				70784
			],
			[
				249,
				28
			]
		],
		[
			[
				44386,
				70812
			],
			[
				715,
				59
			]
		],
		[
			[
				45101,
				70871
			],
			[
				-56,
				1674
			]
		],
		[
			[
				45045,
				72545
			],
			[
				0,
				13
			]
		],
		[
			[
				45045,
				72558
			],
			[
				-962,
				-98
			]
		],
		[
			[
				44083,
				72460
			],
			[
				54,
				-1676
			]
		],
		[
			[
				58089,
				50119
			],
			[
				-3,
				-464
			]
		],
		[
			[
				59044,
				49660
			],
			[
				0,
				168
			]
		],
		[
			[
				59044,
				49828
			],
			[
				-1,
				1179
			]
		],
		[
			[
				59043,
				51007
			],
			[
				-58,
				26
			],
			[
				-857,
				-16
			]
		],
		[
			[
				58128,
				51017
			],
			[
				-44,
				0
			],
			[
				5,
				-898
			]
		],
		[
			[
				49580,
				64910
			],
			[
				18,
				-1004
			],
			[
				375,
				19
			]
		],
		[
			[
				49973,
				63925
			],
			[
				958,
				40
			]
		],
		[
			[
				50931,
				63965
			],
			[
				-2,
				670
			]
		],
		[
			[
				50824,
				65302
			],
			[
				-187,
				-7
			],
			[
				-2,
				167
			],
			[
				-95,
				52
			],
			[
				-383,
				-17
			],
			[
				-1,
				111
			],
			[
				-191,
				-9
			],
			[
				-97,
				52
			]
		],
		[
			[
				49868,
				65651
			],
			[
				6,
				-391
			],
			[
				-299,
				-15
			],
			[
				5,
				-335
			]
		],
		[
			[
				43483,
				69066
			],
			[
				961,
				80
			]
		],
		[
			[
				44444,
				69146
			],
			[
				-58,
				1666
			]
		],
		[
			[
				44137,
				70784
			],
			[
				-716,
				-68
			]
		],
		[
			[
				43421,
				70716
			],
			[
				62,
				-1650
			]
		],
		[
			[
				60480,
				57534
			],
			[
				-10,
				-1190
			]
		],
		[
			[
				61626,
				56316
			],
			[
				13,
				1140
			]
		],
		[
			[
				61586,
				57460
			],
			[
				-674,
				45
			]
		],
		[
			[
				39319,
				44389
			],
			[
				76,
				-210
			],
			[
				138,
				122
			],
			[
				24,
				116
			],
			[
				120,
				-108
			],
			[
				-94,
				-57
			],
			[
				70,
				-101
			],
			[
				-22,
				-172
			]
		],
		[
			[
				39631,
				43979
			],
			[
				785,
				131
			],
			[
				1213,
				169
			]
		],
		[
			[
				41596,
				44946
			],
			[
				-12,
				-2
			]
		],
		[
			[
				41584,
				44944
			],
			[
				-1609,
				-236
			]
		],
		[
			[
				39975,
				44708
			],
			[
				-660,
				-106
			]
		],
		[
			[
				39315,
				44602
			],
			[
				4,
				-213
			]
		],
		[
			[
				39034,
				49628
			],
			[
				188,
				29
			],
			[
				54,
				-1004
			]
		],
		[
			[
				39276,
				48653
			],
			[
				1531,
				224
			]
		],
		[
			[
				40807,
				48877
			],
			[
				-79,
				1444
			]
		],
		[
			[
				40728,
				50321
			],
			[
				-84,
				1583
			]
		],
		[
			[
				40644,
				51904
			],
			[
				-489,
				-393
			]
		],
		[
			[
				40155,
				51511
			],
			[
				-497,
				-398
			],
			[
				-643,
				-28
			],
			[
				-56,
				-142
			]
		],
		[
			[
				38959,
				50943
			],
			[
				75,
				-1315
			]
		],
		[
			[
				64070,
				51157
			],
			[
				50,
				22
			],
			[
				5,
				207
			],
			[
				53,
				43
			],
			[
				286,
				-178
			]
		],
		[
			[
				64464,
				51251
			],
			[
				170,
				217
			],
			[
				-223,
				420
			],
			[
				480,
				760
			],
			[
				76,
				-125
			],
			[
				81,
				102
			]
		],
		[
			[
				65048,
				52625
			],
			[
				-60,
				121
			]
		],
		[
			[
				64988,
				52746
			],
			[
				-679,
				62
			]
		],
		[
			[
				64309,
				52808
			],
			[
				-198,
				22
			],
			[
				-6,
				-355
			]
		],
		[
			[
				41754,
				49033
			],
			[
				574,
				41
			],
			[
				15,
				-335
			]
		],
		[
			[
				42343,
				48739
			],
			[
				191,
				14
			],
			[
				1755,
				207
			]
		],
		[
			[
				44289,
				48960
			],
			[
				-49,
				1335
			]
		],
		[
			[
				44240,
				50295
			],
			[
				-1210,
				-133
			]
		],
		[
			[
				43030,
				50162
			],
			[
				-1139,
				-139
			]
		],
		[
			[
				41891,
				50023
			],
			[
				-177,
				-22
			]
		],
		[
			[
				41714,
				50001
			],
			[
				40,
				-968
			]
		],
		[
			[
				38742,
				46199
			],
			[
				506,
				83
			]
		],
		[
			[
				39248,
				46282
			],
			[
				-48,
				1004
			],
			[
				-65,
				-13
			],
			[
				-24,
				268
			],
			[
				228,
				47
			],
			[
				-37,
				564
			]
		],
		[
			[
				39302,
				48152
			],
			[
				-513,
				-76
			],
			[
				15,
				-188
			],
			[
				-157,
				-28
			]
		],
		[
			[
				38647,
				47860
			],
			[
				93,
				-1661
			]
		],
		[
			[
				38740,
				46199
			],
			[
				2,
				0
			]
		],
		[
			[
				49742,
				82043
			],
			[
				324,
				786
			]
		],
		[
			[
				50066,
				82829
			],
			[
				-461,
				715
			]
		],
		[
			[
				49605,
				83544
			],
			[
				-179,
				100
			],
			[
				-79,
				-17
			]
		],
		[
			[
				49347,
				83627
			],
			[
				-266,
				-248
			]
		],
		[
			[
				49081,
				83379
			],
			[
				20,
				-1365
			]
		],
		[
			[
				45045,
				72558
			],
			[
				-71,
				1675
			]
		],
		[
			[
				44974,
				74233
			],
			[
				-151,
				-14
			]
		],
		[
			[
				59513,
				56354
			],
			[
				700,
				-7
			]
		],
		[
			[
				60335,
				57541
			],
			[
				-782,
				40
			]
		],
		[
			[
				59553,
				57581
			],
			[
				-39,
				2
			]
		],
		[
			[
				59514,
				57583
			],
			[
				-1,
				-1229
			]
		],
		[
			[
				53813,
				54590
			],
			[
				986,
				18
			]
		],
		[
			[
				54799,
				54608
			],
			[
				1,
				1158
			]
		],
		[
			[
				54800,
				55766
			],
			[
				-65,
				1
			]
		],
		[
			[
				54735,
				55767
			],
			[
				-927,
				-4
			]
		],
		[
			[
				53808,
				55763
			],
			[
				5,
				-1173
			]
		],
		[
			[
				70748,
				72777
			],
			[
				51,
				-24
			],
			[
				365,
				414
			],
			[
				85,
				192
			],
			[
				64,
				19
			],
			[
				30,
				143
			],
			[
				509,
				-83
			]
		],
		[
			[
				71852,
				73438
			],
			[
				95,
				-17
			],
			[
				16,
				330
			]
		],
		[
			[
				71963,
				73751
			],
			[
				-96,
				18
			],
			[
				32,
				500
			]
		],
		[
			[
				71899,
				74269
			],
			[
				-1118,
				183
			]
		],
		[
			[
				70781,
				74452
			],
			[
				-224,
				42
			],
			[
				-10,
				-165
			],
			[
				-87,
				-8
			],
			[
				-25,
				-442
			]
		],
		[
			[
				70435,
				73879
			],
			[
				82,
				-67
			],
			[
				-27,
				-485
			],
			[
				191,
				-30
			],
			[
				-27,
				-467
			],
			[
				94,
				-53
			]
		],
		[
			[
				72994,
				27625
			],
			[
				91,
				1332
			]
		],
		[
			[
				73085,
				28957
			],
			[
				-382,
				77
			]
		],
		[
			[
				46725,
				37127
			],
			[
				216,
				21
			]
		],
		[
			[
				46941,
				37148
			],
			[
				-42,
				1332
			]
		],
		[
			[
				46899,
				38480
			],
			[
				-924,
				-88
			]
		],
		[
			[
				45975,
				38392
			],
			[
				-228,
				-24
			]
		],
		[
			[
				45747,
				38368
			],
			[
				46,
				-1337
			]
		],
		[
			[
				42862,
				63756
			],
			[
				648,
				72
			]
		],
		[
			[
				43510,
				63828
			],
			[
				309,
				31
			]
		],
		[
			[
				43819,
				63859
			],
			[
				-61,
				1674
			]
		],
		[
			[
				43758,
				65533
			],
			[
				-168,
				-19
			]
		],
		[
			[
				43590,
				65514
			],
			[
				-796,
				-90
			]
		],
		[
			[
				44240,
				50319
			],
			[
				827,
				84
			]
		],
		[
			[
				45067,
				50403
			],
			[
				44,
				4
			]
		],
		[
			[
				45068,
				52440
			],
			[
				-899,
				-103
			]
		],
		[
			[
				44169,
				52337
			],
			[
				71,
				-2018
			]
		],
		[
			[
				42494,
				15183
			],
			[
				574,
				84
			]
		],
		[
			[
				43031,
				17933
			],
			[
				-294,
				-40
			],
			[
				-16,
				337
			],
			[
				-375,
				-54
			]
		],
		[
			[
				42346,
				18176
			],
			[
				18,
				-382
			]
		],
		[
			[
				42364,
				17794
			],
			[
				130,
				-2611
			]
		],
		[
			[
				59173,
				38266
			],
			[
				759,
				-43
			]
		],
		[
			[
				59932,
				38223
			],
			[
				28,
				1338
			]
		],
		[
			[
				59960,
				39561
			],
			[
				-383,
				22
			]
		],
		[
			[
				59196,
				39603
			],
			[
				-22,
				-1272
			]
		],
		[
			[
				45833,
				52513
			],
			[
				764,
				67
			]
		],
		[
			[
				46597,
				52580
			],
			[
				-29,
				1008
			]
		],
		[
			[
				46568,
				53588
			],
			[
				-10,
				336
			]
		],
		[
			[
				46558,
				53924
			],
			[
				-729,
				-64
			]
		],
		[
			[
				45791,
				53856
			],
			[
				42,
				-1343
			]
		],
		[
			[
				71753,
				31920
			],
			[
				382,
				-80
			]
		],
		[
			[
				72611,
				33096
			],
			[
				-370,
				78
			]
		],
		[
			[
				46808,
				81287
			],
			[
				-1124,
				-82
			]
		],
		[
			[
				45684,
				81205
			],
			[
				-500,
				-42
			]
		],
		[
			[
				45184,
				81163
			],
			[
				49,
				-1600
			]
		],
		[
			[
				49470,
				57525
			],
			[
				207,
				14
			],
			[
				61,
				178
			],
			[
				143,
				67
			],
			[
				47,
				115
			],
			[
				130,
				97
			],
			[
				164,
				-12
			],
			[
				4,
				-253
			]
		],
		[
			[
				50976,
				58914
			],
			[
				-190,
				-7
			]
		],
		[
			[
				50024,
				58873
			],
			[
				-571,
				-16
			]
		],
		[
			[
				49453,
				58857
			],
			[
				17,
				-1332
			]
		],
		[
			[
				43528,
				67392
			],
			[
				961,
				79
			]
		],
		[
			[
				44489,
				67471
			],
			[
				-45,
				1675
			]
		],
		[
			[
				43483,
				69066
			],
			[
				45,
				-1674
			]
		],
		[
			[
				27113,
				5073
			],
			[
				959,
				328
			]
		],
		[
			[
				28072,
				5401
			],
			[
				43,
				355
			],
			[
				-69,
				48
			],
			[
				121,
				321
			],
			[
				75,
				-88
			],
			[
				170,
				65
			],
			[
				14,
				273
			],
			[
				64,
				245
			],
			[
				-112,
				357
			],
			[
				198,
				216
			],
			[
				89,
				-9
			],
			[
				101,
				179
			],
			[
				-55,
				331
			],
			[
				85,
				167
			],
			[
				69,
				10
			],
			[
				32,
				162
			],
			[
				-93,
				137
			],
			[
				41,
				129
			]
		],
		[
			[
				28845,
				8299
			],
			[
				151,
				340
			],
			[
				-32,
				236
			],
			[
				62,
				1
			],
			[
				67,
				164
			],
			[
				110,
				-12
			],
			[
				65,
				93
			]
		],
		[
			[
				29268,
				9121
			],
			[
				26,
				228
			],
			[
				60,
				69
			],
			[
				55,
				345
			],
			[
				-61,
				93
			],
			[
				-105,
				-60
			]
		],
		[
			[
				28863,
				11060
			],
			[
				-479,
				-168
			]
		],
		[
			[
				28384,
				10892
			],
			[
				-250,
				-80
			]
		],
		[
			[
				28134,
				10812
			],
			[
				95,
				-439
			],
			[
				-55,
				-12
			],
			[
				-27,
				-337
			],
			[
				56,
				-313
			],
			[
				-92,
				-269
			],
			[
				-130,
				-143
			],
			[
				-33,
				-292
			],
			[
				-214,
				-69
			],
			[
				-85,
				82
			],
			[
				34,
				245
			],
			[
				-672,
				-221
			],
			[
				-52,
				647
			],
			[
				-189,
				-63
			]
		],
		[
			[
				79587,
				37034
			],
			[
				140,
				-37
			],
			[
				-53,
				-557
			]
		],
		[
			[
				79674,
				36440
			],
			[
				796,
				-217
			]
		],
		[
			[
				80470,
				36223
			],
			[
				4,
				31
			]
		],
		[
			[
				80474,
				36254
			],
			[
				81,
				855
			]
		],
		[
			[
				80555,
				37109
			],
			[
				-500,
				137
			],
			[
				-10,
				-110
			],
			[
				-123,
				32
			],
			[
				10,
				112
			],
			[
				-315,
				83
			]
		],
		[
			[
				79617,
				37363
			],
			[
				-30,
				-329
			]
		],
		[
			[
				42144,
				58610
			],
			[
				1567,
				187
			]
		],
		[
			[
				43711,
				58797
			],
			[
				-62,
				1646
			]
		],
		[
			[
				42070,
				60275
			],
			[
				20,
				-450
			]
		],
		[
			[
				42090,
				59825
			],
			[
				54,
				-1215
			]
		],
		[
			[
				64000,
				38605
			],
			[
				-14,
				-500
			],
			[
				157,
				-236
			],
			[
				222,
				-124
			],
			[
				12,
				-173
			]
		],
		[
			[
				64377,
				37572
			],
			[
				535,
				-55
			]
		],
		[
			[
				64912,
				37517
			],
			[
				18,
				252
			],
			[
				40,
				1091
			]
		],
		[
			[
				64772,
				39210
			],
			[
				-756,
				72
			]
		],
		[
			[
				64016,
				39282
			],
			[
				-16,
				-677
			]
		],
		[
			[
				50172,
				38697
			],
			[
				753,
				36
			]
		],
		[
			[
				50925,
				38733
			],
			[
				8,
				0
			]
		],
		[
			[
				50933,
				38733
			],
			[
				-19,
				1339
			]
		],
		[
			[
				50143,
				40036
			],
			[
				29,
				-1339
			]
		],
		[
			[
				50860,
				72986
			],
			[
				218,
				1077
			]
		],
		[
			[
				51078,
				74063
			],
			[
				145,
				566
			]
		],
		[
			[
				51223,
				74629
			],
			[
				-268,
				258
			]
		],
		[
			[
				50955,
				74887
			],
			[
				-388,
				371
			]
		],
		[
			[
				88363,
				51100
			],
			[
				53,
				216
			]
		],
		[
			[
				88416,
				51316
			],
			[
				-100,
				-41
			],
			[
				-26,
				-122
			],
			[
				73,
				-53
			]
		],
		[
			[
				61085,
				31588
			],
			[
				-24,
				-1104
			]
		],
		[
			[
				61061,
				30484
			],
			[
				563,
				-42
			]
		],
		[
			[
				61624,
				30442
			],
			[
				193,
				-14
			]
		],
		[
			[
				61817,
				30428
			],
			[
				52,
				1606
			]
		],
		[
			[
				61869,
				32034
			],
			[
				-772,
				54
			]
		],
		[
			[
				61097,
				32088
			],
			[
				-12,
				-500
			]
		],
		[
			[
				41956,
				25674
			],
			[
				172,
				-1
			],
			[
				207,
				-74
			],
			[
				400,
				58
			]
		],
		[
			[
				42735,
				25657
			],
			[
				-73,
				1328
			],
			[
				194,
				24
			],
			[
				-20,
				443
			]
		],
		[
			[
				42836,
				27452
			],
			[
				-959,
				-135
			]
		],
		[
			[
				41884,
				27166
			],
			[
				72,
				-1492
			]
		],
		[
			[
				13390,
				83684
			],
			[
				67,
				10
			],
			[
				-16,
				349
			],
			[
				-63,
				-10
			],
			[
				-22,
				451
			],
			[
				-66,
				-8
			],
			[
				-11,
				230
			],
			[
				-68,
				-8
			],
			[
				-42,
				932
			],
			[
				77,
				9
			],
			[
				-6,
				349
			],
			[
				-80,
				-14
			],
			[
				-70,
				231
			],
			[
				-137,
				-21
			],
			[
				-74,
				91
			],
			[
				-14,
				355
			],
			[
				-62,
				-8
			],
			[
				-22,
				342
			]
		],
		[
			[
				12197,
				86492
			],
			[
				131,
				-258
			],
			[
				234,
				102
			],
			[
				160,
				-46
			],
			[
				150,
				-336
			],
			[
				-38,
				-433
			],
			[
				4,
				-196
			],
			[
				-83,
				-234
			],
			[
				-79,
				-48
			],
			[
				48,
				-139
			],
			[
				123,
				64
			],
			[
				80,
				-131
			],
			[
				14,
				-141
			],
			[
				-118,
				-296
			],
			[
				-74,
				185
			],
			[
				-95,
				-39
			],
			[
				-176,
				110
			],
			[
				-138,
				172
			],
			[
				-105,
				254
			],
			[
				-4,
				-237
			],
			[
				-91,
				-210
			],
			[
				-51,
				57
			],
			[
				83,
				169
			],
			[
				-76,
				-7
			],
			[
				-105,
				-152
			],
			[
				-275,
				-47
			],
			[
				-212,
				91
			],
			[
				-384,
				-319
			],
			[
				-59,
				-223
			],
			[
				43,
				-158
			],
			[
				-74,
				-195
			],
			[
				-40,
				-223
			],
			[
				76,
				60
			],
			[
				118,
				-183
			],
			[
				-56,
				-103
			],
			[
				-284,
				-198
			],
			[
				-165,
				-283
			],
			[
				9,
				-145
			],
			[
				199,
				-113
			],
			[
				259,
				-225
			],
			[
				359,
				-268
			],
			[
				208,
				-116
			],
			[
				259,
				-84
			]
		],
		[
			[
				9073,
				85044
			],
			[
				82,
				-289
			],
			[
				23,
				154
			],
			[
				214,
				247
			],
			[
				170,
				-101
			],
			[
				111,
				166
			],
			[
				-7,
				167
			],
			[
				156,
				236
			],
			[
				273,
				203
			],
			[
				-86,
				173
			],
			[
				-177,
				-76
			],
			[
				-129,
				242
			],
			[
				-40,
				-211
			],
			[
				-144,
				-347
			],
			[
				-144,
				-241
			],
			[
				-78,
				-48
			],
			[
				-147,
				95
			],
			[
				-80,
				-120
			],
			[
				3,
				-250
			]
		],
		[
			[
				46648,
				45506
			],
			[
				36,
				3
			]
		],
		[
			[
				46684,
				45509
			],
			[
				-51,
				1675
			]
		],
		[
			[
				46633,
				47184
			],
			[
				-155,
				-13
			]
		],
		[
			[
				46478,
				47171
			],
			[
				-994,
				-98
			]
		],
		[
			[
				45484,
				47073
			],
			[
				60,
				-1669
			]
		],
		[
			[
				74086,
				39532
			],
			[
				99,
				-21
			]
		],
		[
			[
				74185,
				39511
			],
			[
				290,
				-66
			],
			[
				8,
				111
			],
			[
				192,
				-44
			],
			[
				4,
				56
			],
			[
				379,
				-88
			]
		],
		[
			[
				75089,
				39885
			],
			[
				-189,
				55
			],
			[
				0,
				202
			]
		],
		[
			[
				74900,
				40142
			],
			[
				-562,
				129
			],
			[
				29,
				391
			],
			[
				-160,
				35
			],
			[
				6,
				91
			]
		],
		[
			[
				64715,
				45510
			],
			[
				388,
				-48
			]
		],
		[
			[
				65103,
				45462
			],
			[
				383,
				-48
			]
		],
		[
			[
				65569,
				47433
			],
			[
				-768,
				80
			]
		],
		[
			[
				64801,
				47513
			],
			[
				-40,
				-1009
			]
		],
		[
			[
				64761,
				46504
			],
			[
				-46,
				-994
			]
		],
		[
			[
				58670,
				26618
			],
			[
				386,
				-19
			]
		],
		[
			[
				59056,
				26599
			],
			[
				4,
				276
			],
			[
				385,
				-20
			]
		],
		[
			[
				59445,
				26855
			],
			[
				17,
				1055
			]
		],
		[
			[
				58689,
				27951
			],
			[
				-19,
				-1333
			]
		],
		[
			[
				87275,
				36326
			],
			[
				184,
				167
			]
		],
		[
			[
				87459,
				36493
			],
			[
				560,
				508
			]
		],
		[
			[
				88019,
				37001
			],
			[
				-230,
				323
			],
			[
				-204,
				139
			],
			[
				-197,
				234
			]
		],
		[
			[
				87388,
				37697
			],
			[
				-312,
				-984
			],
			[
				199,
				-387
			]
		],
		[
			[
				87297,
				49381
			],
			[
				-20,
				24
			]
		],
		[
			[
				87277,
				49405
			],
			[
				-45,
				-155
			],
			[
				117,
				-63
			],
			[
				-52,
				194
			]
		],
		[
			[
				53203,
				48532
			],
			[
				669,
				10
			]
		],
		[
			[
				53872,
				48542
			],
			[
				0,
				168
			],
			[
				192,
				3
			],
			[
				0,
				335
			],
			[
				64,
				1
			]
		],
		[
			[
				54124,
				49887
			],
			[
				-807,
				-11
			]
		],
		[
			[
				53317,
				49876
			],
			[
				-191,
				-3
			],
			[
				2,
				-336
			]
		],
		[
			[
				53128,
				49537
			],
			[
				10,
				-782
			],
			[
				63,
				1
			],
			[
				2,
				-224
			]
		],
		[
			[
				57890,
				25984
			],
			[
				-386,
				11
			],
			[
				2,
				335
			],
			[
				-385,
				13
			],
			[
				-4,
				-334
			]
		],
		[
			[
				57117,
				26009
			],
			[
				-13,
				-670
			]
		],
		[
			[
				57104,
				25339
			],
			[
				-2,
				-335
			],
			[
				388,
				-10
			]
		],
		[
			[
				74528,
				67625
			],
			[
				513,
				-91
			]
		],
		[
			[
				75234,
				68273
			],
			[
				-261,
				257
			],
			[
				-264,
				215
			]
		],
		[
			[
				74709,
				68745
			],
			[
				-181,
				-1120
			]
		],
		[
			[
				67402,
				62701
			],
			[
				351,
				-45
			]
		],
		[
			[
				67753,
				62656
			],
			[
				23,
				524
			],
			[
				127,
				-17
			],
			[
				18,
				392
			],
			[
				62,
				-8
			]
		],
		[
			[
				67983,
				63547
			],
			[
				29,
				616
			],
			[
				-32,
				4
			]
		],
		[
			[
				67980,
				64167
			],
			[
				-642,
				75
			]
		],
		[
			[
				67338,
				64242
			],
			[
				-35,
				-834
			],
			[
				95,
				-12
			],
			[
				26,
				-171
			],
			[
				-22,
				-524
			]
		],
		[
			[
				92968,
				16433
			],
			[
				-28,
				-214
			],
			[
				75,
				-267
			],
			[
				2,
				-294
			],
			[
				-87,
				-84
			],
			[
				88,
				-34
			],
			[
				115,
				-325
			],
			[
				115,
				156
			],
			[
				149,
				-51
			],
			[
				38,
				-256
			]
		],
		[
			[
				93435,
				15064
			],
			[
				390,
				2000
			],
			[
				316,
				1723
			]
		],
		[
			[
				94141,
				18787
			],
			[
				-52,
				28
			],
			[
				29,
				169
			],
			[
				-327,
				124
			],
			[
				-4,
				607
			],
			[
				-49,
				21
			],
			[
				-64,
				-264
			],
			[
				-117,
				-130
			]
		],
		[
			[
				93557,
				19342
			],
			[
				-110,
				-140
			],
			[
				-161,
				93
			],
			[
				-46,
				-178
			],
			[
				-184,
				-26
			],
			[
				-147,
				-229
			]
		],
		[
			[
				69566,
				56044
			],
			[
				539,
				-97
			]
		],
		[
			[
				70496,
				55882
			],
			[
				-16,
				311
			],
			[
				31,
				46
			],
			[
				15,
				363
			]
		],
		[
			[
				70526,
				56602
			],
			[
				-51,
				48
			],
			[
				-212,
				386
			],
			[
				-4,
				125
			]
		],
		[
			[
				70259,
				57161
			],
			[
				-28,
				42
			],
			[
				-378,
				-28
			]
		],
		[
			[
				69706,
				57070
			],
			[
				-140,
				-1026
			]
		],
		[
			[
				60201,
				59027
			],
			[
				97,
				-2
			]
		],
		[
			[
				61159,
				59172
			],
			[
				2,
				335
			]
		],
		[
			[
				61161,
				59507
			],
			[
				12,
				722
			]
		],
		[
			[
				60218,
				60533
			],
			[
				-3,
				-1339
			],
			[
				-14,
				-167
			]
		],
		[
			[
				77041,
				64392
			],
			[
				109,
				213
			],
			[
				109,
				55
			],
			[
				98,
				-48
			],
			[
				176,
				42
			],
			[
				66,
				241
			]
		],
		[
			[
				77599,
				64895
			],
			[
				-182,
				273
			]
		],
		[
			[
				77417,
				65168
			],
			[
				-37,
				-29
			],
			[
				-168,
				119
			],
			[
				-80,
				-119
			]
		],
		[
			[
				59558,
				31683
			],
			[
				20,
				1173
			]
		],
		[
			[
				59578,
				32856
			],
			[
				-770,
				34
			]
		],
		[
			[
				58808,
				32890
			],
			[
				-19,
				-1333
			]
		],
		[
			[
				65606,
				74268
			],
			[
				942,
				-92
			]
		],
		[
			[
				66733,
				75181
			],
			[
				-192,
				44
			]
		],
		[
			[
				66541,
				75225
			],
			[
				-412,
				96
			]
		],
		[
			[
				66129,
				75321
			],
			[
				-279,
				64
			]
		],
		[
			[
				65850,
				75385
			],
			[
				17,
				-142
			],
			[
				-76,
				-33
			],
			[
				-22,
				-164
			],
			[
				48,
				-58
			],
			[
				-77,
				-275
			],
			[
				-164,
				-372
			],
			[
				30,
				-73
			]
		],
		[
			[
				48588,
				13881
			],
			[
				388,
				28
			]
		],
		[
			[
				48976,
				13909
			],
			[
				778,
				51
			]
		],
		[
			[
				49754,
				13960
			],
			[
				-14,
				659
			],
			[
				48,
				3
			],
			[
				-6,
				324
			]
		],
		[
			[
				49761,
				15935
			],
			[
				-326,
				-20
			]
		],
		[
			[
				49435,
				15915
			],
			[
				-842,
				-57
			]
		],
		[
			[
				48593,
				15858
			],
			[
				34,
				-1315
			],
			[
				-56,
				-4
			],
			[
				17,
				-658
			]
		],
		[
			[
				49757,
				80661
			],
			[
				346,
				8
			],
			[
				116,
				66
			]
		],
		[
			[
				50219,
				80735
			],
			[
				430,
				247
			]
		],
		[
			[
				50649,
				80982
			],
			[
				-95,
				264
			]
		],
		[
			[
				50554,
				81246
			],
			[
				-257,
				1208
			]
		],
		[
			[
				50297,
				82454
			],
			[
				-231,
				375
			]
		],
		[
			[
				60862,
				20317
			],
			[
				773,
				-56
			]
		],
		[
			[
				61635,
				20261
			],
			[
				17,
				662
			],
			[
				16,
				6
			],
			[
				38,
				1311
			]
		],
		[
			[
				61706,
				22240
			],
			[
				-767,
				50
			]
		],
		[
			[
				60939,
				22290
			],
			[
				-35,
				-1313
			],
			[
				-27,
				2
			],
			[
				-15,
				-662
			]
		],
		[
			[
				77483,
				72896
			],
			[
				-15,
				242
			]
		],
		[
			[
				77468,
				73138
			],
			[
				-57,
				185
			],
			[
				-59,
				19
			],
			[
				52,
				129
			],
			[
				-73,
				204
			],
			[
				45,
				248
			]
		],
		[
			[
				77376,
				73923
			],
			[
				-39,
				111
			],
			[
				-478,
				109
			]
		],
		[
			[
				76859,
				74143
			],
			[
				-26,
				-649
			],
			[
				41,
				-151
			],
			[
				-73,
				-127
			],
			[
				-87,
				-16
			]
		],
		[
			[
				76714,
				73200
			],
			[
				-12,
				-162
			],
			[
				781,
				-142
			]
		],
		[
			[
				58023,
				31589
			],
			[
				766,
				-32
			]
		],
		[
			[
				58808,
				32890
			],
			[
				-769,
				35
			]
		],
		[
			[
				40221,
				32972
			],
			[
				386,
				59
			]
		],
		[
			[
				40607,
				33031
			],
			[
				-192,
				3666
			]
		],
		[
			[
				40415,
				36697
			],
			[
				-1030,
				-180
			]
		],
		[
			[
				39385,
				36517
			],
			[
				102,
				-1655
			],
			[
				58,
				-1315
			]
		],
		[
			[
				39545,
				33547
			],
			[
				34,
				-669
			],
			[
				642,
				94
			]
		],
		[
			[
				55738,
				34310
			],
			[
				-579,
				6
			]
		],
		[
			[
				55159,
				34316
			],
			[
				-193,
				3
			]
		],
		[
			[
				54966,
				34319
			],
			[
				0,
				-1340
			]
		],
		[
			[
				68062,
				69216
			],
			[
				266,
				-37
			]
		],
		[
			[
				68328,
				69179
			],
			[
				675,
				-97
			]
		],
		[
			[
				68990,
				70231
			],
			[
				-3,
				240
			]
		],
		[
			[
				68987,
				70471
			],
			[
				-863,
				128
			]
		],
		[
			[
				68124,
				70599
			],
			[
				-62,
				-1383
			]
		],
		[
			[
				53202,
				41481
			],
			[
				12,
				-1342
			]
		],
		[
			[
				53214,
				40139
			],
			[
				4,
				0
			]
		],
		[
			[
				53218,
				40139
			],
			[
				743,
				13
			],
			[
				0,
				112
			]
		],
		[
			[
				53961,
				40264
			],
			[
				-2,
				895
			]
		],
		[
			[
				53959,
				41159
			],
			[
				-3,
				1006
			]
		],
		[
			[
				53956,
				42165
			],
			[
				-756,
				-12
			]
		],
		[
			[
				73795,
				44109
			],
			[
				50,
				832
			]
		],
		[
			[
				73845,
				44941
			],
			[
				-419,
				76
			]
		],
		[
			[
				73426,
				45017
			],
			[
				-259,
				46
			],
			[
				-124,
				180
			]
		],
		[
			[
				72994,
				44534
			],
			[
				-19,
				-276
			]
		],
		[
			[
				83527,
				29876
			],
			[
				435,
				-143
			]
		],
		[
			[
				83962,
				29733
			],
			[
				318,
				-90
			],
			[
				20,
				163
			],
			[
				190,
				-50
			]
		],
		[
			[
				84490,
				29756
			],
			[
				16,
				258
			],
			[
				143,
				1541
			]
		],
		[
			[
				83909,
				31795
			],
			[
				-164,
				54
			]
		],
		[
			[
				83745,
				31849
			],
			[
				-218,
				-1973
			]
		],
		[
			[
				64697,
				75710
			],
			[
				863,
				-91
			],
			[
				64,
				-75
			]
		],
		[
			[
				65624,
				75544
			],
			[
				52,
				1407
			]
		],
		[
			[
				65676,
				76951
			],
			[
				-31,
				3
			]
		],
		[
			[
				65645,
				76954
			],
			[
				-547,
				58
			]
		],
		[
			[
				65098,
				77012
			],
			[
				-161,
				17
			]
		],
		[
			[
				64937,
				77029
			],
			[
				-33,
				-1004
			],
			[
				-197,
				19
			]
		],
		[
			[
				64707,
				76044
			],
			[
				-10,
				-334
			]
		],
		[
			[
				98045,
				13024
			],
			[
				163,
				-146
			],
			[
				-82,
				-298
			],
			[
				-321,
				-979
			]
		],
		[
			[
				97805,
				11601
			],
			[
				336,
				-336
			]
		],
		[
			[
				98141,
				11265
			],
			[
				140,
				-3
			],
			[
				2,
				-68
			],
			[
				140,
				190
			],
			[
				236,
				-19
			],
			[
				64,
				-97
			],
			[
				116,
				336
			],
			[
				-121,
				120
			],
			[
				87,
				211
			],
			[
				87,
				84
			],
			[
				27,
				197
			],
			[
				-42,
				258
			],
			[
				207,
				376
			],
			[
				120,
				74
			],
			[
				49,
				-111
			],
			[
				-16,
				-155
			],
			[
				217,
				-10
			],
			[
				163,
				283
			],
			[
				162,
				408
			],
			[
				103,
				76
			],
			[
				71,
				368
			],
			[
				-202,
				774
			],
			[
				-115,
				150
			],
			[
				-106,
				-280
			],
			[
				-129,
				172
			],
			[
				96,
				148
			],
			[
				-39,
				172
			],
			[
				-65,
				-11
			],
			[
				-41,
				220
			],
			[
				-129,
				50
			],
			[
				30,
				103
			],
			[
				112,
				77
			],
			[
				-86,
				245
			],
			[
				-98,
				-108
			],
			[
				-66,
				-161
			],
			[
				-106,
				210
			],
			[
				-70,
				-167
			],
			[
				-45,
				286
			],
			[
				-4,
				256
			],
			[
				-58,
				-25
			],
			[
				-95,
				120
			]
		],
		[
			[
				65606,
				48758
			],
			[
				193,
				-25
			],
			[
				-17,
				-335
			]
		],
		[
			[
				65782,
				48398
			],
			[
				591,
				-66
			]
		],
		[
			[
				66573,
				48330
			],
			[
				29,
				899
			]
		],
		[
			[
				66602,
				49229
			],
			[
				-267,
				22
			],
			[
				-94,
				-38
			],
			[
				-217,
				219
			],
			[
				-82,
				-37
			],
			[
				-150,
				50
			],
			[
				-146,
				235
			]
		],
		[
			[
				65646,
				49680
			],
			[
				-40,
				-922
			]
		],
		[
			[
				74989,
				42468
			],
			[
				-20,
				-619
			]
		],
		[
			[
				75852,
				41770
			],
			[
				-50,
				951
			],
			[
				-101,
				6
			]
		],
		[
			[
				75701,
				42727
			],
			[
				-314,
				-27
			],
			[
				-4,
				-95
			],
			[
				-194,
				18
			],
			[
				-1,
				-58
			],
			[
				-195,
				17
			]
		],
		[
			[
				74993,
				42582
			],
			[
				-4,
				-114
			]
		],
		[
			[
				41981,
				41296
			],
			[
				193,
				26
			],
			[
				-12,
				335
			]
		],
		[
			[
				41678,
				43274
			],
			[
				-752,
				-106
			]
		],
		[
			[
				40926,
				43168
			],
			[
				106,
				-2011
			],
			[
				949,
				139
			]
		],
		[
			[
				52728,
				51544
			],
			[
				544,
				17
			]
		],
		[
			[
				53272,
				51561
			],
			[
				554,
				4
			]
		],
		[
			[
				53816,
				53415
			],
			[
				-3,
				505
			]
		],
		[
			[
				53813,
				53920
			],
			[
				-1102,
				-14
			]
		],
		[
			[
				52711,
				53906
			],
			[
				13,
				-1687
			]
		],
		[
			[
				68946,
				40259
			],
			[
				433,
				-66
			]
		],
		[
			[
				69379,
				40193
			],
			[
				282,
				-45
			]
		],
		[
			[
				69661,
				40148
			],
			[
				42,
				666
			]
		],
		[
			[
				69703,
				40814
			],
			[
				21,
				334
			]
		],
		[
			[
				68998,
				41258
			],
			[
				-3,
				-55
			]
		],
		[
			[
				65533,
				42329
			],
			[
				571,
				-88
			]
		],
		[
			[
				66349,
				43280
			],
			[
				24,
				503
			],
			[
				-126,
				19
			]
		],
		[
			[
				66247,
				43802
			],
			[
				-318,
				34
			],
			[
				-135,
				-42
			],
			[
				-7,
				-167
			],
			[
				-160,
				21
			]
		],
		[
			[
				65627,
				43648
			],
			[
				-19,
				-448
			],
			[
				-43,
				-111
			]
		],
		[
			[
				65565,
				43089
			],
			[
				-32,
				-760
			]
		],
		[
			[
				64007,
				21032
			],
			[
				959,
				-113
			],
			[
				194,
				-35
			],
			[
				5,
				155
			],
			[
				196,
				-8
			],
			[
				-10,
				-162
			],
			[
				202,
				-10
			]
		],
		[
			[
				65553,
				20859
			],
			[
				73,
				1640
			]
		],
		[
			[
				65626,
				22499
			],
			[
				-592,
				63
			]
		],
		[
			[
				65034,
				22562
			],
			[
				-19,
				-336
			],
			[
				-961,
				110
			]
		],
		[
			[
				84481,
				54188
			],
			[
				182,
				-49
			]
		],
		[
			[
				84663,
				54139
			],
			[
				358,
				-98
			]
		],
		[
			[
				85056,
				55504
			],
			[
				-115,
				45
			],
			[
				-302,
				29
			]
		],
		[
			[
				84639,
				55578
			],
			[
				-39,
				-108
			],
			[
				-119,
				-1282
			]
		],
		[
			[
				45656,
				74297
			],
			[
				294,
				30
			]
		],
		[
			[
				45950,
				74327
			],
			[
				809,
				76
			]
		],
		[
			[
				45604,
				75794
			],
			[
				52,
				-1497
			]
		],
		[
			[
				47517,
				61589
			],
			[
				766,
				55
			],
			[
				8,
				-336
			],
			[
				381,
				24
			]
		],
		[
			[
				48668,
				61500
			],
			[
				-20,
				1344
			]
		],
		[
			[
				48648,
				62844
			],
			[
				-89,
				3
			]
		],
		[
			[
				48559,
				62847
			],
			[
				-56,
				17
			],
			[
				-621,
				-70
			],
			[
				-8,
				334
			],
			[
				-191,
				-14
			]
		],
		[
			[
				47683,
				63114
			],
			[
				-203,
				-15
			]
		],
		[
			[
				47495,
				62513
			],
			[
				22,
				-924
			]
		],
		[
			[
				42477,
				37994
			],
			[
				21,
				-163
			]
		],
		[
			[
				43722,
				38820
			],
			[
				-27,
				666
			],
			[
				-25,
				174
			]
		],
		[
			[
				43670,
				39660
			],
			[
				-54,
				-6
			]
		],
		[
			[
				43616,
				39654
			],
			[
				-1216,
				-153
			]
		],
		[
			[
				42400,
				39501
			],
			[
				19,
				-174
			],
			[
				58,
				-1333
			]
		],
		[
			[
				58827,
				34239
			],
			[
				773,
				-35
			]
		],
		[
			[
				54009,
				13662
			],
			[
				1166,
				-1
			],
			[
				0,
				108
			]
		],
		[
			[
				67935,
				70620
			],
			[
				189,
				-21
			]
		],
		[
			[
				68987,
				70471
			],
			[
				-13,
				1356
			]
		],
		[
			[
				77344,
				43923
			],
			[
				193,
				-21
			],
			[
				-2,
				-56
			],
			[
				200,
				-23
			],
			[
				-3,
				-102
			],
			[
				384,
				-49
			]
		],
		[
			[
				78210,
				44352
			],
			[
				-65,
				9
			],
			[
				30,
				686
			]
		],
		[
			[
				78175,
				45047
			],
			[
				-193,
				19
			]
		],
		[
			[
				77982,
				45066
			],
			[
				-15,
				-397
			],
			[
				-199,
				23
			],
			[
				-6,
				-141
			],
			[
				-197,
				15
			],
			[
				-2,
				-50
			],
			[
				-193,
				25
			]
		],
		[
			[
				77370,
				44541
			],
			[
				-26,
				-618
			]
		],
		[
			[
				70669,
				62221
			],
			[
				678,
				-82
			]
		],
		[
			[
				71347,
				62139
			],
			[
				95,
				-16
			]
		],
		[
			[
				71442,
				62123
			],
			[
				85,
				1694
			]
		],
		[
			[
				71527,
				63817
			],
			[
				-250,
				-98
			],
			[
				-351,
				-328
			]
		],
		[
			[
				70926,
				63391
			],
			[
				-70,
				-136
			],
			[
				-160,
				-126
			],
			[
				-68,
				29
			]
		],
		[
			[
				70628,
				63158
			],
			[
				-35,
				-53
			],
			[
				106,
				-181
			],
			[
				-30,
				-703
			]
		],
		[
			[
				55097,
				45523
			],
			[
				383,
				-2
			]
		],
		[
			[
				55480,
				45521
			],
			[
				-8,
				902
			]
		],
		[
			[
				55472,
				46423
			],
			[
				-33,
				0
			],
			[
				2,
				784
			]
		],
		[
			[
				55441,
				47207
			],
			[
				-765,
				1
			]
		],
		[
			[
				54676,
				47208
			],
			[
				-1,
				-1349
			]
		],
		[
			[
				40807,
				48877
			],
			[
				761,
				106
			],
			[
				186,
				50
			]
		],
		[
			[
				41714,
				50001
			],
			[
				-29,
				354
			],
			[
				-191,
				-22
			],
			[
				-11,
				223
			],
			[
				-375,
				-52
			],
			[
				-17,
				-41
			],
			[
				-363,
				-142
			]
		],
		[
			[
				46558,
				53924
			],
			[
				33,
				3
			],
			[
				-42,
				1494
			]
		],
		[
			[
				46549,
				55421
			],
			[
				-551,
				-40
			]
		],
		[
			[
				45998,
				55381
			],
			[
				-214,
				-19
			]
		],
		[
			[
				42286,
				56906
			],
			[
				1491,
				177
			]
		],
		[
			[
				43777,
				57083
			],
			[
				-66,
				1714
			]
		],
		[
			[
				42144,
				58610
			],
			[
				72,
				-1713
			],
			[
				70,
				9
			]
		],
		[
			[
				75398,
				51548
			],
			[
				38,
				-143
			],
			[
				140,
				-64
			]
		],
		[
			[
				75576,
				51341
			],
			[
				213,
				-105
			],
			[
				115,
				125
			]
		],
		[
			[
				75904,
				51361
			],
			[
				42,
				89
			]
		],
		[
			[
				75946,
				51450
			],
			[
				14,
				154
			],
			[
				68,
				99
			],
			[
				142,
				355
			]
		],
		[
			[
				76170,
				52058
			],
			[
				-249,
				437
			]
		],
		[
			[
				75921,
				52495
			],
			[
				-109,
				12
			],
			[
				-250,
				-204
			]
		],
		[
			[
				75562,
				52303
			],
			[
				5,
				-147
			],
			[
				-78,
				-93
			],
			[
				8,
				-127
			],
			[
				-96,
				-246
			],
			[
				-3,
				-142
			]
		],
		[
			[
				50910,
				41414
			],
			[
				760,
				27
			]
		],
		[
			[
				51670,
				41441
			],
			[
				1,
				0
			]
		],
		[
			[
				51671,
				41441
			],
			[
				-14,
				1341
			]
		],
		[
			[
				51657,
				42782
			],
			[
				-758,
				-27
			]
		],
		[
			[
				50891,
				42755
			],
			[
				19,
				-1341
			]
		],
		[
			[
				88435,
				48723
			],
			[
				100,
				119
			]
		],
		[
			[
				88535,
				48842
			],
			[
				-75,
				87
			],
			[
				-25,
				-206
			]
		],
		[
			[
				45025,
				53781
			],
			[
				43,
				-1341
			]
		],
		[
			[
				46899,
				38480
			],
			[
				768,
				63
			]
		],
		[
			[
				47667,
				38543
			],
			[
				25,
				2
			],
			[
				-38,
				1335
			],
			[
				23,
				1
			]
		],
		[
			[
				46714,
				41140
			],
			[
				-789,
				-62
			]
		],
		[
			[
				45959,
				39899
			],
			[
				-28,
				-170
			],
			[
				44,
				-1337
			]
		],
		[
			[
				57131,
				47843
			],
			[
				-2,
				-268
			]
		],
		[
			[
				57129,
				47575
			],
			[
				191,
				-147
			],
			[
				59,
				114
			],
			[
				100,
				-174
			],
			[
				62,
				4
			],
			[
				126,
				-288
			],
			[
				136,
				111
			]
		],
		[
			[
				57803,
				47195
			],
			[
				187,
				239
			]
		],
		[
			[
				57990,
				47434
			],
			[
				-6,
				873
			]
		],
		[
			[
				57980,
				48632
			],
			[
				-548,
				-8
			],
			[
				-296,
				-21
			]
		],
		[
			[
				57136,
				48603
			],
			[
				-5,
				-760
			]
		],
		[
			[
				24195,
				22168
			],
			[
				4,
				59
			],
			[
				157,
				166
			],
			[
				13,
				200
			],
			[
				-36,
				159
			],
			[
				201,
				243
			],
			[
				-48,
				121
			],
			[
				66,
				125
			],
			[
				-96,
				127
			],
			[
				-4,
				121
			],
			[
				85,
				84
			],
			[
				-41,
				226
			]
		],
		[
			[
				22782,
				24594
			],
			[
				-9,
				19
			],
			[
				-370,
				-1005
			]
		],
		[
			[
				22403,
				23608
			],
			[
				12,
				5
			],
			[
				165,
				-1291
			],
			[
				95,
				35
			]
		],
		[
			[
				69139,
				53235
			],
			[
				-16,
				114
			]
		],
		[
			[
				69123,
				53349
			],
			[
				-60,
				121
			],
			[
				82,
				97
			],
			[
				14,
				202
			],
			[
				81,
				166
			],
			[
				155,
				154
			]
		],
		[
			[
				69395,
				54089
			],
			[
				-28,
				498
			],
			[
				-35,
				81
			]
		],
		[
			[
				69332,
				54668
			],
			[
				-51,
				114
			],
			[
				-159,
				116
			],
			[
				-35,
				-31
			]
		],
		[
			[
				69087,
				54867
			],
			[
				-194,
				-388
			],
			[
				-138,
				-395
			],
			[
				-95,
				-6
			]
		],
		[
			[
				68660,
				54078
			],
			[
				3,
				-164
			],
			[
				62,
				-69
			],
			[
				414,
				-610
			]
		],
		[
			[
				47368,
				51619
			],
			[
				-10,
				335
			]
		],
		[
			[
				47358,
				51954
			],
			[
				13,
				338
			],
			[
				-38,
				1356
			]
		],
		[
			[
				47333,
				53648
			],
			[
				-765,
				-60
			]
		],
		[
			[
				46597,
				52580
			],
			[
				-12,
				-359
			],
			[
				21,
				-669
			],
			[
				762,
				67
			]
		],
		[
			[
				68669,
				47607
			],
			[
				641,
				-105
			]
		],
		[
			[
				69310,
				47502
			],
			[
				80,
				274
			],
			[
				-23,
				319
			],
			[
				-121,
				134
			],
			[
				-80,
				396
			]
		],
		[
			[
				69166,
				48625
			],
			[
				-450,
				60
			]
		],
		[
			[
				59084,
				11037
			],
			[
				206,
				-21
			],
			[
				33,
				87
			],
			[
				306,
				238
			],
			[
				138,
				-22
			],
			[
				1,
				165
			],
			[
				-92,
				22
			],
			[
				-18,
				122
			],
			[
				86,
				95
			],
			[
				222,
				-56
			],
			[
				80,
				172
			],
			[
				-16,
				187
			],
			[
				162,
				488
			],
			[
				147,
				-115
			],
			[
				-61,
				-255
			],
			[
				63,
				-150
			],
			[
				84,
				30
			],
			[
				226,
				-67
			],
			[
				86,
				137
			],
			[
				-5,
				212
			],
			[
				76,
				118
			],
			[
				97,
				-25
			],
			[
				46,
				112
			],
			[
				100,
				9
			]
		],
		[
			[
				61051,
				12520
			],
			[
				80,
				2484
			],
			[
				50,
				2307
			]
		],
		[
			[
				61181,
				17311
			],
			[
				-133,
				139
			],
			[
				-312,
				472
			],
			[
				128,
				301
			]
		],
		[
			[
				60864,
				18223
			],
			[
				-118,
				-155
			],
			[
				-150,
				131
			],
			[
				-15,
				240
			],
			[
				-139,
				-23
			]
		],
		[
			[
				60442,
				18416
			],
			[
				-23,
				-382
			],
			[
				-1167,
				60
			]
		],
		[
			[
				59252,
				18094
			],
			[
				-10,
				-989
			]
		],
		[
			[
				59242,
				17105
			],
			[
				-21,
				-314
			],
			[
				-24,
				-1081
			],
			[
				-10,
				-1239
			],
			[
				-32,
				1
			],
			[
				-10,
				-651
			]
		],
		[
			[
				59145,
				13821
			],
			[
				-35,
				-665
			],
			[
				-26,
				-2119
			]
		],
		[
			[
				80520,
				40022
			],
			[
				25,
				-118
			]
		],
		[
			[
				80545,
				39904
			],
			[
				282,
				23
			]
		],
		[
			[
				80827,
				39927
			],
			[
				52,
				545
			]
		],
		[
			[
				80879,
				40472
			],
			[
				-365,
				36
			]
		],
		[
			[
				80514,
				40508
			],
			[
				-24,
				-159
			],
			[
				39,
				-104
			],
			[
				-9,
				-223
			]
		],
		[
			[
				52461,
				38774
			],
			[
				134,
				-14
			],
			[
				56,
				65
			]
		],
		[
			[
				52651,
				38825
			],
			[
				125,
				31
			],
			[
				272,
				-137
			],
			[
				183,
				-161
			]
		],
		[
			[
				53231,
				38558
			],
			[
				0,
				12
			]
		],
		[
			[
				53231,
				38570
			],
			[
				-13,
				1569
			]
		],
		[
			[
				53214,
				40139
			],
			[
				-764,
				-19
			]
		],
		[
			[
				52450,
				40120
			],
			[
				11,
				-1346
			]
		],
		[
			[
				44477,
				43614
			],
			[
				1085,
				115
			]
		],
		[
			[
				45501,
				45400
			],
			[
				-1083,
				-111
			]
		],
		[
			[
				44419,
				45266
			],
			[
				58,
				-1652
			]
		],
		[
			[
				82509,
				89851
			],
			[
				-40,
				-491
			]
		],
		[
			[
				83439,
				89101
			],
			[
				106,
				1152
			]
		],
		[
			[
				83545,
				90253
			],
			[
				-834,
				210
			],
			[
				-146,
				49
			]
		],
		[
			[
				82565,
				90512
			],
			[
				-56,
				-661
			]
		],
		[
			[
				44489,
				67471
			],
			[
				962,
				79
			]
		],
		[
			[
				45451,
				67550
			],
			[
				-48,
				1678
			]
		],
		[
			[
				45403,
				69228
			],
			[
				-959,
				-82
			]
		],
		[
			[
				60319,
				31639
			],
			[
				25,
				1175
			]
		],
		[
			[
				60344,
				32814
			],
			[
				-766,
				42
			]
		],
		[
			[
				51584,
				86049
			],
			[
				737,
				-1031
			]
		],
		[
			[
				52828,
				86100
			],
			[
				-651,
				903
			]
		],
		[
			[
				52177,
				87003
			],
			[
				-66,
				-123
			],
			[
				-150,
				-116
			],
			[
				-313,
				419
			]
		],
		[
			[
				51648,
				87183
			],
			[
				-345,
				-751
			],
			[
				281,
				-383
			]
		],
		[
			[
				66043,
				30824
			],
			[
				-54,
				-1334
			]
		],
		[
			[
				65989,
				29490
			],
			[
				194,
				-26
			]
		],
		[
			[
				66822,
				31065
			],
			[
				-764,
				93
			]
		],
		[
			[
				66058,
				31158
			],
			[
				-15,
				-334
			]
		],
		[
			[
				58720,
				20456
			],
			[
				586,
				-45
			]
		],
		[
			[
				59306,
				20411
			],
			[
				10,
				675
			],
			[
				-136,
				8
			],
			[
				18,
				956
			]
		],
		[
			[
				59198,
				22050
			],
			[
				-581,
				14
			]
		],
		[
			[
				51579,
				29307
			],
			[
				186,
				10
			]
		],
		[
			[
				52155,
				29332
			],
			[
				-11,
				1341
			]
		],
		[
			[
				52144,
				30673
			],
			[
				-578,
				-16
			]
		],
		[
			[
				51566,
				30657
			],
			[
				13,
				-1350
			]
		],
		[
			[
				84948,
				48202
			],
			[
				45,
				-105
			],
			[
				193,
				-86
			],
			[
				-2,
				-133
			]
		],
		[
			[
				85418,
				49597
			],
			[
				-76,
				63
			],
			[
				-171,
				-213
			]
		],
		[
			[
				85171,
				49447
			],
			[
				-214,
				-160
			],
			[
				-248,
				-301
			]
		],
		[
			[
				84709,
				48986
			],
			[
				6,
				-353
			],
			[
				110,
				7
			],
			[
				114,
				-319
			],
			[
				9,
				-119
			]
		],
		[
			[
				60589,
				55329
			],
			[
				-3,
				-757
			],
			[
				-24,
				0
			],
			[
				-7,
				-841
			]
		],
		[
			[
				68859,
				38612
			],
			[
				187,
				-14
			],
			[
				145,
				-116
			],
			[
				74,
				-137
			]
		],
		[
			[
				69265,
				38345
			],
			[
				63,
				1179
			],
			[
				51,
				669
			]
		],
		[
			[
				59578,
				32856
			],
			[
				22,
				1348
			]
		],
		[
			[
				58827,
				34239
			],
			[
				-19,
				-1349
			]
		],
		[
			[
				73743,
				43324
			],
			[
				-48,
				-729
			]
		],
		[
			[
				73695,
				42595
			],
			[
				551,
				-119
			]
		],
		[
			[
				74246,
				42476
			],
			[
				100,
				1252
			]
		],
		[
			[
				74346,
				43728
			],
			[
				7,
				85
			],
			[
				-570,
				121
			]
		],
		[
			[
				76490,
				72884
			],
			[
				224,
				316
			]
		],
		[
			[
				76859,
				74143
			],
			[
				-287,
				61
			]
		],
		[
			[
				76572,
				74204
			],
			[
				-181,
				40
			]
		],
		[
			[
				76391,
				74244
			],
			[
				6,
				-215
			],
			[
				-88,
				-307
			],
			[
				-65,
				-31
			],
			[
				-33,
				-566
			]
		],
		[
			[
				59046,
				48222
			],
			[
				1,
				-55
			]
		],
		[
			[
				59047,
				48167
			],
			[
				768,
				18
			]
		],
		[
			[
				59815,
				49093
			],
			[
				-1,
				630
			]
		],
		[
			[
				59814,
				49723
			],
			[
				-387,
				-1
			],
			[
				0,
				112
			],
			[
				-383,
				-6
			]
		],
		[
			[
				14827,
				14687
			],
			[
				326,
				153
			],
			[
				153,
				-964
			],
			[
				64,
				31
			],
			[
				104,
				-656
			]
		],
		[
			[
				15474,
				13251
			],
			[
				160,
				44
			],
			[
				164,
				210
			],
			[
				10,
				262
			],
			[
				81,
				24
			],
			[
				93,
				-108
			],
			[
				218,
				66
			],
			[
				33,
				75
			]
		],
		[
			[
				16542,
				16231
			],
			[
				13,
				56
			],
			[
				-69,
				385
			],
			[
				47,
				246
			],
			[
				-45,
				92
			],
			[
				103,
				221
			]
		],
		[
			[
				16591,
				17231
			],
			[
				-1094,
				-505
			],
			[
				-1024,
				-490
			]
		],
		[
			[
				14473,
				16236
			],
			[
				74,
				-203
			]
		],
		[
			[
				14547,
				16033
			],
			[
				39,
				-42
			],
			[
				-45,
				-175
			],
			[
				-54,
				-6
			],
			[
				29,
				-294
			],
			[
				253,
				-96
			],
			[
				23,
				-199
			],
			[
				-43,
				-13
			],
			[
				-39,
				-332
			],
			[
				121,
				-71
			],
			[
				-4,
				-118
			]
		],
		[
			[
				63779,
				26700
			],
			[
				29,
				672
			]
		],
		[
			[
				63808,
				27372
			],
			[
				13,
				358
			]
		],
		[
			[
				63821,
				27730
			],
			[
				-197,
				-3
			],
			[
				-748,
				82
			],
			[
				-113,
				121
			],
			[
				14,
				225
			]
		],
		[
			[
				62777,
				28155
			],
			[
				-286,
				25
			],
			[
				-1,
				-34
			]
		],
		[
			[
				62490,
				28146
			],
			[
				-22,
				-643
			],
			[
				-20,
				-10
			],
			[
				-39,
				-1325
			]
		],
		[
			[
				61162,
				41648
			],
			[
				397,
				-49
			]
		],
		[
			[
				61559,
				41599
			],
			[
				18,
				1176
			]
		],
		[
			[
				61577,
				42775
			],
			[
				-672,
				39
			]
		],
		[
			[
				60905,
				42814
			],
			[
				-7,
				-168
			]
		],
		[
			[
				60898,
				42646
			],
			[
				-23,
				-966
			]
		],
		[
			[
				79285,
				73402
			],
			[
				-28,
				-411
			]
		],
		[
			[
				79257,
				72991
			],
			[
				29,
				-54
			],
			[
				210,
				-8
			],
			[
				49,
				-142
			]
		],
		[
			[
				79545,
				72787
			],
			[
				44,
				550
			],
			[
				392,
				-99
			]
		],
		[
			[
				79981,
				73238
			],
			[
				34,
				435
			],
			[
				61,
				-13
			],
			[
				25,
				337
			]
		],
		[
			[
				80101,
				73997
			],
			[
				-57,
				14
			],
			[
				31,
				401
			]
		],
		[
			[
				80075,
				74412
			],
			[
				-355,
				83
			],
			[
				-53,
				-131
			],
			[
				-223,
				48
			],
			[
				-6,
				-54
			],
			[
				-343,
				68
			]
		],
		[
			[
				79095,
				74426
			],
			[
				-25,
				-195
			]
		],
		[
			[
				73943,
				24743
			],
			[
				895,
				-212
			]
		],
		[
			[
				74838,
				24531
			],
			[
				29,
				656
			],
			[
				-48,
				41
			],
			[
				-80,
				251
			],
			[
				-131,
				25
			],
			[
				-50,
				191
			],
			[
				-7,
				248
			]
		],
		[
			[
				74551,
				25943
			],
			[
				-505,
				119
			]
		],
		[
			[
				74046,
				26062
			],
			[
				-103,
				-1319
			]
		],
		[
			[
				66693,
				51698
			],
			[
				775,
				-103
			]
		],
		[
			[
				67510,
				52611
			],
			[
				-581,
				85
			]
		],
		[
			[
				66929,
				52696
			],
			[
				-197,
				10
			]
		],
		[
			[
				66732,
				52706
			],
			[
				-39,
				-1008
			]
		],
		[
			[
				89866,
				26512
			],
			[
				2,
				-62
			],
			[
				231,
				-429
			],
			[
				-45,
				-213
			]
		],
		[
			[
				90054,
				25808
			],
			[
				150,
				28
			],
			[
				98,
				103
			],
			[
				74,
				-84
			],
			[
				44,
				219
			],
			[
				175,
				215
			]
		],
		[
			[
				90595,
				26289
			],
			[
				-1,
				16
			],
			[
				-563,
				404
			]
		],
		[
			[
				90031,
				26709
			],
			[
				3,
				68
			],
			[
				-141,
				49
			],
			[
				-69,
				-103
			],
			[
				69,
				-66
			],
			[
				-27,
				-145
			]
		],
		[
			[
				70039,
				64996
			],
			[
				192,
				-20
			]
		],
		[
			[
				70231,
				64976
			],
			[
				767,
				-100
			]
		],
		[
			[
				70998,
				64876
			],
			[
				61,
				1178
			],
			[
				-72,
				8
			]
		],
		[
			[
				70097,
				66166
			],
			[
				-58,
				-1170
			]
		],
		[
			[
				66461,
				71925
			],
			[
				-37,
				-961
			]
		],
		[
			[
				66424,
				70964
			],
			[
				-7,
				-174
			],
			[
				764,
				-96
			]
		],
		[
			[
				67240,
				72057
			],
			[
				-9,
				1
			]
		],
		[
			[
				67231,
				72058
			],
			[
				-762,
				85
			],
			[
				-8,
				-218
			]
		],
		[
			[
				49119,
				23839
			],
			[
				1337,
				82
			]
		],
		[
			[
				50456,
				23921
			],
			[
				8,
				0
			]
		],
		[
			[
				50464,
				23921
			],
			[
				-21,
				1327
			]
		],
		[
			[
				50443,
				25248
			],
			[
				-940,
				-52
			]
		],
		[
			[
				49503,
				25196
			],
			[
				-412,
				-26
			]
		],
		[
			[
				49091,
				25170
			],
			[
				28,
				-1331
			]
		],
		[
			[
				51417,
				48146
			],
			[
				-4,
				337
			]
		],
		[
			[
				50455,
				48446
			],
			[
				17,
				-1006
			],
			[
				-10,
				-5
			]
		],
		[
			[
				50462,
				47435
			],
			[
				4,
				-336
			]
		],
		[
			[
				72519,
				37599
			],
			[
				18,
				325
			],
			[
				-48,
				10
			],
			[
				49,
				665
			]
		],
		[
			[
				72026,
				38708
			],
			[
				-65,
				13
			],
			[
				-13,
				-171
			]
		],
		[
			[
				13534,
				41278
			],
			[
				184,
				389
			],
			[
				165,
				-108
			],
			[
				170,
				15
			],
			[
				-19,
				195
			],
			[
				81,
				188
			]
		],
		[
			[
				14115,
				41957
			],
			[
				-39,
				158
			],
			[
				46,
				202
			],
			[
				-64,
				132
			],
			[
				69,
				169
			],
			[
				108,
				138
			],
			[
				75,
				201
			],
			[
				44,
				-43
			],
			[
				108,
				157
			],
			[
				43,
				165
			],
			[
				-45,
				362
			],
			[
				148,
				314
			],
			[
				-51,
				356
			],
			[
				-141,
				158
			]
		],
		[
			[
				14416,
				44426
			],
			[
				-46,
				-174
			]
		],
		[
			[
				14370,
				44252
			],
			[
				-82,
				-281
			],
			[
				-74,
				-137
			],
			[
				-107,
				-35
			],
			[
				-122,
				-181
			],
			[
				-256,
				281
			],
			[
				-223,
				62
			],
			[
				-87,
				-99
			],
			[
				-122,
				-16
			],
			[
				-208,
				-353
			],
			[
				-103,
				139
			],
			[
				-73,
				-98
			],
			[
				-31,
				87
			],
			[
				-148,
				90
			],
			[
				-149,
				-35
			],
			[
				29,
				246
			],
			[
				-49,
				39
			],
			[
				-82,
				-159
			],
			[
				-24,
				187
			]
		],
		[
			[
				12459,
				43989
			],
			[
				-334,
				-940
			]
		],
		[
			[
				12125,
				43049
			],
			[
				43,
				-84
			],
			[
				195,
				-166
			],
			[
				69,
				-133
			],
			[
				-13,
				-125
			],
			[
				117,
				-20
			],
			[
				77,
				-164
			],
			[
				408,
				-659
			],
			[
				216,
				-313
			],
			[
				297,
				-107
			]
		],
		[
			[
				48448,
				27819
			],
			[
				80,
				257
			],
			[
				186,
				-11
			],
			[
				179,
				143
			],
			[
				68,
				-3
			],
			[
				7,
				-173
			],
			[
				-135,
				-99
			],
			[
				56,
				-170
			]
		],
		[
			[
				48889,
				27763
			],
			[
				136,
				105
			]
		],
		[
			[
				49025,
				27868
			],
			[
				54,
				166
			],
			[
				-39,
				184
			],
			[
				78,
				184
			],
			[
				114,
				38
			],
			[
				133,
				206
			],
			[
				-8,
				234
			]
		],
		[
			[
				49357,
				28880
			],
			[
				3,
				161
			],
			[
				68,
				127
			],
			[
				-99,
				255
			],
			[
				-72,
				64
			],
			[
				0,
				266
			],
			[
				-81,
				66
			],
			[
				-7,
				119
			],
			[
				135,
				4
			],
			[
				51,
				295
			],
			[
				87,
				196
			],
			[
				-26,
				119
			]
		],
		[
			[
				49416,
				30552
			],
			[
				0,
				1
			]
		],
		[
			[
				49416,
				30553
			],
			[
				-381,
				-24
			]
		],
		[
			[
				49035,
				30529
			],
			[
				-143,
				-56
			],
			[
				19,
				-882
			],
			[
				-110,
				-79
			],
			[
				-88,
				176
			],
			[
				-79,
				1
			],
			[
				-147,
				122
			],
			[
				-18,
				-90
			],
			[
				-135,
				40
			],
			[
				-42,
				-72
			],
			[
				-168,
				62
			],
			[
				-191,
				-122
			]
		],
		[
			[
				47933,
				29629
			],
			[
				-80,
				60
			],
			[
				-93,
				-85
			]
		],
		[
			[
				47760,
				29604
			],
			[
				12,
				-499
			],
			[
				-42,
				-3
			],
			[
				31,
				-1233
			]
		],
		[
			[
				47761,
				27869
			],
			[
				3,
				-99
			],
			[
				684,
				49
			]
		],
		[
			[
				11257,
				57627
			],
			[
				165,
				169
			],
			[
				115,
				35
			],
			[
				102,
				191
			],
			[
				118,
				65
			],
			[
				65,
				-104
			],
			[
				121,
				113
			],
			[
				-93,
				114
			],
			[
				-188,
				-43
			],
			[
				-118,
				53
			],
			[
				-260,
				-196
			],
			[
				24,
				-195
			],
			[
				-51,
				-202
			]
		],
		[
			[
				10630,
				57649
			],
			[
				146,
				40
			],
			[
				237,
				-5
			],
			[
				-20,
				155
			],
			[
				105,
				92
			],
			[
				-14,
				156
			],
			[
				-290,
				57
			],
			[
				-71,
				-124
			],
			[
				-93,
				-371
			]
		],
		[
			[
				10292,
				57356
			],
			[
				175,
				-64
			],
			[
				78,
				233
			],
			[
				-104,
				-13
			],
			[
				-149,
				-156
			]
		],
		[
			[
				12505,
				54894
			],
			[
				54,
				23
			]
		],
		[
			[
				12559,
				54917
			],
			[
				-240,
				1635
			],
			[
				-40,
				220
			],
			[
				-72,
				76
			]
		],
		[
			[
				12207,
				56848
			],
			[
				-124,
				-197
			],
			[
				-198,
				-97
			],
			[
				-77,
				49
			],
			[
				-140,
				-150
			],
			[
				-145,
				-28
			],
			[
				-209,
				-297
			],
			[
				-228,
				-153
			],
			[
				-288,
				-103
			],
			[
				-310,
				-63
			],
			[
				-28,
				-312
			],
			[
				-204,
				-323
			],
			[
				142,
				-379
			],
			[
				-26,
				-266
			],
			[
				99,
				-302
			],
			[
				-32,
				-247
			],
			[
				-40,
				-30
			],
			[
				79,
				-244
			]
		],
		[
			[
				70345,
				17786
			],
			[
				-44,
				-702
			]
		],
		[
			[
				70301,
				17084
			],
			[
				109,
				15
			],
			[
				319,
				-35
			],
			[
				160,
				-52
			],
			[
				354,
				-357
			]
		],
		[
			[
				71243,
				16655
			],
			[
				129,
				1932
			]
		],
		[
			[
				71372,
				18587
			],
			[
				-966,
				185
			]
		],
		[
			[
				70406,
				18772
			],
			[
				-61,
				-986
			]
		],
		[
			[
				43030,
				50162
			],
			[
				-67,
				1706
			],
			[
				-13,
				0
			],
			[
				-28,
				693
			]
		],
		[
			[
				42922,
				52561
			],
			[
				-574,
				-71
			]
		],
		[
			[
				42348,
				52490
			],
			[
				-574,
				-75
			]
		],
		[
			[
				41774,
				52415
			],
			[
				39,
				-689
			],
			[
				78,
				-1703
			]
		],
		[
			[
				62554,
				52403
			],
			[
				386,
				-25
			],
			[
				5,
				336
			],
			[
				276,
				-13
			]
		],
		[
			[
				63221,
				52701
			],
			[
				9,
				421
			]
		],
		[
			[
				63230,
				53122
			],
			[
				-276,
				6
			],
			[
				7,
				338
			],
			[
				181,
				-3
			],
			[
				8,
				334
			]
		],
		[
			[
				62083,
				53174
			],
			[
				-14,
				-742
			],
			[
				485,
				-29
			]
		],
		[
			[
				45403,
				69228
			],
			[
				-51,
				1641
			]
		],
		[
			[
				45352,
				70869
			],
			[
				-251,
				2
			]
		],
		[
			[
				48269,
				71143
			],
			[
				699,
				41
			]
		],
		[
			[
				48968,
				71184
			],
			[
				-33,
				1700
			]
		],
		[
			[
				48901,
				72882
			],
			[
				-932,
				-56
			]
		],
		[
			[
				47969,
				72826
			],
			[
				38,
				-1699
			]
		],
		[
			[
				50951,
				61950
			],
			[
				142,
				5
			],
			[
				111,
				126
			],
			[
				168,
				-55
			],
			[
				51,
				104
			],
			[
				112,
				-36
			],
			[
				62,
				86
			],
			[
				119,
				-43
			]
		],
		[
			[
				51716,
				62137
			],
			[
				-13,
				1854
			]
		],
		[
			[
				51703,
				63991
			],
			[
				-7,
				669
			]
		],
		[
			[
				50931,
				63965
			],
			[
				20,
				-1338
			],
			[
				0,
				-677
			]
		],
		[
			[
				58917,
				54023
			],
			[
				772,
				4
			]
		],
		[
			[
				59881,
				54025
			],
			[
				4,
				560
			],
			[
				27,
				-1
			],
			[
				6,
				699
			]
		],
		[
			[
				59918,
				55283
			],
			[
				-960,
				10
			]
		],
		[
			[
				42922,
				52561
			],
			[
				1234,
				140
			]
		],
		[
			[
				44156,
				52701
			],
			[
				-36,
				982
			]
		],
		[
			[
				44120,
				53683
			],
			[
				-58,
				1526
			]
		],
		[
			[
				44062,
				55209
			],
			[
				-1157,
				-141
			],
			[
				-535,
				-85
			]
		],
		[
			[
				42370,
				54983
			],
			[
				-148,
				-19
			]
		],
		[
			[
				42222,
				54964
			],
			[
				70,
				-1468
			],
			[
				14,
				2
			],
			[
				42,
				-1008
			]
		],
		[
			[
				80931,
				79779
			],
			[
				203,
				-179
			],
			[
				67,
				-293
			],
			[
				64,
				-40
			],
			[
				125,
				-324
			],
			[
				-2,
				-128
			]
		],
		[
			[
				81388,
				78815
			],
			[
				178,
				-45
			]
		],
		[
			[
				81566,
				78770
			],
			[
				136,
				1497
			],
			[
				3,
				109
			]
		],
		[
			[
				81705,
				80376
			],
			[
				-12,
				5
			]
		],
		[
			[
				81693,
				80381
			],
			[
				-64,
				-207
			],
			[
				-60,
				-12
			],
			[
				-63,
				-189
			],
			[
				-114,
				-18
			],
			[
				-156,
				58
			],
			[
				-133,
				-105
			],
			[
				-123,
				-3
			],
			[
				-49,
				-126
			]
		],
		[
			[
				61912,
				33716
			],
			[
				774,
				-72
			]
		],
		[
			[
				62686,
				33644
			],
			[
				44,
				1344
			]
		],
		[
			[
				61964,
				35050
			],
			[
				-18,
				-664
			],
			[
				-18,
				1
			],
			[
				-16,
				-671
			]
		],
		[
			[
				47506,
				81334
			],
			[
				-26,
				828
			]
		],
		[
			[
				47480,
				82162
			],
			[
				-405,
				-59
			],
			[
				-47,
				213
			],
			[
				-37,
				491
			],
			[
				-42,
				50
			],
			[
				-34,
				394
			],
			[
				13,
				216
			],
			[
				-73,
				41
			],
			[
				87,
				138
			],
			[
				0,
				204
			]
		],
		[
			[
				46942,
				83850
			],
			[
				-190,
				-13
			]
		],
		[
			[
				46752,
				83837
			],
			[
				-1140,
				-93
			]
		],
		[
			[
				45612,
				83744
			],
			[
				72,
				-2539
			]
		],
		[
			[
				70620,
				26725
			],
			[
				-551,
				53
			]
		],
		[
			[
				70069,
				26778
			],
			[
				149,
				-669
			],
			[
				16,
				-669
			]
		],
		[
			[
				66344,
				35153
			],
			[
				384,
				-54
			]
		],
		[
			[
				66974,
				36742
			],
			[
				15,
				339
			]
		],
		[
			[
				66433,
				37165
			],
			[
				-48,
				-1011
			]
		],
		[
			[
				66385,
				36154
			],
			[
				-41,
				-1001
			]
		],
		[
			[
				74246,
				42476
			],
			[
				100,
				-26
			]
		],
		[
			[
				74346,
				42450
			],
			[
				452,
				-108
			],
			[
				11,
				143
			],
			[
				180,
				-17
			]
		],
		[
			[
				74993,
				42582
			],
			[
				-63,
				60
			],
			[
				38,
				1005
			]
		],
		[
			[
				74540,
				43693
			],
			[
				-194,
				35
			]
		],
		[
			[
				54183,
				71249
			],
			[
				6,
				-1425
			]
		],
		[
			[
				54189,
				69824
			],
			[
				810,
				-220
			]
		],
		[
			[
				54999,
				69604
			],
			[
				-6,
				732
			]
		],
		[
			[
				54993,
				70336
			],
			[
				0,
				922
			]
		],
		[
			[
				54993,
				71258
			],
			[
				-154,
				-1
			],
			[
				-78,
				398
			],
			[
				98,
				151
			]
		],
		[
			[
				54859,
				71806
			],
			[
				-266,
				-5
			]
		],
		[
			[
				54593,
				71801
			],
			[
				-411,
				-14
			]
		],
		[
			[
				49409,
				38657
			],
			[
				751,
				40
			]
		],
		[
			[
				50160,
				38697
			],
			[
				12,
				0
			]
		],
		[
			[
				49376,
				39993
			],
			[
				33,
				-1336
			]
		],
		[
			[
				74259,
				41391
			],
			[
				359,
				-88
			],
			[
				333,
				-9
			]
		],
		[
			[
				74346,
				42450
			],
			[
				-87,
				-1059
			]
		],
		[
			[
				50371,
				30606
			],
			[
				621,
				30
			]
		],
		[
			[
				50992,
				30636
			],
			[
				332,
				15
			]
		],
		[
			[
				51324,
				30651
			],
			[
				-6,
				1158
			]
		],
		[
			[
				51318,
				31809
			],
			[
				-914,
				-717
			],
			[
				-43,
				10
			],
			[
				10,
				-496
			]
		],
		[
			[
				72399,
				42736
			],
			[
				-32,
				-504
			]
		],
		[
			[
				72367,
				42232
			],
			[
				609,
				-116
			]
		],
		[
			[
				72976,
				42116
			],
			[
				40,
				274
			]
		],
		[
			[
				73016,
				42390
			],
			[
				35,
				500
			],
			[
				-34,
				8
			],
			[
				22,
				326
			]
		],
		[
			[
				72903,
				43254
			],
			[
				-499,
				99
			]
		],
		[
			[
				12678,
				97489
			],
			[
				67,
				-76
			],
			[
				-9,
				284
			],
			[
				-62,
				-20
			],
			[
				4,
				-188
			]
		],
		[
			[
				12397,
				97795
			],
			[
				79,
				-153
			],
			[
				-37,
				-93
			],
			[
				62,
				-101
			],
			[
				107,
				47
			],
			[
				-29,
				107
			],
			[
				-182,
				193
			]
		],
		[
			[
				12157,
				97232
			],
			[
				72,
				-101
			],
			[
				166,
				95
			],
			[
				-15,
				134
			],
			[
				-78,
				-137
			],
			[
				-13,
				286
			],
			[
				-67,
				-120
			],
			[
				-52,
				93
			],
			[
				-13,
				-250
			]
		],
		[
			[
				11676,
				97411
			],
			[
				39,
				-75
			],
			[
				105,
				122
			],
			[
				-77,
				52
			],
			[
				-67,
				-99
			]
		],
		[
			[
				11193,
				98205
			],
			[
				7,
				-58
			],
			[
				201,
				186
			],
			[
				-53,
				52
			],
			[
				-155,
				-180
			]
		],
		[
			[
				13113,
				95377
			],
			[
				-66,
				175
			],
			[
				-105,
				-17
			],
			[
				-6,
				121
			],
			[
				-136,
				-19
			],
			[
				-6,
				115
			],
			[
				-131,
				-16
			],
			[
				-14,
				226
			],
			[
				41,
				7
			],
			[
				-22,
				453
			],
			[
				-34,
				122
			],
			[
				133,
				19
			],
			[
				-17,
				341
			]
		],
		[
			[
				12750,
				96904
			],
			[
				-79,
				73
			],
			[
				72,
				-316
			],
			[
				-108,
				-54
			],
			[
				-96,
				146
			],
			[
				-155,
				135
			],
			[
				-73,
				160
			],
			[
				-141,
				-94
			],
			[
				-175,
				196
			],
			[
				-122,
				-30
			],
			[
				86,
				-328
			],
			[
				-111,
				-5
			],
			[
				-67,
				296
			],
			[
				-97,
				212
			],
			[
				-62,
				-41
			],
			[
				27,
				166
			],
			[
				-91,
				-84
			],
			[
				-45,
				172
			],
			[
				-96,
				-64
			],
			[
				2,
				-270
			],
			[
				-94,
				23
			],
			[
				52,
				124
			],
			[
				-12,
				221
			],
			[
				-127,
				31
			],
			[
				-84,
				-269
			],
			[
				-73,
				76
			],
			[
				74,
				152
			],
			[
				-166,
				152
			],
			[
				82,
				55
			],
			[
				43,
				164
			],
			[
				-116,
				-146
			],
			[
				-125,
				154
			],
			[
				-223,
				-68
			],
			[
				-121,
				89
			],
			[
				-18,
				84
			],
			[
				-138,
				62
			],
			[
				-89,
				-60
			],
			[
				-27,
				-222
			],
			[
				119,
				-83
			],
			[
				89,
				-232
			],
			[
				319,
				-170
			],
			[
				161,
				27
			],
			[
				77,
				247
			],
			[
				29,
				-326
			],
			[
				197,
				-30
			],
			[
				156,
				-328
			],
			[
				167,
				-282
			],
			[
				214,
				-226
			],
			[
				286,
				-107
			],
			[
				156,
				5
			],
			[
				-66,
				263
			],
			[
				52,
				123
			],
			[
				18,
				-196
			],
			[
				128,
				51
			],
			[
				88,
				147
			],
			[
				29,
				-84
			],
			[
				-149,
				-228
			],
			[
				110,
				-343
			],
			[
				266,
				-358
			],
			[
				125,
				-86
			],
			[
				260,
				-267
			],
			[
				25,
				64
			]
		],
		[
			[
				9884,
				98128
			],
			[
				28,
				-56
			],
			[
				93,
				143
			],
			[
				-78,
				113
			],
			[
				-43,
				-200
			]
		],
		[
			[
				9682,
				98209
			],
			[
				113,
				-63
			],
			[
				74,
				186
			],
			[
				-177,
				16
			],
			[
				-10,
				-139
			]
		],
		[
			[
				77599,
				64895
			],
			[
				301,
				-358
			]
		],
		[
			[
				77900,
				64537
			],
			[
				198,
				115
			]
		],
		[
			[
				78098,
				64652
			],
			[
				-23,
				190
			],
			[
				84,
				164
			],
			[
				-47,
				230
			]
		],
		[
			[
				78112,
				65236
			],
			[
				-95,
				-7
			],
			[
				-196,
				-233
			],
			[
				-159,
				20
			],
			[
				-63,
				-121
			]
		],
		[
			[
				72431,
				44644
			],
			[
				-67,
				-942
			]
		],
		[
			[
				48472,
				15849
			],
			[
				121,
				9
			]
		],
		[
			[
				49435,
				15915
			],
			[
				-29,
				1317
			],
			[
				53,
				2
			],
			[
				-33,
				1329
			]
		],
		[
			[
				48709,
				18510
			],
			[
				-255,
				-20
			]
		],
		[
			[
				48454,
				18490
			],
			[
				42,
				-1323
			],
			[
				-60,
				-5
			],
			[
				36,
				-1313
			]
		],
		[
			[
				46654,
				87557
			],
			[
				1384,
				123
			]
		],
		[
			[
				48033,
				89343
			],
			[
				-1422,
				-70
			]
		],
		[
			[
				46611,
				89273
			],
			[
				43,
				-1716
			]
		],
		[
			[
				64716,
				31285
			],
			[
				48,
				-132
			],
			[
				114,
				-87
			],
			[
				15,
				-136
			]
		],
		[
			[
				64893,
				30930
			],
			[
				577,
				-71
			],
			[
				2,
				49
			],
			[
				571,
				-84
			]
		],
		[
			[
				66058,
				31158
			],
			[
				50,
				1344
			]
		],
		[
			[
				66108,
				32502
			],
			[
				-577,
				80
			]
		],
		[
			[
				65531,
				32582
			],
			[
				-2,
				-44
			],
			[
				-763,
				84
			]
		],
		[
			[
				64766,
				32622
			],
			[
				-50,
				-1337
			]
		],
		[
			[
				22952,
				51190
			],
			[
				67,
				-596
			]
		],
		[
			[
				23019,
				50594
			],
			[
				2001,
				637
			]
		],
		[
			[
				25020,
				51231
			],
			[
				628,
				190
			]
		],
		[
			[
				23661,
				56582
			],
			[
				-493,
				4588
			]
		],
		[
			[
				21130,
				57818
			],
			[
				83,
				-258
			],
			[
				-59,
				-105
			],
			[
				129,
				-83
			],
			[
				31,
				-125
			],
			[
				24,
				-629
			],
			[
				-18,
				-338
			],
			[
				-54,
				-333
			],
			[
				96,
				-444
			],
			[
				-46,
				-175
			],
			[
				28,
				-599
			],
			[
				110,
				-233
			],
			[
				-82,
				-300
			],
			[
				39,
				-317
			],
			[
				-19,
				-239
			],
			[
				42,
				-54
			],
			[
				221,
				-26
			],
			[
				87,
				-54
			],
			[
				106,
				38
			],
			[
				3,
				84
			],
			[
				238,
				28
			],
			[
				87,
				297
			],
			[
				-21,
				59
			],
			[
				113,
				209
			],
			[
				153,
				14
			],
			[
				218,
				-544
			],
			[
				43,
				-39
			],
			[
				86,
				-746
			],
			[
				184,
				-1716
			]
		],
		[
			[
				70762,
				77659
			],
			[
				828,
				-135
			]
		],
		[
			[
				71590,
				77524
			],
			[
				-206,
				314
			],
			[
				-44,
				252
			],
			[
				40,
				184
			],
			[
				-20,
				296
			],
			[
				81,
				66
			],
			[
				49,
				396
			],
			[
				90,
				173
			],
			[
				127,
				100
			],
			[
				61,
				120
			],
			[
				-18,
				329
			],
			[
				-137,
				119
			],
			[
				-20,
				153
			],
			[
				148,
				-17
			],
			[
				399,
				-206
			],
			[
				48,
				43
			]
		],
		[
			[
				72188,
				79846
			],
			[
				-444,
				236
			],
			[
				-213,
				81
			],
			[
				-54,
				-22
			],
			[
				-419,
				231
			]
		],
		[
			[
				22041,
				23472
			],
			[
				362,
				136
			]
		],
		[
			[
				22097,
				26224
			],
			[
				-59,
				-57
			],
			[
				-92,
				-318
			],
			[
				36,
				-169
			],
			[
				-61,
				-245
			],
			[
				-128,
				-23
			]
		],
		[
			[
				21793,
				25412
			],
			[
				79,
				-635
			],
			[
				63,
				23
			],
			[
				84,
				-656
			],
			[
				-62,
				-23
			],
			[
				84,
				-649
			]
		],
		[
			[
				51291,
				12717
			],
			[
				584,
				23
			]
		],
		[
			[
				51875,
				12740
			],
			[
				-8,
				659
			],
			[
				33,
				1
			],
			[
				-16,
				1322
			]
		],
		[
			[
				50948,
				14685
			],
			[
				-38,
				-2
			],
			[
				10,
				-660
			]
		],
		[
			[
				50920,
				14023
			],
			[
				4,
				-260
			]
		],
		[
			[
				50924,
				13763
			],
			[
				6,
				-402
			],
			[
				155,
				7
			],
			[
				11,
				-658
			],
			[
				195,
				7
			]
		],
		[
			[
				42523,
				68960
			],
			[
				960,
				106
			]
		],
		[
			[
				43421,
				70716
			],
			[
				-246,
				-24
			]
		],
		[
			[
				63301,
				41327
			],
			[
				192,
				-4
			]
		],
		[
			[
				63493,
				41323
			],
			[
				579,
				-26
			]
		],
		[
			[
				64072,
				41297
			],
			[
				39,
				1354
			]
		],
		[
			[
				63334,
				42694
			],
			[
				-33,
				-1367
			]
		],
		[
			[
				58813,
				39618
			],
			[
				-387,
				13
			]
		],
		[
			[
				53953,
				43170
			],
			[
				671,
				4
			]
		],
		[
			[
				54624,
				43174
			],
			[
				96,
				2
			]
		],
		[
			[
				54720,
				43176
			],
			[
				-1,
				1006
			]
		],
		[
			[
				54331,
				44181
			],
			[
				-381,
				-3
			]
		],
		[
			[
				53950,
				44178
			],
			[
				3,
				-1008
			]
		],
		[
			[
				72890,
				40960
			],
			[
				22,
				-4
			],
			[
				-39,
				-723
			]
		],
		[
			[
				72873,
				40233
			],
			[
				222,
				-47
			]
		],
		[
			[
				73095,
				40186
			],
			[
				444,
				-104
			]
		],
		[
			[
				73594,
				40923
			],
			[
				11,
				163
			]
		],
		[
			[
				73605,
				41086
			],
			[
				-695,
				151
			]
		],
		[
			[
				72910,
				41237
			],
			[
				-20,
				-277
			]
		],
		[
			[
				23190,
				3635
			],
			[
				574,
				227
			]
		],
		[
			[
				23764,
				3862
			],
			[
				-74,
				563
			]
		],
		[
			[
				23690,
				4425
			],
			[
				-234,
				1753
			],
			[
				-168,
				1198
			]
		],
		[
			[
				23288,
				7376
			],
			[
				-580,
				-231
			]
		],
		[
			[
				22708,
				7145
			],
			[
				42,
				-324
			],
			[
				-95,
				-38
			],
			[
				263,
				-1921
			],
			[
				-189,
				-73
			],
			[
				43,
				-320
			],
			[
				96,
				38
			],
			[
				47,
				-347
			],
			[
				94,
				37
			],
			[
				42,
				-316
			],
			[
				100,
				38
			],
			[
				39,
				-284
			]
		],
		[
			[
				63152,
				36298
			],
			[
				22,
				672
			]
		],
		[
			[
				63174,
				36970
			],
			[
				18,
				670
			]
		],
		[
			[
				63192,
				37640
			],
			[
				-773,
				63
			]
		],
		[
			[
				62419,
				37703
			],
			[
				-28,
				-1006
			]
		],
		[
			[
				32866,
				15613
			],
			[
				249,
				-45
			],
			[
				131,
				44
			],
			[
				96,
				-40
			],
			[
				208,
				281
			],
			[
				113,
				26
			]
		],
		[
			[
				33663,
				15879
			],
			[
				543,
				135
			]
		],
		[
			[
				34206,
				16014
			],
			[
				-63,
				766
			],
			[
				-23,
				-6
			],
			[
				-84,
				1023
			],
			[
				-385,
				-93
			]
		],
		[
			[
				33651,
				17704
			],
			[
				-962,
				-248
			]
		],
		[
			[
				32689,
				17456
			],
			[
				91,
				-1015
			],
			[
				12,
				3
			],
			[
				74,
				-831
			]
		],
		[
			[
				65322,
				64642
			],
			[
				870,
				-94
			]
		],
		[
			[
				66246,
				65939
			],
			[
				4,
				112
			],
			[
				-385,
				39
			]
		],
		[
			[
				65865,
				66090
			],
			[
				-373,
				57
			]
		],
		[
			[
				65329,
				64809
			],
			[
				-7,
				-167
			]
		],
		[
			[
				74473,
				46807
			],
			[
				192,
				-97
			]
		],
		[
			[
				74665,
				46710
			],
			[
				299,
				-328
			]
		],
		[
			[
				74964,
				46382
			],
			[
				16,
				181
			]
		],
		[
			[
				74980,
				46563
			],
			[
				-8,
				21
			],
			[
				120,
				910
			]
		],
		[
			[
				75092,
				47494
			],
			[
				-329,
				152
			],
			[
				-75,
				137
			],
			[
				-51,
				-41
			]
		],
		[
			[
				74637,
				47742
			],
			[
				-164,
				-935
			]
		],
		[
			[
				42899,
				74020
			],
			[
				143,
				16
			]
		],
		[
			[
				43801,
				75794
			],
			[
				-967,
				-105
			]
		],
		[
			[
				42834,
				75689
			],
			[
				65,
				-1669
			]
		],
		[
			[
				44444,
				9103
			],
			[
				1359,
				161
			]
		],
		[
			[
				45803,
				9264
			],
			[
				-27,
				723
			]
		],
		[
			[
				45776,
				9987
			],
			[
				-194,
				-22
			],
			[
				-13,
				328
			],
			[
				-121,
				-13
			],
			[
				-26,
				656
			]
		],
		[
			[
				45422,
				10936
			],
			[
				-972,
				-115
			]
		],
		[
			[
				44450,
				10821
			],
			[
				14,
				-328
			]
		],
		[
			[
				44464,
				10493
			],
			[
				14,
				-329
			],
			[
				-81,
				-10
			],
			[
				47,
				-1051
			]
		],
		[
			[
				52944,
				26690
			],
			[
				-12,
				1330
			]
		],
		[
			[
				52932,
				28020
			],
			[
				-384,
				-5
			]
		],
		[
			[
				51783,
				27992
			],
			[
				-7,
				0
			]
		],
		[
			[
				59396,
				69243
			],
			[
				1,
				-1345
			],
			[
				19,
				0
			],
			[
				0,
				-508
			]
		],
		[
			[
				59569,
				67382
			],
			[
				136,
				225
			],
			[
				24,
				199
			],
			[
				132,
				56
			],
			[
				75,
				107
			],
			[
				72,
				-63
			],
			[
				68,
				145
			]
		],
		[
			[
				60076,
				68051
			],
			[
				0,
				1248
			]
		],
		[
			[
				60076,
				69299
			],
			[
				-194,
				1
			],
			[
				1,
				57
			],
			[
				-268,
				-3
			]
		],
		[
			[
				59615,
				69354
			],
			[
				-219,
				0
			],
			[
				0,
				-111
			]
		],
		[
			[
				64025,
				39618
			],
			[
				-9,
				-336
			]
		],
		[
			[
				64795,
				39883
			],
			[
				36,
				1009
			]
		],
		[
			[
				64831,
				40892
			],
			[
				-768,
				71
			]
		],
		[
			[
				64063,
				40963
			],
			[
				-38,
				-1345
			]
		],
		[
			[
				48330,
				57126
			],
			[
				551,
				25
			],
			[
				18,
				-865
			]
		],
		[
			[
				48899,
				56286
			],
			[
				76,
				138
			],
			[
				196,
				19
			],
			[
				134,
				268
			],
			[
				81,
				295
			],
			[
				91,
				164
			],
			[
				-7,
				355
			]
		],
		[
			[
				49453,
				58857
			],
			[
				-761,
				-54
			]
		],
		[
			[
				48692,
				58803
			],
			[
				-382,
				-23
			],
			[
				-9,
				-319
			],
			[
				29,
				-1335
			]
		],
		[
			[
				69042,
				65994
			],
			[
				11,
				-908
			]
		],
		[
			[
				69053,
				65086
			],
			[
				986,
				-90
			]
		],
		[
			[
				69040,
				66103
			],
			[
				2,
				-109
			]
		],
		[
			[
				66286,
				33838
			],
			[
				268,
				-23
			]
		],
		[
			[
				66344,
				35153
			],
			[
				-58,
				-1315
			]
		],
		[
			[
				85763,
				52617
			],
			[
				232,
				-79
			]
		],
		[
			[
				85995,
				52538
			],
			[
				180,
				874
			],
			[
				-35,
				169
			]
		],
		[
			[
				86140,
				53581
			],
			[
				-41,
				137
			],
			[
				-71,
				21
			],
			[
				-31,
				262
			],
			[
				-152,
				31
			]
		],
		[
			[
				85845,
				54032
			],
			[
				-24,
				-23
			],
			[
				-117,
				-1027
			],
			[
				-1,
				-236
			],
			[
				60,
				-129
			]
		],
		[
			[
				34406,
				91804
			],
			[
				72,
				-328
			],
			[
				154,
				-99
			],
			[
				97,
				106
			],
			[
				150,
				419
			],
			[
				187,
				-96
			],
			[
				128,
				-117
			],
			[
				199,
				69
			],
			[
				0,
				53
			],
			[
				205,
				214
			],
			[
				36,
				129
			],
			[
				236,
				119
			],
			[
				35,
				277
			],
			[
				-139,
				261
			],
			[
				-187,
				118
			],
			[
				-106,
				-31
			],
			[
				-230,
				175
			],
			[
				-158,
				30
			],
			[
				-137,
				-134
			],
			[
				-46,
				-638
			],
			[
				-88,
				-67
			],
			[
				-67,
				80
			],
			[
				-197,
				-162
			],
			[
				-99,
				-188
			],
			[
				-45,
				-190
			]
		],
		[
			[
				34402,
				93280
			],
			[
				264,
				-283
			],
			[
				71,
				101
			],
			[
				-14,
				227
			],
			[
				-114,
				-15
			],
			[
				-136,
				75
			],
			[
				-71,
				-105
			]
		],
		[
			[
				33645,
				91867
			],
			[
				153,
				-106
			],
			[
				194,
				64
			],
			[
				126,
				196
			],
			[
				58,
				207
			],
			[
				-60,
				170
			],
			[
				-149,
				95
			],
			[
				-123,
				17
			],
			[
				-91,
				-454
			],
			[
				-108,
				-189
			]
		],
		[
			[
				33746,
				90799
			],
			[
				152,
				96
			],
			[
				50,
				-31
			]
		],
		[
			[
				33948,
				90864
			],
			[
				105,
				14
			],
			[
				203,
				-59
			],
			[
				130,
				81
			],
			[
				-63,
				180
			],
			[
				-132,
				168
			],
			[
				-157,
				68
			],
			[
				-458,
				-206
			],
			[
				-330,
				60
			],
			[
				-122,
				-53
			],
			[
				114,
				-264
			],
			[
				-14,
				-189
			],
			[
				522,
				135
			]
		],
		[
			[
				48546,
				45652
			],
			[
				44,
				3
			]
		],
		[
			[
				48590,
				45655
			],
			[
				-42,
				1676
			]
		],
		[
			[
				47616,
				47267
			],
			[
				-28,
				-1
			]
		],
		[
			[
				47588,
				47266
			],
			[
				48,
				-1678
			]
		],
		[
			[
				57633,
				22772
			],
			[
				610,
				-25
			]
		],
		[
			[
				58632,
				22734
			],
			[
				10,
				550
			]
		],
		[
			[
				58648,
				23931
			],
			[
				-15,
				-3
			]
		],
		[
			[
				57799,
				23287
			],
			[
				-152,
				-264
			],
			[
				-14,
				-251
			]
		],
		[
			[
				51671,
				41441
			],
			[
				767,
				21
			]
		],
		[
			[
				52426,
				42805
			],
			[
				-763,
				-22
			]
		],
		[
			[
				51663,
				42783
			],
			[
				-6,
				-1
			]
		],
		[
			[
				50277,
				93044
			],
			[
				349,
				19
			]
		],
		[
			[
				50626,
				93063
			],
			[
				143,
				7
			],
			[
				-2,
				195
			]
		],
		[
			[
				50767,
				93265
			],
			[
				-19,
				1626
			]
		],
		[
			[
				50748,
				94891
			],
			[
				-673,
				-34
			]
		],
		[
			[
				50075,
				94857
			],
			[
				-204,
				-10
			]
		],
		[
			[
				49871,
				94847
			],
			[
				24,
				-1032
			],
			[
				-97,
				-2
			],
			[
				4,
				-331
			],
			[
				-52,
				-3
			],
			[
				6,
				-353
			],
			[
				-59,
				-117
			]
		],
		[
			[
				46368,
				69309
			],
			[
				-965,
				-81
			]
		],
		[
			[
				60122,
				38211
			],
			[
				-190,
				12
			]
		],
		[
			[
				60694,
				38173
			],
			[
				33,
				1338
			]
		],
		[
			[
				60343,
				39538
			],
			[
				-383,
				23
			]
		],
		[
			[
				56496,
				31633
			],
			[
				762,
				-18
			]
		],
		[
			[
				57271,
				32948
			],
			[
				-767,
				12
			]
		],
		[
			[
				56504,
				32960
			],
			[
				-8,
				-1327
			]
		],
		[
			[
				87150,
				54818
			],
			[
				153,
				105
			],
			[
				97,
				350
			],
			[
				127,
				166
			]
		],
		[
			[
				87527,
				55439
			],
			[
				-58,
				118
			]
		],
		[
			[
				87469,
				55557
			],
			[
				-151,
				310
			],
			[
				-38,
				15
			]
		],
		[
			[
				87280,
				55882
			],
			[
				-427,
				140
			]
		],
		[
			[
				86853,
				56022
			],
			[
				-115,
				-31
			],
			[
				-104,
				-333
			],
			[
				-67,
				-112
			]
		],
		[
			[
				86567,
				55546
			],
			[
				471,
				-600
			],
			[
				96,
				-6
			],
			[
				16,
				-122
			]
		],
		[
			[
				73417,
				56299
			],
			[
				63,
				-381
			],
			[
				278,
				-182
			]
		],
		[
			[
				74080,
				56155
			],
			[
				23,
				394
			],
			[
				99,
				401
			],
			[
				-53,
				211
			]
		],
		[
			[
				74149,
				57161
			],
			[
				-152,
				-1
			],
			[
				-230,
				-143
			],
			[
				-4,
				-65
			],
			[
				-169,
				-39
			],
			[
				-66,
				-85
			],
			[
				-21,
				-162
			],
			[
				-64,
				19
			]
		],
		[
			[
				73443,
				56685
			],
			[
				-26,
				-386
			]
		],
		[
			[
				62203,
				12479
			],
			[
				213,
				-236
			],
			[
				70,
				17
			],
			[
				14,
				191
			],
			[
				90,
				93
			],
			[
				-28,
				88
			],
			[
				58,
				151
			],
			[
				180,
				-37
			],
			[
				86,
				-85
			],
			[
				42,
				101
			],
			[
				636,
				-124
			],
			[
				167,
				84
			],
			[
				93,
				257
			],
			[
				150,
				92
			],
			[
				73,
				-119
			],
			[
				105,
				-43
			],
			[
				75,
				57
			],
			[
				183,
				18
			],
			[
				135,
				-87
			],
			[
				-142,
				201
			],
			[
				-161,
				113
			],
			[
				-137,
				205
			],
			[
				-192,
				132
			],
			[
				-72,
				128
			],
			[
				-407,
				263
			],
			[
				-123,
				113
			],
			[
				-157,
				72
			],
			[
				-146,
				123
			],
			[
				-291,
				327
			],
			[
				-193,
				275
			],
			[
				-224,
				370
			]
		],
		[
			[
				62300,
				15219
			],
			[
				-45,
				-1604
			],
			[
				-11,
				-54
			],
			[
				-41,
				-1082
			]
		],
		[
			[
				72421,
				25055
			],
			[
				759,
				-142
			]
		],
		[
			[
				73180,
				24913
			],
			[
				99,
				1319
			]
		],
		[
			[
				72511,
				26386
			],
			[
				-78,
				-1046
			],
			[
				-12,
				-285
			]
		],
		[
			[
				71658,
				25200
			],
			[
				763,
				-145
			]
		],
		[
			[
				72137,
				26450
			],
			[
				-394,
				73
			]
		],
		[
			[
				48344,
				11218
			],
			[
				196,
				14
			]
		],
		[
			[
				48540,
				11232
			],
			[
				975,
				68
			]
		],
		[
			[
				49515,
				11300
			],
			[
				-14,
				658
			]
		],
		[
			[
				49501,
				11958
			],
			[
				-530,
				-37
			],
			[
				-34,
				1324
			],
			[
				55,
				4
			],
			[
				-16,
				660
			]
		],
		[
			[
				48588,
				13881
			],
			[
				-194,
				-16
			]
		],
		[
			[
				48394,
				13865
			],
			[
				17,
				-660
			],
			[
				-59,
				-4
			],
			[
				34,
				-1322
			],
			[
				-59,
				-5
			],
			[
				17,
				-656
			]
		],
		[
			[
				45735,
				64047
			],
			[
				735,
				65
			]
		],
		[
			[
				46697,
				64131
			],
			[
				-49,
				1671
			]
		],
		[
			[
				46648,
				65802
			],
			[
				-182,
				-17
			]
		],
		[
			[
				45682,
				65723
			],
			[
				53,
				-1676
			]
		],
		[
			[
				77529,
				50316
			],
			[
				72,
				34
			],
			[
				45,
				-112
			],
			[
				178,
				-148
			],
			[
				224,
				86
			]
		],
		[
			[
				78048,
				50176
			],
			[
				145,
				103
			]
		],
		[
			[
				78193,
				50279
			],
			[
				-84,
				75
			],
			[
				37,
				102
			],
			[
				1,
				541
			],
			[
				78,
				125
			],
			[
				-102,
				647
			],
			[
				-60,
				99
			]
		],
		[
			[
				78063,
				51868
			],
			[
				-80,
				-70
			],
			[
				-39,
				-402
			],
			[
				-139,
				30
			],
			[
				-9,
				-202
			],
			[
				-170,
				-36
			]
		],
		[
			[
				77626,
				51188
			],
			[
				44,
				-98
			],
			[
				-53,
				-501
			],
			[
				-85,
				-124
			],
			[
				-3,
				-149
			]
		],
		[
			[
				53272,
				51561
			],
			[
				11,
				-1015
			],
			[
				31,
				1
			],
			[
				3,
				-671
			]
		],
		[
			[
				30751,
				65365
			],
			[
				248,
				-3056
			]
		],
		[
			[
				30999,
				62309
			],
			[
				1056,
				243
			],
			[
				1335,
				297
			]
		],
		[
			[
				33107,
				67063
			],
			[
				-528,
				-118
			],
			[
				-78,
				1055
			]
		],
		[
			[
				32501,
				68000
			],
			[
				-829,
				-181
			],
			[
				-359,
				-114
			],
			[
				-738,
				-170
			]
		],
		[
			[
				30575,
				67535
			],
			[
				176,
				-2170
			]
		],
		[
			[
				72488,
				55230
			],
			[
				276,
				-434
			],
			[
				115,
				-98
			]
		],
		[
			[
				72879,
				54698
			],
			[
				224,
				-8
			],
			[
				34,
				51
			]
		],
		[
			[
				73137,
				54741
			],
			[
				23,
				153
			],
			[
				82,
				-5
			],
			[
				55,
				158
			],
			[
				81,
				-23
			],
			[
				-35,
				172
			],
			[
				125,
				257
			]
		],
		[
			[
				73468,
				55453
			],
			[
				-91,
				31
			],
			[
				-532,
				73
			]
		],
		[
			[
				72845,
				55557
			],
			[
				-332,
				34
			]
		],
		[
			[
				72513,
				55591
			],
			[
				38,
				-135
			],
			[
				-63,
				-226
			]
		],
		[
			[
				47453,
				64193
			],
			[
				-12,
				717
			]
		],
		[
			[
				47441,
				64910
			],
			[
				-24,
				956
			]
		],
		[
			[
				47417,
				65866
			],
			[
				-769,
				-64
			]
		],
		[
			[
				12638,
				14348
			],
			[
				135,
				-100
			],
			[
				-33,
				-112
			],
			[
				57,
				-68
			],
			[
				-26,
				-234
			],
			[
				65,
				19
			],
			[
				84,
				-199
			],
			[
				86,
				153
			],
			[
				78,
				28
			]
		],
		[
			[
				13084,
				13835
			],
			[
				107,
				-4
			],
			[
				33,
				128
			],
			[
				-41,
				232
			],
			[
				-87,
				167
			],
			[
				82,
				323
			],
			[
				142,
				226
			],
			[
				41,
				352
			],
			[
				172,
				286
			],
			[
				1014,
				488
			]
		],
		[
			[
				14473,
				16236
			],
			[
				-87,
				16
			],
			[
				-20,
				109
			],
			[
				68,
				96
			],
			[
				-88,
				103
			],
			[
				-11,
				174
			]
		],
		[
			[
				12320,
				15491
			],
			[
				117,
				-192
			],
			[
				-118,
				-156
			],
			[
				165,
				-296
			],
			[
				107,
				1
			],
			[
				35,
				-131
			],
			[
				-37,
				-152
			],
			[
				49,
				-217
			]
		],
		[
			[
				74149,
				76323
			],
			[
				153,
				-23
			]
		],
		[
			[
				74302,
				76300
			],
			[
				425,
				-98
			],
			[
				46,
				773
			]
		],
		[
			[
				74773,
				76975
			],
			[
				-19,
				4
			]
		],
		[
			[
				74754,
				76979
			],
			[
				-1019,
				203
			]
		],
		[
			[
				73735,
				77182
			],
			[
				-289,
				49
			]
		],
		[
			[
				73446,
				77231
			],
			[
				-58,
				-754
			]
		],
		[
			[
				59598,
				40593
			],
			[
				768,
				-44
			]
		],
		[
			[
				60392,
				41735
			],
			[
				-128,
				13
			]
		],
		[
			[
				60264,
				41748
			],
			[
				-644,
				57
			]
		],
		[
			[
				47393,
				50611
			],
			[
				1117,
				79
			]
		],
		[
			[
				48520,
				51366
			],
			[
				-15,
				672
			]
		],
		[
			[
				48505,
				52038
			],
			[
				-1147,
				-84
			]
		],
		[
			[
				47368,
				51619
			],
			[
				25,
				-1008
			]
		],
		[
			[
				46888,
				78233
			],
			[
				976,
				70
			]
		],
		[
			[
				47864,
				78303
			],
			[
				-13,
				562
			],
			[
				227,
				15
			]
		],
		[
			[
				43706,
				31956
			],
			[
				1150,
				136
			]
		],
		[
			[
				44856,
				32092
			],
			[
				-34,
				820
			],
			[
				29,
				3
			],
			[
				-49,
				1313
			],
			[
				47,
				6
			],
			[
				-53,
				1330
			],
			[
				56,
				8
			]
		],
		[
			[
				44852,
				35572
			],
			[
				-16,
				335
			],
			[
				-96,
				-11
			]
		],
		[
			[
				43701,
				35793
			],
			[
				7,
				-338
			],
			[
				-66,
				-7
			],
			[
				44,
				-1335
			],
			[
				-39,
				-5
			]
		],
		[
			[
				89516,
				50170
			],
			[
				77,
				-95
			],
			[
				-51,
				-98
			],
			[
				17,
				-175
			]
		],
		[
			[
				89559,
				49802
			],
			[
				151,
				26
			],
			[
				96,
				-126
			],
			[
				69,
				14
			],
			[
				157,
				577
			],
			[
				163,
				455
			],
			[
				80,
				287
			]
		],
		[
			[
				90275,
				51035
			],
			[
				-444,
				162
			]
		],
		[
			[
				89831,
				51197
			],
			[
				-53,
				-434
			],
			[
				88,
				-111
			],
			[
				-168,
				-221
			],
			[
				-111,
				-9
			],
			[
				-71,
				-252
			]
		],
		[
			[
				70808,
				47905
			],
			[
				643,
				-117
			]
		],
		[
			[
				71451,
				47788
			],
			[
				61,
				1019
			]
		],
		[
			[
				71512,
				48807
			],
			[
				7,
				110
			],
			[
				-644,
				108
			]
		],
		[
			[
				70875,
				49025
			],
			[
				-30,
				-505
			]
		],
		[
			[
				62339,
				51011
			],
			[
				167,
				-9
			]
		],
		[
			[
				62506,
				51002
			],
			[
				16,
				-1
			],
			[
				32,
				1402
			]
		],
		[
			[
				61697,
				53188
			],
			[
				-24,
				-1575
			]
		],
		[
			[
				17640,
				4014
			],
			[
				-88,
				-122
			],
			[
				61,
				-96
			],
			[
				-12,
				-179
			],
			[
				66,
				-130
			],
			[
				-22,
				-115
			],
			[
				61,
				-109
			],
			[
				124,
				42
			],
			[
				209,
				-85
			],
			[
				29,
				165
			],
			[
				141,
				-35
			]
		],
		[
			[
				18209,
				3350
			],
			[
				74,
				15
			],
			[
				-25,
				256
			],
			[
				-47,
				50
			],
			[
				-4,
				203
			],
			[
				110,
				194
			],
			[
				-64,
				120
			],
			[
				105,
				90
			],
			[
				41,
				188
			],
			[
				95,
				143
			],
			[
				42,
				190
			],
			[
				-41,
				101
			],
			[
				167,
				426
			],
			[
				95,
				97
			],
			[
				-21,
				98
			],
			[
				78,
				139
			],
			[
				22,
				207
			],
			[
				261,
				116
			]
		],
		[
			[
				19097,
				5983
			],
			[
				-126,
				328
			],
			[
				-69,
				35
			],
			[
				-86,
				214
			],
			[
				-300,
				-47
			],
			[
				-67,
				112
			],
			[
				12,
				166
			],
			[
				-98,
				289
			],
			[
				-120,
				179
			],
			[
				-83,
				282
			],
			[
				11,
				256
			],
			[
				216,
				201
			],
			[
				49,
				129
			],
			[
				-55,
				277
			]
		],
		[
			[
				18381,
				8404
			],
			[
				-437,
				-194
			],
			[
				-156,
				-347
			],
			[
				-77,
				59
			],
			[
				-10,
				-120
			],
			[
				-104,
				-65
			],
			[
				-167,
				-377
			],
			[
				-172,
				-131
			],
			[
				-59,
				-352
			],
			[
				-68,
				-113
			],
			[
				-58,
				-269
			]
		],
		[
			[
				17073,
				6495
			],
			[
				23,
				-299
			],
			[
				114,
				-94
			],
			[
				-39,
				-280
			]
		],
		[
			[
				17171,
				5822
			],
			[
				99,
				-143
			],
			[
				-109,
				-117
			],
			[
				2,
				-283
			],
			[
				138,
				-261
			],
			[
				-17,
				-173
			],
			[
				225,
				-46
			],
			[
				104,
				-79
			],
			[
				-9,
				-107
			],
			[
				99,
				-64
			],
			[
				-57,
				-214
			],
			[
				-6,
				-321
			]
		],
		[
			[
				56527,
				39701
			],
			[
				379,
				-7
			]
		],
		[
			[
				56532,
				40695
			],
			[
				-5,
				-994
			]
		],
		[
			[
				82286,
				53631
			],
			[
				256,
				-46
			],
			[
				425,
				-117
			]
		],
		[
			[
				82967,
				53468
			],
			[
				46,
				-13
			]
		],
		[
			[
				83013,
				53455
			],
			[
				93,
				1089
			]
		],
		[
			[
				82371,
				54737
			],
			[
				-85,
				-1106
			]
		],
		[
			[
				49470,
				52434
			],
			[
				-7,
				357
			]
		],
		[
			[
				49463,
				52791
			],
			[
				-955,
				-64
			]
		],
		[
			[
				48508,
				52727
			],
			[
				-3,
				-689
			]
		],
		[
			[
				71584,
				24058
			],
			[
				53,
				-192
			],
			[
				699,
				-132
			]
		],
		[
			[
				72336,
				23734
			],
			[
				85,
				1321
			]
		],
		[
			[
				71658,
				25200
			],
			[
				-74,
				-1142
			]
		],
		[
			[
				47536,
				60831
			],
			[
				-19,
				758
			]
		],
		[
			[
				50999,
				28951
			],
			[
				5,
				333
			]
		],
		[
			[
				51004,
				29284
			],
			[
				-12,
				1352
			]
		],
		[
			[
				50371,
				30606
			],
			[
				-143,
				-7
			]
		],
		[
			[
				50228,
				30599
			],
			[
				19,
				-1341
			],
			[
				-11,
				-333
			]
		],
		[
			[
				65624,
				75544
			],
			[
				226,
				-159
			]
		],
		[
			[
				66129,
				75321
			],
			[
				31,
				917
			],
			[
				32,
				-4
			],
			[
				19,
				505
			]
		],
		[
			[
				66211,
				76739
			],
			[
				-155,
				1
			],
			[
				8,
				225
			]
		],
		[
			[
				66064,
				76965
			],
			[
				-131,
				-43
			],
			[
				-257,
				29
			]
		],
		[
			[
				83998,
				54344
			],
			[
				-3,
				-33
			]
		],
		[
			[
				83995,
				54311
			],
			[
				486,
				-123
			]
		],
		[
			[
				84639,
				55578
			],
			[
				35,
				117
			],
			[
				-540,
				167
			]
		],
		[
			[
				84134,
				55862
			],
			[
				-21,
				-214
			]
		],
		[
			[
				47305,
				71067
			],
			[
				-289,
				-21
			]
		],
		[
			[
				46318,
				70980
			],
			[
				50,
				-1671
			]
		],
		[
			[
				48112,
				83913
			],
			[
				833,
				67
			],
			[
				-10,
				223
			],
			[
				113,
				13
			],
			[
				243,
				-479
			]
		],
		[
			[
				49291,
				83737
			],
			[
				-26,
				1681
			]
		],
		[
			[
				48065,
				85960
			],
			[
				47,
				-2047
			]
		],
		[
			[
				51579,
				29307
			],
			[
				-575,
				-23
			]
		],
		[
			[
				34206,
				16014
			],
			[
				18,
				-218
			],
			[
				578,
				141
			]
		],
		[
			[
				34802,
				15937
			],
			[
				-40,
				489
			],
			[
				18,
				171
			],
			[
				129,
				33
			],
			[
				-27,
				329
			],
			[
				56,
				160
			],
			[
				-28,
				328
			],
			[
				101,
				359
			],
			[
				-44,
				548
			]
		],
		[
			[
				34967,
				18354
			],
			[
				-223,
				-52
			]
		],
		[
			[
				34744,
				18302
			],
			[
				-755,
				-180
			],
			[
				-29,
				331
			]
		],
		[
			[
				33960,
				18453
			],
			[
				-289,
				-72
			],
			[
				29,
				-330
			],
			[
				-76,
				-19
			],
			[
				27,
				-328
			]
		],
		[
			[
				56141,
				38372
			],
			[
				189,
				-1
			]
		],
		[
			[
				56330,
				38371
			],
			[
				565,
				-10
			]
		],
		[
			[
				56527,
				39701
			],
			[
				-381,
				4
			]
		],
		[
			[
				56146,
				39705
			],
			[
				-5,
				-1333
			]
		],
		[
			[
				70392,
				36067
			],
			[
				490,
				-87
			]
		],
		[
			[
				70882,
				35980
			],
			[
				269,
				-50
			]
		],
		[
			[
				71151,
				35930
			],
			[
				72,
				1077
			]
		],
		[
			[
				70560,
				37302
			],
			[
				-96,
				17
			]
		],
		[
			[
				70464,
				37319
			],
			[
				-20,
				-332
			],
			[
				52,
				-206
			],
			[
				-19,
				-302
			],
			[
				-65,
				7
			],
			[
				-20,
				-419
			]
		],
		[
			[
				55738,
				34310
			],
			[
				2,
				334
			],
			[
				107,
				-1
			],
			[
				2,
				1015
			]
		],
		[
			[
				55278,
				35662
			],
			[
				0,
				-1013
			],
			[
				-118,
				0
			],
			[
				-1,
				-333
			]
		],
		[
			[
				44491,
				63931
			],
			[
				-672,
				-72
			]
		],
		[
			[
				43510,
				63828
			],
			[
				66,
				-1676
			]
		],
		[
			[
				43777,
				57083
			],
			[
				232,
				24
			]
		],
		[
			[
				44009,
				57107
			],
			[
				725,
				81
			]
		],
		[
			[
				44734,
				57188
			],
			[
				-59,
				1710
			]
		],
		[
			[
				44675,
				58898
			],
			[
				-964,
				-101
			]
		],
		[
			[
				50902,
				41413
			],
			[
				8,
				1
			]
		],
		[
			[
				50139,
				42721
			],
			[
				21,
				-1308
			]
		],
		[
			[
				34714,
				26221
			],
			[
				96,
				21
			],
			[
				-24,
				334
			],
			[
				99,
				22
			],
			[
				-30,
				336
			],
			[
				191,
				44
			],
			[
				-17,
				215
			],
			[
				199,
				38
			],
			[
				8,
				115
			],
			[
				348,
				77
			],
			[
				-24,
				324
			],
			[
				384,
				83
			],
			[
				-25,
				334
			],
			[
				194,
				38
			],
			[
				-40,
				553
			]
		],
		[
			[
				36073,
				28755
			],
			[
				-8,
				110
			],
			[
				-899,
				-186
			],
			[
				-3,
				42
			],
			[
				-291,
				-65
			],
			[
				4,
				-55
			],
			[
				-190,
				-43
			],
			[
				8,
				-110
			],
			[
				-191,
				-44
			],
			[
				9,
				-111
			],
			[
				-190,
				-43
			],
			[
				14,
				-165
			],
			[
				-192,
				-44
			],
			[
				9,
				-113
			],
			[
				-191,
				-42
			],
			[
				4,
				-55
			],
			[
				-379,
				-92
			],
			[
				25,
				-302
			],
			[
				-81,
				-270
			],
			[
				-108,
				-218
			]
		],
		[
			[
				82310,
				30194
			],
			[
				107,
				-10
			],
			[
				86,
				206
			],
			[
				140,
				76
			],
			[
				151,
				-99
			],
			[
				59,
				-118
			],
			[
				71,
				11
			],
			[
				141,
				-203
			],
			[
				206,
				-159
			]
		],
		[
			[
				83271,
				29898
			],
			[
				6,
				66
			],
			[
				250,
				-88
			]
		],
		[
			[
				83745,
				31849
			],
			[
				-990,
				318
			]
		],
		[
			[
				82524,
				32234
			],
			[
				-214,
				-2040
			]
		],
		[
			[
				55980,
				15747
			],
			[
				780,
				-6
			]
		],
		[
			[
				56760,
				15741
			],
			[
				13,
				332
			],
			[
				22,
				1974
			],
			[
				-195,
				4
			]
		],
		[
			[
				56006,
				16735
			],
			[
				-4,
				-657
			],
			[
				-22,
				-331
			]
		],
		[
			[
				58964,
				25656
			],
			[
				122,
				-84
			],
			[
				111,
				-300
			]
		],
		[
			[
				59197,
				25272
			],
			[
				76,
				-123
			],
			[
				124,
				-6
			],
			[
				50,
				107
			]
		],
		[
			[
				59447,
				25250
			],
			[
				29,
				289
			],
			[
				-15,
				152
			],
			[
				210,
				-7
			],
			[
				130,
				102
			]
		],
		[
			[
				59801,
				25786
			],
			[
				114,
				116
			]
		],
		[
			[
				59915,
				25902
			],
			[
				7,
				323
			],
			[
				-96,
				4
			],
			[
				6,
				332
			],
			[
				-200,
				11
			],
			[
				-55,
				142
			],
			[
				-134,
				-26
			],
			[
				2,
				167
			]
		],
		[
			[
				59056,
				26599
			],
			[
				0,
				-333
			],
			[
				-65,
				4
			],
			[
				-27,
				-614
			]
		],
		[
			[
				49238,
				71200
			],
			[
				-270,
				-16
			]
		],
		[
			[
				87306,
				30630
			],
			[
				1004,
				-372
			]
		],
		[
			[
				88310,
				30258
			],
			[
				206,
				1333
			]
		],
		[
			[
				88516,
				31591
			],
			[
				-417,
				153
			]
		],
		[
			[
				88099,
				31744
			],
			[
				-598,
				210
			],
			[
				-47,
				-11
			]
		],
		[
			[
				87454,
				31943
			],
			[
				-212,
				-1290
			]
		],
		[
			[
				87242,
				30653
			],
			[
				64,
				-23
			]
		],
		[
			[
				62163,
				68200
			],
			[
				-9,
				-336
			]
		],
		[
			[
				62154,
				67864
			],
			[
				575,
				-45
			],
			[
				197,
				19
			]
		],
		[
			[
				62926,
				67838
			],
			[
				198,
				-5
			],
			[
				17,
				834
			]
		],
		[
			[
				63141,
				68667
			],
			[
				12,
				672
			]
		],
		[
			[
				63153,
				69339
			],
			[
				-987,
				41
			]
		],
		[
			[
				62166,
				69380
			],
			[
				-46,
				-502
			],
			[
				59,
				-6
			],
			[
				-16,
				-672
			]
		],
		[
			[
				60264,
				41748
			],
			[
				55,
				108
			],
			[
				17,
				235
			],
			[
				-52,
				210
			],
			[
				73,
				141
			],
			[
				-23,
				251
			]
		],
		[
			[
				60334,
				42693
			],
			[
				-289,
				20
			]
		],
		[
			[
				60045,
				42713
			],
			[
				-3,
				-167
			],
			[
				-863,
				56
			]
		],
		[
			[
				59179,
				42602
			],
			[
				-24,
				-760
			]
		],
		[
			[
				66211,
				76739
			],
			[
				257,
				-26
			],
			[
				-8,
				-169
			],
			[
				323,
				-35
			]
		],
		[
			[
				66847,
				78160
			],
			[
				-141,
				17
			]
		],
		[
			[
				66706,
				78177
			],
			[
				-205,
				23
			]
		],
		[
			[
				66501,
				78200
			],
			[
				-11,
				-278
			],
			[
				-162,
				18
			],
			[
				-41,
				-219
			],
			[
				-129,
				17
			],
			[
				-55,
				-161
			],
			[
				-39,
				-612
			]
		],
		[
			[
				50474,
				22593
			],
			[
				-18,
				1328
			]
		],
		[
			[
				49119,
				23839
			],
			[
				-213,
				-16
			]
		],
		[
			[
				48906,
				23823
			],
			[
				32,
				-1328
			]
		],
		[
			[
				68355,
				64624
			],
			[
				388,
				-46
			]
		],
		[
			[
				69059,
				64535
			],
			[
				-6,
				551
			]
		],
		[
			[
				69042,
				65994
			],
			[
				-623,
				76
			]
		],
		[
			[
				68419,
				66070
			],
			[
				-64,
				-1446
			]
		],
		[
			[
				67879,
				47484
			],
			[
				-46,
				-1002
			]
		],
		[
			[
				67833,
				46482
			],
			[
				604,
				-96
			]
		],
		[
			[
				68437,
				46386
			],
			[
				97,
				-18
			]
		],
		[
			[
				68534,
				46368
			],
			[
				70,
				1248
			]
		],
		[
			[
				45355,
				18215
			],
			[
				69,
				8
			],
			[
				-42,
				1321
			],
			[
				75,
				9
			],
			[
				-10,
				297
			]
		],
		[
			[
				45447,
				19850
			],
			[
				-773,
				-89
			],
			[
				12,
				-293
			],
			[
				-660,
				-74
			]
		],
		[
			[
				44026,
				19394
			],
			[
				51,
				-1325
			]
		],
		[
			[
				64987,
				28268
			],
			[
				686,
				-84
			]
		],
		[
			[
				65673,
				28184
			],
			[
				18,
				407
			],
			[
				-49,
				5
			],
			[
				11,
				245
			],
			[
				56,
				174
			],
			[
				-123,
				37
			],
			[
				18,
				447
			]
		],
		[
			[
				65604,
				29499
			],
			[
				-568,
				69
			]
		],
		[
			[
				65036,
				29568
			],
			[
				-49,
				-1300
			]
		],
		[
			[
				63221,
				52701
			],
			[
				7,
				-169
			],
			[
				81,
				-4
			]
		],
		[
			[
				64309,
				52808
			],
			[
				-16,
				176
			],
			[
				32,
				1083
			]
		],
		[
			[
				64325,
				54067
			],
			[
				5,
				173
			],
			[
				-325,
				37
			]
		],
		[
			[
				64005,
				54277
			],
			[
				-32,
				7
			],
			[
				-6,
				-396
			],
			[
				-41,
				0
			],
			[
				-21,
				-884
			],
			[
				-382,
				32
			],
			[
				-293,
				86
			]
		],
		[
			[
				46969,
				72725
			],
			[
				-53,
				1689
			]
		],
		[
			[
				45950,
				74327
			],
			[
				57,
				-1688
			]
		],
		[
			[
				45352,
				70869
			],
			[
				90,
				28
			],
			[
				622,
				60
			]
		],
		[
			[
				46007,
				72639
			],
			[
				-962,
				-94
			]
		],
		[
			[
				47969,
				72826
			],
			[
				-33,
				-2
			]
		],
		[
			[
				47936,
				72824
			],
			[
				1,
				-23
			],
			[
				-968,
				-76
			]
		],
		[
			[
				45656,
				74297
			],
			[
				-682,
				-64
			]
		],
		[
			[
				69066,
				64076
			],
			[
				1113,
				-107
			]
		],
		[
			[
				70179,
				63969
			],
			[
				52,
				1007
			]
		],
		[
			[
				67166,
				76464
			],
			[
				100,
				-12
			]
		],
		[
			[
				67266,
				76452
			],
			[
				479,
				-58
			]
		],
		[
			[
				67745,
				76394
			],
			[
				70,
				1658
			],
			[
				31,
				345
			]
		],
		[
			[
				67846,
				78397
			],
			[
				-387,
				47
			]
		],
		[
			[
				67459,
				78444
			],
			[
				-32,
				-382
			]
		],
		[
			[
				27240,
				23600
			],
			[
				-151,
				-49
			],
			[
				-70,
				657
			],
			[
				-66,
				-21
			],
			[
				-12,
				291
			],
			[
				-61,
				398
			],
			[
				64,
				82
			],
			[
				38,
				221
			],
			[
				-108,
				-40
			],
			[
				-21,
				124
			],
			[
				-150,
				171
			],
			[
				-405,
				245
			],
			[
				-85,
				185
			]
		],
		[
			[
				26213,
				25864
			],
			[
				-70,
				-314
			],
			[
				-66,
				-89
			],
			[
				-138,
				-481
			],
			[
				-67,
				69
			],
			[
				-103,
				-97
			],
			[
				-200,
				-329
			],
			[
				10,
				-192
			],
			[
				-130,
				-22
			],
			[
				-59,
				-86
			],
			[
				-169,
				-50
			],
			[
				-39,
				160
			],
			[
				-207,
				-381
			],
			[
				-210,
				-75
			],
			[
				28,
				-245
			],
			[
				-99,
				-29
			],
			[
				-172,
				150
			]
		],
		[
			[
				64148,
				55596
			],
			[
				576,
				-58
			],
			[
				167,
				1
			]
		],
		[
			[
				64891,
				55539
			],
			[
				10,
				172
			],
			[
				75,
				125
			],
			[
				31,
				270
			],
			[
				125,
				281
			],
			[
				-6,
				254
			]
		],
		[
			[
				65126,
				56641
			],
			[
				-63,
				175
			],
			[
				-47,
				350
			]
		],
		[
			[
				65016,
				57166
			],
			[
				-632,
				61
			]
		],
		[
			[
				64384,
				57227
			],
			[
				-9,
				-360
			],
			[
				-33,
				2
			],
			[
				-9,
				-336
			],
			[
				-65,
				6
			],
			[
				-15,
				-507
			],
			[
				-62,
				4
			]
		],
		[
			[
				75701,
				42727
			],
			[
				6,
				217
			]
		],
		[
			[
				75707,
				42944
			],
			[
				21,
				644
			]
		],
		[
			[
				52522,
				16387
			],
			[
				943,
				23
			]
		],
		[
			[
				53465,
				16410
			],
			[
				2,
				333
			]
		],
		[
			[
				53467,
				16743
			],
			[
				18,
				165
			],
			[
				-26,
				382
			],
			[
				66,
				295
			],
			[
				42,
				-21
			],
			[
				-24,
				325
			],
			[
				-35,
				114
			],
			[
				31,
				351
			],
			[
				-20,
				359
			]
		],
		[
			[
				53519,
				18713
			],
			[
				-753,
				0
			]
		],
		[
			[
				52766,
				18713
			],
			[
				-619,
				-18
			]
		],
		[
			[
				86140,
				53581
			],
			[
				315,
				-23
			],
			[
				57,
				123
			],
			[
				182,
				-31
			]
		],
		[
			[
				86694,
				53650
			],
			[
				-187,
				1029
			],
			[
				-88,
				572
			]
		],
		[
			[
				85821,
				54642
			],
			[
				-22,
				-224
			],
			[
				46,
				-386
			]
		],
		[
			[
				67286,
				73411
			],
			[
				-55,
				-1353
			]
		],
		[
			[
				31439,
				56873
			],
			[
				1017,
				235
			],
			[
				1504,
				336
			]
		],
		[
			[
				33960,
				57444
			],
			[
				565,
				124
			],
			[
				-152,
				2222
			],
			[
				-37,
				433
			]
		],
		[
			[
				34336,
				60223
			],
			[
				-571,
				-117
			],
			[
				12,
				-167
			],
			[
				-190,
				-40
			],
			[
				-12,
				166
			],
			[
				-1319,
				-266
			],
			[
				-101,
				1300
			],
			[
				-1038,
				-243
			]
		],
		[
			[
				31117,
				60856
			],
			[
				322,
				-3983
			]
		],
		[
			[
				74828,
				75738
			],
			[
				623,
				-96
			]
		],
		[
			[
				75451,
				75642
			],
			[
				-41,
				132
			],
			[
				37,
				340
			],
			[
				156,
				271
			],
			[
				31,
				120
			]
		],
		[
			[
				75634,
				76505
			],
			[
				60,
				277
			]
		],
		[
			[
				75694,
				76782
			],
			[
				-921,
				193
			]
		],
		[
			[
				74302,
				76300
			],
			[
				67,
				-291
			],
			[
				119,
				-42
			],
			[
				228,
				59
			],
			[
				-10,
				-157
			],
			[
				130,
				-21
			],
			[
				-8,
				-110
			]
		],
		[
			[
				63141,
				68667
			],
			[
				192,
				-16
			],
			[
				3,
				170
			],
			[
				194,
				-7
			],
			[
				23,
				-173
			]
		],
		[
			[
				63553,
				68641
			],
			[
				31,
				121
			]
		],
		[
			[
				63584,
				68762
			],
			[
				64,
				113
			],
			[
				-89,
				238
			],
			[
				120,
				-63
			],
			[
				5,
				-174
			],
			[
				74,
				89
			],
			[
				-7,
				165
			],
			[
				-130,
				63
			],
			[
				72,
				136
			],
			[
				96,
				-60
			],
			[
				43,
				-229
			],
			[
				55,
				83
			],
			[
				-143,
				311
			],
			[
				4,
				200
			],
			[
				84,
				229
			],
			[
				55,
				-194
			],
			[
				45,
				63
			],
			[
				-83,
				196
			],
			[
				17,
				290
			],
			[
				-30,
				55
			],
			[
				-148,
				-26
			],
			[
				-30,
				126
			],
			[
				148,
				162
			],
			[
				-71,
				210
			]
		],
		[
			[
				63735,
				70745
			],
			[
				1,
				28
			]
		],
		[
			[
				63736,
				70773
			],
			[
				-184,
				12
			]
		],
		[
			[
				63552,
				70785
			],
			[
				-318,
				22
			]
		],
		[
			[
				63234,
				70807
			],
			[
				-46,
				5
			]
		],
		[
			[
				63188,
				70812
			],
			[
				-35,
				-1473
			]
		],
		[
			[
				12100,
				58185
			],
			[
				117,
				62
			],
			[
				-12,
				96
			],
			[
				-110,
				-35
			],
			[
				5,
				-123
			]
		],
		[
			[
				12559,
				54917
			],
			[
				106,
				47
			],
			[
				-12,
				79
			],
			[
				186,
				80
			],
			[
				46,
				108
			],
			[
				-23,
				162
			],
			[
				470,
				207
			],
			[
				-11,
				81
			],
			[
				168,
				71
			]
		],
		[
			[
				13489,
				55752
			],
			[
				167,
				2048
			],
			[
				-19,
				196
			],
			[
				-65,
				-12
			],
			[
				-37,
				256
			],
			[
				-215,
				-90
			],
			[
				-321,
				235
			],
			[
				-24,
				108
			]
		],
		[
			[
				12975,
				58493
			],
			[
				-144,
				-211
			],
			[
				-156,
				-133
			],
			[
				-129,
				-238
			],
			[
				-38,
				-440
			],
			[
				-141,
				-325
			],
			[
				-160,
				-298
			]
		],
		[
			[
				11421,
				60871
			],
			[
				95,
				16
			],
			[
				146,
				289
			],
			[
				-91,
				11
			],
			[
				-115,
				-121
			],
			[
				-35,
				-195
			]
		],
		[
			[
				42081,
				39460
			],
			[
				319,
				41
			]
		],
		[
			[
				43616,
				39654
			],
			[
				-36,
				973
			]
		],
		[
			[
				43580,
				40627
			],
			[
				-30,
				167
			],
			[
				-42,
				1026
			]
		],
		[
			[
				43508,
				41820
			],
			[
				-194,
				-23
			]
		],
		[
			[
				41981,
				41296
			],
			[
				45,
				-685
			],
			[
				55,
				-1151
			]
		],
		[
			[
				49357,
				28880
			],
			[
				687,
				35
			]
		],
		[
			[
				50228,
				30599
			],
			[
				-812,
				-47
			]
		],
		[
			[
				34336,
				60223
			],
			[
				128,
				25
			],
			[
				54,
				345
			]
		],
		[
			[
				34518,
				60593
			],
			[
				164,
				1051
			]
		],
		[
			[
				34682,
				61644
			],
			[
				-246,
				-53
			],
			[
				-103,
				1451
			]
		],
		[
			[
				30999,
				62309
			],
			[
				118,
				-1453
			]
		],
		[
			[
				65635,
				39116
			],
			[
				-14,
				-328
			],
			[
				183,
				-20
			],
			[
				26,
				-264
			],
			[
				122,
				-98
			],
			[
				161,
				28
			]
		],
		[
			[
				44360,
				46965
			],
			[
				975,
				95
			]
		],
		[
			[
				45335,
				47060
			],
			[
				-67,
				1673
			]
		],
		[
			[
				45268,
				48733
			],
			[
				-142,
				-12
			]
		],
		[
			[
				45126,
				48721
			],
			[
				-825,
				-78
			]
		],
		[
			[
				44301,
				48643
			],
			[
				48,
				-1347
			]
		],
		[
			[
				48508,
				52727
			],
			[
				-17,
				1031
			]
		],
		[
			[
				48491,
				53758
			],
			[
				-383,
				-25
			],
			[
				-583,
				-70
			]
		],
		[
			[
				47525,
				53663
			],
			[
				-192,
				-15
			]
		],
		[
			[
				87335,
				32421
			],
			[
				-14,
				-414
			],
			[
				133,
				-64
			]
		],
		[
			[
				88099,
				31744
			],
			[
				-78,
				496
			],
			[
				58,
				49
			],
			[
				-64,
				333
			]
		],
		[
			[
				88015,
				32622
			],
			[
				-257,
				263
			],
			[
				-449,
				194
			]
		],
		[
			[
				50035,
				19927
			],
			[
				53,
				4
			]
		],
		[
			[
				50088,
				19931
			],
			[
				-28,
				1308
			]
		],
		[
			[
				48952,
				21168
			],
			[
				-251,
				-20
			]
		],
		[
			[
				48701,
				21148
			],
			[
				33,
				-1300
			]
		],
		[
			[
				67451,
				21264
			],
			[
				232,
				-38
			],
			[
				-34,
				-669
			],
			[
				123,
				-19
			],
			[
				-16,
				-331
			]
		],
		[
			[
				67756,
				20207
			],
			[
				386,
				-57
			]
		],
		[
			[
				68142,
				20150
			],
			[
				17,
				328
			],
			[
				66,
				-8
			],
			[
				68,
				1324
			],
			[
				99,
				-14
			]
		],
		[
			[
				68392,
				21780
			],
			[
				-87,
				409
			],
			[
				15,
				63
			],
			[
				-92,
				249
			],
			[
				-94,
				382
			],
			[
				-198,
				572
			],
			[
				38,
				136
			]
		],
		[
			[
				67974,
				23591
			],
			[
				-114,
				-32
			],
			[
				-129,
				-224
			],
			[
				50,
				-493
			],
			[
				54,
				-167
			],
			[
				-82,
				-182
			],
			[
				-86,
				169
			],
			[
				-127,
				12
			],
			[
				-69,
				-90
			],
			[
				11,
				-222
			],
			[
				94,
				-284
			],
			[
				-69,
				-234
			],
			[
				51,
				18
			],
			[
				22,
				-184
			],
			[
				-80,
				-134
			],
			[
				61,
				-109
			],
			[
				-110,
				-171
			]
		],
		[
			[
				50955,
				74887
			],
			[
				436,
				1341
			]
		],
		[
			[
				50422,
				77176
			],
			[
				-350,
				-1042
			]
		],
		[
			[
				83518,
				48435
			],
			[
				0,
				0
			]
		],
		[
			[
				75904,
				51361
			],
			[
				110,
				-502
			],
			[
				112,
				-218
			],
			[
				47,
				-15
			]
		],
		[
			[
				76173,
				50626
			],
			[
				24,
				-5
			]
		],
		[
			[
				76197,
				50621
			],
			[
				173,
				154
			],
			[
				173,
				72
			]
		],
		[
			[
				76543,
				50847
			],
			[
				-60,
				528
			]
		],
		[
			[
				76483,
				51375
			],
			[
				-109,
				-107
			],
			[
				-69,
				125
			],
			[
				-92,
				-16
			],
			[
				-12,
				108
			],
			[
				-148,
				61
			],
			[
				-107,
				-96
			]
		],
		[
			[
				71230,
				29988
			],
			[
				20,
				336
			]
		],
		[
			[
				71250,
				30324
			],
			[
				-189,
				31
			],
			[
				22,
				333
			],
			[
				-588,
				107
			]
		],
		[
			[
				70495,
				30795
			],
			[
				-245,
				-721
			],
			[
				-142,
				-579
			]
		],
		[
			[
				89171,
				31500
			],
			[
				88,
				117
			],
			[
				115,
				286
			],
			[
				114,
				-27
			],
			[
				44,
				124
			],
			[
				135,
				-64
			],
			[
				102,
				31
			]
		],
		[
			[
				89769,
				31967
			],
			[
				131,
				216
			]
		],
		[
			[
				89900,
				32183
			],
			[
				-89,
				79
			],
			[
				-86,
				240
			],
			[
				-41,
				463
			],
			[
				-135,
				401
			]
		],
		[
			[
				89549,
				33366
			],
			[
				-53,
				70
			],
			[
				-110,
				-200
			],
			[
				-134,
				15
			],
			[
				-6,
				-399
			],
			[
				-368,
				190
			]
		],
		[
			[
				88878,
				33042
			],
			[
				34,
				-377
			],
			[
				-82,
				-112
			],
			[
				136,
				-70
			],
			[
				3,
				-159
			],
			[
				91,
				-155
			],
			[
				111,
				-669
			]
		],
		[
			[
				49416,
				30553
			],
			[
				-22,
				120
			],
			[
				85,
				124
			],
			[
				143,
				15
			],
			[
				150,
				449
			],
			[
				138,
				245
			],
			[
				136,
				53
			],
			[
				63,
				358
			],
			[
				130,
				40
			],
			[
				130,
				273
			],
			[
				117,
				15
			],
			[
				149,
				141
			],
			[
				41,
				159
			]
		],
		[
			[
				50676,
				32545
			],
			[
				-1228,
				-62
			]
		],
		[
			[
				49448,
				32483
			],
			[
				-455,
				-28
			]
		],
		[
			[
				48993,
				32455
			],
			[
				42,
				-1926
			]
		],
		[
			[
				47738,
				21068
			],
			[
				963,
				80
			]
		],
		[
			[
				48923,
				22494
			],
			[
				-1114,
				-87
			]
		],
		[
			[
				47809,
				22407
			],
			[
				43,
				-207
			],
			[
				141,
				-79
			],
			[
				17,
				-130
			],
			[
				-93,
				-421
			],
			[
				-12,
				-204
			],
			[
				-167,
				-298
			]
		],
		[
			[
				56709,
				83116
			],
			[
				1044,
				-44
			]
		],
		[
			[
				57753,
				83072
			],
			[
				163,
				3
			],
			[
				20,
				1244
			]
		],
		[
			[
				57936,
				84319
			],
			[
				-32,
				26
			]
		],
		[
			[
				57904,
				84345
			],
			[
				-4,
				-159
			],
			[
				-98,
				121
			],
			[
				-171,
				94
			]
		],
		[
			[
				57631,
				84401
			],
			[
				-69,
				-110
			],
			[
				-376,
				186
			],
			[
				-79,
				-18
			],
			[
				138,
				-363
			],
			[
				28,
				-266
			],
			[
				-8,
				-241
			],
			[
				-118,
				-88
			],
			[
				-114,
				97
			],
			[
				-108,
				329
			],
			[
				-114,
				-8
			]
		],
		[
			[
				56811,
				83919
			],
			[
				-28,
				-162
			],
			[
				60,
				-192
			],
			[
				8,
				-232
			],
			[
				-142,
				-217
			]
		],
		[
			[
				55117,
				82346
			],
			[
				257,
				-44
			],
			[
				228,
				-267
			],
			[
				100,
				107
			],
			[
				23,
				131
			],
			[
				123,
				-29
			],
			[
				144,
				147
			],
			[
				111,
				185
			],
			[
				56,
				-17
			],
			[
				321,
				-522
			]
		],
		[
			[
				56480,
				82037
			],
			[
				131,
				662
			],
			[
				88,
				81
			],
			[
				-24,
				201
			],
			[
				34,
				135
			]
		],
		[
			[
				56811,
				83919
			],
			[
				-68,
				-100
			],
			[
				-90,
				274
			],
			[
				60,
				105
			],
			[
				-68,
				180
			]
		],
		[
			[
				56645,
				84378
			],
			[
				-91,
				-13
			],
			[
				-74,
				181
			],
			[
				-116,
				54
			],
			[
				-104,
				-222
			]
		],
		[
			[
				56260,
				84378
			],
			[
				-73,
				10
			],
			[
				-15,
				-150
			],
			[
				-136,
				-21
			],
			[
				-180,
				74
			]
		],
		[
			[
				55856,
				84291
			],
			[
				-385,
				-354
			],
			[
				-397,
				-436
			]
		],
		[
			[
				55074,
				83501
			],
			[
				-261,
				-1435
			],
			[
				224,
				285
			],
			[
				80,
				-5
			]
		],
		[
			[
				63643,
				63597
			],
			[
				-4,
				-172
			],
			[
				268,
				-5
			],
			[
				986,
				-84
			]
		],
		[
			[
				64893,
				63336
			],
			[
				11,
				274
			]
		],
		[
			[
				64904,
				63610
			],
			[
				-62,
				-193
			],
			[
				-75,
				12
			],
			[
				49,
				227
			],
			[
				17,
				268
			],
			[
				-28,
				105
			],
			[
				-82,
				-2
			],
			[
				-9,
				-278
			],
			[
				-58,
				83
			],
			[
				-29,
				190
			],
			[
				43,
				117
			],
			[
				129,
				-8
			],
			[
				20,
				100
			],
			[
				-71,
				152
			],
			[
				-72,
				27
			],
			[
				-16,
				-218
			],
			[
				-50,
				187
			]
		],
		[
			[
				64610,
				64379
			],
			[
				-854,
				63
			]
		],
		[
			[
				63756,
				64442
			],
			[
				-6,
				-341
			],
			[
				-96,
				6
			],
			[
				-11,
				-510
			]
		],
		[
			[
				92829,
				19131
			],
			[
				-215,
				136
			],
			[
				-71,
				99
			],
			[
				-26,
				207
			],
			[
				49,
				107
			],
			[
				-1,
				193
			],
			[
				57,
				101
			]
		],
		[
			[
				92622,
				19974
			],
			[
				-219,
				-39
			],
			[
				-204,
				125
			],
			[
				-109,
				-35
			]
		],
		[
			[
				92090,
				20025
			],
			[
				42,
				-290
			],
			[
				-16,
				-95
			],
			[
				93,
				-486
			],
			[
				-18,
				-46
			],
			[
				-370,
				-162
			]
		],
		[
			[
				91821,
				18946
			],
			[
				49,
				-329
			]
		],
		[
			[
				88458,
				45837
			],
			[
				208,
				194
			],
			[
				132,
				30
			],
			[
				210,
				154
			],
			[
				1,
				221
			],
			[
				-86,
				112
			],
			[
				39,
				506
			]
		],
		[
			[
				88962,
				47054
			],
			[
				-108,
				-83
			],
			[
				-6,
				-212
			],
			[
				-70,
				-32
			],
			[
				-53,
				-153
			],
			[
				-157,
				-3
			]
		],
		[
			[
				88568,
				46571
			],
			[
				-83,
				-89
			],
			[
				-42,
				-232
			],
			[
				-150,
				-85
			]
		],
		[
			[
				88293,
				46165
			],
			[
				15,
				-144
			],
			[
				150,
				-184
			]
		],
		[
			[
				18356,
				10883
			],
			[
				243,
				32
			],
			[
				136,
				69
			],
			[
				201,
				-228
			],
			[
				76,
				213
			]
		],
		[
			[
				19012,
				10969
			],
			[
				36,
				268
			],
			[
				152,
				437
			],
			[
				-32,
				330
			],
			[
				-48,
				208
			],
			[
				0,
				219
			],
			[
				-48,
				161
			],
			[
				67,
				167
			],
			[
				129,
				114
			],
			[
				98,
				158
			]
		],
		[
			[
				19366,
				13031
			],
			[
				78,
				288
			],
			[
				-15,
				389
			],
			[
				-84,
				73
			]
		],
		[
			[
				19345,
				13781
			],
			[
				-246,
				159
			],
			[
				-194,
				-110
			],
			[
				-276,
				-33
			]
		],
		[
			[
				18629,
				13797
			],
			[
				-91,
				8
			],
			[
				-116,
				-126
			],
			[
				-89,
				40
			],
			[
				-97,
				150
			],
			[
				-311,
				-54
			]
		],
		[
			[
				17925,
				13815
			],
			[
				117,
				-755
			]
		],
		[
			[
				18042,
				13060
			],
			[
				90,
				-680
			],
			[
				224,
				-1497
			]
		],
		[
			[
				78385,
				49570
			],
			[
				170,
				-389
			]
		],
		[
			[
				78555,
				49181
			],
			[
				189,
				-169
			]
		],
		[
			[
				78744,
				49012
			],
			[
				67,
				227
			],
			[
				71,
				33
			],
			[
				37,
				167
			],
			[
				-53,
				71
			],
			[
				82,
				128
			],
			[
				15,
				194
			],
			[
				58,
				177
			],
			[
				71,
				-19
			],
			[
				103,
				212
			],
			[
				16,
				-111
			],
			[
				194,
				-92
			],
			[
				114,
				43
			]
		],
		[
			[
				79519,
				50042
			],
			[
				23,
				107
			],
			[
				-78,
				342
			]
		],
		[
			[
				79464,
				50491
			],
			[
				-112,
				172
			]
		],
		[
			[
				79352,
				50663
			],
			[
				-79,
				-79
			]
		],
		[
			[
				79273,
				50584
			],
			[
				-264,
				-18
			],
			[
				-247,
				-336
			],
			[
				-98,
				-11
			],
			[
				-90,
				-252
			]
		],
		[
			[
				78574,
				49967
			],
			[
				-27,
				-170
			],
			[
				-162,
				-227
			]
		],
		[
			[
				63993,
				20709
			],
			[
				179,
				-20
			],
			[
				-46,
				-1210
			]
		],
		[
			[
				64126,
				19479
			],
			[
				1320,
				463
			],
			[
				162,
				137
			]
		],
		[
			[
				65608,
				20079
			],
			[
				94,
				78
			]
		],
		[
			[
				65702,
				20157
			],
			[
				15,
				349
			],
			[
				-178,
				21
			],
			[
				14,
				332
			]
		],
		[
			[
				72732,
				73612
			],
			[
				81,
				-11
			],
			[
				-22,
				-332
			]
		],
		[
			[
				72791,
				73269
			],
			[
				192,
				-37
			],
			[
				23,
				330
			],
			[
				207,
				-42
			]
		],
		[
			[
				73213,
				73520
			],
			[
				52,
				274
			],
			[
				-26,
				401
			],
			[
				96,
				-19
			],
			[
				32,
				489
			],
			[
				-51,
				188
			],
			[
				64,
				-16
			]
		],
		[
			[
				73330,
				75531
			],
			[
				-160,
				-31
			],
			[
				-23,
				-278
			],
			[
				-201,
				336
			],
			[
				-25,
				-291
			],
			[
				-187,
				39
			]
		],
		[
			[
				72734,
				75306
			],
			[
				-29,
				-496
			],
			[
				96,
				-18
			],
			[
				-69,
				-1180
			]
		],
		[
			[
				61496,
				60503
			],
			[
				725,
				-24
			]
		],
		[
			[
				62221,
				60479
			],
			[
				85,
				-33
			],
			[
				10,
				685
			]
		],
		[
			[
				62316,
				61131
			],
			[
				4,
				303
			],
			[
				-96,
				81
			],
			[
				5,
				302
			],
			[
				-469,
				16
			]
		],
		[
			[
				61760,
				61833
			],
			[
				-255,
				14
			]
		],
		[
			[
				50415,
				52484
			],
			[
				8,
				355
			]
		],
		[
			[
				49446,
				53804
			],
			[
				17,
				-1013
			]
		],
		[
			[
				79242,
				78878
			],
			[
				55,
				-211
			],
			[
				-15,
				-122
			],
			[
				68,
				-174
			]
		],
		[
			[
				79350,
				78371
			],
			[
				20,
				-128
			],
			[
				129,
				-111
			],
			[
				159,
				-3
			],
			[
				194,
				143
			],
			[
				230,
				113
			]
		],
		[
			[
				80082,
				78385
			],
			[
				110,
				1330
			],
			[
				69,
				63
			],
			[
				-65,
				145
			]
		],
		[
			[
				80196,
				79923
			],
			[
				-138,
				207
			]
		],
		[
			[
				80058,
				80130
			],
			[
				-99,
				-109
			],
			[
				-93,
				-255
			],
			[
				-220,
				-228
			],
			[
				-11,
				-59
			],
			[
				-182,
				-67
			],
			[
				-126,
				15
			],
			[
				-66,
				-257
			],
			[
				-19,
				-292
			]
		],
		[
			[
				79343,
				75285
			],
			[
				143,
				-35
			]
		],
		[
			[
				79582,
				76445
			],
			[
				-85,
				97
			]
		],
		[
			[
				79497,
				76542
			],
			[
				-74,
				-361
			],
			[
				-260,
				49
			],
			[
				-25,
				-285
			],
			[
				-32,
				8
			]
		],
		[
			[
				79106,
				75953
			],
			[
				47,
				-155
			],
			[
				-22,
				-323
			],
			[
				240,
				-54
			],
			[
				-28,
				-136
			]
		],
		[
			[
				78755,
				68067
			],
			[
				80,
				-19
			],
			[
				203,
				-272
			],
			[
				44,
				-236
			],
			[
				129,
				-193
			],
			[
				54,
				-13
			]
		],
		[
			[
				79265,
				67334
			],
			[
				37,
				240
			],
			[
				157,
				148
			]
		],
		[
			[
				79459,
				67722
			],
			[
				144,
				329
			],
			[
				96,
				29
			],
			[
				43,
				267
			],
			[
				62,
				102
			],
			[
				11,
				368
			]
		],
		[
			[
				79815,
				68817
			],
			[
				-72,
				26
			],
			[
				-185,
				190
			],
			[
				-30,
				-47
			],
			[
				-153,
				144
			],
			[
				-76,
				-140
			],
			[
				-252,
				256
			]
		],
		[
			[
				79047,
				69246
			],
			[
				-75,
				-293
			],
			[
				-102,
				-41
			],
			[
				-114,
				-324
			]
		],
		[
			[
				78756,
				68588
			],
			[
				33,
				-160
			],
			[
				-47,
				-151
			],
			[
				13,
				-210
			]
		],
		[
			[
				81015,
				72383
			],
			[
				162,
				106
			]
		],
		[
			[
				81177,
				72489
			],
			[
				124,
				120
			],
			[
				138,
				285
			],
			[
				306,
				242
			],
			[
				88,
				157
			]
		],
		[
			[
				81833,
				73293
			],
			[
				120,
				240
			],
			[
				-18,
				66
			]
		],
		[
			[
				81935,
				73599
			],
			[
				-58,
				222
			],
			[
				-51,
				10
			],
			[
				-51,
				287
			]
		],
		[
			[
				81775,
				74118
			],
			[
				-142,
				-94
			],
			[
				-224,
				126
			],
			[
				-231,
				-49
			]
		],
		[
			[
				81178,
				74101
			],
			[
				-48,
				-301
			],
			[
				-79,
				-79
			],
			[
				-77,
				53
			]
		],
		[
			[
				80974,
				73774
			],
			[
				-99,
				-1147
			],
			[
				140,
				-244
			]
		],
		[
			[
				24009,
				26573
			],
			[
				782,
				269
			]
		],
		[
			[
				24791,
				26842
			],
			[
				-152,
				1310
			]
		],
		[
			[
				24639,
				28152
			],
			[
				-36,
				-12
			],
			[
				-87,
				760
			]
		],
		[
			[
				24516,
				28900
			],
			[
				-196,
				-161
			],
			[
				-123,
				-35
			],
			[
				-12,
				-311
			],
			[
				-118,
				-100
			],
			[
				9,
				-186
			],
			[
				77,
				-152
			],
			[
				-69,
				-244
			],
			[
				-125,
				-31
			]
		],
		[
			[
				69498,
				48709
			],
			[
				189,
				-63
			],
			[
				94,
				109
			],
			[
				95,
				-149
			]
		],
		[
			[
				69876,
				48606
			],
			[
				115,
				-15
			],
			[
				50,
				102
			],
			[
				71,
				-95
			],
			[
				63,
				77
			]
		],
		[
			[
				70175,
				48675
			],
			[
				59,
				1085
			]
		],
		[
			[
				70234,
				49760
			],
			[
				-390,
				65
			],
			[
				-35,
				-50
			]
		],
		[
			[
				69809,
				49775
			],
			[
				-27,
				-521
			],
			[
				-157,
				48
			],
			[
				-12,
				-234
			],
			[
				-101,
				-98
			],
			[
				-14,
				-261
			]
		],
		[
			[
				73795,
				48719
			],
			[
				147,
				-182
			],
			[
				67,
				57
			]
		],
		[
			[
				74009,
				48594
			],
			[
				137,
				42
			],
			[
				88,
				-71
			]
		],
		[
			[
				74234,
				48565
			],
			[
				68,
				596
			]
		],
		[
			[
				74302,
				49161
			],
			[
				-227,
				257
			],
			[
				6,
				93
			]
		],
		[
			[
				74081,
				49511
			],
			[
				-58,
				103
			],
			[
				-219,
				-94
			]
		],
		[
			[
				73804,
				49520
			],
			[
				5,
				-568
			],
			[
				57,
				-234
			],
			[
				-71,
				1
			]
		],
		[
			[
				68494,
				54227
			],
			[
				61,
				47
			],
			[
				105,
				-196
			]
		],
		[
			[
				69087,
				54867
			],
			[
				-212,
				224
			],
			[
				-265,
				205
			]
		],
		[
			[
				68442,
				54875
			],
			[
				52,
				-77
			],
			[
				-54,
				-309
			],
			[
				54,
				-262
			]
		],
		[
			[
				75174,
				47585
			],
			[
				122,
				-196
			],
			[
				159,
				-19
			]
		],
		[
			[
				75455,
				47370
			],
			[
				134,
				365
			]
		],
		[
			[
				75589,
				47735
			],
			[
				-70,
				222
			]
		],
		[
			[
				75519,
				47957
			],
			[
				-50,
				36
			],
			[
				-166,
				-68
			]
		],
		[
			[
				75303,
				47925
			],
			[
				-151,
				-118
			],
			[
				22,
				-222
			]
		],
		[
			[
				80082,
				78385
			],
			[
				98,
				53
			],
			[
				116,
				-127
			],
			[
				49,
				-213
			],
			[
				-52,
				-213
			],
			[
				-70,
				-66
			],
			[
				-81,
				-308
			],
			[
				59,
				-163
			]
		],
		[
			[
				80201,
				77348
			],
			[
				202,
				-26
			]
		],
		[
			[
				80642,
				77293
			],
			[
				145,
				1697
			]
		],
		[
			[
				80787,
				78990
			],
			[
				14,
				116
			],
			[
				-111,
				141
			],
			[
				-82,
				349
			],
			[
				104,
				168
			]
		],
		[
			[
				80712,
				79764
			],
			[
				-86,
				120
			],
			[
				1,
				188
			],
			[
				-127,
				172
			]
		],
		[
			[
				80500,
				80244
			],
			[
				-158,
				-78
			],
			[
				-87,
				-242
			],
			[
				-59,
				-1
			]
		],
		[
			[
				22959,
				9893
			],
			[
				614,
				212
			],
			[
				83,
				-136
			],
			[
				382,
				146
			]
		],
		[
			[
				24038,
				10115
			],
			[
				-185,
				1454
			]
		],
		[
			[
				23853,
				11569
			],
			[
				-192,
				-70
			],
			[
				6,
				-54
			],
			[
				-565,
				-198
			],
			[
				-68,
				-202
			],
			[
				-78,
				11
			],
			[
				-11,
				-192
			],
			[
				-102,
				-85
			]
		],
		[
			[
				22843,
				10779
			],
			[
				64,
				-490
			]
		],
		[
			[
				22907,
				10289
			],
			[
				52,
				-396
			]
		],
		[
			[
				80101,
				73997
			],
			[
				194,
				-44
			],
			[
				41,
				188
			],
			[
				121,
				-28
			]
		],
		[
			[
				80457,
				74113
			],
			[
				69,
				185
			],
			[
				34,
				277
			],
			[
				150,
				216
			],
			[
				58,
				-9
			]
		],
		[
			[
				80768,
				74782
			],
			[
				11,
				123
			],
			[
				150,
				45
			],
			[
				29,
				327
			],
			[
				168,
				244
			]
		],
		[
			[
				81126,
				75521
			],
			[
				-537,
				123
			],
			[
				60,
				831
			],
			[
				461,
				-98
			],
			[
				56,
				12
			],
			[
				-54,
				849
			]
		],
		[
			[
				81112,
				77238
			],
			[
				-392,
				46
			]
		],
		[
			[
				80051,
				75116
			],
			[
				-67,
				-137
			],
			[
				-14,
				-209
			],
			[
				132,
				-19
			],
			[
				-27,
				-339
			]
		],
		[
			[
				75451,
				75642
			],
			[
				-21,
				-206
			],
			[
				32,
				-271
			],
			[
				-20,
				-139
			],
			[
				37,
				-195
			]
		],
		[
			[
				75908,
				74806
			],
			[
				44,
				-9
			],
			[
				32,
				244
			],
			[
				286,
				-48
			]
		],
		[
			[
				76309,
				75661
			],
			[
				-521,
				117
			],
			[
				39,
				700
			]
		],
		[
			[
				75827,
				76478
			],
			[
				-193,
				27
			]
		],
		[
			[
				73405,
				46416
			],
			[
				21,
				-1399
			]
		],
		[
			[
				73845,
				44941
			],
			[
				52,
				766
			]
		],
		[
			[
				73897,
				45707
			],
			[
				-107,
				173
			],
			[
				28,
				133
			]
		],
		[
			[
				73818,
				46013
			],
			[
				-120,
				163
			],
			[
				-76,
				9
			],
			[
				-91,
				203
			],
			[
				-126,
				28
			]
		],
		[
			[
				69729,
				36366
			],
			[
				167,
				-218
			]
		],
		[
			[
				69896,
				36148
			],
			[
				496,
				-81
			]
		],
		[
			[
				70464,
				37319
			],
			[
				-195,
				34
			],
			[
				-89,
				134
			],
			[
				-48,
				319
			],
			[
				-64,
				151
			],
			[
				-233,
				226
			]
		],
		[
			[
				69835,
				38183
			],
			[
				-106,
				-1817
			]
		],
		[
			[
				58062,
				40667
			],
			[
				768,
				-36
			]
		],
		[
			[
				58848,
				41857
			],
			[
				-366,
				25
			]
		],
		[
			[
				58482,
				41882
			],
			[
				-406,
				30
			]
		],
		[
			[
				56361,
				48098
			],
			[
				60,
				-70
			],
			[
				193,
				30
			]
		],
		[
			[
				56614,
				48058
			],
			[
				57,
				-258
			],
			[
				123,
				53
			],
			[
				337,
				-10
			]
		],
		[
			[
				57136,
				48603
			],
			[
				1,
				421
			]
		],
		[
			[
				57137,
				49024
			],
			[
				-771,
				14
			]
		],
		[
			[
				56366,
				49038
			],
			[
				-5,
				-940
			]
		],
		[
			[
				56334,
				53146
			],
			[
				824,
				-11
			]
		],
		[
			[
				57159,
				53210
			],
			[
				10,
				1117
			]
		],
		[
			[
				57169,
				54327
			],
			[
				0,
				99
			]
		],
		[
			[
				57169,
				54426
			],
			[
				-805,
				11
			]
		],
		[
			[
				56364,
				54437
			],
			[
				-24,
				-169
			]
		],
		[
			[
				56340,
				54268
			],
			[
				-6,
				-1122
			]
		],
		[
			[
				62056,
				70890
			],
			[
				1132,
				-78
			]
		],
		[
			[
				63234,
				70807
			],
			[
				0,
				97
			],
			[
				-127,
				403
			],
			[
				-100,
				98
			],
			[
				-61,
				268
			],
			[
				15,
				147
			],
			[
				-73,
				184
			],
			[
				6,
				135
			]
		],
		[
			[
				62894,
				72139
			],
			[
				-59,
				221
			],
			[
				-64,
				-3
			],
			[
				-62,
				214
			],
			[
				-117,
				-39
			],
			[
				-61,
				208
			],
			[
				-92,
				-92
			],
			[
				-44,
				150
			]
		],
		[
			[
				62395,
				72798
			],
			[
				-29,
				-293
			],
			[
				-36,
				-15
			],
			[
				30,
				-226
			],
			[
				-101,
				-247
			],
			[
				-150,
				62
			],
			[
				-19,
				-94
			]
		],
		[
			[
				62090,
				71985
			],
			[
				16,
				-277
			],
			[
				-54,
				-267
			],
			[
				-23,
				-384
			],
			[
				27,
				-167
			]
		],
		[
			[
				59878,
				70983
			],
			[
				466,
				-22
			]
		],
		[
			[
				60344,
				70961
			],
			[
				491,
				-16
			]
		],
		[
			[
				60835,
				70945
			],
			[
				18,
				979
			]
		],
		[
			[
				60853,
				71924
			],
			[
				-192,
				14
			],
			[
				5,
				183
			],
			[
				-74,
				132
			],
			[
				18,
				140
			],
			[
				-34,
				218
			]
		],
		[
			[
				60576,
				72611
			],
			[
				-557,
				31
			]
		],
		[
			[
				60019,
				72642
			],
			[
				-24,
				-506
			],
			[
				-95,
				7
			],
			[
				-22,
				-1160
			]
		],
		[
			[
				97299,
				17401
			],
			[
				37,
				-249
			],
			[
				-19,
				-265
			],
			[
				74,
				19
			],
			[
				-9,
				490
			],
			[
				-83,
				5
			]
		],
		[
			[
				96253,
				16160
			],
			[
				79,
				-71
			],
			[
				16,
				-218
			],
			[
				112,
				102
			],
			[
				88,
				-73
			]
		],
		[
			[
				96548,
				15900
			],
			[
				89,
				-75
			],
			[
				86,
				299
			],
			[
				491,
				-476
			],
			[
				44,
				50
			]
		],
		[
			[
				97379,
				16271
			],
			[
				-1,
				251
			],
			[
				-57,
				165
			],
			[
				-81,
				-73
			],
			[
				-98,
				173
			],
			[
				121,
				218
			],
			[
				10,
				161
			],
			[
				-57,
				107
			],
			[
				-9,
				186
			]
		],
		[
			[
				97207,
				17459
			],
			[
				-147,
				47
			],
			[
				-293,
				-290
			],
			[
				-84,
				267
			],
			[
				-109,
				-25
			]
		],
		[
			[
				96574,
				17458
			],
			[
				-158,
				17
			]
		],
		[
			[
				96416,
				17475
			],
			[
				19,
				-491
			],
			[
				80,
				-12
			],
			[
				-18,
				-340
			],
			[
				-72,
				9
			],
			[
				-19,
				-346
			],
			[
				-107,
				26
			],
			[
				-46,
				-161
			]
		],
		[
			[
				57180,
				55736
			],
			[
				-2,
				-223
			]
		],
		[
			[
				57178,
				55513
			],
			[
				987,
				4
			]
		],
		[
			[
				58162,
				56676
			],
			[
				-975,
				-43
			]
		],
		[
			[
				57187,
				56633
			],
			[
				-7,
				-897
			]
		],
		[
			[
				57712,
				41932
			],
			[
				5,
				391
			],
			[
				29,
				-1
			],
			[
				4,
				332
			]
		],
		[
			[
				57750,
				42654
			],
			[
				-648,
				10
			]
		],
		[
			[
				57102,
				42664
			],
			[
				-20,
				-331
			],
			[
				-40,
				1
			],
			[
				-3,
				-380
			]
		],
		[
			[
				30811,
				6254
			],
			[
				1351,
				393
			]
		],
		[
			[
				32162,
				6647
			],
			[
				-34,
				308
			],
			[
				-249,
				2592
			],
			[
				-198,
				-55
			]
		],
		[
			[
				31681,
				9492
			],
			[
				-380,
				-107
			],
			[
				46,
				-486
			],
			[
				-192,
				-56
			],
			[
				16,
				-162
			],
			[
				-288,
				-87
			],
			[
				17,
				-165
			],
			[
				-152,
				-53
			],
			[
				-120,
				-177
			]
		],
		[
			[
				30628,
				8199
			],
			[
				183,
				-1945
			]
		],
		[
			[
				28779,
				18661
			],
			[
				235,
				-159
			],
			[
				108,
				-261
			],
			[
				49,
				82
			],
			[
				420,
				102
			]
		],
		[
			[
				29591,
				18425
			],
			[
				92,
				-64
			],
			[
				72,
				-248
			],
			[
				135,
				-28
			],
			[
				142,
				45
			],
			[
				25,
				123
			],
			[
				145,
				156
			]
		],
		[
			[
				30202,
				18409
			],
			[
				119,
				135
			],
			[
				-11,
				109
			],
			[
				96,
				27
			],
			[
				153,
				435
			],
			[
				285,
				81
			],
			[
				-102,
				1100
			],
			[
				-36,
				-10
			],
			[
				-222,
				2261
			]
		],
		[
			[
				30484,
				22547
			],
			[
				-73,
				138
			],
			[
				-107,
				-22
			]
		],
		[
			[
				30334,
				70516
			],
			[
				109,
				-1337
			]
		],
		[
			[
				30443,
				69179
			],
			[
				356,
				85
			],
			[
				-50,
				662
			],
			[
				383,
				89
			],
			[
				-27,
				330
			],
			[
				205,
				47
			],
			[
				-131,
				1664
			],
			[
				25,
				6
			],
			[
				-64,
				822
			],
			[
				576,
				128
			]
		],
		[
			[
				31716,
				73012
			],
			[
				-7,
				310
			]
		],
		[
			[
				31709,
				73322
			],
			[
				-129,
				1713
			],
			[
				-1587,
				-359
			]
		],
		[
			[
				29993,
				74676
			],
			[
				341,
				-4160
			]
		],
		[
			[
				84490,
				29756
			],
			[
				-24,
				-242
			],
			[
				96,
				-32
			],
			[
				-18,
				-172
			],
			[
				273,
				-79
			]
		],
		[
			[
				84817,
				29231
			],
			[
				200,
				-65
			]
		],
		[
			[
				85474,
				29373
			],
			[
				106,
				794
			],
			[
				214,
				-99
			]
		],
		[
			[
				85794,
				30068
			],
			[
				123,
				1047
			]
		],
		[
			[
				75878,
				60451
			],
			[
				-1,
				-73
			],
			[
				129,
				-185
			],
			[
				29,
				81
			],
			[
				182,
				-53
			],
			[
				110,
				-198
			]
		],
		[
			[
				76327,
				60023
			],
			[
				66,
				215
			],
			[
				64,
				45
			],
			[
				211,
				-192
			],
			[
				257,
				-29
			]
		],
		[
			[
				76925,
				60062
			],
			[
				-37,
				367
			]
		],
		[
			[
				76888,
				60429
			],
			[
				-201,
				108
			],
			[
				-82,
				258
			],
			[
				-92,
				136
			],
			[
				20,
				79
			],
			[
				-77,
				169
			]
		],
		[
			[
				76456,
				61179
			],
			[
				-224,
				48
			]
		],
		[
			[
				75885,
				61298
			],
			[
				-7,
				-847
			]
		],
		[
			[
				52505,
				14742
			],
			[
				881,
				18
			]
		],
		[
			[
				53442,
				15419
			],
			[
				-17,
				321
			],
			[
				38,
				298
			],
			[
				2,
				372
			]
		],
		[
			[
				47561,
				18416
			],
			[
				893,
				74
			]
		],
		[
			[
				47738,
				21068
			],
			[
				-20,
				-2
			]
		],
		[
			[
				47718,
				21066
			],
			[
				11,
				-87
			],
			[
				-156,
				-414
			],
			[
				-15,
				-284
			],
			[
				121,
				-388
			],
			[
				-31,
				-123
			],
			[
				54,
				-178
			],
			[
				-53,
				-381
			]
		],
		[
			[
				47649,
				19211
			],
			[
				26,
				-294
			],
			[
				56,
				-103
			],
			[
				-61,
				-157
			],
			[
				31,
				-75
			],
			[
				-140,
				-166
			]
		],
		[
			[
				54800,
				55766
			],
			[
				314,
				-1
			]
		],
		[
			[
				55114,
				55765
			],
			[
				0,
				221
			],
			[
				-41,
				0
			],
			[
				2,
				1329
			]
		],
		[
			[
				55075,
				57315
			],
			[
				-5,
				672
			]
		],
		[
			[
				54735,
				57987
			],
			[
				0,
				-2220
			]
		],
		[
			[
				74511,
				47962
			],
			[
				120,
				-86
			],
			[
				6,
				-134
			]
		],
		[
			[
				75092,
				47494
			],
			[
				-10,
				107
			],
			[
				92,
				-16
			]
		],
		[
			[
				75303,
				47925
			],
			[
				-133,
				370
			]
		],
		[
			[
				75170,
				48295
			],
			[
				-132,
				249
			],
			[
				-160,
				178
			],
			[
				-112,
				2
			]
		],
		[
			[
				74766,
				48724
			],
			[
				-74,
				-412
			],
			[
				-114,
				-132
			],
			[
				-67,
				-218
			]
		],
		[
			[
				75589,
				47735
			],
			[
				133,
				111
			],
			[
				353,
				-347
			]
		],
		[
			[
				76075,
				47499
			],
			[
				80,
				73
			],
			[
				51,
				264
			],
			[
				208,
				79
			],
			[
				28,
				60
			]
		],
		[
			[
				76442,
				47975
			],
			[
				-69,
				140
			],
			[
				9,
				311
			],
			[
				-196,
				364
			]
		],
		[
			[
				76186,
				48790
			],
			[
				-109,
				-60
			],
			[
				-45,
				-220
			],
			[
				-107,
				-75
			],
			[
				-139,
				17
			]
		],
		[
			[
				75786,
				48452
			],
			[
				20,
				-75
			],
			[
				-81,
				-165
			],
			[
				-73,
				24
			],
			[
				-75,
				-228
			],
			[
				-58,
				-51
			]
		],
		[
			[
				59572,
				75507
			],
			[
				-3,
				-263
			],
			[
				160,
				-79
			]
		],
		[
			[
				59729,
				75165
			],
			[
				11,
				128
			],
			[
				129,
				37
			],
			[
				80,
				-65
			],
			[
				-2,
				-266
			],
			[
				221,
				-11
			],
			[
				-72,
				-138
			],
			[
				36,
				-182
			],
			[
				-100,
				-349
			]
		],
		[
			[
				60032,
				74319
			],
			[
				467,
				-22
			]
		],
		[
			[
				60499,
				74297
			],
			[
				53,
				168
			],
			[
				88,
				600
			],
			[
				-60,
				368
			],
			[
				-107,
				274
			],
			[
				-8,
				278
			]
		],
		[
			[
				60465,
				75985
			],
			[
				19,
				108
			],
			[
				119,
				74
			],
			[
				-8,
				133
			],
			[
				195,
				91
			],
			[
				172,
				301
			]
		],
		[
			[
				60962,
				76692
			],
			[
				-173,
				303
			],
			[
				-109,
				92
			],
			[
				-75,
				199
			],
			[
				-129,
				90
			]
		],
		[
			[
				60476,
				77376
			],
			[
				-98,
				-63
			],
			[
				-387,
				19
			]
		],
		[
			[
				59991,
				77332
			],
			[
				-12,
				-666
			],
			[
				-192,
				10
			],
			[
				-14,
				-676
			],
			[
				-194,
				8
			],
			[
				-7,
				-501
			]
		],
		[
			[
				76354,
				36022
			],
			[
				7,
				107
			],
			[
				173,
				-42
			],
			[
				54,
				533
			]
		],
		[
			[
				76588,
				36620
			],
			[
				13,
				132
			]
		],
		[
			[
				76601,
				36752
			],
			[
				-957,
				228
			]
		],
		[
			[
				75644,
				36980
			],
			[
				-64,
				-942
			]
		],
		[
			[
				65296,
				63989
			],
			[
				48,
				-139
			],
			[
				264,
				169
			],
			[
				184,
				-188
			],
			[
				21,
				-83
			],
			[
				343,
				-38
			]
		],
		[
			[
				66156,
				63710
			],
			[
				15,
				334
			],
			[
				96,
				-11
			],
			[
				21,
				504
			]
		],
		[
			[
				65322,
				64642
			],
			[
				-85,
				-268
			],
			[
				40,
				-84
			],
			[
				-35,
				-209
			],
			[
				54,
				-92
			]
		],
		[
			[
				64939,
				72573
			],
			[
				60,
				12
			],
			[
				125,
				-285
			]
		],
		[
			[
				65124,
				72300
			],
			[
				12,
				328
			],
			[
				386,
				-36
			],
			[
				12,
				335
			],
			[
				333,
				-33
			]
		],
		[
			[
				65867,
				72894
			],
			[
				-195,
				441
			],
			[
				-46,
				153
			],
			[
				28,
				244
			],
			[
				-92,
				73
			],
			[
				-3,
				228
			],
			[
				47,
				235
			]
		],
		[
			[
				65606,
				74268
			],
			[
				-918,
				97
			]
		],
		[
			[
				64688,
				74365
			],
			[
				-41,
				-676
			]
		],
		[
			[
				64647,
				73689
			],
			[
				50,
				-317
			],
			[
				-21,
				-154
			],
			[
				143,
				16
			],
			[
				-17,
				-135
			],
			[
				85,
				-281
			],
			[
				-39,
				-126
			],
			[
				91,
				-119
			]
		],
		[
			[
				50182,
				37364
			],
			[
				755,
				31
			]
		],
		[
			[
				50937,
				37395
			],
			[
				-7,
				1003
			]
		],
		[
			[
				50930,
				38398
			],
			[
				-5,
				335
			]
		],
		[
			[
				50160,
				38697
			],
			[
				22,
				-1333
			]
		],
		[
			[
				50933,
				38733
			],
			[
				22,
				1
			],
			[
				-10,
				499
			],
			[
				290,
				12
			],
			[
				82,
				-109
			],
			[
				258,
				8
			],
			[
				0,
				-57
			],
			[
				321,
				7
			],
			[
				7,
				-330
			]
		],
		[
			[
				51903,
				38764
			],
			[
				175,
				4
			],
			[
				-2,
				239
			]
		],
		[
			[
				52076,
				39007
			],
			[
				-283,
				370
			],
			[
				-107,
				233
			]
		],
		[
			[
				51686,
				39610
			],
			[
				-98,
				224
			],
			[
				-194,
				134
			],
			[
				-151,
				258
			],
			[
				-97,
				94
			],
			[
				-234,
				437
			]
		],
		[
			[
				50930,
				38398
			],
			[
				319,
				13
			],
			[
				98,
				-111
			],
			[
				259,
				5
			],
			[
				93,
				-53
			]
		],
		[
			[
				51699,
				38252
			],
			[
				209,
				4
			],
			[
				-5,
				508
			]
		],
		[
			[
				90004,
				33904
			],
			[
				98,
				11
			],
			[
				84,
				-121
			],
			[
				-1,
				-169
			],
			[
				164,
				-544
			]
		],
		[
			[
				90349,
				33081
			],
			[
				31,
				187
			],
			[
				74,
				-102
			],
			[
				122,
				118
			],
			[
				131,
				1
			],
			[
				65,
				69
			],
			[
				48,
				286
			]
		],
		[
			[
				90820,
				33640
			],
			[
				-105,
				10
			],
			[
				48,
				143
			],
			[
				-43,
				276
			],
			[
				18,
				229
			]
		],
		[
			[
				90738,
				34298
			],
			[
				-113,
				305
			]
		],
		[
			[
				90625,
				34603
			],
			[
				-89,
				131
			],
			[
				-9,
				-188
			],
			[
				-99,
				-200
			],
			[
				-259,
				254
			]
		],
		[
			[
				90169,
				34600
			],
			[
				-172,
				-24
			],
			[
				-131,
				-129
			]
		],
		[
			[
				89866,
				34447
			],
			[
				56,
				-85
			],
			[
				82,
				-458
			]
		],
		[
			[
				35572,
				67542
			],
			[
				-21,
				342
			],
			[
				49,
				9
			]
		],
		[
			[
				35600,
				67893
			],
			[
				-73,
				1295
			]
		],
		[
			[
				35527,
				69188
			],
			[
				-1071,
				684
			],
			[
				-11,
				167
			],
			[
				-764,
				-158
			],
			[
				-47,
				668
			]
		],
		[
			[
				33634,
				70549
			],
			[
				-574,
				-118
			]
		],
		[
			[
				33060,
				70431
			],
			[
				-232,
				-143
			],
			[
				75,
				-500
			],
			[
				-80,
				-370
			],
			[
				31,
				-218
			],
			[
				-17,
				-163
			],
			[
				-88,
				-62
			],
			[
				-39,
				-158
			],
			[
				56,
				-240
			],
			[
				-35,
				-531
			],
			[
				-230,
				-46
			]
		],
		[
			[
				86140,
				26221
			],
			[
				269,
				-109
			],
			[
				58,
				96
			],
			[
				18,
				-150
			],
			[
				107,
				60
			],
			[
				-34,
				-220
			],
			[
				65,
				74
			],
			[
				308,
				137
			]
		],
		[
			[
				86931,
				26109
			],
			[
				92,
				166
			],
			[
				-19,
				172
			],
			[
				94,
				-15
			],
			[
				173,
				1095
			]
		],
		[
			[
				86676,
				27821
			],
			[
				-168,
				-247
			],
			[
				-147,
				67
			],
			[
				-94,
				-597
			],
			[
				-45,
				22
			],
			[
				-54,
				-345
			],
			[
				38,
				-39
			],
			[
				-66,
				-461
			]
		],
		[
			[
				87257,
				53659
			],
			[
				144,
				114
			],
			[
				157,
				9
			],
			[
				45,
				209
			],
			[
				96,
				-26
			],
			[
				141,
				81
			]
		],
		[
			[
				87840,
				54046
			],
			[
				149,
				339
			],
			[
				24,
				332
			]
		],
		[
			[
				88013,
				54717
			],
			[
				-76,
				-27
			],
			[
				-130,
				169
			],
			[
				-61,
				178
			],
			[
				-219,
				402
			]
		],
		[
			[
				87150,
				54818
			],
			[
				41,
				-570
			],
			[
				67,
				-443
			],
			[
				-1,
				-146
			]
		],
		[
			[
				84603,
				56995
			],
			[
				102,
				-175
			],
			[
				140,
				-130
			],
			[
				127,
				-239
			],
			[
				90,
				43
			],
			[
				105,
				206
			],
			[
				109,
				75
			]
		],
		[
			[
				85276,
				56775
			],
			[
				-294,
				931
			]
		],
		[
			[
				84982,
				57706
			],
			[
				-179,
				-127
			],
			[
				-47,
				-170
			],
			[
				-151,
				-226
			],
			[
				-2,
				-188
			]
		],
		[
			[
				79321,
				38418
			],
			[
				127,
				-30
			],
			[
				-18,
				-280
			],
			[
				247,
				-83
			]
		],
		[
			[
				79677,
				38025
			],
			[
				282,
				-73
			],
			[
				28,
				318
			],
			[
				96,
				-23
			],
			[
				13,
				165
			]
		],
		[
			[
				80096,
				38412
			],
			[
				13,
				168
			],
			[
				-95,
				23
			],
			[
				25,
				338
			],
			[
				-13,
				173
			]
		],
		[
			[
				80026,
				39114
			],
			[
				-550,
				114
			]
		],
		[
			[
				79476,
				39228
			],
			[
				-37,
				-504
			],
			[
				-95,
				19
			],
			[
				-23,
				-325
			]
		],
		[
			[
				51742,
				58941
			],
			[
				-15,
				1692
			]
		],
		[
			[
				51727,
				60633
			],
			[
				-958,
				-30
			]
		],
		[
			[
				85972,
				36234
			],
			[
				234,
				-15
			],
			[
				189,
				-64
			],
			[
				82,
				133
			],
			[
				93,
				-42
			]
		],
		[
			[
				86570,
				36246
			],
			[
				-11,
				44
			]
		],
		[
			[
				86559,
				36290
			],
			[
				-111,
				132
			],
			[
				-253,
				175
			],
			[
				35,
				76
			],
			[
				-183,
				225
			],
			[
				-362,
				591
			],
			[
				-29,
				135
			],
			[
				-142,
				352
			]
		],
		[
			[
				85514,
				37976
			],
			[
				-41,
				117
			]
		],
		[
			[
				85473,
				38093
			],
			[
				-130,
				-408
			]
		],
		[
			[
				85343,
				37685
			],
			[
				182,
				-503
			],
			[
				-10,
				-88
			],
			[
				161,
				-319
			],
			[
				-13,
				-105
			],
			[
				165,
				-261
			],
			[
				144,
				-175
			]
		],
		[
			[
				69790,
				57792
			],
			[
				56,
				315
			],
			[
				1,
				301
			],
			[
				37,
				86
			]
		],
		[
			[
				69589,
				59136
			],
			[
				-182,
				34
			],
			[
				-54,
				136
			],
			[
				-110,
				-118
			],
			[
				-94,
				4
			]
		],
		[
			[
				69149,
				59192
			],
			[
				46,
				-373
			],
			[
				-156,
				-20
			],
			[
				6,
				-316
			],
			[
				127,
				-414
			],
			[
				0,
				-118
			],
			[
				-76,
				-308
			]
		],
		[
			[
				74834,
				58266
			],
			[
				158,
				-357
			]
		],
		[
			[
				74992,
				57909
			],
			[
				224,
				-281
			],
			[
				173,
				-102
			],
			[
				168,
				-280
			]
		],
		[
			[
				75557,
				57246
			],
			[
				163,
				505
			]
		],
		[
			[
				75720,
				57751
			],
			[
				18,
				47
			]
		],
		[
			[
				75738,
				57798
			],
			[
				-117,
				41
			],
			[
				-15,
				238
			],
			[
				-53,
				69
			],
			[
				-2,
				192
			],
			[
				-52,
				-21
			],
			[
				-123,
				259
			],
			[
				-53,
				190
			],
			[
				-88,
				119
			]
		],
		[
			[
				75235,
				58885
			],
			[
				-63,
				12
			]
		],
		[
			[
				75172,
				58897
			],
			[
				-16,
				-242
			],
			[
				-94,
				38
			],
			[
				-105,
				-172
			]
		],
		[
			[
				52998,
				11449
			],
			[
				1159,
				15
			]
		],
		[
			[
				54157,
				11464
			],
			[
				1174,
				21
			]
		],
		[
			[
				55331,
				11485
			],
			[
				16,
				656
			],
			[
				1,
				729
			]
		],
		[
			[
				55348,
				12870
			],
			[
				-1364,
				-6
			]
		],
		[
			[
				53014,
				12853
			],
			[
				7,
				-77
			]
		],
		[
			[
				53021,
				12776
			],
			[
				39,
				-325
			],
			[
				-44,
				-305
			],
			[
				23,
				-383
			],
			[
				-39,
				-308
			]
		],
		[
			[
				53000,
				11455
			],
			[
				-2,
				-6
			]
		],
		[
			[
				20806,
				14930
			],
			[
				343,
				136
			],
			[
				-44,
				326
			],
			[
				-62,
				-26
			],
			[
				-42,
				313
			],
			[
				55,
				306
			],
			[
				-52,
				382
			],
			[
				66,
				24
			],
			[
				-43,
				325
			],
			[
				15,
				362
			],
			[
				93,
				33
			],
			[
				-13,
				106
			],
			[
				71,
				194
			],
			[
				-26,
				218
			],
			[
				67,
				26
			],
			[
				-23,
				165
			],
			[
				322,
				124
			],
			[
				-41,
				317
			]
		],
		[
			[
				21492,
				18261
			],
			[
				-456,
				-174
			],
			[
				-45,
				324
			],
			[
				-329,
				-129
			],
			[
				-110,
				-332
			],
			[
				-183,
				201
			],
			[
				-143,
				-141
			],
			[
				-79,
				-143
			],
			[
				-113,
				63
			],
			[
				-107,
				189
			]
		],
		[
			[
				19927,
				18119
			],
			[
				-83,
				-44
			],
			[
				-76,
				-155
			],
			[
				-131,
				64
			],
			[
				9,
				-84
			],
			[
				-121,
				-94
			]
		],
		[
			[
				19525,
				17806
			],
			[
				45,
				-315
			],
			[
				-46,
				-19
			],
			[
				64,
				-431
			],
			[
				-166,
				-69
			],
			[
				32,
				-226
			],
			[
				-63,
				-26
			],
			[
				45,
				-326
			],
			[
				134,
				17
			],
			[
				282,
				116
			],
			[
				39,
				-274
			],
			[
				350,
				150
			],
			[
				22,
				-161
			],
			[
				124,
				49
			],
			[
				112,
				-809
			],
			[
				77,
				31
			],
			[
				44,
				-316
			],
			[
				55,
				-149
			],
			[
				108,
				44
			],
			[
				23,
				-162
			]
		],
		[
			[
				59837,
				24008
			],
			[
				945,
				-61
			]
		],
		[
			[
				60846,
				25290
			],
			[
				-1000,
				48
			]
		],
		[
			[
				59846,
				25338
			],
			[
				23,
				-290
			],
			[
				-29,
				-348
			],
			[
				-46,
				-193
			],
			[
				71,
				-113
			],
			[
				-28,
				-386
			]
		],
		[
			[
				45614,
				85810
			],
			[
				1088,
				77
			]
		],
		[
			[
				46702,
				85887
			],
			[
				-48,
				1670
			]
		],
		[
			[
				46611,
				89273
			],
			[
				-194,
				-11
			]
		],
		[
			[
				46417,
				89262
			],
			[
				-153,
				-346
			],
			[
				14,
				-123
			],
			[
				-111,
				-336
			],
			[
				27,
				-151
			],
			[
				-56,
				-136
			],
			[
				61,
				-89
			],
			[
				-98,
				-61
			],
			[
				-40,
				-141
			],
			[
				29,
				-123
			],
			[
				-94,
				-101
			],
			[
				4,
				-125
			],
			[
				-103,
				-84
			],
			[
				-6,
				-301
			],
			[
				-47,
				-91
			],
			[
				-21,
				-240
			],
			[
				-46,
				-6
			],
			[
				-53,
				-293
			],
			[
				-58,
				-9
			],
			[
				-52,
				-696
			]
		],
		[
			[
				78883,
				34954
			],
			[
				155,
				-42
			],
			[
				-22,
				-271
			],
			[
				316,
				-87
			],
			[
				-27,
				-277
			],
			[
				161,
				-47
			]
		],
		[
			[
				79466,
				34230
			],
			[
				76,
				812
			]
		],
		[
			[
				79542,
				35042
			],
			[
				55,
				585
			]
		],
		[
			[
				79597,
				35627
			],
			[
				-639,
				170
			]
		],
		[
			[
				78958,
				35797
			],
			[
				-75,
				-843
			]
		],
		[
			[
				50568,
				61264
			],
			[
				4,
				673
			],
			[
				379,
				13
			]
		],
		[
			[
				49973,
				63925
			],
			[
				13,
				-935
			]
		],
		[
			[
				49986,
				62990
			],
			[
				18,
				-1414
			]
		],
		[
			[
				27490,
				72506
			],
			[
				-56,
				440
			],
			[
				-95,
				1067
			]
		],
		[
			[
				27339,
				74013
			],
			[
				-1155,
				-307
			],
			[
				-514,
				-507
			]
		],
		[
			[
				25670,
				73199
			],
			[
				34,
				-360
			],
			[
				380,
				103
			],
			[
				72,
				-774
			],
			[
				577,
				155
			],
			[
				2,
				-19
			],
			[
				755,
				202
			]
		],
		[
			[
				72353,
				75376
			],
			[
				381,
				-70
			]
		],
		[
			[
				73446,
				77231
			],
			[
				-383,
				66
			]
		],
		[
			[
				73063,
				77297
			],
			[
				-569,
				96
			]
		],
		[
			[
				72494,
				77393
			],
			[
				-24,
				-19
			],
			[
				-42,
				-731
			]
		],
		[
			[
				72428,
				76643
			],
			[
				-38,
				-587
			],
			[
				61,
				-100
			],
			[
				-29,
				-254
			],
			[
				-50,
				8
			],
			[
				-19,
				-334
			]
		],
		[
			[
				9446,
				43257
			],
			[
				101,
				75
			]
		],
		[
			[
				9547,
				43332
			],
			[
				173,
				52
			],
			[
				29,
				67
			],
			[
				136,
				-57
			],
			[
				647,
				316
			]
		],
		[
			[
				10532,
				43710
			],
			[
				-25,
				335
			],
			[
				72,
				58
			],
			[
				-36,
				297
			],
			[
				-107,
				63
			],
			[
				22,
				540
			],
			[
				97,
				-89
			],
			[
				33,
				87
			],
			[
				91,
				-28
			],
			[
				64,
				225
			]
		],
		[
			[
				10743,
				45198
			],
			[
				-59,
				154
			],
			[
				49,
				108
			],
			[
				-87,
				130
			],
			[
				-25,
				158
			],
			[
				39,
				105
			]
		],
		[
			[
				9998,
				45792
			],
			[
				13,
				-74
			],
			[
				-103,
				-101
			],
			[
				-59,
				-172
			],
			[
				-61,
				-355
			],
			[
				-122,
				-248
			],
			[
				-217,
				-293
			],
			[
				-67,
				-306
			],
			[
				-70,
				-121
			],
			[
				-50,
				-232
			]
		],
		[
			[
				9262,
				43890
			],
			[
				-47,
				-155
			],
			[
				7,
				-161
			],
			[
				65,
				-255
			],
			[
				159,
				-62
			]
		],
		[
			[
				10444,
				25536
			],
			[
				293,
				150
			],
			[
				1200,
				573
			]
		],
		[
			[
				11937,
				26259
			],
			[
				1331,
				677
			]
		],
		[
			[
				11095,
				29152
			],
			[
				-14,
				-96
			],
			[
				49,
				-277
			],
			[
				71,
				-143
			],
			[
				-83,
				-194
			],
			[
				-141,
				56
			],
			[
				-349,
				333
			],
			[
				-154,
				-65
			],
			[
				-123,
				27
			],
			[
				-70,
				354
			],
			[
				62,
				120
			],
			[
				-42,
				250
			],
			[
				-78,
				-113
			],
			[
				-102,
				-18
			],
			[
				-10,
				-176
			],
			[
				-91,
				-167
			],
			[
				-188,
				-106
			],
			[
				-48,
				-275
			],
			[
				-124,
				-118
			],
			[
				-25,
				-108
			]
		],
		[
			[
				9635,
				28436
			],
			[
				-10,
				-285
			],
			[
				25,
				-456
			],
			[
				-33,
				-83
			],
			[
				-256,
				-128
			]
		],
		[
			[
				9361,
				27484
			],
			[
				124,
				-234
			],
			[
				-5,
				-325
			],
			[
				-63,
				-260
			],
			[
				166,
				-451
			],
			[
				0,
				-398
			],
			[
				135,
				-184
			],
			[
				138,
				3
			],
			[
				132,
				-318
			]
		],
		[
			[
				35219,
				44620
			],
			[
				-72,
				186
			],
			[
				134,
				204
			],
			[
				-30,
				44
			]
		],
		[
			[
				35251,
				45054
			],
			[
				-191,
				110
			]
		],
		[
			[
				35060,
				45164
			],
			[
				-258,
				246
			],
			[
				-100,
				187
			],
			[
				-139,
				134
			],
			[
				-154,
				-166
			],
			[
				-195,
				30
			],
			[
				-230,
				262
			],
			[
				-111,
				260
			],
			[
				-411,
				206
			],
			[
				-57,
				622
			]
		],
		[
			[
				33405,
				46945
			],
			[
				-50,
				643
			],
			[
				-1169,
				-269
			]
		],
		[
			[
				32186,
				47319
			],
			[
				126,
				-1431
			],
			[
				157,
				-1879
			]
		],
		[
			[
				32873,
				39059
			],
			[
				102,
				-1292
			]
		],
		[
			[
				32975,
				37767
			],
			[
				867,
				205
			],
			[
				1010,
				214
			]
		],
		[
			[
				34852,
				38186
			],
			[
				998,
				206
			]
		],
		[
			[
				35850,
				38392
			],
			[
				-102,
				1543
			],
			[
				-96,
				-20
			],
			[
				-15,
				222
			],
			[
				-96,
				-19
			],
			[
				-104,
				1216
			]
		],
		[
			[
				35437,
				41334
			],
			[
				-1991,
				-429
			],
			[
				-713,
				-167
			]
		],
		[
			[
				32733,
				40738
			],
			[
				140,
				-1679
			]
		],
		[
			[
				37118,
				52623
			],
			[
				-10,
				168
			],
			[
				517,
				93
			]
		],
		[
			[
				37625,
				52884
			],
			[
				26,
				263
			],
			[
				-62,
				269
			],
			[
				19,
				149
			],
			[
				-37,
				293
			],
			[
				-67,
				227
			],
			[
				82,
				193
			]
		],
		[
			[
				37586,
				54278
			],
			[
				-507,
				-89
			]
		],
		[
			[
				37079,
				54189
			],
			[
				-826,
				-147
			]
		],
		[
			[
				36253,
				54042
			],
			[
				-162,
				-599
			],
			[
				0,
				-213
			],
			[
				-134,
				-156
			],
			[
				45,
				-671
			]
		],
		[
			[
				93717,
				27940
			],
			[
				485,
				-193
			],
			[
				11,
				57
			]
		],
		[
			[
				94213,
				27804
			],
			[
				176,
				1049
			]
		],
		[
			[
				94440,
				29167
			],
			[
				-260,
				138
			],
			[
				-340,
				18
			],
			[
				-167,
				-113
			]
		],
		[
			[
				93673,
				29210
			],
			[
				111,
				-198
			],
			[
				-58,
				-305
			],
			[
				-85,
				36
			],
			[
				-68,
				-459
			],
			[
				188,
				-85
			],
			[
				-44,
				-259
			]
		],
		[
			[
				75694,
				76782
			],
			[
				53,
				244
			],
			[
				101,
				173
			],
			[
				20,
				257
			],
			[
				64,
				242
			],
			[
				74,
				76
			]
		],
		[
			[
				76006,
				77774
			],
			[
				25,
				57
			]
		],
		[
			[
				76031,
				77831
			],
			[
				16,
				47
			],
			[
				-75,
				320
			],
			[
				-43,
				59
			]
		],
		[
			[
				75929,
				78257
			],
			[
				-449,
				83
			],
			[
				3,
				167
			],
			[
				-405,
				69
			]
		],
		[
			[
				75078,
				78576
			],
			[
				-97,
				16
			]
		],
		[
			[
				74981,
				78592
			],
			[
				-49,
				-831
			],
			[
				-96,
				17
			],
			[
				-10,
				-166
			],
			[
				-224,
				38
			]
		],
		[
			[
				74602,
				77650
			],
			[
				29,
				-268
			],
			[
				76,
				-171
			],
			[
				47,
				-232
			]
		],
		[
			[
				74869,
				69718
			],
			[
				601,
				-113
			],
			[
				-1,
				-15
			]
		],
		[
			[
				75469,
				69590
			],
			[
				206,
				-29
			],
			[
				100,
				75
			]
		],
		[
			[
				75775,
				69636
			],
			[
				19,
				55
			],
			[
				62,
				938
			]
		],
		[
			[
				75856,
				70629
			],
			[
				-397,
				82
			],
			[
				-15,
				-90
			],
			[
				-313,
				61
			]
		],
		[
			[
				75131,
				70682
			],
			[
				-55,
				-132
			],
			[
				-73,
				-400
			]
		],
		[
			[
				26981,
				30960
			],
			[
				560,
				166
			],
			[
				35,
				-330
			],
			[
				252,
				76
			],
			[
				33,
				-330
			],
			[
				126,
				38
			]
		],
		[
			[
				27987,
				30580
			],
			[
				57,
				17
			],
			[
				46,
				229
			],
			[
				76,
				-2
			],
			[
				-20,
				257
			],
			[
				-78,
				222
			],
			[
				81,
				44
			],
			[
				39,
				-114
			],
			[
				111,
				32
			],
			[
				79,
				280
			]
		],
		[
			[
				28378,
				31545
			],
			[
				72,
				204
			],
			[
				-25,
				261
			],
			[
				-63,
				-18
			],
			[
				-66,
				653
			]
		],
		[
			[
				28296,
				32645
			],
			[
				-90,
				-23
			]
		],
		[
			[
				28206,
				32622
			],
			[
				-44,
				-31
			],
			[
				-1313,
				-384
			]
		],
		[
			[
				26849,
				32207
			],
			[
				132,
				-1247
			]
		],
		[
			[
				65905,
				51432
			],
			[
				728,
				-68
			]
		],
		[
			[
				66633,
				51364
			],
			[
				47,
				-5
			],
			[
				13,
				339
			]
		],
		[
			[
				66732,
				52706
			],
			[
				-520,
				64
			],
			[
				-33,
				152
			],
			[
				-86,
				-25
			]
		],
		[
			[
				66093,
				52897
			],
			[
				75,
				-104
			],
			[
				-75,
				-150
			],
			[
				-20,
				-230
			],
			[
				-110,
				-52
			],
			[
				-65,
				-133
			],
			[
				-90,
				-28
			],
			[
				-18,
				-166
			]
		],
		[
			[
				65790,
				52034
			],
			[
				23,
				-218
			],
			[
				92,
				-384
			]
		],
		[
			[
				83035,
				50447
			],
			[
				87,
				-190
			],
			[
				5,
				-122
			],
			[
				-70,
				-65
			],
			[
				237,
				-389
			],
			[
				136,
				99
			],
			[
				124,
				-363
			]
		],
		[
			[
				83554,
				49417
			],
			[
				96,
				-184
			],
			[
				-23,
				-168
			]
		],
		[
			[
				83627,
				49065
			],
			[
				108,
				71
			],
			[
				94,
				254
			],
			[
				90,
				47
			],
			[
				90,
				-52
			],
			[
				116,
				121
			]
		],
		[
			[
				84041,
				49964
			],
			[
				-205,
				1237
			]
		],
		[
			[
				83836,
				51201
			],
			[
				-85,
				16
			],
			[
				40,
				193
			],
			[
				-121,
				-138
			],
			[
				-91,
				60
			]
		],
		[
			[
				83579,
				51332
			],
			[
				-175,
				-369
			],
			[
				-54,
				19
			],
			[
				-44,
				-147
			],
			[
				-238,
				-70
			]
		],
		[
			[
				83068,
				50765
			],
			[
				-33,
				-318
			]
		],
		[
			[
				49070,
				79011
			],
			[
				992,
				48
			]
		],
		[
			[
				50062,
				79059
			],
			[
				-25,
				162
			],
			[
				151,
				176
			],
			[
				-108,
				181
			],
			[
				13,
				138
			],
			[
				94,
				19
			],
			[
				-127,
				276
			],
			[
				70,
				290
			],
			[
				-22,
				90
			],
			[
				113,
				67
			],
			[
				-2,
				277
			]
		],
		[
			[
				84935,
				45504
			],
			[
				34,
				-223
			]
		],
		[
			[
				84969,
				45281
			],
			[
				37,
				70
			],
			[
				47,
				329
			],
			[
				206,
				308
			],
			[
				78,
				-24
			]
		],
		[
			[
				85337,
				45964
			],
			[
				-106,
				375
			]
		],
		[
			[
				85231,
				46339
			],
			[
				-539,
				-194
			]
		],
		[
			[
				84692,
				46145
			],
			[
				166,
				-282
			],
			[
				77,
				-359
			]
		],
		[
			[
				79863,
				43997
			],
			[
				37,
				-329
			],
			[
				34,
				-13
			]
		],
		[
			[
				79934,
				43655
			],
			[
				16,
				-160
			],
			[
				88,
				-14
			],
			[
				258,
				-247
			]
		],
		[
			[
				80296,
				43234
			],
			[
				80,
				23
			],
			[
				35,
				-203
			],
			[
				92,
				86
			],
			[
				15,
				255
			]
		],
		[
			[
				80518,
				43395
			],
			[
				-35,
				93
			],
			[
				230,
				576
			]
		],
		[
			[
				80713,
				44064
			],
			[
				-145,
				287
			],
			[
				-194,
				196
			]
		],
		[
			[
				80374,
				44547
			],
			[
				-71,
				18
			],
			[
				-85,
				-105
			],
			[
				-71,
				66
			]
		],
		[
			[
				80147,
				44526
			],
			[
				-150,
				23
			],
			[
				-48,
				-269
			],
			[
				-127,
				-137
			],
			[
				41,
				-146
			]
		],
		[
			[
				78329,
				72949
			],
			[
				567,
				-133
			]
		],
		[
			[
				78896,
				72816
			],
			[
				83,
				111
			],
			[
				101,
				-12
			],
			[
				117,
				101
			],
			[
				60,
				-25
			]
		],
		[
			[
				78406,
				73278
			],
			[
				-77,
				-329
			]
		],
		[
			[
				51145,
				89595
			],
			[
				490,
				194
			],
			[
				56,
				-131
			]
		],
		[
			[
				51691,
				89658
			],
			[
				116,
				-12
			],
			[
				107,
				197
			],
			[
				95,
				-73
			],
			[
				124,
				4
			],
			[
				109,
				236
			]
		],
		[
			[
				52242,
				90010
			],
			[
				245,
				665
			]
		],
		[
			[
				52487,
				90675
			],
			[
				-106,
				297
			],
			[
				-76,
				-8
			],
			[
				-49,
				-193
			],
			[
				-148,
				28
			],
			[
				-31,
				-60
			],
			[
				-225,
				31
			],
			[
				-76,
				-41
			],
			[
				-24,
				150
			]
		],
		[
			[
				51752,
				90879
			],
			[
				-168,
				-49
			],
			[
				-39,
				-132
			],
			[
				-58,
				93
			],
			[
				-102,
				-200
			],
			[
				-177,
				-121
			],
			[
				-52,
				47
			],
			[
				19,
				-231
			]
		],
		[
			[
				51175,
				90286
			],
			[
				-4,
				-163
			],
			[
				-161,
				-76
			]
		],
		[
			[
				51010,
				90047
			],
			[
				-39,
				-220
			],
			[
				174,
				-232
			]
		],
		[
			[
				54983,
				85636
			],
			[
				51,
				-126
			]
		],
		[
			[
				55034,
				85510
			],
			[
				97,
				-173
			],
			[
				88,
				-64
			],
			[
				192,
				127
			],
			[
				145,
				-189
			],
			[
				57,
				-376
			],
			[
				170,
				-4
			],
			[
				73,
				-540
			]
		],
		[
			[
				56260,
				84378
			],
			[
				-31,
				347
			],
			[
				345,
				997
			]
		],
		[
			[
				56574,
				85722
			],
			[
				-78,
				115
			],
			[
				-116,
				-58
			],
			[
				-12,
				289
			],
			[
				40,
				86
			],
			[
				76,
				-124
			]
		],
		[
			[
				56484,
				86030
			],
			[
				-25,
				173
			]
		],
		[
			[
				56459,
				86203
			],
			[
				-233,
				344
			],
			[
				-279,
				470
			],
			[
				-112,
				29
			],
			[
				-132,
				129
			]
		],
		[
			[
				55703,
				87175
			],
			[
				-125,
				-21
			],
			[
				-123,
				-251
			],
			[
				-30,
				-169
			],
			[
				-100,
				-106
			],
			[
				-128,
				9
			],
			[
				-39,
				-271
			],
			[
				37,
				-82
			],
			[
				-156,
				-186
			],
			[
				10,
				-252
			],
			[
				-66,
				-210
			]
		],
		[
			[
				55021,
				67944
			],
			[
				154,
				-125
			]
		],
		[
			[
				55175,
				67819
			],
			[
				370,
				-228
			],
			[
				29,
				198
			],
			[
				254,
				51
			],
			[
				181,
				-56
			]
		],
		[
			[
				56009,
				67784
			],
			[
				9,
				1923
			]
		],
		[
			[
				56018,
				69707
			],
			[
				-119,
				-38
			],
			[
				-218,
				-239
			],
			[
				-68,
				30
			],
			[
				-72,
				-119
			],
			[
				-397,
				-51
			],
			[
				-144,
				113
			]
		],
		[
			[
				55000,
				69403
			],
			[
				4,
				-1410
			],
			[
				17,
				-49
			]
		],
		[
			[
				80177,
				42528
			],
			[
				137,
				-299
			]
		],
		[
			[
				80314,
				42229
			],
			[
				106,
				75
			],
			[
				50,
				-98
			],
			[
				238,
				365
			],
			[
				210,
				103
			]
		],
		[
			[
				80918,
				42674
			],
			[
				-152,
				121
			],
			[
				-22,
				144
			],
			[
				-143,
				242
			],
			[
				-83,
				214
			]
		],
		[
			[
				80296,
				43234
			],
			[
				-78,
				-435
			],
			[
				-151,
				80
			]
		],
		[
			[
				80067,
				42879
			],
			[
				110,
				-351
			]
		],
		[
			[
				68104,
				32920
			],
			[
				-13,
				204
			],
			[
				56,
				470
			]
		],
		[
			[
				68147,
				33594
			],
			[
				-652,
				78
			]
		],
		[
			[
				72514,
				65397
			],
			[
				161,
				-313
			],
			[
				176,
				-140
			]
		],
		[
			[
				72851,
				64944
			],
			[
				479,
				-84
			]
		],
		[
			[
				73330,
				64860
			],
			[
				26,
				341
			],
			[
				83,
				26
			],
			[
				137,
				404
			],
			[
				1,
				80
			]
		],
		[
			[
				73577,
				65711
			],
			[
				5,
				128
			],
			[
				-198,
				-49
			],
			[
				-95,
				73
			],
			[
				9,
				140
			],
			[
				-129,
				-8
			],
			[
				-36,
				199
			],
			[
				-123,
				110
			]
		],
		[
			[
				73010,
				66304
			],
			[
				-101,
				-221
			],
			[
				-54,
				27
			],
			[
				-62,
				-244
			],
			[
				-61,
				-80
			],
			[
				-92,
				106
			],
			[
				-53,
				-83
			],
			[
				-77,
				204
			]
		],
		[
			[
				72510,
				66013
			],
			[
				-81,
				16
			],
			[
				58,
				-190
			],
			[
				-12,
				-208
			],
			[
				39,
				-234
			]
		],
		[
			[
				72538,
				68133
			],
			[
				117,
				-266
			],
			[
				34,
				-220
			],
			[
				90,
				44
			],
			[
				71,
				-203
			],
			[
				-67,
				-160
			],
			[
				88,
				-136
			],
			[
				-15,
				-155
			],
			[
				-67,
				-103
			],
			[
				112,
				21
			]
		],
		[
			[
				72901,
				66955
			],
			[
				175,
				-14
			],
			[
				71,
				271
			],
			[
				52,
				48
			],
			[
				369,
				-64
			],
			[
				4,
				113
			]
		],
		[
			[
				73572,
				67309
			],
			[
				-89,
				239
			]
		],
		[
			[
				73483,
				67548
			],
			[
				-97,
				18
			],
			[
				-10,
				398
			],
			[
				-94,
				73
			],
			[
				14,
				336
			],
			[
				-255,
				38
			],
			[
				19,
				390
			],
			[
				-97,
				13
			],
			[
				18,
				350
			]
		],
		[
			[
				72981,
				69164
			],
			[
				-585,
				108
			]
		],
		[
			[
				72396,
				69272
			],
			[
				52,
				-95
			],
			[
				-92,
				-193
			],
			[
				73,
				-104
			],
			[
				33,
				-314
			],
			[
				137,
				-79
			],
			[
				-30,
				-168
			],
			[
				47,
				-58
			],
			[
				-78,
				-128
			]
		],
		[
			[
				60103,
				63038
			],
			[
				588,
				-13
			],
			[
				164,
				-154
			],
			[
				-35,
				129
			],
			[
				160,
				-153
			]
		],
		[
			[
				60980,
				62847
			],
			[
				29,
				130
			],
			[
				-97,
				328
			],
			[
				100,
				158
			]
		],
		[
			[
				61012,
				63463
			],
			[
				1,
				65
			],
			[
				-188,
				0
			],
			[
				-1,
				113
			],
			[
				-189,
				7
			],
			[
				30,
				221
			]
		],
		[
			[
				60665,
				63869
			],
			[
				0,
				97
			],
			[
				-164,
				-95
			],
			[
				-444,
				14
			],
			[
				4,
				332
			]
		],
		[
			[
				60061,
				64217
			],
			[
				-387,
				15
			]
		],
		[
			[
				59674,
				64232
			],
			[
				-7,
				-339
			],
			[
				-32,
				-54
			],
			[
				95,
				-114
			],
			[
				124,
				-286
			],
			[
				134,
				-167
			],
			[
				115,
				-234
			]
		],
		[
			[
				13489,
				40953
			],
			[
				-35,
				235
			],
			[
				80,
				90
			]
		],
		[
			[
				12125,
				43049
			],
			[
				-319,
				-1128
			]
		],
		[
			[
				11806,
				41921
			],
			[
				-29,
				-605
			]
		],
		[
			[
				11777,
				41316
			],
			[
				165,
				91
			],
			[
				185,
				-180
			],
			[
				330,
				-81
			],
			[
				56,
				-152
			],
			[
				292,
				-97
			],
			[
				88,
				-64
			],
			[
				46,
				67
			],
			[
				187,
				28
			],
			[
				141,
				-34
			],
			[
				222,
				59
			]
		],
		[
			[
				76588,
				52020
			],
			[
				158,
				-209
			],
			[
				131,
				129
			],
			[
				73,
				-178
			],
			[
				103,
				-98
			],
			[
				83,
				-194
			],
			[
				35,
				105
			],
			[
				134,
				6
			]
		],
		[
			[
				77305,
				51581
			],
			[
				46,
				274
			],
			[
				19,
				351
			],
			[
				85,
				-34
			],
			[
				30,
				104
			],
			[
				101,
				55
			]
		],
		[
			[
				77586,
				52331
			],
			[
				-81,
				298
			],
			[
				47,
				230
			],
			[
				-8,
				176
			],
			[
				-112,
				90
			]
		],
		[
			[
				77432,
				53125
			],
			[
				-111,
				-52
			]
		],
		[
			[
				77321,
				53073
			],
			[
				1,
				-317
			],
			[
				-115,
				-108
			],
			[
				32,
				-155
			],
			[
				-229,
				-145
			],
			[
				-68,
				-257
			],
			[
				-90,
				-77
			],
			[
				-101,
				127
			],
			[
				-64,
				241
			]
		],
		[
			[
				76687,
				52382
			],
			[
				-40,
				-80
			]
		],
		[
			[
				76647,
				52302
			],
			[
				-52,
				-100
			],
			[
				-7,
				-182
			]
		],
		[
			[
				78237,
				68787
			],
			[
				364,
				-404
			],
			[
				155,
				205
			]
		],
		[
			[
				79047,
				69246
			],
			[
				-3,
				199
			]
		],
		[
			[
				79044,
				69445
			],
			[
				-461,
				589
			]
		],
		[
			[
				78583,
				70034
			],
			[
				-428,
				-1120
			]
		],
		[
			[
				78155,
				68914
			],
			[
				82,
				-127
			]
		],
		[
			[
				78562,
				74820
			],
			[
				105,
				-221
			],
			[
				-17,
				-243
			],
			[
				55,
				-55
			]
		],
		[
			[
				79095,
				74426
			],
			[
				71,
				186
			],
			[
				109,
				123
			],
			[
				66,
				196
			],
			[
				2,
				354
			]
		],
		[
			[
				79106,
				75953
			],
			[
				-185,
				35
			]
		],
		[
			[
				78921,
				75988
			],
			[
				18,
				-144
			],
			[
				-88,
				-110
			],
			[
				9,
				-273
			],
			[
				-62,
				-81
			],
			[
				-19,
				-200
			],
			[
				-98,
				-32
			],
			[
				-117,
				-197
			],
			[
				-2,
				-131
			]
		],
		[
			[
				79277,
				65926
			],
			[
				53,
				-48
			]
		],
		[
			[
				79330,
				65878
			],
			[
				170,
				578
			],
			[
				118,
				329
			],
			[
				163,
				26
			],
			[
				131,
				79
			]
		],
		[
			[
				79912,
				66890
			],
			[
				-77,
				163
			]
		],
		[
			[
				79835,
				67053
			],
			[
				-251,
				-142
			],
			[
				-150,
				137
			],
			[
				-53,
				120
			],
			[
				-134,
				112
			]
		],
		[
			[
				79247,
				67280
			],
			[
				-60,
				-46
			],
			[
				-120,
				-588
			],
			[
				-56,
				-49
			]
		],
		[
			[
				79011,
				66597
			],
			[
				-34,
				-81
			],
			[
				77,
				-231
			],
			[
				128,
				7
			],
			[
				53,
				-308
			],
			[
				42,
				-58
			]
		],
		[
			[
				78979,
				64554
			],
			[
				240,
				-109
			]
		],
		[
			[
				79219,
				64445
			],
			[
				140,
				570
			],
			[
				273,
				670
			]
		],
		[
			[
				79632,
				65685
			],
			[
				-58,
				-65
			],
			[
				-61,
				98
			],
			[
				-132,
				57
			],
			[
				-51,
				103
			]
		],
		[
			[
				79277,
				65926
			],
			[
				-120,
				-16
			],
			[
				-141,
				-139
			],
			[
				-111,
				163
			],
			[
				-161,
				-400
			]
		],
		[
			[
				78744,
				65534
			],
			[
				-85,
				-164
			],
			[
				61,
				-279
			],
			[
				146,
				-392
			],
			[
				113,
				-145
			]
		],
		[
			[
				77204,
				74772
			],
			[
				269,
				-72
			]
		],
		[
			[
				77473,
				74700
			],
			[
				28,
				415
			]
		],
		[
			[
				77501,
				75115
			],
			[
				65,
				982
			]
		],
		[
			[
				77566,
				76097
			],
			[
				-214,
				44
			]
		],
		[
			[
				76859,
				76242
			],
			[
				-249,
				54
			]
		],
		[
			[
				46684,
				45509
			],
			[
				920,
				78
			]
		],
		[
			[
				47588,
				47266
			],
			[
				-955,
				-82
			]
		],
		[
			[
				52390,
				46833
			],
			[
				11,
				-1007
			]
		],
		[
			[
				52401,
				45826
			],
			[
				699,
				17
			]
		],
		[
			[
				53100,
				45843
			],
			[
				-12,
				1337
			]
		],
		[
			[
				53088,
				47180
			],
			[
				-5,
				338
			]
		],
		[
			[
				53083,
				47518
			],
			[
				-701,
				-13
			]
		],
		[
			[
				55589,
				50224
			],
			[
				-2,
				-1008
			],
			[
				12,
				-169
			]
		],
		[
			[
				55599,
				49047
			],
			[
				767,
				-9
			]
		],
		[
			[
				56366,
				49038
			],
			[
				-15,
				169
			],
			[
				6,
				1177
			]
		],
		[
			[
				55590,
				50393
			],
			[
				-1,
				-169
			]
		],
		[
			[
				73297,
				48729
			],
			[
				498,
				-10
			]
		],
		[
			[
				73804,
				49520
			],
			[
				-27,
				225
			],
			[
				-84,
				153
			]
		],
		[
			[
				73693,
				49898
			],
			[
				-240,
				-155
			],
			[
				-346,
				-159
			]
		],
		[
			[
				73107,
				49584
			],
			[
				4,
				-452
			],
			[
				-115,
				-62
			]
		],
		[
			[
				72996,
				49070
			],
			[
				65,
				-82
			],
			[
				177,
				-61
			],
			[
				59,
				-198
			]
		],
		[
			[
				70021,
				53053
			],
			[
				151,
				18
			],
			[
				63,
				-137
			],
			[
				125,
				-29
			]
		],
		[
			[
				70360,
				52905
			],
			[
				16,
				153
			],
			[
				-65,
				167
			],
			[
				60,
				20
			],
			[
				48,
				-172
			],
			[
				73,
				15
			],
			[
				88,
				167
			],
			[
				16,
				179
			],
			[
				158,
				197
			]
		],
		[
			[
				70754,
				53631
			],
			[
				-52,
				300
			],
			[
				67,
				1
			],
			[
				21,
				173
			],
			[
				-79,
				88
			]
		],
		[
			[
				70711,
				54193
			],
			[
				-195,
				63
			]
		],
		[
			[
				70151,
				54270
			],
			[
				-143,
				-208
			],
			[
				-5,
				-97
			]
		],
		[
			[
				70003,
				53965
			],
			[
				8,
				-154
			],
			[
				-126,
				-232
			],
			[
				7,
				-197
			],
			[
				58,
				1
			],
			[
				71,
				-330
			]
		],
		[
			[
				77374,
				49795
			],
			[
				113,
				225
			],
			[
				42,
				296
			]
		],
		[
			[
				77626,
				51188
			],
			[
				-45,
				-54
			]
		],
		[
			[
				77581,
				51134
			],
			[
				-135,
				-148
			],
			[
				-150,
				-288
			],
			[
				-71,
				116
			],
			[
				-74,
				-120
			],
			[
				-142,
				-75
			]
		],
		[
			[
				77009,
				50619
			],
			[
				-37,
				-159
			]
		],
		[
			[
				76972,
				50460
			],
			[
				24,
				-128
			],
			[
				-56,
				-85
			],
			[
				94,
				-216
			],
			[
				150,
				-210
			],
			[
				-37,
				-110
			],
			[
				227,
				84
			]
		],
		[
			[
				65806,
				81494
			],
			[
				-20,
				288
			],
			[
				7,
				445
			],
			[
				32,
				256
			],
			[
				60,
				158
			],
			[
				147,
				152
			],
			[
				-101,
				495
			]
		],
		[
			[
				65931,
				83288
			],
			[
				-229,
				19
			],
			[
				-58,
				-247
			],
			[
				-115,
				-54
			],
			[
				-92,
				-128
			],
			[
				-14,
				-134
			],
			[
				-101,
				-134
			]
		],
		[
			[
				65322,
				82610
			],
			[
				-33,
				-502
			],
			[
				168,
				-79
			],
			[
				222,
				-471
			],
			[
				127,
				-64
			]
		],
		[
			[
				66522,
				69407
			],
			[
				-105,
				13
			],
			[
				-78,
				282
			]
		],
		[
			[
				66339,
				69702
			],
			[
				-804,
				-371
			]
		],
		[
			[
				65535,
				69331
			],
			[
				-12,
				-321
			],
			[
				68,
				-5
			],
			[
				-5,
				-164
			],
			[
				67,
				-6
			],
			[
				-32,
				-775
			],
			[
				-47,
				-58
			]
		],
		[
			[
				69310,
				47502
			],
			[
				1,
				-214
			]
		],
		[
			[
				69311,
				47288
			],
			[
				9,
				19
			],
			[
				484,
				-72
			],
			[
				-1,
				-24
			]
		],
		[
			[
				69803,
				47211
			],
			[
				244,
				-24
			]
		],
		[
			[
				70047,
				47187
			],
			[
				-176,
				400
			],
			[
				-50,
				-29
			],
			[
				20,
				285
			],
			[
				-60,
				206
			],
			[
				77,
				115
			],
			[
				-19,
				112
			],
			[
				37,
				330
			]
		],
		[
			[
				69498,
				48709
			],
			[
				-47,
				154
			],
			[
				-136,
				38
			],
			[
				-36,
				162
			],
			[
				-243,
				177
			]
		],
		[
			[
				69036,
				49240
			],
			[
				-4,
				-234
			],
			[
				136,
				-145
			],
			[
				-2,
				-236
			]
		],
		[
			[
				71814,
				35814
			],
			[
				605,
				-118
			]
		],
		[
			[
				72419,
				35696
			],
			[
				157,
				-30
			]
		],
		[
			[
				72576,
				35666
			],
			[
				64,
				892
			]
		],
		[
			[
				71880,
				36715
			],
			[
				-66,
				-901
			]
		],
		[
			[
				68662,
				51678
			],
			[
				150,
				72
			],
			[
				59,
				-134
			],
			[
				-66,
				-235
			],
			[
				13,
				-122
			]
		],
		[
			[
				68818,
				51259
			],
			[
				222,
				507
			],
			[
				110,
				118
			],
			[
				55,
				338
			]
		],
		[
			[
				69205,
				52222
			],
			[
				-316,
				660
			]
		],
		[
			[
				68889,
				52882
			],
			[
				-117,
				-85
			],
			[
				-41,
				-152
			],
			[
				-72,
				172
			]
		],
		[
			[
				68659,
				52817
			],
			[
				-139,
				-247
			]
		],
		[
			[
				68520,
				52570
			],
			[
				-62,
				-330
			],
			[
				56,
				-194
			],
			[
				97,
				-126
			],
			[
				51,
				-242
			]
		],
		[
			[
				58744,
				75546
			],
			[
				828,
				-39
			]
		],
		[
			[
				59991,
				77332
			],
			[
				-293,
				16
			],
			[
				-94,
				340
			],
			[
				-177,
				9
			],
			[
				-27,
				350
			]
		],
		[
			[
				59400,
				78047
			],
			[
				-91,
				40
			]
		],
		[
			[
				59309,
				78087
			],
			[
				-43,
				-362
			],
			[
				-131,
				-144
			],
			[
				44,
				-194
			],
			[
				-75,
				-295
			],
			[
				-94,
				-115
			],
			[
				43,
				-138
			],
			[
				-106,
				-77
			],
			[
				-106,
				-230
			]
		],
		[
			[
				58841,
				76532
			],
			[
				14,
				-287
			],
			[
				39,
				-97
			],
			[
				-53,
				-228
			],
			[
				14,
				-108
			],
			[
				-111,
				-266
			]
		],
		[
			[
				59666,
				73331
			],
			[
				256,
				-12
			],
			[
				-3,
				-169
			],
			[
				168,
				-13
			],
			[
				-68,
				-495
			]
		],
		[
			[
				60576,
				72611
			],
			[
				13,
				503
			],
			[
				191,
				-8
			]
		],
		[
			[
				60780,
				73106
			],
			[
				24,
				835
			],
			[
				-50,
				100
			],
			[
				-21,
				246
			]
		],
		[
			[
				60733,
				74287
			],
			[
				-234,
				10
			]
		],
		[
			[
				60032,
				74319
			],
			[
				-55,
				-332
			],
			[
				-404,
				18
			]
		],
		[
			[
				59573,
				74005
			],
			[
				37,
				-57
			],
			[
				-30,
				-236
			],
			[
				32,
				-219
			],
			[
				-40,
				-110
			],
			[
				94,
				-52
			]
		],
		[
			[
				86334,
				41905
			],
			[
				429,
				-670
			]
		],
		[
			[
				86763,
				41235
			],
			[
				-25,
				59
			],
			[
				127,
				241
			],
			[
				108,
				19
			],
			[
				124,
				144
			],
			[
				118,
				245
			],
			[
				119,
				-29
			]
		],
		[
			[
				87334,
				41914
			],
			[
				-118,
				692
			]
		],
		[
			[
				87216,
				42606
			],
			[
				-78,
				-91
			],
			[
				-106,
				278
			]
		],
		[
			[
				87032,
				42793
			],
			[
				-58,
				-98
			],
			[
				-184,
				-22
			],
			[
				-6,
				-133
			],
			[
				-158,
				-94
			]
		],
		[
			[
				86626,
				42446
			],
			[
				-235,
				7
			],
			[
				-116,
				-137
			],
			[
				-2,
				-240
			],
			[
				61,
				-171
			]
		],
		[
			[
				96137,
				29538
			],
			[
				104,
				-48
			],
			[
				63,
				-339
			],
			[
				126,
				-264
			],
			[
				72,
				19
			],
			[
				57,
				186
			],
			[
				119,
				62
			],
			[
				34,
				-154
			],
			[
				43,
				270
			],
			[
				-412,
				219
			],
			[
				-82,
				201
			],
			[
				-124,
				-152
			]
		],
		[
			[
				96258,
				28794
			],
			[
				48,
				27
			]
		],
		[
			[
				96306,
				28821
			],
			[
				-154,
				339
			],
			[
				-232,
				256
			],
			[
				20,
				-97
			],
			[
				184,
				-204
			],
			[
				134,
				-321
			]
		],
		[
			[
				74820,
				33948
			],
			[
				382,
				-98
			]
		],
		[
			[
				75202,
				33850
			],
			[
				397,
				-125
			],
			[
				197,
				207
			]
		],
		[
			[
				75796,
				33932
			],
			[
				-109,
				377
			],
			[
				-89,
				75
			],
			[
				-12,
				177
			],
			[
				-63,
				50
			],
			[
				-76,
				335
			],
			[
				46,
				177
			],
			[
				-47,
				42
			]
		],
		[
			[
				75446,
				35165
			],
			[
				-505,
				149
			]
		],
		[
			[
				63711,
				75140
			],
			[
				443,
				-8
			],
			[
				329,
				105
			],
			[
				-16,
				160
			],
			[
				220,
				-24
			]
		],
		[
			[
				64687,
				75373
			],
			[
				10,
				337
			]
		],
		[
			[
				64707,
				76044
			],
			[
				-788,
				76
			]
		],
		[
			[
				63919,
				76120
			],
			[
				-3,
				-78
			],
			[
				-149,
				18
			],
			[
				-71,
				-63
			],
			[
				-100,
				-369
			]
		],
		[
			[
				63596,
				75628
			],
			[
				98,
				-92
			],
			[
				-192,
				4
			],
			[
				41,
				-315
			],
			[
				84,
				-41
			],
			[
				51,
				192
			],
			[
				33,
				-236
			]
		],
		[
			[
				57137,
				47397
			],
			[
				-8,
				-1144
			]
		],
		[
			[
				57795,
				46239
			],
			[
				8,
				956
			]
		],
		[
			[
				57129,
				47575
			],
			[
				8,
				-178
			]
		],
		[
			[
				59179,
				42602
			],
			[
				7,
				450
			]
		],
		[
			[
				58517,
				43091
			],
			[
				-14,
				-804
			],
			[
				-21,
				-405
			]
		],
		[
			[
				27115,
				12266
			],
			[
				380,
				125
			],
			[
				18,
				-163
			],
			[
				169,
				55
			],
			[
				36,
				-325
			],
			[
				-66,
				-96
			],
			[
				0,
				-259
			],
			[
				42,
				-129
			],
			[
				-25,
				-224
			],
			[
				-59,
				-129
			],
			[
				79,
				-122
			],
			[
				3,
				-325
			],
			[
				442,
				138
			]
		],
		[
			[
				28384,
				10892
			],
			[
				-171,
				1572
			],
			[
				246,
				76
			],
			[
				-140,
				1300
			]
		],
		[
			[
				28319,
				13840
			],
			[
				-172,
				-54
			],
			[
				-12,
				110
			],
			[
				-95,
				-31
			],
			[
				-24,
				218
			],
			[
				-98,
				-31
			],
			[
				-21,
				102
			],
			[
				-177,
				-56
			],
			[
				-23,
				217
			],
			[
				-241,
				-88
			]
		],
		[
			[
				27456,
				14227
			],
			[
				-763,
				-250
			]
		],
		[
			[
				26284,
				13945
			],
			[
				-50,
				-118
			],
			[
				44,
				-171
			],
			[
				-69,
				-139
			]
		],
		[
			[
				26209,
				13517
			],
			[
				194,
				26
			],
			[
				38,
				-322
			],
			[
				-26,
				-9
			],
			[
				56,
				-484
			],
			[
				81,
				-151
			],
			[
				104,
				98
			],
			[
				61,
				-96
			],
			[
				-96,
				-33
			],
			[
				19,
				-165
			],
			[
				-124,
				-41
			],
			[
				-7,
				-221
			],
			[
				-89,
				-87
			],
			[
				13,
				-107
			],
			[
				-87,
				-88
			],
			[
				19,
				-162
			],
			[
				-95,
				-32
			],
			[
				-13,
				-173
			]
		],
		[
			[
				54624,
				42167
			],
			[
				601,
				-3
			]
		],
		[
			[
				55225,
				42164
			],
			[
				64,
				-151
			],
			[
				18,
				175
			],
			[
				-65,
				47
			],
			[
				12,
				138
			],
			[
				136,
				598
			],
			[
				102,
				88
			],
			[
				-3,
				103
			]
		],
		[
			[
				55489,
				43162
			],
			[
				4,
				10
			]
		],
		[
			[
				55493,
				43172
			],
			[
				-773,
				4
			]
		],
		[
			[
				54624,
				43174
			],
			[
				0,
				-1007
			]
		],
		[
			[
				90624,
				40522
			],
			[
				-10,
				-514
			],
			[
				28,
				-71
			]
		],
		[
			[
				90642,
				39937
			],
			[
				69,
				46
			],
			[
				203,
				-21
			],
			[
				273,
				-145
			]
		],
		[
			[
				91187,
				39817
			],
			[
				-131,
				401
			],
			[
				-67,
				391
			],
			[
				-68,
				532
			],
			[
				-94,
				241
			],
			[
				-181,
				70
			],
			[
				-2,
				-240
			],
			[
				50,
				-462
			],
			[
				-14,
				-176
			],
			[
				-56,
				-52
			]
		],
		[
			[
				91381,
				33476
			],
			[
				7,
				-144
			]
		],
		[
			[
				91388,
				33332
			],
			[
				267,
				53
			]
		],
		[
			[
				91655,
				33385
			],
			[
				18,
				119
			],
			[
				-77,
				24
			],
			[
				67,
				153
			]
		],
		[
			[
				91663,
				33681
			],
			[
				-134,
				116
			],
			[
				-69,
				-13
			]
		],
		[
			[
				91460,
				33784
			],
			[
				-40,
				-34
			],
			[
				7,
				-270
			],
			[
				-46,
				-4
			]
		],
		[
			[
				76400,
				59340
			],
			[
				16,
				9
			]
		],
		[
			[
				76416,
				59349
			],
			[
				161,
				13
			],
			[
				237,
				-49
			],
			[
				160,
				36
			],
			[
				106,
				-47
			],
			[
				4,
				222
			],
			[
				-41,
				135
			],
			[
				-98,
				87
			],
			[
				11,
				188
			]
		],
		[
			[
				76956,
				59934
			],
			[
				-31,
				128
			]
		],
		[
			[
				76327,
				60023
			],
			[
				-32,
				-218
			],
			[
				48,
				-95
			],
			[
				-34,
				-119
			],
			[
				91,
				-251
			]
		],
		[
			[
				55784,
				55760
			],
			[
				591,
				-9
			]
		],
		[
			[
				56375,
				55751
			],
			[
				116,
				-2
			]
		],
		[
			[
				56491,
				55749
			],
			[
				-46,
				21
			],
			[
				62,
				196
			],
			[
				6,
				1052
			]
		],
		[
			[
				56513,
				57018
			],
			[
				-7,
				620
			]
		],
		[
			[
				56506,
				57638
			],
			[
				-574,
				7
			]
		],
		[
			[
				55932,
				57645
			],
			[
				-2,
				-337
			],
			[
				-183,
				3
			]
		],
		[
			[
				55747,
				57311
			],
			[
				-5,
				-1333
			],
			[
				42,
				0
			],
			[
				0,
				-218
			]
		],
		[
			[
				50818,
				66139
			],
			[
				-7,
				570
			]
		],
		[
			[
				50811,
				66709
			],
			[
				-57,
				104
			],
			[
				-239,
				-88
			],
			[
				-116,
				-91
			],
			[
				-113,
				278
			]
		],
		[
			[
				50286,
				66912
			],
			[
				-117,
				76
			],
			[
				-162,
				-340
			],
			[
				-60,
				-35
			]
		],
		[
			[
				49947,
				66613
			],
			[
				12,
				-680
			],
			[
				-96,
				-3
			],
			[
				5,
				-279
			]
		],
		[
			[
				14409,
				21015
			],
			[
				1486,
				685
			],
			[
				0,
				17
			],
			[
				748,
				335
			]
		],
		[
			[
				16643,
				22052
			],
			[
				-242,
				1604
			],
			[
				-56,
				-24
			],
			[
				-145,
				976
			],
			[
				-19,
				-9
			],
			[
				-96,
				636
			],
			[
				916,
				387
			],
			[
				-332,
				2285
			],
			[
				-73,
				535
			]
		],
		[
			[
				16596,
				28442
			],
			[
				-685,
				-310
			],
			[
				-336,
				-135
			]
		],
		[
			[
				14171,
				27368
			],
			[
				430,
				-2790
			],
			[
				-733,
				-351
			],
			[
				363,
				-2260
			],
			[
				26,
				12
			],
			[
				152,
				-964
			]
		],
		[
			[
				53244,
				30697
			],
			[
				526,
				5
			]
		],
		[
			[
				53770,
				30702
			],
			[
				7,
				255
			],
			[
				117,
				188
			],
			[
				-8,
				290
			],
			[
				-88,
				45
			],
			[
				40,
				147
			]
		],
		[
			[
				53838,
				31627
			],
			[
				-9,
				137
			],
			[
				122,
				1
			],
			[
				29,
				285
			],
			[
				48,
				113
			],
			[
				-29,
				144
			]
		],
		[
			[
				53999,
				32307
			],
			[
				-570,
				-8
			]
		],
		[
			[
				53429,
				32299
			],
			[
				-193,
				-3
			]
		],
		[
			[
				78459,
				54466
			],
			[
				554,
				-142
			]
		],
		[
			[
				79013,
				54324
			],
			[
				90,
				-22
			]
		],
		[
			[
				79274,
				54263
			],
			[
				372,
				-93
			],
			[
				13,
				-89
			],
			[
				170,
				-35
			]
		],
		[
			[
				79829,
				54046
			],
			[
				-163,
				222
			],
			[
				-78,
				253
			]
		],
		[
			[
				79588,
				54521
			],
			[
				-187,
				239
			],
			[
				-139,
				263
			],
			[
				-118,
				-47
			],
			[
				-77,
				111
			]
		],
		[
			[
				79067,
				55087
			],
			[
				-124,
				6
			],
			[
				-107,
				-147
			],
			[
				-309,
				170
			],
			[
				-143,
				1
			]
		],
		[
			[
				78384,
				55117
			],
			[
				-45,
				-82
			],
			[
				142,
				-283
			],
			[
				-22,
				-286
			]
		],
		[
			[
				70586,
				60553
			],
			[
				134,
				-110
			],
			[
				311,
				101
			]
		],
		[
			[
				71031,
				60544
			],
			[
				66,
				45
			],
			[
				54,
				334
			],
			[
				152,
				170
			]
		],
		[
			[
				71303,
				61093
			],
			[
				44,
				1046
			]
		],
		[
			[
				70669,
				62221
			],
			[
				-24,
				2
			]
		],
		[
			[
				70645,
				62223
			],
			[
				-8,
				-478
			],
			[
				-55,
				-991
			],
			[
				4,
				-201
			]
		],
		[
			[
				64937,
				66877
			],
			[
				-11,
				-336
			]
		],
		[
			[
				65865,
				66090
			],
			[
				15,
				90
			],
			[
				33,
				933
			]
		],
		[
			[
				65551,
				67832
			],
			[
				-154,
				14
			],
			[
				-38,
				-54
			],
			[
				-49,
				160
			],
			[
				-86,
				-102
			],
			[
				32,
				-100
			],
			[
				199,
				-77
			],
			[
				-1,
				-169
			],
			[
				-495,
				43
			]
		],
		[
			[
				64959,
				67547
			],
			[
				-22,
				-670
			]
		],
		[
			[
				31650,
				9818
			],
			[
				865,
				234
			],
			[
				30,
				-324
			],
			[
				105,
				29
			]
		],
		[
			[
				32650,
				9757
			],
			[
				194,
				51
			],
			[
				30,
				-324
			],
			[
				1156,
				300
			],
			[
				-28,
				325
			],
			[
				178,
				36
			],
			[
				-28,
				332
			],
			[
				287,
				64
			]
		],
		[
			[
				34439,
				10541
			],
			[
				-131,
				1484
			]
		],
		[
			[
				34308,
				12025
			],
			[
				-59,
				-49
			],
			[
				-173,
				31
			],
			[
				-55,
				86
			],
			[
				-168,
				-78
			],
			[
				-13,
				239
			],
			[
				-64,
				405
			],
			[
				-192,
				148
			],
			[
				-98,
				8
			],
			[
				-215,
				191
			],
			[
				-81,
				-26
			]
		],
		[
			[
				33190,
				12980
			],
			[
				-640,
				-167
			]
		],
		[
			[
				32550,
				12813
			],
			[
				9,
				-108
			],
			[
				-194,
				-55
			],
			[
				28,
				-298
			],
			[
				-144,
				-35
			],
			[
				-252,
				-352
			],
			[
				145,
				-345
			],
			[
				-647,
				-177
			]
		],
		[
			[
				31495,
				11443
			],
			[
				104,
				-1082
			]
		],
		[
			[
				31599,
				10361
			],
			[
				51,
				-543
			]
		],
		[
			[
				46941,
				37148
			],
			[
				738,
				60
			]
		],
		[
			[
				47702,
				37210
			],
			[
				-35,
				1333
			]
		],
		[
			[
				36377,
				58484
			],
			[
				-4,
				63
			]
		],
		[
			[
				36373,
				58547
			],
			[
				4,
				-63
			]
		],
		[
			[
				34024,
				56604
			],
			[
				1311,
				280
			],
			[
				-57,
				833
			],
			[
				1137,
				208
			],
			[
				-10,
				134
			]
		],
		[
			[
				36405,
				58059
			],
			[
				-124,
				31
			],
			[
				-148,
				-57
			],
			[
				-40,
				584
			],
			[
				81,
				-18
			],
			[
				127,
				118
			],
			[
				52,
				150
			]
		],
		[
			[
				36353,
				58867
			],
			[
				-111,
				1729
			],
			[
				-14,
				344
			]
		],
		[
			[
				36228,
				60940
			],
			[
				-1710,
				-347
			]
		],
		[
			[
				33960,
				57444
			],
			[
				64,
				-840
			]
		],
		[
			[
				87404,
				56688
			],
			[
				180,
				-102
			],
			[
				53,
				112
			],
			[
				144,
				-151
			],
			[
				183,
				-270
			]
		],
		[
			[
				87964,
				56277
			],
			[
				121,
				261
			],
			[
				67,
				16
			]
		],
		[
			[
				88152,
				56554
			],
			[
				-86,
				187
			],
			[
				60,
				141
			],
			[
				-72,
				142
			]
		],
		[
			[
				88054,
				57024
			],
			[
				-101,
				-23
			],
			[
				67,
				257
			],
			[
				-125,
				439
			],
			[
				-203,
				315
			]
		],
		[
			[
				87692,
				58012
			],
			[
				-33,
				-45
			],
			[
				-42,
				-321
			],
			[
				-48,
				-126
			],
			[
				-134,
				-88
			]
		],
		[
			[
				87435,
				57432
			],
			[
				-53,
				-635
			],
			[
				22,
				-109
			]
		],
		[
			[
				86694,
				53650
			],
			[
				69,
				-395
			],
			[
				20,
				-340
			],
			[
				-39,
				-353
			],
			[
				11,
				-127
			]
		],
		[
			[
				86755,
				52435
			],
			[
				218,
				17
			],
			[
				229,
				-65
			],
			[
				54,
				122
			],
			[
				156,
				114
			],
			[
				-32,
				287
			],
			[
				55,
				87
			],
			[
				202,
				-131
			],
			[
				131,
				239
			],
			[
				14,
				156
			],
			[
				180,
				100
			]
		],
		[
			[
				87962,
				53361
			],
			[
				137,
				204
			],
			[
				-158,
				173
			]
		],
		[
			[
				87941,
				53738
			],
			[
				-101,
				308
			]
		],
		[
			[
				87257,
				53659
			],
			[
				-145,
				94
			],
			[
				-183,
				51
			],
			[
				-71,
				-125
			],
			[
				-164,
				-29
			]
		],
		[
			[
				76322,
				38967
			],
			[
				420,
				-107
			]
		],
		[
			[
				76742,
				38860
			],
			[
				16,
				224
			],
			[
				-160,
				80
			],
			[
				44,
				554
			],
			[
				-96,
				47
			],
			[
				9,
				195
			]
		],
		[
			[
				76555,
				39960
			],
			[
				-384,
				50
			]
		],
		[
			[
				53992,
				65717
			],
			[
				479,
				1
			],
			[
				0,
				-337
			],
			[
				98,
				0
			],
			[
				0,
				-672
			]
		],
		[
			[
				54569,
				64709
			],
			[
				383,
				0
			],
			[
				0,
				335
			],
			[
				383,
				-3
			]
		],
		[
			[
				55142,
				66727
			],
			[
				-390,
				0
			]
		],
		[
			[
				54752,
				66727
			],
			[
				-763,
				-5
			]
		],
		[
			[
				53989,
				66722
			],
			[
				3,
				-1005
			]
		],
		[
			[
				52674,
				62004
			],
			[
				-13,
				1729
			]
		],
		[
			[
				52661,
				63733
			],
			[
				-347,
				-126
			],
			[
				-41,
				-289
			],
			[
				8,
				-166
			],
			[
				-86,
				-39
			],
			[
				-8,
				-200
			],
			[
				-240,
				-300
			],
			[
				-44,
				-129
			],
			[
				12,
				-172
			],
			[
				-199,
				-181
			]
		],
		[
			[
				51716,
				62131
			],
			[
				2,
				-153
			]
		],
		[
			[
				53939,
				62356
			],
			[
				-2,
				672
			],
			[
				-89,
				-1
			],
			[
				-3,
				791
			]
		],
		[
			[
				53845,
				63818
			],
			[
				-69,
				158
			],
			[
				-88,
				-182
			],
			[
				-127,
				54
			],
			[
				-37,
				-106
			],
			[
				-79,
				68
			],
			[
				44,
				110
			],
			[
				-75,
				100
			],
			[
				-87,
				-166
			]
		],
		[
			[
				53327,
				63854
			],
			[
				8,
				-1973
			],
			[
				221,
				78
			],
			[
				55,
				-33
			]
		],
		[
			[
				88015,
				32622
			],
			[
				23,
				121
			],
			[
				166,
				121
			],
			[
				92,
				1
			],
			[
				51,
				276
			],
			[
				44,
				8
			],
			[
				55,
				303
			],
			[
				76,
				30
			]
		],
		[
			[
				88522,
				33482
			],
			[
				-61,
				177
			]
		],
		[
			[
				88461,
				33659
			],
			[
				-77,
				-4
			],
			[
				-84,
				143
			],
			[
				-1,
				206
			],
			[
				83,
				135
			],
			[
				-390,
				518
			]
		],
		[
			[
				87992,
				34657
			],
			[
				-45,
				60
			],
			[
				-317,
				-73
			]
		],
		[
			[
				87630,
				34644
			],
			[
				-81,
				-373
			],
			[
				-41,
				-326
			],
			[
				-77,
				54
			],
			[
				-103,
				-264
			],
			[
				-32,
				-390
			]
		],
		[
			[
				85809,
				35843
			],
			[
				646,
				-540
			],
			[
				235,
				-58
			]
		],
		[
			[
				86690,
				35245
			],
			[
				-48,
				304
			],
			[
				27,
				306
			],
			[
				-99,
				391
			]
		],
		[
			[
				85972,
				36234
			],
			[
				-112,
				7
			],
			[
				-51,
				-398
			]
		],
		[
			[
				81323,
				63554
			],
			[
				150,
				-54
			],
			[
				-105,
				-166
			],
			[
				44,
				-198
			],
			[
				110,
				-106
			]
		],
		[
			[
				81522,
				63030
			],
			[
				83,
				103
			],
			[
				-21,
				81
			],
			[
				88,
				167
			],
			[
				134,
				-36
			],
			[
				123,
				80
			],
			[
				81,
				153
			],
			[
				105,
				79
			],
			[
				85,
				230
			],
			[
				27,
				191
			]
		],
		[
			[
				82227,
				64078
			],
			[
				-44,
				48
			],
			[
				6,
				481
			],
			[
				70,
				-152
			],
			[
				170,
				49
			],
			[
				-197,
				247
			]
		],
		[
			[
				82232,
				64751
			],
			[
				-244,
				277
			]
		],
		[
			[
				81988,
				65028
			],
			[
				-353,
				-200
			],
			[
				-91,
				10
			],
			[
				-104,
				-226
			],
			[
				-81,
				-21
			],
			[
				-149,
				-231
			]
		],
		[
			[
				81210,
				64360
			],
			[
				113,
				-806
			]
		],
		[
			[
				68763,
				59228
			],
			[
				267,
				-14
			],
			[
				7,
				110
			],
			[
				102,
				-33
			]
		],
		[
			[
				69139,
				59291
			],
			[
				-24,
				213
			],
			[
				-72,
				182
			],
			[
				38,
				127
			],
			[
				-22,
				240
			],
			[
				131,
				398
			],
			[
				-109,
				285
			],
			[
				66,
				72
			]
		],
		[
			[
				69147,
				60808
			],
			[
				55,
				102
			],
			[
				-79,
				22
			]
		],
		[
			[
				69123,
				60932
			],
			[
				-77,
				-87
			],
			[
				-102,
				145
			],
			[
				-128,
				35
			],
			[
				-89,
				-156
			]
		],
		[
			[
				51078,
				74063
			],
			[
				310,
				-309
			],
			[
				314,
				1
			]
		],
		[
			[
				51702,
				73755
			],
			[
				-4,
				442
			]
		],
		[
			[
				51698,
				74197
			],
			[
				-29,
				-15
			],
			[
				-446,
				447
			]
		],
		[
			[
				50443,
				86878
			],
			[
				920,
				-1264
			]
		],
		[
			[
				51363,
				85614
			],
			[
				221,
				435
			]
		],
		[
			[
				51648,
				87183
			],
			[
				-405,
				542
			]
		],
		[
			[
				51243,
				87725
			],
			[
				-92,
				-12
			],
			[
				-174,
				-194
			],
			[
				-179,
				106
			]
		],
		[
			[
				50798,
				87625
			],
			[
				-178,
				-376
			]
		],
		[
			[
				56318,
				71542
			],
			[
				806,
				-22
			]
		],
		[
			[
				57151,
				71616
			],
			[
				9,
				331
			]
		],
		[
			[
				57160,
				71947
			],
			[
				5,
				542
			]
		],
		[
			[
				57165,
				72489
			],
			[
				-82,
				-57
			],
			[
				-137,
				299
			],
			[
				-311,
				211
			]
		],
		[
			[
				56635,
				72942
			],
			[
				-287,
				-45
			],
			[
				-26,
				-76
			]
		],
		[
			[
				56322,
				72821
			],
			[
				-4,
				-1279
			]
		],
		[
			[
				50798,
				87625
			],
			[
				-167,
				98
			],
			[
				532,
				1847
			],
			[
				-18,
				25
			]
		],
		[
			[
				51010,
				90047
			],
			[
				-697,
				-30
			]
		],
		[
			[
				50117,
				90010
			],
			[
				29,
				-2115
			]
		],
		[
			[
				20029,
				9119
			],
			[
				1511,
				627
			]
		],
		[
			[
				21540,
				9746
			],
			[
				-174,
				1281
			],
			[
				-102,
				219
			],
			[
				-49,
				229
			],
			[
				-123,
				-26
			],
			[
				-191,
				78
			]
		],
		[
			[
				20901,
				11527
			],
			[
				-457,
				-180
			],
			[
				-1276,
				-540
			]
		],
		[
			[
				19168,
				10807
			],
			[
				96,
				-645
			],
			[
				574,
				249
			],
			[
				191,
				-1292
			]
		],
		[
			[
				80861,
				44077
			],
			[
				48,
				7
			],
			[
				57,
				-214
			],
			[
				93,
				-128
			]
		],
		[
			[
				81584,
				43842
			],
			[
				24,
				561
			],
			[
				-87,
				352
			],
			[
				1,
				417
			],
			[
				43,
				147
			]
		],
		[
			[
				81451,
				45306
			],
			[
				5,
				-152
			],
			[
				-75,
				-68
			],
			[
				8,
				-131
			],
			[
				-94,
				-87
			],
			[
				-102,
				42
			],
			[
				-55,
				-162
			]
		],
		[
			[
				81138,
				44748
			],
			[
				-226,
				-344
			],
			[
				-51,
				-327
			]
		],
		[
			[
				42735,
				25657
			],
			[
				955,
				124
			],
			[
				82,
				-1661
			]
		],
		[
			[
				43772,
				24120
			],
			[
				1507,
				181
			]
		],
		[
			[
				45279,
				24301
			],
			[
				-75,
				2013
			]
		],
		[
			[
				45204,
				26314
			],
			[
				-1,
				2
			]
		],
		[
			[
				45203,
				26316
			],
			[
				-74,
				38
			],
			[
				-120,
				228
			],
			[
				-112,
				23
			],
			[
				-74,
				-97
			],
			[
				-117,
				69
			],
			[
				5,
				165
			],
			[
				-122,
				378
			],
			[
				7,
				202
			],
			[
				-116,
				338
			],
			[
				-1644,
				-208
			]
		],
		[
			[
				73468,
				55453
			],
			[
				246,
				-76
			]
		],
		[
			[
				73714,
				55377
			],
			[
				35,
				-9
			]
		],
		[
			[
				73417,
				56299
			],
			[
				-159,
				-236
			],
			[
				-219,
				-54
			],
			[
				-20,
				-98
			],
			[
				-189,
				123
			]
		],
		[
			[
				72830,
				56034
			],
			[
				15,
				-477
			]
		],
		[
			[
				53041,
				64025
			],
			[
				6,
				-424
			]
		],
		[
			[
				53047,
				63601
			],
			[
				81,
				148
			],
			[
				131,
				-69
			],
			[
				68,
				174
			]
		],
		[
			[
				53845,
				63818
			],
			[
				154,
				10
			],
			[
				-2,
				543
			]
		],
		[
			[
				53997,
				64371
			],
			[
				-1,
				337
			],
			[
				-193,
				-1
			],
			[
				-5,
				673
			]
		],
		[
			[
				53798,
				65380
			],
			[
				-573,
				-10
			]
		],
		[
			[
				53036,
				64863
			],
			[
				5,
				-838
			]
		],
		[
			[
				72296,
				61980
			],
			[
				808,
				-134
			]
		],
		[
			[
				73104,
				61846
			],
			[
				468,
				-73
			]
		],
		[
			[
				73572,
				61773
			],
			[
				70,
				469
			]
		],
		[
			[
				73642,
				62242
			],
			[
				-72,
				124
			],
			[
				-41,
				306
			],
			[
				-57,
				52
			],
			[
				-138,
				490
			],
			[
				-362,
				626
			],
			[
				-97,
				33
			]
		],
		[
			[
				72875,
				63873
			],
			[
				-44,
				52
			],
			[
				-40,
				-214
			],
			[
				-79,
				-31
			],
			[
				-31,
				-249
			],
			[
				-324,
				56
			]
		],
		[
			[
				72357,
				63487
			],
			[
				-66,
				-117
			],
			[
				-21,
				-393
			],
			[
				35,
				-379
			],
			[
				-55,
				-155
			],
			[
				63,
				-170
			],
			[
				-32,
				-81
			],
			[
				119,
				-53
			],
			[
				-104,
				-159
			]
		],
		[
			[
				9118,
				42127
			],
			[
				-187,
				-91
			]
		],
		[
			[
				8931,
				42036
			],
			[
				25,
				-280
			],
			[
				199,
				-25
			],
			[
				17,
				395
			],
			[
				-54,
				1
			]
		],
		[
			[
				23012,
				91658
			],
			[
				151,
				22
			],
			[
				74,
				-53
			]
		],
		[
			[
				22665,
				90779
			],
			[
				328,
				-153
			]
		],
		[
			[
				22993,
				90626
			],
			[
				54,
				120
			],
			[
				189,
				83
			],
			[
				249,
				287
			],
			[
				-21,
				71
			],
			[
				168,
				240
			]
		],
		[
			[
				23534,
				91889
			],
			[
				-8,
				35
			]
		],
		[
			[
				23512,
				91993
			],
			[
				-202,
				-268
			],
			[
				-56,
				-280
			],
			[
				-14,
				125
			],
			[
				-202,
				37
			],
			[
				-118,
				-282
			],
			[
				-218,
				-386
			],
			[
				-37,
				-160
			]
		],
		[
			[
				76428,
				79337
			],
			[
				890,
				-198
			],
			[
				20,
				109
			],
			[
				317,
				-66
			]
		],
		[
			[
				77655,
				79182
			],
			[
				49,
				677
			]
		],
		[
			[
				77704,
				79859
			],
			[
				-149,
				120
			],
			[
				-222,
				-49
			],
			[
				5,
				113
			],
			[
				-166,
				264
			],
			[
				61,
				172
			]
		],
		[
			[
				77233,
				80479
			],
			[
				-189,
				-23
			],
			[
				-65,
				-82
			],
			[
				-86,
				67
			],
			[
				-64,
				-72
			]
		],
		[
			[
				76829,
				80369
			],
			[
				-95,
				-52
			],
			[
				-178,
				-343
			],
			[
				-10,
				-206
			],
			[
				-74,
				-226
			],
			[
				-82,
				-64
			],
			[
				38,
				-141
			]
		],
		[
			[
				23254,
				7628
			],
			[
				602,
				231
			],
			[
				7,
				-53
			],
			[
				190,
				71
			],
			[
				-50,
				375
			],
			[
				269,
				99
			]
		],
		[
			[
				24272,
				8351
			],
			[
				-172,
				1335
			],
			[
				-62,
				429
			]
		],
		[
			[
				22959,
				9893
			],
			[
				295,
				-2265
			]
		],
		[
			[
				62938,
				38680
			],
			[
				31,
				-301
			],
			[
				118,
				-100
			],
			[
				76,
				42
			],
			[
				135,
				-143
			],
			[
				100,
				0
			]
		],
		[
			[
				63398,
				38178
			],
			[
				215,
				-57
			],
			[
				101,
				-205
			],
			[
				214,
				-62
			],
			[
				75,
				-169
			],
			[
				112,
				-98
			],
			[
				26,
				-547
			]
		],
		[
			[
				64141,
				37040
			],
			[
				115,
				-221
			]
		],
		[
			[
				64256,
				36819
			],
			[
				30,
				408
			],
			[
				123,
				113
			],
			[
				-32,
				232
			]
		],
		[
			[
				64000,
				38605
			],
			[
				-1062,
				76
			]
		],
		[
			[
				62938,
				38681
			],
			[
				0,
				-1
			]
		],
		[
			[
				73016,
				42390
			],
			[
				658,
				-133
			]
		],
		[
			[
				73674,
				42257
			],
			[
				21,
				338
			]
		],
		[
			[
				54935,
				39711
			],
			[
				829,
				-4
			]
		],
		[
			[
				55764,
				39707
			],
			[
				3,
				996
			]
		],
		[
			[
				55767,
				40703
			],
			[
				-718,
				4
			]
		],
		[
			[
				55049,
				40707
			],
			[
				-45,
				-147
			],
			[
				20,
				-125
			],
			[
				-69,
				-142
			],
			[
				-17,
				-170
			]
		],
		[
			[
				54938,
				40123
			],
			[
				28,
				-135
			],
			[
				-31,
				-277
			]
		],
		[
			[
				72276,
				49611
			],
			[
				89,
				-247
			],
			[
				9,
				-145
			],
			[
				63,
				-57
			]
		],
		[
			[
				72437,
				49162
			],
			[
				95,
				57
			],
			[
				103,
				-143
			],
			[
				44,
				-315
			]
		],
		[
			[
				72679,
				48761
			],
			[
				317,
				309
			]
		],
		[
			[
				73107,
				49584
			],
			[
				-1,
				113
			]
		],
		[
			[
				72271,
				50208
			],
			[
				5,
				-597
			]
		],
		[
			[
				59084,
				81063
			],
			[
				489,
				-22
			],
			[
				-6,
				-337
			],
			[
				196,
				-7
			],
			[
				4,
				335
			],
			[
				489,
				-26
			]
		],
		[
			[
				60256,
				81006
			],
			[
				1,
				88
			],
			[
				193,
				-8
			],
			[
				9,
				455
			],
			[
				65,
				-4
			],
			[
				8,
				397
			],
			[
				199,
				-8
			],
			[
				-25,
				168
			],
			[
				42,
				110
			],
			[
				-210,
				15
			],
			[
				2,
				168
			]
		],
		[
			[
				60540,
				82387
			],
			[
				-255,
				14
			],
			[
				-1,
				-54
			],
			[
				-1148,
				58
			]
		],
		[
			[
				59136,
				82405
			],
			[
				-20,
				-143
			],
			[
				82,
				-204
			],
			[
				-66,
				-260
			],
			[
				27,
				-133
			]
		],
		[
			[
				59159,
				81665
			],
			[
				-4,
				-141
			],
			[
				-117,
				-195
			],
			[
				46,
				-266
			]
		],
		[
			[
				93831,
				15002
			],
			[
				234,
				909
			],
			[
				-64,
				48
			],
			[
				178,
				608
			],
			[
				70,
				394
			],
			[
				198,
				-344
			],
			[
				260,
				547
			],
			[
				-51,
				89
			],
			[
				437,
				-42
			],
			[
				38,
				238
			],
			[
				92,
				140
			]
		],
		[
			[
				95107,
				19342
			],
			[
				-83,
				144
			],
			[
				-113,
				-90
			],
			[
				-23,
				-311
			],
			[
				-86,
				-153
			],
			[
				-249,
				490
			],
			[
				-67,
				-41
			],
			[
				43,
				162
			],
			[
				91,
				-5
			],
			[
				165,
				234
			],
			[
				-33,
				234
			],
			[
				34,
				68
			],
			[
				-71,
				154
			],
			[
				53,
				139
			]
		],
		[
			[
				94768,
				20367
			],
			[
				-147,
				82
			],
			[
				-160,
				148
			]
		],
		[
			[
				94461,
				20597
			],
			[
				-320,
				-1810
			]
		],
		[
			[
				93435,
				15064
			],
			[
				106,
				-102
			],
			[
				-19,
				-103
			],
			[
				161,
				66
			],
			[
				69,
				236
			],
			[
				95,
				-17
			],
			[
				-16,
				-142
			]
		],
		[
			[
				75670,
				30626
			],
			[
				191,
				-65
			]
		],
		[
			[
				75861,
				30561
			],
			[
				393,
				-108
			],
			[
				76,
				725
			],
			[
				42,
				78
			]
		],
		[
			[
				76372,
				31256
			],
			[
				-136,
				147
			],
			[
				-23,
				152
			],
			[
				72,
				187
			],
			[
				-82,
				3
			],
			[
				-51,
				184
			],
			[
				28,
				274
			]
		],
		[
			[
				76180,
				32203
			],
			[
				-344,
				97
			]
		],
		[
			[
				75836,
				32300
			],
			[
				-166,
				-1674
			]
		],
		[
			[
				68425,
				18153
			],
			[
				145,
				-171
			],
			[
				63,
				17
			],
			[
				57,
				199
			],
			[
				104,
				100
			],
			[
				105,
				-3
			],
			[
				35,
				-159
			],
			[
				80,
				-4
			],
			[
				66,
				130
			],
			[
				9,
				-230
			],
			[
				-49,
				-167
			],
			[
				83,
				-78
			],
			[
				45,
				96
			],
			[
				-19,
				186
			],
			[
				98,
				70
			],
			[
				160,
				-365
			],
			[
				177,
				-133
			],
			[
				302,
				-416
			],
			[
				64,
				59
			],
			[
				351,
				-200
			]
		],
		[
			[
				70345,
				17786
			],
			[
				-768,
				133
			],
			[
				39,
				660
			],
			[
				-385,
				61
			],
			[
				38,
				662
			]
		],
		[
			[
				69269,
				19302
			],
			[
				-773,
				127
			]
		],
		[
			[
				68496,
				19429
			],
			[
				-71,
				-1276
			]
		],
		[
			[
				66525,
				79489
			],
			[
				18,
				-393
			],
			[
				48,
				-101
			],
			[
				44,
				-405
			],
			[
				71,
				-413
			]
		],
		[
			[
				67459,
				78444
			],
			[
				40,
				1002
			]
		],
		[
			[
				67499,
				79446
			],
			[
				-388,
				44
			],
			[
				19,
				502
			],
			[
				-195,
				77
			],
			[
				7,
				168
			],
			[
				-82,
				8
			]
		],
		[
			[
				66860,
				80245
			],
			[
				-139,
				-164
			],
			[
				-104,
				-226
			],
			[
				-29,
				-251
			],
			[
				-63,
				-115
			]
		],
		[
			[
				68938,
				74469
			],
			[
				52,
				748
			]
		],
		[
			[
				68990,
				75217
			],
			[
				74,
				999
			]
		],
		[
			[
				69064,
				76216
			],
			[
				-742,
				106
			]
		],
		[
			[
				68322,
				76322
			],
			[
				-193,
				26
			]
		],
		[
			[
				68129,
				76348
			],
			[
				-64,
				-1497
			]
		],
		[
			[
				53954,
				38810
			],
			[
				235,
				3
			]
		],
		[
			[
				54189,
				38813
			],
			[
				653,
				9
			]
		],
		[
			[
				54842,
				38822
			],
			[
				-17,
				220
			],
			[
				115,
				83
			],
			[
				-20,
				131
			],
			[
				-66,
				-26
			],
			[
				30,
				182
			],
			[
				-19,
				181
			]
		],
		[
			[
				54865,
				39593
			],
			[
				-674,
				2
			]
		],
		[
			[
				54191,
				39595
			],
			[
				-64,
				-170
			],
			[
				25,
				-120
			],
			[
				-94,
				-191
			],
			[
				-23,
				-191
			],
			[
				-81,
				-113
			]
		],
		[
			[
				67288,
				67568
			],
			[
				289,
				-39
			]
		],
		[
			[
				67577,
				67529
			],
			[
				15,
				407
			],
			[
				32,
				-11
			],
			[
				12,
				281
			]
		],
		[
			[
				67636,
				68206
			],
			[
				-128,
				17
			],
			[
				4,
				110
			]
		],
		[
			[
				67512,
				68333
			],
			[
				-171,
				23
			],
			[
				-146,
				73
			],
			[
				-212,
				250
			]
		],
		[
			[
				78671,
				65706
			],
			[
				73,
				-172
			]
		],
		[
			[
				79011,
				66597
			],
			[
				-243,
				42
			],
			[
				-60,
				-70
			]
		],
		[
			[
				78708,
				66569
			],
			[
				44,
				-90
			],
			[
				-80,
				-333
			],
			[
				91,
				-335
			],
			[
				-92,
				-105
			]
		],
		[
			[
				67468,
				55815
			],
			[
				-29,
				-669
			]
		],
		[
			[
				67439,
				55146
			],
			[
				581,
				-59
			]
		],
		[
			[
				68089,
				56785
			],
			[
				-48,
				5
			]
		],
		[
			[
				68041,
				56790
			],
			[
				-533,
				66
			]
		],
		[
			[
				67508,
				56856
			],
			[
				-40,
				-1041
			]
		],
		[
			[
				64464,
				51251
			],
			[
				144,
				-216
			],
			[
				59,
				19
			],
			[
				70,
				-162
			]
		],
		[
			[
				64737,
				50892
			],
			[
				85,
				145
			]
		],
		[
			[
				64822,
				51037
			],
			[
				136,
				85
			],
			[
				80,
				172
			],
			[
				50,
				-5
			],
			[
				116,
				190
			],
			[
				126,
				-1
			],
			[
				-80,
				166
			],
			[
				70,
				164
			]
		],
		[
			[
				65320,
				51808
			],
			[
				-100,
				218
			],
			[
				-172,
				599
			]
		],
		[
			[
				73644,
				26786
			],
			[
				193,
				-41
			],
			[
				25,
				326
			],
			[
				217,
				-44
			]
		],
		[
			[
				74079,
				27027
			],
			[
				4,
				515
			],
			[
				-32,
				109
			],
			[
				94,
				281
			],
			[
				99,
				86
			],
			[
				47,
				-122
			],
			[
				37,
				117
			],
			[
				173,
				116
			]
		],
		[
			[
				74501,
				28129
			],
			[
				36,
				467
			]
		],
		[
			[
				74537,
				28596
			],
			[
				-190,
				41
			],
			[
				-12,
				-165
			],
			[
				-374,
				75
			],
			[
				-11,
				-165
			],
			[
				-189,
				35
			]
		],
		[
			[
				73761,
				28417
			],
			[
				-71,
				-979
			]
		],
		[
			[
				80340,
				34871
			],
			[
				649,
				-151
			],
			[
				202,
				-96
			]
		],
		[
			[
				81191,
				34624
			],
			[
				120,
				1208
			]
		],
		[
			[
				81311,
				35832
			],
			[
				-121,
				434
			]
		],
		[
			[
				81190,
				36266
			],
			[
				-99,
				-143
			],
			[
				-189,
				-1
			],
			[
				-428,
				132
			]
		],
		[
			[
				80470,
				36223
			],
			[
				-130,
				-1352
			]
		],
		[
			[
				86764,
				45441
			],
			[
				-108,
				55
			],
			[
				-45,
				-100
			]
		],
		[
			[
				47363,
				50609
			],
			[
				30,
				2
			]
		],
		[
			[
				73086,
				51891
			],
			[
				90,
				-528
			],
			[
				74,
				-188
			]
		],
		[
			[
				73250,
				51175
			],
			[
				63,
				211
			],
			[
				213,
				124
			],
			[
				396,
				-80
			]
		],
		[
			[
				73922,
				51430
			],
			[
				6,
				334
			]
		],
		[
			[
				73928,
				51764
			],
			[
				25,
				511
			],
			[
				-51,
				5
			]
		],
		[
			[
				73902,
				52280
			],
			[
				-183,
				-164
			],
			[
				-155,
				219
			],
			[
				-142,
				-178
			],
			[
				-180,
				3
			],
			[
				-38,
				54
			]
		],
		[
			[
				73204,
				52214
			],
			[
				-85,
				-309
			],
			[
				-33,
				-14
			]
		],
		[
			[
				61242,
				79060
			],
			[
				169,
				-9
			],
			[
				50,
				-220
			],
			[
				182,
				-200
			],
			[
				103,
				140
			],
			[
				95,
				-14
			]
		],
		[
			[
				61841,
				78757
			],
			[
				3,
				112
			],
			[
				138,
				325
			]
		],
		[
			[
				61982,
				79194
			],
			[
				7,
				213
			],
			[
				76,
				91
			],
			[
				9,
				361
			],
			[
				-52,
				71
			],
			[
				-13,
				338
			],
			[
				-97,
				7
			],
			[
				1,
				112
			],
			[
				-293,
				20
			],
			[
				-82,
				46
			],
			[
				-62,
				186
			]
		],
		[
			[
				61476,
				80639
			],
			[
				-267,
				14
			]
		],
		[
			[
				61209,
				80653
			],
			[
				3,
				-26
			]
		],
		[
			[
				94726,
				9431
			],
			[
				1333,
				-652
			],
			[
				114,
				644
			]
		],
		[
			[
				96173,
				9423
			],
			[
				436,
				2609
			],
			[
				-183,
				182
			],
			[
				61,
				290
			],
			[
				24,
				264
			],
			[
				152,
				-135
			],
			[
				303,
				991
			],
			[
				-148,
				136
			],
			[
				88,
				283
			],
			[
				-711,
				630
			]
		],
		[
			[
				96195,
				14673
			],
			[
				-207,
				180
			],
			[
				-6,
				117
			],
			[
				-151,
				130
			],
			[
				-106,
				-303
			],
			[
				-459,
				-1537
			],
			[
				58,
				-124
			],
			[
				7,
				-246
			],
			[
				-86,
				-161
			],
			[
				-79,
				-16
			],
			[
				-42,
				-285
			],
			[
				88,
				-13
			],
			[
				-32,
				-475
			],
			[
				42,
				117
			],
			[
				48,
				-71
			],
			[
				-128,
				-416
			],
			[
				-64,
				58
			],
			[
				-259,
				-1529
			],
			[
				19,
				-9
			],
			[
				-112,
				-659
			]
		],
		[
			[
				59918,
				55283
			],
			[
				286,
				-2
			],
			[
				1,
				57
			]
		],
		[
			[
				59513,
				56354
			],
			[
				-67,
				1
			],
			[
				-3,
				-676
			],
			[
				-482,
				9
			]
		],
		[
			[
				61037,
				44158
			],
			[
				569,
				-28
			]
		],
		[
			[
				61606,
				44130
			],
			[
				191,
				-10
			]
		],
		[
			[
				61797,
				44120
			],
			[
				17,
				1118
			]
		],
		[
			[
				61814,
				45238
			],
			[
				-576,
				24
			],
			[
				2,
				227
			],
			[
				-192,
				8
			]
		],
		[
			[
				31024,
				14915
			],
			[
				56,
				82
			],
			[
				-12,
				240
			],
			[
				44,
				177
			],
			[
				120,
				62
			],
			[
				-47,
				347
			],
			[
				110,
				192
			],
			[
				-6,
				219
			],
			[
				127,
				4
			],
			[
				145,
				192
			],
			[
				-30,
				209
			],
			[
				-90,
				129
			],
			[
				-23,
				133
			],
			[
				61,
				118
			],
			[
				6,
				224
			]
		],
		[
			[
				31485,
				17243
			],
			[
				-438,
				-102
			],
			[
				-27,
				245
			],
			[
				-82,
				42
			],
			[
				-54,
				154
			],
			[
				-17,
				249
			],
			[
				-58,
				-25
			],
			[
				-81,
				226
			],
			[
				-152,
				64
			],
			[
				-138,
				244
			]
		],
		[
			[
				30438,
				18340
			],
			[
				79,
				-812
			],
			[
				-190,
				-57
			],
			[
				186,
				-1956
			]
		],
		[
			[
				48737,
				33466
			],
			[
				227,
				66
			],
			[
				204,
				-197
			],
			[
				261,
				-108
			]
		],
		[
			[
				49429,
				33227
			],
			[
				16,
				85
			],
			[
				-25,
				1337
			],
			[
				18,
				2
			],
			[
				-28,
				1332
			]
		],
		[
			[
				49410,
				35983
			],
			[
				-704,
				-36
			]
		],
		[
			[
				48706,
				35947
			],
			[
				28,
				-1340
			],
			[
				-20,
				-1
			],
			[
				23,
				-1140
			]
		],
		[
			[
				55381,
				26709
			],
			[
				372,
				-2
			],
			[
				-2,
				-600
			]
		],
		[
			[
				55751,
				26107
			],
			[
				112,
				141
			],
			[
				73,
				-23
			],
			[
				178,
				268
			],
			[
				426,
				369
			]
		],
		[
			[
				56540,
				26862
			],
			[
				5,
				827
			],
			[
				-385,
				9
			],
			[
				0,
				333
			]
		],
		[
			[
				56160,
				28031
			],
			[
				-565,
				4
			]
		],
		[
			[
				55595,
				28035
			],
			[
				-209,
				3
			]
		],
		[
			[
				55386,
				28038
			],
			[
				-5,
				-1329
			]
		],
		[
			[
				64687,
				75373
			],
			[
				30,
				-227
			],
			[
				-29,
				-781
			]
		],
		[
			[
				37859,
				43838
			],
			[
				78,
				-134
			],
			[
				8,
				-231
			],
			[
				106,
				63
			],
			[
				151,
				-48
			],
			[
				89,
				-167
			]
		],
		[
			[
				38291,
				43321
			],
			[
				95,
				50
			],
			[
				211,
				368
			],
			[
				165,
				70
			]
		],
		[
			[
				38762,
				43809
			],
			[
				-42,
				694
			]
		],
		[
			[
				38720,
				44503
			],
			[
				-731,
				-122
			]
		],
		[
			[
				37989,
				44381
			],
			[
				101,
				-161
			],
			[
				-23,
				-102
			],
			[
				-217,
				-181
			],
			[
				9,
				-99
			]
		],
		[
			[
				84514,
				27895
			],
			[
				157,
				-49
			],
			[
				-44,
				-343
			],
			[
				181,
				-62
			]
		],
		[
			[
				84808,
				27441
			],
			[
				376,
				-149
			],
			[
				13,
				103
			],
			[
				270,
				-96
			]
		],
		[
			[
				85467,
				27299
			],
			[
				64,
				528
			],
			[
				-23,
				23
			],
			[
				58,
				391
			]
		],
		[
			[
				84817,
				29231
			],
			[
				-34,
				-354
			],
			[
				-180,
				54
			],
			[
				-60,
				-337
			],
			[
				37,
				-12
			],
			[
				-39,
				-343
			],
			[
				58,
				-20
			],
			[
				-85,
				-324
			]
		],
		[
			[
				81638,
				55040
			],
			[
				207,
				-166
			],
			[
				175,
				-3
			],
			[
				26,
				-134
			],
			[
				76,
				38
			],
			[
				72,
				-80
			],
			[
				30,
				92
			],
			[
				154,
				28
			]
		],
		[
			[
				82372,
				55578
			],
			[
				-350,
				80
			]
		],
		[
			[
				82022,
				55658
			],
			[
				-335,
				75
			]
		],
		[
			[
				81687,
				55733
			],
			[
				-49,
				-693
			]
		],
		[
			[
				49939,
				11985
			],
			[
				344,
				20
			],
			[
				13,
				-657
			]
		],
		[
			[
				51271,
				11396
			],
			[
				-10,
				658
			],
			[
				39,
				2
			],
			[
				-9,
				661
			]
		],
		[
			[
				50924,
				13763
			],
			[
				-151,
				-12
			],
			[
				-65,
				-322
			],
			[
				-96,
				69
			],
			[
				-71,
				-82
			],
			[
				-28,
				-222
			],
			[
				-287,
				249
			],
			[
				-10,
				-240
			],
			[
				-107,
				113
			],
			[
				6,
				-325
			],
			[
				-191,
				-191
			],
			[
				15,
				-815
			]
		],
		[
			[
				53997,
				64371
			],
			[
				572,
				3
			]
		],
		[
			[
				54569,
				64374
			],
			[
				0,
				335
			]
		],
		[
			[
				53992,
				65717
			],
			[
				-195,
				-7
			],
			[
				1,
				-330
			]
		],
		[
			[
				12267,
				12731
			],
			[
				495,
				252
			],
			[
				14,
				122
			],
			[
				124,
				60
			],
			[
				56,
				261
			],
			[
				93,
				47
			],
			[
				43,
				137
			]
		],
		[
			[
				13092,
				13610
			],
			[
				-8,
				225
			]
		],
		[
			[
				12638,
				14348
			],
			[
				-991,
				-506
			]
		],
		[
			[
				11647,
				13842
			],
			[
				-91,
				-48
			],
			[
				91,
				-511
			],
			[
				484,
				246
			],
			[
				136,
				-798
			]
		],
		[
			[
				67192,
				60030
			],
			[
				26,
				1007
			]
		],
		[
			[
				67218,
				61037
			],
			[
				-186,
				17
			],
			[
				3,
				138
			]
		],
		[
			[
				67035,
				61192
			],
			[
				-524,
				40
			]
		],
		[
			[
				66511,
				61232
			],
			[
				-14,
				-553
			],
			[
				-64,
				-124
			]
		],
		[
			[
				66433,
				60555
			],
			[
				56,
				-63
			],
			[
				-7,
				-338
			],
			[
				60,
				-257
			],
			[
				-25,
				-68
			],
			[
				59,
				-227
			],
			[
				83,
				10
			]
		],
		[
			[
				51860,
				71162
			],
			[
				272,
				20
			]
		],
		[
			[
				52701,
				72893
			],
			[
				-869,
				-44
			]
		],
		[
			[
				53039,
				84083
			],
			[
				308,
				-608
			],
			[
				287,
				-642
			]
		],
		[
			[
				54396,
				84091
			],
			[
				-258,
				455
			],
			[
				-81,
				414
			],
			[
				-570,
				600
			]
		],
		[
			[
				53487,
				85560
			],
			[
				-35,
				-62
			]
		],
		[
			[
				51959,
				74320
			],
			[
				0,
				-31
			],
			[
				736,
				-305
			]
		],
		[
			[
				53048,
				74726
			],
			[
				329,
				1000
			]
		],
		[
			[
				52972,
				76127
			],
			[
				-195,
				-596
			],
			[
				-457,
				442
			]
		],
		[
			[
				52320,
				75973
			],
			[
				-91,
				-175
			],
			[
				90,
				-138
			],
			[
				-191,
				-169
			],
			[
				18,
				-102
			],
			[
				-112,
				-162
			],
			[
				91,
				-117
			],
			[
				-71,
				-198
			],
			[
				-121,
				-29
			],
			[
				25,
				-282
			],
			[
				-33,
				-152
			],
			[
				34,
				-129
			]
		],
		[
			[
				85101,
				44643
			],
			[
				220,
				301
			]
		],
		[
			[
				85321,
				44944
			],
			[
				193,
				265
			],
			[
				106,
				198
			],
			[
				-36,
				101
			],
			[
				64,
				198
			]
		],
		[
			[
				85648,
				45706
			],
			[
				-200,
				383
			],
			[
				-111,
				-125
			]
		],
		[
			[
				84969,
				45281
			],
			[
				9,
				-186
			],
			[
				78,
				-179
			],
			[
				45,
				-273
			]
		],
		[
			[
				79794,
				52711
			],
			[
				119,
				-102
			],
			[
				60,
				155
			],
			[
				166,
				-264
			],
			[
				170,
				-157
			],
			[
				68,
				15
			]
		],
		[
			[
				80377,
				52358
			],
			[
				120,
				193
			]
		],
		[
			[
				80497,
				52551
			],
			[
				111,
				177
			],
			[
				161,
				490
			]
		],
		[
			[
				80769,
				53218
			],
			[
				-208,
				146
			],
			[
				-68,
				95
			],
			[
				-198,
				123
			],
			[
				10,
				174
			],
			[
				-97,
				96
			]
		],
		[
			[
				80208,
				53852
			],
			[
				-22,
				23
			],
			[
				-193,
				-556
			],
			[
				-288,
				-470
			]
		],
		[
			[
				79705,
				52849
			],
			[
				89,
				-138
			]
		],
		[
			[
				86710,
				43176
			],
			[
				102,
				-26
			],
			[
				-45,
				133
			],
			[
				-57,
				-107
			]
		],
		[
			[
				66140,
				28462
			],
			[
				-16,
				-337
			]
		],
		[
			[
				66124,
				28125
			],
			[
				-44,
				-992
			]
		],
		[
			[
				80511,
				70601
			],
			[
				399,
				54
			]
		],
		[
			[
				80910,
				70655
			],
			[
				150,
				729
			],
			[
				180,
				75
			],
			[
				238,
				-51
			]
		],
		[
			[
				81478,
				71408
			],
			[
				-106,
				155
			]
		],
		[
			[
				81372,
				71563
			],
			[
				-98,
				289
			],
			[
				22,
				105
			],
			[
				-119,
				532
			]
		],
		[
			[
				81015,
				72383
			],
			[
				-200,
				-230
			],
			[
				-158,
				-14
			]
		],
		[
			[
				80657,
				72139
			],
			[
				-15,
				-852
			],
			[
				14,
				-129
			],
			[
				-121,
				-404
			],
			[
				-24,
				-153
			]
		],
		[
			[
				58882,
				52473
			],
			[
				97,
				1
			]
		],
		[
			[
				58918,
				53463
			],
			[
				2,
				-653
			],
			[
				-40,
				-1
			],
			[
				2,
				-336
			]
		],
		[
			[
				71250,
				30324
			],
			[
				119,
				1667
			]
		],
		[
			[
				70678,
				32118
			],
			[
				-47,
				-577
			],
			[
				-49,
				-365
			],
			[
				-87,
				-381
			]
		],
		[
			[
				60715,
				26846
			],
			[
				256,
				163
			]
		],
		[
			[
				60971,
				27009
			],
			[
				202,
				169
			],
			[
				56,
				95
			],
			[
				13,
				180
			],
			[
				108,
				350
			]
		],
		[
			[
				61350,
				27803
			],
			[
				-352,
				26
			],
			[
				7,
				330
			]
		],
		[
			[
				60245,
				27872
			],
			[
				-14,
				-665
			],
			[
				191,
				-12
			],
			[
				-7,
				-332
			],
			[
				300,
				-17
			]
		],
		[
			[
				56678,
				65360
			],
			[
				870,
				-23
			]
		],
		[
			[
				57548,
				65337
			],
			[
				-5,
				1224
			]
		],
		[
			[
				57543,
				66561
			],
			[
				-4,
				959
			]
		],
		[
			[
				57539,
				67520
			],
			[
				-5,
				1167
			]
		],
		[
			[
				57534,
				68687
			],
			[
				-64,
				84
			],
			[
				-123,
				-237
			],
			[
				-110,
				40
			],
			[
				-25,
				-87
			],
			[
				-145,
				-50
			]
		],
		[
			[
				57067,
				68437
			],
			[
				-71,
				-188
			],
			[
				-136,
				63
			],
			[
				-131,
				-178
			],
			[
				-93,
				-285
			],
			[
				-147,
				-10
			],
			[
				-153,
				-278
			],
			[
				-46,
				1
			]
		],
		[
			[
				56290,
				67562
			],
			[
				-5,
				-847
			]
		],
		[
			[
				87388,
				45758
			],
			[
				87,
				-76
			]
		],
		[
			[
				87475,
				45682
			],
			[
				83,
				249
			],
			[
				38,
				-81
			],
			[
				130,
				78
			]
		],
		[
			[
				87726,
				45928
			],
			[
				88,
				278
			]
		],
		[
			[
				87814,
				46206
			],
			[
				14,
				99
			],
			[
				203,
				228
			],
			[
				168,
				327
			],
			[
				100,
				77
			]
		],
		[
			[
				88299,
				46937
			],
			[
				-97,
				198
			]
		],
		[
			[
				88202,
				47135
			],
			[
				-122,
				-219
			],
			[
				-232,
				121
			],
			[
				-24,
				-162
			],
			[
				-134,
				-20
			],
			[
				-61,
				-295
			],
			[
				-80,
				-128
			]
		],
		[
			[
				87549,
				46432
			],
			[
				-54,
				-174
			],
			[
				-168,
				-202
			],
			[
				61,
				-298
			]
		],
		[
			[
				87117,
				26150
			],
			[
				250,
				-120
			],
			[
				136,
				140
			],
			[
				127,
				263
			],
			[
				69,
				-35
			],
			[
				67,
				392
			],
			[
				156,
				-64
			],
			[
				45,
				258
			],
			[
				297,
				-145
			]
		],
		[
			[
				88264,
				26839
			],
			[
				37,
				329
			],
			[
				-52,
				179
			]
		],
		[
			[
				88249,
				27347
			],
			[
				-212,
				75
			],
			[
				-725,
				354
			]
		],
		[
			[
				86931,
				26109
			],
			[
				186,
				41
			]
		],
		[
			[
				90800,
				36197
			],
			[
				134,
				-154
			],
			[
				14,
				-106
			],
			[
				162,
				-470
			],
			[
				15,
				-191
			]
		],
		[
			[
				91125,
				35276
			],
			[
				148,
				-73
			],
			[
				245,
				83
			],
			[
				7,
				-151
			],
			[
				86,
				556
			],
			[
				13,
				781
			]
		],
		[
			[
				91624,
				36472
			],
			[
				-59,
				70
			],
			[
				-85,
				-155
			],
			[
				-68,
				150
			],
			[
				-146,
				18
			],
			[
				-59,
				-183
			],
			[
				-238,
				79
			],
			[
				-194,
				445
			]
		],
		[
			[
				90775,
				36896
			],
			[
				-88,
				-200
			]
		],
		[
			[
				90687,
				36696
			],
			[
				-65,
				-149
			],
			[
				81,
				-61
			],
			[
				97,
				-289
			]
		],
		[
			[
				35527,
				69188
			],
			[
				-23,
				361
			],
			[
				-67,
				-11
			],
			[
				-235,
				3655
			]
		],
		[
			[
				35202,
				73193
			],
			[
				-453,
				-83
			],
			[
				-31,
				328
			],
			[
				-46,
				66
			],
			[
				59,
				270
			],
			[
				133,
				198
			]
		],
		[
			[
				34864,
				73972
			],
			[
				-1444,
				-287
			]
		],
		[
			[
				33420,
				73685
			],
			[
				214,
				-3136
			]
		],
		[
			[
				87962,
				53361
			],
			[
				-28,
				-145
			],
			[
				117,
				-78
			],
			[
				22,
				-124
			]
		],
		[
			[
				88073,
				53014
			],
			[
				420,
				-110
			],
			[
				437,
				-175
			]
		],
		[
			[
				88930,
				52729
			],
			[
				-29,
				132
			],
			[
				17,
				252
			],
			[
				87,
				287
			],
			[
				110,
				256
			],
			[
				-1,
				184
			]
		],
		[
			[
				89114,
				53840
			],
			[
				-84,
				346
			]
		],
		[
			[
				89030,
				54186
			],
			[
				-131,
				-37
			],
			[
				10,
				130
			],
			[
				-94,
				185
			],
			[
				-76,
				-297
			],
			[
				-142,
				187
			],
			[
				-99,
				-291
			],
			[
				-80,
				101
			],
			[
				-168,
				30
			],
			[
				-65,
				-265
			],
			[
				-225,
				-128
			],
			[
				-19,
				-63
			]
		],
		[
			[
				88362,
				54937
			],
			[
				29,
				-25
			],
			[
				336,
				192
			],
			[
				227,
				-263
			]
		],
		[
			[
				88954,
				54841
			],
			[
				365,
				-128
			]
		],
		[
			[
				89319,
				54713
			],
			[
				83,
				199
			],
			[
				149,
				202
			],
			[
				137,
				57
			]
		],
		[
			[
				89688,
				55171
			],
			[
				-20,
				76
			],
			[
				-206,
				72
			],
			[
				-48,
				128
			],
			[
				81,
				166
			],
			[
				72,
				265
			],
			[
				-248,
				-70
			],
			[
				-84,
				62
			],
			[
				-139,
				-65
			],
			[
				-208,
				-29
			],
			[
				-165,
				-106
			],
			[
				7,
				94
			],
			[
				130,
				163
			],
			[
				134,
				-31
			],
			[
				443,
				191
			],
			[
				105,
				-3
			]
		],
		[
			[
				89542,
				56084
			],
			[
				1,
				401
			],
			[
				-368,
				205
			],
			[
				-106,
				-111
			]
		],
		[
			[
				89069,
				56579
			],
			[
				-134,
				-140
			],
			[
				-462,
				-305
			]
		],
		[
			[
				88473,
				56134
			],
			[
				-20,
				-387
			],
			[
				71,
				-160
			],
			[
				76,
				-22
			],
			[
				-194,
				-259
			],
			[
				-44,
				-369
			]
		],
		[
			[
				80100,
				54078
			],
			[
				574,
				-99
			]
		],
		[
			[
				80674,
				53979
			],
			[
				10,
				110
			],
			[
				176,
				338
			],
			[
				62,
				304
			]
		],
		[
			[
				80922,
				54731
			],
			[
				-128,
				-11
			],
			[
				-46,
				95
			],
			[
				3,
				271
			],
			[
				-82,
				40
			],
			[
				-98,
				186
			]
		],
		[
			[
				80571,
				55312
			],
			[
				-168,
				-80
			],
			[
				-158,
				-268
			],
			[
				-172,
				-108
			]
		],
		[
			[
				80073,
				54856
			],
			[
				36,
				-333
			],
			[
				-41,
				-195
			],
			[
				32,
				-250
			]
		],
		[
			[
				9955,
				37654
			],
			[
				837,
				402
			]
		],
		[
			[
				10792,
				38056
			],
			[
				5,
				195
			],
			[
				137,
				166
			],
			[
				-29,
				177
			],
			[
				38,
				180
			],
			[
				155,
				75
			],
			[
				-34,
				92
			]
		],
		[
			[
				11064,
				38941
			],
			[
				-81,
				161
			],
			[
				84,
				371
			],
			[
				69,
				42
			],
			[
				-32,
				204
			],
			[
				-105,
				128
			],
			[
				69,
				161
			],
			[
				-93,
				395
			],
			[
				-148,
				119
			]
		],
		[
			[
				10827,
				40522
			],
			[
				-168,
				-82
			],
			[
				127,
				-794
			],
			[
				-67,
				-75
			],
			[
				-132,
				-16
			],
			[
				-207,
				-128
			],
			[
				-150,
				107
			],
			[
				-54,
				-140
			],
			[
				-80,
				-23
			]
		],
		[
			[
				10096,
				39371
			],
			[
				2,
				-281
			],
			[
				-98,
				-736
			],
			[
				-10,
				-344
			],
			[
				-106,
				-35
			],
			[
				-57,
				-142
			]
		],
		[
			[
				9827,
				37833
			],
			[
				-20,
				-169
			],
			[
				43,
				-65
			],
			[
				105,
				55
			]
		],
		[
			[
				79419,
				33698
			],
			[
				161,
				-98
			],
			[
				582,
				-585
			]
		],
		[
			[
				80162,
				33015
			],
			[
				47,
				486
			]
		],
		[
			[
				80209,
				33501
			],
			[
				127,
				1328
			]
		],
		[
			[
				80336,
				34829
			],
			[
				-794,
				213
			]
		],
		[
			[
				79466,
				34230
			],
			[
				-47,
				-532
			]
		],
		[
			[
				88522,
				33482
			],
			[
				63,
				-267
			],
			[
				58,
				-58
			]
		],
		[
			[
				88643,
				33157
			],
			[
				235,
				-115
			]
		],
		[
			[
				89549,
				33366
			],
			[
				39,
				-23
			]
		],
		[
			[
				89588,
				33343
			],
			[
				-69,
				242
			],
			[
				-147,
				249
			],
			[
				28,
				79
			]
		],
		[
			[
				89400,
				33913
			],
			[
				-140,
				213
			],
			[
				-93,
				295
			],
			[
				-130,
				172
			],
			[
				-141,
				117
			]
		],
		[
			[
				88896,
				34710
			],
			[
				-230,
				-453
			],
			[
				111,
				-183
			],
			[
				-316,
				-415
			]
		],
		[
			[
				83847,
				60004
			],
			[
				421,
				-118
			]
		],
		[
			[
				84751,
				60425
			],
			[
				24,
				29
			]
		],
		[
			[
				84775,
				60454
			],
			[
				-204,
				1325
			]
		],
		[
			[
				84571,
				61779
			],
			[
				-47,
				-14
			]
		],
		[
			[
				84524,
				61765
			],
			[
				-218,
				-450
			],
			[
				33,
				-200
			],
			[
				-89,
				-103
			],
			[
				-63,
				151
			],
			[
				-61,
				-167
			]
		],
		[
			[
				84126,
				60996
			],
			[
				18,
				-307
			],
			[
				46,
				-31
			],
			[
				-201,
				-255
			],
			[
				-123,
				-244
			],
			[
				-19,
				-155
			]
		],
		[
			[
				46291,
				30738
			],
			[
				1637,
				134
			]
		],
		[
			[
				47928,
				30872
			],
			[
				-26,
				1006
			],
			[
				25,
				1
			],
			[
				-13,
				499
			]
		],
		[
			[
				47914,
				32378
			],
			[
				-1671,
				-139
			]
		],
		[
			[
				46243,
				32239
			],
			[
				48,
				-1501
			]
		],
		[
			[
				45406,
				20844
			],
			[
				3,
				0
			]
		],
		[
			[
				45409,
				20844
			],
			[
				899,
				96
			],
			[
				1410,
				126
			]
		],
		[
			[
				47809,
				22407
			],
			[
				-94,
				216
			],
			[
				64,
				107
			],
			[
				82,
				-2
			],
			[
				74,
				156
			]
		],
		[
			[
				47935,
				22884
			],
			[
				-1766,
				-155
			]
		],
		[
			[
				46169,
				22729
			],
			[
				-829,
				-85
			]
		],
		[
			[
				45340,
				22644
			],
			[
				66,
				-1800
			]
		],
		[
			[
				52005,
				67619
			],
			[
				60,
				95
			],
			[
				-24,
				191
			],
			[
				165,
				90
			],
			[
				69,
				-204
			],
			[
				147,
				40
			],
			[
				86,
				-201
			],
			[
				57,
				93
			],
			[
				22,
				200
			],
			[
				-73,
				148
			],
			[
				60,
				248
			],
			[
				138,
				40
			],
			[
				19,
				-311
			],
			[
				191,
				-296
			],
			[
				-15,
				-242
			],
			[
				92,
				2
			]
		],
		[
			[
				52999,
				67512
			],
			[
				-11,
				2051
			]
		],
		[
			[
				52175,
				69492
			],
			[
				-193,
				-18
			]
		],
		[
			[
				51982,
				69474
			],
			[
				23,
				-1855
			]
		],
		[
			[
				23690,
				44634
			],
			[
				1056,
				343
			],
			[
				1556,
				479
			]
		],
		[
			[
				26302,
				45456
			],
			[
				-30,
				236
			]
		],
		[
			[
				26272,
				45692
			],
			[
				-14,
				120
			],
			[
				165,
				378
			],
			[
				93,
				270
			],
			[
				-110,
				314
			],
			[
				31,
				85
			],
			[
				-178,
				241
			]
		],
		[
			[
				26259,
				47100
			],
			[
				-60,
				-8
			]
		],
		[
			[
				23509,
				46242
			],
			[
				181,
				-1608
			]
		],
		[
			[
				88097,
				48554
			],
			[
				-5,
				-215
			],
			[
				111,
				-110
			],
			[
				98,
				28
			],
			[
				35,
				-139
			]
		],
		[
			[
				88336,
				48118
			],
			[
				67,
				117
			]
		],
		[
			[
				88403,
				48235
			],
			[
				116,
				161
			]
		],
		[
			[
				88519,
				48396
			],
			[
				-138,
				87
			],
			[
				-10,
				121
			],
			[
				64,
				119
			]
		],
		[
			[
				88535,
				48842
			],
			[
				176,
				139
			]
		],
		[
			[
				88699,
				49173
			],
			[
				-92,
				-180
			],
			[
				-137,
				76
			],
			[
				-36,
				100
			],
			[
				-80,
				-128
			],
			[
				-154,
				-63
			]
		],
		[
			[
				88200,
				48978
			],
			[
				-46,
				-396
			],
			[
				-57,
				-28
			]
		],
		[
			[
				20797,
				12053
			],
			[
				18,
				26
			]
		],
		[
			[
				20815,
				12079
			],
			[
				58,
				156
			],
			[
				118,
				-28
			],
			[
				326,
				62
			],
			[
				61,
				-103
			]
		],
		[
			[
				21437,
				14640
			],
			[
				-566,
				-233
			]
		],
		[
			[
				20871,
				14407
			],
			[
				-29,
				-12
			]
		],
		[
			[
				20842,
				14395
			],
			[
				114,
				-766
			],
			[
				-189,
				-76
			],
			[
				46,
				-325
			],
			[
				-189,
				-75
			],
			[
				135,
				-983
			],
			[
				38,
				-117
			]
		],
		[
			[
				65702,
				20157
			],
			[
				196,
				174
			],
			[
				201,
				3
			]
		],
		[
			[
				66099,
				20334
			],
			[
				19,
				119
			],
			[
				42,
				988
			],
			[
				390,
				-51
			]
		],
		[
			[
				66550,
				21390
			],
			[
				58,
				1317
			]
		],
		[
			[
				66608,
				22707
			],
			[
				-391,
				46
			]
		],
		[
			[
				66217,
				22753
			],
			[
				-389,
				51
			],
			[
				-10,
				-331
			],
			[
				-192,
				26
			]
		],
		[
			[
				40433,
				23788
			],
			[
				61,
				10
			]
		],
		[
			[
				40494,
				23798
			],
			[
				1540,
				249
			]
		],
		[
			[
				42034,
				24047
			],
			[
				-78,
				1627
			]
		],
		[
			[
				60665,
				63869
			],
			[
				161,
				-3
			],
			[
				1,
				336
			],
			[
				191,
				-1
			],
			[
				2,
				279
			],
			[
				199,
				54
			],
			[
				1,
				174
			],
			[
				179,
				-6
			],
			[
				0,
				167
			],
			[
				191,
				-7
			],
			[
				5,
				336
			]
		],
		[
			[
				60595,
				65222
			],
			[
				-34,
				-333
			],
			[
				-126,
				5
			],
			[
				0,
				-170
			],
			[
				-191,
				2
			],
			[
				10,
				-521
			],
			[
				-193,
				12
			]
		],
		[
			[
				37202,
				51289
			],
			[
				1012,
				166
			]
		],
		[
			[
				38214,
				51455
			],
			[
				-112,
				578
			],
			[
				24,
				80
			]
		],
		[
			[
				38126,
				52113
			],
			[
				-22,
				51
			],
			[
				-479,
				720
			]
		],
		[
			[
				79999,
				80387
			],
			[
				52,
				-23
			],
			[
				7,
				-234
			]
		],
		[
			[
				80500,
				80244
			],
			[
				88,
				1003
			]
		],
		[
			[
				80588,
				81247
			],
			[
				-192,
				55
			],
			[
				-39,
				-77
			],
			[
				-318,
				52
			]
		],
		[
			[
				80039,
				81277
			],
			[
				-53,
				-172
			],
			[
				-21,
				-441
			],
			[
				34,
				-277
			]
		],
		[
			[
				75684,
				79872
			],
			[
				92,
				-261
			],
			[
				-1,
				-283
			],
			[
				49,
				-289
			],
			[
				17,
				-450
			],
			[
				88,
				-332
			]
		],
		[
			[
				75929,
				78257
			],
			[
				94,
				-16
			],
			[
				20,
				276
			],
			[
				196,
				16
			],
			[
				17,
				223
			],
			[
				128,
				-28
			],
			[
				12,
				164
			],
			[
				137,
				84
			]
		],
		[
			[
				76533,
				78976
			],
			[
				-117,
				237
			],
			[
				12,
				124
			]
		],
		[
			[
				76829,
				80369
			],
			[
				-890,
				170
			],
			[
				-23,
				168
			]
		],
		[
			[
				75916,
				80707
			],
			[
				-183,
				-163
			],
			[
				-95,
				-247
			],
			[
				46,
				-425
			]
		],
		[
			[
				79679,
				70053
			],
			[
				300,
				6
			],
			[
				216,
				147
			],
			[
				-23,
				343
			]
		],
		[
			[
				80172,
				70549
			],
			[
				-433,
				271
			],
			[
				-7,
				55
			]
		],
		[
			[
				79732,
				70875
			],
			[
				-127,
				-15
			]
		],
		[
			[
				79605,
				70860
			],
			[
				-56,
				-64
			],
			[
				68,
				-359
			],
			[
				8,
				-169
			],
			[
				69,
				-49
			],
			[
				-15,
				-166
			]
		],
		[
			[
				76330,
				66409
			],
			[
				178,
				-33
			]
		],
		[
			[
				76508,
				66376
			],
			[
				126,
				297
			],
			[
				164,
				108
			]
		],
		[
			[
				76798,
				66781
			],
			[
				32,
				101
			],
			[
				161,
				28
			],
			[
				52,
				134
			]
		],
		[
			[
				76756,
				67673
			],
			[
				-99,
				-124
			],
			[
				-178,
				37
			],
			[
				-5,
				-64
			],
			[
				-197,
				42
			]
		],
		[
			[
				76277,
				67564
			],
			[
				-21,
				-320
			],
			[
				108,
				-21
			],
			[
				-5,
				-376
			],
			[
				55,
				-234
			],
			[
				-84,
				-204
			]
		],
		[
			[
				24791,
				26842
			],
			[
				350,
				113
			]
		],
		[
			[
				25141,
				26955
			],
			[
				1051,
				344
			]
		],
		[
			[
				25661,
				28828
			],
			[
				-187,
				-61
			],
			[
				18,
				-164
			],
			[
				-473,
				-159
			],
			[
				-14,
				-168
			],
			[
				-366,
				-124
			]
		],
		[
			[
				67426,
				50586
			],
			[
				-19,
				-506
			]
		],
		[
			[
				67407,
				50080
			],
			[
				575,
				-73
			]
		],
		[
			[
				67982,
				50007
			],
			[
				56,
				1336
			]
		],
		[
			[
				68038,
				51343
			],
			[
				0,
				3
			]
		],
		[
			[
				67460,
				51427
			],
			[
				-34,
				-841
			]
		],
		[
			[
				71838,
				44033
			],
			[
				-13,
				-226
			]
		],
		[
			[
				72362,
				45055
			],
			[
				-455,
				94
			]
		],
		[
			[
				71907,
				45149
			],
			[
				-69,
				-1116
			]
		],
		[
			[
				56236,
				36991
			],
			[
				6,
				1006
			],
			[
				86,
				-2
			],
			[
				2,
				376
			]
		],
		[
			[
				56141,
				38372
			],
			[
				-570,
				3
			]
		],
		[
			[
				55571,
				38375
			],
			[
				-1,
				-370
			],
			[
				-100,
				0
			],
			[
				-2,
				-1005
			]
		],
		[
			[
				48507,
				54093
			],
			[
				-16,
				-335
			]
		],
		[
			[
				82894,
				45181
			],
			[
				92,
				-235
			],
			[
				62,
				-267
			],
			[
				-73,
				-216
			],
			[
				35,
				-146
			],
			[
				103,
				-120
			],
			[
				-31,
				-113
			],
			[
				140,
				-7
			],
			[
				28,
				-152
			]
		],
		[
			[
				83250,
				43925
			],
			[
				423,
				436
			]
		],
		[
			[
				83673,
				44361
			],
			[
				152,
				157
			]
		],
		[
			[
				83825,
				44518
			],
			[
				-159,
				978
			],
			[
				-13,
				187
			]
		],
		[
			[
				83653,
				45683
			],
			[
				-124,
				296
			]
		],
		[
			[
				83529,
				45979
			],
			[
				-298,
				-88
			],
			[
				-142,
				-328
			],
			[
				-205,
				-94
			]
		],
		[
			[
				85995,
				52538
			],
			[
				485,
				-159
			]
		],
		[
			[
				86480,
				52379
			],
			[
				255,
				-86
			]
		],
		[
			[
				86735,
				52293
			],
			[
				20,
				142
			]
		],
		[
			[
				55815,
				78633
			],
			[
				902,
				-1275
			]
		],
		[
			[
				56717,
				77358
			],
			[
				-5,
				147
			],
			[
				99,
				43
			],
			[
				24,
				303
			],
			[
				72,
				87
			],
			[
				36,
				338
			]
		],
		[
			[
				56943,
				78276
			],
			[
				-181,
				404
			],
			[
				-494,
				843
			]
		],
		[
			[
				56268,
				79523
			],
			[
				-60,
				-63
			],
			[
				-42,
				-251
			],
			[
				-115,
				35
			],
			[
				-28,
				148
			]
		],
		[
			[
				56023,
				79392
			],
			[
				-132,
				8
			],
			[
				31,
				-86
			],
			[
				-104,
				-218
			],
			[
				-3,
				-463
			]
		],
		[
			[
				45803,
				9264
			],
			[
				777,
				78
			]
		],
		[
			[
				46580,
				9342
			],
			[
				-35,
				1054
			],
			[
				68,
				7
			],
			[
				-23,
				657
			],
			[
				585,
				58
			]
		],
		[
			[
				47175,
				11118
			],
			[
				-11,
				328
			]
		],
		[
			[
				47164,
				11446
			],
			[
				-1169,
				-119
			],
			[
				36,
				-983
			],
			[
				-267,
				-29
			],
			[
				12,
				-328
			]
		],
		[
			[
				47839,
				92911
			],
			[
				170,
				-197
			],
			[
				70,
				179
			],
			[
				757,
				57
			]
		],
		[
			[
				48836,
				92950
			],
			[
				-33,
				1837
			]
		],
		[
			[
				48803,
				94787
			],
			[
				-122,
				414
			],
			[
				-326,
				374
			]
		],
		[
			[
				48355,
				95575
			],
			[
				-56,
				-325
			],
			[
				-12,
				-260
			],
			[
				-61,
				-84
			],
			[
				-50,
				-373
			],
			[
				-118,
				-146
			],
			[
				17,
				-102
			],
			[
				-113,
				-111
			],
			[
				7,
				-150
			],
			[
				-73,
				-169
			],
			[
				-62,
				-26
			],
			[
				40,
				-256
			],
			[
				13,
				-323
			],
			[
				-48,
				-339
			]
		],
		[
			[
				68962,
				72867
			],
			[
				918,
				-137
			]
		],
		[
			[
				69880,
				72730
			],
			[
				-132,
				123
			],
			[
				13,
				378
			],
			[
				-96,
				490
			],
			[
				-88,
				53
			],
			[
				17,
				171
			],
			[
				79,
				50
			]
		],
		[
			[
				69673,
				73995
			],
			[
				11,
				120
			],
			[
				-94,
				46
			],
			[
				68,
				100
			],
			[
				-75,
				76
			],
			[
				-79,
				359
			],
			[
				83,
				228
			],
			[
				69,
				-42
			],
			[
				43,
				230
			]
		],
		[
			[
				69699,
				75112
			],
			[
				-709,
				105
			]
		],
		[
			[
				74524,
				71566
			],
			[
				193,
				-41
			],
			[
				-21,
				-219
			],
			[
				510,
				-117
			],
			[
				-12,
				-138
			],
			[
				106,
				-24
			]
		],
		[
			[
				75300,
				71027
			],
			[
				31,
				214
			],
			[
				67,
				105
			],
			[
				-26,
				179
			]
		],
		[
			[
				75372,
				71525
			],
			[
				-20,
				219
			],
			[
				134,
				66
			],
			[
				87,
				117
			],
			[
				-44,
				135
			]
		],
		[
			[
				75529,
				72062
			],
			[
				-157,
				226
			],
			[
				-78,
				187
			],
			[
				37,
				277
			]
		],
		[
			[
				75331,
				72752
			],
			[
				-254,
				53
			],
			[
				-158,
				-302
			],
			[
				-285,
				60
			]
		],
		[
			[
				74634,
				72563
			],
			[
				-32,
				6
			],
			[
				-34,
				-334
			]
		],
		[
			[
				74568,
				72235
			],
			[
				-44,
				-669
			]
		],
		[
			[
				61596,
				58376
			],
			[
				3,
				337
			],
			[
				-97,
				2
			],
			[
				37,
				161
			]
		],
		[
			[
				61539,
				58876
			],
			[
				3,
				284
			],
			[
				-193,
				6
			],
			[
				4,
				336
			],
			[
				-192,
				5
			]
		],
		[
			[
				32110,
				48653
			],
			[
				233,
				47
			],
			[
				1623,
				376
			]
		],
		[
			[
				33966,
				49076
			],
			[
				15,
				144
			],
			[
				103,
				23
			],
			[
				-40,
				340
			],
			[
				79,
				148
			],
			[
				92,
				35
			],
			[
				70,
				153
			],
			[
				6,
				187
			]
		],
		[
			[
				34291,
				50106
			],
			[
				-88,
				128
			],
			[
				-36,
				167
			],
			[
				-125,
				157
			]
		],
		[
			[
				34042,
				50558
			],
			[
				-92,
				-207
			],
			[
				-113,
				-152
			],
			[
				-387,
				47
			],
			[
				-64,
				-279
			],
			[
				-100,
				-35
			],
			[
				-866,
				-203
			],
			[
				-34,
				49
			],
			[
				-360,
				-85
			]
		],
		[
			[
				76006,
				77774
			],
			[
				37,
				-180
			],
			[
				112,
				-134
			],
			[
				37,
				-332
			],
			[
				-36,
				-595
			],
			[
				35,
				-115
			]
		],
		[
			[
				76191,
				76418
			],
			[
				354,
				-111
			]
		],
		[
			[
				76958,
				77724
			],
			[
				-927,
				107
			]
		],
		[
			[
				64819,
				33972
			],
			[
				713,
				-62
			]
		],
		[
			[
				65532,
				33910
			],
			[
				55,
				1145
			]
		],
		[
			[
				65587,
				35055
			],
			[
				-479,
				67
			]
		],
		[
			[
				65108,
				35122
			],
			[
				-379,
				51
			]
		],
		[
			[
				64729,
				35173
			],
			[
				-57,
				-1185
			]
		],
		[
			[
				64672,
				33988
			],
			[
				147,
				-16
			]
		],
		[
			[
				63174,
				36970
			],
			[
				185,
				-17
			],
			[
				124,
				130
			],
			[
				59,
				-108
			],
			[
				161,
				-8
			],
			[
				175,
				-73
			],
			[
				111,
				160
			],
			[
				152,
				-14
			]
		],
		[
			[
				63398,
				38178
			],
			[
				-14,
				-553
			],
			[
				-192,
				15
			]
		],
		[
			[
				57169,
				54426
			],
			[
				9,
				1087
			]
		],
		[
			[
				57180,
				55736
			],
			[
				-689,
				13
			]
		],
		[
			[
				56375,
				55751
			],
			[
				-11,
				-1314
			]
		],
		[
			[
				63839,
				49004
			],
			[
				118,
				-202
			],
			[
				205,
				-7
			],
			[
				9,
				-162
			],
			[
				68,
				-103
			],
			[
				79,
				-301
			],
			[
				101,
				12
			],
			[
				70,
				-240
			],
			[
				65,
				-28
			],
			[
				186,
				237
			],
			[
				137,
				46
			]
		],
		[
			[
				64877,
				48256
			],
			[
				-80,
				137
			]
		],
		[
			[
				64797,
				48393
			],
			[
				-139,
				222
			],
			[
				-78,
				252
			],
			[
				-10,
				299
			],
			[
				103,
				170
			]
		],
		[
			[
				64673,
				49336
			],
			[
				-9,
				44
			]
		],
		[
			[
				64664,
				49380
			],
			[
				-27,
				302
			],
			[
				-88,
				225
			]
		],
		[
			[
				64549,
				49907
			],
			[
				-27,
				-270
			],
			[
				-100,
				29
			],
			[
				0,
				-185
			],
			[
				-321,
				30
			],
			[
				-36,
				120
			],
			[
				-211,
				39
			]
		],
		[
			[
				65732,
				54659
			],
			[
				104,
				-124
			],
			[
				24,
				-171
			],
			[
				111,
				-146
			],
			[
				228,
				-98
			]
		],
		[
			[
				66199,
				54120
			],
			[
				50,
				-2
			],
			[
				6,
				197
			],
			[
				170,
				440
			],
			[
				2,
				158
			],
			[
				120,
				104
			]
		],
		[
			[
				66547,
				55017
			],
			[
				-107,
				70
			],
			[
				4,
				113
			],
			[
				-155,
				131
			],
			[
				-86,
				266
			]
		],
		[
			[
				66203,
				55597
			],
			[
				-302,
				74
			]
		],
		[
			[
				65901,
				55671
			],
			[
				3,
				-247
			],
			[
				-204,
				-611
			],
			[
				32,
				-154
			]
		],
		[
			[
				64804,
				59129
			],
			[
				92,
				-374
			],
			[
				142,
				-206
			],
			[
				74,
				-243
			]
		],
		[
			[
				65112,
				58306
			],
			[
				109,
				-124
			],
			[
				70,
				-173
			],
			[
				24,
				-330
			],
			[
				-36,
				-166
			],
			[
				-116,
				-79
			],
			[
				-26,
				-281
			],
			[
				-121,
				13
			]
		],
		[
			[
				65126,
				56641
			],
			[
				336,
				-31
			]
		],
		[
			[
				65462,
				56610
			],
			[
				30,
				927
			]
		],
		[
			[
				65492,
				57537
			],
			[
				60,
				1500
			]
		],
		[
			[
				65552,
				59037
			],
			[
				-589,
				72
			]
		],
		[
			[
				64963,
				59109
			],
			[
				-159,
				20
			]
		],
		[
			[
				53067,
				35110
			],
			[
				13,
				-1578
			]
		],
		[
			[
				53080,
				33532
			],
			[
				84,
				159
			],
			[
				93,
				-49
			],
			[
				164,
				116
			]
		],
		[
			[
				53421,
				33758
			],
			[
				6,
				123
			],
			[
				186,
				103
			],
			[
				-39,
				146
			],
			[
				119,
				217
			],
			[
				10,
				107
			]
		],
		[
			[
				53703,
				34454
			],
			[
				-148,
				-4
			],
			[
				-12,
				949
			]
		],
		[
			[
				53543,
				35399
			],
			[
				-157,
				51
			]
		],
		[
			[
				53386,
				35450
			],
			[
				-321,
				-6
			],
			[
				2,
				-334
			]
		],
		[
			[
				92523,
				23682
			],
			[
				16,
				-324
			],
			[
				-55,
				-173
			],
			[
				-15,
				-443
			],
			[
				-44,
				-119
			],
			[
				-5,
				-248
			],
			[
				63,
				-131
			]
		],
		[
			[
				92483,
				22244
			],
			[
				198,
				-27
			],
			[
				243,
				57
			],
			[
				215,
				-33
			]
		],
		[
			[
				93139,
				22241
			],
			[
				-67,
				392
			],
			[
				-69,
				14
			],
			[
				41,
				233
			],
			[
				-31,
				233
			],
			[
				76,
				234
			],
			[
				126,
				211
			]
		],
		[
			[
				93215,
				23558
			],
			[
				-13,
				240
			]
		],
		[
			[
				93202,
				23798
			],
			[
				-222,
				85
			],
			[
				7,
				-201
			],
			[
				-332,
				241
			],
			[
				21,
				92
			],
			[
				-96,
				28
			],
			[
				-46,
				-82
			]
		],
		[
			[
				92534,
				23961
			],
			[
				-11,
				-279
			]
		],
		[
			[
				94337,
				21659
			],
			[
				36,
				-151
			],
			[
				63,
				234
			],
			[
				103,
				-138
			],
			[
				47,
				164
			],
			[
				63,
				-260
			]
		],
		[
			[
				94649,
				21508
			],
			[
				62,
				380
			],
			[
				-26,
				108
			],
			[
				94,
				273
			],
			[
				289,
				340
			],
			[
				13,
				268
			],
			[
				36,
				80
			]
		],
		[
			[
				95117,
				22957
			],
			[
				-67,
				21
			],
			[
				5,
				176
			],
			[
				-97,
				6
			],
			[
				-214,
				132
			],
			[
				40,
				-243
			],
			[
				-424,
				-347
			]
		],
		[
			[
				94360,
				22702
			],
			[
				11,
				-47
			]
		],
		[
			[
				40633,
				64019
			],
			[
				58,
				7
			],
			[
				17,
				-336
			],
			[
				381,
				56
			],
			[
				17,
				-335
			],
			[
				190,
				28
			],
			[
				17,
				-335
			],
			[
				191,
				27
			],
			[
				17,
				-343
			],
			[
				436,
				57
			]
		],
		[
			[
				41957,
				62845
			],
			[
				-35,
				794
			]
		],
		[
			[
				41847,
				65309
			],
			[
				-1,
				39
			]
		],
		[
			[
				41846,
				65348
			],
			[
				-1271,
				-171
			],
			[
				58,
				-1158
			]
		],
		[
			[
				34596,
				53690
			],
			[
				954,
				192
			],
			[
				11,
				32
			],
			[
				692,
				128
			]
		],
		[
			[
				37079,
				54189
			],
			[
				35,
				430
			],
			[
				-40,
				281
			],
			[
				-17,
				379
			],
			[
				16,
				977
			],
			[
				-63,
				406
			],
			[
				-146,
				87
			],
			[
				-43,
				104
			],
			[
				342,
				319
			],
			[
				185,
				538
			],
			[
				346,
				399
			]
		],
		[
			[
				37694,
				58109
			],
			[
				-76,
				138
			],
			[
				-112,
				-42
			],
			[
				-55,
				73
			],
			[
				-98,
				-96
			]
		],
		[
			[
				37353,
				58182
			],
			[
				7,
				-90
			],
			[
				-632,
				-112
			],
			[
				14,
				279
			],
			[
				-341,
				-63
			]
		],
		[
			[
				36401,
				58196
			],
			[
				4,
				-137
			]
		],
		[
			[
				34024,
				56604
			],
			[
				61,
				-901
			],
			[
				109,
				-1322
			],
			[
				49,
				-120
			],
			[
				258,
				-191
			],
			[
				95,
				-380
			]
		],
		[
			[
				82924,
				26744
			],
			[
				149,
				-68
			],
			[
				220,
				-42
			],
			[
				203,
				-91
			],
			[
				177,
				-18
			]
		],
		[
			[
				83673,
				26525
			],
			[
				96,
				878
			]
		],
		[
			[
				83769,
				27403
			],
			[
				-745,
				258
			]
		],
		[
			[
				83024,
				27661
			],
			[
				-100,
				-917
			]
		],
		[
			[
				89207,
				51580
			],
			[
				114,
				127
			],
			[
				223,
				120
			],
			[
				69,
				96
			],
			[
				112,
				19
			],
			[
				116,
				268
			]
		],
		[
			[
				89841,
				52210
			],
			[
				159,
				253
			],
			[
				69,
				33
			],
			[
				60,
				176
			],
			[
				-197,
				174
			],
			[
				-196,
				-188
			]
		],
		[
			[
				89736,
				52658
			],
			[
				-125,
				-171
			],
			[
				-85,
				-29
			],
			[
				-192,
				-401
			]
		],
		[
			[
				89334,
				52057
			],
			[
				-108,
				-319
			],
			[
				-19,
				-158
			]
		],
		[
			[
				44026,
				19394
			],
			[
				-110,
				-10
			]
		],
		[
			[
				43916,
				19384
			],
			[
				-563,
				-70
			],
			[
				-1057,
				-148
			]
		],
		[
			[
				42296,
				19166
			],
			[
				50,
				-990
			]
		],
		[
			[
				44086,
				55211
			],
			[
				835,
				79
			]
		],
		[
			[
				45998,
				55381
			],
			[
				-32,
				576
			],
			[
				-42,
				1344
			]
		],
		[
			[
				45924,
				57301
			],
			[
				-233,
				-20
			]
		],
		[
			[
				45691,
				57281
			],
			[
				-957,
				-93
			]
		],
		[
			[
				44009,
				57107
			],
			[
				77,
				-1896
			]
		],
		[
			[
				17714,
				13815
			],
			[
				211,
				0
			]
		],
		[
			[
				18629,
				13797
			],
			[
				-176,
				1167
			],
			[
				283,
				124
			],
			[
				-47,
				318
			],
			[
				156,
				67
			],
			[
				-235,
				1607
			],
			[
				-25,
				-11
			],
			[
				-48,
				323
			]
		],
		[
			[
				18537,
				17392
			],
			[
				-779,
				-329
			]
		],
		[
			[
				17758,
				17063
			],
			[
				-182,
				-80
			],
			[
				41,
				-271
			]
		],
		[
			[
				21623,
				14717
			],
			[
				854,
				341
			]
		],
		[
			[
				22477,
				15058
			],
			[
				37,
				321
			],
			[
				80,
				269
			]
		],
		[
			[
				22474,
				17905
			],
			[
				-130,
				452
			],
			[
				-111,
				197
			]
		],
		[
			[
				22233,
				18554
			],
			[
				-741,
				-293
			]
		],
		[
			[
				20806,
				14930
			],
			[
				65,
				-523
			]
		],
		[
			[
				88019,
				37001
			],
			[
				544,
				507
			]
		],
		[
			[
				88563,
				37508
			],
			[
				-95,
				137
			],
			[
				39,
				276
			],
			[
				-58,
				357
			],
			[
				49,
				299
			],
			[
				-79,
				472
			],
			[
				-96,
				184
			]
		],
		[
			[
				88323,
				39233
			],
			[
				-163,
				60
			]
		],
		[
			[
				88160,
				39293
			],
			[
				-10,
				4
			]
		],
		[
			[
				88150,
				39297
			],
			[
				-86,
				-182
			],
			[
				-209,
				-280
			],
			[
				-47,
				-169
			],
			[
				-152,
				-82
			],
			[
				-150,
				-356
			],
			[
				-219,
				31
			],
			[
				-122,
				-181
			]
		],
		[
			[
				87165,
				38078
			],
			[
				159,
				-332
			],
			[
				64,
				-49
			]
		],
		[
			[
				66229,
				61370
			],
			[
				162,
				-131
			],
			[
				120,
				-7
			]
		],
		[
			[
				67035,
				61192
			],
			[
				39,
				1552
			]
		],
		[
			[
				67074,
				62744
			],
			[
				-280,
				35
			]
		],
		[
			[
				66794,
				62779
			],
			[
				-528,
				57
			]
		],
		[
			[
				66266,
				62836
			],
			[
				-37,
				-1466
			]
		],
		[
			[
				76747,
				57028
			],
			[
				30,
				-24
			],
			[
				254,
				248
			],
			[
				103,
				139
			],
			[
				101,
				-63
			],
			[
				199,
				100
			]
		],
		[
			[
				77434,
				57428
			],
			[
				108,
				251
			],
			[
				7,
				432
			],
			[
				37,
				-14
			]
		],
		[
			[
				77586,
				58097
			],
			[
				6,
				72
			]
		],
		[
			[
				77592,
				58169
			],
			[
				-63,
				164
			],
			[
				-69,
				-14
			],
			[
				-49,
				115
			],
			[
				-133,
				135
			],
			[
				-58,
				183
			],
			[
				-317,
				67
			]
		],
		[
			[
				76903,
				58819
			],
			[
				-36,
				-464
			],
			[
				-81,
				-64
			],
			[
				-210,
				-636
			]
		],
		[
			[
				76576,
				57655
			],
			[
				224,
				-380
			],
			[
				-53,
				-247
			]
		],
		[
			[
				45924,
				57301
			],
			[
				725,
				63
			]
		],
		[
			[
				46649,
				57364
			],
			[
				-49,
				1706
			]
		],
		[
			[
				45637,
				58983
			],
			[
				54,
				-1702
			]
		],
		[
			[
				46649,
				57364
			],
			[
				965,
				73
			]
		],
		[
			[
				47614,
				57437
			],
			[
				6,
				0
			],
			[
				-43,
				1712
			]
		],
		[
			[
				43649,
				81014
			],
			[
				1535,
				149
			]
		],
		[
			[
				45612,
				83744
			],
			[
				-21,
				780
			],
			[
				-34,
				309
			],
			[
				-66,
				33
			],
			[
				-9,
				279
			],
			[
				-98,
				42
			]
		],
		[
			[
				45384,
				85187
			],
			[
				-153,
				-158
			],
			[
				-18,
				-110
			],
			[
				-234,
				-254
			],
			[
				-86,
				-352
			],
			[
				-176,
				-86
			],
			[
				-38,
				-147
			],
			[
				-167,
				-101
			],
			[
				37,
				-121
			],
			[
				-41,
				-237
			],
			[
				-53,
				218
			],
			[
				-38,
				-95
			],
			[
				3,
				-229
			],
			[
				-97,
				-42
			],
			[
				-82,
				-362
			],
			[
				-113,
				-103
			],
			[
				-113,
				51
			],
			[
				-23,
				-127
			],
			[
				-59,
				80
			],
			[
				-255,
				19
			],
			[
				-102,
				-86
			]
		],
		[
			[
				43576,
				82945
			],
			[
				73,
				-1931
			]
		],
		[
			[
				66932,
				55221
			],
			[
				201,
				70
			],
			[
				125,
				-105
			],
			[
				174,
				-67
			]
		],
		[
			[
				67432,
				55119
			],
			[
				7,
				27
			]
		],
		[
			[
				67468,
				55815
			],
			[
				-449,
				58
			],
			[
				-102,
				-36
			]
		],
		[
			[
				66917,
				55837
			],
			[
				-99,
				-73
			],
			[
				64,
				-162
			],
			[
				50,
				-381
			]
		],
		[
			[
				74436,
				45637
			],
			[
				48,
				277
			],
			[
				74,
				92
			],
			[
				-8,
				173
			],
			[
				52,
				162
			],
			[
				-27,
				212
			],
			[
				90,
				157
			]
		],
		[
			[
				74473,
				46807
			],
			[
				-145,
				-9
			]
		],
		[
			[
				74328,
				46798
			],
			[
				9,
				-303
			],
			[
				-97,
				-738
			]
		],
		[
			[
				74240,
				45757
			],
			[
				112,
				-113
			],
			[
				84,
				-7
			]
		],
		[
			[
				59407,
				79261
			],
			[
				39,
				-49
			],
			[
				114,
				79
			],
			[
				109,
				-96
			],
			[
				846,
				-24
			]
		],
		[
			[
				60254,
				80927
			],
			[
				2,
				79
			]
		],
		[
			[
				59084,
				81063
			],
			[
				80,
				-150
			],
			[
				-65,
				-509
			],
			[
				90,
				-102
			],
			[
				-8,
				-157
			],
			[
				116,
				-213
			],
			[
				1,
				-180
			],
			[
				120,
				-323
			],
			[
				-11,
				-168
			]
		],
		[
			[
				91862,
				25913
			],
			[
				148,
				-55
			]
		],
		[
			[
				92010,
				25858
			],
			[
				753,
				-273
			]
		],
		[
			[
				92763,
				25585
			],
			[
				281,
				-101
			]
		],
		[
			[
				93044,
				25484
			],
			[
				44,
				168
			],
			[
				73,
				14
			],
			[
				29,
				174
			],
			[
				-54,
				83
			],
			[
				34,
				253
			],
			[
				50,
				57
			],
			[
				-55,
				159
			],
			[
				34,
				137
			],
			[
				-26,
				197
			],
			[
				30,
				189
			]
		],
		[
			[
				93203,
				26915
			],
			[
				-36,
				173
			],
			[
				-102,
				-418
			],
			[
				-166,
				123
			],
			[
				-24,
				-94
			],
			[
				-326,
				252
			],
			[
				-20,
				-179
			],
			[
				-288,
				-1
			],
			[
				-40,
				-209
			],
			[
				-164,
				11
			]
		],
		[
			[
				92037,
				26573
			],
			[
				-26,
				-422
			],
			[
				-69,
				-3
			],
			[
				57,
				-146
			],
			[
				-118,
				55
			],
			[
				-19,
				-144
			]
		],
		[
			[
				72270,
				20166
			],
			[
				131,
				1892
			]
		],
		[
			[
				72401,
				22058
			],
			[
				-362,
				65
			],
			[
				-53,
				85
			],
			[
				-85,
				-224
			],
			[
				-79,
				12
			],
			[
				-7,
				-119
			]
		],
		[
			[
				71815,
				21877
			],
			[
				100,
				2
			],
			[
				174,
				-157
			],
			[
				-17,
				-107
			],
			[
				-190,
				-20
			],
			[
				-84,
				-138
			],
			[
				-72,
				-340
			],
			[
				54,
				-242
			],
			[
				87,
				-103
			],
			[
				94,
				-315
			],
			[
				-121,
				-98
			],
			[
				337,
				-17
			],
			[
				30,
				-168
			],
			[
				63,
				-8
			]
		],
		[
			[
				55747,
				9743
			],
			[
				246,
				-4
			],
			[
				-7,
				-1454
			],
			[
				141,
				115
			],
			[
				157,
				-61
			],
			[
				145,
				165
			],
			[
				44,
				112
			],
			[
				82,
				653
			],
			[
				34,
				78
			],
			[
				3,
				376
			],
			[
				47,
				-1
			],
			[
				56,
				437
			],
			[
				-26,
				198
			],
			[
				18,
				202
			],
			[
				76,
				138
			],
			[
				154,
				141
			],
			[
				161,
				3
			]
		],
		[
			[
				57078,
				10841
			],
			[
				15,
				1261
			]
		],
		[
			[
				57093,
				12102
			],
			[
				-1173,
				31
			],
			[
				-5,
				-659
			],
			[
				-195,
				2
			]
		],
		[
			[
				55720,
				11476
			],
			[
				-3,
				-654
			],
			[
				377,
				-5
			],
			[
				-7,
				-784
			],
			[
				-69,
				98
			],
			[
				-144,
				53
			],
			[
				-98,
				-85
			],
			[
				-29,
				-356
			]
		],
		[
			[
				81740,
				51069
			],
			[
				174,
				-167
			],
			[
				102,
				-184
			]
		],
		[
			[
				82016,
				50718
			],
			[
				164,
				-250
			],
			[
				124,
				67
			]
		],
		[
			[
				82304,
				50535
			],
			[
				18,
				122
			],
			[
				148,
				251
			],
			[
				65,
				450
			]
		],
		[
			[
				82535,
				51358
			],
			[
				-171,
				185
			],
			[
				-90,
				248
			],
			[
				-106,
				101
			],
			[
				-76,
				-46
			],
			[
				-58,
				171
			],
			[
				-89,
				13
			]
		],
		[
			[
				81945,
				52030
			],
			[
				-22,
				-246
			],
			[
				-79,
				-136
			]
		],
		[
			[
				81915,
				51454
			],
			[
				-18,
				-88
			],
			[
				-156,
				-171
			],
			[
				-1,
				-126
			]
		],
		[
			[
				82304,
				50535
			],
			[
				94,
				-175
			],
			[
				89,
				-40
			],
			[
				113,
				-189
			]
		],
		[
			[
				82600,
				50131
			],
			[
				25,
				47
			],
			[
				373,
				205
			],
			[
				37,
				64
			]
		],
		[
			[
				83068,
				50765
			],
			[
				-167,
				395
			],
			[
				-93,
				-112
			],
			[
				-135,
				87
			],
			[
				-55,
				162
			]
		],
		[
			[
				82618,
				51297
			],
			[
				-83,
				61
			]
		],
		[
			[
				82730,
				50717
			],
			[
				164,
				155
			],
			[
				94,
				-344
			],
			[
				-58,
				-100
			],
			[
				-124,
				-17
			],
			[
				-73,
				128
			]
		],
		[
			[
				80560,
				45636
			],
			[
				425,
				-747
			],
			[
				153,
				-141
			]
		],
		[
			[
				81199,
				46208
			],
			[
				-170,
				51
			],
			[
				-71,
				-106
			],
			[
				-146,
				238
			]
		],
		[
			[
				80812,
				46391
			],
			[
				-103,
				-254
			],
			[
				-80,
				-33
			],
			[
				-21,
				-118
			],
			[
				-110,
				-137
			]
		],
		[
			[
				80498,
				45849
			],
			[
				62,
				-213
			]
		],
		[
			[
				38503,
				29257
			],
			[
				98,
				16
			]
		],
		[
			[
				40396,
				29562
			],
			[
				-147,
				2724
			],
			[
				-28,
				686
			]
		],
		[
			[
				39545,
				33547
			],
			[
				-79,
				-12
			],
			[
				-60,
				201
			],
			[
				-41,
				327
			],
			[
				-247,
				-38
			],
			[
				-140,
				-84
			],
			[
				16,
				-280
			],
			[
				63,
				12
			],
			[
				44,
				-198
			],
			[
				-842,
				-160
			]
		],
		[
			[
				38259,
				33315
			],
			[
				-4,
				-1
			]
		],
		[
			[
				83769,
				27403
			],
			[
				145,
				-50
			],
			[
				-32,
				378
			],
			[
				76,
				-15
			],
			[
				12,
				189
			]
		],
		[
			[
				83970,
				27905
			],
			[
				15,
				162
			],
			[
				-53,
				171
			],
			[
				19,
				163
			]
		],
		[
			[
				83951,
				28401
			],
			[
				-194,
				34
			],
			[
				-623,
				212
			]
		],
		[
			[
				83134,
				28647
			],
			[
				-92,
				-836
			]
		],
		[
			[
				83042,
				27811
			],
			[
				-18,
				-150
			]
		],
		[
			[
				73928,
				51764
			],
			[
				250,
				1
			],
			[
				84,
				-79
			]
		],
		[
			[
				74262,
				51686
			],
			[
				-1,
				314
			],
			[
				27,
				151
			],
			[
				241,
				211
			],
			[
				46,
				225
			],
			[
				-9,
				241
			]
		],
		[
			[
				74566,
				52828
			],
			[
				-284,
				532
			]
		],
		[
			[
				74015,
				53142
			],
			[
				-43,
				-267
			],
			[
				-203,
				-168
			]
		],
		[
			[
				73769,
				52707
			],
			[
				41,
				-295
			],
			[
				92,
				-132
			]
		],
		[
			[
				77369,
				49388
			],
			[
				52,
				-154
			],
			[
				218,
				75
			],
			[
				181,
				158
			],
			[
				226,
				90
			]
		],
		[
			[
				78046,
				49557
			],
			[
				-92,
				129
			],
			[
				54,
				99
			],
			[
				40,
				391
			]
		],
		[
			[
				77374,
				49795
			],
			[
				58,
				-222
			],
			[
				-63,
				-185
			]
		],
		[
			[
				77149,
				70598
			],
			[
				7,
				-34
			]
		],
		[
			[
				77156,
				70564
			],
			[
				393,
				-716
			],
			[
				122,
				-23
			]
		],
		[
			[
				77671,
				69825
			],
			[
				-16,
				223
			],
			[
				27,
				394
			],
			[
				-46,
				113
			],
			[
				-74,
				-13
			],
			[
				0,
				255
			],
			[
				-97,
				-62
			]
		],
		[
			[
				77465,
				70735
			],
			[
				-171,
				-107
			],
			[
				-159,
				32
			]
		],
		[
			[
				77135,
				70660
			],
			[
				14,
				-62
			]
		],
		[
			[
				72104,
				54479
			],
			[
				64,
				-504
			]
		],
		[
			[
				72168,
				53975
			],
			[
				71,
				18
			],
			[
				4,
				-443
			]
		],
		[
			[
				72243,
				53550
			],
			[
				268,
				24
			],
			[
				279,
				-134
			]
		],
		[
			[
				72790,
				53440
			],
			[
				-50,
				131
			],
			[
				44,
				103
			],
			[
				95,
				1024
			]
		],
		[
			[
				72488,
				55230
			],
			[
				-183,
				-289
			],
			[
				19,
				-98
			],
			[
				-53,
				-233
			],
			[
				-51,
				104
			],
			[
				-116,
				-235
			]
		],
		[
			[
				58113,
				53235
			],
			[
				-1,
				225
			]
		],
		[
			[
				58111,
				54356
			],
			[
				-942,
				-29
			]
		],
		[
			[
				73405,
				46416
			],
			[
				-1,
				72
			]
		],
		[
			[
				73404,
				46488
			],
			[
				-111,
				83
			]
		],
		[
			[
				73293,
				46571
			],
			[
				-414,
				83
			]
		],
		[
			[
				72879,
				46654
			],
			[
				-62,
				-1085
			]
		],
		[
			[
				73293,
				46571
			],
			[
				59,
				855
			]
		],
		[
			[
				73352,
				47426
			],
			[
				-87,
				-142
			],
			[
				-150,
				12
			]
		],
		[
			[
				73115,
				47296
			],
			[
				-171,
				59
			],
			[
				-30,
				162
			],
			[
				75,
				385
			]
		],
		[
			[
				72989,
				47902
			],
			[
				-122,
				-56
			],
			[
				-126,
				26
			]
		],
		[
			[
				72741,
				47872
			],
			[
				-20,
				-278
			],
			[
				-207,
				-186
			],
			[
				-20,
				-302
			]
		],
		[
			[
				72494,
				47106
			],
			[
				123,
				-58
			],
			[
				103,
				-360
			],
			[
				159,
				-34
			]
		],
		[
			[
				61111,
				39483
			],
			[
				390,
				-31
			]
		],
		[
			[
				61501,
				39452
			],
			[
				383,
				-26
			]
		],
		[
			[
				61907,
				40435
			],
			[
				-771,
				61
			]
		],
		[
			[
				61467,
				38116
			],
			[
				34,
				1336
			]
		],
		[
			[
				52924,
				29355
			],
			[
				386,
				6
			]
		],
		[
			[
				53310,
				29361
			],
			[
				699,
				4
			]
		],
		[
			[
				54005,
				30705
			],
			[
				-235,
				-3
			]
		],
		[
			[
				52914,
				30692
			],
			[
				10,
				-1337
			]
		],
		[
			[
				75235,
				58885
			],
			[
				110,
				56
			]
		],
		[
			[
				75345,
				58941
			],
			[
				270,
				821
			],
			[
				34,
				257
			],
			[
				-93,
				65
			],
			[
				-64,
				142
			]
		],
		[
			[
				75492,
				60226
			],
			[
				-32,
				81
			],
			[
				-272,
				50
			],
			[
				-58,
				116
			]
		],
		[
			[
				75130,
				60473
			],
			[
				-161,
				-248
			],
			[
				-149,
				-112
			]
		],
		[
			[
				74820,
				60113
			],
			[
				72,
				-397
			],
			[
				125,
				-309
			],
			[
				155,
				-510
			]
		],
		[
			[
				39385,
				36517
			],
			[
				-143,
				2525
			]
		],
		[
			[
				39242,
				39042
			],
			[
				-746,
				-123
			],
			[
				-774,
				-142
			]
		],
		[
			[
				37722,
				38777
			],
			[
				-217,
				-46
			]
		],
		[
			[
				37505,
				38731
			],
			[
				94,
				-1518
			],
			[
				421,
				78
			],
			[
				75,
				-1332
			],
			[
				164,
				-2644
			]
		],
		[
			[
				43916,
				19384
			],
			[
				-55,
				1276
			]
		],
		[
			[
				43861,
				20660
			],
			[
				-1628,
				-221
			]
		],
		[
			[
				42233,
				20439
			],
			[
				63,
				-1273
			]
		],
		[
			[
				55114,
				55765
			],
			[
				467,
				-3
			]
		],
		[
			[
				55581,
				55762
			],
			[
				203,
				-2
			]
		],
		[
			[
				55747,
				57311
			],
			[
				-672,
				4
			]
		],
		[
			[
				80955,
				47930
			],
			[
				301,
				12
			],
			[
				405,
				-850
			]
		],
		[
			[
				82555,
				47633
			],
			[
				-47,
				291
			],
			[
				-77,
				181
			]
		],
		[
			[
				82431,
				48105
			],
			[
				-135,
				295
			],
			[
				-138,
				552
			],
			[
				19,
				97
			],
			[
				-62,
				166
			]
		],
		[
			[
				82115,
				49215
			],
			[
				-155,
				41
			],
			[
				-25,
				-88
			],
			[
				-84,
				153
			],
			[
				-385,
				-79
			]
		],
		[
			[
				81466,
				49242
			],
			[
				-20,
				-117
			],
			[
				-171,
				-239
			],
			[
				-106,
				-98
			]
		],
		[
			[
				81169,
				48788
			],
			[
				101,
				-13
			],
			[
				-137,
				-291
			],
			[
				57,
				-157
			],
			[
				-117,
				-149
			],
			[
				37,
				-134
			],
			[
				-104,
				54
			],
			[
				-51,
				-168
			]
		],
		[
			[
				77943,
				48147
			],
			[
				46,
				-76
			],
			[
				-11,
				-200
			],
			[
				-54,
				-100
			],
			[
				-17,
				-296
			]
		],
		[
			[
				77907,
				47475
			],
			[
				151,
				7
			]
		],
		[
			[
				78058,
				47482
			],
			[
				315,
				334
			],
			[
				42,
				-113
			],
			[
				55,
				342
			],
			[
				64,
				27
			]
		],
		[
			[
				78534,
				48072
			],
			[
				-9,
				331
			],
			[
				109,
				241
			],
			[
				84,
				96
			],
			[
				-163,
				441
			]
		],
		[
			[
				78385,
				49570
			],
			[
				-5,
				-106
			],
			[
				-102,
				-46
			],
			[
				-54,
				-163
			]
		],
		[
			[
				78224,
				49255
			],
			[
				43,
				-164
			],
			[
				-95,
				-40
			],
			[
				-81,
				-252
			],
			[
				-133,
				-162
			],
			[
				-58,
				-161
			],
			[
				56,
				-37
			],
			[
				-13,
				-292
			]
		],
		[
			[
				59602,
				22359
			],
			[
				559,
				-26
			],
			[
				-6,
				-330
			],
			[
				584,
				-27
			],
			[
				7,
				328
			]
		],
		[
			[
				60746,
				22304
			],
			[
				36,
				1643
			]
		],
		[
			[
				59837,
				24008
			],
			[
				12,
				-331
			]
		],
		[
			[
				59849,
				23677
			],
			[
				142,
				-402
			],
			[
				-8,
				-213
			],
			[
				-198,
				-424
			],
			[
				-173,
				-17
			],
			[
				-10,
				-262
			]
		],
		[
			[
				22639,
				90272
			],
			[
				-131,
				72
			],
			[
				-216,
				-438
			]
		],
		[
			[
				22292,
				89906
			],
			[
				78,
				-135
			],
			[
				221,
				220
			],
			[
				48,
				281
			]
		],
		[
			[
				11859,
				46070
			],
			[
				140,
				-143
			],
			[
				207,
				0
			],
			[
				258,
				-60
			],
			[
				208,
				78
			],
			[
				102,
				-19
			]
		],
		[
			[
				12774,
				45926
			],
			[
				626,
				-657
			],
			[
				189,
				80
			],
			[
				24,
				-163
			],
			[
				133,
				-76
			],
			[
				37,
				-245
			],
			[
				587,
				-613
			]
		],
		[
			[
				14416,
				44426
			],
			[
				76,
				132
			],
			[
				43,
				-72
			],
			[
				124,
				64
			],
			[
				74,
				228
			],
			[
				19,
				401
			]
		],
		[
			[
				14752,
				45179
			],
			[
				-617,
				658
			],
			[
				-59,
				131
			],
			[
				-52,
				394
			],
			[
				-136,
				185
			],
			[
				-47,
				-79
			],
			[
				-131,
				45
			],
			[
				-99,
				-66
			],
			[
				-9,
				180
			],
			[
				-60,
				110
			],
			[
				-55,
				-54
			],
			[
				-77,
				156
			],
			[
				-123,
				2
			],
			[
				-100,
				115
			],
			[
				-210,
				394
			],
			[
				-100,
				-85
			],
			[
				-270,
				57
			],
			[
				-82,
				-80
			],
			[
				-289,
				94
			],
			[
				-233,
				-165
			],
			[
				16,
				-94
			],
			[
				-119,
				-263
			],
			[
				38,
				-176
			],
			[
				-79,
				-568
			]
		],
		[
			[
				12774,
				45926
			],
			[
				-38,
				-158
			],
			[
				-132,
				-230
			],
			[
				8,
				-115
			],
			[
				-79,
				-471
			],
			[
				9,
				-168
			],
			[
				-83,
				-794
			]
		],
		[
			[
				12459,
				43990
			],
			[
				0,
				-1
			]
		],
		[
			[
				37742,
				49381
			],
			[
				852,
				173
			],
			[
				440,
				74
			]
		],
		[
			[
				38959,
				50943
			],
			[
				-51,
				-163
			],
			[
				-73,
				5
			],
			[
				-27,
				-215
			],
			[
				-80,
				-22
			],
			[
				-180,
				389
			],
			[
				-48,
				-161
			],
			[
				-179,
				158
			],
			[
				-102,
				-39
			]
		],
		[
			[
				38219,
				50895
			],
			[
				-91,
				-245
			],
			[
				-48,
				5
			],
			[
				-63,
				-232
			],
			[
				-93,
				-549
			],
			[
				-182,
				-493
			]
		],
		[
			[
				67024,
				43170
			],
			[
				44,
				1011
			]
		],
		[
			[
				67068,
				44181
			],
			[
				-12,
				204
			],
			[
				-93,
				10
			],
			[
				18,
				335
			]
		],
		[
			[
				66981,
				44730
			],
			[
				-365,
				43
			]
		],
		[
			[
				66616,
				44773
			],
			[
				-193,
				21
			],
			[
				-29,
				-561
			],
			[
				-130,
				-31
			]
		],
		[
			[
				66264,
				44202
			],
			[
				-17,
				-400
			]
		],
		[
			[
				68330,
				41657
			],
			[
				40,
				667
			],
			[
				-22,
				4
			],
			[
				74,
				1327
			]
		],
		[
			[
				68422,
				43655
			],
			[
				-889,
				128
			]
		],
		[
			[
				67533,
				43783
			],
			[
				-69,
				-1549
			]
		],
		[
			[
				70450,
				42042
			],
			[
				-47,
				-837
			]
		],
		[
			[
				70403,
				41205
			],
			[
				535,
				-90
			]
		],
		[
			[
				70938,
				41115
			],
			[
				112,
				-19
			],
			[
				10,
				167
			],
			[
				115,
				36
			]
		],
		[
			[
				71175,
				41299
			],
			[
				37,
				606
			]
		],
		[
			[
				70457,
				42179
			],
			[
				-7,
				-137
			]
		],
		[
			[
				69748,
				46196
			],
			[
				319,
				-36
			]
		],
		[
			[
				70067,
				46160
			],
			[
				635,
				-96
			]
		],
		[
			[
				70702,
				46064
			],
			[
				40,
				666
			]
		],
		[
			[
				70742,
				46730
			],
			[
				18,
				337
			]
		],
		[
			[
				70381,
				47132
			],
			[
				-334,
				55
			]
		],
		[
			[
				69803,
				47211
			],
			[
				-55,
				-1015
			]
		],
		[
			[
				69750,
				41567
			],
			[
				31,
				583
			]
		],
		[
			[
				69781,
				42150
			],
			[
				58,
				1005
			]
		],
		[
			[
				69839,
				43155
			],
			[
				-556,
				90
			]
		],
		[
			[
				69283,
				43245
			],
			[
				-40,
				-280
			],
			[
				27,
				-396
			]
		],
		[
			[
				49498,
				49071
			],
			[
				945,
				46
			]
		],
		[
			[
				50442,
				49793
			],
			[
				-14,
				1007
			]
		],
		[
			[
				55597,
				48542
			],
			[
				-1,
				-721
			]
		],
		[
			[
				55596,
				47821
			],
			[
				139,
				-42
			],
			[
				135,
				135
			],
			[
				264,
				-51
			]
		],
		[
			[
				56134,
				47863
			],
			[
				3,
				307
			],
			[
				38,
				-67
			],
			[
				136,
				105
			],
			[
				50,
				-110
			]
		],
		[
			[
				55599,
				49047
			],
			[
				-2,
				-505
			]
		],
		[
			[
				72881,
				52444
			],
			[
				28,
				-199
			],
			[
				89,
				-9
			]
		],
		[
			[
				72998,
				52236
			],
			[
				8,
				145
			],
			[
				203,
				228
			],
			[
				176,
				405
			],
			[
				-24,
				107
			],
			[
				110,
				102
			]
		],
		[
			[
				73471,
				53223
			],
			[
				-42,
				102
			],
			[
				-243,
				275
			]
		],
		[
			[
				73186,
				53600
			],
			[
				-189,
				-25
			],
			[
				-109,
				-202
			]
		],
		[
			[
				72888,
				53373
			],
			[
				9,
				-334
			],
			[
				-57,
				-121
			],
			[
				41,
				-474
			]
		],
		[
			[
				76442,
				47975
			],
			[
				66,
				-72
			],
			[
				145,
				267
			]
		],
		[
			[
				76653,
				48170
			],
			[
				102,
				404
			],
			[
				103,
				44
			]
		],
		[
			[
				76858,
				48618
			],
			[
				73,
				49
			],
			[
				-17,
				173
			],
			[
				-65,
				80
			]
		],
		[
			[
				76849,
				48920
			],
			[
				-183,
				290
			],
			[
				-88,
				89
			]
		],
		[
			[
				76578,
				49299
			],
			[
				-117,
				-39
			]
		],
		[
			[
				76461,
				49260
			],
			[
				53,
				-125
			],
			[
				-127,
				-29
			],
			[
				8,
				-98
			],
			[
				-175,
				-77
			],
			[
				46,
				-88
			],
			[
				-80,
				-53
			]
		],
		[
			[
				60962,
				76692
			],
			[
				29,
				255
			],
			[
				95,
				235
			],
			[
				51,
				-13
			],
			[
				820,
				-388
			]
		],
		[
			[
				61957,
				76781
			],
			[
				68,
				36
			],
			[
				28,
				237
			],
			[
				128,
				145
			],
			[
				-3,
				114
			]
		],
		[
			[
				62178,
				77313
			],
			[
				-142,
				132
			],
			[
				-62,
				-112
			],
			[
				-74,
				52
			],
			[
				15,
				729
			],
			[
				64,
				645
			],
			[
				-138,
				-2
			]
		],
		[
			[
				60810,
				79109
			],
			[
				-38,
				-1388
			],
			[
				-98,
				-134
			],
			[
				-2,
				-110
			],
			[
				-196,
				-101
			]
		],
		[
			[
				60465,
				75985
			],
			[
				669,
				-41
			],
			[
				-5,
				-336
			],
			[
				485,
				-29
			]
		],
		[
			[
				61614,
				75579
			],
			[
				37,
				158
			],
			[
				-48,
				209
			],
			[
				55,
				276
			],
			[
				-71,
				36
			],
			[
				150,
				109
			],
			[
				-23,
				70
			],
			[
				88,
				131
			],
			[
				-1,
				153
			],
			[
				156,
				60
			]
		],
		[
			[
				56079,
				21991
			],
			[
				773,
				-18
			]
		],
		[
			[
				56852,
				21973
			],
			[
				581,
				-21
			]
		],
		[
			[
				57433,
				21952
			],
			[
				89,
				183
			],
			[
				24,
				409
			],
			[
				87,
				228
			]
		],
		[
			[
				57470,
				23829
			],
			[
				-193,
				10
			],
			[
				-2,
				-168
			],
			[
				-596,
				18
			]
		],
		[
			[
				64988,
				52746
			],
			[
				6,
				170
			]
		],
		[
			[
				64994,
				52916
			],
			[
				23,
				215
			],
			[
				19,
				884
			],
			[
				-127,
				3
			]
		],
		[
			[
				64909,
				54018
			],
			[
				-584,
				49
			]
		],
		[
			[
				62253,
				48894
			],
			[
				-14,
				-1373
			]
		],
		[
			[
				62616,
				47168
			],
			[
				255,
				-11
			]
		],
		[
			[
				62871,
				47157
			],
			[
				10,
				567
			]
		],
		[
			[
				62647,
				48837
			],
			[
				-135,
				12
			],
			[
				-81,
				133
			],
			[
				-166,
				-91
			]
		],
		[
			[
				62265,
				48891
			],
			[
				-12,
				3
			]
		],
		[
			[
				35844,
				62206
			],
			[
				473,
				89
			],
			[
				19,
				-320
			],
			[
				-170,
				-30
			],
			[
				21,
				-333
			]
		],
		[
			[
				36187,
				61612
			],
			[
				956,
				166
			]
		],
		[
			[
				37143,
				61778
			],
			[
				765,
				130
			]
		],
		[
			[
				37908,
				61908
			],
			[
				-95,
				1667
			],
			[
				-41,
				-5
			],
			[
				-58,
				998
			]
		],
		[
			[
				37714,
				64568
			],
			[
				-19,
				332
			],
			[
				-1114,
				-193
			]
		],
		[
			[
				35727,
				63853
			],
			[
				19,
				-313
			],
			[
				-95,
				-16
			],
			[
				31,
				-335
			],
			[
				66,
				-1000
			],
			[
				96,
				17
			]
		],
		[
			[
				37051,
				42812
			],
			[
				63,
				55
			],
			[
				243,
				42
			],
			[
				120,
				292
			],
			[
				66,
				16
			],
			[
				72,
				162
			],
			[
				23,
				319
			],
			[
				96,
				175
			],
			[
				125,
				-35
			]
		],
		[
			[
				37989,
				44381
			],
			[
				14,
				106
			],
			[
				-179,
				284
			],
			[
				-98,
				55
			],
			[
				-112,
				276
			],
			[
				-189,
				-104
			]
		],
		[
			[
				37425,
				44998
			],
			[
				-121,
				-22
			]
		],
		[
			[
				37304,
				44976
			],
			[
				-53,
				-361
			],
			[
				7,
				-232
			],
			[
				104,
				-68
			],
			[
				-8,
				-185
			],
			[
				60,
				-154
			],
			[
				-82,
				-90
			],
			[
				-168,
				-402
			],
			[
				-71,
				-70
			],
			[
				-54,
				-372
			],
			[
				12,
				-230
			]
		],
		[
			[
				36228,
				60940
			],
			[
				-41,
				672
			]
		],
		[
			[
				35844,
				62206
			],
			[
				-30,
				-133
			],
			[
				-458,
				-99
			],
			[
				-72,
				126
			],
			[
				-548,
				-108
			],
			[
				-54,
				-348
			]
		],
		[
			[
				85787,
				29038
			],
			[
				317,
				-132
			]
		],
		[
			[
				86104,
				28906
			],
			[
				95,
				641
			],
			[
				33,
				346
			],
			[
				116,
				-36
			]
		],
		[
			[
				86348,
				29857
			],
			[
				-14,
				198
			],
			[
				-152,
				-2
			],
			[
				-23,
				-172
			],
			[
				-369,
				154
			],
			[
				4,
				33
			]
		],
		[
			[
				89735,
				24295
			],
			[
				422,
				-274
			],
			[
				73,
				237
			],
			[
				65,
				73
			],
			[
				18,
				214
			],
			[
				118,
				59
			],
			[
				21,
				-177
			],
			[
				132,
				-210
			],
			[
				74,
				-38
			]
		],
		[
			[
				90658,
				24179
			],
			[
				76,
				353
			],
			[
				19,
				267
			],
			[
				47,
				134
			],
			[
				12,
				363
			],
			[
				-26,
				273
			]
		],
		[
			[
				90786,
				25569
			],
			[
				-56,
				171
			],
			[
				82,
				302
			],
			[
				-9,
				144
			]
		],
		[
			[
				90803,
				26186
			],
			[
				-100,
				-114
			],
			[
				-108,
				217
			]
		],
		[
			[
				90054,
				25808
			],
			[
				-21,
				-99
			]
		],
		[
			[
				90033,
				25709
			],
			[
				-206,
				-987
			]
		],
		[
			[
				89827,
				24722
			],
			[
				-92,
				-427
			]
		],
		[
			[
				86567,
				55546
			],
			[
				-122,
				-130
			],
			[
				-26,
				-165
			]
		],
		[
			[
				52677,
				61668
			],
			[
				932,
				19
			]
		],
		[
			[
				53047,
				63601
			],
			[
				-157,
				217
			],
			[
				-115,
				-85
			],
			[
				-114,
				0
			]
		],
		[
			[
				18537,
				17392
			],
			[
				988,
				414
			]
		],
		[
			[
				19927,
				18119
			],
			[
				21,
				93
			],
			[
				-44,
				264
			],
			[
				-106,
				-33
			],
			[
				-41,
				422
			],
			[
				-129,
				-38
			],
			[
				-166,
				90
			],
			[
				-89,
				-33
			],
			[
				1,
				161
			],
			[
				192,
				159
			],
			[
				74,
				252
			],
			[
				-134,
				183
			],
			[
				-12,
				221
			],
			[
				-84,
				39
			],
			[
				-69,
				189
			],
			[
				29,
				86
			],
			[
				-115,
				127
			],
			[
				-86,
				278
			],
			[
				412,
				165
			]
		],
		[
			[
				19581,
				20744
			],
			[
				-105,
				809
			]
		],
		[
			[
				19476,
				21553
			],
			[
				-559,
				-230
			],
			[
				4,
				-26
			],
			[
				-357,
				-151
			],
			[
				-47,
				326
			],
			[
				-1061,
				-440
			],
			[
				-248,
				-114
			]
		],
		[
			[
				17208,
				20918
			],
			[
				196,
				-1292
			]
		],
		[
			[
				17404,
				19626
			],
			[
				290,
				-1915
			],
			[
				-30,
				-14
			],
			[
				94,
				-634
			]
		],
		[
			[
				88277,
				35482
			],
			[
				705,
				647
			]
		],
		[
			[
				88982,
				36129
			],
			[
				-177,
				876
			]
		],
		[
			[
				88805,
				37005
			],
			[
				-242,
				503
			]
		],
		[
			[
				87459,
				36493
			],
			[
				157,
				-133
			],
			[
				137,
				-55
			],
			[
				130,
				-118
			],
			[
				183,
				-254
			],
			[
				53,
				13
			],
			[
				9,
				-261
			],
			[
				149,
				-203
			]
		],
		[
			[
				71289,
				59379
			],
			[
				171,
				-42
			]
		],
		[
			[
				71460,
				59337
			],
			[
				89,
				79
			]
		],
		[
			[
				71549,
				59416
			],
			[
				-1,
				752
			],
			[
				23,
				215
			],
			[
				121,
				252
			]
		],
		[
			[
				71692,
				60635
			],
			[
				3,
				147
			],
			[
				-165,
				151
			],
			[
				-79,
				177
			],
			[
				-148,
				-17
			]
		],
		[
			[
				71031,
				60544
			],
			[
				3,
				-134
			],
			[
				101,
				-131
			],
			[
				72,
				-185
			],
			[
				28,
				-223
			],
			[
				3,
				-444
			],
			[
				51,
				-48
			]
		],
		[
			[
				76228,
				56567
			],
			[
				72,
				52
			],
			[
				305,
				-50
			]
		],
		[
			[
				76605,
				56569
			],
			[
				140,
				296
			]
		],
		[
			[
				76745,
				56865
			],
			[
				-78,
				-32
			],
			[
				80,
				195
			]
		],
		[
			[
				76576,
				57655
			],
			[
				-92,
				199
			],
			[
				-98,
				0
			],
			[
				-166,
				-108
			],
			[
				-11,
				138
			],
			[
				-131,
				19
			],
			[
				-141,
				210
			]
		],
		[
			[
				75937,
				58113
			],
			[
				-199,
				-315
			]
		],
		[
			[
				75720,
				57751
			],
			[
				80,
				-155
			],
			[
				33,
				-191
			],
			[
				83,
				6
			],
			[
				148,
				-224
			],
			[
				-73,
				-111
			],
			[
				237,
				-509
			]
		],
		[
			[
				56343,
				81359
			],
			[
				618,
				-557
			],
			[
				-13,
				-23
			]
		],
		[
			[
				56948,
				80779
			],
			[
				224,
				8
			]
		],
		[
			[
				57172,
				80787
			],
			[
				275,
				1441
			],
			[
				292,
				-11
			]
		],
		[
			[
				57739,
				82217
			],
			[
				14,
				855
			]
		],
		[
			[
				56480,
				82037
			],
			[
				-137,
				-678
			]
		],
		[
			[
				43446,
				30087
			],
			[
				-79,
				1825
			]
		],
		[
			[
				42550,
				31804
			],
			[
				-888,
				-124
			]
		],
		[
			[
				41662,
				31680
			],
			[
				88,
				-1827
			]
		],
		[
			[
				53650,
				80022
			],
			[
				210,
				-185
			],
			[
				136,
				-457
			],
			[
				95,
				-216
			],
			[
				185,
				-203
			]
		],
		[
			[
				54276,
				78961
			],
			[
				82,
				161
			],
			[
				-29,
				78
			],
			[
				85,
				339
			]
		],
		[
			[
				54414,
				79539
			],
			[
				-18,
				434
			],
			[
				26,
				195
			],
			[
				-47,
				227
			],
			[
				157,
				681
			],
			[
				-93,
				351
			]
		],
		[
			[
				54439,
				81427
			],
			[
				9,
				-148
			],
			[
				-95,
				-54
			],
			[
				-133,
				92
			],
			[
				-59,
				-79
			]
		],
		[
			[
				54161,
				81238
			],
			[
				16,
				-248
			],
			[
				-95,
				-117
			],
			[
				-36,
				-261
			],
			[
				-103,
				11
			],
			[
				-13,
				-172
			],
			[
				-173,
				-168
			],
			[
				-175,
				-51
			],
			[
				68,
				-210
			]
		],
		[
			[
				26433,
				61544
			],
			[
				-12,
				123
			],
			[
				-209,
				148
			],
			[
				-43,
				293
			],
			[
				-95,
				284
			],
			[
				-44,
				289
			],
			[
				425,
				121
			],
			[
				-35,
				57
			],
			[
				30,
				248
			],
			[
				-81,
				261
			]
		],
		[
			[
				26369,
				63368
			],
			[
				-419,
				-119
			],
			[
				-778,
				-414
			],
			[
				-11,
				130
			],
			[
				-168,
				236
			],
			[
				-86,
				204
			],
			[
				-803,
				-700
			],
			[
				-1067,
				-329
			]
		],
		[
			[
				36926,
				44974
			],
			[
				48,
				-65
			],
			[
				77,
				121
			],
			[
				114,
				37
			],
			[
				139,
				-91
			]
		],
		[
			[
				37425,
				44998
			],
			[
				-17,
				152
			],
			[
				-82,
				103
			],
			[
				-25,
				664
			],
			[
				-70,
				116
			],
			[
				65,
				79
			],
			[
				-44,
				110
			]
		],
		[
			[
				37252,
				46222
			],
			[
				-660,
				-131
			]
		],
		[
			[
				36592,
				46091
			],
			[
				53,
				-369
			],
			[
				92,
				-26
			],
			[
				77,
				-551
			],
			[
				91,
				1
			],
			[
				21,
				-172
			]
		],
		[
			[
				82917,
				85361
			],
			[
				1548,
				-415
			]
		],
		[
			[
				84465,
				84946
			],
			[
				93,
				988
			],
			[
				-12,
				4
			],
			[
				93,
				985
			]
		],
		[
			[
				84639,
				86923
			],
			[
				-25,
				7
			],
			[
				80,
				673
			]
		],
		[
			[
				84694,
				87603
			],
			[
				-529,
				140
			]
		],
		[
			[
				84165,
				87743
			],
			[
				-30,
				-251
			],
			[
				-175,
				-407
			],
			[
				-183,
				-81
			],
			[
				-34,
				-221
			],
			[
				-181,
				-267
			],
			[
				-64,
				24
			],
			[
				-39,
				-150
			],
			[
				116,
				91
			],
			[
				38,
				-291
			],
			[
				-214,
				56
			],
			[
				-20,
				-220
			],
			[
				-133,
				37
			],
			[
				-19,
				-219
			],
			[
				-85,
				-203
			],
			[
				-195,
				51
			],
			[
				-30,
				-331
			]
		],
		[
			[
				77671,
				69825
			],
			[
				-1,
				-3
			]
		],
		[
			[
				77670,
				69822
			],
			[
				200,
				64
			]
		],
		[
			[
				77870,
				69886
			],
			[
				70,
				8
			],
			[
				69,
				262
			],
			[
				-20,
				57
			],
			[
				65,
				249
			],
			[
				72,
				61
			],
			[
				-8,
				129
			]
		],
		[
			[
				78118,
				70652
			],
			[
				15,
				193
			]
		],
		[
			[
				78133,
				70845
			],
			[
				-48,
				8
			],
			[
				-138,
				472
			]
		],
		[
			[
				77947,
				71325
			],
			[
				-435,
				87
			]
		],
		[
			[
				77512,
				71412
			],
			[
				-20,
				-570
			],
			[
				-27,
				-107
			]
		],
		[
			[
				64546,
				42963
			],
			[
				129,
				-212
			],
			[
				76,
				-243
			],
			[
				41,
				-309
			],
			[
				182,
				-255
			]
		],
		[
			[
				64974,
				41944
			],
			[
				349,
				-38
			],
			[
				21,
				448
			],
			[
				189,
				-25
			]
		],
		[
			[
				65565,
				43089
			],
			[
				-60,
				-113
			],
			[
				-84,
				4
			],
			[
				-106,
				120
			],
			[
				-252,
				25
			],
			[
				-123,
				-43
			],
			[
				-37,
				135
			]
		],
		[
			[
				64903,
				43217
			],
			[
				-116,
				41
			],
			[
				-114,
				163
			],
			[
				-257,
				53
			],
			[
				-55,
				-229
			],
			[
				-70,
				-25
			]
		],
		[
			[
				72163,
				47481
			],
			[
				78,
				-40
			],
			[
				64,
				-269
			]
		],
		[
			[
				72305,
				47172
			],
			[
				100,
				-113
			],
			[
				89,
				47
			]
		],
		[
			[
				72741,
				47872
			],
			[
				-384,
				77
			],
			[
				-83,
				184
			]
		],
		[
			[
				72274,
				48133
			],
			[
				-69,
				-42
			],
			[
				-42,
				-610
			]
		],
		[
			[
				50435,
				51140
			],
			[
				958,
				35
			]
		],
		[
			[
				51393,
				51175
			],
			[
				383,
				12
			]
		],
		[
			[
				51765,
				52200
			],
			[
				-1,
				680
			],
			[
				-191,
				-1
			]
		],
		[
			[
				18260,
				1540
			],
			[
				-62,
				106
			],
			[
				57,
				136
			],
			[
				99,
				-19
			],
			[
				-45,
				281
			],
			[
				42,
				105
			],
			[
				-46,
				262
			],
			[
				81,
				266
			],
			[
				-179,
				180
			]
		],
		[
			[
				18207,
				2857
			],
			[
				-228,
				-104
			],
			[
				-10,
				60
			],
			[
				-1289,
				-613
			],
			[
				-979,
				-480
			]
		],
		[
			[
				15701,
				1720
			],
			[
				-2,
				-267
			],
			[
				64,
				-118
			],
			[
				-50,
				-123
			],
			[
				-90,
				-26
			],
			[
				-133,
				85
			],
			[
				62,
				-130
			],
			[
				-82,
				-88
			],
			[
				23,
				-251
			],
			[
				-70,
				-161
			],
			[
				83,
				-139
			],
			[
				-97,
				-80
			],
			[
				125,
				-187
			],
			[
				942,
				460
			],
			[
				493,
				255
			],
			[
				1291,
				590
			]
		],
		[
			[
				62064,
				28549
			],
			[
				165,
				-15
			],
			[
				-30,
				-201
			],
			[
				110,
				-142
			],
			[
				72,
				67
			],
			[
				34,
				-135
			],
			[
				75,
				23
			]
		],
		[
			[
				62777,
				28155
			],
			[
				96,
				-12
			],
			[
				45,
				1326
			]
		],
		[
			[
				62918,
				29469
			],
			[
				-557,
				49
			]
		],
		[
			[
				62361,
				29518
			],
			[
				16,
				-190
			],
			[
				-72,
				-272
			]
		],
		[
			[
				62305,
				29056
			],
			[
				-241,
				-507
			]
		],
		[
			[
				72700,
				71898
			],
			[
				120,
				-109
			],
			[
				60,
				-143
			],
			[
				126,
				-130
			],
			[
				97,
				210
			],
			[
				125,
				-14
			],
			[
				154,
				80
			],
			[
				41,
				-61
			]
		],
		[
			[
				73423,
				71731
			],
			[
				79,
				310
			],
			[
				149,
				211
			]
		],
		[
			[
				73651,
				72252
			],
			[
				-125,
				31
			],
			[
				-19,
				88
			],
			[
				52,
				761
			]
		],
		[
			[
				73559,
				73132
			],
			[
				22,
				318
			],
			[
				-368,
				70
			]
		],
		[
			[
				72791,
				73269
			],
			[
				-49,
				-739
			],
			[
				-166,
				-193
			],
			[
				-21,
				-161
			]
		],
		[
			[
				72555,
				72176
			],
			[
				56,
				-246
			],
			[
				89,
				-32
			]
		],
		[
			[
				71500,
				70864
			],
			[
				14,
				254
			],
			[
				186,
				-34
			]
		],
		[
			[
				71700,
				71084
			],
			[
				48,
				482
			],
			[
				127,
				362
			],
			[
				87,
				362
			]
		],
		[
			[
				71962,
				72290
			],
			[
				-78,
				279
			],
			[
				111,
				163
			],
			[
				-183,
				29
			],
			[
				40,
				677
			]
		],
		[
			[
				70748,
				72777
			],
			[
				-11,
				-164
			]
		],
		[
			[
				73577,
				65711
			],
			[
				387,
				-24
			],
			[
				2,
				56
			]
		],
		[
			[
				73966,
				65743
			],
			[
				9,
				201
			],
			[
				-189,
				87
			],
			[
				7,
				113
			],
			[
				96,
				-16
			],
			[
				9,
				168
			],
			[
				-88,
				128
			],
			[
				29,
				478
			],
			[
				-26,
				87
			],
			[
				-152,
				134
			],
			[
				7,
				169
			],
			[
				-96,
				17
			]
		],
		[
			[
				72901,
				66955
			],
			[
				11,
				-145
			],
			[
				156,
				-212
			],
			[
				-58,
				-294
			]
		],
		[
			[
				26302,
				45456
			],
			[
				63,
				-90
			],
			[
				17,
				-167
			],
			[
				77,
				-121
			],
			[
				233,
				89
			],
			[
				175,
				-148
			],
			[
				43,
				-526
			],
			[
				61,
				18
			],
			[
				80,
				-140
			],
			[
				48,
				-192
			],
			[
				117,
				34
			],
			[
				10,
				-111
			],
			[
				82,
				-5
			],
			[
				27,
				-187
			]
		],
		[
			[
				27335,
				43910
			],
			[
				283,
				88
			],
			[
				-15,
				39
			],
			[
				940,
				268
			]
		],
		[
			[
				28543,
				44305
			],
			[
				-130,
				1372
			],
			[
				-14,
				-4
			],
			[
				-56,
				618
			]
		],
		[
			[
				28343,
				46291
			],
			[
				-770,
				-217
			],
			[
				-20,
				25
			]
		],
		[
			[
				27553,
				46099
			],
			[
				-505,
				-145
			],
			[
				3,
				-32
			],
			[
				-779,
				-230
			]
		],
		[
			[
				81344,
				51560
			],
			[
				81,
				-148
			],
			[
				315,
				-343
			]
		],
		[
			[
				81945,
				52030
			],
			[
				-138,
				245
			]
		],
		[
			[
				81807,
				52275
			],
			[
				-72,
				163
			],
			[
				-95,
				90
			]
		],
		[
			[
				81640,
				52528
			],
			[
				-320,
				-492
			],
			[
				-50,
				-166
			]
		],
		[
			[
				81270,
				51870
			],
			[
				-46,
				-150
			],
			[
				120,
				-160
			]
		],
		[
			[
				88202,
				47135
			],
			[
				168,
				379
			],
			[
				55,
				36
			]
		],
		[
			[
				88425,
				47550
			],
			[
				8,
				288
			],
			[
				60,
				169
			],
			[
				-90,
				228
			]
		],
		[
			[
				88336,
				48118
			],
			[
				-109,
				-160
			]
		],
		[
			[
				88227,
				47958
			],
			[
				8,
				-149
			],
			[
				-300,
				-442
			],
			[
				-67,
				44
			],
			[
				-147,
				-78
			],
			[
				-149,
				-205
			],
			[
				-94,
				-287
			],
			[
				-87,
				-67
			]
		],
		[
			[
				87391,
				46774
			],
			[
				-27,
				-219
			],
			[
				47,
				-104
			],
			[
				138,
				-19
			]
		],
		[
			[
				80374,
				44547
			],
			[
				68,
				171
			],
			[
				42,
				438
			],
			[
				-73,
				226
			],
			[
				149,
				254
			]
		],
		[
			[
				80498,
				45849
			],
			[
				-69,
				237
			]
		],
		[
			[
				80429,
				46086
			],
			[
				-41,
				-95
			],
			[
				-98,
				7
			],
			[
				-57,
				-359
			],
			[
				-113,
				-178
			],
			[
				-6,
				-195
			],
			[
				-123,
				-246
			]
		],
		[
			[
				79991,
				45020
			],
			[
				2,
				-84
			],
			[
				154,
				-410
			]
		],
		[
			[
				80984,
				83157
			],
			[
				148,
				-47
			],
			[
				89,
				112
			],
			[
				104,
				15
			],
			[
				119,
				133
			]
		],
		[
			[
				81444,
				83370
			],
			[
				95,
				220
			],
			[
				200,
				241
			],
			[
				7,
				241
			],
			[
				-102,
				184
			],
			[
				-11,
				196
			]
		],
		[
			[
				81633,
				84452
			],
			[
				-300,
				81
			],
			[
				-11,
				-109
			],
			[
				-487,
				121
			]
		],
		[
			[
				80835,
				84545
			],
			[
				-93,
				-80
			],
			[
				16,
				-143
			],
			[
				-91,
				-337
			],
			[
				63,
				-229
			],
			[
				-163,
				-299
			]
		],
		[
			[
				80567,
				83457
			],
			[
				122,
				-186
			],
			[
				157,
				57
			],
			[
				138,
				-171
			]
		],
		[
			[
				20175,
				89861
			],
			[
				13,
				113
			],
			[
				190,
				47
			],
			[
				10,
				-122
			],
			[
				203,
				99
			],
			[
				84,
				-245
			],
			[
				227,
				-110
			],
			[
				4,
				366
			],
			[
				81,
				102
			],
			[
				140,
				60
			],
			[
				56,
				170
			],
			[
				488,
				525
			],
			[
				96,
				424
			]
		],
		[
			[
				21623,
				91496
			],
			[
				-8,
				-88
			],
			[
				-174,
				-184
			],
			[
				-149,
				-44
			],
			[
				-131,
				-111
			],
			[
				-217,
				-96
			],
			[
				-195,
				-143
			],
			[
				33,
				-152
			],
			[
				61,
				18
			],
			[
				-66,
				-341
			],
			[
				9,
				-235
			],
			[
				-56,
				319
			],
			[
				-180,
				249
			],
			[
				-233,
				14
			],
			[
				-225,
				-109
			],
			[
				45,
				-181
			],
			[
				-124,
				91
			],
			[
				-378,
				-50
			],
			[
				-106,
				16
			],
			[
				-332,
				185
			],
			[
				-67,
				71
			]
		],
		[
			[
				19130,
				90725
			],
			[
				-9,
				-71
			],
			[
				218,
				-707
			],
			[
				528,
				-170
			],
			[
				14,
				122
			],
			[
				207,
				-69
			],
			[
				87,
				31
			]
		],
		[
			[
				61539,
				58876
			],
			[
				14,
				105
			],
			[
				88,
				-33
			],
			[
				51,
				181
			],
			[
				103,
				109
			],
			[
				-73,
				116
			],
			[
				29,
				152
			],
			[
				-38,
				136
			],
			[
				108,
				-54
			],
			[
				79,
				87
			],
			[
				91,
				-54
			],
			[
				107,
				181
			],
			[
				85,
				53
			]
		],
		[
			[
				62183,
				59855
			],
			[
				-33,
				222
			],
			[
				69,
				233
			],
			[
				2,
				169
			]
		],
		[
			[
				12186,
				33802
			],
			[
				75,
				299
			],
			[
				-128,
				221
			],
			[
				-86,
				421
			],
			[
				86,
				220
			],
			[
				-1,
				119
			],
			[
				171,
				479
			],
			[
				-12,
				96
			],
			[
				94,
				217
			],
			[
				-18,
				107
			],
			[
				81,
				161
			]
		],
		[
			[
				12448,
				36142
			],
			[
				-146,
				100
			],
			[
				-17,
				108
			],
			[
				-259,
				-94
			],
			[
				-87,
				178
			],
			[
				-18,
				151
			],
			[
				-171,
				262
			],
			[
				-278,
				8
			],
			[
				-72,
				-90
			],
			[
				-34,
				78
			]
		],
		[
			[
				11366,
				36843
			],
			[
				-4,
				-43
			],
			[
				-460,
				-211
			]
		],
		[
			[
				10902,
				36589
			],
			[
				78,
				-283
			]
		],
		[
			[
				10980,
				36306
			],
			[
				72,
				-229
			],
			[
				25,
				-228
			],
			[
				51,
				-86
			],
			[
				-228,
				-97
			],
			[
				84,
				-511
			],
			[
				102,
				-57
			],
			[
				-111,
				-453
			]
		],
		[
			[
				10975,
				34645
			],
			[
				-11,
				-177
			],
			[
				66,
				-141
			],
			[
				395,
				186
			],
			[
				101,
				-42
			],
			[
				124,
				-251
			],
			[
				92,
				51
			],
			[
				43,
				-153
			],
			[
				85,
				-88
			],
			[
				41,
				-152
			],
			[
				124,
				59
			],
			[
				107,
				-36
			],
			[
				44,
				-99
			]
		],
		[
			[
				75404,
				63104
			],
			[
				513,
				-105
			],
			[
				43,
				-64
			]
		],
		[
			[
				75960,
				62935
			],
			[
				73,
				353
			],
			[
				114,
				-22
			],
			[
				23,
				330
			]
		],
		[
			[
				76170,
				63596
			],
			[
				-591,
				122
			],
			[
				-8,
				-118
			],
			[
				-128,
				25
			]
		],
		[
			[
				75443,
				63625
			],
			[
				-39,
				-521
			]
		],
		[
			[
				73922,
				51430
			],
			[
				-4,
				-184
			]
		],
		[
			[
				73918,
				51246
			],
			[
				479,
				-229
			]
		],
		[
			[
				74397,
				51017
			],
			[
				74,
				45
			],
			[
				97,
				226
			]
		],
		[
			[
				74568,
				51288
			],
			[
				5,
				64
			],
			[
				-311,
				334
			]
		],
		[
			[
				26369,
				63368
			],
			[
				90,
				20
			],
			[
				-40,
				306
			],
			[
				83,
				25
			],
			[
				-26,
				128
			],
			[
				51,
				252
			],
			[
				-53,
				273
			],
			[
				77,
				144
			],
			[
				2,
				119
			],
			[
				168,
				382
			],
			[
				152,
				-252
			],
			[
				132,
				861
			]
		],
		[
			[
				27005,
				65626
			],
			[
				-988,
				-274
			],
			[
				-100,
				987
			],
			[
				-918,
				-259
			],
			[
				-101,
				-222
			],
			[
				-74,
				-66
			],
			[
				-3,
				-179
			],
			[
				-308,
				3047
			]
		],
		[
			[
				24513,
				68660
			],
			[
				-543,
				-157
			],
			[
				-1543,
				-472
			]
		],
		[
			[
				22427,
				68031
			],
			[
				356,
				-3300
			]
		],
		[
			[
				58505,
				68221
			],
			[
				232,
				3
			],
			[
				2,
				-1006
			]
		],
		[
			[
				59396,
				69243
			],
			[
				-444,
				-4
			]
		],
		[
			[
				58952,
				69239
			],
			[
				-93,
				-60
			],
			[
				-104,
				-424
			]
		],
		[
			[
				58755,
				68755
			],
			[
				-89,
				-90
			],
			[
				-80,
				19
			],
			[
				-93,
				-150
			],
			[
				12,
				-313
			]
		],
		[
			[
				61508,
				68233
			],
			[
				655,
				-33
			]
		],
		[
			[
				62166,
				69380
			],
			[
				57,
				74
			],
			[
				-47,
				145
			],
			[
				23,
				149
			],
			[
				-118,
				100
			],
			[
				-81,
				207
			],
			[
				-51,
				14
			],
			[
				-35,
				235
			]
		],
		[
			[
				61914,
				70304
			],
			[
				-161,
				-168
			],
			[
				-10,
				-170
			],
			[
				-188,
				-23
			],
			[
				-48,
				-136
			]
		],
		[
			[
				61507,
				69807
			],
			[
				-54,
				-57
			],
			[
				3,
				-246
			],
			[
				58,
				-142
			],
			[
				47,
				-340
			],
			[
				-41,
				-440
			],
			[
				-12,
				-349
			]
		],
		[
			[
				38762,
				43809
			],
			[
				37,
				-637
			]
		],
		[
			[
				38799,
				43172
			],
			[
				392,
				61
			]
		],
		[
			[
				39191,
				43233
			],
			[
				115,
				75
			],
			[
				77,
				-44
			]
		],
		[
			[
				39383,
				43264
			],
			[
				-27,
				473
			]
		],
		[
			[
				39356,
				43737
			],
			[
				-27,
				474
			],
			[
				-103,
				151
			],
			[
				93,
				27
			]
		],
		[
			[
				39315,
				44602
			],
			[
				-79,
				263
			],
			[
				-88,
				79
			],
			[
				12,
				143
			],
			[
				-88,
				93
			],
			[
				-1,
				170
			],
			[
				-140,
				426
			],
			[
				-189,
				423
			]
		],
		[
			[
				38740,
				46199
			],
			[
				-115,
				-19
			],
			[
				95,
				-1677
			]
		],
		[
			[
				34291,
				50106
			],
			[
				70,
				65
			],
			[
				108,
				-88
			],
			[
				6,
				-170
			],
			[
				125,
				26
			]
		],
		[
			[
				34600,
				49939
			],
			[
				58,
				94
			],
			[
				-26,
				267
			],
			[
				21,
				189
			],
			[
				79,
				82
			],
			[
				-35,
				153
			],
			[
				-35,
				494
			]
		],
		[
			[
				34662,
				51218
			],
			[
				-849,
				-180
			]
		],
		[
			[
				33813,
				51038
			],
			[
				19,
				-218
			],
			[
				174,
				-63
			],
			[
				36,
				-199
			]
		],
		[
			[
				76518,
				68147
			],
			[
				232,
				-9
			],
			[
				-7,
				-93
			]
		],
		[
			[
				76895,
				68013
			],
			[
				67,
				973
			],
			[
				-142,
				92
			]
		],
		[
			[
				76820,
				69078
			],
			[
				-14,
				-219
			],
			[
				-274,
				52
			]
		],
		[
			[
				76532,
				68911
			],
			[
				26,
				-193
			],
			[
				-40,
				-571
			]
		],
		[
			[
				16902,
				89151
			],
			[
				95,
				44
			],
			[
				267,
				-35
			]
		],
		[
			[
				17264,
				89160
			],
			[
				34,
				773
			],
			[
				-100,
				14
			],
			[
				4,
				155
			]
		],
		[
			[
				17084,
				89966
			],
			[
				-274,
				-198
			],
			[
				-133,
				-173
			],
			[
				129,
				-322
			],
			[
				96,
				-122
			]
		],
		[
			[
				63584,
				68762
			],
			[
				836,
				-75
			]
		],
		[
			[
				64420,
				68687
			],
			[
				31,
				995
			],
			[
				89,
				-7
			]
		],
		[
			[
				64540,
				69675
			],
			[
				-27,
				145
			],
			[
				102,
				110
			],
			[
				-27,
				410
			]
		],
		[
			[
				64588,
				70340
			],
			[
				-395,
				32
			],
			[
				11,
				345
			]
		],
		[
			[
				64204,
				70717
			],
			[
				-469,
				28
			]
		],
		[
			[
				64588,
				70340
			],
			[
				81,
				-9
			],
			[
				25,
				668
			]
		],
		[
			[
				64694,
				70999
			],
			[
				-181,
				352
			],
			[
				3,
				83
			],
			[
				121,
				-18
			],
			[
				15,
				190
			],
			[
				-94,
				151
			],
			[
				151,
				140
			],
			[
				-106,
				114
			]
		],
		[
			[
				64603,
				72011
			],
			[
				-452,
				39
			],
			[
				-18,
				-674
			],
			[
				79,
				-415
			],
			[
				-8,
				-244
			]
		],
		[
			[
				60045,
				42713
			],
			[
				22,
				1177
			]
		],
		[
			[
				59814,
				49723
			],
			[
				0,
				1031
			]
		],
		[
			[
				59814,
				50754
			],
			[
				-2,
				296
			],
			[
				40,
				1
			],
			[
				-2,
				476
			]
		],
		[
			[
				59085,
				51523
			],
			[
				4,
				-487
			],
			[
				-46,
				-29
			]
		],
		[
			[
				72055,
				48728
			],
			[
				-14,
				-270
			],
			[
				183,
				-91
			],
			[
				50,
				-234
			]
		],
		[
			[
				72989,
				47902
			],
			[
				8,
				240
			]
		],
		[
			[
				72997,
				48142
			],
			[
				-100,
				236
			],
			[
				-180,
				146
			],
			[
				-38,
				237
			]
		],
		[
			[
				72437,
				49162
			],
			[
				-20,
				-169
			],
			[
				58,
				-78
			],
			[
				-94,
				-193
			],
			[
				-324,
				34
			],
			[
				-2,
				-28
			]
		],
		[
			[
				79967,
				66875
			],
			[
				96,
				-188
			]
		],
		[
			[
				80063,
				66687
			],
			[
				425,
				-847
			]
		],
		[
			[
				80488,
				65840
			],
			[
				33,
				42
			]
		],
		[
			[
				80521,
				65882
			],
			[
				63,
				161
			],
			[
				148,
				138
			],
			[
				-32,
				309
			],
			[
				79,
				135
			],
			[
				119,
				37
			],
			[
				12,
				228
			]
		],
		[
			[
				80910,
				66890
			],
			[
				-70,
				-38
			],
			[
				-157,
				175
			],
			[
				-154,
				-21
			],
			[
				-64,
				63
			],
			[
				-164,
				-217
			],
			[
				-161,
				155
			]
		],
		[
			[
				80140,
				67007
			],
			[
				-173,
				-132
			]
		],
		[
			[
				66786,
				54759
			],
			[
				99,
				-209
			],
			[
				35,
				-185
			],
			[
				141,
				-245
			],
			[
				119,
				-28
			]
		],
		[
			[
				67180,
				54092
			],
			[
				252,
				1027
			]
		],
		[
			[
				66932,
				55221
			],
			[
				-63,
				-139
			]
		],
		[
			[
				66869,
				55082
			],
			[
				-97,
				-202
			],
			[
				14,
				-121
			]
		],
		[
			[
				77212,
				71942
			],
			[
				95,
				-318
			],
			[
				205,
				-212
			]
		],
		[
			[
				77947,
				71325
			],
			[
				17,
				-3
			],
			[
				46,
				648
			]
		],
		[
			[
				78010,
				71970
			],
			[
				24,
				345
			]
		],
		[
			[
				78034,
				72315
			],
			[
				-658,
				136
			]
		],
		[
			[
				77376,
				72451
			],
			[
				-164,
				-509
			]
		],
		[
			[
				74642,
				63109
			],
			[
				25,
				-142
			]
		],
		[
			[
				74667,
				62967
			],
			[
				227,
				-20
			],
			[
				18,
				-74
			]
		],
		[
			[
				74912,
				62873
			],
			[
				97,
				80
			],
			[
				212,
				-52
			],
			[
				52,
				-57
			],
			[
				122,
				129
			]
		],
		[
			[
				75395,
				62973
			],
			[
				9,
				131
			]
		],
		[
			[
				75443,
				63625
			],
			[
				-147,
				29
			],
			[
				4,
				63
			],
			[
				-397,
				70
			],
			[
				-96,
				45
			]
		],
		[
			[
				74807,
				63832
			],
			[
				-182,
				-10
			],
			[
				23,
				-85
			],
			[
				-32,
				-447
			],
			[
				26,
				-181
			]
		],
		[
			[
				74007,
				46650
			],
			[
				251,
				259
			]
		],
		[
			[
				74258,
				46909
			],
			[
				-203,
				88
			],
			[
				3,
				173
			]
		],
		[
			[
				74058,
				47170
			],
			[
				-140,
				114
			],
			[
				-100,
				161
			]
		],
		[
			[
				73818,
				47445
			],
			[
				-179,
				-352
			]
		],
		[
			[
				73639,
				47093
			],
			[
				224,
				-172
			],
			[
				129,
				14
			],
			[
				-30,
				-207
			],
			[
				45,
				-78
			]
		],
		[
			[
				76653,
				48170
			],
			[
				184,
				-120
			],
			[
				-37,
				-331
			],
			[
				98,
				-324
			]
		],
		[
			[
				76898,
				47395
			],
			[
				208,
				101
			],
			[
				162,
				242
			],
			[
				98,
				-44
			],
			[
				171,
				57
			]
		],
		[
			[
				77537,
				47751
			],
			[
				82,
				25
			],
			[
				-1,
				463
			]
		],
		[
			[
				77618,
				48239
			],
			[
				-86,
				229
			],
			[
				-118,
				87
			]
		],
		[
			[
				77414,
				48555
			],
			[
				-86,
				32
			],
			[
				-73,
				-114
			],
			[
				-66,
				79
			],
			[
				-206,
				-244
			],
			[
				-93,
				128
			],
			[
				-32,
				182
			]
		],
		[
			[
				60733,
				74287
			],
			[
				945,
				-55
			]
		],
		[
			[
				61678,
				74232
			],
			[
				19,
				840
			]
		],
		[
			[
				61697,
				75072
			],
			[
				-43,
				440
			],
			[
				-40,
				67
			]
		],
		[
			[
				77149,
				70598
			],
			[
				-84,
				17
			]
		],
		[
			[
				77065,
				70615
			],
			[
				91,
				-51
			]
		],
		[
			[
				76732,
				71267
			],
			[
				-5,
				-91
			],
			[
				103,
				-41
			],
			[
				111,
				-431
			],
			[
				124,
				-89
			]
		],
		[
			[
				77065,
				70615
			],
			[
				70,
				45
			]
		],
		[
			[
				77212,
				71942
			],
			[
				-112,
				-54
			],
			[
				-92,
				43
			],
			[
				-11,
				-163
			],
			[
				-89,
				13
			]
		],
		[
			[
				76908,
				71781
			],
			[
				-72,
				9
			],
			[
				-16,
				-244
			],
			[
				-69,
				11
			],
			[
				-19,
				-290
			]
		],
		[
			[
				76327,
				70616
			],
			[
				66,
				-13
			],
			[
				-12,
				-185
			],
			[
				49,
				-93
			],
			[
				51,
				-355
			],
			[
				89,
				-123
			]
		],
		[
			[
				76570,
				69847
			],
			[
				96,
				15
			],
			[
				75,
				171
			]
		],
		[
			[
				76741,
				70033
			],
			[
				186,
				25
			],
			[
				69,
				132
			],
			[
				58,
				253
			],
			[
				102,
				121
			]
		],
		[
			[
				76732,
				71267
			],
			[
				-129,
				26
			],
			[
				-6,
				-97
			],
			[
				-133,
				-35
			]
		],
		[
			[
				76464,
				71161
			],
			[
				-48,
				-266
			],
			[
				-98,
				-169
			],
			[
				9,
				-110
			]
		],
		[
			[
				77376,
				73923
			],
			[
				49,
				86
			],
			[
				48,
				691
			]
		],
		[
			[
				76661,
				74901
			],
			[
				27,
				-84
			],
			[
				-119,
				-476
			],
			[
				3,
				-137
			]
		],
		[
			[
				31748,
				53064
			],
			[
				-309,
				3809
			]
		],
		[
			[
				30751,
				65365
			],
			[
				-550,
				-129
			],
			[
				-43,
				141
			],
			[
				-97,
				94
			],
			[
				-129,
				35
			],
			[
				-40,
				140
			]
		],
		[
			[
				29892,
				65646
			],
			[
				-69,
				99
			],
			[
				-140,
				5
			],
			[
				-43,
				105
			],
			[
				-222,
				187
			],
			[
				-81,
				157
			],
			[
				-101,
				-257
			],
			[
				-96,
				-152
			]
		],
		[
			[
				29140,
				65790
			],
			[
				69,
				-800
			],
			[
				59,
				16
			],
			[
				114,
				-1328
			],
			[
				18,
				5
			],
			[
				119,
				-1381
			],
			[
				-6,
				-279
			],
			[
				55,
				-328
			],
			[
				55,
				-669
			],
			[
				13,
				4
			],
			[
				229,
				-2652
			],
			[
				30,
				7
			],
			[
				47,
				-552
			],
			[
				-309,
				-78
			],
			[
				442,
				-5097
			]
		],
		[
			[
				72305,
				47172
			],
			[
				-69,
				-1001
			]
		],
		[
			[
				72236,
				46171
			],
			[
				-17,
				-231
			],
			[
				193,
				-43
			]
		],
		[
			[
				53083,
				47518
			],
			[
				-4,
				646
			],
			[
				64,
				-53
			],
			[
				-3,
				419
			],
			[
				63,
				2
			]
		],
		[
			[
				53128,
				49537
			],
			[
				-764,
				-15
			]
		],
		[
			[
				52364,
				49522
			],
			[
				12,
				-1345
			]
		],
		[
			[
				47563,
				48943
			],
			[
				-161,
				-15
			]
		],
		[
			[
				46416,
				48841
			],
			[
				62,
				-1670
			]
		],
		[
			[
				74977,
				51781
			],
			[
				130,
				24
			],
			[
				28,
				-264
			]
		],
		[
			[
				75135,
				51541
			],
			[
				145,
				61
			],
			[
				118,
				-54
			]
		],
		[
			[
				75562,
				52303
			],
			[
				-126,
				181
			],
			[
				8,
				145
			],
			[
				-63,
				26
			],
			[
				-60,
				280
			],
			[
				24,
				68
			]
		],
		[
			[
				75345,
				53003
			],
			[
				-423,
				-600
			]
		],
		[
			[
				74922,
				52403
			],
			[
				55,
				-622
			]
		],
		[
			[
				76214,
				62667
			],
			[
				-99,
				100
			],
			[
				7,
				112
			],
			[
				-148,
				-15
			],
			[
				-14,
				71
			]
		],
		[
			[
				75395,
				62973
			],
			[
				-44,
				-557
			],
			[
				15,
				-191
			],
			[
				94,
				-205
			],
			[
				-74,
				-102
			]
		],
		[
			[
				78118,
				70652
			],
			[
				465,
				-618
			]
		],
		[
			[
				78583,
				70034
			],
			[
				211,
				576
			]
		],
		[
			[
				78794,
				70610
			],
			[
				-73,
				-97
			],
			[
				-211,
				477
			],
			[
				44,
				59
			],
			[
				-101,
				224
			]
		],
		[
			[
				78453,
				71273
			],
			[
				-320,
				-428
			]
		],
		[
			[
				75157,
				66134
			],
			[
				43,
				14
			],
			[
				280,
				-110
			],
			[
				-4,
				-67
			]
		],
		[
			[
				75476,
				65971
			],
			[
				186,
				-33
			],
			[
				98,
				218
			]
		],
		[
			[
				75760,
				66156
			],
			[
				-121,
				257
			],
			[
				0,
				94
			],
			[
				-96,
				70
			],
			[
				-161,
				313
			]
		],
		[
			[
				75382,
				66890
			],
			[
				-178,
				38
			],
			[
				-47,
				-794
			]
		],
		[
			[
				77445,
				66363
			],
			[
				255,
				-915
			]
		],
		[
			[
				77700,
				65448
			],
			[
				53,
				48
			],
			[
				86,
				290
			],
			[
				79,
				76
			]
		],
		[
			[
				77918,
				65862
			],
			[
				215,
				477
			],
			[
				85,
				95
			],
			[
				-4,
				195
			]
		],
		[
			[
				78214,
				66629
			],
			[
				-452,
				293
			]
		],
		[
			[
				77762,
				66922
			],
			[
				-299,
				-289
			]
		],
		[
			[
				77463,
				66633
			],
			[
				-18,
				-270
			]
		],
		[
			[
				64041,
				43772
			],
			[
				-31,
				254
			],
			[
				-73,
				186
			]
		],
		[
			[
				63937,
				44212
			],
			[
				25,
				142
			]
		],
		[
			[
				63962,
				44354
			],
			[
				-547,
				46
			],
			[
				-39,
				-13
			]
		],
		[
			[
				63376,
				44387
			],
			[
				-27,
				-999
			]
		],
		[
			[
				65587,
				35055
			],
			[
				366,
				-53
			],
			[
				9,
				207
			],
			[
				382,
				-56
			]
		],
		[
			[
				66385,
				36154
			],
			[
				-690,
				99
			],
			[
				-2,
				-56
			],
			[
				-443,
				56
			]
		],
		[
			[
				65250,
				36253
			],
			[
				-4,
				-109
			],
			[
				-93,
				9
			]
		],
		[
			[
				65153,
				36153
			],
			[
				-45,
				-1031
			]
		],
		[
			[
				60365,
				33831
			],
			[
				772,
				-50
			]
		],
		[
			[
				61137,
				33781
			],
			[
				17,
				668
			],
			[
				28,
				-2
			],
			[
				15,
				657
			]
		],
		[
			[
				61197,
				35104
			],
			[
				-384,
				26
			]
		],
		[
			[
				60373,
				34163
			],
			[
				-8,
				-332
			]
		],
		[
			[
				74418,
				50434
			],
			[
				48,
				-561
			]
		],
		[
			[
				74466,
				49873
			],
			[
				330,
				179
			],
			[
				101,
				340
			]
		],
		[
			[
				74897,
				50392
			],
			[
				-83,
				45
			],
			[
				50,
				133
			],
			[
				-101,
				153
			]
		],
		[
			[
				74763,
				50723
			],
			[
				-68,
				173
			],
			[
				-144,
				-203
			],
			[
				44,
				-70
			],
			[
				-109,
				-64
			],
			[
				-68,
				56
			]
		],
		[
			[
				74418,
				50615
			],
			[
				0,
				-181
			]
		],
		[
			[
				71484,
				53086
			],
			[
				543,
				-151
			]
		],
		[
			[
				72027,
				52935
			],
			[
				218,
				428
			],
			[
				-2,
				187
			]
		],
		[
			[
				72168,
				53975
			],
			[
				-142,
				-35
			],
			[
				-53,
				-97
			],
			[
				-105,
				109
			],
			[
				-227,
				-307
			]
		],
		[
			[
				71641,
				53645
			],
			[
				-157,
				-559
			]
		],
		[
			[
				74081,
				49511
			],
			[
				74,
				114
			],
			[
				-29,
				145
			],
			[
				113,
				280
			]
		],
		[
			[
				74239,
				50050
			],
			[
				-144,
				146
			],
			[
				-97,
				-30
			],
			[
				-73,
				198
			],
			[
				-71,
				69
			]
		],
		[
			[
				73854,
				50433
			],
			[
				-214,
				18
			]
		],
		[
			[
				73640,
				50451
			],
			[
				-45,
				-279
			]
		],
		[
			[
				73595,
				50172
			],
			[
				98,
				-274
			]
		],
		[
			[
				60344,
				32814
			],
			[
				769,
				-51
			]
		],
		[
			[
				61113,
				32763
			],
			[
				24,
				1018
			]
		],
		[
			[
				60365,
				33831
			],
			[
				-21,
				-1017
			]
		],
		[
			[
				70197,
				44955
			],
			[
				434,
				-59
			]
		],
		[
			[
				70631,
				44896
			],
			[
				91,
				-16
			],
			[
				29,
				473
			]
		],
		[
			[
				70751,
				45353
			],
			[
				-91,
				58
			],
			[
				42,
				653
			]
		],
		[
			[
				70067,
				46160
			],
			[
				-35,
				-671
			],
			[
				189,
				-29
			],
			[
				-24,
				-505
			]
		],
		[
			[
				51663,
				42783
			],
			[
				-16,
				1343
			]
		],
		[
			[
				51647,
				44126
			],
			[
				-187,
				-6
			]
		],
		[
			[
				51460,
				44120
			],
			[
				-581,
				-24
			]
		],
		[
			[
				70301,
				59723
			],
			[
				-8,
				289
			],
			[
				128,
				145
			],
			[
				32,
				222
			],
			[
				-27,
				155
			]
		],
		[
			[
				70426,
				60534
			],
			[
				-59,
				-37
			],
			[
				-221,
				29
			],
			[
				7,
				143
			],
			[
				-118,
				105
			],
			[
				-105,
				15
			]
		],
		[
			[
				69930,
				60789
			],
			[
				-142,
				-266
			],
			[
				-130,
				-22
			]
		],
		[
			[
				69658,
				60501
			],
			[
				44,
				-257
			],
			[
				58,
				-89
			],
			[
				-21,
				-154
			]
		],
		[
			[
				52414,
				44148
			],
			[
				12,
				-1343
			]
		],
		[
			[
				53190,
				42823
			],
			[
				-8,
				1343
			]
		],
		[
			[
				53182,
				44166
			],
			[
				-768,
				-18
			]
		],
		[
			[
				52316,
				58978
			],
			[
				382,
				8
			],
			[
				1,
				-336
			],
			[
				386,
				7
			]
		],
		[
			[
				53085,
				58657
			],
			[
				189,
				3
			],
			[
				-1,
				337
			],
			[
				353,
				1
			]
		],
		[
			[
				53623,
				59841
			],
			[
				-931,
				-17
			]
		],
		[
			[
				52692,
				59824
			],
			[
				-113,
				-13
			],
			[
				-128,
				-112
			],
			[
				-85,
				-148
			],
			[
				-55,
				99
			],
			[
				5,
				-672
			]
		],
		[
			[
				69268,
				62407
			],
			[
				685,
				-93
			]
		],
		[
			[
				69953,
				62314
			],
			[
				692,
				-91
			]
		],
		[
			[
				70628,
				63158
			],
			[
				-66,
				40
			],
			[
				-93,
				-162
			],
			[
				-150,
				10
			]
		],
		[
			[
				70319,
				63046
			],
			[
				-184,
				-97
			],
			[
				-320,
				278
			],
			[
				-42,
				114
			],
			[
				-184,
				43
			],
			[
				-166,
				-221
			],
			[
				-111,
				-286
			],
			[
				-127,
				-75
			],
			[
				-100,
				71
			]
		],
		[
			[
				68880,
				62501
			],
			[
				-7,
				-46
			],
			[
				395,
				-48
			]
		],
		[
			[
				73642,
				62242
			],
			[
				148,
				890
			]
		],
		[
			[
				73790,
				63132
			],
			[
				21,
				132
			]
		],
		[
			[
				73811,
				63264
			],
			[
				40,
				242
			]
		],
		[
			[
				73851,
				63506
			],
			[
				-104,
				178
			],
			[
				5,
				169
			],
			[
				-85,
				299
			],
			[
				-224,
				458
			],
			[
				-124,
				79
			],
			[
				11,
				171
			]
		],
		[
			[
				72851,
				64944
			],
			[
				-61,
				-869
			],
			[
				85,
				-202
			]
		],
		[
			[
				70680,
				76720
			],
			[
				111,
				49
			],
			[
				127,
				-145
			],
			[
				117,
				-19
			]
		],
		[
			[
				71035,
				76605
			],
			[
				1212,
				-200
			],
			[
				43,
				13
			],
			[
				20,
				268
			],
			[
				118,
				-43
			]
		],
		[
			[
				72494,
				77393
			],
			[
				-186,
				22
			]
		],
		[
			[
				72308,
				77415
			],
			[
				-718,
				109
			]
		],
		[
			[
				51707,
				37420
			],
			[
				-8,
				832
			]
		],
		[
			[
				50937,
				37395
			],
			[
				10,
				-669
			]
		],
		[
			[
				52076,
				39007
			],
			[
				127,
				-107
			],
			[
				121,
				-23
			],
			[
				137,
				-103
			]
		],
		[
			[
				52450,
				40120
			],
			[
				-766,
				-21
			]
		],
		[
			[
				51684,
				40099
			],
			[
				2,
				-489
			]
		],
		[
			[
				70618,
				38305
			],
			[
				-769,
				126
			]
		],
		[
			[
				69849,
				38431
			],
			[
				-14,
				-248
			]
		],
		[
			[
				49429,
				33227
			],
			[
				171,
				-135
			],
			[
				327,
				-182
			],
			[
				105,
				200
			],
			[
				79,
				-4
			],
			[
				209,
				172
			],
			[
				87,
				-17
			],
			[
				117,
				118
			],
			[
				196,
				61
			],
			[
				77,
				-33
			],
			[
				180,
				63
			]
		],
		[
			[
				50977,
				33470
			],
			[
				-18,
				1247
			],
			[
				9,
				1
			]
		],
		[
			[
				50948,
				36057
			],
			[
				-758,
				-34
			]
		],
		[
			[
				49430,
				35985
			],
			[
				-20,
				-2
			]
		],
		[
			[
				87384,
				49056
			],
			[
				87,
				-31
			]
		],
		[
			[
				87471,
				49025
			],
			[
				61,
				47
			],
			[
				-85,
				163
			],
			[
				-63,
				-179
			]
		],
		[
			[
				83919,
				53195
			],
			[
				71,
				-23
			]
		],
		[
			[
				83990,
				53172
			],
			[
				223,
				-68
			]
		],
		[
			[
				84213,
				53104
			],
			[
				217,
				-67
			]
		],
		[
			[
				84430,
				53037
			],
			[
				142,
				-44
			]
		],
		[
			[
				84572,
				52993
			],
			[
				91,
				1146
			]
		],
		[
			[
				83995,
				54311
			],
			[
				-76,
				-1116
			]
		],
		[
			[
				68799,
				56001
			],
			[
				393,
				-1
			],
			[
				0,
				118
			],
			[
				280,
				-57
			]
		],
		[
			[
				69472,
				56061
			],
			[
				94,
				-17
			]
		],
		[
			[
				69019,
				57227
			],
			[
				-19,
				-24
			]
		],
		[
			[
				69000,
				57203
			],
			[
				-141,
				-304
			],
			[
				4,
				-206
			]
		],
		[
			[
				86853,
				56022
			],
			[
				-89,
				688
			],
			[
				31,
				254
			],
			[
				-223,
				211
			],
			[
				-19,
				120
			]
		],
		[
			[
				86553,
				57295
			],
			[
				-175,
				172
			],
			[
				-147,
				20
			],
			[
				-110,
				-168
			]
		],
		[
			[
				86121,
				57319
			],
			[
				12,
				-83
			],
			[
				-146,
				-219
			],
			[
				-152,
				-318
			],
			[
				-95,
				-59
			]
		],
		[
			[
				79283,
				55968
			],
			[
				133,
				-5
			],
			[
				45,
				157
			],
			[
				89,
				-20
			]
		],
		[
			[
				79550,
				56100
			],
			[
				106,
				355
			],
			[
				-29,
				86
			],
			[
				59,
				163
			],
			[
				103,
				93
			]
		],
		[
			[
				79789,
				56797
			],
			[
				12,
				101
			],
			[
				-92,
				125
			],
			[
				-109,
				55
			],
			[
				-51,
				119
			]
		],
		[
			[
				79549,
				57197
			],
			[
				-52,
				-307
			],
			[
				-105,
				-155
			],
			[
				-19,
				-236
			],
			[
				-132,
				77
			],
			[
				-63,
				-147
			],
			[
				-60,
				73
			],
			[
				-42,
				-201
			],
			[
				-111,
				74
			]
		],
		[
			[
				78965,
				56375
			],
			[
				96,
				-191
			],
			[
				185,
				-106
			],
			[
				37,
				-110
			]
		],
		[
			[
				97059,
				29277
			],
			[
				86,
				40
			],
			[
				257,
				-196
			],
			[
				34,
				-256
			],
			[
				148,
				288
			],
			[
				-63,
				194
			],
			[
				-169,
				63
			],
			[
				-249,
				-65
			],
			[
				-44,
				-68
			]
		],
		[
			[
				67994,
				64504
			],
			[
				354,
				-48
			],
			[
				7,
				168
			]
		],
		[
			[
				68419,
				66070
			],
			[
				-321,
				41
			],
			[
				2,
				57
			]
		],
		[
			[
				68100,
				66168
			],
			[
				-193,
				24
			]
		],
		[
			[
				67907,
				66192
			],
			[
				-50,
				-1113
			]
		],
		[
			[
				67857,
				65079
			],
			[
				59,
				-120
			],
			[
				-17,
				-389
			],
			[
				95,
				-66
			]
		],
		[
			[
				34744,
				18302
			],
			[
				-49,
				655
			],
			[
				32,
				8
			],
			[
				-88,
				1045
			],
			[
				82,
				186
			],
			[
				-8,
				108
			]
		],
		[
			[
				34713,
				20304
			],
			[
				-143,
				-2
			],
			[
				-57,
				-66
			],
			[
				-158,
				91
			],
			[
				-90,
				-38
			],
			[
				-21,
				244
			],
			[
				-98,
				-23
			],
			[
				-19,
				221
			],
			[
				-255,
				-60
			],
			[
				-206,
				118
			],
			[
				-237,
				390
			],
			[
				-13,
				164
			],
			[
				-124,
				-31
			],
			[
				-30,
				357
			],
			[
				-173,
				-43
			]
		],
		[
			[
				33089,
				21626
			],
			[
				-412,
				-123
			]
		],
		[
			[
				32677,
				21503
			],
			[
				58,
				-668
			],
			[
				206,
				52
			],
			[
				57,
				-657
			],
			[
				190,
				48
			],
			[
				15,
				-164
			],
			[
				191,
				49
			],
			[
				14,
				-165
			],
			[
				190,
				47
			],
			[
				53,
				-660
			],
			[
				27,
				7
			],
			[
				24,
				-331
			],
			[
				66,
				18
			],
			[
				27,
				-336
			],
			[
				129,
				28
			],
			[
				36,
				-318
			]
		],
		[
			[
				52438,
				41462
			],
			[
				12,
				-1342
			]
		],
		[
			[
				61010,
				52244
			],
			[
				-9,
				-625
			]
		],
		[
			[
				61001,
				51619
			],
			[
				385,
				-7
			]
		],
		[
			[
				37440,
				69220
			],
			[
				383,
				65
			],
			[
				379,
				36
			],
			[
				39,
				-642
			],
			[
				34,
				6
			],
			[
				6,
				-353
			],
			[
				144,
				-2647
			]
		],
		[
			[
				40157,
				65969
			],
			[
				191,
				32
			],
			[
				-49,
				1007
			],
			[
				230,
				32
			],
			[
				-33,
				626
			],
			[
				384,
				48
			],
			[
				-16,
				334
			]
		],
		[
			[
				40864,
				68048
			],
			[
				-388,
				-51
			],
			[
				-33,
				666
			],
			[
				-83,
				-10
			],
			[
				-79,
				1650
			],
			[
				-90,
				-13
			]
		],
		[
			[
				38173,
				71704
			],
			[
				-937,
				-148
			],
			[
				100,
				-1695
			],
			[
				68,
				11
			],
			[
				36,
				-652
			]
		],
		[
			[
				45405,
				16885
			],
			[
				508,
				53
			]
		],
		[
			[
				47180,
				17055
			],
			[
				34,
				302
			],
			[
				123,
				437
			],
			[
				-34,
				106
			],
			[
				89,
				112
			],
			[
				-18,
				174
			],
			[
				61,
				-3
			],
			[
				27,
				143
			],
			[
				137,
				-70
			],
			[
				-38,
				160
			]
		],
		[
			[
				47649,
				19211
			],
			[
				-151,
				57
			],
			[
				-55,
				110
			],
			[
				-74,
				-76
			],
			[
				-72,
				44
			],
			[
				-159,
				-53
			],
			[
				-40,
				168
			],
			[
				-138,
				239
			]
		],
		[
			[
				46960,
				19700
			],
			[
				-35,
				-5
			],
			[
				10,
				-330
			],
			[
				-386,
				-36
			],
			[
				31,
				-986
			],
			[
				-644,
				-67
			],
			[
				12,
				-330
			],
			[
				-580,
				-60
			]
		],
		[
			[
				80024,
				55957
			],
			[
				238,
				-104
			],
			[
				231,
				-41
			]
		],
		[
			[
				80493,
				55812
			],
			[
				163,
				58
			],
			[
				225,
				209
			],
			[
				37,
				93
			]
		],
		[
			[
				80918,
				56172
			],
			[
				0,
				286
			],
			[
				59,
				479
			]
		],
		[
			[
				80977,
				56937
			],
			[
				-43,
				123
			]
		],
		[
			[
				80934,
				57060
			],
			[
				-55,
				53
			],
			[
				-93,
				-76
			],
			[
				-196,
				77
			],
			[
				-96,
				-161
			],
			[
				-211,
				-141
			],
			[
				-201,
				-287
			]
		],
		[
			[
				80082,
				56525
			],
			[
				90,
				-433
			],
			[
				-148,
				-135
			]
		],
		[
			[
				56677,
				27018
			],
			[
				249,
				-3
			]
		],
		[
			[
				56926,
				27015
			],
			[
				1105,
				-37
			]
		],
		[
			[
				58031,
				26978
			],
			[
				-48,
				353
			],
			[
				48,
				75
			],
			[
				-138,
				325
			],
			[
				17,
				85
			]
		],
		[
			[
				57335,
				27738
			],
			[
				-64,
				-32
			],
			[
				-88,
				-232
			],
			[
				-93,
				-116
			],
			[
				-229,
				-104
			],
			[
				-47,
				-130
			],
			[
				-137,
				-106
			]
		],
		[
			[
				56819,
				19781
			],
			[
				185,
				170
			],
			[
				167,
				-117
			],
			[
				133,
				204
			]
		],
		[
			[
				57304,
				20038
			],
			[
				-72,
				153
			],
			[
				26,
				309
			],
			[
				875,
				-28
			]
		],
		[
			[
				58222,
				21745
			],
			[
				-918,
				33
			],
			[
				129,
				174
			]
		],
		[
			[
				56852,
				21973
			],
			[
				-22,
				-1940
			],
			[
				-11,
				-252
			]
		],
		[
			[
				78740,
				39377
			],
			[
				67,
				-13
			],
			[
				-50,
				-705
			],
			[
				19,
				-33
			]
		],
		[
			[
				78776,
				38626
			],
			[
				263,
				-137
			],
			[
				282,
				-71
			]
		],
		[
			[
				79476,
				39228
			],
			[
				37,
				498
			],
			[
				-100,
				23
			],
			[
				26,
				344
			]
		],
		[
			[
				78960,
				40192
			],
			[
				-38,
				-563
			],
			[
				-162,
				32
			],
			[
				-20,
				-284
			]
		],
		[
			[
				35758,
				44731
			],
			[
				1168,
				243
			]
		],
		[
			[
				36592,
				46091
			],
			[
				-52,
				223
			]
		],
		[
			[
				36540,
				46314
			],
			[
				-84,
				-197
			],
			[
				-161,
				-23
			],
			[
				-106,
				228
			],
			[
				-94,
				-139
			],
			[
				-81,
				51
			],
			[
				-150,
				-224
			],
			[
				15,
				-102
			],
			[
				-105,
				-213
			],
			[
				-369,
				-75
			],
			[
				-13,
				-138
			],
			[
				-94,
				-96
			],
			[
				-47,
				-332
			]
		],
		[
			[
				84134,
				55862
			],
			[
				104,
				1254
			]
		],
		[
			[
				84238,
				57116
			],
			[
				-378,
				126
			]
		],
		[
			[
				83860,
				57242
			],
			[
				-526,
				177
			]
		],
		[
			[
				83334,
				57419
			],
			[
				-121,
				-1589
			]
		],
		[
			[
				76873,
				37698
			],
			[
				485,
				-108
			]
		],
		[
			[
				77358,
				37590
			],
			[
				24,
				-6
			],
			[
				50,
				648
			],
			[
				81,
				121
			],
			[
				20,
				229
			],
			[
				66,
				-14
			],
			[
				59,
				654
			]
		],
		[
			[
				77182,
				39359
			],
			[
				-57,
				-608
			],
			[
				-167,
				33
			]
		],
		[
			[
				76958,
				38784
			],
			[
				-85,
				-1086
			]
		],
		[
			[
				45867,
				13636
			],
			[
				1359,
				129
			]
		],
		[
			[
				47226,
				13765
			],
			[
				583,
				55
			]
		],
		[
			[
				47809,
				13820
			],
			[
				-19,
				658
			],
			[
				-132,
				-12
			],
			[
				-39,
				1315
			]
		],
		[
			[
				47619,
				15781
			],
			[
				-116,
				-11
			],
			[
				-19,
				647
			],
			[
				-329,
				-26
			]
		],
		[
			[
				46723,
				15939
			],
			[
				-131,
				-116
			],
			[
				-16,
				-291
			],
			[
				-57,
				-136
			],
			[
				-48,
				-290
			],
			[
				112,
				-175
			],
			[
				-82,
				-144
			],
			[
				-329,
				95
			],
			[
				-98,
				-48
			],
			[
				-186,
				148
			],
			[
				-201,
				-187
			],
			[
				-102,
				-20
			],
			[
				-5,
				-124
			],
			[
				-256,
				-35
			]
		],
		[
			[
				45324,
				14616
			],
			[
				-83,
				-199
			],
			[
				18,
				-389
			],
			[
				-38,
				-102
			],
			[
				48,
				-106
			],
			[
				-108,
				-85
			],
			[
				-82,
				155
			]
		],
		[
			[
				45079,
				13890
			],
			[
				13,
				-340
			],
			[
				775,
				86
			]
		],
		[
			[
				79661,
				62392
			],
			[
				124,
				-233
			],
			[
				-20,
				-194
			],
			[
				100,
				-798
			]
		],
		[
			[
				79865,
				61167
			],
			[
				121,
				127
			],
			[
				41,
				149
			],
			[
				251,
				173
			],
			[
				72,
				137
			],
			[
				106,
				8
			]
		],
		[
			[
				80456,
				61761
			],
			[
				288,
				63
			],
			[
				112,
				71
			]
		],
		[
			[
				80856,
				61895
			],
			[
				-80,
				337
			],
			[
				-34,
				20
			],
			[
				-80,
				350
			],
			[
				-183,
				299
			],
			[
				-57,
				394
			]
		],
		[
			[
				80422,
				63295
			],
			[
				-284,
				-303
			],
			[
				-164,
				-79
			],
			[
				-26,
				-102
			],
			[
				-140,
				-166
			]
		],
		[
			[
				79808,
				62645
			],
			[
				-147,
				-253
			]
		],
		[
			[
				50464,
				23921
			],
			[
				1157,
				62
			]
		],
		[
			[
				50444,
				26255
			],
			[
				17,
				-1006
			],
			[
				-18,
				-1
			]
		],
		[
			[
				59409,
				71003
			],
			[
				2,
				-929
			],
			[
				74,
				2
			],
			[
				76,
				-543
			],
			[
				54,
				-179
			]
		],
		[
			[
				60076,
				69299
			],
			[
				0,
				279
			],
			[
				261,
				-2
			]
		],
		[
			[
				60337,
				69576
			],
			[
				7,
				1385
			]
		],
		[
			[
				59878,
				70983
			],
			[
				-469,
				20
			]
		],
		[
			[
				13489,
				40953
			],
			[
				110,
				-720
			]
		],
		[
			[
				14009,
				39495
			],
			[
				410,
				1055
			]
		],
		[
			[
				14419,
				40550
			],
			[
				-75,
				156
			],
			[
				-42,
				227
			],
			[
				70,
				447
			],
			[
				-81,
				319
			],
			[
				-110,
				19
			],
			[
				9,
				114
			],
			[
				-75,
				125
			]
		],
		[
			[
				86715,
				95839
			],
			[
				127,
				-684
			],
			[
				-17,
				414
			],
			[
				-70,
				295
			]
		],
		[
			[
				86755,
				95864
			],
			[
				-40,
				-25
			]
		],
		[
			[
				85340,
				94487
			],
			[
				-60,
				-651
			]
		],
		[
			[
				86792,
				93427
			],
			[
				46,
				502
			],
			[
				-7,
				675
			],
			[
				-39,
				-205
			],
			[
				-75,
				-65
			],
			[
				-84,
				129
			],
			[
				-36,
				318
			],
			[
				-41,
				102
			],
			[
				16,
				171
			],
			[
				-41,
				146
			],
			[
				7,
				255
			],
			[
				48,
				245
			],
			[
				43,
				40
			],
			[
				-112,
				333
			]
		],
		[
			[
				86517,
				96073
			],
			[
				-60,
				283
			],
			[
				-151,
				82
			],
			[
				9,
				98
			]
		],
		[
			[
				86315,
				96536
			],
			[
				-97,
				2
			],
			[
				-239,
				140
			],
			[
				-155,
				196
			],
			[
				-143,
				-108
			],
			[
				-90,
				60
			]
		],
		[
			[
				85591,
				96826
			],
			[
				-74,
				-697
			],
			[
				-22,
				7
			],
			[
				-155,
				-1649
			]
		],
		[
			[
				73572,
				61773
			],
			[
				237,
				-38
			]
		],
		[
			[
				73809,
				61735
			],
			[
				199,
				-39
			]
		],
		[
			[
				74008,
				61696
			],
			[
				-119,
				611
			],
			[
				19,
				277
			],
			[
				-51,
				358
			],
			[
				-67,
				190
			]
		],
		[
			[
				21344,
				22922
			],
			[
				18,
				178
			],
			[
				63,
				25
			],
			[
				74,
				200
			],
			[
				228,
				29
			]
		],
		[
			[
				21727,
				23354
			],
			[
				314,
				118
			]
		],
		[
			[
				21793,
				25412
			],
			[
				-99,
				-101
			],
			[
				-39,
				-267
			],
			[
				-196,
				-494
			],
			[
				25,
				-178
			],
			[
				-64,
				-293
			],
			[
				-100,
				-67
			],
			[
				-78,
				-311
			],
			[
				-70,
				-62
			]
		],
		[
			[
				21172,
				23639
			],
			[
				97,
				-663
			],
			[
				75,
				-54
			]
		],
		[
			[
				62338,
				43104
			],
			[
				1003,
				-58
			]
		],
		[
			[
				63376,
				44387
			],
			[
				11,
				339
			],
			[
				-764,
				61
			]
		],
		[
			[
				62623,
				44787
			],
			[
				-27,
				-201
			],
			[
				-91,
				-95
			],
			[
				-35,
				-152
			],
			[
				42,
				-146
			],
			[
				-28,
				-115
			]
		],
		[
			[
				62484,
				44078
			],
			[
				-111,
				-343
			],
			[
				-36,
				-351
			],
			[
				1,
				-280
			]
		],
		[
			[
				53888,
				73642
			],
			[
				380,
				17
			],
			[
				326,
				-9
			]
		],
		[
			[
				54594,
				73650
			],
			[
				1177,
				4
			]
		],
		[
			[
				55771,
				73654
			],
			[
				5,
				228
			],
			[
				-77,
				235
			],
			[
				49,
				127
			],
			[
				-30,
				209
			],
			[
				36,
				45
			]
		],
		[
			[
				55754,
				74498
			],
			[
				60,
				196
			]
		],
		[
			[
				55814,
				74694
			],
			[
				-1177,
				307
			]
		],
		[
			[
				54637,
				75001
			],
			[
				-4,
				-26
			]
		],
		[
			[
				54633,
				74975
			],
			[
				-21,
				-281
			],
			[
				-97,
				-34
			],
			[
				9,
				-253
			],
			[
				-102,
				7
			],
			[
				22,
				-217
			],
			[
				-78,
				31
			],
			[
				-69,
				-166
			],
			[
				-98,
				-23
			],
			[
				-44,
				-169
			],
			[
				-138,
				-112
			]
		],
		[
			[
				18486,
				8616
			],
			[
				59,
				-366
			],
			[
				152,
				-108
			],
			[
				103,
				-240
			],
			[
				507,
				205
			],
			[
				48,
				-317
			],
			[
				234,
				-232
			],
			[
				93,
				41
			],
			[
				126,
				-400
			],
			[
				166,
				-212
			],
			[
				47,
				-320
			],
			[
				142,
				-55
			],
			[
				56,
				-151
			],
			[
				178,
				75
			]
		],
		[
			[
				20397,
				6536
			],
			[
				3,
				73
			]
		],
		[
			[
				20400,
				6609
			],
			[
				-371,
				2510
			]
		],
		[
			[
				19168,
				10807
			],
			[
				-31,
				220
			],
			[
				-125,
				-58
			]
		],
		[
			[
				18356,
				10883
			],
			[
				-82,
				-222
			],
			[
				-5,
				-243
			]
		],
		[
			[
				18269,
				10418
			],
			[
				112,
				-265
			],
			[
				-33,
				-178
			],
			[
				46,
				-314
			],
			[
				-43,
				-521
			],
			[
				91,
				-175
			],
			[
				44,
				-349
			]
		],
		[
			[
				90787,
				22360
			],
			[
				237,
				-152
			],
			[
				-57,
				-255
			],
			[
				289,
				-159
			],
			[
				43,
				147
			],
			[
				79,
				-119
			]
		],
		[
			[
				91378,
				21822
			],
			[
				114,
				-173
			],
			[
				12,
				68
			],
			[
				125,
				-12
			],
			[
				35,
				84
			],
			[
				-10,
				350
			],
			[
				204,
				48
			],
			[
				-37,
				393
			],
			[
				-25,
				-6
			],
			[
				23,
				372
			],
			[
				115,
				56
			],
			[
				11,
				448
			],
			[
				-118,
				-53
			],
			[
				-45,
				151
			],
			[
				22,
				174
			]
		],
		[
			[
				91804,
				23722
			],
			[
				-615,
				204
			]
		],
		[
			[
				91189,
				23926
			],
			[
				-100,
				-793
			],
			[
				-111,
				-197
			],
			[
				-29,
				-140
			],
			[
				-108,
				57
			],
			[
				-11,
				221
			],
			[
				-53,
				-37
			],
			[
				-29,
				-192
			],
			[
				39,
				-485
			]
		],
		[
			[
				56700,
				25349
			],
			[
				404,
				-10
			]
		],
		[
			[
				57117,
				26009
			],
			[
				-208,
				7
			],
			[
				17,
				999
			]
		],
		[
			[
				56677,
				27018
			],
			[
				-137,
				-156
			]
		],
		[
			[
				55751,
				26107
			],
			[
				-195,
				-205
			]
		],
		[
			[
				55556,
				25902
			],
			[
				-2,
				-529
			],
			[
				371,
				-7
			]
		],
		[
			[
				14481,
				11363
			],
			[
				207,
				-1213
			]
		],
		[
			[
				14688,
				10150
			],
			[
				1071,
				500
			]
		],
		[
			[
				15759,
				10650
			],
			[
				-204,
				1266
			]
		],
		[
			[
				15555,
				11916
			],
			[
				-133,
				-62
			],
			[
				-150,
				964
			],
			[
				101,
				91
			],
			[
				-5,
				183
			]
		],
		[
			[
				15368,
				13092
			],
			[
				-339,
				-38
			],
			[
				-107,
				-102
			],
			[
				-92,
				10
			],
			[
				-109,
				123
			]
		],
		[
			[
				14721,
				13085
			],
			[
				-310,
				112
			],
			[
				-127,
				-39
			],
			[
				-117,
				63
			]
		],
		[
			[
				14167,
				13221
			],
			[
				314,
				-1858
			]
		],
		[
			[
				81895,
				39473
			],
			[
				136,
				-159
			],
			[
				-45,
				-37
			],
			[
				15,
				-600
			],
			[
				80,
				-185
			],
			[
				-31,
				-349
			],
			[
				-115,
				-61
			],
			[
				-14,
				-165
			],
			[
				65,
				-78
			],
			[
				24,
				-248
			]
		],
		[
			[
				82010,
				37591
			],
			[
				92,
				-11
			],
			[
				166,
				347
			],
			[
				126,
				116
			],
			[
				72,
				-42
			]
		],
		[
			[
				82466,
				38001
			],
			[
				12,
				176
			],
			[
				107,
				74
			],
			[
				118,
				-18
			],
			[
				298,
				69
			],
			[
				73,
				139
			],
			[
				101,
				-71
			],
			[
				50,
				-170
			],
			[
				83,
				69
			]
		],
		[
			[
				83308,
				38269
			],
			[
				-91,
				463
			]
		],
		[
			[
				83217,
				38732
			],
			[
				-143,
				727
			],
			[
				-63,
				11
			],
			[
				-93,
				312
			]
		],
		[
			[
				82918,
				39782
			],
			[
				-83,
				10
			],
			[
				-34,
				-107
			],
			[
				-235,
				-199
			],
			[
				-164,
				288
			],
			[
				-270,
				-100
			],
			[
				-222,
				71
			]
		],
		[
			[
				81910,
				39745
			],
			[
				-54,
				-83
			],
			[
				81,
				-113
			],
			[
				-42,
				-76
			]
		],
		[
			[
				84540,
				34906
			],
			[
				61,
				15
			],
			[
				25,
				-241
			],
			[
				101,
				-224
			],
			[
				41,
				279
			],
			[
				153,
				-65
			],
			[
				125,
				250
			],
			[
				204,
				-4
			],
			[
				241,
				423
			],
			[
				266,
				-185
			],
			[
				296,
				-326
			]
		],
		[
			[
				86053,
				34828
			],
			[
				-162,
				585
			],
			[
				-112,
				284
			]
		],
		[
			[
				85779,
				35697
			],
			[
				-113,
				130
			],
			[
				-358,
				489
			]
		],
		[
			[
				85308,
				36316
			],
			[
				-139,
				87
			],
			[
				-103,
				-61
			],
			[
				-175,
				263
			],
			[
				-304,
				-87
			]
		],
		[
			[
				84587,
				36518
			],
			[
				-399,
				154
			]
		],
		[
			[
				84188,
				36672
			],
			[
				-36,
				-65
			],
			[
				112,
				-429
			],
			[
				55,
				-30
			],
			[
				24,
				-233
			],
			[
				215,
				-241
			],
			[
				-35,
				-280
			],
			[
				-88,
				-299
			],
			[
				105,
				-189
			]
		],
		[
			[
				83857,
				62817
			],
			[
				414,
				-771
			],
			[
				111,
				-4
			],
			[
				24,
				-210
			],
			[
				118,
				-67
			]
		],
		[
			[
				84571,
				61779
			],
			[
				61,
				11
			],
			[
				119,
				198
			]
		],
		[
			[
				84751,
				61988
			],
			[
				-26,
				185
			],
			[
				137,
				304
			],
			[
				37,
				393
			],
			[
				328,
				492
			],
			[
				109,
				66
			],
			[
				-12,
				76
			]
		],
		[
			[
				85324,
				63504
			],
			[
				-57,
				-46
			],
			[
				-246,
				230
			],
			[
				-264,
				-50
			],
			[
				-166,
				25
			],
			[
				-142,
				-41
			],
			[
				-84,
				-159
			],
			[
				-75,
				12
			]
		],
		[
			[
				84290,
				63475
			],
			[
				-128,
				35
			],
			[
				-75,
				-215
			]
		],
		[
			[
				84087,
				63295
			],
			[
				132,
				-200
			],
			[
				-217,
				-171
			]
		],
		[
			[
				84002,
				62924
			],
			[
				-145,
				-107
			]
		],
		[
			[
				72295,
				60347
			],
			[
				8,
				-357
			],
			[
				-36,
				-489
			]
		],
		[
			[
				72267,
				59501
			],
			[
				-13,
				-218
			],
			[
				65,
				-70
			]
		],
		[
			[
				72319,
				59213
			],
			[
				123,
				142
			],
			[
				194,
				38
			],
			[
				90,
				-83
			]
		],
		[
			[
				72726,
				59310
			],
			[
				86,
				319
			],
			[
				140,
				167
			]
		],
		[
			[
				72952,
				59796
			],
			[
				16,
				550
			],
			[
				-25,
				363
			]
		],
		[
			[
				72943,
				60709
			],
			[
				-234,
				-169
			],
			[
				-223,
				-33
			],
			[
				-179,
				144
			]
		],
		[
			[
				72307,
				60651
			],
			[
				-46,
				-201
			],
			[
				34,
				-103
			]
		],
		[
			[
				73973,
				58559
			],
			[
				45,
				-71
			],
			[
				25,
				-397
			],
			[
				-53,
				0
			],
			[
				-55,
				-246
			]
		],
		[
			[
				73935,
				57845
			],
			[
				0,
				-472
			],
			[
				167,
				-28
			],
			[
				83,
				-168
			]
		],
		[
			[
				74185,
				57177
			],
			[
				118,
				-147
			],
			[
				48,
				57
			],
			[
				172,
				-46
			]
		],
		[
			[
				74523,
				57041
			],
			[
				153,
				250
			],
			[
				222,
				302
			],
			[
				94,
				316
			]
		],
		[
			[
				74611,
				58555
			],
			[
				-197,
				100
			],
			[
				-28,
				-93
			],
			[
				-379,
				98
			]
		],
		[
			[
				74007,
				58660
			],
			[
				1,
				-5
			]
		],
		[
			[
				74008,
				58655
			],
			[
				-40,
				-73
			]
		],
		[
			[
				73968,
				58582
			],
			[
				5,
				-23
			]
		],
		[
			[
				27314,
				35953
			],
			[
				520,
				-861
			],
			[
				344,
				101
			],
			[
				118,
				-77
			],
			[
				32,
				-259
			],
			[
				118,
				63
			]
		],
		[
			[
				28446,
				34920
			],
			[
				18,
				114
			],
			[
				224,
				153
			],
			[
				65,
				-143
			],
			[
				307,
				63
			]
		],
		[
			[
				29060,
				35107
			],
			[
				40,
				164
			],
			[
				85,
				113
			]
		],
		[
			[
				29185,
				35384
			],
			[
				-58,
				264
			],
			[
				-117,
				212
			],
			[
				-159,
				-91
			],
			[
				-36,
				103
			],
			[
				-119,
				77
			],
			[
				-145,
				-186
			],
			[
				-145,
				39
			],
			[
				-19,
				223
			]
		],
		[
			[
				28387,
				36025
			],
			[
				-184,
				-106
			],
			[
				-355,
				-105
			],
			[
				-534,
				139
			]
		],
		[
			[
				71828,
				60652
			],
			[
				63,
				-38
			],
			[
				90,
				143
			],
			[
				125,
				-127
			],
			[
				189,
				-283
			]
		],
		[
			[
				72307,
				60651
			],
			[
				19,
				143
			],
			[
				-76,
				264
			],
			[
				2,
				403
			]
		],
		[
			[
				72252,
				61461
			],
			[
				-92,
				10
			],
			[
				-66,
				-402
			],
			[
				-137,
				-199
			],
			[
				-123,
				-52
			],
			[
				-6,
				-166
			]
		],
		[
			[
				73753,
				59478
			],
			[
				245,
				-644
			],
			[
				9,
				-174
			]
		],
		[
			[
				74298,
				59795
			],
			[
				-58,
				23
			],
			[
				-78,
				409
			]
		],
		[
			[
				74162,
				60227
			],
			[
				-353,
				-468
			],
			[
				-71,
				-177
			],
			[
				15,
				-104
			]
		],
		[
			[
				54999,
				69604
			],
			[
				1,
				-201
			]
		],
		[
			[
				56018,
				69707
			],
			[
				4,
				10
			]
		],
		[
			[
				56018,
				69721
			],
			[
				-64,
				61
			],
			[
				-106,
				-38
			],
			[
				-187,
				93
			],
			[
				-154,
				-42
			],
			[
				-165,
				221
			],
			[
				-106,
				-12
			],
			[
				-103,
				198
			],
			[
				-140,
				134
			]
		],
		[
			[
				72357,
				58235
			],
			[
				245,
				-70
			]
		],
		[
			[
				72602,
				58165
			],
			[
				248,
				356
			],
			[
				12,
				68
			]
		],
		[
			[
				72862,
				58589
			],
			[
				-47,
				75
			],
			[
				-106,
				360
			],
			[
				17,
				286
			]
		],
		[
			[
				72319,
				59213
			],
			[
				25,
				-358
			],
			[
				13,
				-620
			]
		],
		[
			[
				50554,
				81246
			],
			[
				877,
				1301
			]
		],
		[
			[
				51105,
				83173
			],
			[
				-245,
				396
			]
		],
		[
			[
				50860,
				83569
			],
			[
				-56,
				-370
			],
			[
				-507,
				-745
			]
		],
		[
			[
				55968,
				80753
			],
			[
				55,
				-1361
			]
		],
		[
			[
				56268,
				79523
			],
			[
				133,
				162
			],
			[
				-7,
				337
			],
			[
				60,
				136
			],
			[
				95,
				-87
			],
			[
				91,
				283
			],
			[
				-41,
				104
			],
			[
				123,
				7
			],
			[
				237,
				112
			],
			[
				-11,
				202
			]
		],
		[
			[
				56343,
				81359
			],
			[
				-276,
				-229
			],
			[
				-99,
				-377
			]
		],
		[
			[
				69064,
				46357
			],
			[
				104,
				-251
			],
			[
				-58,
				-150
			]
		],
		[
			[
				69731,
				45861
			],
			[
				17,
				335
			]
		],
		[
			[
				69311,
				47288
			],
			[
				17,
				-184
			],
			[
				-120,
				-115
			],
			[
				-4,
				-265
			],
			[
				-135,
				-255
			],
			[
				-5,
				-112
			]
		],
		[
			[
				55767,
				40703
			],
			[
				765,
				-8
			]
		],
		[
			[
				56563,
				41949
			],
			[
				-484,
				-5
			]
		],
		[
			[
				56079,
				41944
			],
			[
				-289,
				-4
			]
		],
		[
			[
				55790,
				41940
			],
			[
				4,
				-569
			],
			[
				-25,
				-1
			],
			[
				-2,
				-667
			]
		],
		[
			[
				61915,
				40771
			],
			[
				25,
				828
			]
		],
		[
			[
				61940,
				41599
			],
			[
				-22,
				-57
			],
			[
				-359,
				57
			]
		],
		[
			[
				47625,
				43910
			],
			[
				26,
				2
			]
		],
		[
			[
				73897,
				45707
			],
			[
				119,
				-187
			],
			[
				224,
				237
			]
		],
		[
			[
				74328,
				46798
			],
			[
				-70,
				111
			]
		],
		[
			[
				74007,
				46650
			],
			[
				4,
				-105
			],
			[
				-146,
				-37
			]
		],
		[
			[
				73865,
				46508
			],
			[
				45,
				-231
			],
			[
				-92,
				-264
			]
		],
		[
			[
				95368,
				28609
			],
			[
				111,
				14
			],
			[
				113,
				592
			]
		],
		[
			[
				95592,
				29215
			],
			[
				-93,
				200
			],
			[
				-84,
				-312
			],
			[
				-51,
				-295
			],
			[
				-35,
				41
			],
			[
				85,
				503
			],
			[
				-94,
				39
			],
			[
				2,
				118
			],
			[
				-97,
				21
			],
			[
				57,
				-491
			],
			[
				-9,
				-165
			],
			[
				95,
				-265
			]
		],
		[
			[
				95164,
				29555
			],
			[
				-30,
				-368
			],
			[
				40,
				-17
			],
			[
				41,
				273
			],
			[
				-51,
				112
			]
		],
		[
			[
				17404,
				19626
			],
			[
				-376,
				-163
			],
			[
				46,
				-308
			],
			[
				-188,
				-83
			],
			[
				26,
				-182
			],
			[
				-561,
				-246
			],
			[
				72,
				-468
			]
		],
		[
			[
				16423,
				18176
			],
			[
				114,
				-765
			],
			[
				54,
				-180
			]
		],
		[
			[
				20901,
				11527
			],
			[
				-61,
				-7
			],
			[
				17,
				438
			],
			[
				-42,
				121
			]
		],
		[
			[
				20797,
				12053
			],
			[
				-133,
				-106
			],
			[
				-115,
				56
			],
			[
				-114,
				-112
			],
			[
				-264,
				254
			],
			[
				-75,
				324
			],
			[
				-135,
				157
			],
			[
				-62,
				-25
			],
			[
				-64,
				215
			],
			[
				-165,
				109
			],
			[
				-176,
				-20
			],
			[
				-128,
				126
			]
		],
		[
			[
				32677,
				17592
			],
			[
				12,
				-136
			]
		],
		[
			[
				32677,
				21503
			],
			[
				-255,
				-65
			],
			[
				59,
				-668
			],
			[
				14,
				3
			],
			[
				143,
				-1649
			],
			[
				-111,
				-29
			],
			[
				116,
				-1316
			],
			[
				34,
				-187
			]
		],
		[
			[
				27631,
				17080
			],
			[
				57,
				20
			],
			[
				187,
				-247
			],
			[
				60,
				71
			],
			[
				105,
				-122
			],
			[
				61,
				-670
			],
			[
				378,
				116
			],
			[
				17,
				-163
			]
		],
		[
			[
				28496,
				16085
			],
			[
				729,
				226
			]
		],
		[
			[
				29225,
				16311
			],
			[
				-71,
				91
			],
			[
				9,
				227
			]
		],
		[
			[
				29163,
				16629
			],
			[
				-98,
				-12
			],
			[
				-77,
				127
			],
			[
				-147,
				-45
			],
			[
				-35,
				328
			],
			[
				-115,
				-35
			],
			[
				-67,
				174
			],
			[
				-87,
				-8
			],
			[
				1,
				228
			],
			[
				-179,
				190
			],
			[
				-99,
				11
			]
		],
		[
			[
				27632,
				17086
			],
			[
				-1,
				-6
			]
		],
		[
			[
				29163,
				16629
			],
			[
				112,
				13
			],
			[
				-41,
				173
			],
			[
				65,
				188
			],
			[
				-30,
				199
			],
			[
				46,
				603
			],
			[
				-34,
				186
			],
			[
				207,
				257
			],
			[
				103,
				177
			]
		],
		[
			[
				72492,
				57700
			],
			[
				131,
				-28
			],
			[
				42,
				-99
			],
			[
				174,
				34
			],
			[
				91,
				-163
			]
		],
		[
			[
				72930,
				57444
			],
			[
				69,
				194
			],
			[
				162,
				22
			],
			[
				87,
				177
			]
		],
		[
			[
				73248,
				57837
			],
			[
				-22,
				173
			],
			[
				70,
				327
			],
			[
				-70,
				217
			]
		],
		[
			[
				73226,
				58554
			],
			[
				-129,
				-49
			],
			[
				-235,
				84
			]
		],
		[
			[
				72602,
				58165
			],
			[
				-110,
				-465
			]
		],
		[
			[
				56364,
				71034
			],
			[
				153,
				-138
			],
			[
				200,
				-19
			],
			[
				85,
				185
			],
			[
				133,
				160
			]
		],
		[
			[
				56318,
				71542
			],
			[
				-2,
				-426
			]
		],
		[
			[
				57681,
				78692
			],
			[
				623,
				-272
			]
		],
		[
			[
				58304,
				78420
			],
			[
				164,
				-79
			],
			[
				7,
				-61
			]
		],
		[
			[
				58475,
				78280
			],
			[
				243,
				-100
			]
		],
		[
			[
				58718,
				78180
			],
			[
				97,
				805
			],
			[
				-33,
				2701
			]
		],
		[
			[
				58782,
				81686
			],
			[
				-417,
				19
			]
		],
		[
			[
				58365,
				81705
			],
			[
				-7,
				-158
			],
			[
				69,
				-229
			],
			[
				-73,
				-320
			],
			[
				87,
				-385
			]
		],
		[
			[
				58441,
				80613
			],
			[
				-43,
				-188
			],
			[
				70,
				-296
			],
			[
				-74,
				-137
			],
			[
				-65,
				9
			],
			[
				-44,
				-384
			],
			[
				-60,
				-25
			],
			[
				-53,
				-507
			],
			[
				-78,
				-135
			],
			[
				-5,
				-186
			],
			[
				-79,
				-105
			],
			[
				-246,
				59
			],
			[
				-83,
				-26
			]
		],
		[
			[
				28343,
				46291
			],
			[
				-4,
				39
			],
			[
				1300,
				352
			],
			[
				892,
				231
			]
		],
		[
			[
				30583,
				48284
			],
			[
				-1307,
				-337
			],
			[
				-1248,
				-351
			],
			[
				-738,
				-198
			]
		],
		[
			[
				27290,
				47398
			],
			[
				93,
				-1045
			],
			[
				170,
				-254
			]
		],
		[
			[
				85648,
				45706
			],
			[
				103,
				-45
			],
			[
				85,
				-204
			],
			[
				143,
				-124
			],
			[
				165,
				-44
			],
			[
				22,
				-74
			],
			[
				129,
				85
			]
		],
		[
			[
				86295,
				45300
			],
			[
				-10,
				99
			],
			[
				-316,
				965
			]
		],
		[
			[
				85969,
				46364
			],
			[
				-89,
				-54
			],
			[
				-177,
				3
			],
			[
				-173,
				132
			]
		],
		[
			[
				85530,
				46445
			],
			[
				-299,
				-106
			]
		],
		[
			[
				55814,
				74694
			],
			[
				9,
				251
			],
			[
				-55,
				228
			],
			[
				56,
				106
			],
			[
				-42,
				342
			],
			[
				64,
				30
			],
			[
				22,
				282
			],
			[
				115,
				100
			],
			[
				126,
				298
			],
			[
				6,
				247
			]
		],
		[
			[
				56115,
				76578
			],
			[
				-718,
				203
			],
			[
				9,
				56
			],
			[
				-174,
				89
			]
		],
		[
			[
				55232,
				76926
			],
			[
				-23,
				-183
			],
			[
				62,
				-7
			],
			[
				15,
				-238
			],
			[
				-49,
				-146
			],
			[
				-59,
				216
			],
			[
				-38,
				-80
			]
		],
		[
			[
				55140,
				76488
			],
			[
				9,
				-231
			],
			[
				-177,
				-65
			],
			[
				3,
				-225
			],
			[
				-205,
				-125
			],
			[
				15,
				-347
			],
			[
				-95,
				14
			],
			[
				42,
				-151
			],
			[
				-42,
				-171
			],
			[
				-71,
				-81
			],
			[
				18,
				-105
			]
		],
		[
			[
				91208,
				20571
			],
			[
				25,
				-464
			],
			[
				-27,
				-11
			],
			[
				42,
				-348
			],
			[
				65,
				-126
			],
			[
				-60,
				-26
			],
			[
				26,
				-202
			]
		],
		[
			[
				91279,
				19394
			],
			[
				262,
				100
			],
			[
				44,
				-340
			],
			[
				192,
				140
			],
			[
				44,
				-348
			]
		],
		[
			[
				92090,
				20025
			],
			[
				-89,
				-29
			],
			[
				14,
				359
			],
			[
				-244,
				-20
			],
			[
				-66,
				611
			],
			[
				-100,
				-23
			]
		],
		[
			[
				91605,
				20923
			],
			[
				-294,
				-42
			],
			[
				-76,
				-330
			],
			[
				-27,
				20
			]
		],
		[
			[
				84637,
				49049
			],
			[
				72,
				-63
			]
		],
		[
			[
				85171,
				49447
			],
			[
				21,
				202
			],
			[
				-111,
				409
			]
		],
		[
			[
				85081,
				50058
			],
			[
				-80,
				140
			],
			[
				-79,
				-8
			],
			[
				-71,
				114
			]
		],
		[
			[
				84851,
				50304
			],
			[
				-76,
				-106
			],
			[
				-101,
				13
			],
			[
				-36,
				-130
			],
			[
				-214,
				-526
			]
		],
		[
			[
				84424,
				49555
			],
			[
				213,
				-506
			]
		],
		[
			[
				82618,
				51297
			],
			[
				52,
				161
			],
			[
				9,
				336
			],
			[
				-74,
				170
			],
			[
				-80,
				342
			]
		],
		[
			[
				82525,
				52306
			],
			[
				-172,
				201
			],
			[
				-65,
				157
			],
			[
				6,
				205
			],
			[
				-87,
				13
			],
			[
				-22,
				159
			]
		],
		[
			[
				82185,
				53041
			],
			[
				-378,
				-766
			]
		],
		[
			[
				94026,
				13355
			],
			[
				215,
				-367
			],
			[
				-87,
				-196
			],
			[
				79,
				-265
			],
			[
				102,
				-201
			],
			[
				-3,
				-209
			],
			[
				-127,
				-19
			],
			[
				30,
				-139
			],
			[
				-64,
				-64
			],
			[
				37,
				-148
			],
			[
				-54,
				-24
			],
			[
				60,
				-356
			],
			[
				-109,
				-124
			],
			[
				44,
				-574
			],
			[
				72,
				-143
			],
			[
				47,
				-222
			],
			[
				55,
				-55
			],
			[
				-45,
				-599
			]
		],
		[
			[
				94278,
				9650
			],
			[
				448,
				-219
			]
		],
		[
			[
				96195,
				14673
			],
			[
				277,
				918
			],
			[
				76,
				309
			]
		],
		[
			[
				96253,
				16160
			],
			[
				-34,
				-92
			],
			[
				-216,
				185
			],
			[
				129,
				238
			],
			[
				-12,
				195
			],
			[
				-279,
				12
			],
			[
				-7,
				127
			],
			[
				-119,
				-119
			],
			[
				-111,
				96
			]
		],
		[
			[
				95604,
				16802
			],
			[
				-88,
				-237
			],
			[
				-53,
				40
			],
			[
				-170,
				-663
			],
			[
				-139,
				122
			],
			[
				-88,
				-320
			],
			[
				60,
				-51
			],
			[
				-184,
				-632
			],
			[
				-193,
				166
			],
			[
				-54,
				-186
			],
			[
				-155,
				149
			],
			[
				-370,
				-1285
			],
			[
				18,
				-16
			],
			[
				-162,
				-534
			]
		],
		[
			[
				70356,
				23024
			],
			[
				27,
				-79
			],
			[
				111,
				61
			],
			[
				18,
				289
			],
			[
				-57,
				6
			],
			[
				-84,
				-143
			],
			[
				-15,
				-134
			]
		],
		[
			[
				71110,
				24292
			],
			[
				-276,
				53
			]
		],
		[
			[
				70427,
				24404
			],
			[
				-19,
				-487
			],
			[
				136,
				-26
			],
			[
				61,
				-252
			],
			[
				104,
				95
			],
			[
				96,
				-59
			],
			[
				71,
				-403
			],
			[
				92,
				-177
			],
			[
				75,
				-376
			],
			[
				117,
				-112
			],
			[
				22,
				123
			],
			[
				-118,
				213
			],
			[
				93,
				246
			],
			[
				-110,
				348
			],
			[
				66,
				-78
			],
			[
				20,
				244
			],
			[
				-61,
				215
			],
			[
				38,
				374
			]
		],
		[
			[
				70242,
				23544
			],
			[
				52,
				-156
			],
			[
				65,
				57
			],
			[
				-78,
				144
			],
			[
				-39,
				-45
			]
		],
		[
			[
				29140,
				65790
			],
			[
				-196,
				-85
			]
		],
		[
			[
				28944,
				65705
			],
			[
				139,
				-1612
			],
			[
				-1363,
				-355
			],
			[
				91,
				-1002
			]
		],
		[
			[
				24513,
				68660
			],
			[
				1173,
				338
			],
			[
				759,
				232
			],
			[
				4,
				-37
			],
			[
				1309,
				342
			]
		],
		[
			[
				27758,
				69535
			],
			[
				-30,
				328
			]
		],
		[
			[
				27728,
				69863
			],
			[
				-54,
				675
			],
			[
				-184,
				1968
			]
		],
		[
			[
				25670,
				73199
			],
			[
				-2624,
				-2596
			],
			[
				-809,
				-811
			]
		],
		[
			[
				22237,
				69792
			],
			[
				190,
				-1761
			]
		],
		[
			[
				33813,
				51038
			],
			[
				-334,
				-56
			],
			[
				-1313,
				-286
			],
			[
				-265,
				511
			]
		],
		[
			[
				93381,
				29855
			],
			[
				80,
				-106
			]
		],
		[
			[
				93461,
				29749
			],
			[
				130,
				-71
			],
			[
				-31,
				-138
			],
			[
				91,
				-173
			],
			[
				22,
				-157
			]
		],
		[
			[
				94462,
				29329
			],
			[
				89,
				678
			],
			[
				-65,
				47
			],
			[
				54,
				245
			],
			[
				-36,
				112
			]
		],
		[
			[
				94504,
				30411
			],
			[
				-147,
				-4
			],
			[
				-111,
				132
			],
			[
				-103,
				34
			],
			[
				-69,
				111
			],
			[
				-83,
				-56
			],
			[
				-39,
				142
			],
			[
				-201,
				132
			]
		],
		[
			[
				93751,
				30902
			],
			[
				-129,
				-362
			],
			[
				-100,
				-111
			],
			[
				200,
				-135
			],
			[
				-83,
				-354
			],
			[
				-168,
				112
			],
			[
				-90,
				-197
			]
		],
		[
			[
				83161,
				48632
			],
			[
				71,
				-385
			],
			[
				-72,
				-40
			]
		],
		[
			[
				83160,
				48207
			],
			[
				47,
				-266
			],
			[
				79,
				-214
			],
			[
				80,
				-422
			]
		],
		[
			[
				83366,
				47305
			],
			[
				580,
				498
			],
			[
				55,
				74
			]
		],
		[
			[
				84001,
				47877
			],
			[
				36,
				113
			],
			[
				-26,
				229
			]
		],
		[
			[
				84011,
				48219
			],
			[
				-110,
				-4
			],
			[
				-98,
				338
			],
			[
				-34,
				296
			],
			[
				-142,
				216
			]
		],
		[
			[
				83554,
				49417
			],
			[
				-156,
				-141
			],
			[
				2,
				-59
			],
			[
				-216,
				-195
			],
			[
				-23,
				-390
			]
		],
		[
			[
				18381,
				8404
			],
			[
				6,
				144
			],
			[
				99,
				68
			]
		],
		[
			[
				18269,
				10418
			],
			[
				-798,
				-360
			],
			[
				50,
				-318
			],
			[
				-186,
				-88
			],
			[
				49,
				-318
			],
			[
				-581,
				-263
			],
			[
				-47,
				-336
			],
			[
				-227,
				-491
			],
			[
				-126,
				14
			],
			[
				-19,
				-73
			]
		],
		[
			[
				16384,
				8185
			],
			[
				-22,
				-138
			],
			[
				178,
				-31
			],
			[
				10,
				-246
			],
			[
				-63,
				-76
			],
			[
				68,
				-197
			],
			[
				-128,
				-107
			],
			[
				-11,
				-244
			],
			[
				97,
				-223
			],
			[
				202,
				-130
			],
			[
				170,
				-258
			],
			[
				188,
				-40
			]
		],
		[
			[
				44156,
				52701
			],
			[
				13,
				-364
			]
		],
		[
			[
				44971,
				53775
			],
			[
				-851,
				-92
			]
		],
		[
			[
				77306,
				68010
			],
			[
				25,
				183
			]
		],
		[
			[
				77331,
				68193
			],
			[
				147,
				233
			],
			[
				99,
				407
			]
		],
		[
			[
				77577,
				68833
			],
			[
				-305,
				472
			]
		],
		[
			[
				77272,
				69305
			],
			[
				-431,
				89
			]
		],
		[
			[
				76841,
				69394
			],
			[
				-21,
				-316
			]
		],
		[
			[
				59392,
				45214
			],
			[
				-33,
				85
			],
			[
				20,
				255
			]
		],
		[
			[
				59379,
				45554
			],
			[
				-819,
				53
			]
		],
		[
			[
				66063,
				37377
			],
			[
				-769,
				93
			]
		],
		[
			[
				65294,
				37470
			],
			[
				-44,
				-1217
			]
		],
		[
			[
				73378,
				32939
			],
			[
				747,
				-167
			]
		],
		[
			[
				74140,
				32768
			],
			[
				98,
				1349
			]
		],
		[
			[
				73859,
				34190
			],
			[
				-565,
				129
			]
		],
		[
			[
				73294,
				34319
			],
			[
				-109,
				-1339
			]
		],
		[
			[
				77414,
				48555
			],
			[
				82,
				223
			],
			[
				-140,
				278
			],
			[
				-58,
				181
			]
		],
		[
			[
				77298,
				49237
			],
			[
				-299,
				56
			],
			[
				-149,
				-194
			],
			[
				-1,
				-179
			]
		],
		[
			[
				70360,
				52905
			],
			[
				-20,
				-248
			],
			[
				97,
				-326
			]
		],
		[
			[
				70437,
				52331
			],
			[
				135,
				-285
			],
			[
				44,
				13
			],
			[
				181,
				-224
			],
			[
				-93,
				-121
			],
			[
				69,
				-124
			]
		],
		[
			[
				70773,
				51590
			],
			[
				338,
				238
			]
		],
		[
			[
				71111,
				51828
			],
			[
				7,
				164
			],
			[
				96,
				197
			]
		],
		[
			[
				71214,
				52189
			],
			[
				-121,
				91
			],
			[
				125,
				564
			]
		],
		[
			[
				71218,
				52844
			],
			[
				-238,
				393
			],
			[
				-105,
				-78
			],
			[
				-11,
				184
			],
			[
				-110,
				288
			]
		],
		[
			[
				59222,
				74015
			],
			[
				270,
				-11
			]
		],
		[
			[
				59492,
				74004
			],
			[
				81,
				1
			]
		],
		[
			[
				59729,
				75165
			],
			[
				-100,
				-209
			],
			[
				-66,
				-2
			],
			[
				-192,
				-426
			],
			[
				56,
				-114
			],
			[
				-54,
				-235
			],
			[
				-108,
				-43
			],
			[
				-43,
				-121
			]
		],
		[
			[
				96173,
				9423
			],
			[
				185,
				-92
			],
			[
				8,
				48
			],
			[
				383,
				-193
			],
			[
				216,
				1240
			],
			[
				77,
				353
			],
			[
				225,
				1344
			],
			[
				32,
				6
			],
			[
				506,
				-528
			]
		],
		[
			[
				54589,
				26373
			],
			[
				776,
				0
			],
			[
				16,
				336
			]
		],
		[
			[
				55386,
				28038
			],
			[
				-751,
				-1
			]
		],
		[
			[
				54635,
				28037
			],
			[
				-24,
				-1
			]
		],
		[
			[
				55331,
				11485
			],
			[
				389,
				-9
			]
		],
		[
			[
				57093,
				12102
			],
			[
				29,
				1329
			],
			[
				5,
				647
			]
		],
		[
			[
				57127,
				14078
			],
			[
				18,
				1522
			]
		],
		[
			[
				57145,
				15600
			],
			[
				1,
				125
			],
			[
				-386,
				16
			]
		],
		[
			[
				55980,
				15747
			],
			[
				-8,
				-989
			],
			[
				-17,
				-756
			],
			[
				-80,
				-40
			],
			[
				-48,
				-227
			],
			[
				72,
				-291
			],
			[
				-533,
				3
			]
		],
		[
			[
				55366,
				13447
			],
			[
				-15,
				0
			],
			[
				-3,
				-577
			]
		],
		[
			[
				67067,
				64672
			],
			[
				-13,
				-337
			]
		],
		[
			[
				67054,
				64335
			],
			[
				-2,
				-55
			],
			[
				286,
				-38
			]
		],
		[
			[
				67980,
				64167
			],
			[
				14,
				337
			]
		],
		[
			[
				67857,
				65079
			],
			[
				-189,
				23
			],
			[
				-3,
				-55
			],
			[
				-580,
				72
			]
		],
		[
			[
				64694,
				70999
			],
			[
				385,
				-41
			],
			[
				-10,
				-329
			],
			[
				158,
				-22
			]
		],
		[
			[
				65227,
				70607
			],
			[
				48,
				-3
			],
			[
				711,
				428
			]
		],
		[
			[
				65986,
				71032
			],
			[
				-135,
				569
			],
			[
				-79,
				127
			],
			[
				-380,
				264
			],
			[
				-58,
				160
			],
			[
				-124,
				136
			],
			[
				-86,
				12
			]
		],
		[
			[
				64939,
				72573
			],
			[
				-69,
				-141
			],
			[
				-125,
				12
			],
			[
				-7,
				-168
			],
			[
				-124,
				-100
			]
		],
		[
			[
				64614,
				72176
			],
			[
				51,
				-72
			],
			[
				-62,
				-93
			]
		],
		[
			[
				59915,
				25902
			],
			[
				267,
				406
			],
			[
				34,
				131
			],
			[
				322,
				24
			],
			[
				51,
				59
			]
		],
		[
			[
				60589,
				26522
			],
			[
				59,
				239
			],
			[
				67,
				85
			]
		],
		[
			[
				69164,
				80888
			],
			[
				196,
				-33
			],
			[
				-82,
				124
			],
			[
				-114,
				-91
			]
		],
		[
			[
				68657,
				80846
			],
			[
				107,
				-58
			],
			[
				252,
				112
			],
			[
				-163,
				6
			],
			[
				-196,
				-60
			]
		],
		[
			[
				69260,
				78883
			],
			[
				99,
				1393
			]
		],
		[
			[
				69359,
				80276
			],
			[
				-202,
				212
			],
			[
				-65,
				-75
			],
			[
				-85,
				58
			],
			[
				-39,
				-93
			],
			[
				-124,
				-4
			],
			[
				-157,
				76
			],
			[
				-134,
				-91
			]
		],
		[
			[
				68553,
				80359
			],
			[
				-139,
				-147
			],
			[
				-46,
				-987
			]
		],
		[
			[
				68368,
				79225
			],
			[
				-10,
				-223
			]
		],
		[
			[
				31091,
				21746
			],
			[
				134,
				-1320
			],
			[
				187,
				51
			],
			[
				58,
				-656
			],
			[
				95,
				26
			],
			[
				24,
				-248
			],
			[
				97,
				26
			],
			[
				76,
				-736
			],
			[
				65,
				-765
			],
			[
				84,
				-766
			]
		],
		[
			[
				31911,
				17358
			],
			[
				573,
				155
			],
			[
				193,
				79
			]
		],
		[
			[
				33089,
				21626
			],
			[
				-53,
				625
			]
		],
		[
			[
				20356,
				29951
			],
			[
				1045,
				408
			],
			[
				1702,
				617
			],
			[
				445,
				156
			]
		],
		[
			[
				23548,
				31132
			],
			[
				338,
				92
			],
			[
				373,
				158
			],
			[
				512,
				173
			]
		],
		[
			[
				24771,
				31555
			],
			[
				388,
				131
			]
		],
		[
			[
				25159,
				31686
			],
			[
				-43,
				412
			],
			[
				-380,
				3347
			]
		],
		[
			[
				24736,
				35445
			],
			[
				-383,
				3341
			]
		],
		[
			[
				24353,
				38786
			],
			[
				-802,
				-276
			],
			[
				-1608,
				-593
			],
			[
				-554,
				-198
			]
		],
		[
			[
				21389,
				37719
			],
			[
				-275,
				-98
			],
			[
				-4,
				-2128
			],
			[
				158,
				-1257
			],
			[
				-701,
				-262
			]
		],
		[
			[
				20567,
				33974
			],
			[
				-704,
				-268
			]
		],
		[
			[
				19863,
				33706
			],
			[
				493,
				-3755
			]
		],
		[
			[
				90313,
				31098
			],
			[
				145,
				-223
			],
			[
				34,
				95
			],
			[
				214,
				4
			],
			[
				-30,
				-119
			],
			[
				149,
				79
			],
			[
				157,
				-100
			]
		],
		[
			[
				90982,
				30834
			],
			[
				-35,
				281
			],
			[
				67,
				304
			]
		],
		[
			[
				91014,
				31419
			],
			[
				79,
				143
			],
			[
				-19,
				285
			]
		],
		[
			[
				90756,
				32694
			],
			[
				-246,
				-142
			]
		],
		[
			[
				90510,
				32552
			],
			[
				-610,
				-369
			]
		],
		[
			[
				89769,
				31967
			],
			[
				-31,
				-260
			],
			[
				443,
				-214
			],
			[
				48,
				-235
			],
			[
				84,
				-160
			]
		],
		[
			[
				86517,
				96073
			],
			[
				29,
				70
			],
			[
				140,
				-170
			],
			[
				29,
				-134
			]
		],
		[
			[
				86755,
				95864
			],
			[
				-109,
				487
			],
			[
				-37,
				280
			],
			[
				-360,
				866
			],
			[
				-154,
				258
			],
			[
				-26,
				-33
			],
			[
				298,
				-616
			],
			[
				41,
				-183
			],
			[
				17,
				-377
			],
			[
				-110,
				-10
			]
		],
		[
			[
				85919,
				97958
			],
			[
				89,
				-224
			],
			[
				40,
				97
			],
			[
				-83,
				167
			],
			[
				-46,
				-40
			]
		],
		[
			[
				85214,
				98724
			],
			[
				354,
				-394
			],
			[
				52,
				85
			],
			[
				-304,
				347
			],
			[
				-102,
				-38
			]
		],
		[
			[
				84146,
				94816
			],
			[
				1194,
				-329
			]
		],
		[
			[
				85591,
				96826
			],
			[
				-73,
				162
			],
			[
				-355,
				176
			],
			[
				-139,
				-134
			],
			[
				-83,
				-202
			],
			[
				10,
				-425
			],
			[
				52,
				-37
			],
			[
				-138,
				-441
			],
			[
				-94,
				-133
			],
			[
				0,
				-111
			],
			[
				-97,
				-223
			],
			[
				-68,
				-40
			],
			[
				-63,
				-264
			],
			[
				-122,
				24
			],
			[
				-96,
				-307
			],
			[
				-179,
				-55
			]
		],
		[
			[
				83862,
				99608
			],
			[
				105,
				-374
			],
			[
				302,
				-372
			],
			[
				157,
				-255
			],
			[
				104,
				-106
			],
			[
				301,
				142
			],
			[
				41,
				160
			],
			[
				112,
				109
			],
			[
				-305,
				274
			],
			[
				-30,
				-81
			],
			[
				-193,
				68
			],
			[
				-12,
				84
			],
			[
				-321,
				322
			],
			[
				-255,
				116
			],
			[
				-6,
				-87
			]
		],
		[
			[
				83457,
				99810
			],
			[
				252,
				-176
			],
			[
				-36,
				182
			],
			[
				-212,
				75
			],
			[
				-4,
				-81
			]
		],
		[
			[
				83145,
				99811
			],
			[
				34,
				-123
			],
			[
				81,
				-1
			],
			[
				-13,
				152
			],
			[
				-102,
				-28
			]
		],
		[
			[
				81555,
				99934
			],
			[
				106,
				-66
			],
			[
				40,
				63
			],
			[
				-143,
				68
			],
			[
				-3,
				-65
			]
		],
		[
			[
				73063,
				77297
			],
			[
				123,
				2350
			]
		],
		[
			[
				73186,
				79647
			],
			[
				-455,
				10
			],
			[
				-318,
				91
			]
		],
		[
			[
				72413,
				79748
			],
			[
				-105,
				-2333
			]
		],
		[
			[
				80201,
				77348
			],
			[
				-858,
				102
			]
		],
		[
			[
				79343,
				77450
			],
			[
				-43,
				-631
			],
			[
				67,
				-244
			],
			[
				130,
				-33
			]
		],
		[
			[
				75005,
				61483
			],
			[
				62,
				-14
			]
		],
		[
			[
				75067,
				61469
			],
			[
				278,
				-59
			]
		],
		[
			[
				74912,
				62873
			],
			[
				-67,
				-152
			],
			[
				101,
				-26
			],
			[
				-92,
				-101
			],
			[
				126,
				-19
			],
			[
				16,
				-138
			],
			[
				-59,
				-133
			],
			[
				82,
				-395
			],
			[
				-54,
				-149
			],
			[
				40,
				-277
			]
		],
		[
			[
				65532,
				33910
			],
			[
				58,
				-6
			]
		],
		[
			[
				65590,
				33904
			],
			[
				696,
				-66
			]
		],
		[
			[
				71989,
				48741
			],
			[
				66,
				-13
			]
		],
		[
			[
				72276,
				49611
			],
			[
				-102,
				-3
			],
			[
				-13,
				-287
			],
			[
				-68,
				-45
			],
			[
				-83,
				-200
			],
			[
				-21,
				-335
			]
		],
		[
			[
				48616,
				41343
			],
			[
				-27,
				1286
			]
		],
		[
			[
				48589,
				42629
			],
			[
				-763,
				-53
			]
		],
		[
			[
				52472,
				37438
			],
			[
				192,
				5
			]
		],
		[
			[
				52664,
				37443
			],
			[
				-13,
				1382
			]
		],
		[
			[
				44086,
				55211
			],
			[
				-24,
				-2
			]
		],
		[
			[
				79335,
				57719
			],
			[
				129,
				-157
			],
			[
				85,
				-365
			]
		],
		[
			[
				79789,
				56797
			],
			[
				52,
				-200
			]
		],
		[
			[
				79841,
				56597
			],
			[
				91,
				272
			],
			[
				-13,
				146
			],
			[
				-105,
				184
			],
			[
				239,
				279
			],
			[
				128,
				531
			]
		],
		[
			[
				80181,
				58009
			],
			[
				-22,
				134
			],
			[
				-227,
				127
			],
			[
				-61,
				-91
			],
			[
				-253,
				196
			],
			[
				-36,
				-30
			]
		],
		[
			[
				79582,
				58345
			],
			[
				-156,
				-85
			],
			[
				-82,
				-104
			],
			[
				38,
				-112
			],
			[
				-75,
				-240
			],
			[
				28,
				-85
			]
		],
		[
			[
				56943,
				78276
			],
			[
				200,
				160
			],
			[
				227,
				62
			],
			[
				111,
				101
			]
		],
		[
			[
				57481,
				78599
			],
			[
				-182,
				185
			],
			[
				231,
				1853
			]
		],
		[
			[
				57530,
				80637
			],
			[
				17,
				137
			],
			[
				-375,
				13
			]
		],
		[
			[
				69302,
				70169
			],
			[
				-33,
				321
			],
			[
				67,
				193
			],
			[
				-16,
				93
			],
			[
				211,
				183
			],
			[
				-69,
				44
			],
			[
				55,
				224
			],
			[
				-49,
				88
			],
			[
				120,
				-27
			],
			[
				-48,
				282
			],
			[
				69,
				100
			],
			[
				198,
				-152
			],
			[
				183,
				52
			],
			[
				-93,
				29
			],
			[
				85,
				262
			]
		],
		[
			[
				69982,
				71861
			],
			[
				-87,
				47
			],
			[
				-126,
				272
			],
			[
				-101,
				107
			],
			[
				-17,
				220
			],
			[
				147,
				105
			],
			[
				82,
				118
			]
		],
		[
			[
				34603,
				52049
			],
			[
				617,
				125
			]
		],
		[
			[
				35220,
				52174
			],
			[
				-8,
				117
			],
			[
				731,
				129
			],
			[
				2,
				-30
			]
		],
		[
			[
				34596,
				53690
			],
			[
				-107,
				-22
			]
		],
		[
			[
				34489,
				53668
			],
			[
				114,
				-1619
			]
		],
		[
			[
				86845,
				57580
			],
			[
				161,
				6
			],
			[
				51,
				-89
			],
			[
				212,
				4
			],
			[
				75,
				84
			],
			[
				91,
				-153
			]
		],
		[
			[
				87692,
				58012
			],
			[
				114,
				99
			]
		],
		[
			[
				87806,
				58111
			],
			[
				65,
				174
			],
			[
				35,
				785
			]
		],
		[
			[
				87906,
				59070
			],
			[
				-555,
				203
			],
			[
				-53,
				-47
			],
			[
				-166,
				95
			]
		],
		[
			[
				87132,
				59321
			],
			[
				-31,
				72
			],
			[
				-132,
				-80
			],
			[
				26,
				-656
			],
			[
				-97,
				-665
			],
			[
				-28,
				-43
			],
			[
				-25,
				-369
			]
		],
		[
			[
				47809,
				13820
			],
			[
				585,
				45
			]
		],
		[
			[
				48472,
				15849
			],
			[
				-853,
				-68
			]
		],
		[
			[
				45422,
				10936
			],
			[
				389,
				40
			],
			[
				-24,
				661
			],
			[
				76,
				7
			],
			[
				-48,
				1320
			],
			[
				77,
				8
			],
			[
				-25,
				664
			]
		],
		[
			[
				45079,
				13890
			],
			[
				-263,
				-115
			],
			[
				-115,
				-179
			]
		],
		[
			[
				44701,
				13596
			],
			[
				10,
				-315
			],
			[
				153,
				-325
			],
			[
				-77,
				-196
			],
			[
				-89,
				-28
			],
			[
				11,
				-97
			],
			[
				-140,
				-87
			],
			[
				0,
				-132
			],
			[
				-101,
				11
			]
		],
		[
			[
				44468,
				12427
			],
			[
				41,
				-941
			],
			[
				-87,
				-9
			],
			[
				28,
				-656
			]
		],
		[
			[
				55163,
				58992
			],
			[
				256,
				0
			],
			[
				91,
				127
			],
			[
				-6,
				209
			],
			[
				235,
				-2
			]
		],
		[
			[
				55739,
				59326
			],
			[
				415,
				-3
			]
		],
		[
			[
				56056,
				60333
			],
			[
				-171,
				-33
			],
			[
				-107,
				89
			],
			[
				-103,
				-17
			],
			[
				-153,
				64
			],
			[
				-48,
				88
			],
			[
				-87,
				-86
			],
			[
				-23,
				-266
			],
			[
				-208,
				2
			]
		],
		[
			[
				55156,
				60174
			],
			[
				-96,
				3
			]
		],
		[
			[
				46603,
				25550
			],
			[
				178,
				105
			],
			[
				172,
				-159
			],
			[
				140,
				-40
			],
			[
				46,
				78
			],
			[
				130,
				-16
			]
		],
		[
			[
				47547,
				26399
			],
			[
				-94,
				320
			],
			[
				68,
				72
			],
			[
				99,
				-53
			],
			[
				138,
				90
			],
			[
				25,
				326
			],
			[
				209,
				131
			],
			[
				232,
				83
			],
			[
				157,
				165
			],
			[
				98,
				15
			],
			[
				47,
				97
			],
			[
				-78,
				174
			]
		],
		[
			[
				47761,
				27869
			],
			[
				-1085,
				-91
			]
		],
		[
			[
				46676,
				27778
			],
			[
				-191,
				-16
			],
			[
				21,
				-670
			],
			[
				19,
				1
			],
			[
				43,
				-1327
			],
			[
				35,
				-216
			]
		],
		[
			[
				54593,
				71801
			],
			[
				1,
				1849
			]
		],
		[
			[
				51175,
				90286
			],
			[
				-274,
				411
			],
			[
				-27,
				949
			]
		],
		[
			[
				50874,
				91646
			],
			[
				-234,
				-9
			],
			[
				-14,
				1426
			]
		],
		[
			[
				56025,
				71316
			],
			[
				-665,
				14
			]
		],
		[
			[
				55360,
				71330
			],
			[
				-4,
				-73
			],
			[
				-363,
				1
			]
		],
		[
			[
				87081,
				50868
			],
			[
				333,
				-565
			]
		],
		[
			[
				87414,
				50303
			],
			[
				368,
				-594
			]
		],
		[
			[
				87782,
				49709
			],
			[
				206,
				123
			],
			[
				149,
				-19
			],
			[
				70,
				409
			]
		],
		[
			[
				88207,
				50222
			],
			[
				-258,
				436
			],
			[
				-465,
				744
			]
		],
		[
			[
				87484,
				51402
			],
			[
				-110,
				-15
			],
			[
				-8,
				-556
			],
			[
				-82,
				98
			],
			[
				-118,
				14
			],
			[
				-85,
				-75
			]
		],
		[
			[
				81379,
				53411
			],
			[
				89,
				-105
			],
			[
				-50,
				177
			]
		],
		[
			[
				81418,
				53483
			],
			[
				-39,
				-72
			]
		],
		[
			[
				87197,
				60731
			],
			[
				39,
				-70
			],
			[
				210,
				88
			]
		],
		[
			[
				87446,
				60749
			],
			[
				39,
				140
			],
			[
				144,
				190
			],
			[
				-4,
				114
			],
			[
				77,
				291
			],
			[
				48,
				354
			],
			[
				-4,
				228
			],
			[
				38,
				153
			]
		],
		[
			[
				87784,
				62219
			],
			[
				-9,
				298
			],
			[
				-87,
				8
			],
			[
				-34,
				-105
			],
			[
				-299,
				-5
			],
			[
				-368,
				167
			],
			[
				-263,
				284
			]
		],
		[
			[
				86724,
				62866
			],
			[
				-236,
				-291
			]
		],
		[
			[
				78151,
				57069
			],
			[
				80,
				-35
			],
			[
				63,
				91
			],
			[
				52,
				-130
			],
			[
				5,
				-188
			],
			[
				235,
				-312
			],
			[
				60,
				86
			]
		],
		[
			[
				78646,
				56581
			],
			[
				9,
				280
			],
			[
				98,
				29
			],
			[
				86,
				-114
			]
		],
		[
			[
				78839,
				56776
			],
			[
				54,
				102
			],
			[
				-13,
				154
			],
			[
				86,
				28
			],
			[
				95,
				284
			]
		],
		[
			[
				79061,
				57344
			],
			[
				-183,
				102
			],
			[
				-328,
				413
			],
			[
				-155,
				162
			],
			[
				-80,
				-45
			],
			[
				-55,
				106
			]
		],
		[
			[
				78260,
				58082
			],
			[
				-81,
				-240
			],
			[
				-57,
				33
			],
			[
				-38,
				-194
			]
		],
		[
			[
				78084,
				57681
			],
			[
				85,
				-334
			],
			[
				-18,
				-278
			]
		],
		[
			[
				76766,
				35784
			],
			[
				149,
				219
			],
			[
				238,
				125
			],
			[
				205,
				-233
			]
		],
		[
			[
				77358,
				35895
			],
			[
				56,
				550
			]
		],
		[
			[
				77414,
				36445
			],
			[
				-826,
				175
			]
		],
		[
			[
				77376,
				45552
			],
			[
				92,
				-12
			],
			[
				16,
				349
			],
			[
				191,
				-23
			]
		],
		[
			[
				77675,
				45866
			],
			[
				10,
				392
			],
			[
				-56,
				5
			],
			[
				4,
				282
			],
			[
				-100,
				14
			],
			[
				67,
				307
			],
			[
				-122,
				132
			]
		],
		[
			[
				77478,
				46998
			],
			[
				-79,
				-148
			],
			[
				-112,
				-531
			],
			[
				-242,
				256
			]
		],
		[
			[
				77045,
				46575
			],
			[
				-106,
				157
			],
			[
				-37,
				190
			],
			[
				-212,
				76
			]
		],
		[
			[
				76690,
				46998
			],
			[
				-126,
				-1519
			]
		],
		[
			[
				71531,
				49741
			],
			[
				81,
				-192
			],
			[
				26,
				-254
			],
			[
				-51,
				-217
			],
			[
				42,
				-3
			],
			[
				-24,
				-284
			]
		],
		[
			[
				71605,
				48791
			],
			[
				384,
				-50
			]
		],
		[
			[
				72241,
				50281
			],
			[
				-94,
				55
			]
		],
		[
			[
				72147,
				50336
			],
			[
				-77,
				170
			],
			[
				-116,
				-189
			],
			[
				-122,
				30
			],
			[
				-177,
				-186
			],
			[
				-13,
				-271
			],
			[
				-111,
				-149
			]
		],
		[
			[
				73865,
				46508
			],
			[
				-462,
				83
			],
			[
				1,
				-103
			]
		],
		[
			[
				54651,
				30709
			],
			[
				310,
				0
			]
		],
		[
			[
				54960,
				31640
			],
			[
				-1122,
				-13
			]
		],
		[
			[
				54966,
				32979
			],
			[
				-1104,
				-2
			]
		],
		[
			[
				53862,
				32977
			],
			[
				61,
				-194
			],
			[
				-15,
				-348
			],
			[
				91,
				-128
			]
		],
		[
			[
				71719,
				55159
			],
			[
				385,
				-680
			]
		],
		[
			[
				72513,
				55591
			],
			[
				-409,
				30
			]
		],
		[
			[
				72104,
				55621
			],
			[
				-366,
				22
			]
		],
		[
			[
				71738,
				55643
			],
			[
				-19,
				-484
			]
		],
		[
			[
				92519,
				30002
			],
			[
				70,
				-48
			],
			[
				34,
				333
			],
			[
				97,
				-69
			],
			[
				74,
				44
			],
			[
				130,
				-191
			]
		],
		[
			[
				92924,
				30071
			],
			[
				80,
				337
			],
			[
				15,
				241
			],
			[
				141,
				-120
			],
			[
				95,
				166
			],
			[
				27,
				211
			],
			[
				165,
				225
			]
		],
		[
			[
				93447,
				31131
			],
			[
				-224,
				51
			],
			[
				-24,
				98
			],
			[
				-109,
				-41
			],
			[
				-233,
				173
			],
			[
				-30,
				-177
			],
			[
				-124,
				417
			],
			[
				-61,
				2
			],
			[
				-71,
				194
			]
		],
		[
			[
				92571,
				31848
			],
			[
				-29,
				-31
			],
			[
				5,
				-367
			],
			[
				26,
				-103
			],
			[
				-257,
				-343
			],
			[
				-201,
				-73
			],
			[
				-32,
				-69
			]
		],
		[
			[
				92083,
				30862
			],
			[
				-33,
				-130
			],
			[
				259,
				-144
			],
			[
				-38,
				-154
			],
			[
				110,
				-99
			],
			[
				33,
				-156
			],
			[
				105,
				-177
			]
		],
		[
			[
				83215,
				93039
			],
			[
				58,
				39
			],
			[
				321,
				-88
			],
			[
				-37,
				-388
			],
			[
				193,
				-56
			],
			[
				-33,
				-339
			]
		],
		[
			[
				83717,
				92207
			],
			[
				580,
				-168
			],
			[
				97,
				987
			],
			[
				774,
				-234
			]
		],
		[
			[
				84146,
				94816
			],
			[
				-342,
				-270
			],
			[
				-118,
				201
			],
			[
				-157,
				-372
			],
			[
				-146,
				-452
			],
			[
				-87,
				-546
			],
			[
				-81,
				-338
			]
		],
		[
			[
				74008,
				61696
			],
			[
				178,
				-41
			]
		],
		[
			[
				74186,
				61655
			],
			[
				33,
				501
			],
			[
				113,
				-23
			],
			[
				21,
				304
			],
			[
				105,
				8
			]
		],
		[
			[
				74458,
				62445
			],
			[
				-31,
				190
			],
			[
				214,
				-41
			],
			[
				26,
				373
			]
		],
		[
			[
				74642,
				63109
			],
			[
				-70,
				13
			]
		],
		[
			[
				74572,
				63122
			],
			[
				-761,
				142
			]
		],
		[
			[
				66191,
				53799
			],
			[
				417,
				-51
			]
		],
		[
			[
				66608,
				53748
			],
			[
				76,
				45
			],
			[
				12,
				121
			],
			[
				-72,
				107
			],
			[
				-27,
				533
			],
			[
				39,
				102
			],
			[
				150,
				103
			]
		],
		[
			[
				66869,
				55082
			],
			[
				-104,
				21
			],
			[
				-17,
				-121
			],
			[
				-117,
				-188
			],
			[
				-85,
				-12
			],
			[
				92,
				169
			],
			[
				-27,
				137
			],
			[
				-64,
				-71
			]
		],
		[
			[
				66199,
				54120
			],
			[
				-48,
				-93
			],
			[
				40,
				-228
			]
		],
		[
			[
				69036,
				49240
			],
			[
				-97,
				214
			],
			[
				-67,
				349
			],
			[
				-51,
				-102
			],
			[
				-41,
				142
			],
			[
				-34,
				-111
			],
			[
				-50,
				245
			],
			[
				-51,
				-69
			]
		],
		[
			[
				68645,
				49908
			],
			[
				-5,
				-10
			]
		],
		[
			[
				68640,
				49898
			],
			[
				62,
				-129
			],
			[
				-64,
				-414
			],
			[
				45,
				-223
			],
			[
				-39,
				-437
			]
		],
		[
			[
				74004,
				64399
			],
			[
				137,
				-25
			],
			[
				74,
				-338
			],
			[
				264,
				-308
			],
			[
				93,
				-606
			]
		],
		[
			[
				74807,
				63832
			],
			[
				-19,
				179
			],
			[
				33,
				512
			],
			[
				-38,
				7
			],
			[
				26,
				449
			]
		],
		[
			[
				74809,
				64979
			],
			[
				-385,
				59
			],
			[
				4,
				83
			],
			[
				-299,
				51
			]
		],
		[
			[
				74129,
				65172
			],
			[
				-125,
				-773
			]
		],
		[
			[
				80713,
				44064
			],
			[
				148,
				13
			]
		],
		[
			[
				78534,
				48072
			],
			[
				115,
				-459
			],
			[
				100,
				-43
			],
			[
				111,
				-146
			]
		],
		[
			[
				78860,
				47424
			],
			[
				25,
				322
			],
			[
				96,
				68
			],
			[
				122,
				-261
			]
		],
		[
			[
				79103,
				47553
			],
			[
				180,
				9
			],
			[
				37,
				347
			],
			[
				-38,
				51
			]
		],
		[
			[
				79282,
				47960
			],
			[
				-54,
				296
			],
			[
				-89,
				-37
			],
			[
				-43,
				410
			],
			[
				77,
				78
			]
		],
		[
			[
				79173,
				48707
			],
			[
				-190,
				89
			],
			[
				-239,
				216
			]
		],
		[
			[
				76170,
				63596
			],
			[
				12,
				176
			]
		],
		[
			[
				76222,
				64342
			],
			[
				-127,
				26
			],
			[
				-44,
				250
			],
			[
				-99,
				80
			],
			[
				9,
				136
			]
		],
		[
			[
				75961,
				64834
			],
			[
				-440,
				73
			]
		],
		[
			[
				75521,
				64907
			],
			[
				-78,
				-1282
			]
		],
		[
			[
				61577,
				42775
			],
			[
				3,
				168
			]
		],
		[
			[
				61580,
				42943
			],
			[
				26,
				1187
			]
		],
		[
			[
				60933,
				43838
			],
			[
				-28,
				-1024
			]
		],
		[
			[
				63937,
				44212
			],
			[
				1000,
				-88
			]
		],
		[
			[
				64937,
				44124
			],
			[
				38,
				594
			],
			[
				127,
				603
			],
			[
				1,
				141
			]
		],
		[
			[
				64715,
				45510
			],
			[
				-252,
				27
			]
		],
		[
			[
				63922,
				44548
			],
			[
				40,
				-194
			]
		],
		[
			[
				71759,
				46329
			],
			[
				4,
				55
			],
			[
				369,
				-119
			],
			[
				104,
				-94
			]
		],
		[
			[
				72163,
				47481
			],
			[
				-173,
				-99
			],
			[
				-68,
				59
			],
			[
				-120,
				-85
			],
			[
				-69,
				94
			],
			[
				-90,
				-66
			],
			[
				-152,
				104
			]
		],
		[
			[
				71491,
				47488
			],
			[
				-61,
				-871
			],
			[
				-63,
				11
			]
		],
		[
			[
				71367,
				46628
			],
			[
				-15,
				-222
			]
		],
		[
			[
				57271,
				32948
			],
			[
				9,
				1011
			]
		],
		[
			[
				57280,
				33959
			],
			[
				4,
				332
			]
		],
		[
			[
				81100,
				67033
			],
			[
				605,
				-1296
			]
		],
		[
			[
				81705,
				65737
			],
			[
				134,
				0
			],
			[
				158,
				118
			]
		],
		[
			[
				81997,
				65855
			],
			[
				32,
				156
			],
			[
				57,
				908
			],
			[
				78,
				143
			]
		],
		[
			[
				82164,
				67062
			],
			[
				-312,
				125
			],
			[
				-343,
				-105
			],
			[
				-111,
				276
			]
		],
		[
			[
				81398,
				67358
			],
			[
				-174,
				-39
			],
			[
				-122,
				-136
			],
			[
				-2,
				-150
			]
		],
		[
			[
				47715,
				65136
			],
			[
				325,
				20
			],
			[
				18,
				-837
			]
		],
		[
			[
				48058,
				64319
			],
			[
				444,
				26
			],
			[
				-22,
				-171
			],
			[
				57,
				4
			],
			[
				4,
				-169
			],
			[
				282,
				18
			],
			[
				8,
				-36
			]
		],
		[
			[
				48831,
				63991
			],
			[
				118,
				-137
			],
			[
				58,
				65
			],
			[
				-47,
				337
			],
			[
				39,
				206
			],
			[
				176,
				13
			],
			[
				14,
				96
			],
			[
				-94,
				26
			],
			[
				-17,
				118
			]
		],
		[
			[
				49078,
				64715
			],
			[
				34,
				184
			],
			[
				-147,
				2
			],
			[
				-112,
				355
			],
			[
				-22,
				412
			],
			[
				62,
				128
			],
			[
				-34,
				70
			]
		],
		[
			[
				48859,
				65866
			],
			[
				-302,
				-483
			],
			[
				-36,
				302
			],
			[
				-141,
				-80
			]
		],
		[
			[
				48380,
				65605
			],
			[
				-190,
				-90
			],
			[
				-40,
				159
			],
			[
				-176,
				-36
			],
			[
				-102,
				-162
			],
			[
				-157,
				-340
			]
		],
		[
			[
				89629,
				37742
			],
			[
				99,
				-176
			],
			[
				-117,
				-122
			],
			[
				48,
				-172
			],
			[
				213,
				105
			],
			[
				110,
				-406
			]
		],
		[
			[
				89982,
				36971
			],
			[
				92,
				41
			],
			[
				19,
				268
			]
		],
		[
			[
				90093,
				37280
			],
			[
				-113,
				270
			]
		],
		[
			[
				89980,
				37550
			],
			[
				-100,
				169
			],
			[
				18,
				270
			]
		],
		[
			[
				89898,
				37989
			],
			[
				-106,
				131
			]
		],
		[
			[
				89792,
				38120
			],
			[
				-97,
				-28
			],
			[
				21,
				-230
			],
			[
				-87,
				-120
			]
		],
		[
			[
				86497,
				38507
			],
			[
				146,
				-356
			],
			[
				196,
				-139
			],
			[
				-53,
				-104
			],
			[
				103,
				-152
			]
		],
		[
			[
				86889,
				37756
			],
			[
				193,
				127
			],
			[
				83,
				195
			]
		],
		[
			[
				88150,
				39297
			],
			[
				-550,
				197
			]
		],
		[
			[
				87600,
				39494
			],
			[
				-363,
				130
			]
		],
		[
			[
				87237,
				39624
			],
			[
				-354,
				127
			]
		],
		[
			[
				86883,
				39751
			],
			[
				-51,
				-420
			],
			[
				64,
				-123
			],
			[
				-68,
				-154
			],
			[
				14,
				-136
			],
			[
				-122,
				-267
			],
			[
				-223,
				-144
			]
		],
		[
			[
				74980,
				46563
			],
			[
				36,
				77
			],
			[
				284,
				72
			]
		],
		[
			[
				75300,
				46712
			],
			[
				122,
				-87
			],
			[
				134,
				44
			]
		],
		[
			[
				75556,
				46669
			],
			[
				-101,
				701
			]
		],
		[
			[
				66329,
				82466
			],
			[
				141,
				-5
			],
			[
				51,
				90
			]
		],
		[
			[
				66521,
				82551
			],
			[
				109,
				275
			],
			[
				83,
				-41
			],
			[
				55,
				149
			],
			[
				167,
				12
			],
			[
				47,
				135
			],
			[
				113,
				123
			],
			[
				184,
				19
			]
		],
		[
			[
				67279,
				83223
			],
			[
				1,
				83
			],
			[
				-252,
				50
			],
			[
				128,
				111
			],
			[
				3,
				107
			],
			[
				-165,
				-144
			],
			[
				90,
				178
			],
			[
				-81,
				56
			],
			[
				95,
				218
			],
			[
				139,
				21
			],
			[
				85,
				141
			],
			[
				50,
				256
			],
			[
				248,
				-51
			],
			[
				122,
				97
			],
			[
				11,
				-98
			],
			[
				151,
				280
			],
			[
				72,
				-153
			],
			[
				182,
				341
			],
			[
				56,
				230
			],
			[
				123,
				-108
			],
			[
				49,
				129
			],
			[
				-164,
				72
			],
			[
				-11,
				107
			],
			[
				113,
				-1
			],
			[
				-46,
				177
			],
			[
				-80,
				-69
			],
			[
				-70,
				237
			],
			[
				18,
				228
			],
			[
				-153,
				-99
			],
			[
				-3,
				-127
			],
			[
				-71,
				-97
			],
			[
				-202,
				478
			],
			[
				-103,
				146
			],
			[
				130,
				-487
			],
			[
				63,
				-76
			],
			[
				-12,
				-177
			],
			[
				63,
				-85
			],
			[
				-14,
				-166
			],
			[
				-74,
				-68
			],
			[
				-51,
				282
			],
			[
				-115,
				20
			],
			[
				-156,
				-315
			],
			[
				-247,
				-113
			],
			[
				-70,
				-139
			],
			[
				-399,
				-64
			],
			[
				-117,
				105
			]
		],
		[
			[
				66615,
				84736
			],
			[
				-164,
				-574
			],
			[
				-37,
				-639
			],
			[
				-178,
				-446
			],
			[
				-15,
				-344
			],
			[
				77,
				-103
			],
			[
				31,
				-164
			]
		],
		[
			[
				64092,
				74142
			],
			[
				-43,
				-10
			]
		],
		[
			[
				64049,
				74132
			],
			[
				-1,
				-209
			],
			[
				86,
				75
			],
			[
				-42,
				144
			]
		],
		[
			[
				63222,
				73119
			],
			[
				-25,
				-341
			],
			[
				47,
				-169
			]
		],
		[
			[
				63244,
				72609
			],
			[
				606,
				-51
			],
			[
				117,
				-106
			]
		],
		[
			[
				63967,
				72452
			],
			[
				-9,
				62
			],
			[
				124,
				105
			],
			[
				26,
				129
			],
			[
				-196,
				-148
			],
			[
				-23,
				165
			],
			[
				168,
				176
			],
			[
				118,
				-12
			],
			[
				-63,
				259
			],
			[
				213,
				135
			],
			[
				-72,
				151
			],
			[
				-80,
				-16
			],
			[
				18,
				167
			],
			[
				-42,
				153
			],
			[
				-54,
				-81
			],
			[
				-189,
				113
			]
		],
		[
			[
				63906,
				73810
			],
			[
				-23,
				-145
			],
			[
				-346,
				210
			],
			[
				-323,
				24
			]
		],
		[
			[
				63214,
				73899
			],
			[
				-102,
				-18
			],
			[
				57,
				-84
			],
			[
				-29,
				-267
			],
			[
				100,
				-69
			],
			[
				-29,
				-190
			],
			[
				62,
				-13
			],
			[
				-51,
				-139
			]
		],
		[
			[
				70732,
				50585
			],
			[
				73,
				-10
			],
			[
				-12,
				-371
			],
			[
				-78,
				-176
			],
			[
				12,
				-243
			]
		],
		[
			[
				70727,
				49785
			],
			[
				-11,
				-168
			],
			[
				196,
				-29
			],
			[
				-4,
				-58
			]
		],
		[
			[
				70908,
				49530
			],
			[
				188,
				-44
			],
			[
				11,
				226
			],
			[
				192,
				-30
			],
			[
				19,
				329
			]
		],
		[
			[
				71318,
				50011
			],
			[
				54,
				124
			],
			[
				-23,
				145
			],
			[
				-66,
				30
			]
		],
		[
			[
				71283,
				50310
			],
			[
				-55,
				78
			],
			[
				54,
				368
			],
			[
				-141,
				61
			],
			[
				-13,
				246
			],
			[
				-85,
				76
			]
		],
		[
			[
				71043,
				51139
			],
			[
				0,
				-257
			],
			[
				-121,
				81
			],
			[
				-61,
				-67
			],
			[
				-129,
				-311
			]
		],
		[
			[
				55480,
				45521
			],
			[
				382,
				-4
			]
		],
		[
			[
				55862,
				45517
			],
			[
				383,
				-4
			],
			[
				30,
				122
			],
			[
				76,
				20
			]
		],
		[
			[
				56351,
				45655
			],
			[
				-18,
				148
			],
			[
				-73,
				14
			],
			[
				9,
				156
			]
		],
		[
			[
				56269,
				45973
			],
			[
				107,
				256
			],
			[
				124,
				181
			]
		],
		[
			[
				56500,
				46410
			],
			[
				-362,
				5
			]
		],
		[
			[
				56138,
				46415
			],
			[
				-666,
				8
			]
		],
		[
			[
				75387,
				53794
			],
			[
				127,
				-82
			],
			[
				76,
				65
			],
			[
				95,
				-141
			],
			[
				60,
				43
			]
		],
		[
			[
				75745,
				53679
			],
			[
				75,
				381
			],
			[
				159,
				172
			],
			[
				65,
				272
			],
			[
				154,
				122
			]
		],
		[
			[
				76198,
				54626
			],
			[
				-126,
				190
			],
			[
				61,
				211
			]
		],
		[
			[
				76133,
				55027
			],
			[
				-101,
				15
			]
		],
		[
			[
				76032,
				55042
			],
			[
				-423,
				81
			]
		],
		[
			[
				75609,
				55123
			],
			[
				-165,
				-294
			],
			[
				-49,
				-308
			],
			[
				-74,
				-188
			],
			[
				60,
				-97
			],
			[
				-22,
				-221
			],
			[
				28,
				-221
			]
		],
		[
			[
				77478,
				46998
			],
			[
				161,
				13
			],
			[
				118,
				174
			]
		],
		[
			[
				77757,
				47185
			],
			[
				-155,
				318
			],
			[
				-65,
				248
			]
		],
		[
			[
				76898,
				47395
			],
			[
				136,
				-387
			],
			[
				-16,
				-141
			],
			[
				69,
				-215
			],
			[
				-42,
				-77
			]
		],
		[
			[
				71318,
				50011
			],
			[
				100,
				42
			],
			[
				126,
				-210
			],
			[
				-13,
				-102
			]
		],
		[
			[
				72147,
				50336
			],
			[
				69,
				468
			],
			[
				-41,
				230
			],
			[
				-246,
				120
			]
		],
		[
			[
				71929,
				51154
			],
			[
				-177,
				-271
			],
			[
				-469,
				-573
			]
		],
		[
			[
				73310,
				36814
			],
			[
				762,
				-163
			]
		],
		[
			[
				74145,
				37653
			],
			[
				-12,
				-167
			],
			[
				-191,
				39
			],
			[
				-13,
				-168
			],
			[
				-573,
				121
			]
		],
		[
			[
				73356,
				37478
			],
			[
				-5,
				-72
			]
		],
		[
			[
				73351,
				37406
			],
			[
				-41,
				-592
			]
		],
		[
			[
				74185,
				39511
			],
			[
				-37,
				-501
			],
			[
				96,
				-21
			],
			[
				-13,
				-164
			]
		],
		[
			[
				57661,
				75581
			],
			[
				56,
				172
			],
			[
				17,
				357
			],
			[
				35,
				200
			]
		],
		[
			[
				57769,
				76310
			],
			[
				170,
				242
			],
			[
				22,
				240
			],
			[
				-45,
				487
			],
			[
				25,
				366
			],
			[
				-44,
				159
			],
			[
				27,
				147
			]
		],
		[
			[
				57924,
				77951
			],
			[
				-135,
				-294
			],
			[
				-103,
				-69
			],
			[
				-41,
				-240
			],
			[
				-138,
				-77
			],
			[
				-81,
				-148
			],
			[
				-275,
				-43
			],
			[
				-262,
				-262
			]
		],
		[
			[
				56889,
				76818
			],
			[
				-52,
				-195
			],
			[
				-60,
				-5
			],
			[
				-82,
				-452
			],
			[
				-14,
				-325
			],
			[
				64,
				-244
			]
		],
		[
			[
				9366,
				41489
			],
			[
				76,
				3
			],
			[
				63,
				140
			],
			[
				28,
				245
			],
			[
				223,
				198
			],
			[
				94,
				364
			],
			[
				740,
				-43
			]
		],
		[
			[
				10590,
				42396
			],
			[
				-164,
				1021
			],
			[
				78,
				105
			],
			[
				29,
				190
			]
		],
		[
			[
				10533,
				43712
			],
			[
				-1,
				-2
			]
		],
		[
			[
				9547,
				43332
			],
			[
				-80,
				-231
			],
			[
				-11,
				-329
			],
			[
				22,
				-309
			],
			[
				-108,
				-269
			],
			[
				27,
				-114
			],
			[
				-130,
				-184
			],
			[
				89,
				-213
			],
			[
				10,
				-194
			]
		],
		[
			[
				8772,
				26847
			],
			[
				469,
				244
			],
			[
				-54,
				308
			],
			[
				174,
				85
			]
		],
		[
			[
				9635,
				28436
			],
			[
				-155,
				337
			],
			[
				53,
				101
			],
			[
				-26,
				182
			],
			[
				-87,
				220
			],
			[
				-72,
				79
			],
			[
				-103,
				-194
			],
			[
				-104,
				62
			],
			[
				18,
				225
			],
			[
				-24,
				505
			],
			[
				-476,
				2725
			]
		],
		[
			[
				8659,
				32678
			],
			[
				-780,
				-399
			]
		],
		[
			[
				7879,
				32279
			],
			[
				-72,
				-152
			],
			[
				-1,
				-299
			],
			[
				-107,
				-164
			],
			[
				-198,
				-630
			],
			[
				61,
				-185
			],
			[
				10,
				-310
			],
			[
				-28,
				-197
			],
			[
				97,
				-276
			],
			[
				150,
				-313
			],
			[
				330,
				-589
			],
			[
				183,
				-390
			],
			[
				107,
				-357
			],
			[
				-50,
				-128
			],
			[
				51,
				-334
			],
			[
				140,
				-276
			],
			[
				150,
				-507
			],
			[
				70,
				-325
			]
		],
		[
			[
				85500,
				61672
			],
			[
				84,
				-205
			]
		],
		[
			[
				86724,
				62866
			],
			[
				-293,
				292
			],
			[
				-156,
				271
			],
			[
				-186,
				463
			],
			[
				-89,
				292
			]
		],
		[
			[
				86000,
				64184
			],
			[
				-179,
				57
			],
			[
				-104,
				-329
			],
			[
				-112,
				-129
			]
		],
		[
			[
				85605,
				63783
			],
			[
				-136,
				-266
			],
			[
				-69,
				-394
			],
			[
				-46,
				-7
			],
			[
				-121,
				-247
			],
			[
				34,
				-243
			],
			[
				66,
				-86
			],
			[
				73,
				-358
			],
			[
				8,
				-178
			],
			[
				91,
				-213
			],
			[
				-5,
				-119
			]
		],
		[
			[
				78066,
				60765
			],
			[
				171,
				-145
			]
		],
		[
			[
				78237,
				60620
			],
			[
				191,
				-156
			]
		],
		[
			[
				78428,
				60464
			],
			[
				-62,
				279
			],
			[
				56,
				51
			],
			[
				88,
				638
			],
			[
				11,
				231
			],
			[
				86,
				48
			],
			[
				59,
				381
			]
		],
		[
			[
				78666,
				62092
			],
			[
				-230,
				615
			]
		],
		[
			[
				78436,
				62707
			],
			[
				-109,
				-36
			]
		],
		[
			[
				78327,
				62671
			],
			[
				-111,
				-136
			]
		],
		[
			[
				78216,
				62535
			],
			[
				-139,
				-229
			],
			[
				-115,
				-2
			],
			[
				-217,
				-243
			]
		],
		[
			[
				77745,
				62061
			],
			[
				-33,
				-107
			]
		],
		[
			[
				77712,
				61954
			],
			[
				30,
				-293
			],
			[
				91,
				-144
			],
			[
				36,
				-220
			],
			[
				194,
				-300
			],
			[
				3,
				-232
			]
		],
		[
			[
				49323,
				66368
			],
			[
				77,
				140
			],
			[
				186,
				92
			],
			[
				90,
				125
			],
			[
				123,
				-26
			],
			[
				79,
				-117
			],
			[
				69,
				31
			]
		],
		[
			[
				50286,
				66912
			],
			[
				-15,
				953
			]
		],
		[
			[
				49295,
				67826
			],
			[
				28,
				-1458
			]
		],
		[
			[
				72270,
				20166
			],
			[
				31,
				77
			],
			[
				266,
				192
			],
			[
				159,
				159
			],
			[
				70,
				-81
			],
			[
				132,
				-8
			],
			[
				204,
				100
			]
		],
		[
			[
				73132,
				20605
			],
			[
				-74,
				20
			],
			[
				127,
				1620
			]
		],
		[
			[
				72422,
				22391
			],
			[
				-21,
				-333
			]
		],
		[
			[
				67074,
				62744
			],
			[
				328,
				-43
			]
		],
		[
			[
				67054,
				64335
			],
			[
				-96,
				13
			],
			[
				-43,
				-1005
			],
			[
				-97,
				12
			],
			[
				-24,
				-576
			]
		],
		[
			[
				68144,
				67179
			],
			[
				-44,
				-1011
			]
		],
		[
			[
				69022,
				67316
			],
			[
				-140,
				17
			],
			[
				-73,
				241
			],
			[
				-258,
				173
			]
		],
		[
			[
				68551,
				67747
			],
			[
				27,
				-119
			],
			[
				-411,
				54
			],
			[
				-23,
				-503
			]
		],
		[
			[
				63137,
				77795
			],
			[
				88,
				-189
			],
			[
				104,
				34
			],
			[
				25,
				-356
			],
			[
				85,
				10
			],
			[
				219,
				-194
			],
			[
				283,
				30
			]
		],
		[
			[
				63941,
				77130
			],
			[
				122,
				88
			]
		],
		[
			[
				64063,
				77218
			],
			[
				72,
				144
			],
			[
				32,
				1084
			]
		],
		[
			[
				64167,
				78446
			],
			[
				-221,
				19
			]
		],
		[
			[
				63946,
				78465
			],
			[
				-879,
				71
			]
		],
		[
			[
				63067,
				78536
			],
			[
				134,
				-162
			],
			[
				-9,
				-119
			],
			[
				-115,
				-235
			],
			[
				60,
				-225
			]
		],
		[
			[
				68286,
				68338
			],
			[
				114,
				-17
			],
			[
				139,
				-137
			],
			[
				97,
				44
			],
			[
				-40,
				-152
			],
			[
				37,
				-139
			],
			[
				-82,
				-190
			]
		],
		[
			[
				68328,
				69179
			],
			[
				-42,
				-841
			]
		],
		[
			[
				65462,
				56610
			],
			[
				458,
				-44
			],
			[
				-42,
				-892
			],
			[
				23,
				-3
			]
		],
		[
			[
				66203,
				55597
			],
			[
				113,
				299
			],
			[
				172,
				312
			],
			[
				93,
				263
			]
		],
		[
			[
				66581,
				56471
			],
			[
				-83,
				73
			],
			[
				-56,
				458
			]
		],
		[
			[
				66442,
				57002
			],
			[
				-57,
				153
			],
			[
				-63,
				-132
			]
		],
		[
			[
				66322,
				57023
			],
			[
				29,
				-154
			],
			[
				-32,
				-126
			],
			[
				-118,
				-25
			],
			[
				-41,
				110
			],
			[
				66,
				204
			]
		],
		[
			[
				66226,
				57032
			],
			[
				39,
				83
			],
			[
				-40,
				161
			],
			[
				71,
				210
			],
			[
				-55,
				167
			]
		],
		[
			[
				66241,
				57653
			],
			[
				-4,
				-89
			],
			[
				-125,
				-183
			],
			[
				-116,
				52
			],
			[
				-101,
				-91
			],
			[
				-30,
				163
			],
			[
				-373,
				32
			]
		],
		[
			[
				63164,
				75639
			],
			[
				315,
				-10
			],
			[
				-2,
				57
			]
		],
		[
			[
				63477,
				75686
			],
			[
				-30,
				85
			],
			[
				3,
				350
			],
			[
				-54,
				39
			],
			[
				-132,
				-120
			],
			[
				-34,
				125
			],
			[
				64,
				100
			],
			[
				117,
				-52
			],
			[
				31,
				104
			],
			[
				-77,
				121
			],
			[
				-123,
				43
			],
			[
				13,
				332
			],
			[
				84,
				179
			],
			[
				-10,
				120
			],
			[
				-71,
				-15
			],
			[
				-61,
				-236
			],
			[
				-60,
				135
			],
			[
				143,
				320
			],
			[
				-17,
				145
			],
			[
				-104,
				63
			],
			[
				-128,
				-12
			],
			[
				-2,
				125
			],
			[
				108,
				158
			]
		],
		[
			[
				63067,
				78536
			],
			[
				-46,
				115
			]
		],
		[
			[
				63021,
				78651
			],
			[
				6,
				-92
			]
		],
		[
			[
				63027,
				78559
			],
			[
				-8,
				-190
			],
			[
				-122,
				8
			]
		],
		[
			[
				62897,
				78377
			],
			[
				32,
				-230
			],
			[
				-32,
				-116
			],
			[
				74,
				-211
			],
			[
				-61,
				-76
			],
			[
				-199,
				-93
			],
			[
				-47,
				-109
			]
		],
		[
			[
				62664,
				77542
			],
			[
				-56,
				-133
			],
			[
				-5,
				-221
			],
			[
				136,
				-67
			],
			[
				-131,
				-179
			],
			[
				-10,
				-271
			],
			[
				63,
				11
			],
			[
				38,
				196
			],
			[
				131,
				17
			],
			[
				-3,
				-137
			],
			[
				-116,
				-14
			],
			[
				30,
				-224
			],
			[
				-92,
				-152
			],
			[
				32,
				-227
			],
			[
				56,
				-72
			],
			[
				77,
				126
			],
			[
				56,
				-114
			],
			[
				12,
				-357
			],
			[
				112,
				15
			],
			[
				61,
				-130
			],
			[
				105,
				142
			],
			[
				4,
				-112
			]
		],
		[
			[
				95829,
				19224
			],
			[
				126,
				180
			],
			[
				67,
				-21
			],
			[
				99,
				-177
			],
			[
				-15,
				272
			],
			[
				73,
				209
			],
			[
				-2,
				161
			]
		],
		[
			[
				96177,
				19848
			],
			[
				-64,
				18
			],
			[
				-72,
				313
			],
			[
				-76,
				-2
			],
			[
				-48,
				213
			],
			[
				-92,
				7
			],
			[
				-55,
				218
			],
			[
				55,
				246
			],
			[
				-32,
				123
			],
			[
				-84,
				-58
			],
			[
				-109,
				161
			]
		],
		[
			[
				95600,
				21087
			],
			[
				-258,
				-163
			],
			[
				32,
				-157
			],
			[
				-187,
				-204
			],
			[
				-26,
				126
			],
			[
				-135,
				0
			],
			[
				-42,
				-317
			],
			[
				-80,
				-28
			],
			[
				-96,
				93
			],
			[
				-40,
				-70
			]
		],
		[
			[
				88623,
				42560
			],
			[
				47,
				-130
			],
			[
				100,
				-47
			],
			[
				-18,
				-238
			],
			[
				261,
				7
			]
		],
		[
			[
				89013,
				42152
			],
			[
				74,
				286
			],
			[
				63,
				71
			],
			[
				-100,
				176
			],
			[
				-56,
				231
			],
			[
				141,
				155
			]
		],
		[
			[
				89135,
				43071
			],
			[
				-39,
				337
			],
			[
				-54,
				99
			]
		],
		[
			[
				89042,
				43507
			],
			[
				-120,
				-163
			],
			[
				-110,
				-8
			],
			[
				-106,
				-95
			],
			[
				-123,
				-215
			],
			[
				-17,
				98
			],
			[
				-83,
				-35
			],
			[
				12,
				194
			],
			[
				-46,
				-11
			],
			[
				-18,
				-335
			],
			[
				70,
				-336
			],
			[
				141,
				96
			],
			[
				-19,
				-137
			]
		],
		[
			[
				38643,
				53305
			],
			[
				67,
				-1
			],
			[
				169,
				-143
			],
			[
				69,
				-127
			],
			[
				118,
				-62
			],
			[
				68,
				49
			],
			[
				235,
				-38
			],
			[
				103,
				-114
			],
			[
				16,
				-176
			],
			[
				204,
				-222
			],
			[
				296,
				-755
			],
			[
				167,
				-205
			]
		],
		[
			[
				40644,
				51904
			],
			[
				-12,
				350
			],
			[
				1142,
				161
			]
		],
		[
			[
				42222,
				54964
			],
			[
				-1625,
				-206
			]
		],
		[
			[
				40597,
				54758
			],
			[
				-584,
				-76
			],
			[
				-1165,
				-181
			],
			[
				-271,
				-53
			]
		],
		[
			[
				38577,
				54448
			],
			[
				66,
				-1143
			]
		],
		[
			[
				91491,
				28806
			],
			[
				700,
				-253
			]
		],
		[
			[
				92191,
				28553
			],
			[
				72,
				-26
			]
		],
		[
			[
				92263,
				28527
			],
			[
				5,
				284
			],
			[
				225,
				-121
			],
			[
				-4,
				305
			],
			[
				-40,
				23
			],
			[
				35,
				337
			],
			[
				-105,
				77
			],
			[
				140,
				570
			]
		],
		[
			[
				92083,
				30862
			],
			[
				-115,
				-33
			],
			[
				-33,
				-88
			],
			[
				-89,
				40
			],
			[
				-203,
				-521
			]
		],
		[
			[
				91643,
				30260
			],
			[
				-152,
				-1454
			]
		],
		[
			[
				79376,
				64518
			],
			[
				100,
				49
			],
			[
				187,
				239
			],
			[
				148,
				78
			],
			[
				192,
				242
			],
			[
				90,
				263
			]
		],
		[
			[
				80093,
				65389
			],
			[
				-168,
				-13
			],
			[
				-198,
				243
			]
		],
		[
			[
				79727,
				65619
			],
			[
				-95,
				66
			]
		],
		[
			[
				79219,
				64445
			],
			[
				157,
				73
			]
		],
		[
			[
				77705,
				62948
			],
			[
				44,
				-47
			],
			[
				351,
				-107
			],
			[
				116,
				-259
			]
		],
		[
			[
				78327,
				62671
			],
			[
				-57,
				321
			],
			[
				20,
				263
			],
			[
				-11,
				289
			]
		],
		[
			[
				78279,
				63544
			],
			[
				-97,
				158
			],
			[
				-186,
				3
			],
			[
				-49,
				-50
			],
			[
				-93,
				180
			]
		],
		[
			[
				63395,
				46107
			],
			[
				552,
				-38
			]
		],
		[
			[
				63947,
				46069
			],
			[
				-11,
				120
			],
			[
				75,
				636
			],
			[
				-11,
				315
			]
		],
		[
			[
				64000,
				47140
			],
			[
				73,
				473
			],
			[
				66,
				128
			],
			[
				143,
				-50
			]
		],
		[
			[
				64282,
				47691
			],
			[
				-148,
				373
			],
			[
				-142,
				-39
			],
			[
				-75,
				-169
			]
		],
		[
			[
				63917,
				47856
			],
			[
				-98,
				-453
			],
			[
				49,
				-138
			],
			[
				-84,
				-516
			]
		],
		[
			[
				63784,
				46749
			],
			[
				-12,
				-104
			],
			[
				-121,
				-214
			],
			[
				-256,
				-324
			]
		],
		[
			[
				43679,
				79617
			],
			[
				108,
				5
			],
			[
				110,
				142
			],
			[
				4,
				230
			],
			[
				-117,
				384
			],
			[
				-83,
				130
			],
			[
				149,
				259
			],
			[
				-155,
				103
			],
			[
				-46,
				144
			]
		],
		[
			[
				43576,
				82945
			],
			[
				-175,
				-93
			],
			[
				-151,
				32
			],
			[
				-84,
				-89
			],
			[
				-149,
				80
			],
			[
				-126,
				-41
			],
			[
				-121,
				-219
			],
			[
				-88,
				-1
			],
			[
				-175,
				-155
			]
		],
		[
			[
				42507,
				82459
			],
			[
				-449,
				-719
			]
		],
		[
			[
				42058,
				81740
			],
			[
				36,
				-880
			],
			[
				430,
				47
			],
			[
				47,
				-1203
			],
			[
				392,
				49
			],
			[
				10,
				-223
			],
			[
				706,
				87
			]
		],
		[
			[
				29060,
				35107
			],
			[
				83,
				-292
			],
			[
				67,
				-88
			],
			[
				54,
				-324
			],
			[
				59,
				-123
			],
			[
				-33,
				-102
			],
			[
				-4,
				-544
			],
			[
				-64,
				-157
			],
			[
				31,
				-230
			],
			[
				56,
				-41
			],
			[
				-34,
				-287
			]
		],
		[
			[
				29275,
				32919
			],
			[
				752,
				200
			]
		],
		[
			[
				30027,
				33119
			],
			[
				-148,
				1604
			]
		],
		[
			[
				29363,
				36281
			],
			[
				96,
				-250
			],
			[
				-94,
				-65
			],
			[
				58,
				-264
			],
			[
				-94,
				-263
			],
			[
				-144,
				-55
			]
		],
		[
			[
				88962,
				47054
			],
			[
				-22,
				105
			],
			[
				91,
				46
			],
			[
				-108,
				140
			],
			[
				-215,
				-142
			],
			[
				-54,
				112
			],
			[
				-185,
				-433
			],
			[
				-110,
				-54
			]
		],
		[
			[
				88359,
				46828
			],
			[
				112,
				-217
			],
			[
				97,
				-40
			]
		],
		[
			[
				15307,
				4945
			],
			[
				576,
				289
			],
			[
				1288,
				588
			]
		],
		[
			[
				16384,
				8185
			],
			[
				-90,
				-34
			],
			[
				-57,
				-154
			],
			[
				-148,
				-89
			],
			[
				-79,
				-188
			],
			[
				-81,
				-2
			],
			[
				-109,
				-139
			],
			[
				-80,
				79
			],
			[
				-289,
				-249
			],
			[
				-64,
				-150
			],
			[
				-26,
				-235
			],
			[
				-280,
				-135
			],
			[
				-84,
				-290
			]
		],
		[
			[
				14997,
				6599
			],
			[
				154,
				-35
			],
			[
				27,
				-512
			],
			[
				-30,
				-388
			],
			[
				132,
				-67
			],
			[
				-94,
				-242
			],
			[
				125,
				-216
			],
			[
				-4,
				-194
			]
		],
		[
			[
				14862,
				6382
			],
			[
				74,
				-321
			],
			[
				94,
				-198
			],
			[
				34,
				192
			],
			[
				-43,
				213
			],
			[
				83,
				114
			],
			[
				-146,
				108
			],
			[
				-101,
				-10
			],
			[
				5,
				-98
			]
		],
		[
			[
				55489,
				43162
			],
			[
				633,
				3
			]
		],
		[
			[
				56122,
				43165
			],
			[
				184,
				1
			],
			[
				51,
				503
			]
		],
		[
			[
				56357,
				43669
			],
			[
				-4,
				216
			],
			[
				61,
				105
			],
			[
				40,
				400
			],
			[
				-7,
				174
			]
		],
		[
			[
				56447,
				44564
			],
			[
				-75,
				125
			],
			[
				-154,
				-30
			],
			[
				-125,
				-230
			],
			[
				-68,
				-41
			],
			[
				-115,
				-210
			]
		],
		[
			[
				55910,
				44178
			],
			[
				-65,
				-109
			],
			[
				-103,
				-17
			],
			[
				-25,
				-100
			],
			[
				38,
				-260
			],
			[
				-137,
				-227
			],
			[
				-12,
				-240
			],
			[
				-113,
				-53
			]
		],
		[
			[
				40107,
				10102
			],
			[
				1236,
				211
			]
		],
		[
			[
				41343,
				10313
			],
			[
				321,
				51
			],
			[
				-18,
				326
			],
			[
				194,
				31
			],
			[
				-17,
				328
			],
			[
				867,
				131
			]
		],
		[
			[
				42690,
				11180
			],
			[
				-70,
				1485
			]
		],
		[
			[
				42620,
				12665
			],
			[
				-81,
				-27
			],
			[
				-36,
				-195
			],
			[
				-168,
				51
			],
			[
				-135,
				-80
			],
			[
				-150,
				-200
			],
			[
				-28,
				-100
			],
			[
				-107,
				-44
			],
			[
				-81,
				49
			],
			[
				-27,
				-127
			],
			[
				-101,
				120
			],
			[
				-135,
				-102
			],
			[
				-127,
				-6
			],
			[
				-64,
				-117
			],
			[
				-81,
				194
			],
			[
				-58,
				-36
			],
			[
				-105,
				149
			],
			[
				-36,
				-70
			],
			[
				-155,
				-43
			],
			[
				-38,
				49
			]
		],
		[
			[
				40907,
				12130
			],
			[
				-46,
				-94
			],
			[
				-409,
				-102
			],
			[
				-48,
				105
			],
			[
				-173,
				-65
			],
			[
				-166,
				169
			],
			[
				-143,
				32
			]
		],
		[
			[
				39922,
				12175
			],
			[
				48,
				-787
			],
			[
				58,
				11
			],
			[
				79,
				-1297
			]
		],
		[
			[
				48589,
				42629
			],
			[
				20,
				1
			]
		],
		[
			[
				14683,
				39181
			],
			[
				398,
				180
			],
			[
				-50,
				349
			],
			[
				-155,
				-38
			],
			[
				-48,
				94
			],
			[
				-58,
				381
			],
			[
				6,
				176
			],
			[
				-46,
				281
			],
			[
				92,
				57
			],
			[
				-40,
				303
			],
			[
				31,
				14
			],
			[
				-61,
				426
			]
		],
		[
			[
				14752,
				41404
			],
			[
				-333,
				-854
			]
		],
		[
			[
				83673,
				26525
			],
			[
				391,
				-19
			],
			[
				189,
				163
			],
			[
				209,
				106
			],
			[
				233,
				-248
			]
		],
		[
			[
				84695,
				26527
			],
			[
				113,
				914
			]
		],
		[
			[
				84514,
				27895
			],
			[
				-243,
				76
			],
			[
				-16,
				-163
			],
			[
				-285,
				97
			]
		],
		[
			[
				85922,
				41636
			],
			[
				40,
				-584
			],
			[
				-28,
				-142
			],
			[
				40,
				-455
			],
			[
				57,
				-63
			],
			[
				68,
				-373
			]
		],
		[
			[
				86099,
				40019
			],
			[
				16,
				-6
			]
		],
		[
			[
				86115,
				40013
			],
			[
				405,
				-138
			]
		],
		[
			[
				86520,
				39875
			],
			[
				12,
				80
			],
			[
				-79,
				399
			],
			[
				163,
				58
			],
			[
				187,
				254
			],
			[
				-39,
				567
			]
		],
		[
			[
				86764,
				41233
			],
			[
				-1,
				2
			]
		],
		[
			[
				86334,
				41905
			],
			[
				-145,
				-116
			],
			[
				-73,
				-148
			],
			[
				-194,
				-5
			]
		],
		[
			[
				63336,
				18545
			],
			[
				380,
				-342
			],
			[
				205,
				-132
			],
			[
				201,
				-371
			]
		],
		[
			[
				64122,
				17700
			],
			[
				40,
				-2
			],
			[
				22,
				656
			],
			[
				191,
				-19
			],
			[
				11,
				330
			],
			[
				579,
				-68
			],
			[
				27,
				656
			],
			[
				578,
				-72
			]
		],
		[
			[
				65570,
				19181
			],
			[
				38,
				898
			]
		],
		[
			[
				64126,
				19479
			],
			[
				-300,
				-109
			],
			[
				-86,
				-384
			],
			[
				-109,
				-256
			],
			[
				-135,
				-31
			],
			[
				-25,
				-119
			],
			[
				-92,
				89
			],
			[
				-43,
				-124
			]
		],
		[
			[
				59306,
				20411
			],
			[
				-20,
				-995
			]
		],
		[
			[
				59286,
				19416
			],
			[
				1177,
				-62
			]
		],
		[
			[
				60463,
				19354
			],
			[
				21,
				990
			]
		],
		[
			[
				60484,
				20344
			],
			[
				7,
				316
			],
			[
				-52,
				35
			],
			[
				-30,
				190
			],
			[
				-134,
				-8
			],
			[
				-53,
				188
			],
			[
				-78,
				-38
			],
			[
				-42,
				125
			],
			[
				-137,
				84
			],
			[
				-102,
				149
			],
			[
				-86,
				264
			],
			[
				-24,
				242
			],
			[
				-86,
				136
			]
		],
		[
			[
				59667,
				22027
			],
			[
				-469,
				23
			]
		],
		[
			[
				63502,
				34098
			],
			[
				354,
				-30
			]
		],
		[
			[
				63856,
				34068
			],
			[
				816,
				-80
			]
		],
		[
			[
				64729,
				35173
			],
			[
				-653,
				80
			]
		],
		[
			[
				64076,
				35253
			],
			[
				-125,
				-109
			],
			[
				-72,
				-196
			],
			[
				19,
				-173
			],
			[
				-103,
				-217
			]
		],
		[
			[
				63795,
				34558
			],
			[
				-126,
				-109
			],
			[
				-40,
				-106
			],
			[
				-128,
				-104
			],
			[
				1,
				-141
			]
		],
		[
			[
				73639,
				47093
			],
			[
				-236,
				334
			],
			[
				-51,
				-1
			]
		],
		[
			[
				47525,
				53663
			],
			[
				-9,
				339
			],
			[
				28,
				3
			],
			[
				-34,
				1484
			]
		],
		[
			[
				47510,
				55489
			],
			[
				-961,
				-68
			]
		],
		[
			[
				76075,
				47499
			],
			[
				5,
				-336
			],
			[
				-44,
				-90
			]
		],
		[
			[
				76036,
				47073
			],
			[
				38,
				-192
			],
			[
				156,
				-108
			],
			[
				99,
				85
			],
			[
				179,
				25
			],
			[
				143,
				208
			],
			[
				39,
				-93
			]
		],
		[
			[
				84688,
				40813
			],
			[
				122,
				28
			],
			[
				116,
				-339
			],
			[
				177,
				49
			],
			[
				113,
				157
			]
		],
		[
			[
				85216,
				40708
			],
			[
				-105,
				181
			],
			[
				-79,
				-20
			],
			[
				-66,
				817
			]
		],
		[
			[
				84966,
				41686
			],
			[
				-230,
				-220
			]
		],
		[
			[
				84736,
				41466
			],
			[
				-225,
				-127
			]
		],
		[
			[
				84511,
				41339
			],
			[
				53,
				-183
			],
			[
				-41,
				-112
			],
			[
				82,
				-12
			],
			[
				83,
				-219
			]
		],
		[
			[
				30208,
				31175
			],
			[
				184,
				-1924
			]
		],
		[
			[
				30392,
				29251
			],
			[
				104,
				-1122
			]
		],
		[
			[
				30496,
				28129
			],
			[
				369,
				99
			],
			[
				-29,
				307
			],
			[
				377,
				100
			]
		],
		[
			[
				31213,
				28635
			],
			[
				-52,
				410
			],
			[
				-119,
				1318
			],
			[
				-58,
				-15
			],
			[
				-23,
				262
			],
			[
				53,
				15
			],
			[
				-90,
				994
			],
			[
				76,
				20
			],
			[
				-56,
				651
			],
			[
				544,
				146
			],
			[
				246,
				85
			]
		],
		[
			[
				31734,
				32521
			],
			[
				-88,
				1004
			],
			[
				13,
				3
			],
			[
				-145,
				1633
			]
		],
		[
			[
				30027,
				33119
			],
			[
				181,
				-1944
			]
		],
		[
			[
				70874,
				67937
			],
			[
				-27,
				110
			],
			[
				-85,
				-11
			],
			[
				40,
				188
			],
			[
				116,
				74
			],
			[
				51,
				193
			],
			[
				65,
				19
			],
			[
				77,
				187
			],
			[
				184,
				89
			],
			[
				6,
				112
			]
		],
		[
			[
				70692,
				69935
			],
			[
				-546,
				69
			]
		],
		[
			[
				70146,
				70004
			],
			[
				-218,
				-8
			],
			[
				-36,
				-521
			]
		],
		[
			[
				58901,
				63316
			],
			[
				-9,
				-444
			],
			[
				78,
				-55
			],
			[
				242,
				1
			],
			[
				127,
				-277
			],
			[
				321,
				-3
			],
			[
				-4,
				-404
			]
		],
		[
			[
				60358,
				62663
			],
			[
				-51,
				12
			],
			[
				-99,
				203
			],
			[
				-108,
				37
			],
			[
				3,
				123
			]
		],
		[
			[
				59674,
				64232
			],
			[
				-194,
				12
			],
			[
				2,
				112
			]
		],
		[
			[
				59482,
				64356
			],
			[
				-575,
				18
			]
		],
		[
			[
				58907,
				64374
			],
			[
				-6,
				-1058
			]
		],
		[
			[
				58877,
				60428
			],
			[
				318,
				6
			]
		],
		[
			[
				59195,
				60434
			],
			[
				1,
				112
			],
			[
				638,
				-9
			]
		],
		[
			[
				59618,
				62097
			],
			[
				-62,
				-51
			],
			[
				-158,
				59
			],
			[
				-33,
				-384
			],
			[
				-104,
				124
			],
			[
				-60,
				-41
			],
			[
				-120,
				100
			],
			[
				-137,
				-78
			],
			[
				-71,
				120
			]
		],
		[
			[
				58873,
				61946
			],
			[
				4,
				-1518
			]
		],
		[
			[
				93202,
				23798
			],
			[
				65,
				702
			],
			[
				90,
				-36
			],
			[
				137,
				554
			],
			[
				47,
				-18
			],
			[
				68,
				273
			]
		],
		[
			[
				93609,
				25273
			],
			[
				-565,
				211
			]
		],
		[
			[
				92763,
				25585
			],
			[
				-178,
				-247
			],
			[
				-58,
				-278
			],
			[
				-6,
				-275
			],
			[
				83,
				-104
			],
			[
				25,
				-163
			],
			[
				-59,
				-150
			],
			[
				17,
				-265
			],
			[
				-53,
				-142
			]
		],
		[
			[
				90786,
				25569
			],
			[
				570,
				-245
			]
		],
		[
			[
				91356,
				25324
			],
			[
				49,
				540
			],
			[
				71,
				192
			]
		],
		[
			[
				91476,
				26056
			],
			[
				-14,
				940
			]
		],
		[
			[
				91462,
				26996
			],
			[
				-662,
				453
			]
		],
		[
			[
				90800,
				27449
			],
			[
				4,
				-278
			],
			[
				-45,
				-283
			],
			[
				47,
				-374
			],
			[
				-3,
				-328
			]
		],
		[
			[
				79461,
				58901
			],
			[
				192,
				-105
			],
			[
				193,
				227
			],
			[
				183,
				262
			],
			[
				22,
				269
			]
		],
		[
			[
				80051,
				59554
			],
			[
				-445,
				78
			]
		],
		[
			[
				79606,
				59632
			],
			[
				-245,
				76
			]
		],
		[
			[
				79361,
				59708
			],
			[
				-16,
				-363
			],
			[
				116,
				-444
			]
		],
		[
			[
				79088,
				44220
			],
			[
				44,
				321
			]
		],
		[
			[
				79132,
				44541
			],
			[
				-104,
				74
			],
			[
				105,
				272
			],
			[
				-16,
				167
			],
			[
				46,
				147
			],
			[
				-95,
				-61
			],
			[
				-57,
				233
			],
			[
				-81,
				66
			]
		],
		[
			[
				78930,
				45439
			],
			[
				-6,
				-229
			],
			[
				-72,
				-177
			],
			[
				-71,
				0
			],
			[
				-69,
				-128
			],
			[
				-133,
				312
			]
		],
		[
			[
				78579,
				45217
			],
			[
				-9,
				-171
			],
			[
				-392,
				57
			],
			[
				-3,
				-56
			]
		],
		[
			[
				84573,
				40511
			],
			[
				94,
				-433
			],
			[
				20,
				-313
			],
			[
				78,
				-635
			],
			[
				-54,
				-91
			],
			[
				87,
				-337
			]
		],
		[
			[
				84798,
				38702
			],
			[
				105,
				88
			],
			[
				269,
				37
			],
			[
				120,
				120
			]
		],
		[
			[
				85292,
				38947
			],
			[
				-68,
				262
			],
			[
				-62,
				703
			],
			[
				-117,
				448
			]
		],
		[
			[
				85045,
				40360
			],
			[
				-408,
				131
			]
		],
		[
			[
				84637,
				40491
			],
			[
				-64,
				20
			]
		],
		[
			[
				9369,
				40454
			],
			[
				354,
				174
			],
			[
				-18,
				-108
			],
			[
				101,
				-320
			],
			[
				-2,
				-178
			],
			[
				236,
				113
			],
			[
				1,
				-275
			],
			[
				-35,
				-194
			],
			[
				90,
				-295
			]
		],
		[
			[
				10827,
				40522
			],
			[
				-108,
				419
			],
			[
				-139,
				78
			],
			[
				-90,
				255
			],
			[
				-156,
				25
			],
			[
				-108,
				-72
			]
		],
		[
			[
				10226,
				41227
			],
			[
				-92,
				40
			],
			[
				-109,
				-142
			],
			[
				-134,
				-44
			],
			[
				-156,
				47
			],
			[
				-55,
				-116
			],
			[
				-135,
				-90
			]
		],
		[
			[
				9545,
				40922
			],
			[
				-31,
				-195
			],
			[
				-138,
				-214
			]
		],
		[
			[
				9376,
				40513
			],
			[
				-7,
				-59
			]
		],
		[
			[
				32935,
				6852
			],
			[
				774,
				201
			],
			[
				1068,
				262
			]
		],
		[
			[
				34777,
				7315
			],
			[
				-51,
				319
			],
			[
				-108,
				1294
			],
			[
				47,
				175
			],
			[
				-22,
				272
			],
			[
				-48,
				-12
			],
			[
				-51,
				209
			],
			[
				-32,
				379
			],
			[
				-49,
				-12
			],
			[
				4,
				277
			],
			[
				-28,
				325
			]
		],
		[
			[
				32650,
				9757
			],
			[
				285,
				-2905
			]
		],
		[
			[
				53961,
				40264
			],
			[
				191,
				5
			],
			[
				51,
				-115
			]
		],
		[
			[
				54203,
				40154
			],
			[
				186,
				191
			],
			[
				254,
				-268
			],
			[
				115,
				49
			],
			[
				180,
				-3
			]
		],
		[
			[
				55049,
				40707
			],
			[
				-56,
				139
			],
			[
				22,
				316
			]
		],
		[
			[
				55015,
				41162
			],
			[
				-1056,
				-3
			]
		],
		[
			[
				16654,
				28467
			],
			[
				1004,
				434
			],
			[
				803,
				310
			]
		],
		[
			[
				18461,
				29211
			],
			[
				918,
				364
			],
			[
				965,
				370
			]
		],
		[
			[
				20344,
				29945
			],
			[
				12,
				6
			]
		],
		[
			[
				19863,
				33706
			],
			[
				-178,
				1343
			],
			[
				-374,
				-142
			],
			[
				-146,
				403
			]
		],
		[
			[
				19165,
				35310
			],
			[
				76,
				-564
			],
			[
				-560,
				-252
			],
			[
				88,
				-652
			],
			[
				-1296,
				-515
			],
			[
				-568,
				-236
			],
			[
				57,
				-393
			],
			[
				-850,
				-358
			]
		],
		[
			[
				16112,
				32340
			],
			[
				-33,
				-14
			],
			[
				148,
				-1027
			],
			[
				41,
				19
			],
			[
				100,
				-672
			],
			[
				-31,
				-13
			],
			[
				91,
				-660
			],
			[
				226,
				-1506
			]
		],
		[
			[
				89980,
				37550
			],
			[
				88,
				13
			],
			[
				9,
				128
			],
			[
				70,
				5
			],
			[
				96,
				165
			],
			[
				97,
				345
			],
			[
				161,
				-35
			],
			[
				146,
				157
			]
		],
		[
			[
				90647,
				38328
			],
			[
				-174,
				548
			]
		],
		[
			[
				90473,
				38876
			],
			[
				-130,
				-276
			],
			[
				-105,
				-26
			],
			[
				-163,
				-242
			],
			[
				-61,
				-206
			],
			[
				-116,
				-137
			]
		],
		[
			[
				91409,
				34040
			],
			[
				51,
				-256
			]
		],
		[
			[
				91663,
				33681
			],
			[
				28,
				-7
			]
		],
		[
			[
				91691,
				33674
			],
			[
				136,
				134
			],
			[
				-28,
				143
			],
			[
				41,
				244
			],
			[
				-55,
				129
			],
			[
				47,
				127
			]
		],
		[
			[
				91832,
				34451
			],
			[
				-131,
				103
			],
			[
				-156,
				199
			],
			[
				-2,
				-94
			]
		],
		[
			[
				91543,
				34659
			],
			[
				127,
				-138
			],
			[
				7,
				-160
			],
			[
				-93,
				-225
			],
			[
				-39,
				64
			],
			[
				-136,
				-160
			]
		],
		[
			[
				97917,
				17647
			],
			[
				84,
				122
			],
			[
				10,
				231
			],
			[
				-79,
				75
			],
			[
				-76,
				-426
			]
		],
		[
			[
				97421,
				17896
			],
			[
				20,
				-151
			],
			[
				117,
				-211
			],
			[
				42,
				177
			],
			[
				116,
				210
			],
			[
				-35,
				153
			],
			[
				-96,
				90
			],
			[
				-81,
				-41
			],
			[
				-26,
				-244
			],
			[
				-57,
				17
			]
		],
		[
			[
				97207,
				17459
			],
			[
				9,
				305
			],
			[
				-32,
				42
			],
			[
				28,
				303
			],
			[
				97,
				-21
			],
			[
				-59,
				124
			],
			[
				76,
				128
			],
			[
				-21,
				170
			],
			[
				-153,
				63
			],
			[
				-30,
				263
			],
			[
				-83,
				44
			],
			[
				18,
				189
			],
			[
				-55,
				59
			],
			[
				-9,
				-300
			],
			[
				-56,
				-24
			],
			[
				-65,
				179
			],
			[
				-20,
				-201
			]
		],
		[
			[
				96852,
				18782
			],
			[
				-13,
				-197
			],
			[
				50,
				7
			],
			[
				23,
				-228
			],
			[
				-54,
				-267
			],
			[
				-100,
				-257
			],
			[
				-106,
				47
			],
			[
				-99,
				-157
			],
			[
				-34,
				-189
			],
			[
				55,
				-83
			]
		],
		[
			[
				95604,
				16802
			],
			[
				-14,
				151
			],
			[
				-141,
				13
			],
			[
				-13,
				241
			],
			[
				72,
				141
			],
			[
				-134,
				56
			]
		],
		[
			[
				93831,
				15002
			],
			[
				-4,
				-354
			],
			[
				-56,
				-114
			],
			[
				90,
				-153
			],
			[
				88,
				109
			],
			[
				121,
				-29
			],
			[
				-57,
				-208
			],
			[
				-129,
				-118
			],
			[
				-23,
				-98
			],
			[
				62,
				-393
			],
			[
				103,
				-289
			]
		],
		[
			[
				89478,
				44666
			],
			[
				98,
				-102
			],
			[
				43,
				-135
			],
			[
				135,
				22
			],
			[
				38,
				-141
			],
			[
				95,
				45
			]
		],
		[
			[
				89887,
				44355
			],
			[
				26,
				107
			],
			[
				136,
				227
			],
			[
				-36,
				150
			],
			[
				87,
				184
			],
			[
				-122,
				111
			],
			[
				-45,
				187
			],
			[
				71,
				114
			]
		],
		[
			[
				90004,
				45435
			],
			[
				-57,
				193
			]
		],
		[
			[
				89947,
				45628
			],
			[
				-86,
				-70
			],
			[
				-119,
				59
			],
			[
				-105,
				254
			],
			[
				-68,
				-2
			],
			[
				-24,
				-186
			],
			[
				16,
				-243
			],
			[
				91,
				-130
			],
			[
				-123,
				-18
			],
			[
				62,
				-254
			],
			[
				-127,
				17
			],
			[
				-64,
				133
			],
			[
				-30,
				-281
			],
			[
				153,
				-148
			],
			[
				-45,
				-93
			]
		],
		[
			[
				89393,
				45821
			],
			[
				-90,
				33
			]
		],
		[
			[
				89303,
				45854
			],
			[
				-29,
				-300
			],
			[
				100,
				4
			],
			[
				19,
				263
			]
		],
		[
			[
				89221,
				45224
			],
			[
				16,
				-112
			]
		],
		[
			[
				89237,
				45112
			],
			[
				77,
				250
			],
			[
				-100,
				-37
			],
			[
				7,
				-101
			]
		],
		[
			[
				62956,
				62797
			],
			[
				87,
				-466
			],
			[
				46,
				75
			],
			[
				1,
				-238
			],
			[
				-57,
				-76
			],
			[
				-67,
				-291
			],
			[
				165,
				-368
			]
		],
		[
			[
				63131,
				61433
			],
			[
				169,
				-40
			],
			[
				13,
				340
			],
			[
				382,
				-21
			]
		],
		[
			[
				63714,
				62506
			],
			[
				12,
				567
			],
			[
				-189,
				11
			]
		],
		[
			[
				63537,
				63084
			],
			[
				-159,
				7
			],
			[
				-99,
				82
			],
			[
				5,
				280
			],
			[
				-134,
				10
			]
		],
		[
			[
				63150,
				63463
			],
			[
				-6,
				-536
			],
			[
				-177,
				15
			],
			[
				-11,
				-145
			]
		],
		[
			[
				36038,
				42639
			],
			[
				643,
				122
			],
			[
				46,
				-10
			]
		],
		[
			[
				36727,
				42751
			],
			[
				324,
				61
			]
		],
		[
			[
				37252,
				46222
			],
			[
				23,
				256
			],
			[
				101,
				33
			],
			[
				-15,
				181
			],
			[
				143,
				16
			],
			[
				160,
				303
			],
			[
				-36,
				117
			],
			[
				52,
				139
			],
			[
				-135,
				415
			]
		],
		[
			[
				37545,
				47682
			],
			[
				72,
				66
			],
			[
				6,
				195
			],
			[
				58,
				119
			],
			[
				-75,
				361
			],
			[
				-51,
				-1
			],
			[
				-139,
				196
			]
		],
		[
			[
				37416,
				48618
			],
			[
				-80,
				-55
			],
			[
				-39,
				122
			],
			[
				-294,
				-50
			]
		],
		[
			[
				37003,
				48635
			],
			[
				-157,
				-475
			],
			[
				14,
				-183
			],
			[
				-47,
				-221
			],
			[
				-94,
				-235
			],
			[
				61,
				-98
			],
			[
				36,
				-386
			],
			[
				114,
				-96
			],
			[
				56,
				-174
			],
			[
				-154,
				-108
			],
			[
				-92,
				23
			],
			[
				-133,
				-356
			],
			[
				-67,
				-12
			]
		],
		[
			[
				77331,
				68193
			],
			[
				486,
				-264
			]
		],
		[
			[
				77817,
				67929
			],
			[
				211,
				-100
			]
		],
		[
			[
				78028,
				67829
			],
			[
				48,
				270
			],
			[
				161,
				688
			]
		],
		[
			[
				78155,
				68914
			],
			[
				-182,
				248
			]
		],
		[
			[
				77973,
				69162
			],
			[
				-169,
				-128
			],
			[
				-112,
				25
			],
			[
				-115,
				-226
			]
		],
		[
			[
				95114,
				28322
			],
			[
				104,
				47
			],
			[
				83,
				129
			]
		],
		[
			[
				95301,
				28498
			],
			[
				6,
				151
			],
			[
				-60,
				167
			],
			[
				-48,
				-240
			],
			[
				-128,
				-118
			]
		],
		[
			[
				95071,
				28458
			],
			[
				43,
				-136
			]
		],
		[
			[
				51369,
				31920
			],
			[
				713,
				23
			]
		],
		[
			[
				52082,
				31943
			],
			[
				-9,
				1219
			]
		],
		[
			[
				52073,
				33162
			],
			[
				-227,
				0
			],
			[
				-87,
				-76
			],
			[
				-77,
				36
			],
			[
				-23,
				159
			],
			[
				-103,
				179
			],
			[
				-138,
				16
			],
			[
				-189,
				-295
			]
		],
		[
			[
				51229,
				33181
			],
			[
				60,
				-114
			],
			[
				-8,
				-158
			],
			[
				53,
				-243
			],
			[
				-32,
				-106
			],
			[
				10,
				-331
			],
			[
				57,
				-309
			]
		],
		[
			[
				51986,
				93040
			],
			[
				61,
				2
			]
		],
		[
			[
				52047,
				93042
			],
			[
				-54,
				474
			],
			[
				-17,
				352
			],
			[
				18,
				664
			],
			[
				83,
				700
			],
			[
				63,
				386
			]
		],
		[
			[
				52140,
				95618
			],
			[
				-50,
				-1
			]
		],
		[
			[
				52090,
				95617
			],
			[
				-143,
				-763
			],
			[
				-21,
				-347
			],
			[
				10,
				-849
			],
			[
				50,
				-618
			]
		],
		[
			[
				50767,
				93265
			],
			[
				69,
				-89
			],
			[
				222,
				-33
			],
			[
				114,
				-148
			],
			[
				114,
				71
			],
			[
				182,
				-54
			]
		],
		[
			[
				51468,
				93012
			],
			[
				14,
				148
			],
			[
				171,
				57
			],
			[
				187,
				-122
			],
			[
				55,
				20
			],
			[
				-52,
				1027
			],
			[
				-51,
				673
			],
			[
				-25,
				10
			],
			[
				20,
				367
			],
			[
				48,
				420
			]
		],
		[
			[
				51835,
				95612
			],
			[
				-846,
				-18
			],
			[
				-192,
				-59
			]
		],
		[
			[
				50797,
				95535
			],
			[
				-56,
				-17
			],
			[
				7,
				-627
			]
		],
		[
			[
				90593,
				20423
			],
			[
				69,
				-12
			],
			[
				442,
				-318
			],
			[
				104,
				478
			]
		],
		[
			[
				91605,
				20923
			],
			[
				-39,
				285
			]
		],
		[
			[
				91566,
				21208
			],
			[
				-61,
				422
			],
			[
				-40,
				-146
			],
			[
				-104,
				155
			],
			[
				17,
				183
			]
		],
		[
			[
				90787,
				22360
			],
			[
				-55,
				-195
			]
		],
		[
			[
				90732,
				22165
			],
			[
				-27,
				-249
			],
			[
				-85,
				-197
			],
			[
				-53,
				-349
			],
			[
				-51,
				-50
			],
			[
				-4,
				-574
			],
			[
				81,
				-323
			]
		],
		[
			[
				82165,
				28174
			],
			[
				118,
				-39
			],
			[
				106,
				143
			],
			[
				83,
				-16
			],
			[
				0,
				-121
			],
			[
				151,
				-188
			],
			[
				203,
				-8
			],
			[
				140,
				-146
			],
			[
				76,
				12
			]
		],
		[
			[
				83134,
				28647
			],
			[
				-36,
				10
			],
			[
				33,
				330
			],
			[
				40,
				-12
			],
			[
				100,
				923
			]
		],
		[
			[
				82310,
				30194
			],
			[
				-135,
				-83
			]
		],
		[
			[
				82175,
				30111
			],
			[
				87,
				-320
			],
			[
				6,
				-176
			],
			[
				191,
				-247
			],
			[
				85,
				-209
			],
			[
				-33,
				-214
			],
			[
				-162,
				-397
			],
			[
				-139,
				-71
			],
			[
				-45,
				-303
			]
		],
		[
			[
				83139,
				60179
			],
			[
				-64,
				-1475
			]
		],
		[
			[
				83075,
				58704
			],
			[
				35,
				-69
			],
			[
				72,
				194
			],
			[
				95,
				5
			],
			[
				111,
				-127
			],
			[
				67,
				95
			]
		],
		[
			[
				83455,
				58802
			],
			[
				30,
				118
			],
			[
				128,
				62
			],
			[
				122,
				-154
			],
			[
				117,
				270
			],
			[
				-32,
				121
			],
			[
				70,
				88
			],
			[
				60,
				304
			],
			[
				-75,
				185
			],
			[
				-28,
				208
			]
		],
		[
			[
				83847,
				60004
			],
			[
				-708,
				175
			]
		],
		[
			[
				86735,
				52293
			],
			[
				232,
				-80
			]
		],
		[
			[
				86967,
				52213
			],
			[
				821,
				-273
			]
		],
		[
			[
				87788,
				51940
			],
			[
				230,
				-85
			]
		],
		[
			[
				88018,
				51855
			],
			[
				37,
				71
			],
			[
				156,
				56
			],
			[
				-84,
				121
			],
			[
				-54,
				911
			]
		],
		[
			[
				81723,
				59263
			],
			[
				64,
				-46
			],
			[
				-57,
				-420
			],
			[
				53,
				-338
			],
			[
				79,
				-58
			],
			[
				-120,
				-38
			],
			[
				48,
				-100
			]
		],
		[
			[
				81773,
				57925
			],
			[
				63,
				-120
			],
			[
				221,
				-27
			]
		],
		[
			[
				82057,
				57778
			],
			[
				90,
				334
			],
			[
				-20,
				56
			],
			[
				149,
				172
			],
			[
				75,
				283
			],
			[
				231,
				172
			]
		],
		[
			[
				82582,
				58795
			],
			[
				-337,
				780
			],
			[
				-107,
				151
			]
		],
		[
			[
				82138,
				59726
			],
			[
				-145,
				-253
			]
		],
		[
			[
				81993,
				59473
			],
			[
				-62,
				-103
			],
			[
				-169,
				291
			],
			[
				-39,
				-398
			]
		],
		[
			[
				80827,
				39927
			],
			[
				-86,
				-914
			]
		],
		[
			[
				80741,
				39013
			],
			[
				-30,
				-295
			]
		],
		[
			[
				80711,
				38718
			],
			[
				262,
				-75
			]
		],
		[
			[
				80973,
				38643
			],
			[
				349,
				466
			],
			[
				479,
				176
			],
			[
				-62,
				160
			],
			[
				156,
				28
			]
		],
		[
			[
				81910,
				39745
			],
			[
				78,
				118
			],
			[
				-92,
				69
			],
			[
				49,
				154
			],
			[
				-182,
				263
			]
		],
		[
			[
				81763,
				40349
			],
			[
				-82,
				43
			],
			[
				-32,
				-112
			],
			[
				-108,
				113
			],
			[
				-85,
				-52
			],
			[
				-222,
				72
			],
			[
				-217,
				180
			],
			[
				-41,
				104
			],
			[
				-77,
				-18
			]
		],
		[
			[
				80899,
				40679
			],
			[
				-20,
				-207
			]
		],
		[
			[
				60442,
				18416
			],
			[
				21,
				938
			]
		],
		[
			[
				59286,
				19416
			],
			[
				-24,
				-662
			],
			[
				-10,
				-660
			]
		],
		[
			[
				54635,
				28037
			],
			[
				-2,
				1332
			]
		],
		[
			[
				54009,
				29365
			],
			[
				5,
				-1332
			]
		],
		[
			[
				62960,
				45378
			],
			[
				57,
				198
			],
			[
				82,
				20
			],
			[
				117,
				338
			],
			[
				179,
				173
			]
		],
		[
			[
				63784,
				46749
			],
			[
				-790,
				59
			],
			[
				6,
				340
			],
			[
				-129,
				9
			]
		],
		[
			[
				62547,
				46493
			],
			[
				-52,
				-506
			],
			[
				422,
				-606
			],
			[
				43,
				-3
			]
		],
		[
			[
				53956,
				42165
			],
			[
				-3,
				1005
			]
		],
		[
			[
				53950,
				44178
			],
			[
				-580,
				-8
			]
		],
		[
			[
				53370,
				44170
			],
			[
				-188,
				-4
			]
		],
		[
			[
				50676,
				32545
			],
			[
				48,
				198
			],
			[
				252,
				261
			]
		],
		[
			[
				50976,
				33004
			],
			[
				1,
				466
			]
		],
		[
			[
				49429,
				33227
			],
			[
				19,
				-744
			]
		],
		[
			[
				52318,
				33173
			],
			[
				111,
				-57
			],
			[
				180,
				-2
			],
			[
				142,
				87
			],
			[
				13,
				141
			],
			[
				81,
				38
			]
		],
		[
			[
				52845,
				33380
			],
			[
				47,
				108
			],
			[
				188,
				44
			]
		],
		[
			[
				53067,
				35110
			],
			[
				-574,
				-15
			]
		],
		[
			[
				52493,
				35095
			],
			[
				3,
				-334
			],
			[
				-193,
				-4
			]
		],
		[
			[
				52303,
				34757
			],
			[
				15,
				-1584
			]
		],
		[
			[
				75760,
				66156
			],
			[
				178,
				-363
			],
			[
				16,
				-328
			],
			[
				103,
				-226
			],
			[
				-96,
				-405
			]
		],
		[
			[
				76252,
				65222
			],
			[
				-132,
				-12
			],
			[
				83,
				1224
			]
		],
		[
			[
				76203,
				66434
			],
			[
				-197,
				38
			],
			[
				25,
				374
			]
		],
		[
			[
				76031,
				66846
			],
			[
				-174,
				124
			],
			[
				-90,
				119
			]
		],
		[
			[
				75321,
				67145
			],
			[
				61,
				-255
			]
		],
		[
			[
				77973,
				69162
			],
			[
				33,
				163
			],
			[
				-122,
				272
			],
			[
				-45,
				-3
			],
			[
				31,
				292
			]
		],
		[
			[
				77670,
				69822
			],
			[
				-213,
				-97
			],
			[
				-185,
				-420
			]
		],
		[
			[
				80255,
				72033
			],
			[
				230,
				81
			],
			[
				39,
				-55
			],
			[
				133,
				80
			]
		],
		[
			[
				80974,
				73774
			],
			[
				-42,
				11
			],
			[
				-154,
				-192
			]
		],
		[
			[
				80778,
				73593
			],
			[
				-34,
				-88
			],
			[
				-223,
				-197
			],
			[
				-117,
				-43
			],
			[
				-236,
				-220
			]
		],
		[
			[
				80168,
				73045
			],
			[
				-39,
				-486
			],
			[
				167,
				-39
			],
			[
				-41,
				-487
			]
		],
		[
			[
				72764,
				51261
			],
			[
				83,
				123
			],
			[
				81,
				-3
			],
			[
				27,
				372
			],
			[
				-47,
				182
			],
			[
				110,
				91
			],
			[
				68,
				-135
			]
		],
		[
			[
				73204,
				52214
			],
			[
				-61,
				-56
			],
			[
				-46,
				121
			],
			[
				-99,
				-43
			]
		],
		[
			[
				72881,
				52444
			],
			[
				-416,
				6
			]
		],
		[
			[
				72465,
				52450
			],
			[
				5,
				-404
			],
			[
				89,
				-173
			],
			[
				19,
				-151
			],
			[
				109,
				-145
			],
			[
				77,
				-316
			]
		],
		[
			[
				75512,
				50239
			],
			[
				140,
				30
			]
		],
		[
			[
				75652,
				50269
			],
			[
				67,
				-42
			],
			[
				11,
				171
			],
			[
				111,
				134
			],
			[
				224,
				-39
			],
			[
				108,
				133
			]
		],
		[
			[
				75576,
				51341
			],
			[
				-85,
				-278
			],
			[
				58,
				-112
			],
			[
				-32,
				-254
			],
			[
				21,
				-261
			],
			[
				-85,
				-45
			],
			[
				59,
				-152
			]
		],
		[
			[
				51444,
				45463
			],
			[
				960,
				28
			]
		],
		[
			[
				52404,
				45491
			],
			[
				-3,
				335
			]
		],
		[
			[
				51440,
				45798
			],
			[
				4,
				-335
			]
		],
		[
			[
				45335,
				47060
			],
			[
				149,
				13
			]
		],
		[
			[
				45882,
				48790
			],
			[
				-614,
				-57
			]
		],
		[
			[
				74511,
				47962
			],
			[
				-34,
				84
			]
		],
		[
			[
				74477,
				48046
			],
			[
				-83,
				-30
			],
			[
				-113,
				-266
			],
			[
				-176,
				-193
			],
			[
				-47,
				-387
			]
		],
		[
			[
				75277,
				53765
			],
			[
				61,
				-168
			],
			[
				-32,
				-238
			],
			[
				68,
				-169
			],
			[
				-29,
				-187
			]
		],
		[
			[
				75921,
				52495
			],
			[
				18,
				165
			],
			[
				-40,
				157
			],
			[
				91,
				198
			],
			[
				70,
				-38
			],
			[
				44,
				239
			]
		],
		[
			[
				76104,
				53216
			],
			[
				-84,
				90
			],
			[
				-48,
				241
			],
			[
				-132,
				-16
			],
			[
				-95,
				148
			]
		],
		[
			[
				75387,
				53794
			],
			[
				-110,
				-29
			]
		],
		[
			[
				91566,
				21208
			],
			[
				30,
				117
			],
			[
				553,
				121
			],
			[
				434,
				78
			]
		],
		[
			[
				92583,
				21524
			],
			[
				-115,
				320
			],
			[
				15,
				400
			]
		],
		[
			[
				92523,
				23682
			],
			[
				-178,
				65
			],
			[
				-212,
				144
			],
			[
				-228,
				-33
			]
		],
		[
			[
				91905,
				23858
			],
			[
				-24,
				-163
			],
			[
				-77,
				27
			]
		],
		[
			[
				13548,
				11714
			],
			[
				111,
				-23
			],
			[
				-1,
				-180
			],
			[
				90,
				-59
			],
			[
				40,
				72
			],
			[
				306,
				-87
			],
			[
				183,
				202
			],
			[
				52,
				-175
			],
			[
				120,
				-139
			],
			[
				32,
				38
			]
		],
		[
			[
				14167,
				13221
			],
			[
				-124,
				-62
			],
			[
				-56,
				-138
			],
			[
				-141,
				-78
			],
			[
				-276,
				-294
			],
			[
				-109,
				-209
			],
			[
				46,
				-261
			]
		],
		[
			[
				13507,
				12179
			],
			[
				1,
				-324
			],
			[
				40,
				-141
			]
		],
		[
			[
				21157,
				2810
			],
			[
				924,
				381
			]
		],
		[
			[
				22081,
				3191
			],
			[
				-68,
				225
			],
			[
				-19,
				215
			],
			[
				34,
				343
			],
			[
				48,
				70
			],
			[
				-45,
				394
			],
			[
				-120,
				539
			],
			[
				-43,
				-12
			],
			[
				-90,
				230
			],
			[
				-11,
				436
			],
			[
				19,
				302
			],
			[
				-98,
				148
			],
			[
				-30,
				211
			],
			[
				-95,
				54
			],
			[
				-23,
				285
			],
			[
				-70,
				44
			],
			[
				-127,
				-67
			],
			[
				-63,
				47
			],
			[
				46,
				258
			],
			[
				-23,
				263
			]
		],
		[
			[
				21303,
				7176
			],
			[
				-51,
				185
			],
			[
				-129,
				-68
			],
			[
				-12,
				-149
			],
			[
				-112,
				-52
			],
			[
				-13,
				-194
			],
			[
				-168,
				-111
			],
			[
				-55,
				131
			],
			[
				-138,
				-124
			],
			[
				-39,
				-162
			]
		],
		[
			[
				20586,
				6632
			],
			[
				12,
				-17
			],
			[
				277,
				-1905
			],
			[
				-38,
				-17
			],
			[
				90,
				-631
			],
			[
				50,
				19
			],
			[
				180,
				-1271
			]
		],
		[
			[
				82138,
				59726
			],
			[
				128,
				222
			],
			[
				14,
				449
			],
			[
				425,
				-109
			]
		],
		[
			[
				82705,
				60288
			],
			[
				349,
				693
			]
		],
		[
			[
				83054,
				60981
			],
			[
				-236,
				280
			],
			[
				67,
				178
			],
			[
				-127,
				65
			],
			[
				-104,
				121
			],
			[
				-69,
				-124
			],
			[
				-79,
				160
			],
			[
				-28,
				-226
			],
			[
				-215,
				380
			]
		],
		[
			[
				82263,
				61815
			],
			[
				-46,
				-100
			],
			[
				18,
				-226
			]
		],
		[
			[
				82099,
				60443
			],
			[
				21,
				-167
			],
			[
				-15,
				-344
			],
			[
				-112,
				-459
			]
		],
		[
			[
				57160,
				71947
			],
			[
				370,
				373
			],
			[
				235,
				-12
			],
			[
				268,
				-197
			],
			[
				10,
				122
			],
			[
				114,
				34
			],
			[
				64,
				-146
			],
			[
				85,
				62
			],
			[
				-31,
				79
			],
			[
				121,
				34
			]
		],
		[
			[
				58396,
				72296
			],
			[
				14,
				1156
			]
		],
		[
			[
				58410,
				73452
			],
			[
				-569,
				255
			],
			[
				-71,
				-137
			],
			[
				-105,
				21
			],
			[
				-98,
				-120
			]
		],
		[
			[
				57403,
				73472
			],
			[
				-118,
				-10
			],
			[
				-113,
				-87
			],
			[
				-7,
				-886
			]
		],
		[
			[
				47441,
				64910
			],
			[
				96,
				-66
			],
			[
				178,
				292
			]
		],
		[
			[
				48380,
				65605
			],
			[
				-26,
				1197
			]
		],
		[
			[
				48354,
				66802
			],
			[
				-137,
				106
			],
			[
				-188,
				-179
			],
			[
				-219,
				8
			],
			[
				-401,
				-534
			]
		],
		[
			[
				47409,
				66203
			],
			[
				8,
				-337
			]
		],
		[
			[
				58718,
				78180
			],
			[
				591,
				-93
			]
		],
		[
			[
				59400,
				78047
			],
			[
				17,
				393
			],
			[
				60,
				224
			],
			[
				-96,
				90
			],
			[
				83,
				234
			],
			[
				-76,
				199
			],
			[
				19,
				74
			]
		],
		[
			[
				59159,
				81665
			],
			[
				-377,
				21
			]
		],
		[
			[
				38461,
				54430
			],
			[
				116,
				18
			]
		],
		[
			[
				40597,
				54758
			],
			[
				-150,
				2992
			]
		],
		[
			[
				40447,
				57750
			],
			[
				-760,
				-116
			]
		],
		[
			[
				39687,
				57634
			],
			[
				-764,
				-116
			],
			[
				10,
				-166
			],
			[
				-846,
				-143
			]
		],
		[
			[
				38087,
				57209
			],
			[
				56,
				-480
			],
			[
				-41,
				-253
			],
			[
				68,
				-155
			],
			[
				-81,
				-242
			],
			[
				15,
				-212
			],
			[
				47,
				-92
			],
			[
				-24,
				-182
			],
			[
				150,
				-158
			],
			[
				119,
				2
			],
			[
				17,
				-429
			],
			[
				59,
				-235
			],
			[
				-42,
				-247
			],
			[
				31,
				-96
			]
		],
		[
			[
				50088,
				19931
			],
			[
				1499,
				68
			]
		],
		[
			[
				51587,
				19999
			],
			[
				43,
				3
			]
		],
		[
			[
				51630,
				20002
			],
			[
				-18,
				1321
			]
		],
		[
			[
				51612,
				21323
			],
			[
				-1114,
				-57
			]
		],
		[
			[
				45447,
				19850
			],
			[
				-23,
				579
			]
		],
		[
			[
				45424,
				20429
			],
			[
				-15,
				415
			]
		],
		[
			[
				45406,
				20844
			],
			[
				-1462,
				-173
			]
		],
		[
			[
				43944,
				20671
			],
			[
				-83,
				-11
			]
		],
		[
			[
				74812,
				36259
			],
			[
				-62,
				-889
			]
		],
		[
			[
				75446,
				35165
			],
			[
				21,
				96
			],
			[
				121,
				51
			],
			[
				76,
				-76
			],
			[
				173,
				183
			],
			[
				115,
				55
			]
		],
		[
			[
				75542,
				35585
			],
			[
				-252,
				64
			],
			[
				-57,
				193
			],
			[
				-104,
				116
			],
			[
				-38,
				219
			],
			[
				-101,
				191
			],
			[
				-158,
				170
			]
		],
		[
			[
				42286,
				56906
			],
			[
				84,
				-1923
			]
		],
		[
			[
				11204,
				13738
			],
			[
				421,
				221
			]
		],
		[
			[
				11625,
				13959
			],
			[
				-196,
				1118
			],
			[
				20,
				82
			],
			[
				155,
				79
			]
		],
		[
			[
				11604,
				15238
			],
			[
				-177,
				1062
			],
			[
				-188,
				-96
			],
			[
				-39,
				270
			],
			[
				-165,
				-22
			],
			[
				24,
				257
			]
		],
		[
			[
				11059,
				16709
			],
			[
				-525,
				-242
			]
		],
		[
			[
				10534,
				16467
			],
			[
				98,
				-326
			],
			[
				98,
				-473
			],
			[
				141,
				-558
			],
			[
				100,
				-638
			],
			[
				97,
				-228
			],
			[
				136,
				-506
			]
		],
		[
			[
				74239,
				50050
			],
			[
				-38,
				216
			],
			[
				79,
				18
			],
			[
				46,
				224
			],
			[
				92,
				-74
			]
		],
		[
			[
				74418,
				50615
			],
			[
				53,
				192
			],
			[
				-74,
				210
			]
		],
		[
			[
				73918,
				51246
			],
			[
				-3,
				-683
			],
			[
				-61,
				-130
			]
		],
		[
			[
				71605,
				48791
			],
			[
				-93,
				16
			]
		],
		[
			[
				71451,
				47788
			],
			[
				-12,
				-172
			],
			[
				52,
				-128
			]
		],
		[
			[
				73769,
				52707
			],
			[
				-38,
				156
			],
			[
				-203,
				140
			],
			[
				-57,
				220
			]
		],
		[
			[
				56560,
				28357
			],
			[
				-384,
				7
			],
			[
				-16,
				-333
			]
		],
		[
			[
				61814,
				45238
			],
			[
				224,
				-14
			]
		],
		[
			[
				62038,
				45224
			],
			[
				28,
				1228
			]
		],
		[
			[
				61053,
				46491
			],
			[
				-7,
				-994
			]
		],
		[
			[
				60334,
				42693
			],
			[
				564,
				-47
			]
		],
		[
			[
				40907,
				12130
			],
			[
				-43,
				749
			],
			[
				-59,
				-10
			],
			[
				-17,
				293
			]
		],
		[
			[
				40788,
				13162
			],
			[
				-193,
				-32
			],
			[
				-58,
				985
			],
			[
				-65,
				-11
			],
			[
				-76,
				1316
			]
		],
		[
			[
				40396,
				15420
			],
			[
				-648,
				-111
			],
			[
				-20,
				333
			],
			[
				-193,
				-34
			],
			[
				20,
				-333
			],
			[
				-193,
				-35
			]
		],
		[
			[
				39047,
				12216
			],
			[
				-2,
				-232
			],
			[
				54,
				-156
			],
			[
				117,
				46
			],
			[
				132,
				135
			],
			[
				217,
				9
			],
			[
				100,
				125
			],
			[
				19,
				-100
			],
			[
				123,
				136
			],
			[
				37,
				-109
			],
			[
				78,
				105
			]
		],
		[
			[
				51684,
				40099
			],
			[
				-14,
				1342
			]
		],
		[
			[
				47899,
				32964
			],
			[
				101,
				155
			],
			[
				266,
				155
			],
			[
				174,
				26
			],
			[
				133,
				139
			],
			[
				164,
				27
			]
		],
		[
			[
				48706,
				35947
			],
			[
				-40,
				-3
			]
		],
		[
			[
				47874,
				35889
			],
			[
				35,
				-1335
			],
			[
				-26,
				-3
			],
			[
				35,
				-1335
			],
			[
				-19,
				-252
			]
		],
		[
			[
				60484,
				20344
			],
			[
				378,
				-27
			]
		],
		[
			[
				60939,
				22290
			],
			[
				-193,
				14
			]
		],
		[
			[
				59602,
				22359
			],
			[
				22,
				-282
			],
			[
				43,
				-50
			]
		],
		[
			[
				40607,
				33031
			],
			[
				982,
				139
			]
		],
		[
			[
				41589,
				33170
			],
			[
				-116,
				2339
			]
		],
		[
			[
				41415,
				36674
			],
			[
				-25,
				513
			]
		],
		[
			[
				41390,
				37187
			],
			[
				-993,
				-152
			],
			[
				18,
				-338
			]
		],
		[
			[
				71387,
				66686
			],
			[
				122,
				-128
			],
			[
				229,
				-28
			],
			[
				224,
				224
			],
			[
				126,
				9
			]
		],
		[
			[
				72088,
				66763
			],
			[
				64,
				-11
			],
			[
				56,
				413
			],
			[
				-39,
				146
			],
			[
				12,
				227
			],
			[
				68,
				46
			]
		],
		[
			[
				72249,
				67584
			],
			[
				-144,
				329
			],
			[
				-129,
				24
			],
			[
				-69,
				206
			],
			[
				-64,
				7
			],
			[
				-37,
				201
			],
			[
				-94,
				39
			],
			[
				6,
				111
			],
			[
				-96,
				13
			],
			[
				-69,
				222
			],
			[
				-98,
				140
			],
			[
				-82,
				12
			]
		],
		[
			[
				70998,
				64876
			],
			[
				-3,
				-55
			]
		],
		[
			[
				70995,
				64821
			],
			[
				965,
				-124
			]
		],
		[
			[
				71960,
				64697
			],
			[
				191,
				-25
			],
			[
				54,
				158
			]
		],
		[
			[
				72205,
				64830
			],
			[
				-119,
				48
			],
			[
				-99,
				458
			],
			[
				-117,
				201
			],
			[
				-84,
				53
			],
			[
				14,
				160
			],
			[
				-150,
				364
			],
			[
				-49,
				-13
			],
			[
				-181,
				216
			],
			[
				16,
				136
			],
			[
				-76,
				71
			]
		],
		[
			[
				86368,
				43361
			],
			[
				258,
				-915
			]
		],
		[
			[
				87032,
				42793
			],
			[
				-70,
				185
			]
		],
		[
			[
				87008,
				43032
			],
			[
				80,
				99
			]
		],
		[
			[
				87088,
				43131
			],
			[
				-38,
				163
			],
			[
				183,
				16
			]
		],
		[
			[
				87233,
				43310
			],
			[
				27,
				254
			],
			[
				-69,
				72
			]
		],
		[
			[
				87191,
				43636
			],
			[
				-64,
				150
			],
			[
				21,
				145
			]
		],
		[
			[
				87148,
				43931
			],
			[
				-114,
				109
			],
			[
				-44,
				-67
			]
		],
		[
			[
				86990,
				43973
			],
			[
				-117,
				-184
			],
			[
				-263,
				-160
			],
			[
				-66,
				-156
			],
			[
				-82,
				51
			],
			[
				-94,
				-163
			]
		],
		[
			[
				84574,
				43125
			],
			[
				164,
				62
			],
			[
				51,
				-269
			],
			[
				118,
				-75
			],
			[
				-1,
				157
			],
			[
				77,
				186
			]
		],
		[
			[
				84934,
				43900
			],
			[
				-164,
				463
			],
			[
				-73,
				-29
			],
			[
				-103,
				559
			]
		],
		[
			[
				84594,
				44893
			],
			[
				-451,
				-480
			]
		],
		[
			[
				84143,
				44413
			],
			[
				88,
				-506
			],
			[
				93,
				-211
			],
			[
				21,
				98
			],
			[
				130,
				-347
			],
			[
				40,
				58
			],
			[
				64,
				-230
			],
			[
				-5,
				-150
			]
		],
		[
			[
				71964,
				63762
			],
			[
				109,
				230
			],
			[
				135,
				-15
			],
			[
				49,
				-159
			],
			[
				108,
				15
			],
			[
				40,
				-278
			],
			[
				-48,
				-68
			]
		],
		[
			[
				72514,
				65397
			],
			[
				-309,
				-567
			]
		],
		[
			[
				71960,
				64697
			],
			[
				-14,
				-255
			],
			[
				18,
				-680
			]
		],
		[
			[
				72510,
				66013
			],
			[
				-14,
				178
			],
			[
				-109,
				247
			],
			[
				-307,
				184
			],
			[
				8,
				141
			]
		],
		[
			[
				51691,
				89658
			],
			[
				334,
				-846
			]
		],
		[
			[
				52025,
				88812
			],
			[
				428,
				-618
			]
		],
		[
			[
				52453,
				88194
			],
			[
				109,
				-3
			],
			[
				28,
				78
			],
			[
				90,
				-57
			],
			[
				123,
				194
			],
			[
				131,
				55
			],
			[
				49,
				-81
			]
		],
		[
			[
				52983,
				88380
			],
			[
				151,
				296
			],
			[
				48,
				-64
			],
			[
				43,
				139
			]
		],
		[
			[
				53225,
				88751
			],
			[
				-46,
				102
			],
			[
				-3,
				247
			]
		],
		[
			[
				53176,
				89100
			],
			[
				-659,
				170
			],
			[
				43,
				173
			],
			[
				146,
				162
			]
		],
		[
			[
				52706,
				89605
			],
			[
				-250,
				191
			],
			[
				-124,
				213
			]
		],
		[
			[
				52332,
				90009
			],
			[
				-90,
				1
			]
		],
		[
			[
				50811,
				66709
			],
			[
				89,
				48
			],
			[
				-56,
				174
			],
			[
				53,
				307
			],
			[
				111,
				51
			],
			[
				103,
				-43
			],
			[
				47,
				67
			],
			[
				-63,
				377
			]
		],
		[
			[
				51095,
				67690
			],
			[
				-22,
				1627
			]
		],
		[
			[
				51073,
				69317
			],
			[
				-819,
				-31
			]
		],
		[
			[
				90360,
				19339
			],
			[
				97,
				-98
			],
			[
				55,
				-305
			],
			[
				-32,
				-275
			]
		],
		[
			[
				90480,
				18661
			],
			[
				332,
				144
			],
			[
				185,
				-7
			]
		],
		[
			[
				90997,
				18798
			],
			[
				191,
				238
			],
			[
				64,
				155
			],
			[
				-29,
				197
			],
			[
				56,
				6
			]
		],
		[
			[
				90593,
				20423
			],
			[
				-89,
				-389
			],
			[
				29,
				-233
			],
			[
				-55,
				-262
			],
			[
				-74,
				-152
			]
		],
		[
			[
				90404,
				19387
			],
			[
				-44,
				-48
			]
		],
		[
			[
				89680,
				47310
			],
			[
				173,
				-82
			],
			[
				94,
				66
			],
			[
				-17,
				223
			],
			[
				182,
				28
			],
			[
				57,
				-76
			]
		],
		[
			[
				90169,
				47469
			],
			[
				37,
				33
			],
			[
				-78,
				489
			],
			[
				-89,
				187
			],
			[
				17,
				400
			],
			[
				-87,
				298
			],
			[
				-116,
				68
			],
			[
				-30,
				120
			],
			[
				-27,
				-248
			],
			[
				-130,
				-347
			],
			[
				-15,
				-289
			],
			[
				29,
				-155
			],
			[
				-21,
				-251
			],
			[
				21,
				-464
			]
		],
		[
			[
				45335,
				16766
			],
			[
				46,
				-1206
			],
			[
				-93,
				-11
			],
			[
				36,
				-933
			]
		],
		[
			[
				76555,
				39960
			],
			[
				3,
				57
			],
			[
				163,
				-23
			],
			[
				10,
				219
			],
			[
				311,
				-41
			]
		],
		[
			[
				77055,
				40452
			],
			[
				29,
				581
			]
		],
		[
			[
				77084,
				41033
			],
			[
				-690,
				96
			]
		],
		[
			[
				82881,
				66625
			],
			[
				471,
				-731
			],
			[
				54,
				198
			],
			[
				113,
				54
			],
			[
				120,
				-32
			]
		],
		[
			[
				83639,
				66114
			],
			[
				78,
				208
			],
			[
				67,
				45
			],
			[
				-47,
				144
			],
			[
				379,
				386
			]
		],
		[
			[
				84116,
				66897
			],
			[
				-45,
				58
			],
			[
				209,
				261
			],
			[
				-18,
				165
			],
			[
				-90,
				22
			],
			[
				4,
				270
			],
			[
				-277,
				-52
			],
			[
				-186,
				27
			]
		],
		[
			[
				83713,
				67648
			],
			[
				19,
				-116
			],
			[
				-94,
				-318
			],
			[
				36,
				-234
			],
			[
				-172,
				-75
			],
			[
				-263,
				73
			],
			[
				-110,
				-121
			],
			[
				-84,
				-175
			],
			[
				-164,
				-57
			]
		],
		[
			[
				49025,
				27868
			],
			[
				442,
				18
			]
		],
		[
			[
				49467,
				27886
			],
			[
				597,
				27
			]
		],
		[
			[
				72826,
				56375
			],
			[
				4,
				-341
			]
		],
		[
			[
				73443,
				56685
			],
			[
				-54,
				242
			],
			[
				-171,
				146
			],
			[
				-265,
				-47
			]
		],
		[
			[
				72953,
				57026
			],
			[
				-89,
				-50
			],
			[
				-41,
				-120
			],
			[
				3,
				-481
			]
		],
		[
			[
				76605,
				56569
			],
			[
				45,
				-177
			],
			[
				-23,
				-250
			],
			[
				41,
				-286
			]
		],
		[
			[
				76668,
				55856
			],
			[
				123,
				-118
			],
			[
				113,
				30
			],
			[
				55,
				-112
			],
			[
				86,
				-2
			],
			[
				7,
				-151
			],
			[
				92,
				-22
			]
		],
		[
			[
				77144,
				55481
			],
			[
				59,
				-40
			],
			[
				134,
				67
			]
		],
		[
			[
				77337,
				55508
			],
			[
				77,
				394
			]
		],
		[
			[
				77414,
				55902
			],
			[
				-54,
				115
			],
			[
				-78,
				-115
			],
			[
				-191,
				350
			],
			[
				-18,
				173
			]
		],
		[
			[
				77073,
				56425
			],
			[
				-133,
				2
			],
			[
				-160,
				182
			],
			[
				27,
				149
			],
			[
				-87,
				-60
			],
			[
				25,
				167
			]
		],
		[
			[
				50860,
				83569
			],
			[
				-179,
				257
			],
			[
				-434,
				323
			]
		],
		[
			[
				50247,
				84149
			],
			[
				-66,
				-99
			],
			[
				8,
				-128
			],
			[
				-69,
				-39
			],
			[
				-1,
				-158
			],
			[
				81,
				-65
			],
			[
				-148,
				-101
			],
			[
				-23,
				105
			],
			[
				-237,
				-171
			],
			[
				-63,
				72
			],
			[
				-124,
				-21
			]
		],
		[
			[
				27290,
				47398
			],
			[
				-376,
				-87
			],
			[
				-655,
				-211
			]
		],
		[
			[
				36815,
				40748
			],
			[
				52,
				268
			],
			[
				129,
				73
			],
			[
				77,
				-147
			],
			[
				46,
				211
			],
			[
				172,
				26
			],
			[
				89,
				116
			],
			[
				66,
				-94
			],
			[
				36,
				135
			],
			[
				163,
				15
			],
			[
				218,
				-126
			],
			[
				76,
				104
			],
			[
				122,
				-187
			],
			[
				21,
				-217
			],
			[
				79,
				-86
			]
		],
		[
			[
				38161,
				40839
			],
			[
				77,
				59
			],
			[
				-26,
				201
			],
			[
				66,
				251
			],
			[
				102,
				155
			],
			[
				67,
				259
			]
		],
		[
			[
				38447,
				41764
			],
			[
				-54,
				216
			],
			[
				56,
				227
			],
			[
				-28,
				412
			],
			[
				-91,
				74
			],
			[
				-23,
				284
			],
			[
				27,
				39
			]
		],
		[
			[
				38334,
				43016
			],
			[
				-50,
				150
			],
			[
				7,
				155
			]
		],
		[
			[
				36727,
				42751
			],
			[
				2,
				-299
			],
			[
				93,
				-1300
			],
			[
				-34,
				-7
			],
			[
				27,
				-397
			]
		],
		[
			[
				9578,
				45787
			],
			[
				-39,
				-343
			],
			[
				-88,
				-228
			],
			[
				-87,
				54
			],
			[
				-226,
				-115
			],
			[
				-135,
				-298
			],
			[
				-90,
				-418
			]
		],
		[
			[
				8913,
				44439
			],
			[
				7,
				-316
			],
			[
				130,
				50
			],
			[
				15,
				-93
			],
			[
				153,
				72
			],
			[
				44,
				-262
			]
		],
		[
			[
				78313,
				67756
			],
			[
				336,
				62
			],
			[
				-18,
				269
			],
			[
				42,
				118
			],
			[
				82,
				-138
			]
		],
		[
			[
				78028,
				67829
			],
			[
				186,
				26
			],
			[
				99,
				-99
			]
		],
		[
			[
				78465,
				67070
			],
			[
				243,
				-501
			]
		],
		[
			[
				79247,
				67280
			],
			[
				18,
				54
			]
		],
		[
			[
				78313,
				67756
			],
			[
				17,
				-283
			],
			[
				182,
				-240
			],
			[
				-47,
				-163
			]
		],
		[
			[
				29681,
				26692
			],
			[
				358,
				119
			]
		],
		[
			[
				30039,
				26811
			],
			[
				248,
				71
			],
			[
				42,
				330
			],
			[
				94,
				0
			],
			[
				138,
				214
			]
		],
		[
			[
				30561,
				27426
			],
			[
				-65,
				703
			]
		],
		[
			[
				30392,
				29251
			],
			[
				-875,
				-243
			]
		],
		[
			[
				29517,
				29008
			],
			[
				99,
				-991
			],
			[
				-375,
				-109
			],
			[
				32,
				-318
			],
			[
				-353,
				-104
			],
			[
				17,
				-165
			],
			[
				-90,
				-84
			],
			[
				-663,
				-197
			],
			[
				79,
				-761
			]
		],
		[
			[
				85045,
				40360
			],
			[
				1054,
				-341
			]
		],
		[
			[
				85922,
				41636
			],
			[
				-70,
				34
			]
		],
		[
			[
				85852,
				41670
			],
			[
				-66,
				-28
			],
			[
				-34,
				-340
			],
			[
				-55,
				-10
			],
			[
				-41,
				-188
			],
			[
				-56,
				-30
			]
		],
		[
			[
				85600,
				41074
			],
			[
				-73,
				-58
			],
			[
				-45,
				-175
			],
			[
				72,
				-54
			],
			[
				-10,
				-114
			],
			[
				-196,
				3
			],
			[
				-46,
				82
			],
			[
				-86,
				-50
			]
		],
		[
			[
				84688,
				40813
			],
			[
				-33,
				-143
			],
			[
				44,
				-80
			],
			[
				-62,
				-99
			]
		],
		[
			[
				67535,
				36646
			],
			[
				380,
				-71
			],
			[
				11,
				167
			]
		],
		[
			[
				67926,
				36742
			],
			[
				10,
				168
			],
			[
				191,
				-37
			],
			[
				21,
				334
			],
			[
				192,
				-35
			],
			[
				27,
				339
			],
			[
				433,
				-67
			]
		],
		[
			[
				68800,
				37444
			],
			[
				32,
				661
			]
		],
		[
			[
				67598,
				37648
			],
			[
				-63,
				-1002
			]
		],
		[
			[
				63142,
				35962
			],
			[
				1225,
				-119
			]
		],
		[
			[
				64367,
				35843
			],
			[
				27,
				112
			],
			[
				-9,
				288
			]
		],
		[
			[
				64385,
				36243
			],
			[
				-42,
				489
			],
			[
				-87,
				87
			]
		],
		[
			[
				89887,
				44355
			],
			[
				415,
				-202
			],
			[
				64,
				-257
			],
			[
				-103,
				-367
			]
		],
		[
			[
				90263,
				43529
			],
			[
				495,
				-186
			]
		],
		[
			[
				90758,
				43343
			],
			[
				1,
				502
			],
			[
				-23,
				60
			],
			[
				-49,
				870
			],
			[
				-48,
				291
			]
		],
		[
			[
				90639,
				45066
			],
			[
				-635,
				369
			]
		],
		[
			[
				90185,
				39144
			],
			[
				155,
				154
			]
		],
		[
			[
				90340,
				39298
			],
			[
				260,
				256
			],
			[
				42,
				383
			]
		],
		[
			[
				90624,
				40522
			],
			[
				-194,
				11
			],
			[
				-10,
				-74
			],
			[
				-141,
				57
			],
			[
				-28,
				132
			],
			[
				-98,
				-203
			],
			[
				-210,
				-106
			],
			[
				-117,
				-189
			],
			[
				-126,
				-84
			]
		],
		[
			[
				89700,
				40066
			],
			[
				-46,
				-264
			],
			[
				75,
				-42
			],
			[
				165,
				-468
			],
			[
				309,
				285
			],
			[
				-18,
				-433
			]
		],
		[
			[
				89327,
				39227
			],
			[
				55,
				-243
			]
		],
		[
			[
				89382,
				38984
			],
			[
				46,
				-332
			],
			[
				67,
				-139
			]
		],
		[
			[
				89495,
				38513
			],
			[
				89,
				235
			],
			[
				101,
				106
			],
			[
				222,
				7
			],
			[
				278,
				283
			]
		],
		[
			[
				89700,
				40066
			],
			[
				-124,
				-178
			],
			[
				-129,
				-37
			],
			[
				-35,
				-304
			],
			[
				18,
				-154
			],
			[
				-86,
				-78
			]
		],
		[
			[
				89344,
				39315
			],
			[
				-17,
				-88
			]
		],
		[
			[
				73651,
				72252
			],
			[
				73,
				-79
			],
			[
				55,
				220
			],
			[
				789,
				-158
			]
		],
		[
			[
				74634,
				72563
			],
			[
				-30,
				34
			],
			[
				29,
				475
			],
			[
				-158,
				33
			],
			[
				-145,
				106
			],
			[
				-94,
				456
			]
		],
		[
			[
				74236,
				73667
			],
			[
				-251,
				46
			],
			[
				-19,
				-332
			],
			[
				-177,
				33
			],
			[
				-36,
				-303
			],
			[
				-194,
				21
			]
		],
		[
			[
				91088,
				34739
			],
			[
				-17,
				-144
			],
			[
				60,
				-77
			]
		],
		[
			[
				91131,
				34518
			],
			[
				141,
				-122
			]
		],
		[
			[
				91272,
				34396
			],
			[
				57,
				203
			],
			[
				-100,
				332
			],
			[
				-188,
				184
			]
		],
		[
			[
				91041,
				35115
			],
			[
				-3,
				-167
			],
			[
				50,
				-209
			]
		],
		[
			[
				59514,
				57583
			],
			[
				-479,
				20
			]
		],
		[
			[
				82164,
				67062
			],
			[
				236,
				295
			]
		],
		[
			[
				82400,
				67357
			],
			[
				-255,
				405
			],
			[
				10,
				128
			],
			[
				-66,
				74
			],
			[
				46,
				194
			],
			[
				-118,
				307
			],
			[
				-124,
				125
			]
		],
		[
			[
				81893,
				68590
			],
			[
				-52,
				-338
			],
			[
				-115,
				-135
			],
			[
				15,
				-76
			],
			[
				-85,
				-143
			],
			[
				-3,
				-270
			],
			[
				-106,
				-116
			]
		],
		[
			[
				81547,
				67512
			],
			[
				-149,
				-154
			]
		],
		[
			[
				68650,
				58182
			],
			[
				59,
				-115
			],
			[
				60,
				-283
			],
			[
				86,
				-172
			],
			[
				-45,
				-191
			],
			[
				4,
				-187
			],
			[
				186,
				-31
			]
		],
		[
			[
				69149,
				59192
			],
			[
				-10,
				99
			]
		],
		[
			[
				95022,
				26344
			],
			[
				30,
				-96
			]
		],
		[
			[
				95052,
				26248
			],
			[
				-2,
				-104
			]
		],
		[
			[
				95050,
				26144
			],
			[
				17,
				-182
			],
			[
				92,
				18
			],
			[
				31,
				-357
			]
		],
		[
			[
				95190,
				25623
			],
			[
				104,
				-44
			]
		],
		[
			[
				95294,
				25579
			],
			[
				-26,
				158
			],
			[
				93,
				186
			],
			[
				-84,
				49
			],
			[
				44,
				115
			]
		],
		[
			[
				95321,
				26087
			],
			[
				-74,
				66
			],
			[
				14,
				106
			],
			[
				-99,
				111
			],
			[
				-12,
				135
			],
			[
				-128,
				-161
			]
		],
		[
			[
				75952,
				58866
			],
			[
				81,
				-227
			],
			[
				-82,
				-170
			],
			[
				25,
				-180
			],
			[
				-39,
				-176
			]
		],
		[
			[
				76903,
				58819
			],
			[
				-193,
				70
			],
			[
				-85,
				168
			],
			[
				-100,
				48
			],
			[
				-40,
				169
			],
			[
				-69,
				75
			]
		],
		[
			[
				76400,
				59340
			],
			[
				33,
				-101
			],
			[
				-137,
				-80
			],
			[
				16,
				-147
			],
			[
				-150,
				78
			],
			[
				-210,
				-224
			]
		],
		[
			[
				34579,
				48625
			],
			[
				232,
				48
			],
			[
				99,
				-1405
			]
		],
		[
			[
				34910,
				47268
			],
			[
				150,
				-2104
			]
		],
		[
			[
				37003,
				48635
			],
			[
				-1298,
				-261
			],
			[
				-73,
				1068
			]
		],
		[
			[
				35632,
				49442
			],
			[
				-981,
				-201
			]
		],
		[
			[
				34651,
				49241
			],
			[
				-7,
				-399
			],
			[
				-65,
				-217
			]
		],
		[
			[
				35632,
				49442
			],
			[
				-49,
				733
			]
		],
		[
			[
				35583,
				50175
			],
			[
				-115,
				-44
			],
			[
				-128,
				62
			],
			[
				-82,
				1007
			],
			[
				37,
				9
			],
			[
				-75,
				965
			]
		],
		[
			[
				34603,
				52049
			],
			[
				59,
				-831
			]
		],
		[
			[
				34600,
				49939
			],
			[
				-31,
				-112
			],
			[
				75,
				-9
			],
			[
				83,
				-225
			],
			[
				-84,
				-165
			],
			[
				8,
				-187
			]
		],
		[
			[
				52212,
				91912
			],
			[
				91,
				0
			]
		],
		[
			[
				52303,
				91912
			],
			[
				-150,
				565
			],
			[
				-106,
				565
			]
		],
		[
			[
				51986,
				93040
			],
			[
				34,
				-280
			],
			[
				119,
				-588
			],
			[
				73,
				-260
			]
		],
		[
			[
				50874,
				91646
			],
			[
				106,
				64
			],
			[
				93,
				237
			],
			[
				1026,
				20
			]
		],
		[
			[
				52099,
				91967
			],
			[
				-85,
				418
			],
			[
				-9,
				145
			],
			[
				-89,
				344
			],
			[
				-192,
				171
			],
			[
				-76,
				-62
			],
			[
				98,
				-65
			],
			[
				24,
				-191
			],
			[
				-55,
				-11
			],
			[
				-192,
				284
			],
			[
				-55,
				12
			]
		],
		[
			[
				73423,
				71731
			],
			[
				84,
				-144
			],
			[
				149,
				-5
			],
			[
				5,
				-176
			]
		],
		[
			[
				73661,
				71406
			],
			[
				165,
				-35
			],
			[
				-23,
				-332
			],
			[
				159,
				-32
			],
			[
				28,
				-60
			]
		],
		[
			[
				73990,
				70947
			],
			[
				72,
				95
			],
			[
				337,
				211
			],
			[
				95,
				-19
			],
			[
				30,
				332
			]
		],
		[
			[
				60337,
				69576
			],
			[
				342,
				-1
			],
			[
				61,
				-62
			],
			[
				188,
				113
			],
			[
				172,
				-55
			]
		],
		[
			[
				61100,
				69571
			],
			[
				64,
				39
			],
			[
				20,
				150
			],
			[
				78,
				-47
			],
			[
				18,
				128
			],
			[
				80,
				88
			],
			[
				147,
				-122
			]
		],
		[
			[
				61914,
				70304
			],
			[
				48,
				115
			],
			[
				-53,
				134
			],
			[
				69,
				242
			],
			[
				78,
				95
			]
		],
		[
			[
				62056,
				70890
			],
			[
				-1221,
				55
			]
		],
		[
			[
				67982,
				50007
			],
			[
				381,
				-55
			]
		],
		[
			[
				68363,
				49952
			],
			[
				277,
				-54
			]
		],
		[
			[
				68645,
				49908
			],
			[
				9,
				103
			]
		],
		[
			[
				68654,
				50011
			],
			[
				117,
				326
			],
			[
				-67,
				46
			],
			[
				-56,
				194
			],
			[
				71,
				-72
			],
			[
				-2,
				128
			],
			[
				-124,
				130
			],
			[
				62,
				228
			],
			[
				-81,
				233
			],
			[
				95,
				83
			],
			[
				-136,
				34
			]
		],
		[
			[
				68533,
				51341
			],
			[
				-80,
				-53
			],
			[
				-415,
				55
			]
		],
		[
			[
				89428,
				44235
			],
			[
				4,
				-511
			],
			[
				88,
				-133
			],
			[
				81,
				-244
			]
		],
		[
			[
				89601,
				43347
			],
			[
				62,
				375
			],
			[
				600,
				-193
			]
		],
		[
			[
				89478,
				44666
			],
			[
				-101,
				-61
			],
			[
				51,
				-370
			]
		],
		[
			[
				16423,
				18176
			],
			[
				-679,
				-304
			],
			[
				-50,
				320
			],
			[
				-248,
				-114
			],
			[
				-50,
				320
			],
			[
				-183,
				-86
			]
		],
		[
			[
				15213,
				18312
			],
			[
				-185,
				-96
			],
			[
				-943,
				-441
			]
		],
		[
			[
				85414,
				46980
			],
			[
				116,
				-535
			]
		],
		[
			[
				85969,
				46364
			],
			[
				65,
				-25
			],
			[
				241,
				85
			],
			[
				199,
				203
			]
		],
		[
			[
				86474,
				46627
			],
			[
				-68,
				1119
			]
		],
		[
			[
				86406,
				47746
			],
			[
				-171,
				-68
			],
			[
				-139,
				-290
			],
			[
				-222,
				-159
			]
		],
		[
			[
				90382,
				17556
			],
			[
				529,
				-235
			],
			[
				451,
				-160
			]
		],
		[
			[
				91449,
				18019
			],
			[
				-63,
				-52
			],
			[
				-199,
				146
			],
			[
				-23,
				381
			],
			[
				-96,
				-34
			],
			[
				-71,
				338
			]
		],
		[
			[
				90480,
				18661
			],
			[
				-62,
				-118
			],
			[
				39,
				-269
			],
			[
				-16,
				-314
			],
			[
				-100,
				-134
			],
			[
				41,
				-270
			]
		],
		[
			[
				90812,
				53263
			],
			[
				126,
				18
			],
			[
				105,
				211
			],
			[
				24,
				151
			],
			[
				-98,
				5
			],
			[
				-5,
				-151
			],
			[
				-152,
				-234
			]
		],
		[
			[
				91116,
				56056
			],
			[
				351,
				-419
			],
			[
				-18,
				-831
			],
			[
				-39,
				-437
			],
			[
				-172,
				-615
			],
			[
				61,
				90
			],
			[
				147,
				522
			],
			[
				34,
				449
			],
			[
				23,
				962
			],
			[
				-146,
				80
			],
			[
				-226,
				251
			]
		],
		[
			[
				91131,
				56108
			],
			[
				-15,
				-52
			]
		],
		[
			[
				90555,
				52208
			],
			[
				35,
				-29
			]
		],
		[
			[
				90590,
				52179
			],
			[
				152,
				408
			],
			[
				149,
				313
			],
			[
				332,
				797
			],
			[
				-58,
				-26
			],
			[
				-222,
				-529
			],
			[
				-74,
				-129
			],
			[
				-83,
				-5
			],
			[
				-133,
				-540
			],
			[
				-98,
				-260
			]
		],
		[
			[
				90729,
				54688
			],
			[
				-96,
				-305
			],
			[
				-198,
				72
			]
		],
		[
			[
				90435,
				54455
			],
			[
				1,
				-391
			],
			[
				-45,
				-493
			],
			[
				69,
				-195
			],
			[
				-43,
				-45
			],
			[
				161,
				-140
			],
			[
				137,
				109
			],
			[
				157,
				433
			],
			[
				-3,
				160
			],
			[
				82,
				274
			],
			[
				-39,
				100
			],
			[
				43,
				167
			],
			[
				-58,
				205
			],
			[
				-168,
				49
			]
		],
		[
			[
				90625,
				34603
			],
			[
				97,
				-13
			],
			[
				-13,
				205
			],
			[
				-50,
				88
			]
		],
		[
			[
				90659,
				34883
			],
			[
				-83,
				190
			],
			[
				143,
				196
			],
			[
				-168,
				373
			],
			[
				-35,
				187
			]
		],
		[
			[
				90516,
				35829
			],
			[
				-64,
				-40
			],
			[
				-105,
				103
			],
			[
				-67,
				-168
			]
		],
		[
			[
				90280,
				35724
			],
			[
				-127,
				-312
			],
			[
				42,
				31
			],
			[
				63,
				-343
			],
			[
				-89,
				-500
			]
		],
		[
			[
				90738,
				34298
			],
			[
				150,
				9
			],
			[
				268,
				81
			]
		],
		[
			[
				91156,
				34388
			],
			[
				-25,
				130
			]
		],
		[
			[
				91088,
				34739
			],
			[
				-143,
				60
			],
			[
				-28,
				-56
			],
			[
				-258,
				140
			]
		],
		[
			[
				89883,
				28982
			],
			[
				259,
				115
			],
			[
				383,
				-83
			],
			[
				14,
				-297
			],
			[
				60,
				-51
			],
			[
				173,
				127
			]
		],
		[
			[
				90772,
				28793
			],
			[
				-5,
				194
			]
		],
		[
			[
				90767,
				28987
			],
			[
				39,
				687
			],
			[
				53,
				101
			],
			[
				123,
				1059
			]
		],
		[
			[
				90313,
				31098
			],
			[
				-72,
				-183
			],
			[
				-342,
				-264
			],
			[
				130,
				-566
			],
			[
				-597,
				-322
			]
		],
		[
			[
				89432,
				29763
			],
			[
				451,
				-781
			]
		],
		[
			[
				78666,
				62092
			],
			[
				78,
				-208
			],
			[
				498,
				-695
			]
		],
		[
			[
				79242,
				61189
			],
			[
				79,
				182
			],
			[
				-14,
				95
			],
			[
				56,
				450
			],
			[
				89,
				257
			],
			[
				209,
				219
			]
		],
		[
			[
				79661,
				62392
			],
			[
				-686,
				1239
			]
		],
		[
			[
				78975,
				63631
			],
			[
				-28,
				-218
			],
			[
				-56,
				-73
			]
		],
		[
			[
				78891,
				63340
			],
			[
				-53,
				-186
			],
			[
				-80,
				-76
			],
			[
				-78,
				-344
			],
			[
				-46,
				-73
			],
			[
				-198,
				46
			]
		],
		[
			[
				56630,
				77211
			],
			[
				259,
				-393
			]
		],
		[
			[
				57924,
				77951
			],
			[
				207,
				191
			],
			[
				51,
				141
			],
			[
				122,
				137
			]
		],
		[
			[
				57681,
				78692
			],
			[
				-200,
				-93
			]
		],
		[
			[
				56717,
				77358
			],
			[
				-87,
				-147
			]
		],
		[
			[
				45203,
				26316
			],
			[
				-34,
				639
			],
			[
				-49,
				1336
			]
		],
		[
			[
				45120,
				28291
			],
			[
				-19,
				-2
			],
			[
				-40,
				1095
			],
			[
				-27,
				72
			],
			[
				-168,
				-64
			]
		],
		[
			[
				44866,
				29392
			],
			[
				-60,
				45
			],
			[
				-1019,
				-121
			]
		],
		[
			[
				81997,
				65855
			],
			[
				73,
				93
			],
			[
				225,
				106
			],
			[
				268,
				180
			],
			[
				195,
				200
			],
			[
				111,
				209
			]
		],
		[
			[
				82869,
				66643
			],
			[
				-161,
				245
			],
			[
				46,
				112
			],
			[
				-42,
				141
			],
			[
				-72,
				-145
			],
			[
				-240,
				361
			]
		],
		[
			[
				79588,
				54521
			],
			[
				71,
				121
			],
			[
				-156,
				450
			],
			[
				110,
				104
			],
			[
				50,
				165
			],
			[
				95,
				66
			]
		],
		[
			[
				79758,
				55427
			],
			[
				-129,
				596
			],
			[
				-79,
				77
			]
		],
		[
			[
				79283,
				55968
			],
			[
				-86,
				-276
			],
			[
				-155,
				-38
			]
		],
		[
			[
				79042,
				55654
			],
			[
				25,
				-567
			]
		],
		[
			[
				82185,
				53041
			],
			[
				11,
				136
			],
			[
				-94,
				141
			],
			[
				-128,
				-32
			],
			[
				-32,
				178
			],
			[
				39,
				225
			]
		],
		[
			[
				81981,
				53689
			],
			[
				-397,
				105
			]
		],
		[
			[
				81584,
				53794
			],
			[
				-166,
				-311
			]
		],
		[
			[
				81379,
				53411
			],
			[
				-241,
				-462
			]
		],
		[
			[
				81138,
				52949
			],
			[
				385,
				-288
			],
			[
				117,
				-133
			]
		],
		[
			[
				12448,
				36142
			],
			[
				135,
				-107
			]
		],
		[
			[
				11533,
				38119
			],
			[
				-227,
				-11
			],
			[
				-67,
				103
			],
			[
				7,
				-473
			],
			[
				59,
				-100
			],
			[
				2,
				-371
			],
			[
				59,
				-424
			]
		],
		[
			[
				25451,
				29694
			],
			[
				115,
				3
			]
		],
		[
			[
				26360,
				29429
			],
			[
				165,
				-21
			],
			[
				87,
				259
			],
			[
				108,
				47
			]
		],
		[
			[
				26720,
				29714
			],
			[
				100,
				23
			],
			[
				-16,
				144
			],
			[
				278,
				91
			],
			[
				-101,
				988
			]
		],
		[
			[
				26849,
				32207
			],
			[
				-1452,
				-421
			],
			[
				-238,
				-100
			]
		],
		[
			[
				24771,
				31555
			],
			[
				176,
				-1600
			],
			[
				348,
				116
			],
			[
				45,
				-367
			],
			[
				111,
				-10
			]
		],
		[
			[
				78314,
				59574
			],
			[
				150,
				-140
			],
			[
				123,
				-436
			]
		],
		[
			[
				78587,
				58998
			],
			[
				262,
				401
			],
			[
				97,
				529
			],
			[
				35,
				62
			]
		],
		[
			[
				78981,
				59990
			],
			[
				-258,
				256
			],
			[
				-59,
				115
			]
		],
		[
			[
				78664,
				60361
			],
			[
				-40,
				-58
			],
			[
				-196,
				161
			]
		],
		[
			[
				78237,
				60620
			],
			[
				-94,
				-80
			],
			[
				108,
				-329
			],
			[
				-34,
				-116
			],
			[
				91,
				-260
			],
			[
				6,
				-261
			]
		],
		[
			[
				82640,
				91497
			],
			[
				986,
				-250
			]
		],
		[
			[
				83626,
				91247
			],
			[
				91,
				960
			]
		],
		[
			[
				83215,
				93039
			],
			[
				-163,
				-330
			],
			[
				-224,
				-187
			],
			[
				-16,
				-142
			],
			[
				-106,
				19
			],
			[
				34,
				134
			],
			[
				-94,
				61
			],
			[
				-113,
				-560
			],
			[
				-102,
				-144
			],
			[
				106,
				-95
			],
			[
				76,
				111
			],
			[
				27,
				-409
			]
		],
		[
			[
				82261,
				91868
			],
			[
				41,
				48
			],
			[
				195,
				645
			],
			[
				193,
				133
			],
			[
				47,
				-69
			],
			[
				103,
				43
			],
			[
				-160,
				144
			],
			[
				-215,
				-201
			],
			[
				-193,
				-580
			],
			[
				-11,
				-163
			]
		],
		[
			[
				82218,
				91530
			],
			[
				109,
				51
			]
		],
		[
			[
				82327,
				91581
			],
			[
				-58,
				38
			],
			[
				-21,
				169
			],
			[
				-30,
				-258
			]
		],
		[
			[
				15213,
				18312
			],
			[
				-140,
				938
			],
			[
				175,
				98
			],
			[
				-99,
				641
			],
			[
				367,
				181
			],
			[
				-49,
				320
			],
			[
				573,
				257
			],
			[
				-49,
				323
			],
			[
				187,
				83
			],
			[
				-49,
				321
			],
			[
				560,
				250
			]
		],
		[
			[
				16689,
				21724
			],
			[
				-46,
				328
			]
		],
		[
			[
				14409,
				21015
			],
			[
				-1040,
				-487
			]
		],
		[
			[
				13369,
				20528
			],
			[
				151,
				-523
			],
			[
				43,
				-360
			],
			[
				117,
				-160
			],
			[
				65,
				51
			],
			[
				133,
				-307
			],
			[
				7,
				-141
			],
			[
				139,
				-182
			],
			[
				47,
				-604
			]
		],
		[
			[
				21540,
				9746
			],
			[
				203,
				82
			]
		],
		[
			[
				21743,
				9828
			],
			[
				1164,
				461
			]
		],
		[
			[
				22843,
				10779
			],
			[
				-286,
				2174
			]
		],
		[
			[
				22557,
				12953
			],
			[
				-57,
				433
			]
		],
		[
			[
				22500,
				13386
			],
			[
				-255,
				-91
			],
			[
				-12,
				-156
			]
		],
		[
			[
				60587,
				50062
			],
			[
				225,
				-2
			]
		],
		[
			[
				61342,
				50382
			],
			[
				1,
				167
			]
		],
		[
			[
				61001,
				51619
			],
			[
				-191,
				7
			],
			[
				-132,
				-277
			],
			[
				-35,
				-286
			],
			[
				-64,
				1
			],
			[
				-1,
				-160
			],
			[
				-97,
				-29
			]
		],
		[
			[
				60481,
				50875
			],
			[
				0,
				-488
			],
			[
				98,
				-5
			],
			[
				8,
				-320
			]
		],
		[
			[
				69896,
				36148
			],
			[
				165,
				-256
			],
			[
				144,
				-313
			],
			[
				170,
				-897
			],
			[
				169,
				-512
			]
		],
		[
			[
				70544,
				34170
			],
			[
				231,
				-42
			],
			[
				40,
				658
			]
		],
		[
			[
				70815,
				34786
			],
			[
				67,
				1194
			]
		],
		[
			[
				9582,
				35629
			],
			[
				994,
				476
			],
			[
				17,
				-108
			],
			[
				210,
				105
			],
			[
				-20,
				109
			],
			[
				197,
				95
			]
		],
		[
			[
				10902,
				36589
			],
			[
				-105,
				204
			],
			[
				-32,
				223
			],
			[
				28,
				448
			],
			[
				67,
				39
			],
			[
				-2,
				328
			],
			[
				-66,
				225
			]
		],
		[
			[
				9955,
				37654
			],
			[
				-90,
				-196
			],
			[
				29,
				-199
			],
			[
				-109,
				-216
			],
			[
				29,
				-255
			],
			[
				61,
				-178
			],
			[
				-64,
				-176
			],
			[
				-215,
				-146
			],
			[
				-103,
				-318
			],
			[
				-8,
				-128
			],
			[
				97,
				-213
			]
		],
		[
			[
				62183,
				59855
			],
			[
				165,
				-304
			],
			[
				77,
				-2
			]
		],
		[
			[
				62425,
				59549
			],
			[
				443,
				-16
			],
			[
				2,
				171
			],
			[
				193,
				-9
			]
		],
		[
			[
				63063,
				59695
			],
			[
				285,
				-22
			]
		],
		[
			[
				63348,
				59673
			],
			[
				-57,
				305
			],
			[
				-133,
				194
			],
			[
				-71,
				263
			],
			[
				33,
				177
			],
			[
				-44,
				46
			],
			[
				-5,
				444
			],
			[
				-373,
				14
			]
		],
		[
			[
				62698,
				61116
			],
			[
				-382,
				15
			]
		],
		[
			[
				81138,
				52949
			],
			[
				-67,
				171
			],
			[
				-302,
				98
			]
		],
		[
			[
				80497,
				52551
			],
			[
				140,
				-297
			],
			[
				98,
				-139
			],
			[
				201,
				44
			],
			[
				334,
				-289
			]
		],
		[
			[
				62427,
				58346
			],
			[
				30,
				340
			],
			[
				12,
				633
			],
			[
				-48,
				2
			],
			[
				4,
				228
			]
		],
		[
			[
				52600,
				90628
			],
			[
				121,
				-113
			],
			[
				276,
				-692
			],
			[
				109,
				-40
			]
		],
		[
			[
				53106,
				89783
			],
			[
				-55,
				303
			]
		],
		[
			[
				53051,
				90086
			],
			[
				-303,
				572
			],
			[
				-82,
				272
			]
		],
		[
			[
				52666,
				90930
			],
			[
				-66,
				-302
			]
		],
		[
			[
				53176,
				89100
			],
			[
				-17,
				177
			]
		],
		[
			[
				53159,
				89277
			],
			[
				-22,
				211
			]
		],
		[
			[
				53137,
				89488
			],
			[
				-249,
				352
			],
			[
				-182,
				-97
			],
			[
				0,
				-138
			]
		],
		[
			[
				52332,
				90009
			],
			[
				154,
				78
			],
			[
				222,
				-206
			],
			[
				-2,
				268
			],
			[
				-219,
				526
			]
		],
		[
			[
				82921,
				68639
			],
			[
				64,
				-187
			]
		],
		[
			[
				82985,
				68452
			],
			[
				169,
				39
			],
			[
				148,
				102
			],
			[
				107,
				221
			],
			[
				134,
				23
			],
			[
				41,
				131
			],
			[
				126,
				135
			]
		],
		[
			[
				83710,
				69103
			],
			[
				-5,
				97
			],
			[
				128,
				241
			],
			[
				-42,
				287
			],
			[
				-178,
				228
			],
			[
				-119,
				103
			],
			[
				-24,
				-133
			],
			[
				-142,
				-94
			],
			[
				-84,
				152
			],
			[
				99,
				72
			],
			[
				108,
				165
			],
			[
				-76,
				241
			],
			[
				-263,
				386
			]
		],
		[
			[
				83112,
				70848
			],
			[
				-55,
				22
			],
			[
				-23,
				-214
			],
			[
				-93,
				58
			],
			[
				9,
				-162
			],
			[
				-95,
				-87
			],
			[
				-57,
				-162
			],
			[
				-4,
				-248
			],
			[
				134,
				-15
			],
			[
				7,
				-204
			],
			[
				83,
				3
			],
			[
				67,
				-274
			],
			[
				-41,
				-57
			],
			[
				29,
				-248
			],
			[
				-65,
				-135
			],
			[
				7,
				-249
			],
			[
				-94,
				-237
			]
		],
		[
			[
				52082,
				31943
			],
			[
				386,
				8
			]
		],
		[
			[
				52853,
				32291
			],
			[
				-8,
				1089
			]
		],
		[
			[
				52318,
				33173
			],
			[
				-245,
				-11
			]
		],
		[
			[
				77586,
				58097
			],
			[
				120,
				-86
			],
			[
				37,
				-145
			],
			[
				136,
				-127
			],
			[
				159,
				23
			],
			[
				46,
				-81
			]
		],
		[
			[
				78260,
				58082
			],
			[
				87,
				228
			],
			[
				54,
				-66
			],
			[
				93,
				182
			],
			[
				19,
				130
			],
			[
				-39,
				284
			],
			[
				114,
				156
			]
		],
		[
			[
				78588,
				58996
			],
			[
				-1,
				2
			]
		],
		[
			[
				78314,
				59574
			],
			[
				-86,
				-71
			],
			[
				-76,
				-206
			],
			[
				-296,
				-401
			],
			[
				-85,
				-62
			]
		],
		[
			[
				77771,
				58834
			],
			[
				41,
				-155
			],
			[
				-58,
				-120
			],
			[
				-23,
				-325
			],
			[
				-139,
				-65
			]
		],
		[
			[
				42631,
				65405
			],
			[
				-77,
				1874
			]
		],
		[
			[
				41760,
				67183
			],
			[
				86,
				-1835
			]
		],
		[
			[
				62686,
				33644
			],
			[
				384,
				-34
			],
			[
				-3,
				-114
			]
		],
		[
			[
				63067,
				33496
			],
			[
				292,
				106
			],
			[
				105,
				270
			],
			[
				38,
				226
			]
		],
		[
			[
				63795,
				34558
			],
			[
				-313,
				28
			],
			[
				10,
				337
			],
			[
				-381,
				33
			]
		],
		[
			[
				57137,
				49024
			],
			[
				3,
				1005
			]
		],
		[
			[
				57140,
				50029
			],
			[
				2,
				343
			]
		],
		[
			[
				9118,
				42127
			],
			[
				-35,
				266
			],
			[
				16,
				189
			],
			[
				152,
				153
			],
			[
				117,
				332
			],
			[
				50,
				9
			],
			[
				28,
				181
			]
		],
		[
			[
				8913,
				44439
			],
			[
				-68,
				-74
			],
			[
				-17,
				-231
			],
			[
				-50,
				-116
			],
			[
				10,
				-207
			],
			[
				98,
				-396
			],
			[
				-25,
				-321
			],
			[
				14,
				-172
			],
			[
				-84,
				-205
			],
			[
				42,
				-257
			],
			[
				72,
				-179
			],
			[
				26,
				-245
			]
		],
		[
			[
				39242,
				39042
			],
			[
				556,
				91
			]
		],
		[
			[
				39798,
				39133
			],
			[
				-141,
				2491
			],
			[
				-187,
				-31
			],
			[
				-16,
				336
			]
		],
		[
			[
				39454,
				41929
			],
			[
				-566,
				-79
			],
			[
				-441,
				-86
			]
		],
		[
			[
				38161,
				40839
			],
			[
				-16,
				-137
			],
			[
				-66,
				9
			],
			[
				7,
				-197
			],
			[
				-45,
				-300
			],
			[
				-141,
				-705
			],
			[
				-103,
				-222
			],
			[
				-85,
				-280
			],
			[
				10,
				-230
			]
		],
		[
			[
				69565,
				50125
			],
			[
				-7,
				-138
			],
			[
				260,
				-42
			],
			[
				-9,
				-170
			]
		],
		[
			[
				70234,
				49760
			],
			[
				5,
				110
			],
			[
				97,
				-15
			]
		],
		[
			[
				70336,
				49855
			],
			[
				18,
				328
			],
			[
				-95,
				16
			],
			[
				-31,
				243
			],
			[
				-96,
				28
			],
			[
				-172,
				526
			],
			[
				8,
				178
			]
		],
		[
			[
				69968,
				51174
			],
			[
				-63,
				-67
			]
		],
		[
			[
				69905,
				51107
			],
			[
				-143,
				-122
			],
			[
				-124,
				-2
			]
		],
		[
			[
				69638,
				50983
			],
			[
				-17,
				-362
			],
			[
				-40,
				7
			],
			[
				-16,
				-503
			]
		],
		[
			[
				51408,
				49491
			],
			[
				956,
				31
			]
		],
		[
			[
				52364,
				49522
			],
			[
				-14,
				1681
			]
		],
		[
			[
				51393,
				51175
			],
			[
				12,
				-1348
			]
		],
		[
			[
				66099,
				20334
			],
			[
				108,
				87
			],
			[
				14,
				-111
			],
			[
				150,
				-29
			],
			[
				151,
				144
			],
			[
				51,
				-69
			],
			[
				138,
				136
			],
			[
				74,
				-56
			],
			[
				209,
				129
			]
		],
		[
			[
				66994,
				20565
			],
			[
				75,
				207
			],
			[
				-86,
				188
			],
			[
				128,
				129
			]
		],
		[
			[
				67111,
				21089
			],
			[
				11,
				257
			],
			[
				-572,
				44
			]
		],
		[
			[
				62506,
				51002
			],
			[
				-8,
				-225
			],
			[
				292,
				-19
			]
		],
		[
			[
				38871,
				20487
			],
			[
				1877,
				331
			]
		],
		[
			[
				40748,
				20818
			],
			[
				-72,
				1310
			],
			[
				-28,
				343
			],
			[
				-80,
				-13
			],
			[
				-74,
				1340
			]
		],
		[
			[
				38943,
				23557
			],
			[
				-375,
				-70
			]
		],
		[
			[
				38568,
				23487
			],
			[
				45,
				-707
			],
			[
				-25,
				-5
			]
		],
		[
			[
				38588,
				22775
			],
			[
				41,
				-654
			],
			[
				69,
				13
			],
			[
				107,
				-1659
			],
			[
				66,
				12
			]
		],
		[
			[
				93609,
				25273
			],
			[
				48,
				-18
			]
		],
		[
			[
				93657,
				25255
			],
			[
				85,
				107
			],
			[
				22,
				153
			],
			[
				130,
				-69
			],
			[
				190,
				15
			],
			[
				23,
				313
			],
			[
				81,
				-124
			],
			[
				133,
				-23
			],
			[
				25,
				79
			],
			[
				19,
				423
			],
			[
				-69,
				276
			],
			[
				85,
				119
			],
			[
				145,
				-142
			],
			[
				5,
				261
			],
			[
				-123,
				73
			],
			[
				-1,
				165
			],
			[
				94,
				99
			],
			[
				80,
				-70
			],
			[
				58,
				111
			]
		],
		[
			[
				94639,
				27021
			],
			[
				48,
				534
			]
		],
		[
			[
				94687,
				27555
			],
			[
				-474,
				249
			]
		],
		[
			[
				93717,
				27940
			],
			[
				-54,
				19
			]
		],
		[
			[
				93663,
				27959
			],
			[
				-72,
				-491
			],
			[
				-98,
				75
			],
			[
				-120,
				-68
			],
			[
				34,
				-258
			]
		],
		[
			[
				93407,
				27217
			],
			[
				-21,
				-254
			],
			[
				-96,
				79
			],
			[
				-43,
				-180
			],
			[
				-44,
				53
			]
		],
		[
			[
				64903,
				43217
			],
			[
				30,
				795
			]
		],
		[
			[
				64933,
				44012
			],
			[
				4,
				112
			]
		],
		[
			[
				69729,
				44512
			],
			[
				316,
				-41
			]
		],
		[
			[
				70045,
				44471
			],
			[
				25,
				504
			],
			[
				127,
				-20
			]
		],
		[
			[
				9518,
				33930
			],
			[
				1457,
				715
			]
		],
		[
			[
				9582,
				35629
			],
			[
				130,
				-729
			],
			[
				-246,
				-120
			]
		],
		[
			[
				9466,
				34780
			],
			[
				69,
				-483
			],
			[
				-50,
				-182
			],
			[
				33,
				-185
			]
		],
		[
			[
				61772,
				62976
			],
			[
				192,
				-52
			],
			[
				81,
				97
			],
			[
				168,
				85
			],
			[
				138,
				-33
			]
		],
		[
			[
				62351,
				63073
			],
			[
				9,
				420
			],
			[
				199,
				-8
			],
			[
				27,
				957
			],
			[
				104,
				165
			],
			[
				-44,
				181
			],
			[
				59,
				125
			],
			[
				-72,
				254
			]
		],
		[
			[
				62633,
				65167
			],
			[
				-50,
				1
			]
		],
		[
			[
				62583,
				65168
			],
			[
				-594,
				19
			]
		],
		[
			[
				61989,
				65187
			],
			[
				-11,
				-509
			],
			[
				-153,
				5
			],
			[
				53,
				-416
			],
			[
				-12,
				-1087
			],
			[
				-92,
				5
			]
		],
		[
			[
				61774,
				63185
			],
			[
				-2,
				-209
			]
		],
		[
			[
				53703,
				34454
			],
			[
				46,
				76
			],
			[
				118,
				-40
			],
			[
				49,
				126
			],
			[
				91,
				-30
			]
		],
		[
			[
				54007,
				34586
			],
			[
				81,
				26
			],
			[
				25,
				145
			],
			[
				-58,
				177
			],
			[
				-4,
				188
			],
			[
				100,
				289
			]
		],
		[
			[
				54151,
				35411
			],
			[
				-608,
				-12
			]
		],
		[
			[
				85284,
				53971
			],
			[
				-115,
				-1163
			]
		],
		[
			[
				85169,
				52808
			],
			[
				109,
				-35
			]
		],
		[
			[
				85278,
				52773
			],
			[
				485,
				-156
			]
		],
		[
			[
				63198,
				58265
			],
			[
				34,
				122
			],
			[
				143,
				64
			],
			[
				43,
				149
			],
			[
				174,
				42
			],
			[
				420,
				-31
			]
		],
		[
			[
				64012,
				58611
			],
			[
				111,
				-9
			],
			[
				-122,
				280
			],
			[
				-57,
				348
			],
			[
				15,
				87
			]
		],
		[
			[
				63959,
				59317
			],
			[
				-27,
				340
			],
			[
				-286,
				9
			]
		],
		[
			[
				63646,
				59666
			],
			[
				-298,
				7
			]
		],
		[
			[
				63063,
				59695
			],
			[
				-24,
				-1312
			],
			[
				160,
				-62
			],
			[
				-1,
				-56
			]
		],
		[
			[
				62169,
				66813
			],
			[
				-2,
				-112
			],
			[
				382,
				-31
			],
			[
				37,
				108
			],
			[
				-12,
				-419
			],
			[
				62,
				8
			],
			[
				172,
				191
			],
			[
				4,
				114
			],
			[
				90,
				-76
			],
			[
				87,
				115
			],
			[
				111,
				-31
			]
		],
		[
			[
				63100,
				66680
			],
			[
				51,
				249
			]
		],
		[
			[
				63151,
				66929
			],
			[
				-79,
				43
			],
			[
				2,
				112
			],
			[
				-152,
				8
			],
			[
				4,
				746
			]
		],
		[
			[
				62154,
				67864
			],
			[
				-18,
				-714
			],
			[
				43,
				-2
			],
			[
				-10,
				-335
			]
		],
		[
			[
				57539,
				67520
			],
			[
				180,
				25
			],
			[
				-15,
				147
			],
			[
				282,
				350
			],
			[
				88,
				46
			],
			[
				275,
				19
			],
			[
				0,
				110
			],
			[
				153,
				4
			]
		],
		[
			[
				58502,
				68221
			],
			[
				3,
				0
			]
		],
		[
			[
				58755,
				68755
			],
			[
				-58,
				-4
			],
			[
				-73,
				155
			],
			[
				-61,
				-40
			],
			[
				-38,
				131
			],
			[
				-31,
				-122
			],
			[
				-139,
				116
			]
		],
		[
			[
				58355,
				68991
			],
			[
				-111,
				-66
			],
			[
				-38,
				72
			],
			[
				-111,
				-151
			],
			[
				-78,
				154
			],
			[
				-134,
				-86
			],
			[
				-111,
				131
			],
			[
				-36,
				-120
			],
			[
				-149,
				-87
			],
			[
				-4,
				-180
			],
			[
				-49,
				29
			]
		],
		[
			[
				77434,
				57428
			],
			[
				21,
				-27
			],
			[
				5,
				-414
			],
			[
				52,
				-314
			]
		],
		[
			[
				77512,
				56673
			],
			[
				-32,
				-145
			],
			[
				43,
				-111
			],
			[
				81,
				70
			],
			[
				0,
				-208
			]
		],
		[
			[
				77604,
				56279
			],
			[
				-4,
				94
			],
			[
				135,
				109
			],
			[
				416,
				587
			]
		],
		[
			[
				89262,
				35669
			],
			[
				189,
				-355
			]
		],
		[
			[
				89451,
				35314
			],
			[
				19,
				60
			]
		],
		[
			[
				89470,
				35374
			],
			[
				24,
				87
			],
			[
				136,
				-54
			],
			[
				64,
				77
			],
			[
				40,
				328
			],
			[
				43,
				138
			],
			[
				159,
				14
			],
			[
				64,
				195
			]
		],
		[
			[
				90000,
				36159
			],
			[
				146,
				127
			],
			[
				65,
				152
			],
			[
				135,
				86
			],
			[
				112,
				226
			]
		],
		[
			[
				90458,
				36750
			],
			[
				-83,
				137
			],
			[
				-69,
				4
			],
			[
				-46,
				194
			],
			[
				-167,
				195
			]
		],
		[
			[
				89982,
				36971
			],
			[
				-911,
				-762
			]
		],
		[
			[
				89071,
				36209
			],
			[
				89,
				-308
			],
			[
				102,
				-232
			]
		],
		[
			[
				84116,
				66897
			],
			[
				106,
				100
			],
			[
				75,
				-66
			],
			[
				117,
				355
			],
			[
				109,
				-72
			],
			[
				-15,
				116
			],
			[
				88,
				199
			],
			[
				116,
				-229
			],
			[
				17,
				-183
			],
			[
				105,
				-117
			],
			[
				5,
				-185
			],
			[
				148,
				-442
			],
			[
				20,
				-136
			],
			[
				158,
				-170
			],
			[
				54,
				18
			],
			[
				57,
				-259
			],
			[
				55,
				-35
			]
		],
		[
			[
				85331,
				65791
			],
			[
				198,
				174
			],
			[
				159,
				26
			],
			[
				-32,
				105
			]
		],
		[
			[
				85656,
				66096
			],
			[
				-63,
				98
			],
			[
				-23,
				332
			],
			[
				-119,
				4
			],
			[
				-105,
				85
			],
			[
				-85,
				-108
			],
			[
				-96,
				142
			],
			[
				-44,
				253
			],
			[
				87,
				17
			],
			[
				5,
				107
			],
			[
				-198,
				277
			],
			[
				-40,
				185
			],
			[
				-206,
				258
			],
			[
				-81,
				-103
			],
			[
				-66,
				150
			],
			[
				119,
				41
			],
			[
				-12,
				201
			],
			[
				-149,
				218
			],
			[
				-35,
				141
			],
			[
				-140,
				51
			],
			[
				-122,
				132
			],
			[
				-50,
				165
			],
			[
				-46,
				-48
			],
			[
				-117,
				184
			]
		],
		[
			[
				84070,
				68878
			],
			[
				-136,
				104
			],
			[
				-135,
				-142
			],
			[
				42,
				-121
			],
			[
				-30,
				-297
			],
			[
				-66,
				-58
			],
			[
				-19,
				-159
			],
			[
				-66,
				-79
			],
			[
				59,
				-295
			],
			[
				-6,
				-183
			]
		],
		[
			[
				90639,
				45066
			],
			[
				-114,
				645
			],
			[
				-46,
				106
			],
			[
				-99,
				-29
			],
			[
				-68,
				171
			],
			[
				-79,
				409
			],
			[
				-28,
				513
			],
			[
				42,
				151
			],
			[
				-78,
				437
			]
		],
		[
			[
				89680,
				47310
			],
			[
				13,
				-293
			],
			[
				117,
				-595
			],
			[
				-40,
				-100
			],
			[
				121,
				-104
			],
			[
				65,
				-242
			],
			[
				-53,
				-134
			],
			[
				-83,
				18
			],
			[
				127,
				-232
			]
		],
		[
			[
				89393,
				45821
			],
			[
				-48,
				170
			],
			[
				-42,
				-137
			]
		],
		[
			[
				81407,
				71018
			],
			[
				612,
				-503
			]
		],
		[
			[
				82019,
				70515
			],
			[
				132,
				530
			]
		],
		[
			[
				82151,
				71045
			],
			[
				62,
				170
			],
			[
				134,
				154
			],
			[
				103,
				317
			],
			[
				96,
				-56
			],
			[
				160,
				200
			],
			[
				-58,
				170
			],
			[
				73,
				330
			]
		],
		[
			[
				82721,
				72330
			],
			[
				-82,
				22
			]
		],
		[
			[
				82639,
				72352
			],
			[
				-124,
				-74
			],
			[
				-47,
				-146
			],
			[
				-61,
				34
			],
			[
				-146,
				-189
			],
			[
				-88,
				-349
			],
			[
				-107,
				6
			],
			[
				-30,
				-86
			],
			[
				-82,
				81
			],
			[
				-136,
				-12
			],
			[
				-32,
				-136
			],
			[
				-193,
				-260
			],
			[
				-49,
				8
			]
		],
		[
			[
				81544,
				71229
			],
			[
				-75,
				-39
			],
			[
				-62,
				-172
			]
		],
		[
			[
				54287,
				77275
			],
			[
				853,
				-787
			]
		],
		[
			[
				55232,
				76926
			],
			[
				-14,
				177
			],
			[
				67,
				15
			],
			[
				38,
				303
			],
			[
				66,
				218
			],
			[
				-129,
				181
			],
			[
				-43,
				241
			],
			[
				37,
				170
			],
			[
				-89,
				107
			],
			[
				21,
				161
			]
		],
		[
			[
				55186,
				78499
			],
			[
				-402,
				9
			],
			[
				-141,
				92
			],
			[
				-233,
				282
			],
			[
				-134,
				79
			]
		],
		[
			[
				54276,
				78961
			],
			[
				-36,
				-125
			],
			[
				33,
				-210
			],
			[
				-72,
				-135
			],
			[
				-31,
				-326
			],
			[
				-64,
				-274
			],
			[
				24,
				-401
			]
		],
		[
			[
				29275,
				32919
			],
			[
				16,
				-228
			],
			[
				-127,
				-190
			],
			[
				35,
				-160
			],
			[
				-39,
				-271
			],
			[
				33,
				-137
			],
			[
				93,
				-81
			],
			[
				-26,
				-197
			],
			[
				72,
				-93
			],
			[
				-51,
				-266
			]
		],
		[
			[
				29281,
				31296
			],
			[
				34,
				-45
			],
			[
				-31,
				-562
			],
			[
				53,
				-59
			],
			[
				367,
				105
			],
			[
				154,
				156
			],
			[
				54,
				-132
			],
			[
				30,
				126
			],
			[
				-50,
				336
			],
			[
				264,
				22
			],
			[
				52,
				-68
			]
		],
		[
			[
				85292,
				38947
			],
			[
				68,
				-436
			],
			[
				113,
				-418
			]
		],
		[
			[
				85514,
				37976
			],
			[
				134,
				314
			]
		],
		[
			[
				85648,
				38290
			],
			[
				31,
				170
			],
			[
				90,
				101
			],
			[
				81,
				250
			],
			[
				164,
				245
			],
			[
				-16,
				117
			]
		],
		[
			[
				85998,
				39173
			],
			[
				117,
				840
			]
		],
		[
			[
				88359,
				46828
			],
			[
				-115,
				-79
			],
			[
				-213,
				-329
			],
			[
				-69,
				-11
			],
			[
				-43,
				-139
			],
			[
				-105,
				-64
			]
		],
		[
			[
				87726,
				45928
			],
			[
				81,
				-214
			],
			[
				137,
				209
			],
			[
				106,
				69
			],
			[
				4,
				117
			],
			[
				239,
				56
			]
		],
		[
			[
				96155,
				18414
			],
			[
				166,
				-13
			],
			[
				-78,
				-381
			],
			[
				-8,
				-204
			],
			[
				169,
				-8
			],
			[
				12,
				-333
			]
		],
		[
			[
				96852,
				18782
			],
			[
				-77,
				56
			],
			[
				29,
				165
			],
			[
				-67,
				161
			],
			[
				-4,
				172
			],
			[
				-90,
				-131
			],
			[
				12,
				155
			],
			[
				-63,
				141
			],
			[
				-84,
				-49
			],
			[
				22,
				217
			],
			[
				-90,
				-139
			]
		],
		[
			[
				96440,
				19530
			],
			[
				-112,
				-221
			],
			[
				35,
				-150
			],
			[
				-38,
				-299
			],
			[
				-186,
				14
			],
			[
				16,
				-460
			]
		],
		[
			[
				29268,
				9121
			],
			[
				1240,
				376
			],
			[
				-16,
				162
			],
			[
				192,
				59
			],
			[
				-16,
				163
			],
			[
				95,
				28
			],
			[
				-22,
				218
			],
			[
				858,
				234
			]
		],
		[
			[
				31495,
				11443
			],
			[
				-386,
				-107
			],
			[
				-31,
				324
			],
			[
				-384,
				-110
			],
			[
				-47,
				414
			],
			[
				-175,
				-112
			]
		],
		[
			[
				83990,
				53172
			],
			[
				10,
				-143
			],
			[
				-120,
				-66
			],
			[
				26,
				-68
			],
			[
				145,
				25
			],
			[
				23,
				-179
			],
			[
				74,
				122
			],
			[
				116,
				65
			],
			[
				-51,
				176
			]
		],
		[
			[
				81383,
				56906
			],
			[
				92,
				-342
			],
			[
				28,
				-400
			],
			[
				-72,
				-322
			]
		],
		[
			[
				81431,
				55842
			],
			[
				89,
				-76
			],
			[
				167,
				-33
			]
		],
		[
			[
				82022,
				55658
			],
			[
				46,
				762
			]
		],
		[
			[
				82068,
				56420
			],
			[
				-48,
				720
			],
			[
				121,
				611
			]
		],
		[
			[
				82141,
				57751
			],
			[
				-84,
				27
			]
		],
		[
			[
				81730,
				57705
			],
			[
				38,
				-296
			],
			[
				-160,
				-283
			],
			[
				-124,
				-5
			],
			[
				-101,
				-215
			]
		],
		[
			[
				82009,
				37591
			],
			[
				-189,
				-1904
			]
		],
		[
			[
				81820,
				35687
			],
			[
				88,
				450
			],
			[
				117,
				31
			],
			[
				-17,
				174
			],
			[
				543,
				-242
			],
			[
				98,
				-192
			]
		],
		[
			[
				82649,
				35908
			],
			[
				55,
				528
			]
		],
		[
			[
				82704,
				36436
			],
			[
				54,
				514
			],
			[
				-292,
				1051
			]
		],
		[
			[
				82010,
				37591
			],
			[
				-1,
				0
			]
		],
		[
			[
				90647,
				38328
			],
			[
				119,
				106
			],
			[
				68,
				228
			],
			[
				55,
				-20
			],
			[
				159,
				136
			],
			[
				153,
				55
			],
			[
				63,
				-64
			]
		],
		[
			[
				91264,
				38769
			],
			[
				90,
				197
			],
			[
				114,
				-50
			]
		],
		[
			[
				91468,
				38916
			],
			[
				28,
				101
			],
			[
				-125,
				488
			],
			[
				-184,
				312
			]
		],
		[
			[
				90340,
				39298
			],
			[
				133,
				-422
			]
		],
		[
			[
				83895,
				38686
			],
			[
				29,
				-339
			]
		],
		[
			[
				83924,
				38347
			],
			[
				207,
				90
			],
			[
				114,
				132
			],
			[
				-2,
				-227
			],
			[
				267,
				151
			],
			[
				29,
				-228
			]
		],
		[
			[
				84539,
				38265
			],
			[
				66,
				266
			],
			[
				193,
				171
			]
		],
		[
			[
				84573,
				40511
			],
			[
				-716,
				226
			]
		],
		[
			[
				83857,
				40737
			],
			[
				45,
				-408
			],
			[
				-105,
				-882
			],
			[
				33,
				-61
			],
			[
				65,
				-700
			]
		],
		[
			[
				55096,
				44182
			],
			[
				761,
				-4
			]
		],
		[
			[
				55857,
				44178
			],
			[
				5,
				1339
			]
		],
		[
			[
				62767,
				83402
			],
			[
				275,
				-442
			],
			[
				157,
				-392
			],
			[
				294,
				-65
			]
		],
		[
			[
				63493,
				82503
			],
			[
				118,
				186
			],
			[
				85,
				263
			],
			[
				-19,
				196
			],
			[
				69,
				61
			],
			[
				266,
				35
			],
			[
				229,
				178
			]
		],
		[
			[
				64241,
				83422
			],
			[
				-4,
				215
			],
			[
				46,
				61
			]
		],
		[
			[
				64283,
				83698
			],
			[
				-153,
				142
			],
			[
				-30,
				-62
			],
			[
				-171,
				294
			],
			[
				0,
				219
			],
			[
				73,
				153
			]
		],
		[
			[
				64002,
				84444
			],
			[
				-181,
				193
			],
			[
				5,
				-135
			],
			[
				-66,
				28
			],
			[
				78,
				-227
			],
			[
				-76,
				-126
			],
			[
				-196,
				181
			],
			[
				-76,
				-258
			],
			[
				-67,
				34
			],
			[
				-54,
				-383
			],
			[
				-185,
				11
			],
			[
				39,
				-112
			],
			[
				4,
				-281
			],
			[
				-226,
				-36
			],
			[
				-222,
				177
			],
			[
				-12,
				-108
			]
		],
		[
			[
				87228,
				49462
			],
			[
				49,
				-57
			]
		],
		[
			[
				87297,
				49381
			],
			[
				44,
				-44
			]
		],
		[
			[
				87341,
				49337
			],
			[
				100,
				157
			],
			[
				-107,
				136
			]
		],
		[
			[
				87334,
				49630
			],
			[
				-89,
				-38
			],
			[
				-17,
				-130
			]
		],
		[
			[
				52711,
				53906
			],
			[
				-1149,
				-25
			]
		],
		[
			[
				83308,
				38269
			],
			[
				59,
				-423
			],
			[
				95,
				-912
			]
		],
		[
			[
				83462,
				36934
			],
			[
				745,
				-237
			]
		],
		[
			[
				84207,
				36697
			],
			[
				-63,
				335
			],
			[
				-48,
				6
			],
			[
				12,
				177
			],
			[
				-49,
				250
			],
			[
				-75,
				160
			],
			[
				16,
				527
			],
			[
				-76,
				195
			]
		],
		[
			[
				83895,
				38686
			],
			[
				-377,
				111
			],
			[
				-87,
				-131
			],
			[
				-214,
				66
			]
		],
		[
			[
				42034,
				24047
			],
			[
				29,
				-8
			],
			[
				40,
				-810
			]
		],
		[
			[
				42103,
				23229
			],
			[
				1698,
				230
			]
		],
		[
			[
				43801,
				23459
			],
			[
				-29,
				661
			]
		],
		[
			[
				53429,
				32299
			],
			[
				-8,
				1459
			]
		],
		[
			[
				77414,
				55902
			],
			[
				68,
				-33
			],
			[
				78,
				-220
			],
			[
				174,
				361
			]
		],
		[
			[
				77734,
				56010
			],
			[
				-130,
				269
			]
		],
		[
			[
				77512,
				56673
			],
			[
				-85,
				-158
			],
			[
				-181,
				-48
			],
			[
				-153,
				28
			],
			[
				-20,
				-70
			]
		],
		[
			[
				82705,
				60288
			],
			[
				434,
				-109
			]
		],
		[
			[
				84126,
				60996
			],
			[
				-83,
				184
			],
			[
				-291,
				80
			],
			[
				-390,
				605
			]
		],
		[
			[
				83362,
				61865
			],
			[
				-81,
				-212
			],
			[
				-38,
				-268
			],
			[
				-99,
				-164
			],
			[
				-90,
				-240
			]
		],
		[
			[
				76939,
				54811
			],
			[
				858,
				-185
			]
		],
		[
			[
				77797,
				54626
			],
			[
				273,
				-65
			]
		],
		[
			[
				78070,
				54561
			],
			[
				-196,
				244
			],
			[
				-112,
				-54
			],
			[
				-179,
				236
			],
			[
				-130,
				312
			],
			[
				28,
				61
			],
			[
				-144,
				148
			]
		],
		[
			[
				77144,
				55481
			],
			[
				17,
				-183
			],
			[
				-74,
				-278
			],
			[
				-148,
				-209
			]
		],
		[
			[
				68517,
				61061
			],
			[
				-4,
				-146
			]
		],
		[
			[
				69123,
				60932
			],
			[
				43,
				154
			],
			[
				-13,
				201
			],
			[
				66,
				5
			],
			[
				49,
				1115
			]
		],
		[
			[
				68585,
				62543
			],
			[
				-31,
				4
			]
		],
		[
			[
				68554,
				62547
			],
			[
				-37,
				-1486
			]
		],
		[
			[
				83182,
				64735
			],
			[
				76,
				-296
			],
			[
				157,
				-49
			],
			[
				-29,
				-196
			],
			[
				223,
				-135
			],
			[
				21,
				-74
			],
			[
				457,
				-690
			]
		],
		[
			[
				84290,
				63475
			],
			[
				-47,
				274
			],
			[
				-126,
				235
			],
			[
				56,
				165
			],
			[
				-28,
				75
			],
			[
				-120,
				845
			]
		],
		[
			[
				84025,
				65069
			],
			[
				-85,
				96
			],
			[
				16,
				112
			],
			[
				-131,
				57
			]
		],
		[
			[
				83825,
				65334
			],
			[
				-237,
				119
			],
			[
				-166,
				-134
			],
			[
				-38,
				-175
			],
			[
				-102,
				-104
			]
		],
		[
			[
				83282,
				65040
			],
			[
				-100,
				-305
			]
		],
		[
			[
				70319,
				63046
			],
			[
				19,
				347
			],
			[
				-23,
				230
			],
			[
				-68,
				20
			],
			[
				-68,
				326
			]
		],
		[
			[
				48993,
				32455
			],
			[
				-1079,
				-77
			]
		],
		[
			[
				47928,
				30872
			],
			[
				8,
				-334
			],
			[
				-27,
				-4
			],
			[
				24,
				-905
			]
		],
		[
			[
				43801,
				23459
			],
			[
				108,
				-1979
			],
			[
				35,
				-809
			]
		],
		[
			[
				45340,
				22644
			],
			[
				-61,
				1657
			]
		],
		[
			[
				47510,
				55489
			],
			[
				153,
				12
			]
		],
		[
			[
				47663,
				55501
			],
			[
				-39,
				1575
			]
		],
		[
			[
				47624,
				57076
			],
			[
				-10,
				361
			]
		],
		[
			[
				80984,
				83157
			],
			[
				-52,
				-642
			],
			[
				254,
				-66
			],
			[
				-93,
				-1017
			]
		],
		[
			[
				81093,
				81432
			],
			[
				369,
				-89
			],
			[
				24,
				204
			],
			[
				126,
				18
			],
			[
				23,
				-75
			],
			[
				148,
				-64
			],
			[
				-10,
				-110
			]
		],
		[
			[
				81773,
				81316
			],
			[
				89,
				-141
			],
			[
				304,
				-152
			],
			[
				12,
				130
			],
			[
				128,
				-36
			],
			[
				22,
				219
			],
			[
				63,
				-19
			],
			[
				21,
				222
			],
			[
				140,
				-39
			],
			[
				-8,
				185
			]
		],
		[
			[
				82544,
				81685
			],
			[
				91,
				161
			]
		],
		[
			[
				82635,
				81846
			],
			[
				82,
				853
			],
			[
				-38,
				20
			],
			[
				30,
				330
			],
			[
				-572,
				148
			]
		],
		[
			[
				82137,
				83197
			],
			[
				-693,
				173
			]
		],
		[
			[
				85841,
				49632
			],
			[
				797,
				146
			]
		],
		[
			[
				86638,
				49778
			],
			[
				-159,
				245
			],
			[
				84,
				581
			]
		],
		[
			[
				86563,
				50604
			],
			[
				-135,
				51
			],
			[
				-77,
				-114
			]
		],
		[
			[
				86351,
				50541
			],
			[
				-131,
				88
			],
			[
				-280,
				-217
			],
			[
				-39,
				-107
			]
		],
		[
			[
				85901,
				50305
			],
			[
				-60,
				-673
			]
		],
		[
			[
				92010,
				25858
			],
			[
				-88,
				-706
			],
			[
				-138,
				46
			],
			[
				-85,
				-713
			],
			[
				206,
				-63
			],
			[
				-49,
				-571
			],
			[
				49,
				7
			]
		],
		[
			[
				94892,
				27590
			],
			[
				28,
				-13
			],
			[
				388,
				-617
			]
		],
		[
			[
				95308,
				26960
			],
			[
				126,
				470
			],
			[
				99,
				88
			],
			[
				82,
				237
			],
			[
				-75,
				211
			],
			[
				39,
				132
			],
			[
				159,
				-123
			],
			[
				75,
				90
			],
			[
				147,
				457
			]
		],
		[
			[
				95960,
				28522
			],
			[
				-107,
				86
			],
			[
				-3,
				418
			],
			[
				-129,
				140
			],
			[
				-129,
				49
			]
		],
		[
			[
				95368,
				28609
			],
			[
				-67,
				-111
			]
		],
		[
			[
				95114,
				28322
			],
			[
				-74,
				-154
			],
			[
				-30,
				-286
			],
			[
				-65,
				50
			],
			[
				-53,
				-342
			]
		],
		[
			[
				69265,
				38345
			],
			[
				90,
				-104
			]
		],
		[
			[
				69355,
				38241
			],
			[
				136,
				-193
			],
			[
				122,
				45
			],
			[
				163,
				167
			],
			[
				59,
				-77
			]
		],
		[
			[
				69849,
				38431
			],
			[
				55,
				997
			]
		],
		[
			[
				69904,
				39428
			],
			[
				-95,
				16
			],
			[
				18,
				285
			],
			[
				-189,
				32
			],
			[
				23,
				387
			]
		],
		[
			[
				64801,
				47513
			],
			[
				-219,
				25
			],
			[
				6,
				293
			]
		],
		[
			[
				64588,
				47831
			],
			[
				-306,
				-140
			]
		],
		[
			[
				64000,
				47140
			],
			[
				42,
				-240
			],
			[
				63,
				-62
			],
			[
				87,
				60
			],
			[
				298,
				-27
			],
			[
				-8,
				-195
			],
			[
				192,
				-22
			],
			[
				-7,
				-126
			],
			[
				94,
				-24
			]
		],
		[
			[
				68654,
				50011
			],
			[
				323,
				-42
			],
			[
				4,
				103
			],
			[
				194,
				-25
			],
			[
				6,
				125
			]
		],
		[
			[
				69181,
				50172
			],
			[
				-6,
				170
			],
			[
				38,
				875
			]
		],
		[
			[
				69213,
				51217
			],
			[
				-222,
				114
			],
			[
				-118,
				-182
			],
			[
				-55,
				110
			]
		],
		[
			[
				68662,
				51678
			],
			[
				-129,
				-337
			]
		],
		[
			[
				81961,
				90993
			],
			[
				239,
				-56
			],
			[
				-28,
				-330
			],
			[
				393,
				-95
			]
		],
		[
			[
				82640,
				91497
			],
			[
				-28,
				-400
			],
			[
				-67,
				-30
			],
			[
				6,
				-268
			],
			[
				-80,
				35
			],
			[
				-103,
				170
			],
			[
				97,
				463
			],
			[
				-138,
				114
			]
		],
		[
			[
				82218,
				91530
			],
			[
				-47,
				-135
			],
			[
				-210,
				-402
			]
		],
		[
			[
				92263,
				28527
			],
			[
				313,
				-124
			],
			[
				16,
				148
			],
			[
				77,
				-54
			],
			[
				0,
				-132
			],
			[
				395,
				-162
			]
		],
		[
			[
				93064,
				28203
			],
			[
				74,
				313
			],
			[
				-32,
				22
			],
			[
				85,
				502
			],
			[
				48,
				-34
			],
			[
				123,
				508
			],
			[
				99,
				235
			]
		],
		[
			[
				93381,
				29855
			],
			[
				-97,
				-209
			],
			[
				-325,
				216
			],
			[
				-35,
				209
			]
		],
		[
			[
				83857,
				62817
			],
			[
				-184,
				-242
			],
			[
				70,
				-159
			],
			[
				-74,
				-320
			],
			[
				-87,
				-138
			],
			[
				-19,
				-151
			],
			[
				-104,
				195
			],
			[
				-96,
				-129
			]
		],
		[
			[
				83363,
				61873
			],
			[
				-1,
				-8
			]
		],
		[
			[
				89445,
				37461
			],
			[
				184,
				281
			]
		],
		[
			[
				89792,
				38120
			],
			[
				-191,
				138
			],
			[
				-117,
				230
			]
		],
		[
			[
				89484,
				38488
			],
			[
				-224,
				-60
			],
			[
				-92,
				38
			]
		],
		[
			[
				89168,
				38466
			],
			[
				-22,
				-136
			],
			[
				89,
				-310
			],
			[
				126,
				-209
			],
			[
				-34,
				-20
			],
			[
				118,
				-330
			]
		],
		[
			[
				84775,
				60454
			],
			[
				809,
				1013
			]
		],
		[
			[
				85500,
				61672
			],
			[
				-96,
				-104
			],
			[
				-61,
				144
			],
			[
				-169,
				-2
			],
			[
				-148,
				-78
			],
			[
				-176,
				102
			],
			[
				-99,
				254
			]
		],
		[
			[
				82263,
				61815
			],
			[
				226,
				252
			],
			[
				-63,
				440
			]
		],
		[
			[
				82426,
				62507
			],
			[
				-240,
				132
			],
			[
				-229,
				211
			],
			[
				-157,
				17
			],
			[
				22,
				172
			],
			[
				-274,
				-179
			]
		],
		[
			[
				81548,
				62860
			],
			[
				-120,
				-305
			],
			[
				-143,
				-461
			],
			[
				-17,
				-154
			]
		],
		[
			[
				81268,
				61940
			],
			[
				-26,
				-296
			]
		],
		[
			[
				23690,
				4425
			],
			[
				360,
				134
			],
			[
				-166,
				1280
			],
			[
				1076,
				396
			]
		],
		[
			[
				24717,
				8178
			],
			[
				-77,
				-247
			],
			[
				-55,
				30
			],
			[
				-92,
				-216
			],
			[
				-80,
				1
			],
			[
				-90,
				130
			],
			[
				-51,
				475
			]
		],
		[
			[
				23254,
				7628
			],
			[
				34,
				-252
			]
		],
		[
			[
				89114,
				53840
			],
			[
				37,
				27
			],
			[
				251,
				-124
			],
			[
				210,
				-234
			],
			[
				77,
				129
			]
		],
		[
			[
				89689,
				53638
			],
			[
				-12,
				123
			],
			[
				71,
				184
			],
			[
				-57,
				321
			],
			[
				59,
				324
			]
		],
		[
			[
				89750,
				54590
			],
			[
				-431,
				123
			]
		],
		[
			[
				88954,
				54841
			],
			[
				55,
				-349
			],
			[
				-27,
				-59
			],
			[
				48,
				-247
			]
		],
		[
			[
				74833,
				56145
			],
			[
				186,
				352
			],
			[
				84,
				112
			],
			[
				182,
				92
			],
			[
				64,
				143
			]
		],
		[
			[
				75349,
				56844
			],
			[
				52,
				204
			],
			[
				77,
				34
			],
			[
				79,
				164
			]
		],
		[
			[
				74523,
				57041
			],
			[
				-25,
				-197
			],
			[
				47,
				-307
			],
			[
				135,
				-74
			],
			[
				14,
				-115
			],
			[
				139,
				-203
			]
		],
		[
			[
				47663,
				55501
			],
			[
				815,
				64
			]
		],
		[
			[
				48628,
				55575
			],
			[
				135,
				471
			],
			[
				105,
				97
			],
			[
				31,
				143
			]
		],
		[
			[
				48330,
				57126
			],
			[
				-706,
				-50
			]
		],
		[
			[
				74008,
				58655
			],
			[
				-40,
				-73
			]
		],
		[
			[
				73248,
				57837
			],
			[
				136,
				-252
			],
			[
				91,
				-51
			],
			[
				366,
				126
			],
			[
				6,
				149
			],
			[
				88,
				36
			]
		],
		[
			[
				73973,
				58559
			],
			[
				0,
				-111
			],
			[
				-167,
				130
			],
			[
				-206,
				-61
			],
			[
				-74,
				133
			],
			[
				-149,
				17
			]
		],
		[
			[
				73377,
				58667
			],
			[
				-151,
				-113
			]
		],
		[
			[
				42103,
				23229
			],
			[
				120,
				-2549
			]
		],
		[
			[
				42223,
				20680
			],
			[
				10,
				-241
			]
		],
		[
			[
				72034,
				56767
			],
			[
				2,
				-409
			],
			[
				63,
				-145
			]
		],
		[
			[
				72099,
				56213
			],
			[
				69,
				6
			],
			[
				48,
				141
			],
			[
				339,
				9
			]
		],
		[
			[
				72555,
				56369
			],
			[
				1,
				117
			],
			[
				-165,
				187
			],
			[
				-85,
				246
			]
		],
		[
			[
				72306,
				56919
			],
			[
				-135,
				-157
			],
			[
				-67,
				154
			],
			[
				-70,
				-149
			]
		],
		[
			[
				62419,
				37703
			],
			[
				16,
				675
			]
		],
		[
			[
				62435,
				38378
			],
			[
				-192,
				13
			]
		],
		[
			[
				62243,
				38391
			],
			[
				-69,
				-331
			],
			[
				-513,
				38
			]
		],
		[
			[
				95614,
				26140
			],
			[
				78,
				20
			]
		],
		[
			[
				95692,
				26160
			],
			[
				-43,
				224
			],
			[
				-65,
				-125
			],
			[
				30,
				-119
			]
		],
		[
			[
				95052,
				26248
			],
			[
				-2,
				-104
			]
		],
		[
			[
				94639,
				27021
			],
			[
				188,
				-261
			],
			[
				-9,
				-187
			],
			[
				47,
				-19
			],
			[
				-80,
				-221
			],
			[
				152,
				-100
			],
			[
				85,
				111
			]
		],
		[
			[
				95321,
				26087
			],
			[
				31,
				168
			],
			[
				46,
				-90
			]
		],
		[
			[
				95398,
				26165
			],
			[
				61,
				-44
			],
			[
				59,
				495
			],
			[
				-210,
				344
			]
		],
		[
			[
				94892,
				27590
			],
			[
				-19,
				-125
			],
			[
				-186,
				90
			]
		],
		[
			[
				29004,
				24819
			],
			[
				192,
				58
			],
			[
				33,
				-328
			],
			[
				190,
				55
			],
			[
				16,
				-165
			],
			[
				87,
				25
			],
			[
				57,
				-486
			],
			[
				96,
				28
			],
			[
				31,
				-320
			],
			[
				273,
				78
			],
			[
				55,
				-559
			]
		],
		[
			[
				30484,
				22547
			],
			[
				74,
				125
			],
			[
				119,
				426
			],
			[
				-17,
				160
			],
			[
				112,
				206
			],
			[
				18,
				168
			],
			[
				109,
				106
			]
		],
		[
			[
				30899,
				23738
			],
			[
				-171,
				1859
			]
		],
		[
			[
				30728,
				25597
			],
			[
				-239,
				133
			],
			[
				-67,
				-84
			],
			[
				-131,
				80
			],
			[
				-63,
				-129
			],
			[
				-78,
				74
			]
		],
		[
			[
				30150,
				25671
			],
			[
				-46,
				37
			],
			[
				-113,
				-105
			],
			[
				-109,
				148
			],
			[
				-220,
				-63
			],
			[
				5,
				-55
			],
			[
				-220,
				-65
			],
			[
				11,
				-109
			],
			[
				-221,
				-64
			]
		],
		[
			[
				65869,
				50415
			],
			[
				774,
				-68
			]
		],
		[
			[
				66643,
				50347
			],
			[
				10,
				338
			],
			[
				35,
				-3
			]
		],
		[
			[
				66688,
				50682
			],
			[
				25,
				107
			],
			[
				-30,
				432
			],
			[
				-50,
				143
			]
		],
		[
			[
				65905,
				51432
			],
			[
				-36,
				-1017
			]
		],
		[
			[
				74809,
				64979
			],
			[
				4,
				52
			],
			[
				227,
				-44
			]
		],
		[
			[
				75040,
				64987
			],
			[
				-68,
				522
			],
			[
				-131,
				21
			],
			[
				12,
				185
			]
		],
		[
			[
				74239,
				65845
			],
			[
				-39,
				-234
			]
		],
		[
			[
				74200,
				65611
			],
			[
				-71,
				-439
			]
		],
		[
			[
				87427,
				41991
			],
			[
				79,
				-129
			],
			[
				112,
				-375
			]
		],
		[
			[
				87618,
				41487
			],
			[
				121,
				-135
			]
		],
		[
			[
				87739,
				41352
			],
			[
				79,
				94
			],
			[
				77,
				-29
			]
		],
		[
			[
				87895,
				41417
			],
			[
				27,
				105
			],
			[
				185,
				116
			],
			[
				19,
				304
			],
			[
				95,
				130
			],
			[
				-117,
				194
			],
			[
				55,
				239
			],
			[
				-59,
				10
			],
			[
				-22,
				192
			],
			[
				61,
				75
			],
			[
				-86,
				311
			],
			[
				77,
				185
			]
		],
		[
			[
				88130,
				43278
			],
			[
				-150,
				25
			],
			[
				-33,
				-171
			],
			[
				-99,
				108
			]
		],
		[
			[
				87848,
				43240
			],
			[
				-71,
				-239
			],
			[
				27,
				-368
			],
			[
				-88,
				-258
			],
			[
				-107,
				-178
			],
			[
				-158,
				-76
			],
			[
				-24,
				-130
			]
		],
		[
			[
				80712,
				79764
			],
			[
				219,
				15
			]
		],
		[
			[
				81693,
				80381
			],
			[
				80,
				935
			]
		],
		[
			[
				81093,
				81432
			],
			[
				-287,
				89
			],
			[
				-20,
				-217
			],
			[
				-189,
				54
			],
			[
				-9,
				-111
			]
		],
		[
			[
				34910,
				47268
			],
			[
				-1505,
				-323
			]
		],
		[
			[
				72690,
				70572
			],
			[
				683,
				-127
			]
		],
		[
			[
				73373,
				70445
			],
			[
				237,
				-41
			],
			[
				-41,
				304
			],
			[
				52,
				107
			],
			[
				40,
				591
			]
		],
		[
			[
				72700,
				71898
			],
			[
				-70,
				-1137
			]
		],
		[
			[
				72630,
				70761
			],
			[
				-11,
				-166
			],
			[
				71,
				-23
			]
		],
		[
			[
				93751,
				30902
			],
			[
				-63,
				100
			],
			[
				-115,
				-18
			],
			[
				-126,
				147
			]
		],
		[
			[
				71685,
				69596
			],
			[
				-6,
				-84
			],
			[
				504,
				-76
			],
			[
				98,
				178
			],
			[
				85,
				-18
			]
		],
		[
			[
				72366,
				69596
			],
			[
				23,
				348
			],
			[
				78,
				199
			],
			[
				56,
				32
			],
			[
				1,
				192
			],
			[
				166,
				205
			]
		],
		[
			[
				72630,
				70761
			],
			[
				-560,
				101
			],
			[
				11,
				168
			],
			[
				-381,
				54
			]
		],
		[
			[
				75644,
				36980
			],
			[
				24,
				334
			]
		],
		[
			[
				56334,
				53146
			],
			[
				-1,
				-224
			]
		],
		[
			[
				51184,
				69449
			],
			[
				798,
				25
			]
		],
		[
			[
				51158,
				71113
			],
			[
				26,
				-1664
			]
		],
		[
			[
				47409,
				66203
			],
			[
				-90,
				-27
			],
			[
				-41,
				1515
			]
		],
		[
			[
				41927,
				75581
			],
			[
				849,
				101
			]
		],
		[
			[
				42776,
				75682
			],
			[
				-47,
				1738
			],
			[
				-5,
				429
			]
		],
		[
			[
				42724,
				77849
			],
			[
				-167,
				-23
			]
		],
		[
			[
				42557,
				77826
			],
			[
				-72,
				-169
			],
			[
				11,
				-247
			],
			[
				-145,
				-270
			],
			[
				-127,
				-61
			],
			[
				-175,
				-246
			],
			[
				-156,
				176
			],
			[
				-23,
				-58
			]
		],
		[
			[
				41870,
				76951
			],
			[
				57,
				-1370
			]
		],
		[
			[
				52912,
				9721
			],
			[
				1223,
				21
			]
		],
		[
			[
				54135,
				9742
			],
			[
				-2,
				1069
			],
			[
				26,
				0
			],
			[
				-2,
				653
			]
		],
		[
			[
				52998,
				11449
			],
			[
				84,
				-333
			],
			[
				16,
				-208
			],
			[
				-55,
				-153
			],
			[
				-56,
				-475
			],
			[
				-91,
				-438
			],
			[
				16,
				-121
			]
		],
		[
			[
				11736,
				11064
			],
			[
				373,
				192
			],
			[
				-6,
				37
			],
			[
				540,
				250
			]
		],
		[
			[
				12643,
				11543
			],
			[
				-45,
				260
			],
			[
				-185,
				-96
			],
			[
				-19,
				109
			],
			[
				64,
				205
			],
			[
				168,
				200
			],
			[
				-111,
				57
			],
			[
				-18,
				107
			],
			[
				-141,
				42
			],
			[
				-89,
				304
			]
		],
		[
			[
				11647,
				13842
			],
			[
				-22,
				117
			]
		],
		[
			[
				11204,
				13738
			],
			[
				109,
				-346
			],
			[
				107,
				-483
			],
			[
				32,
				-281
			],
			[
				69,
				-268
			],
			[
				14,
				-228
			],
			[
				42,
				-61
			],
			[
				126,
				-540
			],
			[
				33,
				-467
			]
		],
		[
			[
				84423,
				34696
			],
			[
				108,
				-625
			],
			[
				-47,
				-406
			]
		],
		[
			[
				85121,
				33439
			],
			[
				169,
				414
			],
			[
				349,
				426
			],
			[
				48,
				-12
			],
			[
				322,
				480
			],
			[
				33,
				-12
			]
		],
		[
			[
				86042,
				34735
			],
			[
				11,
				93
			]
		],
		[
			[
				84540,
				34906
			],
			[
				-117,
				-210
			]
		],
		[
			[
				81759,
				43250
			],
			[
				239,
				-91
			],
			[
				197,
				-260
			]
		],
		[
			[
				82195,
				42899
			],
			[
				96,
				179
			],
			[
				75,
				41
			]
		],
		[
			[
				82366,
				43119
			],
			[
				-10,
				393
			],
			[
				28,
				51
			]
		],
		[
			[
				82384,
				43563
			],
			[
				-48,
				183
			],
			[
				-18,
				394
			],
			[
				-311,
				193
			]
		],
		[
			[
				82007,
				44333
			],
			[
				45,
				-122
			],
			[
				-29,
				-235
			],
			[
				-123,
				33
			],
			[
				-86,
				-170
			],
			[
				-110,
				-67
			]
		],
		[
			[
				41358,
				37844
			],
			[
				1119,
				150
			]
		],
		[
			[
				42081,
				39460
			],
			[
				-800,
				-112
			]
		],
		[
			[
				41281,
				39348
			],
			[
				77,
				-1504
			]
		],
		[
			[
				43930,
				15385
			],
			[
				57,
				-1310
			],
			[
				690,
				88
			],
			[
				24,
				-567
			]
		],
		[
			[
				71549,
				59416
			],
			[
				3,
				-62
			],
			[
				167,
				-12
			],
			[
				97,
				136
			],
			[
				140,
				9
			],
			[
				32,
				115
			],
			[
				93,
				-192
			],
			[
				186,
				91
			]
		],
		[
			[
				71828,
				60652
			],
			[
				-136,
				-17
			]
		],
		[
			[
				37714,
				64568
			],
			[
				768,
				125
			]
		],
		[
			[
				37440,
				69220
			],
			[
				34,
				-662
			],
			[
				-751,
				-129
			],
			[
				17,
				-328
			],
			[
				-1140,
				-208
			]
		],
		[
			[
				75549,
				55136
			],
			[
				60,
				-13
			]
		],
		[
			[
				76032,
				55042
			],
			[
				14,
				110
			],
			[
				147,
				484
			],
			[
				32,
				23
			]
		],
		[
			[
				76225,
				55659
			],
			[
				-74,
				90
			],
			[
				-12,
				403
			],
			[
				-46,
				118
			]
		],
		[
			[
				76093,
				56270
			],
			[
				-58,
				-109
			],
			[
				-46,
				287
			],
			[
				-284,
				5
			],
			[
				-5,
				263
			],
			[
				-119,
				-144
			],
			[
				-125,
				43
			]
		],
		[
			[
				75456,
				56615
			],
			[
				-18,
				-165
			],
			[
				75,
				-113
			],
			[
				-86,
				-686
			],
			[
				23,
				-173
			],
			[
				79,
				5
			],
			[
				54,
				-129
			],
			[
				-34,
				-218
			]
		],
		[
			[
				69904,
				39428
			],
			[
				582,
				-91
			]
		],
		[
			[
				70486,
				39337
			],
			[
				39,
				672
			]
		],
		[
			[
				70525,
				40009
			],
			[
				-281,
				46
			],
			[
				-25,
				283
			],
			[
				27,
				390
			]
		],
		[
			[
				70246,
				40728
			],
			[
				-543,
				86
			]
		],
		[
			[
				70246,
				40728
			],
			[
				128,
				-20
			],
			[
				29,
				497
			]
		],
		[
			[
				70450,
				42042
			],
			[
				-669,
				108
			]
		],
		[
			[
				69395,
				54089
			],
			[
				291,
				125
			],
			[
				57,
				-92
			],
			[
				193,
				-56
			],
			[
				67,
				-101
			]
		],
		[
			[
				69472,
				56061
			],
			[
				13,
				-461
			],
			[
				-21,
				-474
			],
			[
				4,
				-340
			],
			[
				-136,
				-118
			]
		],
		[
			[
				45424,
				20429
			],
			[
				234,
				-51
			],
			[
				6,
				171
			],
			[
				136,
				-38
			],
			[
				143,
				131
			],
			[
				62,
				-89
			],
			[
				44,
				109
			],
			[
				167,
				-54
			],
			[
				161,
				-180
			],
			[
				137,
				-84
			],
			[
				1,
				-109
			],
			[
				175,
				-42
			],
			[
				-9,
				-135
			],
			[
				158,
				-163
			],
			[
				25,
				-170
			],
			[
				96,
				-25
			]
		],
		[
			[
				74897,
				50392
			],
			[
				37,
				-101
			],
			[
				82,
				32
			],
			[
				-35,
				-160
			],
			[
				75,
				22
			]
		],
		[
			[
				75056,
				50185
			],
			[
				105,
				-122
			],
			[
				31,
				155
			],
			[
				128,
				-39
			],
			[
				192,
				60
			]
		],
		[
			[
				75135,
				51541
			],
			[
				-372,
				-818
			]
		],
		[
			[
				77468,
				73138
			],
			[
				182,
				25
			],
			[
				88,
				87
			]
		],
		[
			[
				77738,
				73250
			],
			[
				62,
				686
			],
			[
				147,
				-29
			],
			[
				147,
				176
			]
		],
		[
			[
				78094,
				74083
			],
			[
				60,
				905
			]
		],
		[
			[
				78154,
				74988
			],
			[
				-653,
				127
			]
		],
		[
			[
				49344,
				44023
			],
			[
				190,
				10
			]
		],
		[
			[
				49534,
				44033
			],
			[
				-30,
				1674
			]
		],
		[
			[
				49504,
				45707
			],
			[
				-914,
				-52
			]
		],
		[
			[
				82704,
				36436
			],
			[
				678,
				-188
			]
		],
		[
			[
				83382,
				36248
			],
			[
				80,
				686
			]
		],
		[
			[
				67534,
				36646
			],
			[
				1,
				0
			]
		],
		[
			[
				68800,
				37444
			],
			[
				-45,
				-914
			]
		],
		[
			[
				68755,
				36530
			],
			[
				95,
				123
			],
			[
				115,
				-36
			],
			[
				-31,
				139
			],
			[
				168,
				51
			],
			[
				167,
				-31
			]
		],
		[
			[
				69269,
				36776
			],
			[
				86,
				1465
			]
		],
		[
			[
				68147,
				33594
			],
			[
				13,
				412
			],
			[
				-32,
				326
			],
			[
				74,
				350
			],
			[
				78,
				204
			]
		],
		[
			[
				68280,
				34886
			],
			[
				-722,
				97
			]
		],
		[
			[
				67068,
				44181
			],
			[
				462,
				-60
			]
		],
		[
			[
				67530,
				44121
			],
			[
				27,
				539
			]
		],
		[
			[
				67557,
				44660
			],
			[
				39,
				787
			]
		],
		[
			[
				67596,
				45447
			],
			[
				-194,
				24
			],
			[
				-110,
				-270
			],
			[
				-128,
				16
			],
			[
				-12,
				-223
			],
			[
				-155,
				17
			],
			[
				-16,
				-281
			]
		],
		[
			[
				67508,
				56856
			],
			[
				-19,
				2
			]
		],
		[
			[
				67489,
				56858
			],
			[
				-13,
				1
			]
		],
		[
			[
				67476,
				56859
			],
			[
				-135,
				-269
			],
			[
				-220,
				-31
			],
			[
				-223,
				-192
			],
			[
				-49,
				0
			]
		],
		[
			[
				66849,
				56367
			],
			[
				25,
				-64
			],
			[
				-80,
				-183
			],
			[
				51,
				-174
			],
			[
				73,
				21
			],
			[
				-1,
				-130
			]
		],
		[
			[
				54823,
				50227
			],
			[
				766,
				-3
			]
		],
		[
			[
				55575,
				51752
			],
			[
				-767,
				-4
			]
		],
		[
			[
				67557,
				44660
			],
			[
				694,
				-100
			],
			[
				-5,
				-111
			],
			[
				163,
				-41
			]
		],
		[
			[
				68409,
				44408
			],
			[
				50,
				785
			]
		],
		[
			[
				68459,
				45193
			],
			[
				-92,
				15
			],
			[
				23,
				391
			]
		],
		[
			[
				68390,
				45599
			],
			[
				-781,
				127
			]
		],
		[
			[
				67609,
				45726
			],
			[
				-13,
				-279
			]
		],
		[
			[
				68459,
				45193
			],
			[
				463,
				-92
			],
			[
				0,
				41
			],
			[
				268,
				-42
			]
		],
		[
			[
				69064,
				46357
			],
			[
				-181,
				-57
			],
			[
				-349,
				68
			]
		],
		[
			[
				68437,
				46386
			],
			[
				-47,
				-787
			]
		],
		[
			[
				85675,
				59342
			],
			[
				99,
				-80
			],
			[
				552,
				-189
			],
			[
				67,
				-42
			]
		],
		[
			[
				86393,
				59031
			],
			[
				133,
				241
			],
			[
				97,
				104
			],
			[
				77,
				204
			],
			[
				78,
				73
			],
			[
				60,
				231
			],
			[
				111,
				155
			]
		],
		[
			[
				86949,
				60039
			],
			[
				17,
				173
			],
			[
				160,
				117
			],
			[
				-116,
				295
			]
		],
		[
			[
				85870,
				60661
			],
			[
				35,
				-820
			],
			[
				-106,
				-150
			],
			[
				-124,
				-349
			]
		],
		[
			[
				55567,
				52931
			],
			[
				-762,
				-3
			]
		],
		[
			[
				58011,
				30651
			],
			[
				520,
				-20
			]
		],
		[
			[
				58531,
				30631
			],
			[
				244,
				-10
			]
		],
		[
			[
				67530,
				44121
			],
			[
				3,
				-338
			]
		],
		[
			[
				68422,
				43655
			],
			[
				17,
				336
			],
			[
				-54,
				8
			],
			[
				24,
				409
			]
		],
		[
			[
				86015,
				57625
			],
			[
				38,
				-213
			],
			[
				68,
				-93
			]
		],
		[
			[
				86553,
				57295
			],
			[
				129,
				193
			],
			[
				160,
				34
			],
			[
				3,
				58
			]
		],
		[
			[
				87132,
				59321
			],
			[
				-183,
				718
			]
		],
		[
			[
				86393,
				59031
			],
			[
				-173,
				-261
			],
			[
				-154,
				-164
			],
			[
				-64,
				-319
			],
			[
				21,
				-373
			],
			[
				-8,
				-289
			]
		],
		[
			[
				73102,
				34350
			],
			[
				192,
				-31
			]
		],
		[
			[
				73965,
				35591
			],
			[
				-64,
				16
			]
		],
		[
			[
				73901,
				35607
			],
			[
				-666,
				175
			]
		],
		[
			[
				73235,
				35782
			],
			[
				-48,
				-238
			]
		],
		[
			[
				73187,
				35544
			],
			[
				-85,
				-1194
			]
		],
		[
			[
				34713,
				20304
			],
			[
				103,
				-82
			],
			[
				-57,
				321
			],
			[
				56,
				125
			],
			[
				94,
				22
			],
			[
				-15,
				223
			],
			[
				82,
				19
			]
		],
		[
			[
				34976,
				20932
			],
			[
				-56,
				86
			],
			[
				-57,
				305
			],
			[
				-31,
				526
			],
			[
				915,
				195
			],
			[
				-113,
				165
			],
			[
				-145,
				409
			],
			[
				-32,
				215
			]
		],
		[
			[
				35457,
				22833
			],
			[
				-581,
				-135
			]
		],
		[
			[
				54025,
				23926
			],
			[
				266,
				86
			],
			[
				283,
				270
			]
		],
		[
			[
				54679,
				24376
			],
			[
				89,
				209
			],
			[
				246,
				423
			],
			[
				32,
				144
			],
			[
				106,
				50
			]
		],
		[
			[
				55152,
				25202
			],
			[
				0,
				171
			],
			[
				-174,
				-1
			],
			[
				-2,
				331
			],
			[
				-954,
				-3
			]
		],
		[
			[
				54023,
				25041
			],
			[
				2,
				-1115
			]
		],
		[
			[
				86702,
				47734
			],
			[
				127,
				53
			],
			[
				79,
				-101
			],
			[
				120,
				29
			],
			[
				108,
				274
			],
			[
				332,
				157
			]
		],
		[
			[
				87468,
				48146
			],
			[
				108,
				143
			]
		],
		[
			[
				87576,
				48289
			],
			[
				-76,
				454
			]
		],
		[
			[
				87500,
				48743
			],
			[
				-34,
				129
			],
			[
				-80,
				-94
			],
			[
				-77,
				54
			],
			[
				-140,
				-167
			],
			[
				7,
				-68
			]
		],
		[
			[
				87176,
				48597
			],
			[
				-23,
				-268
			],
			[
				45,
				-85
			],
			[
				-117,
				-211
			],
			[
				-68,
				121
			],
			[
				-92,
				-41
			],
			[
				17,
				123
			],
			[
				-111,
				52
			]
		],
		[
			[
				86827,
				48288
			],
			[
				-103,
				7
			]
		],
		[
			[
				86724,
				48295
			],
			[
				58,
				-80
			],
			[
				-92,
				-205
			],
			[
				12,
				-276
			]
		],
		[
			[
				93215,
				23558
			],
			[
				139,
				-138
			],
			[
				142,
				302
			],
			[
				274,
				-270
			],
			[
				98,
				347
			],
			[
				185,
				-142
			],
			[
				21,
				73
			],
			[
				211,
				60
			]
		],
		[
			[
				94285,
				23790
			],
			[
				67,
				248
			],
			[
				-115,
				85
			],
			[
				99,
				376
			],
			[
				65,
				-23
			],
			[
				85,
				165
			],
			[
				184,
				34
			]
		],
		[
			[
				94670,
				24675
			],
			[
				-12,
				29
			]
		],
		[
			[
				94658,
				24704
			],
			[
				-38,
				176
			],
			[
				-963,
				375
			]
		],
		[
			[
				86827,
				34120
			],
			[
				78,
				-35
			],
			[
				183,
				-529
			]
		],
		[
			[
				87630,
				34644
			],
			[
				-95,
				295
			],
			[
				1,
				322
			],
			[
				-107,
				142
			]
		],
		[
			[
				87429,
				35403
			],
			[
				-76,
				-202
			],
			[
				-146,
				87
			],
			[
				-70,
				-202
			]
		],
		[
			[
				57238,
				70115
			],
			[
				149,
				70
			],
			[
				100,
				-127
			],
			[
				190,
				-13
			],
			[
				68,
				108
			],
			[
				276,
				47
			],
			[
				-18,
				-124
			],
			[
				135,
				-150
			],
			[
				50,
				65
			],
			[
				180,
				-22
			]
		],
		[
			[
				58368,
				69969
			],
			[
				13,
				1073
			]
		],
		[
			[
				58381,
				71042
			],
			[
				6,
				531
			]
		],
		[
			[
				58387,
				71573
			],
			[
				-1139,
				40
			]
		],
		[
			[
				53921,
				34314
			],
			[
				1045,
				5
			]
		],
		[
			[
				55278,
				35662
			],
			[
				-1128,
				-15
			]
		],
		[
			[
				54150,
				35647
			],
			[
				55,
				-56
			],
			[
				-54,
				-180
			]
		],
		[
			[
				54007,
				34586
			],
			[
				-74,
				-115
			],
			[
				-12,
				-157
			]
		],
		[
			[
				72952,
				59796
			],
			[
				110,
				21
			],
			[
				372,
				-139
			]
		],
		[
			[
				73434,
				59678
			],
			[
				137,
				147
			],
			[
				16,
				213
			],
			[
				96,
				79
			],
			[
				-25,
				193
			],
			[
				-84,
				165
			]
		],
		[
			[
				73574,
				60475
			],
			[
				-264,
				166
			],
			[
				40,
				187
			],
			[
				-77,
				107
			],
			[
				-155,
				-76
			],
			[
				-87,
				89
			]
		],
		[
			[
				73031,
				60948
			],
			[
				-88,
				-239
			]
		],
		[
			[
				76170,
				52058
			],
			[
				189,
				3
			],
			[
				38,
				-90
			],
			[
				176,
				313
			],
			[
				74,
				18
			]
		],
		[
			[
				76687,
				52382
			],
			[
				14,
				120
			],
			[
				-70,
				158
			],
			[
				14,
				186
			],
			[
				128,
				674
			]
		],
		[
			[
				76773,
				53520
			],
			[
				-147,
				-34
			]
		],
		[
			[
				76626,
				53486
			],
			[
				-91,
				6
			],
			[
				-256,
				-245
			],
			[
				-69,
				55
			],
			[
				-106,
				-86
			]
		],
		[
			[
				87576,
				48289
			],
			[
				163,
				143
			],
			[
				139,
				47
			],
			[
				137,
				-25
			],
			[
				82,
				100
			]
		],
		[
			[
				88200,
				48978
			],
			[
				-106,
				142
			]
		],
		[
			[
				88094,
				49120
			],
			[
				-56,
				-36
			]
		],
		[
			[
				88038,
				49084
			],
			[
				-91,
				-193
			],
			[
				-86,
				170
			],
			[
				-48,
				-146
			],
			[
				-63,
				53
			],
			[
				-260,
				19
			]
		],
		[
			[
				87490,
				48987
			],
			[
				10,
				-244
			]
		],
		[
			[
				88516,
				31591
			],
			[
				221,
				1430
			],
			[
				-94,
				136
			]
		],
		[
			[
				88469,
				35182
			],
			[
				87,
				-169
			],
			[
				132,
				-117
			]
		],
		[
			[
				88688,
				34896
			],
			[
				30,
				136
			],
			[
				86,
				83
			],
			[
				38,
				-75
			],
			[
				119,
				273
			],
			[
				75,
				-100
			],
			[
				102,
				160
			],
			[
				-14,
				174
			],
			[
				138,
				122
			]
		],
		[
			[
				89071,
				36209
			],
			[
				-89,
				-80
			]
		],
		[
			[
				88277,
				35482
			],
			[
				192,
				-300
			]
		],
		[
			[
				57990,
				47434
			],
			[
				138,
				-47
			],
			[
				-9,
				-143
			],
			[
				115,
				-6
			],
			[
				-45,
				153
			],
			[
				252,
				-251
			],
			[
				140,
				27
			]
		],
		[
			[
				58581,
				47167
			],
			[
				100,
				-15
			],
			[
				83,
				-152
			],
			[
				158,
				107
			],
			[
				116,
				-23
			],
			[
				21,
				-269
			]
		],
		[
			[
				59059,
				46815
			],
			[
				-12,
				1352
			]
		],
		[
			[
				84075,
				33236
			],
			[
				-352,
				118
			],
			[
				7,
				56
			]
		],
		[
			[
				58159,
				57638
			],
			[
				-963,
				26
			]
		],
		[
			[
				57196,
				57664
			],
			[
				-5,
				-650
			]
		],
		[
			[
				57191,
				57014
			],
			[
				-4,
				-381
			]
		],
		[
			[
				85779,
				35697
			],
			[
				30,
				146
			]
		],
		[
			[
				85343,
				37685
			],
			[
				-168,
				108
			],
			[
				-109,
				-96
			],
			[
				118,
				-437
			],
			[
				-64,
				-175
			],
			[
				82,
				-326
			],
			[
				91,
				-242
			],
			[
				15,
				-201
			]
		],
		[
			[
				61641,
				49451
			],
			[
				110,
				-109
			],
			[
				122,
				-267
			],
			[
				187,
				-95
			],
			[
				46,
				-85
			],
			[
				147,
				-1
			]
		],
		[
			[
				62265,
				48891
			],
			[
				35,
				1602
			]
		],
		[
			[
				42735,
				10258
			],
			[
				981,
				139
			],
			[
				748,
				96
			]
		],
		[
			[
				44468,
				12427
			],
			[
				-214,
				-81
			],
			[
				-145,
				3
			],
			[
				-55,
				-60
			],
			[
				-144,
				24
			],
			[
				-83,
				150
			],
			[
				24,
				115
			],
			[
				-227,
				116
			],
			[
				-71,
				-51
			],
			[
				-115,
				99
			],
			[
				-113,
				-213
			],
			[
				56,
				-106
			],
			[
				-184,
				-157
			],
			[
				-105,
				96
			],
			[
				44,
				112
			],
			[
				-62,
				85
			],
			[
				-52,
				-78
			],
			[
				-36,
				243
			],
			[
				-80,
				-91
			],
			[
				-27,
				157
			],
			[
				-79,
				53
			],
			[
				-66,
				-122
			],
			[
				-63,
				60
			],
			[
				-51,
				-116
			]
		],
		[
			[
				42690,
				11180
			],
			[
				45,
				-922
			]
		],
		[
			[
				65431,
				61426
			],
			[
				30,
				-79
			]
		],
		[
			[
				65461,
				61347
			],
			[
				8,
				-17
			]
		],
		[
			[
				65469,
				61330
			],
			[
				21,
				42
			],
			[
				475,
				-18
			],
			[
				1,
				-67
			],
			[
				133,
				-29
			],
			[
				2,
				76
			],
			[
				128,
				36
			]
		],
		[
			[
				66266,
				62836
			],
			[
				-145,
				17
			]
		],
		[
			[
				66121,
				62853
			],
			[
				-1060,
				113
			]
		],
		[
			[
				65061,
				62966
			],
			[
				25,
				-181
			],
			[
				152,
				43
			],
			[
				52,
				-359
			],
			[
				132,
				-8
			],
			[
				62,
				-100
			],
			[
				-102,
				-171
			],
			[
				75,
				-99
			],
			[
				-51,
				-149
			],
			[
				-96,
				-8
			],
			[
				-27,
				-152
			],
			[
				98,
				-44
			],
			[
				50,
				-312
			]
		],
		[
			[
				62243,
				38391
			],
			[
				25,
				1006
			]
		],
		[
			[
				65543,
				16515
			],
			[
				56,
				-268
			],
			[
				181,
				-234
			],
			[
				152,
				-286
			],
			[
				122,
				-94
			],
			[
				87,
				-164
			]
		],
		[
			[
				66141,
				15469
			],
			[
				324,
				-45
			],
			[
				16,
				327
			],
			[
				108,
				-16
			]
		],
		[
			[
				66589,
				15735
			],
			[
				-19,
				247
			],
			[
				-149,
				276
			],
			[
				-13,
				192
			],
			[
				-113,
				322
			]
		],
		[
			[
				66295,
				16772
			],
			[
				-154,
				22
			],
			[
				15,
				329
			],
			[
				-194,
				26
			],
			[
				73,
				1640
			]
		],
		[
			[
				66035,
				18789
			],
			[
				-480,
				64
			]
		],
		[
			[
				65555,
				18853
			],
			[
				-40,
				-990
			],
			[
				192,
				-21
			],
			[
				-14,
				-326
			],
			[
				-105,
				14
			],
			[
				-45,
				-1015
			]
		],
		[
			[
				72555,
				56369
			],
			[
				271,
				6
			]
		],
		[
			[
				72953,
				57026
			],
			[
				-23,
				418
			]
		],
		[
			[
				72492,
				57700
			],
			[
				-186,
				-781
			]
		],
		[
			[
				65708,
				60801
			],
			[
				60,
				-174
			],
			[
				195,
				-288
			],
			[
				168,
				54
			],
			[
				73,
				-89
			],
			[
				78,
				124
			],
			[
				151,
				127
			]
		],
		[
			[
				65469,
				61330
			],
			[
				52,
				-225
			],
			[
				-64,
				-188
			],
			[
				15,
				-128
			],
			[
				88,
				-56
			],
			[
				133,
				164
			],
			[
				15,
				-96
			]
		],
		[
			[
				65306,
				61237
			],
			[
				35,
				-128
			],
			[
				92,
				0
			],
			[
				28,
				238
			]
		],
		[
			[
				65431,
				61426
			],
			[
				-79,
				-98
			],
			[
				-29,
				139
			],
			[
				-80,
				-25
			],
			[
				63,
				-205
			]
		],
		[
			[
				31213,
				28635
			],
			[
				20,
				-214
			],
			[
				375,
				100
			],
			[
				29,
				-330
			],
			[
				458,
				119
			],
			[
				38,
				-328
			]
		],
		[
			[
				32133,
				27982
			],
			[
				474,
				125
			],
			[
				-31,
				370
			],
			[
				95,
				23
			],
			[
				18,
				222
			],
			[
				-47,
				163
			],
			[
				35,
				389
			],
			[
				119,
				354
			],
			[
				-10,
				273
			],
			[
				80,
				28
			],
			[
				146,
				312
			],
			[
				46,
				-14
			],
			[
				61,
				362
			],
			[
				166,
				380
			],
			[
				92,
				143
			],
			[
				-17,
				164
			],
			[
				100,
				26
			],
			[
				-75,
				992
			],
			[
				42,
				9
			],
			[
				-53,
				648
			]
		],
		[
			[
				33374,
				32951
			],
			[
				-1149,
				-287
			],
			[
				-491,
				-143
			]
		],
		[
			[
				57739,
				82217
			],
			[
				89,
				57
			],
			[
				205,
				-84
			],
			[
				31,
				-202
			],
			[
				126,
				-71
			],
			[
				182,
				99
			]
		],
		[
			[
				58372,
				82016
			],
			[
				72,
				125
			],
			[
				-25,
				167
			],
			[
				121,
				84
			],
			[
				0,
				125
			],
			[
				335,
				181
			],
			[
				37,
				106
			],
			[
				-20,
				324
			]
		],
		[
			[
				58892,
				83128
			],
			[
				-130,
				181
			],
			[
				170,
				484
			]
		],
		[
			[
				58932,
				83793
			],
			[
				1,
				44
			],
			[
				-317,
				3
			],
			[
				-256,
				140
			],
			[
				-424,
				339
			]
		],
		[
			[
				15555,
				11916
			],
			[
				1394,
				657
			],
			[
				1093,
				487
			]
		],
		[
			[
				15474,
				13251
			],
			[
				-106,
				-159
			]
		],
		[
			[
				15759,
				10650
			],
			[
				180,
				85
			],
			[
				37,
				-71
			],
			[
				-35,
				-275
			],
			[
				11,
				-222
			],
			[
				156,
				-328
			],
			[
				-4,
				-194
			],
			[
				101,
				-67
			],
			[
				-99,
				-153
			],
			[
				-14,
				-181
			]
		],
		[
			[
				16092,
				9244
			],
			[
				-14,
				-322
			],
			[
				-32,
				-53
			],
			[
				128,
				-135
			],
			[
				20,
				-142
			],
			[
				91,
				-95
			],
			[
				90,
				-197
			],
			[
				9,
				-115
			]
		],
		[
			[
				67450,
				59593
			],
			[
				376,
				-25
			]
		],
		[
			[
				68028,
				60326
			],
			[
				-94,
				396
			],
			[
				-241,
				270
			],
			[
				-47,
				4
			]
		],
		[
			[
				67646,
				60996
			],
			[
				-428,
				41
			]
		],
		[
			[
				34976,
				20932
			],
			[
				510,
				129
			],
			[
				8,
				-110
			],
			[
				191,
				42
			],
			[
				9,
				-110
			],
			[
				191,
				42
			],
			[
				76,
				-1004
			],
			[
				34,
				7
			],
			[
				33,
				-439
			],
			[
				255,
				56
			],
			[
				153,
				-300
			],
			[
				192,
				41
			],
			[
				15,
				-219
			],
			[
				252,
				53
			]
		],
		[
			[
				36895,
				19120
			],
			[
				133,
				28
			],
			[
				-23,
				328
			],
			[
				384,
				79
			],
			[
				-11,
				164
			],
			[
				192,
				38
			],
			[
				-12,
				165
			],
			[
				175,
				35
			]
		],
		[
			[
				37733,
				19957
			],
			[
				-9,
				146
			],
			[
				95,
				18
			],
			[
				-11,
				165
			],
			[
				-55,
				-11
			],
			[
				-27,
				396
			],
			[
				282,
				98
			],
			[
				-81,
				1220
			],
			[
				-61,
				-17
			],
			[
				-42,
				658
			],
			[
				764,
				145
			]
		],
		[
			[
				38568,
				23487
			],
			[
				-1288,
				-259
			],
			[
				-76,
				-29
			],
			[
				-1217,
				-258
			]
		],
		[
			[
				35987,
				22941
			],
			[
				-530,
				-108
			]
		],
		[
			[
				74737,
				55567
			],
			[
				93,
				328
			],
			[
				70,
				142
			],
			[
				-67,
				108
			]
		],
		[
			[
				74185,
				57177
			],
			[
				-36,
				-16
			]
		],
		[
			[
				55754,
				74498
			],
			[
				893,
				-22
			]
		],
		[
			[
				56630,
				77211
			],
			[
				-117,
				-173
			],
			[
				-68,
				13
			],
			[
				0,
				-194
			],
			[
				-83,
				-79
			],
			[
				-49,
				-168
			],
			[
				-74,
				52
			],
			[
				-124,
				-84
			]
		],
		[
			[
				87341,
				49337
			],
			[
				43,
				-281
			]
		],
		[
			[
				87471,
				49025
			],
			[
				19,
				-38
			]
		],
		[
			[
				88038,
				49084
			],
			[
				-68,
				210
			],
			[
				-78,
				93
			],
			[
				-110,
				322
			]
		],
		[
			[
				87414,
				50303
			],
			[
				-80,
				-673
			]
		],
		[
			[
				66849,
				56367
			],
			[
				-81,
				324
			],
			[
				-79,
				2
			],
			[
				-108,
				-222
			]
		],
		[
			[
				61905,
				81887
			],
			[
				66,
				-249
			],
			[
				135,
				-123
			],
			[
				60,
				-225
			]
		],
		[
			[
				62166,
				81290
			],
			[
				171,
				-328
			],
			[
				118,
				40
			]
		],
		[
			[
				62455,
				81002
			],
			[
				68,
				29
			],
			[
				-20,
				131
			],
			[
				37,
				186
			],
			[
				-50,
				63
			],
			[
				66,
				89
			],
			[
				-65,
				215
			],
			[
				110,
				-32
			],
			[
				35,
				126
			],
			[
				-80,
				317
			]
		],
		[
			[
				62556,
				82126
			],
			[
				-23,
				141
			]
		],
		[
			[
				62533,
				82267
			],
			[
				-199,
				-127
			],
			[
				-48,
				-205
			],
			[
				-76,
				52
			],
			[
				-146,
				-93
			],
			[
				-159,
				-7
			]
		],
		[
			[
				86530,
				43600
			],
			[
				28,
				126
			],
			[
				-125,
				65
			],
			[
				65,
				-214
			]
		],
		[
			[
				60766,
				48154
			],
			[
				122,
				367
			],
			[
				89,
				57
			],
			[
				-45,
				175
			],
			[
				27,
				95
			]
		],
		[
			[
				60587,
				50062
			],
			[
				-388,
				-618
			],
			[
				-3,
				-336
			]
		],
		[
			[
				88688,
				34896
			],
			[
				208,
				-186
			]
		],
		[
			[
				89400,
				33913
			],
			[
				156,
				314
			],
			[
				-83,
				427
			],
			[
				-60,
				4
			],
			[
				-14,
				349
			],
			[
				53,
				54
			],
			[
				-1,
				253
			]
		],
		[
			[
				54804,
				54270
			],
			[
				767,
				7
			]
		],
		[
			[
				55571,
				54277
			],
			[
				8,
				0
			]
		],
		[
			[
				55579,
				54277
			],
			[
				7,
				509
			],
			[
				-5,
				976
			]
		],
		[
			[
				54799,
				54608
			],
			[
				5,
				-338
			]
		],
		[
			[
				77321,
				53073
			],
			[
				-79,
				89
			],
			[
				-92,
				219
			],
			[
				-180,
				162
			],
			[
				-7,
				122
			],
			[
				-144,
				12
			]
		],
		[
			[
				76819,
				53677
			],
			[
				-46,
				-157
			]
		],
		[
			[
				77586,
				52331
			],
			[
				44,
				80
			],
			[
				249,
				-434
			],
			[
				150,
				-47
			]
		],
		[
			[
				78029,
				51930
			],
			[
				121,
				143
			],
			[
				68,
				-96
			],
			[
				127,
				170
			]
		],
		[
			[
				78345,
				52147
			],
			[
				-250,
				357
			],
			[
				23,
				287
			],
			[
				-93,
				166
			],
			[
				-80,
				29
			],
			[
				-62,
				137
			]
		],
		[
			[
				77883,
				53123
			],
			[
				-135,
				-128
			],
			[
				-351,
				321
			],
			[
				35,
				-191
			]
		],
		[
			[
				52711,
				53906
			],
			[
				-3,
				1839
			]
		],
		[
			[
				52708,
				55745
			],
			[
				-556,
				-11
			]
		],
		[
			[
				52152,
				55734
			],
			[
				-601,
				-18
			]
		],
		[
			[
				67855,
				54581
			],
			[
				131,
				24
			]
		],
		[
			[
				67986,
				54605
			],
			[
				54,
				-30
			],
			[
				47,
				-301
			],
			[
				-50,
				-201
			],
			[
				-134,
				-294
			],
			[
				51,
				-405
			],
			[
				101,
				-156
			]
		],
		[
			[
				68055,
				53218
			],
			[
				102,
				59
			]
		],
		[
			[
				68157,
				53277
			],
			[
				73,
				17
			],
			[
				54,
				234
			],
			[
				142,
				222
			],
			[
				50,
				201
			],
			[
				-36,
				146
			],
			[
				54,
				130
			]
		],
		[
			[
				68007,
				54778
			],
			[
				-48,
				-2
			],
			[
				-104,
				-195
			]
		],
		[
			[
				55556,
				25902
			],
			[
				-26,
				-144
			],
			[
				-185,
				-230
			],
			[
				-90,
				-205
			],
			[
				-103,
				-121
			]
		],
		[
			[
				62038,
				45224
			],
			[
				-3,
				-111
			],
			[
				696,
				-50
			]
		],
		[
			[
				62731,
				45063
			],
			[
				118,
				202
			],
			[
				111,
				113
			]
		],
		[
			[
				30202,
				18409
			],
			[
				154,
				53
			],
			[
				82,
				-122
			]
		],
		[
			[
				31485,
				17243
			],
			[
				426,
				115
			]
		],
		[
			[
				30957,
				23008
			],
			[
				-58,
				730
			]
		],
		[
			[
				29135,
				50294
			],
			[
				-634,
				-167
			],
			[
				-1390,
				-388
			],
			[
				-51,
				1
			],
			[
				-557,
				-165
			],
			[
				-337,
				-125
			],
			[
				-557,
				-169
			]
		],
		[
			[
				35987,
				22941
			],
			[
				41,
				76
			],
			[
				-1,
				436
			],
			[
				58,
				218
			],
			[
				-30,
				68
			],
			[
				107,
				125
			],
			[
				7,
				213
			],
			[
				112,
				164
			],
			[
				94,
				-44
			],
			[
				125,
				158
			],
			[
				68,
				199
			],
			[
				129,
				102
			],
			[
				21,
				142
			]
		],
		[
			[
				36718,
				24798
			],
			[
				95,
				265
			],
			[
				-13,
				141
			],
			[
				154,
				97
			],
			[
				-10,
				163
			],
			[
				51,
				130
			],
			[
				-48,
				68
			],
			[
				63,
				106
			],
			[
				-44,
				602
			]
		],
		[
			[
				36966,
				26370
			],
			[
				-2225,
				-487
			]
		],
		[
			[
				14448,
				7188
			],
			[
				208,
				-192
			],
			[
				163,
				-369
			],
			[
				7,
				-96
			],
			[
				100,
				246
			],
			[
				71,
				-178
			]
		],
		[
			[
				16092,
				9244
			],
			[
				-448,
				-212
			],
			[
				-141,
				147
			],
			[
				-168,
				-180
			],
			[
				-107,
				-2
			],
			[
				-253,
				-202
			]
		],
		[
			[
				14975,
				8795
			],
			[
				-97,
				-64
			],
			[
				-45,
				-141
			],
			[
				33,
				-109
			],
			[
				-254,
				-279
			],
			[
				18,
				-141
			],
			[
				-79,
				-149
			],
			[
				2,
				-152
			],
			[
				-89,
				-63
			],
			[
				-43,
				-368
			],
			[
				27,
				-141
			]
		],
		[
			[
				14454,
				6196
			],
			[
				33,
				-177
			]
		],
		[
			[
				14487,
				6019
			],
			[
				373,
				187
			]
		],
		[
			[
				14860,
				6206
			],
			[
				-3,
				106
			],
			[
				-89,
				183
			],
			[
				18,
				140
			],
			[
				-123,
				208
			],
			[
				-98,
				-311
			],
			[
				89,
				-284
			],
			[
				-102,
				94
			],
			[
				-68,
				180
			],
			[
				11,
				166
			],
			[
				103,
				173
			],
			[
				-61,
				24
			],
			[
				-78,
				228
			],
			[
				-75,
				-208
			],
			[
				-49,
				-382
			],
			[
				85,
				-84
			],
			[
				34,
				-243
			]
		],
		[
			[
				10226,
				41227
			],
			[
				30,
				154
			],
			[
				156,
				92
			],
			[
				169,
				-196
			],
			[
				137,
				69
			]
		],
		[
			[
				10718,
				41346
			],
			[
				-43,
				214
			],
			[
				34,
				93
			],
			[
				-62,
				102
			],
			[
				12,
				212
			],
			[
				-81,
				260
			],
			[
				70,
				67
			],
			[
				-58,
				102
			]
		],
		[
			[
				9366,
				41489
			],
			[
				-119,
				-110
			],
			[
				-36,
				-230
			],
			[
				114,
				-5
			],
			[
				16,
				-111
			],
			[
				116,
				44
			],
			[
				88,
				-155
			]
		],
		[
			[
				22301,
				22218
			],
			[
				145,
				55
			],
			[
				103,
				-590
			],
			[
				86,
				-396
			],
			[
				42,
				-77
			]
		],
		[
			[
				22677,
				21210
			],
			[
				100,
				-35
			],
			[
				65,
				-140
			],
			[
				83,
				51
			]
		],
		[
			[
				21727,
				23354
			],
			[
				84,
				-652
			],
			[
				219,
				86
			],
			[
				77,
				-83
			],
			[
				27,
				-214
			],
			[
				125,
				49
			],
			[
				42,
				-322
			]
		],
		[
			[
				21606,
				21951
			],
			[
				695,
				267
			]
		],
		[
			[
				21344,
				22922
			],
			[
				140,
				-502
			],
			[
				-36,
				-266
			],
			[
				76,
				-28
			],
			[
				82,
				-175
			]
		],
		[
			[
				87233,
				43310
			],
			[
				-1,
				-25
			]
		],
		[
			[
				87232,
				43285
			],
			[
				174,
				-459
			],
			[
				-190,
				-220
			]
		],
		[
			[
				87334,
				41914
			],
			[
				93,
				77
			]
		],
		[
			[
				87848,
				43240
			],
			[
				-9,
				152
			],
			[
				93,
				335
			],
			[
				31,
				311
			]
		],
		[
			[
				87963,
				44038
			],
			[
				-121,
				-49
			],
			[
				-37,
				-214
			],
			[
				-215,
				-90
			],
			[
				-248,
				112
			],
			[
				-46,
				157
			],
			[
				-105,
				-318
			]
		],
		[
			[
				21389,
				37719
			],
			[
				-54,
				477
			],
			[
				-7,
				581
			],
			[
				-69,
				330
			],
			[
				-54,
				121
			],
			[
				-36,
				540
			],
			[
				-144,
				405
			],
			[
				-70,
				30
			],
			[
				-138,
				1118
			]
		],
		[
			[
				20817,
				41321
			],
			[
				-1165,
				-429
			]
		],
		[
			[
				19652,
				40892
			],
			[
				128,
				-884
			],
			[
				317,
				-2554
			],
			[
				129,
				-924
			],
			[
				299,
				-2359
			],
			[
				42,
				-197
			]
		],
		[
			[
				60853,
				71924
			],
			[
				289,
				25
			],
			[
				100,
				101
			],
			[
				5,
				192
			],
			[
				194,
				-10
			],
			[
				7,
				336
			]
		],
		[
			[
				61448,
				72568
			],
			[
				7,
				335
			]
		],
		[
			[
				61455,
				72903
			],
			[
				-388,
				21
			],
			[
				5,
				167
			],
			[
				-292,
				15
			]
		],
		[
			[
				62090,
				71985
			],
			[
				-41,
				129
			],
			[
				-334,
				443
			],
			[
				-267,
				11
			]
		],
		[
			[
				79335,
				57719
			],
			[
				-121,
				-146
			],
			[
				-24,
				-196
			],
			[
				-129,
				-33
			]
		],
		[
			[
				78839,
				56776
			],
			[
				69,
				-134
			],
			[
				57,
				-267
			]
		],
		[
			[
				45470,
				64024
			],
			[
				265,
				23
			]
		],
		[
			[
				45507,
				65709
			],
			[
				-786,
				-74
			]
		],
		[
			[
				44721,
				65635
			],
			[
				56,
				-1674
			]
		],
		[
			[
				54653,
				29369
			],
			[
				947,
				1
			]
		],
		[
			[
				55600,
				29370
			],
			[
				16,
				-1
			]
		],
		[
			[
				55616,
				29369
			],
			[
				1,
				1334
			]
		],
		[
			[
				67180,
				54092
			],
			[
				9,
				3
			]
		],
		[
			[
				67189,
				54095
			],
			[
				221,
				119
			],
			[
				137,
				155
			],
			[
				183,
				65
			],
			[
				125,
				147
			]
		],
		[
			[
				85351,
				56510
			],
			[
				-75,
				265
			]
		],
		[
			[
				84603,
				56995
			],
			[
				-365,
				121
			]
		],
		[
			[
				61029,
				29155
			],
			[
				560,
				-41
			]
		],
		[
			[
				61589,
				29114
			],
			[
				35,
				1328
			]
		],
		[
			[
				61061,
				30484
			],
			[
				-595,
				41
			]
		],
		[
			[
				28387,
				36025
			],
			[
				-14,
				450
			],
			[
				80,
				272
			],
			[
				-67,
				-13
			],
			[
				46,
				346
			],
			[
				45,
				58
			]
		],
		[
			[
				28477,
				37138
			],
			[
				-232,
				87
			],
			[
				-126,
				-38
			],
			[
				-18,
				-128
			],
			[
				35,
				-257
			],
			[
				-79,
				-23
			],
			[
				-262,
				219
			],
			[
				-215,
				238
			]
		],
		[
			[
				27580,
				37236
			],
			[
				-266,
				-1283
			]
		],
		[
			[
				78046,
				49557
			],
			[
				31,
				-300
			],
			[
				147,
				-2
			]
		],
		[
			[
				78574,
				49967
			],
			[
				-129,
				143
			],
			[
				28,
				153
			],
			[
				-89,
				32
			],
			[
				-120,
				-70
			],
			[
				-71,
				54
			]
		],
		[
			[
				86015,
				57625
			],
			[
				-202,
				-19
			],
			[
				-121,
				41
			],
			[
				-137,
				178
			],
			[
				-372,
				269
			]
		],
		[
			[
				85183,
				58094
			],
			[
				-108,
				-53
			],
			[
				-148,
				-166
			],
			[
				55,
				-169
			]
		],
		[
			[
				55616,
				29369
			],
			[
				952,
				-15
			]
		],
		[
			[
				56585,
				30688
			],
			[
				-97,
				3
			]
		],
		[
			[
				56488,
				30691
			],
			[
				-763,
				12
			]
		],
		[
			[
				25020,
				51231
			],
			[
				196,
				-1899
			]
		],
		[
			[
				51959,
				74320
			],
			[
				-74,
				152
			],
			[
				-100,
				-67
			],
			[
				0,
				-101
			],
			[
				-87,
				-107
			]
		],
		[
			[
				51702,
				73755
			],
			[
				6,
				-910
			]
		],
		[
			[
				67558,
				29628
			],
			[
				395,
				-60
			]
		],
		[
			[
				67953,
				29568
			],
			[
				12,
				192
			],
			[
				-97,
				396
			],
			[
				-48,
				559
			],
			[
				43,
				219
			]
		],
		[
			[
				67863,
				30934
			],
			[
				-276,
				40
			]
		],
		[
			[
				70592,
				55863
			],
			[
				524,
				-108
			]
		],
		[
			[
				71116,
				55755
			],
			[
				306,
				-65
			],
			[
				50,
				65
			]
		],
		[
			[
				71472,
				55755
			],
			[
				-100,
				386
			],
			[
				-19,
				268
			],
			[
				-60,
				254
			],
			[
				-108,
				25
			]
		],
		[
			[
				71185,
				56688
			],
			[
				-48,
				185
			],
			[
				-65,
				49
			],
			[
				-163,
				-100
			]
		],
		[
			[
				70909,
				56822
			],
			[
				-130,
				73
			],
			[
				-36,
				-84
			],
			[
				-217,
				-209
			]
		],
		[
			[
				33190,
				12980
			],
			[
				-18,
				218
			],
			[
				128,
				32
			],
			[
				-28,
				328
			],
			[
				192,
				48
			],
			[
				-14,
				163
			],
			[
				193,
				48
			],
			[
				-13,
				164
			],
			[
				174,
				42
			],
			[
				-91,
				1091
			],
			[
				-61,
				41
			],
			[
				-83,
				285
			],
			[
				105,
				109
			],
			[
				-11,
				330
			]
		],
		[
			[
				32866,
				15613
			],
			[
				-97,
				119
			],
			[
				-64,
				-93
			],
			[
				-144,
				17
			],
			[
				-128,
				-265
			],
			[
				12,
				-122
			],
			[
				-87,
				-26
			],
			[
				-31,
				-207
			]
		],
		[
			[
				32327,
				15036
			],
			[
				30,
				-44
			],
			[
				75,
				-976
			],
			[
				-190,
				-54
			],
			[
				54,
				-516
			],
			[
				-27,
				-64
			],
			[
				69,
				-404
			],
			[
				193,
				51
			],
			[
				19,
				-216
			]
		],
		[
			[
				58420,
				74206
			],
			[
				172,
				-5
			],
			[
				168,
				-229
			],
			[
				21,
				-150
			],
			[
				104,
				-188
			],
			[
				142,
				85
			],
			[
				93,
				-7
			],
			[
				82,
				127
			],
			[
				20,
				176
			]
		],
		[
			[
				58744,
				75546
			],
			[
				-59,
				-186
			],
			[
				-130,
				-123
			],
			[
				-69,
				-210
			]
		],
		[
			[
				58486,
				75027
			],
			[
				-58,
				-66
			],
			[
				-8,
				-755
			]
		],
		[
			[
				87600,
				39494
			],
			[
				56,
				402
			],
			[
				88,
				246
			],
			[
				178,
				102
			],
			[
				104,
				130
			],
			[
				80,
				247
			]
		],
		[
			[
				88106,
				40621
			],
			[
				83,
				265
			],
			[
				-75,
				181
			],
			[
				77,
				46
			],
			[
				-137,
				293
			],
			[
				-104,
				-18
			],
			[
				-63,
				-104
			]
		],
		[
			[
				87887,
				41284
			],
			[
				-60,
				-493
			],
			[
				-305,
				108
			],
			[
				44,
				355
			],
			[
				173,
				98
			]
		],
		[
			[
				87618,
				41487
			],
			[
				-186,
				-139
			],
			[
				-21,
				-181
			],
			[
				-98,
				26
			],
			[
				-67,
				-112
			]
		],
		[
			[
				87246,
				41081
			],
			[
				-67,
				-399
			],
			[
				28,
				-302
			],
			[
				30,
				-756
			]
		],
		[
			[
				25111,
				10251
			],
			[
				-25,
				64
			],
			[
				-170,
				13
			],
			[
				252,
				447
			],
			[
				15,
				186
			],
			[
				155,
				207
			],
			[
				122,
				75
			],
			[
				77,
				437
			],
			[
				72,
				101
			],
			[
				115,
				372
			],
			[
				26,
				304
			],
			[
				71,
				15
			],
			[
				39,
				165
			]
		],
		[
			[
				25860,
				12637
			],
			[
				-994,
				-352
			],
			[
				-1054,
				-389
			]
		],
		[
			[
				23812,
				11896
			],
			[
				41,
				-327
			]
		],
		[
			[
				88323,
				39233
			],
			[
				578,
				-213
			]
		],
		[
			[
				88901,
				39020
			],
			[
				30,
				241
			],
			[
				170,
				1045
			]
		],
		[
			[
				89101,
				40306
			],
			[
				-97,
				21
			],
			[
				-94,
				116
			],
			[
				-202,
				13
			]
		],
		[
			[
				88708,
				40456
			],
			[
				-60,
				-17
			],
			[
				69,
				-268
			],
			[
				-62,
				2
			],
			[
				21,
				-420
			],
			[
				-169,
				119
			]
		],
		[
			[
				88507,
				39872
			],
			[
				-194,
				-350
			],
			[
				-153,
				-229
			]
		],
		[
			[
				87469,
				55557
			],
			[
				195,
				266
			],
			[
				211,
				125
			],
			[
				-6,
				83
			],
			[
				95,
				246
			]
		],
		[
			[
				87404,
				56688
			],
			[
				-62,
				-209
			],
			[
				-62,
				-597
			]
		],
		[
			[
				89470,
				35374
			],
			[
				235,
				-496
			],
			[
				70,
				-90
			],
			[
				91,
				-341
			]
		],
		[
			[
				90280,
				35724
			],
			[
				-96,
				67
			],
			[
				27,
				115
			],
			[
				-108,
				70
			],
			[
				38,
				109
			],
			[
				-141,
				74
			]
		],
		[
			[
				59352,
				65913
			],
			[
				130,
				-1
			]
		],
		[
			[
				60450,
				66571
			],
			[
				0,
				665
			],
			[
				-125,
				42
			],
			[
				131,
				268
			],
			[
				6,
				357
			]
		],
		[
			[
				60462,
				67903
			],
			[
				-252,
				168
			],
			[
				-134,
				-20
			]
		],
		[
			[
				80674,
				53979
			],
			[
				797,
				-164
			]
		],
		[
			[
				81471,
				53815
			],
			[
				-7,
				162
			],
			[
				-82,
				287
			],
			[
				32,
				192
			]
		],
		[
			[
				81414,
				54456
			],
			[
				-71,
				138
			],
			[
				-168,
				-225
			],
			[
				-66,
				127
			],
			[
				-51,
				-64
			],
			[
				-136,
				299
			]
		],
		[
			[
				81471,
				53815
			],
			[
				113,
				-21
			]
		],
		[
			[
				81981,
				53689
			],
			[
				305,
				-58
			]
		],
		[
			[
				81638,
				55040
			],
			[
				-25,
				-345
			],
			[
				-199,
				-239
			]
		],
		[
			[
				86559,
				36290
			],
			[
				-44,
				253
			],
			[
				113,
				375
			],
			[
				-94,
				313
			],
			[
				49,
				112
			],
			[
				117,
				-37
			],
			[
				51,
				96
			]
		],
		[
			[
				86751,
				37402
			],
			[
				-90,
				103
			],
			[
				-427,
				250
			],
			[
				-123,
				107
			],
			[
				-19,
				-89
			],
			[
				-152,
				185
			],
			[
				80,
				-248
			],
			[
				-98,
				142
			],
			[
				-253,
				483
			],
			[
				-21,
				-45
			]
		],
		[
			[
				64014,
				57262
			],
			[
				370,
				-35
			]
		],
		[
			[
				65112,
				58306
			],
			[
				-234,
				23
			],
			[
				-6,
				-226
			],
			[
				-869,
				56
			]
		],
		[
			[
				64003,
				58159
			],
			[
				96,
				-75
			],
			[
				-11,
				-497
			],
			[
				-58,
				-91
			],
			[
				-16,
				-234
			]
		],
		[
			[
				28477,
				37138
			],
			[
				120,
				76
			],
			[
				19,
				206
			]
		],
		[
			[
				28612,
				38286
			],
			[
				-152,
				129
			],
			[
				-145,
				-27
			],
			[
				-57,
				107
			],
			[
				-147,
				39
			],
			[
				-205,
				186
			],
			[
				-74,
				-219
			],
			[
				-54,
				56
			],
			[
				-169,
				-130
			]
		],
		[
			[
				27609,
				38427
			],
			[
				48,
				-522
			],
			[
				-26,
				-196
			],
			[
				22,
				-206
			],
			[
				-73,
				-267
			]
		],
		[
			[
				80948,
				49043
			],
			[
				221,
				-255
			]
		],
		[
			[
				81466,
				49242
			],
			[
				11,
				64
			],
			[
				-240,
				1186
			]
		],
		[
			[
				81237,
				50492
			],
			[
				-2,
				-5
			]
		],
		[
			[
				81235,
				50487
			],
			[
				-464,
				-495
			]
		],
		[
			[
				80771,
				49992
			],
			[
				27,
				-207
			],
			[
				183,
				-346
			],
			[
				131,
				81
			],
			[
				-99,
				-194
			],
			[
				41,
				-107
			],
			[
				-106,
				-176
			]
		],
		[
			[
				80493,
				55812
			],
			[
				72,
				-119
			],
			[
				58,
				-236
			],
			[
				-52,
				-145
			]
		],
		[
			[
				81431,
				55842
			],
			[
				-46,
				68
			],
			[
				-238,
				43
			],
			[
				-125,
				76
			],
			[
				-104,
				143
			]
		],
		[
			[
				66493,
				32471
			],
			[
				-385,
				31
			]
		],
		[
			[
				52810,
				23798
			],
			[
				344,
				10
			],
			[
				23,
				-114
			],
			[
				821,
				12
			]
		],
		[
			[
				53998,
				23706
			],
			[
				27,
				220
			]
		],
		[
			[
				51657,
				21326
			],
			[
				1168,
				36
			]
		],
		[
			[
				52825,
				21362
			],
			[
				-9,
				1440
			]
		],
		[
			[
				51640,
				22652
			],
			[
				17,
				-1326
			]
		],
		[
			[
				59849,
				23677
			],
			[
				-430,
				20
			]
		],
		[
			[
				59411,
				23258
			],
			[
				-14,
				-555
			],
			[
				-188,
				6
			],
			[
				-11,
				-659
			]
		],
		[
			[
				30836,
				14280
			],
			[
				180,
				51
			],
			[
				31,
				-327
			],
			[
				384,
				105
			],
			[
				196,
				13
			],
			[
				27,
				-288
			],
			[
				117,
				34
			],
			[
				-16,
				312
			],
			[
				115,
				34
			],
			[
				117,
				126
			],
			[
				66,
				246
			],
			[
				146,
				159
			],
			[
				-14,
				173
			],
			[
				142,
				118
			]
		],
		[
			[
				52320,
				75973
			],
			[
				-627,
				589
			]
		],
		[
			[
				58952,
				69239
			],
			[
				-10,
				254
			],
			[
				111,
				130
			],
			[
				-67,
				109
			],
			[
				75,
				70
			],
			[
				-35,
				221
			],
			[
				-75,
				-4
			],
			[
				50,
				129
			],
			[
				-148,
				395
			],
			[
				-22,
				-80
			],
			[
				-104,
				119
			],
			[
				-9,
				170
			],
			[
				106,
				273
			]
		],
		[
			[
				58824,
				71025
			],
			[
				-18,
				0
			]
		],
		[
			[
				58806,
				71025
			],
			[
				-425,
				17
			]
		],
		[
			[
				58368,
				69969
			],
			[
				-13,
				-978
			]
		],
		[
			[
				23477,
				12962
			],
			[
				190,
				75
			],
			[
				145,
				-1141
			]
		],
		[
			[
				25860,
				12637
			],
			[
				45,
				67
			],
			[
				-53,
				216
			],
			[
				67,
				220
			],
			[
				135,
				162
			],
			[
				-1,
				318
			],
			[
				156,
				-103
			]
		],
		[
			[
				23531,
				13614
			],
			[
				15,
				-122
			],
			[
				-120,
				-35
			],
			[
				51,
				-495
			]
		],
		[
			[
				91131,
				56108
			],
			[
				-308,
				429
			],
			[
				-101,
				207
			],
			[
				24,
				-238
			],
			[
				370,
				-450
			]
		],
		[
			[
				89750,
				54590
			],
			[
				225,
				-54
			],
			[
				-12,
				327
			],
			[
				152,
				-12
			],
			[
				76,
				-440
			],
			[
				95,
				94
			],
			[
				97,
				-16
			]
		],
		[
			[
				90383,
				54489
			],
			[
				52,
				-34
			]
		],
		[
			[
				90729,
				54688
			],
			[
				-111,
				231
			],
			[
				-60,
				255
			],
			[
				-21,
				271
			],
			[
				-66,
				38
			],
			[
				6,
				132
			],
			[
				-110,
				207
			],
			[
				-173,
				-22
			],
			[
				-185,
				80
			],
			[
				-268,
				-2
			],
			[
				-115,
				-114
			],
			[
				-126,
				-342
			],
			[
				192,
				-79
			],
			[
				-4,
				-172
			]
		],
		[
			[
				73809,
				61735
			],
			[
				141,
				-247
			],
			[
				35,
				-193
			],
			[
				-65,
				-57
			],
			[
				5,
				-162
			]
		],
		[
			[
				73925,
				61076
			],
			[
				210,
				-707
			],
			[
				27,
				-142
			]
		],
		[
			[
				74524,
				59942
			],
			[
				-19,
				120
			],
			[
				97,
				45
			],
			[
				79,
				276
			]
		],
		[
			[
				74681,
				60383
			],
			[
				-105,
				609
			],
			[
				129,
				551
			]
		],
		[
			[
				74705,
				61543
			],
			[
				-6,
				1
			]
		],
		[
			[
				74699,
				61544
			],
			[
				-513,
				111
			]
		],
		[
			[
				57280,
				33959
			],
			[
				770,
				-23
			]
		],
		[
			[
				58050,
				33936
			],
			[
				5,
				333
			]
		],
		[
			[
				64025,
				39618
			],
			[
				-578,
				37
			]
		],
		[
			[
				63447,
				39655
			],
			[
				-273,
				20
			]
		],
		[
			[
				63174,
				39675
			],
			[
				-1,
				-10
			]
		],
		[
			[
				63173,
				39665
			],
			[
				-77,
				-313
			],
			[
				-88,
				-32
			],
			[
				-129,
				-279
			],
			[
				59,
				-360
			]
		],
		[
			[
				90516,
				35829
			],
			[
				0,
				134
			],
			[
				155,
				138
			],
			[
				122,
				17
			],
			[
				7,
				79
			]
		],
		[
			[
				90687,
				36696
			],
			[
				-213,
				-97
			],
			[
				-16,
				151
			]
		],
		[
			[
				51731,
				34743
			],
			[
				572,
				14
			]
		],
		[
			[
				52493,
				35095
			],
			[
				-10,
				1004
			]
		],
		[
			[
				33060,
				70431
			],
			[
				-585,
				-121
			],
			[
				-25,
				332
			],
			[
				-568,
				-123
			],
			[
				-128,
				1665
			],
			[
				25,
				6
			],
			[
				-63,
				822
			]
		],
		[
			[
				30443,
				69179
			],
			[
				132,
				-1644
			]
		],
		[
			[
				40447,
				57750
			],
			[
				-8,
				167
			],
			[
				382,
				57
			],
			[
				-16,
				339
			],
			[
				766,
				102
			],
			[
				-34,
				335
			],
			[
				-45,
				995
			]
		],
		[
			[
				41492,
				59745
			],
			[
				-69,
				1314
			],
			[
				-20,
				28
			],
			[
				-444,
				-59
			]
		],
		[
			[
				40959,
				61028
			],
			[
				6,
				-124
			],
			[
				-158,
				-23
			],
			[
				-382,
				-1522
			],
			[
				-83,
				66
			],
			[
				-620,
				-92
			]
		],
		[
			[
				39722,
				59333
			],
			[
				-25,
				-308
			],
			[
				94,
				-188
			],
			[
				39,
				-180
			],
			[
				-47,
				-94
			],
			[
				-10,
				-327
			],
			[
				-67,
				-231
			],
			[
				25,
				-119
			],
			[
				-44,
				-252
			]
		],
		[
			[
				63815,
				32894
			],
			[
				957,
				-104
			]
		],
		[
			[
				64772,
				32790
			],
			[
				47,
				1182
			]
		],
		[
			[
				63856,
				34068
			],
			[
				-41,
				-1174
			]
		],
		[
			[
				91340,
				34290
			],
			[
				72,
				-136
			],
			[
				-3,
				-114
			]
		],
		[
			[
				91543,
				34659
			],
			[
				-131,
				22
			],
			[
				-76,
				-169
			],
			[
				4,
				-222
			]
		],
		[
			[
				78109,
				53749
			],
			[
				30,
				-136
			],
			[
				105,
				23
			],
			[
				80,
				-246
			],
			[
				403,
				-126
			],
			[
				-2,
				37
			]
		],
		[
			[
				78725,
				53301
			],
			[
				184,
				606
			]
		],
		[
			[
				78909,
				53907
			],
			[
				104,
				417
			]
		],
		[
			[
				78459,
				54466
			],
			[
				-389,
				95
			]
		],
		[
			[
				77797,
				54626
			],
			[
				41,
				-330
			],
			[
				201,
				-271
			],
			[
				70,
				-276
			]
		],
		[
			[
				60481,
				50875
			],
			[
				-104,
				43
			],
			[
				-13,
				89
			],
			[
				-346,
				-10
			],
			[
				-168,
				-278
			],
			[
				-36,
				35
			]
		],
		[
			[
				61092,
				61856
			],
			[
				2,
				338
			],
			[
				-81,
				223
			],
			[
				61,
				191
			],
			[
				-34,
				230
			],
			[
				-60,
				9
			]
		],
		[
			[
				65956,
				58986
			],
			[
				47,
				-7
			],
			[
				35,
				-319
			],
			[
				147,
				-190
			],
			[
				-72,
				-216
			]
		],
		[
			[
				66113,
				58254
			],
			[
				252,
				-38
			],
			[
				5,
				-98
			]
		],
		[
			[
				66370,
				58118
			],
			[
				587,
				-39
			]
		],
		[
			[
				66957,
				58079
			],
			[
				12,
				689
			],
			[
				-40,
				107
			]
		],
		[
			[
				66672,
				59365
			],
			[
				-125,
				-239
			],
			[
				-150,
				14
			],
			[
				-25,
				93
			],
			[
				-233,
				32
			],
			[
				-11,
				74
			]
		],
		[
			[
				66128,
				59339
			],
			[
				-142,
				-231
			],
			[
				-30,
				-122
			]
		],
		[
			[
				58512,
				29293
			],
			[
				19,
				1338
			]
		],
		[
			[
				85183,
				58094
			],
			[
				5,
				69
			]
		],
		[
			[
				84594,
				58857
			],
			[
				-80,
				-56
			],
			[
				-129,
				28
			],
			[
				-28,
				-208
			],
			[
				-87,
				-139
			]
		],
		[
			[
				84270,
				58482
			],
			[
				-191,
				-300
			],
			[
				-219,
				-940
			]
		],
		[
			[
				59110,
				24713
			],
			[
				30,
				-2
			],
			[
				21,
				484
			],
			[
				36,
				77
			]
		],
		[
			[
				58964,
				25656
			],
			[
				-81,
				25
			],
			[
				-92,
				-100
			],
			[
				-130,
				39
			]
		],
		[
			[
				71116,
				55755
			],
			[
				4,
				-304
			],
			[
				82,
				-468
			],
			[
				130,
				-172
			]
		],
		[
			[
				71332,
				54811
			],
			[
				62,
				194
			],
			[
				328,
				33
			],
			[
				-3,
				121
			]
		],
		[
			[
				71738,
				55643
			],
			[
				-171,
				22
			],
			[
				-95,
				90
			]
		],
		[
			[
				83455,
				58802
			],
			[
				97,
				-6
			],
			[
				291,
				-264
			],
			[
				280,
				-63
			],
			[
				147,
				13
			]
		],
		[
			[
				57061,
				69888
			],
			[
				-10,
				-1440
			],
			[
				16,
				-11
			]
		],
		[
			[
				69147,
				60808
			],
			[
				29,
				-121
			],
			[
				327,
				-169
			],
			[
				155,
				-17
			]
		],
		[
			[
				69930,
				60789
			],
			[
				46,
				798
			],
			[
				-22,
				188
			],
			[
				20,
				324
			],
			[
				-21,
				215
			]
		],
		[
			[
				68041,
				56790
			],
			[
				37,
				1348
			]
		],
		[
			[
				67804,
				58520
			],
			[
				-141,
				-174
			],
			[
				-134,
				-26
			],
			[
				-228,
				-355
			]
		],
		[
			[
				67301,
				57965
			],
			[
				-14,
				-719
			],
			[
				116,
				48
			],
			[
				121,
				-84
			],
			[
				-35,
				-352
			]
		],
		[
			[
				65590,
				33904
			],
			[
				-59,
				-1322
			]
		],
		[
			[
				21937,
				19372
			],
			[
				425,
				172
			],
			[
				-84,
				658
			],
			[
				10,
				192
			],
			[
				116,
				44
			],
			[
				-58,
				465
			],
			[
				164,
				245
			],
			[
				167,
				62
			]
		],
		[
			[
				21606,
				21951
			],
			[
				-24,
				-163
			],
			[
				-70,
				-49
			],
			[
				19,
				-144
			],
			[
				-120,
				-23
			],
			[
				-62,
				-216
			],
			[
				-116,
				33
			],
			[
				-56,
				-189
			]
		],
		[
			[
				21177,
				21200
			],
			[
				64,
				-107
			],
			[
				-52,
				-262
			],
			[
				58,
				-98
			],
			[
				14,
				-217
			],
			[
				135,
				-136
			],
			[
				28,
				-134
			],
			[
				208,
				-542
			],
			[
				225,
				-137
			],
			[
				80,
				-195
			]
		],
		[
			[
				82737,
				41088
			],
			[
				141,
				-44
			]
		],
		[
			[
				82878,
				41044
			],
			[
				773,
				-242
			]
		],
		[
			[
				83651,
				40802
			],
			[
				-131,
				1014
			]
		],
		[
			[
				83520,
				41816
			],
			[
				-100,
				289
			],
			[
				-162,
				253
			],
			[
				-23,
				116
			]
		],
		[
			[
				83235,
				42474
			],
			[
				-169,
				299
			],
			[
				-70,
				211
			],
			[
				-75,
				66
			]
		],
		[
			[
				82921,
				43050
			],
			[
				-184,
				-1962
			]
		],
		[
			[
				82256,
				41232
			],
			[
				481,
				-144
			]
		],
		[
			[
				82921,
				43050
			],
			[
				4,
				43
			]
		],
		[
			[
				82925,
				43093
			],
			[
				-43,
				-71
			],
			[
				-325,
				-119
			],
			[
				-191,
				216
			]
		],
		[
			[
				82195,
				42899
			],
			[
				-51,
				-518
			]
		],
		[
			[
				51318,
				31809
			],
			[
				51,
				111
			]
		],
		[
			[
				51229,
				33181
			],
			[
				-253,
				-177
			]
		],
		[
			[
				79582,
				58345
			],
			[
				-111,
				57
			],
			[
				-43,
				223
			]
		],
		[
			[
				79428,
				58625
			],
			[
				-130,
				-102
			],
			[
				-56,
				168
			],
			[
				-86,
				-32
			],
			[
				-60,
				112
			],
			[
				-293,
				126
			],
			[
				-97,
				-70
			],
			[
				-118,
				169
			]
		],
		[
			[
				87992,
				34657
			],
			[
				228,
				298
			],
			[
				249,
				227
			]
		],
		[
			[
				87275,
				36326
			],
			[
				-321,
				-292
			]
		],
		[
			[
				86954,
				36034
			],
			[
				475,
				-631
			]
		],
		[
			[
				63959,
				59317
			],
			[
				832,
				-67
			],
			[
				13,
				-121
			]
		],
		[
			[
				64963,
				59109
			],
			[
				44,
				1142
			]
		],
		[
			[
				63658,
				60348
			],
			[
				-12,
				-682
			]
		],
		[
			[
				40250,
				63963
			],
			[
				383,
				56
			]
		],
		[
			[
				41708,
				68157
			],
			[
				-844,
				-109
			]
		],
		[
			[
				42549,
				33977
			],
			[
				-60,
				1330
			],
			[
				70,
				10
			],
			[
				-16,
				334
			]
		],
		[
			[
				41589,
				33170
			],
			[
				73,
				-1490
			]
		],
		[
			[
				52483,
				36099
			],
			[
				575,
				14
			]
		],
		[
			[
				53049,
				37451
			],
			[
				-385,
				-8
			]
		],
		[
			[
				79841,
				56597
			],
			[
				64,
				-175
			],
			[
				177,
				103
			]
		],
		[
			[
				80934,
				57060
			],
			[
				-73,
				312
			],
			[
				-161,
				527
			]
		],
		[
			[
				80695,
				57917
			],
			[
				-197,
				63
			],
			[
				-83,
				-50
			]
		],
		[
			[
				80415,
				57930
			],
			[
				-122,
				-95
			],
			[
				-112,
				174
			]
		],
		[
			[
				92070,
				33032
			],
			[
				87,
				187
			],
			[
				167,
				707
			],
			[
				32,
				230
			]
		],
		[
			[
				92356,
				34156
			],
			[
				-207,
				195
			],
			[
				-317,
				100
			]
		],
		[
			[
				91691,
				33674
			],
			[
				54,
				-299
			],
			[
				98,
				-15
			],
			[
				18,
				-144
			],
			[
				209,
				-184
			]
		],
		[
			[
				53386,
				35450
			],
			[
				-6,
				669
			]
		],
		[
			[
				68280,
				34886
			],
			[
				141,
				275
			],
			[
				153,
				686
			],
			[
				31,
				248
			],
			[
				150,
				435
			]
		],
		[
			[
				67926,
				36742
			],
			[
				102,
				-20
			],
			[
				80,
				-127
			],
			[
				-62,
				-1063
			],
			[
				-561,
				110
			]
		],
		[
			[
				68422,
				43655
			],
			[
				684,
				-114
			]
		],
		[
			[
				69106,
				43541
			],
			[
				57,
				1059
			]
		],
		[
			[
				66688,
				50682
			],
			[
				738,
				-96
			]
		],
		[
			[
				68520,
				52570
			],
			[
				-85,
				-84
			],
			[
				-342,
				46
			]
		],
		[
			[
				63947,
				46069
			],
			[
				-2,
				-238
			],
			[
				42,
				-252
			]
		],
		[
			[
				58410,
				73452
			],
			[
				10,
				754
			]
		],
		[
			[
				58486,
				75027
			],
			[
				-941,
				59
			]
		],
		[
			[
				76299,
				50174
			],
			[
				184,
				-65
			],
			[
				41,
				-128
			]
		],
		[
			[
				76524,
				49981
			],
			[
				120,
				139
			],
			[
				82,
				3
			],
			[
				123,
				208
			],
			[
				123,
				129
			]
		],
		[
			[
				77009,
				50619
			],
			[
				-302,
				-13
			],
			[
				-52,
				246
			],
			[
				-112,
				-5
			]
		],
		[
			[
				76197,
				50621
			],
			[
				102,
				-447
			]
		],
		[
			[
				85605,
				63783
			],
			[
				-98,
				71
			],
			[
				-96,
				-103
			],
			[
				-66,
				-179
			]
		],
		[
			[
				85345,
				63572
			],
			[
				-21,
				-68
			]
		],
		[
			[
				70481,
				58532
			],
			[
				19,
				-354
			]
		],
		[
			[
				70500,
				58178
			],
			[
				230,
				-21
			]
		],
		[
			[
				70730,
				58157
			],
			[
				59,
				208
			],
			[
				162,
				-271
			],
			[
				257,
				22
			],
			[
				315,
				210
			]
		],
		[
			[
				71523,
				58326
			],
			[
				-3,
				206
			],
			[
				62,
				230
			],
			[
				-10,
				245
			],
			[
				-88,
				13
			],
			[
				-24,
				317
			]
		],
		[
			[
				71289,
				59379
			],
			[
				-473,
				-269
			],
			[
				-332,
				-158
			]
		],
		[
			[
				67986,
				28218
			],
			[
				24,
				311
			],
			[
				50,
				158
			],
			[
				8,
				338
			],
			[
				-115,
				543
			]
		],
		[
			[
				87032,
				42793
			],
			[
				105,
				102
			],
			[
				65,
				212
			]
		],
		[
			[
				87202,
				43107
			],
			[
				-114,
				24
			]
		],
		[
			[
				80856,
				61895
			],
			[
				183,
				173
			],
			[
				33,
				116
			],
			[
				86,
				-198
			],
			[
				110,
				-46
			]
		],
		[
			[
				81548,
				62860
			],
			[
				-26,
				170
			]
		],
		[
			[
				81323,
				63554
			],
			[
				-123,
				12
			],
			[
				-200,
				-198
			],
			[
				-186,
				-114
			],
			[
				-91,
				107
			],
			[
				-140,
				-15
			],
			[
				-3,
				172
			]
		],
		[
			[
				80580,
				63518
			],
			[
				-158,
				-223
			]
		],
		[
			[
				88507,
				39872
			],
			[
				-48,
				202
			],
			[
				117,
				149
			],
			[
				-63,
				70
			],
			[
				-165,
				387
			],
			[
				-57,
				-84
			],
			[
				22,
				-191
			],
			[
				-56,
				-74
			],
			[
				-19,
				330
			],
			[
				62,
				91
			],
			[
				-24,
				163
			],
			[
				-98,
				-318
			],
			[
				-72,
				24
			]
		],
		[
			[
				47935,
				22884
			],
			[
				97,
				356
			],
			[
				-73,
				239
			],
			[
				81,
				278
			]
		],
		[
			[
				48040,
				23757
			],
			[
				-38,
				334
			],
			[
				-79,
				222
			],
			[
				64,
				310
			],
			[
				-174,
				-42
			],
			[
				-57,
				138
			],
			[
				21,
				355
			]
		],
		[
			[
				46603,
				25550
			],
			[
				31,
				-944
			],
			[
				-574,
				-53
			],
			[
				27,
				-169
			],
			[
				45,
				-1326
			],
			[
				37,
				-329
			]
		],
		[
			[
				65708,
				60801
			],
			[
				-95,
				-172
			],
			[
				175,
				-209
			],
			[
				-9,
				-133
			],
			[
				-82,
				79
			],
			[
				-95,
				-142
			],
			[
				3,
				-186
			],
			[
				77,
				-88
			],
			[
				54,
				63
			],
			[
				110,
				-84
			],
			[
				52,
				-180
			],
			[
				133,
				-66
			],
			[
				-110,
				-143
			],
			[
				-7,
				-127
			],
			[
				88,
				-67
			],
			[
				71,
				71
			],
			[
				55,
				-78
			]
		],
		[
			[
				45204,
				26314
			],
			[
				161,
				-76
			],
			[
				151,
				44
			],
			[
				102,
				-141
			],
			[
				132,
				-56
			],
			[
				214,
				82
			],
			[
				134,
				-247
			],
			[
				157,
				-175
			],
			[
				203,
				-5
			],
			[
				145,
				-190
			]
		],
		[
			[
				46676,
				27778
			],
			[
				-20,
				664
			],
			[
				-28,
				-2
			]
		],
		[
			[
				46628,
				28440
			],
			[
				-1508,
				-149
			]
		],
		[
			[
				77675,
				45866
			],
			[
				128,
				-13
			]
		],
		[
			[
				77803,
				45853
			],
			[
				9,
				253
			],
			[
				171,
				-16
			],
			[
				48,
				339
			],
			[
				204,
				-25
			],
			[
				17,
				350
			],
			[
				129,
				-22
			]
		],
		[
			[
				78381,
				46732
			],
			[
				-20,
				525
			],
			[
				-136,
				72
			],
			[
				-167,
				153
			]
		],
		[
			[
				77907,
				47475
			],
			[
				-45,
				-182
			],
			[
				-105,
				-108
			]
		],
		[
			[
				64014,
				57262
			],
			[
				-608,
				59
			]
		],
		[
			[
				63406,
				57321
			],
			[
				-19,
				-1258
			]
		],
		[
			[
				83857,
				40737
			],
			[
				-206,
				65
			]
		],
		[
			[
				82878,
				41044
			],
			[
				-25,
				-126
			],
			[
				31,
				-264
			],
			[
				-100,
				-100
			],
			[
				14,
				-188
			],
			[
				120,
				-584
			]
		],
		[
			[
				57904,
				84345
			],
			[
				-433,
				347
			],
			[
				-192,
				204
			],
			[
				-70,
				179
			],
			[
				-93,
				32
			],
			[
				23,
				-123
			],
			[
				181,
				-323
			],
			[
				151,
				-62
			],
			[
				53,
				-154
			],
			[
				67,
				53
			],
			[
				40,
				-97
			]
		],
		[
			[
				56484,
				86030
			],
			[
				198,
				-238
			],
			[
				240,
				-392
			],
			[
				106,
				-79
			],
			[
				26,
				-182
			],
			[
				156,
				79
			],
			[
				-140,
				204
			],
			[
				-431,
				510
			],
			[
				-180,
				271
			]
		],
		[
			[
				56645,
				84378
			],
			[
				72,
				165
			],
			[
				141,
				52
			],
			[
				-40,
				178
			],
			[
				78,
				63
			],
			[
				-3,
				479
			],
			[
				-114,
				71
			],
			[
				-205,
				336
			]
		],
		[
			[
				61760,
				61833
			],
			[
				12,
				1143
			]
		],
		[
			[
				61774,
				63185
			],
			[
				-192,
				5
			],
			[
				3,
				335
			],
			[
				-344,
				7
			],
			[
				-54,
				112
			],
			[
				-64,
				-179
			],
			[
				-111,
				-2
			]
		],
		[
			[
				54865,
				39593
			],
			[
				132,
				40
			],
			[
				-62,
				78
			]
		],
		[
			[
				54203,
				40154
			],
			[
				-34,
				-145
			],
			[
				48,
				-126
			],
			[
				-26,
				-288
			]
		],
		[
			[
				75593,
				48902
			],
			[
				193,
				-450
			]
		],
		[
			[
				76461,
				49260
			],
			[
				-134,
				-1
			],
			[
				4,
				183
			],
			[
				-140,
				-34
			],
			[
				-165,
				152
			]
		],
		[
			[
				76026,
				49560
			],
			[
				-130,
				-292
			],
			[
				-120,
				-189
			],
			[
				-168,
				-50
			],
			[
				-15,
				-127
			]
		],
		[
			[
				76198,
				54626
			],
			[
				103,
				-260
			],
			[
				25,
				-210
			],
			[
				120,
				-104
			],
			[
				46,
				-142
			],
			[
				164,
				-232
			],
			[
				-30,
				-192
			]
		],
		[
			[
				76819,
				53677
			],
			[
				28,
				174
			],
			[
				-40,
				172
			],
			[
				52,
				81
			],
			[
				-11,
				198
			],
			[
				83,
				121
			],
			[
				7,
				125
			]
		],
		[
			[
				76938,
				54548
			],
			[
				-125,
				27
			],
			[
				-192,
				208
			],
			[
				-42,
				95
			]
		],
		[
			[
				76579,
				54878
			],
			[
				-21,
				75
			],
			[
				-425,
				74
			]
		],
		[
			[
				83334,
				57419
			],
			[
				-206,
				64
			]
		],
		[
			[
				83128,
				57483
			],
			[
				-73,
				-276
			],
			[
				-166,
				-174
			],
			[
				-95,
				-157
			],
			[
				-11,
				-133
			],
			[
				-101,
				27
			],
			[
				-133,
				-54
			]
		],
		[
			[
				82549,
				56716
			],
			[
				-50,
				-180
			],
			[
				10,
				-140
			],
			[
				123,
				-94
			],
			[
				-47,
				-178
			],
			[
				66,
				8
			],
			[
				-9,
				-159
			],
			[
				-65,
				-166
			]
		],
		[
			[
				96258,
				28794
			],
			[
				51,
				-253
			],
			[
				-90,
				-352
			],
			[
				8,
				-150
			]
		],
		[
			[
				96227,
				28039
			],
			[
				-10,
				-173
			],
			[
				123,
				-249
			]
		],
		[
			[
				96340,
				27617
			],
			[
				135,
				134
			],
			[
				167,
				12
			],
			[
				137,
				-47
			],
			[
				63,
				51
			],
			[
				89,
				-194
			],
			[
				240,
				-257
			],
			[
				21,
				-94
			],
			[
				-116,
				-427
			],
			[
				-58,
				136
			],
			[
				-51,
				-309
			],
			[
				-57,
				-162
			],
			[
				-102,
				-68
			],
			[
				-59,
				119
			],
			[
				-96,
				-52
			],
			[
				78,
				-111
			],
			[
				233,
				56
			],
			[
				170,
				282
			],
			[
				165,
				481
			],
			[
				84,
				430
			],
			[
				-35,
				503
			],
			[
				-17,
				-326
			],
			[
				-73,
				-38
			],
			[
				-266,
				234
			],
			[
				-77,
				104
			],
			[
				-152,
				41
			],
			[
				-140,
				190
			],
			[
				-58,
				240
			],
			[
				-216,
				156
			],
			[
				-43,
				120
			]
		],
		[
			[
				80051,
				59554
			],
			[
				171,
				-31
			]
		],
		[
			[
				80222,
				59523
			],
			[
				194,
				529
			],
			[
				105,
				374
			],
			[
				84,
				57
			]
		],
		[
			[
				80605,
				60483
			],
			[
				-103,
				321
			],
			[
				-46,
				957
			]
		],
		[
			[
				79865,
				61167
			],
			[
				-165,
				-212
			],
			[
				-94,
				-1323
			]
		],
		[
			[
				38865,
				25197
			],
			[
				-320,
				-51
			],
			[
				-1297,
				-238
			],
			[
				-530,
				-110
			]
		],
		[
			[
				88363,
				51100
			],
			[
				64,
				-338
			],
			[
				-3,
				-251
			],
			[
				34,
				-260
			],
			[
				-93,
				-130
			],
			[
				-2,
				-157
			]
		],
		[
			[
				88363,
				49964
			],
			[
				234,
				-330
			],
			[
				8,
				-330
			]
		],
		[
			[
				88605,
				49304
			],
			[
				39,
				293
			],
			[
				54,
				92
			],
			[
				106,
				-9
			],
			[
				207,
				228
			],
			[
				16,
				87
			]
		],
		[
			[
				88439,
				51324
			],
			[
				-23,
				-8
			]
		],
		[
			[
				33374,
				32951
			],
			[
				1088,
				251
			],
			[
				1390,
				307
			]
		],
		[
			[
				35852,
				33509
			],
			[
				-47,
				665
			],
			[
				31,
				6
			],
			[
				-127,
				1643
			],
			[
				-690,
				-154
			],
			[
				-74,
				997
			],
			[
				21,
				5
			],
			[
				-114,
				1515
			]
		],
		[
			[
				32975,
				37767
			],
			[
				-1575,
				-373
			]
		],
		[
			[
				22500,
				13386
			],
			[
				-64,
				288
			],
			[
				82,
				238
			],
			[
				33,
				488
			],
			[
				-89,
				247
			],
			[
				15,
				411
			]
		],
		[
			[
				68517,
				61061
			],
			[
				-368,
				45
			],
			[
				-130,
				100
			],
			[
				-78,
				207
			],
			[
				-44,
				248
			],
			[
				-112,
				14
			]
		],
		[
			[
				67785,
				61675
			],
			[
				-34,
				-311
			],
			[
				-98,
				-201
			],
			[
				-7,
				-167
			]
		],
		[
			[
				68554,
				62547
			],
			[
				-735,
				100
			]
		],
		[
			[
				67819,
				62647
			],
			[
				-34,
				-972
			]
		],
		[
			[
				61137,
				33781
			],
			[
				775,
				-65
			]
		],
		[
			[
				61580,
				35069
			],
			[
				-383,
				35
			]
		],
		[
			[
				59353,
				71005
			],
			[
				56,
				-2
			]
		],
		[
			[
				59666,
				73331
			],
			[
				-128,
				5
			],
			[
				-28,
				-1347
			],
			[
				-57,
				-100
			],
			[
				36,
				-132
			],
			[
				-56,
				-404
			],
			[
				-95,
				-181
			],
			[
				15,
				-167
			]
		],
		[
			[
				72099,
				56213
			],
			[
				5,
				-592
			]
		],
		[
			[
				87446,
				60749
			],
			[
				63,
				-42
			],
			[
				39,
				-193
			],
			[
				56,
				79
			],
			[
				189,
				-185
			],
			[
				33,
				110
			],
			[
				162,
				207
			],
			[
				39,
				-15
			]
		],
		[
			[
				88027,
				60710
			],
			[
				-148,
				560
			],
			[
				-48,
				387
			],
			[
				-47,
				562
			]
		],
		[
			[
				46628,
				28440
			],
			[
				-19,
				590
			]
		],
		[
			[
				46609,
				29030
			],
			[
				-96,
				-22
			],
			[
				-173,
				197
			],
			[
				-49,
				1533
			]
		],
		[
			[
				46291,
				30738
			],
			[
				-1424,
				-149
			]
		],
		[
			[
				44867,
				30589
			],
			[
				12,
				-333
			],
			[
				-45,
				-4
			],
			[
				32,
				-860
			]
		],
		[
			[
				72338,
				34504
			],
			[
				764,
				-154
			]
		],
		[
			[
				73187,
				35544
			],
			[
				-611,
				122
			]
		],
		[
			[
				72419,
				35696
			],
			[
				-81,
				-1192
			]
		],
		[
			[
				75492,
				60226
			],
			[
				263,
				146
			],
			[
				16,
				100
			],
			[
				107,
				-21
			]
		],
		[
			[
				75067,
				61469
			],
			[
				48,
				-373
			],
			[
				-23,
				-13
			],
			[
				63,
				-343
			],
			[
				-25,
				-267
			]
		],
		[
			[
				41358,
				37844
			],
			[
				32,
				-657
			]
		],
		[
			[
				88094,
				49120
			],
			[
				75,
				98
			],
			[
				185,
				-47
			],
			[
				116,
				147
			],
			[
				57,
				-10
			],
			[
				28,
				-210
			],
			[
				50,
				206
			]
		],
		[
			[
				88363,
				49964
			],
			[
				-156,
				258
			]
		],
		[
			[
				59480,
				65692
			],
			[
				-9,
				-1112
			],
			[
				11,
				-224
			]
		],
		[
			[
				59059,
				46815
			],
			[
				129,
				121
			],
			[
				8,
				129
			],
			[
				112,
				-96
			],
			[
				-22,
				-181
			],
			[
				194,
				-128
			],
			[
				62,
				-149
			],
			[
				-17,
				-139
			],
			[
				166,
				62
			]
		],
		[
			[
				59691,
				46434
			],
			[
				57,
				182
			],
			[
				86,
				82
			],
			[
				109,
				-3
			],
			[
				4,
				113
			],
			[
				112,
				230
			],
			[
				80,
				-21
			]
		],
		[
			[
				60139,
				47017
			],
			[
				-156,
				469
			],
			[
				63,
				41
			],
			[
				-41,
				122
			]
		],
		[
			[
				44540,
				41933
			],
			[
				37,
				-991
			]
		],
		[
			[
				45761,
				41061
			],
			[
				-39,
				1339
			]
		],
		[
			[
				45722,
				42400
			],
			[
				-1195,
				-118
			]
		],
		[
			[
				44527,
				42282
			],
			[
				13,
				-349
			]
		],
		[
			[
				64122,
				17700
			],
			[
				143,
				-217
			],
			[
				177,
				-76
			],
			[
				85,
				45
			],
			[
				158,
				-106
			],
			[
				151,
				-26
			],
			[
				245,
				-256
			],
			[
				185,
				-369
			],
			[
				200,
				-37
			],
			[
				77,
				-143
			]
		],
		[
			[
				65555,
				18853
			],
			[
				15,
				328
			]
		],
		[
			[
				80890,
				58431
			],
			[
				125,
				226
			],
			[
				84,
				66
			],
			[
				25,
				204
			],
			[
				62,
				47
			],
			[
				22,
				372
			]
		],
		[
			[
				81208,
				59346
			],
			[
				-70,
				13
			]
		],
		[
			[
				81138,
				59359
			],
			[
				-724,
				129
			]
		],
		[
			[
				80414,
				59488
			],
			[
				1,
				-163
			],
			[
				56,
				-541
			],
			[
				-56,
				-854
			]
		],
		[
			[
				45722,
				42400
			],
			[
				32,
				3
			]
		],
		[
			[
				45754,
				42403
			],
			[
				-45,
				1339
			]
		],
		[
			[
				44477,
				43614
			],
			[
				50,
				-1332
			]
		],
		[
			[
				81191,
				34624
			],
			[
				107,
				-217
			],
			[
				123,
				-366
			],
			[
				355,
				-86
			],
			[
				-3,
				-26
			]
		],
		[
			[
				81937,
				33878
			],
			[
				62,
				604
			],
			[
				66,
				79
			],
			[
				21,
				203
			]
		],
		[
			[
				82086,
				34764
			],
			[
				19,
				193
			],
			[
				-201,
				116
			],
			[
				16,
				168
			],
			[
				-66,
				111
			],
			[
				15,
				136
			],
			[
				-57,
				193
			]
		],
		[
			[
				81812,
				35681
			],
			[
				-501,
				151
			]
		],
		[
			[
				77581,
				51134
			],
			[
				-145,
				22
			],
			[
				-60,
				-64
			],
			[
				-105,
				294
			],
			[
				34,
				195
			]
		],
		[
			[
				76588,
				52020
			],
			[
				-1,
				-323
			],
			[
				-104,
				-322
			]
		],
		[
			[
				52139,
				57297
			],
			[
				718,
				16
			]
		],
		[
			[
				52857,
				57313
			],
			[
				97,
				44
			],
			[
				161,
				-60
			],
			[
				16,
				184
			],
			[
				-166,
				83
			],
			[
				-25,
				85
			]
		],
		[
			[
				52940,
				57649
			],
			[
				-40,
				-1
			],
			[
				-4,
				670
			],
			[
				191,
				3
			],
			[
				-2,
				336
			]
		],
		[
			[
				52316,
				58978
			],
			[
				-190,
				-26
			]
		],
		[
			[
				71218,
				52844
			],
			[
				226,
				88
			],
			[
				40,
				154
			]
		],
		[
			[
				71641,
				53645
			],
			[
				-114,
				87
			],
			[
				-62,
				-136
			],
			[
				-75,
				69
			],
			[
				-144,
				5
			],
			[
				19,
				130
			],
			[
				-69,
				581
			]
		],
		[
			[
				71196,
				54381
			],
			[
				-313,
				-167
			],
			[
				-172,
				-21
			]
		],
		[
			[
				51612,
				21323
			],
			[
				45,
				3
			]
		],
		[
			[
				79808,
				62645
			],
			[
				-138,
				295
			],
			[
				70,
				236
			],
			[
				127,
				194
			],
			[
				32,
				213
			],
			[
				-31,
				220
			],
			[
				-95,
				196
			]
		],
		[
			[
				79773,
				63999
			],
			[
				-227,
				70
			],
			[
				-63,
				-65
			],
			[
				-82,
				56
			],
			[
				-98,
				249
			]
		],
		[
			[
				79303,
				64309
			],
			[
				-188,
				-412
			],
			[
				-79,
				-54
			],
			[
				-61,
				-212
			]
		],
		[
			[
				66568,
				56971
			],
			[
				81,
				-25
			],
			[
				827,
				-87
			]
		],
		[
			[
				67301,
				57965
			],
			[
				1,
				82
			],
			[
				-345,
				32
			]
		],
		[
			[
				66370,
				58118
			],
			[
				89,
				-191
			],
			[
				30,
				-362
			],
			[
				69,
				-229
			],
			[
				10,
				-365
			]
		],
		[
			[
				85349,
				42059
			],
			[
				401,
				387
			]
		],
		[
			[
				85750,
				42446
			],
			[
				-36,
				214
			],
			[
				-138,
				310
			]
		],
		[
			[
				85576,
				42970
			],
			[
				-59,
				151
			]
		],
		[
			[
				85245,
				42989
			],
			[
				48,
				-258
			],
			[
				12,
				-422
			],
			[
				44,
				-250
			]
		],
		[
			[
				83337,
				87950
			],
			[
				828,
				-207
			]
		],
		[
			[
				84165,
				87743
			],
			[
				16,
				171
			],
			[
				-117,
				298
			],
			[
				31,
				142
			],
			[
				129,
				128
			],
			[
				21,
				130
			],
			[
				98,
				100
			],
			[
				133,
				41
			],
			[
				39,
				210
			],
			[
				56,
				11
			],
			[
				24,
				162
			],
			[
				113,
				126
			]
		],
		[
			[
				57557,
				63693
			],
			[
				144,
				-113
			],
			[
				36,
				54
			],
			[
				213,
				-86
			],
			[
				0,
				-344
			],
			[
				-40,
				-29
			],
			[
				197,
				-138
			]
		],
		[
			[
				58107,
				63037
			],
			[
				0,
				170
			],
			[
				124,
				2
			],
			[
				0,
				106
			],
			[
				670,
				1
			]
		],
		[
			[
				58907,
				64374
			],
			[
				-129,
				109
			],
			[
				-161,
				28
			],
			[
				-12,
				183
			],
			[
				-95,
				2
			]
		],
		[
			[
				58510,
				64696
			],
			[
				0,
				-55
			],
			[
				-162,
				-42
			],
			[
				-545,
				-2
			],
			[
				0,
				-111
			],
			[
				-251,
				-3
			]
		],
		[
			[
				57552,
				64483
			],
			[
				5,
				-790
			]
		],
		[
			[
				72366,
				69596
			],
			[
				-4,
				-262
			],
			[
				34,
				-62
			]
		],
		[
			[
				72981,
				69164
			],
			[
				306,
				-56
			],
			[
				3,
				55
			]
		],
		[
			[
				73290,
				69163
			],
			[
				83,
				1282
			]
		],
		[
			[
				84639,
				86923
			],
			[
				705,
				-194
			],
			[
				23,
				-132
			],
			[
				84,
				-47
			]
		],
		[
			[
				85451,
				86550
			],
			[
				168,
				418
			],
			[
				189,
				651
			]
		],
		[
			[
				85808,
				87619
			],
			[
				-704,
				194
			]
		],
		[
			[
				85104,
				87813
			],
			[
				-192,
				52
			],
			[
				-31,
				-317
			],
			[
				-187,
				55
			]
		],
		[
			[
				85808,
				87619
			],
			[
				198,
				628
			],
			[
				150,
				407
			]
		],
		[
			[
				86156,
				88654
			],
			[
				-168,
				46
			],
			[
				19,
				217
			],
			[
				-774,
				218
			]
		],
		[
			[
				85233,
				89135
			],
			[
				-129,
				-1322
			]
		],
		[
			[
				57565,
				61948
			],
			[
				4,
				-29
			]
		],
		[
			[
				57569,
				61919
			],
			[
				15,
				-181
			],
			[
				52,
				-39
			],
			[
				89,
				121
			],
			[
				6,
				179
			],
			[
				85,
				80
			],
			[
				164,
				-42
			],
			[
				124,
				-155
			],
			[
				-44,
				-157
			],
			[
				151,
				-25
			]
		],
		[
			[
				58211,
				61700
			],
			[
				-4,
				885
			],
			[
				98,
				5
			]
		],
		[
			[
				58305,
				62590
			],
			[
				-1,
				282
			],
			[
				-196,
				-3
			],
			[
				-1,
				168
			]
		],
		[
			[
				57557,
				63693
			],
			[
				8,
				-1745
			]
		],
		[
			[
				80414,
				59488
			],
			[
				-192,
				35
			]
		],
		[
			[
				79461,
				58901
			],
			[
				-33,
				-276
			]
		],
		[
			[
				19165,
				35310
			],
			[
				-662,
				1818
			]
		],
		[
			[
				18503,
				37128
			],
			[
				-2778,
				-1134
			]
		],
		[
			[
				15725,
				35994
			],
			[
				-189,
				-77
			],
			[
				286,
				-1942
			],
			[
				42,
				16
			],
			[
				248,
				-1651
			]
		],
		[
			[
				92622,
				19974
			],
			[
				50,
				242
			],
			[
				-77,
				417
			],
			[
				49,
				125
			],
			[
				-82,
				438
			],
			[
				21,
				328
			]
		],
		[
			[
				83579,
				51332
			],
			[
				-10,
				731
			]
		],
		[
			[
				83569,
				52063
			],
			[
				-185,
				321
			],
			[
				-432,
				-40
			],
			[
				-6,
				163
			],
			[
				-53,
				-3
			]
		],
		[
			[
				82893,
				52504
			],
			[
				-62,
				-167
			],
			[
				-306,
				-31
			]
		],
		[
			[
				64772,
				32790
			],
			[
				-6,
				-168
			]
		],
		[
			[
				50804,
				80229
			],
			[
				174,
				-613
			],
			[
				263,
				-453
			]
		],
		[
			[
				51241,
				79163
			],
			[
				388,
				150
			],
			[
				588,
				467
			]
		],
		[
			[
				52217,
				79780
			],
			[
				84,
				66
			],
			[
				215,
				1071
			]
		],
		[
			[
				52516,
				80917
			],
			[
				-347,
				201
			]
		],
		[
			[
				52102,
				81053
			],
			[
				-272,
				-259
			],
			[
				-66,
				38
			],
			[
				-94,
				-105
			],
			[
				-344,
				261
			],
			[
				-142,
				-165
			],
			[
				-34,
				-284
			],
			[
				-167,
				-319
			],
			[
				-67,
				68
			],
			[
				-112,
				-59
			]
		],
		[
			[
				14506,
				38447
			],
			[
				117,
				-11
			],
			[
				319,
				-144
			],
			[
				480,
				-925
			]
		],
		[
			[
				15422,
				37367
			],
			[
				56,
				-86
			],
			[
				116,
				43
			]
		],
		[
			[
				15594,
				37324
			],
			[
				46,
				5
			],
			[
				177,
				-195
			],
			[
				-131,
				755
			],
			[
				-45,
				-20
			],
			[
				-72,
				488
			],
			[
				146,
				351
			],
			[
				14,
				125
			],
			[
				132,
				286
			],
			[
				101,
				501
			],
			[
				64,
				28
			],
			[
				-20,
				140
			]
		],
		[
			[
				16006,
				39788
			],
			[
				-295,
				-116
			],
			[
				-211,
				425
			],
			[
				-50,
				345
			],
			[
				189,
				76
			],
			[
				-55,
				323
			],
			[
				-189,
				1314
			],
			[
				-421,
				-179
			]
		],
		[
			[
				14974,
				41976
			],
			[
				-222,
				-572
			]
		],
		[
			[
				75575,
				73804
			],
			[
				91,
				-14
			],
			[
				-33,
				-564
			]
		],
		[
			[
				75633,
				73226
			],
			[
				473,
				-80
			]
		],
		[
			[
				76391,
				74244
			],
			[
				-199,
				-46
			],
			[
				-8,
				93
			],
			[
				-303,
				63
			]
		],
		[
			[
				77176,
				67309
			],
			[
				52,
				-109
			],
			[
				-1,
				-170
			],
			[
				149,
				-202
			],
			[
				112,
				-49
			],
			[
				-25,
				-146
			]
		],
		[
			[
				77762,
				66922
			],
			[
				55,
				1007
			]
		],
		[
			[
				54743,
				38376
			],
			[
				828,
				-1
			]
		],
		[
			[
				56146,
				39705
			],
			[
				-382,
				2
			]
		],
		[
			[
				54842,
				38822
			],
			[
				24,
				-249
			],
			[
				-137,
				-64
			],
			[
				14,
				-133
			]
		],
		[
			[
				53487,
				85560
			],
			[
				644,
				1092
			]
		],
		[
			[
				54131,
				86652
			],
			[
				-30,
				1098
			]
		],
		[
			[
				54101,
				87750
			],
			[
				-159,
				3
			],
			[
				10,
				-213
			],
			[
				-57,
				102
			],
			[
				-293,
				-14
			],
			[
				-17,
				-72
			],
			[
				-112,
				49
			]
		],
		[
			[
				53473,
				87605
			],
			[
				-108,
				-255
			],
			[
				-23,
				-278
			],
			[
				-56,
				-163
			],
			[
				-101,
				-96
			],
			[
				-76,
				-393
			],
			[
				-208,
				-163
			]
		],
		[
			[
				23895,
				28220
			],
			[
				-347,
				2912
			]
		],
		[
			[
				20344,
				29945
			],
			[
				828,
				-6306
			]
		],
		[
			[
				14487,
				6019
			],
			[
				74,
				-423
			],
			[
				-316,
				-158
			]
		],
		[
			[
				14245,
				5438
			],
			[
				113,
				-202
			],
			[
				201,
				-153
			],
			[
				162,
				-6
			],
			[
				61,
				-226
			],
			[
				265,
				-345
			],
			[
				51,
				22
			],
			[
				7,
				-324
			],
			[
				89,
				181
			],
			[
				-42,
				619
			],
			[
				-94,
				-54
			],
			[
				40,
				165
			],
			[
				-46,
				205
			],
			[
				-2,
				245
			],
			[
				-92,
				84
			],
			[
				-25,
				117
			],
			[
				67,
				85
			],
			[
				-77,
				125
			],
			[
				-63,
				230
			]
		],
		[
			[
				73735,
				77182
			],
			[
				63,
				1101
			],
			[
				369,
				-61
			]
		],
		[
			[
				74167,
				78222
			],
			[
				-34,
				160
			],
			[
				-85,
				117
			],
			[
				8,
				153
			],
			[
				78,
				142
			],
			[
				-4,
				141
			],
			[
				58,
				104
			],
			[
				-68,
				190
			],
			[
				-99,
				17
			],
			[
				-63,
				223
			]
		],
		[
			[
				73958,
				79469
			],
			[
				22,
				459
			]
		],
		[
			[
				73980,
				79928
			],
			[
				-188,
				-99
			],
			[
				-414,
				-156
			],
			[
				-192,
				-26
			]
		],
		[
			[
				76191,
				76418
			],
			[
				-364,
				60
			]
		],
		[
			[
				78453,
				71273
			],
			[
				-44,
				98
			],
			[
				175,
				235
			],
			[
				-64,
				297
			]
		],
		[
			[
				78520,
				71903
			],
			[
				-156,
				-69
			],
			[
				-31,
				68
			],
			[
				-323,
				68
			]
		],
		[
			[
				77566,
				76097
			],
			[
				9,
				136
			],
			[
				508,
				-96
			]
		],
		[
			[
				78083,
				76137
			],
			[
				95,
				1445
			]
		],
		[
			[
				78178,
				77582
			],
			[
				-505,
				58
			]
		],
		[
			[
				77673,
				77640
			],
			[
				-146,
				17
			]
		],
		[
			[
				77738,
				73250
			],
			[
				356,
				-81
			],
			[
				-14,
				-190
			]
		],
		[
			[
				78080,
				72979
			],
			[
				249,
				-30
			]
		],
		[
			[
				78365,
				73921
			],
			[
				-279,
				54
			],
			[
				8,
				108
			]
		],
		[
			[
				91041,
				35115
			],
			[
				17,
				143
			],
			[
				67,
				18
			]
		],
		[
			[
				44646,
				38925
			],
			[
				-25,
				838
			]
		],
		[
			[
				44620,
				39769
			],
			[
				-950,
				-109
			]
		],
		[
			[
				77417,
				65168
			],
			[
				122,
				59
			],
			[
				27,
				130
			],
			[
				134,
				91
			]
		],
		[
			[
				77445,
				66363
			],
			[
				-471,
				-468
			]
		],
		[
			[
				76974,
				65895
			],
			[
				-135,
				-133
			]
		],
		[
			[
				60139,
				47017
			],
			[
				92,
				-458
			],
			[
				144,
				76
			]
		],
		[
			[
				60375,
				46635
			],
			[
				480,
				252
			]
		],
		[
			[
				60855,
				46887
			],
			[
				-210,
				980
			],
			[
				14,
				106
			]
		],
		[
			[
				90820,
				33640
			],
			[
				272,
				198
			]
		],
		[
			[
				91092,
				33838
			],
			[
				-11,
				137
			]
		],
		[
			[
				91081,
				33975
			],
			[
				2,
				199
			],
			[
				73,
				-34
			],
			[
				0,
				248
			]
		],
		[
			[
				79275,
				71613
			],
			[
				90,
				-205
			],
			[
				-28,
				-45
			]
		],
		[
			[
				79337,
				71363
			],
			[
				49,
				-5
			],
			[
				219,
				-498
			]
		],
		[
			[
				79732,
				70875
			],
			[
				30,
				198
			],
			[
				92,
				300
			],
			[
				-16,
				115
			],
			[
				91,
				439
			],
			[
				118,
				185
			]
		],
		[
			[
				80047,
				72112
			],
			[
				-178,
				198
			]
		],
		[
			[
				79869,
				72310
			],
			[
				-183,
				-296
			],
			[
				-319,
				-208
			],
			[
				-92,
				-193
			]
		],
		[
			[
				76800,
				62135
			],
			[
				45,
				-41
			],
			[
				-5,
				-189
			],
			[
				77,
				-71
			]
		],
		[
			[
				76917,
				61834
			],
			[
				176,
				-69
			]
		],
		[
			[
				77093,
				61765
			],
			[
				107,
				121
			],
			[
				18,
				117
			],
			[
				-50,
				293
			],
			[
				117,
				389
			],
			[
				-78,
				209
			]
		],
		[
			[
				77207,
				62894
			],
			[
				-321,
				64
			]
		],
		[
			[
				76886,
				62958
			],
			[
				-51,
				-164
			],
			[
				-53,
				-503
			],
			[
				18,
				-156
			]
		],
		[
			[
				79835,
				67053
			],
			[
				-248,
				463
			],
			[
				27,
				194
			],
			[
				-155,
				12
			]
		],
		[
			[
				80172,
				70549
			],
			[
				-36,
				244
			],
			[
				20,
				287
			],
			[
				1,
				966
			]
		],
		[
			[
				80157,
				72046
			],
			[
				-110,
				66
			]
		],
		[
			[
				57769,
				76310
			],
			[
				586,
				108
			],
			[
				204,
				189
			]
		],
		[
			[
				58559,
				76607
			],
			[
				7,
				397
			],
			[
				-113,
				219
			],
			[
				43,
				176
			],
			[
				-43,
				262
			],
			[
				35,
				213
			],
			[
				-13,
				406
			]
		],
		[
			[
				55857,
				44178
			],
			[
				53,
				0
			]
		],
		[
			[
				56447,
				44564
			],
			[
				109,
				32
			],
			[
				-22,
				125
			],
			[
				114,
				137
			]
		],
		[
			[
				56648,
				44858
			],
			[
				-83,
				120
			],
			[
				23,
				117
			],
			[
				73,
				-54
			],
			[
				-24,
				179
			],
			[
				-122,
				-71
			],
			[
				-25,
				229
			],
			[
				-96,
				83
			],
			[
				-43,
				194
			]
		],
		[
			[
				76039,
				67982
			],
			[
				120,
				-21
			],
			[
				72,
				-261
			],
			[
				-16,
				-123
			]
		],
		[
			[
				76215,
				67577
			],
			[
				62,
				-13
			]
		],
		[
			[
				76518,
				68147
			],
			[
				-232,
				46
			],
			[
				-148,
				-43
			],
			[
				-77,
				113
			]
		],
		[
			[
				76061,
				68263
			],
			[
				-21,
				-140
			]
		],
		[
			[
				48559,
				62847
			],
			[
				142,
				289
			],
			[
				-14,
				373
			],
			[
				58,
				84
			],
			[
				-20,
				255
			],
			[
				106,
				143
			]
		],
		[
			[
				48058,
				64319
			],
			[
				-29,
				-171
			],
			[
				-64,
				-4
			],
			[
				-4,
				-678
			],
			[
				-286,
				-19
			],
			[
				8,
				-333
			]
		],
		[
			[
				51703,
				63991
			],
			[
				1338,
				34
			]
		],
		[
			[
				56358,
				69669
			],
			[
				59,
				-32
			],
			[
				342,
				46
			],
			[
				61,
				-41
			],
			[
				125,
				115
			]
		],
		[
			[
				52708,
				55745
			],
			[
				703,
				13
			]
		],
		[
			[
				53411,
				55758
			],
			[
				-8,
				837
			],
			[
				-125,
				54
			],
			[
				-21,
				93
			],
			[
				-98,
				-32
			],
			[
				-77,
				250
			],
			[
				-175,
				-63
			],
			[
				-59,
				66
			],
			[
				9,
				350
			]
		],
		[
			[
				52137,
				57297
			],
			[
				15,
				-1563
			]
		],
		[
			[
				19581,
				20744
			],
			[
				131,
				-4
			],
			[
				16,
				-108
			],
			[
				108,
				-71
			],
			[
				23,
				-104
			],
			[
				223,
				-248
			],
			[
				590,
				231
			],
			[
				19,
				121
			],
			[
				116,
				155
			],
			[
				-44,
				323
			],
			[
				414,
				161
			]
		],
		[
			[
				18461,
				29211
			],
			[
				148,
				-1043
			],
			[
				-31,
				-13
			],
			[
				330,
				-2391
			],
			[
				-20,
				-8
			],
			[
				228,
				-1624
			],
			[
				7,
				-98
			],
			[
				211,
				-1510
			],
			[
				142,
				-971
			]
		],
		[
			[
				58892,
				83128
			],
			[
				124,
				-486
			],
			[
				120,
				-237
			]
		],
		[
			[
				60540,
				82387
			],
			[
				498,
				-23
			]
		],
		[
			[
				61038,
				82364
			],
			[
				238,
				-18
			],
			[
				32,
				1728
			]
		],
		[
			[
				61308,
				84074
			],
			[
				-243,
				-123
			],
			[
				-406,
				-308
			],
			[
				-450,
				-213
			],
			[
				-230,
				-6
			],
			[
				-98,
				53
			],
			[
				-250,
				-14
			],
			[
				-515,
				148
			],
			[
				-184,
				182
			]
		],
		[
			[
				56290,
				67562
			],
			[
				-137,
				-90
			],
			[
				-41,
				214
			],
			[
				-103,
				98
			]
		],
		[
			[
				55175,
				67819
			],
			[
				-144,
				-4
			],
			[
				-71,
				-160
			],
			[
				-109,
				-124
			],
			[
				-1,
				-682
			],
			[
				-98,
				-122
			]
		],
		[
			[
				84011,
				48219
			],
			[
				54,
				-60
			],
			[
				129,
				101
			],
			[
				30,
				219
			],
			[
				150,
				50
			],
			[
				213,
				529
			],
			[
				50,
				-9
			]
		],
		[
			[
				84424,
				49555
			],
			[
				-101,
				170
			]
		],
		[
			[
				15582,
				3209
			],
			[
				67,
				-146
			]
		],
		[
			[
				15649,
				3063
			],
			[
				1158,
				549
			],
			[
				109,
				64
			],
			[
				724,
				338
			]
		],
		[
			[
				15307,
				4945
			],
			[
				124,
				-211
			],
			[
				110,
				-351
			],
			[
				134,
				-43
			],
			[
				24,
				-198
			],
			[
				-59,
				-108
			],
			[
				-76,
				-336
			],
			[
				42,
				-223
			],
			[
				-24,
				-173
			]
		],
		[
			[
				15582,
				3302
			],
			[
				0,
				-93
			]
		],
		[
			[
				63477,
				75686
			],
			[
				119,
				-58
			]
		],
		[
			[
				63919,
				76120
			],
			[
				22,
				1010
			]
		],
		[
			[
				64063,
				77218
			],
			[
				210,
				-125
			],
			[
				664,
				-64
			]
		],
		[
			[
				65098,
				77012
			],
			[
				46,
				1339
			]
		],
		[
			[
				65144,
				78351
			],
			[
				-37,
				4
			]
		],
		[
			[
				64614,
				78404
			],
			[
				-447,
				42
			]
		],
		[
			[
				79044,
				69445
			],
			[
				173,
				-70
			],
			[
				151,
				77
			],
			[
				-8,
				104
			],
			[
				249,
				127
			],
			[
				17,
				339
			],
			[
				53,
				31
			]
		],
		[
			[
				79337,
				71363
			],
			[
				-183,
				233
			],
			[
				-360,
				-986
			]
		],
		[
			[
				79815,
				68817
			],
			[
				180,
				189
			]
		],
		[
			[
				79995,
				69006
			],
			[
				-20,
				172
			],
			[
				-76,
				147
			],
			[
				76,
				134
			],
			[
				-218,
				316
			],
			[
				70,
				165
			],
			[
				-148,
				113
			]
		],
		[
			[
				77918,
				65862
			],
			[
				199,
				-185
			],
			[
				12,
				-110
			]
		],
		[
			[
				78129,
				65567
			],
			[
				315,
				172
			],
			[
				122,
				-94
			],
			[
				105,
				61
			]
		],
		[
			[
				78465,
				67070
			],
			[
				-158,
				-210
			],
			[
				-93,
				-231
			]
		],
		[
			[
				2703,
				97629
			],
			[
				93,
				9
			],
			[
				33,
				99
			],
			[
				-101,
				71
			],
			[
				-25,
				-179
			]
		],
		[
			[
				2214,
				97770
			],
			[
				118,
				120
			],
			[
				86,
				361
			],
			[
				-87,
				-256
			],
			[
				-117,
				-225
			]
		],
		[
			[
				1666,
				96970
			],
			[
				161,
				-15
			],
			[
				70,
				-133
			],
			[
				31,
				91
			],
			[
				-238,
				180
			],
			[
				-24,
				-123
			]
		],
		[
			[
				183,
				94948
			],
			[
				150,
				-16
			],
			[
				-12,
				216
			],
			[
				-138,
				-200
			]
		],
		[
			[
				3,
				93989
			],
			[
				97,
				-21
			],
			[
				195,
				194
			],
			[
				72,
				315
			],
			[
				-72,
				-107
			],
			[
				-66,
				48
			],
			[
				-104,
				-63
			],
			[
				-49,
				-294
			],
			[
				-73,
				-72
			]
		],
		[
			[
				8760,
				99025
			],
			[
				201,
				-102
			],
			[
				44,
				40
			],
			[
				207,
				-329
			],
			[
				81,
				99
			],
			[
				18,
				-87
			],
			[
				-104,
				-66
			],
			[
				-43,
				-110
			],
			[
				68,
				-138
			],
			[
				186,
				-32
			],
			[
				-15,
				119
			],
			[
				68,
				60
			],
			[
				68,
				-133
			],
			[
				63,
				124
			],
			[
				-181,
				237
			],
			[
				211,
				-121
			],
			[
				-7,
				104
			],
			[
				-91,
				97
			],
			[
				-133,
				39
			],
			[
				-116,
				210
			],
			[
				-179,
				-40
			],
			[
				-91,
				90
			],
			[
				-160,
				62
			],
			[
				-95,
				-123
			]
		],
		[
			[
				8131,
				99405
			],
			[
				170,
				-218
			],
			[
				19,
				-146
			],
			[
				90,
				-89
			],
			[
				116,
				59
			],
			[
				-30,
				-111
			],
			[
				66,
				-167
			],
			[
				169,
				-61
			],
			[
				90,
				94
			],
			[
				-45,
				168
			],
			[
				-220,
				115
			],
			[
				-117,
				211
			],
			[
				-308,
				145
			]
		],
		[
			[
				7690,
				99248
			],
			[
				115,
				-18
			],
			[
				13,
				144
			],
			[
				-120,
				-45
			],
			[
				-8,
				-81
			]
		],
		[
			[
				7260,
				99402
			],
			[
				111,
				-86
			],
			[
				-4,
				130
			],
			[
				-97,
				42
			],
			[
				-10,
				-86
			]
		],
		[
			[
				5678,
				99277
			],
			[
				107,
				94
			],
			[
				93,
				-16
			],
			[
				110,
				136
			],
			[
				185,
				96
			],
			[
				-217,
				-19
			],
			[
				-244,
				-168
			],
			[
				-34,
				-123
			]
		],
		[
			[
				5065,
				99087
			],
			[
				161,
				47
			],
			[
				356,
				-23
			],
			[
				-4,
				-141
			],
			[
				103,
				-89
			],
			[
				69,
				166
			],
			[
				-126,
				114
			],
			[
				38,
				114
			],
			[
				-177,
				44
			],
			[
				-154,
				-54
			],
			[
				-266,
				-178
			]
		],
		[
			[
				4678,
				98790
			],
			[
				94,
				108
			],
			[
				-57,
				122
			],
			[
				-56,
				-57
			],
			[
				19,
				-173
			]
		],
		[
			[
				4597,
				99098
			],
			[
				134,
				-43
			],
			[
				-3,
				92
			],
			[
				-110,
				44
			],
			[
				-21,
				-93
			]
		],
		[
			[
				4199,
				99135
			],
			[
				142,
				-179
			],
			[
				46,
				-182
			],
			[
				107,
				30
			],
			[
				-49,
				180
			],
			[
				139,
				41
			],
			[
				-33,
				164
			],
			[
				-83,
				-57
			],
			[
				-186,
				76
			],
			[
				-83,
				-73
			]
		],
		[
			[
				3893,
				98832
			],
			[
				254,
				11
			],
			[
				35,
				-137
			],
			[
				64,
				76
			],
			[
				-80,
				190
			],
			[
				-77,
				21
			],
			[
				-45,
				-103
			],
			[
				-151,
				-58
			]
		],
		[
			[
				3705,
				98464
			],
			[
				133,
				37
			],
			[
				37,
				197
			],
			[
				-113,
				200
			],
			[
				-52,
				-176
			],
			[
				69,
				19
			],
			[
				-74,
				-277
			]
		],
		[
			[
				22639,
				90272
			],
			[
				146,
				63
			],
			[
				112,
				110
			],
			[
				96,
				181
			]
		],
		[
			[
				22665,
				90779
			],
			[
				-90,
				-261
			],
			[
				-122,
				-123
			],
			[
				-27,
				93
			],
			[
				136,
				181
			],
			[
				38,
				204
			],
			[
				156,
				360
			],
			[
				128,
				533
			],
			[
				-132,
				-48
			],
			[
				-68,
				-147
			]
		],
		[
			[
				22099,
				90650
			],
			[
				-37,
				-265
			],
			[
				66,
				-31
			],
			[
				-47,
				-166
			],
			[
				211,
				-282
			]
		],
		[
			[
				63537,
				63084
			],
			[
				6,
				349
			],
			[
				65,
				-5
			],
			[
				35,
				169
			]
		],
		[
			[
				63756,
				64442
			],
			[
				16,
				673
			],
			[
				99,
				-3
			],
			[
				12,
				512
			],
			[
				-99,
				8
			]
		],
		[
			[
				63784,
				65632
			],
			[
				-96,
				-56
			],
			[
				-133,
				-363
			],
			[
				-80,
				32
			],
			[
				13,
				-153
			],
			[
				-173,
				-181
			],
			[
				14,
				-108
			],
			[
				-157,
				6
			]
		],
		[
			[
				63172,
				64809
			],
			[
				-2,
				-113
			],
			[
				-131,
				-52
			],
			[
				-5,
				-225
			],
			[
				132,
				-5
			],
			[
				-4,
				-779
			],
			[
				-12,
				-172
			]
		],
		[
			[
				54859,
				71806
			],
			[
				132,
				231
			],
			[
				127,
				6
			],
			[
				160,
				114
			],
			[
				95,
				131
			],
			[
				46,
				-35
			]
		],
		[
			[
				55419,
				72253
			],
			[
				76,
				128
			]
		],
		[
			[
				55495,
				72381
			],
			[
				2,
				796
			],
			[
				175,
				270
			],
			[
				99,
				207
			]
		],
		[
			[
				51716,
				62137
			],
			[
				0,
				-6
			]
		],
		[
			[
				89706,
				22836
			],
			[
				936,
				-616
			]
		],
		[
			[
				90642,
				22220
			],
			[
				-8,
				590
			],
			[
				-124,
				456
			],
			[
				1,
				262
			],
			[
				147,
				651
			]
		],
		[
			[
				89735,
				24295
			],
			[
				-268,
				-1301
			],
			[
				239,
				-158
			]
		],
		[
			[
				88320,
				29483
			],
			[
				-53,
				-449
			]
		],
		[
			[
				88267,
				29034
			],
			[
				170,
				-92
			],
			[
				51,
				-199
			],
			[
				107,
				-8
			],
			[
				136,
				-297
			],
			[
				113,
				-77
			],
			[
				240,
				-421
			],
			[
				119,
				-15
			],
			[
				90,
				-87
			]
		],
		[
			[
				89293,
				27838
			],
			[
				194,
				293
			],
			[
				316,
				150
			]
		],
		[
			[
				89803,
				28281
			],
			[
				27,
				12
			],
			[
				-100,
				623
			],
			[
				153,
				66
			]
		],
		[
			[
				89432,
				29763
			],
			[
				-507,
				848
			]
		],
		[
			[
				88925,
				30611
			],
			[
				-56,
				-65
			],
			[
				-129,
				91
			],
			[
				-72,
				-276
			],
			[
				-228,
				-152
			]
		],
		[
			[
				88440,
				30209
			],
			[
				-52,
				-149
			],
			[
				-68,
				-577
			]
		],
		[
			[
				61982,
				79194
			],
			[
				760,
				-52
			]
		],
		[
			[
				62742,
				79142
			],
			[
				18,
				141
			],
			[
				77,
				92
			],
			[
				75,
				315
			],
			[
				-31,
				537
			],
			[
				55,
				189
			],
			[
				-47,
				58
			]
		],
		[
			[
				62889,
				80474
			],
			[
				159,
				383
			],
			[
				-257,
				22
			],
			[
				-111,
				-80
			],
			[
				-130,
				95
			],
			[
				-78,
				-42
			],
			[
				-17,
				150
			]
		],
		[
			[
				62166,
				81290
			],
			[
				-69,
				-190
			],
			[
				-9,
				-334
			],
			[
				-130,
				10
			],
			[
				-5,
				-167
			],
			[
				-477,
				30
			]
		],
		[
			[
				53411,
				55758
			],
			[
				397,
				5
			]
		],
		[
			[
				54257,
				58995
			],
			[
				21,
				-245
			],
			[
				-125,
				-4
			],
			[
				-190,
				-231
			],
			[
				-43,
				-181
			],
			[
				-140,
				139
			],
			[
				-65,
				-137
			],
			[
				97,
				-176
			],
			[
				-39,
				-88
			],
			[
				-62,
				100
			],
			[
				-107,
				-200
			],
			[
				-133,
				-47
			],
			[
				9,
				-154
			],
			[
				-54,
				-281
			],
			[
				-57,
				-60
			],
			[
				-93,
				102
			],
			[
				-98,
				260
			],
			[
				-115,
				58
			],
			[
				-123,
				-201
			]
		],
		[
			[
				54770,
				62360
			],
			[
				-4,
				533
			]
		],
		[
			[
				54766,
				62893
			],
			[
				-54,
				38
			],
			[
				-136,
				354
			],
			[
				-7,
				1089
			]
		],
		[
			[
				84851,
				50304
			],
			[
				-65,
				738
			]
		],
		[
			[
				84786,
				51042
			],
			[
				-128,
				-69
			],
			[
				-44,
				89
			],
			[
				-170,
				-68
			]
		],
		[
			[
				84444,
				50994
			],
			[
				-72,
				-89
			],
			[
				-101,
				169
			],
			[
				-67,
				-231
			],
			[
				-78,
				-24
			],
			[
				-52,
				118
			],
			[
				-72,
				-59
			],
			[
				-113,
				167
			],
			[
				-53,
				156
			]
		],
		[
			[
				53024,
				66652
			],
			[
				483,
				8
			],
			[
				91,
				40
			],
			[
				64,
				184
			]
		],
		[
			[
				53662,
				66884
			],
			[
				69,
				194
			],
			[
				-66,
				221
			],
			[
				-77,
				-9
			],
			[
				87,
				173
			],
			[
				-22,
				267
			]
		],
		[
			[
				53653,
				67730
			],
			[
				-160,
				-57
			],
			[
				-29,
				228
			],
			[
				-131,
				97
			],
			[
				-59,
				-173
			],
			[
				-104,
				82
			],
			[
				-60,
				-81
			],
			[
				-29,
				-253
			],
			[
				-64,
				-81
			]
		],
		[
			[
				53017,
				67492
			],
			[
				1,
				-225
			],
			[
				-64,
				-2
			],
			[
				1,
				-223
			]
		],
		[
			[
				71527,
				63817
			],
			[
				175,
				-168
			],
			[
				86,
				131
			],
			[
				116,
				-145
			],
			[
				60,
				127
			]
		],
		[
			[
				70995,
				64821
			],
			[
				-69,
				-1430
			]
		],
		[
			[
				55815,
				78633
			],
			[
				-348,
				492
			]
		],
		[
			[
				55467,
				79125
			],
			[
				-40,
				15
			],
			[
				-81,
				-231
			],
			[
				88,
				-193
			],
			[
				-42,
				-161
			],
			[
				-206,
				-56
			]
		],
		[
			[
				54766,
				62893
			],
			[
				138,
				31
			],
			[
				67,
				-164
			],
			[
				140,
				-44
			],
			[
				68,
				78
			],
			[
				147,
				-122
			],
			[
				118,
				-158
			],
			[
				-14,
				108
			],
			[
				188,
				-174
			],
			[
				22,
				-134
			],
			[
				89,
				18
			]
		],
		[
			[
				55729,
				62332
			],
			[
				-5,
				522
			],
			[
				191,
				-1
			],
			[
				2,
				392
			]
		],
		[
			[
				55917,
				63245
			],
			[
				0,
				113
			],
			[
				-287,
				3
			],
			[
				-8,
				1342
			]
		],
		[
			[
				55729,
				62332
			],
			[
				110,
				-123
			],
			[
				83,
				132
			]
		],
		[
			[
				55922,
				62341
			],
			[
				59,
				-38
			],
			[
				90,
				129
			],
			[
				72,
				-12
			],
			[
				93,
				-149
			],
			[
				17,
				-229
			],
			[
				67,
				-12
			],
			[
				131,
				-336
			]
		],
		[
			[
				56451,
				61694
			],
			[
				79,
				169
			],
			[
				190,
				61
			],
			[
				1,
				197
			],
			[
				102,
				-95
			],
			[
				58,
				182
			]
		],
		[
			[
				56881,
				62208
			],
			[
				2,
				467
			],
			[
				-205,
				3
			],
			[
				3,
				556
			]
		],
		[
			[
				56681,
				63234
			],
			[
				-764,
				11
			]
		],
		[
			[
				63553,
				68641
			],
			[
				175,
				-142
			],
			[
				-13,
				-119
			],
			[
				-172,
				-186
			],
			[
				122,
				-124
			],
			[
				129,
				177
			],
			[
				108,
				-67
			],
			[
				-67,
				-161
			],
			[
				-90,
				51
			],
			[
				-52,
				-78
			],
			[
				-1,
				-182
			],
			[
				282,
				-66
			],
			[
				-103,
				-100
			],
			[
				-58,
				-202
			],
			[
				103,
				-236
			],
			[
				-147,
				-179
			],
			[
				123,
				-119
			],
			[
				39,
				139
			],
			[
				69,
				-59
			],
			[
				-53,
				-146
			],
			[
				181,
				-58
			],
			[
				27,
				-206
			],
			[
				-159,
				-126
			]
		],
		[
			[
				63996,
				66452
			],
			[
				-2,
				-4
			]
		],
		[
			[
				63994,
				66448
			],
			[
				544,
				-46
			],
			[
				21,
				504
			]
		],
		[
			[
				64559,
				66906
			],
			[
				44,
				1428
			],
			[
				-194,
				19
			],
			[
				11,
				334
			]
		],
		[
			[
				64093,
				74166
			],
			[
				128,
				23
			],
			[
				88,
				87
			],
			[
				13,
				-198
			],
			[
				49,
				62
			],
			[
				198,
				-170
			],
			[
				-31,
				-77
			],
			[
				109,
				-204
			]
		],
		[
			[
				63711,
				75140
			],
			[
				122,
				-196
			],
			[
				-20,
				-164
			],
			[
				206,
				-232
			],
			[
				-166,
				-151
			],
			[
				60,
				-70
			],
			[
				59,
				103
			],
			[
				121,
				-264
			]
		],
		[
			[
				67577,
				67529
			],
			[
				-2,
				-56
			],
			[
				189,
				-25
			],
			[
				-9,
				-223
			],
			[
				389,
				-46
			]
		],
		[
			[
				68286,
				68338
			],
			[
				-10,
				-226
			],
			[
				-640,
				94
			]
		],
		[
			[
				62351,
				63073
			],
			[
				189,
				-138
			],
			[
				104,
				127
			],
			[
				101,
				0
			],
			[
				-5,
				-255
			],
			[
				216,
				-10
			]
		],
		[
			[
				63172,
				64809
			],
			[
				-396,
				15
			],
			[
				7,
				337
			],
			[
				-150,
				6
			]
		],
		[
			[
				64610,
				64379
			],
			[
				72,
				276
			],
			[
				-31,
				169
			]
		],
		[
			[
				64651,
				64824
			],
			[
				-33,
				158
			],
			[
				51,
				190
			],
			[
				-24,
				94
			],
			[
				-138,
				148
			],
			[
				8,
				186
			],
			[
				-65,
				25
			],
			[
				12,
				-180
			],
			[
				-143,
				29
			],
			[
				46,
				221
			],
			[
				-41,
				104
			],
			[
				-122,
				61
			],
			[
				-20,
				238
			],
			[
				-111,
				-133
			],
			[
				-46,
				115
			],
			[
				86,
				119
			],
			[
				131,
				-16
			],
			[
				-31,
				177
			],
			[
				-138,
				-94
			],
			[
				-79,
				182
			]
		],
		[
			[
				63996,
				66452
			],
			[
				-299,
				27
			]
		],
		[
			[
				63697,
				66479
			],
			[
				69,
				-102
			],
			[
				-32,
				-124
			],
			[
				57,
				-159
			],
			[
				-38,
				-125
			],
			[
				31,
				-337
			]
		],
		[
			[
				14000,
				92668
			],
			[
				269,
				10
			],
			[
				-8,
				383
			],
			[
				-385,
				-28
			]
		],
		[
			[
				13876,
				93033
			],
			[
				91,
				-130
			],
			[
				33,
				-235
			]
		],
		[
			[
				55495,
				72381
			],
			[
				236,
				311
			],
			[
				175,
				29
			],
			[
				55,
				-45
			],
			[
				203,
				184
			],
			[
				63,
				-44
			],
			[
				44,
				114
			],
			[
				51,
				-109
			]
		],
		[
			[
				56635,
				72942
			],
			[
				7,
				640
			]
		],
		[
			[
				63091,
				75142
			],
			[
				117,
				-192
			],
			[
				-35,
				-110
			],
			[
				53,
				-125
			],
			[
				-25,
				-112
			],
			[
				30,
				-279
			],
			[
				-35,
				-202
			],
			[
				18,
				-223
			]
		],
		[
			[
				63906,
				73810
			],
			[
				-74,
				61
			],
			[
				10,
				247
			],
			[
				207,
				14
			]
		],
		[
			[
				64092,
				74142
			],
			[
				1,
				24
			]
		],
		[
			[
				63164,
				75639
			],
			[
				-67,
				-164
			],
			[
				-6,
				-333
			]
		],
		[
			[
				72252,
				61461
			],
			[
				30,
				521
			]
		],
		[
			[
				72282,
				61982
			],
			[
				-840,
				141
			]
		],
		[
			[
				87499,
				28920
			],
			[
				106,
				574
			],
			[
				45,
				26
			],
			[
				291,
				-106
			],
			[
				27,
				198
			],
			[
				352,
				-129
			]
		],
		[
			[
				88440,
				30209
			],
			[
				-130,
				49
			]
		],
		[
			[
				87306,
				30630
			],
			[
				-104,
				-697
			],
			[
				36,
				-189
			],
			[
				-163,
				-648
			]
		],
		[
			[
				80556,
				39083
			],
			[
				185,
				-70
			]
		],
		[
			[
				80545,
				39904
			],
			[
				72,
				-317
			],
			[
				17,
				-223
			],
			[
				-78,
				-281
			]
		],
		[
			[
				79642,
				40212
			],
			[
				577,
				-115
			]
		],
		[
			[
				80219,
				40097
			],
			[
				301,
				-75
			]
		],
		[
			[
				80514,
				40508
			],
			[
				5,
				465
			],
			[
				-85,
				65
			],
			[
				41,
				143
			],
			[
				-42,
				66
			]
		],
		[
			[
				80433,
				41247
			],
			[
				-705,
				122
			]
		],
		[
			[
				79728,
				41369
			],
			[
				-24,
				-320
			]
		],
		[
			[
				56881,
				62208
			],
			[
				114,
				-132
			],
			[
				121,
				116
			],
			[
				28,
				119
			],
			[
				133,
				-130
			],
			[
				186,
				96
			],
			[
				19,
				-272
			],
			[
				83,
				-57
			]
		],
		[
			[
				57552,
				64483
			],
			[
				-4,
				854
			]
		],
		[
			[
				56451,
				64694
			],
			[
				-4,
				-672
			],
			[
				112,
				-2
			],
			[
				-2,
				-336
			],
			[
				128,
				-2
			],
			[
				-4,
				-448
			]
		],
		[
			[
				78930,
				45439
			],
			[
				249,
				504
			],
			[
				-32,
				80
			],
			[
				81,
				111
			]
		],
		[
			[
				79228,
				46134
			],
			[
				-305,
				170
			],
			[
				-170,
				236
			],
			[
				60,
				505
			]
		],
		[
			[
				78813,
				47045
			],
			[
				-319,
				-377
			]
		],
		[
			[
				78494,
				46668
			],
			[
				72,
				-125
			],
			[
				-47,
				-325
			],
			[
				-88,
				-330
			],
			[
				109,
				-202
			],
			[
				-16,
				-220
			],
			[
				55,
				-249
			]
		],
		[
			[
				78066,
				60765
			],
			[
				-87,
				-284
			],
			[
				-168,
				-267
			],
			[
				-23,
				-306
			],
			[
				-102,
				57
			],
			[
				-72,
				-123
			],
			[
				-63,
				-243
			]
		],
		[
			[
				77551,
				59599
			],
			[
				-20,
				-247
			],
			[
				-51,
				-110
			],
			[
				9,
				-163
			],
			[
				61,
				-2
			],
			[
				125,
				-256
			],
			[
				96,
				13
			]
		],
		[
			[
				82115,
				49215
			],
			[
				66,
				160
			],
			[
				87,
				47
			]
		],
		[
			[
				82268,
				49422
			],
			[
				-162,
				295
			],
			[
				71,
				199
			],
			[
				-274,
				411
			]
		],
		[
			[
				81903,
				50327
			],
			[
				-83,
				-206
			],
			[
				-68,
				51
			],
			[
				-237,
				375
			],
			[
				-104,
				116
			],
			[
				-174,
				-171
			]
		],
		[
			[
				79228,
				46134
			],
			[
				42,
				-23
			],
			[
				107,
				197
			]
		],
		[
			[
				79377,
				46308
			],
			[
				-87,
				200
			],
			[
				95,
				417
			],
			[
				-281,
				343
			],
			[
				-1,
				285
			]
		],
		[
			[
				78860,
				47424
			],
			[
				-47,
				-379
			]
		],
		[
			[
				66124,
				28125
			],
			[
				-451,
				59
			]
		],
		[
			[
				64987,
				28268
			],
			[
				-40,
				-1009
			]
		],
		[
			[
				80006,
				48639
			],
			[
				137,
				35
			]
		],
		[
			[
				80143,
				48674
			],
			[
				55,
				-45
			],
			[
				99,
				102
			],
			[
				22,
				208
			],
			[
				387,
				-52
			],
			[
				42,
				146
			],
			[
				200,
				10
			]
		],
		[
			[
				80771,
				49992
			],
			[
				-74,
				-16
			],
			[
				-102,
				115
			],
			[
				-18,
				259
			]
		],
		[
			[
				80577,
				50350
			],
			[
				-155,
				-100
			],
			[
				-48,
				-187
			],
			[
				-299,
				-648
			],
			[
				-101,
				-2
			]
		],
		[
			[
				79974,
				49413
			],
			[
				-97,
				-170
			],
			[
				-48,
				-325
			],
			[
				90,
				-260
			],
			[
				87,
				-19
			]
		],
		[
			[
				79519,
				50042
			],
			[
				161,
				-202
			],
			[
				-126,
				-165
			],
			[
				260,
				-210
			]
		],
		[
			[
				79814,
				49465
			],
			[
				62,
				83
			],
			[
				98,
				-135
			]
		],
		[
			[
				80577,
				50350
			],
			[
				-130,
				234
			],
			[
				6,
				135
			]
		],
		[
			[
				80453,
				50719
			],
			[
				-165,
				-112
			],
			[
				-53,
				75
			],
			[
				-72,
				-122
			],
			[
				-103,
				63
			],
			[
				-136,
				-33
			],
			[
				-101,
				-91
			],
			[
				-121,
				143
			],
			[
				-179,
				-28
			],
			[
				-59,
				-123
			]
		],
		[
			[
				90510,
				32552
			],
			[
				-161,
				529
			]
		],
		[
			[
				90004,
				33904
			],
			[
				-416,
				-561
			]
		],
		[
			[
				14459,
				89984
			],
			[
				-26,
				1652
			],
			[
				-68,
				116
			],
			[
				-135,
				-9
			],
			[
				-69,
				110
			],
			[
				-43,
				232
			],
			[
				-137,
				-14
			],
			[
				-71,
				109
			],
			[
				37,
				512
			]
		],
		[
			[
				13947,
				92692
			],
			[
				-424,
				300
			],
			[
				-153,
				-250
			],
			[
				32,
				-281
			],
			[
				-120,
				168
			],
			[
				-47,
				221
			],
			[
				52,
				293
			],
			[
				-84,
				115
			],
			[
				-66,
				-54
			],
			[
				-111,
				-471
			],
			[
				-127,
				-245
			],
			[
				-81,
				191
			],
			[
				-51,
				-152
			],
			[
				-103,
				-107
			],
			[
				11,
				-156
			],
			[
				-305,
				261
			]
		],
		[
			[
				12370,
				92525
			],
			[
				14,
				-220
			],
			[
				64,
				11
			],
			[
				42,
				-341
			],
			[
				141,
				-92
			],
			[
				43,
				-349
			],
			[
				68,
				12
			],
			[
				14,
				-232
			],
			[
				89,
				12
			],
			[
				14,
				-230
			],
			[
				62,
				3
			],
			[
				45,
				-330
			],
			[
				66,
				8
			],
			[
				11,
				-232
			],
			[
				68,
				11
			],
			[
				43,
				-341
			],
			[
				139,
				-99
			],
			[
				37,
				-226
			],
			[
				402,
				45
			],
			[
				66,
				114
			],
			[
				74,
				-231
			],
			[
				133,
				12
			],
			[
				-2,
				118
			],
			[
				456,
				36
			]
		],
		[
			[
				12330,
				92853
			],
			[
				20,
				-148
			],
			[
				159,
				-124
			],
			[
				-98,
				298
			],
			[
				-81,
				-26
			]
		],
		[
			[
				12349,
				92543
			],
			[
				-112,
				96
			]
		],
		[
			[
				12237,
				92639
			],
			[
				-2,
				-119
			],
			[
				114,
				23
			]
		],
		[
			[
				17580,
				91326
			],
			[
				38,
				-191
			],
			[
				142,
				-287
			],
			[
				94,
				-372
			],
			[
				66,
				151
			],
			[
				-184,
				481
			],
			[
				47,
				59
			],
			[
				-203,
				159
			]
		],
		[
			[
				17539,
				90713
			],
			[
				44,
				-321
			],
			[
				78,
				-154
			],
			[
				-4,
				287
			],
			[
				-41,
				296
			],
			[
				-77,
				-108
			]
		],
		[
			[
				17264,
				89160
			],
			[
				467,
				-73
			],
			[
				92,
				-83
			],
			[
				-77,
				-1031
			],
			[
				200,
				-36
			],
			[
				-56,
				-821
			],
			[
				-47,
				-410
			]
		],
		[
			[
				17843,
				86706
			],
			[
				126,
				-90
			],
			[
				336,
				-74
			],
			[
				9,
				118
			],
			[
				202,
				-26
			],
			[
				505,
				-131
			],
			[
				58,
				229
			],
			[
				-45,
				162
			],
			[
				74,
				60
			],
			[
				-28,
				201
			],
			[
				112,
				5
			],
			[
				37,
				-165
			],
			[
				134,
				-4
			],
			[
				10,
				113
			],
			[
				138,
				78
			],
			[
				44,
				457
			],
			[
				58,
				33
			],
			[
				33,
				299
			],
			[
				298,
				-99
			]
		],
		[
			[
				19944,
				87872
			],
			[
				231,
				1989
			]
		],
		[
			[
				19130,
				90725
			],
			[
				-121,
				19
			],
			[
				-130,
				306
			],
			[
				42,
				-206
			],
			[
				138,
				-173
			],
			[
				-114,
				-134
			],
			[
				-235,
				-23
			],
			[
				60,
				-74
			],
			[
				-91,
				-38
			],
			[
				36,
				-191
			],
			[
				-147,
				213
			],
			[
				-200,
				-177
			],
			[
				-79,
				43
			],
			[
				77,
				-189
			],
			[
				-140,
				182
			],
			[
				18,
				139
			],
			[
				-225,
				153
			],
			[
				14,
				-275
			],
			[
				61,
				16
			],
			[
				238,
				-222
			],
			[
				-52,
				-153
			],
			[
				-98,
				142
			],
			[
				43,
				-170
			],
			[
				-167,
				120
			],
			[
				-82,
				-68
			],
			[
				187,
				-179
			],
			[
				-137,
				77
			],
			[
				-85,
				-183
			],
			[
				36,
				-225
			],
			[
				-119,
				271
			],
			[
				-49,
				-239
			],
			[
				-30,
				256
			],
			[
				-69,
				86
			],
			[
				-49,
				-100
			],
			[
				-80,
				202
			],
			[
				-155,
				53
			],
			[
				54,
				-382
			],
			[
				-81,
				19
			],
			[
				-55,
				417
			],
			[
				100,
				20
			],
			[
				-67,
				366
			],
			[
				85,
				-174
			],
			[
				66,
				194
			],
			[
				-57,
				288
			],
			[
				133,
				221
			],
			[
				-78,
				173
			],
			[
				-83,
				21
			],
			[
				-49,
				-271
			],
			[
				-26,
				268
			],
			[
				-94,
				14
			]
		],
		[
			[
				91081,
				33975
			],
			[
				98,
				-14
			],
			[
				107,
				-185
			],
			[
				55,
				51
			]
		],
		[
			[
				91341,
				33827
			],
			[
				-47,
				442
			]
		],
		[
			[
				91294,
				34269
			],
			[
				-22,
				127
			]
		],
		[
			[
				77551,
				59599
			],
			[
				-164,
				60
			],
			[
				-105,
				122
			],
			[
				-326,
				153
			]
		],
		[
			[
				55467,
				79125
			],
			[
				-70,
				143
			],
			[
				-118,
				-69
			],
			[
				-130,
				124
			],
			[
				-150,
				59
			]
		],
		[
			[
				54999,
				79382
			],
			[
				-585,
				157
			]
		],
		[
			[
				85756,
				44160
			],
			[
				50,
				-16
			],
			[
				110,
				260
			],
			[
				-14,
				144
			],
			[
				132,
				172
			],
			[
				185,
				400
			],
			[
				69,
				-79
			],
			[
				102,
				34
			]
		],
		[
			[
				86435,
				45224
			],
			[
				-140,
				76
			]
		],
		[
			[
				85321,
				44944
			],
			[
				111,
				-2
			],
			[
				103,
				-320
			],
			[
				221,
				-462
			]
		],
		[
			[
				85576,
				42970
			],
			[
				549,
				98
			]
		],
		[
			[
				86125,
				43068
			],
			[
				-55,
				452
			],
			[
				433,
				942
			]
		],
		[
			[
				85756,
				44160
			],
			[
				-147,
				-73
			],
			[
				-49,
				-255
			],
			[
				-140,
				-91
			],
			[
				-66,
				-113
			]
		],
		[
			[
				78381,
				46732
			],
			[
				113,
				-64
			]
		],
		[
			[
				81763,
				40349
			],
			[
				-9,
				99
			],
			[
				89,
				-5
			],
			[
				69,
				104
			],
			[
				8,
				207
			],
			[
				65,
				273
			],
			[
				-39,
				59
			],
			[
				54,
				221
			]
		],
		[
			[
				81151,
				41550
			],
			[
				-165,
				45
			]
		],
		[
			[
				80986,
				41595
			],
			[
				-87,
				-916
			]
		],
		[
			[
				80986,
				41595
			],
			[
				-527,
				150
			]
		],
		[
			[
				80459,
				41745
			],
			[
				-66,
				-109
			],
			[
				50,
				-191
			],
			[
				-10,
				-198
			]
		],
		[
			[
				70606,
				71961
			],
			[
				-381,
				61
			],
			[
				-8,
				-167
			],
			[
				-157,
				24
			]
		],
		[
			[
				70060,
				71879
			],
			[
				41,
				-319
			],
			[
				-54,
				-117
			],
			[
				52,
				-60
			],
			[
				-42,
				-228
			],
			[
				-69,
				-3
			],
			[
				42,
				-131
			],
			[
				-106,
				-19
			],
			[
				64,
				-151
			],
			[
				-30,
				-249
			],
			[
				42,
				-146
			],
			[
				74,
				-47
			],
			[
				48,
				-148
			],
			[
				-31,
				-141
			],
			[
				55,
				-116
			]
		],
		[
			[
				13269,
				7832
			],
			[
				311,
				153
			]
		],
		[
			[
				13580,
				7985
			],
			[
				-19,
				106
			],
			[
				1414,
				704
			]
		],
		[
			[
				14688,
				10150
			],
			[
				-1260,
				-631
			],
			[
				-190,
				-84
			]
		],
		[
			[
				13238,
				9435
			],
			[
				-208,
				-102
			]
		],
		[
			[
				13030,
				9333
			],
			[
				239,
				-1501
			]
		],
		[
			[
				69064,
				76216
			],
			[
				91,
				1224
			]
		],
		[
			[
				69155,
				77440
			],
			[
				33,
				442
			]
		],
		[
			[
				68410,
				77987
			],
			[
				-13,
				0
			],
			[
				-75,
				-1665
			]
		],
		[
			[
				67912,
				80966
			],
			[
				144,
				-14
			],
			[
				-40,
				127
			],
			[
				-104,
				-113
			]
		],
		[
			[
				67499,
				79446
			],
			[
				194,
				-23
			],
			[
				-5,
				-113
			],
			[
				680,
				-85
			]
		],
		[
			[
				68553,
				80359
			],
			[
				-6,
				59
			],
			[
				-204,
				-101
			],
			[
				-305,
				137
			],
			[
				-389,
				297
			],
			[
				15,
				-181
			],
			[
				-121,
				-74
			]
		],
		[
			[
				67543,
				80496
			],
			[
				-44,
				-1050
			]
		],
		[
			[
				80298,
				47482
			],
			[
				495,
				-725
			],
			[
				138,
				-287
			],
			[
				-119,
				-79
			]
		],
		[
			[
				80955,
				47930
			],
			[
				-139,
				-214
			],
			[
				-51,
				-173
			],
			[
				-154,
				14
			],
			[
				-21,
				110
			],
			[
				-292,
				-184
			]
		],
		[
			[
				80298,
				47483
			],
			[
				0,
				-1
			]
		],
		[
			[
				73605,
				41086
			],
			[
				69,
				1171
			]
		],
		[
			[
				72976,
				42116
			],
			[
				-66,
				-879
			]
		],
		[
			[
				36073,
				28755
			],
			[
				96,
				20
			]
		],
		[
			[
				35899,
				32850
			],
			[
				-47,
				659
			]
		],
		[
			[
				32133,
				27982
			],
			[
				177,
				-2063
			]
		],
		[
			[
				91341,
				33827
			],
			[
				40,
				-351
			]
		],
		[
			[
				91340,
				34290
			],
			[
				-46,
				-21
			]
		],
		[
			[
				55366,
				13447
			],
			[
				1,
				328
			]
		],
		[
			[
				60462,
				67903
			],
			[
				573,
				-4
			]
		],
		[
			[
				61035,
				67899
			],
			[
				2,
				253
			],
			[
				-111,
				287
			],
			[
				-138,
				54
			],
			[
				-20,
				108
			],
			[
				49,
				204
			],
			[
				-85,
				157
			],
			[
				-11,
				142
			],
			[
				93,
				43
			],
			[
				25,
				193
			],
			[
				96,
				-16
			],
			[
				128,
				130
			],
			[
				37,
				117
			]
		],
		[
			[
				53662,
				66884
			],
			[
				241,
				3
			],
			[
				1,
				-165
			],
			[
				85,
				0
			]
		],
		[
			[
				55021,
				67944
			],
			[
				-165,
				-178
			],
			[
				-11,
				98
			],
			[
				-102,
				-11
			],
			[
				-42,
				92
			],
			[
				-149,
				-29
			],
			[
				-139,
				128
			],
			[
				11,
				172
			],
			[
				-94,
				87
			],
			[
				-105,
				-87
			],
			[
				-121,
				318
			],
			[
				-67,
				-150
			]
		],
		[
			[
				54037,
				68384
			],
			[
				-80,
				-194
			],
			[
				-143,
				13
			],
			[
				-48,
				-187
			],
			[
				-85,
				6
			],
			[
				-105,
				-123
			],
			[
				77,
				-169
			]
		],
		[
			[
				90642,
				22220
			],
			[
				90,
				-55
			]
		],
		[
			[
				91189,
				23926
			],
			[
				167,
				1398
			]
		],
		[
			[
				55968,
				80753
			],
			[
				-461,
				-13
			],
			[
				-445,
				-462
			]
		],
		[
			[
				55062,
				80278
			],
			[
				-63,
				-896
			]
		],
		[
			[
				85101,
				44643
			],
			[
				28,
				-105
			],
			[
				-48,
				-185
			],
			[
				55,
				-240
			]
		],
		[
			[
				79234,
				42596
			],
			[
				195,
				-37
			],
			[
				-21,
				-239
			],
			[
				106,
				32
			],
			[
				41,
				102
			],
			[
				191,
				-13
			],
			[
				-4,
				-53
			]
		],
		[
			[
				79742,
				42388
			],
			[
				71,
				86
			],
			[
				356,
				-69
			],
			[
				8,
				123
			]
		],
		[
			[
				80067,
				42879
			],
			[
				-100,
				132
			],
			[
				-48,
				188
			],
			[
				-79,
				22
			],
			[
				-155,
				212
			]
		],
		[
			[
				79685,
				43433
			],
			[
				-151,
				-227
			],
			[
				-163,
				323
			],
			[
				3,
				277
			],
			[
				-173,
				12
			],
			[
				-19,
				193
			],
			[
				-54,
				61
			]
		],
		[
			[
				71899,
				74269
			],
			[
				19,
				296
			]
		],
		[
			[
				71918,
				74565
			],
			[
				-151,
				159
			],
			[
				-116,
				18
			],
			[
				0,
				158
			],
			[
				-142,
				158
			],
			[
				-34,
				479
			],
			[
				-66,
				250
			],
			[
				-156,
				241
			],
			[
				-102,
				338
			],
			[
				-116,
				239
			]
		],
		[
			[
				70387,
				76560
			],
			[
				-43,
				-98
			],
			[
				207,
				-411
			],
			[
				121,
				35
			],
			[
				56,
				-287
			],
			[
				-109,
				-127
			],
			[
				96,
				-148
			],
			[
				-32,
				-548
			],
			[
				96,
				-17
			],
			[
				-28,
				-446
			],
			[
				30,
				-61
			]
		],
		[
			[
				79352,
				50663
			],
			[
				-108,
				166
			],
			[
				120,
				127
			],
			[
				16,
				213
			],
			[
				118,
				161
			],
			[
				58,
				157
			],
			[
				113,
				-16
			],
			[
				103,
				152
			]
		],
		[
			[
				79772,
				51623
			],
			[
				-27,
				150
			],
			[
				-152,
				145
			],
			[
				-71,
				145
			]
		],
		[
			[
				79522,
				52063
			],
			[
				-179,
				134
			],
			[
				0,
				199
			],
			[
				-91,
				142
			],
			[
				-129,
				19
			]
		],
		[
			[
				79123,
				52557
			],
			[
				15,
				-80
			],
			[
				-223,
				-529
			],
			[
				-46,
				-230
			],
			[
				-119,
				-59
			]
		],
		[
			[
				78750,
				51659
			],
			[
				523,
				-1075
			]
		],
		[
			[
				84910,
				90175
			],
			[
				1472,
				-405
			],
			[
				118,
				-88
			]
		],
		[
			[
				86500,
				89682
			],
			[
				160,
				625
			],
			[
				70,
				853
			],
			[
				20,
				950
			]
		],
		[
			[
				85139,
				92515
			],
			[
				-229,
				-2340
			]
		],
		[
			[
				80189,
				68673
			],
			[
				49,
				-409
			],
			[
				-76,
				-467
			],
			[
				54,
				-668
			],
			[
				-76,
				-122
			]
		],
		[
			[
				80910,
				66890
			],
			[
				190,
				143
			]
		],
		[
			[
				81547,
				67512
			],
			[
				-369,
				624
			]
		],
		[
			[
				80513,
				68681
			],
			[
				-124,
				66
			],
			[
				-200,
				-74
			]
		],
		[
			[
				52304,
				77760
			],
			[
				-125,
				134
			],
			[
				-141,
				-295
			]
		],
		[
			[
				75131,
				70682
			],
			[
				169,
				345
			]
		],
		[
			[
				73990,
				70947
			],
			[
				-26,
				-388
			],
			[
				185,
				-155
			]
		],
		[
			[
				8461,
				39443
			],
			[
				182,
				5
			],
			[
				201,
				541
			],
			[
				136,
				172
			],
			[
				134,
				41
			],
			[
				86,
				353
			]
		],
		[
			[
				9200,
				40555
			],
			[
				-68,
				330
			],
			[
				61,
				169
			],
			[
				-126,
				163
			],
			[
				79,
				230
			],
			[
				-97,
				-98
			],
			[
				3,
				247
			],
			[
				-97,
				6
			],
			[
				-34,
				-148
			],
			[
				-127,
				-274
			],
			[
				-84,
				2
			],
			[
				-99,
				-280
			],
			[
				-35,
				-200
			],
			[
				-176,
				-229
			],
			[
				-83,
				117
			],
			[
				-55,
				-72
			],
			[
				153,
				-363
			],
			[
				52,
				-180
			],
			[
				-27,
				-292
			],
			[
				45,
				-16
			],
			[
				-24,
				-224
			]
		],
		[
			[
				86638,
				49778
			],
			[
				84,
				-28
			],
			[
				137,
				-330
			]
		],
		[
			[
				86859,
				49420
			],
			[
				63,
				-43
			],
			[
				110,
				140
			],
			[
				196,
				-55
			]
		],
		[
			[
				87081,
				50868
			],
			[
				-75,
				-40
			]
		],
		[
			[
				87006,
				50828
			],
			[
				-105,
				-42
			],
			[
				-116,
				-200
			],
			[
				-138,
				-37
			],
			[
				-84,
				55
			]
		],
		[
			[
				82869,
				66643
			],
			[
				12,
				-18
			]
		],
		[
			[
				84070,
				68878
			],
			[
				-90,
				177
			],
			[
				-65,
				-52
			],
			[
				-82,
				123
			],
			[
				-29,
				-99
			],
			[
				-94,
				76
			]
		],
		[
			[
				82985,
				68452
			],
			[
				-40,
				-50
			],
			[
				-141,
				-400
			],
			[
				-185,
				-216
			],
			[
				-104,
				-255
			],
			[
				-115,
				-174
			]
		],
		[
			[
				81524,
				78277
			],
			[
				413,
				-1005
			],
			[
				91,
				-136
			],
			[
				19,
				-149
			],
			[
				107,
				16
			],
			[
				108,
				-125
			],
			[
				3,
				120
			],
			[
				138,
				82
			],
			[
				40,
				-179
			],
			[
				155,
				182
			]
		],
		[
			[
				82598,
				77083
			],
			[
				71,
				89
			],
			[
				137,
				852
			]
		],
		[
			[
				82806,
				78024
			],
			[
				-109,
				31
			],
			[
				57,
				554
			],
			[
				-200,
				59
			],
			[
				-173,
				-71
			],
			[
				-103,
				75
			]
		],
		[
			[
				82278,
				78672
			],
			[
				-22,
				-262
			],
			[
				-704,
				194
			]
		],
		[
			[
				81552,
				78604
			],
			[
				-28,
				-327
			]
		],
		[
			[
				77358,
				35895
			],
			[
				151,
				-61
			],
			[
				91,
				-140
			],
			[
				282,
				-270
			],
			[
				75,
				17
			]
		],
		[
			[
				77957,
				35441
			],
			[
				46,
				592
			],
			[
				155,
				-37
			],
			[
				24,
				289
			]
		],
		[
			[
				78182,
				36285
			],
			[
				-156,
				40
			],
			[
				23,
				287
			],
			[
				-163,
				41
			],
			[
				18,
				242
			],
			[
				-158,
				38
			],
			[
				21,
				281
			]
		],
		[
			[
				77767,
				37214
			],
			[
				-273,
				59
			]
		],
		[
			[
				77494,
				37273
			],
			[
				-80,
				-828
			]
		],
		[
			[
				51855,
				11421
			],
			[
				1145,
				34
			]
		],
		[
			[
				53021,
				12776
			],
			[
				-1146,
				-36
			]
		],
		[
			[
				79012,
				78931
			],
			[
				230,
				-53
			]
		],
		[
			[
				79999,
				80387
			],
			[
				-764,
				184
			]
		],
		[
			[
				79235,
				80571
			],
			[
				-65,
				16
			],
			[
				-20,
				-249
			],
			[
				-34,
				8
			],
			[
				-104,
				-1415
			]
		],
		[
			[
				64072,
				41297
			],
			[
				-9,
				-334
			]
		],
		[
			[
				64831,
				40892
			],
			[
				8,
				333
			],
			[
				194,
				-16
			],
			[
				18,
				428
			]
		],
		[
			[
				65051,
				41637
			],
			[
				-77,
				307
			]
		],
		[
			[
				18513,
				84514
			],
			[
				-152,
				152
			],
			[
				-140,
				71
			],
			[
				-169,
				246
			],
			[
				-73,
				-42
			],
			[
				-152,
				234
			],
			[
				-134,
				98
			],
			[
				-121,
				23
			]
		],
		[
			[
				17572,
				85296
			],
			[
				-255,
				43
			],
			[
				-86,
				-100
			]
		],
		[
			[
				17231,
				85239
			],
			[
				-238,
				-361
			],
			[
				-41,
				-764
			],
			[
				362,
				-55
			],
			[
				67,
				-97
			],
			[
				125,
				-23
			],
			[
				230,
				-287
			],
			[
				68,
				51
			],
			[
				-12,
				220
			],
			[
				161,
				102
			],
			[
				0,
				149
			],
			[
				237,
				-193
			],
			[
				153,
				64
			],
			[
				58,
				-91
			],
			[
				122,
				7
			],
			[
				-43,
				406
			],
			[
				33,
				147
			]
		],
		[
			[
				70815,
				34786
			],
			[
				755,
				-128
			]
		],
		[
			[
				71570,
				34658
			],
			[
				70,
				1035
			],
			[
				-42,
				162
			]
		],
		[
			[
				71598,
				35855
			],
			[
				-447,
				75
			]
		],
		[
			[
				82268,
				49422
			],
			[
				143,
				82
			],
			[
				171,
				-300
			],
			[
				26,
				43
			]
		],
		[
			[
				82608,
				49247
			],
			[
				-4,
				92
			],
			[
				132,
				274
			],
			[
				-58,
				149
			],
			[
				-78,
				369
			]
		],
		[
			[
				82016,
				50718
			],
			[
				-113,
				-391
			]
		],
		[
			[
				83520,
				41816
			],
			[
				203,
				63
			],
			[
				199,
				-699
			],
			[
				-9,
				-124
			],
			[
				113,
				56
			],
			[
				-69,
				71
			],
			[
				230,
				188
			]
		],
		[
			[
				84187,
				41371
			],
			[
				-93,
				311
			],
			[
				-56,
				80
			],
			[
				-93,
				485
			],
			[
				-60,
				41
			],
			[
				-120,
				381
			]
		],
		[
			[
				83765,
				42669
			],
			[
				-291,
				-204
			],
			[
				-239,
				9
			]
		],
		[
			[
				83825,
				44518
			],
			[
				71,
				-370
			],
			[
				247,
				265
			]
		],
		[
			[
				84594,
				44893
			],
			[
				-45,
				386
			],
			[
				99,
				18
			],
			[
				35,
				112
			],
			[
				252,
				95
			]
		],
		[
			[
				84692,
				46145
			],
			[
				20,
				61
			],
			[
				-139,
				256
			]
		],
		[
			[
				84573,
				46462
			],
			[
				-276,
				-294
			],
			[
				-42,
				13
			],
			[
				-602,
				-498
			]
		],
		[
			[
				49857,
				77864
			],
			[
				-64,
				-231
			],
			[
				-95,
				-110
			],
			[
				25,
				-124
			],
			[
				-144,
				-11
			],
			[
				37,
				-154
			],
			[
				-77,
				-151
			],
			[
				-32,
				156
			],
			[
				-39,
				-82
			],
			[
				-150,
				-18
			],
			[
				-27,
				-112
			],
			[
				-179,
				7
			],
			[
				-54,
				-183
			]
		],
		[
			[
				28378,
				31545
			],
			[
				230,
				66
			],
			[
				-11,
				110
			],
			[
				141,
				41
			],
			[
				63,
				-604
			],
			[
				32,
				8
			]
		],
		[
			[
				28833,
				31166
			],
			[
				448,
				130
			]
		],
		[
			[
				29275,
				32919
			],
			[
				-979,
				-274
			]
		],
		[
			[
				11261,
				39039
			],
			[
				-197,
				-98
			]
		],
		[
			[
				43580,
				40627
			],
			[
				1005,
				114
			]
		],
		[
			[
				44540,
				41933
			],
			[
				-1032,
				-113
			]
		],
		[
			[
				80816,
				69670
			],
			[
				118,
				69
			],
			[
				2,
				139
			],
			[
				127,
				208
			],
			[
				-45,
				581
			]
		],
		[
			[
				81018,
				70667
			],
			[
				-108,
				-12
			]
		],
		[
			[
				80511,
				70601
			],
			[
				-51,
				-115
			],
			[
				97,
				-343
			],
			[
				46,
				-348
			],
			[
				114,
				-183
			],
			[
				99,
				58
			]
		],
		[
			[
				64076,
				35253
			],
			[
				172,
				126
			],
			[
				93,
				144
			],
			[
				26,
				320
			]
		],
		[
			[
				66876,
				69365
			],
			[
				254,
				-29
			],
			[
				26,
				678
			]
		],
		[
			[
				67156,
				70014
			],
			[
				25,
				680
			]
		],
		[
			[
				66424,
				70964
			],
			[
				-438,
				68
			]
		],
		[
			[
				65986,
				71032
			],
			[
				121,
				-218
			],
			[
				0,
				-124
			],
			[
				115,
				-395
			],
			[
				-7,
				-177
			],
			[
				54,
				-85
			],
			[
				23,
				-246
			],
			[
				47,
				-85
			]
		],
		[
			[
				67512,
				68333
			],
			[
				41,
				949
			]
		],
		[
			[
				67553,
				69282
			],
			[
				-39,
				5
			],
			[
				15,
				338
			],
			[
				-194,
				25
			],
			[
				13,
				339
			],
			[
				-192,
				25
			]
		],
		[
			[
				94994,
				30830
			],
			[
				25,
				-283
			],
			[
				79,
				270
			],
			[
				-104,
				13
			]
		],
		[
			[
				95040,
				28843
			],
			[
				45,
				236
			],
			[
				-66,
				66
			],
			[
				75,
				148
			],
			[
				34,
				232
			],
			[
				-46,
				423
			],
			[
				-221,
				142
			],
			[
				-138,
				179
			],
			[
				-206,
				192
			],
			[
				-13,
				-50
			]
		],
		[
			[
				80652,
				38104
			],
			[
				-78,
				-809
			]
		],
		[
			[
				80574,
				37295
			],
			[
				594,
				-184
			]
		],
		[
			[
				81168,
				37111
			],
			[
				85,
				683
			]
		],
		[
			[
				81253,
				37794
			],
			[
				29,
				230
			],
			[
				-57,
				32
			],
			[
				-252,
				587
			]
		],
		[
			[
				80711,
				38718
			],
			[
				-59,
				-614
			]
		],
		[
			[
				52825,
				21362
			],
			[
				1035,
				17
			]
		],
		[
			[
				53860,
				21379
			],
			[
				-32,
				439
			],
			[
				-109,
				279
			],
			[
				-284,
				359
			],
			[
				-40,
				174
			],
			[
				35,
				77
			]
		],
		[
			[
				53430,
				22707
			],
			[
				116,
				274
			],
			[
				44,
				222
			],
			[
				74,
				174
			],
			[
				104,
				15
			],
			[
				150,
				125
			],
			[
				80,
				189
			]
		],
		[
			[
				44867,
				30589
			],
			[
				-35,
				1000
			],
			[
				45,
				5
			],
			[
				-20,
				498
			]
		],
		[
			[
				44857,
				32092
			],
			[
				-1,
				0
			]
		],
		[
			[
				80209,
				33501
			],
			[
				1478,
				-430
			]
		],
		[
			[
				80340,
				34871
			],
			[
				-4,
				-42
			]
		],
		[
			[
				78827,
				72629
			],
			[
				448,
				-1016
			]
		],
		[
			[
				79869,
				72310
			],
			[
				-68,
				10
			],
			[
				-56,
				149
			],
			[
				-200,
				318
			]
		],
		[
			[
				78896,
				72816
			],
			[
				-69,
				-187
			]
		],
		[
			[
				79083,
				77479
			],
			[
				260,
				-29
			]
		],
		[
			[
				79350,
				78371
			],
			[
				-109,
				-122
			],
			[
				-56,
				-179
			],
			[
				-102,
				-591
			]
		],
		[
			[
				78578,
				76048
			],
			[
				343,
				-60
			]
		],
		[
			[
				79083,
				77479
			],
			[
				-164,
				17
			]
		],
		[
			[
				78919,
				77496
			],
			[
				-19,
				-78
			],
			[
				-152,
				-81
			],
			[
				-105,
				-223
			],
			[
				49,
				-63
			],
			[
				-1,
				-201
			],
			[
				166,
				-110
			],
			[
				-30,
				-171
			],
			[
				-144,
				-52
			],
			[
				8,
				-173
			],
			[
				-113,
				-296
			]
		],
		[
			[
				75775,
				69636
			],
			[
				241,
				-51
			],
			[
				108,
				-165
			]
		],
		[
			[
				76124,
				69420
			],
			[
				134,
				192
			],
			[
				38,
				-59
			],
			[
				69,
				184
			],
			[
				205,
				110
			]
		],
		[
			[
				76327,
				70616
			],
			[
				-253,
				206
			],
			[
				-98,
				-27
			]
		],
		[
			[
				75976,
				70795
			],
			[
				-103,
				83
			]
		],
		[
			[
				75873,
				70878
			],
			[
				39,
				-183
			],
			[
				-56,
				-66
			]
		],
		[
			[
				94259,
				30777
			],
			[
				16,
				-81
			],
			[
				139,
				-125
			],
			[
				-101,
				193
			],
			[
				-54,
				13
			]
		],
		[
			[
				92070,
				33032
			],
			[
				7,
				-96
			],
			[
				143,
				45
			],
			[
				59,
				-70
			],
			[
				237,
				7
			],
			[
				118,
				-144
			],
			[
				-7,
				-145
			],
			[
				160,
				-52
			],
			[
				436,
				-197
			],
			[
				216,
				-158
			],
			[
				218,
				-373
			],
			[
				151,
				-415
			],
			[
				179,
				-143
			],
			[
				-91,
				309
			],
			[
				118,
				134
			],
			[
				118,
				-14
			],
			[
				37,
				-101
			],
			[
				117,
				187
			],
			[
				200,
				-302
			],
			[
				-1,
				-93
			],
			[
				150,
				-89
			],
			[
				-1,
				98
			],
			[
				-344,
				472
			],
			[
				-398,
				593
			],
			[
				-548,
				653
			],
			[
				-416,
				554
			],
			[
				-232,
				238
			],
			[
				-154,
				105
			],
			[
				-39,
				-33
			],
			[
				-147,
				154
			]
		],
		[
			[
				69699,
				75112
			],
			[
				50,
				397
			],
			[
				86,
				27
			],
			[
				23,
				120
			],
			[
				149,
				77
			],
			[
				76,
				124
			],
			[
				3,
				258
			],
			[
				-53,
				198
			],
			[
				86,
				-36
			],
			[
				-8,
				225
			],
			[
				-96,
				40
			],
			[
				71,
				343
			],
			[
				-23,
				125
			]
		],
		[
			[
				70019,
				77133
			],
			[
				-91,
				84
			],
			[
				-584,
				84
			],
			[
				5,
				113
			],
			[
				-194,
				26
			]
		],
		[
			[
				82384,
				43563
			],
			[
				99,
				126
			],
			[
				-2,
				153
			],
			[
				493,
				97
			],
			[
				260,
				-37
			]
		],
		[
			[
				83234,
				43902
			],
			[
				16,
				23
			]
		],
		[
			[
				81770,
				45389
			],
			[
				55,
				-208
			],
			[
				188,
				-62
			],
			[
				12,
				-374
			],
			[
				-40,
				-209
			],
			[
				22,
				-203
			]
		],
		[
			[
				64540,
				69675
			],
			[
				298,
				-31
			],
			[
				-8,
				-225
			],
			[
				189,
				-20
			]
		],
		[
			[
				65019,
				69399
			],
			[
				7,
				239
			],
			[
				80,
				115
			],
			[
				79,
				-31
			],
			[
				63,
				-243
			]
		],
		[
			[
				65248,
				69479
			],
			[
				25,
				633
			],
			[
				-35,
				166
			],
			[
				-35,
				-111
			],
			[
				-113,
				126
			],
			[
				8,
				133
			],
			[
				95,
				63
			],
			[
				34,
				118
			]
		],
		[
			[
				58372,
				82016
			],
			[
				46,
				-171
			],
			[
				-53,
				-140
			]
		],
		[
			[
				74900,
				66064
			],
			[
				257,
				70
			]
		],
		[
			[
				74528,
				67625
			],
			[
				-34,
				-206
			]
		],
		[
			[
				74494,
				67419
			],
			[
				-104,
				-640
			]
		],
		[
			[
				23477,
				12962
			],
			[
				-256,
				-92
			],
			[
				-126,
				110
			],
			[
				-55,
				154
			],
			[
				-483,
				-181
			]
		],
		[
			[
				30728,
				25597
			],
			[
				-167,
				1829
			]
		],
		[
			[
				30039,
				26811
			],
			[
				111,
				-1140
			]
		],
		[
			[
				86967,
				52213
			],
			[
				121,
				-701
			],
			[
				-82,
				-684
			]
		],
		[
			[
				87484,
				51402
			],
			[
				-109,
				183
			],
			[
				159,
				11
			],
			[
				123,
				168
			],
			[
				25,
				175
			],
			[
				106,
				1
			]
		],
		[
			[
				40224,
				78765
			],
			[
				1196,
				-2168
			]
		],
		[
			[
				41420,
				76597
			],
			[
				143,
				99
			],
			[
				190,
				344
			],
			[
				117,
				-89
			]
		],
		[
			[
				42557,
				77826
			],
			[
				183,
				219
			],
			[
				81,
				-15
			],
			[
				306,
				247
			],
			[
				65,
				-58
			],
			[
				105,
				66
			],
			[
				216,
				319
			],
			[
				89,
				625
			],
			[
				9,
				202
			],
			[
				68,
				186
			]
		],
		[
			[
				42058,
				81740
			],
			[
				-1575,
				-2553
			]
		],
		[
			[
				40483,
				79187
			],
			[
				-259,
				-422
			]
		],
		[
			[
				56648,
				44858
			],
			[
				465,
				-7
			]
		],
		[
			[
				57125,
				45967
			],
			[
				-856,
				6
			]
		],
		[
			[
				57137,
				47397
			],
			[
				-104,
				10
			],
			[
				-44,
				-99
			],
			[
				-84,
				58
			],
			[
				-68,
				-120
			]
		],
		[
			[
				56837,
				47246
			],
			[
				-83,
				-34
			],
			[
				-40,
				-225
			],
			[
				-93,
				-121
			],
			[
				-14,
				-218
			],
			[
				31,
				-138
			],
			[
				-77,
				28
			],
			[
				-61,
				-128
			]
		],
		[
			[
				73845,
				44941
			],
			[
				321,
				-92
			],
			[
				476,
				-22
			]
		],
		[
			[
				74807,
				44875
			],
			[
				-90,
				204
			],
			[
				80,
				59
			],
			[
				-31,
				121
			],
			[
				7,
				591
			]
		],
		[
			[
				74773,
				45850
			],
			[
				-197,
				-99
			],
			[
				-61,
				-229
			],
			[
				-79,
				115
			]
		],
		[
			[
				40788,
				13162
			],
			[
				-19,
				328
			],
			[
				387,
				65
			],
			[
				-19,
				329
			],
			[
				582,
				92
			],
			[
				-18,
				328
			],
			[
				122,
				19
			],
			[
				-35,
				661
			],
			[
				141,
				22
			]
		],
		[
			[
				41929,
				15006
			],
			[
				-53,
				126
			],
			[
				90,
				131
			],
			[
				87,
				426
			],
			[
				-11,
				527
			],
			[
				-116,
				343
			],
			[
				-210,
				300
			],
			[
				-164,
				-26
			]
		],
		[
			[
				41552,
				16833
			],
			[
				-648,
				-105
			],
			[
				-52,
				-229
			],
			[
				-194,
				-32
			],
			[
				13,
				-220
			],
			[
				-195,
				-33
			],
			[
				44,
				-773
			],
			[
				-124,
				-21
			]
		],
		[
			[
				41929,
				15006
			],
			[
				438,
				68
			],
			[
				9,
				-165
			],
			[
				131,
				21
			]
		],
		[
			[
				42507,
				14930
			],
			[
				-13,
				253
			]
		],
		[
			[
				42364,
				17794
			],
			[
				-475,
				-70
			],
			[
				6,
				-110
			],
			[
				-94,
				-70
			],
			[
				-289,
				-44
			]
		],
		[
			[
				41512,
				17500
			],
			[
				40,
				-667
			]
		],
		[
			[
				27948,
				87541
			],
			[
				10,
				-131
			],
			[
				81,
				-123
			],
			[
				32,
				-160
			],
			[
				360,
				-322
			],
			[
				100,
				90
			],
			[
				49,
				-98
			],
			[
				178,
				-9
			],
			[
				110,
				69
			],
			[
				116,
				269
			],
			[
				-7,
				149
			],
			[
				-77,
				204
			],
			[
				4,
				343
			],
			[
				-121,
				214
			],
			[
				-121,
				129
			],
			[
				-64,
				-52
			],
			[
				-267,
				-43
			],
			[
				-135,
				-234
			],
			[
				-203,
				-129
			],
			[
				-45,
				-166
			]
		],
		[
			[
				26988,
				88208
			],
			[
				30,
				-141
			],
			[
				198,
				-232
			],
			[
				52,
				-175
			],
			[
				85,
				-29
			],
			[
				-17,
				406
			],
			[
				-174,
				115
			],
			[
				-94,
				321
			],
			[
				-91,
				-137
			],
			[
				11,
				-128
			]
		],
		[
			[
				83382,
				36248
			],
			[
				-90,
				-858
			],
			[
				34,
				-287
			],
			[
				92,
				-26
			]
		],
		[
			[
				84182,
				34721
			],
			[
				241,
				-25
			]
		],
		[
			[
				84188,
				36672
			],
			[
				19,
				25
			]
		],
		[
			[
				79772,
				51623
			],
			[
				119,
				115
			],
			[
				203,
				-74
			],
			[
				173,
				-295
			],
			[
				55,
				-24
			],
			[
				72,
				-271
			]
		],
		[
			[
				80394,
				51074
			],
			[
				274,
				328
			]
		],
		[
			[
				80668,
				51402
			],
			[
				-97,
				100
			],
			[
				51,
				154
			],
			[
				-112,
				123
			],
			[
				116,
				51
			],
			[
				12,
				137
			],
			[
				-141,
				171
			],
			[
				-173,
				134
			],
			[
				53,
				86
			]
		],
		[
			[
				79794,
				52711
			],
			[
				-114,
				-343
			],
			[
				-131,
				-198
			],
			[
				-27,
				-107
			]
		],
		[
			[
				64904,
				63610
			],
			[
				184,
				-125
			]
		],
		[
			[
				65088,
				63485
			],
			[
				186,
				-19
			],
			[
				22,
				523
			]
		],
		[
			[
				64974,
				65182
			],
			[
				-323,
				-358
			]
		],
		[
			[
				67553,
				69282
			],
			[
				509,
				-66
			]
		],
		[
			[
				61940,
				41599
			],
			[
				64,
				171
			],
			[
				96,
				29
			],
			[
				18,
				148
			],
			[
				94,
				162
			],
			[
				59,
				2
			],
			[
				12,
				186
			],
			[
				71,
				98
			],
			[
				110,
				12
			]
		],
		[
			[
				62464,
				42407
			],
			[
				-68,
				144
			],
			[
				-50,
				366
			]
		],
		[
			[
				62346,
				42917
			],
			[
				-766,
				26
			]
		],
		[
			[
				37529,
				78893
			],
			[
				2026,
				1141
			],
			[
				192,
				26
			]
		],
		[
			[
				39747,
				80060
			],
			[
				-193,
				4348
			],
			[
				-5,
				47
			]
		],
		[
			[
				39549,
				84455
			],
			[
				-83,
				-82
			],
			[
				-263,
				-105
			],
			[
				-310,
				-380
			],
			[
				-209,
				-532
			],
			[
				-142,
				-44
			],
			[
				-109,
				-218
			],
			[
				-122,
				-160
			],
			[
				-128,
				-276
			],
			[
				-31,
				-368
			],
			[
				-76,
				-198
			],
			[
				-113,
				-423
			],
			[
				10,
				-193
			],
			[
				-37,
				-296
			],
			[
				50,
				-126
			],
			[
				14,
				-359
			],
			[
				-117,
				-486
			],
			[
				-170,
				-370
			],
			[
				20,
				-106
			],
			[
				-57,
				-593
			],
			[
				-43,
				-139
			],
			[
				-104,
				-108
			]
		],
		[
			[
				84430,
				53037
			],
			[
				14,
				-2043
			]
		],
		[
			[
				84786,
				51042
			],
			[
				39,
				167
			],
			[
				128,
				-69
			],
			[
				51,
				94
			],
			[
				68,
				-215
			],
			[
				56,
				249
			],
			[
				-31,
				78
			],
			[
				161,
				193
			],
			[
				-13,
				136
			],
			[
				61,
				216
			],
			[
				-35,
				100
			],
			[
				93,
				143
			]
		],
		[
			[
				85364,
				52134
			],
			[
				-87,
				250
			],
			[
				1,
				389
			]
		],
		[
			[
				85169,
				52808
			],
			[
				-597,
				185
			]
		],
		[
			[
				53921,
				34314
			],
			[
				-28,
				-267
			],
			[
				-96,
				-201
			],
			[
				-90,
				-91
			],
			[
				-3,
				-240
			],
			[
				146,
				-317
			],
			[
				12,
				-221
			]
		],
		[
			[
				47480,
				82162
			],
			[
				131,
				10
			],
			[
				-17,
				632
			],
			[
				171,
				15
			]
		],
		[
			[
				47765,
				82819
			],
			[
				-25,
				1072
			]
		],
		[
			[
				47740,
				83891
			],
			[
				-798,
				-41
			]
		],
		[
			[
				80093,
				65389
			],
			[
				59,
				202
			],
			[
				159,
				93
			]
		],
		[
			[
				80311,
				65684
			],
			[
				177,
				156
			]
		],
		[
			[
				80063,
				66687
			],
			[
				-203,
				-388
			],
			[
				-122,
				-290
			],
			[
				29,
				-194
			],
			[
				-40,
				-196
			]
		],
		[
			[
				42620,
				12665
			],
			[
				-21,
				506
			],
			[
				-92,
				1759
			]
		],
		[
			[
				28206,
				32622
			],
			[
				50,
				309
			],
			[
				83,
				242
			],
			[
				43,
				282
			],
			[
				-49,
				103
			],
			[
				-52,
				286
			],
			[
				45,
				237
			],
			[
				0,
				376
			],
			[
				68,
				-47
			],
			[
				83,
				329
			],
			[
				-68,
				38
			],
			[
				37,
				143
			]
		],
		[
			[
				27314,
				35953
			],
			[
				-534,
				140
			],
			[
				-1281,
				-401
			],
			[
				-763,
				-247
			]
		],
		[
			[
				41937,
				73911
			],
			[
				962,
				109
			]
		],
		[
			[
				42834,
				75689
			],
			[
				-58,
				-7
			]
		],
		[
			[
				41927,
				75581
			],
			[
				-60,
				-8
			]
		],
		[
			[
				41867,
				75573
			],
			[
				70,
				-1662
			]
		],
		[
			[
				51566,
				83984
			],
			[
				-414,
				1031
			]
		],
		[
			[
				51152,
				85015
			],
			[
				-476,
				-27
			],
			[
				-56,
				51
			],
			[
				-37,
				-293
			]
		],
		[
			[
				50583,
				84746
			],
			[
				24,
				-137
			],
			[
				-120,
				-34
			],
			[
				-92,
				-269
			],
			[
				-125,
				-28
			],
			[
				-23,
				-129
			]
		],
		[
			[
				79829,
				54046
			],
			[
				316,
				-73
			]
		],
		[
			[
				80145,
				53973
			],
			[
				-45,
				105
			]
		],
		[
			[
				80073,
				54856
			],
			[
				50,
				207
			],
			[
				-153,
				-53
			],
			[
				-102,
				116
			],
			[
				-91,
				209
			]
		],
		[
			[
				79777,
				55335
			],
			[
				-19,
				92
			]
		],
		[
			[
				82278,
				78672
			],
			[
				13,
				345
			],
			[
				194,
				241
			],
			[
				-1,
				156
			],
			[
				78,
				272
			]
		],
		[
			[
				82562,
				79686
			],
			[
				-442,
				129
			],
			[
				-83,
				162
			],
			[
				-88,
				48
			],
			[
				-43,
				189
			],
			[
				-201,
				162
			]
		],
		[
			[
				81566,
				78770
			],
			[
				-14,
				-166
			]
		],
		[
			[
				79102,
				81202
			],
			[
				88,
				-16
			],
			[
				45,
				-615
			]
		],
		[
			[
				80039,
				81277
			],
			[
				-60,
				206
			],
			[
				6,
				270
			],
			[
				-127,
				409
			],
			[
				17,
				86
			],
			[
				-186,
				277
			]
		],
		[
			[
				79689,
				82525
			],
			[
				-35,
				-207
			],
			[
				-105,
				-268
			],
			[
				-153,
				-32
			],
			[
				-44,
				-136
			],
			[
				-183,
				-118
			],
			[
				-27,
				-363
			],
			[
				-40,
				-199
			]
		],
		[
			[
				57145,
				15600
			],
			[
				136,
				106
			],
			[
				316,
				-257
			],
			[
				59,
				45
			],
			[
				82,
				224
			],
			[
				3,
				214
			],
			[
				146,
				176
			],
			[
				68,
				-57
			],
			[
				155,
				68
			],
			[
				-36,
				218
			],
			[
				60,
				116
			],
			[
				8,
				688
			]
		],
		[
			[
				58142,
				17141
			],
			[
				10,
				866
			]
		],
		[
			[
				58152,
				18007
			],
			[
				-870,
				20
			],
			[
				3,
				664
			],
			[
				22,
				-1
			],
			[
				18,
				1249
			],
			[
				-21,
				99
			]
		],
		[
			[
				56819,
				19781
			],
			[
				-121,
				-74
			]
		],
		[
			[
				73901,
				35607
			],
			[
				26,
				339
			],
			[
				28,
				-5
			],
			[
				29,
				393
			],
			[
				72,
				95
			]
		],
		[
			[
				73310,
				36814
			],
			[
				-29,
				-398
			]
		],
		[
			[
				73281,
				36416
			],
			[
				-46,
				-634
			]
		],
		[
			[
				41303,
				17743
			],
			[
				10,
				-165
			],
			[
				193,
				32
			],
			[
				6,
				-110
			]
		],
		[
			[
				42223,
				20680
			],
			[
				-141,
				-20
			],
			[
				17,
				-331
			],
			[
				-397,
				-65
			],
			[
				17,
				-321
			],
			[
				-194,
				-29
			],
			[
				16,
				-309
			],
			[
				-570,
				-92
			]
		],
		[
			[
				40971,
				19513
			],
			[
				36,
				-671
			],
			[
				-30,
				-5
			],
			[
				34,
				-641
			],
			[
				266,
				43
			],
			[
				26,
				-496
			]
		],
		[
			[
				41309,
				8652
			],
			[
				1492,
				228
			]
		],
		[
			[
				42801,
				8880
			],
			[
				-66,
				1378
			]
		],
		[
			[
				41343,
				10313
			],
			[
				18,
				-327
			],
			[
				-97,
				-16
			],
			[
				55,
				-980
			],
			[
				-28,
				-5
			],
			[
				18,
				-333
			]
		],
		[
			[
				80918,
				42674
			],
			[
				133,
				46
			]
		],
		[
			[
				38177,
				8077
			],
			[
				1575,
				305
			]
		],
		[
			[
				39752,
				8382
			],
			[
				-21,
				333
			],
			[
				-59,
				-11
			],
			[
				-20,
				326
			],
			[
				193,
				35
			],
			[
				-61,
				980
			],
			[
				323,
				57
			]
		],
		[
			[
				37483,
				12922
			],
			[
				48,
				-673
			],
			[
				108,
				-1306
			],
			[
				51,
				11
			],
			[
				24,
				-326
			],
			[
				162,
				34
			],
			[
				24,
				-335
			],
			[
				-61,
				-12
			],
			[
				22,
				-327
			],
			[
				74,
				-114
			],
			[
				95,
				40
			],
			[
				-5,
				-225
			],
			[
				90,
				-1293
			],
			[
				39,
				8
			],
			[
				23,
				-327
			]
		],
		[
			[
				83278,
				46962
			],
			[
				39,
				-153
			],
			[
				74,
				-21
			],
			[
				26,
				-250
			],
			[
				112,
				-559
			]
		],
		[
			[
				84573,
				46462
			],
			[
				-22,
				166
			],
			[
				19,
				329
			],
			[
				-86,
				157
			]
		],
		[
			[
				84484,
				47114
			],
			[
				-51,
				90
			],
			[
				-18,
				309
			],
			[
				-64,
				63
			],
			[
				-85,
				256
			],
			[
				-113,
				-106
			],
			[
				-152,
				151
			]
		],
		[
			[
				83366,
				47305
			],
			[
				-23,
				-29
			],
			[
				71,
				-286
			],
			[
				-136,
				-28
			]
		],
		[
			[
				84291,
				47137
			],
			[
				58,
				82
			],
			[
				82,
				-57
			],
			[
				-10,
				-217
			],
			[
				-110,
				61
			],
			[
				-20,
				131
			]
		],
		[
			[
				78034,
				72315
			],
			[
				46,
				664
			]
		],
		[
			[
				77483,
				72896
			],
			[
				-107,
				-445
			]
		],
		[
			[
				80897,
				85535
			],
			[
				829,
				-209
			],
			[
				-13,
				-165
			],
			[
				386,
				-96
			]
		],
		[
			[
				82099,
				85065
			],
			[
				52,
				625
			]
		],
		[
			[
				82151,
				85690
			],
			[
				18,
				203
			],
			[
				-99,
				23
			],
			[
				29,
				330
			]
		],
		[
			[
				82099,
				86246
			],
			[
				-1067,
				258
			]
		],
		[
			[
				81032,
				86504
			],
			[
				-301,
				76
			]
		],
		[
			[
				80731,
				86580
			],
			[
				69,
				-196
			],
			[
				97,
				-849
			]
		],
		[
			[
				80683,
				86593
			],
			[
				-58,
				13
			]
		],
		[
			[
				80625,
				86606
			],
			[
				-17,
				-169
			],
			[
				61,
				-19
			],
			[
				14,
				175
			]
		],
		[
			[
				76283,
				44135
			],
			[
				138,
				-572
			]
		],
		[
			[
				77326,
				43537
			],
			[
				18,
				386
			]
		],
		[
			[
				77370,
				44541
			],
			[
				-27,
				159
			]
		],
		[
			[
				76369,
				44816
			],
			[
				-1,
				-206
			],
			[
				-88,
				-45
			],
			[
				3,
				-430
			]
		],
		[
			[
				41584,
				44944
			],
			[
				-82,
				1685
			],
			[
				-55,
				1005
			],
			[
				-577,
				-90
			]
		],
		[
			[
				40870,
				47544
			],
			[
				57,
				-996
			],
			[
				-1046,
				-163
			]
		],
		[
			[
				39881,
				46385
			],
			[
				94,
				-1677
			]
		],
		[
			[
				75521,
				64907
			],
			[
				-143,
				24
			]
		],
		[
			[
				75378,
				64931
			],
			[
				-338,
				56
			]
		],
		[
			[
				71332,
				54811
			],
			[
				-136,
				-430
			]
		],
		[
			[
				58559,
				76607
			],
			[
				164,
				-169
			],
			[
				118,
				94
			]
		],
		[
			[
				76601,
				36752
			],
			[
				98,
				983
			]
		],
		[
			[
				76699,
				37735
			],
			[
				-468,
				123
			]
		],
		[
			[
				48803,
				94787
			],
			[
				1068,
				60
			]
		],
		[
			[
				50075,
				94857
			],
			[
				-161,
				592
			],
			[
				-406,
				1387
			]
		],
		[
			[
				49508,
				96836
			],
			[
				-167,
				63
			],
			[
				-168,
				-345
			],
			[
				-69,
				-28
			],
			[
				-68,
				-152
			],
			[
				-135,
				43
			],
			[
				-151,
				-162
			],
			[
				-144,
				-64
			],
			[
				-87,
				58
			],
			[
				-56,
				-116
			],
			[
				42,
				-190
			],
			[
				-69,
				-187
			],
			[
				-86,
				-62
			],
			[
				5,
				-119
			]
		],
		[
			[
				31339,
				38095
			],
			[
				39,
				25
			]
		],
		[
			[
				31378,
				38120
			],
			[
				-332,
				3828
			]
		],
		[
			[
				31046,
				41948
			],
			[
				-911,
				-237
			],
			[
				2,
				-19
			],
			[
				-570,
				-158
			]
		],
		[
			[
				29567,
				41534
			],
			[
				30,
				-330
			],
			[
				-57,
				-15
			]
		],
		[
			[
				28543,
				44305
			],
			[
				154,
				-1657
			],
			[
				90,
				25
			],
			[
				86,
				-903
			]
		],
		[
			[
				28873,
				41770
			],
			[
				38,
				57
			],
			[
				34,
				294
			],
			[
				89,
				47
			],
			[
				5,
				243
			],
			[
				41,
				130
			],
			[
				-12,
				208
			],
			[
				1786,
				464
			]
		],
		[
			[
				30854,
				43213
			],
			[
				6,
				208
			],
			[
				-160,
				508
			],
			[
				23,
				191
			],
			[
				-88,
				129
			],
			[
				-19,
				203
			],
			[
				-83,
				164
			],
			[
				-48,
				207
			],
			[
				86,
				203
			],
			[
				96,
				-4
			],
			[
				49,
				183
			],
			[
				-101,
				-140
			],
			[
				-138,
				-38
			],
			[
				-2,
				216
			],
			[
				-61,
				-16
			],
			[
				4,
				237
			],
			[
				61,
				158
			],
			[
				-16,
				133
			],
			[
				51,
				171
			],
			[
				-65,
				160
			],
			[
				56,
				211
			],
			[
				58,
				21
			],
			[
				-60,
				261
			],
			[
				90,
				244
			],
			[
				-62,
				90
			]
		],
		[
			[
				57078,
				10841
			],
			[
				61,
				-44
			],
			[
				186,
				44
			],
			[
				62,
				185
			],
			[
				325,
				13
			],
			[
				244,
				43
			],
			[
				80,
				426
			],
			[
				223,
				-14
			],
			[
				264,
				-119
			],
			[
				2,
				-175
			],
			[
				172,
				-140
			],
			[
				208,
				-70
			],
			[
				45,
				70
			],
			[
				134,
				-23
			]
		],
		[
			[
				59145,
				13821
			],
			[
				-1049,
				22
			],
			[
				2,
				199
			],
			[
				-971,
				36
			]
		],
		[
			[
				39284,
				16508
			],
			[
				-56,
				45
			],
			[
				-82,
				1317
			],
			[
				-53,
				-9
			],
			[
				-80,
				1313
			],
			[
				-59,
				-10
			],
			[
				-83,
				1323
			]
		],
		[
			[
				37733,
				19957
			],
			[
				69,
				-1011
			],
			[
				-106,
				-12
			],
			[
				44,
				-650
			],
			[
				-32,
				-9
			],
			[
				23,
				-337
			],
			[
				-192,
				-39
			],
			[
				19,
				-274
			],
			[
				-29,
				-62
			],
			[
				-864,
				-176
			]
		],
		[
			[
				36665,
				17387
			],
			[
				-62,
				-67
			]
		],
		[
			[
				36603,
				17320
			],
			[
				-23,
				-5
			],
			[
				29,
				-381
			],
			[
				-64,
				-13
			],
			[
				91,
				-300
			],
			[
				-32,
				-299
			]
		],
		[
			[
				36604,
				16322
			],
			[
				-8,
				-177
			],
			[
				-64,
				-204
			]
		],
		[
			[
				64937,
				66877
			],
			[
				-378,
				29
			]
		],
		[
			[
				49501,
				11958
			],
			[
				438,
				27
			]
		],
		[
			[
				50920,
				14023
			],
			[
				-1166,
				-63
			]
		],
		[
			[
				79685,
				43433
			],
			[
				95,
				145
			],
			[
				108,
				-5
			],
			[
				46,
				82
			]
		],
		[
			[
				79863,
				43997
			],
			[
				-198,
				247
			],
			[
				-228,
				488
			]
		],
		[
			[
				79437,
				44732
			],
			[
				-305,
				-191
			]
		],
		[
			[
				83765,
				42669
			],
			[
				150,
				104
			],
			[
				499,
				160
			],
			[
				100,
				-82
			],
			[
				108,
				134
			]
		],
		[
			[
				84622,
				42985
			],
			[
				-48,
				140
			]
		],
		[
			[
				83673,
				44361
			],
			[
				103,
				-478
			],
			[
				-117,
				-399
			],
			[
				56,
				-336
			],
			[
				-10,
				-117
			],
			[
				60,
				-362
			]
		],
		[
			[
				81650,
				42691
			],
			[
				124,
				-68
			],
			[
				64,
				41
			],
			[
				100,
				-89
			],
			[
				126,
				-236
			]
		],
		[
			[
				74681,
				60383
			],
			[
				139,
				-270
			]
		],
		[
			[
				75005,
				61483
			],
			[
				-300,
				60
			]
		],
		[
			[
				39532,
				42828
			],
			[
				0,
				0
			]
		],
		[
			[
				39798,
				39133
			],
			[
				1483,
				215
			]
		],
		[
			[
				40926,
				43168
			],
			[
				-1370,
				-209
			]
		],
		[
			[
				39556,
				42959
			],
			[
				-155,
				-25
			]
		],
		[
			[
				39401,
				42934
			],
			[
				53,
				-1005
			]
		],
		[
			[
				80172,
				70549
			],
			[
				121,
				208
			],
			[
				139,
				-41
			],
			[
				79,
				-115
			]
		],
		[
			[
				80255,
				72033
			],
			[
				-98,
				13
			]
		],
		[
			[
				53017,
				67492
			],
			[
				-18,
				20
			]
		],
		[
			[
				52005,
				67619
			],
			[
				-143,
				66
			]
		],
		[
			[
				51862,
				67685
			],
			[
				7,
				-668
			]
		],
		[
			[
				75509,
				28963
			],
			[
				26,
				-8
			],
			[
				-128,
				-1319
			]
		],
		[
			[
				75407,
				27636
			],
			[
				811,
				-251
			]
		],
		[
			[
				76218,
				27385
			],
			[
				79,
				549
			],
			[
				108,
				387
			],
			[
				93,
				800
			],
			[
				50,
				207
			]
		],
		[
			[
				76548,
				29328
			],
			[
				-787,
				247
			]
		],
		[
			[
				75761,
				29575
			],
			[
				-47,
				-497
			],
			[
				-190,
				49
			],
			[
				-15,
				-164
			]
		],
		[
			[
				30854,
				43213
			],
			[
				1,
				1
			]
		],
		[
			[
				30855,
				43214
			],
			[
				1549,
				409
			],
			[
				105,
				-115
			]
		],
		[
			[
				53159,
				89277
			],
			[
				-22,
				211
			]
		],
		[
			[
				54101,
				87750
			],
			[
				1,
				124
			],
			[
				-111,
				962
			]
		],
		[
			[
				53991,
				88836
			],
			[
				-128,
				277
			],
			[
				-375,
				358
			],
			[
				-174,
				220
			],
			[
				-263,
				395
			]
		],
		[
			[
				53106,
				89783
			],
			[
				69,
				-187
			],
			[
				110,
				-5
			],
			[
				270,
				-388
			],
			[
				313,
				-186
			],
			[
				-24,
				-289
			],
			[
				-254,
				247
			],
			[
				-78,
				129
			],
			[
				-125,
				17
			],
			[
				-50,
				-353
			],
			[
				-112,
				-17
			]
		],
		[
			[
				52983,
				88380
			],
			[
				-7,
				-161
			],
			[
				-69,
				-144
			],
			[
				152,
				-182
			],
			[
				258,
				-128
			],
			[
				156,
				-160
			]
		],
		[
			[
				27609,
				38427
			],
			[
				-68,
				11
			],
			[
				-33,
				878
			],
			[
				37,
				242
			],
			[
				-69,
				142
			],
			[
				32,
				112
			],
			[
				-72,
				157
			],
			[
				-12,
				185
			]
		],
		[
			[
				27424,
				40154
			],
			[
				-179,
				232
			],
			[
				-115,
				-54
			],
			[
				-19,
				150
			],
			[
				-1516,
				-464
			],
			[
				-1332,
				-434
			]
		],
		[
			[
				24263,
				39584
			],
			[
				90,
				-798
			]
		],
		[
			[
				31378,
				38120
			],
			[
				161,
				219
			],
			[
				181,
				-27
			],
			[
				27,
				-73
			],
			[
				123,
				62
			],
			[
				170,
				-77
			],
			[
				92,
				19
			],
			[
				-4,
				291
			],
			[
				45,
				53
			],
			[
				165,
				-156
			],
			[
				21,
				-257
			],
			[
				318,
				76
			],
			[
				-21,
				278
			],
			[
				64,
				14
			],
			[
				-28,
				391
			],
			[
				56,
				97
			],
			[
				125,
				29
			]
		],
		[
			[
				32733,
				40738
			],
			[
				-173,
				2148
			]
		],
		[
			[
				30855,
				43214
			],
			[
				8,
				-378
			],
			[
				120,
				-501
			],
			[
				124,
				-152
			],
			[
				96,
				-195
			],
			[
				-157,
				-40
			]
		],
		[
			[
				73574,
				60475
			],
			[
				219,
				178
			],
			[
				84,
				138
			],
			[
				48,
				285
			]
		],
		[
			[
				73104,
				61846
			],
			[
				-54,
				-889
			],
			[
				-19,
				-9
			]
		],
		[
			[
				49986,
				62990
			],
			[
				-31,
				-101
			],
			[
				-175,
				74
			],
			[
				-27,
				-57
			],
			[
				-1105,
				-62
			]
		],
		[
			[
				55932,
				57645
			],
			[
				-191,
				3
			],
			[
				-2,
				1678
			]
		],
		[
			[
				56614,
				48058
			],
			[
				9,
				-816
			],
			[
				214,
				4
			]
		],
		[
			[
				64462,
				81996
			],
			[
				87,
				-187
			],
			[
				521,
				-253
			]
		],
		[
			[
				65070,
				81556
			],
			[
				-23,
				446
			],
			[
				-51,
				61
			],
			[
				81,
				555
			]
		],
		[
			[
				65077,
				82618
			],
			[
				-254,
				-100
			],
			[
				3,
				54
			],
			[
				-194,
				26
			]
		],
		[
			[
				64632,
				82598
			],
			[
				-5,
				-116
			],
			[
				-77,
				10
			],
			[
				-5,
				-165
			],
			[
				-65,
				7
			],
			[
				-18,
				-338
			]
		],
		[
			[
				65368,
				85634
			],
			[
				275,
				84
			],
			[
				-133,
				37
			],
			[
				-142,
				-121
			]
		],
		[
			[
				64997,
				85816
			],
			[
				172,
				-79
			],
			[
				21,
				53
			],
			[
				-186,
				85
			],
			[
				-7,
				-59
			]
		],
		[
			[
				64283,
				83698
			],
			[
				137,
				-351
			]
		],
		[
			[
				64420,
				83347
			],
			[
				245,
				-30
			],
			[
				-7,
				-111
			],
			[
				79,
				-9
			],
			[
				61,
				-119
			],
			[
				89,
				300
			],
			[
				130,
				120
			],
			[
				92,
				195
			],
			[
				76,
				30
			],
			[
				235,
				438
			],
			[
				252,
				403
			],
			[
				23,
				238
			],
			[
				-40,
				163
			]
		],
		[
			[
				65655,
				84965
			],
			[
				7,
				-145
			],
			[
				-81,
				-175
			],
			[
				-84,
				218
			],
			[
				-95,
				-36
			],
			[
				-51,
				77
			],
			[
				-86,
				-61
			],
			[
				22,
				190
			],
			[
				-79,
				156
			],
			[
				-22,
				177
			],
			[
				-57,
				-15
			],
			[
				-30,
				190
			],
			[
				-58,
				-36
			],
			[
				-142,
				166
			],
			[
				-21,
				208
			],
			[
				-109,
				-43
			],
			[
				10,
				-149
			],
			[
				-208,
				-310
			],
			[
				-74,
				50
			],
			[
				-185,
				-53
			],
			[
				-72,
				-101
			],
			[
				-138,
				-9
			],
			[
				-157,
				-88
			],
			[
				-115,
				-185
			],
			[
				82,
				-55
			],
			[
				46,
				-194
			],
			[
				142,
				200
			],
			[
				45,
				-79
			],
			[
				5,
				224
			],
			[
				100,
				49
			],
			[
				-32,
				-340
			],
			[
				-120,
				-105
			],
			[
				-96,
				-247
			]
		],
		[
			[
				14827,
				14687
			],
			[
				43,
				-106
			],
			[
				26,
				-322
			],
			[
				-101,
				-161
			],
			[
				-33,
				-250
			]
		],
		[
			[
				14762,
				13848
			],
			[
				-43,
				-229
			],
			[
				-52,
				-50
			],
			[
				54,
				-484
			]
		],
		[
			[
				84187,
				41371
			],
			[
				324,
				-32
			]
		],
		[
			[
				84736,
				41466
			],
			[
				58,
				424
			],
			[
				-93,
				406
			],
			[
				39,
				40
			],
			[
				-46,
				196
			],
			[
				71,
				97
			],
			[
				-143,
				356
			]
		],
		[
			[
				75345,
				58941
			],
			[
				46,
				-131
			],
			[
				124,
				-89
			],
			[
				54,
				111
			],
			[
				65,
				-111
			],
			[
				123,
				14
			],
			[
				74,
				118
			],
			[
				121,
				13
			]
		],
		[
			[
				78432,
				77553
			],
			[
				487,
				-57
			]
		],
		[
			[
				79012,
				78931
			],
			[
				-194,
				45
			],
			[
				-20,
				-165
			],
			[
				-664,
				151
			]
		],
		[
			[
				78134,
				78962
			],
			[
				56,
				-121
			],
			[
				-49,
				-144
			],
			[
				90,
				-73
			],
			[
				54,
				-200
			],
			[
				-50,
				-272
			],
			[
				53,
				-130
			],
			[
				123,
				-84
			],
			[
				55,
				-125
			],
			[
				-34,
				-260
			]
		],
		[
			[
				54131,
				86652
			],
			[
				674,
				-699
			],
			[
				178,
				-317
			]
		],
		[
			[
				55703,
				87175
			],
			[
				-298,
				287
			],
			[
				-300,
				330
			],
			[
				-521,
				413
			],
			[
				-230,
				207
			],
			[
				-363,
				424
			]
		],
		[
			[
				93407,
				27217
			],
			[
				-105,
				97
			],
			[
				-140,
				245
			],
			[
				-39,
				-166
			],
			[
				-285,
				202
			],
			[
				-67,
				-57
			],
			[
				21,
				-146
			],
			[
				-60,
				3
			],
			[
				-33,
				373
			],
			[
				-160,
				4
			],
			[
				-39,
				-131
			],
			[
				-191,
				72
			],
			[
				36,
				-119
			],
			[
				-44,
				-248
			],
			[
				-115,
				4
			],
			[
				-57,
				148
			]
		],
		[
			[
				92129,
				27498
			],
			[
				-109,
				-19
			],
			[
				-37,
				-222
			],
			[
				91,
				-2
			],
			[
				-17,
				-627
			],
			[
				-20,
				-55
			]
		],
		[
			[
				62944,
				41139
			],
			[
				13,
				-447
			],
			[
				153,
				-386
			],
			[
				58,
				-70
			],
			[
				21,
				-440
			],
			[
				-15,
				-121
			]
		],
		[
			[
				63447,
				39655
			],
			[
				46,
				1668
			]
		],
		[
			[
				63301,
				41327
			],
			[
				-472,
				47
			]
		],
		[
			[
				62829,
				41374
			],
			[
				115,
				-235
			]
		],
		[
			[
				96155,
				18414
			],
			[
				-168,
				-33
			],
			[
				-28,
				254
			],
			[
				-176,
				17
			]
		],
		[
			[
				79981,
				73238
			],
			[
				199,
				-48
			],
			[
				-12,
				-145
			]
		],
		[
			[
				80778,
				73593
			],
			[
				-189,
				437
			],
			[
				-132,
				83
			]
		],
		[
			[
				87608,
				47742
			],
			[
				272,
				142
			],
			[
				216,
				-98
			],
			[
				-22,
				175
			],
			[
				93,
				-112
			],
			[
				60,
				109
			]
		],
		[
			[
				87468,
				48146
			],
			[
				140,
				-404
			]
		],
		[
			[
				82806,
				78024
			],
			[
				233,
				1036
			],
			[
				123,
				398
			],
			[
				40,
				274
			],
			[
				124,
				408
			]
		],
		[
			[
				82746,
				80480
			],
			[
				-50,
				-517
			],
			[
				-134,
				-277
			]
		],
		[
			[
				15092,
				837
			],
			[
				197,
				290
			],
			[
				-144,
				-111
			],
			[
				-53,
				-179
			]
		],
		[
			[
				14646,
				1390
			],
			[
				52,
				-76
			],
			[
				102,
				50
			],
			[
				51,
				235
			],
			[
				84,
				71
			],
			[
				-52,
				-259
			],
			[
				244,
				-242
			],
			[
				60,
				35
			],
			[
				160,
				276
			],
			[
				-122,
				109
			],
			[
				20,
				233
			],
			[
				-44,
				189
			],
			[
				-67,
				50
			],
			[
				-34,
				241
			],
			[
				-92,
				-29
			],
			[
				-49,
				-193
			],
			[
				-162,
				-77
			],
			[
				-95,
				-176
			],
			[
				-56,
				-437
			]
		],
		[
			[
				81983,
				69147
			],
			[
				124,
				100
			],
			[
				90,
				-5
			]
		],
		[
			[
				82197,
				69242
			],
			[
				191,
				304
			],
			[
				-15,
				117
			],
			[
				217,
				519
			],
			[
				-29,
				244
			]
		],
		[
			[
				82561,
				70426
			],
			[
				-94,
				-16
			],
			[
				-316,
				635
			]
		],
		[
			[
				82019,
				70515
			],
			[
				-19,
				-126
			],
			[
				-240,
				-491
			],
			[
				20,
				-131
			],
			[
				-54,
				-139
			]
		],
		[
			[
				81726,
				69628
			],
			[
				11,
				-80
			],
			[
				246,
				-401
			]
		],
		[
			[
				64385,
				36243
			],
			[
				768,
				-90
			]
		],
		[
			[
				65294,
				37470
			],
			[
				-382,
				47
			]
		],
		[
			[
				78499,
				36195
			],
			[
				197,
				-54
			],
			[
				-79,
				-265
			],
			[
				341,
				-79
			]
		],
		[
			[
				78958,
				35797
			],
			[
				121,
				1371
			]
		],
		[
			[
				79079,
				37168
			],
			[
				-38,
				10
			],
			[
				22,
				314
			],
			[
				-379,
				70
			]
		],
		[
			[
				78684,
				37562
			],
			[
				-26,
				-284
			],
			[
				-67,
				18
			]
		],
		[
			[
				78591,
				37296
			],
			[
				-92,
				-1101
			]
		],
		[
			[
				81935,
				73599
			],
			[
				162,
				248
			],
			[
				240,
				189
			],
			[
				96,
				-39
			],
			[
				222,
				27
			]
		],
		[
			[
				82655,
				74024
			],
			[
				-14,
				346
			],
			[
				-39,
				4
			],
			[
				-78,
				298
			],
			[
				-58,
				25
			],
			[
				1,
				455
			],
			[
				-43,
				12
			]
		],
		[
			[
				82424,
				75164
			],
			[
				-271,
				-241
			],
			[
				19,
				-96
			],
			[
				-134,
				83
			],
			[
				-108,
				-27
			],
			[
				-167,
				-140
			]
		],
		[
			[
				81763,
				74743
			],
			[
				-26,
				24
			],
			[
				38,
				-649
			]
		],
		[
			[
				82137,
				83197
			],
			[
				195,
				2322
			]
		],
		[
			[
				82332,
				85519
			],
			[
				12,
				137
			],
			[
				-127,
				-25
			],
			[
				-66,
				59
			]
		],
		[
			[
				82099,
				85065
			],
			[
				-13,
				-163
			],
			[
				-84,
				6
			],
			[
				-93,
				-131
			],
			[
				-147,
				4
			],
			[
				-148,
				-280
			],
			[
				19,
				-49
			]
		],
		[
			[
				79674,
				36440
			],
			[
				-77,
				-813
			]
		],
		[
			[
				82431,
				48105
			],
			[
				207,
				-68
			],
			[
				106,
				209
			],
			[
				240,
				-41
			],
			[
				114,
				127
			],
			[
				62,
				-125
			]
		],
		[
			[
				83161,
				48632
			],
			[
				-260,
				-72
			],
			[
				-136,
				428
			],
			[
				-157,
				259
			]
		],
		[
			[
				81074,
				51066
			],
			[
				131,
				-122
			],
			[
				77,
				-146
			],
			[
				-72,
				-125
			],
			[
				25,
				-186
			]
		],
		[
			[
				81344,
				51560
			],
			[
				-323,
				-413
			],
			[
				53,
				-81
			]
		],
		[
			[
				82758,
				46779
			],
			[
				172,
				127
			],
			[
				255,
				12
			],
			[
				93,
				44
			]
		],
		[
			[
				72249,
				67584
			],
			[
				65,
				-12
			],
			[
				9,
				167
			],
			[
				191,
				-32
			],
			[
				24,
				426
			]
		],
		[
			[
				89151,
				40607
			],
			[
				185,
				-48
			],
			[
				103,
				-106
			],
			[
				94,
				-260
			]
		],
		[
			[
				89533,
				40193
			],
			[
				148,
				151
			],
			[
				129,
				382
			],
			[
				-6,
				193
			],
			[
				58,
				331
			],
			[
				179,
				244
			],
			[
				31,
				155
			]
		],
		[
			[
				90072,
				41649
			],
			[
				-122,
				-17
			],
			[
				-15,
				179
			],
			[
				-132,
				103
			],
			[
				-86,
				304
			],
			[
				-280,
				127
			]
		],
		[
			[
				89437,
				42345
			],
			[
				-192,
				-1166
			]
		],
		[
			[
				89245,
				41179
			],
			[
				-63,
				-383
			]
		],
		[
			[
				89182,
				40796
			],
			[
				-31,
				-189
			]
		],
		[
			[
				69181,
				50172
			],
			[
				384,
				-47
			]
		],
		[
			[
				69638,
				50983
			],
			[
				-98,
				150
			],
			[
				-155,
				-246
			],
			[
				-49,
				172
			],
			[
				69,
				169
			],
			[
				-26,
				211
			],
			[
				-103,
				33
			],
			[
				-3,
				-257
			],
			[
				-60,
				2
			]
		],
		[
			[
				75912,
				44214
			],
			[
				371,
				-79
			]
		],
		[
			[
				76357,
				45373
			],
			[
				-374,
				226
			],
			[
				-106,
				17
			]
		],
		[
			[
				75877,
				45616
			],
			[
				-340,
				72
			],
			[
				-53,
				-867
			]
		],
		[
			[
				61051,
				12520
			],
			[
				125,
				4
			],
			[
				-11,
				109
			],
			[
				134,
				269
			],
			[
				120,
				-52
			],
			[
				-5,
				245
			],
			[
				116,
				-102
			],
			[
				91,
				67
			],
			[
				86,
				-86
			],
			[
				180,
				-71
			],
			[
				162,
				-261
			],
			[
				154,
				-163
			]
		],
		[
			[
				62300,
				15219
			],
			[
				-212,
				394
			],
			[
				-25,
				102
			],
			[
				-243,
				528
			],
			[
				-174,
				323
			],
			[
				-143,
				148
			],
			[
				-134,
				298
			],
			[
				-57,
				39
			],
			[
				-131,
				260
			]
		],
		[
			[
				15406,
				1954
			],
			[
				23,
				-124
			],
			[
				84,
				180
			],
			[
				-102,
				38
			],
			[
				-5,
				-94
			]
		],
		[
			[
				18207,
				2857
			],
			[
				-71,
				93
			],
			[
				37,
				200
			],
			[
				64,
				-15
			],
			[
				-28,
				215
			]
		],
		[
			[
				15649,
				3063
			],
			[
				-26,
				-126
			],
			[
				-119,
				-209
			],
			[
				-43,
				-184
			],
			[
				25,
				-119
			],
			[
				-166,
				30
			],
			[
				-38,
				-202
			],
			[
				55,
				-176
			],
			[
				167,
				86
			],
			[
				19,
				172
			],
			[
				96,
				32
			],
			[
				11,
				-365
			],
			[
				89,
				23
			],
			[
				46,
				-94
			],
			[
				-64,
				-211
			]
		],
		[
			[
				15303,
				1766
			],
			[
				52,
				-72
			],
			[
				38,
				176
			],
			[
				-91,
				66
			],
			[
				1,
				-170
			]
		],
		[
			[
				22081,
				3191
			],
			[
				1109,
				444
			]
		],
		[
			[
				22708,
				7145
			],
			[
				-144,
				-60
			],
			[
				-127,
				934
			],
			[
				-87,
				-54
			],
			[
				-123,
				-267
			],
			[
				-161,
				112
			],
			[
				-34,
				-69
			]
		],
		[
			[
				22032,
				7741
			],
			[
				-79,
				-117
			],
			[
				-90,
				57
			],
			[
				-74,
				-75
			],
			[
				-46,
				89
			],
			[
				-102,
				-14
			],
			[
				-52,
				-348
			],
			[
				-72,
				-268
			],
			[
				-214,
				111
			]
		],
		[
			[
				13702,
				6890
			],
			[
				186,
				95
			],
			[
				152,
				-200
			],
			[
				117,
				-82
			],
			[
				26,
				149
			],
			[
				74,
				-13
			]
		],
		[
			[
				14257,
				6839
			],
			[
				73,
				-10
			],
			[
				22,
				234
			],
			[
				96,
				125
			]
		],
		[
			[
				13580,
				7985
			],
			[
				130,
				-739
			],
			[
				-62,
				-34
			],
			[
				54,
				-322
			]
		],
		[
			[
				77140,
				61668
			],
			[
				118,
				-15
			],
			[
				90,
				254
			],
			[
				134,
				69
			],
			[
				78,
				-109
			],
			[
				152,
				87
			]
		],
		[
			[
				77745,
				62061
			],
			[
				-43,
				54
			],
			[
				-114,
				780
			]
		],
		[
			[
				77321,
				63148
			],
			[
				-114,
				-254
			]
		],
		[
			[
				77093,
				61765
			],
			[
				47,
				-97
			]
		],
		[
			[
				76276,
				62536
			],
			[
				55,
				-75
			],
			[
				166,
				52
			],
			[
				77,
				-304
			],
			[
				71,
				-113
			],
			[
				155,
				39
			]
		],
		[
			[
				76886,
				62958
			],
			[
				-224,
				387
			]
		],
		[
			[
				76662,
				63345
			],
			[
				-242,
				-132
			],
			[
				-174,
				-247
			],
			[
				-13,
				-244
			]
		],
		[
			[
				78891,
				63340
			],
			[
				-245,
				218
			],
			[
				-106,
				171
			],
			[
				-77,
				-75
			],
			[
				-105,
				65
			]
		],
		[
			[
				78358,
				63719
			],
			[
				-79,
				-175
			]
		],
		[
			[
				50649,
				80982
			],
			[
				55,
				-157
			],
			[
				-48,
				-74
			],
			[
				148,
				-522
			]
		],
		[
			[
				67205,
				75063
			],
			[
				61,
				1389
			]
		],
		[
			[
				66910,
				76495
			],
			[
				-12,
				-305
			],
			[
				-106,
				-165
			],
			[
				-228,
				-168
			],
			[
				-23,
				-632
			]
		],
		[
			[
				67543,
				80496
			],
			[
				32,
				265
			],
			[
				-164,
				206
			],
			[
				-45,
				270
			],
			[
				-146,
				35
			]
		],
		[
			[
				67220,
				81272
			],
			[
				-88,
				12
			],
			[
				-98,
				-170
			],
			[
				-59,
				-224
			],
			[
				18,
				-200
			],
			[
				-113,
				-243
			],
			[
				-20,
				-202
			]
		],
		[
			[
				66521,
				73506
			],
			[
				-18,
				-502
			],
			[
				-136,
				-886
			]
		],
		[
			[
				66367,
				72118
			],
			[
				94,
				-193
			]
		],
		[
			[
				13548,
				11714
			],
			[
				26,
				-426
			],
			[
				-52,
				-305
			],
			[
				-6,
				-212
			],
			[
				-252,
				-529
			],
			[
				-152,
				-26
			]
		],
		[
			[
				13112,
				10216
			],
			[
				126,
				-781
			]
		],
		[
			[
				33966,
				49076
			],
			[
				68,
				-245
			],
			[
				-117,
				-166
			],
			[
				-130,
				-28
			],
			[
				-14,
				-231
			],
			[
				-43,
				-79
			],
			[
				856,
				182
			],
			[
				-7,
				116
			]
		],
		[
			[
				35850,
				38392
			],
			[
				764,
				154
			]
		],
		[
			[
				36614,
				38546
			],
			[
				-33,
				120
			],
			[
				28,
				184
			],
			[
				110,
				89
			],
			[
				70,
				148
			],
			[
				76,
				-42
			],
			[
				64,
				367
			],
			[
				-60,
				130
			],
			[
				-17,
				237
			],
			[
				-84,
				295
			],
			[
				0,
				159
			],
			[
				56,
				313
			],
			[
				-9,
				202
			]
		],
		[
			[
				36077,
				41977
			],
			[
				34,
				-513
			],
			[
				-674,
				-130
			]
		],
		[
			[
				27751,
				23755
			],
			[
				-102,
				988
			],
			[
				471,
				147
			]
		],
		[
			[
				27980,
				26207
			],
			[
				-35,
				312
			],
			[
				-188,
				-40
			],
			[
				-35,
				328
			],
			[
				-191,
				-60
			],
			[
				-82,
				617
			],
			[
				-96,
				-29
			]
		],
		[
			[
				27353,
				27335
			],
			[
				-565,
				-176
			],
			[
				34,
				-312
			],
			[
				-435,
				-140
			],
			[
				56,
				-490
			],
			[
				-59,
				-32
			],
			[
				-10,
				-331
			],
			[
				-161,
				10
			]
		],
		[
			[
				77767,
				37214
			],
			[
				20,
				273
			],
			[
				73,
				-17
			]
		],
		[
			[
				77860,
				37470
			],
			[
				112,
				1232
			]
		],
		[
			[
				77972,
				38702
			],
			[
				-157,
				42
			],
			[
				33,
				379
			]
		],
		[
			[
				77358,
				37590
			],
			[
				-30,
				-274
			],
			[
				166,
				-43
			]
		],
		[
			[
				57102,
				42664
			],
			[
				-4,
				1005
			]
		],
		[
			[
				57098,
				43669
			],
			[
				-741,
				0
			]
		],
		[
			[
				56122,
				43165
			],
			[
				0,
				-837
			],
			[
				-41,
				-1
			],
			[
				-2,
				-383
			]
		],
		[
			[
				55156,
				60174
			],
			[
				1,
				504
			],
			[
				96,
				0
			],
			[
				1,
				671
			]
		],
		[
			[
				55254,
				61349
			],
			[
				-197,
				1
			],
			[
				-2,
				338
			],
			[
				-94,
				2
			],
			[
				0,
				335
			],
			[
				-190,
				0
			]
		],
		[
			[
				80026,
				39114
			],
			[
				128,
				-29
			],
			[
				65,
				1012
			]
		],
		[
			[
				74699,
				61544
			],
			[
				-56,
				348
			],
			[
				-59,
				156
			],
			[
				11,
				169
			],
			[
				-126,
				24
			],
			[
				-11,
				204
			]
		],
		[
			[
				19097,
				5983
			],
			[
				36,
				-372
			],
			[
				210,
				-79
			],
			[
				79,
				97
			],
			[
				-21,
				188
			],
			[
				87,
				188
			],
			[
				210,
				-234
			],
			[
				132,
				175
			],
			[
				113,
				-4
			],
			[
				50,
				-151
			],
			[
				79,
				61
			],
			[
				193,
				-99
			],
			[
				106,
				83
			],
			[
				41,
				393
			],
			[
				56,
				145
			],
			[
				-71,
				162
			]
		],
		[
			[
				85600,
				41074
			],
			[
				-16,
				193
			],
			[
				-167,
				367
			],
			[
				-68,
				425
			]
		],
		[
			[
				85349,
				42059
			],
			[
				-383,
				-373
			]
		],
		[
			[
				68129,
				76348
			],
			[
				-384,
				46
			]
		],
		[
			[
				58441,
				80613
			],
			[
				-911,
				24
			]
		],
		[
			[
				78763,
				38500
			],
			[
				-79,
				-938
			]
		],
		[
			[
				79079,
				37168
			],
			[
				508,
				-134
			]
		],
		[
			[
				79617,
				37363
			],
			[
				60,
				662
			]
		],
		[
			[
				78776,
				38626
			],
			[
				-13,
				-126
			]
		],
		[
			[
				81478,
				71408
			],
			[
				66,
				-179
			]
		],
		[
			[
				82639,
				72352
			],
			[
				31,
				87
			],
			[
				106,
				-6
			],
			[
				21,
				276
			],
			[
				-51,
				276
			],
			[
				-44,
				-36
			]
		],
		[
			[
				82702,
				72949
			],
			[
				-3,
				-116
			],
			[
				-152,
				-161
			],
			[
				-102,
				-9
			],
			[
				-218,
				129
			],
			[
				-127,
				-194
			]
		],
		[
			[
				82100,
				72598
			],
			[
				-303,
				-176
			],
			[
				-268,
				-354
			],
			[
				3,
				-369
			],
			[
				-91,
				-10
			],
			[
				-69,
				-126
			]
		],
		[
			[
				82100,
				72598
			],
			[
				-97,
				504
			],
			[
				-170,
				191
			]
		],
		[
			[
				52453,
				88194
			],
			[
				-41,
				-219
			],
			[
				51,
				-631
			],
			[
				-52,
				-217
			],
			[
				-89,
				-126
			],
			[
				-140,
				74
			],
			[
				-5,
				-72
			]
		],
		[
			[
				65042,
				61257
			],
			[
				264,
				-20
			]
		],
		[
			[
				65061,
				62966
			],
			[
				114,
				160
			],
			[
				5,
				169
			],
			[
				-92,
				190
			]
		],
		[
			[
				64893,
				63336
			],
			[
				-17,
				-941
			],
			[
				-185,
				34
			]
		],
		[
			[
				12669,
				9647
			],
			[
				108,
				15
			],
			[
				52,
				185
			],
			[
				-35,
				171
			],
			[
				77,
				181
			]
		],
		[
			[
				12871,
				10199
			],
			[
				-228,
				1344
			]
		],
		[
			[
				11736,
				11064
			],
			[
				83,
				-454
			],
			[
				-14,
				-161
			],
			[
				104,
				-70
			],
			[
				35,
				-110
			],
			[
				40,
				-404
			],
			[
				-24,
				-441
			],
			[
				26,
				-34
			],
			[
				96,
				314
			],
			[
				66,
				81
			],
			[
				7,
				-125
			],
			[
				173,
				-4
			],
			[
				43,
				118
			],
			[
				95,
				-68
			],
			[
				69,
				47
			],
			[
				134,
				-106
			]
		],
		[
			[
				74602,
				77650
			],
			[
				-16,
				337
			],
			[
				-223,
				32
			],
			[
				-41,
				-160
			],
			[
				-133,
				21
			],
			[
				-22,
				342
			]
		],
		[
			[
				74981,
				78592
			],
			[
				-98,
				19
			],
			[
				29,
				499
			],
			[
				-700,
				115
			],
			[
				-51,
				126
			],
			[
				-155,
				27
			],
			[
				-48,
				91
			]
		],
		[
			[
				80096,
				38412
			],
			[
				192,
				-43
			],
			[
				137,
				18
			]
		],
		[
			[
				80425,
				38387
			],
			[
				162,
				389
			],
			[
				-31,
				307
			]
		],
		[
			[
				81383,
				56906
			],
			[
				-78,
				-179
			],
			[
				-328,
				210
			]
		],
		[
			[
				88362,
				54937
			],
			[
				-165,
				-70
			],
			[
				-1,
				-82
			],
			[
				-183,
				-68
			]
		],
		[
			[
				66501,
				78200
			],
			[
				-807,
				92
			]
		],
		[
			[
				65694,
				78292
			],
			[
				-49,
				-1338
			]
		],
		[
			[
				76456,
				61179
			],
			[
				124,
				-27
			]
		],
		[
			[
				76580,
				61152
			],
			[
				56,
				223
			],
			[
				120,
				81
			],
			[
				56,
				-68
			],
			[
				105,
				446
			]
		],
		[
			[
				75933,
				47086
			],
			[
				-56,
				-1470
			]
		],
		[
			[
				76036,
				47073
			],
			[
				-103,
				13
			]
		],
		[
			[
				43590,
				65514
			],
			[
				-62,
				1878
			]
		],
		[
			[
				43528,
				67392
			],
			[
				-974,
				-113
			]
		],
		[
			[
				53231,
				38570
			],
			[
				144,
				53
			],
			[
				121,
				-35
			],
			[
				91,
				94
			],
			[
				204,
				-26
			],
			[
				163,
				154
			]
		],
		[
			[
				83234,
				43902
			],
			[
				23,
				-20
			],
			[
				35,
				-423
			],
			[
				-102,
				-22
			],
			[
				16,
				-245
			],
			[
				55,
				-181
			],
			[
				-36,
				-65
			],
			[
				-261,
				198
			],
			[
				-39,
				-51
			]
		],
		[
			[
				90152,
				17663
			],
			[
				230,
				-107
			]
		],
		[
			[
				90360,
				19339
			],
			[
				-72,
				-181
			],
			[
				-28,
				-480
			],
			[
				13,
				-253
			],
			[
				-86,
				-96
			],
			[
				21,
				-322
			],
			[
				-56,
				-344
			]
		],
		[
			[
				54150,
				35647
			],
			[
				23,
				211
			],
			[
				124,
				194
			],
			[
				-10,
				242
			]
		],
		[
			[
				54287,
				36294
			],
			[
				-61,
				123
			],
			[
				-404,
				-6
			]
		],
		[
			[
				82702,
				72949
			],
			[
				-108,
				76
			],
			[
				-10,
				94
			],
			[
				119,
				-52
			],
			[
				49,
				67
			],
			[
				-139,
				619
			],
			[
				42,
				271
			]
		],
		[
			[
				78358,
				63719
			],
			[
				-29,
				213
			],
			[
				69,
				45
			],
			[
				74,
				199
			],
			[
				127,
				188
			]
		],
		[
			[
				78599,
				64364
			],
			[
				-124,
				136
			],
			[
				-147,
				-89
			],
			[
				-162,
				220
			],
			[
				-68,
				21
			]
		],
		[
			[
				77900,
				64537
			],
			[
				-51,
				-26
			],
			[
				34,
				-264
			],
			[
				-26,
				-180
			],
			[
				-81,
				-112
			]
		],
		[
			[
				76061,
				68263
			],
			[
				-27,
				682
			],
			[
				29,
				144
			]
		],
		[
			[
				76063,
				69089
			],
			[
				61,
				331
			]
		],
		[
			[
				75469,
				69590
			],
			[
				-85,
				-1217
			]
		],
		[
			[
				54564,
				84215
			],
			[
				86,
				151
			],
			[
				-19,
				191
			],
			[
				62,
				39
			],
			[
				-72,
				109
			],
			[
				167,
				237
			],
			[
				52,
				298
			],
			[
				103,
				70
			],
			[
				29,
				185
			],
			[
				62,
				15
			]
		],
		[
			[
				78112,
				65236
			],
			[
				-46,
				153
			],
			[
				63,
				178
			]
		],
		[
			[
				78599,
				64364
			],
			[
				222,
				179
			],
			[
				158,
				11
			]
		],
		[
			[
				75328,
				73033
			],
			[
				206,
				49
			],
			[
				-33,
				71
			],
			[
				132,
				73
			]
		],
		[
			[
				75257,
				73858
			],
			[
				-42,
				-220
			],
			[
				92,
				-408
			],
			[
				21,
				-197
			]
		],
		[
			[
				77944,
				39535
			],
			[
				796,
				-158
			]
		],
		[
			[
				78034,
				40641
			],
			[
				-38,
				-272
			]
		],
		[
			[
				77655,
				79182
			],
			[
				-43,
				-611
			],
			[
				55,
				-124
			],
			[
				-16,
				-222
			],
			[
				122,
				-27
			],
			[
				-83,
				-319
			],
			[
				-17,
				-239
			]
		],
		[
			[
				78178,
				77582
			],
			[
				254,
				-29
			]
		],
		[
			[
				78134,
				78962
			],
			[
				-166,
				262
			],
			[
				-51,
				238
			],
			[
				-48,
				406
			]
		],
		[
			[
				77869,
				79868
			],
			[
				-68,
				-60
			],
			[
				-97,
				51
			]
		],
		[
			[
				80567,
				83457
			],
			[
				-20,
				-230
			],
			[
				-118,
				-57
			],
			[
				2,
				-272
			],
			[
				-47,
				-34
			],
			[
				-333,
				3
			],
			[
				-96,
				205
			],
			[
				-93,
				-308
			],
			[
				-17,
				-189
			],
			[
				-156,
				-50
			]
		],
		[
			[
				12871,
				10199
			],
			[
				123,
				68
			],
			[
				118,
				-51
			]
		],
		[
			[
				13507,
				12179
			],
			[
				-254,
				-98
			]
		],
		[
			[
				13253,
				12081
			],
			[
				10,
				-53
			],
			[
				-146,
				-134
			],
			[
				19,
				-101
			],
			[
				-493,
				-250
			]
		],
		[
			[
				82995,
				81281
			],
			[
				-17,
				75
			],
			[
				-434,
				329
			]
		],
		[
			[
				72413,
				79748
			],
			[
				-225,
				98
			]
		],
		[
			[
				96440,
				19530
			],
			[
				-37,
				341
			],
			[
				-132,
				220
			],
			[
				-94,
				-243
			]
		],
		[
			[
				74621,
				55285
			],
			[
				12,
				-2
			]
		],
		[
			[
				74633,
				55283
			],
			[
				916,
				-147
			]
		],
		[
			[
				75456,
				56615
			],
			[
				-107,
				229
			]
		],
		[
			[
				95600,
				21087
			],
			[
				-26,
				169
			],
			[
				70,
				194
			],
			[
				-72,
				232
			],
			[
				34,
				96
			],
			[
				-171,
				178
			],
			[
				-39,
				367
			],
			[
				44,
				88
			],
			[
				-21,
				358
			],
			[
				-41,
				275
			],
			[
				-42,
				61
			]
		],
		[
			[
				95336,
				23105
			],
			[
				-219,
				-148
			]
		],
		[
			[
				94649,
				21508
			],
			[
				-33,
				-100
			],
			[
				-155,
				-811
			]
		],
		[
			[
				70909,
				56822
			],
			[
				16,
				61
			],
			[
				-108,
				564
			],
			[
				-4,
				375
			],
			[
				-65,
				143
			],
			[
				-18,
				192
			]
		],
		[
			[
				70500,
				58178
			],
			[
				8,
				-408
			],
			[
				24,
				-90
			],
			[
				-93,
				-257
			],
			[
				60,
				-235
			],
			[
				-240,
				-27
			]
		],
		[
			[
				70651,
				44384
			],
			[
				324,
				-53
			],
			[
				-7,
				-112
			],
			[
				237,
				-50
			]
		],
		[
			[
				71205,
				44169
			],
			[
				129,
				-28
			]
		],
		[
			[
				71334,
				44141
			],
			[
				64,
				1121
			]
		],
		[
			[
				71178,
				45308
			],
			[
				-425,
				69
			],
			[
				-2,
				-24
			]
		],
		[
			[
				70631,
				44896
			],
			[
				-15,
				-198
			],
			[
				57,
				-179
			],
			[
				-22,
				-135
			]
		],
		[
			[
				60855,
				46887
			],
			[
				199,
				-4
			]
		],
		[
			[
				61420,
				47562
			],
			[
				-30,
				158
			],
			[
				-41,
				614
			],
			[
				51,
				196
			],
			[
				-130,
				393
			],
			[
				-1,
				275
			]
		],
		[
			[
				71812,
				41167
			],
			[
				39,
				611
			]
		],
		[
			[
				71175,
				41299
			],
			[
				-7,
				-112
			],
			[
				638,
				-130
			]
		],
		[
			[
				62470,
				39721
			],
			[
				703,
				-56
			]
		],
		[
			[
				62944,
				41139
			],
			[
				-162,
				-53
			],
			[
				-65,
				-129
			],
			[
				-283,
				-224
			]
		],
		[
			[
				93633,
				21082
			],
			[
				-123,
				-453
			],
			[
				301,
				-225
			],
			[
				-94,
				-382
			],
			[
				29,
				-198
			],
			[
				-70,
				-82
			],
			[
				-119,
				-400
			]
		],
		[
			[
				62435,
				38378
			],
			[
				8,
				335
			],
			[
				495,
				-33
			]
		],
		[
			[
				15053,
				61946
			],
			[
				65,
				-238
			],
			[
				134,
				-9
			],
			[
				16,
				-133
			]
		],
		[
			[
				15268,
				61566
			],
			[
				263,
				103
			],
			[
				-20,
				49
			],
			[
				213,
				247
			],
			[
				-8,
				64
			],
			[
				378,
				166
			],
			[
				1710,
				647
			]
		],
		[
			[
				17804,
				62842
			],
			[
				-158,
				1324
			],
			[
				-40,
				-14
			],
			[
				-221,
				1712
			]
		],
		[
			[
				17385,
				65864
			],
			[
				-1904,
				-385
			],
			[
				20,
				-358
			],
			[
				-74,
				-273
			],
			[
				-100,
				39
			],
			[
				40,
				-513
			],
			[
				-27,
				-85
			],
			[
				79,
				-175
			],
			[
				16,
				-538
			],
			[
				-31,
				-444
			],
			[
				-215,
				-922
			],
			[
				-136,
				-264
			]
		],
		[
			[
				76888,
				60429
			],
			[
				141,
				-45
			],
			[
				52,
				135
			],
			[
				139,
				166
			],
			[
				7,
				77
			],
			[
				168,
				185
			]
		],
		[
			[
				77395,
				60947
			],
			[
				-120,
				31
			]
		],
		[
			[
				77275,
				60978
			],
			[
				-125,
				51
			],
			[
				-570,
				123
			]
		],
		[
			[
				57445,
				60514
			],
			[
				662,
				13
			],
			[
				0,
				-55
			],
			[
				291,
				4
			]
		],
		[
			[
				58398,
				60476
			],
			[
				93,
				1
			]
		],
		[
			[
				58491,
				60477
			],
			[
				0,
				112
			],
			[
				-94,
				-1
			],
			[
				-33,
				276
			],
			[
				-161,
				-1
			],
			[
				-1,
				336
			],
			[
				96,
				1
			],
			[
				-8,
				258
			],
			[
				-79,
				242
			]
		],
		[
			[
				57569,
				61919
			],
			[
				-83,
				-941
			]
		],
		[
			[
				57486,
				60978
			],
			[
				-41,
				-464
			]
		],
		[
			[
				88925,
				30611
			],
			[
				117,
				110
			],
			[
				-12,
				152
			],
			[
				93,
				50
			],
			[
				76,
				510
			],
			[
				-28,
				67
			]
		],
		[
			[
				88264,
				26839
			],
			[
				53,
				-53
			]
		],
		[
			[
				88317,
				26786
			],
			[
				13,
				87
			],
			[
				111,
				-55
			],
			[
				38,
				-206
			],
			[
				350,
				195
			],
			[
				8,
				-293
			],
			[
				201,
				61
			]
		],
		[
			[
				89038,
				26575
			],
			[
				199,
				55
			]
		],
		[
			[
				89237,
				26630
			],
			[
				9,
				307
			],
			[
				121,
				442
			],
			[
				-74,
				459
			]
		],
		[
			[
				88267,
				29034
			],
			[
				-18,
				-256
			],
			[
				36,
				-132
			],
			[
				-80,
				-239
			],
			[
				25,
				-302
			],
			[
				50,
				-74
			],
			[
				-51,
				-390
			],
			[
				20,
				-294
			]
		],
		[
			[
				22123,
				43694
			],
			[
				1612,
				545
			]
		],
		[
			[
				23735,
				44239
			],
			[
				-45,
				395
			]
		],
		[
			[
				23273,
				48303
			],
			[
				-254,
				2291
			]
		],
		[
			[
				22952,
				51190
			],
			[
				-1237,
				-417
			],
			[
				4,
				-37
			],
			[
				-1698,
				-595
			],
			[
				-5,
				43
			],
			[
				-270,
				-99
			]
		],
		[
			[
				19746,
				50085
			],
			[
				572,
				-4560
			],
			[
				1524,
				540
			],
			[
				281,
				-2371
			]
		],
		[
			[
				56134,
				47863
			],
			[
				11,
				-328
			],
			[
				-7,
				-1120
			]
		],
		[
			[
				88425,
				47550
			],
			[
				107,
				22
			],
			[
				306,
				182
			]
		],
		[
			[
				88838,
				47754
			],
			[
				26,
				363
			],
			[
				83,
				48
			]
		],
		[
			[
				88947,
				48165
			],
			[
				-54,
				153
			],
			[
				148,
				267
			],
			[
				60,
				23
			],
			[
				-243,
				217
			]
		],
		[
			[
				88858,
				48825
			],
			[
				-206,
				-210
			],
			[
				-133,
				-219
			]
		],
		[
			[
				83455,
				58802
			],
			[
				24,
				-154
			],
			[
				-108,
				-272
			],
			[
				41,
				-455
			],
			[
				-87,
				-94
			],
			[
				-48,
				-211
			],
			[
				-149,
				-133
			]
		],
		[
			[
				57327,
				59193
			],
			[
				288,
				3
			],
			[
				1,
				-167
			],
			[
				95,
				-1
			],
			[
				3,
				-280
			],
			[
				564,
				7
			],
			[
				16,
				-86
			],
			[
				215,
				-26
			]
		],
		[
			[
				58509,
				58643
			],
			[
				-94,
				559
			],
			[
				-1,
				995
			],
			[
				-16,
				279
			]
		],
		[
			[
				57445,
				60514
			],
			[
				-118,
				-1321
			]
		],
		[
			[
				78384,
				55117
			],
			[
				-31,
				91
			]
		],
		[
			[
				78353,
				55208
			],
			[
				-184,
				225
			],
			[
				-46,
				-14
			],
			[
				-121,
				191
			],
			[
				-40,
				-14
			],
			[
				-129,
				284
			],
			[
				-99,
				130
			]
		],
		[
			[
				79042,
				55654
			],
			[
				-139,
				417
			],
			[
				-158,
				61
			],
			[
				-103,
				230
			]
		],
		[
			[
				78642,
				56362
			],
			[
				-109,
				-169
			],
			[
				-52,
				-1021
			],
			[
				-128,
				36
			]
		],
		[
			[
				86439,
				48957
			],
			[
				-16,
				210
			],
			[
				152,
				77
			],
			[
				-3,
				116
			],
			[
				96,
				108
			],
			[
				89,
				-136
			],
			[
				102,
				88
			]
		],
		[
			[
				85841,
				49632
			],
			[
				-37,
				-270
			]
		],
		[
			[
				80024,
				55957
			],
			[
				-51,
				-211
			],
			[
				-157,
				-146
			],
			[
				12,
				-189
			],
			[
				-51,
				-76
			]
		],
		[
			[
				83363,
				61873
			],
			[
				-127,
				132
			],
			[
				33,
				132
			],
			[
				-121,
				69
			],
			[
				8,
				261
			],
			[
				-67,
				246
			]
		],
		[
			[
				83089,
				62713
			],
			[
				-156,
				313
			],
			[
				-66,
				19
			]
		],
		[
			[
				82867,
				63045
			],
			[
				-176,
				169
			],
			[
				-313,
				-356
			],
			[
				48,
				-351
			]
		],
		[
			[
				13092,
				13610
			],
			[
				51,
				-90
			],
			[
				146,
				129
			],
			[
				62,
				-373
			]
		],
		[
			[
				13351,
				13276
			],
			[
				144,
				73
			],
			[
				28,
				-100
			],
			[
				1239,
				599
			]
		],
		[
			[
				53100,
				45843
			],
			[
				260,
				5
			]
		],
		[
			[
				53360,
				45848
			],
			[
				384,
				5
			]
		],
		[
			[
				53744,
				45853
			],
			[
				-83,
				119
			],
			[
				-64,
				226
			],
			[
				-73,
				64
			],
			[
				-18,
				252
			],
			[
				59,
				104
			],
			[
				81,
				301
			],
			[
				131,
				198
			],
			[
				100,
				276
			],
			[
				78,
				-90
			],
			[
				114,
				71
			]
		],
		[
			[
				54069,
				47374
			],
			[
				-4,
				500
			],
			[
				-190,
				-3
			]
		],
		[
			[
				53875,
				47871
			],
			[
				0,
				-113
			],
			[
				-352,
				-5
			],
			[
				1,
				-70
			],
			[
				-250,
				8
			],
			[
				5,
				-504
			],
			[
				-191,
				-7
			]
		],
		[
			[
				88152,
				56554
			],
			[
				71,
				23
			],
			[
				80,
				-228
			],
			[
				83,
				85
			],
			[
				15,
				-164
			],
			[
				72,
				-136
			]
		],
		[
			[
				89069,
				56579
			],
			[
				-94,
				241
			],
			[
				-25,
				191
			],
			[
				115,
				151
			],
			[
				-15,
				234
			]
		],
		[
			[
				89050,
				57396
			],
			[
				-50,
				-34
			],
			[
				35,
				207
			],
			[
				171,
				127
			],
			[
				249,
				59
			],
			[
				118,
				-205
			]
		],
		[
			[
				89573,
				57550
			],
			[
				71,
				84
			],
			[
				-42,
				271
			],
			[
				-102,
				123
			],
			[
				-225,
				87
			],
			[
				-115,
				174
			],
			[
				-236,
				113
			]
		],
		[
			[
				88924,
				58402
			],
			[
				58,
				-147
			],
			[
				-108,
				53
			],
			[
				-69,
				-724
			],
			[
				-44,
				-193
			],
			[
				-105,
				107
			],
			[
				-30,
				-89
			],
			[
				-281,
				-172
			],
			[
				-291,
				-213
			]
		],
		[
			[
				86000,
				64184
			],
			[
				-115,
				382
			],
			[
				-73,
				434
			],
			[
				-10,
				489
			],
			[
				18,
				309
			],
			[
				-88,
				217
			],
			[
				-76,
				81
			]
		],
		[
			[
				85331,
				65791
			],
			[
				-57,
				-47
			],
			[
				-400,
				-174
			]
		],
		[
			[
				84874,
				65570
			],
			[
				-13,
				-119
			],
			[
				65,
				-159
			],
			[
				53,
				-295
			],
			[
				74,
				-231
			],
			[
				157,
				-343
			],
			[
				-21,
				-85
			],
			[
				24,
				-370
			],
			[
				132,
				-396
			]
		],
		[
			[
				48859,
				65866
			],
			[
				30,
				472
			],
			[
				94,
				-14
			],
			[
				172,
				91
			],
			[
				168,
				-47
			]
		],
		[
			[
				48333,
				67770
			],
			[
				21,
				-968
			]
		],
		[
			[
				71334,
				44141
			],
			[
				504,
				-108
			]
		],
		[
			[
				71907,
				45149
			],
			[
				-226,
				53
			]
		],
		[
			[
				81622,
				76132
			],
			[
				73,
				90
			],
			[
				115,
				-19
			],
			[
				183,
				128
			],
			[
				129,
				-25
			],
			[
				221,
				56
			],
			[
				93,
				-74
			],
			[
				91,
				39
			]
		],
		[
			[
				82527,
				76327
			],
			[
				36,
				36
			],
			[
				4,
				375
			],
			[
				31,
				345
			]
		],
		[
			[
				81524,
				78277
			],
			[
				-31,
				-337
			]
		],
		[
			[
				81493,
				77940
			],
			[
				25,
				-457
			],
			[
				-6,
				-326
			],
			[
				-114,
				-328
			],
			[
				5,
				-510
			],
			[
				98,
				7
			],
			[
				29,
				-197
			],
			[
				92,
				3
			]
		],
		[
			[
				65671,
				47421
			],
			[
				23,
				480
			],
			[
				64,
				-8
			],
			[
				24,
				505
			]
		],
		[
			[
				65606,
				48758
			],
			[
				-819,
				70
			]
		],
		[
			[
				64787,
				48828
			],
			[
				-60,
				-193
			],
			[
				70,
				-242
			]
		],
		[
			[
				64877,
				48256
			],
			[
				1,
				-168
			],
			[
				-290,
				-257
			]
		],
		[
			[
				55790,
				41940
			],
			[
				-659,
				-13
			]
		],
		[
			[
				55131,
				41927
			],
			[
				24,
				-92
			],
			[
				-64,
				-194
			],
			[
				-90,
				-69
			],
			[
				-74,
				-249
			],
			[
				88,
				-161
			]
		],
		[
			[
				73714,
				55377
			],
			[
				57,
				-471
			],
			[
				27,
				-433
			]
		],
		[
			[
				74063,
				54404
			],
			[
				132,
				372
			],
			[
				-18,
				152
			],
			[
				61,
				351
			],
			[
				45,
				26
			]
		],
		[
			[
				44721,
				65635
			],
			[
				-171,
				-18
			]
		],
		[
			[
				44550,
				65617
			],
			[
				-792,
				-84
			]
		],
		[
			[
				76203,
				66434
			],
			[
				127,
				-25
			]
		],
		[
			[
				76215,
				67577
			],
			[
				-19,
				-426
			],
			[
				-165,
				-305
			]
		],
		[
			[
				77084,
				41033
			],
			[
				24,
				720
			]
		],
		[
			[
				77108,
				41753
			],
			[
				2,
				454
			],
			[
				-28,
				115
			]
		],
		[
			[
				76370,
				42422
			],
			[
				-46,
				-398
			],
			[
				40,
				-67
			],
			[
				-52,
				-136
			],
			[
				-32,
				-298
			],
			[
				72,
				-14
			],
			[
				-8,
				-231
			]
		],
		[
			[
				52693,
				78894
			],
			[
				-364,
				363
			],
			[
				-112,
				523
			]
		],
		[
			[
				51241,
				79163
			],
			[
				-154,
				-499
			]
		],
		[
			[
				81178,
				74101
			],
			[
				114,
				226
			],
			[
				-17,
				124
			],
			[
				-202,
				77
			],
			[
				-45,
				180
			],
			[
				-85,
				96
			],
			[
				-175,
				-22
			]
		],
		[
			[
				51630,
				20002
			],
			[
				1124,
				34
			]
		],
		[
			[
				52754,
				20036
			],
			[
				33,
				1
			],
			[
				-9,
				1088
			],
			[
				48,
				19
			],
			[
				-1,
				218
			]
		],
		[
			[
				76532,
				68911
			],
			[
				-407,
				75
			],
			[
				-62,
				103
			]
		],
		[
			[
				80555,
				37109
			],
			[
				19,
				186
			]
		],
		[
			[
				80652,
				38104
			],
			[
				-184,
				140
			],
			[
				-43,
				143
			]
		],
		[
			[
				8659,
				32678
			],
			[
				-16,
				92
			],
			[
				994,
				497
			]
		],
		[
			[
				9637,
				33267
			],
			[
				-16,
				236
			],
			[
				-54,
				11
			],
			[
				-49,
				416
			]
		],
		[
			[
				9466,
				34780
			],
			[
				-40,
				186
			],
			[
				-228,
				-72
			],
			[
				-72,
				25
			],
			[
				-80,
				345
			],
			[
				36,
				363
			],
			[
				-13,
				339
			],
			[
				-124,
				189
			],
			[
				-52,
				-27
			],
			[
				-90,
				364
			],
			[
				41,
				51
			],
			[
				19,
				282
			],
			[
				69,
				32
			],
			[
				1,
				390
			],
			[
				179,
				291
			]
		],
		[
			[
				9112,
				37538
			],
			[
				-428,
				-223
			],
			[
				-100,
				2
			],
			[
				-19,
				113
			],
			[
				-386,
				-185
			],
			[
				-18,
				110
			],
			[
				-216,
				-115
			],
			[
				-63,
				8
			]
		],
		[
			[
				7882,
				37248
			],
			[
				-139,
				-381
			],
			[
				-82,
				-332
			],
			[
				2,
				-147
			],
			[
				123,
				-208
			],
			[
				18,
				-136
			],
			[
				-37,
				-911
			],
			[
				22,
				-351
			],
			[
				69,
				-278
			],
			[
				148,
				-352
			],
			[
				0,
				-209
			],
			[
				43,
				-298
			],
			[
				-35,
				-175
			],
			[
				35,
				-421
			],
			[
				-71,
				-162
			],
			[
				-6,
				-191
			],
			[
				-93,
				-417
			]
		],
		[
			[
				27283,
				27991
			],
			[
				70,
				-656
			]
		],
		[
			[
				29517,
				29008
			],
			[
				-758,
				-227
			]
		],
		[
			[
				28759,
				28781
			],
			[
				-985,
				-291
			],
			[
				-114,
				96
			],
			[
				-29,
				135
			]
		],
		[
			[
				27631,
				28721
			],
			[
				-141,
				302
			],
			[
				-306,
				-94
			],
			[
				99,
				-938
			]
		],
		[
			[
				54287,
				36294
			],
			[
				78,
				47
			],
			[
				-28,
				124
			],
			[
				186,
				121
			],
			[
				-54,
				254
			],
			[
				38,
				152
			]
		],
		[
			[
				54507,
				36992
			],
			[
				115,
				242
			],
			[
				-55,
				290
			],
			[
				44,
				102
			],
			[
				-76,
				71
			]
		],
		[
			[
				54535,
				37697
			],
			[
				-535,
				-5
			]
		],
		[
			[
				54000,
				37692
			],
			[
				1,
				-224
			],
			[
				-183,
				-2
			]
		],
		[
			[
				64461,
				27300
			],
			[
				284,
				-25
			]
		],
		[
			[
				65036,
				29568
			],
			[
				-298,
				40
			]
		],
		[
			[
				64738,
				29608
			],
			[
				-150,
				-531
			],
			[
				-165,
				-292
			],
			[
				-43,
				-193
			],
			[
				45,
				-30
			],
			[
				-42,
				-280
			],
			[
				-52,
				-42
			],
			[
				-44,
				-315
			],
			[
				74,
				-127
			],
			[
				4,
				-171
			],
			[
				77,
				48
			],
			[
				-11,
				-234
			],
			[
				30,
				-141
			]
		],
		[
			[
				12538,
				32794
			],
			[
				434,
				197
			],
			[
				-112,
				707
			],
			[
				74,
				176
			],
			[
				94,
				44
			],
			[
				12,
				120
			],
			[
				97,
				15
			],
			[
				24,
				-157
			],
			[
				145,
				-78
			],
			[
				74,
				-119
			],
			[
				178,
				114
			],
			[
				98,
				220
			],
			[
				99,
				104
			],
			[
				21,
				181
			],
			[
				180,
				442
			],
			[
				151,
				97
			],
			[
				48,
				109
			],
			[
				-27,
				277
			],
			[
				36,
				194
			],
			[
				74,
				149
			],
			[
				-101,
				648
			],
			[
				-94,
				190
			]
		],
		[
			[
				12186,
				33802
			],
			[
				22,
				-162
			],
			[
				144,
				-54
			],
			[
				82,
				-299
			],
			[
				-46,
				-144
			],
			[
				-141,
				-111
			],
			[
				40,
				-269
			],
			[
				-25,
				-99
			]
		],
		[
			[
				70742,
				46730
			],
			[
				625,
				-102
			]
		],
		[
			[
				58115,
				52226
			],
			[
				449,
				15
			],
			[
				-2,
				225
			],
			[
				320,
				7
			]
		],
		[
			[
				82549,
				56716
			],
			[
				-80,
				-133
			],
			[
				-69,
				5
			],
			[
				-195,
				-178
			],
			[
				-137,
				10
			]
		],
		[
			[
				86751,
				37402
			],
			[
				2,
				154
			],
			[
				136,
				200
			]
		],
		[
			[
				86497,
				38507
			],
			[
				-62,
				181
			],
			[
				-347,
				256
			],
			[
				-90,
				229
			]
		],
		[
			[
				77883,
				53123
			],
			[
				34,
				166
			],
			[
				-25,
				148
			]
		],
		[
			[
				77892,
				53437
			],
			[
				-150,
				174
			],
			[
				-178,
				54
			],
			[
				-82,
				303
			],
			[
				4,
				149
			],
			[
				-291,
				191
			],
			[
				-192,
				215
			],
			[
				-65,
				25
			]
		],
		[
			[
				61253,
				82140
			],
			[
				130,
				-98
			],
			[
				101,
				134
			],
			[
				163,
				104
			],
			[
				89,
				-72
			],
			[
				169,
				-321
			]
		],
		[
			[
				62533,
				82267
			],
			[
				-51,
				205
			],
			[
				56,
				574
			]
		],
		[
			[
				62538,
				83046
			],
			[
				-9,
				129
			],
			[
				-261,
				250
			],
			[
				-42,
				-104
			],
			[
				-122,
				62
			],
			[
				65,
				199
			],
			[
				128,
				18
			],
			[
				-62,
				103
			],
			[
				60,
				201
			],
			[
				141,
				-78
			],
			[
				-57,
				110
			],
			[
				47,
				62
			],
			[
				-226,
				36
			],
			[
				-178,
				173
			],
			[
				-138,
				40
			],
			[
				-576,
				-173
			]
		],
		[
			[
				61038,
				82364
			],
			[
				215,
				-224
			]
		],
		[
			[
				66141,
				15469
			],
			[
				127,
				-342
			],
			[
				344,
				-378
			],
			[
				303,
				-126
			],
			[
				270,
				-16
			],
			[
				136,
				107
			],
			[
				15,
				126
			],
			[
				-155,
				105
			],
			[
				-215,
				22
			],
			[
				37,
				166
			],
			[
				-169,
				193
			],
			[
				-150,
				334
			],
			[
				-95,
				75
			]
		],
		[
			[
				64918,
				13379
			],
			[
				106,
				-237
			],
			[
				496,
				-525
			],
			[
				39,
				0
			],
			[
				216,
				-347
			],
			[
				159,
				-157
			],
			[
				35,
				32
			],
			[
				148,
				-118
			],
			[
				-214,
				434
			],
			[
				2,
				98
			],
			[
				-128,
				196
			],
			[
				-335,
				265
			],
			[
				-149,
				189
			],
			[
				141,
				48
			],
			[
				-310,
				277
			],
			[
				-100,
				1
			],
			[
				-106,
				-156
			]
		],
		[
			[
				33420,
				73685
			],
			[
				-1711,
				-363
			]
		],
		[
			[
				55062,
				80278
			],
			[
				51,
				1120
			],
			[
				1,
				353
			]
		],
		[
			[
				55114,
				81751
			],
			[
				-557,
				78
			]
		],
		[
			[
				54557,
				81829
			],
			[
				-2,
				-322
			],
			[
				-116,
				-80
			]
		],
		[
			[
				39752,
				8382
			],
			[
				1557,
				270
			]
		],
		[
			[
				75078,
				78576
			],
			[
				81,
				1395
			]
		],
		[
			[
				75159,
				79971
			],
			[
				70,
				1051
			]
		],
		[
			[
				75229,
				81022
			],
			[
				-199,
				-104
			],
			[
				-242,
				-320
			],
			[
				-193,
				-120
			],
			[
				-165,
				-198
			],
			[
				-211,
				-190
			],
			[
				-239,
				-162
			]
		],
		[
			[
				47864,
				78303
			],
			[
				37,
				-1551
			]
		],
		[
			[
				47901,
				76752
			],
			[
				122,
				40
			],
			[
				44,
				230
			],
			[
				209,
				-70
			],
			[
				163,
				149
			],
			[
				151,
				-272
			],
			[
				66,
				65
			]
		],
		[
			[
				48869,
				76930
			],
			[
				-41,
				1993
			]
		],
		[
			[
				75761,
				29575
			],
			[
				100,
				986
			]
		],
		[
			[
				75670,
				30626
			],
			[
				-563,
				161
			]
		],
		[
			[
				75107,
				30787
			],
			[
				-113,
				-1303
			]
		],
		[
			[
				74994,
				29484
			],
			[
				171,
				-86
			],
			[
				-30,
				-330
			],
			[
				374,
				-105
			]
		],
		[
			[
				54329,
				45857
			],
			[
				-585,
				-4
			]
		],
		[
			[
				53360,
				45848
			],
			[
				10,
				-1678
			]
		],
		[
			[
				48529,
				9513
			],
			[
				974,
				68
			]
		],
		[
			[
				49503,
				9581
			],
			[
				-23,
				1060
			],
			[
				49,
				3
			],
			[
				-14,
				656
			]
		],
		[
			[
				48540,
				11232
			],
			[
				17,
				-656
			],
			[
				-56,
				-5
			],
			[
				28,
				-1058
			]
		],
		[
			[
				40748,
				20818
			],
			[
				66,
				11
			],
			[
				74,
				-1329
			],
			[
				83,
				13
			]
		],
		[
			[
				51647,
				44126
			],
			[
				767,
				22
			]
		],
		[
			[
				52414,
				44148
			],
			[
				-10,
				1343
			]
		],
		[
			[
				51444,
				45463
			],
			[
				16,
				-1343
			]
		],
		[
			[
				63207,
				16893
			],
			[
				34,
				-273
			],
			[
				63,
				-21
			],
			[
				-19,
				246
			],
			[
				-78,
				48
			]
		],
		[
			[
				62931,
				17215
			],
			[
				183,
				-183
			],
			[
				-24,
				205
			],
			[
				-134,
				46
			],
			[
				-25,
				-68
			]
		],
		[
			[
				62728,
				16857
			],
			[
				189,
				-134
			],
			[
				70,
				174
			],
			[
				69,
				-120
			],
			[
				17,
				143
			],
			[
				-85,
				38
			],
			[
				-90,
				170
			],
			[
				-170,
				-271
			]
		],
		[
			[
				62735,
				17769
			],
			[
				88,
				-67
			],
			[
				169,
				-299
			],
			[
				76,
				96
			],
			[
				-160,
				124
			],
			[
				32,
				111
			],
			[
				-90,
				22
			],
			[
				-107,
				133
			],
			[
				-8,
				-120
			]
		],
		[
			[
				62550,
				18548
			],
			[
				257,
				-257
			],
			[
				22,
				-178
			],
			[
				303,
				384
			]
		],
		[
			[
				63132,
				18497
			],
			[
				39,
				1306
			],
			[
				196,
				-17
			],
			[
				10,
				329
			],
			[
				191,
				-23
			],
			[
				25,
				661
			]
		],
		[
			[
				63011,
				20810
			],
			[
				-386,
				38
			],
			[
				-19,
				-661
			]
		],
		[
			[
				62606,
				20187
			],
			[
				-56,
				-1639
			]
		],
		[
			[
				63963,
				82198
			],
			[
				225,
				-162
			]
		],
		[
			[
				64188,
				82036
			],
			[
				191,
				-81
			],
			[
				83,
				41
			]
		],
		[
			[
				64632,
				82598
			],
			[
				-68,
				302
			],
			[
				-144,
				447
			]
		],
		[
			[
				64241,
				83422
			],
			[
				3,
				-389
			],
			[
				-39,
				-95
			],
			[
				-141,
				-62
			],
			[
				-154,
				-467
			]
		],
		[
			[
				63910,
				82409
			],
			[
				53,
				-211
			]
		],
		[
			[
				60864,
				18223
			],
			[
				114,
				93
			],
			[
				188,
				-53
			],
			[
				406,
				-282
			]
		],
		[
			[
				61572,
				17981
			],
			[
				63,
				2280
			]
		],
		[
			[
				84874,
				65570
			],
			[
				-154,
				-51
			],
			[
				-62,
				-133
			],
			[
				-146,
				-91
			],
			[
				-92,
				-172
			],
			[
				-158,
				-138
			],
			[
				-237,
				84
			]
		],
		[
			[
				71185,
				56688
			],
			[
				74,
				233
			],
			[
				152,
				183
			],
			[
				12,
				127
			],
			[
				82,
				24
			]
		],
		[
			[
				71505,
				57255
			],
			[
				92,
				411
			],
			[
				80,
				120
			]
		],
		[
			[
				71677,
				57786
			],
			[
				-52,
				59
			],
			[
				-14,
				188
			],
			[
				-86,
				131
			],
			[
				-2,
				162
			]
		],
		[
			[
				78562,
				74820
			],
			[
				-42,
				103
			],
			[
				-99,
				18
			]
		],
		[
			[
				78421,
				74941
			],
			[
				-267,
				47
			]
		],
		[
			[
				72288,
				41074
			],
			[
				79,
				1158
			]
		],
		[
			[
				51095,
				67690
			],
			[
				204,
				163
			],
			[
				59,
				-30
			],
			[
				220,
				-339
			],
			[
				54,
				-149
			],
			[
				100,
				24
			],
			[
				80,
				109
			],
			[
				-14,
				153
			],
			[
				64,
				64
			]
		],
		[
			[
				51184,
				69449
			],
			[
				-113,
				-3
			],
			[
				2,
				-129
			]
		],
		[
			[
				84695,
				26527
			],
			[
				414,
				-182
			],
			[
				261,
				-22
			],
			[
				67,
				-106
			],
			[
				166,
				-128
			],
			[
				99,
				-168
			]
		],
		[
			[
				85702,
				25921
			],
			[
				151,
				980
			],
			[
				7,
				218
			]
		],
		[
			[
				85860,
				27119
			],
			[
				-393,
				180
			]
		],
		[
			[
				79361,
				59708
			],
			[
				-78,
				-71
			],
			[
				-61,
				201
			],
			[
				-94,
				4
			],
			[
				-147,
				148
			]
		],
		[
			[
				82197,
				69242
			],
			[
				424,
				-880
			],
			[
				150,
				215
			],
			[
				-8,
				177
			],
			[
				111,
				51
			],
			[
				47,
				-166
			]
		],
		[
			[
				83112,
				70848
			],
			[
				2,
				176
			]
		],
		[
			[
				83114,
				71024
			],
			[
				-79,
				-6
			],
			[
				-170,
				-185
			],
			[
				-108,
				71
			],
			[
				-103,
				-78
			],
			[
				-27,
				-271
			],
			[
				-66,
				-129
			]
		],
		[
			[
				41937,
				73911
			],
			[
				-499,
				-67
			]
		],
		[
			[
				41438,
				73844
			],
			[
				72,
				-1668
			]
		],
		[
			[
				79242,
				61189
			],
			[
				-6,
				-146
			],
			[
				-82,
				-222
			],
			[
				-67,
				-358
			],
			[
				-99,
				53
			],
			[
				-10,
				-253
			],
			[
				-281,
				169
			],
			[
				-33,
				-71
			]
		],
		[
			[
				89188,
				51428
			],
			[
				311,
				-110
			]
		],
		[
			[
				89499,
				51318
			],
			[
				327,
				371
			],
			[
				109,
				232
			],
			[
				110,
				-49
			],
			[
				214,
				216
			],
			[
				-5,
				119
			]
		],
		[
			[
				90254,
				52207
			],
			[
				33,
				215
			],
			[
				105,
				95
			],
			[
				-206,
				-12
			],
			[
				-249,
				-309
			],
			[
				-96,
				14
			]
		],
		[
			[
				89207,
				51580
			],
			[
				-105,
				-119
			]
		],
		[
			[
				89312,
				50050
			],
			[
				-27,
				-268
			],
			[
				100,
				-56
			],
			[
				174,
				76
			]
		],
		[
			[
				89516,
				50170
			],
			[
				-103,
				108
			]
		],
		[
			[
				89413,
				50278
			],
			[
				-101,
				-228
			]
		],
		[
			[
				58533,
				57624
			],
			[
				17,
				615
			],
			[
				84,
				123
			]
		],
		[
			[
				58634,
				58362
			],
			[
				-96,
				0
			],
			[
				3,
				280
			],
			[
				-32,
				1
			]
		],
		[
			[
				57327,
				59193
			],
			[
				-21,
				-230
			]
		],
		[
			[
				57306,
				58963
			],
			[
				-110,
				-1299
			]
		],
		[
			[
				62328,
				77339
			],
			[
				-45,
				-1804
			],
			[
				-7,
				-503
			]
		],
		[
			[
				62276,
				75032
			],
			[
				191,
				-15
			],
			[
				23,
				-171
			]
		],
		[
			[
				62490,
				74846
			],
			[
				87,
				64
			],
			[
				81,
				225
			],
			[
				144,
				30
			],
			[
				107,
				-173
			],
			[
				38,
				-176
			],
			[
				95,
				-5
			],
			[
				9,
				334
			],
			[
				40,
				-3
			]
		],
		[
			[
				62664,
				77542
			],
			[
				-145,
				-106
			],
			[
				-69,
				101
			],
			[
				24,
				127
			],
			[
				-103,
				58
			],
			[
				-43,
				-383
			]
		],
		[
			[
				58089,
				50119
			],
			[
				-331,
				-1
			],
			[
				-97,
				-79
			],
			[
				-521,
				-10
			]
		],
		[
			[
				9112,
				37538
			],
			[
				92,
				230
			],
			[
				36,
				392
			],
			[
				72,
				55
			],
			[
				7,
				159
			]
		],
		[
			[
				9319,
				38374
			],
			[
				-74,
				240
			],
			[
				18,
				163
			],
			[
				103,
				213
			],
			[
				-7,
				198
			],
			[
				67,
				101
			],
			[
				-42,
				93
			],
			[
				55,
				208
			],
			[
				53,
				557
			],
			[
				-62,
				328
			],
			[
				-61,
				-21
			]
		],
		[
			[
				9376,
				40513
			],
			[
				-176,
				42
			]
		],
		[
			[
				8461,
				39443
			],
			[
				-97,
				-75
			],
			[
				29,
				-218
			],
			[
				-44,
				-386
			],
			[
				-162,
				-325
			],
			[
				-102,
				-271
			],
			[
				-117,
				-669
			],
			[
				-86,
				-251
			]
		],
		[
			[
				62607,
				32333
			],
			[
				153,
				-31
			],
			[
				190,
				-284
			],
			[
				151,
				-74
			],
			[
				162,
				-192
			],
			[
				117,
				-197
			]
		],
		[
			[
				63380,
				31555
			],
			[
				191,
				-171
			],
			[
				188,
				22
			]
		],
		[
			[
				63759,
				31406
			],
			[
				56,
				1488
			]
		],
		[
			[
				63067,
				33496
			],
			[
				-135,
				-70
			],
			[
				-148,
				-196
			],
			[
				-73,
				-502
			],
			[
				-75,
				-81
			],
			[
				-29,
				-314
			]
		],
		[
			[
				65552,
				59037
			],
			[
				404,
				-51
			]
		],
		[
			[
				93663,
				27959
			],
			[
				-599,
				244
			]
		],
		[
			[
				92191,
				28553
			],
			[
				-87,
				-392
			],
			[
				60,
				-8
			],
			[
				-9,
				-423
			],
			[
				-26,
				-232
			]
		],
		[
			[
				78363,
				52117
			],
			[
				169,
				-202
			],
			[
				157,
				-122
			],
			[
				61,
				-134
			]
		],
		[
			[
				79123,
				52557
			],
			[
				-80,
				62
			],
			[
				-95,
				187
			],
			[
				-116,
				100
			]
		],
		[
			[
				78832,
				52906
			],
			[
				-55,
				72
			],
			[
				-205,
				-208
			],
			[
				-171,
				-359
			],
			[
				-38,
				-294
			]
		],
		[
			[
				37505,
				38731
			],
			[
				-891,
				-185
			]
		],
		[
			[
				70060,
				71879
			],
			[
				-78,
				-18
			]
		],
		[
			[
				75688,
				49899
			],
			[
				271,
				59
			],
			[
				74,
				-87
			]
		],
		[
			[
				76033,
				49871
			],
			[
				39,
				79
			],
			[
				227,
				224
			]
		],
		[
			[
				75652,
				50269
			],
			[
				-5,
				-311
			],
			[
				41,
				-59
			]
		],
		[
			[
				75684,
				79872
			],
			[
				-525,
				99
			]
		],
		[
			[
				79995,
				69006
			],
			[
				62,
				-74
			],
			[
				-48,
				-122
			],
			[
				180,
				-137
			]
		],
		[
			[
				80847,
				69397
			],
			[
				-31,
				273
			]
		],
		[
			[
				86156,
				88654
			],
			[
				298,
				856
			],
			[
				46,
				172
			]
		],
		[
			[
				84910,
				90175
			],
			[
				354,
				-724
			],
			[
				-31,
				-316
			]
		],
		[
			[
				36353,
				58867
			],
			[
				154,
				-253
			],
			[
				-134,
				-67
			]
		],
		[
			[
				36377,
				58484
			],
			[
				24,
				-288
			]
		],
		[
			[
				37353,
				58182
			],
			[
				-30,
				413
			]
		],
		[
			[
				37323,
				58595
			],
			[
				-180,
				3183
			]
		],
		[
			[
				62346,
				42917
			],
			[
				-8,
				187
			]
		],
		[
			[
				62484,
				44078
			],
			[
				-687,
				42
			]
		],
		[
			[
				17804,
				62842
			],
			[
				1120,
				400
			],
			[
				1157,
				392
			],
			[
				372,
				114
			]
		],
		[
			[
				20477,
				65348
			],
			[
				46,
				485
			],
			[
				-38,
				233
			],
			[
				-139,
				165
			],
			[
				-6,
				131
			],
			[
				-171,
				33
			],
			[
				-134,
				-60
			],
			[
				-65,
				52
			]
		],
		[
			[
				19970,
				66387
			],
			[
				-2585,
				-523
			]
		],
		[
			[
				63262,
				72438
			],
			[
				88,
				-113
			],
			[
				-12,
				-321
			],
			[
				97,
				-229
			],
			[
				-2,
				-274
			],
			[
				47,
				-265
			],
			[
				-3,
				-176
			],
			[
				53,
				-65
			],
			[
				22,
				-210
			]
		],
		[
			[
				63736,
				70773
			],
			[
				-81,
				291
			],
			[
				120,
				99
			],
			[
				21,
				-301
			],
			[
				91,
				5
			],
			[
				40,
				333
			],
			[
				-131,
				181
			],
			[
				-30,
				127
			],
			[
				14,
				265
			],
			[
				192,
				51
			],
			[
				-41,
				137
			],
			[
				-128,
				205
			],
			[
				70,
				208
			],
			[
				126,
				-96
			],
			[
				40,
				-155
			],
			[
				46,
				88
			],
			[
				-71,
				184
			]
		],
		[
			[
				64014,
				72395
			],
			[
				-47,
				57
			]
		],
		[
			[
				63244,
				72609
			],
			[
				18,
				-171
			]
		],
		[
			[
				64604,
				79480
			],
			[
				-775,
				122
			],
			[
				-76,
				221
			]
		],
		[
			[
				63753,
				79823
			],
			[
				-24,
				-15
			]
		],
		[
			[
				63729,
				79808
			],
			[
				-26,
				-172
			],
			[
				151,
				-380
			],
			[
				60,
				-650
			],
			[
				32,
				-141
			]
		],
		[
			[
				61817,
				30428
			],
			[
				633,
				-53
			]
		],
		[
			[
				62450,
				30375
			],
			[
				-20,
				166
			],
			[
				49,
				130
			]
		],
		[
			[
				62479,
				30671
			],
			[
				-9,
				220
			],
			[
				180,
				186
			],
			[
				87,
				216
			],
			[
				-179,
				491
			],
			[
				6,
				198
			]
		],
		[
			[
				62564,
				31982
			],
			[
				-695,
				52
			]
		],
		[
			[
				62564,
				31982
			],
			[
				43,
				351
			]
		],
		[
			[
				61912,
				33716
			],
			[
				-43,
				-1682
			]
		],
		[
			[
				61350,
				27803
			],
			[
				243,
				260
			],
			[
				91,
				182
			],
			[
				161,
				164
			]
		],
		[
			[
				61845,
				28409
			],
			[
				219,
				140
			]
		],
		[
			[
				62305,
				29056
			],
			[
				-716,
				58
			]
		],
		[
			[
				55225,
				42164
			],
			[
				-89,
				-18
			],
			[
				-5,
				-219
			]
		],
		[
			[
				89110,
				18149
			],
			[
				1042,
				-486
			]
		],
		[
			[
				90404,
				19387
			],
			[
				-188,
				114
			],
			[
				-24,
				213
			],
			[
				-243,
				277
			],
			[
				-367,
				195
			]
		],
		[
			[
				89582,
				20186
			],
			[
				-111,
				-532
			],
			[
				-20,
				13
			],
			[
				-100,
				-488
			],
			[
				-30,
				18
			],
			[
				-211,
				-1048
			]
		],
		[
			[
				86577,
				30887
			],
			[
				665,
				-234
			]
		],
		[
			[
				86348,
				32580
			],
			[
				-102,
				9
			]
		],
		[
			[
				85979,
				31091
			],
			[
				598,
				-204
			]
		],
		[
			[
				65660,
				39991
			],
			[
				-131,
				508
			],
			[
				19,
				176
			]
		],
		[
			[
				65548,
				40675
			],
			[
				4,
				179
			],
			[
				-92,
				152
			],
			[
				-63,
				251
			],
			[
				6,
				131
			],
			[
				-256,
				130
			],
			[
				-96,
				119
			]
		],
		[
			[
				64933,
				44012
			],
			[
				381,
				-44
			],
			[
				112,
				-68
			],
			[
				-2,
				-226
			],
			[
				203,
				-26
			]
		],
		[
			[
				66264,
				44202
			],
			[
				-51,
				-43
			],
			[
				-88,
				204
			],
			[
				-148,
				142
			],
			[
				-57,
				-64
			],
			[
				12,
				302
			],
			[
				-92,
				9
			],
			[
				8,
				166
			],
			[
				-97,
				10
			],
			[
				21,
				449
			]
		],
		[
			[
				61678,
				74232
			],
			[
				-10,
				-504
			]
		],
		[
			[
				61668,
				73728
			],
			[
				521,
				-35
			]
		],
		[
			[
				62189,
				73693
			],
			[
				77,
				-4
			],
			[
				-18,
				185
			],
			[
				53,
				45
			],
			[
				-1,
				191
			],
			[
				132,
				-19
			],
			[
				31,
				65
			]
		],
		[
			[
				62463,
				74156
			],
			[
				56,
				227
			],
			[
				-99,
				104
			],
			[
				47,
				97
			],
			[
				23,
				262
			]
		],
		[
			[
				62276,
				75032
			],
			[
				-579,
				40
			]
		],
		[
			[
				64909,
				54018
			],
			[
				22,
				881
			],
			[
				199,
				-19
			],
			[
				4,
				173
			]
		],
		[
			[
				65134,
				55053
			],
			[
				-65,
				5
			],
			[
				3,
				132
			],
			[
				-125,
				178
			],
			[
				-56,
				171
			]
		],
		[
			[
				63954,
				55135
			],
			[
				-9,
				-337
			],
			[
				70,
				-117
			],
			[
				-10,
				-404
			]
		],
		[
			[
				60378,
				45520
			],
			[
				-3,
				1115
			]
		],
		[
			[
				59691,
				46434
			],
			[
				-27,
				-128
			],
			[
				-160,
				-86
			],
			[
				-113,
				-160
			],
			[
				13,
				-253
			],
			[
				-25,
				-253
			]
		],
		[
			[
				64994,
				52916
			],
			[
				500,
				-61
			]
		],
		[
			[
				65494,
				52855
			],
			[
				59,
				1823
			]
		],
		[
			[
				65553,
				54678
			],
			[
				-164,
				14
			],
			[
				12,
				281
			],
			[
				-63,
				62
			],
			[
				-204,
				18
			]
		],
		[
			[
				29225,
				16311
			],
			[
				-2,
				-152
			],
			[
				120,
				-103
			],
			[
				75,
				49
			],
			[
				89,
				-176
			],
			[
				82,
				29
			],
			[
				74,
				-117
			]
		],
		[
			[
				89344,
				39315
			],
			[
				-17,
				-88
			]
		],
		[
			[
				88901,
				39020
			],
			[
				72,
				-272
			],
			[
				90,
				-179
			],
			[
				105,
				-103
			]
		],
		[
			[
				89484,
				38488
			],
			[
				11,
				25
			]
		],
		[
			[
				89382,
				38984
			],
			[
				-112,
				180
			],
			[
				-15,
				218
			],
			[
				98,
				102
			],
			[
				-10,
				332
			],
			[
				190,
				377
			]
		],
		[
			[
				89151,
				40607
			],
			[
				-50,
				-301
			]
		],
		[
			[
				62731,
				45063
			],
			[
				-109,
				-162
			],
			[
				1,
				-114
			]
		],
		[
			[
				77618,
				48239
			],
			[
				101,
				0
			],
			[
				56,
				-109
			],
			[
				153,
				-45
			],
			[
				15,
				62
			]
		],
		[
			[
				77369,
				49388
			],
			[
				-71,
				-151
			]
		],
		[
			[
				88449,
				51683
			],
			[
				3,
				32
			]
		],
		[
			[
				88452,
				51715
			],
			[
				-434,
				140
			]
		],
		[
			[
				58128,
				51017
			],
			[
				-7,
				685
			]
		],
		[
			[
				62996,
				80467
			],
			[
				225,
				-16
			],
			[
				1,
				56
			],
			[
				188,
				-71
			]
		],
		[
			[
				63410,
				80436
			],
			[
				63,
				80
			],
			[
				290,
				560
			],
			[
				325,
				-31
			]
		],
		[
			[
				64088,
				81045
			],
			[
				233,
				-14
			]
		],
		[
			[
				64321,
				81031
			],
			[
				-133,
				1005
			]
		],
		[
			[
				63963,
				82198
			],
			[
				-90,
				-123
			],
			[
				-192,
				18
			]
		],
		[
			[
				63681,
				82093
			],
			[
				-44,
				-162
			],
			[
				-144,
				11
			],
			[
				-36,
				-484
			],
			[
				-169,
				-34
			],
			[
				-120,
				-251
			],
			[
				-50,
				-505
			],
			[
				-122,
				-201
			]
		],
		[
			[
				89334,
				52057
			],
			[
				-173,
				165
			]
		],
		[
			[
				89161,
				52222
			],
			[
				-55,
				135
			],
			[
				-75,
				1
			],
			[
				-84,
				158
			]
		],
		[
			[
				88947,
				52516
			],
			[
				-167,
				-194
			],
			[
				-81,
				14
			],
			[
				-238,
				-104
			],
			[
				-34,
				-226
			],
			[
				59,
				-207
			],
			[
				-34,
				-84
			]
		],
		[
			[
				61845,
				28409
			],
			[
				32,
				-275
			],
			[
				-55,
				-196
			],
			[
				-78,
				-85
			],
			[
				15,
				-150
			],
			[
				110,
				-146
			],
			[
				-36,
				-1338
			]
		],
		[
			[
				39702,
				73943
			],
			[
				153,
				522
			],
			[
				90,
				-61
			],
			[
				42,
				172
			],
			[
				16,
				296
			],
			[
				133,
				138
			],
			[
				62,
				-24
			],
			[
				117,
				180
			],
			[
				18,
				208
			]
		],
		[
			[
				40333,
				75374
			],
			[
				187,
				125
			],
			[
				8,
				641
			],
			[
				62,
				139
			],
			[
				115,
				-6
			],
			[
				118,
				90
			],
			[
				131,
				-43
			],
			[
				26,
				150
			],
			[
				117,
				89
			],
			[
				323,
				38
			]
		],
		[
			[
				40224,
				78765
			],
			[
				-923,
				-1433
			]
		],
		[
			[
				61989,
				65187
			],
			[
				-325,
				9
			]
		],
		[
			[
				67846,
				78397
			],
			[
				482,
				-64
			]
		],
		[
			[
				41303,
				17743
			],
			[
				-192,
				-30
			],
			[
				-9,
				165
			],
			[
				-194,
				-32
			],
			[
				-6,
				110
			],
			[
				-390,
				-67
			],
			[
				6,
				-111
			],
			[
				-322,
				-57
			],
			[
				-59,
				-121
			],
			[
				13,
				-220
			],
			[
				-129,
				-22
			],
			[
				19,
				-330
			],
			[
				-64,
				-10
			],
			[
				20,
				-331
			],
			[
				-328,
				-57
			],
			[
				7,
				-109
			],
			[
				-388,
				-68
			]
		],
		[
			[
				84484,
				47114
			],
			[
				356,
				873
			],
			[
				32,
				212
			],
			[
				76,
				3
			]
		],
		[
			[
				55254,
				61349
			],
			[
				663,
				-8
			],
			[
				5,
				1000
			]
		],
		[
			[
				28250,
				41171
			],
			[
				662,
				185
			]
		],
		[
			[
				28912,
				41356
			],
			[
				-39,
				414
			]
		],
		[
			[
				27335,
				43910
			],
			[
				99,
				-1024
			]
		],
		[
			[
				27434,
				42886
			],
			[
				106,
				-57
			],
			[
				77,
				-152
			],
			[
				296,
				80
			],
			[
				28,
				-294
			],
			[
				58,
				15
			],
			[
				80,
				-893
			],
			[
				240,
				-110
			],
			[
				16,
				-168
			],
			[
				-85,
				-136
			]
		],
		[
			[
				85081,
				50058
			],
			[
				0,
				180
			],
			[
				86,
				10
			],
			[
				128,
				170
			],
			[
				59,
				-57
			],
			[
				142,
				211
			],
			[
				67,
				1
			]
		],
		[
			[
				85563,
				50573
			],
			[
				-8,
				741
			]
		],
		[
			[
				85555,
				51314
			],
			[
				-191,
				820
			]
		],
		[
			[
				36849,
				28918
			],
			[
				78,
				-1205
			],
			[
				-22,
				-4
			],
			[
				90,
				-1333
			],
			[
				-29,
				-6
			]
		],
		[
			[
				63890,
				29699
			],
			[
				846,
				-91
			]
		],
		[
			[
				64736,
				29608
			],
			[
				100,
				261
			],
			[
				213,
				23
			],
			[
				24,
				683
			],
			[
				-121,
				86
			],
			[
				-59,
				269
			]
		],
		[
			[
				64716,
				31285
			],
			[
				-104,
				80
			],
			[
				-157,
				-7
			],
			[
				-128,
				142
			],
			[
				-181,
				7
			]
		],
		[
			[
				64146,
				31507
			],
			[
				-50,
				-1498
			],
			[
				-192,
				24
			]
		],
		[
			[
				63904,
				30033
			],
			[
				-14,
				-334
			]
		],
		[
			[
				58142,
				17141
			],
			[
				894,
				-20
			],
			[
				206,
				-16
			]
		],
		[
			[
				58150,
				20140
			],
			[
				-22,
				-28
			],
			[
				-19,
				-1270
			],
			[
				52,
				-23
			],
			[
				-9,
				-812
			]
		],
		[
			[
				94670,
				24675
			],
			[
				103,
				-27
			],
			[
				-37,
				-194
			],
			[
				66,
				-153
			],
			[
				117,
				7
			],
			[
				21,
				-220
			],
			[
				147,
				-172
			],
			[
				139,
				33
			],
			[
				42,
				-65
			]
		],
		[
			[
				95268,
				23884
			],
			[
				166,
				635
			],
			[
				165,
				84
			],
			[
				48,
				-167
			],
			[
				96,
				71
			],
			[
				-47,
				334
			],
			[
				-46,
				-28
			],
			[
				-275,
				320
			],
			[
				62,
				78
			],
			[
				-48,
				256
			],
			[
				-95,
				112
			]
		],
		[
			[
				95190,
				25623
			],
			[
				-62,
				-96
			],
			[
				-9,
				-196
			],
			[
				-57,
				7
			],
			[
				-19,
				-291
			],
			[
				-162,
				108
			],
			[
				-178,
				-153
			],
			[
				21,
				-60
			],
			[
				-66,
				-238
			]
		],
		[
			[
				51842,
				9691
			],
			[
				1070,
				30
			]
		],
		[
			[
				88805,
				37005
			],
			[
				168,
				-42
			],
			[
				74,
				80
			],
			[
				85,
				208
			],
			[
				98,
				40
			],
			[
				47,
				145
			],
			[
				55,
				-57
			],
			[
				113,
				82
			]
		],
		[
			[
				78345,
				52147
			],
			[
				18,
				-30
			]
		],
		[
			[
				78832,
				52906
			],
			[
				73,
				237
			],
			[
				-180,
				158
			]
		],
		[
			[
				78109,
				53749
			],
			[
				-217,
				-312
			]
		],
		[
			[
				86372,
				24361
			],
			[
				287,
				-56
			],
			[
				-23,
				-144
			],
			[
				402,
				-77
			]
		],
		[
			[
				87171,
				24893
			],
			[
				-99,
				-15
			],
			[
				-39,
				637
			],
			[
				41,
				8
			],
			[
				43,
				627
			]
		],
		[
			[
				86140,
				26221
			],
			[
				-16,
				-108
			],
			[
				-195,
				81
			],
			[
				-95,
				-616
			]
		],
		[
			[
				85834,
				25578
			],
			[
				279,
				-507
			],
			[
				184,
				-26
			],
			[
				89,
				-96
			],
			[
				30,
				-191
			],
			[
				-44,
				-397
			]
		],
		[
			[
				65806,
				81494
			],
			[
				196,
				-121
			]
		],
		[
			[
				66002,
				81373
			],
			[
				96,
				85
			]
		],
		[
			[
				66098,
				81458
			],
			[
				-6,
				712
			],
			[
				-18,
				234
			],
			[
				129,
				-16
			],
			[
				21,
				-102
			],
			[
				105,
				180
			]
		],
		[
			[
				66615,
				84736
			],
			[
				-342,
				538
			]
		],
		[
			[
				66273,
				85274
			],
			[
				66,
				-290
			],
			[
				91,
				-235
			],
			[
				-81,
				-248
			],
			[
				-35,
				-359
			],
			[
				-178,
				-53
			],
			[
				-78,
				-240
			],
			[
				26,
				-117
			],
			[
				-14,
				-372
			],
			[
				-139,
				-72
			]
		],
		[
			[
				91014,
				31419
			],
			[
				52,
				-216
			],
			[
				632,
				-413
			]
		],
		[
			[
				91698,
				30790
			],
			[
				61,
				613
			]
		],
		[
			[
				91759,
				31403
			],
			[
				-686,
				456
			]
		],
		[
			[
				19652,
				40892
			],
			[
				-1223,
				-477
			],
			[
				-778,
				-30
			]
		],
		[
			[
				17651,
				40385
			],
			[
				-31,
				-96
			],
			[
				49,
				-197
			],
			[
				136,
				-142
			],
			[
				22,
				-113
			],
			[
				-25,
				-362
			],
			[
				39,
				-157
			],
			[
				90,
				21
			],
			[
				115,
				-135
			],
			[
				-5,
				-137
			],
			[
				138,
				-146
			],
			[
				188,
				-51
			],
			[
				78,
				-299
			],
			[
				9,
				-473
			],
			[
				49,
				-274
			],
			[
				75,
				-218
			],
			[
				-4,
				-214
			],
			[
				-71,
				-264
			]
		],
		[
			[
				83639,
				66114
			],
			[
				84,
				-50
			],
			[
				98,
				-165
			],
			[
				4,
				-565
			]
		],
		[
			[
				87176,
				48597
			],
			[
				-159,
				-29
			],
			[
				-64,
				-229
			],
			[
				-118,
				29
			],
			[
				-8,
				-80
			]
		],
		[
			[
				87156,
				47257
			],
			[
				128,
				-386
			],
			[
				-3,
				-131
			],
			[
				110,
				34
			]
		],
		[
			[
				87608,
				47742
			],
			[
				12,
				-135
			],
			[
				-129,
				56
			],
			[
				-32,
				-121
			],
			[
				-180,
				-33
			],
			[
				-37,
				-147
			],
			[
				-86,
				-105
			]
		],
		[
			[
				82731,
				34747
			],
			[
				-13,
				61
			],
			[
				-170,
				30
			]
		],
		[
			[
				82548,
				34838
			],
			[
				-36,
				-377
			],
			[
				-318,
				76
			],
			[
				19,
				188
			],
			[
				-127,
				39
			]
		],
		[
			[
				38126,
				52113
			],
			[
				83,
				-117
			],
			[
				263,
				-135
			],
			[
				99,
				161
			],
			[
				84,
				19
			],
			[
				-61,
				134
			],
			[
				62,
				150
			],
			[
				-15,
				347
			],
			[
				52,
				224
			],
			[
				-51,
				38
			],
			[
				1,
				371
			]
		],
		[
			[
				38461,
				54430
			],
			[
				-875,
				-152
			]
		],
		[
			[
				72296,
				61980
			],
			[
				-14,
				2
			]
		],
		[
			[
				9827,
				37833
			],
			[
				7,
				193
			],
			[
				-49,
				28
			],
			[
				-171,
				313
			],
			[
				-295,
				7
			]
		],
		[
			[
				50797,
				95535
			],
			[
				-102,
				615
			],
			[
				286,
				68
			],
			[
				-3,
				324
			]
		],
		[
			[
				50978,
				96542
			],
			[
				-12,
				1053
			],
			[
				-14,
				51
			]
		],
		[
			[
				50952,
				97646
			],
			[
				-201,
				11
			],
			[
				-123,
				-60
			],
			[
				-23,
				95
			],
			[
				-74,
				-104
			],
			[
				-143,
				65
			],
			[
				-206,
				-98
			],
			[
				-171,
				-252
			],
			[
				-2,
				-109
			],
			[
				-101,
				28
			],
			[
				-155,
				-251
			],
			[
				-64,
				33
			],
			[
				-181,
				-168
			]
		],
		[
			[
				35913,
				73320
			],
			[
				1067,
				194
			],
			[
				959,
				159
			]
		],
		[
			[
				37660,
				78779
			],
			[
				-131,
				114
			]
		],
		[
			[
				37529,
				78893
			],
			[
				-35,
				-227
			],
			[
				-106,
				-18
			],
			[
				-80,
				-233
			],
			[
				-78,
				-44
			],
			[
				-115,
				-212
			],
			[
				-82,
				0
			],
			[
				-246,
				-262
			],
			[
				2,
				-129
			],
			[
				-277,
				-438
			],
			[
				-67,
				-368
			],
			[
				-78,
				-142
			],
			[
				-225,
				-235
			],
			[
				-154,
				-497
			],
			[
				-113,
				-115
			],
			[
				-29,
				-204
			],
			[
				-77,
				-99
			]
		],
		[
			[
				35769,
				75670
			],
			[
				82,
				-127
			],
			[
				12,
				-210
			],
			[
				-64,
				-11
			],
			[
				114,
				-2002
			]
		],
		[
			[
				71963,
				73751
			],
			[
				769,
				-139
			]
		],
		[
			[
				72353,
				75376
			],
			[
				-261,
				39
			],
			[
				-55,
				-73
			],
			[
				-95,
				-317
			],
			[
				-24,
				-460
			]
		],
		[
			[
				61656,
				66856
			],
			[
				513,
				-43
			]
		],
		[
			[
				61508,
				68233
			],
			[
				-14,
				-336
			]
		],
		[
			[
				61494,
				67897
			],
			[
				-40,
				-221
			],
			[
				-199,
				-447
			],
			[
				-36,
				2
			],
			[
				0,
				-326
			],
			[
				247,
				-26
			]
		],
		[
			[
				63688,
				79793
			],
			[
				41,
				15
			]
		],
		[
			[
				63753,
				79823
			],
			[
				-28,
				214
			],
			[
				95,
				75
			],
			[
				44,
				137
			],
			[
				-28,
				120
			],
			[
				117,
				-58
			],
			[
				19,
				298
			],
			[
				-74,
				344
			],
			[
				165,
				33
			],
			[
				25,
				59
			]
		],
		[
			[
				63410,
				80436
			],
			[
				133,
				-66
			],
			[
				27,
				-223
			],
			[
				160,
				-324
			],
			[
				-42,
				-30
			]
		],
		[
			[
				66367,
				72118
			],
			[
				-105,
				-13
			],
			[
				-214,
				416
			],
			[
				-103,
				64
			],
			[
				-99,
				236
			],
			[
				21,
				73
			]
		],
		[
			[
				89736,
				52658
			],
			[
				143,
				317
			],
			[
				-460,
				-182
			],
			[
				170,
				188
			],
			[
				144,
				24
			],
			[
				-186,
				169
			]
		],
		[
			[
				89547,
				53174
			],
			[
				-144,
				14
			],
			[
				-155,
				-47
			],
			[
				-88,
				-474
			],
			[
				21,
				-81
			],
			[
				-20,
				-364
			]
		],
		[
			[
				89035,
				49338
			],
			[
				63,
				-71
			]
		],
		[
			[
				89098,
				49267
			],
			[
				93,
				7
			],
			[
				90,
				-113
			]
		],
		[
			[
				89281,
				49161
			],
			[
				43,
				114
			],
			[
				-19,
				335
			],
			[
				-139,
				96
			]
		],
		[
			[
				91698,
				30790
			],
			[
				-55,
				-530
			]
		],
		[
			[
				92571,
				31848
			],
			[
				-24,
				98
			],
			[
				-88,
				-43
			],
			[
				-111,
				242
			],
			[
				-45,
				-23
			],
			[
				-126,
				148
			],
			[
				0,
				181
			],
			[
				-167,
				109
			],
			[
				-122,
				238
			],
			[
				-40,
				-40
			],
			[
				-71,
				148
			]
		],
		[
			[
				91777,
				32906
			],
			[
				-10,
				-101
			],
			[
				-165,
				-285
			],
			[
				338,
				-583
			],
			[
				-154,
				-265
			],
			[
				-27,
				-269
			]
		],
		[
			[
				88035,
				18578
			],
			[
				30,
				152
			],
			[
				121,
				-45
			],
			[
				166,
				852
			],
			[
				406,
				1998
			],
			[
				21,
				272
			]
		],
		[
			[
				88779,
				21807
			],
			[
				-483,
				303
			]
		],
		[
			[
				88296,
				22110
			],
			[
				-316,
				199
			],
			[
				-191,
				-110
			]
		],
		[
			[
				87300,
				21910
			],
			[
				-733,
				-456
			]
		],
		[
			[
				86567,
				21454
			],
			[
				47,
				-131
			],
			[
				45,
				-344
			],
			[
				314,
				-859
			],
			[
				331,
				-759
			],
			[
				240,
				-340
			],
			[
				118,
				-254
			],
			[
				99,
				-38
			],
			[
				110,
				-170
			],
			[
				164,
				19
			]
		],
		[
			[
				73132,
				20605
			],
			[
				135,
				221
			],
			[
				47,
				210
			],
			[
				316,
				6
			],
			[
				178,
				218
			],
			[
				134,
				-7
			],
			[
				208,
				187
			],
			[
				159,
				-54
			],
			[
				205,
				295
			],
			[
				-42,
				98
			],
			[
				51,
				138
			]
		],
		[
			[
				74523,
				21917
			],
			[
				-769,
				192
			]
		],
		[
			[
				40185,
				74011
			],
			[
				746,
				101
			]
		],
		[
			[
				40931,
				74112
			],
			[
				-63,
				1335
			]
		],
		[
			[
				40868,
				75447
			],
			[
				-535,
				-73
			]
		],
		[
			[
				85702,
				25921
			],
			[
				38,
				-17
			],
			[
				94,
				-326
			]
		],
		[
			[
				86759,
				28371
			],
			[
				-306,
				130
			],
			[
				-5,
				-33
			],
			[
				-331,
				133
			]
		],
		[
			[
				86117,
				28601
			],
			[
				-148,
				-330
			],
			[
				-44,
				-254
			],
			[
				16,
				-147
			],
			[
				-93,
				-536
			],
			[
				12,
				-215
			]
		],
		[
			[
				70432,
				48670
			],
			[
				-257,
				5
			]
		],
		[
			[
				85388,
				58966
			],
			[
				158,
				108
			],
			[
				129,
				268
			]
		],
		[
			[
				70336,
				49855
			],
			[
				391,
				-70
			]
		],
		[
			[
				70732,
				50585
			],
			[
				-141,
				227
			],
			[
				-130,
				83
			]
		],
		[
			[
				70461,
				50895
			],
			[
				-90,
				106
			],
			[
				-42,
				413
			],
			[
				-96,
				81
			],
			[
				-76,
				-206
			],
			[
				-100,
				-15
			],
			[
				-89,
				-100
			]
		],
		[
			[
				73115,
				47296
			],
			[
				207,
				384
			],
			[
				42,
				173
			],
			[
				73,
				-34
			]
		],
		[
			[
				73437,
				47819
			],
			[
				-169,
				379
			],
			[
				-58,
				26
			]
		],
		[
			[
				73210,
				48224
			],
			[
				-213,
				-82
			]
		],
		[
			[
				72252,
				22754
			],
			[
				-574,
				109
			],
			[
				-20,
				-334
			],
			[
				-258,
				42
			]
		],
		[
			[
				71400,
				22571
			],
			[
				9,
				-245
			],
			[
				231,
				-376
			],
			[
				175,
				-73
			]
		],
		[
			[
				71033,
				20248
			],
			[
				123,
				104
			],
			[
				-12,
				121
			],
			[
				-115,
				-102
			],
			[
				4,
				-123
			]
		],
		[
			[
				70925,
				21159
			],
			[
				59,
				-634
			],
			[
				103,
				3
			],
			[
				51,
				546
			],
			[
				-100,
				163
			],
			[
				-113,
				-78
			]
		],
		[
			[
				53204,
				82066
			],
			[
				103,
				-140
			],
			[
				14,
				-167
			],
			[
				95,
				-56
			],
			[
				86,
				-155
			]
		],
		[
			[
				53502,
				81548
			],
			[
				279,
				-102
			],
			[
				380,
				-208
			]
		],
		[
			[
				54557,
				81829
			],
			[
				-127,
				71
			],
			[
				8,
				250
			],
			[
				-71,
				11
			],
			[
				88,
				261
			]
		],
		[
			[
				53535,
				82515
			],
			[
				-47,
				-387
			],
			[
				-232,
				22
			],
			[
				-52,
				-84
			]
		],
		[
			[
				21937,
				19372
			],
			[
				151,
				-301
			],
			[
				-18,
				-187
			],
			[
				37,
				-157
			],
			[
				126,
				-173
			]
		],
		[
			[
				37323,
				58595
			],
			[
				665,
				115
			],
			[
				411,
				312
			],
			[
				140,
				231
			],
			[
				77,
				-58
			],
			[
				1106,
				138
			]
		],
		[
			[
				40959,
				61028
			],
			[
				-27,
				572
			],
			[
				-397,
				-61
			],
			[
				4,
				-84
			],
			[
				-505,
				399
			]
		],
		[
			[
				40034,
				61854
			],
			[
				14,
				-282
			],
			[
				-2103,
				-333
			],
			[
				-37,
				669
			]
		],
		[
			[
				48897,
				26496
			],
			[
				20,
				1
			],
			[
				-28,
				1266
			]
		],
		[
			[
				52886,
				80542
			],
			[
				660,
				-656
			]
		],
		[
			[
				53546,
				79886
			],
			[
				104,
				136
			]
		],
		[
			[
				53502,
				81548
			],
			[
				-103,
				-91
			],
			[
				-94,
				1
			],
			[
				-89,
				-273
			],
			[
				-161,
				-258
			],
			[
				-83,
				-315
			],
			[
				-86,
				-70
			]
		],
		[
			[
				54633,
				74975
			],
			[
				-836,
				827
			]
		],
		[
			[
				74428,
				53792
			],
			[
				126,
				129
			],
			[
				153,
				-121
			],
			[
				127,
				302
			],
			[
				81,
				97
			]
		],
		[
			[
				74915,
				54199
			],
			[
				11,
				243
			],
			[
				-69,
				54
			],
			[
				-76,
				227
			],
			[
				-148,
				560
			]
		],
		[
			[
				73851,
				63506
			],
			[
				153,
				893
			]
		],
		[
			[
				74200,
				65611
			],
			[
				-174,
				61
			],
			[
				-60,
				71
			]
		],
		[
			[
				39191,
				43233
			],
			[
				206,
				-212
			],
			[
				4,
				-87
			]
		],
		[
			[
				39556,
				42959
			],
			[
				-96,
				58
			],
			[
				-12,
				230
			],
			[
				-65,
				17
			]
		],
		[
			[
				39342,
				43083
			],
			[
				0,
				0
			]
		],
		[
			[
				39356,
				43737
			],
			[
				446,
				44
			],
			[
				9,
				-167
			],
			[
				100,
				3
			],
			[
				12,
				-206
			],
			[
				222,
				32
			],
			[
				-49,
				291
			],
			[
				-241,
				-40
			],
			[
				36,
				214
			],
			[
				-158,
				-41
			],
			[
				-102,
				112
			]
		],
		[
			[
				89068,
				49052
			],
			[
				82,
				-54
			],
			[
				131,
				163
			]
		],
		[
			[
				89098,
				49267
			],
			[
				-30,
				-215
			]
		],
		[
			[
				89173,
				50066
			],
			[
				83,
				-129
			],
			[
				56,
				113
			]
		],
		[
			[
				89413,
				50278
			],
			[
				7,
				96
			],
			[
				-139,
				93
			],
			[
				-37,
				-189
			],
			[
				-77,
				-86
			]
		],
		[
			[
				91777,
				32906
			],
			[
				-23,
				153
			],
			[
				-101,
				185
			],
			[
				2,
				141
			]
		],
		[
			[
				91388,
				33332
			],
			[
				-3,
				-314
			]
		],
		[
			[
				66098,
				81458
			],
			[
				132,
				23
			],
			[
				258,
				-195
			],
			[
				107,
				21
			],
			[
				102,
				101
			],
			[
				214,
				-80
			],
			[
				123,
				74
			]
		],
		[
			[
				67034,
				81402
			],
			[
				-99,
				181
			],
			[
				-6,
				124
			],
			[
				-83,
				61
			],
			[
				27,
				129
			],
			[
				-199,
				-36
			],
			[
				-65,
				144
			]
		],
		[
			[
				66609,
				82005
			],
			[
				-159,
				122
			],
			[
				-99,
				-24
			],
			[
				-37,
				171
			],
			[
				180,
				97
			],
			[
				27,
				180
			]
		],
		[
			[
				66121,
				62853
			],
			[
				35,
				857
			]
		],
		[
			[
				94285,
				23790
			],
			[
				-19,
				-108
			],
			[
				94,
				-980
			]
		],
		[
			[
				95336,
				23105
			],
			[
				-71,
				511
			],
			[
				3,
				268
			]
		],
		[
			[
				86372,
				24361
			],
			[
				-103,
				-449
			],
			[
				-125,
				-89
			],
			[
				40,
				-185
			],
			[
				77,
				-62
			],
			[
				23,
				101
			],
			[
				102,
				-175
			],
			[
				-32,
				-132
			],
			[
				-130,
				-112
			],
			[
				-113,
				90
			],
			[
				4,
				-152
			],
			[
				-95,
				-84
			],
			[
				-85,
				29
			],
			[
				-24,
				-261
			],
			[
				45,
				-403
			],
			[
				103,
				-55
			],
			[
				110,
				-181
			],
			[
				-15,
				-156
			],
			[
				219,
				-341
			],
			[
				51,
				0
			],
			[
				143,
				-290
			]
		],
		[
			[
				12669,
				9647
			],
			[
				-176,
				-120
			],
			[
				-21,
				-165
			],
			[
				-51,
				37
			]
		],
		[
			[
				12421,
				9399
			],
			[
				65,
				-349
			],
			[
				544,
				283
			]
		],
		[
			[
				74052,
				74714
			],
			[
				77,
				-14
			]
		],
		[
			[
				74129,
				74700
			],
			[
				625,
				-123
			]
		],
		[
			[
				74754,
				74577
			],
			[
				74,
				1161
			]
		],
		[
			[
				74566,
				52828
			],
			[
				255,
				-509
			],
			[
				101,
				84
			]
		],
		[
			[
				75277,
				53765
			],
			[
				-100,
				70
			],
			[
				-262,
				364
			]
		],
		[
			[
				70515,
				43161
			],
			[
				13,
				222
			]
		],
		[
			[
				70528,
				43383
			],
			[
				-533,
				81
			]
		],
		[
			[
				69995,
				43464
			],
			[
				-139,
				20
			],
			[
				-17,
				-329
			]
		],
		[
			[
				54805,
				53432
			],
			[
				-1,
				838
			]
		],
		[
			[
				53813,
				54590
			],
			[
				0,
				-670
			]
		],
		[
			[
				60863,
				25954
			],
			[
				14,
				554
			],
			[
				-288,
				14
			]
		],
		[
			[
				59801,
				25786
			],
			[
				45,
				-448
			]
		],
		[
			[
				47740,
				74474
			],
			[
				156,
				14
			]
		],
		[
			[
				47896,
				74488
			],
			[
				820,
				58
			]
		],
		[
			[
				47901,
				76752
			],
			[
				-47,
				92
			],
			[
				-94,
				-121
			],
			[
				48,
				-113
			],
			[
				-127,
				-198
			]
		],
		[
			[
				61253,
				82140
			],
			[
				-10,
				-158
			],
			[
				69,
				-190
			],
			[
				-49,
				-122
			],
			[
				-41,
				-378
			],
			[
				0,
				-248
			],
			[
				64,
				-162
			],
			[
				-77,
				-229
			]
		],
		[
			[
				57543,
				66561
			],
			[
				415,
				-22
			]
		],
		[
			[
				57958,
				66539
			],
			[
				255,
				-4
			],
			[
				48,
				251
			],
			[
				-26,
				182
			],
			[
				75,
				440
			],
			[
				12,
				299
			],
			[
				154,
				300
			],
			[
				26,
				214
			]
		],
		[
			[
				62361,
				29518
			],
			[
				-6,
				424
			],
			[
				62,
				117
			],
			[
				-14,
				134
			],
			[
				47,
				182
			]
		],
		[
			[
				81018,
				70667
			],
			[
				140,
				-59
			],
			[
				155,
				106
			],
			[
				94,
				304
			]
		],
		[
			[
				15268,
				61566
			],
			[
				14,
				-109
			],
			[
				238,
				-401
			],
			[
				-88,
				-211
			],
			[
				-104,
				-67
			],
			[
				20,
				-179
			],
			[
				-74,
				-76
			],
			[
				-115,
				-446
			]
		],
		[
			[
				82424,
				75164
			],
			[
				-82,
				173
			],
			[
				134,
				46
			],
			[
				27,
				-89
			],
			[
				30,
				262
			],
			[
				-45,
				391
			],
			[
				-1,
				266
			],
			[
				40,
				114
			]
		],
		[
			[
				81622,
				76132
			],
			[
				-26,
				-535
			],
			[
				-52,
				-60
			],
			[
				-1,
				-234
			],
			[
				-59,
				-17
			]
		],
		[
			[
				81484,
				75286
			],
			[
				22,
				-189
			],
			[
				141,
				-45
			],
			[
				28,
				-158
			],
			[
				88,
				-151
			]
		],
		[
			[
				68478,
				81607
			],
			[
				101,
				196
			],
			[
				44,
				242
			],
			[
				-12,
				424
			],
			[
				-71,
				256
			],
			[
				53,
				-491
			],
			[
				-17,
				-291
			],
			[
				-98,
				-336
			]
		],
		[
			[
				67590,
				81695
			],
			[
				275,
				-427
			],
			[
				-59,
				263
			],
			[
				111,
				185
			],
			[
				-101,
				177
			],
			[
				-25,
				-152
			],
			[
				-188,
				26
			],
			[
				-13,
				-72
			]
		],
		[
			[
				66609,
				82005
			],
			[
				40,
				233
			],
			[
				123,
				19
			],
			[
				89,
				-92
			],
			[
				-21,
				196
			],
			[
				103,
				140
			],
			[
				180,
				-70
			],
			[
				33,
				-303
			],
			[
				-18,
				-133
			],
			[
				162,
				-196
			],
			[
				17,
				-149
			],
			[
				218,
				138
			],
			[
				-112,
				123
			],
			[
				4,
				112
			],
			[
				106,
				44
			],
			[
				30,
				197
			],
			[
				99,
				-57
			],
			[
				71,
				-279
			],
			[
				109,
				68
			],
			[
				-17,
				183
			],
			[
				-95,
				14
			],
			[
				-75,
				153
			],
			[
				156,
				-28
			],
			[
				-22,
				99
			],
			[
				-245,
				128
			],
			[
				134,
				176
			],
			[
				76,
				-81
			],
			[
				-46,
				200
			],
			[
				-161,
				-103
			],
			[
				-74,
				190
			],
			[
				2,
				326
			],
			[
				-61,
				25
			],
			[
				-53,
				-278
			],
			[
				-73,
				6
			],
			[
				-9,
				217
			]
		],
		[
			[
				62395,
				72798
			],
			[
				-54,
				225
			],
			[
				-88,
				189
			],
			[
				-64,
				481
			]
		],
		[
			[
				61668,
				73728
			],
			[
				-4,
				-167
			],
			[
				-202,
				-321
			],
			[
				-7,
				-337
			]
		],
		[
			[
				88119,
				23967
			],
			[
				192,
				1047
			],
			[
				-100,
				138
			],
			[
				1,
				188
			],
			[
				161,
				57
			],
			[
				-152,
				746
			],
			[
				112,
				634
			],
			[
				-16,
				9
			]
		],
		[
			[
				88296,
				22110
			],
			[
				412,
				2136
			],
			[
				-72,
				607
			],
			[
				271,
				106
			]
		],
		[
			[
				88907,
				24959
			],
			[
				83,
				409
			],
			[
				-73,
				243
			],
			[
				35,
				271
			]
		],
		[
			[
				88952,
				25882
			],
			[
				55,
				172
			],
			[
				31,
				521
			]
		],
		[
			[
				11604,
				15238
			],
			[
				688,
				352
			]
		],
		[
			[
				11963,
				17169
			],
			[
				-628,
				-310
			],
			[
				-276,
				-150
			]
		],
		[
			[
				71505,
				57255
			],
			[
				74,
				-305
			],
			[
				39,
				157
			],
			[
				120,
				-242
			],
			[
				115,
				78
			],
			[
				162,
				-41
			],
			[
				19,
				-135
			]
		],
		[
			[
				72357,
				58235
			],
			[
				-178,
				5
			],
			[
				-108,
				-49
			],
			[
				-394,
				-405
			]
		],
		[
			[
				76908,
				71781
			],
			[
				16,
				272
			],
			[
				-463,
				68
			]
		],
		[
			[
				76461,
				72121
			],
			[
				-16,
				-252
			],
			[
				35,
				-6
			],
			[
				-23,
				-250
			],
			[
				44,
				-8
			],
			[
				-37,
				-444
			]
		],
		[
			[
				69269,
				36776
			],
			[
				165,
				-110
			],
			[
				295,
				-300
			]
		],
		[
			[
				62328,
				77339
			],
			[
				-36,
				-64
			],
			[
				-114,
				38
			]
		],
		[
			[
				76093,
				56270
			],
			[
				135,
				297
			]
		],
		[
			[
				87887,
				41284
			],
			[
				-105,
				-41
			],
			[
				113,
				174
			]
		],
		[
			[
				17572,
				85296
			],
			[
				74,
				1034
			]
		],
		[
			[
				17646,
				86330
			],
			[
				-366,
				75
			],
			[
				9,
				186
			],
			[
				-514,
				67
			],
			[
				-820,
				791
			],
			[
				0,
				97
			],
			[
				-396,
				10
			]
		],
		[
			[
				15559,
				87556
			],
			[
				195,
				-600
			],
			[
				-3,
				-183
			],
			[
				-133,
				-56
			],
			[
				-18,
				-402
			],
			[
				211,
				-15
			],
			[
				0,
				-224
			],
			[
				61,
				-3
			],
			[
				-1,
				-237
			],
			[
				158,
				-122
			],
			[
				97,
				94
			],
			[
				175,
				-489
			],
			[
				930,
				-80
			]
		],
		[
			[
				69620,
				52124
			],
			[
				145,
				-162
			]
		],
		[
			[
				69765,
				51962
			],
			[
				269,
				196
			],
			[
				301,
				166
			],
			[
				102,
				7
			]
		],
		[
			[
				70021,
				53053
			],
			[
				-105,
				-115
			],
			[
				-14,
				-343
			],
			[
				-56,
				-206
			]
		],
		[
			[
				69846,
				52389
			],
			[
				-164,
				-68
			],
			[
				-62,
				-197
			]
		],
		[
			[
				29567,
				41534
			],
			[
				-655,
				-178
			]
		],
		[
			[
				28250,
				41171
			],
			[
				-209,
				-51
			],
			[
				67,
				-190
			],
			[
				-115,
				-360
			],
			[
				-22,
				85
			],
			[
				-139,
				76
			],
			[
				-102,
				197
			],
			[
				-146,
				175
			],
			[
				-97,
				-31
			],
			[
				59,
				-170
			],
			[
				-13,
				-142
			],
			[
				57,
				-95
			],
			[
				-70,
				-158
			],
			[
				6,
				-175
			],
			[
				-102,
				-178
			]
		],
		[
			[
				71138,
				43049
			],
			[
				67,
				1120
			]
		],
		[
			[
				70651,
				44384
			],
			[
				19,
				-115
			],
			[
				-78,
				12
			],
			[
				-50,
				-900
			],
			[
				-14,
				2
			]
		],
		[
			[
				12557,
				22606
			],
			[
				61,
				-254
			],
			[
				272,
				132
			],
			[
				181,
				-464
			],
			[
				113,
				-156
			],
			[
				6,
				-325
			],
			[
				-68,
				-368
			],
			[
				-60,
				-94
			]
		],
		[
			[
				13062,
				21077
			],
			[
				-20,
				-45
			],
			[
				93,
				-385
			],
			[
				234,
				-119
			]
		],
		[
			[
				11937,
				26259
			],
			[
				290,
				-1728
			],
			[
				18,
				-76
			],
			[
				312,
				-1849
			]
		],
		[
			[
				72995,
				38849
			],
			[
				450,
				-108
			]
		],
		[
			[
				73445,
				38741
			],
			[
				53,
				744
			]
		],
		[
			[
				73095,
				40186
			],
			[
				-100,
				-1337
			]
		],
		[
			[
				76225,
				55659
			],
			[
				173,
				-99
			],
			[
				155,
				319
			],
			[
				58,
				-93
			],
			[
				57,
				70
			]
		],
		[
			[
				69055,
				42522
			],
			[
				51,
				1019
			]
		],
		[
			[
				17491,
				40399
			],
			[
				160,
				-14
			]
		],
		[
			[
				20817,
				41321
			],
			[
				1306,
				2373
			]
		],
		[
			[
				19746,
				50085
			],
			[
				-312,
				2529
			],
			[
				86,
				37
			],
			[
				-97,
				780
			]
		],
		[
			[
				19423,
				53431
			],
			[
				-1809,
				-4656
			]
		],
		[
			[
				17614,
				48775
			],
			[
				514,
				-3879
			],
			[
				-652,
				-2125
			]
		],
		[
			[
				17476,
				42771
			],
			[
				-619,
				-2015
			],
			[
				42,
				-303
			],
			[
				592,
				-54
			]
		],
		[
			[
				58050,
				33936
			],
			[
				-11,
				-1011
			]
		],
		[
			[
				64494,
				79758
			],
			[
				-139,
				309
			],
			[
				47,
				256
			],
			[
				-41,
				167
			],
			[
				116,
				354
			],
			[
				90,
				74
			]
		],
		[
			[
				64567,
				80918
			],
			[
				-183,
				15
			],
			[
				-63,
				98
			]
		],
		[
			[
				49580,
				64910
			],
			[
				-319,
				-17
			],
			[
				3,
				-167
			],
			[
				-186,
				-11
			]
		],
		[
			[
				44763,
				75891
			],
			[
				-13,
				362
			]
		],
		[
			[
				44678,
				78079
			],
			[
				-955,
				-96
			]
		],
		[
			[
				43723,
				77983
			],
			[
				78,
				-2189
			]
		],
		[
			[
				39248,
				46282
			],
			[
				633,
				103
			]
		],
		[
			[
				40870,
				47544
			],
			[
				-63,
				1333
			]
		],
		[
			[
				39276,
				48653
			],
			[
				26,
				-501
			]
		],
		[
			[
				50079,
				78646
			],
			[
				-38,
				81
			],
			[
				21,
				332
			]
		],
		[
			[
				17491,
				40399
			],
			[
				-1485,
				-611
			]
		],
		[
			[
				15594,
				37324
			],
			[
				47,
				-97
			],
			[
				58,
				-404
			],
			[
				-50,
				-108
			],
			[
				48,
				-335
			],
			[
				-28,
				-12
			],
			[
				56,
				-374
			]
		],
		[
			[
				30334,
				70516
			],
			[
				-124,
				-28
			]
		],
		[
			[
				30210,
				70488
			],
			[
				-176,
				-442
			],
			[
				70,
				-397
			],
			[
				0,
				-174
			],
			[
				-142,
				-664
			],
			[
				-28,
				-9
			],
			[
				-225,
				-968
			],
			[
				183,
				-2188
			]
		],
		[
			[
				82099,
				86246
			],
			[
				97,
				-24
			],
			[
				175,
				1978
			]
		],
		[
			[
				82371,
				88200
			],
			[
				-984,
				249
			]
		],
		[
			[
				81387,
				88449
			],
			[
				118,
				-418
			],
			[
				80,
				-89
			],
			[
				58,
				-296
			],
			[
				-60,
				-231
			],
			[
				-155,
				-32
			],
			[
				65,
				358
			],
			[
				-169,
				-59
			],
			[
				39,
				-121
			],
			[
				-76,
				-326
			],
			[
				-202,
				-156
			]
		],
		[
			[
				81085,
				87079
			],
			[
				-53,
				-575
			]
		],
		[
			[
				15564,
				88135
			],
			[
				-5,
				-579
			]
		],
		[
			[
				17646,
				86330
			],
			[
				168,
				-36
			],
			[
				29,
				412
			]
		],
		[
			[
				16902,
				89151
			],
			[
				-69,
				-26
			],
			[
				-93,
				156
			],
			[
				-33,
				201
			],
			[
				-181,
				9
			],
			[
				-69,
				-64
			],
			[
				-118,
				150
			]
		],
		[
			[
				15574,
				89303
			],
			[
				-10,
				-1168
			]
		],
		[
			[
				44852,
				35572
			],
			[
				952,
				114
			]
		],
		[
			[
				45761,
				37028
			],
			[
				-925,
				-92
			]
		],
		[
			[
				65152,
				79693
			],
			[
				21,
				623
			],
			[
				48,
				214
			],
			[
				150,
				311
			],
			[
				-15,
				263
			]
		],
		[
			[
				65356,
				81104
			],
			[
				-38,
				184
			],
			[
				-83,
				140
			],
			[
				-155,
				-84
			]
		],
		[
			[
				65080,
				81344
			],
			[
				-29,
				-40
			],
			[
				-83,
				229
			],
			[
				-114,
				-243
			],
			[
				-123,
				-121
			],
			[
				-43,
				-160
			],
			[
				-121,
				-91
			]
		],
		[
			[
				72555,
				72176
			],
			[
				-227,
				-77
			],
			[
				-77,
				-76
			],
			[
				-47,
				178
			],
			[
				-75,
				-179
			],
			[
				-111,
				51
			],
			[
				22,
				204
			],
			[
				-40,
				131
			],
			[
				-38,
				-118
			]
		],
		[
			[
				75331,
				72752
			],
			[
				-3,
				281
			]
		],
		[
			[
				75266,
				73923
			],
			[
				-47,
				-31
			],
			[
				-100,
				141
			],
			[
				-9,
				154
			],
			[
				-377,
				57
			],
			[
				21,
				333
			]
		],
		[
			[
				74129,
				74700
			],
			[
				50,
				-174
			],
			[
				-12,
				-142
			],
			[
				83,
				-345
			],
			[
				-46,
				-275
			],
			[
				32,
				-97
			]
		],
		[
			[
				75836,
				32300
			],
			[
				-757,
				221
			]
		],
		[
			[
				75079,
				32521
			],
			[
				-182,
				56
			]
		],
		[
			[
				74759,
				31242
			],
			[
				-30,
				-334
			],
			[
				378,
				-121
			]
		],
		[
			[
				73841,
				23423
			],
			[
				889,
				-207
			]
		],
		[
			[
				74730,
				23216
			],
			[
				41,
				101
			],
			[
				30,
				317
			],
			[
				47,
				113
			],
			[
				-31,
				467
			],
			[
				21,
				317
			]
		],
		[
			[
				73943,
				24743
			],
			[
				-102,
				-1320
			]
		],
		[
			[
				73761,
				28417
			],
			[
				20,
				332
			],
			[
				-319,
				69
			],
			[
				4,
				59
			]
		],
		[
			[
				73466,
				28877
			],
			[
				-381,
				80
			]
		],
		[
			[
				73180,
				24913
			],
			[
				763,
				-170
			]
		],
		[
			[
				74046,
				26062
			],
			[
				-447,
				97
			]
		],
		[
			[
				74537,
				28596
			],
			[
				80,
				979
			]
		],
		[
			[
				74617,
				29575
			],
			[
				-380,
				88
			],
			[
				30,
				333
			]
		],
		[
			[
				74267,
				29996
			],
			[
				-705,
				168
			]
		],
		[
			[
				73562,
				30164
			],
			[
				-96,
				-1287
			]
		],
		[
			[
				37545,
				47682
			],
			[
				1102,
				178
			]
		],
		[
			[
				37742,
				49381
			],
			[
				-125,
				-220
			],
			[
				-16,
				-100
			],
			[
				-185,
				-443
			]
		],
		[
			[
				38334,
				43016
			],
			[
				470,
				72
			],
			[
				-5,
				84
			]
		],
		[
			[
				43723,
				77983
			],
			[
				-999,
				-134
			]
		],
		[
			[
				38214,
				51455
			],
			[
				72,
				-377
			],
			[
				-67,
				-183
			]
		],
		[
			[
				91092,
				33838
			],
			[
				-2,
				-155
			],
			[
				-91,
				-387
			],
			[
				-145,
				-3
			],
			[
				-96,
				-107
			],
			[
				37,
				-473
			]
		],
		[
			[
				52140,
				95618
			],
			[
				125,
				719
			]
		],
		[
			[
				52265,
				96337
			],
			[
				-39,
				-1
			]
		],
		[
			[
				52226,
				96336
			],
			[
				-76,
				-499
			],
			[
				-168,
				-56
			],
			[
				125,
				-85
			],
			[
				-17,
				-79
			]
		],
		[
			[
				51835,
				95612
			],
			[
				48,
				177
			],
			[
				5,
				275
			],
			[
				66,
				4
			],
			[
				59,
				263
			]
		],
		[
			[
				52013,
				96331
			],
			[
				-82,
				-1
			],
			[
				-116,
				324
			],
			[
				-156,
				2
			],
			[
				-8,
				91
			],
			[
				-673,
				-205
			]
		],
		[
			[
				62894,
				72139
			],
			[
				76,
				-6
			],
			[
				10,
				327
			],
			[
				282,
				-22
			]
		],
		[
			[
				63222,
				73119
			],
			[
				-296,
				26
			],
			[
				-116,
				146
			],
			[
				-3,
				95
			],
			[
				-111,
				47
			],
			[
				-17,
				354
			],
			[
				-98,
				247
			],
			[
				-118,
				122
			]
		],
		[
			[
				14974,
				41976
			],
			[
				955,
				2460
			]
		],
		[
			[
				15929,
				44436
			],
			[
				790,
				2031
			]
		],
		[
			[
				16719,
				46467
			],
			[
				-1611,
				-651
			]
		],
		[
			[
				15108,
				45816
			],
			[
				-24,
				-112
			],
			[
				-98,
				10
			],
			[
				-74,
				-327
			],
			[
				-98,
				-67
			],
			[
				-62,
				-141
			]
		],
		[
			[
				76646,
				81241
			],
			[
				191,
				-195
			],
			[
				-26,
				165
			],
			[
				-145,
				109
			],
			[
				-20,
				-79
			]
		],
		[
			[
				77233,
				80479
			],
			[
				2,
				246
			],
			[
				-166,
				-2
			],
			[
				-40,
				-79
			],
			[
				-156,
				105
			],
			[
				-236,
				362
			],
			[
				-43,
				8
			],
			[
				-358,
				482
			],
			[
				17,
				-196
			],
			[
				-76,
				-25
			],
			[
				-133,
				289
			],
			[
				-248,
				46
			],
			[
				102,
				141
			],
			[
				17,
				124
			],
			[
				116,
				48
			],
			[
				391,
				-385
			],
			[
				168,
				-312
			],
			[
				20,
				39
			],
			[
				-143,
				302
			],
			[
				-490,
				503
			],
			[
				-371,
				-278
			]
		],
		[
			[
				75606,
				81897
			],
			[
				28,
				-132
			],
			[
				170,
				-286
			],
			[
				134,
				-40
			],
			[
				29,
				-110
			],
			[
				-90,
				-379
			],
			[
				39,
				-243
			]
		],
		[
			[
				70908,
				49530
			],
			[
				-33,
				-505
			]
		],
		[
			[
				75556,
				46669
			],
			[
				227,
				232
			],
			[
				30,
				140
			],
			[
				120,
				45
			]
		],
		[
			[
				78029,
				51930
			],
			[
				34,
				-62
			]
		],
		[
			[
				41438,
				73844
			],
			[
				-14,
				331
			],
			[
				-493,
				-63
			]
		],
		[
			[
				18260,
				1540
			],
			[
				1220,
				552
			],
			[
				1677,
				718
			]
		],
		[
			[
				20586,
				6632
			],
			[
				-186,
				-23
			]
		],
		[
			[
				79608,
				45110
			],
			[
				85,
				-115
			],
			[
				193,
				128
			],
			[
				105,
				-103
			]
		],
		[
			[
				80429,
				46086
			],
			[
				-159,
				372
			]
		],
		[
			[
				80270,
				46458
			],
			[
				-472,
				39
			],
			[
				-119,
				-214
			]
		],
		[
			[
				79679,
				46283
			],
			[
				-62,
				-217
			],
			[
				-9,
				-593
			],
			[
				-36,
				-252
			],
			[
				36,
				-111
			]
		],
		[
			[
				76548,
				29328
			],
			[
				49,
				246
			],
			[
				157,
				448
			],
			[
				-51,
				208
			],
			[
				-6,
				461
			],
			[
				38,
				172
			],
			[
				-41,
				610
			],
			[
				-113,
				256
			],
			[
				-61,
				-10
			],
			[
				-62,
				156
			],
			[
				-9,
				-215
			],
			[
				-59,
				-76
			],
			[
				119,
				-301
			],
			[
				-137,
				-27
			]
		],
		[
			[
				49541,
				45709
			],
			[
				920,
				48
			]
		],
		[
			[
				50461,
				45757
			],
			[
				31,
				1
			]
		],
		[
			[
				50462,
				47435
			],
			[
				-940,
				-48
			]
		],
		[
			[
				49522,
				47387
			],
			[
				-18,
				-1
			]
		],
		[
			[
				49504,
				47386
			],
			[
				37,
				-1677
			]
		],
		[
			[
				65722,
				85543
			],
			[
				62,
				-82
			],
			[
				83,
				194
			],
			[
				-59,
				51
			],
			[
				-86,
				-163
			]
		],
		[
			[
				65077,
				82618
			],
			[
				245,
				-8
			]
		],
		[
			[
				66273,
				85274
			],
			[
				-251,
				328
			],
			[
				-27,
				-164
			],
			[
				-86,
				-45
			],
			[
				23,
				-147
			],
			[
				-91,
				-318
			],
			[
				-93,
				-137
			],
			[
				-96,
				280
			],
			[
				3,
				-106
			]
		],
		[
			[
				46580,
				9342
			],
			[
				551,
				55
			],
			[
				1398,
				116
			]
		],
		[
			[
				48344,
				11218
			],
			[
				-194,
				-14
			],
			[
				9,
				-330
			],
			[
				-974,
				-85
			],
			[
				-10,
				329
			]
		],
		[
			[
				88947,
				52516
			],
			[
				19,
				70
			]
		],
		[
			[
				88966,
				52586
			],
			[
				-36,
				143
			]
		],
		[
			[
				72376,
				19856
			],
			[
				67,
				-9
			],
			[
				39,
				130
			],
			[
				375,
				129
			],
			[
				-83,
				211
			],
			[
				-132,
				-33
			],
			[
				-266,
				-428
			]
		],
		[
			[
				71372,
				18587
			],
			[
				192,
				-38
			],
			[
				23,
				331
			],
			[
				1153,
				-234
			],
			[
				25,
				327
			],
			[
				191,
				-40
			],
			[
				23,
				328
			],
			[
				195,
				-16
			]
		],
		[
			[
				73174,
				19245
			],
			[
				-211,
				130
			],
			[
				-116,
				24
			],
			[
				-65,
				107
			],
			[
				-181,
				-178
			],
			[
				-70,
				86
			],
			[
				-65,
				-228
			],
			[
				-69,
				37
			],
			[
				-80,
				-84
			],
			[
				-49,
				108
			],
			[
				26,
				201
			],
			[
				-76,
				122
			],
			[
				83,
				339
			],
			[
				-66,
				81
			],
			[
				-140,
				-112
			],
			[
				-24,
				-96
			],
			[
				-125,
				-99
			],
			[
				-152,
				-260
			],
			[
				-241,
				-124
			],
			[
				-69,
				36
			],
			[
				-225,
				-139
			],
			[
				-68,
				53
			],
			[
				-218,
				7
			],
			[
				-112,
				254
			],
			[
				-78,
				258
			],
			[
				-50,
				36
			],
			[
				-180,
				-43
			],
			[
				-82,
				60
			]
		],
		[
			[
				70471,
				19821
			],
			[
				-65,
				-1049
			]
		],
		[
			[
				9940,
				18828
			],
			[
				528,
				283
			],
			[
				-60,
				339
			],
			[
				91,
				46
			],
			[
				-57,
				312
			],
			[
				84,
				39
			],
			[
				-113,
				641
			],
			[
				94,
				48
			],
			[
				-108,
				629
			],
			[
				-94,
				-31
			],
			[
				-58,
				319
			],
			[
				-92,
				-47
			],
			[
				-120,
				768
			]
		],
		[
			[
				10035,
				22174
			],
			[
				-186,
				-38
			],
			[
				-25,
				130
			],
			[
				-145,
				140
			],
			[
				-146,
				-74
			],
			[
				-79,
				35
			],
			[
				38,
				-275
			],
			[
				-32,
				-17
			],
			[
				92,
				-526
			],
			[
				-70,
				-121
			],
			[
				-32,
				-223
			],
			[
				-346,
				-182
			]
		],
		[
			[
				9104,
				21023
			],
			[
				144,
				-392
			],
			[
				215,
				-687
			],
			[
				-8,
				-147
			],
			[
				125,
				-117
			],
			[
				141,
				-268
			],
			[
				219,
				-584
			]
		],
		[
			[
				36084,
				50742
			],
			[
				10,
				-146
			],
			[
				-135,
				-73
			],
			[
				-120,
				-154
			],
			[
				-44,
				-172
			],
			[
				-212,
				-22
			]
		],
		[
			[
				89182,
				40796
			],
			[
				-192,
				12
			],
			[
				-114,
				131
			],
			[
				-116,
				36
			],
			[
				-77,
				216
			],
			[
				50,
				99
			],
			[
				-154,
				327
			]
		],
		[
			[
				88579,
				41617
			],
			[
				-72,
				59
			],
			[
				26,
				289
			],
			[
				-66,
				-51
			],
			[
				-13,
				-241
			],
			[
				-101,
				-179
			],
			[
				45,
				-474
			],
			[
				49,
				-295
			],
			[
				82,
				-187
			],
			[
				151,
				-15
			],
			[
				28,
				-67
			]
		],
		[
			[
				17614,
				48775
			],
			[
				-895,
				-2308
			]
		],
		[
			[
				15929,
				44436
			],
			[
				128,
				63
			],
			[
				255,
				-318
			],
			[
				1164,
				-1410
			]
		],
		[
			[
				90772,
				28793
			],
			[
				55,
				-276
			],
			[
				-8,
				-151
			],
			[
				60,
				-116
			],
			[
				10,
				-199
			],
			[
				-60,
				-261
			],
			[
				-29,
				-341
			]
		],
		[
			[
				91462,
				26996
			],
			[
				-24,
				1687
			],
			[
				37,
				130
			]
		],
		[
			[
				91475,
				28813
			],
			[
				-39,
				15
			],
			[
				29,
				273
			],
			[
				-309,
				18
			],
			[
				-355,
				-149
			],
			[
				-34,
				17
			]
		],
		[
			[
				42801,
				8880
			],
			[
				996,
				142
			],
			[
				647,
				81
			]
		],
		[
			[
				67863,
				30934
			],
			[
				-3,
				212
			],
			[
				67,
				232
			],
			[
				-40,
				130
			],
			[
				20,
				158
			],
			[
				84,
				206
			],
			[
				2,
				211
			],
			[
				43,
				175
			]
		],
		[
			[
				75476,
				65971
			],
			[
				-36,
				-540
			],
			[
				-30,
				5
			],
			[
				-32,
				-505
			]
		],
		[
			[
				66602,
				49229
			],
			[
				4,
				112
			]
		],
		[
			[
				66606,
				49341
			],
			[
				37,
				1006
			]
		],
		[
			[
				65869,
				50415
			],
			[
				-191,
				21
			]
		],
		[
			[
				65678,
				50436
			],
			[
				-32,
				-756
			]
		],
		[
			[
				73250,
				51175
			],
			[
				78,
				-118
			],
			[
				-12,
				-102
			],
			[
				81,
				-53
			],
			[
				6,
				-147
			],
			[
				159,
				-89
			],
			[
				-4,
				-201
			],
			[
				82,
				-14
			]
		],
		[
			[
				73281,
				36416
			],
			[
				-641,
				142
			]
		],
		[
			[
				35913,
				73320
			],
			[
				-711,
				-127
			]
		],
		[
			[
				62918,
				29469
			],
			[
				767,
				-75
			],
			[
				193,
				-41
			]
		],
		[
			[
				63878,
				29353
			],
			[
				12,
				346
			]
		],
		[
			[
				63904,
				30033
			],
			[
				-580,
				61
			],
			[
				21,
				498
			]
		],
		[
			[
				63345,
				30592
			],
			[
				-866,
				79
			]
		],
		[
			[
				75529,
				72062
			],
			[
				487,
				-107
			]
		],
		[
			[
				76016,
				71955
			],
			[
				18,
				-4
			]
		],
		[
			[
				31748,
				53064
			],
			[
				1167,
				267
			]
		],
		[
			[
				32915,
				53331
			],
			[
				1574,
				337
			]
		],
		[
			[
				89831,
				51197
			],
			[
				-332,
				121
			]
		],
		[
			[
				74267,
				29996
			],
			[
				111,
				1340
			]
		],
		[
			[
				73998,
				31434
			],
			[
				-332,
				74
			]
		],
		[
			[
				73565,
				30204
			],
			[
				-3,
				-40
			]
		],
		[
			[
				10199,
				17947
			],
			[
				359,
				178
			],
			[
				22,
				-123
			],
			[
				180,
				-91
			],
			[
				190,
				98
			],
			[
				114,
				158
			],
			[
				27,
				222
			],
			[
				78,
				40
			],
			[
				-25,
				142
			],
			[
				78,
				117
			],
			[
				188,
				100
			],
			[
				-18,
				107
			],
			[
				323,
				165
			],
			[
				-108,
				640
			],
			[
				45,
				23
			],
			[
				-41,
				243
			],
			[
				569,
				265
			],
			[
				-66,
				397
			],
			[
				363,
				162
			],
			[
				585,
				287
			]
		],
		[
			[
				12557,
				22606
			],
			[
				-186,
				-90
			],
			[
				-128,
				163
			],
			[
				-157,
				-74
			],
			[
				-9,
				55
			],
			[
				-210,
				69
			],
			[
				-128,
				167
			],
			[
				-58,
				-48
			],
			[
				-62,
				166
			],
			[
				-247,
				-103
			],
			[
				-106,
				37
			],
			[
				-220,
				-126
			],
			[
				-157,
				136
			]
		],
		[
			[
				10181,
				22270
			],
			[
				-146,
				-96
			]
		],
		[
			[
				9940,
				18828
			],
			[
				259,
				-881
			]
		],
		[
			[
				63345,
				30592
			],
			[
				35,
				963
			]
		],
		[
			[
				52265,
				96337
			],
			[
				54,
				402
			],
			[
				71,
				847
			],
			[
				-48,
				-155
			],
			[
				-34,
				-479
			],
			[
				-82,
				-616
			]
		],
		[
			[
				52013,
				96331
			],
			[
				137,
				537
			],
			[
				-63,
				151
			],
			[
				37,
				478
			],
			[
				39,
				70
			],
			[
				144,
				39
			],
			[
				7,
				115
			],
			[
				89,
				-57
			],
			[
				9,
				404
			],
			[
				-265,
				7
			],
			[
				-1,
				64
			],
			[
				-198,
				101
			],
			[
				1,
				255
			],
			[
				-147,
				-41
			],
			[
				-7,
				-83
			],
			[
				-147,
				-59
			],
			[
				-48,
				-182
			],
			[
				-73,
				-16
			],
			[
				-132,
				-321
			],
			[
				-222,
				-47
			],
			[
				-85,
				-109
			],
			[
				-136,
				9
			]
		],
		[
			[
				76798,
				66781
			],
			[
				173,
				-527
			],
			[
				3,
				-359
			]
		],
		[
			[
				25451,
				29694
			],
			[
				-71,
				-32
			],
			[
				-151,
				82
			],
			[
				-66,
				-176
			],
			[
				-226,
				-225
			],
			[
				-57,
				-119
			],
			[
				-145,
				-56
			],
			[
				-73,
				-146
			],
			[
				-146,
				-122
			]
		],
		[
			[
				76578,
				49299
			],
			[
				10,
				354
			],
			[
				-74,
				219
			],
			[
				10,
				109
			]
		],
		[
			[
				76033,
				49871
			],
			[
				-7,
				-311
			]
		],
		[
			[
				63421,
				48148
			],
			[
				42,
				-62
			],
			[
				199,
				20
			],
			[
				19,
				-138
			],
			[
				128,
				-89
			],
			[
				41,
				104
			],
			[
				67,
				-127
			]
		],
		[
			[
				34967,
				18354
			],
			[
				577,
				129
			],
			[
				29,
				-389
			],
			[
				138,
				-77
			],
			[
				447,
				98
			],
			[
				48,
				-269
			],
			[
				144,
				-194
			],
			[
				224,
				49
			],
			[
				29,
				-381
			]
		],
		[
			[
				36665,
				17387
			],
			[
				45,
				288
			],
			[
				113,
				246
			],
			[
				-12,
				164
			],
			[
				113,
				246
			],
			[
				16,
				226
			],
			[
				61,
				69
			],
			[
				-30,
				263
			],
			[
				-76,
				231
			]
		],
		[
			[
				50981,
				18647
			],
			[
				-920,
				-43
			]
		],
		[
			[
				49467,
				27886
			],
			[
				27,
				-1350
			],
			[
				-19,
				-2
			],
			[
				28,
				-1338
			]
		],
		[
			[
				12200,
				7276
			],
			[
				322,
				180
			],
			[
				747,
				376
			]
		],
		[
			[
				12421,
				9399
			],
			[
				-119,
				-38
			],
			[
				-132,
				107
			],
			[
				-46,
				-46
			],
			[
				-71,
				-291
			],
			[
				-48,
				-51
			],
			[
				-121,
				130
			],
			[
				111,
				-417
			],
			[
				71,
				-389
			],
			[
				70,
				-510
			],
			[
				37,
				210
			],
			[
				-68,
				308
			],
			[
				-52,
				442
			],
			[
				91,
				47
			],
			[
				-8,
				-268
			],
			[
				34,
				-190
			],
			[
				53,
				107
			],
			[
				111,
				-181
			],
			[
				-33,
				-413
			],
			[
				78,
				-104
			],
			[
				162,
				-98
			],
			[
				-67,
				-161
			],
			[
				-194,
				90
			],
			[
				-108,
				-231
			],
			[
				28,
				-176
			]
		],
		[
			[
				44788,
				38272
			],
			[
				959,
				96
			]
		],
		[
			[
				17208,
				20918
			],
			[
				-183,
				-80
			],
			[
				-148,
				969
			],
			[
				-188,
				-83
			]
		],
		[
			[
				89573,
				57550
			],
			[
				78,
				-135
			],
			[
				227,
				-151
			],
			[
				21,
				-256
			],
			[
				75,
				22
			],
			[
				44,
				206
			],
			[
				65,
				81
			],
			[
				115,
				-25
			],
			[
				-87,
				-220
			],
			[
				149,
				61
			],
			[
				49,
				241
			],
			[
				-93,
				301
			],
			[
				-106,
				80
			],
			[
				-109,
				506
			],
			[
				-13,
				244
			],
			[
				-117,
				-25
			],
			[
				-25,
				-262
			],
			[
				-76,
				-39
			],
			[
				40,
				263
			],
			[
				-99,
				60
			],
			[
				293,
				114
			],
			[
				138,
				-548
			],
			[
				123,
				-368
			],
			[
				129,
				-301
			],
			[
				221,
				-670
			],
			[
				68,
				41
			],
			[
				-147,
				333
			],
			[
				-349,
				927
			],
			[
				-135,
				531
			],
			[
				-41,
				304
			],
			[
				-50,
				-207
			],
			[
				-249,
				-99
			],
			[
				-169,
				44
			],
			[
				-236,
				139
			],
			[
				-350,
				292
			]
		],
		[
			[
				88957,
				59034
			],
			[
				10,
				-123
			],
			[
				-85,
				-311
			],
			[
				-84,
				-84
			]
		],
		[
			[
				88798,
				58516
			],
			[
				126,
				-114
			]
		],
		[
			[
				68028,
				52539
			],
			[
				27,
				679
			]
		],
		[
			[
				67986,
				54605
			],
			[
				-16,
				-352
			],
			[
				-418,
				-632
			]
		],
		[
			[
				67552,
				53621
			],
			[
				-42,
				-1010
			]
		],
		[
			[
				14292,
				36475
			],
			[
				138,
				-871
			],
			[
				309,
				-2029
			],
			[
				379,
				-2556
			]
		],
		[
			[
				16596,
				28442
			],
			[
				58,
				25
			]
		],
		[
			[
				15422,
				37367
			],
			[
				-163,
				69
			],
			[
				-161,
				-60
			],
			[
				-212,
				56
			],
			[
				-84,
				106
			],
			[
				-118,
				-85
			],
			[
				-41,
				271
			],
			[
				68,
				30
			],
			[
				-83,
				194
			],
			[
				13,
				237
			],
			[
				-135,
				262
			]
		],
		[
			[
				72710,
				37561
			],
			[
				641,
				-155
			]
		],
		[
			[
				73356,
				37478
			],
			[
				70,
				1007
			]
		],
		[
			[
				73426,
				38485
			],
			[
				19,
				256
			]
		],
		[
			[
				72995,
				38849
			],
			[
				-436,
				88
			]
		],
		[
			[
				55596,
				47821
			],
			[
				-166,
				-30
			],
			[
				11,
				-584
			]
		],
		[
			[
				55597,
				48542
			],
			[
				-768,
				3
			]
		],
		[
			[
				54829,
				48545
			],
			[
				-1,
				-883
			],
			[
				-158,
				-107
			]
		],
		[
			[
				54670,
				47555
			],
			[
				6,
				-347
			]
		],
		[
			[
				53317,
				28028
			],
			[
				697,
				5
			]
		],
		[
			[
				53310,
				29361
			],
			[
				7,
				-1333
			]
		],
		[
			[
				70911,
				40618
			],
			[
				350,
				-67
			]
		],
		[
			[
				71261,
				40551
			],
			[
				504,
				-102
			]
		],
		[
			[
				70938,
				41115
			],
			[
				-27,
				-497
			]
		],
		[
			[
				71174,
				39221
			],
			[
				-19,
				-330
			],
			[
				369,
				-79
			]
		],
		[
			[
				71524,
				38812
			],
			[
				94,
				1333
			],
			[
				125,
				-26
			]
		],
		[
			[
				71261,
				40551
			],
			[
				-87,
				-1330
			]
		],
		[
			[
				52692,
				59824
			],
			[
				-7,
				839
			]
		],
		[
			[
				77395,
				60947
			],
			[
				671,
				-182
			]
		],
		[
			[
				77140,
				61668
			],
			[
				-24,
				-210
			],
			[
				81,
				-252
			],
			[
				96,
				-53
			],
			[
				-18,
				-175
			]
		],
		[
			[
				89163,
				21572
			],
			[
				284,
				-181
			],
			[
				-212,
				-977
			],
			[
				347,
				-228
			]
		],
		[
			[
				89706,
				22836
			],
			[
				-11,
				-200
			],
			[
				-177,
				-60
			],
			[
				-91,
				110
			],
			[
				-251,
				-351
			],
			[
				104,
				-216
			],
			[
				-117,
				-547
			]
		],
		[
			[
				56887,
				58975
			],
			[
				419,
				-12
			]
		],
		[
			[
				57486,
				60978
			],
			[
				-602,
				14
			]
		],
		[
			[
				73377,
				58667
			],
			[
				58,
				676
			],
			[
				87,
				313
			]
		],
		[
			[
				73522,
				59656
			],
			[
				-88,
				22
			]
		],
		[
			[
				52182,
				83485
			],
			[
				360,
				548
			],
			[
				-24,
				58
			]
		],
		[
			[
				51363,
				85614
			],
			[
				-248,
				-509
			],
			[
				37,
				-90
			]
		],
		[
			[
				52025,
				88812
			],
			[
				-205,
				-177
			],
			[
				-139,
				-67
			],
			[
				-12,
				-243
			],
			[
				-171,
				-31
			],
			[
				-99,
				-82
			],
			[
				-67,
				-335
			],
			[
				-89,
				-152
			]
		],
		[
			[
				65604,
				29499
			],
			[
				192,
				20
			],
			[
				193,
				-29
			]
		],
		[
			[
				64736,
				29608
			],
			[
				2,
				0
			]
		],
		[
			[
				28759,
				28781
			],
			[
				-57,
				263
			],
			[
				-75,
				2
			],
			[
				-55,
				175
			],
			[
				23,
				256
			],
			[
				-55,
				144
			],
			[
				28,
				364
			],
			[
				85,
				-48
			],
			[
				142,
				41
			],
			[
				6,
				324
			],
			[
				41,
				83
			],
			[
				-34,
				353
			],
			[
				31,
				8
			],
			[
				-6,
				420
			]
		],
		[
			[
				27987,
				30580
			],
			[
				32,
				-245
			],
			[
				49,
				1
			],
			[
				39,
				-280
			],
			[
				-105,
				-228
			],
			[
				13,
				-138
			],
			[
				-63,
				-19
			],
			[
				-9,
				-228
			],
			[
				48,
				-451
			],
			[
				-93,
				-277
			],
			[
				-264,
				186
			],
			[
				-3,
				-180
			]
		],
		[
			[
				76508,
				66376
			],
			[
				134,
				93
			],
			[
				77,
				-451
			],
			[
				53,
				-113
			]
		],
		[
			[
				77108,
				41753
			],
			[
				524,
				-70
			],
			[
				16,
				-25
			]
		],
		[
			[
				77885,
				42673
			],
			[
				-197,
				23
			],
			[
				9,
				227
			],
			[
				-207,
				28
			],
			[
				10,
				171
			],
			[
				-188,
				26
			],
			[
				2,
				56
			]
		],
		[
			[
				69268,
				21380
			],
			[
				125,
				190
			],
			[
				-41,
				80
			],
			[
				-84,
				-270
			]
		],
		[
			[
				68142,
				20150
			],
			[
				194,
				-29
			],
			[
				-34,
				-661
			],
			[
				194,
				-31
			]
		],
		[
			[
				69269,
				19302
			],
			[
				193,
				-30
			],
			[
				59,
				994
			],
			[
				48,
				-9
			],
			[
				30,
				526
			]
		],
		[
			[
				69599,
				20783
			],
			[
				-84,
				36
			],
			[
				-32,
				176
			],
			[
				-68,
				24
			],
			[
				6,
				146
			],
			[
				-62,
				38
			],
			[
				29,
				197
			],
			[
				-151,
				-138
			],
			[
				-20,
				-130
			],
			[
				74,
				-82
			],
			[
				37,
				-313
			],
			[
				74,
				2
			],
			[
				68,
				-298
			],
			[
				-27,
				-138
			],
			[
				-67,
				-22
			],
			[
				-83,
				263
			],
			[
				-217,
				-64
			],
			[
				24,
				183
			],
			[
				-66,
				168
			],
			[
				-16,
				187
			],
			[
				-158,
				128
			],
			[
				-65,
				-28
			],
			[
				-28,
				-397
			],
			[
				-54,
				-100
			],
			[
				-54,
				152
			],
			[
				19,
				354
			],
			[
				-166,
				206
			],
			[
				-120,
				447
			]
		],
		[
			[
				63406,
				57321
			],
			[
				-498,
				45
			]
		],
		[
			[
				62908,
				57366
			],
			[
				-77,
				4
			]
		],
		[
			[
				62436,
				57394
			],
			[
				-24,
				-483
			],
			[
				-9,
				-628
			],
			[
				25,
				-1
			],
			[
				-7,
				-390
			]
		],
		[
			[
				91475,
				28813
			],
			[
				16,
				-7
			]
		],
		[
			[
				88858,
				48825
			],
			[
				58,
				77
			],
			[
				130,
				-82
			],
			[
				22,
				232
			]
		],
		[
			[
				64014,
				72395
			],
			[
				149,
				-10
			],
			[
				21,
				631
			],
			[
				19,
				-130
			],
			[
				168,
				-7
			],
			[
				-14,
				-510
			],
			[
				250,
				4
			],
			[
				7,
				-197
			]
		],
		[
			[
				47899,
				32964
			],
			[
				15,
				-586
			]
		],
		[
			[
				80459,
				41745
			],
			[
				-43,
				145
			],
			[
				5,
				231
			],
			[
				-107,
				108
			]
		],
		[
			[
				79742,
				42388
			],
			[
				-31,
				-387
			],
			[
				-65,
				16
			],
			[
				-47,
				-620
			],
			[
				129,
				-28
			]
		],
		[
			[
				78642,
				56362
			],
			[
				4,
				219
			]
		],
		[
			[
				21743,
				9828
			],
			[
				289,
				-2087
			]
		],
		[
			[
				73595,
				50172
			],
			[
				-394,
				200
			],
			[
				-168,
				-165
			]
		],
		[
			[
				58031,
				26978
			],
			[
				67,
				-241
			],
			[
				-41,
				-92
			]
		],
		[
			[
				58057,
				26645
			],
			[
				613,
				-27
			]
		],
		[
			[
				73868,
				67488
			],
			[
				536,
				-89
			],
			[
				90,
				20
			]
		],
		[
			[
				74709,
				68745
			],
			[
				13,
				81
			]
		],
		[
			[
				74056,
				68956
			],
			[
				-112,
				24
			]
		],
		[
			[
				73944,
				68980
			],
			[
				-76,
				-1492
			]
		],
		[
			[
				63759,
				31406
			],
			[
				127,
				-47
			],
			[
				147,
				31
			],
			[
				113,
				117
			]
		],
		[
			[
				53528,
				18727
			],
			[
				776,
				3
			]
		],
		[
			[
				54325,
				20721
			],
			[
				-2,
				332
			]
		],
		[
			[
				54323,
				21053
			],
			[
				-482,
				-5
			]
		],
		[
			[
				53841,
				21048
			],
			[
				35,
				-237
			],
			[
				-62,
				-519
			],
			[
				-5,
				-421
			],
			[
				-104,
				-173
			],
			[
				-103,
				-396
			],
			[
				-13,
				-332
			],
			[
				-61,
				-243
			]
		],
		[
			[
				81253,
				37794
			],
			[
				756,
				-203
			]
		],
		[
			[
				64787,
				48828
			],
			[
				-9,
				238
			],
			[
				-105,
				270
			]
		],
		[
			[
				53191,
				69608
			],
			[
				833,
				39
			]
		],
		[
			[
				54024,
				69647
			],
			[
				0,
				218
			],
			[
				165,
				-41
			]
		],
		[
			[
				73522,
				59656
			],
			[
				172,
				-86
			],
			[
				59,
				-92
			]
		],
		[
			[
				41957,
				62845
			],
			[
				38,
				-881
			]
		],
		[
			[
				75688,
				49899
			],
			[
				-250,
				-663
			]
		],
		[
			[
				75438,
				49236
			],
			[
				155,
				-334
			]
		],
		[
			[
				65320,
				51808
			],
			[
				64,
				13
			],
			[
				94,
				-149
			],
			[
				117,
				183
			],
			[
				74,
				23
			],
			[
				121,
				156
			]
		],
		[
			[
				66093,
				52897
			],
			[
				0,
				18
			]
		],
		[
			[
				66093,
				52915
			],
			[
				-125,
				-18
			],
			[
				-23,
				-108
			],
			[
				-119,
				89
			],
			[
				-332,
				-23
			]
		],
		[
			[
				86104,
				28906
			],
			[
				176,
				-74
			],
			[
				-163,
				-231
			]
		],
		[
			[
				86879,
				29177
			],
			[
				-64,
				112
			],
			[
				102,
				47
			],
			[
				18,
				257
			],
			[
				-172,
				-23
			],
			[
				-103,
				37
			],
			[
				24,
				209
			],
			[
				-205,
				-1
			]
		],
		[
			[
				86479,
				29815
			],
			[
				-131,
				42
			]
		],
		[
			[
				86042,
				34735
			],
			[
				264,
				-166
			],
			[
				2,
				-240
			],
			[
				109,
				0
			]
		],
		[
			[
				86417,
				34329
			],
			[
				92,
				228
			],
			[
				6,
				398
			],
			[
				72,
				196
			],
			[
				103,
				94
			]
		],
		[
			[
				86559,
				46666
			],
			[
				353,
				-1125
			]
		],
		[
			[
				86987,
				45521
			],
			[
				128,
				-66
			],
			[
				20,
				235
			],
			[
				64,
				-52
			],
			[
				189,
				120
			]
		],
		[
			[
				87156,
				47257
			],
			[
				-86,
				76
			],
			[
				-132,
				-417
			],
			[
				-62,
				65
			],
			[
				-120,
				-87
			],
			[
				-197,
				-228
			]
		],
		[
			[
				48906,
				23823
			],
			[
				-866,
				-66
			]
		],
		[
			[
				58510,
				64696
			],
			[
				6,
				1216
			]
		],
		[
			[
				58516,
				65912
			],
			[
				-585,
				-1
			],
			[
				27,
				628
			]
		],
		[
			[
				66929,
				52696
			],
			[
				38,
				1028
			]
		],
		[
			[
				66967,
				53724
			],
			[
				-359,
				24
			]
		],
		[
			[
				66191,
				53799
			],
			[
				87,
				-48
			],
			[
				19,
				-169
			],
			[
				-40,
				-187
			],
			[
				-150,
				-363
			],
			[
				-14,
				-117
			]
		],
		[
			[
				49394,
				41485
			],
			[
				-24,
				1194
			]
		],
		[
			[
				19699,
				54140
			],
			[
				-276,
				-709
			]
		],
		[
			[
				87806,
				58111
			],
			[
				60,
				-63
			],
			[
				249,
				-26
			],
			[
				269,
				157
			],
			[
				56,
				-144
			],
			[
				114,
				11
			],
			[
				99,
				118
			],
			[
				79,
				277
			],
			[
				66,
				75
			]
		],
		[
			[
				88957,
				59034
			],
			[
				-206,
				274
			],
			[
				-124,
				246
			],
			[
				-308,
				478
			]
		],
		[
			[
				88319,
				60032
			],
			[
				-120,
				-101
			],
			[
				-293,
				-861
			]
		],
		[
			[
				76579,
				54878
			],
			[
				360,
				-67
			]
		],
		[
			[
				54024,
				69647
			],
			[
				13,
				-1263
			]
		],
		[
			[
				82141,
				57751
			],
			[
				788,
				-205
			]
		],
		[
			[
				82929,
				57546
			],
			[
				-258,
				1315
			]
		],
		[
			[
				82671,
				58861
			],
			[
				-89,
				-66
			]
		],
		[
			[
				59430,
				24358
			],
			[
				56,
				-2
			],
			[
				17,
				891
			],
			[
				-56,
				3
			]
		],
		[
			[
				81723,
				59263
			],
			[
				-515,
				83
			]
		],
		[
			[
				60201,
				59027
			],
			[
				-637,
				8
			]
		],
		[
			[
				59564,
				59035
			],
			[
				-11,
				-1454
			]
		],
		[
			[
				68363,
				49952
			],
			[
				-53,
				-1205
			]
		],
		[
			[
				84587,
				36518
			],
			[
				-147,
				301
			],
			[
				115,
				184
			],
			[
				-25,
				242
			],
			[
				85,
				40
			],
			[
				54,
				249
			],
			[
				-130,
				731
			]
		],
		[
			[
				80521,
				65882
			],
			[
				564,
				-1245
			]
		],
		[
			[
				81085,
				64637
			],
			[
				125,
				-277
			]
		],
		[
			[
				81988,
				65028
			],
			[
				-283,
				709
			]
		],
		[
			[
				71761,
				51981
			],
			[
				168,
				-827
			]
		],
		[
			[
				72645,
				50974
			],
			[
				26,
				141
			],
			[
				93,
				146
			]
		],
		[
			[
				72465,
				52450
			],
			[
				-273,
				12
			]
		],
		[
			[
				72192,
				52462
			],
			[
				-22,
				-175
			],
			[
				-122,
				-254
			],
			[
				-101,
				60
			],
			[
				-186,
				-112
			]
		],
		[
			[
				75407,
				27636
			],
			[
				-554,
				158
			],
			[
				-29,
				-240
			]
		],
		[
			[
				74824,
				27554
			],
			[
				-48,
				-147
			],
			[
				67,
				-453
			],
			[
				37,
				159
			],
			[
				121,
				-229
			],
			[
				-128,
				-71
			],
			[
				142,
				-24
			],
			[
				74,
				-233
			],
			[
				126,
				-63
			],
			[
				202,
				-177
			],
			[
				26,
				-120
			],
			[
				164,
				-137
			],
			[
				210,
				133
			],
			[
				96,
				108
			],
			[
				129,
				379
			],
			[
				66,
				105
			],
			[
				110,
				601
			]
		],
		[
			[
				36620,
				7749
			],
			[
				1557,
				328
			]
		],
		[
			[
				36673,
				13674
			],
			[
				33,
				-221
			],
			[
				-30,
				-226
			],
			[
				-197,
				-160
			],
			[
				-176,
				31
			],
			[
				-201,
				-59
			]
		],
		[
			[
				36102,
				13039
			],
			[
				-61,
				49
			],
			[
				-249,
				-260
			],
			[
				-73,
				26
			],
			[
				-146,
				-72
			],
			[
				-77,
				-133
			],
			[
				-66,
				1
			],
			[
				-7,
				-142
			],
			[
				-145,
				-234
			]
		],
		[
			[
				35278,
				12274
			],
			[
				60,
				-707
			],
			[
				393,
				100
			],
			[
				47,
				-259
			],
			[
				256,
				113
			],
			[
				70,
				-913
			],
			[
				34,
				8
			],
			[
				65,
				-847
			],
			[
				90,
				107
			],
			[
				42,
				-548
			],
			[
				40,
				9
			],
			[
				49,
				-652
			],
			[
				89,
				20
			],
			[
				49,
				-642
			],
			[
				33,
				8
			],
			[
				25,
				-322
			]
		],
		[
			[
				75300,
				46712
			],
			[
				-35,
				-1870
			]
		],
		[
			[
				48932,
				25159
			],
			[
				159,
				11
			]
		],
		[
			[
				80668,
				51402
			],
			[
				181,
				-219
			],
			[
				225,
				-117
			]
		],
		[
			[
				82929,
				57546
			],
			[
				199,
				-63
			]
		],
		[
			[
				83075,
				58704
			],
			[
				-129,
				156
			],
			[
				-147,
				56
			],
			[
				-128,
				-55
			]
		],
		[
			[
				51566,
				30657
			],
			[
				-242,
				-6
			]
		],
		[
			[
				40868,
				75447
			],
			[
				999,
				126
			]
		],
		[
			[
				93139,
				22241
			],
			[
				87,
				-368
			],
			[
				87,
				226
			],
			[
				130,
				-133
			]
		],
		[
			[
				79705,
				52849
			],
			[
				-70,
				171
			],
			[
				-101,
				123
			],
			[
				-65,
				-39
			],
			[
				-161,
				135
			],
			[
				-27,
				226
			],
			[
				-144,
				131
			],
			[
				-228,
				311
			]
		],
		[
			[
				58278,
				26264
			],
			[
				-141,
				80
			],
			[
				-70,
				130
			],
			[
				-10,
				171
			]
		],
		[
			[
				46752,
				83837
			],
			[
				-50,
				2050
			]
		],
		[
			[
				45614,
				85810
			],
			[
				-200,
				-359
			],
			[
				-30,
				-264
			]
		],
		[
			[
				13284,
				60870
			],
			[
				104,
				59
			],
			[
				154,
				243
			],
			[
				128,
				138
			],
			[
				67,
				310
			],
			[
				-46,
				127
			],
			[
				-78,
				-116
			],
			[
				-161,
				-89
			],
			[
				-26,
				-132
			],
			[
				34,
				-236
			],
			[
				-119,
				-103
			],
			[
				-57,
				-201
			]
		],
		[
			[
				13043,
				62541
			],
			[
				57,
				11
			],
			[
				104,
				480
			],
			[
				186,
				479
			],
			[
				-142,
				26
			],
			[
				-102,
				-244
			],
			[
				-103,
				-752
			]
		],
		[
			[
				13489,
				55752
			],
			[
				63,
				-82
			],
			[
				934,
				380
			],
			[
				1169,
				460
			]
		],
		[
			[
				15001,
				59715
			],
			[
				-347,
				-137
			],
			[
				-22,
				162
			],
			[
				-176,
				153
			],
			[
				-156,
				345
			]
		],
		[
			[
				14300,
				60238
			],
			[
				-107,
				-123
			],
			[
				-25,
				148
			],
			[
				-145,
				18
			],
			[
				-254,
				-256
			],
			[
				-14,
				-135
			],
			[
				100,
				-221
			],
			[
				-38,
				-409
			],
			[
				-92,
				-383
			],
			[
				-81,
				-90
			],
			[
				-319,
				-95
			],
			[
				-124,
				71
			],
			[
				-71,
				-161
			],
			[
				-155,
				-109
			]
		],
		[
			[
				82227,
				64078
			],
			[
				196,
				205
			],
			[
				68,
				-26
			],
			[
				105,
				117
			],
			[
				161,
				-27
			],
			[
				232,
				55
			]
		],
		[
			[
				82989,
				64402
			],
			[
				77,
				208
			],
			[
				116,
				125
			]
		],
		[
			[
				83282,
				65040
			],
			[
				-5,
				90
			],
			[
				-99,
				-87
			],
			[
				-108,
				289
			],
			[
				26,
				121
			],
			[
				-79,
				3
			],
			[
				-82,
				-175
			],
			[
				-237,
				-256
			],
			[
				-140,
				-22
			],
			[
				-103,
				71
			],
			[
				-223,
				-323
			]
		],
		[
			[
				80319,
				64294
			],
			[
				68,
				-50
			]
		],
		[
			[
				80387,
				64244
			],
			[
				202,
				-105
			],
			[
				72,
				306
			],
			[
				69,
				82
			],
			[
				355,
				110
			]
		],
		[
			[
				80311,
				65684
			],
			[
				24,
				-151
			],
			[
				-147,
				-183
			],
			[
				-36,
				-284
			],
			[
				86,
				-272
			],
			[
				-91,
				-178
			],
			[
				-18,
				-181
			],
			[
				190,
				-141
			]
		],
		[
			[
				91476,
				26056
			],
			[
				386,
				-143
			]
		],
		[
			[
				58305,
				62590
			],
			[
				0,
				-67
			],
			[
				196,
				0
			],
			[
				1,
				-346
			],
			[
				193,
				16
			],
			[
				0,
				-451
			],
			[
				108,
				248
			],
			[
				70,
				-44
			]
		],
		[
			[
				65070,
				81556
			],
			[
				10,
				-212
			]
		],
		[
			[
				65356,
				81104
			],
			[
				353,
				-103
			],
			[
				279,
				202
			]
		],
		[
			[
				65988,
				81203
			],
			[
				14,
				170
			]
		],
		[
			[
				28319,
				13840
			],
			[
				76,
				69
			],
			[
				73,
				370
			],
			[
				87,
				94
			],
			[
				125,
				-47
			],
			[
				-184,
				1759
			]
		],
		[
			[
				27631,
				17080
			],
			[
				-186,
				-152
			],
			[
				-36,
				-282
			],
			[
				-170,
				-108
			],
			[
				102,
				-224
			],
			[
				-45,
				-157
			],
			[
				-13,
				-252
			],
			[
				68,
				-30
			],
			[
				60,
				-158
			],
			[
				-45,
				-60
			],
			[
				34,
				-279
			],
			[
				-88,
				-573
			],
			[
				60,
				-78
			],
			[
				108,
				-368
			],
			[
				-24,
				-132
			]
		],
		[
			[
				83951,
				28401
			],
			[
				80,
				738
			],
			[
				-32,
				216
			],
			[
				-78,
				165
			],
			[
				41,
				213
			]
		],
		[
			[
				63151,
				66929
			],
			[
				58,
				119
			],
			[
				127,
				-79
			],
			[
				-50,
				173
			],
			[
				204,
				-85
			],
			[
				56,
				67
			],
			[
				143,
				-13
			],
			[
				-100,
				-228
			],
			[
				-27,
				-211
			],
			[
				135,
				-193
			]
		],
		[
			[
				53430,
				22707
			],
			[
				911,
				11
			]
		],
		[
			[
				37694,
				58109
			],
			[
				79,
				-82
			],
			[
				145,
				-340
			],
			[
				54,
				-223
			],
			[
				-13,
				-178
			],
			[
				128,
				-77
			]
		],
		[
			[
				79912,
				66890
			],
			[
				55,
				-15
			]
		],
		[
			[
				70586,
				60553
			],
			[
				-160,
				-19
			]
		],
		[
			[
				64664,
				49380
			],
			[
				218,
				343
			],
			[
				1,
				54
			],
			[
				202,
				322
			],
			[
				3,
				56
			],
			[
				213,
				-19
			],
			[
				-16,
				135
			],
			[
				53,
				198
			]
		],
		[
			[
				65338,
				50469
			],
			[
				-239,
				16
			],
			[
				13,
				337
			],
			[
				-290,
				215
			]
		],
		[
			[
				64737,
				50892
			],
			[
				-188,
				-313
			],
			[
				-47,
				-419
			],
			[
				47,
				-253
			]
		],
		[
			[
				54135,
				9742
			],
			[
				1612,
				1
			]
		],
		[
			[
				54624,
				42167
			],
			[
				-668,
				-2
			]
		],
		[
			[
				54535,
				37697
			],
			[
				44,
				117
			],
			[
				-35,
				120
			],
			[
				58,
				182
			],
			[
				-25,
				105
			],
			[
				71,
				147
			],
			[
				43,
				-124
			],
			[
				52,
				132
			]
		],
		[
			[
				54189,
				38813
			],
			[
				1,
				-168
			],
			[
				-64,
				-1
			],
			[
				-96,
				-168
			],
			[
				-30,
				-169
			],
			[
				0,
				-615
			]
		],
		[
			[
				39747,
				80060
			],
			[
				736,
				-873
			]
		],
		[
			[
				42507,
				82459
			],
			[
				-90,
				118
			],
			[
				-54,
				295
			],
			[
				-198,
				-89
			],
			[
				-100,
				113
			],
			[
				-49,
				-44
			],
			[
				-243,
				120
			],
			[
				-18,
				175
			],
			[
				-90,
				123
			],
			[
				-12,
				161
			],
			[
				-56,
				10
			],
			[
				-13,
				175
			],
			[
				-77,
				91
			],
			[
				-93,
				619
			],
			[
				-71,
				9
			],
			[
				-68,
				349
			],
			[
				65,
				78
			],
			[
				-98,
				183
			],
			[
				-160,
				49
			],
			[
				-40,
				175
			],
			[
				-178,
				238
			],
			[
				-41,
				272
			],
			[
				-219,
				-52
			],
			[
				-105,
				22
			],
			[
				-91,
				-164
			],
			[
				-185,
				-103
			],
			[
				-134,
				-313
			],
			[
				-101,
				-151
			],
			[
				-169,
				-39
			],
			[
				-148,
				-144
			],
			[
				-122,
				-280
			]
		],
		[
			[
				83013,
				53455
			],
			[
				548,
				-159
			]
		],
		[
			[
				83561,
				53296
			],
			[
				358,
				-101
			]
		],
		[
			[
				86559,
				46666
			],
			[
				-85,
				-39
			]
		],
		[
			[
				74302,
				49161
			],
			[
				127,
				69
			],
			[
				67,
				199
			]
		],
		[
			[
				74496,
				49429
			],
			[
				-30,
				444
			]
		],
		[
			[
				80605,
				60483
			],
			[
				121,
				17
			],
			[
				49,
				90
			],
			[
				64,
				-69
			],
			[
				195,
				30
			],
			[
				54,
				92
			]
		],
		[
			[
				81088,
				60643
			],
			[
				-32,
				77
			]
		],
		[
			[
				12163,
				2937
			],
			[
				1598,
				829
			],
			[
				-9,
				52
			],
			[
				807,
				407
			],
			[
				123,
				-722
			],
			[
				31,
				16
			]
		],
		[
			[
				14713,
				3519
			],
			[
				61,
				101
			],
			[
				-29,
				187
			],
			[
				56,
				5
			],
			[
				45,
				-155
			],
			[
				-55,
				-143
			],
			[
				24,
				-125
			],
			[
				188,
				-35
			],
			[
				0,
				104
			],
			[
				-94,
				69
			],
			[
				24,
				183
			],
			[
				135,
				-166
			],
			[
				-12,
				334
			],
			[
				-83,
				-5
			],
			[
				36,
				424
			],
			[
				54,
				96
			],
			[
				-133,
				11
			],
			[
				-15,
				137
			],
			[
				-105,
				70
			],
			[
				-49,
				191
			],
			[
				-119,
				203
			],
			[
				-19,
				-72
			],
			[
				120,
				-324
			],
			[
				-89,
				16
			],
			[
				-138,
				337
			],
			[
				-164,
				190
			]
		],
		[
			[
				14352,
				5152
			],
			[
				-763,
				-388
			],
			[
				-56,
				325
			]
		],
		[
			[
				13533,
				5089
			],
			[
				-789,
				-408
			],
			[
				10,
				-58
			],
			[
				-446,
				-231
			]
		],
		[
			[
				12308,
				4392
			],
			[
				28,
				-830
			],
			[
				-52,
				-120
			],
			[
				12,
				-182
			],
			[
				-133,
				-323
			]
		],
		[
			[
				15053,
				61946
			],
			[
				-62,
				-236
			],
			[
				-116,
				-124
			],
			[
				-84,
				-354
			],
			[
				-140,
				-256
			],
			[
				-83,
				-86
			],
			[
				-268,
				-652
			]
		],
		[
			[
				83624,
				81002
			],
			[
				359,
				983
			],
			[
				578,
				1220
			]
		],
		[
			[
				84561,
				83205
			],
			[
				-456,
				129
			],
			[
				69,
				666
			],
			[
				-44,
				12
			]
		],
		[
			[
				83301,
				83207
			],
			[
				22,
				-73
			],
			[
				-34,
				-330
			],
			[
				-223,
				-249
			],
			[
				-31,
				-118
			],
			[
				-69,
				14
			],
			[
				-84,
				-283
			],
			[
				-171,
				-70
			],
			[
				-76,
				-252
			]
		],
		[
			[
				87963,
				44038
			],
			[
				19,
				136
			]
		],
		[
			[
				87982,
				44174
			],
			[
				-175,
				25
			],
			[
				-104,
				553
			],
			[
				50,
				71
			]
		],
		[
			[
				87753,
				44823
			],
			[
				-27,
				102
			],
			[
				82,
				276
			],
			[
				-151,
				-94
			],
			[
				-118,
				-178
			],
			[
				-113,
				-348
			],
			[
				-287,
				436
			],
			[
				-83,
				-61
			],
			[
				-85,
				-361
			],
			[
				31,
				-287
			],
			[
				146,
				-377
			]
		],
		[
			[
				66241,
				57653
			],
			[
				-118,
				0
			],
			[
				3,
				121
			],
			[
				142,
				133
			],
			[
				-88,
				130
			],
			[
				-194,
				-29
			],
			[
				-16,
				64
			],
			[
				143,
				182
			]
		],
		[
			[
				42090,
				59825
			],
			[
				-598,
				-80
			]
		],
		[
			[
				35769,
				75670
			],
			[
				-154,
				-74
			],
			[
				-221,
				-300
			],
			[
				-58,
				-301
			],
			[
				-60,
				-92
			],
			[
				-29,
				-230
			],
			[
				-118,
				-454
			],
			[
				-125,
				-150
			],
			[
				-75,
				49
			],
			[
				-65,
				-146
			]
		],
		[
			[
				77803,
				45853
			],
			[
				206,
				-51
			],
			[
				-27,
				-736
			]
		],
		[
			[
				63131,
				61433
			],
			[
				-429,
				23
			],
			[
				-4,
				-340
			]
		],
		[
			[
				66606,
				49341
			],
			[
				769,
				-102
			]
		],
		[
			[
				67375,
				49239
			],
			[
				32,
				841
			]
		],
		[
			[
				75593,
				48902
			],
			[
				-91,
				-65
			],
			[
				-64,
				-150
			],
			[
				-114,
				-51
			],
			[
				-102,
				-287
			],
			[
				-52,
				-54
			]
		],
		[
			[
				79773,
				63999
			],
			[
				68,
				343
			],
			[
				96,
				-208
			],
			[
				95,
				133
			],
			[
				280,
				-89
			],
			[
				7,
				116
			]
		],
		[
			[
				79376,
				64518
			],
			[
				-73,
				-209
			]
		],
		[
			[
				73483,
				67548
			],
			[
				-28,
				90
			],
			[
				163,
				-1
			],
			[
				25,
				-118
			],
			[
				225,
				-31
			]
		],
		[
			[
				73944,
				68980
			],
			[
				-593,
				114
			],
			[
				-61,
				69
			]
		],
		[
			[
				56513,
				57018
			],
			[
				678,
				-4
			]
		],
		[
			[
				56504,
				58981
			],
			[
				8,
				-338
			],
			[
				-6,
				-1005
			]
		],
		[
			[
				80145,
				53973
			],
			[
				63,
				-121
			]
		],
		[
			[
				82509,
				89851
			],
			[
				-390,
				93
			],
			[
				-54,
				-667
			],
			[
				-772,
				176
			]
		],
		[
			[
				81293,
				89453
			],
			[
				-112,
				-183
			],
			[
				-131,
				-333
			],
			[
				90,
				137
			],
			[
				96,
				-120
			],
			[
				57,
				-252
			],
			[
				94,
				-253
			]
		],
		[
			[
				54830,
				49050
			],
			[
				-1,
				-505
			]
		],
		[
			[
				50461,
				45757
			],
			[
				27,
				-1677
			]
		],
		[
			[
				95398,
				26165
			],
			[
				98,
				-142
			],
			[
				118,
				117
			]
		],
		[
			[
				95692,
				26160
			],
			[
				118,
				120
			],
			[
				36,
				139
			],
			[
				165,
				243
			],
			[
				2,
				304
			],
			[
				-68,
				51
			],
			[
				107,
				137
			],
			[
				193,
				33
			],
			[
				86,
				243
			],
			[
				9,
				187
			]
		],
		[
			[
				96227,
				28039
			],
			[
				-132,
				-8
			],
			[
				29,
				189
			],
			[
				-63,
				-8
			],
			[
				15,
				204
			],
			[
				-78,
				-23
			],
			[
				-38,
				129
			]
		],
		[
			[
				63132,
				18497
			],
			[
				66,
				-28
			],
			[
				138,
				76
			]
		],
		[
			[
				27005,
				65626
			],
			[
				75,
				504
			],
			[
				18,
				336
			],
			[
				208,
				1112
			],
			[
				144,
				-180
			],
			[
				184,
				-337
			],
			[
				77,
				45
			],
			[
				173,
				-146
			],
			[
				109,
				-12
			]
		],
		[
			[
				27993,
				66948
			],
			[
				-235,
				2587
			]
		],
		[
			[
				86764,
				41233
			],
			[
				105,
				-96
			],
			[
				136,
				26
			],
			[
				88,
				-83
			],
			[
				153,
				1
			]
		],
		[
			[
				71110,
				24292
			],
			[
				75,
				10
			],
			[
				69,
				-479
			],
			[
				-55,
				-11
			],
			[
				51,
				-313
			],
			[
				66,
				-78
			],
			[
				-17,
				555
			],
			[
				-72,
				159
			],
			[
				-13,
				195
			],
			[
				80,
				30
			],
			[
				30,
				-219
			],
			[
				76,
				-231
			]
		],
		[
			[
				71400,
				23910
			],
			[
				95,
				-17
			],
			[
				89,
				165
			]
		],
		[
			[
				91624,
				36472
			],
			[
				9,
				479
			],
			[
				58,
				833
			],
			[
				-37,
				307
			],
			[
				-135,
				748
			],
			[
				-51,
				77
			]
		],
		[
			[
				91264,
				38769
			],
			[
				25,
				-163
			],
			[
				-89,
				-667
			],
			[
				-425,
				-1043
			]
		],
		[
			[
				77957,
				35441
			],
			[
				184,
				41
			],
			[
				189,
				-62
			],
			[
				213,
				-341
			],
			[
				158,
				-317
			]
		],
		[
			[
				78701,
				34762
			],
			[
				23,
				233
			],
			[
				159,
				-41
			]
		],
		[
			[
				78499,
				36195
			],
			[
				-317,
				90
			]
		],
		[
			[
				80787,
				78990
			],
			[
				601,
				-175
			]
		],
		[
			[
				81129,
				69161
			],
			[
				78,
				9
			],
			[
				62,
				120
			],
			[
				176,
				189
			],
			[
				281,
				149
			]
		],
		[
			[
				65338,
				50469
			],
			[
				340,
				-33
			]
		],
		[
			[
				86702,
				47734
			],
			[
				-140,
				50
			],
			[
				-156,
				-38
			]
		],
		[
			[
				78827,
				72629
			],
			[
				-49,
				-126
			],
			[
				-137,
				-157
			],
			[
				-49,
				-246
			],
			[
				14,
				-113
			],
			[
				-86,
				-84
			]
		],
		[
			[
				82332,
				85519
			],
			[
				585,
				-158
			]
		],
		[
			[
				71214,
				52189
			],
			[
				23,
				-141
			],
			[
				72,
				25
			],
			[
				33,
				-133
			],
			[
				191,
				186
			],
			[
				111,
				-5
			],
			[
				-16,
				-91
			],
			[
				133,
				-49
			]
		],
		[
			[
				72192,
				52462
			],
			[
				63,
				203
			],
			[
				-90,
				69
			],
			[
				-8,
				116
			],
			[
				-130,
				85
			]
		],
		[
			[
				66051,
				41207
			],
			[
				-100,
				-70
			],
			[
				-26,
				-509
			],
			[
				-377,
				47
			]
		],
		[
			[
				76391,
				42872
			],
			[
				-684,
				72
			]
		],
		[
			[
				57098,
				43669
			],
			[
				3,
				335
			]
		],
		[
			[
				58634,
				58362
			],
			[
				282,
				2
			],
			[
				-8,
				109
			],
			[
				81,
				58
			],
			[
				0,
				223
			],
			[
				63,
				57
			],
			[
				64,
				227
			],
			[
				128,
				-1
			]
		],
		[
			[
				59244,
				59037
			],
			[
				2,
				168
			],
			[
				62,
				0
			],
			[
				-3,
				437
			],
			[
				-94,
				167
			],
			[
				-31,
				170
			],
			[
				15,
				455
			]
		],
		[
			[
				58877,
				60428
			],
			[
				-386,
				-6
			],
			[
				0,
				55
			]
		],
		[
			[
				88319,
				60032
			],
			[
				-216,
				460
			],
			[
				-76,
				218
			]
		],
		[
			[
				34802,
				15937
			],
			[
				575,
				139
			]
		],
		[
			[
				35377,
				16076
			],
			[
				1227,
				246
			]
		],
		[
			[
				81126,
				75521
			],
			[
				96,
				-23
			],
			[
				17,
				-257
			],
			[
				245,
				45
			]
		],
		[
			[
				81493,
				77940
			],
			[
				-228,
				71
			],
			[
				-97,
				-230
			],
			[
				-3,
				-234
			],
			[
				-85,
				-210
			],
			[
				32,
				-99
			]
		],
		[
			[
				71111,
				51828
			],
			[
				31,
				21
			],
			[
				-127,
				-513
			],
			[
				28,
				-197
			]
		],
		[
			[
				65553,
				54678
			],
			[
				179,
				-19
			]
		],
		[
			[
				40034,
				61854
			],
			[
				-79,
				1393
			]
		],
		[
			[
				86417,
				34329
			],
			[
				11,
				-74
			],
			[
				148,
				-125
			],
			[
				93,
				-19
			]
		],
		[
			[
				86954,
				36034
			],
			[
				-77,
				46
			],
			[
				-213,
				244
			],
			[
				-105,
				-34
			]
		],
		[
			[
				55117,
				82346
			],
			[
				-3,
				-595
			]
		],
		[
			[
				63808,
				27372
			],
			[
				653,
				-72
			]
		],
		[
			[
				63878,
				29353
			],
			[
				-57,
				-1623
			]
		],
		[
			[
				89013,
				42152
			],
			[
				-13,
				-224
			],
			[
				64,
				-189
			],
			[
				51,
				-375
			],
			[
				130,
				-185
			]
		],
		[
			[
				89437,
				42345
			],
			[
				118,
				724
			]
		],
		[
			[
				89555,
				43069
			],
			[
				-117,
				-157
			],
			[
				-233,
				32
			],
			[
				-70,
				127
			]
		],
		[
			[
				66967,
				53724
			],
			[
				6,
				137
			],
			[
				69,
				-75
			],
			[
				132,
				17
			]
		],
		[
			[
				67174,
				53803
			],
			[
				15,
				292
			]
		],
		[
			[
				59353,
				71005
			],
			[
				-529,
				20
			]
		],
		[
			[
				65019,
				69399
			],
			[
				-60,
				-1852
			]
		],
		[
			[
				65535,
				69331
			],
			[
				-278,
				-132
			],
			[
				82,
				129
			],
			[
				-91,
				151
			]
		],
		[
			[
				47374,
				67698
			],
			[
				297,
				21
			],
			[
				5,
				73
			],
			[
				142,
				-5
			],
			[
				98,
				87
			],
			[
				13,
				-104
			],
			[
				171,
				164
			],
			[
				44,
				-55
			],
			[
				152,
				146
			],
			[
				31,
				130
			]
		],
		[
			[
				13113,
				95377
			],
			[
				93,
				23
			],
			[
				25,
				-312
			],
			[
				118,
				-262
			],
			[
				136,
				-147
			],
			[
				168,
				-260
			],
			[
				74,
				-747
			],
			[
				79,
				-59
			],
			[
				-57,
				-145
			],
			[
				48,
				-302
			],
			[
				79,
				-133
			]
		],
		[
			[
				14000,
				92668
			],
			[
				-53,
				24
			]
		],
		[
			[
				14459,
				89984
			],
			[
				584,
				19
			],
			[
				373,
				-3
			]
		],
		[
			[
				15078,
				93038
			],
			[
				1,
				251
			],
			[
				-67,
				132
			],
			[
				-223,
				90
			],
			[
				-134,
				127
			],
			[
				-4,
				426
			],
			[
				-153,
				99
			],
			[
				-56,
				264
			],
			[
				-106,
				15
			],
			[
				-27,
				98
			],
			[
				-99,
				-22
			],
			[
				-110,
				244
			],
			[
				-61,
				227
			],
			[
				167,
				15
			]
		],
		[
			[
				14206,
				95004
			],
			[
				-90,
				231
			],
			[
				-141,
				15
			],
			[
				-142,
				254
			],
			[
				-72,
				-133
			],
			[
				-137,
				326
			],
			[
				-23,
				-91
			],
			[
				-80,
				128
			],
			[
				12,
				136
			],
			[
				-101,
				-74
			],
			[
				-95,
				40
			],
			[
				-76,
				182
			],
			[
				155,
				167
			],
			[
				-162,
				174
			],
			[
				-21,
				142
			],
			[
				-59,
				-166
			],
			[
				-5,
				187
			],
			[
				-85,
				-70
			],
			[
				-28,
				88
			],
			[
				-262,
				72
			],
			[
				-44,
				292
			]
		],
		[
			[
				74213,
				40789
			],
			[
				46,
				602
			]
		],
		[
			[
				62829,
				41374
			],
			[
				-105,
				7
			],
			[
				-167,
				125
			],
			[
				-89,
				266
			],
			[
				76,
				159
			],
			[
				-12,
				427
			],
			[
				-68,
				49
			]
		],
		[
			[
				81983,
				69147
			],
			[
				-66,
				-115
			],
			[
				38,
				-88
			],
			[
				-79,
				-183
			],
			[
				17,
				-171
			]
		],
		[
			[
				55277,
				37002
			],
			[
				-770,
				-10
			]
		],
		[
			[
				89163,
				21572
			],
			[
				-384,
				235
			]
		],
		[
			[
				88035,
				18578
			],
			[
				63,
				-44
			],
			[
				540,
				-182
			],
			[
				472,
				-203
			]
		],
		[
			[
				34777,
				7315
			],
			[
				727,
				180
			],
			[
				1116,
				254
			]
		],
		[
			[
				35278,
				12274
			],
			[
				-61,
				-234
			],
			[
				-181,
				-78
			],
			[
				-107,
				51
			],
			[
				-208,
				-96
			],
			[
				-68,
				117
			],
			[
				-181,
				103
			],
			[
				-164,
				-112
			]
		],
		[
			[
				15582,
				3302
			],
			[
				-84,
				-42
			],
			[
				-60,
				131
			],
			[
				17,
				234
			],
			[
				77,
				311
			],
			[
				-114,
				-327
			],
			[
				-62,
				-44
			],
			[
				-6,
				-200
			],
			[
				50,
				-236
			],
			[
				109,
				-28
			],
			[
				73,
				108
			]
		],
		[
			[
				15041,
				3040
			],
			[
				217,
				-393
			],
			[
				42,
				-164
			],
			[
				85,
				2
			],
			[
				0,
				229
			],
			[
				80,
				259
			],
			[
				-85,
				20
			],
			[
				-50,
				-89
			],
			[
				-212,
				227
			],
			[
				124,
				29
			],
			[
				36,
				161
			],
			[
				-31,
				655
			],
			[
				115,
				-252
			],
			[
				57,
				222
			],
			[
				73,
				77
			],
			[
				-12,
				314
			],
			[
				-73,
				159
			],
			[
				-67,
				-75
			],
			[
				-15,
				-298
			],
			[
				-121,
				24
			],
			[
				24,
				-103
			],
			[
				-73,
				-172
			],
			[
				62,
				-284
			],
			[
				2,
				-190
			],
			[
				-92,
				-29
			],
			[
				-86,
				-329
			]
		],
		[
			[
				76662,
				63345
			],
			[
				16,
				232
			],
			[
				51,
				81
			]
		],
		[
			[
				73210,
				48224
			],
			[
				-47,
				137
			],
			[
				50,
				207
			],
			[
				84,
				161
			]
		],
		[
			[
				46609,
				29030
			],
			[
				122,
				-22
			],
			[
				82,
				77
			],
			[
				249,
				427
			],
			[
				144,
				66
			],
			[
				157,
				-206
			],
			[
				189,
				194
			],
			[
				159,
				-51
			],
			[
				49,
				89
			]
		],
		[
			[
				31100,
				89295
			],
			[
				319,
				-28
			],
			[
				226,
				-428
			],
			[
				104,
				-47
			],
			[
				93,
				317
			],
			[
				181,
				378
			],
			[
				-9,
				267
			],
			[
				131,
				181
			],
			[
				29,
				-187
			],
			[
				88,
				8
			],
			[
				-34,
				208
			],
			[
				62,
				194
			],
			[
				121,
				208
			],
			[
				-123,
				138
			],
			[
				-116,
				-64
			],
			[
				-118,
				69
			],
			[
				-144,
				-175
			],
			[
				-192,
				-37
			],
			[
				-275,
				51
			],
			[
				-57,
				-273
			],
			[
				-81,
				-117
			],
			[
				-27,
				-166
			],
			[
				-85,
				-151
			],
			[
				-2,
				-199
			],
			[
				-91,
				-147
			]
		],
		[
			[
				14352,
				5152
			],
			[
				-277,
				447
			],
			[
				-136,
				337
			],
			[
				181,
				110
			],
			[
				197,
				-53
			],
			[
				78,
				-97
			],
			[
				-303,
				66
			],
			[
				-78,
				-130
			],
			[
				231,
				-394
			]
		],
		[
			[
				14454,
				6196
			],
			[
				-141,
				266
			],
			[
				-1,
				244
			],
			[
				-55,
				133
			]
		],
		[
			[
				13702,
				6890
			],
			[
				-423,
				-205
			],
			[
				110,
				-640
			],
			[
				-23,
				-11
			],
			[
				167,
				-945
			]
		],
		[
			[
				63100,
				66680
			],
			[
				-17,
				-206
			],
			[
				-116,
				-23
			],
			[
				-33,
				-279
			],
			[
				-119,
				-43
			],
			[
				-219,
				-2
			],
			[
				-13,
				-959
			]
		],
		[
			[
				60319,
				31639
			],
			[
				766,
				-51
			]
		],
		[
			[
				61097,
				32088
			],
			[
				16,
				675
			]
		],
		[
			[
				62849,
				78479
			],
			[
				120,
				-7
			],
			[
				58,
				87
			]
		],
		[
			[
				63021,
				78651
			],
			[
				38,
				156
			],
			[
				-24,
				278
			],
			[
				74,
				58
			],
			[
				127,
				-42
			],
			[
				-17,
				-131
			],
			[
				-101,
				-124
			],
			[
				125,
				-4
			],
			[
				68,
				152
			],
			[
				-36,
				325
			],
			[
				-102,
				165
			],
			[
				82,
				68
			],
			[
				196,
				-36
			],
			[
				131,
				-98
			],
			[
				106,
				375
			]
		],
		[
			[
				62996,
				80467
			],
			[
				-107,
				7
			]
		],
		[
			[
				62742,
				79142
			],
			[
				19,
				-61
			],
			[
				-13,
				-429
			],
			[
				101,
				-173
			]
		],
		[
			[
				90072,
				41649
			],
			[
				10,
				80
			],
			[
				262,
				366
			],
			[
				108,
				57
			],
			[
				67,
				-85
			],
			[
				108,
				497
			],
			[
				131,
				779
			]
		],
		[
			[
				89601,
				43347
			],
			[
				-46,
				-278
			]
		],
		[
			[
				82867,
				63045
			],
			[
				-30,
				66
			],
			[
				81,
				169
			],
			[
				-69,
				160
			],
			[
				68,
				115
			],
			[
				8,
				438
			],
			[
				71,
				143
			],
			[
				-7,
				266
			]
		],
		[
			[
				53155,
				78443
			],
			[
				68,
				155
			],
			[
				68,
				532
			],
			[
				50,
				106
			],
			[
				86,
				-23
			],
			[
				-20,
				159
			],
			[
				102,
				179
			],
			[
				-16,
				124
			],
			[
				71,
				109
			],
			[
				-18,
				102
			]
		],
		[
			[
				52886,
				80542
			],
			[
				-370,
				375
			]
		],
		[
			[
				80143,
				48674
			],
			[
				17,
				-780
			],
			[
				138,
				-411
			]
		],
		[
			[
				59492,
				74004
			],
			[
				17,
				-46
			],
			[
				-162,
				-167
			],
			[
				-24,
				-197
			],
			[
				-120,
				-18
			],
			[
				-25,
				-166
			],
			[
				-101,
				-6
			],
			[
				32,
				-138
			],
			[
				-59,
				-235
			],
			[
				-131,
				-162
			],
			[
				56,
				-165
			],
			[
				-38,
				-203
			],
			[
				-50,
				-16
			],
			[
				-5,
				-305
			],
			[
				-81,
				-2
			],
			[
				70,
				-181
			],
			[
				-2,
				-355
			],
			[
				-68,
				-147
			],
			[
				5,
				-470
			]
		],
		[
			[
				12163,
				2937
			],
			[
				-33,
				-350
			],
			[
				67,
				-497
			],
			[
				-17,
				-293
			],
			[
				49,
				-31
			],
			[
				161,
				-495
			],
			[
				-71,
				-237
			],
			[
				119,
				25
			],
			[
				111,
				150
			],
			[
				210,
				440
			],
			[
				136,
				192
			],
			[
				53,
				-4
			],
			[
				168,
				268
			],
			[
				-19,
				57
			],
			[
				159,
				222
			],
			[
				169,
				129
			],
			[
				233,
				70
			],
			[
				141,
				198
			],
			[
				223,
				89
			],
			[
				46,
				130
			],
			[
				212,
				102
			],
			[
				153,
				-121
			],
			[
				114,
				236
			],
			[
				15,
				163
			],
			[
				181,
				49
			],
			[
				-30,
				90
			]
		],
		[
			[
				78083,
				76137
			],
			[
				309,
				-54
			],
			[
				-15,
				-168
			]
		],
		[
			[
				78377,
				75915
			],
			[
				51,
				81
			],
			[
				150,
				52
			]
		],
		[
			[
				52666,
				90930
			],
			[
				-196,
				452
			],
			[
				-167,
				530
			]
		],
		[
			[
				52212,
				91912
			],
			[
				166,
				-446
			],
			[
				73,
				-282
			],
			[
				61,
				-113
			],
			[
				-17,
				-156
			],
			[
				105,
				-287
			]
		],
		[
			[
				51752,
				90879
			],
			[
				63,
				79
			],
			[
				186,
				-48
			],
			[
				-31,
				209
			],
			[
				49,
				158
			],
			[
				226,
				177
			],
			[
				-146,
				513
			]
		],
		[
			[
				28845,
				8299
			],
			[
				1142,
				355
			],
			[
				65,
				-648
			],
			[
				513,
				152
			],
			[
				63,
				41
			]
		],
		[
			[
				31681,
				9492
			],
			[
				-31,
				326
			]
		],
		[
			[
				81961,
				90993
			],
			[
				-175,
				-397
			],
			[
				-87,
				-288
			],
			[
				-232,
				-547
			],
			[
				-174,
				-308
			]
		],
		[
			[
				53204,
				82066
			],
			[
				-100,
				169
			],
			[
				-337,
				316
			],
			[
				-10,
				-75
			]
		],
		[
			[
				58581,
				47167
			],
			[
				-18,
				-1225
			]
		],
		[
			[
				64003,
				58159
			],
			[
				9,
				452
			]
		],
		[
			[
				63198,
				58265
			],
			[
				-8,
				-281
			],
			[
				-99,
				-337
			],
			[
				-72,
				4
			],
			[
				-29,
				-196
			],
			[
				-82,
				-89
			]
		],
		[
			[
				80387,
				64244
			],
			[
				108,
				-480
			],
			[
				85,
				-246
			]
		],
		[
			[
				16211,
				52872
			],
			[
				-509,
				-206
			],
			[
				-151,
				-25
			]
		],
		[
			[
				15551,
				52641
			],
			[
				49,
				-277
			],
			[
				39,
				-29
			],
			[
				18,
				-431
			],
			[
				-36,
				-114
			],
			[
				0,
				-517
			],
			[
				38,
				-102
			],
			[
				-56,
				-183
			],
			[
				-3,
				-287
			],
			[
				76,
				-206
			],
			[
				-72,
				-196
			],
			[
				45,
				-119
			],
			[
				-119,
				-103
			],
			[
				-36,
				-221
			],
			[
				33,
				-101
			],
			[
				-67,
				-214
			],
			[
				22,
				-161
			],
			[
				-44,
				-575
			]
		],
		[
			[
				15438,
				48805
			],
			[
				-7,
				-354
			],
			[
				43,
				-5
			],
			[
				32,
				-210
			],
			[
				-42,
				-392
			],
			[
				5,
				-248
			],
			[
				-226,
				-431
			],
			[
				-84,
				-36
			],
			[
				-25,
				-370
			],
			[
				43,
				-150
			],
			[
				-30,
				-179
			],
			[
				-115,
				-134
			],
			[
				76,
				-480
			]
		],
		[
			[
				74773,
				45850
			],
			[
				191,
				532
			]
		],
		[
			[
				71243,
				16655
			],
			[
				266,
				-60
			],
			[
				168,
				-78
			],
			[
				-98,
				295
			],
			[
				10,
				368
			],
			[
				31,
				216
			],
			[
				-41,
				72
			],
			[
				75,
				165
			],
			[
				117,
				-60
			],
			[
				222,
				120
			],
			[
				175,
				-195
			],
			[
				74,
				-4
			],
			[
				71,
				253
			],
			[
				198,
				-140
			],
			[
				49,
				-241
			],
			[
				254,
				-53
			],
			[
				34,
				-134
			],
			[
				150,
				-18
			],
			[
				34,
				93
			],
			[
				-29,
				335
			],
			[
				69,
				383
			],
			[
				25,
				306
			],
			[
				-155,
				41
			],
			[
				-34,
				223
			],
			[
				193,
				-58
			],
			[
				47,
				103
			],
			[
				118,
				48
			],
			[
				-58,
				163
			],
			[
				143,
				199
			],
			[
				71,
				-11
			],
			[
				127,
				113
			],
			[
				26,
				-124
			],
			[
				88,
				96
			],
			[
				15,
				-218
			],
			[
				-92,
				-180
			],
			[
				84,
				30
			],
			[
				207,
				-96
			],
			[
				87,
				36
			],
			[
				97,
				279
			],
			[
				100,
				81
			],
			[
				-41,
				222
			],
			[
				-75,
				81
			],
			[
				-157,
				-91
			],
			[
				-195,
				89
			],
			[
				-156,
				-99
			],
			[
				-208,
				80
			],
			[
				-155,
				-40
			]
		],
		[
			[
				81282,
				31551
			],
			[
				171,
				-230
			],
			[
				306,
				-532
			],
			[
				101,
				-266
			],
			[
				315,
				-412
			]
		],
		[
			[
				81631,
				32505
			],
			[
				-244,
				74
			],
			[
				-105,
				-1028
			]
		],
		[
			[
				33746,
				90799
			],
			[
				108,
				-101
			],
			[
				94,
				166
			]
		],
		[
			[
				78377,
				75915
			],
			[
				35,
				-398
			],
			[
				50,
				-116
			],
			[
				-41,
				-460
			]
		],
		[
			[
				66295,
				16772
			],
			[
				-31,
				414
			],
			[
				57,
				268
			],
			[
				93,
				-299
			],
			[
				199,
				-391
			],
			[
				147,
				-162
			],
			[
				-6,
				88
			],
			[
				-131,
				242
			],
			[
				-52,
				210
			],
			[
				152,
				-298
			],
			[
				198,
				-58
			]
		],
		[
			[
				66921,
				16786
			],
			[
				27,
				558
			],
			[
				82,
				-11
			],
			[
				16,
				326
			],
			[
				-191,
				30
			],
			[
				47,
				982
			]
		],
		[
			[
				66902,
				18671
			],
			[
				-867,
				118
			]
		],
		[
			[
				56307,
				61002
			],
			[
				-6,
				435
			],
			[
				86,
				248
			],
			[
				64,
				9
			]
		],
		[
			[
				67375,
				49239
			],
			[
				-22,
				-506
			]
		],
		[
			[
				73426,
				38485
			],
			[
				671,
				-142
			]
		],
		[
			[
				50033,
				85975
			],
			[
				550,
				-1229
			]
		],
		[
			[
				89237,
				26630
			],
			[
				145,
				9
			],
			[
				191,
				82
			],
			[
				258,
				-144
			],
			[
				35,
				-65
			]
		],
		[
			[
				90031,
				26709
			],
			[
				49,
				227
			],
			[
				-55,
				483
			],
			[
				50,
				203
			],
			[
				3,
				339
			]
		],
		[
			[
				90078,
				27961
			],
			[
				31,
				111
			],
			[
				-151,
				144
			],
			[
				-62,
				-47
			],
			[
				-93,
				112
			]
		],
		[
			[
				56488,
				30691
			],
			[
				8,
				942
			]
		],
		[
			[
				56496,
				31633
			],
			[
				-768,
				11
			]
		],
		[
			[
				81138,
				59359
			],
			[
				-52,
				124
			],
			[
				16,
				348
			],
			[
				-135,
				81
			],
			[
				38,
				195
			],
			[
				-18,
				134
			],
			[
				101,
				402
			]
		],
		[
			[
				27434,
				42886
			],
			[
				-89,
				-85
			],
			[
				-197,
				-57
			],
			[
				54,
				-612
			],
			[
				-7,
				-254
			],
			[
				-1146,
				-338
			],
			[
				-1549,
				-490
			],
			[
				-4,
				32
			],
			[
				-389,
				-122
			]
		],
		[
			[
				24107,
				40960
			],
			[
				156,
				-1376
			]
		],
		[
			[
				10199,
				17947
			],
			[
				213,
				-855
			],
			[
				122,
				-625
			]
		],
		[
			[
				20842,
				14395
			],
			[
				-1497,
				-614
			]
		],
		[
			[
				74234,
				48565
			],
			[
				180,
				-343
			],
			[
				63,
				-176
			]
		],
		[
			[
				74766,
				48724
			],
			[
				91,
				274
			]
		],
		[
			[
				74857,
				48998
			],
			[
				-356,
				379
			],
			[
				-5,
				52
			]
		],
		[
			[
				74501,
				28129
			],
			[
				81,
				-29
			],
			[
				135,
				-428
			],
			[
				107,
				-118
			]
		],
		[
			[
				74994,
				29484
			],
			[
				-377,
				91
			]
		],
		[
			[
				55422,
				16746
			],
			[
				-8,
				-1324
			]
		],
		[
			[
				44619,
				60559
			],
			[
				0,
				15
			]
		],
		[
			[
				55419,
				72253
			],
			[
				-59,
				-923
			]
		],
		[
			[
				11889,
				40237
			],
			[
				-121,
				775
			]
		],
		[
			[
				11768,
				41012
			],
			[
				-64,
				-26
			],
			[
				-208,
				81
			],
			[
				-161,
				-64
			],
			[
				-141,
				31
			],
			[
				-196,
				-218
			],
			[
				-135,
				200
			],
			[
				-37,
				150
			],
			[
				-81,
				54
			],
			[
				-27,
				126
			]
		],
		[
			[
				12881,
				51523
			],
			[
				1798,
				760
			],
			[
				68,
				16
			],
			[
				804,
				342
			]
		],
		[
			[
				74977,
				51781
			],
			[
				-48,
				-23
			],
			[
				-346,
				-410
			],
			[
				-15,
				-60
			]
		],
		[
			[
				47226,
				13765
			],
			[
				22,
				-659
			],
			[
				-67,
				-6
			],
			[
				42,
				-1319
			],
			[
				-69,
				-6
			],
			[
				10,
				-329
			]
		],
		[
			[
				33813,
				51038
			],
			[
				-101,
				165
			],
			[
				-81,
				555
			],
			[
				-60,
				8
			],
			[
				-66,
				189
			],
			[
				-168,
				84
			],
			[
				-45,
				212
			],
			[
				-164,
				279
			],
			[
				-19,
				276
			],
			[
				-99,
				330
			],
			[
				-95,
				195
			]
		],
		[
			[
				85750,
				42446
			],
			[
				64,
				-638
			],
			[
				38,
				-138
			]
		],
		[
			[
				86368,
				43361
			],
			[
				-53,
				-145
			],
			[
				-190,
				-148
			]
		],
		[
			[
				11768,
				41012
			],
			[
				9,
				304
			]
		],
		[
			[
				11806,
				41921
			],
			[
				-188,
				1268
			],
			[
				-115,
				-140
			],
			[
				-108,
				-11
			],
			[
				-279,
				89
			],
			[
				-583,
				585
			]
		],
		[
			[
				74857,
				48998
			],
			[
				89,
				96
			],
			[
				-24,
				266
			],
			[
				173,
				136
			]
		],
		[
			[
				75095,
				49496
			],
			[
				20,
				182
			],
			[
				-64,
				84
			],
			[
				5,
				423
			]
		],
		[
			[
				85048,
				47080
			],
			[
				106,
				-78
			],
			[
				-3,
				-137
			],
			[
				-69,
				-24
			],
			[
				-34,
				239
			]
		],
		[
			[
				59564,
				59035
			],
			[
				-320,
				2
			]
		],
		[
			[
				80683,
				86593
			],
			[
				68,
				407
			],
			[
				60,
				8
			],
			[
				-19,
				-271
			],
			[
				-61,
				-157
			]
		],
		[
			[
				81085,
				87079
			],
			[
				-84,
				-7
			],
			[
				28,
				207
			],
			[
				-70,
				108
			],
			[
				115,
				108
			],
			[
				86,
				-11
			],
			[
				110,
				334
			],
			[
				-59,
				156
			],
			[
				0,
				266
			],
			[
				-154,
				83
			],
			[
				40,
				272
			],
			[
				-52,
				60
			],
			[
				-38,
				-351
			],
			[
				-129,
				-295
			],
			[
				-85,
				-98
			],
			[
				-64,
				-231
			],
			[
				4,
				-515
			],
			[
				-56,
				-204
			],
			[
				-52,
				-355
			]
		],
		[
			[
				66568,
				56971
			],
			[
				-126,
				31
			]
		],
		[
			[
				66322,
				57023
			],
			[
				-96,
				9
			]
		],
		[
			[
				79173,
				48707
			],
			[
				-69,
				169
			],
			[
				218,
				93
			],
			[
				134,
				12
			],
			[
				63,
				181
			],
			[
				-34,
				82
			],
			[
				122,
				193
			],
			[
				103,
				-123
			],
			[
				104,
				151
			]
		],
		[
			[
				57756,
				43609
			],
			[
				-6,
				-955
			]
		],
		[
			[
				81121,
				42542
			],
			[
				32,
				-74
			],
			[
				-73,
				-281
			],
			[
				67,
				-145
			],
			[
				15,
				-175
			],
			[
				63,
				-10
			]
		],
		[
			[
				69905,
				51107
			],
			[
				-20,
				310
			],
			[
				98,
				136
			],
			[
				-81,
				161
			],
			[
				-120,
				89
			],
			[
				-17,
				159
			]
		],
		[
			[
				69620,
				52124
			],
			[
				-415,
				98
			]
		],
		[
			[
				47765,
				82819
			],
			[
				627,
				52
			],
			[
				196,
				46
			],
			[
				493,
				462
			]
		],
		[
			[
				49347,
				83627
			],
			[
				-56,
				110
			]
		],
		[
			[
				48112,
				83913
			],
			[
				-372,
				-22
			]
		],
		[
			[
				22237,
				69792
			],
			[
				-2538,
				-2618
			],
			[
				58,
				-202
			],
			[
				4,
				-258
			],
			[
				96,
				-94
			],
			[
				113,
				-233
			]
		],
		[
			[
				73751,
				54208
			],
			[
				-390,
				24
			]
		],
		[
			[
				73361,
				54232
			],
			[
				14,
				-64
			],
			[
				-123,
				-185
			],
			[
				-66,
				-383
			]
		],
		[
			[
				85901,
				50305
			],
			[
				-55,
				83
			],
			[
				-147,
				37
			],
			[
				-136,
				148
			]
		],
		[
			[
				35377,
				16076
			],
			[
				27,
				-330
			],
			[
				30,
				7
			],
			[
				80,
				-985
			],
			[
				-194,
				-45
			],
			[
				52,
				-315
			],
			[
				26,
				-327
			],
			[
				194,
				45
			],
			[
				11,
				-137
			],
			[
				384,
				81
			],
			[
				34,
				-20
			],
			[
				81,
				-1011
			]
		],
		[
			[
				87011,
				45112
			],
			[
				85,
				31
			],
			[
				248,
				-229
			],
			[
				56,
				-147
			],
			[
				65,
				80
			],
			[
				-4,
				249
			],
			[
				71,
				108
			]
		],
		[
			[
				87532,
				45204
			],
			[
				-72,
				94
			],
			[
				15,
				384
			]
		],
		[
			[
				89542,
				56084
			],
			[
				196,
				-10
			],
			[
				85,
				209
			],
			[
				-84,
				402
			],
			[
				8,
				169
			],
			[
				-70,
				242
			],
			[
				-311,
				517
			],
			[
				-316,
				-217
			]
		],
		[
			[
				23764,
				3862
			],
			[
				1427,
				529
			]
		],
		[
			[
				65740,
				79396
			],
			[
				506,
				126
			],
			[
				279,
				-33
			]
		],
		[
			[
				67220,
				81272
			],
			[
				-116,
				129
			],
			[
				-70,
				1
			]
		],
		[
			[
				65988,
				81203
			],
			[
				-71,
				-286
			],
			[
				-106,
				-183
			],
			[
				-31,
				-899
			],
			[
				-40,
				-439
			]
		],
		[
			[
				89221,
				45224
			],
			[
				-69,
				50
			],
			[
				-33,
				-297
			],
			[
				63,
				-66
			],
			[
				55,
				201
			]
		],
		[
			[
				89428,
				44235
			],
			[
				-82,
				81
			],
			[
				-48,
				386
			],
			[
				-93,
				-197
			],
			[
				63,
				-102
			],
			[
				-88,
				-166
			],
			[
				-48,
				296
			],
			[
				50,
				76
			],
			[
				-10,
				156
			],
			[
				-207,
				70
			],
			[
				-146,
				-251
			],
			[
				-261,
				-586
			],
			[
				118,
				-87
			],
			[
				-75,
				-232
			],
			[
				-1,
				-159
			],
			[
				64,
				-98
			],
			[
				51,
				68
			],
			[
				61,
				-111
			],
			[
				22,
				113
			],
			[
				244,
				15
			]
		],
		[
			[
				79377,
				46308
			],
			[
				133,
				245
			],
			[
				169,
				-270
			]
		],
		[
			[
				80270,
				46458
			],
			[
				-135,
				253
			],
			[
				163,
				771
			]
		],
		[
			[
				80006,
				48639
			],
			[
				-85,
				-290
			],
			[
				-84,
				-12
			],
			[
				24,
				-148
			],
			[
				-169,
				-198
			],
			[
				-84,
				-20
			],
			[
				-4,
				-138
			],
			[
				-210,
				28
			],
			[
				-112,
				99
			]
		],
		[
			[
				80897,
				85535
			],
			[
				-1,
				-606
			],
			[
				-58,
				-205
			],
			[
				-3,
				-179
			]
		],
		[
			[
				80394,
				51074
			],
			[
				59,
				-355
			]
		],
		[
			[
				88952,
				25882
			],
			[
				375,
				97
			],
			[
				706,
				-270
			]
		],
		[
			[
				62556,
				82126
			],
			[
				145,
				11
			],
			[
				112,
				82
			],
			[
				165,
				-313
			],
			[
				138,
				44
			],
			[
				121,
				278
			],
			[
				179,
				-15
			],
			[
				37,
				-98
			],
			[
				228,
				-22
			]
		],
		[
			[
				63910,
				82409
			],
			[
				-417,
				94
			]
		],
		[
			[
				62767,
				83402
			],
			[
				-42,
				-76
			],
			[
				87,
				-284
			],
			[
				-114,
				-18
			],
			[
				-92,
				81
			],
			[
				-68,
				-59
			]
		],
		[
			[
				62472,
				83979
			],
			[
				171,
				-172
			],
			[
				155,
				9
			],
			[
				269,
				254
			],
			[
				-98,
				125
			],
			[
				-2,
				132
			],
			[
				-151,
				54
			],
			[
				-366,
				-331
			],
			[
				22,
				-71
			]
		],
		[
			[
				66994,
				20565
			],
			[
				-61,
				-1235
			]
		],
		[
			[
				66933,
				19330
			],
			[
				774,
				-115
			],
			[
				49,
				992
			]
		],
		[
			[
				67451,
				21264
			],
			[
				-58,
				-117
			],
			[
				-131,
				6
			],
			[
				-57,
				-134
			],
			[
				-94,
				70
			]
		],
		[
			[
				70461,
				50895
			],
			[
				-7,
				129
			],
			[
				93,
				-64
			],
			[
				49,
				272
			],
			[
				102,
				-29
			],
			[
				75,
				178
			],
			[
				0,
				209
			]
		],
		[
			[
				86520,
				39875
			],
			[
				363,
				-124
			]
		],
		[
			[
				67819,
				62647
			],
			[
				-66,
				9
			]
		],
		[
			[
				12459,
				43990
			],
			[
				-1148,
				413
			],
			[
				3,
				215
			],
			[
				-571,
				580
			]
		],
		[
			[
				81190,
				36266
			],
			[
				-87,
				296
			],
			[
				65,
				549
			]
		],
		[
			[
				24107,
				40960
			],
			[
				-372,
				3279
			]
		],
		[
			[
				74551,
				25943
			],
			[
				1,
				413
			],
			[
				-146,
				111
			],
			[
				12,
				161
			],
			[
				-235,
				72
			],
			[
				-69,
				130
			],
			[
				-35,
				197
			]
		],
		[
			[
				28072,
				5401
			],
			[
				547,
				183
			],
			[
				998,
				311
			],
			[
				1194,
				359
			]
		],
		[
			[
				54670,
				47555
			],
			[
				-77,
				-259
			],
			[
				-163,
				14
			],
			[
				-94,
				-87
			],
			[
				-69,
				127
			],
			[
				-87,
				-26
			],
			[
				-12,
				104
			],
			[
				-99,
				-54
			]
		],
		[
			[
				88299,
				46937
			],
			[
				121,
				57
			],
			[
				123,
				343
			],
			[
				96,
				106
			],
			[
				157,
				-62
			],
			[
				57,
				102
			],
			[
				199,
				8
			],
			[
				-78,
				163
			]
		],
		[
			[
				88974,
				47654
			],
			[
				-88,
				0
			],
			[
				-48,
				100
			]
		],
		[
			[
				53231,
				38558
			],
			[
				6,
				-1103
			]
		],
		[
			[
				76180,
				32203
			],
			[
				-56,
				399
			],
			[
				-217,
				184
			],
			[
				-80,
				326
			],
			[
				37,
				576
			],
			[
				-84,
				104
			],
			[
				16,
				140
			]
		],
		[
			[
				75202,
				33850
			],
			[
				-123,
				-1329
			]
		],
		[
			[
				49534,
				44033
			],
			[
				578,
				30
			]
		],
		[
			[
				49541,
				45709
			],
			[
				-37,
				-2
			]
		],
		[
			[
				65144,
				78351
			],
			[
				382,
				-40
			]
		],
		[
			[
				65526,
				78311
			],
			[
				14,
				363
			],
			[
				59,
				50
			],
			[
				56,
				318
			],
			[
				64,
				152
			],
			[
				21,
				202
			]
		],
		[
			[
				54634,
				16748
			],
			[
				0,
				-1320
			]
		],
		[
			[
				61572,
				17981
			],
			[
				62,
				-11
			],
			[
				453,
				-451
			],
			[
				64,
				74
			],
			[
				191,
				-186
			],
			[
				95,
				-207
			],
			[
				81,
				31
			],
			[
				125,
				-111
			],
			[
				141,
				251
			],
			[
				-65,
				254
			],
			[
				-124,
				266
			],
			[
				56,
				211
			],
			[
				-91,
				165
			],
			[
				-48,
				239
			],
			[
				38,
				42
			]
		],
		[
			[
				62606,
				20187
			],
			[
				-971,
				74
			]
		],
		[
			[
				70435,
				73879
			],
			[
				-762,
				116
			]
		],
		[
			[
				72888,
				53373
			],
			[
				-98,
				67
			]
		],
		[
			[
				62897,
				78377
			],
			[
				-48,
				102
			]
		],
		[
			[
				67907,
				66192
			],
			[
				-576,
				76
			]
		],
		[
			[
				48636,
				37274
			],
			[
				769,
				47
			]
		],
		[
			[
				49421,
				37322
			],
			[
				-27,
				1333
			],
			[
				15,
				2
			]
		],
		[
			[
				55579,
				54277
			],
			[
				761,
				-9
			]
		],
		[
			[
				53519,
				18713
			],
			[
				9,
				14
			]
		],
		[
			[
				53841,
				21048
			],
			[
				19,
				331
			]
		],
		[
			[
				52754,
				20036
			],
			[
				12,
				-1323
			]
		],
		[
			[
				54673,
				83732
			],
			[
				120,
				-2
			],
			[
				281,
				-229
			]
		],
		[
			[
				44857,
				32092
			],
			[
				741,
				90
			],
			[
				645,
				57
			]
		],
		[
			[
				47710,
				35876
			],
			[
				-952,
				-86
			]
		],
		[
			[
				82893,
				52504
			],
			[
				-87,
				83
			],
			[
				21,
				209
			],
			[
				88,
				240
			],
			[
				52,
				432
			]
		],
		[
			[
				88623,
				42560
			],
			[
				-43,
				-290
			],
			[
				-54,
				-36
			],
			[
				-85,
				188
			],
			[
				-74,
				13
			],
			[
				28,
				154
			],
			[
				-70,
				80
			],
			[
				-16,
				-344
			],
			[
				54,
				-416
			],
			[
				67,
				201
			],
			[
				131,
				-10
			],
			[
				52,
				-123
			],
			[
				-55,
				-164
			],
			[
				21,
				-196
			]
		],
		[
			[
				83242,
				83586
			],
			[
				-451,
				115
			],
			[
				-17,
				78
			],
			[
				143,
				1582
			]
		],
		[
			[
				75372,
				71525
			],
			[
				52,
				4
			],
			[
				177,
				-192
			],
			[
				161,
				-56
			],
			[
				59,
				-350
			],
			[
				52,
				-53
			]
		],
		[
			[
				75976,
				70795
			],
			[
				-27,
				160
			],
			[
				67,
				1000
			]
		],
		[
			[
				76841,
				69394
			],
			[
				14,
				185
			],
			[
				-46,
				9
			],
			[
				-68,
				445
			]
		],
		[
			[
				83561,
				53296
			],
			[
				8,
				-1233
			]
		],
		[
			[
				65526,
				78311
			],
			[
				168,
				-19
			]
		],
		[
			[
				86990,
				43973
			],
			[
				-77,
				551
			]
		],
		[
			[
				47561,
				59822
			],
			[
				138,
				-116
			],
			[
				73,
				-282
			],
			[
				142,
				-37
			],
			[
				6,
				346
			],
			[
				147,
				206
			],
			[
				173,
				80
			],
			[
				112,
				-85
			],
			[
				122,
				-428
			],
			[
				79,
				-97
			],
			[
				129,
				-22
			]
		],
		[
			[
				48682,
				59387
			],
			[
				-7,
				775
			]
		],
		[
			[
				74523,
				21917
			],
			[
				131,
				272
			],
			[
				105,
				383
			],
			[
				-214,
				-132
			],
			[
				-74,
				166
			],
			[
				43,
				82
			],
			[
				-4,
				216
			],
			[
				93,
				169
			],
			[
				122,
				59
			],
			[
				5,
				84
			]
		],
		[
			[
				70618,
				38305
			],
			[
				57,
				1003
			]
		],
		[
			[
				70675,
				39308
			],
			[
				-189,
				29
			]
		],
		[
			[
				66608,
				22707
			],
			[
				191,
				-17
			],
			[
				26,
				660
			],
			[
				194,
				-13
			],
			[
				11,
				329
			],
			[
				111,
				-9
			],
			[
				12,
				330
			],
			[
				281,
				-28
			],
			[
				3,
				105
			],
			[
				290,
				-33
			],
			[
				1,
				95
			]
		],
		[
			[
				67728,
				24126
			],
			[
				-77,
				56
			],
			[
				-43,
				143
			],
			[
				32,
				100
			],
			[
				-104,
				261
			],
			[
				-50,
				281
			],
			[
				-59,
				147
			],
			[
				-2,
				164
			]
		],
		[
			[
				66319,
				23742
			],
			[
				-10,
				-334
			],
			[
				-66,
				8
			],
			[
				-26,
				-663
			]
		],
		[
			[
				65086,
				23890
			],
			[
				-52,
				-1328
			]
		],
		[
			[
				66921,
				16786
			],
			[
				221,
				-22
			],
			[
				252,
				133
			],
			[
				21,
				127
			],
			[
				154,
				50
			],
			[
				64,
				101
			],
			[
				29,
				196
			],
			[
				100,
				146
			],
			[
				39,
				151
			],
			[
				102,
				155
			],
			[
				92,
				23
			],
			[
				43,
				321
			],
			[
				170,
				47
			],
			[
				217,
				-61
			]
		],
		[
			[
				66933,
				19330
			],
			[
				-31,
				-659
			]
		],
		[
			[
				70471,
				19821
			],
			[
				-84,
				92
			],
			[
				10,
				103
			],
			[
				-211,
				-120
			],
			[
				-226,
				37
			],
			[
				-121,
				102
			],
			[
				-63,
				149
			],
			[
				-36,
				452
			],
			[
				-81,
				0
			],
			[
				-60,
				147
			]
		],
		[
			[
				87532,
				45204
			],
			[
				71,
				60
			],
			[
				18,
				161
			],
			[
				234,
				118
			],
			[
				132,
				-59
			],
			[
				79,
				87
			],
			[
				27,
				-81
			],
			[
				152,
				-25
			],
			[
				40,
				137
			],
			[
				113,
				90
			],
			[
				60,
				145
			]
		],
		[
			[
				12200,
				7276
			],
			[
				14,
				-436
			],
			[
				63,
				50
			],
			[
				-16,
				125
			],
			[
				197,
				-118
			],
			[
				191,
				-2
			],
			[
				-40,
				-114
			],
			[
				-166,
				-162
			],
			[
				16,
				-147
			],
			[
				-133,
				-115
			],
			[
				-56,
				52
			],
			[
				-11,
				302
			],
			[
				-93,
				21
			],
			[
				101,
				-504
			],
			[
				37,
				-409
			],
			[
				14,
				-443
			],
			[
				-76,
				-311
			],
			[
				66,
				-673
			]
		],
		[
			[
				87232,
				43285
			],
			[
				-30,
				-178
			]
		],
		[
			[
				68914,
				22457
			],
			[
				20,
				-269
			],
			[
				174,
				29
			],
			[
				-99,
				338
			],
			[
				-95,
				-98
			]
		],
		[
			[
				67826,
				25221
			],
			[
				15,
				-185
			],
			[
				152,
				-460
			],
			[
				308,
				-302
			],
			[
				55,
				-328
			],
			[
				131,
				-381
			],
			[
				24,
				-333
			],
			[
				68,
				5
			],
			[
				113,
				-126
			],
			[
				3,
				-254
			],
			[
				73,
				-149
			],
			[
				116,
				-28
			],
			[
				12,
				303
			],
			[
				-74,
				-12
			],
			[
				3,
				511
			],
			[
				-108,
				135
			],
			[
				-19,
				179
			],
			[
				-69,
				180
			],
			[
				35,
				137
			],
			[
				-64,
				140
			],
			[
				24,
				82
			],
			[
				-93,
				125
			],
			[
				-50,
				170
			],
			[
				-82,
				509
			]
		],
		[
			[
				13351,
				13276
			],
			[
				52,
				-306
			],
			[
				-95,
				-231
			],
			[
				-56,
				-258
			],
			[
				-54,
				-79
			],
			[
				55,
				-321
			]
		],
		[
			[
				86480,
				52379
			],
			[
				-66,
				-897
			]
		],
		[
			[
				86414,
				51482
			],
			[
				-63,
				-941
			]
		],
		[
			[
				54340,
				22052
			],
			[
				2,
				-667
			],
			[
				-19,
				-332
			]
		],
		[
			[
				75438,
				49236
			],
			[
				-343,
				260
			]
		],
		[
			[
				58396,
				72296
			],
			[
				-9,
				-723
			]
		],
		[
			[
				12811,
				88406
			],
			[
				463,
				73
			],
			[
				7,
				-116
			],
			[
				266,
				34
			],
			[
				4,
				-116
			],
			[
				531,
				37
			],
			[
				-3,
				134
			],
			[
				504,
				32
			],
			[
				716,
				13
			],
			[
				-2,
				-124
			],
			[
				126,
				-6
			],
			[
				0,
				-113
			],
			[
				117,
				-1
			],
			[
				24,
				-118
			]
		],
		[
			[
				12370,
				92525
			],
			[
				-21,
				18
			]
		],
		[
			[
				12237,
				92639
			],
			[
				-17,
				91
			],
			[
				-164,
				122
			],
			[
				-65,
				-154
			],
			[
				79,
				-145
			],
			[
				12,
				-260
			],
			[
				-80,
				-424
			],
			[
				160,
				-265
			],
			[
				-113,
				-637
			],
			[
				-84,
				-319
			],
			[
				-48,
				23
			],
			[
				-19,
				190
			],
			[
				-80,
				-6
			],
			[
				-160,
				138
			],
			[
				-230,
				33
			],
			[
				-171,
				-99
			],
			[
				-7,
				-258
			],
			[
				-68,
				-89
			],
			[
				-93,
				-324
			],
			[
				-95,
				-85
			],
			[
				-51,
				-143
			],
			[
				78,
				-106
			],
			[
				-157,
				-17
			],
			[
				179,
				-244
			],
			[
				5,
				-205
			],
			[
				-40,
				-74
			]
		],
		[
			[
				10033,
				90043
			],
			[
				198,
				78
			],
			[
				97,
				-139
			],
			[
				133,
				6
			],
			[
				96,
				-81
			],
			[
				10,
				159
			],
			[
				78,
				-4
			],
			[
				46,
				160
			],
			[
				9,
				450
			],
			[
				-177,
				9
			],
			[
				-80,
				127
			],
			[
				-69,
				-160
			],
			[
				-79,
				-22
			],
			[
				-242,
				-381
			],
			[
				-20,
				-202
			]
		],
		[
			[
				7988,
				88764
			],
			[
				78,
				-103
			],
			[
				-11,
				129
			],
			[
				204,
				374
			],
			[
				-177,
				-152
			],
			[
				-94,
				-248
			]
		],
		[
			[
				68659,
				52817
			],
			[
				-34,
				131
			],
			[
				-342,
				127
			],
			[
				-126,
				202
			]
		],
		[
			[
				88130,
				43278
			],
			[
				75,
				359
			],
			[
				32,
				331
			],
			[
				61,
				160
			],
			[
				221,
				296
			],
			[
				-23,
				264
			],
			[
				-88,
				27
			]
		],
		[
			[
				88408,
				44715
			],
			[
				-141,
				-240
			],
			[
				-139,
				-56
			],
			[
				-146,
				-245
			]
		],
		[
			[
				55595,
				28035
			],
			[
				5,
				1335
			]
		],
		[
			[
				64080,
				22996
			],
			[
				34,
				1000
			]
		],
		[
			[
				62742,
				24473
			],
			[
				-35,
				-996
			]
		],
		[
			[
				67983,
				63547
			],
			[
				642,
				-82
			]
		],
		[
			[
				28944,
				65705
			],
			[
				-30,
				337
			],
			[
				-109,
				59
			],
			[
				-160,
				-39
			],
			[
				-138,
				73
			],
			[
				-237,
				-19
			],
			[
				-61,
				95
			],
			[
				-82,
				-21
			],
			[
				-81,
				115
			],
			[
				-34,
				257
			],
			[
				58,
				410
			],
			[
				-77,
				-24
			]
		],
		[
			[
				73083,
				23591
			],
			[
				97,
				1322
			]
		],
		[
			[
				74939,
				40949
			],
			[
				7,
				-155
			],
			[
				-46,
				-652
			]
		],
		[
			[
				86414,
				51482
			],
			[
				-157,
				-29
			],
			[
				-229,
				37
			],
			[
				-227,
				-130
			],
			[
				-104,
				64
			],
			[
				-142,
				-110
			]
		],
		[
			[
				48692,
				58803
			],
			[
				-10,
				584
			]
		],
		[
			[
				8891,
				24760
			],
			[
				614,
				331
			]
		],
		[
			[
				8772,
				26847
			],
			[
				34,
				-537
			],
			[
				-11,
				-560
			],
			[
				-44,
				-7
			],
			[
				-72,
				-226
			],
			[
				145,
				-348
			],
			[
				67,
				-409
			]
		],
		[
			[
				82165,
				28174
			],
			[
				-93,
				-17
			],
			[
				25,
				-242
			],
			[
				-92,
				-458
			],
			[
				355,
				-325
			],
			[
				301,
				-251
			],
			[
				263,
				-137
			]
		],
		[
			[
				86577,
				30887
			],
			[
				2,
				-203
			],
			[
				-82,
				-368
			],
			[
				43,
				-26
			],
			[
				-61,
				-475
			]
		],
		[
			[
				89689,
				53638
			],
			[
				308,
				-302
			],
			[
				201,
				-72
			],
			[
				101,
				104
			],
			[
				16,
				138
			],
			[
				-54,
				285
			],
			[
				45,
				168
			],
			[
				77,
				530
			]
		],
		[
			[
				67065,
				46410
			],
			[
				572,
				-71
			]
		],
		[
			[
				67637,
				46339
			],
			[
				8,
				168
			],
			[
				188,
				-25
			]
		],
		[
			[
				44550,
				65617
			],
			[
				-61,
				1854
			]
		],
		[
			[
				18513,
				84514
			],
			[
				108,
				19
			],
			[
				81,
				-254
			],
			[
				-42,
				-184
			],
			[
				115,
				-235
			],
			[
				76,
				-61
			],
			[
				34,
				-327
			],
			[
				154,
				-169
			],
			[
				92,
				-9
			],
			[
				119,
				-253
			],
			[
				8,
				-135
			],
			[
				86,
				-208
			]
		],
		[
			[
				19344,
				82698
			],
			[
				600,
				5174
			]
		],
		[
			[
				76699,
				37735
			],
			[
				174,
				-37
			]
		],
		[
			[
				76958,
				38784
			],
			[
				-216,
				76
			]
		],
		[
			[
				53875,
				47871
			],
			[
				-3,
				671
			]
		],
		[
			[
				48836,
				92950
			],
			[
				312,
				22
			],
			[
				5,
				-327
			]
		],
		[
			[
				61035,
				67899
			],
			[
				459,
				-2
			]
		],
		[
			[
				79102,
				81202
			],
			[
				-258,
				-158
			],
			[
				-111,
				-213
			],
			[
				-8,
				-103
			],
			[
				-121,
				-259
			],
			[
				-104,
				-122
			],
			[
				-212,
				-159
			],
			[
				-291,
				-175
			],
			[
				-128,
				-145
			]
		],
		[
			[
				73137,
				54741
			],
			[
				144,
				-219
			],
			[
				80,
				-290
			]
		],
		[
			[
				71400,
				23910
			],
			[
				56,
				-284
			],
			[
				-2,
				-716
			],
			[
				-54,
				-339
			]
		],
		[
			[
				50134,
				42720
			],
			[
				-764,
				-41
			]
		],
		[
			[
				79437,
				44732
			],
			[
				91,
				57
			],
			[
				-17,
				153
			],
			[
				97,
				168
			]
		],
		[
			[
				75606,
				81897
			],
			[
				-128,
				2
			],
			[
				-113,
				116
			],
			[
				-107,
				-290
			],
			[
				-61,
				-329
			],
			[
				47,
				-200
			],
			[
				24,
				341
			],
			[
				58,
				291
			],
			[
				55,
				61
			],
			[
				57,
				-140
			],
			[
				-9,
				-275
			],
			[
				-200,
				-452
			]
		],
		[
			[
				90800,
				27449
			],
			[
				-722,
				512
			]
		],
		[
			[
				9637,
				33267
			],
			[
				-16,
				-89
			],
			[
				49,
				-385
			],
			[
				-18,
				-191
			],
			[
				58,
				-136
			],
			[
				22,
				-209
			],
			[
				-113,
				-241
			]
		],
		[
			[
				30210,
				70488
			],
			[
				-212,
				-44
			],
			[
				-1706,
				-432
			],
			[
				-564,
				-149
			]
		],
		[
			[
				84130,
				84012
			],
			[
				91,
				49
			],
			[
				34,
				211
			],
			[
				120,
				96
			],
			[
				31,
				114
			],
			[
				-46,
				162
			],
			[
				105,
				302
			]
		],
		[
			[
				35766,
				96281
			],
			[
				15,
				-144
			],
			[
				179,
				-286
			],
			[
				82,
				-15
			],
			[
				69,
				-280
			],
			[
				121,
				-168
			],
			[
				14,
				-226
			],
			[
				-82,
				-164
			],
			[
				-69,
				-255
			],
			[
				-15,
				-226
			],
			[
				32,
				-191
			],
			[
				79,
				-62
			],
			[
				224,
				147
			],
			[
				30,
				102
			],
			[
				167,
				144
			],
			[
				112,
				154
			],
			[
				88,
				-20
			],
			[
				192,
				102
			],
			[
				418,
				337
			],
			[
				295,
				407
			],
			[
				100,
				216
			],
			[
				-17,
				427
			],
			[
				183,
				-8
			],
			[
				55,
				184
			],
			[
				-5,
				187
			],
			[
				171,
				268
			],
			[
				188,
				152
			],
			[
				-17,
				187
			],
			[
				-331,
				507
			],
			[
				-322,
				284
			],
			[
				-152,
				60
			],
			[
				-192,
				-19
			],
			[
				-135,
				221
			],
			[
				-119,
				81
			],
			[
				-76,
				138
			],
			[
				-110,
				55
			],
			[
				-110,
				214
			],
			[
				6,
				107
			],
			[
				-98,
				315
			],
			[
				-148,
				208
			],
			[
				-115,
				-197
			],
			[
				-170,
				-169
			],
			[
				-160,
				-85
			],
			[
				-85,
				-322
			],
			[
				70,
				-824
			],
			[
				-76,
				-539
			],
			[
				-64,
				-38
			],
			[
				-54,
				-455
			],
			[
				-183,
				-466
			],
			[
				15,
				-45
			]
		],
		[
			[
				61741,
				23560
			],
			[
				7,
				327
			],
			[
				-194,
				10
			]
		],
		[
			[
				89827,
				24722
			],
			[
				-130,
				75
			],
			[
				19,
				98
			],
			[
				-157,
				89
			],
			[
				-20,
				-98
			],
			[
				-322,
				180
			],
			[
				-310,
				-107
			]
		],
		[
			[
				77041,
				64392
			],
			[
				311,
				-721
			]
		],
		[
			[
				32162,
				6647
			],
			[
				773,
				205
			]
		],
		[
			[
				48571,
				47333
			],
			[
				933,
				53
			]
		],
		[
			[
				49522,
				47387
			],
			[
				-40,
				1682
			]
		],
		[
			[
				48545,
				49015
			],
			[
				-23,
				-2
			]
		],
		[
			[
				47896,
				74488
			],
			[
				40,
				-1664
			]
		],
		[
			[
				29993,
				74676
			],
			[
				-1790,
				-441
			],
			[
				-864,
				-222
			]
		],
		[
			[
				73437,
				47819
			],
			[
				158,
				-76
			]
		],
		[
			[
				73595,
				47743
			],
			[
				135,
				147
			],
			[
				-36,
				168
			],
			[
				90,
				-26
			],
			[
				44,
				285
			],
			[
				148,
				52
			],
			[
				-23,
				109
			],
			[
				56,
				116
			]
		],
		[
			[
				84561,
				83205
			],
			[
				379,
				691
			],
			[
				142,
				446
			],
			[
				-104,
				217
			],
			[
				-4,
				385
			],
			[
				84,
				492
			],
			[
				75,
				297
			],
			[
				158,
				439
			],
			[
				160,
				378
			]
		],
		[
			[
				73818,
				47445
			],
			[
				-223,
				298
			]
		],
		[
			[
				53467,
				16743
			],
			[
				972,
				7
			]
		],
		[
			[
				70525,
				40009
			],
			[
				95,
				-15
			],
			[
				9,
				165
			],
			[
				254,
				-44
			],
			[
				28,
				503
			]
		],
		[
			[
				50182,
				37364
			],
			[
				-12,
				-1
			]
		],
		[
			[
				94278,
				9650
			],
			[
				-34,
				-466
			],
			[
				670,
				-3350
			],
			[
				232,
				26
			],
			[
				52,
				-40
			],
			[
				67,
				478
			],
			[
				52,
				185
			],
			[
				245,
				141
			],
			[
				229,
				-362
			],
			[
				85,
				-25
			],
			[
				85,
				-134
			],
			[
				4,
				-114
			],
			[
				84,
				-76
			],
			[
				211,
				-78
			],
			[
				-38,
				-198
			],
			[
				51,
				-92
			],
			[
				287,
				-11
			],
			[
				45,
				94
			],
			[
				334,
				223
			],
			[
				169,
				285
			],
			[
				151,
				59
			],
			[
				306,
				1695
			],
			[
				220,
				1170
			],
			[
				221,
				1226
			],
			[
				62,
				68
			],
			[
				-57,
				166
			],
			[
				111,
				182
			],
			[
				-56,
				110
			],
			[
				75,
				453
			]
		],
		[
			[
				70675,
				39308
			],
			[
				499,
				-87
			]
		],
		[
			[
				46675,
				42490
			],
			[
				-921,
				-87
			]
		],
		[
			[
				77972,
				38702
			],
			[
				791,
				-202
			]
		],
		[
			[
				69846,
				52389
			],
			[
				-196,
				349
			],
			[
				-164,
				125
			],
			[
				-363,
				486
			]
		],
		[
			[
				69139,
				53235
			],
			[
				-75,
				29
			],
			[
				-15,
				-176
			],
			[
				-142,
				66
			],
			[
				-18,
				-272
			]
		],
		[
			[
				47839,
				92911
			],
			[
				-85,
				-32
			],
			[
				7,
				-121
			],
			[
				-86,
				-53
			],
			[
				68,
				-80
			],
			[
				61,
				-558
			],
			[
				-95,
				-56
			],
			[
				40,
				-247
			],
			[
				-83,
				-195
			],
			[
				-94,
				-111
			],
			[
				-72,
				53
			],
			[
				-57,
				-130
			],
			[
				-71,
				14
			],
			[
				-125,
				-302
			],
			[
				-206,
				-271
			],
			[
				-41,
				-249
			],
			[
				7,
				-183
			],
			[
				-71,
				-113
			],
			[
				15,
				-153
			],
			[
				-117,
				-60
			],
			[
				-46,
				-271
			],
			[
				-68,
				-81
			],
			[
				-54,
				-230
			],
			[
				-249,
				-220
			]
		],
		[
			[
				61641,
				26233
			],
			[
				-560,
				41
			],
			[
				-55,
				129
			],
			[
				-2,
				199
			],
			[
				-53,
				407
			]
		],
		[
			[
				76461,
				72121
			],
			[
				7,
				123
			]
		],
		[
			[
				89547,
				53174
			],
			[
				-60,
				222
			],
			[
				-88,
				105
			],
			[
				-117,
				36
			],
			[
				-75,
				-94
			],
			[
				-110,
				16
			],
			[
				-126,
				-367
			],
			[
				-20,
				-196
			],
			[
				46,
				-282
			],
			[
				-31,
				-28
			]
		],
		[
			[
				67637,
				46339
			],
			[
				-28,
				-613
			]
		],
		[
			[
				24458,
				24142
			],
			[
				192,
				227
			],
			[
				4,
				99
			],
			[
				206,
				0
			],
			[
				53,
				289
			],
			[
				-57,
				203
			],
			[
				23,
				150
			],
			[
				-49,
				168
			],
			[
				119,
				186
			],
			[
				36,
				276
			],
			[
				56,
				34
			],
			[
				-67,
				192
			],
			[
				-28,
				393
			],
			[
				212,
				108
			],
			[
				-17,
				488
			]
		],
		[
			[
				77146,
				77702
			],
			[
				-78,
				361
			],
			[
				-74,
				103
			],
			[
				-11,
				324
			],
			[
				-39,
				109
			],
			[
				-187,
				87
			],
			[
				-224,
				290
			]
		],
		[
			[
				25124,
				95090
			],
			[
				81,
				-118
			],
			[
				51,
				81
			],
			[
				-51,
				118
			],
			[
				-81,
				-81
			]
		],
		[
			[
				24855,
				94663
			],
			[
				109,
				18
			],
			[
				3,
				250
			],
			[
				-112,
				-268
			]
		],
		[
			[
				24907,
				93151
			],
			[
				135,
				-27
			],
			[
				163,
				80
			],
			[
				44,
				114
			],
			[
				85,
				8
			]
		],
		[
			[
				25464,
				93715
			],
			[
				60,
				227
			],
			[
				143,
				329
			],
			[
				-50,
				555
			],
			[
				-96,
				348
			],
			[
				-67,
				-105
			],
			[
				-46,
				75
			],
			[
				-181,
				-461
			],
			[
				66,
				-185
			],
			[
				-64,
				-302
			],
			[
				-87,
				-260
			],
			[
				-74,
				-60
			],
			[
				126,
				281
			],
			[
				34,
				394
			],
			[
				-31,
				143
			],
			[
				-124,
				16
			],
			[
				-143,
				-77
			],
			[
				-105,
				-175
			],
			[
				23,
				-170
			],
			[
				-52,
				-334
			],
			[
				-1,
				316
			],
			[
				-43,
				99
			],
			[
				27,
				150
			],
			[
				-131,
				-122
			]
		],
		[
			[
				80162,
				33015
			],
			[
				286,
				-310
			],
			[
				257,
				-381
			],
			[
				40,
				-212
			],
			[
				100,
				55
			],
			[
				187,
				-296
			],
			[
				250,
				-320
			]
		],
		[
			[
				67974,
				23591
			],
			[
				-46,
				200
			],
			[
				6,
				261
			],
			[
				-206,
				74
			]
		],
		[
			[
				55567,
				52931
			],
			[
				4,
				1346
			]
		],
		[
			[
				77860,
				37470
			],
			[
				731,
				-174
			]
		],
		[
			[
				78701,
				34762
			],
			[
				61,
				-180
			],
			[
				225,
				-398
			],
			[
				62,
				-21
			],
			[
				370,
				-465
			]
		],
		[
			[
				69506,
				80772
			],
			[
				380,
				-157
			],
			[
				7,
				-84
			],
			[
				101,
				130
			],
			[
				-57,
				89
			],
			[
				-74,
				-43
			],
			[
				-256,
				100
			],
			[
				-101,
				-35
			]
		],
		[
			[
				70041,
				78968
			],
			[
				-95,
				167
			],
			[
				22,
				122
			],
			[
				-71,
				405
			],
			[
				12,
				507
			],
			[
				-45,
				224
			],
			[
				-105,
				29
			],
			[
				0,
				-120
			],
			[
				-145,
				-111
			],
			[
				-105,
				62
			],
			[
				-33,
				-66
			],
			[
				-117,
				89
			]
		],
		[
			[
				71814,
				35814
			],
			[
				-216,
				41
			]
		],
		[
			[
				52932,
				28020
			],
			[
				385,
				8
			]
		],
		[
			[
				52924,
				29355
			],
			[
				-386,
				-11
			]
		],
		[
			[
				44301,
				48643
			],
			[
				-12,
				317
			]
		],
		[
			[
				42343,
				48739
			],
			[
				28,
				-297
			],
			[
				61,
				-1337
			]
		],
		[
			[
				66616,
				44773
			],
			[
				51,
				1186
			],
			[
				-196,
				13
			]
		],
		[
			[
				72513,
				41033
			],
			[
				377,
				-73
			]
		],
		[
			[
				51605,
				18674
			],
			[
				-18,
				1325
			]
		],
		[
			[
				27283,
				27991
			],
			[
				-376,
				-117
			],
			[
				-131,
				1322
			],
			[
				93,
				29
			],
			[
				-36,
				328
			],
			[
				-93,
				-28
			],
			[
				-20,
				189
			]
		],
		[
			[
				48065,
				85960
			],
			[
				-1363,
				-73
			]
		],
		[
			[
				45126,
				48721
			],
			[
				-59,
				1682
			]
		],
		[
			[
				44240,
				50319
			],
			[
				0,
				-24
			]
		],
		[
			[
				88408,
				44715
			],
			[
				45,
				106
			],
			[
				131,
				-60
			],
			[
				-25,
				155
			],
			[
				44,
				149
			],
			[
				147,
				260
			],
			[
				-22,
				82
			],
			[
				67,
				276
			],
			[
				-202,
				-174
			],
			[
				-56,
				-197
			],
			[
				-49,
				47
			],
			[
				12,
				188
			],
			[
				-105,
				-78
			],
			[
				-64,
				-187
			],
			[
				-76,
				-80
			],
			[
				-151,
				-26
			],
			[
				-212,
				65
			],
			[
				-28,
				-163
			],
			[
				-111,
				-255
			]
		],
		[
			[
				90275,
				51035
			],
			[
				176,
				697
			],
			[
				139,
				447
			]
		],
		[
			[
				90555,
				52208
			],
			[
				-62,
				-206
			],
			[
				-64,
				-56
			],
			[
				8,
				-135
			],
			[
				-71,
				-277
			],
			[
				-116,
				-219
			],
			[
				-162,
				12
			],
			[
				-8,
				-122
			],
			[
				-91,
				93
			],
			[
				63,
				182
			],
			[
				120,
				119
			],
			[
				64,
				-57
			],
			[
				30,
				241
			],
			[
				333,
				861
			],
			[
				20,
				151
			],
			[
				-144,
				-162
			],
			[
				-9,
				-123
			],
			[
				-142,
				-283
			],
			[
				-70,
				-20
			]
		],
		[
			[
				72462,
				40314
			],
			[
				411,
				-81
			]
		],
		[
			[
				15438,
				48805
			],
			[
				-1074,
				-434
			],
			[
				-50,
				314
			],
			[
				-549,
				-247
			],
			[
				-48,
				325
			],
			[
				-278,
				-125
			],
			[
				-232,
				244
			]
		],
		[
			[
				11037,
				46911
			],
			[
				573,
				-587
			],
			[
				112,
				51
			],
			[
				13,
				-178
			],
			[
				124,
				-127
			]
		],
		[
			[
				49503,
				9581
			],
			[
				781,
				43
			]
		],
		[
			[
				67552,
				53621
			],
			[
				-347,
				50
			],
			[
				-31,
				132
			]
		],
		[
			[
				52144,
				30673
			],
			[
				331,
				9
			]
		],
		[
			[
				82548,
				34838
			],
			[
				101,
				1070
			]
		],
		[
			[
				81820,
				35687
			],
			[
				-8,
				-6
			]
		],
		[
			[
				70080,
				28161
			],
			[
				-86,
				-570
			],
			[
				-101,
				-318
			],
			[
				121,
				-259
			],
			[
				55,
				-236
			]
		],
		[
			[
				18942,
				79227
			],
			[
				402,
				3471
			]
		],
		[
			[
				70045,
				44471
			],
			[
				-50,
				-1007
			]
		],
		[
			[
				84002,
				62924
			],
			[
				-33,
				163
			],
			[
				-78,
				25
			],
			[
				-179,
				270
			],
			[
				-34,
				-196
			],
			[
				-114,
				71
			],
			[
				-44,
				-144
			],
			[
				-112,
				-145
			],
			[
				-152,
				39
			],
			[
				-149,
				-80
			],
			[
				-18,
				-214
			]
		],
		[
			[
				69283,
				43245
			],
			[
				-18,
				84
			],
			[
				148,
				245
			],
			[
				-47,
				119
			],
			[
				27,
				154
			],
			[
				-8,
				542
			],
			[
				32,
				169
			]
		],
		[
			[
				71524,
				38812
			],
			[
				-9,
				-167
			]
		],
		[
			[
				8891,
				24760
			],
			[
				-103,
				-269
			],
			[
				-48,
				-279
			],
			[
				36,
				-246
			],
			[
				-32,
				-298
			],
			[
				25,
				-314
			],
			[
				63,
				-403
			],
			[
				160,
				-458
			],
			[
				19,
				-325
			],
			[
				-47,
				-96
			],
			[
				1,
				-228
			],
			[
				-61,
				-49
			],
			[
				11,
				-412
			],
			[
				189,
				-360
			]
		],
		[
			[
				70544,
				34170
			],
			[
				55,
				-271
			],
			[
				53,
				-429
			]
		],
		[
			[
				15442,
				93819
			],
			[
				42,
				-103
			],
			[
				61,
				69
			],
			[
				-2,
				-172
			],
			[
				161,
				-206
			],
			[
				65,
				-149
			],
			[
				-24,
				-118
			],
			[
				125,
				-104
			],
			[
				-52,
				383
			],
			[
				56,
				-94
			],
			[
				91,
				167
			],
			[
				61,
				-77
			],
			[
				-34,
				266
			],
			[
				-96,
				-86
			],
			[
				21,
				114
			],
			[
				-138,
				-53
			],
			[
				-22,
				160
			],
			[
				-134,
				106
			],
			[
				-181,
				-103
			]
		],
		[
			[
				15046,
				95861
			],
			[
				118,
				-96
			],
			[
				59,
				144
			],
			[
				-121,
				21
			],
			[
				-56,
				-69
			]
		],
		[
			[
				14874,
				94754
			],
			[
				65,
				-192
			],
			[
				169,
				-201
			],
			[
				102,
				16
			],
			[
				39,
				195
			],
			[
				12,
				-197
			],
			[
				-32,
				-217
			],
			[
				89,
				-105
			],
			[
				62,
				78
			],
			[
				103,
				-23
			],
			[
				-54,
				-154
			],
			[
				151,
				129
			],
			[
				-76,
				-123
			],
			[
				164,
				31
			],
			[
				-6,
				121
			],
			[
				73,
				-20
			],
			[
				100,
				-147
			],
			[
				76,
				287
			],
			[
				-61,
				-38
			],
			[
				-7,
				239
			],
			[
				131,
				-34
			],
			[
				-74,
				268
			],
			[
				-171,
				-105
			],
			[
				62,
				163
			],
			[
				-90,
				160
			],
			[
				-146,
				117
			],
			[
				126,
				37
			],
			[
				-146,
				104
			],
			[
				-35,
				115
			],
			[
				-45,
				-119
			],
			[
				22,
				-151
			],
			[
				-141,
				441
			],
			[
				-92,
				126
			],
			[
				-105,
				34
			],
			[
				128,
				-263
			],
			[
				-55,
				-17
			],
			[
				72,
				-272
			],
			[
				-209,
				417
			],
			[
				-96,
				-196
			],
			[
				-2,
				-257
			],
			[
				-109,
				-143
			],
			[
				6,
				-104
			]
		],
		[
			[
				14854,
				96003
			],
			[
				119,
				-221
			],
			[
				61,
				63
			],
			[
				-180,
				158
			]
		],
		[
			[
				15467,
				92768
			],
			[
				-23,
				141
			],
			[
				-111,
				177
			],
			[
				-89,
				7
			],
			[
				-65,
				157
			],
			[
				24,
				152
			],
			[
				-87,
				322
			],
			[
				-105,
				104
			],
			[
				-104,
				2
			],
			[
				-167,
				132
			],
			[
				7,
				119
			],
			[
				-95,
				44
			],
			[
				7,
				143
			],
			[
				-126,
				-77
			],
			[
				-56,
				304
			],
			[
				-130,
				-26
			],
			[
				7,
				168
			],
			[
				-79,
				-51
			],
			[
				-148,
				237
			],
			[
				92,
				-55
			],
			[
				-13,
				236
			]
		],
		[
			[
				83114,
				71024
			],
			[
				83,
				17
			],
			[
				-17,
				218
			],
			[
				-81,
				122
			],
			[
				-74,
				-32
			],
			[
				-23,
				167
			],
			[
				66,
				8
			],
			[
				-91,
				225
			],
			[
				-142,
				-37
			],
			[
				-3,
				188
			],
			[
				70,
				4
			],
			[
				-34,
				221
			],
			[
				-101,
				206
			],
			[
				-46,
				-1
			]
		],
		[
			[
				88974,
				47654
			],
			[
				14,
				84
			],
			[
				122,
				-56
			],
			[
				77,
				211
			],
			[
				41,
				224
			],
			[
				-17,
				309
			],
			[
				-131,
				-166
			],
			[
				-133,
				-95
			]
		],
		[
			[
				95071,
				28458
			],
			[
				-53,
				-19
			]
		]
	],
	"bbox": [
		-56.77775821661018,
		12.469025989284091,
		942.7924311762474,
		596.9298966319916
	],
	"transform": {
		"scale": [
			0.009995801851947097,
			0.005844667153098606
		],
		"translate": [
			-56.77775821661018,
			12.469025989284091
		]
	}
}